import { useEffect, useRef, useState } from 'react';
import MobileNavigation from '../../components/MobileNavigation';
import { BtnFlex, BtnFlexWrapper, CalendarBtnWrapper, CalendarDays, CalendarDaysWrapper, CalendarEventDays, CalendarTopWrapper, CalendarWrapper, CalendarYearMonth, CircularBox, CurrentDay, DateTimeWrapper, DateWrapper, DayContainer, DayWrapper, EngDate, EventDate, EventName, FixedTopic, HomeWrapper, HR, IconWithTime, LeftDayWrapper, MarginItems, MidWrapper, MonthYear, NavLinks, NormalFont, RightDayWrapper, ScrollDays, SemiBoldFont, TimelineWrapper, Tithi, TithiDays, TodayDate, TopLeftWrapper, TopRightWrapper, TopWrapper, UpcomingDays, UpcomingDaysOption, UpcomingDaysWrapper, WeekDaysName } from './HomeComponent';
import Navbar from '../../components/Navbar';

import { FaSun } from 'react-icons/fa';


import { GiSunrise, GiSunset } from 'react-icons/gi';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';
import useWindowDimensions from '../../utils/useWindowDimensions';
import axios from 'axios';
import { GRAPHQL_QUERY } from '../../utils/constants';
import NepaliDate from 'nepali-date-converter';
import { DATE_CONVERSION } from '../../utils/dateConversion';
import Footer from '../../components/Footer';
import Modal from '../../components/Modal';

import "../../App.css";
import { Tabs } from '@mantine/core';

const Home = ({ neapliDateData }) => {

    // Refrences 
    const calendarRef = useRef();
    const fixedTopicRef = useRef();

    // Timezone Nepal
    const moment = require('moment-timezone');

    // Modes and dimensions
    const [darkMode, setDarkMode] = useState(false);
    const [calendarLoading, setCalendarLoading] = useState(false);
    const { height, width } = useWindowDimensions();

    // Sibling height
    const [calendarHeight, setcalendarHeight] = useState('0');
    const [fixedTopicHeight, setfixedTopicHeight] = useState('6vh');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUpcomingIndex, setSelectedUpcomingIndex] = useState(0);


    // calendar data
    const [currentCalendarData, setCurrentCalendarData] = useState([]);
    const [currentSelectedCalendar, setcurrentSelectedCalendar] = useState();
    const [calendarData, setCalendarData] = useState([]);

    const [currentlySelectedYear, setcurrentlySelectedYear] = useState([]);

    const [today, setToday] = useState();
    const [todayFirstDate, setTodayFirstDate] = useState();
    const [todayData, setTodayData] = useState();
    const [selectedMonthYear, setSelectedMonthYear] = useState();
    const [dateChanged, setDateChanged] = useState(false);
    const [currentlySelectedIndexInModal, setCurrentlySelectedIndexInModal] = useState(0);

    //Dates
    const [dateConversion, setDateConversion] = useState([])

    const [show, setShow] = useState(false);

    const [modalContent, setModalContent] = useState(null);

    const handleOpenModal = () => {
        setShow(true);
    }

    const handleCloseModal = () => {
        setShow(false);
    }

    // Toggle darkmode or lightmode
    const toogleMode = () => {
        setDarkMode(!darkMode);
        console.log("Modes changed");
    }


    // Tabs


    const [activeTab, setActiveTab] = useState(0);

    const onTabChange = (active, tabKey) => {
        setActiveTab(active);
        console.log('tabKey', tabKey);
    }


    // Making responsive height of siblings (inside MidWrapper)

    const getCalendarHeight = () => {
        console.log("Called Calendar Height");
        const newHeight = calendarRef.current?.clientHeight;
        console.log("New Height");
        console.log(newHeight);
        setcalendarHeight(newHeight);
    }

    const getFixedHeight = () => {
        const newHeight = fixedTopicRef.current?.clientHeight;
        setfixedTopicHeight(newHeight);
    }

    useEffect(() => {
        getFixedHeight();
    }, [calendarHeight])

    useEffect(() => {
        getCalendarHeight()
    }, [currentCalendarData, calendarData, calendarLoading])

    useEffect(() => {
        window.addEventListener("resize", getCalendarHeight);
        window.addEventListener("resize", getFixedHeight);
    }, []);


    const onSelectValueChanged = (event) => {
        setSelectedUpcomingIndex(event.target.value ? event.target.value : 1);
    }

    const handleDateSelection = (event) => {
        console.log("Date Selected");
    }


    // API Fetching
    const apiEndPoint = async ({ selectedYear, initial = true, prevYear = true }) => {
        setCalendarLoading(true);
        console.log("Fetching data from server");
        console.log(selectedYear);

        var config = {
            method: 'post',
            url: 'https://api.saralpatro.com/graphql',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                query: GRAPHQL_QUERY({ selectedYear: selectedYear.format('YYYY') }),
                // variables: {}
            })
        };

        await axios(config)
            .then(function (response) {
                // console.log(response.data.data.dates);
                console.log("Dhanda")
                let calendarTemp;


                setcurrentlySelectedYear(response.data.data.dates)

                prevYear ?
                    calendarTemp = [...response.data.data.dates, ...calendarData] :
                    calendarTemp = [...calendarData, ...response.data.data.dates];
                console.log(calendarTemp);

                setCalendarData(calendarTemp);

                // console.log(response.data.data.dates);
                console.log("DHey Hamara")
                console.log(selectedYear.format('M'));
                console.log(calendarTemp.filter(data => data.bsMonth.toString() === selectedYear.format('M')))
                setCurrentCalendarData(calendarTemp.filter(data => data.bsMonth.toString() === selectedYear.format('M') && data.bsYear.toString() === selectedYear.format('YYYY')))


                if (initial) {
                    let details = calendarTemp.filter(data => data.bsMonth.toString() === selectedYear.format('M') && data.bsYear.toString() === selectedYear.format('YYYY') && data.bsDay === parseInt(selectedYear.format('DD')));
                    console.log(details);
                    // console.log(selectedYear.format('DD'));
                    // console.log(response.data.data.dates.filter(data => data.bsMonth.toString() === selectedYear.format('MM') && data.bsDay.toString() === selectedYear.format('DD')))
                    setTodayData(details[0]);
                    // setCalendarData();
                    // console.log(response.data.data.dates.filter(data => data.bsMonth === 12));
                    console.log(details[0].astral);
                    setIsLoading(false);
                }

                setCalendarLoading(false);

                console.log("Pyaar0")
                console.log(calendarTemp);
                console.log("Khet0")

            })

        console.log("Dey Dana done")

        return "done";
    }

    // useEffect(() => {
    //     // console.log("fetch data");
    //     console.log("I am first");
    //     // console.log("fetch data");





    // }, [])


    const onModalPrevClick = () => {


        if (calendarLoading) {
            return;
        }

        console.log(modalContent);

        let d = modalContent.bsDay;

        if (d - 1 <= 0) {
            onPrevClick()
            setCurrentlySelectedIndexInModal(0)
        } else {
            let temp = currentCalendarData.filter(item => item.bsYear === modalContent.bsYear && item.bsMonth === modalContent.bsMonth && item.bsDay === d - 1)
            console.log("=============");
            console.log(temp);
            setModalContent(temp[0]);
        }


    }


    const onModalNextClick = () => {


        if (calendarLoading) {
            return;
        }

        console.log(modalContent);

        // let d = modalContent.bsDay;

        let d = currentCalendarData.indexOf(modalContent)


        console.log("Current day+++++++++++");
        console.log(d);
        console.log(currentCalendarData.length);

        if (d + 1 > currentCalendarData.length - 1) {
            onNextClick();
            setCurrentlySelectedIndexInModal(1)
        } else {
            let temp = currentCalendarData.filter(item => item.bsYear === modalContent.bsYear && item.bsMonth === modalContent.bsMonth && item.bsDay === d + 2)
            console.log("=============");
            console.log(temp);
            setModalContent(temp[0]);
        }

        // if (d - 1 <= 0) {
        //     onPrevClick()
        //     setCurrentlySelectedIndexInModal(0)
        // } else {
        //     let temp = currentCalendarData.filter(item => item.bsYear === modalContent.bsYear && item.bsMonth === modalContent.bsMonth && item.bsDay === d - 1)
        //     console.log("=============");
        //     console.log(temp);
        //     setModalContent(temp[0]);
        // }


    }




    useEffect(() => {
        if (currentlySelectedIndexInModal === 1) {
            setModalContent(currentCalendarData[0])
        } else {
            setModalContent(currentCalendarData[currentCalendarData.length - 1])
        }
    }, [currentCalendarData])


    const onNextClick = () => {


        // let todayTemp = { ...selectedMonthYear };

        let { month, year } = selectedMonthYear;

        let m = parseInt(month);
        let y = parseInt(year);

        console.log("Current Year");


        if ((m + 1) > 12) {
            m = 1;
            y = y + 1;

            let currentData = calendarData.filter(data => data.bsMonth === m && data.bsYear === y);

            console.log("Next Year");
            console.log(currentCalendarData)

            if (currentData.length > 0) {
                console.log("Already Fetched");
                setCurrentCalendarData(
                    currentData
                );
            } else {
                console.log("New Fetched");
                apiEndPoint({
                    selectedYear: moment(`${y}-${m}-01`),
                    initial: false,
                    prevYear: false,
                });
            }

            setSelectedMonthYear({
                'month': m,
                'year': y
            })
        } else {
            m = m + 1;
            setCurrentCalendarData(
                calendarData.filter(data => data.bsMonth === m && data.bsYear === y)
            );

            setSelectedMonthYear({
                'month': m,
                'year': y
            })
        }

        // currentCalendarData = calendarData.filter(data => data.bsMonth.toString() === selectedYear.format('MM') && data.bsYear.toString() === selectedYear.format('YYYY') && data.bsDay === parseInt(selectedYear.format('DD')));
        console.log("00000000000000000");
        console.log(todayFirstDate)
    }


    const onPrevClick = () => {


        // let todayTemp = { ...selectedMonthYear };

        let { month, year } = selectedMonthYear;

        let m = parseInt(month);
        let y = parseInt(year);

        if ((m - 1) === 0) {
            m = 12;
            y = y - 1;

            let currentData = calendarData.filter(data => data.bsMonth === m && data.bsYear === y);

            if (currentData.length > 0) {
                setCurrentCalendarData(
                    currentData
                );
                setcurrentlySelectedYear(calendarData.filter(data => data.bsYear === y))
            } else {
                apiEndPoint({
                    selectedYear: moment(`${y}-${m}-01`),
                    initial: false,
                });
            }

            setSelectedMonthYear({
                'month': m,
                'year': y
            })
        } else {
            m = m - 1;
            setCurrentCalendarData(
                calendarData.filter(data => data.bsMonth === m && data.bsYear === y)
            );

            setSelectedMonthYear({
                'month': m,
                'year': y
            })
        }

        // currentCalendarData = calendarData.filter(data => data.bsMonth.toString() === selectedYear.format('MM') && data.bsYear.toString() === selectedYear.format('YYYY') && data.bsDay === parseInt(selectedYear.format('DD')));
        console.log("00000000000000000");
        console.log(todayFirstDate)
    }




    const onCurrentMonthClick = () => {
        // let todayTemp = { ...selectedMonthYear };

        console.log("Current Month");
        console.log(todayFirstDate);
        console.log(today);
        // console.log(todayFirstDate.nepDate.format("YYYY"));

        let year = todayFirstDate.nepDate.format("YYYY");
        let month = todayFirstDate.nepDate.format("M");
        let day = todayFirstDate.nepDate.format("DD");

        // let { month, year } = today.neapliDateData;

        let m = parseInt(month);
        let y = parseInt(year);


        let currentData = calendarData.filter(data => data.bsMonth === m && data.bsYear === y);

        if (currentData.length > 0) {
            setCurrentCalendarData(
                currentData
            );
        } else {
            apiEndPoint({
                selectedYear: moment(`${y}-${m}-day`),
                initial: true,
            });
        }

        setSelectedMonthYear({
            'month': m,
            'year': y
        })






        // console.log(m + " and " + y);

        // if ((m - 1) === 0) {
        //     m = 12;
        //     y = y - 1;

        //     let currentData = calendarData.filter(data => data.bsMonth === m && data.bsYear === y);

        //     if (currentData.length > 0) {
        //         setCurrentCalendarData(
        //             currentData
        //         );
        //     } else {
        //         apiEndPoint({
        //             selectedYear: moment(`${y}-${m}-01`),
        //             initial: false,
        //         });
        //     }

        //     setSelectedMonthYear({
        //         'month': m,
        //         'year': y
        //     })
        // } else {
        //     m = m - 1;
        //     setCurrentCalendarData(
        //         calendarData.filter(data => data.bsMonth === m && data.bsYear === y)
        //     );

        //     setSelectedMonthYear({
        //         'month': m,
        //         'year': y
        //     })
        // }

        // currentCalendarData = calendarData.filter(data => data.bsMonth.toString() === selectedYear.format('MM') && data.bsYear.toString() === selectedYear.format('YYYY') && data.bsDay === parseInt(selectedYear.format('DD')));
        // console.log("00000000000000000/");
        // console.log(todayFirstDate)
    }

    // useEffect(() => {

    // })


    const changeEngDatetoNep = ({ date }) => {
        console.log("Changing ddate");
        // console.log(date);
        var index = DATE_CONVERSION.findIndex(p => p.engDate === date);
        let _nepDate = DATE_CONVERSION[index].nepDate.split('.');
        console.log(DATE_CONVERSION[index].nepDate);
        // console.log(`${_nepDate[2]}-${_nepDate[1]}-${_nepDate[0]}`);
        var nepalidate = moment(`${_nepDate[2].trim()}-${_nepDate[1]}-${_nepDate[0]}`);
        console.log(nepalidate);
        console.log("===============")
        return nepalidate
    }

    useEffect(() => {


        console.log("Loaf Neplai");
        console.log("loaded Neapli");

        var todayFirstDate = moment.tz(new Date(), 'Asia/Kathmandu');
        apiEndPoint({
            selectedYear: changeEngDatetoNep({
                date: todayFirstDate.format('YYYY-MM-DD'),
            })
        });

        let nepaliFirstDate = changeEngDatetoNep({
            date: todayFirstDate.format('YYYY-MM-DD'),
        });

        console.log("For Today:  " + { todayFirstDate });
        setcurrentSelectedCalendar(todayFirstDate);
        setTodayFirstDate({
            'engDate': todayFirstDate,
            'nepDate': nepaliFirstDate,
        });



        setToday({

            'engDate': todayFirstDate,
            'nepDate': nepaliFirstDate,
        })



        setSelectedMonthYear({
            'month': nepaliFirstDate.format('MM'),
            'year': nepaliFirstDate.format('YYYY')
        })

        console.log("Why this")


        const TimerSetter = setInterval(() => {
            var current = new Date();
            var todayDate = moment.tz(current, 'Asia/Kathmandu');

            // console.log("Nepali Date");
            // console.log(new NepaliDate(new Date(todayDate.format('YYYY'), todayDate.format('MM'), todayDate.format('DD'))));

            setToday(

                {
                    'engDate': todayDate,
                });

            console.log("My Date");
            console.log(todayFirstDate.format('DD MMMM, YYYY, MM  hh:mm:ss A'));
            console.log("Changed");


            console.log(moment(todayDate.format('YYYY-MM-DD')).isSame(todayFirstDate.format('YYYY-MM-DD')));

            // console.log(moment(todayDate.format('DD MMMM, YYYY')).isSame(todayFirstDate.format('DD MMMM, YYYY')));
            var dateSame = moment(todayDate.format('YYYY-MM-DD')).isSame(todayFirstDate.format('YYYY-MM-DD'));
            console.log("What is happeing");
            console.log(!moment(todayDate.format('YYYY-MM-DD')).isSame(todayFirstDate.format('YYYY-MM-DD')));
            if (!dateSame) {

                todayFirstDate = todayDate;

                console.log("For Today:  " + { todayDate });



                setTodayFirstDate(

                    {
                        'engDate': todayDate,
                        'nepDate': changeEngDatetoNep({
                            date: todayFirstDate.format('YYYY-MM-DD'),
                        }),
                    });

                let nepaliDate = changeEngDatetoNep({
                    date: todayDate.format('YYYY-MM-DD')
                });

                setToday({
                    ...today,
                    'engDate': todayDate,
                    'nepDate': nepaliDate,
                });

                setSelectedMonthYear({
                    'month': nepaliDate.format('MM'),
                    'year': nepaliDate.format('YYYY')
                })


                if (calendarData.length > 0) {

                    console.log("today is today");
                } else {
                    console.log("Today data");
                    console.log(today);
                    console.log("Fetch Data");
                    apiEndPoint({
                        selectedYear: changeEngDatetoNep({
                            date: todayDate.format('YYYY-MM-DD'),
                        })
                    });
                }
            }

        }, 900000);

        // For testing: 900000 real: 1000

        return () => clearInterval(TimerSetter);
    }, []);

    const CreateCalendar = ({ calendarData }) => {



        const handleCalenderItemsClick = ({ item }) => {

            // Sets the current selected year to to the modal
            setcurrentlySelectedYear(calendarData.filter(data => data.bsYear === item.bsYear));

            setShow(true);
            setModalContent(item)
            console.log("Calendar items displayed");
        }


        var calendarList = [];
        // console.log("Calendar Data");
        // console.log(currentCalendarData);
        // console.log(calendarData[0].weekday);
        if (calendarData[0].weekday !== 6) {
            for (var i = 0; i <= calendarData[0].weekday; i++) {
                calendarList.push(<CalendarDays key={`Bef-${i}`}></CalendarDays>);
            }
        }


        currentCalendarData.map((item, index) => (
            calendarList.push(
                // key = {`${item.adDay}-${item.adMonth}-${item.adYear}`}

                <CalendarDays key={index} selected={currentlySelectedIndex({ item: item })} isHoliday={item.isHoliday} onClick={() => handleCalenderItemsClick({ item: item })}>
                    <TithiDays>
                        <Tithi>{item.tithiStrNp}</Tithi>
                        <EngDate>{item.adDay}</EngDate>
                    </TithiDays>
                    <CurrentDay>{item.bsDayNp}</CurrentDay>
                    <CalendarEventDays>
                        {item.events.length > 0 ? item.events[0].strNp : ''}
                    </CalendarEventDays>
                </CalendarDays>
            )
        )
        )

        // console.log("WTF");
        // console.log(calendarData[calendarData.length - 1].weekday);
        let finalDay = calendarData[calendarData.length - 1].weekday;

        if (finalDay === 6) {
            for (let j = 0; j < 6; j++) {

                calendarList.push(<CalendarDays key={`Aft-${j}`}></CalendarDays>);
            }
        } else {
            for (let j = 0; j < (5 - finalDay); j++) {
                calendarList.push(<CalendarDays key={`Aft-${j}`}></CalendarDays>);
            }
        }







        return (
            <CalendarDaysWrapper>
                {calendarList.map((item) => item)}
            </CalendarDaysWrapper>
        );
    }


    // const convertDateFromEngToNepali

    const createTime = ({ time }) => {

        if (time < 10) {
            return `0${time}`
        } else {
            return time;
        }

    }

    const checkTimePeriod = ({ item }) => {
        let timePeriod = moment(moment(`${item.adYear}-${item.adMonth}-${item.adDay}`).format('YYYY-MM-DD')).diff(moment(moment(today).format('YYYY-MM-DD'))) / 86400000
        return timePeriod;
    }

    const currentlySelectedIndex = ({ item }) => {

        // console.log(item);
        // console.log("-----------------");
        // console.log(moment(todayFirstDate).format('DD'));
        // console.log(moment(todayFirstDate).format('MM'));
        // console.log(moment(todayFirstDate).format('YYYY'));
        // console.log(moment(todayFirstDate.format('YYYY-MM-DD')).isSame(moment(`${item.adYear}-${item.adMonth}-${item.adDay}`).format('YYYY-MM-DD')));
        // console.log("=================");





        if (moment(todayFirstDate?.engDate.format('YYYY-MM-DD')).isSame(moment(`${item.adYear}-${item.adMonth}-${item.adDay}`).format('YYYY-MM-DD'))) {
            return true;
        }

        // let selected = (if({item.adYear == } - ${ item.adMonth }-${ item.adDay }
        return false;
    }



    return (
        <div className="App">
            {/* <Topbar toogleMode={toogleMode} mode={darkMode} /> */}
            <Navbar></Navbar>
            {isLoading ? <div style={{ flex: 1 }}>Loading</div> : (
                <HomeWrapper>
                    {/* <h1>{today && today.nepDate}</h1> */}
                    {/* <button onClick={() => setShow(true)}>Open Modal</button> */}
                    <TopWrapper>
                        <TopLeftWrapper>
                            <DateWrapper>
                                {todayData?.bsDayNp}
                            </DateWrapper>

                            <DateTimeWrapper>
                                <SemiBoldFont fontSize='36px'>
                                    {todayData?.bsMonthStrNp} {todayData?.bsYearNp}, {todayData?.weekdayStrNp}
                                </SemiBoldFont>
                                <SemiBoldFont fontsize='24px' small={true}>
                                    {todayData?.adDay} {todayData?.adMonthStrEn}, {todayData?.adYear}
                                </SemiBoldFont>
                                <NormalFont>{todayData?.tithiStrNp}
                                    {

                                        todayData?.events.map((item, index) => {
                                            return `, ${item.strNp}`
                                        })

                                    }
                                </NormalFont>
                                <NormalFont>{today?.engDate?.format('hh:mm:ss A')}</NormalFont>


                            </DateTimeWrapper>
                        </TopLeftWrapper>
                        <TopRightWrapper>
                            <SemiBoldFont fontsize='24px' big={true}>
                                {todayData?.adDay} {todayData?.adMonthStrEn}, {todayData?.adYear}
                            </SemiBoldFont>
                            <TimelineWrapper>
                                <IconWithTime>
                                    <MarginItems>
                                        <GiSunrise className='icon-margin' />
                                        {createTime({ time: todayData?.astral?.sunrise?.hour })}:{createTime({ time: todayData?.astral?.sunrise?.minute })}
                                    </MarginItems>

                                    <MarginItems>
                                        <FaSun className='icon-margin' />
                                        {createTime({ time: todayData?.astral?.noon?.hour })}:{createTime({ time: todayData?.astral?.noon?.minute })}
                                    </MarginItems>

                                    <MarginItems>
                                        <GiSunset className='icon-margin' />
                                        {createTime({ time: todayData?.astral?.sunset?.hour })}:{createTime({ time: todayData?.astral?.sunset?.minute })}
                                    </MarginItems>
                                </IconWithTime>
                            </TimelineWrapper>
                        </TopRightWrapper>
                    </TopWrapper>


                    <MidWrapper>
                        <UpcomingDaysWrapper siblingHeight={calendarHeight}>
                            <FixedTopic ref={fixedTopicRef}>
                                <UpcomingDays value={selectedUpcomingIndex} onChange={onSelectValueChanged}>
                                    <UpcomingDaysOption value={0}>
                                        Upcoming Days
                                    </UpcomingDaysOption>
                                    <UpcomingDaysOption value={1}>
                                        All Days
                                    </UpcomingDaysOption>
                                </UpcomingDays>
                                <HR />
                            </FixedTopic>
                            <ScrollDays fixedTopicHeight={fixedTopicHeight}>
                                {
                                    calendarData.length > 0 ? (
                                        calendarData.filter(item => (item.events.length > 0 && item.bsYear === currentCalendarData[0]?.bsYear)).map((item, index) => (
                                            <>
                                                {
                                                    selectedUpcomingIndex == 0 ? (checkTimePeriod({ item: item }) >= 0 && checkTimePeriod({ item: item }) <= 30) ? (<div>
                                                        <DayWrapper>
                                                            <LeftDayWrapper>
                                                                <EventName>{item.events[0].strNp}</EventName>
                                                                <EventDate>{item.bsMonthStrNp} {item.bsDayNp}, {item.bsYearNp} - {item.adMonthStrEn.slice(0, 3)} {item.adDay}, {item.adYear}</EventDate>
                                                            </LeftDayWrapper>
                                                            <RightDayWrapper>
                                                                <DayContainer>{Math.abs(checkTimePeriod({ item: item }))}</DayContainer>
                                                                <DayContainer light={true}>{checkTimePeriod({ item: item }) < 0 ? 'Days ago' : checkTimePeriod({ item: item }) > 0 ? 'Days left' : 'Today'}</DayContainer>
                                                            </RightDayWrapper>
                                                        </DayWrapper>
                                                        <HR bottom={true} />
                                                    </div>) : (<></>) : (
                                                        <div>
                                                            <DayWrapper>
                                                                <LeftDayWrapper>
                                                                    <EventName>{item.events[0].strNp}</EventName>
                                                                    <EventDate>{item.bsMonthStrNp} {item.bsDayNp}, {item.bsYearNp} - {item.adMonthStrEn.slice(0, 3)} {item.adDay}, {item.adYear}</EventDate>
                                                                </LeftDayWrapper>
                                                                <RightDayWrapper>
                                                                    <DayContainer>{Math.abs(checkTimePeriod({ item: item }))}</DayContainer>
                                                                    <DayContainer light={true}>{checkTimePeriod({ item: item }) < 0 ? 'Days Ago' : checkTimePeriod({ item: item }) > 0 ? 'Days Left' : 'Today'}</DayContainer>
                                                                </RightDayWrapper>
                                                            </DayWrapper>
                                                            <HR bottom={true} />
                                                        </div>
                                                    )
                                                }
                                            </>
                                        ))
                                    ) : <></>
                                }
                            </ScrollDays>

                        </UpcomingDaysWrapper>
                        <CalendarWrapper ref={calendarRef}>
                            <CalendarTopWrapper>
                                <CalendarYearMonth>
                                    {currentCalendarData[0].bsMonthStrNp} {currentCalendarData[0].bsYearNp} | {[...new Set(currentCalendarData.map((item) => item.adMonthStrEn))].map((item, index) => index === 0 ? `${item}` : `/${item}`)} {currentCalendarData[0].adYear}
                                </CalendarYearMonth>



                                <CalendarBtnWrapper>

                                    <BtnFlexWrapper>

                                        <BtnFlex onClick={onPrevClick} disabled={calendarLoading}>
                                            <AiFillCaretLeft />
                                        </BtnFlex>
                                        <BtnFlex onClick={onCurrentMonthClick} disabled={calendarLoading}>
                                            Current Month
                                        </BtnFlex>
                                        <BtnFlex onClick={onNextClick} disabled={calendarLoading}>
                                            <AiFillCaretRight />
                                        </BtnFlex>

                                    </BtnFlexWrapper>



                                    <div>
                                        {/* <BtnFlexWrapper>
                                            <BtnFlex>
                                                Subha Sait
                                            </BtnFlex>
                                            <BtnFlex>
                                                Holiday
                                            </BtnFlex>

                                        </BtnFlexWrapper> */}

                                        <BtnFlexWrapper>


                                            <BtnFlex onClick={handleDateSelection}>
                                                Select Date
                                            </BtnFlex>
                                            <BtnFlex>
                                                Yearly
                                            </BtnFlex>

                                        </BtnFlexWrapper>
                                    </div>
                                </CalendarBtnWrapper>



                            </CalendarTopWrapper>

                            <CalendarDaysWrapper weekdays={true}>
                                {['Sunday', 'Monday', 'Tueday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((item) => (

                                    <WeekDaysName>{width > 768 ? item : item.slice(0, 3)}</WeekDaysName>
                                )
                                )}

                                {["आइतबार", "सोमबार", "मंगलबार", "बुधवार", "बिहिबार", "शुक्रबार", "शनिबार"].map((item) => (

                                    <WeekDaysName>{width > 768 ? item : item.slice(0, 3)}</WeekDaysName>
                                )
                                )}
                            </CalendarDaysWrapper>


                            <div>

                                {
                                    calendarLoading ? <div>Loading</div> :
                                        currentCalendarData.length > 0 ? (
                                            <CreateCalendar calendarData={currentCalendarData} />
                                        ) : <></>
                                }




                                {/* {Array(4).fill(1).map((item, index) => (

                <CalendarDaysWrapper>
                  {Array(7).fill(10).map((item, index2) => (

                    <CalendarDays selected={index === 0 && index2 === 1 ? true : false} isHoliday={index === index2 || index2 === 6 ? true : false}>
                      <TithiDays>
                        <Tithi>Ekadashi</Tithi>
                        <EngDate>{(index + 2) * (index2 + 1)}</EngDate>
                      </TithiDays>
                      <CurrentDay>{(index + 1) * (index2 + 1)}</CurrentDay>
                      <CalendarEventDays>
                        {index2 % 3 === 0 ? 'New Meterological Day' : index2 % 3 === 1 ? 'World Meteroday' : 'Holi Day'}
                      </CalendarEventDays>
                    </CalendarDays>
                  )
                  )}
                </CalendarDaysWrapper>
              )
              )} */}
                            </div>
                        </CalendarWrapper>
                    </MidWrapper>

                </HomeWrapper>
            )
            }



            <Modal show={show} handleClose={handleCloseModal} displayIndicators={activeTab === 0} onPrevClick={onModalPrevClick} onNextClick={onModalNextClick}>
                {(calendarLoading) ? (<p>Loading data ...</p>) : <></>}
                <Tabs active={activeTab} onTabChange={onTabChange} color={'red'}>
                    <Tabs.Tab label="Calendar Days" tabKey='calendar_days'>
                        <CalendarYearMonth>
                            {modalContent?.bsDayNp} {modalContent?.bsMonthStrNp}, {modalContent?.bsYearNp} |  {modalContent?.adDay} {modalContent?.adMonthStrEn}, {modalContent?.adYear}
                        </CalendarYearMonth>

                        <NormalFont>
                            {modalContent?.weekdayStrNp} - {modalContent?.tithiStrNp}
                        </NormalFont>


                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start" }}>
                            <IconWithTime>
                                <MarginItems right>
                                    <GiSunrise className='icon-margin' />
                                    {createTime({ time: modalContent?.astral?.sunrise?.hour })}:{createTime({ time: modalContent?.astral?.sunrise?.minute })}
                                </MarginItems>

                                <MarginItems right>
                                    <FaSun className='icon-margin' />
                                    {createTime({ time: modalContent?.astral?.noon?.hour })}:{createTime({ time: modalContent?.astral?.noon?.minute })}
                                </MarginItems>

                                <MarginItems right>
                                    <GiSunset className='icon-margin' />
                                    {createTime({ time: modalContent?.astral?.sunset?.hour })}:{createTime({ time: modalContent?.astral?.sunset?.minute })}
                                </MarginItems>
                            </IconWithTime>
                        </div>

                        <HR bottom={true} />

                        <CalendarYearMonth>
                            विशेष दिनहरु
                        </CalendarYearMonth>
                        <ul style={{ marginLeft: '1.5rem', fontSize: '1.2rem' }}>
                            {
                                modalContent?.events.map((item, index) => {
                                    return <li style={{ fontSize: '0.9rem' }}>{`${item.strNp}`}</li>
                                })

                            }
                        </ul>
                    </Tabs.Tab>
                    <Tabs.Tab label="Subhasait" tabKey="subhasait">
                        Subha Sait
                    </Tabs.Tab>
                    <Tabs.Tab label="Holidays" tabKey="holidays">
                        Holidays
                    </Tabs.Tab>

                </Tabs>
            </Modal>

            <Footer />
            <MobileNavigation />
        </div>
    )
}

export default Home