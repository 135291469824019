import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";

export const MobileNavigationWrapper = styled.div`
        width: 100%;
        position: fixed;
        background: #F0F0F0;
        padding: 10px;
        color: #fff;
        bottom: 0;
        color: white;
        justify-content: space-evenly;
        display: none;

    @media (max-width: 768px){
        display: flex ;
    }

`


export const IconWithTextLink = styled(Link)`
    color: #700606;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.9em;
    text-decoration: none;
    color: ${props => props.match ? COLORS.primary : 'black'};

    &:hover{
    color: ${COLORS.primary};
    }

       @media (max-width: 380px){
        
    font-size: 4vw;
    }
`