import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";
import { AiOutlineCloseCircle, AiFillRightCircle, AiFillLeftCircle } from 'react-icons/ai';

export const ModalWrapper = styled.div`
	z-index: 998;
    display: ${props => props.show ? 'block' : 'none'};
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width:100vw;
	background: rgba(0,0,0,0.5);
    overflow: hidden;
    backdrop-filter: blur(2px);
    background-attachment: fixed;
`


export const MainModal = styled.div`
    /* position: fixed; */
    background: white;
    padding: 2rem 3rem;
    width: 60%;
    filter: drop-shadow(0px 4px 4px #FBEAEA);
    border-radius: 8px;
    margin: 10vh auto;
    min-height: 50vh;
    max-height: 70vh;
    overflow-y: auto;
    z-index: 999;

        @media (max-width: 768px){
        width: 80%;
        min-height: 70vh;
    }
`

export const FixedBtn = styled(AiOutlineCloseCircle)`
    font-size: 2vw;
    position: absolute;
    right: 1%;
    top: 2%;
    /* right: 12%; */
    cursor: pointer;
    /* background: red; */
    color: red;
    /* padding: 0.5rem; */

    @media (max-width: 768px){
        font-size: 6vw;
    }
`

export const FixedNavLeftBtn = styled(AiFillLeftCircle)`
    font-size: 2vw;
    position: absolute;
    left: 0.5%;
    top: 50%;
    /* right: 12%; */
    cursor: pointer;
    /* background: red; */
    color: #808080;
    /* padding: 0.5rem; */

    @media (max-width: 768px){
        font-size: 6vw;
    }
`

export const FixedNavRightBtn = styled(AiFillRightCircle)`
    font-size: 2vw;
    position: absolute;
    right: 0.5%;
    top: 50%;
    /* right: 12%; */
    cursor: pointer;
    /* background: red; */
    color: #808080;
    /* padding: 0.5rem; */

    @media (max-width: 768px){
        font-size: 6vw;
    }
`