import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";

export const HomeWrapper = styled.div`
    /* background: #F0F0F0; */
    /* max-width: 1400px; */
    
    /* min-height: 100vh; */
    width: 98vw;
	margin: 0 auto; 
	float: none; 
    min-height: calc(100vh - 125px);
    /* background: yellow; */
    /* padding: 26px 0; */
`


export const TopWrapper = styled.div`
    /* max-width: 1400px; */
    width: 98vw;
	margin: 25px auto; 
    padding: 25px 42px;  
    background: white;
    filter: drop-shadow(0px 4px 4px #FBEAEA);
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-radius: 8px;

    @media (max-width: 768px){
        grid-template-columns: none;
        grid-template-rows: 2fr 1fr;
        padding: 15px 30px;  
    }
    @media (max-width: 576px){
       padding: 4px;
    }
`

export const TopLeftWrapper = styled.div`
    /* background: red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const DateWrapper = styled.div`
    display: flex;
     width: 100px;
     height: 100px;
     justify-content: center;
     align-items: center;
     border-radius: 50px;
     background: white;
     font-size: 48px;
     font-weight: bold;
    /* font-family: 'Montserrat', sans-serif; */
    color: ${COLORS.primary};
    filter: drop-shadow(0px 4px 4px #FBEAEA);

    @media (max-width: 768px){
        font-size: 24px;
        width: 120px;
        height: 100px;
    }

    @media (max-width: 576px){
        font-size: 18px;
        width: 20vw;
        height: 20vw;
    }
`

export const DateTimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 60px;
    /* background: yellow; */

        @media (max-width: 576px){
        margin-left: 20px;
    }
`








// export const TopWrapper = styled.div`
//     padding: 25px 42px;  
//     background: white;
//     filter: drop-shadow(0px 4px 4px #FBEAEA);
//     display: grid;
//     grid-template-columns: 2fr 1fr;
//     border-radius: 8px;

//     @media (max-width: 768px){
//         grid-template-columns: none;
//         grid-template-rows: 2fr 1fr;
//         padding: 15px 30px;  
//     }
//     @media (max-width: 576px){
//        padding: 4px;
//     }
// `

// export const TopLeftWrapper = styled.div`
//     /* background: red; */
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
// `

// export const DateWrapper = styled.div`
//     display: flex;
//      width: 100px;
//      height: 100px;
//      justify-content: center;
//      align-items: center;
//      border-radius: 50px;
//      background: white;
//      font-size: 48px;
//      font-weight: bold;
//      color: red;
//     filter: drop-shadow(0px 4px 4px #FBEAEA);

//     @media (max-width: 768px){
//         font-size: 24px;
//         width: 120px;
//         height: 100px;
//     }

//     @media (max-width: 576px){
//         font-size: 18px;
//         width: 90px;
//         height: 80px;
//     }
// `

// export const DateTimeWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: space-evenly;
//     margin-left: 60px;
//     /* background: yellow; */

//         @media (max-width: 576px){
//         margin-left: 20px;
//     }
// `

export const SemiBoldFont = styled.h1`
  font-family: 'Nunito Semibold', sans-serif;
  /* font-weight: 600; */
  display: ${props => props.small ? 'none' : 'block'};
  font-size: ${props => props.fontsize ? props.fontsize : '36px'};
  /* font-weight: 600; */

    @media (max-width: 768px){
        display: ${props => props.big ? 'none' : 'block'};
        /* font-size: ${props => props.fontsize ? `calc(${props.fontsize} - 10px);` : '3vw'}; */
        font-size: ${props => props.fontsize ? '4vw' : '5vw'};
    }
`

export const NormalFont = styled.p`
    font-size: 18px;

    @media (max-width: 768px){
        /* font-size: ${props => props.fontsize ? `calc(${props.fontsize} - 10px);` : '12px'}; */
        font-size: ${props => props.fontsize ? '2.5vw' : '3vw'};
   }
`


export const TopRightWrapper = styled.div`
    /* background: green; */
    display: flex;  
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    @media (max-width: 768px){
        align-items: flex-start;
        margin-left: 180px;
    }

    @media (max-width: 576px){
        margin-left: 110px;
    }
    
    @media (max-width: 380px){
        /* justify-content: space-around; */
        margin-left: 25vw;
    }
`


export const TimelineWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 768px){
        justify-content: flex-start;
    }

    @media (max-width: 380px){
        justify-content: space-around;
    }
    
`

export const IconWithTime = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    /* background: green; */

       @media (max-width: 576px){
           display: grid;
           grid-template-columns: 1fr 1fr;
           grid-template-rows: 1fr 1fr;
    }

        @media (max-width: 380px){
           grid-template-columns: 1fr;
           grid-template-rows: none;
    }
`

export const MarginItems = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-left: ${props => props.right ? '0px' : '20px'} ;
    margin-right: ${props => props.right ? '20px' : '0px'} ;
    font-size: 18px;

    @media (max-width: 768px){
        font-size: 12px;
        margin-left: 0px;
        margin-right: 20px;
    }

       @media (max-width: 768px){
        font-size: 4vw;
    }
`

export const MidWrapper = styled.div`

    display: flex;
    /* grid-template-columns: 2fr 3fr; */
    column-gap: 20px;

    /* background: red; */
    margin: 20px 0px;

    @media (max-width: 1020px){
        row-gap: 20px;
        flex-direction: column-reverse;
        column-gap: 0px;
        margin:  0px;
    }

        @media (max-width: 768px){
        margin-bottom: 2.5vh;
    }

`

export const UpcomingDaysWrapper = styled.div`
    flex: 3;
    margin: 0 auto;
    width: 100%;
    min-height: calc((100vw/7)*3);
    height: ${props => props.siblingHeight ? `${props.siblingHeight}px` : '20px'} ;
    background: white;
    filter: drop-shadow(0px 4px 4px #FBEAEA);
`

export const UpcomingDays = styled.select`
    /* background: red; */
    /* width: 60%; */
    
    margin: auto 40px !important;

    align-items: center;
    /* font-family: 'Nunito Semibold', sans-serif; */
    font-weight: 600;
    background: none;

    font-size: 24px;
    border: none;
    padding: 0 2vw 0 0;

    color: red;
    appearance: none;
    width: fit-content;
    font-size: 1.5vw;
    padding-right: 2vw;
    background-image: url("./caretdownred.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 4px) center;
    background-size: 1vw;

    color: #F64040;
    /* background: white; */
    z-index: 2;

    @media (max-width: 576px){
        font-size: 3.5vw;
        padding-right: 6vw;
        background-size: 2.5vw;
        margin: 35px 4px 0px 4px !important;
    }

`

export const UpcomingDaysOption = styled.option`
    font-size: 18px;
    outline: none;
    font-weight: 500;

            @media (max-width: 576px){
       font-size: 10px;
    }
`




export const HR = styled.hr`
    background: #FBEAEA;
    margin-top: ${props => props.bottom ? '6px' : '20px'};
    margin-bottom: ${props => props.bottom ? '20px' : '0px'};
`

export const FixedTopic = styled.div`
/* height: 4vh; */
/* background: green; */
margin: 1vh auto;


`

export const ScrollDays = styled.div`
    height: ${props => props.fixedTopicHeight ? `calc(100% - ${props.fixedTopicHeight + 80}px)` : 'calc(100% - 30px)'} ;

    /* height: ${props => props.siblingHeight ? `${props.siblingHeight}px` : '20px'} ; */
    padding: 0px 10px;
    overflow-y:scroll;


    @media (max-width: 1400px){
              height: ${props => props.fixedTopicHeight ? `calc(100% - ${props.fixedTopicHeight + 45}px)` : 'calc(100% - 30px)'} ;

        padding: 0px 4px;
    }


    @media (max-width: 768px){
              height: ${props => props.fixedTopicHeight ? `calc(100% - ${props.fixedTopicHeight}px)` : 'calc(100% - 30px)'} ;

        padding: 0px 4px;
    }

    @media (max-width: 576px){              height: ${props => props.fixedTopicHeight ? `calc(100% - ${props.fixedTopicHeight}px)` : 'calc(100% - 30px)'} ;

        padding: 0px 4px;
    }
`

export const DayWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* margin-top: 20px; */
    column-gap: 10px;
    padding: 10px 10px 10px 40px;

    
            @media (max-width: 576px){
                padding: 8px 4px;
    }
`

export const LeftDayWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const EventName = styled.p`
    font-weight: 600;
    font-size: 18px;
    color: ${COLORS.primary};

          @media (max-width: 576px){
       font-size: 10px;
    }
`

export const EventDate = styled.p`
    color: #BA9E9E;
    font-size: 18px;

          @media (max-width: 576px){
       font-size: 10px;
    }
`

export const RightDayWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`

export const DayContainer = styled.p`
    font-weight: ${props => props.light ? '400' : '600'};
    color: ${COLORS.primary};
    font-size: ${props => props.light ? '16px' : '18px'};
    text-align: center;
    margin: auto;

          @media (max-width: 576px){
       font-size: 12px;
    }
`



// Calendar

export const CalendarWrapper = styled.div`

    flex: 7;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    background: white;
    filter: drop-shadow(0px 4px 4px #FBEAEA);
    padding: 10px 0;
    
    overflow: hidden;

    /* @media (max-width: 768px){
        display:none ;
    } */
`

export const CalendarTopWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    /* background: yellow; */
    flex-direction: column;
    padding: 0.5vw 1vw;


            @media (max-width:  1020px){
         width: 90vw;
    /* border: 1px solid green; */
    }

    @media (max-width:  768px){
         
    width: 98vw;
    /* border: 1px solid green; */
    }

    @media (max-width: 576px){ 
        width: 100%;
    }

`

export const CalendarYearMonth = styled.p`
  /* font-family: 'Nunito Semibold', sans-serif; */
  font-weight: 600;
  /* background: green; */
  font-size: 26px;

    @media (max-width: 768px){
        font-size: 5vw;
    }

  /* background: green; */
`

export const CalendarBtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

export const BtnFlexWrapper = styled.div`
    margin: 1em 0em;
    display: flex;
    justify-content:center;
    /* justify-content: ${props => props.end ? 'flex-end' : 'flex-start'}; */
    align-items: center;
`

export const BtnFlex = styled.button`
    border: none;
    /* font-family: 'Nunito Semibold', sans-serif; */
    font-weight: 600;
    font-size: 18px;
    padding: 4px 1em;
    background: #F0F0F0;
    border-radius: 8px;
    color: ${COLORS.primary};
    margin-right: 0.5em;
    justify-content: center;
    text-align:center;
    cursor: pointer;
    user-select: none;


    @media (max-width: 768px){ 
    /* border: 1px solid green; */
    font-size: 2.5vw;
    padding: 0.5vw 2vw;
    }
    /* margin-left: ${props => props.end ? '0.5em' : '0'};
    margin-right: ${props => props.end ? '0' : '0.5em'}; */
`



export const CalendarDaysWrapper = styled.div`
    display: grid;
    grid-template-columns:repeat(7, calc(65vw / 7));
    text-align: center;
    border: ${props => props.weekdays ? '1px solid #FBEAEA' : 'none'};

            @media (max-width:  1020px){
         
    grid-template-columns:repeat(7, calc(90vw / 7));
    /* border: 1px solid green; */
    }

    @media (max-width:  768px){
         
    grid-template-columns:repeat(7, calc(98vw / 7));
    /* border: 1px solid green; */
    }

    @media (max-width: 576px){ 
        grid-template-columns:repeat(7,  calc(100% / 7));
    /* border: 1px solid green; */
    }

    

`

export const WeekDaysName = styled.p`
    font-size: 1.2vw;
    overflow: hidden;
    font-weight: 600;

    @media (max-width: 1028px){
        width: calc(98vw / 7);
        font-size: 1.8vw;
    }

    @media (max-width: 768px){ 
        width: calc(98vw / 7);
        font-size: 2.5vw;
    }
`

export const CalendarDays = styled.div`
    height: calc(65vw / 7);
    width: calc(65vw / 7);
    border: 1px solid #FBEAEA;
    display: grid;
    grid-columns-template: repeat(3, 1fr);
    user-select: none;
    cursor: pointer;
    
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: 1.5vw;
    background: ${props => props.selected ? '#FBEAEA' : 'white'};
    color: ${props => props.isHoliday ? 'red' : 'black'};

    @media (max-width: 1020px){
        height: calc(90vw / 7);
        width: calc(90vw / 7);
    }


    @media (max-width: 768px){
        height: calc(98vw / 7);
        width: calc(98vw / 7);
    }

        @media (max-width: 576px){
            display: flex;
            flex-direction: column;
    }
`

export const TithiDays = styled.div`
    /* position: relative; */
    display: flex;
    justify-content: space-between;
    width: calc(65vw / 7);
    /* background: red; */
    /* top: 0;     */
    font-weight: 500;
    font-size: 0.9vw;
    padding: 4px 8px;

     @media (max-width:  1020px){
        width: calc(90vw / 7);
    }

     @media (max-width:  768px){
        width: calc(98vw / 7);
        padding: 4px;
    }

    @media (max-width: 576px){
        padding: 2px;
        justify-content: flex-end;
    }
`

export const Tithi = styled.p`
    font-weight: 500;
    font-size: 0.9vw;
    /* background: yellow; */

        @media (max-width: 768px){
        
    font-size: 1.5vw;
    }

        @media (max-width: 576px){
        display: none;
    }

`

export const EngDate = styled.div`
    font-weight: 500;
    font-size: 0.9vw;

            @media (max-width: 768px){
        
    font-size: 1.5vw;
    }

            @media (max-width: 576px){
       
    font-size: 2vw;
    }
`

export const CalendarEventDays = styled.div`
    /* position: relative; */
    /* bottom: 10%;     */
    font-weight: 700;
    font-size: 0.75vw;
    padding: 4px 8px;
    width: 100%;

    text-overflow: ellipsis;

    /* Needed to make it work */
    overflow: hidden;
    white-space: nowrap;
    
    @media (max-width: 768px){        
        font-size: 1.2vw;
    }

    @media (max-width: 576px){
        display: none;
    }

`

export const CurrentDay = styled.p`
    width: 100%;
    font-size: 2vw;
    font-weight:600;
    /* font-family: 'Montserrat', sans-serif; */

        @media (max-width: 768px){        
        font-size: 3vw;
    }

    @media (max-width: 576px){
        font-size: 4vw;
    }
`
