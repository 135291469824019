import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";

export const FooterWrapper = styled.div`

        background: #FBEAEA;
        color: ${COLORS.primary};
        justify-content: space-evenly;
        display: flex;
        margin-top: 20px;


    width: 100%;
    /* background: green; */
    padding: 8px 4vw;
    filter: drop-shadow(-2px 0px 4px ${COLORS.bgVariant});
    display: flex;
    justify-content: space-between;
    align-items: center;


        @media (max-width: 768px){
        display: none ;
    }
`

export const FooterLinks = styled.div`
    display: flex;
    /* background: green; */
`

export const FooterLink = styled(Link)`
    margin-left: 1vw;
    cursor: pointer;
    color: black;
    text-decoration: none;
`