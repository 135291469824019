export const DATE_CONVERSION = [
    {
        "engDate": "1918-04-13",
        "nepDate": "01.01.1975"
    },
    {
        "engDate": "1918-04-14",
        "nepDate": "02.01.1975"
    },
    {
        "engDate": "1918-04-15",
        "nepDate": "03.01.1975"
    },
    {
        "engDate": "1918-04-16",
        "nepDate": "04.01.1975"
    },
    {
        "engDate": "1918-04-17",
        "nepDate": "05.01.1975"
    },
    {
        "engDate": "1918-04-18",
        "nepDate": "06.01.1975"
    },
    {
        "engDate": "1918-04-19",
        "nepDate": "07.01.1975"
    },
    {
        "engDate": "1918-04-20",
        "nepDate": "08.01.1975"
    },
    {
        "engDate": "1918-04-21",
        "nepDate": "09.01.1975"
    },
    {
        "engDate": "1918-04-22",
        "nepDate": "10.01.1975"
    },
    {
        "engDate": "1918-04-23",
        "nepDate": "11.01.1975"
    },
    {
        "engDate": "1918-04-24",
        "nepDate": "12.01.1975"
    },
    {
        "engDate": "1918-04-25",
        "nepDate": "13.01.1975"
    },
    {
        "engDate": "1918-04-26",
        "nepDate": "14.01.1975"
    },
    {
        "engDate": "1918-04-27",
        "nepDate": "15.01.1975"
    },
    {
        "engDate": "1918-04-28",
        "nepDate": "16.01.1975"
    },
    {
        "engDate": "1918-04-29",
        "nepDate": "17.01.1975"
    },
    {
        "engDate": "1918-04-30",
        "nepDate": "18.01.1975"
    },
    {
        "engDate": "1918-05-01",
        "nepDate": "19.01.1975"
    },
    {
        "engDate": "1918-05-02",
        "nepDate": "20.01.1975"
    },
    {
        "engDate": "1918-05-03",
        "nepDate": "21.01.1975"
    },
    {
        "engDate": "1918-05-04",
        "nepDate": "22.01.1975"
    },
    {
        "engDate": "1918-05-05",
        "nepDate": "23.01.1975"
    },
    {
        "engDate": "1918-05-06",
        "nepDate": "24.01.1975"
    },
    {
        "engDate": "1918-05-07",
        "nepDate": "25.01.1975"
    },
    {
        "engDate": "1918-05-08",
        "nepDate": "26.01.1975"
    },
    {
        "engDate": "1918-05-09",
        "nepDate": "27.01.1975"
    },
    {
        "engDate": "1918-05-10",
        "nepDate": "28.01.1975"
    },
    {
        "engDate": "1918-05-11",
        "nepDate": "29.01.1975"
    },
    {
        "engDate": "1918-05-12",
        "nepDate": "30.01.1975"
    },
    {
        "engDate": "1918-05-13",
        "nepDate": "31.01.1975"
    },
    {
        "engDate": "1918-05-14",
        "nepDate": "01.02.1975"
    },
    {
        "engDate": "1918-05-15",
        "nepDate": "02.02.1975"
    },
    {
        "engDate": "1918-05-16",
        "nepDate": "03.02.1975"
    },
    {
        "engDate": "1918-05-17",
        "nepDate": "04.02.1975"
    },
    {
        "engDate": "1918-05-18",
        "nepDate": "05.02.1975"
    },
    {
        "engDate": "1918-05-19",
        "nepDate": "06.02.1975"
    },
    {
        "engDate": "1918-05-20",
        "nepDate": "07.02.1975"
    },
    {
        "engDate": "1918-05-21",
        "nepDate": "08.02.1975"
    },
    {
        "engDate": "1918-05-22",
        "nepDate": "09.02.1975"
    },
    {
        "engDate": "1918-05-23",
        "nepDate": "10.02.1975"
    },
    {
        "engDate": "1918-05-24",
        "nepDate": "11.02.1975"
    },
    {
        "engDate": "1918-05-25",
        "nepDate": "12.02.1975"
    },
    {
        "engDate": "1918-05-26",
        "nepDate": "13.02.1975"
    },
    {
        "engDate": "1918-05-27",
        "nepDate": "14.02.1975"
    },
    {
        "engDate": "1918-05-28",
        "nepDate": "15.02.1975"
    },
    {
        "engDate": "1918-05-29",
        "nepDate": "16.02.1975"
    },
    {
        "engDate": "1918-05-30",
        "nepDate": "17.02.1975"
    },
    {
        "engDate": "1918-05-31",
        "nepDate": "18.02.1975"
    },
    {
        "engDate": "1918-06-01",
        "nepDate": "19.02.1975"
    },
    {
        "engDate": "1918-06-02",
        "nepDate": "20.02.1975"
    },
    {
        "engDate": "1918-06-03",
        "nepDate": "21.02.1975"
    },
    {
        "engDate": "1918-06-04",
        "nepDate": "22.02.1975"
    },
    {
        "engDate": "1918-06-05",
        "nepDate": "23.02.1975"
    },
    {
        "engDate": "1918-06-06",
        "nepDate": "24.02.1975"
    },
    {
        "engDate": "1918-06-07",
        "nepDate": "25.02.1975"
    },
    {
        "engDate": "1918-06-08",
        "nepDate": "26.02.1975"
    },
    {
        "engDate": "1918-06-09",
        "nepDate": "27.02.1975"
    },
    {
        "engDate": "1918-06-10",
        "nepDate": "28.02.1975"
    },
    {
        "engDate": "1918-06-11",
        "nepDate": "29.02.1975"
    },
    {
        "engDate": "1918-06-12",
        "nepDate": "30.02.1975"
    },
    {
        "engDate": "1918-06-13",
        "nepDate": "31.02.1975"
    },
    {
        "engDate": "1918-06-14",
        "nepDate": "01.03.1975"
    },
    {
        "engDate": "1918-06-15",
        "nepDate": "02.03.1975"
    },
    {
        "engDate": "1918-06-16",
        "nepDate": "03.03.1975"
    },
    {
        "engDate": "1918-06-17",
        "nepDate": "04.03.1975"
    },
    {
        "engDate": "1918-06-18",
        "nepDate": "05.03.1975"
    },
    {
        "engDate": "1918-06-19",
        "nepDate": "06.03.1975"
    },
    {
        "engDate": "1918-06-20",
        "nepDate": "07.03.1975"
    },
    {
        "engDate": "1918-06-21",
        "nepDate": "08.03.1975"
    },
    {
        "engDate": "1918-06-22",
        "nepDate": "09.03.1975"
    },
    {
        "engDate": "1918-06-23",
        "nepDate": "10.03.1975"
    },
    {
        "engDate": "1918-06-24",
        "nepDate": "11.03.1975"
    },
    {
        "engDate": "1918-06-25",
        "nepDate": "12.03.1975"
    },
    {
        "engDate": "1918-06-26",
        "nepDate": "13.03.1975"
    },
    {
        "engDate": "1918-06-27",
        "nepDate": "14.03.1975"
    },
    {
        "engDate": "1918-06-28",
        "nepDate": "15.03.1975"
    },
    {
        "engDate": "1918-06-29",
        "nepDate": "16.03.1975"
    },
    {
        "engDate": "1918-06-30",
        "nepDate": "17.03.1975"
    },
    {
        "engDate": "1918-07-01",
        "nepDate": "18.03.1975"
    },
    {
        "engDate": "1918-07-02",
        "nepDate": "19.03.1975"
    },
    {
        "engDate": "1918-07-03",
        "nepDate": "20.03.1975"
    },
    {
        "engDate": "1918-07-04",
        "nepDate": "21.03.1975"
    },
    {
        "engDate": "1918-07-05",
        "nepDate": "22.03.1975"
    },
    {
        "engDate": "1918-07-06",
        "nepDate": "23.03.1975"
    },
    {
        "engDate": "1918-07-07",
        "nepDate": "24.03.1975"
    },
    {
        "engDate": "1918-07-08",
        "nepDate": "25.03.1975"
    },
    {
        "engDate": "1918-07-09",
        "nepDate": "26.03.1975"
    },
    {
        "engDate": "1918-07-10",
        "nepDate": "27.03.1975"
    },
    {
        "engDate": "1918-07-11",
        "nepDate": "28.03.1975"
    },
    {
        "engDate": "1918-07-12",
        "nepDate": "29.03.1975"
    },
    {
        "engDate": "1918-07-13",
        "nepDate": "30.03.1975"
    },
    {
        "engDate": "1918-07-14",
        "nepDate": "31.03.1975"
    },
    {
        "engDate": "1918-07-15",
        "nepDate": "32.03.1975"
    },
    {
        "engDate": "1918-07-16",
        "nepDate": "01.04.1975"
    },
    {
        "engDate": "1918-07-17",
        "nepDate": "02.04.1975"
    },
    {
        "engDate": "1918-07-18",
        "nepDate": "03.04.1975"
    },
    {
        "engDate": "1918-07-19",
        "nepDate": "04.04.1975"
    },
    {
        "engDate": "1918-07-20",
        "nepDate": "05.04.1975"
    },
    {
        "engDate": "1918-07-21",
        "nepDate": "06.04.1975"
    },
    {
        "engDate": "1918-07-22",
        "nepDate": "07.04.1975"
    },
    {
        "engDate": "1918-07-23",
        "nepDate": "08.04.1975"
    },
    {
        "engDate": "1918-07-24",
        "nepDate": "09.04.1975"
    },
    {
        "engDate": "1918-07-25",
        "nepDate": "10.04.1975"
    },
    {
        "engDate": "1918-07-26",
        "nepDate": "11.04.1975"
    },
    {
        "engDate": "1918-07-27",
        "nepDate": "12.04.1975"
    },
    {
        "engDate": "1918-07-28",
        "nepDate": "13.04.1975"
    },
    {
        "engDate": "1918-07-29",
        "nepDate": "14.04.1975"
    },
    {
        "engDate": "1918-07-30",
        "nepDate": "15.04.1975"
    },
    {
        "engDate": "1918-07-31",
        "nepDate": "16.04.1975"
    },
    {
        "engDate": "1918-08-01",
        "nepDate": "17.04.1975"
    },
    {
        "engDate": "1918-08-02",
        "nepDate": "18.04.1975"
    },
    {
        "engDate": "1918-08-03",
        "nepDate": "19.04.1975"
    },
    {
        "engDate": "1918-08-04",
        "nepDate": "20.04.1975"
    },
    {
        "engDate": "1918-08-05",
        "nepDate": "21.04.1975"
    },
    {
        "engDate": "1918-08-06",
        "nepDate": "22.04.1975"
    },
    {
        "engDate": "1918-08-07",
        "nepDate": "23.04.1975"
    },
    {
        "engDate": "1918-08-08",
        "nepDate": "24.04.1975"
    },
    {
        "engDate": "1918-08-09",
        "nepDate": "25.04.1975"
    },
    {
        "engDate": "1918-08-10",
        "nepDate": "26.04.1975"
    },
    {
        "engDate": "1918-08-11",
        "nepDate": "27.04.1975"
    },
    {
        "engDate": "1918-08-12",
        "nepDate": "28.04.1975"
    },
    {
        "engDate": "1918-08-13",
        "nepDate": "29.04.1975"
    },
    {
        "engDate": "1918-08-14",
        "nepDate": "30.04.1975"
    },
    {
        "engDate": "1918-08-15",
        "nepDate": "31.04.1975"
    },
    {
        "engDate": "1918-08-16",
        "nepDate": "32.04.1975"
    },
    {
        "engDate": "1918-08-17",
        "nepDate": "01.05.1975"
    },
    {
        "engDate": "1918-08-18",
        "nepDate": "02.05.1975"
    },
    {
        "engDate": "1918-08-19",
        "nepDate": "03.05.1975"
    },
    {
        "engDate": "1918-08-20",
        "nepDate": "04.05.1975"
    },
    {
        "engDate": "1918-08-21",
        "nepDate": "05.05.1975"
    },
    {
        "engDate": "1918-08-22",
        "nepDate": "06.05.1975"
    },
    {
        "engDate": "1918-08-23",
        "nepDate": "07.05.1975"
    },
    {
        "engDate": "1918-08-24",
        "nepDate": "08.05.1975"
    },
    {
        "engDate": "1918-08-25",
        "nepDate": "09.05.1975"
    },
    {
        "engDate": "1918-08-26",
        "nepDate": "10.05.1975"
    },
    {
        "engDate": "1918-08-27",
        "nepDate": "11.05.1975"
    },
    {
        "engDate": "1918-08-28",
        "nepDate": "12.05.1975"
    },
    {
        "engDate": "1918-08-29",
        "nepDate": "13.05.1975"
    },
    {
        "engDate": "1918-08-30",
        "nepDate": "14.05.1975"
    },
    {
        "engDate": "1918-08-31",
        "nepDate": "15.05.1975"
    },
    {
        "engDate": "1918-09-01",
        "nepDate": "16.05.1975"
    },
    {
        "engDate": "1918-09-02",
        "nepDate": "17.05.1975"
    },
    {
        "engDate": "1918-09-03",
        "nepDate": "18.05.1975"
    },
    {
        "engDate": "1918-09-04",
        "nepDate": "19.05.1975"
    },
    {
        "engDate": "1918-09-05",
        "nepDate": "20.05.1975"
    },
    {
        "engDate": "1918-09-06",
        "nepDate": "21.05.1975"
    },
    {
        "engDate": "1918-09-07",
        "nepDate": "22.05.1975"
    },
    {
        "engDate": "1918-09-08",
        "nepDate": "23.05.1975"
    },
    {
        "engDate": "1918-09-09",
        "nepDate": "24.05.1975"
    },
    {
        "engDate": "1918-09-10",
        "nepDate": "25.05.1975"
    },
    {
        "engDate": "1918-09-11",
        "nepDate": "26.05.1975"
    },
    {
        "engDate": "1918-09-12",
        "nepDate": "27.05.1975"
    },
    {
        "engDate": "1918-09-13",
        "nepDate": "28.05.1975"
    },
    {
        "engDate": "1918-09-14",
        "nepDate": "29.05.1975"
    },
    {
        "engDate": "1918-09-15",
        "nepDate": "30.05.1975"
    },
    {
        "engDate": "1918-09-16",
        "nepDate": "31.05.1975"
    },
    {
        "engDate": "1918-09-17",
        "nepDate": "01.06.1975"
    },
    {
        "engDate": "1918-09-18",
        "nepDate": "02.06.1975"
    },
    {
        "engDate": "1918-09-19",
        "nepDate": "03.06.1975"
    },
    {
        "engDate": "1918-09-20",
        "nepDate": "04.06.1975"
    },
    {
        "engDate": "1918-09-21",
        "nepDate": "05.06.1975"
    },
    {
        "engDate": "1918-09-22",
        "nepDate": "06.06.1975"
    },
    {
        "engDate": "1918-09-23",
        "nepDate": "07.06.1975"
    },
    {
        "engDate": "1918-09-24",
        "nepDate": "08.06.1975"
    },
    {
        "engDate": "1918-09-25",
        "nepDate": "09.06.1975"
    },
    {
        "engDate": "1918-09-26",
        "nepDate": "10.06.1975"
    },
    {
        "engDate": "1918-09-27",
        "nepDate": "11.06.1975"
    },
    {
        "engDate": "1918-09-28",
        "nepDate": "12.06.1975"
    },
    {
        "engDate": "1918-09-29",
        "nepDate": "13.06.1975"
    },
    {
        "engDate": "1918-09-30",
        "nepDate": "14.06.1975"
    },
    {
        "engDate": "1918-10-01",
        "nepDate": "15.06.1975"
    },
    {
        "engDate": "1918-10-02",
        "nepDate": "16.06.1975"
    },
    {
        "engDate": "1918-10-03",
        "nepDate": "17.06.1975"
    },
    {
        "engDate": "1918-10-04",
        "nepDate": "18.06.1975"
    },
    {
        "engDate": "1918-10-05",
        "nepDate": "19.06.1975"
    },
    {
        "engDate": "1918-10-06",
        "nepDate": "20.06.1975"
    },
    {
        "engDate": "1918-10-07",
        "nepDate": "21.06.1975"
    },
    {
        "engDate": "1918-10-08",
        "nepDate": "22.06.1975"
    },
    {
        "engDate": "1918-10-09",
        "nepDate": "23.06.1975"
    },
    {
        "engDate": "1918-10-10",
        "nepDate": "24.06.1975"
    },
    {
        "engDate": "1918-10-11",
        "nepDate": "25.06.1975"
    },
    {
        "engDate": "1918-10-12",
        "nepDate": "26.06.1975"
    },
    {
        "engDate": "1918-10-13",
        "nepDate": "27.06.1975"
    },
    {
        "engDate": "1918-10-14",
        "nepDate": "28.06.1975"
    },
    {
        "engDate": "1918-10-15",
        "nepDate": "29.06.1975"
    },
    {
        "engDate": "1918-10-16",
        "nepDate": "30.06.1975"
    },
    {
        "engDate": "1918-10-17",
        "nepDate": "01.07.1975"
    },
    {
        "engDate": "1918-10-18",
        "nepDate": "02.07.1975"
    },
    {
        "engDate": "1918-10-19",
        "nepDate": "03.07.1975"
    },
    {
        "engDate": "1918-10-20",
        "nepDate": "04.07.1975"
    },
    {
        "engDate": "1918-10-21",
        "nepDate": "05.07.1975"
    },
    {
        "engDate": "1918-10-22",
        "nepDate": "06.07.1975"
    },
    {
        "engDate": "1918-10-23",
        "nepDate": "07.07.1975"
    },
    {
        "engDate": "1918-10-24",
        "nepDate": "08.07.1975"
    },
    {
        "engDate": "1918-10-25",
        "nepDate": "09.07.1975"
    },
    {
        "engDate": "1918-10-26",
        "nepDate": "10.07.1975"
    },
    {
        "engDate": "1918-10-27",
        "nepDate": "11.07.1975"
    },
    {
        "engDate": "1918-10-28",
        "nepDate": "12.07.1975"
    },
    {
        "engDate": "1918-10-29",
        "nepDate": "13.07.1975"
    },
    {
        "engDate": "1918-10-30",
        "nepDate": "14.07.1975"
    },
    {
        "engDate": "1918-10-31",
        "nepDate": "15.07.1975"
    },
    {
        "engDate": "1918-11-01",
        "nepDate": "16.07.1975"
    },
    {
        "engDate": "1918-11-02",
        "nepDate": "17.07.1975"
    },
    {
        "engDate": "1918-11-03",
        "nepDate": "18.07.1975"
    },
    {
        "engDate": "1918-11-04",
        "nepDate": "19.07.1975"
    },
    {
        "engDate": "1918-11-05",
        "nepDate": "20.07.1975"
    },
    {
        "engDate": "1918-11-06",
        "nepDate": "21.07.1975"
    },
    {
        "engDate": "1918-11-07",
        "nepDate": "22.07.1975"
    },
    {
        "engDate": "1918-11-08",
        "nepDate": "23.07.1975"
    },
    {
        "engDate": "1918-11-09",
        "nepDate": "24.07.1975"
    },
    {
        "engDate": "1918-11-10",
        "nepDate": "25.07.1975"
    },
    {
        "engDate": "1918-11-11",
        "nepDate": "26.07.1975"
    },
    {
        "engDate": "1918-11-12",
        "nepDate": "27.07.1975"
    },
    {
        "engDate": "1918-11-13",
        "nepDate": "28.07.1975"
    },
    {
        "engDate": "1918-11-14",
        "nepDate": "29.07.1975"
    },
    {
        "engDate": "1918-11-15",
        "nepDate": "30.07.1975"
    },
    {
        "engDate": "1918-11-16",
        "nepDate": "01.08.1975"
    },
    {
        "engDate": "1918-11-17",
        "nepDate": "02.08.1975"
    },
    {
        "engDate": "1918-11-18",
        "nepDate": "03.08.1975"
    },
    {
        "engDate": "1918-11-19",
        "nepDate": "04.08.1975"
    },
    {
        "engDate": "1918-11-20",
        "nepDate": "05.08.1975"
    },
    {
        "engDate": "1918-11-21",
        "nepDate": "06.08.1975"
    },
    {
        "engDate": "1918-11-22",
        "nepDate": "07.08.1975"
    },
    {
        "engDate": "1918-11-23",
        "nepDate": "08.08.1975"
    },
    {
        "engDate": "1918-11-24",
        "nepDate": "09.08.1975"
    },
    {
        "engDate": "1918-11-25",
        "nepDate": "10.08.1975"
    },
    {
        "engDate": "1918-11-26",
        "nepDate": "11.08.1975"
    },
    {
        "engDate": "1918-11-27",
        "nepDate": "12.08.1975"
    },
    {
        "engDate": "1918-11-28",
        "nepDate": "13.08.1975"
    },
    {
        "engDate": "1918-11-29",
        "nepDate": "14.08.1975"
    },
    {
        "engDate": "1918-11-30",
        "nepDate": "15.08.1975"
    },
    {
        "engDate": "1918-12-01",
        "nepDate": "16.08.1975"
    },
    {
        "engDate": "1918-12-02",
        "nepDate": "17.08.1975"
    },
    {
        "engDate": "1918-12-03",
        "nepDate": "18.08.1975"
    },
    {
        "engDate": "1918-12-04",
        "nepDate": "19.08.1975"
    },
    {
        "engDate": "1918-12-05",
        "nepDate": "20.08.1975"
    },
    {
        "engDate": "1918-12-06",
        "nepDate": "21.08.1975"
    },
    {
        "engDate": "1918-12-07",
        "nepDate": "22.08.1975"
    },
    {
        "engDate": "1918-12-08",
        "nepDate": "23.08.1975"
    },
    {
        "engDate": "1918-12-09",
        "nepDate": "24.08.1975"
    },
    {
        "engDate": "1918-12-10",
        "nepDate": "25.08.1975"
    },
    {
        "engDate": "1918-12-11",
        "nepDate": "26.08.1975"
    },
    {
        "engDate": "1918-12-12",
        "nepDate": "27.08.1975"
    },
    {
        "engDate": "1918-12-13",
        "nepDate": "28.08.1975"
    },
    {
        "engDate": "1918-12-14",
        "nepDate": "29.08.1975"
    },
    {
        "engDate": "1918-12-15",
        "nepDate": "01.09.1975"
    },
    {
        "engDate": "1918-12-16",
        "nepDate": "02.09.1975"
    },
    {
        "engDate": "1918-12-17",
        "nepDate": "03.09.1975"
    },
    {
        "engDate": "1918-12-18",
        "nepDate": "04.09.1975"
    },
    {
        "engDate": "1918-12-19",
        "nepDate": "05.09.1975"
    },
    {
        "engDate": "1918-12-20",
        "nepDate": "06.09.1975"
    },
    {
        "engDate": "1918-12-21",
        "nepDate": "07.09.1975"
    },
    {
        "engDate": "1918-12-22",
        "nepDate": "08.09.1975"
    },
    {
        "engDate": "1918-12-23",
        "nepDate": "09.09.1975"
    },
    {
        "engDate": "1918-12-24",
        "nepDate": "10.09.1975"
    },
    {
        "engDate": "1918-12-25",
        "nepDate": "11.09.1975"
    },
    {
        "engDate": "1918-12-26",
        "nepDate": "12.09.1975"
    },
    {
        "engDate": "1918-12-27",
        "nepDate": "13.09.1975"
    },
    {
        "engDate": "1918-12-28",
        "nepDate": "14.09.1975"
    },
    {
        "engDate": "1918-12-29",
        "nepDate": "15.09.1975"
    },
    {
        "engDate": "1918-12-30",
        "nepDate": "16.09.1975"
    },
    {
        "engDate": "1918-12-31",
        "nepDate": "17.09.1975"
    },
    {
        "engDate": "1919-01-01",
        "nepDate": "18.09.1975"
    },
    {
        "engDate": "1919-01-02",
        "nepDate": "19.09.1975"
    },
    {
        "engDate": "1919-01-03",
        "nepDate": "20.09.1975"
    },
    {
        "engDate": "1919-01-04",
        "nepDate": "21.09.1975"
    },
    {
        "engDate": "1919-01-05",
        "nepDate": "22.09.1975"
    },
    {
        "engDate": "1919-01-06",
        "nepDate": "23.09.1975"
    },
    {
        "engDate": "1919-01-07",
        "nepDate": "24.09.1975"
    },
    {
        "engDate": "1919-01-08",
        "nepDate": "25.09.1975"
    },
    {
        "engDate": "1919-01-09",
        "nepDate": "26.09.1975"
    },
    {
        "engDate": "1919-01-10",
        "nepDate": "27.09.1975"
    },
    {
        "engDate": "1919-01-11",
        "nepDate": "28.09.1975"
    },
    {
        "engDate": "1919-01-12",
        "nepDate": "29.09.1975"
    },
    {
        "engDate": "1919-01-13",
        "nepDate": "30.09.1975"
    },
    {
        "engDate": "1919-01-14",
        "nepDate": "01.10.1975"
    },
    {
        "engDate": "1919-01-15",
        "nepDate": "02.10.1975"
    },
    {
        "engDate": "1919-01-16",
        "nepDate": "03.10.1975"
    },
    {
        "engDate": "1919-01-17",
        "nepDate": "04.10.1975"
    },
    {
        "engDate": "1919-01-18",
        "nepDate": "05.10.1975"
    },
    {
        "engDate": "1919-01-19",
        "nepDate": "06.10.1975"
    },
    {
        "engDate": "1919-01-20",
        "nepDate": "07.10.1975"
    },
    {
        "engDate": "1919-01-21",
        "nepDate": "08.10.1975"
    },
    {
        "engDate": "1919-01-22",
        "nepDate": "09.10.1975"
    },
    {
        "engDate": "1919-01-23",
        "nepDate": "10.10.1975"
    },
    {
        "engDate": "1919-01-24",
        "nepDate": "11.10.1975"
    },
    {
        "engDate": "1919-01-25",
        "nepDate": "12.10.1975"
    },
    {
        "engDate": "1919-01-26",
        "nepDate": "13.10.1975"
    },
    {
        "engDate": "1919-01-27",
        "nepDate": "14.10.1975"
    },
    {
        "engDate": "1919-01-28",
        "nepDate": "15.10.1975"
    },
    {
        "engDate": "1919-01-29",
        "nepDate": "16.10.1975"
    },
    {
        "engDate": "1919-01-30",
        "nepDate": "17.10.1975"
    },
    {
        "engDate": "1919-01-31",
        "nepDate": "18.10.1975"
    },
    {
        "engDate": "1919-02-01",
        "nepDate": "19.10.1975"
    },
    {
        "engDate": "1919-02-02",
        "nepDate": "20.10.1975"
    },
    {
        "engDate": "1919-02-03",
        "nepDate": "21.10.1975"
    },
    {
        "engDate": "1919-02-04",
        "nepDate": "22.10.1975"
    },
    {
        "engDate": "1919-02-05",
        "nepDate": "23.10.1975"
    },
    {
        "engDate": "1919-02-06",
        "nepDate": "24.10.1975"
    },
    {
        "engDate": "1919-02-07",
        "nepDate": "25.10.1975"
    },
    {
        "engDate": "1919-02-08",
        "nepDate": "26.10.1975"
    },
    {
        "engDate": "1919-02-09",
        "nepDate": "27.10.1975"
    },
    {
        "engDate": "1919-02-10",
        "nepDate": "28.10.1975"
    },
    {
        "engDate": "1919-02-11",
        "nepDate": "29.10.1975"
    },
    {
        "engDate": "1919-02-12",
        "nepDate": "01.11.1975"
    },
    {
        "engDate": "1919-02-13",
        "nepDate": "02.11.1975"
    },
    {
        "engDate": "1919-02-14",
        "nepDate": "03.11.1975"
    },
    {
        "engDate": "1919-02-15",
        "nepDate": "04.11.1975"
    },
    {
        "engDate": "1919-02-16",
        "nepDate": "05.11.1975"
    },
    {
        "engDate": "1919-02-17",
        "nepDate": "06.11.1975"
    },
    {
        "engDate": "1919-02-18",
        "nepDate": "07.11.1975"
    },
    {
        "engDate": "1919-02-19",
        "nepDate": "08.11.1975"
    },
    {
        "engDate": "1919-02-20",
        "nepDate": "09.11.1975"
    },
    {
        "engDate": "1919-02-21",
        "nepDate": "10.11.1975"
    },
    {
        "engDate": "1919-02-22",
        "nepDate": "11.11.1975"
    },
    {
        "engDate": "1919-02-23",
        "nepDate": "12.11.1975"
    },
    {
        "engDate": "1919-02-24",
        "nepDate": "13.11.1975"
    },
    {
        "engDate": "1919-02-25",
        "nepDate": "14.11.1975"
    },
    {
        "engDate": "1919-02-26",
        "nepDate": "15.11.1975"
    },
    {
        "engDate": "1919-02-27",
        "nepDate": "16.11.1975"
    },
    {
        "engDate": "1919-02-28",
        "nepDate": "17.11.1975"
    },
    {
        "engDate": "1919-03-01",
        "nepDate": "18.11.1975"
    },
    {
        "engDate": "1919-03-02",
        "nepDate": "19.11.1975"
    },
    {
        "engDate": "1919-03-03",
        "nepDate": "20.11.1975"
    },
    {
        "engDate": "1919-03-04",
        "nepDate": "21.11.1975"
    },
    {
        "engDate": "1919-03-05",
        "nepDate": "22.11.1975"
    },
    {
        "engDate": "1919-03-06",
        "nepDate": "23.11.1975"
    },
    {
        "engDate": "1919-03-07",
        "nepDate": "24.11.1975"
    },
    {
        "engDate": "1919-03-08",
        "nepDate": "25.11.1975"
    },
    {
        "engDate": "1919-03-09",
        "nepDate": "26.11.1975"
    },
    {
        "engDate": "1919-03-10",
        "nepDate": "27.11.1975"
    },
    {
        "engDate": "1919-03-11",
        "nepDate": "28.11.1975"
    },
    {
        "engDate": "1919-03-12",
        "nepDate": "29.11.1975"
    },
    {
        "engDate": "1919-03-13",
        "nepDate": "30.11.1975"
    },
    {
        "engDate": "1919-03-14",
        "nepDate": "01.12.1975"
    },
    {
        "engDate": "1919-03-15",
        "nepDate": "02.12.1975"
    },
    {
        "engDate": "1919-03-16",
        "nepDate": "03.12.1975"
    },
    {
        "engDate": "1919-03-17",
        "nepDate": "04.12.1975"
    },
    {
        "engDate": "1919-03-18",
        "nepDate": "05.12.1975"
    },
    {
        "engDate": "1919-03-19",
        "nepDate": "06.12.1975"
    },
    {
        "engDate": "1919-03-20",
        "nepDate": "07.12.1975"
    },
    {
        "engDate": "1919-03-21",
        "nepDate": "08.12.1975"
    },
    {
        "engDate": "1919-03-22",
        "nepDate": "09.12.1975"
    },
    {
        "engDate": "1919-03-23",
        "nepDate": "10.12.1975"
    },
    {
        "engDate": "1919-03-24",
        "nepDate": "11.12.1975"
    },
    {
        "engDate": "1919-03-25",
        "nepDate": "12.12.1975"
    },
    {
        "engDate": "1919-03-26",
        "nepDate": "13.12.1975"
    },
    {
        "engDate": "1919-03-27",
        "nepDate": "14.12.1975"
    },
    {
        "engDate": "1919-03-28",
        "nepDate": "15.12.1975"
    },
    {
        "engDate": "1919-03-29",
        "nepDate": "16.12.1975"
    },
    {
        "engDate": "1919-03-30",
        "nepDate": "17.12.1975"
    },
    {
        "engDate": "1919-03-31",
        "nepDate": "18.12.1975"
    },
    {
        "engDate": "1919-04-01",
        "nepDate": "19.12.1975"
    },
    {
        "engDate": "1919-04-02",
        "nepDate": "20.12.1975"
    },
    {
        "engDate": "1919-04-03",
        "nepDate": "21.12.1975"
    },
    {
        "engDate": "1919-04-04",
        "nepDate": "22.12.1975"
    },
    {
        "engDate": "1919-04-05",
        "nepDate": "23.12.1975"
    },
    {
        "engDate": "1919-04-06",
        "nepDate": "24.12.1975"
    },
    {
        "engDate": "1919-04-07",
        "nepDate": "25.12.1975"
    },
    {
        "engDate": "1919-04-08",
        "nepDate": "26.12.1975"
    },
    {
        "engDate": "1919-04-09",
        "nepDate": "27.12.1975"
    },
    {
        "engDate": "1919-04-10",
        "nepDate": "28.12.1975"
    },
    {
        "engDate": "1919-04-11",
        "nepDate": "29.12.1975"
    },
    {
        "engDate": "1919-04-12",
        "nepDate": "30.12.1975"
    },
    {
        "engDate": "1919-04-13",
        "nepDate": "01.01.1976"
    },
    {
        "engDate": "1919-04-14",
        "nepDate": "02.01.1976"
    },
    {
        "engDate": "1919-04-15",
        "nepDate": "03.01.1976"
    },
    {
        "engDate": "1919-04-16",
        "nepDate": "04.01.1976"
    },
    {
        "engDate": "1919-04-17",
        "nepDate": "05.01.1976"
    },
    {
        "engDate": "1919-04-18",
        "nepDate": "06.01.1976"
    },
    {
        "engDate": "1919-04-19",
        "nepDate": "07.01.1976"
    },
    {
        "engDate": "1919-04-20",
        "nepDate": "08.01.1976"
    },
    {
        "engDate": "1919-04-21",
        "nepDate": "09.01.1976"
    },
    {
        "engDate": "1919-04-22",
        "nepDate": "10.01.1976"
    },
    {
        "engDate": "1919-04-23",
        "nepDate": "11.01.1976"
    },
    {
        "engDate": "1919-04-24",
        "nepDate": "12.01.1976"
    },
    {
        "engDate": "1919-04-25",
        "nepDate": "13.01.1976"
    },
    {
        "engDate": "1919-04-26",
        "nepDate": "14.01.1976"
    },
    {
        "engDate": "1919-04-27",
        "nepDate": "15.01.1976"
    },
    {
        "engDate": "1919-04-28",
        "nepDate": "16.01.1976"
    },
    {
        "engDate": "1919-04-29",
        "nepDate": "17.01.1976"
    },
    {
        "engDate": "1919-04-30",
        "nepDate": "18.01.1976"
    },
    {
        "engDate": "1919-05-01",
        "nepDate": "19.01.1976"
    },
    {
        "engDate": "1919-05-02",
        "nepDate": "20.01.1976"
    },
    {
        "engDate": "1919-05-03",
        "nepDate": "21.01.1976"
    },
    {
        "engDate": "1919-05-04",
        "nepDate": "22.01.1976"
    },
    {
        "engDate": "1919-05-05",
        "nepDate": "23.01.1976"
    },
    {
        "engDate": "1919-05-06",
        "nepDate": "24.01.1976"
    },
    {
        "engDate": "1919-05-07",
        "nepDate": "25.01.1976"
    },
    {
        "engDate": "1919-05-08",
        "nepDate": "26.01.1976"
    },
    {
        "engDate": "1919-05-09",
        "nepDate": "27.01.1976"
    },
    {
        "engDate": "1919-05-10",
        "nepDate": "28.01.1976"
    },
    {
        "engDate": "1919-05-11",
        "nepDate": "29.01.1976"
    },
    {
        "engDate": "1919-05-12",
        "nepDate": "30.01.1976"
    },
    {
        "engDate": "1919-05-13",
        "nepDate": "31.01.1976"
    },
    {
        "engDate": "1919-05-14",
        "nepDate": "01.02.1976"
    },
    {
        "engDate": "1919-05-15",
        "nepDate": "02.02.1976"
    },
    {
        "engDate": "1919-05-16",
        "nepDate": "03.02.1976"
    },
    {
        "engDate": "1919-05-17",
        "nepDate": "04.02.1976"
    },
    {
        "engDate": "1919-05-18",
        "nepDate": "05.02.1976"
    },
    {
        "engDate": "1919-05-19",
        "nepDate": "06.02.1976"
    },
    {
        "engDate": "1919-05-20",
        "nepDate": "07.02.1976"
    },
    {
        "engDate": "1919-05-21",
        "nepDate": "08.02.1976"
    },
    {
        "engDate": "1919-05-22",
        "nepDate": "09.02.1976"
    },
    {
        "engDate": "1919-05-23",
        "nepDate": "10.02.1976"
    },
    {
        "engDate": "1919-05-24",
        "nepDate": "11.02.1976"
    },
    {
        "engDate": "1919-05-25",
        "nepDate": "12.02.1976"
    },
    {
        "engDate": "1919-05-26",
        "nepDate": "13.02.1976"
    },
    {
        "engDate": "1919-05-27",
        "nepDate": "14.02.1976"
    },
    {
        "engDate": "1919-05-28",
        "nepDate": "15.02.1976"
    },
    {
        "engDate": "1919-05-29",
        "nepDate": "16.02.1976"
    },
    {
        "engDate": "1919-05-30",
        "nepDate": "17.02.1976"
    },
    {
        "engDate": "1919-05-31",
        "nepDate": "18.02.1976"
    },
    {
        "engDate": "1919-06-01",
        "nepDate": "19.02.1976"
    },
    {
        "engDate": "1919-06-02",
        "nepDate": "20.02.1976"
    },
    {
        "engDate": "1919-06-03",
        "nepDate": "21.02.1976"
    },
    {
        "engDate": "1919-06-04",
        "nepDate": "22.02.1976"
    },
    {
        "engDate": "1919-06-05",
        "nepDate": "23.02.1976"
    },
    {
        "engDate": "1919-06-06",
        "nepDate": "24.02.1976"
    },
    {
        "engDate": "1919-06-07",
        "nepDate": "25.02.1976"
    },
    {
        "engDate": "1919-06-08",
        "nepDate": "26.02.1976"
    },
    {
        "engDate": "1919-06-09",
        "nepDate": "27.02.1976"
    },
    {
        "engDate": "1919-06-10",
        "nepDate": "28.02.1976"
    },
    {
        "engDate": "1919-06-11",
        "nepDate": "29.02.1976"
    },
    {
        "engDate": "1919-06-12",
        "nepDate": "30.02.1976"
    },
    {
        "engDate": "1919-06-13",
        "nepDate": "31.02.1976"
    },
    {
        "engDate": "1919-06-14",
        "nepDate": "32.02.1976"
    },
    {
        "engDate": "1919-06-15",
        "nepDate": "01.03.1976"
    },
    {
        "engDate": "1919-06-16",
        "nepDate": "02.03.1976"
    },
    {
        "engDate": "1919-06-17",
        "nepDate": "03.03.1976"
    },
    {
        "engDate": "1919-06-18",
        "nepDate": "04.03.1976"
    },
    {
        "engDate": "1919-06-19",
        "nepDate": "05.03.1976"
    },
    {
        "engDate": "1919-06-20",
        "nepDate": "06.03.1976"
    },
    {
        "engDate": "1919-06-21",
        "nepDate": "07.03.1976"
    },
    {
        "engDate": "1919-06-22",
        "nepDate": "08.03.1976"
    },
    {
        "engDate": "1919-06-23",
        "nepDate": "09.03.1976"
    },
    {
        "engDate": "1919-06-24",
        "nepDate": "10.03.1976"
    },
    {
        "engDate": "1919-06-25",
        "nepDate": "11.03.1976"
    },
    {
        "engDate": "1919-06-26",
        "nepDate": "12.03.1976"
    },
    {
        "engDate": "1919-06-27",
        "nepDate": "13.03.1976"
    },
    {
        "engDate": "1919-06-28",
        "nepDate": "14.03.1976"
    },
    {
        "engDate": "1919-06-29",
        "nepDate": "15.03.1976"
    },
    {
        "engDate": "1919-06-30",
        "nepDate": "16.03.1976"
    },
    {
        "engDate": "1919-07-01",
        "nepDate": "17.03.1976"
    },
    {
        "engDate": "1919-07-02",
        "nepDate": "18.03.1976"
    },
    {
        "engDate": "1919-07-03",
        "nepDate": "19.03.1976"
    },
    {
        "engDate": "1919-07-04",
        "nepDate": "20.03.1976"
    },
    {
        "engDate": "1919-07-05",
        "nepDate": "21.03.1976"
    },
    {
        "engDate": "1919-07-06",
        "nepDate": "22.03.1976"
    },
    {
        "engDate": "1919-07-07",
        "nepDate": "23.03.1976"
    },
    {
        "engDate": "1919-07-08",
        "nepDate": "24.03.1976"
    },
    {
        "engDate": "1919-07-09",
        "nepDate": "25.03.1976"
    },
    {
        "engDate": "1919-07-10",
        "nepDate": "26.03.1976"
    },
    {
        "engDate": "1919-07-11",
        "nepDate": "27.03.1976"
    },
    {
        "engDate": "1919-07-12",
        "nepDate": "28.03.1976"
    },
    {
        "engDate": "1919-07-13",
        "nepDate": "29.03.1976"
    },
    {
        "engDate": "1919-07-14",
        "nepDate": "30.03.1976"
    },
    {
        "engDate": "1919-07-15",
        "nepDate": "31.03.1976"
    },
    {
        "engDate": "1919-07-16",
        "nepDate": "01.04.1976"
    },
    {
        "engDate": "1919-07-17",
        "nepDate": "02.04.1976"
    },
    {
        "engDate": "1919-07-18",
        "nepDate": "03.04.1976"
    },
    {
        "engDate": "1919-07-19",
        "nepDate": "04.04.1976"
    },
    {
        "engDate": "1919-07-20",
        "nepDate": "05.04.1976"
    },
    {
        "engDate": "1919-07-21",
        "nepDate": "06.04.1976"
    },
    {
        "engDate": "1919-07-22",
        "nepDate": "07.04.1976"
    },
    {
        "engDate": "1919-07-23",
        "nepDate": "08.04.1976"
    },
    {
        "engDate": "1919-07-24",
        "nepDate": "09.04.1976"
    },
    {
        "engDate": "1919-07-25",
        "nepDate": "10.04.1976"
    },
    {
        "engDate": "1919-07-26",
        "nepDate": "11.04.1976"
    },
    {
        "engDate": "1919-07-27",
        "nepDate": "12.04.1976"
    },
    {
        "engDate": "1919-07-28",
        "nepDate": "13.04.1976"
    },
    {
        "engDate": "1919-07-29",
        "nepDate": "14.04.1976"
    },
    {
        "engDate": "1919-07-30",
        "nepDate": "15.04.1976"
    },
    {
        "engDate": "1919-07-31",
        "nepDate": "16.04.1976"
    },
    {
        "engDate": "1919-08-01",
        "nepDate": "17.04.1976"
    },
    {
        "engDate": "1919-08-02",
        "nepDate": "18.04.1976"
    },
    {
        "engDate": "1919-08-03",
        "nepDate": "19.04.1976"
    },
    {
        "engDate": "1919-08-04",
        "nepDate": "20.04.1976"
    },
    {
        "engDate": "1919-08-05",
        "nepDate": "21.04.1976"
    },
    {
        "engDate": "1919-08-06",
        "nepDate": "22.04.1976"
    },
    {
        "engDate": "1919-08-07",
        "nepDate": "23.04.1976"
    },
    {
        "engDate": "1919-08-08",
        "nepDate": "24.04.1976"
    },
    {
        "engDate": "1919-08-09",
        "nepDate": "25.04.1976"
    },
    {
        "engDate": "1919-08-10",
        "nepDate": "26.04.1976"
    },
    {
        "engDate": "1919-08-11",
        "nepDate": "27.04.1976"
    },
    {
        "engDate": "1919-08-12",
        "nepDate": "28.04.1976"
    },
    {
        "engDate": "1919-08-13",
        "nepDate": "29.04.1976"
    },
    {
        "engDate": "1919-08-14",
        "nepDate": "30.04.1976"
    },
    {
        "engDate": "1919-08-15",
        "nepDate": "31.04.1976"
    },
    {
        "engDate": "1919-08-16",
        "nepDate": "32.04.1976"
    },
    {
        "engDate": "1919-08-17",
        "nepDate": "01.05.1976"
    },
    {
        "engDate": "1919-08-18",
        "nepDate": "02.05.1976"
    },
    {
        "engDate": "1919-08-19",
        "nepDate": "03.05.1976"
    },
    {
        "engDate": "1919-08-20",
        "nepDate": "04.05.1976"
    },
    {
        "engDate": "1919-08-21",
        "nepDate": "05.05.1976"
    },
    {
        "engDate": "1919-08-22",
        "nepDate": "06.05.1976"
    },
    {
        "engDate": "1919-08-23",
        "nepDate": "07.05.1976"
    },
    {
        "engDate": "1919-08-24",
        "nepDate": "08.05.1976"
    },
    {
        "engDate": "1919-08-25",
        "nepDate": "09.05.1976"
    },
    {
        "engDate": "1919-08-26",
        "nepDate": "10.05.1976"
    },
    {
        "engDate": "1919-08-27",
        "nepDate": "11.05.1976"
    },
    {
        "engDate": "1919-08-28",
        "nepDate": "12.05.1976"
    },
    {
        "engDate": "1919-08-29",
        "nepDate": "13.05.1976"
    },
    {
        "engDate": "1919-08-30",
        "nepDate": "14.05.1976"
    },
    {
        "engDate": "1919-08-31",
        "nepDate": "15.05.1976"
    },
    {
        "engDate": "1919-09-01",
        "nepDate": "16.05.1976"
    },
    {
        "engDate": "1919-09-02",
        "nepDate": "17.05.1976"
    },
    {
        "engDate": "1919-09-03",
        "nepDate": "18.05.1976"
    },
    {
        "engDate": "1919-09-04",
        "nepDate": "19.05.1976"
    },
    {
        "engDate": "1919-09-05",
        "nepDate": "20.05.1976"
    },
    {
        "engDate": "1919-09-06",
        "nepDate": "21.05.1976"
    },
    {
        "engDate": "1919-09-07",
        "nepDate": "22.05.1976"
    },
    {
        "engDate": "1919-09-08",
        "nepDate": "23.05.1976"
    },
    {
        "engDate": "1919-09-09",
        "nepDate": "24.05.1976"
    },
    {
        "engDate": "1919-09-10",
        "nepDate": "25.05.1976"
    },
    {
        "engDate": "1919-09-11",
        "nepDate": "26.05.1976"
    },
    {
        "engDate": "1919-09-12",
        "nepDate": "27.05.1976"
    },
    {
        "engDate": "1919-09-13",
        "nepDate": "28.05.1976"
    },
    {
        "engDate": "1919-09-14",
        "nepDate": "29.05.1976"
    },
    {
        "engDate": "1919-09-15",
        "nepDate": "30.05.1976"
    },
    {
        "engDate": "1919-09-16",
        "nepDate": "31.05.1976"
    },
    {
        "engDate": "1919-09-17",
        "nepDate": "01.06.1976"
    },
    {
        "engDate": "1919-09-18",
        "nepDate": "02.06.1976"
    },
    {
        "engDate": "1919-09-19",
        "nepDate": "03.06.1976"
    },
    {
        "engDate": "1919-09-20",
        "nepDate": "04.06.1976"
    },
    {
        "engDate": "1919-09-21",
        "nepDate": "05.06.1976"
    },
    {
        "engDate": "1919-09-22",
        "nepDate": "06.06.1976"
    },
    {
        "engDate": "1919-09-23",
        "nepDate": "07.06.1976"
    },
    {
        "engDate": "1919-09-24",
        "nepDate": "08.06.1976"
    },
    {
        "engDate": "1919-09-25",
        "nepDate": "09.06.1976"
    },
    {
        "engDate": "1919-09-26",
        "nepDate": "10.06.1976"
    },
    {
        "engDate": "1919-09-27",
        "nepDate": "11.06.1976"
    },
    {
        "engDate": "1919-09-28",
        "nepDate": "12.06.1976"
    },
    {
        "engDate": "1919-09-29",
        "nepDate": "13.06.1976"
    },
    {
        "engDate": "1919-09-30",
        "nepDate": "14.06.1976"
    },
    {
        "engDate": "1919-10-01",
        "nepDate": "15.06.1976"
    },
    {
        "engDate": "1919-10-02",
        "nepDate": "16.06.1976"
    },
    {
        "engDate": "1919-10-03",
        "nepDate": "17.06.1976"
    },
    {
        "engDate": "1919-10-04",
        "nepDate": "18.06.1976"
    },
    {
        "engDate": "1919-10-05",
        "nepDate": "19.06.1976"
    },
    {
        "engDate": "1919-10-06",
        "nepDate": "20.06.1976"
    },
    {
        "engDate": "1919-10-07",
        "nepDate": "21.06.1976"
    },
    {
        "engDate": "1919-10-08",
        "nepDate": "22.06.1976"
    },
    {
        "engDate": "1919-10-09",
        "nepDate": "23.06.1976"
    },
    {
        "engDate": "1919-10-10",
        "nepDate": "24.06.1976"
    },
    {
        "engDate": "1919-10-11",
        "nepDate": "25.06.1976"
    },
    {
        "engDate": "1919-10-12",
        "nepDate": "26.06.1976"
    },
    {
        "engDate": "1919-10-13",
        "nepDate": "27.06.1976"
    },
    {
        "engDate": "1919-10-14",
        "nepDate": "28.06.1976"
    },
    {
        "engDate": "1919-10-15",
        "nepDate": "29.06.1976"
    },
    {
        "engDate": "1919-10-16",
        "nepDate": "30.06.1976"
    },
    {
        "engDate": "1919-10-17",
        "nepDate": "01.07.1976"
    },
    {
        "engDate": "1919-10-18",
        "nepDate": "02.07.1976"
    },
    {
        "engDate": "1919-10-19",
        "nepDate": "03.07.1976"
    },
    {
        "engDate": "1919-10-20",
        "nepDate": "04.07.1976"
    },
    {
        "engDate": "1919-10-21",
        "nepDate": "05.07.1976"
    },
    {
        "engDate": "1919-10-22",
        "nepDate": "06.07.1976"
    },
    {
        "engDate": "1919-10-23",
        "nepDate": "07.07.1976"
    },
    {
        "engDate": "1919-10-24",
        "nepDate": "08.07.1976"
    },
    {
        "engDate": "1919-10-25",
        "nepDate": "09.07.1976"
    },
    {
        "engDate": "1919-10-26",
        "nepDate": "10.07.1976"
    },
    {
        "engDate": "1919-10-27",
        "nepDate": "11.07.1976"
    },
    {
        "engDate": "1919-10-28",
        "nepDate": "12.07.1976"
    },
    {
        "engDate": "1919-10-29",
        "nepDate": "13.07.1976"
    },
    {
        "engDate": "1919-10-30",
        "nepDate": "14.07.1976"
    },
    {
        "engDate": "1919-10-31",
        "nepDate": "15.07.1976"
    },
    {
        "engDate": "1919-11-01",
        "nepDate": "16.07.1976"
    },
    {
        "engDate": "1919-11-02",
        "nepDate": "17.07.1976"
    },
    {
        "engDate": "1919-11-03",
        "nepDate": "18.07.1976"
    },
    {
        "engDate": "1919-11-04",
        "nepDate": "19.07.1976"
    },
    {
        "engDate": "1919-11-05",
        "nepDate": "20.07.1976"
    },
    {
        "engDate": "1919-11-06",
        "nepDate": "21.07.1976"
    },
    {
        "engDate": "1919-11-07",
        "nepDate": "22.07.1976"
    },
    {
        "engDate": "1919-11-08",
        "nepDate": "23.07.1976"
    },
    {
        "engDate": "1919-11-09",
        "nepDate": "24.07.1976"
    },
    {
        "engDate": "1919-11-10",
        "nepDate": "25.07.1976"
    },
    {
        "engDate": "1919-11-11",
        "nepDate": "26.07.1976"
    },
    {
        "engDate": "1919-11-12",
        "nepDate": "27.07.1976"
    },
    {
        "engDate": "1919-11-13",
        "nepDate": "28.07.1976"
    },
    {
        "engDate": "1919-11-14",
        "nepDate": "29.07.1976"
    },
    {
        "engDate": "1919-11-15",
        "nepDate": "30.07.1976"
    },
    {
        "engDate": "1919-11-16",
        "nepDate": "01.08.1976"
    },
    {
        "engDate": "1919-11-17",
        "nepDate": "02.08.1976"
    },
    {
        "engDate": "1919-11-18",
        "nepDate": "03.08.1976"
    },
    {
        "engDate": "1919-11-19",
        "nepDate": "04.08.1976"
    },
    {
        "engDate": "1919-11-20",
        "nepDate": "05.08.1976"
    },
    {
        "engDate": "1919-11-21",
        "nepDate": "06.08.1976"
    },
    {
        "engDate": "1919-11-22",
        "nepDate": "07.08.1976"
    },
    {
        "engDate": "1919-11-23",
        "nepDate": "08.08.1976"
    },
    {
        "engDate": "1919-11-24",
        "nepDate": "09.08.1976"
    },
    {
        "engDate": "1919-11-25",
        "nepDate": "10.08.1976"
    },
    {
        "engDate": "1919-11-26",
        "nepDate": "11.08.1976"
    },
    {
        "engDate": "1919-11-27",
        "nepDate": "12.08.1976"
    },
    {
        "engDate": "1919-11-28",
        "nepDate": "13.08.1976"
    },
    {
        "engDate": "1919-11-29",
        "nepDate": "14.08.1976"
    },
    {
        "engDate": "1919-11-30",
        "nepDate": "15.08.1976"
    },
    {
        "engDate": "1919-12-01",
        "nepDate": "16.08.1976"
    },
    {
        "engDate": "1919-12-02",
        "nepDate": "17.08.1976"
    },
    {
        "engDate": "1919-12-03",
        "nepDate": "18.08.1976"
    },
    {
        "engDate": "1919-12-04",
        "nepDate": "19.08.1976"
    },
    {
        "engDate": "1919-12-05",
        "nepDate": "20.08.1976"
    },
    {
        "engDate": "1919-12-06",
        "nepDate": "21.08.1976"
    },
    {
        "engDate": "1919-12-07",
        "nepDate": "22.08.1976"
    },
    {
        "engDate": "1919-12-08",
        "nepDate": "23.08.1976"
    },
    {
        "engDate": "1919-12-09",
        "nepDate": "24.08.1976"
    },
    {
        "engDate": "1919-12-10",
        "nepDate": "25.08.1976"
    },
    {
        "engDate": "1919-12-11",
        "nepDate": "26.08.1976"
    },
    {
        "engDate": "1919-12-12",
        "nepDate": "27.08.1976"
    },
    {
        "engDate": "1919-12-13",
        "nepDate": "28.08.1976"
    },
    {
        "engDate": "1919-12-14",
        "nepDate": "29.08.1976"
    },
    {
        "engDate": "1919-12-15",
        "nepDate": "30.08.1976"
    },
    {
        "engDate": "1919-12-16",
        "nepDate": "01.09.1976"
    },
    {
        "engDate": "1919-12-17",
        "nepDate": "02.09.1976"
    },
    {
        "engDate": "1919-12-18",
        "nepDate": "03.09.1976"
    },
    {
        "engDate": "1919-12-19",
        "nepDate": "04.09.1976"
    },
    {
        "engDate": "1919-12-20",
        "nepDate": "05.09.1976"
    },
    {
        "engDate": "1919-12-21",
        "nepDate": "06.09.1976"
    },
    {
        "engDate": "1919-12-22",
        "nepDate": "07.09.1976"
    },
    {
        "engDate": "1919-12-23",
        "nepDate": "08.09.1976"
    },
    {
        "engDate": "1919-12-24",
        "nepDate": "09.09.1976"
    },
    {
        "engDate": "1919-12-25",
        "nepDate": "10.09.1976"
    },
    {
        "engDate": "1919-12-26",
        "nepDate": "11.09.1976"
    },
    {
        "engDate": "1919-12-27",
        "nepDate": "12.09.1976"
    },
    {
        "engDate": "1919-12-28",
        "nepDate": "13.09.1976"
    },
    {
        "engDate": "1919-12-29",
        "nepDate": "14.09.1976"
    },
    {
        "engDate": "1919-12-30",
        "nepDate": "15.09.1976"
    },
    {
        "engDate": "1919-12-31",
        "nepDate": "16.09.1976"
    },
    {
        "engDate": "1920-01-01",
        "nepDate": "17.09.1976"
    },
    {
        "engDate": "1920-01-02",
        "nepDate": "18.09.1976"
    },
    {
        "engDate": "1920-01-03",
        "nepDate": "19.09.1976"
    },
    {
        "engDate": "1920-01-04",
        "nepDate": "20.09.1976"
    },
    {
        "engDate": "1920-01-05",
        "nepDate": "21.09.1976"
    },
    {
        "engDate": "1920-01-06",
        "nepDate": "22.09.1976"
    },
    {
        "engDate": "1920-01-07",
        "nepDate": "23.09.1976"
    },
    {
        "engDate": "1920-01-08",
        "nepDate": "24.09.1976"
    },
    {
        "engDate": "1920-01-09",
        "nepDate": "25.09.1976"
    },
    {
        "engDate": "1920-01-10",
        "nepDate": "26.09.1976"
    },
    {
        "engDate": "1920-01-11",
        "nepDate": "27.09.1976"
    },
    {
        "engDate": "1920-01-12",
        "nepDate": "28.09.1976"
    },
    {
        "engDate": "1920-01-13",
        "nepDate": "29.09.1976"
    },
    {
        "engDate": "1920-01-14",
        "nepDate": "01.10.1976"
    },
    {
        "engDate": "1920-01-15",
        "nepDate": "02.10.1976"
    },
    {
        "engDate": "1920-01-16",
        "nepDate": "03.10.1976"
    },
    {
        "engDate": "1920-01-17",
        "nepDate": "04.10.1976"
    },
    {
        "engDate": "1920-01-18",
        "nepDate": "05.10.1976"
    },
    {
        "engDate": "1920-01-19",
        "nepDate": "06.10.1976"
    },
    {
        "engDate": "1920-01-20",
        "nepDate": "07.10.1976"
    },
    {
        "engDate": "1920-01-21",
        "nepDate": "08.10.1976"
    },
    {
        "engDate": "1920-01-22",
        "nepDate": "09.10.1976"
    },
    {
        "engDate": "1920-01-23",
        "nepDate": "10.10.1976"
    },
    {
        "engDate": "1920-01-24",
        "nepDate": "11.10.1976"
    },
    {
        "engDate": "1920-01-25",
        "nepDate": "12.10.1976"
    },
    {
        "engDate": "1920-01-26",
        "nepDate": "13.10.1976"
    },
    {
        "engDate": "1920-01-27",
        "nepDate": "14.10.1976"
    },
    {
        "engDate": "1920-01-28",
        "nepDate": "15.10.1976"
    },
    {
        "engDate": "1920-01-29",
        "nepDate": "16.10.1976"
    },
    {
        "engDate": "1920-01-30",
        "nepDate": "17.10.1976"
    },
    {
        "engDate": "1920-01-31",
        "nepDate": "18.10.1976"
    },
    {
        "engDate": "1920-02-01",
        "nepDate": "19.10.1976"
    },
    {
        "engDate": "1920-02-02",
        "nepDate": "20.10.1976"
    },
    {
        "engDate": "1920-02-03",
        "nepDate": "21.10.1976"
    },
    {
        "engDate": "1920-02-04",
        "nepDate": "22.10.1976"
    },
    {
        "engDate": "1920-02-05",
        "nepDate": "23.10.1976"
    },
    {
        "engDate": "1920-02-06",
        "nepDate": "24.10.1976"
    },
    {
        "engDate": "1920-02-07",
        "nepDate": "25.10.1976"
    },
    {
        "engDate": "1920-02-08",
        "nepDate": "26.10.1976"
    },
    {
        "engDate": "1920-02-09",
        "nepDate": "27.10.1976"
    },
    {
        "engDate": "1920-02-10",
        "nepDate": "28.10.1976"
    },
    {
        "engDate": "1920-02-11",
        "nepDate": "29.10.1976"
    },
    {
        "engDate": "1920-02-12",
        "nepDate": "01.11.1976"
    },
    {
        "engDate": "1920-02-13",
        "nepDate": "02.11.1976"
    },
    {
        "engDate": "1920-02-14",
        "nepDate": "03.11.1976"
    },
    {
        "engDate": "1920-02-15",
        "nepDate": "04.11.1976"
    },
    {
        "engDate": "1920-02-16",
        "nepDate": "05.11.1976"
    },
    {
        "engDate": "1920-02-17",
        "nepDate": "06.11.1976"
    },
    {
        "engDate": "1920-02-18",
        "nepDate": "07.11.1976"
    },
    {
        "engDate": "1920-02-19",
        "nepDate": "08.11.1976"
    },
    {
        "engDate": "1920-02-20",
        "nepDate": "09.11.1976"
    },
    {
        "engDate": "1920-02-21",
        "nepDate": "10.11.1976"
    },
    {
        "engDate": "1920-02-22",
        "nepDate": "11.11.1976"
    },
    {
        "engDate": "1920-02-23",
        "nepDate": "12.11.1976"
    },
    {
        "engDate": "1920-02-24",
        "nepDate": "13.11.1976"
    },
    {
        "engDate": "1920-02-25",
        "nepDate": "14.11.1976"
    },
    {
        "engDate": "1920-02-26",
        "nepDate": "15.11.1976"
    },
    {
        "engDate": "1920-02-27",
        "nepDate": "16.11.1976"
    },
    {
        "engDate": "1920-02-28",
        "nepDate": "17.11.1976"
    },
    {
        "engDate": "1920-02-29",
        "nepDate": "18.11.1976"
    },
    {
        "engDate": "1920-03-01",
        "nepDate": "19.11.1976"
    },
    {
        "engDate": "1920-03-02",
        "nepDate": "20.11.1976"
    },
    {
        "engDate": "1920-03-03",
        "nepDate": "21.11.1976"
    },
    {
        "engDate": "1920-03-04",
        "nepDate": "22.11.1976"
    },
    {
        "engDate": "1920-03-05",
        "nepDate": "23.11.1976"
    },
    {
        "engDate": "1920-03-06",
        "nepDate": "24.11.1976"
    },
    {
        "engDate": "1920-03-07",
        "nepDate": "25.11.1976"
    },
    {
        "engDate": "1920-03-08",
        "nepDate": "26.11.1976"
    },
    {
        "engDate": "1920-03-09",
        "nepDate": "27.11.1976"
    },
    {
        "engDate": "1920-03-10",
        "nepDate": "28.11.1976"
    },
    {
        "engDate": "1920-03-11",
        "nepDate": "29.11.1976"
    },
    {
        "engDate": "1920-03-12",
        "nepDate": "30.11.1976"
    },
    {
        "engDate": "1920-03-13",
        "nepDate": "01.12.1976"
    },
    {
        "engDate": "1920-03-14",
        "nepDate": "02.12.1976"
    },
    {
        "engDate": "1920-03-15",
        "nepDate": "03.12.1976"
    },
    {
        "engDate": "1920-03-16",
        "nepDate": "04.12.1976"
    },
    {
        "engDate": "1920-03-17",
        "nepDate": "05.12.1976"
    },
    {
        "engDate": "1920-03-18",
        "nepDate": "06.12.1976"
    },
    {
        "engDate": "1920-03-19",
        "nepDate": "07.12.1976"
    },
    {
        "engDate": "1920-03-20",
        "nepDate": "08.12.1976"
    },
    {
        "engDate": "1920-03-21",
        "nepDate": "09.12.1976"
    },
    {
        "engDate": "1920-03-22",
        "nepDate": "10.12.1976"
    },
    {
        "engDate": "1920-03-23",
        "nepDate": "11.12.1976"
    },
    {
        "engDate": "1920-03-24",
        "nepDate": "12.12.1976"
    },
    {
        "engDate": "1920-03-25",
        "nepDate": "13.12.1976"
    },
    {
        "engDate": "1920-03-26",
        "nepDate": "14.12.1976"
    },
    {
        "engDate": "1920-03-27",
        "nepDate": "15.12.1976"
    },
    {
        "engDate": "1920-03-28",
        "nepDate": "16.12.1976"
    },
    {
        "engDate": "1920-03-29",
        "nepDate": "17.12.1976"
    },
    {
        "engDate": "1920-03-30",
        "nepDate": "18.12.1976"
    },
    {
        "engDate": "1920-03-31",
        "nepDate": "19.12.1976"
    },
    {
        "engDate": "1920-04-01",
        "nepDate": "20.12.1976"
    },
    {
        "engDate": "1920-04-02",
        "nepDate": "21.12.1976"
    },
    {
        "engDate": "1920-04-03",
        "nepDate": "22.12.1976"
    },
    {
        "engDate": "1920-04-04",
        "nepDate": "23.12.1976"
    },
    {
        "engDate": "1920-04-05",
        "nepDate": "24.12.1976"
    },
    {
        "engDate": "1920-04-06",
        "nepDate": "25.12.1976"
    },
    {
        "engDate": "1920-04-07",
        "nepDate": "26.12.1976"
    },
    {
        "engDate": "1920-04-08",
        "nepDate": "27.12.1976"
    },
    {
        "engDate": "1920-04-09",
        "nepDate": "28.12.1976"
    },
    {
        "engDate": "1920-04-10",
        "nepDate": "29.12.1976"
    },
    {
        "engDate": "1920-04-11",
        "nepDate": "30.12.1976"
    },
    {
        "engDate": "1920-04-12",
        "nepDate": "31.12.1976"
    },
    {
        "engDate": "1920-04-13",
        "nepDate": "01.01.1977"
    },
    {
        "engDate": "1920-04-14",
        "nepDate": "02.01.1977"
    },
    {
        "engDate": "1920-04-15",
        "nepDate": "03.01.1977"
    },
    {
        "engDate": "1920-04-16",
        "nepDate": "04.01.1977"
    },
    {
        "engDate": "1920-04-17",
        "nepDate": "05.01.1977"
    },
    {
        "engDate": "1920-04-18",
        "nepDate": "06.01.1977"
    },
    {
        "engDate": "1920-04-19",
        "nepDate": "07.01.1977"
    },
    {
        "engDate": "1920-04-20",
        "nepDate": "08.01.1977"
    },
    {
        "engDate": "1920-04-21",
        "nepDate": "09.01.1977"
    },
    {
        "engDate": "1920-04-22",
        "nepDate": "10.01.1977"
    },
    {
        "engDate": "1920-04-23",
        "nepDate": "11.01.1977"
    },
    {
        "engDate": "1920-04-24",
        "nepDate": "12.01.1977"
    },
    {
        "engDate": "1920-04-25",
        "nepDate": "13.01.1977"
    },
    {
        "engDate": "1920-04-26",
        "nepDate": "14.01.1977"
    },
    {
        "engDate": "1920-04-27",
        "nepDate": "15.01.1977"
    },
    {
        "engDate": "1920-04-28",
        "nepDate": "16.01.1977"
    },
    {
        "engDate": "1920-04-29",
        "nepDate": "17.01.1977"
    },
    {
        "engDate": "1920-04-30",
        "nepDate": "18.01.1977"
    },
    {
        "engDate": "1920-05-01",
        "nepDate": "19.01.1977"
    },
    {
        "engDate": "1920-05-02",
        "nepDate": "20.01.1977"
    },
    {
        "engDate": "1920-05-03",
        "nepDate": "21.01.1977"
    },
    {
        "engDate": "1920-05-04",
        "nepDate": "22.01.1977"
    },
    {
        "engDate": "1920-05-05",
        "nepDate": "23.01.1977"
    },
    {
        "engDate": "1920-05-06",
        "nepDate": "24.01.1977"
    },
    {
        "engDate": "1920-05-07",
        "nepDate": "25.01.1977"
    },
    {
        "engDate": "1920-05-08",
        "nepDate": "26.01.1977"
    },
    {
        "engDate": "1920-05-09",
        "nepDate": "27.01.1977"
    },
    {
        "engDate": "1920-05-10",
        "nepDate": "28.01.1977"
    },
    {
        "engDate": "1920-05-11",
        "nepDate": "29.01.1977"
    },
    {
        "engDate": "1920-05-12",
        "nepDate": "30.01.1977"
    },
    {
        "engDate": "1920-05-13",
        "nepDate": "01.02.1977"
    },
    {
        "engDate": "1920-05-14",
        "nepDate": "02.02.1977"
    },
    {
        "engDate": "1920-05-15",
        "nepDate": "03.02.1977"
    },
    {
        "engDate": "1920-05-16",
        "nepDate": "04.02.1977"
    },
    {
        "engDate": "1920-05-17",
        "nepDate": "05.02.1977"
    },
    {
        "engDate": "1920-05-18",
        "nepDate": "06.02.1977"
    },
    {
        "engDate": "1920-05-19",
        "nepDate": "07.02.1977"
    },
    {
        "engDate": "1920-05-20",
        "nepDate": "08.02.1977"
    },
    {
        "engDate": "1920-05-21",
        "nepDate": "09.02.1977"
    },
    {
        "engDate": "1920-05-22",
        "nepDate": "10.02.1977"
    },
    {
        "engDate": "1920-05-23",
        "nepDate": "11.02.1977"
    },
    {
        "engDate": "1920-05-24",
        "nepDate": "12.02.1977"
    },
    {
        "engDate": "1920-05-25",
        "nepDate": "13.02.1977"
    },
    {
        "engDate": "1920-05-26",
        "nepDate": "14.02.1977"
    },
    {
        "engDate": "1920-05-27",
        "nepDate": "15.02.1977"
    },
    {
        "engDate": "1920-05-28",
        "nepDate": "16.02.1977"
    },
    {
        "engDate": "1920-05-29",
        "nepDate": "17.02.1977"
    },
    {
        "engDate": "1920-05-30",
        "nepDate": "18.02.1977"
    },
    {
        "engDate": "1920-05-31",
        "nepDate": "19.02.1977"
    },
    {
        "engDate": "1920-06-01",
        "nepDate": "20.02.1977"
    },
    {
        "engDate": "1920-06-02",
        "nepDate": "21.02.1977"
    },
    {
        "engDate": "1920-06-03",
        "nepDate": "22.02.1977"
    },
    {
        "engDate": "1920-06-04",
        "nepDate": "23.02.1977"
    },
    {
        "engDate": "1920-06-05",
        "nepDate": "24.02.1977"
    },
    {
        "engDate": "1920-06-06",
        "nepDate": "25.02.1977"
    },
    {
        "engDate": "1920-06-07",
        "nepDate": "26.02.1977"
    },
    {
        "engDate": "1920-06-08",
        "nepDate": "27.02.1977"
    },
    {
        "engDate": "1920-06-09",
        "nepDate": "28.02.1977"
    },
    {
        "engDate": "1920-06-10",
        "nepDate": "29.02.1977"
    },
    {
        "engDate": "1920-06-11",
        "nepDate": "30.02.1977"
    },
    {
        "engDate": "1920-06-12",
        "nepDate": "31.02.1977"
    },
    {
        "engDate": "1920-06-13",
        "nepDate": "32.02.1977"
    },
    {
        "engDate": "1920-06-14",
        "nepDate": "01.03.1977"
    },
    {
        "engDate": "1920-06-15",
        "nepDate": "02.03.1977"
    },
    {
        "engDate": "1920-06-16",
        "nepDate": "03.03.1977"
    },
    {
        "engDate": "1920-06-17",
        "nepDate": "04.03.1977"
    },
    {
        "engDate": "1920-06-18",
        "nepDate": "05.03.1977"
    },
    {
        "engDate": "1920-06-19",
        "nepDate": "06.03.1977"
    },
    {
        "engDate": "1920-06-20",
        "nepDate": "07.03.1977"
    },
    {
        "engDate": "1920-06-21",
        "nepDate": "08.03.1977"
    },
    {
        "engDate": "1920-06-22",
        "nepDate": "09.03.1977"
    },
    {
        "engDate": "1920-06-23",
        "nepDate": "10.03.1977"
    },
    {
        "engDate": "1920-06-24",
        "nepDate": "11.03.1977"
    },
    {
        "engDate": "1920-06-25",
        "nepDate": "12.03.1977"
    },
    {
        "engDate": "1920-06-26",
        "nepDate": "13.03.1977"
    },
    {
        "engDate": "1920-06-27",
        "nepDate": "14.03.1977"
    },
    {
        "engDate": "1920-06-28",
        "nepDate": "15.03.1977"
    },
    {
        "engDate": "1920-06-29",
        "nepDate": "16.03.1977"
    },
    {
        "engDate": "1920-06-30",
        "nepDate": "17.03.1977"
    },
    {
        "engDate": "1920-07-01",
        "nepDate": "18.03.1977"
    },
    {
        "engDate": "1920-07-02",
        "nepDate": "19.03.1977"
    },
    {
        "engDate": "1920-07-03",
        "nepDate": "20.03.1977"
    },
    {
        "engDate": "1920-07-04",
        "nepDate": "21.03.1977"
    },
    {
        "engDate": "1920-07-05",
        "nepDate": "22.03.1977"
    },
    {
        "engDate": "1920-07-06",
        "nepDate": "23.03.1977"
    },
    {
        "engDate": "1920-07-07",
        "nepDate": "24.03.1977"
    },
    {
        "engDate": "1920-07-08",
        "nepDate": "25.03.1977"
    },
    {
        "engDate": "1920-07-09",
        "nepDate": "26.03.1977"
    },
    {
        "engDate": "1920-07-10",
        "nepDate": "27.03.1977"
    },
    {
        "engDate": "1920-07-11",
        "nepDate": "28.03.1977"
    },
    {
        "engDate": "1920-07-12",
        "nepDate": "29.03.1977"
    },
    {
        "engDate": "1920-07-13",
        "nepDate": "30.03.1977"
    },
    {
        "engDate": "1920-07-14",
        "nepDate": "31.03.1977"
    },
    {
        "engDate": "1920-07-15",
        "nepDate": "01.04.1977"
    },
    {
        "engDate": "1920-07-16",
        "nepDate": "02.04.1977"
    },
    {
        "engDate": "1920-07-17",
        "nepDate": "03.04.1977"
    },
    {
        "engDate": "1920-07-18",
        "nepDate": "04.04.1977"
    },
    {
        "engDate": "1920-07-19",
        "nepDate": "05.04.1977"
    },
    {
        "engDate": "1920-07-20",
        "nepDate": "06.04.1977"
    },
    {
        "engDate": "1920-07-21",
        "nepDate": "07.04.1977"
    },
    {
        "engDate": "1920-07-22",
        "nepDate": "08.04.1977"
    },
    {
        "engDate": "1920-07-23",
        "nepDate": "09.04.1977"
    },
    {
        "engDate": "1920-07-24",
        "nepDate": "10.04.1977"
    },
    {
        "engDate": "1920-07-25",
        "nepDate": "11.04.1977"
    },
    {
        "engDate": "1920-07-26",
        "nepDate": "12.04.1977"
    },
    {
        "engDate": "1920-07-27",
        "nepDate": "13.04.1977"
    },
    {
        "engDate": "1920-07-28",
        "nepDate": "14.04.1977"
    },
    {
        "engDate": "1920-07-29",
        "nepDate": "15.04.1977"
    },
    {
        "engDate": "1920-07-30",
        "nepDate": "16.04.1977"
    },
    {
        "engDate": "1920-07-31",
        "nepDate": "17.04.1977"
    },
    {
        "engDate": "1920-08-01",
        "nepDate": "18.04.1977"
    },
    {
        "engDate": "1920-08-02",
        "nepDate": "19.04.1977"
    },
    {
        "engDate": "1920-08-03",
        "nepDate": "20.04.1977"
    },
    {
        "engDate": "1920-08-04",
        "nepDate": "21.04.1977"
    },
    {
        "engDate": "1920-08-05",
        "nepDate": "22.04.1977"
    },
    {
        "engDate": "1920-08-06",
        "nepDate": "23.04.1977"
    },
    {
        "engDate": "1920-08-07",
        "nepDate": "24.04.1977"
    },
    {
        "engDate": "1920-08-08",
        "nepDate": "25.04.1977"
    },
    {
        "engDate": "1920-08-09",
        "nepDate": "26.04.1977"
    },
    {
        "engDate": "1920-08-10",
        "nepDate": "27.04.1977"
    },
    {
        "engDate": "1920-08-11",
        "nepDate": "28.04.1977"
    },
    {
        "engDate": "1920-08-12",
        "nepDate": "29.04.1977"
    },
    {
        "engDate": "1920-08-13",
        "nepDate": "30.04.1977"
    },
    {
        "engDate": "1920-08-14",
        "nepDate": "31.04.1977"
    },
    {
        "engDate": "1920-08-15",
        "nepDate": "32.04.1977"
    },
    {
        "engDate": "1920-08-16",
        "nepDate": "01.05.1977"
    },
    {
        "engDate": "1920-08-17",
        "nepDate": "02.05.1977"
    },
    {
        "engDate": "1920-08-18",
        "nepDate": "03.05.1977"
    },
    {
        "engDate": "1920-08-19",
        "nepDate": "04.05.1977"
    },
    {
        "engDate": "1920-08-20",
        "nepDate": "05.05.1977"
    },
    {
        "engDate": "1920-08-21",
        "nepDate": "06.05.1977"
    },
    {
        "engDate": "1920-08-22",
        "nepDate": "07.05.1977"
    },
    {
        "engDate": "1920-08-23",
        "nepDate": "08.05.1977"
    },
    {
        "engDate": "1920-08-24",
        "nepDate": "09.05.1977"
    },
    {
        "engDate": "1920-08-25",
        "nepDate": "10.05.1977"
    },
    {
        "engDate": "1920-08-26",
        "nepDate": "11.05.1977"
    },
    {
        "engDate": "1920-08-27",
        "nepDate": "12.05.1977"
    },
    {
        "engDate": "1920-08-28",
        "nepDate": "13.05.1977"
    },
    {
        "engDate": "1920-08-29",
        "nepDate": "14.05.1977"
    },
    {
        "engDate": "1920-08-30",
        "nepDate": "15.05.1977"
    },
    {
        "engDate": "1920-08-31",
        "nepDate": "16.05.1977"
    },
    {
        "engDate": "1920-09-01",
        "nepDate": "17.05.1977"
    },
    {
        "engDate": "1920-09-02",
        "nepDate": "18.05.1977"
    },
    {
        "engDate": "1920-09-03",
        "nepDate": "19.05.1977"
    },
    {
        "engDate": "1920-09-04",
        "nepDate": "20.05.1977"
    },
    {
        "engDate": "1920-09-05",
        "nepDate": "21.05.1977"
    },
    {
        "engDate": "1920-09-06",
        "nepDate": "22.05.1977"
    },
    {
        "engDate": "1920-09-07",
        "nepDate": "23.05.1977"
    },
    {
        "engDate": "1920-09-08",
        "nepDate": "24.05.1977"
    },
    {
        "engDate": "1920-09-09",
        "nepDate": "25.05.1977"
    },
    {
        "engDate": "1920-09-10",
        "nepDate": "26.05.1977"
    },
    {
        "engDate": "1920-09-11",
        "nepDate": "27.05.1977"
    },
    {
        "engDate": "1920-09-12",
        "nepDate": "28.05.1977"
    },
    {
        "engDate": "1920-09-13",
        "nepDate": "29.05.1977"
    },
    {
        "engDate": "1920-09-14",
        "nepDate": "30.05.1977"
    },
    {
        "engDate": "1920-09-15",
        "nepDate": "31.05.1977"
    },
    {
        "engDate": "1920-09-16",
        "nepDate": "01.06.1977"
    },
    {
        "engDate": "1920-09-17",
        "nepDate": "02.06.1977"
    },
    {
        "engDate": "1920-09-18",
        "nepDate": "03.06.1977"
    },
    {
        "engDate": "1920-09-19",
        "nepDate": "04.06.1977"
    },
    {
        "engDate": "1920-09-20",
        "nepDate": "05.06.1977"
    },
    {
        "engDate": "1920-09-21",
        "nepDate": "06.06.1977"
    },
    {
        "engDate": "1920-09-22",
        "nepDate": "07.06.1977"
    },
    {
        "engDate": "1920-09-23",
        "nepDate": "08.06.1977"
    },
    {
        "engDate": "1920-09-24",
        "nepDate": "09.06.1977"
    },
    {
        "engDate": "1920-09-25",
        "nepDate": "10.06.1977"
    },
    {
        "engDate": "1920-09-26",
        "nepDate": "11.06.1977"
    },
    {
        "engDate": "1920-09-27",
        "nepDate": "12.06.1977"
    },
    {
        "engDate": "1920-09-28",
        "nepDate": "13.06.1977"
    },
    {
        "engDate": "1920-09-29",
        "nepDate": "14.06.1977"
    },
    {
        "engDate": "1920-09-30",
        "nepDate": "15.06.1977"
    },
    {
        "engDate": "1920-10-01",
        "nepDate": "16.06.1977"
    },
    {
        "engDate": "1920-10-02",
        "nepDate": "17.06.1977"
    },
    {
        "engDate": "1920-10-03",
        "nepDate": "18.06.1977"
    },
    {
        "engDate": "1920-10-04",
        "nepDate": "19.06.1977"
    },
    {
        "engDate": "1920-10-05",
        "nepDate": "20.06.1977"
    },
    {
        "engDate": "1920-10-06",
        "nepDate": "21.06.1977"
    },
    {
        "engDate": "1920-10-07",
        "nepDate": "22.06.1977"
    },
    {
        "engDate": "1920-10-08",
        "nepDate": "23.06.1977"
    },
    {
        "engDate": "1920-10-09",
        "nepDate": "24.06.1977"
    },
    {
        "engDate": "1920-10-10",
        "nepDate": "25.06.1977"
    },
    {
        "engDate": "1920-10-11",
        "nepDate": "26.06.1977"
    },
    {
        "engDate": "1920-10-12",
        "nepDate": "27.06.1977"
    },
    {
        "engDate": "1920-10-13",
        "nepDate": "28.06.1977"
    },
    {
        "engDate": "1920-10-14",
        "nepDate": "29.06.1977"
    },
    {
        "engDate": "1920-10-15",
        "nepDate": "30.06.1977"
    },
    {
        "engDate": "1920-10-16",
        "nepDate": "01.07.1977"
    },
    {
        "engDate": "1920-10-17",
        "nepDate": "02.07.1977"
    },
    {
        "engDate": "1920-10-18",
        "nepDate": "03.07.1977"
    },
    {
        "engDate": "1920-10-19",
        "nepDate": "04.07.1977"
    },
    {
        "engDate": "1920-10-20",
        "nepDate": "05.07.1977"
    },
    {
        "engDate": "1920-10-21",
        "nepDate": "06.07.1977"
    },
    {
        "engDate": "1920-10-22",
        "nepDate": "07.07.1977"
    },
    {
        "engDate": "1920-10-23",
        "nepDate": "08.07.1977"
    },
    {
        "engDate": "1920-10-24",
        "nepDate": "09.07.1977"
    },
    {
        "engDate": "1920-10-25",
        "nepDate": "10.07.1977"
    },
    {
        "engDate": "1920-10-26",
        "nepDate": "11.07.1977"
    },
    {
        "engDate": "1920-10-27",
        "nepDate": "12.07.1977"
    },
    {
        "engDate": "1920-10-28",
        "nepDate": "13.07.1977"
    },
    {
        "engDate": "1920-10-29",
        "nepDate": "14.07.1977"
    },
    {
        "engDate": "1920-10-30",
        "nepDate": "15.07.1977"
    },
    {
        "engDate": "1920-10-31",
        "nepDate": "16.07.1977"
    },
    {
        "engDate": "1920-11-01",
        "nepDate": "17.07.1977"
    },
    {
        "engDate": "1920-11-02",
        "nepDate": "18.07.1977"
    },
    {
        "engDate": "1920-11-03",
        "nepDate": "19.07.1977"
    },
    {
        "engDate": "1920-11-04",
        "nepDate": "20.07.1977"
    },
    {
        "engDate": "1920-11-05",
        "nepDate": "21.07.1977"
    },
    {
        "engDate": "1920-11-06",
        "nepDate": "22.07.1977"
    },
    {
        "engDate": "1920-11-07",
        "nepDate": "23.07.1977"
    },
    {
        "engDate": "1920-11-08",
        "nepDate": "24.07.1977"
    },
    {
        "engDate": "1920-11-09",
        "nepDate": "25.07.1977"
    },
    {
        "engDate": "1920-11-10",
        "nepDate": "26.07.1977"
    },
    {
        "engDate": "1920-11-11",
        "nepDate": "27.07.1977"
    },
    {
        "engDate": "1920-11-12",
        "nepDate": "28.07.1977"
    },
    {
        "engDate": "1920-11-13",
        "nepDate": "29.07.1977"
    },
    {
        "engDate": "1920-11-14",
        "nepDate": "30.07.1977"
    },
    {
        "engDate": "1920-11-15",
        "nepDate": "01.08.1977"
    },
    {
        "engDate": "1920-11-16",
        "nepDate": "02.08.1977"
    },
    {
        "engDate": "1920-11-17",
        "nepDate": "03.08.1977"
    },
    {
        "engDate": "1920-11-18",
        "nepDate": "04.08.1977"
    },
    {
        "engDate": "1920-11-19",
        "nepDate": "05.08.1977"
    },
    {
        "engDate": "1920-11-20",
        "nepDate": "06.08.1977"
    },
    {
        "engDate": "1920-11-21",
        "nepDate": "07.08.1977"
    },
    {
        "engDate": "1920-11-22",
        "nepDate": "08.08.1977"
    },
    {
        "engDate": "1920-11-23",
        "nepDate": "09.08.1977"
    },
    {
        "engDate": "1920-11-24",
        "nepDate": "10.08.1977"
    },
    {
        "engDate": "1920-11-25",
        "nepDate": "11.08.1977"
    },
    {
        "engDate": "1920-11-26",
        "nepDate": "12.08.1977"
    },
    {
        "engDate": "1920-11-27",
        "nepDate": "13.08.1977"
    },
    {
        "engDate": "1920-11-28",
        "nepDate": "14.08.1977"
    },
    {
        "engDate": "1920-11-29",
        "nepDate": "15.08.1977"
    },
    {
        "engDate": "1920-11-30",
        "nepDate": "16.08.1977"
    },
    {
        "engDate": "1920-12-01",
        "nepDate": "17.08.1977"
    },
    {
        "engDate": "1920-12-02",
        "nepDate": "18.08.1977"
    },
    {
        "engDate": "1920-12-03",
        "nepDate": "19.08.1977"
    },
    {
        "engDate": "1920-12-04",
        "nepDate": "20.08.1977"
    },
    {
        "engDate": "1920-12-05",
        "nepDate": "21.08.1977"
    },
    {
        "engDate": "1920-12-06",
        "nepDate": "22.08.1977"
    },
    {
        "engDate": "1920-12-07",
        "nepDate": "23.08.1977"
    },
    {
        "engDate": "1920-12-08",
        "nepDate": "24.08.1977"
    },
    {
        "engDate": "1920-12-09",
        "nepDate": "25.08.1977"
    },
    {
        "engDate": "1920-12-10",
        "nepDate": "26.08.1977"
    },
    {
        "engDate": "1920-12-11",
        "nepDate": "27.08.1977"
    },
    {
        "engDate": "1920-12-12",
        "nepDate": "28.08.1977"
    },
    {
        "engDate": "1920-12-13",
        "nepDate": "29.08.1977"
    },
    {
        "engDate": "1920-12-14",
        "nepDate": "30.08.1977"
    },
    {
        "engDate": "1920-12-15",
        "nepDate": "01.09.1977"
    },
    {
        "engDate": "1920-12-16",
        "nepDate": "02.09.1977"
    },
    {
        "engDate": "1920-12-17",
        "nepDate": "03.09.1977"
    },
    {
        "engDate": "1920-12-18",
        "nepDate": "04.09.1977"
    },
    {
        "engDate": "1920-12-19",
        "nepDate": "05.09.1977"
    },
    {
        "engDate": "1920-12-20",
        "nepDate": "06.09.1977"
    },
    {
        "engDate": "1920-12-21",
        "nepDate": "07.09.1977"
    },
    {
        "engDate": "1920-12-22",
        "nepDate": "08.09.1977"
    },
    {
        "engDate": "1920-12-23",
        "nepDate": "09.09.1977"
    },
    {
        "engDate": "1920-12-24",
        "nepDate": "10.09.1977"
    },
    {
        "engDate": "1920-12-25",
        "nepDate": "11.09.1977"
    },
    {
        "engDate": "1920-12-26",
        "nepDate": "12.09.1977"
    },
    {
        "engDate": "1920-12-27",
        "nepDate": "13.09.1977"
    },
    {
        "engDate": "1920-12-28",
        "nepDate": "14.09.1977"
    },
    {
        "engDate": "1920-12-29",
        "nepDate": "15.09.1977"
    },
    {
        "engDate": "1920-12-30",
        "nepDate": "16.09.1977"
    },
    {
        "engDate": "1920-12-31",
        "nepDate": "17.09.1977"
    },
    {
        "engDate": "1921-01-01",
        "nepDate": "18.09.1977"
    },
    {
        "engDate": "1921-01-02",
        "nepDate": "19.09.1977"
    },
    {
        "engDate": "1921-01-03",
        "nepDate": "20.09.1977"
    },
    {
        "engDate": "1921-01-04",
        "nepDate": "21.09.1977"
    },
    {
        "engDate": "1921-01-05",
        "nepDate": "22.09.1977"
    },
    {
        "engDate": "1921-01-06",
        "nepDate": "23.09.1977"
    },
    {
        "engDate": "1921-01-07",
        "nepDate": "24.09.1977"
    },
    {
        "engDate": "1921-01-08",
        "nepDate": "25.09.1977"
    },
    {
        "engDate": "1921-01-09",
        "nepDate": "26.09.1977"
    },
    {
        "engDate": "1921-01-10",
        "nepDate": "27.09.1977"
    },
    {
        "engDate": "1921-01-11",
        "nepDate": "28.09.1977"
    },
    {
        "engDate": "1921-01-12",
        "nepDate": "29.09.1977"
    },
    {
        "engDate": "1921-01-13",
        "nepDate": "01.10.1977"
    },
    {
        "engDate": "1921-01-14",
        "nepDate": "02.10.1977"
    },
    {
        "engDate": "1921-01-15",
        "nepDate": "03.10.1977"
    },
    {
        "engDate": "1921-01-16",
        "nepDate": "04.10.1977"
    },
    {
        "engDate": "1921-01-17",
        "nepDate": "05.10.1977"
    },
    {
        "engDate": "1921-01-18",
        "nepDate": "06.10.1977"
    },
    {
        "engDate": "1921-01-19",
        "nepDate": "07.10.1977"
    },
    {
        "engDate": "1921-01-20",
        "nepDate": "08.10.1977"
    },
    {
        "engDate": "1921-01-21",
        "nepDate": "09.10.1977"
    },
    {
        "engDate": "1921-01-22",
        "nepDate": "10.10.1977"
    },
    {
        "engDate": "1921-01-23",
        "nepDate": "11.10.1977"
    },
    {
        "engDate": "1921-01-24",
        "nepDate": "12.10.1977"
    },
    {
        "engDate": "1921-01-25",
        "nepDate": "13.10.1977"
    },
    {
        "engDate": "1921-01-26",
        "nepDate": "14.10.1977"
    },
    {
        "engDate": "1921-01-27",
        "nepDate": "15.10.1977"
    },
    {
        "engDate": "1921-01-28",
        "nepDate": "16.10.1977"
    },
    {
        "engDate": "1921-01-29",
        "nepDate": "17.10.1977"
    },
    {
        "engDate": "1921-01-30",
        "nepDate": "18.10.1977"
    },
    {
        "engDate": "1921-01-31",
        "nepDate": "19.10.1977"
    },
    {
        "engDate": "1921-02-01",
        "nepDate": "20.10.1977"
    },
    {
        "engDate": "1921-02-02",
        "nepDate": "21.10.1977"
    },
    {
        "engDate": "1921-02-03",
        "nepDate": "22.10.1977"
    },
    {
        "engDate": "1921-02-04",
        "nepDate": "23.10.1977"
    },
    {
        "engDate": "1921-02-05",
        "nepDate": "24.10.1977"
    },
    {
        "engDate": "1921-02-06",
        "nepDate": "25.10.1977"
    },
    {
        "engDate": "1921-02-07",
        "nepDate": "26.10.1977"
    },
    {
        "engDate": "1921-02-08",
        "nepDate": "27.10.1977"
    },
    {
        "engDate": "1921-02-09",
        "nepDate": "28.10.1977"
    },
    {
        "engDate": "1921-02-10",
        "nepDate": "29.10.1977"
    },
    {
        "engDate": "1921-02-11",
        "nepDate": "30.10.1977"
    },
    {
        "engDate": "1921-02-12",
        "nepDate": "01.11.1977"
    },
    {
        "engDate": "1921-02-13",
        "nepDate": "02.11.1977"
    },
    {
        "engDate": "1921-02-14",
        "nepDate": "03.11.1977"
    },
    {
        "engDate": "1921-02-15",
        "nepDate": "04.11.1977"
    },
    {
        "engDate": "1921-02-16",
        "nepDate": "05.11.1977"
    },
    {
        "engDate": "1921-02-17",
        "nepDate": "06.11.1977"
    },
    {
        "engDate": "1921-02-18",
        "nepDate": "07.11.1977"
    },
    {
        "engDate": "1921-02-19",
        "nepDate": "08.11.1977"
    },
    {
        "engDate": "1921-02-20",
        "nepDate": "09.11.1977"
    },
    {
        "engDate": "1921-02-21",
        "nepDate": "10.11.1977"
    },
    {
        "engDate": "1921-02-22",
        "nepDate": "11.11.1977"
    },
    {
        "engDate": "1921-02-23",
        "nepDate": "12.11.1977"
    },
    {
        "engDate": "1921-02-24",
        "nepDate": "13.11.1977"
    },
    {
        "engDate": "1921-02-25",
        "nepDate": "14.11.1977"
    },
    {
        "engDate": "1921-02-26",
        "nepDate": "15.11.1977"
    },
    {
        "engDate": "1921-02-27",
        "nepDate": "16.11.1977"
    },
    {
        "engDate": "1921-02-28",
        "nepDate": "17.11.1977"
    },
    {
        "engDate": "1921-03-01",
        "nepDate": "18.11.1977"
    },
    {
        "engDate": "1921-03-02",
        "nepDate": "19.11.1977"
    },
    {
        "engDate": "1921-03-03",
        "nepDate": "20.11.1977"
    },
    {
        "engDate": "1921-03-04",
        "nepDate": "21.11.1977"
    },
    {
        "engDate": "1921-03-05",
        "nepDate": "22.11.1977"
    },
    {
        "engDate": "1921-03-06",
        "nepDate": "23.11.1977"
    },
    {
        "engDate": "1921-03-07",
        "nepDate": "24.11.1977"
    },
    {
        "engDate": "1921-03-08",
        "nepDate": "25.11.1977"
    },
    {
        "engDate": "1921-03-09",
        "nepDate": "26.11.1977"
    },
    {
        "engDate": "1921-03-10",
        "nepDate": "27.11.1977"
    },
    {
        "engDate": "1921-03-11",
        "nepDate": "28.11.1977"
    },
    {
        "engDate": "1921-03-12",
        "nepDate": "29.11.1977"
    },
    {
        "engDate": "1921-03-13",
        "nepDate": "01.12.1977"
    },
    {
        "engDate": "1921-03-14",
        "nepDate": "02.12.1977"
    },
    {
        "engDate": "1921-03-15",
        "nepDate": "03.12.1977"
    },
    {
        "engDate": "1921-03-16",
        "nepDate": "04.12.1977"
    },
    {
        "engDate": "1921-03-17",
        "nepDate": "05.12.1977"
    },
    {
        "engDate": "1921-03-18",
        "nepDate": "06.12.1977"
    },
    {
        "engDate": "1921-03-19",
        "nepDate": "07.12.1977"
    },
    {
        "engDate": "1921-03-20",
        "nepDate": "08.12.1977"
    },
    {
        "engDate": "1921-03-21",
        "nepDate": "09.12.1977"
    },
    {
        "engDate": "1921-03-22",
        "nepDate": "10.12.1977"
    },
    {
        "engDate": "1921-03-23",
        "nepDate": "11.12.1977"
    },
    {
        "engDate": "1921-03-24",
        "nepDate": "12.12.1977"
    },
    {
        "engDate": "1921-03-25",
        "nepDate": "13.12.1977"
    },
    {
        "engDate": "1921-03-26",
        "nepDate": "14.12.1977"
    },
    {
        "engDate": "1921-03-27",
        "nepDate": "15.12.1977"
    },
    {
        "engDate": "1921-03-28",
        "nepDate": "16.12.1977"
    },
    {
        "engDate": "1921-03-29",
        "nepDate": "17.12.1977"
    },
    {
        "engDate": "1921-03-30",
        "nepDate": "18.12.1977"
    },
    {
        "engDate": "1921-03-31",
        "nepDate": "19.12.1977"
    },
    {
        "engDate": "1921-04-01",
        "nepDate": "20.12.1977"
    },
    {
        "engDate": "1921-04-02",
        "nepDate": "21.12.1977"
    },
    {
        "engDate": "1921-04-03",
        "nepDate": "22.12.1977"
    },
    {
        "engDate": "1921-04-04",
        "nepDate": "23.12.1977"
    },
    {
        "engDate": "1921-04-05",
        "nepDate": "24.12.1977"
    },
    {
        "engDate": "1921-04-06",
        "nepDate": "25.12.1977"
    },
    {
        "engDate": "1921-04-07",
        "nepDate": "26.12.1977"
    },
    {
        "engDate": "1921-04-08",
        "nepDate": "27.12.1977"
    },
    {
        "engDate": "1921-04-09",
        "nepDate": "28.12.1977"
    },
    {
        "engDate": "1921-04-10",
        "nepDate": "29.12.1977"
    },
    {
        "engDate": "1921-04-11",
        "nepDate": "30.12.1977"
    },
    {
        "engDate": "1921-04-12",
        "nepDate": "31.12.1977"
    },
    {
        "engDate": "1921-04-13",
        "nepDate": "01.01.1978"
    },
    {
        "engDate": "1921-04-14",
        "nepDate": "02.01.1978"
    },
    {
        "engDate": "1921-04-15",
        "nepDate": "03.01.1978"
    },
    {
        "engDate": "1921-04-16",
        "nepDate": "04.01.1978"
    },
    {
        "engDate": "1921-04-17",
        "nepDate": "05.01.1978"
    },
    {
        "engDate": "1921-04-18",
        "nepDate": "06.01.1978"
    },
    {
        "engDate": "1921-04-19",
        "nepDate": "07.01.1978"
    },
    {
        "engDate": "1921-04-20",
        "nepDate": "08.01.1978"
    },
    {
        "engDate": "1921-04-21",
        "nepDate": "09.01.1978"
    },
    {
        "engDate": "1921-04-22",
        "nepDate": "10.01.1978"
    },
    {
        "engDate": "1921-04-23",
        "nepDate": "11.01.1978"
    },
    {
        "engDate": "1921-04-24",
        "nepDate": "12.01.1978"
    },
    {
        "engDate": "1921-04-25",
        "nepDate": "13.01.1978"
    },
    {
        "engDate": "1921-04-26",
        "nepDate": "14.01.1978"
    },
    {
        "engDate": "1921-04-27",
        "nepDate": "15.01.1978"
    },
    {
        "engDate": "1921-04-28",
        "nepDate": "16.01.1978"
    },
    {
        "engDate": "1921-04-29",
        "nepDate": "17.01.1978"
    },
    {
        "engDate": "1921-04-30",
        "nepDate": "18.01.1978"
    },
    {
        "engDate": "1921-05-01",
        "nepDate": "19.01.1978"
    },
    {
        "engDate": "1921-05-02",
        "nepDate": "20.01.1978"
    },
    {
        "engDate": "1921-05-03",
        "nepDate": "21.01.1978"
    },
    {
        "engDate": "1921-05-04",
        "nepDate": "22.01.1978"
    },
    {
        "engDate": "1921-05-05",
        "nepDate": "23.01.1978"
    },
    {
        "engDate": "1921-05-06",
        "nepDate": "24.01.1978"
    },
    {
        "engDate": "1921-05-07",
        "nepDate": "25.01.1978"
    },
    {
        "engDate": "1921-05-08",
        "nepDate": "26.01.1978"
    },
    {
        "engDate": "1921-05-09",
        "nepDate": "27.01.1978"
    },
    {
        "engDate": "1921-05-10",
        "nepDate": "28.01.1978"
    },
    {
        "engDate": "1921-05-11",
        "nepDate": "29.01.1978"
    },
    {
        "engDate": "1921-05-12",
        "nepDate": "30.01.1978"
    },
    {
        "engDate": "1921-05-13",
        "nepDate": "31.01.1978"
    },
    {
        "engDate": "1921-05-14",
        "nepDate": "01.02.1978"
    },
    {
        "engDate": "1921-05-15",
        "nepDate": "02.02.1978"
    },
    {
        "engDate": "1921-05-16",
        "nepDate": "03.02.1978"
    },
    {
        "engDate": "1921-05-17",
        "nepDate": "04.02.1978"
    },
    {
        "engDate": "1921-05-18",
        "nepDate": "05.02.1978"
    },
    {
        "engDate": "1921-05-19",
        "nepDate": "06.02.1978"
    },
    {
        "engDate": "1921-05-20",
        "nepDate": "07.02.1978"
    },
    {
        "engDate": "1921-05-21",
        "nepDate": "08.02.1978"
    },
    {
        "engDate": "1921-05-22",
        "nepDate": "09.02.1978"
    },
    {
        "engDate": "1921-05-23",
        "nepDate": "10.02.1978"
    },
    {
        "engDate": "1921-05-24",
        "nepDate": "11.02.1978"
    },
    {
        "engDate": "1921-05-25",
        "nepDate": "12.02.1978"
    },
    {
        "engDate": "1921-05-26",
        "nepDate": "13.02.1978"
    },
    {
        "engDate": "1921-05-27",
        "nepDate": "14.02.1978"
    },
    {
        "engDate": "1921-05-28",
        "nepDate": "15.02.1978"
    },
    {
        "engDate": "1921-05-29",
        "nepDate": "16.02.1978"
    },
    {
        "engDate": "1921-05-30",
        "nepDate": "17.02.1978"
    },
    {
        "engDate": "1921-05-31",
        "nepDate": "18.02.1978"
    },
    {
        "engDate": "1921-06-01",
        "nepDate": "19.02.1978"
    },
    {
        "engDate": "1921-06-02",
        "nepDate": "20.02.1978"
    },
    {
        "engDate": "1921-06-03",
        "nepDate": "21.02.1978"
    },
    {
        "engDate": "1921-06-04",
        "nepDate": "22.02.1978"
    },
    {
        "engDate": "1921-06-05",
        "nepDate": "23.02.1978"
    },
    {
        "engDate": "1921-06-06",
        "nepDate": "24.02.1978"
    },
    {
        "engDate": "1921-06-07",
        "nepDate": "25.02.1978"
    },
    {
        "engDate": "1921-06-08",
        "nepDate": "26.02.1978"
    },
    {
        "engDate": "1921-06-09",
        "nepDate": "27.02.1978"
    },
    {
        "engDate": "1921-06-10",
        "nepDate": "28.02.1978"
    },
    {
        "engDate": "1921-06-11",
        "nepDate": "29.02.1978"
    },
    {
        "engDate": "1921-06-12",
        "nepDate": "30.02.1978"
    },
    {
        "engDate": "1921-06-13",
        "nepDate": "31.02.1978"
    },
    {
        "engDate": "1921-06-14",
        "nepDate": "01.03.1978"
    },
    {
        "engDate": "1921-06-15",
        "nepDate": "02.03.1978"
    },
    {
        "engDate": "1921-06-16",
        "nepDate": "03.03.1978"
    },
    {
        "engDate": "1921-06-17",
        "nepDate": "04.03.1978"
    },
    {
        "engDate": "1921-06-18",
        "nepDate": "05.03.1978"
    },
    {
        "engDate": "1921-06-19",
        "nepDate": "06.03.1978"
    },
    {
        "engDate": "1921-06-20",
        "nepDate": "07.03.1978"
    },
    {
        "engDate": "1921-06-21",
        "nepDate": "08.03.1978"
    },
    {
        "engDate": "1921-06-22",
        "nepDate": "09.03.1978"
    },
    {
        "engDate": "1921-06-23",
        "nepDate": "10.03.1978"
    },
    {
        "engDate": "1921-06-24",
        "nepDate": "11.03.1978"
    },
    {
        "engDate": "1921-06-25",
        "nepDate": "12.03.1978"
    },
    {
        "engDate": "1921-06-26",
        "nepDate": "13.03.1978"
    },
    {
        "engDate": "1921-06-27",
        "nepDate": "14.03.1978"
    },
    {
        "engDate": "1921-06-28",
        "nepDate": "15.03.1978"
    },
    {
        "engDate": "1921-06-29",
        "nepDate": "16.03.1978"
    },
    {
        "engDate": "1921-06-30",
        "nepDate": "17.03.1978"
    },
    {
        "engDate": "1921-07-01",
        "nepDate": "18.03.1978"
    },
    {
        "engDate": "1921-07-02",
        "nepDate": "19.03.1978"
    },
    {
        "engDate": "1921-07-03",
        "nepDate": "20.03.1978"
    },
    {
        "engDate": "1921-07-04",
        "nepDate": "21.03.1978"
    },
    {
        "engDate": "1921-07-05",
        "nepDate": "22.03.1978"
    },
    {
        "engDate": "1921-07-06",
        "nepDate": "23.03.1978"
    },
    {
        "engDate": "1921-07-07",
        "nepDate": "24.03.1978"
    },
    {
        "engDate": "1921-07-08",
        "nepDate": "25.03.1978"
    },
    {
        "engDate": "1921-07-09",
        "nepDate": "26.03.1978"
    },
    {
        "engDate": "1921-07-10",
        "nepDate": "27.03.1978"
    },
    {
        "engDate": "1921-07-11",
        "nepDate": "28.03.1978"
    },
    {
        "engDate": "1921-07-12",
        "nepDate": "29.03.1978"
    },
    {
        "engDate": "1921-07-13",
        "nepDate": "30.03.1978"
    },
    {
        "engDate": "1921-07-14",
        "nepDate": "31.03.1978"
    },
    {
        "engDate": "1921-07-15",
        "nepDate": "32.03.1978"
    },
    {
        "engDate": "1921-07-16",
        "nepDate": "01.04.1978"
    },
    {
        "engDate": "1921-07-17",
        "nepDate": "02.04.1978"
    },
    {
        "engDate": "1921-07-18",
        "nepDate": "03.04.1978"
    },
    {
        "engDate": "1921-07-19",
        "nepDate": "04.04.1978"
    },
    {
        "engDate": "1921-07-20",
        "nepDate": "05.04.1978"
    },
    {
        "engDate": "1921-07-21",
        "nepDate": "06.04.1978"
    },
    {
        "engDate": "1921-07-22",
        "nepDate": "07.04.1978"
    },
    {
        "engDate": "1921-07-23",
        "nepDate": "08.04.1978"
    },
    {
        "engDate": "1921-07-24",
        "nepDate": "09.04.1978"
    },
    {
        "engDate": "1921-07-25",
        "nepDate": "10.04.1978"
    },
    {
        "engDate": "1921-07-26",
        "nepDate": "11.04.1978"
    },
    {
        "engDate": "1921-07-27",
        "nepDate": "12.04.1978"
    },
    {
        "engDate": "1921-07-28",
        "nepDate": "13.04.1978"
    },
    {
        "engDate": "1921-07-29",
        "nepDate": "14.04.1978"
    },
    {
        "engDate": "1921-07-30",
        "nepDate": "15.04.1978"
    },
    {
        "engDate": "1921-07-31",
        "nepDate": "16.04.1978"
    },
    {
        "engDate": "1921-08-01",
        "nepDate": "17.04.1978"
    },
    {
        "engDate": "1921-08-02",
        "nepDate": "18.04.1978"
    },
    {
        "engDate": "1921-08-03",
        "nepDate": "19.04.1978"
    },
    {
        "engDate": "1921-08-04",
        "nepDate": "20.04.1978"
    },
    {
        "engDate": "1921-08-05",
        "nepDate": "21.04.1978"
    },
    {
        "engDate": "1921-08-06",
        "nepDate": "22.04.1978"
    },
    {
        "engDate": "1921-08-07",
        "nepDate": "23.04.1978"
    },
    {
        "engDate": "1921-08-08",
        "nepDate": "24.04.1978"
    },
    {
        "engDate": "1921-08-09",
        "nepDate": "25.04.1978"
    },
    {
        "engDate": "1921-08-10",
        "nepDate": "26.04.1978"
    },
    {
        "engDate": "1921-08-11",
        "nepDate": "27.04.1978"
    },
    {
        "engDate": "1921-08-12",
        "nepDate": "28.04.1978"
    },
    {
        "engDate": "1921-08-13",
        "nepDate": "29.04.1978"
    },
    {
        "engDate": "1921-08-14",
        "nepDate": "30.04.1978"
    },
    {
        "engDate": "1921-08-15",
        "nepDate": "31.04.1978"
    },
    {
        "engDate": "1921-08-16",
        "nepDate": "01.05.1978"
    },
    {
        "engDate": "1921-08-17",
        "nepDate": "02.05.1978"
    },
    {
        "engDate": "1921-08-18",
        "nepDate": "03.05.1978"
    },
    {
        "engDate": "1921-08-19",
        "nepDate": "04.05.1978"
    },
    {
        "engDate": "1921-08-20",
        "nepDate": "05.05.1978"
    },
    {
        "engDate": "1921-08-21",
        "nepDate": "06.05.1978"
    },
    {
        "engDate": "1921-08-22",
        "nepDate": "07.05.1978"
    },
    {
        "engDate": "1921-08-23",
        "nepDate": "08.05.1978"
    },
    {
        "engDate": "1921-08-24",
        "nepDate": "09.05.1978"
    },
    {
        "engDate": "1921-08-25",
        "nepDate": "10.05.1978"
    },
    {
        "engDate": "1921-08-26",
        "nepDate": "11.05.1978"
    },
    {
        "engDate": "1921-08-27",
        "nepDate": "12.05.1978"
    },
    {
        "engDate": "1921-08-28",
        "nepDate": "13.05.1978"
    },
    {
        "engDate": "1921-08-29",
        "nepDate": "14.05.1978"
    },
    {
        "engDate": "1921-08-30",
        "nepDate": "15.05.1978"
    },
    {
        "engDate": "1921-08-31",
        "nepDate": "16.05.1978"
    },
    {
        "engDate": "1921-09-01",
        "nepDate": "17.05.1978"
    },
    {
        "engDate": "1921-09-02",
        "nepDate": "18.05.1978"
    },
    {
        "engDate": "1921-09-03",
        "nepDate": "19.05.1978"
    },
    {
        "engDate": "1921-09-04",
        "nepDate": "20.05.1978"
    },
    {
        "engDate": "1921-09-05",
        "nepDate": "21.05.1978"
    },
    {
        "engDate": "1921-09-06",
        "nepDate": "22.05.1978"
    },
    {
        "engDate": "1921-09-07",
        "nepDate": "23.05.1978"
    },
    {
        "engDate": "1921-09-08",
        "nepDate": "24.05.1978"
    },
    {
        "engDate": "1921-09-09",
        "nepDate": "25.05.1978"
    },
    {
        "engDate": "1921-09-10",
        "nepDate": "26.05.1978"
    },
    {
        "engDate": "1921-09-11",
        "nepDate": "27.05.1978"
    },
    {
        "engDate": "1921-09-12",
        "nepDate": "28.05.1978"
    },
    {
        "engDate": "1921-09-13",
        "nepDate": "29.05.1978"
    },
    {
        "engDate": "1921-09-14",
        "nepDate": "30.05.1978"
    },
    {
        "engDate": "1921-09-15",
        "nepDate": "31.05.1978"
    },
    {
        "engDate": "1921-09-16",
        "nepDate": "01.06.1978"
    },
    {
        "engDate": "1921-09-17",
        "nepDate": "02.06.1978"
    },
    {
        "engDate": "1921-09-18",
        "nepDate": "03.06.1978"
    },
    {
        "engDate": "1921-09-19",
        "nepDate": "04.06.1978"
    },
    {
        "engDate": "1921-09-20",
        "nepDate": "05.06.1978"
    },
    {
        "engDate": "1921-09-21",
        "nepDate": "06.06.1978"
    },
    {
        "engDate": "1921-09-22",
        "nepDate": "07.06.1978"
    },
    {
        "engDate": "1921-09-23",
        "nepDate": "08.06.1978"
    },
    {
        "engDate": "1921-09-24",
        "nepDate": "09.06.1978"
    },
    {
        "engDate": "1921-09-25",
        "nepDate": "10.06.1978"
    },
    {
        "engDate": "1921-09-26",
        "nepDate": "11.06.1978"
    },
    {
        "engDate": "1921-09-27",
        "nepDate": "12.06.1978"
    },
    {
        "engDate": "1921-09-28",
        "nepDate": "13.06.1978"
    },
    {
        "engDate": "1921-09-29",
        "nepDate": "14.06.1978"
    },
    {
        "engDate": "1921-09-30",
        "nepDate": "15.06.1978"
    },
    {
        "engDate": "1921-10-01",
        "nepDate": "16.06.1978"
    },
    {
        "engDate": "1921-10-02",
        "nepDate": "17.06.1978"
    },
    {
        "engDate": "1921-10-03",
        "nepDate": "18.06.1978"
    },
    {
        "engDate": "1921-10-04",
        "nepDate": "19.06.1978"
    },
    {
        "engDate": "1921-10-05",
        "nepDate": "20.06.1978"
    },
    {
        "engDate": "1921-10-06",
        "nepDate": "21.06.1978"
    },
    {
        "engDate": "1921-10-07",
        "nepDate": "22.06.1978"
    },
    {
        "engDate": "1921-10-08",
        "nepDate": "23.06.1978"
    },
    {
        "engDate": "1921-10-09",
        "nepDate": "24.06.1978"
    },
    {
        "engDate": "1921-10-10",
        "nepDate": "25.06.1978"
    },
    {
        "engDate": "1921-10-11",
        "nepDate": "26.06.1978"
    },
    {
        "engDate": "1921-10-12",
        "nepDate": "27.06.1978"
    },
    {
        "engDate": "1921-10-13",
        "nepDate": "28.06.1978"
    },
    {
        "engDate": "1921-10-14",
        "nepDate": "29.06.1978"
    },
    {
        "engDate": "1921-10-15",
        "nepDate": "30.06.1978"
    },
    {
        "engDate": "1921-10-16",
        "nepDate": "31.06.1978"
    },
    {
        "engDate": "1921-10-17",
        "nepDate": "01.07.1978"
    },
    {
        "engDate": "1921-10-18",
        "nepDate": "02.07.1978"
    },
    {
        "engDate": "1921-10-19",
        "nepDate": "03.07.1978"
    },
    {
        "engDate": "1921-10-20",
        "nepDate": "04.07.1978"
    },
    {
        "engDate": "1921-10-21",
        "nepDate": "05.07.1978"
    },
    {
        "engDate": "1921-10-22",
        "nepDate": "06.07.1978"
    },
    {
        "engDate": "1921-10-23",
        "nepDate": "07.07.1978"
    },
    {
        "engDate": "1921-10-24",
        "nepDate": "08.07.1978"
    },
    {
        "engDate": "1921-10-25",
        "nepDate": "09.07.1978"
    },
    {
        "engDate": "1921-10-26",
        "nepDate": "10.07.1978"
    },
    {
        "engDate": "1921-10-27",
        "nepDate": "11.07.1978"
    },
    {
        "engDate": "1921-10-28",
        "nepDate": "12.07.1978"
    },
    {
        "engDate": "1921-10-29",
        "nepDate": "13.07.1978"
    },
    {
        "engDate": "1921-10-30",
        "nepDate": "14.07.1978"
    },
    {
        "engDate": "1921-10-31",
        "nepDate": "15.07.1978"
    },
    {
        "engDate": "1921-11-01",
        "nepDate": "16.07.1978"
    },
    {
        "engDate": "1921-11-02",
        "nepDate": "17.07.1978"
    },
    {
        "engDate": "1921-11-03",
        "nepDate": "18.07.1978"
    },
    {
        "engDate": "1921-11-04",
        "nepDate": "19.07.1978"
    },
    {
        "engDate": "1921-11-05",
        "nepDate": "20.07.1978"
    },
    {
        "engDate": "1921-11-06",
        "nepDate": "21.07.1978"
    },
    {
        "engDate": "1921-11-07",
        "nepDate": "22.07.1978"
    },
    {
        "engDate": "1921-11-08",
        "nepDate": "23.07.1978"
    },
    {
        "engDate": "1921-11-09",
        "nepDate": "24.07.1978"
    },
    {
        "engDate": "1921-11-10",
        "nepDate": "25.07.1978"
    },
    {
        "engDate": "1921-11-11",
        "nepDate": "26.07.1978"
    },
    {
        "engDate": "1921-11-12",
        "nepDate": "27.07.1978"
    },
    {
        "engDate": "1921-11-13",
        "nepDate": "28.07.1978"
    },
    {
        "engDate": "1921-11-14",
        "nepDate": "29.07.1978"
    },
    {
        "engDate": "1921-11-15",
        "nepDate": "30.07.1978"
    },
    {
        "engDate": "1921-11-16",
        "nepDate": "01.08.1978"
    },
    {
        "engDate": "1921-11-17",
        "nepDate": "02.08.1978"
    },
    {
        "engDate": "1921-11-18",
        "nepDate": "03.08.1978"
    },
    {
        "engDate": "1921-11-19",
        "nepDate": "04.08.1978"
    },
    {
        "engDate": "1921-11-20",
        "nepDate": "05.08.1978"
    },
    {
        "engDate": "1921-11-21",
        "nepDate": "06.08.1978"
    },
    {
        "engDate": "1921-11-22",
        "nepDate": "07.08.1978"
    },
    {
        "engDate": "1921-11-23",
        "nepDate": "08.08.1978"
    },
    {
        "engDate": "1921-11-24",
        "nepDate": "09.08.1978"
    },
    {
        "engDate": "1921-11-25",
        "nepDate": "10.08.1978"
    },
    {
        "engDate": "1921-11-26",
        "nepDate": "11.08.1978"
    },
    {
        "engDate": "1921-11-27",
        "nepDate": "12.08.1978"
    },
    {
        "engDate": "1921-11-28",
        "nepDate": "13.08.1978"
    },
    {
        "engDate": "1921-11-29",
        "nepDate": "14.08.1978"
    },
    {
        "engDate": "1921-11-30",
        "nepDate": "15.08.1978"
    },
    {
        "engDate": "1921-12-01",
        "nepDate": "16.08.1978"
    },
    {
        "engDate": "1921-12-02",
        "nepDate": "17.08.1978"
    },
    {
        "engDate": "1921-12-03",
        "nepDate": "18.08.1978"
    },
    {
        "engDate": "1921-12-04",
        "nepDate": "19.08.1978"
    },
    {
        "engDate": "1921-12-05",
        "nepDate": "20.08.1978"
    },
    {
        "engDate": "1921-12-06",
        "nepDate": "21.08.1978"
    },
    {
        "engDate": "1921-12-07",
        "nepDate": "22.08.1978"
    },
    {
        "engDate": "1921-12-08",
        "nepDate": "23.08.1978"
    },
    {
        "engDate": "1921-12-09",
        "nepDate": "24.08.1978"
    },
    {
        "engDate": "1921-12-10",
        "nepDate": "25.08.1978"
    },
    {
        "engDate": "1921-12-11",
        "nepDate": "26.08.1978"
    },
    {
        "engDate": "1921-12-12",
        "nepDate": "27.08.1978"
    },
    {
        "engDate": "1921-12-13",
        "nepDate": "28.08.1978"
    },
    {
        "engDate": "1921-12-14",
        "nepDate": "29.08.1978"
    },
    {
        "engDate": "1921-12-15",
        "nepDate": "01.09.1978"
    },
    {
        "engDate": "1921-12-16",
        "nepDate": "02.09.1978"
    },
    {
        "engDate": "1921-12-17",
        "nepDate": "03.09.1978"
    },
    {
        "engDate": "1921-12-18",
        "nepDate": "04.09.1978"
    },
    {
        "engDate": "1921-12-19",
        "nepDate": "05.09.1978"
    },
    {
        "engDate": "1921-12-20",
        "nepDate": "06.09.1978"
    },
    {
        "engDate": "1921-12-21",
        "nepDate": "07.09.1978"
    },
    {
        "engDate": "1921-12-22",
        "nepDate": "08.09.1978"
    },
    {
        "engDate": "1921-12-23",
        "nepDate": "09.09.1978"
    },
    {
        "engDate": "1921-12-24",
        "nepDate": "10.09.1978"
    },
    {
        "engDate": "1921-12-25",
        "nepDate": "11.09.1978"
    },
    {
        "engDate": "1921-12-26",
        "nepDate": "12.09.1978"
    },
    {
        "engDate": "1921-12-27",
        "nepDate": "13.09.1978"
    },
    {
        "engDate": "1921-12-28",
        "nepDate": "14.09.1978"
    },
    {
        "engDate": "1921-12-29",
        "nepDate": "15.09.1978"
    },
    {
        "engDate": "1921-12-30",
        "nepDate": "16.09.1978"
    },
    {
        "engDate": "1921-12-31",
        "nepDate": "17.09.1978"
    },
    {
        "engDate": "1922-01-01",
        "nepDate": "18.09.1978"
    },
    {
        "engDate": "1922-01-02",
        "nepDate": "19.09.1978"
    },
    {
        "engDate": "1922-01-03",
        "nepDate": "20.09.1978"
    },
    {
        "engDate": "1922-01-04",
        "nepDate": "21.09.1978"
    },
    {
        "engDate": "1922-01-05",
        "nepDate": "22.09.1978"
    },
    {
        "engDate": "1922-01-06",
        "nepDate": "23.09.1978"
    },
    {
        "engDate": "1922-01-07",
        "nepDate": "24.09.1978"
    },
    {
        "engDate": "1922-01-08",
        "nepDate": "25.09.1978"
    },
    {
        "engDate": "1922-01-09",
        "nepDate": "26.09.1978"
    },
    {
        "engDate": "1922-01-10",
        "nepDate": "27.09.1978"
    },
    {
        "engDate": "1922-01-11",
        "nepDate": "28.09.1978"
    },
    {
        "engDate": "1922-01-12",
        "nepDate": "29.09.1978"
    },
    {
        "engDate": "1922-01-13",
        "nepDate": "30.09.1978"
    },
    {
        "engDate": "1922-01-14",
        "nepDate": "01.10.1978"
    },
    {
        "engDate": "1922-01-15",
        "nepDate": "02.10.1978"
    },
    {
        "engDate": "1922-01-16",
        "nepDate": "03.10.1978"
    },
    {
        "engDate": "1922-01-17",
        "nepDate": "04.10.1978"
    },
    {
        "engDate": "1922-01-18",
        "nepDate": "05.10.1978"
    },
    {
        "engDate": "1922-01-19",
        "nepDate": "06.10.1978"
    },
    {
        "engDate": "1922-01-20",
        "nepDate": "07.10.1978"
    },
    {
        "engDate": "1922-01-21",
        "nepDate": "08.10.1978"
    },
    {
        "engDate": "1922-01-22",
        "nepDate": "09.10.1978"
    },
    {
        "engDate": "1922-01-23",
        "nepDate": "10.10.1978"
    },
    {
        "engDate": "1922-01-24",
        "nepDate": "11.10.1978"
    },
    {
        "engDate": "1922-01-25",
        "nepDate": "12.10.1978"
    },
    {
        "engDate": "1922-01-26",
        "nepDate": "13.10.1978"
    },
    {
        "engDate": "1922-01-27",
        "nepDate": "14.10.1978"
    },
    {
        "engDate": "1922-01-28",
        "nepDate": "15.10.1978"
    },
    {
        "engDate": "1922-01-29",
        "nepDate": "16.10.1978"
    },
    {
        "engDate": "1922-01-30",
        "nepDate": "17.10.1978"
    },
    {
        "engDate": "1922-01-31",
        "nepDate": "18.10.1978"
    },
    {
        "engDate": "1922-02-01",
        "nepDate": "19.10.1978"
    },
    {
        "engDate": "1922-02-02",
        "nepDate": "20.10.1978"
    },
    {
        "engDate": "1922-02-03",
        "nepDate": "21.10.1978"
    },
    {
        "engDate": "1922-02-04",
        "nepDate": "22.10.1978"
    },
    {
        "engDate": "1922-02-05",
        "nepDate": "23.10.1978"
    },
    {
        "engDate": "1922-02-06",
        "nepDate": "24.10.1978"
    },
    {
        "engDate": "1922-02-07",
        "nepDate": "25.10.1978"
    },
    {
        "engDate": "1922-02-08",
        "nepDate": "26.10.1978"
    },
    {
        "engDate": "1922-02-09",
        "nepDate": "27.10.1978"
    },
    {
        "engDate": "1922-02-10",
        "nepDate": "28.10.1978"
    },
    {
        "engDate": "1922-02-11",
        "nepDate": "29.10.1978"
    },
    {
        "engDate": "1922-02-12",
        "nepDate": "01.11.1978"
    },
    {
        "engDate": "1922-02-13",
        "nepDate": "02.11.1978"
    },
    {
        "engDate": "1922-02-14",
        "nepDate": "03.11.1978"
    },
    {
        "engDate": "1922-02-15",
        "nepDate": "04.11.1978"
    },
    {
        "engDate": "1922-02-16",
        "nepDate": "05.11.1978"
    },
    {
        "engDate": "1922-02-17",
        "nepDate": "06.11.1978"
    },
    {
        "engDate": "1922-02-18",
        "nepDate": "07.11.1978"
    },
    {
        "engDate": "1922-02-19",
        "nepDate": "08.11.1978"
    },
    {
        "engDate": "1922-02-20",
        "nepDate": "09.11.1978"
    },
    {
        "engDate": "1922-02-21",
        "nepDate": "10.11.1978"
    },
    {
        "engDate": "1922-02-22",
        "nepDate": "11.11.1978"
    },
    {
        "engDate": "1922-02-23",
        "nepDate": "12.11.1978"
    },
    {
        "engDate": "1922-02-24",
        "nepDate": "13.11.1978"
    },
    {
        "engDate": "1922-02-25",
        "nepDate": "14.11.1978"
    },
    {
        "engDate": "1922-02-26",
        "nepDate": "15.11.1978"
    },
    {
        "engDate": "1922-02-27",
        "nepDate": "16.11.1978"
    },
    {
        "engDate": "1922-02-28",
        "nepDate": "17.11.1978"
    },
    {
        "engDate": "1922-03-01",
        "nepDate": "18.11.1978"
    },
    {
        "engDate": "1922-03-02",
        "nepDate": "19.11.1978"
    },
    {
        "engDate": "1922-03-03",
        "nepDate": "20.11.1978"
    },
    {
        "engDate": "1922-03-04",
        "nepDate": "21.11.1978"
    },
    {
        "engDate": "1922-03-05",
        "nepDate": "22.11.1978"
    },
    {
        "engDate": "1922-03-06",
        "nepDate": "23.11.1978"
    },
    {
        "engDate": "1922-03-07",
        "nepDate": "24.11.1978"
    },
    {
        "engDate": "1922-03-08",
        "nepDate": "25.11.1978"
    },
    {
        "engDate": "1922-03-09",
        "nepDate": "26.11.1978"
    },
    {
        "engDate": "1922-03-10",
        "nepDate": "27.11.1978"
    },
    {
        "engDate": "1922-03-11",
        "nepDate": "28.11.1978"
    },
    {
        "engDate": "1922-03-12",
        "nepDate": "29.11.1978"
    },
    {
        "engDate": "1922-03-13",
        "nepDate": "30.11.1978"
    },
    {
        "engDate": "1922-03-14",
        "nepDate": "01.12.1978"
    },
    {
        "engDate": "1922-03-15",
        "nepDate": "02.12.1978"
    },
    {
        "engDate": "1922-03-16",
        "nepDate": "03.12.1978"
    },
    {
        "engDate": "1922-03-17",
        "nepDate": "04.12.1978"
    },
    {
        "engDate": "1922-03-18",
        "nepDate": "05.12.1978"
    },
    {
        "engDate": "1922-03-19",
        "nepDate": "06.12.1978"
    },
    {
        "engDate": "1922-03-20",
        "nepDate": "07.12.1978"
    },
    {
        "engDate": "1922-03-21",
        "nepDate": "08.12.1978"
    },
    {
        "engDate": "1922-03-22",
        "nepDate": "09.12.1978"
    },
    {
        "engDate": "1922-03-23",
        "nepDate": "10.12.1978"
    },
    {
        "engDate": "1922-03-24",
        "nepDate": "11.12.1978"
    },
    {
        "engDate": "1922-03-25",
        "nepDate": "12.12.1978"
    },
    {
        "engDate": "1922-03-26",
        "nepDate": "13.12.1978"
    },
    {
        "engDate": "1922-03-27",
        "nepDate": "14.12.1978"
    },
    {
        "engDate": "1922-03-28",
        "nepDate": "15.12.1978"
    },
    {
        "engDate": "1922-03-29",
        "nepDate": "16.12.1978"
    },
    {
        "engDate": "1922-03-30",
        "nepDate": "17.12.1978"
    },
    {
        "engDate": "1922-03-31",
        "nepDate": "18.12.1978"
    },
    {
        "engDate": "1922-04-01",
        "nepDate": "19.12.1978"
    },
    {
        "engDate": "1922-04-02",
        "nepDate": "20.12.1978"
    },
    {
        "engDate": "1922-04-03",
        "nepDate": "21.12.1978"
    },
    {
        "engDate": "1922-04-04",
        "nepDate": "22.12.1978"
    },
    {
        "engDate": "1922-04-05",
        "nepDate": "23.12.1978"
    },
    {
        "engDate": "1922-04-06",
        "nepDate": "24.12.1978"
    },
    {
        "engDate": "1922-04-07",
        "nepDate": "25.12.1978"
    },
    {
        "engDate": "1922-04-08",
        "nepDate": "26.12.1978"
    },
    {
        "engDate": "1922-04-09",
        "nepDate": "27.12.1978"
    },
    {
        "engDate": "1922-04-10",
        "nepDate": "28.12.1978"
    },
    {
        "engDate": "1922-04-11",
        "nepDate": "29.12.1978"
    },
    {
        "engDate": "1922-04-12",
        "nepDate": "30.12.1978"
    },
    {
        "engDate": "1922-04-13",
        "nepDate": "01.01.1979"
    },
    {
        "engDate": "1922-04-14",
        "nepDate": "02.01.1979"
    },
    {
        "engDate": "1922-04-15",
        "nepDate": "03.01.1979"
    },
    {
        "engDate": "1922-04-16",
        "nepDate": "04.01.1979"
    },
    {
        "engDate": "1922-04-17",
        "nepDate": "05.01.1979"
    },
    {
        "engDate": "1922-04-18",
        "nepDate": "06.01.1979"
    },
    {
        "engDate": "1922-04-19",
        "nepDate": "07.01.1979"
    },
    {
        "engDate": "1922-04-20",
        "nepDate": "08.01.1979"
    },
    {
        "engDate": "1922-04-21",
        "nepDate": "09.01.1979"
    },
    {
        "engDate": "1922-04-22",
        "nepDate": "10.01.1979"
    },
    {
        "engDate": "1922-04-23",
        "nepDate": "11.01.1979"
    },
    {
        "engDate": "1922-04-24",
        "nepDate": "12.01.1979"
    },
    {
        "engDate": "1922-04-25",
        "nepDate": "13.01.1979"
    },
    {
        "engDate": "1922-04-26",
        "nepDate": "14.01.1979"
    },
    {
        "engDate": "1922-04-27",
        "nepDate": "15.01.1979"
    },
    {
        "engDate": "1922-04-28",
        "nepDate": "16.01.1979"
    },
    {
        "engDate": "1922-04-29",
        "nepDate": "17.01.1979"
    },
    {
        "engDate": "1922-04-30",
        "nepDate": "18.01.1979"
    },
    {
        "engDate": "1922-05-01",
        "nepDate": "19.01.1979"
    },
    {
        "engDate": "1922-05-02",
        "nepDate": "20.01.1979"
    },
    {
        "engDate": "1922-05-03",
        "nepDate": "21.01.1979"
    },
    {
        "engDate": "1922-05-04",
        "nepDate": "22.01.1979"
    },
    {
        "engDate": "1922-05-05",
        "nepDate": "23.01.1979"
    },
    {
        "engDate": "1922-05-06",
        "nepDate": "24.01.1979"
    },
    {
        "engDate": "1922-05-07",
        "nepDate": "25.01.1979"
    },
    {
        "engDate": "1922-05-08",
        "nepDate": "26.01.1979"
    },
    {
        "engDate": "1922-05-09",
        "nepDate": "27.01.1979"
    },
    {
        "engDate": "1922-05-10",
        "nepDate": "28.01.1979"
    },
    {
        "engDate": "1922-05-11",
        "nepDate": "29.01.1979"
    },
    {
        "engDate": "1922-05-12",
        "nepDate": "30.01.1979"
    },
    {
        "engDate": "1922-05-13",
        "nepDate": "31.01.1979"
    },
    {
        "engDate": "1922-05-14",
        "nepDate": "01.02.1979"
    },
    {
        "engDate": "1922-05-15",
        "nepDate": "02.02.1979"
    },
    {
        "engDate": "1922-05-16",
        "nepDate": "03.02.1979"
    },
    {
        "engDate": "1922-05-17",
        "nepDate": "04.02.1979"
    },
    {
        "engDate": "1922-05-18",
        "nepDate": "05.02.1979"
    },
    {
        "engDate": "1922-05-19",
        "nepDate": "06.02.1979"
    },
    {
        "engDate": "1922-05-20",
        "nepDate": "07.02.1979"
    },
    {
        "engDate": "1922-05-21",
        "nepDate": "08.02.1979"
    },
    {
        "engDate": "1922-05-22",
        "nepDate": "09.02.1979"
    },
    {
        "engDate": "1922-05-23",
        "nepDate": "10.02.1979"
    },
    {
        "engDate": "1922-05-24",
        "nepDate": "11.02.1979"
    },
    {
        "engDate": "1922-05-25",
        "nepDate": "12.02.1979"
    },
    {
        "engDate": "1922-05-26",
        "nepDate": "13.02.1979"
    },
    {
        "engDate": "1922-05-27",
        "nepDate": "14.02.1979"
    },
    {
        "engDate": "1922-05-28",
        "nepDate": "15.02.1979"
    },
    {
        "engDate": "1922-05-29",
        "nepDate": "16.02.1979"
    },
    {
        "engDate": "1922-05-30",
        "nepDate": "17.02.1979"
    },
    {
        "engDate": "1922-05-31",
        "nepDate": "18.02.1979"
    },
    {
        "engDate": "1922-06-01",
        "nepDate": "19.02.1979"
    },
    {
        "engDate": "1922-06-02",
        "nepDate": "20.02.1979"
    },
    {
        "engDate": "1922-06-03",
        "nepDate": "21.02.1979"
    },
    {
        "engDate": "1922-06-04",
        "nepDate": "22.02.1979"
    },
    {
        "engDate": "1922-06-05",
        "nepDate": "23.02.1979"
    },
    {
        "engDate": "1922-06-06",
        "nepDate": "24.02.1979"
    },
    {
        "engDate": "1922-06-07",
        "nepDate": "25.02.1979"
    },
    {
        "engDate": "1922-06-08",
        "nepDate": "26.02.1979"
    },
    {
        "engDate": "1922-06-09",
        "nepDate": "27.02.1979"
    },
    {
        "engDate": "1922-06-10",
        "nepDate": "28.02.1979"
    },
    {
        "engDate": "1922-06-11",
        "nepDate": "29.02.1979"
    },
    {
        "engDate": "1922-06-12",
        "nepDate": "30.02.1979"
    },
    {
        "engDate": "1922-06-13",
        "nepDate": "31.02.1979"
    },
    {
        "engDate": "1922-06-14",
        "nepDate": "01.03.1979"
    },
    {
        "engDate": "1922-06-15",
        "nepDate": "02.03.1979"
    },
    {
        "engDate": "1922-06-16",
        "nepDate": "03.03.1979"
    },
    {
        "engDate": "1922-06-17",
        "nepDate": "04.03.1979"
    },
    {
        "engDate": "1922-06-18",
        "nepDate": "05.03.1979"
    },
    {
        "engDate": "1922-06-19",
        "nepDate": "06.03.1979"
    },
    {
        "engDate": "1922-06-20",
        "nepDate": "07.03.1979"
    },
    {
        "engDate": "1922-06-21",
        "nepDate": "08.03.1979"
    },
    {
        "engDate": "1922-06-22",
        "nepDate": "09.03.1979"
    },
    {
        "engDate": "1922-06-23",
        "nepDate": "10.03.1979"
    },
    {
        "engDate": "1922-06-24",
        "nepDate": "11.03.1979"
    },
    {
        "engDate": "1922-06-25",
        "nepDate": "12.03.1979"
    },
    {
        "engDate": "1922-06-26",
        "nepDate": "13.03.1979"
    },
    {
        "engDate": "1922-06-27",
        "nepDate": "14.03.1979"
    },
    {
        "engDate": "1922-06-28",
        "nepDate": "15.03.1979"
    },
    {
        "engDate": "1922-06-29",
        "nepDate": "16.03.1979"
    },
    {
        "engDate": "1922-06-30",
        "nepDate": "17.03.1979"
    },
    {
        "engDate": "1922-07-01",
        "nepDate": "18.03.1979"
    },
    {
        "engDate": "1922-07-02",
        "nepDate": "19.03.1979"
    },
    {
        "engDate": "1922-07-03",
        "nepDate": "20.03.1979"
    },
    {
        "engDate": "1922-07-04",
        "nepDate": "21.03.1979"
    },
    {
        "engDate": "1922-07-05",
        "nepDate": "22.03.1979"
    },
    {
        "engDate": "1922-07-06",
        "nepDate": "23.03.1979"
    },
    {
        "engDate": "1922-07-07",
        "nepDate": "24.03.1979"
    },
    {
        "engDate": "1922-07-08",
        "nepDate": "25.03.1979"
    },
    {
        "engDate": "1922-07-09",
        "nepDate": "26.03.1979"
    },
    {
        "engDate": "1922-07-10",
        "nepDate": "27.03.1979"
    },
    {
        "engDate": "1922-07-11",
        "nepDate": "28.03.1979"
    },
    {
        "engDate": "1922-07-12",
        "nepDate": "29.03.1979"
    },
    {
        "engDate": "1922-07-13",
        "nepDate": "30.03.1979"
    },
    {
        "engDate": "1922-07-14",
        "nepDate": "31.03.1979"
    },
    {
        "engDate": "1922-07-15",
        "nepDate": "32.03.1979"
    },
    {
        "engDate": "1922-07-16",
        "nepDate": "01.04.1979"
    },
    {
        "engDate": "1922-07-17",
        "nepDate": "02.04.1979"
    },
    {
        "engDate": "1922-07-18",
        "nepDate": "03.04.1979"
    },
    {
        "engDate": "1922-07-19",
        "nepDate": "04.04.1979"
    },
    {
        "engDate": "1922-07-20",
        "nepDate": "05.04.1979"
    },
    {
        "engDate": "1922-07-21",
        "nepDate": "06.04.1979"
    },
    {
        "engDate": "1922-07-22",
        "nepDate": "07.04.1979"
    },
    {
        "engDate": "1922-07-23",
        "nepDate": "08.04.1979"
    },
    {
        "engDate": "1922-07-24",
        "nepDate": "09.04.1979"
    },
    {
        "engDate": "1922-07-25",
        "nepDate": "10.04.1979"
    },
    {
        "engDate": "1922-07-26",
        "nepDate": "11.04.1979"
    },
    {
        "engDate": "1922-07-27",
        "nepDate": "12.04.1979"
    },
    {
        "engDate": "1922-07-28",
        "nepDate": "13.04.1979"
    },
    {
        "engDate": "1922-07-29",
        "nepDate": "14.04.1979"
    },
    {
        "engDate": "1922-07-30",
        "nepDate": "15.04.1979"
    },
    {
        "engDate": "1922-07-31",
        "nepDate": "16.04.1979"
    },
    {
        "engDate": "1922-08-01",
        "nepDate": "17.04.1979"
    },
    {
        "engDate": "1922-08-02",
        "nepDate": "18.04.1979"
    },
    {
        "engDate": "1922-08-03",
        "nepDate": "19.04.1979"
    },
    {
        "engDate": "1922-08-04",
        "nepDate": "20.04.1979"
    },
    {
        "engDate": "1922-08-05",
        "nepDate": "21.04.1979"
    },
    {
        "engDate": "1922-08-06",
        "nepDate": "22.04.1979"
    },
    {
        "engDate": "1922-08-07",
        "nepDate": "23.04.1979"
    },
    {
        "engDate": "1922-08-08",
        "nepDate": "24.04.1979"
    },
    {
        "engDate": "1922-08-09",
        "nepDate": "25.04.1979"
    },
    {
        "engDate": "1922-08-10",
        "nepDate": "26.04.1979"
    },
    {
        "engDate": "1922-08-11",
        "nepDate": "27.04.1979"
    },
    {
        "engDate": "1922-08-12",
        "nepDate": "28.04.1979"
    },
    {
        "engDate": "1922-08-13",
        "nepDate": "29.04.1979"
    },
    {
        "engDate": "1922-08-14",
        "nepDate": "30.04.1979"
    },
    {
        "engDate": "1922-08-15",
        "nepDate": "31.04.1979"
    },
    {
        "engDate": "1922-08-16",
        "nepDate": "32.04.1979"
    },
    {
        "engDate": "1922-08-17",
        "nepDate": "01.05.1979"
    },
    {
        "engDate": "1922-08-18",
        "nepDate": "02.05.1979"
    },
    {
        "engDate": "1922-08-19",
        "nepDate": "03.05.1979"
    },
    {
        "engDate": "1922-08-20",
        "nepDate": "04.05.1979"
    },
    {
        "engDate": "1922-08-21",
        "nepDate": "05.05.1979"
    },
    {
        "engDate": "1922-08-22",
        "nepDate": "06.05.1979"
    },
    {
        "engDate": "1922-08-23",
        "nepDate": "07.05.1979"
    },
    {
        "engDate": "1922-08-24",
        "nepDate": "08.05.1979"
    },
    {
        "engDate": "1922-08-25",
        "nepDate": "09.05.1979"
    },
    {
        "engDate": "1922-08-26",
        "nepDate": "10.05.1979"
    },
    {
        "engDate": "1922-08-27",
        "nepDate": "11.05.1979"
    },
    {
        "engDate": "1922-08-28",
        "nepDate": "12.05.1979"
    },
    {
        "engDate": "1922-08-29",
        "nepDate": "13.05.1979"
    },
    {
        "engDate": "1922-08-30",
        "nepDate": "14.05.1979"
    },
    {
        "engDate": "1922-08-31",
        "nepDate": "15.05.1979"
    },
    {
        "engDate": "1922-09-01",
        "nepDate": "16.05.1979"
    },
    {
        "engDate": "1922-09-02",
        "nepDate": "17.05.1979"
    },
    {
        "engDate": "1922-09-03",
        "nepDate": "18.05.1979"
    },
    {
        "engDate": "1922-09-04",
        "nepDate": "19.05.1979"
    },
    {
        "engDate": "1922-09-05",
        "nepDate": "20.05.1979"
    },
    {
        "engDate": "1922-09-06",
        "nepDate": "21.05.1979"
    },
    {
        "engDate": "1922-09-07",
        "nepDate": "22.05.1979"
    },
    {
        "engDate": "1922-09-08",
        "nepDate": "23.05.1979"
    },
    {
        "engDate": "1922-09-09",
        "nepDate": "24.05.1979"
    },
    {
        "engDate": "1922-09-10",
        "nepDate": "25.05.1979"
    },
    {
        "engDate": "1922-09-11",
        "nepDate": "26.05.1979"
    },
    {
        "engDate": "1922-09-12",
        "nepDate": "27.05.1979"
    },
    {
        "engDate": "1922-09-13",
        "nepDate": "28.05.1979"
    },
    {
        "engDate": "1922-09-14",
        "nepDate": "29.05.1979"
    },
    {
        "engDate": "1922-09-15",
        "nepDate": "30.05.1979"
    },
    {
        "engDate": "1922-09-16",
        "nepDate": "31.05.1979"
    },
    {
        "engDate": "1922-09-17",
        "nepDate": "01.06.1979"
    },
    {
        "engDate": "1922-09-18",
        "nepDate": "02.06.1979"
    },
    {
        "engDate": "1922-09-19",
        "nepDate": "03.06.1979"
    },
    {
        "engDate": "1922-09-20",
        "nepDate": "04.06.1979"
    },
    {
        "engDate": "1922-09-21",
        "nepDate": "05.06.1979"
    },
    {
        "engDate": "1922-09-22",
        "nepDate": "06.06.1979"
    },
    {
        "engDate": "1922-09-23",
        "nepDate": "07.06.1979"
    },
    {
        "engDate": "1922-09-24",
        "nepDate": "08.06.1979"
    },
    {
        "engDate": "1922-09-25",
        "nepDate": "09.06.1979"
    },
    {
        "engDate": "1922-09-26",
        "nepDate": "10.06.1979"
    },
    {
        "engDate": "1922-09-27",
        "nepDate": "11.06.1979"
    },
    {
        "engDate": "1922-09-28",
        "nepDate": "12.06.1979"
    },
    {
        "engDate": "1922-09-29",
        "nepDate": "13.06.1979"
    },
    {
        "engDate": "1922-09-30",
        "nepDate": "14.06.1979"
    },
    {
        "engDate": "1922-10-01",
        "nepDate": "15.06.1979"
    },
    {
        "engDate": "1922-10-02",
        "nepDate": "16.06.1979"
    },
    {
        "engDate": "1922-10-03",
        "nepDate": "17.06.1979"
    },
    {
        "engDate": "1922-10-04",
        "nepDate": "18.06.1979"
    },
    {
        "engDate": "1922-10-05",
        "nepDate": "19.06.1979"
    },
    {
        "engDate": "1922-10-06",
        "nepDate": "20.06.1979"
    },
    {
        "engDate": "1922-10-07",
        "nepDate": "21.06.1979"
    },
    {
        "engDate": "1922-10-08",
        "nepDate": "22.06.1979"
    },
    {
        "engDate": "1922-10-09",
        "nepDate": "23.06.1979"
    },
    {
        "engDate": "1922-10-10",
        "nepDate": "24.06.1979"
    },
    {
        "engDate": "1922-10-11",
        "nepDate": "25.06.1979"
    },
    {
        "engDate": "1922-10-12",
        "nepDate": "26.06.1979"
    },
    {
        "engDate": "1922-10-13",
        "nepDate": "27.06.1979"
    },
    {
        "engDate": "1922-10-14",
        "nepDate": "28.06.1979"
    },
    {
        "engDate": "1922-10-15",
        "nepDate": "29.06.1979"
    },
    {
        "engDate": "1922-10-16",
        "nepDate": "30.06.1979"
    },
    {
        "engDate": "1922-10-17",
        "nepDate": "01.07.1979"
    },
    {
        "engDate": "1922-10-18",
        "nepDate": "02.07.1979"
    },
    {
        "engDate": "1922-10-19",
        "nepDate": "03.07.1979"
    },
    {
        "engDate": "1922-10-20",
        "nepDate": "04.07.1979"
    },
    {
        "engDate": "1922-10-21",
        "nepDate": "05.07.1979"
    },
    {
        "engDate": "1922-10-22",
        "nepDate": "06.07.1979"
    },
    {
        "engDate": "1922-10-23",
        "nepDate": "07.07.1979"
    },
    {
        "engDate": "1922-10-24",
        "nepDate": "08.07.1979"
    },
    {
        "engDate": "1922-10-25",
        "nepDate": "09.07.1979"
    },
    {
        "engDate": "1922-10-26",
        "nepDate": "10.07.1979"
    },
    {
        "engDate": "1922-10-27",
        "nepDate": "11.07.1979"
    },
    {
        "engDate": "1922-10-28",
        "nepDate": "12.07.1979"
    },
    {
        "engDate": "1922-10-29",
        "nepDate": "13.07.1979"
    },
    {
        "engDate": "1922-10-30",
        "nepDate": "14.07.1979"
    },
    {
        "engDate": "1922-10-31",
        "nepDate": "15.07.1979"
    },
    {
        "engDate": "1922-11-01",
        "nepDate": "16.07.1979"
    },
    {
        "engDate": "1922-11-02",
        "nepDate": "17.07.1979"
    },
    {
        "engDate": "1922-11-03",
        "nepDate": "18.07.1979"
    },
    {
        "engDate": "1922-11-04",
        "nepDate": "19.07.1979"
    },
    {
        "engDate": "1922-11-05",
        "nepDate": "20.07.1979"
    },
    {
        "engDate": "1922-11-06",
        "nepDate": "21.07.1979"
    },
    {
        "engDate": "1922-11-07",
        "nepDate": "22.07.1979"
    },
    {
        "engDate": "1922-11-08",
        "nepDate": "23.07.1979"
    },
    {
        "engDate": "1922-11-09",
        "nepDate": "24.07.1979"
    },
    {
        "engDate": "1922-11-10",
        "nepDate": "25.07.1979"
    },
    {
        "engDate": "1922-11-11",
        "nepDate": "26.07.1979"
    },
    {
        "engDate": "1922-11-12",
        "nepDate": "27.07.1979"
    },
    {
        "engDate": "1922-11-13",
        "nepDate": "28.07.1979"
    },
    {
        "engDate": "1922-11-14",
        "nepDate": "29.07.1979"
    },
    {
        "engDate": "1922-11-15",
        "nepDate": "30.07.1979"
    },
    {
        "engDate": "1922-11-16",
        "nepDate": "01.08.1979"
    },
    {
        "engDate": "1922-11-17",
        "nepDate": "02.08.1979"
    },
    {
        "engDate": "1922-11-18",
        "nepDate": "03.08.1979"
    },
    {
        "engDate": "1922-11-19",
        "nepDate": "04.08.1979"
    },
    {
        "engDate": "1922-11-20",
        "nepDate": "05.08.1979"
    },
    {
        "engDate": "1922-11-21",
        "nepDate": "06.08.1979"
    },
    {
        "engDate": "1922-11-22",
        "nepDate": "07.08.1979"
    },
    {
        "engDate": "1922-11-23",
        "nepDate": "08.08.1979"
    },
    {
        "engDate": "1922-11-24",
        "nepDate": "09.08.1979"
    },
    {
        "engDate": "1922-11-25",
        "nepDate": "10.08.1979"
    },
    {
        "engDate": "1922-11-26",
        "nepDate": "11.08.1979"
    },
    {
        "engDate": "1922-11-27",
        "nepDate": "12.08.1979"
    },
    {
        "engDate": "1922-11-28",
        "nepDate": "13.08.1979"
    },
    {
        "engDate": "1922-11-29",
        "nepDate": "14.08.1979"
    },
    {
        "engDate": "1922-11-30",
        "nepDate": "15.08.1979"
    },
    {
        "engDate": "1922-12-01",
        "nepDate": "16.08.1979"
    },
    {
        "engDate": "1922-12-02",
        "nepDate": "17.08.1979"
    },
    {
        "engDate": "1922-12-03",
        "nepDate": "18.08.1979"
    },
    {
        "engDate": "1922-12-04",
        "nepDate": "19.08.1979"
    },
    {
        "engDate": "1922-12-05",
        "nepDate": "20.08.1979"
    },
    {
        "engDate": "1922-12-06",
        "nepDate": "21.08.1979"
    },
    {
        "engDate": "1922-12-07",
        "nepDate": "22.08.1979"
    },
    {
        "engDate": "1922-12-08",
        "nepDate": "23.08.1979"
    },
    {
        "engDate": "1922-12-09",
        "nepDate": "24.08.1979"
    },
    {
        "engDate": "1922-12-10",
        "nepDate": "25.08.1979"
    },
    {
        "engDate": "1922-12-11",
        "nepDate": "26.08.1979"
    },
    {
        "engDate": "1922-12-12",
        "nepDate": "27.08.1979"
    },
    {
        "engDate": "1922-12-13",
        "nepDate": "28.08.1979"
    },
    {
        "engDate": "1922-12-14",
        "nepDate": "29.08.1979"
    },
    {
        "engDate": "1922-12-15",
        "nepDate": "01.09.1979"
    },
    {
        "engDate": "1922-12-16",
        "nepDate": "02.09.1979"
    },
    {
        "engDate": "1922-12-17",
        "nepDate": "03.09.1979"
    },
    {
        "engDate": "1922-12-18",
        "nepDate": "04.09.1979"
    },
    {
        "engDate": "1922-12-19",
        "nepDate": "05.09.1979"
    },
    {
        "engDate": "1922-12-20",
        "nepDate": "06.09.1979"
    },
    {
        "engDate": "1922-12-21",
        "nepDate": "07.09.1979"
    },
    {
        "engDate": "1922-12-22",
        "nepDate": "08.09.1979"
    },
    {
        "engDate": "1922-12-23",
        "nepDate": "09.09.1979"
    },
    {
        "engDate": "1922-12-24",
        "nepDate": "10.09.1979"
    },
    {
        "engDate": "1922-12-25",
        "nepDate": "11.09.1979"
    },
    {
        "engDate": "1922-12-26",
        "nepDate": "12.09.1979"
    },
    {
        "engDate": "1922-12-27",
        "nepDate": "13.09.1979"
    },
    {
        "engDate": "1922-12-28",
        "nepDate": "14.09.1979"
    },
    {
        "engDate": "1922-12-29",
        "nepDate": "15.09.1979"
    },
    {
        "engDate": "1922-12-30",
        "nepDate": "16.09.1979"
    },
    {
        "engDate": "1922-12-31",
        "nepDate": "17.09.1979"
    },
    {
        "engDate": "1923-01-01",
        "nepDate": "18.09.1979"
    },
    {
        "engDate": "1923-01-02",
        "nepDate": "19.09.1979"
    },
    {
        "engDate": "1923-01-03",
        "nepDate": "20.09.1979"
    },
    {
        "engDate": "1923-01-04",
        "nepDate": "21.09.1979"
    },
    {
        "engDate": "1923-01-05",
        "nepDate": "22.09.1979"
    },
    {
        "engDate": "1923-01-06",
        "nepDate": "23.09.1979"
    },
    {
        "engDate": "1923-01-07",
        "nepDate": "24.09.1979"
    },
    {
        "engDate": "1923-01-08",
        "nepDate": "25.09.1979"
    },
    {
        "engDate": "1923-01-09",
        "nepDate": "26.09.1979"
    },
    {
        "engDate": "1923-01-10",
        "nepDate": "27.09.1979"
    },
    {
        "engDate": "1923-01-11",
        "nepDate": "28.09.1979"
    },
    {
        "engDate": "1923-01-12",
        "nepDate": "29.09.1979"
    },
    {
        "engDate": "1923-01-13",
        "nepDate": "30.09.1979"
    },
    {
        "engDate": "1923-01-14",
        "nepDate": "01.10.1979"
    },
    {
        "engDate": "1923-01-15",
        "nepDate": "02.10.1979"
    },
    {
        "engDate": "1923-01-16",
        "nepDate": "03.10.1979"
    },
    {
        "engDate": "1923-01-17",
        "nepDate": "04.10.1979"
    },
    {
        "engDate": "1923-01-18",
        "nepDate": "05.10.1979"
    },
    {
        "engDate": "1923-01-19",
        "nepDate": "06.10.1979"
    },
    {
        "engDate": "1923-01-20",
        "nepDate": "07.10.1979"
    },
    {
        "engDate": "1923-01-21",
        "nepDate": "08.10.1979"
    },
    {
        "engDate": "1923-01-22",
        "nepDate": "09.10.1979"
    },
    {
        "engDate": "1923-01-23",
        "nepDate": "10.10.1979"
    },
    {
        "engDate": "1923-01-24",
        "nepDate": "11.10.1979"
    },
    {
        "engDate": "1923-01-25",
        "nepDate": "12.10.1979"
    },
    {
        "engDate": "1923-01-26",
        "nepDate": "13.10.1979"
    },
    {
        "engDate": "1923-01-27",
        "nepDate": "14.10.1979"
    },
    {
        "engDate": "1923-01-28",
        "nepDate": "15.10.1979"
    },
    {
        "engDate": "1923-01-29",
        "nepDate": "16.10.1979"
    },
    {
        "engDate": "1923-01-30",
        "nepDate": "17.10.1979"
    },
    {
        "engDate": "1923-01-31",
        "nepDate": "18.10.1979"
    },
    {
        "engDate": "1923-02-01",
        "nepDate": "19.10.1979"
    },
    {
        "engDate": "1923-02-02",
        "nepDate": "20.10.1979"
    },
    {
        "engDate": "1923-02-03",
        "nepDate": "21.10.1979"
    },
    {
        "engDate": "1923-02-04",
        "nepDate": "22.10.1979"
    },
    {
        "engDate": "1923-02-05",
        "nepDate": "23.10.1979"
    },
    {
        "engDate": "1923-02-06",
        "nepDate": "24.10.1979"
    },
    {
        "engDate": "1923-02-07",
        "nepDate": "25.10.1979"
    },
    {
        "engDate": "1923-02-08",
        "nepDate": "26.10.1979"
    },
    {
        "engDate": "1923-02-09",
        "nepDate": "27.10.1979"
    },
    {
        "engDate": "1923-02-10",
        "nepDate": "28.10.1979"
    },
    {
        "engDate": "1923-02-11",
        "nepDate": "29.10.1979"
    },
    {
        "engDate": "1923-02-12",
        "nepDate": "01.11.1979"
    },
    {
        "engDate": "1923-02-13",
        "nepDate": "02.11.1979"
    },
    {
        "engDate": "1923-02-14",
        "nepDate": "03.11.1979"
    },
    {
        "engDate": "1923-02-15",
        "nepDate": "04.11.1979"
    },
    {
        "engDate": "1923-02-16",
        "nepDate": "05.11.1979"
    },
    {
        "engDate": "1923-02-17",
        "nepDate": "06.11.1979"
    },
    {
        "engDate": "1923-02-18",
        "nepDate": "07.11.1979"
    },
    {
        "engDate": "1923-02-19",
        "nepDate": "08.11.1979"
    },
    {
        "engDate": "1923-02-20",
        "nepDate": "09.11.1979"
    },
    {
        "engDate": "1923-02-21",
        "nepDate": "10.11.1979"
    },
    {
        "engDate": "1923-02-22",
        "nepDate": "11.11.1979"
    },
    {
        "engDate": "1923-02-23",
        "nepDate": "12.11.1979"
    },
    {
        "engDate": "1923-02-24",
        "nepDate": "13.11.1979"
    },
    {
        "engDate": "1923-02-25",
        "nepDate": "14.11.1979"
    },
    {
        "engDate": "1923-02-26",
        "nepDate": "15.11.1979"
    },
    {
        "engDate": "1923-02-27",
        "nepDate": "16.11.1979"
    },
    {
        "engDate": "1923-02-28",
        "nepDate": "17.11.1979"
    },
    {
        "engDate": "1923-03-01",
        "nepDate": "18.11.1979"
    },
    {
        "engDate": "1923-03-02",
        "nepDate": "19.11.1979"
    },
    {
        "engDate": "1923-03-03",
        "nepDate": "20.11.1979"
    },
    {
        "engDate": "1923-03-04",
        "nepDate": "21.11.1979"
    },
    {
        "engDate": "1923-03-05",
        "nepDate": "22.11.1979"
    },
    {
        "engDate": "1923-03-06",
        "nepDate": "23.11.1979"
    },
    {
        "engDate": "1923-03-07",
        "nepDate": "24.11.1979"
    },
    {
        "engDate": "1923-03-08",
        "nepDate": "25.11.1979"
    },
    {
        "engDate": "1923-03-09",
        "nepDate": "26.11.1979"
    },
    {
        "engDate": "1923-03-10",
        "nepDate": "27.11.1979"
    },
    {
        "engDate": "1923-03-11",
        "nepDate": "28.11.1979"
    },
    {
        "engDate": "1923-03-12",
        "nepDate": "29.11.1979"
    },
    {
        "engDate": "1923-03-13",
        "nepDate": "30.11.1979"
    },
    {
        "engDate": "1923-03-14",
        "nepDate": "01.12.1979"
    },
    {
        "engDate": "1923-03-15",
        "nepDate": "02.12.1979"
    },
    {
        "engDate": "1923-03-16",
        "nepDate": "03.12.1979"
    },
    {
        "engDate": "1923-03-17",
        "nepDate": "04.12.1979"
    },
    {
        "engDate": "1923-03-18",
        "nepDate": "05.12.1979"
    },
    {
        "engDate": "1923-03-19",
        "nepDate": "06.12.1979"
    },
    {
        "engDate": "1923-03-20",
        "nepDate": "07.12.1979"
    },
    {
        "engDate": "1923-03-21",
        "nepDate": "08.12.1979"
    },
    {
        "engDate": "1923-03-22",
        "nepDate": "09.12.1979"
    },
    {
        "engDate": "1923-03-23",
        "nepDate": "10.12.1979"
    },
    {
        "engDate": "1923-03-24",
        "nepDate": "11.12.1979"
    },
    {
        "engDate": "1923-03-25",
        "nepDate": "12.12.1979"
    },
    {
        "engDate": "1923-03-26",
        "nepDate": "13.12.1979"
    },
    {
        "engDate": "1923-03-27",
        "nepDate": "14.12.1979"
    },
    {
        "engDate": "1923-03-28",
        "nepDate": "15.12.1979"
    },
    {
        "engDate": "1923-03-29",
        "nepDate": "16.12.1979"
    },
    {
        "engDate": "1923-03-30",
        "nepDate": "17.12.1979"
    },
    {
        "engDate": "1923-03-31",
        "nepDate": "18.12.1979"
    },
    {
        "engDate": "1923-04-01",
        "nepDate": "19.12.1979"
    },
    {
        "engDate": "1923-04-02",
        "nepDate": "20.12.1979"
    },
    {
        "engDate": "1923-04-03",
        "nepDate": "21.12.1979"
    },
    {
        "engDate": "1923-04-04",
        "nepDate": "22.12.1979"
    },
    {
        "engDate": "1923-04-05",
        "nepDate": "23.12.1979"
    },
    {
        "engDate": "1923-04-06",
        "nepDate": "24.12.1979"
    },
    {
        "engDate": "1923-04-07",
        "nepDate": "25.12.1979"
    },
    {
        "engDate": "1923-04-08",
        "nepDate": "26.12.1979"
    },
    {
        "engDate": "1923-04-09",
        "nepDate": "27.12.1979"
    },
    {
        "engDate": "1923-04-10",
        "nepDate": "28.12.1979"
    },
    {
        "engDate": "1923-04-11",
        "nepDate": "29.12.1979"
    },
    {
        "engDate": "1923-04-12",
        "nepDate": "30.12.1979"
    },
    {
        "engDate": "1923-04-13",
        "nepDate": "01.01.1980"
    },
    {
        "engDate": "1923-04-14",
        "nepDate": "02.01.1980"
    },
    {
        "engDate": "1923-04-15",
        "nepDate": "03.01.1980"
    },
    {
        "engDate": "1923-04-16",
        "nepDate": "04.01.1980"
    },
    {
        "engDate": "1923-04-17",
        "nepDate": "05.01.1980"
    },
    {
        "engDate": "1923-04-18",
        "nepDate": "06.01.1980"
    },
    {
        "engDate": "1923-04-19",
        "nepDate": "07.01.1980"
    },
    {
        "engDate": "1923-04-20",
        "nepDate": "08.01.1980"
    },
    {
        "engDate": "1923-04-21",
        "nepDate": "09.01.1980"
    },
    {
        "engDate": "1923-04-22",
        "nepDate": "10.01.1980"
    },
    {
        "engDate": "1923-04-23",
        "nepDate": "11.01.1980"
    },
    {
        "engDate": "1923-04-24",
        "nepDate": "12.01.1980"
    },
    {
        "engDate": "1923-04-25",
        "nepDate": "13.01.1980"
    },
    {
        "engDate": "1923-04-26",
        "nepDate": "14.01.1980"
    },
    {
        "engDate": "1923-04-27",
        "nepDate": "15.01.1980"
    },
    {
        "engDate": "1923-04-28",
        "nepDate": "16.01.1980"
    },
    {
        "engDate": "1923-04-29",
        "nepDate": "17.01.1980"
    },
    {
        "engDate": "1923-04-30",
        "nepDate": "18.01.1980"
    },
    {
        "engDate": "1923-05-01",
        "nepDate": "19.01.1980"
    },
    {
        "engDate": "1923-05-02",
        "nepDate": "20.01.1980"
    },
    {
        "engDate": "1923-05-03",
        "nepDate": "21.01.1980"
    },
    {
        "engDate": "1923-05-04",
        "nepDate": "22.01.1980"
    },
    {
        "engDate": "1923-05-05",
        "nepDate": "23.01.1980"
    },
    {
        "engDate": "1923-05-06",
        "nepDate": "24.01.1980"
    },
    {
        "engDate": "1923-05-07",
        "nepDate": "25.01.1980"
    },
    {
        "engDate": "1923-05-08",
        "nepDate": "26.01.1980"
    },
    {
        "engDate": "1923-05-09",
        "nepDate": "27.01.1980"
    },
    {
        "engDate": "1923-05-10",
        "nepDate": "28.01.1980"
    },
    {
        "engDate": "1923-05-11",
        "nepDate": "29.01.1980"
    },
    {
        "engDate": "1923-05-12",
        "nepDate": "30.01.1980"
    },
    {
        "engDate": "1923-05-13",
        "nepDate": "31.01.1980"
    },
    {
        "engDate": "1923-05-14",
        "nepDate": "01.02.1980"
    },
    {
        "engDate": "1923-05-15",
        "nepDate": "02.02.1980"
    },
    {
        "engDate": "1923-05-16",
        "nepDate": "03.02.1980"
    },
    {
        "engDate": "1923-05-17",
        "nepDate": "04.02.1980"
    },
    {
        "engDate": "1923-05-18",
        "nepDate": "05.02.1980"
    },
    {
        "engDate": "1923-05-19",
        "nepDate": "06.02.1980"
    },
    {
        "engDate": "1923-05-20",
        "nepDate": "07.02.1980"
    },
    {
        "engDate": "1923-05-21",
        "nepDate": "08.02.1980"
    },
    {
        "engDate": "1923-05-22",
        "nepDate": "09.02.1980"
    },
    {
        "engDate": "1923-05-23",
        "nepDate": "10.02.1980"
    },
    {
        "engDate": "1923-05-24",
        "nepDate": "11.02.1980"
    },
    {
        "engDate": "1923-05-25",
        "nepDate": "12.02.1980"
    },
    {
        "engDate": "1923-05-26",
        "nepDate": "13.02.1980"
    },
    {
        "engDate": "1923-05-27",
        "nepDate": "14.02.1980"
    },
    {
        "engDate": "1923-05-28",
        "nepDate": "15.02.1980"
    },
    {
        "engDate": "1923-05-29",
        "nepDate": "16.02.1980"
    },
    {
        "engDate": "1923-05-30",
        "nepDate": "17.02.1980"
    },
    {
        "engDate": "1923-05-31",
        "nepDate": "18.02.1980"
    },
    {
        "engDate": "1923-06-01",
        "nepDate": "19.02.1980"
    },
    {
        "engDate": "1923-06-02",
        "nepDate": "20.02.1980"
    },
    {
        "engDate": "1923-06-03",
        "nepDate": "21.02.1980"
    },
    {
        "engDate": "1923-06-04",
        "nepDate": "22.02.1980"
    },
    {
        "engDate": "1923-06-05",
        "nepDate": "23.02.1980"
    },
    {
        "engDate": "1923-06-06",
        "nepDate": "24.02.1980"
    },
    {
        "engDate": "1923-06-07",
        "nepDate": "25.02.1980"
    },
    {
        "engDate": "1923-06-08",
        "nepDate": "26.02.1980"
    },
    {
        "engDate": "1923-06-09",
        "nepDate": "27.02.1980"
    },
    {
        "engDate": "1923-06-10",
        "nepDate": "28.02.1980"
    },
    {
        "engDate": "1923-06-11",
        "nepDate": "29.02.1980"
    },
    {
        "engDate": "1923-06-12",
        "nepDate": "30.02.1980"
    },
    {
        "engDate": "1923-06-13",
        "nepDate": "31.02.1980"
    },
    {
        "engDate": "1923-06-14",
        "nepDate": "32.02.1980"
    },
    {
        "engDate": "1923-06-15",
        "nepDate": "01.03.1980"
    },
    {
        "engDate": "1923-06-16",
        "nepDate": "02.03.1980"
    },
    {
        "engDate": "1923-06-17",
        "nepDate": "03.03.1980"
    },
    {
        "engDate": "1923-06-18",
        "nepDate": "04.03.1980"
    },
    {
        "engDate": "1923-06-19",
        "nepDate": "05.03.1980"
    },
    {
        "engDate": "1923-06-20",
        "nepDate": "06.03.1980"
    },
    {
        "engDate": "1923-06-21",
        "nepDate": "07.03.1980"
    },
    {
        "engDate": "1923-06-22",
        "nepDate": "08.03.1980"
    },
    {
        "engDate": "1923-06-23",
        "nepDate": "09.03.1980"
    },
    {
        "engDate": "1923-06-24",
        "nepDate": "10.03.1980"
    },
    {
        "engDate": "1923-06-25",
        "nepDate": "11.03.1980"
    },
    {
        "engDate": "1923-06-26",
        "nepDate": "12.03.1980"
    },
    {
        "engDate": "1923-06-27",
        "nepDate": "13.03.1980"
    },
    {
        "engDate": "1923-06-28",
        "nepDate": "14.03.1980"
    },
    {
        "engDate": "1923-06-29",
        "nepDate": "15.03.1980"
    },
    {
        "engDate": "1923-06-30",
        "nepDate": "16.03.1980"
    },
    {
        "engDate": "1923-07-01",
        "nepDate": "17.03.1980"
    },
    {
        "engDate": "1923-07-02",
        "nepDate": "18.03.1980"
    },
    {
        "engDate": "1923-07-03",
        "nepDate": "19.03.1980"
    },
    {
        "engDate": "1923-07-04",
        "nepDate": "20.03.1980"
    },
    {
        "engDate": "1923-07-05",
        "nepDate": "21.03.1980"
    },
    {
        "engDate": "1923-07-06",
        "nepDate": "22.03.1980"
    },
    {
        "engDate": "1923-07-07",
        "nepDate": "23.03.1980"
    },
    {
        "engDate": "1923-07-08",
        "nepDate": "24.03.1980"
    },
    {
        "engDate": "1923-07-09",
        "nepDate": "25.03.1980"
    },
    {
        "engDate": "1923-07-10",
        "nepDate": "26.03.1980"
    },
    {
        "engDate": "1923-07-11",
        "nepDate": "27.03.1980"
    },
    {
        "engDate": "1923-07-12",
        "nepDate": "28.03.1980"
    },
    {
        "engDate": "1923-07-13",
        "nepDate": "29.03.1980"
    },
    {
        "engDate": "1923-07-14",
        "nepDate": "30.03.1980"
    },
    {
        "engDate": "1923-07-15",
        "nepDate": "31.03.1980"
    },
    {
        "engDate": "1923-07-16",
        "nepDate": "01.04.1980"
    },
    {
        "engDate": "1923-07-17",
        "nepDate": "02.04.1980"
    },
    {
        "engDate": "1923-07-18",
        "nepDate": "03.04.1980"
    },
    {
        "engDate": "1923-07-19",
        "nepDate": "04.04.1980"
    },
    {
        "engDate": "1923-07-20",
        "nepDate": "05.04.1980"
    },
    {
        "engDate": "1923-07-21",
        "nepDate": "06.04.1980"
    },
    {
        "engDate": "1923-07-22",
        "nepDate": "07.04.1980"
    },
    {
        "engDate": "1923-07-23",
        "nepDate": "08.04.1980"
    },
    {
        "engDate": "1923-07-24",
        "nepDate": "09.04.1980"
    },
    {
        "engDate": "1923-07-25",
        "nepDate": "10.04.1980"
    },
    {
        "engDate": "1923-07-26",
        "nepDate": "11.04.1980"
    },
    {
        "engDate": "1923-07-27",
        "nepDate": "12.04.1980"
    },
    {
        "engDate": "1923-07-28",
        "nepDate": "13.04.1980"
    },
    {
        "engDate": "1923-07-29",
        "nepDate": "14.04.1980"
    },
    {
        "engDate": "1923-07-30",
        "nepDate": "15.04.1980"
    },
    {
        "engDate": "1923-07-31",
        "nepDate": "16.04.1980"
    },
    {
        "engDate": "1923-08-01",
        "nepDate": "17.04.1980"
    },
    {
        "engDate": "1923-08-02",
        "nepDate": "18.04.1980"
    },
    {
        "engDate": "1923-08-03",
        "nepDate": "19.04.1980"
    },
    {
        "engDate": "1923-08-04",
        "nepDate": "20.04.1980"
    },
    {
        "engDate": "1923-08-05",
        "nepDate": "21.04.1980"
    },
    {
        "engDate": "1923-08-06",
        "nepDate": "22.04.1980"
    },
    {
        "engDate": "1923-08-07",
        "nepDate": "23.04.1980"
    },
    {
        "engDate": "1923-08-08",
        "nepDate": "24.04.1980"
    },
    {
        "engDate": "1923-08-09",
        "nepDate": "25.04.1980"
    },
    {
        "engDate": "1923-08-10",
        "nepDate": "26.04.1980"
    },
    {
        "engDate": "1923-08-11",
        "nepDate": "27.04.1980"
    },
    {
        "engDate": "1923-08-12",
        "nepDate": "28.04.1980"
    },
    {
        "engDate": "1923-08-13",
        "nepDate": "29.04.1980"
    },
    {
        "engDate": "1923-08-14",
        "nepDate": "30.04.1980"
    },
    {
        "engDate": "1923-08-15",
        "nepDate": "31.04.1980"
    },
    {
        "engDate": "1923-08-16",
        "nepDate": "32.04.1980"
    },
    {
        "engDate": "1923-08-17",
        "nepDate": "01.05.1980"
    },
    {
        "engDate": "1923-08-18",
        "nepDate": "02.05.1980"
    },
    {
        "engDate": "1923-08-19",
        "nepDate": "03.05.1980"
    },
    {
        "engDate": "1923-08-20",
        "nepDate": "04.05.1980"
    },
    {
        "engDate": "1923-08-21",
        "nepDate": "05.05.1980"
    },
    {
        "engDate": "1923-08-22",
        "nepDate": "06.05.1980"
    },
    {
        "engDate": "1923-08-23",
        "nepDate": "07.05.1980"
    },
    {
        "engDate": "1923-08-24",
        "nepDate": "08.05.1980"
    },
    {
        "engDate": "1923-08-25",
        "nepDate": "09.05.1980"
    },
    {
        "engDate": "1923-08-26",
        "nepDate": "10.05.1980"
    },
    {
        "engDate": "1923-08-27",
        "nepDate": "11.05.1980"
    },
    {
        "engDate": "1923-08-28",
        "nepDate": "12.05.1980"
    },
    {
        "engDate": "1923-08-29",
        "nepDate": "13.05.1980"
    },
    {
        "engDate": "1923-08-30",
        "nepDate": "14.05.1980"
    },
    {
        "engDate": "1923-08-31",
        "nepDate": "15.05.1980"
    },
    {
        "engDate": "1923-09-01",
        "nepDate": "16.05.1980"
    },
    {
        "engDate": "1923-09-02",
        "nepDate": "17.05.1980"
    },
    {
        "engDate": "1923-09-03",
        "nepDate": "18.05.1980"
    },
    {
        "engDate": "1923-09-04",
        "nepDate": "19.05.1980"
    },
    {
        "engDate": "1923-09-05",
        "nepDate": "20.05.1980"
    },
    {
        "engDate": "1923-09-06",
        "nepDate": "21.05.1980"
    },
    {
        "engDate": "1923-09-07",
        "nepDate": "22.05.1980"
    },
    {
        "engDate": "1923-09-08",
        "nepDate": "23.05.1980"
    },
    {
        "engDate": "1923-09-09",
        "nepDate": "24.05.1980"
    },
    {
        "engDate": "1923-09-10",
        "nepDate": "25.05.1980"
    },
    {
        "engDate": "1923-09-11",
        "nepDate": "26.05.1980"
    },
    {
        "engDate": "1923-09-12",
        "nepDate": "27.05.1980"
    },
    {
        "engDate": "1923-09-13",
        "nepDate": "28.05.1980"
    },
    {
        "engDate": "1923-09-14",
        "nepDate": "29.05.1980"
    },
    {
        "engDate": "1923-09-15",
        "nepDate": "30.05.1980"
    },
    {
        "engDate": "1923-09-16",
        "nepDate": "31.05.1980"
    },
    {
        "engDate": "1923-09-17",
        "nepDate": "01.06.1980"
    },
    {
        "engDate": "1923-09-18",
        "nepDate": "02.06.1980"
    },
    {
        "engDate": "1923-09-19",
        "nepDate": "03.06.1980"
    },
    {
        "engDate": "1923-09-20",
        "nepDate": "04.06.1980"
    },
    {
        "engDate": "1923-09-21",
        "nepDate": "05.06.1980"
    },
    {
        "engDate": "1923-09-22",
        "nepDate": "06.06.1980"
    },
    {
        "engDate": "1923-09-23",
        "nepDate": "07.06.1980"
    },
    {
        "engDate": "1923-09-24",
        "nepDate": "08.06.1980"
    },
    {
        "engDate": "1923-09-25",
        "nepDate": "09.06.1980"
    },
    {
        "engDate": "1923-09-26",
        "nepDate": "10.06.1980"
    },
    {
        "engDate": "1923-09-27",
        "nepDate": "11.06.1980"
    },
    {
        "engDate": "1923-09-28",
        "nepDate": "12.06.1980"
    },
    {
        "engDate": "1923-09-29",
        "nepDate": "13.06.1980"
    },
    {
        "engDate": "1923-09-30",
        "nepDate": "14.06.1980"
    },
    {
        "engDate": "1923-10-01",
        "nepDate": "15.06.1980"
    },
    {
        "engDate": "1923-10-02",
        "nepDate": "16.06.1980"
    },
    {
        "engDate": "1923-10-03",
        "nepDate": "17.06.1980"
    },
    {
        "engDate": "1923-10-04",
        "nepDate": "18.06.1980"
    },
    {
        "engDate": "1923-10-05",
        "nepDate": "19.06.1980"
    },
    {
        "engDate": "1923-10-06",
        "nepDate": "20.06.1980"
    },
    {
        "engDate": "1923-10-07",
        "nepDate": "21.06.1980"
    },
    {
        "engDate": "1923-10-08",
        "nepDate": "22.06.1980"
    },
    {
        "engDate": "1923-10-09",
        "nepDate": "23.06.1980"
    },
    {
        "engDate": "1923-10-10",
        "nepDate": "24.06.1980"
    },
    {
        "engDate": "1923-10-11",
        "nepDate": "25.06.1980"
    },
    {
        "engDate": "1923-10-12",
        "nepDate": "26.06.1980"
    },
    {
        "engDate": "1923-10-13",
        "nepDate": "27.06.1980"
    },
    {
        "engDate": "1923-10-14",
        "nepDate": "28.06.1980"
    },
    {
        "engDate": "1923-10-15",
        "nepDate": "29.06.1980"
    },
    {
        "engDate": "1923-10-16",
        "nepDate": "30.06.1980"
    },
    {
        "engDate": "1923-10-17",
        "nepDate": "01.07.1980"
    },
    {
        "engDate": "1923-10-18",
        "nepDate": "02.07.1980"
    },
    {
        "engDate": "1923-10-19",
        "nepDate": "03.07.1980"
    },
    {
        "engDate": "1923-10-20",
        "nepDate": "04.07.1980"
    },
    {
        "engDate": "1923-10-21",
        "nepDate": "05.07.1980"
    },
    {
        "engDate": "1923-10-22",
        "nepDate": "06.07.1980"
    },
    {
        "engDate": "1923-10-23",
        "nepDate": "07.07.1980"
    },
    {
        "engDate": "1923-10-24",
        "nepDate": "08.07.1980"
    },
    {
        "engDate": "1923-10-25",
        "nepDate": "09.07.1980"
    },
    {
        "engDate": "1923-10-26",
        "nepDate": "10.07.1980"
    },
    {
        "engDate": "1923-10-27",
        "nepDate": "11.07.1980"
    },
    {
        "engDate": "1923-10-28",
        "nepDate": "12.07.1980"
    },
    {
        "engDate": "1923-10-29",
        "nepDate": "13.07.1980"
    },
    {
        "engDate": "1923-10-30",
        "nepDate": "14.07.1980"
    },
    {
        "engDate": "1923-10-31",
        "nepDate": "15.07.1980"
    },
    {
        "engDate": "1923-11-01",
        "nepDate": "16.07.1980"
    },
    {
        "engDate": "1923-11-02",
        "nepDate": "17.07.1980"
    },
    {
        "engDate": "1923-11-03",
        "nepDate": "18.07.1980"
    },
    {
        "engDate": "1923-11-04",
        "nepDate": "19.07.1980"
    },
    {
        "engDate": "1923-11-05",
        "nepDate": "20.07.1980"
    },
    {
        "engDate": "1923-11-06",
        "nepDate": "21.07.1980"
    },
    {
        "engDate": "1923-11-07",
        "nepDate": "22.07.1980"
    },
    {
        "engDate": "1923-11-08",
        "nepDate": "23.07.1980"
    },
    {
        "engDate": "1923-11-09",
        "nepDate": "24.07.1980"
    },
    {
        "engDate": "1923-11-10",
        "nepDate": "25.07.1980"
    },
    {
        "engDate": "1923-11-11",
        "nepDate": "26.07.1980"
    },
    {
        "engDate": "1923-11-12",
        "nepDate": "27.07.1980"
    },
    {
        "engDate": "1923-11-13",
        "nepDate": "28.07.1980"
    },
    {
        "engDate": "1923-11-14",
        "nepDate": "29.07.1980"
    },
    {
        "engDate": "1923-11-15",
        "nepDate": "30.07.1980"
    },
    {
        "engDate": "1923-11-16",
        "nepDate": "01.08.1980"
    },
    {
        "engDate": "1923-11-17",
        "nepDate": "02.08.1980"
    },
    {
        "engDate": "1923-11-18",
        "nepDate": "03.08.1980"
    },
    {
        "engDate": "1923-11-19",
        "nepDate": "04.08.1980"
    },
    {
        "engDate": "1923-11-20",
        "nepDate": "05.08.1980"
    },
    {
        "engDate": "1923-11-21",
        "nepDate": "06.08.1980"
    },
    {
        "engDate": "1923-11-22",
        "nepDate": "07.08.1980"
    },
    {
        "engDate": "1923-11-23",
        "nepDate": "08.08.1980"
    },
    {
        "engDate": "1923-11-24",
        "nepDate": "09.08.1980"
    },
    {
        "engDate": "1923-11-25",
        "nepDate": "10.08.1980"
    },
    {
        "engDate": "1923-11-26",
        "nepDate": "11.08.1980"
    },
    {
        "engDate": "1923-11-27",
        "nepDate": "12.08.1980"
    },
    {
        "engDate": "1923-11-28",
        "nepDate": "13.08.1980"
    },
    {
        "engDate": "1923-11-29",
        "nepDate": "14.08.1980"
    },
    {
        "engDate": "1923-11-30",
        "nepDate": "15.08.1980"
    },
    {
        "engDate": "1923-12-01",
        "nepDate": "16.08.1980"
    },
    {
        "engDate": "1923-12-02",
        "nepDate": "17.08.1980"
    },
    {
        "engDate": "1923-12-03",
        "nepDate": "18.08.1980"
    },
    {
        "engDate": "1923-12-04",
        "nepDate": "19.08.1980"
    },
    {
        "engDate": "1923-12-05",
        "nepDate": "20.08.1980"
    },
    {
        "engDate": "1923-12-06",
        "nepDate": "21.08.1980"
    },
    {
        "engDate": "1923-12-07",
        "nepDate": "22.08.1980"
    },
    {
        "engDate": "1923-12-08",
        "nepDate": "23.08.1980"
    },
    {
        "engDate": "1923-12-09",
        "nepDate": "24.08.1980"
    },
    {
        "engDate": "1923-12-10",
        "nepDate": "25.08.1980"
    },
    {
        "engDate": "1923-12-11",
        "nepDate": "26.08.1980"
    },
    {
        "engDate": "1923-12-12",
        "nepDate": "27.08.1980"
    },
    {
        "engDate": "1923-12-13",
        "nepDate": "28.08.1980"
    },
    {
        "engDate": "1923-12-14",
        "nepDate": "29.08.1980"
    },
    {
        "engDate": "1923-12-15",
        "nepDate": "30.08.1980"
    },
    {
        "engDate": "1923-12-16",
        "nepDate": "01.09.1980"
    },
    {
        "engDate": "1923-12-17",
        "nepDate": "02.09.1980"
    },
    {
        "engDate": "1923-12-18",
        "nepDate": "03.09.1980"
    },
    {
        "engDate": "1923-12-19",
        "nepDate": "04.09.1980"
    },
    {
        "engDate": "1923-12-20",
        "nepDate": "05.09.1980"
    },
    {
        "engDate": "1923-12-21",
        "nepDate": "06.09.1980"
    },
    {
        "engDate": "1923-12-22",
        "nepDate": "07.09.1980"
    },
    {
        "engDate": "1923-12-23",
        "nepDate": "08.09.1980"
    },
    {
        "engDate": "1923-12-24",
        "nepDate": "09.09.1980"
    },
    {
        "engDate": "1923-12-25",
        "nepDate": "10.09.1980"
    },
    {
        "engDate": "1923-12-26",
        "nepDate": "11.09.1980"
    },
    {
        "engDate": "1923-12-27",
        "nepDate": "12.09.1980"
    },
    {
        "engDate": "1923-12-28",
        "nepDate": "13.09.1980"
    },
    {
        "engDate": "1923-12-29",
        "nepDate": "14.09.1980"
    },
    {
        "engDate": "1923-12-30",
        "nepDate": "15.09.1980"
    },
    {
        "engDate": "1923-12-31",
        "nepDate": "16.09.1980"
    },
    {
        "engDate": "1924-01-01",
        "nepDate": "17.09.1980"
    },
    {
        "engDate": "1924-01-02",
        "nepDate": "18.09.1980"
    },
    {
        "engDate": "1924-01-03",
        "nepDate": "19.09.1980"
    },
    {
        "engDate": "1924-01-04",
        "nepDate": "20.09.1980"
    },
    {
        "engDate": "1924-01-05",
        "nepDate": "21.09.1980"
    },
    {
        "engDate": "1924-01-06",
        "nepDate": "22.09.1980"
    },
    {
        "engDate": "1924-01-07",
        "nepDate": "23.09.1980"
    },
    {
        "engDate": "1924-01-08",
        "nepDate": "24.09.1980"
    },
    {
        "engDate": "1924-01-09",
        "nepDate": "25.09.1980"
    },
    {
        "engDate": "1924-01-10",
        "nepDate": "26.09.1980"
    },
    {
        "engDate": "1924-01-11",
        "nepDate": "27.09.1980"
    },
    {
        "engDate": "1924-01-12",
        "nepDate": "28.09.1980"
    },
    {
        "engDate": "1924-01-13",
        "nepDate": "29.09.1980"
    },
    {
        "engDate": "1924-01-14",
        "nepDate": "01.10.1980"
    },
    {
        "engDate": "1924-01-15",
        "nepDate": "02.10.1980"
    },
    {
        "engDate": "1924-01-16",
        "nepDate": "03.10.1980"
    },
    {
        "engDate": "1924-01-17",
        "nepDate": "04.10.1980"
    },
    {
        "engDate": "1924-01-18",
        "nepDate": "05.10.1980"
    },
    {
        "engDate": "1924-01-19",
        "nepDate": "06.10.1980"
    },
    {
        "engDate": "1924-01-20",
        "nepDate": "07.10.1980"
    },
    {
        "engDate": "1924-01-21",
        "nepDate": "08.10.1980"
    },
    {
        "engDate": "1924-01-22",
        "nepDate": "09.10.1980"
    },
    {
        "engDate": "1924-01-23",
        "nepDate": "10.10.1980"
    },
    {
        "engDate": "1924-01-24",
        "nepDate": "11.10.1980"
    },
    {
        "engDate": "1924-01-25",
        "nepDate": "12.10.1980"
    },
    {
        "engDate": "1924-01-26",
        "nepDate": "13.10.1980"
    },
    {
        "engDate": "1924-01-27",
        "nepDate": "14.10.1980"
    },
    {
        "engDate": "1924-01-28",
        "nepDate": "15.10.1980"
    },
    {
        "engDate": "1924-01-29",
        "nepDate": "16.10.1980"
    },
    {
        "engDate": "1924-01-30",
        "nepDate": "17.10.1980"
    },
    {
        "engDate": "1924-01-31",
        "nepDate": "18.10.1980"
    },
    {
        "engDate": "1924-02-01",
        "nepDate": "19.10.1980"
    },
    {
        "engDate": "1924-02-02",
        "nepDate": "20.10.1980"
    },
    {
        "engDate": "1924-02-03",
        "nepDate": "21.10.1980"
    },
    {
        "engDate": "1924-02-04",
        "nepDate": "22.10.1980"
    },
    {
        "engDate": "1924-02-05",
        "nepDate": "23.10.1980"
    },
    {
        "engDate": "1924-02-06",
        "nepDate": "24.10.1980"
    },
    {
        "engDate": "1924-02-07",
        "nepDate": "25.10.1980"
    },
    {
        "engDate": "1924-02-08",
        "nepDate": "26.10.1980"
    },
    {
        "engDate": "1924-02-09",
        "nepDate": "27.10.1980"
    },
    {
        "engDate": "1924-02-10",
        "nepDate": "28.10.1980"
    },
    {
        "engDate": "1924-02-11",
        "nepDate": "29.10.1980"
    },
    {
        "engDate": "1924-02-12",
        "nepDate": "01.11.1980"
    },
    {
        "engDate": "1924-02-13",
        "nepDate": "02.11.1980"
    },
    {
        "engDate": "1924-02-14",
        "nepDate": "03.11.1980"
    },
    {
        "engDate": "1924-02-15",
        "nepDate": "04.11.1980"
    },
    {
        "engDate": "1924-02-16",
        "nepDate": "05.11.1980"
    },
    {
        "engDate": "1924-02-17",
        "nepDate": "06.11.1980"
    },
    {
        "engDate": "1924-02-18",
        "nepDate": "07.11.1980"
    },
    {
        "engDate": "1924-02-19",
        "nepDate": "08.11.1980"
    },
    {
        "engDate": "1924-02-20",
        "nepDate": "09.11.1980"
    },
    {
        "engDate": "1924-02-21",
        "nepDate": "10.11.1980"
    },
    {
        "engDate": "1924-02-22",
        "nepDate": "11.11.1980"
    },
    {
        "engDate": "1924-02-23",
        "nepDate": "12.11.1980"
    },
    {
        "engDate": "1924-02-24",
        "nepDate": "13.11.1980"
    },
    {
        "engDate": "1924-02-25",
        "nepDate": "14.11.1980"
    },
    {
        "engDate": "1924-02-26",
        "nepDate": "15.11.1980"
    },
    {
        "engDate": "1924-02-27",
        "nepDate": "16.11.1980"
    },
    {
        "engDate": "1924-02-28",
        "nepDate": "17.11.1980"
    },
    {
        "engDate": "1924-02-29",
        "nepDate": "18.11.1980"
    },
    {
        "engDate": "1924-03-01",
        "nepDate": "19.11.1980"
    },
    {
        "engDate": "1924-03-02",
        "nepDate": "20.11.1980"
    },
    {
        "engDate": "1924-03-03",
        "nepDate": "21.11.1980"
    },
    {
        "engDate": "1924-03-04",
        "nepDate": "22.11.1980"
    },
    {
        "engDate": "1924-03-05",
        "nepDate": "23.11.1980"
    },
    {
        "engDate": "1924-03-06",
        "nepDate": "24.11.1980"
    },
    {
        "engDate": "1924-03-07",
        "nepDate": "25.11.1980"
    },
    {
        "engDate": "1924-03-08",
        "nepDate": "26.11.1980"
    },
    {
        "engDate": "1924-03-09",
        "nepDate": "27.11.1980"
    },
    {
        "engDate": "1924-03-10",
        "nepDate": "28.11.1980"
    },
    {
        "engDate": "1924-03-11",
        "nepDate": "29.11.1980"
    },
    {
        "engDate": "1924-03-12",
        "nepDate": "30.11.1980"
    },
    {
        "engDate": "1924-03-13",
        "nepDate": "01.12.1980"
    },
    {
        "engDate": "1924-03-14",
        "nepDate": "02.12.1980"
    },
    {
        "engDate": "1924-03-15",
        "nepDate": "03.12.1980"
    },
    {
        "engDate": "1924-03-16",
        "nepDate": "04.12.1980"
    },
    {
        "engDate": "1924-03-17",
        "nepDate": "05.12.1980"
    },
    {
        "engDate": "1924-03-18",
        "nepDate": "06.12.1980"
    },
    {
        "engDate": "1924-03-19",
        "nepDate": "07.12.1980"
    },
    {
        "engDate": "1924-03-20",
        "nepDate": "08.12.1980"
    },
    {
        "engDate": "1924-03-21",
        "nepDate": "09.12.1980"
    },
    {
        "engDate": "1924-03-22",
        "nepDate": "10.12.1980"
    },
    {
        "engDate": "1924-03-23",
        "nepDate": "11.12.1980"
    },
    {
        "engDate": "1924-03-24",
        "nepDate": "12.12.1980"
    },
    {
        "engDate": "1924-03-25",
        "nepDate": "13.12.1980"
    },
    {
        "engDate": "1924-03-26",
        "nepDate": "14.12.1980"
    },
    {
        "engDate": "1924-03-27",
        "nepDate": "15.12.1980"
    },
    {
        "engDate": "1924-03-28",
        "nepDate": "16.12.1980"
    },
    {
        "engDate": "1924-03-29",
        "nepDate": "17.12.1980"
    },
    {
        "engDate": "1924-03-30",
        "nepDate": "18.12.1980"
    },
    {
        "engDate": "1924-03-31",
        "nepDate": "19.12.1980"
    },
    {
        "engDate": "1924-04-01",
        "nepDate": "20.12.1980"
    },
    {
        "engDate": "1924-04-02",
        "nepDate": "21.12.1980"
    },
    {
        "engDate": "1924-04-03",
        "nepDate": "22.12.1980"
    },
    {
        "engDate": "1924-04-04",
        "nepDate": "23.12.1980"
    },
    {
        "engDate": "1924-04-05",
        "nepDate": "24.12.1980"
    },
    {
        "engDate": "1924-04-06",
        "nepDate": "25.12.1980"
    },
    {
        "engDate": "1924-04-07",
        "nepDate": "26.12.1980"
    },
    {
        "engDate": "1924-04-08",
        "nepDate": "27.12.1980"
    },
    {
        "engDate": "1924-04-09",
        "nepDate": "28.12.1980"
    },
    {
        "engDate": "1924-04-10",
        "nepDate": "29.12.1980"
    },
    {
        "engDate": "1924-04-11",
        "nepDate": "30.12.1980"
    },
    {
        "engDate": "1924-04-12",
        "nepDate": "31.12.1980"
    },
    {
        "engDate": "1924-04-13",
        "nepDate": "01.01.1981"
    },
    {
        "engDate": "1924-04-14",
        "nepDate": "02.01.1981"
    },
    {
        "engDate": "1924-04-15",
        "nepDate": "03.01.1981"
    },
    {
        "engDate": "1924-04-16",
        "nepDate": "04.01.1981"
    },
    {
        "engDate": "1924-04-17",
        "nepDate": "05.01.1981"
    },
    {
        "engDate": "1924-04-18",
        "nepDate": "06.01.1981"
    },
    {
        "engDate": "1924-04-19",
        "nepDate": "07.01.1981"
    },
    {
        "engDate": "1924-04-20",
        "nepDate": "08.01.1981"
    },
    {
        "engDate": "1924-04-21",
        "nepDate": "09.01.1981"
    },
    {
        "engDate": "1924-04-22",
        "nepDate": "10.01.1981"
    },
    {
        "engDate": "1924-04-23",
        "nepDate": "11.01.1981"
    },
    {
        "engDate": "1924-04-24",
        "nepDate": "12.01.1981"
    },
    {
        "engDate": "1924-04-25",
        "nepDate": "13.01.1981"
    },
    {
        "engDate": "1924-04-26",
        "nepDate": "14.01.1981"
    },
    {
        "engDate": "1924-04-27",
        "nepDate": "15.01.1981"
    },
    {
        "engDate": "1924-04-28",
        "nepDate": "16.01.1981"
    },
    {
        "engDate": "1924-04-29",
        "nepDate": "17.01.1981"
    },
    {
        "engDate": "1924-04-30",
        "nepDate": "18.01.1981"
    },
    {
        "engDate": "1924-05-01",
        "nepDate": "19.01.1981"
    },
    {
        "engDate": "1924-05-02",
        "nepDate": "20.01.1981"
    },
    {
        "engDate": "1924-05-03",
        "nepDate": "21.01.1981"
    },
    {
        "engDate": "1924-05-04",
        "nepDate": "22.01.1981"
    },
    {
        "engDate": "1924-05-05",
        "nepDate": "23.01.1981"
    },
    {
        "engDate": "1924-05-06",
        "nepDate": "24.01.1981"
    },
    {
        "engDate": "1924-05-07",
        "nepDate": "25.01.1981"
    },
    {
        "engDate": "1924-05-08",
        "nepDate": "26.01.1981"
    },
    {
        "engDate": "1924-05-09",
        "nepDate": "27.01.1981"
    },
    {
        "engDate": "1924-05-10",
        "nepDate": "28.01.1981"
    },
    {
        "engDate": "1924-05-11",
        "nepDate": "29.01.1981"
    },
    {
        "engDate": "1924-05-12",
        "nepDate": "30.01.1981"
    },
    {
        "engDate": "1924-05-13",
        "nepDate": "31.01.1981"
    },
    {
        "engDate": "1924-05-14",
        "nepDate": "01.02.1981"
    },
    {
        "engDate": "1924-05-15",
        "nepDate": "02.02.1981"
    },
    {
        "engDate": "1924-05-16",
        "nepDate": "03.02.1981"
    },
    {
        "engDate": "1924-05-17",
        "nepDate": "04.02.1981"
    },
    {
        "engDate": "1924-05-18",
        "nepDate": "05.02.1981"
    },
    {
        "engDate": "1924-05-19",
        "nepDate": "06.02.1981"
    },
    {
        "engDate": "1924-05-20",
        "nepDate": "07.02.1981"
    },
    {
        "engDate": "1924-05-21",
        "nepDate": "08.02.1981"
    },
    {
        "engDate": "1924-05-22",
        "nepDate": "09.02.1981"
    },
    {
        "engDate": "1924-05-23",
        "nepDate": "10.02.1981"
    },
    {
        "engDate": "1924-05-24",
        "nepDate": "11.02.1981"
    },
    {
        "engDate": "1924-05-25",
        "nepDate": "12.02.1981"
    },
    {
        "engDate": "1924-05-26",
        "nepDate": "13.02.1981"
    },
    {
        "engDate": "1924-05-27",
        "nepDate": "14.02.1981"
    },
    {
        "engDate": "1924-05-28",
        "nepDate": "15.02.1981"
    },
    {
        "engDate": "1924-05-29",
        "nepDate": "16.02.1981"
    },
    {
        "engDate": "1924-05-30",
        "nepDate": "17.02.1981"
    },
    {
        "engDate": "1924-05-31",
        "nepDate": "18.02.1981"
    },
    {
        "engDate": "1924-06-01",
        "nepDate": "19.02.1981"
    },
    {
        "engDate": "1924-06-02",
        "nepDate": "20.02.1981"
    },
    {
        "engDate": "1924-06-03",
        "nepDate": "21.02.1981"
    },
    {
        "engDate": "1924-06-04",
        "nepDate": "22.02.1981"
    },
    {
        "engDate": "1924-06-05",
        "nepDate": "23.02.1981"
    },
    {
        "engDate": "1924-06-06",
        "nepDate": "24.02.1981"
    },
    {
        "engDate": "1924-06-07",
        "nepDate": "25.02.1981"
    },
    {
        "engDate": "1924-06-08",
        "nepDate": "26.02.1981"
    },
    {
        "engDate": "1924-06-09",
        "nepDate": "27.02.1981"
    },
    {
        "engDate": "1924-06-10",
        "nepDate": "28.02.1981"
    },
    {
        "engDate": "1924-06-11",
        "nepDate": "29.02.1981"
    },
    {
        "engDate": "1924-06-12",
        "nepDate": "30.02.1981"
    },
    {
        "engDate": "1924-06-13",
        "nepDate": "31.02.1981"
    },
    {
        "engDate": "1924-06-14",
        "nepDate": "01.03.1981"
    },
    {
        "engDate": "1924-06-15",
        "nepDate": "02.03.1981"
    },
    {
        "engDate": "1924-06-16",
        "nepDate": "03.03.1981"
    },
    {
        "engDate": "1924-06-17",
        "nepDate": "04.03.1981"
    },
    {
        "engDate": "1924-06-18",
        "nepDate": "05.03.1981"
    },
    {
        "engDate": "1924-06-19",
        "nepDate": "06.03.1981"
    },
    {
        "engDate": "1924-06-20",
        "nepDate": "07.03.1981"
    },
    {
        "engDate": "1924-06-21",
        "nepDate": "08.03.1981"
    },
    {
        "engDate": "1924-06-22",
        "nepDate": "09.03.1981"
    },
    {
        "engDate": "1924-06-23",
        "nepDate": "10.03.1981"
    },
    {
        "engDate": "1924-06-24",
        "nepDate": "11.03.1981"
    },
    {
        "engDate": "1924-06-25",
        "nepDate": "12.03.1981"
    },
    {
        "engDate": "1924-06-26",
        "nepDate": "13.03.1981"
    },
    {
        "engDate": "1924-06-27",
        "nepDate": "14.03.1981"
    },
    {
        "engDate": "1924-06-28",
        "nepDate": "15.03.1981"
    },
    {
        "engDate": "1924-06-29",
        "nepDate": "16.03.1981"
    },
    {
        "engDate": "1924-06-30",
        "nepDate": "17.03.1981"
    },
    {
        "engDate": "1924-07-01",
        "nepDate": "18.03.1981"
    },
    {
        "engDate": "1924-07-02",
        "nepDate": "19.03.1981"
    },
    {
        "engDate": "1924-07-03",
        "nepDate": "20.03.1981"
    },
    {
        "engDate": "1924-07-04",
        "nepDate": "21.03.1981"
    },
    {
        "engDate": "1924-07-05",
        "nepDate": "22.03.1981"
    },
    {
        "engDate": "1924-07-06",
        "nepDate": "23.03.1981"
    },
    {
        "engDate": "1924-07-07",
        "nepDate": "24.03.1981"
    },
    {
        "engDate": "1924-07-08",
        "nepDate": "25.03.1981"
    },
    {
        "engDate": "1924-07-09",
        "nepDate": "26.03.1981"
    },
    {
        "engDate": "1924-07-10",
        "nepDate": "27.03.1981"
    },
    {
        "engDate": "1924-07-11",
        "nepDate": "28.03.1981"
    },
    {
        "engDate": "1924-07-12",
        "nepDate": "29.03.1981"
    },
    {
        "engDate": "1924-07-13",
        "nepDate": "30.03.1981"
    },
    {
        "engDate": "1924-07-14",
        "nepDate": "31.03.1981"
    },
    {
        "engDate": "1924-07-15",
        "nepDate": "01.04.1981"
    },
    {
        "engDate": "1924-07-16",
        "nepDate": "02.04.1981"
    },
    {
        "engDate": "1924-07-17",
        "nepDate": "03.04.1981"
    },
    {
        "engDate": "1924-07-18",
        "nepDate": "04.04.1981"
    },
    {
        "engDate": "1924-07-19",
        "nepDate": "05.04.1981"
    },
    {
        "engDate": "1924-07-20",
        "nepDate": "06.04.1981"
    },
    {
        "engDate": "1924-07-21",
        "nepDate": "07.04.1981"
    },
    {
        "engDate": "1924-07-22",
        "nepDate": "08.04.1981"
    },
    {
        "engDate": "1924-07-23",
        "nepDate": "09.04.1981"
    },
    {
        "engDate": "1924-07-24",
        "nepDate": "10.04.1981"
    },
    {
        "engDate": "1924-07-25",
        "nepDate": "11.04.1981"
    },
    {
        "engDate": "1924-07-26",
        "nepDate": "12.04.1981"
    },
    {
        "engDate": "1924-07-27",
        "nepDate": "13.04.1981"
    },
    {
        "engDate": "1924-07-28",
        "nepDate": "14.04.1981"
    },
    {
        "engDate": "1924-07-29",
        "nepDate": "15.04.1981"
    },
    {
        "engDate": "1924-07-30",
        "nepDate": "16.04.1981"
    },
    {
        "engDate": "1924-07-31",
        "nepDate": "17.04.1981"
    },
    {
        "engDate": "1924-08-01",
        "nepDate": "18.04.1981"
    },
    {
        "engDate": "1924-08-02",
        "nepDate": "19.04.1981"
    },
    {
        "engDate": "1924-08-03",
        "nepDate": "20.04.1981"
    },
    {
        "engDate": "1924-08-04",
        "nepDate": "21.04.1981"
    },
    {
        "engDate": "1924-08-05",
        "nepDate": "22.04.1981"
    },
    {
        "engDate": "1924-08-06",
        "nepDate": "23.04.1981"
    },
    {
        "engDate": "1924-08-07",
        "nepDate": "24.04.1981"
    },
    {
        "engDate": "1924-08-08",
        "nepDate": "25.04.1981"
    },
    {
        "engDate": "1924-08-09",
        "nepDate": "26.04.1981"
    },
    {
        "engDate": "1924-08-10",
        "nepDate": "27.04.1981"
    },
    {
        "engDate": "1924-08-11",
        "nepDate": "28.04.1981"
    },
    {
        "engDate": "1924-08-12",
        "nepDate": "29.04.1981"
    },
    {
        "engDate": "1924-08-13",
        "nepDate": "30.04.1981"
    },
    {
        "engDate": "1924-08-14",
        "nepDate": "31.04.1981"
    },
    {
        "engDate": "1924-08-15",
        "nepDate": "32.04.1981"
    },
    {
        "engDate": "1924-08-16",
        "nepDate": "01.05.1981"
    },
    {
        "engDate": "1924-08-17",
        "nepDate": "02.05.1981"
    },
    {
        "engDate": "1924-08-18",
        "nepDate": "03.05.1981"
    },
    {
        "engDate": "1924-08-19",
        "nepDate": "04.05.1981"
    },
    {
        "engDate": "1924-08-20",
        "nepDate": "05.05.1981"
    },
    {
        "engDate": "1924-08-21",
        "nepDate": "06.05.1981"
    },
    {
        "engDate": "1924-08-22",
        "nepDate": "07.05.1981"
    },
    {
        "engDate": "1924-08-23",
        "nepDate": "08.05.1981"
    },
    {
        "engDate": "1924-08-24",
        "nepDate": "09.05.1981"
    },
    {
        "engDate": "1924-08-25",
        "nepDate": "10.05.1981"
    },
    {
        "engDate": "1924-08-26",
        "nepDate": "11.05.1981"
    },
    {
        "engDate": "1924-08-27",
        "nepDate": "12.05.1981"
    },
    {
        "engDate": "1924-08-28",
        "nepDate": "13.05.1981"
    },
    {
        "engDate": "1924-08-29",
        "nepDate": "14.05.1981"
    },
    {
        "engDate": "1924-08-30",
        "nepDate": "15.05.1981"
    },
    {
        "engDate": "1924-08-31",
        "nepDate": "16.05.1981"
    },
    {
        "engDate": "1924-09-01",
        "nepDate": "17.05.1981"
    },
    {
        "engDate": "1924-09-02",
        "nepDate": "18.05.1981"
    },
    {
        "engDate": "1924-09-03",
        "nepDate": "19.05.1981"
    },
    {
        "engDate": "1924-09-04",
        "nepDate": "20.05.1981"
    },
    {
        "engDate": "1924-09-05",
        "nepDate": "21.05.1981"
    },
    {
        "engDate": "1924-09-06",
        "nepDate": "22.05.1981"
    },
    {
        "engDate": "1924-09-07",
        "nepDate": "23.05.1981"
    },
    {
        "engDate": "1924-09-08",
        "nepDate": "24.05.1981"
    },
    {
        "engDate": "1924-09-09",
        "nepDate": "25.05.1981"
    },
    {
        "engDate": "1924-09-10",
        "nepDate": "26.05.1981"
    },
    {
        "engDate": "1924-09-11",
        "nepDate": "27.05.1981"
    },
    {
        "engDate": "1924-09-12",
        "nepDate": "28.05.1981"
    },
    {
        "engDate": "1924-09-13",
        "nepDate": "29.05.1981"
    },
    {
        "engDate": "1924-09-14",
        "nepDate": "30.05.1981"
    },
    {
        "engDate": "1924-09-15",
        "nepDate": "31.05.1981"
    },
    {
        "engDate": "1924-09-16",
        "nepDate": "01.06.1981"
    },
    {
        "engDate": "1924-09-17",
        "nepDate": "02.06.1981"
    },
    {
        "engDate": "1924-09-18",
        "nepDate": "03.06.1981"
    },
    {
        "engDate": "1924-09-19",
        "nepDate": "04.06.1981"
    },
    {
        "engDate": "1924-09-20",
        "nepDate": "05.06.1981"
    },
    {
        "engDate": "1924-09-21",
        "nepDate": "06.06.1981"
    },
    {
        "engDate": "1924-09-22",
        "nepDate": "07.06.1981"
    },
    {
        "engDate": "1924-09-23",
        "nepDate": "08.06.1981"
    },
    {
        "engDate": "1924-09-24",
        "nepDate": "09.06.1981"
    },
    {
        "engDate": "1924-09-25",
        "nepDate": "10.06.1981"
    },
    {
        "engDate": "1924-09-26",
        "nepDate": "11.06.1981"
    },
    {
        "engDate": "1924-09-27",
        "nepDate": "12.06.1981"
    },
    {
        "engDate": "1924-09-28",
        "nepDate": "13.06.1981"
    },
    {
        "engDate": "1924-09-29",
        "nepDate": "14.06.1981"
    },
    {
        "engDate": "1924-09-30",
        "nepDate": "15.06.1981"
    },
    {
        "engDate": "1924-10-01",
        "nepDate": "16.06.1981"
    },
    {
        "engDate": "1924-10-02",
        "nepDate": "17.06.1981"
    },
    {
        "engDate": "1924-10-03",
        "nepDate": "18.06.1981"
    },
    {
        "engDate": "1924-10-04",
        "nepDate": "19.06.1981"
    },
    {
        "engDate": "1924-10-05",
        "nepDate": "20.06.1981"
    },
    {
        "engDate": "1924-10-06",
        "nepDate": "21.06.1981"
    },
    {
        "engDate": "1924-10-07",
        "nepDate": "22.06.1981"
    },
    {
        "engDate": "1924-10-08",
        "nepDate": "23.06.1981"
    },
    {
        "engDate": "1924-10-09",
        "nepDate": "24.06.1981"
    },
    {
        "engDate": "1924-10-10",
        "nepDate": "25.06.1981"
    },
    {
        "engDate": "1924-10-11",
        "nepDate": "26.06.1981"
    },
    {
        "engDate": "1924-10-12",
        "nepDate": "27.06.1981"
    },
    {
        "engDate": "1924-10-13",
        "nepDate": "28.06.1981"
    },
    {
        "engDate": "1924-10-14",
        "nepDate": "29.06.1981"
    },
    {
        "engDate": "1924-10-15",
        "nepDate": "30.06.1981"
    },
    {
        "engDate": "1924-10-16",
        "nepDate": "31.06.1981"
    },
    {
        "engDate": "1924-10-17",
        "nepDate": "01.07.1981"
    },
    {
        "engDate": "1924-10-18",
        "nepDate": "02.07.1981"
    },
    {
        "engDate": "1924-10-19",
        "nepDate": "03.07.1981"
    },
    {
        "engDate": "1924-10-20",
        "nepDate": "04.07.1981"
    },
    {
        "engDate": "1924-10-21",
        "nepDate": "05.07.1981"
    },
    {
        "engDate": "1924-10-22",
        "nepDate": "06.07.1981"
    },
    {
        "engDate": "1924-10-23",
        "nepDate": "07.07.1981"
    },
    {
        "engDate": "1924-10-24",
        "nepDate": "08.07.1981"
    },
    {
        "engDate": "1924-10-25",
        "nepDate": "09.07.1981"
    },
    {
        "engDate": "1924-10-26",
        "nepDate": "10.07.1981"
    },
    {
        "engDate": "1924-10-27",
        "nepDate": "11.07.1981"
    },
    {
        "engDate": "1924-10-28",
        "nepDate": "12.07.1981"
    },
    {
        "engDate": "1924-10-29",
        "nepDate": "13.07.1981"
    },
    {
        "engDate": "1924-10-30",
        "nepDate": "14.07.1981"
    },
    {
        "engDate": "1924-10-31",
        "nepDate": "15.07.1981"
    },
    {
        "engDate": "1924-11-01",
        "nepDate": "16.07.1981"
    },
    {
        "engDate": "1924-11-02",
        "nepDate": "17.07.1981"
    },
    {
        "engDate": "1924-11-03",
        "nepDate": "18.07.1981"
    },
    {
        "engDate": "1924-11-04",
        "nepDate": "19.07.1981"
    },
    {
        "engDate": "1924-11-05",
        "nepDate": "20.07.1981"
    },
    {
        "engDate": "1924-11-06",
        "nepDate": "21.07.1981"
    },
    {
        "engDate": "1924-11-07",
        "nepDate": "22.07.1981"
    },
    {
        "engDate": "1924-11-08",
        "nepDate": "23.07.1981"
    },
    {
        "engDate": "1924-11-09",
        "nepDate": "24.07.1981"
    },
    {
        "engDate": "1924-11-10",
        "nepDate": "25.07.1981"
    },
    {
        "engDate": "1924-11-11",
        "nepDate": "26.07.1981"
    },
    {
        "engDate": "1924-11-12",
        "nepDate": "27.07.1981"
    },
    {
        "engDate": "1924-11-13",
        "nepDate": "28.07.1981"
    },
    {
        "engDate": "1924-11-14",
        "nepDate": "29.07.1981"
    },
    {
        "engDate": "1924-11-15",
        "nepDate": "01.08.1981"
    },
    {
        "engDate": "1924-11-16",
        "nepDate": "02.08.1981"
    },
    {
        "engDate": "1924-11-17",
        "nepDate": "03.08.1981"
    },
    {
        "engDate": "1924-11-18",
        "nepDate": "04.08.1981"
    },
    {
        "engDate": "1924-11-19",
        "nepDate": "05.08.1981"
    },
    {
        "engDate": "1924-11-20",
        "nepDate": "06.08.1981"
    },
    {
        "engDate": "1924-11-21",
        "nepDate": "07.08.1981"
    },
    {
        "engDate": "1924-11-22",
        "nepDate": "08.08.1981"
    },
    {
        "engDate": "1924-11-23",
        "nepDate": "09.08.1981"
    },
    {
        "engDate": "1924-11-24",
        "nepDate": "10.08.1981"
    },
    {
        "engDate": "1924-11-25",
        "nepDate": "11.08.1981"
    },
    {
        "engDate": "1924-11-26",
        "nepDate": "12.08.1981"
    },
    {
        "engDate": "1924-11-27",
        "nepDate": "13.08.1981"
    },
    {
        "engDate": "1924-11-28",
        "nepDate": "14.08.1981"
    },
    {
        "engDate": "1924-11-29",
        "nepDate": "15.08.1981"
    },
    {
        "engDate": "1924-11-30",
        "nepDate": "16.08.1981"
    },
    {
        "engDate": "1924-12-01",
        "nepDate": "17.08.1981"
    },
    {
        "engDate": "1924-12-02",
        "nepDate": "18.08.1981"
    },
    {
        "engDate": "1924-12-03",
        "nepDate": "19.08.1981"
    },
    {
        "engDate": "1924-12-04",
        "nepDate": "20.08.1981"
    },
    {
        "engDate": "1924-12-05",
        "nepDate": "21.08.1981"
    },
    {
        "engDate": "1924-12-06",
        "nepDate": "22.08.1981"
    },
    {
        "engDate": "1924-12-07",
        "nepDate": "23.08.1981"
    },
    {
        "engDate": "1924-12-08",
        "nepDate": "24.08.1981"
    },
    {
        "engDate": "1924-12-09",
        "nepDate": "25.08.1981"
    },
    {
        "engDate": "1924-12-10",
        "nepDate": "26.08.1981"
    },
    {
        "engDate": "1924-12-11",
        "nepDate": "27.08.1981"
    },
    {
        "engDate": "1924-12-12",
        "nepDate": "28.08.1981"
    },
    {
        "engDate": "1924-12-13",
        "nepDate": "29.08.1981"
    },
    {
        "engDate": "1924-12-14",
        "nepDate": "30.08.1981"
    },
    {
        "engDate": "1924-12-15",
        "nepDate": "01.09.1981"
    },
    {
        "engDate": "1924-12-16",
        "nepDate": "02.09.1981"
    },
    {
        "engDate": "1924-12-17",
        "nepDate": "03.09.1981"
    },
    {
        "engDate": "1924-12-18",
        "nepDate": "04.09.1981"
    },
    {
        "engDate": "1924-12-19",
        "nepDate": "05.09.1981"
    },
    {
        "engDate": "1924-12-20",
        "nepDate": "06.09.1981"
    },
    {
        "engDate": "1924-12-21",
        "nepDate": "07.09.1981"
    },
    {
        "engDate": "1924-12-22",
        "nepDate": "08.09.1981"
    },
    {
        "engDate": "1924-12-23",
        "nepDate": "09.09.1981"
    },
    {
        "engDate": "1924-12-24",
        "nepDate": "10.09.1981"
    },
    {
        "engDate": "1924-12-25",
        "nepDate": "11.09.1981"
    },
    {
        "engDate": "1924-12-26",
        "nepDate": "12.09.1981"
    },
    {
        "engDate": "1924-12-27",
        "nepDate": "13.09.1981"
    },
    {
        "engDate": "1924-12-28",
        "nepDate": "14.09.1981"
    },
    {
        "engDate": "1924-12-29",
        "nepDate": "15.09.1981"
    },
    {
        "engDate": "1924-12-30",
        "nepDate": "16.09.1981"
    },
    {
        "engDate": "1924-12-31",
        "nepDate": "17.09.1981"
    },
    {
        "engDate": "1925-01-01",
        "nepDate": "18.09.1981"
    },
    {
        "engDate": "1925-01-02",
        "nepDate": "19.09.1981"
    },
    {
        "engDate": "1925-01-03",
        "nepDate": "20.09.1981"
    },
    {
        "engDate": "1925-01-04",
        "nepDate": "21.09.1981"
    },
    {
        "engDate": "1925-01-05",
        "nepDate": "22.09.1981"
    },
    {
        "engDate": "1925-01-06",
        "nepDate": "23.09.1981"
    },
    {
        "engDate": "1925-01-07",
        "nepDate": "24.09.1981"
    },
    {
        "engDate": "1925-01-08",
        "nepDate": "25.09.1981"
    },
    {
        "engDate": "1925-01-09",
        "nepDate": "26.09.1981"
    },
    {
        "engDate": "1925-01-10",
        "nepDate": "27.09.1981"
    },
    {
        "engDate": "1925-01-11",
        "nepDate": "28.09.1981"
    },
    {
        "engDate": "1925-01-12",
        "nepDate": "29.09.1981"
    },
    {
        "engDate": "1925-01-13",
        "nepDate": "30.09.1981"
    },
    {
        "engDate": "1925-01-14",
        "nepDate": "01.10.1981"
    },
    {
        "engDate": "1925-01-15",
        "nepDate": "02.10.1981"
    },
    {
        "engDate": "1925-01-16",
        "nepDate": "03.10.1981"
    },
    {
        "engDate": "1925-01-17",
        "nepDate": "04.10.1981"
    },
    {
        "engDate": "1925-01-18",
        "nepDate": "05.10.1981"
    },
    {
        "engDate": "1925-01-19",
        "nepDate": "06.10.1981"
    },
    {
        "engDate": "1925-01-20",
        "nepDate": "07.10.1981"
    },
    {
        "engDate": "1925-01-21",
        "nepDate": "08.10.1981"
    },
    {
        "engDate": "1925-01-22",
        "nepDate": "09.10.1981"
    },
    {
        "engDate": "1925-01-23",
        "nepDate": "10.10.1981"
    },
    {
        "engDate": "1925-01-24",
        "nepDate": "11.10.1981"
    },
    {
        "engDate": "1925-01-25",
        "nepDate": "12.10.1981"
    },
    {
        "engDate": "1925-01-26",
        "nepDate": "13.10.1981"
    },
    {
        "engDate": "1925-01-27",
        "nepDate": "14.10.1981"
    },
    {
        "engDate": "1925-01-28",
        "nepDate": "15.10.1981"
    },
    {
        "engDate": "1925-01-29",
        "nepDate": "16.10.1981"
    },
    {
        "engDate": "1925-01-30",
        "nepDate": "17.10.1981"
    },
    {
        "engDate": "1925-01-31",
        "nepDate": "18.10.1981"
    },
    {
        "engDate": "1925-02-01",
        "nepDate": "19.10.1981"
    },
    {
        "engDate": "1925-02-02",
        "nepDate": "20.10.1981"
    },
    {
        "engDate": "1925-02-03",
        "nepDate": "21.10.1981"
    },
    {
        "engDate": "1925-02-04",
        "nepDate": "22.10.1981"
    },
    {
        "engDate": "1925-02-05",
        "nepDate": "23.10.1981"
    },
    {
        "engDate": "1925-02-06",
        "nepDate": "24.10.1981"
    },
    {
        "engDate": "1925-02-07",
        "nepDate": "25.10.1981"
    },
    {
        "engDate": "1925-02-08",
        "nepDate": "26.10.1981"
    },
    {
        "engDate": "1925-02-09",
        "nepDate": "27.10.1981"
    },
    {
        "engDate": "1925-02-10",
        "nepDate": "28.10.1981"
    },
    {
        "engDate": "1925-02-11",
        "nepDate": "29.10.1981"
    },
    {
        "engDate": "1925-02-12",
        "nepDate": "01.11.1981"
    },
    {
        "engDate": "1925-02-13",
        "nepDate": "02.11.1981"
    },
    {
        "engDate": "1925-02-14",
        "nepDate": "03.11.1981"
    },
    {
        "engDate": "1925-02-15",
        "nepDate": "04.11.1981"
    },
    {
        "engDate": "1925-02-16",
        "nepDate": "05.11.1981"
    },
    {
        "engDate": "1925-02-17",
        "nepDate": "06.11.1981"
    },
    {
        "engDate": "1925-02-18",
        "nepDate": "07.11.1981"
    },
    {
        "engDate": "1925-02-19",
        "nepDate": "08.11.1981"
    },
    {
        "engDate": "1925-02-20",
        "nepDate": "09.11.1981"
    },
    {
        "engDate": "1925-02-21",
        "nepDate": "10.11.1981"
    },
    {
        "engDate": "1925-02-22",
        "nepDate": "11.11.1981"
    },
    {
        "engDate": "1925-02-23",
        "nepDate": "12.11.1981"
    },
    {
        "engDate": "1925-02-24",
        "nepDate": "13.11.1981"
    },
    {
        "engDate": "1925-02-25",
        "nepDate": "14.11.1981"
    },
    {
        "engDate": "1925-02-26",
        "nepDate": "15.11.1981"
    },
    {
        "engDate": "1925-02-27",
        "nepDate": "16.11.1981"
    },
    {
        "engDate": "1925-02-28",
        "nepDate": "17.11.1981"
    },
    {
        "engDate": "1925-03-01",
        "nepDate": "18.11.1981"
    },
    {
        "engDate": "1925-03-02",
        "nepDate": "19.11.1981"
    },
    {
        "engDate": "1925-03-03",
        "nepDate": "20.11.1981"
    },
    {
        "engDate": "1925-03-04",
        "nepDate": "21.11.1981"
    },
    {
        "engDate": "1925-03-05",
        "nepDate": "22.11.1981"
    },
    {
        "engDate": "1925-03-06",
        "nepDate": "23.11.1981"
    },
    {
        "engDate": "1925-03-07",
        "nepDate": "24.11.1981"
    },
    {
        "engDate": "1925-03-08",
        "nepDate": "25.11.1981"
    },
    {
        "engDate": "1925-03-09",
        "nepDate": "26.11.1981"
    },
    {
        "engDate": "1925-03-10",
        "nepDate": "27.11.1981"
    },
    {
        "engDate": "1925-03-11",
        "nepDate": "28.11.1981"
    },
    {
        "engDate": "1925-03-12",
        "nepDate": "29.11.1981"
    },
    {
        "engDate": "1925-03-13",
        "nepDate": "30.11.1981"
    },
    {
        "engDate": "1925-03-14",
        "nepDate": "01.12.1981"
    },
    {
        "engDate": "1925-03-15",
        "nepDate": "02.12.1981"
    },
    {
        "engDate": "1925-03-16",
        "nepDate": "03.12.1981"
    },
    {
        "engDate": "1925-03-17",
        "nepDate": "04.12.1981"
    },
    {
        "engDate": "1925-03-18",
        "nepDate": "05.12.1981"
    },
    {
        "engDate": "1925-03-19",
        "nepDate": "06.12.1981"
    },
    {
        "engDate": "1925-03-20",
        "nepDate": "07.12.1981"
    },
    {
        "engDate": "1925-03-21",
        "nepDate": "08.12.1981"
    },
    {
        "engDate": "1925-03-22",
        "nepDate": "09.12.1981"
    },
    {
        "engDate": "1925-03-23",
        "nepDate": "10.12.1981"
    },
    {
        "engDate": "1925-03-24",
        "nepDate": "11.12.1981"
    },
    {
        "engDate": "1925-03-25",
        "nepDate": "12.12.1981"
    },
    {
        "engDate": "1925-03-26",
        "nepDate": "13.12.1981"
    },
    {
        "engDate": "1925-03-27",
        "nepDate": "14.12.1981"
    },
    {
        "engDate": "1925-03-28",
        "nepDate": "15.12.1981"
    },
    {
        "engDate": "1925-03-29",
        "nepDate": "16.12.1981"
    },
    {
        "engDate": "1925-03-30",
        "nepDate": "17.12.1981"
    },
    {
        "engDate": "1925-03-31",
        "nepDate": "18.12.1981"
    },
    {
        "engDate": "1925-04-01",
        "nepDate": "19.12.1981"
    },
    {
        "engDate": "1925-04-02",
        "nepDate": "20.12.1981"
    },
    {
        "engDate": "1925-04-03",
        "nepDate": "21.12.1981"
    },
    {
        "engDate": "1925-04-04",
        "nepDate": "22.12.1981"
    },
    {
        "engDate": "1925-04-05",
        "nepDate": "23.12.1981"
    },
    {
        "engDate": "1925-04-06",
        "nepDate": "24.12.1981"
    },
    {
        "engDate": "1925-04-07",
        "nepDate": "25.12.1981"
    },
    {
        "engDate": "1925-04-08",
        "nepDate": "26.12.1981"
    },
    {
        "engDate": "1925-04-09",
        "nepDate": "27.12.1981"
    },
    {
        "engDate": "1925-04-10",
        "nepDate": "28.12.1981"
    },
    {
        "engDate": "1925-04-11",
        "nepDate": "29.12.1981"
    },
    {
        "engDate": "1925-04-12",
        "nepDate": "30.12.1981"
    },
    {
        "engDate": "1925-04-13",
        "nepDate": "01.01.1982"
    },
    {
        "engDate": "1925-04-14",
        "nepDate": "02.01.1982"
    },
    {
        "engDate": "1925-04-15",
        "nepDate": "03.01.1982"
    },
    {
        "engDate": "1925-04-16",
        "nepDate": "04.01.1982"
    },
    {
        "engDate": "1925-04-17",
        "nepDate": "05.01.1982"
    },
    {
        "engDate": "1925-04-18",
        "nepDate": "06.01.1982"
    },
    {
        "engDate": "1925-04-19",
        "nepDate": "07.01.1982"
    },
    {
        "engDate": "1925-04-20",
        "nepDate": "08.01.1982"
    },
    {
        "engDate": "1925-04-21",
        "nepDate": "09.01.1982"
    },
    {
        "engDate": "1925-04-22",
        "nepDate": "10.01.1982"
    },
    {
        "engDate": "1925-04-23",
        "nepDate": "11.01.1982"
    },
    {
        "engDate": "1925-04-24",
        "nepDate": "12.01.1982"
    },
    {
        "engDate": "1925-04-25",
        "nepDate": "13.01.1982"
    },
    {
        "engDate": "1925-04-26",
        "nepDate": "14.01.1982"
    },
    {
        "engDate": "1925-04-27",
        "nepDate": "15.01.1982"
    },
    {
        "engDate": "1925-04-28",
        "nepDate": "16.01.1982"
    },
    {
        "engDate": "1925-04-29",
        "nepDate": "17.01.1982"
    },
    {
        "engDate": "1925-04-30",
        "nepDate": "18.01.1982"
    },
    {
        "engDate": "1925-05-01",
        "nepDate": "19.01.1982"
    },
    {
        "engDate": "1925-05-02",
        "nepDate": "20.01.1982"
    },
    {
        "engDate": "1925-05-03",
        "nepDate": "21.01.1982"
    },
    {
        "engDate": "1925-05-04",
        "nepDate": "22.01.1982"
    },
    {
        "engDate": "1925-05-05",
        "nepDate": "23.01.1982"
    },
    {
        "engDate": "1925-05-06",
        "nepDate": "24.01.1982"
    },
    {
        "engDate": "1925-05-07",
        "nepDate": "25.01.1982"
    },
    {
        "engDate": "1925-05-08",
        "nepDate": "26.01.1982"
    },
    {
        "engDate": "1925-05-09",
        "nepDate": "27.01.1982"
    },
    {
        "engDate": "1925-05-10",
        "nepDate": "28.01.1982"
    },
    {
        "engDate": "1925-05-11",
        "nepDate": "29.01.1982"
    },
    {
        "engDate": "1925-05-12",
        "nepDate": "30.01.1982"
    },
    {
        "engDate": "1925-05-13",
        "nepDate": "31.01.1982"
    },
    {
        "engDate": "1925-05-14",
        "nepDate": "01.02.1982"
    },
    {
        "engDate": "1925-05-15",
        "nepDate": "02.02.1982"
    },
    {
        "engDate": "1925-05-16",
        "nepDate": "03.02.1982"
    },
    {
        "engDate": "1925-05-17",
        "nepDate": "04.02.1982"
    },
    {
        "engDate": "1925-05-18",
        "nepDate": "05.02.1982"
    },
    {
        "engDate": "1925-05-19",
        "nepDate": "06.02.1982"
    },
    {
        "engDate": "1925-05-20",
        "nepDate": "07.02.1982"
    },
    {
        "engDate": "1925-05-21",
        "nepDate": "08.02.1982"
    },
    {
        "engDate": "1925-05-22",
        "nepDate": "09.02.1982"
    },
    {
        "engDate": "1925-05-23",
        "nepDate": "10.02.1982"
    },
    {
        "engDate": "1925-05-24",
        "nepDate": "11.02.1982"
    },
    {
        "engDate": "1925-05-25",
        "nepDate": "12.02.1982"
    },
    {
        "engDate": "1925-05-26",
        "nepDate": "13.02.1982"
    },
    {
        "engDate": "1925-05-27",
        "nepDate": "14.02.1982"
    },
    {
        "engDate": "1925-05-28",
        "nepDate": "15.02.1982"
    },
    {
        "engDate": "1925-05-29",
        "nepDate": "16.02.1982"
    },
    {
        "engDate": "1925-05-30",
        "nepDate": "17.02.1982"
    },
    {
        "engDate": "1925-05-31",
        "nepDate": "18.02.1982"
    },
    {
        "engDate": "1925-06-01",
        "nepDate": "19.02.1982"
    },
    {
        "engDate": "1925-06-02",
        "nepDate": "20.02.1982"
    },
    {
        "engDate": "1925-06-03",
        "nepDate": "21.02.1982"
    },
    {
        "engDate": "1925-06-04",
        "nepDate": "22.02.1982"
    },
    {
        "engDate": "1925-06-05",
        "nepDate": "23.02.1982"
    },
    {
        "engDate": "1925-06-06",
        "nepDate": "24.02.1982"
    },
    {
        "engDate": "1925-06-07",
        "nepDate": "25.02.1982"
    },
    {
        "engDate": "1925-06-08",
        "nepDate": "26.02.1982"
    },
    {
        "engDate": "1925-06-09",
        "nepDate": "27.02.1982"
    },
    {
        "engDate": "1925-06-10",
        "nepDate": "28.02.1982"
    },
    {
        "engDate": "1925-06-11",
        "nepDate": "29.02.1982"
    },
    {
        "engDate": "1925-06-12",
        "nepDate": "30.02.1982"
    },
    {
        "engDate": "1925-06-13",
        "nepDate": "31.02.1982"
    },
    {
        "engDate": "1925-06-14",
        "nepDate": "01.03.1982"
    },
    {
        "engDate": "1925-06-15",
        "nepDate": "02.03.1982"
    },
    {
        "engDate": "1925-06-16",
        "nepDate": "03.03.1982"
    },
    {
        "engDate": "1925-06-17",
        "nepDate": "04.03.1982"
    },
    {
        "engDate": "1925-06-18",
        "nepDate": "05.03.1982"
    },
    {
        "engDate": "1925-06-19",
        "nepDate": "06.03.1982"
    },
    {
        "engDate": "1925-06-20",
        "nepDate": "07.03.1982"
    },
    {
        "engDate": "1925-06-21",
        "nepDate": "08.03.1982"
    },
    {
        "engDate": "1925-06-22",
        "nepDate": "09.03.1982"
    },
    {
        "engDate": "1925-06-23",
        "nepDate": "10.03.1982"
    },
    {
        "engDate": "1925-06-24",
        "nepDate": "11.03.1982"
    },
    {
        "engDate": "1925-06-25",
        "nepDate": "12.03.1982"
    },
    {
        "engDate": "1925-06-26",
        "nepDate": "13.03.1982"
    },
    {
        "engDate": "1925-06-27",
        "nepDate": "14.03.1982"
    },
    {
        "engDate": "1925-06-28",
        "nepDate": "15.03.1982"
    },
    {
        "engDate": "1925-06-29",
        "nepDate": "16.03.1982"
    },
    {
        "engDate": "1925-06-30",
        "nepDate": "17.03.1982"
    },
    {
        "engDate": "1925-07-01",
        "nepDate": "18.03.1982"
    },
    {
        "engDate": "1925-07-02",
        "nepDate": "19.03.1982"
    },
    {
        "engDate": "1925-07-03",
        "nepDate": "20.03.1982"
    },
    {
        "engDate": "1925-07-04",
        "nepDate": "21.03.1982"
    },
    {
        "engDate": "1925-07-05",
        "nepDate": "22.03.1982"
    },
    {
        "engDate": "1925-07-06",
        "nepDate": "23.03.1982"
    },
    {
        "engDate": "1925-07-07",
        "nepDate": "24.03.1982"
    },
    {
        "engDate": "1925-07-08",
        "nepDate": "25.03.1982"
    },
    {
        "engDate": "1925-07-09",
        "nepDate": "26.03.1982"
    },
    {
        "engDate": "1925-07-10",
        "nepDate": "27.03.1982"
    },
    {
        "engDate": "1925-07-11",
        "nepDate": "28.03.1982"
    },
    {
        "engDate": "1925-07-12",
        "nepDate": "29.03.1982"
    },
    {
        "engDate": "1925-07-13",
        "nepDate": "30.03.1982"
    },
    {
        "engDate": "1925-07-14",
        "nepDate": "31.03.1982"
    },
    {
        "engDate": "1925-07-15",
        "nepDate": "32.03.1982"
    },
    {
        "engDate": "1925-07-16",
        "nepDate": "01.04.1982"
    },
    {
        "engDate": "1925-07-17",
        "nepDate": "02.04.1982"
    },
    {
        "engDate": "1925-07-18",
        "nepDate": "03.04.1982"
    },
    {
        "engDate": "1925-07-19",
        "nepDate": "04.04.1982"
    },
    {
        "engDate": "1925-07-20",
        "nepDate": "05.04.1982"
    },
    {
        "engDate": "1925-07-21",
        "nepDate": "06.04.1982"
    },
    {
        "engDate": "1925-07-22",
        "nepDate": "07.04.1982"
    },
    {
        "engDate": "1925-07-23",
        "nepDate": "08.04.1982"
    },
    {
        "engDate": "1925-07-24",
        "nepDate": "09.04.1982"
    },
    {
        "engDate": "1925-07-25",
        "nepDate": "10.04.1982"
    },
    {
        "engDate": "1925-07-26",
        "nepDate": "11.04.1982"
    },
    {
        "engDate": "1925-07-27",
        "nepDate": "12.04.1982"
    },
    {
        "engDate": "1925-07-28",
        "nepDate": "13.04.1982"
    },
    {
        "engDate": "1925-07-29",
        "nepDate": "14.04.1982"
    },
    {
        "engDate": "1925-07-30",
        "nepDate": "15.04.1982"
    },
    {
        "engDate": "1925-07-31",
        "nepDate": "16.04.1982"
    },
    {
        "engDate": "1925-08-01",
        "nepDate": "17.04.1982"
    },
    {
        "engDate": "1925-08-02",
        "nepDate": "18.04.1982"
    },
    {
        "engDate": "1925-08-03",
        "nepDate": "19.04.1982"
    },
    {
        "engDate": "1925-08-04",
        "nepDate": "20.04.1982"
    },
    {
        "engDate": "1925-08-05",
        "nepDate": "21.04.1982"
    },
    {
        "engDate": "1925-08-06",
        "nepDate": "22.04.1982"
    },
    {
        "engDate": "1925-08-07",
        "nepDate": "23.04.1982"
    },
    {
        "engDate": "1925-08-08",
        "nepDate": "24.04.1982"
    },
    {
        "engDate": "1925-08-09",
        "nepDate": "25.04.1982"
    },
    {
        "engDate": "1925-08-10",
        "nepDate": "26.04.1982"
    },
    {
        "engDate": "1925-08-11",
        "nepDate": "27.04.1982"
    },
    {
        "engDate": "1925-08-12",
        "nepDate": "28.04.1982"
    },
    {
        "engDate": "1925-08-13",
        "nepDate": "29.04.1982"
    },
    {
        "engDate": "1925-08-14",
        "nepDate": "30.04.1982"
    },
    {
        "engDate": "1925-08-15",
        "nepDate": "31.04.1982"
    },
    {
        "engDate": "1925-08-16",
        "nepDate": "01.05.1982"
    },
    {
        "engDate": "1925-08-17",
        "nepDate": "02.05.1982"
    },
    {
        "engDate": "1925-08-18",
        "nepDate": "03.05.1982"
    },
    {
        "engDate": "1925-08-19",
        "nepDate": "04.05.1982"
    },
    {
        "engDate": "1925-08-20",
        "nepDate": "05.05.1982"
    },
    {
        "engDate": "1925-08-21",
        "nepDate": "06.05.1982"
    },
    {
        "engDate": "1925-08-22",
        "nepDate": "07.05.1982"
    },
    {
        "engDate": "1925-08-23",
        "nepDate": "08.05.1982"
    },
    {
        "engDate": "1925-08-24",
        "nepDate": "09.05.1982"
    },
    {
        "engDate": "1925-08-25",
        "nepDate": "10.05.1982"
    },
    {
        "engDate": "1925-08-26",
        "nepDate": "11.05.1982"
    },
    {
        "engDate": "1925-08-27",
        "nepDate": "12.05.1982"
    },
    {
        "engDate": "1925-08-28",
        "nepDate": "13.05.1982"
    },
    {
        "engDate": "1925-08-29",
        "nepDate": "14.05.1982"
    },
    {
        "engDate": "1925-08-30",
        "nepDate": "15.05.1982"
    },
    {
        "engDate": "1925-08-31",
        "nepDate": "16.05.1982"
    },
    {
        "engDate": "1925-09-01",
        "nepDate": "17.05.1982"
    },
    {
        "engDate": "1925-09-02",
        "nepDate": "18.05.1982"
    },
    {
        "engDate": "1925-09-03",
        "nepDate": "19.05.1982"
    },
    {
        "engDate": "1925-09-04",
        "nepDate": "20.05.1982"
    },
    {
        "engDate": "1925-09-05",
        "nepDate": "21.05.1982"
    },
    {
        "engDate": "1925-09-06",
        "nepDate": "22.05.1982"
    },
    {
        "engDate": "1925-09-07",
        "nepDate": "23.05.1982"
    },
    {
        "engDate": "1925-09-08",
        "nepDate": "24.05.1982"
    },
    {
        "engDate": "1925-09-09",
        "nepDate": "25.05.1982"
    },
    {
        "engDate": "1925-09-10",
        "nepDate": "26.05.1982"
    },
    {
        "engDate": "1925-09-11",
        "nepDate": "27.05.1982"
    },
    {
        "engDate": "1925-09-12",
        "nepDate": "28.05.1982"
    },
    {
        "engDate": "1925-09-13",
        "nepDate": "29.05.1982"
    },
    {
        "engDate": "1925-09-14",
        "nepDate": "30.05.1982"
    },
    {
        "engDate": "1925-09-15",
        "nepDate": "31.05.1982"
    },
    {
        "engDate": "1925-09-16",
        "nepDate": "01.06.1982"
    },
    {
        "engDate": "1925-09-17",
        "nepDate": "02.06.1982"
    },
    {
        "engDate": "1925-09-18",
        "nepDate": "03.06.1982"
    },
    {
        "engDate": "1925-09-19",
        "nepDate": "04.06.1982"
    },
    {
        "engDate": "1925-09-20",
        "nepDate": "05.06.1982"
    },
    {
        "engDate": "1925-09-21",
        "nepDate": "06.06.1982"
    },
    {
        "engDate": "1925-09-22",
        "nepDate": "07.06.1982"
    },
    {
        "engDate": "1925-09-23",
        "nepDate": "08.06.1982"
    },
    {
        "engDate": "1925-09-24",
        "nepDate": "09.06.1982"
    },
    {
        "engDate": "1925-09-25",
        "nepDate": "10.06.1982"
    },
    {
        "engDate": "1925-09-26",
        "nepDate": "11.06.1982"
    },
    {
        "engDate": "1925-09-27",
        "nepDate": "12.06.1982"
    },
    {
        "engDate": "1925-09-28",
        "nepDate": "13.06.1982"
    },
    {
        "engDate": "1925-09-29",
        "nepDate": "14.06.1982"
    },
    {
        "engDate": "1925-09-30",
        "nepDate": "15.06.1982"
    },
    {
        "engDate": "1925-10-01",
        "nepDate": "16.06.1982"
    },
    {
        "engDate": "1925-10-02",
        "nepDate": "17.06.1982"
    },
    {
        "engDate": "1925-10-03",
        "nepDate": "18.06.1982"
    },
    {
        "engDate": "1925-10-04",
        "nepDate": "19.06.1982"
    },
    {
        "engDate": "1925-10-05",
        "nepDate": "20.06.1982"
    },
    {
        "engDate": "1925-10-06",
        "nepDate": "21.06.1982"
    },
    {
        "engDate": "1925-10-07",
        "nepDate": "22.06.1982"
    },
    {
        "engDate": "1925-10-08",
        "nepDate": "23.06.1982"
    },
    {
        "engDate": "1925-10-09",
        "nepDate": "24.06.1982"
    },
    {
        "engDate": "1925-10-10",
        "nepDate": "25.06.1982"
    },
    {
        "engDate": "1925-10-11",
        "nepDate": "26.06.1982"
    },
    {
        "engDate": "1925-10-12",
        "nepDate": "27.06.1982"
    },
    {
        "engDate": "1925-10-13",
        "nepDate": "28.06.1982"
    },
    {
        "engDate": "1925-10-14",
        "nepDate": "29.06.1982"
    },
    {
        "engDate": "1925-10-15",
        "nepDate": "30.06.1982"
    },
    {
        "engDate": "1925-10-16",
        "nepDate": "31.06.1982"
    },
    {
        "engDate": "1925-10-17",
        "nepDate": "01.07.1982"
    },
    {
        "engDate": "1925-10-18",
        "nepDate": "02.07.1982"
    },
    {
        "engDate": "1925-10-19",
        "nepDate": "03.07.1982"
    },
    {
        "engDate": "1925-10-20",
        "nepDate": "04.07.1982"
    },
    {
        "engDate": "1925-10-21",
        "nepDate": "05.07.1982"
    },
    {
        "engDate": "1925-10-22",
        "nepDate": "06.07.1982"
    },
    {
        "engDate": "1925-10-23",
        "nepDate": "07.07.1982"
    },
    {
        "engDate": "1925-10-24",
        "nepDate": "08.07.1982"
    },
    {
        "engDate": "1925-10-25",
        "nepDate": "09.07.1982"
    },
    {
        "engDate": "1925-10-26",
        "nepDate": "10.07.1982"
    },
    {
        "engDate": "1925-10-27",
        "nepDate": "11.07.1982"
    },
    {
        "engDate": "1925-10-28",
        "nepDate": "12.07.1982"
    },
    {
        "engDate": "1925-10-29",
        "nepDate": "13.07.1982"
    },
    {
        "engDate": "1925-10-30",
        "nepDate": "14.07.1982"
    },
    {
        "engDate": "1925-10-31",
        "nepDate": "15.07.1982"
    },
    {
        "engDate": "1925-11-01",
        "nepDate": "16.07.1982"
    },
    {
        "engDate": "1925-11-02",
        "nepDate": "17.07.1982"
    },
    {
        "engDate": "1925-11-03",
        "nepDate": "18.07.1982"
    },
    {
        "engDate": "1925-11-04",
        "nepDate": "19.07.1982"
    },
    {
        "engDate": "1925-11-05",
        "nepDate": "20.07.1982"
    },
    {
        "engDate": "1925-11-06",
        "nepDate": "21.07.1982"
    },
    {
        "engDate": "1925-11-07",
        "nepDate": "22.07.1982"
    },
    {
        "engDate": "1925-11-08",
        "nepDate": "23.07.1982"
    },
    {
        "engDate": "1925-11-09",
        "nepDate": "24.07.1982"
    },
    {
        "engDate": "1925-11-10",
        "nepDate": "25.07.1982"
    },
    {
        "engDate": "1925-11-11",
        "nepDate": "26.07.1982"
    },
    {
        "engDate": "1925-11-12",
        "nepDate": "27.07.1982"
    },
    {
        "engDate": "1925-11-13",
        "nepDate": "28.07.1982"
    },
    {
        "engDate": "1925-11-14",
        "nepDate": "29.07.1982"
    },
    {
        "engDate": "1925-11-15",
        "nepDate": "30.07.1982"
    },
    {
        "engDate": "1925-11-16",
        "nepDate": "01.08.1982"
    },
    {
        "engDate": "1925-11-17",
        "nepDate": "02.08.1982"
    },
    {
        "engDate": "1925-11-18",
        "nepDate": "03.08.1982"
    },
    {
        "engDate": "1925-11-19",
        "nepDate": "04.08.1982"
    },
    {
        "engDate": "1925-11-20",
        "nepDate": "05.08.1982"
    },
    {
        "engDate": "1925-11-21",
        "nepDate": "06.08.1982"
    },
    {
        "engDate": "1925-11-22",
        "nepDate": "07.08.1982"
    },
    {
        "engDate": "1925-11-23",
        "nepDate": "08.08.1982"
    },
    {
        "engDate": "1925-11-24",
        "nepDate": "09.08.1982"
    },
    {
        "engDate": "1925-11-25",
        "nepDate": "10.08.1982"
    },
    {
        "engDate": "1925-11-26",
        "nepDate": "11.08.1982"
    },
    {
        "engDate": "1925-11-27",
        "nepDate": "12.08.1982"
    },
    {
        "engDate": "1925-11-28",
        "nepDate": "13.08.1982"
    },
    {
        "engDate": "1925-11-29",
        "nepDate": "14.08.1982"
    },
    {
        "engDate": "1925-11-30",
        "nepDate": "15.08.1982"
    },
    {
        "engDate": "1925-12-01",
        "nepDate": "16.08.1982"
    },
    {
        "engDate": "1925-12-02",
        "nepDate": "17.08.1982"
    },
    {
        "engDate": "1925-12-03",
        "nepDate": "18.08.1982"
    },
    {
        "engDate": "1925-12-04",
        "nepDate": "19.08.1982"
    },
    {
        "engDate": "1925-12-05",
        "nepDate": "20.08.1982"
    },
    {
        "engDate": "1925-12-06",
        "nepDate": "21.08.1982"
    },
    {
        "engDate": "1925-12-07",
        "nepDate": "22.08.1982"
    },
    {
        "engDate": "1925-12-08",
        "nepDate": "23.08.1982"
    },
    {
        "engDate": "1925-12-09",
        "nepDate": "24.08.1982"
    },
    {
        "engDate": "1925-12-10",
        "nepDate": "25.08.1982"
    },
    {
        "engDate": "1925-12-11",
        "nepDate": "26.08.1982"
    },
    {
        "engDate": "1925-12-12",
        "nepDate": "27.08.1982"
    },
    {
        "engDate": "1925-12-13",
        "nepDate": "28.08.1982"
    },
    {
        "engDate": "1925-12-14",
        "nepDate": "29.08.1982"
    },
    {
        "engDate": "1925-12-15",
        "nepDate": "01.09.1982"
    },
    {
        "engDate": "1925-12-16",
        "nepDate": "02.09.1982"
    },
    {
        "engDate": "1925-12-17",
        "nepDate": "03.09.1982"
    },
    {
        "engDate": "1925-12-18",
        "nepDate": "04.09.1982"
    },
    {
        "engDate": "1925-12-19",
        "nepDate": "05.09.1982"
    },
    {
        "engDate": "1925-12-20",
        "nepDate": "06.09.1982"
    },
    {
        "engDate": "1925-12-21",
        "nepDate": "07.09.1982"
    },
    {
        "engDate": "1925-12-22",
        "nepDate": "08.09.1982"
    },
    {
        "engDate": "1925-12-23",
        "nepDate": "09.09.1982"
    },
    {
        "engDate": "1925-12-24",
        "nepDate": "10.09.1982"
    },
    {
        "engDate": "1925-12-25",
        "nepDate": "11.09.1982"
    },
    {
        "engDate": "1925-12-26",
        "nepDate": "12.09.1982"
    },
    {
        "engDate": "1925-12-27",
        "nepDate": "13.09.1982"
    },
    {
        "engDate": "1925-12-28",
        "nepDate": "14.09.1982"
    },
    {
        "engDate": "1925-12-29",
        "nepDate": "15.09.1982"
    },
    {
        "engDate": "1925-12-30",
        "nepDate": "16.09.1982"
    },
    {
        "engDate": "1925-12-31",
        "nepDate": "17.09.1982"
    },
    {
        "engDate": "1926-01-01",
        "nepDate": "18.09.1982"
    },
    {
        "engDate": "1926-01-02",
        "nepDate": "19.09.1982"
    },
    {
        "engDate": "1926-01-03",
        "nepDate": "20.09.1982"
    },
    {
        "engDate": "1926-01-04",
        "nepDate": "21.09.1982"
    },
    {
        "engDate": "1926-01-05",
        "nepDate": "22.09.1982"
    },
    {
        "engDate": "1926-01-06",
        "nepDate": "23.09.1982"
    },
    {
        "engDate": "1926-01-07",
        "nepDate": "24.09.1982"
    },
    {
        "engDate": "1926-01-08",
        "nepDate": "25.09.1982"
    },
    {
        "engDate": "1926-01-09",
        "nepDate": "26.09.1982"
    },
    {
        "engDate": "1926-01-10",
        "nepDate": "27.09.1982"
    },
    {
        "engDate": "1926-01-11",
        "nepDate": "28.09.1982"
    },
    {
        "engDate": "1926-01-12",
        "nepDate": "29.09.1982"
    },
    {
        "engDate": "1926-01-13",
        "nepDate": "30.09.1982"
    },
    {
        "engDate": "1926-01-14",
        "nepDate": "01.10.1982"
    },
    {
        "engDate": "1926-01-15",
        "nepDate": "02.10.1982"
    },
    {
        "engDate": "1926-01-16",
        "nepDate": "03.10.1982"
    },
    {
        "engDate": "1926-01-17",
        "nepDate": "04.10.1982"
    },
    {
        "engDate": "1926-01-18",
        "nepDate": "05.10.1982"
    },
    {
        "engDate": "1926-01-19",
        "nepDate": "06.10.1982"
    },
    {
        "engDate": "1926-01-20",
        "nepDate": "07.10.1982"
    },
    {
        "engDate": "1926-01-21",
        "nepDate": "08.10.1982"
    },
    {
        "engDate": "1926-01-22",
        "nepDate": "09.10.1982"
    },
    {
        "engDate": "1926-01-23",
        "nepDate": "10.10.1982"
    },
    {
        "engDate": "1926-01-24",
        "nepDate": "11.10.1982"
    },
    {
        "engDate": "1926-01-25",
        "nepDate": "12.10.1982"
    },
    {
        "engDate": "1926-01-26",
        "nepDate": "13.10.1982"
    },
    {
        "engDate": "1926-01-27",
        "nepDate": "14.10.1982"
    },
    {
        "engDate": "1926-01-28",
        "nepDate": "15.10.1982"
    },
    {
        "engDate": "1926-01-29",
        "nepDate": "16.10.1982"
    },
    {
        "engDate": "1926-01-30",
        "nepDate": "17.10.1982"
    },
    {
        "engDate": "1926-01-31",
        "nepDate": "18.10.1982"
    },
    {
        "engDate": "1926-02-01",
        "nepDate": "19.10.1982"
    },
    {
        "engDate": "1926-02-02",
        "nepDate": "20.10.1982"
    },
    {
        "engDate": "1926-02-03",
        "nepDate": "21.10.1982"
    },
    {
        "engDate": "1926-02-04",
        "nepDate": "22.10.1982"
    },
    {
        "engDate": "1926-02-05",
        "nepDate": "23.10.1982"
    },
    {
        "engDate": "1926-02-06",
        "nepDate": "24.10.1982"
    },
    {
        "engDate": "1926-02-07",
        "nepDate": "25.10.1982"
    },
    {
        "engDate": "1926-02-08",
        "nepDate": "26.10.1982"
    },
    {
        "engDate": "1926-02-09",
        "nepDate": "27.10.1982"
    },
    {
        "engDate": "1926-02-10",
        "nepDate": "28.10.1982"
    },
    {
        "engDate": "1926-02-11",
        "nepDate": "29.10.1982"
    },
    {
        "engDate": "1926-02-12",
        "nepDate": "01.11.1982"
    },
    {
        "engDate": "1926-02-13",
        "nepDate": "02.11.1982"
    },
    {
        "engDate": "1926-02-14",
        "nepDate": "03.11.1982"
    },
    {
        "engDate": "1926-02-15",
        "nepDate": "04.11.1982"
    },
    {
        "engDate": "1926-02-16",
        "nepDate": "05.11.1982"
    },
    {
        "engDate": "1926-02-17",
        "nepDate": "06.11.1982"
    },
    {
        "engDate": "1926-02-18",
        "nepDate": "07.11.1982"
    },
    {
        "engDate": "1926-02-19",
        "nepDate": "08.11.1982"
    },
    {
        "engDate": "1926-02-20",
        "nepDate": "09.11.1982"
    },
    {
        "engDate": "1926-02-21",
        "nepDate": "10.11.1982"
    },
    {
        "engDate": "1926-02-22",
        "nepDate": "11.11.1982"
    },
    {
        "engDate": "1926-02-23",
        "nepDate": "12.11.1982"
    },
    {
        "engDate": "1926-02-24",
        "nepDate": "13.11.1982"
    },
    {
        "engDate": "1926-02-25",
        "nepDate": "14.11.1982"
    },
    {
        "engDate": "1926-02-26",
        "nepDate": "15.11.1982"
    },
    {
        "engDate": "1926-02-27",
        "nepDate": "16.11.1982"
    },
    {
        "engDate": "1926-02-28",
        "nepDate": "17.11.1982"
    },
    {
        "engDate": "1926-03-01",
        "nepDate": "18.11.1982"
    },
    {
        "engDate": "1926-03-02",
        "nepDate": "19.11.1982"
    },
    {
        "engDate": "1926-03-03",
        "nepDate": "20.11.1982"
    },
    {
        "engDate": "1926-03-04",
        "nepDate": "21.11.1982"
    },
    {
        "engDate": "1926-03-05",
        "nepDate": "22.11.1982"
    },
    {
        "engDate": "1926-03-06",
        "nepDate": "23.11.1982"
    },
    {
        "engDate": "1926-03-07",
        "nepDate": "24.11.1982"
    },
    {
        "engDate": "1926-03-08",
        "nepDate": "25.11.1982"
    },
    {
        "engDate": "1926-03-09",
        "nepDate": "26.11.1982"
    },
    {
        "engDate": "1926-03-10",
        "nepDate": "27.11.1982"
    },
    {
        "engDate": "1926-03-11",
        "nepDate": "28.11.1982"
    },
    {
        "engDate": "1926-03-12",
        "nepDate": "29.11.1982"
    },
    {
        "engDate": "1926-03-13",
        "nepDate": "30.11.1982"
    },
    {
        "engDate": "1926-03-14",
        "nepDate": "01.12.1982"
    },
    {
        "engDate": "1926-03-15",
        "nepDate": "02.12.1982"
    },
    {
        "engDate": "1926-03-16",
        "nepDate": "03.12.1982"
    },
    {
        "engDate": "1926-03-17",
        "nepDate": "04.12.1982"
    },
    {
        "engDate": "1926-03-18",
        "nepDate": "05.12.1982"
    },
    {
        "engDate": "1926-03-19",
        "nepDate": "06.12.1982"
    },
    {
        "engDate": "1926-03-20",
        "nepDate": "07.12.1982"
    },
    {
        "engDate": "1926-03-21",
        "nepDate": "08.12.1982"
    },
    {
        "engDate": "1926-03-22",
        "nepDate": "09.12.1982"
    },
    {
        "engDate": "1926-03-23",
        "nepDate": "10.12.1982"
    },
    {
        "engDate": "1926-03-24",
        "nepDate": "11.12.1982"
    },
    {
        "engDate": "1926-03-25",
        "nepDate": "12.12.1982"
    },
    {
        "engDate": "1926-03-26",
        "nepDate": "13.12.1982"
    },
    {
        "engDate": "1926-03-27",
        "nepDate": "14.12.1982"
    },
    {
        "engDate": "1926-03-28",
        "nepDate": "15.12.1982"
    },
    {
        "engDate": "1926-03-29",
        "nepDate": "16.12.1982"
    },
    {
        "engDate": "1926-03-30",
        "nepDate": "17.12.1982"
    },
    {
        "engDate": "1926-03-31",
        "nepDate": "18.12.1982"
    },
    {
        "engDate": "1926-04-01",
        "nepDate": "19.12.1982"
    },
    {
        "engDate": "1926-04-02",
        "nepDate": "20.12.1982"
    },
    {
        "engDate": "1926-04-03",
        "nepDate": "21.12.1982"
    },
    {
        "engDate": "1926-04-04",
        "nepDate": "22.12.1982"
    },
    {
        "engDate": "1926-04-05",
        "nepDate": "23.12.1982"
    },
    {
        "engDate": "1926-04-06",
        "nepDate": "24.12.1982"
    },
    {
        "engDate": "1926-04-07",
        "nepDate": "25.12.1982"
    },
    {
        "engDate": "1926-04-08",
        "nepDate": "26.12.1982"
    },
    {
        "engDate": "1926-04-09",
        "nepDate": "27.12.1982"
    },
    {
        "engDate": "1926-04-10",
        "nepDate": "28.12.1982"
    },
    {
        "engDate": "1926-04-11",
        "nepDate": "29.12.1982"
    },
    {
        "engDate": "1926-04-12",
        "nepDate": "30.12.1982"
    },
    {
        "engDate": "1926-04-13",
        "nepDate": "01.01.1983"
    },
    {
        "engDate": "1926-04-14",
        "nepDate": "02.01.1983"
    },
    {
        "engDate": "1926-04-15",
        "nepDate": "03.01.1983"
    },
    {
        "engDate": "1926-04-16",
        "nepDate": "04.01.1983"
    },
    {
        "engDate": "1926-04-17",
        "nepDate": "05.01.1983"
    },
    {
        "engDate": "1926-04-18",
        "nepDate": "06.01.1983"
    },
    {
        "engDate": "1926-04-19",
        "nepDate": "07.01.1983"
    },
    {
        "engDate": "1926-04-20",
        "nepDate": "08.01.1983"
    },
    {
        "engDate": "1926-04-21",
        "nepDate": "09.01.1983"
    },
    {
        "engDate": "1926-04-22",
        "nepDate": "10.01.1983"
    },
    {
        "engDate": "1926-04-23",
        "nepDate": "11.01.1983"
    },
    {
        "engDate": "1926-04-24",
        "nepDate": "12.01.1983"
    },
    {
        "engDate": "1926-04-25",
        "nepDate": "13.01.1983"
    },
    {
        "engDate": "1926-04-26",
        "nepDate": "14.01.1983"
    },
    {
        "engDate": "1926-04-27",
        "nepDate": "15.01.1983"
    },
    {
        "engDate": "1926-04-28",
        "nepDate": "16.01.1983"
    },
    {
        "engDate": "1926-04-29",
        "nepDate": "17.01.1983"
    },
    {
        "engDate": "1926-04-30",
        "nepDate": "18.01.1983"
    },
    {
        "engDate": "1926-05-01",
        "nepDate": "19.01.1983"
    },
    {
        "engDate": "1926-05-02",
        "nepDate": "20.01.1983"
    },
    {
        "engDate": "1926-05-03",
        "nepDate": "21.01.1983"
    },
    {
        "engDate": "1926-05-04",
        "nepDate": "22.01.1983"
    },
    {
        "engDate": "1926-05-05",
        "nepDate": "23.01.1983"
    },
    {
        "engDate": "1926-05-06",
        "nepDate": "24.01.1983"
    },
    {
        "engDate": "1926-05-07",
        "nepDate": "25.01.1983"
    },
    {
        "engDate": "1926-05-08",
        "nepDate": "26.01.1983"
    },
    {
        "engDate": "1926-05-09",
        "nepDate": "27.01.1983"
    },
    {
        "engDate": "1926-05-10",
        "nepDate": "28.01.1983"
    },
    {
        "engDate": "1926-05-11",
        "nepDate": "29.01.1983"
    },
    {
        "engDate": "1926-05-12",
        "nepDate": "30.01.1983"
    },
    {
        "engDate": "1926-05-13",
        "nepDate": "31.01.1983"
    },
    {
        "engDate": "1926-05-14",
        "nepDate": "01.02.1983"
    },
    {
        "engDate": "1926-05-15",
        "nepDate": "02.02.1983"
    },
    {
        "engDate": "1926-05-16",
        "nepDate": "03.02.1983"
    },
    {
        "engDate": "1926-05-17",
        "nepDate": "04.02.1983"
    },
    {
        "engDate": "1926-05-18",
        "nepDate": "05.02.1983"
    },
    {
        "engDate": "1926-05-19",
        "nepDate": "06.02.1983"
    },
    {
        "engDate": "1926-05-20",
        "nepDate": "07.02.1983"
    },
    {
        "engDate": "1926-05-21",
        "nepDate": "08.02.1983"
    },
    {
        "engDate": "1926-05-22",
        "nepDate": "09.02.1983"
    },
    {
        "engDate": "1926-05-23",
        "nepDate": "10.02.1983"
    },
    {
        "engDate": "1926-05-24",
        "nepDate": "11.02.1983"
    },
    {
        "engDate": "1926-05-25",
        "nepDate": "12.02.1983"
    },
    {
        "engDate": "1926-05-26",
        "nepDate": "13.02.1983"
    },
    {
        "engDate": "1926-05-27",
        "nepDate": "14.02.1983"
    },
    {
        "engDate": "1926-05-28",
        "nepDate": "15.02.1983"
    },
    {
        "engDate": "1926-05-29",
        "nepDate": "16.02.1983"
    },
    {
        "engDate": "1926-05-30",
        "nepDate": "17.02.1983"
    },
    {
        "engDate": "1926-05-31",
        "nepDate": "18.02.1983"
    },
    {
        "engDate": "1926-06-01",
        "nepDate": "19.02.1983"
    },
    {
        "engDate": "1926-06-02",
        "nepDate": "20.02.1983"
    },
    {
        "engDate": "1926-06-03",
        "nepDate": "21.02.1983"
    },
    {
        "engDate": "1926-06-04",
        "nepDate": "22.02.1983"
    },
    {
        "engDate": "1926-06-05",
        "nepDate": "23.02.1983"
    },
    {
        "engDate": "1926-06-06",
        "nepDate": "24.02.1983"
    },
    {
        "engDate": "1926-06-07",
        "nepDate": "25.02.1983"
    },
    {
        "engDate": "1926-06-08",
        "nepDate": "26.02.1983"
    },
    {
        "engDate": "1926-06-09",
        "nepDate": "27.02.1983"
    },
    {
        "engDate": "1926-06-10",
        "nepDate": "28.02.1983"
    },
    {
        "engDate": "1926-06-11",
        "nepDate": "29.02.1983"
    },
    {
        "engDate": "1926-06-12",
        "nepDate": "30.02.1983"
    },
    {
        "engDate": "1926-06-13",
        "nepDate": "31.02.1983"
    },
    {
        "engDate": "1926-06-14",
        "nepDate": "01.03.1983"
    },
    {
        "engDate": "1926-06-15",
        "nepDate": "02.03.1983"
    },
    {
        "engDate": "1926-06-16",
        "nepDate": "03.03.1983"
    },
    {
        "engDate": "1926-06-17",
        "nepDate": "04.03.1983"
    },
    {
        "engDate": "1926-06-18",
        "nepDate": "05.03.1983"
    },
    {
        "engDate": "1926-06-19",
        "nepDate": "06.03.1983"
    },
    {
        "engDate": "1926-06-20",
        "nepDate": "07.03.1983"
    },
    {
        "engDate": "1926-06-21",
        "nepDate": "08.03.1983"
    },
    {
        "engDate": "1926-06-22",
        "nepDate": "09.03.1983"
    },
    {
        "engDate": "1926-06-23",
        "nepDate": "10.03.1983"
    },
    {
        "engDate": "1926-06-24",
        "nepDate": "11.03.1983"
    },
    {
        "engDate": "1926-06-25",
        "nepDate": "12.03.1983"
    },
    {
        "engDate": "1926-06-26",
        "nepDate": "13.03.1983"
    },
    {
        "engDate": "1926-06-27",
        "nepDate": "14.03.1983"
    },
    {
        "engDate": "1926-06-28",
        "nepDate": "15.03.1983"
    },
    {
        "engDate": "1926-06-29",
        "nepDate": "16.03.1983"
    },
    {
        "engDate": "1926-06-30",
        "nepDate": "17.03.1983"
    },
    {
        "engDate": "1926-07-01",
        "nepDate": "18.03.1983"
    },
    {
        "engDate": "1926-07-02",
        "nepDate": "19.03.1983"
    },
    {
        "engDate": "1926-07-03",
        "nepDate": "20.03.1983"
    },
    {
        "engDate": "1926-07-04",
        "nepDate": "21.03.1983"
    },
    {
        "engDate": "1926-07-05",
        "nepDate": "22.03.1983"
    },
    {
        "engDate": "1926-07-06",
        "nepDate": "23.03.1983"
    },
    {
        "engDate": "1926-07-07",
        "nepDate": "24.03.1983"
    },
    {
        "engDate": "1926-07-08",
        "nepDate": "25.03.1983"
    },
    {
        "engDate": "1926-07-09",
        "nepDate": "26.03.1983"
    },
    {
        "engDate": "1926-07-10",
        "nepDate": "27.03.1983"
    },
    {
        "engDate": "1926-07-11",
        "nepDate": "28.03.1983"
    },
    {
        "engDate": "1926-07-12",
        "nepDate": "29.03.1983"
    },
    {
        "engDate": "1926-07-13",
        "nepDate": "30.03.1983"
    },
    {
        "engDate": "1926-07-14",
        "nepDate": "31.03.1983"
    },
    {
        "engDate": "1926-07-15",
        "nepDate": "32.03.1983"
    },
    {
        "engDate": "1926-07-16",
        "nepDate": "01.04.1983"
    },
    {
        "engDate": "1926-07-17",
        "nepDate": "02.04.1983"
    },
    {
        "engDate": "1926-07-18",
        "nepDate": "03.04.1983"
    },
    {
        "engDate": "1926-07-19",
        "nepDate": "04.04.1983"
    },
    {
        "engDate": "1926-07-20",
        "nepDate": "05.04.1983"
    },
    {
        "engDate": "1926-07-21",
        "nepDate": "06.04.1983"
    },
    {
        "engDate": "1926-07-22",
        "nepDate": "07.04.1983"
    },
    {
        "engDate": "1926-07-23",
        "nepDate": "08.04.1983"
    },
    {
        "engDate": "1926-07-24",
        "nepDate": "09.04.1983"
    },
    {
        "engDate": "1926-07-25",
        "nepDate": "10.04.1983"
    },
    {
        "engDate": "1926-07-26",
        "nepDate": "11.04.1983"
    },
    {
        "engDate": "1926-07-27",
        "nepDate": "12.04.1983"
    },
    {
        "engDate": "1926-07-28",
        "nepDate": "13.04.1983"
    },
    {
        "engDate": "1926-07-29",
        "nepDate": "14.04.1983"
    },
    {
        "engDate": "1926-07-30",
        "nepDate": "15.04.1983"
    },
    {
        "engDate": "1926-07-31",
        "nepDate": "16.04.1983"
    },
    {
        "engDate": "1926-08-01",
        "nepDate": "17.04.1983"
    },
    {
        "engDate": "1926-08-02",
        "nepDate": "18.04.1983"
    },
    {
        "engDate": "1926-08-03",
        "nepDate": "19.04.1983"
    },
    {
        "engDate": "1926-08-04",
        "nepDate": "20.04.1983"
    },
    {
        "engDate": "1926-08-05",
        "nepDate": "21.04.1983"
    },
    {
        "engDate": "1926-08-06",
        "nepDate": "22.04.1983"
    },
    {
        "engDate": "1926-08-07",
        "nepDate": "23.04.1983"
    },
    {
        "engDate": "1926-08-08",
        "nepDate": "24.04.1983"
    },
    {
        "engDate": "1926-08-09",
        "nepDate": "25.04.1983"
    },
    {
        "engDate": "1926-08-10",
        "nepDate": "26.04.1983"
    },
    {
        "engDate": "1926-08-11",
        "nepDate": "27.04.1983"
    },
    {
        "engDate": "1926-08-12",
        "nepDate": "28.04.1983"
    },
    {
        "engDate": "1926-08-13",
        "nepDate": "29.04.1983"
    },
    {
        "engDate": "1926-08-14",
        "nepDate": "30.04.1983"
    },
    {
        "engDate": "1926-08-15",
        "nepDate": "31.04.1983"
    },
    {
        "engDate": "1926-08-16",
        "nepDate": "32.04.1983"
    },
    {
        "engDate": "1926-08-17",
        "nepDate": "01.05.1983"
    },
    {
        "engDate": "1926-08-18",
        "nepDate": "02.05.1983"
    },
    {
        "engDate": "1926-08-19",
        "nepDate": "03.05.1983"
    },
    {
        "engDate": "1926-08-20",
        "nepDate": "04.05.1983"
    },
    {
        "engDate": "1926-08-21",
        "nepDate": "05.05.1983"
    },
    {
        "engDate": "1926-08-22",
        "nepDate": "06.05.1983"
    },
    {
        "engDate": "1926-08-23",
        "nepDate": "07.05.1983"
    },
    {
        "engDate": "1926-08-24",
        "nepDate": "08.05.1983"
    },
    {
        "engDate": "1926-08-25",
        "nepDate": "09.05.1983"
    },
    {
        "engDate": "1926-08-26",
        "nepDate": "10.05.1983"
    },
    {
        "engDate": "1926-08-27",
        "nepDate": "11.05.1983"
    },
    {
        "engDate": "1926-08-28",
        "nepDate": "12.05.1983"
    },
    {
        "engDate": "1926-08-29",
        "nepDate": "13.05.1983"
    },
    {
        "engDate": "1926-08-30",
        "nepDate": "14.05.1983"
    },
    {
        "engDate": "1926-08-31",
        "nepDate": "15.05.1983"
    },
    {
        "engDate": "1926-09-01",
        "nepDate": "16.05.1983"
    },
    {
        "engDate": "1926-09-02",
        "nepDate": "17.05.1983"
    },
    {
        "engDate": "1926-09-03",
        "nepDate": "18.05.1983"
    },
    {
        "engDate": "1926-09-04",
        "nepDate": "19.05.1983"
    },
    {
        "engDate": "1926-09-05",
        "nepDate": "20.05.1983"
    },
    {
        "engDate": "1926-09-06",
        "nepDate": "21.05.1983"
    },
    {
        "engDate": "1926-09-07",
        "nepDate": "22.05.1983"
    },
    {
        "engDate": "1926-09-08",
        "nepDate": "23.05.1983"
    },
    {
        "engDate": "1926-09-09",
        "nepDate": "24.05.1983"
    },
    {
        "engDate": "1926-09-10",
        "nepDate": "25.05.1983"
    },
    {
        "engDate": "1926-09-11",
        "nepDate": "26.05.1983"
    },
    {
        "engDate": "1926-09-12",
        "nepDate": "27.05.1983"
    },
    {
        "engDate": "1926-09-13",
        "nepDate": "28.05.1983"
    },
    {
        "engDate": "1926-09-14",
        "nepDate": "29.05.1983"
    },
    {
        "engDate": "1926-09-15",
        "nepDate": "30.05.1983"
    },
    {
        "engDate": "1926-09-16",
        "nepDate": "31.05.1983"
    },
    {
        "engDate": "1926-09-17",
        "nepDate": "01.06.1983"
    },
    {
        "engDate": "1926-09-18",
        "nepDate": "02.06.1983"
    },
    {
        "engDate": "1926-09-19",
        "nepDate": "03.06.1983"
    },
    {
        "engDate": "1926-09-20",
        "nepDate": "04.06.1983"
    },
    {
        "engDate": "1926-09-21",
        "nepDate": "05.06.1983"
    },
    {
        "engDate": "1926-09-22",
        "nepDate": "06.06.1983"
    },
    {
        "engDate": "1926-09-23",
        "nepDate": "07.06.1983"
    },
    {
        "engDate": "1926-09-24",
        "nepDate": "08.06.1983"
    },
    {
        "engDate": "1926-09-25",
        "nepDate": "09.06.1983"
    },
    {
        "engDate": "1926-09-26",
        "nepDate": "10.06.1983"
    },
    {
        "engDate": "1926-09-27",
        "nepDate": "11.06.1983"
    },
    {
        "engDate": "1926-09-28",
        "nepDate": "12.06.1983"
    },
    {
        "engDate": "1926-09-29",
        "nepDate": "13.06.1983"
    },
    {
        "engDate": "1926-09-30",
        "nepDate": "14.06.1983"
    },
    {
        "engDate": "1926-10-01",
        "nepDate": "15.06.1983"
    },
    {
        "engDate": "1926-10-02",
        "nepDate": "16.06.1983"
    },
    {
        "engDate": "1926-10-03",
        "nepDate": "17.06.1983"
    },
    {
        "engDate": "1926-10-04",
        "nepDate": "18.06.1983"
    },
    {
        "engDate": "1926-10-05",
        "nepDate": "19.06.1983"
    },
    {
        "engDate": "1926-10-06",
        "nepDate": "20.06.1983"
    },
    {
        "engDate": "1926-10-07",
        "nepDate": "21.06.1983"
    },
    {
        "engDate": "1926-10-08",
        "nepDate": "22.06.1983"
    },
    {
        "engDate": "1926-10-09",
        "nepDate": "23.06.1983"
    },
    {
        "engDate": "1926-10-10",
        "nepDate": "24.06.1983"
    },
    {
        "engDate": "1926-10-11",
        "nepDate": "25.06.1983"
    },
    {
        "engDate": "1926-10-12",
        "nepDate": "26.06.1983"
    },
    {
        "engDate": "1926-10-13",
        "nepDate": "27.06.1983"
    },
    {
        "engDate": "1926-10-14",
        "nepDate": "28.06.1983"
    },
    {
        "engDate": "1926-10-15",
        "nepDate": "29.06.1983"
    },
    {
        "engDate": "1926-10-16",
        "nepDate": "30.06.1983"
    },
    {
        "engDate": "1926-10-17",
        "nepDate": "01.07.1983"
    },
    {
        "engDate": "1926-10-18",
        "nepDate": "02.07.1983"
    },
    {
        "engDate": "1926-10-19",
        "nepDate": "03.07.1983"
    },
    {
        "engDate": "1926-10-20",
        "nepDate": "04.07.1983"
    },
    {
        "engDate": "1926-10-21",
        "nepDate": "05.07.1983"
    },
    {
        "engDate": "1926-10-22",
        "nepDate": "06.07.1983"
    },
    {
        "engDate": "1926-10-23",
        "nepDate": "07.07.1983"
    },
    {
        "engDate": "1926-10-24",
        "nepDate": "08.07.1983"
    },
    {
        "engDate": "1926-10-25",
        "nepDate": "09.07.1983"
    },
    {
        "engDate": "1926-10-26",
        "nepDate": "10.07.1983"
    },
    {
        "engDate": "1926-10-27",
        "nepDate": "11.07.1983"
    },
    {
        "engDate": "1926-10-28",
        "nepDate": "12.07.1983"
    },
    {
        "engDate": "1926-10-29",
        "nepDate": "13.07.1983"
    },
    {
        "engDate": "1926-10-30",
        "nepDate": "14.07.1983"
    },
    {
        "engDate": "1926-10-31",
        "nepDate": "15.07.1983"
    },
    {
        "engDate": "1926-11-01",
        "nepDate": "16.07.1983"
    },
    {
        "engDate": "1926-11-02",
        "nepDate": "17.07.1983"
    },
    {
        "engDate": "1926-11-03",
        "nepDate": "18.07.1983"
    },
    {
        "engDate": "1926-11-04",
        "nepDate": "19.07.1983"
    },
    {
        "engDate": "1926-11-05",
        "nepDate": "20.07.1983"
    },
    {
        "engDate": "1926-11-06",
        "nepDate": "21.07.1983"
    },
    {
        "engDate": "1926-11-07",
        "nepDate": "22.07.1983"
    },
    {
        "engDate": "1926-11-08",
        "nepDate": "23.07.1983"
    },
    {
        "engDate": "1926-11-09",
        "nepDate": "24.07.1983"
    },
    {
        "engDate": "1926-11-10",
        "nepDate": "25.07.1983"
    },
    {
        "engDate": "1926-11-11",
        "nepDate": "26.07.1983"
    },
    {
        "engDate": "1926-11-12",
        "nepDate": "27.07.1983"
    },
    {
        "engDate": "1926-11-13",
        "nepDate": "28.07.1983"
    },
    {
        "engDate": "1926-11-14",
        "nepDate": "29.07.1983"
    },
    {
        "engDate": "1926-11-15",
        "nepDate": "30.07.1983"
    },
    {
        "engDate": "1926-11-16",
        "nepDate": "01.08.1983"
    },
    {
        "engDate": "1926-11-17",
        "nepDate": "02.08.1983"
    },
    {
        "engDate": "1926-11-18",
        "nepDate": "03.08.1983"
    },
    {
        "engDate": "1926-11-19",
        "nepDate": "04.08.1983"
    },
    {
        "engDate": "1926-11-20",
        "nepDate": "05.08.1983"
    },
    {
        "engDate": "1926-11-21",
        "nepDate": "06.08.1983"
    },
    {
        "engDate": "1926-11-22",
        "nepDate": "07.08.1983"
    },
    {
        "engDate": "1926-11-23",
        "nepDate": "08.08.1983"
    },
    {
        "engDate": "1926-11-24",
        "nepDate": "09.08.1983"
    },
    {
        "engDate": "1926-11-25",
        "nepDate": "10.08.1983"
    },
    {
        "engDate": "1926-11-26",
        "nepDate": "11.08.1983"
    },
    {
        "engDate": "1926-11-27",
        "nepDate": "12.08.1983"
    },
    {
        "engDate": "1926-11-28",
        "nepDate": "13.08.1983"
    },
    {
        "engDate": "1926-11-29",
        "nepDate": "14.08.1983"
    },
    {
        "engDate": "1926-11-30",
        "nepDate": "15.08.1983"
    },
    {
        "engDate": "1926-12-01",
        "nepDate": "16.08.1983"
    },
    {
        "engDate": "1926-12-02",
        "nepDate": "17.08.1983"
    },
    {
        "engDate": "1926-12-03",
        "nepDate": "18.08.1983"
    },
    {
        "engDate": "1926-12-04",
        "nepDate": "19.08.1983"
    },
    {
        "engDate": "1926-12-05",
        "nepDate": "20.08.1983"
    },
    {
        "engDate": "1926-12-06",
        "nepDate": "21.08.1983"
    },
    {
        "engDate": "1926-12-07",
        "nepDate": "22.08.1983"
    },
    {
        "engDate": "1926-12-08",
        "nepDate": "23.08.1983"
    },
    {
        "engDate": "1926-12-09",
        "nepDate": "24.08.1983"
    },
    {
        "engDate": "1926-12-10",
        "nepDate": "25.08.1983"
    },
    {
        "engDate": "1926-12-11",
        "nepDate": "26.08.1983"
    },
    {
        "engDate": "1926-12-12",
        "nepDate": "27.08.1983"
    },
    {
        "engDate": "1926-12-13",
        "nepDate": "28.08.1983"
    },
    {
        "engDate": "1926-12-14",
        "nepDate": "29.08.1983"
    },
    {
        "engDate": "1926-12-15",
        "nepDate": "01.09.1983"
    },
    {
        "engDate": "1926-12-16",
        "nepDate": "02.09.1983"
    },
    {
        "engDate": "1926-12-17",
        "nepDate": "03.09.1983"
    },
    {
        "engDate": "1926-12-18",
        "nepDate": "04.09.1983"
    },
    {
        "engDate": "1926-12-19",
        "nepDate": "05.09.1983"
    },
    {
        "engDate": "1926-12-20",
        "nepDate": "06.09.1983"
    },
    {
        "engDate": "1926-12-21",
        "nepDate": "07.09.1983"
    },
    {
        "engDate": "1926-12-22",
        "nepDate": "08.09.1983"
    },
    {
        "engDate": "1926-12-23",
        "nepDate": "09.09.1983"
    },
    {
        "engDate": "1926-12-24",
        "nepDate": "10.09.1983"
    },
    {
        "engDate": "1926-12-25",
        "nepDate": "11.09.1983"
    },
    {
        "engDate": "1926-12-26",
        "nepDate": "12.09.1983"
    },
    {
        "engDate": "1926-12-27",
        "nepDate": "13.09.1983"
    },
    {
        "engDate": "1926-12-28",
        "nepDate": "14.09.1983"
    },
    {
        "engDate": "1926-12-29",
        "nepDate": "15.09.1983"
    },
    {
        "engDate": "1926-12-30",
        "nepDate": "16.09.1983"
    },
    {
        "engDate": "1926-12-31",
        "nepDate": "17.09.1983"
    },
    {
        "engDate": "1927-01-01",
        "nepDate": "18.09.1983"
    },
    {
        "engDate": "1927-01-02",
        "nepDate": "19.09.1983"
    },
    {
        "engDate": "1927-01-03",
        "nepDate": "20.09.1983"
    },
    {
        "engDate": "1927-01-04",
        "nepDate": "21.09.1983"
    },
    {
        "engDate": "1927-01-05",
        "nepDate": "22.09.1983"
    },
    {
        "engDate": "1927-01-06",
        "nepDate": "23.09.1983"
    },
    {
        "engDate": "1927-01-07",
        "nepDate": "24.09.1983"
    },
    {
        "engDate": "1927-01-08",
        "nepDate": "25.09.1983"
    },
    {
        "engDate": "1927-01-09",
        "nepDate": "26.09.1983"
    },
    {
        "engDate": "1927-01-10",
        "nepDate": "27.09.1983"
    },
    {
        "engDate": "1927-01-11",
        "nepDate": "28.09.1983"
    },
    {
        "engDate": "1927-01-12",
        "nepDate": "29.09.1983"
    },
    {
        "engDate": "1927-01-13",
        "nepDate": "30.09.1983"
    },
    {
        "engDate": "1927-01-14",
        "nepDate": "01.10.1983"
    },
    {
        "engDate": "1927-01-15",
        "nepDate": "02.10.1983"
    },
    {
        "engDate": "1927-01-16",
        "nepDate": "03.10.1983"
    },
    {
        "engDate": "1927-01-17",
        "nepDate": "04.10.1983"
    },
    {
        "engDate": "1927-01-18",
        "nepDate": "05.10.1983"
    },
    {
        "engDate": "1927-01-19",
        "nepDate": "06.10.1983"
    },
    {
        "engDate": "1927-01-20",
        "nepDate": "07.10.1983"
    },
    {
        "engDate": "1927-01-21",
        "nepDate": "08.10.1983"
    },
    {
        "engDate": "1927-01-22",
        "nepDate": "09.10.1983"
    },
    {
        "engDate": "1927-01-23",
        "nepDate": "10.10.1983"
    },
    {
        "engDate": "1927-01-24",
        "nepDate": "11.10.1983"
    },
    {
        "engDate": "1927-01-25",
        "nepDate": "12.10.1983"
    },
    {
        "engDate": "1927-01-26",
        "nepDate": "13.10.1983"
    },
    {
        "engDate": "1927-01-27",
        "nepDate": "14.10.1983"
    },
    {
        "engDate": "1927-01-28",
        "nepDate": "15.10.1983"
    },
    {
        "engDate": "1927-01-29",
        "nepDate": "16.10.1983"
    },
    {
        "engDate": "1927-01-30",
        "nepDate": "17.10.1983"
    },
    {
        "engDate": "1927-01-31",
        "nepDate": "18.10.1983"
    },
    {
        "engDate": "1927-02-01",
        "nepDate": "19.10.1983"
    },
    {
        "engDate": "1927-02-02",
        "nepDate": "20.10.1983"
    },
    {
        "engDate": "1927-02-03",
        "nepDate": "21.10.1983"
    },
    {
        "engDate": "1927-02-04",
        "nepDate": "22.10.1983"
    },
    {
        "engDate": "1927-02-05",
        "nepDate": "23.10.1983"
    },
    {
        "engDate": "1927-02-06",
        "nepDate": "24.10.1983"
    },
    {
        "engDate": "1927-02-07",
        "nepDate": "25.10.1983"
    },
    {
        "engDate": "1927-02-08",
        "nepDate": "26.10.1983"
    },
    {
        "engDate": "1927-02-09",
        "nepDate": "27.10.1983"
    },
    {
        "engDate": "1927-02-10",
        "nepDate": "28.10.1983"
    },
    {
        "engDate": "1927-02-11",
        "nepDate": "29.10.1983"
    },
    {
        "engDate": "1927-02-12",
        "nepDate": "01.11.1983"
    },
    {
        "engDate": "1927-02-13",
        "nepDate": "02.11.1983"
    },
    {
        "engDate": "1927-02-14",
        "nepDate": "03.11.1983"
    },
    {
        "engDate": "1927-02-15",
        "nepDate": "04.11.1983"
    },
    {
        "engDate": "1927-02-16",
        "nepDate": "05.11.1983"
    },
    {
        "engDate": "1927-02-17",
        "nepDate": "06.11.1983"
    },
    {
        "engDate": "1927-02-18",
        "nepDate": "07.11.1983"
    },
    {
        "engDate": "1927-02-19",
        "nepDate": "08.11.1983"
    },
    {
        "engDate": "1927-02-20",
        "nepDate": "09.11.1983"
    },
    {
        "engDate": "1927-02-21",
        "nepDate": "10.11.1983"
    },
    {
        "engDate": "1927-02-22",
        "nepDate": "11.11.1983"
    },
    {
        "engDate": "1927-02-23",
        "nepDate": "12.11.1983"
    },
    {
        "engDate": "1927-02-24",
        "nepDate": "13.11.1983"
    },
    {
        "engDate": "1927-02-25",
        "nepDate": "14.11.1983"
    },
    {
        "engDate": "1927-02-26",
        "nepDate": "15.11.1983"
    },
    {
        "engDate": "1927-02-27",
        "nepDate": "16.11.1983"
    },
    {
        "engDate": "1927-02-28",
        "nepDate": "17.11.1983"
    },
    {
        "engDate": "1927-03-01",
        "nepDate": "18.11.1983"
    },
    {
        "engDate": "1927-03-02",
        "nepDate": "19.11.1983"
    },
    {
        "engDate": "1927-03-03",
        "nepDate": "20.11.1983"
    },
    {
        "engDate": "1927-03-04",
        "nepDate": "21.11.1983"
    },
    {
        "engDate": "1927-03-05",
        "nepDate": "22.11.1983"
    },
    {
        "engDate": "1927-03-06",
        "nepDate": "23.11.1983"
    },
    {
        "engDate": "1927-03-07",
        "nepDate": "24.11.1983"
    },
    {
        "engDate": "1927-03-08",
        "nepDate": "25.11.1983"
    },
    {
        "engDate": "1927-03-09",
        "nepDate": "26.11.1983"
    },
    {
        "engDate": "1927-03-10",
        "nepDate": "27.11.1983"
    },
    {
        "engDate": "1927-03-11",
        "nepDate": "28.11.1983"
    },
    {
        "engDate": "1927-03-12",
        "nepDate": "29.11.1983"
    },
    {
        "engDate": "1927-03-13",
        "nepDate": "30.11.1983"
    },
    {
        "engDate": "1927-03-14",
        "nepDate": "01.12.1983"
    },
    {
        "engDate": "1927-03-15",
        "nepDate": "02.12.1983"
    },
    {
        "engDate": "1927-03-16",
        "nepDate": "03.12.1983"
    },
    {
        "engDate": "1927-03-17",
        "nepDate": "04.12.1983"
    },
    {
        "engDate": "1927-03-18",
        "nepDate": "05.12.1983"
    },
    {
        "engDate": "1927-03-19",
        "nepDate": "06.12.1983"
    },
    {
        "engDate": "1927-03-20",
        "nepDate": "07.12.1983"
    },
    {
        "engDate": "1927-03-21",
        "nepDate": "08.12.1983"
    },
    {
        "engDate": "1927-03-22",
        "nepDate": "09.12.1983"
    },
    {
        "engDate": "1927-03-23",
        "nepDate": "10.12.1983"
    },
    {
        "engDate": "1927-03-24",
        "nepDate": "11.12.1983"
    },
    {
        "engDate": "1927-03-25",
        "nepDate": "12.12.1983"
    },
    {
        "engDate": "1927-03-26",
        "nepDate": "13.12.1983"
    },
    {
        "engDate": "1927-03-27",
        "nepDate": "14.12.1983"
    },
    {
        "engDate": "1927-03-28",
        "nepDate": "15.12.1983"
    },
    {
        "engDate": "1927-03-29",
        "nepDate": "16.12.1983"
    },
    {
        "engDate": "1927-03-30",
        "nepDate": "17.12.1983"
    },
    {
        "engDate": "1927-03-31",
        "nepDate": "18.12.1983"
    },
    {
        "engDate": "1927-04-01",
        "nepDate": "19.12.1983"
    },
    {
        "engDate": "1927-04-02",
        "nepDate": "20.12.1983"
    },
    {
        "engDate": "1927-04-03",
        "nepDate": "21.12.1983"
    },
    {
        "engDate": "1927-04-04",
        "nepDate": "22.12.1983"
    },
    {
        "engDate": "1927-04-05",
        "nepDate": "23.12.1983"
    },
    {
        "engDate": "1927-04-06",
        "nepDate": "24.12.1983"
    },
    {
        "engDate": "1927-04-07",
        "nepDate": "25.12.1983"
    },
    {
        "engDate": "1927-04-08",
        "nepDate": "26.12.1983"
    },
    {
        "engDate": "1927-04-09",
        "nepDate": "27.12.1983"
    },
    {
        "engDate": "1927-04-10",
        "nepDate": "28.12.1983"
    },
    {
        "engDate": "1927-04-11",
        "nepDate": "29.12.1983"
    },
    {
        "engDate": "1927-04-12",
        "nepDate": "30.12.1983"
    },
    {
        "engDate": "1927-04-13",
        "nepDate": "01.01.1984"
    },
    {
        "engDate": "1927-04-14",
        "nepDate": "02.01.1984"
    },
    {
        "engDate": "1927-04-15",
        "nepDate": "03.01.1984"
    },
    {
        "engDate": "1927-04-16",
        "nepDate": "04.01.1984"
    },
    {
        "engDate": "1927-04-17",
        "nepDate": "05.01.1984"
    },
    {
        "engDate": "1927-04-18",
        "nepDate": "06.01.1984"
    },
    {
        "engDate": "1927-04-19",
        "nepDate": "07.01.1984"
    },
    {
        "engDate": "1927-04-20",
        "nepDate": "08.01.1984"
    },
    {
        "engDate": "1927-04-21",
        "nepDate": "09.01.1984"
    },
    {
        "engDate": "1927-04-22",
        "nepDate": "10.01.1984"
    },
    {
        "engDate": "1927-04-23",
        "nepDate": "11.01.1984"
    },
    {
        "engDate": "1927-04-24",
        "nepDate": "12.01.1984"
    },
    {
        "engDate": "1927-04-25",
        "nepDate": "13.01.1984"
    },
    {
        "engDate": "1927-04-26",
        "nepDate": "14.01.1984"
    },
    {
        "engDate": "1927-04-27",
        "nepDate": "15.01.1984"
    },
    {
        "engDate": "1927-04-28",
        "nepDate": "16.01.1984"
    },
    {
        "engDate": "1927-04-29",
        "nepDate": "17.01.1984"
    },
    {
        "engDate": "1927-04-30",
        "nepDate": "18.01.1984"
    },
    {
        "engDate": "1927-05-01",
        "nepDate": "19.01.1984"
    },
    {
        "engDate": "1927-05-02",
        "nepDate": "20.01.1984"
    },
    {
        "engDate": "1927-05-03",
        "nepDate": "21.01.1984"
    },
    {
        "engDate": "1927-05-04",
        "nepDate": "22.01.1984"
    },
    {
        "engDate": "1927-05-05",
        "nepDate": "23.01.1984"
    },
    {
        "engDate": "1927-05-06",
        "nepDate": "24.01.1984"
    },
    {
        "engDate": "1927-05-07",
        "nepDate": "25.01.1984"
    },
    {
        "engDate": "1927-05-08",
        "nepDate": "26.01.1984"
    },
    {
        "engDate": "1927-05-09",
        "nepDate": "27.01.1984"
    },
    {
        "engDate": "1927-05-10",
        "nepDate": "28.01.1984"
    },
    {
        "engDate": "1927-05-11",
        "nepDate": "29.01.1984"
    },
    {
        "engDate": "1927-05-12",
        "nepDate": "30.01.1984"
    },
    {
        "engDate": "1927-05-13",
        "nepDate": "31.01.1984"
    },
    {
        "engDate": "1927-05-14",
        "nepDate": "01.02.1984"
    },
    {
        "engDate": "1927-05-15",
        "nepDate": "02.02.1984"
    },
    {
        "engDate": "1927-05-16",
        "nepDate": "03.02.1984"
    },
    {
        "engDate": "1927-05-17",
        "nepDate": "04.02.1984"
    },
    {
        "engDate": "1927-05-18",
        "nepDate": "05.02.1984"
    },
    {
        "engDate": "1927-05-19",
        "nepDate": "06.02.1984"
    },
    {
        "engDate": "1927-05-20",
        "nepDate": "07.02.1984"
    },
    {
        "engDate": "1927-05-21",
        "nepDate": "08.02.1984"
    },
    {
        "engDate": "1927-05-22",
        "nepDate": "09.02.1984"
    },
    {
        "engDate": "1927-05-23",
        "nepDate": "10.02.1984"
    },
    {
        "engDate": "1927-05-24",
        "nepDate": "11.02.1984"
    },
    {
        "engDate": "1927-05-25",
        "nepDate": "12.02.1984"
    },
    {
        "engDate": "1927-05-26",
        "nepDate": "13.02.1984"
    },
    {
        "engDate": "1927-05-27",
        "nepDate": "14.02.1984"
    },
    {
        "engDate": "1927-05-28",
        "nepDate": "15.02.1984"
    },
    {
        "engDate": "1927-05-29",
        "nepDate": "16.02.1984"
    },
    {
        "engDate": "1927-05-30",
        "nepDate": "17.02.1984"
    },
    {
        "engDate": "1927-05-31",
        "nepDate": "18.02.1984"
    },
    {
        "engDate": "1927-06-01",
        "nepDate": "19.02.1984"
    },
    {
        "engDate": "1927-06-02",
        "nepDate": "20.02.1984"
    },
    {
        "engDate": "1927-06-03",
        "nepDate": "21.02.1984"
    },
    {
        "engDate": "1927-06-04",
        "nepDate": "22.02.1984"
    },
    {
        "engDate": "1927-06-05",
        "nepDate": "23.02.1984"
    },
    {
        "engDate": "1927-06-06",
        "nepDate": "24.02.1984"
    },
    {
        "engDate": "1927-06-07",
        "nepDate": "25.02.1984"
    },
    {
        "engDate": "1927-06-08",
        "nepDate": "26.02.1984"
    },
    {
        "engDate": "1927-06-09",
        "nepDate": "27.02.1984"
    },
    {
        "engDate": "1927-06-10",
        "nepDate": "28.02.1984"
    },
    {
        "engDate": "1927-06-11",
        "nepDate": "29.02.1984"
    },
    {
        "engDate": "1927-06-12",
        "nepDate": "30.02.1984"
    },
    {
        "engDate": "1927-06-13",
        "nepDate": "31.02.1984"
    },
    {
        "engDate": "1927-06-14",
        "nepDate": "32.02.1984"
    },
    {
        "engDate": "1927-06-15",
        "nepDate": "01.03.1984"
    },
    {
        "engDate": "1927-06-16",
        "nepDate": "02.03.1984"
    },
    {
        "engDate": "1927-06-17",
        "nepDate": "03.03.1984"
    },
    {
        "engDate": "1927-06-18",
        "nepDate": "04.03.1984"
    },
    {
        "engDate": "1927-06-19",
        "nepDate": "05.03.1984"
    },
    {
        "engDate": "1927-06-20",
        "nepDate": "06.03.1984"
    },
    {
        "engDate": "1927-06-21",
        "nepDate": "07.03.1984"
    },
    {
        "engDate": "1927-06-22",
        "nepDate": "08.03.1984"
    },
    {
        "engDate": "1927-06-23",
        "nepDate": "09.03.1984"
    },
    {
        "engDate": "1927-06-24",
        "nepDate": "10.03.1984"
    },
    {
        "engDate": "1927-06-25",
        "nepDate": "11.03.1984"
    },
    {
        "engDate": "1927-06-26",
        "nepDate": "12.03.1984"
    },
    {
        "engDate": "1927-06-27",
        "nepDate": "13.03.1984"
    },
    {
        "engDate": "1927-06-28",
        "nepDate": "14.03.1984"
    },
    {
        "engDate": "1927-06-29",
        "nepDate": "15.03.1984"
    },
    {
        "engDate": "1927-06-30",
        "nepDate": "16.03.1984"
    },
    {
        "engDate": "1927-07-01",
        "nepDate": "17.03.1984"
    },
    {
        "engDate": "1927-07-02",
        "nepDate": "18.03.1984"
    },
    {
        "engDate": "1927-07-03",
        "nepDate": "19.03.1984"
    },
    {
        "engDate": "1927-07-04",
        "nepDate": "20.03.1984"
    },
    {
        "engDate": "1927-07-05",
        "nepDate": "21.03.1984"
    },
    {
        "engDate": "1927-07-06",
        "nepDate": "22.03.1984"
    },
    {
        "engDate": "1927-07-07",
        "nepDate": "23.03.1984"
    },
    {
        "engDate": "1927-07-08",
        "nepDate": "24.03.1984"
    },
    {
        "engDate": "1927-07-09",
        "nepDate": "25.03.1984"
    },
    {
        "engDate": "1927-07-10",
        "nepDate": "26.03.1984"
    },
    {
        "engDate": "1927-07-11",
        "nepDate": "27.03.1984"
    },
    {
        "engDate": "1927-07-12",
        "nepDate": "28.03.1984"
    },
    {
        "engDate": "1927-07-13",
        "nepDate": "29.03.1984"
    },
    {
        "engDate": "1927-07-14",
        "nepDate": "30.03.1984"
    },
    {
        "engDate": "1927-07-15",
        "nepDate": "31.03.1984"
    },
    {
        "engDate": "1927-07-16",
        "nepDate": "01.04.1984"
    },
    {
        "engDate": "1927-07-17",
        "nepDate": "02.04.1984"
    },
    {
        "engDate": "1927-07-18",
        "nepDate": "03.04.1984"
    },
    {
        "engDate": "1927-07-19",
        "nepDate": "04.04.1984"
    },
    {
        "engDate": "1927-07-20",
        "nepDate": "05.04.1984"
    },
    {
        "engDate": "1927-07-21",
        "nepDate": "06.04.1984"
    },
    {
        "engDate": "1927-07-22",
        "nepDate": "07.04.1984"
    },
    {
        "engDate": "1927-07-23",
        "nepDate": "08.04.1984"
    },
    {
        "engDate": "1927-07-24",
        "nepDate": "09.04.1984"
    },
    {
        "engDate": "1927-07-25",
        "nepDate": "10.04.1984"
    },
    {
        "engDate": "1927-07-26",
        "nepDate": "11.04.1984"
    },
    {
        "engDate": "1927-07-27",
        "nepDate": "12.04.1984"
    },
    {
        "engDate": "1927-07-28",
        "nepDate": "13.04.1984"
    },
    {
        "engDate": "1927-07-29",
        "nepDate": "14.04.1984"
    },
    {
        "engDate": "1927-07-30",
        "nepDate": "15.04.1984"
    },
    {
        "engDate": "1927-07-31",
        "nepDate": "16.04.1984"
    },
    {
        "engDate": "1927-08-01",
        "nepDate": "17.04.1984"
    },
    {
        "engDate": "1927-08-02",
        "nepDate": "18.04.1984"
    },
    {
        "engDate": "1927-08-03",
        "nepDate": "19.04.1984"
    },
    {
        "engDate": "1927-08-04",
        "nepDate": "20.04.1984"
    },
    {
        "engDate": "1927-08-05",
        "nepDate": "21.04.1984"
    },
    {
        "engDate": "1927-08-06",
        "nepDate": "22.04.1984"
    },
    {
        "engDate": "1927-08-07",
        "nepDate": "23.04.1984"
    },
    {
        "engDate": "1927-08-08",
        "nepDate": "24.04.1984"
    },
    {
        "engDate": "1927-08-09",
        "nepDate": "25.04.1984"
    },
    {
        "engDate": "1927-08-10",
        "nepDate": "26.04.1984"
    },
    {
        "engDate": "1927-08-11",
        "nepDate": "27.04.1984"
    },
    {
        "engDate": "1927-08-12",
        "nepDate": "28.04.1984"
    },
    {
        "engDate": "1927-08-13",
        "nepDate": "29.04.1984"
    },
    {
        "engDate": "1927-08-14",
        "nepDate": "30.04.1984"
    },
    {
        "engDate": "1927-08-15",
        "nepDate": "31.04.1984"
    },
    {
        "engDate": "1927-08-16",
        "nepDate": "32.04.1984"
    },
    {
        "engDate": "1927-08-17",
        "nepDate": "01.05.1984"
    },
    {
        "engDate": "1927-08-18",
        "nepDate": "02.05.1984"
    },
    {
        "engDate": "1927-08-19",
        "nepDate": "03.05.1984"
    },
    {
        "engDate": "1927-08-20",
        "nepDate": "04.05.1984"
    },
    {
        "engDate": "1927-08-21",
        "nepDate": "05.05.1984"
    },
    {
        "engDate": "1927-08-22",
        "nepDate": "06.05.1984"
    },
    {
        "engDate": "1927-08-23",
        "nepDate": "07.05.1984"
    },
    {
        "engDate": "1927-08-24",
        "nepDate": "08.05.1984"
    },
    {
        "engDate": "1927-08-25",
        "nepDate": "09.05.1984"
    },
    {
        "engDate": "1927-08-26",
        "nepDate": "10.05.1984"
    },
    {
        "engDate": "1927-08-27",
        "nepDate": "11.05.1984"
    },
    {
        "engDate": "1927-08-28",
        "nepDate": "12.05.1984"
    },
    {
        "engDate": "1927-08-29",
        "nepDate": "13.05.1984"
    },
    {
        "engDate": "1927-08-30",
        "nepDate": "14.05.1984"
    },
    {
        "engDate": "1927-08-31",
        "nepDate": "15.05.1984"
    },
    {
        "engDate": "1927-09-01",
        "nepDate": "16.05.1984"
    },
    {
        "engDate": "1927-09-02",
        "nepDate": "17.05.1984"
    },
    {
        "engDate": "1927-09-03",
        "nepDate": "18.05.1984"
    },
    {
        "engDate": "1927-09-04",
        "nepDate": "19.05.1984"
    },
    {
        "engDate": "1927-09-05",
        "nepDate": "20.05.1984"
    },
    {
        "engDate": "1927-09-06",
        "nepDate": "21.05.1984"
    },
    {
        "engDate": "1927-09-07",
        "nepDate": "22.05.1984"
    },
    {
        "engDate": "1927-09-08",
        "nepDate": "23.05.1984"
    },
    {
        "engDate": "1927-09-09",
        "nepDate": "24.05.1984"
    },
    {
        "engDate": "1927-09-10",
        "nepDate": "25.05.1984"
    },
    {
        "engDate": "1927-09-11",
        "nepDate": "26.05.1984"
    },
    {
        "engDate": "1927-09-12",
        "nepDate": "27.05.1984"
    },
    {
        "engDate": "1927-09-13",
        "nepDate": "28.05.1984"
    },
    {
        "engDate": "1927-09-14",
        "nepDate": "29.05.1984"
    },
    {
        "engDate": "1927-09-15",
        "nepDate": "30.05.1984"
    },
    {
        "engDate": "1927-09-16",
        "nepDate": "31.05.1984"
    },
    {
        "engDate": "1927-09-17",
        "nepDate": "01.06.1984"
    },
    {
        "engDate": "1927-09-18",
        "nepDate": "02.06.1984"
    },
    {
        "engDate": "1927-09-19",
        "nepDate": "03.06.1984"
    },
    {
        "engDate": "1927-09-20",
        "nepDate": "04.06.1984"
    },
    {
        "engDate": "1927-09-21",
        "nepDate": "05.06.1984"
    },
    {
        "engDate": "1927-09-22",
        "nepDate": "06.06.1984"
    },
    {
        "engDate": "1927-09-23",
        "nepDate": "07.06.1984"
    },
    {
        "engDate": "1927-09-24",
        "nepDate": "08.06.1984"
    },
    {
        "engDate": "1927-09-25",
        "nepDate": "09.06.1984"
    },
    {
        "engDate": "1927-09-26",
        "nepDate": "10.06.1984"
    },
    {
        "engDate": "1927-09-27",
        "nepDate": "11.06.1984"
    },
    {
        "engDate": "1927-09-28",
        "nepDate": "12.06.1984"
    },
    {
        "engDate": "1927-09-29",
        "nepDate": "13.06.1984"
    },
    {
        "engDate": "1927-09-30",
        "nepDate": "14.06.1984"
    },
    {
        "engDate": "1927-10-01",
        "nepDate": "15.06.1984"
    },
    {
        "engDate": "1927-10-02",
        "nepDate": "16.06.1984"
    },
    {
        "engDate": "1927-10-03",
        "nepDate": "17.06.1984"
    },
    {
        "engDate": "1927-10-04",
        "nepDate": "18.06.1984"
    },
    {
        "engDate": "1927-10-05",
        "nepDate": "19.06.1984"
    },
    {
        "engDate": "1927-10-06",
        "nepDate": "20.06.1984"
    },
    {
        "engDate": "1927-10-07",
        "nepDate": "21.06.1984"
    },
    {
        "engDate": "1927-10-08",
        "nepDate": "22.06.1984"
    },
    {
        "engDate": "1927-10-09",
        "nepDate": "23.06.1984"
    },
    {
        "engDate": "1927-10-10",
        "nepDate": "24.06.1984"
    },
    {
        "engDate": "1927-10-11",
        "nepDate": "25.06.1984"
    },
    {
        "engDate": "1927-10-12",
        "nepDate": "26.06.1984"
    },
    {
        "engDate": "1927-10-13",
        "nepDate": "27.06.1984"
    },
    {
        "engDate": "1927-10-14",
        "nepDate": "28.06.1984"
    },
    {
        "engDate": "1927-10-15",
        "nepDate": "29.06.1984"
    },
    {
        "engDate": "1927-10-16",
        "nepDate": "30.06.1984"
    },
    {
        "engDate": "1927-10-17",
        "nepDate": "01.07.1984"
    },
    {
        "engDate": "1927-10-18",
        "nepDate": "02.07.1984"
    },
    {
        "engDate": "1927-10-19",
        "nepDate": "03.07.1984"
    },
    {
        "engDate": "1927-10-20",
        "nepDate": "04.07.1984"
    },
    {
        "engDate": "1927-10-21",
        "nepDate": "05.07.1984"
    },
    {
        "engDate": "1927-10-22",
        "nepDate": "06.07.1984"
    },
    {
        "engDate": "1927-10-23",
        "nepDate": "07.07.1984"
    },
    {
        "engDate": "1927-10-24",
        "nepDate": "08.07.1984"
    },
    {
        "engDate": "1927-10-25",
        "nepDate": "09.07.1984"
    },
    {
        "engDate": "1927-10-26",
        "nepDate": "10.07.1984"
    },
    {
        "engDate": "1927-10-27",
        "nepDate": "11.07.1984"
    },
    {
        "engDate": "1927-10-28",
        "nepDate": "12.07.1984"
    },
    {
        "engDate": "1927-10-29",
        "nepDate": "13.07.1984"
    },
    {
        "engDate": "1927-10-30",
        "nepDate": "14.07.1984"
    },
    {
        "engDate": "1927-10-31",
        "nepDate": "15.07.1984"
    },
    {
        "engDate": "1927-11-01",
        "nepDate": "16.07.1984"
    },
    {
        "engDate": "1927-11-02",
        "nepDate": "17.07.1984"
    },
    {
        "engDate": "1927-11-03",
        "nepDate": "18.07.1984"
    },
    {
        "engDate": "1927-11-04",
        "nepDate": "19.07.1984"
    },
    {
        "engDate": "1927-11-05",
        "nepDate": "20.07.1984"
    },
    {
        "engDate": "1927-11-06",
        "nepDate": "21.07.1984"
    },
    {
        "engDate": "1927-11-07",
        "nepDate": "22.07.1984"
    },
    {
        "engDate": "1927-11-08",
        "nepDate": "23.07.1984"
    },
    {
        "engDate": "1927-11-09",
        "nepDate": "24.07.1984"
    },
    {
        "engDate": "1927-11-10",
        "nepDate": "25.07.1984"
    },
    {
        "engDate": "1927-11-11",
        "nepDate": "26.07.1984"
    },
    {
        "engDate": "1927-11-12",
        "nepDate": "27.07.1984"
    },
    {
        "engDate": "1927-11-13",
        "nepDate": "28.07.1984"
    },
    {
        "engDate": "1927-11-14",
        "nepDate": "29.07.1984"
    },
    {
        "engDate": "1927-11-15",
        "nepDate": "30.07.1984"
    },
    {
        "engDate": "1927-11-16",
        "nepDate": "01.08.1984"
    },
    {
        "engDate": "1927-11-17",
        "nepDate": "02.08.1984"
    },
    {
        "engDate": "1927-11-18",
        "nepDate": "03.08.1984"
    },
    {
        "engDate": "1927-11-19",
        "nepDate": "04.08.1984"
    },
    {
        "engDate": "1927-11-20",
        "nepDate": "05.08.1984"
    },
    {
        "engDate": "1927-11-21",
        "nepDate": "06.08.1984"
    },
    {
        "engDate": "1927-11-22",
        "nepDate": "07.08.1984"
    },
    {
        "engDate": "1927-11-23",
        "nepDate": "08.08.1984"
    },
    {
        "engDate": "1927-11-24",
        "nepDate": "09.08.1984"
    },
    {
        "engDate": "1927-11-25",
        "nepDate": "10.08.1984"
    },
    {
        "engDate": "1927-11-26",
        "nepDate": "11.08.1984"
    },
    {
        "engDate": "1927-11-27",
        "nepDate": "12.08.1984"
    },
    {
        "engDate": "1927-11-28",
        "nepDate": "13.08.1984"
    },
    {
        "engDate": "1927-11-29",
        "nepDate": "14.08.1984"
    },
    {
        "engDate": "1927-11-30",
        "nepDate": "15.08.1984"
    },
    {
        "engDate": "1927-12-01",
        "nepDate": "16.08.1984"
    },
    {
        "engDate": "1927-12-02",
        "nepDate": "17.08.1984"
    },
    {
        "engDate": "1927-12-03",
        "nepDate": "18.08.1984"
    },
    {
        "engDate": "1927-12-04",
        "nepDate": "19.08.1984"
    },
    {
        "engDate": "1927-12-05",
        "nepDate": "20.08.1984"
    },
    {
        "engDate": "1927-12-06",
        "nepDate": "21.08.1984"
    },
    {
        "engDate": "1927-12-07",
        "nepDate": "22.08.1984"
    },
    {
        "engDate": "1927-12-08",
        "nepDate": "23.08.1984"
    },
    {
        "engDate": "1927-12-09",
        "nepDate": "24.08.1984"
    },
    {
        "engDate": "1927-12-10",
        "nepDate": "25.08.1984"
    },
    {
        "engDate": "1927-12-11",
        "nepDate": "26.08.1984"
    },
    {
        "engDate": "1927-12-12",
        "nepDate": "27.08.1984"
    },
    {
        "engDate": "1927-12-13",
        "nepDate": "28.08.1984"
    },
    {
        "engDate": "1927-12-14",
        "nepDate": "29.08.1984"
    },
    {
        "engDate": "1927-12-15",
        "nepDate": "30.08.1984"
    },
    {
        "engDate": "1927-12-16",
        "nepDate": "01.09.1984"
    },
    {
        "engDate": "1927-12-17",
        "nepDate": "02.09.1984"
    },
    {
        "engDate": "1927-12-18",
        "nepDate": "03.09.1984"
    },
    {
        "engDate": "1927-12-19",
        "nepDate": "04.09.1984"
    },
    {
        "engDate": "1927-12-20",
        "nepDate": "05.09.1984"
    },
    {
        "engDate": "1927-12-21",
        "nepDate": "06.09.1984"
    },
    {
        "engDate": "1927-12-22",
        "nepDate": "07.09.1984"
    },
    {
        "engDate": "1927-12-23",
        "nepDate": "08.09.1984"
    },
    {
        "engDate": "1927-12-24",
        "nepDate": "09.09.1984"
    },
    {
        "engDate": "1927-12-25",
        "nepDate": "10.09.1984"
    },
    {
        "engDate": "1927-12-26",
        "nepDate": "11.09.1984"
    },
    {
        "engDate": "1927-12-27",
        "nepDate": "12.09.1984"
    },
    {
        "engDate": "1927-12-28",
        "nepDate": "13.09.1984"
    },
    {
        "engDate": "1927-12-29",
        "nepDate": "14.09.1984"
    },
    {
        "engDate": "1927-12-30",
        "nepDate": "15.09.1984"
    },
    {
        "engDate": "1927-12-31",
        "nepDate": "16.09.1984"
    },
    {
        "engDate": "1928-01-01",
        "nepDate": "17.09.1984"
    },
    {
        "engDate": "1928-01-02",
        "nepDate": "18.09.1984"
    },
    {
        "engDate": "1928-01-03",
        "nepDate": "19.09.1984"
    },
    {
        "engDate": "1928-01-04",
        "nepDate": "20.09.1984"
    },
    {
        "engDate": "1928-01-05",
        "nepDate": "21.09.1984"
    },
    {
        "engDate": "1928-01-06",
        "nepDate": "22.09.1984"
    },
    {
        "engDate": "1928-01-07",
        "nepDate": "23.09.1984"
    },
    {
        "engDate": "1928-01-08",
        "nepDate": "24.09.1984"
    },
    {
        "engDate": "1928-01-09",
        "nepDate": "25.09.1984"
    },
    {
        "engDate": "1928-01-10",
        "nepDate": "26.09.1984"
    },
    {
        "engDate": "1928-01-11",
        "nepDate": "27.09.1984"
    },
    {
        "engDate": "1928-01-12",
        "nepDate": "28.09.1984"
    },
    {
        "engDate": "1928-01-13",
        "nepDate": "29.09.1984"
    },
    {
        "engDate": "1928-01-14",
        "nepDate": "01.10.1984"
    },
    {
        "engDate": "1928-01-15",
        "nepDate": "02.10.1984"
    },
    {
        "engDate": "1928-01-16",
        "nepDate": "03.10.1984"
    },
    {
        "engDate": "1928-01-17",
        "nepDate": "04.10.1984"
    },
    {
        "engDate": "1928-01-18",
        "nepDate": "05.10.1984"
    },
    {
        "engDate": "1928-01-19",
        "nepDate": "06.10.1984"
    },
    {
        "engDate": "1928-01-20",
        "nepDate": "07.10.1984"
    },
    {
        "engDate": "1928-01-21",
        "nepDate": "08.10.1984"
    },
    {
        "engDate": "1928-01-22",
        "nepDate": "09.10.1984"
    },
    {
        "engDate": "1928-01-23",
        "nepDate": "10.10.1984"
    },
    {
        "engDate": "1928-01-24",
        "nepDate": "11.10.1984"
    },
    {
        "engDate": "1928-01-25",
        "nepDate": "12.10.1984"
    },
    {
        "engDate": "1928-01-26",
        "nepDate": "13.10.1984"
    },
    {
        "engDate": "1928-01-27",
        "nepDate": "14.10.1984"
    },
    {
        "engDate": "1928-01-28",
        "nepDate": "15.10.1984"
    },
    {
        "engDate": "1928-01-29",
        "nepDate": "16.10.1984"
    },
    {
        "engDate": "1928-01-30",
        "nepDate": "17.10.1984"
    },
    {
        "engDate": "1928-01-31",
        "nepDate": "18.10.1984"
    },
    {
        "engDate": "1928-02-01",
        "nepDate": "19.10.1984"
    },
    {
        "engDate": "1928-02-02",
        "nepDate": "20.10.1984"
    },
    {
        "engDate": "1928-02-03",
        "nepDate": "21.10.1984"
    },
    {
        "engDate": "1928-02-04",
        "nepDate": "22.10.1984"
    },
    {
        "engDate": "1928-02-05",
        "nepDate": "23.10.1984"
    },
    {
        "engDate": "1928-02-06",
        "nepDate": "24.10.1984"
    },
    {
        "engDate": "1928-02-07",
        "nepDate": "25.10.1984"
    },
    {
        "engDate": "1928-02-08",
        "nepDate": "26.10.1984"
    },
    {
        "engDate": "1928-02-09",
        "nepDate": "27.10.1984"
    },
    {
        "engDate": "1928-02-10",
        "nepDate": "28.10.1984"
    },
    {
        "engDate": "1928-02-11",
        "nepDate": "29.10.1984"
    },
    {
        "engDate": "1928-02-12",
        "nepDate": "01.11.1984"
    },
    {
        "engDate": "1928-02-13",
        "nepDate": "02.11.1984"
    },
    {
        "engDate": "1928-02-14",
        "nepDate": "03.11.1984"
    },
    {
        "engDate": "1928-02-15",
        "nepDate": "04.11.1984"
    },
    {
        "engDate": "1928-02-16",
        "nepDate": "05.11.1984"
    },
    {
        "engDate": "1928-02-17",
        "nepDate": "06.11.1984"
    },
    {
        "engDate": "1928-02-18",
        "nepDate": "07.11.1984"
    },
    {
        "engDate": "1928-02-19",
        "nepDate": "08.11.1984"
    },
    {
        "engDate": "1928-02-20",
        "nepDate": "09.11.1984"
    },
    {
        "engDate": "1928-02-21",
        "nepDate": "10.11.1984"
    },
    {
        "engDate": "1928-02-22",
        "nepDate": "11.11.1984"
    },
    {
        "engDate": "1928-02-23",
        "nepDate": "12.11.1984"
    },
    {
        "engDate": "1928-02-24",
        "nepDate": "13.11.1984"
    },
    {
        "engDate": "1928-02-25",
        "nepDate": "14.11.1984"
    },
    {
        "engDate": "1928-02-26",
        "nepDate": "15.11.1984"
    },
    {
        "engDate": "1928-02-27",
        "nepDate": "16.11.1984"
    },
    {
        "engDate": "1928-02-28",
        "nepDate": "17.11.1984"
    },
    {
        "engDate": "1928-02-29",
        "nepDate": "18.11.1984"
    },
    {
        "engDate": "1928-03-01",
        "nepDate": "19.11.1984"
    },
    {
        "engDate": "1928-03-02",
        "nepDate": "20.11.1984"
    },
    {
        "engDate": "1928-03-03",
        "nepDate": "21.11.1984"
    },
    {
        "engDate": "1928-03-04",
        "nepDate": "22.11.1984"
    },
    {
        "engDate": "1928-03-05",
        "nepDate": "23.11.1984"
    },
    {
        "engDate": "1928-03-06",
        "nepDate": "24.11.1984"
    },
    {
        "engDate": "1928-03-07",
        "nepDate": "25.11.1984"
    },
    {
        "engDate": "1928-03-08",
        "nepDate": "26.11.1984"
    },
    {
        "engDate": "1928-03-09",
        "nepDate": "27.11.1984"
    },
    {
        "engDate": "1928-03-10",
        "nepDate": "28.11.1984"
    },
    {
        "engDate": "1928-03-11",
        "nepDate": "29.11.1984"
    },
    {
        "engDate": "1928-03-12",
        "nepDate": "30.11.1984"
    },
    {
        "engDate": "1928-03-13",
        "nepDate": "01.12.1984"
    },
    {
        "engDate": "1928-03-14",
        "nepDate": "02.12.1984"
    },
    {
        "engDate": "1928-03-15",
        "nepDate": "03.12.1984"
    },
    {
        "engDate": "1928-03-16",
        "nepDate": "04.12.1984"
    },
    {
        "engDate": "1928-03-17",
        "nepDate": "05.12.1984"
    },
    {
        "engDate": "1928-03-18",
        "nepDate": "06.12.1984"
    },
    {
        "engDate": "1928-03-19",
        "nepDate": "07.12.1984"
    },
    {
        "engDate": "1928-03-20",
        "nepDate": "08.12.1984"
    },
    {
        "engDate": "1928-03-21",
        "nepDate": "09.12.1984"
    },
    {
        "engDate": "1928-03-22",
        "nepDate": "10.12.1984"
    },
    {
        "engDate": "1928-03-23",
        "nepDate": "11.12.1984"
    },
    {
        "engDate": "1928-03-24",
        "nepDate": "12.12.1984"
    },
    {
        "engDate": "1928-03-25",
        "nepDate": "13.12.1984"
    },
    {
        "engDate": "1928-03-26",
        "nepDate": "14.12.1984"
    },
    {
        "engDate": "1928-03-27",
        "nepDate": "15.12.1984"
    },
    {
        "engDate": "1928-03-28",
        "nepDate": "16.12.1984"
    },
    {
        "engDate": "1928-03-29",
        "nepDate": "17.12.1984"
    },
    {
        "engDate": "1928-03-30",
        "nepDate": "18.12.1984"
    },
    {
        "engDate": "1928-03-31",
        "nepDate": "19.12.1984"
    },
    {
        "engDate": "1928-04-01",
        "nepDate": "20.12.1984"
    },
    {
        "engDate": "1928-04-02",
        "nepDate": "21.12.1984"
    },
    {
        "engDate": "1928-04-03",
        "nepDate": "22.12.1984"
    },
    {
        "engDate": "1928-04-04",
        "nepDate": "23.12.1984"
    },
    {
        "engDate": "1928-04-05",
        "nepDate": "24.12.1984"
    },
    {
        "engDate": "1928-04-06",
        "nepDate": "25.12.1984"
    },
    {
        "engDate": "1928-04-07",
        "nepDate": "26.12.1984"
    },
    {
        "engDate": "1928-04-08",
        "nepDate": "27.12.1984"
    },
    {
        "engDate": "1928-04-09",
        "nepDate": "28.12.1984"
    },
    {
        "engDate": "1928-04-10",
        "nepDate": "29.12.1984"
    },
    {
        "engDate": "1928-04-11",
        "nepDate": "30.12.1984"
    },
    {
        "engDate": "1928-04-12",
        "nepDate": "31.12.1984"
    },
    {
        "engDate": "1928-04-13",
        "nepDate": "01.01.1985"
    },
    {
        "engDate": "1928-04-14",
        "nepDate": "02.01.1985"
    },
    {
        "engDate": "1928-04-15",
        "nepDate": "03.01.1985"
    },
    {
        "engDate": "1928-04-16",
        "nepDate": "04.01.1985"
    },
    {
        "engDate": "1928-04-17",
        "nepDate": "05.01.1985"
    },
    {
        "engDate": "1928-04-18",
        "nepDate": "06.01.1985"
    },
    {
        "engDate": "1928-04-19",
        "nepDate": "07.01.1985"
    },
    {
        "engDate": "1928-04-20",
        "nepDate": "08.01.1985"
    },
    {
        "engDate": "1928-04-21",
        "nepDate": "09.01.1985"
    },
    {
        "engDate": "1928-04-22",
        "nepDate": "10.01.1985"
    },
    {
        "engDate": "1928-04-23",
        "nepDate": "11.01.1985"
    },
    {
        "engDate": "1928-04-24",
        "nepDate": "12.01.1985"
    },
    {
        "engDate": "1928-04-25",
        "nepDate": "13.01.1985"
    },
    {
        "engDate": "1928-04-26",
        "nepDate": "14.01.1985"
    },
    {
        "engDate": "1928-04-27",
        "nepDate": "15.01.1985"
    },
    {
        "engDate": "1928-04-28",
        "nepDate": "16.01.1985"
    },
    {
        "engDate": "1928-04-29",
        "nepDate": "17.01.1985"
    },
    {
        "engDate": "1928-04-30",
        "nepDate": "18.01.1985"
    },
    {
        "engDate": "1928-05-01",
        "nepDate": "19.01.1985"
    },
    {
        "engDate": "1928-05-02",
        "nepDate": "20.01.1985"
    },
    {
        "engDate": "1928-05-03",
        "nepDate": "21.01.1985"
    },
    {
        "engDate": "1928-05-04",
        "nepDate": "22.01.1985"
    },
    {
        "engDate": "1928-05-05",
        "nepDate": "23.01.1985"
    },
    {
        "engDate": "1928-05-06",
        "nepDate": "24.01.1985"
    },
    {
        "engDate": "1928-05-07",
        "nepDate": "25.01.1985"
    },
    {
        "engDate": "1928-05-08",
        "nepDate": "26.01.1985"
    },
    {
        "engDate": "1928-05-09",
        "nepDate": "27.01.1985"
    },
    {
        "engDate": "1928-05-10",
        "nepDate": "28.01.1985"
    },
    {
        "engDate": "1928-05-11",
        "nepDate": "29.01.1985"
    },
    {
        "engDate": "1928-05-12",
        "nepDate": "30.01.1985"
    },
    {
        "engDate": "1928-05-13",
        "nepDate": "31.01.1985"
    },
    {
        "engDate": "1928-05-14",
        "nepDate": "01.02.1985"
    },
    {
        "engDate": "1928-05-15",
        "nepDate": "02.02.1985"
    },
    {
        "engDate": "1928-05-16",
        "nepDate": "03.02.1985"
    },
    {
        "engDate": "1928-05-17",
        "nepDate": "04.02.1985"
    },
    {
        "engDate": "1928-05-18",
        "nepDate": "05.02.1985"
    },
    {
        "engDate": "1928-05-19",
        "nepDate": "06.02.1985"
    },
    {
        "engDate": "1928-05-20",
        "nepDate": "07.02.1985"
    },
    {
        "engDate": "1928-05-21",
        "nepDate": "08.02.1985"
    },
    {
        "engDate": "1928-05-22",
        "nepDate": "09.02.1985"
    },
    {
        "engDate": "1928-05-23",
        "nepDate": "10.02.1985"
    },
    {
        "engDate": "1928-05-24",
        "nepDate": "11.02.1985"
    },
    {
        "engDate": "1928-05-25",
        "nepDate": "12.02.1985"
    },
    {
        "engDate": "1928-05-26",
        "nepDate": "13.02.1985"
    },
    {
        "engDate": "1928-05-27",
        "nepDate": "14.02.1985"
    },
    {
        "engDate": "1928-05-28",
        "nepDate": "15.02.1985"
    },
    {
        "engDate": "1928-05-29",
        "nepDate": "16.02.1985"
    },
    {
        "engDate": "1928-05-30",
        "nepDate": "17.02.1985"
    },
    {
        "engDate": "1928-05-31",
        "nepDate": "18.02.1985"
    },
    {
        "engDate": "1928-06-01",
        "nepDate": "19.02.1985"
    },
    {
        "engDate": "1928-06-02",
        "nepDate": "20.02.1985"
    },
    {
        "engDate": "1928-06-03",
        "nepDate": "21.02.1985"
    },
    {
        "engDate": "1928-06-04",
        "nepDate": "22.02.1985"
    },
    {
        "engDate": "1928-06-05",
        "nepDate": "23.02.1985"
    },
    {
        "engDate": "1928-06-06",
        "nepDate": "24.02.1985"
    },
    {
        "engDate": "1928-06-07",
        "nepDate": "25.02.1985"
    },
    {
        "engDate": "1928-06-08",
        "nepDate": "26.02.1985"
    },
    {
        "engDate": "1928-06-09",
        "nepDate": "27.02.1985"
    },
    {
        "engDate": "1928-06-10",
        "nepDate": "28.02.1985"
    },
    {
        "engDate": "1928-06-11",
        "nepDate": "29.02.1985"
    },
    {
        "engDate": "1928-06-12",
        "nepDate": "30.02.1985"
    },
    {
        "engDate": "1928-06-13",
        "nepDate": "31.02.1985"
    },
    {
        "engDate": "1928-06-14",
        "nepDate": "01.03.1985"
    },
    {
        "engDate": "1928-06-15",
        "nepDate": "02.03.1985"
    },
    {
        "engDate": "1928-06-16",
        "nepDate": "03.03.1985"
    },
    {
        "engDate": "1928-06-17",
        "nepDate": "04.03.1985"
    },
    {
        "engDate": "1928-06-18",
        "nepDate": "05.03.1985"
    },
    {
        "engDate": "1928-06-19",
        "nepDate": "06.03.1985"
    },
    {
        "engDate": "1928-06-20",
        "nepDate": "07.03.1985"
    },
    {
        "engDate": "1928-06-21",
        "nepDate": "08.03.1985"
    },
    {
        "engDate": "1928-06-22",
        "nepDate": "09.03.1985"
    },
    {
        "engDate": "1928-06-23",
        "nepDate": "10.03.1985"
    },
    {
        "engDate": "1928-06-24",
        "nepDate": "11.03.1985"
    },
    {
        "engDate": "1928-06-25",
        "nepDate": "12.03.1985"
    },
    {
        "engDate": "1928-06-26",
        "nepDate": "13.03.1985"
    },
    {
        "engDate": "1928-06-27",
        "nepDate": "14.03.1985"
    },
    {
        "engDate": "1928-06-28",
        "nepDate": "15.03.1985"
    },
    {
        "engDate": "1928-06-29",
        "nepDate": "16.03.1985"
    },
    {
        "engDate": "1928-06-30",
        "nepDate": "17.03.1985"
    },
    {
        "engDate": "1928-07-01",
        "nepDate": "18.03.1985"
    },
    {
        "engDate": "1928-07-02",
        "nepDate": "19.03.1985"
    },
    {
        "engDate": "1928-07-03",
        "nepDate": "20.03.1985"
    },
    {
        "engDate": "1928-07-04",
        "nepDate": "21.03.1985"
    },
    {
        "engDate": "1928-07-05",
        "nepDate": "22.03.1985"
    },
    {
        "engDate": "1928-07-06",
        "nepDate": "23.03.1985"
    },
    {
        "engDate": "1928-07-07",
        "nepDate": "24.03.1985"
    },
    {
        "engDate": "1928-07-08",
        "nepDate": "25.03.1985"
    },
    {
        "engDate": "1928-07-09",
        "nepDate": "26.03.1985"
    },
    {
        "engDate": "1928-07-10",
        "nepDate": "27.03.1985"
    },
    {
        "engDate": "1928-07-11",
        "nepDate": "28.03.1985"
    },
    {
        "engDate": "1928-07-12",
        "nepDate": "29.03.1985"
    },
    {
        "engDate": "1928-07-13",
        "nepDate": "30.03.1985"
    },
    {
        "engDate": "1928-07-14",
        "nepDate": "31.03.1985"
    },
    {
        "engDate": "1928-07-15",
        "nepDate": "01.04.1985"
    },
    {
        "engDate": "1928-07-16",
        "nepDate": "02.04.1985"
    },
    {
        "engDate": "1928-07-17",
        "nepDate": "03.04.1985"
    },
    {
        "engDate": "1928-07-18",
        "nepDate": "04.04.1985"
    },
    {
        "engDate": "1928-07-19",
        "nepDate": "05.04.1985"
    },
    {
        "engDate": "1928-07-20",
        "nepDate": "06.04.1985"
    },
    {
        "engDate": "1928-07-21",
        "nepDate": "07.04.1985"
    },
    {
        "engDate": "1928-07-22",
        "nepDate": "08.04.1985"
    },
    {
        "engDate": "1928-07-23",
        "nepDate": "09.04.1985"
    },
    {
        "engDate": "1928-07-24",
        "nepDate": "10.04.1985"
    },
    {
        "engDate": "1928-07-25",
        "nepDate": "11.04.1985"
    },
    {
        "engDate": "1928-07-26",
        "nepDate": "12.04.1985"
    },
    {
        "engDate": "1928-07-27",
        "nepDate": "13.04.1985"
    },
    {
        "engDate": "1928-07-28",
        "nepDate": "14.04.1985"
    },
    {
        "engDate": "1928-07-29",
        "nepDate": "15.04.1985"
    },
    {
        "engDate": "1928-07-30",
        "nepDate": "16.04.1985"
    },
    {
        "engDate": "1928-07-31",
        "nepDate": "17.04.1985"
    },
    {
        "engDate": "1928-08-01",
        "nepDate": "18.04.1985"
    },
    {
        "engDate": "1928-08-02",
        "nepDate": "19.04.1985"
    },
    {
        "engDate": "1928-08-03",
        "nepDate": "20.04.1985"
    },
    {
        "engDate": "1928-08-04",
        "nepDate": "21.04.1985"
    },
    {
        "engDate": "1928-08-05",
        "nepDate": "22.04.1985"
    },
    {
        "engDate": "1928-08-06",
        "nepDate": "23.04.1985"
    },
    {
        "engDate": "1928-08-07",
        "nepDate": "24.04.1985"
    },
    {
        "engDate": "1928-08-08",
        "nepDate": "25.04.1985"
    },
    {
        "engDate": "1928-08-09",
        "nepDate": "26.04.1985"
    },
    {
        "engDate": "1928-08-10",
        "nepDate": "27.04.1985"
    },
    {
        "engDate": "1928-08-11",
        "nepDate": "28.04.1985"
    },
    {
        "engDate": "1928-08-12",
        "nepDate": "29.04.1985"
    },
    {
        "engDate": "1928-08-13",
        "nepDate": "30.04.1985"
    },
    {
        "engDate": "1928-08-14",
        "nepDate": "31.04.1985"
    },
    {
        "engDate": "1928-08-15",
        "nepDate": "32.04.1985"
    },
    {
        "engDate": "1928-08-16",
        "nepDate": "01.05.1985"
    },
    {
        "engDate": "1928-08-17",
        "nepDate": "02.05.1985"
    },
    {
        "engDate": "1928-08-18",
        "nepDate": "03.05.1985"
    },
    {
        "engDate": "1928-08-19",
        "nepDate": "04.05.1985"
    },
    {
        "engDate": "1928-08-20",
        "nepDate": "05.05.1985"
    },
    {
        "engDate": "1928-08-21",
        "nepDate": "06.05.1985"
    },
    {
        "engDate": "1928-08-22",
        "nepDate": "07.05.1985"
    },
    {
        "engDate": "1928-08-23",
        "nepDate": "08.05.1985"
    },
    {
        "engDate": "1928-08-24",
        "nepDate": "09.05.1985"
    },
    {
        "engDate": "1928-08-25",
        "nepDate": "10.05.1985"
    },
    {
        "engDate": "1928-08-26",
        "nepDate": "11.05.1985"
    },
    {
        "engDate": "1928-08-27",
        "nepDate": "12.05.1985"
    },
    {
        "engDate": "1928-08-28",
        "nepDate": "13.05.1985"
    },
    {
        "engDate": "1928-08-29",
        "nepDate": "14.05.1985"
    },
    {
        "engDate": "1928-08-30",
        "nepDate": "15.05.1985"
    },
    {
        "engDate": "1928-08-31",
        "nepDate": "16.05.1985"
    },
    {
        "engDate": "1928-09-01",
        "nepDate": "17.05.1985"
    },
    {
        "engDate": "1928-09-02",
        "nepDate": "18.05.1985"
    },
    {
        "engDate": "1928-09-03",
        "nepDate": "19.05.1985"
    },
    {
        "engDate": "1928-09-04",
        "nepDate": "20.05.1985"
    },
    {
        "engDate": "1928-09-05",
        "nepDate": "21.05.1985"
    },
    {
        "engDate": "1928-09-06",
        "nepDate": "22.05.1985"
    },
    {
        "engDate": "1928-09-07",
        "nepDate": "23.05.1985"
    },
    {
        "engDate": "1928-09-08",
        "nepDate": "24.05.1985"
    },
    {
        "engDate": "1928-09-09",
        "nepDate": "25.05.1985"
    },
    {
        "engDate": "1928-09-10",
        "nepDate": "26.05.1985"
    },
    {
        "engDate": "1928-09-11",
        "nepDate": "27.05.1985"
    },
    {
        "engDate": "1928-09-12",
        "nepDate": "28.05.1985"
    },
    {
        "engDate": "1928-09-13",
        "nepDate": "29.05.1985"
    },
    {
        "engDate": "1928-09-14",
        "nepDate": "30.05.1985"
    },
    {
        "engDate": "1928-09-15",
        "nepDate": "31.05.1985"
    },
    {
        "engDate": "1928-09-16",
        "nepDate": "01.06.1985"
    },
    {
        "engDate": "1928-09-17",
        "nepDate": "02.06.1985"
    },
    {
        "engDate": "1928-09-18",
        "nepDate": "03.06.1985"
    },
    {
        "engDate": "1928-09-19",
        "nepDate": "04.06.1985"
    },
    {
        "engDate": "1928-09-20",
        "nepDate": "05.06.1985"
    },
    {
        "engDate": "1928-09-21",
        "nepDate": "06.06.1985"
    },
    {
        "engDate": "1928-09-22",
        "nepDate": "07.06.1985"
    },
    {
        "engDate": "1928-09-23",
        "nepDate": "08.06.1985"
    },
    {
        "engDate": "1928-09-24",
        "nepDate": "09.06.1985"
    },
    {
        "engDate": "1928-09-25",
        "nepDate": "10.06.1985"
    },
    {
        "engDate": "1928-09-26",
        "nepDate": "11.06.1985"
    },
    {
        "engDate": "1928-09-27",
        "nepDate": "12.06.1985"
    },
    {
        "engDate": "1928-09-28",
        "nepDate": "13.06.1985"
    },
    {
        "engDate": "1928-09-29",
        "nepDate": "14.06.1985"
    },
    {
        "engDate": "1928-09-30",
        "nepDate": "15.06.1985"
    },
    {
        "engDate": "1928-10-01",
        "nepDate": "16.06.1985"
    },
    {
        "engDate": "1928-10-02",
        "nepDate": "17.06.1985"
    },
    {
        "engDate": "1928-10-03",
        "nepDate": "18.06.1985"
    },
    {
        "engDate": "1928-10-04",
        "nepDate": "19.06.1985"
    },
    {
        "engDate": "1928-10-05",
        "nepDate": "20.06.1985"
    },
    {
        "engDate": "1928-10-06",
        "nepDate": "21.06.1985"
    },
    {
        "engDate": "1928-10-07",
        "nepDate": "22.06.1985"
    },
    {
        "engDate": "1928-10-08",
        "nepDate": "23.06.1985"
    },
    {
        "engDate": "1928-10-09",
        "nepDate": "24.06.1985"
    },
    {
        "engDate": "1928-10-10",
        "nepDate": "25.06.1985"
    },
    {
        "engDate": "1928-10-11",
        "nepDate": "26.06.1985"
    },
    {
        "engDate": "1928-10-12",
        "nepDate": "27.06.1985"
    },
    {
        "engDate": "1928-10-13",
        "nepDate": "28.06.1985"
    },
    {
        "engDate": "1928-10-14",
        "nepDate": "29.06.1985"
    },
    {
        "engDate": "1928-10-15",
        "nepDate": "30.06.1985"
    },
    {
        "engDate": "1928-10-16",
        "nepDate": "31.06.1985"
    },
    {
        "engDate": "1928-10-17",
        "nepDate": "01.07.1985"
    },
    {
        "engDate": "1928-10-18",
        "nepDate": "02.07.1985"
    },
    {
        "engDate": "1928-10-19",
        "nepDate": "03.07.1985"
    },
    {
        "engDate": "1928-10-20",
        "nepDate": "04.07.1985"
    },
    {
        "engDate": "1928-10-21",
        "nepDate": "05.07.1985"
    },
    {
        "engDate": "1928-10-22",
        "nepDate": "06.07.1985"
    },
    {
        "engDate": "1928-10-23",
        "nepDate": "07.07.1985"
    },
    {
        "engDate": "1928-10-24",
        "nepDate": "08.07.1985"
    },
    {
        "engDate": "1928-10-25",
        "nepDate": "09.07.1985"
    },
    {
        "engDate": "1928-10-26",
        "nepDate": "10.07.1985"
    },
    {
        "engDate": "1928-10-27",
        "nepDate": "11.07.1985"
    },
    {
        "engDate": "1928-10-28",
        "nepDate": "12.07.1985"
    },
    {
        "engDate": "1928-10-29",
        "nepDate": "13.07.1985"
    },
    {
        "engDate": "1928-10-30",
        "nepDate": "14.07.1985"
    },
    {
        "engDate": "1928-10-31",
        "nepDate": "15.07.1985"
    },
    {
        "engDate": "1928-11-01",
        "nepDate": "16.07.1985"
    },
    {
        "engDate": "1928-11-02",
        "nepDate": "17.07.1985"
    },
    {
        "engDate": "1928-11-03",
        "nepDate": "18.07.1985"
    },
    {
        "engDate": "1928-11-04",
        "nepDate": "19.07.1985"
    },
    {
        "engDate": "1928-11-05",
        "nepDate": "20.07.1985"
    },
    {
        "engDate": "1928-11-06",
        "nepDate": "21.07.1985"
    },
    {
        "engDate": "1928-11-07",
        "nepDate": "22.07.1985"
    },
    {
        "engDate": "1928-11-08",
        "nepDate": "23.07.1985"
    },
    {
        "engDate": "1928-11-09",
        "nepDate": "24.07.1985"
    },
    {
        "engDate": "1928-11-10",
        "nepDate": "25.07.1985"
    },
    {
        "engDate": "1928-11-11",
        "nepDate": "26.07.1985"
    },
    {
        "engDate": "1928-11-12",
        "nepDate": "27.07.1985"
    },
    {
        "engDate": "1928-11-13",
        "nepDate": "28.07.1985"
    },
    {
        "engDate": "1928-11-14",
        "nepDate": "29.07.1985"
    },
    {
        "engDate": "1928-11-15",
        "nepDate": "01.08.1985"
    },
    {
        "engDate": "1928-11-16",
        "nepDate": "02.08.1985"
    },
    {
        "engDate": "1928-11-17",
        "nepDate": "03.08.1985"
    },
    {
        "engDate": "1928-11-18",
        "nepDate": "04.08.1985"
    },
    {
        "engDate": "1928-11-19",
        "nepDate": "05.08.1985"
    },
    {
        "engDate": "1928-11-20",
        "nepDate": "06.08.1985"
    },
    {
        "engDate": "1928-11-21",
        "nepDate": "07.08.1985"
    },
    {
        "engDate": "1928-11-22",
        "nepDate": "08.08.1985"
    },
    {
        "engDate": "1928-11-23",
        "nepDate": "09.08.1985"
    },
    {
        "engDate": "1928-11-24",
        "nepDate": "10.08.1985"
    },
    {
        "engDate": "1928-11-25",
        "nepDate": "11.08.1985"
    },
    {
        "engDate": "1928-11-26",
        "nepDate": "12.08.1985"
    },
    {
        "engDate": "1928-11-27",
        "nepDate": "13.08.1985"
    },
    {
        "engDate": "1928-11-28",
        "nepDate": "14.08.1985"
    },
    {
        "engDate": "1928-11-29",
        "nepDate": "15.08.1985"
    },
    {
        "engDate": "1928-11-30",
        "nepDate": "16.08.1985"
    },
    {
        "engDate": "1928-12-01",
        "nepDate": "17.08.1985"
    },
    {
        "engDate": "1928-12-02",
        "nepDate": "18.08.1985"
    },
    {
        "engDate": "1928-12-03",
        "nepDate": "19.08.1985"
    },
    {
        "engDate": "1928-12-04",
        "nepDate": "20.08.1985"
    },
    {
        "engDate": "1928-12-05",
        "nepDate": "21.08.1985"
    },
    {
        "engDate": "1928-12-06",
        "nepDate": "22.08.1985"
    },
    {
        "engDate": "1928-12-07",
        "nepDate": "23.08.1985"
    },
    {
        "engDate": "1928-12-08",
        "nepDate": "24.08.1985"
    },
    {
        "engDate": "1928-12-09",
        "nepDate": "25.08.1985"
    },
    {
        "engDate": "1928-12-10",
        "nepDate": "26.08.1985"
    },
    {
        "engDate": "1928-12-11",
        "nepDate": "27.08.1985"
    },
    {
        "engDate": "1928-12-12",
        "nepDate": "28.08.1985"
    },
    {
        "engDate": "1928-12-13",
        "nepDate": "29.08.1985"
    },
    {
        "engDate": "1928-12-14",
        "nepDate": "30.08.1985"
    },
    {
        "engDate": "1928-12-15",
        "nepDate": "01.09.1985"
    },
    {
        "engDate": "1928-12-16",
        "nepDate": "02.09.1985"
    },
    {
        "engDate": "1928-12-17",
        "nepDate": "03.09.1985"
    },
    {
        "engDate": "1928-12-18",
        "nepDate": "04.09.1985"
    },
    {
        "engDate": "1928-12-19",
        "nepDate": "05.09.1985"
    },
    {
        "engDate": "1928-12-20",
        "nepDate": "06.09.1985"
    },
    {
        "engDate": "1928-12-21",
        "nepDate": "07.09.1985"
    },
    {
        "engDate": "1928-12-22",
        "nepDate": "08.09.1985"
    },
    {
        "engDate": "1928-12-23",
        "nepDate": "09.09.1985"
    },
    {
        "engDate": "1928-12-24",
        "nepDate": "10.09.1985"
    },
    {
        "engDate": "1928-12-25",
        "nepDate": "11.09.1985"
    },
    {
        "engDate": "1928-12-26",
        "nepDate": "12.09.1985"
    },
    {
        "engDate": "1928-12-27",
        "nepDate": "13.09.1985"
    },
    {
        "engDate": "1928-12-28",
        "nepDate": "14.09.1985"
    },
    {
        "engDate": "1928-12-29",
        "nepDate": "15.09.1985"
    },
    {
        "engDate": "1928-12-30",
        "nepDate": "16.09.1985"
    },
    {
        "engDate": "1928-12-31",
        "nepDate": "17.09.1985"
    },
    {
        "engDate": "1929-01-01",
        "nepDate": "18.09.1985"
    },
    {
        "engDate": "1929-01-02",
        "nepDate": "19.09.1985"
    },
    {
        "engDate": "1929-01-03",
        "nepDate": "20.09.1985"
    },
    {
        "engDate": "1929-01-04",
        "nepDate": "21.09.1985"
    },
    {
        "engDate": "1929-01-05",
        "nepDate": "22.09.1985"
    },
    {
        "engDate": "1929-01-06",
        "nepDate": "23.09.1985"
    },
    {
        "engDate": "1929-01-07",
        "nepDate": "24.09.1985"
    },
    {
        "engDate": "1929-01-08",
        "nepDate": "25.09.1985"
    },
    {
        "engDate": "1929-01-09",
        "nepDate": "26.09.1985"
    },
    {
        "engDate": "1929-01-10",
        "nepDate": "27.09.1985"
    },
    {
        "engDate": "1929-01-11",
        "nepDate": "28.09.1985"
    },
    {
        "engDate": "1929-01-12",
        "nepDate": "29.09.1985"
    },
    {
        "engDate": "1929-01-13",
        "nepDate": "30.09.1985"
    },
    {
        "engDate": "1929-01-14",
        "nepDate": "01.10.1985"
    },
    {
        "engDate": "1929-01-15",
        "nepDate": "02.10.1985"
    },
    {
        "engDate": "1929-01-16",
        "nepDate": "03.10.1985"
    },
    {
        "engDate": "1929-01-17",
        "nepDate": "04.10.1985"
    },
    {
        "engDate": "1929-01-18",
        "nepDate": "05.10.1985"
    },
    {
        "engDate": "1929-01-19",
        "nepDate": "06.10.1985"
    },
    {
        "engDate": "1929-01-20",
        "nepDate": "07.10.1985"
    },
    {
        "engDate": "1929-01-21",
        "nepDate": "08.10.1985"
    },
    {
        "engDate": "1929-01-22",
        "nepDate": "09.10.1985"
    },
    {
        "engDate": "1929-01-23",
        "nepDate": "10.10.1985"
    },
    {
        "engDate": "1929-01-24",
        "nepDate": "11.10.1985"
    },
    {
        "engDate": "1929-01-25",
        "nepDate": "12.10.1985"
    },
    {
        "engDate": "1929-01-26",
        "nepDate": "13.10.1985"
    },
    {
        "engDate": "1929-01-27",
        "nepDate": "14.10.1985"
    },
    {
        "engDate": "1929-01-28",
        "nepDate": "15.10.1985"
    },
    {
        "engDate": "1929-01-29",
        "nepDate": "16.10.1985"
    },
    {
        "engDate": "1929-01-30",
        "nepDate": "17.10.1985"
    },
    {
        "engDate": "1929-01-31",
        "nepDate": "18.10.1985"
    },
    {
        "engDate": "1929-02-01",
        "nepDate": "19.10.1985"
    },
    {
        "engDate": "1929-02-02",
        "nepDate": "20.10.1985"
    },
    {
        "engDate": "1929-02-03",
        "nepDate": "21.10.1985"
    },
    {
        "engDate": "1929-02-04",
        "nepDate": "22.10.1985"
    },
    {
        "engDate": "1929-02-05",
        "nepDate": "23.10.1985"
    },
    {
        "engDate": "1929-02-06",
        "nepDate": "24.10.1985"
    },
    {
        "engDate": "1929-02-07",
        "nepDate": "25.10.1985"
    },
    {
        "engDate": "1929-02-08",
        "nepDate": "26.10.1985"
    },
    {
        "engDate": "1929-02-09",
        "nepDate": "27.10.1985"
    },
    {
        "engDate": "1929-02-10",
        "nepDate": "28.10.1985"
    },
    {
        "engDate": "1929-02-11",
        "nepDate": "29.10.1985"
    },
    {
        "engDate": "1929-02-12",
        "nepDate": "01.11.1985"
    },
    {
        "engDate": "1929-02-13",
        "nepDate": "02.11.1985"
    },
    {
        "engDate": "1929-02-14",
        "nepDate": "03.11.1985"
    },
    {
        "engDate": "1929-02-15",
        "nepDate": "04.11.1985"
    },
    {
        "engDate": "1929-02-16",
        "nepDate": "05.11.1985"
    },
    {
        "engDate": "1929-02-17",
        "nepDate": "06.11.1985"
    },
    {
        "engDate": "1929-02-18",
        "nepDate": "07.11.1985"
    },
    {
        "engDate": "1929-02-19",
        "nepDate": "08.11.1985"
    },
    {
        "engDate": "1929-02-20",
        "nepDate": "09.11.1985"
    },
    {
        "engDate": "1929-02-21",
        "nepDate": "10.11.1985"
    },
    {
        "engDate": "1929-02-22",
        "nepDate": "11.11.1985"
    },
    {
        "engDate": "1929-02-23",
        "nepDate": "12.11.1985"
    },
    {
        "engDate": "1929-02-24",
        "nepDate": "13.11.1985"
    },
    {
        "engDate": "1929-02-25",
        "nepDate": "14.11.1985"
    },
    {
        "engDate": "1929-02-26",
        "nepDate": "15.11.1985"
    },
    {
        "engDate": "1929-02-27",
        "nepDate": "16.11.1985"
    },
    {
        "engDate": "1929-02-28",
        "nepDate": "17.11.1985"
    },
    {
        "engDate": "1929-03-01",
        "nepDate": "18.11.1985"
    },
    {
        "engDate": "1929-03-02",
        "nepDate": "19.11.1985"
    },
    {
        "engDate": "1929-03-03",
        "nepDate": "20.11.1985"
    },
    {
        "engDate": "1929-03-04",
        "nepDate": "21.11.1985"
    },
    {
        "engDate": "1929-03-05",
        "nepDate": "22.11.1985"
    },
    {
        "engDate": "1929-03-06",
        "nepDate": "23.11.1985"
    },
    {
        "engDate": "1929-03-07",
        "nepDate": "24.11.1985"
    },
    {
        "engDate": "1929-03-08",
        "nepDate": "25.11.1985"
    },
    {
        "engDate": "1929-03-09",
        "nepDate": "26.11.1985"
    },
    {
        "engDate": "1929-03-10",
        "nepDate": "27.11.1985"
    },
    {
        "engDate": "1929-03-11",
        "nepDate": "28.11.1985"
    },
    {
        "engDate": "1929-03-12",
        "nepDate": "29.11.1985"
    },
    {
        "engDate": "1929-03-13",
        "nepDate": "30.11.1985"
    },
    {
        "engDate": "1929-03-14",
        "nepDate": "01.12.1985"
    },
    {
        "engDate": "1929-03-15",
        "nepDate": "02.12.1985"
    },
    {
        "engDate": "1929-03-16",
        "nepDate": "03.12.1985"
    },
    {
        "engDate": "1929-03-17",
        "nepDate": "04.12.1985"
    },
    {
        "engDate": "1929-03-18",
        "nepDate": "05.12.1985"
    },
    {
        "engDate": "1929-03-19",
        "nepDate": "06.12.1985"
    },
    {
        "engDate": "1929-03-20",
        "nepDate": "07.12.1985"
    },
    {
        "engDate": "1929-03-21",
        "nepDate": "08.12.1985"
    },
    {
        "engDate": "1929-03-22",
        "nepDate": "09.12.1985"
    },
    {
        "engDate": "1929-03-23",
        "nepDate": "10.12.1985"
    },
    {
        "engDate": "1929-03-24",
        "nepDate": "11.12.1985"
    },
    {
        "engDate": "1929-03-25",
        "nepDate": "12.12.1985"
    },
    {
        "engDate": "1929-03-26",
        "nepDate": "13.12.1985"
    },
    {
        "engDate": "1929-03-27",
        "nepDate": "14.12.1985"
    },
    {
        "engDate": "1929-03-28",
        "nepDate": "15.12.1985"
    },
    {
        "engDate": "1929-03-29",
        "nepDate": "16.12.1985"
    },
    {
        "engDate": "1929-03-30",
        "nepDate": "17.12.1985"
    },
    {
        "engDate": "1929-03-31",
        "nepDate": "18.12.1985"
    },
    {
        "engDate": "1929-04-01",
        "nepDate": "19.12.1985"
    },
    {
        "engDate": "1929-04-02",
        "nepDate": "20.12.1985"
    },
    {
        "engDate": "1929-04-03",
        "nepDate": "21.12.1985"
    },
    {
        "engDate": "1929-04-04",
        "nepDate": "22.12.1985"
    },
    {
        "engDate": "1929-04-05",
        "nepDate": "23.12.1985"
    },
    {
        "engDate": "1929-04-06",
        "nepDate": "24.12.1985"
    },
    {
        "engDate": "1929-04-07",
        "nepDate": "25.12.1985"
    },
    {
        "engDate": "1929-04-08",
        "nepDate": "26.12.1985"
    },
    {
        "engDate": "1929-04-09",
        "nepDate": "27.12.1985"
    },
    {
        "engDate": "1929-04-10",
        "nepDate": "28.12.1985"
    },
    {
        "engDate": "1929-04-11",
        "nepDate": "29.12.1985"
    },
    {
        "engDate": "1929-04-12",
        "nepDate": "30.12.1985"
    },
    {
        "engDate": "1929-04-13",
        "nepDate": "01.01.1986"
    },
    {
        "engDate": "1929-04-14",
        "nepDate": "02.01.1986"
    },
    {
        "engDate": "1929-04-15",
        "nepDate": "03.01.1986"
    },
    {
        "engDate": "1929-04-16",
        "nepDate": "04.01.1986"
    },
    {
        "engDate": "1929-04-17",
        "nepDate": "05.01.1986"
    },
    {
        "engDate": "1929-04-18",
        "nepDate": "06.01.1986"
    },
    {
        "engDate": "1929-04-19",
        "nepDate": "07.01.1986"
    },
    {
        "engDate": "1929-04-20",
        "nepDate": "08.01.1986"
    },
    {
        "engDate": "1929-04-21",
        "nepDate": "09.01.1986"
    },
    {
        "engDate": "1929-04-22",
        "nepDate": "10.01.1986"
    },
    {
        "engDate": "1929-04-23",
        "nepDate": "11.01.1986"
    },
    {
        "engDate": "1929-04-24",
        "nepDate": "12.01.1986"
    },
    {
        "engDate": "1929-04-25",
        "nepDate": "13.01.1986"
    },
    {
        "engDate": "1929-04-26",
        "nepDate": "14.01.1986"
    },
    {
        "engDate": "1929-04-27",
        "nepDate": "15.01.1986"
    },
    {
        "engDate": "1929-04-28",
        "nepDate": "16.01.1986"
    },
    {
        "engDate": "1929-04-29",
        "nepDate": "17.01.1986"
    },
    {
        "engDate": "1929-04-30",
        "nepDate": "18.01.1986"
    },
    {
        "engDate": "1929-05-01",
        "nepDate": "19.01.1986"
    },
    {
        "engDate": "1929-05-02",
        "nepDate": "20.01.1986"
    },
    {
        "engDate": "1929-05-03",
        "nepDate": "21.01.1986"
    },
    {
        "engDate": "1929-05-04",
        "nepDate": "22.01.1986"
    },
    {
        "engDate": "1929-05-05",
        "nepDate": "23.01.1986"
    },
    {
        "engDate": "1929-05-06",
        "nepDate": "24.01.1986"
    },
    {
        "engDate": "1929-05-07",
        "nepDate": "25.01.1986"
    },
    {
        "engDate": "1929-05-08",
        "nepDate": "26.01.1986"
    },
    {
        "engDate": "1929-05-09",
        "nepDate": "27.01.1986"
    },
    {
        "engDate": "1929-05-10",
        "nepDate": "28.01.1986"
    },
    {
        "engDate": "1929-05-11",
        "nepDate": "29.01.1986"
    },
    {
        "engDate": "1929-05-12",
        "nepDate": "30.01.1986"
    },
    {
        "engDate": "1929-05-13",
        "nepDate": "31.01.1986"
    },
    {
        "engDate": "1929-05-14",
        "nepDate": "01.02.1986"
    },
    {
        "engDate": "1929-05-15",
        "nepDate": "02.02.1986"
    },
    {
        "engDate": "1929-05-16",
        "nepDate": "03.02.1986"
    },
    {
        "engDate": "1929-05-17",
        "nepDate": "04.02.1986"
    },
    {
        "engDate": "1929-05-18",
        "nepDate": "05.02.1986"
    },
    {
        "engDate": "1929-05-19",
        "nepDate": "06.02.1986"
    },
    {
        "engDate": "1929-05-20",
        "nepDate": "07.02.1986"
    },
    {
        "engDate": "1929-05-21",
        "nepDate": "08.02.1986"
    },
    {
        "engDate": "1929-05-22",
        "nepDate": "09.02.1986"
    },
    {
        "engDate": "1929-05-23",
        "nepDate": "10.02.1986"
    },
    {
        "engDate": "1929-05-24",
        "nepDate": "11.02.1986"
    },
    {
        "engDate": "1929-05-25",
        "nepDate": "12.02.1986"
    },
    {
        "engDate": "1929-05-26",
        "nepDate": "13.02.1986"
    },
    {
        "engDate": "1929-05-27",
        "nepDate": "14.02.1986"
    },
    {
        "engDate": "1929-05-28",
        "nepDate": "15.02.1986"
    },
    {
        "engDate": "1929-05-29",
        "nepDate": "16.02.1986"
    },
    {
        "engDate": "1929-05-30",
        "nepDate": "17.02.1986"
    },
    {
        "engDate": "1929-05-31",
        "nepDate": "18.02.1986"
    },
    {
        "engDate": "1929-06-01",
        "nepDate": "19.02.1986"
    },
    {
        "engDate": "1929-06-02",
        "nepDate": "20.02.1986"
    },
    {
        "engDate": "1929-06-03",
        "nepDate": "21.02.1986"
    },
    {
        "engDate": "1929-06-04",
        "nepDate": "22.02.1986"
    },
    {
        "engDate": "1929-06-05",
        "nepDate": "23.02.1986"
    },
    {
        "engDate": "1929-06-06",
        "nepDate": "24.02.1986"
    },
    {
        "engDate": "1929-06-07",
        "nepDate": "25.02.1986"
    },
    {
        "engDate": "1929-06-08",
        "nepDate": "26.02.1986"
    },
    {
        "engDate": "1929-06-09",
        "nepDate": "27.02.1986"
    },
    {
        "engDate": "1929-06-10",
        "nepDate": "28.02.1986"
    },
    {
        "engDate": "1929-06-11",
        "nepDate": "29.02.1986"
    },
    {
        "engDate": "1929-06-12",
        "nepDate": "30.02.1986"
    },
    {
        "engDate": "1929-06-13",
        "nepDate": "31.02.1986"
    },
    {
        "engDate": "1929-06-14",
        "nepDate": "01.03.1986"
    },
    {
        "engDate": "1929-06-15",
        "nepDate": "02.03.1986"
    },
    {
        "engDate": "1929-06-16",
        "nepDate": "03.03.1986"
    },
    {
        "engDate": "1929-06-17",
        "nepDate": "04.03.1986"
    },
    {
        "engDate": "1929-06-18",
        "nepDate": "05.03.1986"
    },
    {
        "engDate": "1929-06-19",
        "nepDate": "06.03.1986"
    },
    {
        "engDate": "1929-06-20",
        "nepDate": "07.03.1986"
    },
    {
        "engDate": "1929-06-21",
        "nepDate": "08.03.1986"
    },
    {
        "engDate": "1929-06-22",
        "nepDate": "09.03.1986"
    },
    {
        "engDate": "1929-06-23",
        "nepDate": "10.03.1986"
    },
    {
        "engDate": "1929-06-24",
        "nepDate": "11.03.1986"
    },
    {
        "engDate": "1929-06-25",
        "nepDate": "12.03.1986"
    },
    {
        "engDate": "1929-06-26",
        "nepDate": "13.03.1986"
    },
    {
        "engDate": "1929-06-27",
        "nepDate": "14.03.1986"
    },
    {
        "engDate": "1929-06-28",
        "nepDate": "15.03.1986"
    },
    {
        "engDate": "1929-06-29",
        "nepDate": "16.03.1986"
    },
    {
        "engDate": "1929-06-30",
        "nepDate": "17.03.1986"
    },
    {
        "engDate": "1929-07-01",
        "nepDate": "18.03.1986"
    },
    {
        "engDate": "1929-07-02",
        "nepDate": "19.03.1986"
    },
    {
        "engDate": "1929-07-03",
        "nepDate": "20.03.1986"
    },
    {
        "engDate": "1929-07-04",
        "nepDate": "21.03.1986"
    },
    {
        "engDate": "1929-07-05",
        "nepDate": "22.03.1986"
    },
    {
        "engDate": "1929-07-06",
        "nepDate": "23.03.1986"
    },
    {
        "engDate": "1929-07-07",
        "nepDate": "24.03.1986"
    },
    {
        "engDate": "1929-07-08",
        "nepDate": "25.03.1986"
    },
    {
        "engDate": "1929-07-09",
        "nepDate": "26.03.1986"
    },
    {
        "engDate": "1929-07-10",
        "nepDate": "27.03.1986"
    },
    {
        "engDate": "1929-07-11",
        "nepDate": "28.03.1986"
    },
    {
        "engDate": "1929-07-12",
        "nepDate": "29.03.1986"
    },
    {
        "engDate": "1929-07-13",
        "nepDate": "30.03.1986"
    },
    {
        "engDate": "1929-07-14",
        "nepDate": "31.03.1986"
    },
    {
        "engDate": "1929-07-15",
        "nepDate": "32.03.1986"
    },
    {
        "engDate": "1929-07-16",
        "nepDate": "01.04.1986"
    },
    {
        "engDate": "1929-07-17",
        "nepDate": "02.04.1986"
    },
    {
        "engDate": "1929-07-18",
        "nepDate": "03.04.1986"
    },
    {
        "engDate": "1929-07-19",
        "nepDate": "04.04.1986"
    },
    {
        "engDate": "1929-07-20",
        "nepDate": "05.04.1986"
    },
    {
        "engDate": "1929-07-21",
        "nepDate": "06.04.1986"
    },
    {
        "engDate": "1929-07-22",
        "nepDate": "07.04.1986"
    },
    {
        "engDate": "1929-07-23",
        "nepDate": "08.04.1986"
    },
    {
        "engDate": "1929-07-24",
        "nepDate": "09.04.1986"
    },
    {
        "engDate": "1929-07-25",
        "nepDate": "10.04.1986"
    },
    {
        "engDate": "1929-07-26",
        "nepDate": "11.04.1986"
    },
    {
        "engDate": "1929-07-27",
        "nepDate": "12.04.1986"
    },
    {
        "engDate": "1929-07-28",
        "nepDate": "13.04.1986"
    },
    {
        "engDate": "1929-07-29",
        "nepDate": "14.04.1986"
    },
    {
        "engDate": "1929-07-30",
        "nepDate": "15.04.1986"
    },
    {
        "engDate": "1929-07-31",
        "nepDate": "16.04.1986"
    },
    {
        "engDate": "1929-08-01",
        "nepDate": "17.04.1986"
    },
    {
        "engDate": "1929-08-02",
        "nepDate": "18.04.1986"
    },
    {
        "engDate": "1929-08-03",
        "nepDate": "19.04.1986"
    },
    {
        "engDate": "1929-08-04",
        "nepDate": "20.04.1986"
    },
    {
        "engDate": "1929-08-05",
        "nepDate": "21.04.1986"
    },
    {
        "engDate": "1929-08-06",
        "nepDate": "22.04.1986"
    },
    {
        "engDate": "1929-08-07",
        "nepDate": "23.04.1986"
    },
    {
        "engDate": "1929-08-08",
        "nepDate": "24.04.1986"
    },
    {
        "engDate": "1929-08-09",
        "nepDate": "25.04.1986"
    },
    {
        "engDate": "1929-08-10",
        "nepDate": "26.04.1986"
    },
    {
        "engDate": "1929-08-11",
        "nepDate": "27.04.1986"
    },
    {
        "engDate": "1929-08-12",
        "nepDate": "28.04.1986"
    },
    {
        "engDate": "1929-08-13",
        "nepDate": "29.04.1986"
    },
    {
        "engDate": "1929-08-14",
        "nepDate": "30.04.1986"
    },
    {
        "engDate": "1929-08-15",
        "nepDate": "31.04.1986"
    },
    {
        "engDate": "1929-08-16",
        "nepDate": "01.05.1986"
    },
    {
        "engDate": "1929-08-17",
        "nepDate": "02.05.1986"
    },
    {
        "engDate": "1929-08-18",
        "nepDate": "03.05.1986"
    },
    {
        "engDate": "1929-08-19",
        "nepDate": "04.05.1986"
    },
    {
        "engDate": "1929-08-20",
        "nepDate": "05.05.1986"
    },
    {
        "engDate": "1929-08-21",
        "nepDate": "06.05.1986"
    },
    {
        "engDate": "1929-08-22",
        "nepDate": "07.05.1986"
    },
    {
        "engDate": "1929-08-23",
        "nepDate": "08.05.1986"
    },
    {
        "engDate": "1929-08-24",
        "nepDate": "09.05.1986"
    },
    {
        "engDate": "1929-08-25",
        "nepDate": "10.05.1986"
    },
    {
        "engDate": "1929-08-26",
        "nepDate": "11.05.1986"
    },
    {
        "engDate": "1929-08-27",
        "nepDate": "12.05.1986"
    },
    {
        "engDate": "1929-08-28",
        "nepDate": "13.05.1986"
    },
    {
        "engDate": "1929-08-29",
        "nepDate": "14.05.1986"
    },
    {
        "engDate": "1929-08-30",
        "nepDate": "15.05.1986"
    },
    {
        "engDate": "1929-08-31",
        "nepDate": "16.05.1986"
    },
    {
        "engDate": "1929-09-01",
        "nepDate": "17.05.1986"
    },
    {
        "engDate": "1929-09-02",
        "nepDate": "18.05.1986"
    },
    {
        "engDate": "1929-09-03",
        "nepDate": "19.05.1986"
    },
    {
        "engDate": "1929-09-04",
        "nepDate": "20.05.1986"
    },
    {
        "engDate": "1929-09-05",
        "nepDate": "21.05.1986"
    },
    {
        "engDate": "1929-09-06",
        "nepDate": "22.05.1986"
    },
    {
        "engDate": "1929-09-07",
        "nepDate": "23.05.1986"
    },
    {
        "engDate": "1929-09-08",
        "nepDate": "24.05.1986"
    },
    {
        "engDate": "1929-09-09",
        "nepDate": "25.05.1986"
    },
    {
        "engDate": "1929-09-10",
        "nepDate": "26.05.1986"
    },
    {
        "engDate": "1929-09-11",
        "nepDate": "27.05.1986"
    },
    {
        "engDate": "1929-09-12",
        "nepDate": "28.05.1986"
    },
    {
        "engDate": "1929-09-13",
        "nepDate": "29.05.1986"
    },
    {
        "engDate": "1929-09-14",
        "nepDate": "30.05.1986"
    },
    {
        "engDate": "1929-09-15",
        "nepDate": "31.05.1986"
    },
    {
        "engDate": "1929-09-16",
        "nepDate": "01.06.1986"
    },
    {
        "engDate": "1929-09-17",
        "nepDate": "02.06.1986"
    },
    {
        "engDate": "1929-09-18",
        "nepDate": "03.06.1986"
    },
    {
        "engDate": "1929-09-19",
        "nepDate": "04.06.1986"
    },
    {
        "engDate": "1929-09-20",
        "nepDate": "05.06.1986"
    },
    {
        "engDate": "1929-09-21",
        "nepDate": "06.06.1986"
    },
    {
        "engDate": "1929-09-22",
        "nepDate": "07.06.1986"
    },
    {
        "engDate": "1929-09-23",
        "nepDate": "08.06.1986"
    },
    {
        "engDate": "1929-09-24",
        "nepDate": "09.06.1986"
    },
    {
        "engDate": "1929-09-25",
        "nepDate": "10.06.1986"
    },
    {
        "engDate": "1929-09-26",
        "nepDate": "11.06.1986"
    },
    {
        "engDate": "1929-09-27",
        "nepDate": "12.06.1986"
    },
    {
        "engDate": "1929-09-28",
        "nepDate": "13.06.1986"
    },
    {
        "engDate": "1929-09-29",
        "nepDate": "14.06.1986"
    },
    {
        "engDate": "1929-09-30",
        "nepDate": "15.06.1986"
    },
    {
        "engDate": "1929-10-01",
        "nepDate": "16.06.1986"
    },
    {
        "engDate": "1929-10-02",
        "nepDate": "17.06.1986"
    },
    {
        "engDate": "1929-10-03",
        "nepDate": "18.06.1986"
    },
    {
        "engDate": "1929-10-04",
        "nepDate": "19.06.1986"
    },
    {
        "engDate": "1929-10-05",
        "nepDate": "20.06.1986"
    },
    {
        "engDate": "1929-10-06",
        "nepDate": "21.06.1986"
    },
    {
        "engDate": "1929-10-07",
        "nepDate": "22.06.1986"
    },
    {
        "engDate": "1929-10-08",
        "nepDate": "23.06.1986"
    },
    {
        "engDate": "1929-10-09",
        "nepDate": "24.06.1986"
    },
    {
        "engDate": "1929-10-10",
        "nepDate": "25.06.1986"
    },
    {
        "engDate": "1929-10-11",
        "nepDate": "26.06.1986"
    },
    {
        "engDate": "1929-10-12",
        "nepDate": "27.06.1986"
    },
    {
        "engDate": "1929-10-13",
        "nepDate": "28.06.1986"
    },
    {
        "engDate": "1929-10-14",
        "nepDate": "29.06.1986"
    },
    {
        "engDate": "1929-10-15",
        "nepDate": "30.06.1986"
    },
    {
        "engDate": "1929-10-16",
        "nepDate": "31.06.1986"
    },
    {
        "engDate": "1929-10-17",
        "nepDate": "01.07.1986"
    },
    {
        "engDate": "1929-10-18",
        "nepDate": "02.07.1986"
    },
    {
        "engDate": "1929-10-19",
        "nepDate": "03.07.1986"
    },
    {
        "engDate": "1929-10-20",
        "nepDate": "04.07.1986"
    },
    {
        "engDate": "1929-10-21",
        "nepDate": "05.07.1986"
    },
    {
        "engDate": "1929-10-22",
        "nepDate": "06.07.1986"
    },
    {
        "engDate": "1929-10-23",
        "nepDate": "07.07.1986"
    },
    {
        "engDate": "1929-10-24",
        "nepDate": "08.07.1986"
    },
    {
        "engDate": "1929-10-25",
        "nepDate": "09.07.1986"
    },
    {
        "engDate": "1929-10-26",
        "nepDate": "10.07.1986"
    },
    {
        "engDate": "1929-10-27",
        "nepDate": "11.07.1986"
    },
    {
        "engDate": "1929-10-28",
        "nepDate": "12.07.1986"
    },
    {
        "engDate": "1929-10-29",
        "nepDate": "13.07.1986"
    },
    {
        "engDate": "1929-10-30",
        "nepDate": "14.07.1986"
    },
    {
        "engDate": "1929-10-31",
        "nepDate": "15.07.1986"
    },
    {
        "engDate": "1929-11-01",
        "nepDate": "16.07.1986"
    },
    {
        "engDate": "1929-11-02",
        "nepDate": "17.07.1986"
    },
    {
        "engDate": "1929-11-03",
        "nepDate": "18.07.1986"
    },
    {
        "engDate": "1929-11-04",
        "nepDate": "19.07.1986"
    },
    {
        "engDate": "1929-11-05",
        "nepDate": "20.07.1986"
    },
    {
        "engDate": "1929-11-06",
        "nepDate": "21.07.1986"
    },
    {
        "engDate": "1929-11-07",
        "nepDate": "22.07.1986"
    },
    {
        "engDate": "1929-11-08",
        "nepDate": "23.07.1986"
    },
    {
        "engDate": "1929-11-09",
        "nepDate": "24.07.1986"
    },
    {
        "engDate": "1929-11-10",
        "nepDate": "25.07.1986"
    },
    {
        "engDate": "1929-11-11",
        "nepDate": "26.07.1986"
    },
    {
        "engDate": "1929-11-12",
        "nepDate": "27.07.1986"
    },
    {
        "engDate": "1929-11-13",
        "nepDate": "28.07.1986"
    },
    {
        "engDate": "1929-11-14",
        "nepDate": "29.07.1986"
    },
    {
        "engDate": "1929-11-15",
        "nepDate": "30.07.1986"
    },
    {
        "engDate": "1929-11-16",
        "nepDate": "01.08.1986"
    },
    {
        "engDate": "1929-11-17",
        "nepDate": "02.08.1986"
    },
    {
        "engDate": "1929-11-18",
        "nepDate": "03.08.1986"
    },
    {
        "engDate": "1929-11-19",
        "nepDate": "04.08.1986"
    },
    {
        "engDate": "1929-11-20",
        "nepDate": "05.08.1986"
    },
    {
        "engDate": "1929-11-21",
        "nepDate": "06.08.1986"
    },
    {
        "engDate": "1929-11-22",
        "nepDate": "07.08.1986"
    },
    {
        "engDate": "1929-11-23",
        "nepDate": "08.08.1986"
    },
    {
        "engDate": "1929-11-24",
        "nepDate": "09.08.1986"
    },
    {
        "engDate": "1929-11-25",
        "nepDate": "10.08.1986"
    },
    {
        "engDate": "1929-11-26",
        "nepDate": "11.08.1986"
    },
    {
        "engDate": "1929-11-27",
        "nepDate": "12.08.1986"
    },
    {
        "engDate": "1929-11-28",
        "nepDate": "13.08.1986"
    },
    {
        "engDate": "1929-11-29",
        "nepDate": "14.08.1986"
    },
    {
        "engDate": "1929-11-30",
        "nepDate": "15.08.1986"
    },
    {
        "engDate": "1929-12-01",
        "nepDate": "16.08.1986"
    },
    {
        "engDate": "1929-12-02",
        "nepDate": "17.08.1986"
    },
    {
        "engDate": "1929-12-03",
        "nepDate": "18.08.1986"
    },
    {
        "engDate": "1929-12-04",
        "nepDate": "19.08.1986"
    },
    {
        "engDate": "1929-12-05",
        "nepDate": "20.08.1986"
    },
    {
        "engDate": "1929-12-06",
        "nepDate": "21.08.1986"
    },
    {
        "engDate": "1929-12-07",
        "nepDate": "22.08.1986"
    },
    {
        "engDate": "1929-12-08",
        "nepDate": "23.08.1986"
    },
    {
        "engDate": "1929-12-09",
        "nepDate": "24.08.1986"
    },
    {
        "engDate": "1929-12-10",
        "nepDate": "25.08.1986"
    },
    {
        "engDate": "1929-12-11",
        "nepDate": "26.08.1986"
    },
    {
        "engDate": "1929-12-12",
        "nepDate": "27.08.1986"
    },
    {
        "engDate": "1929-12-13",
        "nepDate": "28.08.1986"
    },
    {
        "engDate": "1929-12-14",
        "nepDate": "29.08.1986"
    },
    {
        "engDate": "1929-12-15",
        "nepDate": "01.09.1986"
    },
    {
        "engDate": "1929-12-16",
        "nepDate": "02.09.1986"
    },
    {
        "engDate": "1929-12-17",
        "nepDate": "03.09.1986"
    },
    {
        "engDate": "1929-12-18",
        "nepDate": "04.09.1986"
    },
    {
        "engDate": "1929-12-19",
        "nepDate": "05.09.1986"
    },
    {
        "engDate": "1929-12-20",
        "nepDate": "06.09.1986"
    },
    {
        "engDate": "1929-12-21",
        "nepDate": "07.09.1986"
    },
    {
        "engDate": "1929-12-22",
        "nepDate": "08.09.1986"
    },
    {
        "engDate": "1929-12-23",
        "nepDate": "09.09.1986"
    },
    {
        "engDate": "1929-12-24",
        "nepDate": "10.09.1986"
    },
    {
        "engDate": "1929-12-25",
        "nepDate": "11.09.1986"
    },
    {
        "engDate": "1929-12-26",
        "nepDate": "12.09.1986"
    },
    {
        "engDate": "1929-12-27",
        "nepDate": "13.09.1986"
    },
    {
        "engDate": "1929-12-28",
        "nepDate": "14.09.1986"
    },
    {
        "engDate": "1929-12-29",
        "nepDate": "15.09.1986"
    },
    {
        "engDate": "1929-12-30",
        "nepDate": "16.09.1986"
    },
    {
        "engDate": "1929-12-31",
        "nepDate": "17.09.1986"
    },
    {
        "engDate": "1930-01-01",
        "nepDate": "18.09.1986"
    },
    {
        "engDate": "1930-01-02",
        "nepDate": "19.09.1986"
    },
    {
        "engDate": "1930-01-03",
        "nepDate": "20.09.1986"
    },
    {
        "engDate": "1930-01-04",
        "nepDate": "21.09.1986"
    },
    {
        "engDate": "1930-01-05",
        "nepDate": "22.09.1986"
    },
    {
        "engDate": "1930-01-06",
        "nepDate": "23.09.1986"
    },
    {
        "engDate": "1930-01-07",
        "nepDate": "24.09.1986"
    },
    {
        "engDate": "1930-01-08",
        "nepDate": "25.09.1986"
    },
    {
        "engDate": "1930-01-09",
        "nepDate": "26.09.1986"
    },
    {
        "engDate": "1930-01-10",
        "nepDate": "27.09.1986"
    },
    {
        "engDate": "1930-01-11",
        "nepDate": "28.09.1986"
    },
    {
        "engDate": "1930-01-12",
        "nepDate": "29.09.1986"
    },
    {
        "engDate": "1930-01-13",
        "nepDate": "30.09.1986"
    },
    {
        "engDate": "1930-01-14",
        "nepDate": "01.10.1986"
    },
    {
        "engDate": "1930-01-15",
        "nepDate": "02.10.1986"
    },
    {
        "engDate": "1930-01-16",
        "nepDate": "03.10.1986"
    },
    {
        "engDate": "1930-01-17",
        "nepDate": "04.10.1986"
    },
    {
        "engDate": "1930-01-18",
        "nepDate": "05.10.1986"
    },
    {
        "engDate": "1930-01-19",
        "nepDate": "06.10.1986"
    },
    {
        "engDate": "1930-01-20",
        "nepDate": "07.10.1986"
    },
    {
        "engDate": "1930-01-21",
        "nepDate": "08.10.1986"
    },
    {
        "engDate": "1930-01-22",
        "nepDate": "09.10.1986"
    },
    {
        "engDate": "1930-01-23",
        "nepDate": "10.10.1986"
    },
    {
        "engDate": "1930-01-24",
        "nepDate": "11.10.1986"
    },
    {
        "engDate": "1930-01-25",
        "nepDate": "12.10.1986"
    },
    {
        "engDate": "1930-01-26",
        "nepDate": "13.10.1986"
    },
    {
        "engDate": "1930-01-27",
        "nepDate": "14.10.1986"
    },
    {
        "engDate": "1930-01-28",
        "nepDate": "15.10.1986"
    },
    {
        "engDate": "1930-01-29",
        "nepDate": "16.10.1986"
    },
    {
        "engDate": "1930-01-30",
        "nepDate": "17.10.1986"
    },
    {
        "engDate": "1930-01-31",
        "nepDate": "18.10.1986"
    },
    {
        "engDate": "1930-02-01",
        "nepDate": "19.10.1986"
    },
    {
        "engDate": "1930-02-02",
        "nepDate": "20.10.1986"
    },
    {
        "engDate": "1930-02-03",
        "nepDate": "21.10.1986"
    },
    {
        "engDate": "1930-02-04",
        "nepDate": "22.10.1986"
    },
    {
        "engDate": "1930-02-05",
        "nepDate": "23.10.1986"
    },
    {
        "engDate": "1930-02-06",
        "nepDate": "24.10.1986"
    },
    {
        "engDate": "1930-02-07",
        "nepDate": "25.10.1986"
    },
    {
        "engDate": "1930-02-08",
        "nepDate": "26.10.1986"
    },
    {
        "engDate": "1930-02-09",
        "nepDate": "27.10.1986"
    },
    {
        "engDate": "1930-02-10",
        "nepDate": "28.10.1986"
    },
    {
        "engDate": "1930-02-11",
        "nepDate": "29.10.1986"
    },
    {
        "engDate": "1930-02-12",
        "nepDate": "01.11.1986"
    },
    {
        "engDate": "1930-02-13",
        "nepDate": "02.11.1986"
    },
    {
        "engDate": "1930-02-14",
        "nepDate": "03.11.1986"
    },
    {
        "engDate": "1930-02-15",
        "nepDate": "04.11.1986"
    },
    {
        "engDate": "1930-02-16",
        "nepDate": "05.11.1986"
    },
    {
        "engDate": "1930-02-17",
        "nepDate": "06.11.1986"
    },
    {
        "engDate": "1930-02-18",
        "nepDate": "07.11.1986"
    },
    {
        "engDate": "1930-02-19",
        "nepDate": "08.11.1986"
    },
    {
        "engDate": "1930-02-20",
        "nepDate": "09.11.1986"
    },
    {
        "engDate": "1930-02-21",
        "nepDate": "10.11.1986"
    },
    {
        "engDate": "1930-02-22",
        "nepDate": "11.11.1986"
    },
    {
        "engDate": "1930-02-23",
        "nepDate": "12.11.1986"
    },
    {
        "engDate": "1930-02-24",
        "nepDate": "13.11.1986"
    },
    {
        "engDate": "1930-02-25",
        "nepDate": "14.11.1986"
    },
    {
        "engDate": "1930-02-26",
        "nepDate": "15.11.1986"
    },
    {
        "engDate": "1930-02-27",
        "nepDate": "16.11.1986"
    },
    {
        "engDate": "1930-02-28",
        "nepDate": "17.11.1986"
    },
    {
        "engDate": "1930-03-01",
        "nepDate": "18.11.1986"
    },
    {
        "engDate": "1930-03-02",
        "nepDate": "19.11.1986"
    },
    {
        "engDate": "1930-03-03",
        "nepDate": "20.11.1986"
    },
    {
        "engDate": "1930-03-04",
        "nepDate": "21.11.1986"
    },
    {
        "engDate": "1930-03-05",
        "nepDate": "22.11.1986"
    },
    {
        "engDate": "1930-03-06",
        "nepDate": "23.11.1986"
    },
    {
        "engDate": "1930-03-07",
        "nepDate": "24.11.1986"
    },
    {
        "engDate": "1930-03-08",
        "nepDate": "25.11.1986"
    },
    {
        "engDate": "1930-03-09",
        "nepDate": "26.11.1986"
    },
    {
        "engDate": "1930-03-10",
        "nepDate": "27.11.1986"
    },
    {
        "engDate": "1930-03-11",
        "nepDate": "28.11.1986"
    },
    {
        "engDate": "1930-03-12",
        "nepDate": "29.11.1986"
    },
    {
        "engDate": "1930-03-13",
        "nepDate": "30.11.1986"
    },
    {
        "engDate": "1930-03-14",
        "nepDate": "01.12.1986"
    },
    {
        "engDate": "1930-03-15",
        "nepDate": "02.12.1986"
    },
    {
        "engDate": "1930-03-16",
        "nepDate": "03.12.1986"
    },
    {
        "engDate": "1930-03-17",
        "nepDate": "04.12.1986"
    },
    {
        "engDate": "1930-03-18",
        "nepDate": "05.12.1986"
    },
    {
        "engDate": "1930-03-19",
        "nepDate": "06.12.1986"
    },
    {
        "engDate": "1930-03-20",
        "nepDate": "07.12.1986"
    },
    {
        "engDate": "1930-03-21",
        "nepDate": "08.12.1986"
    },
    {
        "engDate": "1930-03-22",
        "nepDate": "09.12.1986"
    },
    {
        "engDate": "1930-03-23",
        "nepDate": "10.12.1986"
    },
    {
        "engDate": "1930-03-24",
        "nepDate": "11.12.1986"
    },
    {
        "engDate": "1930-03-25",
        "nepDate": "12.12.1986"
    },
    {
        "engDate": "1930-03-26",
        "nepDate": "13.12.1986"
    },
    {
        "engDate": "1930-03-27",
        "nepDate": "14.12.1986"
    },
    {
        "engDate": "1930-03-28",
        "nepDate": "15.12.1986"
    },
    {
        "engDate": "1930-03-29",
        "nepDate": "16.12.1986"
    },
    {
        "engDate": "1930-03-30",
        "nepDate": "17.12.1986"
    },
    {
        "engDate": "1930-03-31",
        "nepDate": "18.12.1986"
    },
    {
        "engDate": "1930-04-01",
        "nepDate": "19.12.1986"
    },
    {
        "engDate": "1930-04-02",
        "nepDate": "20.12.1986"
    },
    {
        "engDate": "1930-04-03",
        "nepDate": "21.12.1986"
    },
    {
        "engDate": "1930-04-04",
        "nepDate": "22.12.1986"
    },
    {
        "engDate": "1930-04-05",
        "nepDate": "23.12.1986"
    },
    {
        "engDate": "1930-04-06",
        "nepDate": "24.12.1986"
    },
    {
        "engDate": "1930-04-07",
        "nepDate": "25.12.1986"
    },
    {
        "engDate": "1930-04-08",
        "nepDate": "26.12.1986"
    },
    {
        "engDate": "1930-04-09",
        "nepDate": "27.12.1986"
    },
    {
        "engDate": "1930-04-10",
        "nepDate": "28.12.1986"
    },
    {
        "engDate": "1930-04-11",
        "nepDate": "29.12.1986"
    },
    {
        "engDate": "1930-04-12",
        "nepDate": "30.12.1986"
    },
    {
        "engDate": "1930-04-13",
        "nepDate": "01.01.1987"
    },
    {
        "engDate": "1930-04-14",
        "nepDate": "02.01.1987"
    },
    {
        "engDate": "1930-04-15",
        "nepDate": "03.01.1987"
    },
    {
        "engDate": "1930-04-16",
        "nepDate": "04.01.1987"
    },
    {
        "engDate": "1930-04-17",
        "nepDate": "05.01.1987"
    },
    {
        "engDate": "1930-04-18",
        "nepDate": "06.01.1987"
    },
    {
        "engDate": "1930-04-19",
        "nepDate": "07.01.1987"
    },
    {
        "engDate": "1930-04-20",
        "nepDate": "08.01.1987"
    },
    {
        "engDate": "1930-04-21",
        "nepDate": "09.01.1987"
    },
    {
        "engDate": "1930-04-22",
        "nepDate": "10.01.1987"
    },
    {
        "engDate": "1930-04-23",
        "nepDate": "11.01.1987"
    },
    {
        "engDate": "1930-04-24",
        "nepDate": "12.01.1987"
    },
    {
        "engDate": "1930-04-25",
        "nepDate": "13.01.1987"
    },
    {
        "engDate": "1930-04-26",
        "nepDate": "14.01.1987"
    },
    {
        "engDate": "1930-04-27",
        "nepDate": "15.01.1987"
    },
    {
        "engDate": "1930-04-28",
        "nepDate": "16.01.1987"
    },
    {
        "engDate": "1930-04-29",
        "nepDate": "17.01.1987"
    },
    {
        "engDate": "1930-04-30",
        "nepDate": "18.01.1987"
    },
    {
        "engDate": "1930-05-01",
        "nepDate": "19.01.1987"
    },
    {
        "engDate": "1930-05-02",
        "nepDate": "20.01.1987"
    },
    {
        "engDate": "1930-05-03",
        "nepDate": "21.01.1987"
    },
    {
        "engDate": "1930-05-04",
        "nepDate": "22.01.1987"
    },
    {
        "engDate": "1930-05-05",
        "nepDate": "23.01.1987"
    },
    {
        "engDate": "1930-05-06",
        "nepDate": "24.01.1987"
    },
    {
        "engDate": "1930-05-07",
        "nepDate": "25.01.1987"
    },
    {
        "engDate": "1930-05-08",
        "nepDate": "26.01.1987"
    },
    {
        "engDate": "1930-05-09",
        "nepDate": "27.01.1987"
    },
    {
        "engDate": "1930-05-10",
        "nepDate": "28.01.1987"
    },
    {
        "engDate": "1930-05-11",
        "nepDate": "29.01.1987"
    },
    {
        "engDate": "1930-05-12",
        "nepDate": "30.01.1987"
    },
    {
        "engDate": "1930-05-13",
        "nepDate": "31.01.1987"
    },
    {
        "engDate": "1930-05-14",
        "nepDate": "01.02.1987"
    },
    {
        "engDate": "1930-05-15",
        "nepDate": "02.02.1987"
    },
    {
        "engDate": "1930-05-16",
        "nepDate": "03.02.1987"
    },
    {
        "engDate": "1930-05-17",
        "nepDate": "04.02.1987"
    },
    {
        "engDate": "1930-05-18",
        "nepDate": "05.02.1987"
    },
    {
        "engDate": "1930-05-19",
        "nepDate": "06.02.1987"
    },
    {
        "engDate": "1930-05-20",
        "nepDate": "07.02.1987"
    },
    {
        "engDate": "1930-05-21",
        "nepDate": "08.02.1987"
    },
    {
        "engDate": "1930-05-22",
        "nepDate": "09.02.1987"
    },
    {
        "engDate": "1930-05-23",
        "nepDate": "10.02.1987"
    },
    {
        "engDate": "1930-05-24",
        "nepDate": "11.02.1987"
    },
    {
        "engDate": "1930-05-25",
        "nepDate": "12.02.1987"
    },
    {
        "engDate": "1930-05-26",
        "nepDate": "13.02.1987"
    },
    {
        "engDate": "1930-05-27",
        "nepDate": "14.02.1987"
    },
    {
        "engDate": "1930-05-28",
        "nepDate": "15.02.1987"
    },
    {
        "engDate": "1930-05-29",
        "nepDate": "16.02.1987"
    },
    {
        "engDate": "1930-05-30",
        "nepDate": "17.02.1987"
    },
    {
        "engDate": "1930-05-31",
        "nepDate": "18.02.1987"
    },
    {
        "engDate": "1930-06-01",
        "nepDate": "19.02.1987"
    },
    {
        "engDate": "1930-06-02",
        "nepDate": "20.02.1987"
    },
    {
        "engDate": "1930-06-03",
        "nepDate": "21.02.1987"
    },
    {
        "engDate": "1930-06-04",
        "nepDate": "22.02.1987"
    },
    {
        "engDate": "1930-06-05",
        "nepDate": "23.02.1987"
    },
    {
        "engDate": "1930-06-06",
        "nepDate": "24.02.1987"
    },
    {
        "engDate": "1930-06-07",
        "nepDate": "25.02.1987"
    },
    {
        "engDate": "1930-06-08",
        "nepDate": "26.02.1987"
    },
    {
        "engDate": "1930-06-09",
        "nepDate": "27.02.1987"
    },
    {
        "engDate": "1930-06-10",
        "nepDate": "28.02.1987"
    },
    {
        "engDate": "1930-06-11",
        "nepDate": "29.02.1987"
    },
    {
        "engDate": "1930-06-12",
        "nepDate": "30.02.1987"
    },
    {
        "engDate": "1930-06-13",
        "nepDate": "31.02.1987"
    },
    {
        "engDate": "1930-06-14",
        "nepDate": "32.02.1987"
    },
    {
        "engDate": "1930-06-15",
        "nepDate": "01.03.1987"
    },
    {
        "engDate": "1930-06-16",
        "nepDate": "02.03.1987"
    },
    {
        "engDate": "1930-06-17",
        "nepDate": "03.03.1987"
    },
    {
        "engDate": "1930-06-18",
        "nepDate": "04.03.1987"
    },
    {
        "engDate": "1930-06-19",
        "nepDate": "05.03.1987"
    },
    {
        "engDate": "1930-06-20",
        "nepDate": "06.03.1987"
    },
    {
        "engDate": "1930-06-21",
        "nepDate": "07.03.1987"
    },
    {
        "engDate": "1930-06-22",
        "nepDate": "08.03.1987"
    },
    {
        "engDate": "1930-06-23",
        "nepDate": "09.03.1987"
    },
    {
        "engDate": "1930-06-24",
        "nepDate": "10.03.1987"
    },
    {
        "engDate": "1930-06-25",
        "nepDate": "11.03.1987"
    },
    {
        "engDate": "1930-06-26",
        "nepDate": "12.03.1987"
    },
    {
        "engDate": "1930-06-27",
        "nepDate": "13.03.1987"
    },
    {
        "engDate": "1930-06-28",
        "nepDate": "14.03.1987"
    },
    {
        "engDate": "1930-06-29",
        "nepDate": "15.03.1987"
    },
    {
        "engDate": "1930-06-30",
        "nepDate": "16.03.1987"
    },
    {
        "engDate": "1930-07-01",
        "nepDate": "17.03.1987"
    },
    {
        "engDate": "1930-07-02",
        "nepDate": "18.03.1987"
    },
    {
        "engDate": "1930-07-03",
        "nepDate": "19.03.1987"
    },
    {
        "engDate": "1930-07-04",
        "nepDate": "20.03.1987"
    },
    {
        "engDate": "1930-07-05",
        "nepDate": "21.03.1987"
    },
    {
        "engDate": "1930-07-06",
        "nepDate": "22.03.1987"
    },
    {
        "engDate": "1930-07-07",
        "nepDate": "23.03.1987"
    },
    {
        "engDate": "1930-07-08",
        "nepDate": "24.03.1987"
    },
    {
        "engDate": "1930-07-09",
        "nepDate": "25.03.1987"
    },
    {
        "engDate": "1930-07-10",
        "nepDate": "26.03.1987"
    },
    {
        "engDate": "1930-07-11",
        "nepDate": "27.03.1987"
    },
    {
        "engDate": "1930-07-12",
        "nepDate": "28.03.1987"
    },
    {
        "engDate": "1930-07-13",
        "nepDate": "29.03.1987"
    },
    {
        "engDate": "1930-07-14",
        "nepDate": "30.03.1987"
    },
    {
        "engDate": "1930-07-15",
        "nepDate": "31.03.1987"
    },
    {
        "engDate": "1930-07-16",
        "nepDate": "01.04.1987"
    },
    {
        "engDate": "1930-07-17",
        "nepDate": "02.04.1987"
    },
    {
        "engDate": "1930-07-18",
        "nepDate": "03.04.1987"
    },
    {
        "engDate": "1930-07-19",
        "nepDate": "04.04.1987"
    },
    {
        "engDate": "1930-07-20",
        "nepDate": "05.04.1987"
    },
    {
        "engDate": "1930-07-21",
        "nepDate": "06.04.1987"
    },
    {
        "engDate": "1930-07-22",
        "nepDate": "07.04.1987"
    },
    {
        "engDate": "1930-07-23",
        "nepDate": "08.04.1987"
    },
    {
        "engDate": "1930-07-24",
        "nepDate": "09.04.1987"
    },
    {
        "engDate": "1930-07-25",
        "nepDate": "10.04.1987"
    },
    {
        "engDate": "1930-07-26",
        "nepDate": "11.04.1987"
    },
    {
        "engDate": "1930-07-27",
        "nepDate": "12.04.1987"
    },
    {
        "engDate": "1930-07-28",
        "nepDate": "13.04.1987"
    },
    {
        "engDate": "1930-07-29",
        "nepDate": "14.04.1987"
    },
    {
        "engDate": "1930-07-30",
        "nepDate": "15.04.1987"
    },
    {
        "engDate": "1930-07-31",
        "nepDate": "16.04.1987"
    },
    {
        "engDate": "1930-08-01",
        "nepDate": "17.04.1987"
    },
    {
        "engDate": "1930-08-02",
        "nepDate": "18.04.1987"
    },
    {
        "engDate": "1930-08-03",
        "nepDate": "19.04.1987"
    },
    {
        "engDate": "1930-08-04",
        "nepDate": "20.04.1987"
    },
    {
        "engDate": "1930-08-05",
        "nepDate": "21.04.1987"
    },
    {
        "engDate": "1930-08-06",
        "nepDate": "22.04.1987"
    },
    {
        "engDate": "1930-08-07",
        "nepDate": "23.04.1987"
    },
    {
        "engDate": "1930-08-08",
        "nepDate": "24.04.1987"
    },
    {
        "engDate": "1930-08-09",
        "nepDate": "25.04.1987"
    },
    {
        "engDate": "1930-08-10",
        "nepDate": "26.04.1987"
    },
    {
        "engDate": "1930-08-11",
        "nepDate": "27.04.1987"
    },
    {
        "engDate": "1930-08-12",
        "nepDate": "28.04.1987"
    },
    {
        "engDate": "1930-08-13",
        "nepDate": "29.04.1987"
    },
    {
        "engDate": "1930-08-14",
        "nepDate": "30.04.1987"
    },
    {
        "engDate": "1930-08-15",
        "nepDate": "31.04.1987"
    },
    {
        "engDate": "1930-08-16",
        "nepDate": "32.04.1987"
    },
    {
        "engDate": "1930-08-17",
        "nepDate": "01.05.1987"
    },
    {
        "engDate": "1930-08-18",
        "nepDate": "02.05.1987"
    },
    {
        "engDate": "1930-08-19",
        "nepDate": "03.05.1987"
    },
    {
        "engDate": "1930-08-20",
        "nepDate": "04.05.1987"
    },
    {
        "engDate": "1930-08-21",
        "nepDate": "05.05.1987"
    },
    {
        "engDate": "1930-08-22",
        "nepDate": "06.05.1987"
    },
    {
        "engDate": "1930-08-23",
        "nepDate": "07.05.1987"
    },
    {
        "engDate": "1930-08-24",
        "nepDate": "08.05.1987"
    },
    {
        "engDate": "1930-08-25",
        "nepDate": "09.05.1987"
    },
    {
        "engDate": "1930-08-26",
        "nepDate": "10.05.1987"
    },
    {
        "engDate": "1930-08-27",
        "nepDate": "11.05.1987"
    },
    {
        "engDate": "1930-08-28",
        "nepDate": "12.05.1987"
    },
    {
        "engDate": "1930-08-29",
        "nepDate": "13.05.1987"
    },
    {
        "engDate": "1930-08-30",
        "nepDate": "14.05.1987"
    },
    {
        "engDate": "1930-08-31",
        "nepDate": "15.05.1987"
    },
    {
        "engDate": "1930-09-01",
        "nepDate": "16.05.1987"
    },
    {
        "engDate": "1930-09-02",
        "nepDate": "17.05.1987"
    },
    {
        "engDate": "1930-09-03",
        "nepDate": "18.05.1987"
    },
    {
        "engDate": "1930-09-04",
        "nepDate": "19.05.1987"
    },
    {
        "engDate": "1930-09-05",
        "nepDate": "20.05.1987"
    },
    {
        "engDate": "1930-09-06",
        "nepDate": "21.05.1987"
    },
    {
        "engDate": "1930-09-07",
        "nepDate": "22.05.1987"
    },
    {
        "engDate": "1930-09-08",
        "nepDate": "23.05.1987"
    },
    {
        "engDate": "1930-09-09",
        "nepDate": "24.05.1987"
    },
    {
        "engDate": "1930-09-10",
        "nepDate": "25.05.1987"
    },
    {
        "engDate": "1930-09-11",
        "nepDate": "26.05.1987"
    },
    {
        "engDate": "1930-09-12",
        "nepDate": "27.05.1987"
    },
    {
        "engDate": "1930-09-13",
        "nepDate": "28.05.1987"
    },
    {
        "engDate": "1930-09-14",
        "nepDate": "29.05.1987"
    },
    {
        "engDate": "1930-09-15",
        "nepDate": "30.05.1987"
    },
    {
        "engDate": "1930-09-16",
        "nepDate": "31.05.1987"
    },
    {
        "engDate": "1930-09-17",
        "nepDate": "01.06.1987"
    },
    {
        "engDate": "1930-09-18",
        "nepDate": "02.06.1987"
    },
    {
        "engDate": "1930-09-19",
        "nepDate": "03.06.1987"
    },
    {
        "engDate": "1930-09-20",
        "nepDate": "04.06.1987"
    },
    {
        "engDate": "1930-09-21",
        "nepDate": "05.06.1987"
    },
    {
        "engDate": "1930-09-22",
        "nepDate": "06.06.1987"
    },
    {
        "engDate": "1930-09-23",
        "nepDate": "07.06.1987"
    },
    {
        "engDate": "1930-09-24",
        "nepDate": "08.06.1987"
    },
    {
        "engDate": "1930-09-25",
        "nepDate": "09.06.1987"
    },
    {
        "engDate": "1930-09-26",
        "nepDate": "10.06.1987"
    },
    {
        "engDate": "1930-09-27",
        "nepDate": "11.06.1987"
    },
    {
        "engDate": "1930-09-28",
        "nepDate": "12.06.1987"
    },
    {
        "engDate": "1930-09-29",
        "nepDate": "13.06.1987"
    },
    {
        "engDate": "1930-09-30",
        "nepDate": "14.06.1987"
    },
    {
        "engDate": "1930-10-01",
        "nepDate": "15.06.1987"
    },
    {
        "engDate": "1930-10-02",
        "nepDate": "16.06.1987"
    },
    {
        "engDate": "1930-10-03",
        "nepDate": "17.06.1987"
    },
    {
        "engDate": "1930-10-04",
        "nepDate": "18.06.1987"
    },
    {
        "engDate": "1930-10-05",
        "nepDate": "19.06.1987"
    },
    {
        "engDate": "1930-10-06",
        "nepDate": "20.06.1987"
    },
    {
        "engDate": "1930-10-07",
        "nepDate": "21.06.1987"
    },
    {
        "engDate": "1930-10-08",
        "nepDate": "22.06.1987"
    },
    {
        "engDate": "1930-10-09",
        "nepDate": "23.06.1987"
    },
    {
        "engDate": "1930-10-10",
        "nepDate": "24.06.1987"
    },
    {
        "engDate": "1930-10-11",
        "nepDate": "25.06.1987"
    },
    {
        "engDate": "1930-10-12",
        "nepDate": "26.06.1987"
    },
    {
        "engDate": "1930-10-13",
        "nepDate": "27.06.1987"
    },
    {
        "engDate": "1930-10-14",
        "nepDate": "28.06.1987"
    },
    {
        "engDate": "1930-10-15",
        "nepDate": "29.06.1987"
    },
    {
        "engDate": "1930-10-16",
        "nepDate": "30.06.1987"
    },
    {
        "engDate": "1930-10-17",
        "nepDate": "01.07.1987"
    },
    {
        "engDate": "1930-10-18",
        "nepDate": "02.07.1987"
    },
    {
        "engDate": "1930-10-19",
        "nepDate": "03.07.1987"
    },
    {
        "engDate": "1930-10-20",
        "nepDate": "04.07.1987"
    },
    {
        "engDate": "1930-10-21",
        "nepDate": "05.07.1987"
    },
    {
        "engDate": "1930-10-22",
        "nepDate": "06.07.1987"
    },
    {
        "engDate": "1930-10-23",
        "nepDate": "07.07.1987"
    },
    {
        "engDate": "1930-10-24",
        "nepDate": "08.07.1987"
    },
    {
        "engDate": "1930-10-25",
        "nepDate": "09.07.1987"
    },
    {
        "engDate": "1930-10-26",
        "nepDate": "10.07.1987"
    },
    {
        "engDate": "1930-10-27",
        "nepDate": "11.07.1987"
    },
    {
        "engDate": "1930-10-28",
        "nepDate": "12.07.1987"
    },
    {
        "engDate": "1930-10-29",
        "nepDate": "13.07.1987"
    },
    {
        "engDate": "1930-10-30",
        "nepDate": "14.07.1987"
    },
    {
        "engDate": "1930-10-31",
        "nepDate": "15.07.1987"
    },
    {
        "engDate": "1930-11-01",
        "nepDate": "16.07.1987"
    },
    {
        "engDate": "1930-11-02",
        "nepDate": "17.07.1987"
    },
    {
        "engDate": "1930-11-03",
        "nepDate": "18.07.1987"
    },
    {
        "engDate": "1930-11-04",
        "nepDate": "19.07.1987"
    },
    {
        "engDate": "1930-11-05",
        "nepDate": "20.07.1987"
    },
    {
        "engDate": "1930-11-06",
        "nepDate": "21.07.1987"
    },
    {
        "engDate": "1930-11-07",
        "nepDate": "22.07.1987"
    },
    {
        "engDate": "1930-11-08",
        "nepDate": "23.07.1987"
    },
    {
        "engDate": "1930-11-09",
        "nepDate": "24.07.1987"
    },
    {
        "engDate": "1930-11-10",
        "nepDate": "25.07.1987"
    },
    {
        "engDate": "1930-11-11",
        "nepDate": "26.07.1987"
    },
    {
        "engDate": "1930-11-12",
        "nepDate": "27.07.1987"
    },
    {
        "engDate": "1930-11-13",
        "nepDate": "28.07.1987"
    },
    {
        "engDate": "1930-11-14",
        "nepDate": "29.07.1987"
    },
    {
        "engDate": "1930-11-15",
        "nepDate": "30.07.1987"
    },
    {
        "engDate": "1930-11-16",
        "nepDate": "01.08.1987"
    },
    {
        "engDate": "1930-11-17",
        "nepDate": "02.08.1987"
    },
    {
        "engDate": "1930-11-18",
        "nepDate": "03.08.1987"
    },
    {
        "engDate": "1930-11-19",
        "nepDate": "04.08.1987"
    },
    {
        "engDate": "1930-11-20",
        "nepDate": "05.08.1987"
    },
    {
        "engDate": "1930-11-21",
        "nepDate": "06.08.1987"
    },
    {
        "engDate": "1930-11-22",
        "nepDate": "07.08.1987"
    },
    {
        "engDate": "1930-11-23",
        "nepDate": "08.08.1987"
    },
    {
        "engDate": "1930-11-24",
        "nepDate": "09.08.1987"
    },
    {
        "engDate": "1930-11-25",
        "nepDate": "10.08.1987"
    },
    {
        "engDate": "1930-11-26",
        "nepDate": "11.08.1987"
    },
    {
        "engDate": "1930-11-27",
        "nepDate": "12.08.1987"
    },
    {
        "engDate": "1930-11-28",
        "nepDate": "13.08.1987"
    },
    {
        "engDate": "1930-11-29",
        "nepDate": "14.08.1987"
    },
    {
        "engDate": "1930-11-30",
        "nepDate": "15.08.1987"
    },
    {
        "engDate": "1930-12-01",
        "nepDate": "16.08.1987"
    },
    {
        "engDate": "1930-12-02",
        "nepDate": "17.08.1987"
    },
    {
        "engDate": "1930-12-03",
        "nepDate": "18.08.1987"
    },
    {
        "engDate": "1930-12-04",
        "nepDate": "19.08.1987"
    },
    {
        "engDate": "1930-12-05",
        "nepDate": "20.08.1987"
    },
    {
        "engDate": "1930-12-06",
        "nepDate": "21.08.1987"
    },
    {
        "engDate": "1930-12-07",
        "nepDate": "22.08.1987"
    },
    {
        "engDate": "1930-12-08",
        "nepDate": "23.08.1987"
    },
    {
        "engDate": "1930-12-09",
        "nepDate": "24.08.1987"
    },
    {
        "engDate": "1930-12-10",
        "nepDate": "25.08.1987"
    },
    {
        "engDate": "1930-12-11",
        "nepDate": "26.08.1987"
    },
    {
        "engDate": "1930-12-12",
        "nepDate": "27.08.1987"
    },
    {
        "engDate": "1930-12-13",
        "nepDate": "28.08.1987"
    },
    {
        "engDate": "1930-12-14",
        "nepDate": "29.08.1987"
    },
    {
        "engDate": "1930-12-15",
        "nepDate": "01.09.1987"
    },
    {
        "engDate": "1930-12-16",
        "nepDate": "02.09.1987"
    },
    {
        "engDate": "1930-12-17",
        "nepDate": "03.09.1987"
    },
    {
        "engDate": "1930-12-18",
        "nepDate": "04.09.1987"
    },
    {
        "engDate": "1930-12-19",
        "nepDate": "05.09.1987"
    },
    {
        "engDate": "1930-12-20",
        "nepDate": "06.09.1987"
    },
    {
        "engDate": "1930-12-21",
        "nepDate": "07.09.1987"
    },
    {
        "engDate": "1930-12-22",
        "nepDate": "08.09.1987"
    },
    {
        "engDate": "1930-12-23",
        "nepDate": "09.09.1987"
    },
    {
        "engDate": "1930-12-24",
        "nepDate": "10.09.1987"
    },
    {
        "engDate": "1930-12-25",
        "nepDate": "11.09.1987"
    },
    {
        "engDate": "1930-12-26",
        "nepDate": "12.09.1987"
    },
    {
        "engDate": "1930-12-27",
        "nepDate": "13.09.1987"
    },
    {
        "engDate": "1930-12-28",
        "nepDate": "14.09.1987"
    },
    {
        "engDate": "1930-12-29",
        "nepDate": "15.09.1987"
    },
    {
        "engDate": "1930-12-30",
        "nepDate": "16.09.1987"
    },
    {
        "engDate": "1930-12-31",
        "nepDate": "17.09.1987"
    },
    {
        "engDate": "1931-01-01",
        "nepDate": "18.09.1987"
    },
    {
        "engDate": "1931-01-02",
        "nepDate": "19.09.1987"
    },
    {
        "engDate": "1931-01-03",
        "nepDate": "20.09.1987"
    },
    {
        "engDate": "1931-01-04",
        "nepDate": "21.09.1987"
    },
    {
        "engDate": "1931-01-05",
        "nepDate": "22.09.1987"
    },
    {
        "engDate": "1931-01-06",
        "nepDate": "23.09.1987"
    },
    {
        "engDate": "1931-01-07",
        "nepDate": "24.09.1987"
    },
    {
        "engDate": "1931-01-08",
        "nepDate": "25.09.1987"
    },
    {
        "engDate": "1931-01-09",
        "nepDate": "26.09.1987"
    },
    {
        "engDate": "1931-01-10",
        "nepDate": "27.09.1987"
    },
    {
        "engDate": "1931-01-11",
        "nepDate": "28.09.1987"
    },
    {
        "engDate": "1931-01-12",
        "nepDate": "29.09.1987"
    },
    {
        "engDate": "1931-01-13",
        "nepDate": "30.09.1987"
    },
    {
        "engDate": "1931-01-14",
        "nepDate": "01.10.1987"
    },
    {
        "engDate": "1931-01-15",
        "nepDate": "02.10.1987"
    },
    {
        "engDate": "1931-01-16",
        "nepDate": "03.10.1987"
    },
    {
        "engDate": "1931-01-17",
        "nepDate": "04.10.1987"
    },
    {
        "engDate": "1931-01-18",
        "nepDate": "05.10.1987"
    },
    {
        "engDate": "1931-01-19",
        "nepDate": "06.10.1987"
    },
    {
        "engDate": "1931-01-20",
        "nepDate": "07.10.1987"
    },
    {
        "engDate": "1931-01-21",
        "nepDate": "08.10.1987"
    },
    {
        "engDate": "1931-01-22",
        "nepDate": "09.10.1987"
    },
    {
        "engDate": "1931-01-23",
        "nepDate": "10.10.1987"
    },
    {
        "engDate": "1931-01-24",
        "nepDate": "11.10.1987"
    },
    {
        "engDate": "1931-01-25",
        "nepDate": "12.10.1987"
    },
    {
        "engDate": "1931-01-26",
        "nepDate": "13.10.1987"
    },
    {
        "engDate": "1931-01-27",
        "nepDate": "14.10.1987"
    },
    {
        "engDate": "1931-01-28",
        "nepDate": "15.10.1987"
    },
    {
        "engDate": "1931-01-29",
        "nepDate": "16.10.1987"
    },
    {
        "engDate": "1931-01-30",
        "nepDate": "17.10.1987"
    },
    {
        "engDate": "1931-01-31",
        "nepDate": "18.10.1987"
    },
    {
        "engDate": "1931-02-01",
        "nepDate": "19.10.1987"
    },
    {
        "engDate": "1931-02-02",
        "nepDate": "20.10.1987"
    },
    {
        "engDate": "1931-02-03",
        "nepDate": "21.10.1987"
    },
    {
        "engDate": "1931-02-04",
        "nepDate": "22.10.1987"
    },
    {
        "engDate": "1931-02-05",
        "nepDate": "23.10.1987"
    },
    {
        "engDate": "1931-02-06",
        "nepDate": "24.10.1987"
    },
    {
        "engDate": "1931-02-07",
        "nepDate": "25.10.1987"
    },
    {
        "engDate": "1931-02-08",
        "nepDate": "26.10.1987"
    },
    {
        "engDate": "1931-02-09",
        "nepDate": "27.10.1987"
    },
    {
        "engDate": "1931-02-10",
        "nepDate": "28.10.1987"
    },
    {
        "engDate": "1931-02-11",
        "nepDate": "29.10.1987"
    },
    {
        "engDate": "1931-02-12",
        "nepDate": "01.11.1987"
    },
    {
        "engDate": "1931-02-13",
        "nepDate": "02.11.1987"
    },
    {
        "engDate": "1931-02-14",
        "nepDate": "03.11.1987"
    },
    {
        "engDate": "1931-02-15",
        "nepDate": "04.11.1987"
    },
    {
        "engDate": "1931-02-16",
        "nepDate": "05.11.1987"
    },
    {
        "engDate": "1931-02-17",
        "nepDate": "06.11.1987"
    },
    {
        "engDate": "1931-02-18",
        "nepDate": "07.11.1987"
    },
    {
        "engDate": "1931-02-19",
        "nepDate": "08.11.1987"
    },
    {
        "engDate": "1931-02-20",
        "nepDate": "09.11.1987"
    },
    {
        "engDate": "1931-02-21",
        "nepDate": "10.11.1987"
    },
    {
        "engDate": "1931-02-22",
        "nepDate": "11.11.1987"
    },
    {
        "engDate": "1931-02-23",
        "nepDate": "12.11.1987"
    },
    {
        "engDate": "1931-02-24",
        "nepDate": "13.11.1987"
    },
    {
        "engDate": "1931-02-25",
        "nepDate": "14.11.1987"
    },
    {
        "engDate": "1931-02-26",
        "nepDate": "15.11.1987"
    },
    {
        "engDate": "1931-02-27",
        "nepDate": "16.11.1987"
    },
    {
        "engDate": "1931-02-28",
        "nepDate": "17.11.1987"
    },
    {
        "engDate": "1931-03-01",
        "nepDate": "18.11.1987"
    },
    {
        "engDate": "1931-03-02",
        "nepDate": "19.11.1987"
    },
    {
        "engDate": "1931-03-03",
        "nepDate": "20.11.1987"
    },
    {
        "engDate": "1931-03-04",
        "nepDate": "21.11.1987"
    },
    {
        "engDate": "1931-03-05",
        "nepDate": "22.11.1987"
    },
    {
        "engDate": "1931-03-06",
        "nepDate": "23.11.1987"
    },
    {
        "engDate": "1931-03-07",
        "nepDate": "24.11.1987"
    },
    {
        "engDate": "1931-03-08",
        "nepDate": "25.11.1987"
    },
    {
        "engDate": "1931-03-09",
        "nepDate": "26.11.1987"
    },
    {
        "engDate": "1931-03-10",
        "nepDate": "27.11.1987"
    },
    {
        "engDate": "1931-03-11",
        "nepDate": "28.11.1987"
    },
    {
        "engDate": "1931-03-12",
        "nepDate": "29.11.1987"
    },
    {
        "engDate": "1931-03-13",
        "nepDate": "30.11.1987"
    },
    {
        "engDate": "1931-03-14",
        "nepDate": "01.12.1987"
    },
    {
        "engDate": "1931-03-15",
        "nepDate": "02.12.1987"
    },
    {
        "engDate": "1931-03-16",
        "nepDate": "03.12.1987"
    },
    {
        "engDate": "1931-03-17",
        "nepDate": "04.12.1987"
    },
    {
        "engDate": "1931-03-18",
        "nepDate": "05.12.1987"
    },
    {
        "engDate": "1931-03-19",
        "nepDate": "06.12.1987"
    },
    {
        "engDate": "1931-03-20",
        "nepDate": "07.12.1987"
    },
    {
        "engDate": "1931-03-21",
        "nepDate": "08.12.1987"
    },
    {
        "engDate": "1931-03-22",
        "nepDate": "09.12.1987"
    },
    {
        "engDate": "1931-03-23",
        "nepDate": "10.12.1987"
    },
    {
        "engDate": "1931-03-24",
        "nepDate": "11.12.1987"
    },
    {
        "engDate": "1931-03-25",
        "nepDate": "12.12.1987"
    },
    {
        "engDate": "1931-03-26",
        "nepDate": "13.12.1987"
    },
    {
        "engDate": "1931-03-27",
        "nepDate": "14.12.1987"
    },
    {
        "engDate": "1931-03-28",
        "nepDate": "15.12.1987"
    },
    {
        "engDate": "1931-03-29",
        "nepDate": "16.12.1987"
    },
    {
        "engDate": "1931-03-30",
        "nepDate": "17.12.1987"
    },
    {
        "engDate": "1931-03-31",
        "nepDate": "18.12.1987"
    },
    {
        "engDate": "1931-04-01",
        "nepDate": "19.12.1987"
    },
    {
        "engDate": "1931-04-02",
        "nepDate": "20.12.1987"
    },
    {
        "engDate": "1931-04-03",
        "nepDate": "21.12.1987"
    },
    {
        "engDate": "1931-04-04",
        "nepDate": "22.12.1987"
    },
    {
        "engDate": "1931-04-05",
        "nepDate": "23.12.1987"
    },
    {
        "engDate": "1931-04-06",
        "nepDate": "24.12.1987"
    },
    {
        "engDate": "1931-04-07",
        "nepDate": "25.12.1987"
    },
    {
        "engDate": "1931-04-08",
        "nepDate": "26.12.1987"
    },
    {
        "engDate": "1931-04-09",
        "nepDate": "27.12.1987"
    },
    {
        "engDate": "1931-04-10",
        "nepDate": "28.12.1987"
    },
    {
        "engDate": "1931-04-11",
        "nepDate": "29.12.1987"
    },
    {
        "engDate": "1931-04-12",
        "nepDate": "30.12.1987"
    },
    {
        "engDate": "1931-04-13",
        "nepDate": "01.01.1988"
    },
    {
        "engDate": "1931-04-14",
        "nepDate": "02.01.1988"
    },
    {
        "engDate": "1931-04-15",
        "nepDate": "03.01.1988"
    },
    {
        "engDate": "1931-04-16",
        "nepDate": "04.01.1988"
    },
    {
        "engDate": "1931-04-17",
        "nepDate": "05.01.1988"
    },
    {
        "engDate": "1931-04-18",
        "nepDate": "06.01.1988"
    },
    {
        "engDate": "1931-04-19",
        "nepDate": "07.01.1988"
    },
    {
        "engDate": "1931-04-20",
        "nepDate": "08.01.1988"
    },
    {
        "engDate": "1931-04-21",
        "nepDate": "09.01.1988"
    },
    {
        "engDate": "1931-04-22",
        "nepDate": "10.01.1988"
    },
    {
        "engDate": "1931-04-23",
        "nepDate": "11.01.1988"
    },
    {
        "engDate": "1931-04-24",
        "nepDate": "12.01.1988"
    },
    {
        "engDate": "1931-04-25",
        "nepDate": "13.01.1988"
    },
    {
        "engDate": "1931-04-26",
        "nepDate": "14.01.1988"
    },
    {
        "engDate": "1931-04-27",
        "nepDate": "15.01.1988"
    },
    {
        "engDate": "1931-04-28",
        "nepDate": "16.01.1988"
    },
    {
        "engDate": "1931-04-29",
        "nepDate": "17.01.1988"
    },
    {
        "engDate": "1931-04-30",
        "nepDate": "18.01.1988"
    },
    {
        "engDate": "1931-05-01",
        "nepDate": "19.01.1988"
    },
    {
        "engDate": "1931-05-02",
        "nepDate": "20.01.1988"
    },
    {
        "engDate": "1931-05-03",
        "nepDate": "21.01.1988"
    },
    {
        "engDate": "1931-05-04",
        "nepDate": "22.01.1988"
    },
    {
        "engDate": "1931-05-05",
        "nepDate": "23.01.1988"
    },
    {
        "engDate": "1931-05-06",
        "nepDate": "24.01.1988"
    },
    {
        "engDate": "1931-05-07",
        "nepDate": "25.01.1988"
    },
    {
        "engDate": "1931-05-08",
        "nepDate": "26.01.1988"
    },
    {
        "engDate": "1931-05-09",
        "nepDate": "27.01.1988"
    },
    {
        "engDate": "1931-05-10",
        "nepDate": "28.01.1988"
    },
    {
        "engDate": "1931-05-11",
        "nepDate": "29.01.1988"
    },
    {
        "engDate": "1931-05-12",
        "nepDate": "30.01.1988"
    },
    {
        "engDate": "1931-05-13",
        "nepDate": "31.01.1988"
    },
    {
        "engDate": "1931-05-14",
        "nepDate": "01.02.1988"
    },
    {
        "engDate": "1931-05-15",
        "nepDate": "02.02.1988"
    },
    {
        "engDate": "1931-05-16",
        "nepDate": "03.02.1988"
    },
    {
        "engDate": "1931-05-17",
        "nepDate": "04.02.1988"
    },
    {
        "engDate": "1931-05-18",
        "nepDate": "05.02.1988"
    },
    {
        "engDate": "1931-05-19",
        "nepDate": "06.02.1988"
    },
    {
        "engDate": "1931-05-20",
        "nepDate": "07.02.1988"
    },
    {
        "engDate": "1931-05-21",
        "nepDate": "08.02.1988"
    },
    {
        "engDate": "1931-05-22",
        "nepDate": "09.02.1988"
    },
    {
        "engDate": "1931-05-23",
        "nepDate": "10.02.1988"
    },
    {
        "engDate": "1931-05-24",
        "nepDate": "11.02.1988"
    },
    {
        "engDate": "1931-05-25",
        "nepDate": "12.02.1988"
    },
    {
        "engDate": "1931-05-26",
        "nepDate": "13.02.1988"
    },
    {
        "engDate": "1931-05-27",
        "nepDate": "14.02.1988"
    },
    {
        "engDate": "1931-05-28",
        "nepDate": "15.02.1988"
    },
    {
        "engDate": "1931-05-29",
        "nepDate": "16.02.1988"
    },
    {
        "engDate": "1931-05-30",
        "nepDate": "17.02.1988"
    },
    {
        "engDate": "1931-05-31",
        "nepDate": "18.02.1988"
    },
    {
        "engDate": "1931-06-01",
        "nepDate": "19.02.1988"
    },
    {
        "engDate": "1931-06-02",
        "nepDate": "20.02.1988"
    },
    {
        "engDate": "1931-06-03",
        "nepDate": "21.02.1988"
    },
    {
        "engDate": "1931-06-04",
        "nepDate": "22.02.1988"
    },
    {
        "engDate": "1931-06-05",
        "nepDate": "23.02.1988"
    },
    {
        "engDate": "1931-06-06",
        "nepDate": "24.02.1988"
    },
    {
        "engDate": "1931-06-07",
        "nepDate": "25.02.1988"
    },
    {
        "engDate": "1931-06-08",
        "nepDate": "26.02.1988"
    },
    {
        "engDate": "1931-06-09",
        "nepDate": "27.02.1988"
    },
    {
        "engDate": "1931-06-10",
        "nepDate": "28.02.1988"
    },
    {
        "engDate": "1931-06-11",
        "nepDate": "29.02.1988"
    },
    {
        "engDate": "1931-06-12",
        "nepDate": "30.02.1988"
    },
    {
        "engDate": "1931-06-13",
        "nepDate": "31.02.1988"
    },
    {
        "engDate": "1931-06-14",
        "nepDate": "32.02.1988"
    },
    {
        "engDate": "1931-06-15",
        "nepDate": "01.03.1988"
    },
    {
        "engDate": "1931-06-16",
        "nepDate": "02.03.1988"
    },
    {
        "engDate": "1931-06-17",
        "nepDate": "03.03.1988"
    },
    {
        "engDate": "1931-06-18",
        "nepDate": "04.03.1988"
    },
    {
        "engDate": "1931-06-19",
        "nepDate": "05.03.1988"
    },
    {
        "engDate": "1931-06-20",
        "nepDate": "06.03.1988"
    },
    {
        "engDate": "1931-06-21",
        "nepDate": "07.03.1988"
    },
    {
        "engDate": "1931-06-22",
        "nepDate": "08.03.1988"
    },
    {
        "engDate": "1931-06-23",
        "nepDate": "09.03.1988"
    },
    {
        "engDate": "1931-06-24",
        "nepDate": "10.03.1988"
    },
    {
        "engDate": "1931-06-25",
        "nepDate": "11.03.1988"
    },
    {
        "engDate": "1931-06-26",
        "nepDate": "12.03.1988"
    },
    {
        "engDate": "1931-06-27",
        "nepDate": "13.03.1988"
    },
    {
        "engDate": "1931-06-28",
        "nepDate": "14.03.1988"
    },
    {
        "engDate": "1931-06-29",
        "nepDate": "15.03.1988"
    },
    {
        "engDate": "1931-06-30",
        "nepDate": "16.03.1988"
    },
    {
        "engDate": "1931-07-01",
        "nepDate": "17.03.1988"
    },
    {
        "engDate": "1931-07-02",
        "nepDate": "18.03.1988"
    },
    {
        "engDate": "1931-07-03",
        "nepDate": "19.03.1988"
    },
    {
        "engDate": "1931-07-04",
        "nepDate": "20.03.1988"
    },
    {
        "engDate": "1931-07-05",
        "nepDate": "21.03.1988"
    },
    {
        "engDate": "1931-07-06",
        "nepDate": "22.03.1988"
    },
    {
        "engDate": "1931-07-07",
        "nepDate": "23.03.1988"
    },
    {
        "engDate": "1931-07-08",
        "nepDate": "24.03.1988"
    },
    {
        "engDate": "1931-07-09",
        "nepDate": "25.03.1988"
    },
    {
        "engDate": "1931-07-10",
        "nepDate": "26.03.1988"
    },
    {
        "engDate": "1931-07-11",
        "nepDate": "27.03.1988"
    },
    {
        "engDate": "1931-07-12",
        "nepDate": "28.03.1988"
    },
    {
        "engDate": "1931-07-13",
        "nepDate": "29.03.1988"
    },
    {
        "engDate": "1931-07-14",
        "nepDate": "30.03.1988"
    },
    {
        "engDate": "1931-07-15",
        "nepDate": "31.03.1988"
    },
    {
        "engDate": "1931-07-16",
        "nepDate": "01.04.1988"
    },
    {
        "engDate": "1931-07-17",
        "nepDate": "02.04.1988"
    },
    {
        "engDate": "1931-07-18",
        "nepDate": "03.04.1988"
    },
    {
        "engDate": "1931-07-19",
        "nepDate": "04.04.1988"
    },
    {
        "engDate": "1931-07-20",
        "nepDate": "05.04.1988"
    },
    {
        "engDate": "1931-07-21",
        "nepDate": "06.04.1988"
    },
    {
        "engDate": "1931-07-22",
        "nepDate": "07.04.1988"
    },
    {
        "engDate": "1931-07-23",
        "nepDate": "08.04.1988"
    },
    {
        "engDate": "1931-07-24",
        "nepDate": "09.04.1988"
    },
    {
        "engDate": "1931-07-25",
        "nepDate": "10.04.1988"
    },
    {
        "engDate": "1931-07-26",
        "nepDate": "11.04.1988"
    },
    {
        "engDate": "1931-07-27",
        "nepDate": "12.04.1988"
    },
    {
        "engDate": "1931-07-28",
        "nepDate": "13.04.1988"
    },
    {
        "engDate": "1931-07-29",
        "nepDate": "14.04.1988"
    },
    {
        "engDate": "1931-07-30",
        "nepDate": "15.04.1988"
    },
    {
        "engDate": "1931-07-31",
        "nepDate": "16.04.1988"
    },
    {
        "engDate": "1931-08-01",
        "nepDate": "17.04.1988"
    },
    {
        "engDate": "1931-08-02",
        "nepDate": "18.04.1988"
    },
    {
        "engDate": "1931-08-03",
        "nepDate": "19.04.1988"
    },
    {
        "engDate": "1931-08-04",
        "nepDate": "20.04.1988"
    },
    {
        "engDate": "1931-08-05",
        "nepDate": "21.04.1988"
    },
    {
        "engDate": "1931-08-06",
        "nepDate": "22.04.1988"
    },
    {
        "engDate": "1931-08-07",
        "nepDate": "23.04.1988"
    },
    {
        "engDate": "1931-08-08",
        "nepDate": "24.04.1988"
    },
    {
        "engDate": "1931-08-09",
        "nepDate": "25.04.1988"
    },
    {
        "engDate": "1931-08-10",
        "nepDate": "26.04.1988"
    },
    {
        "engDate": "1931-08-11",
        "nepDate": "27.04.1988"
    },
    {
        "engDate": "1931-08-12",
        "nepDate": "28.04.1988"
    },
    {
        "engDate": "1931-08-13",
        "nepDate": "29.04.1988"
    },
    {
        "engDate": "1931-08-14",
        "nepDate": "30.04.1988"
    },
    {
        "engDate": "1931-08-15",
        "nepDate": "31.04.1988"
    },
    {
        "engDate": "1931-08-16",
        "nepDate": "32.04.1988"
    },
    {
        "engDate": "1931-08-17",
        "nepDate": "01.05.1988"
    },
    {
        "engDate": "1931-08-18",
        "nepDate": "02.05.1988"
    },
    {
        "engDate": "1931-08-19",
        "nepDate": "03.05.1988"
    },
    {
        "engDate": "1931-08-20",
        "nepDate": "04.05.1988"
    },
    {
        "engDate": "1931-08-21",
        "nepDate": "05.05.1988"
    },
    {
        "engDate": "1931-08-22",
        "nepDate": "06.05.1988"
    },
    {
        "engDate": "1931-08-23",
        "nepDate": "07.05.1988"
    },
    {
        "engDate": "1931-08-24",
        "nepDate": "08.05.1988"
    },
    {
        "engDate": "1931-08-25",
        "nepDate": "09.05.1988"
    },
    {
        "engDate": "1931-08-26",
        "nepDate": "10.05.1988"
    },
    {
        "engDate": "1931-08-27",
        "nepDate": "11.05.1988"
    },
    {
        "engDate": "1931-08-28",
        "nepDate": "12.05.1988"
    },
    {
        "engDate": "1931-08-29",
        "nepDate": "13.05.1988"
    },
    {
        "engDate": "1931-08-30",
        "nepDate": "14.05.1988"
    },
    {
        "engDate": "1931-08-31",
        "nepDate": "15.05.1988"
    },
    {
        "engDate": "1931-09-01",
        "nepDate": "16.05.1988"
    },
    {
        "engDate": "1931-09-02",
        "nepDate": "17.05.1988"
    },
    {
        "engDate": "1931-09-03",
        "nepDate": "18.05.1988"
    },
    {
        "engDate": "1931-09-04",
        "nepDate": "19.05.1988"
    },
    {
        "engDate": "1931-09-05",
        "nepDate": "20.05.1988"
    },
    {
        "engDate": "1931-09-06",
        "nepDate": "21.05.1988"
    },
    {
        "engDate": "1931-09-07",
        "nepDate": "22.05.1988"
    },
    {
        "engDate": "1931-09-08",
        "nepDate": "23.05.1988"
    },
    {
        "engDate": "1931-09-09",
        "nepDate": "24.05.1988"
    },
    {
        "engDate": "1931-09-10",
        "nepDate": "25.05.1988"
    },
    {
        "engDate": "1931-09-11",
        "nepDate": "26.05.1988"
    },
    {
        "engDate": "1931-09-12",
        "nepDate": "27.05.1988"
    },
    {
        "engDate": "1931-09-13",
        "nepDate": "28.05.1988"
    },
    {
        "engDate": "1931-09-14",
        "nepDate": "29.05.1988"
    },
    {
        "engDate": "1931-09-15",
        "nepDate": "30.05.1988"
    },
    {
        "engDate": "1931-09-16",
        "nepDate": "31.05.1988"
    },
    {
        "engDate": "1931-09-17",
        "nepDate": "01.06.1988"
    },
    {
        "engDate": "1931-09-18",
        "nepDate": "02.06.1988"
    },
    {
        "engDate": "1931-09-19",
        "nepDate": "03.06.1988"
    },
    {
        "engDate": "1931-09-20",
        "nepDate": "04.06.1988"
    },
    {
        "engDate": "1931-09-21",
        "nepDate": "05.06.1988"
    },
    {
        "engDate": "1931-09-22",
        "nepDate": "06.06.1988"
    },
    {
        "engDate": "1931-09-23",
        "nepDate": "07.06.1988"
    },
    {
        "engDate": "1931-09-24",
        "nepDate": "08.06.1988"
    },
    {
        "engDate": "1931-09-25",
        "nepDate": "09.06.1988"
    },
    {
        "engDate": "1931-09-26",
        "nepDate": "10.06.1988"
    },
    {
        "engDate": "1931-09-27",
        "nepDate": "11.06.1988"
    },
    {
        "engDate": "1931-09-28",
        "nepDate": "12.06.1988"
    },
    {
        "engDate": "1931-09-29",
        "nepDate": "13.06.1988"
    },
    {
        "engDate": "1931-09-30",
        "nepDate": "14.06.1988"
    },
    {
        "engDate": "1931-10-01",
        "nepDate": "15.06.1988"
    },
    {
        "engDate": "1931-10-02",
        "nepDate": "16.06.1988"
    },
    {
        "engDate": "1931-10-03",
        "nepDate": "17.06.1988"
    },
    {
        "engDate": "1931-10-04",
        "nepDate": "18.06.1988"
    },
    {
        "engDate": "1931-10-05",
        "nepDate": "19.06.1988"
    },
    {
        "engDate": "1931-10-06",
        "nepDate": "20.06.1988"
    },
    {
        "engDate": "1931-10-07",
        "nepDate": "21.06.1988"
    },
    {
        "engDate": "1931-10-08",
        "nepDate": "22.06.1988"
    },
    {
        "engDate": "1931-10-09",
        "nepDate": "23.06.1988"
    },
    {
        "engDate": "1931-10-10",
        "nepDate": "24.06.1988"
    },
    {
        "engDate": "1931-10-11",
        "nepDate": "25.06.1988"
    },
    {
        "engDate": "1931-10-12",
        "nepDate": "26.06.1988"
    },
    {
        "engDate": "1931-10-13",
        "nepDate": "27.06.1988"
    },
    {
        "engDate": "1931-10-14",
        "nepDate": "28.06.1988"
    },
    {
        "engDate": "1931-10-15",
        "nepDate": "29.06.1988"
    },
    {
        "engDate": "1931-10-16",
        "nepDate": "30.06.1988"
    },
    {
        "engDate": "1931-10-17",
        "nepDate": "01.07.1988"
    },
    {
        "engDate": "1931-10-18",
        "nepDate": "02.07.1988"
    },
    {
        "engDate": "1931-10-19",
        "nepDate": "03.07.1988"
    },
    {
        "engDate": "1931-10-20",
        "nepDate": "04.07.1988"
    },
    {
        "engDate": "1931-10-21",
        "nepDate": "05.07.1988"
    },
    {
        "engDate": "1931-10-22",
        "nepDate": "06.07.1988"
    },
    {
        "engDate": "1931-10-23",
        "nepDate": "07.07.1988"
    },
    {
        "engDate": "1931-10-24",
        "nepDate": "08.07.1988"
    },
    {
        "engDate": "1931-10-25",
        "nepDate": "09.07.1988"
    },
    {
        "engDate": "1931-10-26",
        "nepDate": "10.07.1988"
    },
    {
        "engDate": "1931-10-27",
        "nepDate": "11.07.1988"
    },
    {
        "engDate": "1931-10-28",
        "nepDate": "12.07.1988"
    },
    {
        "engDate": "1931-10-29",
        "nepDate": "13.07.1988"
    },
    {
        "engDate": "1931-10-30",
        "nepDate": "14.07.1988"
    },
    {
        "engDate": "1931-10-31",
        "nepDate": "15.07.1988"
    },
    {
        "engDate": "1931-11-01",
        "nepDate": "16.07.1988"
    },
    {
        "engDate": "1931-11-02",
        "nepDate": "17.07.1988"
    },
    {
        "engDate": "1931-11-03",
        "nepDate": "18.07.1988"
    },
    {
        "engDate": "1931-11-04",
        "nepDate": "19.07.1988"
    },
    {
        "engDate": "1931-11-05",
        "nepDate": "20.07.1988"
    },
    {
        "engDate": "1931-11-06",
        "nepDate": "21.07.1988"
    },
    {
        "engDate": "1931-11-07",
        "nepDate": "22.07.1988"
    },
    {
        "engDate": "1931-11-08",
        "nepDate": "23.07.1988"
    },
    {
        "engDate": "1931-11-09",
        "nepDate": "24.07.1988"
    },
    {
        "engDate": "1931-11-10",
        "nepDate": "25.07.1988"
    },
    {
        "engDate": "1931-11-11",
        "nepDate": "26.07.1988"
    },
    {
        "engDate": "1931-11-12",
        "nepDate": "27.07.1988"
    },
    {
        "engDate": "1931-11-13",
        "nepDate": "28.07.1988"
    },
    {
        "engDate": "1931-11-14",
        "nepDate": "29.07.1988"
    },
    {
        "engDate": "1931-11-15",
        "nepDate": "30.07.1988"
    },
    {
        "engDate": "1931-11-16",
        "nepDate": "01.08.1988"
    },
    {
        "engDate": "1931-11-17",
        "nepDate": "02.08.1988"
    },
    {
        "engDate": "1931-11-18",
        "nepDate": "03.08.1988"
    },
    {
        "engDate": "1931-11-19",
        "nepDate": "04.08.1988"
    },
    {
        "engDate": "1931-11-20",
        "nepDate": "05.08.1988"
    },
    {
        "engDate": "1931-11-21",
        "nepDate": "06.08.1988"
    },
    {
        "engDate": "1931-11-22",
        "nepDate": "07.08.1988"
    },
    {
        "engDate": "1931-11-23",
        "nepDate": "08.08.1988"
    },
    {
        "engDate": "1931-11-24",
        "nepDate": "09.08.1988"
    },
    {
        "engDate": "1931-11-25",
        "nepDate": "10.08.1988"
    },
    {
        "engDate": "1931-11-26",
        "nepDate": "11.08.1988"
    },
    {
        "engDate": "1931-11-27",
        "nepDate": "12.08.1988"
    },
    {
        "engDate": "1931-11-28",
        "nepDate": "13.08.1988"
    },
    {
        "engDate": "1931-11-29",
        "nepDate": "14.08.1988"
    },
    {
        "engDate": "1931-11-30",
        "nepDate": "15.08.1988"
    },
    {
        "engDate": "1931-12-01",
        "nepDate": "16.08.1988"
    },
    {
        "engDate": "1931-12-02",
        "nepDate": "17.08.1988"
    },
    {
        "engDate": "1931-12-03",
        "nepDate": "18.08.1988"
    },
    {
        "engDate": "1931-12-04",
        "nepDate": "19.08.1988"
    },
    {
        "engDate": "1931-12-05",
        "nepDate": "20.08.1988"
    },
    {
        "engDate": "1931-12-06",
        "nepDate": "21.08.1988"
    },
    {
        "engDate": "1931-12-07",
        "nepDate": "22.08.1988"
    },
    {
        "engDate": "1931-12-08",
        "nepDate": "23.08.1988"
    },
    {
        "engDate": "1931-12-09",
        "nepDate": "24.08.1988"
    },
    {
        "engDate": "1931-12-10",
        "nepDate": "25.08.1988"
    },
    {
        "engDate": "1931-12-11",
        "nepDate": "26.08.1988"
    },
    {
        "engDate": "1931-12-12",
        "nepDate": "27.08.1988"
    },
    {
        "engDate": "1931-12-13",
        "nepDate": "28.08.1988"
    },
    {
        "engDate": "1931-12-14",
        "nepDate": "29.08.1988"
    },
    {
        "engDate": "1931-12-15",
        "nepDate": "30.08.1988"
    },
    {
        "engDate": "1931-12-16",
        "nepDate": "01.09.1988"
    },
    {
        "engDate": "1931-12-17",
        "nepDate": "02.09.1988"
    },
    {
        "engDate": "1931-12-18",
        "nepDate": "03.09.1988"
    },
    {
        "engDate": "1931-12-19",
        "nepDate": "04.09.1988"
    },
    {
        "engDate": "1931-12-20",
        "nepDate": "05.09.1988"
    },
    {
        "engDate": "1931-12-21",
        "nepDate": "06.09.1988"
    },
    {
        "engDate": "1931-12-22",
        "nepDate": "07.09.1988"
    },
    {
        "engDate": "1931-12-23",
        "nepDate": "08.09.1988"
    },
    {
        "engDate": "1931-12-24",
        "nepDate": "09.09.1988"
    },
    {
        "engDate": "1931-12-25",
        "nepDate": "10.09.1988"
    },
    {
        "engDate": "1931-12-26",
        "nepDate": "11.09.1988"
    },
    {
        "engDate": "1931-12-27",
        "nepDate": "12.09.1988"
    },
    {
        "engDate": "1931-12-28",
        "nepDate": "13.09.1988"
    },
    {
        "engDate": "1931-12-29",
        "nepDate": "14.09.1988"
    },
    {
        "engDate": "1931-12-30",
        "nepDate": "15.09.1988"
    },
    {
        "engDate": "1931-12-31",
        "nepDate": "16.09.1988"
    },
    {
        "engDate": "1932-01-01",
        "nepDate": "17.09.1988"
    },
    {
        "engDate": "1932-01-02",
        "nepDate": "18.09.1988"
    },
    {
        "engDate": "1932-01-03",
        "nepDate": "19.09.1988"
    },
    {
        "engDate": "1932-01-04",
        "nepDate": "20.09.1988"
    },
    {
        "engDate": "1932-01-05",
        "nepDate": "21.09.1988"
    },
    {
        "engDate": "1932-01-06",
        "nepDate": "22.09.1988"
    },
    {
        "engDate": "1932-01-07",
        "nepDate": "23.09.1988"
    },
    {
        "engDate": "1932-01-08",
        "nepDate": "24.09.1988"
    },
    {
        "engDate": "1932-01-09",
        "nepDate": "25.09.1988"
    },
    {
        "engDate": "1932-01-10",
        "nepDate": "26.09.1988"
    },
    {
        "engDate": "1932-01-11",
        "nepDate": "27.09.1988"
    },
    {
        "engDate": "1932-01-12",
        "nepDate": "28.09.1988"
    },
    {
        "engDate": "1932-01-13",
        "nepDate": "29.09.1988"
    },
    {
        "engDate": "1932-01-14",
        "nepDate": "01.10.1988"
    },
    {
        "engDate": "1932-01-15",
        "nepDate": "02.10.1988"
    },
    {
        "engDate": "1932-01-16",
        "nepDate": "03.10.1988"
    },
    {
        "engDate": "1932-01-17",
        "nepDate": "04.10.1988"
    },
    {
        "engDate": "1932-01-18",
        "nepDate": "05.10.1988"
    },
    {
        "engDate": "1932-01-19",
        "nepDate": "06.10.1988"
    },
    {
        "engDate": "1932-01-20",
        "nepDate": "07.10.1988"
    },
    {
        "engDate": "1932-01-21",
        "nepDate": "08.10.1988"
    },
    {
        "engDate": "1932-01-22",
        "nepDate": "09.10.1988"
    },
    {
        "engDate": "1932-01-23",
        "nepDate": "10.10.1988"
    },
    {
        "engDate": "1932-01-24",
        "nepDate": "11.10.1988"
    },
    {
        "engDate": "1932-01-25",
        "nepDate": "12.10.1988"
    },
    {
        "engDate": "1932-01-26",
        "nepDate": "13.10.1988"
    },
    {
        "engDate": "1932-01-27",
        "nepDate": "14.10.1988"
    },
    {
        "engDate": "1932-01-28",
        "nepDate": "15.10.1988"
    },
    {
        "engDate": "1932-01-29",
        "nepDate": "16.10.1988"
    },
    {
        "engDate": "1932-01-30",
        "nepDate": "17.10.1988"
    },
    {
        "engDate": "1932-01-31",
        "nepDate": "18.10.1988"
    },
    {
        "engDate": "1932-02-01",
        "nepDate": "19.10.1988"
    },
    {
        "engDate": "1932-02-02",
        "nepDate": "20.10.1988"
    },
    {
        "engDate": "1932-02-03",
        "nepDate": "21.10.1988"
    },
    {
        "engDate": "1932-02-04",
        "nepDate": "22.10.1988"
    },
    {
        "engDate": "1932-02-05",
        "nepDate": "23.10.1988"
    },
    {
        "engDate": "1932-02-06",
        "nepDate": "24.10.1988"
    },
    {
        "engDate": "1932-02-07",
        "nepDate": "25.10.1988"
    },
    {
        "engDate": "1932-02-08",
        "nepDate": "26.10.1988"
    },
    {
        "engDate": "1932-02-09",
        "nepDate": "27.10.1988"
    },
    {
        "engDate": "1932-02-10",
        "nepDate": "28.10.1988"
    },
    {
        "engDate": "1932-02-11",
        "nepDate": "29.10.1988"
    },
    {
        "engDate": "1932-02-12",
        "nepDate": "01.11.1988"
    },
    {
        "engDate": "1932-02-13",
        "nepDate": "02.11.1988"
    },
    {
        "engDate": "1932-02-14",
        "nepDate": "03.11.1988"
    },
    {
        "engDate": "1932-02-15",
        "nepDate": "04.11.1988"
    },
    {
        "engDate": "1932-02-16",
        "nepDate": "05.11.1988"
    },
    {
        "engDate": "1932-02-17",
        "nepDate": "06.11.1988"
    },
    {
        "engDate": "1932-02-18",
        "nepDate": "07.11.1988"
    },
    {
        "engDate": "1932-02-19",
        "nepDate": "08.11.1988"
    },
    {
        "engDate": "1932-02-20",
        "nepDate": "09.11.1988"
    },
    {
        "engDate": "1932-02-21",
        "nepDate": "10.11.1988"
    },
    {
        "engDate": "1932-02-22",
        "nepDate": "11.11.1988"
    },
    {
        "engDate": "1932-02-23",
        "nepDate": "12.11.1988"
    },
    {
        "engDate": "1932-02-24",
        "nepDate": "13.11.1988"
    },
    {
        "engDate": "1932-02-25",
        "nepDate": "14.11.1988"
    },
    {
        "engDate": "1932-02-26",
        "nepDate": "15.11.1988"
    },
    {
        "engDate": "1932-02-27",
        "nepDate": "16.11.1988"
    },
    {
        "engDate": "1932-02-28",
        "nepDate": "17.11.1988"
    },
    {
        "engDate": "1932-02-29",
        "nepDate": "18.11.1988"
    },
    {
        "engDate": "1932-03-01",
        "nepDate": "19.11.1988"
    },
    {
        "engDate": "1932-03-02",
        "nepDate": "20.11.1988"
    },
    {
        "engDate": "1932-03-03",
        "nepDate": "21.11.1988"
    },
    {
        "engDate": "1932-03-04",
        "nepDate": "22.11.1988"
    },
    {
        "engDate": "1932-03-05",
        "nepDate": "23.11.1988"
    },
    {
        "engDate": "1932-03-06",
        "nepDate": "24.11.1988"
    },
    {
        "engDate": "1932-03-07",
        "nepDate": "25.11.1988"
    },
    {
        "engDate": "1932-03-08",
        "nepDate": "26.11.1988"
    },
    {
        "engDate": "1932-03-09",
        "nepDate": "27.11.1988"
    },
    {
        "engDate": "1932-03-10",
        "nepDate": "28.11.1988"
    },
    {
        "engDate": "1932-03-11",
        "nepDate": "29.11.1988"
    },
    {
        "engDate": "1932-03-12",
        "nepDate": "30.11.1988"
    },
    {
        "engDate": "1932-03-13",
        "nepDate": "01.12.1988"
    },
    {
        "engDate": "1932-03-14",
        "nepDate": "02.12.1988"
    },
    {
        "engDate": "1932-03-15",
        "nepDate": "03.12.1988"
    },
    {
        "engDate": "1932-03-16",
        "nepDate": "04.12.1988"
    },
    {
        "engDate": "1932-03-17",
        "nepDate": "05.12.1988"
    },
    {
        "engDate": "1932-03-18",
        "nepDate": "06.12.1988"
    },
    {
        "engDate": "1932-03-19",
        "nepDate": "07.12.1988"
    },
    {
        "engDate": "1932-03-20",
        "nepDate": "08.12.1988"
    },
    {
        "engDate": "1932-03-21",
        "nepDate": "09.12.1988"
    },
    {
        "engDate": "1932-03-22",
        "nepDate": "10.12.1988"
    },
    {
        "engDate": "1932-03-23",
        "nepDate": "11.12.1988"
    },
    {
        "engDate": "1932-03-24",
        "nepDate": "12.12.1988"
    },
    {
        "engDate": "1932-03-25",
        "nepDate": "13.12.1988"
    },
    {
        "engDate": "1932-03-26",
        "nepDate": "14.12.1988"
    },
    {
        "engDate": "1932-03-27",
        "nepDate": "15.12.1988"
    },
    {
        "engDate": "1932-03-28",
        "nepDate": "16.12.1988"
    },
    {
        "engDate": "1932-03-29",
        "nepDate": "17.12.1988"
    },
    {
        "engDate": "1932-03-30",
        "nepDate": "18.12.1988"
    },
    {
        "engDate": "1932-03-31",
        "nepDate": "19.12.1988"
    },
    {
        "engDate": "1932-04-01",
        "nepDate": "20.12.1988"
    },
    {
        "engDate": "1932-04-02",
        "nepDate": "21.12.1988"
    },
    {
        "engDate": "1932-04-03",
        "nepDate": "22.12.1988"
    },
    {
        "engDate": "1932-04-04",
        "nepDate": "23.12.1988"
    },
    {
        "engDate": "1932-04-05",
        "nepDate": "24.12.1988"
    },
    {
        "engDate": "1932-04-06",
        "nepDate": "25.12.1988"
    },
    {
        "engDate": "1932-04-07",
        "nepDate": "26.12.1988"
    },
    {
        "engDate": "1932-04-08",
        "nepDate": "27.12.1988"
    },
    {
        "engDate": "1932-04-09",
        "nepDate": "28.12.1988"
    },
    {
        "engDate": "1932-04-10",
        "nepDate": "29.12.1988"
    },
    {
        "engDate": "1932-04-11",
        "nepDate": "30.12.1988"
    },
    {
        "engDate": "1932-04-12",
        "nepDate": "31.12.1988"
    },
    {
        "engDate": "1932-04-13",
        "nepDate": "01.01.1989"
    },
    {
        "engDate": "1932-04-14",
        "nepDate": "02.01.1989"
    },
    {
        "engDate": "1932-04-15",
        "nepDate": "03.01.1989"
    },
    {
        "engDate": "1932-04-16",
        "nepDate": "04.01.1989"
    },
    {
        "engDate": "1932-04-17",
        "nepDate": "05.01.1989"
    },
    {
        "engDate": "1932-04-18",
        "nepDate": "06.01.1989"
    },
    {
        "engDate": "1932-04-19",
        "nepDate": "07.01.1989"
    },
    {
        "engDate": "1932-04-20",
        "nepDate": "08.01.1989"
    },
    {
        "engDate": "1932-04-21",
        "nepDate": "09.01.1989"
    },
    {
        "engDate": "1932-04-22",
        "nepDate": "10.01.1989"
    },
    {
        "engDate": "1932-04-23",
        "nepDate": "11.01.1989"
    },
    {
        "engDate": "1932-04-24",
        "nepDate": "12.01.1989"
    },
    {
        "engDate": "1932-04-25",
        "nepDate": "13.01.1989"
    },
    {
        "engDate": "1932-04-26",
        "nepDate": "14.01.1989"
    },
    {
        "engDate": "1932-04-27",
        "nepDate": "15.01.1989"
    },
    {
        "engDate": "1932-04-28",
        "nepDate": "16.01.1989"
    },
    {
        "engDate": "1932-04-29",
        "nepDate": "17.01.1989"
    },
    {
        "engDate": "1932-04-30",
        "nepDate": "18.01.1989"
    },
    {
        "engDate": "1932-05-01",
        "nepDate": "19.01.1989"
    },
    {
        "engDate": "1932-05-02",
        "nepDate": "20.01.1989"
    },
    {
        "engDate": "1932-05-03",
        "nepDate": "21.01.1989"
    },
    {
        "engDate": "1932-05-04",
        "nepDate": "22.01.1989"
    },
    {
        "engDate": "1932-05-05",
        "nepDate": "23.01.1989"
    },
    {
        "engDate": "1932-05-06",
        "nepDate": "24.01.1989"
    },
    {
        "engDate": "1932-05-07",
        "nepDate": "25.01.1989"
    },
    {
        "engDate": "1932-05-08",
        "nepDate": "26.01.1989"
    },
    {
        "engDate": "1932-05-09",
        "nepDate": "27.01.1989"
    },
    {
        "engDate": "1932-05-10",
        "nepDate": "28.01.1989"
    },
    {
        "engDate": "1932-05-11",
        "nepDate": "29.01.1989"
    },
    {
        "engDate": "1932-05-12",
        "nepDate": "30.01.1989"
    },
    {
        "engDate": "1932-05-13",
        "nepDate": "31.01.1989"
    },
    {
        "engDate": "1932-05-14",
        "nepDate": "01.02.1989"
    },
    {
        "engDate": "1932-05-15",
        "nepDate": "02.02.1989"
    },
    {
        "engDate": "1932-05-16",
        "nepDate": "03.02.1989"
    },
    {
        "engDate": "1932-05-17",
        "nepDate": "04.02.1989"
    },
    {
        "engDate": "1932-05-18",
        "nepDate": "05.02.1989"
    },
    {
        "engDate": "1932-05-19",
        "nepDate": "06.02.1989"
    },
    {
        "engDate": "1932-05-20",
        "nepDate": "07.02.1989"
    },
    {
        "engDate": "1932-05-21",
        "nepDate": "08.02.1989"
    },
    {
        "engDate": "1932-05-22",
        "nepDate": "09.02.1989"
    },
    {
        "engDate": "1932-05-23",
        "nepDate": "10.02.1989"
    },
    {
        "engDate": "1932-05-24",
        "nepDate": "11.02.1989"
    },
    {
        "engDate": "1932-05-25",
        "nepDate": "12.02.1989"
    },
    {
        "engDate": "1932-05-26",
        "nepDate": "13.02.1989"
    },
    {
        "engDate": "1932-05-27",
        "nepDate": "14.02.1989"
    },
    {
        "engDate": "1932-05-28",
        "nepDate": "15.02.1989"
    },
    {
        "engDate": "1932-05-29",
        "nepDate": "16.02.1989"
    },
    {
        "engDate": "1932-05-30",
        "nepDate": "17.02.1989"
    },
    {
        "engDate": "1932-05-31",
        "nepDate": "18.02.1989"
    },
    {
        "engDate": "1932-06-01",
        "nepDate": "19.02.1989"
    },
    {
        "engDate": "1932-06-02",
        "nepDate": "20.02.1989"
    },
    {
        "engDate": "1932-06-03",
        "nepDate": "21.02.1989"
    },
    {
        "engDate": "1932-06-04",
        "nepDate": "22.02.1989"
    },
    {
        "engDate": "1932-06-05",
        "nepDate": "23.02.1989"
    },
    {
        "engDate": "1932-06-06",
        "nepDate": "24.02.1989"
    },
    {
        "engDate": "1932-06-07",
        "nepDate": "25.02.1989"
    },
    {
        "engDate": "1932-06-08",
        "nepDate": "26.02.1989"
    },
    {
        "engDate": "1932-06-09",
        "nepDate": "27.02.1989"
    },
    {
        "engDate": "1932-06-10",
        "nepDate": "28.02.1989"
    },
    {
        "engDate": "1932-06-11",
        "nepDate": "29.02.1989"
    },
    {
        "engDate": "1932-06-12",
        "nepDate": "30.02.1989"
    },
    {
        "engDate": "1932-06-13",
        "nepDate": "31.02.1989"
    },
    {
        "engDate": "1932-06-14",
        "nepDate": "01.03.1989"
    },
    {
        "engDate": "1932-06-15",
        "nepDate": "02.03.1989"
    },
    {
        "engDate": "1932-06-16",
        "nepDate": "03.03.1989"
    },
    {
        "engDate": "1932-06-17",
        "nepDate": "04.03.1989"
    },
    {
        "engDate": "1932-06-18",
        "nepDate": "05.03.1989"
    },
    {
        "engDate": "1932-06-19",
        "nepDate": "06.03.1989"
    },
    {
        "engDate": "1932-06-20",
        "nepDate": "07.03.1989"
    },
    {
        "engDate": "1932-06-21",
        "nepDate": "08.03.1989"
    },
    {
        "engDate": "1932-06-22",
        "nepDate": "09.03.1989"
    },
    {
        "engDate": "1932-06-23",
        "nepDate": "10.03.1989"
    },
    {
        "engDate": "1932-06-24",
        "nepDate": "11.03.1989"
    },
    {
        "engDate": "1932-06-25",
        "nepDate": "12.03.1989"
    },
    {
        "engDate": "1932-06-26",
        "nepDate": "13.03.1989"
    },
    {
        "engDate": "1932-06-27",
        "nepDate": "14.03.1989"
    },
    {
        "engDate": "1932-06-28",
        "nepDate": "15.03.1989"
    },
    {
        "engDate": "1932-06-29",
        "nepDate": "16.03.1989"
    },
    {
        "engDate": "1932-06-30",
        "nepDate": "17.03.1989"
    },
    {
        "engDate": "1932-07-01",
        "nepDate": "18.03.1989"
    },
    {
        "engDate": "1932-07-02",
        "nepDate": "19.03.1989"
    },
    {
        "engDate": "1932-07-03",
        "nepDate": "20.03.1989"
    },
    {
        "engDate": "1932-07-04",
        "nepDate": "21.03.1989"
    },
    {
        "engDate": "1932-07-05",
        "nepDate": "22.03.1989"
    },
    {
        "engDate": "1932-07-06",
        "nepDate": "23.03.1989"
    },
    {
        "engDate": "1932-07-07",
        "nepDate": "24.03.1989"
    },
    {
        "engDate": "1932-07-08",
        "nepDate": "25.03.1989"
    },
    {
        "engDate": "1932-07-09",
        "nepDate": "26.03.1989"
    },
    {
        "engDate": "1932-07-10",
        "nepDate": "27.03.1989"
    },
    {
        "engDate": "1932-07-11",
        "nepDate": "28.03.1989"
    },
    {
        "engDate": "1932-07-12",
        "nepDate": "29.03.1989"
    },
    {
        "engDate": "1932-07-13",
        "nepDate": "30.03.1989"
    },
    {
        "engDate": "1932-07-14",
        "nepDate": "31.03.1989"
    },
    {
        "engDate": "1932-07-15",
        "nepDate": "01.04.1989"
    },
    {
        "engDate": "1932-07-16",
        "nepDate": "02.04.1989"
    },
    {
        "engDate": "1932-07-17",
        "nepDate": "03.04.1989"
    },
    {
        "engDate": "1932-07-18",
        "nepDate": "04.04.1989"
    },
    {
        "engDate": "1932-07-19",
        "nepDate": "05.04.1989"
    },
    {
        "engDate": "1932-07-20",
        "nepDate": "06.04.1989"
    },
    {
        "engDate": "1932-07-21",
        "nepDate": "07.04.1989"
    },
    {
        "engDate": "1932-07-22",
        "nepDate": "08.04.1989"
    },
    {
        "engDate": "1932-07-23",
        "nepDate": "09.04.1989"
    },
    {
        "engDate": "1932-07-24",
        "nepDate": "10.04.1989"
    },
    {
        "engDate": "1932-07-25",
        "nepDate": "11.04.1989"
    },
    {
        "engDate": "1932-07-26",
        "nepDate": "12.04.1989"
    },
    {
        "engDate": "1932-07-27",
        "nepDate": "13.04.1989"
    },
    {
        "engDate": "1932-07-28",
        "nepDate": "14.04.1989"
    },
    {
        "engDate": "1932-07-29",
        "nepDate": "15.04.1989"
    },
    {
        "engDate": "1932-07-30",
        "nepDate": "16.04.1989"
    },
    {
        "engDate": "1932-07-31",
        "nepDate": "17.04.1989"
    },
    {
        "engDate": "1932-08-01",
        "nepDate": "18.04.1989"
    },
    {
        "engDate": "1932-08-02",
        "nepDate": "19.04.1989"
    },
    {
        "engDate": "1932-08-03",
        "nepDate": "20.04.1989"
    },
    {
        "engDate": "1932-08-04",
        "nepDate": "21.04.1989"
    },
    {
        "engDate": "1932-08-05",
        "nepDate": "22.04.1989"
    },
    {
        "engDate": "1932-08-06",
        "nepDate": "23.04.1989"
    },
    {
        "engDate": "1932-08-07",
        "nepDate": "24.04.1989"
    },
    {
        "engDate": "1932-08-08",
        "nepDate": "25.04.1989"
    },
    {
        "engDate": "1932-08-09",
        "nepDate": "26.04.1989"
    },
    {
        "engDate": "1932-08-10",
        "nepDate": "27.04.1989"
    },
    {
        "engDate": "1932-08-11",
        "nepDate": "28.04.1989"
    },
    {
        "engDate": "1932-08-12",
        "nepDate": "29.04.1989"
    },
    {
        "engDate": "1932-08-13",
        "nepDate": "30.04.1989"
    },
    {
        "engDate": "1932-08-14",
        "nepDate": "31.04.1989"
    },
    {
        "engDate": "1932-08-15",
        "nepDate": "32.04.1989"
    },
    {
        "engDate": "1932-08-16",
        "nepDate": "01.05.1989"
    },
    {
        "engDate": "1932-08-17",
        "nepDate": "02.05.1989"
    },
    {
        "engDate": "1932-08-18",
        "nepDate": "03.05.1989"
    },
    {
        "engDate": "1932-08-19",
        "nepDate": "04.05.1989"
    },
    {
        "engDate": "1932-08-20",
        "nepDate": "05.05.1989"
    },
    {
        "engDate": "1932-08-21",
        "nepDate": "06.05.1989"
    },
    {
        "engDate": "1932-08-22",
        "nepDate": "07.05.1989"
    },
    {
        "engDate": "1932-08-23",
        "nepDate": "08.05.1989"
    },
    {
        "engDate": "1932-08-24",
        "nepDate": "09.05.1989"
    },
    {
        "engDate": "1932-08-25",
        "nepDate": "10.05.1989"
    },
    {
        "engDate": "1932-08-26",
        "nepDate": "11.05.1989"
    },
    {
        "engDate": "1932-08-27",
        "nepDate": "12.05.1989"
    },
    {
        "engDate": "1932-08-28",
        "nepDate": "13.05.1989"
    },
    {
        "engDate": "1932-08-29",
        "nepDate": "14.05.1989"
    },
    {
        "engDate": "1932-08-30",
        "nepDate": "15.05.1989"
    },
    {
        "engDate": "1932-08-31",
        "nepDate": "16.05.1989"
    },
    {
        "engDate": "1932-09-01",
        "nepDate": "17.05.1989"
    },
    {
        "engDate": "1932-09-02",
        "nepDate": "18.05.1989"
    },
    {
        "engDate": "1932-09-03",
        "nepDate": "19.05.1989"
    },
    {
        "engDate": "1932-09-04",
        "nepDate": "20.05.1989"
    },
    {
        "engDate": "1932-09-05",
        "nepDate": "21.05.1989"
    },
    {
        "engDate": "1932-09-06",
        "nepDate": "22.05.1989"
    },
    {
        "engDate": "1932-09-07",
        "nepDate": "23.05.1989"
    },
    {
        "engDate": "1932-09-08",
        "nepDate": "24.05.1989"
    },
    {
        "engDate": "1932-09-09",
        "nepDate": "25.05.1989"
    },
    {
        "engDate": "1932-09-10",
        "nepDate": "26.05.1989"
    },
    {
        "engDate": "1932-09-11",
        "nepDate": "27.05.1989"
    },
    {
        "engDate": "1932-09-12",
        "nepDate": "28.05.1989"
    },
    {
        "engDate": "1932-09-13",
        "nepDate": "29.05.1989"
    },
    {
        "engDate": "1932-09-14",
        "nepDate": "30.05.1989"
    },
    {
        "engDate": "1932-09-15",
        "nepDate": "31.05.1989"
    },
    {
        "engDate": "1932-09-16",
        "nepDate": "01.06.1989"
    },
    {
        "engDate": "1932-09-17",
        "nepDate": "02.06.1989"
    },
    {
        "engDate": "1932-09-18",
        "nepDate": "03.06.1989"
    },
    {
        "engDate": "1932-09-19",
        "nepDate": "04.06.1989"
    },
    {
        "engDate": "1932-09-20",
        "nepDate": "05.06.1989"
    },
    {
        "engDate": "1932-09-21",
        "nepDate": "06.06.1989"
    },
    {
        "engDate": "1932-09-22",
        "nepDate": "07.06.1989"
    },
    {
        "engDate": "1932-09-23",
        "nepDate": "08.06.1989"
    },
    {
        "engDate": "1932-09-24",
        "nepDate": "09.06.1989"
    },
    {
        "engDate": "1932-09-25",
        "nepDate": "10.06.1989"
    },
    {
        "engDate": "1932-09-26",
        "nepDate": "11.06.1989"
    },
    {
        "engDate": "1932-09-27",
        "nepDate": "12.06.1989"
    },
    {
        "engDate": "1932-09-28",
        "nepDate": "13.06.1989"
    },
    {
        "engDate": "1932-09-29",
        "nepDate": "14.06.1989"
    },
    {
        "engDate": "1932-09-30",
        "nepDate": "15.06.1989"
    },
    {
        "engDate": "1932-10-01",
        "nepDate": "16.06.1989"
    },
    {
        "engDate": "1932-10-02",
        "nepDate": "17.06.1989"
    },
    {
        "engDate": "1932-10-03",
        "nepDate": "18.06.1989"
    },
    {
        "engDate": "1932-10-04",
        "nepDate": "19.06.1989"
    },
    {
        "engDate": "1932-10-05",
        "nepDate": "20.06.1989"
    },
    {
        "engDate": "1932-10-06",
        "nepDate": "21.06.1989"
    },
    {
        "engDate": "1932-10-07",
        "nepDate": "22.06.1989"
    },
    {
        "engDate": "1932-10-08",
        "nepDate": "23.06.1989"
    },
    {
        "engDate": "1932-10-09",
        "nepDate": "24.06.1989"
    },
    {
        "engDate": "1932-10-10",
        "nepDate": "25.06.1989"
    },
    {
        "engDate": "1932-10-11",
        "nepDate": "26.06.1989"
    },
    {
        "engDate": "1932-10-12",
        "nepDate": "27.06.1989"
    },
    {
        "engDate": "1932-10-13",
        "nepDate": "28.06.1989"
    },
    {
        "engDate": "1932-10-14",
        "nepDate": "29.06.1989"
    },
    {
        "engDate": "1932-10-15",
        "nepDate": "30.06.1989"
    },
    {
        "engDate": "1932-10-16",
        "nepDate": "31.06.1989"
    },
    {
        "engDate": "1932-10-17",
        "nepDate": "01.07.1989"
    },
    {
        "engDate": "1932-10-18",
        "nepDate": "02.07.1989"
    },
    {
        "engDate": "1932-10-19",
        "nepDate": "03.07.1989"
    },
    {
        "engDate": "1932-10-20",
        "nepDate": "04.07.1989"
    },
    {
        "engDate": "1932-10-21",
        "nepDate": "05.07.1989"
    },
    {
        "engDate": "1932-10-22",
        "nepDate": "06.07.1989"
    },
    {
        "engDate": "1932-10-23",
        "nepDate": "07.07.1989"
    },
    {
        "engDate": "1932-10-24",
        "nepDate": "08.07.1989"
    },
    {
        "engDate": "1932-10-25",
        "nepDate": "09.07.1989"
    },
    {
        "engDate": "1932-10-26",
        "nepDate": "10.07.1989"
    },
    {
        "engDate": "1932-10-27",
        "nepDate": "11.07.1989"
    },
    {
        "engDate": "1932-10-28",
        "nepDate": "12.07.1989"
    },
    {
        "engDate": "1932-10-29",
        "nepDate": "13.07.1989"
    },
    {
        "engDate": "1932-10-30",
        "nepDate": "14.07.1989"
    },
    {
        "engDate": "1932-10-31",
        "nepDate": "15.07.1989"
    },
    {
        "engDate": "1932-11-01",
        "nepDate": "16.07.1989"
    },
    {
        "engDate": "1932-11-02",
        "nepDate": "17.07.1989"
    },
    {
        "engDate": "1932-11-03",
        "nepDate": "18.07.1989"
    },
    {
        "engDate": "1932-11-04",
        "nepDate": "19.07.1989"
    },
    {
        "engDate": "1932-11-05",
        "nepDate": "20.07.1989"
    },
    {
        "engDate": "1932-11-06",
        "nepDate": "21.07.1989"
    },
    {
        "engDate": "1932-11-07",
        "nepDate": "22.07.1989"
    },
    {
        "engDate": "1932-11-08",
        "nepDate": "23.07.1989"
    },
    {
        "engDate": "1932-11-09",
        "nepDate": "24.07.1989"
    },
    {
        "engDate": "1932-11-10",
        "nepDate": "25.07.1989"
    },
    {
        "engDate": "1932-11-11",
        "nepDate": "26.07.1989"
    },
    {
        "engDate": "1932-11-12",
        "nepDate": "27.07.1989"
    },
    {
        "engDate": "1932-11-13",
        "nepDate": "28.07.1989"
    },
    {
        "engDate": "1932-11-14",
        "nepDate": "29.07.1989"
    },
    {
        "engDate": "1932-11-15",
        "nepDate": "01.08.1989"
    },
    {
        "engDate": "1932-11-16",
        "nepDate": "02.08.1989"
    },
    {
        "engDate": "1932-11-17",
        "nepDate": "03.08.1989"
    },
    {
        "engDate": "1932-11-18",
        "nepDate": "04.08.1989"
    },
    {
        "engDate": "1932-11-19",
        "nepDate": "05.08.1989"
    },
    {
        "engDate": "1932-11-20",
        "nepDate": "06.08.1989"
    },
    {
        "engDate": "1932-11-21",
        "nepDate": "07.08.1989"
    },
    {
        "engDate": "1932-11-22",
        "nepDate": "08.08.1989"
    },
    {
        "engDate": "1932-11-23",
        "nepDate": "09.08.1989"
    },
    {
        "engDate": "1932-11-24",
        "nepDate": "10.08.1989"
    },
    {
        "engDate": "1932-11-25",
        "nepDate": "11.08.1989"
    },
    {
        "engDate": "1932-11-26",
        "nepDate": "12.08.1989"
    },
    {
        "engDate": "1932-11-27",
        "nepDate": "13.08.1989"
    },
    {
        "engDate": "1932-11-28",
        "nepDate": "14.08.1989"
    },
    {
        "engDate": "1932-11-29",
        "nepDate": "15.08.1989"
    },
    {
        "engDate": "1932-11-30",
        "nepDate": "16.08.1989"
    },
    {
        "engDate": "1932-12-01",
        "nepDate": "17.08.1989"
    },
    {
        "engDate": "1932-12-02",
        "nepDate": "18.08.1989"
    },
    {
        "engDate": "1932-12-03",
        "nepDate": "19.08.1989"
    },
    {
        "engDate": "1932-12-04",
        "nepDate": "20.08.1989"
    },
    {
        "engDate": "1932-12-05",
        "nepDate": "21.08.1989"
    },
    {
        "engDate": "1932-12-06",
        "nepDate": "22.08.1989"
    },
    {
        "engDate": "1932-12-07",
        "nepDate": "23.08.1989"
    },
    {
        "engDate": "1932-12-08",
        "nepDate": "24.08.1989"
    },
    {
        "engDate": "1932-12-09",
        "nepDate": "25.08.1989"
    },
    {
        "engDate": "1932-12-10",
        "nepDate": "26.08.1989"
    },
    {
        "engDate": "1932-12-11",
        "nepDate": "27.08.1989"
    },
    {
        "engDate": "1932-12-12",
        "nepDate": "28.08.1989"
    },
    {
        "engDate": "1932-12-13",
        "nepDate": "29.08.1989"
    },
    {
        "engDate": "1932-12-14",
        "nepDate": "30.08.1989"
    },
    {
        "engDate": "1932-12-15",
        "nepDate": "01.09.1989"
    },
    {
        "engDate": "1932-12-16",
        "nepDate": "02.09.1989"
    },
    {
        "engDate": "1932-12-17",
        "nepDate": "03.09.1989"
    },
    {
        "engDate": "1932-12-18",
        "nepDate": "04.09.1989"
    },
    {
        "engDate": "1932-12-19",
        "nepDate": "05.09.1989"
    },
    {
        "engDate": "1932-12-20",
        "nepDate": "06.09.1989"
    },
    {
        "engDate": "1932-12-21",
        "nepDate": "07.09.1989"
    },
    {
        "engDate": "1932-12-22",
        "nepDate": "08.09.1989"
    },
    {
        "engDate": "1932-12-23",
        "nepDate": "09.09.1989"
    },
    {
        "engDate": "1932-12-24",
        "nepDate": "10.09.1989"
    },
    {
        "engDate": "1932-12-25",
        "nepDate": "11.09.1989"
    },
    {
        "engDate": "1932-12-26",
        "nepDate": "12.09.1989"
    },
    {
        "engDate": "1932-12-27",
        "nepDate": "13.09.1989"
    },
    {
        "engDate": "1932-12-28",
        "nepDate": "14.09.1989"
    },
    {
        "engDate": "1932-12-29",
        "nepDate": "15.09.1989"
    },
    {
        "engDate": "1932-12-30",
        "nepDate": "16.09.1989"
    },
    {
        "engDate": "1932-12-31",
        "nepDate": "17.09.1989"
    },
    {
        "engDate": "1933-01-01",
        "nepDate": "18.09.1989"
    },
    {
        "engDate": "1933-01-02",
        "nepDate": "19.09.1989"
    },
    {
        "engDate": "1933-01-03",
        "nepDate": "20.09.1989"
    },
    {
        "engDate": "1933-01-04",
        "nepDate": "21.09.1989"
    },
    {
        "engDate": "1933-01-05",
        "nepDate": "22.09.1989"
    },
    {
        "engDate": "1933-01-06",
        "nepDate": "23.09.1989"
    },
    {
        "engDate": "1933-01-07",
        "nepDate": "24.09.1989"
    },
    {
        "engDate": "1933-01-08",
        "nepDate": "25.09.1989"
    },
    {
        "engDate": "1933-01-09",
        "nepDate": "26.09.1989"
    },
    {
        "engDate": "1933-01-10",
        "nepDate": "27.09.1989"
    },
    {
        "engDate": "1933-01-11",
        "nepDate": "28.09.1989"
    },
    {
        "engDate": "1933-01-12",
        "nepDate": "29.09.1989"
    },
    {
        "engDate": "1933-01-13",
        "nepDate": "30.09.1989"
    },
    {
        "engDate": "1933-01-14",
        "nepDate": "01.10.1989"
    },
    {
        "engDate": "1933-01-15",
        "nepDate": "02.10.1989"
    },
    {
        "engDate": "1933-01-16",
        "nepDate": "03.10.1989"
    },
    {
        "engDate": "1933-01-17",
        "nepDate": "04.10.1989"
    },
    {
        "engDate": "1933-01-18",
        "nepDate": "05.10.1989"
    },
    {
        "engDate": "1933-01-19",
        "nepDate": "06.10.1989"
    },
    {
        "engDate": "1933-01-20",
        "nepDate": "07.10.1989"
    },
    {
        "engDate": "1933-01-21",
        "nepDate": "08.10.1989"
    },
    {
        "engDate": "1933-01-22",
        "nepDate": "09.10.1989"
    },
    {
        "engDate": "1933-01-23",
        "nepDate": "10.10.1989"
    },
    {
        "engDate": "1933-01-24",
        "nepDate": "11.10.1989"
    },
    {
        "engDate": "1933-01-25",
        "nepDate": "12.10.1989"
    },
    {
        "engDate": "1933-01-26",
        "nepDate": "13.10.1989"
    },
    {
        "engDate": "1933-01-27",
        "nepDate": "14.10.1989"
    },
    {
        "engDate": "1933-01-28",
        "nepDate": "15.10.1989"
    },
    {
        "engDate": "1933-01-29",
        "nepDate": "16.10.1989"
    },
    {
        "engDate": "1933-01-30",
        "nepDate": "17.10.1989"
    },
    {
        "engDate": "1933-01-31",
        "nepDate": "18.10.1989"
    },
    {
        "engDate": "1933-02-01",
        "nepDate": "19.10.1989"
    },
    {
        "engDate": "1933-02-02",
        "nepDate": "20.10.1989"
    },
    {
        "engDate": "1933-02-03",
        "nepDate": "21.10.1989"
    },
    {
        "engDate": "1933-02-04",
        "nepDate": "22.10.1989"
    },
    {
        "engDate": "1933-02-05",
        "nepDate": "23.10.1989"
    },
    {
        "engDate": "1933-02-06",
        "nepDate": "24.10.1989"
    },
    {
        "engDate": "1933-02-07",
        "nepDate": "25.10.1989"
    },
    {
        "engDate": "1933-02-08",
        "nepDate": "26.10.1989"
    },
    {
        "engDate": "1933-02-09",
        "nepDate": "27.10.1989"
    },
    {
        "engDate": "1933-02-10",
        "nepDate": "28.10.1989"
    },
    {
        "engDate": "1933-02-11",
        "nepDate": "29.10.1989"
    },
    {
        "engDate": "1933-02-12",
        "nepDate": "01.11.1989"
    },
    {
        "engDate": "1933-02-13",
        "nepDate": "02.11.1989"
    },
    {
        "engDate": "1933-02-14",
        "nepDate": "03.11.1989"
    },
    {
        "engDate": "1933-02-15",
        "nepDate": "04.11.1989"
    },
    {
        "engDate": "1933-02-16",
        "nepDate": "05.11.1989"
    },
    {
        "engDate": "1933-02-17",
        "nepDate": "06.11.1989"
    },
    {
        "engDate": "1933-02-18",
        "nepDate": "07.11.1989"
    },
    {
        "engDate": "1933-02-19",
        "nepDate": "08.11.1989"
    },
    {
        "engDate": "1933-02-20",
        "nepDate": "09.11.1989"
    },
    {
        "engDate": "1933-02-21",
        "nepDate": "10.11.1989"
    },
    {
        "engDate": "1933-02-22",
        "nepDate": "11.11.1989"
    },
    {
        "engDate": "1933-02-23",
        "nepDate": "12.11.1989"
    },
    {
        "engDate": "1933-02-24",
        "nepDate": "13.11.1989"
    },
    {
        "engDate": "1933-02-25",
        "nepDate": "14.11.1989"
    },
    {
        "engDate": "1933-02-26",
        "nepDate": "15.11.1989"
    },
    {
        "engDate": "1933-02-27",
        "nepDate": "16.11.1989"
    },
    {
        "engDate": "1933-02-28",
        "nepDate": "17.11.1989"
    },
    {
        "engDate": "1933-03-01",
        "nepDate": "18.11.1989"
    },
    {
        "engDate": "1933-03-02",
        "nepDate": "19.11.1989"
    },
    {
        "engDate": "1933-03-03",
        "nepDate": "20.11.1989"
    },
    {
        "engDate": "1933-03-04",
        "nepDate": "21.11.1989"
    },
    {
        "engDate": "1933-03-05",
        "nepDate": "22.11.1989"
    },
    {
        "engDate": "1933-03-06",
        "nepDate": "23.11.1989"
    },
    {
        "engDate": "1933-03-07",
        "nepDate": "24.11.1989"
    },
    {
        "engDate": "1933-03-08",
        "nepDate": "25.11.1989"
    },
    {
        "engDate": "1933-03-09",
        "nepDate": "26.11.1989"
    },
    {
        "engDate": "1933-03-10",
        "nepDate": "27.11.1989"
    },
    {
        "engDate": "1933-03-11",
        "nepDate": "28.11.1989"
    },
    {
        "engDate": "1933-03-12",
        "nepDate": "29.11.1989"
    },
    {
        "engDate": "1933-03-13",
        "nepDate": "30.11.1989"
    },
    {
        "engDate": "1933-03-14",
        "nepDate": "01.12.1989"
    },
    {
        "engDate": "1933-03-15",
        "nepDate": "02.12.1989"
    },
    {
        "engDate": "1933-03-16",
        "nepDate": "03.12.1989"
    },
    {
        "engDate": "1933-03-17",
        "nepDate": "04.12.1989"
    },
    {
        "engDate": "1933-03-18",
        "nepDate": "05.12.1989"
    },
    {
        "engDate": "1933-03-19",
        "nepDate": "06.12.1989"
    },
    {
        "engDate": "1933-03-20",
        "nepDate": "07.12.1989"
    },
    {
        "engDate": "1933-03-21",
        "nepDate": "08.12.1989"
    },
    {
        "engDate": "1933-03-22",
        "nepDate": "09.12.1989"
    },
    {
        "engDate": "1933-03-23",
        "nepDate": "10.12.1989"
    },
    {
        "engDate": "1933-03-24",
        "nepDate": "11.12.1989"
    },
    {
        "engDate": "1933-03-25",
        "nepDate": "12.12.1989"
    },
    {
        "engDate": "1933-03-26",
        "nepDate": "13.12.1989"
    },
    {
        "engDate": "1933-03-27",
        "nepDate": "14.12.1989"
    },
    {
        "engDate": "1933-03-28",
        "nepDate": "15.12.1989"
    },
    {
        "engDate": "1933-03-29",
        "nepDate": "16.12.1989"
    },
    {
        "engDate": "1933-03-30",
        "nepDate": "17.12.1989"
    },
    {
        "engDate": "1933-03-31",
        "nepDate": "18.12.1989"
    },
    {
        "engDate": "1933-04-01",
        "nepDate": "19.12.1989"
    },
    {
        "engDate": "1933-04-02",
        "nepDate": "20.12.1989"
    },
    {
        "engDate": "1933-04-03",
        "nepDate": "21.12.1989"
    },
    {
        "engDate": "1933-04-04",
        "nepDate": "22.12.1989"
    },
    {
        "engDate": "1933-04-05",
        "nepDate": "23.12.1989"
    },
    {
        "engDate": "1933-04-06",
        "nepDate": "24.12.1989"
    },
    {
        "engDate": "1933-04-07",
        "nepDate": "25.12.1989"
    },
    {
        "engDate": "1933-04-08",
        "nepDate": "26.12.1989"
    },
    {
        "engDate": "1933-04-09",
        "nepDate": "27.12.1989"
    },
    {
        "engDate": "1933-04-10",
        "nepDate": "28.12.1989"
    },
    {
        "engDate": "1933-04-11",
        "nepDate": "29.12.1989"
    },
    {
        "engDate": "1933-04-12",
        "nepDate": "30.12.1989"
    },
    {
        "engDate": "1933-04-13",
        "nepDate": "01.01.1990"
    },
    {
        "engDate": "1933-04-14",
        "nepDate": "02.01.1990"
    },
    {
        "engDate": "1933-04-15",
        "nepDate": "03.01.1990"
    },
    {
        "engDate": "1933-04-16",
        "nepDate": "04.01.1990"
    },
    {
        "engDate": "1933-04-17",
        "nepDate": "05.01.1990"
    },
    {
        "engDate": "1933-04-18",
        "nepDate": "06.01.1990"
    },
    {
        "engDate": "1933-04-19",
        "nepDate": "07.01.1990"
    },
    {
        "engDate": "1933-04-20",
        "nepDate": "08.01.1990"
    },
    {
        "engDate": "1933-04-21",
        "nepDate": "09.01.1990"
    },
    {
        "engDate": "1933-04-22",
        "nepDate": "10.01.1990"
    },
    {
        "engDate": "1933-04-23",
        "nepDate": "11.01.1990"
    },
    {
        "engDate": "1933-04-24",
        "nepDate": "12.01.1990"
    },
    {
        "engDate": "1933-04-25",
        "nepDate": "13.01.1990"
    },
    {
        "engDate": "1933-04-26",
        "nepDate": "14.01.1990"
    },
    {
        "engDate": "1933-04-27",
        "nepDate": "15.01.1990"
    },
    {
        "engDate": "1933-04-28",
        "nepDate": "16.01.1990"
    },
    {
        "engDate": "1933-04-29",
        "nepDate": "17.01.1990"
    },
    {
        "engDate": "1933-04-30",
        "nepDate": "18.01.1990"
    },
    {
        "engDate": "1933-05-01",
        "nepDate": "19.01.1990"
    },
    {
        "engDate": "1933-05-02",
        "nepDate": "20.01.1990"
    },
    {
        "engDate": "1933-05-03",
        "nepDate": "21.01.1990"
    },
    {
        "engDate": "1933-05-04",
        "nepDate": "22.01.1990"
    },
    {
        "engDate": "1933-05-05",
        "nepDate": "23.01.1990"
    },
    {
        "engDate": "1933-05-06",
        "nepDate": "24.01.1990"
    },
    {
        "engDate": "1933-05-07",
        "nepDate": "25.01.1990"
    },
    {
        "engDate": "1933-05-08",
        "nepDate": "26.01.1990"
    },
    {
        "engDate": "1933-05-09",
        "nepDate": "27.01.1990"
    },
    {
        "engDate": "1933-05-10",
        "nepDate": "28.01.1990"
    },
    {
        "engDate": "1933-05-11",
        "nepDate": "29.01.1990"
    },
    {
        "engDate": "1933-05-12",
        "nepDate": "30.01.1990"
    },
    {
        "engDate": "1933-05-13",
        "nepDate": "31.01.1990"
    },
    {
        "engDate": "1933-05-14",
        "nepDate": "01.02.1990"
    },
    {
        "engDate": "1933-05-15",
        "nepDate": "02.02.1990"
    },
    {
        "engDate": "1933-05-16",
        "nepDate": "03.02.1990"
    },
    {
        "engDate": "1933-05-17",
        "nepDate": "04.02.1990"
    },
    {
        "engDate": "1933-05-18",
        "nepDate": "05.02.1990"
    },
    {
        "engDate": "1933-05-19",
        "nepDate": "06.02.1990"
    },
    {
        "engDate": "1933-05-20",
        "nepDate": "07.02.1990"
    },
    {
        "engDate": "1933-05-21",
        "nepDate": "08.02.1990"
    },
    {
        "engDate": "1933-05-22",
        "nepDate": "09.02.1990"
    },
    {
        "engDate": "1933-05-23",
        "nepDate": "10.02.1990"
    },
    {
        "engDate": "1933-05-24",
        "nepDate": "11.02.1990"
    },
    {
        "engDate": "1933-05-25",
        "nepDate": "12.02.1990"
    },
    {
        "engDate": "1933-05-26",
        "nepDate": "13.02.1990"
    },
    {
        "engDate": "1933-05-27",
        "nepDate": "14.02.1990"
    },
    {
        "engDate": "1933-05-28",
        "nepDate": "15.02.1990"
    },
    {
        "engDate": "1933-05-29",
        "nepDate": "16.02.1990"
    },
    {
        "engDate": "1933-05-30",
        "nepDate": "17.02.1990"
    },
    {
        "engDate": "1933-05-31",
        "nepDate": "18.02.1990"
    },
    {
        "engDate": "1933-06-01",
        "nepDate": "19.02.1990"
    },
    {
        "engDate": "1933-06-02",
        "nepDate": "20.02.1990"
    },
    {
        "engDate": "1933-06-03",
        "nepDate": "21.02.1990"
    },
    {
        "engDate": "1933-06-04",
        "nepDate": "22.02.1990"
    },
    {
        "engDate": "1933-06-05",
        "nepDate": "23.02.1990"
    },
    {
        "engDate": "1933-06-06",
        "nepDate": "24.02.1990"
    },
    {
        "engDate": "1933-06-07",
        "nepDate": "25.02.1990"
    },
    {
        "engDate": "1933-06-08",
        "nepDate": "26.02.1990"
    },
    {
        "engDate": "1933-06-09",
        "nepDate": "27.02.1990"
    },
    {
        "engDate": "1933-06-10",
        "nepDate": "28.02.1990"
    },
    {
        "engDate": "1933-06-11",
        "nepDate": "29.02.1990"
    },
    {
        "engDate": "1933-06-12",
        "nepDate": "30.02.1990"
    },
    {
        "engDate": "1933-06-13",
        "nepDate": "31.02.1990"
    },
    {
        "engDate": "1933-06-14",
        "nepDate": "01.03.1990"
    },
    {
        "engDate": "1933-06-15",
        "nepDate": "02.03.1990"
    },
    {
        "engDate": "1933-06-16",
        "nepDate": "03.03.1990"
    },
    {
        "engDate": "1933-06-17",
        "nepDate": "04.03.1990"
    },
    {
        "engDate": "1933-06-18",
        "nepDate": "05.03.1990"
    },
    {
        "engDate": "1933-06-19",
        "nepDate": "06.03.1990"
    },
    {
        "engDate": "1933-06-20",
        "nepDate": "07.03.1990"
    },
    {
        "engDate": "1933-06-21",
        "nepDate": "08.03.1990"
    },
    {
        "engDate": "1933-06-22",
        "nepDate": "09.03.1990"
    },
    {
        "engDate": "1933-06-23",
        "nepDate": "10.03.1990"
    },
    {
        "engDate": "1933-06-24",
        "nepDate": "11.03.1990"
    },
    {
        "engDate": "1933-06-25",
        "nepDate": "12.03.1990"
    },
    {
        "engDate": "1933-06-26",
        "nepDate": "13.03.1990"
    },
    {
        "engDate": "1933-06-27",
        "nepDate": "14.03.1990"
    },
    {
        "engDate": "1933-06-28",
        "nepDate": "15.03.1990"
    },
    {
        "engDate": "1933-06-29",
        "nepDate": "16.03.1990"
    },
    {
        "engDate": "1933-06-30",
        "nepDate": "17.03.1990"
    },
    {
        "engDate": "1933-07-01",
        "nepDate": "18.03.1990"
    },
    {
        "engDate": "1933-07-02",
        "nepDate": "19.03.1990"
    },
    {
        "engDate": "1933-07-03",
        "nepDate": "20.03.1990"
    },
    {
        "engDate": "1933-07-04",
        "nepDate": "21.03.1990"
    },
    {
        "engDate": "1933-07-05",
        "nepDate": "22.03.1990"
    },
    {
        "engDate": "1933-07-06",
        "nepDate": "23.03.1990"
    },
    {
        "engDate": "1933-07-07",
        "nepDate": "24.03.1990"
    },
    {
        "engDate": "1933-07-08",
        "nepDate": "25.03.1990"
    },
    {
        "engDate": "1933-07-09",
        "nepDate": "26.03.1990"
    },
    {
        "engDate": "1933-07-10",
        "nepDate": "27.03.1990"
    },
    {
        "engDate": "1933-07-11",
        "nepDate": "28.03.1990"
    },
    {
        "engDate": "1933-07-12",
        "nepDate": "29.03.1990"
    },
    {
        "engDate": "1933-07-13",
        "nepDate": "30.03.1990"
    },
    {
        "engDate": "1933-07-14",
        "nepDate": "31.03.1990"
    },
    {
        "engDate": "1933-07-15",
        "nepDate": "32.03.1990"
    },
    {
        "engDate": "1933-07-16",
        "nepDate": "01.04.1990"
    },
    {
        "engDate": "1933-07-17",
        "nepDate": "02.04.1990"
    },
    {
        "engDate": "1933-07-18",
        "nepDate": "03.04.1990"
    },
    {
        "engDate": "1933-07-19",
        "nepDate": "04.04.1990"
    },
    {
        "engDate": "1933-07-20",
        "nepDate": "05.04.1990"
    },
    {
        "engDate": "1933-07-21",
        "nepDate": "06.04.1990"
    },
    {
        "engDate": "1933-07-22",
        "nepDate": "07.04.1990"
    },
    {
        "engDate": "1933-07-23",
        "nepDate": "08.04.1990"
    },
    {
        "engDate": "1933-07-24",
        "nepDate": "09.04.1990"
    },
    {
        "engDate": "1933-07-25",
        "nepDate": "10.04.1990"
    },
    {
        "engDate": "1933-07-26",
        "nepDate": "11.04.1990"
    },
    {
        "engDate": "1933-07-27",
        "nepDate": "12.04.1990"
    },
    {
        "engDate": "1933-07-28",
        "nepDate": "13.04.1990"
    },
    {
        "engDate": "1933-07-29",
        "nepDate": "14.04.1990"
    },
    {
        "engDate": "1933-07-30",
        "nepDate": "15.04.1990"
    },
    {
        "engDate": "1933-07-31",
        "nepDate": "16.04.1990"
    },
    {
        "engDate": "1933-08-01",
        "nepDate": "17.04.1990"
    },
    {
        "engDate": "1933-08-02",
        "nepDate": "18.04.1990"
    },
    {
        "engDate": "1933-08-03",
        "nepDate": "19.04.1990"
    },
    {
        "engDate": "1933-08-04",
        "nepDate": "20.04.1990"
    },
    {
        "engDate": "1933-08-05",
        "nepDate": "21.04.1990"
    },
    {
        "engDate": "1933-08-06",
        "nepDate": "22.04.1990"
    },
    {
        "engDate": "1933-08-07",
        "nepDate": "23.04.1990"
    },
    {
        "engDate": "1933-08-08",
        "nepDate": "24.04.1990"
    },
    {
        "engDate": "1933-08-09",
        "nepDate": "25.04.1990"
    },
    {
        "engDate": "1933-08-10",
        "nepDate": "26.04.1990"
    },
    {
        "engDate": "1933-08-11",
        "nepDate": "27.04.1990"
    },
    {
        "engDate": "1933-08-12",
        "nepDate": "28.04.1990"
    },
    {
        "engDate": "1933-08-13",
        "nepDate": "29.04.1990"
    },
    {
        "engDate": "1933-08-14",
        "nepDate": "30.04.1990"
    },
    {
        "engDate": "1933-08-15",
        "nepDate": "31.04.1990"
    },
    {
        "engDate": "1933-08-16",
        "nepDate": "01.05.1990"
    },
    {
        "engDate": "1933-08-17",
        "nepDate": "02.05.1990"
    },
    {
        "engDate": "1933-08-18",
        "nepDate": "03.05.1990"
    },
    {
        "engDate": "1933-08-19",
        "nepDate": "04.05.1990"
    },
    {
        "engDate": "1933-08-20",
        "nepDate": "05.05.1990"
    },
    {
        "engDate": "1933-08-21",
        "nepDate": "06.05.1990"
    },
    {
        "engDate": "1933-08-22",
        "nepDate": "07.05.1990"
    },
    {
        "engDate": "1933-08-23",
        "nepDate": "08.05.1990"
    },
    {
        "engDate": "1933-08-24",
        "nepDate": "09.05.1990"
    },
    {
        "engDate": "1933-08-25",
        "nepDate": "10.05.1990"
    },
    {
        "engDate": "1933-08-26",
        "nepDate": "11.05.1990"
    },
    {
        "engDate": "1933-08-27",
        "nepDate": "12.05.1990"
    },
    {
        "engDate": "1933-08-28",
        "nepDate": "13.05.1990"
    },
    {
        "engDate": "1933-08-29",
        "nepDate": "14.05.1990"
    },
    {
        "engDate": "1933-08-30",
        "nepDate": "15.05.1990"
    },
    {
        "engDate": "1933-08-31",
        "nepDate": "16.05.1990"
    },
    {
        "engDate": "1933-09-01",
        "nepDate": "17.05.1990"
    },
    {
        "engDate": "1933-09-02",
        "nepDate": "18.05.1990"
    },
    {
        "engDate": "1933-09-03",
        "nepDate": "19.05.1990"
    },
    {
        "engDate": "1933-09-04",
        "nepDate": "20.05.1990"
    },
    {
        "engDate": "1933-09-05",
        "nepDate": "21.05.1990"
    },
    {
        "engDate": "1933-09-06",
        "nepDate": "22.05.1990"
    },
    {
        "engDate": "1933-09-07",
        "nepDate": "23.05.1990"
    },
    {
        "engDate": "1933-09-08",
        "nepDate": "24.05.1990"
    },
    {
        "engDate": "1933-09-09",
        "nepDate": "25.05.1990"
    },
    {
        "engDate": "1933-09-10",
        "nepDate": "26.05.1990"
    },
    {
        "engDate": "1933-09-11",
        "nepDate": "27.05.1990"
    },
    {
        "engDate": "1933-09-12",
        "nepDate": "28.05.1990"
    },
    {
        "engDate": "1933-09-13",
        "nepDate": "29.05.1990"
    },
    {
        "engDate": "1933-09-14",
        "nepDate": "30.05.1990"
    },
    {
        "engDate": "1933-09-15",
        "nepDate": "31.05.1990"
    },
    {
        "engDate": "1933-09-16",
        "nepDate": "01.06.1990"
    },
    {
        "engDate": "1933-09-17",
        "nepDate": "02.06.1990"
    },
    {
        "engDate": "1933-09-18",
        "nepDate": "03.06.1990"
    },
    {
        "engDate": "1933-09-19",
        "nepDate": "04.06.1990"
    },
    {
        "engDate": "1933-09-20",
        "nepDate": "05.06.1990"
    },
    {
        "engDate": "1933-09-21",
        "nepDate": "06.06.1990"
    },
    {
        "engDate": "1933-09-22",
        "nepDate": "07.06.1990"
    },
    {
        "engDate": "1933-09-23",
        "nepDate": "08.06.1990"
    },
    {
        "engDate": "1933-09-24",
        "nepDate": "09.06.1990"
    },
    {
        "engDate": "1933-09-25",
        "nepDate": "10.06.1990"
    },
    {
        "engDate": "1933-09-26",
        "nepDate": "11.06.1990"
    },
    {
        "engDate": "1933-09-27",
        "nepDate": "12.06.1990"
    },
    {
        "engDate": "1933-09-28",
        "nepDate": "13.06.1990"
    },
    {
        "engDate": "1933-09-29",
        "nepDate": "14.06.1990"
    },
    {
        "engDate": "1933-09-30",
        "nepDate": "15.06.1990"
    },
    {
        "engDate": "1933-10-01",
        "nepDate": "16.06.1990"
    },
    {
        "engDate": "1933-10-02",
        "nepDate": "17.06.1990"
    },
    {
        "engDate": "1933-10-03",
        "nepDate": "18.06.1990"
    },
    {
        "engDate": "1933-10-04",
        "nepDate": "19.06.1990"
    },
    {
        "engDate": "1933-10-05",
        "nepDate": "20.06.1990"
    },
    {
        "engDate": "1933-10-06",
        "nepDate": "21.06.1990"
    },
    {
        "engDate": "1933-10-07",
        "nepDate": "22.06.1990"
    },
    {
        "engDate": "1933-10-08",
        "nepDate": "23.06.1990"
    },
    {
        "engDate": "1933-10-09",
        "nepDate": "24.06.1990"
    },
    {
        "engDate": "1933-10-10",
        "nepDate": "25.06.1990"
    },
    {
        "engDate": "1933-10-11",
        "nepDate": "26.06.1990"
    },
    {
        "engDate": "1933-10-12",
        "nepDate": "27.06.1990"
    },
    {
        "engDate": "1933-10-13",
        "nepDate": "28.06.1990"
    },
    {
        "engDate": "1933-10-14",
        "nepDate": "29.06.1990"
    },
    {
        "engDate": "1933-10-15",
        "nepDate": "30.06.1990"
    },
    {
        "engDate": "1933-10-16",
        "nepDate": "31.06.1990"
    },
    {
        "engDate": "1933-10-17",
        "nepDate": "01.07.1990"
    },
    {
        "engDate": "1933-10-18",
        "nepDate": "02.07.1990"
    },
    {
        "engDate": "1933-10-19",
        "nepDate": "03.07.1990"
    },
    {
        "engDate": "1933-10-20",
        "nepDate": "04.07.1990"
    },
    {
        "engDate": "1933-10-21",
        "nepDate": "05.07.1990"
    },
    {
        "engDate": "1933-10-22",
        "nepDate": "06.07.1990"
    },
    {
        "engDate": "1933-10-23",
        "nepDate": "07.07.1990"
    },
    {
        "engDate": "1933-10-24",
        "nepDate": "08.07.1990"
    },
    {
        "engDate": "1933-10-25",
        "nepDate": "09.07.1990"
    },
    {
        "engDate": "1933-10-26",
        "nepDate": "10.07.1990"
    },
    {
        "engDate": "1933-10-27",
        "nepDate": "11.07.1990"
    },
    {
        "engDate": "1933-10-28",
        "nepDate": "12.07.1990"
    },
    {
        "engDate": "1933-10-29",
        "nepDate": "13.07.1990"
    },
    {
        "engDate": "1933-10-30",
        "nepDate": "14.07.1990"
    },
    {
        "engDate": "1933-10-31",
        "nepDate": "15.07.1990"
    },
    {
        "engDate": "1933-11-01",
        "nepDate": "16.07.1990"
    },
    {
        "engDate": "1933-11-02",
        "nepDate": "17.07.1990"
    },
    {
        "engDate": "1933-11-03",
        "nepDate": "18.07.1990"
    },
    {
        "engDate": "1933-11-04",
        "nepDate": "19.07.1990"
    },
    {
        "engDate": "1933-11-05",
        "nepDate": "20.07.1990"
    },
    {
        "engDate": "1933-11-06",
        "nepDate": "21.07.1990"
    },
    {
        "engDate": "1933-11-07",
        "nepDate": "22.07.1990"
    },
    {
        "engDate": "1933-11-08",
        "nepDate": "23.07.1990"
    },
    {
        "engDate": "1933-11-09",
        "nepDate": "24.07.1990"
    },
    {
        "engDate": "1933-11-10",
        "nepDate": "25.07.1990"
    },
    {
        "engDate": "1933-11-11",
        "nepDate": "26.07.1990"
    },
    {
        "engDate": "1933-11-12",
        "nepDate": "27.07.1990"
    },
    {
        "engDate": "1933-11-13",
        "nepDate": "28.07.1990"
    },
    {
        "engDate": "1933-11-14",
        "nepDate": "29.07.1990"
    },
    {
        "engDate": "1933-11-15",
        "nepDate": "30.07.1990"
    },
    {
        "engDate": "1933-11-16",
        "nepDate": "01.08.1990"
    },
    {
        "engDate": "1933-11-17",
        "nepDate": "02.08.1990"
    },
    {
        "engDate": "1933-11-18",
        "nepDate": "03.08.1990"
    },
    {
        "engDate": "1933-11-19",
        "nepDate": "04.08.1990"
    },
    {
        "engDate": "1933-11-20",
        "nepDate": "05.08.1990"
    },
    {
        "engDate": "1933-11-21",
        "nepDate": "06.08.1990"
    },
    {
        "engDate": "1933-11-22",
        "nepDate": "07.08.1990"
    },
    {
        "engDate": "1933-11-23",
        "nepDate": "08.08.1990"
    },
    {
        "engDate": "1933-11-24",
        "nepDate": "09.08.1990"
    },
    {
        "engDate": "1933-11-25",
        "nepDate": "10.08.1990"
    },
    {
        "engDate": "1933-11-26",
        "nepDate": "11.08.1990"
    },
    {
        "engDate": "1933-11-27",
        "nepDate": "12.08.1990"
    },
    {
        "engDate": "1933-11-28",
        "nepDate": "13.08.1990"
    },
    {
        "engDate": "1933-11-29",
        "nepDate": "14.08.1990"
    },
    {
        "engDate": "1933-11-30",
        "nepDate": "15.08.1990"
    },
    {
        "engDate": "1933-12-01",
        "nepDate": "16.08.1990"
    },
    {
        "engDate": "1933-12-02",
        "nepDate": "17.08.1990"
    },
    {
        "engDate": "1933-12-03",
        "nepDate": "18.08.1990"
    },
    {
        "engDate": "1933-12-04",
        "nepDate": "19.08.1990"
    },
    {
        "engDate": "1933-12-05",
        "nepDate": "20.08.1990"
    },
    {
        "engDate": "1933-12-06",
        "nepDate": "21.08.1990"
    },
    {
        "engDate": "1933-12-07",
        "nepDate": "22.08.1990"
    },
    {
        "engDate": "1933-12-08",
        "nepDate": "23.08.1990"
    },
    {
        "engDate": "1933-12-09",
        "nepDate": "24.08.1990"
    },
    {
        "engDate": "1933-12-10",
        "nepDate": "25.08.1990"
    },
    {
        "engDate": "1933-12-11",
        "nepDate": "26.08.1990"
    },
    {
        "engDate": "1933-12-12",
        "nepDate": "27.08.1990"
    },
    {
        "engDate": "1933-12-13",
        "nepDate": "28.08.1990"
    },
    {
        "engDate": "1933-12-14",
        "nepDate": "29.08.1990"
    },
    {
        "engDate": "1933-12-15",
        "nepDate": "01.09.1990"
    },
    {
        "engDate": "1933-12-16",
        "nepDate": "02.09.1990"
    },
    {
        "engDate": "1933-12-17",
        "nepDate": "03.09.1990"
    },
    {
        "engDate": "1933-12-18",
        "nepDate": "04.09.1990"
    },
    {
        "engDate": "1933-12-19",
        "nepDate": "05.09.1990"
    },
    {
        "engDate": "1933-12-20",
        "nepDate": "06.09.1990"
    },
    {
        "engDate": "1933-12-21",
        "nepDate": "07.09.1990"
    },
    {
        "engDate": "1933-12-22",
        "nepDate": "08.09.1990"
    },
    {
        "engDate": "1933-12-23",
        "nepDate": "09.09.1990"
    },
    {
        "engDate": "1933-12-24",
        "nepDate": "10.09.1990"
    },
    {
        "engDate": "1933-12-25",
        "nepDate": "11.09.1990"
    },
    {
        "engDate": "1933-12-26",
        "nepDate": "12.09.1990"
    },
    {
        "engDate": "1933-12-27",
        "nepDate": "13.09.1990"
    },
    {
        "engDate": "1933-12-28",
        "nepDate": "14.09.1990"
    },
    {
        "engDate": "1933-12-29",
        "nepDate": "15.09.1990"
    },
    {
        "engDate": "1933-12-30",
        "nepDate": "16.09.1990"
    },
    {
        "engDate": "1933-12-31",
        "nepDate": "17.09.1990"
    },
    {
        "engDate": "1934-01-01",
        "nepDate": "18.09.1990"
    },
    {
        "engDate": "1934-01-02",
        "nepDate": "19.09.1990"
    },
    {
        "engDate": "1934-01-03",
        "nepDate": "20.09.1990"
    },
    {
        "engDate": "1934-01-04",
        "nepDate": "21.09.1990"
    },
    {
        "engDate": "1934-01-05",
        "nepDate": "22.09.1990"
    },
    {
        "engDate": "1934-01-06",
        "nepDate": "23.09.1990"
    },
    {
        "engDate": "1934-01-07",
        "nepDate": "24.09.1990"
    },
    {
        "engDate": "1934-01-08",
        "nepDate": "25.09.1990"
    },
    {
        "engDate": "1934-01-09",
        "nepDate": "26.09.1990"
    },
    {
        "engDate": "1934-01-10",
        "nepDate": "27.09.1990"
    },
    {
        "engDate": "1934-01-11",
        "nepDate": "28.09.1990"
    },
    {
        "engDate": "1934-01-12",
        "nepDate": "29.09.1990"
    },
    {
        "engDate": "1934-01-13",
        "nepDate": "30.09.1990"
    },
    {
        "engDate": "1934-01-14",
        "nepDate": "01.10.1990"
    },
    {
        "engDate": "1934-01-15",
        "nepDate": "02.10.1990"
    },
    {
        "engDate": "1934-01-16",
        "nepDate": "03.10.1990"
    },
    {
        "engDate": "1934-01-17",
        "nepDate": "04.10.1990"
    },
    {
        "engDate": "1934-01-18",
        "nepDate": "05.10.1990"
    },
    {
        "engDate": "1934-01-19",
        "nepDate": "06.10.1990"
    },
    {
        "engDate": "1934-01-20",
        "nepDate": "07.10.1990"
    },
    {
        "engDate": "1934-01-21",
        "nepDate": "08.10.1990"
    },
    {
        "engDate": "1934-01-22",
        "nepDate": "09.10.1990"
    },
    {
        "engDate": "1934-01-23",
        "nepDate": "10.10.1990"
    },
    {
        "engDate": "1934-01-24",
        "nepDate": "11.10.1990"
    },
    {
        "engDate": "1934-01-25",
        "nepDate": "12.10.1990"
    },
    {
        "engDate": "1934-01-26",
        "nepDate": "13.10.1990"
    },
    {
        "engDate": "1934-01-27",
        "nepDate": "14.10.1990"
    },
    {
        "engDate": "1934-01-28",
        "nepDate": "15.10.1990"
    },
    {
        "engDate": "1934-01-29",
        "nepDate": "16.10.1990"
    },
    {
        "engDate": "1934-01-30",
        "nepDate": "17.10.1990"
    },
    {
        "engDate": "1934-01-31",
        "nepDate": "18.10.1990"
    },
    {
        "engDate": "1934-02-01",
        "nepDate": "19.10.1990"
    },
    {
        "engDate": "1934-02-02",
        "nepDate": "20.10.1990"
    },
    {
        "engDate": "1934-02-03",
        "nepDate": "21.10.1990"
    },
    {
        "engDate": "1934-02-04",
        "nepDate": "22.10.1990"
    },
    {
        "engDate": "1934-02-05",
        "nepDate": "23.10.1990"
    },
    {
        "engDate": "1934-02-06",
        "nepDate": "24.10.1990"
    },
    {
        "engDate": "1934-02-07",
        "nepDate": "25.10.1990"
    },
    {
        "engDate": "1934-02-08",
        "nepDate": "26.10.1990"
    },
    {
        "engDate": "1934-02-09",
        "nepDate": "27.10.1990"
    },
    {
        "engDate": "1934-02-10",
        "nepDate": "28.10.1990"
    },
    {
        "engDate": "1934-02-11",
        "nepDate": "29.10.1990"
    },
    {
        "engDate": "1934-02-12",
        "nepDate": "01.11.1990"
    },
    {
        "engDate": "1934-02-13",
        "nepDate": "02.11.1990"
    },
    {
        "engDate": "1934-02-14",
        "nepDate": "03.11.1990"
    },
    {
        "engDate": "1934-02-15",
        "nepDate": "04.11.1990"
    },
    {
        "engDate": "1934-02-16",
        "nepDate": "05.11.1990"
    },
    {
        "engDate": "1934-02-17",
        "nepDate": "06.11.1990"
    },
    {
        "engDate": "1934-02-18",
        "nepDate": "07.11.1990"
    },
    {
        "engDate": "1934-02-19",
        "nepDate": "08.11.1990"
    },
    {
        "engDate": "1934-02-20",
        "nepDate": "09.11.1990"
    },
    {
        "engDate": "1934-02-21",
        "nepDate": "10.11.1990"
    },
    {
        "engDate": "1934-02-22",
        "nepDate": "11.11.1990"
    },
    {
        "engDate": "1934-02-23",
        "nepDate": "12.11.1990"
    },
    {
        "engDate": "1934-02-24",
        "nepDate": "13.11.1990"
    },
    {
        "engDate": "1934-02-25",
        "nepDate": "14.11.1990"
    },
    {
        "engDate": "1934-02-26",
        "nepDate": "15.11.1990"
    },
    {
        "engDate": "1934-02-27",
        "nepDate": "16.11.1990"
    },
    {
        "engDate": "1934-02-28",
        "nepDate": "17.11.1990"
    },
    {
        "engDate": "1934-03-01",
        "nepDate": "18.11.1990"
    },
    {
        "engDate": "1934-03-02",
        "nepDate": "19.11.1990"
    },
    {
        "engDate": "1934-03-03",
        "nepDate": "20.11.1990"
    },
    {
        "engDate": "1934-03-04",
        "nepDate": "21.11.1990"
    },
    {
        "engDate": "1934-03-05",
        "nepDate": "22.11.1990"
    },
    {
        "engDate": "1934-03-06",
        "nepDate": "23.11.1990"
    },
    {
        "engDate": "1934-03-07",
        "nepDate": "24.11.1990"
    },
    {
        "engDate": "1934-03-08",
        "nepDate": "25.11.1990"
    },
    {
        "engDate": "1934-03-09",
        "nepDate": "26.11.1990"
    },
    {
        "engDate": "1934-03-10",
        "nepDate": "27.11.1990"
    },
    {
        "engDate": "1934-03-11",
        "nepDate": "28.11.1990"
    },
    {
        "engDate": "1934-03-12",
        "nepDate": "29.11.1990"
    },
    {
        "engDate": "1934-03-13",
        "nepDate": "30.11.1990"
    },
    {
        "engDate": "1934-03-14",
        "nepDate": "01.12.1990"
    },
    {
        "engDate": "1934-03-15",
        "nepDate": "02.12.1990"
    },
    {
        "engDate": "1934-03-16",
        "nepDate": "03.12.1990"
    },
    {
        "engDate": "1934-03-17",
        "nepDate": "04.12.1990"
    },
    {
        "engDate": "1934-03-18",
        "nepDate": "05.12.1990"
    },
    {
        "engDate": "1934-03-19",
        "nepDate": "06.12.1990"
    },
    {
        "engDate": "1934-03-20",
        "nepDate": "07.12.1990"
    },
    {
        "engDate": "1934-03-21",
        "nepDate": "08.12.1990"
    },
    {
        "engDate": "1934-03-22",
        "nepDate": "09.12.1990"
    },
    {
        "engDate": "1934-03-23",
        "nepDate": "10.12.1990"
    },
    {
        "engDate": "1934-03-24",
        "nepDate": "11.12.1990"
    },
    {
        "engDate": "1934-03-25",
        "nepDate": "12.12.1990"
    },
    {
        "engDate": "1934-03-26",
        "nepDate": "13.12.1990"
    },
    {
        "engDate": "1934-03-27",
        "nepDate": "14.12.1990"
    },
    {
        "engDate": "1934-03-28",
        "nepDate": "15.12.1990"
    },
    {
        "engDate": "1934-03-29",
        "nepDate": "16.12.1990"
    },
    {
        "engDate": "1934-03-30",
        "nepDate": "17.12.1990"
    },
    {
        "engDate": "1934-03-31",
        "nepDate": "18.12.1990"
    },
    {
        "engDate": "1934-04-01",
        "nepDate": "19.12.1990"
    },
    {
        "engDate": "1934-04-02",
        "nepDate": "20.12.1990"
    },
    {
        "engDate": "1934-04-03",
        "nepDate": "21.12.1990"
    },
    {
        "engDate": "1934-04-04",
        "nepDate": "22.12.1990"
    },
    {
        "engDate": "1934-04-05",
        "nepDate": "23.12.1990"
    },
    {
        "engDate": "1934-04-06",
        "nepDate": "24.12.1990"
    },
    {
        "engDate": "1934-04-07",
        "nepDate": "25.12.1990"
    },
    {
        "engDate": "1934-04-08",
        "nepDate": "26.12.1990"
    },
    {
        "engDate": "1934-04-09",
        "nepDate": "27.12.1990"
    },
    {
        "engDate": "1934-04-10",
        "nepDate": "28.12.1990"
    },
    {
        "engDate": "1934-04-11",
        "nepDate": "29.12.1990"
    },
    {
        "engDate": "1934-04-12",
        "nepDate": "30.12.1990"
    },
    {
        "engDate": "1934-04-13",
        "nepDate": "01.01.1991"
    },
    {
        "engDate": "1934-04-14",
        "nepDate": "02.01.1991"
    },
    {
        "engDate": "1934-04-15",
        "nepDate": "03.01.1991"
    },
    {
        "engDate": "1934-04-16",
        "nepDate": "04.01.1991"
    },
    {
        "engDate": "1934-04-17",
        "nepDate": "05.01.1991"
    },
    {
        "engDate": "1934-04-18",
        "nepDate": "06.01.1991"
    },
    {
        "engDate": "1934-04-19",
        "nepDate": "07.01.1991"
    },
    {
        "engDate": "1934-04-20",
        "nepDate": "08.01.1991"
    },
    {
        "engDate": "1934-04-21",
        "nepDate": "09.01.1991"
    },
    {
        "engDate": "1934-04-22",
        "nepDate": "10.01.1991"
    },
    {
        "engDate": "1934-04-23",
        "nepDate": "11.01.1991"
    },
    {
        "engDate": "1934-04-24",
        "nepDate": "12.01.1991"
    },
    {
        "engDate": "1934-04-25",
        "nepDate": "13.01.1991"
    },
    {
        "engDate": "1934-04-26",
        "nepDate": "14.01.1991"
    },
    {
        "engDate": "1934-04-27",
        "nepDate": "15.01.1991"
    },
    {
        "engDate": "1934-04-28",
        "nepDate": "16.01.1991"
    },
    {
        "engDate": "1934-04-29",
        "nepDate": "17.01.1991"
    },
    {
        "engDate": "1934-04-30",
        "nepDate": "18.01.1991"
    },
    {
        "engDate": "1934-05-01",
        "nepDate": "19.01.1991"
    },
    {
        "engDate": "1934-05-02",
        "nepDate": "20.01.1991"
    },
    {
        "engDate": "1934-05-03",
        "nepDate": "21.01.1991"
    },
    {
        "engDate": "1934-05-04",
        "nepDate": "22.01.1991"
    },
    {
        "engDate": "1934-05-05",
        "nepDate": "23.01.1991"
    },
    {
        "engDate": "1934-05-06",
        "nepDate": "24.01.1991"
    },
    {
        "engDate": "1934-05-07",
        "nepDate": "25.01.1991"
    },
    {
        "engDate": "1934-05-08",
        "nepDate": "26.01.1991"
    },
    {
        "engDate": "1934-05-09",
        "nepDate": "27.01.1991"
    },
    {
        "engDate": "1934-05-10",
        "nepDate": "28.01.1991"
    },
    {
        "engDate": "1934-05-11",
        "nepDate": "29.01.1991"
    },
    {
        "engDate": "1934-05-12",
        "nepDate": "30.01.1991"
    },
    {
        "engDate": "1934-05-13",
        "nepDate": "31.01.1991"
    },
    {
        "engDate": "1934-05-14",
        "nepDate": "01.02.1991"
    },
    {
        "engDate": "1934-05-15",
        "nepDate": "02.02.1991"
    },
    {
        "engDate": "1934-05-16",
        "nepDate": "03.02.1991"
    },
    {
        "engDate": "1934-05-17",
        "nepDate": "04.02.1991"
    },
    {
        "engDate": "1934-05-18",
        "nepDate": "05.02.1991"
    },
    {
        "engDate": "1934-05-19",
        "nepDate": "06.02.1991"
    },
    {
        "engDate": "1934-05-20",
        "nepDate": "07.02.1991"
    },
    {
        "engDate": "1934-05-21",
        "nepDate": "08.02.1991"
    },
    {
        "engDate": "1934-05-22",
        "nepDate": "09.02.1991"
    },
    {
        "engDate": "1934-05-23",
        "nepDate": "10.02.1991"
    },
    {
        "engDate": "1934-05-24",
        "nepDate": "11.02.1991"
    },
    {
        "engDate": "1934-05-25",
        "nepDate": "12.02.1991"
    },
    {
        "engDate": "1934-05-26",
        "nepDate": "13.02.1991"
    },
    {
        "engDate": "1934-05-27",
        "nepDate": "14.02.1991"
    },
    {
        "engDate": "1934-05-28",
        "nepDate": "15.02.1991"
    },
    {
        "engDate": "1934-05-29",
        "nepDate": "16.02.1991"
    },
    {
        "engDate": "1934-05-30",
        "nepDate": "17.02.1991"
    },
    {
        "engDate": "1934-05-31",
        "nepDate": "18.02.1991"
    },
    {
        "engDate": "1934-06-01",
        "nepDate": "19.02.1991"
    },
    {
        "engDate": "1934-06-02",
        "nepDate": "20.02.1991"
    },
    {
        "engDate": "1934-06-03",
        "nepDate": "21.02.1991"
    },
    {
        "engDate": "1934-06-04",
        "nepDate": "22.02.1991"
    },
    {
        "engDate": "1934-06-05",
        "nepDate": "23.02.1991"
    },
    {
        "engDate": "1934-06-06",
        "nepDate": "24.02.1991"
    },
    {
        "engDate": "1934-06-07",
        "nepDate": "25.02.1991"
    },
    {
        "engDate": "1934-06-08",
        "nepDate": "26.02.1991"
    },
    {
        "engDate": "1934-06-09",
        "nepDate": "27.02.1991"
    },
    {
        "engDate": "1934-06-10",
        "nepDate": "28.02.1991"
    },
    {
        "engDate": "1934-06-11",
        "nepDate": "29.02.1991"
    },
    {
        "engDate": "1934-06-12",
        "nepDate": "30.02.1991"
    },
    {
        "engDate": "1934-06-13",
        "nepDate": "31.02.1991"
    },
    {
        "engDate": "1934-06-14",
        "nepDate": "32.02.1991"
    },
    {
        "engDate": "1934-06-15",
        "nepDate": "01.03.1991"
    },
    {
        "engDate": "1934-06-16",
        "nepDate": "02.03.1991"
    },
    {
        "engDate": "1934-06-17",
        "nepDate": "03.03.1991"
    },
    {
        "engDate": "1934-06-18",
        "nepDate": "04.03.1991"
    },
    {
        "engDate": "1934-06-19",
        "nepDate": "05.03.1991"
    },
    {
        "engDate": "1934-06-20",
        "nepDate": "06.03.1991"
    },
    {
        "engDate": "1934-06-21",
        "nepDate": "07.03.1991"
    },
    {
        "engDate": "1934-06-22",
        "nepDate": "08.03.1991"
    },
    {
        "engDate": "1934-06-23",
        "nepDate": "09.03.1991"
    },
    {
        "engDate": "1934-06-24",
        "nepDate": "10.03.1991"
    },
    {
        "engDate": "1934-06-25",
        "nepDate": "11.03.1991"
    },
    {
        "engDate": "1934-06-26",
        "nepDate": "12.03.1991"
    },
    {
        "engDate": "1934-06-27",
        "nepDate": "13.03.1991"
    },
    {
        "engDate": "1934-06-28",
        "nepDate": "14.03.1991"
    },
    {
        "engDate": "1934-06-29",
        "nepDate": "15.03.1991"
    },
    {
        "engDate": "1934-06-30",
        "nepDate": "16.03.1991"
    },
    {
        "engDate": "1934-07-01",
        "nepDate": "17.03.1991"
    },
    {
        "engDate": "1934-07-02",
        "nepDate": "18.03.1991"
    },
    {
        "engDate": "1934-07-03",
        "nepDate": "19.03.1991"
    },
    {
        "engDate": "1934-07-04",
        "nepDate": "20.03.1991"
    },
    {
        "engDate": "1934-07-05",
        "nepDate": "21.03.1991"
    },
    {
        "engDate": "1934-07-06",
        "nepDate": "22.03.1991"
    },
    {
        "engDate": "1934-07-07",
        "nepDate": "23.03.1991"
    },
    {
        "engDate": "1934-07-08",
        "nepDate": "24.03.1991"
    },
    {
        "engDate": "1934-07-09",
        "nepDate": "25.03.1991"
    },
    {
        "engDate": "1934-07-10",
        "nepDate": "26.03.1991"
    },
    {
        "engDate": "1934-07-11",
        "nepDate": "27.03.1991"
    },
    {
        "engDate": "1934-07-12",
        "nepDate": "28.03.1991"
    },
    {
        "engDate": "1934-07-13",
        "nepDate": "29.03.1991"
    },
    {
        "engDate": "1934-07-14",
        "nepDate": "30.03.1991"
    },
    {
        "engDate": "1934-07-15",
        "nepDate": "31.03.1991"
    },
    {
        "engDate": "1934-07-16",
        "nepDate": "01.04.1991"
    },
    {
        "engDate": "1934-07-17",
        "nepDate": "02.04.1991"
    },
    {
        "engDate": "1934-07-18",
        "nepDate": "03.04.1991"
    },
    {
        "engDate": "1934-07-19",
        "nepDate": "04.04.1991"
    },
    {
        "engDate": "1934-07-20",
        "nepDate": "05.04.1991"
    },
    {
        "engDate": "1934-07-21",
        "nepDate": "06.04.1991"
    },
    {
        "engDate": "1934-07-22",
        "nepDate": "07.04.1991"
    },
    {
        "engDate": "1934-07-23",
        "nepDate": "08.04.1991"
    },
    {
        "engDate": "1934-07-24",
        "nepDate": "09.04.1991"
    },
    {
        "engDate": "1934-07-25",
        "nepDate": "10.04.1991"
    },
    {
        "engDate": "1934-07-26",
        "nepDate": "11.04.1991"
    },
    {
        "engDate": "1934-07-27",
        "nepDate": "12.04.1991"
    },
    {
        "engDate": "1934-07-28",
        "nepDate": "13.04.1991"
    },
    {
        "engDate": "1934-07-29",
        "nepDate": "14.04.1991"
    },
    {
        "engDate": "1934-07-30",
        "nepDate": "15.04.1991"
    },
    {
        "engDate": "1934-07-31",
        "nepDate": "16.04.1991"
    },
    {
        "engDate": "1934-08-01",
        "nepDate": "17.04.1991"
    },
    {
        "engDate": "1934-08-02",
        "nepDate": "18.04.1991"
    },
    {
        "engDate": "1934-08-03",
        "nepDate": "19.04.1991"
    },
    {
        "engDate": "1934-08-04",
        "nepDate": "20.04.1991"
    },
    {
        "engDate": "1934-08-05",
        "nepDate": "21.04.1991"
    },
    {
        "engDate": "1934-08-06",
        "nepDate": "22.04.1991"
    },
    {
        "engDate": "1934-08-07",
        "nepDate": "23.04.1991"
    },
    {
        "engDate": "1934-08-08",
        "nepDate": "24.04.1991"
    },
    {
        "engDate": "1934-08-09",
        "nepDate": "25.04.1991"
    },
    {
        "engDate": "1934-08-10",
        "nepDate": "26.04.1991"
    },
    {
        "engDate": "1934-08-11",
        "nepDate": "27.04.1991"
    },
    {
        "engDate": "1934-08-12",
        "nepDate": "28.04.1991"
    },
    {
        "engDate": "1934-08-13",
        "nepDate": "29.04.1991"
    },
    {
        "engDate": "1934-08-14",
        "nepDate": "30.04.1991"
    },
    {
        "engDate": "1934-08-15",
        "nepDate": "31.04.1991"
    },
    {
        "engDate": "1934-08-16",
        "nepDate": "32.04.1991"
    },
    {
        "engDate": "1934-08-17",
        "nepDate": "01.05.1991"
    },
    {
        "engDate": "1934-08-18",
        "nepDate": "02.05.1991"
    },
    {
        "engDate": "1934-08-19",
        "nepDate": "03.05.1991"
    },
    {
        "engDate": "1934-08-20",
        "nepDate": "04.05.1991"
    },
    {
        "engDate": "1934-08-21",
        "nepDate": "05.05.1991"
    },
    {
        "engDate": "1934-08-22",
        "nepDate": "06.05.1991"
    },
    {
        "engDate": "1934-08-23",
        "nepDate": "07.05.1991"
    },
    {
        "engDate": "1934-08-24",
        "nepDate": "08.05.1991"
    },
    {
        "engDate": "1934-08-25",
        "nepDate": "09.05.1991"
    },
    {
        "engDate": "1934-08-26",
        "nepDate": "10.05.1991"
    },
    {
        "engDate": "1934-08-27",
        "nepDate": "11.05.1991"
    },
    {
        "engDate": "1934-08-28",
        "nepDate": "12.05.1991"
    },
    {
        "engDate": "1934-08-29",
        "nepDate": "13.05.1991"
    },
    {
        "engDate": "1934-08-30",
        "nepDate": "14.05.1991"
    },
    {
        "engDate": "1934-08-31",
        "nepDate": "15.05.1991"
    },
    {
        "engDate": "1934-09-01",
        "nepDate": "16.05.1991"
    },
    {
        "engDate": "1934-09-02",
        "nepDate": "17.05.1991"
    },
    {
        "engDate": "1934-09-03",
        "nepDate": "18.05.1991"
    },
    {
        "engDate": "1934-09-04",
        "nepDate": "19.05.1991"
    },
    {
        "engDate": "1934-09-05",
        "nepDate": "20.05.1991"
    },
    {
        "engDate": "1934-09-06",
        "nepDate": "21.05.1991"
    },
    {
        "engDate": "1934-09-07",
        "nepDate": "22.05.1991"
    },
    {
        "engDate": "1934-09-08",
        "nepDate": "23.05.1991"
    },
    {
        "engDate": "1934-09-09",
        "nepDate": "24.05.1991"
    },
    {
        "engDate": "1934-09-10",
        "nepDate": "25.05.1991"
    },
    {
        "engDate": "1934-09-11",
        "nepDate": "26.05.1991"
    },
    {
        "engDate": "1934-09-12",
        "nepDate": "27.05.1991"
    },
    {
        "engDate": "1934-09-13",
        "nepDate": "28.05.1991"
    },
    {
        "engDate": "1934-09-14",
        "nepDate": "29.05.1991"
    },
    {
        "engDate": "1934-09-15",
        "nepDate": "30.05.1991"
    },
    {
        "engDate": "1934-09-16",
        "nepDate": "31.05.1991"
    },
    {
        "engDate": "1934-09-17",
        "nepDate": "01.06.1991"
    },
    {
        "engDate": "1934-09-18",
        "nepDate": "02.06.1991"
    },
    {
        "engDate": "1934-09-19",
        "nepDate": "03.06.1991"
    },
    {
        "engDate": "1934-09-20",
        "nepDate": "04.06.1991"
    },
    {
        "engDate": "1934-09-21",
        "nepDate": "05.06.1991"
    },
    {
        "engDate": "1934-09-22",
        "nepDate": "06.06.1991"
    },
    {
        "engDate": "1934-09-23",
        "nepDate": "07.06.1991"
    },
    {
        "engDate": "1934-09-24",
        "nepDate": "08.06.1991"
    },
    {
        "engDate": "1934-09-25",
        "nepDate": "09.06.1991"
    },
    {
        "engDate": "1934-09-26",
        "nepDate": "10.06.1991"
    },
    {
        "engDate": "1934-09-27",
        "nepDate": "11.06.1991"
    },
    {
        "engDate": "1934-09-28",
        "nepDate": "12.06.1991"
    },
    {
        "engDate": "1934-09-29",
        "nepDate": "13.06.1991"
    },
    {
        "engDate": "1934-09-30",
        "nepDate": "14.06.1991"
    },
    {
        "engDate": "1934-10-01",
        "nepDate": "15.06.1991"
    },
    {
        "engDate": "1934-10-02",
        "nepDate": "16.06.1991"
    },
    {
        "engDate": "1934-10-03",
        "nepDate": "17.06.1991"
    },
    {
        "engDate": "1934-10-04",
        "nepDate": "18.06.1991"
    },
    {
        "engDate": "1934-10-05",
        "nepDate": "19.06.1991"
    },
    {
        "engDate": "1934-10-06",
        "nepDate": "20.06.1991"
    },
    {
        "engDate": "1934-10-07",
        "nepDate": "21.06.1991"
    },
    {
        "engDate": "1934-10-08",
        "nepDate": "22.06.1991"
    },
    {
        "engDate": "1934-10-09",
        "nepDate": "23.06.1991"
    },
    {
        "engDate": "1934-10-10",
        "nepDate": "24.06.1991"
    },
    {
        "engDate": "1934-10-11",
        "nepDate": "25.06.1991"
    },
    {
        "engDate": "1934-10-12",
        "nepDate": "26.06.1991"
    },
    {
        "engDate": "1934-10-13",
        "nepDate": "27.06.1991"
    },
    {
        "engDate": "1934-10-14",
        "nepDate": "28.06.1991"
    },
    {
        "engDate": "1934-10-15",
        "nepDate": "29.06.1991"
    },
    {
        "engDate": "1934-10-16",
        "nepDate": "30.06.1991"
    },
    {
        "engDate": "1934-10-17",
        "nepDate": "01.07.1991"
    },
    {
        "engDate": "1934-10-18",
        "nepDate": "02.07.1991"
    },
    {
        "engDate": "1934-10-19",
        "nepDate": "03.07.1991"
    },
    {
        "engDate": "1934-10-20",
        "nepDate": "04.07.1991"
    },
    {
        "engDate": "1934-10-21",
        "nepDate": "05.07.1991"
    },
    {
        "engDate": "1934-10-22",
        "nepDate": "06.07.1991"
    },
    {
        "engDate": "1934-10-23",
        "nepDate": "07.07.1991"
    },
    {
        "engDate": "1934-10-24",
        "nepDate": "08.07.1991"
    },
    {
        "engDate": "1934-10-25",
        "nepDate": "09.07.1991"
    },
    {
        "engDate": "1934-10-26",
        "nepDate": "10.07.1991"
    },
    {
        "engDate": "1934-10-27",
        "nepDate": "11.07.1991"
    },
    {
        "engDate": "1934-10-28",
        "nepDate": "12.07.1991"
    },
    {
        "engDate": "1934-10-29",
        "nepDate": "13.07.1991"
    },
    {
        "engDate": "1934-10-30",
        "nepDate": "14.07.1991"
    },
    {
        "engDate": "1934-10-31",
        "nepDate": "15.07.1991"
    },
    {
        "engDate": "1934-11-01",
        "nepDate": "16.07.1991"
    },
    {
        "engDate": "1934-11-02",
        "nepDate": "17.07.1991"
    },
    {
        "engDate": "1934-11-03",
        "nepDate": "18.07.1991"
    },
    {
        "engDate": "1934-11-04",
        "nepDate": "19.07.1991"
    },
    {
        "engDate": "1934-11-05",
        "nepDate": "20.07.1991"
    },
    {
        "engDate": "1934-11-06",
        "nepDate": "21.07.1991"
    },
    {
        "engDate": "1934-11-07",
        "nepDate": "22.07.1991"
    },
    {
        "engDate": "1934-11-08",
        "nepDate": "23.07.1991"
    },
    {
        "engDate": "1934-11-09",
        "nepDate": "24.07.1991"
    },
    {
        "engDate": "1934-11-10",
        "nepDate": "25.07.1991"
    },
    {
        "engDate": "1934-11-11",
        "nepDate": "26.07.1991"
    },
    {
        "engDate": "1934-11-12",
        "nepDate": "27.07.1991"
    },
    {
        "engDate": "1934-11-13",
        "nepDate": "28.07.1991"
    },
    {
        "engDate": "1934-11-14",
        "nepDate": "29.07.1991"
    },
    {
        "engDate": "1934-11-15",
        "nepDate": "30.07.1991"
    },
    {
        "engDate": "1934-11-16",
        "nepDate": "01.08.1991"
    },
    {
        "engDate": "1934-11-17",
        "nepDate": "02.08.1991"
    },
    {
        "engDate": "1934-11-18",
        "nepDate": "03.08.1991"
    },
    {
        "engDate": "1934-11-19",
        "nepDate": "04.08.1991"
    },
    {
        "engDate": "1934-11-20",
        "nepDate": "05.08.1991"
    },
    {
        "engDate": "1934-11-21",
        "nepDate": "06.08.1991"
    },
    {
        "engDate": "1934-11-22",
        "nepDate": "07.08.1991"
    },
    {
        "engDate": "1934-11-23",
        "nepDate": "08.08.1991"
    },
    {
        "engDate": "1934-11-24",
        "nepDate": "09.08.1991"
    },
    {
        "engDate": "1934-11-25",
        "nepDate": "10.08.1991"
    },
    {
        "engDate": "1934-11-26",
        "nepDate": "11.08.1991"
    },
    {
        "engDate": "1934-11-27",
        "nepDate": "12.08.1991"
    },
    {
        "engDate": "1934-11-28",
        "nepDate": "13.08.1991"
    },
    {
        "engDate": "1934-11-29",
        "nepDate": "14.08.1991"
    },
    {
        "engDate": "1934-11-30",
        "nepDate": "15.08.1991"
    },
    {
        "engDate": "1934-12-01",
        "nepDate": "16.08.1991"
    },
    {
        "engDate": "1934-12-02",
        "nepDate": "17.08.1991"
    },
    {
        "engDate": "1934-12-03",
        "nepDate": "18.08.1991"
    },
    {
        "engDate": "1934-12-04",
        "nepDate": "19.08.1991"
    },
    {
        "engDate": "1934-12-05",
        "nepDate": "20.08.1991"
    },
    {
        "engDate": "1934-12-06",
        "nepDate": "21.08.1991"
    },
    {
        "engDate": "1934-12-07",
        "nepDate": "22.08.1991"
    },
    {
        "engDate": "1934-12-08",
        "nepDate": "23.08.1991"
    },
    {
        "engDate": "1934-12-09",
        "nepDate": "24.08.1991"
    },
    {
        "engDate": "1934-12-10",
        "nepDate": "25.08.1991"
    },
    {
        "engDate": "1934-12-11",
        "nepDate": "26.08.1991"
    },
    {
        "engDate": "1934-12-12",
        "nepDate": "27.08.1991"
    },
    {
        "engDate": "1934-12-13",
        "nepDate": "28.08.1991"
    },
    {
        "engDate": "1934-12-14",
        "nepDate": "29.08.1991"
    },
    {
        "engDate": "1934-12-15",
        "nepDate": "30.08.1991"
    },
    {
        "engDate": "1934-12-16",
        "nepDate": "01.09.1991"
    },
    {
        "engDate": "1934-12-17",
        "nepDate": "02.09.1991"
    },
    {
        "engDate": "1934-12-18",
        "nepDate": "03.09.1991"
    },
    {
        "engDate": "1934-12-19",
        "nepDate": "04.09.1991"
    },
    {
        "engDate": "1934-12-20",
        "nepDate": "05.09.1991"
    },
    {
        "engDate": "1934-12-21",
        "nepDate": "06.09.1991"
    },
    {
        "engDate": "1934-12-22",
        "nepDate": "07.09.1991"
    },
    {
        "engDate": "1934-12-23",
        "nepDate": "08.09.1991"
    },
    {
        "engDate": "1934-12-24",
        "nepDate": "09.09.1991"
    },
    {
        "engDate": "1934-12-25",
        "nepDate": "10.09.1991"
    },
    {
        "engDate": "1934-12-26",
        "nepDate": "11.09.1991"
    },
    {
        "engDate": "1934-12-27",
        "nepDate": "12.09.1991"
    },
    {
        "engDate": "1934-12-28",
        "nepDate": "13.09.1991"
    },
    {
        "engDate": "1934-12-29",
        "nepDate": "14.09.1991"
    },
    {
        "engDate": "1934-12-30",
        "nepDate": "15.09.1991"
    },
    {
        "engDate": "1934-12-31",
        "nepDate": "16.09.1991"
    },
    {
        "engDate": "1935-01-01",
        "nepDate": "17.09.1991"
    },
    {
        "engDate": "1935-01-02",
        "nepDate": "18.09.1991"
    },
    {
        "engDate": "1935-01-03",
        "nepDate": "19.09.1991"
    },
    {
        "engDate": "1935-01-04",
        "nepDate": "20.09.1991"
    },
    {
        "engDate": "1935-01-05",
        "nepDate": "21.09.1991"
    },
    {
        "engDate": "1935-01-06",
        "nepDate": "22.09.1991"
    },
    {
        "engDate": "1935-01-07",
        "nepDate": "23.09.1991"
    },
    {
        "engDate": "1935-01-08",
        "nepDate": "24.09.1991"
    },
    {
        "engDate": "1935-01-09",
        "nepDate": "25.09.1991"
    },
    {
        "engDate": "1935-01-10",
        "nepDate": "26.09.1991"
    },
    {
        "engDate": "1935-01-11",
        "nepDate": "27.09.1991"
    },
    {
        "engDate": "1935-01-12",
        "nepDate": "28.09.1991"
    },
    {
        "engDate": "1935-01-13",
        "nepDate": "29.09.1991"
    },
    {
        "engDate": "1935-01-14",
        "nepDate": "01.10.1991"
    },
    {
        "engDate": "1935-01-15",
        "nepDate": "02.10.1991"
    },
    {
        "engDate": "1935-01-16",
        "nepDate": "03.10.1991"
    },
    {
        "engDate": "1935-01-17",
        "nepDate": "04.10.1991"
    },
    {
        "engDate": "1935-01-18",
        "nepDate": "05.10.1991"
    },
    {
        "engDate": "1935-01-19",
        "nepDate": "06.10.1991"
    },
    {
        "engDate": "1935-01-20",
        "nepDate": "07.10.1991"
    },
    {
        "engDate": "1935-01-21",
        "nepDate": "08.10.1991"
    },
    {
        "engDate": "1935-01-22",
        "nepDate": "09.10.1991"
    },
    {
        "engDate": "1935-01-23",
        "nepDate": "10.10.1991"
    },
    {
        "engDate": "1935-01-24",
        "nepDate": "11.10.1991"
    },
    {
        "engDate": "1935-01-25",
        "nepDate": "12.10.1991"
    },
    {
        "engDate": "1935-01-26",
        "nepDate": "13.10.1991"
    },
    {
        "engDate": "1935-01-27",
        "nepDate": "14.10.1991"
    },
    {
        "engDate": "1935-01-28",
        "nepDate": "15.10.1991"
    },
    {
        "engDate": "1935-01-29",
        "nepDate": "16.10.1991"
    },
    {
        "engDate": "1935-01-30",
        "nepDate": "17.10.1991"
    },
    {
        "engDate": "1935-01-31",
        "nepDate": "18.10.1991"
    },
    {
        "engDate": "1935-02-01",
        "nepDate": "19.10.1991"
    },
    {
        "engDate": "1935-02-02",
        "nepDate": "20.10.1991"
    },
    {
        "engDate": "1935-02-03",
        "nepDate": "21.10.1991"
    },
    {
        "engDate": "1935-02-04",
        "nepDate": "22.10.1991"
    },
    {
        "engDate": "1935-02-05",
        "nepDate": "23.10.1991"
    },
    {
        "engDate": "1935-02-06",
        "nepDate": "24.10.1991"
    },
    {
        "engDate": "1935-02-07",
        "nepDate": "25.10.1991"
    },
    {
        "engDate": "1935-02-08",
        "nepDate": "26.10.1991"
    },
    {
        "engDate": "1935-02-09",
        "nepDate": "27.10.1991"
    },
    {
        "engDate": "1935-02-10",
        "nepDate": "28.10.1991"
    },
    {
        "engDate": "1935-02-11",
        "nepDate": "29.10.1991"
    },
    {
        "engDate": "1935-02-12",
        "nepDate": "01.11.1991"
    },
    {
        "engDate": "1935-02-13",
        "nepDate": "02.11.1991"
    },
    {
        "engDate": "1935-02-14",
        "nepDate": "03.11.1991"
    },
    {
        "engDate": "1935-02-15",
        "nepDate": "04.11.1991"
    },
    {
        "engDate": "1935-02-16",
        "nepDate": "05.11.1991"
    },
    {
        "engDate": "1935-02-17",
        "nepDate": "06.11.1991"
    },
    {
        "engDate": "1935-02-18",
        "nepDate": "07.11.1991"
    },
    {
        "engDate": "1935-02-19",
        "nepDate": "08.11.1991"
    },
    {
        "engDate": "1935-02-20",
        "nepDate": "09.11.1991"
    },
    {
        "engDate": "1935-02-21",
        "nepDate": "10.11.1991"
    },
    {
        "engDate": "1935-02-22",
        "nepDate": "11.11.1991"
    },
    {
        "engDate": "1935-02-23",
        "nepDate": "12.11.1991"
    },
    {
        "engDate": "1935-02-24",
        "nepDate": "13.11.1991"
    },
    {
        "engDate": "1935-02-25",
        "nepDate": "14.11.1991"
    },
    {
        "engDate": "1935-02-26",
        "nepDate": "15.11.1991"
    },
    {
        "engDate": "1935-02-27",
        "nepDate": "16.11.1991"
    },
    {
        "engDate": "1935-02-28",
        "nepDate": "17.11.1991"
    },
    {
        "engDate": "1935-03-01",
        "nepDate": "18.11.1991"
    },
    {
        "engDate": "1935-03-02",
        "nepDate": "19.11.1991"
    },
    {
        "engDate": "1935-03-03",
        "nepDate": "20.11.1991"
    },
    {
        "engDate": "1935-03-04",
        "nepDate": "21.11.1991"
    },
    {
        "engDate": "1935-03-05",
        "nepDate": "22.11.1991"
    },
    {
        "engDate": "1935-03-06",
        "nepDate": "23.11.1991"
    },
    {
        "engDate": "1935-03-07",
        "nepDate": "24.11.1991"
    },
    {
        "engDate": "1935-03-08",
        "nepDate": "25.11.1991"
    },
    {
        "engDate": "1935-03-09",
        "nepDate": "26.11.1991"
    },
    {
        "engDate": "1935-03-10",
        "nepDate": "27.11.1991"
    },
    {
        "engDate": "1935-03-11",
        "nepDate": "28.11.1991"
    },
    {
        "engDate": "1935-03-12",
        "nepDate": "29.11.1991"
    },
    {
        "engDate": "1935-03-13",
        "nepDate": "30.11.1991"
    },
    {
        "engDate": "1935-03-14",
        "nepDate": "01.12.1991"
    },
    {
        "engDate": "1935-03-15",
        "nepDate": "02.12.1991"
    },
    {
        "engDate": "1935-03-16",
        "nepDate": "03.12.1991"
    },
    {
        "engDate": "1935-03-17",
        "nepDate": "04.12.1991"
    },
    {
        "engDate": "1935-03-18",
        "nepDate": "05.12.1991"
    },
    {
        "engDate": "1935-03-19",
        "nepDate": "06.12.1991"
    },
    {
        "engDate": "1935-03-20",
        "nepDate": "07.12.1991"
    },
    {
        "engDate": "1935-03-21",
        "nepDate": "08.12.1991"
    },
    {
        "engDate": "1935-03-22",
        "nepDate": "09.12.1991"
    },
    {
        "engDate": "1935-03-23",
        "nepDate": "10.12.1991"
    },
    {
        "engDate": "1935-03-24",
        "nepDate": "11.12.1991"
    },
    {
        "engDate": "1935-03-25",
        "nepDate": "12.12.1991"
    },
    {
        "engDate": "1935-03-26",
        "nepDate": "13.12.1991"
    },
    {
        "engDate": "1935-03-27",
        "nepDate": "14.12.1991"
    },
    {
        "engDate": "1935-03-28",
        "nepDate": "15.12.1991"
    },
    {
        "engDate": "1935-03-29",
        "nepDate": "16.12.1991"
    },
    {
        "engDate": "1935-03-30",
        "nepDate": "17.12.1991"
    },
    {
        "engDate": "1935-03-31",
        "nepDate": "18.12.1991"
    },
    {
        "engDate": "1935-04-01",
        "nepDate": "19.12.1991"
    },
    {
        "engDate": "1935-04-02",
        "nepDate": "20.12.1991"
    },
    {
        "engDate": "1935-04-03",
        "nepDate": "21.12.1991"
    },
    {
        "engDate": "1935-04-04",
        "nepDate": "22.12.1991"
    },
    {
        "engDate": "1935-04-05",
        "nepDate": "23.12.1991"
    },
    {
        "engDate": "1935-04-06",
        "nepDate": "24.12.1991"
    },
    {
        "engDate": "1935-04-07",
        "nepDate": "25.12.1991"
    },
    {
        "engDate": "1935-04-08",
        "nepDate": "26.12.1991"
    },
    {
        "engDate": "1935-04-09",
        "nepDate": "27.12.1991"
    },
    {
        "engDate": "1935-04-10",
        "nepDate": "28.12.1991"
    },
    {
        "engDate": "1935-04-11",
        "nepDate": "29.12.1991"
    },
    {
        "engDate": "1935-04-12",
        "nepDate": "30.12.1991"
    },
    {
        "engDate": "1935-04-13",
        "nepDate": "01.01.1992"
    },
    {
        "engDate": "1935-04-14",
        "nepDate": "02.01.1992"
    },
    {
        "engDate": "1935-04-15",
        "nepDate": "03.01.1992"
    },
    {
        "engDate": "1935-04-16",
        "nepDate": "04.01.1992"
    },
    {
        "engDate": "1935-04-17",
        "nepDate": "05.01.1992"
    },
    {
        "engDate": "1935-04-18",
        "nepDate": "06.01.1992"
    },
    {
        "engDate": "1935-04-19",
        "nepDate": "07.01.1992"
    },
    {
        "engDate": "1935-04-20",
        "nepDate": "08.01.1992"
    },
    {
        "engDate": "1935-04-21",
        "nepDate": "09.01.1992"
    },
    {
        "engDate": "1935-04-22",
        "nepDate": "10.01.1992"
    },
    {
        "engDate": "1935-04-23",
        "nepDate": "11.01.1992"
    },
    {
        "engDate": "1935-04-24",
        "nepDate": "12.01.1992"
    },
    {
        "engDate": "1935-04-25",
        "nepDate": "13.01.1992"
    },
    {
        "engDate": "1935-04-26",
        "nepDate": "14.01.1992"
    },
    {
        "engDate": "1935-04-27",
        "nepDate": "15.01.1992"
    },
    {
        "engDate": "1935-04-28",
        "nepDate": "16.01.1992"
    },
    {
        "engDate": "1935-04-29",
        "nepDate": "17.01.1992"
    },
    {
        "engDate": "1935-04-30",
        "nepDate": "18.01.1992"
    },
    {
        "engDate": "1935-05-01",
        "nepDate": "19.01.1992"
    },
    {
        "engDate": "1935-05-02",
        "nepDate": "20.01.1992"
    },
    {
        "engDate": "1935-05-03",
        "nepDate": "21.01.1992"
    },
    {
        "engDate": "1935-05-04",
        "nepDate": "22.01.1992"
    },
    {
        "engDate": "1935-05-05",
        "nepDate": "23.01.1992"
    },
    {
        "engDate": "1935-05-06",
        "nepDate": "24.01.1992"
    },
    {
        "engDate": "1935-05-07",
        "nepDate": "25.01.1992"
    },
    {
        "engDate": "1935-05-08",
        "nepDate": "26.01.1992"
    },
    {
        "engDate": "1935-05-09",
        "nepDate": "27.01.1992"
    },
    {
        "engDate": "1935-05-10",
        "nepDate": "28.01.1992"
    },
    {
        "engDate": "1935-05-11",
        "nepDate": "29.01.1992"
    },
    {
        "engDate": "1935-05-12",
        "nepDate": "30.01.1992"
    },
    {
        "engDate": "1935-05-13",
        "nepDate": "31.01.1992"
    },
    {
        "engDate": "1935-05-14",
        "nepDate": "01.02.1992"
    },
    {
        "engDate": "1935-05-15",
        "nepDate": "02.02.1992"
    },
    {
        "engDate": "1935-05-16",
        "nepDate": "03.02.1992"
    },
    {
        "engDate": "1935-05-17",
        "nepDate": "04.02.1992"
    },
    {
        "engDate": "1935-05-18",
        "nepDate": "05.02.1992"
    },
    {
        "engDate": "1935-05-19",
        "nepDate": "06.02.1992"
    },
    {
        "engDate": "1935-05-20",
        "nepDate": "07.02.1992"
    },
    {
        "engDate": "1935-05-21",
        "nepDate": "08.02.1992"
    },
    {
        "engDate": "1935-05-22",
        "nepDate": "09.02.1992"
    },
    {
        "engDate": "1935-05-23",
        "nepDate": "10.02.1992"
    },
    {
        "engDate": "1935-05-24",
        "nepDate": "11.02.1992"
    },
    {
        "engDate": "1935-05-25",
        "nepDate": "12.02.1992"
    },
    {
        "engDate": "1935-05-26",
        "nepDate": "13.02.1992"
    },
    {
        "engDate": "1935-05-27",
        "nepDate": "14.02.1992"
    },
    {
        "engDate": "1935-05-28",
        "nepDate": "15.02.1992"
    },
    {
        "engDate": "1935-05-29",
        "nepDate": "16.02.1992"
    },
    {
        "engDate": "1935-05-30",
        "nepDate": "17.02.1992"
    },
    {
        "engDate": "1935-05-31",
        "nepDate": "18.02.1992"
    },
    {
        "engDate": "1935-06-01",
        "nepDate": "19.02.1992"
    },
    {
        "engDate": "1935-06-02",
        "nepDate": "20.02.1992"
    },
    {
        "engDate": "1935-06-03",
        "nepDate": "21.02.1992"
    },
    {
        "engDate": "1935-06-04",
        "nepDate": "22.02.1992"
    },
    {
        "engDate": "1935-06-05",
        "nepDate": "23.02.1992"
    },
    {
        "engDate": "1935-06-06",
        "nepDate": "24.02.1992"
    },
    {
        "engDate": "1935-06-07",
        "nepDate": "25.02.1992"
    },
    {
        "engDate": "1935-06-08",
        "nepDate": "26.02.1992"
    },
    {
        "engDate": "1935-06-09",
        "nepDate": "27.02.1992"
    },
    {
        "engDate": "1935-06-10",
        "nepDate": "28.02.1992"
    },
    {
        "engDate": "1935-06-11",
        "nepDate": "29.02.1992"
    },
    {
        "engDate": "1935-06-12",
        "nepDate": "30.02.1992"
    },
    {
        "engDate": "1935-06-13",
        "nepDate": "31.02.1992"
    },
    {
        "engDate": "1935-06-14",
        "nepDate": "32.02.1992"
    },
    {
        "engDate": "1935-06-15",
        "nepDate": "01.03.1992"
    },
    {
        "engDate": "1935-06-16",
        "nepDate": "02.03.1992"
    },
    {
        "engDate": "1935-06-17",
        "nepDate": "03.03.1992"
    },
    {
        "engDate": "1935-06-18",
        "nepDate": "04.03.1992"
    },
    {
        "engDate": "1935-06-19",
        "nepDate": "05.03.1992"
    },
    {
        "engDate": "1935-06-20",
        "nepDate": "06.03.1992"
    },
    {
        "engDate": "1935-06-21",
        "nepDate": "07.03.1992"
    },
    {
        "engDate": "1935-06-22",
        "nepDate": "08.03.1992"
    },
    {
        "engDate": "1935-06-23",
        "nepDate": "09.03.1992"
    },
    {
        "engDate": "1935-06-24",
        "nepDate": "10.03.1992"
    },
    {
        "engDate": "1935-06-25",
        "nepDate": "11.03.1992"
    },
    {
        "engDate": "1935-06-26",
        "nepDate": "12.03.1992"
    },
    {
        "engDate": "1935-06-27",
        "nepDate": "13.03.1992"
    },
    {
        "engDate": "1935-06-28",
        "nepDate": "14.03.1992"
    },
    {
        "engDate": "1935-06-29",
        "nepDate": "15.03.1992"
    },
    {
        "engDate": "1935-06-30",
        "nepDate": "16.03.1992"
    },
    {
        "engDate": "1935-07-01",
        "nepDate": "17.03.1992"
    },
    {
        "engDate": "1935-07-02",
        "nepDate": "18.03.1992"
    },
    {
        "engDate": "1935-07-03",
        "nepDate": "19.03.1992"
    },
    {
        "engDate": "1935-07-04",
        "nepDate": "20.03.1992"
    },
    {
        "engDate": "1935-07-05",
        "nepDate": "21.03.1992"
    },
    {
        "engDate": "1935-07-06",
        "nepDate": "22.03.1992"
    },
    {
        "engDate": "1935-07-07",
        "nepDate": "23.03.1992"
    },
    {
        "engDate": "1935-07-08",
        "nepDate": "24.03.1992"
    },
    {
        "engDate": "1935-07-09",
        "nepDate": "25.03.1992"
    },
    {
        "engDate": "1935-07-10",
        "nepDate": "26.03.1992"
    },
    {
        "engDate": "1935-07-11",
        "nepDate": "27.03.1992"
    },
    {
        "engDate": "1935-07-12",
        "nepDate": "28.03.1992"
    },
    {
        "engDate": "1935-07-13",
        "nepDate": "29.03.1992"
    },
    {
        "engDate": "1935-07-14",
        "nepDate": "30.03.1992"
    },
    {
        "engDate": "1935-07-15",
        "nepDate": "31.03.1992"
    },
    {
        "engDate": "1935-07-16",
        "nepDate": "01.04.1992"
    },
    {
        "engDate": "1935-07-17",
        "nepDate": "02.04.1992"
    },
    {
        "engDate": "1935-07-18",
        "nepDate": "03.04.1992"
    },
    {
        "engDate": "1935-07-19",
        "nepDate": "04.04.1992"
    },
    {
        "engDate": "1935-07-20",
        "nepDate": "05.04.1992"
    },
    {
        "engDate": "1935-07-21",
        "nepDate": "06.04.1992"
    },
    {
        "engDate": "1935-07-22",
        "nepDate": "07.04.1992"
    },
    {
        "engDate": "1935-07-23",
        "nepDate": "08.04.1992"
    },
    {
        "engDate": "1935-07-24",
        "nepDate": "09.04.1992"
    },
    {
        "engDate": "1935-07-25",
        "nepDate": "10.04.1992"
    },
    {
        "engDate": "1935-07-26",
        "nepDate": "11.04.1992"
    },
    {
        "engDate": "1935-07-27",
        "nepDate": "12.04.1992"
    },
    {
        "engDate": "1935-07-28",
        "nepDate": "13.04.1992"
    },
    {
        "engDate": "1935-07-29",
        "nepDate": "14.04.1992"
    },
    {
        "engDate": "1935-07-30",
        "nepDate": "15.04.1992"
    },
    {
        "engDate": "1935-07-31",
        "nepDate": "16.04.1992"
    },
    {
        "engDate": "1935-08-01",
        "nepDate": "17.04.1992"
    },
    {
        "engDate": "1935-08-02",
        "nepDate": "18.04.1992"
    },
    {
        "engDate": "1935-08-03",
        "nepDate": "19.04.1992"
    },
    {
        "engDate": "1935-08-04",
        "nepDate": "20.04.1992"
    },
    {
        "engDate": "1935-08-05",
        "nepDate": "21.04.1992"
    },
    {
        "engDate": "1935-08-06",
        "nepDate": "22.04.1992"
    },
    {
        "engDate": "1935-08-07",
        "nepDate": "23.04.1992"
    },
    {
        "engDate": "1935-08-08",
        "nepDate": "24.04.1992"
    },
    {
        "engDate": "1935-08-09",
        "nepDate": "25.04.1992"
    },
    {
        "engDate": "1935-08-10",
        "nepDate": "26.04.1992"
    },
    {
        "engDate": "1935-08-11",
        "nepDate": "27.04.1992"
    },
    {
        "engDate": "1935-08-12",
        "nepDate": "28.04.1992"
    },
    {
        "engDate": "1935-08-13",
        "nepDate": "29.04.1992"
    },
    {
        "engDate": "1935-08-14",
        "nepDate": "30.04.1992"
    },
    {
        "engDate": "1935-08-15",
        "nepDate": "31.04.1992"
    },
    {
        "engDate": "1935-08-16",
        "nepDate": "32.04.1992"
    },
    {
        "engDate": "1935-08-17",
        "nepDate": "01.05.1992"
    },
    {
        "engDate": "1935-08-18",
        "nepDate": "02.05.1992"
    },
    {
        "engDate": "1935-08-19",
        "nepDate": "03.05.1992"
    },
    {
        "engDate": "1935-08-20",
        "nepDate": "04.05.1992"
    },
    {
        "engDate": "1935-08-21",
        "nepDate": "05.05.1992"
    },
    {
        "engDate": "1935-08-22",
        "nepDate": "06.05.1992"
    },
    {
        "engDate": "1935-08-23",
        "nepDate": "07.05.1992"
    },
    {
        "engDate": "1935-08-24",
        "nepDate": "08.05.1992"
    },
    {
        "engDate": "1935-08-25",
        "nepDate": "09.05.1992"
    },
    {
        "engDate": "1935-08-26",
        "nepDate": "10.05.1992"
    },
    {
        "engDate": "1935-08-27",
        "nepDate": "11.05.1992"
    },
    {
        "engDate": "1935-08-28",
        "nepDate": "12.05.1992"
    },
    {
        "engDate": "1935-08-29",
        "nepDate": "13.05.1992"
    },
    {
        "engDate": "1935-08-30",
        "nepDate": "14.05.1992"
    },
    {
        "engDate": "1935-08-31",
        "nepDate": "15.05.1992"
    },
    {
        "engDate": "1935-09-01",
        "nepDate": "16.05.1992"
    },
    {
        "engDate": "1935-09-02",
        "nepDate": "17.05.1992"
    },
    {
        "engDate": "1935-09-03",
        "nepDate": "18.05.1992"
    },
    {
        "engDate": "1935-09-04",
        "nepDate": "19.05.1992"
    },
    {
        "engDate": "1935-09-05",
        "nepDate": "20.05.1992"
    },
    {
        "engDate": "1935-09-06",
        "nepDate": "21.05.1992"
    },
    {
        "engDate": "1935-09-07",
        "nepDate": "22.05.1992"
    },
    {
        "engDate": "1935-09-08",
        "nepDate": "23.05.1992"
    },
    {
        "engDate": "1935-09-09",
        "nepDate": "24.05.1992"
    },
    {
        "engDate": "1935-09-10",
        "nepDate": "25.05.1992"
    },
    {
        "engDate": "1935-09-11",
        "nepDate": "26.05.1992"
    },
    {
        "engDate": "1935-09-12",
        "nepDate": "27.05.1992"
    },
    {
        "engDate": "1935-09-13",
        "nepDate": "28.05.1992"
    },
    {
        "engDate": "1935-09-14",
        "nepDate": "29.05.1992"
    },
    {
        "engDate": "1935-09-15",
        "nepDate": "30.05.1992"
    },
    {
        "engDate": "1935-09-16",
        "nepDate": "31.05.1992"
    },
    {
        "engDate": "1935-09-17",
        "nepDate": "01.06.1992"
    },
    {
        "engDate": "1935-09-18",
        "nepDate": "02.06.1992"
    },
    {
        "engDate": "1935-09-19",
        "nepDate": "03.06.1992"
    },
    {
        "engDate": "1935-09-20",
        "nepDate": "04.06.1992"
    },
    {
        "engDate": "1935-09-21",
        "nepDate": "05.06.1992"
    },
    {
        "engDate": "1935-09-22",
        "nepDate": "06.06.1992"
    },
    {
        "engDate": "1935-09-23",
        "nepDate": "07.06.1992"
    },
    {
        "engDate": "1935-09-24",
        "nepDate": "08.06.1992"
    },
    {
        "engDate": "1935-09-25",
        "nepDate": "09.06.1992"
    },
    {
        "engDate": "1935-09-26",
        "nepDate": "10.06.1992"
    },
    {
        "engDate": "1935-09-27",
        "nepDate": "11.06.1992"
    },
    {
        "engDate": "1935-09-28",
        "nepDate": "12.06.1992"
    },
    {
        "engDate": "1935-09-29",
        "nepDate": "13.06.1992"
    },
    {
        "engDate": "1935-09-30",
        "nepDate": "14.06.1992"
    },
    {
        "engDate": "1935-10-01",
        "nepDate": "15.06.1992"
    },
    {
        "engDate": "1935-10-02",
        "nepDate": "16.06.1992"
    },
    {
        "engDate": "1935-10-03",
        "nepDate": "17.06.1992"
    },
    {
        "engDate": "1935-10-04",
        "nepDate": "18.06.1992"
    },
    {
        "engDate": "1935-10-05",
        "nepDate": "19.06.1992"
    },
    {
        "engDate": "1935-10-06",
        "nepDate": "20.06.1992"
    },
    {
        "engDate": "1935-10-07",
        "nepDate": "21.06.1992"
    },
    {
        "engDate": "1935-10-08",
        "nepDate": "22.06.1992"
    },
    {
        "engDate": "1935-10-09",
        "nepDate": "23.06.1992"
    },
    {
        "engDate": "1935-10-10",
        "nepDate": "24.06.1992"
    },
    {
        "engDate": "1935-10-11",
        "nepDate": "25.06.1992"
    },
    {
        "engDate": "1935-10-12",
        "nepDate": "26.06.1992"
    },
    {
        "engDate": "1935-10-13",
        "nepDate": "27.06.1992"
    },
    {
        "engDate": "1935-10-14",
        "nepDate": "28.06.1992"
    },
    {
        "engDate": "1935-10-15",
        "nepDate": "29.06.1992"
    },
    {
        "engDate": "1935-10-16",
        "nepDate": "30.06.1992"
    },
    {
        "engDate": "1935-10-17",
        "nepDate": "01.07.1992"
    },
    {
        "engDate": "1935-10-18",
        "nepDate": "02.07.1992"
    },
    {
        "engDate": "1935-10-19",
        "nepDate": "03.07.1992"
    },
    {
        "engDate": "1935-10-20",
        "nepDate": "04.07.1992"
    },
    {
        "engDate": "1935-10-21",
        "nepDate": "05.07.1992"
    },
    {
        "engDate": "1935-10-22",
        "nepDate": "06.07.1992"
    },
    {
        "engDate": "1935-10-23",
        "nepDate": "07.07.1992"
    },
    {
        "engDate": "1935-10-24",
        "nepDate": "08.07.1992"
    },
    {
        "engDate": "1935-10-25",
        "nepDate": "09.07.1992"
    },
    {
        "engDate": "1935-10-26",
        "nepDate": "10.07.1992"
    },
    {
        "engDate": "1935-10-27",
        "nepDate": "11.07.1992"
    },
    {
        "engDate": "1935-10-28",
        "nepDate": "12.07.1992"
    },
    {
        "engDate": "1935-10-29",
        "nepDate": "13.07.1992"
    },
    {
        "engDate": "1935-10-30",
        "nepDate": "14.07.1992"
    },
    {
        "engDate": "1935-10-31",
        "nepDate": "15.07.1992"
    },
    {
        "engDate": "1935-11-01",
        "nepDate": "16.07.1992"
    },
    {
        "engDate": "1935-11-02",
        "nepDate": "17.07.1992"
    },
    {
        "engDate": "1935-11-03",
        "nepDate": "18.07.1992"
    },
    {
        "engDate": "1935-11-04",
        "nepDate": "19.07.1992"
    },
    {
        "engDate": "1935-11-05",
        "nepDate": "20.07.1992"
    },
    {
        "engDate": "1935-11-06",
        "nepDate": "21.07.1992"
    },
    {
        "engDate": "1935-11-07",
        "nepDate": "22.07.1992"
    },
    {
        "engDate": "1935-11-08",
        "nepDate": "23.07.1992"
    },
    {
        "engDate": "1935-11-09",
        "nepDate": "24.07.1992"
    },
    {
        "engDate": "1935-11-10",
        "nepDate": "25.07.1992"
    },
    {
        "engDate": "1935-11-11",
        "nepDate": "26.07.1992"
    },
    {
        "engDate": "1935-11-12",
        "nepDate": "27.07.1992"
    },
    {
        "engDate": "1935-11-13",
        "nepDate": "28.07.1992"
    },
    {
        "engDate": "1935-11-14",
        "nepDate": "29.07.1992"
    },
    {
        "engDate": "1935-11-15",
        "nepDate": "30.07.1992"
    },
    {
        "engDate": "1935-11-16",
        "nepDate": "01.08.1992"
    },
    {
        "engDate": "1935-11-17",
        "nepDate": "02.08.1992"
    },
    {
        "engDate": "1935-11-18",
        "nepDate": "03.08.1992"
    },
    {
        "engDate": "1935-11-19",
        "nepDate": "04.08.1992"
    },
    {
        "engDate": "1935-11-20",
        "nepDate": "05.08.1992"
    },
    {
        "engDate": "1935-11-21",
        "nepDate": "06.08.1992"
    },
    {
        "engDate": "1935-11-22",
        "nepDate": "07.08.1992"
    },
    {
        "engDate": "1935-11-23",
        "nepDate": "08.08.1992"
    },
    {
        "engDate": "1935-11-24",
        "nepDate": "09.08.1992"
    },
    {
        "engDate": "1935-11-25",
        "nepDate": "10.08.1992"
    },
    {
        "engDate": "1935-11-26",
        "nepDate": "11.08.1992"
    },
    {
        "engDate": "1935-11-27",
        "nepDate": "12.08.1992"
    },
    {
        "engDate": "1935-11-28",
        "nepDate": "13.08.1992"
    },
    {
        "engDate": "1935-11-29",
        "nepDate": "14.08.1992"
    },
    {
        "engDate": "1935-11-30",
        "nepDate": "15.08.1992"
    },
    {
        "engDate": "1935-12-01",
        "nepDate": "16.08.1992"
    },
    {
        "engDate": "1935-12-02",
        "nepDate": "17.08.1992"
    },
    {
        "engDate": "1935-12-03",
        "nepDate": "18.08.1992"
    },
    {
        "engDate": "1935-12-04",
        "nepDate": "19.08.1992"
    },
    {
        "engDate": "1935-12-05",
        "nepDate": "20.08.1992"
    },
    {
        "engDate": "1935-12-06",
        "nepDate": "21.08.1992"
    },
    {
        "engDate": "1935-12-07",
        "nepDate": "22.08.1992"
    },
    {
        "engDate": "1935-12-08",
        "nepDate": "23.08.1992"
    },
    {
        "engDate": "1935-12-09",
        "nepDate": "24.08.1992"
    },
    {
        "engDate": "1935-12-10",
        "nepDate": "25.08.1992"
    },
    {
        "engDate": "1935-12-11",
        "nepDate": "26.08.1992"
    },
    {
        "engDate": "1935-12-12",
        "nepDate": "27.08.1992"
    },
    {
        "engDate": "1935-12-13",
        "nepDate": "28.08.1992"
    },
    {
        "engDate": "1935-12-14",
        "nepDate": "29.08.1992"
    },
    {
        "engDate": "1935-12-15",
        "nepDate": "30.08.1992"
    },
    {
        "engDate": "1935-12-16",
        "nepDate": "01.09.1992"
    },
    {
        "engDate": "1935-12-17",
        "nepDate": "02.09.1992"
    },
    {
        "engDate": "1935-12-18",
        "nepDate": "03.09.1992"
    },
    {
        "engDate": "1935-12-19",
        "nepDate": "04.09.1992"
    },
    {
        "engDate": "1935-12-20",
        "nepDate": "05.09.1992"
    },
    {
        "engDate": "1935-12-21",
        "nepDate": "06.09.1992"
    },
    {
        "engDate": "1935-12-22",
        "nepDate": "07.09.1992"
    },
    {
        "engDate": "1935-12-23",
        "nepDate": "08.09.1992"
    },
    {
        "engDate": "1935-12-24",
        "nepDate": "09.09.1992"
    },
    {
        "engDate": "1935-12-25",
        "nepDate": "10.09.1992"
    },
    {
        "engDate": "1935-12-26",
        "nepDate": "11.09.1992"
    },
    {
        "engDate": "1935-12-27",
        "nepDate": "12.09.1992"
    },
    {
        "engDate": "1935-12-28",
        "nepDate": "13.09.1992"
    },
    {
        "engDate": "1935-12-29",
        "nepDate": "14.09.1992"
    },
    {
        "engDate": "1935-12-30",
        "nepDate": "15.09.1992"
    },
    {
        "engDate": "1935-12-31",
        "nepDate": "16.09.1992"
    },
    {
        "engDate": "1936-01-01",
        "nepDate": "17.09.1992"
    },
    {
        "engDate": "1936-01-02",
        "nepDate": "18.09.1992"
    },
    {
        "engDate": "1936-01-03",
        "nepDate": "19.09.1992"
    },
    {
        "engDate": "1936-01-04",
        "nepDate": "20.09.1992"
    },
    {
        "engDate": "1936-01-05",
        "nepDate": "21.09.1992"
    },
    {
        "engDate": "1936-01-06",
        "nepDate": "22.09.1992"
    },
    {
        "engDate": "1936-01-07",
        "nepDate": "23.09.1992"
    },
    {
        "engDate": "1936-01-08",
        "nepDate": "24.09.1992"
    },
    {
        "engDate": "1936-01-09",
        "nepDate": "25.09.1992"
    },
    {
        "engDate": "1936-01-10",
        "nepDate": "26.09.1992"
    },
    {
        "engDate": "1936-01-11",
        "nepDate": "27.09.1992"
    },
    {
        "engDate": "1936-01-12",
        "nepDate": "28.09.1992"
    },
    {
        "engDate": "1936-01-13",
        "nepDate": "29.09.1992"
    },
    {
        "engDate": "1936-01-14",
        "nepDate": "01.10.1992"
    },
    {
        "engDate": "1936-01-15",
        "nepDate": "02.10.1992"
    },
    {
        "engDate": "1936-01-16",
        "nepDate": "03.10.1992"
    },
    {
        "engDate": "1936-01-17",
        "nepDate": "04.10.1992"
    },
    {
        "engDate": "1936-01-18",
        "nepDate": "05.10.1992"
    },
    {
        "engDate": "1936-01-19",
        "nepDate": "06.10.1992"
    },
    {
        "engDate": "1936-01-20",
        "nepDate": "07.10.1992"
    },
    {
        "engDate": "1936-01-21",
        "nepDate": "08.10.1992"
    },
    {
        "engDate": "1936-01-22",
        "nepDate": "09.10.1992"
    },
    {
        "engDate": "1936-01-23",
        "nepDate": "10.10.1992"
    },
    {
        "engDate": "1936-01-24",
        "nepDate": "11.10.1992"
    },
    {
        "engDate": "1936-01-25",
        "nepDate": "12.10.1992"
    },
    {
        "engDate": "1936-01-26",
        "nepDate": "13.10.1992"
    },
    {
        "engDate": "1936-01-27",
        "nepDate": "14.10.1992"
    },
    {
        "engDate": "1936-01-28",
        "nepDate": "15.10.1992"
    },
    {
        "engDate": "1936-01-29",
        "nepDate": "16.10.1992"
    },
    {
        "engDate": "1936-01-30",
        "nepDate": "17.10.1992"
    },
    {
        "engDate": "1936-01-31",
        "nepDate": "18.10.1992"
    },
    {
        "engDate": "1936-02-01",
        "nepDate": "19.10.1992"
    },
    {
        "engDate": "1936-02-02",
        "nepDate": "20.10.1992"
    },
    {
        "engDate": "1936-02-03",
        "nepDate": "21.10.1992"
    },
    {
        "engDate": "1936-02-04",
        "nepDate": "22.10.1992"
    },
    {
        "engDate": "1936-02-05",
        "nepDate": "23.10.1992"
    },
    {
        "engDate": "1936-02-06",
        "nepDate": "24.10.1992"
    },
    {
        "engDate": "1936-02-07",
        "nepDate": "25.10.1992"
    },
    {
        "engDate": "1936-02-08",
        "nepDate": "26.10.1992"
    },
    {
        "engDate": "1936-02-09",
        "nepDate": "27.10.1992"
    },
    {
        "engDate": "1936-02-10",
        "nepDate": "28.10.1992"
    },
    {
        "engDate": "1936-02-11",
        "nepDate": "29.10.1992"
    },
    {
        "engDate": "1936-02-12",
        "nepDate": "30.10.1992"
    },
    {
        "engDate": "1936-02-13",
        "nepDate": "01.11.1992"
    },
    {
        "engDate": "1936-02-14",
        "nepDate": "02.11.1992"
    },
    {
        "engDate": "1936-02-15",
        "nepDate": "03.11.1992"
    },
    {
        "engDate": "1936-02-16",
        "nepDate": "04.11.1992"
    },
    {
        "engDate": "1936-02-17",
        "nepDate": "05.11.1992"
    },
    {
        "engDate": "1936-02-18",
        "nepDate": "06.11.1992"
    },
    {
        "engDate": "1936-02-19",
        "nepDate": "07.11.1992"
    },
    {
        "engDate": "1936-02-20",
        "nepDate": "08.11.1992"
    },
    {
        "engDate": "1936-02-21",
        "nepDate": "09.11.1992"
    },
    {
        "engDate": "1936-02-22",
        "nepDate": "10.11.1992"
    },
    {
        "engDate": "1936-02-23",
        "nepDate": "11.11.1992"
    },
    {
        "engDate": "1936-02-24",
        "nepDate": "12.11.1992"
    },
    {
        "engDate": "1936-02-25",
        "nepDate": "13.11.1992"
    },
    {
        "engDate": "1936-02-26",
        "nepDate": "14.11.1992"
    },
    {
        "engDate": "1936-02-27",
        "nepDate": "15.11.1992"
    },
    {
        "engDate": "1936-02-28",
        "nepDate": "16.11.1992"
    },
    {
        "engDate": "1936-02-29",
        "nepDate": "17.11.1992"
    },
    {
        "engDate": "1936-03-01",
        "nepDate": "18.11.1992"
    },
    {
        "engDate": "1936-03-02",
        "nepDate": "19.11.1992"
    },
    {
        "engDate": "1936-03-03",
        "nepDate": "20.11.1992"
    },
    {
        "engDate": "1936-03-04",
        "nepDate": "21.11.1992"
    },
    {
        "engDate": "1936-03-05",
        "nepDate": "22.11.1992"
    },
    {
        "engDate": "1936-03-06",
        "nepDate": "23.11.1992"
    },
    {
        "engDate": "1936-03-07",
        "nepDate": "24.11.1992"
    },
    {
        "engDate": "1936-03-08",
        "nepDate": "25.11.1992"
    },
    {
        "engDate": "1936-03-09",
        "nepDate": "26.11.1992"
    },
    {
        "engDate": "1936-03-10",
        "nepDate": "27.11.1992"
    },
    {
        "engDate": "1936-03-11",
        "nepDate": "28.11.1992"
    },
    {
        "engDate": "1936-03-12",
        "nepDate": "29.11.1992"
    },
    {
        "engDate": "1936-03-13",
        "nepDate": "01.12.1992"
    },
    {
        "engDate": "1936-03-14",
        "nepDate": "02.12.1992"
    },
    {
        "engDate": "1936-03-15",
        "nepDate": "03.12.1992"
    },
    {
        "engDate": "1936-03-16",
        "nepDate": "04.12.1992"
    },
    {
        "engDate": "1936-03-17",
        "nepDate": "05.12.1992"
    },
    {
        "engDate": "1936-03-18",
        "nepDate": "06.12.1992"
    },
    {
        "engDate": "1936-03-19",
        "nepDate": "07.12.1992"
    },
    {
        "engDate": "1936-03-20",
        "nepDate": "08.12.1992"
    },
    {
        "engDate": "1936-03-21",
        "nepDate": "09.12.1992"
    },
    {
        "engDate": "1936-03-22",
        "nepDate": "10.12.1992"
    },
    {
        "engDate": "1936-03-23",
        "nepDate": "11.12.1992"
    },
    {
        "engDate": "1936-03-24",
        "nepDate": "12.12.1992"
    },
    {
        "engDate": "1936-03-25",
        "nepDate": "13.12.1992"
    },
    {
        "engDate": "1936-03-26",
        "nepDate": "14.12.1992"
    },
    {
        "engDate": "1936-03-27",
        "nepDate": "15.12.1992"
    },
    {
        "engDate": "1936-03-28",
        "nepDate": "16.12.1992"
    },
    {
        "engDate": "1936-03-29",
        "nepDate": "17.12.1992"
    },
    {
        "engDate": "1936-03-30",
        "nepDate": "18.12.1992"
    },
    {
        "engDate": "1936-03-31",
        "nepDate": "19.12.1992"
    },
    {
        "engDate": "1936-04-01",
        "nepDate": "20.12.1992"
    },
    {
        "engDate": "1936-04-02",
        "nepDate": "21.12.1992"
    },
    {
        "engDate": "1936-04-03",
        "nepDate": "22.12.1992"
    },
    {
        "engDate": "1936-04-04",
        "nepDate": "23.12.1992"
    },
    {
        "engDate": "1936-04-05",
        "nepDate": "24.12.1992"
    },
    {
        "engDate": "1936-04-06",
        "nepDate": "25.12.1992"
    },
    {
        "engDate": "1936-04-07",
        "nepDate": "26.12.1992"
    },
    {
        "engDate": "1936-04-08",
        "nepDate": "27.12.1992"
    },
    {
        "engDate": "1936-04-09",
        "nepDate": "28.12.1992"
    },
    {
        "engDate": "1936-04-10",
        "nepDate": "29.12.1992"
    },
    {
        "engDate": "1936-04-11",
        "nepDate": "30.12.1992"
    },
    {
        "engDate": "1936-04-12",
        "nepDate": "31.12.1992"
    },
    {
        "engDate": "1936-04-13",
        "nepDate": "01.01.1993"
    },
    {
        "engDate": "1936-04-14",
        "nepDate": "02.01.1993"
    },
    {
        "engDate": "1936-04-15",
        "nepDate": "03.01.1993"
    },
    {
        "engDate": "1936-04-16",
        "nepDate": "04.01.1993"
    },
    {
        "engDate": "1936-04-17",
        "nepDate": "05.01.1993"
    },
    {
        "engDate": "1936-04-18",
        "nepDate": "06.01.1993"
    },
    {
        "engDate": "1936-04-19",
        "nepDate": "07.01.1993"
    },
    {
        "engDate": "1936-04-20",
        "nepDate": "08.01.1993"
    },
    {
        "engDate": "1936-04-21",
        "nepDate": "09.01.1993"
    },
    {
        "engDate": "1936-04-22",
        "nepDate": "10.01.1993"
    },
    {
        "engDate": "1936-04-23",
        "nepDate": "11.01.1993"
    },
    {
        "engDate": "1936-04-24",
        "nepDate": "12.01.1993"
    },
    {
        "engDate": "1936-04-25",
        "nepDate": "13.01.1993"
    },
    {
        "engDate": "1936-04-26",
        "nepDate": "14.01.1993"
    },
    {
        "engDate": "1936-04-27",
        "nepDate": "15.01.1993"
    },
    {
        "engDate": "1936-04-28",
        "nepDate": "16.01.1993"
    },
    {
        "engDate": "1936-04-29",
        "nepDate": "17.01.1993"
    },
    {
        "engDate": "1936-04-30",
        "nepDate": "18.01.1993"
    },
    {
        "engDate": "1936-05-01",
        "nepDate": "19.01.1993"
    },
    {
        "engDate": "1936-05-02",
        "nepDate": "20.01.1993"
    },
    {
        "engDate": "1936-05-03",
        "nepDate": "21.01.1993"
    },
    {
        "engDate": "1936-05-04",
        "nepDate": "22.01.1993"
    },
    {
        "engDate": "1936-05-05",
        "nepDate": "23.01.1993"
    },
    {
        "engDate": "1936-05-06",
        "nepDate": "24.01.1993"
    },
    {
        "engDate": "1936-05-07",
        "nepDate": "25.01.1993"
    },
    {
        "engDate": "1936-05-08",
        "nepDate": "26.01.1993"
    },
    {
        "engDate": "1936-05-09",
        "nepDate": "27.01.1993"
    },
    {
        "engDate": "1936-05-10",
        "nepDate": "28.01.1993"
    },
    {
        "engDate": "1936-05-11",
        "nepDate": "29.01.1993"
    },
    {
        "engDate": "1936-05-12",
        "nepDate": "30.01.1993"
    },
    {
        "engDate": "1936-05-13",
        "nepDate": "31.01.1993"
    },
    {
        "engDate": "1936-05-14",
        "nepDate": "01.02.1993"
    },
    {
        "engDate": "1936-05-15",
        "nepDate": "02.02.1993"
    },
    {
        "engDate": "1936-05-16",
        "nepDate": "03.02.1993"
    },
    {
        "engDate": "1936-05-17",
        "nepDate": "04.02.1993"
    },
    {
        "engDate": "1936-05-18",
        "nepDate": "05.02.1993"
    },
    {
        "engDate": "1936-05-19",
        "nepDate": "06.02.1993"
    },
    {
        "engDate": "1936-05-20",
        "nepDate": "07.02.1993"
    },
    {
        "engDate": "1936-05-21",
        "nepDate": "08.02.1993"
    },
    {
        "engDate": "1936-05-22",
        "nepDate": "09.02.1993"
    },
    {
        "engDate": "1936-05-23",
        "nepDate": "10.02.1993"
    },
    {
        "engDate": "1936-05-24",
        "nepDate": "11.02.1993"
    },
    {
        "engDate": "1936-05-25",
        "nepDate": "12.02.1993"
    },
    {
        "engDate": "1936-05-26",
        "nepDate": "13.02.1993"
    },
    {
        "engDate": "1936-05-27",
        "nepDate": "14.02.1993"
    },
    {
        "engDate": "1936-05-28",
        "nepDate": "15.02.1993"
    },
    {
        "engDate": "1936-05-29",
        "nepDate": "16.02.1993"
    },
    {
        "engDate": "1936-05-30",
        "nepDate": "17.02.1993"
    },
    {
        "engDate": "1936-05-31",
        "nepDate": "18.02.1993"
    },
    {
        "engDate": "1936-06-01",
        "nepDate": "19.02.1993"
    },
    {
        "engDate": "1936-06-02",
        "nepDate": "20.02.1993"
    },
    {
        "engDate": "1936-06-03",
        "nepDate": "21.02.1993"
    },
    {
        "engDate": "1936-06-04",
        "nepDate": "22.02.1993"
    },
    {
        "engDate": "1936-06-05",
        "nepDate": "23.02.1993"
    },
    {
        "engDate": "1936-06-06",
        "nepDate": "24.02.1993"
    },
    {
        "engDate": "1936-06-07",
        "nepDate": "25.02.1993"
    },
    {
        "engDate": "1936-06-08",
        "nepDate": "26.02.1993"
    },
    {
        "engDate": "1936-06-09",
        "nepDate": "27.02.1993"
    },
    {
        "engDate": "1936-06-10",
        "nepDate": "28.02.1993"
    },
    {
        "engDate": "1936-06-11",
        "nepDate": "29.02.1993"
    },
    {
        "engDate": "1936-06-12",
        "nepDate": "30.02.1993"
    },
    {
        "engDate": "1936-06-13",
        "nepDate": "31.02.1993"
    },
    {
        "engDate": "1936-06-14",
        "nepDate": "01.03.1993"
    },
    {
        "engDate": "1936-06-15",
        "nepDate": "02.03.1993"
    },
    {
        "engDate": "1936-06-16",
        "nepDate": "03.03.1993"
    },
    {
        "engDate": "1936-06-17",
        "nepDate": "04.03.1993"
    },
    {
        "engDate": "1936-06-18",
        "nepDate": "05.03.1993"
    },
    {
        "engDate": "1936-06-19",
        "nepDate": "06.03.1993"
    },
    {
        "engDate": "1936-06-20",
        "nepDate": "07.03.1993"
    },
    {
        "engDate": "1936-06-21",
        "nepDate": "08.03.1993"
    },
    {
        "engDate": "1936-06-22",
        "nepDate": "09.03.1993"
    },
    {
        "engDate": "1936-06-23",
        "nepDate": "10.03.1993"
    },
    {
        "engDate": "1936-06-24",
        "nepDate": "11.03.1993"
    },
    {
        "engDate": "1936-06-25",
        "nepDate": "12.03.1993"
    },
    {
        "engDate": "1936-06-26",
        "nepDate": "13.03.1993"
    },
    {
        "engDate": "1936-06-27",
        "nepDate": "14.03.1993"
    },
    {
        "engDate": "1936-06-28",
        "nepDate": "15.03.1993"
    },
    {
        "engDate": "1936-06-29",
        "nepDate": "16.03.1993"
    },
    {
        "engDate": "1936-06-30",
        "nepDate": "17.03.1993"
    },
    {
        "engDate": "1936-07-01",
        "nepDate": "18.03.1993"
    },
    {
        "engDate": "1936-07-02",
        "nepDate": "19.03.1993"
    },
    {
        "engDate": "1936-07-03",
        "nepDate": "20.03.1993"
    },
    {
        "engDate": "1936-07-04",
        "nepDate": "21.03.1993"
    },
    {
        "engDate": "1936-07-05",
        "nepDate": "22.03.1993"
    },
    {
        "engDate": "1936-07-06",
        "nepDate": "23.03.1993"
    },
    {
        "engDate": "1936-07-07",
        "nepDate": "24.03.1993"
    },
    {
        "engDate": "1936-07-08",
        "nepDate": "25.03.1993"
    },
    {
        "engDate": "1936-07-09",
        "nepDate": "26.03.1993"
    },
    {
        "engDate": "1936-07-10",
        "nepDate": "27.03.1993"
    },
    {
        "engDate": "1936-07-11",
        "nepDate": "28.03.1993"
    },
    {
        "engDate": "1936-07-12",
        "nepDate": "29.03.1993"
    },
    {
        "engDate": "1936-07-13",
        "nepDate": "30.03.1993"
    },
    {
        "engDate": "1936-07-14",
        "nepDate": "31.03.1993"
    },
    {
        "engDate": "1936-07-15",
        "nepDate": "32.03.1993"
    },
    {
        "engDate": "1936-07-16",
        "nepDate": "01.04.1993"
    },
    {
        "engDate": "1936-07-17",
        "nepDate": "02.04.1993"
    },
    {
        "engDate": "1936-07-18",
        "nepDate": "03.04.1993"
    },
    {
        "engDate": "1936-07-19",
        "nepDate": "04.04.1993"
    },
    {
        "engDate": "1936-07-20",
        "nepDate": "05.04.1993"
    },
    {
        "engDate": "1936-07-21",
        "nepDate": "06.04.1993"
    },
    {
        "engDate": "1936-07-22",
        "nepDate": "07.04.1993"
    },
    {
        "engDate": "1936-07-23",
        "nepDate": "08.04.1993"
    },
    {
        "engDate": "1936-07-24",
        "nepDate": "09.04.1993"
    },
    {
        "engDate": "1936-07-25",
        "nepDate": "10.04.1993"
    },
    {
        "engDate": "1936-07-26",
        "nepDate": "11.04.1993"
    },
    {
        "engDate": "1936-07-27",
        "nepDate": "12.04.1993"
    },
    {
        "engDate": "1936-07-28",
        "nepDate": "13.04.1993"
    },
    {
        "engDate": "1936-07-29",
        "nepDate": "14.04.1993"
    },
    {
        "engDate": "1936-07-30",
        "nepDate": "15.04.1993"
    },
    {
        "engDate": "1936-07-31",
        "nepDate": "16.04.1993"
    },
    {
        "engDate": "1936-08-01",
        "nepDate": "17.04.1993"
    },
    {
        "engDate": "1936-08-02",
        "nepDate": "18.04.1993"
    },
    {
        "engDate": "1936-08-03",
        "nepDate": "19.04.1993"
    },
    {
        "engDate": "1936-08-04",
        "nepDate": "20.04.1993"
    },
    {
        "engDate": "1936-08-05",
        "nepDate": "21.04.1993"
    },
    {
        "engDate": "1936-08-06",
        "nepDate": "22.04.1993"
    },
    {
        "engDate": "1936-08-07",
        "nepDate": "23.04.1993"
    },
    {
        "engDate": "1936-08-08",
        "nepDate": "24.04.1993"
    },
    {
        "engDate": "1936-08-09",
        "nepDate": "25.04.1993"
    },
    {
        "engDate": "1936-08-10",
        "nepDate": "26.04.1993"
    },
    {
        "engDate": "1936-08-11",
        "nepDate": "27.04.1993"
    },
    {
        "engDate": "1936-08-12",
        "nepDate": "28.04.1993"
    },
    {
        "engDate": "1936-08-13",
        "nepDate": "29.04.1993"
    },
    {
        "engDate": "1936-08-14",
        "nepDate": "30.04.1993"
    },
    {
        "engDate": "1936-08-15",
        "nepDate": "31.04.1993"
    },
    {
        "engDate": "1936-08-16",
        "nepDate": "01.05.1993"
    },
    {
        "engDate": "1936-08-17",
        "nepDate": "02.05.1993"
    },
    {
        "engDate": "1936-08-18",
        "nepDate": "03.05.1993"
    },
    {
        "engDate": "1936-08-19",
        "nepDate": "04.05.1993"
    },
    {
        "engDate": "1936-08-20",
        "nepDate": "05.05.1993"
    },
    {
        "engDate": "1936-08-21",
        "nepDate": "06.05.1993"
    },
    {
        "engDate": "1936-08-22",
        "nepDate": "07.05.1993"
    },
    {
        "engDate": "1936-08-23",
        "nepDate": "08.05.1993"
    },
    {
        "engDate": "1936-08-24",
        "nepDate": "09.05.1993"
    },
    {
        "engDate": "1936-08-25",
        "nepDate": "10.05.1993"
    },
    {
        "engDate": "1936-08-26",
        "nepDate": "11.05.1993"
    },
    {
        "engDate": "1936-08-27",
        "nepDate": "12.05.1993"
    },
    {
        "engDate": "1936-08-28",
        "nepDate": "13.05.1993"
    },
    {
        "engDate": "1936-08-29",
        "nepDate": "14.05.1993"
    },
    {
        "engDate": "1936-08-30",
        "nepDate": "15.05.1993"
    },
    {
        "engDate": "1936-08-31",
        "nepDate": "16.05.1993"
    },
    {
        "engDate": "1936-09-01",
        "nepDate": "17.05.1993"
    },
    {
        "engDate": "1936-09-02",
        "nepDate": "18.05.1993"
    },
    {
        "engDate": "1936-09-03",
        "nepDate": "19.05.1993"
    },
    {
        "engDate": "1936-09-04",
        "nepDate": "20.05.1993"
    },
    {
        "engDate": "1936-09-05",
        "nepDate": "21.05.1993"
    },
    {
        "engDate": "1936-09-06",
        "nepDate": "22.05.1993"
    },
    {
        "engDate": "1936-09-07",
        "nepDate": "23.05.1993"
    },
    {
        "engDate": "1936-09-08",
        "nepDate": "24.05.1993"
    },
    {
        "engDate": "1936-09-09",
        "nepDate": "25.05.1993"
    },
    {
        "engDate": "1936-09-10",
        "nepDate": "26.05.1993"
    },
    {
        "engDate": "1936-09-11",
        "nepDate": "27.05.1993"
    },
    {
        "engDate": "1936-09-12",
        "nepDate": "28.05.1993"
    },
    {
        "engDate": "1936-09-13",
        "nepDate": "29.05.1993"
    },
    {
        "engDate": "1936-09-14",
        "nepDate": "30.05.1993"
    },
    {
        "engDate": "1936-09-15",
        "nepDate": "31.05.1993"
    },
    {
        "engDate": "1936-09-16",
        "nepDate": "01.06.1993"
    },
    {
        "engDate": "1936-09-17",
        "nepDate": "02.06.1993"
    },
    {
        "engDate": "1936-09-18",
        "nepDate": "03.06.1993"
    },
    {
        "engDate": "1936-09-19",
        "nepDate": "04.06.1993"
    },
    {
        "engDate": "1936-09-20",
        "nepDate": "05.06.1993"
    },
    {
        "engDate": "1936-09-21",
        "nepDate": "06.06.1993"
    },
    {
        "engDate": "1936-09-22",
        "nepDate": "07.06.1993"
    },
    {
        "engDate": "1936-09-23",
        "nepDate": "08.06.1993"
    },
    {
        "engDate": "1936-09-24",
        "nepDate": "09.06.1993"
    },
    {
        "engDate": "1936-09-25",
        "nepDate": "10.06.1993"
    },
    {
        "engDate": "1936-09-26",
        "nepDate": "11.06.1993"
    },
    {
        "engDate": "1936-09-27",
        "nepDate": "12.06.1993"
    },
    {
        "engDate": "1936-09-28",
        "nepDate": "13.06.1993"
    },
    {
        "engDate": "1936-09-29",
        "nepDate": "14.06.1993"
    },
    {
        "engDate": "1936-09-30",
        "nepDate": "15.06.1993"
    },
    {
        "engDate": "1936-10-01",
        "nepDate": "16.06.1993"
    },
    {
        "engDate": "1936-10-02",
        "nepDate": "17.06.1993"
    },
    {
        "engDate": "1936-10-03",
        "nepDate": "18.06.1993"
    },
    {
        "engDate": "1936-10-04",
        "nepDate": "19.06.1993"
    },
    {
        "engDate": "1936-10-05",
        "nepDate": "20.06.1993"
    },
    {
        "engDate": "1936-10-06",
        "nepDate": "21.06.1993"
    },
    {
        "engDate": "1936-10-07",
        "nepDate": "22.06.1993"
    },
    {
        "engDate": "1936-10-08",
        "nepDate": "23.06.1993"
    },
    {
        "engDate": "1936-10-09",
        "nepDate": "24.06.1993"
    },
    {
        "engDate": "1936-10-10",
        "nepDate": "25.06.1993"
    },
    {
        "engDate": "1936-10-11",
        "nepDate": "26.06.1993"
    },
    {
        "engDate": "1936-10-12",
        "nepDate": "27.06.1993"
    },
    {
        "engDate": "1936-10-13",
        "nepDate": "28.06.1993"
    },
    {
        "engDate": "1936-10-14",
        "nepDate": "29.06.1993"
    },
    {
        "engDate": "1936-10-15",
        "nepDate": "30.06.1993"
    },
    {
        "engDate": "1936-10-16",
        "nepDate": "31.06.1993"
    },
    {
        "engDate": "1936-10-17",
        "nepDate": "01.07.1993"
    },
    {
        "engDate": "1936-10-18",
        "nepDate": "02.07.1993"
    },
    {
        "engDate": "1936-10-19",
        "nepDate": "03.07.1993"
    },
    {
        "engDate": "1936-10-20",
        "nepDate": "04.07.1993"
    },
    {
        "engDate": "1936-10-21",
        "nepDate": "05.07.1993"
    },
    {
        "engDate": "1936-10-22",
        "nepDate": "06.07.1993"
    },
    {
        "engDate": "1936-10-23",
        "nepDate": "07.07.1993"
    },
    {
        "engDate": "1936-10-24",
        "nepDate": "08.07.1993"
    },
    {
        "engDate": "1936-10-25",
        "nepDate": "09.07.1993"
    },
    {
        "engDate": "1936-10-26",
        "nepDate": "10.07.1993"
    },
    {
        "engDate": "1936-10-27",
        "nepDate": "11.07.1993"
    },
    {
        "engDate": "1936-10-28",
        "nepDate": "12.07.1993"
    },
    {
        "engDate": "1936-10-29",
        "nepDate": "13.07.1993"
    },
    {
        "engDate": "1936-10-30",
        "nepDate": "14.07.1993"
    },
    {
        "engDate": "1936-10-31",
        "nepDate": "15.07.1993"
    },
    {
        "engDate": "1936-11-01",
        "nepDate": "16.07.1993"
    },
    {
        "engDate": "1936-11-02",
        "nepDate": "17.07.1993"
    },
    {
        "engDate": "1936-11-03",
        "nepDate": "18.07.1993"
    },
    {
        "engDate": "1936-11-04",
        "nepDate": "19.07.1993"
    },
    {
        "engDate": "1936-11-05",
        "nepDate": "20.07.1993"
    },
    {
        "engDate": "1936-11-06",
        "nepDate": "21.07.1993"
    },
    {
        "engDate": "1936-11-07",
        "nepDate": "22.07.1993"
    },
    {
        "engDate": "1936-11-08",
        "nepDate": "23.07.1993"
    },
    {
        "engDate": "1936-11-09",
        "nepDate": "24.07.1993"
    },
    {
        "engDate": "1936-11-10",
        "nepDate": "25.07.1993"
    },
    {
        "engDate": "1936-11-11",
        "nepDate": "26.07.1993"
    },
    {
        "engDate": "1936-11-12",
        "nepDate": "27.07.1993"
    },
    {
        "engDate": "1936-11-13",
        "nepDate": "28.07.1993"
    },
    {
        "engDate": "1936-11-14",
        "nepDate": "29.07.1993"
    },
    {
        "engDate": "1936-11-15",
        "nepDate": "30.07.1993"
    },
    {
        "engDate": "1936-11-16",
        "nepDate": "01.08.1993"
    },
    {
        "engDate": "1936-11-17",
        "nepDate": "02.08.1993"
    },
    {
        "engDate": "1936-11-18",
        "nepDate": "03.08.1993"
    },
    {
        "engDate": "1936-11-19",
        "nepDate": "04.08.1993"
    },
    {
        "engDate": "1936-11-20",
        "nepDate": "05.08.1993"
    },
    {
        "engDate": "1936-11-21",
        "nepDate": "06.08.1993"
    },
    {
        "engDate": "1936-11-22",
        "nepDate": "07.08.1993"
    },
    {
        "engDate": "1936-11-23",
        "nepDate": "08.08.1993"
    },
    {
        "engDate": "1936-11-24",
        "nepDate": "09.08.1993"
    },
    {
        "engDate": "1936-11-25",
        "nepDate": "10.08.1993"
    },
    {
        "engDate": "1936-11-26",
        "nepDate": "11.08.1993"
    },
    {
        "engDate": "1936-11-27",
        "nepDate": "12.08.1993"
    },
    {
        "engDate": "1936-11-28",
        "nepDate": "13.08.1993"
    },
    {
        "engDate": "1936-11-29",
        "nepDate": "14.08.1993"
    },
    {
        "engDate": "1936-11-30",
        "nepDate": "15.08.1993"
    },
    {
        "engDate": "1936-12-01",
        "nepDate": "16.08.1993"
    },
    {
        "engDate": "1936-12-02",
        "nepDate": "17.08.1993"
    },
    {
        "engDate": "1936-12-03",
        "nepDate": "18.08.1993"
    },
    {
        "engDate": "1936-12-04",
        "nepDate": "19.08.1993"
    },
    {
        "engDate": "1936-12-05",
        "nepDate": "20.08.1993"
    },
    {
        "engDate": "1936-12-06",
        "nepDate": "21.08.1993"
    },
    {
        "engDate": "1936-12-07",
        "nepDate": "22.08.1993"
    },
    {
        "engDate": "1936-12-08",
        "nepDate": "23.08.1993"
    },
    {
        "engDate": "1936-12-09",
        "nepDate": "24.08.1993"
    },
    {
        "engDate": "1936-12-10",
        "nepDate": "25.08.1993"
    },
    {
        "engDate": "1936-12-11",
        "nepDate": "26.08.1993"
    },
    {
        "engDate": "1936-12-12",
        "nepDate": "27.08.1993"
    },
    {
        "engDate": "1936-12-13",
        "nepDate": "28.08.1993"
    },
    {
        "engDate": "1936-12-14",
        "nepDate": "29.08.1993"
    },
    {
        "engDate": "1936-12-15",
        "nepDate": "01.09.1993"
    },
    {
        "engDate": "1936-12-16",
        "nepDate": "02.09.1993"
    },
    {
        "engDate": "1936-12-17",
        "nepDate": "03.09.1993"
    },
    {
        "engDate": "1936-12-18",
        "nepDate": "04.09.1993"
    },
    {
        "engDate": "1936-12-19",
        "nepDate": "05.09.1993"
    },
    {
        "engDate": "1936-12-20",
        "nepDate": "06.09.1993"
    },
    {
        "engDate": "1936-12-21",
        "nepDate": "07.09.1993"
    },
    {
        "engDate": "1936-12-22",
        "nepDate": "08.09.1993"
    },
    {
        "engDate": "1936-12-23",
        "nepDate": "09.09.1993"
    },
    {
        "engDate": "1936-12-24",
        "nepDate": "10.09.1993"
    },
    {
        "engDate": "1936-12-25",
        "nepDate": "11.09.1993"
    },
    {
        "engDate": "1936-12-26",
        "nepDate": "12.09.1993"
    },
    {
        "engDate": "1936-12-27",
        "nepDate": "13.09.1993"
    },
    {
        "engDate": "1936-12-28",
        "nepDate": "14.09.1993"
    },
    {
        "engDate": "1936-12-29",
        "nepDate": "15.09.1993"
    },
    {
        "engDate": "1936-12-30",
        "nepDate": "16.09.1993"
    },
    {
        "engDate": "1936-12-31",
        "nepDate": "17.09.1993"
    },
    {
        "engDate": "1937-01-01",
        "nepDate": "18.09.1993"
    },
    {
        "engDate": "1937-01-02",
        "nepDate": "19.09.1993"
    },
    {
        "engDate": "1937-01-03",
        "nepDate": "20.09.1993"
    },
    {
        "engDate": "1937-01-04",
        "nepDate": "21.09.1993"
    },
    {
        "engDate": "1937-01-05",
        "nepDate": "22.09.1993"
    },
    {
        "engDate": "1937-01-06",
        "nepDate": "23.09.1993"
    },
    {
        "engDate": "1937-01-07",
        "nepDate": "24.09.1993"
    },
    {
        "engDate": "1937-01-08",
        "nepDate": "25.09.1993"
    },
    {
        "engDate": "1937-01-09",
        "nepDate": "26.09.1993"
    },
    {
        "engDate": "1937-01-10",
        "nepDate": "27.09.1993"
    },
    {
        "engDate": "1937-01-11",
        "nepDate": "28.09.1993"
    },
    {
        "engDate": "1937-01-12",
        "nepDate": "29.09.1993"
    },
    {
        "engDate": "1937-01-13",
        "nepDate": "30.09.1993"
    },
    {
        "engDate": "1937-01-14",
        "nepDate": "01.10.1993"
    },
    {
        "engDate": "1937-01-15",
        "nepDate": "02.10.1993"
    },
    {
        "engDate": "1937-01-16",
        "nepDate": "03.10.1993"
    },
    {
        "engDate": "1937-01-17",
        "nepDate": "04.10.1993"
    },
    {
        "engDate": "1937-01-18",
        "nepDate": "05.10.1993"
    },
    {
        "engDate": "1937-01-19",
        "nepDate": "06.10.1993"
    },
    {
        "engDate": "1937-01-20",
        "nepDate": "07.10.1993"
    },
    {
        "engDate": "1937-01-21",
        "nepDate": "08.10.1993"
    },
    {
        "engDate": "1937-01-22",
        "nepDate": "09.10.1993"
    },
    {
        "engDate": "1937-01-23",
        "nepDate": "10.10.1993"
    },
    {
        "engDate": "1937-01-24",
        "nepDate": "11.10.1993"
    },
    {
        "engDate": "1937-01-25",
        "nepDate": "12.10.1993"
    },
    {
        "engDate": "1937-01-26",
        "nepDate": "13.10.1993"
    },
    {
        "engDate": "1937-01-27",
        "nepDate": "14.10.1993"
    },
    {
        "engDate": "1937-01-28",
        "nepDate": "15.10.1993"
    },
    {
        "engDate": "1937-01-29",
        "nepDate": "16.10.1993"
    },
    {
        "engDate": "1937-01-30",
        "nepDate": "17.10.1993"
    },
    {
        "engDate": "1937-01-31",
        "nepDate": "18.10.1993"
    },
    {
        "engDate": "1937-02-01",
        "nepDate": "19.10.1993"
    },
    {
        "engDate": "1937-02-02",
        "nepDate": "20.10.1993"
    },
    {
        "engDate": "1937-02-03",
        "nepDate": "21.10.1993"
    },
    {
        "engDate": "1937-02-04",
        "nepDate": "22.10.1993"
    },
    {
        "engDate": "1937-02-05",
        "nepDate": "23.10.1993"
    },
    {
        "engDate": "1937-02-06",
        "nepDate": "24.10.1993"
    },
    {
        "engDate": "1937-02-07",
        "nepDate": "25.10.1993"
    },
    {
        "engDate": "1937-02-08",
        "nepDate": "26.10.1993"
    },
    {
        "engDate": "1937-02-09",
        "nepDate": "27.10.1993"
    },
    {
        "engDate": "1937-02-10",
        "nepDate": "28.10.1993"
    },
    {
        "engDate": "1937-02-11",
        "nepDate": "29.10.1993"
    },
    {
        "engDate": "1937-02-12",
        "nepDate": "01.11.1993"
    },
    {
        "engDate": "1937-02-13",
        "nepDate": "02.11.1993"
    },
    {
        "engDate": "1937-02-14",
        "nepDate": "03.11.1993"
    },
    {
        "engDate": "1937-02-15",
        "nepDate": "04.11.1993"
    },
    {
        "engDate": "1937-02-16",
        "nepDate": "05.11.1993"
    },
    {
        "engDate": "1937-02-17",
        "nepDate": "06.11.1993"
    },
    {
        "engDate": "1937-02-18",
        "nepDate": "07.11.1993"
    },
    {
        "engDate": "1937-02-19",
        "nepDate": "08.11.1993"
    },
    {
        "engDate": "1937-02-20",
        "nepDate": "09.11.1993"
    },
    {
        "engDate": "1937-02-21",
        "nepDate": "10.11.1993"
    },
    {
        "engDate": "1937-02-22",
        "nepDate": "11.11.1993"
    },
    {
        "engDate": "1937-02-23",
        "nepDate": "12.11.1993"
    },
    {
        "engDate": "1937-02-24",
        "nepDate": "13.11.1993"
    },
    {
        "engDate": "1937-02-25",
        "nepDate": "14.11.1993"
    },
    {
        "engDate": "1937-02-26",
        "nepDate": "15.11.1993"
    },
    {
        "engDate": "1937-02-27",
        "nepDate": "16.11.1993"
    },
    {
        "engDate": "1937-02-28",
        "nepDate": "17.11.1993"
    },
    {
        "engDate": "1937-03-01",
        "nepDate": "18.11.1993"
    },
    {
        "engDate": "1937-03-02",
        "nepDate": "19.11.1993"
    },
    {
        "engDate": "1937-03-03",
        "nepDate": "20.11.1993"
    },
    {
        "engDate": "1937-03-04",
        "nepDate": "21.11.1993"
    },
    {
        "engDate": "1937-03-05",
        "nepDate": "22.11.1993"
    },
    {
        "engDate": "1937-03-06",
        "nepDate": "23.11.1993"
    },
    {
        "engDate": "1937-03-07",
        "nepDate": "24.11.1993"
    },
    {
        "engDate": "1937-03-08",
        "nepDate": "25.11.1993"
    },
    {
        "engDate": "1937-03-09",
        "nepDate": "26.11.1993"
    },
    {
        "engDate": "1937-03-10",
        "nepDate": "27.11.1993"
    },
    {
        "engDate": "1937-03-11",
        "nepDate": "28.11.1993"
    },
    {
        "engDate": "1937-03-12",
        "nepDate": "29.11.1993"
    },
    {
        "engDate": "1937-03-13",
        "nepDate": "30.11.1993"
    },
    {
        "engDate": "1937-03-14",
        "nepDate": "01.12.1993"
    },
    {
        "engDate": "1937-03-15",
        "nepDate": "02.12.1993"
    },
    {
        "engDate": "1937-03-16",
        "nepDate": "03.12.1993"
    },
    {
        "engDate": "1937-03-17",
        "nepDate": "04.12.1993"
    },
    {
        "engDate": "1937-03-18",
        "nepDate": "05.12.1993"
    },
    {
        "engDate": "1937-03-19",
        "nepDate": "06.12.1993"
    },
    {
        "engDate": "1937-03-20",
        "nepDate": "07.12.1993"
    },
    {
        "engDate": "1937-03-21",
        "nepDate": "08.12.1993"
    },
    {
        "engDate": "1937-03-22",
        "nepDate": "09.12.1993"
    },
    {
        "engDate": "1937-03-23",
        "nepDate": "10.12.1993"
    },
    {
        "engDate": "1937-03-24",
        "nepDate": "11.12.1993"
    },
    {
        "engDate": "1937-03-25",
        "nepDate": "12.12.1993"
    },
    {
        "engDate": "1937-03-26",
        "nepDate": "13.12.1993"
    },
    {
        "engDate": "1937-03-27",
        "nepDate": "14.12.1993"
    },
    {
        "engDate": "1937-03-28",
        "nepDate": "15.12.1993"
    },
    {
        "engDate": "1937-03-29",
        "nepDate": "16.12.1993"
    },
    {
        "engDate": "1937-03-30",
        "nepDate": "17.12.1993"
    },
    {
        "engDate": "1937-03-31",
        "nepDate": "18.12.1993"
    },
    {
        "engDate": "1937-04-01",
        "nepDate": "19.12.1993"
    },
    {
        "engDate": "1937-04-02",
        "nepDate": "20.12.1993"
    },
    {
        "engDate": "1937-04-03",
        "nepDate": "21.12.1993"
    },
    {
        "engDate": "1937-04-04",
        "nepDate": "22.12.1993"
    },
    {
        "engDate": "1937-04-05",
        "nepDate": "23.12.1993"
    },
    {
        "engDate": "1937-04-06",
        "nepDate": "24.12.1993"
    },
    {
        "engDate": "1937-04-07",
        "nepDate": "25.12.1993"
    },
    {
        "engDate": "1937-04-08",
        "nepDate": "26.12.1993"
    },
    {
        "engDate": "1937-04-09",
        "nepDate": "27.12.1993"
    },
    {
        "engDate": "1937-04-10",
        "nepDate": "28.12.1993"
    },
    {
        "engDate": "1937-04-11",
        "nepDate": "29.12.1993"
    },
    {
        "engDate": "1937-04-12",
        "nepDate": "30.12.1993"
    },
    {
        "engDate": "1937-04-13",
        "nepDate": "01.01.1994"
    },
    {
        "engDate": "1937-04-14",
        "nepDate": "02.01.1994"
    },
    {
        "engDate": "1937-04-15",
        "nepDate": "03.01.1994"
    },
    {
        "engDate": "1937-04-16",
        "nepDate": "04.01.1994"
    },
    {
        "engDate": "1937-04-17",
        "nepDate": "05.01.1994"
    },
    {
        "engDate": "1937-04-18",
        "nepDate": "06.01.1994"
    },
    {
        "engDate": "1937-04-19",
        "nepDate": "07.01.1994"
    },
    {
        "engDate": "1937-04-20",
        "nepDate": "08.01.1994"
    },
    {
        "engDate": "1937-04-21",
        "nepDate": "09.01.1994"
    },
    {
        "engDate": "1937-04-22",
        "nepDate": "10.01.1994"
    },
    {
        "engDate": "1937-04-23",
        "nepDate": "11.01.1994"
    },
    {
        "engDate": "1937-04-24",
        "nepDate": "12.01.1994"
    },
    {
        "engDate": "1937-04-25",
        "nepDate": "13.01.1994"
    },
    {
        "engDate": "1937-04-26",
        "nepDate": "14.01.1994"
    },
    {
        "engDate": "1937-04-27",
        "nepDate": "15.01.1994"
    },
    {
        "engDate": "1937-04-28",
        "nepDate": "16.01.1994"
    },
    {
        "engDate": "1937-04-29",
        "nepDate": "17.01.1994"
    },
    {
        "engDate": "1937-04-30",
        "nepDate": "18.01.1994"
    },
    {
        "engDate": "1937-05-01",
        "nepDate": "19.01.1994"
    },
    {
        "engDate": "1937-05-02",
        "nepDate": "20.01.1994"
    },
    {
        "engDate": "1937-05-03",
        "nepDate": "21.01.1994"
    },
    {
        "engDate": "1937-05-04",
        "nepDate": "22.01.1994"
    },
    {
        "engDate": "1937-05-05",
        "nepDate": "23.01.1994"
    },
    {
        "engDate": "1937-05-06",
        "nepDate": "24.01.1994"
    },
    {
        "engDate": "1937-05-07",
        "nepDate": "25.01.1994"
    },
    {
        "engDate": "1937-05-08",
        "nepDate": "26.01.1994"
    },
    {
        "engDate": "1937-05-09",
        "nepDate": "27.01.1994"
    },
    {
        "engDate": "1937-05-10",
        "nepDate": "28.01.1994"
    },
    {
        "engDate": "1937-05-11",
        "nepDate": "29.01.1994"
    },
    {
        "engDate": "1937-05-12",
        "nepDate": "30.01.1994"
    },
    {
        "engDate": "1937-05-13",
        "nepDate": "31.01.1994"
    },
    {
        "engDate": "1937-05-14",
        "nepDate": "01.02.1994"
    },
    {
        "engDate": "1937-05-15",
        "nepDate": "02.02.1994"
    },
    {
        "engDate": "1937-05-16",
        "nepDate": "03.02.1994"
    },
    {
        "engDate": "1937-05-17",
        "nepDate": "04.02.1994"
    },
    {
        "engDate": "1937-05-18",
        "nepDate": "05.02.1994"
    },
    {
        "engDate": "1937-05-19",
        "nepDate": "06.02.1994"
    },
    {
        "engDate": "1937-05-20",
        "nepDate": "07.02.1994"
    },
    {
        "engDate": "1937-05-21",
        "nepDate": "08.02.1994"
    },
    {
        "engDate": "1937-05-22",
        "nepDate": "09.02.1994"
    },
    {
        "engDate": "1937-05-23",
        "nepDate": "10.02.1994"
    },
    {
        "engDate": "1937-05-24",
        "nepDate": "11.02.1994"
    },
    {
        "engDate": "1937-05-25",
        "nepDate": "12.02.1994"
    },
    {
        "engDate": "1937-05-26",
        "nepDate": "13.02.1994"
    },
    {
        "engDate": "1937-05-27",
        "nepDate": "14.02.1994"
    },
    {
        "engDate": "1937-05-28",
        "nepDate": "15.02.1994"
    },
    {
        "engDate": "1937-05-29",
        "nepDate": "16.02.1994"
    },
    {
        "engDate": "1937-05-30",
        "nepDate": "17.02.1994"
    },
    {
        "engDate": "1937-05-31",
        "nepDate": "18.02.1994"
    },
    {
        "engDate": "1937-06-01",
        "nepDate": "19.02.1994"
    },
    {
        "engDate": "1937-06-02",
        "nepDate": "20.02.1994"
    },
    {
        "engDate": "1937-06-03",
        "nepDate": "21.02.1994"
    },
    {
        "engDate": "1937-06-04",
        "nepDate": "22.02.1994"
    },
    {
        "engDate": "1937-06-05",
        "nepDate": "23.02.1994"
    },
    {
        "engDate": "1937-06-06",
        "nepDate": "24.02.1994"
    },
    {
        "engDate": "1937-06-07",
        "nepDate": "25.02.1994"
    },
    {
        "engDate": "1937-06-08",
        "nepDate": "26.02.1994"
    },
    {
        "engDate": "1937-06-09",
        "nepDate": "27.02.1994"
    },
    {
        "engDate": "1937-06-10",
        "nepDate": "28.02.1994"
    },
    {
        "engDate": "1937-06-11",
        "nepDate": "29.02.1994"
    },
    {
        "engDate": "1937-06-12",
        "nepDate": "30.02.1994"
    },
    {
        "engDate": "1937-06-13",
        "nepDate": "31.02.1994"
    },
    {
        "engDate": "1937-06-14",
        "nepDate": "01.03.1994"
    },
    {
        "engDate": "1937-06-15",
        "nepDate": "02.03.1994"
    },
    {
        "engDate": "1937-06-16",
        "nepDate": "03.03.1994"
    },
    {
        "engDate": "1937-06-17",
        "nepDate": "04.03.1994"
    },
    {
        "engDate": "1937-06-18",
        "nepDate": "05.03.1994"
    },
    {
        "engDate": "1937-06-19",
        "nepDate": "06.03.1994"
    },
    {
        "engDate": "1937-06-20",
        "nepDate": "07.03.1994"
    },
    {
        "engDate": "1937-06-21",
        "nepDate": "08.03.1994"
    },
    {
        "engDate": "1937-06-22",
        "nepDate": "09.03.1994"
    },
    {
        "engDate": "1937-06-23",
        "nepDate": "10.03.1994"
    },
    {
        "engDate": "1937-06-24",
        "nepDate": "11.03.1994"
    },
    {
        "engDate": "1937-06-25",
        "nepDate": "12.03.1994"
    },
    {
        "engDate": "1937-06-26",
        "nepDate": "13.03.1994"
    },
    {
        "engDate": "1937-06-27",
        "nepDate": "14.03.1994"
    },
    {
        "engDate": "1937-06-28",
        "nepDate": "15.03.1994"
    },
    {
        "engDate": "1937-06-29",
        "nepDate": "16.03.1994"
    },
    {
        "engDate": "1937-06-30",
        "nepDate": "17.03.1994"
    },
    {
        "engDate": "1937-07-01",
        "nepDate": "18.03.1994"
    },
    {
        "engDate": "1937-07-02",
        "nepDate": "19.03.1994"
    },
    {
        "engDate": "1937-07-03",
        "nepDate": "20.03.1994"
    },
    {
        "engDate": "1937-07-04",
        "nepDate": "21.03.1994"
    },
    {
        "engDate": "1937-07-05",
        "nepDate": "22.03.1994"
    },
    {
        "engDate": "1937-07-06",
        "nepDate": "23.03.1994"
    },
    {
        "engDate": "1937-07-07",
        "nepDate": "24.03.1994"
    },
    {
        "engDate": "1937-07-08",
        "nepDate": "25.03.1994"
    },
    {
        "engDate": "1937-07-09",
        "nepDate": "26.03.1994"
    },
    {
        "engDate": "1937-07-10",
        "nepDate": "27.03.1994"
    },
    {
        "engDate": "1937-07-11",
        "nepDate": "28.03.1994"
    },
    {
        "engDate": "1937-07-12",
        "nepDate": "29.03.1994"
    },
    {
        "engDate": "1937-07-13",
        "nepDate": "30.03.1994"
    },
    {
        "engDate": "1937-07-14",
        "nepDate": "31.03.1994"
    },
    {
        "engDate": "1937-07-15",
        "nepDate": "32.03.1994"
    },
    {
        "engDate": "1937-07-16",
        "nepDate": "01.04.1994"
    },
    {
        "engDate": "1937-07-17",
        "nepDate": "02.04.1994"
    },
    {
        "engDate": "1937-07-18",
        "nepDate": "03.04.1994"
    },
    {
        "engDate": "1937-07-19",
        "nepDate": "04.04.1994"
    },
    {
        "engDate": "1937-07-20",
        "nepDate": "05.04.1994"
    },
    {
        "engDate": "1937-07-21",
        "nepDate": "06.04.1994"
    },
    {
        "engDate": "1937-07-22",
        "nepDate": "07.04.1994"
    },
    {
        "engDate": "1937-07-23",
        "nepDate": "08.04.1994"
    },
    {
        "engDate": "1937-07-24",
        "nepDate": "09.04.1994"
    },
    {
        "engDate": "1937-07-25",
        "nepDate": "10.04.1994"
    },
    {
        "engDate": "1937-07-26",
        "nepDate": "11.04.1994"
    },
    {
        "engDate": "1937-07-27",
        "nepDate": "12.04.1994"
    },
    {
        "engDate": "1937-07-28",
        "nepDate": "13.04.1994"
    },
    {
        "engDate": "1937-07-29",
        "nepDate": "14.04.1994"
    },
    {
        "engDate": "1937-07-30",
        "nepDate": "15.04.1994"
    },
    {
        "engDate": "1937-07-31",
        "nepDate": "16.04.1994"
    },
    {
        "engDate": "1937-08-01",
        "nepDate": "17.04.1994"
    },
    {
        "engDate": "1937-08-02",
        "nepDate": "18.04.1994"
    },
    {
        "engDate": "1937-08-03",
        "nepDate": "19.04.1994"
    },
    {
        "engDate": "1937-08-04",
        "nepDate": "20.04.1994"
    },
    {
        "engDate": "1937-08-05",
        "nepDate": "21.04.1994"
    },
    {
        "engDate": "1937-08-06",
        "nepDate": "22.04.1994"
    },
    {
        "engDate": "1937-08-07",
        "nepDate": "23.04.1994"
    },
    {
        "engDate": "1937-08-08",
        "nepDate": "24.04.1994"
    },
    {
        "engDate": "1937-08-09",
        "nepDate": "25.04.1994"
    },
    {
        "engDate": "1937-08-10",
        "nepDate": "26.04.1994"
    },
    {
        "engDate": "1937-08-11",
        "nepDate": "27.04.1994"
    },
    {
        "engDate": "1937-08-12",
        "nepDate": "28.04.1994"
    },
    {
        "engDate": "1937-08-13",
        "nepDate": "29.04.1994"
    },
    {
        "engDate": "1937-08-14",
        "nepDate": "30.04.1994"
    },
    {
        "engDate": "1937-08-15",
        "nepDate": "31.04.1994"
    },
    {
        "engDate": "1937-08-16",
        "nepDate": "01.05.1994"
    },
    {
        "engDate": "1937-08-17",
        "nepDate": "02.05.1994"
    },
    {
        "engDate": "1937-08-18",
        "nepDate": "03.05.1994"
    },
    {
        "engDate": "1937-08-19",
        "nepDate": "04.05.1994"
    },
    {
        "engDate": "1937-08-20",
        "nepDate": "05.05.1994"
    },
    {
        "engDate": "1937-08-21",
        "nepDate": "06.05.1994"
    },
    {
        "engDate": "1937-08-22",
        "nepDate": "07.05.1994"
    },
    {
        "engDate": "1937-08-23",
        "nepDate": "08.05.1994"
    },
    {
        "engDate": "1937-08-24",
        "nepDate": "09.05.1994"
    },
    {
        "engDate": "1937-08-25",
        "nepDate": "10.05.1994"
    },
    {
        "engDate": "1937-08-26",
        "nepDate": "11.05.1994"
    },
    {
        "engDate": "1937-08-27",
        "nepDate": "12.05.1994"
    },
    {
        "engDate": "1937-08-28",
        "nepDate": "13.05.1994"
    },
    {
        "engDate": "1937-08-29",
        "nepDate": "14.05.1994"
    },
    {
        "engDate": "1937-08-30",
        "nepDate": "15.05.1994"
    },
    {
        "engDate": "1937-08-31",
        "nepDate": "16.05.1994"
    },
    {
        "engDate": "1937-09-01",
        "nepDate": "17.05.1994"
    },
    {
        "engDate": "1937-09-02",
        "nepDate": "18.05.1994"
    },
    {
        "engDate": "1937-09-03",
        "nepDate": "19.05.1994"
    },
    {
        "engDate": "1937-09-04",
        "nepDate": "20.05.1994"
    },
    {
        "engDate": "1937-09-05",
        "nepDate": "21.05.1994"
    },
    {
        "engDate": "1937-09-06",
        "nepDate": "22.05.1994"
    },
    {
        "engDate": "1937-09-07",
        "nepDate": "23.05.1994"
    },
    {
        "engDate": "1937-09-08",
        "nepDate": "24.05.1994"
    },
    {
        "engDate": "1937-09-09",
        "nepDate": "25.05.1994"
    },
    {
        "engDate": "1937-09-10",
        "nepDate": "26.05.1994"
    },
    {
        "engDate": "1937-09-11",
        "nepDate": "27.05.1994"
    },
    {
        "engDate": "1937-09-12",
        "nepDate": "28.05.1994"
    },
    {
        "engDate": "1937-09-13",
        "nepDate": "29.05.1994"
    },
    {
        "engDate": "1937-09-14",
        "nepDate": "30.05.1994"
    },
    {
        "engDate": "1937-09-15",
        "nepDate": "31.05.1994"
    },
    {
        "engDate": "1937-09-16",
        "nepDate": "01.06.1994"
    },
    {
        "engDate": "1937-09-17",
        "nepDate": "02.06.1994"
    },
    {
        "engDate": "1937-09-18",
        "nepDate": "03.06.1994"
    },
    {
        "engDate": "1937-09-19",
        "nepDate": "04.06.1994"
    },
    {
        "engDate": "1937-09-20",
        "nepDate": "05.06.1994"
    },
    {
        "engDate": "1937-09-21",
        "nepDate": "06.06.1994"
    },
    {
        "engDate": "1937-09-22",
        "nepDate": "07.06.1994"
    },
    {
        "engDate": "1937-09-23",
        "nepDate": "08.06.1994"
    },
    {
        "engDate": "1937-09-24",
        "nepDate": "09.06.1994"
    },
    {
        "engDate": "1937-09-25",
        "nepDate": "10.06.1994"
    },
    {
        "engDate": "1937-09-26",
        "nepDate": "11.06.1994"
    },
    {
        "engDate": "1937-09-27",
        "nepDate": "12.06.1994"
    },
    {
        "engDate": "1937-09-28",
        "nepDate": "13.06.1994"
    },
    {
        "engDate": "1937-09-29",
        "nepDate": "14.06.1994"
    },
    {
        "engDate": "1937-09-30",
        "nepDate": "15.06.1994"
    },
    {
        "engDate": "1937-10-01",
        "nepDate": "16.06.1994"
    },
    {
        "engDate": "1937-10-02",
        "nepDate": "17.06.1994"
    },
    {
        "engDate": "1937-10-03",
        "nepDate": "18.06.1994"
    },
    {
        "engDate": "1937-10-04",
        "nepDate": "19.06.1994"
    },
    {
        "engDate": "1937-10-05",
        "nepDate": "20.06.1994"
    },
    {
        "engDate": "1937-10-06",
        "nepDate": "21.06.1994"
    },
    {
        "engDate": "1937-10-07",
        "nepDate": "22.06.1994"
    },
    {
        "engDate": "1937-10-08",
        "nepDate": "23.06.1994"
    },
    {
        "engDate": "1937-10-09",
        "nepDate": "24.06.1994"
    },
    {
        "engDate": "1937-10-10",
        "nepDate": "25.06.1994"
    },
    {
        "engDate": "1937-10-11",
        "nepDate": "26.06.1994"
    },
    {
        "engDate": "1937-10-12",
        "nepDate": "27.06.1994"
    },
    {
        "engDate": "1937-10-13",
        "nepDate": "28.06.1994"
    },
    {
        "engDate": "1937-10-14",
        "nepDate": "29.06.1994"
    },
    {
        "engDate": "1937-10-15",
        "nepDate": "30.06.1994"
    },
    {
        "engDate": "1937-10-16",
        "nepDate": "31.06.1994"
    },
    {
        "engDate": "1937-10-17",
        "nepDate": "01.07.1994"
    },
    {
        "engDate": "1937-10-18",
        "nepDate": "02.07.1994"
    },
    {
        "engDate": "1937-10-19",
        "nepDate": "03.07.1994"
    },
    {
        "engDate": "1937-10-20",
        "nepDate": "04.07.1994"
    },
    {
        "engDate": "1937-10-21",
        "nepDate": "05.07.1994"
    },
    {
        "engDate": "1937-10-22",
        "nepDate": "06.07.1994"
    },
    {
        "engDate": "1937-10-23",
        "nepDate": "07.07.1994"
    },
    {
        "engDate": "1937-10-24",
        "nepDate": "08.07.1994"
    },
    {
        "engDate": "1937-10-25",
        "nepDate": "09.07.1994"
    },
    {
        "engDate": "1937-10-26",
        "nepDate": "10.07.1994"
    },
    {
        "engDate": "1937-10-27",
        "nepDate": "11.07.1994"
    },
    {
        "engDate": "1937-10-28",
        "nepDate": "12.07.1994"
    },
    {
        "engDate": "1937-10-29",
        "nepDate": "13.07.1994"
    },
    {
        "engDate": "1937-10-30",
        "nepDate": "14.07.1994"
    },
    {
        "engDate": "1937-10-31",
        "nepDate": "15.07.1994"
    },
    {
        "engDate": "1937-11-01",
        "nepDate": "16.07.1994"
    },
    {
        "engDate": "1937-11-02",
        "nepDate": "17.07.1994"
    },
    {
        "engDate": "1937-11-03",
        "nepDate": "18.07.1994"
    },
    {
        "engDate": "1937-11-04",
        "nepDate": "19.07.1994"
    },
    {
        "engDate": "1937-11-05",
        "nepDate": "20.07.1994"
    },
    {
        "engDate": "1937-11-06",
        "nepDate": "21.07.1994"
    },
    {
        "engDate": "1937-11-07",
        "nepDate": "22.07.1994"
    },
    {
        "engDate": "1937-11-08",
        "nepDate": "23.07.1994"
    },
    {
        "engDate": "1937-11-09",
        "nepDate": "24.07.1994"
    },
    {
        "engDate": "1937-11-10",
        "nepDate": "25.07.1994"
    },
    {
        "engDate": "1937-11-11",
        "nepDate": "26.07.1994"
    },
    {
        "engDate": "1937-11-12",
        "nepDate": "27.07.1994"
    },
    {
        "engDate": "1937-11-13",
        "nepDate": "28.07.1994"
    },
    {
        "engDate": "1937-11-14",
        "nepDate": "29.07.1994"
    },
    {
        "engDate": "1937-11-15",
        "nepDate": "30.07.1994"
    },
    {
        "engDate": "1937-11-16",
        "nepDate": "01.08.1994"
    },
    {
        "engDate": "1937-11-17",
        "nepDate": "02.08.1994"
    },
    {
        "engDate": "1937-11-18",
        "nepDate": "03.08.1994"
    },
    {
        "engDate": "1937-11-19",
        "nepDate": "04.08.1994"
    },
    {
        "engDate": "1937-11-20",
        "nepDate": "05.08.1994"
    },
    {
        "engDate": "1937-11-21",
        "nepDate": "06.08.1994"
    },
    {
        "engDate": "1937-11-22",
        "nepDate": "07.08.1994"
    },
    {
        "engDate": "1937-11-23",
        "nepDate": "08.08.1994"
    },
    {
        "engDate": "1937-11-24",
        "nepDate": "09.08.1994"
    },
    {
        "engDate": "1937-11-25",
        "nepDate": "10.08.1994"
    },
    {
        "engDate": "1937-11-26",
        "nepDate": "11.08.1994"
    },
    {
        "engDate": "1937-11-27",
        "nepDate": "12.08.1994"
    },
    {
        "engDate": "1937-11-28",
        "nepDate": "13.08.1994"
    },
    {
        "engDate": "1937-11-29",
        "nepDate": "14.08.1994"
    },
    {
        "engDate": "1937-11-30",
        "nepDate": "15.08.1994"
    },
    {
        "engDate": "1937-12-01",
        "nepDate": "16.08.1994"
    },
    {
        "engDate": "1937-12-02",
        "nepDate": "17.08.1994"
    },
    {
        "engDate": "1937-12-03",
        "nepDate": "18.08.1994"
    },
    {
        "engDate": "1937-12-04",
        "nepDate": "19.08.1994"
    },
    {
        "engDate": "1937-12-05",
        "nepDate": "20.08.1994"
    },
    {
        "engDate": "1937-12-06",
        "nepDate": "21.08.1994"
    },
    {
        "engDate": "1937-12-07",
        "nepDate": "22.08.1994"
    },
    {
        "engDate": "1937-12-08",
        "nepDate": "23.08.1994"
    },
    {
        "engDate": "1937-12-09",
        "nepDate": "24.08.1994"
    },
    {
        "engDate": "1937-12-10",
        "nepDate": "25.08.1994"
    },
    {
        "engDate": "1937-12-11",
        "nepDate": "26.08.1994"
    },
    {
        "engDate": "1937-12-12",
        "nepDate": "27.08.1994"
    },
    {
        "engDate": "1937-12-13",
        "nepDate": "28.08.1994"
    },
    {
        "engDate": "1937-12-14",
        "nepDate": "29.08.1994"
    },
    {
        "engDate": "1937-12-15",
        "nepDate": "01.09.1994"
    },
    {
        "engDate": "1937-12-16",
        "nepDate": "02.09.1994"
    },
    {
        "engDate": "1937-12-17",
        "nepDate": "03.09.1994"
    },
    {
        "engDate": "1937-12-18",
        "nepDate": "04.09.1994"
    },
    {
        "engDate": "1937-12-19",
        "nepDate": "05.09.1994"
    },
    {
        "engDate": "1937-12-20",
        "nepDate": "06.09.1994"
    },
    {
        "engDate": "1937-12-21",
        "nepDate": "07.09.1994"
    },
    {
        "engDate": "1937-12-22",
        "nepDate": "08.09.1994"
    },
    {
        "engDate": "1937-12-23",
        "nepDate": "09.09.1994"
    },
    {
        "engDate": "1937-12-24",
        "nepDate": "10.09.1994"
    },
    {
        "engDate": "1937-12-25",
        "nepDate": "11.09.1994"
    },
    {
        "engDate": "1937-12-26",
        "nepDate": "12.09.1994"
    },
    {
        "engDate": "1937-12-27",
        "nepDate": "13.09.1994"
    },
    {
        "engDate": "1937-12-28",
        "nepDate": "14.09.1994"
    },
    {
        "engDate": "1937-12-29",
        "nepDate": "15.09.1994"
    },
    {
        "engDate": "1937-12-30",
        "nepDate": "16.09.1994"
    },
    {
        "engDate": "1937-12-31",
        "nepDate": "17.09.1994"
    },
    {
        "engDate": "1938-01-01",
        "nepDate": "18.09.1994"
    },
    {
        "engDate": "1938-01-02",
        "nepDate": "19.09.1994"
    },
    {
        "engDate": "1938-01-03",
        "nepDate": "20.09.1994"
    },
    {
        "engDate": "1938-01-04",
        "nepDate": "21.09.1994"
    },
    {
        "engDate": "1938-01-05",
        "nepDate": "22.09.1994"
    },
    {
        "engDate": "1938-01-06",
        "nepDate": "23.09.1994"
    },
    {
        "engDate": "1938-01-07",
        "nepDate": "24.09.1994"
    },
    {
        "engDate": "1938-01-08",
        "nepDate": "25.09.1994"
    },
    {
        "engDate": "1938-01-09",
        "nepDate": "26.09.1994"
    },
    {
        "engDate": "1938-01-10",
        "nepDate": "27.09.1994"
    },
    {
        "engDate": "1938-01-11",
        "nepDate": "28.09.1994"
    },
    {
        "engDate": "1938-01-12",
        "nepDate": "29.09.1994"
    },
    {
        "engDate": "1938-01-13",
        "nepDate": "30.09.1994"
    },
    {
        "engDate": "1938-01-14",
        "nepDate": "01.10.1994"
    },
    {
        "engDate": "1938-01-15",
        "nepDate": "02.10.1994"
    },
    {
        "engDate": "1938-01-16",
        "nepDate": "03.10.1994"
    },
    {
        "engDate": "1938-01-17",
        "nepDate": "04.10.1994"
    },
    {
        "engDate": "1938-01-18",
        "nepDate": "05.10.1994"
    },
    {
        "engDate": "1938-01-19",
        "nepDate": "06.10.1994"
    },
    {
        "engDate": "1938-01-20",
        "nepDate": "07.10.1994"
    },
    {
        "engDate": "1938-01-21",
        "nepDate": "08.10.1994"
    },
    {
        "engDate": "1938-01-22",
        "nepDate": "09.10.1994"
    },
    {
        "engDate": "1938-01-23",
        "nepDate": "10.10.1994"
    },
    {
        "engDate": "1938-01-24",
        "nepDate": "11.10.1994"
    },
    {
        "engDate": "1938-01-25",
        "nepDate": "12.10.1994"
    },
    {
        "engDate": "1938-01-26",
        "nepDate": "13.10.1994"
    },
    {
        "engDate": "1938-01-27",
        "nepDate": "14.10.1994"
    },
    {
        "engDate": "1938-01-28",
        "nepDate": "15.10.1994"
    },
    {
        "engDate": "1938-01-29",
        "nepDate": "16.10.1994"
    },
    {
        "engDate": "1938-01-30",
        "nepDate": "17.10.1994"
    },
    {
        "engDate": "1938-01-31",
        "nepDate": "18.10.1994"
    },
    {
        "engDate": "1938-02-01",
        "nepDate": "19.10.1994"
    },
    {
        "engDate": "1938-02-02",
        "nepDate": "20.10.1994"
    },
    {
        "engDate": "1938-02-03",
        "nepDate": "21.10.1994"
    },
    {
        "engDate": "1938-02-04",
        "nepDate": "22.10.1994"
    },
    {
        "engDate": "1938-02-05",
        "nepDate": "23.10.1994"
    },
    {
        "engDate": "1938-02-06",
        "nepDate": "24.10.1994"
    },
    {
        "engDate": "1938-02-07",
        "nepDate": "25.10.1994"
    },
    {
        "engDate": "1938-02-08",
        "nepDate": "26.10.1994"
    },
    {
        "engDate": "1938-02-09",
        "nepDate": "27.10.1994"
    },
    {
        "engDate": "1938-02-10",
        "nepDate": "28.10.1994"
    },
    {
        "engDate": "1938-02-11",
        "nepDate": "29.10.1994"
    },
    {
        "engDate": "1938-02-12",
        "nepDate": "01.11.1994"
    },
    {
        "engDate": "1938-02-13",
        "nepDate": "02.11.1994"
    },
    {
        "engDate": "1938-02-14",
        "nepDate": "03.11.1994"
    },
    {
        "engDate": "1938-02-15",
        "nepDate": "04.11.1994"
    },
    {
        "engDate": "1938-02-16",
        "nepDate": "05.11.1994"
    },
    {
        "engDate": "1938-02-17",
        "nepDate": "06.11.1994"
    },
    {
        "engDate": "1938-02-18",
        "nepDate": "07.11.1994"
    },
    {
        "engDate": "1938-02-19",
        "nepDate": "08.11.1994"
    },
    {
        "engDate": "1938-02-20",
        "nepDate": "09.11.1994"
    },
    {
        "engDate": "1938-02-21",
        "nepDate": "10.11.1994"
    },
    {
        "engDate": "1938-02-22",
        "nepDate": "11.11.1994"
    },
    {
        "engDate": "1938-02-23",
        "nepDate": "12.11.1994"
    },
    {
        "engDate": "1938-02-24",
        "nepDate": "13.11.1994"
    },
    {
        "engDate": "1938-02-25",
        "nepDate": "14.11.1994"
    },
    {
        "engDate": "1938-02-26",
        "nepDate": "15.11.1994"
    },
    {
        "engDate": "1938-02-27",
        "nepDate": "16.11.1994"
    },
    {
        "engDate": "1938-02-28",
        "nepDate": "17.11.1994"
    },
    {
        "engDate": "1938-03-01",
        "nepDate": "18.11.1994"
    },
    {
        "engDate": "1938-03-02",
        "nepDate": "19.11.1994"
    },
    {
        "engDate": "1938-03-03",
        "nepDate": "20.11.1994"
    },
    {
        "engDate": "1938-03-04",
        "nepDate": "21.11.1994"
    },
    {
        "engDate": "1938-03-05",
        "nepDate": "22.11.1994"
    },
    {
        "engDate": "1938-03-06",
        "nepDate": "23.11.1994"
    },
    {
        "engDate": "1938-03-07",
        "nepDate": "24.11.1994"
    },
    {
        "engDate": "1938-03-08",
        "nepDate": "25.11.1994"
    },
    {
        "engDate": "1938-03-09",
        "nepDate": "26.11.1994"
    },
    {
        "engDate": "1938-03-10",
        "nepDate": "27.11.1994"
    },
    {
        "engDate": "1938-03-11",
        "nepDate": "28.11.1994"
    },
    {
        "engDate": "1938-03-12",
        "nepDate": "29.11.1994"
    },
    {
        "engDate": "1938-03-13",
        "nepDate": "30.11.1994"
    },
    {
        "engDate": "1938-03-14",
        "nepDate": "01.12.1994"
    },
    {
        "engDate": "1938-03-15",
        "nepDate": "02.12.1994"
    },
    {
        "engDate": "1938-03-16",
        "nepDate": "03.12.1994"
    },
    {
        "engDate": "1938-03-17",
        "nepDate": "04.12.1994"
    },
    {
        "engDate": "1938-03-18",
        "nepDate": "05.12.1994"
    },
    {
        "engDate": "1938-03-19",
        "nepDate": "06.12.1994"
    },
    {
        "engDate": "1938-03-20",
        "nepDate": "07.12.1994"
    },
    {
        "engDate": "1938-03-21",
        "nepDate": "08.12.1994"
    },
    {
        "engDate": "1938-03-22",
        "nepDate": "09.12.1994"
    },
    {
        "engDate": "1938-03-23",
        "nepDate": "10.12.1994"
    },
    {
        "engDate": "1938-03-24",
        "nepDate": "11.12.1994"
    },
    {
        "engDate": "1938-03-25",
        "nepDate": "12.12.1994"
    },
    {
        "engDate": "1938-03-26",
        "nepDate": "13.12.1994"
    },
    {
        "engDate": "1938-03-27",
        "nepDate": "14.12.1994"
    },
    {
        "engDate": "1938-03-28",
        "nepDate": "15.12.1994"
    },
    {
        "engDate": "1938-03-29",
        "nepDate": "16.12.1994"
    },
    {
        "engDate": "1938-03-30",
        "nepDate": "17.12.1994"
    },
    {
        "engDate": "1938-03-31",
        "nepDate": "18.12.1994"
    },
    {
        "engDate": "1938-04-01",
        "nepDate": "19.12.1994"
    },
    {
        "engDate": "1938-04-02",
        "nepDate": "20.12.1994"
    },
    {
        "engDate": "1938-04-03",
        "nepDate": "21.12.1994"
    },
    {
        "engDate": "1938-04-04",
        "nepDate": "22.12.1994"
    },
    {
        "engDate": "1938-04-05",
        "nepDate": "23.12.1994"
    },
    {
        "engDate": "1938-04-06",
        "nepDate": "24.12.1994"
    },
    {
        "engDate": "1938-04-07",
        "nepDate": "25.12.1994"
    },
    {
        "engDate": "1938-04-08",
        "nepDate": "26.12.1994"
    },
    {
        "engDate": "1938-04-09",
        "nepDate": "27.12.1994"
    },
    {
        "engDate": "1938-04-10",
        "nepDate": "28.12.1994"
    },
    {
        "engDate": "1938-04-11",
        "nepDate": "29.12.1994"
    },
    {
        "engDate": "1938-04-12",
        "nepDate": "30.12.1994"
    },
    {
        "engDate": "1938-04-13",
        "nepDate": "01.01.1995"
    },
    {
        "engDate": "1938-04-14",
        "nepDate": "02.01.1995"
    },
    {
        "engDate": "1938-04-15",
        "nepDate": "03.01.1995"
    },
    {
        "engDate": "1938-04-16",
        "nepDate": "04.01.1995"
    },
    {
        "engDate": "1938-04-17",
        "nepDate": "05.01.1995"
    },
    {
        "engDate": "1938-04-18",
        "nepDate": "06.01.1995"
    },
    {
        "engDate": "1938-04-19",
        "nepDate": "07.01.1995"
    },
    {
        "engDate": "1938-04-20",
        "nepDate": "08.01.1995"
    },
    {
        "engDate": "1938-04-21",
        "nepDate": "09.01.1995"
    },
    {
        "engDate": "1938-04-22",
        "nepDate": "10.01.1995"
    },
    {
        "engDate": "1938-04-23",
        "nepDate": "11.01.1995"
    },
    {
        "engDate": "1938-04-24",
        "nepDate": "12.01.1995"
    },
    {
        "engDate": "1938-04-25",
        "nepDate": "13.01.1995"
    },
    {
        "engDate": "1938-04-26",
        "nepDate": "14.01.1995"
    },
    {
        "engDate": "1938-04-27",
        "nepDate": "15.01.1995"
    },
    {
        "engDate": "1938-04-28",
        "nepDate": "16.01.1995"
    },
    {
        "engDate": "1938-04-29",
        "nepDate": "17.01.1995"
    },
    {
        "engDate": "1938-04-30",
        "nepDate": "18.01.1995"
    },
    {
        "engDate": "1938-05-01",
        "nepDate": "19.01.1995"
    },
    {
        "engDate": "1938-05-02",
        "nepDate": "20.01.1995"
    },
    {
        "engDate": "1938-05-03",
        "nepDate": "21.01.1995"
    },
    {
        "engDate": "1938-05-04",
        "nepDate": "22.01.1995"
    },
    {
        "engDate": "1938-05-05",
        "nepDate": "23.01.1995"
    },
    {
        "engDate": "1938-05-06",
        "nepDate": "24.01.1995"
    },
    {
        "engDate": "1938-05-07",
        "nepDate": "25.01.1995"
    },
    {
        "engDate": "1938-05-08",
        "nepDate": "26.01.1995"
    },
    {
        "engDate": "1938-05-09",
        "nepDate": "27.01.1995"
    },
    {
        "engDate": "1938-05-10",
        "nepDate": "28.01.1995"
    },
    {
        "engDate": "1938-05-11",
        "nepDate": "29.01.1995"
    },
    {
        "engDate": "1938-05-12",
        "nepDate": "30.01.1995"
    },
    {
        "engDate": "1938-05-13",
        "nepDate": "31.01.1995"
    },
    {
        "engDate": "1938-05-14",
        "nepDate": "01.02.1995"
    },
    {
        "engDate": "1938-05-15",
        "nepDate": "02.02.1995"
    },
    {
        "engDate": "1938-05-16",
        "nepDate": "03.02.1995"
    },
    {
        "engDate": "1938-05-17",
        "nepDate": "04.02.1995"
    },
    {
        "engDate": "1938-05-18",
        "nepDate": "05.02.1995"
    },
    {
        "engDate": "1938-05-19",
        "nepDate": "06.02.1995"
    },
    {
        "engDate": "1938-05-20",
        "nepDate": "07.02.1995"
    },
    {
        "engDate": "1938-05-21",
        "nepDate": "08.02.1995"
    },
    {
        "engDate": "1938-05-22",
        "nepDate": "09.02.1995"
    },
    {
        "engDate": "1938-05-23",
        "nepDate": "10.02.1995"
    },
    {
        "engDate": "1938-05-24",
        "nepDate": "11.02.1995"
    },
    {
        "engDate": "1938-05-25",
        "nepDate": "12.02.1995"
    },
    {
        "engDate": "1938-05-26",
        "nepDate": "13.02.1995"
    },
    {
        "engDate": "1938-05-27",
        "nepDate": "14.02.1995"
    },
    {
        "engDate": "1938-05-28",
        "nepDate": "15.02.1995"
    },
    {
        "engDate": "1938-05-29",
        "nepDate": "16.02.1995"
    },
    {
        "engDate": "1938-05-30",
        "nepDate": "17.02.1995"
    },
    {
        "engDate": "1938-05-31",
        "nepDate": "18.02.1995"
    },
    {
        "engDate": "1938-06-01",
        "nepDate": "19.02.1995"
    },
    {
        "engDate": "1938-06-02",
        "nepDate": "20.02.1995"
    },
    {
        "engDate": "1938-06-03",
        "nepDate": "21.02.1995"
    },
    {
        "engDate": "1938-06-04",
        "nepDate": "22.02.1995"
    },
    {
        "engDate": "1938-06-05",
        "nepDate": "23.02.1995"
    },
    {
        "engDate": "1938-06-06",
        "nepDate": "24.02.1995"
    },
    {
        "engDate": "1938-06-07",
        "nepDate": "25.02.1995"
    },
    {
        "engDate": "1938-06-08",
        "nepDate": "26.02.1995"
    },
    {
        "engDate": "1938-06-09",
        "nepDate": "27.02.1995"
    },
    {
        "engDate": "1938-06-10",
        "nepDate": "28.02.1995"
    },
    {
        "engDate": "1938-06-11",
        "nepDate": "29.02.1995"
    },
    {
        "engDate": "1938-06-12",
        "nepDate": "30.02.1995"
    },
    {
        "engDate": "1938-06-13",
        "nepDate": "31.02.1995"
    },
    {
        "engDate": "1938-06-14",
        "nepDate": "32.02.1995"
    },
    {
        "engDate": "1938-06-15",
        "nepDate": "01.03.1995"
    },
    {
        "engDate": "1938-06-16",
        "nepDate": "02.03.1995"
    },
    {
        "engDate": "1938-06-17",
        "nepDate": "03.03.1995"
    },
    {
        "engDate": "1938-06-18",
        "nepDate": "04.03.1995"
    },
    {
        "engDate": "1938-06-19",
        "nepDate": "05.03.1995"
    },
    {
        "engDate": "1938-06-20",
        "nepDate": "06.03.1995"
    },
    {
        "engDate": "1938-06-21",
        "nepDate": "07.03.1995"
    },
    {
        "engDate": "1938-06-22",
        "nepDate": "08.03.1995"
    },
    {
        "engDate": "1938-06-23",
        "nepDate": "09.03.1995"
    },
    {
        "engDate": "1938-06-24",
        "nepDate": "10.03.1995"
    },
    {
        "engDate": "1938-06-25",
        "nepDate": "11.03.1995"
    },
    {
        "engDate": "1938-06-26",
        "nepDate": "12.03.1995"
    },
    {
        "engDate": "1938-06-27",
        "nepDate": "13.03.1995"
    },
    {
        "engDate": "1938-06-28",
        "nepDate": "14.03.1995"
    },
    {
        "engDate": "1938-06-29",
        "nepDate": "15.03.1995"
    },
    {
        "engDate": "1938-06-30",
        "nepDate": "16.03.1995"
    },
    {
        "engDate": "1938-07-01",
        "nepDate": "17.03.1995"
    },
    {
        "engDate": "1938-07-02",
        "nepDate": "18.03.1995"
    },
    {
        "engDate": "1938-07-03",
        "nepDate": "19.03.1995"
    },
    {
        "engDate": "1938-07-04",
        "nepDate": "20.03.1995"
    },
    {
        "engDate": "1938-07-05",
        "nepDate": "21.03.1995"
    },
    {
        "engDate": "1938-07-06",
        "nepDate": "22.03.1995"
    },
    {
        "engDate": "1938-07-07",
        "nepDate": "23.03.1995"
    },
    {
        "engDate": "1938-07-08",
        "nepDate": "24.03.1995"
    },
    {
        "engDate": "1938-07-09",
        "nepDate": "25.03.1995"
    },
    {
        "engDate": "1938-07-10",
        "nepDate": "26.03.1995"
    },
    {
        "engDate": "1938-07-11",
        "nepDate": "27.03.1995"
    },
    {
        "engDate": "1938-07-12",
        "nepDate": "28.03.1995"
    },
    {
        "engDate": "1938-07-13",
        "nepDate": "29.03.1995"
    },
    {
        "engDate": "1938-07-14",
        "nepDate": "30.03.1995"
    },
    {
        "engDate": "1938-07-15",
        "nepDate": "31.03.1995"
    },
    {
        "engDate": "1938-07-16",
        "nepDate": "01.04.1995"
    },
    {
        "engDate": "1938-07-17",
        "nepDate": "02.04.1995"
    },
    {
        "engDate": "1938-07-18",
        "nepDate": "03.04.1995"
    },
    {
        "engDate": "1938-07-19",
        "nepDate": "04.04.1995"
    },
    {
        "engDate": "1938-07-20",
        "nepDate": "05.04.1995"
    },
    {
        "engDate": "1938-07-21",
        "nepDate": "06.04.1995"
    },
    {
        "engDate": "1938-07-22",
        "nepDate": "07.04.1995"
    },
    {
        "engDate": "1938-07-23",
        "nepDate": "08.04.1995"
    },
    {
        "engDate": "1938-07-24",
        "nepDate": "09.04.1995"
    },
    {
        "engDate": "1938-07-25",
        "nepDate": "10.04.1995"
    },
    {
        "engDate": "1938-07-26",
        "nepDate": "11.04.1995"
    },
    {
        "engDate": "1938-07-27",
        "nepDate": "12.04.1995"
    },
    {
        "engDate": "1938-07-28",
        "nepDate": "13.04.1995"
    },
    {
        "engDate": "1938-07-29",
        "nepDate": "14.04.1995"
    },
    {
        "engDate": "1938-07-30",
        "nepDate": "15.04.1995"
    },
    {
        "engDate": "1938-07-31",
        "nepDate": "16.04.1995"
    },
    {
        "engDate": "1938-08-01",
        "nepDate": "17.04.1995"
    },
    {
        "engDate": "1938-08-02",
        "nepDate": "18.04.1995"
    },
    {
        "engDate": "1938-08-03",
        "nepDate": "19.04.1995"
    },
    {
        "engDate": "1938-08-04",
        "nepDate": "20.04.1995"
    },
    {
        "engDate": "1938-08-05",
        "nepDate": "21.04.1995"
    },
    {
        "engDate": "1938-08-06",
        "nepDate": "22.04.1995"
    },
    {
        "engDate": "1938-08-07",
        "nepDate": "23.04.1995"
    },
    {
        "engDate": "1938-08-08",
        "nepDate": "24.04.1995"
    },
    {
        "engDate": "1938-08-09",
        "nepDate": "25.04.1995"
    },
    {
        "engDate": "1938-08-10",
        "nepDate": "26.04.1995"
    },
    {
        "engDate": "1938-08-11",
        "nepDate": "27.04.1995"
    },
    {
        "engDate": "1938-08-12",
        "nepDate": "28.04.1995"
    },
    {
        "engDate": "1938-08-13",
        "nepDate": "29.04.1995"
    },
    {
        "engDate": "1938-08-14",
        "nepDate": "30.04.1995"
    },
    {
        "engDate": "1938-08-15",
        "nepDate": "31.04.1995"
    },
    {
        "engDate": "1938-08-16",
        "nepDate": "32.04.1995"
    },
    {
        "engDate": "1938-08-17",
        "nepDate": "01.05.1995"
    },
    {
        "engDate": "1938-08-18",
        "nepDate": "02.05.1995"
    },
    {
        "engDate": "1938-08-19",
        "nepDate": "03.05.1995"
    },
    {
        "engDate": "1938-08-20",
        "nepDate": "04.05.1995"
    },
    {
        "engDate": "1938-08-21",
        "nepDate": "05.05.1995"
    },
    {
        "engDate": "1938-08-22",
        "nepDate": "06.05.1995"
    },
    {
        "engDate": "1938-08-23",
        "nepDate": "07.05.1995"
    },
    {
        "engDate": "1938-08-24",
        "nepDate": "08.05.1995"
    },
    {
        "engDate": "1938-08-25",
        "nepDate": "09.05.1995"
    },
    {
        "engDate": "1938-08-26",
        "nepDate": "10.05.1995"
    },
    {
        "engDate": "1938-08-27",
        "nepDate": "11.05.1995"
    },
    {
        "engDate": "1938-08-28",
        "nepDate": "12.05.1995"
    },
    {
        "engDate": "1938-08-29",
        "nepDate": "13.05.1995"
    },
    {
        "engDate": "1938-08-30",
        "nepDate": "14.05.1995"
    },
    {
        "engDate": "1938-08-31",
        "nepDate": "15.05.1995"
    },
    {
        "engDate": "1938-09-01",
        "nepDate": "16.05.1995"
    },
    {
        "engDate": "1938-09-02",
        "nepDate": "17.05.1995"
    },
    {
        "engDate": "1938-09-03",
        "nepDate": "18.05.1995"
    },
    {
        "engDate": "1938-09-04",
        "nepDate": "19.05.1995"
    },
    {
        "engDate": "1938-09-05",
        "nepDate": "20.05.1995"
    },
    {
        "engDate": "1938-09-06",
        "nepDate": "21.05.1995"
    },
    {
        "engDate": "1938-09-07",
        "nepDate": "22.05.1995"
    },
    {
        "engDate": "1938-09-08",
        "nepDate": "23.05.1995"
    },
    {
        "engDate": "1938-09-09",
        "nepDate": "24.05.1995"
    },
    {
        "engDate": "1938-09-10",
        "nepDate": "25.05.1995"
    },
    {
        "engDate": "1938-09-11",
        "nepDate": "26.05.1995"
    },
    {
        "engDate": "1938-09-12",
        "nepDate": "27.05.1995"
    },
    {
        "engDate": "1938-09-13",
        "nepDate": "28.05.1995"
    },
    {
        "engDate": "1938-09-14",
        "nepDate": "29.05.1995"
    },
    {
        "engDate": "1938-09-15",
        "nepDate": "30.05.1995"
    },
    {
        "engDate": "1938-09-16",
        "nepDate": "31.05.1995"
    },
    {
        "engDate": "1938-09-17",
        "nepDate": "01.06.1995"
    },
    {
        "engDate": "1938-09-18",
        "nepDate": "02.06.1995"
    },
    {
        "engDate": "1938-09-19",
        "nepDate": "03.06.1995"
    },
    {
        "engDate": "1938-09-20",
        "nepDate": "04.06.1995"
    },
    {
        "engDate": "1938-09-21",
        "nepDate": "05.06.1995"
    },
    {
        "engDate": "1938-09-22",
        "nepDate": "06.06.1995"
    },
    {
        "engDate": "1938-09-23",
        "nepDate": "07.06.1995"
    },
    {
        "engDate": "1938-09-24",
        "nepDate": "08.06.1995"
    },
    {
        "engDate": "1938-09-25",
        "nepDate": "09.06.1995"
    },
    {
        "engDate": "1938-09-26",
        "nepDate": "10.06.1995"
    },
    {
        "engDate": "1938-09-27",
        "nepDate": "11.06.1995"
    },
    {
        "engDate": "1938-09-28",
        "nepDate": "12.06.1995"
    },
    {
        "engDate": "1938-09-29",
        "nepDate": "13.06.1995"
    },
    {
        "engDate": "1938-09-30",
        "nepDate": "14.06.1995"
    },
    {
        "engDate": "1938-10-01",
        "nepDate": "15.06.1995"
    },
    {
        "engDate": "1938-10-02",
        "nepDate": "16.06.1995"
    },
    {
        "engDate": "1938-10-03",
        "nepDate": "17.06.1995"
    },
    {
        "engDate": "1938-10-04",
        "nepDate": "18.06.1995"
    },
    {
        "engDate": "1938-10-05",
        "nepDate": "19.06.1995"
    },
    {
        "engDate": "1938-10-06",
        "nepDate": "20.06.1995"
    },
    {
        "engDate": "1938-10-07",
        "nepDate": "21.06.1995"
    },
    {
        "engDate": "1938-10-08",
        "nepDate": "22.06.1995"
    },
    {
        "engDate": "1938-10-09",
        "nepDate": "23.06.1995"
    },
    {
        "engDate": "1938-10-10",
        "nepDate": "24.06.1995"
    },
    {
        "engDate": "1938-10-11",
        "nepDate": "25.06.1995"
    },
    {
        "engDate": "1938-10-12",
        "nepDate": "26.06.1995"
    },
    {
        "engDate": "1938-10-13",
        "nepDate": "27.06.1995"
    },
    {
        "engDate": "1938-10-14",
        "nepDate": "28.06.1995"
    },
    {
        "engDate": "1938-10-15",
        "nepDate": "29.06.1995"
    },
    {
        "engDate": "1938-10-16",
        "nepDate": "30.06.1995"
    },
    {
        "engDate": "1938-10-17",
        "nepDate": "01.07.1995"
    },
    {
        "engDate": "1938-10-18",
        "nepDate": "02.07.1995"
    },
    {
        "engDate": "1938-10-19",
        "nepDate": "03.07.1995"
    },
    {
        "engDate": "1938-10-20",
        "nepDate": "04.07.1995"
    },
    {
        "engDate": "1938-10-21",
        "nepDate": "05.07.1995"
    },
    {
        "engDate": "1938-10-22",
        "nepDate": "06.07.1995"
    },
    {
        "engDate": "1938-10-23",
        "nepDate": "07.07.1995"
    },
    {
        "engDate": "1938-10-24",
        "nepDate": "08.07.1995"
    },
    {
        "engDate": "1938-10-25",
        "nepDate": "09.07.1995"
    },
    {
        "engDate": "1938-10-26",
        "nepDate": "10.07.1995"
    },
    {
        "engDate": "1938-10-27",
        "nepDate": "11.07.1995"
    },
    {
        "engDate": "1938-10-28",
        "nepDate": "12.07.1995"
    },
    {
        "engDate": "1938-10-29",
        "nepDate": "13.07.1995"
    },
    {
        "engDate": "1938-10-30",
        "nepDate": "14.07.1995"
    },
    {
        "engDate": "1938-10-31",
        "nepDate": "15.07.1995"
    },
    {
        "engDate": "1938-11-01",
        "nepDate": "16.07.1995"
    },
    {
        "engDate": "1938-11-02",
        "nepDate": "17.07.1995"
    },
    {
        "engDate": "1938-11-03",
        "nepDate": "18.07.1995"
    },
    {
        "engDate": "1938-11-04",
        "nepDate": "19.07.1995"
    },
    {
        "engDate": "1938-11-05",
        "nepDate": "20.07.1995"
    },
    {
        "engDate": "1938-11-06",
        "nepDate": "21.07.1995"
    },
    {
        "engDate": "1938-11-07",
        "nepDate": "22.07.1995"
    },
    {
        "engDate": "1938-11-08",
        "nepDate": "23.07.1995"
    },
    {
        "engDate": "1938-11-09",
        "nepDate": "24.07.1995"
    },
    {
        "engDate": "1938-11-10",
        "nepDate": "25.07.1995"
    },
    {
        "engDate": "1938-11-11",
        "nepDate": "26.07.1995"
    },
    {
        "engDate": "1938-11-12",
        "nepDate": "27.07.1995"
    },
    {
        "engDate": "1938-11-13",
        "nepDate": "28.07.1995"
    },
    {
        "engDate": "1938-11-14",
        "nepDate": "29.07.1995"
    },
    {
        "engDate": "1938-11-15",
        "nepDate": "30.07.1995"
    },
    {
        "engDate": "1938-11-16",
        "nepDate": "01.08.1995"
    },
    {
        "engDate": "1938-11-17",
        "nepDate": "02.08.1995"
    },
    {
        "engDate": "1938-11-18",
        "nepDate": "03.08.1995"
    },
    {
        "engDate": "1938-11-19",
        "nepDate": "04.08.1995"
    },
    {
        "engDate": "1938-11-20",
        "nepDate": "05.08.1995"
    },
    {
        "engDate": "1938-11-21",
        "nepDate": "06.08.1995"
    },
    {
        "engDate": "1938-11-22",
        "nepDate": "07.08.1995"
    },
    {
        "engDate": "1938-11-23",
        "nepDate": "08.08.1995"
    },
    {
        "engDate": "1938-11-24",
        "nepDate": "09.08.1995"
    },
    {
        "engDate": "1938-11-25",
        "nepDate": "10.08.1995"
    },
    {
        "engDate": "1938-11-26",
        "nepDate": "11.08.1995"
    },
    {
        "engDate": "1938-11-27",
        "nepDate": "12.08.1995"
    },
    {
        "engDate": "1938-11-28",
        "nepDate": "13.08.1995"
    },
    {
        "engDate": "1938-11-29",
        "nepDate": "14.08.1995"
    },
    {
        "engDate": "1938-11-30",
        "nepDate": "15.08.1995"
    },
    {
        "engDate": "1938-12-01",
        "nepDate": "16.08.1995"
    },
    {
        "engDate": "1938-12-02",
        "nepDate": "17.08.1995"
    },
    {
        "engDate": "1938-12-03",
        "nepDate": "18.08.1995"
    },
    {
        "engDate": "1938-12-04",
        "nepDate": "19.08.1995"
    },
    {
        "engDate": "1938-12-05",
        "nepDate": "20.08.1995"
    },
    {
        "engDate": "1938-12-06",
        "nepDate": "21.08.1995"
    },
    {
        "engDate": "1938-12-07",
        "nepDate": "22.08.1995"
    },
    {
        "engDate": "1938-12-08",
        "nepDate": "23.08.1995"
    },
    {
        "engDate": "1938-12-09",
        "nepDate": "24.08.1995"
    },
    {
        "engDate": "1938-12-10",
        "nepDate": "25.08.1995"
    },
    {
        "engDate": "1938-12-11",
        "nepDate": "26.08.1995"
    },
    {
        "engDate": "1938-12-12",
        "nepDate": "27.08.1995"
    },
    {
        "engDate": "1938-12-13",
        "nepDate": "28.08.1995"
    },
    {
        "engDate": "1938-12-14",
        "nepDate": "29.08.1995"
    },
    {
        "engDate": "1938-12-15",
        "nepDate": "30.08.1995"
    },
    {
        "engDate": "1938-12-16",
        "nepDate": "01.09.1995"
    },
    {
        "engDate": "1938-12-17",
        "nepDate": "02.09.1995"
    },
    {
        "engDate": "1938-12-18",
        "nepDate": "03.09.1995"
    },
    {
        "engDate": "1938-12-19",
        "nepDate": "04.09.1995"
    },
    {
        "engDate": "1938-12-20",
        "nepDate": "05.09.1995"
    },
    {
        "engDate": "1938-12-21",
        "nepDate": "06.09.1995"
    },
    {
        "engDate": "1938-12-22",
        "nepDate": "07.09.1995"
    },
    {
        "engDate": "1938-12-23",
        "nepDate": "08.09.1995"
    },
    {
        "engDate": "1938-12-24",
        "nepDate": "09.09.1995"
    },
    {
        "engDate": "1938-12-25",
        "nepDate": "10.09.1995"
    },
    {
        "engDate": "1938-12-26",
        "nepDate": "11.09.1995"
    },
    {
        "engDate": "1938-12-27",
        "nepDate": "12.09.1995"
    },
    {
        "engDate": "1938-12-28",
        "nepDate": "13.09.1995"
    },
    {
        "engDate": "1938-12-29",
        "nepDate": "14.09.1995"
    },
    {
        "engDate": "1938-12-30",
        "nepDate": "15.09.1995"
    },
    {
        "engDate": "1938-12-31",
        "nepDate": "16.09.1995"
    },
    {
        "engDate": "1939-01-01",
        "nepDate": "17.09.1995"
    },
    {
        "engDate": "1939-01-02",
        "nepDate": "18.09.1995"
    },
    {
        "engDate": "1939-01-03",
        "nepDate": "19.09.1995"
    },
    {
        "engDate": "1939-01-04",
        "nepDate": "20.09.1995"
    },
    {
        "engDate": "1939-01-05",
        "nepDate": "21.09.1995"
    },
    {
        "engDate": "1939-01-06",
        "nepDate": "22.09.1995"
    },
    {
        "engDate": "1939-01-07",
        "nepDate": "23.09.1995"
    },
    {
        "engDate": "1939-01-08",
        "nepDate": "24.09.1995"
    },
    {
        "engDate": "1939-01-09",
        "nepDate": "25.09.1995"
    },
    {
        "engDate": "1939-01-10",
        "nepDate": "26.09.1995"
    },
    {
        "engDate": "1939-01-11",
        "nepDate": "27.09.1995"
    },
    {
        "engDate": "1939-01-12",
        "nepDate": "28.09.1995"
    },
    {
        "engDate": "1939-01-13",
        "nepDate": "29.09.1995"
    },
    {
        "engDate": "1939-01-14",
        "nepDate": "01.10.1995"
    },
    {
        "engDate": "1939-01-15",
        "nepDate": "02.10.1995"
    },
    {
        "engDate": "1939-01-16",
        "nepDate": "03.10.1995"
    },
    {
        "engDate": "1939-01-17",
        "nepDate": "04.10.1995"
    },
    {
        "engDate": "1939-01-18",
        "nepDate": "05.10.1995"
    },
    {
        "engDate": "1939-01-19",
        "nepDate": "06.10.1995"
    },
    {
        "engDate": "1939-01-20",
        "nepDate": "07.10.1995"
    },
    {
        "engDate": "1939-01-21",
        "nepDate": "08.10.1995"
    },
    {
        "engDate": "1939-01-22",
        "nepDate": "09.10.1995"
    },
    {
        "engDate": "1939-01-23",
        "nepDate": "10.10.1995"
    },
    {
        "engDate": "1939-01-24",
        "nepDate": "11.10.1995"
    },
    {
        "engDate": "1939-01-25",
        "nepDate": "12.10.1995"
    },
    {
        "engDate": "1939-01-26",
        "nepDate": "13.10.1995"
    },
    {
        "engDate": "1939-01-27",
        "nepDate": "14.10.1995"
    },
    {
        "engDate": "1939-01-28",
        "nepDate": "15.10.1995"
    },
    {
        "engDate": "1939-01-29",
        "nepDate": "16.10.1995"
    },
    {
        "engDate": "1939-01-30",
        "nepDate": "17.10.1995"
    },
    {
        "engDate": "1939-01-31",
        "nepDate": "18.10.1995"
    },
    {
        "engDate": "1939-02-01",
        "nepDate": "19.10.1995"
    },
    {
        "engDate": "1939-02-02",
        "nepDate": "20.10.1995"
    },
    {
        "engDate": "1939-02-03",
        "nepDate": "21.10.1995"
    },
    {
        "engDate": "1939-02-04",
        "nepDate": "22.10.1995"
    },
    {
        "engDate": "1939-02-05",
        "nepDate": "23.10.1995"
    },
    {
        "engDate": "1939-02-06",
        "nepDate": "24.10.1995"
    },
    {
        "engDate": "1939-02-07",
        "nepDate": "25.10.1995"
    },
    {
        "engDate": "1939-02-08",
        "nepDate": "26.10.1995"
    },
    {
        "engDate": "1939-02-09",
        "nepDate": "27.10.1995"
    },
    {
        "engDate": "1939-02-10",
        "nepDate": "28.10.1995"
    },
    {
        "engDate": "1939-02-11",
        "nepDate": "29.10.1995"
    },
    {
        "engDate": "1939-02-12",
        "nepDate": "01.11.1995"
    },
    {
        "engDate": "1939-02-13",
        "nepDate": "02.11.1995"
    },
    {
        "engDate": "1939-02-14",
        "nepDate": "03.11.1995"
    },
    {
        "engDate": "1939-02-15",
        "nepDate": "04.11.1995"
    },
    {
        "engDate": "1939-02-16",
        "nepDate": "05.11.1995"
    },
    {
        "engDate": "1939-02-17",
        "nepDate": "06.11.1995"
    },
    {
        "engDate": "1939-02-18",
        "nepDate": "07.11.1995"
    },
    {
        "engDate": "1939-02-19",
        "nepDate": "08.11.1995"
    },
    {
        "engDate": "1939-02-20",
        "nepDate": "09.11.1995"
    },
    {
        "engDate": "1939-02-21",
        "nepDate": "10.11.1995"
    },
    {
        "engDate": "1939-02-22",
        "nepDate": "11.11.1995"
    },
    {
        "engDate": "1939-02-23",
        "nepDate": "12.11.1995"
    },
    {
        "engDate": "1939-02-24",
        "nepDate": "13.11.1995"
    },
    {
        "engDate": "1939-02-25",
        "nepDate": "14.11.1995"
    },
    {
        "engDate": "1939-02-26",
        "nepDate": "15.11.1995"
    },
    {
        "engDate": "1939-02-27",
        "nepDate": "16.11.1995"
    },
    {
        "engDate": "1939-02-28",
        "nepDate": "17.11.1995"
    },
    {
        "engDate": "1939-03-01",
        "nepDate": "18.11.1995"
    },
    {
        "engDate": "1939-03-02",
        "nepDate": "19.11.1995"
    },
    {
        "engDate": "1939-03-03",
        "nepDate": "20.11.1995"
    },
    {
        "engDate": "1939-03-04",
        "nepDate": "21.11.1995"
    },
    {
        "engDate": "1939-03-05",
        "nepDate": "22.11.1995"
    },
    {
        "engDate": "1939-03-06",
        "nepDate": "23.11.1995"
    },
    {
        "engDate": "1939-03-07",
        "nepDate": "24.11.1995"
    },
    {
        "engDate": "1939-03-08",
        "nepDate": "25.11.1995"
    },
    {
        "engDate": "1939-03-09",
        "nepDate": "26.11.1995"
    },
    {
        "engDate": "1939-03-10",
        "nepDate": "27.11.1995"
    },
    {
        "engDate": "1939-03-11",
        "nepDate": "28.11.1995"
    },
    {
        "engDate": "1939-03-12",
        "nepDate": "29.11.1995"
    },
    {
        "engDate": "1939-03-13",
        "nepDate": "30.11.1995"
    },
    {
        "engDate": "1939-03-14",
        "nepDate": "01.12.1995"
    },
    {
        "engDate": "1939-03-15",
        "nepDate": "02.12.1995"
    },
    {
        "engDate": "1939-03-16",
        "nepDate": "03.12.1995"
    },
    {
        "engDate": "1939-03-17",
        "nepDate": "04.12.1995"
    },
    {
        "engDate": "1939-03-18",
        "nepDate": "05.12.1995"
    },
    {
        "engDate": "1939-03-19",
        "nepDate": "06.12.1995"
    },
    {
        "engDate": "1939-03-20",
        "nepDate": "07.12.1995"
    },
    {
        "engDate": "1939-03-21",
        "nepDate": "08.12.1995"
    },
    {
        "engDate": "1939-03-22",
        "nepDate": "09.12.1995"
    },
    {
        "engDate": "1939-03-23",
        "nepDate": "10.12.1995"
    },
    {
        "engDate": "1939-03-24",
        "nepDate": "11.12.1995"
    },
    {
        "engDate": "1939-03-25",
        "nepDate": "12.12.1995"
    },
    {
        "engDate": "1939-03-26",
        "nepDate": "13.12.1995"
    },
    {
        "engDate": "1939-03-27",
        "nepDate": "14.12.1995"
    },
    {
        "engDate": "1939-03-28",
        "nepDate": "15.12.1995"
    },
    {
        "engDate": "1939-03-29",
        "nepDate": "16.12.1995"
    },
    {
        "engDate": "1939-03-30",
        "nepDate": "17.12.1995"
    },
    {
        "engDate": "1939-03-31",
        "nepDate": "18.12.1995"
    },
    {
        "engDate": "1939-04-01",
        "nepDate": "19.12.1995"
    },
    {
        "engDate": "1939-04-02",
        "nepDate": "20.12.1995"
    },
    {
        "engDate": "1939-04-03",
        "nepDate": "21.12.1995"
    },
    {
        "engDate": "1939-04-04",
        "nepDate": "22.12.1995"
    },
    {
        "engDate": "1939-04-05",
        "nepDate": "23.12.1995"
    },
    {
        "engDate": "1939-04-06",
        "nepDate": "24.12.1995"
    },
    {
        "engDate": "1939-04-07",
        "nepDate": "25.12.1995"
    },
    {
        "engDate": "1939-04-08",
        "nepDate": "26.12.1995"
    },
    {
        "engDate": "1939-04-09",
        "nepDate": "27.12.1995"
    },
    {
        "engDate": "1939-04-10",
        "nepDate": "28.12.1995"
    },
    {
        "engDate": "1939-04-11",
        "nepDate": "29.12.1995"
    },
    {
        "engDate": "1939-04-12",
        "nepDate": "30.12.1995"
    },
    {
        "engDate": "1939-04-13",
        "nepDate": "01.01.1996"
    },
    {
        "engDate": "1939-04-14",
        "nepDate": "02.01.1996"
    },
    {
        "engDate": "1939-04-15",
        "nepDate": "03.01.1996"
    },
    {
        "engDate": "1939-04-16",
        "nepDate": "04.01.1996"
    },
    {
        "engDate": "1939-04-17",
        "nepDate": "05.01.1996"
    },
    {
        "engDate": "1939-04-18",
        "nepDate": "06.01.1996"
    },
    {
        "engDate": "1939-04-19",
        "nepDate": "07.01.1996"
    },
    {
        "engDate": "1939-04-20",
        "nepDate": "08.01.1996"
    },
    {
        "engDate": "1939-04-21",
        "nepDate": "09.01.1996"
    },
    {
        "engDate": "1939-04-22",
        "nepDate": "10.01.1996"
    },
    {
        "engDate": "1939-04-23",
        "nepDate": "11.01.1996"
    },
    {
        "engDate": "1939-04-24",
        "nepDate": "12.01.1996"
    },
    {
        "engDate": "1939-04-25",
        "nepDate": "13.01.1996"
    },
    {
        "engDate": "1939-04-26",
        "nepDate": "14.01.1996"
    },
    {
        "engDate": "1939-04-27",
        "nepDate": "15.01.1996"
    },
    {
        "engDate": "1939-04-28",
        "nepDate": "16.01.1996"
    },
    {
        "engDate": "1939-04-29",
        "nepDate": "17.01.1996"
    },
    {
        "engDate": "1939-04-30",
        "nepDate": "18.01.1996"
    },
    {
        "engDate": "1939-05-01",
        "nepDate": "19.01.1996"
    },
    {
        "engDate": "1939-05-02",
        "nepDate": "20.01.1996"
    },
    {
        "engDate": "1939-05-03",
        "nepDate": "21.01.1996"
    },
    {
        "engDate": "1939-05-04",
        "nepDate": "22.01.1996"
    },
    {
        "engDate": "1939-05-05",
        "nepDate": "23.01.1996"
    },
    {
        "engDate": "1939-05-06",
        "nepDate": "24.01.1996"
    },
    {
        "engDate": "1939-05-07",
        "nepDate": "25.01.1996"
    },
    {
        "engDate": "1939-05-08",
        "nepDate": "26.01.1996"
    },
    {
        "engDate": "1939-05-09",
        "nepDate": "27.01.1996"
    },
    {
        "engDate": "1939-05-10",
        "nepDate": "28.01.1996"
    },
    {
        "engDate": "1939-05-11",
        "nepDate": "29.01.1996"
    },
    {
        "engDate": "1939-05-12",
        "nepDate": "30.01.1996"
    },
    {
        "engDate": "1939-05-13",
        "nepDate": "31.01.1996"
    },
    {
        "engDate": "1939-05-14",
        "nepDate": "01.02.1996"
    },
    {
        "engDate": "1939-05-15",
        "nepDate": "02.02.1996"
    },
    {
        "engDate": "1939-05-16",
        "nepDate": "03.02.1996"
    },
    {
        "engDate": "1939-05-17",
        "nepDate": "04.02.1996"
    },
    {
        "engDate": "1939-05-18",
        "nepDate": "05.02.1996"
    },
    {
        "engDate": "1939-05-19",
        "nepDate": "06.02.1996"
    },
    {
        "engDate": "1939-05-20",
        "nepDate": "07.02.1996"
    },
    {
        "engDate": "1939-05-21",
        "nepDate": "08.02.1996"
    },
    {
        "engDate": "1939-05-22",
        "nepDate": "09.02.1996"
    },
    {
        "engDate": "1939-05-23",
        "nepDate": "10.02.1996"
    },
    {
        "engDate": "1939-05-24",
        "nepDate": "11.02.1996"
    },
    {
        "engDate": "1939-05-25",
        "nepDate": "12.02.1996"
    },
    {
        "engDate": "1939-05-26",
        "nepDate": "13.02.1996"
    },
    {
        "engDate": "1939-05-27",
        "nepDate": "14.02.1996"
    },
    {
        "engDate": "1939-05-28",
        "nepDate": "15.02.1996"
    },
    {
        "engDate": "1939-05-29",
        "nepDate": "16.02.1996"
    },
    {
        "engDate": "1939-05-30",
        "nepDate": "17.02.1996"
    },
    {
        "engDate": "1939-05-31",
        "nepDate": "18.02.1996"
    },
    {
        "engDate": "1939-06-01",
        "nepDate": "19.02.1996"
    },
    {
        "engDate": "1939-06-02",
        "nepDate": "20.02.1996"
    },
    {
        "engDate": "1939-06-03",
        "nepDate": "21.02.1996"
    },
    {
        "engDate": "1939-06-04",
        "nepDate": "22.02.1996"
    },
    {
        "engDate": "1939-06-05",
        "nepDate": "23.02.1996"
    },
    {
        "engDate": "1939-06-06",
        "nepDate": "24.02.1996"
    },
    {
        "engDate": "1939-06-07",
        "nepDate": "25.02.1996"
    },
    {
        "engDate": "1939-06-08",
        "nepDate": "26.02.1996"
    },
    {
        "engDate": "1939-06-09",
        "nepDate": "27.02.1996"
    },
    {
        "engDate": "1939-06-10",
        "nepDate": "28.02.1996"
    },
    {
        "engDate": "1939-06-11",
        "nepDate": "29.02.1996"
    },
    {
        "engDate": "1939-06-12",
        "nepDate": "30.02.1996"
    },
    {
        "engDate": "1939-06-13",
        "nepDate": "31.02.1996"
    },
    {
        "engDate": "1939-06-14",
        "nepDate": "32.02.1996"
    },
    {
        "engDate": "1939-06-15",
        "nepDate": "01.03.1996"
    },
    {
        "engDate": "1939-06-16",
        "nepDate": "02.03.1996"
    },
    {
        "engDate": "1939-06-17",
        "nepDate": "03.03.1996"
    },
    {
        "engDate": "1939-06-18",
        "nepDate": "04.03.1996"
    },
    {
        "engDate": "1939-06-19",
        "nepDate": "05.03.1996"
    },
    {
        "engDate": "1939-06-20",
        "nepDate": "06.03.1996"
    },
    {
        "engDate": "1939-06-21",
        "nepDate": "07.03.1996"
    },
    {
        "engDate": "1939-06-22",
        "nepDate": "08.03.1996"
    },
    {
        "engDate": "1939-06-23",
        "nepDate": "09.03.1996"
    },
    {
        "engDate": "1939-06-24",
        "nepDate": "10.03.1996"
    },
    {
        "engDate": "1939-06-25",
        "nepDate": "11.03.1996"
    },
    {
        "engDate": "1939-06-26",
        "nepDate": "12.03.1996"
    },
    {
        "engDate": "1939-06-27",
        "nepDate": "13.03.1996"
    },
    {
        "engDate": "1939-06-28",
        "nepDate": "14.03.1996"
    },
    {
        "engDate": "1939-06-29",
        "nepDate": "15.03.1996"
    },
    {
        "engDate": "1939-06-30",
        "nepDate": "16.03.1996"
    },
    {
        "engDate": "1939-07-01",
        "nepDate": "17.03.1996"
    },
    {
        "engDate": "1939-07-02",
        "nepDate": "18.03.1996"
    },
    {
        "engDate": "1939-07-03",
        "nepDate": "19.03.1996"
    },
    {
        "engDate": "1939-07-04",
        "nepDate": "20.03.1996"
    },
    {
        "engDate": "1939-07-05",
        "nepDate": "21.03.1996"
    },
    {
        "engDate": "1939-07-06",
        "nepDate": "22.03.1996"
    },
    {
        "engDate": "1939-07-07",
        "nepDate": "23.03.1996"
    },
    {
        "engDate": "1939-07-08",
        "nepDate": "24.03.1996"
    },
    {
        "engDate": "1939-07-09",
        "nepDate": "25.03.1996"
    },
    {
        "engDate": "1939-07-10",
        "nepDate": "26.03.1996"
    },
    {
        "engDate": "1939-07-11",
        "nepDate": "27.03.1996"
    },
    {
        "engDate": "1939-07-12",
        "nepDate": "28.03.1996"
    },
    {
        "engDate": "1939-07-13",
        "nepDate": "29.03.1996"
    },
    {
        "engDate": "1939-07-14",
        "nepDate": "30.03.1996"
    },
    {
        "engDate": "1939-07-15",
        "nepDate": "31.03.1996"
    },
    {
        "engDate": "1939-07-16",
        "nepDate": "01.04.1996"
    },
    {
        "engDate": "1939-07-17",
        "nepDate": "02.04.1996"
    },
    {
        "engDate": "1939-07-18",
        "nepDate": "03.04.1996"
    },
    {
        "engDate": "1939-07-19",
        "nepDate": "04.04.1996"
    },
    {
        "engDate": "1939-07-20",
        "nepDate": "05.04.1996"
    },
    {
        "engDate": "1939-07-21",
        "nepDate": "06.04.1996"
    },
    {
        "engDate": "1939-07-22",
        "nepDate": "07.04.1996"
    },
    {
        "engDate": "1939-07-23",
        "nepDate": "08.04.1996"
    },
    {
        "engDate": "1939-07-24",
        "nepDate": "09.04.1996"
    },
    {
        "engDate": "1939-07-25",
        "nepDate": "10.04.1996"
    },
    {
        "engDate": "1939-07-26",
        "nepDate": "11.04.1996"
    },
    {
        "engDate": "1939-07-27",
        "nepDate": "12.04.1996"
    },
    {
        "engDate": "1939-07-28",
        "nepDate": "13.04.1996"
    },
    {
        "engDate": "1939-07-29",
        "nepDate": "14.04.1996"
    },
    {
        "engDate": "1939-07-30",
        "nepDate": "15.04.1996"
    },
    {
        "engDate": "1939-07-31",
        "nepDate": "16.04.1996"
    },
    {
        "engDate": "1939-08-01",
        "nepDate": "17.04.1996"
    },
    {
        "engDate": "1939-08-02",
        "nepDate": "18.04.1996"
    },
    {
        "engDate": "1939-08-03",
        "nepDate": "19.04.1996"
    },
    {
        "engDate": "1939-08-04",
        "nepDate": "20.04.1996"
    },
    {
        "engDate": "1939-08-05",
        "nepDate": "21.04.1996"
    },
    {
        "engDate": "1939-08-06",
        "nepDate": "22.04.1996"
    },
    {
        "engDate": "1939-08-07",
        "nepDate": "23.04.1996"
    },
    {
        "engDate": "1939-08-08",
        "nepDate": "24.04.1996"
    },
    {
        "engDate": "1939-08-09",
        "nepDate": "25.04.1996"
    },
    {
        "engDate": "1939-08-10",
        "nepDate": "26.04.1996"
    },
    {
        "engDate": "1939-08-11",
        "nepDate": "27.04.1996"
    },
    {
        "engDate": "1939-08-12",
        "nepDate": "28.04.1996"
    },
    {
        "engDate": "1939-08-13",
        "nepDate": "29.04.1996"
    },
    {
        "engDate": "1939-08-14",
        "nepDate": "30.04.1996"
    },
    {
        "engDate": "1939-08-15",
        "nepDate": "31.04.1996"
    },
    {
        "engDate": "1939-08-16",
        "nepDate": "32.04.1996"
    },
    {
        "engDate": "1939-08-17",
        "nepDate": "01.05.1996"
    },
    {
        "engDate": "1939-08-18",
        "nepDate": "02.05.1996"
    },
    {
        "engDate": "1939-08-19",
        "nepDate": "03.05.1996"
    },
    {
        "engDate": "1939-08-20",
        "nepDate": "04.05.1996"
    },
    {
        "engDate": "1939-08-21",
        "nepDate": "05.05.1996"
    },
    {
        "engDate": "1939-08-22",
        "nepDate": "06.05.1996"
    },
    {
        "engDate": "1939-08-23",
        "nepDate": "07.05.1996"
    },
    {
        "engDate": "1939-08-24",
        "nepDate": "08.05.1996"
    },
    {
        "engDate": "1939-08-25",
        "nepDate": "09.05.1996"
    },
    {
        "engDate": "1939-08-26",
        "nepDate": "10.05.1996"
    },
    {
        "engDate": "1939-08-27",
        "nepDate": "11.05.1996"
    },
    {
        "engDate": "1939-08-28",
        "nepDate": "12.05.1996"
    },
    {
        "engDate": "1939-08-29",
        "nepDate": "13.05.1996"
    },
    {
        "engDate": "1939-08-30",
        "nepDate": "14.05.1996"
    },
    {
        "engDate": "1939-08-31",
        "nepDate": "15.05.1996"
    },
    {
        "engDate": "1939-09-01",
        "nepDate": "16.05.1996"
    },
    {
        "engDate": "1939-09-02",
        "nepDate": "17.05.1996"
    },
    {
        "engDate": "1939-09-03",
        "nepDate": "18.05.1996"
    },
    {
        "engDate": "1939-09-04",
        "nepDate": "19.05.1996"
    },
    {
        "engDate": "1939-09-05",
        "nepDate": "20.05.1996"
    },
    {
        "engDate": "1939-09-06",
        "nepDate": "21.05.1996"
    },
    {
        "engDate": "1939-09-07",
        "nepDate": "22.05.1996"
    },
    {
        "engDate": "1939-09-08",
        "nepDate": "23.05.1996"
    },
    {
        "engDate": "1939-09-09",
        "nepDate": "24.05.1996"
    },
    {
        "engDate": "1939-09-10",
        "nepDate": "25.05.1996"
    },
    {
        "engDate": "1939-09-11",
        "nepDate": "26.05.1996"
    },
    {
        "engDate": "1939-09-12",
        "nepDate": "27.05.1996"
    },
    {
        "engDate": "1939-09-13",
        "nepDate": "28.05.1996"
    },
    {
        "engDate": "1939-09-14",
        "nepDate": "29.05.1996"
    },
    {
        "engDate": "1939-09-15",
        "nepDate": "30.05.1996"
    },
    {
        "engDate": "1939-09-16",
        "nepDate": "31.05.1996"
    },
    {
        "engDate": "1939-09-17",
        "nepDate": "01.06.1996"
    },
    {
        "engDate": "1939-09-18",
        "nepDate": "02.06.1996"
    },
    {
        "engDate": "1939-09-19",
        "nepDate": "03.06.1996"
    },
    {
        "engDate": "1939-09-20",
        "nepDate": "04.06.1996"
    },
    {
        "engDate": "1939-09-21",
        "nepDate": "05.06.1996"
    },
    {
        "engDate": "1939-09-22",
        "nepDate": "06.06.1996"
    },
    {
        "engDate": "1939-09-23",
        "nepDate": "07.06.1996"
    },
    {
        "engDate": "1939-09-24",
        "nepDate": "08.06.1996"
    },
    {
        "engDate": "1939-09-25",
        "nepDate": "09.06.1996"
    },
    {
        "engDate": "1939-09-26",
        "nepDate": "10.06.1996"
    },
    {
        "engDate": "1939-09-27",
        "nepDate": "11.06.1996"
    },
    {
        "engDate": "1939-09-28",
        "nepDate": "12.06.1996"
    },
    {
        "engDate": "1939-09-29",
        "nepDate": "13.06.1996"
    },
    {
        "engDate": "1939-09-30",
        "nepDate": "14.06.1996"
    },
    {
        "engDate": "1939-10-01",
        "nepDate": "15.06.1996"
    },
    {
        "engDate": "1939-10-02",
        "nepDate": "16.06.1996"
    },
    {
        "engDate": "1939-10-03",
        "nepDate": "17.06.1996"
    },
    {
        "engDate": "1939-10-04",
        "nepDate": "18.06.1996"
    },
    {
        "engDate": "1939-10-05",
        "nepDate": "19.06.1996"
    },
    {
        "engDate": "1939-10-06",
        "nepDate": "20.06.1996"
    },
    {
        "engDate": "1939-10-07",
        "nepDate": "21.06.1996"
    },
    {
        "engDate": "1939-10-08",
        "nepDate": "22.06.1996"
    },
    {
        "engDate": "1939-10-09",
        "nepDate": "23.06.1996"
    },
    {
        "engDate": "1939-10-10",
        "nepDate": "24.06.1996"
    },
    {
        "engDate": "1939-10-11",
        "nepDate": "25.06.1996"
    },
    {
        "engDate": "1939-10-12",
        "nepDate": "26.06.1996"
    },
    {
        "engDate": "1939-10-13",
        "nepDate": "27.06.1996"
    },
    {
        "engDate": "1939-10-14",
        "nepDate": "28.06.1996"
    },
    {
        "engDate": "1939-10-15",
        "nepDate": "29.06.1996"
    },
    {
        "engDate": "1939-10-16",
        "nepDate": "30.06.1996"
    },
    {
        "engDate": "1939-10-17",
        "nepDate": "01.07.1996"
    },
    {
        "engDate": "1939-10-18",
        "nepDate": "02.07.1996"
    },
    {
        "engDate": "1939-10-19",
        "nepDate": "03.07.1996"
    },
    {
        "engDate": "1939-10-20",
        "nepDate": "04.07.1996"
    },
    {
        "engDate": "1939-10-21",
        "nepDate": "05.07.1996"
    },
    {
        "engDate": "1939-10-22",
        "nepDate": "06.07.1996"
    },
    {
        "engDate": "1939-10-23",
        "nepDate": "07.07.1996"
    },
    {
        "engDate": "1939-10-24",
        "nepDate": "08.07.1996"
    },
    {
        "engDate": "1939-10-25",
        "nepDate": "09.07.1996"
    },
    {
        "engDate": "1939-10-26",
        "nepDate": "10.07.1996"
    },
    {
        "engDate": "1939-10-27",
        "nepDate": "11.07.1996"
    },
    {
        "engDate": "1939-10-28",
        "nepDate": "12.07.1996"
    },
    {
        "engDate": "1939-10-29",
        "nepDate": "13.07.1996"
    },
    {
        "engDate": "1939-10-30",
        "nepDate": "14.07.1996"
    },
    {
        "engDate": "1939-10-31",
        "nepDate": "15.07.1996"
    },
    {
        "engDate": "1939-11-01",
        "nepDate": "16.07.1996"
    },
    {
        "engDate": "1939-11-02",
        "nepDate": "17.07.1996"
    },
    {
        "engDate": "1939-11-03",
        "nepDate": "18.07.1996"
    },
    {
        "engDate": "1939-11-04",
        "nepDate": "19.07.1996"
    },
    {
        "engDate": "1939-11-05",
        "nepDate": "20.07.1996"
    },
    {
        "engDate": "1939-11-06",
        "nepDate": "21.07.1996"
    },
    {
        "engDate": "1939-11-07",
        "nepDate": "22.07.1996"
    },
    {
        "engDate": "1939-11-08",
        "nepDate": "23.07.1996"
    },
    {
        "engDate": "1939-11-09",
        "nepDate": "24.07.1996"
    },
    {
        "engDate": "1939-11-10",
        "nepDate": "25.07.1996"
    },
    {
        "engDate": "1939-11-11",
        "nepDate": "26.07.1996"
    },
    {
        "engDate": "1939-11-12",
        "nepDate": "27.07.1996"
    },
    {
        "engDate": "1939-11-13",
        "nepDate": "28.07.1996"
    },
    {
        "engDate": "1939-11-14",
        "nepDate": "29.07.1996"
    },
    {
        "engDate": "1939-11-15",
        "nepDate": "30.07.1996"
    },
    {
        "engDate": "1939-11-16",
        "nepDate": "01.08.1996"
    },
    {
        "engDate": "1939-11-17",
        "nepDate": "02.08.1996"
    },
    {
        "engDate": "1939-11-18",
        "nepDate": "03.08.1996"
    },
    {
        "engDate": "1939-11-19",
        "nepDate": "04.08.1996"
    },
    {
        "engDate": "1939-11-20",
        "nepDate": "05.08.1996"
    },
    {
        "engDate": "1939-11-21",
        "nepDate": "06.08.1996"
    },
    {
        "engDate": "1939-11-22",
        "nepDate": "07.08.1996"
    },
    {
        "engDate": "1939-11-23",
        "nepDate": "08.08.1996"
    },
    {
        "engDate": "1939-11-24",
        "nepDate": "09.08.1996"
    },
    {
        "engDate": "1939-11-25",
        "nepDate": "10.08.1996"
    },
    {
        "engDate": "1939-11-26",
        "nepDate": "11.08.1996"
    },
    {
        "engDate": "1939-11-27",
        "nepDate": "12.08.1996"
    },
    {
        "engDate": "1939-11-28",
        "nepDate": "13.08.1996"
    },
    {
        "engDate": "1939-11-29",
        "nepDate": "14.08.1996"
    },
    {
        "engDate": "1939-11-30",
        "nepDate": "15.08.1996"
    },
    {
        "engDate": "1939-12-01",
        "nepDate": "16.08.1996"
    },
    {
        "engDate": "1939-12-02",
        "nepDate": "17.08.1996"
    },
    {
        "engDate": "1939-12-03",
        "nepDate": "18.08.1996"
    },
    {
        "engDate": "1939-12-04",
        "nepDate": "19.08.1996"
    },
    {
        "engDate": "1939-12-05",
        "nepDate": "20.08.1996"
    },
    {
        "engDate": "1939-12-06",
        "nepDate": "21.08.1996"
    },
    {
        "engDate": "1939-12-07",
        "nepDate": "22.08.1996"
    },
    {
        "engDate": "1939-12-08",
        "nepDate": "23.08.1996"
    },
    {
        "engDate": "1939-12-09",
        "nepDate": "24.08.1996"
    },
    {
        "engDate": "1939-12-10",
        "nepDate": "25.08.1996"
    },
    {
        "engDate": "1939-12-11",
        "nepDate": "26.08.1996"
    },
    {
        "engDate": "1939-12-12",
        "nepDate": "27.08.1996"
    },
    {
        "engDate": "1939-12-13",
        "nepDate": "28.08.1996"
    },
    {
        "engDate": "1939-12-14",
        "nepDate": "29.08.1996"
    },
    {
        "engDate": "1939-12-15",
        "nepDate": "30.08.1996"
    },
    {
        "engDate": "1939-12-16",
        "nepDate": "01.09.1996"
    },
    {
        "engDate": "1939-12-17",
        "nepDate": "02.09.1996"
    },
    {
        "engDate": "1939-12-18",
        "nepDate": "03.09.1996"
    },
    {
        "engDate": "1939-12-19",
        "nepDate": "04.09.1996"
    },
    {
        "engDate": "1939-12-20",
        "nepDate": "05.09.1996"
    },
    {
        "engDate": "1939-12-21",
        "nepDate": "06.09.1996"
    },
    {
        "engDate": "1939-12-22",
        "nepDate": "07.09.1996"
    },
    {
        "engDate": "1939-12-23",
        "nepDate": "08.09.1996"
    },
    {
        "engDate": "1939-12-24",
        "nepDate": "09.09.1996"
    },
    {
        "engDate": "1939-12-25",
        "nepDate": "10.09.1996"
    },
    {
        "engDate": "1939-12-26",
        "nepDate": "11.09.1996"
    },
    {
        "engDate": "1939-12-27",
        "nepDate": "12.09.1996"
    },
    {
        "engDate": "1939-12-28",
        "nepDate": "13.09.1996"
    },
    {
        "engDate": "1939-12-29",
        "nepDate": "14.09.1996"
    },
    {
        "engDate": "1939-12-30",
        "nepDate": "15.09.1996"
    },
    {
        "engDate": "1939-12-31",
        "nepDate": "16.09.1996"
    },
    {
        "engDate": "1940-01-01",
        "nepDate": "17.09.1996"
    },
    {
        "engDate": "1940-01-02",
        "nepDate": "18.09.1996"
    },
    {
        "engDate": "1940-01-03",
        "nepDate": "19.09.1996"
    },
    {
        "engDate": "1940-01-04",
        "nepDate": "20.09.1996"
    },
    {
        "engDate": "1940-01-05",
        "nepDate": "21.09.1996"
    },
    {
        "engDate": "1940-01-06",
        "nepDate": "22.09.1996"
    },
    {
        "engDate": "1940-01-07",
        "nepDate": "23.09.1996"
    },
    {
        "engDate": "1940-01-08",
        "nepDate": "24.09.1996"
    },
    {
        "engDate": "1940-01-09",
        "nepDate": "25.09.1996"
    },
    {
        "engDate": "1940-01-10",
        "nepDate": "26.09.1996"
    },
    {
        "engDate": "1940-01-11",
        "nepDate": "27.09.1996"
    },
    {
        "engDate": "1940-01-12",
        "nepDate": "28.09.1996"
    },
    {
        "engDate": "1940-01-13",
        "nepDate": "29.09.1996"
    },
    {
        "engDate": "1940-01-14",
        "nepDate": "01.10.1996"
    },
    {
        "engDate": "1940-01-15",
        "nepDate": "02.10.1996"
    },
    {
        "engDate": "1940-01-16",
        "nepDate": "03.10.1996"
    },
    {
        "engDate": "1940-01-17",
        "nepDate": "04.10.1996"
    },
    {
        "engDate": "1940-01-18",
        "nepDate": "05.10.1996"
    },
    {
        "engDate": "1940-01-19",
        "nepDate": "06.10.1996"
    },
    {
        "engDate": "1940-01-20",
        "nepDate": "07.10.1996"
    },
    {
        "engDate": "1940-01-21",
        "nepDate": "08.10.1996"
    },
    {
        "engDate": "1940-01-22",
        "nepDate": "09.10.1996"
    },
    {
        "engDate": "1940-01-23",
        "nepDate": "10.10.1996"
    },
    {
        "engDate": "1940-01-24",
        "nepDate": "11.10.1996"
    },
    {
        "engDate": "1940-01-25",
        "nepDate": "12.10.1996"
    },
    {
        "engDate": "1940-01-26",
        "nepDate": "13.10.1996"
    },
    {
        "engDate": "1940-01-27",
        "nepDate": "14.10.1996"
    },
    {
        "engDate": "1940-01-28",
        "nepDate": "15.10.1996"
    },
    {
        "engDate": "1940-01-29",
        "nepDate": "16.10.1996"
    },
    {
        "engDate": "1940-01-30",
        "nepDate": "17.10.1996"
    },
    {
        "engDate": "1940-01-31",
        "nepDate": "18.10.1996"
    },
    {
        "engDate": "1940-02-01",
        "nepDate": "19.10.1996"
    },
    {
        "engDate": "1940-02-02",
        "nepDate": "20.10.1996"
    },
    {
        "engDate": "1940-02-03",
        "nepDate": "21.10.1996"
    },
    {
        "engDate": "1940-02-04",
        "nepDate": "22.10.1996"
    },
    {
        "engDate": "1940-02-05",
        "nepDate": "23.10.1996"
    },
    {
        "engDate": "1940-02-06",
        "nepDate": "24.10.1996"
    },
    {
        "engDate": "1940-02-07",
        "nepDate": "25.10.1996"
    },
    {
        "engDate": "1940-02-08",
        "nepDate": "26.10.1996"
    },
    {
        "engDate": "1940-02-09",
        "nepDate": "27.10.1996"
    },
    {
        "engDate": "1940-02-10",
        "nepDate": "28.10.1996"
    },
    {
        "engDate": "1940-02-11",
        "nepDate": "29.10.1996"
    },
    {
        "engDate": "1940-02-12",
        "nepDate": "30.10.1996"
    },
    {
        "engDate": "1940-02-13",
        "nepDate": "01.11.1996"
    },
    {
        "engDate": "1940-02-14",
        "nepDate": "02.11.1996"
    },
    {
        "engDate": "1940-02-15",
        "nepDate": "03.11.1996"
    },
    {
        "engDate": "1940-02-16",
        "nepDate": "04.11.1996"
    },
    {
        "engDate": "1940-02-17",
        "nepDate": "05.11.1996"
    },
    {
        "engDate": "1940-02-18",
        "nepDate": "06.11.1996"
    },
    {
        "engDate": "1940-02-19",
        "nepDate": "07.11.1996"
    },
    {
        "engDate": "1940-02-20",
        "nepDate": "08.11.1996"
    },
    {
        "engDate": "1940-02-21",
        "nepDate": "09.11.1996"
    },
    {
        "engDate": "1940-02-22",
        "nepDate": "10.11.1996"
    },
    {
        "engDate": "1940-02-23",
        "nepDate": "11.11.1996"
    },
    {
        "engDate": "1940-02-24",
        "nepDate": "12.11.1996"
    },
    {
        "engDate": "1940-02-25",
        "nepDate": "13.11.1996"
    },
    {
        "engDate": "1940-02-26",
        "nepDate": "14.11.1996"
    },
    {
        "engDate": "1940-02-27",
        "nepDate": "15.11.1996"
    },
    {
        "engDate": "1940-02-28",
        "nepDate": "16.11.1996"
    },
    {
        "engDate": "1940-02-29",
        "nepDate": "17.11.1996"
    },
    {
        "engDate": "1940-03-01",
        "nepDate": "18.11.1996"
    },
    {
        "engDate": "1940-03-02",
        "nepDate": "19.11.1996"
    },
    {
        "engDate": "1940-03-03",
        "nepDate": "20.11.1996"
    },
    {
        "engDate": "1940-03-04",
        "nepDate": "21.11.1996"
    },
    {
        "engDate": "1940-03-05",
        "nepDate": "22.11.1996"
    },
    {
        "engDate": "1940-03-06",
        "nepDate": "23.11.1996"
    },
    {
        "engDate": "1940-03-07",
        "nepDate": "24.11.1996"
    },
    {
        "engDate": "1940-03-08",
        "nepDate": "25.11.1996"
    },
    {
        "engDate": "1940-03-09",
        "nepDate": "26.11.1996"
    },
    {
        "engDate": "1940-03-10",
        "nepDate": "27.11.1996"
    },
    {
        "engDate": "1940-03-11",
        "nepDate": "28.11.1996"
    },
    {
        "engDate": "1940-03-12",
        "nepDate": "29.11.1996"
    },
    {
        "engDate": "1940-03-13",
        "nepDate": "01.12.1996"
    },
    {
        "engDate": "1940-03-14",
        "nepDate": "02.12.1996"
    },
    {
        "engDate": "1940-03-15",
        "nepDate": "03.12.1996"
    },
    {
        "engDate": "1940-03-16",
        "nepDate": "04.12.1996"
    },
    {
        "engDate": "1940-03-17",
        "nepDate": "05.12.1996"
    },
    {
        "engDate": "1940-03-18",
        "nepDate": "06.12.1996"
    },
    {
        "engDate": "1940-03-19",
        "nepDate": "07.12.1996"
    },
    {
        "engDate": "1940-03-20",
        "nepDate": "08.12.1996"
    },
    {
        "engDate": "1940-03-21",
        "nepDate": "09.12.1996"
    },
    {
        "engDate": "1940-03-22",
        "nepDate": "10.12.1996"
    },
    {
        "engDate": "1940-03-23",
        "nepDate": "11.12.1996"
    },
    {
        "engDate": "1940-03-24",
        "nepDate": "12.12.1996"
    },
    {
        "engDate": "1940-03-25",
        "nepDate": "13.12.1996"
    },
    {
        "engDate": "1940-03-26",
        "nepDate": "14.12.1996"
    },
    {
        "engDate": "1940-03-27",
        "nepDate": "15.12.1996"
    },
    {
        "engDate": "1940-03-28",
        "nepDate": "16.12.1996"
    },
    {
        "engDate": "1940-03-29",
        "nepDate": "17.12.1996"
    },
    {
        "engDate": "1940-03-30",
        "nepDate": "18.12.1996"
    },
    {
        "engDate": "1940-03-31",
        "nepDate": "19.12.1996"
    },
    {
        "engDate": "1940-04-01",
        "nepDate": "20.12.1996"
    },
    {
        "engDate": "1940-04-02",
        "nepDate": "21.12.1996"
    },
    {
        "engDate": "1940-04-03",
        "nepDate": "22.12.1996"
    },
    {
        "engDate": "1940-04-04",
        "nepDate": "23.12.1996"
    },
    {
        "engDate": "1940-04-05",
        "nepDate": "24.12.1996"
    },
    {
        "engDate": "1940-04-06",
        "nepDate": "25.12.1996"
    },
    {
        "engDate": "1940-04-07",
        "nepDate": "26.12.1996"
    },
    {
        "engDate": "1940-04-08",
        "nepDate": "27.12.1996"
    },
    {
        "engDate": "1940-04-09",
        "nepDate": "28.12.1996"
    },
    {
        "engDate": "1940-04-10",
        "nepDate": "29.12.1996"
    },
    {
        "engDate": "1940-04-11",
        "nepDate": "30.12.1996"
    },
    {
        "engDate": "1940-04-12",
        "nepDate": "31.12.1996"
    },
    {
        "engDate": "1940-04-13",
        "nepDate": "01.01.1997"
    },
    {
        "engDate": "1940-04-14",
        "nepDate": "02.01.1997"
    },
    {
        "engDate": "1940-04-15",
        "nepDate": "03.01.1997"
    },
    {
        "engDate": "1940-04-16",
        "nepDate": "04.01.1997"
    },
    {
        "engDate": "1940-04-17",
        "nepDate": "05.01.1997"
    },
    {
        "engDate": "1940-04-18",
        "nepDate": "06.01.1997"
    },
    {
        "engDate": "1940-04-19",
        "nepDate": "07.01.1997"
    },
    {
        "engDate": "1940-04-20",
        "nepDate": "08.01.1997"
    },
    {
        "engDate": "1940-04-21",
        "nepDate": "09.01.1997"
    },
    {
        "engDate": "1940-04-22",
        "nepDate": "10.01.1997"
    },
    {
        "engDate": "1940-04-23",
        "nepDate": "11.01.1997"
    },
    {
        "engDate": "1940-04-24",
        "nepDate": "12.01.1997"
    },
    {
        "engDate": "1940-04-25",
        "nepDate": "13.01.1997"
    },
    {
        "engDate": "1940-04-26",
        "nepDate": "14.01.1997"
    },
    {
        "engDate": "1940-04-27",
        "nepDate": "15.01.1997"
    },
    {
        "engDate": "1940-04-28",
        "nepDate": "16.01.1997"
    },
    {
        "engDate": "1940-04-29",
        "nepDate": "17.01.1997"
    },
    {
        "engDate": "1940-04-30",
        "nepDate": "18.01.1997"
    },
    {
        "engDate": "1940-05-01",
        "nepDate": "19.01.1997"
    },
    {
        "engDate": "1940-05-02",
        "nepDate": "20.01.1997"
    },
    {
        "engDate": "1940-05-03",
        "nepDate": "21.01.1997"
    },
    {
        "engDate": "1940-05-04",
        "nepDate": "22.01.1997"
    },
    {
        "engDate": "1940-05-05",
        "nepDate": "23.01.1997"
    },
    {
        "engDate": "1940-05-06",
        "nepDate": "24.01.1997"
    },
    {
        "engDate": "1940-05-07",
        "nepDate": "25.01.1997"
    },
    {
        "engDate": "1940-05-08",
        "nepDate": "26.01.1997"
    },
    {
        "engDate": "1940-05-09",
        "nepDate": "27.01.1997"
    },
    {
        "engDate": "1940-05-10",
        "nepDate": "28.01.1997"
    },
    {
        "engDate": "1940-05-11",
        "nepDate": "29.01.1997"
    },
    {
        "engDate": "1940-05-12",
        "nepDate": "30.01.1997"
    },
    {
        "engDate": "1940-05-13",
        "nepDate": "31.01.1997"
    },
    {
        "engDate": "1940-05-14",
        "nepDate": "01.02.1997"
    },
    {
        "engDate": "1940-05-15",
        "nepDate": "02.02.1997"
    },
    {
        "engDate": "1940-05-16",
        "nepDate": "03.02.1997"
    },
    {
        "engDate": "1940-05-17",
        "nepDate": "04.02.1997"
    },
    {
        "engDate": "1940-05-18",
        "nepDate": "05.02.1997"
    },
    {
        "engDate": "1940-05-19",
        "nepDate": "06.02.1997"
    },
    {
        "engDate": "1940-05-20",
        "nepDate": "07.02.1997"
    },
    {
        "engDate": "1940-05-21",
        "nepDate": "08.02.1997"
    },
    {
        "engDate": "1940-05-22",
        "nepDate": "09.02.1997"
    },
    {
        "engDate": "1940-05-23",
        "nepDate": "10.02.1997"
    },
    {
        "engDate": "1940-05-24",
        "nepDate": "11.02.1997"
    },
    {
        "engDate": "1940-05-25",
        "nepDate": "12.02.1997"
    },
    {
        "engDate": "1940-05-26",
        "nepDate": "13.02.1997"
    },
    {
        "engDate": "1940-05-27",
        "nepDate": "14.02.1997"
    },
    {
        "engDate": "1940-05-28",
        "nepDate": "15.02.1997"
    },
    {
        "engDate": "1940-05-29",
        "nepDate": "16.02.1997"
    },
    {
        "engDate": "1940-05-30",
        "nepDate": "17.02.1997"
    },
    {
        "engDate": "1940-05-31",
        "nepDate": "18.02.1997"
    },
    {
        "engDate": "1940-06-01",
        "nepDate": "19.02.1997"
    },
    {
        "engDate": "1940-06-02",
        "nepDate": "20.02.1997"
    },
    {
        "engDate": "1940-06-03",
        "nepDate": "21.02.1997"
    },
    {
        "engDate": "1940-06-04",
        "nepDate": "22.02.1997"
    },
    {
        "engDate": "1940-06-05",
        "nepDate": "23.02.1997"
    },
    {
        "engDate": "1940-06-06",
        "nepDate": "24.02.1997"
    },
    {
        "engDate": "1940-06-07",
        "nepDate": "25.02.1997"
    },
    {
        "engDate": "1940-06-08",
        "nepDate": "26.02.1997"
    },
    {
        "engDate": "1940-06-09",
        "nepDate": "27.02.1997"
    },
    {
        "engDate": "1940-06-10",
        "nepDate": "28.02.1997"
    },
    {
        "engDate": "1940-06-11",
        "nepDate": "29.02.1997"
    },
    {
        "engDate": "1940-06-12",
        "nepDate": "30.02.1997"
    },
    {
        "engDate": "1940-06-13",
        "nepDate": "31.02.1997"
    },
    {
        "engDate": "1940-06-14",
        "nepDate": "01.03.1997"
    },
    {
        "engDate": "1940-06-15",
        "nepDate": "02.03.1997"
    },
    {
        "engDate": "1940-06-16",
        "nepDate": "03.03.1997"
    },
    {
        "engDate": "1940-06-17",
        "nepDate": "04.03.1997"
    },
    {
        "engDate": "1940-06-18",
        "nepDate": "05.03.1997"
    },
    {
        "engDate": "1940-06-19",
        "nepDate": "06.03.1997"
    },
    {
        "engDate": "1940-06-20",
        "nepDate": "07.03.1997"
    },
    {
        "engDate": "1940-06-21",
        "nepDate": "08.03.1997"
    },
    {
        "engDate": "1940-06-22",
        "nepDate": "09.03.1997"
    },
    {
        "engDate": "1940-06-23",
        "nepDate": "10.03.1997"
    },
    {
        "engDate": "1940-06-24",
        "nepDate": "11.03.1997"
    },
    {
        "engDate": "1940-06-25",
        "nepDate": "12.03.1997"
    },
    {
        "engDate": "1940-06-26",
        "nepDate": "13.03.1997"
    },
    {
        "engDate": "1940-06-27",
        "nepDate": "14.03.1997"
    },
    {
        "engDate": "1940-06-28",
        "nepDate": "15.03.1997"
    },
    {
        "engDate": "1940-06-29",
        "nepDate": "16.03.1997"
    },
    {
        "engDate": "1940-06-30",
        "nepDate": "17.03.1997"
    },
    {
        "engDate": "1940-07-01",
        "nepDate": "18.03.1997"
    },
    {
        "engDate": "1940-07-02",
        "nepDate": "19.03.1997"
    },
    {
        "engDate": "1940-07-03",
        "nepDate": "20.03.1997"
    },
    {
        "engDate": "1940-07-04",
        "nepDate": "21.03.1997"
    },
    {
        "engDate": "1940-07-05",
        "nepDate": "22.03.1997"
    },
    {
        "engDate": "1940-07-06",
        "nepDate": "23.03.1997"
    },
    {
        "engDate": "1940-07-07",
        "nepDate": "24.03.1997"
    },
    {
        "engDate": "1940-07-08",
        "nepDate": "25.03.1997"
    },
    {
        "engDate": "1940-07-09",
        "nepDate": "26.03.1997"
    },
    {
        "engDate": "1940-07-10",
        "nepDate": "27.03.1997"
    },
    {
        "engDate": "1940-07-11",
        "nepDate": "28.03.1997"
    },
    {
        "engDate": "1940-07-12",
        "nepDate": "29.03.1997"
    },
    {
        "engDate": "1940-07-13",
        "nepDate": "30.03.1997"
    },
    {
        "engDate": "1940-07-14",
        "nepDate": "31.03.1997"
    },
    {
        "engDate": "1940-07-15",
        "nepDate": "32.03.1997"
    },
    {
        "engDate": "1940-07-16",
        "nepDate": "01.04.1997"
    },
    {
        "engDate": "1940-07-17",
        "nepDate": "02.04.1997"
    },
    {
        "engDate": "1940-07-18",
        "nepDate": "03.04.1997"
    },
    {
        "engDate": "1940-07-19",
        "nepDate": "04.04.1997"
    },
    {
        "engDate": "1940-07-20",
        "nepDate": "05.04.1997"
    },
    {
        "engDate": "1940-07-21",
        "nepDate": "06.04.1997"
    },
    {
        "engDate": "1940-07-22",
        "nepDate": "07.04.1997"
    },
    {
        "engDate": "1940-07-23",
        "nepDate": "08.04.1997"
    },
    {
        "engDate": "1940-07-24",
        "nepDate": "09.04.1997"
    },
    {
        "engDate": "1940-07-25",
        "nepDate": "10.04.1997"
    },
    {
        "engDate": "1940-07-26",
        "nepDate": "11.04.1997"
    },
    {
        "engDate": "1940-07-27",
        "nepDate": "12.04.1997"
    },
    {
        "engDate": "1940-07-28",
        "nepDate": "13.04.1997"
    },
    {
        "engDate": "1940-07-29",
        "nepDate": "14.04.1997"
    },
    {
        "engDate": "1940-07-30",
        "nepDate": "15.04.1997"
    },
    {
        "engDate": "1940-07-31",
        "nepDate": "16.04.1997"
    },
    {
        "engDate": "1940-08-01",
        "nepDate": "17.04.1997"
    },
    {
        "engDate": "1940-08-02",
        "nepDate": "18.04.1997"
    },
    {
        "engDate": "1940-08-03",
        "nepDate": "19.04.1997"
    },
    {
        "engDate": "1940-08-04",
        "nepDate": "20.04.1997"
    },
    {
        "engDate": "1940-08-05",
        "nepDate": "21.04.1997"
    },
    {
        "engDate": "1940-08-06",
        "nepDate": "22.04.1997"
    },
    {
        "engDate": "1940-08-07",
        "nepDate": "23.04.1997"
    },
    {
        "engDate": "1940-08-08",
        "nepDate": "24.04.1997"
    },
    {
        "engDate": "1940-08-09",
        "nepDate": "25.04.1997"
    },
    {
        "engDate": "1940-08-10",
        "nepDate": "26.04.1997"
    },
    {
        "engDate": "1940-08-11",
        "nepDate": "27.04.1997"
    },
    {
        "engDate": "1940-08-12",
        "nepDate": "28.04.1997"
    },
    {
        "engDate": "1940-08-13",
        "nepDate": "29.04.1997"
    },
    {
        "engDate": "1940-08-14",
        "nepDate": "30.04.1997"
    },
    {
        "engDate": "1940-08-15",
        "nepDate": "31.04.1997"
    },
    {
        "engDate": "1940-08-16",
        "nepDate": "01.05.1997"
    },
    {
        "engDate": "1940-08-17",
        "nepDate": "02.05.1997"
    },
    {
        "engDate": "1940-08-18",
        "nepDate": "03.05.1997"
    },
    {
        "engDate": "1940-08-19",
        "nepDate": "04.05.1997"
    },
    {
        "engDate": "1940-08-20",
        "nepDate": "05.05.1997"
    },
    {
        "engDate": "1940-08-21",
        "nepDate": "06.05.1997"
    },
    {
        "engDate": "1940-08-22",
        "nepDate": "07.05.1997"
    },
    {
        "engDate": "1940-08-23",
        "nepDate": "08.05.1997"
    },
    {
        "engDate": "1940-08-24",
        "nepDate": "09.05.1997"
    },
    {
        "engDate": "1940-08-25",
        "nepDate": "10.05.1997"
    },
    {
        "engDate": "1940-08-26",
        "nepDate": "11.05.1997"
    },
    {
        "engDate": "1940-08-27",
        "nepDate": "12.05.1997"
    },
    {
        "engDate": "1940-08-28",
        "nepDate": "13.05.1997"
    },
    {
        "engDate": "1940-08-29",
        "nepDate": "14.05.1997"
    },
    {
        "engDate": "1940-08-30",
        "nepDate": "15.05.1997"
    },
    {
        "engDate": "1940-08-31",
        "nepDate": "16.05.1997"
    },
    {
        "engDate": "1940-09-01",
        "nepDate": "17.05.1997"
    },
    {
        "engDate": "1940-09-02",
        "nepDate": "18.05.1997"
    },
    {
        "engDate": "1940-09-03",
        "nepDate": "19.05.1997"
    },
    {
        "engDate": "1940-09-04",
        "nepDate": "20.05.1997"
    },
    {
        "engDate": "1940-09-05",
        "nepDate": "21.05.1997"
    },
    {
        "engDate": "1940-09-06",
        "nepDate": "22.05.1997"
    },
    {
        "engDate": "1940-09-07",
        "nepDate": "23.05.1997"
    },
    {
        "engDate": "1940-09-08",
        "nepDate": "24.05.1997"
    },
    {
        "engDate": "1940-09-09",
        "nepDate": "25.05.1997"
    },
    {
        "engDate": "1940-09-10",
        "nepDate": "26.05.1997"
    },
    {
        "engDate": "1940-09-11",
        "nepDate": "27.05.1997"
    },
    {
        "engDate": "1940-09-12",
        "nepDate": "28.05.1997"
    },
    {
        "engDate": "1940-09-13",
        "nepDate": "29.05.1997"
    },
    {
        "engDate": "1940-09-14",
        "nepDate": "30.05.1997"
    },
    {
        "engDate": "1940-09-15",
        "nepDate": "31.05.1997"
    },
    {
        "engDate": "1940-09-16",
        "nepDate": "01.06.1997"
    },
    {
        "engDate": "1940-09-17",
        "nepDate": "02.06.1997"
    },
    {
        "engDate": "1940-09-18",
        "nepDate": "03.06.1997"
    },
    {
        "engDate": "1940-09-19",
        "nepDate": "04.06.1997"
    },
    {
        "engDate": "1940-09-20",
        "nepDate": "05.06.1997"
    },
    {
        "engDate": "1940-09-21",
        "nepDate": "06.06.1997"
    },
    {
        "engDate": "1940-09-22",
        "nepDate": "07.06.1997"
    },
    {
        "engDate": "1940-09-23",
        "nepDate": "08.06.1997"
    },
    {
        "engDate": "1940-09-24",
        "nepDate": "09.06.1997"
    },
    {
        "engDate": "1940-09-25",
        "nepDate": "10.06.1997"
    },
    {
        "engDate": "1940-09-26",
        "nepDate": "11.06.1997"
    },
    {
        "engDate": "1940-09-27",
        "nepDate": "12.06.1997"
    },
    {
        "engDate": "1940-09-28",
        "nepDate": "13.06.1997"
    },
    {
        "engDate": "1940-09-29",
        "nepDate": "14.06.1997"
    },
    {
        "engDate": "1940-09-30",
        "nepDate": "15.06.1997"
    },
    {
        "engDate": "1940-10-01",
        "nepDate": "16.06.1997"
    },
    {
        "engDate": "1940-10-02",
        "nepDate": "17.06.1997"
    },
    {
        "engDate": "1940-10-03",
        "nepDate": "18.06.1997"
    },
    {
        "engDate": "1940-10-04",
        "nepDate": "19.06.1997"
    },
    {
        "engDate": "1940-10-05",
        "nepDate": "20.06.1997"
    },
    {
        "engDate": "1940-10-06",
        "nepDate": "21.06.1997"
    },
    {
        "engDate": "1940-10-07",
        "nepDate": "22.06.1997"
    },
    {
        "engDate": "1940-10-08",
        "nepDate": "23.06.1997"
    },
    {
        "engDate": "1940-10-09",
        "nepDate": "24.06.1997"
    },
    {
        "engDate": "1940-10-10",
        "nepDate": "25.06.1997"
    },
    {
        "engDate": "1940-10-11",
        "nepDate": "26.06.1997"
    },
    {
        "engDate": "1940-10-12",
        "nepDate": "27.06.1997"
    },
    {
        "engDate": "1940-10-13",
        "nepDate": "28.06.1997"
    },
    {
        "engDate": "1940-10-14",
        "nepDate": "29.06.1997"
    },
    {
        "engDate": "1940-10-15",
        "nepDate": "30.06.1997"
    },
    {
        "engDate": "1940-10-16",
        "nepDate": "31.06.1997"
    },
    {
        "engDate": "1940-10-17",
        "nepDate": "01.07.1997"
    },
    {
        "engDate": "1940-10-18",
        "nepDate": "02.07.1997"
    },
    {
        "engDate": "1940-10-19",
        "nepDate": "03.07.1997"
    },
    {
        "engDate": "1940-10-20",
        "nepDate": "04.07.1997"
    },
    {
        "engDate": "1940-10-21",
        "nepDate": "05.07.1997"
    },
    {
        "engDate": "1940-10-22",
        "nepDate": "06.07.1997"
    },
    {
        "engDate": "1940-10-23",
        "nepDate": "07.07.1997"
    },
    {
        "engDate": "1940-10-24",
        "nepDate": "08.07.1997"
    },
    {
        "engDate": "1940-10-25",
        "nepDate": "09.07.1997"
    },
    {
        "engDate": "1940-10-26",
        "nepDate": "10.07.1997"
    },
    {
        "engDate": "1940-10-27",
        "nepDate": "11.07.1997"
    },
    {
        "engDate": "1940-10-28",
        "nepDate": "12.07.1997"
    },
    {
        "engDate": "1940-10-29",
        "nepDate": "13.07.1997"
    },
    {
        "engDate": "1940-10-30",
        "nepDate": "14.07.1997"
    },
    {
        "engDate": "1940-10-31",
        "nepDate": "15.07.1997"
    },
    {
        "engDate": "1940-11-01",
        "nepDate": "16.07.1997"
    },
    {
        "engDate": "1940-11-02",
        "nepDate": "17.07.1997"
    },
    {
        "engDate": "1940-11-03",
        "nepDate": "18.07.1997"
    },
    {
        "engDate": "1940-11-04",
        "nepDate": "19.07.1997"
    },
    {
        "engDate": "1940-11-05",
        "nepDate": "20.07.1997"
    },
    {
        "engDate": "1940-11-06",
        "nepDate": "21.07.1997"
    },
    {
        "engDate": "1940-11-07",
        "nepDate": "22.07.1997"
    },
    {
        "engDate": "1940-11-08",
        "nepDate": "23.07.1997"
    },
    {
        "engDate": "1940-11-09",
        "nepDate": "24.07.1997"
    },
    {
        "engDate": "1940-11-10",
        "nepDate": "25.07.1997"
    },
    {
        "engDate": "1940-11-11",
        "nepDate": "26.07.1997"
    },
    {
        "engDate": "1940-11-12",
        "nepDate": "27.07.1997"
    },
    {
        "engDate": "1940-11-13",
        "nepDate": "28.07.1997"
    },
    {
        "engDate": "1940-11-14",
        "nepDate": "29.07.1997"
    },
    {
        "engDate": "1940-11-15",
        "nepDate": "30.07.1997"
    },
    {
        "engDate": "1940-11-16",
        "nepDate": "01.08.1997"
    },
    {
        "engDate": "1940-11-17",
        "nepDate": "02.08.1997"
    },
    {
        "engDate": "1940-11-18",
        "nepDate": "03.08.1997"
    },
    {
        "engDate": "1940-11-19",
        "nepDate": "04.08.1997"
    },
    {
        "engDate": "1940-11-20",
        "nepDate": "05.08.1997"
    },
    {
        "engDate": "1940-11-21",
        "nepDate": "06.08.1997"
    },
    {
        "engDate": "1940-11-22",
        "nepDate": "07.08.1997"
    },
    {
        "engDate": "1940-11-23",
        "nepDate": "08.08.1997"
    },
    {
        "engDate": "1940-11-24",
        "nepDate": "09.08.1997"
    },
    {
        "engDate": "1940-11-25",
        "nepDate": "10.08.1997"
    },
    {
        "engDate": "1940-11-26",
        "nepDate": "11.08.1997"
    },
    {
        "engDate": "1940-11-27",
        "nepDate": "12.08.1997"
    },
    {
        "engDate": "1940-11-28",
        "nepDate": "13.08.1997"
    },
    {
        "engDate": "1940-11-29",
        "nepDate": "14.08.1997"
    },
    {
        "engDate": "1940-11-30",
        "nepDate": "15.08.1997"
    },
    {
        "engDate": "1940-12-01",
        "nepDate": "16.08.1997"
    },
    {
        "engDate": "1940-12-02",
        "nepDate": "17.08.1997"
    },
    {
        "engDate": "1940-12-03",
        "nepDate": "18.08.1997"
    },
    {
        "engDate": "1940-12-04",
        "nepDate": "19.08.1997"
    },
    {
        "engDate": "1940-12-05",
        "nepDate": "20.08.1997"
    },
    {
        "engDate": "1940-12-06",
        "nepDate": "21.08.1997"
    },
    {
        "engDate": "1940-12-07",
        "nepDate": "22.08.1997"
    },
    {
        "engDate": "1940-12-08",
        "nepDate": "23.08.1997"
    },
    {
        "engDate": "1940-12-09",
        "nepDate": "24.08.1997"
    },
    {
        "engDate": "1940-12-10",
        "nepDate": "25.08.1997"
    },
    {
        "engDate": "1940-12-11",
        "nepDate": "26.08.1997"
    },
    {
        "engDate": "1940-12-12",
        "nepDate": "27.08.1997"
    },
    {
        "engDate": "1940-12-13",
        "nepDate": "28.08.1997"
    },
    {
        "engDate": "1940-12-14",
        "nepDate": "29.08.1997"
    },
    {
        "engDate": "1940-12-15",
        "nepDate": "01.09.1997"
    },
    {
        "engDate": "1940-12-16",
        "nepDate": "02.09.1997"
    },
    {
        "engDate": "1940-12-17",
        "nepDate": "03.09.1997"
    },
    {
        "engDate": "1940-12-18",
        "nepDate": "04.09.1997"
    },
    {
        "engDate": "1940-12-19",
        "nepDate": "05.09.1997"
    },
    {
        "engDate": "1940-12-20",
        "nepDate": "06.09.1997"
    },
    {
        "engDate": "1940-12-21",
        "nepDate": "07.09.1997"
    },
    {
        "engDate": "1940-12-22",
        "nepDate": "08.09.1997"
    },
    {
        "engDate": "1940-12-23",
        "nepDate": "09.09.1997"
    },
    {
        "engDate": "1940-12-24",
        "nepDate": "10.09.1997"
    },
    {
        "engDate": "1940-12-25",
        "nepDate": "11.09.1997"
    },
    {
        "engDate": "1940-12-26",
        "nepDate": "12.09.1997"
    },
    {
        "engDate": "1940-12-27",
        "nepDate": "13.09.1997"
    },
    {
        "engDate": "1940-12-28",
        "nepDate": "14.09.1997"
    },
    {
        "engDate": "1940-12-29",
        "nepDate": "15.09.1997"
    },
    {
        "engDate": "1940-12-30",
        "nepDate": "16.09.1997"
    },
    {
        "engDate": "1940-12-31",
        "nepDate": "17.09.1997"
    },
    {
        "engDate": "1941-01-01",
        "nepDate": "18.09.1997"
    },
    {
        "engDate": "1941-01-02",
        "nepDate": "19.09.1997"
    },
    {
        "engDate": "1941-01-03",
        "nepDate": "20.09.1997"
    },
    {
        "engDate": "1941-01-04",
        "nepDate": "21.09.1997"
    },
    {
        "engDate": "1941-01-05",
        "nepDate": "22.09.1997"
    },
    {
        "engDate": "1941-01-06",
        "nepDate": "23.09.1997"
    },
    {
        "engDate": "1941-01-07",
        "nepDate": "24.09.1997"
    },
    {
        "engDate": "1941-01-08",
        "nepDate": "25.09.1997"
    },
    {
        "engDate": "1941-01-09",
        "nepDate": "26.09.1997"
    },
    {
        "engDate": "1941-01-10",
        "nepDate": "27.09.1997"
    },
    {
        "engDate": "1941-01-11",
        "nepDate": "28.09.1997"
    },
    {
        "engDate": "1941-01-12",
        "nepDate": "29.09.1997"
    },
    {
        "engDate": "1941-01-13",
        "nepDate": "30.09.1997"
    },
    {
        "engDate": "1941-01-14",
        "nepDate": "01.10.1997"
    },
    {
        "engDate": "1941-01-15",
        "nepDate": "02.10.1997"
    },
    {
        "engDate": "1941-01-16",
        "nepDate": "03.10.1997"
    },
    {
        "engDate": "1941-01-17",
        "nepDate": "04.10.1997"
    },
    {
        "engDate": "1941-01-18",
        "nepDate": "05.10.1997"
    },
    {
        "engDate": "1941-01-19",
        "nepDate": "06.10.1997"
    },
    {
        "engDate": "1941-01-20",
        "nepDate": "07.10.1997"
    },
    {
        "engDate": "1941-01-21",
        "nepDate": "08.10.1997"
    },
    {
        "engDate": "1941-01-22",
        "nepDate": "09.10.1997"
    },
    {
        "engDate": "1941-01-23",
        "nepDate": "10.10.1997"
    },
    {
        "engDate": "1941-01-24",
        "nepDate": "11.10.1997"
    },
    {
        "engDate": "1941-01-25",
        "nepDate": "12.10.1997"
    },
    {
        "engDate": "1941-01-26",
        "nepDate": "13.10.1997"
    },
    {
        "engDate": "1941-01-27",
        "nepDate": "14.10.1997"
    },
    {
        "engDate": "1941-01-28",
        "nepDate": "15.10.1997"
    },
    {
        "engDate": "1941-01-29",
        "nepDate": "16.10.1997"
    },
    {
        "engDate": "1941-01-30",
        "nepDate": "17.10.1997"
    },
    {
        "engDate": "1941-01-31",
        "nepDate": "18.10.1997"
    },
    {
        "engDate": "1941-02-01",
        "nepDate": "19.10.1997"
    },
    {
        "engDate": "1941-02-02",
        "nepDate": "20.10.1997"
    },
    {
        "engDate": "1941-02-03",
        "nepDate": "21.10.1997"
    },
    {
        "engDate": "1941-02-04",
        "nepDate": "22.10.1997"
    },
    {
        "engDate": "1941-02-05",
        "nepDate": "23.10.1997"
    },
    {
        "engDate": "1941-02-06",
        "nepDate": "24.10.1997"
    },
    {
        "engDate": "1941-02-07",
        "nepDate": "25.10.1997"
    },
    {
        "engDate": "1941-02-08",
        "nepDate": "26.10.1997"
    },
    {
        "engDate": "1941-02-09",
        "nepDate": "27.10.1997"
    },
    {
        "engDate": "1941-02-10",
        "nepDate": "28.10.1997"
    },
    {
        "engDate": "1941-02-11",
        "nepDate": "29.10.1997"
    },
    {
        "engDate": "1941-02-12",
        "nepDate": "01.11.1997"
    },
    {
        "engDate": "1941-02-13",
        "nepDate": "02.11.1997"
    },
    {
        "engDate": "1941-02-14",
        "nepDate": "03.11.1997"
    },
    {
        "engDate": "1941-02-15",
        "nepDate": "04.11.1997"
    },
    {
        "engDate": "1941-02-16",
        "nepDate": "05.11.1997"
    },
    {
        "engDate": "1941-02-17",
        "nepDate": "06.11.1997"
    },
    {
        "engDate": "1941-02-18",
        "nepDate": "07.11.1997"
    },
    {
        "engDate": "1941-02-19",
        "nepDate": "08.11.1997"
    },
    {
        "engDate": "1941-02-20",
        "nepDate": "09.11.1997"
    },
    {
        "engDate": "1941-02-21",
        "nepDate": "10.11.1997"
    },
    {
        "engDate": "1941-02-22",
        "nepDate": "11.11.1997"
    },
    {
        "engDate": "1941-02-23",
        "nepDate": "12.11.1997"
    },
    {
        "engDate": "1941-02-24",
        "nepDate": "13.11.1997"
    },
    {
        "engDate": "1941-02-25",
        "nepDate": "14.11.1997"
    },
    {
        "engDate": "1941-02-26",
        "nepDate": "15.11.1997"
    },
    {
        "engDate": "1941-02-27",
        "nepDate": "16.11.1997"
    },
    {
        "engDate": "1941-02-28",
        "nepDate": "17.11.1997"
    },
    {
        "engDate": "1941-03-01",
        "nepDate": "18.11.1997"
    },
    {
        "engDate": "1941-03-02",
        "nepDate": "19.11.1997"
    },
    {
        "engDate": "1941-03-03",
        "nepDate": "20.11.1997"
    },
    {
        "engDate": "1941-03-04",
        "nepDate": "21.11.1997"
    },
    {
        "engDate": "1941-03-05",
        "nepDate": "22.11.1997"
    },
    {
        "engDate": "1941-03-06",
        "nepDate": "23.11.1997"
    },
    {
        "engDate": "1941-03-07",
        "nepDate": "24.11.1997"
    },
    {
        "engDate": "1941-03-08",
        "nepDate": "25.11.1997"
    },
    {
        "engDate": "1941-03-09",
        "nepDate": "26.11.1997"
    },
    {
        "engDate": "1941-03-10",
        "nepDate": "27.11.1997"
    },
    {
        "engDate": "1941-03-11",
        "nepDate": "28.11.1997"
    },
    {
        "engDate": "1941-03-12",
        "nepDate": "29.11.1997"
    },
    {
        "engDate": "1941-03-13",
        "nepDate": "30.11.1997"
    },
    {
        "engDate": "1941-03-14",
        "nepDate": "01.12.1997"
    },
    {
        "engDate": "1941-03-15",
        "nepDate": "02.12.1997"
    },
    {
        "engDate": "1941-03-16",
        "nepDate": "03.12.1997"
    },
    {
        "engDate": "1941-03-17",
        "nepDate": "04.12.1997"
    },
    {
        "engDate": "1941-03-18",
        "nepDate": "05.12.1997"
    },
    {
        "engDate": "1941-03-19",
        "nepDate": "06.12.1997"
    },
    {
        "engDate": "1941-03-20",
        "nepDate": "07.12.1997"
    },
    {
        "engDate": "1941-03-21",
        "nepDate": "08.12.1997"
    },
    {
        "engDate": "1941-03-22",
        "nepDate": "09.12.1997"
    },
    {
        "engDate": "1941-03-23",
        "nepDate": "10.12.1997"
    },
    {
        "engDate": "1941-03-24",
        "nepDate": "11.12.1997"
    },
    {
        "engDate": "1941-03-25",
        "nepDate": "12.12.1997"
    },
    {
        "engDate": "1941-03-26",
        "nepDate": "13.12.1997"
    },
    {
        "engDate": "1941-03-27",
        "nepDate": "14.12.1997"
    },
    {
        "engDate": "1941-03-28",
        "nepDate": "15.12.1997"
    },
    {
        "engDate": "1941-03-29",
        "nepDate": "16.12.1997"
    },
    {
        "engDate": "1941-03-30",
        "nepDate": "17.12.1997"
    },
    {
        "engDate": "1941-03-31",
        "nepDate": "18.12.1997"
    },
    {
        "engDate": "1941-04-01",
        "nepDate": "19.12.1997"
    },
    {
        "engDate": "1941-04-02",
        "nepDate": "20.12.1997"
    },
    {
        "engDate": "1941-04-03",
        "nepDate": "21.12.1997"
    },
    {
        "engDate": "1941-04-04",
        "nepDate": "22.12.1997"
    },
    {
        "engDate": "1941-04-05",
        "nepDate": "23.12.1997"
    },
    {
        "engDate": "1941-04-06",
        "nepDate": "24.12.1997"
    },
    {
        "engDate": "1941-04-07",
        "nepDate": "25.12.1997"
    },
    {
        "engDate": "1941-04-08",
        "nepDate": "26.12.1997"
    },
    {
        "engDate": "1941-04-09",
        "nepDate": "27.12.1997"
    },
    {
        "engDate": "1941-04-10",
        "nepDate": "28.12.1997"
    },
    {
        "engDate": "1941-04-11",
        "nepDate": "29.12.1997"
    },
    {
        "engDate": "1941-04-12",
        "nepDate": "30.12.1997"
    },
    {
        "engDate": "1941-04-13",
        "nepDate": "01.01.1998"
    },
    {
        "engDate": "1941-04-14",
        "nepDate": "02.01.1998"
    },
    {
        "engDate": "1941-04-15",
        "nepDate": "03.01.1998"
    },
    {
        "engDate": "1941-04-16",
        "nepDate": "04.01.1998"
    },
    {
        "engDate": "1941-04-17",
        "nepDate": "05.01.1998"
    },
    {
        "engDate": "1941-04-18",
        "nepDate": "06.01.1998"
    },
    {
        "engDate": "1941-04-19",
        "nepDate": "07.01.1998"
    },
    {
        "engDate": "1941-04-20",
        "nepDate": "08.01.1998"
    },
    {
        "engDate": "1941-04-21",
        "nepDate": "09.01.1998"
    },
    {
        "engDate": "1941-04-22",
        "nepDate": "10.01.1998"
    },
    {
        "engDate": "1941-04-23",
        "nepDate": "11.01.1998"
    },
    {
        "engDate": "1941-04-24",
        "nepDate": "12.01.1998"
    },
    {
        "engDate": "1941-04-25",
        "nepDate": "13.01.1998"
    },
    {
        "engDate": "1941-04-26",
        "nepDate": "14.01.1998"
    },
    {
        "engDate": "1941-04-27",
        "nepDate": "15.01.1998"
    },
    {
        "engDate": "1941-04-28",
        "nepDate": "16.01.1998"
    },
    {
        "engDate": "1941-04-29",
        "nepDate": "17.01.1998"
    },
    {
        "engDate": "1941-04-30",
        "nepDate": "18.01.1998"
    },
    {
        "engDate": "1941-05-01",
        "nepDate": "19.01.1998"
    },
    {
        "engDate": "1941-05-02",
        "nepDate": "20.01.1998"
    },
    {
        "engDate": "1941-05-03",
        "nepDate": "21.01.1998"
    },
    {
        "engDate": "1941-05-04",
        "nepDate": "22.01.1998"
    },
    {
        "engDate": "1941-05-05",
        "nepDate": "23.01.1998"
    },
    {
        "engDate": "1941-05-06",
        "nepDate": "24.01.1998"
    },
    {
        "engDate": "1941-05-07",
        "nepDate": "25.01.1998"
    },
    {
        "engDate": "1941-05-08",
        "nepDate": "26.01.1998"
    },
    {
        "engDate": "1941-05-09",
        "nepDate": "27.01.1998"
    },
    {
        "engDate": "1941-05-10",
        "nepDate": "28.01.1998"
    },
    {
        "engDate": "1941-05-11",
        "nepDate": "29.01.1998"
    },
    {
        "engDate": "1941-05-12",
        "nepDate": "30.01.1998"
    },
    {
        "engDate": "1941-05-13",
        "nepDate": "31.01.1998"
    },
    {
        "engDate": "1941-05-14",
        "nepDate": "01.02.1998"
    },
    {
        "engDate": "1941-05-15",
        "nepDate": "02.02.1998"
    },
    {
        "engDate": "1941-05-16",
        "nepDate": "03.02.1998"
    },
    {
        "engDate": "1941-05-17",
        "nepDate": "04.02.1998"
    },
    {
        "engDate": "1941-05-18",
        "nepDate": "05.02.1998"
    },
    {
        "engDate": "1941-05-19",
        "nepDate": "06.02.1998"
    },
    {
        "engDate": "1941-05-20",
        "nepDate": "07.02.1998"
    },
    {
        "engDate": "1941-05-21",
        "nepDate": "08.02.1998"
    },
    {
        "engDate": "1941-05-22",
        "nepDate": "09.02.1998"
    },
    {
        "engDate": "1941-05-23",
        "nepDate": "10.02.1998"
    },
    {
        "engDate": "1941-05-24",
        "nepDate": "11.02.1998"
    },
    {
        "engDate": "1941-05-25",
        "nepDate": "12.02.1998"
    },
    {
        "engDate": "1941-05-26",
        "nepDate": "13.02.1998"
    },
    {
        "engDate": "1941-05-27",
        "nepDate": "14.02.1998"
    },
    {
        "engDate": "1941-05-28",
        "nepDate": "15.02.1998"
    },
    {
        "engDate": "1941-05-29",
        "nepDate": "16.02.1998"
    },
    {
        "engDate": "1941-05-30",
        "nepDate": "17.02.1998"
    },
    {
        "engDate": "1941-05-31",
        "nepDate": "18.02.1998"
    },
    {
        "engDate": "1941-06-01",
        "nepDate": "19.02.1998"
    },
    {
        "engDate": "1941-06-02",
        "nepDate": "20.02.1998"
    },
    {
        "engDate": "1941-06-03",
        "nepDate": "21.02.1998"
    },
    {
        "engDate": "1941-06-04",
        "nepDate": "22.02.1998"
    },
    {
        "engDate": "1941-06-05",
        "nepDate": "23.02.1998"
    },
    {
        "engDate": "1941-06-06",
        "nepDate": "24.02.1998"
    },
    {
        "engDate": "1941-06-07",
        "nepDate": "25.02.1998"
    },
    {
        "engDate": "1941-06-08",
        "nepDate": "26.02.1998"
    },
    {
        "engDate": "1941-06-09",
        "nepDate": "27.02.1998"
    },
    {
        "engDate": "1941-06-10",
        "nepDate": "28.02.1998"
    },
    {
        "engDate": "1941-06-11",
        "nepDate": "29.02.1998"
    },
    {
        "engDate": "1941-06-12",
        "nepDate": "30.02.1998"
    },
    {
        "engDate": "1941-06-13",
        "nepDate": "31.02.1998"
    },
    {
        "engDate": "1941-06-14",
        "nepDate": "01.03.1998"
    },
    {
        "engDate": "1941-06-15",
        "nepDate": "02.03.1998"
    },
    {
        "engDate": "1941-06-16",
        "nepDate": "03.03.1998"
    },
    {
        "engDate": "1941-06-17",
        "nepDate": "04.03.1998"
    },
    {
        "engDate": "1941-06-18",
        "nepDate": "05.03.1998"
    },
    {
        "engDate": "1941-06-19",
        "nepDate": "06.03.1998"
    },
    {
        "engDate": "1941-06-20",
        "nepDate": "07.03.1998"
    },
    {
        "engDate": "1941-06-21",
        "nepDate": "08.03.1998"
    },
    {
        "engDate": "1941-06-22",
        "nepDate": "09.03.1998"
    },
    {
        "engDate": "1941-06-23",
        "nepDate": "10.03.1998"
    },
    {
        "engDate": "1941-06-24",
        "nepDate": "11.03.1998"
    },
    {
        "engDate": "1941-06-25",
        "nepDate": "12.03.1998"
    },
    {
        "engDate": "1941-06-26",
        "nepDate": "13.03.1998"
    },
    {
        "engDate": "1941-06-27",
        "nepDate": "14.03.1998"
    },
    {
        "engDate": "1941-06-28",
        "nepDate": "15.03.1998"
    },
    {
        "engDate": "1941-06-29",
        "nepDate": "16.03.1998"
    },
    {
        "engDate": "1941-06-30",
        "nepDate": "17.03.1998"
    },
    {
        "engDate": "1941-07-01",
        "nepDate": "18.03.1998"
    },
    {
        "engDate": "1941-07-02",
        "nepDate": "19.03.1998"
    },
    {
        "engDate": "1941-07-03",
        "nepDate": "20.03.1998"
    },
    {
        "engDate": "1941-07-04",
        "nepDate": "21.03.1998"
    },
    {
        "engDate": "1941-07-05",
        "nepDate": "22.03.1998"
    },
    {
        "engDate": "1941-07-06",
        "nepDate": "23.03.1998"
    },
    {
        "engDate": "1941-07-07",
        "nepDate": "24.03.1998"
    },
    {
        "engDate": "1941-07-08",
        "nepDate": "25.03.1998"
    },
    {
        "engDate": "1941-07-09",
        "nepDate": "26.03.1998"
    },
    {
        "engDate": "1941-07-10",
        "nepDate": "27.03.1998"
    },
    {
        "engDate": "1941-07-11",
        "nepDate": "28.03.1998"
    },
    {
        "engDate": "1941-07-12",
        "nepDate": "29.03.1998"
    },
    {
        "engDate": "1941-07-13",
        "nepDate": "30.03.1998"
    },
    {
        "engDate": "1941-07-14",
        "nepDate": "31.03.1998"
    },
    {
        "engDate": "1941-07-15",
        "nepDate": "32.03.1998"
    },
    {
        "engDate": "1941-07-16",
        "nepDate": "01.04.1998"
    },
    {
        "engDate": "1941-07-17",
        "nepDate": "02.04.1998"
    },
    {
        "engDate": "1941-07-18",
        "nepDate": "03.04.1998"
    },
    {
        "engDate": "1941-07-19",
        "nepDate": "04.04.1998"
    },
    {
        "engDate": "1941-07-20",
        "nepDate": "05.04.1998"
    },
    {
        "engDate": "1941-07-21",
        "nepDate": "06.04.1998"
    },
    {
        "engDate": "1941-07-22",
        "nepDate": "07.04.1998"
    },
    {
        "engDate": "1941-07-23",
        "nepDate": "08.04.1998"
    },
    {
        "engDate": "1941-07-24",
        "nepDate": "09.04.1998"
    },
    {
        "engDate": "1941-07-25",
        "nepDate": "10.04.1998"
    },
    {
        "engDate": "1941-07-26",
        "nepDate": "11.04.1998"
    },
    {
        "engDate": "1941-07-27",
        "nepDate": "12.04.1998"
    },
    {
        "engDate": "1941-07-28",
        "nepDate": "13.04.1998"
    },
    {
        "engDate": "1941-07-29",
        "nepDate": "14.04.1998"
    },
    {
        "engDate": "1941-07-30",
        "nepDate": "15.04.1998"
    },
    {
        "engDate": "1941-07-31",
        "nepDate": "16.04.1998"
    },
    {
        "engDate": "1941-08-01",
        "nepDate": "17.04.1998"
    },
    {
        "engDate": "1941-08-02",
        "nepDate": "18.04.1998"
    },
    {
        "engDate": "1941-08-03",
        "nepDate": "19.04.1998"
    },
    {
        "engDate": "1941-08-04",
        "nepDate": "20.04.1998"
    },
    {
        "engDate": "1941-08-05",
        "nepDate": "21.04.1998"
    },
    {
        "engDate": "1941-08-06",
        "nepDate": "22.04.1998"
    },
    {
        "engDate": "1941-08-07",
        "nepDate": "23.04.1998"
    },
    {
        "engDate": "1941-08-08",
        "nepDate": "24.04.1998"
    },
    {
        "engDate": "1941-08-09",
        "nepDate": "25.04.1998"
    },
    {
        "engDate": "1941-08-10",
        "nepDate": "26.04.1998"
    },
    {
        "engDate": "1941-08-11",
        "nepDate": "27.04.1998"
    },
    {
        "engDate": "1941-08-12",
        "nepDate": "28.04.1998"
    },
    {
        "engDate": "1941-08-13",
        "nepDate": "29.04.1998"
    },
    {
        "engDate": "1941-08-14",
        "nepDate": "30.04.1998"
    },
    {
        "engDate": "1941-08-15",
        "nepDate": "31.04.1998"
    },
    {
        "engDate": "1941-08-16",
        "nepDate": "01.05.1998"
    },
    {
        "engDate": "1941-08-17",
        "nepDate": "02.05.1998"
    },
    {
        "engDate": "1941-08-18",
        "nepDate": "03.05.1998"
    },
    {
        "engDate": "1941-08-19",
        "nepDate": "04.05.1998"
    },
    {
        "engDate": "1941-08-20",
        "nepDate": "05.05.1998"
    },
    {
        "engDate": "1941-08-21",
        "nepDate": "06.05.1998"
    },
    {
        "engDate": "1941-08-22",
        "nepDate": "07.05.1998"
    },
    {
        "engDate": "1941-08-23",
        "nepDate": "08.05.1998"
    },
    {
        "engDate": "1941-08-24",
        "nepDate": "09.05.1998"
    },
    {
        "engDate": "1941-08-25",
        "nepDate": "10.05.1998"
    },
    {
        "engDate": "1941-08-26",
        "nepDate": "11.05.1998"
    },
    {
        "engDate": "1941-08-27",
        "nepDate": "12.05.1998"
    },
    {
        "engDate": "1941-08-28",
        "nepDate": "13.05.1998"
    },
    {
        "engDate": "1941-08-29",
        "nepDate": "14.05.1998"
    },
    {
        "engDate": "1941-08-30",
        "nepDate": "15.05.1998"
    },
    {
        "engDate": "1941-08-31",
        "nepDate": "16.05.1998"
    },
    {
        "engDate": "1941-09-01",
        "nepDate": "17.05.1998"
    },
    {
        "engDate": "1941-09-02",
        "nepDate": "18.05.1998"
    },
    {
        "engDate": "1941-09-03",
        "nepDate": "19.05.1998"
    },
    {
        "engDate": "1941-09-04",
        "nepDate": "20.05.1998"
    },
    {
        "engDate": "1941-09-05",
        "nepDate": "21.05.1998"
    },
    {
        "engDate": "1941-09-06",
        "nepDate": "22.05.1998"
    },
    {
        "engDate": "1941-09-07",
        "nepDate": "23.05.1998"
    },
    {
        "engDate": "1941-09-08",
        "nepDate": "24.05.1998"
    },
    {
        "engDate": "1941-09-09",
        "nepDate": "25.05.1998"
    },
    {
        "engDate": "1941-09-10",
        "nepDate": "26.05.1998"
    },
    {
        "engDate": "1941-09-11",
        "nepDate": "27.05.1998"
    },
    {
        "engDate": "1941-09-12",
        "nepDate": "28.05.1998"
    },
    {
        "engDate": "1941-09-13",
        "nepDate": "29.05.1998"
    },
    {
        "engDate": "1941-09-14",
        "nepDate": "30.05.1998"
    },
    {
        "engDate": "1941-09-15",
        "nepDate": "31.05.1998"
    },
    {
        "engDate": "1941-09-16",
        "nepDate": "01.06.1998"
    },
    {
        "engDate": "1941-09-17",
        "nepDate": "02.06.1998"
    },
    {
        "engDate": "1941-09-18",
        "nepDate": "03.06.1998"
    },
    {
        "engDate": "1941-09-19",
        "nepDate": "04.06.1998"
    },
    {
        "engDate": "1941-09-20",
        "nepDate": "05.06.1998"
    },
    {
        "engDate": "1941-09-21",
        "nepDate": "06.06.1998"
    },
    {
        "engDate": "1941-09-22",
        "nepDate": "07.06.1998"
    },
    {
        "engDate": "1941-09-23",
        "nepDate": "08.06.1998"
    },
    {
        "engDate": "1941-09-24",
        "nepDate": "09.06.1998"
    },
    {
        "engDate": "1941-09-25",
        "nepDate": "10.06.1998"
    },
    {
        "engDate": "1941-09-26",
        "nepDate": "11.06.1998"
    },
    {
        "engDate": "1941-09-27",
        "nepDate": "12.06.1998"
    },
    {
        "engDate": "1941-09-28",
        "nepDate": "13.06.1998"
    },
    {
        "engDate": "1941-09-29",
        "nepDate": "14.06.1998"
    },
    {
        "engDate": "1941-09-30",
        "nepDate": "15.06.1998"
    },
    {
        "engDate": "1941-10-01",
        "nepDate": "16.06.1998"
    },
    {
        "engDate": "1941-10-02",
        "nepDate": "17.06.1998"
    },
    {
        "engDate": "1941-10-03",
        "nepDate": "18.06.1998"
    },
    {
        "engDate": "1941-10-04",
        "nepDate": "19.06.1998"
    },
    {
        "engDate": "1941-10-05",
        "nepDate": "20.06.1998"
    },
    {
        "engDate": "1941-10-06",
        "nepDate": "21.06.1998"
    },
    {
        "engDate": "1941-10-07",
        "nepDate": "22.06.1998"
    },
    {
        "engDate": "1941-10-08",
        "nepDate": "23.06.1998"
    },
    {
        "engDate": "1941-10-09",
        "nepDate": "24.06.1998"
    },
    {
        "engDate": "1941-10-10",
        "nepDate": "25.06.1998"
    },
    {
        "engDate": "1941-10-11",
        "nepDate": "26.06.1998"
    },
    {
        "engDate": "1941-10-12",
        "nepDate": "27.06.1998"
    },
    {
        "engDate": "1941-10-13",
        "nepDate": "28.06.1998"
    },
    {
        "engDate": "1941-10-14",
        "nepDate": "29.06.1998"
    },
    {
        "engDate": "1941-10-15",
        "nepDate": "30.06.1998"
    },
    {
        "engDate": "1941-10-16",
        "nepDate": "31.06.1998"
    },
    {
        "engDate": "1941-10-17",
        "nepDate": "01.07.1998"
    },
    {
        "engDate": "1941-10-18",
        "nepDate": "02.07.1998"
    },
    {
        "engDate": "1941-10-19",
        "nepDate": "03.07.1998"
    },
    {
        "engDate": "1941-10-20",
        "nepDate": "04.07.1998"
    },
    {
        "engDate": "1941-10-21",
        "nepDate": "05.07.1998"
    },
    {
        "engDate": "1941-10-22",
        "nepDate": "06.07.1998"
    },
    {
        "engDate": "1941-10-23",
        "nepDate": "07.07.1998"
    },
    {
        "engDate": "1941-10-24",
        "nepDate": "08.07.1998"
    },
    {
        "engDate": "1941-10-25",
        "nepDate": "09.07.1998"
    },
    {
        "engDate": "1941-10-26",
        "nepDate": "10.07.1998"
    },
    {
        "engDate": "1941-10-27",
        "nepDate": "11.07.1998"
    },
    {
        "engDate": "1941-10-28",
        "nepDate": "12.07.1998"
    },
    {
        "engDate": "1941-10-29",
        "nepDate": "13.07.1998"
    },
    {
        "engDate": "1941-10-30",
        "nepDate": "14.07.1998"
    },
    {
        "engDate": "1941-10-31",
        "nepDate": "15.07.1998"
    },
    {
        "engDate": "1941-11-01",
        "nepDate": "16.07.1998"
    },
    {
        "engDate": "1941-11-02",
        "nepDate": "17.07.1998"
    },
    {
        "engDate": "1941-11-03",
        "nepDate": "18.07.1998"
    },
    {
        "engDate": "1941-11-04",
        "nepDate": "19.07.1998"
    },
    {
        "engDate": "1941-11-05",
        "nepDate": "20.07.1998"
    },
    {
        "engDate": "1941-11-06",
        "nepDate": "21.07.1998"
    },
    {
        "engDate": "1941-11-07",
        "nepDate": "22.07.1998"
    },
    {
        "engDate": "1941-11-08",
        "nepDate": "23.07.1998"
    },
    {
        "engDate": "1941-11-09",
        "nepDate": "24.07.1998"
    },
    {
        "engDate": "1941-11-10",
        "nepDate": "25.07.1998"
    },
    {
        "engDate": "1941-11-11",
        "nepDate": "26.07.1998"
    },
    {
        "engDate": "1941-11-12",
        "nepDate": "27.07.1998"
    },
    {
        "engDate": "1941-11-13",
        "nepDate": "28.07.1998"
    },
    {
        "engDate": "1941-11-14",
        "nepDate": "29.07.1998"
    },
    {
        "engDate": "1941-11-15",
        "nepDate": "30.07.1998"
    },
    {
        "engDate": "1941-11-16",
        "nepDate": "01.08.1998"
    },
    {
        "engDate": "1941-11-17",
        "nepDate": "02.08.1998"
    },
    {
        "engDate": "1941-11-18",
        "nepDate": "03.08.1998"
    },
    {
        "engDate": "1941-11-19",
        "nepDate": "04.08.1998"
    },
    {
        "engDate": "1941-11-20",
        "nepDate": "05.08.1998"
    },
    {
        "engDate": "1941-11-21",
        "nepDate": "06.08.1998"
    },
    {
        "engDate": "1941-11-22",
        "nepDate": "07.08.1998"
    },
    {
        "engDate": "1941-11-23",
        "nepDate": "08.08.1998"
    },
    {
        "engDate": "1941-11-24",
        "nepDate": "09.08.1998"
    },
    {
        "engDate": "1941-11-25",
        "nepDate": "10.08.1998"
    },
    {
        "engDate": "1941-11-26",
        "nepDate": "11.08.1998"
    },
    {
        "engDate": "1941-11-27",
        "nepDate": "12.08.1998"
    },
    {
        "engDate": "1941-11-28",
        "nepDate": "13.08.1998"
    },
    {
        "engDate": "1941-11-29",
        "nepDate": "14.08.1998"
    },
    {
        "engDate": "1941-11-30",
        "nepDate": "15.08.1998"
    },
    {
        "engDate": "1941-12-01",
        "nepDate": "16.08.1998"
    },
    {
        "engDate": "1941-12-02",
        "nepDate": "17.08.1998"
    },
    {
        "engDate": "1941-12-03",
        "nepDate": "18.08.1998"
    },
    {
        "engDate": "1941-12-04",
        "nepDate": "19.08.1998"
    },
    {
        "engDate": "1941-12-05",
        "nepDate": "20.08.1998"
    },
    {
        "engDate": "1941-12-06",
        "nepDate": "21.08.1998"
    },
    {
        "engDate": "1941-12-07",
        "nepDate": "22.08.1998"
    },
    {
        "engDate": "1941-12-08",
        "nepDate": "23.08.1998"
    },
    {
        "engDate": "1941-12-09",
        "nepDate": "24.08.1998"
    },
    {
        "engDate": "1941-12-10",
        "nepDate": "25.08.1998"
    },
    {
        "engDate": "1941-12-11",
        "nepDate": "26.08.1998"
    },
    {
        "engDate": "1941-12-12",
        "nepDate": "27.08.1998"
    },
    {
        "engDate": "1941-12-13",
        "nepDate": "28.08.1998"
    },
    {
        "engDate": "1941-12-14",
        "nepDate": "29.08.1998"
    },
    {
        "engDate": "1941-12-15",
        "nepDate": "01.09.1998"
    },
    {
        "engDate": "1941-12-16",
        "nepDate": "02.09.1998"
    },
    {
        "engDate": "1941-12-17",
        "nepDate": "03.09.1998"
    },
    {
        "engDate": "1941-12-18",
        "nepDate": "04.09.1998"
    },
    {
        "engDate": "1941-12-19",
        "nepDate": "05.09.1998"
    },
    {
        "engDate": "1941-12-20",
        "nepDate": "06.09.1998"
    },
    {
        "engDate": "1941-12-21",
        "nepDate": "07.09.1998"
    },
    {
        "engDate": "1941-12-22",
        "nepDate": "08.09.1998"
    },
    {
        "engDate": "1941-12-23",
        "nepDate": "09.09.1998"
    },
    {
        "engDate": "1941-12-24",
        "nepDate": "10.09.1998"
    },
    {
        "engDate": "1941-12-25",
        "nepDate": "11.09.1998"
    },
    {
        "engDate": "1941-12-26",
        "nepDate": "12.09.1998"
    },
    {
        "engDate": "1941-12-27",
        "nepDate": "13.09.1998"
    },
    {
        "engDate": "1941-12-28",
        "nepDate": "14.09.1998"
    },
    {
        "engDate": "1941-12-29",
        "nepDate": "15.09.1998"
    },
    {
        "engDate": "1941-12-30",
        "nepDate": "16.09.1998"
    },
    {
        "engDate": "1941-12-31",
        "nepDate": "17.09.1998"
    },
    {
        "engDate": "1942-01-01",
        "nepDate": "18.09.1998"
    },
    {
        "engDate": "1942-01-02",
        "nepDate": "19.09.1998"
    },
    {
        "engDate": "1942-01-03",
        "nepDate": "20.09.1998"
    },
    {
        "engDate": "1942-01-04",
        "nepDate": "21.09.1998"
    },
    {
        "engDate": "1942-01-05",
        "nepDate": "22.09.1998"
    },
    {
        "engDate": "1942-01-06",
        "nepDate": "23.09.1998"
    },
    {
        "engDate": "1942-01-07",
        "nepDate": "24.09.1998"
    },
    {
        "engDate": "1942-01-08",
        "nepDate": "25.09.1998"
    },
    {
        "engDate": "1942-01-09",
        "nepDate": "26.09.1998"
    },
    {
        "engDate": "1942-01-10",
        "nepDate": "27.09.1998"
    },
    {
        "engDate": "1942-01-11",
        "nepDate": "28.09.1998"
    },
    {
        "engDate": "1942-01-12",
        "nepDate": "29.09.1998"
    },
    {
        "engDate": "1942-01-13",
        "nepDate": "30.09.1998"
    },
    {
        "engDate": "1942-01-14",
        "nepDate": "01.10.1998"
    },
    {
        "engDate": "1942-01-15",
        "nepDate": "02.10.1998"
    },
    {
        "engDate": "1942-01-16",
        "nepDate": "03.10.1998"
    },
    {
        "engDate": "1942-01-17",
        "nepDate": "04.10.1998"
    },
    {
        "engDate": "1942-01-18",
        "nepDate": "05.10.1998"
    },
    {
        "engDate": "1942-01-19",
        "nepDate": "06.10.1998"
    },
    {
        "engDate": "1942-01-20",
        "nepDate": "07.10.1998"
    },
    {
        "engDate": "1942-01-21",
        "nepDate": "08.10.1998"
    },
    {
        "engDate": "1942-01-22",
        "nepDate": "09.10.1998"
    },
    {
        "engDate": "1942-01-23",
        "nepDate": "10.10.1998"
    },
    {
        "engDate": "1942-01-24",
        "nepDate": "11.10.1998"
    },
    {
        "engDate": "1942-01-25",
        "nepDate": "12.10.1998"
    },
    {
        "engDate": "1942-01-26",
        "nepDate": "13.10.1998"
    },
    {
        "engDate": "1942-01-27",
        "nepDate": "14.10.1998"
    },
    {
        "engDate": "1942-01-28",
        "nepDate": "15.10.1998"
    },
    {
        "engDate": "1942-01-29",
        "nepDate": "16.10.1998"
    },
    {
        "engDate": "1942-01-30",
        "nepDate": "17.10.1998"
    },
    {
        "engDate": "1942-01-31",
        "nepDate": "18.10.1998"
    },
    {
        "engDate": "1942-02-01",
        "nepDate": "19.10.1998"
    },
    {
        "engDate": "1942-02-02",
        "nepDate": "20.10.1998"
    },
    {
        "engDate": "1942-02-03",
        "nepDate": "21.10.1998"
    },
    {
        "engDate": "1942-02-04",
        "nepDate": "22.10.1998"
    },
    {
        "engDate": "1942-02-05",
        "nepDate": "23.10.1998"
    },
    {
        "engDate": "1942-02-06",
        "nepDate": "24.10.1998"
    },
    {
        "engDate": "1942-02-07",
        "nepDate": "25.10.1998"
    },
    {
        "engDate": "1942-02-08",
        "nepDate": "26.10.1998"
    },
    {
        "engDate": "1942-02-09",
        "nepDate": "27.10.1998"
    },
    {
        "engDate": "1942-02-10",
        "nepDate": "28.10.1998"
    },
    {
        "engDate": "1942-02-11",
        "nepDate": "29.10.1998"
    },
    {
        "engDate": "1942-02-12",
        "nepDate": "01.11.1998"
    },
    {
        "engDate": "1942-02-13",
        "nepDate": "02.11.1998"
    },
    {
        "engDate": "1942-02-14",
        "nepDate": "03.11.1998"
    },
    {
        "engDate": "1942-02-15",
        "nepDate": "04.11.1998"
    },
    {
        "engDate": "1942-02-16",
        "nepDate": "05.11.1998"
    },
    {
        "engDate": "1942-02-17",
        "nepDate": "06.11.1998"
    },
    {
        "engDate": "1942-02-18",
        "nepDate": "07.11.1998"
    },
    {
        "engDate": "1942-02-19",
        "nepDate": "08.11.1998"
    },
    {
        "engDate": "1942-02-20",
        "nepDate": "09.11.1998"
    },
    {
        "engDate": "1942-02-21",
        "nepDate": "10.11.1998"
    },
    {
        "engDate": "1942-02-22",
        "nepDate": "11.11.1998"
    },
    {
        "engDate": "1942-02-23",
        "nepDate": "12.11.1998"
    },
    {
        "engDate": "1942-02-24",
        "nepDate": "13.11.1998"
    },
    {
        "engDate": "1942-02-25",
        "nepDate": "14.11.1998"
    },
    {
        "engDate": "1942-02-26",
        "nepDate": "15.11.1998"
    },
    {
        "engDate": "1942-02-27",
        "nepDate": "16.11.1998"
    },
    {
        "engDate": "1942-02-28",
        "nepDate": "17.11.1998"
    },
    {
        "engDate": "1942-03-01",
        "nepDate": "18.11.1998"
    },
    {
        "engDate": "1942-03-02",
        "nepDate": "19.11.1998"
    },
    {
        "engDate": "1942-03-03",
        "nepDate": "20.11.1998"
    },
    {
        "engDate": "1942-03-04",
        "nepDate": "21.11.1998"
    },
    {
        "engDate": "1942-03-05",
        "nepDate": "22.11.1998"
    },
    {
        "engDate": "1942-03-06",
        "nepDate": "23.11.1998"
    },
    {
        "engDate": "1942-03-07",
        "nepDate": "24.11.1998"
    },
    {
        "engDate": "1942-03-08",
        "nepDate": "25.11.1998"
    },
    {
        "engDate": "1942-03-09",
        "nepDate": "26.11.1998"
    },
    {
        "engDate": "1942-03-10",
        "nepDate": "27.11.1998"
    },
    {
        "engDate": "1942-03-11",
        "nepDate": "28.11.1998"
    },
    {
        "engDate": "1942-03-12",
        "nepDate": "29.11.1998"
    },
    {
        "engDate": "1942-03-13",
        "nepDate": "30.11.1998"
    },
    {
        "engDate": "1942-03-14",
        "nepDate": "01.12.1998"
    },
    {
        "engDate": "1942-03-15",
        "nepDate": "02.12.1998"
    },
    {
        "engDate": "1942-03-16",
        "nepDate": "03.12.1998"
    },
    {
        "engDate": "1942-03-17",
        "nepDate": "04.12.1998"
    },
    {
        "engDate": "1942-03-18",
        "nepDate": "05.12.1998"
    },
    {
        "engDate": "1942-03-19",
        "nepDate": "06.12.1998"
    },
    {
        "engDate": "1942-03-20",
        "nepDate": "07.12.1998"
    },
    {
        "engDate": "1942-03-21",
        "nepDate": "08.12.1998"
    },
    {
        "engDate": "1942-03-22",
        "nepDate": "09.12.1998"
    },
    {
        "engDate": "1942-03-23",
        "nepDate": "10.12.1998"
    },
    {
        "engDate": "1942-03-24",
        "nepDate": "11.12.1998"
    },
    {
        "engDate": "1942-03-25",
        "nepDate": "12.12.1998"
    },
    {
        "engDate": "1942-03-26",
        "nepDate": "13.12.1998"
    },
    {
        "engDate": "1942-03-27",
        "nepDate": "14.12.1998"
    },
    {
        "engDate": "1942-03-28",
        "nepDate": "15.12.1998"
    },
    {
        "engDate": "1942-03-29",
        "nepDate": "16.12.1998"
    },
    {
        "engDate": "1942-03-30",
        "nepDate": "17.12.1998"
    },
    {
        "engDate": "1942-03-31",
        "nepDate": "18.12.1998"
    },
    {
        "engDate": "1942-04-01",
        "nepDate": "19.12.1998"
    },
    {
        "engDate": "1942-04-02",
        "nepDate": "20.12.1998"
    },
    {
        "engDate": "1942-04-03",
        "nepDate": "21.12.1998"
    },
    {
        "engDate": "1942-04-04",
        "nepDate": "22.12.1998"
    },
    {
        "engDate": "1942-04-05",
        "nepDate": "23.12.1998"
    },
    {
        "engDate": "1942-04-06",
        "nepDate": "24.12.1998"
    },
    {
        "engDate": "1942-04-07",
        "nepDate": "25.12.1998"
    },
    {
        "engDate": "1942-04-08",
        "nepDate": "26.12.1998"
    },
    {
        "engDate": "1942-04-09",
        "nepDate": "27.12.1998"
    },
    {
        "engDate": "1942-04-10",
        "nepDate": "28.12.1998"
    },
    {
        "engDate": "1942-04-11",
        "nepDate": "29.12.1998"
    },
    {
        "engDate": "1942-04-12",
        "nepDate": "30.12.1998"
    },
    {
        "engDate": "1942-04-13",
        "nepDate": "01.01.1999"
    },
    {
        "engDate": "1942-04-14",
        "nepDate": "02.01.1999"
    },
    {
        "engDate": "1942-04-15",
        "nepDate": "03.01.1999"
    },
    {
        "engDate": "1942-04-16",
        "nepDate": "04.01.1999"
    },
    {
        "engDate": "1942-04-17",
        "nepDate": "05.01.1999"
    },
    {
        "engDate": "1942-04-18",
        "nepDate": "06.01.1999"
    },
    {
        "engDate": "1942-04-19",
        "nepDate": "07.01.1999"
    },
    {
        "engDate": "1942-04-20",
        "nepDate": "08.01.1999"
    },
    {
        "engDate": "1942-04-21",
        "nepDate": "09.01.1999"
    },
    {
        "engDate": "1942-04-22",
        "nepDate": "10.01.1999"
    },
    {
        "engDate": "1942-04-23",
        "nepDate": "11.01.1999"
    },
    {
        "engDate": "1942-04-24",
        "nepDate": "12.01.1999"
    },
    {
        "engDate": "1942-04-25",
        "nepDate": "13.01.1999"
    },
    {
        "engDate": "1942-04-26",
        "nepDate": "14.01.1999"
    },
    {
        "engDate": "1942-04-27",
        "nepDate": "15.01.1999"
    },
    {
        "engDate": "1942-04-28",
        "nepDate": "16.01.1999"
    },
    {
        "engDate": "1942-04-29",
        "nepDate": "17.01.1999"
    },
    {
        "engDate": "1942-04-30",
        "nepDate": "18.01.1999"
    },
    {
        "engDate": "1942-05-01",
        "nepDate": "19.01.1999"
    },
    {
        "engDate": "1942-05-02",
        "nepDate": "20.01.1999"
    },
    {
        "engDate": "1942-05-03",
        "nepDate": "21.01.1999"
    },
    {
        "engDate": "1942-05-04",
        "nepDate": "22.01.1999"
    },
    {
        "engDate": "1942-05-05",
        "nepDate": "23.01.1999"
    },
    {
        "engDate": "1942-05-06",
        "nepDate": "24.01.1999"
    },
    {
        "engDate": "1942-05-07",
        "nepDate": "25.01.1999"
    },
    {
        "engDate": "1942-05-08",
        "nepDate": "26.01.1999"
    },
    {
        "engDate": "1942-05-09",
        "nepDate": "27.01.1999"
    },
    {
        "engDate": "1942-05-10",
        "nepDate": "28.01.1999"
    },
    {
        "engDate": "1942-05-11",
        "nepDate": "29.01.1999"
    },
    {
        "engDate": "1942-05-12",
        "nepDate": "30.01.1999"
    },
    {
        "engDate": "1942-05-13",
        "nepDate": "31.01.1999"
    },
    {
        "engDate": "1942-05-14",
        "nepDate": "01.02.1999"
    },
    {
        "engDate": "1942-05-15",
        "nepDate": "02.02.1999"
    },
    {
        "engDate": "1942-05-16",
        "nepDate": "03.02.1999"
    },
    {
        "engDate": "1942-05-17",
        "nepDate": "04.02.1999"
    },
    {
        "engDate": "1942-05-18",
        "nepDate": "05.02.1999"
    },
    {
        "engDate": "1942-05-19",
        "nepDate": "06.02.1999"
    },
    {
        "engDate": "1942-05-20",
        "nepDate": "07.02.1999"
    },
    {
        "engDate": "1942-05-21",
        "nepDate": "08.02.1999"
    },
    {
        "engDate": "1942-05-22",
        "nepDate": "09.02.1999"
    },
    {
        "engDate": "1942-05-23",
        "nepDate": "10.02.1999"
    },
    {
        "engDate": "1942-05-24",
        "nepDate": "11.02.1999"
    },
    {
        "engDate": "1942-05-25",
        "nepDate": "12.02.1999"
    },
    {
        "engDate": "1942-05-26",
        "nepDate": "13.02.1999"
    },
    {
        "engDate": "1942-05-27",
        "nepDate": "14.02.1999"
    },
    {
        "engDate": "1942-05-28",
        "nepDate": "15.02.1999"
    },
    {
        "engDate": "1942-05-29",
        "nepDate": "16.02.1999"
    },
    {
        "engDate": "1942-05-30",
        "nepDate": "17.02.1999"
    },
    {
        "engDate": "1942-05-31",
        "nepDate": "18.02.1999"
    },
    {
        "engDate": "1942-06-01",
        "nepDate": "19.02.1999"
    },
    {
        "engDate": "1942-06-02",
        "nepDate": "20.02.1999"
    },
    {
        "engDate": "1942-06-03",
        "nepDate": "21.02.1999"
    },
    {
        "engDate": "1942-06-04",
        "nepDate": "22.02.1999"
    },
    {
        "engDate": "1942-06-05",
        "nepDate": "23.02.1999"
    },
    {
        "engDate": "1942-06-06",
        "nepDate": "24.02.1999"
    },
    {
        "engDate": "1942-06-07",
        "nepDate": "25.02.1999"
    },
    {
        "engDate": "1942-06-08",
        "nepDate": "26.02.1999"
    },
    {
        "engDate": "1942-06-09",
        "nepDate": "27.02.1999"
    },
    {
        "engDate": "1942-06-10",
        "nepDate": "28.02.1999"
    },
    {
        "engDate": "1942-06-11",
        "nepDate": "29.02.1999"
    },
    {
        "engDate": "1942-06-12",
        "nepDate": "30.02.1999"
    },
    {
        "engDate": "1942-06-13",
        "nepDate": "31.02.1999"
    },
    {
        "engDate": "1942-06-14",
        "nepDate": "32.02.1999"
    },
    {
        "engDate": "1942-06-15",
        "nepDate": "01.03.1999"
    },
    {
        "engDate": "1942-06-16",
        "nepDate": "02.03.1999"
    },
    {
        "engDate": "1942-06-17",
        "nepDate": "03.03.1999"
    },
    {
        "engDate": "1942-06-18",
        "nepDate": "04.03.1999"
    },
    {
        "engDate": "1942-06-19",
        "nepDate": "05.03.1999"
    },
    {
        "engDate": "1942-06-20",
        "nepDate": "06.03.1999"
    },
    {
        "engDate": "1942-06-21",
        "nepDate": "07.03.1999"
    },
    {
        "engDate": "1942-06-22",
        "nepDate": "08.03.1999"
    },
    {
        "engDate": "1942-06-23",
        "nepDate": "09.03.1999"
    },
    {
        "engDate": "1942-06-24",
        "nepDate": "10.03.1999"
    },
    {
        "engDate": "1942-06-25",
        "nepDate": "11.03.1999"
    },
    {
        "engDate": "1942-06-26",
        "nepDate": "12.03.1999"
    },
    {
        "engDate": "1942-06-27",
        "nepDate": "13.03.1999"
    },
    {
        "engDate": "1942-06-28",
        "nepDate": "14.03.1999"
    },
    {
        "engDate": "1942-06-29",
        "nepDate": "15.03.1999"
    },
    {
        "engDate": "1942-06-30",
        "nepDate": "16.03.1999"
    },
    {
        "engDate": "1942-07-01",
        "nepDate": "17.03.1999"
    },
    {
        "engDate": "1942-07-02",
        "nepDate": "18.03.1999"
    },
    {
        "engDate": "1942-07-03",
        "nepDate": "19.03.1999"
    },
    {
        "engDate": "1942-07-04",
        "nepDate": "20.03.1999"
    },
    {
        "engDate": "1942-07-05",
        "nepDate": "21.03.1999"
    },
    {
        "engDate": "1942-07-06",
        "nepDate": "22.03.1999"
    },
    {
        "engDate": "1942-07-07",
        "nepDate": "23.03.1999"
    },
    {
        "engDate": "1942-07-08",
        "nepDate": "24.03.1999"
    },
    {
        "engDate": "1942-07-09",
        "nepDate": "25.03.1999"
    },
    {
        "engDate": "1942-07-10",
        "nepDate": "26.03.1999"
    },
    {
        "engDate": "1942-07-11",
        "nepDate": "27.03.1999"
    },
    {
        "engDate": "1942-07-12",
        "nepDate": "28.03.1999"
    },
    {
        "engDate": "1942-07-13",
        "nepDate": "29.03.1999"
    },
    {
        "engDate": "1942-07-14",
        "nepDate": "30.03.1999"
    },
    {
        "engDate": "1942-07-15",
        "nepDate": "31.03.1999"
    },
    {
        "engDate": "1942-07-16",
        "nepDate": "01.04.1999"
    },
    {
        "engDate": "1942-07-17",
        "nepDate": "02.04.1999"
    },
    {
        "engDate": "1942-07-18",
        "nepDate": "03.04.1999"
    },
    {
        "engDate": "1942-07-19",
        "nepDate": "04.04.1999"
    },
    {
        "engDate": "1942-07-20",
        "nepDate": "05.04.1999"
    },
    {
        "engDate": "1942-07-21",
        "nepDate": "06.04.1999"
    },
    {
        "engDate": "1942-07-22",
        "nepDate": "07.04.1999"
    },
    {
        "engDate": "1942-07-23",
        "nepDate": "08.04.1999"
    },
    {
        "engDate": "1942-07-24",
        "nepDate": "09.04.1999"
    },
    {
        "engDate": "1942-07-25",
        "nepDate": "10.04.1999"
    },
    {
        "engDate": "1942-07-26",
        "nepDate": "11.04.1999"
    },
    {
        "engDate": "1942-07-27",
        "nepDate": "12.04.1999"
    },
    {
        "engDate": "1942-07-28",
        "nepDate": "13.04.1999"
    },
    {
        "engDate": "1942-07-29",
        "nepDate": "14.04.1999"
    },
    {
        "engDate": "1942-07-30",
        "nepDate": "15.04.1999"
    },
    {
        "engDate": "1942-07-31",
        "nepDate": "16.04.1999"
    },
    {
        "engDate": "1942-08-01",
        "nepDate": "17.04.1999"
    },
    {
        "engDate": "1942-08-02",
        "nepDate": "18.04.1999"
    },
    {
        "engDate": "1942-08-03",
        "nepDate": "19.04.1999"
    },
    {
        "engDate": "1942-08-04",
        "nepDate": "20.04.1999"
    },
    {
        "engDate": "1942-08-05",
        "nepDate": "21.04.1999"
    },
    {
        "engDate": "1942-08-06",
        "nepDate": "22.04.1999"
    },
    {
        "engDate": "1942-08-07",
        "nepDate": "23.04.1999"
    },
    {
        "engDate": "1942-08-08",
        "nepDate": "24.04.1999"
    },
    {
        "engDate": "1942-08-09",
        "nepDate": "25.04.1999"
    },
    {
        "engDate": "1942-08-10",
        "nepDate": "26.04.1999"
    },
    {
        "engDate": "1942-08-11",
        "nepDate": "27.04.1999"
    },
    {
        "engDate": "1942-08-12",
        "nepDate": "28.04.1999"
    },
    {
        "engDate": "1942-08-13",
        "nepDate": "29.04.1999"
    },
    {
        "engDate": "1942-08-14",
        "nepDate": "30.04.1999"
    },
    {
        "engDate": "1942-08-15",
        "nepDate": "31.04.1999"
    },
    {
        "engDate": "1942-08-16",
        "nepDate": "32.04.1999"
    },
    {
        "engDate": "1942-08-17",
        "nepDate": "01.05.1999"
    },
    {
        "engDate": "1942-08-18",
        "nepDate": "02.05.1999"
    },
    {
        "engDate": "1942-08-19",
        "nepDate": "03.05.1999"
    },
    {
        "engDate": "1942-08-20",
        "nepDate": "04.05.1999"
    },
    {
        "engDate": "1942-08-21",
        "nepDate": "05.05.1999"
    },
    {
        "engDate": "1942-08-22",
        "nepDate": "06.05.1999"
    },
    {
        "engDate": "1942-08-23",
        "nepDate": "07.05.1999"
    },
    {
        "engDate": "1942-08-24",
        "nepDate": "08.05.1999"
    },
    {
        "engDate": "1942-08-25",
        "nepDate": "09.05.1999"
    },
    {
        "engDate": "1942-08-26",
        "nepDate": "10.05.1999"
    },
    {
        "engDate": "1942-08-27",
        "nepDate": "11.05.1999"
    },
    {
        "engDate": "1942-08-28",
        "nepDate": "12.05.1999"
    },
    {
        "engDate": "1942-08-29",
        "nepDate": "13.05.1999"
    },
    {
        "engDate": "1942-08-30",
        "nepDate": "14.05.1999"
    },
    {
        "engDate": "1942-08-31",
        "nepDate": "15.05.1999"
    },
    {
        "engDate": "1942-09-01",
        "nepDate": "16.05.1999"
    },
    {
        "engDate": "1942-09-02",
        "nepDate": "17.05.1999"
    },
    {
        "engDate": "1942-09-03",
        "nepDate": "18.05.1999"
    },
    {
        "engDate": "1942-09-04",
        "nepDate": "19.05.1999"
    },
    {
        "engDate": "1942-09-05",
        "nepDate": "20.05.1999"
    },
    {
        "engDate": "1942-09-06",
        "nepDate": "21.05.1999"
    },
    {
        "engDate": "1942-09-07",
        "nepDate": "22.05.1999"
    },
    {
        "engDate": "1942-09-08",
        "nepDate": "23.05.1999"
    },
    {
        "engDate": "1942-09-09",
        "nepDate": "24.05.1999"
    },
    {
        "engDate": "1942-09-10",
        "nepDate": "25.05.1999"
    },
    {
        "engDate": "1942-09-11",
        "nepDate": "26.05.1999"
    },
    {
        "engDate": "1942-09-12",
        "nepDate": "27.05.1999"
    },
    {
        "engDate": "1942-09-13",
        "nepDate": "28.05.1999"
    },
    {
        "engDate": "1942-09-14",
        "nepDate": "29.05.1999"
    },
    {
        "engDate": "1942-09-15",
        "nepDate": "30.05.1999"
    },
    {
        "engDate": "1942-09-16",
        "nepDate": "31.05.1999"
    },
    {
        "engDate": "1942-09-17",
        "nepDate": "01.06.1999"
    },
    {
        "engDate": "1942-09-18",
        "nepDate": "02.06.1999"
    },
    {
        "engDate": "1942-09-19",
        "nepDate": "03.06.1999"
    },
    {
        "engDate": "1942-09-20",
        "nepDate": "04.06.1999"
    },
    {
        "engDate": "1942-09-21",
        "nepDate": "05.06.1999"
    },
    {
        "engDate": "1942-09-22",
        "nepDate": "06.06.1999"
    },
    {
        "engDate": "1942-09-23",
        "nepDate": "07.06.1999"
    },
    {
        "engDate": "1942-09-24",
        "nepDate": "08.06.1999"
    },
    {
        "engDate": "1942-09-25",
        "nepDate": "09.06.1999"
    },
    {
        "engDate": "1942-09-26",
        "nepDate": "10.06.1999"
    },
    {
        "engDate": "1942-09-27",
        "nepDate": "11.06.1999"
    },
    {
        "engDate": "1942-09-28",
        "nepDate": "12.06.1999"
    },
    {
        "engDate": "1942-09-29",
        "nepDate": "13.06.1999"
    },
    {
        "engDate": "1942-09-30",
        "nepDate": "14.06.1999"
    },
    {
        "engDate": "1942-10-01",
        "nepDate": "15.06.1999"
    },
    {
        "engDate": "1942-10-02",
        "nepDate": "16.06.1999"
    },
    {
        "engDate": "1942-10-03",
        "nepDate": "17.06.1999"
    },
    {
        "engDate": "1942-10-04",
        "nepDate": "18.06.1999"
    },
    {
        "engDate": "1942-10-05",
        "nepDate": "19.06.1999"
    },
    {
        "engDate": "1942-10-06",
        "nepDate": "20.06.1999"
    },
    {
        "engDate": "1942-10-07",
        "nepDate": "21.06.1999"
    },
    {
        "engDate": "1942-10-08",
        "nepDate": "22.06.1999"
    },
    {
        "engDate": "1942-10-09",
        "nepDate": "23.06.1999"
    },
    {
        "engDate": "1942-10-10",
        "nepDate": "24.06.1999"
    },
    {
        "engDate": "1942-10-11",
        "nepDate": "25.06.1999"
    },
    {
        "engDate": "1942-10-12",
        "nepDate": "26.06.1999"
    },
    {
        "engDate": "1942-10-13",
        "nepDate": "27.06.1999"
    },
    {
        "engDate": "1942-10-14",
        "nepDate": "28.06.1999"
    },
    {
        "engDate": "1942-10-15",
        "nepDate": "29.06.1999"
    },
    {
        "engDate": "1942-10-16",
        "nepDate": "30.06.1999"
    },
    {
        "engDate": "1942-10-17",
        "nepDate": "01.07.1999"
    },
    {
        "engDate": "1942-10-18",
        "nepDate": "02.07.1999"
    },
    {
        "engDate": "1942-10-19",
        "nepDate": "03.07.1999"
    },
    {
        "engDate": "1942-10-20",
        "nepDate": "04.07.1999"
    },
    {
        "engDate": "1942-10-21",
        "nepDate": "05.07.1999"
    },
    {
        "engDate": "1942-10-22",
        "nepDate": "06.07.1999"
    },
    {
        "engDate": "1942-10-23",
        "nepDate": "07.07.1999"
    },
    {
        "engDate": "1942-10-24",
        "nepDate": "08.07.1999"
    },
    {
        "engDate": "1942-10-25",
        "nepDate": "09.07.1999"
    },
    {
        "engDate": "1942-10-26",
        "nepDate": "10.07.1999"
    },
    {
        "engDate": "1942-10-27",
        "nepDate": "11.07.1999"
    },
    {
        "engDate": "1942-10-28",
        "nepDate": "12.07.1999"
    },
    {
        "engDate": "1942-10-29",
        "nepDate": "13.07.1999"
    },
    {
        "engDate": "1942-10-30",
        "nepDate": "14.07.1999"
    },
    {
        "engDate": "1942-10-31",
        "nepDate": "15.07.1999"
    },
    {
        "engDate": "1942-11-01",
        "nepDate": "16.07.1999"
    },
    {
        "engDate": "1942-11-02",
        "nepDate": "17.07.1999"
    },
    {
        "engDate": "1942-11-03",
        "nepDate": "18.07.1999"
    },
    {
        "engDate": "1942-11-04",
        "nepDate": "19.07.1999"
    },
    {
        "engDate": "1942-11-05",
        "nepDate": "20.07.1999"
    },
    {
        "engDate": "1942-11-06",
        "nepDate": "21.07.1999"
    },
    {
        "engDate": "1942-11-07",
        "nepDate": "22.07.1999"
    },
    {
        "engDate": "1942-11-08",
        "nepDate": "23.07.1999"
    },
    {
        "engDate": "1942-11-09",
        "nepDate": "24.07.1999"
    },
    {
        "engDate": "1942-11-10",
        "nepDate": "25.07.1999"
    },
    {
        "engDate": "1942-11-11",
        "nepDate": "26.07.1999"
    },
    {
        "engDate": "1942-11-12",
        "nepDate": "27.07.1999"
    },
    {
        "engDate": "1942-11-13",
        "nepDate": "28.07.1999"
    },
    {
        "engDate": "1942-11-14",
        "nepDate": "29.07.1999"
    },
    {
        "engDate": "1942-11-15",
        "nepDate": "30.07.1999"
    },
    {
        "engDate": "1942-11-16",
        "nepDate": "01.08.1999"
    },
    {
        "engDate": "1942-11-17",
        "nepDate": "02.08.1999"
    },
    {
        "engDate": "1942-11-18",
        "nepDate": "03.08.1999"
    },
    {
        "engDate": "1942-11-19",
        "nepDate": "04.08.1999"
    },
    {
        "engDate": "1942-11-20",
        "nepDate": "05.08.1999"
    },
    {
        "engDate": "1942-11-21",
        "nepDate": "06.08.1999"
    },
    {
        "engDate": "1942-11-22",
        "nepDate": "07.08.1999"
    },
    {
        "engDate": "1942-11-23",
        "nepDate": "08.08.1999"
    },
    {
        "engDate": "1942-11-24",
        "nepDate": "09.08.1999"
    },
    {
        "engDate": "1942-11-25",
        "nepDate": "10.08.1999"
    },
    {
        "engDate": "1942-11-26",
        "nepDate": "11.08.1999"
    },
    {
        "engDate": "1942-11-27",
        "nepDate": "12.08.1999"
    },
    {
        "engDate": "1942-11-28",
        "nepDate": "13.08.1999"
    },
    {
        "engDate": "1942-11-29",
        "nepDate": "14.08.1999"
    },
    {
        "engDate": "1942-11-30",
        "nepDate": "15.08.1999"
    },
    {
        "engDate": "1942-12-01",
        "nepDate": "16.08.1999"
    },
    {
        "engDate": "1942-12-02",
        "nepDate": "17.08.1999"
    },
    {
        "engDate": "1942-12-03",
        "nepDate": "18.08.1999"
    },
    {
        "engDate": "1942-12-04",
        "nepDate": "19.08.1999"
    },
    {
        "engDate": "1942-12-05",
        "nepDate": "20.08.1999"
    },
    {
        "engDate": "1942-12-06",
        "nepDate": "21.08.1999"
    },
    {
        "engDate": "1942-12-07",
        "nepDate": "22.08.1999"
    },
    {
        "engDate": "1942-12-08",
        "nepDate": "23.08.1999"
    },
    {
        "engDate": "1942-12-09",
        "nepDate": "24.08.1999"
    },
    {
        "engDate": "1942-12-10",
        "nepDate": "25.08.1999"
    },
    {
        "engDate": "1942-12-11",
        "nepDate": "26.08.1999"
    },
    {
        "engDate": "1942-12-12",
        "nepDate": "27.08.1999"
    },
    {
        "engDate": "1942-12-13",
        "nepDate": "28.08.1999"
    },
    {
        "engDate": "1942-12-14",
        "nepDate": "29.08.1999"
    },
    {
        "engDate": "1942-12-15",
        "nepDate": "30.08.1999"
    },
    {
        "engDate": "1942-12-16",
        "nepDate": "01.09.1999"
    },
    {
        "engDate": "1942-12-17",
        "nepDate": "02.09.1999"
    },
    {
        "engDate": "1942-12-18",
        "nepDate": "03.09.1999"
    },
    {
        "engDate": "1942-12-19",
        "nepDate": "04.09.1999"
    },
    {
        "engDate": "1942-12-20",
        "nepDate": "05.09.1999"
    },
    {
        "engDate": "1942-12-21",
        "nepDate": "06.09.1999"
    },
    {
        "engDate": "1942-12-22",
        "nepDate": "07.09.1999"
    },
    {
        "engDate": "1942-12-23",
        "nepDate": "08.09.1999"
    },
    {
        "engDate": "1942-12-24",
        "nepDate": "09.09.1999"
    },
    {
        "engDate": "1942-12-25",
        "nepDate": "10.09.1999"
    },
    {
        "engDate": "1942-12-26",
        "nepDate": "11.09.1999"
    },
    {
        "engDate": "1942-12-27",
        "nepDate": "12.09.1999"
    },
    {
        "engDate": "1942-12-28",
        "nepDate": "13.09.1999"
    },
    {
        "engDate": "1942-12-29",
        "nepDate": "14.09.1999"
    },
    {
        "engDate": "1942-12-30",
        "nepDate": "15.09.1999"
    },
    {
        "engDate": "1942-12-31",
        "nepDate": "16.09.1999"
    },
    {
        "engDate": "1943-01-01",
        "nepDate": "17.09.1999"
    },
    {
        "engDate": "1943-01-02",
        "nepDate": "18.09.1999"
    },
    {
        "engDate": "1943-01-03",
        "nepDate": "19.09.1999"
    },
    {
        "engDate": "1943-01-04",
        "nepDate": "20.09.1999"
    },
    {
        "engDate": "1943-01-05",
        "nepDate": "21.09.1999"
    },
    {
        "engDate": "1943-01-06",
        "nepDate": "22.09.1999"
    },
    {
        "engDate": "1943-01-07",
        "nepDate": "23.09.1999"
    },
    {
        "engDate": "1943-01-08",
        "nepDate": "24.09.1999"
    },
    {
        "engDate": "1943-01-09",
        "nepDate": "25.09.1999"
    },
    {
        "engDate": "1943-01-10",
        "nepDate": "26.09.1999"
    },
    {
        "engDate": "1943-01-11",
        "nepDate": "27.09.1999"
    },
    {
        "engDate": "1943-01-12",
        "nepDate": "28.09.1999"
    },
    {
        "engDate": "1943-01-13",
        "nepDate": "29.09.1999"
    },
    {
        "engDate": "1943-01-14",
        "nepDate": "01.10.1999"
    },
    {
        "engDate": "1943-01-15",
        "nepDate": "02.10.1999"
    },
    {
        "engDate": "1943-01-16",
        "nepDate": "03.10.1999"
    },
    {
        "engDate": "1943-01-17",
        "nepDate": "04.10.1999"
    },
    {
        "engDate": "1943-01-18",
        "nepDate": "05.10.1999"
    },
    {
        "engDate": "1943-01-19",
        "nepDate": "06.10.1999"
    },
    {
        "engDate": "1943-01-20",
        "nepDate": "07.10.1999"
    },
    {
        "engDate": "1943-01-21",
        "nepDate": "08.10.1999"
    },
    {
        "engDate": "1943-01-22",
        "nepDate": "09.10.1999"
    },
    {
        "engDate": "1943-01-23",
        "nepDate": "10.10.1999"
    },
    {
        "engDate": "1943-01-24",
        "nepDate": "11.10.1999"
    },
    {
        "engDate": "1943-01-25",
        "nepDate": "12.10.1999"
    },
    {
        "engDate": "1943-01-26",
        "nepDate": "13.10.1999"
    },
    {
        "engDate": "1943-01-27",
        "nepDate": "14.10.1999"
    },
    {
        "engDate": "1943-01-28",
        "nepDate": "15.10.1999"
    },
    {
        "engDate": "1943-01-29",
        "nepDate": "16.10.1999"
    },
    {
        "engDate": "1943-01-30",
        "nepDate": "17.10.1999"
    },
    {
        "engDate": "1943-01-31",
        "nepDate": "18.10.1999"
    },
    {
        "engDate": "1943-02-01",
        "nepDate": "19.10.1999"
    },
    {
        "engDate": "1943-02-02",
        "nepDate": "20.10.1999"
    },
    {
        "engDate": "1943-02-03",
        "nepDate": "21.10.1999"
    },
    {
        "engDate": "1943-02-04",
        "nepDate": "22.10.1999"
    },
    {
        "engDate": "1943-02-05",
        "nepDate": "23.10.1999"
    },
    {
        "engDate": "1943-02-06",
        "nepDate": "24.10.1999"
    },
    {
        "engDate": "1943-02-07",
        "nepDate": "25.10.1999"
    },
    {
        "engDate": "1943-02-08",
        "nepDate": "26.10.1999"
    },
    {
        "engDate": "1943-02-09",
        "nepDate": "27.10.1999"
    },
    {
        "engDate": "1943-02-10",
        "nepDate": "28.10.1999"
    },
    {
        "engDate": "1943-02-11",
        "nepDate": "29.10.1999"
    },
    {
        "engDate": "1943-02-12",
        "nepDate": "01.11.1999"
    },
    {
        "engDate": "1943-02-13",
        "nepDate": "02.11.1999"
    },
    {
        "engDate": "1943-02-14",
        "nepDate": "03.11.1999"
    },
    {
        "engDate": "1943-02-15",
        "nepDate": "04.11.1999"
    },
    {
        "engDate": "1943-02-16",
        "nepDate": "05.11.1999"
    },
    {
        "engDate": "1943-02-17",
        "nepDate": "06.11.1999"
    },
    {
        "engDate": "1943-02-18",
        "nepDate": "07.11.1999"
    },
    {
        "engDate": "1943-02-19",
        "nepDate": "08.11.1999"
    },
    {
        "engDate": "1943-02-20",
        "nepDate": "09.11.1999"
    },
    {
        "engDate": "1943-02-21",
        "nepDate": "10.11.1999"
    },
    {
        "engDate": "1943-02-22",
        "nepDate": "11.11.1999"
    },
    {
        "engDate": "1943-02-23",
        "nepDate": "12.11.1999"
    },
    {
        "engDate": "1943-02-24",
        "nepDate": "13.11.1999"
    },
    {
        "engDate": "1943-02-25",
        "nepDate": "14.11.1999"
    },
    {
        "engDate": "1943-02-26",
        "nepDate": "15.11.1999"
    },
    {
        "engDate": "1943-02-27",
        "nepDate": "16.11.1999"
    },
    {
        "engDate": "1943-02-28",
        "nepDate": "17.11.1999"
    },
    {
        "engDate": "1943-03-01",
        "nepDate": "18.11.1999"
    },
    {
        "engDate": "1943-03-02",
        "nepDate": "19.11.1999"
    },
    {
        "engDate": "1943-03-03",
        "nepDate": "20.11.1999"
    },
    {
        "engDate": "1943-03-04",
        "nepDate": "21.11.1999"
    },
    {
        "engDate": "1943-03-05",
        "nepDate": "22.11.1999"
    },
    {
        "engDate": "1943-03-06",
        "nepDate": "23.11.1999"
    },
    {
        "engDate": "1943-03-07",
        "nepDate": "24.11.1999"
    },
    {
        "engDate": "1943-03-08",
        "nepDate": "25.11.1999"
    },
    {
        "engDate": "1943-03-09",
        "nepDate": "26.11.1999"
    },
    {
        "engDate": "1943-03-10",
        "nepDate": "27.11.1999"
    },
    {
        "engDate": "1943-03-11",
        "nepDate": "28.11.1999"
    },
    {
        "engDate": "1943-03-12",
        "nepDate": "29.11.1999"
    },
    {
        "engDate": "1943-03-13",
        "nepDate": "30.11.1999"
    },
    {
        "engDate": "1943-03-14",
        "nepDate": "01.12.1999"
    },
    {
        "engDate": "1943-03-15",
        "nepDate": "02.12.1999"
    },
    {
        "engDate": "1943-03-16",
        "nepDate": "03.12.1999"
    },
    {
        "engDate": "1943-03-17",
        "nepDate": "04.12.1999"
    },
    {
        "engDate": "1943-03-18",
        "nepDate": "05.12.1999"
    },
    {
        "engDate": "1943-03-19",
        "nepDate": "06.12.1999"
    },
    {
        "engDate": "1943-03-20",
        "nepDate": "07.12.1999"
    },
    {
        "engDate": "1943-03-21",
        "nepDate": "08.12.1999"
    },
    {
        "engDate": "1943-03-22",
        "nepDate": "09.12.1999"
    },
    {
        "engDate": "1943-03-23",
        "nepDate": "10.12.1999"
    },
    {
        "engDate": "1943-03-24",
        "nepDate": "11.12.1999"
    },
    {
        "engDate": "1943-03-25",
        "nepDate": "12.12.1999"
    },
    {
        "engDate": "1943-03-26",
        "nepDate": "13.12.1999"
    },
    {
        "engDate": "1943-03-27",
        "nepDate": "14.12.1999"
    },
    {
        "engDate": "1943-03-28",
        "nepDate": "15.12.1999"
    },
    {
        "engDate": "1943-03-29",
        "nepDate": "16.12.1999"
    },
    {
        "engDate": "1943-03-30",
        "nepDate": "17.12.1999"
    },
    {
        "engDate": "1943-03-31",
        "nepDate": "18.12.1999"
    },
    {
        "engDate": "1943-04-01",
        "nepDate": "19.12.1999"
    },
    {
        "engDate": "1943-04-02",
        "nepDate": "20.12.1999"
    },
    {
        "engDate": "1943-04-03",
        "nepDate": "21.12.1999"
    },
    {
        "engDate": "1943-04-04",
        "nepDate": "22.12.1999"
    },
    {
        "engDate": "1943-04-05",
        "nepDate": "23.12.1999"
    },
    {
        "engDate": "1943-04-06",
        "nepDate": "24.12.1999"
    },
    {
        "engDate": "1943-04-07",
        "nepDate": "25.12.1999"
    },
    {
        "engDate": "1943-04-08",
        "nepDate": "26.12.1999"
    },
    {
        "engDate": "1943-04-09",
        "nepDate": "27.12.1999"
    },
    {
        "engDate": "1943-04-10",
        "nepDate": "28.12.1999"
    },
    {
        "engDate": "1943-04-11",
        "nepDate": "29.12.1999"
    },
    {
        "engDate": "1943-04-12",
        "nepDate": "30.12.1999"
    },
    {
        "engDate": "1943-04-13",
        "nepDate": "31.12.1999"
    },
    {
        "engDate": "1943-04-14",
        "nepDate": "01.01.2000"
    },
    {
        "engDate": "1943-04-15",
        "nepDate": "02.01.2000"
    },
    {
        "engDate": "1943-04-16",
        "nepDate": "03.01.2000"
    },
    {
        "engDate": "1943-04-17",
        "nepDate": "04.01.2000"
    },
    {
        "engDate": "1943-04-18",
        "nepDate": "05.01.2000"
    },
    {
        "engDate": "1943-04-19",
        "nepDate": "06.01.2000"
    },
    {
        "engDate": "1943-04-20",
        "nepDate": "07.01.2000"
    },
    {
        "engDate": "1943-04-21",
        "nepDate": "08.01.2000"
    },
    {
        "engDate": "1943-04-22",
        "nepDate": "09.01.2000"
    },
    {
        "engDate": "1943-04-23",
        "nepDate": "10.01.2000"
    },
    {
        "engDate": "1943-04-24",
        "nepDate": "11.01.2000"
    },
    {
        "engDate": "1943-04-25",
        "nepDate": "12.01.2000"
    },
    {
        "engDate": "1943-04-26",
        "nepDate": "13.01.2000"
    },
    {
        "engDate": "1943-04-27",
        "nepDate": "14.01.2000"
    },
    {
        "engDate": "1943-04-28",
        "nepDate": "15.01.2000"
    },
    {
        "engDate": "1943-04-29",
        "nepDate": "16.01.2000"
    },
    {
        "engDate": "1943-04-30",
        "nepDate": "17.01.2000"
    },
    {
        "engDate": "1943-05-01",
        "nepDate": "18.01.2000"
    },
    {
        "engDate": "1943-05-02",
        "nepDate": "19.01.2000"
    },
    {
        "engDate": "1943-05-03",
        "nepDate": "20.01.2000"
    },
    {
        "engDate": "1943-05-04",
        "nepDate": "21.01.2000"
    },
    {
        "engDate": "1943-05-05",
        "nepDate": "22.01.2000"
    },
    {
        "engDate": "1943-05-06",
        "nepDate": "23.01.2000"
    },
    {
        "engDate": "1943-05-07",
        "nepDate": "24.01.2000"
    },
    {
        "engDate": "1943-05-08",
        "nepDate": "25.01.2000"
    },
    {
        "engDate": "1943-05-09",
        "nepDate": "26.01.2000"
    },
    {
        "engDate": "1943-05-10",
        "nepDate": "27.01.2000"
    },
    {
        "engDate": "1943-05-11",
        "nepDate": "28.01.2000"
    },
    {
        "engDate": "1943-05-12",
        "nepDate": "29.01.2000"
    },
    {
        "engDate": "1943-05-13",
        "nepDate": "30.01.2000"
    },
    {
        "engDate": "1943-05-14",
        "nepDate": "01.02.2000"
    },
    {
        "engDate": "1943-05-15",
        "nepDate": "02.02.2000"
    },
    {
        "engDate": "1943-05-16",
        "nepDate": "03.02.2000"
    },
    {
        "engDate": "1943-05-17",
        "nepDate": "04.02.2000"
    },
    {
        "engDate": "1943-05-18",
        "nepDate": "05.02.2000"
    },
    {
        "engDate": "1943-05-19",
        "nepDate": "06.02.2000"
    },
    {
        "engDate": "1943-05-20",
        "nepDate": "07.02.2000"
    },
    {
        "engDate": "1943-05-21",
        "nepDate": "08.02.2000"
    },
    {
        "engDate": "1943-05-22",
        "nepDate": "09.02.2000"
    },
    {
        "engDate": "1943-05-23",
        "nepDate": "10.02.2000"
    },
    {
        "engDate": "1943-05-24",
        "nepDate": "11.02.2000"
    },
    {
        "engDate": "1943-05-25",
        "nepDate": "12.02.2000"
    },
    {
        "engDate": "1943-05-26",
        "nepDate": "13.02.2000"
    },
    {
        "engDate": "1943-05-27",
        "nepDate": "14.02.2000"
    },
    {
        "engDate": "1943-05-28",
        "nepDate": "15.02.2000"
    },
    {
        "engDate": "1943-05-29",
        "nepDate": "16.02.2000"
    },
    {
        "engDate": "1943-05-30",
        "nepDate": "17.02.2000"
    },
    {
        "engDate": "1943-05-31",
        "nepDate": "18.02.2000"
    },
    {
        "engDate": "1943-06-01",
        "nepDate": "19.02.2000"
    },
    {
        "engDate": "1943-06-02",
        "nepDate": "20.02.2000"
    },
    {
        "engDate": "1943-06-03",
        "nepDate": "21.02.2000"
    },
    {
        "engDate": "1943-06-04",
        "nepDate": "22.02.2000"
    },
    {
        "engDate": "1943-06-05",
        "nepDate": "23.02.2000"
    },
    {
        "engDate": "1943-06-06",
        "nepDate": "24.02.2000"
    },
    {
        "engDate": "1943-06-07",
        "nepDate": "25.02.2000"
    },
    {
        "engDate": "1943-06-08",
        "nepDate": "26.02.2000"
    },
    {
        "engDate": "1943-06-09",
        "nepDate": "27.02.2000"
    },
    {
        "engDate": "1943-06-10",
        "nepDate": "28.02.2000"
    },
    {
        "engDate": "1943-06-11",
        "nepDate": "29.02.2000"
    },
    {
        "engDate": "1943-06-12",
        "nepDate": "30.02.2000"
    },
    {
        "engDate": "1943-06-13",
        "nepDate": "31.02.2000"
    },
    {
        "engDate": "1943-06-14",
        "nepDate": "32.02.2000"
    },
    {
        "engDate": "1943-06-15",
        "nepDate": "01.03.2000"
    },
    {
        "engDate": "1943-06-16",
        "nepDate": "02.03.2000"
    },
    {
        "engDate": "1943-06-17",
        "nepDate": "03.03.2000"
    },
    {
        "engDate": "1943-06-18",
        "nepDate": "04.03.2000"
    },
    {
        "engDate": "1943-06-19",
        "nepDate": "05.03.2000"
    },
    {
        "engDate": "1943-06-20",
        "nepDate": "06.03.2000"
    },
    {
        "engDate": "1943-06-21",
        "nepDate": "07.03.2000"
    },
    {
        "engDate": "1943-06-22",
        "nepDate": "08.03.2000"
    },
    {
        "engDate": "1943-06-23",
        "nepDate": "09.03.2000"
    },
    {
        "engDate": "1943-06-24",
        "nepDate": "10.03.2000"
    },
    {
        "engDate": "1943-06-25",
        "nepDate": "11.03.2000"
    },
    {
        "engDate": "1943-06-26",
        "nepDate": "12.03.2000"
    },
    {
        "engDate": "1943-06-27",
        "nepDate": "13.03.2000"
    },
    {
        "engDate": "1943-06-28",
        "nepDate": "14.03.2000"
    },
    {
        "engDate": "1943-06-29",
        "nepDate": "15.03.2000"
    },
    {
        "engDate": "1943-06-30",
        "nepDate": "16.03.2000"
    },
    {
        "engDate": "1943-07-01",
        "nepDate": "17.03.2000"
    },
    {
        "engDate": "1943-07-02",
        "nepDate": "18.03.2000"
    },
    {
        "engDate": "1943-07-03",
        "nepDate": "19.03.2000"
    },
    {
        "engDate": "1943-07-04",
        "nepDate": "20.03.2000"
    },
    {
        "engDate": "1943-07-05",
        "nepDate": "21.03.2000"
    },
    {
        "engDate": "1943-07-06",
        "nepDate": "22.03.2000"
    },
    {
        "engDate": "1943-07-07",
        "nepDate": "23.03.2000"
    },
    {
        "engDate": "1943-07-08",
        "nepDate": "24.03.2000"
    },
    {
        "engDate": "1943-07-09",
        "nepDate": "25.03.2000"
    },
    {
        "engDate": "1943-07-10",
        "nepDate": "26.03.2000"
    },
    {
        "engDate": "1943-07-11",
        "nepDate": "27.03.2000"
    },
    {
        "engDate": "1943-07-12",
        "nepDate": "28.03.2000"
    },
    {
        "engDate": "1943-07-13",
        "nepDate": "29.03.2000"
    },
    {
        "engDate": "1943-07-14",
        "nepDate": "30.03.2000"
    },
    {
        "engDate": "1943-07-15",
        "nepDate": "31.03.2000"
    },
    {
        "engDate": "1943-07-16",
        "nepDate": "01.04.2000"
    },
    {
        "engDate": "1943-07-17",
        "nepDate": "02.04.2000"
    },
    {
        "engDate": "1943-07-18",
        "nepDate": "03.04.2000"
    },
    {
        "engDate": "1943-07-19",
        "nepDate": "04.04.2000"
    },
    {
        "engDate": "1943-07-20",
        "nepDate": "05.04.2000"
    },
    {
        "engDate": "1943-07-21",
        "nepDate": "06.04.2000"
    },
    {
        "engDate": "1943-07-22",
        "nepDate": "07.04.2000"
    },
    {
        "engDate": "1943-07-23",
        "nepDate": "08.04.2000"
    },
    {
        "engDate": "1943-07-24",
        "nepDate": "09.04.2000"
    },
    {
        "engDate": "1943-07-25",
        "nepDate": "10.04.2000"
    },
    {
        "engDate": "1943-07-26",
        "nepDate": "11.04.2000"
    },
    {
        "engDate": "1943-07-27",
        "nepDate": "12.04.2000"
    },
    {
        "engDate": "1943-07-28",
        "nepDate": "13.04.2000"
    },
    {
        "engDate": "1943-07-29",
        "nepDate": "14.04.2000"
    },
    {
        "engDate": "1943-07-30",
        "nepDate": "15.04.2000"
    },
    {
        "engDate": "1943-07-31",
        "nepDate": "16.04.2000"
    },
    {
        "engDate": "1943-08-01",
        "nepDate": "17.04.2000"
    },
    {
        "engDate": "1943-08-02",
        "nepDate": "18.04.2000"
    },
    {
        "engDate": "1943-08-03",
        "nepDate": "19.04.2000"
    },
    {
        "engDate": "1943-08-04",
        "nepDate": "20.04.2000"
    },
    {
        "engDate": "1943-08-05",
        "nepDate": "21.04.2000"
    },
    {
        "engDate": "1943-08-06",
        "nepDate": "22.04.2000"
    },
    {
        "engDate": "1943-08-07",
        "nepDate": "23.04.2000"
    },
    {
        "engDate": "1943-08-08",
        "nepDate": "24.04.2000"
    },
    {
        "engDate": "1943-08-09",
        "nepDate": "25.04.2000"
    },
    {
        "engDate": "1943-08-10",
        "nepDate": "26.04.2000"
    },
    {
        "engDate": "1943-08-11",
        "nepDate": "27.04.2000"
    },
    {
        "engDate": "1943-08-12",
        "nepDate": "28.04.2000"
    },
    {
        "engDate": "1943-08-13",
        "nepDate": "29.04.2000"
    },
    {
        "engDate": "1943-08-14",
        "nepDate": "30.04.2000"
    },
    {
        "engDate": "1943-08-15",
        "nepDate": "31.04.2000"
    },
    {
        "engDate": "1943-08-16",
        "nepDate": "32.04.2000"
    },
    {
        "engDate": "1943-08-17",
        "nepDate": "01.05.2000"
    },
    {
        "engDate": "1943-08-18",
        "nepDate": "02.05.2000"
    },
    {
        "engDate": "1943-08-19",
        "nepDate": "03.05.2000"
    },
    {
        "engDate": "1943-08-20",
        "nepDate": "04.05.2000"
    },
    {
        "engDate": "1943-08-21",
        "nepDate": "05.05.2000"
    },
    {
        "engDate": "1943-08-22",
        "nepDate": "06.05.2000"
    },
    {
        "engDate": "1943-08-23",
        "nepDate": "07.05.2000"
    },
    {
        "engDate": "1943-08-24",
        "nepDate": "08.05.2000"
    },
    {
        "engDate": "1943-08-25",
        "nepDate": "09.05.2000"
    },
    {
        "engDate": "1943-08-26",
        "nepDate": "10.05.2000"
    },
    {
        "engDate": "1943-08-27",
        "nepDate": "11.05.2000"
    },
    {
        "engDate": "1943-08-28",
        "nepDate": "12.05.2000"
    },
    {
        "engDate": "1943-08-29",
        "nepDate": "13.05.2000"
    },
    {
        "engDate": "1943-08-30",
        "nepDate": "14.05.2000"
    },
    {
        "engDate": "1943-08-31",
        "nepDate": "15.05.2000"
    },
    {
        "engDate": "1943-09-01",
        "nepDate": "16.05.2000"
    },
    {
        "engDate": "1943-09-02",
        "nepDate": "17.05.2000"
    },
    {
        "engDate": "1943-09-03",
        "nepDate": "18.05.2000"
    },
    {
        "engDate": "1943-09-04",
        "nepDate": "19.05.2000"
    },
    {
        "engDate": "1943-09-05",
        "nepDate": "20.05.2000"
    },
    {
        "engDate": "1943-09-06",
        "nepDate": "21.05.2000"
    },
    {
        "engDate": "1943-09-07",
        "nepDate": "22.05.2000"
    },
    {
        "engDate": "1943-09-08",
        "nepDate": "23.05.2000"
    },
    {
        "engDate": "1943-09-09",
        "nepDate": "24.05.2000"
    },
    {
        "engDate": "1943-09-10",
        "nepDate": "25.05.2000"
    },
    {
        "engDate": "1943-09-11",
        "nepDate": "26.05.2000"
    },
    {
        "engDate": "1943-09-12",
        "nepDate": "27.05.2000"
    },
    {
        "engDate": "1943-09-13",
        "nepDate": "28.05.2000"
    },
    {
        "engDate": "1943-09-14",
        "nepDate": "29.05.2000"
    },
    {
        "engDate": "1943-09-15",
        "nepDate": "30.05.2000"
    },
    {
        "engDate": "1943-09-16",
        "nepDate": "31.05.2000"
    },
    {
        "engDate": "1943-09-17",
        "nepDate": "01.06.2000"
    },
    {
        "engDate": "1943-09-18",
        "nepDate": "02.06.2000"
    },
    {
        "engDate": "1943-09-19",
        "nepDate": "03.06.2000"
    },
    {
        "engDate": "1943-09-20",
        "nepDate": "04.06.2000"
    },
    {
        "engDate": "1943-09-21",
        "nepDate": "05.06.2000"
    },
    {
        "engDate": "1943-09-22",
        "nepDate": "06.06.2000"
    },
    {
        "engDate": "1943-09-23",
        "nepDate": "07.06.2000"
    },
    {
        "engDate": "1943-09-24",
        "nepDate": "08.06.2000"
    },
    {
        "engDate": "1943-09-25",
        "nepDate": "09.06.2000"
    },
    {
        "engDate": "1943-09-26",
        "nepDate": "10.06.2000"
    },
    {
        "engDate": "1943-09-27",
        "nepDate": "11.06.2000"
    },
    {
        "engDate": "1943-09-28",
        "nepDate": "12.06.2000"
    },
    {
        "engDate": "1943-09-29",
        "nepDate": "13.06.2000"
    },
    {
        "engDate": "1943-09-30",
        "nepDate": "14.06.2000"
    },
    {
        "engDate": "1943-10-01",
        "nepDate": "15.06.2000"
    },
    {
        "engDate": "1943-10-02",
        "nepDate": "16.06.2000"
    },
    {
        "engDate": "1943-10-03",
        "nepDate": "17.06.2000"
    },
    {
        "engDate": "1943-10-04",
        "nepDate": "18.06.2000"
    },
    {
        "engDate": "1943-10-05",
        "nepDate": "19.06.2000"
    },
    {
        "engDate": "1943-10-06",
        "nepDate": "20.06.2000"
    },
    {
        "engDate": "1943-10-07",
        "nepDate": "21.06.2000"
    },
    {
        "engDate": "1943-10-08",
        "nepDate": "22.06.2000"
    },
    {
        "engDate": "1943-10-09",
        "nepDate": "23.06.2000"
    },
    {
        "engDate": "1943-10-10",
        "nepDate": "24.06.2000"
    },
    {
        "engDate": "1943-10-11",
        "nepDate": "25.06.2000"
    },
    {
        "engDate": "1943-10-12",
        "nepDate": "26.06.2000"
    },
    {
        "engDate": "1943-10-13",
        "nepDate": "27.06.2000"
    },
    {
        "engDate": "1943-10-14",
        "nepDate": "28.06.2000"
    },
    {
        "engDate": "1943-10-15",
        "nepDate": "29.06.2000"
    },
    {
        "engDate": "1943-10-16",
        "nepDate": "30.06.2000"
    },
    {
        "engDate": "1943-10-17",
        "nepDate": "01.07.2000"
    },
    {
        "engDate": "1943-10-18",
        "nepDate": "02.07.2000"
    },
    {
        "engDate": "1943-10-19",
        "nepDate": "03.07.2000"
    },
    {
        "engDate": "1943-10-20",
        "nepDate": "04.07.2000"
    },
    {
        "engDate": "1943-10-21",
        "nepDate": "05.07.2000"
    },
    {
        "engDate": "1943-10-22",
        "nepDate": "06.07.2000"
    },
    {
        "engDate": "1943-10-23",
        "nepDate": "07.07.2000"
    },
    {
        "engDate": "1943-10-24",
        "nepDate": "08.07.2000"
    },
    {
        "engDate": "1943-10-25",
        "nepDate": "09.07.2000"
    },
    {
        "engDate": "1943-10-26",
        "nepDate": "10.07.2000"
    },
    {
        "engDate": "1943-10-27",
        "nepDate": "11.07.2000"
    },
    {
        "engDate": "1943-10-28",
        "nepDate": "12.07.2000"
    },
    {
        "engDate": "1943-10-29",
        "nepDate": "13.07.2000"
    },
    {
        "engDate": "1943-10-30",
        "nepDate": "14.07.2000"
    },
    {
        "engDate": "1943-10-31",
        "nepDate": "15.07.2000"
    },
    {
        "engDate": "1943-11-01",
        "nepDate": "16.07.2000"
    },
    {
        "engDate": "1943-11-02",
        "nepDate": "17.07.2000"
    },
    {
        "engDate": "1943-11-03",
        "nepDate": "18.07.2000"
    },
    {
        "engDate": "1943-11-04",
        "nepDate": "19.07.2000"
    },
    {
        "engDate": "1943-11-05",
        "nepDate": "20.07.2000"
    },
    {
        "engDate": "1943-11-06",
        "nepDate": "21.07.2000"
    },
    {
        "engDate": "1943-11-07",
        "nepDate": "22.07.2000"
    },
    {
        "engDate": "1943-11-08",
        "nepDate": "23.07.2000"
    },
    {
        "engDate": "1943-11-09",
        "nepDate": "24.07.2000"
    },
    {
        "engDate": "1943-11-10",
        "nepDate": "25.07.2000"
    },
    {
        "engDate": "1943-11-11",
        "nepDate": "26.07.2000"
    },
    {
        "engDate": "1943-11-12",
        "nepDate": "27.07.2000"
    },
    {
        "engDate": "1943-11-13",
        "nepDate": "28.07.2000"
    },
    {
        "engDate": "1943-11-14",
        "nepDate": "29.07.2000"
    },
    {
        "engDate": "1943-11-15",
        "nepDate": "30.07.2000"
    },
    {
        "engDate": "1943-11-16",
        "nepDate": "01.08.2000"
    },
    {
        "engDate": "1943-11-17",
        "nepDate": "02.08.2000"
    },
    {
        "engDate": "1943-11-18",
        "nepDate": "03.08.2000"
    },
    {
        "engDate": "1943-11-19",
        "nepDate": "04.08.2000"
    },
    {
        "engDate": "1943-11-20",
        "nepDate": "05.08.2000"
    },
    {
        "engDate": "1943-11-21",
        "nepDate": "06.08.2000"
    },
    {
        "engDate": "1943-11-22",
        "nepDate": "07.08.2000"
    },
    {
        "engDate": "1943-11-23",
        "nepDate": "08.08.2000"
    },
    {
        "engDate": "1943-11-24",
        "nepDate": "09.08.2000"
    },
    {
        "engDate": "1943-11-25",
        "nepDate": "10.08.2000"
    },
    {
        "engDate": "1943-11-26",
        "nepDate": "11.08.2000"
    },
    {
        "engDate": "1943-11-27",
        "nepDate": "12.08.2000"
    },
    {
        "engDate": "1943-11-28",
        "nepDate": "13.08.2000"
    },
    {
        "engDate": "1943-11-29",
        "nepDate": "14.08.2000"
    },
    {
        "engDate": "1943-11-30",
        "nepDate": "15.08.2000"
    },
    {
        "engDate": "1943-12-01",
        "nepDate": "16.08.2000"
    },
    {
        "engDate": "1943-12-02",
        "nepDate": "17.08.2000"
    },
    {
        "engDate": "1943-12-03",
        "nepDate": "18.08.2000"
    },
    {
        "engDate": "1943-12-04",
        "nepDate": "19.08.2000"
    },
    {
        "engDate": "1943-12-05",
        "nepDate": "20.08.2000"
    },
    {
        "engDate": "1943-12-06",
        "nepDate": "21.08.2000"
    },
    {
        "engDate": "1943-12-07",
        "nepDate": "22.08.2000"
    },
    {
        "engDate": "1943-12-08",
        "nepDate": "23.08.2000"
    },
    {
        "engDate": "1943-12-09",
        "nepDate": "24.08.2000"
    },
    {
        "engDate": "1943-12-10",
        "nepDate": "25.08.2000"
    },
    {
        "engDate": "1943-12-11",
        "nepDate": "26.08.2000"
    },
    {
        "engDate": "1943-12-12",
        "nepDate": "27.08.2000"
    },
    {
        "engDate": "1943-12-13",
        "nepDate": "28.08.2000"
    },
    {
        "engDate": "1943-12-14",
        "nepDate": "29.08.2000"
    },
    {
        "engDate": "1943-12-15",
        "nepDate": "30.08.2000"
    },
    {
        "engDate": "1943-12-16",
        "nepDate": "01.09.2000"
    },
    {
        "engDate": "1943-12-17",
        "nepDate": "02.09.2000"
    },
    {
        "engDate": "1943-12-18",
        "nepDate": "03.09.2000"
    },
    {
        "engDate": "1943-12-19",
        "nepDate": "04.09.2000"
    },
    {
        "engDate": "1943-12-20",
        "nepDate": "05.09.2000"
    },
    {
        "engDate": "1943-12-21",
        "nepDate": "06.09.2000"
    },
    {
        "engDate": "1943-12-22",
        "nepDate": "07.09.2000"
    },
    {
        "engDate": "1943-12-23",
        "nepDate": "08.09.2000"
    },
    {
        "engDate": "1943-12-24",
        "nepDate": "09.09.2000"
    },
    {
        "engDate": "1943-12-25",
        "nepDate": "10.09.2000"
    },
    {
        "engDate": "1943-12-26",
        "nepDate": "11.09.2000"
    },
    {
        "engDate": "1943-12-27",
        "nepDate": "12.09.2000"
    },
    {
        "engDate": "1943-12-28",
        "nepDate": "13.09.2000"
    },
    {
        "engDate": "1943-12-29",
        "nepDate": "14.09.2000"
    },
    {
        "engDate": "1943-12-30",
        "nepDate": "15.09.2000"
    },
    {
        "engDate": "1943-12-31",
        "nepDate": "16.09.2000"
    },
    {
        "engDate": "1944-01-01",
        "nepDate": "17.09.2000"
    },
    {
        "engDate": "1944-01-02",
        "nepDate": "18.09.2000"
    },
    {
        "engDate": "1944-01-03",
        "nepDate": "19.09.2000"
    },
    {
        "engDate": "1944-01-04",
        "nepDate": "20.09.2000"
    },
    {
        "engDate": "1944-01-05",
        "nepDate": "21.09.2000"
    },
    {
        "engDate": "1944-01-06",
        "nepDate": "22.09.2000"
    },
    {
        "engDate": "1944-01-07",
        "nepDate": "23.09.2000"
    },
    {
        "engDate": "1944-01-08",
        "nepDate": "24.09.2000"
    },
    {
        "engDate": "1944-01-09",
        "nepDate": "25.09.2000"
    },
    {
        "engDate": "1944-01-10",
        "nepDate": "26.09.2000"
    },
    {
        "engDate": "1944-01-11",
        "nepDate": "27.09.2000"
    },
    {
        "engDate": "1944-01-12",
        "nepDate": "28.09.2000"
    },
    {
        "engDate": "1944-01-13",
        "nepDate": "29.09.2000"
    },
    {
        "engDate": "1944-01-14",
        "nepDate": "01.10.2000"
    },
    {
        "engDate": "1944-01-15",
        "nepDate": "02.10.2000"
    },
    {
        "engDate": "1944-01-16",
        "nepDate": "03.10.2000"
    },
    {
        "engDate": "1944-01-17",
        "nepDate": "04.10.2000"
    },
    {
        "engDate": "1944-01-18",
        "nepDate": "05.10.2000"
    },
    {
        "engDate": "1944-01-19",
        "nepDate": "06.10.2000"
    },
    {
        "engDate": "1944-01-20",
        "nepDate": "07.10.2000"
    },
    {
        "engDate": "1944-01-21",
        "nepDate": "08.10.2000"
    },
    {
        "engDate": "1944-01-22",
        "nepDate": "09.10.2000"
    },
    {
        "engDate": "1944-01-23",
        "nepDate": "10.10.2000"
    },
    {
        "engDate": "1944-01-24",
        "nepDate": "11.10.2000"
    },
    {
        "engDate": "1944-01-25",
        "nepDate": "12.10.2000"
    },
    {
        "engDate": "1944-01-26",
        "nepDate": "13.10.2000"
    },
    {
        "engDate": "1944-01-27",
        "nepDate": "14.10.2000"
    },
    {
        "engDate": "1944-01-28",
        "nepDate": "15.10.2000"
    },
    {
        "engDate": "1944-01-29",
        "nepDate": "16.10.2000"
    },
    {
        "engDate": "1944-01-30",
        "nepDate": "17.10.2000"
    },
    {
        "engDate": "1944-01-31",
        "nepDate": "18.10.2000"
    },
    {
        "engDate": "1944-02-01",
        "nepDate": "19.10.2000"
    },
    {
        "engDate": "1944-02-02",
        "nepDate": "20.10.2000"
    },
    {
        "engDate": "1944-02-03",
        "nepDate": "21.10.2000"
    },
    {
        "engDate": "1944-02-04",
        "nepDate": "22.10.2000"
    },
    {
        "engDate": "1944-02-05",
        "nepDate": "23.10.2000"
    },
    {
        "engDate": "1944-02-06",
        "nepDate": "24.10.2000"
    },
    {
        "engDate": "1944-02-07",
        "nepDate": "25.10.2000"
    },
    {
        "engDate": "1944-02-08",
        "nepDate": "26.10.2000"
    },
    {
        "engDate": "1944-02-09",
        "nepDate": "27.10.2000"
    },
    {
        "engDate": "1944-02-10",
        "nepDate": "28.10.2000"
    },
    {
        "engDate": "1944-02-11",
        "nepDate": "29.10.2000"
    },
    {
        "engDate": "1944-02-12",
        "nepDate": "30.10.2000"
    },
    {
        "engDate": "1944-02-13",
        "nepDate": "01.11.2000"
    },
    {
        "engDate": "1944-02-14",
        "nepDate": "02.11.2000"
    },
    {
        "engDate": "1944-02-15",
        "nepDate": "03.11.2000"
    },
    {
        "engDate": "1944-02-16",
        "nepDate": "04.11.2000"
    },
    {
        "engDate": "1944-02-17",
        "nepDate": "05.11.2000"
    },
    {
        "engDate": "1944-02-18",
        "nepDate": "06.11.2000"
    },
    {
        "engDate": "1944-02-19",
        "nepDate": "07.11.2000"
    },
    {
        "engDate": "1944-02-20",
        "nepDate": "08.11.2000"
    },
    {
        "engDate": "1944-02-21",
        "nepDate": "09.11.2000"
    },
    {
        "engDate": "1944-02-22",
        "nepDate": "10.11.2000"
    },
    {
        "engDate": "1944-02-23",
        "nepDate": "11.11.2000"
    },
    {
        "engDate": "1944-02-24",
        "nepDate": "12.11.2000"
    },
    {
        "engDate": "1944-02-25",
        "nepDate": "13.11.2000"
    },
    {
        "engDate": "1944-02-26",
        "nepDate": "14.11.2000"
    },
    {
        "engDate": "1944-02-27",
        "nepDate": "15.11.2000"
    },
    {
        "engDate": "1944-02-28",
        "nepDate": "16.11.2000"
    },
    {
        "engDate": "1944-02-29",
        "nepDate": "17.11.2000"
    },
    {
        "engDate": "1944-03-01",
        "nepDate": "18.11.2000"
    },
    {
        "engDate": "1944-03-02",
        "nepDate": "19.11.2000"
    },
    {
        "engDate": "1944-03-03",
        "nepDate": "20.11.2000"
    },
    {
        "engDate": "1944-03-04",
        "nepDate": "21.11.2000"
    },
    {
        "engDate": "1944-03-05",
        "nepDate": "22.11.2000"
    },
    {
        "engDate": "1944-03-06",
        "nepDate": "23.11.2000"
    },
    {
        "engDate": "1944-03-07",
        "nepDate": "24.11.2000"
    },
    {
        "engDate": "1944-03-08",
        "nepDate": "25.11.2000"
    },
    {
        "engDate": "1944-03-09",
        "nepDate": "26.11.2000"
    },
    {
        "engDate": "1944-03-10",
        "nepDate": "27.11.2000"
    },
    {
        "engDate": "1944-03-11",
        "nepDate": "28.11.2000"
    },
    {
        "engDate": "1944-03-12",
        "nepDate": "29.11.2000"
    },
    {
        "engDate": "1944-03-13",
        "nepDate": "01.12.2000"
    },
    {
        "engDate": "1944-03-14",
        "nepDate": "02.12.2000"
    },
    {
        "engDate": "1944-03-15",
        "nepDate": "03.12.2000"
    },
    {
        "engDate": "1944-03-16",
        "nepDate": "04.12.2000"
    },
    {
        "engDate": "1944-03-17",
        "nepDate": "05.12.2000"
    },
    {
        "engDate": "1944-03-18",
        "nepDate": "06.12.2000"
    },
    {
        "engDate": "1944-03-19",
        "nepDate": "07.12.2000"
    },
    {
        "engDate": "1944-03-20",
        "nepDate": "08.12.2000"
    },
    {
        "engDate": "1944-03-21",
        "nepDate": "09.12.2000"
    },
    {
        "engDate": "1944-03-22",
        "nepDate": "10.12.2000"
    },
    {
        "engDate": "1944-03-23",
        "nepDate": "11.12.2000"
    },
    {
        "engDate": "1944-03-24",
        "nepDate": "12.12.2000"
    },
    {
        "engDate": "1944-03-25",
        "nepDate": "13.12.2000"
    },
    {
        "engDate": "1944-03-26",
        "nepDate": "14.12.2000"
    },
    {
        "engDate": "1944-03-27",
        "nepDate": "15.12.2000"
    },
    {
        "engDate": "1944-03-28",
        "nepDate": "16.12.2000"
    },
    {
        "engDate": "1944-03-29",
        "nepDate": "17.12.2000"
    },
    {
        "engDate": "1944-03-30",
        "nepDate": "18.12.2000"
    },
    {
        "engDate": "1944-03-31",
        "nepDate": "19.12.2000"
    },
    {
        "engDate": "1944-04-01",
        "nepDate": "20.12.2000"
    },
    {
        "engDate": "1944-04-02",
        "nepDate": "21.12.2000"
    },
    {
        "engDate": "1944-04-03",
        "nepDate": "22.12.2000"
    },
    {
        "engDate": "1944-04-04",
        "nepDate": "23.12.2000"
    },
    {
        "engDate": "1944-04-05",
        "nepDate": "24.12.2000"
    },
    {
        "engDate": "1944-04-06",
        "nepDate": "25.12.2000"
    },
    {
        "engDate": "1944-04-07",
        "nepDate": "26.12.2000"
    },
    {
        "engDate": "1944-04-08",
        "nepDate": "27.12.2000"
    },
    {
        "engDate": "1944-04-09",
        "nepDate": "28.12.2000"
    },
    {
        "engDate": "1944-04-10",
        "nepDate": "29.12.2000"
    },
    {
        "engDate": "1944-04-11",
        "nepDate": "30.12.2000"
    },
    {
        "engDate": "1944-04-12",
        "nepDate": "31.12.2000"
    },
    {
        "engDate": "1944-04-13",
        "nepDate": "01.01.2001"
    },
    {
        "engDate": "1944-04-14",
        "nepDate": "02.01.2001"
    },
    {
        "engDate": "1944-04-15",
        "nepDate": "03.01.2001"
    },
    {
        "engDate": "1944-04-16",
        "nepDate": "04.01.2001"
    },
    {
        "engDate": "1944-04-17",
        "nepDate": "05.01.2001"
    },
    {
        "engDate": "1944-04-18",
        "nepDate": "06.01.2001"
    },
    {
        "engDate": "1944-04-19",
        "nepDate": "07.01.2001"
    },
    {
        "engDate": "1944-04-20",
        "nepDate": "08.01.2001"
    },
    {
        "engDate": "1944-04-21",
        "nepDate": "09.01.2001"
    },
    {
        "engDate": "1944-04-22",
        "nepDate": "10.01.2001"
    },
    {
        "engDate": "1944-04-23",
        "nepDate": "11.01.2001"
    },
    {
        "engDate": "1944-04-24",
        "nepDate": "12.01.2001"
    },
    {
        "engDate": "1944-04-25",
        "nepDate": "13.01.2001"
    },
    {
        "engDate": "1944-04-26",
        "nepDate": "14.01.2001"
    },
    {
        "engDate": "1944-04-27",
        "nepDate": "15.01.2001"
    },
    {
        "engDate": "1944-04-28",
        "nepDate": "16.01.2001"
    },
    {
        "engDate": "1944-04-29",
        "nepDate": "17.01.2001"
    },
    {
        "engDate": "1944-04-30",
        "nepDate": "18.01.2001"
    },
    {
        "engDate": "1944-05-01",
        "nepDate": "19.01.2001"
    },
    {
        "engDate": "1944-05-02",
        "nepDate": "20.01.2001"
    },
    {
        "engDate": "1944-05-03",
        "nepDate": "21.01.2001"
    },
    {
        "engDate": "1944-05-04",
        "nepDate": "22.01.2001"
    },
    {
        "engDate": "1944-05-05",
        "nepDate": "23.01.2001"
    },
    {
        "engDate": "1944-05-06",
        "nepDate": "24.01.2001"
    },
    {
        "engDate": "1944-05-07",
        "nepDate": "25.01.2001"
    },
    {
        "engDate": "1944-05-08",
        "nepDate": "26.01.2001"
    },
    {
        "engDate": "1944-05-09",
        "nepDate": "27.01.2001"
    },
    {
        "engDate": "1944-05-10",
        "nepDate": "28.01.2001"
    },
    {
        "engDate": "1944-05-11",
        "nepDate": "29.01.2001"
    },
    {
        "engDate": "1944-05-12",
        "nepDate": "30.01.2001"
    },
    {
        "engDate": "1944-05-13",
        "nepDate": "31.01.2001"
    },
    {
        "engDate": "1944-05-14",
        "nepDate": "01.02.2001"
    },
    {
        "engDate": "1944-05-15",
        "nepDate": "02.02.2001"
    },
    {
        "engDate": "1944-05-16",
        "nepDate": "03.02.2001"
    },
    {
        "engDate": "1944-05-17",
        "nepDate": "04.02.2001"
    },
    {
        "engDate": "1944-05-18",
        "nepDate": "05.02.2001"
    },
    {
        "engDate": "1944-05-19",
        "nepDate": "06.02.2001"
    },
    {
        "engDate": "1944-05-20",
        "nepDate": "07.02.2001"
    },
    {
        "engDate": "1944-05-21",
        "nepDate": "08.02.2001"
    },
    {
        "engDate": "1944-05-22",
        "nepDate": "09.02.2001"
    },
    {
        "engDate": "1944-05-23",
        "nepDate": "10.02.2001"
    },
    {
        "engDate": "1944-05-24",
        "nepDate": "11.02.2001"
    },
    {
        "engDate": "1944-05-25",
        "nepDate": "12.02.2001"
    },
    {
        "engDate": "1944-05-26",
        "nepDate": "13.02.2001"
    },
    {
        "engDate": "1944-05-27",
        "nepDate": "14.02.2001"
    },
    {
        "engDate": "1944-05-28",
        "nepDate": "15.02.2001"
    },
    {
        "engDate": "1944-05-29",
        "nepDate": "16.02.2001"
    },
    {
        "engDate": "1944-05-30",
        "nepDate": "17.02.2001"
    },
    {
        "engDate": "1944-05-31",
        "nepDate": "18.02.2001"
    },
    {
        "engDate": "1944-06-01",
        "nepDate": "19.02.2001"
    },
    {
        "engDate": "1944-06-02",
        "nepDate": "20.02.2001"
    },
    {
        "engDate": "1944-06-03",
        "nepDate": "21.02.2001"
    },
    {
        "engDate": "1944-06-04",
        "nepDate": "22.02.2001"
    },
    {
        "engDate": "1944-06-05",
        "nepDate": "23.02.2001"
    },
    {
        "engDate": "1944-06-06",
        "nepDate": "24.02.2001"
    },
    {
        "engDate": "1944-06-07",
        "nepDate": "25.02.2001"
    },
    {
        "engDate": "1944-06-08",
        "nepDate": "26.02.2001"
    },
    {
        "engDate": "1944-06-09",
        "nepDate": "27.02.2001"
    },
    {
        "engDate": "1944-06-10",
        "nepDate": "28.02.2001"
    },
    {
        "engDate": "1944-06-11",
        "nepDate": "29.02.2001"
    },
    {
        "engDate": "1944-06-12",
        "nepDate": "30.02.2001"
    },
    {
        "engDate": "1944-06-13",
        "nepDate": "31.02.2001"
    },
    {
        "engDate": "1944-06-14",
        "nepDate": "01.03.2001"
    },
    {
        "engDate": "1944-06-15",
        "nepDate": "02.03.2001"
    },
    {
        "engDate": "1944-06-16",
        "nepDate": "03.03.2001"
    },
    {
        "engDate": "1944-06-17",
        "nepDate": "04.03.2001"
    },
    {
        "engDate": "1944-06-18",
        "nepDate": "05.03.2001"
    },
    {
        "engDate": "1944-06-19",
        "nepDate": "06.03.2001"
    },
    {
        "engDate": "1944-06-20",
        "nepDate": "07.03.2001"
    },
    {
        "engDate": "1944-06-21",
        "nepDate": "08.03.2001"
    },
    {
        "engDate": "1944-06-22",
        "nepDate": "09.03.2001"
    },
    {
        "engDate": "1944-06-23",
        "nepDate": "10.03.2001"
    },
    {
        "engDate": "1944-06-24",
        "nepDate": "11.03.2001"
    },
    {
        "engDate": "1944-06-25",
        "nepDate": "12.03.2001"
    },
    {
        "engDate": "1944-06-26",
        "nepDate": "13.03.2001"
    },
    {
        "engDate": "1944-06-27",
        "nepDate": "14.03.2001"
    },
    {
        "engDate": "1944-06-28",
        "nepDate": "15.03.2001"
    },
    {
        "engDate": "1944-06-29",
        "nepDate": "16.03.2001"
    },
    {
        "engDate": "1944-06-30",
        "nepDate": "17.03.2001"
    },
    {
        "engDate": "1944-07-01",
        "nepDate": "18.03.2001"
    },
    {
        "engDate": "1944-07-02",
        "nepDate": "19.03.2001"
    },
    {
        "engDate": "1944-07-03",
        "nepDate": "20.03.2001"
    },
    {
        "engDate": "1944-07-04",
        "nepDate": "21.03.2001"
    },
    {
        "engDate": "1944-07-05",
        "nepDate": "22.03.2001"
    },
    {
        "engDate": "1944-07-06",
        "nepDate": "23.03.2001"
    },
    {
        "engDate": "1944-07-07",
        "nepDate": "24.03.2001"
    },
    {
        "engDate": "1944-07-08",
        "nepDate": "25.03.2001"
    },
    {
        "engDate": "1944-07-09",
        "nepDate": "26.03.2001"
    },
    {
        "engDate": "1944-07-10",
        "nepDate": "27.03.2001"
    },
    {
        "engDate": "1944-07-11",
        "nepDate": "28.03.2001"
    },
    {
        "engDate": "1944-07-12",
        "nepDate": "29.03.2001"
    },
    {
        "engDate": "1944-07-13",
        "nepDate": "30.03.2001"
    },
    {
        "engDate": "1944-07-14",
        "nepDate": "31.03.2001"
    },
    {
        "engDate": "1944-07-15",
        "nepDate": "32.03.2001"
    },
    {
        "engDate": "1944-07-16",
        "nepDate": "01.04.2001"
    },
    {
        "engDate": "1944-07-17",
        "nepDate": "02.04.2001"
    },
    {
        "engDate": "1944-07-18",
        "nepDate": "03.04.2001"
    },
    {
        "engDate": "1944-07-19",
        "nepDate": "04.04.2001"
    },
    {
        "engDate": "1944-07-20",
        "nepDate": "05.04.2001"
    },
    {
        "engDate": "1944-07-21",
        "nepDate": "06.04.2001"
    },
    {
        "engDate": "1944-07-22",
        "nepDate": "07.04.2001"
    },
    {
        "engDate": "1944-07-23",
        "nepDate": "08.04.2001"
    },
    {
        "engDate": "1944-07-24",
        "nepDate": "09.04.2001"
    },
    {
        "engDate": "1944-07-25",
        "nepDate": "10.04.2001"
    },
    {
        "engDate": "1944-07-26",
        "nepDate": "11.04.2001"
    },
    {
        "engDate": "1944-07-27",
        "nepDate": "12.04.2001"
    },
    {
        "engDate": "1944-07-28",
        "nepDate": "13.04.2001"
    },
    {
        "engDate": "1944-07-29",
        "nepDate": "14.04.2001"
    },
    {
        "engDate": "1944-07-30",
        "nepDate": "15.04.2001"
    },
    {
        "engDate": "1944-07-31",
        "nepDate": "16.04.2001"
    },
    {
        "engDate": "1944-08-01",
        "nepDate": "17.04.2001"
    },
    {
        "engDate": "1944-08-02",
        "nepDate": "18.04.2001"
    },
    {
        "engDate": "1944-08-03",
        "nepDate": "19.04.2001"
    },
    {
        "engDate": "1944-08-04",
        "nepDate": "20.04.2001"
    },
    {
        "engDate": "1944-08-05",
        "nepDate": "21.04.2001"
    },
    {
        "engDate": "1944-08-06",
        "nepDate": "22.04.2001"
    },
    {
        "engDate": "1944-08-07",
        "nepDate": "23.04.2001"
    },
    {
        "engDate": "1944-08-08",
        "nepDate": "24.04.2001"
    },
    {
        "engDate": "1944-08-09",
        "nepDate": "25.04.2001"
    },
    {
        "engDate": "1944-08-10",
        "nepDate": "26.04.2001"
    },
    {
        "engDate": "1944-08-11",
        "nepDate": "27.04.2001"
    },
    {
        "engDate": "1944-08-12",
        "nepDate": "28.04.2001"
    },
    {
        "engDate": "1944-08-13",
        "nepDate": "29.04.2001"
    },
    {
        "engDate": "1944-08-14",
        "nepDate": "30.04.2001"
    },
    {
        "engDate": "1944-08-15",
        "nepDate": "31.04.2001"
    },
    {
        "engDate": "1944-08-16",
        "nepDate": "01.05.2001"
    },
    {
        "engDate": "1944-08-17",
        "nepDate": "02.05.2001"
    },
    {
        "engDate": "1944-08-18",
        "nepDate": "03.05.2001"
    },
    {
        "engDate": "1944-08-19",
        "nepDate": "04.05.2001"
    },
    {
        "engDate": "1944-08-20",
        "nepDate": "05.05.2001"
    },
    {
        "engDate": "1944-08-21",
        "nepDate": "06.05.2001"
    },
    {
        "engDate": "1944-08-22",
        "nepDate": "07.05.2001"
    },
    {
        "engDate": "1944-08-23",
        "nepDate": "08.05.2001"
    },
    {
        "engDate": "1944-08-24",
        "nepDate": "09.05.2001"
    },
    {
        "engDate": "1944-08-25",
        "nepDate": "10.05.2001"
    },
    {
        "engDate": "1944-08-26",
        "nepDate": "11.05.2001"
    },
    {
        "engDate": "1944-08-27",
        "nepDate": "12.05.2001"
    },
    {
        "engDate": "1944-08-28",
        "nepDate": "13.05.2001"
    },
    {
        "engDate": "1944-08-29",
        "nepDate": "14.05.2001"
    },
    {
        "engDate": "1944-08-30",
        "nepDate": "15.05.2001"
    },
    {
        "engDate": "1944-08-31",
        "nepDate": "16.05.2001"
    },
    {
        "engDate": "1944-09-01",
        "nepDate": "17.05.2001"
    },
    {
        "engDate": "1944-09-02",
        "nepDate": "18.05.2001"
    },
    {
        "engDate": "1944-09-03",
        "nepDate": "19.05.2001"
    },
    {
        "engDate": "1944-09-04",
        "nepDate": "20.05.2001"
    },
    {
        "engDate": "1944-09-05",
        "nepDate": "21.05.2001"
    },
    {
        "engDate": "1944-09-06",
        "nepDate": "22.05.2001"
    },
    {
        "engDate": "1944-09-07",
        "nepDate": "23.05.2001"
    },
    {
        "engDate": "1944-09-08",
        "nepDate": "24.05.2001"
    },
    {
        "engDate": "1944-09-09",
        "nepDate": "25.05.2001"
    },
    {
        "engDate": "1944-09-10",
        "nepDate": "26.05.2001"
    },
    {
        "engDate": "1944-09-11",
        "nepDate": "27.05.2001"
    },
    {
        "engDate": "1944-09-12",
        "nepDate": "28.05.2001"
    },
    {
        "engDate": "1944-09-13",
        "nepDate": "29.05.2001"
    },
    {
        "engDate": "1944-09-14",
        "nepDate": "30.05.2001"
    },
    {
        "engDate": "1944-09-15",
        "nepDate": "31.05.2001"
    },
    {
        "engDate": "1944-09-16",
        "nepDate": "01.06.2001"
    },
    {
        "engDate": "1944-09-17",
        "nepDate": "02.06.2001"
    },
    {
        "engDate": "1944-09-18",
        "nepDate": "03.06.2001"
    },
    {
        "engDate": "1944-09-19",
        "nepDate": "04.06.2001"
    },
    {
        "engDate": "1944-09-20",
        "nepDate": "05.06.2001"
    },
    {
        "engDate": "1944-09-21",
        "nepDate": "06.06.2001"
    },
    {
        "engDate": "1944-09-22",
        "nepDate": "07.06.2001"
    },
    {
        "engDate": "1944-09-23",
        "nepDate": "08.06.2001"
    },
    {
        "engDate": "1944-09-24",
        "nepDate": "09.06.2001"
    },
    {
        "engDate": "1944-09-25",
        "nepDate": "10.06.2001"
    },
    {
        "engDate": "1944-09-26",
        "nepDate": "11.06.2001"
    },
    {
        "engDate": "1944-09-27",
        "nepDate": "12.06.2001"
    },
    {
        "engDate": "1944-09-28",
        "nepDate": "13.06.2001"
    },
    {
        "engDate": "1944-09-29",
        "nepDate": "14.06.2001"
    },
    {
        "engDate": "1944-09-30",
        "nepDate": "15.06.2001"
    },
    {
        "engDate": "1944-10-01",
        "nepDate": "16.06.2001"
    },
    {
        "engDate": "1944-10-02",
        "nepDate": "17.06.2001"
    },
    {
        "engDate": "1944-10-03",
        "nepDate": "18.06.2001"
    },
    {
        "engDate": "1944-10-04",
        "nepDate": "19.06.2001"
    },
    {
        "engDate": "1944-10-05",
        "nepDate": "20.06.2001"
    },
    {
        "engDate": "1944-10-06",
        "nepDate": "21.06.2001"
    },
    {
        "engDate": "1944-10-07",
        "nepDate": "22.06.2001"
    },
    {
        "engDate": "1944-10-08",
        "nepDate": "23.06.2001"
    },
    {
        "engDate": "1944-10-09",
        "nepDate": "24.06.2001"
    },
    {
        "engDate": "1944-10-10",
        "nepDate": "25.06.2001"
    },
    {
        "engDate": "1944-10-11",
        "nepDate": "26.06.2001"
    },
    {
        "engDate": "1944-10-12",
        "nepDate": "27.06.2001"
    },
    {
        "engDate": "1944-10-13",
        "nepDate": "28.06.2001"
    },
    {
        "engDate": "1944-10-14",
        "nepDate": "29.06.2001"
    },
    {
        "engDate": "1944-10-15",
        "nepDate": "30.06.2001"
    },
    {
        "engDate": "1944-10-16",
        "nepDate": "31.06.2001"
    },
    {
        "engDate": "1944-10-17",
        "nepDate": "01.07.2001"
    },
    {
        "engDate": "1944-10-18",
        "nepDate": "02.07.2001"
    },
    {
        "engDate": "1944-10-19",
        "nepDate": "03.07.2001"
    },
    {
        "engDate": "1944-10-20",
        "nepDate": "04.07.2001"
    },
    {
        "engDate": "1944-10-21",
        "nepDate": "05.07.2001"
    },
    {
        "engDate": "1944-10-22",
        "nepDate": "06.07.2001"
    },
    {
        "engDate": "1944-10-23",
        "nepDate": "07.07.2001"
    },
    {
        "engDate": "1944-10-24",
        "nepDate": "08.07.2001"
    },
    {
        "engDate": "1944-10-25",
        "nepDate": "09.07.2001"
    },
    {
        "engDate": "1944-10-26",
        "nepDate": "10.07.2001"
    },
    {
        "engDate": "1944-10-27",
        "nepDate": "11.07.2001"
    },
    {
        "engDate": "1944-10-28",
        "nepDate": "12.07.2001"
    },
    {
        "engDate": "1944-10-29",
        "nepDate": "13.07.2001"
    },
    {
        "engDate": "1944-10-30",
        "nepDate": "14.07.2001"
    },
    {
        "engDate": "1944-10-31",
        "nepDate": "15.07.2001"
    },
    {
        "engDate": "1944-11-01",
        "nepDate": "16.07.2001"
    },
    {
        "engDate": "1944-11-02",
        "nepDate": "17.07.2001"
    },
    {
        "engDate": "1944-11-03",
        "nepDate": "18.07.2001"
    },
    {
        "engDate": "1944-11-04",
        "nepDate": "19.07.2001"
    },
    {
        "engDate": "1944-11-05",
        "nepDate": "20.07.2001"
    },
    {
        "engDate": "1944-11-06",
        "nepDate": "21.07.2001"
    },
    {
        "engDate": "1944-11-07",
        "nepDate": "22.07.2001"
    },
    {
        "engDate": "1944-11-08",
        "nepDate": "23.07.2001"
    },
    {
        "engDate": "1944-11-09",
        "nepDate": "24.07.2001"
    },
    {
        "engDate": "1944-11-10",
        "nepDate": "25.07.2001"
    },
    {
        "engDate": "1944-11-11",
        "nepDate": "26.07.2001"
    },
    {
        "engDate": "1944-11-12",
        "nepDate": "27.07.2001"
    },
    {
        "engDate": "1944-11-13",
        "nepDate": "28.07.2001"
    },
    {
        "engDate": "1944-11-14",
        "nepDate": "29.07.2001"
    },
    {
        "engDate": "1944-11-15",
        "nepDate": "30.07.2001"
    },
    {
        "engDate": "1944-11-16",
        "nepDate": "01.08.2001"
    },
    {
        "engDate": "1944-11-17",
        "nepDate": "02.08.2001"
    },
    {
        "engDate": "1944-11-18",
        "nepDate": "03.08.2001"
    },
    {
        "engDate": "1944-11-19",
        "nepDate": "04.08.2001"
    },
    {
        "engDate": "1944-11-20",
        "nepDate": "05.08.2001"
    },
    {
        "engDate": "1944-11-21",
        "nepDate": "06.08.2001"
    },
    {
        "engDate": "1944-11-22",
        "nepDate": "07.08.2001"
    },
    {
        "engDate": "1944-11-23",
        "nepDate": "08.08.2001"
    },
    {
        "engDate": "1944-11-24",
        "nepDate": "09.08.2001"
    },
    {
        "engDate": "1944-11-25",
        "nepDate": "10.08.2001"
    },
    {
        "engDate": "1944-11-26",
        "nepDate": "11.08.2001"
    },
    {
        "engDate": "1944-11-27",
        "nepDate": "12.08.2001"
    },
    {
        "engDate": "1944-11-28",
        "nepDate": "13.08.2001"
    },
    {
        "engDate": "1944-11-29",
        "nepDate": "14.08.2001"
    },
    {
        "engDate": "1944-11-30",
        "nepDate": "15.08.2001"
    },
    {
        "engDate": "1944-12-01",
        "nepDate": "16.08.2001"
    },
    {
        "engDate": "1944-12-02",
        "nepDate": "17.08.2001"
    },
    {
        "engDate": "1944-12-03",
        "nepDate": "18.08.2001"
    },
    {
        "engDate": "1944-12-04",
        "nepDate": "19.08.2001"
    },
    {
        "engDate": "1944-12-05",
        "nepDate": "20.08.2001"
    },
    {
        "engDate": "1944-12-06",
        "nepDate": "21.08.2001"
    },
    {
        "engDate": "1944-12-07",
        "nepDate": "22.08.2001"
    },
    {
        "engDate": "1944-12-08",
        "nepDate": "23.08.2001"
    },
    {
        "engDate": "1944-12-09",
        "nepDate": "24.08.2001"
    },
    {
        "engDate": "1944-12-10",
        "nepDate": "25.08.2001"
    },
    {
        "engDate": "1944-12-11",
        "nepDate": "26.08.2001"
    },
    {
        "engDate": "1944-12-12",
        "nepDate": "27.08.2001"
    },
    {
        "engDate": "1944-12-13",
        "nepDate": "28.08.2001"
    },
    {
        "engDate": "1944-12-14",
        "nepDate": "29.08.2001"
    },
    {
        "engDate": "1944-12-15",
        "nepDate": "01.09.2001"
    },
    {
        "engDate": "1944-12-16",
        "nepDate": "02.09.2001"
    },
    {
        "engDate": "1944-12-17",
        "nepDate": "03.09.2001"
    },
    {
        "engDate": "1944-12-18",
        "nepDate": "04.09.2001"
    },
    {
        "engDate": "1944-12-19",
        "nepDate": "05.09.2001"
    },
    {
        "engDate": "1944-12-20",
        "nepDate": "06.09.2001"
    },
    {
        "engDate": "1944-12-21",
        "nepDate": "07.09.2001"
    },
    {
        "engDate": "1944-12-22",
        "nepDate": "08.09.2001"
    },
    {
        "engDate": "1944-12-23",
        "nepDate": "09.09.2001"
    },
    {
        "engDate": "1944-12-24",
        "nepDate": "10.09.2001"
    },
    {
        "engDate": "1944-12-25",
        "nepDate": "11.09.2001"
    },
    {
        "engDate": "1944-12-26",
        "nepDate": "12.09.2001"
    },
    {
        "engDate": "1944-12-27",
        "nepDate": "13.09.2001"
    },
    {
        "engDate": "1944-12-28",
        "nepDate": "14.09.2001"
    },
    {
        "engDate": "1944-12-29",
        "nepDate": "15.09.2001"
    },
    {
        "engDate": "1944-12-30",
        "nepDate": "16.09.2001"
    },
    {
        "engDate": "1944-12-31",
        "nepDate": "17.09.2001"
    },
    {
        "engDate": "1945-01-01",
        "nepDate": "18.09.2001"
    },
    {
        "engDate": "1945-01-02",
        "nepDate": "19.09.2001"
    },
    {
        "engDate": "1945-01-03",
        "nepDate": "20.09.2001"
    },
    {
        "engDate": "1945-01-04",
        "nepDate": "21.09.2001"
    },
    {
        "engDate": "1945-01-05",
        "nepDate": "22.09.2001"
    },
    {
        "engDate": "1945-01-06",
        "nepDate": "23.09.2001"
    },
    {
        "engDate": "1945-01-07",
        "nepDate": "24.09.2001"
    },
    {
        "engDate": "1945-01-08",
        "nepDate": "25.09.2001"
    },
    {
        "engDate": "1945-01-09",
        "nepDate": "26.09.2001"
    },
    {
        "engDate": "1945-01-10",
        "nepDate": "27.09.2001"
    },
    {
        "engDate": "1945-01-11",
        "nepDate": "28.09.2001"
    },
    {
        "engDate": "1945-01-12",
        "nepDate": "29.09.2001"
    },
    {
        "engDate": "1945-01-13",
        "nepDate": "30.09.2001"
    },
    {
        "engDate": "1945-01-14",
        "nepDate": "01.10.2001"
    },
    {
        "engDate": "1945-01-15",
        "nepDate": "02.10.2001"
    },
    {
        "engDate": "1945-01-16",
        "nepDate": "03.10.2001"
    },
    {
        "engDate": "1945-01-17",
        "nepDate": "04.10.2001"
    },
    {
        "engDate": "1945-01-18",
        "nepDate": "05.10.2001"
    },
    {
        "engDate": "1945-01-19",
        "nepDate": "06.10.2001"
    },
    {
        "engDate": "1945-01-20",
        "nepDate": "07.10.2001"
    },
    {
        "engDate": "1945-01-21",
        "nepDate": "08.10.2001"
    },
    {
        "engDate": "1945-01-22",
        "nepDate": "09.10.2001"
    },
    {
        "engDate": "1945-01-23",
        "nepDate": "10.10.2001"
    },
    {
        "engDate": "1945-01-24",
        "nepDate": "11.10.2001"
    },
    {
        "engDate": "1945-01-25",
        "nepDate": "12.10.2001"
    },
    {
        "engDate": "1945-01-26",
        "nepDate": "13.10.2001"
    },
    {
        "engDate": "1945-01-27",
        "nepDate": "14.10.2001"
    },
    {
        "engDate": "1945-01-28",
        "nepDate": "15.10.2001"
    },
    {
        "engDate": "1945-01-29",
        "nepDate": "16.10.2001"
    },
    {
        "engDate": "1945-01-30",
        "nepDate": "17.10.2001"
    },
    {
        "engDate": "1945-01-31",
        "nepDate": "18.10.2001"
    },
    {
        "engDate": "1945-02-01",
        "nepDate": "19.10.2001"
    },
    {
        "engDate": "1945-02-02",
        "nepDate": "20.10.2001"
    },
    {
        "engDate": "1945-02-03",
        "nepDate": "21.10.2001"
    },
    {
        "engDate": "1945-02-04",
        "nepDate": "22.10.2001"
    },
    {
        "engDate": "1945-02-05",
        "nepDate": "23.10.2001"
    },
    {
        "engDate": "1945-02-06",
        "nepDate": "24.10.2001"
    },
    {
        "engDate": "1945-02-07",
        "nepDate": "25.10.2001"
    },
    {
        "engDate": "1945-02-08",
        "nepDate": "26.10.2001"
    },
    {
        "engDate": "1945-02-09",
        "nepDate": "27.10.2001"
    },
    {
        "engDate": "1945-02-10",
        "nepDate": "28.10.2001"
    },
    {
        "engDate": "1945-02-11",
        "nepDate": "29.10.2001"
    },
    {
        "engDate": "1945-02-12",
        "nepDate": "01.11.2001"
    },
    {
        "engDate": "1945-02-13",
        "nepDate": "02.11.2001"
    },
    {
        "engDate": "1945-02-14",
        "nepDate": "03.11.2001"
    },
    {
        "engDate": "1945-02-15",
        "nepDate": "04.11.2001"
    },
    {
        "engDate": "1945-02-16",
        "nepDate": "05.11.2001"
    },
    {
        "engDate": "1945-02-17",
        "nepDate": "06.11.2001"
    },
    {
        "engDate": "1945-02-18",
        "nepDate": "07.11.2001"
    },
    {
        "engDate": "1945-02-19",
        "nepDate": "08.11.2001"
    },
    {
        "engDate": "1945-02-20",
        "nepDate": "09.11.2001"
    },
    {
        "engDate": "1945-02-21",
        "nepDate": "10.11.2001"
    },
    {
        "engDate": "1945-02-22",
        "nepDate": "11.11.2001"
    },
    {
        "engDate": "1945-02-23",
        "nepDate": "12.11.2001"
    },
    {
        "engDate": "1945-02-24",
        "nepDate": "13.11.2001"
    },
    {
        "engDate": "1945-02-25",
        "nepDate": "14.11.2001"
    },
    {
        "engDate": "1945-02-26",
        "nepDate": "15.11.2001"
    },
    {
        "engDate": "1945-02-27",
        "nepDate": "16.11.2001"
    },
    {
        "engDate": "1945-02-28",
        "nepDate": "17.11.2001"
    },
    {
        "engDate": "1945-03-01",
        "nepDate": "18.11.2001"
    },
    {
        "engDate": "1945-03-02",
        "nepDate": "19.11.2001"
    },
    {
        "engDate": "1945-03-03",
        "nepDate": "20.11.2001"
    },
    {
        "engDate": "1945-03-04",
        "nepDate": "21.11.2001"
    },
    {
        "engDate": "1945-03-05",
        "nepDate": "22.11.2001"
    },
    {
        "engDate": "1945-03-06",
        "nepDate": "23.11.2001"
    },
    {
        "engDate": "1945-03-07",
        "nepDate": "24.11.2001"
    },
    {
        "engDate": "1945-03-08",
        "nepDate": "25.11.2001"
    },
    {
        "engDate": "1945-03-09",
        "nepDate": "26.11.2001"
    },
    {
        "engDate": "1945-03-10",
        "nepDate": "27.11.2001"
    },
    {
        "engDate": "1945-03-11",
        "nepDate": "28.11.2001"
    },
    {
        "engDate": "1945-03-12",
        "nepDate": "29.11.2001"
    },
    {
        "engDate": "1945-03-13",
        "nepDate": "30.11.2001"
    },
    {
        "engDate": "1945-03-14",
        "nepDate": "01.12.2001"
    },
    {
        "engDate": "1945-03-15",
        "nepDate": "02.12.2001"
    },
    {
        "engDate": "1945-03-16",
        "nepDate": "03.12.2001"
    },
    {
        "engDate": "1945-03-17",
        "nepDate": "04.12.2001"
    },
    {
        "engDate": "1945-03-18",
        "nepDate": "05.12.2001"
    },
    {
        "engDate": "1945-03-19",
        "nepDate": "06.12.2001"
    },
    {
        "engDate": "1945-03-20",
        "nepDate": "07.12.2001"
    },
    {
        "engDate": "1945-03-21",
        "nepDate": "08.12.2001"
    },
    {
        "engDate": "1945-03-22",
        "nepDate": "09.12.2001"
    },
    {
        "engDate": "1945-03-23",
        "nepDate": "10.12.2001"
    },
    {
        "engDate": "1945-03-24",
        "nepDate": "11.12.2001"
    },
    {
        "engDate": "1945-03-25",
        "nepDate": "12.12.2001"
    },
    {
        "engDate": "1945-03-26",
        "nepDate": "13.12.2001"
    },
    {
        "engDate": "1945-03-27",
        "nepDate": "14.12.2001"
    },
    {
        "engDate": "1945-03-28",
        "nepDate": "15.12.2001"
    },
    {
        "engDate": "1945-03-29",
        "nepDate": "16.12.2001"
    },
    {
        "engDate": "1945-03-30",
        "nepDate": "17.12.2001"
    },
    {
        "engDate": "1945-03-31",
        "nepDate": "18.12.2001"
    },
    {
        "engDate": "1945-04-01",
        "nepDate": "19.12.2001"
    },
    {
        "engDate": "1945-04-02",
        "nepDate": "20.12.2001"
    },
    {
        "engDate": "1945-04-03",
        "nepDate": "21.12.2001"
    },
    {
        "engDate": "1945-04-04",
        "nepDate": "22.12.2001"
    },
    {
        "engDate": "1945-04-05",
        "nepDate": "23.12.2001"
    },
    {
        "engDate": "1945-04-06",
        "nepDate": "24.12.2001"
    },
    {
        "engDate": "1945-04-07",
        "nepDate": "25.12.2001"
    },
    {
        "engDate": "1945-04-08",
        "nepDate": "26.12.2001"
    },
    {
        "engDate": "1945-04-09",
        "nepDate": "27.12.2001"
    },
    {
        "engDate": "1945-04-10",
        "nepDate": "28.12.2001"
    },
    {
        "engDate": "1945-04-11",
        "nepDate": "29.12.2001"
    },
    {
        "engDate": "1945-04-12",
        "nepDate": "30.12.2001"
    },
    {
        "engDate": "1945-04-13",
        "nepDate": "01.01.2002"
    },
    {
        "engDate": "1945-04-14",
        "nepDate": "02.01.2002"
    },
    {
        "engDate": "1945-04-15",
        "nepDate": "03.01.2002"
    },
    {
        "engDate": "1945-04-16",
        "nepDate": "04.01.2002"
    },
    {
        "engDate": "1945-04-17",
        "nepDate": "05.01.2002"
    },
    {
        "engDate": "1945-04-18",
        "nepDate": "06.01.2002"
    },
    {
        "engDate": "1945-04-19",
        "nepDate": "07.01.2002"
    },
    {
        "engDate": "1945-04-20",
        "nepDate": "08.01.2002"
    },
    {
        "engDate": "1945-04-21",
        "nepDate": "09.01.2002"
    },
    {
        "engDate": "1945-04-22",
        "nepDate": "10.01.2002"
    },
    {
        "engDate": "1945-04-23",
        "nepDate": "11.01.2002"
    },
    {
        "engDate": "1945-04-24",
        "nepDate": "12.01.2002"
    },
    {
        "engDate": "1945-04-25",
        "nepDate": "13.01.2002"
    },
    {
        "engDate": "1945-04-26",
        "nepDate": "14.01.2002"
    },
    {
        "engDate": "1945-04-27",
        "nepDate": "15.01.2002"
    },
    {
        "engDate": "1945-04-28",
        "nepDate": "16.01.2002"
    },
    {
        "engDate": "1945-04-29",
        "nepDate": "17.01.2002"
    },
    {
        "engDate": "1945-04-30",
        "nepDate": "18.01.2002"
    },
    {
        "engDate": "1945-05-01",
        "nepDate": "19.01.2002"
    },
    {
        "engDate": "1945-05-02",
        "nepDate": "20.01.2002"
    },
    {
        "engDate": "1945-05-03",
        "nepDate": "21.01.2002"
    },
    {
        "engDate": "1945-05-04",
        "nepDate": "22.01.2002"
    },
    {
        "engDate": "1945-05-05",
        "nepDate": "23.01.2002"
    },
    {
        "engDate": "1945-05-06",
        "nepDate": "24.01.2002"
    },
    {
        "engDate": "1945-05-07",
        "nepDate": "25.01.2002"
    },
    {
        "engDate": "1945-05-08",
        "nepDate": "26.01.2002"
    },
    {
        "engDate": "1945-05-09",
        "nepDate": "27.01.2002"
    },
    {
        "engDate": "1945-05-10",
        "nepDate": "28.01.2002"
    },
    {
        "engDate": "1945-05-11",
        "nepDate": "29.01.2002"
    },
    {
        "engDate": "1945-05-12",
        "nepDate": "30.01.2002"
    },
    {
        "engDate": "1945-05-13",
        "nepDate": "31.01.2002"
    },
    {
        "engDate": "1945-05-14",
        "nepDate": "01.02.2002"
    },
    {
        "engDate": "1945-05-15",
        "nepDate": "02.02.2002"
    },
    {
        "engDate": "1945-05-16",
        "nepDate": "03.02.2002"
    },
    {
        "engDate": "1945-05-17",
        "nepDate": "04.02.2002"
    },
    {
        "engDate": "1945-05-18",
        "nepDate": "05.02.2002"
    },
    {
        "engDate": "1945-05-19",
        "nepDate": "06.02.2002"
    },
    {
        "engDate": "1945-05-20",
        "nepDate": "07.02.2002"
    },
    {
        "engDate": "1945-05-21",
        "nepDate": "08.02.2002"
    },
    {
        "engDate": "1945-05-22",
        "nepDate": "09.02.2002"
    },
    {
        "engDate": "1945-05-23",
        "nepDate": "10.02.2002"
    },
    {
        "engDate": "1945-05-24",
        "nepDate": "11.02.2002"
    },
    {
        "engDate": "1945-05-25",
        "nepDate": "12.02.2002"
    },
    {
        "engDate": "1945-05-26",
        "nepDate": "13.02.2002"
    },
    {
        "engDate": "1945-05-27",
        "nepDate": "14.02.2002"
    },
    {
        "engDate": "1945-05-28",
        "nepDate": "15.02.2002"
    },
    {
        "engDate": "1945-05-29",
        "nepDate": "16.02.2002"
    },
    {
        "engDate": "1945-05-30",
        "nepDate": "17.02.2002"
    },
    {
        "engDate": "1945-05-31",
        "nepDate": "18.02.2002"
    },
    {
        "engDate": "1945-06-01",
        "nepDate": "19.02.2002"
    },
    {
        "engDate": "1945-06-02",
        "nepDate": "20.02.2002"
    },
    {
        "engDate": "1945-06-03",
        "nepDate": "21.02.2002"
    },
    {
        "engDate": "1945-06-04",
        "nepDate": "22.02.2002"
    },
    {
        "engDate": "1945-06-05",
        "nepDate": "23.02.2002"
    },
    {
        "engDate": "1945-06-06",
        "nepDate": "24.02.2002"
    },
    {
        "engDate": "1945-06-07",
        "nepDate": "25.02.2002"
    },
    {
        "engDate": "1945-06-08",
        "nepDate": "26.02.2002"
    },
    {
        "engDate": "1945-06-09",
        "nepDate": "27.02.2002"
    },
    {
        "engDate": "1945-06-10",
        "nepDate": "28.02.2002"
    },
    {
        "engDate": "1945-06-11",
        "nepDate": "29.02.2002"
    },
    {
        "engDate": "1945-06-12",
        "nepDate": "30.02.2002"
    },
    {
        "engDate": "1945-06-13",
        "nepDate": "31.02.2002"
    },
    {
        "engDate": "1945-06-14",
        "nepDate": "01.03.2002"
    },
    {
        "engDate": "1945-06-15",
        "nepDate": "02.03.2002"
    },
    {
        "engDate": "1945-06-16",
        "nepDate": "03.03.2002"
    },
    {
        "engDate": "1945-06-17",
        "nepDate": "04.03.2002"
    },
    {
        "engDate": "1945-06-18",
        "nepDate": "05.03.2002"
    },
    {
        "engDate": "1945-06-19",
        "nepDate": "06.03.2002"
    },
    {
        "engDate": "1945-06-20",
        "nepDate": "07.03.2002"
    },
    {
        "engDate": "1945-06-21",
        "nepDate": "08.03.2002"
    },
    {
        "engDate": "1945-06-22",
        "nepDate": "09.03.2002"
    },
    {
        "engDate": "1945-06-23",
        "nepDate": "10.03.2002"
    },
    {
        "engDate": "1945-06-24",
        "nepDate": "11.03.2002"
    },
    {
        "engDate": "1945-06-25",
        "nepDate": "12.03.2002"
    },
    {
        "engDate": "1945-06-26",
        "nepDate": "13.03.2002"
    },
    {
        "engDate": "1945-06-27",
        "nepDate": "14.03.2002"
    },
    {
        "engDate": "1945-06-28",
        "nepDate": "15.03.2002"
    },
    {
        "engDate": "1945-06-29",
        "nepDate": "16.03.2002"
    },
    {
        "engDate": "1945-06-30",
        "nepDate": "17.03.2002"
    },
    {
        "engDate": "1945-07-01",
        "nepDate": "18.03.2002"
    },
    {
        "engDate": "1945-07-02",
        "nepDate": "19.03.2002"
    },
    {
        "engDate": "1945-07-03",
        "nepDate": "20.03.2002"
    },
    {
        "engDate": "1945-07-04",
        "nepDate": "21.03.2002"
    },
    {
        "engDate": "1945-07-05",
        "nepDate": "22.03.2002"
    },
    {
        "engDate": "1945-07-06",
        "nepDate": "23.03.2002"
    },
    {
        "engDate": "1945-07-07",
        "nepDate": "24.03.2002"
    },
    {
        "engDate": "1945-07-08",
        "nepDate": "25.03.2002"
    },
    {
        "engDate": "1945-07-09",
        "nepDate": "26.03.2002"
    },
    {
        "engDate": "1945-07-10",
        "nepDate": "27.03.2002"
    },
    {
        "engDate": "1945-07-11",
        "nepDate": "28.03.2002"
    },
    {
        "engDate": "1945-07-12",
        "nepDate": "29.03.2002"
    },
    {
        "engDate": "1945-07-13",
        "nepDate": "30.03.2002"
    },
    {
        "engDate": "1945-07-14",
        "nepDate": "31.03.2002"
    },
    {
        "engDate": "1945-07-15",
        "nepDate": "32.03.2002"
    },
    {
        "engDate": "1945-07-16",
        "nepDate": "01.04.2002"
    },
    {
        "engDate": "1945-07-17",
        "nepDate": "02.04.2002"
    },
    {
        "engDate": "1945-07-18",
        "nepDate": "03.04.2002"
    },
    {
        "engDate": "1945-07-19",
        "nepDate": "04.04.2002"
    },
    {
        "engDate": "1945-07-20",
        "nepDate": "05.04.2002"
    },
    {
        "engDate": "1945-07-21",
        "nepDate": "06.04.2002"
    },
    {
        "engDate": "1945-07-22",
        "nepDate": "07.04.2002"
    },
    {
        "engDate": "1945-07-23",
        "nepDate": "08.04.2002"
    },
    {
        "engDate": "1945-07-24",
        "nepDate": "09.04.2002"
    },
    {
        "engDate": "1945-07-25",
        "nepDate": "10.04.2002"
    },
    {
        "engDate": "1945-07-26",
        "nepDate": "11.04.2002"
    },
    {
        "engDate": "1945-07-27",
        "nepDate": "12.04.2002"
    },
    {
        "engDate": "1945-07-28",
        "nepDate": "13.04.2002"
    },
    {
        "engDate": "1945-07-29",
        "nepDate": "14.04.2002"
    },
    {
        "engDate": "1945-07-30",
        "nepDate": "15.04.2002"
    },
    {
        "engDate": "1945-07-31",
        "nepDate": "16.04.2002"
    },
    {
        "engDate": "1945-08-01",
        "nepDate": "17.04.2002"
    },
    {
        "engDate": "1945-08-02",
        "nepDate": "18.04.2002"
    },
    {
        "engDate": "1945-08-03",
        "nepDate": "19.04.2002"
    },
    {
        "engDate": "1945-08-04",
        "nepDate": "20.04.2002"
    },
    {
        "engDate": "1945-08-05",
        "nepDate": "21.04.2002"
    },
    {
        "engDate": "1945-08-06",
        "nepDate": "22.04.2002"
    },
    {
        "engDate": "1945-08-07",
        "nepDate": "23.04.2002"
    },
    {
        "engDate": "1945-08-08",
        "nepDate": "24.04.2002"
    },
    {
        "engDate": "1945-08-09",
        "nepDate": "25.04.2002"
    },
    {
        "engDate": "1945-08-10",
        "nepDate": "26.04.2002"
    },
    {
        "engDate": "1945-08-11",
        "nepDate": "27.04.2002"
    },
    {
        "engDate": "1945-08-12",
        "nepDate": "28.04.2002"
    },
    {
        "engDate": "1945-08-13",
        "nepDate": "29.04.2002"
    },
    {
        "engDate": "1945-08-14",
        "nepDate": "30.04.2002"
    },
    {
        "engDate": "1945-08-15",
        "nepDate": "31.04.2002"
    },
    {
        "engDate": "1945-08-16",
        "nepDate": "32.04.2002"
    },
    {
        "engDate": "1945-08-17",
        "nepDate": "01.05.2002"
    },
    {
        "engDate": "1945-08-18",
        "nepDate": "02.05.2002"
    },
    {
        "engDate": "1945-08-19",
        "nepDate": "03.05.2002"
    },
    {
        "engDate": "1945-08-20",
        "nepDate": "04.05.2002"
    },
    {
        "engDate": "1945-08-21",
        "nepDate": "05.05.2002"
    },
    {
        "engDate": "1945-08-22",
        "nepDate": "06.05.2002"
    },
    {
        "engDate": "1945-08-23",
        "nepDate": "07.05.2002"
    },
    {
        "engDate": "1945-08-24",
        "nepDate": "08.05.2002"
    },
    {
        "engDate": "1945-08-25",
        "nepDate": "09.05.2002"
    },
    {
        "engDate": "1945-08-26",
        "nepDate": "10.05.2002"
    },
    {
        "engDate": "1945-08-27",
        "nepDate": "11.05.2002"
    },
    {
        "engDate": "1945-08-28",
        "nepDate": "12.05.2002"
    },
    {
        "engDate": "1945-08-29",
        "nepDate": "13.05.2002"
    },
    {
        "engDate": "1945-08-30",
        "nepDate": "14.05.2002"
    },
    {
        "engDate": "1945-08-31",
        "nepDate": "15.05.2002"
    },
    {
        "engDate": "1945-09-01",
        "nepDate": "16.05.2002"
    },
    {
        "engDate": "1945-09-02",
        "nepDate": "17.05.2002"
    },
    {
        "engDate": "1945-09-03",
        "nepDate": "18.05.2002"
    },
    {
        "engDate": "1945-09-04",
        "nepDate": "19.05.2002"
    },
    {
        "engDate": "1945-09-05",
        "nepDate": "20.05.2002"
    },
    {
        "engDate": "1945-09-06",
        "nepDate": "21.05.2002"
    },
    {
        "engDate": "1945-09-07",
        "nepDate": "22.05.2002"
    },
    {
        "engDate": "1945-09-08",
        "nepDate": "23.05.2002"
    },
    {
        "engDate": "1945-09-09",
        "nepDate": "24.05.2002"
    },
    {
        "engDate": "1945-09-10",
        "nepDate": "25.05.2002"
    },
    {
        "engDate": "1945-09-11",
        "nepDate": "26.05.2002"
    },
    {
        "engDate": "1945-09-12",
        "nepDate": "27.05.2002"
    },
    {
        "engDate": "1945-09-13",
        "nepDate": "28.05.2002"
    },
    {
        "engDate": "1945-09-14",
        "nepDate": "29.05.2002"
    },
    {
        "engDate": "1945-09-15",
        "nepDate": "30.05.2002"
    },
    {
        "engDate": "1945-09-16",
        "nepDate": "31.05.2002"
    },
    {
        "engDate": "1945-09-17",
        "nepDate": "01.06.2002"
    },
    {
        "engDate": "1945-09-18",
        "nepDate": "02.06.2002"
    },
    {
        "engDate": "1945-09-19",
        "nepDate": "03.06.2002"
    },
    {
        "engDate": "1945-09-20",
        "nepDate": "04.06.2002"
    },
    {
        "engDate": "1945-09-21",
        "nepDate": "05.06.2002"
    },
    {
        "engDate": "1945-09-22",
        "nepDate": "06.06.2002"
    },
    {
        "engDate": "1945-09-23",
        "nepDate": "07.06.2002"
    },
    {
        "engDate": "1945-09-24",
        "nepDate": "08.06.2002"
    },
    {
        "engDate": "1945-09-25",
        "nepDate": "09.06.2002"
    },
    {
        "engDate": "1945-09-26",
        "nepDate": "10.06.2002"
    },
    {
        "engDate": "1945-09-27",
        "nepDate": "11.06.2002"
    },
    {
        "engDate": "1945-09-28",
        "nepDate": "12.06.2002"
    },
    {
        "engDate": "1945-09-29",
        "nepDate": "13.06.2002"
    },
    {
        "engDate": "1945-09-30",
        "nepDate": "14.06.2002"
    },
    {
        "engDate": "1945-10-01",
        "nepDate": "15.06.2002"
    },
    {
        "engDate": "1945-10-02",
        "nepDate": "16.06.2002"
    },
    {
        "engDate": "1945-10-03",
        "nepDate": "17.06.2002"
    },
    {
        "engDate": "1945-10-04",
        "nepDate": "18.06.2002"
    },
    {
        "engDate": "1945-10-05",
        "nepDate": "19.06.2002"
    },
    {
        "engDate": "1945-10-06",
        "nepDate": "20.06.2002"
    },
    {
        "engDate": "1945-10-07",
        "nepDate": "21.06.2002"
    },
    {
        "engDate": "1945-10-08",
        "nepDate": "22.06.2002"
    },
    {
        "engDate": "1945-10-09",
        "nepDate": "23.06.2002"
    },
    {
        "engDate": "1945-10-10",
        "nepDate": "24.06.2002"
    },
    {
        "engDate": "1945-10-11",
        "nepDate": "25.06.2002"
    },
    {
        "engDate": "1945-10-12",
        "nepDate": "26.06.2002"
    },
    {
        "engDate": "1945-10-13",
        "nepDate": "27.06.2002"
    },
    {
        "engDate": "1945-10-14",
        "nepDate": "28.06.2002"
    },
    {
        "engDate": "1945-10-15",
        "nepDate": "29.06.2002"
    },
    {
        "engDate": "1945-10-16",
        "nepDate": "30.06.2002"
    },
    {
        "engDate": "1945-10-17",
        "nepDate": "01.07.2002"
    },
    {
        "engDate": "1945-10-18",
        "nepDate": "02.07.2002"
    },
    {
        "engDate": "1945-10-19",
        "nepDate": "03.07.2002"
    },
    {
        "engDate": "1945-10-20",
        "nepDate": "04.07.2002"
    },
    {
        "engDate": "1945-10-21",
        "nepDate": "05.07.2002"
    },
    {
        "engDate": "1945-10-22",
        "nepDate": "06.07.2002"
    },
    {
        "engDate": "1945-10-23",
        "nepDate": "07.07.2002"
    },
    {
        "engDate": "1945-10-24",
        "nepDate": "08.07.2002"
    },
    {
        "engDate": "1945-10-25",
        "nepDate": "09.07.2002"
    },
    {
        "engDate": "1945-10-26",
        "nepDate": "10.07.2002"
    },
    {
        "engDate": "1945-10-27",
        "nepDate": "11.07.2002"
    },
    {
        "engDate": "1945-10-28",
        "nepDate": "12.07.2002"
    },
    {
        "engDate": "1945-10-29",
        "nepDate": "13.07.2002"
    },
    {
        "engDate": "1945-10-30",
        "nepDate": "14.07.2002"
    },
    {
        "engDate": "1945-10-31",
        "nepDate": "15.07.2002"
    },
    {
        "engDate": "1945-11-01",
        "nepDate": "16.07.2002"
    },
    {
        "engDate": "1945-11-02",
        "nepDate": "17.07.2002"
    },
    {
        "engDate": "1945-11-03",
        "nepDate": "18.07.2002"
    },
    {
        "engDate": "1945-11-04",
        "nepDate": "19.07.2002"
    },
    {
        "engDate": "1945-11-05",
        "nepDate": "20.07.2002"
    },
    {
        "engDate": "1945-11-06",
        "nepDate": "21.07.2002"
    },
    {
        "engDate": "1945-11-07",
        "nepDate": "22.07.2002"
    },
    {
        "engDate": "1945-11-08",
        "nepDate": "23.07.2002"
    },
    {
        "engDate": "1945-11-09",
        "nepDate": "24.07.2002"
    },
    {
        "engDate": "1945-11-10",
        "nepDate": "25.07.2002"
    },
    {
        "engDate": "1945-11-11",
        "nepDate": "26.07.2002"
    },
    {
        "engDate": "1945-11-12",
        "nepDate": "27.07.2002"
    },
    {
        "engDate": "1945-11-13",
        "nepDate": "28.07.2002"
    },
    {
        "engDate": "1945-11-14",
        "nepDate": "29.07.2002"
    },
    {
        "engDate": "1945-11-15",
        "nepDate": "30.07.2002"
    },
    {
        "engDate": "1945-11-16",
        "nepDate": "01.08.2002"
    },
    {
        "engDate": "1945-11-17",
        "nepDate": "02.08.2002"
    },
    {
        "engDate": "1945-11-18",
        "nepDate": "03.08.2002"
    },
    {
        "engDate": "1945-11-19",
        "nepDate": "04.08.2002"
    },
    {
        "engDate": "1945-11-20",
        "nepDate": "05.08.2002"
    },
    {
        "engDate": "1945-11-21",
        "nepDate": "06.08.2002"
    },
    {
        "engDate": "1945-11-22",
        "nepDate": "07.08.2002"
    },
    {
        "engDate": "1945-11-23",
        "nepDate": "08.08.2002"
    },
    {
        "engDate": "1945-11-24",
        "nepDate": "09.08.2002"
    },
    {
        "engDate": "1945-11-25",
        "nepDate": "10.08.2002"
    },
    {
        "engDate": "1945-11-26",
        "nepDate": "11.08.2002"
    },
    {
        "engDate": "1945-11-27",
        "nepDate": "12.08.2002"
    },
    {
        "engDate": "1945-11-28",
        "nepDate": "13.08.2002"
    },
    {
        "engDate": "1945-11-29",
        "nepDate": "14.08.2002"
    },
    {
        "engDate": "1945-11-30",
        "nepDate": "15.08.2002"
    },
    {
        "engDate": "1945-12-01",
        "nepDate": "16.08.2002"
    },
    {
        "engDate": "1945-12-02",
        "nepDate": "17.08.2002"
    },
    {
        "engDate": "1945-12-03",
        "nepDate": "18.08.2002"
    },
    {
        "engDate": "1945-12-04",
        "nepDate": "19.08.2002"
    },
    {
        "engDate": "1945-12-05",
        "nepDate": "20.08.2002"
    },
    {
        "engDate": "1945-12-06",
        "nepDate": "21.08.2002"
    },
    {
        "engDate": "1945-12-07",
        "nepDate": "22.08.2002"
    },
    {
        "engDate": "1945-12-08",
        "nepDate": "23.08.2002"
    },
    {
        "engDate": "1945-12-09",
        "nepDate": "24.08.2002"
    },
    {
        "engDate": "1945-12-10",
        "nepDate": "25.08.2002"
    },
    {
        "engDate": "1945-12-11",
        "nepDate": "26.08.2002"
    },
    {
        "engDate": "1945-12-12",
        "nepDate": "27.08.2002"
    },
    {
        "engDate": "1945-12-13",
        "nepDate": "28.08.2002"
    },
    {
        "engDate": "1945-12-14",
        "nepDate": "29.08.2002"
    },
    {
        "engDate": "1945-12-15",
        "nepDate": "01.09.2002"
    },
    {
        "engDate": "1945-12-16",
        "nepDate": "02.09.2002"
    },
    {
        "engDate": "1945-12-17",
        "nepDate": "03.09.2002"
    },
    {
        "engDate": "1945-12-18",
        "nepDate": "04.09.2002"
    },
    {
        "engDate": "1945-12-19",
        "nepDate": "05.09.2002"
    },
    {
        "engDate": "1945-12-20",
        "nepDate": "06.09.2002"
    },
    {
        "engDate": "1945-12-21",
        "nepDate": "07.09.2002"
    },
    {
        "engDate": "1945-12-22",
        "nepDate": "08.09.2002"
    },
    {
        "engDate": "1945-12-23",
        "nepDate": "09.09.2002"
    },
    {
        "engDate": "1945-12-24",
        "nepDate": "10.09.2002"
    },
    {
        "engDate": "1945-12-25",
        "nepDate": "11.09.2002"
    },
    {
        "engDate": "1945-12-26",
        "nepDate": "12.09.2002"
    },
    {
        "engDate": "1945-12-27",
        "nepDate": "13.09.2002"
    },
    {
        "engDate": "1945-12-28",
        "nepDate": "14.09.2002"
    },
    {
        "engDate": "1945-12-29",
        "nepDate": "15.09.2002"
    },
    {
        "engDate": "1945-12-30",
        "nepDate": "16.09.2002"
    },
    {
        "engDate": "1945-12-31",
        "nepDate": "17.09.2002"
    },
    {
        "engDate": "1946-01-01",
        "nepDate": "18.09.2002"
    },
    {
        "engDate": "1946-01-02",
        "nepDate": "19.09.2002"
    },
    {
        "engDate": "1946-01-03",
        "nepDate": "20.09.2002"
    },
    {
        "engDate": "1946-01-04",
        "nepDate": "21.09.2002"
    },
    {
        "engDate": "1946-01-05",
        "nepDate": "22.09.2002"
    },
    {
        "engDate": "1946-01-06",
        "nepDate": "23.09.2002"
    },
    {
        "engDate": "1946-01-07",
        "nepDate": "24.09.2002"
    },
    {
        "engDate": "1946-01-08",
        "nepDate": "25.09.2002"
    },
    {
        "engDate": "1946-01-09",
        "nepDate": "26.09.2002"
    },
    {
        "engDate": "1946-01-10",
        "nepDate": "27.09.2002"
    },
    {
        "engDate": "1946-01-11",
        "nepDate": "28.09.2002"
    },
    {
        "engDate": "1946-01-12",
        "nepDate": "29.09.2002"
    },
    {
        "engDate": "1946-01-13",
        "nepDate": "30.09.2002"
    },
    {
        "engDate": "1946-01-14",
        "nepDate": "01.10.2002"
    },
    {
        "engDate": "1946-01-15",
        "nepDate": "02.10.2002"
    },
    {
        "engDate": "1946-01-16",
        "nepDate": "03.10.2002"
    },
    {
        "engDate": "1946-01-17",
        "nepDate": "04.10.2002"
    },
    {
        "engDate": "1946-01-18",
        "nepDate": "05.10.2002"
    },
    {
        "engDate": "1946-01-19",
        "nepDate": "06.10.2002"
    },
    {
        "engDate": "1946-01-20",
        "nepDate": "07.10.2002"
    },
    {
        "engDate": "1946-01-21",
        "nepDate": "08.10.2002"
    },
    {
        "engDate": "1946-01-22",
        "nepDate": "09.10.2002"
    },
    {
        "engDate": "1946-01-23",
        "nepDate": "10.10.2002"
    },
    {
        "engDate": "1946-01-24",
        "nepDate": "11.10.2002"
    },
    {
        "engDate": "1946-01-25",
        "nepDate": "12.10.2002"
    },
    {
        "engDate": "1946-01-26",
        "nepDate": "13.10.2002"
    },
    {
        "engDate": "1946-01-27",
        "nepDate": "14.10.2002"
    },
    {
        "engDate": "1946-01-28",
        "nepDate": "15.10.2002"
    },
    {
        "engDate": "1946-01-29",
        "nepDate": "16.10.2002"
    },
    {
        "engDate": "1946-01-30",
        "nepDate": "17.10.2002"
    },
    {
        "engDate": "1946-01-31",
        "nepDate": "18.10.2002"
    },
    {
        "engDate": "1946-02-01",
        "nepDate": "19.10.2002"
    },
    {
        "engDate": "1946-02-02",
        "nepDate": "20.10.2002"
    },
    {
        "engDate": "1946-02-03",
        "nepDate": "21.10.2002"
    },
    {
        "engDate": "1946-02-04",
        "nepDate": "22.10.2002"
    },
    {
        "engDate": "1946-02-05",
        "nepDate": "23.10.2002"
    },
    {
        "engDate": "1946-02-06",
        "nepDate": "24.10.2002"
    },
    {
        "engDate": "1946-02-07",
        "nepDate": "25.10.2002"
    },
    {
        "engDate": "1946-02-08",
        "nepDate": "26.10.2002"
    },
    {
        "engDate": "1946-02-09",
        "nepDate": "27.10.2002"
    },
    {
        "engDate": "1946-02-10",
        "nepDate": "28.10.2002"
    },
    {
        "engDate": "1946-02-11",
        "nepDate": "29.10.2002"
    },
    {
        "engDate": "1946-02-12",
        "nepDate": "01.11.2002"
    },
    {
        "engDate": "1946-02-13",
        "nepDate": "02.11.2002"
    },
    {
        "engDate": "1946-02-14",
        "nepDate": "03.11.2002"
    },
    {
        "engDate": "1946-02-15",
        "nepDate": "04.11.2002"
    },
    {
        "engDate": "1946-02-16",
        "nepDate": "05.11.2002"
    },
    {
        "engDate": "1946-02-17",
        "nepDate": "06.11.2002"
    },
    {
        "engDate": "1946-02-18",
        "nepDate": "07.11.2002"
    },
    {
        "engDate": "1946-02-19",
        "nepDate": "08.11.2002"
    },
    {
        "engDate": "1946-02-20",
        "nepDate": "09.11.2002"
    },
    {
        "engDate": "1946-02-21",
        "nepDate": "10.11.2002"
    },
    {
        "engDate": "1946-02-22",
        "nepDate": "11.11.2002"
    },
    {
        "engDate": "1946-02-23",
        "nepDate": "12.11.2002"
    },
    {
        "engDate": "1946-02-24",
        "nepDate": "13.11.2002"
    },
    {
        "engDate": "1946-02-25",
        "nepDate": "14.11.2002"
    },
    {
        "engDate": "1946-02-26",
        "nepDate": "15.11.2002"
    },
    {
        "engDate": "1946-02-27",
        "nepDate": "16.11.2002"
    },
    {
        "engDate": "1946-02-28",
        "nepDate": "17.11.2002"
    },
    {
        "engDate": "1946-03-01",
        "nepDate": "18.11.2002"
    },
    {
        "engDate": "1946-03-02",
        "nepDate": "19.11.2002"
    },
    {
        "engDate": "1946-03-03",
        "nepDate": "20.11.2002"
    },
    {
        "engDate": "1946-03-04",
        "nepDate": "21.11.2002"
    },
    {
        "engDate": "1946-03-05",
        "nepDate": "22.11.2002"
    },
    {
        "engDate": "1946-03-06",
        "nepDate": "23.11.2002"
    },
    {
        "engDate": "1946-03-07",
        "nepDate": "24.11.2002"
    },
    {
        "engDate": "1946-03-08",
        "nepDate": "25.11.2002"
    },
    {
        "engDate": "1946-03-09",
        "nepDate": "26.11.2002"
    },
    {
        "engDate": "1946-03-10",
        "nepDate": "27.11.2002"
    },
    {
        "engDate": "1946-03-11",
        "nepDate": "28.11.2002"
    },
    {
        "engDate": "1946-03-12",
        "nepDate": "29.11.2002"
    },
    {
        "engDate": "1946-03-13",
        "nepDate": "30.11.2002"
    },
    {
        "engDate": "1946-03-14",
        "nepDate": "01.12.2002"
    },
    {
        "engDate": "1946-03-15",
        "nepDate": "02.12.2002"
    },
    {
        "engDate": "1946-03-16",
        "nepDate": "03.12.2002"
    },
    {
        "engDate": "1946-03-17",
        "nepDate": "04.12.2002"
    },
    {
        "engDate": "1946-03-18",
        "nepDate": "05.12.2002"
    },
    {
        "engDate": "1946-03-19",
        "nepDate": "06.12.2002"
    },
    {
        "engDate": "1946-03-20",
        "nepDate": "07.12.2002"
    },
    {
        "engDate": "1946-03-21",
        "nepDate": "08.12.2002"
    },
    {
        "engDate": "1946-03-22",
        "nepDate": "09.12.2002"
    },
    {
        "engDate": "1946-03-23",
        "nepDate": "10.12.2002"
    },
    {
        "engDate": "1946-03-24",
        "nepDate": "11.12.2002"
    },
    {
        "engDate": "1946-03-25",
        "nepDate": "12.12.2002"
    },
    {
        "engDate": "1946-03-26",
        "nepDate": "13.12.2002"
    },
    {
        "engDate": "1946-03-27",
        "nepDate": "14.12.2002"
    },
    {
        "engDate": "1946-03-28",
        "nepDate": "15.12.2002"
    },
    {
        "engDate": "1946-03-29",
        "nepDate": "16.12.2002"
    },
    {
        "engDate": "1946-03-30",
        "nepDate": "17.12.2002"
    },
    {
        "engDate": "1946-03-31",
        "nepDate": "18.12.2002"
    },
    {
        "engDate": "1946-04-01",
        "nepDate": "19.12.2002"
    },
    {
        "engDate": "1946-04-02",
        "nepDate": "20.12.2002"
    },
    {
        "engDate": "1946-04-03",
        "nepDate": "21.12.2002"
    },
    {
        "engDate": "1946-04-04",
        "nepDate": "22.12.2002"
    },
    {
        "engDate": "1946-04-05",
        "nepDate": "23.12.2002"
    },
    {
        "engDate": "1946-04-06",
        "nepDate": "24.12.2002"
    },
    {
        "engDate": "1946-04-07",
        "nepDate": "25.12.2002"
    },
    {
        "engDate": "1946-04-08",
        "nepDate": "26.12.2002"
    },
    {
        "engDate": "1946-04-09",
        "nepDate": "27.12.2002"
    },
    {
        "engDate": "1946-04-10",
        "nepDate": "28.12.2002"
    },
    {
        "engDate": "1946-04-11",
        "nepDate": "29.12.2002"
    },
    {
        "engDate": "1946-04-12",
        "nepDate": "30.12.2002"
    },
    {
        "engDate": "1946-04-13",
        "nepDate": "01.01.2003"
    },
    {
        "engDate": "1946-04-14",
        "nepDate": "02.01.2003"
    },
    {
        "engDate": "1946-04-15",
        "nepDate": "03.01.2003"
    },
    {
        "engDate": "1946-04-16",
        "nepDate": "04.01.2003"
    },
    {
        "engDate": "1946-04-17",
        "nepDate": "05.01.2003"
    },
    {
        "engDate": "1946-04-18",
        "nepDate": "06.01.2003"
    },
    {
        "engDate": "1946-04-19",
        "nepDate": "07.01.2003"
    },
    {
        "engDate": "1946-04-20",
        "nepDate": "08.01.2003"
    },
    {
        "engDate": "1946-04-21",
        "nepDate": "09.01.2003"
    },
    {
        "engDate": "1946-04-22",
        "nepDate": "10.01.2003"
    },
    {
        "engDate": "1946-04-23",
        "nepDate": "11.01.2003"
    },
    {
        "engDate": "1946-04-24",
        "nepDate": "12.01.2003"
    },
    {
        "engDate": "1946-04-25",
        "nepDate": "13.01.2003"
    },
    {
        "engDate": "1946-04-26",
        "nepDate": "14.01.2003"
    },
    {
        "engDate": "1946-04-27",
        "nepDate": "15.01.2003"
    },
    {
        "engDate": "1946-04-28",
        "nepDate": "16.01.2003"
    },
    {
        "engDate": "1946-04-29",
        "nepDate": "17.01.2003"
    },
    {
        "engDate": "1946-04-30",
        "nepDate": "18.01.2003"
    },
    {
        "engDate": "1946-05-01",
        "nepDate": "19.01.2003"
    },
    {
        "engDate": "1946-05-02",
        "nepDate": "20.01.2003"
    },
    {
        "engDate": "1946-05-03",
        "nepDate": "21.01.2003"
    },
    {
        "engDate": "1946-05-04",
        "nepDate": "22.01.2003"
    },
    {
        "engDate": "1946-05-05",
        "nepDate": "23.01.2003"
    },
    {
        "engDate": "1946-05-06",
        "nepDate": "24.01.2003"
    },
    {
        "engDate": "1946-05-07",
        "nepDate": "25.01.2003"
    },
    {
        "engDate": "1946-05-08",
        "nepDate": "26.01.2003"
    },
    {
        "engDate": "1946-05-09",
        "nepDate": "27.01.2003"
    },
    {
        "engDate": "1946-05-10",
        "nepDate": "28.01.2003"
    },
    {
        "engDate": "1946-05-11",
        "nepDate": "29.01.2003"
    },
    {
        "engDate": "1946-05-12",
        "nepDate": "30.01.2003"
    },
    {
        "engDate": "1946-05-13",
        "nepDate": "31.01.2003"
    },
    {
        "engDate": "1946-05-14",
        "nepDate": "01.02.2003"
    },
    {
        "engDate": "1946-05-15",
        "nepDate": "02.02.2003"
    },
    {
        "engDate": "1946-05-16",
        "nepDate": "03.02.2003"
    },
    {
        "engDate": "1946-05-17",
        "nepDate": "04.02.2003"
    },
    {
        "engDate": "1946-05-18",
        "nepDate": "05.02.2003"
    },
    {
        "engDate": "1946-05-19",
        "nepDate": "06.02.2003"
    },
    {
        "engDate": "1946-05-20",
        "nepDate": "07.02.2003"
    },
    {
        "engDate": "1946-05-21",
        "nepDate": "08.02.2003"
    },
    {
        "engDate": "1946-05-22",
        "nepDate": "09.02.2003"
    },
    {
        "engDate": "1946-05-23",
        "nepDate": "10.02.2003"
    },
    {
        "engDate": "1946-05-24",
        "nepDate": "11.02.2003"
    },
    {
        "engDate": "1946-05-25",
        "nepDate": "12.02.2003"
    },
    {
        "engDate": "1946-05-26",
        "nepDate": "13.02.2003"
    },
    {
        "engDate": "1946-05-27",
        "nepDate": "14.02.2003"
    },
    {
        "engDate": "1946-05-28",
        "nepDate": "15.02.2003"
    },
    {
        "engDate": "1946-05-29",
        "nepDate": "16.02.2003"
    },
    {
        "engDate": "1946-05-30",
        "nepDate": "17.02.2003"
    },
    {
        "engDate": "1946-05-31",
        "nepDate": "18.02.2003"
    },
    {
        "engDate": "1946-06-01",
        "nepDate": "19.02.2003"
    },
    {
        "engDate": "1946-06-02",
        "nepDate": "20.02.2003"
    },
    {
        "engDate": "1946-06-03",
        "nepDate": "21.02.2003"
    },
    {
        "engDate": "1946-06-04",
        "nepDate": "22.02.2003"
    },
    {
        "engDate": "1946-06-05",
        "nepDate": "23.02.2003"
    },
    {
        "engDate": "1946-06-06",
        "nepDate": "24.02.2003"
    },
    {
        "engDate": "1946-06-07",
        "nepDate": "25.02.2003"
    },
    {
        "engDate": "1946-06-08",
        "nepDate": "26.02.2003"
    },
    {
        "engDate": "1946-06-09",
        "nepDate": "27.02.2003"
    },
    {
        "engDate": "1946-06-10",
        "nepDate": "28.02.2003"
    },
    {
        "engDate": "1946-06-11",
        "nepDate": "29.02.2003"
    },
    {
        "engDate": "1946-06-12",
        "nepDate": "30.02.2003"
    },
    {
        "engDate": "1946-06-13",
        "nepDate": "31.02.2003"
    },
    {
        "engDate": "1946-06-14",
        "nepDate": "32.02.2003"
    },
    {
        "engDate": "1946-06-15",
        "nepDate": "01.03.2003"
    },
    {
        "engDate": "1946-06-16",
        "nepDate": "02.03.2003"
    },
    {
        "engDate": "1946-06-17",
        "nepDate": "03.03.2003"
    },
    {
        "engDate": "1946-06-18",
        "nepDate": "04.03.2003"
    },
    {
        "engDate": "1946-06-19",
        "nepDate": "05.03.2003"
    },
    {
        "engDate": "1946-06-20",
        "nepDate": "06.03.2003"
    },
    {
        "engDate": "1946-06-21",
        "nepDate": "07.03.2003"
    },
    {
        "engDate": "1946-06-22",
        "nepDate": "08.03.2003"
    },
    {
        "engDate": "1946-06-23",
        "nepDate": "09.03.2003"
    },
    {
        "engDate": "1946-06-24",
        "nepDate": "10.03.2003"
    },
    {
        "engDate": "1946-06-25",
        "nepDate": "11.03.2003"
    },
    {
        "engDate": "1946-06-26",
        "nepDate": "12.03.2003"
    },
    {
        "engDate": "1946-06-27",
        "nepDate": "13.03.2003"
    },
    {
        "engDate": "1946-06-28",
        "nepDate": "14.03.2003"
    },
    {
        "engDate": "1946-06-29",
        "nepDate": "15.03.2003"
    },
    {
        "engDate": "1946-06-30",
        "nepDate": "16.03.2003"
    },
    {
        "engDate": "1946-07-01",
        "nepDate": "17.03.2003"
    },
    {
        "engDate": "1946-07-02",
        "nepDate": "18.03.2003"
    },
    {
        "engDate": "1946-07-03",
        "nepDate": "19.03.2003"
    },
    {
        "engDate": "1946-07-04",
        "nepDate": "20.03.2003"
    },
    {
        "engDate": "1946-07-05",
        "nepDate": "21.03.2003"
    },
    {
        "engDate": "1946-07-06",
        "nepDate": "22.03.2003"
    },
    {
        "engDate": "1946-07-07",
        "nepDate": "23.03.2003"
    },
    {
        "engDate": "1946-07-08",
        "nepDate": "24.03.2003"
    },
    {
        "engDate": "1946-07-09",
        "nepDate": "25.03.2003"
    },
    {
        "engDate": "1946-07-10",
        "nepDate": "26.03.2003"
    },
    {
        "engDate": "1946-07-11",
        "nepDate": "27.03.2003"
    },
    {
        "engDate": "1946-07-12",
        "nepDate": "28.03.2003"
    },
    {
        "engDate": "1946-07-13",
        "nepDate": "29.03.2003"
    },
    {
        "engDate": "1946-07-14",
        "nepDate": "30.03.2003"
    },
    {
        "engDate": "1946-07-15",
        "nepDate": "31.03.2003"
    },
    {
        "engDate": "1946-07-16",
        "nepDate": "01.04.2003"
    },
    {
        "engDate": "1946-07-17",
        "nepDate": "02.04.2003"
    },
    {
        "engDate": "1946-07-18",
        "nepDate": "03.04.2003"
    },
    {
        "engDate": "1946-07-19",
        "nepDate": "04.04.2003"
    },
    {
        "engDate": "1946-07-20",
        "nepDate": "05.04.2003"
    },
    {
        "engDate": "1946-07-21",
        "nepDate": "06.04.2003"
    },
    {
        "engDate": "1946-07-22",
        "nepDate": "07.04.2003"
    },
    {
        "engDate": "1946-07-23",
        "nepDate": "08.04.2003"
    },
    {
        "engDate": "1946-07-24",
        "nepDate": "09.04.2003"
    },
    {
        "engDate": "1946-07-25",
        "nepDate": "10.04.2003"
    },
    {
        "engDate": "1946-07-26",
        "nepDate": "11.04.2003"
    },
    {
        "engDate": "1946-07-27",
        "nepDate": "12.04.2003"
    },
    {
        "engDate": "1946-07-28",
        "nepDate": "13.04.2003"
    },
    {
        "engDate": "1946-07-29",
        "nepDate": "14.04.2003"
    },
    {
        "engDate": "1946-07-30",
        "nepDate": "15.04.2003"
    },
    {
        "engDate": "1946-07-31",
        "nepDate": "16.04.2003"
    },
    {
        "engDate": "1946-08-01",
        "nepDate": "17.04.2003"
    },
    {
        "engDate": "1946-08-02",
        "nepDate": "18.04.2003"
    },
    {
        "engDate": "1946-08-03",
        "nepDate": "19.04.2003"
    },
    {
        "engDate": "1946-08-04",
        "nepDate": "20.04.2003"
    },
    {
        "engDate": "1946-08-05",
        "nepDate": "21.04.2003"
    },
    {
        "engDate": "1946-08-06",
        "nepDate": "22.04.2003"
    },
    {
        "engDate": "1946-08-07",
        "nepDate": "23.04.2003"
    },
    {
        "engDate": "1946-08-08",
        "nepDate": "24.04.2003"
    },
    {
        "engDate": "1946-08-09",
        "nepDate": "25.04.2003"
    },
    {
        "engDate": "1946-08-10",
        "nepDate": "26.04.2003"
    },
    {
        "engDate": "1946-08-11",
        "nepDate": "27.04.2003"
    },
    {
        "engDate": "1946-08-12",
        "nepDate": "28.04.2003"
    },
    {
        "engDate": "1946-08-13",
        "nepDate": "29.04.2003"
    },
    {
        "engDate": "1946-08-14",
        "nepDate": "30.04.2003"
    },
    {
        "engDate": "1946-08-15",
        "nepDate": "31.04.2003"
    },
    {
        "engDate": "1946-08-16",
        "nepDate": "32.04.2003"
    },
    {
        "engDate": "1946-08-17",
        "nepDate": "01.05.2003"
    },
    {
        "engDate": "1946-08-18",
        "nepDate": "02.05.2003"
    },
    {
        "engDate": "1946-08-19",
        "nepDate": "03.05.2003"
    },
    {
        "engDate": "1946-08-20",
        "nepDate": "04.05.2003"
    },
    {
        "engDate": "1946-08-21",
        "nepDate": "05.05.2003"
    },
    {
        "engDate": "1946-08-22",
        "nepDate": "06.05.2003"
    },
    {
        "engDate": "1946-08-23",
        "nepDate": "07.05.2003"
    },
    {
        "engDate": "1946-08-24",
        "nepDate": "08.05.2003"
    },
    {
        "engDate": "1946-08-25",
        "nepDate": "09.05.2003"
    },
    {
        "engDate": "1946-08-26",
        "nepDate": "10.05.2003"
    },
    {
        "engDate": "1946-08-27",
        "nepDate": "11.05.2003"
    },
    {
        "engDate": "1946-08-28",
        "nepDate": "12.05.2003"
    },
    {
        "engDate": "1946-08-29",
        "nepDate": "13.05.2003"
    },
    {
        "engDate": "1946-08-30",
        "nepDate": "14.05.2003"
    },
    {
        "engDate": "1946-08-31",
        "nepDate": "15.05.2003"
    },
    {
        "engDate": "1946-09-01",
        "nepDate": "16.05.2003"
    },
    {
        "engDate": "1946-09-02",
        "nepDate": "17.05.2003"
    },
    {
        "engDate": "1946-09-03",
        "nepDate": "18.05.2003"
    },
    {
        "engDate": "1946-09-04",
        "nepDate": "19.05.2003"
    },
    {
        "engDate": "1946-09-05",
        "nepDate": "20.05.2003"
    },
    {
        "engDate": "1946-09-06",
        "nepDate": "21.05.2003"
    },
    {
        "engDate": "1946-09-07",
        "nepDate": "22.05.2003"
    },
    {
        "engDate": "1946-09-08",
        "nepDate": "23.05.2003"
    },
    {
        "engDate": "1946-09-09",
        "nepDate": "24.05.2003"
    },
    {
        "engDate": "1946-09-10",
        "nepDate": "25.05.2003"
    },
    {
        "engDate": "1946-09-11",
        "nepDate": "26.05.2003"
    },
    {
        "engDate": "1946-09-12",
        "nepDate": "27.05.2003"
    },
    {
        "engDate": "1946-09-13",
        "nepDate": "28.05.2003"
    },
    {
        "engDate": "1946-09-14",
        "nepDate": "29.05.2003"
    },
    {
        "engDate": "1946-09-15",
        "nepDate": "30.05.2003"
    },
    {
        "engDate": "1946-09-16",
        "nepDate": "31.05.2003"
    },
    {
        "engDate": "1946-09-17",
        "nepDate": "01.06.2003"
    },
    {
        "engDate": "1946-09-18",
        "nepDate": "02.06.2003"
    },
    {
        "engDate": "1946-09-19",
        "nepDate": "03.06.2003"
    },
    {
        "engDate": "1946-09-20",
        "nepDate": "04.06.2003"
    },
    {
        "engDate": "1946-09-21",
        "nepDate": "05.06.2003"
    },
    {
        "engDate": "1946-09-22",
        "nepDate": "06.06.2003"
    },
    {
        "engDate": "1946-09-23",
        "nepDate": "07.06.2003"
    },
    {
        "engDate": "1946-09-24",
        "nepDate": "08.06.2003"
    },
    {
        "engDate": "1946-09-25",
        "nepDate": "09.06.2003"
    },
    {
        "engDate": "1946-09-26",
        "nepDate": "10.06.2003"
    },
    {
        "engDate": "1946-09-27",
        "nepDate": "11.06.2003"
    },
    {
        "engDate": "1946-09-28",
        "nepDate": "12.06.2003"
    },
    {
        "engDate": "1946-09-29",
        "nepDate": "13.06.2003"
    },
    {
        "engDate": "1946-09-30",
        "nepDate": "14.06.2003"
    },
    {
        "engDate": "1946-10-01",
        "nepDate": "15.06.2003"
    },
    {
        "engDate": "1946-10-02",
        "nepDate": "16.06.2003"
    },
    {
        "engDate": "1946-10-03",
        "nepDate": "17.06.2003"
    },
    {
        "engDate": "1946-10-04",
        "nepDate": "18.06.2003"
    },
    {
        "engDate": "1946-10-05",
        "nepDate": "19.06.2003"
    },
    {
        "engDate": "1946-10-06",
        "nepDate": "20.06.2003"
    },
    {
        "engDate": "1946-10-07",
        "nepDate": "21.06.2003"
    },
    {
        "engDate": "1946-10-08",
        "nepDate": "22.06.2003"
    },
    {
        "engDate": "1946-10-09",
        "nepDate": "23.06.2003"
    },
    {
        "engDate": "1946-10-10",
        "nepDate": "24.06.2003"
    },
    {
        "engDate": "1946-10-11",
        "nepDate": "25.06.2003"
    },
    {
        "engDate": "1946-10-12",
        "nepDate": "26.06.2003"
    },
    {
        "engDate": "1946-10-13",
        "nepDate": "27.06.2003"
    },
    {
        "engDate": "1946-10-14",
        "nepDate": "28.06.2003"
    },
    {
        "engDate": "1946-10-15",
        "nepDate": "29.06.2003"
    },
    {
        "engDate": "1946-10-16",
        "nepDate": "30.06.2003"
    },
    {
        "engDate": "1946-10-17",
        "nepDate": "01.07.2003"
    },
    {
        "engDate": "1946-10-18",
        "nepDate": "02.07.2003"
    },
    {
        "engDate": "1946-10-19",
        "nepDate": "03.07.2003"
    },
    {
        "engDate": "1946-10-20",
        "nepDate": "04.07.2003"
    },
    {
        "engDate": "1946-10-21",
        "nepDate": "05.07.2003"
    },
    {
        "engDate": "1946-10-22",
        "nepDate": "06.07.2003"
    },
    {
        "engDate": "1946-10-23",
        "nepDate": "07.07.2003"
    },
    {
        "engDate": "1946-10-24",
        "nepDate": "08.07.2003"
    },
    {
        "engDate": "1946-10-25",
        "nepDate": "09.07.2003"
    },
    {
        "engDate": "1946-10-26",
        "nepDate": "10.07.2003"
    },
    {
        "engDate": "1946-10-27",
        "nepDate": "11.07.2003"
    },
    {
        "engDate": "1946-10-28",
        "nepDate": "12.07.2003"
    },
    {
        "engDate": "1946-10-29",
        "nepDate": "13.07.2003"
    },
    {
        "engDate": "1946-10-30",
        "nepDate": "14.07.2003"
    },
    {
        "engDate": "1946-10-31",
        "nepDate": "15.07.2003"
    },
    {
        "engDate": "1946-11-01",
        "nepDate": "16.07.2003"
    },
    {
        "engDate": "1946-11-02",
        "nepDate": "17.07.2003"
    },
    {
        "engDate": "1946-11-03",
        "nepDate": "18.07.2003"
    },
    {
        "engDate": "1946-11-04",
        "nepDate": "19.07.2003"
    },
    {
        "engDate": "1946-11-05",
        "nepDate": "20.07.2003"
    },
    {
        "engDate": "1946-11-06",
        "nepDate": "21.07.2003"
    },
    {
        "engDate": "1946-11-07",
        "nepDate": "22.07.2003"
    },
    {
        "engDate": "1946-11-08",
        "nepDate": "23.07.2003"
    },
    {
        "engDate": "1946-11-09",
        "nepDate": "24.07.2003"
    },
    {
        "engDate": "1946-11-10",
        "nepDate": "25.07.2003"
    },
    {
        "engDate": "1946-11-11",
        "nepDate": "26.07.2003"
    },
    {
        "engDate": "1946-11-12",
        "nepDate": "27.07.2003"
    },
    {
        "engDate": "1946-11-13",
        "nepDate": "28.07.2003"
    },
    {
        "engDate": "1946-11-14",
        "nepDate": "29.07.2003"
    },
    {
        "engDate": "1946-11-15",
        "nepDate": "30.07.2003"
    },
    {
        "engDate": "1946-11-16",
        "nepDate": "01.08.2003"
    },
    {
        "engDate": "1946-11-17",
        "nepDate": "02.08.2003"
    },
    {
        "engDate": "1946-11-18",
        "nepDate": "03.08.2003"
    },
    {
        "engDate": "1946-11-19",
        "nepDate": "04.08.2003"
    },
    {
        "engDate": "1946-11-20",
        "nepDate": "05.08.2003"
    },
    {
        "engDate": "1946-11-21",
        "nepDate": "06.08.2003"
    },
    {
        "engDate": "1946-11-22",
        "nepDate": "07.08.2003"
    },
    {
        "engDate": "1946-11-23",
        "nepDate": "08.08.2003"
    },
    {
        "engDate": "1946-11-24",
        "nepDate": "09.08.2003"
    },
    {
        "engDate": "1946-11-25",
        "nepDate": "10.08.2003"
    },
    {
        "engDate": "1946-11-26",
        "nepDate": "11.08.2003"
    },
    {
        "engDate": "1946-11-27",
        "nepDate": "12.08.2003"
    },
    {
        "engDate": "1946-11-28",
        "nepDate": "13.08.2003"
    },
    {
        "engDate": "1946-11-29",
        "nepDate": "14.08.2003"
    },
    {
        "engDate": "1946-11-30",
        "nepDate": "15.08.2003"
    },
    {
        "engDate": "1946-12-01",
        "nepDate": "16.08.2003"
    },
    {
        "engDate": "1946-12-02",
        "nepDate": "17.08.2003"
    },
    {
        "engDate": "1946-12-03",
        "nepDate": "18.08.2003"
    },
    {
        "engDate": "1946-12-04",
        "nepDate": "19.08.2003"
    },
    {
        "engDate": "1946-12-05",
        "nepDate": "20.08.2003"
    },
    {
        "engDate": "1946-12-06",
        "nepDate": "21.08.2003"
    },
    {
        "engDate": "1946-12-07",
        "nepDate": "22.08.2003"
    },
    {
        "engDate": "1946-12-08",
        "nepDate": "23.08.2003"
    },
    {
        "engDate": "1946-12-09",
        "nepDate": "24.08.2003"
    },
    {
        "engDate": "1946-12-10",
        "nepDate": "25.08.2003"
    },
    {
        "engDate": "1946-12-11",
        "nepDate": "26.08.2003"
    },
    {
        "engDate": "1946-12-12",
        "nepDate": "27.08.2003"
    },
    {
        "engDate": "1946-12-13",
        "nepDate": "28.08.2003"
    },
    {
        "engDate": "1946-12-14",
        "nepDate": "29.08.2003"
    },
    {
        "engDate": "1946-12-15",
        "nepDate": "30.08.2003"
    },
    {
        "engDate": "1946-12-16",
        "nepDate": "01.09.2003"
    },
    {
        "engDate": "1946-12-17",
        "nepDate": "02.09.2003"
    },
    {
        "engDate": "1946-12-18",
        "nepDate": "03.09.2003"
    },
    {
        "engDate": "1946-12-19",
        "nepDate": "04.09.2003"
    },
    {
        "engDate": "1946-12-20",
        "nepDate": "05.09.2003"
    },
    {
        "engDate": "1946-12-21",
        "nepDate": "06.09.2003"
    },
    {
        "engDate": "1946-12-22",
        "nepDate": "07.09.2003"
    },
    {
        "engDate": "1946-12-23",
        "nepDate": "08.09.2003"
    },
    {
        "engDate": "1946-12-24",
        "nepDate": "09.09.2003"
    },
    {
        "engDate": "1946-12-25",
        "nepDate": "10.09.2003"
    },
    {
        "engDate": "1946-12-26",
        "nepDate": "11.09.2003"
    },
    {
        "engDate": "1946-12-27",
        "nepDate": "12.09.2003"
    },
    {
        "engDate": "1946-12-28",
        "nepDate": "13.09.2003"
    },
    {
        "engDate": "1946-12-29",
        "nepDate": "14.09.2003"
    },
    {
        "engDate": "1946-12-30",
        "nepDate": "15.09.2003"
    },
    {
        "engDate": "1946-12-31",
        "nepDate": "16.09.2003"
    },
    {
        "engDate": "1947-01-01",
        "nepDate": "17.09.2003"
    },
    {
        "engDate": "1947-01-02",
        "nepDate": "18.09.2003"
    },
    {
        "engDate": "1947-01-03",
        "nepDate": "19.09.2003"
    },
    {
        "engDate": "1947-01-04",
        "nepDate": "20.09.2003"
    },
    {
        "engDate": "1947-01-05",
        "nepDate": "21.09.2003"
    },
    {
        "engDate": "1947-01-06",
        "nepDate": "22.09.2003"
    },
    {
        "engDate": "1947-01-07",
        "nepDate": "23.09.2003"
    },
    {
        "engDate": "1947-01-08",
        "nepDate": "24.09.2003"
    },
    {
        "engDate": "1947-01-09",
        "nepDate": "25.09.2003"
    },
    {
        "engDate": "1947-01-10",
        "nepDate": "26.09.2003"
    },
    {
        "engDate": "1947-01-11",
        "nepDate": "27.09.2003"
    },
    {
        "engDate": "1947-01-12",
        "nepDate": "28.09.2003"
    },
    {
        "engDate": "1947-01-13",
        "nepDate": "29.09.2003"
    },
    {
        "engDate": "1947-01-14",
        "nepDate": "01.10.2003"
    },
    {
        "engDate": "1947-01-15",
        "nepDate": "02.10.2003"
    },
    {
        "engDate": "1947-01-16",
        "nepDate": "03.10.2003"
    },
    {
        "engDate": "1947-01-17",
        "nepDate": "04.10.2003"
    },
    {
        "engDate": "1947-01-18",
        "nepDate": "05.10.2003"
    },
    {
        "engDate": "1947-01-19",
        "nepDate": "06.10.2003"
    },
    {
        "engDate": "1947-01-20",
        "nepDate": "07.10.2003"
    },
    {
        "engDate": "1947-01-21",
        "nepDate": "08.10.2003"
    },
    {
        "engDate": "1947-01-22",
        "nepDate": "09.10.2003"
    },
    {
        "engDate": "1947-01-23",
        "nepDate": "10.10.2003"
    },
    {
        "engDate": "1947-01-24",
        "nepDate": "11.10.2003"
    },
    {
        "engDate": "1947-01-25",
        "nepDate": "12.10.2003"
    },
    {
        "engDate": "1947-01-26",
        "nepDate": "13.10.2003"
    },
    {
        "engDate": "1947-01-27",
        "nepDate": "14.10.2003"
    },
    {
        "engDate": "1947-01-28",
        "nepDate": "15.10.2003"
    },
    {
        "engDate": "1947-01-29",
        "nepDate": "16.10.2003"
    },
    {
        "engDate": "1947-01-30",
        "nepDate": "17.10.2003"
    },
    {
        "engDate": "1947-01-31",
        "nepDate": "18.10.2003"
    },
    {
        "engDate": "1947-02-01",
        "nepDate": "19.10.2003"
    },
    {
        "engDate": "1947-02-02",
        "nepDate": "20.10.2003"
    },
    {
        "engDate": "1947-02-03",
        "nepDate": "21.10.2003"
    },
    {
        "engDate": "1947-02-04",
        "nepDate": "22.10.2003"
    },
    {
        "engDate": "1947-02-05",
        "nepDate": "23.10.2003"
    },
    {
        "engDate": "1947-02-06",
        "nepDate": "24.10.2003"
    },
    {
        "engDate": "1947-02-07",
        "nepDate": "25.10.2003"
    },
    {
        "engDate": "1947-02-08",
        "nepDate": "26.10.2003"
    },
    {
        "engDate": "1947-02-09",
        "nepDate": "27.10.2003"
    },
    {
        "engDate": "1947-02-10",
        "nepDate": "28.10.2003"
    },
    {
        "engDate": "1947-02-11",
        "nepDate": "29.10.2003"
    },
    {
        "engDate": "1947-02-12",
        "nepDate": "01.11.2003"
    },
    {
        "engDate": "1947-02-13",
        "nepDate": "02.11.2003"
    },
    {
        "engDate": "1947-02-14",
        "nepDate": "03.11.2003"
    },
    {
        "engDate": "1947-02-15",
        "nepDate": "04.11.2003"
    },
    {
        "engDate": "1947-02-16",
        "nepDate": "05.11.2003"
    },
    {
        "engDate": "1947-02-17",
        "nepDate": "06.11.2003"
    },
    {
        "engDate": "1947-02-18",
        "nepDate": "07.11.2003"
    },
    {
        "engDate": "1947-02-19",
        "nepDate": "08.11.2003"
    },
    {
        "engDate": "1947-02-20",
        "nepDate": "09.11.2003"
    },
    {
        "engDate": "1947-02-21",
        "nepDate": "10.11.2003"
    },
    {
        "engDate": "1947-02-22",
        "nepDate": "11.11.2003"
    },
    {
        "engDate": "1947-02-23",
        "nepDate": "12.11.2003"
    },
    {
        "engDate": "1947-02-24",
        "nepDate": "13.11.2003"
    },
    {
        "engDate": "1947-02-25",
        "nepDate": "14.11.2003"
    },
    {
        "engDate": "1947-02-26",
        "nepDate": "15.11.2003"
    },
    {
        "engDate": "1947-02-27",
        "nepDate": "16.11.2003"
    },
    {
        "engDate": "1947-02-28",
        "nepDate": "17.11.2003"
    },
    {
        "engDate": "1947-03-01",
        "nepDate": "18.11.2003"
    },
    {
        "engDate": "1947-03-02",
        "nepDate": "19.11.2003"
    },
    {
        "engDate": "1947-03-03",
        "nepDate": "20.11.2003"
    },
    {
        "engDate": "1947-03-04",
        "nepDate": "21.11.2003"
    },
    {
        "engDate": "1947-03-05",
        "nepDate": "22.11.2003"
    },
    {
        "engDate": "1947-03-06",
        "nepDate": "23.11.2003"
    },
    {
        "engDate": "1947-03-07",
        "nepDate": "24.11.2003"
    },
    {
        "engDate": "1947-03-08",
        "nepDate": "25.11.2003"
    },
    {
        "engDate": "1947-03-09",
        "nepDate": "26.11.2003"
    },
    {
        "engDate": "1947-03-10",
        "nepDate": "27.11.2003"
    },
    {
        "engDate": "1947-03-11",
        "nepDate": "28.11.2003"
    },
    {
        "engDate": "1947-03-12",
        "nepDate": "29.11.2003"
    },
    {
        "engDate": "1947-03-13",
        "nepDate": "30.11.2003"
    },
    {
        "engDate": "1947-03-14",
        "nepDate": "01.12.2003"
    },
    {
        "engDate": "1947-03-15",
        "nepDate": "02.12.2003"
    },
    {
        "engDate": "1947-03-16",
        "nepDate": "03.12.2003"
    },
    {
        "engDate": "1947-03-17",
        "nepDate": "04.12.2003"
    },
    {
        "engDate": "1947-03-18",
        "nepDate": "05.12.2003"
    },
    {
        "engDate": "1947-03-19",
        "nepDate": "06.12.2003"
    },
    {
        "engDate": "1947-03-20",
        "nepDate": "07.12.2003"
    },
    {
        "engDate": "1947-03-21",
        "nepDate": "08.12.2003"
    },
    {
        "engDate": "1947-03-22",
        "nepDate": "09.12.2003"
    },
    {
        "engDate": "1947-03-23",
        "nepDate": "10.12.2003"
    },
    {
        "engDate": "1947-03-24",
        "nepDate": "11.12.2003"
    },
    {
        "engDate": "1947-03-25",
        "nepDate": "12.12.2003"
    },
    {
        "engDate": "1947-03-26",
        "nepDate": "13.12.2003"
    },
    {
        "engDate": "1947-03-27",
        "nepDate": "14.12.2003"
    },
    {
        "engDate": "1947-03-28",
        "nepDate": "15.12.2003"
    },
    {
        "engDate": "1947-03-29",
        "nepDate": "16.12.2003"
    },
    {
        "engDate": "1947-03-30",
        "nepDate": "17.12.2003"
    },
    {
        "engDate": "1947-03-31",
        "nepDate": "18.12.2003"
    },
    {
        "engDate": "1947-04-01",
        "nepDate": "19.12.2003"
    },
    {
        "engDate": "1947-04-02",
        "nepDate": "20.12.2003"
    },
    {
        "engDate": "1947-04-03",
        "nepDate": "21.12.2003"
    },
    {
        "engDate": "1947-04-04",
        "nepDate": "22.12.2003"
    },
    {
        "engDate": "1947-04-05",
        "nepDate": "23.12.2003"
    },
    {
        "engDate": "1947-04-06",
        "nepDate": "24.12.2003"
    },
    {
        "engDate": "1947-04-07",
        "nepDate": "25.12.2003"
    },
    {
        "engDate": "1947-04-08",
        "nepDate": "26.12.2003"
    },
    {
        "engDate": "1947-04-09",
        "nepDate": "27.12.2003"
    },
    {
        "engDate": "1947-04-10",
        "nepDate": "28.12.2003"
    },
    {
        "engDate": "1947-04-11",
        "nepDate": "29.12.2003"
    },
    {
        "engDate": "1947-04-12",
        "nepDate": "30.12.2003"
    },
    {
        "engDate": "1947-04-13",
        "nepDate": "31.12.2003"
    },
    {
        "engDate": "1947-04-14",
        "nepDate": "01.01.2004"
    },
    {
        "engDate": "1947-04-15",
        "nepDate": "02.01.2004"
    },
    {
        "engDate": "1947-04-16",
        "nepDate": "03.01.2004"
    },
    {
        "engDate": "1947-04-17",
        "nepDate": "04.01.2004"
    },
    {
        "engDate": "1947-04-18",
        "nepDate": "05.01.2004"
    },
    {
        "engDate": "1947-04-19",
        "nepDate": "06.01.2004"
    },
    {
        "engDate": "1947-04-20",
        "nepDate": "07.01.2004"
    },
    {
        "engDate": "1947-04-21",
        "nepDate": "08.01.2004"
    },
    {
        "engDate": "1947-04-22",
        "nepDate": "09.01.2004"
    },
    {
        "engDate": "1947-04-23",
        "nepDate": "10.01.2004"
    },
    {
        "engDate": "1947-04-24",
        "nepDate": "11.01.2004"
    },
    {
        "engDate": "1947-04-25",
        "nepDate": "12.01.2004"
    },
    {
        "engDate": "1947-04-26",
        "nepDate": "13.01.2004"
    },
    {
        "engDate": "1947-04-27",
        "nepDate": "14.01.2004"
    },
    {
        "engDate": "1947-04-28",
        "nepDate": "15.01.2004"
    },
    {
        "engDate": "1947-04-29",
        "nepDate": "16.01.2004"
    },
    {
        "engDate": "1947-04-30",
        "nepDate": "17.01.2004"
    },
    {
        "engDate": "1947-05-01",
        "nepDate": "18.01.2004"
    },
    {
        "engDate": "1947-05-02",
        "nepDate": "19.01.2004"
    },
    {
        "engDate": "1947-05-03",
        "nepDate": "20.01.2004"
    },
    {
        "engDate": "1947-05-04",
        "nepDate": "21.01.2004"
    },
    {
        "engDate": "1947-05-05",
        "nepDate": "22.01.2004"
    },
    {
        "engDate": "1947-05-06",
        "nepDate": "23.01.2004"
    },
    {
        "engDate": "1947-05-07",
        "nepDate": "24.01.2004"
    },
    {
        "engDate": "1947-05-08",
        "nepDate": "25.01.2004"
    },
    {
        "engDate": "1947-05-09",
        "nepDate": "26.01.2004"
    },
    {
        "engDate": "1947-05-10",
        "nepDate": "27.01.2004"
    },
    {
        "engDate": "1947-05-11",
        "nepDate": "28.01.2004"
    },
    {
        "engDate": "1947-05-12",
        "nepDate": "29.01.2004"
    },
    {
        "engDate": "1947-05-13",
        "nepDate": "30.01.2004"
    },
    {
        "engDate": "1947-05-14",
        "nepDate": "01.02.2004"
    },
    {
        "engDate": "1947-05-15",
        "nepDate": "02.02.2004"
    },
    {
        "engDate": "1947-05-16",
        "nepDate": "03.02.2004"
    },
    {
        "engDate": "1947-05-17",
        "nepDate": "04.02.2004"
    },
    {
        "engDate": "1947-05-18",
        "nepDate": "05.02.2004"
    },
    {
        "engDate": "1947-05-19",
        "nepDate": "06.02.2004"
    },
    {
        "engDate": "1947-05-20",
        "nepDate": "07.02.2004"
    },
    {
        "engDate": "1947-05-21",
        "nepDate": "08.02.2004"
    },
    {
        "engDate": "1947-05-22",
        "nepDate": "09.02.2004"
    },
    {
        "engDate": "1947-05-23",
        "nepDate": "10.02.2004"
    },
    {
        "engDate": "1947-05-24",
        "nepDate": "11.02.2004"
    },
    {
        "engDate": "1947-05-25",
        "nepDate": "12.02.2004"
    },
    {
        "engDate": "1947-05-26",
        "nepDate": "13.02.2004"
    },
    {
        "engDate": "1947-05-27",
        "nepDate": "14.02.2004"
    },
    {
        "engDate": "1947-05-28",
        "nepDate": "15.02.2004"
    },
    {
        "engDate": "1947-05-29",
        "nepDate": "16.02.2004"
    },
    {
        "engDate": "1947-05-30",
        "nepDate": "17.02.2004"
    },
    {
        "engDate": "1947-05-31",
        "nepDate": "18.02.2004"
    },
    {
        "engDate": "1947-06-01",
        "nepDate": "19.02.2004"
    },
    {
        "engDate": "1947-06-02",
        "nepDate": "20.02.2004"
    },
    {
        "engDate": "1947-06-03",
        "nepDate": "21.02.2004"
    },
    {
        "engDate": "1947-06-04",
        "nepDate": "22.02.2004"
    },
    {
        "engDate": "1947-06-05",
        "nepDate": "23.02.2004"
    },
    {
        "engDate": "1947-06-06",
        "nepDate": "24.02.2004"
    },
    {
        "engDate": "1947-06-07",
        "nepDate": "25.02.2004"
    },
    {
        "engDate": "1947-06-08",
        "nepDate": "26.02.2004"
    },
    {
        "engDate": "1947-06-09",
        "nepDate": "27.02.2004"
    },
    {
        "engDate": "1947-06-10",
        "nepDate": "28.02.2004"
    },
    {
        "engDate": "1947-06-11",
        "nepDate": "29.02.2004"
    },
    {
        "engDate": "1947-06-12",
        "nepDate": "30.02.2004"
    },
    {
        "engDate": "1947-06-13",
        "nepDate": "31.02.2004"
    },
    {
        "engDate": "1947-06-14",
        "nepDate": "32.02.2004"
    },
    {
        "engDate": "1947-06-15",
        "nepDate": "01.03.2004"
    },
    {
        "engDate": "1947-06-16",
        "nepDate": "02.03.2004"
    },
    {
        "engDate": "1947-06-17",
        "nepDate": "03.03.2004"
    },
    {
        "engDate": "1947-06-18",
        "nepDate": "04.03.2004"
    },
    {
        "engDate": "1947-06-19",
        "nepDate": "05.03.2004"
    },
    {
        "engDate": "1947-06-20",
        "nepDate": "06.03.2004"
    },
    {
        "engDate": "1947-06-21",
        "nepDate": "07.03.2004"
    },
    {
        "engDate": "1947-06-22",
        "nepDate": "08.03.2004"
    },
    {
        "engDate": "1947-06-23",
        "nepDate": "09.03.2004"
    },
    {
        "engDate": "1947-06-24",
        "nepDate": "10.03.2004"
    },
    {
        "engDate": "1947-06-25",
        "nepDate": "11.03.2004"
    },
    {
        "engDate": "1947-06-26",
        "nepDate": "12.03.2004"
    },
    {
        "engDate": "1947-06-27",
        "nepDate": "13.03.2004"
    },
    {
        "engDate": "1947-06-28",
        "nepDate": "14.03.2004"
    },
    {
        "engDate": "1947-06-29",
        "nepDate": "15.03.2004"
    },
    {
        "engDate": "1947-06-30",
        "nepDate": "16.03.2004"
    },
    {
        "engDate": "1947-07-01",
        "nepDate": "17.03.2004"
    },
    {
        "engDate": "1947-07-02",
        "nepDate": "18.03.2004"
    },
    {
        "engDate": "1947-07-03",
        "nepDate": "19.03.2004"
    },
    {
        "engDate": "1947-07-04",
        "nepDate": "20.03.2004"
    },
    {
        "engDate": "1947-07-05",
        "nepDate": "21.03.2004"
    },
    {
        "engDate": "1947-07-06",
        "nepDate": "22.03.2004"
    },
    {
        "engDate": "1947-07-07",
        "nepDate": "23.03.2004"
    },
    {
        "engDate": "1947-07-08",
        "nepDate": "24.03.2004"
    },
    {
        "engDate": "1947-07-09",
        "nepDate": "25.03.2004"
    },
    {
        "engDate": "1947-07-10",
        "nepDate": "26.03.2004"
    },
    {
        "engDate": "1947-07-11",
        "nepDate": "27.03.2004"
    },
    {
        "engDate": "1947-07-12",
        "nepDate": "28.03.2004"
    },
    {
        "engDate": "1947-07-13",
        "nepDate": "29.03.2004"
    },
    {
        "engDate": "1947-07-14",
        "nepDate": "30.03.2004"
    },
    {
        "engDate": "1947-07-15",
        "nepDate": "31.03.2004"
    },
    {
        "engDate": "1947-07-16",
        "nepDate": "01.04.2004"
    },
    {
        "engDate": "1947-07-17",
        "nepDate": "02.04.2004"
    },
    {
        "engDate": "1947-07-18",
        "nepDate": "03.04.2004"
    },
    {
        "engDate": "1947-07-19",
        "nepDate": "04.04.2004"
    },
    {
        "engDate": "1947-07-20",
        "nepDate": "05.04.2004"
    },
    {
        "engDate": "1947-07-21",
        "nepDate": "06.04.2004"
    },
    {
        "engDate": "1947-07-22",
        "nepDate": "07.04.2004"
    },
    {
        "engDate": "1947-07-23",
        "nepDate": "08.04.2004"
    },
    {
        "engDate": "1947-07-24",
        "nepDate": "09.04.2004"
    },
    {
        "engDate": "1947-07-25",
        "nepDate": "10.04.2004"
    },
    {
        "engDate": "1947-07-26",
        "nepDate": "11.04.2004"
    },
    {
        "engDate": "1947-07-27",
        "nepDate": "12.04.2004"
    },
    {
        "engDate": "1947-07-28",
        "nepDate": "13.04.2004"
    },
    {
        "engDate": "1947-07-29",
        "nepDate": "14.04.2004"
    },
    {
        "engDate": "1947-07-30",
        "nepDate": "15.04.2004"
    },
    {
        "engDate": "1947-07-31",
        "nepDate": "16.04.2004"
    },
    {
        "engDate": "1947-08-01",
        "nepDate": "17.04.2004"
    },
    {
        "engDate": "1947-08-02",
        "nepDate": "18.04.2004"
    },
    {
        "engDate": "1947-08-03",
        "nepDate": "19.04.2004"
    },
    {
        "engDate": "1947-08-04",
        "nepDate": "20.04.2004"
    },
    {
        "engDate": "1947-08-05",
        "nepDate": "21.04.2004"
    },
    {
        "engDate": "1947-08-06",
        "nepDate": "22.04.2004"
    },
    {
        "engDate": "1947-08-07",
        "nepDate": "23.04.2004"
    },
    {
        "engDate": "1947-08-08",
        "nepDate": "24.04.2004"
    },
    {
        "engDate": "1947-08-09",
        "nepDate": "25.04.2004"
    },
    {
        "engDate": "1947-08-10",
        "nepDate": "26.04.2004"
    },
    {
        "engDate": "1947-08-11",
        "nepDate": "27.04.2004"
    },
    {
        "engDate": "1947-08-12",
        "nepDate": "28.04.2004"
    },
    {
        "engDate": "1947-08-13",
        "nepDate": "29.04.2004"
    },
    {
        "engDate": "1947-08-14",
        "nepDate": "30.04.2004"
    },
    {
        "engDate": "1947-08-15",
        "nepDate": "31.04.2004"
    },
    {
        "engDate": "1947-08-16",
        "nepDate": "32.04.2004"
    },
    {
        "engDate": "1947-08-17",
        "nepDate": "01.05.2004"
    },
    {
        "engDate": "1947-08-18",
        "nepDate": "02.05.2004"
    },
    {
        "engDate": "1947-08-19",
        "nepDate": "03.05.2004"
    },
    {
        "engDate": "1947-08-20",
        "nepDate": "04.05.2004"
    },
    {
        "engDate": "1947-08-21",
        "nepDate": "05.05.2004"
    },
    {
        "engDate": "1947-08-22",
        "nepDate": "06.05.2004"
    },
    {
        "engDate": "1947-08-23",
        "nepDate": "07.05.2004"
    },
    {
        "engDate": "1947-08-24",
        "nepDate": "08.05.2004"
    },
    {
        "engDate": "1947-08-25",
        "nepDate": "09.05.2004"
    },
    {
        "engDate": "1947-08-26",
        "nepDate": "10.05.2004"
    },
    {
        "engDate": "1947-08-27",
        "nepDate": "11.05.2004"
    },
    {
        "engDate": "1947-08-28",
        "nepDate": "12.05.2004"
    },
    {
        "engDate": "1947-08-29",
        "nepDate": "13.05.2004"
    },
    {
        "engDate": "1947-08-30",
        "nepDate": "14.05.2004"
    },
    {
        "engDate": "1947-08-31",
        "nepDate": "15.05.2004"
    },
    {
        "engDate": "1947-09-01",
        "nepDate": "16.05.2004"
    },
    {
        "engDate": "1947-09-02",
        "nepDate": "17.05.2004"
    },
    {
        "engDate": "1947-09-03",
        "nepDate": "18.05.2004"
    },
    {
        "engDate": "1947-09-04",
        "nepDate": "19.05.2004"
    },
    {
        "engDate": "1947-09-05",
        "nepDate": "20.05.2004"
    },
    {
        "engDate": "1947-09-06",
        "nepDate": "21.05.2004"
    },
    {
        "engDate": "1947-09-07",
        "nepDate": "22.05.2004"
    },
    {
        "engDate": "1947-09-08",
        "nepDate": "23.05.2004"
    },
    {
        "engDate": "1947-09-09",
        "nepDate": "24.05.2004"
    },
    {
        "engDate": "1947-09-10",
        "nepDate": "25.05.2004"
    },
    {
        "engDate": "1947-09-11",
        "nepDate": "26.05.2004"
    },
    {
        "engDate": "1947-09-12",
        "nepDate": "27.05.2004"
    },
    {
        "engDate": "1947-09-13",
        "nepDate": "28.05.2004"
    },
    {
        "engDate": "1947-09-14",
        "nepDate": "29.05.2004"
    },
    {
        "engDate": "1947-09-15",
        "nepDate": "30.05.2004"
    },
    {
        "engDate": "1947-09-16",
        "nepDate": "31.05.2004"
    },
    {
        "engDate": "1947-09-17",
        "nepDate": "01.06.2004"
    },
    {
        "engDate": "1947-09-18",
        "nepDate": "02.06.2004"
    },
    {
        "engDate": "1947-09-19",
        "nepDate": "03.06.2004"
    },
    {
        "engDate": "1947-09-20",
        "nepDate": "04.06.2004"
    },
    {
        "engDate": "1947-09-21",
        "nepDate": "05.06.2004"
    },
    {
        "engDate": "1947-09-22",
        "nepDate": "06.06.2004"
    },
    {
        "engDate": "1947-09-23",
        "nepDate": "07.06.2004"
    },
    {
        "engDate": "1947-09-24",
        "nepDate": "08.06.2004"
    },
    {
        "engDate": "1947-09-25",
        "nepDate": "09.06.2004"
    },
    {
        "engDate": "1947-09-26",
        "nepDate": "10.06.2004"
    },
    {
        "engDate": "1947-09-27",
        "nepDate": "11.06.2004"
    },
    {
        "engDate": "1947-09-28",
        "nepDate": "12.06.2004"
    },
    {
        "engDate": "1947-09-29",
        "nepDate": "13.06.2004"
    },
    {
        "engDate": "1947-09-30",
        "nepDate": "14.06.2004"
    },
    {
        "engDate": "1947-10-01",
        "nepDate": "15.06.2004"
    },
    {
        "engDate": "1947-10-02",
        "nepDate": "16.06.2004"
    },
    {
        "engDate": "1947-10-03",
        "nepDate": "17.06.2004"
    },
    {
        "engDate": "1947-10-04",
        "nepDate": "18.06.2004"
    },
    {
        "engDate": "1947-10-05",
        "nepDate": "19.06.2004"
    },
    {
        "engDate": "1947-10-06",
        "nepDate": "20.06.2004"
    },
    {
        "engDate": "1947-10-07",
        "nepDate": "21.06.2004"
    },
    {
        "engDate": "1947-10-08",
        "nepDate": "22.06.2004"
    },
    {
        "engDate": "1947-10-09",
        "nepDate": "23.06.2004"
    },
    {
        "engDate": "1947-10-10",
        "nepDate": "24.06.2004"
    },
    {
        "engDate": "1947-10-11",
        "nepDate": "25.06.2004"
    },
    {
        "engDate": "1947-10-12",
        "nepDate": "26.06.2004"
    },
    {
        "engDate": "1947-10-13",
        "nepDate": "27.06.2004"
    },
    {
        "engDate": "1947-10-14",
        "nepDate": "28.06.2004"
    },
    {
        "engDate": "1947-10-15",
        "nepDate": "29.06.2004"
    },
    {
        "engDate": "1947-10-16",
        "nepDate": "30.06.2004"
    },
    {
        "engDate": "1947-10-17",
        "nepDate": "01.07.2004"
    },
    {
        "engDate": "1947-10-18",
        "nepDate": "02.07.2004"
    },
    {
        "engDate": "1947-10-19",
        "nepDate": "03.07.2004"
    },
    {
        "engDate": "1947-10-20",
        "nepDate": "04.07.2004"
    },
    {
        "engDate": "1947-10-21",
        "nepDate": "05.07.2004"
    },
    {
        "engDate": "1947-10-22",
        "nepDate": "06.07.2004"
    },
    {
        "engDate": "1947-10-23",
        "nepDate": "07.07.2004"
    },
    {
        "engDate": "1947-10-24",
        "nepDate": "08.07.2004"
    },
    {
        "engDate": "1947-10-25",
        "nepDate": "09.07.2004"
    },
    {
        "engDate": "1947-10-26",
        "nepDate": "10.07.2004"
    },
    {
        "engDate": "1947-10-27",
        "nepDate": "11.07.2004"
    },
    {
        "engDate": "1947-10-28",
        "nepDate": "12.07.2004"
    },
    {
        "engDate": "1947-10-29",
        "nepDate": "13.07.2004"
    },
    {
        "engDate": "1947-10-30",
        "nepDate": "14.07.2004"
    },
    {
        "engDate": "1947-10-31",
        "nepDate": "15.07.2004"
    },
    {
        "engDate": "1947-11-01",
        "nepDate": "16.07.2004"
    },
    {
        "engDate": "1947-11-02",
        "nepDate": "17.07.2004"
    },
    {
        "engDate": "1947-11-03",
        "nepDate": "18.07.2004"
    },
    {
        "engDate": "1947-11-04",
        "nepDate": "19.07.2004"
    },
    {
        "engDate": "1947-11-05",
        "nepDate": "20.07.2004"
    },
    {
        "engDate": "1947-11-06",
        "nepDate": "21.07.2004"
    },
    {
        "engDate": "1947-11-07",
        "nepDate": "22.07.2004"
    },
    {
        "engDate": "1947-11-08",
        "nepDate": "23.07.2004"
    },
    {
        "engDate": "1947-11-09",
        "nepDate": "24.07.2004"
    },
    {
        "engDate": "1947-11-10",
        "nepDate": "25.07.2004"
    },
    {
        "engDate": "1947-11-11",
        "nepDate": "26.07.2004"
    },
    {
        "engDate": "1947-11-12",
        "nepDate": "27.07.2004"
    },
    {
        "engDate": "1947-11-13",
        "nepDate": "28.07.2004"
    },
    {
        "engDate": "1947-11-14",
        "nepDate": "29.07.2004"
    },
    {
        "engDate": "1947-11-15",
        "nepDate": "30.07.2004"
    },
    {
        "engDate": "1947-11-16",
        "nepDate": "01.08.2004"
    },
    {
        "engDate": "1947-11-17",
        "nepDate": "02.08.2004"
    },
    {
        "engDate": "1947-11-18",
        "nepDate": "03.08.2004"
    },
    {
        "engDate": "1947-11-19",
        "nepDate": "04.08.2004"
    },
    {
        "engDate": "1947-11-20",
        "nepDate": "05.08.2004"
    },
    {
        "engDate": "1947-11-21",
        "nepDate": "06.08.2004"
    },
    {
        "engDate": "1947-11-22",
        "nepDate": "07.08.2004"
    },
    {
        "engDate": "1947-11-23",
        "nepDate": "08.08.2004"
    },
    {
        "engDate": "1947-11-24",
        "nepDate": "09.08.2004"
    },
    {
        "engDate": "1947-11-25",
        "nepDate": "10.08.2004"
    },
    {
        "engDate": "1947-11-26",
        "nepDate": "11.08.2004"
    },
    {
        "engDate": "1947-11-27",
        "nepDate": "12.08.2004"
    },
    {
        "engDate": "1947-11-28",
        "nepDate": "13.08.2004"
    },
    {
        "engDate": "1947-11-29",
        "nepDate": "14.08.2004"
    },
    {
        "engDate": "1947-11-30",
        "nepDate": "15.08.2004"
    },
    {
        "engDate": "1947-12-01",
        "nepDate": "16.08.2004"
    },
    {
        "engDate": "1947-12-02",
        "nepDate": "17.08.2004"
    },
    {
        "engDate": "1947-12-03",
        "nepDate": "18.08.2004"
    },
    {
        "engDate": "1947-12-04",
        "nepDate": "19.08.2004"
    },
    {
        "engDate": "1947-12-05",
        "nepDate": "20.08.2004"
    },
    {
        "engDate": "1947-12-06",
        "nepDate": "21.08.2004"
    },
    {
        "engDate": "1947-12-07",
        "nepDate": "22.08.2004"
    },
    {
        "engDate": "1947-12-08",
        "nepDate": "23.08.2004"
    },
    {
        "engDate": "1947-12-09",
        "nepDate": "24.08.2004"
    },
    {
        "engDate": "1947-12-10",
        "nepDate": "25.08.2004"
    },
    {
        "engDate": "1947-12-11",
        "nepDate": "26.08.2004"
    },
    {
        "engDate": "1947-12-12",
        "nepDate": "27.08.2004"
    },
    {
        "engDate": "1947-12-13",
        "nepDate": "28.08.2004"
    },
    {
        "engDate": "1947-12-14",
        "nepDate": "29.08.2004"
    },
    {
        "engDate": "1947-12-15",
        "nepDate": "30.08.2004"
    },
    {
        "engDate": "1947-12-16",
        "nepDate": "01.09.2004"
    },
    {
        "engDate": "1947-12-17",
        "nepDate": "02.09.2004"
    },
    {
        "engDate": "1947-12-18",
        "nepDate": "03.09.2004"
    },
    {
        "engDate": "1947-12-19",
        "nepDate": "04.09.2004"
    },
    {
        "engDate": "1947-12-20",
        "nepDate": "05.09.2004"
    },
    {
        "engDate": "1947-12-21",
        "nepDate": "06.09.2004"
    },
    {
        "engDate": "1947-12-22",
        "nepDate": "07.09.2004"
    },
    {
        "engDate": "1947-12-23",
        "nepDate": "08.09.2004"
    },
    {
        "engDate": "1947-12-24",
        "nepDate": "09.09.2004"
    },
    {
        "engDate": "1947-12-25",
        "nepDate": "10.09.2004"
    },
    {
        "engDate": "1947-12-26",
        "nepDate": "11.09.2004"
    },
    {
        "engDate": "1947-12-27",
        "nepDate": "12.09.2004"
    },
    {
        "engDate": "1947-12-28",
        "nepDate": "13.09.2004"
    },
    {
        "engDate": "1947-12-29",
        "nepDate": "14.09.2004"
    },
    {
        "engDate": "1947-12-30",
        "nepDate": "15.09.2004"
    },
    {
        "engDate": "1947-12-31",
        "nepDate": "16.09.2004"
    },
    {
        "engDate": "1948-01-01",
        "nepDate": "17.09.2004"
    },
    {
        "engDate": "1948-01-02",
        "nepDate": "18.09.2004"
    },
    {
        "engDate": "1948-01-03",
        "nepDate": "19.09.2004"
    },
    {
        "engDate": "1948-01-04",
        "nepDate": "20.09.2004"
    },
    {
        "engDate": "1948-01-05",
        "nepDate": "21.09.2004"
    },
    {
        "engDate": "1948-01-06",
        "nepDate": "22.09.2004"
    },
    {
        "engDate": "1948-01-07",
        "nepDate": "23.09.2004"
    },
    {
        "engDate": "1948-01-08",
        "nepDate": "24.09.2004"
    },
    {
        "engDate": "1948-01-09",
        "nepDate": "25.09.2004"
    },
    {
        "engDate": "1948-01-10",
        "nepDate": "26.09.2004"
    },
    {
        "engDate": "1948-01-11",
        "nepDate": "27.09.2004"
    },
    {
        "engDate": "1948-01-12",
        "nepDate": "28.09.2004"
    },
    {
        "engDate": "1948-01-13",
        "nepDate": "29.09.2004"
    },
    {
        "engDate": "1948-01-14",
        "nepDate": "01.10.2004"
    },
    {
        "engDate": "1948-01-15",
        "nepDate": "02.10.2004"
    },
    {
        "engDate": "1948-01-16",
        "nepDate": "03.10.2004"
    },
    {
        "engDate": "1948-01-17",
        "nepDate": "04.10.2004"
    },
    {
        "engDate": "1948-01-18",
        "nepDate": "05.10.2004"
    },
    {
        "engDate": "1948-01-19",
        "nepDate": "06.10.2004"
    },
    {
        "engDate": "1948-01-20",
        "nepDate": "07.10.2004"
    },
    {
        "engDate": "1948-01-21",
        "nepDate": "08.10.2004"
    },
    {
        "engDate": "1948-01-22",
        "nepDate": "09.10.2004"
    },
    {
        "engDate": "1948-01-23",
        "nepDate": "10.10.2004"
    },
    {
        "engDate": "1948-01-24",
        "nepDate": "11.10.2004"
    },
    {
        "engDate": "1948-01-25",
        "nepDate": "12.10.2004"
    },
    {
        "engDate": "1948-01-26",
        "nepDate": "13.10.2004"
    },
    {
        "engDate": "1948-01-27",
        "nepDate": "14.10.2004"
    },
    {
        "engDate": "1948-01-28",
        "nepDate": "15.10.2004"
    },
    {
        "engDate": "1948-01-29",
        "nepDate": "16.10.2004"
    },
    {
        "engDate": "1948-01-30",
        "nepDate": "17.10.2004"
    },
    {
        "engDate": "1948-01-31",
        "nepDate": "18.10.2004"
    },
    {
        "engDate": "1948-02-01",
        "nepDate": "19.10.2004"
    },
    {
        "engDate": "1948-02-02",
        "nepDate": "20.10.2004"
    },
    {
        "engDate": "1948-02-03",
        "nepDate": "21.10.2004"
    },
    {
        "engDate": "1948-02-04",
        "nepDate": "22.10.2004"
    },
    {
        "engDate": "1948-02-05",
        "nepDate": "23.10.2004"
    },
    {
        "engDate": "1948-02-06",
        "nepDate": "24.10.2004"
    },
    {
        "engDate": "1948-02-07",
        "nepDate": "25.10.2004"
    },
    {
        "engDate": "1948-02-08",
        "nepDate": "26.10.2004"
    },
    {
        "engDate": "1948-02-09",
        "nepDate": "27.10.2004"
    },
    {
        "engDate": "1948-02-10",
        "nepDate": "28.10.2004"
    },
    {
        "engDate": "1948-02-11",
        "nepDate": "29.10.2004"
    },
    {
        "engDate": "1948-02-12",
        "nepDate": "30.10.2004"
    },
    {
        "engDate": "1948-02-13",
        "nepDate": "01.11.2004"
    },
    {
        "engDate": "1948-02-14",
        "nepDate": "02.11.2004"
    },
    {
        "engDate": "1948-02-15",
        "nepDate": "03.11.2004"
    },
    {
        "engDate": "1948-02-16",
        "nepDate": "04.11.2004"
    },
    {
        "engDate": "1948-02-17",
        "nepDate": "05.11.2004"
    },
    {
        "engDate": "1948-02-18",
        "nepDate": "06.11.2004"
    },
    {
        "engDate": "1948-02-19",
        "nepDate": "07.11.2004"
    },
    {
        "engDate": "1948-02-20",
        "nepDate": "08.11.2004"
    },
    {
        "engDate": "1948-02-21",
        "nepDate": "09.11.2004"
    },
    {
        "engDate": "1948-02-22",
        "nepDate": "10.11.2004"
    },
    {
        "engDate": "1948-02-23",
        "nepDate": "11.11.2004"
    },
    {
        "engDate": "1948-02-24",
        "nepDate": "12.11.2004"
    },
    {
        "engDate": "1948-02-25",
        "nepDate": "13.11.2004"
    },
    {
        "engDate": "1948-02-26",
        "nepDate": "14.11.2004"
    },
    {
        "engDate": "1948-02-27",
        "nepDate": "15.11.2004"
    },
    {
        "engDate": "1948-02-28",
        "nepDate": "16.11.2004"
    },
    {
        "engDate": "1948-02-29",
        "nepDate": "17.11.2004"
    },
    {
        "engDate": "1948-03-01",
        "nepDate": "18.11.2004"
    },
    {
        "engDate": "1948-03-02",
        "nepDate": "19.11.2004"
    },
    {
        "engDate": "1948-03-03",
        "nepDate": "20.11.2004"
    },
    {
        "engDate": "1948-03-04",
        "nepDate": "21.11.2004"
    },
    {
        "engDate": "1948-03-05",
        "nepDate": "22.11.2004"
    },
    {
        "engDate": "1948-03-06",
        "nepDate": "23.11.2004"
    },
    {
        "engDate": "1948-03-07",
        "nepDate": "24.11.2004"
    },
    {
        "engDate": "1948-03-08",
        "nepDate": "25.11.2004"
    },
    {
        "engDate": "1948-03-09",
        "nepDate": "26.11.2004"
    },
    {
        "engDate": "1948-03-10",
        "nepDate": "27.11.2004"
    },
    {
        "engDate": "1948-03-11",
        "nepDate": "28.11.2004"
    },
    {
        "engDate": "1948-03-12",
        "nepDate": "29.11.2004"
    },
    {
        "engDate": "1948-03-13",
        "nepDate": "01.12.2004"
    },
    {
        "engDate": "1948-03-14",
        "nepDate": "02.12.2004"
    },
    {
        "engDate": "1948-03-15",
        "nepDate": "03.12.2004"
    },
    {
        "engDate": "1948-03-16",
        "nepDate": "04.12.2004"
    },
    {
        "engDate": "1948-03-17",
        "nepDate": "05.12.2004"
    },
    {
        "engDate": "1948-03-18",
        "nepDate": "06.12.2004"
    },
    {
        "engDate": "1948-03-19",
        "nepDate": "07.12.2004"
    },
    {
        "engDate": "1948-03-20",
        "nepDate": "08.12.2004"
    },
    {
        "engDate": "1948-03-21",
        "nepDate": "09.12.2004"
    },
    {
        "engDate": "1948-03-22",
        "nepDate": "10.12.2004"
    },
    {
        "engDate": "1948-03-23",
        "nepDate": "11.12.2004"
    },
    {
        "engDate": "1948-03-24",
        "nepDate": "12.12.2004"
    },
    {
        "engDate": "1948-03-25",
        "nepDate": "13.12.2004"
    },
    {
        "engDate": "1948-03-26",
        "nepDate": "14.12.2004"
    },
    {
        "engDate": "1948-03-27",
        "nepDate": "15.12.2004"
    },
    {
        "engDate": "1948-03-28",
        "nepDate": "16.12.2004"
    },
    {
        "engDate": "1948-03-29",
        "nepDate": "17.12.2004"
    },
    {
        "engDate": "1948-03-30",
        "nepDate": "18.12.2004"
    },
    {
        "engDate": "1948-03-31",
        "nepDate": "19.12.2004"
    },
    {
        "engDate": "1948-04-01",
        "nepDate": "20.12.2004"
    },
    {
        "engDate": "1948-04-02",
        "nepDate": "21.12.2004"
    },
    {
        "engDate": "1948-04-03",
        "nepDate": "22.12.2004"
    },
    {
        "engDate": "1948-04-04",
        "nepDate": "23.12.2004"
    },
    {
        "engDate": "1948-04-05",
        "nepDate": "24.12.2004"
    },
    {
        "engDate": "1948-04-06",
        "nepDate": "25.12.2004"
    },
    {
        "engDate": "1948-04-07",
        "nepDate": "26.12.2004"
    },
    {
        "engDate": "1948-04-08",
        "nepDate": "27.12.2004"
    },
    {
        "engDate": "1948-04-09",
        "nepDate": "28.12.2004"
    },
    {
        "engDate": "1948-04-10",
        "nepDate": "29.12.2004"
    },
    {
        "engDate": "1948-04-11",
        "nepDate": "30.12.2004"
    },
    {
        "engDate": "1948-04-12",
        "nepDate": "31.12.2004"
    },
    {
        "engDate": "1948-04-13",
        "nepDate": "01.01.2005"
    },
    {
        "engDate": "1948-04-14",
        "nepDate": "02.01.2005"
    },
    {
        "engDate": "1948-04-15",
        "nepDate": "03.01.2005"
    },
    {
        "engDate": "1948-04-16",
        "nepDate": "04.01.2005"
    },
    {
        "engDate": "1948-04-17",
        "nepDate": "05.01.2005"
    },
    {
        "engDate": "1948-04-18",
        "nepDate": "06.01.2005"
    },
    {
        "engDate": "1948-04-19",
        "nepDate": "07.01.2005"
    },
    {
        "engDate": "1948-04-20",
        "nepDate": "08.01.2005"
    },
    {
        "engDate": "1948-04-21",
        "nepDate": "09.01.2005"
    },
    {
        "engDate": "1948-04-22",
        "nepDate": "10.01.2005"
    },
    {
        "engDate": "1948-04-23",
        "nepDate": "11.01.2005"
    },
    {
        "engDate": "1948-04-24",
        "nepDate": "12.01.2005"
    },
    {
        "engDate": "1948-04-25",
        "nepDate": "13.01.2005"
    },
    {
        "engDate": "1948-04-26",
        "nepDate": "14.01.2005"
    },
    {
        "engDate": "1948-04-27",
        "nepDate": "15.01.2005"
    },
    {
        "engDate": "1948-04-28",
        "nepDate": "16.01.2005"
    },
    {
        "engDate": "1948-04-29",
        "nepDate": "17.01.2005"
    },
    {
        "engDate": "1948-04-30",
        "nepDate": "18.01.2005"
    },
    {
        "engDate": "1948-05-01",
        "nepDate": "19.01.2005"
    },
    {
        "engDate": "1948-05-02",
        "nepDate": "20.01.2005"
    },
    {
        "engDate": "1948-05-03",
        "nepDate": "21.01.2005"
    },
    {
        "engDate": "1948-05-04",
        "nepDate": "22.01.2005"
    },
    {
        "engDate": "1948-05-05",
        "nepDate": "23.01.2005"
    },
    {
        "engDate": "1948-05-06",
        "nepDate": "24.01.2005"
    },
    {
        "engDate": "1948-05-07",
        "nepDate": "25.01.2005"
    },
    {
        "engDate": "1948-05-08",
        "nepDate": "26.01.2005"
    },
    {
        "engDate": "1948-05-09",
        "nepDate": "27.01.2005"
    },
    {
        "engDate": "1948-05-10",
        "nepDate": "28.01.2005"
    },
    {
        "engDate": "1948-05-11",
        "nepDate": "29.01.2005"
    },
    {
        "engDate": "1948-05-12",
        "nepDate": "30.01.2005"
    },
    {
        "engDate": "1948-05-13",
        "nepDate": "31.01.2005"
    },
    {
        "engDate": "1948-05-14",
        "nepDate": "01.02.2005"
    },
    {
        "engDate": "1948-05-15",
        "nepDate": "02.02.2005"
    },
    {
        "engDate": "1948-05-16",
        "nepDate": "03.02.2005"
    },
    {
        "engDate": "1948-05-17",
        "nepDate": "04.02.2005"
    },
    {
        "engDate": "1948-05-18",
        "nepDate": "05.02.2005"
    },
    {
        "engDate": "1948-05-19",
        "nepDate": "06.02.2005"
    },
    {
        "engDate": "1948-05-20",
        "nepDate": "07.02.2005"
    },
    {
        "engDate": "1948-05-21",
        "nepDate": "08.02.2005"
    },
    {
        "engDate": "1948-05-22",
        "nepDate": "09.02.2005"
    },
    {
        "engDate": "1948-05-23",
        "nepDate": "10.02.2005"
    },
    {
        "engDate": "1948-05-24",
        "nepDate": "11.02.2005"
    },
    {
        "engDate": "1948-05-25",
        "nepDate": "12.02.2005"
    },
    {
        "engDate": "1948-05-26",
        "nepDate": "13.02.2005"
    },
    {
        "engDate": "1948-05-27",
        "nepDate": "14.02.2005"
    },
    {
        "engDate": "1948-05-28",
        "nepDate": "15.02.2005"
    },
    {
        "engDate": "1948-05-29",
        "nepDate": "16.02.2005"
    },
    {
        "engDate": "1948-05-30",
        "nepDate": "17.02.2005"
    },
    {
        "engDate": "1948-05-31",
        "nepDate": "18.02.2005"
    },
    {
        "engDate": "1948-06-01",
        "nepDate": "19.02.2005"
    },
    {
        "engDate": "1948-06-02",
        "nepDate": "20.02.2005"
    },
    {
        "engDate": "1948-06-03",
        "nepDate": "21.02.2005"
    },
    {
        "engDate": "1948-06-04",
        "nepDate": "22.02.2005"
    },
    {
        "engDate": "1948-06-05",
        "nepDate": "23.02.2005"
    },
    {
        "engDate": "1948-06-06",
        "nepDate": "24.02.2005"
    },
    {
        "engDate": "1948-06-07",
        "nepDate": "25.02.2005"
    },
    {
        "engDate": "1948-06-08",
        "nepDate": "26.02.2005"
    },
    {
        "engDate": "1948-06-09",
        "nepDate": "27.02.2005"
    },
    {
        "engDate": "1948-06-10",
        "nepDate": "28.02.2005"
    },
    {
        "engDate": "1948-06-11",
        "nepDate": "29.02.2005"
    },
    {
        "engDate": "1948-06-12",
        "nepDate": "30.02.2005"
    },
    {
        "engDate": "1948-06-13",
        "nepDate": "31.02.2005"
    },
    {
        "engDate": "1948-06-14",
        "nepDate": "01.03.2005"
    },
    {
        "engDate": "1948-06-15",
        "nepDate": "02.03.2005"
    },
    {
        "engDate": "1948-06-16",
        "nepDate": "03.03.2005"
    },
    {
        "engDate": "1948-06-17",
        "nepDate": "04.03.2005"
    },
    {
        "engDate": "1948-06-18",
        "nepDate": "05.03.2005"
    },
    {
        "engDate": "1948-06-19",
        "nepDate": "06.03.2005"
    },
    {
        "engDate": "1948-06-20",
        "nepDate": "07.03.2005"
    },
    {
        "engDate": "1948-06-21",
        "nepDate": "08.03.2005"
    },
    {
        "engDate": "1948-06-22",
        "nepDate": "09.03.2005"
    },
    {
        "engDate": "1948-06-23",
        "nepDate": "10.03.2005"
    },
    {
        "engDate": "1948-06-24",
        "nepDate": "11.03.2005"
    },
    {
        "engDate": "1948-06-25",
        "nepDate": "12.03.2005"
    },
    {
        "engDate": "1948-06-26",
        "nepDate": "13.03.2005"
    },
    {
        "engDate": "1948-06-27",
        "nepDate": "14.03.2005"
    },
    {
        "engDate": "1948-06-28",
        "nepDate": "15.03.2005"
    },
    {
        "engDate": "1948-06-29",
        "nepDate": "16.03.2005"
    },
    {
        "engDate": "1948-06-30",
        "nepDate": "17.03.2005"
    },
    {
        "engDate": "1948-07-01",
        "nepDate": "18.03.2005"
    },
    {
        "engDate": "1948-07-02",
        "nepDate": "19.03.2005"
    },
    {
        "engDate": "1948-07-03",
        "nepDate": "20.03.2005"
    },
    {
        "engDate": "1948-07-04",
        "nepDate": "21.03.2005"
    },
    {
        "engDate": "1948-07-05",
        "nepDate": "22.03.2005"
    },
    {
        "engDate": "1948-07-06",
        "nepDate": "23.03.2005"
    },
    {
        "engDate": "1948-07-07",
        "nepDate": "24.03.2005"
    },
    {
        "engDate": "1948-07-08",
        "nepDate": "25.03.2005"
    },
    {
        "engDate": "1948-07-09",
        "nepDate": "26.03.2005"
    },
    {
        "engDate": "1948-07-10",
        "nepDate": "27.03.2005"
    },
    {
        "engDate": "1948-07-11",
        "nepDate": "28.03.2005"
    },
    {
        "engDate": "1948-07-12",
        "nepDate": "29.03.2005"
    },
    {
        "engDate": "1948-07-13",
        "nepDate": "30.03.2005"
    },
    {
        "engDate": "1948-07-14",
        "nepDate": "31.03.2005"
    },
    {
        "engDate": "1948-07-15",
        "nepDate": "32.03.2005"
    },
    {
        "engDate": "1948-07-16",
        "nepDate": "01.04.2005"
    },
    {
        "engDate": "1948-07-17",
        "nepDate": "02.04.2005"
    },
    {
        "engDate": "1948-07-18",
        "nepDate": "03.04.2005"
    },
    {
        "engDate": "1948-07-19",
        "nepDate": "04.04.2005"
    },
    {
        "engDate": "1948-07-20",
        "nepDate": "05.04.2005"
    },
    {
        "engDate": "1948-07-21",
        "nepDate": "06.04.2005"
    },
    {
        "engDate": "1948-07-22",
        "nepDate": "07.04.2005"
    },
    {
        "engDate": "1948-07-23",
        "nepDate": "08.04.2005"
    },
    {
        "engDate": "1948-07-24",
        "nepDate": "09.04.2005"
    },
    {
        "engDate": "1948-07-25",
        "nepDate": "10.04.2005"
    },
    {
        "engDate": "1948-07-26",
        "nepDate": "11.04.2005"
    },
    {
        "engDate": "1948-07-27",
        "nepDate": "12.04.2005"
    },
    {
        "engDate": "1948-07-28",
        "nepDate": "13.04.2005"
    },
    {
        "engDate": "1948-07-29",
        "nepDate": "14.04.2005"
    },
    {
        "engDate": "1948-07-30",
        "nepDate": "15.04.2005"
    },
    {
        "engDate": "1948-07-31",
        "nepDate": "16.04.2005"
    },
    {
        "engDate": "1948-08-01",
        "nepDate": "17.04.2005"
    },
    {
        "engDate": "1948-08-02",
        "nepDate": "18.04.2005"
    },
    {
        "engDate": "1948-08-03",
        "nepDate": "19.04.2005"
    },
    {
        "engDate": "1948-08-04",
        "nepDate": "20.04.2005"
    },
    {
        "engDate": "1948-08-05",
        "nepDate": "21.04.2005"
    },
    {
        "engDate": "1948-08-06",
        "nepDate": "22.04.2005"
    },
    {
        "engDate": "1948-08-07",
        "nepDate": "23.04.2005"
    },
    {
        "engDate": "1948-08-08",
        "nepDate": "24.04.2005"
    },
    {
        "engDate": "1948-08-09",
        "nepDate": "25.04.2005"
    },
    {
        "engDate": "1948-08-10",
        "nepDate": "26.04.2005"
    },
    {
        "engDate": "1948-08-11",
        "nepDate": "27.04.2005"
    },
    {
        "engDate": "1948-08-12",
        "nepDate": "28.04.2005"
    },
    {
        "engDate": "1948-08-13",
        "nepDate": "29.04.2005"
    },
    {
        "engDate": "1948-08-14",
        "nepDate": "30.04.2005"
    },
    {
        "engDate": "1948-08-15",
        "nepDate": "31.04.2005"
    },
    {
        "engDate": "1948-08-16",
        "nepDate": "01.05.2005"
    },
    {
        "engDate": "1948-08-17",
        "nepDate": "02.05.2005"
    },
    {
        "engDate": "1948-08-18",
        "nepDate": "03.05.2005"
    },
    {
        "engDate": "1948-08-19",
        "nepDate": "04.05.2005"
    },
    {
        "engDate": "1948-08-20",
        "nepDate": "05.05.2005"
    },
    {
        "engDate": "1948-08-21",
        "nepDate": "06.05.2005"
    },
    {
        "engDate": "1948-08-22",
        "nepDate": "07.05.2005"
    },
    {
        "engDate": "1948-08-23",
        "nepDate": "08.05.2005"
    },
    {
        "engDate": "1948-08-24",
        "nepDate": "09.05.2005"
    },
    {
        "engDate": "1948-08-25",
        "nepDate": "10.05.2005"
    },
    {
        "engDate": "1948-08-26",
        "nepDate": "11.05.2005"
    },
    {
        "engDate": "1948-08-27",
        "nepDate": "12.05.2005"
    },
    {
        "engDate": "1948-08-28",
        "nepDate": "13.05.2005"
    },
    {
        "engDate": "1948-08-29",
        "nepDate": "14.05.2005"
    },
    {
        "engDate": "1948-08-30",
        "nepDate": "15.05.2005"
    },
    {
        "engDate": "1948-08-31",
        "nepDate": "16.05.2005"
    },
    {
        "engDate": "1948-09-01",
        "nepDate": "17.05.2005"
    },
    {
        "engDate": "1948-09-02",
        "nepDate": "18.05.2005"
    },
    {
        "engDate": "1948-09-03",
        "nepDate": "19.05.2005"
    },
    {
        "engDate": "1948-09-04",
        "nepDate": "20.05.2005"
    },
    {
        "engDate": "1948-09-05",
        "nepDate": "21.05.2005"
    },
    {
        "engDate": "1948-09-06",
        "nepDate": "22.05.2005"
    },
    {
        "engDate": "1948-09-07",
        "nepDate": "23.05.2005"
    },
    {
        "engDate": "1948-09-08",
        "nepDate": "24.05.2005"
    },
    {
        "engDate": "1948-09-09",
        "nepDate": "25.05.2005"
    },
    {
        "engDate": "1948-09-10",
        "nepDate": "26.05.2005"
    },
    {
        "engDate": "1948-09-11",
        "nepDate": "27.05.2005"
    },
    {
        "engDate": "1948-09-12",
        "nepDate": "28.05.2005"
    },
    {
        "engDate": "1948-09-13",
        "nepDate": "29.05.2005"
    },
    {
        "engDate": "1948-09-14",
        "nepDate": "30.05.2005"
    },
    {
        "engDate": "1948-09-15",
        "nepDate": "31.05.2005"
    },
    {
        "engDate": "1948-09-16",
        "nepDate": "01.06.2005"
    },
    {
        "engDate": "1948-09-17",
        "nepDate": "02.06.2005"
    },
    {
        "engDate": "1948-09-18",
        "nepDate": "03.06.2005"
    },
    {
        "engDate": "1948-09-19",
        "nepDate": "04.06.2005"
    },
    {
        "engDate": "1948-09-20",
        "nepDate": "05.06.2005"
    },
    {
        "engDate": "1948-09-21",
        "nepDate": "06.06.2005"
    },
    {
        "engDate": "1948-09-22",
        "nepDate": "07.06.2005"
    },
    {
        "engDate": "1948-09-23",
        "nepDate": "08.06.2005"
    },
    {
        "engDate": "1948-09-24",
        "nepDate": "09.06.2005"
    },
    {
        "engDate": "1948-09-25",
        "nepDate": "10.06.2005"
    },
    {
        "engDate": "1948-09-26",
        "nepDate": "11.06.2005"
    },
    {
        "engDate": "1948-09-27",
        "nepDate": "12.06.2005"
    },
    {
        "engDate": "1948-09-28",
        "nepDate": "13.06.2005"
    },
    {
        "engDate": "1948-09-29",
        "nepDate": "14.06.2005"
    },
    {
        "engDate": "1948-09-30",
        "nepDate": "15.06.2005"
    },
    {
        "engDate": "1948-10-01",
        "nepDate": "16.06.2005"
    },
    {
        "engDate": "1948-10-02",
        "nepDate": "17.06.2005"
    },
    {
        "engDate": "1948-10-03",
        "nepDate": "18.06.2005"
    },
    {
        "engDate": "1948-10-04",
        "nepDate": "19.06.2005"
    },
    {
        "engDate": "1948-10-05",
        "nepDate": "20.06.2005"
    },
    {
        "engDate": "1948-10-06",
        "nepDate": "21.06.2005"
    },
    {
        "engDate": "1948-10-07",
        "nepDate": "22.06.2005"
    },
    {
        "engDate": "1948-10-08",
        "nepDate": "23.06.2005"
    },
    {
        "engDate": "1948-10-09",
        "nepDate": "24.06.2005"
    },
    {
        "engDate": "1948-10-10",
        "nepDate": "25.06.2005"
    },
    {
        "engDate": "1948-10-11",
        "nepDate": "26.06.2005"
    },
    {
        "engDate": "1948-10-12",
        "nepDate": "27.06.2005"
    },
    {
        "engDate": "1948-10-13",
        "nepDate": "28.06.2005"
    },
    {
        "engDate": "1948-10-14",
        "nepDate": "29.06.2005"
    },
    {
        "engDate": "1948-10-15",
        "nepDate": "30.06.2005"
    },
    {
        "engDate": "1948-10-16",
        "nepDate": "31.06.2005"
    },
    {
        "engDate": "1948-10-17",
        "nepDate": "01.07.2005"
    },
    {
        "engDate": "1948-10-18",
        "nepDate": "02.07.2005"
    },
    {
        "engDate": "1948-10-19",
        "nepDate": "03.07.2005"
    },
    {
        "engDate": "1948-10-20",
        "nepDate": "04.07.2005"
    },
    {
        "engDate": "1948-10-21",
        "nepDate": "05.07.2005"
    },
    {
        "engDate": "1948-10-22",
        "nepDate": "06.07.2005"
    },
    {
        "engDate": "1948-10-23",
        "nepDate": "07.07.2005"
    },
    {
        "engDate": "1948-10-24",
        "nepDate": "08.07.2005"
    },
    {
        "engDate": "1948-10-25",
        "nepDate": "09.07.2005"
    },
    {
        "engDate": "1948-10-26",
        "nepDate": "10.07.2005"
    },
    {
        "engDate": "1948-10-27",
        "nepDate": "11.07.2005"
    },
    {
        "engDate": "1948-10-28",
        "nepDate": "12.07.2005"
    },
    {
        "engDate": "1948-10-29",
        "nepDate": "13.07.2005"
    },
    {
        "engDate": "1948-10-30",
        "nepDate": "14.07.2005"
    },
    {
        "engDate": "1948-10-31",
        "nepDate": "15.07.2005"
    },
    {
        "engDate": "1948-11-01",
        "nepDate": "16.07.2005"
    },
    {
        "engDate": "1948-11-02",
        "nepDate": "17.07.2005"
    },
    {
        "engDate": "1948-11-03",
        "nepDate": "18.07.2005"
    },
    {
        "engDate": "1948-11-04",
        "nepDate": "19.07.2005"
    },
    {
        "engDate": "1948-11-05",
        "nepDate": "20.07.2005"
    },
    {
        "engDate": "1948-11-06",
        "nepDate": "21.07.2005"
    },
    {
        "engDate": "1948-11-07",
        "nepDate": "22.07.2005"
    },
    {
        "engDate": "1948-11-08",
        "nepDate": "23.07.2005"
    },
    {
        "engDate": "1948-11-09",
        "nepDate": "24.07.2005"
    },
    {
        "engDate": "1948-11-10",
        "nepDate": "25.07.2005"
    },
    {
        "engDate": "1948-11-11",
        "nepDate": "26.07.2005"
    },
    {
        "engDate": "1948-11-12",
        "nepDate": "27.07.2005"
    },
    {
        "engDate": "1948-11-13",
        "nepDate": "28.07.2005"
    },
    {
        "engDate": "1948-11-14",
        "nepDate": "29.07.2005"
    },
    {
        "engDate": "1948-11-15",
        "nepDate": "30.07.2005"
    },
    {
        "engDate": "1948-11-16",
        "nepDate": "01.08.2005"
    },
    {
        "engDate": "1948-11-17",
        "nepDate": "02.08.2005"
    },
    {
        "engDate": "1948-11-18",
        "nepDate": "03.08.2005"
    },
    {
        "engDate": "1948-11-19",
        "nepDate": "04.08.2005"
    },
    {
        "engDate": "1948-11-20",
        "nepDate": "05.08.2005"
    },
    {
        "engDate": "1948-11-21",
        "nepDate": "06.08.2005"
    },
    {
        "engDate": "1948-11-22",
        "nepDate": "07.08.2005"
    },
    {
        "engDate": "1948-11-23",
        "nepDate": "08.08.2005"
    },
    {
        "engDate": "1948-11-24",
        "nepDate": "09.08.2005"
    },
    {
        "engDate": "1948-11-25",
        "nepDate": "10.08.2005"
    },
    {
        "engDate": "1948-11-26",
        "nepDate": "11.08.2005"
    },
    {
        "engDate": "1948-11-27",
        "nepDate": "12.08.2005"
    },
    {
        "engDate": "1948-11-28",
        "nepDate": "13.08.2005"
    },
    {
        "engDate": "1948-11-29",
        "nepDate": "14.08.2005"
    },
    {
        "engDate": "1948-11-30",
        "nepDate": "15.08.2005"
    },
    {
        "engDate": "1948-12-01",
        "nepDate": "16.08.2005"
    },
    {
        "engDate": "1948-12-02",
        "nepDate": "17.08.2005"
    },
    {
        "engDate": "1948-12-03",
        "nepDate": "18.08.2005"
    },
    {
        "engDate": "1948-12-04",
        "nepDate": "19.08.2005"
    },
    {
        "engDate": "1948-12-05",
        "nepDate": "20.08.2005"
    },
    {
        "engDate": "1948-12-06",
        "nepDate": "21.08.2005"
    },
    {
        "engDate": "1948-12-07",
        "nepDate": "22.08.2005"
    },
    {
        "engDate": "1948-12-08",
        "nepDate": "23.08.2005"
    },
    {
        "engDate": "1948-12-09",
        "nepDate": "24.08.2005"
    },
    {
        "engDate": "1948-12-10",
        "nepDate": "25.08.2005"
    },
    {
        "engDate": "1948-12-11",
        "nepDate": "26.08.2005"
    },
    {
        "engDate": "1948-12-12",
        "nepDate": "27.08.2005"
    },
    {
        "engDate": "1948-12-13",
        "nepDate": "28.08.2005"
    },
    {
        "engDate": "1948-12-14",
        "nepDate": "29.08.2005"
    },
    {
        "engDate": "1948-12-15",
        "nepDate": "01.09.2005"
    },
    {
        "engDate": "1948-12-16",
        "nepDate": "02.09.2005"
    },
    {
        "engDate": "1948-12-17",
        "nepDate": "03.09.2005"
    },
    {
        "engDate": "1948-12-18",
        "nepDate": "04.09.2005"
    },
    {
        "engDate": "1948-12-19",
        "nepDate": "05.09.2005"
    },
    {
        "engDate": "1948-12-20",
        "nepDate": "06.09.2005"
    },
    {
        "engDate": "1948-12-21",
        "nepDate": "07.09.2005"
    },
    {
        "engDate": "1948-12-22",
        "nepDate": "08.09.2005"
    },
    {
        "engDate": "1948-12-23",
        "nepDate": "09.09.2005"
    },
    {
        "engDate": "1948-12-24",
        "nepDate": "10.09.2005"
    },
    {
        "engDate": "1948-12-25",
        "nepDate": "11.09.2005"
    },
    {
        "engDate": "1948-12-26",
        "nepDate": "12.09.2005"
    },
    {
        "engDate": "1948-12-27",
        "nepDate": "13.09.2005"
    },
    {
        "engDate": "1948-12-28",
        "nepDate": "14.09.2005"
    },
    {
        "engDate": "1948-12-29",
        "nepDate": "15.09.2005"
    },
    {
        "engDate": "1948-12-30",
        "nepDate": "16.09.2005"
    },
    {
        "engDate": "1948-12-31",
        "nepDate": "17.09.2005"
    },
    {
        "engDate": "1949-01-01",
        "nepDate": "18.09.2005"
    },
    {
        "engDate": "1949-01-02",
        "nepDate": "19.09.2005"
    },
    {
        "engDate": "1949-01-03",
        "nepDate": "20.09.2005"
    },
    {
        "engDate": "1949-01-04",
        "nepDate": "21.09.2005"
    },
    {
        "engDate": "1949-01-05",
        "nepDate": "22.09.2005"
    },
    {
        "engDate": "1949-01-06",
        "nepDate": "23.09.2005"
    },
    {
        "engDate": "1949-01-07",
        "nepDate": "24.09.2005"
    },
    {
        "engDate": "1949-01-08",
        "nepDate": "25.09.2005"
    },
    {
        "engDate": "1949-01-09",
        "nepDate": "26.09.2005"
    },
    {
        "engDate": "1949-01-10",
        "nepDate": "27.09.2005"
    },
    {
        "engDate": "1949-01-11",
        "nepDate": "28.09.2005"
    },
    {
        "engDate": "1949-01-12",
        "nepDate": "29.09.2005"
    },
    {
        "engDate": "1949-01-13",
        "nepDate": "30.09.2005"
    },
    {
        "engDate": "1949-01-14",
        "nepDate": "01.10.2005"
    },
    {
        "engDate": "1949-01-15",
        "nepDate": "02.10.2005"
    },
    {
        "engDate": "1949-01-16",
        "nepDate": "03.10.2005"
    },
    {
        "engDate": "1949-01-17",
        "nepDate": "04.10.2005"
    },
    {
        "engDate": "1949-01-18",
        "nepDate": "05.10.2005"
    },
    {
        "engDate": "1949-01-19",
        "nepDate": "06.10.2005"
    },
    {
        "engDate": "1949-01-20",
        "nepDate": "07.10.2005"
    },
    {
        "engDate": "1949-01-21",
        "nepDate": "08.10.2005"
    },
    {
        "engDate": "1949-01-22",
        "nepDate": "09.10.2005"
    },
    {
        "engDate": "1949-01-23",
        "nepDate": "10.10.2005"
    },
    {
        "engDate": "1949-01-24",
        "nepDate": "11.10.2005"
    },
    {
        "engDate": "1949-01-25",
        "nepDate": "12.10.2005"
    },
    {
        "engDate": "1949-01-26",
        "nepDate": "13.10.2005"
    },
    {
        "engDate": "1949-01-27",
        "nepDate": "14.10.2005"
    },
    {
        "engDate": "1949-01-28",
        "nepDate": "15.10.2005"
    },
    {
        "engDate": "1949-01-29",
        "nepDate": "16.10.2005"
    },
    {
        "engDate": "1949-01-30",
        "nepDate": "17.10.2005"
    },
    {
        "engDate": "1949-01-31",
        "nepDate": "18.10.2005"
    },
    {
        "engDate": "1949-02-01",
        "nepDate": "19.10.2005"
    },
    {
        "engDate": "1949-02-02",
        "nepDate": "20.10.2005"
    },
    {
        "engDate": "1949-02-03",
        "nepDate": "21.10.2005"
    },
    {
        "engDate": "1949-02-04",
        "nepDate": "22.10.2005"
    },
    {
        "engDate": "1949-02-05",
        "nepDate": "23.10.2005"
    },
    {
        "engDate": "1949-02-06",
        "nepDate": "24.10.2005"
    },
    {
        "engDate": "1949-02-07",
        "nepDate": "25.10.2005"
    },
    {
        "engDate": "1949-02-08",
        "nepDate": "26.10.2005"
    },
    {
        "engDate": "1949-02-09",
        "nepDate": "27.10.2005"
    },
    {
        "engDate": "1949-02-10",
        "nepDate": "28.10.2005"
    },
    {
        "engDate": "1949-02-11",
        "nepDate": "29.10.2005"
    },
    {
        "engDate": "1949-02-12",
        "nepDate": "01.11.2005"
    },
    {
        "engDate": "1949-02-13",
        "nepDate": "02.11.2005"
    },
    {
        "engDate": "1949-02-14",
        "nepDate": "03.11.2005"
    },
    {
        "engDate": "1949-02-15",
        "nepDate": "04.11.2005"
    },
    {
        "engDate": "1949-02-16",
        "nepDate": "05.11.2005"
    },
    {
        "engDate": "1949-02-17",
        "nepDate": "06.11.2005"
    },
    {
        "engDate": "1949-02-18",
        "nepDate": "07.11.2005"
    },
    {
        "engDate": "1949-02-19",
        "nepDate": "08.11.2005"
    },
    {
        "engDate": "1949-02-20",
        "nepDate": "09.11.2005"
    },
    {
        "engDate": "1949-02-21",
        "nepDate": "10.11.2005"
    },
    {
        "engDate": "1949-02-22",
        "nepDate": "11.11.2005"
    },
    {
        "engDate": "1949-02-23",
        "nepDate": "12.11.2005"
    },
    {
        "engDate": "1949-02-24",
        "nepDate": "13.11.2005"
    },
    {
        "engDate": "1949-02-25",
        "nepDate": "14.11.2005"
    },
    {
        "engDate": "1949-02-26",
        "nepDate": "15.11.2005"
    },
    {
        "engDate": "1949-02-27",
        "nepDate": "16.11.2005"
    },
    {
        "engDate": "1949-02-28",
        "nepDate": "17.11.2005"
    },
    {
        "engDate": "1949-03-01",
        "nepDate": "18.11.2005"
    },
    {
        "engDate": "1949-03-02",
        "nepDate": "19.11.2005"
    },
    {
        "engDate": "1949-03-03",
        "nepDate": "20.11.2005"
    },
    {
        "engDate": "1949-03-04",
        "nepDate": "21.11.2005"
    },
    {
        "engDate": "1949-03-05",
        "nepDate": "22.11.2005"
    },
    {
        "engDate": "1949-03-06",
        "nepDate": "23.11.2005"
    },
    {
        "engDate": "1949-03-07",
        "nepDate": "24.11.2005"
    },
    {
        "engDate": "1949-03-08",
        "nepDate": "25.11.2005"
    },
    {
        "engDate": "1949-03-09",
        "nepDate": "26.11.2005"
    },
    {
        "engDate": "1949-03-10",
        "nepDate": "27.11.2005"
    },
    {
        "engDate": "1949-03-11",
        "nepDate": "28.11.2005"
    },
    {
        "engDate": "1949-03-12",
        "nepDate": "29.11.2005"
    },
    {
        "engDate": "1949-03-13",
        "nepDate": "30.11.2005"
    },
    {
        "engDate": "1949-03-14",
        "nepDate": "01.12.2005"
    },
    {
        "engDate": "1949-03-15",
        "nepDate": "02.12.2005"
    },
    {
        "engDate": "1949-03-16",
        "nepDate": "03.12.2005"
    },
    {
        "engDate": "1949-03-17",
        "nepDate": "04.12.2005"
    },
    {
        "engDate": "1949-03-18",
        "nepDate": "05.12.2005"
    },
    {
        "engDate": "1949-03-19",
        "nepDate": "06.12.2005"
    },
    {
        "engDate": "1949-03-20",
        "nepDate": "07.12.2005"
    },
    {
        "engDate": "1949-03-21",
        "nepDate": "08.12.2005"
    },
    {
        "engDate": "1949-03-22",
        "nepDate": "09.12.2005"
    },
    {
        "engDate": "1949-03-23",
        "nepDate": "10.12.2005"
    },
    {
        "engDate": "1949-03-24",
        "nepDate": "11.12.2005"
    },
    {
        "engDate": "1949-03-25",
        "nepDate": "12.12.2005"
    },
    {
        "engDate": "1949-03-26",
        "nepDate": "13.12.2005"
    },
    {
        "engDate": "1949-03-27",
        "nepDate": "14.12.2005"
    },
    {
        "engDate": "1949-03-28",
        "nepDate": "15.12.2005"
    },
    {
        "engDate": "1949-03-29",
        "nepDate": "16.12.2005"
    },
    {
        "engDate": "1949-03-30",
        "nepDate": "17.12.2005"
    },
    {
        "engDate": "1949-03-31",
        "nepDate": "18.12.2005"
    },
    {
        "engDate": "1949-04-01",
        "nepDate": "19.12.2005"
    },
    {
        "engDate": "1949-04-02",
        "nepDate": "20.12.2005"
    },
    {
        "engDate": "1949-04-03",
        "nepDate": "21.12.2005"
    },
    {
        "engDate": "1949-04-04",
        "nepDate": "22.12.2005"
    },
    {
        "engDate": "1949-04-05",
        "nepDate": "23.12.2005"
    },
    {
        "engDate": "1949-04-06",
        "nepDate": "24.12.2005"
    },
    {
        "engDate": "1949-04-07",
        "nepDate": "25.12.2005"
    },
    {
        "engDate": "1949-04-08",
        "nepDate": "26.12.2005"
    },
    {
        "engDate": "1949-04-09",
        "nepDate": "27.12.2005"
    },
    {
        "engDate": "1949-04-10",
        "nepDate": "28.12.2005"
    },
    {
        "engDate": "1949-04-11",
        "nepDate": "29.12.2005"
    },
    {
        "engDate": "1949-04-12",
        "nepDate": "30.12.2005"
    },
    {
        "engDate": "1949-04-13",
        "nepDate": "01.01.2006"
    },
    {
        "engDate": "1949-04-14",
        "nepDate": "02.01.2006"
    },
    {
        "engDate": "1949-04-15",
        "nepDate": "03.01.2006"
    },
    {
        "engDate": "1949-04-16",
        "nepDate": "04.01.2006"
    },
    {
        "engDate": "1949-04-17",
        "nepDate": "05.01.2006"
    },
    {
        "engDate": "1949-04-18",
        "nepDate": "06.01.2006"
    },
    {
        "engDate": "1949-04-19",
        "nepDate": "07.01.2006"
    },
    {
        "engDate": "1949-04-20",
        "nepDate": "08.01.2006"
    },
    {
        "engDate": "1949-04-21",
        "nepDate": "09.01.2006"
    },
    {
        "engDate": "1949-04-22",
        "nepDate": "10.01.2006"
    },
    {
        "engDate": "1949-04-23",
        "nepDate": "11.01.2006"
    },
    {
        "engDate": "1949-04-24",
        "nepDate": "12.01.2006"
    },
    {
        "engDate": "1949-04-25",
        "nepDate": "13.01.2006"
    },
    {
        "engDate": "1949-04-26",
        "nepDate": "14.01.2006"
    },
    {
        "engDate": "1949-04-27",
        "nepDate": "15.01.2006"
    },
    {
        "engDate": "1949-04-28",
        "nepDate": "16.01.2006"
    },
    {
        "engDate": "1949-04-29",
        "nepDate": "17.01.2006"
    },
    {
        "engDate": "1949-04-30",
        "nepDate": "18.01.2006"
    },
    {
        "engDate": "1949-05-01",
        "nepDate": "19.01.2006"
    },
    {
        "engDate": "1949-05-02",
        "nepDate": "20.01.2006"
    },
    {
        "engDate": "1949-05-03",
        "nepDate": "21.01.2006"
    },
    {
        "engDate": "1949-05-04",
        "nepDate": "22.01.2006"
    },
    {
        "engDate": "1949-05-05",
        "nepDate": "23.01.2006"
    },
    {
        "engDate": "1949-05-06",
        "nepDate": "24.01.2006"
    },
    {
        "engDate": "1949-05-07",
        "nepDate": "25.01.2006"
    },
    {
        "engDate": "1949-05-08",
        "nepDate": "26.01.2006"
    },
    {
        "engDate": "1949-05-09",
        "nepDate": "27.01.2006"
    },
    {
        "engDate": "1949-05-10",
        "nepDate": "28.01.2006"
    },
    {
        "engDate": "1949-05-11",
        "nepDate": "29.01.2006"
    },
    {
        "engDate": "1949-05-12",
        "nepDate": "30.01.2006"
    },
    {
        "engDate": "1949-05-13",
        "nepDate": "31.01.2006"
    },
    {
        "engDate": "1949-05-14",
        "nepDate": "01.02.2006"
    },
    {
        "engDate": "1949-05-15",
        "nepDate": "02.02.2006"
    },
    {
        "engDate": "1949-05-16",
        "nepDate": "03.02.2006"
    },
    {
        "engDate": "1949-05-17",
        "nepDate": "04.02.2006"
    },
    {
        "engDate": "1949-05-18",
        "nepDate": "05.02.2006"
    },
    {
        "engDate": "1949-05-19",
        "nepDate": "06.02.2006"
    },
    {
        "engDate": "1949-05-20",
        "nepDate": "07.02.2006"
    },
    {
        "engDate": "1949-05-21",
        "nepDate": "08.02.2006"
    },
    {
        "engDate": "1949-05-22",
        "nepDate": "09.02.2006"
    },
    {
        "engDate": "1949-05-23",
        "nepDate": "10.02.2006"
    },
    {
        "engDate": "1949-05-24",
        "nepDate": "11.02.2006"
    },
    {
        "engDate": "1949-05-25",
        "nepDate": "12.02.2006"
    },
    {
        "engDate": "1949-05-26",
        "nepDate": "13.02.2006"
    },
    {
        "engDate": "1949-05-27",
        "nepDate": "14.02.2006"
    },
    {
        "engDate": "1949-05-28",
        "nepDate": "15.02.2006"
    },
    {
        "engDate": "1949-05-29",
        "nepDate": "16.02.2006"
    },
    {
        "engDate": "1949-05-30",
        "nepDate": "17.02.2006"
    },
    {
        "engDate": "1949-05-31",
        "nepDate": "18.02.2006"
    },
    {
        "engDate": "1949-06-01",
        "nepDate": "19.02.2006"
    },
    {
        "engDate": "1949-06-02",
        "nepDate": "20.02.2006"
    },
    {
        "engDate": "1949-06-03",
        "nepDate": "21.02.2006"
    },
    {
        "engDate": "1949-06-04",
        "nepDate": "22.02.2006"
    },
    {
        "engDate": "1949-06-05",
        "nepDate": "23.02.2006"
    },
    {
        "engDate": "1949-06-06",
        "nepDate": "24.02.2006"
    },
    {
        "engDate": "1949-06-07",
        "nepDate": "25.02.2006"
    },
    {
        "engDate": "1949-06-08",
        "nepDate": "26.02.2006"
    },
    {
        "engDate": "1949-06-09",
        "nepDate": "27.02.2006"
    },
    {
        "engDate": "1949-06-10",
        "nepDate": "28.02.2006"
    },
    {
        "engDate": "1949-06-11",
        "nepDate": "29.02.2006"
    },
    {
        "engDate": "1949-06-12",
        "nepDate": "30.02.2006"
    },
    {
        "engDate": "1949-06-13",
        "nepDate": "31.02.2006"
    },
    {
        "engDate": "1949-06-14",
        "nepDate": "01.03.2006"
    },
    {
        "engDate": "1949-06-15",
        "nepDate": "02.03.2006"
    },
    {
        "engDate": "1949-06-16",
        "nepDate": "03.03.2006"
    },
    {
        "engDate": "1949-06-17",
        "nepDate": "04.03.2006"
    },
    {
        "engDate": "1949-06-18",
        "nepDate": "05.03.2006"
    },
    {
        "engDate": "1949-06-19",
        "nepDate": "06.03.2006"
    },
    {
        "engDate": "1949-06-20",
        "nepDate": "07.03.2006"
    },
    {
        "engDate": "1949-06-21",
        "nepDate": "08.03.2006"
    },
    {
        "engDate": "1949-06-22",
        "nepDate": "09.03.2006"
    },
    {
        "engDate": "1949-06-23",
        "nepDate": "10.03.2006"
    },
    {
        "engDate": "1949-06-24",
        "nepDate": "11.03.2006"
    },
    {
        "engDate": "1949-06-25",
        "nepDate": "12.03.2006"
    },
    {
        "engDate": "1949-06-26",
        "nepDate": "13.03.2006"
    },
    {
        "engDate": "1949-06-27",
        "nepDate": "14.03.2006"
    },
    {
        "engDate": "1949-06-28",
        "nepDate": "15.03.2006"
    },
    {
        "engDate": "1949-06-29",
        "nepDate": "16.03.2006"
    },
    {
        "engDate": "1949-06-30",
        "nepDate": "17.03.2006"
    },
    {
        "engDate": "1949-07-01",
        "nepDate": "18.03.2006"
    },
    {
        "engDate": "1949-07-02",
        "nepDate": "19.03.2006"
    },
    {
        "engDate": "1949-07-03",
        "nepDate": "20.03.2006"
    },
    {
        "engDate": "1949-07-04",
        "nepDate": "21.03.2006"
    },
    {
        "engDate": "1949-07-05",
        "nepDate": "22.03.2006"
    },
    {
        "engDate": "1949-07-06",
        "nepDate": "23.03.2006"
    },
    {
        "engDate": "1949-07-07",
        "nepDate": "24.03.2006"
    },
    {
        "engDate": "1949-07-08",
        "nepDate": "25.03.2006"
    },
    {
        "engDate": "1949-07-09",
        "nepDate": "26.03.2006"
    },
    {
        "engDate": "1949-07-10",
        "nepDate": "27.03.2006"
    },
    {
        "engDate": "1949-07-11",
        "nepDate": "28.03.2006"
    },
    {
        "engDate": "1949-07-12",
        "nepDate": "29.03.2006"
    },
    {
        "engDate": "1949-07-13",
        "nepDate": "30.03.2006"
    },
    {
        "engDate": "1949-07-14",
        "nepDate": "31.03.2006"
    },
    {
        "engDate": "1949-07-15",
        "nepDate": "32.03.2006"
    },
    {
        "engDate": "1949-07-16",
        "nepDate": "01.04.2006"
    },
    {
        "engDate": "1949-07-17",
        "nepDate": "02.04.2006"
    },
    {
        "engDate": "1949-07-18",
        "nepDate": "03.04.2006"
    },
    {
        "engDate": "1949-07-19",
        "nepDate": "04.04.2006"
    },
    {
        "engDate": "1949-07-20",
        "nepDate": "05.04.2006"
    },
    {
        "engDate": "1949-07-21",
        "nepDate": "06.04.2006"
    },
    {
        "engDate": "1949-07-22",
        "nepDate": "07.04.2006"
    },
    {
        "engDate": "1949-07-23",
        "nepDate": "08.04.2006"
    },
    {
        "engDate": "1949-07-24",
        "nepDate": "09.04.2006"
    },
    {
        "engDate": "1949-07-25",
        "nepDate": "10.04.2006"
    },
    {
        "engDate": "1949-07-26",
        "nepDate": "11.04.2006"
    },
    {
        "engDate": "1949-07-27",
        "nepDate": "12.04.2006"
    },
    {
        "engDate": "1949-07-28",
        "nepDate": "13.04.2006"
    },
    {
        "engDate": "1949-07-29",
        "nepDate": "14.04.2006"
    },
    {
        "engDate": "1949-07-30",
        "nepDate": "15.04.2006"
    },
    {
        "engDate": "1949-07-31",
        "nepDate": "16.04.2006"
    },
    {
        "engDate": "1949-08-01",
        "nepDate": "17.04.2006"
    },
    {
        "engDate": "1949-08-02",
        "nepDate": "18.04.2006"
    },
    {
        "engDate": "1949-08-03",
        "nepDate": "19.04.2006"
    },
    {
        "engDate": "1949-08-04",
        "nepDate": "20.04.2006"
    },
    {
        "engDate": "1949-08-05",
        "nepDate": "21.04.2006"
    },
    {
        "engDate": "1949-08-06",
        "nepDate": "22.04.2006"
    },
    {
        "engDate": "1949-08-07",
        "nepDate": "23.04.2006"
    },
    {
        "engDate": "1949-08-08",
        "nepDate": "24.04.2006"
    },
    {
        "engDate": "1949-08-09",
        "nepDate": "25.04.2006"
    },
    {
        "engDate": "1949-08-10",
        "nepDate": "26.04.2006"
    },
    {
        "engDate": "1949-08-11",
        "nepDate": "27.04.2006"
    },
    {
        "engDate": "1949-08-12",
        "nepDate": "28.04.2006"
    },
    {
        "engDate": "1949-08-13",
        "nepDate": "29.04.2006"
    },
    {
        "engDate": "1949-08-14",
        "nepDate": "30.04.2006"
    },
    {
        "engDate": "1949-08-15",
        "nepDate": "31.04.2006"
    },
    {
        "engDate": "1949-08-16",
        "nepDate": "32.04.2006"
    },
    {
        "engDate": "1949-08-17",
        "nepDate": "01.05.2006"
    },
    {
        "engDate": "1949-08-18",
        "nepDate": "02.05.2006"
    },
    {
        "engDate": "1949-08-19",
        "nepDate": "03.05.2006"
    },
    {
        "engDate": "1949-08-20",
        "nepDate": "04.05.2006"
    },
    {
        "engDate": "1949-08-21",
        "nepDate": "05.05.2006"
    },
    {
        "engDate": "1949-08-22",
        "nepDate": "06.05.2006"
    },
    {
        "engDate": "1949-08-23",
        "nepDate": "07.05.2006"
    },
    {
        "engDate": "1949-08-24",
        "nepDate": "08.05.2006"
    },
    {
        "engDate": "1949-08-25",
        "nepDate": "09.05.2006"
    },
    {
        "engDate": "1949-08-26",
        "nepDate": "10.05.2006"
    },
    {
        "engDate": "1949-08-27",
        "nepDate": "11.05.2006"
    },
    {
        "engDate": "1949-08-28",
        "nepDate": "12.05.2006"
    },
    {
        "engDate": "1949-08-29",
        "nepDate": "13.05.2006"
    },
    {
        "engDate": "1949-08-30",
        "nepDate": "14.05.2006"
    },
    {
        "engDate": "1949-08-31",
        "nepDate": "15.05.2006"
    },
    {
        "engDate": "1949-09-01",
        "nepDate": "16.05.2006"
    },
    {
        "engDate": "1949-09-02",
        "nepDate": "17.05.2006"
    },
    {
        "engDate": "1949-09-03",
        "nepDate": "18.05.2006"
    },
    {
        "engDate": "1949-09-04",
        "nepDate": "19.05.2006"
    },
    {
        "engDate": "1949-09-05",
        "nepDate": "20.05.2006"
    },
    {
        "engDate": "1949-09-06",
        "nepDate": "21.05.2006"
    },
    {
        "engDate": "1949-09-07",
        "nepDate": "22.05.2006"
    },
    {
        "engDate": "1949-09-08",
        "nepDate": "23.05.2006"
    },
    {
        "engDate": "1949-09-09",
        "nepDate": "24.05.2006"
    },
    {
        "engDate": "1949-09-10",
        "nepDate": "25.05.2006"
    },
    {
        "engDate": "1949-09-11",
        "nepDate": "26.05.2006"
    },
    {
        "engDate": "1949-09-12",
        "nepDate": "27.05.2006"
    },
    {
        "engDate": "1949-09-13",
        "nepDate": "28.05.2006"
    },
    {
        "engDate": "1949-09-14",
        "nepDate": "29.05.2006"
    },
    {
        "engDate": "1949-09-15",
        "nepDate": "30.05.2006"
    },
    {
        "engDate": "1949-09-16",
        "nepDate": "31.05.2006"
    },
    {
        "engDate": "1949-09-17",
        "nepDate": "01.06.2006"
    },
    {
        "engDate": "1949-09-18",
        "nepDate": "02.06.2006"
    },
    {
        "engDate": "1949-09-19",
        "nepDate": "03.06.2006"
    },
    {
        "engDate": "1949-09-20",
        "nepDate": "04.06.2006"
    },
    {
        "engDate": "1949-09-21",
        "nepDate": "05.06.2006"
    },
    {
        "engDate": "1949-09-22",
        "nepDate": "06.06.2006"
    },
    {
        "engDate": "1949-09-23",
        "nepDate": "07.06.2006"
    },
    {
        "engDate": "1949-09-24",
        "nepDate": "08.06.2006"
    },
    {
        "engDate": "1949-09-25",
        "nepDate": "09.06.2006"
    },
    {
        "engDate": "1949-09-26",
        "nepDate": "10.06.2006"
    },
    {
        "engDate": "1949-09-27",
        "nepDate": "11.06.2006"
    },
    {
        "engDate": "1949-09-28",
        "nepDate": "12.06.2006"
    },
    {
        "engDate": "1949-09-29",
        "nepDate": "13.06.2006"
    },
    {
        "engDate": "1949-09-30",
        "nepDate": "14.06.2006"
    },
    {
        "engDate": "1949-10-01",
        "nepDate": "15.06.2006"
    },
    {
        "engDate": "1949-10-02",
        "nepDate": "16.06.2006"
    },
    {
        "engDate": "1949-10-03",
        "nepDate": "17.06.2006"
    },
    {
        "engDate": "1949-10-04",
        "nepDate": "18.06.2006"
    },
    {
        "engDate": "1949-10-05",
        "nepDate": "19.06.2006"
    },
    {
        "engDate": "1949-10-06",
        "nepDate": "20.06.2006"
    },
    {
        "engDate": "1949-10-07",
        "nepDate": "21.06.2006"
    },
    {
        "engDate": "1949-10-08",
        "nepDate": "22.06.2006"
    },
    {
        "engDate": "1949-10-09",
        "nepDate": "23.06.2006"
    },
    {
        "engDate": "1949-10-10",
        "nepDate": "24.06.2006"
    },
    {
        "engDate": "1949-10-11",
        "nepDate": "25.06.2006"
    },
    {
        "engDate": "1949-10-12",
        "nepDate": "26.06.2006"
    },
    {
        "engDate": "1949-10-13",
        "nepDate": "27.06.2006"
    },
    {
        "engDate": "1949-10-14",
        "nepDate": "28.06.2006"
    },
    {
        "engDate": "1949-10-15",
        "nepDate": "29.06.2006"
    },
    {
        "engDate": "1949-10-16",
        "nepDate": "30.06.2006"
    },
    {
        "engDate": "1949-10-17",
        "nepDate": "01.07.2006"
    },
    {
        "engDate": "1949-10-18",
        "nepDate": "02.07.2006"
    },
    {
        "engDate": "1949-10-19",
        "nepDate": "03.07.2006"
    },
    {
        "engDate": "1949-10-20",
        "nepDate": "04.07.2006"
    },
    {
        "engDate": "1949-10-21",
        "nepDate": "05.07.2006"
    },
    {
        "engDate": "1949-10-22",
        "nepDate": "06.07.2006"
    },
    {
        "engDate": "1949-10-23",
        "nepDate": "07.07.2006"
    },
    {
        "engDate": "1949-10-24",
        "nepDate": "08.07.2006"
    },
    {
        "engDate": "1949-10-25",
        "nepDate": "09.07.2006"
    },
    {
        "engDate": "1949-10-26",
        "nepDate": "10.07.2006"
    },
    {
        "engDate": "1949-10-27",
        "nepDate": "11.07.2006"
    },
    {
        "engDate": "1949-10-28",
        "nepDate": "12.07.2006"
    },
    {
        "engDate": "1949-10-29",
        "nepDate": "13.07.2006"
    },
    {
        "engDate": "1949-10-30",
        "nepDate": "14.07.2006"
    },
    {
        "engDate": "1949-10-31",
        "nepDate": "15.07.2006"
    },
    {
        "engDate": "1949-11-01",
        "nepDate": "16.07.2006"
    },
    {
        "engDate": "1949-11-02",
        "nepDate": "17.07.2006"
    },
    {
        "engDate": "1949-11-03",
        "nepDate": "18.07.2006"
    },
    {
        "engDate": "1949-11-04",
        "nepDate": "19.07.2006"
    },
    {
        "engDate": "1949-11-05",
        "nepDate": "20.07.2006"
    },
    {
        "engDate": "1949-11-06",
        "nepDate": "21.07.2006"
    },
    {
        "engDate": "1949-11-07",
        "nepDate": "22.07.2006"
    },
    {
        "engDate": "1949-11-08",
        "nepDate": "23.07.2006"
    },
    {
        "engDate": "1949-11-09",
        "nepDate": "24.07.2006"
    },
    {
        "engDate": "1949-11-10",
        "nepDate": "25.07.2006"
    },
    {
        "engDate": "1949-11-11",
        "nepDate": "26.07.2006"
    },
    {
        "engDate": "1949-11-12",
        "nepDate": "27.07.2006"
    },
    {
        "engDate": "1949-11-13",
        "nepDate": "28.07.2006"
    },
    {
        "engDate": "1949-11-14",
        "nepDate": "29.07.2006"
    },
    {
        "engDate": "1949-11-15",
        "nepDate": "30.07.2006"
    },
    {
        "engDate": "1949-11-16",
        "nepDate": "01.08.2006"
    },
    {
        "engDate": "1949-11-17",
        "nepDate": "02.08.2006"
    },
    {
        "engDate": "1949-11-18",
        "nepDate": "03.08.2006"
    },
    {
        "engDate": "1949-11-19",
        "nepDate": "04.08.2006"
    },
    {
        "engDate": "1949-11-20",
        "nepDate": "05.08.2006"
    },
    {
        "engDate": "1949-11-21",
        "nepDate": "06.08.2006"
    },
    {
        "engDate": "1949-11-22",
        "nepDate": "07.08.2006"
    },
    {
        "engDate": "1949-11-23",
        "nepDate": "08.08.2006"
    },
    {
        "engDate": "1949-11-24",
        "nepDate": "09.08.2006"
    },
    {
        "engDate": "1949-11-25",
        "nepDate": "10.08.2006"
    },
    {
        "engDate": "1949-11-26",
        "nepDate": "11.08.2006"
    },
    {
        "engDate": "1949-11-27",
        "nepDate": "12.08.2006"
    },
    {
        "engDate": "1949-11-28",
        "nepDate": "13.08.2006"
    },
    {
        "engDate": "1949-11-29",
        "nepDate": "14.08.2006"
    },
    {
        "engDate": "1949-11-30",
        "nepDate": "15.08.2006"
    },
    {
        "engDate": "1949-12-01",
        "nepDate": "16.08.2006"
    },
    {
        "engDate": "1949-12-02",
        "nepDate": "17.08.2006"
    },
    {
        "engDate": "1949-12-03",
        "nepDate": "18.08.2006"
    },
    {
        "engDate": "1949-12-04",
        "nepDate": "19.08.2006"
    },
    {
        "engDate": "1949-12-05",
        "nepDate": "20.08.2006"
    },
    {
        "engDate": "1949-12-06",
        "nepDate": "21.08.2006"
    },
    {
        "engDate": "1949-12-07",
        "nepDate": "22.08.2006"
    },
    {
        "engDate": "1949-12-08",
        "nepDate": "23.08.2006"
    },
    {
        "engDate": "1949-12-09",
        "nepDate": "24.08.2006"
    },
    {
        "engDate": "1949-12-10",
        "nepDate": "25.08.2006"
    },
    {
        "engDate": "1949-12-11",
        "nepDate": "26.08.2006"
    },
    {
        "engDate": "1949-12-12",
        "nepDate": "27.08.2006"
    },
    {
        "engDate": "1949-12-13",
        "nepDate": "28.08.2006"
    },
    {
        "engDate": "1949-12-14",
        "nepDate": "29.08.2006"
    },
    {
        "engDate": "1949-12-15",
        "nepDate": "01.09.2006"
    },
    {
        "engDate": "1949-12-16",
        "nepDate": "02.09.2006"
    },
    {
        "engDate": "1949-12-17",
        "nepDate": "03.09.2006"
    },
    {
        "engDate": "1949-12-18",
        "nepDate": "04.09.2006"
    },
    {
        "engDate": "1949-12-19",
        "nepDate": "05.09.2006"
    },
    {
        "engDate": "1949-12-20",
        "nepDate": "06.09.2006"
    },
    {
        "engDate": "1949-12-21",
        "nepDate": "07.09.2006"
    },
    {
        "engDate": "1949-12-22",
        "nepDate": "08.09.2006"
    },
    {
        "engDate": "1949-12-23",
        "nepDate": "09.09.2006"
    },
    {
        "engDate": "1949-12-24",
        "nepDate": "10.09.2006"
    },
    {
        "engDate": "1949-12-25",
        "nepDate": "11.09.2006"
    },
    {
        "engDate": "1949-12-26",
        "nepDate": "12.09.2006"
    },
    {
        "engDate": "1949-12-27",
        "nepDate": "13.09.2006"
    },
    {
        "engDate": "1949-12-28",
        "nepDate": "14.09.2006"
    },
    {
        "engDate": "1949-12-29",
        "nepDate": "15.09.2006"
    },
    {
        "engDate": "1949-12-30",
        "nepDate": "16.09.2006"
    },
    {
        "engDate": "1949-12-31",
        "nepDate": "17.09.2006"
    },
    {
        "engDate": "1950-01-01",
        "nepDate": "18.09.2006"
    },
    {
        "engDate": "1950-01-02",
        "nepDate": "19.09.2006"
    },
    {
        "engDate": "1950-01-03",
        "nepDate": "20.09.2006"
    },
    {
        "engDate": "1950-01-04",
        "nepDate": "21.09.2006"
    },
    {
        "engDate": "1950-01-05",
        "nepDate": "22.09.2006"
    },
    {
        "engDate": "1950-01-06",
        "nepDate": "23.09.2006"
    },
    {
        "engDate": "1950-01-07",
        "nepDate": "24.09.2006"
    },
    {
        "engDate": "1950-01-08",
        "nepDate": "25.09.2006"
    },
    {
        "engDate": "1950-01-09",
        "nepDate": "26.09.2006"
    },
    {
        "engDate": "1950-01-10",
        "nepDate": "27.09.2006"
    },
    {
        "engDate": "1950-01-11",
        "nepDate": "28.09.2006"
    },
    {
        "engDate": "1950-01-12",
        "nepDate": "29.09.2006"
    },
    {
        "engDate": "1950-01-13",
        "nepDate": "30.09.2006"
    },
    {
        "engDate": "1950-01-14",
        "nepDate": "01.10.2006"
    },
    {
        "engDate": "1950-01-15",
        "nepDate": "02.10.2006"
    },
    {
        "engDate": "1950-01-16",
        "nepDate": "03.10.2006"
    },
    {
        "engDate": "1950-01-17",
        "nepDate": "04.10.2006"
    },
    {
        "engDate": "1950-01-18",
        "nepDate": "05.10.2006"
    },
    {
        "engDate": "1950-01-19",
        "nepDate": "06.10.2006"
    },
    {
        "engDate": "1950-01-20",
        "nepDate": "07.10.2006"
    },
    {
        "engDate": "1950-01-21",
        "nepDate": "08.10.2006"
    },
    {
        "engDate": "1950-01-22",
        "nepDate": "09.10.2006"
    },
    {
        "engDate": "1950-01-23",
        "nepDate": "10.10.2006"
    },
    {
        "engDate": "1950-01-24",
        "nepDate": "11.10.2006"
    },
    {
        "engDate": "1950-01-25",
        "nepDate": "12.10.2006"
    },
    {
        "engDate": "1950-01-26",
        "nepDate": "13.10.2006"
    },
    {
        "engDate": "1950-01-27",
        "nepDate": "14.10.2006"
    },
    {
        "engDate": "1950-01-28",
        "nepDate": "15.10.2006"
    },
    {
        "engDate": "1950-01-29",
        "nepDate": "16.10.2006"
    },
    {
        "engDate": "1950-01-30",
        "nepDate": "17.10.2006"
    },
    {
        "engDate": "1950-01-31",
        "nepDate": "18.10.2006"
    },
    {
        "engDate": "1950-02-01",
        "nepDate": "19.10.2006"
    },
    {
        "engDate": "1950-02-02",
        "nepDate": "20.10.2006"
    },
    {
        "engDate": "1950-02-03",
        "nepDate": "21.10.2006"
    },
    {
        "engDate": "1950-02-04",
        "nepDate": "22.10.2006"
    },
    {
        "engDate": "1950-02-05",
        "nepDate": "23.10.2006"
    },
    {
        "engDate": "1950-02-06",
        "nepDate": "24.10.2006"
    },
    {
        "engDate": "1950-02-07",
        "nepDate": "25.10.2006"
    },
    {
        "engDate": "1950-02-08",
        "nepDate": "26.10.2006"
    },
    {
        "engDate": "1950-02-09",
        "nepDate": "27.10.2006"
    },
    {
        "engDate": "1950-02-10",
        "nepDate": "28.10.2006"
    },
    {
        "engDate": "1950-02-11",
        "nepDate": "29.10.2006"
    },
    {
        "engDate": "1950-02-12",
        "nepDate": "01.11.2006"
    },
    {
        "engDate": "1950-02-13",
        "nepDate": "02.11.2006"
    },
    {
        "engDate": "1950-02-14",
        "nepDate": "03.11.2006"
    },
    {
        "engDate": "1950-02-15",
        "nepDate": "04.11.2006"
    },
    {
        "engDate": "1950-02-16",
        "nepDate": "05.11.2006"
    },
    {
        "engDate": "1950-02-17",
        "nepDate": "06.11.2006"
    },
    {
        "engDate": "1950-02-18",
        "nepDate": "07.11.2006"
    },
    {
        "engDate": "1950-02-19",
        "nepDate": "08.11.2006"
    },
    {
        "engDate": "1950-02-20",
        "nepDate": "09.11.2006"
    },
    {
        "engDate": "1950-02-21",
        "nepDate": "10.11.2006"
    },
    {
        "engDate": "1950-02-22",
        "nepDate": "11.11.2006"
    },
    {
        "engDate": "1950-02-23",
        "nepDate": "12.11.2006"
    },
    {
        "engDate": "1950-02-24",
        "nepDate": "13.11.2006"
    },
    {
        "engDate": "1950-02-25",
        "nepDate": "14.11.2006"
    },
    {
        "engDate": "1950-02-26",
        "nepDate": "15.11.2006"
    },
    {
        "engDate": "1950-02-27",
        "nepDate": "16.11.2006"
    },
    {
        "engDate": "1950-02-28",
        "nepDate": "17.11.2006"
    },
    {
        "engDate": "1950-03-01",
        "nepDate": "18.11.2006"
    },
    {
        "engDate": "1950-03-02",
        "nepDate": "19.11.2006"
    },
    {
        "engDate": "1950-03-03",
        "nepDate": "20.11.2006"
    },
    {
        "engDate": "1950-03-04",
        "nepDate": "21.11.2006"
    },
    {
        "engDate": "1950-03-05",
        "nepDate": "22.11.2006"
    },
    {
        "engDate": "1950-03-06",
        "nepDate": "23.11.2006"
    },
    {
        "engDate": "1950-03-07",
        "nepDate": "24.11.2006"
    },
    {
        "engDate": "1950-03-08",
        "nepDate": "25.11.2006"
    },
    {
        "engDate": "1950-03-09",
        "nepDate": "26.11.2006"
    },
    {
        "engDate": "1950-03-10",
        "nepDate": "27.11.2006"
    },
    {
        "engDate": "1950-03-11",
        "nepDate": "28.11.2006"
    },
    {
        "engDate": "1950-03-12",
        "nepDate": "29.11.2006"
    },
    {
        "engDate": "1950-03-13",
        "nepDate": "30.11.2006"
    },
    {
        "engDate": "1950-03-14",
        "nepDate": "01.12.2006"
    },
    {
        "engDate": "1950-03-15",
        "nepDate": "02.12.2006"
    },
    {
        "engDate": "1950-03-16",
        "nepDate": "03.12.2006"
    },
    {
        "engDate": "1950-03-17",
        "nepDate": "04.12.2006"
    },
    {
        "engDate": "1950-03-18",
        "nepDate": "05.12.2006"
    },
    {
        "engDate": "1950-03-19",
        "nepDate": "06.12.2006"
    },
    {
        "engDate": "1950-03-20",
        "nepDate": "07.12.2006"
    },
    {
        "engDate": "1950-03-21",
        "nepDate": "08.12.2006"
    },
    {
        "engDate": "1950-03-22",
        "nepDate": "09.12.2006"
    },
    {
        "engDate": "1950-03-23",
        "nepDate": "10.12.2006"
    },
    {
        "engDate": "1950-03-24",
        "nepDate": "11.12.2006"
    },
    {
        "engDate": "1950-03-25",
        "nepDate": "12.12.2006"
    },
    {
        "engDate": "1950-03-26",
        "nepDate": "13.12.2006"
    },
    {
        "engDate": "1950-03-27",
        "nepDate": "14.12.2006"
    },
    {
        "engDate": "1950-03-28",
        "nepDate": "15.12.2006"
    },
    {
        "engDate": "1950-03-29",
        "nepDate": "16.12.2006"
    },
    {
        "engDate": "1950-03-30",
        "nepDate": "17.12.2006"
    },
    {
        "engDate": "1950-03-31",
        "nepDate": "18.12.2006"
    },
    {
        "engDate": "1950-04-01",
        "nepDate": "19.12.2006"
    },
    {
        "engDate": "1950-04-02",
        "nepDate": "20.12.2006"
    },
    {
        "engDate": "1950-04-03",
        "nepDate": "21.12.2006"
    },
    {
        "engDate": "1950-04-04",
        "nepDate": "22.12.2006"
    },
    {
        "engDate": "1950-04-05",
        "nepDate": "23.12.2006"
    },
    {
        "engDate": "1950-04-06",
        "nepDate": "24.12.2006"
    },
    {
        "engDate": "1950-04-07",
        "nepDate": "25.12.2006"
    },
    {
        "engDate": "1950-04-08",
        "nepDate": "26.12.2006"
    },
    {
        "engDate": "1950-04-09",
        "nepDate": "27.12.2006"
    },
    {
        "engDate": "1950-04-10",
        "nepDate": "28.12.2006"
    },
    {
        "engDate": "1950-04-11",
        "nepDate": "29.12.2006"
    },
    {
        "engDate": "1950-04-12",
        "nepDate": "30.12.2006"
    },
    {
        "engDate": "1950-04-13",
        "nepDate": "01.01.2007"
    },
    {
        "engDate": "1950-04-14",
        "nepDate": "02.01.2007"
    },
    {
        "engDate": "1950-04-15",
        "nepDate": "03.01.2007"
    },
    {
        "engDate": "1950-04-16",
        "nepDate": "04.01.2007"
    },
    {
        "engDate": "1950-04-17",
        "nepDate": "05.01.2007"
    },
    {
        "engDate": "1950-04-18",
        "nepDate": "06.01.2007"
    },
    {
        "engDate": "1950-04-19",
        "nepDate": "07.01.2007"
    },
    {
        "engDate": "1950-04-20",
        "nepDate": "08.01.2007"
    },
    {
        "engDate": "1950-04-21",
        "nepDate": "09.01.2007"
    },
    {
        "engDate": "1950-04-22",
        "nepDate": "10.01.2007"
    },
    {
        "engDate": "1950-04-23",
        "nepDate": "11.01.2007"
    },
    {
        "engDate": "1950-04-24",
        "nepDate": "12.01.2007"
    },
    {
        "engDate": "1950-04-25",
        "nepDate": "13.01.2007"
    },
    {
        "engDate": "1950-04-26",
        "nepDate": "14.01.2007"
    },
    {
        "engDate": "1950-04-27",
        "nepDate": "15.01.2007"
    },
    {
        "engDate": "1950-04-28",
        "nepDate": "16.01.2007"
    },
    {
        "engDate": "1950-04-29",
        "nepDate": "17.01.2007"
    },
    {
        "engDate": "1950-04-30",
        "nepDate": "18.01.2007"
    },
    {
        "engDate": "1950-05-01",
        "nepDate": "19.01.2007"
    },
    {
        "engDate": "1950-05-02",
        "nepDate": "20.01.2007"
    },
    {
        "engDate": "1950-05-03",
        "nepDate": "21.01.2007"
    },
    {
        "engDate": "1950-05-04",
        "nepDate": "22.01.2007"
    },
    {
        "engDate": "1950-05-05",
        "nepDate": "23.01.2007"
    },
    {
        "engDate": "1950-05-06",
        "nepDate": "24.01.2007"
    },
    {
        "engDate": "1950-05-07",
        "nepDate": "25.01.2007"
    },
    {
        "engDate": "1950-05-08",
        "nepDate": "26.01.2007"
    },
    {
        "engDate": "1950-05-09",
        "nepDate": "27.01.2007"
    },
    {
        "engDate": "1950-05-10",
        "nepDate": "28.01.2007"
    },
    {
        "engDate": "1950-05-11",
        "nepDate": "29.01.2007"
    },
    {
        "engDate": "1950-05-12",
        "nepDate": "30.01.2007"
    },
    {
        "engDate": "1950-05-13",
        "nepDate": "31.01.2007"
    },
    {
        "engDate": "1950-05-14",
        "nepDate": "01.02.2007"
    },
    {
        "engDate": "1950-05-15",
        "nepDate": "02.02.2007"
    },
    {
        "engDate": "1950-05-16",
        "nepDate": "03.02.2007"
    },
    {
        "engDate": "1950-05-17",
        "nepDate": "04.02.2007"
    },
    {
        "engDate": "1950-05-18",
        "nepDate": "05.02.2007"
    },
    {
        "engDate": "1950-05-19",
        "nepDate": "06.02.2007"
    },
    {
        "engDate": "1950-05-20",
        "nepDate": "07.02.2007"
    },
    {
        "engDate": "1950-05-21",
        "nepDate": "08.02.2007"
    },
    {
        "engDate": "1950-05-22",
        "nepDate": "09.02.2007"
    },
    {
        "engDate": "1950-05-23",
        "nepDate": "10.02.2007"
    },
    {
        "engDate": "1950-05-24",
        "nepDate": "11.02.2007"
    },
    {
        "engDate": "1950-05-25",
        "nepDate": "12.02.2007"
    },
    {
        "engDate": "1950-05-26",
        "nepDate": "13.02.2007"
    },
    {
        "engDate": "1950-05-27",
        "nepDate": "14.02.2007"
    },
    {
        "engDate": "1950-05-28",
        "nepDate": "15.02.2007"
    },
    {
        "engDate": "1950-05-29",
        "nepDate": "16.02.2007"
    },
    {
        "engDate": "1950-05-30",
        "nepDate": "17.02.2007"
    },
    {
        "engDate": "1950-05-31",
        "nepDate": "18.02.2007"
    },
    {
        "engDate": "1950-06-01",
        "nepDate": "19.02.2007"
    },
    {
        "engDate": "1950-06-02",
        "nepDate": "20.02.2007"
    },
    {
        "engDate": "1950-06-03",
        "nepDate": "21.02.2007"
    },
    {
        "engDate": "1950-06-04",
        "nepDate": "22.02.2007"
    },
    {
        "engDate": "1950-06-05",
        "nepDate": "23.02.2007"
    },
    {
        "engDate": "1950-06-06",
        "nepDate": "24.02.2007"
    },
    {
        "engDate": "1950-06-07",
        "nepDate": "25.02.2007"
    },
    {
        "engDate": "1950-06-08",
        "nepDate": "26.02.2007"
    },
    {
        "engDate": "1950-06-09",
        "nepDate": "27.02.2007"
    },
    {
        "engDate": "1950-06-10",
        "nepDate": "28.02.2007"
    },
    {
        "engDate": "1950-06-11",
        "nepDate": "29.02.2007"
    },
    {
        "engDate": "1950-06-12",
        "nepDate": "30.02.2007"
    },
    {
        "engDate": "1950-06-13",
        "nepDate": "31.02.2007"
    },
    {
        "engDate": "1950-06-14",
        "nepDate": "32.02.2007"
    },
    {
        "engDate": "1950-06-15",
        "nepDate": "01.03.2007"
    },
    {
        "engDate": "1950-06-16",
        "nepDate": "02.03.2007"
    },
    {
        "engDate": "1950-06-17",
        "nepDate": "03.03.2007"
    },
    {
        "engDate": "1950-06-18",
        "nepDate": "04.03.2007"
    },
    {
        "engDate": "1950-06-19",
        "nepDate": "05.03.2007"
    },
    {
        "engDate": "1950-06-20",
        "nepDate": "06.03.2007"
    },
    {
        "engDate": "1950-06-21",
        "nepDate": "07.03.2007"
    },
    {
        "engDate": "1950-06-22",
        "nepDate": "08.03.2007"
    },
    {
        "engDate": "1950-06-23",
        "nepDate": "09.03.2007"
    },
    {
        "engDate": "1950-06-24",
        "nepDate": "10.03.2007"
    },
    {
        "engDate": "1950-06-25",
        "nepDate": "11.03.2007"
    },
    {
        "engDate": "1950-06-26",
        "nepDate": "12.03.2007"
    },
    {
        "engDate": "1950-06-27",
        "nepDate": "13.03.2007"
    },
    {
        "engDate": "1950-06-28",
        "nepDate": "14.03.2007"
    },
    {
        "engDate": "1950-06-29",
        "nepDate": "15.03.2007"
    },
    {
        "engDate": "1950-06-30",
        "nepDate": "16.03.2007"
    },
    {
        "engDate": "1950-07-01",
        "nepDate": "17.03.2007"
    },
    {
        "engDate": "1950-07-02",
        "nepDate": "18.03.2007"
    },
    {
        "engDate": "1950-07-03",
        "nepDate": "19.03.2007"
    },
    {
        "engDate": "1950-07-04",
        "nepDate": "20.03.2007"
    },
    {
        "engDate": "1950-07-05",
        "nepDate": "21.03.2007"
    },
    {
        "engDate": "1950-07-06",
        "nepDate": "22.03.2007"
    },
    {
        "engDate": "1950-07-07",
        "nepDate": "23.03.2007"
    },
    {
        "engDate": "1950-07-08",
        "nepDate": "24.03.2007"
    },
    {
        "engDate": "1950-07-09",
        "nepDate": "25.03.2007"
    },
    {
        "engDate": "1950-07-10",
        "nepDate": "26.03.2007"
    },
    {
        "engDate": "1950-07-11",
        "nepDate": "27.03.2007"
    },
    {
        "engDate": "1950-07-12",
        "nepDate": "28.03.2007"
    },
    {
        "engDate": "1950-07-13",
        "nepDate": "29.03.2007"
    },
    {
        "engDate": "1950-07-14",
        "nepDate": "30.03.2007"
    },
    {
        "engDate": "1950-07-15",
        "nepDate": "31.03.2007"
    },
    {
        "engDate": "1950-07-16",
        "nepDate": "01.04.2007"
    },
    {
        "engDate": "1950-07-17",
        "nepDate": "02.04.2007"
    },
    {
        "engDate": "1950-07-18",
        "nepDate": "03.04.2007"
    },
    {
        "engDate": "1950-07-19",
        "nepDate": "04.04.2007"
    },
    {
        "engDate": "1950-07-20",
        "nepDate": "05.04.2007"
    },
    {
        "engDate": "1950-07-21",
        "nepDate": "06.04.2007"
    },
    {
        "engDate": "1950-07-22",
        "nepDate": "07.04.2007"
    },
    {
        "engDate": "1950-07-23",
        "nepDate": "08.04.2007"
    },
    {
        "engDate": "1950-07-24",
        "nepDate": "09.04.2007"
    },
    {
        "engDate": "1950-07-25",
        "nepDate": "10.04.2007"
    },
    {
        "engDate": "1950-07-26",
        "nepDate": "11.04.2007"
    },
    {
        "engDate": "1950-07-27",
        "nepDate": "12.04.2007"
    },
    {
        "engDate": "1950-07-28",
        "nepDate": "13.04.2007"
    },
    {
        "engDate": "1950-07-29",
        "nepDate": "14.04.2007"
    },
    {
        "engDate": "1950-07-30",
        "nepDate": "15.04.2007"
    },
    {
        "engDate": "1950-07-31",
        "nepDate": "16.04.2007"
    },
    {
        "engDate": "1950-08-01",
        "nepDate": "17.04.2007"
    },
    {
        "engDate": "1950-08-02",
        "nepDate": "18.04.2007"
    },
    {
        "engDate": "1950-08-03",
        "nepDate": "19.04.2007"
    },
    {
        "engDate": "1950-08-04",
        "nepDate": "20.04.2007"
    },
    {
        "engDate": "1950-08-05",
        "nepDate": "21.04.2007"
    },
    {
        "engDate": "1950-08-06",
        "nepDate": "22.04.2007"
    },
    {
        "engDate": "1950-08-07",
        "nepDate": "23.04.2007"
    },
    {
        "engDate": "1950-08-08",
        "nepDate": "24.04.2007"
    },
    {
        "engDate": "1950-08-09",
        "nepDate": "25.04.2007"
    },
    {
        "engDate": "1950-08-10",
        "nepDate": "26.04.2007"
    },
    {
        "engDate": "1950-08-11",
        "nepDate": "27.04.2007"
    },
    {
        "engDate": "1950-08-12",
        "nepDate": "28.04.2007"
    },
    {
        "engDate": "1950-08-13",
        "nepDate": "29.04.2007"
    },
    {
        "engDate": "1950-08-14",
        "nepDate": "30.04.2007"
    },
    {
        "engDate": "1950-08-15",
        "nepDate": "31.04.2007"
    },
    {
        "engDate": "1950-08-16",
        "nepDate": "32.04.2007"
    },
    {
        "engDate": "1950-08-17",
        "nepDate": "01.05.2007"
    },
    {
        "engDate": "1950-08-18",
        "nepDate": "02.05.2007"
    },
    {
        "engDate": "1950-08-19",
        "nepDate": "03.05.2007"
    },
    {
        "engDate": "1950-08-20",
        "nepDate": "04.05.2007"
    },
    {
        "engDate": "1950-08-21",
        "nepDate": "05.05.2007"
    },
    {
        "engDate": "1950-08-22",
        "nepDate": "06.05.2007"
    },
    {
        "engDate": "1950-08-23",
        "nepDate": "07.05.2007"
    },
    {
        "engDate": "1950-08-24",
        "nepDate": "08.05.2007"
    },
    {
        "engDate": "1950-08-25",
        "nepDate": "09.05.2007"
    },
    {
        "engDate": "1950-08-26",
        "nepDate": "10.05.2007"
    },
    {
        "engDate": "1950-08-27",
        "nepDate": "11.05.2007"
    },
    {
        "engDate": "1950-08-28",
        "nepDate": "12.05.2007"
    },
    {
        "engDate": "1950-08-29",
        "nepDate": "13.05.2007"
    },
    {
        "engDate": "1950-08-30",
        "nepDate": "14.05.2007"
    },
    {
        "engDate": "1950-08-31",
        "nepDate": "15.05.2007"
    },
    {
        "engDate": "1950-09-01",
        "nepDate": "16.05.2007"
    },
    {
        "engDate": "1950-09-02",
        "nepDate": "17.05.2007"
    },
    {
        "engDate": "1950-09-03",
        "nepDate": "18.05.2007"
    },
    {
        "engDate": "1950-09-04",
        "nepDate": "19.05.2007"
    },
    {
        "engDate": "1950-09-05",
        "nepDate": "20.05.2007"
    },
    {
        "engDate": "1950-09-06",
        "nepDate": "21.05.2007"
    },
    {
        "engDate": "1950-09-07",
        "nepDate": "22.05.2007"
    },
    {
        "engDate": "1950-09-08",
        "nepDate": "23.05.2007"
    },
    {
        "engDate": "1950-09-09",
        "nepDate": "24.05.2007"
    },
    {
        "engDate": "1950-09-10",
        "nepDate": "25.05.2007"
    },
    {
        "engDate": "1950-09-11",
        "nepDate": "26.05.2007"
    },
    {
        "engDate": "1950-09-12",
        "nepDate": "27.05.2007"
    },
    {
        "engDate": "1950-09-13",
        "nepDate": "28.05.2007"
    },
    {
        "engDate": "1950-09-14",
        "nepDate": "29.05.2007"
    },
    {
        "engDate": "1950-09-15",
        "nepDate": "30.05.2007"
    },
    {
        "engDate": "1950-09-16",
        "nepDate": "31.05.2007"
    },
    {
        "engDate": "1950-09-17",
        "nepDate": "01.06.2007"
    },
    {
        "engDate": "1950-09-18",
        "nepDate": "02.06.2007"
    },
    {
        "engDate": "1950-09-19",
        "nepDate": "03.06.2007"
    },
    {
        "engDate": "1950-09-20",
        "nepDate": "04.06.2007"
    },
    {
        "engDate": "1950-09-21",
        "nepDate": "05.06.2007"
    },
    {
        "engDate": "1950-09-22",
        "nepDate": "06.06.2007"
    },
    {
        "engDate": "1950-09-23",
        "nepDate": "07.06.2007"
    },
    {
        "engDate": "1950-09-24",
        "nepDate": "08.06.2007"
    },
    {
        "engDate": "1950-09-25",
        "nepDate": "09.06.2007"
    },
    {
        "engDate": "1950-09-26",
        "nepDate": "10.06.2007"
    },
    {
        "engDate": "1950-09-27",
        "nepDate": "11.06.2007"
    },
    {
        "engDate": "1950-09-28",
        "nepDate": "12.06.2007"
    },
    {
        "engDate": "1950-09-29",
        "nepDate": "13.06.2007"
    },
    {
        "engDate": "1950-09-30",
        "nepDate": "14.06.2007"
    },
    {
        "engDate": "1950-10-01",
        "nepDate": "15.06.2007"
    },
    {
        "engDate": "1950-10-02",
        "nepDate": "16.06.2007"
    },
    {
        "engDate": "1950-10-03",
        "nepDate": "17.06.2007"
    },
    {
        "engDate": "1950-10-04",
        "nepDate": "18.06.2007"
    },
    {
        "engDate": "1950-10-05",
        "nepDate": "19.06.2007"
    },
    {
        "engDate": "1950-10-06",
        "nepDate": "20.06.2007"
    },
    {
        "engDate": "1950-10-07",
        "nepDate": "21.06.2007"
    },
    {
        "engDate": "1950-10-08",
        "nepDate": "22.06.2007"
    },
    {
        "engDate": "1950-10-09",
        "nepDate": "23.06.2007"
    },
    {
        "engDate": "1950-10-10",
        "nepDate": "24.06.2007"
    },
    {
        "engDate": "1950-10-11",
        "nepDate": "25.06.2007"
    },
    {
        "engDate": "1950-10-12",
        "nepDate": "26.06.2007"
    },
    {
        "engDate": "1950-10-13",
        "nepDate": "27.06.2007"
    },
    {
        "engDate": "1950-10-14",
        "nepDate": "28.06.2007"
    },
    {
        "engDate": "1950-10-15",
        "nepDate": "29.06.2007"
    },
    {
        "engDate": "1950-10-16",
        "nepDate": "30.06.2007"
    },
    {
        "engDate": "1950-10-17",
        "nepDate": "01.07.2007"
    },
    {
        "engDate": "1950-10-18",
        "nepDate": "02.07.2007"
    },
    {
        "engDate": "1950-10-19",
        "nepDate": "03.07.2007"
    },
    {
        "engDate": "1950-10-20",
        "nepDate": "04.07.2007"
    },
    {
        "engDate": "1950-10-21",
        "nepDate": "05.07.2007"
    },
    {
        "engDate": "1950-10-22",
        "nepDate": "06.07.2007"
    },
    {
        "engDate": "1950-10-23",
        "nepDate": "07.07.2007"
    },
    {
        "engDate": "1950-10-24",
        "nepDate": "08.07.2007"
    },
    {
        "engDate": "1950-10-25",
        "nepDate": "09.07.2007"
    },
    {
        "engDate": "1950-10-26",
        "nepDate": "10.07.2007"
    },
    {
        "engDate": "1950-10-27",
        "nepDate": "11.07.2007"
    },
    {
        "engDate": "1950-10-28",
        "nepDate": "12.07.2007"
    },
    {
        "engDate": "1950-10-29",
        "nepDate": "13.07.2007"
    },
    {
        "engDate": "1950-10-30",
        "nepDate": "14.07.2007"
    },
    {
        "engDate": "1950-10-31",
        "nepDate": "15.07.2007"
    },
    {
        "engDate": "1950-11-01",
        "nepDate": "16.07.2007"
    },
    {
        "engDate": "1950-11-02",
        "nepDate": "17.07.2007"
    },
    {
        "engDate": "1950-11-03",
        "nepDate": "18.07.2007"
    },
    {
        "engDate": "1950-11-04",
        "nepDate": "19.07.2007"
    },
    {
        "engDate": "1950-11-05",
        "nepDate": "20.07.2007"
    },
    {
        "engDate": "1950-11-06",
        "nepDate": "21.07.2007"
    },
    {
        "engDate": "1950-11-07",
        "nepDate": "22.07.2007"
    },
    {
        "engDate": "1950-11-08",
        "nepDate": "23.07.2007"
    },
    {
        "engDate": "1950-11-09",
        "nepDate": "24.07.2007"
    },
    {
        "engDate": "1950-11-10",
        "nepDate": "25.07.2007"
    },
    {
        "engDate": "1950-11-11",
        "nepDate": "26.07.2007"
    },
    {
        "engDate": "1950-11-12",
        "nepDate": "27.07.2007"
    },
    {
        "engDate": "1950-11-13",
        "nepDate": "28.07.2007"
    },
    {
        "engDate": "1950-11-14",
        "nepDate": "29.07.2007"
    },
    {
        "engDate": "1950-11-15",
        "nepDate": "30.07.2007"
    },
    {
        "engDate": "1950-11-16",
        "nepDate": "01.08.2007"
    },
    {
        "engDate": "1950-11-17",
        "nepDate": "02.08.2007"
    },
    {
        "engDate": "1950-11-18",
        "nepDate": "03.08.2007"
    },
    {
        "engDate": "1950-11-19",
        "nepDate": "04.08.2007"
    },
    {
        "engDate": "1950-11-20",
        "nepDate": "05.08.2007"
    },
    {
        "engDate": "1950-11-21",
        "nepDate": "06.08.2007"
    },
    {
        "engDate": "1950-11-22",
        "nepDate": "07.08.2007"
    },
    {
        "engDate": "1950-11-23",
        "nepDate": "08.08.2007"
    },
    {
        "engDate": "1950-11-24",
        "nepDate": "09.08.2007"
    },
    {
        "engDate": "1950-11-25",
        "nepDate": "10.08.2007"
    },
    {
        "engDate": "1950-11-26",
        "nepDate": "11.08.2007"
    },
    {
        "engDate": "1950-11-27",
        "nepDate": "12.08.2007"
    },
    {
        "engDate": "1950-11-28",
        "nepDate": "13.08.2007"
    },
    {
        "engDate": "1950-11-29",
        "nepDate": "14.08.2007"
    },
    {
        "engDate": "1950-11-30",
        "nepDate": "15.08.2007"
    },
    {
        "engDate": "1950-12-01",
        "nepDate": "16.08.2007"
    },
    {
        "engDate": "1950-12-02",
        "nepDate": "17.08.2007"
    },
    {
        "engDate": "1950-12-03",
        "nepDate": "18.08.2007"
    },
    {
        "engDate": "1950-12-04",
        "nepDate": "19.08.2007"
    },
    {
        "engDate": "1950-12-05",
        "nepDate": "20.08.2007"
    },
    {
        "engDate": "1950-12-06",
        "nepDate": "21.08.2007"
    },
    {
        "engDate": "1950-12-07",
        "nepDate": "22.08.2007"
    },
    {
        "engDate": "1950-12-08",
        "nepDate": "23.08.2007"
    },
    {
        "engDate": "1950-12-09",
        "nepDate": "24.08.2007"
    },
    {
        "engDate": "1950-12-10",
        "nepDate": "25.08.2007"
    },
    {
        "engDate": "1950-12-11",
        "nepDate": "26.08.2007"
    },
    {
        "engDate": "1950-12-12",
        "nepDate": "27.08.2007"
    },
    {
        "engDate": "1950-12-13",
        "nepDate": "28.08.2007"
    },
    {
        "engDate": "1950-12-14",
        "nepDate": "29.08.2007"
    },
    {
        "engDate": "1950-12-15",
        "nepDate": "30.08.2007"
    },
    {
        "engDate": "1950-12-16",
        "nepDate": "01.09.2007"
    },
    {
        "engDate": "1950-12-17",
        "nepDate": "02.09.2007"
    },
    {
        "engDate": "1950-12-18",
        "nepDate": "03.09.2007"
    },
    {
        "engDate": "1950-12-19",
        "nepDate": "04.09.2007"
    },
    {
        "engDate": "1950-12-20",
        "nepDate": "05.09.2007"
    },
    {
        "engDate": "1950-12-21",
        "nepDate": "06.09.2007"
    },
    {
        "engDate": "1950-12-22",
        "nepDate": "07.09.2007"
    },
    {
        "engDate": "1950-12-23",
        "nepDate": "08.09.2007"
    },
    {
        "engDate": "1950-12-24",
        "nepDate": "09.09.2007"
    },
    {
        "engDate": "1950-12-25",
        "nepDate": "10.09.2007"
    },
    {
        "engDate": "1950-12-26",
        "nepDate": "11.09.2007"
    },
    {
        "engDate": "1950-12-27",
        "nepDate": "12.09.2007"
    },
    {
        "engDate": "1950-12-28",
        "nepDate": "13.09.2007"
    },
    {
        "engDate": "1950-12-29",
        "nepDate": "14.09.2007"
    },
    {
        "engDate": "1950-12-30",
        "nepDate": "15.09.2007"
    },
    {
        "engDate": "1950-12-31",
        "nepDate": "16.09.2007"
    },
    {
        "engDate": "1951-01-01",
        "nepDate": "17.09.2007"
    },
    {
        "engDate": "1951-01-02",
        "nepDate": "18.09.2007"
    },
    {
        "engDate": "1951-01-03",
        "nepDate": "19.09.2007"
    },
    {
        "engDate": "1951-01-04",
        "nepDate": "20.09.2007"
    },
    {
        "engDate": "1951-01-05",
        "nepDate": "21.09.2007"
    },
    {
        "engDate": "1951-01-06",
        "nepDate": "22.09.2007"
    },
    {
        "engDate": "1951-01-07",
        "nepDate": "23.09.2007"
    },
    {
        "engDate": "1951-01-08",
        "nepDate": "24.09.2007"
    },
    {
        "engDate": "1951-01-09",
        "nepDate": "25.09.2007"
    },
    {
        "engDate": "1951-01-10",
        "nepDate": "26.09.2007"
    },
    {
        "engDate": "1951-01-11",
        "nepDate": "27.09.2007"
    },
    {
        "engDate": "1951-01-12",
        "nepDate": "28.09.2007"
    },
    {
        "engDate": "1951-01-13",
        "nepDate": "29.09.2007"
    },
    {
        "engDate": "1951-01-14",
        "nepDate": "01.10.2007"
    },
    {
        "engDate": "1951-01-15",
        "nepDate": "02.10.2007"
    },
    {
        "engDate": "1951-01-16",
        "nepDate": "03.10.2007"
    },
    {
        "engDate": "1951-01-17",
        "nepDate": "04.10.2007"
    },
    {
        "engDate": "1951-01-18",
        "nepDate": "05.10.2007"
    },
    {
        "engDate": "1951-01-19",
        "nepDate": "06.10.2007"
    },
    {
        "engDate": "1951-01-20",
        "nepDate": "07.10.2007"
    },
    {
        "engDate": "1951-01-21",
        "nepDate": "08.10.2007"
    },
    {
        "engDate": "1951-01-22",
        "nepDate": "09.10.2007"
    },
    {
        "engDate": "1951-01-23",
        "nepDate": "10.10.2007"
    },
    {
        "engDate": "1951-01-24",
        "nepDate": "11.10.2007"
    },
    {
        "engDate": "1951-01-25",
        "nepDate": "12.10.2007"
    },
    {
        "engDate": "1951-01-26",
        "nepDate": "13.10.2007"
    },
    {
        "engDate": "1951-01-27",
        "nepDate": "14.10.2007"
    },
    {
        "engDate": "1951-01-28",
        "nepDate": "15.10.2007"
    },
    {
        "engDate": "1951-01-29",
        "nepDate": "16.10.2007"
    },
    {
        "engDate": "1951-01-30",
        "nepDate": "17.10.2007"
    },
    {
        "engDate": "1951-01-31",
        "nepDate": "18.10.2007"
    },
    {
        "engDate": "1951-02-01",
        "nepDate": "19.10.2007"
    },
    {
        "engDate": "1951-02-02",
        "nepDate": "20.10.2007"
    },
    {
        "engDate": "1951-02-03",
        "nepDate": "21.10.2007"
    },
    {
        "engDate": "1951-02-04",
        "nepDate": "22.10.2007"
    },
    {
        "engDate": "1951-02-05",
        "nepDate": "23.10.2007"
    },
    {
        "engDate": "1951-02-06",
        "nepDate": "24.10.2007"
    },
    {
        "engDate": "1951-02-07",
        "nepDate": "25.10.2007"
    },
    {
        "engDate": "1951-02-08",
        "nepDate": "26.10.2007"
    },
    {
        "engDate": "1951-02-09",
        "nepDate": "27.10.2007"
    },
    {
        "engDate": "1951-02-10",
        "nepDate": "28.10.2007"
    },
    {
        "engDate": "1951-02-11",
        "nepDate": "29.10.2007"
    },
    {
        "engDate": "1951-02-12",
        "nepDate": "01.11.2007"
    },
    {
        "engDate": "1951-02-13",
        "nepDate": "02.11.2007"
    },
    {
        "engDate": "1951-02-14",
        "nepDate": "03.11.2007"
    },
    {
        "engDate": "1951-02-15",
        "nepDate": "04.11.2007"
    },
    {
        "engDate": "1951-02-16",
        "nepDate": "05.11.2007"
    },
    {
        "engDate": "1951-02-17",
        "nepDate": "06.11.2007"
    },
    {
        "engDate": "1951-02-18",
        "nepDate": "07.11.2007"
    },
    {
        "engDate": "1951-02-19",
        "nepDate": "08.11.2007"
    },
    {
        "engDate": "1951-02-20",
        "nepDate": "09.11.2007"
    },
    {
        "engDate": "1951-02-21",
        "nepDate": "10.11.2007"
    },
    {
        "engDate": "1951-02-22",
        "nepDate": "11.11.2007"
    },
    {
        "engDate": "1951-02-23",
        "nepDate": "12.11.2007"
    },
    {
        "engDate": "1951-02-24",
        "nepDate": "13.11.2007"
    },
    {
        "engDate": "1951-02-25",
        "nepDate": "14.11.2007"
    },
    {
        "engDate": "1951-02-26",
        "nepDate": "15.11.2007"
    },
    {
        "engDate": "1951-02-27",
        "nepDate": "16.11.2007"
    },
    {
        "engDate": "1951-02-28",
        "nepDate": "17.11.2007"
    },
    {
        "engDate": "1951-03-01",
        "nepDate": "18.11.2007"
    },
    {
        "engDate": "1951-03-02",
        "nepDate": "19.11.2007"
    },
    {
        "engDate": "1951-03-03",
        "nepDate": "20.11.2007"
    },
    {
        "engDate": "1951-03-04",
        "nepDate": "21.11.2007"
    },
    {
        "engDate": "1951-03-05",
        "nepDate": "22.11.2007"
    },
    {
        "engDate": "1951-03-06",
        "nepDate": "23.11.2007"
    },
    {
        "engDate": "1951-03-07",
        "nepDate": "24.11.2007"
    },
    {
        "engDate": "1951-03-08",
        "nepDate": "25.11.2007"
    },
    {
        "engDate": "1951-03-09",
        "nepDate": "26.11.2007"
    },
    {
        "engDate": "1951-03-10",
        "nepDate": "27.11.2007"
    },
    {
        "engDate": "1951-03-11",
        "nepDate": "28.11.2007"
    },
    {
        "engDate": "1951-03-12",
        "nepDate": "29.11.2007"
    },
    {
        "engDate": "1951-03-13",
        "nepDate": "30.11.2007"
    },
    {
        "engDate": "1951-03-14",
        "nepDate": "01.12.2007"
    },
    {
        "engDate": "1951-03-15",
        "nepDate": "02.12.2007"
    },
    {
        "engDate": "1951-03-16",
        "nepDate": "03.12.2007"
    },
    {
        "engDate": "1951-03-17",
        "nepDate": "04.12.2007"
    },
    {
        "engDate": "1951-03-18",
        "nepDate": "05.12.2007"
    },
    {
        "engDate": "1951-03-19",
        "nepDate": "06.12.2007"
    },
    {
        "engDate": "1951-03-20",
        "nepDate": "07.12.2007"
    },
    {
        "engDate": "1951-03-21",
        "nepDate": "08.12.2007"
    },
    {
        "engDate": "1951-03-22",
        "nepDate": "09.12.2007"
    },
    {
        "engDate": "1951-03-23",
        "nepDate": "10.12.2007"
    },
    {
        "engDate": "1951-03-24",
        "nepDate": "11.12.2007"
    },
    {
        "engDate": "1951-03-25",
        "nepDate": "12.12.2007"
    },
    {
        "engDate": "1951-03-26",
        "nepDate": "13.12.2007"
    },
    {
        "engDate": "1951-03-27",
        "nepDate": "14.12.2007"
    },
    {
        "engDate": "1951-03-28",
        "nepDate": "15.12.2007"
    },
    {
        "engDate": "1951-03-29",
        "nepDate": "16.12.2007"
    },
    {
        "engDate": "1951-03-30",
        "nepDate": "17.12.2007"
    },
    {
        "engDate": "1951-03-31",
        "nepDate": "18.12.2007"
    },
    {
        "engDate": "1951-04-01",
        "nepDate": "19.12.2007"
    },
    {
        "engDate": "1951-04-02",
        "nepDate": "20.12.2007"
    },
    {
        "engDate": "1951-04-03",
        "nepDate": "21.12.2007"
    },
    {
        "engDate": "1951-04-04",
        "nepDate": "22.12.2007"
    },
    {
        "engDate": "1951-04-05",
        "nepDate": "23.12.2007"
    },
    {
        "engDate": "1951-04-06",
        "nepDate": "24.12.2007"
    },
    {
        "engDate": "1951-04-07",
        "nepDate": "25.12.2007"
    },
    {
        "engDate": "1951-04-08",
        "nepDate": "26.12.2007"
    },
    {
        "engDate": "1951-04-09",
        "nepDate": "27.12.2007"
    },
    {
        "engDate": "1951-04-10",
        "nepDate": "28.12.2007"
    },
    {
        "engDate": "1951-04-11",
        "nepDate": "29.12.2007"
    },
    {
        "engDate": "1951-04-12",
        "nepDate": "30.12.2007"
    },
    {
        "engDate": "1951-04-13",
        "nepDate": "31.12.2007"
    },
    {
        "engDate": "1951-04-14",
        "nepDate": "01.01.2008"
    },
    {
        "engDate": "1951-04-15",
        "nepDate": "02.01.2008"
    },
    {
        "engDate": "1951-04-16",
        "nepDate": "03.01.2008"
    },
    {
        "engDate": "1951-04-17",
        "nepDate": "04.01.2008"
    },
    {
        "engDate": "1951-04-18",
        "nepDate": "05.01.2008"
    },
    {
        "engDate": "1951-04-19",
        "nepDate": "06.01.2008"
    },
    {
        "engDate": "1951-04-20",
        "nepDate": "07.01.2008"
    },
    {
        "engDate": "1951-04-21",
        "nepDate": "08.01.2008"
    },
    {
        "engDate": "1951-04-22",
        "nepDate": "09.01.2008"
    },
    {
        "engDate": "1951-04-23",
        "nepDate": "10.01.2008"
    },
    {
        "engDate": "1951-04-24",
        "nepDate": "11.01.2008"
    },
    {
        "engDate": "1951-04-25",
        "nepDate": "12.01.2008"
    },
    {
        "engDate": "1951-04-26",
        "nepDate": "13.01.2008"
    },
    {
        "engDate": "1951-04-27",
        "nepDate": "14.01.2008"
    },
    {
        "engDate": "1951-04-28",
        "nepDate": "15.01.2008"
    },
    {
        "engDate": "1951-04-29",
        "nepDate": "16.01.2008"
    },
    {
        "engDate": "1951-04-30",
        "nepDate": "17.01.2008"
    },
    {
        "engDate": "1951-05-01",
        "nepDate": "18.01.2008"
    },
    {
        "engDate": "1951-05-02",
        "nepDate": "19.01.2008"
    },
    {
        "engDate": "1951-05-03",
        "nepDate": "20.01.2008"
    },
    {
        "engDate": "1951-05-04",
        "nepDate": "21.01.2008"
    },
    {
        "engDate": "1951-05-05",
        "nepDate": "22.01.2008"
    },
    {
        "engDate": "1951-05-06",
        "nepDate": "23.01.2008"
    },
    {
        "engDate": "1951-05-07",
        "nepDate": "24.01.2008"
    },
    {
        "engDate": "1951-05-08",
        "nepDate": "25.01.2008"
    },
    {
        "engDate": "1951-05-09",
        "nepDate": "26.01.2008"
    },
    {
        "engDate": "1951-05-10",
        "nepDate": "27.01.2008"
    },
    {
        "engDate": "1951-05-11",
        "nepDate": "28.01.2008"
    },
    {
        "engDate": "1951-05-12",
        "nepDate": "29.01.2008"
    },
    {
        "engDate": "1951-05-13",
        "nepDate": "30.01.2008"
    },
    {
        "engDate": "1951-05-14",
        "nepDate": "31.01.2008"
    },
    {
        "engDate": "1951-05-15",
        "nepDate": "01.02.2008"
    },
    {
        "engDate": "1951-05-16",
        "nepDate": "02.02.2008"
    },
    {
        "engDate": "1951-05-17",
        "nepDate": "03.02.2008"
    },
    {
        "engDate": "1951-05-18",
        "nepDate": "04.02.2008"
    },
    {
        "engDate": "1951-05-19",
        "nepDate": "05.02.2008"
    },
    {
        "engDate": "1951-05-20",
        "nepDate": "06.02.2008"
    },
    {
        "engDate": "1951-05-21",
        "nepDate": "07.02.2008"
    },
    {
        "engDate": "1951-05-22",
        "nepDate": "08.02.2008"
    },
    {
        "engDate": "1951-05-23",
        "nepDate": "09.02.2008"
    },
    {
        "engDate": "1951-05-24",
        "nepDate": "10.02.2008"
    },
    {
        "engDate": "1951-05-25",
        "nepDate": "11.02.2008"
    },
    {
        "engDate": "1951-05-26",
        "nepDate": "12.02.2008"
    },
    {
        "engDate": "1951-05-27",
        "nepDate": "13.02.2008"
    },
    {
        "engDate": "1951-05-28",
        "nepDate": "14.02.2008"
    },
    {
        "engDate": "1951-05-29",
        "nepDate": "15.02.2008"
    },
    {
        "engDate": "1951-05-30",
        "nepDate": "16.02.2008"
    },
    {
        "engDate": "1951-05-31",
        "nepDate": "17.02.2008"
    },
    {
        "engDate": "1951-06-01",
        "nepDate": "18.02.2008"
    },
    {
        "engDate": "1951-06-02",
        "nepDate": "19.02.2008"
    },
    {
        "engDate": "1951-06-03",
        "nepDate": "20.02.2008"
    },
    {
        "engDate": "1951-06-04",
        "nepDate": "21.02.2008"
    },
    {
        "engDate": "1951-06-05",
        "nepDate": "22.02.2008"
    },
    {
        "engDate": "1951-06-06",
        "nepDate": "23.02.2008"
    },
    {
        "engDate": "1951-06-07",
        "nepDate": "24.02.2008"
    },
    {
        "engDate": "1951-06-08",
        "nepDate": "25.02.2008"
    },
    {
        "engDate": "1951-06-09",
        "nepDate": "26.02.2008"
    },
    {
        "engDate": "1951-06-10",
        "nepDate": "27.02.2008"
    },
    {
        "engDate": "1951-06-11",
        "nepDate": "28.02.2008"
    },
    {
        "engDate": "1951-06-12",
        "nepDate": "29.02.2008"
    },
    {
        "engDate": "1951-06-13",
        "nepDate": "30.02.2008"
    },
    {
        "engDate": "1951-06-14",
        "nepDate": "31.02.2008"
    },
    {
        "engDate": "1951-06-15",
        "nepDate": "01.03.2008"
    },
    {
        "engDate": "1951-06-16",
        "nepDate": "02.03.2008"
    },
    {
        "engDate": "1951-06-17",
        "nepDate": "03.03.2008"
    },
    {
        "engDate": "1951-06-18",
        "nepDate": "04.03.2008"
    },
    {
        "engDate": "1951-06-19",
        "nepDate": "05.03.2008"
    },
    {
        "engDate": "1951-06-20",
        "nepDate": "06.03.2008"
    },
    {
        "engDate": "1951-06-21",
        "nepDate": "07.03.2008"
    },
    {
        "engDate": "1951-06-22",
        "nepDate": "08.03.2008"
    },
    {
        "engDate": "1951-06-23",
        "nepDate": "09.03.2008"
    },
    {
        "engDate": "1951-06-24",
        "nepDate": "10.03.2008"
    },
    {
        "engDate": "1951-06-25",
        "nepDate": "11.03.2008"
    },
    {
        "engDate": "1951-06-26",
        "nepDate": "12.03.2008"
    },
    {
        "engDate": "1951-06-27",
        "nepDate": "13.03.2008"
    },
    {
        "engDate": "1951-06-28",
        "nepDate": "14.03.2008"
    },
    {
        "engDate": "1951-06-29",
        "nepDate": "15.03.2008"
    },
    {
        "engDate": "1951-06-30",
        "nepDate": "16.03.2008"
    },
    {
        "engDate": "1951-07-01",
        "nepDate": "17.03.2008"
    },
    {
        "engDate": "1951-07-02",
        "nepDate": "18.03.2008"
    },
    {
        "engDate": "1951-07-03",
        "nepDate": "19.03.2008"
    },
    {
        "engDate": "1951-07-04",
        "nepDate": "20.03.2008"
    },
    {
        "engDate": "1951-07-05",
        "nepDate": "21.03.2008"
    },
    {
        "engDate": "1951-07-06",
        "nepDate": "22.03.2008"
    },
    {
        "engDate": "1951-07-07",
        "nepDate": "23.03.2008"
    },
    {
        "engDate": "1951-07-08",
        "nepDate": "24.03.2008"
    },
    {
        "engDate": "1951-07-09",
        "nepDate": "25.03.2008"
    },
    {
        "engDate": "1951-07-10",
        "nepDate": "26.03.2008"
    },
    {
        "engDate": "1951-07-11",
        "nepDate": "27.03.2008"
    },
    {
        "engDate": "1951-07-12",
        "nepDate": "28.03.2008"
    },
    {
        "engDate": "1951-07-13",
        "nepDate": "29.03.2008"
    },
    {
        "engDate": "1951-07-14",
        "nepDate": "30.03.2008"
    },
    {
        "engDate": "1951-07-15",
        "nepDate": "31.03.2008"
    },
    {
        "engDate": "1951-07-16",
        "nepDate": "01.04.2008"
    },
    {
        "engDate": "1951-07-17",
        "nepDate": "02.04.2008"
    },
    {
        "engDate": "1951-07-18",
        "nepDate": "03.04.2008"
    },
    {
        "engDate": "1951-07-19",
        "nepDate": "04.04.2008"
    },
    {
        "engDate": "1951-07-20",
        "nepDate": "05.04.2008"
    },
    {
        "engDate": "1951-07-21",
        "nepDate": "06.04.2008"
    },
    {
        "engDate": "1951-07-22",
        "nepDate": "07.04.2008"
    },
    {
        "engDate": "1951-07-23",
        "nepDate": "08.04.2008"
    },
    {
        "engDate": "1951-07-24",
        "nepDate": "09.04.2008"
    },
    {
        "engDate": "1951-07-25",
        "nepDate": "10.04.2008"
    },
    {
        "engDate": "1951-07-26",
        "nepDate": "11.04.2008"
    },
    {
        "engDate": "1951-07-27",
        "nepDate": "12.04.2008"
    },
    {
        "engDate": "1951-07-28",
        "nepDate": "13.04.2008"
    },
    {
        "engDate": "1951-07-29",
        "nepDate": "14.04.2008"
    },
    {
        "engDate": "1951-07-30",
        "nepDate": "15.04.2008"
    },
    {
        "engDate": "1951-07-31",
        "nepDate": "16.04.2008"
    },
    {
        "engDate": "1951-08-01",
        "nepDate": "17.04.2008"
    },
    {
        "engDate": "1951-08-02",
        "nepDate": "18.04.2008"
    },
    {
        "engDate": "1951-08-03",
        "nepDate": "19.04.2008"
    },
    {
        "engDate": "1951-08-04",
        "nepDate": "20.04.2008"
    },
    {
        "engDate": "1951-08-05",
        "nepDate": "21.04.2008"
    },
    {
        "engDate": "1951-08-06",
        "nepDate": "22.04.2008"
    },
    {
        "engDate": "1951-08-07",
        "nepDate": "23.04.2008"
    },
    {
        "engDate": "1951-08-08",
        "nepDate": "24.04.2008"
    },
    {
        "engDate": "1951-08-09",
        "nepDate": "25.04.2008"
    },
    {
        "engDate": "1951-08-10",
        "nepDate": "26.04.2008"
    },
    {
        "engDate": "1951-08-11",
        "nepDate": "27.04.2008"
    },
    {
        "engDate": "1951-08-12",
        "nepDate": "28.04.2008"
    },
    {
        "engDate": "1951-08-13",
        "nepDate": "29.04.2008"
    },
    {
        "engDate": "1951-08-14",
        "nepDate": "30.04.2008"
    },
    {
        "engDate": "1951-08-15",
        "nepDate": "31.04.2008"
    },
    {
        "engDate": "1951-08-16",
        "nepDate": "32.04.2008"
    },
    {
        "engDate": "1951-08-17",
        "nepDate": "01.05.2008"
    },
    {
        "engDate": "1951-08-18",
        "nepDate": "02.05.2008"
    },
    {
        "engDate": "1951-08-19",
        "nepDate": "03.05.2008"
    },
    {
        "engDate": "1951-08-20",
        "nepDate": "04.05.2008"
    },
    {
        "engDate": "1951-08-21",
        "nepDate": "05.05.2008"
    },
    {
        "engDate": "1951-08-22",
        "nepDate": "06.05.2008"
    },
    {
        "engDate": "1951-08-23",
        "nepDate": "07.05.2008"
    },
    {
        "engDate": "1951-08-24",
        "nepDate": "08.05.2008"
    },
    {
        "engDate": "1951-08-25",
        "nepDate": "09.05.2008"
    },
    {
        "engDate": "1951-08-26",
        "nepDate": "10.05.2008"
    },
    {
        "engDate": "1951-08-27",
        "nepDate": "11.05.2008"
    },
    {
        "engDate": "1951-08-28",
        "nepDate": "12.05.2008"
    },
    {
        "engDate": "1951-08-29",
        "nepDate": "13.05.2008"
    },
    {
        "engDate": "1951-08-30",
        "nepDate": "14.05.2008"
    },
    {
        "engDate": "1951-08-31",
        "nepDate": "15.05.2008"
    },
    {
        "engDate": "1951-09-01",
        "nepDate": "16.05.2008"
    },
    {
        "engDate": "1951-09-02",
        "nepDate": "17.05.2008"
    },
    {
        "engDate": "1951-09-03",
        "nepDate": "18.05.2008"
    },
    {
        "engDate": "1951-09-04",
        "nepDate": "19.05.2008"
    },
    {
        "engDate": "1951-09-05",
        "nepDate": "20.05.2008"
    },
    {
        "engDate": "1951-09-06",
        "nepDate": "21.05.2008"
    },
    {
        "engDate": "1951-09-07",
        "nepDate": "22.05.2008"
    },
    {
        "engDate": "1951-09-08",
        "nepDate": "23.05.2008"
    },
    {
        "engDate": "1951-09-09",
        "nepDate": "24.05.2008"
    },
    {
        "engDate": "1951-09-10",
        "nepDate": "25.05.2008"
    },
    {
        "engDate": "1951-09-11",
        "nepDate": "26.05.2008"
    },
    {
        "engDate": "1951-09-12",
        "nepDate": "27.05.2008"
    },
    {
        "engDate": "1951-09-13",
        "nepDate": "28.05.2008"
    },
    {
        "engDate": "1951-09-14",
        "nepDate": "29.05.2008"
    },
    {
        "engDate": "1951-09-15",
        "nepDate": "30.05.2008"
    },
    {
        "engDate": "1951-09-16",
        "nepDate": "31.05.2008"
    },
    {
        "engDate": "1951-09-17",
        "nepDate": "01.06.2008"
    },
    {
        "engDate": "1951-09-18",
        "nepDate": "02.06.2008"
    },
    {
        "engDate": "1951-09-19",
        "nepDate": "03.06.2008"
    },
    {
        "engDate": "1951-09-20",
        "nepDate": "04.06.2008"
    },
    {
        "engDate": "1951-09-21",
        "nepDate": "05.06.2008"
    },
    {
        "engDate": "1951-09-22",
        "nepDate": "06.06.2008"
    },
    {
        "engDate": "1951-09-23",
        "nepDate": "07.06.2008"
    },
    {
        "engDate": "1951-09-24",
        "nepDate": "08.06.2008"
    },
    {
        "engDate": "1951-09-25",
        "nepDate": "09.06.2008"
    },
    {
        "engDate": "1951-09-26",
        "nepDate": "10.06.2008"
    },
    {
        "engDate": "1951-09-27",
        "nepDate": "11.06.2008"
    },
    {
        "engDate": "1951-09-28",
        "nepDate": "12.06.2008"
    },
    {
        "engDate": "1951-09-29",
        "nepDate": "13.06.2008"
    },
    {
        "engDate": "1951-09-30",
        "nepDate": "14.06.2008"
    },
    {
        "engDate": "1951-10-01",
        "nepDate": "15.06.2008"
    },
    {
        "engDate": "1951-10-02",
        "nepDate": "16.06.2008"
    },
    {
        "engDate": "1951-10-03",
        "nepDate": "17.06.2008"
    },
    {
        "engDate": "1951-10-04",
        "nepDate": "18.06.2008"
    },
    {
        "engDate": "1951-10-05",
        "nepDate": "19.06.2008"
    },
    {
        "engDate": "1951-10-06",
        "nepDate": "20.06.2008"
    },
    {
        "engDate": "1951-10-07",
        "nepDate": "21.06.2008"
    },
    {
        "engDate": "1951-10-08",
        "nepDate": "22.06.2008"
    },
    {
        "engDate": "1951-10-09",
        "nepDate": "23.06.2008"
    },
    {
        "engDate": "1951-10-10",
        "nepDate": "24.06.2008"
    },
    {
        "engDate": "1951-10-11",
        "nepDate": "25.06.2008"
    },
    {
        "engDate": "1951-10-12",
        "nepDate": "26.06.2008"
    },
    {
        "engDate": "1951-10-13",
        "nepDate": "27.06.2008"
    },
    {
        "engDate": "1951-10-14",
        "nepDate": "28.06.2008"
    },
    {
        "engDate": "1951-10-15",
        "nepDate": "29.06.2008"
    },
    {
        "engDate": "1951-10-16",
        "nepDate": "30.06.2008"
    },
    {
        "engDate": "1951-10-17",
        "nepDate": "31.06.2008"
    },
    {
        "engDate": "1951-10-18",
        "nepDate": "01.07.2008"
    },
    {
        "engDate": "1951-10-19",
        "nepDate": "02.07.2008"
    },
    {
        "engDate": "1951-10-20",
        "nepDate": "03.07.2008"
    },
    {
        "engDate": "1951-10-21",
        "nepDate": "04.07.2008"
    },
    {
        "engDate": "1951-10-22",
        "nepDate": "05.07.2008"
    },
    {
        "engDate": "1951-10-23",
        "nepDate": "06.07.2008"
    },
    {
        "engDate": "1951-10-24",
        "nepDate": "07.07.2008"
    },
    {
        "engDate": "1951-10-25",
        "nepDate": "08.07.2008"
    },
    {
        "engDate": "1951-10-26",
        "nepDate": "09.07.2008"
    },
    {
        "engDate": "1951-10-27",
        "nepDate": "10.07.2008"
    },
    {
        "engDate": "1951-10-28",
        "nepDate": "11.07.2008"
    },
    {
        "engDate": "1951-10-29",
        "nepDate": "12.07.2008"
    },
    {
        "engDate": "1951-10-30",
        "nepDate": "13.07.2008"
    },
    {
        "engDate": "1951-10-31",
        "nepDate": "14.07.2008"
    },
    {
        "engDate": "1951-11-01",
        "nepDate": "15.07.2008"
    },
    {
        "engDate": "1951-11-02",
        "nepDate": "16.07.2008"
    },
    {
        "engDate": "1951-11-03",
        "nepDate": "17.07.2008"
    },
    {
        "engDate": "1951-11-04",
        "nepDate": "18.07.2008"
    },
    {
        "engDate": "1951-11-05",
        "nepDate": "19.07.2008"
    },
    {
        "engDate": "1951-11-06",
        "nepDate": "20.07.2008"
    },
    {
        "engDate": "1951-11-07",
        "nepDate": "21.07.2008"
    },
    {
        "engDate": "1951-11-08",
        "nepDate": "22.07.2008"
    },
    {
        "engDate": "1951-11-09",
        "nepDate": "23.07.2008"
    },
    {
        "engDate": "1951-11-10",
        "nepDate": "24.07.2008"
    },
    {
        "engDate": "1951-11-11",
        "nepDate": "25.07.2008"
    },
    {
        "engDate": "1951-11-12",
        "nepDate": "26.07.2008"
    },
    {
        "engDate": "1951-11-13",
        "nepDate": "27.07.2008"
    },
    {
        "engDate": "1951-11-14",
        "nepDate": "28.07.2008"
    },
    {
        "engDate": "1951-11-15",
        "nepDate": "29.07.2008"
    },
    {
        "engDate": "1951-11-16",
        "nepDate": "01.08.2008"
    },
    {
        "engDate": "1951-11-17",
        "nepDate": "02.08.2008"
    },
    {
        "engDate": "1951-11-18",
        "nepDate": "03.08.2008"
    },
    {
        "engDate": "1951-11-19",
        "nepDate": "04.08.2008"
    },
    {
        "engDate": "1951-11-20",
        "nepDate": "05.08.2008"
    },
    {
        "engDate": "1951-11-21",
        "nepDate": "06.08.2008"
    },
    {
        "engDate": "1951-11-22",
        "nepDate": "07.08.2008"
    },
    {
        "engDate": "1951-11-23",
        "nepDate": "08.08.2008"
    },
    {
        "engDate": "1951-11-24",
        "nepDate": "09.08.2008"
    },
    {
        "engDate": "1951-11-25",
        "nepDate": "10.08.2008"
    },
    {
        "engDate": "1951-11-26",
        "nepDate": "11.08.2008"
    },
    {
        "engDate": "1951-11-27",
        "nepDate": "12.08.2008"
    },
    {
        "engDate": "1951-11-28",
        "nepDate": "13.08.2008"
    },
    {
        "engDate": "1951-11-29",
        "nepDate": "14.08.2008"
    },
    {
        "engDate": "1951-11-30",
        "nepDate": "15.08.2008"
    },
    {
        "engDate": "1951-12-01",
        "nepDate": "16.08.2008"
    },
    {
        "engDate": "1951-12-02",
        "nepDate": "17.08.2008"
    },
    {
        "engDate": "1951-12-03",
        "nepDate": "18.08.2008"
    },
    {
        "engDate": "1951-12-04",
        "nepDate": "19.08.2008"
    },
    {
        "engDate": "1951-12-05",
        "nepDate": "20.08.2008"
    },
    {
        "engDate": "1951-12-06",
        "nepDate": "21.08.2008"
    },
    {
        "engDate": "1951-12-07",
        "nepDate": "22.08.2008"
    },
    {
        "engDate": "1951-12-08",
        "nepDate": "23.08.2008"
    },
    {
        "engDate": "1951-12-09",
        "nepDate": "24.08.2008"
    },
    {
        "engDate": "1951-12-10",
        "nepDate": "25.08.2008"
    },
    {
        "engDate": "1951-12-11",
        "nepDate": "26.08.2008"
    },
    {
        "engDate": "1951-12-12",
        "nepDate": "27.08.2008"
    },
    {
        "engDate": "1951-12-13",
        "nepDate": "28.08.2008"
    },
    {
        "engDate": "1951-12-14",
        "nepDate": "29.08.2008"
    },
    {
        "engDate": "1951-12-15",
        "nepDate": "30.08.2008"
    },
    {
        "engDate": "1951-12-16",
        "nepDate": "01.09.2008"
    },
    {
        "engDate": "1951-12-17",
        "nepDate": "02.09.2008"
    },
    {
        "engDate": "1951-12-18",
        "nepDate": "03.09.2008"
    },
    {
        "engDate": "1951-12-19",
        "nepDate": "04.09.2008"
    },
    {
        "engDate": "1951-12-20",
        "nepDate": "05.09.2008"
    },
    {
        "engDate": "1951-12-21",
        "nepDate": "06.09.2008"
    },
    {
        "engDate": "1951-12-22",
        "nepDate": "07.09.2008"
    },
    {
        "engDate": "1951-12-23",
        "nepDate": "08.09.2008"
    },
    {
        "engDate": "1951-12-24",
        "nepDate": "09.09.2008"
    },
    {
        "engDate": "1951-12-25",
        "nepDate": "10.09.2008"
    },
    {
        "engDate": "1951-12-26",
        "nepDate": "11.09.2008"
    },
    {
        "engDate": "1951-12-27",
        "nepDate": "12.09.2008"
    },
    {
        "engDate": "1951-12-28",
        "nepDate": "13.09.2008"
    },
    {
        "engDate": "1951-12-29",
        "nepDate": "14.09.2008"
    },
    {
        "engDate": "1951-12-30",
        "nepDate": "15.09.2008"
    },
    {
        "engDate": "1951-12-31",
        "nepDate": "16.09.2008"
    },
    {
        "engDate": "1952-01-01",
        "nepDate": "17.09.2008"
    },
    {
        "engDate": "1952-01-02",
        "nepDate": "18.09.2008"
    },
    {
        "engDate": "1952-01-03",
        "nepDate": "19.09.2008"
    },
    {
        "engDate": "1952-01-04",
        "nepDate": "20.09.2008"
    },
    {
        "engDate": "1952-01-05",
        "nepDate": "21.09.2008"
    },
    {
        "engDate": "1952-01-06",
        "nepDate": "22.09.2008"
    },
    {
        "engDate": "1952-01-07",
        "nepDate": "23.09.2008"
    },
    {
        "engDate": "1952-01-08",
        "nepDate": "24.09.2008"
    },
    {
        "engDate": "1952-01-09",
        "nepDate": "25.09.2008"
    },
    {
        "engDate": "1952-01-10",
        "nepDate": "26.09.2008"
    },
    {
        "engDate": "1952-01-11",
        "nepDate": "27.09.2008"
    },
    {
        "engDate": "1952-01-12",
        "nepDate": "28.09.2008"
    },
    {
        "engDate": "1952-01-13",
        "nepDate": "29.09.2008"
    },
    {
        "engDate": "1952-01-14",
        "nepDate": "30.09.2008"
    },
    {
        "engDate": "1952-01-15",
        "nepDate": "01.10.2008"
    },
    {
        "engDate": "1952-01-16",
        "nepDate": "02.10.2008"
    },
    {
        "engDate": "1952-01-17",
        "nepDate": "03.10.2008"
    },
    {
        "engDate": "1952-01-18",
        "nepDate": "04.10.2008"
    },
    {
        "engDate": "1952-01-19",
        "nepDate": "05.10.2008"
    },
    {
        "engDate": "1952-01-20",
        "nepDate": "06.10.2008"
    },
    {
        "engDate": "1952-01-21",
        "nepDate": "07.10.2008"
    },
    {
        "engDate": "1952-01-22",
        "nepDate": "08.10.2008"
    },
    {
        "engDate": "1952-01-23",
        "nepDate": "09.10.2008"
    },
    {
        "engDate": "1952-01-24",
        "nepDate": "10.10.2008"
    },
    {
        "engDate": "1952-01-25",
        "nepDate": "11.10.2008"
    },
    {
        "engDate": "1952-01-26",
        "nepDate": "12.10.2008"
    },
    {
        "engDate": "1952-01-27",
        "nepDate": "13.10.2008"
    },
    {
        "engDate": "1952-01-28",
        "nepDate": "14.10.2008"
    },
    {
        "engDate": "1952-01-29",
        "nepDate": "15.10.2008"
    },
    {
        "engDate": "1952-01-30",
        "nepDate": "16.10.2008"
    },
    {
        "engDate": "1952-01-31",
        "nepDate": "17.10.2008"
    },
    {
        "engDate": "1952-02-01",
        "nepDate": "18.10.2008"
    },
    {
        "engDate": "1952-02-02",
        "nepDate": "19.10.2008"
    },
    {
        "engDate": "1952-02-03",
        "nepDate": "20.10.2008"
    },
    {
        "engDate": "1952-02-04",
        "nepDate": "21.10.2008"
    },
    {
        "engDate": "1952-02-05",
        "nepDate": "22.10.2008"
    },
    {
        "engDate": "1952-02-06",
        "nepDate": "23.10.2008"
    },
    {
        "engDate": "1952-02-07",
        "nepDate": "24.10.2008"
    },
    {
        "engDate": "1952-02-08",
        "nepDate": "25.10.2008"
    },
    {
        "engDate": "1952-02-09",
        "nepDate": "26.10.2008"
    },
    {
        "engDate": "1952-02-10",
        "nepDate": "27.10.2008"
    },
    {
        "engDate": "1952-02-11",
        "nepDate": "28.10.2008"
    },
    {
        "engDate": "1952-02-12",
        "nepDate": "29.10.2008"
    },
    {
        "engDate": "1952-02-13",
        "nepDate": "01.11.2008"
    },
    {
        "engDate": "1952-02-14",
        "nepDate": "02.11.2008"
    },
    {
        "engDate": "1952-02-15",
        "nepDate": "03.11.2008"
    },
    {
        "engDate": "1952-02-16",
        "nepDate": "04.11.2008"
    },
    {
        "engDate": "1952-02-17",
        "nepDate": "05.11.2008"
    },
    {
        "engDate": "1952-02-18",
        "nepDate": "06.11.2008"
    },
    {
        "engDate": "1952-02-19",
        "nepDate": "07.11.2008"
    },
    {
        "engDate": "1952-02-20",
        "nepDate": "08.11.2008"
    },
    {
        "engDate": "1952-02-21",
        "nepDate": "09.11.2008"
    },
    {
        "engDate": "1952-02-22",
        "nepDate": "10.11.2008"
    },
    {
        "engDate": "1952-02-23",
        "nepDate": "11.11.2008"
    },
    {
        "engDate": "1952-02-24",
        "nepDate": "12.11.2008"
    },
    {
        "engDate": "1952-02-25",
        "nepDate": "13.11.2008"
    },
    {
        "engDate": "1952-02-26",
        "nepDate": "14.11.2008"
    },
    {
        "engDate": "1952-02-27",
        "nepDate": "15.11.2008"
    },
    {
        "engDate": "1952-02-28",
        "nepDate": "16.11.2008"
    },
    {
        "engDate": "1952-02-29",
        "nepDate": "17.11.2008"
    },
    {
        "engDate": "1952-03-01",
        "nepDate": "18.11.2008"
    },
    {
        "engDate": "1952-03-02",
        "nepDate": "19.11.2008"
    },
    {
        "engDate": "1952-03-03",
        "nepDate": "20.11.2008"
    },
    {
        "engDate": "1952-03-04",
        "nepDate": "21.11.2008"
    },
    {
        "engDate": "1952-03-05",
        "nepDate": "22.11.2008"
    },
    {
        "engDate": "1952-03-06",
        "nepDate": "23.11.2008"
    },
    {
        "engDate": "1952-03-07",
        "nepDate": "24.11.2008"
    },
    {
        "engDate": "1952-03-08",
        "nepDate": "25.11.2008"
    },
    {
        "engDate": "1952-03-09",
        "nepDate": "26.11.2008"
    },
    {
        "engDate": "1952-03-10",
        "nepDate": "27.11.2008"
    },
    {
        "engDate": "1952-03-11",
        "nepDate": "28.11.2008"
    },
    {
        "engDate": "1952-03-12",
        "nepDate": "29.11.2008"
    },
    {
        "engDate": "1952-03-13",
        "nepDate": "01.12.2008"
    },
    {
        "engDate": "1952-03-14",
        "nepDate": "02.12.2008"
    },
    {
        "engDate": "1952-03-15",
        "nepDate": "03.12.2008"
    },
    {
        "engDate": "1952-03-16",
        "nepDate": "04.12.2008"
    },
    {
        "engDate": "1952-03-17",
        "nepDate": "05.12.2008"
    },
    {
        "engDate": "1952-03-18",
        "nepDate": "06.12.2008"
    },
    {
        "engDate": "1952-03-19",
        "nepDate": "07.12.2008"
    },
    {
        "engDate": "1952-03-20",
        "nepDate": "08.12.2008"
    },
    {
        "engDate": "1952-03-21",
        "nepDate": "09.12.2008"
    },
    {
        "engDate": "1952-03-22",
        "nepDate": "10.12.2008"
    },
    {
        "engDate": "1952-03-23",
        "nepDate": "11.12.2008"
    },
    {
        "engDate": "1952-03-24",
        "nepDate": "12.12.2008"
    },
    {
        "engDate": "1952-03-25",
        "nepDate": "13.12.2008"
    },
    {
        "engDate": "1952-03-26",
        "nepDate": "14.12.2008"
    },
    {
        "engDate": "1952-03-27",
        "nepDate": "15.12.2008"
    },
    {
        "engDate": "1952-03-28",
        "nepDate": "16.12.2008"
    },
    {
        "engDate": "1952-03-29",
        "nepDate": "17.12.2008"
    },
    {
        "engDate": "1952-03-30",
        "nepDate": "18.12.2008"
    },
    {
        "engDate": "1952-03-31",
        "nepDate": "19.12.2008"
    },
    {
        "engDate": "1952-04-01",
        "nepDate": "20.12.2008"
    },
    {
        "engDate": "1952-04-02",
        "nepDate": "21.12.2008"
    },
    {
        "engDate": "1952-04-03",
        "nepDate": "22.12.2008"
    },
    {
        "engDate": "1952-04-04",
        "nepDate": "23.12.2008"
    },
    {
        "engDate": "1952-04-05",
        "nepDate": "24.12.2008"
    },
    {
        "engDate": "1952-04-06",
        "nepDate": "25.12.2008"
    },
    {
        "engDate": "1952-04-07",
        "nepDate": "26.12.2008"
    },
    {
        "engDate": "1952-04-08",
        "nepDate": "27.12.2008"
    },
    {
        "engDate": "1952-04-09",
        "nepDate": "28.12.2008"
    },
    {
        "engDate": "1952-04-10",
        "nepDate": "29.12.2008"
    },
    {
        "engDate": "1952-04-11",
        "nepDate": "30.12.2008"
    },
    {
        "engDate": "1952-04-12",
        "nepDate": "31.12.2008"
    },
    {
        "engDate": "1952-04-13",
        "nepDate": "01.01.2009"
    },
    {
        "engDate": "1952-04-14",
        "nepDate": "02.01.2009"
    },
    {
        "engDate": "1952-04-15",
        "nepDate": "03.01.2009"
    },
    {
        "engDate": "1952-04-16",
        "nepDate": "04.01.2009"
    },
    {
        "engDate": "1952-04-17",
        "nepDate": "05.01.2009"
    },
    {
        "engDate": "1952-04-18",
        "nepDate": "06.01.2009"
    },
    {
        "engDate": "1952-04-19",
        "nepDate": "07.01.2009"
    },
    {
        "engDate": "1952-04-20",
        "nepDate": "08.01.2009"
    },
    {
        "engDate": "1952-04-21",
        "nepDate": "09.01.2009"
    },
    {
        "engDate": "1952-04-22",
        "nepDate": "10.01.2009"
    },
    {
        "engDate": "1952-04-23",
        "nepDate": "11.01.2009"
    },
    {
        "engDate": "1952-04-24",
        "nepDate": "12.01.2009"
    },
    {
        "engDate": "1952-04-25",
        "nepDate": "13.01.2009"
    },
    {
        "engDate": "1952-04-26",
        "nepDate": "14.01.2009"
    },
    {
        "engDate": "1952-04-27",
        "nepDate": "15.01.2009"
    },
    {
        "engDate": "1952-04-28",
        "nepDate": "16.01.2009"
    },
    {
        "engDate": "1952-04-29",
        "nepDate": "17.01.2009"
    },
    {
        "engDate": "1952-04-30",
        "nepDate": "18.01.2009"
    },
    {
        "engDate": "1952-05-01",
        "nepDate": "19.01.2009"
    },
    {
        "engDate": "1952-05-02",
        "nepDate": "20.01.2009"
    },
    {
        "engDate": "1952-05-03",
        "nepDate": "21.01.2009"
    },
    {
        "engDate": "1952-05-04",
        "nepDate": "22.01.2009"
    },
    {
        "engDate": "1952-05-05",
        "nepDate": "23.01.2009"
    },
    {
        "engDate": "1952-05-06",
        "nepDate": "24.01.2009"
    },
    {
        "engDate": "1952-05-07",
        "nepDate": "25.01.2009"
    },
    {
        "engDate": "1952-05-08",
        "nepDate": "26.01.2009"
    },
    {
        "engDate": "1952-05-09",
        "nepDate": "27.01.2009"
    },
    {
        "engDate": "1952-05-10",
        "nepDate": "28.01.2009"
    },
    {
        "engDate": "1952-05-11",
        "nepDate": "29.01.2009"
    },
    {
        "engDate": "1952-05-12",
        "nepDate": "30.01.2009"
    },
    {
        "engDate": "1952-05-13",
        "nepDate": "31.01.2009"
    },
    {
        "engDate": "1952-05-14",
        "nepDate": "01.02.2009"
    },
    {
        "engDate": "1952-05-15",
        "nepDate": "02.02.2009"
    },
    {
        "engDate": "1952-05-16",
        "nepDate": "03.02.2009"
    },
    {
        "engDate": "1952-05-17",
        "nepDate": "04.02.2009"
    },
    {
        "engDate": "1952-05-18",
        "nepDate": "05.02.2009"
    },
    {
        "engDate": "1952-05-19",
        "nepDate": "06.02.2009"
    },
    {
        "engDate": "1952-05-20",
        "nepDate": "07.02.2009"
    },
    {
        "engDate": "1952-05-21",
        "nepDate": "08.02.2009"
    },
    {
        "engDate": "1952-05-22",
        "nepDate": "09.02.2009"
    },
    {
        "engDate": "1952-05-23",
        "nepDate": "10.02.2009"
    },
    {
        "engDate": "1952-05-24",
        "nepDate": "11.02.2009"
    },
    {
        "engDate": "1952-05-25",
        "nepDate": "12.02.2009"
    },
    {
        "engDate": "1952-05-26",
        "nepDate": "13.02.2009"
    },
    {
        "engDate": "1952-05-27",
        "nepDate": "14.02.2009"
    },
    {
        "engDate": "1952-05-28",
        "nepDate": "15.02.2009"
    },
    {
        "engDate": "1952-05-29",
        "nepDate": "16.02.2009"
    },
    {
        "engDate": "1952-05-30",
        "nepDate": "17.02.2009"
    },
    {
        "engDate": "1952-05-31",
        "nepDate": "18.02.2009"
    },
    {
        "engDate": "1952-06-01",
        "nepDate": "19.02.2009"
    },
    {
        "engDate": "1952-06-02",
        "nepDate": "20.02.2009"
    },
    {
        "engDate": "1952-06-03",
        "nepDate": "21.02.2009"
    },
    {
        "engDate": "1952-06-04",
        "nepDate": "22.02.2009"
    },
    {
        "engDate": "1952-06-05",
        "nepDate": "23.02.2009"
    },
    {
        "engDate": "1952-06-06",
        "nepDate": "24.02.2009"
    },
    {
        "engDate": "1952-06-07",
        "nepDate": "25.02.2009"
    },
    {
        "engDate": "1952-06-08",
        "nepDate": "26.02.2009"
    },
    {
        "engDate": "1952-06-09",
        "nepDate": "27.02.2009"
    },
    {
        "engDate": "1952-06-10",
        "nepDate": "28.02.2009"
    },
    {
        "engDate": "1952-06-11",
        "nepDate": "29.02.2009"
    },
    {
        "engDate": "1952-06-12",
        "nepDate": "30.02.2009"
    },
    {
        "engDate": "1952-06-13",
        "nepDate": "31.02.2009"
    },
    {
        "engDate": "1952-06-14",
        "nepDate": "01.03.2009"
    },
    {
        "engDate": "1952-06-15",
        "nepDate": "02.03.2009"
    },
    {
        "engDate": "1952-06-16",
        "nepDate": "03.03.2009"
    },
    {
        "engDate": "1952-06-17",
        "nepDate": "04.03.2009"
    },
    {
        "engDate": "1952-06-18",
        "nepDate": "05.03.2009"
    },
    {
        "engDate": "1952-06-19",
        "nepDate": "06.03.2009"
    },
    {
        "engDate": "1952-06-20",
        "nepDate": "07.03.2009"
    },
    {
        "engDate": "1952-06-21",
        "nepDate": "08.03.2009"
    },
    {
        "engDate": "1952-06-22",
        "nepDate": "09.03.2009"
    },
    {
        "engDate": "1952-06-23",
        "nepDate": "10.03.2009"
    },
    {
        "engDate": "1952-06-24",
        "nepDate": "11.03.2009"
    },
    {
        "engDate": "1952-06-25",
        "nepDate": "12.03.2009"
    },
    {
        "engDate": "1952-06-26",
        "nepDate": "13.03.2009"
    },
    {
        "engDate": "1952-06-27",
        "nepDate": "14.03.2009"
    },
    {
        "engDate": "1952-06-28",
        "nepDate": "15.03.2009"
    },
    {
        "engDate": "1952-06-29",
        "nepDate": "16.03.2009"
    },
    {
        "engDate": "1952-06-30",
        "nepDate": "17.03.2009"
    },
    {
        "engDate": "1952-07-01",
        "nepDate": "18.03.2009"
    },
    {
        "engDate": "1952-07-02",
        "nepDate": "19.03.2009"
    },
    {
        "engDate": "1952-07-03",
        "nepDate": "20.03.2009"
    },
    {
        "engDate": "1952-07-04",
        "nepDate": "21.03.2009"
    },
    {
        "engDate": "1952-07-05",
        "nepDate": "22.03.2009"
    },
    {
        "engDate": "1952-07-06",
        "nepDate": "23.03.2009"
    },
    {
        "engDate": "1952-07-07",
        "nepDate": "24.03.2009"
    },
    {
        "engDate": "1952-07-08",
        "nepDate": "25.03.2009"
    },
    {
        "engDate": "1952-07-09",
        "nepDate": "26.03.2009"
    },
    {
        "engDate": "1952-07-10",
        "nepDate": "27.03.2009"
    },
    {
        "engDate": "1952-07-11",
        "nepDate": "28.03.2009"
    },
    {
        "engDate": "1952-07-12",
        "nepDate": "29.03.2009"
    },
    {
        "engDate": "1952-07-13",
        "nepDate": "30.03.2009"
    },
    {
        "engDate": "1952-07-14",
        "nepDate": "31.03.2009"
    },
    {
        "engDate": "1952-07-15",
        "nepDate": "32.03.2009"
    },
    {
        "engDate": "1952-07-16",
        "nepDate": "01.04.2009"
    },
    {
        "engDate": "1952-07-17",
        "nepDate": "02.04.2009"
    },
    {
        "engDate": "1952-07-18",
        "nepDate": "03.04.2009"
    },
    {
        "engDate": "1952-07-19",
        "nepDate": "04.04.2009"
    },
    {
        "engDate": "1952-07-20",
        "nepDate": "05.04.2009"
    },
    {
        "engDate": "1952-07-21",
        "nepDate": "06.04.2009"
    },
    {
        "engDate": "1952-07-22",
        "nepDate": "07.04.2009"
    },
    {
        "engDate": "1952-07-23",
        "nepDate": "08.04.2009"
    },
    {
        "engDate": "1952-07-24",
        "nepDate": "09.04.2009"
    },
    {
        "engDate": "1952-07-25",
        "nepDate": "10.04.2009"
    },
    {
        "engDate": "1952-07-26",
        "nepDate": "11.04.2009"
    },
    {
        "engDate": "1952-07-27",
        "nepDate": "12.04.2009"
    },
    {
        "engDate": "1952-07-28",
        "nepDate": "13.04.2009"
    },
    {
        "engDate": "1952-07-29",
        "nepDate": "14.04.2009"
    },
    {
        "engDate": "1952-07-30",
        "nepDate": "15.04.2009"
    },
    {
        "engDate": "1952-07-31",
        "nepDate": "16.04.2009"
    },
    {
        "engDate": "1952-08-01",
        "nepDate": "17.04.2009"
    },
    {
        "engDate": "1952-08-02",
        "nepDate": "18.04.2009"
    },
    {
        "engDate": "1952-08-03",
        "nepDate": "19.04.2009"
    },
    {
        "engDate": "1952-08-04",
        "nepDate": "20.04.2009"
    },
    {
        "engDate": "1952-08-05",
        "nepDate": "21.04.2009"
    },
    {
        "engDate": "1952-08-06",
        "nepDate": "22.04.2009"
    },
    {
        "engDate": "1952-08-07",
        "nepDate": "23.04.2009"
    },
    {
        "engDate": "1952-08-08",
        "nepDate": "24.04.2009"
    },
    {
        "engDate": "1952-08-09",
        "nepDate": "25.04.2009"
    },
    {
        "engDate": "1952-08-10",
        "nepDate": "26.04.2009"
    },
    {
        "engDate": "1952-08-11",
        "nepDate": "27.04.2009"
    },
    {
        "engDate": "1952-08-12",
        "nepDate": "28.04.2009"
    },
    {
        "engDate": "1952-08-13",
        "nepDate": "29.04.2009"
    },
    {
        "engDate": "1952-08-14",
        "nepDate": "30.04.2009"
    },
    {
        "engDate": "1952-08-15",
        "nepDate": "31.04.2009"
    },
    {
        "engDate": "1952-08-16",
        "nepDate": "01.05.2009"
    },
    {
        "engDate": "1952-08-17",
        "nepDate": "02.05.2009"
    },
    {
        "engDate": "1952-08-18",
        "nepDate": "03.05.2009"
    },
    {
        "engDate": "1952-08-19",
        "nepDate": "04.05.2009"
    },
    {
        "engDate": "1952-08-20",
        "nepDate": "05.05.2009"
    },
    {
        "engDate": "1952-08-21",
        "nepDate": "06.05.2009"
    },
    {
        "engDate": "1952-08-22",
        "nepDate": "07.05.2009"
    },
    {
        "engDate": "1952-08-23",
        "nepDate": "08.05.2009"
    },
    {
        "engDate": "1952-08-24",
        "nepDate": "09.05.2009"
    },
    {
        "engDate": "1952-08-25",
        "nepDate": "10.05.2009"
    },
    {
        "engDate": "1952-08-26",
        "nepDate": "11.05.2009"
    },
    {
        "engDate": "1952-08-27",
        "nepDate": "12.05.2009"
    },
    {
        "engDate": "1952-08-28",
        "nepDate": "13.05.2009"
    },
    {
        "engDate": "1952-08-29",
        "nepDate": "14.05.2009"
    },
    {
        "engDate": "1952-08-30",
        "nepDate": "15.05.2009"
    },
    {
        "engDate": "1952-08-31",
        "nepDate": "16.05.2009"
    },
    {
        "engDate": "1952-09-01",
        "nepDate": "17.05.2009"
    },
    {
        "engDate": "1952-09-02",
        "nepDate": "18.05.2009"
    },
    {
        "engDate": "1952-09-03",
        "nepDate": "19.05.2009"
    },
    {
        "engDate": "1952-09-04",
        "nepDate": "20.05.2009"
    },
    {
        "engDate": "1952-09-05",
        "nepDate": "21.05.2009"
    },
    {
        "engDate": "1952-09-06",
        "nepDate": "22.05.2009"
    },
    {
        "engDate": "1952-09-07",
        "nepDate": "23.05.2009"
    },
    {
        "engDate": "1952-09-08",
        "nepDate": "24.05.2009"
    },
    {
        "engDate": "1952-09-09",
        "nepDate": "25.05.2009"
    },
    {
        "engDate": "1952-09-10",
        "nepDate": "26.05.2009"
    },
    {
        "engDate": "1952-09-11",
        "nepDate": "27.05.2009"
    },
    {
        "engDate": "1952-09-12",
        "nepDate": "28.05.2009"
    },
    {
        "engDate": "1952-09-13",
        "nepDate": "29.05.2009"
    },
    {
        "engDate": "1952-09-14",
        "nepDate": "30.05.2009"
    },
    {
        "engDate": "1952-09-15",
        "nepDate": "31.05.2009"
    },
    {
        "engDate": "1952-09-16",
        "nepDate": "01.06.2009"
    },
    {
        "engDate": "1952-09-17",
        "nepDate": "02.06.2009"
    },
    {
        "engDate": "1952-09-18",
        "nepDate": "03.06.2009"
    },
    {
        "engDate": "1952-09-19",
        "nepDate": "04.06.2009"
    },
    {
        "engDate": "1952-09-20",
        "nepDate": "05.06.2009"
    },
    {
        "engDate": "1952-09-21",
        "nepDate": "06.06.2009"
    },
    {
        "engDate": "1952-09-22",
        "nepDate": "07.06.2009"
    },
    {
        "engDate": "1952-09-23",
        "nepDate": "08.06.2009"
    },
    {
        "engDate": "1952-09-24",
        "nepDate": "09.06.2009"
    },
    {
        "engDate": "1952-09-25",
        "nepDate": "10.06.2009"
    },
    {
        "engDate": "1952-09-26",
        "nepDate": "11.06.2009"
    },
    {
        "engDate": "1952-09-27",
        "nepDate": "12.06.2009"
    },
    {
        "engDate": "1952-09-28",
        "nepDate": "13.06.2009"
    },
    {
        "engDate": "1952-09-29",
        "nepDate": "14.06.2009"
    },
    {
        "engDate": "1952-09-30",
        "nepDate": "15.06.2009"
    },
    {
        "engDate": "1952-10-01",
        "nepDate": "16.06.2009"
    },
    {
        "engDate": "1952-10-02",
        "nepDate": "17.06.2009"
    },
    {
        "engDate": "1952-10-03",
        "nepDate": "18.06.2009"
    },
    {
        "engDate": "1952-10-04",
        "nepDate": "19.06.2009"
    },
    {
        "engDate": "1952-10-05",
        "nepDate": "20.06.2009"
    },
    {
        "engDate": "1952-10-06",
        "nepDate": "21.06.2009"
    },
    {
        "engDate": "1952-10-07",
        "nepDate": "22.06.2009"
    },
    {
        "engDate": "1952-10-08",
        "nepDate": "23.06.2009"
    },
    {
        "engDate": "1952-10-09",
        "nepDate": "24.06.2009"
    },
    {
        "engDate": "1952-10-10",
        "nepDate": "25.06.2009"
    },
    {
        "engDate": "1952-10-11",
        "nepDate": "26.06.2009"
    },
    {
        "engDate": "1952-10-12",
        "nepDate": "27.06.2009"
    },
    {
        "engDate": "1952-10-13",
        "nepDate": "28.06.2009"
    },
    {
        "engDate": "1952-10-14",
        "nepDate": "29.06.2009"
    },
    {
        "engDate": "1952-10-15",
        "nepDate": "30.06.2009"
    },
    {
        "engDate": "1952-10-16",
        "nepDate": "31.06.2009"
    },
    {
        "engDate": "1952-10-17",
        "nepDate": "01.07.2009"
    },
    {
        "engDate": "1952-10-18",
        "nepDate": "02.07.2009"
    },
    {
        "engDate": "1952-10-19",
        "nepDate": "03.07.2009"
    },
    {
        "engDate": "1952-10-20",
        "nepDate": "04.07.2009"
    },
    {
        "engDate": "1952-10-21",
        "nepDate": "05.07.2009"
    },
    {
        "engDate": "1952-10-22",
        "nepDate": "06.07.2009"
    },
    {
        "engDate": "1952-10-23",
        "nepDate": "07.07.2009"
    },
    {
        "engDate": "1952-10-24",
        "nepDate": "08.07.2009"
    },
    {
        "engDate": "1952-10-25",
        "nepDate": "09.07.2009"
    },
    {
        "engDate": "1952-10-26",
        "nepDate": "10.07.2009"
    },
    {
        "engDate": "1952-10-27",
        "nepDate": "11.07.2009"
    },
    {
        "engDate": "1952-10-28",
        "nepDate": "12.07.2009"
    },
    {
        "engDate": "1952-10-29",
        "nepDate": "13.07.2009"
    },
    {
        "engDate": "1952-10-30",
        "nepDate": "14.07.2009"
    },
    {
        "engDate": "1952-10-31",
        "nepDate": "15.07.2009"
    },
    {
        "engDate": "1952-11-01",
        "nepDate": "16.07.2009"
    },
    {
        "engDate": "1952-11-02",
        "nepDate": "17.07.2009"
    },
    {
        "engDate": "1952-11-03",
        "nepDate": "18.07.2009"
    },
    {
        "engDate": "1952-11-04",
        "nepDate": "19.07.2009"
    },
    {
        "engDate": "1952-11-05",
        "nepDate": "20.07.2009"
    },
    {
        "engDate": "1952-11-06",
        "nepDate": "21.07.2009"
    },
    {
        "engDate": "1952-11-07",
        "nepDate": "22.07.2009"
    },
    {
        "engDate": "1952-11-08",
        "nepDate": "23.07.2009"
    },
    {
        "engDate": "1952-11-09",
        "nepDate": "24.07.2009"
    },
    {
        "engDate": "1952-11-10",
        "nepDate": "25.07.2009"
    },
    {
        "engDate": "1952-11-11",
        "nepDate": "26.07.2009"
    },
    {
        "engDate": "1952-11-12",
        "nepDate": "27.07.2009"
    },
    {
        "engDate": "1952-11-13",
        "nepDate": "28.07.2009"
    },
    {
        "engDate": "1952-11-14",
        "nepDate": "29.07.2009"
    },
    {
        "engDate": "1952-11-15",
        "nepDate": "30.07.2009"
    },
    {
        "engDate": "1952-11-16",
        "nepDate": "01.08.2009"
    },
    {
        "engDate": "1952-11-17",
        "nepDate": "02.08.2009"
    },
    {
        "engDate": "1952-11-18",
        "nepDate": "03.08.2009"
    },
    {
        "engDate": "1952-11-19",
        "nepDate": "04.08.2009"
    },
    {
        "engDate": "1952-11-20",
        "nepDate": "05.08.2009"
    },
    {
        "engDate": "1952-11-21",
        "nepDate": "06.08.2009"
    },
    {
        "engDate": "1952-11-22",
        "nepDate": "07.08.2009"
    },
    {
        "engDate": "1952-11-23",
        "nepDate": "08.08.2009"
    },
    {
        "engDate": "1952-11-24",
        "nepDate": "09.08.2009"
    },
    {
        "engDate": "1952-11-25",
        "nepDate": "10.08.2009"
    },
    {
        "engDate": "1952-11-26",
        "nepDate": "11.08.2009"
    },
    {
        "engDate": "1952-11-27",
        "nepDate": "12.08.2009"
    },
    {
        "engDate": "1952-11-28",
        "nepDate": "13.08.2009"
    },
    {
        "engDate": "1952-11-29",
        "nepDate": "14.08.2009"
    },
    {
        "engDate": "1952-11-30",
        "nepDate": "15.08.2009"
    },
    {
        "engDate": "1952-12-01",
        "nepDate": "16.08.2009"
    },
    {
        "engDate": "1952-12-02",
        "nepDate": "17.08.2009"
    },
    {
        "engDate": "1952-12-03",
        "nepDate": "18.08.2009"
    },
    {
        "engDate": "1952-12-04",
        "nepDate": "19.08.2009"
    },
    {
        "engDate": "1952-12-05",
        "nepDate": "20.08.2009"
    },
    {
        "engDate": "1952-12-06",
        "nepDate": "21.08.2009"
    },
    {
        "engDate": "1952-12-07",
        "nepDate": "22.08.2009"
    },
    {
        "engDate": "1952-12-08",
        "nepDate": "23.08.2009"
    },
    {
        "engDate": "1952-12-09",
        "nepDate": "24.08.2009"
    },
    {
        "engDate": "1952-12-10",
        "nepDate": "25.08.2009"
    },
    {
        "engDate": "1952-12-11",
        "nepDate": "26.08.2009"
    },
    {
        "engDate": "1952-12-12",
        "nepDate": "27.08.2009"
    },
    {
        "engDate": "1952-12-13",
        "nepDate": "28.08.2009"
    },
    {
        "engDate": "1952-12-14",
        "nepDate": "29.08.2009"
    },
    {
        "engDate": "1952-12-15",
        "nepDate": "01.09.2009"
    },
    {
        "engDate": "1952-12-16",
        "nepDate": "02.09.2009"
    },
    {
        "engDate": "1952-12-17",
        "nepDate": "03.09.2009"
    },
    {
        "engDate": "1952-12-18",
        "nepDate": "04.09.2009"
    },
    {
        "engDate": "1952-12-19",
        "nepDate": "05.09.2009"
    },
    {
        "engDate": "1952-12-20",
        "nepDate": "06.09.2009"
    },
    {
        "engDate": "1952-12-21",
        "nepDate": "07.09.2009"
    },
    {
        "engDate": "1952-12-22",
        "nepDate": "08.09.2009"
    },
    {
        "engDate": "1952-12-23",
        "nepDate": "09.09.2009"
    },
    {
        "engDate": "1952-12-24",
        "nepDate": "10.09.2009"
    },
    {
        "engDate": "1952-12-25",
        "nepDate": "11.09.2009"
    },
    {
        "engDate": "1952-12-26",
        "nepDate": "12.09.2009"
    },
    {
        "engDate": "1952-12-27",
        "nepDate": "13.09.2009"
    },
    {
        "engDate": "1952-12-28",
        "nepDate": "14.09.2009"
    },
    {
        "engDate": "1952-12-29",
        "nepDate": "15.09.2009"
    },
    {
        "engDate": "1952-12-30",
        "nepDate": "16.09.2009"
    },
    {
        "engDate": "1952-12-31",
        "nepDate": "17.09.2009"
    },
    {
        "engDate": "1953-01-01",
        "nepDate": "18.09.2009"
    },
    {
        "engDate": "1953-01-02",
        "nepDate": "19.09.2009"
    },
    {
        "engDate": "1953-01-03",
        "nepDate": "20.09.2009"
    },
    {
        "engDate": "1953-01-04",
        "nepDate": "21.09.2009"
    },
    {
        "engDate": "1953-01-05",
        "nepDate": "22.09.2009"
    },
    {
        "engDate": "1953-01-06",
        "nepDate": "23.09.2009"
    },
    {
        "engDate": "1953-01-07",
        "nepDate": "24.09.2009"
    },
    {
        "engDate": "1953-01-08",
        "nepDate": "25.09.2009"
    },
    {
        "engDate": "1953-01-09",
        "nepDate": "26.09.2009"
    },
    {
        "engDate": "1953-01-10",
        "nepDate": "27.09.2009"
    },
    {
        "engDate": "1953-01-11",
        "nepDate": "28.09.2009"
    },
    {
        "engDate": "1953-01-12",
        "nepDate": "29.09.2009"
    },
    {
        "engDate": "1953-01-13",
        "nepDate": "30.09.2009"
    },
    {
        "engDate": "1953-01-14",
        "nepDate": "01.10.2009"
    },
    {
        "engDate": "1953-01-15",
        "nepDate": "02.10.2009"
    },
    {
        "engDate": "1953-01-16",
        "nepDate": "03.10.2009"
    },
    {
        "engDate": "1953-01-17",
        "nepDate": "04.10.2009"
    },
    {
        "engDate": "1953-01-18",
        "nepDate": "05.10.2009"
    },
    {
        "engDate": "1953-01-19",
        "nepDate": "06.10.2009"
    },
    {
        "engDate": "1953-01-20",
        "nepDate": "07.10.2009"
    },
    {
        "engDate": "1953-01-21",
        "nepDate": "08.10.2009"
    },
    {
        "engDate": "1953-01-22",
        "nepDate": "09.10.2009"
    },
    {
        "engDate": "1953-01-23",
        "nepDate": "10.10.2009"
    },
    {
        "engDate": "1953-01-24",
        "nepDate": "11.10.2009"
    },
    {
        "engDate": "1953-01-25",
        "nepDate": "12.10.2009"
    },
    {
        "engDate": "1953-01-26",
        "nepDate": "13.10.2009"
    },
    {
        "engDate": "1953-01-27",
        "nepDate": "14.10.2009"
    },
    {
        "engDate": "1953-01-28",
        "nepDate": "15.10.2009"
    },
    {
        "engDate": "1953-01-29",
        "nepDate": "16.10.2009"
    },
    {
        "engDate": "1953-01-30",
        "nepDate": "17.10.2009"
    },
    {
        "engDate": "1953-01-31",
        "nepDate": "18.10.2009"
    },
    {
        "engDate": "1953-02-01",
        "nepDate": "19.10.2009"
    },
    {
        "engDate": "1953-02-02",
        "nepDate": "20.10.2009"
    },
    {
        "engDate": "1953-02-03",
        "nepDate": "21.10.2009"
    },
    {
        "engDate": "1953-02-04",
        "nepDate": "22.10.2009"
    },
    {
        "engDate": "1953-02-05",
        "nepDate": "23.10.2009"
    },
    {
        "engDate": "1953-02-06",
        "nepDate": "24.10.2009"
    },
    {
        "engDate": "1953-02-07",
        "nepDate": "25.10.2009"
    },
    {
        "engDate": "1953-02-08",
        "nepDate": "26.10.2009"
    },
    {
        "engDate": "1953-02-09",
        "nepDate": "27.10.2009"
    },
    {
        "engDate": "1953-02-10",
        "nepDate": "28.10.2009"
    },
    {
        "engDate": "1953-02-11",
        "nepDate": "29.10.2009"
    },
    {
        "engDate": "1953-02-12",
        "nepDate": "01.11.2009"
    },
    {
        "engDate": "1953-02-13",
        "nepDate": "02.11.2009"
    },
    {
        "engDate": "1953-02-14",
        "nepDate": "03.11.2009"
    },
    {
        "engDate": "1953-02-15",
        "nepDate": "04.11.2009"
    },
    {
        "engDate": "1953-02-16",
        "nepDate": "05.11.2009"
    },
    {
        "engDate": "1953-02-17",
        "nepDate": "06.11.2009"
    },
    {
        "engDate": "1953-02-18",
        "nepDate": "07.11.2009"
    },
    {
        "engDate": "1953-02-19",
        "nepDate": "08.11.2009"
    },
    {
        "engDate": "1953-02-20",
        "nepDate": "09.11.2009"
    },
    {
        "engDate": "1953-02-21",
        "nepDate": "10.11.2009"
    },
    {
        "engDate": "1953-02-22",
        "nepDate": "11.11.2009"
    },
    {
        "engDate": "1953-02-23",
        "nepDate": "12.11.2009"
    },
    {
        "engDate": "1953-02-24",
        "nepDate": "13.11.2009"
    },
    {
        "engDate": "1953-02-25",
        "nepDate": "14.11.2009"
    },
    {
        "engDate": "1953-02-26",
        "nepDate": "15.11.2009"
    },
    {
        "engDate": "1953-02-27",
        "nepDate": "16.11.2009"
    },
    {
        "engDate": "1953-02-28",
        "nepDate": "17.11.2009"
    },
    {
        "engDate": "1953-03-01",
        "nepDate": "18.11.2009"
    },
    {
        "engDate": "1953-03-02",
        "nepDate": "19.11.2009"
    },
    {
        "engDate": "1953-03-03",
        "nepDate": "20.11.2009"
    },
    {
        "engDate": "1953-03-04",
        "nepDate": "21.11.2009"
    },
    {
        "engDate": "1953-03-05",
        "nepDate": "22.11.2009"
    },
    {
        "engDate": "1953-03-06",
        "nepDate": "23.11.2009"
    },
    {
        "engDate": "1953-03-07",
        "nepDate": "24.11.2009"
    },
    {
        "engDate": "1953-03-08",
        "nepDate": "25.11.2009"
    },
    {
        "engDate": "1953-03-09",
        "nepDate": "26.11.2009"
    },
    {
        "engDate": "1953-03-10",
        "nepDate": "27.11.2009"
    },
    {
        "engDate": "1953-03-11",
        "nepDate": "28.11.2009"
    },
    {
        "engDate": "1953-03-12",
        "nepDate": "29.11.2009"
    },
    {
        "engDate": "1953-03-13",
        "nepDate": "30.11.2009"
    },
    {
        "engDate": "1953-03-14",
        "nepDate": "01.12.2009"
    },
    {
        "engDate": "1953-03-15",
        "nepDate": "02.12.2009"
    },
    {
        "engDate": "1953-03-16",
        "nepDate": "03.12.2009"
    },
    {
        "engDate": "1953-03-17",
        "nepDate": "04.12.2009"
    },
    {
        "engDate": "1953-03-18",
        "nepDate": "05.12.2009"
    },
    {
        "engDate": "1953-03-19",
        "nepDate": "06.12.2009"
    },
    {
        "engDate": "1953-03-20",
        "nepDate": "07.12.2009"
    },
    {
        "engDate": "1953-03-21",
        "nepDate": "08.12.2009"
    },
    {
        "engDate": "1953-03-22",
        "nepDate": "09.12.2009"
    },
    {
        "engDate": "1953-03-23",
        "nepDate": "10.12.2009"
    },
    {
        "engDate": "1953-03-24",
        "nepDate": "11.12.2009"
    },
    {
        "engDate": "1953-03-25",
        "nepDate": "12.12.2009"
    },
    {
        "engDate": "1953-03-26",
        "nepDate": "13.12.2009"
    },
    {
        "engDate": "1953-03-27",
        "nepDate": "14.12.2009"
    },
    {
        "engDate": "1953-03-28",
        "nepDate": "15.12.2009"
    },
    {
        "engDate": "1953-03-29",
        "nepDate": "16.12.2009"
    },
    {
        "engDate": "1953-03-30",
        "nepDate": "17.12.2009"
    },
    {
        "engDate": "1953-03-31",
        "nepDate": "18.12.2009"
    },
    {
        "engDate": "1953-04-01",
        "nepDate": "19.12.2009"
    },
    {
        "engDate": "1953-04-02",
        "nepDate": "20.12.2009"
    },
    {
        "engDate": "1953-04-03",
        "nepDate": "21.12.2009"
    },
    {
        "engDate": "1953-04-04",
        "nepDate": "22.12.2009"
    },
    {
        "engDate": "1953-04-05",
        "nepDate": "23.12.2009"
    },
    {
        "engDate": "1953-04-06",
        "nepDate": "24.12.2009"
    },
    {
        "engDate": "1953-04-07",
        "nepDate": "25.12.2009"
    },
    {
        "engDate": "1953-04-08",
        "nepDate": "26.12.2009"
    },
    {
        "engDate": "1953-04-09",
        "nepDate": "27.12.2009"
    },
    {
        "engDate": "1953-04-10",
        "nepDate": "28.12.2009"
    },
    {
        "engDate": "1953-04-11",
        "nepDate": "29.12.2009"
    },
    {
        "engDate": "1953-04-12",
        "nepDate": "30.12.2009"
    },
    {
        "engDate": "1953-04-13",
        "nepDate": "01.01.2010"
    },
    {
        "engDate": "1953-04-14",
        "nepDate": "02.01.2010"
    },
    {
        "engDate": "1953-04-15",
        "nepDate": "03.01.2010"
    },
    {
        "engDate": "1953-04-16",
        "nepDate": "04.01.2010"
    },
    {
        "engDate": "1953-04-17",
        "nepDate": "05.01.2010"
    },
    {
        "engDate": "1953-04-18",
        "nepDate": "06.01.2010"
    },
    {
        "engDate": "1953-04-19",
        "nepDate": "07.01.2010"
    },
    {
        "engDate": "1953-04-20",
        "nepDate": "08.01.2010"
    },
    {
        "engDate": "1953-04-21",
        "nepDate": "09.01.2010"
    },
    {
        "engDate": "1953-04-22",
        "nepDate": "10.01.2010"
    },
    {
        "engDate": "1953-04-23",
        "nepDate": "11.01.2010"
    },
    {
        "engDate": "1953-04-24",
        "nepDate": "12.01.2010"
    },
    {
        "engDate": "1953-04-25",
        "nepDate": "13.01.2010"
    },
    {
        "engDate": "1953-04-26",
        "nepDate": "14.01.2010"
    },
    {
        "engDate": "1953-04-27",
        "nepDate": "15.01.2010"
    },
    {
        "engDate": "1953-04-28",
        "nepDate": "16.01.2010"
    },
    {
        "engDate": "1953-04-29",
        "nepDate": "17.01.2010"
    },
    {
        "engDate": "1953-04-30",
        "nepDate": "18.01.2010"
    },
    {
        "engDate": "1953-05-01",
        "nepDate": "19.01.2010"
    },
    {
        "engDate": "1953-05-02",
        "nepDate": "20.01.2010"
    },
    {
        "engDate": "1953-05-03",
        "nepDate": "21.01.2010"
    },
    {
        "engDate": "1953-05-04",
        "nepDate": "22.01.2010"
    },
    {
        "engDate": "1953-05-05",
        "nepDate": "23.01.2010"
    },
    {
        "engDate": "1953-05-06",
        "nepDate": "24.01.2010"
    },
    {
        "engDate": "1953-05-07",
        "nepDate": "25.01.2010"
    },
    {
        "engDate": "1953-05-08",
        "nepDate": "26.01.2010"
    },
    {
        "engDate": "1953-05-09",
        "nepDate": "27.01.2010"
    },
    {
        "engDate": "1953-05-10",
        "nepDate": "28.01.2010"
    },
    {
        "engDate": "1953-05-11",
        "nepDate": "29.01.2010"
    },
    {
        "engDate": "1953-05-12",
        "nepDate": "30.01.2010"
    },
    {
        "engDate": "1953-05-13",
        "nepDate": "31.01.2010"
    },
    {
        "engDate": "1953-05-14",
        "nepDate": "01.02.2010"
    },
    {
        "engDate": "1953-05-15",
        "nepDate": "02.02.2010"
    },
    {
        "engDate": "1953-05-16",
        "nepDate": "03.02.2010"
    },
    {
        "engDate": "1953-05-17",
        "nepDate": "04.02.2010"
    },
    {
        "engDate": "1953-05-18",
        "nepDate": "05.02.2010"
    },
    {
        "engDate": "1953-05-19",
        "nepDate": "06.02.2010"
    },
    {
        "engDate": "1953-05-20",
        "nepDate": "07.02.2010"
    },
    {
        "engDate": "1953-05-21",
        "nepDate": "08.02.2010"
    },
    {
        "engDate": "1953-05-22",
        "nepDate": "09.02.2010"
    },
    {
        "engDate": "1953-05-23",
        "nepDate": "10.02.2010"
    },
    {
        "engDate": "1953-05-24",
        "nepDate": "11.02.2010"
    },
    {
        "engDate": "1953-05-25",
        "nepDate": "12.02.2010"
    },
    {
        "engDate": "1953-05-26",
        "nepDate": "13.02.2010"
    },
    {
        "engDate": "1953-05-27",
        "nepDate": "14.02.2010"
    },
    {
        "engDate": "1953-05-28",
        "nepDate": "15.02.2010"
    },
    {
        "engDate": "1953-05-29",
        "nepDate": "16.02.2010"
    },
    {
        "engDate": "1953-05-30",
        "nepDate": "17.02.2010"
    },
    {
        "engDate": "1953-05-31",
        "nepDate": "18.02.2010"
    },
    {
        "engDate": "1953-06-01",
        "nepDate": "19.02.2010"
    },
    {
        "engDate": "1953-06-02",
        "nepDate": "20.02.2010"
    },
    {
        "engDate": "1953-06-03",
        "nepDate": "21.02.2010"
    },
    {
        "engDate": "1953-06-04",
        "nepDate": "22.02.2010"
    },
    {
        "engDate": "1953-06-05",
        "nepDate": "23.02.2010"
    },
    {
        "engDate": "1953-06-06",
        "nepDate": "24.02.2010"
    },
    {
        "engDate": "1953-06-07",
        "nepDate": "25.02.2010"
    },
    {
        "engDate": "1953-06-08",
        "nepDate": "26.02.2010"
    },
    {
        "engDate": "1953-06-09",
        "nepDate": "27.02.2010"
    },
    {
        "engDate": "1953-06-10",
        "nepDate": "28.02.2010"
    },
    {
        "engDate": "1953-06-11",
        "nepDate": "29.02.2010"
    },
    {
        "engDate": "1953-06-12",
        "nepDate": "30.02.2010"
    },
    {
        "engDate": "1953-06-13",
        "nepDate": "31.02.2010"
    },
    {
        "engDate": "1953-06-14",
        "nepDate": "01.03.2010"
    },
    {
        "engDate": "1953-06-15",
        "nepDate": "02.03.2010"
    },
    {
        "engDate": "1953-06-16",
        "nepDate": "03.03.2010"
    },
    {
        "engDate": "1953-06-17",
        "nepDate": "04.03.2010"
    },
    {
        "engDate": "1953-06-18",
        "nepDate": "05.03.2010"
    },
    {
        "engDate": "1953-06-19",
        "nepDate": "06.03.2010"
    },
    {
        "engDate": "1953-06-20",
        "nepDate": "07.03.2010"
    },
    {
        "engDate": "1953-06-21",
        "nepDate": "08.03.2010"
    },
    {
        "engDate": "1953-06-22",
        "nepDate": "09.03.2010"
    },
    {
        "engDate": "1953-06-23",
        "nepDate": "10.03.2010"
    },
    {
        "engDate": "1953-06-24",
        "nepDate": "11.03.2010"
    },
    {
        "engDate": "1953-06-25",
        "nepDate": "12.03.2010"
    },
    {
        "engDate": "1953-06-26",
        "nepDate": "13.03.2010"
    },
    {
        "engDate": "1953-06-27",
        "nepDate": "14.03.2010"
    },
    {
        "engDate": "1953-06-28",
        "nepDate": "15.03.2010"
    },
    {
        "engDate": "1953-06-29",
        "nepDate": "16.03.2010"
    },
    {
        "engDate": "1953-06-30",
        "nepDate": "17.03.2010"
    },
    {
        "engDate": "1953-07-01",
        "nepDate": "18.03.2010"
    },
    {
        "engDate": "1953-07-02",
        "nepDate": "19.03.2010"
    },
    {
        "engDate": "1953-07-03",
        "nepDate": "20.03.2010"
    },
    {
        "engDate": "1953-07-04",
        "nepDate": "21.03.2010"
    },
    {
        "engDate": "1953-07-05",
        "nepDate": "22.03.2010"
    },
    {
        "engDate": "1953-07-06",
        "nepDate": "23.03.2010"
    },
    {
        "engDate": "1953-07-07",
        "nepDate": "24.03.2010"
    },
    {
        "engDate": "1953-07-08",
        "nepDate": "25.03.2010"
    },
    {
        "engDate": "1953-07-09",
        "nepDate": "26.03.2010"
    },
    {
        "engDate": "1953-07-10",
        "nepDate": "27.03.2010"
    },
    {
        "engDate": "1953-07-11",
        "nepDate": "28.03.2010"
    },
    {
        "engDate": "1953-07-12",
        "nepDate": "29.03.2010"
    },
    {
        "engDate": "1953-07-13",
        "nepDate": "30.03.2010"
    },
    {
        "engDate": "1953-07-14",
        "nepDate": "31.03.2010"
    },
    {
        "engDate": "1953-07-15",
        "nepDate": "32.03.2010"
    },
    {
        "engDate": "1953-07-16",
        "nepDate": "01.04.2010"
    },
    {
        "engDate": "1953-07-17",
        "nepDate": "02.04.2010"
    },
    {
        "engDate": "1953-07-18",
        "nepDate": "03.04.2010"
    },
    {
        "engDate": "1953-07-19",
        "nepDate": "04.04.2010"
    },
    {
        "engDate": "1953-07-20",
        "nepDate": "05.04.2010"
    },
    {
        "engDate": "1953-07-21",
        "nepDate": "06.04.2010"
    },
    {
        "engDate": "1953-07-22",
        "nepDate": "07.04.2010"
    },
    {
        "engDate": "1953-07-23",
        "nepDate": "08.04.2010"
    },
    {
        "engDate": "1953-07-24",
        "nepDate": "09.04.2010"
    },
    {
        "engDate": "1953-07-25",
        "nepDate": "10.04.2010"
    },
    {
        "engDate": "1953-07-26",
        "nepDate": "11.04.2010"
    },
    {
        "engDate": "1953-07-27",
        "nepDate": "12.04.2010"
    },
    {
        "engDate": "1953-07-28",
        "nepDate": "13.04.2010"
    },
    {
        "engDate": "1953-07-29",
        "nepDate": "14.04.2010"
    },
    {
        "engDate": "1953-07-30",
        "nepDate": "15.04.2010"
    },
    {
        "engDate": "1953-07-31",
        "nepDate": "16.04.2010"
    },
    {
        "engDate": "1953-08-01",
        "nepDate": "17.04.2010"
    },
    {
        "engDate": "1953-08-02",
        "nepDate": "18.04.2010"
    },
    {
        "engDate": "1953-08-03",
        "nepDate": "19.04.2010"
    },
    {
        "engDate": "1953-08-04",
        "nepDate": "20.04.2010"
    },
    {
        "engDate": "1953-08-05",
        "nepDate": "21.04.2010"
    },
    {
        "engDate": "1953-08-06",
        "nepDate": "22.04.2010"
    },
    {
        "engDate": "1953-08-07",
        "nepDate": "23.04.2010"
    },
    {
        "engDate": "1953-08-08",
        "nepDate": "24.04.2010"
    },
    {
        "engDate": "1953-08-09",
        "nepDate": "25.04.2010"
    },
    {
        "engDate": "1953-08-10",
        "nepDate": "26.04.2010"
    },
    {
        "engDate": "1953-08-11",
        "nepDate": "27.04.2010"
    },
    {
        "engDate": "1953-08-12",
        "nepDate": "28.04.2010"
    },
    {
        "engDate": "1953-08-13",
        "nepDate": "29.04.2010"
    },
    {
        "engDate": "1953-08-14",
        "nepDate": "30.04.2010"
    },
    {
        "engDate": "1953-08-15",
        "nepDate": "31.04.2010"
    },
    {
        "engDate": "1953-08-16",
        "nepDate": "32.04.2010"
    },
    {
        "engDate": "1953-08-17",
        "nepDate": "01.05.2010"
    },
    {
        "engDate": "1953-08-18",
        "nepDate": "02.05.2010"
    },
    {
        "engDate": "1953-08-19",
        "nepDate": "03.05.2010"
    },
    {
        "engDate": "1953-08-20",
        "nepDate": "04.05.2010"
    },
    {
        "engDate": "1953-08-21",
        "nepDate": "05.05.2010"
    },
    {
        "engDate": "1953-08-22",
        "nepDate": "06.05.2010"
    },
    {
        "engDate": "1953-08-23",
        "nepDate": "07.05.2010"
    },
    {
        "engDate": "1953-08-24",
        "nepDate": "08.05.2010"
    },
    {
        "engDate": "1953-08-25",
        "nepDate": "09.05.2010"
    },
    {
        "engDate": "1953-08-26",
        "nepDate": "10.05.2010"
    },
    {
        "engDate": "1953-08-27",
        "nepDate": "11.05.2010"
    },
    {
        "engDate": "1953-08-28",
        "nepDate": "12.05.2010"
    },
    {
        "engDate": "1953-08-29",
        "nepDate": "13.05.2010"
    },
    {
        "engDate": "1953-08-30",
        "nepDate": "14.05.2010"
    },
    {
        "engDate": "1953-08-31",
        "nepDate": "15.05.2010"
    },
    {
        "engDate": "1953-09-01",
        "nepDate": "16.05.2010"
    },
    {
        "engDate": "1953-09-02",
        "nepDate": "17.05.2010"
    },
    {
        "engDate": "1953-09-03",
        "nepDate": "18.05.2010"
    },
    {
        "engDate": "1953-09-04",
        "nepDate": "19.05.2010"
    },
    {
        "engDate": "1953-09-05",
        "nepDate": "20.05.2010"
    },
    {
        "engDate": "1953-09-06",
        "nepDate": "21.05.2010"
    },
    {
        "engDate": "1953-09-07",
        "nepDate": "22.05.2010"
    },
    {
        "engDate": "1953-09-08",
        "nepDate": "23.05.2010"
    },
    {
        "engDate": "1953-09-09",
        "nepDate": "24.05.2010"
    },
    {
        "engDate": "1953-09-10",
        "nepDate": "25.05.2010"
    },
    {
        "engDate": "1953-09-11",
        "nepDate": "26.05.2010"
    },
    {
        "engDate": "1953-09-12",
        "nepDate": "27.05.2010"
    },
    {
        "engDate": "1953-09-13",
        "nepDate": "28.05.2010"
    },
    {
        "engDate": "1953-09-14",
        "nepDate": "29.05.2010"
    },
    {
        "engDate": "1953-09-15",
        "nepDate": "30.05.2010"
    },
    {
        "engDate": "1953-09-16",
        "nepDate": "31.05.2010"
    },
    {
        "engDate": "1953-09-17",
        "nepDate": "01.06.2010"
    },
    {
        "engDate": "1953-09-18",
        "nepDate": "02.06.2010"
    },
    {
        "engDate": "1953-09-19",
        "nepDate": "03.06.2010"
    },
    {
        "engDate": "1953-09-20",
        "nepDate": "04.06.2010"
    },
    {
        "engDate": "1953-09-21",
        "nepDate": "05.06.2010"
    },
    {
        "engDate": "1953-09-22",
        "nepDate": "06.06.2010"
    },
    {
        "engDate": "1953-09-23",
        "nepDate": "07.06.2010"
    },
    {
        "engDate": "1953-09-24",
        "nepDate": "08.06.2010"
    },
    {
        "engDate": "1953-09-25",
        "nepDate": "09.06.2010"
    },
    {
        "engDate": "1953-09-26",
        "nepDate": "10.06.2010"
    },
    {
        "engDate": "1953-09-27",
        "nepDate": "11.06.2010"
    },
    {
        "engDate": "1953-09-28",
        "nepDate": "12.06.2010"
    },
    {
        "engDate": "1953-09-29",
        "nepDate": "13.06.2010"
    },
    {
        "engDate": "1953-09-30",
        "nepDate": "14.06.2010"
    },
    {
        "engDate": "1953-10-01",
        "nepDate": "15.06.2010"
    },
    {
        "engDate": "1953-10-02",
        "nepDate": "16.06.2010"
    },
    {
        "engDate": "1953-10-03",
        "nepDate": "17.06.2010"
    },
    {
        "engDate": "1953-10-04",
        "nepDate": "18.06.2010"
    },
    {
        "engDate": "1953-10-05",
        "nepDate": "19.06.2010"
    },
    {
        "engDate": "1953-10-06",
        "nepDate": "20.06.2010"
    },
    {
        "engDate": "1953-10-07",
        "nepDate": "21.06.2010"
    },
    {
        "engDate": "1953-10-08",
        "nepDate": "22.06.2010"
    },
    {
        "engDate": "1953-10-09",
        "nepDate": "23.06.2010"
    },
    {
        "engDate": "1953-10-10",
        "nepDate": "24.06.2010"
    },
    {
        "engDate": "1953-10-11",
        "nepDate": "25.06.2010"
    },
    {
        "engDate": "1953-10-12",
        "nepDate": "26.06.2010"
    },
    {
        "engDate": "1953-10-13",
        "nepDate": "27.06.2010"
    },
    {
        "engDate": "1953-10-14",
        "nepDate": "28.06.2010"
    },
    {
        "engDate": "1953-10-15",
        "nepDate": "29.06.2010"
    },
    {
        "engDate": "1953-10-16",
        "nepDate": "30.06.2010"
    },
    {
        "engDate": "1953-10-17",
        "nepDate": "01.07.2010"
    },
    {
        "engDate": "1953-10-18",
        "nepDate": "02.07.2010"
    },
    {
        "engDate": "1953-10-19",
        "nepDate": "03.07.2010"
    },
    {
        "engDate": "1953-10-20",
        "nepDate": "04.07.2010"
    },
    {
        "engDate": "1953-10-21",
        "nepDate": "05.07.2010"
    },
    {
        "engDate": "1953-10-22",
        "nepDate": "06.07.2010"
    },
    {
        "engDate": "1953-10-23",
        "nepDate": "07.07.2010"
    },
    {
        "engDate": "1953-10-24",
        "nepDate": "08.07.2010"
    },
    {
        "engDate": "1953-10-25",
        "nepDate": "09.07.2010"
    },
    {
        "engDate": "1953-10-26",
        "nepDate": "10.07.2010"
    },
    {
        "engDate": "1953-10-27",
        "nepDate": "11.07.2010"
    },
    {
        "engDate": "1953-10-28",
        "nepDate": "12.07.2010"
    },
    {
        "engDate": "1953-10-29",
        "nepDate": "13.07.2010"
    },
    {
        "engDate": "1953-10-30",
        "nepDate": "14.07.2010"
    },
    {
        "engDate": "1953-10-31",
        "nepDate": "15.07.2010"
    },
    {
        "engDate": "1953-11-01",
        "nepDate": "16.07.2010"
    },
    {
        "engDate": "1953-11-02",
        "nepDate": "17.07.2010"
    },
    {
        "engDate": "1953-11-03",
        "nepDate": "18.07.2010"
    },
    {
        "engDate": "1953-11-04",
        "nepDate": "19.07.2010"
    },
    {
        "engDate": "1953-11-05",
        "nepDate": "20.07.2010"
    },
    {
        "engDate": "1953-11-06",
        "nepDate": "21.07.2010"
    },
    {
        "engDate": "1953-11-07",
        "nepDate": "22.07.2010"
    },
    {
        "engDate": "1953-11-08",
        "nepDate": "23.07.2010"
    },
    {
        "engDate": "1953-11-09",
        "nepDate": "24.07.2010"
    },
    {
        "engDate": "1953-11-10",
        "nepDate": "25.07.2010"
    },
    {
        "engDate": "1953-11-11",
        "nepDate": "26.07.2010"
    },
    {
        "engDate": "1953-11-12",
        "nepDate": "27.07.2010"
    },
    {
        "engDate": "1953-11-13",
        "nepDate": "28.07.2010"
    },
    {
        "engDate": "1953-11-14",
        "nepDate": "29.07.2010"
    },
    {
        "engDate": "1953-11-15",
        "nepDate": "30.07.2010"
    },
    {
        "engDate": "1953-11-16",
        "nepDate": "01.08.2010"
    },
    {
        "engDate": "1953-11-17",
        "nepDate": "02.08.2010"
    },
    {
        "engDate": "1953-11-18",
        "nepDate": "03.08.2010"
    },
    {
        "engDate": "1953-11-19",
        "nepDate": "04.08.2010"
    },
    {
        "engDate": "1953-11-20",
        "nepDate": "05.08.2010"
    },
    {
        "engDate": "1953-11-21",
        "nepDate": "06.08.2010"
    },
    {
        "engDate": "1953-11-22",
        "nepDate": "07.08.2010"
    },
    {
        "engDate": "1953-11-23",
        "nepDate": "08.08.2010"
    },
    {
        "engDate": "1953-11-24",
        "nepDate": "09.08.2010"
    },
    {
        "engDate": "1953-11-25",
        "nepDate": "10.08.2010"
    },
    {
        "engDate": "1953-11-26",
        "nepDate": "11.08.2010"
    },
    {
        "engDate": "1953-11-27",
        "nepDate": "12.08.2010"
    },
    {
        "engDate": "1953-11-28",
        "nepDate": "13.08.2010"
    },
    {
        "engDate": "1953-11-29",
        "nepDate": "14.08.2010"
    },
    {
        "engDate": "1953-11-30",
        "nepDate": "15.08.2010"
    },
    {
        "engDate": "1953-12-01",
        "nepDate": "16.08.2010"
    },
    {
        "engDate": "1953-12-02",
        "nepDate": "17.08.2010"
    },
    {
        "engDate": "1953-12-03",
        "nepDate": "18.08.2010"
    },
    {
        "engDate": "1953-12-04",
        "nepDate": "19.08.2010"
    },
    {
        "engDate": "1953-12-05",
        "nepDate": "20.08.2010"
    },
    {
        "engDate": "1953-12-06",
        "nepDate": "21.08.2010"
    },
    {
        "engDate": "1953-12-07",
        "nepDate": "22.08.2010"
    },
    {
        "engDate": "1953-12-08",
        "nepDate": "23.08.2010"
    },
    {
        "engDate": "1953-12-09",
        "nepDate": "24.08.2010"
    },
    {
        "engDate": "1953-12-10",
        "nepDate": "25.08.2010"
    },
    {
        "engDate": "1953-12-11",
        "nepDate": "26.08.2010"
    },
    {
        "engDate": "1953-12-12",
        "nepDate": "27.08.2010"
    },
    {
        "engDate": "1953-12-13",
        "nepDate": "28.08.2010"
    },
    {
        "engDate": "1953-12-14",
        "nepDate": "29.08.2010"
    },
    {
        "engDate": "1953-12-15",
        "nepDate": "01.09.2010"
    },
    {
        "engDate": "1953-12-16",
        "nepDate": "02.09.2010"
    },
    {
        "engDate": "1953-12-17",
        "nepDate": "03.09.2010"
    },
    {
        "engDate": "1953-12-18",
        "nepDate": "04.09.2010"
    },
    {
        "engDate": "1953-12-19",
        "nepDate": "05.09.2010"
    },
    {
        "engDate": "1953-12-20",
        "nepDate": "06.09.2010"
    },
    {
        "engDate": "1953-12-21",
        "nepDate": "07.09.2010"
    },
    {
        "engDate": "1953-12-22",
        "nepDate": "08.09.2010"
    },
    {
        "engDate": "1953-12-23",
        "nepDate": "09.09.2010"
    },
    {
        "engDate": "1953-12-24",
        "nepDate": "10.09.2010"
    },
    {
        "engDate": "1953-12-25",
        "nepDate": "11.09.2010"
    },
    {
        "engDate": "1953-12-26",
        "nepDate": "12.09.2010"
    },
    {
        "engDate": "1953-12-27",
        "nepDate": "13.09.2010"
    },
    {
        "engDate": "1953-12-28",
        "nepDate": "14.09.2010"
    },
    {
        "engDate": "1953-12-29",
        "nepDate": "15.09.2010"
    },
    {
        "engDate": "1953-12-30",
        "nepDate": "16.09.2010"
    },
    {
        "engDate": "1953-12-31",
        "nepDate": "17.09.2010"
    },
    {
        "engDate": "1954-01-01",
        "nepDate": "18.09.2010"
    },
    {
        "engDate": "1954-01-02",
        "nepDate": "19.09.2010"
    },
    {
        "engDate": "1954-01-03",
        "nepDate": "20.09.2010"
    },
    {
        "engDate": "1954-01-04",
        "nepDate": "21.09.2010"
    },
    {
        "engDate": "1954-01-05",
        "nepDate": "22.09.2010"
    },
    {
        "engDate": "1954-01-06",
        "nepDate": "23.09.2010"
    },
    {
        "engDate": "1954-01-07",
        "nepDate": "24.09.2010"
    },
    {
        "engDate": "1954-01-08",
        "nepDate": "25.09.2010"
    },
    {
        "engDate": "1954-01-09",
        "nepDate": "26.09.2010"
    },
    {
        "engDate": "1954-01-10",
        "nepDate": "27.09.2010"
    },
    {
        "engDate": "1954-01-11",
        "nepDate": "28.09.2010"
    },
    {
        "engDate": "1954-01-12",
        "nepDate": "29.09.2010"
    },
    {
        "engDate": "1954-01-13",
        "nepDate": "30.09.2010"
    },
    {
        "engDate": "1954-01-14",
        "nepDate": "01.10.2010"
    },
    {
        "engDate": "1954-01-15",
        "nepDate": "02.10.2010"
    },
    {
        "engDate": "1954-01-16",
        "nepDate": "03.10.2010"
    },
    {
        "engDate": "1954-01-17",
        "nepDate": "04.10.2010"
    },
    {
        "engDate": "1954-01-18",
        "nepDate": "05.10.2010"
    },
    {
        "engDate": "1954-01-19",
        "nepDate": "06.10.2010"
    },
    {
        "engDate": "1954-01-20",
        "nepDate": "07.10.2010"
    },
    {
        "engDate": "1954-01-21",
        "nepDate": "08.10.2010"
    },
    {
        "engDate": "1954-01-22",
        "nepDate": "09.10.2010"
    },
    {
        "engDate": "1954-01-23",
        "nepDate": "10.10.2010"
    },
    {
        "engDate": "1954-01-24",
        "nepDate": "11.10.2010"
    },
    {
        "engDate": "1954-01-25",
        "nepDate": "12.10.2010"
    },
    {
        "engDate": "1954-01-26",
        "nepDate": "13.10.2010"
    },
    {
        "engDate": "1954-01-27",
        "nepDate": "14.10.2010"
    },
    {
        "engDate": "1954-01-28",
        "nepDate": "15.10.2010"
    },
    {
        "engDate": "1954-01-29",
        "nepDate": "16.10.2010"
    },
    {
        "engDate": "1954-01-30",
        "nepDate": "17.10.2010"
    },
    {
        "engDate": "1954-01-31",
        "nepDate": "18.10.2010"
    },
    {
        "engDate": "1954-02-01",
        "nepDate": "19.10.2010"
    },
    {
        "engDate": "1954-02-02",
        "nepDate": "20.10.2010"
    },
    {
        "engDate": "1954-02-03",
        "nepDate": "21.10.2010"
    },
    {
        "engDate": "1954-02-04",
        "nepDate": "22.10.2010"
    },
    {
        "engDate": "1954-02-05",
        "nepDate": "23.10.2010"
    },
    {
        "engDate": "1954-02-06",
        "nepDate": "24.10.2010"
    },
    {
        "engDate": "1954-02-07",
        "nepDate": "25.10.2010"
    },
    {
        "engDate": "1954-02-08",
        "nepDate": "26.10.2010"
    },
    {
        "engDate": "1954-02-09",
        "nepDate": "27.10.2010"
    },
    {
        "engDate": "1954-02-10",
        "nepDate": "28.10.2010"
    },
    {
        "engDate": "1954-02-11",
        "nepDate": "29.10.2010"
    },
    {
        "engDate": "1954-02-12",
        "nepDate": "01.11.2010"
    },
    {
        "engDate": "1954-02-13",
        "nepDate": "02.11.2010"
    },
    {
        "engDate": "1954-02-14",
        "nepDate": "03.11.2010"
    },
    {
        "engDate": "1954-02-15",
        "nepDate": "04.11.2010"
    },
    {
        "engDate": "1954-02-16",
        "nepDate": "05.11.2010"
    },
    {
        "engDate": "1954-02-17",
        "nepDate": "06.11.2010"
    },
    {
        "engDate": "1954-02-18",
        "nepDate": "07.11.2010"
    },
    {
        "engDate": "1954-02-19",
        "nepDate": "08.11.2010"
    },
    {
        "engDate": "1954-02-20",
        "nepDate": "09.11.2010"
    },
    {
        "engDate": "1954-02-21",
        "nepDate": "10.11.2010"
    },
    {
        "engDate": "1954-02-22",
        "nepDate": "11.11.2010"
    },
    {
        "engDate": "1954-02-23",
        "nepDate": "12.11.2010"
    },
    {
        "engDate": "1954-02-24",
        "nepDate": "13.11.2010"
    },
    {
        "engDate": "1954-02-25",
        "nepDate": "14.11.2010"
    },
    {
        "engDate": "1954-02-26",
        "nepDate": "15.11.2010"
    },
    {
        "engDate": "1954-02-27",
        "nepDate": "16.11.2010"
    },
    {
        "engDate": "1954-02-28",
        "nepDate": "17.11.2010"
    },
    {
        "engDate": "1954-03-01",
        "nepDate": "18.11.2010"
    },
    {
        "engDate": "1954-03-02",
        "nepDate": "19.11.2010"
    },
    {
        "engDate": "1954-03-03",
        "nepDate": "20.11.2010"
    },
    {
        "engDate": "1954-03-04",
        "nepDate": "21.11.2010"
    },
    {
        "engDate": "1954-03-05",
        "nepDate": "22.11.2010"
    },
    {
        "engDate": "1954-03-06",
        "nepDate": "23.11.2010"
    },
    {
        "engDate": "1954-03-07",
        "nepDate": "24.11.2010"
    },
    {
        "engDate": "1954-03-08",
        "nepDate": "25.11.2010"
    },
    {
        "engDate": "1954-03-09",
        "nepDate": "26.11.2010"
    },
    {
        "engDate": "1954-03-10",
        "nepDate": "27.11.2010"
    },
    {
        "engDate": "1954-03-11",
        "nepDate": "28.11.2010"
    },
    {
        "engDate": "1954-03-12",
        "nepDate": "29.11.2010"
    },
    {
        "engDate": "1954-03-13",
        "nepDate": "30.11.2010"
    },
    {
        "engDate": "1954-03-14",
        "nepDate": "01.12.2010"
    },
    {
        "engDate": "1954-03-15",
        "nepDate": "02.12.2010"
    },
    {
        "engDate": "1954-03-16",
        "nepDate": "03.12.2010"
    },
    {
        "engDate": "1954-03-17",
        "nepDate": "04.12.2010"
    },
    {
        "engDate": "1954-03-18",
        "nepDate": "05.12.2010"
    },
    {
        "engDate": "1954-03-19",
        "nepDate": "06.12.2010"
    },
    {
        "engDate": "1954-03-20",
        "nepDate": "07.12.2010"
    },
    {
        "engDate": "1954-03-21",
        "nepDate": "08.12.2010"
    },
    {
        "engDate": "1954-03-22",
        "nepDate": "09.12.2010"
    },
    {
        "engDate": "1954-03-23",
        "nepDate": "10.12.2010"
    },
    {
        "engDate": "1954-03-24",
        "nepDate": "11.12.2010"
    },
    {
        "engDate": "1954-03-25",
        "nepDate": "12.12.2010"
    },
    {
        "engDate": "1954-03-26",
        "nepDate": "13.12.2010"
    },
    {
        "engDate": "1954-03-27",
        "nepDate": "14.12.2010"
    },
    {
        "engDate": "1954-03-28",
        "nepDate": "15.12.2010"
    },
    {
        "engDate": "1954-03-29",
        "nepDate": "16.12.2010"
    },
    {
        "engDate": "1954-03-30",
        "nepDate": "17.12.2010"
    },
    {
        "engDate": "1954-03-31",
        "nepDate": "18.12.2010"
    },
    {
        "engDate": "1954-04-01",
        "nepDate": "19.12.2010"
    },
    {
        "engDate": "1954-04-02",
        "nepDate": "20.12.2010"
    },
    {
        "engDate": "1954-04-03",
        "nepDate": "21.12.2010"
    },
    {
        "engDate": "1954-04-04",
        "nepDate": "22.12.2010"
    },
    {
        "engDate": "1954-04-05",
        "nepDate": "23.12.2010"
    },
    {
        "engDate": "1954-04-06",
        "nepDate": "24.12.2010"
    },
    {
        "engDate": "1954-04-07",
        "nepDate": "25.12.2010"
    },
    {
        "engDate": "1954-04-08",
        "nepDate": "26.12.2010"
    },
    {
        "engDate": "1954-04-09",
        "nepDate": "27.12.2010"
    },
    {
        "engDate": "1954-04-10",
        "nepDate": "28.12.2010"
    },
    {
        "engDate": "1954-04-11",
        "nepDate": "29.12.2010"
    },
    {
        "engDate": "1954-04-12",
        "nepDate": "30.12.2010"
    },
    {
        "engDate": "1954-04-13",
        "nepDate": "01.01.2011"
    },
    {
        "engDate": "1954-04-14",
        "nepDate": "02.01.2011"
    },
    {
        "engDate": "1954-04-15",
        "nepDate": "03.01.2011"
    },
    {
        "engDate": "1954-04-16",
        "nepDate": "04.01.2011"
    },
    {
        "engDate": "1954-04-17",
        "nepDate": "05.01.2011"
    },
    {
        "engDate": "1954-04-18",
        "nepDate": "06.01.2011"
    },
    {
        "engDate": "1954-04-19",
        "nepDate": "07.01.2011"
    },
    {
        "engDate": "1954-04-20",
        "nepDate": "08.01.2011"
    },
    {
        "engDate": "1954-04-21",
        "nepDate": "09.01.2011"
    },
    {
        "engDate": "1954-04-22",
        "nepDate": "10.01.2011"
    },
    {
        "engDate": "1954-04-23",
        "nepDate": "11.01.2011"
    },
    {
        "engDate": "1954-04-24",
        "nepDate": "12.01.2011"
    },
    {
        "engDate": "1954-04-25",
        "nepDate": "13.01.2011"
    },
    {
        "engDate": "1954-04-26",
        "nepDate": "14.01.2011"
    },
    {
        "engDate": "1954-04-27",
        "nepDate": "15.01.2011"
    },
    {
        "engDate": "1954-04-28",
        "nepDate": "16.01.2011"
    },
    {
        "engDate": "1954-04-29",
        "nepDate": "17.01.2011"
    },
    {
        "engDate": "1954-04-30",
        "nepDate": "18.01.2011"
    },
    {
        "engDate": "1954-05-01",
        "nepDate": "19.01.2011"
    },
    {
        "engDate": "1954-05-02",
        "nepDate": "20.01.2011"
    },
    {
        "engDate": "1954-05-03",
        "nepDate": "21.01.2011"
    },
    {
        "engDate": "1954-05-04",
        "nepDate": "22.01.2011"
    },
    {
        "engDate": "1954-05-05",
        "nepDate": "23.01.2011"
    },
    {
        "engDate": "1954-05-06",
        "nepDate": "24.01.2011"
    },
    {
        "engDate": "1954-05-07",
        "nepDate": "25.01.2011"
    },
    {
        "engDate": "1954-05-08",
        "nepDate": "26.01.2011"
    },
    {
        "engDate": "1954-05-09",
        "nepDate": "27.01.2011"
    },
    {
        "engDate": "1954-05-10",
        "nepDate": "28.01.2011"
    },
    {
        "engDate": "1954-05-11",
        "nepDate": "29.01.2011"
    },
    {
        "engDate": "1954-05-12",
        "nepDate": "30.01.2011"
    },
    {
        "engDate": "1954-05-13",
        "nepDate": "31.01.2011"
    },
    {
        "engDate": "1954-05-14",
        "nepDate": "01.02.2011"
    },
    {
        "engDate": "1954-05-15",
        "nepDate": "02.02.2011"
    },
    {
        "engDate": "1954-05-16",
        "nepDate": "03.02.2011"
    },
    {
        "engDate": "1954-05-17",
        "nepDate": "04.02.2011"
    },
    {
        "engDate": "1954-05-18",
        "nepDate": "05.02.2011"
    },
    {
        "engDate": "1954-05-19",
        "nepDate": "06.02.2011"
    },
    {
        "engDate": "1954-05-20",
        "nepDate": "07.02.2011"
    },
    {
        "engDate": "1954-05-21",
        "nepDate": "08.02.2011"
    },
    {
        "engDate": "1954-05-22",
        "nepDate": "09.02.2011"
    },
    {
        "engDate": "1954-05-23",
        "nepDate": "10.02.2011"
    },
    {
        "engDate": "1954-05-24",
        "nepDate": "11.02.2011"
    },
    {
        "engDate": "1954-05-25",
        "nepDate": "12.02.2011"
    },
    {
        "engDate": "1954-05-26",
        "nepDate": "13.02.2011"
    },
    {
        "engDate": "1954-05-27",
        "nepDate": "14.02.2011"
    },
    {
        "engDate": "1954-05-28",
        "nepDate": "15.02.2011"
    },
    {
        "engDate": "1954-05-29",
        "nepDate": "16.02.2011"
    },
    {
        "engDate": "1954-05-30",
        "nepDate": "17.02.2011"
    },
    {
        "engDate": "1954-05-31",
        "nepDate": "18.02.2011"
    },
    {
        "engDate": "1954-06-01",
        "nepDate": "19.02.2011"
    },
    {
        "engDate": "1954-06-02",
        "nepDate": "20.02.2011"
    },
    {
        "engDate": "1954-06-03",
        "nepDate": "21.02.2011"
    },
    {
        "engDate": "1954-06-04",
        "nepDate": "22.02.2011"
    },
    {
        "engDate": "1954-06-05",
        "nepDate": "23.02.2011"
    },
    {
        "engDate": "1954-06-06",
        "nepDate": "24.02.2011"
    },
    {
        "engDate": "1954-06-07",
        "nepDate": "25.02.2011"
    },
    {
        "engDate": "1954-06-08",
        "nepDate": "26.02.2011"
    },
    {
        "engDate": "1954-06-09",
        "nepDate": "27.02.2011"
    },
    {
        "engDate": "1954-06-10",
        "nepDate": "28.02.2011"
    },
    {
        "engDate": "1954-06-11",
        "nepDate": "29.02.2011"
    },
    {
        "engDate": "1954-06-12",
        "nepDate": "30.02.2011"
    },
    {
        "engDate": "1954-06-13",
        "nepDate": "31.02.2011"
    },
    {
        "engDate": "1954-06-14",
        "nepDate": "32.02.2011"
    },
    {
        "engDate": "1954-06-15",
        "nepDate": "01.03.2011"
    },
    {
        "engDate": "1954-06-16",
        "nepDate": "02.03.2011"
    },
    {
        "engDate": "1954-06-17",
        "nepDate": "03.03.2011"
    },
    {
        "engDate": "1954-06-18",
        "nepDate": "04.03.2011"
    },
    {
        "engDate": "1954-06-19",
        "nepDate": "05.03.2011"
    },
    {
        "engDate": "1954-06-20",
        "nepDate": "06.03.2011"
    },
    {
        "engDate": "1954-06-21",
        "nepDate": "07.03.2011"
    },
    {
        "engDate": "1954-06-22",
        "nepDate": "08.03.2011"
    },
    {
        "engDate": "1954-06-23",
        "nepDate": "09.03.2011"
    },
    {
        "engDate": "1954-06-24",
        "nepDate": "10.03.2011"
    },
    {
        "engDate": "1954-06-25",
        "nepDate": "11.03.2011"
    },
    {
        "engDate": "1954-06-26",
        "nepDate": "12.03.2011"
    },
    {
        "engDate": "1954-06-27",
        "nepDate": "13.03.2011"
    },
    {
        "engDate": "1954-06-28",
        "nepDate": "14.03.2011"
    },
    {
        "engDate": "1954-06-29",
        "nepDate": "15.03.2011"
    },
    {
        "engDate": "1954-06-30",
        "nepDate": "16.03.2011"
    },
    {
        "engDate": "1954-07-01",
        "nepDate": "17.03.2011"
    },
    {
        "engDate": "1954-07-02",
        "nepDate": "18.03.2011"
    },
    {
        "engDate": "1954-07-03",
        "nepDate": "19.03.2011"
    },
    {
        "engDate": "1954-07-04",
        "nepDate": "20.03.2011"
    },
    {
        "engDate": "1954-07-05",
        "nepDate": "21.03.2011"
    },
    {
        "engDate": "1954-07-06",
        "nepDate": "22.03.2011"
    },
    {
        "engDate": "1954-07-07",
        "nepDate": "23.03.2011"
    },
    {
        "engDate": "1954-07-08",
        "nepDate": "24.03.2011"
    },
    {
        "engDate": "1954-07-09",
        "nepDate": "25.03.2011"
    },
    {
        "engDate": "1954-07-10",
        "nepDate": "26.03.2011"
    },
    {
        "engDate": "1954-07-11",
        "nepDate": "27.03.2011"
    },
    {
        "engDate": "1954-07-12",
        "nepDate": "28.03.2011"
    },
    {
        "engDate": "1954-07-13",
        "nepDate": "29.03.2011"
    },
    {
        "engDate": "1954-07-14",
        "nepDate": "30.03.2011"
    },
    {
        "engDate": "1954-07-15",
        "nepDate": "31.03.2011"
    },
    {
        "engDate": "1954-07-16",
        "nepDate": "01.04.2011"
    },
    {
        "engDate": "1954-07-17",
        "nepDate": "02.04.2011"
    },
    {
        "engDate": "1954-07-18",
        "nepDate": "03.04.2011"
    },
    {
        "engDate": "1954-07-19",
        "nepDate": "04.04.2011"
    },
    {
        "engDate": "1954-07-20",
        "nepDate": "05.04.2011"
    },
    {
        "engDate": "1954-07-21",
        "nepDate": "06.04.2011"
    },
    {
        "engDate": "1954-07-22",
        "nepDate": "07.04.2011"
    },
    {
        "engDate": "1954-07-23",
        "nepDate": "08.04.2011"
    },
    {
        "engDate": "1954-07-24",
        "nepDate": "09.04.2011"
    },
    {
        "engDate": "1954-07-25",
        "nepDate": "10.04.2011"
    },
    {
        "engDate": "1954-07-26",
        "nepDate": "11.04.2011"
    },
    {
        "engDate": "1954-07-27",
        "nepDate": "12.04.2011"
    },
    {
        "engDate": "1954-07-28",
        "nepDate": "13.04.2011"
    },
    {
        "engDate": "1954-07-29",
        "nepDate": "14.04.2011"
    },
    {
        "engDate": "1954-07-30",
        "nepDate": "15.04.2011"
    },
    {
        "engDate": "1954-07-31",
        "nepDate": "16.04.2011"
    },
    {
        "engDate": "1954-08-01",
        "nepDate": "17.04.2011"
    },
    {
        "engDate": "1954-08-02",
        "nepDate": "18.04.2011"
    },
    {
        "engDate": "1954-08-03",
        "nepDate": "19.04.2011"
    },
    {
        "engDate": "1954-08-04",
        "nepDate": "20.04.2011"
    },
    {
        "engDate": "1954-08-05",
        "nepDate": "21.04.2011"
    },
    {
        "engDate": "1954-08-06",
        "nepDate": "22.04.2011"
    },
    {
        "engDate": "1954-08-07",
        "nepDate": "23.04.2011"
    },
    {
        "engDate": "1954-08-08",
        "nepDate": "24.04.2011"
    },
    {
        "engDate": "1954-08-09",
        "nepDate": "25.04.2011"
    },
    {
        "engDate": "1954-08-10",
        "nepDate": "26.04.2011"
    },
    {
        "engDate": "1954-08-11",
        "nepDate": "27.04.2011"
    },
    {
        "engDate": "1954-08-12",
        "nepDate": "28.04.2011"
    },
    {
        "engDate": "1954-08-13",
        "nepDate": "29.04.2011"
    },
    {
        "engDate": "1954-08-14",
        "nepDate": "30.04.2011"
    },
    {
        "engDate": "1954-08-15",
        "nepDate": "31.04.2011"
    },
    {
        "engDate": "1954-08-16",
        "nepDate": "32.04.2011"
    },
    {
        "engDate": "1954-08-17",
        "nepDate": "01.05.2011"
    },
    {
        "engDate": "1954-08-18",
        "nepDate": "02.05.2011"
    },
    {
        "engDate": "1954-08-19",
        "nepDate": "03.05.2011"
    },
    {
        "engDate": "1954-08-20",
        "nepDate": "04.05.2011"
    },
    {
        "engDate": "1954-08-21",
        "nepDate": "05.05.2011"
    },
    {
        "engDate": "1954-08-22",
        "nepDate": "06.05.2011"
    },
    {
        "engDate": "1954-08-23",
        "nepDate": "07.05.2011"
    },
    {
        "engDate": "1954-08-24",
        "nepDate": "08.05.2011"
    },
    {
        "engDate": "1954-08-25",
        "nepDate": "09.05.2011"
    },
    {
        "engDate": "1954-08-26",
        "nepDate": "10.05.2011"
    },
    {
        "engDate": "1954-08-27",
        "nepDate": "11.05.2011"
    },
    {
        "engDate": "1954-08-28",
        "nepDate": "12.05.2011"
    },
    {
        "engDate": "1954-08-29",
        "nepDate": "13.05.2011"
    },
    {
        "engDate": "1954-08-30",
        "nepDate": "14.05.2011"
    },
    {
        "engDate": "1954-08-31",
        "nepDate": "15.05.2011"
    },
    {
        "engDate": "1954-09-01",
        "nepDate": "16.05.2011"
    },
    {
        "engDate": "1954-09-02",
        "nepDate": "17.05.2011"
    },
    {
        "engDate": "1954-09-03",
        "nepDate": "18.05.2011"
    },
    {
        "engDate": "1954-09-04",
        "nepDate": "19.05.2011"
    },
    {
        "engDate": "1954-09-05",
        "nepDate": "20.05.2011"
    },
    {
        "engDate": "1954-09-06",
        "nepDate": "21.05.2011"
    },
    {
        "engDate": "1954-09-07",
        "nepDate": "22.05.2011"
    },
    {
        "engDate": "1954-09-08",
        "nepDate": "23.05.2011"
    },
    {
        "engDate": "1954-09-09",
        "nepDate": "24.05.2011"
    },
    {
        "engDate": "1954-09-10",
        "nepDate": "25.05.2011"
    },
    {
        "engDate": "1954-09-11",
        "nepDate": "26.05.2011"
    },
    {
        "engDate": "1954-09-12",
        "nepDate": "27.05.2011"
    },
    {
        "engDate": "1954-09-13",
        "nepDate": "28.05.2011"
    },
    {
        "engDate": "1954-09-14",
        "nepDate": "29.05.2011"
    },
    {
        "engDate": "1954-09-15",
        "nepDate": "30.05.2011"
    },
    {
        "engDate": "1954-09-16",
        "nepDate": "31.05.2011"
    },
    {
        "engDate": "1954-09-17",
        "nepDate": "01.06.2011"
    },
    {
        "engDate": "1954-09-18",
        "nepDate": "02.06.2011"
    },
    {
        "engDate": "1954-09-19",
        "nepDate": "03.06.2011"
    },
    {
        "engDate": "1954-09-20",
        "nepDate": "04.06.2011"
    },
    {
        "engDate": "1954-09-21",
        "nepDate": "05.06.2011"
    },
    {
        "engDate": "1954-09-22",
        "nepDate": "06.06.2011"
    },
    {
        "engDate": "1954-09-23",
        "nepDate": "07.06.2011"
    },
    {
        "engDate": "1954-09-24",
        "nepDate": "08.06.2011"
    },
    {
        "engDate": "1954-09-25",
        "nepDate": "09.06.2011"
    },
    {
        "engDate": "1954-09-26",
        "nepDate": "10.06.2011"
    },
    {
        "engDate": "1954-09-27",
        "nepDate": "11.06.2011"
    },
    {
        "engDate": "1954-09-28",
        "nepDate": "12.06.2011"
    },
    {
        "engDate": "1954-09-29",
        "nepDate": "13.06.2011"
    },
    {
        "engDate": "1954-09-30",
        "nepDate": "14.06.2011"
    },
    {
        "engDate": "1954-10-01",
        "nepDate": "15.06.2011"
    },
    {
        "engDate": "1954-10-02",
        "nepDate": "16.06.2011"
    },
    {
        "engDate": "1954-10-03",
        "nepDate": "17.06.2011"
    },
    {
        "engDate": "1954-10-04",
        "nepDate": "18.06.2011"
    },
    {
        "engDate": "1954-10-05",
        "nepDate": "19.06.2011"
    },
    {
        "engDate": "1954-10-06",
        "nepDate": "20.06.2011"
    },
    {
        "engDate": "1954-10-07",
        "nepDate": "21.06.2011"
    },
    {
        "engDate": "1954-10-08",
        "nepDate": "22.06.2011"
    },
    {
        "engDate": "1954-10-09",
        "nepDate": "23.06.2011"
    },
    {
        "engDate": "1954-10-10",
        "nepDate": "24.06.2011"
    },
    {
        "engDate": "1954-10-11",
        "nepDate": "25.06.2011"
    },
    {
        "engDate": "1954-10-12",
        "nepDate": "26.06.2011"
    },
    {
        "engDate": "1954-10-13",
        "nepDate": "27.06.2011"
    },
    {
        "engDate": "1954-10-14",
        "nepDate": "28.06.2011"
    },
    {
        "engDate": "1954-10-15",
        "nepDate": "29.06.2011"
    },
    {
        "engDate": "1954-10-16",
        "nepDate": "30.06.2011"
    },
    {
        "engDate": "1954-10-17",
        "nepDate": "01.07.2011"
    },
    {
        "engDate": "1954-10-18",
        "nepDate": "02.07.2011"
    },
    {
        "engDate": "1954-10-19",
        "nepDate": "03.07.2011"
    },
    {
        "engDate": "1954-10-20",
        "nepDate": "04.07.2011"
    },
    {
        "engDate": "1954-10-21",
        "nepDate": "05.07.2011"
    },
    {
        "engDate": "1954-10-22",
        "nepDate": "06.07.2011"
    },
    {
        "engDate": "1954-10-23",
        "nepDate": "07.07.2011"
    },
    {
        "engDate": "1954-10-24",
        "nepDate": "08.07.2011"
    },
    {
        "engDate": "1954-10-25",
        "nepDate": "09.07.2011"
    },
    {
        "engDate": "1954-10-26",
        "nepDate": "10.07.2011"
    },
    {
        "engDate": "1954-10-27",
        "nepDate": "11.07.2011"
    },
    {
        "engDate": "1954-10-28",
        "nepDate": "12.07.2011"
    },
    {
        "engDate": "1954-10-29",
        "nepDate": "13.07.2011"
    },
    {
        "engDate": "1954-10-30",
        "nepDate": "14.07.2011"
    },
    {
        "engDate": "1954-10-31",
        "nepDate": "15.07.2011"
    },
    {
        "engDate": "1954-11-01",
        "nepDate": "16.07.2011"
    },
    {
        "engDate": "1954-11-02",
        "nepDate": "17.07.2011"
    },
    {
        "engDate": "1954-11-03",
        "nepDate": "18.07.2011"
    },
    {
        "engDate": "1954-11-04",
        "nepDate": "19.07.2011"
    },
    {
        "engDate": "1954-11-05",
        "nepDate": "20.07.2011"
    },
    {
        "engDate": "1954-11-06",
        "nepDate": "21.07.2011"
    },
    {
        "engDate": "1954-11-07",
        "nepDate": "22.07.2011"
    },
    {
        "engDate": "1954-11-08",
        "nepDate": "23.07.2011"
    },
    {
        "engDate": "1954-11-09",
        "nepDate": "24.07.2011"
    },
    {
        "engDate": "1954-11-10",
        "nepDate": "25.07.2011"
    },
    {
        "engDate": "1954-11-11",
        "nepDate": "26.07.2011"
    },
    {
        "engDate": "1954-11-12",
        "nepDate": "27.07.2011"
    },
    {
        "engDate": "1954-11-13",
        "nepDate": "28.07.2011"
    },
    {
        "engDate": "1954-11-14",
        "nepDate": "29.07.2011"
    },
    {
        "engDate": "1954-11-15",
        "nepDate": "30.07.2011"
    },
    {
        "engDate": "1954-11-16",
        "nepDate": "01.08.2011"
    },
    {
        "engDate": "1954-11-17",
        "nepDate": "02.08.2011"
    },
    {
        "engDate": "1954-11-18",
        "nepDate": "03.08.2011"
    },
    {
        "engDate": "1954-11-19",
        "nepDate": "04.08.2011"
    },
    {
        "engDate": "1954-11-20",
        "nepDate": "05.08.2011"
    },
    {
        "engDate": "1954-11-21",
        "nepDate": "06.08.2011"
    },
    {
        "engDate": "1954-11-22",
        "nepDate": "07.08.2011"
    },
    {
        "engDate": "1954-11-23",
        "nepDate": "08.08.2011"
    },
    {
        "engDate": "1954-11-24",
        "nepDate": "09.08.2011"
    },
    {
        "engDate": "1954-11-25",
        "nepDate": "10.08.2011"
    },
    {
        "engDate": "1954-11-26",
        "nepDate": "11.08.2011"
    },
    {
        "engDate": "1954-11-27",
        "nepDate": "12.08.2011"
    },
    {
        "engDate": "1954-11-28",
        "nepDate": "13.08.2011"
    },
    {
        "engDate": "1954-11-29",
        "nepDate": "14.08.2011"
    },
    {
        "engDate": "1954-11-30",
        "nepDate": "15.08.2011"
    },
    {
        "engDate": "1954-12-01",
        "nepDate": "16.08.2011"
    },
    {
        "engDate": "1954-12-02",
        "nepDate": "17.08.2011"
    },
    {
        "engDate": "1954-12-03",
        "nepDate": "18.08.2011"
    },
    {
        "engDate": "1954-12-04",
        "nepDate": "19.08.2011"
    },
    {
        "engDate": "1954-12-05",
        "nepDate": "20.08.2011"
    },
    {
        "engDate": "1954-12-06",
        "nepDate": "21.08.2011"
    },
    {
        "engDate": "1954-12-07",
        "nepDate": "22.08.2011"
    },
    {
        "engDate": "1954-12-08",
        "nepDate": "23.08.2011"
    },
    {
        "engDate": "1954-12-09",
        "nepDate": "24.08.2011"
    },
    {
        "engDate": "1954-12-10",
        "nepDate": "25.08.2011"
    },
    {
        "engDate": "1954-12-11",
        "nepDate": "26.08.2011"
    },
    {
        "engDate": "1954-12-12",
        "nepDate": "27.08.2011"
    },
    {
        "engDate": "1954-12-13",
        "nepDate": "28.08.2011"
    },
    {
        "engDate": "1954-12-14",
        "nepDate": "29.08.2011"
    },
    {
        "engDate": "1954-12-15",
        "nepDate": "30.08.2011"
    },
    {
        "engDate": "1954-12-16",
        "nepDate": "01.09.2011"
    },
    {
        "engDate": "1954-12-17",
        "nepDate": "02.09.2011"
    },
    {
        "engDate": "1954-12-18",
        "nepDate": "03.09.2011"
    },
    {
        "engDate": "1954-12-19",
        "nepDate": "04.09.2011"
    },
    {
        "engDate": "1954-12-20",
        "nepDate": "05.09.2011"
    },
    {
        "engDate": "1954-12-21",
        "nepDate": "06.09.2011"
    },
    {
        "engDate": "1954-12-22",
        "nepDate": "07.09.2011"
    },
    {
        "engDate": "1954-12-23",
        "nepDate": "08.09.2011"
    },
    {
        "engDate": "1954-12-24",
        "nepDate": "09.09.2011"
    },
    {
        "engDate": "1954-12-25",
        "nepDate": "10.09.2011"
    },
    {
        "engDate": "1954-12-26",
        "nepDate": "11.09.2011"
    },
    {
        "engDate": "1954-12-27",
        "nepDate": "12.09.2011"
    },
    {
        "engDate": "1954-12-28",
        "nepDate": "13.09.2011"
    },
    {
        "engDate": "1954-12-29",
        "nepDate": "14.09.2011"
    },
    {
        "engDate": "1954-12-30",
        "nepDate": "15.09.2011"
    },
    {
        "engDate": "1954-12-31",
        "nepDate": "16.09.2011"
    },
    {
        "engDate": "1955-01-01",
        "nepDate": "17.09.2011"
    },
    {
        "engDate": "1955-01-02",
        "nepDate": "18.09.2011"
    },
    {
        "engDate": "1955-01-03",
        "nepDate": "19.09.2011"
    },
    {
        "engDate": "1955-01-04",
        "nepDate": "20.09.2011"
    },
    {
        "engDate": "1955-01-05",
        "nepDate": "21.09.2011"
    },
    {
        "engDate": "1955-01-06",
        "nepDate": "22.09.2011"
    },
    {
        "engDate": "1955-01-07",
        "nepDate": "23.09.2011"
    },
    {
        "engDate": "1955-01-08",
        "nepDate": "24.09.2011"
    },
    {
        "engDate": "1955-01-09",
        "nepDate": "25.09.2011"
    },
    {
        "engDate": "1955-01-10",
        "nepDate": "26.09.2011"
    },
    {
        "engDate": "1955-01-11",
        "nepDate": "27.09.2011"
    },
    {
        "engDate": "1955-01-12",
        "nepDate": "28.09.2011"
    },
    {
        "engDate": "1955-01-13",
        "nepDate": "29.09.2011"
    },
    {
        "engDate": "1955-01-14",
        "nepDate": "01.10.2011"
    },
    {
        "engDate": "1955-01-15",
        "nepDate": "02.10.2011"
    },
    {
        "engDate": "1955-01-16",
        "nepDate": "03.10.2011"
    },
    {
        "engDate": "1955-01-17",
        "nepDate": "04.10.2011"
    },
    {
        "engDate": "1955-01-18",
        "nepDate": "05.10.2011"
    },
    {
        "engDate": "1955-01-19",
        "nepDate": "06.10.2011"
    },
    {
        "engDate": "1955-01-20",
        "nepDate": "07.10.2011"
    },
    {
        "engDate": "1955-01-21",
        "nepDate": "08.10.2011"
    },
    {
        "engDate": "1955-01-22",
        "nepDate": "09.10.2011"
    },
    {
        "engDate": "1955-01-23",
        "nepDate": "10.10.2011"
    },
    {
        "engDate": "1955-01-24",
        "nepDate": "11.10.2011"
    },
    {
        "engDate": "1955-01-25",
        "nepDate": "12.10.2011"
    },
    {
        "engDate": "1955-01-26",
        "nepDate": "13.10.2011"
    },
    {
        "engDate": "1955-01-27",
        "nepDate": "14.10.2011"
    },
    {
        "engDate": "1955-01-28",
        "nepDate": "15.10.2011"
    },
    {
        "engDate": "1955-01-29",
        "nepDate": "16.10.2011"
    },
    {
        "engDate": "1955-01-30",
        "nepDate": "17.10.2011"
    },
    {
        "engDate": "1955-01-31",
        "nepDate": "18.10.2011"
    },
    {
        "engDate": "1955-02-01",
        "nepDate": "19.10.2011"
    },
    {
        "engDate": "1955-02-02",
        "nepDate": "20.10.2011"
    },
    {
        "engDate": "1955-02-03",
        "nepDate": "21.10.2011"
    },
    {
        "engDate": "1955-02-04",
        "nepDate": "22.10.2011"
    },
    {
        "engDate": "1955-02-05",
        "nepDate": "23.10.2011"
    },
    {
        "engDate": "1955-02-06",
        "nepDate": "24.10.2011"
    },
    {
        "engDate": "1955-02-07",
        "nepDate": "25.10.2011"
    },
    {
        "engDate": "1955-02-08",
        "nepDate": "26.10.2011"
    },
    {
        "engDate": "1955-02-09",
        "nepDate": "27.10.2011"
    },
    {
        "engDate": "1955-02-10",
        "nepDate": "28.10.2011"
    },
    {
        "engDate": "1955-02-11",
        "nepDate": "29.10.2011"
    },
    {
        "engDate": "1955-02-12",
        "nepDate": "01.11.2011"
    },
    {
        "engDate": "1955-02-13",
        "nepDate": "02.11.2011"
    },
    {
        "engDate": "1955-02-14",
        "nepDate": "03.11.2011"
    },
    {
        "engDate": "1955-02-15",
        "nepDate": "04.11.2011"
    },
    {
        "engDate": "1955-02-16",
        "nepDate": "05.11.2011"
    },
    {
        "engDate": "1955-02-17",
        "nepDate": "06.11.2011"
    },
    {
        "engDate": "1955-02-18",
        "nepDate": "07.11.2011"
    },
    {
        "engDate": "1955-02-19",
        "nepDate": "08.11.2011"
    },
    {
        "engDate": "1955-02-20",
        "nepDate": "09.11.2011"
    },
    {
        "engDate": "1955-02-21",
        "nepDate": "10.11.2011"
    },
    {
        "engDate": "1955-02-22",
        "nepDate": "11.11.2011"
    },
    {
        "engDate": "1955-02-23",
        "nepDate": "12.11.2011"
    },
    {
        "engDate": "1955-02-24",
        "nepDate": "13.11.2011"
    },
    {
        "engDate": "1955-02-25",
        "nepDate": "14.11.2011"
    },
    {
        "engDate": "1955-02-26",
        "nepDate": "15.11.2011"
    },
    {
        "engDate": "1955-02-27",
        "nepDate": "16.11.2011"
    },
    {
        "engDate": "1955-02-28",
        "nepDate": "17.11.2011"
    },
    {
        "engDate": "1955-03-01",
        "nepDate": "18.11.2011"
    },
    {
        "engDate": "1955-03-02",
        "nepDate": "19.11.2011"
    },
    {
        "engDate": "1955-03-03",
        "nepDate": "20.11.2011"
    },
    {
        "engDate": "1955-03-04",
        "nepDate": "21.11.2011"
    },
    {
        "engDate": "1955-03-05",
        "nepDate": "22.11.2011"
    },
    {
        "engDate": "1955-03-06",
        "nepDate": "23.11.2011"
    },
    {
        "engDate": "1955-03-07",
        "nepDate": "24.11.2011"
    },
    {
        "engDate": "1955-03-08",
        "nepDate": "25.11.2011"
    },
    {
        "engDate": "1955-03-09",
        "nepDate": "26.11.2011"
    },
    {
        "engDate": "1955-03-10",
        "nepDate": "27.11.2011"
    },
    {
        "engDate": "1955-03-11",
        "nepDate": "28.11.2011"
    },
    {
        "engDate": "1955-03-12",
        "nepDate": "29.11.2011"
    },
    {
        "engDate": "1955-03-13",
        "nepDate": "30.11.2011"
    },
    {
        "engDate": "1955-03-14",
        "nepDate": "01.12.2011"
    },
    {
        "engDate": "1955-03-15",
        "nepDate": "02.12.2011"
    },
    {
        "engDate": "1955-03-16",
        "nepDate": "03.12.2011"
    },
    {
        "engDate": "1955-03-17",
        "nepDate": "04.12.2011"
    },
    {
        "engDate": "1955-03-18",
        "nepDate": "05.12.2011"
    },
    {
        "engDate": "1955-03-19",
        "nepDate": "06.12.2011"
    },
    {
        "engDate": "1955-03-20",
        "nepDate": "07.12.2011"
    },
    {
        "engDate": "1955-03-21",
        "nepDate": "08.12.2011"
    },
    {
        "engDate": "1955-03-22",
        "nepDate": "09.12.2011"
    },
    {
        "engDate": "1955-03-23",
        "nepDate": "10.12.2011"
    },
    {
        "engDate": "1955-03-24",
        "nepDate": "11.12.2011"
    },
    {
        "engDate": "1955-03-25",
        "nepDate": "12.12.2011"
    },
    {
        "engDate": "1955-03-26",
        "nepDate": "13.12.2011"
    },
    {
        "engDate": "1955-03-27",
        "nepDate": "14.12.2011"
    },
    {
        "engDate": "1955-03-28",
        "nepDate": "15.12.2011"
    },
    {
        "engDate": "1955-03-29",
        "nepDate": "16.12.2011"
    },
    {
        "engDate": "1955-03-30",
        "nepDate": "17.12.2011"
    },
    {
        "engDate": "1955-03-31",
        "nepDate": "18.12.2011"
    },
    {
        "engDate": "1955-04-01",
        "nepDate": "19.12.2011"
    },
    {
        "engDate": "1955-04-02",
        "nepDate": "20.12.2011"
    },
    {
        "engDate": "1955-04-03",
        "nepDate": "21.12.2011"
    },
    {
        "engDate": "1955-04-04",
        "nepDate": "22.12.2011"
    },
    {
        "engDate": "1955-04-05",
        "nepDate": "23.12.2011"
    },
    {
        "engDate": "1955-04-06",
        "nepDate": "24.12.2011"
    },
    {
        "engDate": "1955-04-07",
        "nepDate": "25.12.2011"
    },
    {
        "engDate": "1955-04-08",
        "nepDate": "26.12.2011"
    },
    {
        "engDate": "1955-04-09",
        "nepDate": "27.12.2011"
    },
    {
        "engDate": "1955-04-10",
        "nepDate": "28.12.2011"
    },
    {
        "engDate": "1955-04-11",
        "nepDate": "29.12.2011"
    },
    {
        "engDate": "1955-04-12",
        "nepDate": "30.12.2011"
    },
    {
        "engDate": "1955-04-13",
        "nepDate": "31.12.2011"
    },
    {
        "engDate": "1955-04-14",
        "nepDate": "01.01.2012"
    },
    {
        "engDate": "1955-04-15",
        "nepDate": "02.01.2012"
    },
    {
        "engDate": "1955-04-16",
        "nepDate": "03.01.2012"
    },
    {
        "engDate": "1955-04-17",
        "nepDate": "04.01.2012"
    },
    {
        "engDate": "1955-04-18",
        "nepDate": "05.01.2012"
    },
    {
        "engDate": "1955-04-19",
        "nepDate": "06.01.2012"
    },
    {
        "engDate": "1955-04-20",
        "nepDate": "07.01.2012"
    },
    {
        "engDate": "1955-04-21",
        "nepDate": "08.01.2012"
    },
    {
        "engDate": "1955-04-22",
        "nepDate": "09.01.2012"
    },
    {
        "engDate": "1955-04-23",
        "nepDate": "10.01.2012"
    },
    {
        "engDate": "1955-04-24",
        "nepDate": "11.01.2012"
    },
    {
        "engDate": "1955-04-25",
        "nepDate": "12.01.2012"
    },
    {
        "engDate": "1955-04-26",
        "nepDate": "13.01.2012"
    },
    {
        "engDate": "1955-04-27",
        "nepDate": "14.01.2012"
    },
    {
        "engDate": "1955-04-28",
        "nepDate": "15.01.2012"
    },
    {
        "engDate": "1955-04-29",
        "nepDate": "16.01.2012"
    },
    {
        "engDate": "1955-04-30",
        "nepDate": "17.01.2012"
    },
    {
        "engDate": "1955-05-01",
        "nepDate": "18.01.2012"
    },
    {
        "engDate": "1955-05-02",
        "nepDate": "19.01.2012"
    },
    {
        "engDate": "1955-05-03",
        "nepDate": "20.01.2012"
    },
    {
        "engDate": "1955-05-04",
        "nepDate": "21.01.2012"
    },
    {
        "engDate": "1955-05-05",
        "nepDate": "22.01.2012"
    },
    {
        "engDate": "1955-05-06",
        "nepDate": "23.01.2012"
    },
    {
        "engDate": "1955-05-07",
        "nepDate": "24.01.2012"
    },
    {
        "engDate": "1955-05-08",
        "nepDate": "25.01.2012"
    },
    {
        "engDate": "1955-05-09",
        "nepDate": "26.01.2012"
    },
    {
        "engDate": "1955-05-10",
        "nepDate": "27.01.2012"
    },
    {
        "engDate": "1955-05-11",
        "nepDate": "28.01.2012"
    },
    {
        "engDate": "1955-05-12",
        "nepDate": "29.01.2012"
    },
    {
        "engDate": "1955-05-13",
        "nepDate": "30.01.2012"
    },
    {
        "engDate": "1955-05-14",
        "nepDate": "31.01.2012"
    },
    {
        "engDate": "1955-05-15",
        "nepDate": "01.02.2012"
    },
    {
        "engDate": "1955-05-16",
        "nepDate": "02.02.2012"
    },
    {
        "engDate": "1955-05-17",
        "nepDate": "03.02.2012"
    },
    {
        "engDate": "1955-05-18",
        "nepDate": "04.02.2012"
    },
    {
        "engDate": "1955-05-19",
        "nepDate": "05.02.2012"
    },
    {
        "engDate": "1955-05-20",
        "nepDate": "06.02.2012"
    },
    {
        "engDate": "1955-05-21",
        "nepDate": "07.02.2012"
    },
    {
        "engDate": "1955-05-22",
        "nepDate": "08.02.2012"
    },
    {
        "engDate": "1955-05-23",
        "nepDate": "09.02.2012"
    },
    {
        "engDate": "1955-05-24",
        "nepDate": "10.02.2012"
    },
    {
        "engDate": "1955-05-25",
        "nepDate": "11.02.2012"
    },
    {
        "engDate": "1955-05-26",
        "nepDate": "12.02.2012"
    },
    {
        "engDate": "1955-05-27",
        "nepDate": "13.02.2012"
    },
    {
        "engDate": "1955-05-28",
        "nepDate": "14.02.2012"
    },
    {
        "engDate": "1955-05-29",
        "nepDate": "15.02.2012"
    },
    {
        "engDate": "1955-05-30",
        "nepDate": "16.02.2012"
    },
    {
        "engDate": "1955-05-31",
        "nepDate": "17.02.2012"
    },
    {
        "engDate": "1955-06-01",
        "nepDate": "18.02.2012"
    },
    {
        "engDate": "1955-06-02",
        "nepDate": "19.02.2012"
    },
    {
        "engDate": "1955-06-03",
        "nepDate": "20.02.2012"
    },
    {
        "engDate": "1955-06-04",
        "nepDate": "21.02.2012"
    },
    {
        "engDate": "1955-06-05",
        "nepDate": "22.02.2012"
    },
    {
        "engDate": "1955-06-06",
        "nepDate": "23.02.2012"
    },
    {
        "engDate": "1955-06-07",
        "nepDate": "24.02.2012"
    },
    {
        "engDate": "1955-06-08",
        "nepDate": "25.02.2012"
    },
    {
        "engDate": "1955-06-09",
        "nepDate": "26.02.2012"
    },
    {
        "engDate": "1955-06-10",
        "nepDate": "27.02.2012"
    },
    {
        "engDate": "1955-06-11",
        "nepDate": "28.02.2012"
    },
    {
        "engDate": "1955-06-12",
        "nepDate": "29.02.2012"
    },
    {
        "engDate": "1955-06-13",
        "nepDate": "30.02.2012"
    },
    {
        "engDate": "1955-06-14",
        "nepDate": "31.02.2012"
    },
    {
        "engDate": "1955-06-15",
        "nepDate": "01.03.2012"
    },
    {
        "engDate": "1955-06-16",
        "nepDate": "02.03.2012"
    },
    {
        "engDate": "1955-06-17",
        "nepDate": "03.03.2012"
    },
    {
        "engDate": "1955-06-18",
        "nepDate": "04.03.2012"
    },
    {
        "engDate": "1955-06-19",
        "nepDate": "05.03.2012"
    },
    {
        "engDate": "1955-06-20",
        "nepDate": "06.03.2012"
    },
    {
        "engDate": "1955-06-21",
        "nepDate": "07.03.2012"
    },
    {
        "engDate": "1955-06-22",
        "nepDate": "08.03.2012"
    },
    {
        "engDate": "1955-06-23",
        "nepDate": "09.03.2012"
    },
    {
        "engDate": "1955-06-24",
        "nepDate": "10.03.2012"
    },
    {
        "engDate": "1955-06-25",
        "nepDate": "11.03.2012"
    },
    {
        "engDate": "1955-06-26",
        "nepDate": "12.03.2012"
    },
    {
        "engDate": "1955-06-27",
        "nepDate": "13.03.2012"
    },
    {
        "engDate": "1955-06-28",
        "nepDate": "14.03.2012"
    },
    {
        "engDate": "1955-06-29",
        "nepDate": "15.03.2012"
    },
    {
        "engDate": "1955-06-30",
        "nepDate": "16.03.2012"
    },
    {
        "engDate": "1955-07-01",
        "nepDate": "17.03.2012"
    },
    {
        "engDate": "1955-07-02",
        "nepDate": "18.03.2012"
    },
    {
        "engDate": "1955-07-03",
        "nepDate": "19.03.2012"
    },
    {
        "engDate": "1955-07-04",
        "nepDate": "20.03.2012"
    },
    {
        "engDate": "1955-07-05",
        "nepDate": "21.03.2012"
    },
    {
        "engDate": "1955-07-06",
        "nepDate": "22.03.2012"
    },
    {
        "engDate": "1955-07-07",
        "nepDate": "23.03.2012"
    },
    {
        "engDate": "1955-07-08",
        "nepDate": "24.03.2012"
    },
    {
        "engDate": "1955-07-09",
        "nepDate": "25.03.2012"
    },
    {
        "engDate": "1955-07-10",
        "nepDate": "26.03.2012"
    },
    {
        "engDate": "1955-07-11",
        "nepDate": "27.03.2012"
    },
    {
        "engDate": "1955-07-12",
        "nepDate": "28.03.2012"
    },
    {
        "engDate": "1955-07-13",
        "nepDate": "29.03.2012"
    },
    {
        "engDate": "1955-07-14",
        "nepDate": "30.03.2012"
    },
    {
        "engDate": "1955-07-15",
        "nepDate": "31.03.2012"
    },
    {
        "engDate": "1955-07-16",
        "nepDate": "01.04.2012"
    },
    {
        "engDate": "1955-07-17",
        "nepDate": "02.04.2012"
    },
    {
        "engDate": "1955-07-18",
        "nepDate": "03.04.2012"
    },
    {
        "engDate": "1955-07-19",
        "nepDate": "04.04.2012"
    },
    {
        "engDate": "1955-07-20",
        "nepDate": "05.04.2012"
    },
    {
        "engDate": "1955-07-21",
        "nepDate": "06.04.2012"
    },
    {
        "engDate": "1955-07-22",
        "nepDate": "07.04.2012"
    },
    {
        "engDate": "1955-07-23",
        "nepDate": "08.04.2012"
    },
    {
        "engDate": "1955-07-24",
        "nepDate": "09.04.2012"
    },
    {
        "engDate": "1955-07-25",
        "nepDate": "10.04.2012"
    },
    {
        "engDate": "1955-07-26",
        "nepDate": "11.04.2012"
    },
    {
        "engDate": "1955-07-27",
        "nepDate": "12.04.2012"
    },
    {
        "engDate": "1955-07-28",
        "nepDate": "13.04.2012"
    },
    {
        "engDate": "1955-07-29",
        "nepDate": "14.04.2012"
    },
    {
        "engDate": "1955-07-30",
        "nepDate": "15.04.2012"
    },
    {
        "engDate": "1955-07-31",
        "nepDate": "16.04.2012"
    },
    {
        "engDate": "1955-08-01",
        "nepDate": "17.04.2012"
    },
    {
        "engDate": "1955-08-02",
        "nepDate": "18.04.2012"
    },
    {
        "engDate": "1955-08-03",
        "nepDate": "19.04.2012"
    },
    {
        "engDate": "1955-08-04",
        "nepDate": "20.04.2012"
    },
    {
        "engDate": "1955-08-05",
        "nepDate": "21.04.2012"
    },
    {
        "engDate": "1955-08-06",
        "nepDate": "22.04.2012"
    },
    {
        "engDate": "1955-08-07",
        "nepDate": "23.04.2012"
    },
    {
        "engDate": "1955-08-08",
        "nepDate": "24.04.2012"
    },
    {
        "engDate": "1955-08-09",
        "nepDate": "25.04.2012"
    },
    {
        "engDate": "1955-08-10",
        "nepDate": "26.04.2012"
    },
    {
        "engDate": "1955-08-11",
        "nepDate": "27.04.2012"
    },
    {
        "engDate": "1955-08-12",
        "nepDate": "28.04.2012"
    },
    {
        "engDate": "1955-08-13",
        "nepDate": "29.04.2012"
    },
    {
        "engDate": "1955-08-14",
        "nepDate": "30.04.2012"
    },
    {
        "engDate": "1955-08-15",
        "nepDate": "31.04.2012"
    },
    {
        "engDate": "1955-08-16",
        "nepDate": "32.04.2012"
    },
    {
        "engDate": "1955-08-17",
        "nepDate": "01.05.2012"
    },
    {
        "engDate": "1955-08-18",
        "nepDate": "02.05.2012"
    },
    {
        "engDate": "1955-08-19",
        "nepDate": "03.05.2012"
    },
    {
        "engDate": "1955-08-20",
        "nepDate": "04.05.2012"
    },
    {
        "engDate": "1955-08-21",
        "nepDate": "05.05.2012"
    },
    {
        "engDate": "1955-08-22",
        "nepDate": "06.05.2012"
    },
    {
        "engDate": "1955-08-23",
        "nepDate": "07.05.2012"
    },
    {
        "engDate": "1955-08-24",
        "nepDate": "08.05.2012"
    },
    {
        "engDate": "1955-08-25",
        "nepDate": "09.05.2012"
    },
    {
        "engDate": "1955-08-26",
        "nepDate": "10.05.2012"
    },
    {
        "engDate": "1955-08-27",
        "nepDate": "11.05.2012"
    },
    {
        "engDate": "1955-08-28",
        "nepDate": "12.05.2012"
    },
    {
        "engDate": "1955-08-29",
        "nepDate": "13.05.2012"
    },
    {
        "engDate": "1955-08-30",
        "nepDate": "14.05.2012"
    },
    {
        "engDate": "1955-08-31",
        "nepDate": "15.05.2012"
    },
    {
        "engDate": "1955-09-01",
        "nepDate": "16.05.2012"
    },
    {
        "engDate": "1955-09-02",
        "nepDate": "17.05.2012"
    },
    {
        "engDate": "1955-09-03",
        "nepDate": "18.05.2012"
    },
    {
        "engDate": "1955-09-04",
        "nepDate": "19.05.2012"
    },
    {
        "engDate": "1955-09-05",
        "nepDate": "20.05.2012"
    },
    {
        "engDate": "1955-09-06",
        "nepDate": "21.05.2012"
    },
    {
        "engDate": "1955-09-07",
        "nepDate": "22.05.2012"
    },
    {
        "engDate": "1955-09-08",
        "nepDate": "23.05.2012"
    },
    {
        "engDate": "1955-09-09",
        "nepDate": "24.05.2012"
    },
    {
        "engDate": "1955-09-10",
        "nepDate": "25.05.2012"
    },
    {
        "engDate": "1955-09-11",
        "nepDate": "26.05.2012"
    },
    {
        "engDate": "1955-09-12",
        "nepDate": "27.05.2012"
    },
    {
        "engDate": "1955-09-13",
        "nepDate": "28.05.2012"
    },
    {
        "engDate": "1955-09-14",
        "nepDate": "29.05.2012"
    },
    {
        "engDate": "1955-09-15",
        "nepDate": "30.05.2012"
    },
    {
        "engDate": "1955-09-16",
        "nepDate": "31.05.2012"
    },
    {
        "engDate": "1955-09-17",
        "nepDate": "01.06.2012"
    },
    {
        "engDate": "1955-09-18",
        "nepDate": "02.06.2012"
    },
    {
        "engDate": "1955-09-19",
        "nepDate": "03.06.2012"
    },
    {
        "engDate": "1955-09-20",
        "nepDate": "04.06.2012"
    },
    {
        "engDate": "1955-09-21",
        "nepDate": "05.06.2012"
    },
    {
        "engDate": "1955-09-22",
        "nepDate": "06.06.2012"
    },
    {
        "engDate": "1955-09-23",
        "nepDate": "07.06.2012"
    },
    {
        "engDate": "1955-09-24",
        "nepDate": "08.06.2012"
    },
    {
        "engDate": "1955-09-25",
        "nepDate": "09.06.2012"
    },
    {
        "engDate": "1955-09-26",
        "nepDate": "10.06.2012"
    },
    {
        "engDate": "1955-09-27",
        "nepDate": "11.06.2012"
    },
    {
        "engDate": "1955-09-28",
        "nepDate": "12.06.2012"
    },
    {
        "engDate": "1955-09-29",
        "nepDate": "13.06.2012"
    },
    {
        "engDate": "1955-09-30",
        "nepDate": "14.06.2012"
    },
    {
        "engDate": "1955-10-01",
        "nepDate": "15.06.2012"
    },
    {
        "engDate": "1955-10-02",
        "nepDate": "16.06.2012"
    },
    {
        "engDate": "1955-10-03",
        "nepDate": "17.06.2012"
    },
    {
        "engDate": "1955-10-04",
        "nepDate": "18.06.2012"
    },
    {
        "engDate": "1955-10-05",
        "nepDate": "19.06.2012"
    },
    {
        "engDate": "1955-10-06",
        "nepDate": "20.06.2012"
    },
    {
        "engDate": "1955-10-07",
        "nepDate": "21.06.2012"
    },
    {
        "engDate": "1955-10-08",
        "nepDate": "22.06.2012"
    },
    {
        "engDate": "1955-10-09",
        "nepDate": "23.06.2012"
    },
    {
        "engDate": "1955-10-10",
        "nepDate": "24.06.2012"
    },
    {
        "engDate": "1955-10-11",
        "nepDate": "25.06.2012"
    },
    {
        "engDate": "1955-10-12",
        "nepDate": "26.06.2012"
    },
    {
        "engDate": "1955-10-13",
        "nepDate": "27.06.2012"
    },
    {
        "engDate": "1955-10-14",
        "nepDate": "28.06.2012"
    },
    {
        "engDate": "1955-10-15",
        "nepDate": "29.06.2012"
    },
    {
        "engDate": "1955-10-16",
        "nepDate": "30.06.2012"
    },
    {
        "engDate": "1955-10-17",
        "nepDate": "31.06.2012"
    },
    {
        "engDate": "1955-10-18",
        "nepDate": "01.07.2012"
    },
    {
        "engDate": "1955-10-19",
        "nepDate": "02.07.2012"
    },
    {
        "engDate": "1955-10-20",
        "nepDate": "03.07.2012"
    },
    {
        "engDate": "1955-10-21",
        "nepDate": "04.07.2012"
    },
    {
        "engDate": "1955-10-22",
        "nepDate": "05.07.2012"
    },
    {
        "engDate": "1955-10-23",
        "nepDate": "06.07.2012"
    },
    {
        "engDate": "1955-10-24",
        "nepDate": "07.07.2012"
    },
    {
        "engDate": "1955-10-25",
        "nepDate": "08.07.2012"
    },
    {
        "engDate": "1955-10-26",
        "nepDate": "09.07.2012"
    },
    {
        "engDate": "1955-10-27",
        "nepDate": "10.07.2012"
    },
    {
        "engDate": "1955-10-28",
        "nepDate": "11.07.2012"
    },
    {
        "engDate": "1955-10-29",
        "nepDate": "12.07.2012"
    },
    {
        "engDate": "1955-10-30",
        "nepDate": "13.07.2012"
    },
    {
        "engDate": "1955-10-31",
        "nepDate": "14.07.2012"
    },
    {
        "engDate": "1955-11-01",
        "nepDate": "15.07.2012"
    },
    {
        "engDate": "1955-11-02",
        "nepDate": "16.07.2012"
    },
    {
        "engDate": "1955-11-03",
        "nepDate": "17.07.2012"
    },
    {
        "engDate": "1955-11-04",
        "nepDate": "18.07.2012"
    },
    {
        "engDate": "1955-11-05",
        "nepDate": "19.07.2012"
    },
    {
        "engDate": "1955-11-06",
        "nepDate": "20.07.2012"
    },
    {
        "engDate": "1955-11-07",
        "nepDate": "21.07.2012"
    },
    {
        "engDate": "1955-11-08",
        "nepDate": "22.07.2012"
    },
    {
        "engDate": "1955-11-09",
        "nepDate": "23.07.2012"
    },
    {
        "engDate": "1955-11-10",
        "nepDate": "24.07.2012"
    },
    {
        "engDate": "1955-11-11",
        "nepDate": "25.07.2012"
    },
    {
        "engDate": "1955-11-12",
        "nepDate": "26.07.2012"
    },
    {
        "engDate": "1955-11-13",
        "nepDate": "27.07.2012"
    },
    {
        "engDate": "1955-11-14",
        "nepDate": "28.07.2012"
    },
    {
        "engDate": "1955-11-15",
        "nepDate": "29.07.2012"
    },
    {
        "engDate": "1955-11-16",
        "nepDate": "01.08.2012"
    },
    {
        "engDate": "1955-11-17",
        "nepDate": "02.08.2012"
    },
    {
        "engDate": "1955-11-18",
        "nepDate": "03.08.2012"
    },
    {
        "engDate": "1955-11-19",
        "nepDate": "04.08.2012"
    },
    {
        "engDate": "1955-11-20",
        "nepDate": "05.08.2012"
    },
    {
        "engDate": "1955-11-21",
        "nepDate": "06.08.2012"
    },
    {
        "engDate": "1955-11-22",
        "nepDate": "07.08.2012"
    },
    {
        "engDate": "1955-11-23",
        "nepDate": "08.08.2012"
    },
    {
        "engDate": "1955-11-24",
        "nepDate": "09.08.2012"
    },
    {
        "engDate": "1955-11-25",
        "nepDate": "10.08.2012"
    },
    {
        "engDate": "1955-11-26",
        "nepDate": "11.08.2012"
    },
    {
        "engDate": "1955-11-27",
        "nepDate": "12.08.2012"
    },
    {
        "engDate": "1955-11-28",
        "nepDate": "13.08.2012"
    },
    {
        "engDate": "1955-11-29",
        "nepDate": "14.08.2012"
    },
    {
        "engDate": "1955-11-30",
        "nepDate": "15.08.2012"
    },
    {
        "engDate": "1955-12-01",
        "nepDate": "16.08.2012"
    },
    {
        "engDate": "1955-12-02",
        "nepDate": "17.08.2012"
    },
    {
        "engDate": "1955-12-03",
        "nepDate": "18.08.2012"
    },
    {
        "engDate": "1955-12-04",
        "nepDate": "19.08.2012"
    },
    {
        "engDate": "1955-12-05",
        "nepDate": "20.08.2012"
    },
    {
        "engDate": "1955-12-06",
        "nepDate": "21.08.2012"
    },
    {
        "engDate": "1955-12-07",
        "nepDate": "22.08.2012"
    },
    {
        "engDate": "1955-12-08",
        "nepDate": "23.08.2012"
    },
    {
        "engDate": "1955-12-09",
        "nepDate": "24.08.2012"
    },
    {
        "engDate": "1955-12-10",
        "nepDate": "25.08.2012"
    },
    {
        "engDate": "1955-12-11",
        "nepDate": "26.08.2012"
    },
    {
        "engDate": "1955-12-12",
        "nepDate": "27.08.2012"
    },
    {
        "engDate": "1955-12-13",
        "nepDate": "28.08.2012"
    },
    {
        "engDate": "1955-12-14",
        "nepDate": "29.08.2012"
    },
    {
        "engDate": "1955-12-15",
        "nepDate": "30.08.2012"
    },
    {
        "engDate": "1955-12-16",
        "nepDate": "01.09.2012"
    },
    {
        "engDate": "1955-12-17",
        "nepDate": "02.09.2012"
    },
    {
        "engDate": "1955-12-18",
        "nepDate": "03.09.2012"
    },
    {
        "engDate": "1955-12-19",
        "nepDate": "04.09.2012"
    },
    {
        "engDate": "1955-12-20",
        "nepDate": "05.09.2012"
    },
    {
        "engDate": "1955-12-21",
        "nepDate": "06.09.2012"
    },
    {
        "engDate": "1955-12-22",
        "nepDate": "07.09.2012"
    },
    {
        "engDate": "1955-12-23",
        "nepDate": "08.09.2012"
    },
    {
        "engDate": "1955-12-24",
        "nepDate": "09.09.2012"
    },
    {
        "engDate": "1955-12-25",
        "nepDate": "10.09.2012"
    },
    {
        "engDate": "1955-12-26",
        "nepDate": "11.09.2012"
    },
    {
        "engDate": "1955-12-27",
        "nepDate": "12.09.2012"
    },
    {
        "engDate": "1955-12-28",
        "nepDate": "13.09.2012"
    },
    {
        "engDate": "1955-12-29",
        "nepDate": "14.09.2012"
    },
    {
        "engDate": "1955-12-30",
        "nepDate": "15.09.2012"
    },
    {
        "engDate": "1955-12-31",
        "nepDate": "16.09.2012"
    },
    {
        "engDate": "1956-01-01",
        "nepDate": "17.09.2012"
    },
    {
        "engDate": "1956-01-02",
        "nepDate": "18.09.2012"
    },
    {
        "engDate": "1956-01-03",
        "nepDate": "19.09.2012"
    },
    {
        "engDate": "1956-01-04",
        "nepDate": "20.09.2012"
    },
    {
        "engDate": "1956-01-05",
        "nepDate": "21.09.2012"
    },
    {
        "engDate": "1956-01-06",
        "nepDate": "22.09.2012"
    },
    {
        "engDate": "1956-01-07",
        "nepDate": "23.09.2012"
    },
    {
        "engDate": "1956-01-08",
        "nepDate": "24.09.2012"
    },
    {
        "engDate": "1956-01-09",
        "nepDate": "25.09.2012"
    },
    {
        "engDate": "1956-01-10",
        "nepDate": "26.09.2012"
    },
    {
        "engDate": "1956-01-11",
        "nepDate": "27.09.2012"
    },
    {
        "engDate": "1956-01-12",
        "nepDate": "28.09.2012"
    },
    {
        "engDate": "1956-01-13",
        "nepDate": "29.09.2012"
    },
    {
        "engDate": "1956-01-14",
        "nepDate": "30.09.2012"
    },
    {
        "engDate": "1956-01-15",
        "nepDate": "01.10.2012"
    },
    {
        "engDate": "1956-01-16",
        "nepDate": "02.10.2012"
    },
    {
        "engDate": "1956-01-17",
        "nepDate": "03.10.2012"
    },
    {
        "engDate": "1956-01-18",
        "nepDate": "04.10.2012"
    },
    {
        "engDate": "1956-01-19",
        "nepDate": "05.10.2012"
    },
    {
        "engDate": "1956-01-20",
        "nepDate": "06.10.2012"
    },
    {
        "engDate": "1956-01-21",
        "nepDate": "07.10.2012"
    },
    {
        "engDate": "1956-01-22",
        "nepDate": "08.10.2012"
    },
    {
        "engDate": "1956-01-23",
        "nepDate": "09.10.2012"
    },
    {
        "engDate": "1956-01-24",
        "nepDate": "10.10.2012"
    },
    {
        "engDate": "1956-01-25",
        "nepDate": "11.10.2012"
    },
    {
        "engDate": "1956-01-26",
        "nepDate": "12.10.2012"
    },
    {
        "engDate": "1956-01-27",
        "nepDate": "13.10.2012"
    },
    {
        "engDate": "1956-01-28",
        "nepDate": "14.10.2012"
    },
    {
        "engDate": "1956-01-29",
        "nepDate": "15.10.2012"
    },
    {
        "engDate": "1956-01-30",
        "nepDate": "16.10.2012"
    },
    {
        "engDate": "1956-01-31",
        "nepDate": "17.10.2012"
    },
    {
        "engDate": "1956-02-01",
        "nepDate": "18.10.2012"
    },
    {
        "engDate": "1956-02-02",
        "nepDate": "19.10.2012"
    },
    {
        "engDate": "1956-02-03",
        "nepDate": "20.10.2012"
    },
    {
        "engDate": "1956-02-04",
        "nepDate": "21.10.2012"
    },
    {
        "engDate": "1956-02-05",
        "nepDate": "22.10.2012"
    },
    {
        "engDate": "1956-02-06",
        "nepDate": "23.10.2012"
    },
    {
        "engDate": "1956-02-07",
        "nepDate": "24.10.2012"
    },
    {
        "engDate": "1956-02-08",
        "nepDate": "25.10.2012"
    },
    {
        "engDate": "1956-02-09",
        "nepDate": "26.10.2012"
    },
    {
        "engDate": "1956-02-10",
        "nepDate": "27.10.2012"
    },
    {
        "engDate": "1956-02-11",
        "nepDate": "28.10.2012"
    },
    {
        "engDate": "1956-02-12",
        "nepDate": "29.10.2012"
    },
    {
        "engDate": "1956-02-13",
        "nepDate": "01.11.2012"
    },
    {
        "engDate": "1956-02-14",
        "nepDate": "02.11.2012"
    },
    {
        "engDate": "1956-02-15",
        "nepDate": "03.11.2012"
    },
    {
        "engDate": "1956-02-16",
        "nepDate": "04.11.2012"
    },
    {
        "engDate": "1956-02-17",
        "nepDate": "05.11.2012"
    },
    {
        "engDate": "1956-02-18",
        "nepDate": "06.11.2012"
    },
    {
        "engDate": "1956-02-19",
        "nepDate": "07.11.2012"
    },
    {
        "engDate": "1956-02-20",
        "nepDate": "08.11.2012"
    },
    {
        "engDate": "1956-02-21",
        "nepDate": "09.11.2012"
    },
    {
        "engDate": "1956-02-22",
        "nepDate": "10.11.2012"
    },
    {
        "engDate": "1956-02-23",
        "nepDate": "11.11.2012"
    },
    {
        "engDate": "1956-02-24",
        "nepDate": "12.11.2012"
    },
    {
        "engDate": "1956-02-25",
        "nepDate": "13.11.2012"
    },
    {
        "engDate": "1956-02-26",
        "nepDate": "14.11.2012"
    },
    {
        "engDate": "1956-02-27",
        "nepDate": "15.11.2012"
    },
    {
        "engDate": "1956-02-28",
        "nepDate": "16.11.2012"
    },
    {
        "engDate": "1956-02-29",
        "nepDate": "17.11.2012"
    },
    {
        "engDate": "1956-03-01",
        "nepDate": "18.11.2012"
    },
    {
        "engDate": "1956-03-02",
        "nepDate": "19.11.2012"
    },
    {
        "engDate": "1956-03-03",
        "nepDate": "20.11.2012"
    },
    {
        "engDate": "1956-03-04",
        "nepDate": "21.11.2012"
    },
    {
        "engDate": "1956-03-05",
        "nepDate": "22.11.2012"
    },
    {
        "engDate": "1956-03-06",
        "nepDate": "23.11.2012"
    },
    {
        "engDate": "1956-03-07",
        "nepDate": "24.11.2012"
    },
    {
        "engDate": "1956-03-08",
        "nepDate": "25.11.2012"
    },
    {
        "engDate": "1956-03-09",
        "nepDate": "26.11.2012"
    },
    {
        "engDate": "1956-03-10",
        "nepDate": "27.11.2012"
    },
    {
        "engDate": "1956-03-11",
        "nepDate": "28.11.2012"
    },
    {
        "engDate": "1956-03-12",
        "nepDate": "29.11.2012"
    },
    {
        "engDate": "1956-03-13",
        "nepDate": "30.11.2012"
    },
    {
        "engDate": "1956-03-14",
        "nepDate": "01.12.2012"
    },
    {
        "engDate": "1956-03-15",
        "nepDate": "02.12.2012"
    },
    {
        "engDate": "1956-03-16",
        "nepDate": "03.12.2012"
    },
    {
        "engDate": "1956-03-17",
        "nepDate": "04.12.2012"
    },
    {
        "engDate": "1956-03-18",
        "nepDate": "05.12.2012"
    },
    {
        "engDate": "1956-03-19",
        "nepDate": "06.12.2012"
    },
    {
        "engDate": "1956-03-20",
        "nepDate": "07.12.2012"
    },
    {
        "engDate": "1956-03-21",
        "nepDate": "08.12.2012"
    },
    {
        "engDate": "1956-03-22",
        "nepDate": "09.12.2012"
    },
    {
        "engDate": "1956-03-23",
        "nepDate": "10.12.2012"
    },
    {
        "engDate": "1956-03-24",
        "nepDate": "11.12.2012"
    },
    {
        "engDate": "1956-03-25",
        "nepDate": "12.12.2012"
    },
    {
        "engDate": "1956-03-26",
        "nepDate": "13.12.2012"
    },
    {
        "engDate": "1956-03-27",
        "nepDate": "14.12.2012"
    },
    {
        "engDate": "1956-03-28",
        "nepDate": "15.12.2012"
    },
    {
        "engDate": "1956-03-29",
        "nepDate": "16.12.2012"
    },
    {
        "engDate": "1956-03-30",
        "nepDate": "17.12.2012"
    },
    {
        "engDate": "1956-03-31",
        "nepDate": "18.12.2012"
    },
    {
        "engDate": "1956-04-01",
        "nepDate": "19.12.2012"
    },
    {
        "engDate": "1956-04-02",
        "nepDate": "20.12.2012"
    },
    {
        "engDate": "1956-04-03",
        "nepDate": "21.12.2012"
    },
    {
        "engDate": "1956-04-04",
        "nepDate": "22.12.2012"
    },
    {
        "engDate": "1956-04-05",
        "nepDate": "23.12.2012"
    },
    {
        "engDate": "1956-04-06",
        "nepDate": "24.12.2012"
    },
    {
        "engDate": "1956-04-07",
        "nepDate": "25.12.2012"
    },
    {
        "engDate": "1956-04-08",
        "nepDate": "26.12.2012"
    },
    {
        "engDate": "1956-04-09",
        "nepDate": "27.12.2012"
    },
    {
        "engDate": "1956-04-10",
        "nepDate": "28.12.2012"
    },
    {
        "engDate": "1956-04-11",
        "nepDate": "29.12.2012"
    },
    {
        "engDate": "1956-04-12",
        "nepDate": "30.12.2012"
    },
    {
        "engDate": "1956-04-13",
        "nepDate": "01.01.2013"
    },
    {
        "engDate": "1956-04-14",
        "nepDate": "02.01.2013"
    },
    {
        "engDate": "1956-04-15",
        "nepDate": "03.01.2013"
    },
    {
        "engDate": "1956-04-16",
        "nepDate": "04.01.2013"
    },
    {
        "engDate": "1956-04-17",
        "nepDate": "05.01.2013"
    },
    {
        "engDate": "1956-04-18",
        "nepDate": "06.01.2013"
    },
    {
        "engDate": "1956-04-19",
        "nepDate": "07.01.2013"
    },
    {
        "engDate": "1956-04-20",
        "nepDate": "08.01.2013"
    },
    {
        "engDate": "1956-04-21",
        "nepDate": "09.01.2013"
    },
    {
        "engDate": "1956-04-22",
        "nepDate": "10.01.2013"
    },
    {
        "engDate": "1956-04-23",
        "nepDate": "11.01.2013"
    },
    {
        "engDate": "1956-04-24",
        "nepDate": "12.01.2013"
    },
    {
        "engDate": "1956-04-25",
        "nepDate": "13.01.2013"
    },
    {
        "engDate": "1956-04-26",
        "nepDate": "14.01.2013"
    },
    {
        "engDate": "1956-04-27",
        "nepDate": "15.01.2013"
    },
    {
        "engDate": "1956-04-28",
        "nepDate": "16.01.2013"
    },
    {
        "engDate": "1956-04-29",
        "nepDate": "17.01.2013"
    },
    {
        "engDate": "1956-04-30",
        "nepDate": "18.01.2013"
    },
    {
        "engDate": "1956-05-01",
        "nepDate": "19.01.2013"
    },
    {
        "engDate": "1956-05-02",
        "nepDate": "20.01.2013"
    },
    {
        "engDate": "1956-05-03",
        "nepDate": "21.01.2013"
    },
    {
        "engDate": "1956-05-04",
        "nepDate": "22.01.2013"
    },
    {
        "engDate": "1956-05-05",
        "nepDate": "23.01.2013"
    },
    {
        "engDate": "1956-05-06",
        "nepDate": "24.01.2013"
    },
    {
        "engDate": "1956-05-07",
        "nepDate": "25.01.2013"
    },
    {
        "engDate": "1956-05-08",
        "nepDate": "26.01.2013"
    },
    {
        "engDate": "1956-05-09",
        "nepDate": "27.01.2013"
    },
    {
        "engDate": "1956-05-10",
        "nepDate": "28.01.2013"
    },
    {
        "engDate": "1956-05-11",
        "nepDate": "29.01.2013"
    },
    {
        "engDate": "1956-05-12",
        "nepDate": "30.01.2013"
    },
    {
        "engDate": "1956-05-13",
        "nepDate": "31.01.2013"
    },
    {
        "engDate": "1956-05-14",
        "nepDate": "01.02.2013"
    },
    {
        "engDate": "1956-05-15",
        "nepDate": "02.02.2013"
    },
    {
        "engDate": "1956-05-16",
        "nepDate": "03.02.2013"
    },
    {
        "engDate": "1956-05-17",
        "nepDate": "04.02.2013"
    },
    {
        "engDate": "1956-05-18",
        "nepDate": "05.02.2013"
    },
    {
        "engDate": "1956-05-19",
        "nepDate": "06.02.2013"
    },
    {
        "engDate": "1956-05-20",
        "nepDate": "07.02.2013"
    },
    {
        "engDate": "1956-05-21",
        "nepDate": "08.02.2013"
    },
    {
        "engDate": "1956-05-22",
        "nepDate": "09.02.2013"
    },
    {
        "engDate": "1956-05-23",
        "nepDate": "10.02.2013"
    },
    {
        "engDate": "1956-05-24",
        "nepDate": "11.02.2013"
    },
    {
        "engDate": "1956-05-25",
        "nepDate": "12.02.2013"
    },
    {
        "engDate": "1956-05-26",
        "nepDate": "13.02.2013"
    },
    {
        "engDate": "1956-05-27",
        "nepDate": "14.02.2013"
    },
    {
        "engDate": "1956-05-28",
        "nepDate": "15.02.2013"
    },
    {
        "engDate": "1956-05-29",
        "nepDate": "16.02.2013"
    },
    {
        "engDate": "1956-05-30",
        "nepDate": "17.02.2013"
    },
    {
        "engDate": "1956-05-31",
        "nepDate": "18.02.2013"
    },
    {
        "engDate": "1956-06-01",
        "nepDate": "19.02.2013"
    },
    {
        "engDate": "1956-06-02",
        "nepDate": "20.02.2013"
    },
    {
        "engDate": "1956-06-03",
        "nepDate": "21.02.2013"
    },
    {
        "engDate": "1956-06-04",
        "nepDate": "22.02.2013"
    },
    {
        "engDate": "1956-06-05",
        "nepDate": "23.02.2013"
    },
    {
        "engDate": "1956-06-06",
        "nepDate": "24.02.2013"
    },
    {
        "engDate": "1956-06-07",
        "nepDate": "25.02.2013"
    },
    {
        "engDate": "1956-06-08",
        "nepDate": "26.02.2013"
    },
    {
        "engDate": "1956-06-09",
        "nepDate": "27.02.2013"
    },
    {
        "engDate": "1956-06-10",
        "nepDate": "28.02.2013"
    },
    {
        "engDate": "1956-06-11",
        "nepDate": "29.02.2013"
    },
    {
        "engDate": "1956-06-12",
        "nepDate": "30.02.2013"
    },
    {
        "engDate": "1956-06-13",
        "nepDate": "31.02.2013"
    },
    {
        "engDate": "1956-06-14",
        "nepDate": "01.03.2013"
    },
    {
        "engDate": "1956-06-15",
        "nepDate": "02.03.2013"
    },
    {
        "engDate": "1956-06-16",
        "nepDate": "03.03.2013"
    },
    {
        "engDate": "1956-06-17",
        "nepDate": "04.03.2013"
    },
    {
        "engDate": "1956-06-18",
        "nepDate": "05.03.2013"
    },
    {
        "engDate": "1956-06-19",
        "nepDate": "06.03.2013"
    },
    {
        "engDate": "1956-06-20",
        "nepDate": "07.03.2013"
    },
    {
        "engDate": "1956-06-21",
        "nepDate": "08.03.2013"
    },
    {
        "engDate": "1956-06-22",
        "nepDate": "09.03.2013"
    },
    {
        "engDate": "1956-06-23",
        "nepDate": "10.03.2013"
    },
    {
        "engDate": "1956-06-24",
        "nepDate": "11.03.2013"
    },
    {
        "engDate": "1956-06-25",
        "nepDate": "12.03.2013"
    },
    {
        "engDate": "1956-06-26",
        "nepDate": "13.03.2013"
    },
    {
        "engDate": "1956-06-27",
        "nepDate": "14.03.2013"
    },
    {
        "engDate": "1956-06-28",
        "nepDate": "15.03.2013"
    },
    {
        "engDate": "1956-06-29",
        "nepDate": "16.03.2013"
    },
    {
        "engDate": "1956-06-30",
        "nepDate": "17.03.2013"
    },
    {
        "engDate": "1956-07-01",
        "nepDate": "18.03.2013"
    },
    {
        "engDate": "1956-07-02",
        "nepDate": "19.03.2013"
    },
    {
        "engDate": "1956-07-03",
        "nepDate": "20.03.2013"
    },
    {
        "engDate": "1956-07-04",
        "nepDate": "21.03.2013"
    },
    {
        "engDate": "1956-07-05",
        "nepDate": "22.03.2013"
    },
    {
        "engDate": "1956-07-06",
        "nepDate": "23.03.2013"
    },
    {
        "engDate": "1956-07-07",
        "nepDate": "24.03.2013"
    },
    {
        "engDate": "1956-07-08",
        "nepDate": "25.03.2013"
    },
    {
        "engDate": "1956-07-09",
        "nepDate": "26.03.2013"
    },
    {
        "engDate": "1956-07-10",
        "nepDate": "27.03.2013"
    },
    {
        "engDate": "1956-07-11",
        "nepDate": "28.03.2013"
    },
    {
        "engDate": "1956-07-12",
        "nepDate": "29.03.2013"
    },
    {
        "engDate": "1956-07-13",
        "nepDate": "30.03.2013"
    },
    {
        "engDate": "1956-07-14",
        "nepDate": "31.03.2013"
    },
    {
        "engDate": "1956-07-15",
        "nepDate": "32.03.2013"
    },
    {
        "engDate": "1956-07-16",
        "nepDate": "01.04.2013"
    },
    {
        "engDate": "1956-07-17",
        "nepDate": "02.04.2013"
    },
    {
        "engDate": "1956-07-18",
        "nepDate": "03.04.2013"
    },
    {
        "engDate": "1956-07-19",
        "nepDate": "04.04.2013"
    },
    {
        "engDate": "1956-07-20",
        "nepDate": "05.04.2013"
    },
    {
        "engDate": "1956-07-21",
        "nepDate": "06.04.2013"
    },
    {
        "engDate": "1956-07-22",
        "nepDate": "07.04.2013"
    },
    {
        "engDate": "1956-07-23",
        "nepDate": "08.04.2013"
    },
    {
        "engDate": "1956-07-24",
        "nepDate": "09.04.2013"
    },
    {
        "engDate": "1956-07-25",
        "nepDate": "10.04.2013"
    },
    {
        "engDate": "1956-07-26",
        "nepDate": "11.04.2013"
    },
    {
        "engDate": "1956-07-27",
        "nepDate": "12.04.2013"
    },
    {
        "engDate": "1956-07-28",
        "nepDate": "13.04.2013"
    },
    {
        "engDate": "1956-07-29",
        "nepDate": "14.04.2013"
    },
    {
        "engDate": "1956-07-30",
        "nepDate": "15.04.2013"
    },
    {
        "engDate": "1956-07-31",
        "nepDate": "16.04.2013"
    },
    {
        "engDate": "1956-08-01",
        "nepDate": "17.04.2013"
    },
    {
        "engDate": "1956-08-02",
        "nepDate": "18.04.2013"
    },
    {
        "engDate": "1956-08-03",
        "nepDate": "19.04.2013"
    },
    {
        "engDate": "1956-08-04",
        "nepDate": "20.04.2013"
    },
    {
        "engDate": "1956-08-05",
        "nepDate": "21.04.2013"
    },
    {
        "engDate": "1956-08-06",
        "nepDate": "22.04.2013"
    },
    {
        "engDate": "1956-08-07",
        "nepDate": "23.04.2013"
    },
    {
        "engDate": "1956-08-08",
        "nepDate": "24.04.2013"
    },
    {
        "engDate": "1956-08-09",
        "nepDate": "25.04.2013"
    },
    {
        "engDate": "1956-08-10",
        "nepDate": "26.04.2013"
    },
    {
        "engDate": "1956-08-11",
        "nepDate": "27.04.2013"
    },
    {
        "engDate": "1956-08-12",
        "nepDate": "28.04.2013"
    },
    {
        "engDate": "1956-08-13",
        "nepDate": "29.04.2013"
    },
    {
        "engDate": "1956-08-14",
        "nepDate": "30.04.2013"
    },
    {
        "engDate": "1956-08-15",
        "nepDate": "31.04.2013"
    },
    {
        "engDate": "1956-08-16",
        "nepDate": "01.05.2013"
    },
    {
        "engDate": "1956-08-17",
        "nepDate": "02.05.2013"
    },
    {
        "engDate": "1956-08-18",
        "nepDate": "03.05.2013"
    },
    {
        "engDate": "1956-08-19",
        "nepDate": "04.05.2013"
    },
    {
        "engDate": "1956-08-20",
        "nepDate": "05.05.2013"
    },
    {
        "engDate": "1956-08-21",
        "nepDate": "06.05.2013"
    },
    {
        "engDate": "1956-08-22",
        "nepDate": "07.05.2013"
    },
    {
        "engDate": "1956-08-23",
        "nepDate": "08.05.2013"
    },
    {
        "engDate": "1956-08-24",
        "nepDate": "09.05.2013"
    },
    {
        "engDate": "1956-08-25",
        "nepDate": "10.05.2013"
    },
    {
        "engDate": "1956-08-26",
        "nepDate": "11.05.2013"
    },
    {
        "engDate": "1956-08-27",
        "nepDate": "12.05.2013"
    },
    {
        "engDate": "1956-08-28",
        "nepDate": "13.05.2013"
    },
    {
        "engDate": "1956-08-29",
        "nepDate": "14.05.2013"
    },
    {
        "engDate": "1956-08-30",
        "nepDate": "15.05.2013"
    },
    {
        "engDate": "1956-08-31",
        "nepDate": "16.05.2013"
    },
    {
        "engDate": "1956-09-01",
        "nepDate": "17.05.2013"
    },
    {
        "engDate": "1956-09-02",
        "nepDate": "18.05.2013"
    },
    {
        "engDate": "1956-09-03",
        "nepDate": "19.05.2013"
    },
    {
        "engDate": "1956-09-04",
        "nepDate": "20.05.2013"
    },
    {
        "engDate": "1956-09-05",
        "nepDate": "21.05.2013"
    },
    {
        "engDate": "1956-09-06",
        "nepDate": "22.05.2013"
    },
    {
        "engDate": "1956-09-07",
        "nepDate": "23.05.2013"
    },
    {
        "engDate": "1956-09-08",
        "nepDate": "24.05.2013"
    },
    {
        "engDate": "1956-09-09",
        "nepDate": "25.05.2013"
    },
    {
        "engDate": "1956-09-10",
        "nepDate": "26.05.2013"
    },
    {
        "engDate": "1956-09-11",
        "nepDate": "27.05.2013"
    },
    {
        "engDate": "1956-09-12",
        "nepDate": "28.05.2013"
    },
    {
        "engDate": "1956-09-13",
        "nepDate": "29.05.2013"
    },
    {
        "engDate": "1956-09-14",
        "nepDate": "30.05.2013"
    },
    {
        "engDate": "1956-09-15",
        "nepDate": "31.05.2013"
    },
    {
        "engDate": "1956-09-16",
        "nepDate": "01.06.2013"
    },
    {
        "engDate": "1956-09-17",
        "nepDate": "02.06.2013"
    },
    {
        "engDate": "1956-09-18",
        "nepDate": "03.06.2013"
    },
    {
        "engDate": "1956-09-19",
        "nepDate": "04.06.2013"
    },
    {
        "engDate": "1956-09-20",
        "nepDate": "05.06.2013"
    },
    {
        "engDate": "1956-09-21",
        "nepDate": "06.06.2013"
    },
    {
        "engDate": "1956-09-22",
        "nepDate": "07.06.2013"
    },
    {
        "engDate": "1956-09-23",
        "nepDate": "08.06.2013"
    },
    {
        "engDate": "1956-09-24",
        "nepDate": "09.06.2013"
    },
    {
        "engDate": "1956-09-25",
        "nepDate": "10.06.2013"
    },
    {
        "engDate": "1956-09-26",
        "nepDate": "11.06.2013"
    },
    {
        "engDate": "1956-09-27",
        "nepDate": "12.06.2013"
    },
    {
        "engDate": "1956-09-28",
        "nepDate": "13.06.2013"
    },
    {
        "engDate": "1956-09-29",
        "nepDate": "14.06.2013"
    },
    {
        "engDate": "1956-09-30",
        "nepDate": "15.06.2013"
    },
    {
        "engDate": "1956-10-01",
        "nepDate": "16.06.2013"
    },
    {
        "engDate": "1956-10-02",
        "nepDate": "17.06.2013"
    },
    {
        "engDate": "1956-10-03",
        "nepDate": "18.06.2013"
    },
    {
        "engDate": "1956-10-04",
        "nepDate": "19.06.2013"
    },
    {
        "engDate": "1956-10-05",
        "nepDate": "20.06.2013"
    },
    {
        "engDate": "1956-10-06",
        "nepDate": "21.06.2013"
    },
    {
        "engDate": "1956-10-07",
        "nepDate": "22.06.2013"
    },
    {
        "engDate": "1956-10-08",
        "nepDate": "23.06.2013"
    },
    {
        "engDate": "1956-10-09",
        "nepDate": "24.06.2013"
    },
    {
        "engDate": "1956-10-10",
        "nepDate": "25.06.2013"
    },
    {
        "engDate": "1956-10-11",
        "nepDate": "26.06.2013"
    },
    {
        "engDate": "1956-10-12",
        "nepDate": "27.06.2013"
    },
    {
        "engDate": "1956-10-13",
        "nepDate": "28.06.2013"
    },
    {
        "engDate": "1956-10-14",
        "nepDate": "29.06.2013"
    },
    {
        "engDate": "1956-10-15",
        "nepDate": "30.06.2013"
    },
    {
        "engDate": "1956-10-16",
        "nepDate": "31.06.2013"
    },
    {
        "engDate": "1956-10-17",
        "nepDate": "01.07.2013"
    },
    {
        "engDate": "1956-10-18",
        "nepDate": "02.07.2013"
    },
    {
        "engDate": "1956-10-19",
        "nepDate": "03.07.2013"
    },
    {
        "engDate": "1956-10-20",
        "nepDate": "04.07.2013"
    },
    {
        "engDate": "1956-10-21",
        "nepDate": "05.07.2013"
    },
    {
        "engDate": "1956-10-22",
        "nepDate": "06.07.2013"
    },
    {
        "engDate": "1956-10-23",
        "nepDate": "07.07.2013"
    },
    {
        "engDate": "1956-10-24",
        "nepDate": "08.07.2013"
    },
    {
        "engDate": "1956-10-25",
        "nepDate": "09.07.2013"
    },
    {
        "engDate": "1956-10-26",
        "nepDate": "10.07.2013"
    },
    {
        "engDate": "1956-10-27",
        "nepDate": "11.07.2013"
    },
    {
        "engDate": "1956-10-28",
        "nepDate": "12.07.2013"
    },
    {
        "engDate": "1956-10-29",
        "nepDate": "13.07.2013"
    },
    {
        "engDate": "1956-10-30",
        "nepDate": "14.07.2013"
    },
    {
        "engDate": "1956-10-31",
        "nepDate": "15.07.2013"
    },
    {
        "engDate": "1956-11-01",
        "nepDate": "16.07.2013"
    },
    {
        "engDate": "1956-11-02",
        "nepDate": "17.07.2013"
    },
    {
        "engDate": "1956-11-03",
        "nepDate": "18.07.2013"
    },
    {
        "engDate": "1956-11-04",
        "nepDate": "19.07.2013"
    },
    {
        "engDate": "1956-11-05",
        "nepDate": "20.07.2013"
    },
    {
        "engDate": "1956-11-06",
        "nepDate": "21.07.2013"
    },
    {
        "engDate": "1956-11-07",
        "nepDate": "22.07.2013"
    },
    {
        "engDate": "1956-11-08",
        "nepDate": "23.07.2013"
    },
    {
        "engDate": "1956-11-09",
        "nepDate": "24.07.2013"
    },
    {
        "engDate": "1956-11-10",
        "nepDate": "25.07.2013"
    },
    {
        "engDate": "1956-11-11",
        "nepDate": "26.07.2013"
    },
    {
        "engDate": "1956-11-12",
        "nepDate": "27.07.2013"
    },
    {
        "engDate": "1956-11-13",
        "nepDate": "28.07.2013"
    },
    {
        "engDate": "1956-11-14",
        "nepDate": "29.07.2013"
    },
    {
        "engDate": "1956-11-15",
        "nepDate": "30.07.2013"
    },
    {
        "engDate": "1956-11-16",
        "nepDate": "01.08.2013"
    },
    {
        "engDate": "1956-11-17",
        "nepDate": "02.08.2013"
    },
    {
        "engDate": "1956-11-18",
        "nepDate": "03.08.2013"
    },
    {
        "engDate": "1956-11-19",
        "nepDate": "04.08.2013"
    },
    {
        "engDate": "1956-11-20",
        "nepDate": "05.08.2013"
    },
    {
        "engDate": "1956-11-21",
        "nepDate": "06.08.2013"
    },
    {
        "engDate": "1956-11-22",
        "nepDate": "07.08.2013"
    },
    {
        "engDate": "1956-11-23",
        "nepDate": "08.08.2013"
    },
    {
        "engDate": "1956-11-24",
        "nepDate": "09.08.2013"
    },
    {
        "engDate": "1956-11-25",
        "nepDate": "10.08.2013"
    },
    {
        "engDate": "1956-11-26",
        "nepDate": "11.08.2013"
    },
    {
        "engDate": "1956-11-27",
        "nepDate": "12.08.2013"
    },
    {
        "engDate": "1956-11-28",
        "nepDate": "13.08.2013"
    },
    {
        "engDate": "1956-11-29",
        "nepDate": "14.08.2013"
    },
    {
        "engDate": "1956-11-30",
        "nepDate": "15.08.2013"
    },
    {
        "engDate": "1956-12-01",
        "nepDate": "16.08.2013"
    },
    {
        "engDate": "1956-12-02",
        "nepDate": "17.08.2013"
    },
    {
        "engDate": "1956-12-03",
        "nepDate": "18.08.2013"
    },
    {
        "engDate": "1956-12-04",
        "nepDate": "19.08.2013"
    },
    {
        "engDate": "1956-12-05",
        "nepDate": "20.08.2013"
    },
    {
        "engDate": "1956-12-06",
        "nepDate": "21.08.2013"
    },
    {
        "engDate": "1956-12-07",
        "nepDate": "22.08.2013"
    },
    {
        "engDate": "1956-12-08",
        "nepDate": "23.08.2013"
    },
    {
        "engDate": "1956-12-09",
        "nepDate": "24.08.2013"
    },
    {
        "engDate": "1956-12-10",
        "nepDate": "25.08.2013"
    },
    {
        "engDate": "1956-12-11",
        "nepDate": "26.08.2013"
    },
    {
        "engDate": "1956-12-12",
        "nepDate": "27.08.2013"
    },
    {
        "engDate": "1956-12-13",
        "nepDate": "28.08.2013"
    },
    {
        "engDate": "1956-12-14",
        "nepDate": "29.08.2013"
    },
    {
        "engDate": "1956-12-15",
        "nepDate": "01.09.2013"
    },
    {
        "engDate": "1956-12-16",
        "nepDate": "02.09.2013"
    },
    {
        "engDate": "1956-12-17",
        "nepDate": "03.09.2013"
    },
    {
        "engDate": "1956-12-18",
        "nepDate": "04.09.2013"
    },
    {
        "engDate": "1956-12-19",
        "nepDate": "05.09.2013"
    },
    {
        "engDate": "1956-12-20",
        "nepDate": "06.09.2013"
    },
    {
        "engDate": "1956-12-21",
        "nepDate": "07.09.2013"
    },
    {
        "engDate": "1956-12-22",
        "nepDate": "08.09.2013"
    },
    {
        "engDate": "1956-12-23",
        "nepDate": "09.09.2013"
    },
    {
        "engDate": "1956-12-24",
        "nepDate": "10.09.2013"
    },
    {
        "engDate": "1956-12-25",
        "nepDate": "11.09.2013"
    },
    {
        "engDate": "1956-12-26",
        "nepDate": "12.09.2013"
    },
    {
        "engDate": "1956-12-27",
        "nepDate": "13.09.2013"
    },
    {
        "engDate": "1956-12-28",
        "nepDate": "14.09.2013"
    },
    {
        "engDate": "1956-12-29",
        "nepDate": "15.09.2013"
    },
    {
        "engDate": "1956-12-30",
        "nepDate": "16.09.2013"
    },
    {
        "engDate": "1956-12-31",
        "nepDate": "17.09.2013"
    },
    {
        "engDate": "1957-01-01",
        "nepDate": "18.09.2013"
    },
    {
        "engDate": "1957-01-02",
        "nepDate": "19.09.2013"
    },
    {
        "engDate": "1957-01-03",
        "nepDate": "20.09.2013"
    },
    {
        "engDate": "1957-01-04",
        "nepDate": "21.09.2013"
    },
    {
        "engDate": "1957-01-05",
        "nepDate": "22.09.2013"
    },
    {
        "engDate": "1957-01-06",
        "nepDate": "23.09.2013"
    },
    {
        "engDate": "1957-01-07",
        "nepDate": "24.09.2013"
    },
    {
        "engDate": "1957-01-08",
        "nepDate": "25.09.2013"
    },
    {
        "engDate": "1957-01-09",
        "nepDate": "26.09.2013"
    },
    {
        "engDate": "1957-01-10",
        "nepDate": "27.09.2013"
    },
    {
        "engDate": "1957-01-11",
        "nepDate": "28.09.2013"
    },
    {
        "engDate": "1957-01-12",
        "nepDate": "29.09.2013"
    },
    {
        "engDate": "1957-01-13",
        "nepDate": "30.09.2013"
    },
    {
        "engDate": "1957-01-14",
        "nepDate": "01.10.2013"
    },
    {
        "engDate": "1957-01-15",
        "nepDate": "02.10.2013"
    },
    {
        "engDate": "1957-01-16",
        "nepDate": "03.10.2013"
    },
    {
        "engDate": "1957-01-17",
        "nepDate": "04.10.2013"
    },
    {
        "engDate": "1957-01-18",
        "nepDate": "05.10.2013"
    },
    {
        "engDate": "1957-01-19",
        "nepDate": "06.10.2013"
    },
    {
        "engDate": "1957-01-20",
        "nepDate": "07.10.2013"
    },
    {
        "engDate": "1957-01-21",
        "nepDate": "08.10.2013"
    },
    {
        "engDate": "1957-01-22",
        "nepDate": "09.10.2013"
    },
    {
        "engDate": "1957-01-23",
        "nepDate": "10.10.2013"
    },
    {
        "engDate": "1957-01-24",
        "nepDate": "11.10.2013"
    },
    {
        "engDate": "1957-01-25",
        "nepDate": "12.10.2013"
    },
    {
        "engDate": "1957-01-26",
        "nepDate": "13.10.2013"
    },
    {
        "engDate": "1957-01-27",
        "nepDate": "14.10.2013"
    },
    {
        "engDate": "1957-01-28",
        "nepDate": "15.10.2013"
    },
    {
        "engDate": "1957-01-29",
        "nepDate": "16.10.2013"
    },
    {
        "engDate": "1957-01-30",
        "nepDate": "17.10.2013"
    },
    {
        "engDate": "1957-01-31",
        "nepDate": "18.10.2013"
    },
    {
        "engDate": "1957-02-01",
        "nepDate": "19.10.2013"
    },
    {
        "engDate": "1957-02-02",
        "nepDate": "20.10.2013"
    },
    {
        "engDate": "1957-02-03",
        "nepDate": "21.10.2013"
    },
    {
        "engDate": "1957-02-04",
        "nepDate": "22.10.2013"
    },
    {
        "engDate": "1957-02-05",
        "nepDate": "23.10.2013"
    },
    {
        "engDate": "1957-02-06",
        "nepDate": "24.10.2013"
    },
    {
        "engDate": "1957-02-07",
        "nepDate": "25.10.2013"
    },
    {
        "engDate": "1957-02-08",
        "nepDate": "26.10.2013"
    },
    {
        "engDate": "1957-02-09",
        "nepDate": "27.10.2013"
    },
    {
        "engDate": "1957-02-10",
        "nepDate": "28.10.2013"
    },
    {
        "engDate": "1957-02-11",
        "nepDate": "29.10.2013"
    },
    {
        "engDate": "1957-02-12",
        "nepDate": "01.11.2013"
    },
    {
        "engDate": "1957-02-13",
        "nepDate": "02.11.2013"
    },
    {
        "engDate": "1957-02-14",
        "nepDate": "03.11.2013"
    },
    {
        "engDate": "1957-02-15",
        "nepDate": "04.11.2013"
    },
    {
        "engDate": "1957-02-16",
        "nepDate": "05.11.2013"
    },
    {
        "engDate": "1957-02-17",
        "nepDate": "06.11.2013"
    },
    {
        "engDate": "1957-02-18",
        "nepDate": "07.11.2013"
    },
    {
        "engDate": "1957-02-19",
        "nepDate": "08.11.2013"
    },
    {
        "engDate": "1957-02-20",
        "nepDate": "09.11.2013"
    },
    {
        "engDate": "1957-02-21",
        "nepDate": "10.11.2013"
    },
    {
        "engDate": "1957-02-22",
        "nepDate": "11.11.2013"
    },
    {
        "engDate": "1957-02-23",
        "nepDate": "12.11.2013"
    },
    {
        "engDate": "1957-02-24",
        "nepDate": "13.11.2013"
    },
    {
        "engDate": "1957-02-25",
        "nepDate": "14.11.2013"
    },
    {
        "engDate": "1957-02-26",
        "nepDate": "15.11.2013"
    },
    {
        "engDate": "1957-02-27",
        "nepDate": "16.11.2013"
    },
    {
        "engDate": "1957-02-28",
        "nepDate": "17.11.2013"
    },
    {
        "engDate": "1957-03-01",
        "nepDate": "18.11.2013"
    },
    {
        "engDate": "1957-03-02",
        "nepDate": "19.11.2013"
    },
    {
        "engDate": "1957-03-03",
        "nepDate": "20.11.2013"
    },
    {
        "engDate": "1957-03-04",
        "nepDate": "21.11.2013"
    },
    {
        "engDate": "1957-03-05",
        "nepDate": "22.11.2013"
    },
    {
        "engDate": "1957-03-06",
        "nepDate": "23.11.2013"
    },
    {
        "engDate": "1957-03-07",
        "nepDate": "24.11.2013"
    },
    {
        "engDate": "1957-03-08",
        "nepDate": "25.11.2013"
    },
    {
        "engDate": "1957-03-09",
        "nepDate": "26.11.2013"
    },
    {
        "engDate": "1957-03-10",
        "nepDate": "27.11.2013"
    },
    {
        "engDate": "1957-03-11",
        "nepDate": "28.11.2013"
    },
    {
        "engDate": "1957-03-12",
        "nepDate": "29.11.2013"
    },
    {
        "engDate": "1957-03-13",
        "nepDate": "30.11.2013"
    },
    {
        "engDate": "1957-03-14",
        "nepDate": "01.12.2013"
    },
    {
        "engDate": "1957-03-15",
        "nepDate": "02.12.2013"
    },
    {
        "engDate": "1957-03-16",
        "nepDate": "03.12.2013"
    },
    {
        "engDate": "1957-03-17",
        "nepDate": "04.12.2013"
    },
    {
        "engDate": "1957-03-18",
        "nepDate": "05.12.2013"
    },
    {
        "engDate": "1957-03-19",
        "nepDate": "06.12.2013"
    },
    {
        "engDate": "1957-03-20",
        "nepDate": "07.12.2013"
    },
    {
        "engDate": "1957-03-21",
        "nepDate": "08.12.2013"
    },
    {
        "engDate": "1957-03-22",
        "nepDate": "09.12.2013"
    },
    {
        "engDate": "1957-03-23",
        "nepDate": "10.12.2013"
    },
    {
        "engDate": "1957-03-24",
        "nepDate": "11.12.2013"
    },
    {
        "engDate": "1957-03-25",
        "nepDate": "12.12.2013"
    },
    {
        "engDate": "1957-03-26",
        "nepDate": "13.12.2013"
    },
    {
        "engDate": "1957-03-27",
        "nepDate": "14.12.2013"
    },
    {
        "engDate": "1957-03-28",
        "nepDate": "15.12.2013"
    },
    {
        "engDate": "1957-03-29",
        "nepDate": "16.12.2013"
    },
    {
        "engDate": "1957-03-30",
        "nepDate": "17.12.2013"
    },
    {
        "engDate": "1957-03-31",
        "nepDate": "18.12.2013"
    },
    {
        "engDate": "1957-04-01",
        "nepDate": "19.12.2013"
    },
    {
        "engDate": "1957-04-02",
        "nepDate": "20.12.2013"
    },
    {
        "engDate": "1957-04-03",
        "nepDate": "21.12.2013"
    },
    {
        "engDate": "1957-04-04",
        "nepDate": "22.12.2013"
    },
    {
        "engDate": "1957-04-05",
        "nepDate": "23.12.2013"
    },
    {
        "engDate": "1957-04-06",
        "nepDate": "24.12.2013"
    },
    {
        "engDate": "1957-04-07",
        "nepDate": "25.12.2013"
    },
    {
        "engDate": "1957-04-08",
        "nepDate": "26.12.2013"
    },
    {
        "engDate": "1957-04-09",
        "nepDate": "27.12.2013"
    },
    {
        "engDate": "1957-04-10",
        "nepDate": "28.12.2013"
    },
    {
        "engDate": "1957-04-11",
        "nepDate": "29.12.2013"
    },
    {
        "engDate": "1957-04-12",
        "nepDate": "30.12.2013"
    },
    {
        "engDate": "1957-04-13",
        "nepDate": "01.01.2014"
    },
    {
        "engDate": "1957-04-14",
        "nepDate": "02.01.2014"
    },
    {
        "engDate": "1957-04-15",
        "nepDate": "03.01.2014"
    },
    {
        "engDate": "1957-04-16",
        "nepDate": "04.01.2014"
    },
    {
        "engDate": "1957-04-17",
        "nepDate": "05.01.2014"
    },
    {
        "engDate": "1957-04-18",
        "nepDate": "06.01.2014"
    },
    {
        "engDate": "1957-04-19",
        "nepDate": "07.01.2014"
    },
    {
        "engDate": "1957-04-20",
        "nepDate": "08.01.2014"
    },
    {
        "engDate": "1957-04-21",
        "nepDate": "09.01.2014"
    },
    {
        "engDate": "1957-04-22",
        "nepDate": "10.01.2014"
    },
    {
        "engDate": "1957-04-23",
        "nepDate": "11.01.2014"
    },
    {
        "engDate": "1957-04-24",
        "nepDate": "12.01.2014"
    },
    {
        "engDate": "1957-04-25",
        "nepDate": "13.01.2014"
    },
    {
        "engDate": "1957-04-26",
        "nepDate": "14.01.2014"
    },
    {
        "engDate": "1957-04-27",
        "nepDate": "15.01.2014"
    },
    {
        "engDate": "1957-04-28",
        "nepDate": "16.01.2014"
    },
    {
        "engDate": "1957-04-29",
        "nepDate": "17.01.2014"
    },
    {
        "engDate": "1957-04-30",
        "nepDate": "18.01.2014"
    },
    {
        "engDate": "1957-05-01",
        "nepDate": "19.01.2014"
    },
    {
        "engDate": "1957-05-02",
        "nepDate": "20.01.2014"
    },
    {
        "engDate": "1957-05-03",
        "nepDate": "21.01.2014"
    },
    {
        "engDate": "1957-05-04",
        "nepDate": "22.01.2014"
    },
    {
        "engDate": "1957-05-05",
        "nepDate": "23.01.2014"
    },
    {
        "engDate": "1957-05-06",
        "nepDate": "24.01.2014"
    },
    {
        "engDate": "1957-05-07",
        "nepDate": "25.01.2014"
    },
    {
        "engDate": "1957-05-08",
        "nepDate": "26.01.2014"
    },
    {
        "engDate": "1957-05-09",
        "nepDate": "27.01.2014"
    },
    {
        "engDate": "1957-05-10",
        "nepDate": "28.01.2014"
    },
    {
        "engDate": "1957-05-11",
        "nepDate": "29.01.2014"
    },
    {
        "engDate": "1957-05-12",
        "nepDate": "30.01.2014"
    },
    {
        "engDate": "1957-05-13",
        "nepDate": "31.01.2014"
    },
    {
        "engDate": "1957-05-14",
        "nepDate": "01.02.2014"
    },
    {
        "engDate": "1957-05-15",
        "nepDate": "02.02.2014"
    },
    {
        "engDate": "1957-05-16",
        "nepDate": "03.02.2014"
    },
    {
        "engDate": "1957-05-17",
        "nepDate": "04.02.2014"
    },
    {
        "engDate": "1957-05-18",
        "nepDate": "05.02.2014"
    },
    {
        "engDate": "1957-05-19",
        "nepDate": "06.02.2014"
    },
    {
        "engDate": "1957-05-20",
        "nepDate": "07.02.2014"
    },
    {
        "engDate": "1957-05-21",
        "nepDate": "08.02.2014"
    },
    {
        "engDate": "1957-05-22",
        "nepDate": "09.02.2014"
    },
    {
        "engDate": "1957-05-23",
        "nepDate": "10.02.2014"
    },
    {
        "engDate": "1957-05-24",
        "nepDate": "11.02.2014"
    },
    {
        "engDate": "1957-05-25",
        "nepDate": "12.02.2014"
    },
    {
        "engDate": "1957-05-26",
        "nepDate": "13.02.2014"
    },
    {
        "engDate": "1957-05-27",
        "nepDate": "14.02.2014"
    },
    {
        "engDate": "1957-05-28",
        "nepDate": "15.02.2014"
    },
    {
        "engDate": "1957-05-29",
        "nepDate": "16.02.2014"
    },
    {
        "engDate": "1957-05-30",
        "nepDate": "17.02.2014"
    },
    {
        "engDate": "1957-05-31",
        "nepDate": "18.02.2014"
    },
    {
        "engDate": "1957-06-01",
        "nepDate": "19.02.2014"
    },
    {
        "engDate": "1957-06-02",
        "nepDate": "20.02.2014"
    },
    {
        "engDate": "1957-06-03",
        "nepDate": "21.02.2014"
    },
    {
        "engDate": "1957-06-04",
        "nepDate": "22.02.2014"
    },
    {
        "engDate": "1957-06-05",
        "nepDate": "23.02.2014"
    },
    {
        "engDate": "1957-06-06",
        "nepDate": "24.02.2014"
    },
    {
        "engDate": "1957-06-07",
        "nepDate": "25.02.2014"
    },
    {
        "engDate": "1957-06-08",
        "nepDate": "26.02.2014"
    },
    {
        "engDate": "1957-06-09",
        "nepDate": "27.02.2014"
    },
    {
        "engDate": "1957-06-10",
        "nepDate": "28.02.2014"
    },
    {
        "engDate": "1957-06-11",
        "nepDate": "29.02.2014"
    },
    {
        "engDate": "1957-06-12",
        "nepDate": "30.02.2014"
    },
    {
        "engDate": "1957-06-13",
        "nepDate": "31.02.2014"
    },
    {
        "engDate": "1957-06-14",
        "nepDate": "01.03.2014"
    },
    {
        "engDate": "1957-06-15",
        "nepDate": "02.03.2014"
    },
    {
        "engDate": "1957-06-16",
        "nepDate": "03.03.2014"
    },
    {
        "engDate": "1957-06-17",
        "nepDate": "04.03.2014"
    },
    {
        "engDate": "1957-06-18",
        "nepDate": "05.03.2014"
    },
    {
        "engDate": "1957-06-19",
        "nepDate": "06.03.2014"
    },
    {
        "engDate": "1957-06-20",
        "nepDate": "07.03.2014"
    },
    {
        "engDate": "1957-06-21",
        "nepDate": "08.03.2014"
    },
    {
        "engDate": "1957-06-22",
        "nepDate": "09.03.2014"
    },
    {
        "engDate": "1957-06-23",
        "nepDate": "10.03.2014"
    },
    {
        "engDate": "1957-06-24",
        "nepDate": "11.03.2014"
    },
    {
        "engDate": "1957-06-25",
        "nepDate": "12.03.2014"
    },
    {
        "engDate": "1957-06-26",
        "nepDate": "13.03.2014"
    },
    {
        "engDate": "1957-06-27",
        "nepDate": "14.03.2014"
    },
    {
        "engDate": "1957-06-28",
        "nepDate": "15.03.2014"
    },
    {
        "engDate": "1957-06-29",
        "nepDate": "16.03.2014"
    },
    {
        "engDate": "1957-06-30",
        "nepDate": "17.03.2014"
    },
    {
        "engDate": "1957-07-01",
        "nepDate": "18.03.2014"
    },
    {
        "engDate": "1957-07-02",
        "nepDate": "19.03.2014"
    },
    {
        "engDate": "1957-07-03",
        "nepDate": "20.03.2014"
    },
    {
        "engDate": "1957-07-04",
        "nepDate": "21.03.2014"
    },
    {
        "engDate": "1957-07-05",
        "nepDate": "22.03.2014"
    },
    {
        "engDate": "1957-07-06",
        "nepDate": "23.03.2014"
    },
    {
        "engDate": "1957-07-07",
        "nepDate": "24.03.2014"
    },
    {
        "engDate": "1957-07-08",
        "nepDate": "25.03.2014"
    },
    {
        "engDate": "1957-07-09",
        "nepDate": "26.03.2014"
    },
    {
        "engDate": "1957-07-10",
        "nepDate": "27.03.2014"
    },
    {
        "engDate": "1957-07-11",
        "nepDate": "28.03.2014"
    },
    {
        "engDate": "1957-07-12",
        "nepDate": "29.03.2014"
    },
    {
        "engDate": "1957-07-13",
        "nepDate": "30.03.2014"
    },
    {
        "engDate": "1957-07-14",
        "nepDate": "31.03.2014"
    },
    {
        "engDate": "1957-07-15",
        "nepDate": "32.03.2014"
    },
    {
        "engDate": "1957-07-16",
        "nepDate": "01.04.2014"
    },
    {
        "engDate": "1957-07-17",
        "nepDate": "02.04.2014"
    },
    {
        "engDate": "1957-07-18",
        "nepDate": "03.04.2014"
    },
    {
        "engDate": "1957-07-19",
        "nepDate": "04.04.2014"
    },
    {
        "engDate": "1957-07-20",
        "nepDate": "05.04.2014"
    },
    {
        "engDate": "1957-07-21",
        "nepDate": "06.04.2014"
    },
    {
        "engDate": "1957-07-22",
        "nepDate": "07.04.2014"
    },
    {
        "engDate": "1957-07-23",
        "nepDate": "08.04.2014"
    },
    {
        "engDate": "1957-07-24",
        "nepDate": "09.04.2014"
    },
    {
        "engDate": "1957-07-25",
        "nepDate": "10.04.2014"
    },
    {
        "engDate": "1957-07-26",
        "nepDate": "11.04.2014"
    },
    {
        "engDate": "1957-07-27",
        "nepDate": "12.04.2014"
    },
    {
        "engDate": "1957-07-28",
        "nepDate": "13.04.2014"
    },
    {
        "engDate": "1957-07-29",
        "nepDate": "14.04.2014"
    },
    {
        "engDate": "1957-07-30",
        "nepDate": "15.04.2014"
    },
    {
        "engDate": "1957-07-31",
        "nepDate": "16.04.2014"
    },
    {
        "engDate": "1957-08-01",
        "nepDate": "17.04.2014"
    },
    {
        "engDate": "1957-08-02",
        "nepDate": "18.04.2014"
    },
    {
        "engDate": "1957-08-03",
        "nepDate": "19.04.2014"
    },
    {
        "engDate": "1957-08-04",
        "nepDate": "20.04.2014"
    },
    {
        "engDate": "1957-08-05",
        "nepDate": "21.04.2014"
    },
    {
        "engDate": "1957-08-06",
        "nepDate": "22.04.2014"
    },
    {
        "engDate": "1957-08-07",
        "nepDate": "23.04.2014"
    },
    {
        "engDate": "1957-08-08",
        "nepDate": "24.04.2014"
    },
    {
        "engDate": "1957-08-09",
        "nepDate": "25.04.2014"
    },
    {
        "engDate": "1957-08-10",
        "nepDate": "26.04.2014"
    },
    {
        "engDate": "1957-08-11",
        "nepDate": "27.04.2014"
    },
    {
        "engDate": "1957-08-12",
        "nepDate": "28.04.2014"
    },
    {
        "engDate": "1957-08-13",
        "nepDate": "29.04.2014"
    },
    {
        "engDate": "1957-08-14",
        "nepDate": "30.04.2014"
    },
    {
        "engDate": "1957-08-15",
        "nepDate": "31.04.2014"
    },
    {
        "engDate": "1957-08-16",
        "nepDate": "32.04.2014"
    },
    {
        "engDate": "1957-08-17",
        "nepDate": "01.05.2014"
    },
    {
        "engDate": "1957-08-18",
        "nepDate": "02.05.2014"
    },
    {
        "engDate": "1957-08-19",
        "nepDate": "03.05.2014"
    },
    {
        "engDate": "1957-08-20",
        "nepDate": "04.05.2014"
    },
    {
        "engDate": "1957-08-21",
        "nepDate": "05.05.2014"
    },
    {
        "engDate": "1957-08-22",
        "nepDate": "06.05.2014"
    },
    {
        "engDate": "1957-08-23",
        "nepDate": "07.05.2014"
    },
    {
        "engDate": "1957-08-24",
        "nepDate": "08.05.2014"
    },
    {
        "engDate": "1957-08-25",
        "nepDate": "09.05.2014"
    },
    {
        "engDate": "1957-08-26",
        "nepDate": "10.05.2014"
    },
    {
        "engDate": "1957-08-27",
        "nepDate": "11.05.2014"
    },
    {
        "engDate": "1957-08-28",
        "nepDate": "12.05.2014"
    },
    {
        "engDate": "1957-08-29",
        "nepDate": "13.05.2014"
    },
    {
        "engDate": "1957-08-30",
        "nepDate": "14.05.2014"
    },
    {
        "engDate": "1957-08-31",
        "nepDate": "15.05.2014"
    },
    {
        "engDate": "1957-09-01",
        "nepDate": "16.05.2014"
    },
    {
        "engDate": "1957-09-02",
        "nepDate": "17.05.2014"
    },
    {
        "engDate": "1957-09-03",
        "nepDate": "18.05.2014"
    },
    {
        "engDate": "1957-09-04",
        "nepDate": "19.05.2014"
    },
    {
        "engDate": "1957-09-05",
        "nepDate": "20.05.2014"
    },
    {
        "engDate": "1957-09-06",
        "nepDate": "21.05.2014"
    },
    {
        "engDate": "1957-09-07",
        "nepDate": "22.05.2014"
    },
    {
        "engDate": "1957-09-08",
        "nepDate": "23.05.2014"
    },
    {
        "engDate": "1957-09-09",
        "nepDate": "24.05.2014"
    },
    {
        "engDate": "1957-09-10",
        "nepDate": "25.05.2014"
    },
    {
        "engDate": "1957-09-11",
        "nepDate": "26.05.2014"
    },
    {
        "engDate": "1957-09-12",
        "nepDate": "27.05.2014"
    },
    {
        "engDate": "1957-09-13",
        "nepDate": "28.05.2014"
    },
    {
        "engDate": "1957-09-14",
        "nepDate": "29.05.2014"
    },
    {
        "engDate": "1957-09-15",
        "nepDate": "30.05.2014"
    },
    {
        "engDate": "1957-09-16",
        "nepDate": "31.05.2014"
    },
    {
        "engDate": "1957-09-17",
        "nepDate": "01.06.2014"
    },
    {
        "engDate": "1957-09-18",
        "nepDate": "02.06.2014"
    },
    {
        "engDate": "1957-09-19",
        "nepDate": "03.06.2014"
    },
    {
        "engDate": "1957-09-20",
        "nepDate": "04.06.2014"
    },
    {
        "engDate": "1957-09-21",
        "nepDate": "05.06.2014"
    },
    {
        "engDate": "1957-09-22",
        "nepDate": "06.06.2014"
    },
    {
        "engDate": "1957-09-23",
        "nepDate": "07.06.2014"
    },
    {
        "engDate": "1957-09-24",
        "nepDate": "08.06.2014"
    },
    {
        "engDate": "1957-09-25",
        "nepDate": "09.06.2014"
    },
    {
        "engDate": "1957-09-26",
        "nepDate": "10.06.2014"
    },
    {
        "engDate": "1957-09-27",
        "nepDate": "11.06.2014"
    },
    {
        "engDate": "1957-09-28",
        "nepDate": "12.06.2014"
    },
    {
        "engDate": "1957-09-29",
        "nepDate": "13.06.2014"
    },
    {
        "engDate": "1957-09-30",
        "nepDate": "14.06.2014"
    },
    {
        "engDate": "1957-10-01",
        "nepDate": "15.06.2014"
    },
    {
        "engDate": "1957-10-02",
        "nepDate": "16.06.2014"
    },
    {
        "engDate": "1957-10-03",
        "nepDate": "17.06.2014"
    },
    {
        "engDate": "1957-10-04",
        "nepDate": "18.06.2014"
    },
    {
        "engDate": "1957-10-05",
        "nepDate": "19.06.2014"
    },
    {
        "engDate": "1957-10-06",
        "nepDate": "20.06.2014"
    },
    {
        "engDate": "1957-10-07",
        "nepDate": "21.06.2014"
    },
    {
        "engDate": "1957-10-08",
        "nepDate": "22.06.2014"
    },
    {
        "engDate": "1957-10-09",
        "nepDate": "23.06.2014"
    },
    {
        "engDate": "1957-10-10",
        "nepDate": "24.06.2014"
    },
    {
        "engDate": "1957-10-11",
        "nepDate": "25.06.2014"
    },
    {
        "engDate": "1957-10-12",
        "nepDate": "26.06.2014"
    },
    {
        "engDate": "1957-10-13",
        "nepDate": "27.06.2014"
    },
    {
        "engDate": "1957-10-14",
        "nepDate": "28.06.2014"
    },
    {
        "engDate": "1957-10-15",
        "nepDate": "29.06.2014"
    },
    {
        "engDate": "1957-10-16",
        "nepDate": "30.06.2014"
    },
    {
        "engDate": "1957-10-17",
        "nepDate": "01.07.2014"
    },
    {
        "engDate": "1957-10-18",
        "nepDate": "02.07.2014"
    },
    {
        "engDate": "1957-10-19",
        "nepDate": "03.07.2014"
    },
    {
        "engDate": "1957-10-20",
        "nepDate": "04.07.2014"
    },
    {
        "engDate": "1957-10-21",
        "nepDate": "05.07.2014"
    },
    {
        "engDate": "1957-10-22",
        "nepDate": "06.07.2014"
    },
    {
        "engDate": "1957-10-23",
        "nepDate": "07.07.2014"
    },
    {
        "engDate": "1957-10-24",
        "nepDate": "08.07.2014"
    },
    {
        "engDate": "1957-10-25",
        "nepDate": "09.07.2014"
    },
    {
        "engDate": "1957-10-26",
        "nepDate": "10.07.2014"
    },
    {
        "engDate": "1957-10-27",
        "nepDate": "11.07.2014"
    },
    {
        "engDate": "1957-10-28",
        "nepDate": "12.07.2014"
    },
    {
        "engDate": "1957-10-29",
        "nepDate": "13.07.2014"
    },
    {
        "engDate": "1957-10-30",
        "nepDate": "14.07.2014"
    },
    {
        "engDate": "1957-10-31",
        "nepDate": "15.07.2014"
    },
    {
        "engDate": "1957-11-01",
        "nepDate": "16.07.2014"
    },
    {
        "engDate": "1957-11-02",
        "nepDate": "17.07.2014"
    },
    {
        "engDate": "1957-11-03",
        "nepDate": "18.07.2014"
    },
    {
        "engDate": "1957-11-04",
        "nepDate": "19.07.2014"
    },
    {
        "engDate": "1957-11-05",
        "nepDate": "20.07.2014"
    },
    {
        "engDate": "1957-11-06",
        "nepDate": "21.07.2014"
    },
    {
        "engDate": "1957-11-07",
        "nepDate": "22.07.2014"
    },
    {
        "engDate": "1957-11-08",
        "nepDate": "23.07.2014"
    },
    {
        "engDate": "1957-11-09",
        "nepDate": "24.07.2014"
    },
    {
        "engDate": "1957-11-10",
        "nepDate": "25.07.2014"
    },
    {
        "engDate": "1957-11-11",
        "nepDate": "26.07.2014"
    },
    {
        "engDate": "1957-11-12",
        "nepDate": "27.07.2014"
    },
    {
        "engDate": "1957-11-13",
        "nepDate": "28.07.2014"
    },
    {
        "engDate": "1957-11-14",
        "nepDate": "29.07.2014"
    },
    {
        "engDate": "1957-11-15",
        "nepDate": "30.07.2014"
    },
    {
        "engDate": "1957-11-16",
        "nepDate": "01.08.2014"
    },
    {
        "engDate": "1957-11-17",
        "nepDate": "02.08.2014"
    },
    {
        "engDate": "1957-11-18",
        "nepDate": "03.08.2014"
    },
    {
        "engDate": "1957-11-19",
        "nepDate": "04.08.2014"
    },
    {
        "engDate": "1957-11-20",
        "nepDate": "05.08.2014"
    },
    {
        "engDate": "1957-11-21",
        "nepDate": "06.08.2014"
    },
    {
        "engDate": "1957-11-22",
        "nepDate": "07.08.2014"
    },
    {
        "engDate": "1957-11-23",
        "nepDate": "08.08.2014"
    },
    {
        "engDate": "1957-11-24",
        "nepDate": "09.08.2014"
    },
    {
        "engDate": "1957-11-25",
        "nepDate": "10.08.2014"
    },
    {
        "engDate": "1957-11-26",
        "nepDate": "11.08.2014"
    },
    {
        "engDate": "1957-11-27",
        "nepDate": "12.08.2014"
    },
    {
        "engDate": "1957-11-28",
        "nepDate": "13.08.2014"
    },
    {
        "engDate": "1957-11-29",
        "nepDate": "14.08.2014"
    },
    {
        "engDate": "1957-11-30",
        "nepDate": "15.08.2014"
    },
    {
        "engDate": "1957-12-01",
        "nepDate": "16.08.2014"
    },
    {
        "engDate": "1957-12-02",
        "nepDate": "17.08.2014"
    },
    {
        "engDate": "1957-12-03",
        "nepDate": "18.08.2014"
    },
    {
        "engDate": "1957-12-04",
        "nepDate": "19.08.2014"
    },
    {
        "engDate": "1957-12-05",
        "nepDate": "20.08.2014"
    },
    {
        "engDate": "1957-12-06",
        "nepDate": "21.08.2014"
    },
    {
        "engDate": "1957-12-07",
        "nepDate": "22.08.2014"
    },
    {
        "engDate": "1957-12-08",
        "nepDate": "23.08.2014"
    },
    {
        "engDate": "1957-12-09",
        "nepDate": "24.08.2014"
    },
    {
        "engDate": "1957-12-10",
        "nepDate": "25.08.2014"
    },
    {
        "engDate": "1957-12-11",
        "nepDate": "26.08.2014"
    },
    {
        "engDate": "1957-12-12",
        "nepDate": "27.08.2014"
    },
    {
        "engDate": "1957-12-13",
        "nepDate": "28.08.2014"
    },
    {
        "engDate": "1957-12-14",
        "nepDate": "29.08.2014"
    },
    {
        "engDate": "1957-12-15",
        "nepDate": "01.09.2014"
    },
    {
        "engDate": "1957-12-16",
        "nepDate": "02.09.2014"
    },
    {
        "engDate": "1957-12-17",
        "nepDate": "03.09.2014"
    },
    {
        "engDate": "1957-12-18",
        "nepDate": "04.09.2014"
    },
    {
        "engDate": "1957-12-19",
        "nepDate": "05.09.2014"
    },
    {
        "engDate": "1957-12-20",
        "nepDate": "06.09.2014"
    },
    {
        "engDate": "1957-12-21",
        "nepDate": "07.09.2014"
    },
    {
        "engDate": "1957-12-22",
        "nepDate": "08.09.2014"
    },
    {
        "engDate": "1957-12-23",
        "nepDate": "09.09.2014"
    },
    {
        "engDate": "1957-12-24",
        "nepDate": "10.09.2014"
    },
    {
        "engDate": "1957-12-25",
        "nepDate": "11.09.2014"
    },
    {
        "engDate": "1957-12-26",
        "nepDate": "12.09.2014"
    },
    {
        "engDate": "1957-12-27",
        "nepDate": "13.09.2014"
    },
    {
        "engDate": "1957-12-28",
        "nepDate": "14.09.2014"
    },
    {
        "engDate": "1957-12-29",
        "nepDate": "15.09.2014"
    },
    {
        "engDate": "1957-12-30",
        "nepDate": "16.09.2014"
    },
    {
        "engDate": "1957-12-31",
        "nepDate": "17.09.2014"
    },
    {
        "engDate": "1958-01-01",
        "nepDate": "18.09.2014"
    },
    {
        "engDate": "1958-01-02",
        "nepDate": "19.09.2014"
    },
    {
        "engDate": "1958-01-03",
        "nepDate": "20.09.2014"
    },
    {
        "engDate": "1958-01-04",
        "nepDate": "21.09.2014"
    },
    {
        "engDate": "1958-01-05",
        "nepDate": "22.09.2014"
    },
    {
        "engDate": "1958-01-06",
        "nepDate": "23.09.2014"
    },
    {
        "engDate": "1958-01-07",
        "nepDate": "24.09.2014"
    },
    {
        "engDate": "1958-01-08",
        "nepDate": "25.09.2014"
    },
    {
        "engDate": "1958-01-09",
        "nepDate": "26.09.2014"
    },
    {
        "engDate": "1958-01-10",
        "nepDate": "27.09.2014"
    },
    {
        "engDate": "1958-01-11",
        "nepDate": "28.09.2014"
    },
    {
        "engDate": "1958-01-12",
        "nepDate": "29.09.2014"
    },
    {
        "engDate": "1958-01-13",
        "nepDate": "30.09.2014"
    },
    {
        "engDate": "1958-01-14",
        "nepDate": "01.10.2014"
    },
    {
        "engDate": "1958-01-15",
        "nepDate": "02.10.2014"
    },
    {
        "engDate": "1958-01-16",
        "nepDate": "03.10.2014"
    },
    {
        "engDate": "1958-01-17",
        "nepDate": "04.10.2014"
    },
    {
        "engDate": "1958-01-18",
        "nepDate": "05.10.2014"
    },
    {
        "engDate": "1958-01-19",
        "nepDate": "06.10.2014"
    },
    {
        "engDate": "1958-01-20",
        "nepDate": "07.10.2014"
    },
    {
        "engDate": "1958-01-21",
        "nepDate": "08.10.2014"
    },
    {
        "engDate": "1958-01-22",
        "nepDate": "09.10.2014"
    },
    {
        "engDate": "1958-01-23",
        "nepDate": "10.10.2014"
    },
    {
        "engDate": "1958-01-24",
        "nepDate": "11.10.2014"
    },
    {
        "engDate": "1958-01-25",
        "nepDate": "12.10.2014"
    },
    {
        "engDate": "1958-01-26",
        "nepDate": "13.10.2014"
    },
    {
        "engDate": "1958-01-27",
        "nepDate": "14.10.2014"
    },
    {
        "engDate": "1958-01-28",
        "nepDate": "15.10.2014"
    },
    {
        "engDate": "1958-01-29",
        "nepDate": "16.10.2014"
    },
    {
        "engDate": "1958-01-30",
        "nepDate": "17.10.2014"
    },
    {
        "engDate": "1958-01-31",
        "nepDate": "18.10.2014"
    },
    {
        "engDate": "1958-02-01",
        "nepDate": "19.10.2014"
    },
    {
        "engDate": "1958-02-02",
        "nepDate": "20.10.2014"
    },
    {
        "engDate": "1958-02-03",
        "nepDate": "21.10.2014"
    },
    {
        "engDate": "1958-02-04",
        "nepDate": "22.10.2014"
    },
    {
        "engDate": "1958-02-05",
        "nepDate": "23.10.2014"
    },
    {
        "engDate": "1958-02-06",
        "nepDate": "24.10.2014"
    },
    {
        "engDate": "1958-02-07",
        "nepDate": "25.10.2014"
    },
    {
        "engDate": "1958-02-08",
        "nepDate": "26.10.2014"
    },
    {
        "engDate": "1958-02-09",
        "nepDate": "27.10.2014"
    },
    {
        "engDate": "1958-02-10",
        "nepDate": "28.10.2014"
    },
    {
        "engDate": "1958-02-11",
        "nepDate": "29.10.2014"
    },
    {
        "engDate": "1958-02-12",
        "nepDate": "01.11.2014"
    },
    {
        "engDate": "1958-02-13",
        "nepDate": "02.11.2014"
    },
    {
        "engDate": "1958-02-14",
        "nepDate": "03.11.2014"
    },
    {
        "engDate": "1958-02-15",
        "nepDate": "04.11.2014"
    },
    {
        "engDate": "1958-02-16",
        "nepDate": "05.11.2014"
    },
    {
        "engDate": "1958-02-17",
        "nepDate": "06.11.2014"
    },
    {
        "engDate": "1958-02-18",
        "nepDate": "07.11.2014"
    },
    {
        "engDate": "1958-02-19",
        "nepDate": "08.11.2014"
    },
    {
        "engDate": "1958-02-20",
        "nepDate": "09.11.2014"
    },
    {
        "engDate": "1958-02-21",
        "nepDate": "10.11.2014"
    },
    {
        "engDate": "1958-02-22",
        "nepDate": "11.11.2014"
    },
    {
        "engDate": "1958-02-23",
        "nepDate": "12.11.2014"
    },
    {
        "engDate": "1958-02-24",
        "nepDate": "13.11.2014"
    },
    {
        "engDate": "1958-02-25",
        "nepDate": "14.11.2014"
    },
    {
        "engDate": "1958-02-26",
        "nepDate": "15.11.2014"
    },
    {
        "engDate": "1958-02-27",
        "nepDate": "16.11.2014"
    },
    {
        "engDate": "1958-02-28",
        "nepDate": "17.11.2014"
    },
    {
        "engDate": "1958-03-01",
        "nepDate": "18.11.2014"
    },
    {
        "engDate": "1958-03-02",
        "nepDate": "19.11.2014"
    },
    {
        "engDate": "1958-03-03",
        "nepDate": "20.11.2014"
    },
    {
        "engDate": "1958-03-04",
        "nepDate": "21.11.2014"
    },
    {
        "engDate": "1958-03-05",
        "nepDate": "22.11.2014"
    },
    {
        "engDate": "1958-03-06",
        "nepDate": "23.11.2014"
    },
    {
        "engDate": "1958-03-07",
        "nepDate": "24.11.2014"
    },
    {
        "engDate": "1958-03-08",
        "nepDate": "25.11.2014"
    },
    {
        "engDate": "1958-03-09",
        "nepDate": "26.11.2014"
    },
    {
        "engDate": "1958-03-10",
        "nepDate": "27.11.2014"
    },
    {
        "engDate": "1958-03-11",
        "nepDate": "28.11.2014"
    },
    {
        "engDate": "1958-03-12",
        "nepDate": "29.11.2014"
    },
    {
        "engDate": "1958-03-13",
        "nepDate": "30.11.2014"
    },
    {
        "engDate": "1958-03-14",
        "nepDate": "01.12.2014"
    },
    {
        "engDate": "1958-03-15",
        "nepDate": "02.12.2014"
    },
    {
        "engDate": "1958-03-16",
        "nepDate": "03.12.2014"
    },
    {
        "engDate": "1958-03-17",
        "nepDate": "04.12.2014"
    },
    {
        "engDate": "1958-03-18",
        "nepDate": "05.12.2014"
    },
    {
        "engDate": "1958-03-19",
        "nepDate": "06.12.2014"
    },
    {
        "engDate": "1958-03-20",
        "nepDate": "07.12.2014"
    },
    {
        "engDate": "1958-03-21",
        "nepDate": "08.12.2014"
    },
    {
        "engDate": "1958-03-22",
        "nepDate": "09.12.2014"
    },
    {
        "engDate": "1958-03-23",
        "nepDate": "10.12.2014"
    },
    {
        "engDate": "1958-03-24",
        "nepDate": "11.12.2014"
    },
    {
        "engDate": "1958-03-25",
        "nepDate": "12.12.2014"
    },
    {
        "engDate": "1958-03-26",
        "nepDate": "13.12.2014"
    },
    {
        "engDate": "1958-03-27",
        "nepDate": "14.12.2014"
    },
    {
        "engDate": "1958-03-28",
        "nepDate": "15.12.2014"
    },
    {
        "engDate": "1958-03-29",
        "nepDate": "16.12.2014"
    },
    {
        "engDate": "1958-03-30",
        "nepDate": "17.12.2014"
    },
    {
        "engDate": "1958-03-31",
        "nepDate": "18.12.2014"
    },
    {
        "engDate": "1958-04-01",
        "nepDate": "19.12.2014"
    },
    {
        "engDate": "1958-04-02",
        "nepDate": "20.12.2014"
    },
    {
        "engDate": "1958-04-03",
        "nepDate": "21.12.2014"
    },
    {
        "engDate": "1958-04-04",
        "nepDate": "22.12.2014"
    },
    {
        "engDate": "1958-04-05",
        "nepDate": "23.12.2014"
    },
    {
        "engDate": "1958-04-06",
        "nepDate": "24.12.2014"
    },
    {
        "engDate": "1958-04-07",
        "nepDate": "25.12.2014"
    },
    {
        "engDate": "1958-04-08",
        "nepDate": "26.12.2014"
    },
    {
        "engDate": "1958-04-09",
        "nepDate": "27.12.2014"
    },
    {
        "engDate": "1958-04-10",
        "nepDate": "28.12.2014"
    },
    {
        "engDate": "1958-04-11",
        "nepDate": "29.12.2014"
    },
    {
        "engDate": "1958-04-12",
        "nepDate": "30.12.2014"
    },
    {
        "engDate": "1958-04-13",
        "nepDate": "01.01.2015"
    },
    {
        "engDate": "1958-04-14",
        "nepDate": "02.01.2015"
    },
    {
        "engDate": "1958-04-15",
        "nepDate": "03.01.2015"
    },
    {
        "engDate": "1958-04-16",
        "nepDate": "04.01.2015"
    },
    {
        "engDate": "1958-04-17",
        "nepDate": "05.01.2015"
    },
    {
        "engDate": "1958-04-18",
        "nepDate": "06.01.2015"
    },
    {
        "engDate": "1958-04-19",
        "nepDate": "07.01.2015"
    },
    {
        "engDate": "1958-04-20",
        "nepDate": "08.01.2015"
    },
    {
        "engDate": "1958-04-21",
        "nepDate": "09.01.2015"
    },
    {
        "engDate": "1958-04-22",
        "nepDate": "10.01.2015"
    },
    {
        "engDate": "1958-04-23",
        "nepDate": "11.01.2015"
    },
    {
        "engDate": "1958-04-24",
        "nepDate": "12.01.2015"
    },
    {
        "engDate": "1958-04-25",
        "nepDate": "13.01.2015"
    },
    {
        "engDate": "1958-04-26",
        "nepDate": "14.01.2015"
    },
    {
        "engDate": "1958-04-27",
        "nepDate": "15.01.2015"
    },
    {
        "engDate": "1958-04-28",
        "nepDate": "16.01.2015"
    },
    {
        "engDate": "1958-04-29",
        "nepDate": "17.01.2015"
    },
    {
        "engDate": "1958-04-30",
        "nepDate": "18.01.2015"
    },
    {
        "engDate": "1958-05-01",
        "nepDate": "19.01.2015"
    },
    {
        "engDate": "1958-05-02",
        "nepDate": "20.01.2015"
    },
    {
        "engDate": "1958-05-03",
        "nepDate": "21.01.2015"
    },
    {
        "engDate": "1958-05-04",
        "nepDate": "22.01.2015"
    },
    {
        "engDate": "1958-05-05",
        "nepDate": "23.01.2015"
    },
    {
        "engDate": "1958-05-06",
        "nepDate": "24.01.2015"
    },
    {
        "engDate": "1958-05-07",
        "nepDate": "25.01.2015"
    },
    {
        "engDate": "1958-05-08",
        "nepDate": "26.01.2015"
    },
    {
        "engDate": "1958-05-09",
        "nepDate": "27.01.2015"
    },
    {
        "engDate": "1958-05-10",
        "nepDate": "28.01.2015"
    },
    {
        "engDate": "1958-05-11",
        "nepDate": "29.01.2015"
    },
    {
        "engDate": "1958-05-12",
        "nepDate": "30.01.2015"
    },
    {
        "engDate": "1958-05-13",
        "nepDate": "31.01.2015"
    },
    {
        "engDate": "1958-05-14",
        "nepDate": "01.02.2015"
    },
    {
        "engDate": "1958-05-15",
        "nepDate": "02.02.2015"
    },
    {
        "engDate": "1958-05-16",
        "nepDate": "03.02.2015"
    },
    {
        "engDate": "1958-05-17",
        "nepDate": "04.02.2015"
    },
    {
        "engDate": "1958-05-18",
        "nepDate": "05.02.2015"
    },
    {
        "engDate": "1958-05-19",
        "nepDate": "06.02.2015"
    },
    {
        "engDate": "1958-05-20",
        "nepDate": "07.02.2015"
    },
    {
        "engDate": "1958-05-21",
        "nepDate": "08.02.2015"
    },
    {
        "engDate": "1958-05-22",
        "nepDate": "09.02.2015"
    },
    {
        "engDate": "1958-05-23",
        "nepDate": "10.02.2015"
    },
    {
        "engDate": "1958-05-24",
        "nepDate": "11.02.2015"
    },
    {
        "engDate": "1958-05-25",
        "nepDate": "12.02.2015"
    },
    {
        "engDate": "1958-05-26",
        "nepDate": "13.02.2015"
    },
    {
        "engDate": "1958-05-27",
        "nepDate": "14.02.2015"
    },
    {
        "engDate": "1958-05-28",
        "nepDate": "15.02.2015"
    },
    {
        "engDate": "1958-05-29",
        "nepDate": "16.02.2015"
    },
    {
        "engDate": "1958-05-30",
        "nepDate": "17.02.2015"
    },
    {
        "engDate": "1958-05-31",
        "nepDate": "18.02.2015"
    },
    {
        "engDate": "1958-06-01",
        "nepDate": "19.02.2015"
    },
    {
        "engDate": "1958-06-02",
        "nepDate": "20.02.2015"
    },
    {
        "engDate": "1958-06-03",
        "nepDate": "21.02.2015"
    },
    {
        "engDate": "1958-06-04",
        "nepDate": "22.02.2015"
    },
    {
        "engDate": "1958-06-05",
        "nepDate": "23.02.2015"
    },
    {
        "engDate": "1958-06-06",
        "nepDate": "24.02.2015"
    },
    {
        "engDate": "1958-06-07",
        "nepDate": "25.02.2015"
    },
    {
        "engDate": "1958-06-08",
        "nepDate": "26.02.2015"
    },
    {
        "engDate": "1958-06-09",
        "nepDate": "27.02.2015"
    },
    {
        "engDate": "1958-06-10",
        "nepDate": "28.02.2015"
    },
    {
        "engDate": "1958-06-11",
        "nepDate": "29.02.2015"
    },
    {
        "engDate": "1958-06-12",
        "nepDate": "30.02.2015"
    },
    {
        "engDate": "1958-06-13",
        "nepDate": "31.02.2015"
    },
    {
        "engDate": "1958-06-14",
        "nepDate": "32.02.2015"
    },
    {
        "engDate": "1958-06-15",
        "nepDate": "01.03.2015"
    },
    {
        "engDate": "1958-06-16",
        "nepDate": "02.03.2015"
    },
    {
        "engDate": "1958-06-17",
        "nepDate": "03.03.2015"
    },
    {
        "engDate": "1958-06-18",
        "nepDate": "04.03.2015"
    },
    {
        "engDate": "1958-06-19",
        "nepDate": "05.03.2015"
    },
    {
        "engDate": "1958-06-20",
        "nepDate": "06.03.2015"
    },
    {
        "engDate": "1958-06-21",
        "nepDate": "07.03.2015"
    },
    {
        "engDate": "1958-06-22",
        "nepDate": "08.03.2015"
    },
    {
        "engDate": "1958-06-23",
        "nepDate": "09.03.2015"
    },
    {
        "engDate": "1958-06-24",
        "nepDate": "10.03.2015"
    },
    {
        "engDate": "1958-06-25",
        "nepDate": "11.03.2015"
    },
    {
        "engDate": "1958-06-26",
        "nepDate": "12.03.2015"
    },
    {
        "engDate": "1958-06-27",
        "nepDate": "13.03.2015"
    },
    {
        "engDate": "1958-06-28",
        "nepDate": "14.03.2015"
    },
    {
        "engDate": "1958-06-29",
        "nepDate": "15.03.2015"
    },
    {
        "engDate": "1958-06-30",
        "nepDate": "16.03.2015"
    },
    {
        "engDate": "1958-07-01",
        "nepDate": "17.03.2015"
    },
    {
        "engDate": "1958-07-02",
        "nepDate": "18.03.2015"
    },
    {
        "engDate": "1958-07-03",
        "nepDate": "19.03.2015"
    },
    {
        "engDate": "1958-07-04",
        "nepDate": "20.03.2015"
    },
    {
        "engDate": "1958-07-05",
        "nepDate": "21.03.2015"
    },
    {
        "engDate": "1958-07-06",
        "nepDate": "22.03.2015"
    },
    {
        "engDate": "1958-07-07",
        "nepDate": "23.03.2015"
    },
    {
        "engDate": "1958-07-08",
        "nepDate": "24.03.2015"
    },
    {
        "engDate": "1958-07-09",
        "nepDate": "25.03.2015"
    },
    {
        "engDate": "1958-07-10",
        "nepDate": "26.03.2015"
    },
    {
        "engDate": "1958-07-11",
        "nepDate": "27.03.2015"
    },
    {
        "engDate": "1958-07-12",
        "nepDate": "28.03.2015"
    },
    {
        "engDate": "1958-07-13",
        "nepDate": "29.03.2015"
    },
    {
        "engDate": "1958-07-14",
        "nepDate": "30.03.2015"
    },
    {
        "engDate": "1958-07-15",
        "nepDate": "31.03.2015"
    },
    {
        "engDate": "1958-07-16",
        "nepDate": "01.04.2015"
    },
    {
        "engDate": "1958-07-17",
        "nepDate": "02.04.2015"
    },
    {
        "engDate": "1958-07-18",
        "nepDate": "03.04.2015"
    },
    {
        "engDate": "1958-07-19",
        "nepDate": "04.04.2015"
    },
    {
        "engDate": "1958-07-20",
        "nepDate": "05.04.2015"
    },
    {
        "engDate": "1958-07-21",
        "nepDate": "06.04.2015"
    },
    {
        "engDate": "1958-07-22",
        "nepDate": "07.04.2015"
    },
    {
        "engDate": "1958-07-23",
        "nepDate": "08.04.2015"
    },
    {
        "engDate": "1958-07-24",
        "nepDate": "09.04.2015"
    },
    {
        "engDate": "1958-07-25",
        "nepDate": "10.04.2015"
    },
    {
        "engDate": "1958-07-26",
        "nepDate": "11.04.2015"
    },
    {
        "engDate": "1958-07-27",
        "nepDate": "12.04.2015"
    },
    {
        "engDate": "1958-07-28",
        "nepDate": "13.04.2015"
    },
    {
        "engDate": "1958-07-29",
        "nepDate": "14.04.2015"
    },
    {
        "engDate": "1958-07-30",
        "nepDate": "15.04.2015"
    },
    {
        "engDate": "1958-07-31",
        "nepDate": "16.04.2015"
    },
    {
        "engDate": "1958-08-01",
        "nepDate": "17.04.2015"
    },
    {
        "engDate": "1958-08-02",
        "nepDate": "18.04.2015"
    },
    {
        "engDate": "1958-08-03",
        "nepDate": "19.04.2015"
    },
    {
        "engDate": "1958-08-04",
        "nepDate": "20.04.2015"
    },
    {
        "engDate": "1958-08-05",
        "nepDate": "21.04.2015"
    },
    {
        "engDate": "1958-08-06",
        "nepDate": "22.04.2015"
    },
    {
        "engDate": "1958-08-07",
        "nepDate": "23.04.2015"
    },
    {
        "engDate": "1958-08-08",
        "nepDate": "24.04.2015"
    },
    {
        "engDate": "1958-08-09",
        "nepDate": "25.04.2015"
    },
    {
        "engDate": "1958-08-10",
        "nepDate": "26.04.2015"
    },
    {
        "engDate": "1958-08-11",
        "nepDate": "27.04.2015"
    },
    {
        "engDate": "1958-08-12",
        "nepDate": "28.04.2015"
    },
    {
        "engDate": "1958-08-13",
        "nepDate": "29.04.2015"
    },
    {
        "engDate": "1958-08-14",
        "nepDate": "30.04.2015"
    },
    {
        "engDate": "1958-08-15",
        "nepDate": "31.04.2015"
    },
    {
        "engDate": "1958-08-16",
        "nepDate": "32.04.2015"
    },
    {
        "engDate": "1958-08-17",
        "nepDate": "01.05.2015"
    },
    {
        "engDate": "1958-08-18",
        "nepDate": "02.05.2015"
    },
    {
        "engDate": "1958-08-19",
        "nepDate": "03.05.2015"
    },
    {
        "engDate": "1958-08-20",
        "nepDate": "04.05.2015"
    },
    {
        "engDate": "1958-08-21",
        "nepDate": "05.05.2015"
    },
    {
        "engDate": "1958-08-22",
        "nepDate": "06.05.2015"
    },
    {
        "engDate": "1958-08-23",
        "nepDate": "07.05.2015"
    },
    {
        "engDate": "1958-08-24",
        "nepDate": "08.05.2015"
    },
    {
        "engDate": "1958-08-25",
        "nepDate": "09.05.2015"
    },
    {
        "engDate": "1958-08-26",
        "nepDate": "10.05.2015"
    },
    {
        "engDate": "1958-08-27",
        "nepDate": "11.05.2015"
    },
    {
        "engDate": "1958-08-28",
        "nepDate": "12.05.2015"
    },
    {
        "engDate": "1958-08-29",
        "nepDate": "13.05.2015"
    },
    {
        "engDate": "1958-08-30",
        "nepDate": "14.05.2015"
    },
    {
        "engDate": "1958-08-31",
        "nepDate": "15.05.2015"
    },
    {
        "engDate": "1958-09-01",
        "nepDate": "16.05.2015"
    },
    {
        "engDate": "1958-09-02",
        "nepDate": "17.05.2015"
    },
    {
        "engDate": "1958-09-03",
        "nepDate": "18.05.2015"
    },
    {
        "engDate": "1958-09-04",
        "nepDate": "19.05.2015"
    },
    {
        "engDate": "1958-09-05",
        "nepDate": "20.05.2015"
    },
    {
        "engDate": "1958-09-06",
        "nepDate": "21.05.2015"
    },
    {
        "engDate": "1958-09-07",
        "nepDate": "22.05.2015"
    },
    {
        "engDate": "1958-09-08",
        "nepDate": "23.05.2015"
    },
    {
        "engDate": "1958-09-09",
        "nepDate": "24.05.2015"
    },
    {
        "engDate": "1958-09-10",
        "nepDate": "25.05.2015"
    },
    {
        "engDate": "1958-09-11",
        "nepDate": "26.05.2015"
    },
    {
        "engDate": "1958-09-12",
        "nepDate": "27.05.2015"
    },
    {
        "engDate": "1958-09-13",
        "nepDate": "28.05.2015"
    },
    {
        "engDate": "1958-09-14",
        "nepDate": "29.05.2015"
    },
    {
        "engDate": "1958-09-15",
        "nepDate": "30.05.2015"
    },
    {
        "engDate": "1958-09-16",
        "nepDate": "31.05.2015"
    },
    {
        "engDate": "1958-09-17",
        "nepDate": "01.06.2015"
    },
    {
        "engDate": "1958-09-18",
        "nepDate": "02.06.2015"
    },
    {
        "engDate": "1958-09-19",
        "nepDate": "03.06.2015"
    },
    {
        "engDate": "1958-09-20",
        "nepDate": "04.06.2015"
    },
    {
        "engDate": "1958-09-21",
        "nepDate": "05.06.2015"
    },
    {
        "engDate": "1958-09-22",
        "nepDate": "06.06.2015"
    },
    {
        "engDate": "1958-09-23",
        "nepDate": "07.06.2015"
    },
    {
        "engDate": "1958-09-24",
        "nepDate": "08.06.2015"
    },
    {
        "engDate": "1958-09-25",
        "nepDate": "09.06.2015"
    },
    {
        "engDate": "1958-09-26",
        "nepDate": "10.06.2015"
    },
    {
        "engDate": "1958-09-27",
        "nepDate": "11.06.2015"
    },
    {
        "engDate": "1958-09-28",
        "nepDate": "12.06.2015"
    },
    {
        "engDate": "1958-09-29",
        "nepDate": "13.06.2015"
    },
    {
        "engDate": "1958-09-30",
        "nepDate": "14.06.2015"
    },
    {
        "engDate": "1958-10-01",
        "nepDate": "15.06.2015"
    },
    {
        "engDate": "1958-10-02",
        "nepDate": "16.06.2015"
    },
    {
        "engDate": "1958-10-03",
        "nepDate": "17.06.2015"
    },
    {
        "engDate": "1958-10-04",
        "nepDate": "18.06.2015"
    },
    {
        "engDate": "1958-10-05",
        "nepDate": "19.06.2015"
    },
    {
        "engDate": "1958-10-06",
        "nepDate": "20.06.2015"
    },
    {
        "engDate": "1958-10-07",
        "nepDate": "21.06.2015"
    },
    {
        "engDate": "1958-10-08",
        "nepDate": "22.06.2015"
    },
    {
        "engDate": "1958-10-09",
        "nepDate": "23.06.2015"
    },
    {
        "engDate": "1958-10-10",
        "nepDate": "24.06.2015"
    },
    {
        "engDate": "1958-10-11",
        "nepDate": "25.06.2015"
    },
    {
        "engDate": "1958-10-12",
        "nepDate": "26.06.2015"
    },
    {
        "engDate": "1958-10-13",
        "nepDate": "27.06.2015"
    },
    {
        "engDate": "1958-10-14",
        "nepDate": "28.06.2015"
    },
    {
        "engDate": "1958-10-15",
        "nepDate": "29.06.2015"
    },
    {
        "engDate": "1958-10-16",
        "nepDate": "30.06.2015"
    },
    {
        "engDate": "1958-10-17",
        "nepDate": "01.07.2015"
    },
    {
        "engDate": "1958-10-18",
        "nepDate": "02.07.2015"
    },
    {
        "engDate": "1958-10-19",
        "nepDate": "03.07.2015"
    },
    {
        "engDate": "1958-10-20",
        "nepDate": "04.07.2015"
    },
    {
        "engDate": "1958-10-21",
        "nepDate": "05.07.2015"
    },
    {
        "engDate": "1958-10-22",
        "nepDate": "06.07.2015"
    },
    {
        "engDate": "1958-10-23",
        "nepDate": "07.07.2015"
    },
    {
        "engDate": "1958-10-24",
        "nepDate": "08.07.2015"
    },
    {
        "engDate": "1958-10-25",
        "nepDate": "09.07.2015"
    },
    {
        "engDate": "1958-10-26",
        "nepDate": "10.07.2015"
    },
    {
        "engDate": "1958-10-27",
        "nepDate": "11.07.2015"
    },
    {
        "engDate": "1958-10-28",
        "nepDate": "12.07.2015"
    },
    {
        "engDate": "1958-10-29",
        "nepDate": "13.07.2015"
    },
    {
        "engDate": "1958-10-30",
        "nepDate": "14.07.2015"
    },
    {
        "engDate": "1958-10-31",
        "nepDate": "15.07.2015"
    },
    {
        "engDate": "1958-11-01",
        "nepDate": "16.07.2015"
    },
    {
        "engDate": "1958-11-02",
        "nepDate": "17.07.2015"
    },
    {
        "engDate": "1958-11-03",
        "nepDate": "18.07.2015"
    },
    {
        "engDate": "1958-11-04",
        "nepDate": "19.07.2015"
    },
    {
        "engDate": "1958-11-05",
        "nepDate": "20.07.2015"
    },
    {
        "engDate": "1958-11-06",
        "nepDate": "21.07.2015"
    },
    {
        "engDate": "1958-11-07",
        "nepDate": "22.07.2015"
    },
    {
        "engDate": "1958-11-08",
        "nepDate": "23.07.2015"
    },
    {
        "engDate": "1958-11-09",
        "nepDate": "24.07.2015"
    },
    {
        "engDate": "1958-11-10",
        "nepDate": "25.07.2015"
    },
    {
        "engDate": "1958-11-11",
        "nepDate": "26.07.2015"
    },
    {
        "engDate": "1958-11-12",
        "nepDate": "27.07.2015"
    },
    {
        "engDate": "1958-11-13",
        "nepDate": "28.07.2015"
    },
    {
        "engDate": "1958-11-14",
        "nepDate": "29.07.2015"
    },
    {
        "engDate": "1958-11-15",
        "nepDate": "30.07.2015"
    },
    {
        "engDate": "1958-11-16",
        "nepDate": "01.08.2015"
    },
    {
        "engDate": "1958-11-17",
        "nepDate": "02.08.2015"
    },
    {
        "engDate": "1958-11-18",
        "nepDate": "03.08.2015"
    },
    {
        "engDate": "1958-11-19",
        "nepDate": "04.08.2015"
    },
    {
        "engDate": "1958-11-20",
        "nepDate": "05.08.2015"
    },
    {
        "engDate": "1958-11-21",
        "nepDate": "06.08.2015"
    },
    {
        "engDate": "1958-11-22",
        "nepDate": "07.08.2015"
    },
    {
        "engDate": "1958-11-23",
        "nepDate": "08.08.2015"
    },
    {
        "engDate": "1958-11-24",
        "nepDate": "09.08.2015"
    },
    {
        "engDate": "1958-11-25",
        "nepDate": "10.08.2015"
    },
    {
        "engDate": "1958-11-26",
        "nepDate": "11.08.2015"
    },
    {
        "engDate": "1958-11-27",
        "nepDate": "12.08.2015"
    },
    {
        "engDate": "1958-11-28",
        "nepDate": "13.08.2015"
    },
    {
        "engDate": "1958-11-29",
        "nepDate": "14.08.2015"
    },
    {
        "engDate": "1958-11-30",
        "nepDate": "15.08.2015"
    },
    {
        "engDate": "1958-12-01",
        "nepDate": "16.08.2015"
    },
    {
        "engDate": "1958-12-02",
        "nepDate": "17.08.2015"
    },
    {
        "engDate": "1958-12-03",
        "nepDate": "18.08.2015"
    },
    {
        "engDate": "1958-12-04",
        "nepDate": "19.08.2015"
    },
    {
        "engDate": "1958-12-05",
        "nepDate": "20.08.2015"
    },
    {
        "engDate": "1958-12-06",
        "nepDate": "21.08.2015"
    },
    {
        "engDate": "1958-12-07",
        "nepDate": "22.08.2015"
    },
    {
        "engDate": "1958-12-08",
        "nepDate": "23.08.2015"
    },
    {
        "engDate": "1958-12-09",
        "nepDate": "24.08.2015"
    },
    {
        "engDate": "1958-12-10",
        "nepDate": "25.08.2015"
    },
    {
        "engDate": "1958-12-11",
        "nepDate": "26.08.2015"
    },
    {
        "engDate": "1958-12-12",
        "nepDate": "27.08.2015"
    },
    {
        "engDate": "1958-12-13",
        "nepDate": "28.08.2015"
    },
    {
        "engDate": "1958-12-14",
        "nepDate": "29.08.2015"
    },
    {
        "engDate": "1958-12-15",
        "nepDate": "30.08.2015"
    },
    {
        "engDate": "1958-12-16",
        "nepDate": "01.09.2015"
    },
    {
        "engDate": "1958-12-17",
        "nepDate": "02.09.2015"
    },
    {
        "engDate": "1958-12-18",
        "nepDate": "03.09.2015"
    },
    {
        "engDate": "1958-12-19",
        "nepDate": "04.09.2015"
    },
    {
        "engDate": "1958-12-20",
        "nepDate": "05.09.2015"
    },
    {
        "engDate": "1958-12-21",
        "nepDate": "06.09.2015"
    },
    {
        "engDate": "1958-12-22",
        "nepDate": "07.09.2015"
    },
    {
        "engDate": "1958-12-23",
        "nepDate": "08.09.2015"
    },
    {
        "engDate": "1958-12-24",
        "nepDate": "09.09.2015"
    },
    {
        "engDate": "1958-12-25",
        "nepDate": "10.09.2015"
    },
    {
        "engDate": "1958-12-26",
        "nepDate": "11.09.2015"
    },
    {
        "engDate": "1958-12-27",
        "nepDate": "12.09.2015"
    },
    {
        "engDate": "1958-12-28",
        "nepDate": "13.09.2015"
    },
    {
        "engDate": "1958-12-29",
        "nepDate": "14.09.2015"
    },
    {
        "engDate": "1958-12-30",
        "nepDate": "15.09.2015"
    },
    {
        "engDate": "1958-12-31",
        "nepDate": "16.09.2015"
    },
    {
        "engDate": "1959-01-01",
        "nepDate": "17.09.2015"
    },
    {
        "engDate": "1959-01-02",
        "nepDate": "18.09.2015"
    },
    {
        "engDate": "1959-01-03",
        "nepDate": "19.09.2015"
    },
    {
        "engDate": "1959-01-04",
        "nepDate": "20.09.2015"
    },
    {
        "engDate": "1959-01-05",
        "nepDate": "21.09.2015"
    },
    {
        "engDate": "1959-01-06",
        "nepDate": "22.09.2015"
    },
    {
        "engDate": "1959-01-07",
        "nepDate": "23.09.2015"
    },
    {
        "engDate": "1959-01-08",
        "nepDate": "24.09.2015"
    },
    {
        "engDate": "1959-01-09",
        "nepDate": "25.09.2015"
    },
    {
        "engDate": "1959-01-10",
        "nepDate": "26.09.2015"
    },
    {
        "engDate": "1959-01-11",
        "nepDate": "27.09.2015"
    },
    {
        "engDate": "1959-01-12",
        "nepDate": "28.09.2015"
    },
    {
        "engDate": "1959-01-13",
        "nepDate": "29.09.2015"
    },
    {
        "engDate": "1959-01-14",
        "nepDate": "01.10.2015"
    },
    {
        "engDate": "1959-01-15",
        "nepDate": "02.10.2015"
    },
    {
        "engDate": "1959-01-16",
        "nepDate": "03.10.2015"
    },
    {
        "engDate": "1959-01-17",
        "nepDate": "04.10.2015"
    },
    {
        "engDate": "1959-01-18",
        "nepDate": "05.10.2015"
    },
    {
        "engDate": "1959-01-19",
        "nepDate": "06.10.2015"
    },
    {
        "engDate": "1959-01-20",
        "nepDate": "07.10.2015"
    },
    {
        "engDate": "1959-01-21",
        "nepDate": "08.10.2015"
    },
    {
        "engDate": "1959-01-22",
        "nepDate": "09.10.2015"
    },
    {
        "engDate": "1959-01-23",
        "nepDate": "10.10.2015"
    },
    {
        "engDate": "1959-01-24",
        "nepDate": "11.10.2015"
    },
    {
        "engDate": "1959-01-25",
        "nepDate": "12.10.2015"
    },
    {
        "engDate": "1959-01-26",
        "nepDate": "13.10.2015"
    },
    {
        "engDate": "1959-01-27",
        "nepDate": "14.10.2015"
    },
    {
        "engDate": "1959-01-28",
        "nepDate": "15.10.2015"
    },
    {
        "engDate": "1959-01-29",
        "nepDate": "16.10.2015"
    },
    {
        "engDate": "1959-01-30",
        "nepDate": "17.10.2015"
    },
    {
        "engDate": "1959-01-31",
        "nepDate": "18.10.2015"
    },
    {
        "engDate": "1959-02-01",
        "nepDate": "19.10.2015"
    },
    {
        "engDate": "1959-02-02",
        "nepDate": "20.10.2015"
    },
    {
        "engDate": "1959-02-03",
        "nepDate": "21.10.2015"
    },
    {
        "engDate": "1959-02-04",
        "nepDate": "22.10.2015"
    },
    {
        "engDate": "1959-02-05",
        "nepDate": "23.10.2015"
    },
    {
        "engDate": "1959-02-06",
        "nepDate": "24.10.2015"
    },
    {
        "engDate": "1959-02-07",
        "nepDate": "25.10.2015"
    },
    {
        "engDate": "1959-02-08",
        "nepDate": "26.10.2015"
    },
    {
        "engDate": "1959-02-09",
        "nepDate": "27.10.2015"
    },
    {
        "engDate": "1959-02-10",
        "nepDate": "28.10.2015"
    },
    {
        "engDate": "1959-02-11",
        "nepDate": "29.10.2015"
    },
    {
        "engDate": "1959-02-12",
        "nepDate": "01.11.2015"
    },
    {
        "engDate": "1959-02-13",
        "nepDate": "02.11.2015"
    },
    {
        "engDate": "1959-02-14",
        "nepDate": "03.11.2015"
    },
    {
        "engDate": "1959-02-15",
        "nepDate": "04.11.2015"
    },
    {
        "engDate": "1959-02-16",
        "nepDate": "05.11.2015"
    },
    {
        "engDate": "1959-02-17",
        "nepDate": "06.11.2015"
    },
    {
        "engDate": "1959-02-18",
        "nepDate": "07.11.2015"
    },
    {
        "engDate": "1959-02-19",
        "nepDate": "08.11.2015"
    },
    {
        "engDate": "1959-02-20",
        "nepDate": "09.11.2015"
    },
    {
        "engDate": "1959-02-21",
        "nepDate": "10.11.2015"
    },
    {
        "engDate": "1959-02-22",
        "nepDate": "11.11.2015"
    },
    {
        "engDate": "1959-02-23",
        "nepDate": "12.11.2015"
    },
    {
        "engDate": "1959-02-24",
        "nepDate": "13.11.2015"
    },
    {
        "engDate": "1959-02-25",
        "nepDate": "14.11.2015"
    },
    {
        "engDate": "1959-02-26",
        "nepDate": "15.11.2015"
    },
    {
        "engDate": "1959-02-27",
        "nepDate": "16.11.2015"
    },
    {
        "engDate": "1959-02-28",
        "nepDate": "17.11.2015"
    },
    {
        "engDate": "1959-03-01",
        "nepDate": "18.11.2015"
    },
    {
        "engDate": "1959-03-02",
        "nepDate": "19.11.2015"
    },
    {
        "engDate": "1959-03-03",
        "nepDate": "20.11.2015"
    },
    {
        "engDate": "1959-03-04",
        "nepDate": "21.11.2015"
    },
    {
        "engDate": "1959-03-05",
        "nepDate": "22.11.2015"
    },
    {
        "engDate": "1959-03-06",
        "nepDate": "23.11.2015"
    },
    {
        "engDate": "1959-03-07",
        "nepDate": "24.11.2015"
    },
    {
        "engDate": "1959-03-08",
        "nepDate": "25.11.2015"
    },
    {
        "engDate": "1959-03-09",
        "nepDate": "26.11.2015"
    },
    {
        "engDate": "1959-03-10",
        "nepDate": "27.11.2015"
    },
    {
        "engDate": "1959-03-11",
        "nepDate": "28.11.2015"
    },
    {
        "engDate": "1959-03-12",
        "nepDate": "29.11.2015"
    },
    {
        "engDate": "1959-03-13",
        "nepDate": "30.11.2015"
    },
    {
        "engDate": "1959-03-14",
        "nepDate": "01.12.2015"
    },
    {
        "engDate": "1959-03-15",
        "nepDate": "02.12.2015"
    },
    {
        "engDate": "1959-03-16",
        "nepDate": "03.12.2015"
    },
    {
        "engDate": "1959-03-17",
        "nepDate": "04.12.2015"
    },
    {
        "engDate": "1959-03-18",
        "nepDate": "05.12.2015"
    },
    {
        "engDate": "1959-03-19",
        "nepDate": "06.12.2015"
    },
    {
        "engDate": "1959-03-20",
        "nepDate": "07.12.2015"
    },
    {
        "engDate": "1959-03-21",
        "nepDate": "08.12.2015"
    },
    {
        "engDate": "1959-03-22",
        "nepDate": "09.12.2015"
    },
    {
        "engDate": "1959-03-23",
        "nepDate": "10.12.2015"
    },
    {
        "engDate": "1959-03-24",
        "nepDate": "11.12.2015"
    },
    {
        "engDate": "1959-03-25",
        "nepDate": "12.12.2015"
    },
    {
        "engDate": "1959-03-26",
        "nepDate": "13.12.2015"
    },
    {
        "engDate": "1959-03-27",
        "nepDate": "14.12.2015"
    },
    {
        "engDate": "1959-03-28",
        "nepDate": "15.12.2015"
    },
    {
        "engDate": "1959-03-29",
        "nepDate": "16.12.2015"
    },
    {
        "engDate": "1959-03-30",
        "nepDate": "17.12.2015"
    },
    {
        "engDate": "1959-03-31",
        "nepDate": "18.12.2015"
    },
    {
        "engDate": "1959-04-01",
        "nepDate": "19.12.2015"
    },
    {
        "engDate": "1959-04-02",
        "nepDate": "20.12.2015"
    },
    {
        "engDate": "1959-04-03",
        "nepDate": "21.12.2015"
    },
    {
        "engDate": "1959-04-04",
        "nepDate": "22.12.2015"
    },
    {
        "engDate": "1959-04-05",
        "nepDate": "23.12.2015"
    },
    {
        "engDate": "1959-04-06",
        "nepDate": "24.12.2015"
    },
    {
        "engDate": "1959-04-07",
        "nepDate": "25.12.2015"
    },
    {
        "engDate": "1959-04-08",
        "nepDate": "26.12.2015"
    },
    {
        "engDate": "1959-04-09",
        "nepDate": "27.12.2015"
    },
    {
        "engDate": "1959-04-10",
        "nepDate": "28.12.2015"
    },
    {
        "engDate": "1959-04-11",
        "nepDate": "29.12.2015"
    },
    {
        "engDate": "1959-04-12",
        "nepDate": "30.12.2015"
    },
    {
        "engDate": "1959-04-13",
        "nepDate": "31.12.2015"
    },
    {
        "engDate": "1959-04-14",
        "nepDate": "01.01.2016"
    },
    {
        "engDate": "1959-04-15",
        "nepDate": "02.01.2016"
    },
    {
        "engDate": "1959-04-16",
        "nepDate": "03.01.2016"
    },
    {
        "engDate": "1959-04-17",
        "nepDate": "04.01.2016"
    },
    {
        "engDate": "1959-04-18",
        "nepDate": "05.01.2016"
    },
    {
        "engDate": "1959-04-19",
        "nepDate": "06.01.2016"
    },
    {
        "engDate": "1959-04-20",
        "nepDate": "07.01.2016"
    },
    {
        "engDate": "1959-04-21",
        "nepDate": "08.01.2016"
    },
    {
        "engDate": "1959-04-22",
        "nepDate": "09.01.2016"
    },
    {
        "engDate": "1959-04-23",
        "nepDate": "10.01.2016"
    },
    {
        "engDate": "1959-04-24",
        "nepDate": "11.01.2016"
    },
    {
        "engDate": "1959-04-25",
        "nepDate": "12.01.2016"
    },
    {
        "engDate": "1959-04-26",
        "nepDate": "13.01.2016"
    },
    {
        "engDate": "1959-04-27",
        "nepDate": "14.01.2016"
    },
    {
        "engDate": "1959-04-28",
        "nepDate": "15.01.2016"
    },
    {
        "engDate": "1959-04-29",
        "nepDate": "16.01.2016"
    },
    {
        "engDate": "1959-04-30",
        "nepDate": "17.01.2016"
    },
    {
        "engDate": "1959-05-01",
        "nepDate": "18.01.2016"
    },
    {
        "engDate": "1959-05-02",
        "nepDate": "19.01.2016"
    },
    {
        "engDate": "1959-05-03",
        "nepDate": "20.01.2016"
    },
    {
        "engDate": "1959-05-04",
        "nepDate": "21.01.2016"
    },
    {
        "engDate": "1959-05-05",
        "nepDate": "22.01.2016"
    },
    {
        "engDate": "1959-05-06",
        "nepDate": "23.01.2016"
    },
    {
        "engDate": "1959-05-07",
        "nepDate": "24.01.2016"
    },
    {
        "engDate": "1959-05-08",
        "nepDate": "25.01.2016"
    },
    {
        "engDate": "1959-05-09",
        "nepDate": "26.01.2016"
    },
    {
        "engDate": "1959-05-10",
        "nepDate": "27.01.2016"
    },
    {
        "engDate": "1959-05-11",
        "nepDate": "28.01.2016"
    },
    {
        "engDate": "1959-05-12",
        "nepDate": "29.01.2016"
    },
    {
        "engDate": "1959-05-13",
        "nepDate": "30.01.2016"
    },
    {
        "engDate": "1959-05-14",
        "nepDate": "31.01.2016"
    },
    {
        "engDate": "1959-05-15",
        "nepDate": "01.02.2016"
    },
    {
        "engDate": "1959-05-16",
        "nepDate": "02.02.2016"
    },
    {
        "engDate": "1959-05-17",
        "nepDate": "03.02.2016"
    },
    {
        "engDate": "1959-05-18",
        "nepDate": "04.02.2016"
    },
    {
        "engDate": "1959-05-19",
        "nepDate": "05.02.2016"
    },
    {
        "engDate": "1959-05-20",
        "nepDate": "06.02.2016"
    },
    {
        "engDate": "1959-05-21",
        "nepDate": "07.02.2016"
    },
    {
        "engDate": "1959-05-22",
        "nepDate": "08.02.2016"
    },
    {
        "engDate": "1959-05-23",
        "nepDate": "09.02.2016"
    },
    {
        "engDate": "1959-05-24",
        "nepDate": "10.02.2016"
    },
    {
        "engDate": "1959-05-25",
        "nepDate": "11.02.2016"
    },
    {
        "engDate": "1959-05-26",
        "nepDate": "12.02.2016"
    },
    {
        "engDate": "1959-05-27",
        "nepDate": "13.02.2016"
    },
    {
        "engDate": "1959-05-28",
        "nepDate": "14.02.2016"
    },
    {
        "engDate": "1959-05-29",
        "nepDate": "15.02.2016"
    },
    {
        "engDate": "1959-05-30",
        "nepDate": "16.02.2016"
    },
    {
        "engDate": "1959-05-31",
        "nepDate": "17.02.2016"
    },
    {
        "engDate": "1959-06-01",
        "nepDate": "18.02.2016"
    },
    {
        "engDate": "1959-06-02",
        "nepDate": "19.02.2016"
    },
    {
        "engDate": "1959-06-03",
        "nepDate": "20.02.2016"
    },
    {
        "engDate": "1959-06-04",
        "nepDate": "21.02.2016"
    },
    {
        "engDate": "1959-06-05",
        "nepDate": "22.02.2016"
    },
    {
        "engDate": "1959-06-06",
        "nepDate": "23.02.2016"
    },
    {
        "engDate": "1959-06-07",
        "nepDate": "24.02.2016"
    },
    {
        "engDate": "1959-06-08",
        "nepDate": "25.02.2016"
    },
    {
        "engDate": "1959-06-09",
        "nepDate": "26.02.2016"
    },
    {
        "engDate": "1959-06-10",
        "nepDate": "27.02.2016"
    },
    {
        "engDate": "1959-06-11",
        "nepDate": "28.02.2016"
    },
    {
        "engDate": "1959-06-12",
        "nepDate": "29.02.2016"
    },
    {
        "engDate": "1959-06-13",
        "nepDate": "30.02.2016"
    },
    {
        "engDate": "1959-06-14",
        "nepDate": "31.02.2016"
    },
    {
        "engDate": "1959-06-15",
        "nepDate": "01.03.2016"
    },
    {
        "engDate": "1959-06-16",
        "nepDate": "02.03.2016"
    },
    {
        "engDate": "1959-06-17",
        "nepDate": "03.03.2016"
    },
    {
        "engDate": "1959-06-18",
        "nepDate": "04.03.2016"
    },
    {
        "engDate": "1959-06-19",
        "nepDate": "05.03.2016"
    },
    {
        "engDate": "1959-06-20",
        "nepDate": "06.03.2016"
    },
    {
        "engDate": "1959-06-21",
        "nepDate": "07.03.2016"
    },
    {
        "engDate": "1959-06-22",
        "nepDate": "08.03.2016"
    },
    {
        "engDate": "1959-06-23",
        "nepDate": "09.03.2016"
    },
    {
        "engDate": "1959-06-24",
        "nepDate": "10.03.2016"
    },
    {
        "engDate": "1959-06-25",
        "nepDate": "11.03.2016"
    },
    {
        "engDate": "1959-06-26",
        "nepDate": "12.03.2016"
    },
    {
        "engDate": "1959-06-27",
        "nepDate": "13.03.2016"
    },
    {
        "engDate": "1959-06-28",
        "nepDate": "14.03.2016"
    },
    {
        "engDate": "1959-06-29",
        "nepDate": "15.03.2016"
    },
    {
        "engDate": "1959-06-30",
        "nepDate": "16.03.2016"
    },
    {
        "engDate": "1959-07-01",
        "nepDate": "17.03.2016"
    },
    {
        "engDate": "1959-07-02",
        "nepDate": "18.03.2016"
    },
    {
        "engDate": "1959-07-03",
        "nepDate": "19.03.2016"
    },
    {
        "engDate": "1959-07-04",
        "nepDate": "20.03.2016"
    },
    {
        "engDate": "1959-07-05",
        "nepDate": "21.03.2016"
    },
    {
        "engDate": "1959-07-06",
        "nepDate": "22.03.2016"
    },
    {
        "engDate": "1959-07-07",
        "nepDate": "23.03.2016"
    },
    {
        "engDate": "1959-07-08",
        "nepDate": "24.03.2016"
    },
    {
        "engDate": "1959-07-09",
        "nepDate": "25.03.2016"
    },
    {
        "engDate": "1959-07-10",
        "nepDate": "26.03.2016"
    },
    {
        "engDate": "1959-07-11",
        "nepDate": "27.03.2016"
    },
    {
        "engDate": "1959-07-12",
        "nepDate": "28.03.2016"
    },
    {
        "engDate": "1959-07-13",
        "nepDate": "29.03.2016"
    },
    {
        "engDate": "1959-07-14",
        "nepDate": "30.03.2016"
    },
    {
        "engDate": "1959-07-15",
        "nepDate": "31.03.2016"
    },
    {
        "engDate": "1959-07-16",
        "nepDate": "01.04.2016"
    },
    {
        "engDate": "1959-07-17",
        "nepDate": "02.04.2016"
    },
    {
        "engDate": "1959-07-18",
        "nepDate": "03.04.2016"
    },
    {
        "engDate": "1959-07-19",
        "nepDate": "04.04.2016"
    },
    {
        "engDate": "1959-07-20",
        "nepDate": "05.04.2016"
    },
    {
        "engDate": "1959-07-21",
        "nepDate": "06.04.2016"
    },
    {
        "engDate": "1959-07-22",
        "nepDate": "07.04.2016"
    },
    {
        "engDate": "1959-07-23",
        "nepDate": "08.04.2016"
    },
    {
        "engDate": "1959-07-24",
        "nepDate": "09.04.2016"
    },
    {
        "engDate": "1959-07-25",
        "nepDate": "10.04.2016"
    },
    {
        "engDate": "1959-07-26",
        "nepDate": "11.04.2016"
    },
    {
        "engDate": "1959-07-27",
        "nepDate": "12.04.2016"
    },
    {
        "engDate": "1959-07-28",
        "nepDate": "13.04.2016"
    },
    {
        "engDate": "1959-07-29",
        "nepDate": "14.04.2016"
    },
    {
        "engDate": "1959-07-30",
        "nepDate": "15.04.2016"
    },
    {
        "engDate": "1959-07-31",
        "nepDate": "16.04.2016"
    },
    {
        "engDate": "1959-08-01",
        "nepDate": "17.04.2016"
    },
    {
        "engDate": "1959-08-02",
        "nepDate": "18.04.2016"
    },
    {
        "engDate": "1959-08-03",
        "nepDate": "19.04.2016"
    },
    {
        "engDate": "1959-08-04",
        "nepDate": "20.04.2016"
    },
    {
        "engDate": "1959-08-05",
        "nepDate": "21.04.2016"
    },
    {
        "engDate": "1959-08-06",
        "nepDate": "22.04.2016"
    },
    {
        "engDate": "1959-08-07",
        "nepDate": "23.04.2016"
    },
    {
        "engDate": "1959-08-08",
        "nepDate": "24.04.2016"
    },
    {
        "engDate": "1959-08-09",
        "nepDate": "25.04.2016"
    },
    {
        "engDate": "1959-08-10",
        "nepDate": "26.04.2016"
    },
    {
        "engDate": "1959-08-11",
        "nepDate": "27.04.2016"
    },
    {
        "engDate": "1959-08-12",
        "nepDate": "28.04.2016"
    },
    {
        "engDate": "1959-08-13",
        "nepDate": "29.04.2016"
    },
    {
        "engDate": "1959-08-14",
        "nepDate": "30.04.2016"
    },
    {
        "engDate": "1959-08-15",
        "nepDate": "31.04.2016"
    },
    {
        "engDate": "1959-08-16",
        "nepDate": "32.04.2016"
    },
    {
        "engDate": "1959-08-17",
        "nepDate": "01.05.2016"
    },
    {
        "engDate": "1959-08-18",
        "nepDate": "02.05.2016"
    },
    {
        "engDate": "1959-08-19",
        "nepDate": "03.05.2016"
    },
    {
        "engDate": "1959-08-20",
        "nepDate": "04.05.2016"
    },
    {
        "engDate": "1959-08-21",
        "nepDate": "05.05.2016"
    },
    {
        "engDate": "1959-08-22",
        "nepDate": "06.05.2016"
    },
    {
        "engDate": "1959-08-23",
        "nepDate": "07.05.2016"
    },
    {
        "engDate": "1959-08-24",
        "nepDate": "08.05.2016"
    },
    {
        "engDate": "1959-08-25",
        "nepDate": "09.05.2016"
    },
    {
        "engDate": "1959-08-26",
        "nepDate": "10.05.2016"
    },
    {
        "engDate": "1959-08-27",
        "nepDate": "11.05.2016"
    },
    {
        "engDate": "1959-08-28",
        "nepDate": "12.05.2016"
    },
    {
        "engDate": "1959-08-29",
        "nepDate": "13.05.2016"
    },
    {
        "engDate": "1959-08-30",
        "nepDate": "14.05.2016"
    },
    {
        "engDate": "1959-08-31",
        "nepDate": "15.05.2016"
    },
    {
        "engDate": "1959-09-01",
        "nepDate": "16.05.2016"
    },
    {
        "engDate": "1959-09-02",
        "nepDate": "17.05.2016"
    },
    {
        "engDate": "1959-09-03",
        "nepDate": "18.05.2016"
    },
    {
        "engDate": "1959-09-04",
        "nepDate": "19.05.2016"
    },
    {
        "engDate": "1959-09-05",
        "nepDate": "20.05.2016"
    },
    {
        "engDate": "1959-09-06",
        "nepDate": "21.05.2016"
    },
    {
        "engDate": "1959-09-07",
        "nepDate": "22.05.2016"
    },
    {
        "engDate": "1959-09-08",
        "nepDate": "23.05.2016"
    },
    {
        "engDate": "1959-09-09",
        "nepDate": "24.05.2016"
    },
    {
        "engDate": "1959-09-10",
        "nepDate": "25.05.2016"
    },
    {
        "engDate": "1959-09-11",
        "nepDate": "26.05.2016"
    },
    {
        "engDate": "1959-09-12",
        "nepDate": "27.05.2016"
    },
    {
        "engDate": "1959-09-13",
        "nepDate": "28.05.2016"
    },
    {
        "engDate": "1959-09-14",
        "nepDate": "29.05.2016"
    },
    {
        "engDate": "1959-09-15",
        "nepDate": "30.05.2016"
    },
    {
        "engDate": "1959-09-16",
        "nepDate": "31.05.2016"
    },
    {
        "engDate": "1959-09-17",
        "nepDate": "01.06.2016"
    },
    {
        "engDate": "1959-09-18",
        "nepDate": "02.06.2016"
    },
    {
        "engDate": "1959-09-19",
        "nepDate": "03.06.2016"
    },
    {
        "engDate": "1959-09-20",
        "nepDate": "04.06.2016"
    },
    {
        "engDate": "1959-09-21",
        "nepDate": "05.06.2016"
    },
    {
        "engDate": "1959-09-22",
        "nepDate": "06.06.2016"
    },
    {
        "engDate": "1959-09-23",
        "nepDate": "07.06.2016"
    },
    {
        "engDate": "1959-09-24",
        "nepDate": "08.06.2016"
    },
    {
        "engDate": "1959-09-25",
        "nepDate": "09.06.2016"
    },
    {
        "engDate": "1959-09-26",
        "nepDate": "10.06.2016"
    },
    {
        "engDate": "1959-09-27",
        "nepDate": "11.06.2016"
    },
    {
        "engDate": "1959-09-28",
        "nepDate": "12.06.2016"
    },
    {
        "engDate": "1959-09-29",
        "nepDate": "13.06.2016"
    },
    {
        "engDate": "1959-09-30",
        "nepDate": "14.06.2016"
    },
    {
        "engDate": "1959-10-01",
        "nepDate": "15.06.2016"
    },
    {
        "engDate": "1959-10-02",
        "nepDate": "16.06.2016"
    },
    {
        "engDate": "1959-10-03",
        "nepDate": "17.06.2016"
    },
    {
        "engDate": "1959-10-04",
        "nepDate": "18.06.2016"
    },
    {
        "engDate": "1959-10-05",
        "nepDate": "19.06.2016"
    },
    {
        "engDate": "1959-10-06",
        "nepDate": "20.06.2016"
    },
    {
        "engDate": "1959-10-07",
        "nepDate": "21.06.2016"
    },
    {
        "engDate": "1959-10-08",
        "nepDate": "22.06.2016"
    },
    {
        "engDate": "1959-10-09",
        "nepDate": "23.06.2016"
    },
    {
        "engDate": "1959-10-10",
        "nepDate": "24.06.2016"
    },
    {
        "engDate": "1959-10-11",
        "nepDate": "25.06.2016"
    },
    {
        "engDate": "1959-10-12",
        "nepDate": "26.06.2016"
    },
    {
        "engDate": "1959-10-13",
        "nepDate": "27.06.2016"
    },
    {
        "engDate": "1959-10-14",
        "nepDate": "28.06.2016"
    },
    {
        "engDate": "1959-10-15",
        "nepDate": "29.06.2016"
    },
    {
        "engDate": "1959-10-16",
        "nepDate": "30.06.2016"
    },
    {
        "engDate": "1959-10-17",
        "nepDate": "31.06.2016"
    },
    {
        "engDate": "1959-10-18",
        "nepDate": "01.07.2016"
    },
    {
        "engDate": "1959-10-19",
        "nepDate": "02.07.2016"
    },
    {
        "engDate": "1959-10-20",
        "nepDate": "03.07.2016"
    },
    {
        "engDate": "1959-10-21",
        "nepDate": "04.07.2016"
    },
    {
        "engDate": "1959-10-22",
        "nepDate": "05.07.2016"
    },
    {
        "engDate": "1959-10-23",
        "nepDate": "06.07.2016"
    },
    {
        "engDate": "1959-10-24",
        "nepDate": "07.07.2016"
    },
    {
        "engDate": "1959-10-25",
        "nepDate": "08.07.2016"
    },
    {
        "engDate": "1959-10-26",
        "nepDate": "09.07.2016"
    },
    {
        "engDate": "1959-10-27",
        "nepDate": "10.07.2016"
    },
    {
        "engDate": "1959-10-28",
        "nepDate": "11.07.2016"
    },
    {
        "engDate": "1959-10-29",
        "nepDate": "12.07.2016"
    },
    {
        "engDate": "1959-10-30",
        "nepDate": "13.07.2016"
    },
    {
        "engDate": "1959-10-31",
        "nepDate": "14.07.2016"
    },
    {
        "engDate": "1959-11-01",
        "nepDate": "15.07.2016"
    },
    {
        "engDate": "1959-11-02",
        "nepDate": "16.07.2016"
    },
    {
        "engDate": "1959-11-03",
        "nepDate": "17.07.2016"
    },
    {
        "engDate": "1959-11-04",
        "nepDate": "18.07.2016"
    },
    {
        "engDate": "1959-11-05",
        "nepDate": "19.07.2016"
    },
    {
        "engDate": "1959-11-06",
        "nepDate": "20.07.2016"
    },
    {
        "engDate": "1959-11-07",
        "nepDate": "21.07.2016"
    },
    {
        "engDate": "1959-11-08",
        "nepDate": "22.07.2016"
    },
    {
        "engDate": "1959-11-09",
        "nepDate": "23.07.2016"
    },
    {
        "engDate": "1959-11-10",
        "nepDate": "24.07.2016"
    },
    {
        "engDate": "1959-11-11",
        "nepDate": "25.07.2016"
    },
    {
        "engDate": "1959-11-12",
        "nepDate": "26.07.2016"
    },
    {
        "engDate": "1959-11-13",
        "nepDate": "27.07.2016"
    },
    {
        "engDate": "1959-11-14",
        "nepDate": "28.07.2016"
    },
    {
        "engDate": "1959-11-15",
        "nepDate": "29.07.2016"
    },
    {
        "engDate": "1959-11-16",
        "nepDate": "01.08.2016"
    },
    {
        "engDate": "1959-11-17",
        "nepDate": "02.08.2016"
    },
    {
        "engDate": "1959-11-18",
        "nepDate": "03.08.2016"
    },
    {
        "engDate": "1959-11-19",
        "nepDate": "04.08.2016"
    },
    {
        "engDate": "1959-11-20",
        "nepDate": "05.08.2016"
    },
    {
        "engDate": "1959-11-21",
        "nepDate": "06.08.2016"
    },
    {
        "engDate": "1959-11-22",
        "nepDate": "07.08.2016"
    },
    {
        "engDate": "1959-11-23",
        "nepDate": "08.08.2016"
    },
    {
        "engDate": "1959-11-24",
        "nepDate": "09.08.2016"
    },
    {
        "engDate": "1959-11-25",
        "nepDate": "10.08.2016"
    },
    {
        "engDate": "1959-11-26",
        "nepDate": "11.08.2016"
    },
    {
        "engDate": "1959-11-27",
        "nepDate": "12.08.2016"
    },
    {
        "engDate": "1959-11-28",
        "nepDate": "13.08.2016"
    },
    {
        "engDate": "1959-11-29",
        "nepDate": "14.08.2016"
    },
    {
        "engDate": "1959-11-30",
        "nepDate": "15.08.2016"
    },
    {
        "engDate": "1959-12-01",
        "nepDate": "16.08.2016"
    },
    {
        "engDate": "1959-12-02",
        "nepDate": "17.08.2016"
    },
    {
        "engDate": "1959-12-03",
        "nepDate": "18.08.2016"
    },
    {
        "engDate": "1959-12-04",
        "nepDate": "19.08.2016"
    },
    {
        "engDate": "1959-12-05",
        "nepDate": "20.08.2016"
    },
    {
        "engDate": "1959-12-06",
        "nepDate": "21.08.2016"
    },
    {
        "engDate": "1959-12-07",
        "nepDate": "22.08.2016"
    },
    {
        "engDate": "1959-12-08",
        "nepDate": "23.08.2016"
    },
    {
        "engDate": "1959-12-09",
        "nepDate": "24.08.2016"
    },
    {
        "engDate": "1959-12-10",
        "nepDate": "25.08.2016"
    },
    {
        "engDate": "1959-12-11",
        "nepDate": "26.08.2016"
    },
    {
        "engDate": "1959-12-12",
        "nepDate": "27.08.2016"
    },
    {
        "engDate": "1959-12-13",
        "nepDate": "28.08.2016"
    },
    {
        "engDate": "1959-12-14",
        "nepDate": "29.08.2016"
    },
    {
        "engDate": "1959-12-15",
        "nepDate": "30.08.2016"
    },
    {
        "engDate": "1959-12-16",
        "nepDate": "01.09.2016"
    },
    {
        "engDate": "1959-12-17",
        "nepDate": "02.09.2016"
    },
    {
        "engDate": "1959-12-18",
        "nepDate": "03.09.2016"
    },
    {
        "engDate": "1959-12-19",
        "nepDate": "04.09.2016"
    },
    {
        "engDate": "1959-12-20",
        "nepDate": "05.09.2016"
    },
    {
        "engDate": "1959-12-21",
        "nepDate": "06.09.2016"
    },
    {
        "engDate": "1959-12-22",
        "nepDate": "07.09.2016"
    },
    {
        "engDate": "1959-12-23",
        "nepDate": "08.09.2016"
    },
    {
        "engDate": "1959-12-24",
        "nepDate": "09.09.2016"
    },
    {
        "engDate": "1959-12-25",
        "nepDate": "10.09.2016"
    },
    {
        "engDate": "1959-12-26",
        "nepDate": "11.09.2016"
    },
    {
        "engDate": "1959-12-27",
        "nepDate": "12.09.2016"
    },
    {
        "engDate": "1959-12-28",
        "nepDate": "13.09.2016"
    },
    {
        "engDate": "1959-12-29",
        "nepDate": "14.09.2016"
    },
    {
        "engDate": "1959-12-30",
        "nepDate": "15.09.2016"
    },
    {
        "engDate": "1959-12-31",
        "nepDate": "16.09.2016"
    },
    {
        "engDate": "1960-01-01",
        "nepDate": "17.09.2016"
    },
    {
        "engDate": "1960-01-02",
        "nepDate": "18.09.2016"
    },
    {
        "engDate": "1960-01-03",
        "nepDate": "19.09.2016"
    },
    {
        "engDate": "1960-01-04",
        "nepDate": "20.09.2016"
    },
    {
        "engDate": "1960-01-05",
        "nepDate": "21.09.2016"
    },
    {
        "engDate": "1960-01-06",
        "nepDate": "22.09.2016"
    },
    {
        "engDate": "1960-01-07",
        "nepDate": "23.09.2016"
    },
    {
        "engDate": "1960-01-08",
        "nepDate": "24.09.2016"
    },
    {
        "engDate": "1960-01-09",
        "nepDate": "25.09.2016"
    },
    {
        "engDate": "1960-01-10",
        "nepDate": "26.09.2016"
    },
    {
        "engDate": "1960-01-11",
        "nepDate": "27.09.2016"
    },
    {
        "engDate": "1960-01-12",
        "nepDate": "28.09.2016"
    },
    {
        "engDate": "1960-01-13",
        "nepDate": "29.09.2016"
    },
    {
        "engDate": "1960-01-14",
        "nepDate": "30.09.2016"
    },
    {
        "engDate": "1960-01-15",
        "nepDate": "01.10.2016"
    },
    {
        "engDate": "1960-01-16",
        "nepDate": "02.10.2016"
    },
    {
        "engDate": "1960-01-17",
        "nepDate": "03.10.2016"
    },
    {
        "engDate": "1960-01-18",
        "nepDate": "04.10.2016"
    },
    {
        "engDate": "1960-01-19",
        "nepDate": "05.10.2016"
    },
    {
        "engDate": "1960-01-20",
        "nepDate": "06.10.2016"
    },
    {
        "engDate": "1960-01-21",
        "nepDate": "07.10.2016"
    },
    {
        "engDate": "1960-01-22",
        "nepDate": "08.10.2016"
    },
    {
        "engDate": "1960-01-23",
        "nepDate": "09.10.2016"
    },
    {
        "engDate": "1960-01-24",
        "nepDate": "10.10.2016"
    },
    {
        "engDate": "1960-01-25",
        "nepDate": "11.10.2016"
    },
    {
        "engDate": "1960-01-26",
        "nepDate": "12.10.2016"
    },
    {
        "engDate": "1960-01-27",
        "nepDate": "13.10.2016"
    },
    {
        "engDate": "1960-01-28",
        "nepDate": "14.10.2016"
    },
    {
        "engDate": "1960-01-29",
        "nepDate": "15.10.2016"
    },
    {
        "engDate": "1960-01-30",
        "nepDate": "16.10.2016"
    },
    {
        "engDate": "1960-01-31",
        "nepDate": "17.10.2016"
    },
    {
        "engDate": "1960-02-01",
        "nepDate": "18.10.2016"
    },
    {
        "engDate": "1960-02-02",
        "nepDate": "19.10.2016"
    },
    {
        "engDate": "1960-02-03",
        "nepDate": "20.10.2016"
    },
    {
        "engDate": "1960-02-04",
        "nepDate": "21.10.2016"
    },
    {
        "engDate": "1960-02-05",
        "nepDate": "22.10.2016"
    },
    {
        "engDate": "1960-02-06",
        "nepDate": "23.10.2016"
    },
    {
        "engDate": "1960-02-07",
        "nepDate": "24.10.2016"
    },
    {
        "engDate": "1960-02-08",
        "nepDate": "25.10.2016"
    },
    {
        "engDate": "1960-02-09",
        "nepDate": "26.10.2016"
    },
    {
        "engDate": "1960-02-10",
        "nepDate": "27.10.2016"
    },
    {
        "engDate": "1960-02-11",
        "nepDate": "28.10.2016"
    },
    {
        "engDate": "1960-02-12",
        "nepDate": "29.10.2016"
    },
    {
        "engDate": "1960-02-13",
        "nepDate": "01.11.2016"
    },
    {
        "engDate": "1960-02-14",
        "nepDate": "02.11.2016"
    },
    {
        "engDate": "1960-02-15",
        "nepDate": "03.11.2016"
    },
    {
        "engDate": "1960-02-16",
        "nepDate": "04.11.2016"
    },
    {
        "engDate": "1960-02-17",
        "nepDate": "05.11.2016"
    },
    {
        "engDate": "1960-02-18",
        "nepDate": "06.11.2016"
    },
    {
        "engDate": "1960-02-19",
        "nepDate": "07.11.2016"
    },
    {
        "engDate": "1960-02-20",
        "nepDate": "08.11.2016"
    },
    {
        "engDate": "1960-02-21",
        "nepDate": "09.11.2016"
    },
    {
        "engDate": "1960-02-22",
        "nepDate": "10.11.2016"
    },
    {
        "engDate": "1960-02-23",
        "nepDate": "11.11.2016"
    },
    {
        "engDate": "1960-02-24",
        "nepDate": "12.11.2016"
    },
    {
        "engDate": "1960-02-25",
        "nepDate": "13.11.2016"
    },
    {
        "engDate": "1960-02-26",
        "nepDate": "14.11.2016"
    },
    {
        "engDate": "1960-02-27",
        "nepDate": "15.11.2016"
    },
    {
        "engDate": "1960-02-28",
        "nepDate": "16.11.2016"
    },
    {
        "engDate": "1960-02-29",
        "nepDate": "17.11.2016"
    },
    {
        "engDate": "1960-03-01",
        "nepDate": "18.11.2016"
    },
    {
        "engDate": "1960-03-02",
        "nepDate": "19.11.2016"
    },
    {
        "engDate": "1960-03-03",
        "nepDate": "20.11.2016"
    },
    {
        "engDate": "1960-03-04",
        "nepDate": "21.11.2016"
    },
    {
        "engDate": "1960-03-05",
        "nepDate": "22.11.2016"
    },
    {
        "engDate": "1960-03-06",
        "nepDate": "23.11.2016"
    },
    {
        "engDate": "1960-03-07",
        "nepDate": "24.11.2016"
    },
    {
        "engDate": "1960-03-08",
        "nepDate": "25.11.2016"
    },
    {
        "engDate": "1960-03-09",
        "nepDate": "26.11.2016"
    },
    {
        "engDate": "1960-03-10",
        "nepDate": "27.11.2016"
    },
    {
        "engDate": "1960-03-11",
        "nepDate": "28.11.2016"
    },
    {
        "engDate": "1960-03-12",
        "nepDate": "29.11.2016"
    },
    {
        "engDate": "1960-03-13",
        "nepDate": "30.11.2016"
    },
    {
        "engDate": "1960-03-14",
        "nepDate": "01.12.2016"
    },
    {
        "engDate": "1960-03-15",
        "nepDate": "02.12.2016"
    },
    {
        "engDate": "1960-03-16",
        "nepDate": "03.12.2016"
    },
    {
        "engDate": "1960-03-17",
        "nepDate": "04.12.2016"
    },
    {
        "engDate": "1960-03-18",
        "nepDate": "05.12.2016"
    },
    {
        "engDate": "1960-03-19",
        "nepDate": "06.12.2016"
    },
    {
        "engDate": "1960-03-20",
        "nepDate": "07.12.2016"
    },
    {
        "engDate": "1960-03-21",
        "nepDate": "08.12.2016"
    },
    {
        "engDate": "1960-03-22",
        "nepDate": "09.12.2016"
    },
    {
        "engDate": "1960-03-23",
        "nepDate": "10.12.2016"
    },
    {
        "engDate": "1960-03-24",
        "nepDate": "11.12.2016"
    },
    {
        "engDate": "1960-03-25",
        "nepDate": "12.12.2016"
    },
    {
        "engDate": "1960-03-26",
        "nepDate": "13.12.2016"
    },
    {
        "engDate": "1960-03-27",
        "nepDate": "14.12.2016"
    },
    {
        "engDate": "1960-03-28",
        "nepDate": "15.12.2016"
    },
    {
        "engDate": "1960-03-29",
        "nepDate": "16.12.2016"
    },
    {
        "engDate": "1960-03-30",
        "nepDate": "17.12.2016"
    },
    {
        "engDate": "1960-03-31",
        "nepDate": "18.12.2016"
    },
    {
        "engDate": "1960-04-01",
        "nepDate": "19.12.2016"
    },
    {
        "engDate": "1960-04-02",
        "nepDate": "20.12.2016"
    },
    {
        "engDate": "1960-04-03",
        "nepDate": "21.12.2016"
    },
    {
        "engDate": "1960-04-04",
        "nepDate": "22.12.2016"
    },
    {
        "engDate": "1960-04-05",
        "nepDate": "23.12.2016"
    },
    {
        "engDate": "1960-04-06",
        "nepDate": "24.12.2016"
    },
    {
        "engDate": "1960-04-07",
        "nepDate": "25.12.2016"
    },
    {
        "engDate": "1960-04-08",
        "nepDate": "26.12.2016"
    },
    {
        "engDate": "1960-04-09",
        "nepDate": "27.12.2016"
    },
    {
        "engDate": "1960-04-10",
        "nepDate": "28.12.2016"
    },
    {
        "engDate": "1960-04-11",
        "nepDate": "29.12.2016"
    },
    {
        "engDate": "1960-04-12",
        "nepDate": "30.12.2016"
    },
    {
        "engDate": "1960-04-13",
        "nepDate": "01.01.2017"
    },
    {
        "engDate": "1960-04-14",
        "nepDate": "02.01.2017"
    },
    {
        "engDate": "1960-04-15",
        "nepDate": "03.01.2017"
    },
    {
        "engDate": "1960-04-16",
        "nepDate": "04.01.2017"
    },
    {
        "engDate": "1960-04-17",
        "nepDate": "05.01.2017"
    },
    {
        "engDate": "1960-04-18",
        "nepDate": "06.01.2017"
    },
    {
        "engDate": "1960-04-19",
        "nepDate": "07.01.2017"
    },
    {
        "engDate": "1960-04-20",
        "nepDate": "08.01.2017"
    },
    {
        "engDate": "1960-04-21",
        "nepDate": "09.01.2017"
    },
    {
        "engDate": "1960-04-22",
        "nepDate": "10.01.2017"
    },
    {
        "engDate": "1960-04-23",
        "nepDate": "11.01.2017"
    },
    {
        "engDate": "1960-04-24",
        "nepDate": "12.01.2017"
    },
    {
        "engDate": "1960-04-25",
        "nepDate": "13.01.2017"
    },
    {
        "engDate": "1960-04-26",
        "nepDate": "14.01.2017"
    },
    {
        "engDate": "1960-04-27",
        "nepDate": "15.01.2017"
    },
    {
        "engDate": "1960-04-28",
        "nepDate": "16.01.2017"
    },
    {
        "engDate": "1960-04-29",
        "nepDate": "17.01.2017"
    },
    {
        "engDate": "1960-04-30",
        "nepDate": "18.01.2017"
    },
    {
        "engDate": "1960-05-01",
        "nepDate": "19.01.2017"
    },
    {
        "engDate": "1960-05-02",
        "nepDate": "20.01.2017"
    },
    {
        "engDate": "1960-05-03",
        "nepDate": "21.01.2017"
    },
    {
        "engDate": "1960-05-04",
        "nepDate": "22.01.2017"
    },
    {
        "engDate": "1960-05-05",
        "nepDate": "23.01.2017"
    },
    {
        "engDate": "1960-05-06",
        "nepDate": "24.01.2017"
    },
    {
        "engDate": "1960-05-07",
        "nepDate": "25.01.2017"
    },
    {
        "engDate": "1960-05-08",
        "nepDate": "26.01.2017"
    },
    {
        "engDate": "1960-05-09",
        "nepDate": "27.01.2017"
    },
    {
        "engDate": "1960-05-10",
        "nepDate": "28.01.2017"
    },
    {
        "engDate": "1960-05-11",
        "nepDate": "29.01.2017"
    },
    {
        "engDate": "1960-05-12",
        "nepDate": "30.01.2017"
    },
    {
        "engDate": "1960-05-13",
        "nepDate": "31.01.2017"
    },
    {
        "engDate": "1960-05-14",
        "nepDate": "01.02.2017"
    },
    {
        "engDate": "1960-05-15",
        "nepDate": "02.02.2017"
    },
    {
        "engDate": "1960-05-16",
        "nepDate": "03.02.2017"
    },
    {
        "engDate": "1960-05-17",
        "nepDate": "04.02.2017"
    },
    {
        "engDate": "1960-05-18",
        "nepDate": "05.02.2017"
    },
    {
        "engDate": "1960-05-19",
        "nepDate": "06.02.2017"
    },
    {
        "engDate": "1960-05-20",
        "nepDate": "07.02.2017"
    },
    {
        "engDate": "1960-05-21",
        "nepDate": "08.02.2017"
    },
    {
        "engDate": "1960-05-22",
        "nepDate": "09.02.2017"
    },
    {
        "engDate": "1960-05-23",
        "nepDate": "10.02.2017"
    },
    {
        "engDate": "1960-05-24",
        "nepDate": "11.02.2017"
    },
    {
        "engDate": "1960-05-25",
        "nepDate": "12.02.2017"
    },
    {
        "engDate": "1960-05-26",
        "nepDate": "13.02.2017"
    },
    {
        "engDate": "1960-05-27",
        "nepDate": "14.02.2017"
    },
    {
        "engDate": "1960-05-28",
        "nepDate": "15.02.2017"
    },
    {
        "engDate": "1960-05-29",
        "nepDate": "16.02.2017"
    },
    {
        "engDate": "1960-05-30",
        "nepDate": "17.02.2017"
    },
    {
        "engDate": "1960-05-31",
        "nepDate": "18.02.2017"
    },
    {
        "engDate": "1960-06-01",
        "nepDate": "19.02.2017"
    },
    {
        "engDate": "1960-06-02",
        "nepDate": "20.02.2017"
    },
    {
        "engDate": "1960-06-03",
        "nepDate": "21.02.2017"
    },
    {
        "engDate": "1960-06-04",
        "nepDate": "22.02.2017"
    },
    {
        "engDate": "1960-06-05",
        "nepDate": "23.02.2017"
    },
    {
        "engDate": "1960-06-06",
        "nepDate": "24.02.2017"
    },
    {
        "engDate": "1960-06-07",
        "nepDate": "25.02.2017"
    },
    {
        "engDate": "1960-06-08",
        "nepDate": "26.02.2017"
    },
    {
        "engDate": "1960-06-09",
        "nepDate": "27.02.2017"
    },
    {
        "engDate": "1960-06-10",
        "nepDate": "28.02.2017"
    },
    {
        "engDate": "1960-06-11",
        "nepDate": "29.02.2017"
    },
    {
        "engDate": "1960-06-12",
        "nepDate": "30.02.2017"
    },
    {
        "engDate": "1960-06-13",
        "nepDate": "31.02.2017"
    },
    {
        "engDate": "1960-06-14",
        "nepDate": "01.03.2017"
    },
    {
        "engDate": "1960-06-15",
        "nepDate": "02.03.2017"
    },
    {
        "engDate": "1960-06-16",
        "nepDate": "03.03.2017"
    },
    {
        "engDate": "1960-06-17",
        "nepDate": "04.03.2017"
    },
    {
        "engDate": "1960-06-18",
        "nepDate": "05.03.2017"
    },
    {
        "engDate": "1960-06-19",
        "nepDate": "06.03.2017"
    },
    {
        "engDate": "1960-06-20",
        "nepDate": "07.03.2017"
    },
    {
        "engDate": "1960-06-21",
        "nepDate": "08.03.2017"
    },
    {
        "engDate": "1960-06-22",
        "nepDate": "09.03.2017"
    },
    {
        "engDate": "1960-06-23",
        "nepDate": "10.03.2017"
    },
    {
        "engDate": "1960-06-24",
        "nepDate": "11.03.2017"
    },
    {
        "engDate": "1960-06-25",
        "nepDate": "12.03.2017"
    },
    {
        "engDate": "1960-06-26",
        "nepDate": "13.03.2017"
    },
    {
        "engDate": "1960-06-27",
        "nepDate": "14.03.2017"
    },
    {
        "engDate": "1960-06-28",
        "nepDate": "15.03.2017"
    },
    {
        "engDate": "1960-06-29",
        "nepDate": "16.03.2017"
    },
    {
        "engDate": "1960-06-30",
        "nepDate": "17.03.2017"
    },
    {
        "engDate": "1960-07-01",
        "nepDate": "18.03.2017"
    },
    {
        "engDate": "1960-07-02",
        "nepDate": "19.03.2017"
    },
    {
        "engDate": "1960-07-03",
        "nepDate": "20.03.2017"
    },
    {
        "engDate": "1960-07-04",
        "nepDate": "21.03.2017"
    },
    {
        "engDate": "1960-07-05",
        "nepDate": "22.03.2017"
    },
    {
        "engDate": "1960-07-06",
        "nepDate": "23.03.2017"
    },
    {
        "engDate": "1960-07-07",
        "nepDate": "24.03.2017"
    },
    {
        "engDate": "1960-07-08",
        "nepDate": "25.03.2017"
    },
    {
        "engDate": "1960-07-09",
        "nepDate": "26.03.2017"
    },
    {
        "engDate": "1960-07-10",
        "nepDate": "27.03.2017"
    },
    {
        "engDate": "1960-07-11",
        "nepDate": "28.03.2017"
    },
    {
        "engDate": "1960-07-12",
        "nepDate": "29.03.2017"
    },
    {
        "engDate": "1960-07-13",
        "nepDate": "30.03.2017"
    },
    {
        "engDate": "1960-07-14",
        "nepDate": "31.03.2017"
    },
    {
        "engDate": "1960-07-15",
        "nepDate": "32.03.2017"
    },
    {
        "engDate": "1960-07-16",
        "nepDate": "01.04.2017"
    },
    {
        "engDate": "1960-07-17",
        "nepDate": "02.04.2017"
    },
    {
        "engDate": "1960-07-18",
        "nepDate": "03.04.2017"
    },
    {
        "engDate": "1960-07-19",
        "nepDate": "04.04.2017"
    },
    {
        "engDate": "1960-07-20",
        "nepDate": "05.04.2017"
    },
    {
        "engDate": "1960-07-21",
        "nepDate": "06.04.2017"
    },
    {
        "engDate": "1960-07-22",
        "nepDate": "07.04.2017"
    },
    {
        "engDate": "1960-07-23",
        "nepDate": "08.04.2017"
    },
    {
        "engDate": "1960-07-24",
        "nepDate": "09.04.2017"
    },
    {
        "engDate": "1960-07-25",
        "nepDate": "10.04.2017"
    },
    {
        "engDate": "1960-07-26",
        "nepDate": "11.04.2017"
    },
    {
        "engDate": "1960-07-27",
        "nepDate": "12.04.2017"
    },
    {
        "engDate": "1960-07-28",
        "nepDate": "13.04.2017"
    },
    {
        "engDate": "1960-07-29",
        "nepDate": "14.04.2017"
    },
    {
        "engDate": "1960-07-30",
        "nepDate": "15.04.2017"
    },
    {
        "engDate": "1960-07-31",
        "nepDate": "16.04.2017"
    },
    {
        "engDate": "1960-08-01",
        "nepDate": "17.04.2017"
    },
    {
        "engDate": "1960-08-02",
        "nepDate": "18.04.2017"
    },
    {
        "engDate": "1960-08-03",
        "nepDate": "19.04.2017"
    },
    {
        "engDate": "1960-08-04",
        "nepDate": "20.04.2017"
    },
    {
        "engDate": "1960-08-05",
        "nepDate": "21.04.2017"
    },
    {
        "engDate": "1960-08-06",
        "nepDate": "22.04.2017"
    },
    {
        "engDate": "1960-08-07",
        "nepDate": "23.04.2017"
    },
    {
        "engDate": "1960-08-08",
        "nepDate": "24.04.2017"
    },
    {
        "engDate": "1960-08-09",
        "nepDate": "25.04.2017"
    },
    {
        "engDate": "1960-08-10",
        "nepDate": "26.04.2017"
    },
    {
        "engDate": "1960-08-11",
        "nepDate": "27.04.2017"
    },
    {
        "engDate": "1960-08-12",
        "nepDate": "28.04.2017"
    },
    {
        "engDate": "1960-08-13",
        "nepDate": "29.04.2017"
    },
    {
        "engDate": "1960-08-14",
        "nepDate": "30.04.2017"
    },
    {
        "engDate": "1960-08-15",
        "nepDate": "31.04.2017"
    },
    {
        "engDate": "1960-08-16",
        "nepDate": "01.05.2017"
    },
    {
        "engDate": "1960-08-17",
        "nepDate": "02.05.2017"
    },
    {
        "engDate": "1960-08-18",
        "nepDate": "03.05.2017"
    },
    {
        "engDate": "1960-08-19",
        "nepDate": "04.05.2017"
    },
    {
        "engDate": "1960-08-20",
        "nepDate": "05.05.2017"
    },
    {
        "engDate": "1960-08-21",
        "nepDate": "06.05.2017"
    },
    {
        "engDate": "1960-08-22",
        "nepDate": "07.05.2017"
    },
    {
        "engDate": "1960-08-23",
        "nepDate": "08.05.2017"
    },
    {
        "engDate": "1960-08-24",
        "nepDate": "09.05.2017"
    },
    {
        "engDate": "1960-08-25",
        "nepDate": "10.05.2017"
    },
    {
        "engDate": "1960-08-26",
        "nepDate": "11.05.2017"
    },
    {
        "engDate": "1960-08-27",
        "nepDate": "12.05.2017"
    },
    {
        "engDate": "1960-08-28",
        "nepDate": "13.05.2017"
    },
    {
        "engDate": "1960-08-29",
        "nepDate": "14.05.2017"
    },
    {
        "engDate": "1960-08-30",
        "nepDate": "15.05.2017"
    },
    {
        "engDate": "1960-08-31",
        "nepDate": "16.05.2017"
    },
    {
        "engDate": "1960-09-01",
        "nepDate": "17.05.2017"
    },
    {
        "engDate": "1960-09-02",
        "nepDate": "18.05.2017"
    },
    {
        "engDate": "1960-09-03",
        "nepDate": "19.05.2017"
    },
    {
        "engDate": "1960-09-04",
        "nepDate": "20.05.2017"
    },
    {
        "engDate": "1960-09-05",
        "nepDate": "21.05.2017"
    },
    {
        "engDate": "1960-09-06",
        "nepDate": "22.05.2017"
    },
    {
        "engDate": "1960-09-07",
        "nepDate": "23.05.2017"
    },
    {
        "engDate": "1960-09-08",
        "nepDate": "24.05.2017"
    },
    {
        "engDate": "1960-09-09",
        "nepDate": "25.05.2017"
    },
    {
        "engDate": "1960-09-10",
        "nepDate": "26.05.2017"
    },
    {
        "engDate": "1960-09-11",
        "nepDate": "27.05.2017"
    },
    {
        "engDate": "1960-09-12",
        "nepDate": "28.05.2017"
    },
    {
        "engDate": "1960-09-13",
        "nepDate": "29.05.2017"
    },
    {
        "engDate": "1960-09-14",
        "nepDate": "30.05.2017"
    },
    {
        "engDate": "1960-09-15",
        "nepDate": "31.05.2017"
    },
    {
        "engDate": "1960-09-16",
        "nepDate": "01.06.2017"
    },
    {
        "engDate": "1960-09-17",
        "nepDate": "02.06.2017"
    },
    {
        "engDate": "1960-09-18",
        "nepDate": "03.06.2017"
    },
    {
        "engDate": "1960-09-19",
        "nepDate": "04.06.2017"
    },
    {
        "engDate": "1960-09-20",
        "nepDate": "05.06.2017"
    },
    {
        "engDate": "1960-09-21",
        "nepDate": "06.06.2017"
    },
    {
        "engDate": "1960-09-22",
        "nepDate": "07.06.2017"
    },
    {
        "engDate": "1960-09-23",
        "nepDate": "08.06.2017"
    },
    {
        "engDate": "1960-09-24",
        "nepDate": "09.06.2017"
    },
    {
        "engDate": "1960-09-25",
        "nepDate": "10.06.2017"
    },
    {
        "engDate": "1960-09-26",
        "nepDate": "11.06.2017"
    },
    {
        "engDate": "1960-09-27",
        "nepDate": "12.06.2017"
    },
    {
        "engDate": "1960-09-28",
        "nepDate": "13.06.2017"
    },
    {
        "engDate": "1960-09-29",
        "nepDate": "14.06.2017"
    },
    {
        "engDate": "1960-09-30",
        "nepDate": "15.06.2017"
    },
    {
        "engDate": "1960-10-01",
        "nepDate": "16.06.2017"
    },
    {
        "engDate": "1960-10-02",
        "nepDate": "17.06.2017"
    },
    {
        "engDate": "1960-10-03",
        "nepDate": "18.06.2017"
    },
    {
        "engDate": "1960-10-04",
        "nepDate": "19.06.2017"
    },
    {
        "engDate": "1960-10-05",
        "nepDate": "20.06.2017"
    },
    {
        "engDate": "1960-10-06",
        "nepDate": "21.06.2017"
    },
    {
        "engDate": "1960-10-07",
        "nepDate": "22.06.2017"
    },
    {
        "engDate": "1960-10-08",
        "nepDate": "23.06.2017"
    },
    {
        "engDate": "1960-10-09",
        "nepDate": "24.06.2017"
    },
    {
        "engDate": "1960-10-10",
        "nepDate": "25.06.2017"
    },
    {
        "engDate": "1960-10-11",
        "nepDate": "26.06.2017"
    },
    {
        "engDate": "1960-10-12",
        "nepDate": "27.06.2017"
    },
    {
        "engDate": "1960-10-13",
        "nepDate": "28.06.2017"
    },
    {
        "engDate": "1960-10-14",
        "nepDate": "29.06.2017"
    },
    {
        "engDate": "1960-10-15",
        "nepDate": "30.06.2017"
    },
    {
        "engDate": "1960-10-16",
        "nepDate": "31.06.2017"
    },
    {
        "engDate": "1960-10-17",
        "nepDate": "01.07.2017"
    },
    {
        "engDate": "1960-10-18",
        "nepDate": "02.07.2017"
    },
    {
        "engDate": "1960-10-19",
        "nepDate": "03.07.2017"
    },
    {
        "engDate": "1960-10-20",
        "nepDate": "04.07.2017"
    },
    {
        "engDate": "1960-10-21",
        "nepDate": "05.07.2017"
    },
    {
        "engDate": "1960-10-22",
        "nepDate": "06.07.2017"
    },
    {
        "engDate": "1960-10-23",
        "nepDate": "07.07.2017"
    },
    {
        "engDate": "1960-10-24",
        "nepDate": "08.07.2017"
    },
    {
        "engDate": "1960-10-25",
        "nepDate": "09.07.2017"
    },
    {
        "engDate": "1960-10-26",
        "nepDate": "10.07.2017"
    },
    {
        "engDate": "1960-10-27",
        "nepDate": "11.07.2017"
    },
    {
        "engDate": "1960-10-28",
        "nepDate": "12.07.2017"
    },
    {
        "engDate": "1960-10-29",
        "nepDate": "13.07.2017"
    },
    {
        "engDate": "1960-10-30",
        "nepDate": "14.07.2017"
    },
    {
        "engDate": "1960-10-31",
        "nepDate": "15.07.2017"
    },
    {
        "engDate": "1960-11-01",
        "nepDate": "16.07.2017"
    },
    {
        "engDate": "1960-11-02",
        "nepDate": "17.07.2017"
    },
    {
        "engDate": "1960-11-03",
        "nepDate": "18.07.2017"
    },
    {
        "engDate": "1960-11-04",
        "nepDate": "19.07.2017"
    },
    {
        "engDate": "1960-11-05",
        "nepDate": "20.07.2017"
    },
    {
        "engDate": "1960-11-06",
        "nepDate": "21.07.2017"
    },
    {
        "engDate": "1960-11-07",
        "nepDate": "22.07.2017"
    },
    {
        "engDate": "1960-11-08",
        "nepDate": "23.07.2017"
    },
    {
        "engDate": "1960-11-09",
        "nepDate": "24.07.2017"
    },
    {
        "engDate": "1960-11-10",
        "nepDate": "25.07.2017"
    },
    {
        "engDate": "1960-11-11",
        "nepDate": "26.07.2017"
    },
    {
        "engDate": "1960-11-12",
        "nepDate": "27.07.2017"
    },
    {
        "engDate": "1960-11-13",
        "nepDate": "28.07.2017"
    },
    {
        "engDate": "1960-11-14",
        "nepDate": "29.07.2017"
    },
    {
        "engDate": "1960-11-15",
        "nepDate": "30.07.2017"
    },
    {
        "engDate": "1960-11-16",
        "nepDate": "01.08.2017"
    },
    {
        "engDate": "1960-11-17",
        "nepDate": "02.08.2017"
    },
    {
        "engDate": "1960-11-18",
        "nepDate": "03.08.2017"
    },
    {
        "engDate": "1960-11-19",
        "nepDate": "04.08.2017"
    },
    {
        "engDate": "1960-11-20",
        "nepDate": "05.08.2017"
    },
    {
        "engDate": "1960-11-21",
        "nepDate": "06.08.2017"
    },
    {
        "engDate": "1960-11-22",
        "nepDate": "07.08.2017"
    },
    {
        "engDate": "1960-11-23",
        "nepDate": "08.08.2017"
    },
    {
        "engDate": "1960-11-24",
        "nepDate": "09.08.2017"
    },
    {
        "engDate": "1960-11-25",
        "nepDate": "10.08.2017"
    },
    {
        "engDate": "1960-11-26",
        "nepDate": "11.08.2017"
    },
    {
        "engDate": "1960-11-27",
        "nepDate": "12.08.2017"
    },
    {
        "engDate": "1960-11-28",
        "nepDate": "13.08.2017"
    },
    {
        "engDate": "1960-11-29",
        "nepDate": "14.08.2017"
    },
    {
        "engDate": "1960-11-30",
        "nepDate": "15.08.2017"
    },
    {
        "engDate": "1960-12-01",
        "nepDate": "16.08.2017"
    },
    {
        "engDate": "1960-12-02",
        "nepDate": "17.08.2017"
    },
    {
        "engDate": "1960-12-03",
        "nepDate": "18.08.2017"
    },
    {
        "engDate": "1960-12-04",
        "nepDate": "19.08.2017"
    },
    {
        "engDate": "1960-12-05",
        "nepDate": "20.08.2017"
    },
    {
        "engDate": "1960-12-06",
        "nepDate": "21.08.2017"
    },
    {
        "engDate": "1960-12-07",
        "nepDate": "22.08.2017"
    },
    {
        "engDate": "1960-12-08",
        "nepDate": "23.08.2017"
    },
    {
        "engDate": "1960-12-09",
        "nepDate": "24.08.2017"
    },
    {
        "engDate": "1960-12-10",
        "nepDate": "25.08.2017"
    },
    {
        "engDate": "1960-12-11",
        "nepDate": "26.08.2017"
    },
    {
        "engDate": "1960-12-12",
        "nepDate": "27.08.2017"
    },
    {
        "engDate": "1960-12-13",
        "nepDate": "28.08.2017"
    },
    {
        "engDate": "1960-12-14",
        "nepDate": "29.08.2017"
    },
    {
        "engDate": "1960-12-15",
        "nepDate": "01.09.2017"
    },
    {
        "engDate": "1960-12-16",
        "nepDate": "02.09.2017"
    },
    {
        "engDate": "1960-12-17",
        "nepDate": "03.09.2017"
    },
    {
        "engDate": "1960-12-18",
        "nepDate": "04.09.2017"
    },
    {
        "engDate": "1960-12-19",
        "nepDate": "05.09.2017"
    },
    {
        "engDate": "1960-12-20",
        "nepDate": "06.09.2017"
    },
    {
        "engDate": "1960-12-21",
        "nepDate": "07.09.2017"
    },
    {
        "engDate": "1960-12-22",
        "nepDate": "08.09.2017"
    },
    {
        "engDate": "1960-12-23",
        "nepDate": "09.09.2017"
    },
    {
        "engDate": "1960-12-24",
        "nepDate": "10.09.2017"
    },
    {
        "engDate": "1960-12-25",
        "nepDate": "11.09.2017"
    },
    {
        "engDate": "1960-12-26",
        "nepDate": "12.09.2017"
    },
    {
        "engDate": "1960-12-27",
        "nepDate": "13.09.2017"
    },
    {
        "engDate": "1960-12-28",
        "nepDate": "14.09.2017"
    },
    {
        "engDate": "1960-12-29",
        "nepDate": "15.09.2017"
    },
    {
        "engDate": "1960-12-30",
        "nepDate": "16.09.2017"
    },
    {
        "engDate": "1960-12-31",
        "nepDate": "17.09.2017"
    },
    {
        "engDate": "1961-01-01",
        "nepDate": "18.09.2017"
    },
    {
        "engDate": "1961-01-02",
        "nepDate": "19.09.2017"
    },
    {
        "engDate": "1961-01-03",
        "nepDate": "20.09.2017"
    },
    {
        "engDate": "1961-01-04",
        "nepDate": "21.09.2017"
    },
    {
        "engDate": "1961-01-05",
        "nepDate": "22.09.2017"
    },
    {
        "engDate": "1961-01-06",
        "nepDate": "23.09.2017"
    },
    {
        "engDate": "1961-01-07",
        "nepDate": "24.09.2017"
    },
    {
        "engDate": "1961-01-08",
        "nepDate": "25.09.2017"
    },
    {
        "engDate": "1961-01-09",
        "nepDate": "26.09.2017"
    },
    {
        "engDate": "1961-01-10",
        "nepDate": "27.09.2017"
    },
    {
        "engDate": "1961-01-11",
        "nepDate": "28.09.2017"
    },
    {
        "engDate": "1961-01-12",
        "nepDate": "29.09.2017"
    },
    {
        "engDate": "1961-01-13",
        "nepDate": "30.09.2017"
    },
    {
        "engDate": "1961-01-14",
        "nepDate": "01.10.2017"
    },
    {
        "engDate": "1961-01-15",
        "nepDate": "02.10.2017"
    },
    {
        "engDate": "1961-01-16",
        "nepDate": "03.10.2017"
    },
    {
        "engDate": "1961-01-17",
        "nepDate": "04.10.2017"
    },
    {
        "engDate": "1961-01-18",
        "nepDate": "05.10.2017"
    },
    {
        "engDate": "1961-01-19",
        "nepDate": "06.10.2017"
    },
    {
        "engDate": "1961-01-20",
        "nepDate": "07.10.2017"
    },
    {
        "engDate": "1961-01-21",
        "nepDate": "08.10.2017"
    },
    {
        "engDate": "1961-01-22",
        "nepDate": "09.10.2017"
    },
    {
        "engDate": "1961-01-23",
        "nepDate": "10.10.2017"
    },
    {
        "engDate": "1961-01-24",
        "nepDate": "11.10.2017"
    },
    {
        "engDate": "1961-01-25",
        "nepDate": "12.10.2017"
    },
    {
        "engDate": "1961-01-26",
        "nepDate": "13.10.2017"
    },
    {
        "engDate": "1961-01-27",
        "nepDate": "14.10.2017"
    },
    {
        "engDate": "1961-01-28",
        "nepDate": "15.10.2017"
    },
    {
        "engDate": "1961-01-29",
        "nepDate": "16.10.2017"
    },
    {
        "engDate": "1961-01-30",
        "nepDate": "17.10.2017"
    },
    {
        "engDate": "1961-01-31",
        "nepDate": "18.10.2017"
    },
    {
        "engDate": "1961-02-01",
        "nepDate": "19.10.2017"
    },
    {
        "engDate": "1961-02-02",
        "nepDate": "20.10.2017"
    },
    {
        "engDate": "1961-02-03",
        "nepDate": "21.10.2017"
    },
    {
        "engDate": "1961-02-04",
        "nepDate": "22.10.2017"
    },
    {
        "engDate": "1961-02-05",
        "nepDate": "23.10.2017"
    },
    {
        "engDate": "1961-02-06",
        "nepDate": "24.10.2017"
    },
    {
        "engDate": "1961-02-07",
        "nepDate": "25.10.2017"
    },
    {
        "engDate": "1961-02-08",
        "nepDate": "26.10.2017"
    },
    {
        "engDate": "1961-02-09",
        "nepDate": "27.10.2017"
    },
    {
        "engDate": "1961-02-10",
        "nepDate": "28.10.2017"
    },
    {
        "engDate": "1961-02-11",
        "nepDate": "29.10.2017"
    },
    {
        "engDate": "1961-02-12",
        "nepDate": "01.11.2017"
    },
    {
        "engDate": "1961-02-13",
        "nepDate": "02.11.2017"
    },
    {
        "engDate": "1961-02-14",
        "nepDate": "03.11.2017"
    },
    {
        "engDate": "1961-02-15",
        "nepDate": "04.11.2017"
    },
    {
        "engDate": "1961-02-16",
        "nepDate": "05.11.2017"
    },
    {
        "engDate": "1961-02-17",
        "nepDate": "06.11.2017"
    },
    {
        "engDate": "1961-02-18",
        "nepDate": "07.11.2017"
    },
    {
        "engDate": "1961-02-19",
        "nepDate": "08.11.2017"
    },
    {
        "engDate": "1961-02-20",
        "nepDate": "09.11.2017"
    },
    {
        "engDate": "1961-02-21",
        "nepDate": "10.11.2017"
    },
    {
        "engDate": "1961-02-22",
        "nepDate": "11.11.2017"
    },
    {
        "engDate": "1961-02-23",
        "nepDate": "12.11.2017"
    },
    {
        "engDate": "1961-02-24",
        "nepDate": "13.11.2017"
    },
    {
        "engDate": "1961-02-25",
        "nepDate": "14.11.2017"
    },
    {
        "engDate": "1961-02-26",
        "nepDate": "15.11.2017"
    },
    {
        "engDate": "1961-02-27",
        "nepDate": "16.11.2017"
    },
    {
        "engDate": "1961-02-28",
        "nepDate": "17.11.2017"
    },
    {
        "engDate": "1961-03-01",
        "nepDate": "18.11.2017"
    },
    {
        "engDate": "1961-03-02",
        "nepDate": "19.11.2017"
    },
    {
        "engDate": "1961-03-03",
        "nepDate": "20.11.2017"
    },
    {
        "engDate": "1961-03-04",
        "nepDate": "21.11.2017"
    },
    {
        "engDate": "1961-03-05",
        "nepDate": "22.11.2017"
    },
    {
        "engDate": "1961-03-06",
        "nepDate": "23.11.2017"
    },
    {
        "engDate": "1961-03-07",
        "nepDate": "24.11.2017"
    },
    {
        "engDate": "1961-03-08",
        "nepDate": "25.11.2017"
    },
    {
        "engDate": "1961-03-09",
        "nepDate": "26.11.2017"
    },
    {
        "engDate": "1961-03-10",
        "nepDate": "27.11.2017"
    },
    {
        "engDate": "1961-03-11",
        "nepDate": "28.11.2017"
    },
    {
        "engDate": "1961-03-12",
        "nepDate": "29.11.2017"
    },
    {
        "engDate": "1961-03-13",
        "nepDate": "30.11.2017"
    },
    {
        "engDate": "1961-03-14",
        "nepDate": "01.12.2017"
    },
    {
        "engDate": "1961-03-15",
        "nepDate": "02.12.2017"
    },
    {
        "engDate": "1961-03-16",
        "nepDate": "03.12.2017"
    },
    {
        "engDate": "1961-03-17",
        "nepDate": "04.12.2017"
    },
    {
        "engDate": "1961-03-18",
        "nepDate": "05.12.2017"
    },
    {
        "engDate": "1961-03-19",
        "nepDate": "06.12.2017"
    },
    {
        "engDate": "1961-03-20",
        "nepDate": "07.12.2017"
    },
    {
        "engDate": "1961-03-21",
        "nepDate": "08.12.2017"
    },
    {
        "engDate": "1961-03-22",
        "nepDate": "09.12.2017"
    },
    {
        "engDate": "1961-03-23",
        "nepDate": "10.12.2017"
    },
    {
        "engDate": "1961-03-24",
        "nepDate": "11.12.2017"
    },
    {
        "engDate": "1961-03-25",
        "nepDate": "12.12.2017"
    },
    {
        "engDate": "1961-03-26",
        "nepDate": "13.12.2017"
    },
    {
        "engDate": "1961-03-27",
        "nepDate": "14.12.2017"
    },
    {
        "engDate": "1961-03-28",
        "nepDate": "15.12.2017"
    },
    {
        "engDate": "1961-03-29",
        "nepDate": "16.12.2017"
    },
    {
        "engDate": "1961-03-30",
        "nepDate": "17.12.2017"
    },
    {
        "engDate": "1961-03-31",
        "nepDate": "18.12.2017"
    },
    {
        "engDate": "1961-04-01",
        "nepDate": "19.12.2017"
    },
    {
        "engDate": "1961-04-02",
        "nepDate": "20.12.2017"
    },
    {
        "engDate": "1961-04-03",
        "nepDate": "21.12.2017"
    },
    {
        "engDate": "1961-04-04",
        "nepDate": "22.12.2017"
    },
    {
        "engDate": "1961-04-05",
        "nepDate": "23.12.2017"
    },
    {
        "engDate": "1961-04-06",
        "nepDate": "24.12.2017"
    },
    {
        "engDate": "1961-04-07",
        "nepDate": "25.12.2017"
    },
    {
        "engDate": "1961-04-08",
        "nepDate": "26.12.2017"
    },
    {
        "engDate": "1961-04-09",
        "nepDate": "27.12.2017"
    },
    {
        "engDate": "1961-04-10",
        "nepDate": "28.12.2017"
    },
    {
        "engDate": "1961-04-11",
        "nepDate": "29.12.2017"
    },
    {
        "engDate": "1961-04-12",
        "nepDate": "30.12.2017"
    },
    {
        "engDate": "1961-04-13",
        "nepDate": "01.01.2018"
    },
    {
        "engDate": "1961-04-14",
        "nepDate": "02.01.2018"
    },
    {
        "engDate": "1961-04-15",
        "nepDate": "03.01.2018"
    },
    {
        "engDate": "1961-04-16",
        "nepDate": "04.01.2018"
    },
    {
        "engDate": "1961-04-17",
        "nepDate": "05.01.2018"
    },
    {
        "engDate": "1961-04-18",
        "nepDate": "06.01.2018"
    },
    {
        "engDate": "1961-04-19",
        "nepDate": "07.01.2018"
    },
    {
        "engDate": "1961-04-20",
        "nepDate": "08.01.2018"
    },
    {
        "engDate": "1961-04-21",
        "nepDate": "09.01.2018"
    },
    {
        "engDate": "1961-04-22",
        "nepDate": "10.01.2018"
    },
    {
        "engDate": "1961-04-23",
        "nepDate": "11.01.2018"
    },
    {
        "engDate": "1961-04-24",
        "nepDate": "12.01.2018"
    },
    {
        "engDate": "1961-04-25",
        "nepDate": "13.01.2018"
    },
    {
        "engDate": "1961-04-26",
        "nepDate": "14.01.2018"
    },
    {
        "engDate": "1961-04-27",
        "nepDate": "15.01.2018"
    },
    {
        "engDate": "1961-04-28",
        "nepDate": "16.01.2018"
    },
    {
        "engDate": "1961-04-29",
        "nepDate": "17.01.2018"
    },
    {
        "engDate": "1961-04-30",
        "nepDate": "18.01.2018"
    },
    {
        "engDate": "1961-05-01",
        "nepDate": "19.01.2018"
    },
    {
        "engDate": "1961-05-02",
        "nepDate": "20.01.2018"
    },
    {
        "engDate": "1961-05-03",
        "nepDate": "21.01.2018"
    },
    {
        "engDate": "1961-05-04",
        "nepDate": "22.01.2018"
    },
    {
        "engDate": "1961-05-05",
        "nepDate": "23.01.2018"
    },
    {
        "engDate": "1961-05-06",
        "nepDate": "24.01.2018"
    },
    {
        "engDate": "1961-05-07",
        "nepDate": "25.01.2018"
    },
    {
        "engDate": "1961-05-08",
        "nepDate": "26.01.2018"
    },
    {
        "engDate": "1961-05-09",
        "nepDate": "27.01.2018"
    },
    {
        "engDate": "1961-05-10",
        "nepDate": "28.01.2018"
    },
    {
        "engDate": "1961-05-11",
        "nepDate": "29.01.2018"
    },
    {
        "engDate": "1961-05-12",
        "nepDate": "30.01.2018"
    },
    {
        "engDate": "1961-05-13",
        "nepDate": "31.01.2018"
    },
    {
        "engDate": "1961-05-14",
        "nepDate": "01.02.2018"
    },
    {
        "engDate": "1961-05-15",
        "nepDate": "02.02.2018"
    },
    {
        "engDate": "1961-05-16",
        "nepDate": "03.02.2018"
    },
    {
        "engDate": "1961-05-17",
        "nepDate": "04.02.2018"
    },
    {
        "engDate": "1961-05-18",
        "nepDate": "05.02.2018"
    },
    {
        "engDate": "1961-05-19",
        "nepDate": "06.02.2018"
    },
    {
        "engDate": "1961-05-20",
        "nepDate": "07.02.2018"
    },
    {
        "engDate": "1961-05-21",
        "nepDate": "08.02.2018"
    },
    {
        "engDate": "1961-05-22",
        "nepDate": "09.02.2018"
    },
    {
        "engDate": "1961-05-23",
        "nepDate": "10.02.2018"
    },
    {
        "engDate": "1961-05-24",
        "nepDate": "11.02.2018"
    },
    {
        "engDate": "1961-05-25",
        "nepDate": "12.02.2018"
    },
    {
        "engDate": "1961-05-26",
        "nepDate": "13.02.2018"
    },
    {
        "engDate": "1961-05-27",
        "nepDate": "14.02.2018"
    },
    {
        "engDate": "1961-05-28",
        "nepDate": "15.02.2018"
    },
    {
        "engDate": "1961-05-29",
        "nepDate": "16.02.2018"
    },
    {
        "engDate": "1961-05-30",
        "nepDate": "17.02.2018"
    },
    {
        "engDate": "1961-05-31",
        "nepDate": "18.02.2018"
    },
    {
        "engDate": "1961-06-01",
        "nepDate": "19.02.2018"
    },
    {
        "engDate": "1961-06-02",
        "nepDate": "20.02.2018"
    },
    {
        "engDate": "1961-06-03",
        "nepDate": "21.02.2018"
    },
    {
        "engDate": "1961-06-04",
        "nepDate": "22.02.2018"
    },
    {
        "engDate": "1961-06-05",
        "nepDate": "23.02.2018"
    },
    {
        "engDate": "1961-06-06",
        "nepDate": "24.02.2018"
    },
    {
        "engDate": "1961-06-07",
        "nepDate": "25.02.2018"
    },
    {
        "engDate": "1961-06-08",
        "nepDate": "26.02.2018"
    },
    {
        "engDate": "1961-06-09",
        "nepDate": "27.02.2018"
    },
    {
        "engDate": "1961-06-10",
        "nepDate": "28.02.2018"
    },
    {
        "engDate": "1961-06-11",
        "nepDate": "29.02.2018"
    },
    {
        "engDate": "1961-06-12",
        "nepDate": "30.02.2018"
    },
    {
        "engDate": "1961-06-13",
        "nepDate": "31.02.2018"
    },
    {
        "engDate": "1961-06-14",
        "nepDate": "32.02.2018"
    },
    {
        "engDate": "1961-06-15",
        "nepDate": "01.03.2018"
    },
    {
        "engDate": "1961-06-16",
        "nepDate": "02.03.2018"
    },
    {
        "engDate": "1961-06-17",
        "nepDate": "03.03.2018"
    },
    {
        "engDate": "1961-06-18",
        "nepDate": "04.03.2018"
    },
    {
        "engDate": "1961-06-19",
        "nepDate": "05.03.2018"
    },
    {
        "engDate": "1961-06-20",
        "nepDate": "06.03.2018"
    },
    {
        "engDate": "1961-06-21",
        "nepDate": "07.03.2018"
    },
    {
        "engDate": "1961-06-22",
        "nepDate": "08.03.2018"
    },
    {
        "engDate": "1961-06-23",
        "nepDate": "09.03.2018"
    },
    {
        "engDate": "1961-06-24",
        "nepDate": "10.03.2018"
    },
    {
        "engDate": "1961-06-25",
        "nepDate": "11.03.2018"
    },
    {
        "engDate": "1961-06-26",
        "nepDate": "12.03.2018"
    },
    {
        "engDate": "1961-06-27",
        "nepDate": "13.03.2018"
    },
    {
        "engDate": "1961-06-28",
        "nepDate": "14.03.2018"
    },
    {
        "engDate": "1961-06-29",
        "nepDate": "15.03.2018"
    },
    {
        "engDate": "1961-06-30",
        "nepDate": "16.03.2018"
    },
    {
        "engDate": "1961-07-01",
        "nepDate": "17.03.2018"
    },
    {
        "engDate": "1961-07-02",
        "nepDate": "18.03.2018"
    },
    {
        "engDate": "1961-07-03",
        "nepDate": "19.03.2018"
    },
    {
        "engDate": "1961-07-04",
        "nepDate": "20.03.2018"
    },
    {
        "engDate": "1961-07-05",
        "nepDate": "21.03.2018"
    },
    {
        "engDate": "1961-07-06",
        "nepDate": "22.03.2018"
    },
    {
        "engDate": "1961-07-07",
        "nepDate": "23.03.2018"
    },
    {
        "engDate": "1961-07-08",
        "nepDate": "24.03.2018"
    },
    {
        "engDate": "1961-07-09",
        "nepDate": "25.03.2018"
    },
    {
        "engDate": "1961-07-10",
        "nepDate": "26.03.2018"
    },
    {
        "engDate": "1961-07-11",
        "nepDate": "27.03.2018"
    },
    {
        "engDate": "1961-07-12",
        "nepDate": "28.03.2018"
    },
    {
        "engDate": "1961-07-13",
        "nepDate": "29.03.2018"
    },
    {
        "engDate": "1961-07-14",
        "nepDate": "30.03.2018"
    },
    {
        "engDate": "1961-07-15",
        "nepDate": "31.03.2018"
    },
    {
        "engDate": "1961-07-16",
        "nepDate": "01.04.2018"
    },
    {
        "engDate": "1961-07-17",
        "nepDate": "02.04.2018"
    },
    {
        "engDate": "1961-07-18",
        "nepDate": "03.04.2018"
    },
    {
        "engDate": "1961-07-19",
        "nepDate": "04.04.2018"
    },
    {
        "engDate": "1961-07-20",
        "nepDate": "05.04.2018"
    },
    {
        "engDate": "1961-07-21",
        "nepDate": "06.04.2018"
    },
    {
        "engDate": "1961-07-22",
        "nepDate": "07.04.2018"
    },
    {
        "engDate": "1961-07-23",
        "nepDate": "08.04.2018"
    },
    {
        "engDate": "1961-07-24",
        "nepDate": "09.04.2018"
    },
    {
        "engDate": "1961-07-25",
        "nepDate": "10.04.2018"
    },
    {
        "engDate": "1961-07-26",
        "nepDate": "11.04.2018"
    },
    {
        "engDate": "1961-07-27",
        "nepDate": "12.04.2018"
    },
    {
        "engDate": "1961-07-28",
        "nepDate": "13.04.2018"
    },
    {
        "engDate": "1961-07-29",
        "nepDate": "14.04.2018"
    },
    {
        "engDate": "1961-07-30",
        "nepDate": "15.04.2018"
    },
    {
        "engDate": "1961-07-31",
        "nepDate": "16.04.2018"
    },
    {
        "engDate": "1961-08-01",
        "nepDate": "17.04.2018"
    },
    {
        "engDate": "1961-08-02",
        "nepDate": "18.04.2018"
    },
    {
        "engDate": "1961-08-03",
        "nepDate": "19.04.2018"
    },
    {
        "engDate": "1961-08-04",
        "nepDate": "20.04.2018"
    },
    {
        "engDate": "1961-08-05",
        "nepDate": "21.04.2018"
    },
    {
        "engDate": "1961-08-06",
        "nepDate": "22.04.2018"
    },
    {
        "engDate": "1961-08-07",
        "nepDate": "23.04.2018"
    },
    {
        "engDate": "1961-08-08",
        "nepDate": "24.04.2018"
    },
    {
        "engDate": "1961-08-09",
        "nepDate": "25.04.2018"
    },
    {
        "engDate": "1961-08-10",
        "nepDate": "26.04.2018"
    },
    {
        "engDate": "1961-08-11",
        "nepDate": "27.04.2018"
    },
    {
        "engDate": "1961-08-12",
        "nepDate": "28.04.2018"
    },
    {
        "engDate": "1961-08-13",
        "nepDate": "29.04.2018"
    },
    {
        "engDate": "1961-08-14",
        "nepDate": "30.04.2018"
    },
    {
        "engDate": "1961-08-15",
        "nepDate": "31.04.2018"
    },
    {
        "engDate": "1961-08-16",
        "nepDate": "32.04.2018"
    },
    {
        "engDate": "1961-08-17",
        "nepDate": "01.05.2018"
    },
    {
        "engDate": "1961-08-18",
        "nepDate": "02.05.2018"
    },
    {
        "engDate": "1961-08-19",
        "nepDate": "03.05.2018"
    },
    {
        "engDate": "1961-08-20",
        "nepDate": "04.05.2018"
    },
    {
        "engDate": "1961-08-21",
        "nepDate": "05.05.2018"
    },
    {
        "engDate": "1961-08-22",
        "nepDate": "06.05.2018"
    },
    {
        "engDate": "1961-08-23",
        "nepDate": "07.05.2018"
    },
    {
        "engDate": "1961-08-24",
        "nepDate": "08.05.2018"
    },
    {
        "engDate": "1961-08-25",
        "nepDate": "09.05.2018"
    },
    {
        "engDate": "1961-08-26",
        "nepDate": "10.05.2018"
    },
    {
        "engDate": "1961-08-27",
        "nepDate": "11.05.2018"
    },
    {
        "engDate": "1961-08-28",
        "nepDate": "12.05.2018"
    },
    {
        "engDate": "1961-08-29",
        "nepDate": "13.05.2018"
    },
    {
        "engDate": "1961-08-30",
        "nepDate": "14.05.2018"
    },
    {
        "engDate": "1961-08-31",
        "nepDate": "15.05.2018"
    },
    {
        "engDate": "1961-09-01",
        "nepDate": "16.05.2018"
    },
    {
        "engDate": "1961-09-02",
        "nepDate": "17.05.2018"
    },
    {
        "engDate": "1961-09-03",
        "nepDate": "18.05.2018"
    },
    {
        "engDate": "1961-09-04",
        "nepDate": "19.05.2018"
    },
    {
        "engDate": "1961-09-05",
        "nepDate": "20.05.2018"
    },
    {
        "engDate": "1961-09-06",
        "nepDate": "21.05.2018"
    },
    {
        "engDate": "1961-09-07",
        "nepDate": "22.05.2018"
    },
    {
        "engDate": "1961-09-08",
        "nepDate": "23.05.2018"
    },
    {
        "engDate": "1961-09-09",
        "nepDate": "24.05.2018"
    },
    {
        "engDate": "1961-09-10",
        "nepDate": "25.05.2018"
    },
    {
        "engDate": "1961-09-11",
        "nepDate": "26.05.2018"
    },
    {
        "engDate": "1961-09-12",
        "nepDate": "27.05.2018"
    },
    {
        "engDate": "1961-09-13",
        "nepDate": "28.05.2018"
    },
    {
        "engDate": "1961-09-14",
        "nepDate": "29.05.2018"
    },
    {
        "engDate": "1961-09-15",
        "nepDate": "30.05.2018"
    },
    {
        "engDate": "1961-09-16",
        "nepDate": "31.05.2018"
    },
    {
        "engDate": "1961-09-17",
        "nepDate": "01.06.2018"
    },
    {
        "engDate": "1961-09-18",
        "nepDate": "02.06.2018"
    },
    {
        "engDate": "1961-09-19",
        "nepDate": "03.06.2018"
    },
    {
        "engDate": "1961-09-20",
        "nepDate": "04.06.2018"
    },
    {
        "engDate": "1961-09-21",
        "nepDate": "05.06.2018"
    },
    {
        "engDate": "1961-09-22",
        "nepDate": "06.06.2018"
    },
    {
        "engDate": "1961-09-23",
        "nepDate": "07.06.2018"
    },
    {
        "engDate": "1961-09-24",
        "nepDate": "08.06.2018"
    },
    {
        "engDate": "1961-09-25",
        "nepDate": "09.06.2018"
    },
    {
        "engDate": "1961-09-26",
        "nepDate": "10.06.2018"
    },
    {
        "engDate": "1961-09-27",
        "nepDate": "11.06.2018"
    },
    {
        "engDate": "1961-09-28",
        "nepDate": "12.06.2018"
    },
    {
        "engDate": "1961-09-29",
        "nepDate": "13.06.2018"
    },
    {
        "engDate": "1961-09-30",
        "nepDate": "14.06.2018"
    },
    {
        "engDate": "1961-10-01",
        "nepDate": "15.06.2018"
    },
    {
        "engDate": "1961-10-02",
        "nepDate": "16.06.2018"
    },
    {
        "engDate": "1961-10-03",
        "nepDate": "17.06.2018"
    },
    {
        "engDate": "1961-10-04",
        "nepDate": "18.06.2018"
    },
    {
        "engDate": "1961-10-05",
        "nepDate": "19.06.2018"
    },
    {
        "engDate": "1961-10-06",
        "nepDate": "20.06.2018"
    },
    {
        "engDate": "1961-10-07",
        "nepDate": "21.06.2018"
    },
    {
        "engDate": "1961-10-08",
        "nepDate": "22.06.2018"
    },
    {
        "engDate": "1961-10-09",
        "nepDate": "23.06.2018"
    },
    {
        "engDate": "1961-10-10",
        "nepDate": "24.06.2018"
    },
    {
        "engDate": "1961-10-11",
        "nepDate": "25.06.2018"
    },
    {
        "engDate": "1961-10-12",
        "nepDate": "26.06.2018"
    },
    {
        "engDate": "1961-10-13",
        "nepDate": "27.06.2018"
    },
    {
        "engDate": "1961-10-14",
        "nepDate": "28.06.2018"
    },
    {
        "engDate": "1961-10-15",
        "nepDate": "29.06.2018"
    },
    {
        "engDate": "1961-10-16",
        "nepDate": "30.06.2018"
    },
    {
        "engDate": "1961-10-17",
        "nepDate": "01.07.2018"
    },
    {
        "engDate": "1961-10-18",
        "nepDate": "02.07.2018"
    },
    {
        "engDate": "1961-10-19",
        "nepDate": "03.07.2018"
    },
    {
        "engDate": "1961-10-20",
        "nepDate": "04.07.2018"
    },
    {
        "engDate": "1961-10-21",
        "nepDate": "05.07.2018"
    },
    {
        "engDate": "1961-10-22",
        "nepDate": "06.07.2018"
    },
    {
        "engDate": "1961-10-23",
        "nepDate": "07.07.2018"
    },
    {
        "engDate": "1961-10-24",
        "nepDate": "08.07.2018"
    },
    {
        "engDate": "1961-10-25",
        "nepDate": "09.07.2018"
    },
    {
        "engDate": "1961-10-26",
        "nepDate": "10.07.2018"
    },
    {
        "engDate": "1961-10-27",
        "nepDate": "11.07.2018"
    },
    {
        "engDate": "1961-10-28",
        "nepDate": "12.07.2018"
    },
    {
        "engDate": "1961-10-29",
        "nepDate": "13.07.2018"
    },
    {
        "engDate": "1961-10-30",
        "nepDate": "14.07.2018"
    },
    {
        "engDate": "1961-10-31",
        "nepDate": "15.07.2018"
    },
    {
        "engDate": "1961-11-01",
        "nepDate": "16.07.2018"
    },
    {
        "engDate": "1961-11-02",
        "nepDate": "17.07.2018"
    },
    {
        "engDate": "1961-11-03",
        "nepDate": "18.07.2018"
    },
    {
        "engDate": "1961-11-04",
        "nepDate": "19.07.2018"
    },
    {
        "engDate": "1961-11-05",
        "nepDate": "20.07.2018"
    },
    {
        "engDate": "1961-11-06",
        "nepDate": "21.07.2018"
    },
    {
        "engDate": "1961-11-07",
        "nepDate": "22.07.2018"
    },
    {
        "engDate": "1961-11-08",
        "nepDate": "23.07.2018"
    },
    {
        "engDate": "1961-11-09",
        "nepDate": "24.07.2018"
    },
    {
        "engDate": "1961-11-10",
        "nepDate": "25.07.2018"
    },
    {
        "engDate": "1961-11-11",
        "nepDate": "26.07.2018"
    },
    {
        "engDate": "1961-11-12",
        "nepDate": "27.07.2018"
    },
    {
        "engDate": "1961-11-13",
        "nepDate": "28.07.2018"
    },
    {
        "engDate": "1961-11-14",
        "nepDate": "29.07.2018"
    },
    {
        "engDate": "1961-11-15",
        "nepDate": "30.07.2018"
    },
    {
        "engDate": "1961-11-16",
        "nepDate": "01.08.2018"
    },
    {
        "engDate": "1961-11-17",
        "nepDate": "02.08.2018"
    },
    {
        "engDate": "1961-11-18",
        "nepDate": "03.08.2018"
    },
    {
        "engDate": "1961-11-19",
        "nepDate": "04.08.2018"
    },
    {
        "engDate": "1961-11-20",
        "nepDate": "05.08.2018"
    },
    {
        "engDate": "1961-11-21",
        "nepDate": "06.08.2018"
    },
    {
        "engDate": "1961-11-22",
        "nepDate": "07.08.2018"
    },
    {
        "engDate": "1961-11-23",
        "nepDate": "08.08.2018"
    },
    {
        "engDate": "1961-11-24",
        "nepDate": "09.08.2018"
    },
    {
        "engDate": "1961-11-25",
        "nepDate": "10.08.2018"
    },
    {
        "engDate": "1961-11-26",
        "nepDate": "11.08.2018"
    },
    {
        "engDate": "1961-11-27",
        "nepDate": "12.08.2018"
    },
    {
        "engDate": "1961-11-28",
        "nepDate": "13.08.2018"
    },
    {
        "engDate": "1961-11-29",
        "nepDate": "14.08.2018"
    },
    {
        "engDate": "1961-11-30",
        "nepDate": "15.08.2018"
    },
    {
        "engDate": "1961-12-01",
        "nepDate": "16.08.2018"
    },
    {
        "engDate": "1961-12-02",
        "nepDate": "17.08.2018"
    },
    {
        "engDate": "1961-12-03",
        "nepDate": "18.08.2018"
    },
    {
        "engDate": "1961-12-04",
        "nepDate": "19.08.2018"
    },
    {
        "engDate": "1961-12-05",
        "nepDate": "20.08.2018"
    },
    {
        "engDate": "1961-12-06",
        "nepDate": "21.08.2018"
    },
    {
        "engDate": "1961-12-07",
        "nepDate": "22.08.2018"
    },
    {
        "engDate": "1961-12-08",
        "nepDate": "23.08.2018"
    },
    {
        "engDate": "1961-12-09",
        "nepDate": "24.08.2018"
    },
    {
        "engDate": "1961-12-10",
        "nepDate": "25.08.2018"
    },
    {
        "engDate": "1961-12-11",
        "nepDate": "26.08.2018"
    },
    {
        "engDate": "1961-12-12",
        "nepDate": "27.08.2018"
    },
    {
        "engDate": "1961-12-13",
        "nepDate": "28.08.2018"
    },
    {
        "engDate": "1961-12-14",
        "nepDate": "29.08.2018"
    },
    {
        "engDate": "1961-12-15",
        "nepDate": "01.09.2018"
    },
    {
        "engDate": "1961-12-16",
        "nepDate": "02.09.2018"
    },
    {
        "engDate": "1961-12-17",
        "nepDate": "03.09.2018"
    },
    {
        "engDate": "1961-12-18",
        "nepDate": "04.09.2018"
    },
    {
        "engDate": "1961-12-19",
        "nepDate": "05.09.2018"
    },
    {
        "engDate": "1961-12-20",
        "nepDate": "06.09.2018"
    },
    {
        "engDate": "1961-12-21",
        "nepDate": "07.09.2018"
    },
    {
        "engDate": "1961-12-22",
        "nepDate": "08.09.2018"
    },
    {
        "engDate": "1961-12-23",
        "nepDate": "09.09.2018"
    },
    {
        "engDate": "1961-12-24",
        "nepDate": "10.09.2018"
    },
    {
        "engDate": "1961-12-25",
        "nepDate": "11.09.2018"
    },
    {
        "engDate": "1961-12-26",
        "nepDate": "12.09.2018"
    },
    {
        "engDate": "1961-12-27",
        "nepDate": "13.09.2018"
    },
    {
        "engDate": "1961-12-28",
        "nepDate": "14.09.2018"
    },
    {
        "engDate": "1961-12-29",
        "nepDate": "15.09.2018"
    },
    {
        "engDate": "1961-12-30",
        "nepDate": "16.09.2018"
    },
    {
        "engDate": "1961-12-31",
        "nepDate": "17.09.2018"
    },
    {
        "engDate": "1962-01-01",
        "nepDate": "18.09.2018"
    },
    {
        "engDate": "1962-01-02",
        "nepDate": "19.09.2018"
    },
    {
        "engDate": "1962-01-03",
        "nepDate": "20.09.2018"
    },
    {
        "engDate": "1962-01-04",
        "nepDate": "21.09.2018"
    },
    {
        "engDate": "1962-01-05",
        "nepDate": "22.09.2018"
    },
    {
        "engDate": "1962-01-06",
        "nepDate": "23.09.2018"
    },
    {
        "engDate": "1962-01-07",
        "nepDate": "24.09.2018"
    },
    {
        "engDate": "1962-01-08",
        "nepDate": "25.09.2018"
    },
    {
        "engDate": "1962-01-09",
        "nepDate": "26.09.2018"
    },
    {
        "engDate": "1962-01-10",
        "nepDate": "27.09.2018"
    },
    {
        "engDate": "1962-01-11",
        "nepDate": "28.09.2018"
    },
    {
        "engDate": "1962-01-12",
        "nepDate": "29.09.2018"
    },
    {
        "engDate": "1962-01-13",
        "nepDate": "30.09.2018"
    },
    {
        "engDate": "1962-01-14",
        "nepDate": "01.10.2018"
    },
    {
        "engDate": "1962-01-15",
        "nepDate": "02.10.2018"
    },
    {
        "engDate": "1962-01-16",
        "nepDate": "03.10.2018"
    },
    {
        "engDate": "1962-01-17",
        "nepDate": "04.10.2018"
    },
    {
        "engDate": "1962-01-18",
        "nepDate": "05.10.2018"
    },
    {
        "engDate": "1962-01-19",
        "nepDate": "06.10.2018"
    },
    {
        "engDate": "1962-01-20",
        "nepDate": "07.10.2018"
    },
    {
        "engDate": "1962-01-21",
        "nepDate": "08.10.2018"
    },
    {
        "engDate": "1962-01-22",
        "nepDate": "09.10.2018"
    },
    {
        "engDate": "1962-01-23",
        "nepDate": "10.10.2018"
    },
    {
        "engDate": "1962-01-24",
        "nepDate": "11.10.2018"
    },
    {
        "engDate": "1962-01-25",
        "nepDate": "12.10.2018"
    },
    {
        "engDate": "1962-01-26",
        "nepDate": "13.10.2018"
    },
    {
        "engDate": "1962-01-27",
        "nepDate": "14.10.2018"
    },
    {
        "engDate": "1962-01-28",
        "nepDate": "15.10.2018"
    },
    {
        "engDate": "1962-01-29",
        "nepDate": "16.10.2018"
    },
    {
        "engDate": "1962-01-30",
        "nepDate": "17.10.2018"
    },
    {
        "engDate": "1962-01-31",
        "nepDate": "18.10.2018"
    },
    {
        "engDate": "1962-02-01",
        "nepDate": "19.10.2018"
    },
    {
        "engDate": "1962-02-02",
        "nepDate": "20.10.2018"
    },
    {
        "engDate": "1962-02-03",
        "nepDate": "21.10.2018"
    },
    {
        "engDate": "1962-02-04",
        "nepDate": "22.10.2018"
    },
    {
        "engDate": "1962-02-05",
        "nepDate": "23.10.2018"
    },
    {
        "engDate": "1962-02-06",
        "nepDate": "24.10.2018"
    },
    {
        "engDate": "1962-02-07",
        "nepDate": "25.10.2018"
    },
    {
        "engDate": "1962-02-08",
        "nepDate": "26.10.2018"
    },
    {
        "engDate": "1962-02-09",
        "nepDate": "27.10.2018"
    },
    {
        "engDate": "1962-02-10",
        "nepDate": "28.10.2018"
    },
    {
        "engDate": "1962-02-11",
        "nepDate": "29.10.2018"
    },
    {
        "engDate": "1962-02-12",
        "nepDate": "01.11.2018"
    },
    {
        "engDate": "1962-02-13",
        "nepDate": "02.11.2018"
    },
    {
        "engDate": "1962-02-14",
        "nepDate": "03.11.2018"
    },
    {
        "engDate": "1962-02-15",
        "nepDate": "04.11.2018"
    },
    {
        "engDate": "1962-02-16",
        "nepDate": "05.11.2018"
    },
    {
        "engDate": "1962-02-17",
        "nepDate": "06.11.2018"
    },
    {
        "engDate": "1962-02-18",
        "nepDate": "07.11.2018"
    },
    {
        "engDate": "1962-02-19",
        "nepDate": "08.11.2018"
    },
    {
        "engDate": "1962-02-20",
        "nepDate": "09.11.2018"
    },
    {
        "engDate": "1962-02-21",
        "nepDate": "10.11.2018"
    },
    {
        "engDate": "1962-02-22",
        "nepDate": "11.11.2018"
    },
    {
        "engDate": "1962-02-23",
        "nepDate": "12.11.2018"
    },
    {
        "engDate": "1962-02-24",
        "nepDate": "13.11.2018"
    },
    {
        "engDate": "1962-02-25",
        "nepDate": "14.11.2018"
    },
    {
        "engDate": "1962-02-26",
        "nepDate": "15.11.2018"
    },
    {
        "engDate": "1962-02-27",
        "nepDate": "16.11.2018"
    },
    {
        "engDate": "1962-02-28",
        "nepDate": "17.11.2018"
    },
    {
        "engDate": "1962-03-01",
        "nepDate": "18.11.2018"
    },
    {
        "engDate": "1962-03-02",
        "nepDate": "19.11.2018"
    },
    {
        "engDate": "1962-03-03",
        "nepDate": "20.11.2018"
    },
    {
        "engDate": "1962-03-04",
        "nepDate": "21.11.2018"
    },
    {
        "engDate": "1962-03-05",
        "nepDate": "22.11.2018"
    },
    {
        "engDate": "1962-03-06",
        "nepDate": "23.11.2018"
    },
    {
        "engDate": "1962-03-07",
        "nepDate": "24.11.2018"
    },
    {
        "engDate": "1962-03-08",
        "nepDate": "25.11.2018"
    },
    {
        "engDate": "1962-03-09",
        "nepDate": "26.11.2018"
    },
    {
        "engDate": "1962-03-10",
        "nepDate": "27.11.2018"
    },
    {
        "engDate": "1962-03-11",
        "nepDate": "28.11.2018"
    },
    {
        "engDate": "1962-03-12",
        "nepDate": "29.11.2018"
    },
    {
        "engDate": "1962-03-13",
        "nepDate": "30.11.2018"
    },
    {
        "engDate": "1962-03-14",
        "nepDate": "01.12.2018"
    },
    {
        "engDate": "1962-03-15",
        "nepDate": "02.12.2018"
    },
    {
        "engDate": "1962-03-16",
        "nepDate": "03.12.2018"
    },
    {
        "engDate": "1962-03-17",
        "nepDate": "04.12.2018"
    },
    {
        "engDate": "1962-03-18",
        "nepDate": "05.12.2018"
    },
    {
        "engDate": "1962-03-19",
        "nepDate": "06.12.2018"
    },
    {
        "engDate": "1962-03-20",
        "nepDate": "07.12.2018"
    },
    {
        "engDate": "1962-03-21",
        "nepDate": "08.12.2018"
    },
    {
        "engDate": "1962-03-22",
        "nepDate": "09.12.2018"
    },
    {
        "engDate": "1962-03-23",
        "nepDate": "10.12.2018"
    },
    {
        "engDate": "1962-03-24",
        "nepDate": "11.12.2018"
    },
    {
        "engDate": "1962-03-25",
        "nepDate": "12.12.2018"
    },
    {
        "engDate": "1962-03-26",
        "nepDate": "13.12.2018"
    },
    {
        "engDate": "1962-03-27",
        "nepDate": "14.12.2018"
    },
    {
        "engDate": "1962-03-28",
        "nepDate": "15.12.2018"
    },
    {
        "engDate": "1962-03-29",
        "nepDate": "16.12.2018"
    },
    {
        "engDate": "1962-03-30",
        "nepDate": "17.12.2018"
    },
    {
        "engDate": "1962-03-31",
        "nepDate": "18.12.2018"
    },
    {
        "engDate": "1962-04-01",
        "nepDate": "19.12.2018"
    },
    {
        "engDate": "1962-04-02",
        "nepDate": "20.12.2018"
    },
    {
        "engDate": "1962-04-03",
        "nepDate": "21.12.2018"
    },
    {
        "engDate": "1962-04-04",
        "nepDate": "22.12.2018"
    },
    {
        "engDate": "1962-04-05",
        "nepDate": "23.12.2018"
    },
    {
        "engDate": "1962-04-06",
        "nepDate": "24.12.2018"
    },
    {
        "engDate": "1962-04-07",
        "nepDate": "25.12.2018"
    },
    {
        "engDate": "1962-04-08",
        "nepDate": "26.12.2018"
    },
    {
        "engDate": "1962-04-09",
        "nepDate": "27.12.2018"
    },
    {
        "engDate": "1962-04-10",
        "nepDate": "28.12.2018"
    },
    {
        "engDate": "1962-04-11",
        "nepDate": "29.12.2018"
    },
    {
        "engDate": "1962-04-12",
        "nepDate": "30.12.2018"
    },
    {
        "engDate": "1962-04-13",
        "nepDate": "01.01.2019"
    },
    {
        "engDate": "1962-04-14",
        "nepDate": "02.01.2019"
    },
    {
        "engDate": "1962-04-15",
        "nepDate": "03.01.2019"
    },
    {
        "engDate": "1962-04-16",
        "nepDate": "04.01.2019"
    },
    {
        "engDate": "1962-04-17",
        "nepDate": "05.01.2019"
    },
    {
        "engDate": "1962-04-18",
        "nepDate": "06.01.2019"
    },
    {
        "engDate": "1962-04-19",
        "nepDate": "07.01.2019"
    },
    {
        "engDate": "1962-04-20",
        "nepDate": "08.01.2019"
    },
    {
        "engDate": "1962-04-21",
        "nepDate": "09.01.2019"
    },
    {
        "engDate": "1962-04-22",
        "nepDate": "10.01.2019"
    },
    {
        "engDate": "1962-04-23",
        "nepDate": "11.01.2019"
    },
    {
        "engDate": "1962-04-24",
        "nepDate": "12.01.2019"
    },
    {
        "engDate": "1962-04-25",
        "nepDate": "13.01.2019"
    },
    {
        "engDate": "1962-04-26",
        "nepDate": "14.01.2019"
    },
    {
        "engDate": "1962-04-27",
        "nepDate": "15.01.2019"
    },
    {
        "engDate": "1962-04-28",
        "nepDate": "16.01.2019"
    },
    {
        "engDate": "1962-04-29",
        "nepDate": "17.01.2019"
    },
    {
        "engDate": "1962-04-30",
        "nepDate": "18.01.2019"
    },
    {
        "engDate": "1962-05-01",
        "nepDate": "19.01.2019"
    },
    {
        "engDate": "1962-05-02",
        "nepDate": "20.01.2019"
    },
    {
        "engDate": "1962-05-03",
        "nepDate": "21.01.2019"
    },
    {
        "engDate": "1962-05-04",
        "nepDate": "22.01.2019"
    },
    {
        "engDate": "1962-05-05",
        "nepDate": "23.01.2019"
    },
    {
        "engDate": "1962-05-06",
        "nepDate": "24.01.2019"
    },
    {
        "engDate": "1962-05-07",
        "nepDate": "25.01.2019"
    },
    {
        "engDate": "1962-05-08",
        "nepDate": "26.01.2019"
    },
    {
        "engDate": "1962-05-09",
        "nepDate": "27.01.2019"
    },
    {
        "engDate": "1962-05-10",
        "nepDate": "28.01.2019"
    },
    {
        "engDate": "1962-05-11",
        "nepDate": "29.01.2019"
    },
    {
        "engDate": "1962-05-12",
        "nepDate": "30.01.2019"
    },
    {
        "engDate": "1962-05-13",
        "nepDate": "31.01.2019"
    },
    {
        "engDate": "1962-05-14",
        "nepDate": "01.02.2019"
    },
    {
        "engDate": "1962-05-15",
        "nepDate": "02.02.2019"
    },
    {
        "engDate": "1962-05-16",
        "nepDate": "03.02.2019"
    },
    {
        "engDate": "1962-05-17",
        "nepDate": "04.02.2019"
    },
    {
        "engDate": "1962-05-18",
        "nepDate": "05.02.2019"
    },
    {
        "engDate": "1962-05-19",
        "nepDate": "06.02.2019"
    },
    {
        "engDate": "1962-05-20",
        "nepDate": "07.02.2019"
    },
    {
        "engDate": "1962-05-21",
        "nepDate": "08.02.2019"
    },
    {
        "engDate": "1962-05-22",
        "nepDate": "09.02.2019"
    },
    {
        "engDate": "1962-05-23",
        "nepDate": "10.02.2019"
    },
    {
        "engDate": "1962-05-24",
        "nepDate": "11.02.2019"
    },
    {
        "engDate": "1962-05-25",
        "nepDate": "12.02.2019"
    },
    {
        "engDate": "1962-05-26",
        "nepDate": "13.02.2019"
    },
    {
        "engDate": "1962-05-27",
        "nepDate": "14.02.2019"
    },
    {
        "engDate": "1962-05-28",
        "nepDate": "15.02.2019"
    },
    {
        "engDate": "1962-05-29",
        "nepDate": "16.02.2019"
    },
    {
        "engDate": "1962-05-30",
        "nepDate": "17.02.2019"
    },
    {
        "engDate": "1962-05-31",
        "nepDate": "18.02.2019"
    },
    {
        "engDate": "1962-06-01",
        "nepDate": "19.02.2019"
    },
    {
        "engDate": "1962-06-02",
        "nepDate": "20.02.2019"
    },
    {
        "engDate": "1962-06-03",
        "nepDate": "21.02.2019"
    },
    {
        "engDate": "1962-06-04",
        "nepDate": "22.02.2019"
    },
    {
        "engDate": "1962-06-05",
        "nepDate": "23.02.2019"
    },
    {
        "engDate": "1962-06-06",
        "nepDate": "24.02.2019"
    },
    {
        "engDate": "1962-06-07",
        "nepDate": "25.02.2019"
    },
    {
        "engDate": "1962-06-08",
        "nepDate": "26.02.2019"
    },
    {
        "engDate": "1962-06-09",
        "nepDate": "27.02.2019"
    },
    {
        "engDate": "1962-06-10",
        "nepDate": "28.02.2019"
    },
    {
        "engDate": "1962-06-11",
        "nepDate": "29.02.2019"
    },
    {
        "engDate": "1962-06-12",
        "nepDate": "30.02.2019"
    },
    {
        "engDate": "1962-06-13",
        "nepDate": "31.02.2019"
    },
    {
        "engDate": "1962-06-14",
        "nepDate": "32.02.2019"
    },
    {
        "engDate": "1962-06-15",
        "nepDate": "01.03.2019"
    },
    {
        "engDate": "1962-06-16",
        "nepDate": "02.03.2019"
    },
    {
        "engDate": "1962-06-17",
        "nepDate": "03.03.2019"
    },
    {
        "engDate": "1962-06-18",
        "nepDate": "04.03.2019"
    },
    {
        "engDate": "1962-06-19",
        "nepDate": "05.03.2019"
    },
    {
        "engDate": "1962-06-20",
        "nepDate": "06.03.2019"
    },
    {
        "engDate": "1962-06-21",
        "nepDate": "07.03.2019"
    },
    {
        "engDate": "1962-06-22",
        "nepDate": "08.03.2019"
    },
    {
        "engDate": "1962-06-23",
        "nepDate": "09.03.2019"
    },
    {
        "engDate": "1962-06-24",
        "nepDate": "10.03.2019"
    },
    {
        "engDate": "1962-06-25",
        "nepDate": "11.03.2019"
    },
    {
        "engDate": "1962-06-26",
        "nepDate": "12.03.2019"
    },
    {
        "engDate": "1962-06-27",
        "nepDate": "13.03.2019"
    },
    {
        "engDate": "1962-06-28",
        "nepDate": "14.03.2019"
    },
    {
        "engDate": "1962-06-29",
        "nepDate": "15.03.2019"
    },
    {
        "engDate": "1962-06-30",
        "nepDate": "16.03.2019"
    },
    {
        "engDate": "1962-07-01",
        "nepDate": "17.03.2019"
    },
    {
        "engDate": "1962-07-02",
        "nepDate": "18.03.2019"
    },
    {
        "engDate": "1962-07-03",
        "nepDate": "19.03.2019"
    },
    {
        "engDate": "1962-07-04",
        "nepDate": "20.03.2019"
    },
    {
        "engDate": "1962-07-05",
        "nepDate": "21.03.2019"
    },
    {
        "engDate": "1962-07-06",
        "nepDate": "22.03.2019"
    },
    {
        "engDate": "1962-07-07",
        "nepDate": "23.03.2019"
    },
    {
        "engDate": "1962-07-08",
        "nepDate": "24.03.2019"
    },
    {
        "engDate": "1962-07-09",
        "nepDate": "25.03.2019"
    },
    {
        "engDate": "1962-07-10",
        "nepDate": "26.03.2019"
    },
    {
        "engDate": "1962-07-11",
        "nepDate": "27.03.2019"
    },
    {
        "engDate": "1962-07-12",
        "nepDate": "28.03.2019"
    },
    {
        "engDate": "1962-07-13",
        "nepDate": "29.03.2019"
    },
    {
        "engDate": "1962-07-14",
        "nepDate": "30.03.2019"
    },
    {
        "engDate": "1962-07-15",
        "nepDate": "31.03.2019"
    },
    {
        "engDate": "1962-07-16",
        "nepDate": "01.04.2019"
    },
    {
        "engDate": "1962-07-17",
        "nepDate": "02.04.2019"
    },
    {
        "engDate": "1962-07-18",
        "nepDate": "03.04.2019"
    },
    {
        "engDate": "1962-07-19",
        "nepDate": "04.04.2019"
    },
    {
        "engDate": "1962-07-20",
        "nepDate": "05.04.2019"
    },
    {
        "engDate": "1962-07-21",
        "nepDate": "06.04.2019"
    },
    {
        "engDate": "1962-07-22",
        "nepDate": "07.04.2019"
    },
    {
        "engDate": "1962-07-23",
        "nepDate": "08.04.2019"
    },
    {
        "engDate": "1962-07-24",
        "nepDate": "09.04.2019"
    },
    {
        "engDate": "1962-07-25",
        "nepDate": "10.04.2019"
    },
    {
        "engDate": "1962-07-26",
        "nepDate": "11.04.2019"
    },
    {
        "engDate": "1962-07-27",
        "nepDate": "12.04.2019"
    },
    {
        "engDate": "1962-07-28",
        "nepDate": "13.04.2019"
    },
    {
        "engDate": "1962-07-29",
        "nepDate": "14.04.2019"
    },
    {
        "engDate": "1962-07-30",
        "nepDate": "15.04.2019"
    },
    {
        "engDate": "1962-07-31",
        "nepDate": "16.04.2019"
    },
    {
        "engDate": "1962-08-01",
        "nepDate": "17.04.2019"
    },
    {
        "engDate": "1962-08-02",
        "nepDate": "18.04.2019"
    },
    {
        "engDate": "1962-08-03",
        "nepDate": "19.04.2019"
    },
    {
        "engDate": "1962-08-04",
        "nepDate": "20.04.2019"
    },
    {
        "engDate": "1962-08-05",
        "nepDate": "21.04.2019"
    },
    {
        "engDate": "1962-08-06",
        "nepDate": "22.04.2019"
    },
    {
        "engDate": "1962-08-07",
        "nepDate": "23.04.2019"
    },
    {
        "engDate": "1962-08-08",
        "nepDate": "24.04.2019"
    },
    {
        "engDate": "1962-08-09",
        "nepDate": "25.04.2019"
    },
    {
        "engDate": "1962-08-10",
        "nepDate": "26.04.2019"
    },
    {
        "engDate": "1962-08-11",
        "nepDate": "27.04.2019"
    },
    {
        "engDate": "1962-08-12",
        "nepDate": "28.04.2019"
    },
    {
        "engDate": "1962-08-13",
        "nepDate": "29.04.2019"
    },
    {
        "engDate": "1962-08-14",
        "nepDate": "30.04.2019"
    },
    {
        "engDate": "1962-08-15",
        "nepDate": "31.04.2019"
    },
    {
        "engDate": "1962-08-16",
        "nepDate": "32.04.2019"
    },
    {
        "engDate": "1962-08-17",
        "nepDate": "01.05.2019"
    },
    {
        "engDate": "1962-08-18",
        "nepDate": "02.05.2019"
    },
    {
        "engDate": "1962-08-19",
        "nepDate": "03.05.2019"
    },
    {
        "engDate": "1962-08-20",
        "nepDate": "04.05.2019"
    },
    {
        "engDate": "1962-08-21",
        "nepDate": "05.05.2019"
    },
    {
        "engDate": "1962-08-22",
        "nepDate": "06.05.2019"
    },
    {
        "engDate": "1962-08-23",
        "nepDate": "07.05.2019"
    },
    {
        "engDate": "1962-08-24",
        "nepDate": "08.05.2019"
    },
    {
        "engDate": "1962-08-25",
        "nepDate": "09.05.2019"
    },
    {
        "engDate": "1962-08-26",
        "nepDate": "10.05.2019"
    },
    {
        "engDate": "1962-08-27",
        "nepDate": "11.05.2019"
    },
    {
        "engDate": "1962-08-28",
        "nepDate": "12.05.2019"
    },
    {
        "engDate": "1962-08-29",
        "nepDate": "13.05.2019"
    },
    {
        "engDate": "1962-08-30",
        "nepDate": "14.05.2019"
    },
    {
        "engDate": "1962-08-31",
        "nepDate": "15.05.2019"
    },
    {
        "engDate": "1962-09-01",
        "nepDate": "16.05.2019"
    },
    {
        "engDate": "1962-09-02",
        "nepDate": "17.05.2019"
    },
    {
        "engDate": "1962-09-03",
        "nepDate": "18.05.2019"
    },
    {
        "engDate": "1962-09-04",
        "nepDate": "19.05.2019"
    },
    {
        "engDate": "1962-09-05",
        "nepDate": "20.05.2019"
    },
    {
        "engDate": "1962-09-06",
        "nepDate": "21.05.2019"
    },
    {
        "engDate": "1962-09-07",
        "nepDate": "22.05.2019"
    },
    {
        "engDate": "1962-09-08",
        "nepDate": "23.05.2019"
    },
    {
        "engDate": "1962-09-09",
        "nepDate": "24.05.2019"
    },
    {
        "engDate": "1962-09-10",
        "nepDate": "25.05.2019"
    },
    {
        "engDate": "1962-09-11",
        "nepDate": "26.05.2019"
    },
    {
        "engDate": "1962-09-12",
        "nepDate": "27.05.2019"
    },
    {
        "engDate": "1962-09-13",
        "nepDate": "28.05.2019"
    },
    {
        "engDate": "1962-09-14",
        "nepDate": "29.05.2019"
    },
    {
        "engDate": "1962-09-15",
        "nepDate": "30.05.2019"
    },
    {
        "engDate": "1962-09-16",
        "nepDate": "31.05.2019"
    },
    {
        "engDate": "1962-09-17",
        "nepDate": "01.06.2019"
    },
    {
        "engDate": "1962-09-18",
        "nepDate": "02.06.2019"
    },
    {
        "engDate": "1962-09-19",
        "nepDate": "03.06.2019"
    },
    {
        "engDate": "1962-09-20",
        "nepDate": "04.06.2019"
    },
    {
        "engDate": "1962-09-21",
        "nepDate": "05.06.2019"
    },
    {
        "engDate": "1962-09-22",
        "nepDate": "06.06.2019"
    },
    {
        "engDate": "1962-09-23",
        "nepDate": "07.06.2019"
    },
    {
        "engDate": "1962-09-24",
        "nepDate": "08.06.2019"
    },
    {
        "engDate": "1962-09-25",
        "nepDate": "09.06.2019"
    },
    {
        "engDate": "1962-09-26",
        "nepDate": "10.06.2019"
    },
    {
        "engDate": "1962-09-27",
        "nepDate": "11.06.2019"
    },
    {
        "engDate": "1962-09-28",
        "nepDate": "12.06.2019"
    },
    {
        "engDate": "1962-09-29",
        "nepDate": "13.06.2019"
    },
    {
        "engDate": "1962-09-30",
        "nepDate": "14.06.2019"
    },
    {
        "engDate": "1962-10-01",
        "nepDate": "15.06.2019"
    },
    {
        "engDate": "1962-10-02",
        "nepDate": "16.06.2019"
    },
    {
        "engDate": "1962-10-03",
        "nepDate": "17.06.2019"
    },
    {
        "engDate": "1962-10-04",
        "nepDate": "18.06.2019"
    },
    {
        "engDate": "1962-10-05",
        "nepDate": "19.06.2019"
    },
    {
        "engDate": "1962-10-06",
        "nepDate": "20.06.2019"
    },
    {
        "engDate": "1962-10-07",
        "nepDate": "21.06.2019"
    },
    {
        "engDate": "1962-10-08",
        "nepDate": "22.06.2019"
    },
    {
        "engDate": "1962-10-09",
        "nepDate": "23.06.2019"
    },
    {
        "engDate": "1962-10-10",
        "nepDate": "24.06.2019"
    },
    {
        "engDate": "1962-10-11",
        "nepDate": "25.06.2019"
    },
    {
        "engDate": "1962-10-12",
        "nepDate": "26.06.2019"
    },
    {
        "engDate": "1962-10-13",
        "nepDate": "27.06.2019"
    },
    {
        "engDate": "1962-10-14",
        "nepDate": "28.06.2019"
    },
    {
        "engDate": "1962-10-15",
        "nepDate": "29.06.2019"
    },
    {
        "engDate": "1962-10-16",
        "nepDate": "30.06.2019"
    },
    {
        "engDate": "1962-10-17",
        "nepDate": "01.07.2019"
    },
    {
        "engDate": "1962-10-18",
        "nepDate": "02.07.2019"
    },
    {
        "engDate": "1962-10-19",
        "nepDate": "03.07.2019"
    },
    {
        "engDate": "1962-10-20",
        "nepDate": "04.07.2019"
    },
    {
        "engDate": "1962-10-21",
        "nepDate": "05.07.2019"
    },
    {
        "engDate": "1962-10-22",
        "nepDate": "06.07.2019"
    },
    {
        "engDate": "1962-10-23",
        "nepDate": "07.07.2019"
    },
    {
        "engDate": "1962-10-24",
        "nepDate": "08.07.2019"
    },
    {
        "engDate": "1962-10-25",
        "nepDate": "09.07.2019"
    },
    {
        "engDate": "1962-10-26",
        "nepDate": "10.07.2019"
    },
    {
        "engDate": "1962-10-27",
        "nepDate": "11.07.2019"
    },
    {
        "engDate": "1962-10-28",
        "nepDate": "12.07.2019"
    },
    {
        "engDate": "1962-10-29",
        "nepDate": "13.07.2019"
    },
    {
        "engDate": "1962-10-30",
        "nepDate": "14.07.2019"
    },
    {
        "engDate": "1962-10-31",
        "nepDate": "15.07.2019"
    },
    {
        "engDate": "1962-11-01",
        "nepDate": "16.07.2019"
    },
    {
        "engDate": "1962-11-02",
        "nepDate": "17.07.2019"
    },
    {
        "engDate": "1962-11-03",
        "nepDate": "18.07.2019"
    },
    {
        "engDate": "1962-11-04",
        "nepDate": "19.07.2019"
    },
    {
        "engDate": "1962-11-05",
        "nepDate": "20.07.2019"
    },
    {
        "engDate": "1962-11-06",
        "nepDate": "21.07.2019"
    },
    {
        "engDate": "1962-11-07",
        "nepDate": "22.07.2019"
    },
    {
        "engDate": "1962-11-08",
        "nepDate": "23.07.2019"
    },
    {
        "engDate": "1962-11-09",
        "nepDate": "24.07.2019"
    },
    {
        "engDate": "1962-11-10",
        "nepDate": "25.07.2019"
    },
    {
        "engDate": "1962-11-11",
        "nepDate": "26.07.2019"
    },
    {
        "engDate": "1962-11-12",
        "nepDate": "27.07.2019"
    },
    {
        "engDate": "1962-11-13",
        "nepDate": "28.07.2019"
    },
    {
        "engDate": "1962-11-14",
        "nepDate": "29.07.2019"
    },
    {
        "engDate": "1962-11-15",
        "nepDate": "30.07.2019"
    },
    {
        "engDate": "1962-11-16",
        "nepDate": "01.08.2019"
    },
    {
        "engDate": "1962-11-17",
        "nepDate": "02.08.2019"
    },
    {
        "engDate": "1962-11-18",
        "nepDate": "03.08.2019"
    },
    {
        "engDate": "1962-11-19",
        "nepDate": "04.08.2019"
    },
    {
        "engDate": "1962-11-20",
        "nepDate": "05.08.2019"
    },
    {
        "engDate": "1962-11-21",
        "nepDate": "06.08.2019"
    },
    {
        "engDate": "1962-11-22",
        "nepDate": "07.08.2019"
    },
    {
        "engDate": "1962-11-23",
        "nepDate": "08.08.2019"
    },
    {
        "engDate": "1962-11-24",
        "nepDate": "09.08.2019"
    },
    {
        "engDate": "1962-11-25",
        "nepDate": "10.08.2019"
    },
    {
        "engDate": "1962-11-26",
        "nepDate": "11.08.2019"
    },
    {
        "engDate": "1962-11-27",
        "nepDate": "12.08.2019"
    },
    {
        "engDate": "1962-11-28",
        "nepDate": "13.08.2019"
    },
    {
        "engDate": "1962-11-29",
        "nepDate": "14.08.2019"
    },
    {
        "engDate": "1962-11-30",
        "nepDate": "15.08.2019"
    },
    {
        "engDate": "1962-12-01",
        "nepDate": "16.08.2019"
    },
    {
        "engDate": "1962-12-02",
        "nepDate": "17.08.2019"
    },
    {
        "engDate": "1962-12-03",
        "nepDate": "18.08.2019"
    },
    {
        "engDate": "1962-12-04",
        "nepDate": "19.08.2019"
    },
    {
        "engDate": "1962-12-05",
        "nepDate": "20.08.2019"
    },
    {
        "engDate": "1962-12-06",
        "nepDate": "21.08.2019"
    },
    {
        "engDate": "1962-12-07",
        "nepDate": "22.08.2019"
    },
    {
        "engDate": "1962-12-08",
        "nepDate": "23.08.2019"
    },
    {
        "engDate": "1962-12-09",
        "nepDate": "24.08.2019"
    },
    {
        "engDate": "1962-12-10",
        "nepDate": "25.08.2019"
    },
    {
        "engDate": "1962-12-11",
        "nepDate": "26.08.2019"
    },
    {
        "engDate": "1962-12-12",
        "nepDate": "27.08.2019"
    },
    {
        "engDate": "1962-12-13",
        "nepDate": "28.08.2019"
    },
    {
        "engDate": "1962-12-14",
        "nepDate": "29.08.2019"
    },
    {
        "engDate": "1962-12-15",
        "nepDate": "30.08.2019"
    },
    {
        "engDate": "1962-12-16",
        "nepDate": "01.09.2019"
    },
    {
        "engDate": "1962-12-17",
        "nepDate": "02.09.2019"
    },
    {
        "engDate": "1962-12-18",
        "nepDate": "03.09.2019"
    },
    {
        "engDate": "1962-12-19",
        "nepDate": "04.09.2019"
    },
    {
        "engDate": "1962-12-20",
        "nepDate": "05.09.2019"
    },
    {
        "engDate": "1962-12-21",
        "nepDate": "06.09.2019"
    },
    {
        "engDate": "1962-12-22",
        "nepDate": "07.09.2019"
    },
    {
        "engDate": "1962-12-23",
        "nepDate": "08.09.2019"
    },
    {
        "engDate": "1962-12-24",
        "nepDate": "09.09.2019"
    },
    {
        "engDate": "1962-12-25",
        "nepDate": "10.09.2019"
    },
    {
        "engDate": "1962-12-26",
        "nepDate": "11.09.2019"
    },
    {
        "engDate": "1962-12-27",
        "nepDate": "12.09.2019"
    },
    {
        "engDate": "1962-12-28",
        "nepDate": "13.09.2019"
    },
    {
        "engDate": "1962-12-29",
        "nepDate": "14.09.2019"
    },
    {
        "engDate": "1962-12-30",
        "nepDate": "15.09.2019"
    },
    {
        "engDate": "1962-12-31",
        "nepDate": "16.09.2019"
    },
    {
        "engDate": "1963-01-01",
        "nepDate": "17.09.2019"
    },
    {
        "engDate": "1963-01-02",
        "nepDate": "18.09.2019"
    },
    {
        "engDate": "1963-01-03",
        "nepDate": "19.09.2019"
    },
    {
        "engDate": "1963-01-04",
        "nepDate": "20.09.2019"
    },
    {
        "engDate": "1963-01-05",
        "nepDate": "21.09.2019"
    },
    {
        "engDate": "1963-01-06",
        "nepDate": "22.09.2019"
    },
    {
        "engDate": "1963-01-07",
        "nepDate": "23.09.2019"
    },
    {
        "engDate": "1963-01-08",
        "nepDate": "24.09.2019"
    },
    {
        "engDate": "1963-01-09",
        "nepDate": "25.09.2019"
    },
    {
        "engDate": "1963-01-10",
        "nepDate": "26.09.2019"
    },
    {
        "engDate": "1963-01-11",
        "nepDate": "27.09.2019"
    },
    {
        "engDate": "1963-01-12",
        "nepDate": "28.09.2019"
    },
    {
        "engDate": "1963-01-13",
        "nepDate": "29.09.2019"
    },
    {
        "engDate": "1963-01-14",
        "nepDate": "01.10.2019"
    },
    {
        "engDate": "1963-01-15",
        "nepDate": "02.10.2019"
    },
    {
        "engDate": "1963-01-16",
        "nepDate": "03.10.2019"
    },
    {
        "engDate": "1963-01-17",
        "nepDate": "04.10.2019"
    },
    {
        "engDate": "1963-01-18",
        "nepDate": "05.10.2019"
    },
    {
        "engDate": "1963-01-19",
        "nepDate": "06.10.2019"
    },
    {
        "engDate": "1963-01-20",
        "nepDate": "07.10.2019"
    },
    {
        "engDate": "1963-01-21",
        "nepDate": "08.10.2019"
    },
    {
        "engDate": "1963-01-22",
        "nepDate": "09.10.2019"
    },
    {
        "engDate": "1963-01-23",
        "nepDate": "10.10.2019"
    },
    {
        "engDate": "1963-01-24",
        "nepDate": "11.10.2019"
    },
    {
        "engDate": "1963-01-25",
        "nepDate": "12.10.2019"
    },
    {
        "engDate": "1963-01-26",
        "nepDate": "13.10.2019"
    },
    {
        "engDate": "1963-01-27",
        "nepDate": "14.10.2019"
    },
    {
        "engDate": "1963-01-28",
        "nepDate": "15.10.2019"
    },
    {
        "engDate": "1963-01-29",
        "nepDate": "16.10.2019"
    },
    {
        "engDate": "1963-01-30",
        "nepDate": "17.10.2019"
    },
    {
        "engDate": "1963-01-31",
        "nepDate": "18.10.2019"
    },
    {
        "engDate": "1963-02-01",
        "nepDate": "19.10.2019"
    },
    {
        "engDate": "1963-02-02",
        "nepDate": "20.10.2019"
    },
    {
        "engDate": "1963-02-03",
        "nepDate": "21.10.2019"
    },
    {
        "engDate": "1963-02-04",
        "nepDate": "22.10.2019"
    },
    {
        "engDate": "1963-02-05",
        "nepDate": "23.10.2019"
    },
    {
        "engDate": "1963-02-06",
        "nepDate": "24.10.2019"
    },
    {
        "engDate": "1963-02-07",
        "nepDate": "25.10.2019"
    },
    {
        "engDate": "1963-02-08",
        "nepDate": "26.10.2019"
    },
    {
        "engDate": "1963-02-09",
        "nepDate": "27.10.2019"
    },
    {
        "engDate": "1963-02-10",
        "nepDate": "28.10.2019"
    },
    {
        "engDate": "1963-02-11",
        "nepDate": "29.10.2019"
    },
    {
        "engDate": "1963-02-12",
        "nepDate": "30.10.2019"
    },
    {
        "engDate": "1963-02-13",
        "nepDate": "01.11.2019"
    },
    {
        "engDate": "1963-02-14",
        "nepDate": "02.11.2019"
    },
    {
        "engDate": "1963-02-15",
        "nepDate": "03.11.2019"
    },
    {
        "engDate": "1963-02-16",
        "nepDate": "04.11.2019"
    },
    {
        "engDate": "1963-02-17",
        "nepDate": "05.11.2019"
    },
    {
        "engDate": "1963-02-18",
        "nepDate": "06.11.2019"
    },
    {
        "engDate": "1963-02-19",
        "nepDate": "07.11.2019"
    },
    {
        "engDate": "1963-02-20",
        "nepDate": "08.11.2019"
    },
    {
        "engDate": "1963-02-21",
        "nepDate": "09.11.2019"
    },
    {
        "engDate": "1963-02-22",
        "nepDate": "10.11.2019"
    },
    {
        "engDate": "1963-02-23",
        "nepDate": "11.11.2019"
    },
    {
        "engDate": "1963-02-24",
        "nepDate": "12.11.2019"
    },
    {
        "engDate": "1963-02-25",
        "nepDate": "13.11.2019"
    },
    {
        "engDate": "1963-02-26",
        "nepDate": "14.11.2019"
    },
    {
        "engDate": "1963-02-27",
        "nepDate": "15.11.2019"
    },
    {
        "engDate": "1963-02-28",
        "nepDate": "16.11.2019"
    },
    {
        "engDate": "1963-03-01",
        "nepDate": "17.11.2019"
    },
    {
        "engDate": "1963-03-02",
        "nepDate": "18.11.2019"
    },
    {
        "engDate": "1963-03-03",
        "nepDate": "19.11.2019"
    },
    {
        "engDate": "1963-03-04",
        "nepDate": "20.11.2019"
    },
    {
        "engDate": "1963-03-05",
        "nepDate": "21.11.2019"
    },
    {
        "engDate": "1963-03-06",
        "nepDate": "22.11.2019"
    },
    {
        "engDate": "1963-03-07",
        "nepDate": "23.11.2019"
    },
    {
        "engDate": "1963-03-08",
        "nepDate": "24.11.2019"
    },
    {
        "engDate": "1963-03-09",
        "nepDate": "25.11.2019"
    },
    {
        "engDate": "1963-03-10",
        "nepDate": "26.11.2019"
    },
    {
        "engDate": "1963-03-11",
        "nepDate": "27.11.2019"
    },
    {
        "engDate": "1963-03-12",
        "nepDate": "28.11.2019"
    },
    {
        "engDate": "1963-03-13",
        "nepDate": "29.11.2019"
    },
    {
        "engDate": "1963-03-14",
        "nepDate": "01.12.2019"
    },
    {
        "engDate": "1963-03-15",
        "nepDate": "02.12.2019"
    },
    {
        "engDate": "1963-03-16",
        "nepDate": "03.12.2019"
    },
    {
        "engDate": "1963-03-17",
        "nepDate": "04.12.2019"
    },
    {
        "engDate": "1963-03-18",
        "nepDate": "05.12.2019"
    },
    {
        "engDate": "1963-03-19",
        "nepDate": "06.12.2019"
    },
    {
        "engDate": "1963-03-20",
        "nepDate": "07.12.2019"
    },
    {
        "engDate": "1963-03-21",
        "nepDate": "08.12.2019"
    },
    {
        "engDate": "1963-03-22",
        "nepDate": "09.12.2019"
    },
    {
        "engDate": "1963-03-23",
        "nepDate": "10.12.2019"
    },
    {
        "engDate": "1963-03-24",
        "nepDate": "11.12.2019"
    },
    {
        "engDate": "1963-03-25",
        "nepDate": "12.12.2019"
    },
    {
        "engDate": "1963-03-26",
        "nepDate": "13.12.2019"
    },
    {
        "engDate": "1963-03-27",
        "nepDate": "14.12.2019"
    },
    {
        "engDate": "1963-03-28",
        "nepDate": "15.12.2019"
    },
    {
        "engDate": "1963-03-29",
        "nepDate": "16.12.2019"
    },
    {
        "engDate": "1963-03-30",
        "nepDate": "17.12.2019"
    },
    {
        "engDate": "1963-03-31",
        "nepDate": "18.12.2019"
    },
    {
        "engDate": "1963-04-01",
        "nepDate": "19.12.2019"
    },
    {
        "engDate": "1963-04-02",
        "nepDate": "20.12.2019"
    },
    {
        "engDate": "1963-04-03",
        "nepDate": "21.12.2019"
    },
    {
        "engDate": "1963-04-04",
        "nepDate": "22.12.2019"
    },
    {
        "engDate": "1963-04-05",
        "nepDate": "23.12.2019"
    },
    {
        "engDate": "1963-04-06",
        "nepDate": "24.12.2019"
    },
    {
        "engDate": "1963-04-07",
        "nepDate": "25.12.2019"
    },
    {
        "engDate": "1963-04-08",
        "nepDate": "26.12.2019"
    },
    {
        "engDate": "1963-04-09",
        "nepDate": "27.12.2019"
    },
    {
        "engDate": "1963-04-10",
        "nepDate": "28.12.2019"
    },
    {
        "engDate": "1963-04-11",
        "nepDate": "29.12.2019"
    },
    {
        "engDate": "1963-04-12",
        "nepDate": "30.12.2019"
    },
    {
        "engDate": "1963-04-13",
        "nepDate": "31.12.2019"
    },
    {
        "engDate": "1963-04-14",
        "nepDate": "01.01.2020"
    },
    {
        "engDate": "1963-04-15",
        "nepDate": "02.01.2020"
    },
    {
        "engDate": "1963-04-16",
        "nepDate": "03.01.2020"
    },
    {
        "engDate": "1963-04-17",
        "nepDate": "04.01.2020"
    },
    {
        "engDate": "1963-04-18",
        "nepDate": "05.01.2020"
    },
    {
        "engDate": "1963-04-19",
        "nepDate": "06.01.2020"
    },
    {
        "engDate": "1963-04-20",
        "nepDate": "07.01.2020"
    },
    {
        "engDate": "1963-04-21",
        "nepDate": "08.01.2020"
    },
    {
        "engDate": "1963-04-22",
        "nepDate": "09.01.2020"
    },
    {
        "engDate": "1963-04-23",
        "nepDate": "10.01.2020"
    },
    {
        "engDate": "1963-04-24",
        "nepDate": "11.01.2020"
    },
    {
        "engDate": "1963-04-25",
        "nepDate": "12.01.2020"
    },
    {
        "engDate": "1963-04-26",
        "nepDate": "13.01.2020"
    },
    {
        "engDate": "1963-04-27",
        "nepDate": "14.01.2020"
    },
    {
        "engDate": "1963-04-28",
        "nepDate": "15.01.2020"
    },
    {
        "engDate": "1963-04-29",
        "nepDate": "16.01.2020"
    },
    {
        "engDate": "1963-04-30",
        "nepDate": "17.01.2020"
    },
    {
        "engDate": "1963-05-01",
        "nepDate": "18.01.2020"
    },
    {
        "engDate": "1963-05-02",
        "nepDate": "19.01.2020"
    },
    {
        "engDate": "1963-05-03",
        "nepDate": "20.01.2020"
    },
    {
        "engDate": "1963-05-04",
        "nepDate": "21.01.2020"
    },
    {
        "engDate": "1963-05-05",
        "nepDate": "22.01.2020"
    },
    {
        "engDate": "1963-05-06",
        "nepDate": "23.01.2020"
    },
    {
        "engDate": "1963-05-07",
        "nepDate": "24.01.2020"
    },
    {
        "engDate": "1963-05-08",
        "nepDate": "25.01.2020"
    },
    {
        "engDate": "1963-05-09",
        "nepDate": "26.01.2020"
    },
    {
        "engDate": "1963-05-10",
        "nepDate": "27.01.2020"
    },
    {
        "engDate": "1963-05-11",
        "nepDate": "28.01.2020"
    },
    {
        "engDate": "1963-05-12",
        "nepDate": "29.01.2020"
    },
    {
        "engDate": "1963-05-13",
        "nepDate": "30.01.2020"
    },
    {
        "engDate": "1963-05-14",
        "nepDate": "31.01.2020"
    },
    {
        "engDate": "1963-05-15",
        "nepDate": "01.02.2020"
    },
    {
        "engDate": "1963-05-16",
        "nepDate": "02.02.2020"
    },
    {
        "engDate": "1963-05-17",
        "nepDate": "03.02.2020"
    },
    {
        "engDate": "1963-05-18",
        "nepDate": "04.02.2020"
    },
    {
        "engDate": "1963-05-19",
        "nepDate": "05.02.2020"
    },
    {
        "engDate": "1963-05-20",
        "nepDate": "06.02.2020"
    },
    {
        "engDate": "1963-05-21",
        "nepDate": "07.02.2020"
    },
    {
        "engDate": "1963-05-22",
        "nepDate": "08.02.2020"
    },
    {
        "engDate": "1963-05-23",
        "nepDate": "09.02.2020"
    },
    {
        "engDate": "1963-05-24",
        "nepDate": "10.02.2020"
    },
    {
        "engDate": "1963-05-25",
        "nepDate": "11.02.2020"
    },
    {
        "engDate": "1963-05-26",
        "nepDate": "12.02.2020"
    },
    {
        "engDate": "1963-05-27",
        "nepDate": "13.02.2020"
    },
    {
        "engDate": "1963-05-28",
        "nepDate": "14.02.2020"
    },
    {
        "engDate": "1963-05-29",
        "nepDate": "15.02.2020"
    },
    {
        "engDate": "1963-05-30",
        "nepDate": "16.02.2020"
    },
    {
        "engDate": "1963-05-31",
        "nepDate": "17.02.2020"
    },
    {
        "engDate": "1963-06-01",
        "nepDate": "18.02.2020"
    },
    {
        "engDate": "1963-06-02",
        "nepDate": "19.02.2020"
    },
    {
        "engDate": "1963-06-03",
        "nepDate": "20.02.2020"
    },
    {
        "engDate": "1963-06-04",
        "nepDate": "21.02.2020"
    },
    {
        "engDate": "1963-06-05",
        "nepDate": "22.02.2020"
    },
    {
        "engDate": "1963-06-06",
        "nepDate": "23.02.2020"
    },
    {
        "engDate": "1963-06-07",
        "nepDate": "24.02.2020"
    },
    {
        "engDate": "1963-06-08",
        "nepDate": "25.02.2020"
    },
    {
        "engDate": "1963-06-09",
        "nepDate": "26.02.2020"
    },
    {
        "engDate": "1963-06-10",
        "nepDate": "27.02.2020"
    },
    {
        "engDate": "1963-06-11",
        "nepDate": "28.02.2020"
    },
    {
        "engDate": "1963-06-12",
        "nepDate": "29.02.2020"
    },
    {
        "engDate": "1963-06-13",
        "nepDate": "30.02.2020"
    },
    {
        "engDate": "1963-06-14",
        "nepDate": "31.02.2020"
    },
    {
        "engDate": "1963-06-15",
        "nepDate": "01.03.2020"
    },
    {
        "engDate": "1963-06-16",
        "nepDate": "02.03.2020"
    },
    {
        "engDate": "1963-06-17",
        "nepDate": "03.03.2020"
    },
    {
        "engDate": "1963-06-18",
        "nepDate": "04.03.2020"
    },
    {
        "engDate": "1963-06-19",
        "nepDate": "05.03.2020"
    },
    {
        "engDate": "1963-06-20",
        "nepDate": "06.03.2020"
    },
    {
        "engDate": "1963-06-21",
        "nepDate": "07.03.2020"
    },
    {
        "engDate": "1963-06-22",
        "nepDate": "08.03.2020"
    },
    {
        "engDate": "1963-06-23",
        "nepDate": "09.03.2020"
    },
    {
        "engDate": "1963-06-24",
        "nepDate": "10.03.2020"
    },
    {
        "engDate": "1963-06-25",
        "nepDate": "11.03.2020"
    },
    {
        "engDate": "1963-06-26",
        "nepDate": "12.03.2020"
    },
    {
        "engDate": "1963-06-27",
        "nepDate": "13.03.2020"
    },
    {
        "engDate": "1963-06-28",
        "nepDate": "14.03.2020"
    },
    {
        "engDate": "1963-06-29",
        "nepDate": "15.03.2020"
    },
    {
        "engDate": "1963-06-30",
        "nepDate": "16.03.2020"
    },
    {
        "engDate": "1963-07-01",
        "nepDate": "17.03.2020"
    },
    {
        "engDate": "1963-07-02",
        "nepDate": "18.03.2020"
    },
    {
        "engDate": "1963-07-03",
        "nepDate": "19.03.2020"
    },
    {
        "engDate": "1963-07-04",
        "nepDate": "20.03.2020"
    },
    {
        "engDate": "1963-07-05",
        "nepDate": "21.03.2020"
    },
    {
        "engDate": "1963-07-06",
        "nepDate": "22.03.2020"
    },
    {
        "engDate": "1963-07-07",
        "nepDate": "23.03.2020"
    },
    {
        "engDate": "1963-07-08",
        "nepDate": "24.03.2020"
    },
    {
        "engDate": "1963-07-09",
        "nepDate": "25.03.2020"
    },
    {
        "engDate": "1963-07-10",
        "nepDate": "26.03.2020"
    },
    {
        "engDate": "1963-07-11",
        "nepDate": "27.03.2020"
    },
    {
        "engDate": "1963-07-12",
        "nepDate": "28.03.2020"
    },
    {
        "engDate": "1963-07-13",
        "nepDate": "29.03.2020"
    },
    {
        "engDate": "1963-07-14",
        "nepDate": "30.03.2020"
    },
    {
        "engDate": "1963-07-15",
        "nepDate": "31.03.2020"
    },
    {
        "engDate": "1963-07-16",
        "nepDate": "01.04.2020"
    },
    {
        "engDate": "1963-07-17",
        "nepDate": "02.04.2020"
    },
    {
        "engDate": "1963-07-18",
        "nepDate": "03.04.2020"
    },
    {
        "engDate": "1963-07-19",
        "nepDate": "04.04.2020"
    },
    {
        "engDate": "1963-07-20",
        "nepDate": "05.04.2020"
    },
    {
        "engDate": "1963-07-21",
        "nepDate": "06.04.2020"
    },
    {
        "engDate": "1963-07-22",
        "nepDate": "07.04.2020"
    },
    {
        "engDate": "1963-07-23",
        "nepDate": "08.04.2020"
    },
    {
        "engDate": "1963-07-24",
        "nepDate": "09.04.2020"
    },
    {
        "engDate": "1963-07-25",
        "nepDate": "10.04.2020"
    },
    {
        "engDate": "1963-07-26",
        "nepDate": "11.04.2020"
    },
    {
        "engDate": "1963-07-27",
        "nepDate": "12.04.2020"
    },
    {
        "engDate": "1963-07-28",
        "nepDate": "13.04.2020"
    },
    {
        "engDate": "1963-07-29",
        "nepDate": "14.04.2020"
    },
    {
        "engDate": "1963-07-30",
        "nepDate": "15.04.2020"
    },
    {
        "engDate": "1963-07-31",
        "nepDate": "16.04.2020"
    },
    {
        "engDate": "1963-08-01",
        "nepDate": "17.04.2020"
    },
    {
        "engDate": "1963-08-02",
        "nepDate": "18.04.2020"
    },
    {
        "engDate": "1963-08-03",
        "nepDate": "19.04.2020"
    },
    {
        "engDate": "1963-08-04",
        "nepDate": "20.04.2020"
    },
    {
        "engDate": "1963-08-05",
        "nepDate": "21.04.2020"
    },
    {
        "engDate": "1963-08-06",
        "nepDate": "22.04.2020"
    },
    {
        "engDate": "1963-08-07",
        "nepDate": "23.04.2020"
    },
    {
        "engDate": "1963-08-08",
        "nepDate": "24.04.2020"
    },
    {
        "engDate": "1963-08-09",
        "nepDate": "25.04.2020"
    },
    {
        "engDate": "1963-08-10",
        "nepDate": "26.04.2020"
    },
    {
        "engDate": "1963-08-11",
        "nepDate": "27.04.2020"
    },
    {
        "engDate": "1963-08-12",
        "nepDate": "28.04.2020"
    },
    {
        "engDate": "1963-08-13",
        "nepDate": "29.04.2020"
    },
    {
        "engDate": "1963-08-14",
        "nepDate": "30.04.2020"
    },
    {
        "engDate": "1963-08-15",
        "nepDate": "31.04.2020"
    },
    {
        "engDate": "1963-08-16",
        "nepDate": "32.04.2020"
    },
    {
        "engDate": "1963-08-17",
        "nepDate": "01.05.2020"
    },
    {
        "engDate": "1963-08-18",
        "nepDate": "02.05.2020"
    },
    {
        "engDate": "1963-08-19",
        "nepDate": "03.05.2020"
    },
    {
        "engDate": "1963-08-20",
        "nepDate": "04.05.2020"
    },
    {
        "engDate": "1963-08-21",
        "nepDate": "05.05.2020"
    },
    {
        "engDate": "1963-08-22",
        "nepDate": "06.05.2020"
    },
    {
        "engDate": "1963-08-23",
        "nepDate": "07.05.2020"
    },
    {
        "engDate": "1963-08-24",
        "nepDate": "08.05.2020"
    },
    {
        "engDate": "1963-08-25",
        "nepDate": "09.05.2020"
    },
    {
        "engDate": "1963-08-26",
        "nepDate": "10.05.2020"
    },
    {
        "engDate": "1963-08-27",
        "nepDate": "11.05.2020"
    },
    {
        "engDate": "1963-08-28",
        "nepDate": "12.05.2020"
    },
    {
        "engDate": "1963-08-29",
        "nepDate": "13.05.2020"
    },
    {
        "engDate": "1963-08-30",
        "nepDate": "14.05.2020"
    },
    {
        "engDate": "1963-08-31",
        "nepDate": "15.05.2020"
    },
    {
        "engDate": "1963-09-01",
        "nepDate": "16.05.2020"
    },
    {
        "engDate": "1963-09-02",
        "nepDate": "17.05.2020"
    },
    {
        "engDate": "1963-09-03",
        "nepDate": "18.05.2020"
    },
    {
        "engDate": "1963-09-04",
        "nepDate": "19.05.2020"
    },
    {
        "engDate": "1963-09-05",
        "nepDate": "20.05.2020"
    },
    {
        "engDate": "1963-09-06",
        "nepDate": "21.05.2020"
    },
    {
        "engDate": "1963-09-07",
        "nepDate": "22.05.2020"
    },
    {
        "engDate": "1963-09-08",
        "nepDate": "23.05.2020"
    },
    {
        "engDate": "1963-09-09",
        "nepDate": "24.05.2020"
    },
    {
        "engDate": "1963-09-10",
        "nepDate": "25.05.2020"
    },
    {
        "engDate": "1963-09-11",
        "nepDate": "26.05.2020"
    },
    {
        "engDate": "1963-09-12",
        "nepDate": "27.05.2020"
    },
    {
        "engDate": "1963-09-13",
        "nepDate": "28.05.2020"
    },
    {
        "engDate": "1963-09-14",
        "nepDate": "29.05.2020"
    },
    {
        "engDate": "1963-09-15",
        "nepDate": "30.05.2020"
    },
    {
        "engDate": "1963-09-16",
        "nepDate": "31.05.2020"
    },
    {
        "engDate": "1963-09-17",
        "nepDate": "01.06.2020"
    },
    {
        "engDate": "1963-09-18",
        "nepDate": "02.06.2020"
    },
    {
        "engDate": "1963-09-19",
        "nepDate": "03.06.2020"
    },
    {
        "engDate": "1963-09-20",
        "nepDate": "04.06.2020"
    },
    {
        "engDate": "1963-09-21",
        "nepDate": "05.06.2020"
    },
    {
        "engDate": "1963-09-22",
        "nepDate": "06.06.2020"
    },
    {
        "engDate": "1963-09-23",
        "nepDate": "07.06.2020"
    },
    {
        "engDate": "1963-09-24",
        "nepDate": "08.06.2020"
    },
    {
        "engDate": "1963-09-25",
        "nepDate": "09.06.2020"
    },
    {
        "engDate": "1963-09-26",
        "nepDate": "10.06.2020"
    },
    {
        "engDate": "1963-09-27",
        "nepDate": "11.06.2020"
    },
    {
        "engDate": "1963-09-28",
        "nepDate": "12.06.2020"
    },
    {
        "engDate": "1963-09-29",
        "nepDate": "13.06.2020"
    },
    {
        "engDate": "1963-09-30",
        "nepDate": "14.06.2020"
    },
    {
        "engDate": "1963-10-01",
        "nepDate": "15.06.2020"
    },
    {
        "engDate": "1963-10-02",
        "nepDate": "16.06.2020"
    },
    {
        "engDate": "1963-10-03",
        "nepDate": "17.06.2020"
    },
    {
        "engDate": "1963-10-04",
        "nepDate": "18.06.2020"
    },
    {
        "engDate": "1963-10-05",
        "nepDate": "19.06.2020"
    },
    {
        "engDate": "1963-10-06",
        "nepDate": "20.06.2020"
    },
    {
        "engDate": "1963-10-07",
        "nepDate": "21.06.2020"
    },
    {
        "engDate": "1963-10-08",
        "nepDate": "22.06.2020"
    },
    {
        "engDate": "1963-10-09",
        "nepDate": "23.06.2020"
    },
    {
        "engDate": "1963-10-10",
        "nepDate": "24.06.2020"
    },
    {
        "engDate": "1963-10-11",
        "nepDate": "25.06.2020"
    },
    {
        "engDate": "1963-10-12",
        "nepDate": "26.06.2020"
    },
    {
        "engDate": "1963-10-13",
        "nepDate": "27.06.2020"
    },
    {
        "engDate": "1963-10-14",
        "nepDate": "28.06.2020"
    },
    {
        "engDate": "1963-10-15",
        "nepDate": "29.06.2020"
    },
    {
        "engDate": "1963-10-16",
        "nepDate": "30.06.2020"
    },
    {
        "engDate": "1963-10-17",
        "nepDate": "31.06.2020"
    },
    {
        "engDate": "1963-10-18",
        "nepDate": "01.07.2020"
    },
    {
        "engDate": "1963-10-19",
        "nepDate": "02.07.2020"
    },
    {
        "engDate": "1963-10-20",
        "nepDate": "03.07.2020"
    },
    {
        "engDate": "1963-10-21",
        "nepDate": "04.07.2020"
    },
    {
        "engDate": "1963-10-22",
        "nepDate": "05.07.2020"
    },
    {
        "engDate": "1963-10-23",
        "nepDate": "06.07.2020"
    },
    {
        "engDate": "1963-10-24",
        "nepDate": "07.07.2020"
    },
    {
        "engDate": "1963-10-25",
        "nepDate": "08.07.2020"
    },
    {
        "engDate": "1963-10-26",
        "nepDate": "09.07.2020"
    },
    {
        "engDate": "1963-10-27",
        "nepDate": "10.07.2020"
    },
    {
        "engDate": "1963-10-28",
        "nepDate": "11.07.2020"
    },
    {
        "engDate": "1963-10-29",
        "nepDate": "12.07.2020"
    },
    {
        "engDate": "1963-10-30",
        "nepDate": "13.07.2020"
    },
    {
        "engDate": "1963-10-31",
        "nepDate": "14.07.2020"
    },
    {
        "engDate": "1963-11-01",
        "nepDate": "15.07.2020"
    },
    {
        "engDate": "1963-11-02",
        "nepDate": "16.07.2020"
    },
    {
        "engDate": "1963-11-03",
        "nepDate": "17.07.2020"
    },
    {
        "engDate": "1963-11-04",
        "nepDate": "18.07.2020"
    },
    {
        "engDate": "1963-11-05",
        "nepDate": "19.07.2020"
    },
    {
        "engDate": "1963-11-06",
        "nepDate": "20.07.2020"
    },
    {
        "engDate": "1963-11-07",
        "nepDate": "21.07.2020"
    },
    {
        "engDate": "1963-11-08",
        "nepDate": "22.07.2020"
    },
    {
        "engDate": "1963-11-09",
        "nepDate": "23.07.2020"
    },
    {
        "engDate": "1963-11-10",
        "nepDate": "24.07.2020"
    },
    {
        "engDate": "1963-11-11",
        "nepDate": "25.07.2020"
    },
    {
        "engDate": "1963-11-12",
        "nepDate": "26.07.2020"
    },
    {
        "engDate": "1963-11-13",
        "nepDate": "27.07.2020"
    },
    {
        "engDate": "1963-11-14",
        "nepDate": "28.07.2020"
    },
    {
        "engDate": "1963-11-15",
        "nepDate": "29.07.2020"
    },
    {
        "engDate": "1963-11-16",
        "nepDate": "30.07.2020"
    },
    {
        "engDate": "1963-11-17",
        "nepDate": "01.08.2020"
    },
    {
        "engDate": "1963-11-18",
        "nepDate": "02.08.2020"
    },
    {
        "engDate": "1963-11-19",
        "nepDate": "03.08.2020"
    },
    {
        "engDate": "1963-11-20",
        "nepDate": "04.08.2020"
    },
    {
        "engDate": "1963-11-21",
        "nepDate": "05.08.2020"
    },
    {
        "engDate": "1963-11-22",
        "nepDate": "06.08.2020"
    },
    {
        "engDate": "1963-11-23",
        "nepDate": "07.08.2020"
    },
    {
        "engDate": "1963-11-24",
        "nepDate": "08.08.2020"
    },
    {
        "engDate": "1963-11-25",
        "nepDate": "09.08.2020"
    },
    {
        "engDate": "1963-11-26",
        "nepDate": "10.08.2020"
    },
    {
        "engDate": "1963-11-27",
        "nepDate": "11.08.2020"
    },
    {
        "engDate": "1963-11-28",
        "nepDate": "12.08.2020"
    },
    {
        "engDate": "1963-11-29",
        "nepDate": "13.08.2020"
    },
    {
        "engDate": "1963-11-30",
        "nepDate": "14.08.2020"
    },
    {
        "engDate": "1963-12-01",
        "nepDate": "15.08.2020"
    },
    {
        "engDate": "1963-12-02",
        "nepDate": "16.08.2020"
    },
    {
        "engDate": "1963-12-03",
        "nepDate": "17.08.2020"
    },
    {
        "engDate": "1963-12-04",
        "nepDate": "18.08.2020"
    },
    {
        "engDate": "1963-12-05",
        "nepDate": "19.08.2020"
    },
    {
        "engDate": "1963-12-06",
        "nepDate": "20.08.2020"
    },
    {
        "engDate": "1963-12-07",
        "nepDate": "21.08.2020"
    },
    {
        "engDate": "1963-12-08",
        "nepDate": "22.08.2020"
    },
    {
        "engDate": "1963-12-09",
        "nepDate": "23.08.2020"
    },
    {
        "engDate": "1963-12-10",
        "nepDate": "24.08.2020"
    },
    {
        "engDate": "1963-12-11",
        "nepDate": "25.08.2020"
    },
    {
        "engDate": "1963-12-12",
        "nepDate": "26.08.2020"
    },
    {
        "engDate": "1963-12-13",
        "nepDate": "27.08.2020"
    },
    {
        "engDate": "1963-12-14",
        "nepDate": "28.08.2020"
    },
    {
        "engDate": "1963-12-15",
        "nepDate": "29.08.2020"
    },
    {
        "engDate": "1963-12-16",
        "nepDate": "01.09.2020"
    },
    {
        "engDate": "1963-12-17",
        "nepDate": "02.09.2020"
    },
    {
        "engDate": "1963-12-18",
        "nepDate": "03.09.2020"
    },
    {
        "engDate": "1963-12-19",
        "nepDate": "04.09.2020"
    },
    {
        "engDate": "1963-12-20",
        "nepDate": "05.09.2020"
    },
    {
        "engDate": "1963-12-21",
        "nepDate": "06.09.2020"
    },
    {
        "engDate": "1963-12-22",
        "nepDate": "07.09.2020"
    },
    {
        "engDate": "1963-12-23",
        "nepDate": "08.09.2020"
    },
    {
        "engDate": "1963-12-24",
        "nepDate": "09.09.2020"
    },
    {
        "engDate": "1963-12-25",
        "nepDate": "10.09.2020"
    },
    {
        "engDate": "1963-12-26",
        "nepDate": "11.09.2020"
    },
    {
        "engDate": "1963-12-27",
        "nepDate": "12.09.2020"
    },
    {
        "engDate": "1963-12-28",
        "nepDate": "13.09.2020"
    },
    {
        "engDate": "1963-12-29",
        "nepDate": "14.09.2020"
    },
    {
        "engDate": "1963-12-30",
        "nepDate": "15.09.2020"
    },
    {
        "engDate": "1963-12-31",
        "nepDate": "16.09.2020"
    },
    {
        "engDate": "1964-01-01",
        "nepDate": "17.09.2020"
    },
    {
        "engDate": "1964-01-02",
        "nepDate": "18.09.2020"
    },
    {
        "engDate": "1964-01-03",
        "nepDate": "19.09.2020"
    },
    {
        "engDate": "1964-01-04",
        "nepDate": "20.09.2020"
    },
    {
        "engDate": "1964-01-05",
        "nepDate": "21.09.2020"
    },
    {
        "engDate": "1964-01-06",
        "nepDate": "22.09.2020"
    },
    {
        "engDate": "1964-01-07",
        "nepDate": "23.09.2020"
    },
    {
        "engDate": "1964-01-08",
        "nepDate": "24.09.2020"
    },
    {
        "engDate": "1964-01-09",
        "nepDate": "25.09.2020"
    },
    {
        "engDate": "1964-01-10",
        "nepDate": "26.09.2020"
    },
    {
        "engDate": "1964-01-11",
        "nepDate": "27.09.2020"
    },
    {
        "engDate": "1964-01-12",
        "nepDate": "28.09.2020"
    },
    {
        "engDate": "1964-01-13",
        "nepDate": "29.09.2020"
    },
    {
        "engDate": "1964-01-14",
        "nepDate": "30.09.2020"
    },
    {
        "engDate": "1964-01-15",
        "nepDate": "01.10.2020"
    },
    {
        "engDate": "1964-01-16",
        "nepDate": "02.10.2020"
    },
    {
        "engDate": "1964-01-17",
        "nepDate": "03.10.2020"
    },
    {
        "engDate": "1964-01-18",
        "nepDate": "04.10.2020"
    },
    {
        "engDate": "1964-01-19",
        "nepDate": "05.10.2020"
    },
    {
        "engDate": "1964-01-20",
        "nepDate": "06.10.2020"
    },
    {
        "engDate": "1964-01-21",
        "nepDate": "07.10.2020"
    },
    {
        "engDate": "1964-01-22",
        "nepDate": "08.10.2020"
    },
    {
        "engDate": "1964-01-23",
        "nepDate": "09.10.2020"
    },
    {
        "engDate": "1964-01-24",
        "nepDate": "10.10.2020"
    },
    {
        "engDate": "1964-01-25",
        "nepDate": "11.10.2020"
    },
    {
        "engDate": "1964-01-26",
        "nepDate": "12.10.2020"
    },
    {
        "engDate": "1964-01-27",
        "nepDate": "13.10.2020"
    },
    {
        "engDate": "1964-01-28",
        "nepDate": "14.10.2020"
    },
    {
        "engDate": "1964-01-29",
        "nepDate": "15.10.2020"
    },
    {
        "engDate": "1964-01-30",
        "nepDate": "16.10.2020"
    },
    {
        "engDate": "1964-01-31",
        "nepDate": "17.10.2020"
    },
    {
        "engDate": "1964-02-01",
        "nepDate": "18.10.2020"
    },
    {
        "engDate": "1964-02-02",
        "nepDate": "19.10.2020"
    },
    {
        "engDate": "1964-02-03",
        "nepDate": "20.10.2020"
    },
    {
        "engDate": "1964-02-04",
        "nepDate": "21.10.2020"
    },
    {
        "engDate": "1964-02-05",
        "nepDate": "22.10.2020"
    },
    {
        "engDate": "1964-02-06",
        "nepDate": "23.10.2020"
    },
    {
        "engDate": "1964-02-07",
        "nepDate": "24.10.2020"
    },
    {
        "engDate": "1964-02-08",
        "nepDate": "25.10.2020"
    },
    {
        "engDate": "1964-02-09",
        "nepDate": "26.10.2020"
    },
    {
        "engDate": "1964-02-10",
        "nepDate": "27.10.2020"
    },
    {
        "engDate": "1964-02-11",
        "nepDate": "28.10.2020"
    },
    {
        "engDate": "1964-02-12",
        "nepDate": "29.10.2020"
    },
    {
        "engDate": "1964-02-13",
        "nepDate": "01.11.2020"
    },
    {
        "engDate": "1964-02-14",
        "nepDate": "02.11.2020"
    },
    {
        "engDate": "1964-02-15",
        "nepDate": "03.11.2020"
    },
    {
        "engDate": "1964-02-16",
        "nepDate": "04.11.2020"
    },
    {
        "engDate": "1964-02-17",
        "nepDate": "05.11.2020"
    },
    {
        "engDate": "1964-02-18",
        "nepDate": "06.11.2020"
    },
    {
        "engDate": "1964-02-19",
        "nepDate": "07.11.2020"
    },
    {
        "engDate": "1964-02-20",
        "nepDate": "08.11.2020"
    },
    {
        "engDate": "1964-02-21",
        "nepDate": "09.11.2020"
    },
    {
        "engDate": "1964-02-22",
        "nepDate": "10.11.2020"
    },
    {
        "engDate": "1964-02-23",
        "nepDate": "11.11.2020"
    },
    {
        "engDate": "1964-02-24",
        "nepDate": "12.11.2020"
    },
    {
        "engDate": "1964-02-25",
        "nepDate": "13.11.2020"
    },
    {
        "engDate": "1964-02-26",
        "nepDate": "14.11.2020"
    },
    {
        "engDate": "1964-02-27",
        "nepDate": "15.11.2020"
    },
    {
        "engDate": "1964-02-28",
        "nepDate": "16.11.2020"
    },
    {
        "engDate": "1964-02-29",
        "nepDate": "17.11.2020"
    },
    {
        "engDate": "1964-03-01",
        "nepDate": "18.11.2020"
    },
    {
        "engDate": "1964-03-02",
        "nepDate": "19.11.2020"
    },
    {
        "engDate": "1964-03-03",
        "nepDate": "20.11.2020"
    },
    {
        "engDate": "1964-03-04",
        "nepDate": "21.11.2020"
    },
    {
        "engDate": "1964-03-05",
        "nepDate": "22.11.2020"
    },
    {
        "engDate": "1964-03-06",
        "nepDate": "23.11.2020"
    },
    {
        "engDate": "1964-03-07",
        "nepDate": "24.11.2020"
    },
    {
        "engDate": "1964-03-08",
        "nepDate": "25.11.2020"
    },
    {
        "engDate": "1964-03-09",
        "nepDate": "26.11.2020"
    },
    {
        "engDate": "1964-03-10",
        "nepDate": "27.11.2020"
    },
    {
        "engDate": "1964-03-11",
        "nepDate": "28.11.2020"
    },
    {
        "engDate": "1964-03-12",
        "nepDate": "29.11.2020"
    },
    {
        "engDate": "1964-03-13",
        "nepDate": "30.11.2020"
    },
    {
        "engDate": "1964-03-14",
        "nepDate": "01.12.2020"
    },
    {
        "engDate": "1964-03-15",
        "nepDate": "02.12.2020"
    },
    {
        "engDate": "1964-03-16",
        "nepDate": "03.12.2020"
    },
    {
        "engDate": "1964-03-17",
        "nepDate": "04.12.2020"
    },
    {
        "engDate": "1964-03-18",
        "nepDate": "05.12.2020"
    },
    {
        "engDate": "1964-03-19",
        "nepDate": "06.12.2020"
    },
    {
        "engDate": "1964-03-20",
        "nepDate": "07.12.2020"
    },
    {
        "engDate": "1964-03-21",
        "nepDate": "08.12.2020"
    },
    {
        "engDate": "1964-03-22",
        "nepDate": "09.12.2020"
    },
    {
        "engDate": "1964-03-23",
        "nepDate": "10.12.2020"
    },
    {
        "engDate": "1964-03-24",
        "nepDate": "11.12.2020"
    },
    {
        "engDate": "1964-03-25",
        "nepDate": "12.12.2020"
    },
    {
        "engDate": "1964-03-26",
        "nepDate": "13.12.2020"
    },
    {
        "engDate": "1964-03-27",
        "nepDate": "14.12.2020"
    },
    {
        "engDate": "1964-03-28",
        "nepDate": "15.12.2020"
    },
    {
        "engDate": "1964-03-29",
        "nepDate": "16.12.2020"
    },
    {
        "engDate": "1964-03-30",
        "nepDate": "17.12.2020"
    },
    {
        "engDate": "1964-03-31",
        "nepDate": "18.12.2020"
    },
    {
        "engDate": "1964-04-01",
        "nepDate": "19.12.2020"
    },
    {
        "engDate": "1964-04-02",
        "nepDate": "20.12.2020"
    },
    {
        "engDate": "1964-04-03",
        "nepDate": "21.12.2020"
    },
    {
        "engDate": "1964-04-04",
        "nepDate": "22.12.2020"
    },
    {
        "engDate": "1964-04-05",
        "nepDate": "23.12.2020"
    },
    {
        "engDate": "1964-04-06",
        "nepDate": "24.12.2020"
    },
    {
        "engDate": "1964-04-07",
        "nepDate": "25.12.2020"
    },
    {
        "engDate": "1964-04-08",
        "nepDate": "26.12.2020"
    },
    {
        "engDate": "1964-04-09",
        "nepDate": "27.12.2020"
    },
    {
        "engDate": "1964-04-10",
        "nepDate": "28.12.2020"
    },
    {
        "engDate": "1964-04-11",
        "nepDate": "29.12.2020"
    },
    {
        "engDate": "1964-04-12",
        "nepDate": "30.12.2020"
    },
    {
        "engDate": "1964-04-13",
        "nepDate": "01.01.2021"
    },
    {
        "engDate": "1964-04-14",
        "nepDate": "02.01.2021"
    },
    {
        "engDate": "1964-04-15",
        "nepDate": "03.01.2021"
    },
    {
        "engDate": "1964-04-16",
        "nepDate": "04.01.2021"
    },
    {
        "engDate": "1964-04-17",
        "nepDate": "05.01.2021"
    },
    {
        "engDate": "1964-04-18",
        "nepDate": "06.01.2021"
    },
    {
        "engDate": "1964-04-19",
        "nepDate": "07.01.2021"
    },
    {
        "engDate": "1964-04-20",
        "nepDate": "08.01.2021"
    },
    {
        "engDate": "1964-04-21",
        "nepDate": "09.01.2021"
    },
    {
        "engDate": "1964-04-22",
        "nepDate": "10.01.2021"
    },
    {
        "engDate": "1964-04-23",
        "nepDate": "11.01.2021"
    },
    {
        "engDate": "1964-04-24",
        "nepDate": "12.01.2021"
    },
    {
        "engDate": "1964-04-25",
        "nepDate": "13.01.2021"
    },
    {
        "engDate": "1964-04-26",
        "nepDate": "14.01.2021"
    },
    {
        "engDate": "1964-04-27",
        "nepDate": "15.01.2021"
    },
    {
        "engDate": "1964-04-28",
        "nepDate": "16.01.2021"
    },
    {
        "engDate": "1964-04-29",
        "nepDate": "17.01.2021"
    },
    {
        "engDate": "1964-04-30",
        "nepDate": "18.01.2021"
    },
    {
        "engDate": "1964-05-01",
        "nepDate": "19.01.2021"
    },
    {
        "engDate": "1964-05-02",
        "nepDate": "20.01.2021"
    },
    {
        "engDate": "1964-05-03",
        "nepDate": "21.01.2021"
    },
    {
        "engDate": "1964-05-04",
        "nepDate": "22.01.2021"
    },
    {
        "engDate": "1964-05-05",
        "nepDate": "23.01.2021"
    },
    {
        "engDate": "1964-05-06",
        "nepDate": "24.01.2021"
    },
    {
        "engDate": "1964-05-07",
        "nepDate": "25.01.2021"
    },
    {
        "engDate": "1964-05-08",
        "nepDate": "26.01.2021"
    },
    {
        "engDate": "1964-05-09",
        "nepDate": "27.01.2021"
    },
    {
        "engDate": "1964-05-10",
        "nepDate": "28.01.2021"
    },
    {
        "engDate": "1964-05-11",
        "nepDate": "29.01.2021"
    },
    {
        "engDate": "1964-05-12",
        "nepDate": "30.01.2021"
    },
    {
        "engDate": "1964-05-13",
        "nepDate": "31.01.2021"
    },
    {
        "engDate": "1964-05-14",
        "nepDate": "01.02.2021"
    },
    {
        "engDate": "1964-05-15",
        "nepDate": "02.02.2021"
    },
    {
        "engDate": "1964-05-16",
        "nepDate": "03.02.2021"
    },
    {
        "engDate": "1964-05-17",
        "nepDate": "04.02.2021"
    },
    {
        "engDate": "1964-05-18",
        "nepDate": "05.02.2021"
    },
    {
        "engDate": "1964-05-19",
        "nepDate": "06.02.2021"
    },
    {
        "engDate": "1964-05-20",
        "nepDate": "07.02.2021"
    },
    {
        "engDate": "1964-05-21",
        "nepDate": "08.02.2021"
    },
    {
        "engDate": "1964-05-22",
        "nepDate": "09.02.2021"
    },
    {
        "engDate": "1964-05-23",
        "nepDate": "10.02.2021"
    },
    {
        "engDate": "1964-05-24",
        "nepDate": "11.02.2021"
    },
    {
        "engDate": "1964-05-25",
        "nepDate": "12.02.2021"
    },
    {
        "engDate": "1964-05-26",
        "nepDate": "13.02.2021"
    },
    {
        "engDate": "1964-05-27",
        "nepDate": "14.02.2021"
    },
    {
        "engDate": "1964-05-28",
        "nepDate": "15.02.2021"
    },
    {
        "engDate": "1964-05-29",
        "nepDate": "16.02.2021"
    },
    {
        "engDate": "1964-05-30",
        "nepDate": "17.02.2021"
    },
    {
        "engDate": "1964-05-31",
        "nepDate": "18.02.2021"
    },
    {
        "engDate": "1964-06-01",
        "nepDate": "19.02.2021"
    },
    {
        "engDate": "1964-06-02",
        "nepDate": "20.02.2021"
    },
    {
        "engDate": "1964-06-03",
        "nepDate": "21.02.2021"
    },
    {
        "engDate": "1964-06-04",
        "nepDate": "22.02.2021"
    },
    {
        "engDate": "1964-06-05",
        "nepDate": "23.02.2021"
    },
    {
        "engDate": "1964-06-06",
        "nepDate": "24.02.2021"
    },
    {
        "engDate": "1964-06-07",
        "nepDate": "25.02.2021"
    },
    {
        "engDate": "1964-06-08",
        "nepDate": "26.02.2021"
    },
    {
        "engDate": "1964-06-09",
        "nepDate": "27.02.2021"
    },
    {
        "engDate": "1964-06-10",
        "nepDate": "28.02.2021"
    },
    {
        "engDate": "1964-06-11",
        "nepDate": "29.02.2021"
    },
    {
        "engDate": "1964-06-12",
        "nepDate": "30.02.2021"
    },
    {
        "engDate": "1964-06-13",
        "nepDate": "31.02.2021"
    },
    {
        "engDate": "1964-06-14",
        "nepDate": "01.03.2021"
    },
    {
        "engDate": "1964-06-15",
        "nepDate": "02.03.2021"
    },
    {
        "engDate": "1964-06-16",
        "nepDate": "03.03.2021"
    },
    {
        "engDate": "1964-06-17",
        "nepDate": "04.03.2021"
    },
    {
        "engDate": "1964-06-18",
        "nepDate": "05.03.2021"
    },
    {
        "engDate": "1964-06-19",
        "nepDate": "06.03.2021"
    },
    {
        "engDate": "1964-06-20",
        "nepDate": "07.03.2021"
    },
    {
        "engDate": "1964-06-21",
        "nepDate": "08.03.2021"
    },
    {
        "engDate": "1964-06-22",
        "nepDate": "09.03.2021"
    },
    {
        "engDate": "1964-06-23",
        "nepDate": "10.03.2021"
    },
    {
        "engDate": "1964-06-24",
        "nepDate": "11.03.2021"
    },
    {
        "engDate": "1964-06-25",
        "nepDate": "12.03.2021"
    },
    {
        "engDate": "1964-06-26",
        "nepDate": "13.03.2021"
    },
    {
        "engDate": "1964-06-27",
        "nepDate": "14.03.2021"
    },
    {
        "engDate": "1964-06-28",
        "nepDate": "15.03.2021"
    },
    {
        "engDate": "1964-06-29",
        "nepDate": "16.03.2021"
    },
    {
        "engDate": "1964-06-30",
        "nepDate": "17.03.2021"
    },
    {
        "engDate": "1964-07-01",
        "nepDate": "18.03.2021"
    },
    {
        "engDate": "1964-07-02",
        "nepDate": "19.03.2021"
    },
    {
        "engDate": "1964-07-03",
        "nepDate": "20.03.2021"
    },
    {
        "engDate": "1964-07-04",
        "nepDate": "21.03.2021"
    },
    {
        "engDate": "1964-07-05",
        "nepDate": "22.03.2021"
    },
    {
        "engDate": "1964-07-06",
        "nepDate": "23.03.2021"
    },
    {
        "engDate": "1964-07-07",
        "nepDate": "24.03.2021"
    },
    {
        "engDate": "1964-07-08",
        "nepDate": "25.03.2021"
    },
    {
        "engDate": "1964-07-09",
        "nepDate": "26.03.2021"
    },
    {
        "engDate": "1964-07-10",
        "nepDate": "27.03.2021"
    },
    {
        "engDate": "1964-07-11",
        "nepDate": "28.03.2021"
    },
    {
        "engDate": "1964-07-12",
        "nepDate": "29.03.2021"
    },
    {
        "engDate": "1964-07-13",
        "nepDate": "30.03.2021"
    },
    {
        "engDate": "1964-07-14",
        "nepDate": "31.03.2021"
    },
    {
        "engDate": "1964-07-15",
        "nepDate": "32.03.2021"
    },
    {
        "engDate": "1964-07-16",
        "nepDate": "01.04.2021"
    },
    {
        "engDate": "1964-07-17",
        "nepDate": "02.04.2021"
    },
    {
        "engDate": "1964-07-18",
        "nepDate": "03.04.2021"
    },
    {
        "engDate": "1964-07-19",
        "nepDate": "04.04.2021"
    },
    {
        "engDate": "1964-07-20",
        "nepDate": "05.04.2021"
    },
    {
        "engDate": "1964-07-21",
        "nepDate": "06.04.2021"
    },
    {
        "engDate": "1964-07-22",
        "nepDate": "07.04.2021"
    },
    {
        "engDate": "1964-07-23",
        "nepDate": "08.04.2021"
    },
    {
        "engDate": "1964-07-24",
        "nepDate": "09.04.2021"
    },
    {
        "engDate": "1964-07-25",
        "nepDate": "10.04.2021"
    },
    {
        "engDate": "1964-07-26",
        "nepDate": "11.04.2021"
    },
    {
        "engDate": "1964-07-27",
        "nepDate": "12.04.2021"
    },
    {
        "engDate": "1964-07-28",
        "nepDate": "13.04.2021"
    },
    {
        "engDate": "1964-07-29",
        "nepDate": "14.04.2021"
    },
    {
        "engDate": "1964-07-30",
        "nepDate": "15.04.2021"
    },
    {
        "engDate": "1964-07-31",
        "nepDate": "16.04.2021"
    },
    {
        "engDate": "1964-08-01",
        "nepDate": "17.04.2021"
    },
    {
        "engDate": "1964-08-02",
        "nepDate": "18.04.2021"
    },
    {
        "engDate": "1964-08-03",
        "nepDate": "19.04.2021"
    },
    {
        "engDate": "1964-08-04",
        "nepDate": "20.04.2021"
    },
    {
        "engDate": "1964-08-05",
        "nepDate": "21.04.2021"
    },
    {
        "engDate": "1964-08-06",
        "nepDate": "22.04.2021"
    },
    {
        "engDate": "1964-08-07",
        "nepDate": "23.04.2021"
    },
    {
        "engDate": "1964-08-08",
        "nepDate": "24.04.2021"
    },
    {
        "engDate": "1964-08-09",
        "nepDate": "25.04.2021"
    },
    {
        "engDate": "1964-08-10",
        "nepDate": "26.04.2021"
    },
    {
        "engDate": "1964-08-11",
        "nepDate": "27.04.2021"
    },
    {
        "engDate": "1964-08-12",
        "nepDate": "28.04.2021"
    },
    {
        "engDate": "1964-08-13",
        "nepDate": "29.04.2021"
    },
    {
        "engDate": "1964-08-14",
        "nepDate": "30.04.2021"
    },
    {
        "engDate": "1964-08-15",
        "nepDate": "31.04.2021"
    },
    {
        "engDate": "1964-08-16",
        "nepDate": "01.05.2021"
    },
    {
        "engDate": "1964-08-17",
        "nepDate": "02.05.2021"
    },
    {
        "engDate": "1964-08-18",
        "nepDate": "03.05.2021"
    },
    {
        "engDate": "1964-08-19",
        "nepDate": "04.05.2021"
    },
    {
        "engDate": "1964-08-20",
        "nepDate": "05.05.2021"
    },
    {
        "engDate": "1964-08-21",
        "nepDate": "06.05.2021"
    },
    {
        "engDate": "1964-08-22",
        "nepDate": "07.05.2021"
    },
    {
        "engDate": "1964-08-23",
        "nepDate": "08.05.2021"
    },
    {
        "engDate": "1964-08-24",
        "nepDate": "09.05.2021"
    },
    {
        "engDate": "1964-08-25",
        "nepDate": "10.05.2021"
    },
    {
        "engDate": "1964-08-26",
        "nepDate": "11.05.2021"
    },
    {
        "engDate": "1964-08-27",
        "nepDate": "12.05.2021"
    },
    {
        "engDate": "1964-08-28",
        "nepDate": "13.05.2021"
    },
    {
        "engDate": "1964-08-29",
        "nepDate": "14.05.2021"
    },
    {
        "engDate": "1964-08-30",
        "nepDate": "15.05.2021"
    },
    {
        "engDate": "1964-08-31",
        "nepDate": "16.05.2021"
    },
    {
        "engDate": "1964-09-01",
        "nepDate": "17.05.2021"
    },
    {
        "engDate": "1964-09-02",
        "nepDate": "18.05.2021"
    },
    {
        "engDate": "1964-09-03",
        "nepDate": "19.05.2021"
    },
    {
        "engDate": "1964-09-04",
        "nepDate": "20.05.2021"
    },
    {
        "engDate": "1964-09-05",
        "nepDate": "21.05.2021"
    },
    {
        "engDate": "1964-09-06",
        "nepDate": "22.05.2021"
    },
    {
        "engDate": "1964-09-07",
        "nepDate": "23.05.2021"
    },
    {
        "engDate": "1964-09-08",
        "nepDate": "24.05.2021"
    },
    {
        "engDate": "1964-09-09",
        "nepDate": "25.05.2021"
    },
    {
        "engDate": "1964-09-10",
        "nepDate": "26.05.2021"
    },
    {
        "engDate": "1964-09-11",
        "nepDate": "27.05.2021"
    },
    {
        "engDate": "1964-09-12",
        "nepDate": "28.05.2021"
    },
    {
        "engDate": "1964-09-13",
        "nepDate": "29.05.2021"
    },
    {
        "engDate": "1964-09-14",
        "nepDate": "30.05.2021"
    },
    {
        "engDate": "1964-09-15",
        "nepDate": "31.05.2021"
    },
    {
        "engDate": "1964-09-16",
        "nepDate": "01.06.2021"
    },
    {
        "engDate": "1964-09-17",
        "nepDate": "02.06.2021"
    },
    {
        "engDate": "1964-09-18",
        "nepDate": "03.06.2021"
    },
    {
        "engDate": "1964-09-19",
        "nepDate": "04.06.2021"
    },
    {
        "engDate": "1964-09-20",
        "nepDate": "05.06.2021"
    },
    {
        "engDate": "1964-09-21",
        "nepDate": "06.06.2021"
    },
    {
        "engDate": "1964-09-22",
        "nepDate": "07.06.2021"
    },
    {
        "engDate": "1964-09-23",
        "nepDate": "08.06.2021"
    },
    {
        "engDate": "1964-09-24",
        "nepDate": "09.06.2021"
    },
    {
        "engDate": "1964-09-25",
        "nepDate": "10.06.2021"
    },
    {
        "engDate": "1964-09-26",
        "nepDate": "11.06.2021"
    },
    {
        "engDate": "1964-09-27",
        "nepDate": "12.06.2021"
    },
    {
        "engDate": "1964-09-28",
        "nepDate": "13.06.2021"
    },
    {
        "engDate": "1964-09-29",
        "nepDate": "14.06.2021"
    },
    {
        "engDate": "1964-09-30",
        "nepDate": "15.06.2021"
    },
    {
        "engDate": "1964-10-01",
        "nepDate": "16.06.2021"
    },
    {
        "engDate": "1964-10-02",
        "nepDate": "17.06.2021"
    },
    {
        "engDate": "1964-10-03",
        "nepDate": "18.06.2021"
    },
    {
        "engDate": "1964-10-04",
        "nepDate": "19.06.2021"
    },
    {
        "engDate": "1964-10-05",
        "nepDate": "20.06.2021"
    },
    {
        "engDate": "1964-10-06",
        "nepDate": "21.06.2021"
    },
    {
        "engDate": "1964-10-07",
        "nepDate": "22.06.2021"
    },
    {
        "engDate": "1964-10-08",
        "nepDate": "23.06.2021"
    },
    {
        "engDate": "1964-10-09",
        "nepDate": "24.06.2021"
    },
    {
        "engDate": "1964-10-10",
        "nepDate": "25.06.2021"
    },
    {
        "engDate": "1964-10-11",
        "nepDate": "26.06.2021"
    },
    {
        "engDate": "1964-10-12",
        "nepDate": "27.06.2021"
    },
    {
        "engDate": "1964-10-13",
        "nepDate": "28.06.2021"
    },
    {
        "engDate": "1964-10-14",
        "nepDate": "29.06.2021"
    },
    {
        "engDate": "1964-10-15",
        "nepDate": "30.06.2021"
    },
    {
        "engDate": "1964-10-16",
        "nepDate": "31.06.2021"
    },
    {
        "engDate": "1964-10-17",
        "nepDate": "01.07.2021"
    },
    {
        "engDate": "1964-10-18",
        "nepDate": "02.07.2021"
    },
    {
        "engDate": "1964-10-19",
        "nepDate": "03.07.2021"
    },
    {
        "engDate": "1964-10-20",
        "nepDate": "04.07.2021"
    },
    {
        "engDate": "1964-10-21",
        "nepDate": "05.07.2021"
    },
    {
        "engDate": "1964-10-22",
        "nepDate": "06.07.2021"
    },
    {
        "engDate": "1964-10-23",
        "nepDate": "07.07.2021"
    },
    {
        "engDate": "1964-10-24",
        "nepDate": "08.07.2021"
    },
    {
        "engDate": "1964-10-25",
        "nepDate": "09.07.2021"
    },
    {
        "engDate": "1964-10-26",
        "nepDate": "10.07.2021"
    },
    {
        "engDate": "1964-10-27",
        "nepDate": "11.07.2021"
    },
    {
        "engDate": "1964-10-28",
        "nepDate": "12.07.2021"
    },
    {
        "engDate": "1964-10-29",
        "nepDate": "13.07.2021"
    },
    {
        "engDate": "1964-10-30",
        "nepDate": "14.07.2021"
    },
    {
        "engDate": "1964-10-31",
        "nepDate": "15.07.2021"
    },
    {
        "engDate": "1964-11-01",
        "nepDate": "16.07.2021"
    },
    {
        "engDate": "1964-11-02",
        "nepDate": "17.07.2021"
    },
    {
        "engDate": "1964-11-03",
        "nepDate": "18.07.2021"
    },
    {
        "engDate": "1964-11-04",
        "nepDate": "19.07.2021"
    },
    {
        "engDate": "1964-11-05",
        "nepDate": "20.07.2021"
    },
    {
        "engDate": "1964-11-06",
        "nepDate": "21.07.2021"
    },
    {
        "engDate": "1964-11-07",
        "nepDate": "22.07.2021"
    },
    {
        "engDate": "1964-11-08",
        "nepDate": "23.07.2021"
    },
    {
        "engDate": "1964-11-09",
        "nepDate": "24.07.2021"
    },
    {
        "engDate": "1964-11-10",
        "nepDate": "25.07.2021"
    },
    {
        "engDate": "1964-11-11",
        "nepDate": "26.07.2021"
    },
    {
        "engDate": "1964-11-12",
        "nepDate": "27.07.2021"
    },
    {
        "engDate": "1964-11-13",
        "nepDate": "28.07.2021"
    },
    {
        "engDate": "1964-11-14",
        "nepDate": "29.07.2021"
    },
    {
        "engDate": "1964-11-15",
        "nepDate": "30.07.2021"
    },
    {
        "engDate": "1964-11-16",
        "nepDate": "01.08.2021"
    },
    {
        "engDate": "1964-11-17",
        "nepDate": "02.08.2021"
    },
    {
        "engDate": "1964-11-18",
        "nepDate": "03.08.2021"
    },
    {
        "engDate": "1964-11-19",
        "nepDate": "04.08.2021"
    },
    {
        "engDate": "1964-11-20",
        "nepDate": "05.08.2021"
    },
    {
        "engDate": "1964-11-21",
        "nepDate": "06.08.2021"
    },
    {
        "engDate": "1964-11-22",
        "nepDate": "07.08.2021"
    },
    {
        "engDate": "1964-11-23",
        "nepDate": "08.08.2021"
    },
    {
        "engDate": "1964-11-24",
        "nepDate": "09.08.2021"
    },
    {
        "engDate": "1964-11-25",
        "nepDate": "10.08.2021"
    },
    {
        "engDate": "1964-11-26",
        "nepDate": "11.08.2021"
    },
    {
        "engDate": "1964-11-27",
        "nepDate": "12.08.2021"
    },
    {
        "engDate": "1964-11-28",
        "nepDate": "13.08.2021"
    },
    {
        "engDate": "1964-11-29",
        "nepDate": "14.08.2021"
    },
    {
        "engDate": "1964-11-30",
        "nepDate": "15.08.2021"
    },
    {
        "engDate": "1964-12-01",
        "nepDate": "16.08.2021"
    },
    {
        "engDate": "1964-12-02",
        "nepDate": "17.08.2021"
    },
    {
        "engDate": "1964-12-03",
        "nepDate": "18.08.2021"
    },
    {
        "engDate": "1964-12-04",
        "nepDate": "19.08.2021"
    },
    {
        "engDate": "1964-12-05",
        "nepDate": "20.08.2021"
    },
    {
        "engDate": "1964-12-06",
        "nepDate": "21.08.2021"
    },
    {
        "engDate": "1964-12-07",
        "nepDate": "22.08.2021"
    },
    {
        "engDate": "1964-12-08",
        "nepDate": "23.08.2021"
    },
    {
        "engDate": "1964-12-09",
        "nepDate": "24.08.2021"
    },
    {
        "engDate": "1964-12-10",
        "nepDate": "25.08.2021"
    },
    {
        "engDate": "1964-12-11",
        "nepDate": "26.08.2021"
    },
    {
        "engDate": "1964-12-12",
        "nepDate": "27.08.2021"
    },
    {
        "engDate": "1964-12-13",
        "nepDate": "28.08.2021"
    },
    {
        "engDate": "1964-12-14",
        "nepDate": "29.08.2021"
    },
    {
        "engDate": "1964-12-15",
        "nepDate": "01.09.2021"
    },
    {
        "engDate": "1964-12-16",
        "nepDate": "02.09.2021"
    },
    {
        "engDate": "1964-12-17",
        "nepDate": "03.09.2021"
    },
    {
        "engDate": "1964-12-18",
        "nepDate": "04.09.2021"
    },
    {
        "engDate": "1964-12-19",
        "nepDate": "05.09.2021"
    },
    {
        "engDate": "1964-12-20",
        "nepDate": "06.09.2021"
    },
    {
        "engDate": "1964-12-21",
        "nepDate": "07.09.2021"
    },
    {
        "engDate": "1964-12-22",
        "nepDate": "08.09.2021"
    },
    {
        "engDate": "1964-12-23",
        "nepDate": "09.09.2021"
    },
    {
        "engDate": "1964-12-24",
        "nepDate": "10.09.2021"
    },
    {
        "engDate": "1964-12-25",
        "nepDate": "11.09.2021"
    },
    {
        "engDate": "1964-12-26",
        "nepDate": "12.09.2021"
    },
    {
        "engDate": "1964-12-27",
        "nepDate": "13.09.2021"
    },
    {
        "engDate": "1964-12-28",
        "nepDate": "14.09.2021"
    },
    {
        "engDate": "1964-12-29",
        "nepDate": "15.09.2021"
    },
    {
        "engDate": "1964-12-30",
        "nepDate": "16.09.2021"
    },
    {
        "engDate": "1964-12-31",
        "nepDate": "17.09.2021"
    },
    {
        "engDate": "1965-01-01",
        "nepDate": "18.09.2021"
    },
    {
        "engDate": "1965-01-02",
        "nepDate": "19.09.2021"
    },
    {
        "engDate": "1965-01-03",
        "nepDate": "20.09.2021"
    },
    {
        "engDate": "1965-01-04",
        "nepDate": "21.09.2021"
    },
    {
        "engDate": "1965-01-05",
        "nepDate": "22.09.2021"
    },
    {
        "engDate": "1965-01-06",
        "nepDate": "23.09.2021"
    },
    {
        "engDate": "1965-01-07",
        "nepDate": "24.09.2021"
    },
    {
        "engDate": "1965-01-08",
        "nepDate": "25.09.2021"
    },
    {
        "engDate": "1965-01-09",
        "nepDate": "26.09.2021"
    },
    {
        "engDate": "1965-01-10",
        "nepDate": "27.09.2021"
    },
    {
        "engDate": "1965-01-11",
        "nepDate": "28.09.2021"
    },
    {
        "engDate": "1965-01-12",
        "nepDate": "29.09.2021"
    },
    {
        "engDate": "1965-01-13",
        "nepDate": "30.09.2021"
    },
    {
        "engDate": "1965-01-14",
        "nepDate": "01.10.2021"
    },
    {
        "engDate": "1965-01-15",
        "nepDate": "02.10.2021"
    },
    {
        "engDate": "1965-01-16",
        "nepDate": "03.10.2021"
    },
    {
        "engDate": "1965-01-17",
        "nepDate": "04.10.2021"
    },
    {
        "engDate": "1965-01-18",
        "nepDate": "05.10.2021"
    },
    {
        "engDate": "1965-01-19",
        "nepDate": "06.10.2021"
    },
    {
        "engDate": "1965-01-20",
        "nepDate": "07.10.2021"
    },
    {
        "engDate": "1965-01-21",
        "nepDate": "08.10.2021"
    },
    {
        "engDate": "1965-01-22",
        "nepDate": "09.10.2021"
    },
    {
        "engDate": "1965-01-23",
        "nepDate": "10.10.2021"
    },
    {
        "engDate": "1965-01-24",
        "nepDate": "11.10.2021"
    },
    {
        "engDate": "1965-01-25",
        "nepDate": "12.10.2021"
    },
    {
        "engDate": "1965-01-26",
        "nepDate": "13.10.2021"
    },
    {
        "engDate": "1965-01-27",
        "nepDate": "14.10.2021"
    },
    {
        "engDate": "1965-01-28",
        "nepDate": "15.10.2021"
    },
    {
        "engDate": "1965-01-29",
        "nepDate": "16.10.2021"
    },
    {
        "engDate": "1965-01-30",
        "nepDate": "17.10.2021"
    },
    {
        "engDate": "1965-01-31",
        "nepDate": "18.10.2021"
    },
    {
        "engDate": "1965-02-01",
        "nepDate": "19.10.2021"
    },
    {
        "engDate": "1965-02-02",
        "nepDate": "20.10.2021"
    },
    {
        "engDate": "1965-02-03",
        "nepDate": "21.10.2021"
    },
    {
        "engDate": "1965-02-04",
        "nepDate": "22.10.2021"
    },
    {
        "engDate": "1965-02-05",
        "nepDate": "23.10.2021"
    },
    {
        "engDate": "1965-02-06",
        "nepDate": "24.10.2021"
    },
    {
        "engDate": "1965-02-07",
        "nepDate": "25.10.2021"
    },
    {
        "engDate": "1965-02-08",
        "nepDate": "26.10.2021"
    },
    {
        "engDate": "1965-02-09",
        "nepDate": "27.10.2021"
    },
    {
        "engDate": "1965-02-10",
        "nepDate": "28.10.2021"
    },
    {
        "engDate": "1965-02-11",
        "nepDate": "29.10.2021"
    },
    {
        "engDate": "1965-02-12",
        "nepDate": "01.11.2021"
    },
    {
        "engDate": "1965-02-13",
        "nepDate": "02.11.2021"
    },
    {
        "engDate": "1965-02-14",
        "nepDate": "03.11.2021"
    },
    {
        "engDate": "1965-02-15",
        "nepDate": "04.11.2021"
    },
    {
        "engDate": "1965-02-16",
        "nepDate": "05.11.2021"
    },
    {
        "engDate": "1965-02-17",
        "nepDate": "06.11.2021"
    },
    {
        "engDate": "1965-02-18",
        "nepDate": "07.11.2021"
    },
    {
        "engDate": "1965-02-19",
        "nepDate": "08.11.2021"
    },
    {
        "engDate": "1965-02-20",
        "nepDate": "09.11.2021"
    },
    {
        "engDate": "1965-02-21",
        "nepDate": "10.11.2021"
    },
    {
        "engDate": "1965-02-22",
        "nepDate": "11.11.2021"
    },
    {
        "engDate": "1965-02-23",
        "nepDate": "12.11.2021"
    },
    {
        "engDate": "1965-02-24",
        "nepDate": "13.11.2021"
    },
    {
        "engDate": "1965-02-25",
        "nepDate": "14.11.2021"
    },
    {
        "engDate": "1965-02-26",
        "nepDate": "15.11.2021"
    },
    {
        "engDate": "1965-02-27",
        "nepDate": "16.11.2021"
    },
    {
        "engDate": "1965-02-28",
        "nepDate": "17.11.2021"
    },
    {
        "engDate": "1965-03-01",
        "nepDate": "18.11.2021"
    },
    {
        "engDate": "1965-03-02",
        "nepDate": "19.11.2021"
    },
    {
        "engDate": "1965-03-03",
        "nepDate": "20.11.2021"
    },
    {
        "engDate": "1965-03-04",
        "nepDate": "21.11.2021"
    },
    {
        "engDate": "1965-03-05",
        "nepDate": "22.11.2021"
    },
    {
        "engDate": "1965-03-06",
        "nepDate": "23.11.2021"
    },
    {
        "engDate": "1965-03-07",
        "nepDate": "24.11.2021"
    },
    {
        "engDate": "1965-03-08",
        "nepDate": "25.11.2021"
    },
    {
        "engDate": "1965-03-09",
        "nepDate": "26.11.2021"
    },
    {
        "engDate": "1965-03-10",
        "nepDate": "27.11.2021"
    },
    {
        "engDate": "1965-03-11",
        "nepDate": "28.11.2021"
    },
    {
        "engDate": "1965-03-12",
        "nepDate": "29.11.2021"
    },
    {
        "engDate": "1965-03-13",
        "nepDate": "30.11.2021"
    },
    {
        "engDate": "1965-03-14",
        "nepDate": "01.12.2021"
    },
    {
        "engDate": "1965-03-15",
        "nepDate": "02.12.2021"
    },
    {
        "engDate": "1965-03-16",
        "nepDate": "03.12.2021"
    },
    {
        "engDate": "1965-03-17",
        "nepDate": "04.12.2021"
    },
    {
        "engDate": "1965-03-18",
        "nepDate": "05.12.2021"
    },
    {
        "engDate": "1965-03-19",
        "nepDate": "06.12.2021"
    },
    {
        "engDate": "1965-03-20",
        "nepDate": "07.12.2021"
    },
    {
        "engDate": "1965-03-21",
        "nepDate": "08.12.2021"
    },
    {
        "engDate": "1965-03-22",
        "nepDate": "09.12.2021"
    },
    {
        "engDate": "1965-03-23",
        "nepDate": "10.12.2021"
    },
    {
        "engDate": "1965-03-24",
        "nepDate": "11.12.2021"
    },
    {
        "engDate": "1965-03-25",
        "nepDate": "12.12.2021"
    },
    {
        "engDate": "1965-03-26",
        "nepDate": "13.12.2021"
    },
    {
        "engDate": "1965-03-27",
        "nepDate": "14.12.2021"
    },
    {
        "engDate": "1965-03-28",
        "nepDate": "15.12.2021"
    },
    {
        "engDate": "1965-03-29",
        "nepDate": "16.12.2021"
    },
    {
        "engDate": "1965-03-30",
        "nepDate": "17.12.2021"
    },
    {
        "engDate": "1965-03-31",
        "nepDate": "18.12.2021"
    },
    {
        "engDate": "1965-04-01",
        "nepDate": "19.12.2021"
    },
    {
        "engDate": "1965-04-02",
        "nepDate": "20.12.2021"
    },
    {
        "engDate": "1965-04-03",
        "nepDate": "21.12.2021"
    },
    {
        "engDate": "1965-04-04",
        "nepDate": "22.12.2021"
    },
    {
        "engDate": "1965-04-05",
        "nepDate": "23.12.2021"
    },
    {
        "engDate": "1965-04-06",
        "nepDate": "24.12.2021"
    },
    {
        "engDate": "1965-04-07",
        "nepDate": "25.12.2021"
    },
    {
        "engDate": "1965-04-08",
        "nepDate": "26.12.2021"
    },
    {
        "engDate": "1965-04-09",
        "nepDate": "27.12.2021"
    },
    {
        "engDate": "1965-04-10",
        "nepDate": "28.12.2021"
    },
    {
        "engDate": "1965-04-11",
        "nepDate": "29.12.2021"
    },
    {
        "engDate": "1965-04-12",
        "nepDate": "30.12.2021"
    },
    {
        "engDate": "1965-04-13",
        "nepDate": "01.01.2022"
    },
    {
        "engDate": "1965-04-14",
        "nepDate": "02.01.2022"
    },
    {
        "engDate": "1965-04-15",
        "nepDate": "03.01.2022"
    },
    {
        "engDate": "1965-04-16",
        "nepDate": "04.01.2022"
    },
    {
        "engDate": "1965-04-17",
        "nepDate": "05.01.2022"
    },
    {
        "engDate": "1965-04-18",
        "nepDate": "06.01.2022"
    },
    {
        "engDate": "1965-04-19",
        "nepDate": "07.01.2022"
    },
    {
        "engDate": "1965-04-20",
        "nepDate": "08.01.2022"
    },
    {
        "engDate": "1965-04-21",
        "nepDate": "09.01.2022"
    },
    {
        "engDate": "1965-04-22",
        "nepDate": "10.01.2022"
    },
    {
        "engDate": "1965-04-23",
        "nepDate": "11.01.2022"
    },
    {
        "engDate": "1965-04-24",
        "nepDate": "12.01.2022"
    },
    {
        "engDate": "1965-04-25",
        "nepDate": "13.01.2022"
    },
    {
        "engDate": "1965-04-26",
        "nepDate": "14.01.2022"
    },
    {
        "engDate": "1965-04-27",
        "nepDate": "15.01.2022"
    },
    {
        "engDate": "1965-04-28",
        "nepDate": "16.01.2022"
    },
    {
        "engDate": "1965-04-29",
        "nepDate": "17.01.2022"
    },
    {
        "engDate": "1965-04-30",
        "nepDate": "18.01.2022"
    },
    {
        "engDate": "1965-05-01",
        "nepDate": "19.01.2022"
    },
    {
        "engDate": "1965-05-02",
        "nepDate": "20.01.2022"
    },
    {
        "engDate": "1965-05-03",
        "nepDate": "21.01.2022"
    },
    {
        "engDate": "1965-05-04",
        "nepDate": "22.01.2022"
    },
    {
        "engDate": "1965-05-05",
        "nepDate": "23.01.2022"
    },
    {
        "engDate": "1965-05-06",
        "nepDate": "24.01.2022"
    },
    {
        "engDate": "1965-05-07",
        "nepDate": "25.01.2022"
    },
    {
        "engDate": "1965-05-08",
        "nepDate": "26.01.2022"
    },
    {
        "engDate": "1965-05-09",
        "nepDate": "27.01.2022"
    },
    {
        "engDate": "1965-05-10",
        "nepDate": "28.01.2022"
    },
    {
        "engDate": "1965-05-11",
        "nepDate": "29.01.2022"
    },
    {
        "engDate": "1965-05-12",
        "nepDate": "30.01.2022"
    },
    {
        "engDate": "1965-05-13",
        "nepDate": "31.01.2022"
    },
    {
        "engDate": "1965-05-14",
        "nepDate": "01.02.2022"
    },
    {
        "engDate": "1965-05-15",
        "nepDate": "02.02.2022"
    },
    {
        "engDate": "1965-05-16",
        "nepDate": "03.02.2022"
    },
    {
        "engDate": "1965-05-17",
        "nepDate": "04.02.2022"
    },
    {
        "engDate": "1965-05-18",
        "nepDate": "05.02.2022"
    },
    {
        "engDate": "1965-05-19",
        "nepDate": "06.02.2022"
    },
    {
        "engDate": "1965-05-20",
        "nepDate": "07.02.2022"
    },
    {
        "engDate": "1965-05-21",
        "nepDate": "08.02.2022"
    },
    {
        "engDate": "1965-05-22",
        "nepDate": "09.02.2022"
    },
    {
        "engDate": "1965-05-23",
        "nepDate": "10.02.2022"
    },
    {
        "engDate": "1965-05-24",
        "nepDate": "11.02.2022"
    },
    {
        "engDate": "1965-05-25",
        "nepDate": "12.02.2022"
    },
    {
        "engDate": "1965-05-26",
        "nepDate": "13.02.2022"
    },
    {
        "engDate": "1965-05-27",
        "nepDate": "14.02.2022"
    },
    {
        "engDate": "1965-05-28",
        "nepDate": "15.02.2022"
    },
    {
        "engDate": "1965-05-29",
        "nepDate": "16.02.2022"
    },
    {
        "engDate": "1965-05-30",
        "nepDate": "17.02.2022"
    },
    {
        "engDate": "1965-05-31",
        "nepDate": "18.02.2022"
    },
    {
        "engDate": "1965-06-01",
        "nepDate": "19.02.2022"
    },
    {
        "engDate": "1965-06-02",
        "nepDate": "20.02.2022"
    },
    {
        "engDate": "1965-06-03",
        "nepDate": "21.02.2022"
    },
    {
        "engDate": "1965-06-04",
        "nepDate": "22.02.2022"
    },
    {
        "engDate": "1965-06-05",
        "nepDate": "23.02.2022"
    },
    {
        "engDate": "1965-06-06",
        "nepDate": "24.02.2022"
    },
    {
        "engDate": "1965-06-07",
        "nepDate": "25.02.2022"
    },
    {
        "engDate": "1965-06-08",
        "nepDate": "26.02.2022"
    },
    {
        "engDate": "1965-06-09",
        "nepDate": "27.02.2022"
    },
    {
        "engDate": "1965-06-10",
        "nepDate": "28.02.2022"
    },
    {
        "engDate": "1965-06-11",
        "nepDate": "29.02.2022"
    },
    {
        "engDate": "1965-06-12",
        "nepDate": "30.02.2022"
    },
    {
        "engDate": "1965-06-13",
        "nepDate": "31.02.2022"
    },
    {
        "engDate": "1965-06-14",
        "nepDate": "32.02.2022"
    },
    {
        "engDate": "1965-06-15",
        "nepDate": "01.03.2022"
    },
    {
        "engDate": "1965-06-16",
        "nepDate": "02.03.2022"
    },
    {
        "engDate": "1965-06-17",
        "nepDate": "03.03.2022"
    },
    {
        "engDate": "1965-06-18",
        "nepDate": "04.03.2022"
    },
    {
        "engDate": "1965-06-19",
        "nepDate": "05.03.2022"
    },
    {
        "engDate": "1965-06-20",
        "nepDate": "06.03.2022"
    },
    {
        "engDate": "1965-06-21",
        "nepDate": "07.03.2022"
    },
    {
        "engDate": "1965-06-22",
        "nepDate": "08.03.2022"
    },
    {
        "engDate": "1965-06-23",
        "nepDate": "09.03.2022"
    },
    {
        "engDate": "1965-06-24",
        "nepDate": "10.03.2022"
    },
    {
        "engDate": "1965-06-25",
        "nepDate": "11.03.2022"
    },
    {
        "engDate": "1965-06-26",
        "nepDate": "12.03.2022"
    },
    {
        "engDate": "1965-06-27",
        "nepDate": "13.03.2022"
    },
    {
        "engDate": "1965-06-28",
        "nepDate": "14.03.2022"
    },
    {
        "engDate": "1965-06-29",
        "nepDate": "15.03.2022"
    },
    {
        "engDate": "1965-06-30",
        "nepDate": "16.03.2022"
    },
    {
        "engDate": "1965-07-01",
        "nepDate": "17.03.2022"
    },
    {
        "engDate": "1965-07-02",
        "nepDate": "18.03.2022"
    },
    {
        "engDate": "1965-07-03",
        "nepDate": "19.03.2022"
    },
    {
        "engDate": "1965-07-04",
        "nepDate": "20.03.2022"
    },
    {
        "engDate": "1965-07-05",
        "nepDate": "21.03.2022"
    },
    {
        "engDate": "1965-07-06",
        "nepDate": "22.03.2022"
    },
    {
        "engDate": "1965-07-07",
        "nepDate": "23.03.2022"
    },
    {
        "engDate": "1965-07-08",
        "nepDate": "24.03.2022"
    },
    {
        "engDate": "1965-07-09",
        "nepDate": "25.03.2022"
    },
    {
        "engDate": "1965-07-10",
        "nepDate": "26.03.2022"
    },
    {
        "engDate": "1965-07-11",
        "nepDate": "27.03.2022"
    },
    {
        "engDate": "1965-07-12",
        "nepDate": "28.03.2022"
    },
    {
        "engDate": "1965-07-13",
        "nepDate": "29.03.2022"
    },
    {
        "engDate": "1965-07-14",
        "nepDate": "30.03.2022"
    },
    {
        "engDate": "1965-07-15",
        "nepDate": "31.03.2022"
    },
    {
        "engDate": "1965-07-16",
        "nepDate": "01.04.2022"
    },
    {
        "engDate": "1965-07-17",
        "nepDate": "02.04.2022"
    },
    {
        "engDate": "1965-07-18",
        "nepDate": "03.04.2022"
    },
    {
        "engDate": "1965-07-19",
        "nepDate": "04.04.2022"
    },
    {
        "engDate": "1965-07-20",
        "nepDate": "05.04.2022"
    },
    {
        "engDate": "1965-07-21",
        "nepDate": "06.04.2022"
    },
    {
        "engDate": "1965-07-22",
        "nepDate": "07.04.2022"
    },
    {
        "engDate": "1965-07-23",
        "nepDate": "08.04.2022"
    },
    {
        "engDate": "1965-07-24",
        "nepDate": "09.04.2022"
    },
    {
        "engDate": "1965-07-25",
        "nepDate": "10.04.2022"
    },
    {
        "engDate": "1965-07-26",
        "nepDate": "11.04.2022"
    },
    {
        "engDate": "1965-07-27",
        "nepDate": "12.04.2022"
    },
    {
        "engDate": "1965-07-28",
        "nepDate": "13.04.2022"
    },
    {
        "engDate": "1965-07-29",
        "nepDate": "14.04.2022"
    },
    {
        "engDate": "1965-07-30",
        "nepDate": "15.04.2022"
    },
    {
        "engDate": "1965-07-31",
        "nepDate": "16.04.2022"
    },
    {
        "engDate": "1965-08-01",
        "nepDate": "17.04.2022"
    },
    {
        "engDate": "1965-08-02",
        "nepDate": "18.04.2022"
    },
    {
        "engDate": "1965-08-03",
        "nepDate": "19.04.2022"
    },
    {
        "engDate": "1965-08-04",
        "nepDate": "20.04.2022"
    },
    {
        "engDate": "1965-08-05",
        "nepDate": "21.04.2022"
    },
    {
        "engDate": "1965-08-06",
        "nepDate": "22.04.2022"
    },
    {
        "engDate": "1965-08-07",
        "nepDate": "23.04.2022"
    },
    {
        "engDate": "1965-08-08",
        "nepDate": "24.04.2022"
    },
    {
        "engDate": "1965-08-09",
        "nepDate": "25.04.2022"
    },
    {
        "engDate": "1965-08-10",
        "nepDate": "26.04.2022"
    },
    {
        "engDate": "1965-08-11",
        "nepDate": "27.04.2022"
    },
    {
        "engDate": "1965-08-12",
        "nepDate": "28.04.2022"
    },
    {
        "engDate": "1965-08-13",
        "nepDate": "29.04.2022"
    },
    {
        "engDate": "1965-08-14",
        "nepDate": "30.04.2022"
    },
    {
        "engDate": "1965-08-15",
        "nepDate": "31.04.2022"
    },
    {
        "engDate": "1965-08-16",
        "nepDate": "32.04.2022"
    },
    {
        "engDate": "1965-08-17",
        "nepDate": "01.05.2022"
    },
    {
        "engDate": "1965-08-18",
        "nepDate": "02.05.2022"
    },
    {
        "engDate": "1965-08-19",
        "nepDate": "03.05.2022"
    },
    {
        "engDate": "1965-08-20",
        "nepDate": "04.05.2022"
    },
    {
        "engDate": "1965-08-21",
        "nepDate": "05.05.2022"
    },
    {
        "engDate": "1965-08-22",
        "nepDate": "06.05.2022"
    },
    {
        "engDate": "1965-08-23",
        "nepDate": "07.05.2022"
    },
    {
        "engDate": "1965-08-24",
        "nepDate": "08.05.2022"
    },
    {
        "engDate": "1965-08-25",
        "nepDate": "09.05.2022"
    },
    {
        "engDate": "1965-08-26",
        "nepDate": "10.05.2022"
    },
    {
        "engDate": "1965-08-27",
        "nepDate": "11.05.2022"
    },
    {
        "engDate": "1965-08-28",
        "nepDate": "12.05.2022"
    },
    {
        "engDate": "1965-08-29",
        "nepDate": "13.05.2022"
    },
    {
        "engDate": "1965-08-30",
        "nepDate": "14.05.2022"
    },
    {
        "engDate": "1965-08-31",
        "nepDate": "15.05.2022"
    },
    {
        "engDate": "1965-09-01",
        "nepDate": "16.05.2022"
    },
    {
        "engDate": "1965-09-02",
        "nepDate": "17.05.2022"
    },
    {
        "engDate": "1965-09-03",
        "nepDate": "18.05.2022"
    },
    {
        "engDate": "1965-09-04",
        "nepDate": "19.05.2022"
    },
    {
        "engDate": "1965-09-05",
        "nepDate": "20.05.2022"
    },
    {
        "engDate": "1965-09-06",
        "nepDate": "21.05.2022"
    },
    {
        "engDate": "1965-09-07",
        "nepDate": "22.05.2022"
    },
    {
        "engDate": "1965-09-08",
        "nepDate": "23.05.2022"
    },
    {
        "engDate": "1965-09-09",
        "nepDate": "24.05.2022"
    },
    {
        "engDate": "1965-09-10",
        "nepDate": "25.05.2022"
    },
    {
        "engDate": "1965-09-11",
        "nepDate": "26.05.2022"
    },
    {
        "engDate": "1965-09-12",
        "nepDate": "27.05.2022"
    },
    {
        "engDate": "1965-09-13",
        "nepDate": "28.05.2022"
    },
    {
        "engDate": "1965-09-14",
        "nepDate": "29.05.2022"
    },
    {
        "engDate": "1965-09-15",
        "nepDate": "30.05.2022"
    },
    {
        "engDate": "1965-09-16",
        "nepDate": "31.05.2022"
    },
    {
        "engDate": "1965-09-17",
        "nepDate": "01.06.2022"
    },
    {
        "engDate": "1965-09-18",
        "nepDate": "02.06.2022"
    },
    {
        "engDate": "1965-09-19",
        "nepDate": "03.06.2022"
    },
    {
        "engDate": "1965-09-20",
        "nepDate": "04.06.2022"
    },
    {
        "engDate": "1965-09-21",
        "nepDate": "05.06.2022"
    },
    {
        "engDate": "1965-09-22",
        "nepDate": "06.06.2022"
    },
    {
        "engDate": "1965-09-23",
        "nepDate": "07.06.2022"
    },
    {
        "engDate": "1965-09-24",
        "nepDate": "08.06.2022"
    },
    {
        "engDate": "1965-09-25",
        "nepDate": "09.06.2022"
    },
    {
        "engDate": "1965-09-26",
        "nepDate": "10.06.2022"
    },
    {
        "engDate": "1965-09-27",
        "nepDate": "11.06.2022"
    },
    {
        "engDate": "1965-09-28",
        "nepDate": "12.06.2022"
    },
    {
        "engDate": "1965-09-29",
        "nepDate": "13.06.2022"
    },
    {
        "engDate": "1965-09-30",
        "nepDate": "14.06.2022"
    },
    {
        "engDate": "1965-10-01",
        "nepDate": "15.06.2022"
    },
    {
        "engDate": "1965-10-02",
        "nepDate": "16.06.2022"
    },
    {
        "engDate": "1965-10-03",
        "nepDate": "17.06.2022"
    },
    {
        "engDate": "1965-10-04",
        "nepDate": "18.06.2022"
    },
    {
        "engDate": "1965-10-05",
        "nepDate": "19.06.2022"
    },
    {
        "engDate": "1965-10-06",
        "nepDate": "20.06.2022"
    },
    {
        "engDate": "1965-10-07",
        "nepDate": "21.06.2022"
    },
    {
        "engDate": "1965-10-08",
        "nepDate": "22.06.2022"
    },
    {
        "engDate": "1965-10-09",
        "nepDate": "23.06.2022"
    },
    {
        "engDate": "1965-10-10",
        "nepDate": "24.06.2022"
    },
    {
        "engDate": "1965-10-11",
        "nepDate": "25.06.2022"
    },
    {
        "engDate": "1965-10-12",
        "nepDate": "26.06.2022"
    },
    {
        "engDate": "1965-10-13",
        "nepDate": "27.06.2022"
    },
    {
        "engDate": "1965-10-14",
        "nepDate": "28.06.2022"
    },
    {
        "engDate": "1965-10-15",
        "nepDate": "29.06.2022"
    },
    {
        "engDate": "1965-10-16",
        "nepDate": "30.06.2022"
    },
    {
        "engDate": "1965-10-17",
        "nepDate": "01.07.2022"
    },
    {
        "engDate": "1965-10-18",
        "nepDate": "02.07.2022"
    },
    {
        "engDate": "1965-10-19",
        "nepDate": "03.07.2022"
    },
    {
        "engDate": "1965-10-20",
        "nepDate": "04.07.2022"
    },
    {
        "engDate": "1965-10-21",
        "nepDate": "05.07.2022"
    },
    {
        "engDate": "1965-10-22",
        "nepDate": "06.07.2022"
    },
    {
        "engDate": "1965-10-23",
        "nepDate": "07.07.2022"
    },
    {
        "engDate": "1965-10-24",
        "nepDate": "08.07.2022"
    },
    {
        "engDate": "1965-10-25",
        "nepDate": "09.07.2022"
    },
    {
        "engDate": "1965-10-26",
        "nepDate": "10.07.2022"
    },
    {
        "engDate": "1965-10-27",
        "nepDate": "11.07.2022"
    },
    {
        "engDate": "1965-10-28",
        "nepDate": "12.07.2022"
    },
    {
        "engDate": "1965-10-29",
        "nepDate": "13.07.2022"
    },
    {
        "engDate": "1965-10-30",
        "nepDate": "14.07.2022"
    },
    {
        "engDate": "1965-10-31",
        "nepDate": "15.07.2022"
    },
    {
        "engDate": "1965-11-01",
        "nepDate": "16.07.2022"
    },
    {
        "engDate": "1965-11-02",
        "nepDate": "17.07.2022"
    },
    {
        "engDate": "1965-11-03",
        "nepDate": "18.07.2022"
    },
    {
        "engDate": "1965-11-04",
        "nepDate": "19.07.2022"
    },
    {
        "engDate": "1965-11-05",
        "nepDate": "20.07.2022"
    },
    {
        "engDate": "1965-11-06",
        "nepDate": "21.07.2022"
    },
    {
        "engDate": "1965-11-07",
        "nepDate": "22.07.2022"
    },
    {
        "engDate": "1965-11-08",
        "nepDate": "23.07.2022"
    },
    {
        "engDate": "1965-11-09",
        "nepDate": "24.07.2022"
    },
    {
        "engDate": "1965-11-10",
        "nepDate": "25.07.2022"
    },
    {
        "engDate": "1965-11-11",
        "nepDate": "26.07.2022"
    },
    {
        "engDate": "1965-11-12",
        "nepDate": "27.07.2022"
    },
    {
        "engDate": "1965-11-13",
        "nepDate": "28.07.2022"
    },
    {
        "engDate": "1965-11-14",
        "nepDate": "29.07.2022"
    },
    {
        "engDate": "1965-11-15",
        "nepDate": "30.07.2022"
    },
    {
        "engDate": "1965-11-16",
        "nepDate": "01.08.2022"
    },
    {
        "engDate": "1965-11-17",
        "nepDate": "02.08.2022"
    },
    {
        "engDate": "1965-11-18",
        "nepDate": "03.08.2022"
    },
    {
        "engDate": "1965-11-19",
        "nepDate": "04.08.2022"
    },
    {
        "engDate": "1965-11-20",
        "nepDate": "05.08.2022"
    },
    {
        "engDate": "1965-11-21",
        "nepDate": "06.08.2022"
    },
    {
        "engDate": "1965-11-22",
        "nepDate": "07.08.2022"
    },
    {
        "engDate": "1965-11-23",
        "nepDate": "08.08.2022"
    },
    {
        "engDate": "1965-11-24",
        "nepDate": "09.08.2022"
    },
    {
        "engDate": "1965-11-25",
        "nepDate": "10.08.2022"
    },
    {
        "engDate": "1965-11-26",
        "nepDate": "11.08.2022"
    },
    {
        "engDate": "1965-11-27",
        "nepDate": "12.08.2022"
    },
    {
        "engDate": "1965-11-28",
        "nepDate": "13.08.2022"
    },
    {
        "engDate": "1965-11-29",
        "nepDate": "14.08.2022"
    },
    {
        "engDate": "1965-11-30",
        "nepDate": "15.08.2022"
    },
    {
        "engDate": "1965-12-01",
        "nepDate": "16.08.2022"
    },
    {
        "engDate": "1965-12-02",
        "nepDate": "17.08.2022"
    },
    {
        "engDate": "1965-12-03",
        "nepDate": "18.08.2022"
    },
    {
        "engDate": "1965-12-04",
        "nepDate": "19.08.2022"
    },
    {
        "engDate": "1965-12-05",
        "nepDate": "20.08.2022"
    },
    {
        "engDate": "1965-12-06",
        "nepDate": "21.08.2022"
    },
    {
        "engDate": "1965-12-07",
        "nepDate": "22.08.2022"
    },
    {
        "engDate": "1965-12-08",
        "nepDate": "23.08.2022"
    },
    {
        "engDate": "1965-12-09",
        "nepDate": "24.08.2022"
    },
    {
        "engDate": "1965-12-10",
        "nepDate": "25.08.2022"
    },
    {
        "engDate": "1965-12-11",
        "nepDate": "26.08.2022"
    },
    {
        "engDate": "1965-12-12",
        "nepDate": "27.08.2022"
    },
    {
        "engDate": "1965-12-13",
        "nepDate": "28.08.2022"
    },
    {
        "engDate": "1965-12-14",
        "nepDate": "29.08.2022"
    },
    {
        "engDate": "1965-12-15",
        "nepDate": "30.08.2022"
    },
    {
        "engDate": "1965-12-16",
        "nepDate": "01.09.2022"
    },
    {
        "engDate": "1965-12-17",
        "nepDate": "02.09.2022"
    },
    {
        "engDate": "1965-12-18",
        "nepDate": "03.09.2022"
    },
    {
        "engDate": "1965-12-19",
        "nepDate": "04.09.2022"
    },
    {
        "engDate": "1965-12-20",
        "nepDate": "05.09.2022"
    },
    {
        "engDate": "1965-12-21",
        "nepDate": "06.09.2022"
    },
    {
        "engDate": "1965-12-22",
        "nepDate": "07.09.2022"
    },
    {
        "engDate": "1965-12-23",
        "nepDate": "08.09.2022"
    },
    {
        "engDate": "1965-12-24",
        "nepDate": "09.09.2022"
    },
    {
        "engDate": "1965-12-25",
        "nepDate": "10.09.2022"
    },
    {
        "engDate": "1965-12-26",
        "nepDate": "11.09.2022"
    },
    {
        "engDate": "1965-12-27",
        "nepDate": "12.09.2022"
    },
    {
        "engDate": "1965-12-28",
        "nepDate": "13.09.2022"
    },
    {
        "engDate": "1965-12-29",
        "nepDate": "14.09.2022"
    },
    {
        "engDate": "1965-12-30",
        "nepDate": "15.09.2022"
    },
    {
        "engDate": "1965-12-31",
        "nepDate": "16.09.2022"
    },
    {
        "engDate": "1966-01-01",
        "nepDate": "17.09.2022"
    },
    {
        "engDate": "1966-01-02",
        "nepDate": "18.09.2022"
    },
    {
        "engDate": "1966-01-03",
        "nepDate": "19.09.2022"
    },
    {
        "engDate": "1966-01-04",
        "nepDate": "20.09.2022"
    },
    {
        "engDate": "1966-01-05",
        "nepDate": "21.09.2022"
    },
    {
        "engDate": "1966-01-06",
        "nepDate": "22.09.2022"
    },
    {
        "engDate": "1966-01-07",
        "nepDate": "23.09.2022"
    },
    {
        "engDate": "1966-01-08",
        "nepDate": "24.09.2022"
    },
    {
        "engDate": "1966-01-09",
        "nepDate": "25.09.2022"
    },
    {
        "engDate": "1966-01-10",
        "nepDate": "26.09.2022"
    },
    {
        "engDate": "1966-01-11",
        "nepDate": "27.09.2022"
    },
    {
        "engDate": "1966-01-12",
        "nepDate": "28.09.2022"
    },
    {
        "engDate": "1966-01-13",
        "nepDate": "29.09.2022"
    },
    {
        "engDate": "1966-01-14",
        "nepDate": "01.10.2022"
    },
    {
        "engDate": "1966-01-15",
        "nepDate": "02.10.2022"
    },
    {
        "engDate": "1966-01-16",
        "nepDate": "03.10.2022"
    },
    {
        "engDate": "1966-01-17",
        "nepDate": "04.10.2022"
    },
    {
        "engDate": "1966-01-18",
        "nepDate": "05.10.2022"
    },
    {
        "engDate": "1966-01-19",
        "nepDate": "06.10.2022"
    },
    {
        "engDate": "1966-01-20",
        "nepDate": "07.10.2022"
    },
    {
        "engDate": "1966-01-21",
        "nepDate": "08.10.2022"
    },
    {
        "engDate": "1966-01-22",
        "nepDate": "09.10.2022"
    },
    {
        "engDate": "1966-01-23",
        "nepDate": "10.10.2022"
    },
    {
        "engDate": "1966-01-24",
        "nepDate": "11.10.2022"
    },
    {
        "engDate": "1966-01-25",
        "nepDate": "12.10.2022"
    },
    {
        "engDate": "1966-01-26",
        "nepDate": "13.10.2022"
    },
    {
        "engDate": "1966-01-27",
        "nepDate": "14.10.2022"
    },
    {
        "engDate": "1966-01-28",
        "nepDate": "15.10.2022"
    },
    {
        "engDate": "1966-01-29",
        "nepDate": "16.10.2022"
    },
    {
        "engDate": "1966-01-30",
        "nepDate": "17.10.2022"
    },
    {
        "engDate": "1966-01-31",
        "nepDate": "18.10.2022"
    },
    {
        "engDate": "1966-02-01",
        "nepDate": "19.10.2022"
    },
    {
        "engDate": "1966-02-02",
        "nepDate": "20.10.2022"
    },
    {
        "engDate": "1966-02-03",
        "nepDate": "21.10.2022"
    },
    {
        "engDate": "1966-02-04",
        "nepDate": "22.10.2022"
    },
    {
        "engDate": "1966-02-05",
        "nepDate": "23.10.2022"
    },
    {
        "engDate": "1966-02-06",
        "nepDate": "24.10.2022"
    },
    {
        "engDate": "1966-02-07",
        "nepDate": "25.10.2022"
    },
    {
        "engDate": "1966-02-08",
        "nepDate": "26.10.2022"
    },
    {
        "engDate": "1966-02-09",
        "nepDate": "27.10.2022"
    },
    {
        "engDate": "1966-02-10",
        "nepDate": "28.10.2022"
    },
    {
        "engDate": "1966-02-11",
        "nepDate": "29.10.2022"
    },
    {
        "engDate": "1966-02-12",
        "nepDate": "01.11.2022"
    },
    {
        "engDate": "1966-02-13",
        "nepDate": "02.11.2022"
    },
    {
        "engDate": "1966-02-14",
        "nepDate": "03.11.2022"
    },
    {
        "engDate": "1966-02-15",
        "nepDate": "04.11.2022"
    },
    {
        "engDate": "1966-02-16",
        "nepDate": "05.11.2022"
    },
    {
        "engDate": "1966-02-17",
        "nepDate": "06.11.2022"
    },
    {
        "engDate": "1966-02-18",
        "nepDate": "07.11.2022"
    },
    {
        "engDate": "1966-02-19",
        "nepDate": "08.11.2022"
    },
    {
        "engDate": "1966-02-20",
        "nepDate": "09.11.2022"
    },
    {
        "engDate": "1966-02-21",
        "nepDate": "10.11.2022"
    },
    {
        "engDate": "1966-02-22",
        "nepDate": "11.11.2022"
    },
    {
        "engDate": "1966-02-23",
        "nepDate": "12.11.2022"
    },
    {
        "engDate": "1966-02-24",
        "nepDate": "13.11.2022"
    },
    {
        "engDate": "1966-02-25",
        "nepDate": "14.11.2022"
    },
    {
        "engDate": "1966-02-26",
        "nepDate": "15.11.2022"
    },
    {
        "engDate": "1966-02-27",
        "nepDate": "16.11.2022"
    },
    {
        "engDate": "1966-02-28",
        "nepDate": "17.11.2022"
    },
    {
        "engDate": "1966-03-01",
        "nepDate": "18.11.2022"
    },
    {
        "engDate": "1966-03-02",
        "nepDate": "19.11.2022"
    },
    {
        "engDate": "1966-03-03",
        "nepDate": "20.11.2022"
    },
    {
        "engDate": "1966-03-04",
        "nepDate": "21.11.2022"
    },
    {
        "engDate": "1966-03-05",
        "nepDate": "22.11.2022"
    },
    {
        "engDate": "1966-03-06",
        "nepDate": "23.11.2022"
    },
    {
        "engDate": "1966-03-07",
        "nepDate": "24.11.2022"
    },
    {
        "engDate": "1966-03-08",
        "nepDate": "25.11.2022"
    },
    {
        "engDate": "1966-03-09",
        "nepDate": "26.11.2022"
    },
    {
        "engDate": "1966-03-10",
        "nepDate": "27.11.2022"
    },
    {
        "engDate": "1966-03-11",
        "nepDate": "28.11.2022"
    },
    {
        "engDate": "1966-03-12",
        "nepDate": "29.11.2022"
    },
    {
        "engDate": "1966-03-13",
        "nepDate": "30.11.2022"
    },
    {
        "engDate": "1966-03-14",
        "nepDate": "01.12.2022"
    },
    {
        "engDate": "1966-03-15",
        "nepDate": "02.12.2022"
    },
    {
        "engDate": "1966-03-16",
        "nepDate": "03.12.2022"
    },
    {
        "engDate": "1966-03-17",
        "nepDate": "04.12.2022"
    },
    {
        "engDate": "1966-03-18",
        "nepDate": "05.12.2022"
    },
    {
        "engDate": "1966-03-19",
        "nepDate": "06.12.2022"
    },
    {
        "engDate": "1966-03-20",
        "nepDate": "07.12.2022"
    },
    {
        "engDate": "1966-03-21",
        "nepDate": "08.12.2022"
    },
    {
        "engDate": "1966-03-22",
        "nepDate": "09.12.2022"
    },
    {
        "engDate": "1966-03-23",
        "nepDate": "10.12.2022"
    },
    {
        "engDate": "1966-03-24",
        "nepDate": "11.12.2022"
    },
    {
        "engDate": "1966-03-25",
        "nepDate": "12.12.2022"
    },
    {
        "engDate": "1966-03-26",
        "nepDate": "13.12.2022"
    },
    {
        "engDate": "1966-03-27",
        "nepDate": "14.12.2022"
    },
    {
        "engDate": "1966-03-28",
        "nepDate": "15.12.2022"
    },
    {
        "engDate": "1966-03-29",
        "nepDate": "16.12.2022"
    },
    {
        "engDate": "1966-03-30",
        "nepDate": "17.12.2022"
    },
    {
        "engDate": "1966-03-31",
        "nepDate": "18.12.2022"
    },
    {
        "engDate": "1966-04-01",
        "nepDate": "19.12.2022"
    },
    {
        "engDate": "1966-04-02",
        "nepDate": "20.12.2022"
    },
    {
        "engDate": "1966-04-03",
        "nepDate": "21.12.2022"
    },
    {
        "engDate": "1966-04-04",
        "nepDate": "22.12.2022"
    },
    {
        "engDate": "1966-04-05",
        "nepDate": "23.12.2022"
    },
    {
        "engDate": "1966-04-06",
        "nepDate": "24.12.2022"
    },
    {
        "engDate": "1966-04-07",
        "nepDate": "25.12.2022"
    },
    {
        "engDate": "1966-04-08",
        "nepDate": "26.12.2022"
    },
    {
        "engDate": "1966-04-09",
        "nepDate": "27.12.2022"
    },
    {
        "engDate": "1966-04-10",
        "nepDate": "28.12.2022"
    },
    {
        "engDate": "1966-04-11",
        "nepDate": "29.12.2022"
    },
    {
        "engDate": "1966-04-12",
        "nepDate": "30.12.2022"
    },
    {
        "engDate": "1966-04-13",
        "nepDate": "01.01.2023"
    },
    {
        "engDate": "1966-04-14",
        "nepDate": "02.01.2023"
    },
    {
        "engDate": "1966-04-15",
        "nepDate": "03.01.2023"
    },
    {
        "engDate": "1966-04-16",
        "nepDate": "04.01.2023"
    },
    {
        "engDate": "1966-04-17",
        "nepDate": "05.01.2023"
    },
    {
        "engDate": "1966-04-18",
        "nepDate": "06.01.2023"
    },
    {
        "engDate": "1966-04-19",
        "nepDate": "07.01.2023"
    },
    {
        "engDate": "1966-04-20",
        "nepDate": "08.01.2023"
    },
    {
        "engDate": "1966-04-21",
        "nepDate": "09.01.2023"
    },
    {
        "engDate": "1966-04-22",
        "nepDate": "10.01.2023"
    },
    {
        "engDate": "1966-04-23",
        "nepDate": "11.01.2023"
    },
    {
        "engDate": "1966-04-24",
        "nepDate": "12.01.2023"
    },
    {
        "engDate": "1966-04-25",
        "nepDate": "13.01.2023"
    },
    {
        "engDate": "1966-04-26",
        "nepDate": "14.01.2023"
    },
    {
        "engDate": "1966-04-27",
        "nepDate": "15.01.2023"
    },
    {
        "engDate": "1966-04-28",
        "nepDate": "16.01.2023"
    },
    {
        "engDate": "1966-04-29",
        "nepDate": "17.01.2023"
    },
    {
        "engDate": "1966-04-30",
        "nepDate": "18.01.2023"
    },
    {
        "engDate": "1966-05-01",
        "nepDate": "19.01.2023"
    },
    {
        "engDate": "1966-05-02",
        "nepDate": "20.01.2023"
    },
    {
        "engDate": "1966-05-03",
        "nepDate": "21.01.2023"
    },
    {
        "engDate": "1966-05-04",
        "nepDate": "22.01.2023"
    },
    {
        "engDate": "1966-05-05",
        "nepDate": "23.01.2023"
    },
    {
        "engDate": "1966-05-06",
        "nepDate": "24.01.2023"
    },
    {
        "engDate": "1966-05-07",
        "nepDate": "25.01.2023"
    },
    {
        "engDate": "1966-05-08",
        "nepDate": "26.01.2023"
    },
    {
        "engDate": "1966-05-09",
        "nepDate": "27.01.2023"
    },
    {
        "engDate": "1966-05-10",
        "nepDate": "28.01.2023"
    },
    {
        "engDate": "1966-05-11",
        "nepDate": "29.01.2023"
    },
    {
        "engDate": "1966-05-12",
        "nepDate": "30.01.2023"
    },
    {
        "engDate": "1966-05-13",
        "nepDate": "31.01.2023"
    },
    {
        "engDate": "1966-05-14",
        "nepDate": "01.02.2023"
    },
    {
        "engDate": "1966-05-15",
        "nepDate": "02.02.2023"
    },
    {
        "engDate": "1966-05-16",
        "nepDate": "03.02.2023"
    },
    {
        "engDate": "1966-05-17",
        "nepDate": "04.02.2023"
    },
    {
        "engDate": "1966-05-18",
        "nepDate": "05.02.2023"
    },
    {
        "engDate": "1966-05-19",
        "nepDate": "06.02.2023"
    },
    {
        "engDate": "1966-05-20",
        "nepDate": "07.02.2023"
    },
    {
        "engDate": "1966-05-21",
        "nepDate": "08.02.2023"
    },
    {
        "engDate": "1966-05-22",
        "nepDate": "09.02.2023"
    },
    {
        "engDate": "1966-05-23",
        "nepDate": "10.02.2023"
    },
    {
        "engDate": "1966-05-24",
        "nepDate": "11.02.2023"
    },
    {
        "engDate": "1966-05-25",
        "nepDate": "12.02.2023"
    },
    {
        "engDate": "1966-05-26",
        "nepDate": "13.02.2023"
    },
    {
        "engDate": "1966-05-27",
        "nepDate": "14.02.2023"
    },
    {
        "engDate": "1966-05-28",
        "nepDate": "15.02.2023"
    },
    {
        "engDate": "1966-05-29",
        "nepDate": "16.02.2023"
    },
    {
        "engDate": "1966-05-30",
        "nepDate": "17.02.2023"
    },
    {
        "engDate": "1966-05-31",
        "nepDate": "18.02.2023"
    },
    {
        "engDate": "1966-06-01",
        "nepDate": "19.02.2023"
    },
    {
        "engDate": "1966-06-02",
        "nepDate": "20.02.2023"
    },
    {
        "engDate": "1966-06-03",
        "nepDate": "21.02.2023"
    },
    {
        "engDate": "1966-06-04",
        "nepDate": "22.02.2023"
    },
    {
        "engDate": "1966-06-05",
        "nepDate": "23.02.2023"
    },
    {
        "engDate": "1966-06-06",
        "nepDate": "24.02.2023"
    },
    {
        "engDate": "1966-06-07",
        "nepDate": "25.02.2023"
    },
    {
        "engDate": "1966-06-08",
        "nepDate": "26.02.2023"
    },
    {
        "engDate": "1966-06-09",
        "nepDate": "27.02.2023"
    },
    {
        "engDate": "1966-06-10",
        "nepDate": "28.02.2023"
    },
    {
        "engDate": "1966-06-11",
        "nepDate": "29.02.2023"
    },
    {
        "engDate": "1966-06-12",
        "nepDate": "30.02.2023"
    },
    {
        "engDate": "1966-06-13",
        "nepDate": "31.02.2023"
    },
    {
        "engDate": "1966-06-14",
        "nepDate": "32.02.2023"
    },
    {
        "engDate": "1966-06-15",
        "nepDate": "01.03.2023"
    },
    {
        "engDate": "1966-06-16",
        "nepDate": "02.03.2023"
    },
    {
        "engDate": "1966-06-17",
        "nepDate": "03.03.2023"
    },
    {
        "engDate": "1966-06-18",
        "nepDate": "04.03.2023"
    },
    {
        "engDate": "1966-06-19",
        "nepDate": "05.03.2023"
    },
    {
        "engDate": "1966-06-20",
        "nepDate": "06.03.2023"
    },
    {
        "engDate": "1966-06-21",
        "nepDate": "07.03.2023"
    },
    {
        "engDate": "1966-06-22",
        "nepDate": "08.03.2023"
    },
    {
        "engDate": "1966-06-23",
        "nepDate": "09.03.2023"
    },
    {
        "engDate": "1966-06-24",
        "nepDate": "10.03.2023"
    },
    {
        "engDate": "1966-06-25",
        "nepDate": "11.03.2023"
    },
    {
        "engDate": "1966-06-26",
        "nepDate": "12.03.2023"
    },
    {
        "engDate": "1966-06-27",
        "nepDate": "13.03.2023"
    },
    {
        "engDate": "1966-06-28",
        "nepDate": "14.03.2023"
    },
    {
        "engDate": "1966-06-29",
        "nepDate": "15.03.2023"
    },
    {
        "engDate": "1966-06-30",
        "nepDate": "16.03.2023"
    },
    {
        "engDate": "1966-07-01",
        "nepDate": "17.03.2023"
    },
    {
        "engDate": "1966-07-02",
        "nepDate": "18.03.2023"
    },
    {
        "engDate": "1966-07-03",
        "nepDate": "19.03.2023"
    },
    {
        "engDate": "1966-07-04",
        "nepDate": "20.03.2023"
    },
    {
        "engDate": "1966-07-05",
        "nepDate": "21.03.2023"
    },
    {
        "engDate": "1966-07-06",
        "nepDate": "22.03.2023"
    },
    {
        "engDate": "1966-07-07",
        "nepDate": "23.03.2023"
    },
    {
        "engDate": "1966-07-08",
        "nepDate": "24.03.2023"
    },
    {
        "engDate": "1966-07-09",
        "nepDate": "25.03.2023"
    },
    {
        "engDate": "1966-07-10",
        "nepDate": "26.03.2023"
    },
    {
        "engDate": "1966-07-11",
        "nepDate": "27.03.2023"
    },
    {
        "engDate": "1966-07-12",
        "nepDate": "28.03.2023"
    },
    {
        "engDate": "1966-07-13",
        "nepDate": "29.03.2023"
    },
    {
        "engDate": "1966-07-14",
        "nepDate": "30.03.2023"
    },
    {
        "engDate": "1966-07-15",
        "nepDate": "31.03.2023"
    },
    {
        "engDate": "1966-07-16",
        "nepDate": "01.04.2023"
    },
    {
        "engDate": "1966-07-17",
        "nepDate": "02.04.2023"
    },
    {
        "engDate": "1966-07-18",
        "nepDate": "03.04.2023"
    },
    {
        "engDate": "1966-07-19",
        "nepDate": "04.04.2023"
    },
    {
        "engDate": "1966-07-20",
        "nepDate": "05.04.2023"
    },
    {
        "engDate": "1966-07-21",
        "nepDate": "06.04.2023"
    },
    {
        "engDate": "1966-07-22",
        "nepDate": "07.04.2023"
    },
    {
        "engDate": "1966-07-23",
        "nepDate": "08.04.2023"
    },
    {
        "engDate": "1966-07-24",
        "nepDate": "09.04.2023"
    },
    {
        "engDate": "1966-07-25",
        "nepDate": "10.04.2023"
    },
    {
        "engDate": "1966-07-26",
        "nepDate": "11.04.2023"
    },
    {
        "engDate": "1966-07-27",
        "nepDate": "12.04.2023"
    },
    {
        "engDate": "1966-07-28",
        "nepDate": "13.04.2023"
    },
    {
        "engDate": "1966-07-29",
        "nepDate": "14.04.2023"
    },
    {
        "engDate": "1966-07-30",
        "nepDate": "15.04.2023"
    },
    {
        "engDate": "1966-07-31",
        "nepDate": "16.04.2023"
    },
    {
        "engDate": "1966-08-01",
        "nepDate": "17.04.2023"
    },
    {
        "engDate": "1966-08-02",
        "nepDate": "18.04.2023"
    },
    {
        "engDate": "1966-08-03",
        "nepDate": "19.04.2023"
    },
    {
        "engDate": "1966-08-04",
        "nepDate": "20.04.2023"
    },
    {
        "engDate": "1966-08-05",
        "nepDate": "21.04.2023"
    },
    {
        "engDate": "1966-08-06",
        "nepDate": "22.04.2023"
    },
    {
        "engDate": "1966-08-07",
        "nepDate": "23.04.2023"
    },
    {
        "engDate": "1966-08-08",
        "nepDate": "24.04.2023"
    },
    {
        "engDate": "1966-08-09",
        "nepDate": "25.04.2023"
    },
    {
        "engDate": "1966-08-10",
        "nepDate": "26.04.2023"
    },
    {
        "engDate": "1966-08-11",
        "nepDate": "27.04.2023"
    },
    {
        "engDate": "1966-08-12",
        "nepDate": "28.04.2023"
    },
    {
        "engDate": "1966-08-13",
        "nepDate": "29.04.2023"
    },
    {
        "engDate": "1966-08-14",
        "nepDate": "30.04.2023"
    },
    {
        "engDate": "1966-08-15",
        "nepDate": "31.04.2023"
    },
    {
        "engDate": "1966-08-16",
        "nepDate": "32.04.2023"
    },
    {
        "engDate": "1966-08-17",
        "nepDate": "01.05.2023"
    },
    {
        "engDate": "1966-08-18",
        "nepDate": "02.05.2023"
    },
    {
        "engDate": "1966-08-19",
        "nepDate": "03.05.2023"
    },
    {
        "engDate": "1966-08-20",
        "nepDate": "04.05.2023"
    },
    {
        "engDate": "1966-08-21",
        "nepDate": "05.05.2023"
    },
    {
        "engDate": "1966-08-22",
        "nepDate": "06.05.2023"
    },
    {
        "engDate": "1966-08-23",
        "nepDate": "07.05.2023"
    },
    {
        "engDate": "1966-08-24",
        "nepDate": "08.05.2023"
    },
    {
        "engDate": "1966-08-25",
        "nepDate": "09.05.2023"
    },
    {
        "engDate": "1966-08-26",
        "nepDate": "10.05.2023"
    },
    {
        "engDate": "1966-08-27",
        "nepDate": "11.05.2023"
    },
    {
        "engDate": "1966-08-28",
        "nepDate": "12.05.2023"
    },
    {
        "engDate": "1966-08-29",
        "nepDate": "13.05.2023"
    },
    {
        "engDate": "1966-08-30",
        "nepDate": "14.05.2023"
    },
    {
        "engDate": "1966-08-31",
        "nepDate": "15.05.2023"
    },
    {
        "engDate": "1966-09-01",
        "nepDate": "16.05.2023"
    },
    {
        "engDate": "1966-09-02",
        "nepDate": "17.05.2023"
    },
    {
        "engDate": "1966-09-03",
        "nepDate": "18.05.2023"
    },
    {
        "engDate": "1966-09-04",
        "nepDate": "19.05.2023"
    },
    {
        "engDate": "1966-09-05",
        "nepDate": "20.05.2023"
    },
    {
        "engDate": "1966-09-06",
        "nepDate": "21.05.2023"
    },
    {
        "engDate": "1966-09-07",
        "nepDate": "22.05.2023"
    },
    {
        "engDate": "1966-09-08",
        "nepDate": "23.05.2023"
    },
    {
        "engDate": "1966-09-09",
        "nepDate": "24.05.2023"
    },
    {
        "engDate": "1966-09-10",
        "nepDate": "25.05.2023"
    },
    {
        "engDate": "1966-09-11",
        "nepDate": "26.05.2023"
    },
    {
        "engDate": "1966-09-12",
        "nepDate": "27.05.2023"
    },
    {
        "engDate": "1966-09-13",
        "nepDate": "28.05.2023"
    },
    {
        "engDate": "1966-09-14",
        "nepDate": "29.05.2023"
    },
    {
        "engDate": "1966-09-15",
        "nepDate": "30.05.2023"
    },
    {
        "engDate": "1966-09-16",
        "nepDate": "31.05.2023"
    },
    {
        "engDate": "1966-09-17",
        "nepDate": "01.06.2023"
    },
    {
        "engDate": "1966-09-18",
        "nepDate": "02.06.2023"
    },
    {
        "engDate": "1966-09-19",
        "nepDate": "03.06.2023"
    },
    {
        "engDate": "1966-09-20",
        "nepDate": "04.06.2023"
    },
    {
        "engDate": "1966-09-21",
        "nepDate": "05.06.2023"
    },
    {
        "engDate": "1966-09-22",
        "nepDate": "06.06.2023"
    },
    {
        "engDate": "1966-09-23",
        "nepDate": "07.06.2023"
    },
    {
        "engDate": "1966-09-24",
        "nepDate": "08.06.2023"
    },
    {
        "engDate": "1966-09-25",
        "nepDate": "09.06.2023"
    },
    {
        "engDate": "1966-09-26",
        "nepDate": "10.06.2023"
    },
    {
        "engDate": "1966-09-27",
        "nepDate": "11.06.2023"
    },
    {
        "engDate": "1966-09-28",
        "nepDate": "12.06.2023"
    },
    {
        "engDate": "1966-09-29",
        "nepDate": "13.06.2023"
    },
    {
        "engDate": "1966-09-30",
        "nepDate": "14.06.2023"
    },
    {
        "engDate": "1966-10-01",
        "nepDate": "15.06.2023"
    },
    {
        "engDate": "1966-10-02",
        "nepDate": "16.06.2023"
    },
    {
        "engDate": "1966-10-03",
        "nepDate": "17.06.2023"
    },
    {
        "engDate": "1966-10-04",
        "nepDate": "18.06.2023"
    },
    {
        "engDate": "1966-10-05",
        "nepDate": "19.06.2023"
    },
    {
        "engDate": "1966-10-06",
        "nepDate": "20.06.2023"
    },
    {
        "engDate": "1966-10-07",
        "nepDate": "21.06.2023"
    },
    {
        "engDate": "1966-10-08",
        "nepDate": "22.06.2023"
    },
    {
        "engDate": "1966-10-09",
        "nepDate": "23.06.2023"
    },
    {
        "engDate": "1966-10-10",
        "nepDate": "24.06.2023"
    },
    {
        "engDate": "1966-10-11",
        "nepDate": "25.06.2023"
    },
    {
        "engDate": "1966-10-12",
        "nepDate": "26.06.2023"
    },
    {
        "engDate": "1966-10-13",
        "nepDate": "27.06.2023"
    },
    {
        "engDate": "1966-10-14",
        "nepDate": "28.06.2023"
    },
    {
        "engDate": "1966-10-15",
        "nepDate": "29.06.2023"
    },
    {
        "engDate": "1966-10-16",
        "nepDate": "30.06.2023"
    },
    {
        "engDate": "1966-10-17",
        "nepDate": "01.07.2023"
    },
    {
        "engDate": "1966-10-18",
        "nepDate": "02.07.2023"
    },
    {
        "engDate": "1966-10-19",
        "nepDate": "03.07.2023"
    },
    {
        "engDate": "1966-10-20",
        "nepDate": "04.07.2023"
    },
    {
        "engDate": "1966-10-21",
        "nepDate": "05.07.2023"
    },
    {
        "engDate": "1966-10-22",
        "nepDate": "06.07.2023"
    },
    {
        "engDate": "1966-10-23",
        "nepDate": "07.07.2023"
    },
    {
        "engDate": "1966-10-24",
        "nepDate": "08.07.2023"
    },
    {
        "engDate": "1966-10-25",
        "nepDate": "09.07.2023"
    },
    {
        "engDate": "1966-10-26",
        "nepDate": "10.07.2023"
    },
    {
        "engDate": "1966-10-27",
        "nepDate": "11.07.2023"
    },
    {
        "engDate": "1966-10-28",
        "nepDate": "12.07.2023"
    },
    {
        "engDate": "1966-10-29",
        "nepDate": "13.07.2023"
    },
    {
        "engDate": "1966-10-30",
        "nepDate": "14.07.2023"
    },
    {
        "engDate": "1966-10-31",
        "nepDate": "15.07.2023"
    },
    {
        "engDate": "1966-11-01",
        "nepDate": "16.07.2023"
    },
    {
        "engDate": "1966-11-02",
        "nepDate": "17.07.2023"
    },
    {
        "engDate": "1966-11-03",
        "nepDate": "18.07.2023"
    },
    {
        "engDate": "1966-11-04",
        "nepDate": "19.07.2023"
    },
    {
        "engDate": "1966-11-05",
        "nepDate": "20.07.2023"
    },
    {
        "engDate": "1966-11-06",
        "nepDate": "21.07.2023"
    },
    {
        "engDate": "1966-11-07",
        "nepDate": "22.07.2023"
    },
    {
        "engDate": "1966-11-08",
        "nepDate": "23.07.2023"
    },
    {
        "engDate": "1966-11-09",
        "nepDate": "24.07.2023"
    },
    {
        "engDate": "1966-11-10",
        "nepDate": "25.07.2023"
    },
    {
        "engDate": "1966-11-11",
        "nepDate": "26.07.2023"
    },
    {
        "engDate": "1966-11-12",
        "nepDate": "27.07.2023"
    },
    {
        "engDate": "1966-11-13",
        "nepDate": "28.07.2023"
    },
    {
        "engDate": "1966-11-14",
        "nepDate": "29.07.2023"
    },
    {
        "engDate": "1966-11-15",
        "nepDate": "30.07.2023"
    },
    {
        "engDate": "1966-11-16",
        "nepDate": "01.08.2023"
    },
    {
        "engDate": "1966-11-17",
        "nepDate": "02.08.2023"
    },
    {
        "engDate": "1966-11-18",
        "nepDate": "03.08.2023"
    },
    {
        "engDate": "1966-11-19",
        "nepDate": "04.08.2023"
    },
    {
        "engDate": "1966-11-20",
        "nepDate": "05.08.2023"
    },
    {
        "engDate": "1966-11-21",
        "nepDate": "06.08.2023"
    },
    {
        "engDate": "1966-11-22",
        "nepDate": "07.08.2023"
    },
    {
        "engDate": "1966-11-23",
        "nepDate": "08.08.2023"
    },
    {
        "engDate": "1966-11-24",
        "nepDate": "09.08.2023"
    },
    {
        "engDate": "1966-11-25",
        "nepDate": "10.08.2023"
    },
    {
        "engDate": "1966-11-26",
        "nepDate": "11.08.2023"
    },
    {
        "engDate": "1966-11-27",
        "nepDate": "12.08.2023"
    },
    {
        "engDate": "1966-11-28",
        "nepDate": "13.08.2023"
    },
    {
        "engDate": "1966-11-29",
        "nepDate": "14.08.2023"
    },
    {
        "engDate": "1966-11-30",
        "nepDate": "15.08.2023"
    },
    {
        "engDate": "1966-12-01",
        "nepDate": "16.08.2023"
    },
    {
        "engDate": "1966-12-02",
        "nepDate": "17.08.2023"
    },
    {
        "engDate": "1966-12-03",
        "nepDate": "18.08.2023"
    },
    {
        "engDate": "1966-12-04",
        "nepDate": "19.08.2023"
    },
    {
        "engDate": "1966-12-05",
        "nepDate": "20.08.2023"
    },
    {
        "engDate": "1966-12-06",
        "nepDate": "21.08.2023"
    },
    {
        "engDate": "1966-12-07",
        "nepDate": "22.08.2023"
    },
    {
        "engDate": "1966-12-08",
        "nepDate": "23.08.2023"
    },
    {
        "engDate": "1966-12-09",
        "nepDate": "24.08.2023"
    },
    {
        "engDate": "1966-12-10",
        "nepDate": "25.08.2023"
    },
    {
        "engDate": "1966-12-11",
        "nepDate": "26.08.2023"
    },
    {
        "engDate": "1966-12-12",
        "nepDate": "27.08.2023"
    },
    {
        "engDate": "1966-12-13",
        "nepDate": "28.08.2023"
    },
    {
        "engDate": "1966-12-14",
        "nepDate": "29.08.2023"
    },
    {
        "engDate": "1966-12-15",
        "nepDate": "30.08.2023"
    },
    {
        "engDate": "1966-12-16",
        "nepDate": "01.09.2023"
    },
    {
        "engDate": "1966-12-17",
        "nepDate": "02.09.2023"
    },
    {
        "engDate": "1966-12-18",
        "nepDate": "03.09.2023"
    },
    {
        "engDate": "1966-12-19",
        "nepDate": "04.09.2023"
    },
    {
        "engDate": "1966-12-20",
        "nepDate": "05.09.2023"
    },
    {
        "engDate": "1966-12-21",
        "nepDate": "06.09.2023"
    },
    {
        "engDate": "1966-12-22",
        "nepDate": "07.09.2023"
    },
    {
        "engDate": "1966-12-23",
        "nepDate": "08.09.2023"
    },
    {
        "engDate": "1966-12-24",
        "nepDate": "09.09.2023"
    },
    {
        "engDate": "1966-12-25",
        "nepDate": "10.09.2023"
    },
    {
        "engDate": "1966-12-26",
        "nepDate": "11.09.2023"
    },
    {
        "engDate": "1966-12-27",
        "nepDate": "12.09.2023"
    },
    {
        "engDate": "1966-12-28",
        "nepDate": "13.09.2023"
    },
    {
        "engDate": "1966-12-29",
        "nepDate": "14.09.2023"
    },
    {
        "engDate": "1966-12-30",
        "nepDate": "15.09.2023"
    },
    {
        "engDate": "1966-12-31",
        "nepDate": "16.09.2023"
    },
    {
        "engDate": "1967-01-01",
        "nepDate": "17.09.2023"
    },
    {
        "engDate": "1967-01-02",
        "nepDate": "18.09.2023"
    },
    {
        "engDate": "1967-01-03",
        "nepDate": "19.09.2023"
    },
    {
        "engDate": "1967-01-04",
        "nepDate": "20.09.2023"
    },
    {
        "engDate": "1967-01-05",
        "nepDate": "21.09.2023"
    },
    {
        "engDate": "1967-01-06",
        "nepDate": "22.09.2023"
    },
    {
        "engDate": "1967-01-07",
        "nepDate": "23.09.2023"
    },
    {
        "engDate": "1967-01-08",
        "nepDate": "24.09.2023"
    },
    {
        "engDate": "1967-01-09",
        "nepDate": "25.09.2023"
    },
    {
        "engDate": "1967-01-10",
        "nepDate": "26.09.2023"
    },
    {
        "engDate": "1967-01-11",
        "nepDate": "27.09.2023"
    },
    {
        "engDate": "1967-01-12",
        "nepDate": "28.09.2023"
    },
    {
        "engDate": "1967-01-13",
        "nepDate": "29.09.2023"
    },
    {
        "engDate": "1967-01-14",
        "nepDate": "01.10.2023"
    },
    {
        "engDate": "1967-01-15",
        "nepDate": "02.10.2023"
    },
    {
        "engDate": "1967-01-16",
        "nepDate": "03.10.2023"
    },
    {
        "engDate": "1967-01-17",
        "nepDate": "04.10.2023"
    },
    {
        "engDate": "1967-01-18",
        "nepDate": "05.10.2023"
    },
    {
        "engDate": "1967-01-19",
        "nepDate": "06.10.2023"
    },
    {
        "engDate": "1967-01-20",
        "nepDate": "07.10.2023"
    },
    {
        "engDate": "1967-01-21",
        "nepDate": "08.10.2023"
    },
    {
        "engDate": "1967-01-22",
        "nepDate": "09.10.2023"
    },
    {
        "engDate": "1967-01-23",
        "nepDate": "10.10.2023"
    },
    {
        "engDate": "1967-01-24",
        "nepDate": "11.10.2023"
    },
    {
        "engDate": "1967-01-25",
        "nepDate": "12.10.2023"
    },
    {
        "engDate": "1967-01-26",
        "nepDate": "13.10.2023"
    },
    {
        "engDate": "1967-01-27",
        "nepDate": "14.10.2023"
    },
    {
        "engDate": "1967-01-28",
        "nepDate": "15.10.2023"
    },
    {
        "engDate": "1967-01-29",
        "nepDate": "16.10.2023"
    },
    {
        "engDate": "1967-01-30",
        "nepDate": "17.10.2023"
    },
    {
        "engDate": "1967-01-31",
        "nepDate": "18.10.2023"
    },
    {
        "engDate": "1967-02-01",
        "nepDate": "19.10.2023"
    },
    {
        "engDate": "1967-02-02",
        "nepDate": "20.10.2023"
    },
    {
        "engDate": "1967-02-03",
        "nepDate": "21.10.2023"
    },
    {
        "engDate": "1967-02-04",
        "nepDate": "22.10.2023"
    },
    {
        "engDate": "1967-02-05",
        "nepDate": "23.10.2023"
    },
    {
        "engDate": "1967-02-06",
        "nepDate": "24.10.2023"
    },
    {
        "engDate": "1967-02-07",
        "nepDate": "25.10.2023"
    },
    {
        "engDate": "1967-02-08",
        "nepDate": "26.10.2023"
    },
    {
        "engDate": "1967-02-09",
        "nepDate": "27.10.2023"
    },
    {
        "engDate": "1967-02-10",
        "nepDate": "28.10.2023"
    },
    {
        "engDate": "1967-02-11",
        "nepDate": "29.10.2023"
    },
    {
        "engDate": "1967-02-12",
        "nepDate": "30.10.2023"
    },
    {
        "engDate": "1967-02-13",
        "nepDate": "01.11.2023"
    },
    {
        "engDate": "1967-02-14",
        "nepDate": "02.11.2023"
    },
    {
        "engDate": "1967-02-15",
        "nepDate": "03.11.2023"
    },
    {
        "engDate": "1967-02-16",
        "nepDate": "04.11.2023"
    },
    {
        "engDate": "1967-02-17",
        "nepDate": "05.11.2023"
    },
    {
        "engDate": "1967-02-18",
        "nepDate": "06.11.2023"
    },
    {
        "engDate": "1967-02-19",
        "nepDate": "07.11.2023"
    },
    {
        "engDate": "1967-02-20",
        "nepDate": "08.11.2023"
    },
    {
        "engDate": "1967-02-21",
        "nepDate": "09.11.2023"
    },
    {
        "engDate": "1967-02-22",
        "nepDate": "10.11.2023"
    },
    {
        "engDate": "1967-02-23",
        "nepDate": "11.11.2023"
    },
    {
        "engDate": "1967-02-24",
        "nepDate": "12.11.2023"
    },
    {
        "engDate": "1967-02-25",
        "nepDate": "13.11.2023"
    },
    {
        "engDate": "1967-02-26",
        "nepDate": "14.11.2023"
    },
    {
        "engDate": "1967-02-27",
        "nepDate": "15.11.2023"
    },
    {
        "engDate": "1967-02-28",
        "nepDate": "16.11.2023"
    },
    {
        "engDate": "1967-03-01",
        "nepDate": "17.11.2023"
    },
    {
        "engDate": "1967-03-02",
        "nepDate": "18.11.2023"
    },
    {
        "engDate": "1967-03-03",
        "nepDate": "19.11.2023"
    },
    {
        "engDate": "1967-03-04",
        "nepDate": "20.11.2023"
    },
    {
        "engDate": "1967-03-05",
        "nepDate": "21.11.2023"
    },
    {
        "engDate": "1967-03-06",
        "nepDate": "22.11.2023"
    },
    {
        "engDate": "1967-03-07",
        "nepDate": "23.11.2023"
    },
    {
        "engDate": "1967-03-08",
        "nepDate": "24.11.2023"
    },
    {
        "engDate": "1967-03-09",
        "nepDate": "25.11.2023"
    },
    {
        "engDate": "1967-03-10",
        "nepDate": "26.11.2023"
    },
    {
        "engDate": "1967-03-11",
        "nepDate": "27.11.2023"
    },
    {
        "engDate": "1967-03-12",
        "nepDate": "28.11.2023"
    },
    {
        "engDate": "1967-03-13",
        "nepDate": "29.11.2023"
    },
    {
        "engDate": "1967-03-14",
        "nepDate": "01.12.2023"
    },
    {
        "engDate": "1967-03-15",
        "nepDate": "02.12.2023"
    },
    {
        "engDate": "1967-03-16",
        "nepDate": "03.12.2023"
    },
    {
        "engDate": "1967-03-17",
        "nepDate": "04.12.2023"
    },
    {
        "engDate": "1967-03-18",
        "nepDate": "05.12.2023"
    },
    {
        "engDate": "1967-03-19",
        "nepDate": "06.12.2023"
    },
    {
        "engDate": "1967-03-20",
        "nepDate": "07.12.2023"
    },
    {
        "engDate": "1967-03-21",
        "nepDate": "08.12.2023"
    },
    {
        "engDate": "1967-03-22",
        "nepDate": "09.12.2023"
    },
    {
        "engDate": "1967-03-23",
        "nepDate": "10.12.2023"
    },
    {
        "engDate": "1967-03-24",
        "nepDate": "11.12.2023"
    },
    {
        "engDate": "1967-03-25",
        "nepDate": "12.12.2023"
    },
    {
        "engDate": "1967-03-26",
        "nepDate": "13.12.2023"
    },
    {
        "engDate": "1967-03-27",
        "nepDate": "14.12.2023"
    },
    {
        "engDate": "1967-03-28",
        "nepDate": "15.12.2023"
    },
    {
        "engDate": "1967-03-29",
        "nepDate": "16.12.2023"
    },
    {
        "engDate": "1967-03-30",
        "nepDate": "17.12.2023"
    },
    {
        "engDate": "1967-03-31",
        "nepDate": "18.12.2023"
    },
    {
        "engDate": "1967-04-01",
        "nepDate": "19.12.2023"
    },
    {
        "engDate": "1967-04-02",
        "nepDate": "20.12.2023"
    },
    {
        "engDate": "1967-04-03",
        "nepDate": "21.12.2023"
    },
    {
        "engDate": "1967-04-04",
        "nepDate": "22.12.2023"
    },
    {
        "engDate": "1967-04-05",
        "nepDate": "23.12.2023"
    },
    {
        "engDate": "1967-04-06",
        "nepDate": "24.12.2023"
    },
    {
        "engDate": "1967-04-07",
        "nepDate": "25.12.2023"
    },
    {
        "engDate": "1967-04-08",
        "nepDate": "26.12.2023"
    },
    {
        "engDate": "1967-04-09",
        "nepDate": "27.12.2023"
    },
    {
        "engDate": "1967-04-10",
        "nepDate": "28.12.2023"
    },
    {
        "engDate": "1967-04-11",
        "nepDate": "29.12.2023"
    },
    {
        "engDate": "1967-04-12",
        "nepDate": "30.12.2023"
    },
    {
        "engDate": "1967-04-13",
        "nepDate": "31.12.2023"
    },
    {
        "engDate": "1967-04-14",
        "nepDate": "01.01.2024"
    },
    {
        "engDate": "1967-04-15",
        "nepDate": "02.01.2024"
    },
    {
        "engDate": "1967-04-16",
        "nepDate": "03.01.2024"
    },
    {
        "engDate": "1967-04-17",
        "nepDate": "04.01.2024"
    },
    {
        "engDate": "1967-04-18",
        "nepDate": "05.01.2024"
    },
    {
        "engDate": "1967-04-19",
        "nepDate": "06.01.2024"
    },
    {
        "engDate": "1967-04-20",
        "nepDate": "07.01.2024"
    },
    {
        "engDate": "1967-04-21",
        "nepDate": "08.01.2024"
    },
    {
        "engDate": "1967-04-22",
        "nepDate": "09.01.2024"
    },
    {
        "engDate": "1967-04-23",
        "nepDate": "10.01.2024"
    },
    {
        "engDate": "1967-04-24",
        "nepDate": "11.01.2024"
    },
    {
        "engDate": "1967-04-25",
        "nepDate": "12.01.2024"
    },
    {
        "engDate": "1967-04-26",
        "nepDate": "13.01.2024"
    },
    {
        "engDate": "1967-04-27",
        "nepDate": "14.01.2024"
    },
    {
        "engDate": "1967-04-28",
        "nepDate": "15.01.2024"
    },
    {
        "engDate": "1967-04-29",
        "nepDate": "16.01.2024"
    },
    {
        "engDate": "1967-04-30",
        "nepDate": "17.01.2024"
    },
    {
        "engDate": "1967-05-01",
        "nepDate": "18.01.2024"
    },
    {
        "engDate": "1967-05-02",
        "nepDate": "19.01.2024"
    },
    {
        "engDate": "1967-05-03",
        "nepDate": "20.01.2024"
    },
    {
        "engDate": "1967-05-04",
        "nepDate": "21.01.2024"
    },
    {
        "engDate": "1967-05-05",
        "nepDate": "22.01.2024"
    },
    {
        "engDate": "1967-05-06",
        "nepDate": "23.01.2024"
    },
    {
        "engDate": "1967-05-07",
        "nepDate": "24.01.2024"
    },
    {
        "engDate": "1967-05-08",
        "nepDate": "25.01.2024"
    },
    {
        "engDate": "1967-05-09",
        "nepDate": "26.01.2024"
    },
    {
        "engDate": "1967-05-10",
        "nepDate": "27.01.2024"
    },
    {
        "engDate": "1967-05-11",
        "nepDate": "28.01.2024"
    },
    {
        "engDate": "1967-05-12",
        "nepDate": "29.01.2024"
    },
    {
        "engDate": "1967-05-13",
        "nepDate": "30.01.2024"
    },
    {
        "engDate": "1967-05-14",
        "nepDate": "31.01.2024"
    },
    {
        "engDate": "1967-05-15",
        "nepDate": "01.02.2024"
    },
    {
        "engDate": "1967-05-16",
        "nepDate": "02.02.2024"
    },
    {
        "engDate": "1967-05-17",
        "nepDate": "03.02.2024"
    },
    {
        "engDate": "1967-05-18",
        "nepDate": "04.02.2024"
    },
    {
        "engDate": "1967-05-19",
        "nepDate": "05.02.2024"
    },
    {
        "engDate": "1967-05-20",
        "nepDate": "06.02.2024"
    },
    {
        "engDate": "1967-05-21",
        "nepDate": "07.02.2024"
    },
    {
        "engDate": "1967-05-22",
        "nepDate": "08.02.2024"
    },
    {
        "engDate": "1967-05-23",
        "nepDate": "09.02.2024"
    },
    {
        "engDate": "1967-05-24",
        "nepDate": "10.02.2024"
    },
    {
        "engDate": "1967-05-25",
        "nepDate": "11.02.2024"
    },
    {
        "engDate": "1967-05-26",
        "nepDate": "12.02.2024"
    },
    {
        "engDate": "1967-05-27",
        "nepDate": "13.02.2024"
    },
    {
        "engDate": "1967-05-28",
        "nepDate": "14.02.2024"
    },
    {
        "engDate": "1967-05-29",
        "nepDate": "15.02.2024"
    },
    {
        "engDate": "1967-05-30",
        "nepDate": "16.02.2024"
    },
    {
        "engDate": "1967-05-31",
        "nepDate": "17.02.2024"
    },
    {
        "engDate": "1967-06-01",
        "nepDate": "18.02.2024"
    },
    {
        "engDate": "1967-06-02",
        "nepDate": "19.02.2024"
    },
    {
        "engDate": "1967-06-03",
        "nepDate": "20.02.2024"
    },
    {
        "engDate": "1967-06-04",
        "nepDate": "21.02.2024"
    },
    {
        "engDate": "1967-06-05",
        "nepDate": "22.02.2024"
    },
    {
        "engDate": "1967-06-06",
        "nepDate": "23.02.2024"
    },
    {
        "engDate": "1967-06-07",
        "nepDate": "24.02.2024"
    },
    {
        "engDate": "1967-06-08",
        "nepDate": "25.02.2024"
    },
    {
        "engDate": "1967-06-09",
        "nepDate": "26.02.2024"
    },
    {
        "engDate": "1967-06-10",
        "nepDate": "27.02.2024"
    },
    {
        "engDate": "1967-06-11",
        "nepDate": "28.02.2024"
    },
    {
        "engDate": "1967-06-12",
        "nepDate": "29.02.2024"
    },
    {
        "engDate": "1967-06-13",
        "nepDate": "30.02.2024"
    },
    {
        "engDate": "1967-06-14",
        "nepDate": "31.02.2024"
    },
    {
        "engDate": "1967-06-15",
        "nepDate": "01.03.2024"
    },
    {
        "engDate": "1967-06-16",
        "nepDate": "02.03.2024"
    },
    {
        "engDate": "1967-06-17",
        "nepDate": "03.03.2024"
    },
    {
        "engDate": "1967-06-18",
        "nepDate": "04.03.2024"
    },
    {
        "engDate": "1967-06-19",
        "nepDate": "05.03.2024"
    },
    {
        "engDate": "1967-06-20",
        "nepDate": "06.03.2024"
    },
    {
        "engDate": "1967-06-21",
        "nepDate": "07.03.2024"
    },
    {
        "engDate": "1967-06-22",
        "nepDate": "08.03.2024"
    },
    {
        "engDate": "1967-06-23",
        "nepDate": "09.03.2024"
    },
    {
        "engDate": "1967-06-24",
        "nepDate": "10.03.2024"
    },
    {
        "engDate": "1967-06-25",
        "nepDate": "11.03.2024"
    },
    {
        "engDate": "1967-06-26",
        "nepDate": "12.03.2024"
    },
    {
        "engDate": "1967-06-27",
        "nepDate": "13.03.2024"
    },
    {
        "engDate": "1967-06-28",
        "nepDate": "14.03.2024"
    },
    {
        "engDate": "1967-06-29",
        "nepDate": "15.03.2024"
    },
    {
        "engDate": "1967-06-30",
        "nepDate": "16.03.2024"
    },
    {
        "engDate": "1967-07-01",
        "nepDate": "17.03.2024"
    },
    {
        "engDate": "1967-07-02",
        "nepDate": "18.03.2024"
    },
    {
        "engDate": "1967-07-03",
        "nepDate": "19.03.2024"
    },
    {
        "engDate": "1967-07-04",
        "nepDate": "20.03.2024"
    },
    {
        "engDate": "1967-07-05",
        "nepDate": "21.03.2024"
    },
    {
        "engDate": "1967-07-06",
        "nepDate": "22.03.2024"
    },
    {
        "engDate": "1967-07-07",
        "nepDate": "23.03.2024"
    },
    {
        "engDate": "1967-07-08",
        "nepDate": "24.03.2024"
    },
    {
        "engDate": "1967-07-09",
        "nepDate": "25.03.2024"
    },
    {
        "engDate": "1967-07-10",
        "nepDate": "26.03.2024"
    },
    {
        "engDate": "1967-07-11",
        "nepDate": "27.03.2024"
    },
    {
        "engDate": "1967-07-12",
        "nepDate": "28.03.2024"
    },
    {
        "engDate": "1967-07-13",
        "nepDate": "29.03.2024"
    },
    {
        "engDate": "1967-07-14",
        "nepDate": "30.03.2024"
    },
    {
        "engDate": "1967-07-15",
        "nepDate": "31.03.2024"
    },
    {
        "engDate": "1967-07-16",
        "nepDate": "01.04.2024"
    },
    {
        "engDate": "1967-07-17",
        "nepDate": "02.04.2024"
    },
    {
        "engDate": "1967-07-18",
        "nepDate": "03.04.2024"
    },
    {
        "engDate": "1967-07-19",
        "nepDate": "04.04.2024"
    },
    {
        "engDate": "1967-07-20",
        "nepDate": "05.04.2024"
    },
    {
        "engDate": "1967-07-21",
        "nepDate": "06.04.2024"
    },
    {
        "engDate": "1967-07-22",
        "nepDate": "07.04.2024"
    },
    {
        "engDate": "1967-07-23",
        "nepDate": "08.04.2024"
    },
    {
        "engDate": "1967-07-24",
        "nepDate": "09.04.2024"
    },
    {
        "engDate": "1967-07-25",
        "nepDate": "10.04.2024"
    },
    {
        "engDate": "1967-07-26",
        "nepDate": "11.04.2024"
    },
    {
        "engDate": "1967-07-27",
        "nepDate": "12.04.2024"
    },
    {
        "engDate": "1967-07-28",
        "nepDate": "13.04.2024"
    },
    {
        "engDate": "1967-07-29",
        "nepDate": "14.04.2024"
    },
    {
        "engDate": "1967-07-30",
        "nepDate": "15.04.2024"
    },
    {
        "engDate": "1967-07-31",
        "nepDate": "16.04.2024"
    },
    {
        "engDate": "1967-08-01",
        "nepDate": "17.04.2024"
    },
    {
        "engDate": "1967-08-02",
        "nepDate": "18.04.2024"
    },
    {
        "engDate": "1967-08-03",
        "nepDate": "19.04.2024"
    },
    {
        "engDate": "1967-08-04",
        "nepDate": "20.04.2024"
    },
    {
        "engDate": "1967-08-05",
        "nepDate": "21.04.2024"
    },
    {
        "engDate": "1967-08-06",
        "nepDate": "22.04.2024"
    },
    {
        "engDate": "1967-08-07",
        "nepDate": "23.04.2024"
    },
    {
        "engDate": "1967-08-08",
        "nepDate": "24.04.2024"
    },
    {
        "engDate": "1967-08-09",
        "nepDate": "25.04.2024"
    },
    {
        "engDate": "1967-08-10",
        "nepDate": "26.04.2024"
    },
    {
        "engDate": "1967-08-11",
        "nepDate": "27.04.2024"
    },
    {
        "engDate": "1967-08-12",
        "nepDate": "28.04.2024"
    },
    {
        "engDate": "1967-08-13",
        "nepDate": "29.04.2024"
    },
    {
        "engDate": "1967-08-14",
        "nepDate": "30.04.2024"
    },
    {
        "engDate": "1967-08-15",
        "nepDate": "31.04.2024"
    },
    {
        "engDate": "1967-08-16",
        "nepDate": "32.04.2024"
    },
    {
        "engDate": "1967-08-17",
        "nepDate": "01.05.2024"
    },
    {
        "engDate": "1967-08-18",
        "nepDate": "02.05.2024"
    },
    {
        "engDate": "1967-08-19",
        "nepDate": "03.05.2024"
    },
    {
        "engDate": "1967-08-20",
        "nepDate": "04.05.2024"
    },
    {
        "engDate": "1967-08-21",
        "nepDate": "05.05.2024"
    },
    {
        "engDate": "1967-08-22",
        "nepDate": "06.05.2024"
    },
    {
        "engDate": "1967-08-23",
        "nepDate": "07.05.2024"
    },
    {
        "engDate": "1967-08-24",
        "nepDate": "08.05.2024"
    },
    {
        "engDate": "1967-08-25",
        "nepDate": "09.05.2024"
    },
    {
        "engDate": "1967-08-26",
        "nepDate": "10.05.2024"
    },
    {
        "engDate": "1967-08-27",
        "nepDate": "11.05.2024"
    },
    {
        "engDate": "1967-08-28",
        "nepDate": "12.05.2024"
    },
    {
        "engDate": "1967-08-29",
        "nepDate": "13.05.2024"
    },
    {
        "engDate": "1967-08-30",
        "nepDate": "14.05.2024"
    },
    {
        "engDate": "1967-08-31",
        "nepDate": "15.05.2024"
    },
    {
        "engDate": "1967-09-01",
        "nepDate": "16.05.2024"
    },
    {
        "engDate": "1967-09-02",
        "nepDate": "17.05.2024"
    },
    {
        "engDate": "1967-09-03",
        "nepDate": "18.05.2024"
    },
    {
        "engDate": "1967-09-04",
        "nepDate": "19.05.2024"
    },
    {
        "engDate": "1967-09-05",
        "nepDate": "20.05.2024"
    },
    {
        "engDate": "1967-09-06",
        "nepDate": "21.05.2024"
    },
    {
        "engDate": "1967-09-07",
        "nepDate": "22.05.2024"
    },
    {
        "engDate": "1967-09-08",
        "nepDate": "23.05.2024"
    },
    {
        "engDate": "1967-09-09",
        "nepDate": "24.05.2024"
    },
    {
        "engDate": "1967-09-10",
        "nepDate": "25.05.2024"
    },
    {
        "engDate": "1967-09-11",
        "nepDate": "26.05.2024"
    },
    {
        "engDate": "1967-09-12",
        "nepDate": "27.05.2024"
    },
    {
        "engDate": "1967-09-13",
        "nepDate": "28.05.2024"
    },
    {
        "engDate": "1967-09-14",
        "nepDate": "29.05.2024"
    },
    {
        "engDate": "1967-09-15",
        "nepDate": "30.05.2024"
    },
    {
        "engDate": "1967-09-16",
        "nepDate": "31.05.2024"
    },
    {
        "engDate": "1967-09-17",
        "nepDate": "01.06.2024"
    },
    {
        "engDate": "1967-09-18",
        "nepDate": "02.06.2024"
    },
    {
        "engDate": "1967-09-19",
        "nepDate": "03.06.2024"
    },
    {
        "engDate": "1967-09-20",
        "nepDate": "04.06.2024"
    },
    {
        "engDate": "1967-09-21",
        "nepDate": "05.06.2024"
    },
    {
        "engDate": "1967-09-22",
        "nepDate": "06.06.2024"
    },
    {
        "engDate": "1967-09-23",
        "nepDate": "07.06.2024"
    },
    {
        "engDate": "1967-09-24",
        "nepDate": "08.06.2024"
    },
    {
        "engDate": "1967-09-25",
        "nepDate": "09.06.2024"
    },
    {
        "engDate": "1967-09-26",
        "nepDate": "10.06.2024"
    },
    {
        "engDate": "1967-09-27",
        "nepDate": "11.06.2024"
    },
    {
        "engDate": "1967-09-28",
        "nepDate": "12.06.2024"
    },
    {
        "engDate": "1967-09-29",
        "nepDate": "13.06.2024"
    },
    {
        "engDate": "1967-09-30",
        "nepDate": "14.06.2024"
    },
    {
        "engDate": "1967-10-01",
        "nepDate": "15.06.2024"
    },
    {
        "engDate": "1967-10-02",
        "nepDate": "16.06.2024"
    },
    {
        "engDate": "1967-10-03",
        "nepDate": "17.06.2024"
    },
    {
        "engDate": "1967-10-04",
        "nepDate": "18.06.2024"
    },
    {
        "engDate": "1967-10-05",
        "nepDate": "19.06.2024"
    },
    {
        "engDate": "1967-10-06",
        "nepDate": "20.06.2024"
    },
    {
        "engDate": "1967-10-07",
        "nepDate": "21.06.2024"
    },
    {
        "engDate": "1967-10-08",
        "nepDate": "22.06.2024"
    },
    {
        "engDate": "1967-10-09",
        "nepDate": "23.06.2024"
    },
    {
        "engDate": "1967-10-10",
        "nepDate": "24.06.2024"
    },
    {
        "engDate": "1967-10-11",
        "nepDate": "25.06.2024"
    },
    {
        "engDate": "1967-10-12",
        "nepDate": "26.06.2024"
    },
    {
        "engDate": "1967-10-13",
        "nepDate": "27.06.2024"
    },
    {
        "engDate": "1967-10-14",
        "nepDate": "28.06.2024"
    },
    {
        "engDate": "1967-10-15",
        "nepDate": "29.06.2024"
    },
    {
        "engDate": "1967-10-16",
        "nepDate": "30.06.2024"
    },
    {
        "engDate": "1967-10-17",
        "nepDate": "31.06.2024"
    },
    {
        "engDate": "1967-10-18",
        "nepDate": "01.07.2024"
    },
    {
        "engDate": "1967-10-19",
        "nepDate": "02.07.2024"
    },
    {
        "engDate": "1967-10-20",
        "nepDate": "03.07.2024"
    },
    {
        "engDate": "1967-10-21",
        "nepDate": "04.07.2024"
    },
    {
        "engDate": "1967-10-22",
        "nepDate": "05.07.2024"
    },
    {
        "engDate": "1967-10-23",
        "nepDate": "06.07.2024"
    },
    {
        "engDate": "1967-10-24",
        "nepDate": "07.07.2024"
    },
    {
        "engDate": "1967-10-25",
        "nepDate": "08.07.2024"
    },
    {
        "engDate": "1967-10-26",
        "nepDate": "09.07.2024"
    },
    {
        "engDate": "1967-10-27",
        "nepDate": "10.07.2024"
    },
    {
        "engDate": "1967-10-28",
        "nepDate": "11.07.2024"
    },
    {
        "engDate": "1967-10-29",
        "nepDate": "12.07.2024"
    },
    {
        "engDate": "1967-10-30",
        "nepDate": "13.07.2024"
    },
    {
        "engDate": "1967-10-31",
        "nepDate": "14.07.2024"
    },
    {
        "engDate": "1967-11-01",
        "nepDate": "15.07.2024"
    },
    {
        "engDate": "1967-11-02",
        "nepDate": "16.07.2024"
    },
    {
        "engDate": "1967-11-03",
        "nepDate": "17.07.2024"
    },
    {
        "engDate": "1967-11-04",
        "nepDate": "18.07.2024"
    },
    {
        "engDate": "1967-11-05",
        "nepDate": "19.07.2024"
    },
    {
        "engDate": "1967-11-06",
        "nepDate": "20.07.2024"
    },
    {
        "engDate": "1967-11-07",
        "nepDate": "21.07.2024"
    },
    {
        "engDate": "1967-11-08",
        "nepDate": "22.07.2024"
    },
    {
        "engDate": "1967-11-09",
        "nepDate": "23.07.2024"
    },
    {
        "engDate": "1967-11-10",
        "nepDate": "24.07.2024"
    },
    {
        "engDate": "1967-11-11",
        "nepDate": "25.07.2024"
    },
    {
        "engDate": "1967-11-12",
        "nepDate": "26.07.2024"
    },
    {
        "engDate": "1967-11-13",
        "nepDate": "27.07.2024"
    },
    {
        "engDate": "1967-11-14",
        "nepDate": "28.07.2024"
    },
    {
        "engDate": "1967-11-15",
        "nepDate": "29.07.2024"
    },
    {
        "engDate": "1967-11-16",
        "nepDate": "30.07.2024"
    },
    {
        "engDate": "1967-11-17",
        "nepDate": "01.08.2024"
    },
    {
        "engDate": "1967-11-18",
        "nepDate": "02.08.2024"
    },
    {
        "engDate": "1967-11-19",
        "nepDate": "03.08.2024"
    },
    {
        "engDate": "1967-11-20",
        "nepDate": "04.08.2024"
    },
    {
        "engDate": "1967-11-21",
        "nepDate": "05.08.2024"
    },
    {
        "engDate": "1967-11-22",
        "nepDate": "06.08.2024"
    },
    {
        "engDate": "1967-11-23",
        "nepDate": "07.08.2024"
    },
    {
        "engDate": "1967-11-24",
        "nepDate": "08.08.2024"
    },
    {
        "engDate": "1967-11-25",
        "nepDate": "09.08.2024"
    },
    {
        "engDate": "1967-11-26",
        "nepDate": "10.08.2024"
    },
    {
        "engDate": "1967-11-27",
        "nepDate": "11.08.2024"
    },
    {
        "engDate": "1967-11-28",
        "nepDate": "12.08.2024"
    },
    {
        "engDate": "1967-11-29",
        "nepDate": "13.08.2024"
    },
    {
        "engDate": "1967-11-30",
        "nepDate": "14.08.2024"
    },
    {
        "engDate": "1967-12-01",
        "nepDate": "15.08.2024"
    },
    {
        "engDate": "1967-12-02",
        "nepDate": "16.08.2024"
    },
    {
        "engDate": "1967-12-03",
        "nepDate": "17.08.2024"
    },
    {
        "engDate": "1967-12-04",
        "nepDate": "18.08.2024"
    },
    {
        "engDate": "1967-12-05",
        "nepDate": "19.08.2024"
    },
    {
        "engDate": "1967-12-06",
        "nepDate": "20.08.2024"
    },
    {
        "engDate": "1967-12-07",
        "nepDate": "21.08.2024"
    },
    {
        "engDate": "1967-12-08",
        "nepDate": "22.08.2024"
    },
    {
        "engDate": "1967-12-09",
        "nepDate": "23.08.2024"
    },
    {
        "engDate": "1967-12-10",
        "nepDate": "24.08.2024"
    },
    {
        "engDate": "1967-12-11",
        "nepDate": "25.08.2024"
    },
    {
        "engDate": "1967-12-12",
        "nepDate": "26.08.2024"
    },
    {
        "engDate": "1967-12-13",
        "nepDate": "27.08.2024"
    },
    {
        "engDate": "1967-12-14",
        "nepDate": "28.08.2024"
    },
    {
        "engDate": "1967-12-15",
        "nepDate": "29.08.2024"
    },
    {
        "engDate": "1967-12-16",
        "nepDate": "01.09.2024"
    },
    {
        "engDate": "1967-12-17",
        "nepDate": "02.09.2024"
    },
    {
        "engDate": "1967-12-18",
        "nepDate": "03.09.2024"
    },
    {
        "engDate": "1967-12-19",
        "nepDate": "04.09.2024"
    },
    {
        "engDate": "1967-12-20",
        "nepDate": "05.09.2024"
    },
    {
        "engDate": "1967-12-21",
        "nepDate": "06.09.2024"
    },
    {
        "engDate": "1967-12-22",
        "nepDate": "07.09.2024"
    },
    {
        "engDate": "1967-12-23",
        "nepDate": "08.09.2024"
    },
    {
        "engDate": "1967-12-24",
        "nepDate": "09.09.2024"
    },
    {
        "engDate": "1967-12-25",
        "nepDate": "10.09.2024"
    },
    {
        "engDate": "1967-12-26",
        "nepDate": "11.09.2024"
    },
    {
        "engDate": "1967-12-27",
        "nepDate": "12.09.2024"
    },
    {
        "engDate": "1967-12-28",
        "nepDate": "13.09.2024"
    },
    {
        "engDate": "1967-12-29",
        "nepDate": "14.09.2024"
    },
    {
        "engDate": "1967-12-30",
        "nepDate": "15.09.2024"
    },
    {
        "engDate": "1967-12-31",
        "nepDate": "16.09.2024"
    },
    {
        "engDate": "1968-01-01",
        "nepDate": "17.09.2024"
    },
    {
        "engDate": "1968-01-02",
        "nepDate": "18.09.2024"
    },
    {
        "engDate": "1968-01-03",
        "nepDate": "19.09.2024"
    },
    {
        "engDate": "1968-01-04",
        "nepDate": "20.09.2024"
    },
    {
        "engDate": "1968-01-05",
        "nepDate": "21.09.2024"
    },
    {
        "engDate": "1968-01-06",
        "nepDate": "22.09.2024"
    },
    {
        "engDate": "1968-01-07",
        "nepDate": "23.09.2024"
    },
    {
        "engDate": "1968-01-08",
        "nepDate": "24.09.2024"
    },
    {
        "engDate": "1968-01-09",
        "nepDate": "25.09.2024"
    },
    {
        "engDate": "1968-01-10",
        "nepDate": "26.09.2024"
    },
    {
        "engDate": "1968-01-11",
        "nepDate": "27.09.2024"
    },
    {
        "engDate": "1968-01-12",
        "nepDate": "28.09.2024"
    },
    {
        "engDate": "1968-01-13",
        "nepDate": "29.09.2024"
    },
    {
        "engDate": "1968-01-14",
        "nepDate": "30.09.2024"
    },
    {
        "engDate": "1968-01-15",
        "nepDate": "01.10.2024"
    },
    {
        "engDate": "1968-01-16",
        "nepDate": "02.10.2024"
    },
    {
        "engDate": "1968-01-17",
        "nepDate": "03.10.2024"
    },
    {
        "engDate": "1968-01-18",
        "nepDate": "04.10.2024"
    },
    {
        "engDate": "1968-01-19",
        "nepDate": "05.10.2024"
    },
    {
        "engDate": "1968-01-20",
        "nepDate": "06.10.2024"
    },
    {
        "engDate": "1968-01-21",
        "nepDate": "07.10.2024"
    },
    {
        "engDate": "1968-01-22",
        "nepDate": "08.10.2024"
    },
    {
        "engDate": "1968-01-23",
        "nepDate": "09.10.2024"
    },
    {
        "engDate": "1968-01-24",
        "nepDate": "10.10.2024"
    },
    {
        "engDate": "1968-01-25",
        "nepDate": "11.10.2024"
    },
    {
        "engDate": "1968-01-26",
        "nepDate": "12.10.2024"
    },
    {
        "engDate": "1968-01-27",
        "nepDate": "13.10.2024"
    },
    {
        "engDate": "1968-01-28",
        "nepDate": "14.10.2024"
    },
    {
        "engDate": "1968-01-29",
        "nepDate": "15.10.2024"
    },
    {
        "engDate": "1968-01-30",
        "nepDate": "16.10.2024"
    },
    {
        "engDate": "1968-01-31",
        "nepDate": "17.10.2024"
    },
    {
        "engDate": "1968-02-01",
        "nepDate": "18.10.2024"
    },
    {
        "engDate": "1968-02-02",
        "nepDate": "19.10.2024"
    },
    {
        "engDate": "1968-02-03",
        "nepDate": "20.10.2024"
    },
    {
        "engDate": "1968-02-04",
        "nepDate": "21.10.2024"
    },
    {
        "engDate": "1968-02-05",
        "nepDate": "22.10.2024"
    },
    {
        "engDate": "1968-02-06",
        "nepDate": "23.10.2024"
    },
    {
        "engDate": "1968-02-07",
        "nepDate": "24.10.2024"
    },
    {
        "engDate": "1968-02-08",
        "nepDate": "25.10.2024"
    },
    {
        "engDate": "1968-02-09",
        "nepDate": "26.10.2024"
    },
    {
        "engDate": "1968-02-10",
        "nepDate": "27.10.2024"
    },
    {
        "engDate": "1968-02-11",
        "nepDate": "28.10.2024"
    },
    {
        "engDate": "1968-02-12",
        "nepDate": "29.10.2024"
    },
    {
        "engDate": "1968-02-13",
        "nepDate": "01.11.2024"
    },
    {
        "engDate": "1968-02-14",
        "nepDate": "02.11.2024"
    },
    {
        "engDate": "1968-02-15",
        "nepDate": "03.11.2024"
    },
    {
        "engDate": "1968-02-16",
        "nepDate": "04.11.2024"
    },
    {
        "engDate": "1968-02-17",
        "nepDate": "05.11.2024"
    },
    {
        "engDate": "1968-02-18",
        "nepDate": "06.11.2024"
    },
    {
        "engDate": "1968-02-19",
        "nepDate": "07.11.2024"
    },
    {
        "engDate": "1968-02-20",
        "nepDate": "08.11.2024"
    },
    {
        "engDate": "1968-02-21",
        "nepDate": "09.11.2024"
    },
    {
        "engDate": "1968-02-22",
        "nepDate": "10.11.2024"
    },
    {
        "engDate": "1968-02-23",
        "nepDate": "11.11.2024"
    },
    {
        "engDate": "1968-02-24",
        "nepDate": "12.11.2024"
    },
    {
        "engDate": "1968-02-25",
        "nepDate": "13.11.2024"
    },
    {
        "engDate": "1968-02-26",
        "nepDate": "14.11.2024"
    },
    {
        "engDate": "1968-02-27",
        "nepDate": "15.11.2024"
    },
    {
        "engDate": "1968-02-28",
        "nepDate": "16.11.2024"
    },
    {
        "engDate": "1968-02-29",
        "nepDate": "17.11.2024"
    },
    {
        "engDate": "1968-03-01",
        "nepDate": "18.11.2024"
    },
    {
        "engDate": "1968-03-02",
        "nepDate": "19.11.2024"
    },
    {
        "engDate": "1968-03-03",
        "nepDate": "20.11.2024"
    },
    {
        "engDate": "1968-03-04",
        "nepDate": "21.11.2024"
    },
    {
        "engDate": "1968-03-05",
        "nepDate": "22.11.2024"
    },
    {
        "engDate": "1968-03-06",
        "nepDate": "23.11.2024"
    },
    {
        "engDate": "1968-03-07",
        "nepDate": "24.11.2024"
    },
    {
        "engDate": "1968-03-08",
        "nepDate": "25.11.2024"
    },
    {
        "engDate": "1968-03-09",
        "nepDate": "26.11.2024"
    },
    {
        "engDate": "1968-03-10",
        "nepDate": "27.11.2024"
    },
    {
        "engDate": "1968-03-11",
        "nepDate": "28.11.2024"
    },
    {
        "engDate": "1968-03-12",
        "nepDate": "29.11.2024"
    },
    {
        "engDate": "1968-03-13",
        "nepDate": "30.11.2024"
    },
    {
        "engDate": "1968-03-14",
        "nepDate": "01.12.2024"
    },
    {
        "engDate": "1968-03-15",
        "nepDate": "02.12.2024"
    },
    {
        "engDate": "1968-03-16",
        "nepDate": "03.12.2024"
    },
    {
        "engDate": "1968-03-17",
        "nepDate": "04.12.2024"
    },
    {
        "engDate": "1968-03-18",
        "nepDate": "05.12.2024"
    },
    {
        "engDate": "1968-03-19",
        "nepDate": "06.12.2024"
    },
    {
        "engDate": "1968-03-20",
        "nepDate": "07.12.2024"
    },
    {
        "engDate": "1968-03-21",
        "nepDate": "08.12.2024"
    },
    {
        "engDate": "1968-03-22",
        "nepDate": "09.12.2024"
    },
    {
        "engDate": "1968-03-23",
        "nepDate": "10.12.2024"
    },
    {
        "engDate": "1968-03-24",
        "nepDate": "11.12.2024"
    },
    {
        "engDate": "1968-03-25",
        "nepDate": "12.12.2024"
    },
    {
        "engDate": "1968-03-26",
        "nepDate": "13.12.2024"
    },
    {
        "engDate": "1968-03-27",
        "nepDate": "14.12.2024"
    },
    {
        "engDate": "1968-03-28",
        "nepDate": "15.12.2024"
    },
    {
        "engDate": "1968-03-29",
        "nepDate": "16.12.2024"
    },
    {
        "engDate": "1968-03-30",
        "nepDate": "17.12.2024"
    },
    {
        "engDate": "1968-03-31",
        "nepDate": "18.12.2024"
    },
    {
        "engDate": "1968-04-01",
        "nepDate": "19.12.2024"
    },
    {
        "engDate": "1968-04-02",
        "nepDate": "20.12.2024"
    },
    {
        "engDate": "1968-04-03",
        "nepDate": "21.12.2024"
    },
    {
        "engDate": "1968-04-04",
        "nepDate": "22.12.2024"
    },
    {
        "engDate": "1968-04-05",
        "nepDate": "23.12.2024"
    },
    {
        "engDate": "1968-04-06",
        "nepDate": "24.12.2024"
    },
    {
        "engDate": "1968-04-07",
        "nepDate": "25.12.2024"
    },
    {
        "engDate": "1968-04-08",
        "nepDate": "26.12.2024"
    },
    {
        "engDate": "1968-04-09",
        "nepDate": "27.12.2024"
    },
    {
        "engDate": "1968-04-10",
        "nepDate": "28.12.2024"
    },
    {
        "engDate": "1968-04-11",
        "nepDate": "29.12.2024"
    },
    {
        "engDate": "1968-04-12",
        "nepDate": "30.12.2024"
    },
    {
        "engDate": "1968-04-13",
        "nepDate": "01.01.2025"
    },
    {
        "engDate": "1968-04-14",
        "nepDate": "02.01.2025"
    },
    {
        "engDate": "1968-04-15",
        "nepDate": "03.01.2025"
    },
    {
        "engDate": "1968-04-16",
        "nepDate": "04.01.2025"
    },
    {
        "engDate": "1968-04-17",
        "nepDate": "05.01.2025"
    },
    {
        "engDate": "1968-04-18",
        "nepDate": "06.01.2025"
    },
    {
        "engDate": "1968-04-19",
        "nepDate": "07.01.2025"
    },
    {
        "engDate": "1968-04-20",
        "nepDate": "08.01.2025"
    },
    {
        "engDate": "1968-04-21",
        "nepDate": "09.01.2025"
    },
    {
        "engDate": "1968-04-22",
        "nepDate": "10.01.2025"
    },
    {
        "engDate": "1968-04-23",
        "nepDate": "11.01.2025"
    },
    {
        "engDate": "1968-04-24",
        "nepDate": "12.01.2025"
    },
    {
        "engDate": "1968-04-25",
        "nepDate": "13.01.2025"
    },
    {
        "engDate": "1968-04-26",
        "nepDate": "14.01.2025"
    },
    {
        "engDate": "1968-04-27",
        "nepDate": "15.01.2025"
    },
    {
        "engDate": "1968-04-28",
        "nepDate": "16.01.2025"
    },
    {
        "engDate": "1968-04-29",
        "nepDate": "17.01.2025"
    },
    {
        "engDate": "1968-04-30",
        "nepDate": "18.01.2025"
    },
    {
        "engDate": "1968-05-01",
        "nepDate": "19.01.2025"
    },
    {
        "engDate": "1968-05-02",
        "nepDate": "20.01.2025"
    },
    {
        "engDate": "1968-05-03",
        "nepDate": "21.01.2025"
    },
    {
        "engDate": "1968-05-04",
        "nepDate": "22.01.2025"
    },
    {
        "engDate": "1968-05-05",
        "nepDate": "23.01.2025"
    },
    {
        "engDate": "1968-05-06",
        "nepDate": "24.01.2025"
    },
    {
        "engDate": "1968-05-07",
        "nepDate": "25.01.2025"
    },
    {
        "engDate": "1968-05-08",
        "nepDate": "26.01.2025"
    },
    {
        "engDate": "1968-05-09",
        "nepDate": "27.01.2025"
    },
    {
        "engDate": "1968-05-10",
        "nepDate": "28.01.2025"
    },
    {
        "engDate": "1968-05-11",
        "nepDate": "29.01.2025"
    },
    {
        "engDate": "1968-05-12",
        "nepDate": "30.01.2025"
    },
    {
        "engDate": "1968-05-13",
        "nepDate": "31.01.2025"
    },
    {
        "engDate": "1968-05-14",
        "nepDate": "01.02.2025"
    },
    {
        "engDate": "1968-05-15",
        "nepDate": "02.02.2025"
    },
    {
        "engDate": "1968-05-16",
        "nepDate": "03.02.2025"
    },
    {
        "engDate": "1968-05-17",
        "nepDate": "04.02.2025"
    },
    {
        "engDate": "1968-05-18",
        "nepDate": "05.02.2025"
    },
    {
        "engDate": "1968-05-19",
        "nepDate": "06.02.2025"
    },
    {
        "engDate": "1968-05-20",
        "nepDate": "07.02.2025"
    },
    {
        "engDate": "1968-05-21",
        "nepDate": "08.02.2025"
    },
    {
        "engDate": "1968-05-22",
        "nepDate": "09.02.2025"
    },
    {
        "engDate": "1968-05-23",
        "nepDate": "10.02.2025"
    },
    {
        "engDate": "1968-05-24",
        "nepDate": "11.02.2025"
    },
    {
        "engDate": "1968-05-25",
        "nepDate": "12.02.2025"
    },
    {
        "engDate": "1968-05-26",
        "nepDate": "13.02.2025"
    },
    {
        "engDate": "1968-05-27",
        "nepDate": "14.02.2025"
    },
    {
        "engDate": "1968-05-28",
        "nepDate": "15.02.2025"
    },
    {
        "engDate": "1968-05-29",
        "nepDate": "16.02.2025"
    },
    {
        "engDate": "1968-05-30",
        "nepDate": "17.02.2025"
    },
    {
        "engDate": "1968-05-31",
        "nepDate": "18.02.2025"
    },
    {
        "engDate": "1968-06-01",
        "nepDate": "19.02.2025"
    },
    {
        "engDate": "1968-06-02",
        "nepDate": "20.02.2025"
    },
    {
        "engDate": "1968-06-03",
        "nepDate": "21.02.2025"
    },
    {
        "engDate": "1968-06-04",
        "nepDate": "22.02.2025"
    },
    {
        "engDate": "1968-06-05",
        "nepDate": "23.02.2025"
    },
    {
        "engDate": "1968-06-06",
        "nepDate": "24.02.2025"
    },
    {
        "engDate": "1968-06-07",
        "nepDate": "25.02.2025"
    },
    {
        "engDate": "1968-06-08",
        "nepDate": "26.02.2025"
    },
    {
        "engDate": "1968-06-09",
        "nepDate": "27.02.2025"
    },
    {
        "engDate": "1968-06-10",
        "nepDate": "28.02.2025"
    },
    {
        "engDate": "1968-06-11",
        "nepDate": "29.02.2025"
    },
    {
        "engDate": "1968-06-12",
        "nepDate": "30.02.2025"
    },
    {
        "engDate": "1968-06-13",
        "nepDate": "31.02.2025"
    },
    {
        "engDate": "1968-06-14",
        "nepDate": "01.03.2025"
    },
    {
        "engDate": "1968-06-15",
        "nepDate": "02.03.2025"
    },
    {
        "engDate": "1968-06-16",
        "nepDate": "03.03.2025"
    },
    {
        "engDate": "1968-06-17",
        "nepDate": "04.03.2025"
    },
    {
        "engDate": "1968-06-18",
        "nepDate": "05.03.2025"
    },
    {
        "engDate": "1968-06-19",
        "nepDate": "06.03.2025"
    },
    {
        "engDate": "1968-06-20",
        "nepDate": "07.03.2025"
    },
    {
        "engDate": "1968-06-21",
        "nepDate": "08.03.2025"
    },
    {
        "engDate": "1968-06-22",
        "nepDate": "09.03.2025"
    },
    {
        "engDate": "1968-06-23",
        "nepDate": "10.03.2025"
    },
    {
        "engDate": "1968-06-24",
        "nepDate": "11.03.2025"
    },
    {
        "engDate": "1968-06-25",
        "nepDate": "12.03.2025"
    },
    {
        "engDate": "1968-06-26",
        "nepDate": "13.03.2025"
    },
    {
        "engDate": "1968-06-27",
        "nepDate": "14.03.2025"
    },
    {
        "engDate": "1968-06-28",
        "nepDate": "15.03.2025"
    },
    {
        "engDate": "1968-06-29",
        "nepDate": "16.03.2025"
    },
    {
        "engDate": "1968-06-30",
        "nepDate": "17.03.2025"
    },
    {
        "engDate": "1968-07-01",
        "nepDate": "18.03.2025"
    },
    {
        "engDate": "1968-07-02",
        "nepDate": "19.03.2025"
    },
    {
        "engDate": "1968-07-03",
        "nepDate": "20.03.2025"
    },
    {
        "engDate": "1968-07-04",
        "nepDate": "21.03.2025"
    },
    {
        "engDate": "1968-07-05",
        "nepDate": "22.03.2025"
    },
    {
        "engDate": "1968-07-06",
        "nepDate": "23.03.2025"
    },
    {
        "engDate": "1968-07-07",
        "nepDate": "24.03.2025"
    },
    {
        "engDate": "1968-07-08",
        "nepDate": "25.03.2025"
    },
    {
        "engDate": "1968-07-09",
        "nepDate": "26.03.2025"
    },
    {
        "engDate": "1968-07-10",
        "nepDate": "27.03.2025"
    },
    {
        "engDate": "1968-07-11",
        "nepDate": "28.03.2025"
    },
    {
        "engDate": "1968-07-12",
        "nepDate": "29.03.2025"
    },
    {
        "engDate": "1968-07-13",
        "nepDate": "30.03.2025"
    },
    {
        "engDate": "1968-07-14",
        "nepDate": "31.03.2025"
    },
    {
        "engDate": "1968-07-15",
        "nepDate": "32.03.2025"
    },
    {
        "engDate": "1968-07-16",
        "nepDate": "01.04.2025"
    },
    {
        "engDate": "1968-07-17",
        "nepDate": "02.04.2025"
    },
    {
        "engDate": "1968-07-18",
        "nepDate": "03.04.2025"
    },
    {
        "engDate": "1968-07-19",
        "nepDate": "04.04.2025"
    },
    {
        "engDate": "1968-07-20",
        "nepDate": "05.04.2025"
    },
    {
        "engDate": "1968-07-21",
        "nepDate": "06.04.2025"
    },
    {
        "engDate": "1968-07-22",
        "nepDate": "07.04.2025"
    },
    {
        "engDate": "1968-07-23",
        "nepDate": "08.04.2025"
    },
    {
        "engDate": "1968-07-24",
        "nepDate": "09.04.2025"
    },
    {
        "engDate": "1968-07-25",
        "nepDate": "10.04.2025"
    },
    {
        "engDate": "1968-07-26",
        "nepDate": "11.04.2025"
    },
    {
        "engDate": "1968-07-27",
        "nepDate": "12.04.2025"
    },
    {
        "engDate": "1968-07-28",
        "nepDate": "13.04.2025"
    },
    {
        "engDate": "1968-07-29",
        "nepDate": "14.04.2025"
    },
    {
        "engDate": "1968-07-30",
        "nepDate": "15.04.2025"
    },
    {
        "engDate": "1968-07-31",
        "nepDate": "16.04.2025"
    },
    {
        "engDate": "1968-08-01",
        "nepDate": "17.04.2025"
    },
    {
        "engDate": "1968-08-02",
        "nepDate": "18.04.2025"
    },
    {
        "engDate": "1968-08-03",
        "nepDate": "19.04.2025"
    },
    {
        "engDate": "1968-08-04",
        "nepDate": "20.04.2025"
    },
    {
        "engDate": "1968-08-05",
        "nepDate": "21.04.2025"
    },
    {
        "engDate": "1968-08-06",
        "nepDate": "22.04.2025"
    },
    {
        "engDate": "1968-08-07",
        "nepDate": "23.04.2025"
    },
    {
        "engDate": "1968-08-08",
        "nepDate": "24.04.2025"
    },
    {
        "engDate": "1968-08-09",
        "nepDate": "25.04.2025"
    },
    {
        "engDate": "1968-08-10",
        "nepDate": "26.04.2025"
    },
    {
        "engDate": "1968-08-11",
        "nepDate": "27.04.2025"
    },
    {
        "engDate": "1968-08-12",
        "nepDate": "28.04.2025"
    },
    {
        "engDate": "1968-08-13",
        "nepDate": "29.04.2025"
    },
    {
        "engDate": "1968-08-14",
        "nepDate": "30.04.2025"
    },
    {
        "engDate": "1968-08-15",
        "nepDate": "31.04.2025"
    },
    {
        "engDate": "1968-08-16",
        "nepDate": "01.05.2025"
    },
    {
        "engDate": "1968-08-17",
        "nepDate": "02.05.2025"
    },
    {
        "engDate": "1968-08-18",
        "nepDate": "03.05.2025"
    },
    {
        "engDate": "1968-08-19",
        "nepDate": "04.05.2025"
    },
    {
        "engDate": "1968-08-20",
        "nepDate": "05.05.2025"
    },
    {
        "engDate": "1968-08-21",
        "nepDate": "06.05.2025"
    },
    {
        "engDate": "1968-08-22",
        "nepDate": "07.05.2025"
    },
    {
        "engDate": "1968-08-23",
        "nepDate": "08.05.2025"
    },
    {
        "engDate": "1968-08-24",
        "nepDate": "09.05.2025"
    },
    {
        "engDate": "1968-08-25",
        "nepDate": "10.05.2025"
    },
    {
        "engDate": "1968-08-26",
        "nepDate": "11.05.2025"
    },
    {
        "engDate": "1968-08-27",
        "nepDate": "12.05.2025"
    },
    {
        "engDate": "1968-08-28",
        "nepDate": "13.05.2025"
    },
    {
        "engDate": "1968-08-29",
        "nepDate": "14.05.2025"
    },
    {
        "engDate": "1968-08-30",
        "nepDate": "15.05.2025"
    },
    {
        "engDate": "1968-08-31",
        "nepDate": "16.05.2025"
    },
    {
        "engDate": "1968-09-01",
        "nepDate": "17.05.2025"
    },
    {
        "engDate": "1968-09-02",
        "nepDate": "18.05.2025"
    },
    {
        "engDate": "1968-09-03",
        "nepDate": "19.05.2025"
    },
    {
        "engDate": "1968-09-04",
        "nepDate": "20.05.2025"
    },
    {
        "engDate": "1968-09-05",
        "nepDate": "21.05.2025"
    },
    {
        "engDate": "1968-09-06",
        "nepDate": "22.05.2025"
    },
    {
        "engDate": "1968-09-07",
        "nepDate": "23.05.2025"
    },
    {
        "engDate": "1968-09-08",
        "nepDate": "24.05.2025"
    },
    {
        "engDate": "1968-09-09",
        "nepDate": "25.05.2025"
    },
    {
        "engDate": "1968-09-10",
        "nepDate": "26.05.2025"
    },
    {
        "engDate": "1968-09-11",
        "nepDate": "27.05.2025"
    },
    {
        "engDate": "1968-09-12",
        "nepDate": "28.05.2025"
    },
    {
        "engDate": "1968-09-13",
        "nepDate": "29.05.2025"
    },
    {
        "engDate": "1968-09-14",
        "nepDate": "30.05.2025"
    },
    {
        "engDate": "1968-09-15",
        "nepDate": "31.05.2025"
    },
    {
        "engDate": "1968-09-16",
        "nepDate": "01.06.2025"
    },
    {
        "engDate": "1968-09-17",
        "nepDate": "02.06.2025"
    },
    {
        "engDate": "1968-09-18",
        "nepDate": "03.06.2025"
    },
    {
        "engDate": "1968-09-19",
        "nepDate": "04.06.2025"
    },
    {
        "engDate": "1968-09-20",
        "nepDate": "05.06.2025"
    },
    {
        "engDate": "1968-09-21",
        "nepDate": "06.06.2025"
    },
    {
        "engDate": "1968-09-22",
        "nepDate": "07.06.2025"
    },
    {
        "engDate": "1968-09-23",
        "nepDate": "08.06.2025"
    },
    {
        "engDate": "1968-09-24",
        "nepDate": "09.06.2025"
    },
    {
        "engDate": "1968-09-25",
        "nepDate": "10.06.2025"
    },
    {
        "engDate": "1968-09-26",
        "nepDate": "11.06.2025"
    },
    {
        "engDate": "1968-09-27",
        "nepDate": "12.06.2025"
    },
    {
        "engDate": "1968-09-28",
        "nepDate": "13.06.2025"
    },
    {
        "engDate": "1968-09-29",
        "nepDate": "14.06.2025"
    },
    {
        "engDate": "1968-09-30",
        "nepDate": "15.06.2025"
    },
    {
        "engDate": "1968-10-01",
        "nepDate": "16.06.2025"
    },
    {
        "engDate": "1968-10-02",
        "nepDate": "17.06.2025"
    },
    {
        "engDate": "1968-10-03",
        "nepDate": "18.06.2025"
    },
    {
        "engDate": "1968-10-04",
        "nepDate": "19.06.2025"
    },
    {
        "engDate": "1968-10-05",
        "nepDate": "20.06.2025"
    },
    {
        "engDate": "1968-10-06",
        "nepDate": "21.06.2025"
    },
    {
        "engDate": "1968-10-07",
        "nepDate": "22.06.2025"
    },
    {
        "engDate": "1968-10-08",
        "nepDate": "23.06.2025"
    },
    {
        "engDate": "1968-10-09",
        "nepDate": "24.06.2025"
    },
    {
        "engDate": "1968-10-10",
        "nepDate": "25.06.2025"
    },
    {
        "engDate": "1968-10-11",
        "nepDate": "26.06.2025"
    },
    {
        "engDate": "1968-10-12",
        "nepDate": "27.06.2025"
    },
    {
        "engDate": "1968-10-13",
        "nepDate": "28.06.2025"
    },
    {
        "engDate": "1968-10-14",
        "nepDate": "29.06.2025"
    },
    {
        "engDate": "1968-10-15",
        "nepDate": "30.06.2025"
    },
    {
        "engDate": "1968-10-16",
        "nepDate": "31.06.2025"
    },
    {
        "engDate": "1968-10-17",
        "nepDate": "01.07.2025"
    },
    {
        "engDate": "1968-10-18",
        "nepDate": "02.07.2025"
    },
    {
        "engDate": "1968-10-19",
        "nepDate": "03.07.2025"
    },
    {
        "engDate": "1968-10-20",
        "nepDate": "04.07.2025"
    },
    {
        "engDate": "1968-10-21",
        "nepDate": "05.07.2025"
    },
    {
        "engDate": "1968-10-22",
        "nepDate": "06.07.2025"
    },
    {
        "engDate": "1968-10-23",
        "nepDate": "07.07.2025"
    },
    {
        "engDate": "1968-10-24",
        "nepDate": "08.07.2025"
    },
    {
        "engDate": "1968-10-25",
        "nepDate": "09.07.2025"
    },
    {
        "engDate": "1968-10-26",
        "nepDate": "10.07.2025"
    },
    {
        "engDate": "1968-10-27",
        "nepDate": "11.07.2025"
    },
    {
        "engDate": "1968-10-28",
        "nepDate": "12.07.2025"
    },
    {
        "engDate": "1968-10-29",
        "nepDate": "13.07.2025"
    },
    {
        "engDate": "1968-10-30",
        "nepDate": "14.07.2025"
    },
    {
        "engDate": "1968-10-31",
        "nepDate": "15.07.2025"
    },
    {
        "engDate": "1968-11-01",
        "nepDate": "16.07.2025"
    },
    {
        "engDate": "1968-11-02",
        "nepDate": "17.07.2025"
    },
    {
        "engDate": "1968-11-03",
        "nepDate": "18.07.2025"
    },
    {
        "engDate": "1968-11-04",
        "nepDate": "19.07.2025"
    },
    {
        "engDate": "1968-11-05",
        "nepDate": "20.07.2025"
    },
    {
        "engDate": "1968-11-06",
        "nepDate": "21.07.2025"
    },
    {
        "engDate": "1968-11-07",
        "nepDate": "22.07.2025"
    },
    {
        "engDate": "1968-11-08",
        "nepDate": "23.07.2025"
    },
    {
        "engDate": "1968-11-09",
        "nepDate": "24.07.2025"
    },
    {
        "engDate": "1968-11-10",
        "nepDate": "25.07.2025"
    },
    {
        "engDate": "1968-11-11",
        "nepDate": "26.07.2025"
    },
    {
        "engDate": "1968-11-12",
        "nepDate": "27.07.2025"
    },
    {
        "engDate": "1968-11-13",
        "nepDate": "28.07.2025"
    },
    {
        "engDate": "1968-11-14",
        "nepDate": "29.07.2025"
    },
    {
        "engDate": "1968-11-15",
        "nepDate": "30.07.2025"
    },
    {
        "engDate": "1968-11-16",
        "nepDate": "01.08.2025"
    },
    {
        "engDate": "1968-11-17",
        "nepDate": "02.08.2025"
    },
    {
        "engDate": "1968-11-18",
        "nepDate": "03.08.2025"
    },
    {
        "engDate": "1968-11-19",
        "nepDate": "04.08.2025"
    },
    {
        "engDate": "1968-11-20",
        "nepDate": "05.08.2025"
    },
    {
        "engDate": "1968-11-21",
        "nepDate": "06.08.2025"
    },
    {
        "engDate": "1968-11-22",
        "nepDate": "07.08.2025"
    },
    {
        "engDate": "1968-11-23",
        "nepDate": "08.08.2025"
    },
    {
        "engDate": "1968-11-24",
        "nepDate": "09.08.2025"
    },
    {
        "engDate": "1968-11-25",
        "nepDate": "10.08.2025"
    },
    {
        "engDate": "1968-11-26",
        "nepDate": "11.08.2025"
    },
    {
        "engDate": "1968-11-27",
        "nepDate": "12.08.2025"
    },
    {
        "engDate": "1968-11-28",
        "nepDate": "13.08.2025"
    },
    {
        "engDate": "1968-11-29",
        "nepDate": "14.08.2025"
    },
    {
        "engDate": "1968-11-30",
        "nepDate": "15.08.2025"
    },
    {
        "engDate": "1968-12-01",
        "nepDate": "16.08.2025"
    },
    {
        "engDate": "1968-12-02",
        "nepDate": "17.08.2025"
    },
    {
        "engDate": "1968-12-03",
        "nepDate": "18.08.2025"
    },
    {
        "engDate": "1968-12-04",
        "nepDate": "19.08.2025"
    },
    {
        "engDate": "1968-12-05",
        "nepDate": "20.08.2025"
    },
    {
        "engDate": "1968-12-06",
        "nepDate": "21.08.2025"
    },
    {
        "engDate": "1968-12-07",
        "nepDate": "22.08.2025"
    },
    {
        "engDate": "1968-12-08",
        "nepDate": "23.08.2025"
    },
    {
        "engDate": "1968-12-09",
        "nepDate": "24.08.2025"
    },
    {
        "engDate": "1968-12-10",
        "nepDate": "25.08.2025"
    },
    {
        "engDate": "1968-12-11",
        "nepDate": "26.08.2025"
    },
    {
        "engDate": "1968-12-12",
        "nepDate": "27.08.2025"
    },
    {
        "engDate": "1968-12-13",
        "nepDate": "28.08.2025"
    },
    {
        "engDate": "1968-12-14",
        "nepDate": "29.08.2025"
    },
    {
        "engDate": "1968-12-15",
        "nepDate": "01.09.2025"
    },
    {
        "engDate": "1968-12-16",
        "nepDate": "02.09.2025"
    },
    {
        "engDate": "1968-12-17",
        "nepDate": "03.09.2025"
    },
    {
        "engDate": "1968-12-18",
        "nepDate": "04.09.2025"
    },
    {
        "engDate": "1968-12-19",
        "nepDate": "05.09.2025"
    },
    {
        "engDate": "1968-12-20",
        "nepDate": "06.09.2025"
    },
    {
        "engDate": "1968-12-21",
        "nepDate": "07.09.2025"
    },
    {
        "engDate": "1968-12-22",
        "nepDate": "08.09.2025"
    },
    {
        "engDate": "1968-12-23",
        "nepDate": "09.09.2025"
    },
    {
        "engDate": "1968-12-24",
        "nepDate": "10.09.2025"
    },
    {
        "engDate": "1968-12-25",
        "nepDate": "11.09.2025"
    },
    {
        "engDate": "1968-12-26",
        "nepDate": "12.09.2025"
    },
    {
        "engDate": "1968-12-27",
        "nepDate": "13.09.2025"
    },
    {
        "engDate": "1968-12-28",
        "nepDate": "14.09.2025"
    },
    {
        "engDate": "1968-12-29",
        "nepDate": "15.09.2025"
    },
    {
        "engDate": "1968-12-30",
        "nepDate": "16.09.2025"
    },
    {
        "engDate": "1968-12-31",
        "nepDate": "17.09.2025"
    },
    {
        "engDate": "1969-01-01",
        "nepDate": "18.09.2025"
    },
    {
        "engDate": "1969-01-02",
        "nepDate": "19.09.2025"
    },
    {
        "engDate": "1969-01-03",
        "nepDate": "20.09.2025"
    },
    {
        "engDate": "1969-01-04",
        "nepDate": "21.09.2025"
    },
    {
        "engDate": "1969-01-05",
        "nepDate": "22.09.2025"
    },
    {
        "engDate": "1969-01-06",
        "nepDate": "23.09.2025"
    },
    {
        "engDate": "1969-01-07",
        "nepDate": "24.09.2025"
    },
    {
        "engDate": "1969-01-08",
        "nepDate": "25.09.2025"
    },
    {
        "engDate": "1969-01-09",
        "nepDate": "26.09.2025"
    },
    {
        "engDate": "1969-01-10",
        "nepDate": "27.09.2025"
    },
    {
        "engDate": "1969-01-11",
        "nepDate": "28.09.2025"
    },
    {
        "engDate": "1969-01-12",
        "nepDate": "29.09.2025"
    },
    {
        "engDate": "1969-01-13",
        "nepDate": "30.09.2025"
    },
    {
        "engDate": "1969-01-14",
        "nepDate": "01.10.2025"
    },
    {
        "engDate": "1969-01-15",
        "nepDate": "02.10.2025"
    },
    {
        "engDate": "1969-01-16",
        "nepDate": "03.10.2025"
    },
    {
        "engDate": "1969-01-17",
        "nepDate": "04.10.2025"
    },
    {
        "engDate": "1969-01-18",
        "nepDate": "05.10.2025"
    },
    {
        "engDate": "1969-01-19",
        "nepDate": "06.10.2025"
    },
    {
        "engDate": "1969-01-20",
        "nepDate": "07.10.2025"
    },
    {
        "engDate": "1969-01-21",
        "nepDate": "08.10.2025"
    },
    {
        "engDate": "1969-01-22",
        "nepDate": "09.10.2025"
    },
    {
        "engDate": "1969-01-23",
        "nepDate": "10.10.2025"
    },
    {
        "engDate": "1969-01-24",
        "nepDate": "11.10.2025"
    },
    {
        "engDate": "1969-01-25",
        "nepDate": "12.10.2025"
    },
    {
        "engDate": "1969-01-26",
        "nepDate": "13.10.2025"
    },
    {
        "engDate": "1969-01-27",
        "nepDate": "14.10.2025"
    },
    {
        "engDate": "1969-01-28",
        "nepDate": "15.10.2025"
    },
    {
        "engDate": "1969-01-29",
        "nepDate": "16.10.2025"
    },
    {
        "engDate": "1969-01-30",
        "nepDate": "17.10.2025"
    },
    {
        "engDate": "1969-01-31",
        "nepDate": "18.10.2025"
    },
    {
        "engDate": "1969-02-01",
        "nepDate": "19.10.2025"
    },
    {
        "engDate": "1969-02-02",
        "nepDate": "20.10.2025"
    },
    {
        "engDate": "1969-02-03",
        "nepDate": "21.10.2025"
    },
    {
        "engDate": "1969-02-04",
        "nepDate": "22.10.2025"
    },
    {
        "engDate": "1969-02-05",
        "nepDate": "23.10.2025"
    },
    {
        "engDate": "1969-02-06",
        "nepDate": "24.10.2025"
    },
    {
        "engDate": "1969-02-07",
        "nepDate": "25.10.2025"
    },
    {
        "engDate": "1969-02-08",
        "nepDate": "26.10.2025"
    },
    {
        "engDate": "1969-02-09",
        "nepDate": "27.10.2025"
    },
    {
        "engDate": "1969-02-10",
        "nepDate": "28.10.2025"
    },
    {
        "engDate": "1969-02-11",
        "nepDate": "29.10.2025"
    },
    {
        "engDate": "1969-02-12",
        "nepDate": "01.11.2025"
    },
    {
        "engDate": "1969-02-13",
        "nepDate": "02.11.2025"
    },
    {
        "engDate": "1969-02-14",
        "nepDate": "03.11.2025"
    },
    {
        "engDate": "1969-02-15",
        "nepDate": "04.11.2025"
    },
    {
        "engDate": "1969-02-16",
        "nepDate": "05.11.2025"
    },
    {
        "engDate": "1969-02-17",
        "nepDate": "06.11.2025"
    },
    {
        "engDate": "1969-02-18",
        "nepDate": "07.11.2025"
    },
    {
        "engDate": "1969-02-19",
        "nepDate": "08.11.2025"
    },
    {
        "engDate": "1969-02-20",
        "nepDate": "09.11.2025"
    },
    {
        "engDate": "1969-02-21",
        "nepDate": "10.11.2025"
    },
    {
        "engDate": "1969-02-22",
        "nepDate": "11.11.2025"
    },
    {
        "engDate": "1969-02-23",
        "nepDate": "12.11.2025"
    },
    {
        "engDate": "1969-02-24",
        "nepDate": "13.11.2025"
    },
    {
        "engDate": "1969-02-25",
        "nepDate": "14.11.2025"
    },
    {
        "engDate": "1969-02-26",
        "nepDate": "15.11.2025"
    },
    {
        "engDate": "1969-02-27",
        "nepDate": "16.11.2025"
    },
    {
        "engDate": "1969-02-28",
        "nepDate": "17.11.2025"
    },
    {
        "engDate": "1969-03-01",
        "nepDate": "18.11.2025"
    },
    {
        "engDate": "1969-03-02",
        "nepDate": "19.11.2025"
    },
    {
        "engDate": "1969-03-03",
        "nepDate": "20.11.2025"
    },
    {
        "engDate": "1969-03-04",
        "nepDate": "21.11.2025"
    },
    {
        "engDate": "1969-03-05",
        "nepDate": "22.11.2025"
    },
    {
        "engDate": "1969-03-06",
        "nepDate": "23.11.2025"
    },
    {
        "engDate": "1969-03-07",
        "nepDate": "24.11.2025"
    },
    {
        "engDate": "1969-03-08",
        "nepDate": "25.11.2025"
    },
    {
        "engDate": "1969-03-09",
        "nepDate": "26.11.2025"
    },
    {
        "engDate": "1969-03-10",
        "nepDate": "27.11.2025"
    },
    {
        "engDate": "1969-03-11",
        "nepDate": "28.11.2025"
    },
    {
        "engDate": "1969-03-12",
        "nepDate": "29.11.2025"
    },
    {
        "engDate": "1969-03-13",
        "nepDate": "30.11.2025"
    },
    {
        "engDate": "1969-03-14",
        "nepDate": "01.12.2025"
    },
    {
        "engDate": "1969-03-15",
        "nepDate": "02.12.2025"
    },
    {
        "engDate": "1969-03-16",
        "nepDate": "03.12.2025"
    },
    {
        "engDate": "1969-03-17",
        "nepDate": "04.12.2025"
    },
    {
        "engDate": "1969-03-18",
        "nepDate": "05.12.2025"
    },
    {
        "engDate": "1969-03-19",
        "nepDate": "06.12.2025"
    },
    {
        "engDate": "1969-03-20",
        "nepDate": "07.12.2025"
    },
    {
        "engDate": "1969-03-21",
        "nepDate": "08.12.2025"
    },
    {
        "engDate": "1969-03-22",
        "nepDate": "09.12.2025"
    },
    {
        "engDate": "1969-03-23",
        "nepDate": "10.12.2025"
    },
    {
        "engDate": "1969-03-24",
        "nepDate": "11.12.2025"
    },
    {
        "engDate": "1969-03-25",
        "nepDate": "12.12.2025"
    },
    {
        "engDate": "1969-03-26",
        "nepDate": "13.12.2025"
    },
    {
        "engDate": "1969-03-27",
        "nepDate": "14.12.2025"
    },
    {
        "engDate": "1969-03-28",
        "nepDate": "15.12.2025"
    },
    {
        "engDate": "1969-03-29",
        "nepDate": "16.12.2025"
    },
    {
        "engDate": "1969-03-30",
        "nepDate": "17.12.2025"
    },
    {
        "engDate": "1969-03-31",
        "nepDate": "18.12.2025"
    },
    {
        "engDate": "1969-04-01",
        "nepDate": "19.12.2025"
    },
    {
        "engDate": "1969-04-02",
        "nepDate": "20.12.2025"
    },
    {
        "engDate": "1969-04-03",
        "nepDate": "21.12.2025"
    },
    {
        "engDate": "1969-04-04",
        "nepDate": "22.12.2025"
    },
    {
        "engDate": "1969-04-05",
        "nepDate": "23.12.2025"
    },
    {
        "engDate": "1969-04-06",
        "nepDate": "24.12.2025"
    },
    {
        "engDate": "1969-04-07",
        "nepDate": "25.12.2025"
    },
    {
        "engDate": "1969-04-08",
        "nepDate": "26.12.2025"
    },
    {
        "engDate": "1969-04-09",
        "nepDate": "27.12.2025"
    },
    {
        "engDate": "1969-04-10",
        "nepDate": "28.12.2025"
    },
    {
        "engDate": "1969-04-11",
        "nepDate": "29.12.2025"
    },
    {
        "engDate": "1969-04-12",
        "nepDate": "30.12.2025"
    },
    {
        "engDate": "1969-04-13",
        "nepDate": "01.01.2026"
    },
    {
        "engDate": "1969-04-14",
        "nepDate": "02.01.2026"
    },
    {
        "engDate": "1969-04-15",
        "nepDate": "03.01.2026"
    },
    {
        "engDate": "1969-04-16",
        "nepDate": "04.01.2026"
    },
    {
        "engDate": "1969-04-17",
        "nepDate": "05.01.2026"
    },
    {
        "engDate": "1969-04-18",
        "nepDate": "06.01.2026"
    },
    {
        "engDate": "1969-04-19",
        "nepDate": "07.01.2026"
    },
    {
        "engDate": "1969-04-20",
        "nepDate": "08.01.2026"
    },
    {
        "engDate": "1969-04-21",
        "nepDate": "09.01.2026"
    },
    {
        "engDate": "1969-04-22",
        "nepDate": "10.01.2026"
    },
    {
        "engDate": "1969-04-23",
        "nepDate": "11.01.2026"
    },
    {
        "engDate": "1969-04-24",
        "nepDate": "12.01.2026"
    },
    {
        "engDate": "1969-04-25",
        "nepDate": "13.01.2026"
    },
    {
        "engDate": "1969-04-26",
        "nepDate": "14.01.2026"
    },
    {
        "engDate": "1969-04-27",
        "nepDate": "15.01.2026"
    },
    {
        "engDate": "1969-04-28",
        "nepDate": "16.01.2026"
    },
    {
        "engDate": "1969-04-29",
        "nepDate": "17.01.2026"
    },
    {
        "engDate": "1969-04-30",
        "nepDate": "18.01.2026"
    },
    {
        "engDate": "1969-05-01",
        "nepDate": "19.01.2026"
    },
    {
        "engDate": "1969-05-02",
        "nepDate": "20.01.2026"
    },
    {
        "engDate": "1969-05-03",
        "nepDate": "21.01.2026"
    },
    {
        "engDate": "1969-05-04",
        "nepDate": "22.01.2026"
    },
    {
        "engDate": "1969-05-05",
        "nepDate": "23.01.2026"
    },
    {
        "engDate": "1969-05-06",
        "nepDate": "24.01.2026"
    },
    {
        "engDate": "1969-05-07",
        "nepDate": "25.01.2026"
    },
    {
        "engDate": "1969-05-08",
        "nepDate": "26.01.2026"
    },
    {
        "engDate": "1969-05-09",
        "nepDate": "27.01.2026"
    },
    {
        "engDate": "1969-05-10",
        "nepDate": "28.01.2026"
    },
    {
        "engDate": "1969-05-11",
        "nepDate": "29.01.2026"
    },
    {
        "engDate": "1969-05-12",
        "nepDate": "30.01.2026"
    },
    {
        "engDate": "1969-05-13",
        "nepDate": "31.01.2026"
    },
    {
        "engDate": "1969-05-14",
        "nepDate": "01.02.2026"
    },
    {
        "engDate": "1969-05-15",
        "nepDate": "02.02.2026"
    },
    {
        "engDate": "1969-05-16",
        "nepDate": "03.02.2026"
    },
    {
        "engDate": "1969-05-17",
        "nepDate": "04.02.2026"
    },
    {
        "engDate": "1969-05-18",
        "nepDate": "05.02.2026"
    },
    {
        "engDate": "1969-05-19",
        "nepDate": "06.02.2026"
    },
    {
        "engDate": "1969-05-20",
        "nepDate": "07.02.2026"
    },
    {
        "engDate": "1969-05-21",
        "nepDate": "08.02.2026"
    },
    {
        "engDate": "1969-05-22",
        "nepDate": "09.02.2026"
    },
    {
        "engDate": "1969-05-23",
        "nepDate": "10.02.2026"
    },
    {
        "engDate": "1969-05-24",
        "nepDate": "11.02.2026"
    },
    {
        "engDate": "1969-05-25",
        "nepDate": "12.02.2026"
    },
    {
        "engDate": "1969-05-26",
        "nepDate": "13.02.2026"
    },
    {
        "engDate": "1969-05-27",
        "nepDate": "14.02.2026"
    },
    {
        "engDate": "1969-05-28",
        "nepDate": "15.02.2026"
    },
    {
        "engDate": "1969-05-29",
        "nepDate": "16.02.2026"
    },
    {
        "engDate": "1969-05-30",
        "nepDate": "17.02.2026"
    },
    {
        "engDate": "1969-05-31",
        "nepDate": "18.02.2026"
    },
    {
        "engDate": "1969-06-01",
        "nepDate": "19.02.2026"
    },
    {
        "engDate": "1969-06-02",
        "nepDate": "20.02.2026"
    },
    {
        "engDate": "1969-06-03",
        "nepDate": "21.02.2026"
    },
    {
        "engDate": "1969-06-04",
        "nepDate": "22.02.2026"
    },
    {
        "engDate": "1969-06-05",
        "nepDate": "23.02.2026"
    },
    {
        "engDate": "1969-06-06",
        "nepDate": "24.02.2026"
    },
    {
        "engDate": "1969-06-07",
        "nepDate": "25.02.2026"
    },
    {
        "engDate": "1969-06-08",
        "nepDate": "26.02.2026"
    },
    {
        "engDate": "1969-06-09",
        "nepDate": "27.02.2026"
    },
    {
        "engDate": "1969-06-10",
        "nepDate": "28.02.2026"
    },
    {
        "engDate": "1969-06-11",
        "nepDate": "29.02.2026"
    },
    {
        "engDate": "1969-06-12",
        "nepDate": "30.02.2026"
    },
    {
        "engDate": "1969-06-13",
        "nepDate": "31.02.2026"
    },
    {
        "engDate": "1969-06-14",
        "nepDate": "32.02.2026"
    },
    {
        "engDate": "1969-06-15",
        "nepDate": "01.03.2026"
    },
    {
        "engDate": "1969-06-16",
        "nepDate": "02.03.2026"
    },
    {
        "engDate": "1969-06-17",
        "nepDate": "03.03.2026"
    },
    {
        "engDate": "1969-06-18",
        "nepDate": "04.03.2026"
    },
    {
        "engDate": "1969-06-19",
        "nepDate": "05.03.2026"
    },
    {
        "engDate": "1969-06-20",
        "nepDate": "06.03.2026"
    },
    {
        "engDate": "1969-06-21",
        "nepDate": "07.03.2026"
    },
    {
        "engDate": "1969-06-22",
        "nepDate": "08.03.2026"
    },
    {
        "engDate": "1969-06-23",
        "nepDate": "09.03.2026"
    },
    {
        "engDate": "1969-06-24",
        "nepDate": "10.03.2026"
    },
    {
        "engDate": "1969-06-25",
        "nepDate": "11.03.2026"
    },
    {
        "engDate": "1969-06-26",
        "nepDate": "12.03.2026"
    },
    {
        "engDate": "1969-06-27",
        "nepDate": "13.03.2026"
    },
    {
        "engDate": "1969-06-28",
        "nepDate": "14.03.2026"
    },
    {
        "engDate": "1969-06-29",
        "nepDate": "15.03.2026"
    },
    {
        "engDate": "1969-06-30",
        "nepDate": "16.03.2026"
    },
    {
        "engDate": "1969-07-01",
        "nepDate": "17.03.2026"
    },
    {
        "engDate": "1969-07-02",
        "nepDate": "18.03.2026"
    },
    {
        "engDate": "1969-07-03",
        "nepDate": "19.03.2026"
    },
    {
        "engDate": "1969-07-04",
        "nepDate": "20.03.2026"
    },
    {
        "engDate": "1969-07-05",
        "nepDate": "21.03.2026"
    },
    {
        "engDate": "1969-07-06",
        "nepDate": "22.03.2026"
    },
    {
        "engDate": "1969-07-07",
        "nepDate": "23.03.2026"
    },
    {
        "engDate": "1969-07-08",
        "nepDate": "24.03.2026"
    },
    {
        "engDate": "1969-07-09",
        "nepDate": "25.03.2026"
    },
    {
        "engDate": "1969-07-10",
        "nepDate": "26.03.2026"
    },
    {
        "engDate": "1969-07-11",
        "nepDate": "27.03.2026"
    },
    {
        "engDate": "1969-07-12",
        "nepDate": "28.03.2026"
    },
    {
        "engDate": "1969-07-13",
        "nepDate": "29.03.2026"
    },
    {
        "engDate": "1969-07-14",
        "nepDate": "30.03.2026"
    },
    {
        "engDate": "1969-07-15",
        "nepDate": "31.03.2026"
    },
    {
        "engDate": "1969-07-16",
        "nepDate": "01.04.2026"
    },
    {
        "engDate": "1969-07-17",
        "nepDate": "02.04.2026"
    },
    {
        "engDate": "1969-07-18",
        "nepDate": "03.04.2026"
    },
    {
        "engDate": "1969-07-19",
        "nepDate": "04.04.2026"
    },
    {
        "engDate": "1969-07-20",
        "nepDate": "05.04.2026"
    },
    {
        "engDate": "1969-07-21",
        "nepDate": "06.04.2026"
    },
    {
        "engDate": "1969-07-22",
        "nepDate": "07.04.2026"
    },
    {
        "engDate": "1969-07-23",
        "nepDate": "08.04.2026"
    },
    {
        "engDate": "1969-07-24",
        "nepDate": "09.04.2026"
    },
    {
        "engDate": "1969-07-25",
        "nepDate": "10.04.2026"
    },
    {
        "engDate": "1969-07-26",
        "nepDate": "11.04.2026"
    },
    {
        "engDate": "1969-07-27",
        "nepDate": "12.04.2026"
    },
    {
        "engDate": "1969-07-28",
        "nepDate": "13.04.2026"
    },
    {
        "engDate": "1969-07-29",
        "nepDate": "14.04.2026"
    },
    {
        "engDate": "1969-07-30",
        "nepDate": "15.04.2026"
    },
    {
        "engDate": "1969-07-31",
        "nepDate": "16.04.2026"
    },
    {
        "engDate": "1969-08-01",
        "nepDate": "17.04.2026"
    },
    {
        "engDate": "1969-08-02",
        "nepDate": "18.04.2026"
    },
    {
        "engDate": "1969-08-03",
        "nepDate": "19.04.2026"
    },
    {
        "engDate": "1969-08-04",
        "nepDate": "20.04.2026"
    },
    {
        "engDate": "1969-08-05",
        "nepDate": "21.04.2026"
    },
    {
        "engDate": "1969-08-06",
        "nepDate": "22.04.2026"
    },
    {
        "engDate": "1969-08-07",
        "nepDate": "23.04.2026"
    },
    {
        "engDate": "1969-08-08",
        "nepDate": "24.04.2026"
    },
    {
        "engDate": "1969-08-09",
        "nepDate": "25.04.2026"
    },
    {
        "engDate": "1969-08-10",
        "nepDate": "26.04.2026"
    },
    {
        "engDate": "1969-08-11",
        "nepDate": "27.04.2026"
    },
    {
        "engDate": "1969-08-12",
        "nepDate": "28.04.2026"
    },
    {
        "engDate": "1969-08-13",
        "nepDate": "29.04.2026"
    },
    {
        "engDate": "1969-08-14",
        "nepDate": "30.04.2026"
    },
    {
        "engDate": "1969-08-15",
        "nepDate": "31.04.2026"
    },
    {
        "engDate": "1969-08-16",
        "nepDate": "32.04.2026"
    },
    {
        "engDate": "1969-08-17",
        "nepDate": "01.05.2026"
    },
    {
        "engDate": "1969-08-18",
        "nepDate": "02.05.2026"
    },
    {
        "engDate": "1969-08-19",
        "nepDate": "03.05.2026"
    },
    {
        "engDate": "1969-08-20",
        "nepDate": "04.05.2026"
    },
    {
        "engDate": "1969-08-21",
        "nepDate": "05.05.2026"
    },
    {
        "engDate": "1969-08-22",
        "nepDate": "06.05.2026"
    },
    {
        "engDate": "1969-08-23",
        "nepDate": "07.05.2026"
    },
    {
        "engDate": "1969-08-24",
        "nepDate": "08.05.2026"
    },
    {
        "engDate": "1969-08-25",
        "nepDate": "09.05.2026"
    },
    {
        "engDate": "1969-08-26",
        "nepDate": "10.05.2026"
    },
    {
        "engDate": "1969-08-27",
        "nepDate": "11.05.2026"
    },
    {
        "engDate": "1969-08-28",
        "nepDate": "12.05.2026"
    },
    {
        "engDate": "1969-08-29",
        "nepDate": "13.05.2026"
    },
    {
        "engDate": "1969-08-30",
        "nepDate": "14.05.2026"
    },
    {
        "engDate": "1969-08-31",
        "nepDate": "15.05.2026"
    },
    {
        "engDate": "1969-09-01",
        "nepDate": "16.05.2026"
    },
    {
        "engDate": "1969-09-02",
        "nepDate": "17.05.2026"
    },
    {
        "engDate": "1969-09-03",
        "nepDate": "18.05.2026"
    },
    {
        "engDate": "1969-09-04",
        "nepDate": "19.05.2026"
    },
    {
        "engDate": "1969-09-05",
        "nepDate": "20.05.2026"
    },
    {
        "engDate": "1969-09-06",
        "nepDate": "21.05.2026"
    },
    {
        "engDate": "1969-09-07",
        "nepDate": "22.05.2026"
    },
    {
        "engDate": "1969-09-08",
        "nepDate": "23.05.2026"
    },
    {
        "engDate": "1969-09-09",
        "nepDate": "24.05.2026"
    },
    {
        "engDate": "1969-09-10",
        "nepDate": "25.05.2026"
    },
    {
        "engDate": "1969-09-11",
        "nepDate": "26.05.2026"
    },
    {
        "engDate": "1969-09-12",
        "nepDate": "27.05.2026"
    },
    {
        "engDate": "1969-09-13",
        "nepDate": "28.05.2026"
    },
    {
        "engDate": "1969-09-14",
        "nepDate": "29.05.2026"
    },
    {
        "engDate": "1969-09-15",
        "nepDate": "30.05.2026"
    },
    {
        "engDate": "1969-09-16",
        "nepDate": "31.05.2026"
    },
    {
        "engDate": "1969-09-17",
        "nepDate": "01.06.2026"
    },
    {
        "engDate": "1969-09-18",
        "nepDate": "02.06.2026"
    },
    {
        "engDate": "1969-09-19",
        "nepDate": "03.06.2026"
    },
    {
        "engDate": "1969-09-20",
        "nepDate": "04.06.2026"
    },
    {
        "engDate": "1969-09-21",
        "nepDate": "05.06.2026"
    },
    {
        "engDate": "1969-09-22",
        "nepDate": "06.06.2026"
    },
    {
        "engDate": "1969-09-23",
        "nepDate": "07.06.2026"
    },
    {
        "engDate": "1969-09-24",
        "nepDate": "08.06.2026"
    },
    {
        "engDate": "1969-09-25",
        "nepDate": "09.06.2026"
    },
    {
        "engDate": "1969-09-26",
        "nepDate": "10.06.2026"
    },
    {
        "engDate": "1969-09-27",
        "nepDate": "11.06.2026"
    },
    {
        "engDate": "1969-09-28",
        "nepDate": "12.06.2026"
    },
    {
        "engDate": "1969-09-29",
        "nepDate": "13.06.2026"
    },
    {
        "engDate": "1969-09-30",
        "nepDate": "14.06.2026"
    },
    {
        "engDate": "1969-10-01",
        "nepDate": "15.06.2026"
    },
    {
        "engDate": "1969-10-02",
        "nepDate": "16.06.2026"
    },
    {
        "engDate": "1969-10-03",
        "nepDate": "17.06.2026"
    },
    {
        "engDate": "1969-10-04",
        "nepDate": "18.06.2026"
    },
    {
        "engDate": "1969-10-05",
        "nepDate": "19.06.2026"
    },
    {
        "engDate": "1969-10-06",
        "nepDate": "20.06.2026"
    },
    {
        "engDate": "1969-10-07",
        "nepDate": "21.06.2026"
    },
    {
        "engDate": "1969-10-08",
        "nepDate": "22.06.2026"
    },
    {
        "engDate": "1969-10-09",
        "nepDate": "23.06.2026"
    },
    {
        "engDate": "1969-10-10",
        "nepDate": "24.06.2026"
    },
    {
        "engDate": "1969-10-11",
        "nepDate": "25.06.2026"
    },
    {
        "engDate": "1969-10-12",
        "nepDate": "26.06.2026"
    },
    {
        "engDate": "1969-10-13",
        "nepDate": "27.06.2026"
    },
    {
        "engDate": "1969-10-14",
        "nepDate": "28.06.2026"
    },
    {
        "engDate": "1969-10-15",
        "nepDate": "29.06.2026"
    },
    {
        "engDate": "1969-10-16",
        "nepDate": "30.06.2026"
    },
    {
        "engDate": "1969-10-17",
        "nepDate": "01.07.2026"
    },
    {
        "engDate": "1969-10-18",
        "nepDate": "02.07.2026"
    },
    {
        "engDate": "1969-10-19",
        "nepDate": "03.07.2026"
    },
    {
        "engDate": "1969-10-20",
        "nepDate": "04.07.2026"
    },
    {
        "engDate": "1969-10-21",
        "nepDate": "05.07.2026"
    },
    {
        "engDate": "1969-10-22",
        "nepDate": "06.07.2026"
    },
    {
        "engDate": "1969-10-23",
        "nepDate": "07.07.2026"
    },
    {
        "engDate": "1969-10-24",
        "nepDate": "08.07.2026"
    },
    {
        "engDate": "1969-10-25",
        "nepDate": "09.07.2026"
    },
    {
        "engDate": "1969-10-26",
        "nepDate": "10.07.2026"
    },
    {
        "engDate": "1969-10-27",
        "nepDate": "11.07.2026"
    },
    {
        "engDate": "1969-10-28",
        "nepDate": "12.07.2026"
    },
    {
        "engDate": "1969-10-29",
        "nepDate": "13.07.2026"
    },
    {
        "engDate": "1969-10-30",
        "nepDate": "14.07.2026"
    },
    {
        "engDate": "1969-10-31",
        "nepDate": "15.07.2026"
    },
    {
        "engDate": "1969-11-01",
        "nepDate": "16.07.2026"
    },
    {
        "engDate": "1969-11-02",
        "nepDate": "17.07.2026"
    },
    {
        "engDate": "1969-11-03",
        "nepDate": "18.07.2026"
    },
    {
        "engDate": "1969-11-04",
        "nepDate": "19.07.2026"
    },
    {
        "engDate": "1969-11-05",
        "nepDate": "20.07.2026"
    },
    {
        "engDate": "1969-11-06",
        "nepDate": "21.07.2026"
    },
    {
        "engDate": "1969-11-07",
        "nepDate": "22.07.2026"
    },
    {
        "engDate": "1969-11-08",
        "nepDate": "23.07.2026"
    },
    {
        "engDate": "1969-11-09",
        "nepDate": "24.07.2026"
    },
    {
        "engDate": "1969-11-10",
        "nepDate": "25.07.2026"
    },
    {
        "engDate": "1969-11-11",
        "nepDate": "26.07.2026"
    },
    {
        "engDate": "1969-11-12",
        "nepDate": "27.07.2026"
    },
    {
        "engDate": "1969-11-13",
        "nepDate": "28.07.2026"
    },
    {
        "engDate": "1969-11-14",
        "nepDate": "29.07.2026"
    },
    {
        "engDate": "1969-11-15",
        "nepDate": "30.07.2026"
    },
    {
        "engDate": "1969-11-16",
        "nepDate": "01.08.2026"
    },
    {
        "engDate": "1969-11-17",
        "nepDate": "02.08.2026"
    },
    {
        "engDate": "1969-11-18",
        "nepDate": "03.08.2026"
    },
    {
        "engDate": "1969-11-19",
        "nepDate": "04.08.2026"
    },
    {
        "engDate": "1969-11-20",
        "nepDate": "05.08.2026"
    },
    {
        "engDate": "1969-11-21",
        "nepDate": "06.08.2026"
    },
    {
        "engDate": "1969-11-22",
        "nepDate": "07.08.2026"
    },
    {
        "engDate": "1969-11-23",
        "nepDate": "08.08.2026"
    },
    {
        "engDate": "1969-11-24",
        "nepDate": "09.08.2026"
    },
    {
        "engDate": "1969-11-25",
        "nepDate": "10.08.2026"
    },
    {
        "engDate": "1969-11-26",
        "nepDate": "11.08.2026"
    },
    {
        "engDate": "1969-11-27",
        "nepDate": "12.08.2026"
    },
    {
        "engDate": "1969-11-28",
        "nepDate": "13.08.2026"
    },
    {
        "engDate": "1969-11-29",
        "nepDate": "14.08.2026"
    },
    {
        "engDate": "1969-11-30",
        "nepDate": "15.08.2026"
    },
    {
        "engDate": "1969-12-01",
        "nepDate": "16.08.2026"
    },
    {
        "engDate": "1969-12-02",
        "nepDate": "17.08.2026"
    },
    {
        "engDate": "1969-12-03",
        "nepDate": "18.08.2026"
    },
    {
        "engDate": "1969-12-04",
        "nepDate": "19.08.2026"
    },
    {
        "engDate": "1969-12-05",
        "nepDate": "20.08.2026"
    },
    {
        "engDate": "1969-12-06",
        "nepDate": "21.08.2026"
    },
    {
        "engDate": "1969-12-07",
        "nepDate": "22.08.2026"
    },
    {
        "engDate": "1969-12-08",
        "nepDate": "23.08.2026"
    },
    {
        "engDate": "1969-12-09",
        "nepDate": "24.08.2026"
    },
    {
        "engDate": "1969-12-10",
        "nepDate": "25.08.2026"
    },
    {
        "engDate": "1969-12-11",
        "nepDate": "26.08.2026"
    },
    {
        "engDate": "1969-12-12",
        "nepDate": "27.08.2026"
    },
    {
        "engDate": "1969-12-13",
        "nepDate": "28.08.2026"
    },
    {
        "engDate": "1969-12-14",
        "nepDate": "29.08.2026"
    },
    {
        "engDate": "1969-12-15",
        "nepDate": "30.08.2026"
    },
    {
        "engDate": "1969-12-16",
        "nepDate": "01.09.2026"
    },
    {
        "engDate": "1969-12-17",
        "nepDate": "02.09.2026"
    },
    {
        "engDate": "1969-12-18",
        "nepDate": "03.09.2026"
    },
    {
        "engDate": "1969-12-19",
        "nepDate": "04.09.2026"
    },
    {
        "engDate": "1969-12-20",
        "nepDate": "05.09.2026"
    },
    {
        "engDate": "1969-12-21",
        "nepDate": "06.09.2026"
    },
    {
        "engDate": "1969-12-22",
        "nepDate": "07.09.2026"
    },
    {
        "engDate": "1969-12-23",
        "nepDate": "08.09.2026"
    },
    {
        "engDate": "1969-12-24",
        "nepDate": "09.09.2026"
    },
    {
        "engDate": "1969-12-25",
        "nepDate": "10.09.2026"
    },
    {
        "engDate": "1969-12-26",
        "nepDate": "11.09.2026"
    },
    {
        "engDate": "1969-12-27",
        "nepDate": "12.09.2026"
    },
    {
        "engDate": "1969-12-28",
        "nepDate": "13.09.2026"
    },
    {
        "engDate": "1969-12-29",
        "nepDate": "14.09.2026"
    },
    {
        "engDate": "1969-12-30",
        "nepDate": "15.09.2026"
    },
    {
        "engDate": "1969-12-31",
        "nepDate": "16.09.2026"
    },
    {
        "engDate": "1970-01-01",
        "nepDate": "17.09.2026"
    },
    {
        "engDate": "1970-01-02",
        "nepDate": "18.09.2026"
    },
    {
        "engDate": "1970-01-03",
        "nepDate": "19.09.2026"
    },
    {
        "engDate": "1970-01-04",
        "nepDate": "20.09.2026"
    },
    {
        "engDate": "1970-01-05",
        "nepDate": "21.09.2026"
    },
    {
        "engDate": "1970-01-06",
        "nepDate": "22.09.2026"
    },
    {
        "engDate": "1970-01-07",
        "nepDate": "23.09.2026"
    },
    {
        "engDate": "1970-01-08",
        "nepDate": "24.09.2026"
    },
    {
        "engDate": "1970-01-09",
        "nepDate": "25.09.2026"
    },
    {
        "engDate": "1970-01-10",
        "nepDate": "26.09.2026"
    },
    {
        "engDate": "1970-01-11",
        "nepDate": "27.09.2026"
    },
    {
        "engDate": "1970-01-12",
        "nepDate": "28.09.2026"
    },
    {
        "engDate": "1970-01-13",
        "nepDate": "29.09.2026"
    },
    {
        "engDate": "1970-01-14",
        "nepDate": "01.10.2026"
    },
    {
        "engDate": "1970-01-15",
        "nepDate": "02.10.2026"
    },
    {
        "engDate": "1970-01-16",
        "nepDate": "03.10.2026"
    },
    {
        "engDate": "1970-01-17",
        "nepDate": "04.10.2026"
    },
    {
        "engDate": "1970-01-18",
        "nepDate": "05.10.2026"
    },
    {
        "engDate": "1970-01-19",
        "nepDate": "06.10.2026"
    },
    {
        "engDate": "1970-01-20",
        "nepDate": "07.10.2026"
    },
    {
        "engDate": "1970-01-21",
        "nepDate": "08.10.2026"
    },
    {
        "engDate": "1970-01-22",
        "nepDate": "09.10.2026"
    },
    {
        "engDate": "1970-01-23",
        "nepDate": "10.10.2026"
    },
    {
        "engDate": "1970-01-24",
        "nepDate": "11.10.2026"
    },
    {
        "engDate": "1970-01-25",
        "nepDate": "12.10.2026"
    },
    {
        "engDate": "1970-01-26",
        "nepDate": "13.10.2026"
    },
    {
        "engDate": "1970-01-27",
        "nepDate": "14.10.2026"
    },
    {
        "engDate": "1970-01-28",
        "nepDate": "15.10.2026"
    },
    {
        "engDate": "1970-01-29",
        "nepDate": "16.10.2026"
    },
    {
        "engDate": "1970-01-30",
        "nepDate": "17.10.2026"
    },
    {
        "engDate": "1970-01-31",
        "nepDate": "18.10.2026"
    },
    {
        "engDate": "1970-02-01",
        "nepDate": "19.10.2026"
    },
    {
        "engDate": "1970-02-02",
        "nepDate": "20.10.2026"
    },
    {
        "engDate": "1970-02-03",
        "nepDate": "21.10.2026"
    },
    {
        "engDate": "1970-02-04",
        "nepDate": "22.10.2026"
    },
    {
        "engDate": "1970-02-05",
        "nepDate": "23.10.2026"
    },
    {
        "engDate": "1970-02-06",
        "nepDate": "24.10.2026"
    },
    {
        "engDate": "1970-02-07",
        "nepDate": "25.10.2026"
    },
    {
        "engDate": "1970-02-08",
        "nepDate": "26.10.2026"
    },
    {
        "engDate": "1970-02-09",
        "nepDate": "27.10.2026"
    },
    {
        "engDate": "1970-02-10",
        "nepDate": "28.10.2026"
    },
    {
        "engDate": "1970-02-11",
        "nepDate": "29.10.2026"
    },
    {
        "engDate": "1970-02-12",
        "nepDate": "01.11.2026"
    },
    {
        "engDate": "1970-02-13",
        "nepDate": "02.11.2026"
    },
    {
        "engDate": "1970-02-14",
        "nepDate": "03.11.2026"
    },
    {
        "engDate": "1970-02-15",
        "nepDate": "04.11.2026"
    },
    {
        "engDate": "1970-02-16",
        "nepDate": "05.11.2026"
    },
    {
        "engDate": "1970-02-17",
        "nepDate": "06.11.2026"
    },
    {
        "engDate": "1970-02-18",
        "nepDate": "07.11.2026"
    },
    {
        "engDate": "1970-02-19",
        "nepDate": "08.11.2026"
    },
    {
        "engDate": "1970-02-20",
        "nepDate": "09.11.2026"
    },
    {
        "engDate": "1970-02-21",
        "nepDate": "10.11.2026"
    },
    {
        "engDate": "1970-02-22",
        "nepDate": "11.11.2026"
    },
    {
        "engDate": "1970-02-23",
        "nepDate": "12.11.2026"
    },
    {
        "engDate": "1970-02-24",
        "nepDate": "13.11.2026"
    },
    {
        "engDate": "1970-02-25",
        "nepDate": "14.11.2026"
    },
    {
        "engDate": "1970-02-26",
        "nepDate": "15.11.2026"
    },
    {
        "engDate": "1970-02-27",
        "nepDate": "16.11.2026"
    },
    {
        "engDate": "1970-02-28",
        "nepDate": "17.11.2026"
    },
    {
        "engDate": "1970-03-01",
        "nepDate": "18.11.2026"
    },
    {
        "engDate": "1970-03-02",
        "nepDate": "19.11.2026"
    },
    {
        "engDate": "1970-03-03",
        "nepDate": "20.11.2026"
    },
    {
        "engDate": "1970-03-04",
        "nepDate": "21.11.2026"
    },
    {
        "engDate": "1970-03-05",
        "nepDate": "22.11.2026"
    },
    {
        "engDate": "1970-03-06",
        "nepDate": "23.11.2026"
    },
    {
        "engDate": "1970-03-07",
        "nepDate": "24.11.2026"
    },
    {
        "engDate": "1970-03-08",
        "nepDate": "25.11.2026"
    },
    {
        "engDate": "1970-03-09",
        "nepDate": "26.11.2026"
    },
    {
        "engDate": "1970-03-10",
        "nepDate": "27.11.2026"
    },
    {
        "engDate": "1970-03-11",
        "nepDate": "28.11.2026"
    },
    {
        "engDate": "1970-03-12",
        "nepDate": "29.11.2026"
    },
    {
        "engDate": "1970-03-13",
        "nepDate": "30.11.2026"
    },
    {
        "engDate": "1970-03-14",
        "nepDate": "01.12.2026"
    },
    {
        "engDate": "1970-03-15",
        "nepDate": "02.12.2026"
    },
    {
        "engDate": "1970-03-16",
        "nepDate": "03.12.2026"
    },
    {
        "engDate": "1970-03-17",
        "nepDate": "04.12.2026"
    },
    {
        "engDate": "1970-03-18",
        "nepDate": "05.12.2026"
    },
    {
        "engDate": "1970-03-19",
        "nepDate": "06.12.2026"
    },
    {
        "engDate": "1970-03-20",
        "nepDate": "07.12.2026"
    },
    {
        "engDate": "1970-03-21",
        "nepDate": "08.12.2026"
    },
    {
        "engDate": "1970-03-22",
        "nepDate": "09.12.2026"
    },
    {
        "engDate": "1970-03-23",
        "nepDate": "10.12.2026"
    },
    {
        "engDate": "1970-03-24",
        "nepDate": "11.12.2026"
    },
    {
        "engDate": "1970-03-25",
        "nepDate": "12.12.2026"
    },
    {
        "engDate": "1970-03-26",
        "nepDate": "13.12.2026"
    },
    {
        "engDate": "1970-03-27",
        "nepDate": "14.12.2026"
    },
    {
        "engDate": "1970-03-28",
        "nepDate": "15.12.2026"
    },
    {
        "engDate": "1970-03-29",
        "nepDate": "16.12.2026"
    },
    {
        "engDate": "1970-03-30",
        "nepDate": "17.12.2026"
    },
    {
        "engDate": "1970-03-31",
        "nepDate": "18.12.2026"
    },
    {
        "engDate": "1970-04-01",
        "nepDate": "19.12.2026"
    },
    {
        "engDate": "1970-04-02",
        "nepDate": "20.12.2026"
    },
    {
        "engDate": "1970-04-03",
        "nepDate": "21.12.2026"
    },
    {
        "engDate": "1970-04-04",
        "nepDate": "22.12.2026"
    },
    {
        "engDate": "1970-04-05",
        "nepDate": "23.12.2026"
    },
    {
        "engDate": "1970-04-06",
        "nepDate": "24.12.2026"
    },
    {
        "engDate": "1970-04-07",
        "nepDate": "25.12.2026"
    },
    {
        "engDate": "1970-04-08",
        "nepDate": "26.12.2026"
    },
    {
        "engDate": "1970-04-09",
        "nepDate": "27.12.2026"
    },
    {
        "engDate": "1970-04-10",
        "nepDate": "28.12.2026"
    },
    {
        "engDate": "1970-04-11",
        "nepDate": "29.12.2026"
    },
    {
        "engDate": "1970-04-12",
        "nepDate": "30.12.2026"
    },
    {
        "engDate": "1970-04-13",
        "nepDate": "31.12.2026"
    },
    {
        "engDate": "1970-04-14",
        "nepDate": "01.01.2027"
    },
    {
        "engDate": "1970-04-15",
        "nepDate": "02.01.2027"
    },
    {
        "engDate": "1970-04-16",
        "nepDate": "03.01.2027"
    },
    {
        "engDate": "1970-04-17",
        "nepDate": "04.01.2027"
    },
    {
        "engDate": "1970-04-18",
        "nepDate": "05.01.2027"
    },
    {
        "engDate": "1970-04-19",
        "nepDate": "06.01.2027"
    },
    {
        "engDate": "1970-04-20",
        "nepDate": "07.01.2027"
    },
    {
        "engDate": "1970-04-21",
        "nepDate": "08.01.2027"
    },
    {
        "engDate": "1970-04-22",
        "nepDate": "09.01.2027"
    },
    {
        "engDate": "1970-04-23",
        "nepDate": "10.01.2027"
    },
    {
        "engDate": "1970-04-24",
        "nepDate": "11.01.2027"
    },
    {
        "engDate": "1970-04-25",
        "nepDate": "12.01.2027"
    },
    {
        "engDate": "1970-04-26",
        "nepDate": "13.01.2027"
    },
    {
        "engDate": "1970-04-27",
        "nepDate": "14.01.2027"
    },
    {
        "engDate": "1970-04-28",
        "nepDate": "15.01.2027"
    },
    {
        "engDate": "1970-04-29",
        "nepDate": "16.01.2027"
    },
    {
        "engDate": "1970-04-30",
        "nepDate": "17.01.2027"
    },
    {
        "engDate": "1970-05-01",
        "nepDate": "18.01.2027"
    },
    {
        "engDate": "1970-05-02",
        "nepDate": "19.01.2027"
    },
    {
        "engDate": "1970-05-03",
        "nepDate": "20.01.2027"
    },
    {
        "engDate": "1970-05-04",
        "nepDate": "21.01.2027"
    },
    {
        "engDate": "1970-05-05",
        "nepDate": "22.01.2027"
    },
    {
        "engDate": "1970-05-06",
        "nepDate": "23.01.2027"
    },
    {
        "engDate": "1970-05-07",
        "nepDate": "24.01.2027"
    },
    {
        "engDate": "1970-05-08",
        "nepDate": "25.01.2027"
    },
    {
        "engDate": "1970-05-09",
        "nepDate": "26.01.2027"
    },
    {
        "engDate": "1970-05-10",
        "nepDate": "27.01.2027"
    },
    {
        "engDate": "1970-05-11",
        "nepDate": "28.01.2027"
    },
    {
        "engDate": "1970-05-12",
        "nepDate": "29.01.2027"
    },
    {
        "engDate": "1970-05-13",
        "nepDate": "30.01.2027"
    },
    {
        "engDate": "1970-05-14",
        "nepDate": "01.02.2027"
    },
    {
        "engDate": "1970-05-15",
        "nepDate": "02.02.2027"
    },
    {
        "engDate": "1970-05-16",
        "nepDate": "03.02.2027"
    },
    {
        "engDate": "1970-05-17",
        "nepDate": "04.02.2027"
    },
    {
        "engDate": "1970-05-18",
        "nepDate": "05.02.2027"
    },
    {
        "engDate": "1970-05-19",
        "nepDate": "06.02.2027"
    },
    {
        "engDate": "1970-05-20",
        "nepDate": "07.02.2027"
    },
    {
        "engDate": "1970-05-21",
        "nepDate": "08.02.2027"
    },
    {
        "engDate": "1970-05-22",
        "nepDate": "09.02.2027"
    },
    {
        "engDate": "1970-05-23",
        "nepDate": "10.02.2027"
    },
    {
        "engDate": "1970-05-24",
        "nepDate": "11.02.2027"
    },
    {
        "engDate": "1970-05-25",
        "nepDate": "12.02.2027"
    },
    {
        "engDate": "1970-05-26",
        "nepDate": "13.02.2027"
    },
    {
        "engDate": "1970-05-27",
        "nepDate": "14.02.2027"
    },
    {
        "engDate": "1970-05-28",
        "nepDate": "15.02.2027"
    },
    {
        "engDate": "1970-05-29",
        "nepDate": "16.02.2027"
    },
    {
        "engDate": "1970-05-30",
        "nepDate": "17.02.2027"
    },
    {
        "engDate": "1970-05-31",
        "nepDate": "18.02.2027"
    },
    {
        "engDate": "1970-06-01",
        "nepDate": "19.02.2027"
    },
    {
        "engDate": "1970-06-02",
        "nepDate": "20.02.2027"
    },
    {
        "engDate": "1970-06-03",
        "nepDate": "21.02.2027"
    },
    {
        "engDate": "1970-06-04",
        "nepDate": "22.02.2027"
    },
    {
        "engDate": "1970-06-05",
        "nepDate": "23.02.2027"
    },
    {
        "engDate": "1970-06-06",
        "nepDate": "24.02.2027"
    },
    {
        "engDate": "1970-06-07",
        "nepDate": "25.02.2027"
    },
    {
        "engDate": "1970-06-08",
        "nepDate": "26.02.2027"
    },
    {
        "engDate": "1970-06-09",
        "nepDate": "27.02.2027"
    },
    {
        "engDate": "1970-06-10",
        "nepDate": "28.02.2027"
    },
    {
        "engDate": "1970-06-11",
        "nepDate": "29.02.2027"
    },
    {
        "engDate": "1970-06-12",
        "nepDate": "30.02.2027"
    },
    {
        "engDate": "1970-06-13",
        "nepDate": "31.02.2027"
    },
    {
        "engDate": "1970-06-14",
        "nepDate": "32.02.2027"
    },
    {
        "engDate": "1970-06-15",
        "nepDate": "01.03.2027"
    },
    {
        "engDate": "1970-06-16",
        "nepDate": "02.03.2027"
    },
    {
        "engDate": "1970-06-17",
        "nepDate": "03.03.2027"
    },
    {
        "engDate": "1970-06-18",
        "nepDate": "04.03.2027"
    },
    {
        "engDate": "1970-06-19",
        "nepDate": "05.03.2027"
    },
    {
        "engDate": "1970-06-20",
        "nepDate": "06.03.2027"
    },
    {
        "engDate": "1970-06-21",
        "nepDate": "07.03.2027"
    },
    {
        "engDate": "1970-06-22",
        "nepDate": "08.03.2027"
    },
    {
        "engDate": "1970-06-23",
        "nepDate": "09.03.2027"
    },
    {
        "engDate": "1970-06-24",
        "nepDate": "10.03.2027"
    },
    {
        "engDate": "1970-06-25",
        "nepDate": "11.03.2027"
    },
    {
        "engDate": "1970-06-26",
        "nepDate": "12.03.2027"
    },
    {
        "engDate": "1970-06-27",
        "nepDate": "13.03.2027"
    },
    {
        "engDate": "1970-06-28",
        "nepDate": "14.03.2027"
    },
    {
        "engDate": "1970-06-29",
        "nepDate": "15.03.2027"
    },
    {
        "engDate": "1970-06-30",
        "nepDate": "16.03.2027"
    },
    {
        "engDate": "1970-07-01",
        "nepDate": "17.03.2027"
    },
    {
        "engDate": "1970-07-02",
        "nepDate": "18.03.2027"
    },
    {
        "engDate": "1970-07-03",
        "nepDate": "19.03.2027"
    },
    {
        "engDate": "1970-07-04",
        "nepDate": "20.03.2027"
    },
    {
        "engDate": "1970-07-05",
        "nepDate": "21.03.2027"
    },
    {
        "engDate": "1970-07-06",
        "nepDate": "22.03.2027"
    },
    {
        "engDate": "1970-07-07",
        "nepDate": "23.03.2027"
    },
    {
        "engDate": "1970-07-08",
        "nepDate": "24.03.2027"
    },
    {
        "engDate": "1970-07-09",
        "nepDate": "25.03.2027"
    },
    {
        "engDate": "1970-07-10",
        "nepDate": "26.03.2027"
    },
    {
        "engDate": "1970-07-11",
        "nepDate": "27.03.2027"
    },
    {
        "engDate": "1970-07-12",
        "nepDate": "28.03.2027"
    },
    {
        "engDate": "1970-07-13",
        "nepDate": "29.03.2027"
    },
    {
        "engDate": "1970-07-14",
        "nepDate": "30.03.2027"
    },
    {
        "engDate": "1970-07-15",
        "nepDate": "31.03.2027"
    },
    {
        "engDate": "1970-07-16",
        "nepDate": "01.04.2027"
    },
    {
        "engDate": "1970-07-17",
        "nepDate": "02.04.2027"
    },
    {
        "engDate": "1970-07-18",
        "nepDate": "03.04.2027"
    },
    {
        "engDate": "1970-07-19",
        "nepDate": "04.04.2027"
    },
    {
        "engDate": "1970-07-20",
        "nepDate": "05.04.2027"
    },
    {
        "engDate": "1970-07-21",
        "nepDate": "06.04.2027"
    },
    {
        "engDate": "1970-07-22",
        "nepDate": "07.04.2027"
    },
    {
        "engDate": "1970-07-23",
        "nepDate": "08.04.2027"
    },
    {
        "engDate": "1970-07-24",
        "nepDate": "09.04.2027"
    },
    {
        "engDate": "1970-07-25",
        "nepDate": "10.04.2027"
    },
    {
        "engDate": "1970-07-26",
        "nepDate": "11.04.2027"
    },
    {
        "engDate": "1970-07-27",
        "nepDate": "12.04.2027"
    },
    {
        "engDate": "1970-07-28",
        "nepDate": "13.04.2027"
    },
    {
        "engDate": "1970-07-29",
        "nepDate": "14.04.2027"
    },
    {
        "engDate": "1970-07-30",
        "nepDate": "15.04.2027"
    },
    {
        "engDate": "1970-07-31",
        "nepDate": "16.04.2027"
    },
    {
        "engDate": "1970-08-01",
        "nepDate": "17.04.2027"
    },
    {
        "engDate": "1970-08-02",
        "nepDate": "18.04.2027"
    },
    {
        "engDate": "1970-08-03",
        "nepDate": "19.04.2027"
    },
    {
        "engDate": "1970-08-04",
        "nepDate": "20.04.2027"
    },
    {
        "engDate": "1970-08-05",
        "nepDate": "21.04.2027"
    },
    {
        "engDate": "1970-08-06",
        "nepDate": "22.04.2027"
    },
    {
        "engDate": "1970-08-07",
        "nepDate": "23.04.2027"
    },
    {
        "engDate": "1970-08-08",
        "nepDate": "24.04.2027"
    },
    {
        "engDate": "1970-08-09",
        "nepDate": "25.04.2027"
    },
    {
        "engDate": "1970-08-10",
        "nepDate": "26.04.2027"
    },
    {
        "engDate": "1970-08-11",
        "nepDate": "27.04.2027"
    },
    {
        "engDate": "1970-08-12",
        "nepDate": "28.04.2027"
    },
    {
        "engDate": "1970-08-13",
        "nepDate": "29.04.2027"
    },
    {
        "engDate": "1970-08-14",
        "nepDate": "30.04.2027"
    },
    {
        "engDate": "1970-08-15",
        "nepDate": "31.04.2027"
    },
    {
        "engDate": "1970-08-16",
        "nepDate": "32.04.2027"
    },
    {
        "engDate": "1970-08-17",
        "nepDate": "01.05.2027"
    },
    {
        "engDate": "1970-08-18",
        "nepDate": "02.05.2027"
    },
    {
        "engDate": "1970-08-19",
        "nepDate": "03.05.2027"
    },
    {
        "engDate": "1970-08-20",
        "nepDate": "04.05.2027"
    },
    {
        "engDate": "1970-08-21",
        "nepDate": "05.05.2027"
    },
    {
        "engDate": "1970-08-22",
        "nepDate": "06.05.2027"
    },
    {
        "engDate": "1970-08-23",
        "nepDate": "07.05.2027"
    },
    {
        "engDate": "1970-08-24",
        "nepDate": "08.05.2027"
    },
    {
        "engDate": "1970-08-25",
        "nepDate": "09.05.2027"
    },
    {
        "engDate": "1970-08-26",
        "nepDate": "10.05.2027"
    },
    {
        "engDate": "1970-08-27",
        "nepDate": "11.05.2027"
    },
    {
        "engDate": "1970-08-28",
        "nepDate": "12.05.2027"
    },
    {
        "engDate": "1970-08-29",
        "nepDate": "13.05.2027"
    },
    {
        "engDate": "1970-08-30",
        "nepDate": "14.05.2027"
    },
    {
        "engDate": "1970-08-31",
        "nepDate": "15.05.2027"
    },
    {
        "engDate": "1970-09-01",
        "nepDate": "16.05.2027"
    },
    {
        "engDate": "1970-09-02",
        "nepDate": "17.05.2027"
    },
    {
        "engDate": "1970-09-03",
        "nepDate": "18.05.2027"
    },
    {
        "engDate": "1970-09-04",
        "nepDate": "19.05.2027"
    },
    {
        "engDate": "1970-09-05",
        "nepDate": "20.05.2027"
    },
    {
        "engDate": "1970-09-06",
        "nepDate": "21.05.2027"
    },
    {
        "engDate": "1970-09-07",
        "nepDate": "22.05.2027"
    },
    {
        "engDate": "1970-09-08",
        "nepDate": "23.05.2027"
    },
    {
        "engDate": "1970-09-09",
        "nepDate": "24.05.2027"
    },
    {
        "engDate": "1970-09-10",
        "nepDate": "25.05.2027"
    },
    {
        "engDate": "1970-09-11",
        "nepDate": "26.05.2027"
    },
    {
        "engDate": "1970-09-12",
        "nepDate": "27.05.2027"
    },
    {
        "engDate": "1970-09-13",
        "nepDate": "28.05.2027"
    },
    {
        "engDate": "1970-09-14",
        "nepDate": "29.05.2027"
    },
    {
        "engDate": "1970-09-15",
        "nepDate": "30.05.2027"
    },
    {
        "engDate": "1970-09-16",
        "nepDate": "31.05.2027"
    },
    {
        "engDate": "1970-09-17",
        "nepDate": "01.06.2027"
    },
    {
        "engDate": "1970-09-18",
        "nepDate": "02.06.2027"
    },
    {
        "engDate": "1970-09-19",
        "nepDate": "03.06.2027"
    },
    {
        "engDate": "1970-09-20",
        "nepDate": "04.06.2027"
    },
    {
        "engDate": "1970-09-21",
        "nepDate": "05.06.2027"
    },
    {
        "engDate": "1970-09-22",
        "nepDate": "06.06.2027"
    },
    {
        "engDate": "1970-09-23",
        "nepDate": "07.06.2027"
    },
    {
        "engDate": "1970-09-24",
        "nepDate": "08.06.2027"
    },
    {
        "engDate": "1970-09-25",
        "nepDate": "09.06.2027"
    },
    {
        "engDate": "1970-09-26",
        "nepDate": "10.06.2027"
    },
    {
        "engDate": "1970-09-27",
        "nepDate": "11.06.2027"
    },
    {
        "engDate": "1970-09-28",
        "nepDate": "12.06.2027"
    },
    {
        "engDate": "1970-09-29",
        "nepDate": "13.06.2027"
    },
    {
        "engDate": "1970-09-30",
        "nepDate": "14.06.2027"
    },
    {
        "engDate": "1970-10-01",
        "nepDate": "15.06.2027"
    },
    {
        "engDate": "1970-10-02",
        "nepDate": "16.06.2027"
    },
    {
        "engDate": "1970-10-03",
        "nepDate": "17.06.2027"
    },
    {
        "engDate": "1970-10-04",
        "nepDate": "18.06.2027"
    },
    {
        "engDate": "1970-10-05",
        "nepDate": "19.06.2027"
    },
    {
        "engDate": "1970-10-06",
        "nepDate": "20.06.2027"
    },
    {
        "engDate": "1970-10-07",
        "nepDate": "21.06.2027"
    },
    {
        "engDate": "1970-10-08",
        "nepDate": "22.06.2027"
    },
    {
        "engDate": "1970-10-09",
        "nepDate": "23.06.2027"
    },
    {
        "engDate": "1970-10-10",
        "nepDate": "24.06.2027"
    },
    {
        "engDate": "1970-10-11",
        "nepDate": "25.06.2027"
    },
    {
        "engDate": "1970-10-12",
        "nepDate": "26.06.2027"
    },
    {
        "engDate": "1970-10-13",
        "nepDate": "27.06.2027"
    },
    {
        "engDate": "1970-10-14",
        "nepDate": "28.06.2027"
    },
    {
        "engDate": "1970-10-15",
        "nepDate": "29.06.2027"
    },
    {
        "engDate": "1970-10-16",
        "nepDate": "30.06.2027"
    },
    {
        "engDate": "1970-10-17",
        "nepDate": "01.07.2027"
    },
    {
        "engDate": "1970-10-18",
        "nepDate": "02.07.2027"
    },
    {
        "engDate": "1970-10-19",
        "nepDate": "03.07.2027"
    },
    {
        "engDate": "1970-10-20",
        "nepDate": "04.07.2027"
    },
    {
        "engDate": "1970-10-21",
        "nepDate": "05.07.2027"
    },
    {
        "engDate": "1970-10-22",
        "nepDate": "06.07.2027"
    },
    {
        "engDate": "1970-10-23",
        "nepDate": "07.07.2027"
    },
    {
        "engDate": "1970-10-24",
        "nepDate": "08.07.2027"
    },
    {
        "engDate": "1970-10-25",
        "nepDate": "09.07.2027"
    },
    {
        "engDate": "1970-10-26",
        "nepDate": "10.07.2027"
    },
    {
        "engDate": "1970-10-27",
        "nepDate": "11.07.2027"
    },
    {
        "engDate": "1970-10-28",
        "nepDate": "12.07.2027"
    },
    {
        "engDate": "1970-10-29",
        "nepDate": "13.07.2027"
    },
    {
        "engDate": "1970-10-30",
        "nepDate": "14.07.2027"
    },
    {
        "engDate": "1970-10-31",
        "nepDate": "15.07.2027"
    },
    {
        "engDate": "1970-11-01",
        "nepDate": "16.07.2027"
    },
    {
        "engDate": "1970-11-02",
        "nepDate": "17.07.2027"
    },
    {
        "engDate": "1970-11-03",
        "nepDate": "18.07.2027"
    },
    {
        "engDate": "1970-11-04",
        "nepDate": "19.07.2027"
    },
    {
        "engDate": "1970-11-05",
        "nepDate": "20.07.2027"
    },
    {
        "engDate": "1970-11-06",
        "nepDate": "21.07.2027"
    },
    {
        "engDate": "1970-11-07",
        "nepDate": "22.07.2027"
    },
    {
        "engDate": "1970-11-08",
        "nepDate": "23.07.2027"
    },
    {
        "engDate": "1970-11-09",
        "nepDate": "24.07.2027"
    },
    {
        "engDate": "1970-11-10",
        "nepDate": "25.07.2027"
    },
    {
        "engDate": "1970-11-11",
        "nepDate": "26.07.2027"
    },
    {
        "engDate": "1970-11-12",
        "nepDate": "27.07.2027"
    },
    {
        "engDate": "1970-11-13",
        "nepDate": "28.07.2027"
    },
    {
        "engDate": "1970-11-14",
        "nepDate": "29.07.2027"
    },
    {
        "engDate": "1970-11-15",
        "nepDate": "30.07.2027"
    },
    {
        "engDate": "1970-11-16",
        "nepDate": "01.08.2027"
    },
    {
        "engDate": "1970-11-17",
        "nepDate": "02.08.2027"
    },
    {
        "engDate": "1970-11-18",
        "nepDate": "03.08.2027"
    },
    {
        "engDate": "1970-11-19",
        "nepDate": "04.08.2027"
    },
    {
        "engDate": "1970-11-20",
        "nepDate": "05.08.2027"
    },
    {
        "engDate": "1970-11-21",
        "nepDate": "06.08.2027"
    },
    {
        "engDate": "1970-11-22",
        "nepDate": "07.08.2027"
    },
    {
        "engDate": "1970-11-23",
        "nepDate": "08.08.2027"
    },
    {
        "engDate": "1970-11-24",
        "nepDate": "09.08.2027"
    },
    {
        "engDate": "1970-11-25",
        "nepDate": "10.08.2027"
    },
    {
        "engDate": "1970-11-26",
        "nepDate": "11.08.2027"
    },
    {
        "engDate": "1970-11-27",
        "nepDate": "12.08.2027"
    },
    {
        "engDate": "1970-11-28",
        "nepDate": "13.08.2027"
    },
    {
        "engDate": "1970-11-29",
        "nepDate": "14.08.2027"
    },
    {
        "engDate": "1970-11-30",
        "nepDate": "15.08.2027"
    },
    {
        "engDate": "1970-12-01",
        "nepDate": "16.08.2027"
    },
    {
        "engDate": "1970-12-02",
        "nepDate": "17.08.2027"
    },
    {
        "engDate": "1970-12-03",
        "nepDate": "18.08.2027"
    },
    {
        "engDate": "1970-12-04",
        "nepDate": "19.08.2027"
    },
    {
        "engDate": "1970-12-05",
        "nepDate": "20.08.2027"
    },
    {
        "engDate": "1970-12-06",
        "nepDate": "21.08.2027"
    },
    {
        "engDate": "1970-12-07",
        "nepDate": "22.08.2027"
    },
    {
        "engDate": "1970-12-08",
        "nepDate": "23.08.2027"
    },
    {
        "engDate": "1970-12-09",
        "nepDate": "24.08.2027"
    },
    {
        "engDate": "1970-12-10",
        "nepDate": "25.08.2027"
    },
    {
        "engDate": "1970-12-11",
        "nepDate": "26.08.2027"
    },
    {
        "engDate": "1970-12-12",
        "nepDate": "27.08.2027"
    },
    {
        "engDate": "1970-12-13",
        "nepDate": "28.08.2027"
    },
    {
        "engDate": "1970-12-14",
        "nepDate": "29.08.2027"
    },
    {
        "engDate": "1970-12-15",
        "nepDate": "30.08.2027"
    },
    {
        "engDate": "1970-12-16",
        "nepDate": "01.09.2027"
    },
    {
        "engDate": "1970-12-17",
        "nepDate": "02.09.2027"
    },
    {
        "engDate": "1970-12-18",
        "nepDate": "03.09.2027"
    },
    {
        "engDate": "1970-12-19",
        "nepDate": "04.09.2027"
    },
    {
        "engDate": "1970-12-20",
        "nepDate": "05.09.2027"
    },
    {
        "engDate": "1970-12-21",
        "nepDate": "06.09.2027"
    },
    {
        "engDate": "1970-12-22",
        "nepDate": "07.09.2027"
    },
    {
        "engDate": "1970-12-23",
        "nepDate": "08.09.2027"
    },
    {
        "engDate": "1970-12-24",
        "nepDate": "09.09.2027"
    },
    {
        "engDate": "1970-12-25",
        "nepDate": "10.09.2027"
    },
    {
        "engDate": "1970-12-26",
        "nepDate": "11.09.2027"
    },
    {
        "engDate": "1970-12-27",
        "nepDate": "12.09.2027"
    },
    {
        "engDate": "1970-12-28",
        "nepDate": "13.09.2027"
    },
    {
        "engDate": "1970-12-29",
        "nepDate": "14.09.2027"
    },
    {
        "engDate": "1970-12-30",
        "nepDate": "15.09.2027"
    },
    {
        "engDate": "1970-12-31",
        "nepDate": "16.09.2027"
    },
    {
        "engDate": "1971-01-01",
        "nepDate": "17.09.2027"
    },
    {
        "engDate": "1971-01-02",
        "nepDate": "18.09.2027"
    },
    {
        "engDate": "1971-01-03",
        "nepDate": "19.09.2027"
    },
    {
        "engDate": "1971-01-04",
        "nepDate": "20.09.2027"
    },
    {
        "engDate": "1971-01-05",
        "nepDate": "21.09.2027"
    },
    {
        "engDate": "1971-01-06",
        "nepDate": "22.09.2027"
    },
    {
        "engDate": "1971-01-07",
        "nepDate": "23.09.2027"
    },
    {
        "engDate": "1971-01-08",
        "nepDate": "24.09.2027"
    },
    {
        "engDate": "1971-01-09",
        "nepDate": "25.09.2027"
    },
    {
        "engDate": "1971-01-10",
        "nepDate": "26.09.2027"
    },
    {
        "engDate": "1971-01-11",
        "nepDate": "27.09.2027"
    },
    {
        "engDate": "1971-01-12",
        "nepDate": "28.09.2027"
    },
    {
        "engDate": "1971-01-13",
        "nepDate": "29.09.2027"
    },
    {
        "engDate": "1971-01-14",
        "nepDate": "01.10.2027"
    },
    {
        "engDate": "1971-01-15",
        "nepDate": "02.10.2027"
    },
    {
        "engDate": "1971-01-16",
        "nepDate": "03.10.2027"
    },
    {
        "engDate": "1971-01-17",
        "nepDate": "04.10.2027"
    },
    {
        "engDate": "1971-01-18",
        "nepDate": "05.10.2027"
    },
    {
        "engDate": "1971-01-19",
        "nepDate": "06.10.2027"
    },
    {
        "engDate": "1971-01-20",
        "nepDate": "07.10.2027"
    },
    {
        "engDate": "1971-01-21",
        "nepDate": "08.10.2027"
    },
    {
        "engDate": "1971-01-22",
        "nepDate": "09.10.2027"
    },
    {
        "engDate": "1971-01-23",
        "nepDate": "10.10.2027"
    },
    {
        "engDate": "1971-01-24",
        "nepDate": "11.10.2027"
    },
    {
        "engDate": "1971-01-25",
        "nepDate": "12.10.2027"
    },
    {
        "engDate": "1971-01-26",
        "nepDate": "13.10.2027"
    },
    {
        "engDate": "1971-01-27",
        "nepDate": "14.10.2027"
    },
    {
        "engDate": "1971-01-28",
        "nepDate": "15.10.2027"
    },
    {
        "engDate": "1971-01-29",
        "nepDate": "16.10.2027"
    },
    {
        "engDate": "1971-01-30",
        "nepDate": "17.10.2027"
    },
    {
        "engDate": "1971-01-31",
        "nepDate": "18.10.2027"
    },
    {
        "engDate": "1971-02-01",
        "nepDate": "19.10.2027"
    },
    {
        "engDate": "1971-02-02",
        "nepDate": "20.10.2027"
    },
    {
        "engDate": "1971-02-03",
        "nepDate": "21.10.2027"
    },
    {
        "engDate": "1971-02-04",
        "nepDate": "22.10.2027"
    },
    {
        "engDate": "1971-02-05",
        "nepDate": "23.10.2027"
    },
    {
        "engDate": "1971-02-06",
        "nepDate": "24.10.2027"
    },
    {
        "engDate": "1971-02-07",
        "nepDate": "25.10.2027"
    },
    {
        "engDate": "1971-02-08",
        "nepDate": "26.10.2027"
    },
    {
        "engDate": "1971-02-09",
        "nepDate": "27.10.2027"
    },
    {
        "engDate": "1971-02-10",
        "nepDate": "28.10.2027"
    },
    {
        "engDate": "1971-02-11",
        "nepDate": "29.10.2027"
    },
    {
        "engDate": "1971-02-12",
        "nepDate": "30.10.2027"
    },
    {
        "engDate": "1971-02-13",
        "nepDate": "01.11.2027"
    },
    {
        "engDate": "1971-02-14",
        "nepDate": "02.11.2027"
    },
    {
        "engDate": "1971-02-15",
        "nepDate": "03.11.2027"
    },
    {
        "engDate": "1971-02-16",
        "nepDate": "04.11.2027"
    },
    {
        "engDate": "1971-02-17",
        "nepDate": "05.11.2027"
    },
    {
        "engDate": "1971-02-18",
        "nepDate": "06.11.2027"
    },
    {
        "engDate": "1971-02-19",
        "nepDate": "07.11.2027"
    },
    {
        "engDate": "1971-02-20",
        "nepDate": "08.11.2027"
    },
    {
        "engDate": "1971-02-21",
        "nepDate": "09.11.2027"
    },
    {
        "engDate": "1971-02-22",
        "nepDate": "10.11.2027"
    },
    {
        "engDate": "1971-02-23",
        "nepDate": "11.11.2027"
    },
    {
        "engDate": "1971-02-24",
        "nepDate": "12.11.2027"
    },
    {
        "engDate": "1971-02-25",
        "nepDate": "13.11.2027"
    },
    {
        "engDate": "1971-02-26",
        "nepDate": "14.11.2027"
    },
    {
        "engDate": "1971-02-27",
        "nepDate": "15.11.2027"
    },
    {
        "engDate": "1971-02-28",
        "nepDate": "16.11.2027"
    },
    {
        "engDate": "1971-03-01",
        "nepDate": "17.11.2027"
    },
    {
        "engDate": "1971-03-02",
        "nepDate": "18.11.2027"
    },
    {
        "engDate": "1971-03-03",
        "nepDate": "19.11.2027"
    },
    {
        "engDate": "1971-03-04",
        "nepDate": "20.11.2027"
    },
    {
        "engDate": "1971-03-05",
        "nepDate": "21.11.2027"
    },
    {
        "engDate": "1971-03-06",
        "nepDate": "22.11.2027"
    },
    {
        "engDate": "1971-03-07",
        "nepDate": "23.11.2027"
    },
    {
        "engDate": "1971-03-08",
        "nepDate": "24.11.2027"
    },
    {
        "engDate": "1971-03-09",
        "nepDate": "25.11.2027"
    },
    {
        "engDate": "1971-03-10",
        "nepDate": "26.11.2027"
    },
    {
        "engDate": "1971-03-11",
        "nepDate": "27.11.2027"
    },
    {
        "engDate": "1971-03-12",
        "nepDate": "28.11.2027"
    },
    {
        "engDate": "1971-03-13",
        "nepDate": "29.11.2027"
    },
    {
        "engDate": "1971-03-14",
        "nepDate": "01.12.2027"
    },
    {
        "engDate": "1971-03-15",
        "nepDate": "02.12.2027"
    },
    {
        "engDate": "1971-03-16",
        "nepDate": "03.12.2027"
    },
    {
        "engDate": "1971-03-17",
        "nepDate": "04.12.2027"
    },
    {
        "engDate": "1971-03-18",
        "nepDate": "05.12.2027"
    },
    {
        "engDate": "1971-03-19",
        "nepDate": "06.12.2027"
    },
    {
        "engDate": "1971-03-20",
        "nepDate": "07.12.2027"
    },
    {
        "engDate": "1971-03-21",
        "nepDate": "08.12.2027"
    },
    {
        "engDate": "1971-03-22",
        "nepDate": "09.12.2027"
    },
    {
        "engDate": "1971-03-23",
        "nepDate": "10.12.2027"
    },
    {
        "engDate": "1971-03-24",
        "nepDate": "11.12.2027"
    },
    {
        "engDate": "1971-03-25",
        "nepDate": "12.12.2027"
    },
    {
        "engDate": "1971-03-26",
        "nepDate": "13.12.2027"
    },
    {
        "engDate": "1971-03-27",
        "nepDate": "14.12.2027"
    },
    {
        "engDate": "1971-03-28",
        "nepDate": "15.12.2027"
    },
    {
        "engDate": "1971-03-29",
        "nepDate": "16.12.2027"
    },
    {
        "engDate": "1971-03-30",
        "nepDate": "17.12.2027"
    },
    {
        "engDate": "1971-03-31",
        "nepDate": "18.12.2027"
    },
    {
        "engDate": "1971-04-01",
        "nepDate": "19.12.2027"
    },
    {
        "engDate": "1971-04-02",
        "nepDate": "20.12.2027"
    },
    {
        "engDate": "1971-04-03",
        "nepDate": "21.12.2027"
    },
    {
        "engDate": "1971-04-04",
        "nepDate": "22.12.2027"
    },
    {
        "engDate": "1971-04-05",
        "nepDate": "23.12.2027"
    },
    {
        "engDate": "1971-04-06",
        "nepDate": "24.12.2027"
    },
    {
        "engDate": "1971-04-07",
        "nepDate": "25.12.2027"
    },
    {
        "engDate": "1971-04-08",
        "nepDate": "26.12.2027"
    },
    {
        "engDate": "1971-04-09",
        "nepDate": "27.12.2027"
    },
    {
        "engDate": "1971-04-10",
        "nepDate": "28.12.2027"
    },
    {
        "engDate": "1971-04-11",
        "nepDate": "29.12.2027"
    },
    {
        "engDate": "1971-04-12",
        "nepDate": "30.12.2027"
    },
    {
        "engDate": "1971-04-13",
        "nepDate": "31.12.2027"
    },
    {
        "engDate": "1971-04-14",
        "nepDate": "01.01.2028"
    },
    {
        "engDate": "1971-04-15",
        "nepDate": "02.01.2028"
    },
    {
        "engDate": "1971-04-16",
        "nepDate": "03.01.2028"
    },
    {
        "engDate": "1971-04-17",
        "nepDate": "04.01.2028"
    },
    {
        "engDate": "1971-04-18",
        "nepDate": "05.01.2028"
    },
    {
        "engDate": "1971-04-19",
        "nepDate": "06.01.2028"
    },
    {
        "engDate": "1971-04-20",
        "nepDate": "07.01.2028"
    },
    {
        "engDate": "1971-04-21",
        "nepDate": "08.01.2028"
    },
    {
        "engDate": "1971-04-22",
        "nepDate": "09.01.2028"
    },
    {
        "engDate": "1971-04-23",
        "nepDate": "10.01.2028"
    },
    {
        "engDate": "1971-04-24",
        "nepDate": "11.01.2028"
    },
    {
        "engDate": "1971-04-25",
        "nepDate": "12.01.2028"
    },
    {
        "engDate": "1971-04-26",
        "nepDate": "13.01.2028"
    },
    {
        "engDate": "1971-04-27",
        "nepDate": "14.01.2028"
    },
    {
        "engDate": "1971-04-28",
        "nepDate": "15.01.2028"
    },
    {
        "engDate": "1971-04-29",
        "nepDate": "16.01.2028"
    },
    {
        "engDate": "1971-04-30",
        "nepDate": "17.01.2028"
    },
    {
        "engDate": "1971-05-01",
        "nepDate": "18.01.2028"
    },
    {
        "engDate": "1971-05-02",
        "nepDate": "19.01.2028"
    },
    {
        "engDate": "1971-05-03",
        "nepDate": "20.01.2028"
    },
    {
        "engDate": "1971-05-04",
        "nepDate": "21.01.2028"
    },
    {
        "engDate": "1971-05-05",
        "nepDate": "22.01.2028"
    },
    {
        "engDate": "1971-05-06",
        "nepDate": "23.01.2028"
    },
    {
        "engDate": "1971-05-07",
        "nepDate": "24.01.2028"
    },
    {
        "engDate": "1971-05-08",
        "nepDate": "25.01.2028"
    },
    {
        "engDate": "1971-05-09",
        "nepDate": "26.01.2028"
    },
    {
        "engDate": "1971-05-10",
        "nepDate": "27.01.2028"
    },
    {
        "engDate": "1971-05-11",
        "nepDate": "28.01.2028"
    },
    {
        "engDate": "1971-05-12",
        "nepDate": "29.01.2028"
    },
    {
        "engDate": "1971-05-13",
        "nepDate": "30.01.2028"
    },
    {
        "engDate": "1971-05-14",
        "nepDate": "31.01.2028"
    },
    {
        "engDate": "1971-05-15",
        "nepDate": "01.02.2028"
    },
    {
        "engDate": "1971-05-16",
        "nepDate": "02.02.2028"
    },
    {
        "engDate": "1971-05-17",
        "nepDate": "03.02.2028"
    },
    {
        "engDate": "1971-05-18",
        "nepDate": "04.02.2028"
    },
    {
        "engDate": "1971-05-19",
        "nepDate": "05.02.2028"
    },
    {
        "engDate": "1971-05-20",
        "nepDate": "06.02.2028"
    },
    {
        "engDate": "1971-05-21",
        "nepDate": "07.02.2028"
    },
    {
        "engDate": "1971-05-22",
        "nepDate": "08.02.2028"
    },
    {
        "engDate": "1971-05-23",
        "nepDate": "09.02.2028"
    },
    {
        "engDate": "1971-05-24",
        "nepDate": "10.02.2028"
    },
    {
        "engDate": "1971-05-25",
        "nepDate": "11.02.2028"
    },
    {
        "engDate": "1971-05-26",
        "nepDate": "12.02.2028"
    },
    {
        "engDate": "1971-05-27",
        "nepDate": "13.02.2028"
    },
    {
        "engDate": "1971-05-28",
        "nepDate": "14.02.2028"
    },
    {
        "engDate": "1971-05-29",
        "nepDate": "15.02.2028"
    },
    {
        "engDate": "1971-05-30",
        "nepDate": "16.02.2028"
    },
    {
        "engDate": "1971-05-31",
        "nepDate": "17.02.2028"
    },
    {
        "engDate": "1971-06-01",
        "nepDate": "18.02.2028"
    },
    {
        "engDate": "1971-06-02",
        "nepDate": "19.02.2028"
    },
    {
        "engDate": "1971-06-03",
        "nepDate": "20.02.2028"
    },
    {
        "engDate": "1971-06-04",
        "nepDate": "21.02.2028"
    },
    {
        "engDate": "1971-06-05",
        "nepDate": "22.02.2028"
    },
    {
        "engDate": "1971-06-06",
        "nepDate": "23.02.2028"
    },
    {
        "engDate": "1971-06-07",
        "nepDate": "24.02.2028"
    },
    {
        "engDate": "1971-06-08",
        "nepDate": "25.02.2028"
    },
    {
        "engDate": "1971-06-09",
        "nepDate": "26.02.2028"
    },
    {
        "engDate": "1971-06-10",
        "nepDate": "27.02.2028"
    },
    {
        "engDate": "1971-06-11",
        "nepDate": "28.02.2028"
    },
    {
        "engDate": "1971-06-12",
        "nepDate": "29.02.2028"
    },
    {
        "engDate": "1971-06-13",
        "nepDate": "30.02.2028"
    },
    {
        "engDate": "1971-06-14",
        "nepDate": "31.02.2028"
    },
    {
        "engDate": "1971-06-15",
        "nepDate": "01.03.2028"
    },
    {
        "engDate": "1971-06-16",
        "nepDate": "02.03.2028"
    },
    {
        "engDate": "1971-06-17",
        "nepDate": "03.03.2028"
    },
    {
        "engDate": "1971-06-18",
        "nepDate": "04.03.2028"
    },
    {
        "engDate": "1971-06-19",
        "nepDate": "05.03.2028"
    },
    {
        "engDate": "1971-06-20",
        "nepDate": "06.03.2028"
    },
    {
        "engDate": "1971-06-21",
        "nepDate": "07.03.2028"
    },
    {
        "engDate": "1971-06-22",
        "nepDate": "08.03.2028"
    },
    {
        "engDate": "1971-06-23",
        "nepDate": "09.03.2028"
    },
    {
        "engDate": "1971-06-24",
        "nepDate": "10.03.2028"
    },
    {
        "engDate": "1971-06-25",
        "nepDate": "11.03.2028"
    },
    {
        "engDate": "1971-06-26",
        "nepDate": "12.03.2028"
    },
    {
        "engDate": "1971-06-27",
        "nepDate": "13.03.2028"
    },
    {
        "engDate": "1971-06-28",
        "nepDate": "14.03.2028"
    },
    {
        "engDate": "1971-06-29",
        "nepDate": "15.03.2028"
    },
    {
        "engDate": "1971-06-30",
        "nepDate": "16.03.2028"
    },
    {
        "engDate": "1971-07-01",
        "nepDate": "17.03.2028"
    },
    {
        "engDate": "1971-07-02",
        "nepDate": "18.03.2028"
    },
    {
        "engDate": "1971-07-03",
        "nepDate": "19.03.2028"
    },
    {
        "engDate": "1971-07-04",
        "nepDate": "20.03.2028"
    },
    {
        "engDate": "1971-07-05",
        "nepDate": "21.03.2028"
    },
    {
        "engDate": "1971-07-06",
        "nepDate": "22.03.2028"
    },
    {
        "engDate": "1971-07-07",
        "nepDate": "23.03.2028"
    },
    {
        "engDate": "1971-07-08",
        "nepDate": "24.03.2028"
    },
    {
        "engDate": "1971-07-09",
        "nepDate": "25.03.2028"
    },
    {
        "engDate": "1971-07-10",
        "nepDate": "26.03.2028"
    },
    {
        "engDate": "1971-07-11",
        "nepDate": "27.03.2028"
    },
    {
        "engDate": "1971-07-12",
        "nepDate": "28.03.2028"
    },
    {
        "engDate": "1971-07-13",
        "nepDate": "29.03.2028"
    },
    {
        "engDate": "1971-07-14",
        "nepDate": "30.03.2028"
    },
    {
        "engDate": "1971-07-15",
        "nepDate": "31.03.2028"
    },
    {
        "engDate": "1971-07-16",
        "nepDate": "32.03.2028"
    },
    {
        "engDate": "1971-07-17",
        "nepDate": "01.04.2028"
    },
    {
        "engDate": "1971-07-18",
        "nepDate": "02.04.2028"
    },
    {
        "engDate": "1971-07-19",
        "nepDate": "03.04.2028"
    },
    {
        "engDate": "1971-07-20",
        "nepDate": "04.04.2028"
    },
    {
        "engDate": "1971-07-21",
        "nepDate": "05.04.2028"
    },
    {
        "engDate": "1971-07-22",
        "nepDate": "06.04.2028"
    },
    {
        "engDate": "1971-07-23",
        "nepDate": "07.04.2028"
    },
    {
        "engDate": "1971-07-24",
        "nepDate": "08.04.2028"
    },
    {
        "engDate": "1971-07-25",
        "nepDate": "09.04.2028"
    },
    {
        "engDate": "1971-07-26",
        "nepDate": "10.04.2028"
    },
    {
        "engDate": "1971-07-27",
        "nepDate": "11.04.2028"
    },
    {
        "engDate": "1971-07-28",
        "nepDate": "12.04.2028"
    },
    {
        "engDate": "1971-07-29",
        "nepDate": "13.04.2028"
    },
    {
        "engDate": "1971-07-30",
        "nepDate": "14.04.2028"
    },
    {
        "engDate": "1971-07-31",
        "nepDate": "15.04.2028"
    },
    {
        "engDate": "1971-08-01",
        "nepDate": "16.04.2028"
    },
    {
        "engDate": "1971-08-02",
        "nepDate": "17.04.2028"
    },
    {
        "engDate": "1971-08-03",
        "nepDate": "18.04.2028"
    },
    {
        "engDate": "1971-08-04",
        "nepDate": "19.04.2028"
    },
    {
        "engDate": "1971-08-05",
        "nepDate": "20.04.2028"
    },
    {
        "engDate": "1971-08-06",
        "nepDate": "21.04.2028"
    },
    {
        "engDate": "1971-08-07",
        "nepDate": "22.04.2028"
    },
    {
        "engDate": "1971-08-08",
        "nepDate": "23.04.2028"
    },
    {
        "engDate": "1971-08-09",
        "nepDate": "24.04.2028"
    },
    {
        "engDate": "1971-08-10",
        "nepDate": "25.04.2028"
    },
    {
        "engDate": "1971-08-11",
        "nepDate": "26.04.2028"
    },
    {
        "engDate": "1971-08-12",
        "nepDate": "27.04.2028"
    },
    {
        "engDate": "1971-08-13",
        "nepDate": "28.04.2028"
    },
    {
        "engDate": "1971-08-14",
        "nepDate": "29.04.2028"
    },
    {
        "engDate": "1971-08-15",
        "nepDate": "30.04.2028"
    },
    {
        "engDate": "1971-08-16",
        "nepDate": "31.04.2028"
    },
    {
        "engDate": "1971-08-17",
        "nepDate": "01.05.2028"
    },
    {
        "engDate": "1971-08-18",
        "nepDate": "02.05.2028"
    },
    {
        "engDate": "1971-08-19",
        "nepDate": "03.05.2028"
    },
    {
        "engDate": "1971-08-20",
        "nepDate": "04.05.2028"
    },
    {
        "engDate": "1971-08-21",
        "nepDate": "05.05.2028"
    },
    {
        "engDate": "1971-08-22",
        "nepDate": "06.05.2028"
    },
    {
        "engDate": "1971-08-23",
        "nepDate": "07.05.2028"
    },
    {
        "engDate": "1971-08-24",
        "nepDate": "08.05.2028"
    },
    {
        "engDate": "1971-08-25",
        "nepDate": "09.05.2028"
    },
    {
        "engDate": "1971-08-26",
        "nepDate": "10.05.2028"
    },
    {
        "engDate": "1971-08-27",
        "nepDate": "11.05.2028"
    },
    {
        "engDate": "1971-08-28",
        "nepDate": "12.05.2028"
    },
    {
        "engDate": "1971-08-29",
        "nepDate": "13.05.2028"
    },
    {
        "engDate": "1971-08-30",
        "nepDate": "14.05.2028"
    },
    {
        "engDate": "1971-08-31",
        "nepDate": "15.05.2028"
    },
    {
        "engDate": "1971-09-01",
        "nepDate": "16.05.2028"
    },
    {
        "engDate": "1971-09-02",
        "nepDate": "17.05.2028"
    },
    {
        "engDate": "1971-09-03",
        "nepDate": "18.05.2028"
    },
    {
        "engDate": "1971-09-04",
        "nepDate": "19.05.2028"
    },
    {
        "engDate": "1971-09-05",
        "nepDate": "20.05.2028"
    },
    {
        "engDate": "1971-09-06",
        "nepDate": "21.05.2028"
    },
    {
        "engDate": "1971-09-07",
        "nepDate": "22.05.2028"
    },
    {
        "engDate": "1971-09-08",
        "nepDate": "23.05.2028"
    },
    {
        "engDate": "1971-09-09",
        "nepDate": "24.05.2028"
    },
    {
        "engDate": "1971-09-10",
        "nepDate": "25.05.2028"
    },
    {
        "engDate": "1971-09-11",
        "nepDate": "26.05.2028"
    },
    {
        "engDate": "1971-09-12",
        "nepDate": "27.05.2028"
    },
    {
        "engDate": "1971-09-13",
        "nepDate": "28.05.2028"
    },
    {
        "engDate": "1971-09-14",
        "nepDate": "29.05.2028"
    },
    {
        "engDate": "1971-09-15",
        "nepDate": "30.05.2028"
    },
    {
        "engDate": "1971-09-16",
        "nepDate": "31.05.2028"
    },
    {
        "engDate": "1971-09-17",
        "nepDate": "01.06.2028"
    },
    {
        "engDate": "1971-09-18",
        "nepDate": "02.06.2028"
    },
    {
        "engDate": "1971-09-19",
        "nepDate": "03.06.2028"
    },
    {
        "engDate": "1971-09-20",
        "nepDate": "04.06.2028"
    },
    {
        "engDate": "1971-09-21",
        "nepDate": "05.06.2028"
    },
    {
        "engDate": "1971-09-22",
        "nepDate": "06.06.2028"
    },
    {
        "engDate": "1971-09-23",
        "nepDate": "07.06.2028"
    },
    {
        "engDate": "1971-09-24",
        "nepDate": "08.06.2028"
    },
    {
        "engDate": "1971-09-25",
        "nepDate": "09.06.2028"
    },
    {
        "engDate": "1971-09-26",
        "nepDate": "10.06.2028"
    },
    {
        "engDate": "1971-09-27",
        "nepDate": "11.06.2028"
    },
    {
        "engDate": "1971-09-28",
        "nepDate": "12.06.2028"
    },
    {
        "engDate": "1971-09-29",
        "nepDate": "13.06.2028"
    },
    {
        "engDate": "1971-09-30",
        "nepDate": "14.06.2028"
    },
    {
        "engDate": "1971-10-01",
        "nepDate": "15.06.2028"
    },
    {
        "engDate": "1971-10-02",
        "nepDate": "16.06.2028"
    },
    {
        "engDate": "1971-10-03",
        "nepDate": "17.06.2028"
    },
    {
        "engDate": "1971-10-04",
        "nepDate": "18.06.2028"
    },
    {
        "engDate": "1971-10-05",
        "nepDate": "19.06.2028"
    },
    {
        "engDate": "1971-10-06",
        "nepDate": "20.06.2028"
    },
    {
        "engDate": "1971-10-07",
        "nepDate": "21.06.2028"
    },
    {
        "engDate": "1971-10-08",
        "nepDate": "22.06.2028"
    },
    {
        "engDate": "1971-10-09",
        "nepDate": "23.06.2028"
    },
    {
        "engDate": "1971-10-10",
        "nepDate": "24.06.2028"
    },
    {
        "engDate": "1971-10-11",
        "nepDate": "25.06.2028"
    },
    {
        "engDate": "1971-10-12",
        "nepDate": "26.06.2028"
    },
    {
        "engDate": "1971-10-13",
        "nepDate": "27.06.2028"
    },
    {
        "engDate": "1971-10-14",
        "nepDate": "28.06.2028"
    },
    {
        "engDate": "1971-10-15",
        "nepDate": "29.06.2028"
    },
    {
        "engDate": "1971-10-16",
        "nepDate": "30.06.2028"
    },
    {
        "engDate": "1971-10-17",
        "nepDate": "31.06.2028"
    },
    {
        "engDate": "1971-10-18",
        "nepDate": "01.07.2028"
    },
    {
        "engDate": "1971-10-19",
        "nepDate": "02.07.2028"
    },
    {
        "engDate": "1971-10-20",
        "nepDate": "03.07.2028"
    },
    {
        "engDate": "1971-10-21",
        "nepDate": "04.07.2028"
    },
    {
        "engDate": "1971-10-22",
        "nepDate": "05.07.2028"
    },
    {
        "engDate": "1971-10-23",
        "nepDate": "06.07.2028"
    },
    {
        "engDate": "1971-10-24",
        "nepDate": "07.07.2028"
    },
    {
        "engDate": "1971-10-25",
        "nepDate": "08.07.2028"
    },
    {
        "engDate": "1971-10-26",
        "nepDate": "09.07.2028"
    },
    {
        "engDate": "1971-10-27",
        "nepDate": "10.07.2028"
    },
    {
        "engDate": "1971-10-28",
        "nepDate": "11.07.2028"
    },
    {
        "engDate": "1971-10-29",
        "nepDate": "12.07.2028"
    },
    {
        "engDate": "1971-10-30",
        "nepDate": "13.07.2028"
    },
    {
        "engDate": "1971-10-31",
        "nepDate": "14.07.2028"
    },
    {
        "engDate": "1971-11-01",
        "nepDate": "15.07.2028"
    },
    {
        "engDate": "1971-11-02",
        "nepDate": "16.07.2028"
    },
    {
        "engDate": "1971-11-03",
        "nepDate": "17.07.2028"
    },
    {
        "engDate": "1971-11-04",
        "nepDate": "18.07.2028"
    },
    {
        "engDate": "1971-11-05",
        "nepDate": "19.07.2028"
    },
    {
        "engDate": "1971-11-06",
        "nepDate": "20.07.2028"
    },
    {
        "engDate": "1971-11-07",
        "nepDate": "21.07.2028"
    },
    {
        "engDate": "1971-11-08",
        "nepDate": "22.07.2028"
    },
    {
        "engDate": "1971-11-09",
        "nepDate": "23.07.2028"
    },
    {
        "engDate": "1971-11-10",
        "nepDate": "24.07.2028"
    },
    {
        "engDate": "1971-11-11",
        "nepDate": "25.07.2028"
    },
    {
        "engDate": "1971-11-12",
        "nepDate": "26.07.2028"
    },
    {
        "engDate": "1971-11-13",
        "nepDate": "27.07.2028"
    },
    {
        "engDate": "1971-11-14",
        "nepDate": "28.07.2028"
    },
    {
        "engDate": "1971-11-15",
        "nepDate": "29.07.2028"
    },
    {
        "engDate": "1971-11-16",
        "nepDate": "30.07.2028"
    },
    {
        "engDate": "1971-11-17",
        "nepDate": "01.08.2028"
    },
    {
        "engDate": "1971-11-18",
        "nepDate": "02.08.2028"
    },
    {
        "engDate": "1971-11-19",
        "nepDate": "03.08.2028"
    },
    {
        "engDate": "1971-11-20",
        "nepDate": "04.08.2028"
    },
    {
        "engDate": "1971-11-21",
        "nepDate": "05.08.2028"
    },
    {
        "engDate": "1971-11-22",
        "nepDate": "06.08.2028"
    },
    {
        "engDate": "1971-11-23",
        "nepDate": "07.08.2028"
    },
    {
        "engDate": "1971-11-24",
        "nepDate": "08.08.2028"
    },
    {
        "engDate": "1971-11-25",
        "nepDate": "09.08.2028"
    },
    {
        "engDate": "1971-11-26",
        "nepDate": "10.08.2028"
    },
    {
        "engDate": "1971-11-27",
        "nepDate": "11.08.2028"
    },
    {
        "engDate": "1971-11-28",
        "nepDate": "12.08.2028"
    },
    {
        "engDate": "1971-11-29",
        "nepDate": "13.08.2028"
    },
    {
        "engDate": "1971-11-30",
        "nepDate": "14.08.2028"
    },
    {
        "engDate": "1971-12-01",
        "nepDate": "15.08.2028"
    },
    {
        "engDate": "1971-12-02",
        "nepDate": "16.08.2028"
    },
    {
        "engDate": "1971-12-03",
        "nepDate": "17.08.2028"
    },
    {
        "engDate": "1971-12-04",
        "nepDate": "18.08.2028"
    },
    {
        "engDate": "1971-12-05",
        "nepDate": "19.08.2028"
    },
    {
        "engDate": "1971-12-06",
        "nepDate": "20.08.2028"
    },
    {
        "engDate": "1971-12-07",
        "nepDate": "21.08.2028"
    },
    {
        "engDate": "1971-12-08",
        "nepDate": "22.08.2028"
    },
    {
        "engDate": "1971-12-09",
        "nepDate": "23.08.2028"
    },
    {
        "engDate": "1971-12-10",
        "nepDate": "24.08.2028"
    },
    {
        "engDate": "1971-12-11",
        "nepDate": "25.08.2028"
    },
    {
        "engDate": "1971-12-12",
        "nepDate": "26.08.2028"
    },
    {
        "engDate": "1971-12-13",
        "nepDate": "27.08.2028"
    },
    {
        "engDate": "1971-12-14",
        "nepDate": "28.08.2028"
    },
    {
        "engDate": "1971-12-15",
        "nepDate": "29.08.2028"
    },
    {
        "engDate": "1971-12-16",
        "nepDate": "01.09.2028"
    },
    {
        "engDate": "1971-12-17",
        "nepDate": "02.09.2028"
    },
    {
        "engDate": "1971-12-18",
        "nepDate": "03.09.2028"
    },
    {
        "engDate": "1971-12-19",
        "nepDate": "04.09.2028"
    },
    {
        "engDate": "1971-12-20",
        "nepDate": "05.09.2028"
    },
    {
        "engDate": "1971-12-21",
        "nepDate": "06.09.2028"
    },
    {
        "engDate": "1971-12-22",
        "nepDate": "07.09.2028"
    },
    {
        "engDate": "1971-12-23",
        "nepDate": "08.09.2028"
    },
    {
        "engDate": "1971-12-24",
        "nepDate": "09.09.2028"
    },
    {
        "engDate": "1971-12-25",
        "nepDate": "10.09.2028"
    },
    {
        "engDate": "1971-12-26",
        "nepDate": "11.09.2028"
    },
    {
        "engDate": "1971-12-27",
        "nepDate": "12.09.2028"
    },
    {
        "engDate": "1971-12-28",
        "nepDate": "13.09.2028"
    },
    {
        "engDate": "1971-12-29",
        "nepDate": "14.09.2028"
    },
    {
        "engDate": "1971-12-30",
        "nepDate": "15.09.2028"
    },
    {
        "engDate": "1971-12-31",
        "nepDate": "16.09.2028"
    },
    {
        "engDate": "1972-01-01",
        "nepDate": "17.09.2028"
    },
    {
        "engDate": "1972-01-02",
        "nepDate": "18.09.2028"
    },
    {
        "engDate": "1972-01-03",
        "nepDate": "19.09.2028"
    },
    {
        "engDate": "1972-01-04",
        "nepDate": "20.09.2028"
    },
    {
        "engDate": "1972-01-05",
        "nepDate": "21.09.2028"
    },
    {
        "engDate": "1972-01-06",
        "nepDate": "22.09.2028"
    },
    {
        "engDate": "1972-01-07",
        "nepDate": "23.09.2028"
    },
    {
        "engDate": "1972-01-08",
        "nepDate": "24.09.2028"
    },
    {
        "engDate": "1972-01-09",
        "nepDate": "25.09.2028"
    },
    {
        "engDate": "1972-01-10",
        "nepDate": "26.09.2028"
    },
    {
        "engDate": "1972-01-11",
        "nepDate": "27.09.2028"
    },
    {
        "engDate": "1972-01-12",
        "nepDate": "28.09.2028"
    },
    {
        "engDate": "1972-01-13",
        "nepDate": "29.09.2028"
    },
    {
        "engDate": "1972-01-14",
        "nepDate": "30.09.2028"
    },
    {
        "engDate": "1972-01-15",
        "nepDate": "01.10.2028"
    },
    {
        "engDate": "1972-01-16",
        "nepDate": "02.10.2028"
    },
    {
        "engDate": "1972-01-17",
        "nepDate": "03.10.2028"
    },
    {
        "engDate": "1972-01-18",
        "nepDate": "04.10.2028"
    },
    {
        "engDate": "1972-01-19",
        "nepDate": "05.10.2028"
    },
    {
        "engDate": "1972-01-20",
        "nepDate": "06.10.2028"
    },
    {
        "engDate": "1972-01-21",
        "nepDate": "07.10.2028"
    },
    {
        "engDate": "1972-01-22",
        "nepDate": "08.10.2028"
    },
    {
        "engDate": "1972-01-23",
        "nepDate": "09.10.2028"
    },
    {
        "engDate": "1972-01-24",
        "nepDate": "10.10.2028"
    },
    {
        "engDate": "1972-01-25",
        "nepDate": "11.10.2028"
    },
    {
        "engDate": "1972-01-26",
        "nepDate": "12.10.2028"
    },
    {
        "engDate": "1972-01-27",
        "nepDate": "13.10.2028"
    },
    {
        "engDate": "1972-01-28",
        "nepDate": "14.10.2028"
    },
    {
        "engDate": "1972-01-29",
        "nepDate": "15.10.2028"
    },
    {
        "engDate": "1972-01-30",
        "nepDate": "16.10.2028"
    },
    {
        "engDate": "1972-01-31",
        "nepDate": "17.10.2028"
    },
    {
        "engDate": "1972-02-01",
        "nepDate": "18.10.2028"
    },
    {
        "engDate": "1972-02-02",
        "nepDate": "19.10.2028"
    },
    {
        "engDate": "1972-02-03",
        "nepDate": "20.10.2028"
    },
    {
        "engDate": "1972-02-04",
        "nepDate": "21.10.2028"
    },
    {
        "engDate": "1972-02-05",
        "nepDate": "22.10.2028"
    },
    {
        "engDate": "1972-02-06",
        "nepDate": "23.10.2028"
    },
    {
        "engDate": "1972-02-07",
        "nepDate": "24.10.2028"
    },
    {
        "engDate": "1972-02-08",
        "nepDate": "25.10.2028"
    },
    {
        "engDate": "1972-02-09",
        "nepDate": "26.10.2028"
    },
    {
        "engDate": "1972-02-10",
        "nepDate": "27.10.2028"
    },
    {
        "engDate": "1972-02-11",
        "nepDate": "28.10.2028"
    },
    {
        "engDate": "1972-02-12",
        "nepDate": "29.10.2028"
    },
    {
        "engDate": "1972-02-13",
        "nepDate": "01.11.2028"
    },
    {
        "engDate": "1972-02-14",
        "nepDate": "02.11.2028"
    },
    {
        "engDate": "1972-02-15",
        "nepDate": "03.11.2028"
    },
    {
        "engDate": "1972-02-16",
        "nepDate": "04.11.2028"
    },
    {
        "engDate": "1972-02-17",
        "nepDate": "05.11.2028"
    },
    {
        "engDate": "1972-02-18",
        "nepDate": "06.11.2028"
    },
    {
        "engDate": "1972-02-19",
        "nepDate": "07.11.2028"
    },
    {
        "engDate": "1972-02-20",
        "nepDate": "08.11.2028"
    },
    {
        "engDate": "1972-02-21",
        "nepDate": "09.11.2028"
    },
    {
        "engDate": "1972-02-22",
        "nepDate": "10.11.2028"
    },
    {
        "engDate": "1972-02-23",
        "nepDate": "11.11.2028"
    },
    {
        "engDate": "1972-02-24",
        "nepDate": "12.11.2028"
    },
    {
        "engDate": "1972-02-25",
        "nepDate": "13.11.2028"
    },
    {
        "engDate": "1972-02-26",
        "nepDate": "14.11.2028"
    },
    {
        "engDate": "1972-02-27",
        "nepDate": "15.11.2028"
    },
    {
        "engDate": "1972-02-28",
        "nepDate": "16.11.2028"
    },
    {
        "engDate": "1972-02-29",
        "nepDate": "17.11.2028"
    },
    {
        "engDate": "1972-03-01",
        "nepDate": "18.11.2028"
    },
    {
        "engDate": "1972-03-02",
        "nepDate": "19.11.2028"
    },
    {
        "engDate": "1972-03-03",
        "nepDate": "20.11.2028"
    },
    {
        "engDate": "1972-03-04",
        "nepDate": "21.11.2028"
    },
    {
        "engDate": "1972-03-05",
        "nepDate": "22.11.2028"
    },
    {
        "engDate": "1972-03-06",
        "nepDate": "23.11.2028"
    },
    {
        "engDate": "1972-03-07",
        "nepDate": "24.11.2028"
    },
    {
        "engDate": "1972-03-08",
        "nepDate": "25.11.2028"
    },
    {
        "engDate": "1972-03-09",
        "nepDate": "26.11.2028"
    },
    {
        "engDate": "1972-03-10",
        "nepDate": "27.11.2028"
    },
    {
        "engDate": "1972-03-11",
        "nepDate": "28.11.2028"
    },
    {
        "engDate": "1972-03-12",
        "nepDate": "29.11.2028"
    },
    {
        "engDate": "1972-03-13",
        "nepDate": "30.11.2028"
    },
    {
        "engDate": "1972-03-14",
        "nepDate": "01.12.2028"
    },
    {
        "engDate": "1972-03-15",
        "nepDate": "02.12.2028"
    },
    {
        "engDate": "1972-03-16",
        "nepDate": "03.12.2028"
    },
    {
        "engDate": "1972-03-17",
        "nepDate": "04.12.2028"
    },
    {
        "engDate": "1972-03-18",
        "nepDate": "05.12.2028"
    },
    {
        "engDate": "1972-03-19",
        "nepDate": "06.12.2028"
    },
    {
        "engDate": "1972-03-20",
        "nepDate": "07.12.2028"
    },
    {
        "engDate": "1972-03-21",
        "nepDate": "08.12.2028"
    },
    {
        "engDate": "1972-03-22",
        "nepDate": "09.12.2028"
    },
    {
        "engDate": "1972-03-23",
        "nepDate": "10.12.2028"
    },
    {
        "engDate": "1972-03-24",
        "nepDate": "11.12.2028"
    },
    {
        "engDate": "1972-03-25",
        "nepDate": "12.12.2028"
    },
    {
        "engDate": "1972-03-26",
        "nepDate": "13.12.2028"
    },
    {
        "engDate": "1972-03-27",
        "nepDate": "14.12.2028"
    },
    {
        "engDate": "1972-03-28",
        "nepDate": "15.12.2028"
    },
    {
        "engDate": "1972-03-29",
        "nepDate": "16.12.2028"
    },
    {
        "engDate": "1972-03-30",
        "nepDate": "17.12.2028"
    },
    {
        "engDate": "1972-03-31",
        "nepDate": "18.12.2028"
    },
    {
        "engDate": "1972-04-01",
        "nepDate": "19.12.2028"
    },
    {
        "engDate": "1972-04-02",
        "nepDate": "20.12.2028"
    },
    {
        "engDate": "1972-04-03",
        "nepDate": "21.12.2028"
    },
    {
        "engDate": "1972-04-04",
        "nepDate": "22.12.2028"
    },
    {
        "engDate": "1972-04-05",
        "nepDate": "23.12.2028"
    },
    {
        "engDate": "1972-04-06",
        "nepDate": "24.12.2028"
    },
    {
        "engDate": "1972-04-07",
        "nepDate": "25.12.2028"
    },
    {
        "engDate": "1972-04-08",
        "nepDate": "26.12.2028"
    },
    {
        "engDate": "1972-04-09",
        "nepDate": "27.12.2028"
    },
    {
        "engDate": "1972-04-10",
        "nepDate": "28.12.2028"
    },
    {
        "engDate": "1972-04-11",
        "nepDate": "29.12.2028"
    },
    {
        "engDate": "1972-04-12",
        "nepDate": "30.12.2028"
    },
    {
        "engDate": "1972-04-13",
        "nepDate": "01.01.2029"
    },
    {
        "engDate": "1972-04-14",
        "nepDate": "02.01.2029"
    },
    {
        "engDate": "1972-04-15",
        "nepDate": "03.01.2029"
    },
    {
        "engDate": "1972-04-16",
        "nepDate": "04.01.2029"
    },
    {
        "engDate": "1972-04-17",
        "nepDate": "05.01.2029"
    },
    {
        "engDate": "1972-04-18",
        "nepDate": "06.01.2029"
    },
    {
        "engDate": "1972-04-19",
        "nepDate": "07.01.2029"
    },
    {
        "engDate": "1972-04-20",
        "nepDate": "08.01.2029"
    },
    {
        "engDate": "1972-04-21",
        "nepDate": "09.01.2029"
    },
    {
        "engDate": "1972-04-22",
        "nepDate": "10.01.2029"
    },
    {
        "engDate": "1972-04-23",
        "nepDate": "11.01.2029"
    },
    {
        "engDate": "1972-04-24",
        "nepDate": "12.01.2029"
    },
    {
        "engDate": "1972-04-25",
        "nepDate": "13.01.2029"
    },
    {
        "engDate": "1972-04-26",
        "nepDate": "14.01.2029"
    },
    {
        "engDate": "1972-04-27",
        "nepDate": "15.01.2029"
    },
    {
        "engDate": "1972-04-28",
        "nepDate": "16.01.2029"
    },
    {
        "engDate": "1972-04-29",
        "nepDate": "17.01.2029"
    },
    {
        "engDate": "1972-04-30",
        "nepDate": "18.01.2029"
    },
    {
        "engDate": "1972-05-01",
        "nepDate": "19.01.2029"
    },
    {
        "engDate": "1972-05-02",
        "nepDate": "20.01.2029"
    },
    {
        "engDate": "1972-05-03",
        "nepDate": "21.01.2029"
    },
    {
        "engDate": "1972-05-04",
        "nepDate": "22.01.2029"
    },
    {
        "engDate": "1972-05-05",
        "nepDate": "23.01.2029"
    },
    {
        "engDate": "1972-05-06",
        "nepDate": "24.01.2029"
    },
    {
        "engDate": "1972-05-07",
        "nepDate": "25.01.2029"
    },
    {
        "engDate": "1972-05-08",
        "nepDate": "26.01.2029"
    },
    {
        "engDate": "1972-05-09",
        "nepDate": "27.01.2029"
    },
    {
        "engDate": "1972-05-10",
        "nepDate": "28.01.2029"
    },
    {
        "engDate": "1972-05-11",
        "nepDate": "29.01.2029"
    },
    {
        "engDate": "1972-05-12",
        "nepDate": "30.01.2029"
    },
    {
        "engDate": "1972-05-13",
        "nepDate": "31.01.2029"
    },
    {
        "engDate": "1972-05-14",
        "nepDate": "01.02.2029"
    },
    {
        "engDate": "1972-05-15",
        "nepDate": "02.02.2029"
    },
    {
        "engDate": "1972-05-16",
        "nepDate": "03.02.2029"
    },
    {
        "engDate": "1972-05-17",
        "nepDate": "04.02.2029"
    },
    {
        "engDate": "1972-05-18",
        "nepDate": "05.02.2029"
    },
    {
        "engDate": "1972-05-19",
        "nepDate": "06.02.2029"
    },
    {
        "engDate": "1972-05-20",
        "nepDate": "07.02.2029"
    },
    {
        "engDate": "1972-05-21",
        "nepDate": "08.02.2029"
    },
    {
        "engDate": "1972-05-22",
        "nepDate": "09.02.2029"
    },
    {
        "engDate": "1972-05-23",
        "nepDate": "10.02.2029"
    },
    {
        "engDate": "1972-05-24",
        "nepDate": "11.02.2029"
    },
    {
        "engDate": "1972-05-25",
        "nepDate": "12.02.2029"
    },
    {
        "engDate": "1972-05-26",
        "nepDate": "13.02.2029"
    },
    {
        "engDate": "1972-05-27",
        "nepDate": "14.02.2029"
    },
    {
        "engDate": "1972-05-28",
        "nepDate": "15.02.2029"
    },
    {
        "engDate": "1972-05-29",
        "nepDate": "16.02.2029"
    },
    {
        "engDate": "1972-05-30",
        "nepDate": "17.02.2029"
    },
    {
        "engDate": "1972-05-31",
        "nepDate": "18.02.2029"
    },
    {
        "engDate": "1972-06-01",
        "nepDate": "19.02.2029"
    },
    {
        "engDate": "1972-06-02",
        "nepDate": "20.02.2029"
    },
    {
        "engDate": "1972-06-03",
        "nepDate": "21.02.2029"
    },
    {
        "engDate": "1972-06-04",
        "nepDate": "22.02.2029"
    },
    {
        "engDate": "1972-06-05",
        "nepDate": "23.02.2029"
    },
    {
        "engDate": "1972-06-06",
        "nepDate": "24.02.2029"
    },
    {
        "engDate": "1972-06-07",
        "nepDate": "25.02.2029"
    },
    {
        "engDate": "1972-06-08",
        "nepDate": "26.02.2029"
    },
    {
        "engDate": "1972-06-09",
        "nepDate": "27.02.2029"
    },
    {
        "engDate": "1972-06-10",
        "nepDate": "28.02.2029"
    },
    {
        "engDate": "1972-06-11",
        "nepDate": "29.02.2029"
    },
    {
        "engDate": "1972-06-12",
        "nepDate": "30.02.2029"
    },
    {
        "engDate": "1972-06-13",
        "nepDate": "31.02.2029"
    },
    {
        "engDate": "1972-06-14",
        "nepDate": "01.03.2029"
    },
    {
        "engDate": "1972-06-15",
        "nepDate": "02.03.2029"
    },
    {
        "engDate": "1972-06-16",
        "nepDate": "03.03.2029"
    },
    {
        "engDate": "1972-06-17",
        "nepDate": "04.03.2029"
    },
    {
        "engDate": "1972-06-18",
        "nepDate": "05.03.2029"
    },
    {
        "engDate": "1972-06-19",
        "nepDate": "06.03.2029"
    },
    {
        "engDate": "1972-06-20",
        "nepDate": "07.03.2029"
    },
    {
        "engDate": "1972-06-21",
        "nepDate": "08.03.2029"
    },
    {
        "engDate": "1972-06-22",
        "nepDate": "09.03.2029"
    },
    {
        "engDate": "1972-06-23",
        "nepDate": "10.03.2029"
    },
    {
        "engDate": "1972-06-24",
        "nepDate": "11.03.2029"
    },
    {
        "engDate": "1972-06-25",
        "nepDate": "12.03.2029"
    },
    {
        "engDate": "1972-06-26",
        "nepDate": "13.03.2029"
    },
    {
        "engDate": "1972-06-27",
        "nepDate": "14.03.2029"
    },
    {
        "engDate": "1972-06-28",
        "nepDate": "15.03.2029"
    },
    {
        "engDate": "1972-06-29",
        "nepDate": "16.03.2029"
    },
    {
        "engDate": "1972-06-30",
        "nepDate": "17.03.2029"
    },
    {
        "engDate": "1972-07-01",
        "nepDate": "18.03.2029"
    },
    {
        "engDate": "1972-07-02",
        "nepDate": "19.03.2029"
    },
    {
        "engDate": "1972-07-03",
        "nepDate": "20.03.2029"
    },
    {
        "engDate": "1972-07-04",
        "nepDate": "21.03.2029"
    },
    {
        "engDate": "1972-07-05",
        "nepDate": "22.03.2029"
    },
    {
        "engDate": "1972-07-06",
        "nepDate": "23.03.2029"
    },
    {
        "engDate": "1972-07-07",
        "nepDate": "24.03.2029"
    },
    {
        "engDate": "1972-07-08",
        "nepDate": "25.03.2029"
    },
    {
        "engDate": "1972-07-09",
        "nepDate": "26.03.2029"
    },
    {
        "engDate": "1972-07-10",
        "nepDate": "27.03.2029"
    },
    {
        "engDate": "1972-07-11",
        "nepDate": "28.03.2029"
    },
    {
        "engDate": "1972-07-12",
        "nepDate": "29.03.2029"
    },
    {
        "engDate": "1972-07-13",
        "nepDate": "30.03.2029"
    },
    {
        "engDate": "1972-07-14",
        "nepDate": "31.03.2029"
    },
    {
        "engDate": "1972-07-15",
        "nepDate": "32.03.2029"
    },
    {
        "engDate": "1972-07-16",
        "nepDate": "01.04.2029"
    },
    {
        "engDate": "1972-07-17",
        "nepDate": "02.04.2029"
    },
    {
        "engDate": "1972-07-18",
        "nepDate": "03.04.2029"
    },
    {
        "engDate": "1972-07-19",
        "nepDate": "04.04.2029"
    },
    {
        "engDate": "1972-07-20",
        "nepDate": "05.04.2029"
    },
    {
        "engDate": "1972-07-21",
        "nepDate": "06.04.2029"
    },
    {
        "engDate": "1972-07-22",
        "nepDate": "07.04.2029"
    },
    {
        "engDate": "1972-07-23",
        "nepDate": "08.04.2029"
    },
    {
        "engDate": "1972-07-24",
        "nepDate": "09.04.2029"
    },
    {
        "engDate": "1972-07-25",
        "nepDate": "10.04.2029"
    },
    {
        "engDate": "1972-07-26",
        "nepDate": "11.04.2029"
    },
    {
        "engDate": "1972-07-27",
        "nepDate": "12.04.2029"
    },
    {
        "engDate": "1972-07-28",
        "nepDate": "13.04.2029"
    },
    {
        "engDate": "1972-07-29",
        "nepDate": "14.04.2029"
    },
    {
        "engDate": "1972-07-30",
        "nepDate": "15.04.2029"
    },
    {
        "engDate": "1972-07-31",
        "nepDate": "16.04.2029"
    },
    {
        "engDate": "1972-08-01",
        "nepDate": "17.04.2029"
    },
    {
        "engDate": "1972-08-02",
        "nepDate": "18.04.2029"
    },
    {
        "engDate": "1972-08-03",
        "nepDate": "19.04.2029"
    },
    {
        "engDate": "1972-08-04",
        "nepDate": "20.04.2029"
    },
    {
        "engDate": "1972-08-05",
        "nepDate": "21.04.2029"
    },
    {
        "engDate": "1972-08-06",
        "nepDate": "22.04.2029"
    },
    {
        "engDate": "1972-08-07",
        "nepDate": "23.04.2029"
    },
    {
        "engDate": "1972-08-08",
        "nepDate": "24.04.2029"
    },
    {
        "engDate": "1972-08-09",
        "nepDate": "25.04.2029"
    },
    {
        "engDate": "1972-08-10",
        "nepDate": "26.04.2029"
    },
    {
        "engDate": "1972-08-11",
        "nepDate": "27.04.2029"
    },
    {
        "engDate": "1972-08-12",
        "nepDate": "28.04.2029"
    },
    {
        "engDate": "1972-08-13",
        "nepDate": "29.04.2029"
    },
    {
        "engDate": "1972-08-14",
        "nepDate": "30.04.2029"
    },
    {
        "engDate": "1972-08-15",
        "nepDate": "31.04.2029"
    },
    {
        "engDate": "1972-08-16",
        "nepDate": "01.05.2029"
    },
    {
        "engDate": "1972-08-17",
        "nepDate": "02.05.2029"
    },
    {
        "engDate": "1972-08-18",
        "nepDate": "03.05.2029"
    },
    {
        "engDate": "1972-08-19",
        "nepDate": "04.05.2029"
    },
    {
        "engDate": "1972-08-20",
        "nepDate": "05.05.2029"
    },
    {
        "engDate": "1972-08-21",
        "nepDate": "06.05.2029"
    },
    {
        "engDate": "1972-08-22",
        "nepDate": "07.05.2029"
    },
    {
        "engDate": "1972-08-23",
        "nepDate": "08.05.2029"
    },
    {
        "engDate": "1972-08-24",
        "nepDate": "09.05.2029"
    },
    {
        "engDate": "1972-08-25",
        "nepDate": "10.05.2029"
    },
    {
        "engDate": "1972-08-26",
        "nepDate": "11.05.2029"
    },
    {
        "engDate": "1972-08-27",
        "nepDate": "12.05.2029"
    },
    {
        "engDate": "1972-08-28",
        "nepDate": "13.05.2029"
    },
    {
        "engDate": "1972-08-29",
        "nepDate": "14.05.2029"
    },
    {
        "engDate": "1972-08-30",
        "nepDate": "15.05.2029"
    },
    {
        "engDate": "1972-08-31",
        "nepDate": "16.05.2029"
    },
    {
        "engDate": "1972-09-01",
        "nepDate": "17.05.2029"
    },
    {
        "engDate": "1972-09-02",
        "nepDate": "18.05.2029"
    },
    {
        "engDate": "1972-09-03",
        "nepDate": "19.05.2029"
    },
    {
        "engDate": "1972-09-04",
        "nepDate": "20.05.2029"
    },
    {
        "engDate": "1972-09-05",
        "nepDate": "21.05.2029"
    },
    {
        "engDate": "1972-09-06",
        "nepDate": "22.05.2029"
    },
    {
        "engDate": "1972-09-07",
        "nepDate": "23.05.2029"
    },
    {
        "engDate": "1972-09-08",
        "nepDate": "24.05.2029"
    },
    {
        "engDate": "1972-09-09",
        "nepDate": "25.05.2029"
    },
    {
        "engDate": "1972-09-10",
        "nepDate": "26.05.2029"
    },
    {
        "engDate": "1972-09-11",
        "nepDate": "27.05.2029"
    },
    {
        "engDate": "1972-09-12",
        "nepDate": "28.05.2029"
    },
    {
        "engDate": "1972-09-13",
        "nepDate": "29.05.2029"
    },
    {
        "engDate": "1972-09-14",
        "nepDate": "30.05.2029"
    },
    {
        "engDate": "1972-09-15",
        "nepDate": "31.05.2029"
    },
    {
        "engDate": "1972-09-16",
        "nepDate": "32.05.2029"
    },
    {
        "engDate": "1972-09-17",
        "nepDate": "01.06.2029"
    },
    {
        "engDate": "1972-09-18",
        "nepDate": "02.06.2029"
    },
    {
        "engDate": "1972-09-19",
        "nepDate": "03.06.2029"
    },
    {
        "engDate": "1972-09-20",
        "nepDate": "04.06.2029"
    },
    {
        "engDate": "1972-09-21",
        "nepDate": "05.06.2029"
    },
    {
        "engDate": "1972-09-22",
        "nepDate": "06.06.2029"
    },
    {
        "engDate": "1972-09-23",
        "nepDate": "07.06.2029"
    },
    {
        "engDate": "1972-09-24",
        "nepDate": "08.06.2029"
    },
    {
        "engDate": "1972-09-25",
        "nepDate": "09.06.2029"
    },
    {
        "engDate": "1972-09-26",
        "nepDate": "10.06.2029"
    },
    {
        "engDate": "1972-09-27",
        "nepDate": "11.06.2029"
    },
    {
        "engDate": "1972-09-28",
        "nepDate": "12.06.2029"
    },
    {
        "engDate": "1972-09-29",
        "nepDate": "13.06.2029"
    },
    {
        "engDate": "1972-09-30",
        "nepDate": "14.06.2029"
    },
    {
        "engDate": "1972-10-01",
        "nepDate": "15.06.2029"
    },
    {
        "engDate": "1972-10-02",
        "nepDate": "16.06.2029"
    },
    {
        "engDate": "1972-10-03",
        "nepDate": "17.06.2029"
    },
    {
        "engDate": "1972-10-04",
        "nepDate": "18.06.2029"
    },
    {
        "engDate": "1972-10-05",
        "nepDate": "19.06.2029"
    },
    {
        "engDate": "1972-10-06",
        "nepDate": "20.06.2029"
    },
    {
        "engDate": "1972-10-07",
        "nepDate": "21.06.2029"
    },
    {
        "engDate": "1972-10-08",
        "nepDate": "22.06.2029"
    },
    {
        "engDate": "1972-10-09",
        "nepDate": "23.06.2029"
    },
    {
        "engDate": "1972-10-10",
        "nepDate": "24.06.2029"
    },
    {
        "engDate": "1972-10-11",
        "nepDate": "25.06.2029"
    },
    {
        "engDate": "1972-10-12",
        "nepDate": "26.06.2029"
    },
    {
        "engDate": "1972-10-13",
        "nepDate": "27.06.2029"
    },
    {
        "engDate": "1972-10-14",
        "nepDate": "28.06.2029"
    },
    {
        "engDate": "1972-10-15",
        "nepDate": "29.06.2029"
    },
    {
        "engDate": "1972-10-16",
        "nepDate": "30.06.2029"
    },
    {
        "engDate": "1972-10-17",
        "nepDate": "01.07.2029"
    },
    {
        "engDate": "1972-10-18",
        "nepDate": "02.07.2029"
    },
    {
        "engDate": "1972-10-19",
        "nepDate": "03.07.2029"
    },
    {
        "engDate": "1972-10-20",
        "nepDate": "04.07.2029"
    },
    {
        "engDate": "1972-10-21",
        "nepDate": "05.07.2029"
    },
    {
        "engDate": "1972-10-22",
        "nepDate": "06.07.2029"
    },
    {
        "engDate": "1972-10-23",
        "nepDate": "07.07.2029"
    },
    {
        "engDate": "1972-10-24",
        "nepDate": "08.07.2029"
    },
    {
        "engDate": "1972-10-25",
        "nepDate": "09.07.2029"
    },
    {
        "engDate": "1972-10-26",
        "nepDate": "10.07.2029"
    },
    {
        "engDate": "1972-10-27",
        "nepDate": "11.07.2029"
    },
    {
        "engDate": "1972-10-28",
        "nepDate": "12.07.2029"
    },
    {
        "engDate": "1972-10-29",
        "nepDate": "13.07.2029"
    },
    {
        "engDate": "1972-10-30",
        "nepDate": "14.07.2029"
    },
    {
        "engDate": "1972-10-31",
        "nepDate": "15.07.2029"
    },
    {
        "engDate": "1972-11-01",
        "nepDate": "16.07.2029"
    },
    {
        "engDate": "1972-11-02",
        "nepDate": "17.07.2029"
    },
    {
        "engDate": "1972-11-03",
        "nepDate": "18.07.2029"
    },
    {
        "engDate": "1972-11-04",
        "nepDate": "19.07.2029"
    },
    {
        "engDate": "1972-11-05",
        "nepDate": "20.07.2029"
    },
    {
        "engDate": "1972-11-06",
        "nepDate": "21.07.2029"
    },
    {
        "engDate": "1972-11-07",
        "nepDate": "22.07.2029"
    },
    {
        "engDate": "1972-11-08",
        "nepDate": "23.07.2029"
    },
    {
        "engDate": "1972-11-09",
        "nepDate": "24.07.2029"
    },
    {
        "engDate": "1972-11-10",
        "nepDate": "25.07.2029"
    },
    {
        "engDate": "1972-11-11",
        "nepDate": "26.07.2029"
    },
    {
        "engDate": "1972-11-12",
        "nepDate": "27.07.2029"
    },
    {
        "engDate": "1972-11-13",
        "nepDate": "28.07.2029"
    },
    {
        "engDate": "1972-11-14",
        "nepDate": "29.07.2029"
    },
    {
        "engDate": "1972-11-15",
        "nepDate": "30.07.2029"
    },
    {
        "engDate": "1972-11-16",
        "nepDate": "01.08.2029"
    },
    {
        "engDate": "1972-11-17",
        "nepDate": "02.08.2029"
    },
    {
        "engDate": "1972-11-18",
        "nepDate": "03.08.2029"
    },
    {
        "engDate": "1972-11-19",
        "nepDate": "04.08.2029"
    },
    {
        "engDate": "1972-11-20",
        "nepDate": "05.08.2029"
    },
    {
        "engDate": "1972-11-21",
        "nepDate": "06.08.2029"
    },
    {
        "engDate": "1972-11-22",
        "nepDate": "07.08.2029"
    },
    {
        "engDate": "1972-11-23",
        "nepDate": "08.08.2029"
    },
    {
        "engDate": "1972-11-24",
        "nepDate": "09.08.2029"
    },
    {
        "engDate": "1972-11-25",
        "nepDate": "10.08.2029"
    },
    {
        "engDate": "1972-11-26",
        "nepDate": "11.08.2029"
    },
    {
        "engDate": "1972-11-27",
        "nepDate": "12.08.2029"
    },
    {
        "engDate": "1972-11-28",
        "nepDate": "13.08.2029"
    },
    {
        "engDate": "1972-11-29",
        "nepDate": "14.08.2029"
    },
    {
        "engDate": "1972-11-30",
        "nepDate": "15.08.2029"
    },
    {
        "engDate": "1972-12-01",
        "nepDate": "16.08.2029"
    },
    {
        "engDate": "1972-12-02",
        "nepDate": "17.08.2029"
    },
    {
        "engDate": "1972-12-03",
        "nepDate": "18.08.2029"
    },
    {
        "engDate": "1972-12-04",
        "nepDate": "19.08.2029"
    },
    {
        "engDate": "1972-12-05",
        "nepDate": "20.08.2029"
    },
    {
        "engDate": "1972-12-06",
        "nepDate": "21.08.2029"
    },
    {
        "engDate": "1972-12-07",
        "nepDate": "22.08.2029"
    },
    {
        "engDate": "1972-12-08",
        "nepDate": "23.08.2029"
    },
    {
        "engDate": "1972-12-09",
        "nepDate": "24.08.2029"
    },
    {
        "engDate": "1972-12-10",
        "nepDate": "25.08.2029"
    },
    {
        "engDate": "1972-12-11",
        "nepDate": "26.08.2029"
    },
    {
        "engDate": "1972-12-12",
        "nepDate": "27.08.2029"
    },
    {
        "engDate": "1972-12-13",
        "nepDate": "28.08.2029"
    },
    {
        "engDate": "1972-12-14",
        "nepDate": "29.08.2029"
    },
    {
        "engDate": "1972-12-15",
        "nepDate": "01.09.2029"
    },
    {
        "engDate": "1972-12-16",
        "nepDate": "02.09.2029"
    },
    {
        "engDate": "1972-12-17",
        "nepDate": "03.09.2029"
    },
    {
        "engDate": "1972-12-18",
        "nepDate": "04.09.2029"
    },
    {
        "engDate": "1972-12-19",
        "nepDate": "05.09.2029"
    },
    {
        "engDate": "1972-12-20",
        "nepDate": "06.09.2029"
    },
    {
        "engDate": "1972-12-21",
        "nepDate": "07.09.2029"
    },
    {
        "engDate": "1972-12-22",
        "nepDate": "08.09.2029"
    },
    {
        "engDate": "1972-12-23",
        "nepDate": "09.09.2029"
    },
    {
        "engDate": "1972-12-24",
        "nepDate": "10.09.2029"
    },
    {
        "engDate": "1972-12-25",
        "nepDate": "11.09.2029"
    },
    {
        "engDate": "1972-12-26",
        "nepDate": "12.09.2029"
    },
    {
        "engDate": "1972-12-27",
        "nepDate": "13.09.2029"
    },
    {
        "engDate": "1972-12-28",
        "nepDate": "14.09.2029"
    },
    {
        "engDate": "1972-12-29",
        "nepDate": "15.09.2029"
    },
    {
        "engDate": "1972-12-30",
        "nepDate": "16.09.2029"
    },
    {
        "engDate": "1972-12-31",
        "nepDate": "17.09.2029"
    },
    {
        "engDate": "1973-01-01",
        "nepDate": "18.09.2029"
    },
    {
        "engDate": "1973-01-02",
        "nepDate": "19.09.2029"
    },
    {
        "engDate": "1973-01-03",
        "nepDate": "20.09.2029"
    },
    {
        "engDate": "1973-01-04",
        "nepDate": "21.09.2029"
    },
    {
        "engDate": "1973-01-05",
        "nepDate": "22.09.2029"
    },
    {
        "engDate": "1973-01-06",
        "nepDate": "23.09.2029"
    },
    {
        "engDate": "1973-01-07",
        "nepDate": "24.09.2029"
    },
    {
        "engDate": "1973-01-08",
        "nepDate": "25.09.2029"
    },
    {
        "engDate": "1973-01-09",
        "nepDate": "26.09.2029"
    },
    {
        "engDate": "1973-01-10",
        "nepDate": "27.09.2029"
    },
    {
        "engDate": "1973-01-11",
        "nepDate": "28.09.2029"
    },
    {
        "engDate": "1973-01-12",
        "nepDate": "29.09.2029"
    },
    {
        "engDate": "1973-01-13",
        "nepDate": "30.09.2029"
    },
    {
        "engDate": "1973-01-14",
        "nepDate": "01.10.2029"
    },
    {
        "engDate": "1973-01-15",
        "nepDate": "02.10.2029"
    },
    {
        "engDate": "1973-01-16",
        "nepDate": "03.10.2029"
    },
    {
        "engDate": "1973-01-17",
        "nepDate": "04.10.2029"
    },
    {
        "engDate": "1973-01-18",
        "nepDate": "05.10.2029"
    },
    {
        "engDate": "1973-01-19",
        "nepDate": "06.10.2029"
    },
    {
        "engDate": "1973-01-20",
        "nepDate": "07.10.2029"
    },
    {
        "engDate": "1973-01-21",
        "nepDate": "08.10.2029"
    },
    {
        "engDate": "1973-01-22",
        "nepDate": "09.10.2029"
    },
    {
        "engDate": "1973-01-23",
        "nepDate": "10.10.2029"
    },
    {
        "engDate": "1973-01-24",
        "nepDate": "11.10.2029"
    },
    {
        "engDate": "1973-01-25",
        "nepDate": "12.10.2029"
    },
    {
        "engDate": "1973-01-26",
        "nepDate": "13.10.2029"
    },
    {
        "engDate": "1973-01-27",
        "nepDate": "14.10.2029"
    },
    {
        "engDate": "1973-01-28",
        "nepDate": "15.10.2029"
    },
    {
        "engDate": "1973-01-29",
        "nepDate": "16.10.2029"
    },
    {
        "engDate": "1973-01-30",
        "nepDate": "17.10.2029"
    },
    {
        "engDate": "1973-01-31",
        "nepDate": "18.10.2029"
    },
    {
        "engDate": "1973-02-01",
        "nepDate": "19.10.2029"
    },
    {
        "engDate": "1973-02-02",
        "nepDate": "20.10.2029"
    },
    {
        "engDate": "1973-02-03",
        "nepDate": "21.10.2029"
    },
    {
        "engDate": "1973-02-04",
        "nepDate": "22.10.2029"
    },
    {
        "engDate": "1973-02-05",
        "nepDate": "23.10.2029"
    },
    {
        "engDate": "1973-02-06",
        "nepDate": "24.10.2029"
    },
    {
        "engDate": "1973-02-07",
        "nepDate": "25.10.2029"
    },
    {
        "engDate": "1973-02-08",
        "nepDate": "26.10.2029"
    },
    {
        "engDate": "1973-02-09",
        "nepDate": "27.10.2029"
    },
    {
        "engDate": "1973-02-10",
        "nepDate": "28.10.2029"
    },
    {
        "engDate": "1973-02-11",
        "nepDate": "29.10.2029"
    },
    {
        "engDate": "1973-02-12",
        "nepDate": "01.11.2029"
    },
    {
        "engDate": "1973-02-13",
        "nepDate": "02.11.2029"
    },
    {
        "engDate": "1973-02-14",
        "nepDate": "03.11.2029"
    },
    {
        "engDate": "1973-02-15",
        "nepDate": "04.11.2029"
    },
    {
        "engDate": "1973-02-16",
        "nepDate": "05.11.2029"
    },
    {
        "engDate": "1973-02-17",
        "nepDate": "06.11.2029"
    },
    {
        "engDate": "1973-02-18",
        "nepDate": "07.11.2029"
    },
    {
        "engDate": "1973-02-19",
        "nepDate": "08.11.2029"
    },
    {
        "engDate": "1973-02-20",
        "nepDate": "09.11.2029"
    },
    {
        "engDate": "1973-02-21",
        "nepDate": "10.11.2029"
    },
    {
        "engDate": "1973-02-22",
        "nepDate": "11.11.2029"
    },
    {
        "engDate": "1973-02-23",
        "nepDate": "12.11.2029"
    },
    {
        "engDate": "1973-02-24",
        "nepDate": "13.11.2029"
    },
    {
        "engDate": "1973-02-25",
        "nepDate": "14.11.2029"
    },
    {
        "engDate": "1973-02-26",
        "nepDate": "15.11.2029"
    },
    {
        "engDate": "1973-02-27",
        "nepDate": "16.11.2029"
    },
    {
        "engDate": "1973-02-28",
        "nepDate": "17.11.2029"
    },
    {
        "engDate": "1973-03-01",
        "nepDate": "18.11.2029"
    },
    {
        "engDate": "1973-03-02",
        "nepDate": "19.11.2029"
    },
    {
        "engDate": "1973-03-03",
        "nepDate": "20.11.2029"
    },
    {
        "engDate": "1973-03-04",
        "nepDate": "21.11.2029"
    },
    {
        "engDate": "1973-03-05",
        "nepDate": "22.11.2029"
    },
    {
        "engDate": "1973-03-06",
        "nepDate": "23.11.2029"
    },
    {
        "engDate": "1973-03-07",
        "nepDate": "24.11.2029"
    },
    {
        "engDate": "1973-03-08",
        "nepDate": "25.11.2029"
    },
    {
        "engDate": "1973-03-09",
        "nepDate": "26.11.2029"
    },
    {
        "engDate": "1973-03-10",
        "nepDate": "27.11.2029"
    },
    {
        "engDate": "1973-03-11",
        "nepDate": "28.11.2029"
    },
    {
        "engDate": "1973-03-12",
        "nepDate": "29.11.2029"
    },
    {
        "engDate": "1973-03-13",
        "nepDate": "30.11.2029"
    },
    {
        "engDate": "1973-03-14",
        "nepDate": "01.12.2029"
    },
    {
        "engDate": "1973-03-15",
        "nepDate": "02.12.2029"
    },
    {
        "engDate": "1973-03-16",
        "nepDate": "03.12.2029"
    },
    {
        "engDate": "1973-03-17",
        "nepDate": "04.12.2029"
    },
    {
        "engDate": "1973-03-18",
        "nepDate": "05.12.2029"
    },
    {
        "engDate": "1973-03-19",
        "nepDate": "06.12.2029"
    },
    {
        "engDate": "1973-03-20",
        "nepDate": "07.12.2029"
    },
    {
        "engDate": "1973-03-21",
        "nepDate": "08.12.2029"
    },
    {
        "engDate": "1973-03-22",
        "nepDate": "09.12.2029"
    },
    {
        "engDate": "1973-03-23",
        "nepDate": "10.12.2029"
    },
    {
        "engDate": "1973-03-24",
        "nepDate": "11.12.2029"
    },
    {
        "engDate": "1973-03-25",
        "nepDate": "12.12.2029"
    },
    {
        "engDate": "1973-03-26",
        "nepDate": "13.12.2029"
    },
    {
        "engDate": "1973-03-27",
        "nepDate": "14.12.2029"
    },
    {
        "engDate": "1973-03-28",
        "nepDate": "15.12.2029"
    },
    {
        "engDate": "1973-03-29",
        "nepDate": "16.12.2029"
    },
    {
        "engDate": "1973-03-30",
        "nepDate": "17.12.2029"
    },
    {
        "engDate": "1973-03-31",
        "nepDate": "18.12.2029"
    },
    {
        "engDate": "1973-04-01",
        "nepDate": "19.12.2029"
    },
    {
        "engDate": "1973-04-02",
        "nepDate": "20.12.2029"
    },
    {
        "engDate": "1973-04-03",
        "nepDate": "21.12.2029"
    },
    {
        "engDate": "1973-04-04",
        "nepDate": "22.12.2029"
    },
    {
        "engDate": "1973-04-05",
        "nepDate": "23.12.2029"
    },
    {
        "engDate": "1973-04-06",
        "nepDate": "24.12.2029"
    },
    {
        "engDate": "1973-04-07",
        "nepDate": "25.12.2029"
    },
    {
        "engDate": "1973-04-08",
        "nepDate": "26.12.2029"
    },
    {
        "engDate": "1973-04-09",
        "nepDate": "27.12.2029"
    },
    {
        "engDate": "1973-04-10",
        "nepDate": "28.12.2029"
    },
    {
        "engDate": "1973-04-11",
        "nepDate": "29.12.2029"
    },
    {
        "engDate": "1973-04-12",
        "nepDate": "30.12.2029"
    },
    {
        "engDate": "1973-04-13",
        "nepDate": "01.01.2030"
    },
    {
        "engDate": "1973-04-14",
        "nepDate": "02.01.2030"
    },
    {
        "engDate": "1973-04-15",
        "nepDate": "03.01.2030"
    },
    {
        "engDate": "1973-04-16",
        "nepDate": "04.01.2030"
    },
    {
        "engDate": "1973-04-17",
        "nepDate": "05.01.2030"
    },
    {
        "engDate": "1973-04-18",
        "nepDate": "06.01.2030"
    },
    {
        "engDate": "1973-04-19",
        "nepDate": "07.01.2030"
    },
    {
        "engDate": "1973-04-20",
        "nepDate": "08.01.2030"
    },
    {
        "engDate": "1973-04-21",
        "nepDate": "09.01.2030"
    },
    {
        "engDate": "1973-04-22",
        "nepDate": "10.01.2030"
    },
    {
        "engDate": "1973-04-23",
        "nepDate": "11.01.2030"
    },
    {
        "engDate": "1973-04-24",
        "nepDate": "12.01.2030"
    },
    {
        "engDate": "1973-04-25",
        "nepDate": "13.01.2030"
    },
    {
        "engDate": "1973-04-26",
        "nepDate": "14.01.2030"
    },
    {
        "engDate": "1973-04-27",
        "nepDate": "15.01.2030"
    },
    {
        "engDate": "1973-04-28",
        "nepDate": "16.01.2030"
    },
    {
        "engDate": "1973-04-29",
        "nepDate": "17.01.2030"
    },
    {
        "engDate": "1973-04-30",
        "nepDate": "18.01.2030"
    },
    {
        "engDate": "1973-05-01",
        "nepDate": "19.01.2030"
    },
    {
        "engDate": "1973-05-02",
        "nepDate": "20.01.2030"
    },
    {
        "engDate": "1973-05-03",
        "nepDate": "21.01.2030"
    },
    {
        "engDate": "1973-05-04",
        "nepDate": "22.01.2030"
    },
    {
        "engDate": "1973-05-05",
        "nepDate": "23.01.2030"
    },
    {
        "engDate": "1973-05-06",
        "nepDate": "24.01.2030"
    },
    {
        "engDate": "1973-05-07",
        "nepDate": "25.01.2030"
    },
    {
        "engDate": "1973-05-08",
        "nepDate": "26.01.2030"
    },
    {
        "engDate": "1973-05-09",
        "nepDate": "27.01.2030"
    },
    {
        "engDate": "1973-05-10",
        "nepDate": "28.01.2030"
    },
    {
        "engDate": "1973-05-11",
        "nepDate": "29.01.2030"
    },
    {
        "engDate": "1973-05-12",
        "nepDate": "30.01.2030"
    },
    {
        "engDate": "1973-05-13",
        "nepDate": "31.01.2030"
    },
    {
        "engDate": "1973-05-14",
        "nepDate": "01.02.2030"
    },
    {
        "engDate": "1973-05-15",
        "nepDate": "02.02.2030"
    },
    {
        "engDate": "1973-05-16",
        "nepDate": "03.02.2030"
    },
    {
        "engDate": "1973-05-17",
        "nepDate": "04.02.2030"
    },
    {
        "engDate": "1973-05-18",
        "nepDate": "05.02.2030"
    },
    {
        "engDate": "1973-05-19",
        "nepDate": "06.02.2030"
    },
    {
        "engDate": "1973-05-20",
        "nepDate": "07.02.2030"
    },
    {
        "engDate": "1973-05-21",
        "nepDate": "08.02.2030"
    },
    {
        "engDate": "1973-05-22",
        "nepDate": "09.02.2030"
    },
    {
        "engDate": "1973-05-23",
        "nepDate": "10.02.2030"
    },
    {
        "engDate": "1973-05-24",
        "nepDate": "11.02.2030"
    },
    {
        "engDate": "1973-05-25",
        "nepDate": "12.02.2030"
    },
    {
        "engDate": "1973-05-26",
        "nepDate": "13.02.2030"
    },
    {
        "engDate": "1973-05-27",
        "nepDate": "14.02.2030"
    },
    {
        "engDate": "1973-05-28",
        "nepDate": "15.02.2030"
    },
    {
        "engDate": "1973-05-29",
        "nepDate": "16.02.2030"
    },
    {
        "engDate": "1973-05-30",
        "nepDate": "17.02.2030"
    },
    {
        "engDate": "1973-05-31",
        "nepDate": "18.02.2030"
    },
    {
        "engDate": "1973-06-01",
        "nepDate": "19.02.2030"
    },
    {
        "engDate": "1973-06-02",
        "nepDate": "20.02.2030"
    },
    {
        "engDate": "1973-06-03",
        "nepDate": "21.02.2030"
    },
    {
        "engDate": "1973-06-04",
        "nepDate": "22.02.2030"
    },
    {
        "engDate": "1973-06-05",
        "nepDate": "23.02.2030"
    },
    {
        "engDate": "1973-06-06",
        "nepDate": "24.02.2030"
    },
    {
        "engDate": "1973-06-07",
        "nepDate": "25.02.2030"
    },
    {
        "engDate": "1973-06-08",
        "nepDate": "26.02.2030"
    },
    {
        "engDate": "1973-06-09",
        "nepDate": "27.02.2030"
    },
    {
        "engDate": "1973-06-10",
        "nepDate": "28.02.2030"
    },
    {
        "engDate": "1973-06-11",
        "nepDate": "29.02.2030"
    },
    {
        "engDate": "1973-06-12",
        "nepDate": "30.02.2030"
    },
    {
        "engDate": "1973-06-13",
        "nepDate": "31.02.2030"
    },
    {
        "engDate": "1973-06-14",
        "nepDate": "32.02.2030"
    },
    {
        "engDate": "1973-06-15",
        "nepDate": "01.03.2030"
    },
    {
        "engDate": "1973-06-16",
        "nepDate": "02.03.2030"
    },
    {
        "engDate": "1973-06-17",
        "nepDate": "03.03.2030"
    },
    {
        "engDate": "1973-06-18",
        "nepDate": "04.03.2030"
    },
    {
        "engDate": "1973-06-19",
        "nepDate": "05.03.2030"
    },
    {
        "engDate": "1973-06-20",
        "nepDate": "06.03.2030"
    },
    {
        "engDate": "1973-06-21",
        "nepDate": "07.03.2030"
    },
    {
        "engDate": "1973-06-22",
        "nepDate": "08.03.2030"
    },
    {
        "engDate": "1973-06-23",
        "nepDate": "09.03.2030"
    },
    {
        "engDate": "1973-06-24",
        "nepDate": "10.03.2030"
    },
    {
        "engDate": "1973-06-25",
        "nepDate": "11.03.2030"
    },
    {
        "engDate": "1973-06-26",
        "nepDate": "12.03.2030"
    },
    {
        "engDate": "1973-06-27",
        "nepDate": "13.03.2030"
    },
    {
        "engDate": "1973-06-28",
        "nepDate": "14.03.2030"
    },
    {
        "engDate": "1973-06-29",
        "nepDate": "15.03.2030"
    },
    {
        "engDate": "1973-06-30",
        "nepDate": "16.03.2030"
    },
    {
        "engDate": "1973-07-01",
        "nepDate": "17.03.2030"
    },
    {
        "engDate": "1973-07-02",
        "nepDate": "18.03.2030"
    },
    {
        "engDate": "1973-07-03",
        "nepDate": "19.03.2030"
    },
    {
        "engDate": "1973-07-04",
        "nepDate": "20.03.2030"
    },
    {
        "engDate": "1973-07-05",
        "nepDate": "21.03.2030"
    },
    {
        "engDate": "1973-07-06",
        "nepDate": "22.03.2030"
    },
    {
        "engDate": "1973-07-07",
        "nepDate": "23.03.2030"
    },
    {
        "engDate": "1973-07-08",
        "nepDate": "24.03.2030"
    },
    {
        "engDate": "1973-07-09",
        "nepDate": "25.03.2030"
    },
    {
        "engDate": "1973-07-10",
        "nepDate": "26.03.2030"
    },
    {
        "engDate": "1973-07-11",
        "nepDate": "27.03.2030"
    },
    {
        "engDate": "1973-07-12",
        "nepDate": "28.03.2030"
    },
    {
        "engDate": "1973-07-13",
        "nepDate": "29.03.2030"
    },
    {
        "engDate": "1973-07-14",
        "nepDate": "30.03.2030"
    },
    {
        "engDate": "1973-07-15",
        "nepDate": "31.03.2030"
    },
    {
        "engDate": "1973-07-16",
        "nepDate": "01.04.2030"
    },
    {
        "engDate": "1973-07-17",
        "nepDate": "02.04.2030"
    },
    {
        "engDate": "1973-07-18",
        "nepDate": "03.04.2030"
    },
    {
        "engDate": "1973-07-19",
        "nepDate": "04.04.2030"
    },
    {
        "engDate": "1973-07-20",
        "nepDate": "05.04.2030"
    },
    {
        "engDate": "1973-07-21",
        "nepDate": "06.04.2030"
    },
    {
        "engDate": "1973-07-22",
        "nepDate": "07.04.2030"
    },
    {
        "engDate": "1973-07-23",
        "nepDate": "08.04.2030"
    },
    {
        "engDate": "1973-07-24",
        "nepDate": "09.04.2030"
    },
    {
        "engDate": "1973-07-25",
        "nepDate": "10.04.2030"
    },
    {
        "engDate": "1973-07-26",
        "nepDate": "11.04.2030"
    },
    {
        "engDate": "1973-07-27",
        "nepDate": "12.04.2030"
    },
    {
        "engDate": "1973-07-28",
        "nepDate": "13.04.2030"
    },
    {
        "engDate": "1973-07-29",
        "nepDate": "14.04.2030"
    },
    {
        "engDate": "1973-07-30",
        "nepDate": "15.04.2030"
    },
    {
        "engDate": "1973-07-31",
        "nepDate": "16.04.2030"
    },
    {
        "engDate": "1973-08-01",
        "nepDate": "17.04.2030"
    },
    {
        "engDate": "1973-08-02",
        "nepDate": "18.04.2030"
    },
    {
        "engDate": "1973-08-03",
        "nepDate": "19.04.2030"
    },
    {
        "engDate": "1973-08-04",
        "nepDate": "20.04.2030"
    },
    {
        "engDate": "1973-08-05",
        "nepDate": "21.04.2030"
    },
    {
        "engDate": "1973-08-06",
        "nepDate": "22.04.2030"
    },
    {
        "engDate": "1973-08-07",
        "nepDate": "23.04.2030"
    },
    {
        "engDate": "1973-08-08",
        "nepDate": "24.04.2030"
    },
    {
        "engDate": "1973-08-09",
        "nepDate": "25.04.2030"
    },
    {
        "engDate": "1973-08-10",
        "nepDate": "26.04.2030"
    },
    {
        "engDate": "1973-08-11",
        "nepDate": "27.04.2030"
    },
    {
        "engDate": "1973-08-12",
        "nepDate": "28.04.2030"
    },
    {
        "engDate": "1973-08-13",
        "nepDate": "29.04.2030"
    },
    {
        "engDate": "1973-08-14",
        "nepDate": "30.04.2030"
    },
    {
        "engDate": "1973-08-15",
        "nepDate": "31.04.2030"
    },
    {
        "engDate": "1973-08-16",
        "nepDate": "32.04.2030"
    },
    {
        "engDate": "1973-08-17",
        "nepDate": "01.05.2030"
    },
    {
        "engDate": "1973-08-18",
        "nepDate": "02.05.2030"
    },
    {
        "engDate": "1973-08-19",
        "nepDate": "03.05.2030"
    },
    {
        "engDate": "1973-08-20",
        "nepDate": "04.05.2030"
    },
    {
        "engDate": "1973-08-21",
        "nepDate": "05.05.2030"
    },
    {
        "engDate": "1973-08-22",
        "nepDate": "06.05.2030"
    },
    {
        "engDate": "1973-08-23",
        "nepDate": "07.05.2030"
    },
    {
        "engDate": "1973-08-24",
        "nepDate": "08.05.2030"
    },
    {
        "engDate": "1973-08-25",
        "nepDate": "09.05.2030"
    },
    {
        "engDate": "1973-08-26",
        "nepDate": "10.05.2030"
    },
    {
        "engDate": "1973-08-27",
        "nepDate": "11.05.2030"
    },
    {
        "engDate": "1973-08-28",
        "nepDate": "12.05.2030"
    },
    {
        "engDate": "1973-08-29",
        "nepDate": "13.05.2030"
    },
    {
        "engDate": "1973-08-30",
        "nepDate": "14.05.2030"
    },
    {
        "engDate": "1973-08-31",
        "nepDate": "15.05.2030"
    },
    {
        "engDate": "1973-09-01",
        "nepDate": "16.05.2030"
    },
    {
        "engDate": "1973-09-02",
        "nepDate": "17.05.2030"
    },
    {
        "engDate": "1973-09-03",
        "nepDate": "18.05.2030"
    },
    {
        "engDate": "1973-09-04",
        "nepDate": "19.05.2030"
    },
    {
        "engDate": "1973-09-05",
        "nepDate": "20.05.2030"
    },
    {
        "engDate": "1973-09-06",
        "nepDate": "21.05.2030"
    },
    {
        "engDate": "1973-09-07",
        "nepDate": "22.05.2030"
    },
    {
        "engDate": "1973-09-08",
        "nepDate": "23.05.2030"
    },
    {
        "engDate": "1973-09-09",
        "nepDate": "24.05.2030"
    },
    {
        "engDate": "1973-09-10",
        "nepDate": "25.05.2030"
    },
    {
        "engDate": "1973-09-11",
        "nepDate": "26.05.2030"
    },
    {
        "engDate": "1973-09-12",
        "nepDate": "27.05.2030"
    },
    {
        "engDate": "1973-09-13",
        "nepDate": "28.05.2030"
    },
    {
        "engDate": "1973-09-14",
        "nepDate": "29.05.2030"
    },
    {
        "engDate": "1973-09-15",
        "nepDate": "30.05.2030"
    },
    {
        "engDate": "1973-09-16",
        "nepDate": "31.05.2030"
    },
    {
        "engDate": "1973-09-17",
        "nepDate": "01.06.2030"
    },
    {
        "engDate": "1973-09-18",
        "nepDate": "02.06.2030"
    },
    {
        "engDate": "1973-09-19",
        "nepDate": "03.06.2030"
    },
    {
        "engDate": "1973-09-20",
        "nepDate": "04.06.2030"
    },
    {
        "engDate": "1973-09-21",
        "nepDate": "05.06.2030"
    },
    {
        "engDate": "1973-09-22",
        "nepDate": "06.06.2030"
    },
    {
        "engDate": "1973-09-23",
        "nepDate": "07.06.2030"
    },
    {
        "engDate": "1973-09-24",
        "nepDate": "08.06.2030"
    },
    {
        "engDate": "1973-09-25",
        "nepDate": "09.06.2030"
    },
    {
        "engDate": "1973-09-26",
        "nepDate": "10.06.2030"
    },
    {
        "engDate": "1973-09-27",
        "nepDate": "11.06.2030"
    },
    {
        "engDate": "1973-09-28",
        "nepDate": "12.06.2030"
    },
    {
        "engDate": "1973-09-29",
        "nepDate": "13.06.2030"
    },
    {
        "engDate": "1973-09-30",
        "nepDate": "14.06.2030"
    },
    {
        "engDate": "1973-10-01",
        "nepDate": "15.06.2030"
    },
    {
        "engDate": "1973-10-02",
        "nepDate": "16.06.2030"
    },
    {
        "engDate": "1973-10-03",
        "nepDate": "17.06.2030"
    },
    {
        "engDate": "1973-10-04",
        "nepDate": "18.06.2030"
    },
    {
        "engDate": "1973-10-05",
        "nepDate": "19.06.2030"
    },
    {
        "engDate": "1973-10-06",
        "nepDate": "20.06.2030"
    },
    {
        "engDate": "1973-10-07",
        "nepDate": "21.06.2030"
    },
    {
        "engDate": "1973-10-08",
        "nepDate": "22.06.2030"
    },
    {
        "engDate": "1973-10-09",
        "nepDate": "23.06.2030"
    },
    {
        "engDate": "1973-10-10",
        "nepDate": "24.06.2030"
    },
    {
        "engDate": "1973-10-11",
        "nepDate": "25.06.2030"
    },
    {
        "engDate": "1973-10-12",
        "nepDate": "26.06.2030"
    },
    {
        "engDate": "1973-10-13",
        "nepDate": "27.06.2030"
    },
    {
        "engDate": "1973-10-14",
        "nepDate": "28.06.2030"
    },
    {
        "engDate": "1973-10-15",
        "nepDate": "29.06.2030"
    },
    {
        "engDate": "1973-10-16",
        "nepDate": "30.06.2030"
    },
    {
        "engDate": "1973-10-17",
        "nepDate": "01.07.2030"
    },
    {
        "engDate": "1973-10-18",
        "nepDate": "02.07.2030"
    },
    {
        "engDate": "1973-10-19",
        "nepDate": "03.07.2030"
    },
    {
        "engDate": "1973-10-20",
        "nepDate": "04.07.2030"
    },
    {
        "engDate": "1973-10-21",
        "nepDate": "05.07.2030"
    },
    {
        "engDate": "1973-10-22",
        "nepDate": "06.07.2030"
    },
    {
        "engDate": "1973-10-23",
        "nepDate": "07.07.2030"
    },
    {
        "engDate": "1973-10-24",
        "nepDate": "08.07.2030"
    },
    {
        "engDate": "1973-10-25",
        "nepDate": "09.07.2030"
    },
    {
        "engDate": "1973-10-26",
        "nepDate": "10.07.2030"
    },
    {
        "engDate": "1973-10-27",
        "nepDate": "11.07.2030"
    },
    {
        "engDate": "1973-10-28",
        "nepDate": "12.07.2030"
    },
    {
        "engDate": "1973-10-29",
        "nepDate": "13.07.2030"
    },
    {
        "engDate": "1973-10-30",
        "nepDate": "14.07.2030"
    },
    {
        "engDate": "1973-10-31",
        "nepDate": "15.07.2030"
    },
    {
        "engDate": "1973-11-01",
        "nepDate": "16.07.2030"
    },
    {
        "engDate": "1973-11-02",
        "nepDate": "17.07.2030"
    },
    {
        "engDate": "1973-11-03",
        "nepDate": "18.07.2030"
    },
    {
        "engDate": "1973-11-04",
        "nepDate": "19.07.2030"
    },
    {
        "engDate": "1973-11-05",
        "nepDate": "20.07.2030"
    },
    {
        "engDate": "1973-11-06",
        "nepDate": "21.07.2030"
    },
    {
        "engDate": "1973-11-07",
        "nepDate": "22.07.2030"
    },
    {
        "engDate": "1973-11-08",
        "nepDate": "23.07.2030"
    },
    {
        "engDate": "1973-11-09",
        "nepDate": "24.07.2030"
    },
    {
        "engDate": "1973-11-10",
        "nepDate": "25.07.2030"
    },
    {
        "engDate": "1973-11-11",
        "nepDate": "26.07.2030"
    },
    {
        "engDate": "1973-11-12",
        "nepDate": "27.07.2030"
    },
    {
        "engDate": "1973-11-13",
        "nepDate": "28.07.2030"
    },
    {
        "engDate": "1973-11-14",
        "nepDate": "29.07.2030"
    },
    {
        "engDate": "1973-11-15",
        "nepDate": "30.07.2030"
    },
    {
        "engDate": "1973-11-16",
        "nepDate": "01.08.2030"
    },
    {
        "engDate": "1973-11-17",
        "nepDate": "02.08.2030"
    },
    {
        "engDate": "1973-11-18",
        "nepDate": "03.08.2030"
    },
    {
        "engDate": "1973-11-19",
        "nepDate": "04.08.2030"
    },
    {
        "engDate": "1973-11-20",
        "nepDate": "05.08.2030"
    },
    {
        "engDate": "1973-11-21",
        "nepDate": "06.08.2030"
    },
    {
        "engDate": "1973-11-22",
        "nepDate": "07.08.2030"
    },
    {
        "engDate": "1973-11-23",
        "nepDate": "08.08.2030"
    },
    {
        "engDate": "1973-11-24",
        "nepDate": "09.08.2030"
    },
    {
        "engDate": "1973-11-25",
        "nepDate": "10.08.2030"
    },
    {
        "engDate": "1973-11-26",
        "nepDate": "11.08.2030"
    },
    {
        "engDate": "1973-11-27",
        "nepDate": "12.08.2030"
    },
    {
        "engDate": "1973-11-28",
        "nepDate": "13.08.2030"
    },
    {
        "engDate": "1973-11-29",
        "nepDate": "14.08.2030"
    },
    {
        "engDate": "1973-11-30",
        "nepDate": "15.08.2030"
    },
    {
        "engDate": "1973-12-01",
        "nepDate": "16.08.2030"
    },
    {
        "engDate": "1973-12-02",
        "nepDate": "17.08.2030"
    },
    {
        "engDate": "1973-12-03",
        "nepDate": "18.08.2030"
    },
    {
        "engDate": "1973-12-04",
        "nepDate": "19.08.2030"
    },
    {
        "engDate": "1973-12-05",
        "nepDate": "20.08.2030"
    },
    {
        "engDate": "1973-12-06",
        "nepDate": "21.08.2030"
    },
    {
        "engDate": "1973-12-07",
        "nepDate": "22.08.2030"
    },
    {
        "engDate": "1973-12-08",
        "nepDate": "23.08.2030"
    },
    {
        "engDate": "1973-12-09",
        "nepDate": "24.08.2030"
    },
    {
        "engDate": "1973-12-10",
        "nepDate": "25.08.2030"
    },
    {
        "engDate": "1973-12-11",
        "nepDate": "26.08.2030"
    },
    {
        "engDate": "1973-12-12",
        "nepDate": "27.08.2030"
    },
    {
        "engDate": "1973-12-13",
        "nepDate": "28.08.2030"
    },
    {
        "engDate": "1973-12-14",
        "nepDate": "29.08.2030"
    },
    {
        "engDate": "1973-12-15",
        "nepDate": "30.08.2030"
    },
    {
        "engDate": "1973-12-16",
        "nepDate": "01.09.2030"
    },
    {
        "engDate": "1973-12-17",
        "nepDate": "02.09.2030"
    },
    {
        "engDate": "1973-12-18",
        "nepDate": "03.09.2030"
    },
    {
        "engDate": "1973-12-19",
        "nepDate": "04.09.2030"
    },
    {
        "engDate": "1973-12-20",
        "nepDate": "05.09.2030"
    },
    {
        "engDate": "1973-12-21",
        "nepDate": "06.09.2030"
    },
    {
        "engDate": "1973-12-22",
        "nepDate": "07.09.2030"
    },
    {
        "engDate": "1973-12-23",
        "nepDate": "08.09.2030"
    },
    {
        "engDate": "1973-12-24",
        "nepDate": "09.09.2030"
    },
    {
        "engDate": "1973-12-25",
        "nepDate": "10.09.2030"
    },
    {
        "engDate": "1973-12-26",
        "nepDate": "11.09.2030"
    },
    {
        "engDate": "1973-12-27",
        "nepDate": "12.09.2030"
    },
    {
        "engDate": "1973-12-28",
        "nepDate": "13.09.2030"
    },
    {
        "engDate": "1973-12-29",
        "nepDate": "14.09.2030"
    },
    {
        "engDate": "1973-12-30",
        "nepDate": "15.09.2030"
    },
    {
        "engDate": "1973-12-31",
        "nepDate": "16.09.2030"
    },
    {
        "engDate": "1974-01-01",
        "nepDate": "17.09.2030"
    },
    {
        "engDate": "1974-01-02",
        "nepDate": "18.09.2030"
    },
    {
        "engDate": "1974-01-03",
        "nepDate": "19.09.2030"
    },
    {
        "engDate": "1974-01-04",
        "nepDate": "20.09.2030"
    },
    {
        "engDate": "1974-01-05",
        "nepDate": "21.09.2030"
    },
    {
        "engDate": "1974-01-06",
        "nepDate": "22.09.2030"
    },
    {
        "engDate": "1974-01-07",
        "nepDate": "23.09.2030"
    },
    {
        "engDate": "1974-01-08",
        "nepDate": "24.09.2030"
    },
    {
        "engDate": "1974-01-09",
        "nepDate": "25.09.2030"
    },
    {
        "engDate": "1974-01-10",
        "nepDate": "26.09.2030"
    },
    {
        "engDate": "1974-01-11",
        "nepDate": "27.09.2030"
    },
    {
        "engDate": "1974-01-12",
        "nepDate": "28.09.2030"
    },
    {
        "engDate": "1974-01-13",
        "nepDate": "29.09.2030"
    },
    {
        "engDate": "1974-01-14",
        "nepDate": "01.10.2030"
    },
    {
        "engDate": "1974-01-15",
        "nepDate": "02.10.2030"
    },
    {
        "engDate": "1974-01-16",
        "nepDate": "03.10.2030"
    },
    {
        "engDate": "1974-01-17",
        "nepDate": "04.10.2030"
    },
    {
        "engDate": "1974-01-18",
        "nepDate": "05.10.2030"
    },
    {
        "engDate": "1974-01-19",
        "nepDate": "06.10.2030"
    },
    {
        "engDate": "1974-01-20",
        "nepDate": "07.10.2030"
    },
    {
        "engDate": "1974-01-21",
        "nepDate": "08.10.2030"
    },
    {
        "engDate": "1974-01-22",
        "nepDate": "09.10.2030"
    },
    {
        "engDate": "1974-01-23",
        "nepDate": "10.10.2030"
    },
    {
        "engDate": "1974-01-24",
        "nepDate": "11.10.2030"
    },
    {
        "engDate": "1974-01-25",
        "nepDate": "12.10.2030"
    },
    {
        "engDate": "1974-01-26",
        "nepDate": "13.10.2030"
    },
    {
        "engDate": "1974-01-27",
        "nepDate": "14.10.2030"
    },
    {
        "engDate": "1974-01-28",
        "nepDate": "15.10.2030"
    },
    {
        "engDate": "1974-01-29",
        "nepDate": "16.10.2030"
    },
    {
        "engDate": "1974-01-30",
        "nepDate": "17.10.2030"
    },
    {
        "engDate": "1974-01-31",
        "nepDate": "18.10.2030"
    },
    {
        "engDate": "1974-02-01",
        "nepDate": "19.10.2030"
    },
    {
        "engDate": "1974-02-02",
        "nepDate": "20.10.2030"
    },
    {
        "engDate": "1974-02-03",
        "nepDate": "21.10.2030"
    },
    {
        "engDate": "1974-02-04",
        "nepDate": "22.10.2030"
    },
    {
        "engDate": "1974-02-05",
        "nepDate": "23.10.2030"
    },
    {
        "engDate": "1974-02-06",
        "nepDate": "24.10.2030"
    },
    {
        "engDate": "1974-02-07",
        "nepDate": "25.10.2030"
    },
    {
        "engDate": "1974-02-08",
        "nepDate": "26.10.2030"
    },
    {
        "engDate": "1974-02-09",
        "nepDate": "27.10.2030"
    },
    {
        "engDate": "1974-02-10",
        "nepDate": "28.10.2030"
    },
    {
        "engDate": "1974-02-11",
        "nepDate": "29.10.2030"
    },
    {
        "engDate": "1974-02-12",
        "nepDate": "01.11.2030"
    },
    {
        "engDate": "1974-02-13",
        "nepDate": "02.11.2030"
    },
    {
        "engDate": "1974-02-14",
        "nepDate": "03.11.2030"
    },
    {
        "engDate": "1974-02-15",
        "nepDate": "04.11.2030"
    },
    {
        "engDate": "1974-02-16",
        "nepDate": "05.11.2030"
    },
    {
        "engDate": "1974-02-17",
        "nepDate": "06.11.2030"
    },
    {
        "engDate": "1974-02-18",
        "nepDate": "07.11.2030"
    },
    {
        "engDate": "1974-02-19",
        "nepDate": "08.11.2030"
    },
    {
        "engDate": "1974-02-20",
        "nepDate": "09.11.2030"
    },
    {
        "engDate": "1974-02-21",
        "nepDate": "10.11.2030"
    },
    {
        "engDate": "1974-02-22",
        "nepDate": "11.11.2030"
    },
    {
        "engDate": "1974-02-23",
        "nepDate": "12.11.2030"
    },
    {
        "engDate": "1974-02-24",
        "nepDate": "13.11.2030"
    },
    {
        "engDate": "1974-02-25",
        "nepDate": "14.11.2030"
    },
    {
        "engDate": "1974-02-26",
        "nepDate": "15.11.2030"
    },
    {
        "engDate": "1974-02-27",
        "nepDate": "16.11.2030"
    },
    {
        "engDate": "1974-02-28",
        "nepDate": "17.11.2030"
    },
    {
        "engDate": "1974-03-01",
        "nepDate": "18.11.2030"
    },
    {
        "engDate": "1974-03-02",
        "nepDate": "19.11.2030"
    },
    {
        "engDate": "1974-03-03",
        "nepDate": "20.11.2030"
    },
    {
        "engDate": "1974-03-04",
        "nepDate": "21.11.2030"
    },
    {
        "engDate": "1974-03-05",
        "nepDate": "22.11.2030"
    },
    {
        "engDate": "1974-03-06",
        "nepDate": "23.11.2030"
    },
    {
        "engDate": "1974-03-07",
        "nepDate": "24.11.2030"
    },
    {
        "engDate": "1974-03-08",
        "nepDate": "25.11.2030"
    },
    {
        "engDate": "1974-03-09",
        "nepDate": "26.11.2030"
    },
    {
        "engDate": "1974-03-10",
        "nepDate": "27.11.2030"
    },
    {
        "engDate": "1974-03-11",
        "nepDate": "28.11.2030"
    },
    {
        "engDate": "1974-03-12",
        "nepDate": "29.11.2030"
    },
    {
        "engDate": "1974-03-13",
        "nepDate": "30.11.2030"
    },
    {
        "engDate": "1974-03-14",
        "nepDate": "01.12.2030"
    },
    {
        "engDate": "1974-03-15",
        "nepDate": "02.12.2030"
    },
    {
        "engDate": "1974-03-16",
        "nepDate": "03.12.2030"
    },
    {
        "engDate": "1974-03-17",
        "nepDate": "04.12.2030"
    },
    {
        "engDate": "1974-03-18",
        "nepDate": "05.12.2030"
    },
    {
        "engDate": "1974-03-19",
        "nepDate": "06.12.2030"
    },
    {
        "engDate": "1974-03-20",
        "nepDate": "07.12.2030"
    },
    {
        "engDate": "1974-03-21",
        "nepDate": "08.12.2030"
    },
    {
        "engDate": "1974-03-22",
        "nepDate": "09.12.2030"
    },
    {
        "engDate": "1974-03-23",
        "nepDate": "10.12.2030"
    },
    {
        "engDate": "1974-03-24",
        "nepDate": "11.12.2030"
    },
    {
        "engDate": "1974-03-25",
        "nepDate": "12.12.2030"
    },
    {
        "engDate": "1974-03-26",
        "nepDate": "13.12.2030"
    },
    {
        "engDate": "1974-03-27",
        "nepDate": "14.12.2030"
    },
    {
        "engDate": "1974-03-28",
        "nepDate": "15.12.2030"
    },
    {
        "engDate": "1974-03-29",
        "nepDate": "16.12.2030"
    },
    {
        "engDate": "1974-03-30",
        "nepDate": "17.12.2030"
    },
    {
        "engDate": "1974-03-31",
        "nepDate": "18.12.2030"
    },
    {
        "engDate": "1974-04-01",
        "nepDate": "19.12.2030"
    },
    {
        "engDate": "1974-04-02",
        "nepDate": "20.12.2030"
    },
    {
        "engDate": "1974-04-03",
        "nepDate": "21.12.2030"
    },
    {
        "engDate": "1974-04-04",
        "nepDate": "22.12.2030"
    },
    {
        "engDate": "1974-04-05",
        "nepDate": "23.12.2030"
    },
    {
        "engDate": "1974-04-06",
        "nepDate": "24.12.2030"
    },
    {
        "engDate": "1974-04-07",
        "nepDate": "25.12.2030"
    },
    {
        "engDate": "1974-04-08",
        "nepDate": "26.12.2030"
    },
    {
        "engDate": "1974-04-09",
        "nepDate": "27.12.2030"
    },
    {
        "engDate": "1974-04-10",
        "nepDate": "28.12.2030"
    },
    {
        "engDate": "1974-04-11",
        "nepDate": "29.12.2030"
    },
    {
        "engDate": "1974-04-12",
        "nepDate": "30.12.2030"
    },
    {
        "engDate": "1974-04-13",
        "nepDate": "31.12.2030"
    },
    {
        "engDate": "1974-04-14",
        "nepDate": "01.01.2031"
    },
    {
        "engDate": "1974-04-15",
        "nepDate": "02.01.2031"
    },
    {
        "engDate": "1974-04-16",
        "nepDate": "03.01.2031"
    },
    {
        "engDate": "1974-04-17",
        "nepDate": "04.01.2031"
    },
    {
        "engDate": "1974-04-18",
        "nepDate": "05.01.2031"
    },
    {
        "engDate": "1974-04-19",
        "nepDate": "06.01.2031"
    },
    {
        "engDate": "1974-04-20",
        "nepDate": "07.01.2031"
    },
    {
        "engDate": "1974-04-21",
        "nepDate": "08.01.2031"
    },
    {
        "engDate": "1974-04-22",
        "nepDate": "09.01.2031"
    },
    {
        "engDate": "1974-04-23",
        "nepDate": "10.01.2031"
    },
    {
        "engDate": "1974-04-24",
        "nepDate": "11.01.2031"
    },
    {
        "engDate": "1974-04-25",
        "nepDate": "12.01.2031"
    },
    {
        "engDate": "1974-04-26",
        "nepDate": "13.01.2031"
    },
    {
        "engDate": "1974-04-27",
        "nepDate": "14.01.2031"
    },
    {
        "engDate": "1974-04-28",
        "nepDate": "15.01.2031"
    },
    {
        "engDate": "1974-04-29",
        "nepDate": "16.01.2031"
    },
    {
        "engDate": "1974-04-30",
        "nepDate": "17.01.2031"
    },
    {
        "engDate": "1974-05-01",
        "nepDate": "18.01.2031"
    },
    {
        "engDate": "1974-05-02",
        "nepDate": "19.01.2031"
    },
    {
        "engDate": "1974-05-03",
        "nepDate": "20.01.2031"
    },
    {
        "engDate": "1974-05-04",
        "nepDate": "21.01.2031"
    },
    {
        "engDate": "1974-05-05",
        "nepDate": "22.01.2031"
    },
    {
        "engDate": "1974-05-06",
        "nepDate": "23.01.2031"
    },
    {
        "engDate": "1974-05-07",
        "nepDate": "24.01.2031"
    },
    {
        "engDate": "1974-05-08",
        "nepDate": "25.01.2031"
    },
    {
        "engDate": "1974-05-09",
        "nepDate": "26.01.2031"
    },
    {
        "engDate": "1974-05-10",
        "nepDate": "27.01.2031"
    },
    {
        "engDate": "1974-05-11",
        "nepDate": "28.01.2031"
    },
    {
        "engDate": "1974-05-12",
        "nepDate": "29.01.2031"
    },
    {
        "engDate": "1974-05-13",
        "nepDate": "30.01.2031"
    },
    {
        "engDate": "1974-05-14",
        "nepDate": "01.02.2031"
    },
    {
        "engDate": "1974-05-15",
        "nepDate": "02.02.2031"
    },
    {
        "engDate": "1974-05-16",
        "nepDate": "03.02.2031"
    },
    {
        "engDate": "1974-05-17",
        "nepDate": "04.02.2031"
    },
    {
        "engDate": "1974-05-18",
        "nepDate": "05.02.2031"
    },
    {
        "engDate": "1974-05-19",
        "nepDate": "06.02.2031"
    },
    {
        "engDate": "1974-05-20",
        "nepDate": "07.02.2031"
    },
    {
        "engDate": "1974-05-21",
        "nepDate": "08.02.2031"
    },
    {
        "engDate": "1974-05-22",
        "nepDate": "09.02.2031"
    },
    {
        "engDate": "1974-05-23",
        "nepDate": "10.02.2031"
    },
    {
        "engDate": "1974-05-24",
        "nepDate": "11.02.2031"
    },
    {
        "engDate": "1974-05-25",
        "nepDate": "12.02.2031"
    },
    {
        "engDate": "1974-05-26",
        "nepDate": "13.02.2031"
    },
    {
        "engDate": "1974-05-27",
        "nepDate": "14.02.2031"
    },
    {
        "engDate": "1974-05-28",
        "nepDate": "15.02.2031"
    },
    {
        "engDate": "1974-05-29",
        "nepDate": "16.02.2031"
    },
    {
        "engDate": "1974-05-30",
        "nepDate": "17.02.2031"
    },
    {
        "engDate": "1974-05-31",
        "nepDate": "18.02.2031"
    },
    {
        "engDate": "1974-06-01",
        "nepDate": "19.02.2031"
    },
    {
        "engDate": "1974-06-02",
        "nepDate": "20.02.2031"
    },
    {
        "engDate": "1974-06-03",
        "nepDate": "21.02.2031"
    },
    {
        "engDate": "1974-06-04",
        "nepDate": "22.02.2031"
    },
    {
        "engDate": "1974-06-05",
        "nepDate": "23.02.2031"
    },
    {
        "engDate": "1974-06-06",
        "nepDate": "24.02.2031"
    },
    {
        "engDate": "1974-06-07",
        "nepDate": "25.02.2031"
    },
    {
        "engDate": "1974-06-08",
        "nepDate": "26.02.2031"
    },
    {
        "engDate": "1974-06-09",
        "nepDate": "27.02.2031"
    },
    {
        "engDate": "1974-06-10",
        "nepDate": "28.02.2031"
    },
    {
        "engDate": "1974-06-11",
        "nepDate": "29.02.2031"
    },
    {
        "engDate": "1974-06-12",
        "nepDate": "30.02.2031"
    },
    {
        "engDate": "1974-06-13",
        "nepDate": "31.02.2031"
    },
    {
        "engDate": "1974-06-14",
        "nepDate": "32.02.2031"
    },
    {
        "engDate": "1974-06-15",
        "nepDate": "01.03.2031"
    },
    {
        "engDate": "1974-06-16",
        "nepDate": "02.03.2031"
    },
    {
        "engDate": "1974-06-17",
        "nepDate": "03.03.2031"
    },
    {
        "engDate": "1974-06-18",
        "nepDate": "04.03.2031"
    },
    {
        "engDate": "1974-06-19",
        "nepDate": "05.03.2031"
    },
    {
        "engDate": "1974-06-20",
        "nepDate": "06.03.2031"
    },
    {
        "engDate": "1974-06-21",
        "nepDate": "07.03.2031"
    },
    {
        "engDate": "1974-06-22",
        "nepDate": "08.03.2031"
    },
    {
        "engDate": "1974-06-23",
        "nepDate": "09.03.2031"
    },
    {
        "engDate": "1974-06-24",
        "nepDate": "10.03.2031"
    },
    {
        "engDate": "1974-06-25",
        "nepDate": "11.03.2031"
    },
    {
        "engDate": "1974-06-26",
        "nepDate": "12.03.2031"
    },
    {
        "engDate": "1974-06-27",
        "nepDate": "13.03.2031"
    },
    {
        "engDate": "1974-06-28",
        "nepDate": "14.03.2031"
    },
    {
        "engDate": "1974-06-29",
        "nepDate": "15.03.2031"
    },
    {
        "engDate": "1974-06-30",
        "nepDate": "16.03.2031"
    },
    {
        "engDate": "1974-07-01",
        "nepDate": "17.03.2031"
    },
    {
        "engDate": "1974-07-02",
        "nepDate": "18.03.2031"
    },
    {
        "engDate": "1974-07-03",
        "nepDate": "19.03.2031"
    },
    {
        "engDate": "1974-07-04",
        "nepDate": "20.03.2031"
    },
    {
        "engDate": "1974-07-05",
        "nepDate": "21.03.2031"
    },
    {
        "engDate": "1974-07-06",
        "nepDate": "22.03.2031"
    },
    {
        "engDate": "1974-07-07",
        "nepDate": "23.03.2031"
    },
    {
        "engDate": "1974-07-08",
        "nepDate": "24.03.2031"
    },
    {
        "engDate": "1974-07-09",
        "nepDate": "25.03.2031"
    },
    {
        "engDate": "1974-07-10",
        "nepDate": "26.03.2031"
    },
    {
        "engDate": "1974-07-11",
        "nepDate": "27.03.2031"
    },
    {
        "engDate": "1974-07-12",
        "nepDate": "28.03.2031"
    },
    {
        "engDate": "1974-07-13",
        "nepDate": "29.03.2031"
    },
    {
        "engDate": "1974-07-14",
        "nepDate": "30.03.2031"
    },
    {
        "engDate": "1974-07-15",
        "nepDate": "31.03.2031"
    },
    {
        "engDate": "1974-07-16",
        "nepDate": "01.04.2031"
    },
    {
        "engDate": "1974-07-17",
        "nepDate": "02.04.2031"
    },
    {
        "engDate": "1974-07-18",
        "nepDate": "03.04.2031"
    },
    {
        "engDate": "1974-07-19",
        "nepDate": "04.04.2031"
    },
    {
        "engDate": "1974-07-20",
        "nepDate": "05.04.2031"
    },
    {
        "engDate": "1974-07-21",
        "nepDate": "06.04.2031"
    },
    {
        "engDate": "1974-07-22",
        "nepDate": "07.04.2031"
    },
    {
        "engDate": "1974-07-23",
        "nepDate": "08.04.2031"
    },
    {
        "engDate": "1974-07-24",
        "nepDate": "09.04.2031"
    },
    {
        "engDate": "1974-07-25",
        "nepDate": "10.04.2031"
    },
    {
        "engDate": "1974-07-26",
        "nepDate": "11.04.2031"
    },
    {
        "engDate": "1974-07-27",
        "nepDate": "12.04.2031"
    },
    {
        "engDate": "1974-07-28",
        "nepDate": "13.04.2031"
    },
    {
        "engDate": "1974-07-29",
        "nepDate": "14.04.2031"
    },
    {
        "engDate": "1974-07-30",
        "nepDate": "15.04.2031"
    },
    {
        "engDate": "1974-07-31",
        "nepDate": "16.04.2031"
    },
    {
        "engDate": "1974-08-01",
        "nepDate": "17.04.2031"
    },
    {
        "engDate": "1974-08-02",
        "nepDate": "18.04.2031"
    },
    {
        "engDate": "1974-08-03",
        "nepDate": "19.04.2031"
    },
    {
        "engDate": "1974-08-04",
        "nepDate": "20.04.2031"
    },
    {
        "engDate": "1974-08-05",
        "nepDate": "21.04.2031"
    },
    {
        "engDate": "1974-08-06",
        "nepDate": "22.04.2031"
    },
    {
        "engDate": "1974-08-07",
        "nepDate": "23.04.2031"
    },
    {
        "engDate": "1974-08-08",
        "nepDate": "24.04.2031"
    },
    {
        "engDate": "1974-08-09",
        "nepDate": "25.04.2031"
    },
    {
        "engDate": "1974-08-10",
        "nepDate": "26.04.2031"
    },
    {
        "engDate": "1974-08-11",
        "nepDate": "27.04.2031"
    },
    {
        "engDate": "1974-08-12",
        "nepDate": "28.04.2031"
    },
    {
        "engDate": "1974-08-13",
        "nepDate": "29.04.2031"
    },
    {
        "engDate": "1974-08-14",
        "nepDate": "30.04.2031"
    },
    {
        "engDate": "1974-08-15",
        "nepDate": "31.04.2031"
    },
    {
        "engDate": "1974-08-16",
        "nepDate": "32.04.2031"
    },
    {
        "engDate": "1974-08-17",
        "nepDate": "01.05.2031"
    },
    {
        "engDate": "1974-08-18",
        "nepDate": "02.05.2031"
    },
    {
        "engDate": "1974-08-19",
        "nepDate": "03.05.2031"
    },
    {
        "engDate": "1974-08-20",
        "nepDate": "04.05.2031"
    },
    {
        "engDate": "1974-08-21",
        "nepDate": "05.05.2031"
    },
    {
        "engDate": "1974-08-22",
        "nepDate": "06.05.2031"
    },
    {
        "engDate": "1974-08-23",
        "nepDate": "07.05.2031"
    },
    {
        "engDate": "1974-08-24",
        "nepDate": "08.05.2031"
    },
    {
        "engDate": "1974-08-25",
        "nepDate": "09.05.2031"
    },
    {
        "engDate": "1974-08-26",
        "nepDate": "10.05.2031"
    },
    {
        "engDate": "1974-08-27",
        "nepDate": "11.05.2031"
    },
    {
        "engDate": "1974-08-28",
        "nepDate": "12.05.2031"
    },
    {
        "engDate": "1974-08-29",
        "nepDate": "13.05.2031"
    },
    {
        "engDate": "1974-08-30",
        "nepDate": "14.05.2031"
    },
    {
        "engDate": "1974-08-31",
        "nepDate": "15.05.2031"
    },
    {
        "engDate": "1974-09-01",
        "nepDate": "16.05.2031"
    },
    {
        "engDate": "1974-09-02",
        "nepDate": "17.05.2031"
    },
    {
        "engDate": "1974-09-03",
        "nepDate": "18.05.2031"
    },
    {
        "engDate": "1974-09-04",
        "nepDate": "19.05.2031"
    },
    {
        "engDate": "1974-09-05",
        "nepDate": "20.05.2031"
    },
    {
        "engDate": "1974-09-06",
        "nepDate": "21.05.2031"
    },
    {
        "engDate": "1974-09-07",
        "nepDate": "22.05.2031"
    },
    {
        "engDate": "1974-09-08",
        "nepDate": "23.05.2031"
    },
    {
        "engDate": "1974-09-09",
        "nepDate": "24.05.2031"
    },
    {
        "engDate": "1974-09-10",
        "nepDate": "25.05.2031"
    },
    {
        "engDate": "1974-09-11",
        "nepDate": "26.05.2031"
    },
    {
        "engDate": "1974-09-12",
        "nepDate": "27.05.2031"
    },
    {
        "engDate": "1974-09-13",
        "nepDate": "28.05.2031"
    },
    {
        "engDate": "1974-09-14",
        "nepDate": "29.05.2031"
    },
    {
        "engDate": "1974-09-15",
        "nepDate": "30.05.2031"
    },
    {
        "engDate": "1974-09-16",
        "nepDate": "31.05.2031"
    },
    {
        "engDate": "1974-09-17",
        "nepDate": "01.06.2031"
    },
    {
        "engDate": "1974-09-18",
        "nepDate": "02.06.2031"
    },
    {
        "engDate": "1974-09-19",
        "nepDate": "03.06.2031"
    },
    {
        "engDate": "1974-09-20",
        "nepDate": "04.06.2031"
    },
    {
        "engDate": "1974-09-21",
        "nepDate": "05.06.2031"
    },
    {
        "engDate": "1974-09-22",
        "nepDate": "06.06.2031"
    },
    {
        "engDate": "1974-09-23",
        "nepDate": "07.06.2031"
    },
    {
        "engDate": "1974-09-24",
        "nepDate": "08.06.2031"
    },
    {
        "engDate": "1974-09-25",
        "nepDate": "09.06.2031"
    },
    {
        "engDate": "1974-09-26",
        "nepDate": "10.06.2031"
    },
    {
        "engDate": "1974-09-27",
        "nepDate": "11.06.2031"
    },
    {
        "engDate": "1974-09-28",
        "nepDate": "12.06.2031"
    },
    {
        "engDate": "1974-09-29",
        "nepDate": "13.06.2031"
    },
    {
        "engDate": "1974-09-30",
        "nepDate": "14.06.2031"
    },
    {
        "engDate": "1974-10-01",
        "nepDate": "15.06.2031"
    },
    {
        "engDate": "1974-10-02",
        "nepDate": "16.06.2031"
    },
    {
        "engDate": "1974-10-03",
        "nepDate": "17.06.2031"
    },
    {
        "engDate": "1974-10-04",
        "nepDate": "18.06.2031"
    },
    {
        "engDate": "1974-10-05",
        "nepDate": "19.06.2031"
    },
    {
        "engDate": "1974-10-06",
        "nepDate": "20.06.2031"
    },
    {
        "engDate": "1974-10-07",
        "nepDate": "21.06.2031"
    },
    {
        "engDate": "1974-10-08",
        "nepDate": "22.06.2031"
    },
    {
        "engDate": "1974-10-09",
        "nepDate": "23.06.2031"
    },
    {
        "engDate": "1974-10-10",
        "nepDate": "24.06.2031"
    },
    {
        "engDate": "1974-10-11",
        "nepDate": "25.06.2031"
    },
    {
        "engDate": "1974-10-12",
        "nepDate": "26.06.2031"
    },
    {
        "engDate": "1974-10-13",
        "nepDate": "27.06.2031"
    },
    {
        "engDate": "1974-10-14",
        "nepDate": "28.06.2031"
    },
    {
        "engDate": "1974-10-15",
        "nepDate": "29.06.2031"
    },
    {
        "engDate": "1974-10-16",
        "nepDate": "30.06.2031"
    },
    {
        "engDate": "1974-10-17",
        "nepDate": "01.07.2031"
    },
    {
        "engDate": "1974-10-18",
        "nepDate": "02.07.2031"
    },
    {
        "engDate": "1974-10-19",
        "nepDate": "03.07.2031"
    },
    {
        "engDate": "1974-10-20",
        "nepDate": "04.07.2031"
    },
    {
        "engDate": "1974-10-21",
        "nepDate": "05.07.2031"
    },
    {
        "engDate": "1974-10-22",
        "nepDate": "06.07.2031"
    },
    {
        "engDate": "1974-10-23",
        "nepDate": "07.07.2031"
    },
    {
        "engDate": "1974-10-24",
        "nepDate": "08.07.2031"
    },
    {
        "engDate": "1974-10-25",
        "nepDate": "09.07.2031"
    },
    {
        "engDate": "1974-10-26",
        "nepDate": "10.07.2031"
    },
    {
        "engDate": "1974-10-27",
        "nepDate": "11.07.2031"
    },
    {
        "engDate": "1974-10-28",
        "nepDate": "12.07.2031"
    },
    {
        "engDate": "1974-10-29",
        "nepDate": "13.07.2031"
    },
    {
        "engDate": "1974-10-30",
        "nepDate": "14.07.2031"
    },
    {
        "engDate": "1974-10-31",
        "nepDate": "15.07.2031"
    },
    {
        "engDate": "1974-11-01",
        "nepDate": "16.07.2031"
    },
    {
        "engDate": "1974-11-02",
        "nepDate": "17.07.2031"
    },
    {
        "engDate": "1974-11-03",
        "nepDate": "18.07.2031"
    },
    {
        "engDate": "1974-11-04",
        "nepDate": "19.07.2031"
    },
    {
        "engDate": "1974-11-05",
        "nepDate": "20.07.2031"
    },
    {
        "engDate": "1974-11-06",
        "nepDate": "21.07.2031"
    },
    {
        "engDate": "1974-11-07",
        "nepDate": "22.07.2031"
    },
    {
        "engDate": "1974-11-08",
        "nepDate": "23.07.2031"
    },
    {
        "engDate": "1974-11-09",
        "nepDate": "24.07.2031"
    },
    {
        "engDate": "1974-11-10",
        "nepDate": "25.07.2031"
    },
    {
        "engDate": "1974-11-11",
        "nepDate": "26.07.2031"
    },
    {
        "engDate": "1974-11-12",
        "nepDate": "27.07.2031"
    },
    {
        "engDate": "1974-11-13",
        "nepDate": "28.07.2031"
    },
    {
        "engDate": "1974-11-14",
        "nepDate": "29.07.2031"
    },
    {
        "engDate": "1974-11-15",
        "nepDate": "30.07.2031"
    },
    {
        "engDate": "1974-11-16",
        "nepDate": "01.08.2031"
    },
    {
        "engDate": "1974-11-17",
        "nepDate": "02.08.2031"
    },
    {
        "engDate": "1974-11-18",
        "nepDate": "03.08.2031"
    },
    {
        "engDate": "1974-11-19",
        "nepDate": "04.08.2031"
    },
    {
        "engDate": "1974-11-20",
        "nepDate": "05.08.2031"
    },
    {
        "engDate": "1974-11-21",
        "nepDate": "06.08.2031"
    },
    {
        "engDate": "1974-11-22",
        "nepDate": "07.08.2031"
    },
    {
        "engDate": "1974-11-23",
        "nepDate": "08.08.2031"
    },
    {
        "engDate": "1974-11-24",
        "nepDate": "09.08.2031"
    },
    {
        "engDate": "1974-11-25",
        "nepDate": "10.08.2031"
    },
    {
        "engDate": "1974-11-26",
        "nepDate": "11.08.2031"
    },
    {
        "engDate": "1974-11-27",
        "nepDate": "12.08.2031"
    },
    {
        "engDate": "1974-11-28",
        "nepDate": "13.08.2031"
    },
    {
        "engDate": "1974-11-29",
        "nepDate": "14.08.2031"
    },
    {
        "engDate": "1974-11-30",
        "nepDate": "15.08.2031"
    },
    {
        "engDate": "1974-12-01",
        "nepDate": "16.08.2031"
    },
    {
        "engDate": "1974-12-02",
        "nepDate": "17.08.2031"
    },
    {
        "engDate": "1974-12-03",
        "nepDate": "18.08.2031"
    },
    {
        "engDate": "1974-12-04",
        "nepDate": "19.08.2031"
    },
    {
        "engDate": "1974-12-05",
        "nepDate": "20.08.2031"
    },
    {
        "engDate": "1974-12-06",
        "nepDate": "21.08.2031"
    },
    {
        "engDate": "1974-12-07",
        "nepDate": "22.08.2031"
    },
    {
        "engDate": "1974-12-08",
        "nepDate": "23.08.2031"
    },
    {
        "engDate": "1974-12-09",
        "nepDate": "24.08.2031"
    },
    {
        "engDate": "1974-12-10",
        "nepDate": "25.08.2031"
    },
    {
        "engDate": "1974-12-11",
        "nepDate": "26.08.2031"
    },
    {
        "engDate": "1974-12-12",
        "nepDate": "27.08.2031"
    },
    {
        "engDate": "1974-12-13",
        "nepDate": "28.08.2031"
    },
    {
        "engDate": "1974-12-14",
        "nepDate": "29.08.2031"
    },
    {
        "engDate": "1974-12-15",
        "nepDate": "30.08.2031"
    },
    {
        "engDate": "1974-12-16",
        "nepDate": "01.09.2031"
    },
    {
        "engDate": "1974-12-17",
        "nepDate": "02.09.2031"
    },
    {
        "engDate": "1974-12-18",
        "nepDate": "03.09.2031"
    },
    {
        "engDate": "1974-12-19",
        "nepDate": "04.09.2031"
    },
    {
        "engDate": "1974-12-20",
        "nepDate": "05.09.2031"
    },
    {
        "engDate": "1974-12-21",
        "nepDate": "06.09.2031"
    },
    {
        "engDate": "1974-12-22",
        "nepDate": "07.09.2031"
    },
    {
        "engDate": "1974-12-23",
        "nepDate": "08.09.2031"
    },
    {
        "engDate": "1974-12-24",
        "nepDate": "09.09.2031"
    },
    {
        "engDate": "1974-12-25",
        "nepDate": "10.09.2031"
    },
    {
        "engDate": "1974-12-26",
        "nepDate": "11.09.2031"
    },
    {
        "engDate": "1974-12-27",
        "nepDate": "12.09.2031"
    },
    {
        "engDate": "1974-12-28",
        "nepDate": "13.09.2031"
    },
    {
        "engDate": "1974-12-29",
        "nepDate": "14.09.2031"
    },
    {
        "engDate": "1974-12-30",
        "nepDate": "15.09.2031"
    },
    {
        "engDate": "1974-12-31",
        "nepDate": "16.09.2031"
    },
    {
        "engDate": "1975-01-01",
        "nepDate": "17.09.2031"
    },
    {
        "engDate": "1975-01-02",
        "nepDate": "18.09.2031"
    },
    {
        "engDate": "1975-01-03",
        "nepDate": "19.09.2031"
    },
    {
        "engDate": "1975-01-04",
        "nepDate": "20.09.2031"
    },
    {
        "engDate": "1975-01-05",
        "nepDate": "21.09.2031"
    },
    {
        "engDate": "1975-01-06",
        "nepDate": "22.09.2031"
    },
    {
        "engDate": "1975-01-07",
        "nepDate": "23.09.2031"
    },
    {
        "engDate": "1975-01-08",
        "nepDate": "24.09.2031"
    },
    {
        "engDate": "1975-01-09",
        "nepDate": "25.09.2031"
    },
    {
        "engDate": "1975-01-10",
        "nepDate": "26.09.2031"
    },
    {
        "engDate": "1975-01-11",
        "nepDate": "27.09.2031"
    },
    {
        "engDate": "1975-01-12",
        "nepDate": "28.09.2031"
    },
    {
        "engDate": "1975-01-13",
        "nepDate": "29.09.2031"
    },
    {
        "engDate": "1975-01-14",
        "nepDate": "01.10.2031"
    },
    {
        "engDate": "1975-01-15",
        "nepDate": "02.10.2031"
    },
    {
        "engDate": "1975-01-16",
        "nepDate": "03.10.2031"
    },
    {
        "engDate": "1975-01-17",
        "nepDate": "04.10.2031"
    },
    {
        "engDate": "1975-01-18",
        "nepDate": "05.10.2031"
    },
    {
        "engDate": "1975-01-19",
        "nepDate": "06.10.2031"
    },
    {
        "engDate": "1975-01-20",
        "nepDate": "07.10.2031"
    },
    {
        "engDate": "1975-01-21",
        "nepDate": "08.10.2031"
    },
    {
        "engDate": "1975-01-22",
        "nepDate": "09.10.2031"
    },
    {
        "engDate": "1975-01-23",
        "nepDate": "10.10.2031"
    },
    {
        "engDate": "1975-01-24",
        "nepDate": "11.10.2031"
    },
    {
        "engDate": "1975-01-25",
        "nepDate": "12.10.2031"
    },
    {
        "engDate": "1975-01-26",
        "nepDate": "13.10.2031"
    },
    {
        "engDate": "1975-01-27",
        "nepDate": "14.10.2031"
    },
    {
        "engDate": "1975-01-28",
        "nepDate": "15.10.2031"
    },
    {
        "engDate": "1975-01-29",
        "nepDate": "16.10.2031"
    },
    {
        "engDate": "1975-01-30",
        "nepDate": "17.10.2031"
    },
    {
        "engDate": "1975-01-31",
        "nepDate": "18.10.2031"
    },
    {
        "engDate": "1975-02-01",
        "nepDate": "19.10.2031"
    },
    {
        "engDate": "1975-02-02",
        "nepDate": "20.10.2031"
    },
    {
        "engDate": "1975-02-03",
        "nepDate": "21.10.2031"
    },
    {
        "engDate": "1975-02-04",
        "nepDate": "22.10.2031"
    },
    {
        "engDate": "1975-02-05",
        "nepDate": "23.10.2031"
    },
    {
        "engDate": "1975-02-06",
        "nepDate": "24.10.2031"
    },
    {
        "engDate": "1975-02-07",
        "nepDate": "25.10.2031"
    },
    {
        "engDate": "1975-02-08",
        "nepDate": "26.10.2031"
    },
    {
        "engDate": "1975-02-09",
        "nepDate": "27.10.2031"
    },
    {
        "engDate": "1975-02-10",
        "nepDate": "28.10.2031"
    },
    {
        "engDate": "1975-02-11",
        "nepDate": "29.10.2031"
    },
    {
        "engDate": "1975-02-12",
        "nepDate": "30.10.2031"
    },
    {
        "engDate": "1975-02-13",
        "nepDate": "01.11.2031"
    },
    {
        "engDate": "1975-02-14",
        "nepDate": "02.11.2031"
    },
    {
        "engDate": "1975-02-15",
        "nepDate": "03.11.2031"
    },
    {
        "engDate": "1975-02-16",
        "nepDate": "04.11.2031"
    },
    {
        "engDate": "1975-02-17",
        "nepDate": "05.11.2031"
    },
    {
        "engDate": "1975-02-18",
        "nepDate": "06.11.2031"
    },
    {
        "engDate": "1975-02-19",
        "nepDate": "07.11.2031"
    },
    {
        "engDate": "1975-02-20",
        "nepDate": "08.11.2031"
    },
    {
        "engDate": "1975-02-21",
        "nepDate": "09.11.2031"
    },
    {
        "engDate": "1975-02-22",
        "nepDate": "10.11.2031"
    },
    {
        "engDate": "1975-02-23",
        "nepDate": "11.11.2031"
    },
    {
        "engDate": "1975-02-24",
        "nepDate": "12.11.2031"
    },
    {
        "engDate": "1975-02-25",
        "nepDate": "13.11.2031"
    },
    {
        "engDate": "1975-02-26",
        "nepDate": "14.11.2031"
    },
    {
        "engDate": "1975-02-27",
        "nepDate": "15.11.2031"
    },
    {
        "engDate": "1975-02-28",
        "nepDate": "16.11.2031"
    },
    {
        "engDate": "1975-03-01",
        "nepDate": "17.11.2031"
    },
    {
        "engDate": "1975-03-02",
        "nepDate": "18.11.2031"
    },
    {
        "engDate": "1975-03-03",
        "nepDate": "19.11.2031"
    },
    {
        "engDate": "1975-03-04",
        "nepDate": "20.11.2031"
    },
    {
        "engDate": "1975-03-05",
        "nepDate": "21.11.2031"
    },
    {
        "engDate": "1975-03-06",
        "nepDate": "22.11.2031"
    },
    {
        "engDate": "1975-03-07",
        "nepDate": "23.11.2031"
    },
    {
        "engDate": "1975-03-08",
        "nepDate": "24.11.2031"
    },
    {
        "engDate": "1975-03-09",
        "nepDate": "25.11.2031"
    },
    {
        "engDate": "1975-03-10",
        "nepDate": "26.11.2031"
    },
    {
        "engDate": "1975-03-11",
        "nepDate": "27.11.2031"
    },
    {
        "engDate": "1975-03-12",
        "nepDate": "28.11.2031"
    },
    {
        "engDate": "1975-03-13",
        "nepDate": "29.11.2031"
    },
    {
        "engDate": "1975-03-14",
        "nepDate": "01.12.2031"
    },
    {
        "engDate": "1975-03-15",
        "nepDate": "02.12.2031"
    },
    {
        "engDate": "1975-03-16",
        "nepDate": "03.12.2031"
    },
    {
        "engDate": "1975-03-17",
        "nepDate": "04.12.2031"
    },
    {
        "engDate": "1975-03-18",
        "nepDate": "05.12.2031"
    },
    {
        "engDate": "1975-03-19",
        "nepDate": "06.12.2031"
    },
    {
        "engDate": "1975-03-20",
        "nepDate": "07.12.2031"
    },
    {
        "engDate": "1975-03-21",
        "nepDate": "08.12.2031"
    },
    {
        "engDate": "1975-03-22",
        "nepDate": "09.12.2031"
    },
    {
        "engDate": "1975-03-23",
        "nepDate": "10.12.2031"
    },
    {
        "engDate": "1975-03-24",
        "nepDate": "11.12.2031"
    },
    {
        "engDate": "1975-03-25",
        "nepDate": "12.12.2031"
    },
    {
        "engDate": "1975-03-26",
        "nepDate": "13.12.2031"
    },
    {
        "engDate": "1975-03-27",
        "nepDate": "14.12.2031"
    },
    {
        "engDate": "1975-03-28",
        "nepDate": "15.12.2031"
    },
    {
        "engDate": "1975-03-29",
        "nepDate": "16.12.2031"
    },
    {
        "engDate": "1975-03-30",
        "nepDate": "17.12.2031"
    },
    {
        "engDate": "1975-03-31",
        "nepDate": "18.12.2031"
    },
    {
        "engDate": "1975-04-01",
        "nepDate": "19.12.2031"
    },
    {
        "engDate": "1975-04-02",
        "nepDate": "20.12.2031"
    },
    {
        "engDate": "1975-04-03",
        "nepDate": "21.12.2031"
    },
    {
        "engDate": "1975-04-04",
        "nepDate": "22.12.2031"
    },
    {
        "engDate": "1975-04-05",
        "nepDate": "23.12.2031"
    },
    {
        "engDate": "1975-04-06",
        "nepDate": "24.12.2031"
    },
    {
        "engDate": "1975-04-07",
        "nepDate": "25.12.2031"
    },
    {
        "engDate": "1975-04-08",
        "nepDate": "26.12.2031"
    },
    {
        "engDate": "1975-04-09",
        "nepDate": "27.12.2031"
    },
    {
        "engDate": "1975-04-10",
        "nepDate": "28.12.2031"
    },
    {
        "engDate": "1975-04-11",
        "nepDate": "29.12.2031"
    },
    {
        "engDate": "1975-04-12",
        "nepDate": "30.12.2031"
    },
    {
        "engDate": "1975-04-13",
        "nepDate": "31.12.2031"
    },
    {
        "engDate": "1975-04-14",
        "nepDate": "01.01.2032"
    },
    {
        "engDate": "1975-04-15",
        "nepDate": "02.01.2032"
    },
    {
        "engDate": "1975-04-16",
        "nepDate": "03.01.2032"
    },
    {
        "engDate": "1975-04-17",
        "nepDate": "04.01.2032"
    },
    {
        "engDate": "1975-04-18",
        "nepDate": "05.01.2032"
    },
    {
        "engDate": "1975-04-19",
        "nepDate": "06.01.2032"
    },
    {
        "engDate": "1975-04-20",
        "nepDate": "07.01.2032"
    },
    {
        "engDate": "1975-04-21",
        "nepDate": "08.01.2032"
    },
    {
        "engDate": "1975-04-22",
        "nepDate": "09.01.2032"
    },
    {
        "engDate": "1975-04-23",
        "nepDate": "10.01.2032"
    },
    {
        "engDate": "1975-04-24",
        "nepDate": "11.01.2032"
    },
    {
        "engDate": "1975-04-25",
        "nepDate": "12.01.2032"
    },
    {
        "engDate": "1975-04-26",
        "nepDate": "13.01.2032"
    },
    {
        "engDate": "1975-04-27",
        "nepDate": "14.01.2032"
    },
    {
        "engDate": "1975-04-28",
        "nepDate": "15.01.2032"
    },
    {
        "engDate": "1975-04-29",
        "nepDate": "16.01.2032"
    },
    {
        "engDate": "1975-04-30",
        "nepDate": "17.01.2032"
    },
    {
        "engDate": "1975-05-01",
        "nepDate": "18.01.2032"
    },
    {
        "engDate": "1975-05-02",
        "nepDate": "19.01.2032"
    },
    {
        "engDate": "1975-05-03",
        "nepDate": "20.01.2032"
    },
    {
        "engDate": "1975-05-04",
        "nepDate": "21.01.2032"
    },
    {
        "engDate": "1975-05-05",
        "nepDate": "22.01.2032"
    },
    {
        "engDate": "1975-05-06",
        "nepDate": "23.01.2032"
    },
    {
        "engDate": "1975-05-07",
        "nepDate": "24.01.2032"
    },
    {
        "engDate": "1975-05-08",
        "nepDate": "25.01.2032"
    },
    {
        "engDate": "1975-05-09",
        "nepDate": "26.01.2032"
    },
    {
        "engDate": "1975-05-10",
        "nepDate": "27.01.2032"
    },
    {
        "engDate": "1975-05-11",
        "nepDate": "28.01.2032"
    },
    {
        "engDate": "1975-05-12",
        "nepDate": "29.01.2032"
    },
    {
        "engDate": "1975-05-13",
        "nepDate": "30.01.2032"
    },
    {
        "engDate": "1975-05-14",
        "nepDate": "31.01.2032"
    },
    {
        "engDate": "1975-05-15",
        "nepDate": "01.02.2032"
    },
    {
        "engDate": "1975-05-16",
        "nepDate": "02.02.2032"
    },
    {
        "engDate": "1975-05-17",
        "nepDate": "03.02.2032"
    },
    {
        "engDate": "1975-05-18",
        "nepDate": "04.02.2032"
    },
    {
        "engDate": "1975-05-19",
        "nepDate": "05.02.2032"
    },
    {
        "engDate": "1975-05-20",
        "nepDate": "06.02.2032"
    },
    {
        "engDate": "1975-05-21",
        "nepDate": "07.02.2032"
    },
    {
        "engDate": "1975-05-22",
        "nepDate": "08.02.2032"
    },
    {
        "engDate": "1975-05-23",
        "nepDate": "09.02.2032"
    },
    {
        "engDate": "1975-05-24",
        "nepDate": "10.02.2032"
    },
    {
        "engDate": "1975-05-25",
        "nepDate": "11.02.2032"
    },
    {
        "engDate": "1975-05-26",
        "nepDate": "12.02.2032"
    },
    {
        "engDate": "1975-05-27",
        "nepDate": "13.02.2032"
    },
    {
        "engDate": "1975-05-28",
        "nepDate": "14.02.2032"
    },
    {
        "engDate": "1975-05-29",
        "nepDate": "15.02.2032"
    },
    {
        "engDate": "1975-05-30",
        "nepDate": "16.02.2032"
    },
    {
        "engDate": "1975-05-31",
        "nepDate": "17.02.2032"
    },
    {
        "engDate": "1975-06-01",
        "nepDate": "18.02.2032"
    },
    {
        "engDate": "1975-06-02",
        "nepDate": "19.02.2032"
    },
    {
        "engDate": "1975-06-03",
        "nepDate": "20.02.2032"
    },
    {
        "engDate": "1975-06-04",
        "nepDate": "21.02.2032"
    },
    {
        "engDate": "1975-06-05",
        "nepDate": "22.02.2032"
    },
    {
        "engDate": "1975-06-06",
        "nepDate": "23.02.2032"
    },
    {
        "engDate": "1975-06-07",
        "nepDate": "24.02.2032"
    },
    {
        "engDate": "1975-06-08",
        "nepDate": "25.02.2032"
    },
    {
        "engDate": "1975-06-09",
        "nepDate": "26.02.2032"
    },
    {
        "engDate": "1975-06-10",
        "nepDate": "27.02.2032"
    },
    {
        "engDate": "1975-06-11",
        "nepDate": "28.02.2032"
    },
    {
        "engDate": "1975-06-12",
        "nepDate": "29.02.2032"
    },
    {
        "engDate": "1975-06-13",
        "nepDate": "30.02.2032"
    },
    {
        "engDate": "1975-06-14",
        "nepDate": "31.02.2032"
    },
    {
        "engDate": "1975-06-15",
        "nepDate": "01.03.2032"
    },
    {
        "engDate": "1975-06-16",
        "nepDate": "02.03.2032"
    },
    {
        "engDate": "1975-06-17",
        "nepDate": "03.03.2032"
    },
    {
        "engDate": "1975-06-18",
        "nepDate": "04.03.2032"
    },
    {
        "engDate": "1975-06-19",
        "nepDate": "05.03.2032"
    },
    {
        "engDate": "1975-06-20",
        "nepDate": "06.03.2032"
    },
    {
        "engDate": "1975-06-21",
        "nepDate": "07.03.2032"
    },
    {
        "engDate": "1975-06-22",
        "nepDate": "08.03.2032"
    },
    {
        "engDate": "1975-06-23",
        "nepDate": "09.03.2032"
    },
    {
        "engDate": "1975-06-24",
        "nepDate": "10.03.2032"
    },
    {
        "engDate": "1975-06-25",
        "nepDate": "11.03.2032"
    },
    {
        "engDate": "1975-06-26",
        "nepDate": "12.03.2032"
    },
    {
        "engDate": "1975-06-27",
        "nepDate": "13.03.2032"
    },
    {
        "engDate": "1975-06-28",
        "nepDate": "14.03.2032"
    },
    {
        "engDate": "1975-06-29",
        "nepDate": "15.03.2032"
    },
    {
        "engDate": "1975-06-30",
        "nepDate": "16.03.2032"
    },
    {
        "engDate": "1975-07-01",
        "nepDate": "17.03.2032"
    },
    {
        "engDate": "1975-07-02",
        "nepDate": "18.03.2032"
    },
    {
        "engDate": "1975-07-03",
        "nepDate": "19.03.2032"
    },
    {
        "engDate": "1975-07-04",
        "nepDate": "20.03.2032"
    },
    {
        "engDate": "1975-07-05",
        "nepDate": "21.03.2032"
    },
    {
        "engDate": "1975-07-06",
        "nepDate": "22.03.2032"
    },
    {
        "engDate": "1975-07-07",
        "nepDate": "23.03.2032"
    },
    {
        "engDate": "1975-07-08",
        "nepDate": "24.03.2032"
    },
    {
        "engDate": "1975-07-09",
        "nepDate": "25.03.2032"
    },
    {
        "engDate": "1975-07-10",
        "nepDate": "26.03.2032"
    },
    {
        "engDate": "1975-07-11",
        "nepDate": "27.03.2032"
    },
    {
        "engDate": "1975-07-12",
        "nepDate": "28.03.2032"
    },
    {
        "engDate": "1975-07-13",
        "nepDate": "29.03.2032"
    },
    {
        "engDate": "1975-07-14",
        "nepDate": "30.03.2032"
    },
    {
        "engDate": "1975-07-15",
        "nepDate": "31.03.2032"
    },
    {
        "engDate": "1975-07-16",
        "nepDate": "32.03.2032"
    },
    {
        "engDate": "1975-07-17",
        "nepDate": "01.04.2032"
    },
    {
        "engDate": "1975-07-18",
        "nepDate": "02.04.2032"
    },
    {
        "engDate": "1975-07-19",
        "nepDate": "03.04.2032"
    },
    {
        "engDate": "1975-07-20",
        "nepDate": "04.04.2032"
    },
    {
        "engDate": "1975-07-21",
        "nepDate": "05.04.2032"
    },
    {
        "engDate": "1975-07-22",
        "nepDate": "06.04.2032"
    },
    {
        "engDate": "1975-07-23",
        "nepDate": "07.04.2032"
    },
    {
        "engDate": "1975-07-24",
        "nepDate": "08.04.2032"
    },
    {
        "engDate": "1975-07-25",
        "nepDate": "09.04.2032"
    },
    {
        "engDate": "1975-07-26",
        "nepDate": "10.04.2032"
    },
    {
        "engDate": "1975-07-27",
        "nepDate": "11.04.2032"
    },
    {
        "engDate": "1975-07-28",
        "nepDate": "12.04.2032"
    },
    {
        "engDate": "1975-07-29",
        "nepDate": "13.04.2032"
    },
    {
        "engDate": "1975-07-30",
        "nepDate": "14.04.2032"
    },
    {
        "engDate": "1975-07-31",
        "nepDate": "15.04.2032"
    },
    {
        "engDate": "1975-08-01",
        "nepDate": "16.04.2032"
    },
    {
        "engDate": "1975-08-02",
        "nepDate": "17.04.2032"
    },
    {
        "engDate": "1975-08-03",
        "nepDate": "18.04.2032"
    },
    {
        "engDate": "1975-08-04",
        "nepDate": "19.04.2032"
    },
    {
        "engDate": "1975-08-05",
        "nepDate": "20.04.2032"
    },
    {
        "engDate": "1975-08-06",
        "nepDate": "21.04.2032"
    },
    {
        "engDate": "1975-08-07",
        "nepDate": "22.04.2032"
    },
    {
        "engDate": "1975-08-08",
        "nepDate": "23.04.2032"
    },
    {
        "engDate": "1975-08-09",
        "nepDate": "24.04.2032"
    },
    {
        "engDate": "1975-08-10",
        "nepDate": "25.04.2032"
    },
    {
        "engDate": "1975-08-11",
        "nepDate": "26.04.2032"
    },
    {
        "engDate": "1975-08-12",
        "nepDate": "27.04.2032"
    },
    {
        "engDate": "1975-08-13",
        "nepDate": "28.04.2032"
    },
    {
        "engDate": "1975-08-14",
        "nepDate": "29.04.2032"
    },
    {
        "engDate": "1975-08-15",
        "nepDate": "30.04.2032"
    },
    {
        "engDate": "1975-08-16",
        "nepDate": "31.04.2032"
    },
    {
        "engDate": "1975-08-17",
        "nepDate": "01.05.2032"
    },
    {
        "engDate": "1975-08-18",
        "nepDate": "02.05.2032"
    },
    {
        "engDate": "1975-08-19",
        "nepDate": "03.05.2032"
    },
    {
        "engDate": "1975-08-20",
        "nepDate": "04.05.2032"
    },
    {
        "engDate": "1975-08-21",
        "nepDate": "05.05.2032"
    },
    {
        "engDate": "1975-08-22",
        "nepDate": "06.05.2032"
    },
    {
        "engDate": "1975-08-23",
        "nepDate": "07.05.2032"
    },
    {
        "engDate": "1975-08-24",
        "nepDate": "08.05.2032"
    },
    {
        "engDate": "1975-08-25",
        "nepDate": "09.05.2032"
    },
    {
        "engDate": "1975-08-26",
        "nepDate": "10.05.2032"
    },
    {
        "engDate": "1975-08-27",
        "nepDate": "11.05.2032"
    },
    {
        "engDate": "1975-08-28",
        "nepDate": "12.05.2032"
    },
    {
        "engDate": "1975-08-29",
        "nepDate": "13.05.2032"
    },
    {
        "engDate": "1975-08-30",
        "nepDate": "14.05.2032"
    },
    {
        "engDate": "1975-08-31",
        "nepDate": "15.05.2032"
    },
    {
        "engDate": "1975-09-01",
        "nepDate": "16.05.2032"
    },
    {
        "engDate": "1975-09-02",
        "nepDate": "17.05.2032"
    },
    {
        "engDate": "1975-09-03",
        "nepDate": "18.05.2032"
    },
    {
        "engDate": "1975-09-04",
        "nepDate": "19.05.2032"
    },
    {
        "engDate": "1975-09-05",
        "nepDate": "20.05.2032"
    },
    {
        "engDate": "1975-09-06",
        "nepDate": "21.05.2032"
    },
    {
        "engDate": "1975-09-07",
        "nepDate": "22.05.2032"
    },
    {
        "engDate": "1975-09-08",
        "nepDate": "23.05.2032"
    },
    {
        "engDate": "1975-09-09",
        "nepDate": "24.05.2032"
    },
    {
        "engDate": "1975-09-10",
        "nepDate": "25.05.2032"
    },
    {
        "engDate": "1975-09-11",
        "nepDate": "26.05.2032"
    },
    {
        "engDate": "1975-09-12",
        "nepDate": "27.05.2032"
    },
    {
        "engDate": "1975-09-13",
        "nepDate": "28.05.2032"
    },
    {
        "engDate": "1975-09-14",
        "nepDate": "29.05.2032"
    },
    {
        "engDate": "1975-09-15",
        "nepDate": "30.05.2032"
    },
    {
        "engDate": "1975-09-16",
        "nepDate": "31.05.2032"
    },
    {
        "engDate": "1975-09-17",
        "nepDate": "01.06.2032"
    },
    {
        "engDate": "1975-09-18",
        "nepDate": "02.06.2032"
    },
    {
        "engDate": "1975-09-19",
        "nepDate": "03.06.2032"
    },
    {
        "engDate": "1975-09-20",
        "nepDate": "04.06.2032"
    },
    {
        "engDate": "1975-09-21",
        "nepDate": "05.06.2032"
    },
    {
        "engDate": "1975-09-22",
        "nepDate": "06.06.2032"
    },
    {
        "engDate": "1975-09-23",
        "nepDate": "07.06.2032"
    },
    {
        "engDate": "1975-09-24",
        "nepDate": "08.06.2032"
    },
    {
        "engDate": "1975-09-25",
        "nepDate": "09.06.2032"
    },
    {
        "engDate": "1975-09-26",
        "nepDate": "10.06.2032"
    },
    {
        "engDate": "1975-09-27",
        "nepDate": "11.06.2032"
    },
    {
        "engDate": "1975-09-28",
        "nepDate": "12.06.2032"
    },
    {
        "engDate": "1975-09-29",
        "nepDate": "13.06.2032"
    },
    {
        "engDate": "1975-09-30",
        "nepDate": "14.06.2032"
    },
    {
        "engDate": "1975-10-01",
        "nepDate": "15.06.2032"
    },
    {
        "engDate": "1975-10-02",
        "nepDate": "16.06.2032"
    },
    {
        "engDate": "1975-10-03",
        "nepDate": "17.06.2032"
    },
    {
        "engDate": "1975-10-04",
        "nepDate": "18.06.2032"
    },
    {
        "engDate": "1975-10-05",
        "nepDate": "19.06.2032"
    },
    {
        "engDate": "1975-10-06",
        "nepDate": "20.06.2032"
    },
    {
        "engDate": "1975-10-07",
        "nepDate": "21.06.2032"
    },
    {
        "engDate": "1975-10-08",
        "nepDate": "22.06.2032"
    },
    {
        "engDate": "1975-10-09",
        "nepDate": "23.06.2032"
    },
    {
        "engDate": "1975-10-10",
        "nepDate": "24.06.2032"
    },
    {
        "engDate": "1975-10-11",
        "nepDate": "25.06.2032"
    },
    {
        "engDate": "1975-10-12",
        "nepDate": "26.06.2032"
    },
    {
        "engDate": "1975-10-13",
        "nepDate": "27.06.2032"
    },
    {
        "engDate": "1975-10-14",
        "nepDate": "28.06.2032"
    },
    {
        "engDate": "1975-10-15",
        "nepDate": "29.06.2032"
    },
    {
        "engDate": "1975-10-16",
        "nepDate": "30.06.2032"
    },
    {
        "engDate": "1975-10-17",
        "nepDate": "31.06.2032"
    },
    {
        "engDate": "1975-10-18",
        "nepDate": "01.07.2032"
    },
    {
        "engDate": "1975-10-19",
        "nepDate": "02.07.2032"
    },
    {
        "engDate": "1975-10-20",
        "nepDate": "03.07.2032"
    },
    {
        "engDate": "1975-10-21",
        "nepDate": "04.07.2032"
    },
    {
        "engDate": "1975-10-22",
        "nepDate": "05.07.2032"
    },
    {
        "engDate": "1975-10-23",
        "nepDate": "06.07.2032"
    },
    {
        "engDate": "1975-10-24",
        "nepDate": "07.07.2032"
    },
    {
        "engDate": "1975-10-25",
        "nepDate": "08.07.2032"
    },
    {
        "engDate": "1975-10-26",
        "nepDate": "09.07.2032"
    },
    {
        "engDate": "1975-10-27",
        "nepDate": "10.07.2032"
    },
    {
        "engDate": "1975-10-28",
        "nepDate": "11.07.2032"
    },
    {
        "engDate": "1975-10-29",
        "nepDate": "12.07.2032"
    },
    {
        "engDate": "1975-10-30",
        "nepDate": "13.07.2032"
    },
    {
        "engDate": "1975-10-31",
        "nepDate": "14.07.2032"
    },
    {
        "engDate": "1975-11-01",
        "nepDate": "15.07.2032"
    },
    {
        "engDate": "1975-11-02",
        "nepDate": "16.07.2032"
    },
    {
        "engDate": "1975-11-03",
        "nepDate": "17.07.2032"
    },
    {
        "engDate": "1975-11-04",
        "nepDate": "18.07.2032"
    },
    {
        "engDate": "1975-11-05",
        "nepDate": "19.07.2032"
    },
    {
        "engDate": "1975-11-06",
        "nepDate": "20.07.2032"
    },
    {
        "engDate": "1975-11-07",
        "nepDate": "21.07.2032"
    },
    {
        "engDate": "1975-11-08",
        "nepDate": "22.07.2032"
    },
    {
        "engDate": "1975-11-09",
        "nepDate": "23.07.2032"
    },
    {
        "engDate": "1975-11-10",
        "nepDate": "24.07.2032"
    },
    {
        "engDate": "1975-11-11",
        "nepDate": "25.07.2032"
    },
    {
        "engDate": "1975-11-12",
        "nepDate": "26.07.2032"
    },
    {
        "engDate": "1975-11-13",
        "nepDate": "27.07.2032"
    },
    {
        "engDate": "1975-11-14",
        "nepDate": "28.07.2032"
    },
    {
        "engDate": "1975-11-15",
        "nepDate": "29.07.2032"
    },
    {
        "engDate": "1975-11-16",
        "nepDate": "30.07.2032"
    },
    {
        "engDate": "1975-11-17",
        "nepDate": "01.08.2032"
    },
    {
        "engDate": "1975-11-18",
        "nepDate": "02.08.2032"
    },
    {
        "engDate": "1975-11-19",
        "nepDate": "03.08.2032"
    },
    {
        "engDate": "1975-11-20",
        "nepDate": "04.08.2032"
    },
    {
        "engDate": "1975-11-21",
        "nepDate": "05.08.2032"
    },
    {
        "engDate": "1975-11-22",
        "nepDate": "06.08.2032"
    },
    {
        "engDate": "1975-11-23",
        "nepDate": "07.08.2032"
    },
    {
        "engDate": "1975-11-24",
        "nepDate": "08.08.2032"
    },
    {
        "engDate": "1975-11-25",
        "nepDate": "09.08.2032"
    },
    {
        "engDate": "1975-11-26",
        "nepDate": "10.08.2032"
    },
    {
        "engDate": "1975-11-27",
        "nepDate": "11.08.2032"
    },
    {
        "engDate": "1975-11-28",
        "nepDate": "12.08.2032"
    },
    {
        "engDate": "1975-11-29",
        "nepDate": "13.08.2032"
    },
    {
        "engDate": "1975-11-30",
        "nepDate": "14.08.2032"
    },
    {
        "engDate": "1975-12-01",
        "nepDate": "15.08.2032"
    },
    {
        "engDate": "1975-12-02",
        "nepDate": "16.08.2032"
    },
    {
        "engDate": "1975-12-03",
        "nepDate": "17.08.2032"
    },
    {
        "engDate": "1975-12-04",
        "nepDate": "18.08.2032"
    },
    {
        "engDate": "1975-12-05",
        "nepDate": "19.08.2032"
    },
    {
        "engDate": "1975-12-06",
        "nepDate": "20.08.2032"
    },
    {
        "engDate": "1975-12-07",
        "nepDate": "21.08.2032"
    },
    {
        "engDate": "1975-12-08",
        "nepDate": "22.08.2032"
    },
    {
        "engDate": "1975-12-09",
        "nepDate": "23.08.2032"
    },
    {
        "engDate": "1975-12-10",
        "nepDate": "24.08.2032"
    },
    {
        "engDate": "1975-12-11",
        "nepDate": "25.08.2032"
    },
    {
        "engDate": "1975-12-12",
        "nepDate": "26.08.2032"
    },
    {
        "engDate": "1975-12-13",
        "nepDate": "27.08.2032"
    },
    {
        "engDate": "1975-12-14",
        "nepDate": "28.08.2032"
    },
    {
        "engDate": "1975-12-15",
        "nepDate": "29.08.2032"
    },
    {
        "engDate": "1975-12-16",
        "nepDate": "01.09.2032"
    },
    {
        "engDate": "1975-12-17",
        "nepDate": "02.09.2032"
    },
    {
        "engDate": "1975-12-18",
        "nepDate": "03.09.2032"
    },
    {
        "engDate": "1975-12-19",
        "nepDate": "04.09.2032"
    },
    {
        "engDate": "1975-12-20",
        "nepDate": "05.09.2032"
    },
    {
        "engDate": "1975-12-21",
        "nepDate": "06.09.2032"
    },
    {
        "engDate": "1975-12-22",
        "nepDate": "07.09.2032"
    },
    {
        "engDate": "1975-12-23",
        "nepDate": "08.09.2032"
    },
    {
        "engDate": "1975-12-24",
        "nepDate": "09.09.2032"
    },
    {
        "engDate": "1975-12-25",
        "nepDate": "10.09.2032"
    },
    {
        "engDate": "1975-12-26",
        "nepDate": "11.09.2032"
    },
    {
        "engDate": "1975-12-27",
        "nepDate": "12.09.2032"
    },
    {
        "engDate": "1975-12-28",
        "nepDate": "13.09.2032"
    },
    {
        "engDate": "1975-12-29",
        "nepDate": "14.09.2032"
    },
    {
        "engDate": "1975-12-30",
        "nepDate": "15.09.2032"
    },
    {
        "engDate": "1975-12-31",
        "nepDate": "16.09.2032"
    },
    {
        "engDate": "1976-01-01",
        "nepDate": "17.09.2032"
    },
    {
        "engDate": "1976-01-02",
        "nepDate": "18.09.2032"
    },
    {
        "engDate": "1976-01-03",
        "nepDate": "19.09.2032"
    },
    {
        "engDate": "1976-01-04",
        "nepDate": "20.09.2032"
    },
    {
        "engDate": "1976-01-05",
        "nepDate": "21.09.2032"
    },
    {
        "engDate": "1976-01-06",
        "nepDate": "22.09.2032"
    },
    {
        "engDate": "1976-01-07",
        "nepDate": "23.09.2032"
    },
    {
        "engDate": "1976-01-08",
        "nepDate": "24.09.2032"
    },
    {
        "engDate": "1976-01-09",
        "nepDate": "25.09.2032"
    },
    {
        "engDate": "1976-01-10",
        "nepDate": "26.09.2032"
    },
    {
        "engDate": "1976-01-11",
        "nepDate": "27.09.2032"
    },
    {
        "engDate": "1976-01-12",
        "nepDate": "28.09.2032"
    },
    {
        "engDate": "1976-01-13",
        "nepDate": "29.09.2032"
    },
    {
        "engDate": "1976-01-14",
        "nepDate": "30.09.2032"
    },
    {
        "engDate": "1976-01-15",
        "nepDate": "01.10.2032"
    },
    {
        "engDate": "1976-01-16",
        "nepDate": "02.10.2032"
    },
    {
        "engDate": "1976-01-17",
        "nepDate": "03.10.2032"
    },
    {
        "engDate": "1976-01-18",
        "nepDate": "04.10.2032"
    },
    {
        "engDate": "1976-01-19",
        "nepDate": "05.10.2032"
    },
    {
        "engDate": "1976-01-20",
        "nepDate": "06.10.2032"
    },
    {
        "engDate": "1976-01-21",
        "nepDate": "07.10.2032"
    },
    {
        "engDate": "1976-01-22",
        "nepDate": "08.10.2032"
    },
    {
        "engDate": "1976-01-23",
        "nepDate": "09.10.2032"
    },
    {
        "engDate": "1976-01-24",
        "nepDate": "10.10.2032"
    },
    {
        "engDate": "1976-01-25",
        "nepDate": "11.10.2032"
    },
    {
        "engDate": "1976-01-26",
        "nepDate": "12.10.2032"
    },
    {
        "engDate": "1976-01-27",
        "nepDate": "13.10.2032"
    },
    {
        "engDate": "1976-01-28",
        "nepDate": "14.10.2032"
    },
    {
        "engDate": "1976-01-29",
        "nepDate": "15.10.2032"
    },
    {
        "engDate": "1976-01-30",
        "nepDate": "16.10.2032"
    },
    {
        "engDate": "1976-01-31",
        "nepDate": "17.10.2032"
    },
    {
        "engDate": "1976-02-01",
        "nepDate": "18.10.2032"
    },
    {
        "engDate": "1976-02-02",
        "nepDate": "19.10.2032"
    },
    {
        "engDate": "1976-02-03",
        "nepDate": "20.10.2032"
    },
    {
        "engDate": "1976-02-04",
        "nepDate": "21.10.2032"
    },
    {
        "engDate": "1976-02-05",
        "nepDate": "22.10.2032"
    },
    {
        "engDate": "1976-02-06",
        "nepDate": "23.10.2032"
    },
    {
        "engDate": "1976-02-07",
        "nepDate": "24.10.2032"
    },
    {
        "engDate": "1976-02-08",
        "nepDate": "25.10.2032"
    },
    {
        "engDate": "1976-02-09",
        "nepDate": "26.10.2032"
    },
    {
        "engDate": "1976-02-10",
        "nepDate": "27.10.2032"
    },
    {
        "engDate": "1976-02-11",
        "nepDate": "28.10.2032"
    },
    {
        "engDate": "1976-02-12",
        "nepDate": "29.10.2032"
    },
    {
        "engDate": "1976-02-13",
        "nepDate": "01.11.2032"
    },
    {
        "engDate": "1976-02-14",
        "nepDate": "02.11.2032"
    },
    {
        "engDate": "1976-02-15",
        "nepDate": "03.11.2032"
    },
    {
        "engDate": "1976-02-16",
        "nepDate": "04.11.2032"
    },
    {
        "engDate": "1976-02-17",
        "nepDate": "05.11.2032"
    },
    {
        "engDate": "1976-02-18",
        "nepDate": "06.11.2032"
    },
    {
        "engDate": "1976-02-19",
        "nepDate": "07.11.2032"
    },
    {
        "engDate": "1976-02-20",
        "nepDate": "08.11.2032"
    },
    {
        "engDate": "1976-02-21",
        "nepDate": "09.11.2032"
    },
    {
        "engDate": "1976-02-22",
        "nepDate": "10.11.2032"
    },
    {
        "engDate": "1976-02-23",
        "nepDate": "11.11.2032"
    },
    {
        "engDate": "1976-02-24",
        "nepDate": "12.11.2032"
    },
    {
        "engDate": "1976-02-25",
        "nepDate": "13.11.2032"
    },
    {
        "engDate": "1976-02-26",
        "nepDate": "14.11.2032"
    },
    {
        "engDate": "1976-02-27",
        "nepDate": "15.11.2032"
    },
    {
        "engDate": "1976-02-28",
        "nepDate": "16.11.2032"
    },
    {
        "engDate": "1976-02-29",
        "nepDate": "17.11.2032"
    },
    {
        "engDate": "1976-03-01",
        "nepDate": "18.11.2032"
    },
    {
        "engDate": "1976-03-02",
        "nepDate": "19.11.2032"
    },
    {
        "engDate": "1976-03-03",
        "nepDate": "20.11.2032"
    },
    {
        "engDate": "1976-03-04",
        "nepDate": "21.11.2032"
    },
    {
        "engDate": "1976-03-05",
        "nepDate": "22.11.2032"
    },
    {
        "engDate": "1976-03-06",
        "nepDate": "23.11.2032"
    },
    {
        "engDate": "1976-03-07",
        "nepDate": "24.11.2032"
    },
    {
        "engDate": "1976-03-08",
        "nepDate": "25.11.2032"
    },
    {
        "engDate": "1976-03-09",
        "nepDate": "26.11.2032"
    },
    {
        "engDate": "1976-03-10",
        "nepDate": "27.11.2032"
    },
    {
        "engDate": "1976-03-11",
        "nepDate": "28.11.2032"
    },
    {
        "engDate": "1976-03-12",
        "nepDate": "29.11.2032"
    },
    {
        "engDate": "1976-03-13",
        "nepDate": "30.11.2032"
    },
    {
        "engDate": "1976-03-14",
        "nepDate": "01.12.2032"
    },
    {
        "engDate": "1976-03-15",
        "nepDate": "02.12.2032"
    },
    {
        "engDate": "1976-03-16",
        "nepDate": "03.12.2032"
    },
    {
        "engDate": "1976-03-17",
        "nepDate": "04.12.2032"
    },
    {
        "engDate": "1976-03-18",
        "nepDate": "05.12.2032"
    },
    {
        "engDate": "1976-03-19",
        "nepDate": "06.12.2032"
    },
    {
        "engDate": "1976-03-20",
        "nepDate": "07.12.2032"
    },
    {
        "engDate": "1976-03-21",
        "nepDate": "08.12.2032"
    },
    {
        "engDate": "1976-03-22",
        "nepDate": "09.12.2032"
    },
    {
        "engDate": "1976-03-23",
        "nepDate": "10.12.2032"
    },
    {
        "engDate": "1976-03-24",
        "nepDate": "11.12.2032"
    },
    {
        "engDate": "1976-03-25",
        "nepDate": "12.12.2032"
    },
    {
        "engDate": "1976-03-26",
        "nepDate": "13.12.2032"
    },
    {
        "engDate": "1976-03-27",
        "nepDate": "14.12.2032"
    },
    {
        "engDate": "1976-03-28",
        "nepDate": "15.12.2032"
    },
    {
        "engDate": "1976-03-29",
        "nepDate": "16.12.2032"
    },
    {
        "engDate": "1976-03-30",
        "nepDate": "17.12.2032"
    },
    {
        "engDate": "1976-03-31",
        "nepDate": "18.12.2032"
    },
    {
        "engDate": "1976-04-01",
        "nepDate": "19.12.2032"
    },
    {
        "engDate": "1976-04-02",
        "nepDate": "20.12.2032"
    },
    {
        "engDate": "1976-04-03",
        "nepDate": "21.12.2032"
    },
    {
        "engDate": "1976-04-04",
        "nepDate": "22.12.2032"
    },
    {
        "engDate": "1976-04-05",
        "nepDate": "23.12.2032"
    },
    {
        "engDate": "1976-04-06",
        "nepDate": "24.12.2032"
    },
    {
        "engDate": "1976-04-07",
        "nepDate": "25.12.2032"
    },
    {
        "engDate": "1976-04-08",
        "nepDate": "26.12.2032"
    },
    {
        "engDate": "1976-04-09",
        "nepDate": "27.12.2032"
    },
    {
        "engDate": "1976-04-10",
        "nepDate": "28.12.2032"
    },
    {
        "engDate": "1976-04-11",
        "nepDate": "29.12.2032"
    },
    {
        "engDate": "1976-04-12",
        "nepDate": "30.12.2032"
    },
    {
        "engDate": "1976-04-13",
        "nepDate": "01.01.2033"
    },
    {
        "engDate": "1976-04-14",
        "nepDate": "02.01.2033"
    },
    {
        "engDate": "1976-04-15",
        "nepDate": "03.01.2033"
    },
    {
        "engDate": "1976-04-16",
        "nepDate": "04.01.2033"
    },
    {
        "engDate": "1976-04-17",
        "nepDate": "05.01.2033"
    },
    {
        "engDate": "1976-04-18",
        "nepDate": "06.01.2033"
    },
    {
        "engDate": "1976-04-19",
        "nepDate": "07.01.2033"
    },
    {
        "engDate": "1976-04-20",
        "nepDate": "08.01.2033"
    },
    {
        "engDate": "1976-04-21",
        "nepDate": "09.01.2033"
    },
    {
        "engDate": "1976-04-22",
        "nepDate": "10.01.2033"
    },
    {
        "engDate": "1976-04-23",
        "nepDate": "11.01.2033"
    },
    {
        "engDate": "1976-04-24",
        "nepDate": "12.01.2033"
    },
    {
        "engDate": "1976-04-25",
        "nepDate": "13.01.2033"
    },
    {
        "engDate": "1976-04-26",
        "nepDate": "14.01.2033"
    },
    {
        "engDate": "1976-04-27",
        "nepDate": "15.01.2033"
    },
    {
        "engDate": "1976-04-28",
        "nepDate": "16.01.2033"
    },
    {
        "engDate": "1976-04-29",
        "nepDate": "17.01.2033"
    },
    {
        "engDate": "1976-04-30",
        "nepDate": "18.01.2033"
    },
    {
        "engDate": "1976-05-01",
        "nepDate": "19.01.2033"
    },
    {
        "engDate": "1976-05-02",
        "nepDate": "20.01.2033"
    },
    {
        "engDate": "1976-05-03",
        "nepDate": "21.01.2033"
    },
    {
        "engDate": "1976-05-04",
        "nepDate": "22.01.2033"
    },
    {
        "engDate": "1976-05-05",
        "nepDate": "23.01.2033"
    },
    {
        "engDate": "1976-05-06",
        "nepDate": "24.01.2033"
    },
    {
        "engDate": "1976-05-07",
        "nepDate": "25.01.2033"
    },
    {
        "engDate": "1976-05-08",
        "nepDate": "26.01.2033"
    },
    {
        "engDate": "1976-05-09",
        "nepDate": "27.01.2033"
    },
    {
        "engDate": "1976-05-10",
        "nepDate": "28.01.2033"
    },
    {
        "engDate": "1976-05-11",
        "nepDate": "29.01.2033"
    },
    {
        "engDate": "1976-05-12",
        "nepDate": "30.01.2033"
    },
    {
        "engDate": "1976-05-13",
        "nepDate": "31.01.2033"
    },
    {
        "engDate": "1976-05-14",
        "nepDate": "01.02.2033"
    },
    {
        "engDate": "1976-05-15",
        "nepDate": "02.02.2033"
    },
    {
        "engDate": "1976-05-16",
        "nepDate": "03.02.2033"
    },
    {
        "engDate": "1976-05-17",
        "nepDate": "04.02.2033"
    },
    {
        "engDate": "1976-05-18",
        "nepDate": "05.02.2033"
    },
    {
        "engDate": "1976-05-19",
        "nepDate": "06.02.2033"
    },
    {
        "engDate": "1976-05-20",
        "nepDate": "07.02.2033"
    },
    {
        "engDate": "1976-05-21",
        "nepDate": "08.02.2033"
    },
    {
        "engDate": "1976-05-22",
        "nepDate": "09.02.2033"
    },
    {
        "engDate": "1976-05-23",
        "nepDate": "10.02.2033"
    },
    {
        "engDate": "1976-05-24",
        "nepDate": "11.02.2033"
    },
    {
        "engDate": "1976-05-25",
        "nepDate": "12.02.2033"
    },
    {
        "engDate": "1976-05-26",
        "nepDate": "13.02.2033"
    },
    {
        "engDate": "1976-05-27",
        "nepDate": "14.02.2033"
    },
    {
        "engDate": "1976-05-28",
        "nepDate": "15.02.2033"
    },
    {
        "engDate": "1976-05-29",
        "nepDate": "16.02.2033"
    },
    {
        "engDate": "1976-05-30",
        "nepDate": "17.02.2033"
    },
    {
        "engDate": "1976-05-31",
        "nepDate": "18.02.2033"
    },
    {
        "engDate": "1976-06-01",
        "nepDate": "19.02.2033"
    },
    {
        "engDate": "1976-06-02",
        "nepDate": "20.02.2033"
    },
    {
        "engDate": "1976-06-03",
        "nepDate": "21.02.2033"
    },
    {
        "engDate": "1976-06-04",
        "nepDate": "22.02.2033"
    },
    {
        "engDate": "1976-06-05",
        "nepDate": "23.02.2033"
    },
    {
        "engDate": "1976-06-06",
        "nepDate": "24.02.2033"
    },
    {
        "engDate": "1976-06-07",
        "nepDate": "25.02.2033"
    },
    {
        "engDate": "1976-06-08",
        "nepDate": "26.02.2033"
    },
    {
        "engDate": "1976-06-09",
        "nepDate": "27.02.2033"
    },
    {
        "engDate": "1976-06-10",
        "nepDate": "28.02.2033"
    },
    {
        "engDate": "1976-06-11",
        "nepDate": "29.02.2033"
    },
    {
        "engDate": "1976-06-12",
        "nepDate": "30.02.2033"
    },
    {
        "engDate": "1976-06-13",
        "nepDate": "31.02.2033"
    },
    {
        "engDate": "1976-06-14",
        "nepDate": "01.03.2033"
    },
    {
        "engDate": "1976-06-15",
        "nepDate": "02.03.2033"
    },
    {
        "engDate": "1976-06-16",
        "nepDate": "03.03.2033"
    },
    {
        "engDate": "1976-06-17",
        "nepDate": "04.03.2033"
    },
    {
        "engDate": "1976-06-18",
        "nepDate": "05.03.2033"
    },
    {
        "engDate": "1976-06-19",
        "nepDate": "06.03.2033"
    },
    {
        "engDate": "1976-06-20",
        "nepDate": "07.03.2033"
    },
    {
        "engDate": "1976-06-21",
        "nepDate": "08.03.2033"
    },
    {
        "engDate": "1976-06-22",
        "nepDate": "09.03.2033"
    },
    {
        "engDate": "1976-06-23",
        "nepDate": "10.03.2033"
    },
    {
        "engDate": "1976-06-24",
        "nepDate": "11.03.2033"
    },
    {
        "engDate": "1976-06-25",
        "nepDate": "12.03.2033"
    },
    {
        "engDate": "1976-06-26",
        "nepDate": "13.03.2033"
    },
    {
        "engDate": "1976-06-27",
        "nepDate": "14.03.2033"
    },
    {
        "engDate": "1976-06-28",
        "nepDate": "15.03.2033"
    },
    {
        "engDate": "1976-06-29",
        "nepDate": "16.03.2033"
    },
    {
        "engDate": "1976-06-30",
        "nepDate": "17.03.2033"
    },
    {
        "engDate": "1976-07-01",
        "nepDate": "18.03.2033"
    },
    {
        "engDate": "1976-07-02",
        "nepDate": "19.03.2033"
    },
    {
        "engDate": "1976-07-03",
        "nepDate": "20.03.2033"
    },
    {
        "engDate": "1976-07-04",
        "nepDate": "21.03.2033"
    },
    {
        "engDate": "1976-07-05",
        "nepDate": "22.03.2033"
    },
    {
        "engDate": "1976-07-06",
        "nepDate": "23.03.2033"
    },
    {
        "engDate": "1976-07-07",
        "nepDate": "24.03.2033"
    },
    {
        "engDate": "1976-07-08",
        "nepDate": "25.03.2033"
    },
    {
        "engDate": "1976-07-09",
        "nepDate": "26.03.2033"
    },
    {
        "engDate": "1976-07-10",
        "nepDate": "27.03.2033"
    },
    {
        "engDate": "1976-07-11",
        "nepDate": "28.03.2033"
    },
    {
        "engDate": "1976-07-12",
        "nepDate": "29.03.2033"
    },
    {
        "engDate": "1976-07-13",
        "nepDate": "30.03.2033"
    },
    {
        "engDate": "1976-07-14",
        "nepDate": "31.03.2033"
    },
    {
        "engDate": "1976-07-15",
        "nepDate": "32.03.2033"
    },
    {
        "engDate": "1976-07-16",
        "nepDate": "01.04.2033"
    },
    {
        "engDate": "1976-07-17",
        "nepDate": "02.04.2033"
    },
    {
        "engDate": "1976-07-18",
        "nepDate": "03.04.2033"
    },
    {
        "engDate": "1976-07-19",
        "nepDate": "04.04.2033"
    },
    {
        "engDate": "1976-07-20",
        "nepDate": "05.04.2033"
    },
    {
        "engDate": "1976-07-21",
        "nepDate": "06.04.2033"
    },
    {
        "engDate": "1976-07-22",
        "nepDate": "07.04.2033"
    },
    {
        "engDate": "1976-07-23",
        "nepDate": "08.04.2033"
    },
    {
        "engDate": "1976-07-24",
        "nepDate": "09.04.2033"
    },
    {
        "engDate": "1976-07-25",
        "nepDate": "10.04.2033"
    },
    {
        "engDate": "1976-07-26",
        "nepDate": "11.04.2033"
    },
    {
        "engDate": "1976-07-27",
        "nepDate": "12.04.2033"
    },
    {
        "engDate": "1976-07-28",
        "nepDate": "13.04.2033"
    },
    {
        "engDate": "1976-07-29",
        "nepDate": "14.04.2033"
    },
    {
        "engDate": "1976-07-30",
        "nepDate": "15.04.2033"
    },
    {
        "engDate": "1976-07-31",
        "nepDate": "16.04.2033"
    },
    {
        "engDate": "1976-08-01",
        "nepDate": "17.04.2033"
    },
    {
        "engDate": "1976-08-02",
        "nepDate": "18.04.2033"
    },
    {
        "engDate": "1976-08-03",
        "nepDate": "19.04.2033"
    },
    {
        "engDate": "1976-08-04",
        "nepDate": "20.04.2033"
    },
    {
        "engDate": "1976-08-05",
        "nepDate": "21.04.2033"
    },
    {
        "engDate": "1976-08-06",
        "nepDate": "22.04.2033"
    },
    {
        "engDate": "1976-08-07",
        "nepDate": "23.04.2033"
    },
    {
        "engDate": "1976-08-08",
        "nepDate": "24.04.2033"
    },
    {
        "engDate": "1976-08-09",
        "nepDate": "25.04.2033"
    },
    {
        "engDate": "1976-08-10",
        "nepDate": "26.04.2033"
    },
    {
        "engDate": "1976-08-11",
        "nepDate": "27.04.2033"
    },
    {
        "engDate": "1976-08-12",
        "nepDate": "28.04.2033"
    },
    {
        "engDate": "1976-08-13",
        "nepDate": "29.04.2033"
    },
    {
        "engDate": "1976-08-14",
        "nepDate": "30.04.2033"
    },
    {
        "engDate": "1976-08-15",
        "nepDate": "31.04.2033"
    },
    {
        "engDate": "1976-08-16",
        "nepDate": "32.04.2033"
    },
    {
        "engDate": "1976-08-17",
        "nepDate": "01.05.2033"
    },
    {
        "engDate": "1976-08-18",
        "nepDate": "02.05.2033"
    },
    {
        "engDate": "1976-08-19",
        "nepDate": "03.05.2033"
    },
    {
        "engDate": "1976-08-20",
        "nepDate": "04.05.2033"
    },
    {
        "engDate": "1976-08-21",
        "nepDate": "05.05.2033"
    },
    {
        "engDate": "1976-08-22",
        "nepDate": "06.05.2033"
    },
    {
        "engDate": "1976-08-23",
        "nepDate": "07.05.2033"
    },
    {
        "engDate": "1976-08-24",
        "nepDate": "08.05.2033"
    },
    {
        "engDate": "1976-08-25",
        "nepDate": "09.05.2033"
    },
    {
        "engDate": "1976-08-26",
        "nepDate": "10.05.2033"
    },
    {
        "engDate": "1976-08-27",
        "nepDate": "11.05.2033"
    },
    {
        "engDate": "1976-08-28",
        "nepDate": "12.05.2033"
    },
    {
        "engDate": "1976-08-29",
        "nepDate": "13.05.2033"
    },
    {
        "engDate": "1976-08-30",
        "nepDate": "14.05.2033"
    },
    {
        "engDate": "1976-08-31",
        "nepDate": "15.05.2033"
    },
    {
        "engDate": "1976-09-01",
        "nepDate": "16.05.2033"
    },
    {
        "engDate": "1976-09-02",
        "nepDate": "17.05.2033"
    },
    {
        "engDate": "1976-09-03",
        "nepDate": "18.05.2033"
    },
    {
        "engDate": "1976-09-04",
        "nepDate": "19.05.2033"
    },
    {
        "engDate": "1976-09-05",
        "nepDate": "20.05.2033"
    },
    {
        "engDate": "1976-09-06",
        "nepDate": "21.05.2033"
    },
    {
        "engDate": "1976-09-07",
        "nepDate": "22.05.2033"
    },
    {
        "engDate": "1976-09-08",
        "nepDate": "23.05.2033"
    },
    {
        "engDate": "1976-09-09",
        "nepDate": "24.05.2033"
    },
    {
        "engDate": "1976-09-10",
        "nepDate": "25.05.2033"
    },
    {
        "engDate": "1976-09-11",
        "nepDate": "26.05.2033"
    },
    {
        "engDate": "1976-09-12",
        "nepDate": "27.05.2033"
    },
    {
        "engDate": "1976-09-13",
        "nepDate": "28.05.2033"
    },
    {
        "engDate": "1976-09-14",
        "nepDate": "29.05.2033"
    },
    {
        "engDate": "1976-09-15",
        "nepDate": "30.05.2033"
    },
    {
        "engDate": "1976-09-16",
        "nepDate": "31.05.2033"
    },
    {
        "engDate": "1976-09-17",
        "nepDate": "01.06.2033"
    },
    {
        "engDate": "1976-09-18",
        "nepDate": "02.06.2033"
    },
    {
        "engDate": "1976-09-19",
        "nepDate": "03.06.2033"
    },
    {
        "engDate": "1976-09-20",
        "nepDate": "04.06.2033"
    },
    {
        "engDate": "1976-09-21",
        "nepDate": "05.06.2033"
    },
    {
        "engDate": "1976-09-22",
        "nepDate": "06.06.2033"
    },
    {
        "engDate": "1976-09-23",
        "nepDate": "07.06.2033"
    },
    {
        "engDate": "1976-09-24",
        "nepDate": "08.06.2033"
    },
    {
        "engDate": "1976-09-25",
        "nepDate": "09.06.2033"
    },
    {
        "engDate": "1976-09-26",
        "nepDate": "10.06.2033"
    },
    {
        "engDate": "1976-09-27",
        "nepDate": "11.06.2033"
    },
    {
        "engDate": "1976-09-28",
        "nepDate": "12.06.2033"
    },
    {
        "engDate": "1976-09-29",
        "nepDate": "13.06.2033"
    },
    {
        "engDate": "1976-09-30",
        "nepDate": "14.06.2033"
    },
    {
        "engDate": "1976-10-01",
        "nepDate": "15.06.2033"
    },
    {
        "engDate": "1976-10-02",
        "nepDate": "16.06.2033"
    },
    {
        "engDate": "1976-10-03",
        "nepDate": "17.06.2033"
    },
    {
        "engDate": "1976-10-04",
        "nepDate": "18.06.2033"
    },
    {
        "engDate": "1976-10-05",
        "nepDate": "19.06.2033"
    },
    {
        "engDate": "1976-10-06",
        "nepDate": "20.06.2033"
    },
    {
        "engDate": "1976-10-07",
        "nepDate": "21.06.2033"
    },
    {
        "engDate": "1976-10-08",
        "nepDate": "22.06.2033"
    },
    {
        "engDate": "1976-10-09",
        "nepDate": "23.06.2033"
    },
    {
        "engDate": "1976-10-10",
        "nepDate": "24.06.2033"
    },
    {
        "engDate": "1976-10-11",
        "nepDate": "25.06.2033"
    },
    {
        "engDate": "1976-10-12",
        "nepDate": "26.06.2033"
    },
    {
        "engDate": "1976-10-13",
        "nepDate": "27.06.2033"
    },
    {
        "engDate": "1976-10-14",
        "nepDate": "28.06.2033"
    },
    {
        "engDate": "1976-10-15",
        "nepDate": "29.06.2033"
    },
    {
        "engDate": "1976-10-16",
        "nepDate": "30.06.2033"
    },
    {
        "engDate": "1976-10-17",
        "nepDate": "01.07.2033"
    },
    {
        "engDate": "1976-10-18",
        "nepDate": "02.07.2033"
    },
    {
        "engDate": "1976-10-19",
        "nepDate": "03.07.2033"
    },
    {
        "engDate": "1976-10-20",
        "nepDate": "04.07.2033"
    },
    {
        "engDate": "1976-10-21",
        "nepDate": "05.07.2033"
    },
    {
        "engDate": "1976-10-22",
        "nepDate": "06.07.2033"
    },
    {
        "engDate": "1976-10-23",
        "nepDate": "07.07.2033"
    },
    {
        "engDate": "1976-10-24",
        "nepDate": "08.07.2033"
    },
    {
        "engDate": "1976-10-25",
        "nepDate": "09.07.2033"
    },
    {
        "engDate": "1976-10-26",
        "nepDate": "10.07.2033"
    },
    {
        "engDate": "1976-10-27",
        "nepDate": "11.07.2033"
    },
    {
        "engDate": "1976-10-28",
        "nepDate": "12.07.2033"
    },
    {
        "engDate": "1976-10-29",
        "nepDate": "13.07.2033"
    },
    {
        "engDate": "1976-10-30",
        "nepDate": "14.07.2033"
    },
    {
        "engDate": "1976-10-31",
        "nepDate": "15.07.2033"
    },
    {
        "engDate": "1976-11-01",
        "nepDate": "16.07.2033"
    },
    {
        "engDate": "1976-11-02",
        "nepDate": "17.07.2033"
    },
    {
        "engDate": "1976-11-03",
        "nepDate": "18.07.2033"
    },
    {
        "engDate": "1976-11-04",
        "nepDate": "19.07.2033"
    },
    {
        "engDate": "1976-11-05",
        "nepDate": "20.07.2033"
    },
    {
        "engDate": "1976-11-06",
        "nepDate": "21.07.2033"
    },
    {
        "engDate": "1976-11-07",
        "nepDate": "22.07.2033"
    },
    {
        "engDate": "1976-11-08",
        "nepDate": "23.07.2033"
    },
    {
        "engDate": "1976-11-09",
        "nepDate": "24.07.2033"
    },
    {
        "engDate": "1976-11-10",
        "nepDate": "25.07.2033"
    },
    {
        "engDate": "1976-11-11",
        "nepDate": "26.07.2033"
    },
    {
        "engDate": "1976-11-12",
        "nepDate": "27.07.2033"
    },
    {
        "engDate": "1976-11-13",
        "nepDate": "28.07.2033"
    },
    {
        "engDate": "1976-11-14",
        "nepDate": "29.07.2033"
    },
    {
        "engDate": "1976-11-15",
        "nepDate": "30.07.2033"
    },
    {
        "engDate": "1976-11-16",
        "nepDate": "01.08.2033"
    },
    {
        "engDate": "1976-11-17",
        "nepDate": "02.08.2033"
    },
    {
        "engDate": "1976-11-18",
        "nepDate": "03.08.2033"
    },
    {
        "engDate": "1976-11-19",
        "nepDate": "04.08.2033"
    },
    {
        "engDate": "1976-11-20",
        "nepDate": "05.08.2033"
    },
    {
        "engDate": "1976-11-21",
        "nepDate": "06.08.2033"
    },
    {
        "engDate": "1976-11-22",
        "nepDate": "07.08.2033"
    },
    {
        "engDate": "1976-11-23",
        "nepDate": "08.08.2033"
    },
    {
        "engDate": "1976-11-24",
        "nepDate": "09.08.2033"
    },
    {
        "engDate": "1976-11-25",
        "nepDate": "10.08.2033"
    },
    {
        "engDate": "1976-11-26",
        "nepDate": "11.08.2033"
    },
    {
        "engDate": "1976-11-27",
        "nepDate": "12.08.2033"
    },
    {
        "engDate": "1976-11-28",
        "nepDate": "13.08.2033"
    },
    {
        "engDate": "1976-11-29",
        "nepDate": "14.08.2033"
    },
    {
        "engDate": "1976-11-30",
        "nepDate": "15.08.2033"
    },
    {
        "engDate": "1976-12-01",
        "nepDate": "16.08.2033"
    },
    {
        "engDate": "1976-12-02",
        "nepDate": "17.08.2033"
    },
    {
        "engDate": "1976-12-03",
        "nepDate": "18.08.2033"
    },
    {
        "engDate": "1976-12-04",
        "nepDate": "19.08.2033"
    },
    {
        "engDate": "1976-12-05",
        "nepDate": "20.08.2033"
    },
    {
        "engDate": "1976-12-06",
        "nepDate": "21.08.2033"
    },
    {
        "engDate": "1976-12-07",
        "nepDate": "22.08.2033"
    },
    {
        "engDate": "1976-12-08",
        "nepDate": "23.08.2033"
    },
    {
        "engDate": "1976-12-09",
        "nepDate": "24.08.2033"
    },
    {
        "engDate": "1976-12-10",
        "nepDate": "25.08.2033"
    },
    {
        "engDate": "1976-12-11",
        "nepDate": "26.08.2033"
    },
    {
        "engDate": "1976-12-12",
        "nepDate": "27.08.2033"
    },
    {
        "engDate": "1976-12-13",
        "nepDate": "28.08.2033"
    },
    {
        "engDate": "1976-12-14",
        "nepDate": "29.08.2033"
    },
    {
        "engDate": "1976-12-15",
        "nepDate": "01.09.2033"
    },
    {
        "engDate": "1976-12-16",
        "nepDate": "02.09.2033"
    },
    {
        "engDate": "1976-12-17",
        "nepDate": "03.09.2033"
    },
    {
        "engDate": "1976-12-18",
        "nepDate": "04.09.2033"
    },
    {
        "engDate": "1976-12-19",
        "nepDate": "05.09.2033"
    },
    {
        "engDate": "1976-12-20",
        "nepDate": "06.09.2033"
    },
    {
        "engDate": "1976-12-21",
        "nepDate": "07.09.2033"
    },
    {
        "engDate": "1976-12-22",
        "nepDate": "08.09.2033"
    },
    {
        "engDate": "1976-12-23",
        "nepDate": "09.09.2033"
    },
    {
        "engDate": "1976-12-24",
        "nepDate": "10.09.2033"
    },
    {
        "engDate": "1976-12-25",
        "nepDate": "11.09.2033"
    },
    {
        "engDate": "1976-12-26",
        "nepDate": "12.09.2033"
    },
    {
        "engDate": "1976-12-27",
        "nepDate": "13.09.2033"
    },
    {
        "engDate": "1976-12-28",
        "nepDate": "14.09.2033"
    },
    {
        "engDate": "1976-12-29",
        "nepDate": "15.09.2033"
    },
    {
        "engDate": "1976-12-30",
        "nepDate": "16.09.2033"
    },
    {
        "engDate": "1976-12-31",
        "nepDate": "17.09.2033"
    },
    {
        "engDate": "1977-01-01",
        "nepDate": "18.09.2033"
    },
    {
        "engDate": "1977-01-02",
        "nepDate": "19.09.2033"
    },
    {
        "engDate": "1977-01-03",
        "nepDate": "20.09.2033"
    },
    {
        "engDate": "1977-01-04",
        "nepDate": "21.09.2033"
    },
    {
        "engDate": "1977-01-05",
        "nepDate": "22.09.2033"
    },
    {
        "engDate": "1977-01-06",
        "nepDate": "23.09.2033"
    },
    {
        "engDate": "1977-01-07",
        "nepDate": "24.09.2033"
    },
    {
        "engDate": "1977-01-08",
        "nepDate": "25.09.2033"
    },
    {
        "engDate": "1977-01-09",
        "nepDate": "26.09.2033"
    },
    {
        "engDate": "1977-01-10",
        "nepDate": "27.09.2033"
    },
    {
        "engDate": "1977-01-11",
        "nepDate": "28.09.2033"
    },
    {
        "engDate": "1977-01-12",
        "nepDate": "29.09.2033"
    },
    {
        "engDate": "1977-01-13",
        "nepDate": "30.09.2033"
    },
    {
        "engDate": "1977-01-14",
        "nepDate": "01.10.2033"
    },
    {
        "engDate": "1977-01-15",
        "nepDate": "02.10.2033"
    },
    {
        "engDate": "1977-01-16",
        "nepDate": "03.10.2033"
    },
    {
        "engDate": "1977-01-17",
        "nepDate": "04.10.2033"
    },
    {
        "engDate": "1977-01-18",
        "nepDate": "05.10.2033"
    },
    {
        "engDate": "1977-01-19",
        "nepDate": "06.10.2033"
    },
    {
        "engDate": "1977-01-20",
        "nepDate": "07.10.2033"
    },
    {
        "engDate": "1977-01-21",
        "nepDate": "08.10.2033"
    },
    {
        "engDate": "1977-01-22",
        "nepDate": "09.10.2033"
    },
    {
        "engDate": "1977-01-23",
        "nepDate": "10.10.2033"
    },
    {
        "engDate": "1977-01-24",
        "nepDate": "11.10.2033"
    },
    {
        "engDate": "1977-01-25",
        "nepDate": "12.10.2033"
    },
    {
        "engDate": "1977-01-26",
        "nepDate": "13.10.2033"
    },
    {
        "engDate": "1977-01-27",
        "nepDate": "14.10.2033"
    },
    {
        "engDate": "1977-01-28",
        "nepDate": "15.10.2033"
    },
    {
        "engDate": "1977-01-29",
        "nepDate": "16.10.2033"
    },
    {
        "engDate": "1977-01-30",
        "nepDate": "17.10.2033"
    },
    {
        "engDate": "1977-01-31",
        "nepDate": "18.10.2033"
    },
    {
        "engDate": "1977-02-01",
        "nepDate": "19.10.2033"
    },
    {
        "engDate": "1977-02-02",
        "nepDate": "20.10.2033"
    },
    {
        "engDate": "1977-02-03",
        "nepDate": "21.10.2033"
    },
    {
        "engDate": "1977-02-04",
        "nepDate": "22.10.2033"
    },
    {
        "engDate": "1977-02-05",
        "nepDate": "23.10.2033"
    },
    {
        "engDate": "1977-02-06",
        "nepDate": "24.10.2033"
    },
    {
        "engDate": "1977-02-07",
        "nepDate": "25.10.2033"
    },
    {
        "engDate": "1977-02-08",
        "nepDate": "26.10.2033"
    },
    {
        "engDate": "1977-02-09",
        "nepDate": "27.10.2033"
    },
    {
        "engDate": "1977-02-10",
        "nepDate": "28.10.2033"
    },
    {
        "engDate": "1977-02-11",
        "nepDate": "29.10.2033"
    },
    {
        "engDate": "1977-02-12",
        "nepDate": "01.11.2033"
    },
    {
        "engDate": "1977-02-13",
        "nepDate": "02.11.2033"
    },
    {
        "engDate": "1977-02-14",
        "nepDate": "03.11.2033"
    },
    {
        "engDate": "1977-02-15",
        "nepDate": "04.11.2033"
    },
    {
        "engDate": "1977-02-16",
        "nepDate": "05.11.2033"
    },
    {
        "engDate": "1977-02-17",
        "nepDate": "06.11.2033"
    },
    {
        "engDate": "1977-02-18",
        "nepDate": "07.11.2033"
    },
    {
        "engDate": "1977-02-19",
        "nepDate": "08.11.2033"
    },
    {
        "engDate": "1977-02-20",
        "nepDate": "09.11.2033"
    },
    {
        "engDate": "1977-02-21",
        "nepDate": "10.11.2033"
    },
    {
        "engDate": "1977-02-22",
        "nepDate": "11.11.2033"
    },
    {
        "engDate": "1977-02-23",
        "nepDate": "12.11.2033"
    },
    {
        "engDate": "1977-02-24",
        "nepDate": "13.11.2033"
    },
    {
        "engDate": "1977-02-25",
        "nepDate": "14.11.2033"
    },
    {
        "engDate": "1977-02-26",
        "nepDate": "15.11.2033"
    },
    {
        "engDate": "1977-02-27",
        "nepDate": "16.11.2033"
    },
    {
        "engDate": "1977-02-28",
        "nepDate": "17.11.2033"
    },
    {
        "engDate": "1977-03-01",
        "nepDate": "18.11.2033"
    },
    {
        "engDate": "1977-03-02",
        "nepDate": "19.11.2033"
    },
    {
        "engDate": "1977-03-03",
        "nepDate": "20.11.2033"
    },
    {
        "engDate": "1977-03-04",
        "nepDate": "21.11.2033"
    },
    {
        "engDate": "1977-03-05",
        "nepDate": "22.11.2033"
    },
    {
        "engDate": "1977-03-06",
        "nepDate": "23.11.2033"
    },
    {
        "engDate": "1977-03-07",
        "nepDate": "24.11.2033"
    },
    {
        "engDate": "1977-03-08",
        "nepDate": "25.11.2033"
    },
    {
        "engDate": "1977-03-09",
        "nepDate": "26.11.2033"
    },
    {
        "engDate": "1977-03-10",
        "nepDate": "27.11.2033"
    },
    {
        "engDate": "1977-03-11",
        "nepDate": "28.11.2033"
    },
    {
        "engDate": "1977-03-12",
        "nepDate": "29.11.2033"
    },
    {
        "engDate": "1977-03-13",
        "nepDate": "30.11.2033"
    },
    {
        "engDate": "1977-03-14",
        "nepDate": "01.12.2033"
    },
    {
        "engDate": "1977-03-15",
        "nepDate": "02.12.2033"
    },
    {
        "engDate": "1977-03-16",
        "nepDate": "03.12.2033"
    },
    {
        "engDate": "1977-03-17",
        "nepDate": "04.12.2033"
    },
    {
        "engDate": "1977-03-18",
        "nepDate": "05.12.2033"
    },
    {
        "engDate": "1977-03-19",
        "nepDate": "06.12.2033"
    },
    {
        "engDate": "1977-03-20",
        "nepDate": "07.12.2033"
    },
    {
        "engDate": "1977-03-21",
        "nepDate": "08.12.2033"
    },
    {
        "engDate": "1977-03-22",
        "nepDate": "09.12.2033"
    },
    {
        "engDate": "1977-03-23",
        "nepDate": "10.12.2033"
    },
    {
        "engDate": "1977-03-24",
        "nepDate": "11.12.2033"
    },
    {
        "engDate": "1977-03-25",
        "nepDate": "12.12.2033"
    },
    {
        "engDate": "1977-03-26",
        "nepDate": "13.12.2033"
    },
    {
        "engDate": "1977-03-27",
        "nepDate": "14.12.2033"
    },
    {
        "engDate": "1977-03-28",
        "nepDate": "15.12.2033"
    },
    {
        "engDate": "1977-03-29",
        "nepDate": "16.12.2033"
    },
    {
        "engDate": "1977-03-30",
        "nepDate": "17.12.2033"
    },
    {
        "engDate": "1977-03-31",
        "nepDate": "18.12.2033"
    },
    {
        "engDate": "1977-04-01",
        "nepDate": "19.12.2033"
    },
    {
        "engDate": "1977-04-02",
        "nepDate": "20.12.2033"
    },
    {
        "engDate": "1977-04-03",
        "nepDate": "21.12.2033"
    },
    {
        "engDate": "1977-04-04",
        "nepDate": "22.12.2033"
    },
    {
        "engDate": "1977-04-05",
        "nepDate": "23.12.2033"
    },
    {
        "engDate": "1977-04-06",
        "nepDate": "24.12.2033"
    },
    {
        "engDate": "1977-04-07",
        "nepDate": "25.12.2033"
    },
    {
        "engDate": "1977-04-08",
        "nepDate": "26.12.2033"
    },
    {
        "engDate": "1977-04-09",
        "nepDate": "27.12.2033"
    },
    {
        "engDate": "1977-04-10",
        "nepDate": "28.12.2033"
    },
    {
        "engDate": "1977-04-11",
        "nepDate": "29.12.2033"
    },
    {
        "engDate": "1977-04-12",
        "nepDate": "30.12.2033"
    },
    {
        "engDate": "1977-04-13",
        "nepDate": "01.01.2034"
    },
    {
        "engDate": "1977-04-14",
        "nepDate": "02.01.2034"
    },
    {
        "engDate": "1977-04-15",
        "nepDate": "03.01.2034"
    },
    {
        "engDate": "1977-04-16",
        "nepDate": "04.01.2034"
    },
    {
        "engDate": "1977-04-17",
        "nepDate": "05.01.2034"
    },
    {
        "engDate": "1977-04-18",
        "nepDate": "06.01.2034"
    },
    {
        "engDate": "1977-04-19",
        "nepDate": "07.01.2034"
    },
    {
        "engDate": "1977-04-20",
        "nepDate": "08.01.2034"
    },
    {
        "engDate": "1977-04-21",
        "nepDate": "09.01.2034"
    },
    {
        "engDate": "1977-04-22",
        "nepDate": "10.01.2034"
    },
    {
        "engDate": "1977-04-23",
        "nepDate": "11.01.2034"
    },
    {
        "engDate": "1977-04-24",
        "nepDate": "12.01.2034"
    },
    {
        "engDate": "1977-04-25",
        "nepDate": "13.01.2034"
    },
    {
        "engDate": "1977-04-26",
        "nepDate": "14.01.2034"
    },
    {
        "engDate": "1977-04-27",
        "nepDate": "15.01.2034"
    },
    {
        "engDate": "1977-04-28",
        "nepDate": "16.01.2034"
    },
    {
        "engDate": "1977-04-29",
        "nepDate": "17.01.2034"
    },
    {
        "engDate": "1977-04-30",
        "nepDate": "18.01.2034"
    },
    {
        "engDate": "1977-05-01",
        "nepDate": "19.01.2034"
    },
    {
        "engDate": "1977-05-02",
        "nepDate": "20.01.2034"
    },
    {
        "engDate": "1977-05-03",
        "nepDate": "21.01.2034"
    },
    {
        "engDate": "1977-05-04",
        "nepDate": "22.01.2034"
    },
    {
        "engDate": "1977-05-05",
        "nepDate": "23.01.2034"
    },
    {
        "engDate": "1977-05-06",
        "nepDate": "24.01.2034"
    },
    {
        "engDate": "1977-05-07",
        "nepDate": "25.01.2034"
    },
    {
        "engDate": "1977-05-08",
        "nepDate": "26.01.2034"
    },
    {
        "engDate": "1977-05-09",
        "nepDate": "27.01.2034"
    },
    {
        "engDate": "1977-05-10",
        "nepDate": "28.01.2034"
    },
    {
        "engDate": "1977-05-11",
        "nepDate": "29.01.2034"
    },
    {
        "engDate": "1977-05-12",
        "nepDate": "30.01.2034"
    },
    {
        "engDate": "1977-05-13",
        "nepDate": "31.01.2034"
    },
    {
        "engDate": "1977-05-14",
        "nepDate": "01.02.2034"
    },
    {
        "engDate": "1977-05-15",
        "nepDate": "02.02.2034"
    },
    {
        "engDate": "1977-05-16",
        "nepDate": "03.02.2034"
    },
    {
        "engDate": "1977-05-17",
        "nepDate": "04.02.2034"
    },
    {
        "engDate": "1977-05-18",
        "nepDate": "05.02.2034"
    },
    {
        "engDate": "1977-05-19",
        "nepDate": "06.02.2034"
    },
    {
        "engDate": "1977-05-20",
        "nepDate": "07.02.2034"
    },
    {
        "engDate": "1977-05-21",
        "nepDate": "08.02.2034"
    },
    {
        "engDate": "1977-05-22",
        "nepDate": "09.02.2034"
    },
    {
        "engDate": "1977-05-23",
        "nepDate": "10.02.2034"
    },
    {
        "engDate": "1977-05-24",
        "nepDate": "11.02.2034"
    },
    {
        "engDate": "1977-05-25",
        "nepDate": "12.02.2034"
    },
    {
        "engDate": "1977-05-26",
        "nepDate": "13.02.2034"
    },
    {
        "engDate": "1977-05-27",
        "nepDate": "14.02.2034"
    },
    {
        "engDate": "1977-05-28",
        "nepDate": "15.02.2034"
    },
    {
        "engDate": "1977-05-29",
        "nepDate": "16.02.2034"
    },
    {
        "engDate": "1977-05-30",
        "nepDate": "17.02.2034"
    },
    {
        "engDate": "1977-05-31",
        "nepDate": "18.02.2034"
    },
    {
        "engDate": "1977-06-01",
        "nepDate": "19.02.2034"
    },
    {
        "engDate": "1977-06-02",
        "nepDate": "20.02.2034"
    },
    {
        "engDate": "1977-06-03",
        "nepDate": "21.02.2034"
    },
    {
        "engDate": "1977-06-04",
        "nepDate": "22.02.2034"
    },
    {
        "engDate": "1977-06-05",
        "nepDate": "23.02.2034"
    },
    {
        "engDate": "1977-06-06",
        "nepDate": "24.02.2034"
    },
    {
        "engDate": "1977-06-07",
        "nepDate": "25.02.2034"
    },
    {
        "engDate": "1977-06-08",
        "nepDate": "26.02.2034"
    },
    {
        "engDate": "1977-06-09",
        "nepDate": "27.02.2034"
    },
    {
        "engDate": "1977-06-10",
        "nepDate": "28.02.2034"
    },
    {
        "engDate": "1977-06-11",
        "nepDate": "29.02.2034"
    },
    {
        "engDate": "1977-06-12",
        "nepDate": "30.02.2034"
    },
    {
        "engDate": "1977-06-13",
        "nepDate": "31.02.2034"
    },
    {
        "engDate": "1977-06-14",
        "nepDate": "32.02.2034"
    },
    {
        "engDate": "1977-06-15",
        "nepDate": "01.03.2034"
    },
    {
        "engDate": "1977-06-16",
        "nepDate": "02.03.2034"
    },
    {
        "engDate": "1977-06-17",
        "nepDate": "03.03.2034"
    },
    {
        "engDate": "1977-06-18",
        "nepDate": "04.03.2034"
    },
    {
        "engDate": "1977-06-19",
        "nepDate": "05.03.2034"
    },
    {
        "engDate": "1977-06-20",
        "nepDate": "06.03.2034"
    },
    {
        "engDate": "1977-06-21",
        "nepDate": "07.03.2034"
    },
    {
        "engDate": "1977-06-22",
        "nepDate": "08.03.2034"
    },
    {
        "engDate": "1977-06-23",
        "nepDate": "09.03.2034"
    },
    {
        "engDate": "1977-06-24",
        "nepDate": "10.03.2034"
    },
    {
        "engDate": "1977-06-25",
        "nepDate": "11.03.2034"
    },
    {
        "engDate": "1977-06-26",
        "nepDate": "12.03.2034"
    },
    {
        "engDate": "1977-06-27",
        "nepDate": "13.03.2034"
    },
    {
        "engDate": "1977-06-28",
        "nepDate": "14.03.2034"
    },
    {
        "engDate": "1977-06-29",
        "nepDate": "15.03.2034"
    },
    {
        "engDate": "1977-06-30",
        "nepDate": "16.03.2034"
    },
    {
        "engDate": "1977-07-01",
        "nepDate": "17.03.2034"
    },
    {
        "engDate": "1977-07-02",
        "nepDate": "18.03.2034"
    },
    {
        "engDate": "1977-07-03",
        "nepDate": "19.03.2034"
    },
    {
        "engDate": "1977-07-04",
        "nepDate": "20.03.2034"
    },
    {
        "engDate": "1977-07-05",
        "nepDate": "21.03.2034"
    },
    {
        "engDate": "1977-07-06",
        "nepDate": "22.03.2034"
    },
    {
        "engDate": "1977-07-07",
        "nepDate": "23.03.2034"
    },
    {
        "engDate": "1977-07-08",
        "nepDate": "24.03.2034"
    },
    {
        "engDate": "1977-07-09",
        "nepDate": "25.03.2034"
    },
    {
        "engDate": "1977-07-10",
        "nepDate": "26.03.2034"
    },
    {
        "engDate": "1977-07-11",
        "nepDate": "27.03.2034"
    },
    {
        "engDate": "1977-07-12",
        "nepDate": "28.03.2034"
    },
    {
        "engDate": "1977-07-13",
        "nepDate": "29.03.2034"
    },
    {
        "engDate": "1977-07-14",
        "nepDate": "30.03.2034"
    },
    {
        "engDate": "1977-07-15",
        "nepDate": "31.03.2034"
    },
    {
        "engDate": "1977-07-16",
        "nepDate": "01.04.2034"
    },
    {
        "engDate": "1977-07-17",
        "nepDate": "02.04.2034"
    },
    {
        "engDate": "1977-07-18",
        "nepDate": "03.04.2034"
    },
    {
        "engDate": "1977-07-19",
        "nepDate": "04.04.2034"
    },
    {
        "engDate": "1977-07-20",
        "nepDate": "05.04.2034"
    },
    {
        "engDate": "1977-07-21",
        "nepDate": "06.04.2034"
    },
    {
        "engDate": "1977-07-22",
        "nepDate": "07.04.2034"
    },
    {
        "engDate": "1977-07-23",
        "nepDate": "08.04.2034"
    },
    {
        "engDate": "1977-07-24",
        "nepDate": "09.04.2034"
    },
    {
        "engDate": "1977-07-25",
        "nepDate": "10.04.2034"
    },
    {
        "engDate": "1977-07-26",
        "nepDate": "11.04.2034"
    },
    {
        "engDate": "1977-07-27",
        "nepDate": "12.04.2034"
    },
    {
        "engDate": "1977-07-28",
        "nepDate": "13.04.2034"
    },
    {
        "engDate": "1977-07-29",
        "nepDate": "14.04.2034"
    },
    {
        "engDate": "1977-07-30",
        "nepDate": "15.04.2034"
    },
    {
        "engDate": "1977-07-31",
        "nepDate": "16.04.2034"
    },
    {
        "engDate": "1977-08-01",
        "nepDate": "17.04.2034"
    },
    {
        "engDate": "1977-08-02",
        "nepDate": "18.04.2034"
    },
    {
        "engDate": "1977-08-03",
        "nepDate": "19.04.2034"
    },
    {
        "engDate": "1977-08-04",
        "nepDate": "20.04.2034"
    },
    {
        "engDate": "1977-08-05",
        "nepDate": "21.04.2034"
    },
    {
        "engDate": "1977-08-06",
        "nepDate": "22.04.2034"
    },
    {
        "engDate": "1977-08-07",
        "nepDate": "23.04.2034"
    },
    {
        "engDate": "1977-08-08",
        "nepDate": "24.04.2034"
    },
    {
        "engDate": "1977-08-09",
        "nepDate": "25.04.2034"
    },
    {
        "engDate": "1977-08-10",
        "nepDate": "26.04.2034"
    },
    {
        "engDate": "1977-08-11",
        "nepDate": "27.04.2034"
    },
    {
        "engDate": "1977-08-12",
        "nepDate": "28.04.2034"
    },
    {
        "engDate": "1977-08-13",
        "nepDate": "29.04.2034"
    },
    {
        "engDate": "1977-08-14",
        "nepDate": "30.04.2034"
    },
    {
        "engDate": "1977-08-15",
        "nepDate": "31.04.2034"
    },
    {
        "engDate": "1977-08-16",
        "nepDate": "32.04.2034"
    },
    {
        "engDate": "1977-08-17",
        "nepDate": "01.05.2034"
    },
    {
        "engDate": "1977-08-18",
        "nepDate": "02.05.2034"
    },
    {
        "engDate": "1977-08-19",
        "nepDate": "03.05.2034"
    },
    {
        "engDate": "1977-08-20",
        "nepDate": "04.05.2034"
    },
    {
        "engDate": "1977-08-21",
        "nepDate": "05.05.2034"
    },
    {
        "engDate": "1977-08-22",
        "nepDate": "06.05.2034"
    },
    {
        "engDate": "1977-08-23",
        "nepDate": "07.05.2034"
    },
    {
        "engDate": "1977-08-24",
        "nepDate": "08.05.2034"
    },
    {
        "engDate": "1977-08-25",
        "nepDate": "09.05.2034"
    },
    {
        "engDate": "1977-08-26",
        "nepDate": "10.05.2034"
    },
    {
        "engDate": "1977-08-27",
        "nepDate": "11.05.2034"
    },
    {
        "engDate": "1977-08-28",
        "nepDate": "12.05.2034"
    },
    {
        "engDate": "1977-08-29",
        "nepDate": "13.05.2034"
    },
    {
        "engDate": "1977-08-30",
        "nepDate": "14.05.2034"
    },
    {
        "engDate": "1977-08-31",
        "nepDate": "15.05.2034"
    },
    {
        "engDate": "1977-09-01",
        "nepDate": "16.05.2034"
    },
    {
        "engDate": "1977-09-02",
        "nepDate": "17.05.2034"
    },
    {
        "engDate": "1977-09-03",
        "nepDate": "18.05.2034"
    },
    {
        "engDate": "1977-09-04",
        "nepDate": "19.05.2034"
    },
    {
        "engDate": "1977-09-05",
        "nepDate": "20.05.2034"
    },
    {
        "engDate": "1977-09-06",
        "nepDate": "21.05.2034"
    },
    {
        "engDate": "1977-09-07",
        "nepDate": "22.05.2034"
    },
    {
        "engDate": "1977-09-08",
        "nepDate": "23.05.2034"
    },
    {
        "engDate": "1977-09-09",
        "nepDate": "24.05.2034"
    },
    {
        "engDate": "1977-09-10",
        "nepDate": "25.05.2034"
    },
    {
        "engDate": "1977-09-11",
        "nepDate": "26.05.2034"
    },
    {
        "engDate": "1977-09-12",
        "nepDate": "27.05.2034"
    },
    {
        "engDate": "1977-09-13",
        "nepDate": "28.05.2034"
    },
    {
        "engDate": "1977-09-14",
        "nepDate": "29.05.2034"
    },
    {
        "engDate": "1977-09-15",
        "nepDate": "30.05.2034"
    },
    {
        "engDate": "1977-09-16",
        "nepDate": "31.05.2034"
    },
    {
        "engDate": "1977-09-17",
        "nepDate": "01.06.2034"
    },
    {
        "engDate": "1977-09-18",
        "nepDate": "02.06.2034"
    },
    {
        "engDate": "1977-09-19",
        "nepDate": "03.06.2034"
    },
    {
        "engDate": "1977-09-20",
        "nepDate": "04.06.2034"
    },
    {
        "engDate": "1977-09-21",
        "nepDate": "05.06.2034"
    },
    {
        "engDate": "1977-09-22",
        "nepDate": "06.06.2034"
    },
    {
        "engDate": "1977-09-23",
        "nepDate": "07.06.2034"
    },
    {
        "engDate": "1977-09-24",
        "nepDate": "08.06.2034"
    },
    {
        "engDate": "1977-09-25",
        "nepDate": "09.06.2034"
    },
    {
        "engDate": "1977-09-26",
        "nepDate": "10.06.2034"
    },
    {
        "engDate": "1977-09-27",
        "nepDate": "11.06.2034"
    },
    {
        "engDate": "1977-09-28",
        "nepDate": "12.06.2034"
    },
    {
        "engDate": "1977-09-29",
        "nepDate": "13.06.2034"
    },
    {
        "engDate": "1977-09-30",
        "nepDate": "14.06.2034"
    },
    {
        "engDate": "1977-10-01",
        "nepDate": "15.06.2034"
    },
    {
        "engDate": "1977-10-02",
        "nepDate": "16.06.2034"
    },
    {
        "engDate": "1977-10-03",
        "nepDate": "17.06.2034"
    },
    {
        "engDate": "1977-10-04",
        "nepDate": "18.06.2034"
    },
    {
        "engDate": "1977-10-05",
        "nepDate": "19.06.2034"
    },
    {
        "engDate": "1977-10-06",
        "nepDate": "20.06.2034"
    },
    {
        "engDate": "1977-10-07",
        "nepDate": "21.06.2034"
    },
    {
        "engDate": "1977-10-08",
        "nepDate": "22.06.2034"
    },
    {
        "engDate": "1977-10-09",
        "nepDate": "23.06.2034"
    },
    {
        "engDate": "1977-10-10",
        "nepDate": "24.06.2034"
    },
    {
        "engDate": "1977-10-11",
        "nepDate": "25.06.2034"
    },
    {
        "engDate": "1977-10-12",
        "nepDate": "26.06.2034"
    },
    {
        "engDate": "1977-10-13",
        "nepDate": "27.06.2034"
    },
    {
        "engDate": "1977-10-14",
        "nepDate": "28.06.2034"
    },
    {
        "engDate": "1977-10-15",
        "nepDate": "29.06.2034"
    },
    {
        "engDate": "1977-10-16",
        "nepDate": "30.06.2034"
    },
    {
        "engDate": "1977-10-17",
        "nepDate": "01.07.2034"
    },
    {
        "engDate": "1977-10-18",
        "nepDate": "02.07.2034"
    },
    {
        "engDate": "1977-10-19",
        "nepDate": "03.07.2034"
    },
    {
        "engDate": "1977-10-20",
        "nepDate": "04.07.2034"
    },
    {
        "engDate": "1977-10-21",
        "nepDate": "05.07.2034"
    },
    {
        "engDate": "1977-10-22",
        "nepDate": "06.07.2034"
    },
    {
        "engDate": "1977-10-23",
        "nepDate": "07.07.2034"
    },
    {
        "engDate": "1977-10-24",
        "nepDate": "08.07.2034"
    },
    {
        "engDate": "1977-10-25",
        "nepDate": "09.07.2034"
    },
    {
        "engDate": "1977-10-26",
        "nepDate": "10.07.2034"
    },
    {
        "engDate": "1977-10-27",
        "nepDate": "11.07.2034"
    },
    {
        "engDate": "1977-10-28",
        "nepDate": "12.07.2034"
    },
    {
        "engDate": "1977-10-29",
        "nepDate": "13.07.2034"
    },
    {
        "engDate": "1977-10-30",
        "nepDate": "14.07.2034"
    },
    {
        "engDate": "1977-10-31",
        "nepDate": "15.07.2034"
    },
    {
        "engDate": "1977-11-01",
        "nepDate": "16.07.2034"
    },
    {
        "engDate": "1977-11-02",
        "nepDate": "17.07.2034"
    },
    {
        "engDate": "1977-11-03",
        "nepDate": "18.07.2034"
    },
    {
        "engDate": "1977-11-04",
        "nepDate": "19.07.2034"
    },
    {
        "engDate": "1977-11-05",
        "nepDate": "20.07.2034"
    },
    {
        "engDate": "1977-11-06",
        "nepDate": "21.07.2034"
    },
    {
        "engDate": "1977-11-07",
        "nepDate": "22.07.2034"
    },
    {
        "engDate": "1977-11-08",
        "nepDate": "23.07.2034"
    },
    {
        "engDate": "1977-11-09",
        "nepDate": "24.07.2034"
    },
    {
        "engDate": "1977-11-10",
        "nepDate": "25.07.2034"
    },
    {
        "engDate": "1977-11-11",
        "nepDate": "26.07.2034"
    },
    {
        "engDate": "1977-11-12",
        "nepDate": "27.07.2034"
    },
    {
        "engDate": "1977-11-13",
        "nepDate": "28.07.2034"
    },
    {
        "engDate": "1977-11-14",
        "nepDate": "29.07.2034"
    },
    {
        "engDate": "1977-11-15",
        "nepDate": "30.07.2034"
    },
    {
        "engDate": "1977-11-16",
        "nepDate": "01.08.2034"
    },
    {
        "engDate": "1977-11-17",
        "nepDate": "02.08.2034"
    },
    {
        "engDate": "1977-11-18",
        "nepDate": "03.08.2034"
    },
    {
        "engDate": "1977-11-19",
        "nepDate": "04.08.2034"
    },
    {
        "engDate": "1977-11-20",
        "nepDate": "05.08.2034"
    },
    {
        "engDate": "1977-11-21",
        "nepDate": "06.08.2034"
    },
    {
        "engDate": "1977-11-22",
        "nepDate": "07.08.2034"
    },
    {
        "engDate": "1977-11-23",
        "nepDate": "08.08.2034"
    },
    {
        "engDate": "1977-11-24",
        "nepDate": "09.08.2034"
    },
    {
        "engDate": "1977-11-25",
        "nepDate": "10.08.2034"
    },
    {
        "engDate": "1977-11-26",
        "nepDate": "11.08.2034"
    },
    {
        "engDate": "1977-11-27",
        "nepDate": "12.08.2034"
    },
    {
        "engDate": "1977-11-28",
        "nepDate": "13.08.2034"
    },
    {
        "engDate": "1977-11-29",
        "nepDate": "14.08.2034"
    },
    {
        "engDate": "1977-11-30",
        "nepDate": "15.08.2034"
    },
    {
        "engDate": "1977-12-01",
        "nepDate": "16.08.2034"
    },
    {
        "engDate": "1977-12-02",
        "nepDate": "17.08.2034"
    },
    {
        "engDate": "1977-12-03",
        "nepDate": "18.08.2034"
    },
    {
        "engDate": "1977-12-04",
        "nepDate": "19.08.2034"
    },
    {
        "engDate": "1977-12-05",
        "nepDate": "20.08.2034"
    },
    {
        "engDate": "1977-12-06",
        "nepDate": "21.08.2034"
    },
    {
        "engDate": "1977-12-07",
        "nepDate": "22.08.2034"
    },
    {
        "engDate": "1977-12-08",
        "nepDate": "23.08.2034"
    },
    {
        "engDate": "1977-12-09",
        "nepDate": "24.08.2034"
    },
    {
        "engDate": "1977-12-10",
        "nepDate": "25.08.2034"
    },
    {
        "engDate": "1977-12-11",
        "nepDate": "26.08.2034"
    },
    {
        "engDate": "1977-12-12",
        "nepDate": "27.08.2034"
    },
    {
        "engDate": "1977-12-13",
        "nepDate": "28.08.2034"
    },
    {
        "engDate": "1977-12-14",
        "nepDate": "29.08.2034"
    },
    {
        "engDate": "1977-12-15",
        "nepDate": "30.08.2034"
    },
    {
        "engDate": "1977-12-16",
        "nepDate": "01.09.2034"
    },
    {
        "engDate": "1977-12-17",
        "nepDate": "02.09.2034"
    },
    {
        "engDate": "1977-12-18",
        "nepDate": "03.09.2034"
    },
    {
        "engDate": "1977-12-19",
        "nepDate": "04.09.2034"
    },
    {
        "engDate": "1977-12-20",
        "nepDate": "05.09.2034"
    },
    {
        "engDate": "1977-12-21",
        "nepDate": "06.09.2034"
    },
    {
        "engDate": "1977-12-22",
        "nepDate": "07.09.2034"
    },
    {
        "engDate": "1977-12-23",
        "nepDate": "08.09.2034"
    },
    {
        "engDate": "1977-12-24",
        "nepDate": "09.09.2034"
    },
    {
        "engDate": "1977-12-25",
        "nepDate": "10.09.2034"
    },
    {
        "engDate": "1977-12-26",
        "nepDate": "11.09.2034"
    },
    {
        "engDate": "1977-12-27",
        "nepDate": "12.09.2034"
    },
    {
        "engDate": "1977-12-28",
        "nepDate": "13.09.2034"
    },
    {
        "engDate": "1977-12-29",
        "nepDate": "14.09.2034"
    },
    {
        "engDate": "1977-12-30",
        "nepDate": "15.09.2034"
    },
    {
        "engDate": "1977-12-31",
        "nepDate": "16.09.2034"
    },
    {
        "engDate": "1978-01-01",
        "nepDate": "17.09.2034"
    },
    {
        "engDate": "1978-01-02",
        "nepDate": "18.09.2034"
    },
    {
        "engDate": "1978-01-03",
        "nepDate": "19.09.2034"
    },
    {
        "engDate": "1978-01-04",
        "nepDate": "20.09.2034"
    },
    {
        "engDate": "1978-01-05",
        "nepDate": "21.09.2034"
    },
    {
        "engDate": "1978-01-06",
        "nepDate": "22.09.2034"
    },
    {
        "engDate": "1978-01-07",
        "nepDate": "23.09.2034"
    },
    {
        "engDate": "1978-01-08",
        "nepDate": "24.09.2034"
    },
    {
        "engDate": "1978-01-09",
        "nepDate": "25.09.2034"
    },
    {
        "engDate": "1978-01-10",
        "nepDate": "26.09.2034"
    },
    {
        "engDate": "1978-01-11",
        "nepDate": "27.09.2034"
    },
    {
        "engDate": "1978-01-12",
        "nepDate": "28.09.2034"
    },
    {
        "engDate": "1978-01-13",
        "nepDate": "29.09.2034"
    },
    {
        "engDate": "1978-01-14",
        "nepDate": "01.10.2034"
    },
    {
        "engDate": "1978-01-15",
        "nepDate": "02.10.2034"
    },
    {
        "engDate": "1978-01-16",
        "nepDate": "03.10.2034"
    },
    {
        "engDate": "1978-01-17",
        "nepDate": "04.10.2034"
    },
    {
        "engDate": "1978-01-18",
        "nepDate": "05.10.2034"
    },
    {
        "engDate": "1978-01-19",
        "nepDate": "06.10.2034"
    },
    {
        "engDate": "1978-01-20",
        "nepDate": "07.10.2034"
    },
    {
        "engDate": "1978-01-21",
        "nepDate": "08.10.2034"
    },
    {
        "engDate": "1978-01-22",
        "nepDate": "09.10.2034"
    },
    {
        "engDate": "1978-01-23",
        "nepDate": "10.10.2034"
    },
    {
        "engDate": "1978-01-24",
        "nepDate": "11.10.2034"
    },
    {
        "engDate": "1978-01-25",
        "nepDate": "12.10.2034"
    },
    {
        "engDate": "1978-01-26",
        "nepDate": "13.10.2034"
    },
    {
        "engDate": "1978-01-27",
        "nepDate": "14.10.2034"
    },
    {
        "engDate": "1978-01-28",
        "nepDate": "15.10.2034"
    },
    {
        "engDate": "1978-01-29",
        "nepDate": "16.10.2034"
    },
    {
        "engDate": "1978-01-30",
        "nepDate": "17.10.2034"
    },
    {
        "engDate": "1978-01-31",
        "nepDate": "18.10.2034"
    },
    {
        "engDate": "1978-02-01",
        "nepDate": "19.10.2034"
    },
    {
        "engDate": "1978-02-02",
        "nepDate": "20.10.2034"
    },
    {
        "engDate": "1978-02-03",
        "nepDate": "21.10.2034"
    },
    {
        "engDate": "1978-02-04",
        "nepDate": "22.10.2034"
    },
    {
        "engDate": "1978-02-05",
        "nepDate": "23.10.2034"
    },
    {
        "engDate": "1978-02-06",
        "nepDate": "24.10.2034"
    },
    {
        "engDate": "1978-02-07",
        "nepDate": "25.10.2034"
    },
    {
        "engDate": "1978-02-08",
        "nepDate": "26.10.2034"
    },
    {
        "engDate": "1978-02-09",
        "nepDate": "27.10.2034"
    },
    {
        "engDate": "1978-02-10",
        "nepDate": "28.10.2034"
    },
    {
        "engDate": "1978-02-11",
        "nepDate": "29.10.2034"
    },
    {
        "engDate": "1978-02-12",
        "nepDate": "01.11.2034"
    },
    {
        "engDate": "1978-02-13",
        "nepDate": "02.11.2034"
    },
    {
        "engDate": "1978-02-14",
        "nepDate": "03.11.2034"
    },
    {
        "engDate": "1978-02-15",
        "nepDate": "04.11.2034"
    },
    {
        "engDate": "1978-02-16",
        "nepDate": "05.11.2034"
    },
    {
        "engDate": "1978-02-17",
        "nepDate": "06.11.2034"
    },
    {
        "engDate": "1978-02-18",
        "nepDate": "07.11.2034"
    },
    {
        "engDate": "1978-02-19",
        "nepDate": "08.11.2034"
    },
    {
        "engDate": "1978-02-20",
        "nepDate": "09.11.2034"
    },
    {
        "engDate": "1978-02-21",
        "nepDate": "10.11.2034"
    },
    {
        "engDate": "1978-02-22",
        "nepDate": "11.11.2034"
    },
    {
        "engDate": "1978-02-23",
        "nepDate": "12.11.2034"
    },
    {
        "engDate": "1978-02-24",
        "nepDate": "13.11.2034"
    },
    {
        "engDate": "1978-02-25",
        "nepDate": "14.11.2034"
    },
    {
        "engDate": "1978-02-26",
        "nepDate": "15.11.2034"
    },
    {
        "engDate": "1978-02-27",
        "nepDate": "16.11.2034"
    },
    {
        "engDate": "1978-02-28",
        "nepDate": "17.11.2034"
    },
    {
        "engDate": "1978-03-01",
        "nepDate": "18.11.2034"
    },
    {
        "engDate": "1978-03-02",
        "nepDate": "19.11.2034"
    },
    {
        "engDate": "1978-03-03",
        "nepDate": "20.11.2034"
    },
    {
        "engDate": "1978-03-04",
        "nepDate": "21.11.2034"
    },
    {
        "engDate": "1978-03-05",
        "nepDate": "22.11.2034"
    },
    {
        "engDate": "1978-03-06",
        "nepDate": "23.11.2034"
    },
    {
        "engDate": "1978-03-07",
        "nepDate": "24.11.2034"
    },
    {
        "engDate": "1978-03-08",
        "nepDate": "25.11.2034"
    },
    {
        "engDate": "1978-03-09",
        "nepDate": "26.11.2034"
    },
    {
        "engDate": "1978-03-10",
        "nepDate": "27.11.2034"
    },
    {
        "engDate": "1978-03-11",
        "nepDate": "28.11.2034"
    },
    {
        "engDate": "1978-03-12",
        "nepDate": "29.11.2034"
    },
    {
        "engDate": "1978-03-13",
        "nepDate": "30.11.2034"
    },
    {
        "engDate": "1978-03-14",
        "nepDate": "01.12.2034"
    },
    {
        "engDate": "1978-03-15",
        "nepDate": "02.12.2034"
    },
    {
        "engDate": "1978-03-16",
        "nepDate": "03.12.2034"
    },
    {
        "engDate": "1978-03-17",
        "nepDate": "04.12.2034"
    },
    {
        "engDate": "1978-03-18",
        "nepDate": "05.12.2034"
    },
    {
        "engDate": "1978-03-19",
        "nepDate": "06.12.2034"
    },
    {
        "engDate": "1978-03-20",
        "nepDate": "07.12.2034"
    },
    {
        "engDate": "1978-03-21",
        "nepDate": "08.12.2034"
    },
    {
        "engDate": "1978-03-22",
        "nepDate": "09.12.2034"
    },
    {
        "engDate": "1978-03-23",
        "nepDate": "10.12.2034"
    },
    {
        "engDate": "1978-03-24",
        "nepDate": "11.12.2034"
    },
    {
        "engDate": "1978-03-25",
        "nepDate": "12.12.2034"
    },
    {
        "engDate": "1978-03-26",
        "nepDate": "13.12.2034"
    },
    {
        "engDate": "1978-03-27",
        "nepDate": "14.12.2034"
    },
    {
        "engDate": "1978-03-28",
        "nepDate": "15.12.2034"
    },
    {
        "engDate": "1978-03-29",
        "nepDate": "16.12.2034"
    },
    {
        "engDate": "1978-03-30",
        "nepDate": "17.12.2034"
    },
    {
        "engDate": "1978-03-31",
        "nepDate": "18.12.2034"
    },
    {
        "engDate": "1978-04-01",
        "nepDate": "19.12.2034"
    },
    {
        "engDate": "1978-04-02",
        "nepDate": "20.12.2034"
    },
    {
        "engDate": "1978-04-03",
        "nepDate": "21.12.2034"
    },
    {
        "engDate": "1978-04-04",
        "nepDate": "22.12.2034"
    },
    {
        "engDate": "1978-04-05",
        "nepDate": "23.12.2034"
    },
    {
        "engDate": "1978-04-06",
        "nepDate": "24.12.2034"
    },
    {
        "engDate": "1978-04-07",
        "nepDate": "25.12.2034"
    },
    {
        "engDate": "1978-04-08",
        "nepDate": "26.12.2034"
    },
    {
        "engDate": "1978-04-09",
        "nepDate": "27.12.2034"
    },
    {
        "engDate": "1978-04-10",
        "nepDate": "28.12.2034"
    },
    {
        "engDate": "1978-04-11",
        "nepDate": "29.12.2034"
    },
    {
        "engDate": "1978-04-12",
        "nepDate": "30.12.2034"
    },
    {
        "engDate": "1978-04-13",
        "nepDate": "31.12.2034"
    },
    {
        "engDate": "1978-04-14",
        "nepDate": "01.01.2035"
    },
    {
        "engDate": "1978-04-15",
        "nepDate": "02.01.2035"
    },
    {
        "engDate": "1978-04-16",
        "nepDate": "03.01.2035"
    },
    {
        "engDate": "1978-04-17",
        "nepDate": "04.01.2035"
    },
    {
        "engDate": "1978-04-18",
        "nepDate": "05.01.2035"
    },
    {
        "engDate": "1978-04-19",
        "nepDate": "06.01.2035"
    },
    {
        "engDate": "1978-04-20",
        "nepDate": "07.01.2035"
    },
    {
        "engDate": "1978-04-21",
        "nepDate": "08.01.2035"
    },
    {
        "engDate": "1978-04-22",
        "nepDate": "09.01.2035"
    },
    {
        "engDate": "1978-04-23",
        "nepDate": "10.01.2035"
    },
    {
        "engDate": "1978-04-24",
        "nepDate": "11.01.2035"
    },
    {
        "engDate": "1978-04-25",
        "nepDate": "12.01.2035"
    },
    {
        "engDate": "1978-04-26",
        "nepDate": "13.01.2035"
    },
    {
        "engDate": "1978-04-27",
        "nepDate": "14.01.2035"
    },
    {
        "engDate": "1978-04-28",
        "nepDate": "15.01.2035"
    },
    {
        "engDate": "1978-04-29",
        "nepDate": "16.01.2035"
    },
    {
        "engDate": "1978-04-30",
        "nepDate": "17.01.2035"
    },
    {
        "engDate": "1978-05-01",
        "nepDate": "18.01.2035"
    },
    {
        "engDate": "1978-05-02",
        "nepDate": "19.01.2035"
    },
    {
        "engDate": "1978-05-03",
        "nepDate": "20.01.2035"
    },
    {
        "engDate": "1978-05-04",
        "nepDate": "21.01.2035"
    },
    {
        "engDate": "1978-05-05",
        "nepDate": "22.01.2035"
    },
    {
        "engDate": "1978-05-06",
        "nepDate": "23.01.2035"
    },
    {
        "engDate": "1978-05-07",
        "nepDate": "24.01.2035"
    },
    {
        "engDate": "1978-05-08",
        "nepDate": "25.01.2035"
    },
    {
        "engDate": "1978-05-09",
        "nepDate": "26.01.2035"
    },
    {
        "engDate": "1978-05-10",
        "nepDate": "27.01.2035"
    },
    {
        "engDate": "1978-05-11",
        "nepDate": "28.01.2035"
    },
    {
        "engDate": "1978-05-12",
        "nepDate": "29.01.2035"
    },
    {
        "engDate": "1978-05-13",
        "nepDate": "30.01.2035"
    },
    {
        "engDate": "1978-05-14",
        "nepDate": "01.02.2035"
    },
    {
        "engDate": "1978-05-15",
        "nepDate": "02.02.2035"
    },
    {
        "engDate": "1978-05-16",
        "nepDate": "03.02.2035"
    },
    {
        "engDate": "1978-05-17",
        "nepDate": "04.02.2035"
    },
    {
        "engDate": "1978-05-18",
        "nepDate": "05.02.2035"
    },
    {
        "engDate": "1978-05-19",
        "nepDate": "06.02.2035"
    },
    {
        "engDate": "1978-05-20",
        "nepDate": "07.02.2035"
    },
    {
        "engDate": "1978-05-21",
        "nepDate": "08.02.2035"
    },
    {
        "engDate": "1978-05-22",
        "nepDate": "09.02.2035"
    },
    {
        "engDate": "1978-05-23",
        "nepDate": "10.02.2035"
    },
    {
        "engDate": "1978-05-24",
        "nepDate": "11.02.2035"
    },
    {
        "engDate": "1978-05-25",
        "nepDate": "12.02.2035"
    },
    {
        "engDate": "1978-05-26",
        "nepDate": "13.02.2035"
    },
    {
        "engDate": "1978-05-27",
        "nepDate": "14.02.2035"
    },
    {
        "engDate": "1978-05-28",
        "nepDate": "15.02.2035"
    },
    {
        "engDate": "1978-05-29",
        "nepDate": "16.02.2035"
    },
    {
        "engDate": "1978-05-30",
        "nepDate": "17.02.2035"
    },
    {
        "engDate": "1978-05-31",
        "nepDate": "18.02.2035"
    },
    {
        "engDate": "1978-06-01",
        "nepDate": "19.02.2035"
    },
    {
        "engDate": "1978-06-02",
        "nepDate": "20.02.2035"
    },
    {
        "engDate": "1978-06-03",
        "nepDate": "21.02.2035"
    },
    {
        "engDate": "1978-06-04",
        "nepDate": "22.02.2035"
    },
    {
        "engDate": "1978-06-05",
        "nepDate": "23.02.2035"
    },
    {
        "engDate": "1978-06-06",
        "nepDate": "24.02.2035"
    },
    {
        "engDate": "1978-06-07",
        "nepDate": "25.02.2035"
    },
    {
        "engDate": "1978-06-08",
        "nepDate": "26.02.2035"
    },
    {
        "engDate": "1978-06-09",
        "nepDate": "27.02.2035"
    },
    {
        "engDate": "1978-06-10",
        "nepDate": "28.02.2035"
    },
    {
        "engDate": "1978-06-11",
        "nepDate": "29.02.2035"
    },
    {
        "engDate": "1978-06-12",
        "nepDate": "30.02.2035"
    },
    {
        "engDate": "1978-06-13",
        "nepDate": "31.02.2035"
    },
    {
        "engDate": "1978-06-14",
        "nepDate": "32.02.2035"
    },
    {
        "engDate": "1978-06-15",
        "nepDate": "01.03.2035"
    },
    {
        "engDate": "1978-06-16",
        "nepDate": "02.03.2035"
    },
    {
        "engDate": "1978-06-17",
        "nepDate": "03.03.2035"
    },
    {
        "engDate": "1978-06-18",
        "nepDate": "04.03.2035"
    },
    {
        "engDate": "1978-06-19",
        "nepDate": "05.03.2035"
    },
    {
        "engDate": "1978-06-20",
        "nepDate": "06.03.2035"
    },
    {
        "engDate": "1978-06-21",
        "nepDate": "07.03.2035"
    },
    {
        "engDate": "1978-06-22",
        "nepDate": "08.03.2035"
    },
    {
        "engDate": "1978-06-23",
        "nepDate": "09.03.2035"
    },
    {
        "engDate": "1978-06-24",
        "nepDate": "10.03.2035"
    },
    {
        "engDate": "1978-06-25",
        "nepDate": "11.03.2035"
    },
    {
        "engDate": "1978-06-26",
        "nepDate": "12.03.2035"
    },
    {
        "engDate": "1978-06-27",
        "nepDate": "13.03.2035"
    },
    {
        "engDate": "1978-06-28",
        "nepDate": "14.03.2035"
    },
    {
        "engDate": "1978-06-29",
        "nepDate": "15.03.2035"
    },
    {
        "engDate": "1978-06-30",
        "nepDate": "16.03.2035"
    },
    {
        "engDate": "1978-07-01",
        "nepDate": "17.03.2035"
    },
    {
        "engDate": "1978-07-02",
        "nepDate": "18.03.2035"
    },
    {
        "engDate": "1978-07-03",
        "nepDate": "19.03.2035"
    },
    {
        "engDate": "1978-07-04",
        "nepDate": "20.03.2035"
    },
    {
        "engDate": "1978-07-05",
        "nepDate": "21.03.2035"
    },
    {
        "engDate": "1978-07-06",
        "nepDate": "22.03.2035"
    },
    {
        "engDate": "1978-07-07",
        "nepDate": "23.03.2035"
    },
    {
        "engDate": "1978-07-08",
        "nepDate": "24.03.2035"
    },
    {
        "engDate": "1978-07-09",
        "nepDate": "25.03.2035"
    },
    {
        "engDate": "1978-07-10",
        "nepDate": "26.03.2035"
    },
    {
        "engDate": "1978-07-11",
        "nepDate": "27.03.2035"
    },
    {
        "engDate": "1978-07-12",
        "nepDate": "28.03.2035"
    },
    {
        "engDate": "1978-07-13",
        "nepDate": "29.03.2035"
    },
    {
        "engDate": "1978-07-14",
        "nepDate": "30.03.2035"
    },
    {
        "engDate": "1978-07-15",
        "nepDate": "31.03.2035"
    },
    {
        "engDate": "1978-07-16",
        "nepDate": "01.04.2035"
    },
    {
        "engDate": "1978-07-17",
        "nepDate": "02.04.2035"
    },
    {
        "engDate": "1978-07-18",
        "nepDate": "03.04.2035"
    },
    {
        "engDate": "1978-07-19",
        "nepDate": "04.04.2035"
    },
    {
        "engDate": "1978-07-20",
        "nepDate": "05.04.2035"
    },
    {
        "engDate": "1978-07-21",
        "nepDate": "06.04.2035"
    },
    {
        "engDate": "1978-07-22",
        "nepDate": "07.04.2035"
    },
    {
        "engDate": "1978-07-23",
        "nepDate": "08.04.2035"
    },
    {
        "engDate": "1978-07-24",
        "nepDate": "09.04.2035"
    },
    {
        "engDate": "1978-07-25",
        "nepDate": "10.04.2035"
    },
    {
        "engDate": "1978-07-26",
        "nepDate": "11.04.2035"
    },
    {
        "engDate": "1978-07-27",
        "nepDate": "12.04.2035"
    },
    {
        "engDate": "1978-07-28",
        "nepDate": "13.04.2035"
    },
    {
        "engDate": "1978-07-29",
        "nepDate": "14.04.2035"
    },
    {
        "engDate": "1978-07-30",
        "nepDate": "15.04.2035"
    },
    {
        "engDate": "1978-07-31",
        "nepDate": "16.04.2035"
    },
    {
        "engDate": "1978-08-01",
        "nepDate": "17.04.2035"
    },
    {
        "engDate": "1978-08-02",
        "nepDate": "18.04.2035"
    },
    {
        "engDate": "1978-08-03",
        "nepDate": "19.04.2035"
    },
    {
        "engDate": "1978-08-04",
        "nepDate": "20.04.2035"
    },
    {
        "engDate": "1978-08-05",
        "nepDate": "21.04.2035"
    },
    {
        "engDate": "1978-08-06",
        "nepDate": "22.04.2035"
    },
    {
        "engDate": "1978-08-07",
        "nepDate": "23.04.2035"
    },
    {
        "engDate": "1978-08-08",
        "nepDate": "24.04.2035"
    },
    {
        "engDate": "1978-08-09",
        "nepDate": "25.04.2035"
    },
    {
        "engDate": "1978-08-10",
        "nepDate": "26.04.2035"
    },
    {
        "engDate": "1978-08-11",
        "nepDate": "27.04.2035"
    },
    {
        "engDate": "1978-08-12",
        "nepDate": "28.04.2035"
    },
    {
        "engDate": "1978-08-13",
        "nepDate": "29.04.2035"
    },
    {
        "engDate": "1978-08-14",
        "nepDate": "30.04.2035"
    },
    {
        "engDate": "1978-08-15",
        "nepDate": "31.04.2035"
    },
    {
        "engDate": "1978-08-16",
        "nepDate": "32.04.2035"
    },
    {
        "engDate": "1978-08-17",
        "nepDate": "01.05.2035"
    },
    {
        "engDate": "1978-08-18",
        "nepDate": "02.05.2035"
    },
    {
        "engDate": "1978-08-19",
        "nepDate": "03.05.2035"
    },
    {
        "engDate": "1978-08-20",
        "nepDate": "04.05.2035"
    },
    {
        "engDate": "1978-08-21",
        "nepDate": "05.05.2035"
    },
    {
        "engDate": "1978-08-22",
        "nepDate": "06.05.2035"
    },
    {
        "engDate": "1978-08-23",
        "nepDate": "07.05.2035"
    },
    {
        "engDate": "1978-08-24",
        "nepDate": "08.05.2035"
    },
    {
        "engDate": "1978-08-25",
        "nepDate": "09.05.2035"
    },
    {
        "engDate": "1978-08-26",
        "nepDate": "10.05.2035"
    },
    {
        "engDate": "1978-08-27",
        "nepDate": "11.05.2035"
    },
    {
        "engDate": "1978-08-28",
        "nepDate": "12.05.2035"
    },
    {
        "engDate": "1978-08-29",
        "nepDate": "13.05.2035"
    },
    {
        "engDate": "1978-08-30",
        "nepDate": "14.05.2035"
    },
    {
        "engDate": "1978-08-31",
        "nepDate": "15.05.2035"
    },
    {
        "engDate": "1978-09-01",
        "nepDate": "16.05.2035"
    },
    {
        "engDate": "1978-09-02",
        "nepDate": "17.05.2035"
    },
    {
        "engDate": "1978-09-03",
        "nepDate": "18.05.2035"
    },
    {
        "engDate": "1978-09-04",
        "nepDate": "19.05.2035"
    },
    {
        "engDate": "1978-09-05",
        "nepDate": "20.05.2035"
    },
    {
        "engDate": "1978-09-06",
        "nepDate": "21.05.2035"
    },
    {
        "engDate": "1978-09-07",
        "nepDate": "22.05.2035"
    },
    {
        "engDate": "1978-09-08",
        "nepDate": "23.05.2035"
    },
    {
        "engDate": "1978-09-09",
        "nepDate": "24.05.2035"
    },
    {
        "engDate": "1978-09-10",
        "nepDate": "25.05.2035"
    },
    {
        "engDate": "1978-09-11",
        "nepDate": "26.05.2035"
    },
    {
        "engDate": "1978-09-12",
        "nepDate": "27.05.2035"
    },
    {
        "engDate": "1978-09-13",
        "nepDate": "28.05.2035"
    },
    {
        "engDate": "1978-09-14",
        "nepDate": "29.05.2035"
    },
    {
        "engDate": "1978-09-15",
        "nepDate": "30.05.2035"
    },
    {
        "engDate": "1978-09-16",
        "nepDate": "31.05.2035"
    },
    {
        "engDate": "1978-09-17",
        "nepDate": "01.06.2035"
    },
    {
        "engDate": "1978-09-18",
        "nepDate": "02.06.2035"
    },
    {
        "engDate": "1978-09-19",
        "nepDate": "03.06.2035"
    },
    {
        "engDate": "1978-09-20",
        "nepDate": "04.06.2035"
    },
    {
        "engDate": "1978-09-21",
        "nepDate": "05.06.2035"
    },
    {
        "engDate": "1978-09-22",
        "nepDate": "06.06.2035"
    },
    {
        "engDate": "1978-09-23",
        "nepDate": "07.06.2035"
    },
    {
        "engDate": "1978-09-24",
        "nepDate": "08.06.2035"
    },
    {
        "engDate": "1978-09-25",
        "nepDate": "09.06.2035"
    },
    {
        "engDate": "1978-09-26",
        "nepDate": "10.06.2035"
    },
    {
        "engDate": "1978-09-27",
        "nepDate": "11.06.2035"
    },
    {
        "engDate": "1978-09-28",
        "nepDate": "12.06.2035"
    },
    {
        "engDate": "1978-09-29",
        "nepDate": "13.06.2035"
    },
    {
        "engDate": "1978-09-30",
        "nepDate": "14.06.2035"
    },
    {
        "engDate": "1978-10-01",
        "nepDate": "15.06.2035"
    },
    {
        "engDate": "1978-10-02",
        "nepDate": "16.06.2035"
    },
    {
        "engDate": "1978-10-03",
        "nepDate": "17.06.2035"
    },
    {
        "engDate": "1978-10-04",
        "nepDate": "18.06.2035"
    },
    {
        "engDate": "1978-10-05",
        "nepDate": "19.06.2035"
    },
    {
        "engDate": "1978-10-06",
        "nepDate": "20.06.2035"
    },
    {
        "engDate": "1978-10-07",
        "nepDate": "21.06.2035"
    },
    {
        "engDate": "1978-10-08",
        "nepDate": "22.06.2035"
    },
    {
        "engDate": "1978-10-09",
        "nepDate": "23.06.2035"
    },
    {
        "engDate": "1978-10-10",
        "nepDate": "24.06.2035"
    },
    {
        "engDate": "1978-10-11",
        "nepDate": "25.06.2035"
    },
    {
        "engDate": "1978-10-12",
        "nepDate": "26.06.2035"
    },
    {
        "engDate": "1978-10-13",
        "nepDate": "27.06.2035"
    },
    {
        "engDate": "1978-10-14",
        "nepDate": "28.06.2035"
    },
    {
        "engDate": "1978-10-15",
        "nepDate": "29.06.2035"
    },
    {
        "engDate": "1978-10-16",
        "nepDate": "30.06.2035"
    },
    {
        "engDate": "1978-10-17",
        "nepDate": "31.06.2035"
    },
    {
        "engDate": "1978-10-18",
        "nepDate": "01.07.2035"
    },
    {
        "engDate": "1978-10-19",
        "nepDate": "02.07.2035"
    },
    {
        "engDate": "1978-10-20",
        "nepDate": "03.07.2035"
    },
    {
        "engDate": "1978-10-21",
        "nepDate": "04.07.2035"
    },
    {
        "engDate": "1978-10-22",
        "nepDate": "05.07.2035"
    },
    {
        "engDate": "1978-10-23",
        "nepDate": "06.07.2035"
    },
    {
        "engDate": "1978-10-24",
        "nepDate": "07.07.2035"
    },
    {
        "engDate": "1978-10-25",
        "nepDate": "08.07.2035"
    },
    {
        "engDate": "1978-10-26",
        "nepDate": "09.07.2035"
    },
    {
        "engDate": "1978-10-27",
        "nepDate": "10.07.2035"
    },
    {
        "engDate": "1978-10-28",
        "nepDate": "11.07.2035"
    },
    {
        "engDate": "1978-10-29",
        "nepDate": "12.07.2035"
    },
    {
        "engDate": "1978-10-30",
        "nepDate": "13.07.2035"
    },
    {
        "engDate": "1978-10-31",
        "nepDate": "14.07.2035"
    },
    {
        "engDate": "1978-11-01",
        "nepDate": "15.07.2035"
    },
    {
        "engDate": "1978-11-02",
        "nepDate": "16.07.2035"
    },
    {
        "engDate": "1978-11-03",
        "nepDate": "17.07.2035"
    },
    {
        "engDate": "1978-11-04",
        "nepDate": "18.07.2035"
    },
    {
        "engDate": "1978-11-05",
        "nepDate": "19.07.2035"
    },
    {
        "engDate": "1978-11-06",
        "nepDate": "20.07.2035"
    },
    {
        "engDate": "1978-11-07",
        "nepDate": "21.07.2035"
    },
    {
        "engDate": "1978-11-08",
        "nepDate": "22.07.2035"
    },
    {
        "engDate": "1978-11-09",
        "nepDate": "23.07.2035"
    },
    {
        "engDate": "1978-11-10",
        "nepDate": "24.07.2035"
    },
    {
        "engDate": "1978-11-11",
        "nepDate": "25.07.2035"
    },
    {
        "engDate": "1978-11-12",
        "nepDate": "26.07.2035"
    },
    {
        "engDate": "1978-11-13",
        "nepDate": "27.07.2035"
    },
    {
        "engDate": "1978-11-14",
        "nepDate": "28.07.2035"
    },
    {
        "engDate": "1978-11-15",
        "nepDate": "29.07.2035"
    },
    {
        "engDate": "1978-11-16",
        "nepDate": "01.08.2035"
    },
    {
        "engDate": "1978-11-17",
        "nepDate": "02.08.2035"
    },
    {
        "engDate": "1978-11-18",
        "nepDate": "03.08.2035"
    },
    {
        "engDate": "1978-11-19",
        "nepDate": "04.08.2035"
    },
    {
        "engDate": "1978-11-20",
        "nepDate": "05.08.2035"
    },
    {
        "engDate": "1978-11-21",
        "nepDate": "06.08.2035"
    },
    {
        "engDate": "1978-11-22",
        "nepDate": "07.08.2035"
    },
    {
        "engDate": "1978-11-23",
        "nepDate": "08.08.2035"
    },
    {
        "engDate": "1978-11-24",
        "nepDate": "09.08.2035"
    },
    {
        "engDate": "1978-11-25",
        "nepDate": "10.08.2035"
    },
    {
        "engDate": "1978-11-26",
        "nepDate": "11.08.2035"
    },
    {
        "engDate": "1978-11-27",
        "nepDate": "12.08.2035"
    },
    {
        "engDate": "1978-11-28",
        "nepDate": "13.08.2035"
    },
    {
        "engDate": "1978-11-29",
        "nepDate": "14.08.2035"
    },
    {
        "engDate": "1978-11-30",
        "nepDate": "15.08.2035"
    },
    {
        "engDate": "1978-12-01",
        "nepDate": "16.08.2035"
    },
    {
        "engDate": "1978-12-02",
        "nepDate": "17.08.2035"
    },
    {
        "engDate": "1978-12-03",
        "nepDate": "18.08.2035"
    },
    {
        "engDate": "1978-12-04",
        "nepDate": "19.08.2035"
    },
    {
        "engDate": "1978-12-05",
        "nepDate": "20.08.2035"
    },
    {
        "engDate": "1978-12-06",
        "nepDate": "21.08.2035"
    },
    {
        "engDate": "1978-12-07",
        "nepDate": "22.08.2035"
    },
    {
        "engDate": "1978-12-08",
        "nepDate": "23.08.2035"
    },
    {
        "engDate": "1978-12-09",
        "nepDate": "24.08.2035"
    },
    {
        "engDate": "1978-12-10",
        "nepDate": "25.08.2035"
    },
    {
        "engDate": "1978-12-11",
        "nepDate": "26.08.2035"
    },
    {
        "engDate": "1978-12-12",
        "nepDate": "27.08.2035"
    },
    {
        "engDate": "1978-12-13",
        "nepDate": "28.08.2035"
    },
    {
        "engDate": "1978-12-14",
        "nepDate": "29.08.2035"
    },
    {
        "engDate": "1978-12-15",
        "nepDate": "30.08.2035"
    },
    {
        "engDate": "1978-12-16",
        "nepDate": "01.09.2035"
    },
    {
        "engDate": "1978-12-17",
        "nepDate": "02.09.2035"
    },
    {
        "engDate": "1978-12-18",
        "nepDate": "03.09.2035"
    },
    {
        "engDate": "1978-12-19",
        "nepDate": "04.09.2035"
    },
    {
        "engDate": "1978-12-20",
        "nepDate": "05.09.2035"
    },
    {
        "engDate": "1978-12-21",
        "nepDate": "06.09.2035"
    },
    {
        "engDate": "1978-12-22",
        "nepDate": "07.09.2035"
    },
    {
        "engDate": "1978-12-23",
        "nepDate": "08.09.2035"
    },
    {
        "engDate": "1978-12-24",
        "nepDate": "09.09.2035"
    },
    {
        "engDate": "1978-12-25",
        "nepDate": "10.09.2035"
    },
    {
        "engDate": "1978-12-26",
        "nepDate": "11.09.2035"
    },
    {
        "engDate": "1978-12-27",
        "nepDate": "12.09.2035"
    },
    {
        "engDate": "1978-12-28",
        "nepDate": "13.09.2035"
    },
    {
        "engDate": "1978-12-29",
        "nepDate": "14.09.2035"
    },
    {
        "engDate": "1978-12-30",
        "nepDate": "15.09.2035"
    },
    {
        "engDate": "1978-12-31",
        "nepDate": "16.09.2035"
    },
    {
        "engDate": "1979-01-01",
        "nepDate": "17.09.2035"
    },
    {
        "engDate": "1979-01-02",
        "nepDate": "18.09.2035"
    },
    {
        "engDate": "1979-01-03",
        "nepDate": "19.09.2035"
    },
    {
        "engDate": "1979-01-04",
        "nepDate": "20.09.2035"
    },
    {
        "engDate": "1979-01-05",
        "nepDate": "21.09.2035"
    },
    {
        "engDate": "1979-01-06",
        "nepDate": "22.09.2035"
    },
    {
        "engDate": "1979-01-07",
        "nepDate": "23.09.2035"
    },
    {
        "engDate": "1979-01-08",
        "nepDate": "24.09.2035"
    },
    {
        "engDate": "1979-01-09",
        "nepDate": "25.09.2035"
    },
    {
        "engDate": "1979-01-10",
        "nepDate": "26.09.2035"
    },
    {
        "engDate": "1979-01-11",
        "nepDate": "27.09.2035"
    },
    {
        "engDate": "1979-01-12",
        "nepDate": "28.09.2035"
    },
    {
        "engDate": "1979-01-13",
        "nepDate": "29.09.2035"
    },
    {
        "engDate": "1979-01-14",
        "nepDate": "30.09.2035"
    },
    {
        "engDate": "1979-01-15",
        "nepDate": "01.10.2035"
    },
    {
        "engDate": "1979-01-16",
        "nepDate": "02.10.2035"
    },
    {
        "engDate": "1979-01-17",
        "nepDate": "03.10.2035"
    },
    {
        "engDate": "1979-01-18",
        "nepDate": "04.10.2035"
    },
    {
        "engDate": "1979-01-19",
        "nepDate": "05.10.2035"
    },
    {
        "engDate": "1979-01-20",
        "nepDate": "06.10.2035"
    },
    {
        "engDate": "1979-01-21",
        "nepDate": "07.10.2035"
    },
    {
        "engDate": "1979-01-22",
        "nepDate": "08.10.2035"
    },
    {
        "engDate": "1979-01-23",
        "nepDate": "09.10.2035"
    },
    {
        "engDate": "1979-01-24",
        "nepDate": "10.10.2035"
    },
    {
        "engDate": "1979-01-25",
        "nepDate": "11.10.2035"
    },
    {
        "engDate": "1979-01-26",
        "nepDate": "12.10.2035"
    },
    {
        "engDate": "1979-01-27",
        "nepDate": "13.10.2035"
    },
    {
        "engDate": "1979-01-28",
        "nepDate": "14.10.2035"
    },
    {
        "engDate": "1979-01-29",
        "nepDate": "15.10.2035"
    },
    {
        "engDate": "1979-01-30",
        "nepDate": "16.10.2035"
    },
    {
        "engDate": "1979-01-31",
        "nepDate": "17.10.2035"
    },
    {
        "engDate": "1979-02-01",
        "nepDate": "18.10.2035"
    },
    {
        "engDate": "1979-02-02",
        "nepDate": "19.10.2035"
    },
    {
        "engDate": "1979-02-03",
        "nepDate": "20.10.2035"
    },
    {
        "engDate": "1979-02-04",
        "nepDate": "21.10.2035"
    },
    {
        "engDate": "1979-02-05",
        "nepDate": "22.10.2035"
    },
    {
        "engDate": "1979-02-06",
        "nepDate": "23.10.2035"
    },
    {
        "engDate": "1979-02-07",
        "nepDate": "24.10.2035"
    },
    {
        "engDate": "1979-02-08",
        "nepDate": "25.10.2035"
    },
    {
        "engDate": "1979-02-09",
        "nepDate": "26.10.2035"
    },
    {
        "engDate": "1979-02-10",
        "nepDate": "27.10.2035"
    },
    {
        "engDate": "1979-02-11",
        "nepDate": "28.10.2035"
    },
    {
        "engDate": "1979-02-12",
        "nepDate": "29.10.2035"
    },
    {
        "engDate": "1979-02-13",
        "nepDate": "01.11.2035"
    },
    {
        "engDate": "1979-02-14",
        "nepDate": "02.11.2035"
    },
    {
        "engDate": "1979-02-15",
        "nepDate": "03.11.2035"
    },
    {
        "engDate": "1979-02-16",
        "nepDate": "04.11.2035"
    },
    {
        "engDate": "1979-02-17",
        "nepDate": "05.11.2035"
    },
    {
        "engDate": "1979-02-18",
        "nepDate": "06.11.2035"
    },
    {
        "engDate": "1979-02-19",
        "nepDate": "07.11.2035"
    },
    {
        "engDate": "1979-02-20",
        "nepDate": "08.11.2035"
    },
    {
        "engDate": "1979-02-21",
        "nepDate": "09.11.2035"
    },
    {
        "engDate": "1979-02-22",
        "nepDate": "10.11.2035"
    },
    {
        "engDate": "1979-02-23",
        "nepDate": "11.11.2035"
    },
    {
        "engDate": "1979-02-24",
        "nepDate": "12.11.2035"
    },
    {
        "engDate": "1979-02-25",
        "nepDate": "13.11.2035"
    },
    {
        "engDate": "1979-02-26",
        "nepDate": "14.11.2035"
    },
    {
        "engDate": "1979-02-27",
        "nepDate": "15.11.2035"
    },
    {
        "engDate": "1979-02-28",
        "nepDate": "16.11.2035"
    },
    {
        "engDate": "1979-03-01",
        "nepDate": "17.11.2035"
    },
    {
        "engDate": "1979-03-02",
        "nepDate": "18.11.2035"
    },
    {
        "engDate": "1979-03-03",
        "nepDate": "19.11.2035"
    },
    {
        "engDate": "1979-03-04",
        "nepDate": "20.11.2035"
    },
    {
        "engDate": "1979-03-05",
        "nepDate": "21.11.2035"
    },
    {
        "engDate": "1979-03-06",
        "nepDate": "22.11.2035"
    },
    {
        "engDate": "1979-03-07",
        "nepDate": "23.11.2035"
    },
    {
        "engDate": "1979-03-08",
        "nepDate": "24.11.2035"
    },
    {
        "engDate": "1979-03-09",
        "nepDate": "25.11.2035"
    },
    {
        "engDate": "1979-03-10",
        "nepDate": "26.11.2035"
    },
    {
        "engDate": "1979-03-11",
        "nepDate": "27.11.2035"
    },
    {
        "engDate": "1979-03-12",
        "nepDate": "28.11.2035"
    },
    {
        "engDate": "1979-03-13",
        "nepDate": "29.11.2035"
    },
    {
        "engDate": "1979-03-14",
        "nepDate": "01.12.2035"
    },
    {
        "engDate": "1979-03-15",
        "nepDate": "02.12.2035"
    },
    {
        "engDate": "1979-03-16",
        "nepDate": "03.12.2035"
    },
    {
        "engDate": "1979-03-17",
        "nepDate": "04.12.2035"
    },
    {
        "engDate": "1979-03-18",
        "nepDate": "05.12.2035"
    },
    {
        "engDate": "1979-03-19",
        "nepDate": "06.12.2035"
    },
    {
        "engDate": "1979-03-20",
        "nepDate": "07.12.2035"
    },
    {
        "engDate": "1979-03-21",
        "nepDate": "08.12.2035"
    },
    {
        "engDate": "1979-03-22",
        "nepDate": "09.12.2035"
    },
    {
        "engDate": "1979-03-23",
        "nepDate": "10.12.2035"
    },
    {
        "engDate": "1979-03-24",
        "nepDate": "11.12.2035"
    },
    {
        "engDate": "1979-03-25",
        "nepDate": "12.12.2035"
    },
    {
        "engDate": "1979-03-26",
        "nepDate": "13.12.2035"
    },
    {
        "engDate": "1979-03-27",
        "nepDate": "14.12.2035"
    },
    {
        "engDate": "1979-03-28",
        "nepDate": "15.12.2035"
    },
    {
        "engDate": "1979-03-29",
        "nepDate": "16.12.2035"
    },
    {
        "engDate": "1979-03-30",
        "nepDate": "17.12.2035"
    },
    {
        "engDate": "1979-03-31",
        "nepDate": "18.12.2035"
    },
    {
        "engDate": "1979-04-01",
        "nepDate": "19.12.2035"
    },
    {
        "engDate": "1979-04-02",
        "nepDate": "20.12.2035"
    },
    {
        "engDate": "1979-04-03",
        "nepDate": "21.12.2035"
    },
    {
        "engDate": "1979-04-04",
        "nepDate": "22.12.2035"
    },
    {
        "engDate": "1979-04-05",
        "nepDate": "23.12.2035"
    },
    {
        "engDate": "1979-04-06",
        "nepDate": "24.12.2035"
    },
    {
        "engDate": "1979-04-07",
        "nepDate": "25.12.2035"
    },
    {
        "engDate": "1979-04-08",
        "nepDate": "26.12.2035"
    },
    {
        "engDate": "1979-04-09",
        "nepDate": "27.12.2035"
    },
    {
        "engDate": "1979-04-10",
        "nepDate": "28.12.2035"
    },
    {
        "engDate": "1979-04-11",
        "nepDate": "29.12.2035"
    },
    {
        "engDate": "1979-04-12",
        "nepDate": "30.12.2035"
    },
    {
        "engDate": "1979-04-13",
        "nepDate": "31.12.2035"
    },
    {
        "engDate": "1979-04-14",
        "nepDate": "01.01.2036"
    },
    {
        "engDate": "1979-04-15",
        "nepDate": "02.01.2036"
    },
    {
        "engDate": "1979-04-16",
        "nepDate": "03.01.2036"
    },
    {
        "engDate": "1979-04-17",
        "nepDate": "04.01.2036"
    },
    {
        "engDate": "1979-04-18",
        "nepDate": "05.01.2036"
    },
    {
        "engDate": "1979-04-19",
        "nepDate": "06.01.2036"
    },
    {
        "engDate": "1979-04-20",
        "nepDate": "07.01.2036"
    },
    {
        "engDate": "1979-04-21",
        "nepDate": "08.01.2036"
    },
    {
        "engDate": "1979-04-22",
        "nepDate": "09.01.2036"
    },
    {
        "engDate": "1979-04-23",
        "nepDate": "10.01.2036"
    },
    {
        "engDate": "1979-04-24",
        "nepDate": "11.01.2036"
    },
    {
        "engDate": "1979-04-25",
        "nepDate": "12.01.2036"
    },
    {
        "engDate": "1979-04-26",
        "nepDate": "13.01.2036"
    },
    {
        "engDate": "1979-04-27",
        "nepDate": "14.01.2036"
    },
    {
        "engDate": "1979-04-28",
        "nepDate": "15.01.2036"
    },
    {
        "engDate": "1979-04-29",
        "nepDate": "16.01.2036"
    },
    {
        "engDate": "1979-04-30",
        "nepDate": "17.01.2036"
    },
    {
        "engDate": "1979-05-01",
        "nepDate": "18.01.2036"
    },
    {
        "engDate": "1979-05-02",
        "nepDate": "19.01.2036"
    },
    {
        "engDate": "1979-05-03",
        "nepDate": "20.01.2036"
    },
    {
        "engDate": "1979-05-04",
        "nepDate": "21.01.2036"
    },
    {
        "engDate": "1979-05-05",
        "nepDate": "22.01.2036"
    },
    {
        "engDate": "1979-05-06",
        "nepDate": "23.01.2036"
    },
    {
        "engDate": "1979-05-07",
        "nepDate": "24.01.2036"
    },
    {
        "engDate": "1979-05-08",
        "nepDate": "25.01.2036"
    },
    {
        "engDate": "1979-05-09",
        "nepDate": "26.01.2036"
    },
    {
        "engDate": "1979-05-10",
        "nepDate": "27.01.2036"
    },
    {
        "engDate": "1979-05-11",
        "nepDate": "28.01.2036"
    },
    {
        "engDate": "1979-05-12",
        "nepDate": "29.01.2036"
    },
    {
        "engDate": "1979-05-13",
        "nepDate": "30.01.2036"
    },
    {
        "engDate": "1979-05-14",
        "nepDate": "31.01.2036"
    },
    {
        "engDate": "1979-05-15",
        "nepDate": "01.02.2036"
    },
    {
        "engDate": "1979-05-16",
        "nepDate": "02.02.2036"
    },
    {
        "engDate": "1979-05-17",
        "nepDate": "03.02.2036"
    },
    {
        "engDate": "1979-05-18",
        "nepDate": "04.02.2036"
    },
    {
        "engDate": "1979-05-19",
        "nepDate": "05.02.2036"
    },
    {
        "engDate": "1979-05-20",
        "nepDate": "06.02.2036"
    },
    {
        "engDate": "1979-05-21",
        "nepDate": "07.02.2036"
    },
    {
        "engDate": "1979-05-22",
        "nepDate": "08.02.2036"
    },
    {
        "engDate": "1979-05-23",
        "nepDate": "09.02.2036"
    },
    {
        "engDate": "1979-05-24",
        "nepDate": "10.02.2036"
    },
    {
        "engDate": "1979-05-25",
        "nepDate": "11.02.2036"
    },
    {
        "engDate": "1979-05-26",
        "nepDate": "12.02.2036"
    },
    {
        "engDate": "1979-05-27",
        "nepDate": "13.02.2036"
    },
    {
        "engDate": "1979-05-28",
        "nepDate": "14.02.2036"
    },
    {
        "engDate": "1979-05-29",
        "nepDate": "15.02.2036"
    },
    {
        "engDate": "1979-05-30",
        "nepDate": "16.02.2036"
    },
    {
        "engDate": "1979-05-31",
        "nepDate": "17.02.2036"
    },
    {
        "engDate": "1979-06-01",
        "nepDate": "18.02.2036"
    },
    {
        "engDate": "1979-06-02",
        "nepDate": "19.02.2036"
    },
    {
        "engDate": "1979-06-03",
        "nepDate": "20.02.2036"
    },
    {
        "engDate": "1979-06-04",
        "nepDate": "21.02.2036"
    },
    {
        "engDate": "1979-06-05",
        "nepDate": "22.02.2036"
    },
    {
        "engDate": "1979-06-06",
        "nepDate": "23.02.2036"
    },
    {
        "engDate": "1979-06-07",
        "nepDate": "24.02.2036"
    },
    {
        "engDate": "1979-06-08",
        "nepDate": "25.02.2036"
    },
    {
        "engDate": "1979-06-09",
        "nepDate": "26.02.2036"
    },
    {
        "engDate": "1979-06-10",
        "nepDate": "27.02.2036"
    },
    {
        "engDate": "1979-06-11",
        "nepDate": "28.02.2036"
    },
    {
        "engDate": "1979-06-12",
        "nepDate": "29.02.2036"
    },
    {
        "engDate": "1979-06-13",
        "nepDate": "30.02.2036"
    },
    {
        "engDate": "1979-06-14",
        "nepDate": "31.02.2036"
    },
    {
        "engDate": "1979-06-15",
        "nepDate": "01.03.2036"
    },
    {
        "engDate": "1979-06-16",
        "nepDate": "02.03.2036"
    },
    {
        "engDate": "1979-06-17",
        "nepDate": "03.03.2036"
    },
    {
        "engDate": "1979-06-18",
        "nepDate": "04.03.2036"
    },
    {
        "engDate": "1979-06-19",
        "nepDate": "05.03.2036"
    },
    {
        "engDate": "1979-06-20",
        "nepDate": "06.03.2036"
    },
    {
        "engDate": "1979-06-21",
        "nepDate": "07.03.2036"
    },
    {
        "engDate": "1979-06-22",
        "nepDate": "08.03.2036"
    },
    {
        "engDate": "1979-06-23",
        "nepDate": "09.03.2036"
    },
    {
        "engDate": "1979-06-24",
        "nepDate": "10.03.2036"
    },
    {
        "engDate": "1979-06-25",
        "nepDate": "11.03.2036"
    },
    {
        "engDate": "1979-06-26",
        "nepDate": "12.03.2036"
    },
    {
        "engDate": "1979-06-27",
        "nepDate": "13.03.2036"
    },
    {
        "engDate": "1979-06-28",
        "nepDate": "14.03.2036"
    },
    {
        "engDate": "1979-06-29",
        "nepDate": "15.03.2036"
    },
    {
        "engDate": "1979-06-30",
        "nepDate": "16.03.2036"
    },
    {
        "engDate": "1979-07-01",
        "nepDate": "17.03.2036"
    },
    {
        "engDate": "1979-07-02",
        "nepDate": "18.03.2036"
    },
    {
        "engDate": "1979-07-03",
        "nepDate": "19.03.2036"
    },
    {
        "engDate": "1979-07-04",
        "nepDate": "20.03.2036"
    },
    {
        "engDate": "1979-07-05",
        "nepDate": "21.03.2036"
    },
    {
        "engDate": "1979-07-06",
        "nepDate": "22.03.2036"
    },
    {
        "engDate": "1979-07-07",
        "nepDate": "23.03.2036"
    },
    {
        "engDate": "1979-07-08",
        "nepDate": "24.03.2036"
    },
    {
        "engDate": "1979-07-09",
        "nepDate": "25.03.2036"
    },
    {
        "engDate": "1979-07-10",
        "nepDate": "26.03.2036"
    },
    {
        "engDate": "1979-07-11",
        "nepDate": "27.03.2036"
    },
    {
        "engDate": "1979-07-12",
        "nepDate": "28.03.2036"
    },
    {
        "engDate": "1979-07-13",
        "nepDate": "29.03.2036"
    },
    {
        "engDate": "1979-07-14",
        "nepDate": "30.03.2036"
    },
    {
        "engDate": "1979-07-15",
        "nepDate": "31.03.2036"
    },
    {
        "engDate": "1979-07-16",
        "nepDate": "32.03.2036"
    },
    {
        "engDate": "1979-07-17",
        "nepDate": "01.04.2036"
    },
    {
        "engDate": "1979-07-18",
        "nepDate": "02.04.2036"
    },
    {
        "engDate": "1979-07-19",
        "nepDate": "03.04.2036"
    },
    {
        "engDate": "1979-07-20",
        "nepDate": "04.04.2036"
    },
    {
        "engDate": "1979-07-21",
        "nepDate": "05.04.2036"
    },
    {
        "engDate": "1979-07-22",
        "nepDate": "06.04.2036"
    },
    {
        "engDate": "1979-07-23",
        "nepDate": "07.04.2036"
    },
    {
        "engDate": "1979-07-24",
        "nepDate": "08.04.2036"
    },
    {
        "engDate": "1979-07-25",
        "nepDate": "09.04.2036"
    },
    {
        "engDate": "1979-07-26",
        "nepDate": "10.04.2036"
    },
    {
        "engDate": "1979-07-27",
        "nepDate": "11.04.2036"
    },
    {
        "engDate": "1979-07-28",
        "nepDate": "12.04.2036"
    },
    {
        "engDate": "1979-07-29",
        "nepDate": "13.04.2036"
    },
    {
        "engDate": "1979-07-30",
        "nepDate": "14.04.2036"
    },
    {
        "engDate": "1979-07-31",
        "nepDate": "15.04.2036"
    },
    {
        "engDate": "1979-08-01",
        "nepDate": "16.04.2036"
    },
    {
        "engDate": "1979-08-02",
        "nepDate": "17.04.2036"
    },
    {
        "engDate": "1979-08-03",
        "nepDate": "18.04.2036"
    },
    {
        "engDate": "1979-08-04",
        "nepDate": "19.04.2036"
    },
    {
        "engDate": "1979-08-05",
        "nepDate": "20.04.2036"
    },
    {
        "engDate": "1979-08-06",
        "nepDate": "21.04.2036"
    },
    {
        "engDate": "1979-08-07",
        "nepDate": "22.04.2036"
    },
    {
        "engDate": "1979-08-08",
        "nepDate": "23.04.2036"
    },
    {
        "engDate": "1979-08-09",
        "nepDate": "24.04.2036"
    },
    {
        "engDate": "1979-08-10",
        "nepDate": "25.04.2036"
    },
    {
        "engDate": "1979-08-11",
        "nepDate": "26.04.2036"
    },
    {
        "engDate": "1979-08-12",
        "nepDate": "27.04.2036"
    },
    {
        "engDate": "1979-08-13",
        "nepDate": "28.04.2036"
    },
    {
        "engDate": "1979-08-14",
        "nepDate": "29.04.2036"
    },
    {
        "engDate": "1979-08-15",
        "nepDate": "30.04.2036"
    },
    {
        "engDate": "1979-08-16",
        "nepDate": "31.04.2036"
    },
    {
        "engDate": "1979-08-17",
        "nepDate": "01.05.2036"
    },
    {
        "engDate": "1979-08-18",
        "nepDate": "02.05.2036"
    },
    {
        "engDate": "1979-08-19",
        "nepDate": "03.05.2036"
    },
    {
        "engDate": "1979-08-20",
        "nepDate": "04.05.2036"
    },
    {
        "engDate": "1979-08-21",
        "nepDate": "05.05.2036"
    },
    {
        "engDate": "1979-08-22",
        "nepDate": "06.05.2036"
    },
    {
        "engDate": "1979-08-23",
        "nepDate": "07.05.2036"
    },
    {
        "engDate": "1979-08-24",
        "nepDate": "08.05.2036"
    },
    {
        "engDate": "1979-08-25",
        "nepDate": "09.05.2036"
    },
    {
        "engDate": "1979-08-26",
        "nepDate": "10.05.2036"
    },
    {
        "engDate": "1979-08-27",
        "nepDate": "11.05.2036"
    },
    {
        "engDate": "1979-08-28",
        "nepDate": "12.05.2036"
    },
    {
        "engDate": "1979-08-29",
        "nepDate": "13.05.2036"
    },
    {
        "engDate": "1979-08-30",
        "nepDate": "14.05.2036"
    },
    {
        "engDate": "1979-08-31",
        "nepDate": "15.05.2036"
    },
    {
        "engDate": "1979-09-01",
        "nepDate": "16.05.2036"
    },
    {
        "engDate": "1979-09-02",
        "nepDate": "17.05.2036"
    },
    {
        "engDate": "1979-09-03",
        "nepDate": "18.05.2036"
    },
    {
        "engDate": "1979-09-04",
        "nepDate": "19.05.2036"
    },
    {
        "engDate": "1979-09-05",
        "nepDate": "20.05.2036"
    },
    {
        "engDate": "1979-09-06",
        "nepDate": "21.05.2036"
    },
    {
        "engDate": "1979-09-07",
        "nepDate": "22.05.2036"
    },
    {
        "engDate": "1979-09-08",
        "nepDate": "23.05.2036"
    },
    {
        "engDate": "1979-09-09",
        "nepDate": "24.05.2036"
    },
    {
        "engDate": "1979-09-10",
        "nepDate": "25.05.2036"
    },
    {
        "engDate": "1979-09-11",
        "nepDate": "26.05.2036"
    },
    {
        "engDate": "1979-09-12",
        "nepDate": "27.05.2036"
    },
    {
        "engDate": "1979-09-13",
        "nepDate": "28.05.2036"
    },
    {
        "engDate": "1979-09-14",
        "nepDate": "29.05.2036"
    },
    {
        "engDate": "1979-09-15",
        "nepDate": "30.05.2036"
    },
    {
        "engDate": "1979-09-16",
        "nepDate": "31.05.2036"
    },
    {
        "engDate": "1979-09-17",
        "nepDate": "01.06.2036"
    },
    {
        "engDate": "1979-09-18",
        "nepDate": "02.06.2036"
    },
    {
        "engDate": "1979-09-19",
        "nepDate": "03.06.2036"
    },
    {
        "engDate": "1979-09-20",
        "nepDate": "04.06.2036"
    },
    {
        "engDate": "1979-09-21",
        "nepDate": "05.06.2036"
    },
    {
        "engDate": "1979-09-22",
        "nepDate": "06.06.2036"
    },
    {
        "engDate": "1979-09-23",
        "nepDate": "07.06.2036"
    },
    {
        "engDate": "1979-09-24",
        "nepDate": "08.06.2036"
    },
    {
        "engDate": "1979-09-25",
        "nepDate": "09.06.2036"
    },
    {
        "engDate": "1979-09-26",
        "nepDate": "10.06.2036"
    },
    {
        "engDate": "1979-09-27",
        "nepDate": "11.06.2036"
    },
    {
        "engDate": "1979-09-28",
        "nepDate": "12.06.2036"
    },
    {
        "engDate": "1979-09-29",
        "nepDate": "13.06.2036"
    },
    {
        "engDate": "1979-09-30",
        "nepDate": "14.06.2036"
    },
    {
        "engDate": "1979-10-01",
        "nepDate": "15.06.2036"
    },
    {
        "engDate": "1979-10-02",
        "nepDate": "16.06.2036"
    },
    {
        "engDate": "1979-10-03",
        "nepDate": "17.06.2036"
    },
    {
        "engDate": "1979-10-04",
        "nepDate": "18.06.2036"
    },
    {
        "engDate": "1979-10-05",
        "nepDate": "19.06.2036"
    },
    {
        "engDate": "1979-10-06",
        "nepDate": "20.06.2036"
    },
    {
        "engDate": "1979-10-07",
        "nepDate": "21.06.2036"
    },
    {
        "engDate": "1979-10-08",
        "nepDate": "22.06.2036"
    },
    {
        "engDate": "1979-10-09",
        "nepDate": "23.06.2036"
    },
    {
        "engDate": "1979-10-10",
        "nepDate": "24.06.2036"
    },
    {
        "engDate": "1979-10-11",
        "nepDate": "25.06.2036"
    },
    {
        "engDate": "1979-10-12",
        "nepDate": "26.06.2036"
    },
    {
        "engDate": "1979-10-13",
        "nepDate": "27.06.2036"
    },
    {
        "engDate": "1979-10-14",
        "nepDate": "28.06.2036"
    },
    {
        "engDate": "1979-10-15",
        "nepDate": "29.06.2036"
    },
    {
        "engDate": "1979-10-16",
        "nepDate": "30.06.2036"
    },
    {
        "engDate": "1979-10-17",
        "nepDate": "31.06.2036"
    },
    {
        "engDate": "1979-10-18",
        "nepDate": "01.07.2036"
    },
    {
        "engDate": "1979-10-19",
        "nepDate": "02.07.2036"
    },
    {
        "engDate": "1979-10-20",
        "nepDate": "03.07.2036"
    },
    {
        "engDate": "1979-10-21",
        "nepDate": "04.07.2036"
    },
    {
        "engDate": "1979-10-22",
        "nepDate": "05.07.2036"
    },
    {
        "engDate": "1979-10-23",
        "nepDate": "06.07.2036"
    },
    {
        "engDate": "1979-10-24",
        "nepDate": "07.07.2036"
    },
    {
        "engDate": "1979-10-25",
        "nepDate": "08.07.2036"
    },
    {
        "engDate": "1979-10-26",
        "nepDate": "09.07.2036"
    },
    {
        "engDate": "1979-10-27",
        "nepDate": "10.07.2036"
    },
    {
        "engDate": "1979-10-28",
        "nepDate": "11.07.2036"
    },
    {
        "engDate": "1979-10-29",
        "nepDate": "12.07.2036"
    },
    {
        "engDate": "1979-10-30",
        "nepDate": "13.07.2036"
    },
    {
        "engDate": "1979-10-31",
        "nepDate": "14.07.2036"
    },
    {
        "engDate": "1979-11-01",
        "nepDate": "15.07.2036"
    },
    {
        "engDate": "1979-11-02",
        "nepDate": "16.07.2036"
    },
    {
        "engDate": "1979-11-03",
        "nepDate": "17.07.2036"
    },
    {
        "engDate": "1979-11-04",
        "nepDate": "18.07.2036"
    },
    {
        "engDate": "1979-11-05",
        "nepDate": "19.07.2036"
    },
    {
        "engDate": "1979-11-06",
        "nepDate": "20.07.2036"
    },
    {
        "engDate": "1979-11-07",
        "nepDate": "21.07.2036"
    },
    {
        "engDate": "1979-11-08",
        "nepDate": "22.07.2036"
    },
    {
        "engDate": "1979-11-09",
        "nepDate": "23.07.2036"
    },
    {
        "engDate": "1979-11-10",
        "nepDate": "24.07.2036"
    },
    {
        "engDate": "1979-11-11",
        "nepDate": "25.07.2036"
    },
    {
        "engDate": "1979-11-12",
        "nepDate": "26.07.2036"
    },
    {
        "engDate": "1979-11-13",
        "nepDate": "27.07.2036"
    },
    {
        "engDate": "1979-11-14",
        "nepDate": "28.07.2036"
    },
    {
        "engDate": "1979-11-15",
        "nepDate": "29.07.2036"
    },
    {
        "engDate": "1979-11-16",
        "nepDate": "30.07.2036"
    },
    {
        "engDate": "1979-11-17",
        "nepDate": "01.08.2036"
    },
    {
        "engDate": "1979-11-18",
        "nepDate": "02.08.2036"
    },
    {
        "engDate": "1979-11-19",
        "nepDate": "03.08.2036"
    },
    {
        "engDate": "1979-11-20",
        "nepDate": "04.08.2036"
    },
    {
        "engDate": "1979-11-21",
        "nepDate": "05.08.2036"
    },
    {
        "engDate": "1979-11-22",
        "nepDate": "06.08.2036"
    },
    {
        "engDate": "1979-11-23",
        "nepDate": "07.08.2036"
    },
    {
        "engDate": "1979-11-24",
        "nepDate": "08.08.2036"
    },
    {
        "engDate": "1979-11-25",
        "nepDate": "09.08.2036"
    },
    {
        "engDate": "1979-11-26",
        "nepDate": "10.08.2036"
    },
    {
        "engDate": "1979-11-27",
        "nepDate": "11.08.2036"
    },
    {
        "engDate": "1979-11-28",
        "nepDate": "12.08.2036"
    },
    {
        "engDate": "1979-11-29",
        "nepDate": "13.08.2036"
    },
    {
        "engDate": "1979-11-30",
        "nepDate": "14.08.2036"
    },
    {
        "engDate": "1979-12-01",
        "nepDate": "15.08.2036"
    },
    {
        "engDate": "1979-12-02",
        "nepDate": "16.08.2036"
    },
    {
        "engDate": "1979-12-03",
        "nepDate": "17.08.2036"
    },
    {
        "engDate": "1979-12-04",
        "nepDate": "18.08.2036"
    },
    {
        "engDate": "1979-12-05",
        "nepDate": "19.08.2036"
    },
    {
        "engDate": "1979-12-06",
        "nepDate": "20.08.2036"
    },
    {
        "engDate": "1979-12-07",
        "nepDate": "21.08.2036"
    },
    {
        "engDate": "1979-12-08",
        "nepDate": "22.08.2036"
    },
    {
        "engDate": "1979-12-09",
        "nepDate": "23.08.2036"
    },
    {
        "engDate": "1979-12-10",
        "nepDate": "24.08.2036"
    },
    {
        "engDate": "1979-12-11",
        "nepDate": "25.08.2036"
    },
    {
        "engDate": "1979-12-12",
        "nepDate": "26.08.2036"
    },
    {
        "engDate": "1979-12-13",
        "nepDate": "27.08.2036"
    },
    {
        "engDate": "1979-12-14",
        "nepDate": "28.08.2036"
    },
    {
        "engDate": "1979-12-15",
        "nepDate": "29.08.2036"
    },
    {
        "engDate": "1979-12-16",
        "nepDate": "01.09.2036"
    },
    {
        "engDate": "1979-12-17",
        "nepDate": "02.09.2036"
    },
    {
        "engDate": "1979-12-18",
        "nepDate": "03.09.2036"
    },
    {
        "engDate": "1979-12-19",
        "nepDate": "04.09.2036"
    },
    {
        "engDate": "1979-12-20",
        "nepDate": "05.09.2036"
    },
    {
        "engDate": "1979-12-21",
        "nepDate": "06.09.2036"
    },
    {
        "engDate": "1979-12-22",
        "nepDate": "07.09.2036"
    },
    {
        "engDate": "1979-12-23",
        "nepDate": "08.09.2036"
    },
    {
        "engDate": "1979-12-24",
        "nepDate": "09.09.2036"
    },
    {
        "engDate": "1979-12-25",
        "nepDate": "10.09.2036"
    },
    {
        "engDate": "1979-12-26",
        "nepDate": "11.09.2036"
    },
    {
        "engDate": "1979-12-27",
        "nepDate": "12.09.2036"
    },
    {
        "engDate": "1979-12-28",
        "nepDate": "13.09.2036"
    },
    {
        "engDate": "1979-12-29",
        "nepDate": "14.09.2036"
    },
    {
        "engDate": "1979-12-30",
        "nepDate": "15.09.2036"
    },
    {
        "engDate": "1979-12-31",
        "nepDate": "16.09.2036"
    },
    {
        "engDate": "1980-01-01",
        "nepDate": "17.09.2036"
    },
    {
        "engDate": "1980-01-02",
        "nepDate": "18.09.2036"
    },
    {
        "engDate": "1980-01-03",
        "nepDate": "19.09.2036"
    },
    {
        "engDate": "1980-01-04",
        "nepDate": "20.09.2036"
    },
    {
        "engDate": "1980-01-05",
        "nepDate": "21.09.2036"
    },
    {
        "engDate": "1980-01-06",
        "nepDate": "22.09.2036"
    },
    {
        "engDate": "1980-01-07",
        "nepDate": "23.09.2036"
    },
    {
        "engDate": "1980-01-08",
        "nepDate": "24.09.2036"
    },
    {
        "engDate": "1980-01-09",
        "nepDate": "25.09.2036"
    },
    {
        "engDate": "1980-01-10",
        "nepDate": "26.09.2036"
    },
    {
        "engDate": "1980-01-11",
        "nepDate": "27.09.2036"
    },
    {
        "engDate": "1980-01-12",
        "nepDate": "28.09.2036"
    },
    {
        "engDate": "1980-01-13",
        "nepDate": "29.09.2036"
    },
    {
        "engDate": "1980-01-14",
        "nepDate": "30.09.2036"
    },
    {
        "engDate": "1980-01-15",
        "nepDate": "01.10.2036"
    },
    {
        "engDate": "1980-01-16",
        "nepDate": "02.10.2036"
    },
    {
        "engDate": "1980-01-17",
        "nepDate": "03.10.2036"
    },
    {
        "engDate": "1980-01-18",
        "nepDate": "04.10.2036"
    },
    {
        "engDate": "1980-01-19",
        "nepDate": "05.10.2036"
    },
    {
        "engDate": "1980-01-20",
        "nepDate": "06.10.2036"
    },
    {
        "engDate": "1980-01-21",
        "nepDate": "07.10.2036"
    },
    {
        "engDate": "1980-01-22",
        "nepDate": "08.10.2036"
    },
    {
        "engDate": "1980-01-23",
        "nepDate": "09.10.2036"
    },
    {
        "engDate": "1980-01-24",
        "nepDate": "10.10.2036"
    },
    {
        "engDate": "1980-01-25",
        "nepDate": "11.10.2036"
    },
    {
        "engDate": "1980-01-26",
        "nepDate": "12.10.2036"
    },
    {
        "engDate": "1980-01-27",
        "nepDate": "13.10.2036"
    },
    {
        "engDate": "1980-01-28",
        "nepDate": "14.10.2036"
    },
    {
        "engDate": "1980-01-29",
        "nepDate": "15.10.2036"
    },
    {
        "engDate": "1980-01-30",
        "nepDate": "16.10.2036"
    },
    {
        "engDate": "1980-01-31",
        "nepDate": "17.10.2036"
    },
    {
        "engDate": "1980-02-01",
        "nepDate": "18.10.2036"
    },
    {
        "engDate": "1980-02-02",
        "nepDate": "19.10.2036"
    },
    {
        "engDate": "1980-02-03",
        "nepDate": "20.10.2036"
    },
    {
        "engDate": "1980-02-04",
        "nepDate": "21.10.2036"
    },
    {
        "engDate": "1980-02-05",
        "nepDate": "22.10.2036"
    },
    {
        "engDate": "1980-02-06",
        "nepDate": "23.10.2036"
    },
    {
        "engDate": "1980-02-07",
        "nepDate": "24.10.2036"
    },
    {
        "engDate": "1980-02-08",
        "nepDate": "25.10.2036"
    },
    {
        "engDate": "1980-02-09",
        "nepDate": "26.10.2036"
    },
    {
        "engDate": "1980-02-10",
        "nepDate": "27.10.2036"
    },
    {
        "engDate": "1980-02-11",
        "nepDate": "28.10.2036"
    },
    {
        "engDate": "1980-02-12",
        "nepDate": "29.10.2036"
    },
    {
        "engDate": "1980-02-13",
        "nepDate": "01.11.2036"
    },
    {
        "engDate": "1980-02-14",
        "nepDate": "02.11.2036"
    },
    {
        "engDate": "1980-02-15",
        "nepDate": "03.11.2036"
    },
    {
        "engDate": "1980-02-16",
        "nepDate": "04.11.2036"
    },
    {
        "engDate": "1980-02-17",
        "nepDate": "05.11.2036"
    },
    {
        "engDate": "1980-02-18",
        "nepDate": "06.11.2036"
    },
    {
        "engDate": "1980-02-19",
        "nepDate": "07.11.2036"
    },
    {
        "engDate": "1980-02-20",
        "nepDate": "08.11.2036"
    },
    {
        "engDate": "1980-02-21",
        "nepDate": "09.11.2036"
    },
    {
        "engDate": "1980-02-22",
        "nepDate": "10.11.2036"
    },
    {
        "engDate": "1980-02-23",
        "nepDate": "11.11.2036"
    },
    {
        "engDate": "1980-02-24",
        "nepDate": "12.11.2036"
    },
    {
        "engDate": "1980-02-25",
        "nepDate": "13.11.2036"
    },
    {
        "engDate": "1980-02-26",
        "nepDate": "14.11.2036"
    },
    {
        "engDate": "1980-02-27",
        "nepDate": "15.11.2036"
    },
    {
        "engDate": "1980-02-28",
        "nepDate": "16.11.2036"
    },
    {
        "engDate": "1980-02-29",
        "nepDate": "17.11.2036"
    },
    {
        "engDate": "1980-03-01",
        "nepDate": "18.11.2036"
    },
    {
        "engDate": "1980-03-02",
        "nepDate": "19.11.2036"
    },
    {
        "engDate": "1980-03-03",
        "nepDate": "20.11.2036"
    },
    {
        "engDate": "1980-03-04",
        "nepDate": "21.11.2036"
    },
    {
        "engDate": "1980-03-05",
        "nepDate": "22.11.2036"
    },
    {
        "engDate": "1980-03-06",
        "nepDate": "23.11.2036"
    },
    {
        "engDate": "1980-03-07",
        "nepDate": "24.11.2036"
    },
    {
        "engDate": "1980-03-08",
        "nepDate": "25.11.2036"
    },
    {
        "engDate": "1980-03-09",
        "nepDate": "26.11.2036"
    },
    {
        "engDate": "1980-03-10",
        "nepDate": "27.11.2036"
    },
    {
        "engDate": "1980-03-11",
        "nepDate": "28.11.2036"
    },
    {
        "engDate": "1980-03-12",
        "nepDate": "29.11.2036"
    },
    {
        "engDate": "1980-03-13",
        "nepDate": "30.11.2036"
    },
    {
        "engDate": "1980-03-14",
        "nepDate": "01.12.2036"
    },
    {
        "engDate": "1980-03-15",
        "nepDate": "02.12.2036"
    },
    {
        "engDate": "1980-03-16",
        "nepDate": "03.12.2036"
    },
    {
        "engDate": "1980-03-17",
        "nepDate": "04.12.2036"
    },
    {
        "engDate": "1980-03-18",
        "nepDate": "05.12.2036"
    },
    {
        "engDate": "1980-03-19",
        "nepDate": "06.12.2036"
    },
    {
        "engDate": "1980-03-20",
        "nepDate": "07.12.2036"
    },
    {
        "engDate": "1980-03-21",
        "nepDate": "08.12.2036"
    },
    {
        "engDate": "1980-03-22",
        "nepDate": "09.12.2036"
    },
    {
        "engDate": "1980-03-23",
        "nepDate": "10.12.2036"
    },
    {
        "engDate": "1980-03-24",
        "nepDate": "11.12.2036"
    },
    {
        "engDate": "1980-03-25",
        "nepDate": "12.12.2036"
    },
    {
        "engDate": "1980-03-26",
        "nepDate": "13.12.2036"
    },
    {
        "engDate": "1980-03-27",
        "nepDate": "14.12.2036"
    },
    {
        "engDate": "1980-03-28",
        "nepDate": "15.12.2036"
    },
    {
        "engDate": "1980-03-29",
        "nepDate": "16.12.2036"
    },
    {
        "engDate": "1980-03-30",
        "nepDate": "17.12.2036"
    },
    {
        "engDate": "1980-03-31",
        "nepDate": "18.12.2036"
    },
    {
        "engDate": "1980-04-01",
        "nepDate": "19.12.2036"
    },
    {
        "engDate": "1980-04-02",
        "nepDate": "20.12.2036"
    },
    {
        "engDate": "1980-04-03",
        "nepDate": "21.12.2036"
    },
    {
        "engDate": "1980-04-04",
        "nepDate": "22.12.2036"
    },
    {
        "engDate": "1980-04-05",
        "nepDate": "23.12.2036"
    },
    {
        "engDate": "1980-04-06",
        "nepDate": "24.12.2036"
    },
    {
        "engDate": "1980-04-07",
        "nepDate": "25.12.2036"
    },
    {
        "engDate": "1980-04-08",
        "nepDate": "26.12.2036"
    },
    {
        "engDate": "1980-04-09",
        "nepDate": "27.12.2036"
    },
    {
        "engDate": "1980-04-10",
        "nepDate": "28.12.2036"
    },
    {
        "engDate": "1980-04-11",
        "nepDate": "29.12.2036"
    },
    {
        "engDate": "1980-04-12",
        "nepDate": "30.12.2036"
    },
    {
        "engDate": "1980-04-13",
        "nepDate": "01.01.2037"
    },
    {
        "engDate": "1980-04-14",
        "nepDate": "02.01.2037"
    },
    {
        "engDate": "1980-04-15",
        "nepDate": "03.01.2037"
    },
    {
        "engDate": "1980-04-16",
        "nepDate": "04.01.2037"
    },
    {
        "engDate": "1980-04-17",
        "nepDate": "05.01.2037"
    },
    {
        "engDate": "1980-04-18",
        "nepDate": "06.01.2037"
    },
    {
        "engDate": "1980-04-19",
        "nepDate": "07.01.2037"
    },
    {
        "engDate": "1980-04-20",
        "nepDate": "08.01.2037"
    },
    {
        "engDate": "1980-04-21",
        "nepDate": "09.01.2037"
    },
    {
        "engDate": "1980-04-22",
        "nepDate": "10.01.2037"
    },
    {
        "engDate": "1980-04-23",
        "nepDate": "11.01.2037"
    },
    {
        "engDate": "1980-04-24",
        "nepDate": "12.01.2037"
    },
    {
        "engDate": "1980-04-25",
        "nepDate": "13.01.2037"
    },
    {
        "engDate": "1980-04-26",
        "nepDate": "14.01.2037"
    },
    {
        "engDate": "1980-04-27",
        "nepDate": "15.01.2037"
    },
    {
        "engDate": "1980-04-28",
        "nepDate": "16.01.2037"
    },
    {
        "engDate": "1980-04-29",
        "nepDate": "17.01.2037"
    },
    {
        "engDate": "1980-04-30",
        "nepDate": "18.01.2037"
    },
    {
        "engDate": "1980-05-01",
        "nepDate": "19.01.2037"
    },
    {
        "engDate": "1980-05-02",
        "nepDate": "20.01.2037"
    },
    {
        "engDate": "1980-05-03",
        "nepDate": "21.01.2037"
    },
    {
        "engDate": "1980-05-04",
        "nepDate": "22.01.2037"
    },
    {
        "engDate": "1980-05-05",
        "nepDate": "23.01.2037"
    },
    {
        "engDate": "1980-05-06",
        "nepDate": "24.01.2037"
    },
    {
        "engDate": "1980-05-07",
        "nepDate": "25.01.2037"
    },
    {
        "engDate": "1980-05-08",
        "nepDate": "26.01.2037"
    },
    {
        "engDate": "1980-05-09",
        "nepDate": "27.01.2037"
    },
    {
        "engDate": "1980-05-10",
        "nepDate": "28.01.2037"
    },
    {
        "engDate": "1980-05-11",
        "nepDate": "29.01.2037"
    },
    {
        "engDate": "1980-05-12",
        "nepDate": "30.01.2037"
    },
    {
        "engDate": "1980-05-13",
        "nepDate": "31.01.2037"
    },
    {
        "engDate": "1980-05-14",
        "nepDate": "01.02.2037"
    },
    {
        "engDate": "1980-05-15",
        "nepDate": "02.02.2037"
    },
    {
        "engDate": "1980-05-16",
        "nepDate": "03.02.2037"
    },
    {
        "engDate": "1980-05-17",
        "nepDate": "04.02.2037"
    },
    {
        "engDate": "1980-05-18",
        "nepDate": "05.02.2037"
    },
    {
        "engDate": "1980-05-19",
        "nepDate": "06.02.2037"
    },
    {
        "engDate": "1980-05-20",
        "nepDate": "07.02.2037"
    },
    {
        "engDate": "1980-05-21",
        "nepDate": "08.02.2037"
    },
    {
        "engDate": "1980-05-22",
        "nepDate": "09.02.2037"
    },
    {
        "engDate": "1980-05-23",
        "nepDate": "10.02.2037"
    },
    {
        "engDate": "1980-05-24",
        "nepDate": "11.02.2037"
    },
    {
        "engDate": "1980-05-25",
        "nepDate": "12.02.2037"
    },
    {
        "engDate": "1980-05-26",
        "nepDate": "13.02.2037"
    },
    {
        "engDate": "1980-05-27",
        "nepDate": "14.02.2037"
    },
    {
        "engDate": "1980-05-28",
        "nepDate": "15.02.2037"
    },
    {
        "engDate": "1980-05-29",
        "nepDate": "16.02.2037"
    },
    {
        "engDate": "1980-05-30",
        "nepDate": "17.02.2037"
    },
    {
        "engDate": "1980-05-31",
        "nepDate": "18.02.2037"
    },
    {
        "engDate": "1980-06-01",
        "nepDate": "19.02.2037"
    },
    {
        "engDate": "1980-06-02",
        "nepDate": "20.02.2037"
    },
    {
        "engDate": "1980-06-03",
        "nepDate": "21.02.2037"
    },
    {
        "engDate": "1980-06-04",
        "nepDate": "22.02.2037"
    },
    {
        "engDate": "1980-06-05",
        "nepDate": "23.02.2037"
    },
    {
        "engDate": "1980-06-06",
        "nepDate": "24.02.2037"
    },
    {
        "engDate": "1980-06-07",
        "nepDate": "25.02.2037"
    },
    {
        "engDate": "1980-06-08",
        "nepDate": "26.02.2037"
    },
    {
        "engDate": "1980-06-09",
        "nepDate": "27.02.2037"
    },
    {
        "engDate": "1980-06-10",
        "nepDate": "28.02.2037"
    },
    {
        "engDate": "1980-06-11",
        "nepDate": "29.02.2037"
    },
    {
        "engDate": "1980-06-12",
        "nepDate": "30.02.2037"
    },
    {
        "engDate": "1980-06-13",
        "nepDate": "31.02.2037"
    },
    {
        "engDate": "1980-06-14",
        "nepDate": "01.03.2037"
    },
    {
        "engDate": "1980-06-15",
        "nepDate": "02.03.2037"
    },
    {
        "engDate": "1980-06-16",
        "nepDate": "03.03.2037"
    },
    {
        "engDate": "1980-06-17",
        "nepDate": "04.03.2037"
    },
    {
        "engDate": "1980-06-18",
        "nepDate": "05.03.2037"
    },
    {
        "engDate": "1980-06-19",
        "nepDate": "06.03.2037"
    },
    {
        "engDate": "1980-06-20",
        "nepDate": "07.03.2037"
    },
    {
        "engDate": "1980-06-21",
        "nepDate": "08.03.2037"
    },
    {
        "engDate": "1980-06-22",
        "nepDate": "09.03.2037"
    },
    {
        "engDate": "1980-06-23",
        "nepDate": "10.03.2037"
    },
    {
        "engDate": "1980-06-24",
        "nepDate": "11.03.2037"
    },
    {
        "engDate": "1980-06-25",
        "nepDate": "12.03.2037"
    },
    {
        "engDate": "1980-06-26",
        "nepDate": "13.03.2037"
    },
    {
        "engDate": "1980-06-27",
        "nepDate": "14.03.2037"
    },
    {
        "engDate": "1980-06-28",
        "nepDate": "15.03.2037"
    },
    {
        "engDate": "1980-06-29",
        "nepDate": "16.03.2037"
    },
    {
        "engDate": "1980-06-30",
        "nepDate": "17.03.2037"
    },
    {
        "engDate": "1980-07-01",
        "nepDate": "18.03.2037"
    },
    {
        "engDate": "1980-07-02",
        "nepDate": "19.03.2037"
    },
    {
        "engDate": "1980-07-03",
        "nepDate": "20.03.2037"
    },
    {
        "engDate": "1980-07-04",
        "nepDate": "21.03.2037"
    },
    {
        "engDate": "1980-07-05",
        "nepDate": "22.03.2037"
    },
    {
        "engDate": "1980-07-06",
        "nepDate": "23.03.2037"
    },
    {
        "engDate": "1980-07-07",
        "nepDate": "24.03.2037"
    },
    {
        "engDate": "1980-07-08",
        "nepDate": "25.03.2037"
    },
    {
        "engDate": "1980-07-09",
        "nepDate": "26.03.2037"
    },
    {
        "engDate": "1980-07-10",
        "nepDate": "27.03.2037"
    },
    {
        "engDate": "1980-07-11",
        "nepDate": "28.03.2037"
    },
    {
        "engDate": "1980-07-12",
        "nepDate": "29.03.2037"
    },
    {
        "engDate": "1980-07-13",
        "nepDate": "30.03.2037"
    },
    {
        "engDate": "1980-07-14",
        "nepDate": "31.03.2037"
    },
    {
        "engDate": "1980-07-15",
        "nepDate": "32.03.2037"
    },
    {
        "engDate": "1980-07-16",
        "nepDate": "01.04.2037"
    },
    {
        "engDate": "1980-07-17",
        "nepDate": "02.04.2037"
    },
    {
        "engDate": "1980-07-18",
        "nepDate": "03.04.2037"
    },
    {
        "engDate": "1980-07-19",
        "nepDate": "04.04.2037"
    },
    {
        "engDate": "1980-07-20",
        "nepDate": "05.04.2037"
    },
    {
        "engDate": "1980-07-21",
        "nepDate": "06.04.2037"
    },
    {
        "engDate": "1980-07-22",
        "nepDate": "07.04.2037"
    },
    {
        "engDate": "1980-07-23",
        "nepDate": "08.04.2037"
    },
    {
        "engDate": "1980-07-24",
        "nepDate": "09.04.2037"
    },
    {
        "engDate": "1980-07-25",
        "nepDate": "10.04.2037"
    },
    {
        "engDate": "1980-07-26",
        "nepDate": "11.04.2037"
    },
    {
        "engDate": "1980-07-27",
        "nepDate": "12.04.2037"
    },
    {
        "engDate": "1980-07-28",
        "nepDate": "13.04.2037"
    },
    {
        "engDate": "1980-07-29",
        "nepDate": "14.04.2037"
    },
    {
        "engDate": "1980-07-30",
        "nepDate": "15.04.2037"
    },
    {
        "engDate": "1980-07-31",
        "nepDate": "16.04.2037"
    },
    {
        "engDate": "1980-08-01",
        "nepDate": "17.04.2037"
    },
    {
        "engDate": "1980-08-02",
        "nepDate": "18.04.2037"
    },
    {
        "engDate": "1980-08-03",
        "nepDate": "19.04.2037"
    },
    {
        "engDate": "1980-08-04",
        "nepDate": "20.04.2037"
    },
    {
        "engDate": "1980-08-05",
        "nepDate": "21.04.2037"
    },
    {
        "engDate": "1980-08-06",
        "nepDate": "22.04.2037"
    },
    {
        "engDate": "1980-08-07",
        "nepDate": "23.04.2037"
    },
    {
        "engDate": "1980-08-08",
        "nepDate": "24.04.2037"
    },
    {
        "engDate": "1980-08-09",
        "nepDate": "25.04.2037"
    },
    {
        "engDate": "1980-08-10",
        "nepDate": "26.04.2037"
    },
    {
        "engDate": "1980-08-11",
        "nepDate": "27.04.2037"
    },
    {
        "engDate": "1980-08-12",
        "nepDate": "28.04.2037"
    },
    {
        "engDate": "1980-08-13",
        "nepDate": "29.04.2037"
    },
    {
        "engDate": "1980-08-14",
        "nepDate": "30.04.2037"
    },
    {
        "engDate": "1980-08-15",
        "nepDate": "31.04.2037"
    },
    {
        "engDate": "1980-08-16",
        "nepDate": "32.04.2037"
    },
    {
        "engDate": "1980-08-17",
        "nepDate": "01.05.2037"
    },
    {
        "engDate": "1980-08-18",
        "nepDate": "02.05.2037"
    },
    {
        "engDate": "1980-08-19",
        "nepDate": "03.05.2037"
    },
    {
        "engDate": "1980-08-20",
        "nepDate": "04.05.2037"
    },
    {
        "engDate": "1980-08-21",
        "nepDate": "05.05.2037"
    },
    {
        "engDate": "1980-08-22",
        "nepDate": "06.05.2037"
    },
    {
        "engDate": "1980-08-23",
        "nepDate": "07.05.2037"
    },
    {
        "engDate": "1980-08-24",
        "nepDate": "08.05.2037"
    },
    {
        "engDate": "1980-08-25",
        "nepDate": "09.05.2037"
    },
    {
        "engDate": "1980-08-26",
        "nepDate": "10.05.2037"
    },
    {
        "engDate": "1980-08-27",
        "nepDate": "11.05.2037"
    },
    {
        "engDate": "1980-08-28",
        "nepDate": "12.05.2037"
    },
    {
        "engDate": "1980-08-29",
        "nepDate": "13.05.2037"
    },
    {
        "engDate": "1980-08-30",
        "nepDate": "14.05.2037"
    },
    {
        "engDate": "1980-08-31",
        "nepDate": "15.05.2037"
    },
    {
        "engDate": "1980-09-01",
        "nepDate": "16.05.2037"
    },
    {
        "engDate": "1980-09-02",
        "nepDate": "17.05.2037"
    },
    {
        "engDate": "1980-09-03",
        "nepDate": "18.05.2037"
    },
    {
        "engDate": "1980-09-04",
        "nepDate": "19.05.2037"
    },
    {
        "engDate": "1980-09-05",
        "nepDate": "20.05.2037"
    },
    {
        "engDate": "1980-09-06",
        "nepDate": "21.05.2037"
    },
    {
        "engDate": "1980-09-07",
        "nepDate": "22.05.2037"
    },
    {
        "engDate": "1980-09-08",
        "nepDate": "23.05.2037"
    },
    {
        "engDate": "1980-09-09",
        "nepDate": "24.05.2037"
    },
    {
        "engDate": "1980-09-10",
        "nepDate": "25.05.2037"
    },
    {
        "engDate": "1980-09-11",
        "nepDate": "26.05.2037"
    },
    {
        "engDate": "1980-09-12",
        "nepDate": "27.05.2037"
    },
    {
        "engDate": "1980-09-13",
        "nepDate": "28.05.2037"
    },
    {
        "engDate": "1980-09-14",
        "nepDate": "29.05.2037"
    },
    {
        "engDate": "1980-09-15",
        "nepDate": "30.05.2037"
    },
    {
        "engDate": "1980-09-16",
        "nepDate": "31.05.2037"
    },
    {
        "engDate": "1980-09-17",
        "nepDate": "01.06.2037"
    },
    {
        "engDate": "1980-09-18",
        "nepDate": "02.06.2037"
    },
    {
        "engDate": "1980-09-19",
        "nepDate": "03.06.2037"
    },
    {
        "engDate": "1980-09-20",
        "nepDate": "04.06.2037"
    },
    {
        "engDate": "1980-09-21",
        "nepDate": "05.06.2037"
    },
    {
        "engDate": "1980-09-22",
        "nepDate": "06.06.2037"
    },
    {
        "engDate": "1980-09-23",
        "nepDate": "07.06.2037"
    },
    {
        "engDate": "1980-09-24",
        "nepDate": "08.06.2037"
    },
    {
        "engDate": "1980-09-25",
        "nepDate": "09.06.2037"
    },
    {
        "engDate": "1980-09-26",
        "nepDate": "10.06.2037"
    },
    {
        "engDate": "1980-09-27",
        "nepDate": "11.06.2037"
    },
    {
        "engDate": "1980-09-28",
        "nepDate": "12.06.2037"
    },
    {
        "engDate": "1980-09-29",
        "nepDate": "13.06.2037"
    },
    {
        "engDate": "1980-09-30",
        "nepDate": "14.06.2037"
    },
    {
        "engDate": "1980-10-01",
        "nepDate": "15.06.2037"
    },
    {
        "engDate": "1980-10-02",
        "nepDate": "16.06.2037"
    },
    {
        "engDate": "1980-10-03",
        "nepDate": "17.06.2037"
    },
    {
        "engDate": "1980-10-04",
        "nepDate": "18.06.2037"
    },
    {
        "engDate": "1980-10-05",
        "nepDate": "19.06.2037"
    },
    {
        "engDate": "1980-10-06",
        "nepDate": "20.06.2037"
    },
    {
        "engDate": "1980-10-07",
        "nepDate": "21.06.2037"
    },
    {
        "engDate": "1980-10-08",
        "nepDate": "22.06.2037"
    },
    {
        "engDate": "1980-10-09",
        "nepDate": "23.06.2037"
    },
    {
        "engDate": "1980-10-10",
        "nepDate": "24.06.2037"
    },
    {
        "engDate": "1980-10-11",
        "nepDate": "25.06.2037"
    },
    {
        "engDate": "1980-10-12",
        "nepDate": "26.06.2037"
    },
    {
        "engDate": "1980-10-13",
        "nepDate": "27.06.2037"
    },
    {
        "engDate": "1980-10-14",
        "nepDate": "28.06.2037"
    },
    {
        "engDate": "1980-10-15",
        "nepDate": "29.06.2037"
    },
    {
        "engDate": "1980-10-16",
        "nepDate": "30.06.2037"
    },
    {
        "engDate": "1980-10-17",
        "nepDate": "01.07.2037"
    },
    {
        "engDate": "1980-10-18",
        "nepDate": "02.07.2037"
    },
    {
        "engDate": "1980-10-19",
        "nepDate": "03.07.2037"
    },
    {
        "engDate": "1980-10-20",
        "nepDate": "04.07.2037"
    },
    {
        "engDate": "1980-10-21",
        "nepDate": "05.07.2037"
    },
    {
        "engDate": "1980-10-22",
        "nepDate": "06.07.2037"
    },
    {
        "engDate": "1980-10-23",
        "nepDate": "07.07.2037"
    },
    {
        "engDate": "1980-10-24",
        "nepDate": "08.07.2037"
    },
    {
        "engDate": "1980-10-25",
        "nepDate": "09.07.2037"
    },
    {
        "engDate": "1980-10-26",
        "nepDate": "10.07.2037"
    },
    {
        "engDate": "1980-10-27",
        "nepDate": "11.07.2037"
    },
    {
        "engDate": "1980-10-28",
        "nepDate": "12.07.2037"
    },
    {
        "engDate": "1980-10-29",
        "nepDate": "13.07.2037"
    },
    {
        "engDate": "1980-10-30",
        "nepDate": "14.07.2037"
    },
    {
        "engDate": "1980-10-31",
        "nepDate": "15.07.2037"
    },
    {
        "engDate": "1980-11-01",
        "nepDate": "16.07.2037"
    },
    {
        "engDate": "1980-11-02",
        "nepDate": "17.07.2037"
    },
    {
        "engDate": "1980-11-03",
        "nepDate": "18.07.2037"
    },
    {
        "engDate": "1980-11-04",
        "nepDate": "19.07.2037"
    },
    {
        "engDate": "1980-11-05",
        "nepDate": "20.07.2037"
    },
    {
        "engDate": "1980-11-06",
        "nepDate": "21.07.2037"
    },
    {
        "engDate": "1980-11-07",
        "nepDate": "22.07.2037"
    },
    {
        "engDate": "1980-11-08",
        "nepDate": "23.07.2037"
    },
    {
        "engDate": "1980-11-09",
        "nepDate": "24.07.2037"
    },
    {
        "engDate": "1980-11-10",
        "nepDate": "25.07.2037"
    },
    {
        "engDate": "1980-11-11",
        "nepDate": "26.07.2037"
    },
    {
        "engDate": "1980-11-12",
        "nepDate": "27.07.2037"
    },
    {
        "engDate": "1980-11-13",
        "nepDate": "28.07.2037"
    },
    {
        "engDate": "1980-11-14",
        "nepDate": "29.07.2037"
    },
    {
        "engDate": "1980-11-15",
        "nepDate": "30.07.2037"
    },
    {
        "engDate": "1980-11-16",
        "nepDate": "01.08.2037"
    },
    {
        "engDate": "1980-11-17",
        "nepDate": "02.08.2037"
    },
    {
        "engDate": "1980-11-18",
        "nepDate": "03.08.2037"
    },
    {
        "engDate": "1980-11-19",
        "nepDate": "04.08.2037"
    },
    {
        "engDate": "1980-11-20",
        "nepDate": "05.08.2037"
    },
    {
        "engDate": "1980-11-21",
        "nepDate": "06.08.2037"
    },
    {
        "engDate": "1980-11-22",
        "nepDate": "07.08.2037"
    },
    {
        "engDate": "1980-11-23",
        "nepDate": "08.08.2037"
    },
    {
        "engDate": "1980-11-24",
        "nepDate": "09.08.2037"
    },
    {
        "engDate": "1980-11-25",
        "nepDate": "10.08.2037"
    },
    {
        "engDate": "1980-11-26",
        "nepDate": "11.08.2037"
    },
    {
        "engDate": "1980-11-27",
        "nepDate": "12.08.2037"
    },
    {
        "engDate": "1980-11-28",
        "nepDate": "13.08.2037"
    },
    {
        "engDate": "1980-11-29",
        "nepDate": "14.08.2037"
    },
    {
        "engDate": "1980-11-30",
        "nepDate": "15.08.2037"
    },
    {
        "engDate": "1980-12-01",
        "nepDate": "16.08.2037"
    },
    {
        "engDate": "1980-12-02",
        "nepDate": "17.08.2037"
    },
    {
        "engDate": "1980-12-03",
        "nepDate": "18.08.2037"
    },
    {
        "engDate": "1980-12-04",
        "nepDate": "19.08.2037"
    },
    {
        "engDate": "1980-12-05",
        "nepDate": "20.08.2037"
    },
    {
        "engDate": "1980-12-06",
        "nepDate": "21.08.2037"
    },
    {
        "engDate": "1980-12-07",
        "nepDate": "22.08.2037"
    },
    {
        "engDate": "1980-12-08",
        "nepDate": "23.08.2037"
    },
    {
        "engDate": "1980-12-09",
        "nepDate": "24.08.2037"
    },
    {
        "engDate": "1980-12-10",
        "nepDate": "25.08.2037"
    },
    {
        "engDate": "1980-12-11",
        "nepDate": "26.08.2037"
    },
    {
        "engDate": "1980-12-12",
        "nepDate": "27.08.2037"
    },
    {
        "engDate": "1980-12-13",
        "nepDate": "28.08.2037"
    },
    {
        "engDate": "1980-12-14",
        "nepDate": "29.08.2037"
    },
    {
        "engDate": "1980-12-15",
        "nepDate": "01.09.2037"
    },
    {
        "engDate": "1980-12-16",
        "nepDate": "02.09.2037"
    },
    {
        "engDate": "1980-12-17",
        "nepDate": "03.09.2037"
    },
    {
        "engDate": "1980-12-18",
        "nepDate": "04.09.2037"
    },
    {
        "engDate": "1980-12-19",
        "nepDate": "05.09.2037"
    },
    {
        "engDate": "1980-12-20",
        "nepDate": "06.09.2037"
    },
    {
        "engDate": "1980-12-21",
        "nepDate": "07.09.2037"
    },
    {
        "engDate": "1980-12-22",
        "nepDate": "08.09.2037"
    },
    {
        "engDate": "1980-12-23",
        "nepDate": "09.09.2037"
    },
    {
        "engDate": "1980-12-24",
        "nepDate": "10.09.2037"
    },
    {
        "engDate": "1980-12-25",
        "nepDate": "11.09.2037"
    },
    {
        "engDate": "1980-12-26",
        "nepDate": "12.09.2037"
    },
    {
        "engDate": "1980-12-27",
        "nepDate": "13.09.2037"
    },
    {
        "engDate": "1980-12-28",
        "nepDate": "14.09.2037"
    },
    {
        "engDate": "1980-12-29",
        "nepDate": "15.09.2037"
    },
    {
        "engDate": "1980-12-30",
        "nepDate": "16.09.2037"
    },
    {
        "engDate": "1980-12-31",
        "nepDate": "17.09.2037"
    },
    {
        "engDate": "1981-01-01",
        "nepDate": "18.09.2037"
    },
    {
        "engDate": "1981-01-02",
        "nepDate": "19.09.2037"
    },
    {
        "engDate": "1981-01-03",
        "nepDate": "20.09.2037"
    },
    {
        "engDate": "1981-01-04",
        "nepDate": "21.09.2037"
    },
    {
        "engDate": "1981-01-05",
        "nepDate": "22.09.2037"
    },
    {
        "engDate": "1981-01-06",
        "nepDate": "23.09.2037"
    },
    {
        "engDate": "1981-01-07",
        "nepDate": "24.09.2037"
    },
    {
        "engDate": "1981-01-08",
        "nepDate": "25.09.2037"
    },
    {
        "engDate": "1981-01-09",
        "nepDate": "26.09.2037"
    },
    {
        "engDate": "1981-01-10",
        "nepDate": "27.09.2037"
    },
    {
        "engDate": "1981-01-11",
        "nepDate": "28.09.2037"
    },
    {
        "engDate": "1981-01-12",
        "nepDate": "29.09.2037"
    },
    {
        "engDate": "1981-01-13",
        "nepDate": "30.09.2037"
    },
    {
        "engDate": "1981-01-14",
        "nepDate": "01.10.2037"
    },
    {
        "engDate": "1981-01-15",
        "nepDate": "02.10.2037"
    },
    {
        "engDate": "1981-01-16",
        "nepDate": "03.10.2037"
    },
    {
        "engDate": "1981-01-17",
        "nepDate": "04.10.2037"
    },
    {
        "engDate": "1981-01-18",
        "nepDate": "05.10.2037"
    },
    {
        "engDate": "1981-01-19",
        "nepDate": "06.10.2037"
    },
    {
        "engDate": "1981-01-20",
        "nepDate": "07.10.2037"
    },
    {
        "engDate": "1981-01-21",
        "nepDate": "08.10.2037"
    },
    {
        "engDate": "1981-01-22",
        "nepDate": "09.10.2037"
    },
    {
        "engDate": "1981-01-23",
        "nepDate": "10.10.2037"
    },
    {
        "engDate": "1981-01-24",
        "nepDate": "11.10.2037"
    },
    {
        "engDate": "1981-01-25",
        "nepDate": "12.10.2037"
    },
    {
        "engDate": "1981-01-26",
        "nepDate": "13.10.2037"
    },
    {
        "engDate": "1981-01-27",
        "nepDate": "14.10.2037"
    },
    {
        "engDate": "1981-01-28",
        "nepDate": "15.10.2037"
    },
    {
        "engDate": "1981-01-29",
        "nepDate": "16.10.2037"
    },
    {
        "engDate": "1981-01-30",
        "nepDate": "17.10.2037"
    },
    {
        "engDate": "1981-01-31",
        "nepDate": "18.10.2037"
    },
    {
        "engDate": "1981-02-01",
        "nepDate": "19.10.2037"
    },
    {
        "engDate": "1981-02-02",
        "nepDate": "20.10.2037"
    },
    {
        "engDate": "1981-02-03",
        "nepDate": "21.10.2037"
    },
    {
        "engDate": "1981-02-04",
        "nepDate": "22.10.2037"
    },
    {
        "engDate": "1981-02-05",
        "nepDate": "23.10.2037"
    },
    {
        "engDate": "1981-02-06",
        "nepDate": "24.10.2037"
    },
    {
        "engDate": "1981-02-07",
        "nepDate": "25.10.2037"
    },
    {
        "engDate": "1981-02-08",
        "nepDate": "26.10.2037"
    },
    {
        "engDate": "1981-02-09",
        "nepDate": "27.10.2037"
    },
    {
        "engDate": "1981-02-10",
        "nepDate": "28.10.2037"
    },
    {
        "engDate": "1981-02-11",
        "nepDate": "29.10.2037"
    },
    {
        "engDate": "1981-02-12",
        "nepDate": "01.11.2037"
    },
    {
        "engDate": "1981-02-13",
        "nepDate": "02.11.2037"
    },
    {
        "engDate": "1981-02-14",
        "nepDate": "03.11.2037"
    },
    {
        "engDate": "1981-02-15",
        "nepDate": "04.11.2037"
    },
    {
        "engDate": "1981-02-16",
        "nepDate": "05.11.2037"
    },
    {
        "engDate": "1981-02-17",
        "nepDate": "06.11.2037"
    },
    {
        "engDate": "1981-02-18",
        "nepDate": "07.11.2037"
    },
    {
        "engDate": "1981-02-19",
        "nepDate": "08.11.2037"
    },
    {
        "engDate": "1981-02-20",
        "nepDate": "09.11.2037"
    },
    {
        "engDate": "1981-02-21",
        "nepDate": "10.11.2037"
    },
    {
        "engDate": "1981-02-22",
        "nepDate": "11.11.2037"
    },
    {
        "engDate": "1981-02-23",
        "nepDate": "12.11.2037"
    },
    {
        "engDate": "1981-02-24",
        "nepDate": "13.11.2037"
    },
    {
        "engDate": "1981-02-25",
        "nepDate": "14.11.2037"
    },
    {
        "engDate": "1981-02-26",
        "nepDate": "15.11.2037"
    },
    {
        "engDate": "1981-02-27",
        "nepDate": "16.11.2037"
    },
    {
        "engDate": "1981-02-28",
        "nepDate": "17.11.2037"
    },
    {
        "engDate": "1981-03-01",
        "nepDate": "18.11.2037"
    },
    {
        "engDate": "1981-03-02",
        "nepDate": "19.11.2037"
    },
    {
        "engDate": "1981-03-03",
        "nepDate": "20.11.2037"
    },
    {
        "engDate": "1981-03-04",
        "nepDate": "21.11.2037"
    },
    {
        "engDate": "1981-03-05",
        "nepDate": "22.11.2037"
    },
    {
        "engDate": "1981-03-06",
        "nepDate": "23.11.2037"
    },
    {
        "engDate": "1981-03-07",
        "nepDate": "24.11.2037"
    },
    {
        "engDate": "1981-03-08",
        "nepDate": "25.11.2037"
    },
    {
        "engDate": "1981-03-09",
        "nepDate": "26.11.2037"
    },
    {
        "engDate": "1981-03-10",
        "nepDate": "27.11.2037"
    },
    {
        "engDate": "1981-03-11",
        "nepDate": "28.11.2037"
    },
    {
        "engDate": "1981-03-12",
        "nepDate": "29.11.2037"
    },
    {
        "engDate": "1981-03-13",
        "nepDate": "30.11.2037"
    },
    {
        "engDate": "1981-03-14",
        "nepDate": "01.12.2037"
    },
    {
        "engDate": "1981-03-15",
        "nepDate": "02.12.2037"
    },
    {
        "engDate": "1981-03-16",
        "nepDate": "03.12.2037"
    },
    {
        "engDate": "1981-03-17",
        "nepDate": "04.12.2037"
    },
    {
        "engDate": "1981-03-18",
        "nepDate": "05.12.2037"
    },
    {
        "engDate": "1981-03-19",
        "nepDate": "06.12.2037"
    },
    {
        "engDate": "1981-03-20",
        "nepDate": "07.12.2037"
    },
    {
        "engDate": "1981-03-21",
        "nepDate": "08.12.2037"
    },
    {
        "engDate": "1981-03-22",
        "nepDate": "09.12.2037"
    },
    {
        "engDate": "1981-03-23",
        "nepDate": "10.12.2037"
    },
    {
        "engDate": "1981-03-24",
        "nepDate": "11.12.2037"
    },
    {
        "engDate": "1981-03-25",
        "nepDate": "12.12.2037"
    },
    {
        "engDate": "1981-03-26",
        "nepDate": "13.12.2037"
    },
    {
        "engDate": "1981-03-27",
        "nepDate": "14.12.2037"
    },
    {
        "engDate": "1981-03-28",
        "nepDate": "15.12.2037"
    },
    {
        "engDate": "1981-03-29",
        "nepDate": "16.12.2037"
    },
    {
        "engDate": "1981-03-30",
        "nepDate": "17.12.2037"
    },
    {
        "engDate": "1981-03-31",
        "nepDate": "18.12.2037"
    },
    {
        "engDate": "1981-04-01",
        "nepDate": "19.12.2037"
    },
    {
        "engDate": "1981-04-02",
        "nepDate": "20.12.2037"
    },
    {
        "engDate": "1981-04-03",
        "nepDate": "21.12.2037"
    },
    {
        "engDate": "1981-04-04",
        "nepDate": "22.12.2037"
    },
    {
        "engDate": "1981-04-05",
        "nepDate": "23.12.2037"
    },
    {
        "engDate": "1981-04-06",
        "nepDate": "24.12.2037"
    },
    {
        "engDate": "1981-04-07",
        "nepDate": "25.12.2037"
    },
    {
        "engDate": "1981-04-08",
        "nepDate": "26.12.2037"
    },
    {
        "engDate": "1981-04-09",
        "nepDate": "27.12.2037"
    },
    {
        "engDate": "1981-04-10",
        "nepDate": "28.12.2037"
    },
    {
        "engDate": "1981-04-11",
        "nepDate": "29.12.2037"
    },
    {
        "engDate": "1981-04-12",
        "nepDate": "30.12.2037"
    },
    {
        "engDate": "1981-04-13",
        "nepDate": "01.01.2038"
    },
    {
        "engDate": "1981-04-14",
        "nepDate": "02.01.2038"
    },
    {
        "engDate": "1981-04-15",
        "nepDate": "03.01.2038"
    },
    {
        "engDate": "1981-04-16",
        "nepDate": "04.01.2038"
    },
    {
        "engDate": "1981-04-17",
        "nepDate": "05.01.2038"
    },
    {
        "engDate": "1981-04-18",
        "nepDate": "06.01.2038"
    },
    {
        "engDate": "1981-04-19",
        "nepDate": "07.01.2038"
    },
    {
        "engDate": "1981-04-20",
        "nepDate": "08.01.2038"
    },
    {
        "engDate": "1981-04-21",
        "nepDate": "09.01.2038"
    },
    {
        "engDate": "1981-04-22",
        "nepDate": "10.01.2038"
    },
    {
        "engDate": "1981-04-23",
        "nepDate": "11.01.2038"
    },
    {
        "engDate": "1981-04-24",
        "nepDate": "12.01.2038"
    },
    {
        "engDate": "1981-04-25",
        "nepDate": "13.01.2038"
    },
    {
        "engDate": "1981-04-26",
        "nepDate": "14.01.2038"
    },
    {
        "engDate": "1981-04-27",
        "nepDate": "15.01.2038"
    },
    {
        "engDate": "1981-04-28",
        "nepDate": "16.01.2038"
    },
    {
        "engDate": "1981-04-29",
        "nepDate": "17.01.2038"
    },
    {
        "engDate": "1981-04-30",
        "nepDate": "18.01.2038"
    },
    {
        "engDate": "1981-05-01",
        "nepDate": "19.01.2038"
    },
    {
        "engDate": "1981-05-02",
        "nepDate": "20.01.2038"
    },
    {
        "engDate": "1981-05-03",
        "nepDate": "21.01.2038"
    },
    {
        "engDate": "1981-05-04",
        "nepDate": "22.01.2038"
    },
    {
        "engDate": "1981-05-05",
        "nepDate": "23.01.2038"
    },
    {
        "engDate": "1981-05-06",
        "nepDate": "24.01.2038"
    },
    {
        "engDate": "1981-05-07",
        "nepDate": "25.01.2038"
    },
    {
        "engDate": "1981-05-08",
        "nepDate": "26.01.2038"
    },
    {
        "engDate": "1981-05-09",
        "nepDate": "27.01.2038"
    },
    {
        "engDate": "1981-05-10",
        "nepDate": "28.01.2038"
    },
    {
        "engDate": "1981-05-11",
        "nepDate": "29.01.2038"
    },
    {
        "engDate": "1981-05-12",
        "nepDate": "30.01.2038"
    },
    {
        "engDate": "1981-05-13",
        "nepDate": "31.01.2038"
    },
    {
        "engDate": "1981-05-14",
        "nepDate": "01.02.2038"
    },
    {
        "engDate": "1981-05-15",
        "nepDate": "02.02.2038"
    },
    {
        "engDate": "1981-05-16",
        "nepDate": "03.02.2038"
    },
    {
        "engDate": "1981-05-17",
        "nepDate": "04.02.2038"
    },
    {
        "engDate": "1981-05-18",
        "nepDate": "05.02.2038"
    },
    {
        "engDate": "1981-05-19",
        "nepDate": "06.02.2038"
    },
    {
        "engDate": "1981-05-20",
        "nepDate": "07.02.2038"
    },
    {
        "engDate": "1981-05-21",
        "nepDate": "08.02.2038"
    },
    {
        "engDate": "1981-05-22",
        "nepDate": "09.02.2038"
    },
    {
        "engDate": "1981-05-23",
        "nepDate": "10.02.2038"
    },
    {
        "engDate": "1981-05-24",
        "nepDate": "11.02.2038"
    },
    {
        "engDate": "1981-05-25",
        "nepDate": "12.02.2038"
    },
    {
        "engDate": "1981-05-26",
        "nepDate": "13.02.2038"
    },
    {
        "engDate": "1981-05-27",
        "nepDate": "14.02.2038"
    },
    {
        "engDate": "1981-05-28",
        "nepDate": "15.02.2038"
    },
    {
        "engDate": "1981-05-29",
        "nepDate": "16.02.2038"
    },
    {
        "engDate": "1981-05-30",
        "nepDate": "17.02.2038"
    },
    {
        "engDate": "1981-05-31",
        "nepDate": "18.02.2038"
    },
    {
        "engDate": "1981-06-01",
        "nepDate": "19.02.2038"
    },
    {
        "engDate": "1981-06-02",
        "nepDate": "20.02.2038"
    },
    {
        "engDate": "1981-06-03",
        "nepDate": "21.02.2038"
    },
    {
        "engDate": "1981-06-04",
        "nepDate": "22.02.2038"
    },
    {
        "engDate": "1981-06-05",
        "nepDate": "23.02.2038"
    },
    {
        "engDate": "1981-06-06",
        "nepDate": "24.02.2038"
    },
    {
        "engDate": "1981-06-07",
        "nepDate": "25.02.2038"
    },
    {
        "engDate": "1981-06-08",
        "nepDate": "26.02.2038"
    },
    {
        "engDate": "1981-06-09",
        "nepDate": "27.02.2038"
    },
    {
        "engDate": "1981-06-10",
        "nepDate": "28.02.2038"
    },
    {
        "engDate": "1981-06-11",
        "nepDate": "29.02.2038"
    },
    {
        "engDate": "1981-06-12",
        "nepDate": "30.02.2038"
    },
    {
        "engDate": "1981-06-13",
        "nepDate": "31.02.2038"
    },
    {
        "engDate": "1981-06-14",
        "nepDate": "32.02.2038"
    },
    {
        "engDate": "1981-06-15",
        "nepDate": "01.03.2038"
    },
    {
        "engDate": "1981-06-16",
        "nepDate": "02.03.2038"
    },
    {
        "engDate": "1981-06-17",
        "nepDate": "03.03.2038"
    },
    {
        "engDate": "1981-06-18",
        "nepDate": "04.03.2038"
    },
    {
        "engDate": "1981-06-19",
        "nepDate": "05.03.2038"
    },
    {
        "engDate": "1981-06-20",
        "nepDate": "06.03.2038"
    },
    {
        "engDate": "1981-06-21",
        "nepDate": "07.03.2038"
    },
    {
        "engDate": "1981-06-22",
        "nepDate": "08.03.2038"
    },
    {
        "engDate": "1981-06-23",
        "nepDate": "09.03.2038"
    },
    {
        "engDate": "1981-06-24",
        "nepDate": "10.03.2038"
    },
    {
        "engDate": "1981-06-25",
        "nepDate": "11.03.2038"
    },
    {
        "engDate": "1981-06-26",
        "nepDate": "12.03.2038"
    },
    {
        "engDate": "1981-06-27",
        "nepDate": "13.03.2038"
    },
    {
        "engDate": "1981-06-28",
        "nepDate": "14.03.2038"
    },
    {
        "engDate": "1981-06-29",
        "nepDate": "15.03.2038"
    },
    {
        "engDate": "1981-06-30",
        "nepDate": "16.03.2038"
    },
    {
        "engDate": "1981-07-01",
        "nepDate": "17.03.2038"
    },
    {
        "engDate": "1981-07-02",
        "nepDate": "18.03.2038"
    },
    {
        "engDate": "1981-07-03",
        "nepDate": "19.03.2038"
    },
    {
        "engDate": "1981-07-04",
        "nepDate": "20.03.2038"
    },
    {
        "engDate": "1981-07-05",
        "nepDate": "21.03.2038"
    },
    {
        "engDate": "1981-07-06",
        "nepDate": "22.03.2038"
    },
    {
        "engDate": "1981-07-07",
        "nepDate": "23.03.2038"
    },
    {
        "engDate": "1981-07-08",
        "nepDate": "24.03.2038"
    },
    {
        "engDate": "1981-07-09",
        "nepDate": "25.03.2038"
    },
    {
        "engDate": "1981-07-10",
        "nepDate": "26.03.2038"
    },
    {
        "engDate": "1981-07-11",
        "nepDate": "27.03.2038"
    },
    {
        "engDate": "1981-07-12",
        "nepDate": "28.03.2038"
    },
    {
        "engDate": "1981-07-13",
        "nepDate": "29.03.2038"
    },
    {
        "engDate": "1981-07-14",
        "nepDate": "30.03.2038"
    },
    {
        "engDate": "1981-07-15",
        "nepDate": "31.03.2038"
    },
    {
        "engDate": "1981-07-16",
        "nepDate": "01.04.2038"
    },
    {
        "engDate": "1981-07-17",
        "nepDate": "02.04.2038"
    },
    {
        "engDate": "1981-07-18",
        "nepDate": "03.04.2038"
    },
    {
        "engDate": "1981-07-19",
        "nepDate": "04.04.2038"
    },
    {
        "engDate": "1981-07-20",
        "nepDate": "05.04.2038"
    },
    {
        "engDate": "1981-07-21",
        "nepDate": "06.04.2038"
    },
    {
        "engDate": "1981-07-22",
        "nepDate": "07.04.2038"
    },
    {
        "engDate": "1981-07-23",
        "nepDate": "08.04.2038"
    },
    {
        "engDate": "1981-07-24",
        "nepDate": "09.04.2038"
    },
    {
        "engDate": "1981-07-25",
        "nepDate": "10.04.2038"
    },
    {
        "engDate": "1981-07-26",
        "nepDate": "11.04.2038"
    },
    {
        "engDate": "1981-07-27",
        "nepDate": "12.04.2038"
    },
    {
        "engDate": "1981-07-28",
        "nepDate": "13.04.2038"
    },
    {
        "engDate": "1981-07-29",
        "nepDate": "14.04.2038"
    },
    {
        "engDate": "1981-07-30",
        "nepDate": "15.04.2038"
    },
    {
        "engDate": "1981-07-31",
        "nepDate": "16.04.2038"
    },
    {
        "engDate": "1981-08-01",
        "nepDate": "17.04.2038"
    },
    {
        "engDate": "1981-08-02",
        "nepDate": "18.04.2038"
    },
    {
        "engDate": "1981-08-03",
        "nepDate": "19.04.2038"
    },
    {
        "engDate": "1981-08-04",
        "nepDate": "20.04.2038"
    },
    {
        "engDate": "1981-08-05",
        "nepDate": "21.04.2038"
    },
    {
        "engDate": "1981-08-06",
        "nepDate": "22.04.2038"
    },
    {
        "engDate": "1981-08-07",
        "nepDate": "23.04.2038"
    },
    {
        "engDate": "1981-08-08",
        "nepDate": "24.04.2038"
    },
    {
        "engDate": "1981-08-09",
        "nepDate": "25.04.2038"
    },
    {
        "engDate": "1981-08-10",
        "nepDate": "26.04.2038"
    },
    {
        "engDate": "1981-08-11",
        "nepDate": "27.04.2038"
    },
    {
        "engDate": "1981-08-12",
        "nepDate": "28.04.2038"
    },
    {
        "engDate": "1981-08-13",
        "nepDate": "29.04.2038"
    },
    {
        "engDate": "1981-08-14",
        "nepDate": "30.04.2038"
    },
    {
        "engDate": "1981-08-15",
        "nepDate": "31.04.2038"
    },
    {
        "engDate": "1981-08-16",
        "nepDate": "32.04.2038"
    },
    {
        "engDate": "1981-08-17",
        "nepDate": "01.05.2038"
    },
    {
        "engDate": "1981-08-18",
        "nepDate": "02.05.2038"
    },
    {
        "engDate": "1981-08-19",
        "nepDate": "03.05.2038"
    },
    {
        "engDate": "1981-08-20",
        "nepDate": "04.05.2038"
    },
    {
        "engDate": "1981-08-21",
        "nepDate": "05.05.2038"
    },
    {
        "engDate": "1981-08-22",
        "nepDate": "06.05.2038"
    },
    {
        "engDate": "1981-08-23",
        "nepDate": "07.05.2038"
    },
    {
        "engDate": "1981-08-24",
        "nepDate": "08.05.2038"
    },
    {
        "engDate": "1981-08-25",
        "nepDate": "09.05.2038"
    },
    {
        "engDate": "1981-08-26",
        "nepDate": "10.05.2038"
    },
    {
        "engDate": "1981-08-27",
        "nepDate": "11.05.2038"
    },
    {
        "engDate": "1981-08-28",
        "nepDate": "12.05.2038"
    },
    {
        "engDate": "1981-08-29",
        "nepDate": "13.05.2038"
    },
    {
        "engDate": "1981-08-30",
        "nepDate": "14.05.2038"
    },
    {
        "engDate": "1981-08-31",
        "nepDate": "15.05.2038"
    },
    {
        "engDate": "1981-09-01",
        "nepDate": "16.05.2038"
    },
    {
        "engDate": "1981-09-02",
        "nepDate": "17.05.2038"
    },
    {
        "engDate": "1981-09-03",
        "nepDate": "18.05.2038"
    },
    {
        "engDate": "1981-09-04",
        "nepDate": "19.05.2038"
    },
    {
        "engDate": "1981-09-05",
        "nepDate": "20.05.2038"
    },
    {
        "engDate": "1981-09-06",
        "nepDate": "21.05.2038"
    },
    {
        "engDate": "1981-09-07",
        "nepDate": "22.05.2038"
    },
    {
        "engDate": "1981-09-08",
        "nepDate": "23.05.2038"
    },
    {
        "engDate": "1981-09-09",
        "nepDate": "24.05.2038"
    },
    {
        "engDate": "1981-09-10",
        "nepDate": "25.05.2038"
    },
    {
        "engDate": "1981-09-11",
        "nepDate": "26.05.2038"
    },
    {
        "engDate": "1981-09-12",
        "nepDate": "27.05.2038"
    },
    {
        "engDate": "1981-09-13",
        "nepDate": "28.05.2038"
    },
    {
        "engDate": "1981-09-14",
        "nepDate": "29.05.2038"
    },
    {
        "engDate": "1981-09-15",
        "nepDate": "30.05.2038"
    },
    {
        "engDate": "1981-09-16",
        "nepDate": "31.05.2038"
    },
    {
        "engDate": "1981-09-17",
        "nepDate": "01.06.2038"
    },
    {
        "engDate": "1981-09-18",
        "nepDate": "02.06.2038"
    },
    {
        "engDate": "1981-09-19",
        "nepDate": "03.06.2038"
    },
    {
        "engDate": "1981-09-20",
        "nepDate": "04.06.2038"
    },
    {
        "engDate": "1981-09-21",
        "nepDate": "05.06.2038"
    },
    {
        "engDate": "1981-09-22",
        "nepDate": "06.06.2038"
    },
    {
        "engDate": "1981-09-23",
        "nepDate": "07.06.2038"
    },
    {
        "engDate": "1981-09-24",
        "nepDate": "08.06.2038"
    },
    {
        "engDate": "1981-09-25",
        "nepDate": "09.06.2038"
    },
    {
        "engDate": "1981-09-26",
        "nepDate": "10.06.2038"
    },
    {
        "engDate": "1981-09-27",
        "nepDate": "11.06.2038"
    },
    {
        "engDate": "1981-09-28",
        "nepDate": "12.06.2038"
    },
    {
        "engDate": "1981-09-29",
        "nepDate": "13.06.2038"
    },
    {
        "engDate": "1981-09-30",
        "nepDate": "14.06.2038"
    },
    {
        "engDate": "1981-10-01",
        "nepDate": "15.06.2038"
    },
    {
        "engDate": "1981-10-02",
        "nepDate": "16.06.2038"
    },
    {
        "engDate": "1981-10-03",
        "nepDate": "17.06.2038"
    },
    {
        "engDate": "1981-10-04",
        "nepDate": "18.06.2038"
    },
    {
        "engDate": "1981-10-05",
        "nepDate": "19.06.2038"
    },
    {
        "engDate": "1981-10-06",
        "nepDate": "20.06.2038"
    },
    {
        "engDate": "1981-10-07",
        "nepDate": "21.06.2038"
    },
    {
        "engDate": "1981-10-08",
        "nepDate": "22.06.2038"
    },
    {
        "engDate": "1981-10-09",
        "nepDate": "23.06.2038"
    },
    {
        "engDate": "1981-10-10",
        "nepDate": "24.06.2038"
    },
    {
        "engDate": "1981-10-11",
        "nepDate": "25.06.2038"
    },
    {
        "engDate": "1981-10-12",
        "nepDate": "26.06.2038"
    },
    {
        "engDate": "1981-10-13",
        "nepDate": "27.06.2038"
    },
    {
        "engDate": "1981-10-14",
        "nepDate": "28.06.2038"
    },
    {
        "engDate": "1981-10-15",
        "nepDate": "29.06.2038"
    },
    {
        "engDate": "1981-10-16",
        "nepDate": "30.06.2038"
    },
    {
        "engDate": "1981-10-17",
        "nepDate": "01.07.2038"
    },
    {
        "engDate": "1981-10-18",
        "nepDate": "02.07.2038"
    },
    {
        "engDate": "1981-10-19",
        "nepDate": "03.07.2038"
    },
    {
        "engDate": "1981-10-20",
        "nepDate": "04.07.2038"
    },
    {
        "engDate": "1981-10-21",
        "nepDate": "05.07.2038"
    },
    {
        "engDate": "1981-10-22",
        "nepDate": "06.07.2038"
    },
    {
        "engDate": "1981-10-23",
        "nepDate": "07.07.2038"
    },
    {
        "engDate": "1981-10-24",
        "nepDate": "08.07.2038"
    },
    {
        "engDate": "1981-10-25",
        "nepDate": "09.07.2038"
    },
    {
        "engDate": "1981-10-26",
        "nepDate": "10.07.2038"
    },
    {
        "engDate": "1981-10-27",
        "nepDate": "11.07.2038"
    },
    {
        "engDate": "1981-10-28",
        "nepDate": "12.07.2038"
    },
    {
        "engDate": "1981-10-29",
        "nepDate": "13.07.2038"
    },
    {
        "engDate": "1981-10-30",
        "nepDate": "14.07.2038"
    },
    {
        "engDate": "1981-10-31",
        "nepDate": "15.07.2038"
    },
    {
        "engDate": "1981-11-01",
        "nepDate": "16.07.2038"
    },
    {
        "engDate": "1981-11-02",
        "nepDate": "17.07.2038"
    },
    {
        "engDate": "1981-11-03",
        "nepDate": "18.07.2038"
    },
    {
        "engDate": "1981-11-04",
        "nepDate": "19.07.2038"
    },
    {
        "engDate": "1981-11-05",
        "nepDate": "20.07.2038"
    },
    {
        "engDate": "1981-11-06",
        "nepDate": "21.07.2038"
    },
    {
        "engDate": "1981-11-07",
        "nepDate": "22.07.2038"
    },
    {
        "engDate": "1981-11-08",
        "nepDate": "23.07.2038"
    },
    {
        "engDate": "1981-11-09",
        "nepDate": "24.07.2038"
    },
    {
        "engDate": "1981-11-10",
        "nepDate": "25.07.2038"
    },
    {
        "engDate": "1981-11-11",
        "nepDate": "26.07.2038"
    },
    {
        "engDate": "1981-11-12",
        "nepDate": "27.07.2038"
    },
    {
        "engDate": "1981-11-13",
        "nepDate": "28.07.2038"
    },
    {
        "engDate": "1981-11-14",
        "nepDate": "29.07.2038"
    },
    {
        "engDate": "1981-11-15",
        "nepDate": "30.07.2038"
    },
    {
        "engDate": "1981-11-16",
        "nepDate": "01.08.2038"
    },
    {
        "engDate": "1981-11-17",
        "nepDate": "02.08.2038"
    },
    {
        "engDate": "1981-11-18",
        "nepDate": "03.08.2038"
    },
    {
        "engDate": "1981-11-19",
        "nepDate": "04.08.2038"
    },
    {
        "engDate": "1981-11-20",
        "nepDate": "05.08.2038"
    },
    {
        "engDate": "1981-11-21",
        "nepDate": "06.08.2038"
    },
    {
        "engDate": "1981-11-22",
        "nepDate": "07.08.2038"
    },
    {
        "engDate": "1981-11-23",
        "nepDate": "08.08.2038"
    },
    {
        "engDate": "1981-11-24",
        "nepDate": "09.08.2038"
    },
    {
        "engDate": "1981-11-25",
        "nepDate": "10.08.2038"
    },
    {
        "engDate": "1981-11-26",
        "nepDate": "11.08.2038"
    },
    {
        "engDate": "1981-11-27",
        "nepDate": "12.08.2038"
    },
    {
        "engDate": "1981-11-28",
        "nepDate": "13.08.2038"
    },
    {
        "engDate": "1981-11-29",
        "nepDate": "14.08.2038"
    },
    {
        "engDate": "1981-11-30",
        "nepDate": "15.08.2038"
    },
    {
        "engDate": "1981-12-01",
        "nepDate": "16.08.2038"
    },
    {
        "engDate": "1981-12-02",
        "nepDate": "17.08.2038"
    },
    {
        "engDate": "1981-12-03",
        "nepDate": "18.08.2038"
    },
    {
        "engDate": "1981-12-04",
        "nepDate": "19.08.2038"
    },
    {
        "engDate": "1981-12-05",
        "nepDate": "20.08.2038"
    },
    {
        "engDate": "1981-12-06",
        "nepDate": "21.08.2038"
    },
    {
        "engDate": "1981-12-07",
        "nepDate": "22.08.2038"
    },
    {
        "engDate": "1981-12-08",
        "nepDate": "23.08.2038"
    },
    {
        "engDate": "1981-12-09",
        "nepDate": "24.08.2038"
    },
    {
        "engDate": "1981-12-10",
        "nepDate": "25.08.2038"
    },
    {
        "engDate": "1981-12-11",
        "nepDate": "26.08.2038"
    },
    {
        "engDate": "1981-12-12",
        "nepDate": "27.08.2038"
    },
    {
        "engDate": "1981-12-13",
        "nepDate": "28.08.2038"
    },
    {
        "engDate": "1981-12-14",
        "nepDate": "29.08.2038"
    },
    {
        "engDate": "1981-12-15",
        "nepDate": "30.08.2038"
    },
    {
        "engDate": "1981-12-16",
        "nepDate": "01.09.2038"
    },
    {
        "engDate": "1981-12-17",
        "nepDate": "02.09.2038"
    },
    {
        "engDate": "1981-12-18",
        "nepDate": "03.09.2038"
    },
    {
        "engDate": "1981-12-19",
        "nepDate": "04.09.2038"
    },
    {
        "engDate": "1981-12-20",
        "nepDate": "05.09.2038"
    },
    {
        "engDate": "1981-12-21",
        "nepDate": "06.09.2038"
    },
    {
        "engDate": "1981-12-22",
        "nepDate": "07.09.2038"
    },
    {
        "engDate": "1981-12-23",
        "nepDate": "08.09.2038"
    },
    {
        "engDate": "1981-12-24",
        "nepDate": "09.09.2038"
    },
    {
        "engDate": "1981-12-25",
        "nepDate": "10.09.2038"
    },
    {
        "engDate": "1981-12-26",
        "nepDate": "11.09.2038"
    },
    {
        "engDate": "1981-12-27",
        "nepDate": "12.09.2038"
    },
    {
        "engDate": "1981-12-28",
        "nepDate": "13.09.2038"
    },
    {
        "engDate": "1981-12-29",
        "nepDate": "14.09.2038"
    },
    {
        "engDate": "1981-12-30",
        "nepDate": "15.09.2038"
    },
    {
        "engDate": "1981-12-31",
        "nepDate": "16.09.2038"
    },
    {
        "engDate": "1982-01-01",
        "nepDate": "17.09.2038"
    },
    {
        "engDate": "1982-01-02",
        "nepDate": "18.09.2038"
    },
    {
        "engDate": "1982-01-03",
        "nepDate": "19.09.2038"
    },
    {
        "engDate": "1982-01-04",
        "nepDate": "20.09.2038"
    },
    {
        "engDate": "1982-01-05",
        "nepDate": "21.09.2038"
    },
    {
        "engDate": "1982-01-06",
        "nepDate": "22.09.2038"
    },
    {
        "engDate": "1982-01-07",
        "nepDate": "23.09.2038"
    },
    {
        "engDate": "1982-01-08",
        "nepDate": "24.09.2038"
    },
    {
        "engDate": "1982-01-09",
        "nepDate": "25.09.2038"
    },
    {
        "engDate": "1982-01-10",
        "nepDate": "26.09.2038"
    },
    {
        "engDate": "1982-01-11",
        "nepDate": "27.09.2038"
    },
    {
        "engDate": "1982-01-12",
        "nepDate": "28.09.2038"
    },
    {
        "engDate": "1982-01-13",
        "nepDate": "29.09.2038"
    },
    {
        "engDate": "1982-01-14",
        "nepDate": "01.10.2038"
    },
    {
        "engDate": "1982-01-15",
        "nepDate": "02.10.2038"
    },
    {
        "engDate": "1982-01-16",
        "nepDate": "03.10.2038"
    },
    {
        "engDate": "1982-01-17",
        "nepDate": "04.10.2038"
    },
    {
        "engDate": "1982-01-18",
        "nepDate": "05.10.2038"
    },
    {
        "engDate": "1982-01-19",
        "nepDate": "06.10.2038"
    },
    {
        "engDate": "1982-01-20",
        "nepDate": "07.10.2038"
    },
    {
        "engDate": "1982-01-21",
        "nepDate": "08.10.2038"
    },
    {
        "engDate": "1982-01-22",
        "nepDate": "09.10.2038"
    },
    {
        "engDate": "1982-01-23",
        "nepDate": "10.10.2038"
    },
    {
        "engDate": "1982-01-24",
        "nepDate": "11.10.2038"
    },
    {
        "engDate": "1982-01-25",
        "nepDate": "12.10.2038"
    },
    {
        "engDate": "1982-01-26",
        "nepDate": "13.10.2038"
    },
    {
        "engDate": "1982-01-27",
        "nepDate": "14.10.2038"
    },
    {
        "engDate": "1982-01-28",
        "nepDate": "15.10.2038"
    },
    {
        "engDate": "1982-01-29",
        "nepDate": "16.10.2038"
    },
    {
        "engDate": "1982-01-30",
        "nepDate": "17.10.2038"
    },
    {
        "engDate": "1982-01-31",
        "nepDate": "18.10.2038"
    },
    {
        "engDate": "1982-02-01",
        "nepDate": "19.10.2038"
    },
    {
        "engDate": "1982-02-02",
        "nepDate": "20.10.2038"
    },
    {
        "engDate": "1982-02-03",
        "nepDate": "21.10.2038"
    },
    {
        "engDate": "1982-02-04",
        "nepDate": "22.10.2038"
    },
    {
        "engDate": "1982-02-05",
        "nepDate": "23.10.2038"
    },
    {
        "engDate": "1982-02-06",
        "nepDate": "24.10.2038"
    },
    {
        "engDate": "1982-02-07",
        "nepDate": "25.10.2038"
    },
    {
        "engDate": "1982-02-08",
        "nepDate": "26.10.2038"
    },
    {
        "engDate": "1982-02-09",
        "nepDate": "27.10.2038"
    },
    {
        "engDate": "1982-02-10",
        "nepDate": "28.10.2038"
    },
    {
        "engDate": "1982-02-11",
        "nepDate": "29.10.2038"
    },
    {
        "engDate": "1982-02-12",
        "nepDate": "01.11.2038"
    },
    {
        "engDate": "1982-02-13",
        "nepDate": "02.11.2038"
    },
    {
        "engDate": "1982-02-14",
        "nepDate": "03.11.2038"
    },
    {
        "engDate": "1982-02-15",
        "nepDate": "04.11.2038"
    },
    {
        "engDate": "1982-02-16",
        "nepDate": "05.11.2038"
    },
    {
        "engDate": "1982-02-17",
        "nepDate": "06.11.2038"
    },
    {
        "engDate": "1982-02-18",
        "nepDate": "07.11.2038"
    },
    {
        "engDate": "1982-02-19",
        "nepDate": "08.11.2038"
    },
    {
        "engDate": "1982-02-20",
        "nepDate": "09.11.2038"
    },
    {
        "engDate": "1982-02-21",
        "nepDate": "10.11.2038"
    },
    {
        "engDate": "1982-02-22",
        "nepDate": "11.11.2038"
    },
    {
        "engDate": "1982-02-23",
        "nepDate": "12.11.2038"
    },
    {
        "engDate": "1982-02-24",
        "nepDate": "13.11.2038"
    },
    {
        "engDate": "1982-02-25",
        "nepDate": "14.11.2038"
    },
    {
        "engDate": "1982-02-26",
        "nepDate": "15.11.2038"
    },
    {
        "engDate": "1982-02-27",
        "nepDate": "16.11.2038"
    },
    {
        "engDate": "1982-02-28",
        "nepDate": "17.11.2038"
    },
    {
        "engDate": "1982-03-01",
        "nepDate": "18.11.2038"
    },
    {
        "engDate": "1982-03-02",
        "nepDate": "19.11.2038"
    },
    {
        "engDate": "1982-03-03",
        "nepDate": "20.11.2038"
    },
    {
        "engDate": "1982-03-04",
        "nepDate": "21.11.2038"
    },
    {
        "engDate": "1982-03-05",
        "nepDate": "22.11.2038"
    },
    {
        "engDate": "1982-03-06",
        "nepDate": "23.11.2038"
    },
    {
        "engDate": "1982-03-07",
        "nepDate": "24.11.2038"
    },
    {
        "engDate": "1982-03-08",
        "nepDate": "25.11.2038"
    },
    {
        "engDate": "1982-03-09",
        "nepDate": "26.11.2038"
    },
    {
        "engDate": "1982-03-10",
        "nepDate": "27.11.2038"
    },
    {
        "engDate": "1982-03-11",
        "nepDate": "28.11.2038"
    },
    {
        "engDate": "1982-03-12",
        "nepDate": "29.11.2038"
    },
    {
        "engDate": "1982-03-13",
        "nepDate": "30.11.2038"
    },
    {
        "engDate": "1982-03-14",
        "nepDate": "01.12.2038"
    },
    {
        "engDate": "1982-03-15",
        "nepDate": "02.12.2038"
    },
    {
        "engDate": "1982-03-16",
        "nepDate": "03.12.2038"
    },
    {
        "engDate": "1982-03-17",
        "nepDate": "04.12.2038"
    },
    {
        "engDate": "1982-03-18",
        "nepDate": "05.12.2038"
    },
    {
        "engDate": "1982-03-19",
        "nepDate": "06.12.2038"
    },
    {
        "engDate": "1982-03-20",
        "nepDate": "07.12.2038"
    },
    {
        "engDate": "1982-03-21",
        "nepDate": "08.12.2038"
    },
    {
        "engDate": "1982-03-22",
        "nepDate": "09.12.2038"
    },
    {
        "engDate": "1982-03-23",
        "nepDate": "10.12.2038"
    },
    {
        "engDate": "1982-03-24",
        "nepDate": "11.12.2038"
    },
    {
        "engDate": "1982-03-25",
        "nepDate": "12.12.2038"
    },
    {
        "engDate": "1982-03-26",
        "nepDate": "13.12.2038"
    },
    {
        "engDate": "1982-03-27",
        "nepDate": "14.12.2038"
    },
    {
        "engDate": "1982-03-28",
        "nepDate": "15.12.2038"
    },
    {
        "engDate": "1982-03-29",
        "nepDate": "16.12.2038"
    },
    {
        "engDate": "1982-03-30",
        "nepDate": "17.12.2038"
    },
    {
        "engDate": "1982-03-31",
        "nepDate": "18.12.2038"
    },
    {
        "engDate": "1982-04-01",
        "nepDate": "19.12.2038"
    },
    {
        "engDate": "1982-04-02",
        "nepDate": "20.12.2038"
    },
    {
        "engDate": "1982-04-03",
        "nepDate": "21.12.2038"
    },
    {
        "engDate": "1982-04-04",
        "nepDate": "22.12.2038"
    },
    {
        "engDate": "1982-04-05",
        "nepDate": "23.12.2038"
    },
    {
        "engDate": "1982-04-06",
        "nepDate": "24.12.2038"
    },
    {
        "engDate": "1982-04-07",
        "nepDate": "25.12.2038"
    },
    {
        "engDate": "1982-04-08",
        "nepDate": "26.12.2038"
    },
    {
        "engDate": "1982-04-09",
        "nepDate": "27.12.2038"
    },
    {
        "engDate": "1982-04-10",
        "nepDate": "28.12.2038"
    },
    {
        "engDate": "1982-04-11",
        "nepDate": "29.12.2038"
    },
    {
        "engDate": "1982-04-12",
        "nepDate": "30.12.2038"
    },
    {
        "engDate": "1982-04-13",
        "nepDate": "31.12.2038"
    },
    {
        "engDate": "1982-04-14",
        "nepDate": "01.01.2039"
    },
    {
        "engDate": "1982-04-15",
        "nepDate": "02.01.2039"
    },
    {
        "engDate": "1982-04-16",
        "nepDate": "03.01.2039"
    },
    {
        "engDate": "1982-04-17",
        "nepDate": "04.01.2039"
    },
    {
        "engDate": "1982-04-18",
        "nepDate": "05.01.2039"
    },
    {
        "engDate": "1982-04-19",
        "nepDate": "06.01.2039"
    },
    {
        "engDate": "1982-04-20",
        "nepDate": "07.01.2039"
    },
    {
        "engDate": "1982-04-21",
        "nepDate": "08.01.2039"
    },
    {
        "engDate": "1982-04-22",
        "nepDate": "09.01.2039"
    },
    {
        "engDate": "1982-04-23",
        "nepDate": "10.01.2039"
    },
    {
        "engDate": "1982-04-24",
        "nepDate": "11.01.2039"
    },
    {
        "engDate": "1982-04-25",
        "nepDate": "12.01.2039"
    },
    {
        "engDate": "1982-04-26",
        "nepDate": "13.01.2039"
    },
    {
        "engDate": "1982-04-27",
        "nepDate": "14.01.2039"
    },
    {
        "engDate": "1982-04-28",
        "nepDate": "15.01.2039"
    },
    {
        "engDate": "1982-04-29",
        "nepDate": "16.01.2039"
    },
    {
        "engDate": "1982-04-30",
        "nepDate": "17.01.2039"
    },
    {
        "engDate": "1982-05-01",
        "nepDate": "18.01.2039"
    },
    {
        "engDate": "1982-05-02",
        "nepDate": "19.01.2039"
    },
    {
        "engDate": "1982-05-03",
        "nepDate": "20.01.2039"
    },
    {
        "engDate": "1982-05-04",
        "nepDate": "21.01.2039"
    },
    {
        "engDate": "1982-05-05",
        "nepDate": "22.01.2039"
    },
    {
        "engDate": "1982-05-06",
        "nepDate": "23.01.2039"
    },
    {
        "engDate": "1982-05-07",
        "nepDate": "24.01.2039"
    },
    {
        "engDate": "1982-05-08",
        "nepDate": "25.01.2039"
    },
    {
        "engDate": "1982-05-09",
        "nepDate": "26.01.2039"
    },
    {
        "engDate": "1982-05-10",
        "nepDate": "27.01.2039"
    },
    {
        "engDate": "1982-05-11",
        "nepDate": "28.01.2039"
    },
    {
        "engDate": "1982-05-12",
        "nepDate": "29.01.2039"
    },
    {
        "engDate": "1982-05-13",
        "nepDate": "30.01.2039"
    },
    {
        "engDate": "1982-05-14",
        "nepDate": "31.01.2039"
    },
    {
        "engDate": "1982-05-15",
        "nepDate": "01.02.2039"
    },
    {
        "engDate": "1982-05-16",
        "nepDate": "02.02.2039"
    },
    {
        "engDate": "1982-05-17",
        "nepDate": "03.02.2039"
    },
    {
        "engDate": "1982-05-18",
        "nepDate": "04.02.2039"
    },
    {
        "engDate": "1982-05-19",
        "nepDate": "05.02.2039"
    },
    {
        "engDate": "1982-05-20",
        "nepDate": "06.02.2039"
    },
    {
        "engDate": "1982-05-21",
        "nepDate": "07.02.2039"
    },
    {
        "engDate": "1982-05-22",
        "nepDate": "08.02.2039"
    },
    {
        "engDate": "1982-05-23",
        "nepDate": "09.02.2039"
    },
    {
        "engDate": "1982-05-24",
        "nepDate": "10.02.2039"
    },
    {
        "engDate": "1982-05-25",
        "nepDate": "11.02.2039"
    },
    {
        "engDate": "1982-05-26",
        "nepDate": "12.02.2039"
    },
    {
        "engDate": "1982-05-27",
        "nepDate": "13.02.2039"
    },
    {
        "engDate": "1982-05-28",
        "nepDate": "14.02.2039"
    },
    {
        "engDate": "1982-05-29",
        "nepDate": "15.02.2039"
    },
    {
        "engDate": "1982-05-30",
        "nepDate": "16.02.2039"
    },
    {
        "engDate": "1982-05-31",
        "nepDate": "17.02.2039"
    },
    {
        "engDate": "1982-06-01",
        "nepDate": "18.02.2039"
    },
    {
        "engDate": "1982-06-02",
        "nepDate": "19.02.2039"
    },
    {
        "engDate": "1982-06-03",
        "nepDate": "20.02.2039"
    },
    {
        "engDate": "1982-06-04",
        "nepDate": "21.02.2039"
    },
    {
        "engDate": "1982-06-05",
        "nepDate": "22.02.2039"
    },
    {
        "engDate": "1982-06-06",
        "nepDate": "23.02.2039"
    },
    {
        "engDate": "1982-06-07",
        "nepDate": "24.02.2039"
    },
    {
        "engDate": "1982-06-08",
        "nepDate": "25.02.2039"
    },
    {
        "engDate": "1982-06-09",
        "nepDate": "26.02.2039"
    },
    {
        "engDate": "1982-06-10",
        "nepDate": "27.02.2039"
    },
    {
        "engDate": "1982-06-11",
        "nepDate": "28.02.2039"
    },
    {
        "engDate": "1982-06-12",
        "nepDate": "29.02.2039"
    },
    {
        "engDate": "1982-06-13",
        "nepDate": "30.02.2039"
    },
    {
        "engDate": "1982-06-14",
        "nepDate": "31.02.2039"
    },
    {
        "engDate": "1982-06-15",
        "nepDate": "01.03.2039"
    },
    {
        "engDate": "1982-06-16",
        "nepDate": "02.03.2039"
    },
    {
        "engDate": "1982-06-17",
        "nepDate": "03.03.2039"
    },
    {
        "engDate": "1982-06-18",
        "nepDate": "04.03.2039"
    },
    {
        "engDate": "1982-06-19",
        "nepDate": "05.03.2039"
    },
    {
        "engDate": "1982-06-20",
        "nepDate": "06.03.2039"
    },
    {
        "engDate": "1982-06-21",
        "nepDate": "07.03.2039"
    },
    {
        "engDate": "1982-06-22",
        "nepDate": "08.03.2039"
    },
    {
        "engDate": "1982-06-23",
        "nepDate": "09.03.2039"
    },
    {
        "engDate": "1982-06-24",
        "nepDate": "10.03.2039"
    },
    {
        "engDate": "1982-06-25",
        "nepDate": "11.03.2039"
    },
    {
        "engDate": "1982-06-26",
        "nepDate": "12.03.2039"
    },
    {
        "engDate": "1982-06-27",
        "nepDate": "13.03.2039"
    },
    {
        "engDate": "1982-06-28",
        "nepDate": "14.03.2039"
    },
    {
        "engDate": "1982-06-29",
        "nepDate": "15.03.2039"
    },
    {
        "engDate": "1982-06-30",
        "nepDate": "16.03.2039"
    },
    {
        "engDate": "1982-07-01",
        "nepDate": "17.03.2039"
    },
    {
        "engDate": "1982-07-02",
        "nepDate": "18.03.2039"
    },
    {
        "engDate": "1982-07-03",
        "nepDate": "19.03.2039"
    },
    {
        "engDate": "1982-07-04",
        "nepDate": "20.03.2039"
    },
    {
        "engDate": "1982-07-05",
        "nepDate": "21.03.2039"
    },
    {
        "engDate": "1982-07-06",
        "nepDate": "22.03.2039"
    },
    {
        "engDate": "1982-07-07",
        "nepDate": "23.03.2039"
    },
    {
        "engDate": "1982-07-08",
        "nepDate": "24.03.2039"
    },
    {
        "engDate": "1982-07-09",
        "nepDate": "25.03.2039"
    },
    {
        "engDate": "1982-07-10",
        "nepDate": "26.03.2039"
    },
    {
        "engDate": "1982-07-11",
        "nepDate": "27.03.2039"
    },
    {
        "engDate": "1982-07-12",
        "nepDate": "28.03.2039"
    },
    {
        "engDate": "1982-07-13",
        "nepDate": "29.03.2039"
    },
    {
        "engDate": "1982-07-14",
        "nepDate": "30.03.2039"
    },
    {
        "engDate": "1982-07-15",
        "nepDate": "31.03.2039"
    },
    {
        "engDate": "1982-07-16",
        "nepDate": "01.04.2039"
    },
    {
        "engDate": "1982-07-17",
        "nepDate": "02.04.2039"
    },
    {
        "engDate": "1982-07-18",
        "nepDate": "03.04.2039"
    },
    {
        "engDate": "1982-07-19",
        "nepDate": "04.04.2039"
    },
    {
        "engDate": "1982-07-20",
        "nepDate": "05.04.2039"
    },
    {
        "engDate": "1982-07-21",
        "nepDate": "06.04.2039"
    },
    {
        "engDate": "1982-07-22",
        "nepDate": "07.04.2039"
    },
    {
        "engDate": "1982-07-23",
        "nepDate": "08.04.2039"
    },
    {
        "engDate": "1982-07-24",
        "nepDate": "09.04.2039"
    },
    {
        "engDate": "1982-07-25",
        "nepDate": "10.04.2039"
    },
    {
        "engDate": "1982-07-26",
        "nepDate": "11.04.2039"
    },
    {
        "engDate": "1982-07-27",
        "nepDate": "12.04.2039"
    },
    {
        "engDate": "1982-07-28",
        "nepDate": "13.04.2039"
    },
    {
        "engDate": "1982-07-29",
        "nepDate": "14.04.2039"
    },
    {
        "engDate": "1982-07-30",
        "nepDate": "15.04.2039"
    },
    {
        "engDate": "1982-07-31",
        "nepDate": "16.04.2039"
    },
    {
        "engDate": "1982-08-01",
        "nepDate": "17.04.2039"
    },
    {
        "engDate": "1982-08-02",
        "nepDate": "18.04.2039"
    },
    {
        "engDate": "1982-08-03",
        "nepDate": "19.04.2039"
    },
    {
        "engDate": "1982-08-04",
        "nepDate": "20.04.2039"
    },
    {
        "engDate": "1982-08-05",
        "nepDate": "21.04.2039"
    },
    {
        "engDate": "1982-08-06",
        "nepDate": "22.04.2039"
    },
    {
        "engDate": "1982-08-07",
        "nepDate": "23.04.2039"
    },
    {
        "engDate": "1982-08-08",
        "nepDate": "24.04.2039"
    },
    {
        "engDate": "1982-08-09",
        "nepDate": "25.04.2039"
    },
    {
        "engDate": "1982-08-10",
        "nepDate": "26.04.2039"
    },
    {
        "engDate": "1982-08-11",
        "nepDate": "27.04.2039"
    },
    {
        "engDate": "1982-08-12",
        "nepDate": "28.04.2039"
    },
    {
        "engDate": "1982-08-13",
        "nepDate": "29.04.2039"
    },
    {
        "engDate": "1982-08-14",
        "nepDate": "30.04.2039"
    },
    {
        "engDate": "1982-08-15",
        "nepDate": "31.04.2039"
    },
    {
        "engDate": "1982-08-16",
        "nepDate": "32.04.2039"
    },
    {
        "engDate": "1982-08-17",
        "nepDate": "01.05.2039"
    },
    {
        "engDate": "1982-08-18",
        "nepDate": "02.05.2039"
    },
    {
        "engDate": "1982-08-19",
        "nepDate": "03.05.2039"
    },
    {
        "engDate": "1982-08-20",
        "nepDate": "04.05.2039"
    },
    {
        "engDate": "1982-08-21",
        "nepDate": "05.05.2039"
    },
    {
        "engDate": "1982-08-22",
        "nepDate": "06.05.2039"
    },
    {
        "engDate": "1982-08-23",
        "nepDate": "07.05.2039"
    },
    {
        "engDate": "1982-08-24",
        "nepDate": "08.05.2039"
    },
    {
        "engDate": "1982-08-25",
        "nepDate": "09.05.2039"
    },
    {
        "engDate": "1982-08-26",
        "nepDate": "10.05.2039"
    },
    {
        "engDate": "1982-08-27",
        "nepDate": "11.05.2039"
    },
    {
        "engDate": "1982-08-28",
        "nepDate": "12.05.2039"
    },
    {
        "engDate": "1982-08-29",
        "nepDate": "13.05.2039"
    },
    {
        "engDate": "1982-08-30",
        "nepDate": "14.05.2039"
    },
    {
        "engDate": "1982-08-31",
        "nepDate": "15.05.2039"
    },
    {
        "engDate": "1982-09-01",
        "nepDate": "16.05.2039"
    },
    {
        "engDate": "1982-09-02",
        "nepDate": "17.05.2039"
    },
    {
        "engDate": "1982-09-03",
        "nepDate": "18.05.2039"
    },
    {
        "engDate": "1982-09-04",
        "nepDate": "19.05.2039"
    },
    {
        "engDate": "1982-09-05",
        "nepDate": "20.05.2039"
    },
    {
        "engDate": "1982-09-06",
        "nepDate": "21.05.2039"
    },
    {
        "engDate": "1982-09-07",
        "nepDate": "22.05.2039"
    },
    {
        "engDate": "1982-09-08",
        "nepDate": "23.05.2039"
    },
    {
        "engDate": "1982-09-09",
        "nepDate": "24.05.2039"
    },
    {
        "engDate": "1982-09-10",
        "nepDate": "25.05.2039"
    },
    {
        "engDate": "1982-09-11",
        "nepDate": "26.05.2039"
    },
    {
        "engDate": "1982-09-12",
        "nepDate": "27.05.2039"
    },
    {
        "engDate": "1982-09-13",
        "nepDate": "28.05.2039"
    },
    {
        "engDate": "1982-09-14",
        "nepDate": "29.05.2039"
    },
    {
        "engDate": "1982-09-15",
        "nepDate": "30.05.2039"
    },
    {
        "engDate": "1982-09-16",
        "nepDate": "31.05.2039"
    },
    {
        "engDate": "1982-09-17",
        "nepDate": "01.06.2039"
    },
    {
        "engDate": "1982-09-18",
        "nepDate": "02.06.2039"
    },
    {
        "engDate": "1982-09-19",
        "nepDate": "03.06.2039"
    },
    {
        "engDate": "1982-09-20",
        "nepDate": "04.06.2039"
    },
    {
        "engDate": "1982-09-21",
        "nepDate": "05.06.2039"
    },
    {
        "engDate": "1982-09-22",
        "nepDate": "06.06.2039"
    },
    {
        "engDate": "1982-09-23",
        "nepDate": "07.06.2039"
    },
    {
        "engDate": "1982-09-24",
        "nepDate": "08.06.2039"
    },
    {
        "engDate": "1982-09-25",
        "nepDate": "09.06.2039"
    },
    {
        "engDate": "1982-09-26",
        "nepDate": "10.06.2039"
    },
    {
        "engDate": "1982-09-27",
        "nepDate": "11.06.2039"
    },
    {
        "engDate": "1982-09-28",
        "nepDate": "12.06.2039"
    },
    {
        "engDate": "1982-09-29",
        "nepDate": "13.06.2039"
    },
    {
        "engDate": "1982-09-30",
        "nepDate": "14.06.2039"
    },
    {
        "engDate": "1982-10-01",
        "nepDate": "15.06.2039"
    },
    {
        "engDate": "1982-10-02",
        "nepDate": "16.06.2039"
    },
    {
        "engDate": "1982-10-03",
        "nepDate": "17.06.2039"
    },
    {
        "engDate": "1982-10-04",
        "nepDate": "18.06.2039"
    },
    {
        "engDate": "1982-10-05",
        "nepDate": "19.06.2039"
    },
    {
        "engDate": "1982-10-06",
        "nepDate": "20.06.2039"
    },
    {
        "engDate": "1982-10-07",
        "nepDate": "21.06.2039"
    },
    {
        "engDate": "1982-10-08",
        "nepDate": "22.06.2039"
    },
    {
        "engDate": "1982-10-09",
        "nepDate": "23.06.2039"
    },
    {
        "engDate": "1982-10-10",
        "nepDate": "24.06.2039"
    },
    {
        "engDate": "1982-10-11",
        "nepDate": "25.06.2039"
    },
    {
        "engDate": "1982-10-12",
        "nepDate": "26.06.2039"
    },
    {
        "engDate": "1982-10-13",
        "nepDate": "27.06.2039"
    },
    {
        "engDate": "1982-10-14",
        "nepDate": "28.06.2039"
    },
    {
        "engDate": "1982-10-15",
        "nepDate": "29.06.2039"
    },
    {
        "engDate": "1982-10-16",
        "nepDate": "30.06.2039"
    },
    {
        "engDate": "1982-10-17",
        "nepDate": "31.06.2039"
    },
    {
        "engDate": "1982-10-18",
        "nepDate": "01.07.2039"
    },
    {
        "engDate": "1982-10-19",
        "nepDate": "02.07.2039"
    },
    {
        "engDate": "1982-10-20",
        "nepDate": "03.07.2039"
    },
    {
        "engDate": "1982-10-21",
        "nepDate": "04.07.2039"
    },
    {
        "engDate": "1982-10-22",
        "nepDate": "05.07.2039"
    },
    {
        "engDate": "1982-10-23",
        "nepDate": "06.07.2039"
    },
    {
        "engDate": "1982-10-24",
        "nepDate": "07.07.2039"
    },
    {
        "engDate": "1982-10-25",
        "nepDate": "08.07.2039"
    },
    {
        "engDate": "1982-10-26",
        "nepDate": "09.07.2039"
    },
    {
        "engDate": "1982-10-27",
        "nepDate": "10.07.2039"
    },
    {
        "engDate": "1982-10-28",
        "nepDate": "11.07.2039"
    },
    {
        "engDate": "1982-10-29",
        "nepDate": "12.07.2039"
    },
    {
        "engDate": "1982-10-30",
        "nepDate": "13.07.2039"
    },
    {
        "engDate": "1982-10-31",
        "nepDate": "14.07.2039"
    },
    {
        "engDate": "1982-11-01",
        "nepDate": "15.07.2039"
    },
    {
        "engDate": "1982-11-02",
        "nepDate": "16.07.2039"
    },
    {
        "engDate": "1982-11-03",
        "nepDate": "17.07.2039"
    },
    {
        "engDate": "1982-11-04",
        "nepDate": "18.07.2039"
    },
    {
        "engDate": "1982-11-05",
        "nepDate": "19.07.2039"
    },
    {
        "engDate": "1982-11-06",
        "nepDate": "20.07.2039"
    },
    {
        "engDate": "1982-11-07",
        "nepDate": "21.07.2039"
    },
    {
        "engDate": "1982-11-08",
        "nepDate": "22.07.2039"
    },
    {
        "engDate": "1982-11-09",
        "nepDate": "23.07.2039"
    },
    {
        "engDate": "1982-11-10",
        "nepDate": "24.07.2039"
    },
    {
        "engDate": "1982-11-11",
        "nepDate": "25.07.2039"
    },
    {
        "engDate": "1982-11-12",
        "nepDate": "26.07.2039"
    },
    {
        "engDate": "1982-11-13",
        "nepDate": "27.07.2039"
    },
    {
        "engDate": "1982-11-14",
        "nepDate": "28.07.2039"
    },
    {
        "engDate": "1982-11-15",
        "nepDate": "29.07.2039"
    },
    {
        "engDate": "1982-11-16",
        "nepDate": "01.08.2039"
    },
    {
        "engDate": "1982-11-17",
        "nepDate": "02.08.2039"
    },
    {
        "engDate": "1982-11-18",
        "nepDate": "03.08.2039"
    },
    {
        "engDate": "1982-11-19",
        "nepDate": "04.08.2039"
    },
    {
        "engDate": "1982-11-20",
        "nepDate": "05.08.2039"
    },
    {
        "engDate": "1982-11-21",
        "nepDate": "06.08.2039"
    },
    {
        "engDate": "1982-11-22",
        "nepDate": "07.08.2039"
    },
    {
        "engDate": "1982-11-23",
        "nepDate": "08.08.2039"
    },
    {
        "engDate": "1982-11-24",
        "nepDate": "09.08.2039"
    },
    {
        "engDate": "1982-11-25",
        "nepDate": "10.08.2039"
    },
    {
        "engDate": "1982-11-26",
        "nepDate": "11.08.2039"
    },
    {
        "engDate": "1982-11-27",
        "nepDate": "12.08.2039"
    },
    {
        "engDate": "1982-11-28",
        "nepDate": "13.08.2039"
    },
    {
        "engDate": "1982-11-29",
        "nepDate": "14.08.2039"
    },
    {
        "engDate": "1982-11-30",
        "nepDate": "15.08.2039"
    },
    {
        "engDate": "1982-12-01",
        "nepDate": "16.08.2039"
    },
    {
        "engDate": "1982-12-02",
        "nepDate": "17.08.2039"
    },
    {
        "engDate": "1982-12-03",
        "nepDate": "18.08.2039"
    },
    {
        "engDate": "1982-12-04",
        "nepDate": "19.08.2039"
    },
    {
        "engDate": "1982-12-05",
        "nepDate": "20.08.2039"
    },
    {
        "engDate": "1982-12-06",
        "nepDate": "21.08.2039"
    },
    {
        "engDate": "1982-12-07",
        "nepDate": "22.08.2039"
    },
    {
        "engDate": "1982-12-08",
        "nepDate": "23.08.2039"
    },
    {
        "engDate": "1982-12-09",
        "nepDate": "24.08.2039"
    },
    {
        "engDate": "1982-12-10",
        "nepDate": "25.08.2039"
    },
    {
        "engDate": "1982-12-11",
        "nepDate": "26.08.2039"
    },
    {
        "engDate": "1982-12-12",
        "nepDate": "27.08.2039"
    },
    {
        "engDate": "1982-12-13",
        "nepDate": "28.08.2039"
    },
    {
        "engDate": "1982-12-14",
        "nepDate": "29.08.2039"
    },
    {
        "engDate": "1982-12-15",
        "nepDate": "30.08.2039"
    },
    {
        "engDate": "1982-12-16",
        "nepDate": "01.09.2039"
    },
    {
        "engDate": "1982-12-17",
        "nepDate": "02.09.2039"
    },
    {
        "engDate": "1982-12-18",
        "nepDate": "03.09.2039"
    },
    {
        "engDate": "1982-12-19",
        "nepDate": "04.09.2039"
    },
    {
        "engDate": "1982-12-20",
        "nepDate": "05.09.2039"
    },
    {
        "engDate": "1982-12-21",
        "nepDate": "06.09.2039"
    },
    {
        "engDate": "1982-12-22",
        "nepDate": "07.09.2039"
    },
    {
        "engDate": "1982-12-23",
        "nepDate": "08.09.2039"
    },
    {
        "engDate": "1982-12-24",
        "nepDate": "09.09.2039"
    },
    {
        "engDate": "1982-12-25",
        "nepDate": "10.09.2039"
    },
    {
        "engDate": "1982-12-26",
        "nepDate": "11.09.2039"
    },
    {
        "engDate": "1982-12-27",
        "nepDate": "12.09.2039"
    },
    {
        "engDate": "1982-12-28",
        "nepDate": "13.09.2039"
    },
    {
        "engDate": "1982-12-29",
        "nepDate": "14.09.2039"
    },
    {
        "engDate": "1982-12-30",
        "nepDate": "15.09.2039"
    },
    {
        "engDate": "1982-12-31",
        "nepDate": "16.09.2039"
    },
    {
        "engDate": "1983-01-01",
        "nepDate": "17.09.2039"
    },
    {
        "engDate": "1983-01-02",
        "nepDate": "18.09.2039"
    },
    {
        "engDate": "1983-01-03",
        "nepDate": "19.09.2039"
    },
    {
        "engDate": "1983-01-04",
        "nepDate": "20.09.2039"
    },
    {
        "engDate": "1983-01-05",
        "nepDate": "21.09.2039"
    },
    {
        "engDate": "1983-01-06",
        "nepDate": "22.09.2039"
    },
    {
        "engDate": "1983-01-07",
        "nepDate": "23.09.2039"
    },
    {
        "engDate": "1983-01-08",
        "nepDate": "24.09.2039"
    },
    {
        "engDate": "1983-01-09",
        "nepDate": "25.09.2039"
    },
    {
        "engDate": "1983-01-10",
        "nepDate": "26.09.2039"
    },
    {
        "engDate": "1983-01-11",
        "nepDate": "27.09.2039"
    },
    {
        "engDate": "1983-01-12",
        "nepDate": "28.09.2039"
    },
    {
        "engDate": "1983-01-13",
        "nepDate": "29.09.2039"
    },
    {
        "engDate": "1983-01-14",
        "nepDate": "30.09.2039"
    },
    {
        "engDate": "1983-01-15",
        "nepDate": "01.10.2039"
    },
    {
        "engDate": "1983-01-16",
        "nepDate": "02.10.2039"
    },
    {
        "engDate": "1983-01-17",
        "nepDate": "03.10.2039"
    },
    {
        "engDate": "1983-01-18",
        "nepDate": "04.10.2039"
    },
    {
        "engDate": "1983-01-19",
        "nepDate": "05.10.2039"
    },
    {
        "engDate": "1983-01-20",
        "nepDate": "06.10.2039"
    },
    {
        "engDate": "1983-01-21",
        "nepDate": "07.10.2039"
    },
    {
        "engDate": "1983-01-22",
        "nepDate": "08.10.2039"
    },
    {
        "engDate": "1983-01-23",
        "nepDate": "09.10.2039"
    },
    {
        "engDate": "1983-01-24",
        "nepDate": "10.10.2039"
    },
    {
        "engDate": "1983-01-25",
        "nepDate": "11.10.2039"
    },
    {
        "engDate": "1983-01-26",
        "nepDate": "12.10.2039"
    },
    {
        "engDate": "1983-01-27",
        "nepDate": "13.10.2039"
    },
    {
        "engDate": "1983-01-28",
        "nepDate": "14.10.2039"
    },
    {
        "engDate": "1983-01-29",
        "nepDate": "15.10.2039"
    },
    {
        "engDate": "1983-01-30",
        "nepDate": "16.10.2039"
    },
    {
        "engDate": "1983-01-31",
        "nepDate": "17.10.2039"
    },
    {
        "engDate": "1983-02-01",
        "nepDate": "18.10.2039"
    },
    {
        "engDate": "1983-02-02",
        "nepDate": "19.10.2039"
    },
    {
        "engDate": "1983-02-03",
        "nepDate": "20.10.2039"
    },
    {
        "engDate": "1983-02-04",
        "nepDate": "21.10.2039"
    },
    {
        "engDate": "1983-02-05",
        "nepDate": "22.10.2039"
    },
    {
        "engDate": "1983-02-06",
        "nepDate": "23.10.2039"
    },
    {
        "engDate": "1983-02-07",
        "nepDate": "24.10.2039"
    },
    {
        "engDate": "1983-02-08",
        "nepDate": "25.10.2039"
    },
    {
        "engDate": "1983-02-09",
        "nepDate": "26.10.2039"
    },
    {
        "engDate": "1983-02-10",
        "nepDate": "27.10.2039"
    },
    {
        "engDate": "1983-02-11",
        "nepDate": "28.10.2039"
    },
    {
        "engDate": "1983-02-12",
        "nepDate": "29.10.2039"
    },
    {
        "engDate": "1983-02-13",
        "nepDate": "01.11.2039"
    },
    {
        "engDate": "1983-02-14",
        "nepDate": "02.11.2039"
    },
    {
        "engDate": "1983-02-15",
        "nepDate": "03.11.2039"
    },
    {
        "engDate": "1983-02-16",
        "nepDate": "04.11.2039"
    },
    {
        "engDate": "1983-02-17",
        "nepDate": "05.11.2039"
    },
    {
        "engDate": "1983-02-18",
        "nepDate": "06.11.2039"
    },
    {
        "engDate": "1983-02-19",
        "nepDate": "07.11.2039"
    },
    {
        "engDate": "1983-02-20",
        "nepDate": "08.11.2039"
    },
    {
        "engDate": "1983-02-21",
        "nepDate": "09.11.2039"
    },
    {
        "engDate": "1983-02-22",
        "nepDate": "10.11.2039"
    },
    {
        "engDate": "1983-02-23",
        "nepDate": "11.11.2039"
    },
    {
        "engDate": "1983-02-24",
        "nepDate": "12.11.2039"
    },
    {
        "engDate": "1983-02-25",
        "nepDate": "13.11.2039"
    },
    {
        "engDate": "1983-02-26",
        "nepDate": "14.11.2039"
    },
    {
        "engDate": "1983-02-27",
        "nepDate": "15.11.2039"
    },
    {
        "engDate": "1983-02-28",
        "nepDate": "16.11.2039"
    },
    {
        "engDate": "1983-03-01",
        "nepDate": "17.11.2039"
    },
    {
        "engDate": "1983-03-02",
        "nepDate": "18.11.2039"
    },
    {
        "engDate": "1983-03-03",
        "nepDate": "19.11.2039"
    },
    {
        "engDate": "1983-03-04",
        "nepDate": "20.11.2039"
    },
    {
        "engDate": "1983-03-05",
        "nepDate": "21.11.2039"
    },
    {
        "engDate": "1983-03-06",
        "nepDate": "22.11.2039"
    },
    {
        "engDate": "1983-03-07",
        "nepDate": "23.11.2039"
    },
    {
        "engDate": "1983-03-08",
        "nepDate": "24.11.2039"
    },
    {
        "engDate": "1983-03-09",
        "nepDate": "25.11.2039"
    },
    {
        "engDate": "1983-03-10",
        "nepDate": "26.11.2039"
    },
    {
        "engDate": "1983-03-11",
        "nepDate": "27.11.2039"
    },
    {
        "engDate": "1983-03-12",
        "nepDate": "28.11.2039"
    },
    {
        "engDate": "1983-03-13",
        "nepDate": "29.11.2039"
    },
    {
        "engDate": "1983-03-14",
        "nepDate": "30.11.2039"
    },
    {
        "engDate": "1983-03-15",
        "nepDate": "01.12.2039"
    },
    {
        "engDate": "1983-03-16",
        "nepDate": "02.12.2039"
    },
    {
        "engDate": "1983-03-17",
        "nepDate": "03.12.2039"
    },
    {
        "engDate": "1983-03-18",
        "nepDate": "04.12.2039"
    },
    {
        "engDate": "1983-03-19",
        "nepDate": "05.12.2039"
    },
    {
        "engDate": "1983-03-20",
        "nepDate": "06.12.2039"
    },
    {
        "engDate": "1983-03-21",
        "nepDate": "07.12.2039"
    },
    {
        "engDate": "1983-03-22",
        "nepDate": "08.12.2039"
    },
    {
        "engDate": "1983-03-23",
        "nepDate": "09.12.2039"
    },
    {
        "engDate": "1983-03-24",
        "nepDate": "10.12.2039"
    },
    {
        "engDate": "1983-03-25",
        "nepDate": "11.12.2039"
    },
    {
        "engDate": "1983-03-26",
        "nepDate": "12.12.2039"
    },
    {
        "engDate": "1983-03-27",
        "nepDate": "13.12.2039"
    },
    {
        "engDate": "1983-03-28",
        "nepDate": "14.12.2039"
    },
    {
        "engDate": "1983-03-29",
        "nepDate": "15.12.2039"
    },
    {
        "engDate": "1983-03-30",
        "nepDate": "16.12.2039"
    },
    {
        "engDate": "1983-03-31",
        "nepDate": "17.12.2039"
    },
    {
        "engDate": "1983-04-01",
        "nepDate": "18.12.2039"
    },
    {
        "engDate": "1983-04-02",
        "nepDate": "19.12.2039"
    },
    {
        "engDate": "1983-04-03",
        "nepDate": "20.12.2039"
    },
    {
        "engDate": "1983-04-04",
        "nepDate": "21.12.2039"
    },
    {
        "engDate": "1983-04-05",
        "nepDate": "22.12.2039"
    },
    {
        "engDate": "1983-04-06",
        "nepDate": "23.12.2039"
    },
    {
        "engDate": "1983-04-07",
        "nepDate": "24.12.2039"
    },
    {
        "engDate": "1983-04-08",
        "nepDate": "25.12.2039"
    },
    {
        "engDate": "1983-04-09",
        "nepDate": "26.12.2039"
    },
    {
        "engDate": "1983-04-10",
        "nepDate": "27.12.2039"
    },
    {
        "engDate": "1983-04-11",
        "nepDate": "28.12.2039"
    },
    {
        "engDate": "1983-04-12",
        "nepDate": "29.12.2039"
    },
    {
        "engDate": "1983-04-13",
        "nepDate": "30.12.2039"
    },
    {
        "engDate": "1983-04-14",
        "nepDate": "01.01.2040"
    },
    {
        "engDate": "1983-04-15",
        "nepDate": "02.01.2040"
    },
    {
        "engDate": "1983-04-16",
        "nepDate": "03.01.2040"
    },
    {
        "engDate": "1983-04-17",
        "nepDate": "04.01.2040"
    },
    {
        "engDate": "1983-04-18",
        "nepDate": "05.01.2040"
    },
    {
        "engDate": "1983-04-19",
        "nepDate": "06.01.2040"
    },
    {
        "engDate": "1983-04-20",
        "nepDate": "07.01.2040"
    },
    {
        "engDate": "1983-04-21",
        "nepDate": "08.01.2040"
    },
    {
        "engDate": "1983-04-22",
        "nepDate": "09.01.2040"
    },
    {
        "engDate": "1983-04-23",
        "nepDate": "10.01.2040"
    },
    {
        "engDate": "1983-04-24",
        "nepDate": "11.01.2040"
    },
    {
        "engDate": "1983-04-25",
        "nepDate": "12.01.2040"
    },
    {
        "engDate": "1983-04-26",
        "nepDate": "13.01.2040"
    },
    {
        "engDate": "1983-04-27",
        "nepDate": "14.01.2040"
    },
    {
        "engDate": "1983-04-28",
        "nepDate": "15.01.2040"
    },
    {
        "engDate": "1983-04-29",
        "nepDate": "16.01.2040"
    },
    {
        "engDate": "1983-04-30",
        "nepDate": "17.01.2040"
    },
    {
        "engDate": "1983-05-01",
        "nepDate": "18.01.2040"
    },
    {
        "engDate": "1983-05-02",
        "nepDate": "19.01.2040"
    },
    {
        "engDate": "1983-05-03",
        "nepDate": "20.01.2040"
    },
    {
        "engDate": "1983-05-04",
        "nepDate": "21.01.2040"
    },
    {
        "engDate": "1983-05-05",
        "nepDate": "22.01.2040"
    },
    {
        "engDate": "1983-05-06",
        "nepDate": "23.01.2040"
    },
    {
        "engDate": "1983-05-07",
        "nepDate": "24.01.2040"
    },
    {
        "engDate": "1983-05-08",
        "nepDate": "25.01.2040"
    },
    {
        "engDate": "1983-05-09",
        "nepDate": "26.01.2040"
    },
    {
        "engDate": "1983-05-10",
        "nepDate": "27.01.2040"
    },
    {
        "engDate": "1983-05-11",
        "nepDate": "28.01.2040"
    },
    {
        "engDate": "1983-05-12",
        "nepDate": "29.01.2040"
    },
    {
        "engDate": "1983-05-13",
        "nepDate": "30.01.2040"
    },
    {
        "engDate": "1983-05-14",
        "nepDate": "31.01.2040"
    },
    {
        "engDate": "1983-05-15",
        "nepDate": "01.02.2040"
    },
    {
        "engDate": "1983-05-16",
        "nepDate": "02.02.2040"
    },
    {
        "engDate": "1983-05-17",
        "nepDate": "03.02.2040"
    },
    {
        "engDate": "1983-05-18",
        "nepDate": "04.02.2040"
    },
    {
        "engDate": "1983-05-19",
        "nepDate": "05.02.2040"
    },
    {
        "engDate": "1983-05-20",
        "nepDate": "06.02.2040"
    },
    {
        "engDate": "1983-05-21",
        "nepDate": "07.02.2040"
    },
    {
        "engDate": "1983-05-22",
        "nepDate": "08.02.2040"
    },
    {
        "engDate": "1983-05-23",
        "nepDate": "09.02.2040"
    },
    {
        "engDate": "1983-05-24",
        "nepDate": "10.02.2040"
    },
    {
        "engDate": "1983-05-25",
        "nepDate": "11.02.2040"
    },
    {
        "engDate": "1983-05-26",
        "nepDate": "12.02.2040"
    },
    {
        "engDate": "1983-05-27",
        "nepDate": "13.02.2040"
    },
    {
        "engDate": "1983-05-28",
        "nepDate": "14.02.2040"
    },
    {
        "engDate": "1983-05-29",
        "nepDate": "15.02.2040"
    },
    {
        "engDate": "1983-05-30",
        "nepDate": "16.02.2040"
    },
    {
        "engDate": "1983-05-31",
        "nepDate": "17.02.2040"
    },
    {
        "engDate": "1983-06-01",
        "nepDate": "18.02.2040"
    },
    {
        "engDate": "1983-06-02",
        "nepDate": "19.02.2040"
    },
    {
        "engDate": "1983-06-03",
        "nepDate": "20.02.2040"
    },
    {
        "engDate": "1983-06-04",
        "nepDate": "21.02.2040"
    },
    {
        "engDate": "1983-06-05",
        "nepDate": "22.02.2040"
    },
    {
        "engDate": "1983-06-06",
        "nepDate": "23.02.2040"
    },
    {
        "engDate": "1983-06-07",
        "nepDate": "24.02.2040"
    },
    {
        "engDate": "1983-06-08",
        "nepDate": "25.02.2040"
    },
    {
        "engDate": "1983-06-09",
        "nepDate": "26.02.2040"
    },
    {
        "engDate": "1983-06-10",
        "nepDate": "27.02.2040"
    },
    {
        "engDate": "1983-06-11",
        "nepDate": "28.02.2040"
    },
    {
        "engDate": "1983-06-12",
        "nepDate": "29.02.2040"
    },
    {
        "engDate": "1983-06-13",
        "nepDate": "30.02.2040"
    },
    {
        "engDate": "1983-06-14",
        "nepDate": "31.02.2040"
    },
    {
        "engDate": "1983-06-15",
        "nepDate": "01.03.2040"
    },
    {
        "engDate": "1983-06-16",
        "nepDate": "02.03.2040"
    },
    {
        "engDate": "1983-06-17",
        "nepDate": "03.03.2040"
    },
    {
        "engDate": "1983-06-18",
        "nepDate": "04.03.2040"
    },
    {
        "engDate": "1983-06-19",
        "nepDate": "05.03.2040"
    },
    {
        "engDate": "1983-06-20",
        "nepDate": "06.03.2040"
    },
    {
        "engDate": "1983-06-21",
        "nepDate": "07.03.2040"
    },
    {
        "engDate": "1983-06-22",
        "nepDate": "08.03.2040"
    },
    {
        "engDate": "1983-06-23",
        "nepDate": "09.03.2040"
    },
    {
        "engDate": "1983-06-24",
        "nepDate": "10.03.2040"
    },
    {
        "engDate": "1983-06-25",
        "nepDate": "11.03.2040"
    },
    {
        "engDate": "1983-06-26",
        "nepDate": "12.03.2040"
    },
    {
        "engDate": "1983-06-27",
        "nepDate": "13.03.2040"
    },
    {
        "engDate": "1983-06-28",
        "nepDate": "14.03.2040"
    },
    {
        "engDate": "1983-06-29",
        "nepDate": "15.03.2040"
    },
    {
        "engDate": "1983-06-30",
        "nepDate": "16.03.2040"
    },
    {
        "engDate": "1983-07-01",
        "nepDate": "17.03.2040"
    },
    {
        "engDate": "1983-07-02",
        "nepDate": "18.03.2040"
    },
    {
        "engDate": "1983-07-03",
        "nepDate": "19.03.2040"
    },
    {
        "engDate": "1983-07-04",
        "nepDate": "20.03.2040"
    },
    {
        "engDate": "1983-07-05",
        "nepDate": "21.03.2040"
    },
    {
        "engDate": "1983-07-06",
        "nepDate": "22.03.2040"
    },
    {
        "engDate": "1983-07-07",
        "nepDate": "23.03.2040"
    },
    {
        "engDate": "1983-07-08",
        "nepDate": "24.03.2040"
    },
    {
        "engDate": "1983-07-09",
        "nepDate": "25.03.2040"
    },
    {
        "engDate": "1983-07-10",
        "nepDate": "26.03.2040"
    },
    {
        "engDate": "1983-07-11",
        "nepDate": "27.03.2040"
    },
    {
        "engDate": "1983-07-12",
        "nepDate": "28.03.2040"
    },
    {
        "engDate": "1983-07-13",
        "nepDate": "29.03.2040"
    },
    {
        "engDate": "1983-07-14",
        "nepDate": "30.03.2040"
    },
    {
        "engDate": "1983-07-15",
        "nepDate": "31.03.2040"
    },
    {
        "engDate": "1983-07-16",
        "nepDate": "32.03.2040"
    },
    {
        "engDate": "1983-07-17",
        "nepDate": "01.04.2040"
    },
    {
        "engDate": "1983-07-18",
        "nepDate": "02.04.2040"
    },
    {
        "engDate": "1983-07-19",
        "nepDate": "03.04.2040"
    },
    {
        "engDate": "1983-07-20",
        "nepDate": "04.04.2040"
    },
    {
        "engDate": "1983-07-21",
        "nepDate": "05.04.2040"
    },
    {
        "engDate": "1983-07-22",
        "nepDate": "06.04.2040"
    },
    {
        "engDate": "1983-07-23",
        "nepDate": "07.04.2040"
    },
    {
        "engDate": "1983-07-24",
        "nepDate": "08.04.2040"
    },
    {
        "engDate": "1983-07-25",
        "nepDate": "09.04.2040"
    },
    {
        "engDate": "1983-07-26",
        "nepDate": "10.04.2040"
    },
    {
        "engDate": "1983-07-27",
        "nepDate": "11.04.2040"
    },
    {
        "engDate": "1983-07-28",
        "nepDate": "12.04.2040"
    },
    {
        "engDate": "1983-07-29",
        "nepDate": "13.04.2040"
    },
    {
        "engDate": "1983-07-30",
        "nepDate": "14.04.2040"
    },
    {
        "engDate": "1983-07-31",
        "nepDate": "15.04.2040"
    },
    {
        "engDate": "1983-08-01",
        "nepDate": "16.04.2040"
    },
    {
        "engDate": "1983-08-02",
        "nepDate": "17.04.2040"
    },
    {
        "engDate": "1983-08-03",
        "nepDate": "18.04.2040"
    },
    {
        "engDate": "1983-08-04",
        "nepDate": "19.04.2040"
    },
    {
        "engDate": "1983-08-05",
        "nepDate": "20.04.2040"
    },
    {
        "engDate": "1983-08-06",
        "nepDate": "21.04.2040"
    },
    {
        "engDate": "1983-08-07",
        "nepDate": "22.04.2040"
    },
    {
        "engDate": "1983-08-08",
        "nepDate": "23.04.2040"
    },
    {
        "engDate": "1983-08-09",
        "nepDate": "24.04.2040"
    },
    {
        "engDate": "1983-08-10",
        "nepDate": "25.04.2040"
    },
    {
        "engDate": "1983-08-11",
        "nepDate": "26.04.2040"
    },
    {
        "engDate": "1983-08-12",
        "nepDate": "27.04.2040"
    },
    {
        "engDate": "1983-08-13",
        "nepDate": "28.04.2040"
    },
    {
        "engDate": "1983-08-14",
        "nepDate": "29.04.2040"
    },
    {
        "engDate": "1983-08-15",
        "nepDate": "30.04.2040"
    },
    {
        "engDate": "1983-08-16",
        "nepDate": "31.04.2040"
    },
    {
        "engDate": "1983-08-17",
        "nepDate": "01.05.2040"
    },
    {
        "engDate": "1983-08-18",
        "nepDate": "02.05.2040"
    },
    {
        "engDate": "1983-08-19",
        "nepDate": "03.05.2040"
    },
    {
        "engDate": "1983-08-20",
        "nepDate": "04.05.2040"
    },
    {
        "engDate": "1983-08-21",
        "nepDate": "05.05.2040"
    },
    {
        "engDate": "1983-08-22",
        "nepDate": "06.05.2040"
    },
    {
        "engDate": "1983-08-23",
        "nepDate": "07.05.2040"
    },
    {
        "engDate": "1983-08-24",
        "nepDate": "08.05.2040"
    },
    {
        "engDate": "1983-08-25",
        "nepDate": "09.05.2040"
    },
    {
        "engDate": "1983-08-26",
        "nepDate": "10.05.2040"
    },
    {
        "engDate": "1983-08-27",
        "nepDate": "11.05.2040"
    },
    {
        "engDate": "1983-08-28",
        "nepDate": "12.05.2040"
    },
    {
        "engDate": "1983-08-29",
        "nepDate": "13.05.2040"
    },
    {
        "engDate": "1983-08-30",
        "nepDate": "14.05.2040"
    },
    {
        "engDate": "1983-08-31",
        "nepDate": "15.05.2040"
    },
    {
        "engDate": "1983-09-01",
        "nepDate": "16.05.2040"
    },
    {
        "engDate": "1983-09-02",
        "nepDate": "17.05.2040"
    },
    {
        "engDate": "1983-09-03",
        "nepDate": "18.05.2040"
    },
    {
        "engDate": "1983-09-04",
        "nepDate": "19.05.2040"
    },
    {
        "engDate": "1983-09-05",
        "nepDate": "20.05.2040"
    },
    {
        "engDate": "1983-09-06",
        "nepDate": "21.05.2040"
    },
    {
        "engDate": "1983-09-07",
        "nepDate": "22.05.2040"
    },
    {
        "engDate": "1983-09-08",
        "nepDate": "23.05.2040"
    },
    {
        "engDate": "1983-09-09",
        "nepDate": "24.05.2040"
    },
    {
        "engDate": "1983-09-10",
        "nepDate": "25.05.2040"
    },
    {
        "engDate": "1983-09-11",
        "nepDate": "26.05.2040"
    },
    {
        "engDate": "1983-09-12",
        "nepDate": "27.05.2040"
    },
    {
        "engDate": "1983-09-13",
        "nepDate": "28.05.2040"
    },
    {
        "engDate": "1983-09-14",
        "nepDate": "29.05.2040"
    },
    {
        "engDate": "1983-09-15",
        "nepDate": "30.05.2040"
    },
    {
        "engDate": "1983-09-16",
        "nepDate": "31.05.2040"
    },
    {
        "engDate": "1983-09-17",
        "nepDate": "01.06.2040"
    },
    {
        "engDate": "1983-09-18",
        "nepDate": "02.06.2040"
    },
    {
        "engDate": "1983-09-19",
        "nepDate": "03.06.2040"
    },
    {
        "engDate": "1983-09-20",
        "nepDate": "04.06.2040"
    },
    {
        "engDate": "1983-09-21",
        "nepDate": "05.06.2040"
    },
    {
        "engDate": "1983-09-22",
        "nepDate": "06.06.2040"
    },
    {
        "engDate": "1983-09-23",
        "nepDate": "07.06.2040"
    },
    {
        "engDate": "1983-09-24",
        "nepDate": "08.06.2040"
    },
    {
        "engDate": "1983-09-25",
        "nepDate": "09.06.2040"
    },
    {
        "engDate": "1983-09-26",
        "nepDate": "10.06.2040"
    },
    {
        "engDate": "1983-09-27",
        "nepDate": "11.06.2040"
    },
    {
        "engDate": "1983-09-28",
        "nepDate": "12.06.2040"
    },
    {
        "engDate": "1983-09-29",
        "nepDate": "13.06.2040"
    },
    {
        "engDate": "1983-09-30",
        "nepDate": "14.06.2040"
    },
    {
        "engDate": "1983-10-01",
        "nepDate": "15.06.2040"
    },
    {
        "engDate": "1983-10-02",
        "nepDate": "16.06.2040"
    },
    {
        "engDate": "1983-10-03",
        "nepDate": "17.06.2040"
    },
    {
        "engDate": "1983-10-04",
        "nepDate": "18.06.2040"
    },
    {
        "engDate": "1983-10-05",
        "nepDate": "19.06.2040"
    },
    {
        "engDate": "1983-10-06",
        "nepDate": "20.06.2040"
    },
    {
        "engDate": "1983-10-07",
        "nepDate": "21.06.2040"
    },
    {
        "engDate": "1983-10-08",
        "nepDate": "22.06.2040"
    },
    {
        "engDate": "1983-10-09",
        "nepDate": "23.06.2040"
    },
    {
        "engDate": "1983-10-10",
        "nepDate": "24.06.2040"
    },
    {
        "engDate": "1983-10-11",
        "nepDate": "25.06.2040"
    },
    {
        "engDate": "1983-10-12",
        "nepDate": "26.06.2040"
    },
    {
        "engDate": "1983-10-13",
        "nepDate": "27.06.2040"
    },
    {
        "engDate": "1983-10-14",
        "nepDate": "28.06.2040"
    },
    {
        "engDate": "1983-10-15",
        "nepDate": "29.06.2040"
    },
    {
        "engDate": "1983-10-16",
        "nepDate": "30.06.2040"
    },
    {
        "engDate": "1983-10-17",
        "nepDate": "31.06.2040"
    },
    {
        "engDate": "1983-10-18",
        "nepDate": "01.07.2040"
    },
    {
        "engDate": "1983-10-19",
        "nepDate": "02.07.2040"
    },
    {
        "engDate": "1983-10-20",
        "nepDate": "03.07.2040"
    },
    {
        "engDate": "1983-10-21",
        "nepDate": "04.07.2040"
    },
    {
        "engDate": "1983-10-22",
        "nepDate": "05.07.2040"
    },
    {
        "engDate": "1983-10-23",
        "nepDate": "06.07.2040"
    },
    {
        "engDate": "1983-10-24",
        "nepDate": "07.07.2040"
    },
    {
        "engDate": "1983-10-25",
        "nepDate": "08.07.2040"
    },
    {
        "engDate": "1983-10-26",
        "nepDate": "09.07.2040"
    },
    {
        "engDate": "1983-10-27",
        "nepDate": "10.07.2040"
    },
    {
        "engDate": "1983-10-28",
        "nepDate": "11.07.2040"
    },
    {
        "engDate": "1983-10-29",
        "nepDate": "12.07.2040"
    },
    {
        "engDate": "1983-10-30",
        "nepDate": "13.07.2040"
    },
    {
        "engDate": "1983-10-31",
        "nepDate": "14.07.2040"
    },
    {
        "engDate": "1983-11-01",
        "nepDate": "15.07.2040"
    },
    {
        "engDate": "1983-11-02",
        "nepDate": "16.07.2040"
    },
    {
        "engDate": "1983-11-03",
        "nepDate": "17.07.2040"
    },
    {
        "engDate": "1983-11-04",
        "nepDate": "18.07.2040"
    },
    {
        "engDate": "1983-11-05",
        "nepDate": "19.07.2040"
    },
    {
        "engDate": "1983-11-06",
        "nepDate": "20.07.2040"
    },
    {
        "engDate": "1983-11-07",
        "nepDate": "21.07.2040"
    },
    {
        "engDate": "1983-11-08",
        "nepDate": "22.07.2040"
    },
    {
        "engDate": "1983-11-09",
        "nepDate": "23.07.2040"
    },
    {
        "engDate": "1983-11-10",
        "nepDate": "24.07.2040"
    },
    {
        "engDate": "1983-11-11",
        "nepDate": "25.07.2040"
    },
    {
        "engDate": "1983-11-12",
        "nepDate": "26.07.2040"
    },
    {
        "engDate": "1983-11-13",
        "nepDate": "27.07.2040"
    },
    {
        "engDate": "1983-11-14",
        "nepDate": "28.07.2040"
    },
    {
        "engDate": "1983-11-15",
        "nepDate": "29.07.2040"
    },
    {
        "engDate": "1983-11-16",
        "nepDate": "30.07.2040"
    },
    {
        "engDate": "1983-11-17",
        "nepDate": "01.08.2040"
    },
    {
        "engDate": "1983-11-18",
        "nepDate": "02.08.2040"
    },
    {
        "engDate": "1983-11-19",
        "nepDate": "03.08.2040"
    },
    {
        "engDate": "1983-11-20",
        "nepDate": "04.08.2040"
    },
    {
        "engDate": "1983-11-21",
        "nepDate": "05.08.2040"
    },
    {
        "engDate": "1983-11-22",
        "nepDate": "06.08.2040"
    },
    {
        "engDate": "1983-11-23",
        "nepDate": "07.08.2040"
    },
    {
        "engDate": "1983-11-24",
        "nepDate": "08.08.2040"
    },
    {
        "engDate": "1983-11-25",
        "nepDate": "09.08.2040"
    },
    {
        "engDate": "1983-11-26",
        "nepDate": "10.08.2040"
    },
    {
        "engDate": "1983-11-27",
        "nepDate": "11.08.2040"
    },
    {
        "engDate": "1983-11-28",
        "nepDate": "12.08.2040"
    },
    {
        "engDate": "1983-11-29",
        "nepDate": "13.08.2040"
    },
    {
        "engDate": "1983-11-30",
        "nepDate": "14.08.2040"
    },
    {
        "engDate": "1983-12-01",
        "nepDate": "15.08.2040"
    },
    {
        "engDate": "1983-12-02",
        "nepDate": "16.08.2040"
    },
    {
        "engDate": "1983-12-03",
        "nepDate": "17.08.2040"
    },
    {
        "engDate": "1983-12-04",
        "nepDate": "18.08.2040"
    },
    {
        "engDate": "1983-12-05",
        "nepDate": "19.08.2040"
    },
    {
        "engDate": "1983-12-06",
        "nepDate": "20.08.2040"
    },
    {
        "engDate": "1983-12-07",
        "nepDate": "21.08.2040"
    },
    {
        "engDate": "1983-12-08",
        "nepDate": "22.08.2040"
    },
    {
        "engDate": "1983-12-09",
        "nepDate": "23.08.2040"
    },
    {
        "engDate": "1983-12-10",
        "nepDate": "24.08.2040"
    },
    {
        "engDate": "1983-12-11",
        "nepDate": "25.08.2040"
    },
    {
        "engDate": "1983-12-12",
        "nepDate": "26.08.2040"
    },
    {
        "engDate": "1983-12-13",
        "nepDate": "27.08.2040"
    },
    {
        "engDate": "1983-12-14",
        "nepDate": "28.08.2040"
    },
    {
        "engDate": "1983-12-15",
        "nepDate": "29.08.2040"
    },
    {
        "engDate": "1983-12-16",
        "nepDate": "01.09.2040"
    },
    {
        "engDate": "1983-12-17",
        "nepDate": "02.09.2040"
    },
    {
        "engDate": "1983-12-18",
        "nepDate": "03.09.2040"
    },
    {
        "engDate": "1983-12-19",
        "nepDate": "04.09.2040"
    },
    {
        "engDate": "1983-12-20",
        "nepDate": "05.09.2040"
    },
    {
        "engDate": "1983-12-21",
        "nepDate": "06.09.2040"
    },
    {
        "engDate": "1983-12-22",
        "nepDate": "07.09.2040"
    },
    {
        "engDate": "1983-12-23",
        "nepDate": "08.09.2040"
    },
    {
        "engDate": "1983-12-24",
        "nepDate": "09.09.2040"
    },
    {
        "engDate": "1983-12-25",
        "nepDate": "10.09.2040"
    },
    {
        "engDate": "1983-12-26",
        "nepDate": "11.09.2040"
    },
    {
        "engDate": "1983-12-27",
        "nepDate": "12.09.2040"
    },
    {
        "engDate": "1983-12-28",
        "nepDate": "13.09.2040"
    },
    {
        "engDate": "1983-12-29",
        "nepDate": "14.09.2040"
    },
    {
        "engDate": "1983-12-30",
        "nepDate": "15.09.2040"
    },
    {
        "engDate": "1983-12-31",
        "nepDate": "16.09.2040"
    },
    {
        "engDate": "1984-01-01",
        "nepDate": "17.09.2040"
    },
    {
        "engDate": "1984-01-02",
        "nepDate": "18.09.2040"
    },
    {
        "engDate": "1984-01-03",
        "nepDate": "19.09.2040"
    },
    {
        "engDate": "1984-01-04",
        "nepDate": "20.09.2040"
    },
    {
        "engDate": "1984-01-05",
        "nepDate": "21.09.2040"
    },
    {
        "engDate": "1984-01-06",
        "nepDate": "22.09.2040"
    },
    {
        "engDate": "1984-01-07",
        "nepDate": "23.09.2040"
    },
    {
        "engDate": "1984-01-08",
        "nepDate": "24.09.2040"
    },
    {
        "engDate": "1984-01-09",
        "nepDate": "25.09.2040"
    },
    {
        "engDate": "1984-01-10",
        "nepDate": "26.09.2040"
    },
    {
        "engDate": "1984-01-11",
        "nepDate": "27.09.2040"
    },
    {
        "engDate": "1984-01-12",
        "nepDate": "28.09.2040"
    },
    {
        "engDate": "1984-01-13",
        "nepDate": "29.09.2040"
    },
    {
        "engDate": "1984-01-14",
        "nepDate": "30.09.2040"
    },
    {
        "engDate": "1984-01-15",
        "nepDate": "01.10.2040"
    },
    {
        "engDate": "1984-01-16",
        "nepDate": "02.10.2040"
    },
    {
        "engDate": "1984-01-17",
        "nepDate": "03.10.2040"
    },
    {
        "engDate": "1984-01-18",
        "nepDate": "04.10.2040"
    },
    {
        "engDate": "1984-01-19",
        "nepDate": "05.10.2040"
    },
    {
        "engDate": "1984-01-20",
        "nepDate": "06.10.2040"
    },
    {
        "engDate": "1984-01-21",
        "nepDate": "07.10.2040"
    },
    {
        "engDate": "1984-01-22",
        "nepDate": "08.10.2040"
    },
    {
        "engDate": "1984-01-23",
        "nepDate": "09.10.2040"
    },
    {
        "engDate": "1984-01-24",
        "nepDate": "10.10.2040"
    },
    {
        "engDate": "1984-01-25",
        "nepDate": "11.10.2040"
    },
    {
        "engDate": "1984-01-26",
        "nepDate": "12.10.2040"
    },
    {
        "engDate": "1984-01-27",
        "nepDate": "13.10.2040"
    },
    {
        "engDate": "1984-01-28",
        "nepDate": "14.10.2040"
    },
    {
        "engDate": "1984-01-29",
        "nepDate": "15.10.2040"
    },
    {
        "engDate": "1984-01-30",
        "nepDate": "16.10.2040"
    },
    {
        "engDate": "1984-01-31",
        "nepDate": "17.10.2040"
    },
    {
        "engDate": "1984-02-01",
        "nepDate": "18.10.2040"
    },
    {
        "engDate": "1984-02-02",
        "nepDate": "19.10.2040"
    },
    {
        "engDate": "1984-02-03",
        "nepDate": "20.10.2040"
    },
    {
        "engDate": "1984-02-04",
        "nepDate": "21.10.2040"
    },
    {
        "engDate": "1984-02-05",
        "nepDate": "22.10.2040"
    },
    {
        "engDate": "1984-02-06",
        "nepDate": "23.10.2040"
    },
    {
        "engDate": "1984-02-07",
        "nepDate": "24.10.2040"
    },
    {
        "engDate": "1984-02-08",
        "nepDate": "25.10.2040"
    },
    {
        "engDate": "1984-02-09",
        "nepDate": "26.10.2040"
    },
    {
        "engDate": "1984-02-10",
        "nepDate": "27.10.2040"
    },
    {
        "engDate": "1984-02-11",
        "nepDate": "28.10.2040"
    },
    {
        "engDate": "1984-02-12",
        "nepDate": "29.10.2040"
    },
    {
        "engDate": "1984-02-13",
        "nepDate": "01.11.2040"
    },
    {
        "engDate": "1984-02-14",
        "nepDate": "02.11.2040"
    },
    {
        "engDate": "1984-02-15",
        "nepDate": "03.11.2040"
    },
    {
        "engDate": "1984-02-16",
        "nepDate": "04.11.2040"
    },
    {
        "engDate": "1984-02-17",
        "nepDate": "05.11.2040"
    },
    {
        "engDate": "1984-02-18",
        "nepDate": "06.11.2040"
    },
    {
        "engDate": "1984-02-19",
        "nepDate": "07.11.2040"
    },
    {
        "engDate": "1984-02-20",
        "nepDate": "08.11.2040"
    },
    {
        "engDate": "1984-02-21",
        "nepDate": "09.11.2040"
    },
    {
        "engDate": "1984-02-22",
        "nepDate": "10.11.2040"
    },
    {
        "engDate": "1984-02-23",
        "nepDate": "11.11.2040"
    },
    {
        "engDate": "1984-02-24",
        "nepDate": "12.11.2040"
    },
    {
        "engDate": "1984-02-25",
        "nepDate": "13.11.2040"
    },
    {
        "engDate": "1984-02-26",
        "nepDate": "14.11.2040"
    },
    {
        "engDate": "1984-02-27",
        "nepDate": "15.11.2040"
    },
    {
        "engDate": "1984-02-28",
        "nepDate": "16.11.2040"
    },
    {
        "engDate": "1984-02-29",
        "nepDate": "17.11.2040"
    },
    {
        "engDate": "1984-03-01",
        "nepDate": "18.11.2040"
    },
    {
        "engDate": "1984-03-02",
        "nepDate": "19.11.2040"
    },
    {
        "engDate": "1984-03-03",
        "nepDate": "20.11.2040"
    },
    {
        "engDate": "1984-03-04",
        "nepDate": "21.11.2040"
    },
    {
        "engDate": "1984-03-05",
        "nepDate": "22.11.2040"
    },
    {
        "engDate": "1984-03-06",
        "nepDate": "23.11.2040"
    },
    {
        "engDate": "1984-03-07",
        "nepDate": "24.11.2040"
    },
    {
        "engDate": "1984-03-08",
        "nepDate": "25.11.2040"
    },
    {
        "engDate": "1984-03-09",
        "nepDate": "26.11.2040"
    },
    {
        "engDate": "1984-03-10",
        "nepDate": "27.11.2040"
    },
    {
        "engDate": "1984-03-11",
        "nepDate": "28.11.2040"
    },
    {
        "engDate": "1984-03-12",
        "nepDate": "29.11.2040"
    },
    {
        "engDate": "1984-03-13",
        "nepDate": "30.11.2040"
    },
    {
        "engDate": "1984-03-14",
        "nepDate": "01.12.2040"
    },
    {
        "engDate": "1984-03-15",
        "nepDate": "02.12.2040"
    },
    {
        "engDate": "1984-03-16",
        "nepDate": "03.12.2040"
    },
    {
        "engDate": "1984-03-17",
        "nepDate": "04.12.2040"
    },
    {
        "engDate": "1984-03-18",
        "nepDate": "05.12.2040"
    },
    {
        "engDate": "1984-03-19",
        "nepDate": "06.12.2040"
    },
    {
        "engDate": "1984-03-20",
        "nepDate": "07.12.2040"
    },
    {
        "engDate": "1984-03-21",
        "nepDate": "08.12.2040"
    },
    {
        "engDate": "1984-03-22",
        "nepDate": "09.12.2040"
    },
    {
        "engDate": "1984-03-23",
        "nepDate": "10.12.2040"
    },
    {
        "engDate": "1984-03-24",
        "nepDate": "11.12.2040"
    },
    {
        "engDate": "1984-03-25",
        "nepDate": "12.12.2040"
    },
    {
        "engDate": "1984-03-26",
        "nepDate": "13.12.2040"
    },
    {
        "engDate": "1984-03-27",
        "nepDate": "14.12.2040"
    },
    {
        "engDate": "1984-03-28",
        "nepDate": "15.12.2040"
    },
    {
        "engDate": "1984-03-29",
        "nepDate": "16.12.2040"
    },
    {
        "engDate": "1984-03-30",
        "nepDate": "17.12.2040"
    },
    {
        "engDate": "1984-03-31",
        "nepDate": "18.12.2040"
    },
    {
        "engDate": "1984-04-01",
        "nepDate": "19.12.2040"
    },
    {
        "engDate": "1984-04-02",
        "nepDate": "20.12.2040"
    },
    {
        "engDate": "1984-04-03",
        "nepDate": "21.12.2040"
    },
    {
        "engDate": "1984-04-04",
        "nepDate": "22.12.2040"
    },
    {
        "engDate": "1984-04-05",
        "nepDate": "23.12.2040"
    },
    {
        "engDate": "1984-04-06",
        "nepDate": "24.12.2040"
    },
    {
        "engDate": "1984-04-07",
        "nepDate": "25.12.2040"
    },
    {
        "engDate": "1984-04-08",
        "nepDate": "26.12.2040"
    },
    {
        "engDate": "1984-04-09",
        "nepDate": "27.12.2040"
    },
    {
        "engDate": "1984-04-10",
        "nepDate": "28.12.2040"
    },
    {
        "engDate": "1984-04-11",
        "nepDate": "29.12.2040"
    },
    {
        "engDate": "1984-04-12",
        "nepDate": "30.12.2040"
    },
    {
        "engDate": "1984-04-13",
        "nepDate": "01.01.2041"
    },
    {
        "engDate": "1984-04-14",
        "nepDate": "02.01.2041"
    },
    {
        "engDate": "1984-04-15",
        "nepDate": "03.01.2041"
    },
    {
        "engDate": "1984-04-16",
        "nepDate": "04.01.2041"
    },
    {
        "engDate": "1984-04-17",
        "nepDate": "05.01.2041"
    },
    {
        "engDate": "1984-04-18",
        "nepDate": "06.01.2041"
    },
    {
        "engDate": "1984-04-19",
        "nepDate": "07.01.2041"
    },
    {
        "engDate": "1984-04-20",
        "nepDate": "08.01.2041"
    },
    {
        "engDate": "1984-04-21",
        "nepDate": "09.01.2041"
    },
    {
        "engDate": "1984-04-22",
        "nepDate": "10.01.2041"
    },
    {
        "engDate": "1984-04-23",
        "nepDate": "11.01.2041"
    },
    {
        "engDate": "1984-04-24",
        "nepDate": "12.01.2041"
    },
    {
        "engDate": "1984-04-25",
        "nepDate": "13.01.2041"
    },
    {
        "engDate": "1984-04-26",
        "nepDate": "14.01.2041"
    },
    {
        "engDate": "1984-04-27",
        "nepDate": "15.01.2041"
    },
    {
        "engDate": "1984-04-28",
        "nepDate": "16.01.2041"
    },
    {
        "engDate": "1984-04-29",
        "nepDate": "17.01.2041"
    },
    {
        "engDate": "1984-04-30",
        "nepDate": "18.01.2041"
    },
    {
        "engDate": "1984-05-01",
        "nepDate": "19.01.2041"
    },
    {
        "engDate": "1984-05-02",
        "nepDate": "20.01.2041"
    },
    {
        "engDate": "1984-05-03",
        "nepDate": "21.01.2041"
    },
    {
        "engDate": "1984-05-04",
        "nepDate": "22.01.2041"
    },
    {
        "engDate": "1984-05-05",
        "nepDate": "23.01.2041"
    },
    {
        "engDate": "1984-05-06",
        "nepDate": "24.01.2041"
    },
    {
        "engDate": "1984-05-07",
        "nepDate": "25.01.2041"
    },
    {
        "engDate": "1984-05-08",
        "nepDate": "26.01.2041"
    },
    {
        "engDate": "1984-05-09",
        "nepDate": "27.01.2041"
    },
    {
        "engDate": "1984-05-10",
        "nepDate": "28.01.2041"
    },
    {
        "engDate": "1984-05-11",
        "nepDate": "29.01.2041"
    },
    {
        "engDate": "1984-05-12",
        "nepDate": "30.01.2041"
    },
    {
        "engDate": "1984-05-13",
        "nepDate": "31.01.2041"
    },
    {
        "engDate": "1984-05-14",
        "nepDate": "01.02.2041"
    },
    {
        "engDate": "1984-05-15",
        "nepDate": "02.02.2041"
    },
    {
        "engDate": "1984-05-16",
        "nepDate": "03.02.2041"
    },
    {
        "engDate": "1984-05-17",
        "nepDate": "04.02.2041"
    },
    {
        "engDate": "1984-05-18",
        "nepDate": "05.02.2041"
    },
    {
        "engDate": "1984-05-19",
        "nepDate": "06.02.2041"
    },
    {
        "engDate": "1984-05-20",
        "nepDate": "07.02.2041"
    },
    {
        "engDate": "1984-05-21",
        "nepDate": "08.02.2041"
    },
    {
        "engDate": "1984-05-22",
        "nepDate": "09.02.2041"
    },
    {
        "engDate": "1984-05-23",
        "nepDate": "10.02.2041"
    },
    {
        "engDate": "1984-05-24",
        "nepDate": "11.02.2041"
    },
    {
        "engDate": "1984-05-25",
        "nepDate": "12.02.2041"
    },
    {
        "engDate": "1984-05-26",
        "nepDate": "13.02.2041"
    },
    {
        "engDate": "1984-05-27",
        "nepDate": "14.02.2041"
    },
    {
        "engDate": "1984-05-28",
        "nepDate": "15.02.2041"
    },
    {
        "engDate": "1984-05-29",
        "nepDate": "16.02.2041"
    },
    {
        "engDate": "1984-05-30",
        "nepDate": "17.02.2041"
    },
    {
        "engDate": "1984-05-31",
        "nepDate": "18.02.2041"
    },
    {
        "engDate": "1984-06-01",
        "nepDate": "19.02.2041"
    },
    {
        "engDate": "1984-06-02",
        "nepDate": "20.02.2041"
    },
    {
        "engDate": "1984-06-03",
        "nepDate": "21.02.2041"
    },
    {
        "engDate": "1984-06-04",
        "nepDate": "22.02.2041"
    },
    {
        "engDate": "1984-06-05",
        "nepDate": "23.02.2041"
    },
    {
        "engDate": "1984-06-06",
        "nepDate": "24.02.2041"
    },
    {
        "engDate": "1984-06-07",
        "nepDate": "25.02.2041"
    },
    {
        "engDate": "1984-06-08",
        "nepDate": "26.02.2041"
    },
    {
        "engDate": "1984-06-09",
        "nepDate": "27.02.2041"
    },
    {
        "engDate": "1984-06-10",
        "nepDate": "28.02.2041"
    },
    {
        "engDate": "1984-06-11",
        "nepDate": "29.02.2041"
    },
    {
        "engDate": "1984-06-12",
        "nepDate": "30.02.2041"
    },
    {
        "engDate": "1984-06-13",
        "nepDate": "31.02.2041"
    },
    {
        "engDate": "1984-06-14",
        "nepDate": "01.03.2041"
    },
    {
        "engDate": "1984-06-15",
        "nepDate": "02.03.2041"
    },
    {
        "engDate": "1984-06-16",
        "nepDate": "03.03.2041"
    },
    {
        "engDate": "1984-06-17",
        "nepDate": "04.03.2041"
    },
    {
        "engDate": "1984-06-18",
        "nepDate": "05.03.2041"
    },
    {
        "engDate": "1984-06-19",
        "nepDate": "06.03.2041"
    },
    {
        "engDate": "1984-06-20",
        "nepDate": "07.03.2041"
    },
    {
        "engDate": "1984-06-21",
        "nepDate": "08.03.2041"
    },
    {
        "engDate": "1984-06-22",
        "nepDate": "09.03.2041"
    },
    {
        "engDate": "1984-06-23",
        "nepDate": "10.03.2041"
    },
    {
        "engDate": "1984-06-24",
        "nepDate": "11.03.2041"
    },
    {
        "engDate": "1984-06-25",
        "nepDate": "12.03.2041"
    },
    {
        "engDate": "1984-06-26",
        "nepDate": "13.03.2041"
    },
    {
        "engDate": "1984-06-27",
        "nepDate": "14.03.2041"
    },
    {
        "engDate": "1984-06-28",
        "nepDate": "15.03.2041"
    },
    {
        "engDate": "1984-06-29",
        "nepDate": "16.03.2041"
    },
    {
        "engDate": "1984-06-30",
        "nepDate": "17.03.2041"
    },
    {
        "engDate": "1984-07-01",
        "nepDate": "18.03.2041"
    },
    {
        "engDate": "1984-07-02",
        "nepDate": "19.03.2041"
    },
    {
        "engDate": "1984-07-03",
        "nepDate": "20.03.2041"
    },
    {
        "engDate": "1984-07-04",
        "nepDate": "21.03.2041"
    },
    {
        "engDate": "1984-07-05",
        "nepDate": "22.03.2041"
    },
    {
        "engDate": "1984-07-06",
        "nepDate": "23.03.2041"
    },
    {
        "engDate": "1984-07-07",
        "nepDate": "24.03.2041"
    },
    {
        "engDate": "1984-07-08",
        "nepDate": "25.03.2041"
    },
    {
        "engDate": "1984-07-09",
        "nepDate": "26.03.2041"
    },
    {
        "engDate": "1984-07-10",
        "nepDate": "27.03.2041"
    },
    {
        "engDate": "1984-07-11",
        "nepDate": "28.03.2041"
    },
    {
        "engDate": "1984-07-12",
        "nepDate": "29.03.2041"
    },
    {
        "engDate": "1984-07-13",
        "nepDate": "30.03.2041"
    },
    {
        "engDate": "1984-07-14",
        "nepDate": "31.03.2041"
    },
    {
        "engDate": "1984-07-15",
        "nepDate": "32.03.2041"
    },
    {
        "engDate": "1984-07-16",
        "nepDate": "01.04.2041"
    },
    {
        "engDate": "1984-07-17",
        "nepDate": "02.04.2041"
    },
    {
        "engDate": "1984-07-18",
        "nepDate": "03.04.2041"
    },
    {
        "engDate": "1984-07-19",
        "nepDate": "04.04.2041"
    },
    {
        "engDate": "1984-07-20",
        "nepDate": "05.04.2041"
    },
    {
        "engDate": "1984-07-21",
        "nepDate": "06.04.2041"
    },
    {
        "engDate": "1984-07-22",
        "nepDate": "07.04.2041"
    },
    {
        "engDate": "1984-07-23",
        "nepDate": "08.04.2041"
    },
    {
        "engDate": "1984-07-24",
        "nepDate": "09.04.2041"
    },
    {
        "engDate": "1984-07-25",
        "nepDate": "10.04.2041"
    },
    {
        "engDate": "1984-07-26",
        "nepDate": "11.04.2041"
    },
    {
        "engDate": "1984-07-27",
        "nepDate": "12.04.2041"
    },
    {
        "engDate": "1984-07-28",
        "nepDate": "13.04.2041"
    },
    {
        "engDate": "1984-07-29",
        "nepDate": "14.04.2041"
    },
    {
        "engDate": "1984-07-30",
        "nepDate": "15.04.2041"
    },
    {
        "engDate": "1984-07-31",
        "nepDate": "16.04.2041"
    },
    {
        "engDate": "1984-08-01",
        "nepDate": "17.04.2041"
    },
    {
        "engDate": "1984-08-02",
        "nepDate": "18.04.2041"
    },
    {
        "engDate": "1984-08-03",
        "nepDate": "19.04.2041"
    },
    {
        "engDate": "1984-08-04",
        "nepDate": "20.04.2041"
    },
    {
        "engDate": "1984-08-05",
        "nepDate": "21.04.2041"
    },
    {
        "engDate": "1984-08-06",
        "nepDate": "22.04.2041"
    },
    {
        "engDate": "1984-08-07",
        "nepDate": "23.04.2041"
    },
    {
        "engDate": "1984-08-08",
        "nepDate": "24.04.2041"
    },
    {
        "engDate": "1984-08-09",
        "nepDate": "25.04.2041"
    },
    {
        "engDate": "1984-08-10",
        "nepDate": "26.04.2041"
    },
    {
        "engDate": "1984-08-11",
        "nepDate": "27.04.2041"
    },
    {
        "engDate": "1984-08-12",
        "nepDate": "28.04.2041"
    },
    {
        "engDate": "1984-08-13",
        "nepDate": "29.04.2041"
    },
    {
        "engDate": "1984-08-14",
        "nepDate": "30.04.2041"
    },
    {
        "engDate": "1984-08-15",
        "nepDate": "31.04.2041"
    },
    {
        "engDate": "1984-08-16",
        "nepDate": "32.04.2041"
    },
    {
        "engDate": "1984-08-17",
        "nepDate": "01.05.2041"
    },
    {
        "engDate": "1984-08-18",
        "nepDate": "02.05.2041"
    },
    {
        "engDate": "1984-08-19",
        "nepDate": "03.05.2041"
    },
    {
        "engDate": "1984-08-20",
        "nepDate": "04.05.2041"
    },
    {
        "engDate": "1984-08-21",
        "nepDate": "05.05.2041"
    },
    {
        "engDate": "1984-08-22",
        "nepDate": "06.05.2041"
    },
    {
        "engDate": "1984-08-23",
        "nepDate": "07.05.2041"
    },
    {
        "engDate": "1984-08-24",
        "nepDate": "08.05.2041"
    },
    {
        "engDate": "1984-08-25",
        "nepDate": "09.05.2041"
    },
    {
        "engDate": "1984-08-26",
        "nepDate": "10.05.2041"
    },
    {
        "engDate": "1984-08-27",
        "nepDate": "11.05.2041"
    },
    {
        "engDate": "1984-08-28",
        "nepDate": "12.05.2041"
    },
    {
        "engDate": "1984-08-29",
        "nepDate": "13.05.2041"
    },
    {
        "engDate": "1984-08-30",
        "nepDate": "14.05.2041"
    },
    {
        "engDate": "1984-08-31",
        "nepDate": "15.05.2041"
    },
    {
        "engDate": "1984-09-01",
        "nepDate": "16.05.2041"
    },
    {
        "engDate": "1984-09-02",
        "nepDate": "17.05.2041"
    },
    {
        "engDate": "1984-09-03",
        "nepDate": "18.05.2041"
    },
    {
        "engDate": "1984-09-04",
        "nepDate": "19.05.2041"
    },
    {
        "engDate": "1984-09-05",
        "nepDate": "20.05.2041"
    },
    {
        "engDate": "1984-09-06",
        "nepDate": "21.05.2041"
    },
    {
        "engDate": "1984-09-07",
        "nepDate": "22.05.2041"
    },
    {
        "engDate": "1984-09-08",
        "nepDate": "23.05.2041"
    },
    {
        "engDate": "1984-09-09",
        "nepDate": "24.05.2041"
    },
    {
        "engDate": "1984-09-10",
        "nepDate": "25.05.2041"
    },
    {
        "engDate": "1984-09-11",
        "nepDate": "26.05.2041"
    },
    {
        "engDate": "1984-09-12",
        "nepDate": "27.05.2041"
    },
    {
        "engDate": "1984-09-13",
        "nepDate": "28.05.2041"
    },
    {
        "engDate": "1984-09-14",
        "nepDate": "29.05.2041"
    },
    {
        "engDate": "1984-09-15",
        "nepDate": "30.05.2041"
    },
    {
        "engDate": "1984-09-16",
        "nepDate": "31.05.2041"
    },
    {
        "engDate": "1984-09-17",
        "nepDate": "01.06.2041"
    },
    {
        "engDate": "1984-09-18",
        "nepDate": "02.06.2041"
    },
    {
        "engDate": "1984-09-19",
        "nepDate": "03.06.2041"
    },
    {
        "engDate": "1984-09-20",
        "nepDate": "04.06.2041"
    },
    {
        "engDate": "1984-09-21",
        "nepDate": "05.06.2041"
    },
    {
        "engDate": "1984-09-22",
        "nepDate": "06.06.2041"
    },
    {
        "engDate": "1984-09-23",
        "nepDate": "07.06.2041"
    },
    {
        "engDate": "1984-09-24",
        "nepDate": "08.06.2041"
    },
    {
        "engDate": "1984-09-25",
        "nepDate": "09.06.2041"
    },
    {
        "engDate": "1984-09-26",
        "nepDate": "10.06.2041"
    },
    {
        "engDate": "1984-09-27",
        "nepDate": "11.06.2041"
    },
    {
        "engDate": "1984-09-28",
        "nepDate": "12.06.2041"
    },
    {
        "engDate": "1984-09-29",
        "nepDate": "13.06.2041"
    },
    {
        "engDate": "1984-09-30",
        "nepDate": "14.06.2041"
    },
    {
        "engDate": "1984-10-01",
        "nepDate": "15.06.2041"
    },
    {
        "engDate": "1984-10-02",
        "nepDate": "16.06.2041"
    },
    {
        "engDate": "1984-10-03",
        "nepDate": "17.06.2041"
    },
    {
        "engDate": "1984-10-04",
        "nepDate": "18.06.2041"
    },
    {
        "engDate": "1984-10-05",
        "nepDate": "19.06.2041"
    },
    {
        "engDate": "1984-10-06",
        "nepDate": "20.06.2041"
    },
    {
        "engDate": "1984-10-07",
        "nepDate": "21.06.2041"
    },
    {
        "engDate": "1984-10-08",
        "nepDate": "22.06.2041"
    },
    {
        "engDate": "1984-10-09",
        "nepDate": "23.06.2041"
    },
    {
        "engDate": "1984-10-10",
        "nepDate": "24.06.2041"
    },
    {
        "engDate": "1984-10-11",
        "nepDate": "25.06.2041"
    },
    {
        "engDate": "1984-10-12",
        "nepDate": "26.06.2041"
    },
    {
        "engDate": "1984-10-13",
        "nepDate": "27.06.2041"
    },
    {
        "engDate": "1984-10-14",
        "nepDate": "28.06.2041"
    },
    {
        "engDate": "1984-10-15",
        "nepDate": "29.06.2041"
    },
    {
        "engDate": "1984-10-16",
        "nepDate": "30.06.2041"
    },
    {
        "engDate": "1984-10-17",
        "nepDate": "01.07.2041"
    },
    {
        "engDate": "1984-10-18",
        "nepDate": "02.07.2041"
    },
    {
        "engDate": "1984-10-19",
        "nepDate": "03.07.2041"
    },
    {
        "engDate": "1984-10-20",
        "nepDate": "04.07.2041"
    },
    {
        "engDate": "1984-10-21",
        "nepDate": "05.07.2041"
    },
    {
        "engDate": "1984-10-22",
        "nepDate": "06.07.2041"
    },
    {
        "engDate": "1984-10-23",
        "nepDate": "07.07.2041"
    },
    {
        "engDate": "1984-10-24",
        "nepDate": "08.07.2041"
    },
    {
        "engDate": "1984-10-25",
        "nepDate": "09.07.2041"
    },
    {
        "engDate": "1984-10-26",
        "nepDate": "10.07.2041"
    },
    {
        "engDate": "1984-10-27",
        "nepDate": "11.07.2041"
    },
    {
        "engDate": "1984-10-28",
        "nepDate": "12.07.2041"
    },
    {
        "engDate": "1984-10-29",
        "nepDate": "13.07.2041"
    },
    {
        "engDate": "1984-10-30",
        "nepDate": "14.07.2041"
    },
    {
        "engDate": "1984-10-31",
        "nepDate": "15.07.2041"
    },
    {
        "engDate": "1984-11-01",
        "nepDate": "16.07.2041"
    },
    {
        "engDate": "1984-11-02",
        "nepDate": "17.07.2041"
    },
    {
        "engDate": "1984-11-03",
        "nepDate": "18.07.2041"
    },
    {
        "engDate": "1984-11-04",
        "nepDate": "19.07.2041"
    },
    {
        "engDate": "1984-11-05",
        "nepDate": "20.07.2041"
    },
    {
        "engDate": "1984-11-06",
        "nepDate": "21.07.2041"
    },
    {
        "engDate": "1984-11-07",
        "nepDate": "22.07.2041"
    },
    {
        "engDate": "1984-11-08",
        "nepDate": "23.07.2041"
    },
    {
        "engDate": "1984-11-09",
        "nepDate": "24.07.2041"
    },
    {
        "engDate": "1984-11-10",
        "nepDate": "25.07.2041"
    },
    {
        "engDate": "1984-11-11",
        "nepDate": "26.07.2041"
    },
    {
        "engDate": "1984-11-12",
        "nepDate": "27.07.2041"
    },
    {
        "engDate": "1984-11-13",
        "nepDate": "28.07.2041"
    },
    {
        "engDate": "1984-11-14",
        "nepDate": "29.07.2041"
    },
    {
        "engDate": "1984-11-15",
        "nepDate": "30.07.2041"
    },
    {
        "engDate": "1984-11-16",
        "nepDate": "01.08.2041"
    },
    {
        "engDate": "1984-11-17",
        "nepDate": "02.08.2041"
    },
    {
        "engDate": "1984-11-18",
        "nepDate": "03.08.2041"
    },
    {
        "engDate": "1984-11-19",
        "nepDate": "04.08.2041"
    },
    {
        "engDate": "1984-11-20",
        "nepDate": "05.08.2041"
    },
    {
        "engDate": "1984-11-21",
        "nepDate": "06.08.2041"
    },
    {
        "engDate": "1984-11-22",
        "nepDate": "07.08.2041"
    },
    {
        "engDate": "1984-11-23",
        "nepDate": "08.08.2041"
    },
    {
        "engDate": "1984-11-24",
        "nepDate": "09.08.2041"
    },
    {
        "engDate": "1984-11-25",
        "nepDate": "10.08.2041"
    },
    {
        "engDate": "1984-11-26",
        "nepDate": "11.08.2041"
    },
    {
        "engDate": "1984-11-27",
        "nepDate": "12.08.2041"
    },
    {
        "engDate": "1984-11-28",
        "nepDate": "13.08.2041"
    },
    {
        "engDate": "1984-11-29",
        "nepDate": "14.08.2041"
    },
    {
        "engDate": "1984-11-30",
        "nepDate": "15.08.2041"
    },
    {
        "engDate": "1984-12-01",
        "nepDate": "16.08.2041"
    },
    {
        "engDate": "1984-12-02",
        "nepDate": "17.08.2041"
    },
    {
        "engDate": "1984-12-03",
        "nepDate": "18.08.2041"
    },
    {
        "engDate": "1984-12-04",
        "nepDate": "19.08.2041"
    },
    {
        "engDate": "1984-12-05",
        "nepDate": "20.08.2041"
    },
    {
        "engDate": "1984-12-06",
        "nepDate": "21.08.2041"
    },
    {
        "engDate": "1984-12-07",
        "nepDate": "22.08.2041"
    },
    {
        "engDate": "1984-12-08",
        "nepDate": "23.08.2041"
    },
    {
        "engDate": "1984-12-09",
        "nepDate": "24.08.2041"
    },
    {
        "engDate": "1984-12-10",
        "nepDate": "25.08.2041"
    },
    {
        "engDate": "1984-12-11",
        "nepDate": "26.08.2041"
    },
    {
        "engDate": "1984-12-12",
        "nepDate": "27.08.2041"
    },
    {
        "engDate": "1984-12-13",
        "nepDate": "28.08.2041"
    },
    {
        "engDate": "1984-12-14",
        "nepDate": "29.08.2041"
    },
    {
        "engDate": "1984-12-15",
        "nepDate": "01.09.2041"
    },
    {
        "engDate": "1984-12-16",
        "nepDate": "02.09.2041"
    },
    {
        "engDate": "1984-12-17",
        "nepDate": "03.09.2041"
    },
    {
        "engDate": "1984-12-18",
        "nepDate": "04.09.2041"
    },
    {
        "engDate": "1984-12-19",
        "nepDate": "05.09.2041"
    },
    {
        "engDate": "1984-12-20",
        "nepDate": "06.09.2041"
    },
    {
        "engDate": "1984-12-21",
        "nepDate": "07.09.2041"
    },
    {
        "engDate": "1984-12-22",
        "nepDate": "08.09.2041"
    },
    {
        "engDate": "1984-12-23",
        "nepDate": "09.09.2041"
    },
    {
        "engDate": "1984-12-24",
        "nepDate": "10.09.2041"
    },
    {
        "engDate": "1984-12-25",
        "nepDate": "11.09.2041"
    },
    {
        "engDate": "1984-12-26",
        "nepDate": "12.09.2041"
    },
    {
        "engDate": "1984-12-27",
        "nepDate": "13.09.2041"
    },
    {
        "engDate": "1984-12-28",
        "nepDate": "14.09.2041"
    },
    {
        "engDate": "1984-12-29",
        "nepDate": "15.09.2041"
    },
    {
        "engDate": "1984-12-30",
        "nepDate": "16.09.2041"
    },
    {
        "engDate": "1984-12-31",
        "nepDate": "17.09.2041"
    },
    {
        "engDate": "1985-01-01",
        "nepDate": "18.09.2041"
    },
    {
        "engDate": "1985-01-02",
        "nepDate": "19.09.2041"
    },
    {
        "engDate": "1985-01-03",
        "nepDate": "20.09.2041"
    },
    {
        "engDate": "1985-01-04",
        "nepDate": "21.09.2041"
    },
    {
        "engDate": "1985-01-05",
        "nepDate": "22.09.2041"
    },
    {
        "engDate": "1985-01-06",
        "nepDate": "23.09.2041"
    },
    {
        "engDate": "1985-01-07",
        "nepDate": "24.09.2041"
    },
    {
        "engDate": "1985-01-08",
        "nepDate": "25.09.2041"
    },
    {
        "engDate": "1985-01-09",
        "nepDate": "26.09.2041"
    },
    {
        "engDate": "1985-01-10",
        "nepDate": "27.09.2041"
    },
    {
        "engDate": "1985-01-11",
        "nepDate": "28.09.2041"
    },
    {
        "engDate": "1985-01-12",
        "nepDate": "29.09.2041"
    },
    {
        "engDate": "1985-01-13",
        "nepDate": "30.09.2041"
    },
    {
        "engDate": "1985-01-14",
        "nepDate": "01.10.2041"
    },
    {
        "engDate": "1985-01-15",
        "nepDate": "02.10.2041"
    },
    {
        "engDate": "1985-01-16",
        "nepDate": "03.10.2041"
    },
    {
        "engDate": "1985-01-17",
        "nepDate": "04.10.2041"
    },
    {
        "engDate": "1985-01-18",
        "nepDate": "05.10.2041"
    },
    {
        "engDate": "1985-01-19",
        "nepDate": "06.10.2041"
    },
    {
        "engDate": "1985-01-20",
        "nepDate": "07.10.2041"
    },
    {
        "engDate": "1985-01-21",
        "nepDate": "08.10.2041"
    },
    {
        "engDate": "1985-01-22",
        "nepDate": "09.10.2041"
    },
    {
        "engDate": "1985-01-23",
        "nepDate": "10.10.2041"
    },
    {
        "engDate": "1985-01-24",
        "nepDate": "11.10.2041"
    },
    {
        "engDate": "1985-01-25",
        "nepDate": "12.10.2041"
    },
    {
        "engDate": "1985-01-26",
        "nepDate": "13.10.2041"
    },
    {
        "engDate": "1985-01-27",
        "nepDate": "14.10.2041"
    },
    {
        "engDate": "1985-01-28",
        "nepDate": "15.10.2041"
    },
    {
        "engDate": "1985-01-29",
        "nepDate": "16.10.2041"
    },
    {
        "engDate": "1985-01-30",
        "nepDate": "17.10.2041"
    },
    {
        "engDate": "1985-01-31",
        "nepDate": "18.10.2041"
    },
    {
        "engDate": "1985-02-01",
        "nepDate": "19.10.2041"
    },
    {
        "engDate": "1985-02-02",
        "nepDate": "20.10.2041"
    },
    {
        "engDate": "1985-02-03",
        "nepDate": "21.10.2041"
    },
    {
        "engDate": "1985-02-04",
        "nepDate": "22.10.2041"
    },
    {
        "engDate": "1985-02-05",
        "nepDate": "23.10.2041"
    },
    {
        "engDate": "1985-02-06",
        "nepDate": "24.10.2041"
    },
    {
        "engDate": "1985-02-07",
        "nepDate": "25.10.2041"
    },
    {
        "engDate": "1985-02-08",
        "nepDate": "26.10.2041"
    },
    {
        "engDate": "1985-02-09",
        "nepDate": "27.10.2041"
    },
    {
        "engDate": "1985-02-10",
        "nepDate": "28.10.2041"
    },
    {
        "engDate": "1985-02-11",
        "nepDate": "29.10.2041"
    },
    {
        "engDate": "1985-02-12",
        "nepDate": "01.11.2041"
    },
    {
        "engDate": "1985-02-13",
        "nepDate": "02.11.2041"
    },
    {
        "engDate": "1985-02-14",
        "nepDate": "03.11.2041"
    },
    {
        "engDate": "1985-02-15",
        "nepDate": "04.11.2041"
    },
    {
        "engDate": "1985-02-16",
        "nepDate": "05.11.2041"
    },
    {
        "engDate": "1985-02-17",
        "nepDate": "06.11.2041"
    },
    {
        "engDate": "1985-02-18",
        "nepDate": "07.11.2041"
    },
    {
        "engDate": "1985-02-19",
        "nepDate": "08.11.2041"
    },
    {
        "engDate": "1985-02-20",
        "nepDate": "09.11.2041"
    },
    {
        "engDate": "1985-02-21",
        "nepDate": "10.11.2041"
    },
    {
        "engDate": "1985-02-22",
        "nepDate": "11.11.2041"
    },
    {
        "engDate": "1985-02-23",
        "nepDate": "12.11.2041"
    },
    {
        "engDate": "1985-02-24",
        "nepDate": "13.11.2041"
    },
    {
        "engDate": "1985-02-25",
        "nepDate": "14.11.2041"
    },
    {
        "engDate": "1985-02-26",
        "nepDate": "15.11.2041"
    },
    {
        "engDate": "1985-02-27",
        "nepDate": "16.11.2041"
    },
    {
        "engDate": "1985-02-28",
        "nepDate": "17.11.2041"
    },
    {
        "engDate": "1985-03-01",
        "nepDate": "18.11.2041"
    },
    {
        "engDate": "1985-03-02",
        "nepDate": "19.11.2041"
    },
    {
        "engDate": "1985-03-03",
        "nepDate": "20.11.2041"
    },
    {
        "engDate": "1985-03-04",
        "nepDate": "21.11.2041"
    },
    {
        "engDate": "1985-03-05",
        "nepDate": "22.11.2041"
    },
    {
        "engDate": "1985-03-06",
        "nepDate": "23.11.2041"
    },
    {
        "engDate": "1985-03-07",
        "nepDate": "24.11.2041"
    },
    {
        "engDate": "1985-03-08",
        "nepDate": "25.11.2041"
    },
    {
        "engDate": "1985-03-09",
        "nepDate": "26.11.2041"
    },
    {
        "engDate": "1985-03-10",
        "nepDate": "27.11.2041"
    },
    {
        "engDate": "1985-03-11",
        "nepDate": "28.11.2041"
    },
    {
        "engDate": "1985-03-12",
        "nepDate": "29.11.2041"
    },
    {
        "engDate": "1985-03-13",
        "nepDate": "30.11.2041"
    },
    {
        "engDate": "1985-03-14",
        "nepDate": "01.12.2041"
    },
    {
        "engDate": "1985-03-15",
        "nepDate": "02.12.2041"
    },
    {
        "engDate": "1985-03-16",
        "nepDate": "03.12.2041"
    },
    {
        "engDate": "1985-03-17",
        "nepDate": "04.12.2041"
    },
    {
        "engDate": "1985-03-18",
        "nepDate": "05.12.2041"
    },
    {
        "engDate": "1985-03-19",
        "nepDate": "06.12.2041"
    },
    {
        "engDate": "1985-03-20",
        "nepDate": "07.12.2041"
    },
    {
        "engDate": "1985-03-21",
        "nepDate": "08.12.2041"
    },
    {
        "engDate": "1985-03-22",
        "nepDate": "09.12.2041"
    },
    {
        "engDate": "1985-03-23",
        "nepDate": "10.12.2041"
    },
    {
        "engDate": "1985-03-24",
        "nepDate": "11.12.2041"
    },
    {
        "engDate": "1985-03-25",
        "nepDate": "12.12.2041"
    },
    {
        "engDate": "1985-03-26",
        "nepDate": "13.12.2041"
    },
    {
        "engDate": "1985-03-27",
        "nepDate": "14.12.2041"
    },
    {
        "engDate": "1985-03-28",
        "nepDate": "15.12.2041"
    },
    {
        "engDate": "1985-03-29",
        "nepDate": "16.12.2041"
    },
    {
        "engDate": "1985-03-30",
        "nepDate": "17.12.2041"
    },
    {
        "engDate": "1985-03-31",
        "nepDate": "18.12.2041"
    },
    {
        "engDate": "1985-04-01",
        "nepDate": "19.12.2041"
    },
    {
        "engDate": "1985-04-02",
        "nepDate": "20.12.2041"
    },
    {
        "engDate": "1985-04-03",
        "nepDate": "21.12.2041"
    },
    {
        "engDate": "1985-04-04",
        "nepDate": "22.12.2041"
    },
    {
        "engDate": "1985-04-05",
        "nepDate": "23.12.2041"
    },
    {
        "engDate": "1985-04-06",
        "nepDate": "24.12.2041"
    },
    {
        "engDate": "1985-04-07",
        "nepDate": "25.12.2041"
    },
    {
        "engDate": "1985-04-08",
        "nepDate": "26.12.2041"
    },
    {
        "engDate": "1985-04-09",
        "nepDate": "27.12.2041"
    },
    {
        "engDate": "1985-04-10",
        "nepDate": "28.12.2041"
    },
    {
        "engDate": "1985-04-11",
        "nepDate": "29.12.2041"
    },
    {
        "engDate": "1985-04-12",
        "nepDate": "30.12.2041"
    },
    {
        "engDate": "1985-04-13",
        "nepDate": "01.01.2042"
    },
    {
        "engDate": "1985-04-14",
        "nepDate": "02.01.2042"
    },
    {
        "engDate": "1985-04-15",
        "nepDate": "03.01.2042"
    },
    {
        "engDate": "1985-04-16",
        "nepDate": "04.01.2042"
    },
    {
        "engDate": "1985-04-17",
        "nepDate": "05.01.2042"
    },
    {
        "engDate": "1985-04-18",
        "nepDate": "06.01.2042"
    },
    {
        "engDate": "1985-04-19",
        "nepDate": "07.01.2042"
    },
    {
        "engDate": "1985-04-20",
        "nepDate": "08.01.2042"
    },
    {
        "engDate": "1985-04-21",
        "nepDate": "09.01.2042"
    },
    {
        "engDate": "1985-04-22",
        "nepDate": "10.01.2042"
    },
    {
        "engDate": "1985-04-23",
        "nepDate": "11.01.2042"
    },
    {
        "engDate": "1985-04-24",
        "nepDate": "12.01.2042"
    },
    {
        "engDate": "1985-04-25",
        "nepDate": "13.01.2042"
    },
    {
        "engDate": "1985-04-26",
        "nepDate": "14.01.2042"
    },
    {
        "engDate": "1985-04-27",
        "nepDate": "15.01.2042"
    },
    {
        "engDate": "1985-04-28",
        "nepDate": "16.01.2042"
    },
    {
        "engDate": "1985-04-29",
        "nepDate": "17.01.2042"
    },
    {
        "engDate": "1985-04-30",
        "nepDate": "18.01.2042"
    },
    {
        "engDate": "1985-05-01",
        "nepDate": "19.01.2042"
    },
    {
        "engDate": "1985-05-02",
        "nepDate": "20.01.2042"
    },
    {
        "engDate": "1985-05-03",
        "nepDate": "21.01.2042"
    },
    {
        "engDate": "1985-05-04",
        "nepDate": "22.01.2042"
    },
    {
        "engDate": "1985-05-05",
        "nepDate": "23.01.2042"
    },
    {
        "engDate": "1985-05-06",
        "nepDate": "24.01.2042"
    },
    {
        "engDate": "1985-05-07",
        "nepDate": "25.01.2042"
    },
    {
        "engDate": "1985-05-08",
        "nepDate": "26.01.2042"
    },
    {
        "engDate": "1985-05-09",
        "nepDate": "27.01.2042"
    },
    {
        "engDate": "1985-05-10",
        "nepDate": "28.01.2042"
    },
    {
        "engDate": "1985-05-11",
        "nepDate": "29.01.2042"
    },
    {
        "engDate": "1985-05-12",
        "nepDate": "30.01.2042"
    },
    {
        "engDate": "1985-05-13",
        "nepDate": "31.01.2042"
    },
    {
        "engDate": "1985-05-14",
        "nepDate": "01.02.2042"
    },
    {
        "engDate": "1985-05-15",
        "nepDate": "02.02.2042"
    },
    {
        "engDate": "1985-05-16",
        "nepDate": "03.02.2042"
    },
    {
        "engDate": "1985-05-17",
        "nepDate": "04.02.2042"
    },
    {
        "engDate": "1985-05-18",
        "nepDate": "05.02.2042"
    },
    {
        "engDate": "1985-05-19",
        "nepDate": "06.02.2042"
    },
    {
        "engDate": "1985-05-20",
        "nepDate": "07.02.2042"
    },
    {
        "engDate": "1985-05-21",
        "nepDate": "08.02.2042"
    },
    {
        "engDate": "1985-05-22",
        "nepDate": "09.02.2042"
    },
    {
        "engDate": "1985-05-23",
        "nepDate": "10.02.2042"
    },
    {
        "engDate": "1985-05-24",
        "nepDate": "11.02.2042"
    },
    {
        "engDate": "1985-05-25",
        "nepDate": "12.02.2042"
    },
    {
        "engDate": "1985-05-26",
        "nepDate": "13.02.2042"
    },
    {
        "engDate": "1985-05-27",
        "nepDate": "14.02.2042"
    },
    {
        "engDate": "1985-05-28",
        "nepDate": "15.02.2042"
    },
    {
        "engDate": "1985-05-29",
        "nepDate": "16.02.2042"
    },
    {
        "engDate": "1985-05-30",
        "nepDate": "17.02.2042"
    },
    {
        "engDate": "1985-05-31",
        "nepDate": "18.02.2042"
    },
    {
        "engDate": "1985-06-01",
        "nepDate": "19.02.2042"
    },
    {
        "engDate": "1985-06-02",
        "nepDate": "20.02.2042"
    },
    {
        "engDate": "1985-06-03",
        "nepDate": "21.02.2042"
    },
    {
        "engDate": "1985-06-04",
        "nepDate": "22.02.2042"
    },
    {
        "engDate": "1985-06-05",
        "nepDate": "23.02.2042"
    },
    {
        "engDate": "1985-06-06",
        "nepDate": "24.02.2042"
    },
    {
        "engDate": "1985-06-07",
        "nepDate": "25.02.2042"
    },
    {
        "engDate": "1985-06-08",
        "nepDate": "26.02.2042"
    },
    {
        "engDate": "1985-06-09",
        "nepDate": "27.02.2042"
    },
    {
        "engDate": "1985-06-10",
        "nepDate": "28.02.2042"
    },
    {
        "engDate": "1985-06-11",
        "nepDate": "29.02.2042"
    },
    {
        "engDate": "1985-06-12",
        "nepDate": "30.02.2042"
    },
    {
        "engDate": "1985-06-13",
        "nepDate": "31.02.2042"
    },
    {
        "engDate": "1985-06-14",
        "nepDate": "32.02.2042"
    },
    {
        "engDate": "1985-06-15",
        "nepDate": "01.03.2042"
    },
    {
        "engDate": "1985-06-16",
        "nepDate": "02.03.2042"
    },
    {
        "engDate": "1985-06-17",
        "nepDate": "03.03.2042"
    },
    {
        "engDate": "1985-06-18",
        "nepDate": "04.03.2042"
    },
    {
        "engDate": "1985-06-19",
        "nepDate": "05.03.2042"
    },
    {
        "engDate": "1985-06-20",
        "nepDate": "06.03.2042"
    },
    {
        "engDate": "1985-06-21",
        "nepDate": "07.03.2042"
    },
    {
        "engDate": "1985-06-22",
        "nepDate": "08.03.2042"
    },
    {
        "engDate": "1985-06-23",
        "nepDate": "09.03.2042"
    },
    {
        "engDate": "1985-06-24",
        "nepDate": "10.03.2042"
    },
    {
        "engDate": "1985-06-25",
        "nepDate": "11.03.2042"
    },
    {
        "engDate": "1985-06-26",
        "nepDate": "12.03.2042"
    },
    {
        "engDate": "1985-06-27",
        "nepDate": "13.03.2042"
    },
    {
        "engDate": "1985-06-28",
        "nepDate": "14.03.2042"
    },
    {
        "engDate": "1985-06-29",
        "nepDate": "15.03.2042"
    },
    {
        "engDate": "1985-06-30",
        "nepDate": "16.03.2042"
    },
    {
        "engDate": "1985-07-01",
        "nepDate": "17.03.2042"
    },
    {
        "engDate": "1985-07-02",
        "nepDate": "18.03.2042"
    },
    {
        "engDate": "1985-07-03",
        "nepDate": "19.03.2042"
    },
    {
        "engDate": "1985-07-04",
        "nepDate": "20.03.2042"
    },
    {
        "engDate": "1985-07-05",
        "nepDate": "21.03.2042"
    },
    {
        "engDate": "1985-07-06",
        "nepDate": "22.03.2042"
    },
    {
        "engDate": "1985-07-07",
        "nepDate": "23.03.2042"
    },
    {
        "engDate": "1985-07-08",
        "nepDate": "24.03.2042"
    },
    {
        "engDate": "1985-07-09",
        "nepDate": "25.03.2042"
    },
    {
        "engDate": "1985-07-10",
        "nepDate": "26.03.2042"
    },
    {
        "engDate": "1985-07-11",
        "nepDate": "27.03.2042"
    },
    {
        "engDate": "1985-07-12",
        "nepDate": "28.03.2042"
    },
    {
        "engDate": "1985-07-13",
        "nepDate": "29.03.2042"
    },
    {
        "engDate": "1985-07-14",
        "nepDate": "30.03.2042"
    },
    {
        "engDate": "1985-07-15",
        "nepDate": "31.03.2042"
    },
    {
        "engDate": "1985-07-16",
        "nepDate": "01.04.2042"
    },
    {
        "engDate": "1985-07-17",
        "nepDate": "02.04.2042"
    },
    {
        "engDate": "1985-07-18",
        "nepDate": "03.04.2042"
    },
    {
        "engDate": "1985-07-19",
        "nepDate": "04.04.2042"
    },
    {
        "engDate": "1985-07-20",
        "nepDate": "05.04.2042"
    },
    {
        "engDate": "1985-07-21",
        "nepDate": "06.04.2042"
    },
    {
        "engDate": "1985-07-22",
        "nepDate": "07.04.2042"
    },
    {
        "engDate": "1985-07-23",
        "nepDate": "08.04.2042"
    },
    {
        "engDate": "1985-07-24",
        "nepDate": "09.04.2042"
    },
    {
        "engDate": "1985-07-25",
        "nepDate": "10.04.2042"
    },
    {
        "engDate": "1985-07-26",
        "nepDate": "11.04.2042"
    },
    {
        "engDate": "1985-07-27",
        "nepDate": "12.04.2042"
    },
    {
        "engDate": "1985-07-28",
        "nepDate": "13.04.2042"
    },
    {
        "engDate": "1985-07-29",
        "nepDate": "14.04.2042"
    },
    {
        "engDate": "1985-07-30",
        "nepDate": "15.04.2042"
    },
    {
        "engDate": "1985-07-31",
        "nepDate": "16.04.2042"
    },
    {
        "engDate": "1985-08-01",
        "nepDate": "17.04.2042"
    },
    {
        "engDate": "1985-08-02",
        "nepDate": "18.04.2042"
    },
    {
        "engDate": "1985-08-03",
        "nepDate": "19.04.2042"
    },
    {
        "engDate": "1985-08-04",
        "nepDate": "20.04.2042"
    },
    {
        "engDate": "1985-08-05",
        "nepDate": "21.04.2042"
    },
    {
        "engDate": "1985-08-06",
        "nepDate": "22.04.2042"
    },
    {
        "engDate": "1985-08-07",
        "nepDate": "23.04.2042"
    },
    {
        "engDate": "1985-08-08",
        "nepDate": "24.04.2042"
    },
    {
        "engDate": "1985-08-09",
        "nepDate": "25.04.2042"
    },
    {
        "engDate": "1985-08-10",
        "nepDate": "26.04.2042"
    },
    {
        "engDate": "1985-08-11",
        "nepDate": "27.04.2042"
    },
    {
        "engDate": "1985-08-12",
        "nepDate": "28.04.2042"
    },
    {
        "engDate": "1985-08-13",
        "nepDate": "29.04.2042"
    },
    {
        "engDate": "1985-08-14",
        "nepDate": "30.04.2042"
    },
    {
        "engDate": "1985-08-15",
        "nepDate": "31.04.2042"
    },
    {
        "engDate": "1985-08-16",
        "nepDate": "32.04.2042"
    },
    {
        "engDate": "1985-08-17",
        "nepDate": "01.05.2042"
    },
    {
        "engDate": "1985-08-18",
        "nepDate": "02.05.2042"
    },
    {
        "engDate": "1985-08-19",
        "nepDate": "03.05.2042"
    },
    {
        "engDate": "1985-08-20",
        "nepDate": "04.05.2042"
    },
    {
        "engDate": "1985-08-21",
        "nepDate": "05.05.2042"
    },
    {
        "engDate": "1985-08-22",
        "nepDate": "06.05.2042"
    },
    {
        "engDate": "1985-08-23",
        "nepDate": "07.05.2042"
    },
    {
        "engDate": "1985-08-24",
        "nepDate": "08.05.2042"
    },
    {
        "engDate": "1985-08-25",
        "nepDate": "09.05.2042"
    },
    {
        "engDate": "1985-08-26",
        "nepDate": "10.05.2042"
    },
    {
        "engDate": "1985-08-27",
        "nepDate": "11.05.2042"
    },
    {
        "engDate": "1985-08-28",
        "nepDate": "12.05.2042"
    },
    {
        "engDate": "1985-08-29",
        "nepDate": "13.05.2042"
    },
    {
        "engDate": "1985-08-30",
        "nepDate": "14.05.2042"
    },
    {
        "engDate": "1985-08-31",
        "nepDate": "15.05.2042"
    },
    {
        "engDate": "1985-09-01",
        "nepDate": "16.05.2042"
    },
    {
        "engDate": "1985-09-02",
        "nepDate": "17.05.2042"
    },
    {
        "engDate": "1985-09-03",
        "nepDate": "18.05.2042"
    },
    {
        "engDate": "1985-09-04",
        "nepDate": "19.05.2042"
    },
    {
        "engDate": "1985-09-05",
        "nepDate": "20.05.2042"
    },
    {
        "engDate": "1985-09-06",
        "nepDate": "21.05.2042"
    },
    {
        "engDate": "1985-09-07",
        "nepDate": "22.05.2042"
    },
    {
        "engDate": "1985-09-08",
        "nepDate": "23.05.2042"
    },
    {
        "engDate": "1985-09-09",
        "nepDate": "24.05.2042"
    },
    {
        "engDate": "1985-09-10",
        "nepDate": "25.05.2042"
    },
    {
        "engDate": "1985-09-11",
        "nepDate": "26.05.2042"
    },
    {
        "engDate": "1985-09-12",
        "nepDate": "27.05.2042"
    },
    {
        "engDate": "1985-09-13",
        "nepDate": "28.05.2042"
    },
    {
        "engDate": "1985-09-14",
        "nepDate": "29.05.2042"
    },
    {
        "engDate": "1985-09-15",
        "nepDate": "30.05.2042"
    },
    {
        "engDate": "1985-09-16",
        "nepDate": "31.05.2042"
    },
    {
        "engDate": "1985-09-17",
        "nepDate": "01.06.2042"
    },
    {
        "engDate": "1985-09-18",
        "nepDate": "02.06.2042"
    },
    {
        "engDate": "1985-09-19",
        "nepDate": "03.06.2042"
    },
    {
        "engDate": "1985-09-20",
        "nepDate": "04.06.2042"
    },
    {
        "engDate": "1985-09-21",
        "nepDate": "05.06.2042"
    },
    {
        "engDate": "1985-09-22",
        "nepDate": "06.06.2042"
    },
    {
        "engDate": "1985-09-23",
        "nepDate": "07.06.2042"
    },
    {
        "engDate": "1985-09-24",
        "nepDate": "08.06.2042"
    },
    {
        "engDate": "1985-09-25",
        "nepDate": "09.06.2042"
    },
    {
        "engDate": "1985-09-26",
        "nepDate": "10.06.2042"
    },
    {
        "engDate": "1985-09-27",
        "nepDate": "11.06.2042"
    },
    {
        "engDate": "1985-09-28",
        "nepDate": "12.06.2042"
    },
    {
        "engDate": "1985-09-29",
        "nepDate": "13.06.2042"
    },
    {
        "engDate": "1985-09-30",
        "nepDate": "14.06.2042"
    },
    {
        "engDate": "1985-10-01",
        "nepDate": "15.06.2042"
    },
    {
        "engDate": "1985-10-02",
        "nepDate": "16.06.2042"
    },
    {
        "engDate": "1985-10-03",
        "nepDate": "17.06.2042"
    },
    {
        "engDate": "1985-10-04",
        "nepDate": "18.06.2042"
    },
    {
        "engDate": "1985-10-05",
        "nepDate": "19.06.2042"
    },
    {
        "engDate": "1985-10-06",
        "nepDate": "20.06.2042"
    },
    {
        "engDate": "1985-10-07",
        "nepDate": "21.06.2042"
    },
    {
        "engDate": "1985-10-08",
        "nepDate": "22.06.2042"
    },
    {
        "engDate": "1985-10-09",
        "nepDate": "23.06.2042"
    },
    {
        "engDate": "1985-10-10",
        "nepDate": "24.06.2042"
    },
    {
        "engDate": "1985-10-11",
        "nepDate": "25.06.2042"
    },
    {
        "engDate": "1985-10-12",
        "nepDate": "26.06.2042"
    },
    {
        "engDate": "1985-10-13",
        "nepDate": "27.06.2042"
    },
    {
        "engDate": "1985-10-14",
        "nepDate": "28.06.2042"
    },
    {
        "engDate": "1985-10-15",
        "nepDate": "29.06.2042"
    },
    {
        "engDate": "1985-10-16",
        "nepDate": "30.06.2042"
    },
    {
        "engDate": "1985-10-17",
        "nepDate": "01.07.2042"
    },
    {
        "engDate": "1985-10-18",
        "nepDate": "02.07.2042"
    },
    {
        "engDate": "1985-10-19",
        "nepDate": "03.07.2042"
    },
    {
        "engDate": "1985-10-20",
        "nepDate": "04.07.2042"
    },
    {
        "engDate": "1985-10-21",
        "nepDate": "05.07.2042"
    },
    {
        "engDate": "1985-10-22",
        "nepDate": "06.07.2042"
    },
    {
        "engDate": "1985-10-23",
        "nepDate": "07.07.2042"
    },
    {
        "engDate": "1985-10-24",
        "nepDate": "08.07.2042"
    },
    {
        "engDate": "1985-10-25",
        "nepDate": "09.07.2042"
    },
    {
        "engDate": "1985-10-26",
        "nepDate": "10.07.2042"
    },
    {
        "engDate": "1985-10-27",
        "nepDate": "11.07.2042"
    },
    {
        "engDate": "1985-10-28",
        "nepDate": "12.07.2042"
    },
    {
        "engDate": "1985-10-29",
        "nepDate": "13.07.2042"
    },
    {
        "engDate": "1985-10-30",
        "nepDate": "14.07.2042"
    },
    {
        "engDate": "1985-10-31",
        "nepDate": "15.07.2042"
    },
    {
        "engDate": "1985-11-01",
        "nepDate": "16.07.2042"
    },
    {
        "engDate": "1985-11-02",
        "nepDate": "17.07.2042"
    },
    {
        "engDate": "1985-11-03",
        "nepDate": "18.07.2042"
    },
    {
        "engDate": "1985-11-04",
        "nepDate": "19.07.2042"
    },
    {
        "engDate": "1985-11-05",
        "nepDate": "20.07.2042"
    },
    {
        "engDate": "1985-11-06",
        "nepDate": "21.07.2042"
    },
    {
        "engDate": "1985-11-07",
        "nepDate": "22.07.2042"
    },
    {
        "engDate": "1985-11-08",
        "nepDate": "23.07.2042"
    },
    {
        "engDate": "1985-11-09",
        "nepDate": "24.07.2042"
    },
    {
        "engDate": "1985-11-10",
        "nepDate": "25.07.2042"
    },
    {
        "engDate": "1985-11-11",
        "nepDate": "26.07.2042"
    },
    {
        "engDate": "1985-11-12",
        "nepDate": "27.07.2042"
    },
    {
        "engDate": "1985-11-13",
        "nepDate": "28.07.2042"
    },
    {
        "engDate": "1985-11-14",
        "nepDate": "29.07.2042"
    },
    {
        "engDate": "1985-11-15",
        "nepDate": "30.07.2042"
    },
    {
        "engDate": "1985-11-16",
        "nepDate": "01.08.2042"
    },
    {
        "engDate": "1985-11-17",
        "nepDate": "02.08.2042"
    },
    {
        "engDate": "1985-11-18",
        "nepDate": "03.08.2042"
    },
    {
        "engDate": "1985-11-19",
        "nepDate": "04.08.2042"
    },
    {
        "engDate": "1985-11-20",
        "nepDate": "05.08.2042"
    },
    {
        "engDate": "1985-11-21",
        "nepDate": "06.08.2042"
    },
    {
        "engDate": "1985-11-22",
        "nepDate": "07.08.2042"
    },
    {
        "engDate": "1985-11-23",
        "nepDate": "08.08.2042"
    },
    {
        "engDate": "1985-11-24",
        "nepDate": "09.08.2042"
    },
    {
        "engDate": "1985-11-25",
        "nepDate": "10.08.2042"
    },
    {
        "engDate": "1985-11-26",
        "nepDate": "11.08.2042"
    },
    {
        "engDate": "1985-11-27",
        "nepDate": "12.08.2042"
    },
    {
        "engDate": "1985-11-28",
        "nepDate": "13.08.2042"
    },
    {
        "engDate": "1985-11-29",
        "nepDate": "14.08.2042"
    },
    {
        "engDate": "1985-11-30",
        "nepDate": "15.08.2042"
    },
    {
        "engDate": "1985-12-01",
        "nepDate": "16.08.2042"
    },
    {
        "engDate": "1985-12-02",
        "nepDate": "17.08.2042"
    },
    {
        "engDate": "1985-12-03",
        "nepDate": "18.08.2042"
    },
    {
        "engDate": "1985-12-04",
        "nepDate": "19.08.2042"
    },
    {
        "engDate": "1985-12-05",
        "nepDate": "20.08.2042"
    },
    {
        "engDate": "1985-12-06",
        "nepDate": "21.08.2042"
    },
    {
        "engDate": "1985-12-07",
        "nepDate": "22.08.2042"
    },
    {
        "engDate": "1985-12-08",
        "nepDate": "23.08.2042"
    },
    {
        "engDate": "1985-12-09",
        "nepDate": "24.08.2042"
    },
    {
        "engDate": "1985-12-10",
        "nepDate": "25.08.2042"
    },
    {
        "engDate": "1985-12-11",
        "nepDate": "26.08.2042"
    },
    {
        "engDate": "1985-12-12",
        "nepDate": "27.08.2042"
    },
    {
        "engDate": "1985-12-13",
        "nepDate": "28.08.2042"
    },
    {
        "engDate": "1985-12-14",
        "nepDate": "29.08.2042"
    },
    {
        "engDate": "1985-12-15",
        "nepDate": "30.08.2042"
    },
    {
        "engDate": "1985-12-16",
        "nepDate": "01.09.2042"
    },
    {
        "engDate": "1985-12-17",
        "nepDate": "02.09.2042"
    },
    {
        "engDate": "1985-12-18",
        "nepDate": "03.09.2042"
    },
    {
        "engDate": "1985-12-19",
        "nepDate": "04.09.2042"
    },
    {
        "engDate": "1985-12-20",
        "nepDate": "05.09.2042"
    },
    {
        "engDate": "1985-12-21",
        "nepDate": "06.09.2042"
    },
    {
        "engDate": "1985-12-22",
        "nepDate": "07.09.2042"
    },
    {
        "engDate": "1985-12-23",
        "nepDate": "08.09.2042"
    },
    {
        "engDate": "1985-12-24",
        "nepDate": "09.09.2042"
    },
    {
        "engDate": "1985-12-25",
        "nepDate": "10.09.2042"
    },
    {
        "engDate": "1985-12-26",
        "nepDate": "11.09.2042"
    },
    {
        "engDate": "1985-12-27",
        "nepDate": "12.09.2042"
    },
    {
        "engDate": "1985-12-28",
        "nepDate": "13.09.2042"
    },
    {
        "engDate": "1985-12-29",
        "nepDate": "14.09.2042"
    },
    {
        "engDate": "1985-12-30",
        "nepDate": "15.09.2042"
    },
    {
        "engDate": "1985-12-31",
        "nepDate": "16.09.2042"
    },
    {
        "engDate": "1986-01-01",
        "nepDate": "17.09.2042"
    },
    {
        "engDate": "1986-01-02",
        "nepDate": "18.09.2042"
    },
    {
        "engDate": "1986-01-03",
        "nepDate": "19.09.2042"
    },
    {
        "engDate": "1986-01-04",
        "nepDate": "20.09.2042"
    },
    {
        "engDate": "1986-01-05",
        "nepDate": "21.09.2042"
    },
    {
        "engDate": "1986-01-06",
        "nepDate": "22.09.2042"
    },
    {
        "engDate": "1986-01-07",
        "nepDate": "23.09.2042"
    },
    {
        "engDate": "1986-01-08",
        "nepDate": "24.09.2042"
    },
    {
        "engDate": "1986-01-09",
        "nepDate": "25.09.2042"
    },
    {
        "engDate": "1986-01-10",
        "nepDate": "26.09.2042"
    },
    {
        "engDate": "1986-01-11",
        "nepDate": "27.09.2042"
    },
    {
        "engDate": "1986-01-12",
        "nepDate": "28.09.2042"
    },
    {
        "engDate": "1986-01-13",
        "nepDate": "29.09.2042"
    },
    {
        "engDate": "1986-01-14",
        "nepDate": "01.10.2042"
    },
    {
        "engDate": "1986-01-15",
        "nepDate": "02.10.2042"
    },
    {
        "engDate": "1986-01-16",
        "nepDate": "03.10.2042"
    },
    {
        "engDate": "1986-01-17",
        "nepDate": "04.10.2042"
    },
    {
        "engDate": "1986-01-18",
        "nepDate": "05.10.2042"
    },
    {
        "engDate": "1986-01-19",
        "nepDate": "06.10.2042"
    },
    {
        "engDate": "1986-01-20",
        "nepDate": "07.10.2042"
    },
    {
        "engDate": "1986-01-21",
        "nepDate": "08.10.2042"
    },
    {
        "engDate": "1986-01-22",
        "nepDate": "09.10.2042"
    },
    {
        "engDate": "1986-01-23",
        "nepDate": "10.10.2042"
    },
    {
        "engDate": "1986-01-24",
        "nepDate": "11.10.2042"
    },
    {
        "engDate": "1986-01-25",
        "nepDate": "12.10.2042"
    },
    {
        "engDate": "1986-01-26",
        "nepDate": "13.10.2042"
    },
    {
        "engDate": "1986-01-27",
        "nepDate": "14.10.2042"
    },
    {
        "engDate": "1986-01-28",
        "nepDate": "15.10.2042"
    },
    {
        "engDate": "1986-01-29",
        "nepDate": "16.10.2042"
    },
    {
        "engDate": "1986-01-30",
        "nepDate": "17.10.2042"
    },
    {
        "engDate": "1986-01-31",
        "nepDate": "18.10.2042"
    },
    {
        "engDate": "1986-02-01",
        "nepDate": "19.10.2042"
    },
    {
        "engDate": "1986-02-02",
        "nepDate": "20.10.2042"
    },
    {
        "engDate": "1986-02-03",
        "nepDate": "21.10.2042"
    },
    {
        "engDate": "1986-02-04",
        "nepDate": "22.10.2042"
    },
    {
        "engDate": "1986-02-05",
        "nepDate": "23.10.2042"
    },
    {
        "engDate": "1986-02-06",
        "nepDate": "24.10.2042"
    },
    {
        "engDate": "1986-02-07",
        "nepDate": "25.10.2042"
    },
    {
        "engDate": "1986-02-08",
        "nepDate": "26.10.2042"
    },
    {
        "engDate": "1986-02-09",
        "nepDate": "27.10.2042"
    },
    {
        "engDate": "1986-02-10",
        "nepDate": "28.10.2042"
    },
    {
        "engDate": "1986-02-11",
        "nepDate": "29.10.2042"
    },
    {
        "engDate": "1986-02-12",
        "nepDate": "01.11.2042"
    },
    {
        "engDate": "1986-02-13",
        "nepDate": "02.11.2042"
    },
    {
        "engDate": "1986-02-14",
        "nepDate": "03.11.2042"
    },
    {
        "engDate": "1986-02-15",
        "nepDate": "04.11.2042"
    },
    {
        "engDate": "1986-02-16",
        "nepDate": "05.11.2042"
    },
    {
        "engDate": "1986-02-17",
        "nepDate": "06.11.2042"
    },
    {
        "engDate": "1986-02-18",
        "nepDate": "07.11.2042"
    },
    {
        "engDate": "1986-02-19",
        "nepDate": "08.11.2042"
    },
    {
        "engDate": "1986-02-20",
        "nepDate": "09.11.2042"
    },
    {
        "engDate": "1986-02-21",
        "nepDate": "10.11.2042"
    },
    {
        "engDate": "1986-02-22",
        "nepDate": "11.11.2042"
    },
    {
        "engDate": "1986-02-23",
        "nepDate": "12.11.2042"
    },
    {
        "engDate": "1986-02-24",
        "nepDate": "13.11.2042"
    },
    {
        "engDate": "1986-02-25",
        "nepDate": "14.11.2042"
    },
    {
        "engDate": "1986-02-26",
        "nepDate": "15.11.2042"
    },
    {
        "engDate": "1986-02-27",
        "nepDate": "16.11.2042"
    },
    {
        "engDate": "1986-02-28",
        "nepDate": "17.11.2042"
    },
    {
        "engDate": "1986-03-01",
        "nepDate": "18.11.2042"
    },
    {
        "engDate": "1986-03-02",
        "nepDate": "19.11.2042"
    },
    {
        "engDate": "1986-03-03",
        "nepDate": "20.11.2042"
    },
    {
        "engDate": "1986-03-04",
        "nepDate": "21.11.2042"
    },
    {
        "engDate": "1986-03-05",
        "nepDate": "22.11.2042"
    },
    {
        "engDate": "1986-03-06",
        "nepDate": "23.11.2042"
    },
    {
        "engDate": "1986-03-07",
        "nepDate": "24.11.2042"
    },
    {
        "engDate": "1986-03-08",
        "nepDate": "25.11.2042"
    },
    {
        "engDate": "1986-03-09",
        "nepDate": "26.11.2042"
    },
    {
        "engDate": "1986-03-10",
        "nepDate": "27.11.2042"
    },
    {
        "engDate": "1986-03-11",
        "nepDate": "28.11.2042"
    },
    {
        "engDate": "1986-03-12",
        "nepDate": "29.11.2042"
    },
    {
        "engDate": "1986-03-13",
        "nepDate": "30.11.2042"
    },
    {
        "engDate": "1986-03-14",
        "nepDate": "01.12.2042"
    },
    {
        "engDate": "1986-03-15",
        "nepDate": "02.12.2042"
    },
    {
        "engDate": "1986-03-16",
        "nepDate": "03.12.2042"
    },
    {
        "engDate": "1986-03-17",
        "nepDate": "04.12.2042"
    },
    {
        "engDate": "1986-03-18",
        "nepDate": "05.12.2042"
    },
    {
        "engDate": "1986-03-19",
        "nepDate": "06.12.2042"
    },
    {
        "engDate": "1986-03-20",
        "nepDate": "07.12.2042"
    },
    {
        "engDate": "1986-03-21",
        "nepDate": "08.12.2042"
    },
    {
        "engDate": "1986-03-22",
        "nepDate": "09.12.2042"
    },
    {
        "engDate": "1986-03-23",
        "nepDate": "10.12.2042"
    },
    {
        "engDate": "1986-03-24",
        "nepDate": "11.12.2042"
    },
    {
        "engDate": "1986-03-25",
        "nepDate": "12.12.2042"
    },
    {
        "engDate": "1986-03-26",
        "nepDate": "13.12.2042"
    },
    {
        "engDate": "1986-03-27",
        "nepDate": "14.12.2042"
    },
    {
        "engDate": "1986-03-28",
        "nepDate": "15.12.2042"
    },
    {
        "engDate": "1986-03-29",
        "nepDate": "16.12.2042"
    },
    {
        "engDate": "1986-03-30",
        "nepDate": "17.12.2042"
    },
    {
        "engDate": "1986-03-31",
        "nepDate": "18.12.2042"
    },
    {
        "engDate": "1986-04-01",
        "nepDate": "19.12.2042"
    },
    {
        "engDate": "1986-04-02",
        "nepDate": "20.12.2042"
    },
    {
        "engDate": "1986-04-03",
        "nepDate": "21.12.2042"
    },
    {
        "engDate": "1986-04-04",
        "nepDate": "22.12.2042"
    },
    {
        "engDate": "1986-04-05",
        "nepDate": "23.12.2042"
    },
    {
        "engDate": "1986-04-06",
        "nepDate": "24.12.2042"
    },
    {
        "engDate": "1986-04-07",
        "nepDate": "25.12.2042"
    },
    {
        "engDate": "1986-04-08",
        "nepDate": "26.12.2042"
    },
    {
        "engDate": "1986-04-09",
        "nepDate": "27.12.2042"
    },
    {
        "engDate": "1986-04-10",
        "nepDate": "28.12.2042"
    },
    {
        "engDate": "1986-04-11",
        "nepDate": "29.12.2042"
    },
    {
        "engDate": "1986-04-12",
        "nepDate": "30.12.2042"
    },
    {
        "engDate": "1986-04-13",
        "nepDate": "31.12.2042"
    },
    {
        "engDate": "1986-04-14",
        "nepDate": "01.01.2043"
    },
    {
        "engDate": "1986-04-15",
        "nepDate": "02.01.2043"
    },
    {
        "engDate": "1986-04-16",
        "nepDate": "03.01.2043"
    },
    {
        "engDate": "1986-04-17",
        "nepDate": "04.01.2043"
    },
    {
        "engDate": "1986-04-18",
        "nepDate": "05.01.2043"
    },
    {
        "engDate": "1986-04-19",
        "nepDate": "06.01.2043"
    },
    {
        "engDate": "1986-04-20",
        "nepDate": "07.01.2043"
    },
    {
        "engDate": "1986-04-21",
        "nepDate": "08.01.2043"
    },
    {
        "engDate": "1986-04-22",
        "nepDate": "09.01.2043"
    },
    {
        "engDate": "1986-04-23",
        "nepDate": "10.01.2043"
    },
    {
        "engDate": "1986-04-24",
        "nepDate": "11.01.2043"
    },
    {
        "engDate": "1986-04-25",
        "nepDate": "12.01.2043"
    },
    {
        "engDate": "1986-04-26",
        "nepDate": "13.01.2043"
    },
    {
        "engDate": "1986-04-27",
        "nepDate": "14.01.2043"
    },
    {
        "engDate": "1986-04-28",
        "nepDate": "15.01.2043"
    },
    {
        "engDate": "1986-04-29",
        "nepDate": "16.01.2043"
    },
    {
        "engDate": "1986-04-30",
        "nepDate": "17.01.2043"
    },
    {
        "engDate": "1986-05-01",
        "nepDate": "18.01.2043"
    },
    {
        "engDate": "1986-05-02",
        "nepDate": "19.01.2043"
    },
    {
        "engDate": "1986-05-03",
        "nepDate": "20.01.2043"
    },
    {
        "engDate": "1986-05-04",
        "nepDate": "21.01.2043"
    },
    {
        "engDate": "1986-05-05",
        "nepDate": "22.01.2043"
    },
    {
        "engDate": "1986-05-06",
        "nepDate": "23.01.2043"
    },
    {
        "engDate": "1986-05-07",
        "nepDate": "24.01.2043"
    },
    {
        "engDate": "1986-05-08",
        "nepDate": "25.01.2043"
    },
    {
        "engDate": "1986-05-09",
        "nepDate": "26.01.2043"
    },
    {
        "engDate": "1986-05-10",
        "nepDate": "27.01.2043"
    },
    {
        "engDate": "1986-05-11",
        "nepDate": "28.01.2043"
    },
    {
        "engDate": "1986-05-12",
        "nepDate": "29.01.2043"
    },
    {
        "engDate": "1986-05-13",
        "nepDate": "30.01.2043"
    },
    {
        "engDate": "1986-05-14",
        "nepDate": "31.01.2043"
    },
    {
        "engDate": "1986-05-15",
        "nepDate": "01.02.2043"
    },
    {
        "engDate": "1986-05-16",
        "nepDate": "02.02.2043"
    },
    {
        "engDate": "1986-05-17",
        "nepDate": "03.02.2043"
    },
    {
        "engDate": "1986-05-18",
        "nepDate": "04.02.2043"
    },
    {
        "engDate": "1986-05-19",
        "nepDate": "05.02.2043"
    },
    {
        "engDate": "1986-05-20",
        "nepDate": "06.02.2043"
    },
    {
        "engDate": "1986-05-21",
        "nepDate": "07.02.2043"
    },
    {
        "engDate": "1986-05-22",
        "nepDate": "08.02.2043"
    },
    {
        "engDate": "1986-05-23",
        "nepDate": "09.02.2043"
    },
    {
        "engDate": "1986-05-24",
        "nepDate": "10.02.2043"
    },
    {
        "engDate": "1986-05-25",
        "nepDate": "11.02.2043"
    },
    {
        "engDate": "1986-05-26",
        "nepDate": "12.02.2043"
    },
    {
        "engDate": "1986-05-27",
        "nepDate": "13.02.2043"
    },
    {
        "engDate": "1986-05-28",
        "nepDate": "14.02.2043"
    },
    {
        "engDate": "1986-05-29",
        "nepDate": "15.02.2043"
    },
    {
        "engDate": "1986-05-30",
        "nepDate": "16.02.2043"
    },
    {
        "engDate": "1986-05-31",
        "nepDate": "17.02.2043"
    },
    {
        "engDate": "1986-06-01",
        "nepDate": "18.02.2043"
    },
    {
        "engDate": "1986-06-02",
        "nepDate": "19.02.2043"
    },
    {
        "engDate": "1986-06-03",
        "nepDate": "20.02.2043"
    },
    {
        "engDate": "1986-06-04",
        "nepDate": "21.02.2043"
    },
    {
        "engDate": "1986-06-05",
        "nepDate": "22.02.2043"
    },
    {
        "engDate": "1986-06-06",
        "nepDate": "23.02.2043"
    },
    {
        "engDate": "1986-06-07",
        "nepDate": "24.02.2043"
    },
    {
        "engDate": "1986-06-08",
        "nepDate": "25.02.2043"
    },
    {
        "engDate": "1986-06-09",
        "nepDate": "26.02.2043"
    },
    {
        "engDate": "1986-06-10",
        "nepDate": "27.02.2043"
    },
    {
        "engDate": "1986-06-11",
        "nepDate": "28.02.2043"
    },
    {
        "engDate": "1986-06-12",
        "nepDate": "29.02.2043"
    },
    {
        "engDate": "1986-06-13",
        "nepDate": "30.02.2043"
    },
    {
        "engDate": "1986-06-14",
        "nepDate": "31.02.2043"
    },
    {
        "engDate": "1986-06-15",
        "nepDate": "01.03.2043"
    },
    {
        "engDate": "1986-06-16",
        "nepDate": "02.03.2043"
    },
    {
        "engDate": "1986-06-17",
        "nepDate": "03.03.2043"
    },
    {
        "engDate": "1986-06-18",
        "nepDate": "04.03.2043"
    },
    {
        "engDate": "1986-06-19",
        "nepDate": "05.03.2043"
    },
    {
        "engDate": "1986-06-20",
        "nepDate": "06.03.2043"
    },
    {
        "engDate": "1986-06-21",
        "nepDate": "07.03.2043"
    },
    {
        "engDate": "1986-06-22",
        "nepDate": "08.03.2043"
    },
    {
        "engDate": "1986-06-23",
        "nepDate": "09.03.2043"
    },
    {
        "engDate": "1986-06-24",
        "nepDate": "10.03.2043"
    },
    {
        "engDate": "1986-06-25",
        "nepDate": "11.03.2043"
    },
    {
        "engDate": "1986-06-26",
        "nepDate": "12.03.2043"
    },
    {
        "engDate": "1986-06-27",
        "nepDate": "13.03.2043"
    },
    {
        "engDate": "1986-06-28",
        "nepDate": "14.03.2043"
    },
    {
        "engDate": "1986-06-29",
        "nepDate": "15.03.2043"
    },
    {
        "engDate": "1986-06-30",
        "nepDate": "16.03.2043"
    },
    {
        "engDate": "1986-07-01",
        "nepDate": "17.03.2043"
    },
    {
        "engDate": "1986-07-02",
        "nepDate": "18.03.2043"
    },
    {
        "engDate": "1986-07-03",
        "nepDate": "19.03.2043"
    },
    {
        "engDate": "1986-07-04",
        "nepDate": "20.03.2043"
    },
    {
        "engDate": "1986-07-05",
        "nepDate": "21.03.2043"
    },
    {
        "engDate": "1986-07-06",
        "nepDate": "22.03.2043"
    },
    {
        "engDate": "1986-07-07",
        "nepDate": "23.03.2043"
    },
    {
        "engDate": "1986-07-08",
        "nepDate": "24.03.2043"
    },
    {
        "engDate": "1986-07-09",
        "nepDate": "25.03.2043"
    },
    {
        "engDate": "1986-07-10",
        "nepDate": "26.03.2043"
    },
    {
        "engDate": "1986-07-11",
        "nepDate": "27.03.2043"
    },
    {
        "engDate": "1986-07-12",
        "nepDate": "28.03.2043"
    },
    {
        "engDate": "1986-07-13",
        "nepDate": "29.03.2043"
    },
    {
        "engDate": "1986-07-14",
        "nepDate": "30.03.2043"
    },
    {
        "engDate": "1986-07-15",
        "nepDate": "31.03.2043"
    },
    {
        "engDate": "1986-07-16",
        "nepDate": "01.04.2043"
    },
    {
        "engDate": "1986-07-17",
        "nepDate": "02.04.2043"
    },
    {
        "engDate": "1986-07-18",
        "nepDate": "03.04.2043"
    },
    {
        "engDate": "1986-07-19",
        "nepDate": "04.04.2043"
    },
    {
        "engDate": "1986-07-20",
        "nepDate": "05.04.2043"
    },
    {
        "engDate": "1986-07-21",
        "nepDate": "06.04.2043"
    },
    {
        "engDate": "1986-07-22",
        "nepDate": "07.04.2043"
    },
    {
        "engDate": "1986-07-23",
        "nepDate": "08.04.2043"
    },
    {
        "engDate": "1986-07-24",
        "nepDate": "09.04.2043"
    },
    {
        "engDate": "1986-07-25",
        "nepDate": "10.04.2043"
    },
    {
        "engDate": "1986-07-26",
        "nepDate": "11.04.2043"
    },
    {
        "engDate": "1986-07-27",
        "nepDate": "12.04.2043"
    },
    {
        "engDate": "1986-07-28",
        "nepDate": "13.04.2043"
    },
    {
        "engDate": "1986-07-29",
        "nepDate": "14.04.2043"
    },
    {
        "engDate": "1986-07-30",
        "nepDate": "15.04.2043"
    },
    {
        "engDate": "1986-07-31",
        "nepDate": "16.04.2043"
    },
    {
        "engDate": "1986-08-01",
        "nepDate": "17.04.2043"
    },
    {
        "engDate": "1986-08-02",
        "nepDate": "18.04.2043"
    },
    {
        "engDate": "1986-08-03",
        "nepDate": "19.04.2043"
    },
    {
        "engDate": "1986-08-04",
        "nepDate": "20.04.2043"
    },
    {
        "engDate": "1986-08-05",
        "nepDate": "21.04.2043"
    },
    {
        "engDate": "1986-08-06",
        "nepDate": "22.04.2043"
    },
    {
        "engDate": "1986-08-07",
        "nepDate": "23.04.2043"
    },
    {
        "engDate": "1986-08-08",
        "nepDate": "24.04.2043"
    },
    {
        "engDate": "1986-08-09",
        "nepDate": "25.04.2043"
    },
    {
        "engDate": "1986-08-10",
        "nepDate": "26.04.2043"
    },
    {
        "engDate": "1986-08-11",
        "nepDate": "27.04.2043"
    },
    {
        "engDate": "1986-08-12",
        "nepDate": "28.04.2043"
    },
    {
        "engDate": "1986-08-13",
        "nepDate": "29.04.2043"
    },
    {
        "engDate": "1986-08-14",
        "nepDate": "30.04.2043"
    },
    {
        "engDate": "1986-08-15",
        "nepDate": "31.04.2043"
    },
    {
        "engDate": "1986-08-16",
        "nepDate": "32.04.2043"
    },
    {
        "engDate": "1986-08-17",
        "nepDate": "01.05.2043"
    },
    {
        "engDate": "1986-08-18",
        "nepDate": "02.05.2043"
    },
    {
        "engDate": "1986-08-19",
        "nepDate": "03.05.2043"
    },
    {
        "engDate": "1986-08-20",
        "nepDate": "04.05.2043"
    },
    {
        "engDate": "1986-08-21",
        "nepDate": "05.05.2043"
    },
    {
        "engDate": "1986-08-22",
        "nepDate": "06.05.2043"
    },
    {
        "engDate": "1986-08-23",
        "nepDate": "07.05.2043"
    },
    {
        "engDate": "1986-08-24",
        "nepDate": "08.05.2043"
    },
    {
        "engDate": "1986-08-25",
        "nepDate": "09.05.2043"
    },
    {
        "engDate": "1986-08-26",
        "nepDate": "10.05.2043"
    },
    {
        "engDate": "1986-08-27",
        "nepDate": "11.05.2043"
    },
    {
        "engDate": "1986-08-28",
        "nepDate": "12.05.2043"
    },
    {
        "engDate": "1986-08-29",
        "nepDate": "13.05.2043"
    },
    {
        "engDate": "1986-08-30",
        "nepDate": "14.05.2043"
    },
    {
        "engDate": "1986-08-31",
        "nepDate": "15.05.2043"
    },
    {
        "engDate": "1986-09-01",
        "nepDate": "16.05.2043"
    },
    {
        "engDate": "1986-09-02",
        "nepDate": "17.05.2043"
    },
    {
        "engDate": "1986-09-03",
        "nepDate": "18.05.2043"
    },
    {
        "engDate": "1986-09-04",
        "nepDate": "19.05.2043"
    },
    {
        "engDate": "1986-09-05",
        "nepDate": "20.05.2043"
    },
    {
        "engDate": "1986-09-06",
        "nepDate": "21.05.2043"
    },
    {
        "engDate": "1986-09-07",
        "nepDate": "22.05.2043"
    },
    {
        "engDate": "1986-09-08",
        "nepDate": "23.05.2043"
    },
    {
        "engDate": "1986-09-09",
        "nepDate": "24.05.2043"
    },
    {
        "engDate": "1986-09-10",
        "nepDate": "25.05.2043"
    },
    {
        "engDate": "1986-09-11",
        "nepDate": "26.05.2043"
    },
    {
        "engDate": "1986-09-12",
        "nepDate": "27.05.2043"
    },
    {
        "engDate": "1986-09-13",
        "nepDate": "28.05.2043"
    },
    {
        "engDate": "1986-09-14",
        "nepDate": "29.05.2043"
    },
    {
        "engDate": "1986-09-15",
        "nepDate": "30.05.2043"
    },
    {
        "engDate": "1986-09-16",
        "nepDate": "31.05.2043"
    },
    {
        "engDate": "1986-09-17",
        "nepDate": "01.06.2043"
    },
    {
        "engDate": "1986-09-18",
        "nepDate": "02.06.2043"
    },
    {
        "engDate": "1986-09-19",
        "nepDate": "03.06.2043"
    },
    {
        "engDate": "1986-09-20",
        "nepDate": "04.06.2043"
    },
    {
        "engDate": "1986-09-21",
        "nepDate": "05.06.2043"
    },
    {
        "engDate": "1986-09-22",
        "nepDate": "06.06.2043"
    },
    {
        "engDate": "1986-09-23",
        "nepDate": "07.06.2043"
    },
    {
        "engDate": "1986-09-24",
        "nepDate": "08.06.2043"
    },
    {
        "engDate": "1986-09-25",
        "nepDate": "09.06.2043"
    },
    {
        "engDate": "1986-09-26",
        "nepDate": "10.06.2043"
    },
    {
        "engDate": "1986-09-27",
        "nepDate": "11.06.2043"
    },
    {
        "engDate": "1986-09-28",
        "nepDate": "12.06.2043"
    },
    {
        "engDate": "1986-09-29",
        "nepDate": "13.06.2043"
    },
    {
        "engDate": "1986-09-30",
        "nepDate": "14.06.2043"
    },
    {
        "engDate": "1986-10-01",
        "nepDate": "15.06.2043"
    },
    {
        "engDate": "1986-10-02",
        "nepDate": "16.06.2043"
    },
    {
        "engDate": "1986-10-03",
        "nepDate": "17.06.2043"
    },
    {
        "engDate": "1986-10-04",
        "nepDate": "18.06.2043"
    },
    {
        "engDate": "1986-10-05",
        "nepDate": "19.06.2043"
    },
    {
        "engDate": "1986-10-06",
        "nepDate": "20.06.2043"
    },
    {
        "engDate": "1986-10-07",
        "nepDate": "21.06.2043"
    },
    {
        "engDate": "1986-10-08",
        "nepDate": "22.06.2043"
    },
    {
        "engDate": "1986-10-09",
        "nepDate": "23.06.2043"
    },
    {
        "engDate": "1986-10-10",
        "nepDate": "24.06.2043"
    },
    {
        "engDate": "1986-10-11",
        "nepDate": "25.06.2043"
    },
    {
        "engDate": "1986-10-12",
        "nepDate": "26.06.2043"
    },
    {
        "engDate": "1986-10-13",
        "nepDate": "27.06.2043"
    },
    {
        "engDate": "1986-10-14",
        "nepDate": "28.06.2043"
    },
    {
        "engDate": "1986-10-15",
        "nepDate": "29.06.2043"
    },
    {
        "engDate": "1986-10-16",
        "nepDate": "30.06.2043"
    },
    {
        "engDate": "1986-10-17",
        "nepDate": "31.06.2043"
    },
    {
        "engDate": "1986-10-18",
        "nepDate": "01.07.2043"
    },
    {
        "engDate": "1986-10-19",
        "nepDate": "02.07.2043"
    },
    {
        "engDate": "1986-10-20",
        "nepDate": "03.07.2043"
    },
    {
        "engDate": "1986-10-21",
        "nepDate": "04.07.2043"
    },
    {
        "engDate": "1986-10-22",
        "nepDate": "05.07.2043"
    },
    {
        "engDate": "1986-10-23",
        "nepDate": "06.07.2043"
    },
    {
        "engDate": "1986-10-24",
        "nepDate": "07.07.2043"
    },
    {
        "engDate": "1986-10-25",
        "nepDate": "08.07.2043"
    },
    {
        "engDate": "1986-10-26",
        "nepDate": "09.07.2043"
    },
    {
        "engDate": "1986-10-27",
        "nepDate": "10.07.2043"
    },
    {
        "engDate": "1986-10-28",
        "nepDate": "11.07.2043"
    },
    {
        "engDate": "1986-10-29",
        "nepDate": "12.07.2043"
    },
    {
        "engDate": "1986-10-30",
        "nepDate": "13.07.2043"
    },
    {
        "engDate": "1986-10-31",
        "nepDate": "14.07.2043"
    },
    {
        "engDate": "1986-11-01",
        "nepDate": "15.07.2043"
    },
    {
        "engDate": "1986-11-02",
        "nepDate": "16.07.2043"
    },
    {
        "engDate": "1986-11-03",
        "nepDate": "17.07.2043"
    },
    {
        "engDate": "1986-11-04",
        "nepDate": "18.07.2043"
    },
    {
        "engDate": "1986-11-05",
        "nepDate": "19.07.2043"
    },
    {
        "engDate": "1986-11-06",
        "nepDate": "20.07.2043"
    },
    {
        "engDate": "1986-11-07",
        "nepDate": "21.07.2043"
    },
    {
        "engDate": "1986-11-08",
        "nepDate": "22.07.2043"
    },
    {
        "engDate": "1986-11-09",
        "nepDate": "23.07.2043"
    },
    {
        "engDate": "1986-11-10",
        "nepDate": "24.07.2043"
    },
    {
        "engDate": "1986-11-11",
        "nepDate": "25.07.2043"
    },
    {
        "engDate": "1986-11-12",
        "nepDate": "26.07.2043"
    },
    {
        "engDate": "1986-11-13",
        "nepDate": "27.07.2043"
    },
    {
        "engDate": "1986-11-14",
        "nepDate": "28.07.2043"
    },
    {
        "engDate": "1986-11-15",
        "nepDate": "29.07.2043"
    },
    {
        "engDate": "1986-11-16",
        "nepDate": "01.08.2043"
    },
    {
        "engDate": "1986-11-17",
        "nepDate": "02.08.2043"
    },
    {
        "engDate": "1986-11-18",
        "nepDate": "03.08.2043"
    },
    {
        "engDate": "1986-11-19",
        "nepDate": "04.08.2043"
    },
    {
        "engDate": "1986-11-20",
        "nepDate": "05.08.2043"
    },
    {
        "engDate": "1986-11-21",
        "nepDate": "06.08.2043"
    },
    {
        "engDate": "1986-11-22",
        "nepDate": "07.08.2043"
    },
    {
        "engDate": "1986-11-23",
        "nepDate": "08.08.2043"
    },
    {
        "engDate": "1986-11-24",
        "nepDate": "09.08.2043"
    },
    {
        "engDate": "1986-11-25",
        "nepDate": "10.08.2043"
    },
    {
        "engDate": "1986-11-26",
        "nepDate": "11.08.2043"
    },
    {
        "engDate": "1986-11-27",
        "nepDate": "12.08.2043"
    },
    {
        "engDate": "1986-11-28",
        "nepDate": "13.08.2043"
    },
    {
        "engDate": "1986-11-29",
        "nepDate": "14.08.2043"
    },
    {
        "engDate": "1986-11-30",
        "nepDate": "15.08.2043"
    },
    {
        "engDate": "1986-12-01",
        "nepDate": "16.08.2043"
    },
    {
        "engDate": "1986-12-02",
        "nepDate": "17.08.2043"
    },
    {
        "engDate": "1986-12-03",
        "nepDate": "18.08.2043"
    },
    {
        "engDate": "1986-12-04",
        "nepDate": "19.08.2043"
    },
    {
        "engDate": "1986-12-05",
        "nepDate": "20.08.2043"
    },
    {
        "engDate": "1986-12-06",
        "nepDate": "21.08.2043"
    },
    {
        "engDate": "1986-12-07",
        "nepDate": "22.08.2043"
    },
    {
        "engDate": "1986-12-08",
        "nepDate": "23.08.2043"
    },
    {
        "engDate": "1986-12-09",
        "nepDate": "24.08.2043"
    },
    {
        "engDate": "1986-12-10",
        "nepDate": "25.08.2043"
    },
    {
        "engDate": "1986-12-11",
        "nepDate": "26.08.2043"
    },
    {
        "engDate": "1986-12-12",
        "nepDate": "27.08.2043"
    },
    {
        "engDate": "1986-12-13",
        "nepDate": "28.08.2043"
    },
    {
        "engDate": "1986-12-14",
        "nepDate": "29.08.2043"
    },
    {
        "engDate": "1986-12-15",
        "nepDate": "30.08.2043"
    },
    {
        "engDate": "1986-12-16",
        "nepDate": "01.09.2043"
    },
    {
        "engDate": "1986-12-17",
        "nepDate": "02.09.2043"
    },
    {
        "engDate": "1986-12-18",
        "nepDate": "03.09.2043"
    },
    {
        "engDate": "1986-12-19",
        "nepDate": "04.09.2043"
    },
    {
        "engDate": "1986-12-20",
        "nepDate": "05.09.2043"
    },
    {
        "engDate": "1986-12-21",
        "nepDate": "06.09.2043"
    },
    {
        "engDate": "1986-12-22",
        "nepDate": "07.09.2043"
    },
    {
        "engDate": "1986-12-23",
        "nepDate": "08.09.2043"
    },
    {
        "engDate": "1986-12-24",
        "nepDate": "09.09.2043"
    },
    {
        "engDate": "1986-12-25",
        "nepDate": "10.09.2043"
    },
    {
        "engDate": "1986-12-26",
        "nepDate": "11.09.2043"
    },
    {
        "engDate": "1986-12-27",
        "nepDate": "12.09.2043"
    },
    {
        "engDate": "1986-12-28",
        "nepDate": "13.09.2043"
    },
    {
        "engDate": "1986-12-29",
        "nepDate": "14.09.2043"
    },
    {
        "engDate": "1986-12-30",
        "nepDate": "15.09.2043"
    },
    {
        "engDate": "1986-12-31",
        "nepDate": "16.09.2043"
    },
    {
        "engDate": "1987-01-01",
        "nepDate": "17.09.2043"
    },
    {
        "engDate": "1987-01-02",
        "nepDate": "18.09.2043"
    },
    {
        "engDate": "1987-01-03",
        "nepDate": "19.09.2043"
    },
    {
        "engDate": "1987-01-04",
        "nepDate": "20.09.2043"
    },
    {
        "engDate": "1987-01-05",
        "nepDate": "21.09.2043"
    },
    {
        "engDate": "1987-01-06",
        "nepDate": "22.09.2043"
    },
    {
        "engDate": "1987-01-07",
        "nepDate": "23.09.2043"
    },
    {
        "engDate": "1987-01-08",
        "nepDate": "24.09.2043"
    },
    {
        "engDate": "1987-01-09",
        "nepDate": "25.09.2043"
    },
    {
        "engDate": "1987-01-10",
        "nepDate": "26.09.2043"
    },
    {
        "engDate": "1987-01-11",
        "nepDate": "27.09.2043"
    },
    {
        "engDate": "1987-01-12",
        "nepDate": "28.09.2043"
    },
    {
        "engDate": "1987-01-13",
        "nepDate": "29.09.2043"
    },
    {
        "engDate": "1987-01-14",
        "nepDate": "30.09.2043"
    },
    {
        "engDate": "1987-01-15",
        "nepDate": "01.10.2043"
    },
    {
        "engDate": "1987-01-16",
        "nepDate": "02.10.2043"
    },
    {
        "engDate": "1987-01-17",
        "nepDate": "03.10.2043"
    },
    {
        "engDate": "1987-01-18",
        "nepDate": "04.10.2043"
    },
    {
        "engDate": "1987-01-19",
        "nepDate": "05.10.2043"
    },
    {
        "engDate": "1987-01-20",
        "nepDate": "06.10.2043"
    },
    {
        "engDate": "1987-01-21",
        "nepDate": "07.10.2043"
    },
    {
        "engDate": "1987-01-22",
        "nepDate": "08.10.2043"
    },
    {
        "engDate": "1987-01-23",
        "nepDate": "09.10.2043"
    },
    {
        "engDate": "1987-01-24",
        "nepDate": "10.10.2043"
    },
    {
        "engDate": "1987-01-25",
        "nepDate": "11.10.2043"
    },
    {
        "engDate": "1987-01-26",
        "nepDate": "12.10.2043"
    },
    {
        "engDate": "1987-01-27",
        "nepDate": "13.10.2043"
    },
    {
        "engDate": "1987-01-28",
        "nepDate": "14.10.2043"
    },
    {
        "engDate": "1987-01-29",
        "nepDate": "15.10.2043"
    },
    {
        "engDate": "1987-01-30",
        "nepDate": "16.10.2043"
    },
    {
        "engDate": "1987-01-31",
        "nepDate": "17.10.2043"
    },
    {
        "engDate": "1987-02-01",
        "nepDate": "18.10.2043"
    },
    {
        "engDate": "1987-02-02",
        "nepDate": "19.10.2043"
    },
    {
        "engDate": "1987-02-03",
        "nepDate": "20.10.2043"
    },
    {
        "engDate": "1987-02-04",
        "nepDate": "21.10.2043"
    },
    {
        "engDate": "1987-02-05",
        "nepDate": "22.10.2043"
    },
    {
        "engDate": "1987-02-06",
        "nepDate": "23.10.2043"
    },
    {
        "engDate": "1987-02-07",
        "nepDate": "24.10.2043"
    },
    {
        "engDate": "1987-02-08",
        "nepDate": "25.10.2043"
    },
    {
        "engDate": "1987-02-09",
        "nepDate": "26.10.2043"
    },
    {
        "engDate": "1987-02-10",
        "nepDate": "27.10.2043"
    },
    {
        "engDate": "1987-02-11",
        "nepDate": "28.10.2043"
    },
    {
        "engDate": "1987-02-12",
        "nepDate": "29.10.2043"
    },
    {
        "engDate": "1987-02-13",
        "nepDate": "01.11.2043"
    },
    {
        "engDate": "1987-02-14",
        "nepDate": "02.11.2043"
    },
    {
        "engDate": "1987-02-15",
        "nepDate": "03.11.2043"
    },
    {
        "engDate": "1987-02-16",
        "nepDate": "04.11.2043"
    },
    {
        "engDate": "1987-02-17",
        "nepDate": "05.11.2043"
    },
    {
        "engDate": "1987-02-18",
        "nepDate": "06.11.2043"
    },
    {
        "engDate": "1987-02-19",
        "nepDate": "07.11.2043"
    },
    {
        "engDate": "1987-02-20",
        "nepDate": "08.11.2043"
    },
    {
        "engDate": "1987-02-21",
        "nepDate": "09.11.2043"
    },
    {
        "engDate": "1987-02-22",
        "nepDate": "10.11.2043"
    },
    {
        "engDate": "1987-02-23",
        "nepDate": "11.11.2043"
    },
    {
        "engDate": "1987-02-24",
        "nepDate": "12.11.2043"
    },
    {
        "engDate": "1987-02-25",
        "nepDate": "13.11.2043"
    },
    {
        "engDate": "1987-02-26",
        "nepDate": "14.11.2043"
    },
    {
        "engDate": "1987-02-27",
        "nepDate": "15.11.2043"
    },
    {
        "engDate": "1987-02-28",
        "nepDate": "16.11.2043"
    },
    {
        "engDate": "1987-03-01",
        "nepDate": "17.11.2043"
    },
    {
        "engDate": "1987-03-02",
        "nepDate": "18.11.2043"
    },
    {
        "engDate": "1987-03-03",
        "nepDate": "19.11.2043"
    },
    {
        "engDate": "1987-03-04",
        "nepDate": "20.11.2043"
    },
    {
        "engDate": "1987-03-05",
        "nepDate": "21.11.2043"
    },
    {
        "engDate": "1987-03-06",
        "nepDate": "22.11.2043"
    },
    {
        "engDate": "1987-03-07",
        "nepDate": "23.11.2043"
    },
    {
        "engDate": "1987-03-08",
        "nepDate": "24.11.2043"
    },
    {
        "engDate": "1987-03-09",
        "nepDate": "25.11.2043"
    },
    {
        "engDate": "1987-03-10",
        "nepDate": "26.11.2043"
    },
    {
        "engDate": "1987-03-11",
        "nepDate": "27.11.2043"
    },
    {
        "engDate": "1987-03-12",
        "nepDate": "28.11.2043"
    },
    {
        "engDate": "1987-03-13",
        "nepDate": "29.11.2043"
    },
    {
        "engDate": "1987-03-14",
        "nepDate": "30.11.2043"
    },
    {
        "engDate": "1987-03-15",
        "nepDate": "01.12.2043"
    },
    {
        "engDate": "1987-03-16",
        "nepDate": "02.12.2043"
    },
    {
        "engDate": "1987-03-17",
        "nepDate": "03.12.2043"
    },
    {
        "engDate": "1987-03-18",
        "nepDate": "04.12.2043"
    },
    {
        "engDate": "1987-03-19",
        "nepDate": "05.12.2043"
    },
    {
        "engDate": "1987-03-20",
        "nepDate": "06.12.2043"
    },
    {
        "engDate": "1987-03-21",
        "nepDate": "07.12.2043"
    },
    {
        "engDate": "1987-03-22",
        "nepDate": "08.12.2043"
    },
    {
        "engDate": "1987-03-23",
        "nepDate": "09.12.2043"
    },
    {
        "engDate": "1987-03-24",
        "nepDate": "10.12.2043"
    },
    {
        "engDate": "1987-03-25",
        "nepDate": "11.12.2043"
    },
    {
        "engDate": "1987-03-26",
        "nepDate": "12.12.2043"
    },
    {
        "engDate": "1987-03-27",
        "nepDate": "13.12.2043"
    },
    {
        "engDate": "1987-03-28",
        "nepDate": "14.12.2043"
    },
    {
        "engDate": "1987-03-29",
        "nepDate": "15.12.2043"
    },
    {
        "engDate": "1987-03-30",
        "nepDate": "16.12.2043"
    },
    {
        "engDate": "1987-03-31",
        "nepDate": "17.12.2043"
    },
    {
        "engDate": "1987-04-01",
        "nepDate": "18.12.2043"
    },
    {
        "engDate": "1987-04-02",
        "nepDate": "19.12.2043"
    },
    {
        "engDate": "1987-04-03",
        "nepDate": "20.12.2043"
    },
    {
        "engDate": "1987-04-04",
        "nepDate": "21.12.2043"
    },
    {
        "engDate": "1987-04-05",
        "nepDate": "22.12.2043"
    },
    {
        "engDate": "1987-04-06",
        "nepDate": "23.12.2043"
    },
    {
        "engDate": "1987-04-07",
        "nepDate": "24.12.2043"
    },
    {
        "engDate": "1987-04-08",
        "nepDate": "25.12.2043"
    },
    {
        "engDate": "1987-04-09",
        "nepDate": "26.12.2043"
    },
    {
        "engDate": "1987-04-10",
        "nepDate": "27.12.2043"
    },
    {
        "engDate": "1987-04-11",
        "nepDate": "28.12.2043"
    },
    {
        "engDate": "1987-04-12",
        "nepDate": "29.12.2043"
    },
    {
        "engDate": "1987-04-13",
        "nepDate": "30.12.2043"
    },
    {
        "engDate": "1987-04-14",
        "nepDate": "01.01.2044"
    },
    {
        "engDate": "1987-04-15",
        "nepDate": "02.01.2044"
    },
    {
        "engDate": "1987-04-16",
        "nepDate": "03.01.2044"
    },
    {
        "engDate": "1987-04-17",
        "nepDate": "04.01.2044"
    },
    {
        "engDate": "1987-04-18",
        "nepDate": "05.01.2044"
    },
    {
        "engDate": "1987-04-19",
        "nepDate": "06.01.2044"
    },
    {
        "engDate": "1987-04-20",
        "nepDate": "07.01.2044"
    },
    {
        "engDate": "1987-04-21",
        "nepDate": "08.01.2044"
    },
    {
        "engDate": "1987-04-22",
        "nepDate": "09.01.2044"
    },
    {
        "engDate": "1987-04-23",
        "nepDate": "10.01.2044"
    },
    {
        "engDate": "1987-04-24",
        "nepDate": "11.01.2044"
    },
    {
        "engDate": "1987-04-25",
        "nepDate": "12.01.2044"
    },
    {
        "engDate": "1987-04-26",
        "nepDate": "13.01.2044"
    },
    {
        "engDate": "1987-04-27",
        "nepDate": "14.01.2044"
    },
    {
        "engDate": "1987-04-28",
        "nepDate": "15.01.2044"
    },
    {
        "engDate": "1987-04-29",
        "nepDate": "16.01.2044"
    },
    {
        "engDate": "1987-04-30",
        "nepDate": "17.01.2044"
    },
    {
        "engDate": "1987-05-01",
        "nepDate": "18.01.2044"
    },
    {
        "engDate": "1987-05-02",
        "nepDate": "19.01.2044"
    },
    {
        "engDate": "1987-05-03",
        "nepDate": "20.01.2044"
    },
    {
        "engDate": "1987-05-04",
        "nepDate": "21.01.2044"
    },
    {
        "engDate": "1987-05-05",
        "nepDate": "22.01.2044"
    },
    {
        "engDate": "1987-05-06",
        "nepDate": "23.01.2044"
    },
    {
        "engDate": "1987-05-07",
        "nepDate": "24.01.2044"
    },
    {
        "engDate": "1987-05-08",
        "nepDate": "25.01.2044"
    },
    {
        "engDate": "1987-05-09",
        "nepDate": "26.01.2044"
    },
    {
        "engDate": "1987-05-10",
        "nepDate": "27.01.2044"
    },
    {
        "engDate": "1987-05-11",
        "nepDate": "28.01.2044"
    },
    {
        "engDate": "1987-05-12",
        "nepDate": "29.01.2044"
    },
    {
        "engDate": "1987-05-13",
        "nepDate": "30.01.2044"
    },
    {
        "engDate": "1987-05-14",
        "nepDate": "31.01.2044"
    },
    {
        "engDate": "1987-05-15",
        "nepDate": "01.02.2044"
    },
    {
        "engDate": "1987-05-16",
        "nepDate": "02.02.2044"
    },
    {
        "engDate": "1987-05-17",
        "nepDate": "03.02.2044"
    },
    {
        "engDate": "1987-05-18",
        "nepDate": "04.02.2044"
    },
    {
        "engDate": "1987-05-19",
        "nepDate": "05.02.2044"
    },
    {
        "engDate": "1987-05-20",
        "nepDate": "06.02.2044"
    },
    {
        "engDate": "1987-05-21",
        "nepDate": "07.02.2044"
    },
    {
        "engDate": "1987-05-22",
        "nepDate": "08.02.2044"
    },
    {
        "engDate": "1987-05-23",
        "nepDate": "09.02.2044"
    },
    {
        "engDate": "1987-05-24",
        "nepDate": "10.02.2044"
    },
    {
        "engDate": "1987-05-25",
        "nepDate": "11.02.2044"
    },
    {
        "engDate": "1987-05-26",
        "nepDate": "12.02.2044"
    },
    {
        "engDate": "1987-05-27",
        "nepDate": "13.02.2044"
    },
    {
        "engDate": "1987-05-28",
        "nepDate": "14.02.2044"
    },
    {
        "engDate": "1987-05-29",
        "nepDate": "15.02.2044"
    },
    {
        "engDate": "1987-05-30",
        "nepDate": "16.02.2044"
    },
    {
        "engDate": "1987-05-31",
        "nepDate": "17.02.2044"
    },
    {
        "engDate": "1987-06-01",
        "nepDate": "18.02.2044"
    },
    {
        "engDate": "1987-06-02",
        "nepDate": "19.02.2044"
    },
    {
        "engDate": "1987-06-03",
        "nepDate": "20.02.2044"
    },
    {
        "engDate": "1987-06-04",
        "nepDate": "21.02.2044"
    },
    {
        "engDate": "1987-06-05",
        "nepDate": "22.02.2044"
    },
    {
        "engDate": "1987-06-06",
        "nepDate": "23.02.2044"
    },
    {
        "engDate": "1987-06-07",
        "nepDate": "24.02.2044"
    },
    {
        "engDate": "1987-06-08",
        "nepDate": "25.02.2044"
    },
    {
        "engDate": "1987-06-09",
        "nepDate": "26.02.2044"
    },
    {
        "engDate": "1987-06-10",
        "nepDate": "27.02.2044"
    },
    {
        "engDate": "1987-06-11",
        "nepDate": "28.02.2044"
    },
    {
        "engDate": "1987-06-12",
        "nepDate": "29.02.2044"
    },
    {
        "engDate": "1987-06-13",
        "nepDate": "30.02.2044"
    },
    {
        "engDate": "1987-06-14",
        "nepDate": "31.02.2044"
    },
    {
        "engDate": "1987-06-15",
        "nepDate": "01.03.2044"
    },
    {
        "engDate": "1987-06-16",
        "nepDate": "02.03.2044"
    },
    {
        "engDate": "1987-06-17",
        "nepDate": "03.03.2044"
    },
    {
        "engDate": "1987-06-18",
        "nepDate": "04.03.2044"
    },
    {
        "engDate": "1987-06-19",
        "nepDate": "05.03.2044"
    },
    {
        "engDate": "1987-06-20",
        "nepDate": "06.03.2044"
    },
    {
        "engDate": "1987-06-21",
        "nepDate": "07.03.2044"
    },
    {
        "engDate": "1987-06-22",
        "nepDate": "08.03.2044"
    },
    {
        "engDate": "1987-06-23",
        "nepDate": "09.03.2044"
    },
    {
        "engDate": "1987-06-24",
        "nepDate": "10.03.2044"
    },
    {
        "engDate": "1987-06-25",
        "nepDate": "11.03.2044"
    },
    {
        "engDate": "1987-06-26",
        "nepDate": "12.03.2044"
    },
    {
        "engDate": "1987-06-27",
        "nepDate": "13.03.2044"
    },
    {
        "engDate": "1987-06-28",
        "nepDate": "14.03.2044"
    },
    {
        "engDate": "1987-06-29",
        "nepDate": "15.03.2044"
    },
    {
        "engDate": "1987-06-30",
        "nepDate": "16.03.2044"
    },
    {
        "engDate": "1987-07-01",
        "nepDate": "17.03.2044"
    },
    {
        "engDate": "1987-07-02",
        "nepDate": "18.03.2044"
    },
    {
        "engDate": "1987-07-03",
        "nepDate": "19.03.2044"
    },
    {
        "engDate": "1987-07-04",
        "nepDate": "20.03.2044"
    },
    {
        "engDate": "1987-07-05",
        "nepDate": "21.03.2044"
    },
    {
        "engDate": "1987-07-06",
        "nepDate": "22.03.2044"
    },
    {
        "engDate": "1987-07-07",
        "nepDate": "23.03.2044"
    },
    {
        "engDate": "1987-07-08",
        "nepDate": "24.03.2044"
    },
    {
        "engDate": "1987-07-09",
        "nepDate": "25.03.2044"
    },
    {
        "engDate": "1987-07-10",
        "nepDate": "26.03.2044"
    },
    {
        "engDate": "1987-07-11",
        "nepDate": "27.03.2044"
    },
    {
        "engDate": "1987-07-12",
        "nepDate": "28.03.2044"
    },
    {
        "engDate": "1987-07-13",
        "nepDate": "29.03.2044"
    },
    {
        "engDate": "1987-07-14",
        "nepDate": "30.03.2044"
    },
    {
        "engDate": "1987-07-15",
        "nepDate": "31.03.2044"
    },
    {
        "engDate": "1987-07-16",
        "nepDate": "32.03.2044"
    },
    {
        "engDate": "1987-07-17",
        "nepDate": "01.04.2044"
    },
    {
        "engDate": "1987-07-18",
        "nepDate": "02.04.2044"
    },
    {
        "engDate": "1987-07-19",
        "nepDate": "03.04.2044"
    },
    {
        "engDate": "1987-07-20",
        "nepDate": "04.04.2044"
    },
    {
        "engDate": "1987-07-21",
        "nepDate": "05.04.2044"
    },
    {
        "engDate": "1987-07-22",
        "nepDate": "06.04.2044"
    },
    {
        "engDate": "1987-07-23",
        "nepDate": "07.04.2044"
    },
    {
        "engDate": "1987-07-24",
        "nepDate": "08.04.2044"
    },
    {
        "engDate": "1987-07-25",
        "nepDate": "09.04.2044"
    },
    {
        "engDate": "1987-07-26",
        "nepDate": "10.04.2044"
    },
    {
        "engDate": "1987-07-27",
        "nepDate": "11.04.2044"
    },
    {
        "engDate": "1987-07-28",
        "nepDate": "12.04.2044"
    },
    {
        "engDate": "1987-07-29",
        "nepDate": "13.04.2044"
    },
    {
        "engDate": "1987-07-30",
        "nepDate": "14.04.2044"
    },
    {
        "engDate": "1987-07-31",
        "nepDate": "15.04.2044"
    },
    {
        "engDate": "1987-08-01",
        "nepDate": "16.04.2044"
    },
    {
        "engDate": "1987-08-02",
        "nepDate": "17.04.2044"
    },
    {
        "engDate": "1987-08-03",
        "nepDate": "18.04.2044"
    },
    {
        "engDate": "1987-08-04",
        "nepDate": "19.04.2044"
    },
    {
        "engDate": "1987-08-05",
        "nepDate": "20.04.2044"
    },
    {
        "engDate": "1987-08-06",
        "nepDate": "21.04.2044"
    },
    {
        "engDate": "1987-08-07",
        "nepDate": "22.04.2044"
    },
    {
        "engDate": "1987-08-08",
        "nepDate": "23.04.2044"
    },
    {
        "engDate": "1987-08-09",
        "nepDate": "24.04.2044"
    },
    {
        "engDate": "1987-08-10",
        "nepDate": "25.04.2044"
    },
    {
        "engDate": "1987-08-11",
        "nepDate": "26.04.2044"
    },
    {
        "engDate": "1987-08-12",
        "nepDate": "27.04.2044"
    },
    {
        "engDate": "1987-08-13",
        "nepDate": "28.04.2044"
    },
    {
        "engDate": "1987-08-14",
        "nepDate": "29.04.2044"
    },
    {
        "engDate": "1987-08-15",
        "nepDate": "30.04.2044"
    },
    {
        "engDate": "1987-08-16",
        "nepDate": "31.04.2044"
    },
    {
        "engDate": "1987-08-17",
        "nepDate": "01.05.2044"
    },
    {
        "engDate": "1987-08-18",
        "nepDate": "02.05.2044"
    },
    {
        "engDate": "1987-08-19",
        "nepDate": "03.05.2044"
    },
    {
        "engDate": "1987-08-20",
        "nepDate": "04.05.2044"
    },
    {
        "engDate": "1987-08-21",
        "nepDate": "05.05.2044"
    },
    {
        "engDate": "1987-08-22",
        "nepDate": "06.05.2044"
    },
    {
        "engDate": "1987-08-23",
        "nepDate": "07.05.2044"
    },
    {
        "engDate": "1987-08-24",
        "nepDate": "08.05.2044"
    },
    {
        "engDate": "1987-08-25",
        "nepDate": "09.05.2044"
    },
    {
        "engDate": "1987-08-26",
        "nepDate": "10.05.2044"
    },
    {
        "engDate": "1987-08-27",
        "nepDate": "11.05.2044"
    },
    {
        "engDate": "1987-08-28",
        "nepDate": "12.05.2044"
    },
    {
        "engDate": "1987-08-29",
        "nepDate": "13.05.2044"
    },
    {
        "engDate": "1987-08-30",
        "nepDate": "14.05.2044"
    },
    {
        "engDate": "1987-08-31",
        "nepDate": "15.05.2044"
    },
    {
        "engDate": "1987-09-01",
        "nepDate": "16.05.2044"
    },
    {
        "engDate": "1987-09-02",
        "nepDate": "17.05.2044"
    },
    {
        "engDate": "1987-09-03",
        "nepDate": "18.05.2044"
    },
    {
        "engDate": "1987-09-04",
        "nepDate": "19.05.2044"
    },
    {
        "engDate": "1987-09-05",
        "nepDate": "20.05.2044"
    },
    {
        "engDate": "1987-09-06",
        "nepDate": "21.05.2044"
    },
    {
        "engDate": "1987-09-07",
        "nepDate": "22.05.2044"
    },
    {
        "engDate": "1987-09-08",
        "nepDate": "23.05.2044"
    },
    {
        "engDate": "1987-09-09",
        "nepDate": "24.05.2044"
    },
    {
        "engDate": "1987-09-10",
        "nepDate": "25.05.2044"
    },
    {
        "engDate": "1987-09-11",
        "nepDate": "26.05.2044"
    },
    {
        "engDate": "1987-09-12",
        "nepDate": "27.05.2044"
    },
    {
        "engDate": "1987-09-13",
        "nepDate": "28.05.2044"
    },
    {
        "engDate": "1987-09-14",
        "nepDate": "29.05.2044"
    },
    {
        "engDate": "1987-09-15",
        "nepDate": "30.05.2044"
    },
    {
        "engDate": "1987-09-16",
        "nepDate": "31.05.2044"
    },
    {
        "engDate": "1987-09-17",
        "nepDate": "01.06.2044"
    },
    {
        "engDate": "1987-09-18",
        "nepDate": "02.06.2044"
    },
    {
        "engDate": "1987-09-19",
        "nepDate": "03.06.2044"
    },
    {
        "engDate": "1987-09-20",
        "nepDate": "04.06.2044"
    },
    {
        "engDate": "1987-09-21",
        "nepDate": "05.06.2044"
    },
    {
        "engDate": "1987-09-22",
        "nepDate": "06.06.2044"
    },
    {
        "engDate": "1987-09-23",
        "nepDate": "07.06.2044"
    },
    {
        "engDate": "1987-09-24",
        "nepDate": "08.06.2044"
    },
    {
        "engDate": "1987-09-25",
        "nepDate": "09.06.2044"
    },
    {
        "engDate": "1987-09-26",
        "nepDate": "10.06.2044"
    },
    {
        "engDate": "1987-09-27",
        "nepDate": "11.06.2044"
    },
    {
        "engDate": "1987-09-28",
        "nepDate": "12.06.2044"
    },
    {
        "engDate": "1987-09-29",
        "nepDate": "13.06.2044"
    },
    {
        "engDate": "1987-09-30",
        "nepDate": "14.06.2044"
    },
    {
        "engDate": "1987-10-01",
        "nepDate": "15.06.2044"
    },
    {
        "engDate": "1987-10-02",
        "nepDate": "16.06.2044"
    },
    {
        "engDate": "1987-10-03",
        "nepDate": "17.06.2044"
    },
    {
        "engDate": "1987-10-04",
        "nepDate": "18.06.2044"
    },
    {
        "engDate": "1987-10-05",
        "nepDate": "19.06.2044"
    },
    {
        "engDate": "1987-10-06",
        "nepDate": "20.06.2044"
    },
    {
        "engDate": "1987-10-07",
        "nepDate": "21.06.2044"
    },
    {
        "engDate": "1987-10-08",
        "nepDate": "22.06.2044"
    },
    {
        "engDate": "1987-10-09",
        "nepDate": "23.06.2044"
    },
    {
        "engDate": "1987-10-10",
        "nepDate": "24.06.2044"
    },
    {
        "engDate": "1987-10-11",
        "nepDate": "25.06.2044"
    },
    {
        "engDate": "1987-10-12",
        "nepDate": "26.06.2044"
    },
    {
        "engDate": "1987-10-13",
        "nepDate": "27.06.2044"
    },
    {
        "engDate": "1987-10-14",
        "nepDate": "28.06.2044"
    },
    {
        "engDate": "1987-10-15",
        "nepDate": "29.06.2044"
    },
    {
        "engDate": "1987-10-16",
        "nepDate": "30.06.2044"
    },
    {
        "engDate": "1987-10-17",
        "nepDate": "31.06.2044"
    },
    {
        "engDate": "1987-10-18",
        "nepDate": "01.07.2044"
    },
    {
        "engDate": "1987-10-19",
        "nepDate": "02.07.2044"
    },
    {
        "engDate": "1987-10-20",
        "nepDate": "03.07.2044"
    },
    {
        "engDate": "1987-10-21",
        "nepDate": "04.07.2044"
    },
    {
        "engDate": "1987-10-22",
        "nepDate": "05.07.2044"
    },
    {
        "engDate": "1987-10-23",
        "nepDate": "06.07.2044"
    },
    {
        "engDate": "1987-10-24",
        "nepDate": "07.07.2044"
    },
    {
        "engDate": "1987-10-25",
        "nepDate": "08.07.2044"
    },
    {
        "engDate": "1987-10-26",
        "nepDate": "09.07.2044"
    },
    {
        "engDate": "1987-10-27",
        "nepDate": "10.07.2044"
    },
    {
        "engDate": "1987-10-28",
        "nepDate": "11.07.2044"
    },
    {
        "engDate": "1987-10-29",
        "nepDate": "12.07.2044"
    },
    {
        "engDate": "1987-10-30",
        "nepDate": "13.07.2044"
    },
    {
        "engDate": "1987-10-31",
        "nepDate": "14.07.2044"
    },
    {
        "engDate": "1987-11-01",
        "nepDate": "15.07.2044"
    },
    {
        "engDate": "1987-11-02",
        "nepDate": "16.07.2044"
    },
    {
        "engDate": "1987-11-03",
        "nepDate": "17.07.2044"
    },
    {
        "engDate": "1987-11-04",
        "nepDate": "18.07.2044"
    },
    {
        "engDate": "1987-11-05",
        "nepDate": "19.07.2044"
    },
    {
        "engDate": "1987-11-06",
        "nepDate": "20.07.2044"
    },
    {
        "engDate": "1987-11-07",
        "nepDate": "21.07.2044"
    },
    {
        "engDate": "1987-11-08",
        "nepDate": "22.07.2044"
    },
    {
        "engDate": "1987-11-09",
        "nepDate": "23.07.2044"
    },
    {
        "engDate": "1987-11-10",
        "nepDate": "24.07.2044"
    },
    {
        "engDate": "1987-11-11",
        "nepDate": "25.07.2044"
    },
    {
        "engDate": "1987-11-12",
        "nepDate": "26.07.2044"
    },
    {
        "engDate": "1987-11-13",
        "nepDate": "27.07.2044"
    },
    {
        "engDate": "1987-11-14",
        "nepDate": "28.07.2044"
    },
    {
        "engDate": "1987-11-15",
        "nepDate": "29.07.2044"
    },
    {
        "engDate": "1987-11-16",
        "nepDate": "30.07.2044"
    },
    {
        "engDate": "1987-11-17",
        "nepDate": "01.08.2044"
    },
    {
        "engDate": "1987-11-18",
        "nepDate": "02.08.2044"
    },
    {
        "engDate": "1987-11-19",
        "nepDate": "03.08.2044"
    },
    {
        "engDate": "1987-11-20",
        "nepDate": "04.08.2044"
    },
    {
        "engDate": "1987-11-21",
        "nepDate": "05.08.2044"
    },
    {
        "engDate": "1987-11-22",
        "nepDate": "06.08.2044"
    },
    {
        "engDate": "1987-11-23",
        "nepDate": "07.08.2044"
    },
    {
        "engDate": "1987-11-24",
        "nepDate": "08.08.2044"
    },
    {
        "engDate": "1987-11-25",
        "nepDate": "09.08.2044"
    },
    {
        "engDate": "1987-11-26",
        "nepDate": "10.08.2044"
    },
    {
        "engDate": "1987-11-27",
        "nepDate": "11.08.2044"
    },
    {
        "engDate": "1987-11-28",
        "nepDate": "12.08.2044"
    },
    {
        "engDate": "1987-11-29",
        "nepDate": "13.08.2044"
    },
    {
        "engDate": "1987-11-30",
        "nepDate": "14.08.2044"
    },
    {
        "engDate": "1987-12-01",
        "nepDate": "15.08.2044"
    },
    {
        "engDate": "1987-12-02",
        "nepDate": "16.08.2044"
    },
    {
        "engDate": "1987-12-03",
        "nepDate": "17.08.2044"
    },
    {
        "engDate": "1987-12-04",
        "nepDate": "18.08.2044"
    },
    {
        "engDate": "1987-12-05",
        "nepDate": "19.08.2044"
    },
    {
        "engDate": "1987-12-06",
        "nepDate": "20.08.2044"
    },
    {
        "engDate": "1987-12-07",
        "nepDate": "21.08.2044"
    },
    {
        "engDate": "1987-12-08",
        "nepDate": "22.08.2044"
    },
    {
        "engDate": "1987-12-09",
        "nepDate": "23.08.2044"
    },
    {
        "engDate": "1987-12-10",
        "nepDate": "24.08.2044"
    },
    {
        "engDate": "1987-12-11",
        "nepDate": "25.08.2044"
    },
    {
        "engDate": "1987-12-12",
        "nepDate": "26.08.2044"
    },
    {
        "engDate": "1987-12-13",
        "nepDate": "27.08.2044"
    },
    {
        "engDate": "1987-12-14",
        "nepDate": "28.08.2044"
    },
    {
        "engDate": "1987-12-15",
        "nepDate": "29.08.2044"
    },
    {
        "engDate": "1987-12-16",
        "nepDate": "01.09.2044"
    },
    {
        "engDate": "1987-12-17",
        "nepDate": "02.09.2044"
    },
    {
        "engDate": "1987-12-18",
        "nepDate": "03.09.2044"
    },
    {
        "engDate": "1987-12-19",
        "nepDate": "04.09.2044"
    },
    {
        "engDate": "1987-12-20",
        "nepDate": "05.09.2044"
    },
    {
        "engDate": "1987-12-21",
        "nepDate": "06.09.2044"
    },
    {
        "engDate": "1987-12-22",
        "nepDate": "07.09.2044"
    },
    {
        "engDate": "1987-12-23",
        "nepDate": "08.09.2044"
    },
    {
        "engDate": "1987-12-24",
        "nepDate": "09.09.2044"
    },
    {
        "engDate": "1987-12-25",
        "nepDate": "10.09.2044"
    },
    {
        "engDate": "1987-12-26",
        "nepDate": "11.09.2044"
    },
    {
        "engDate": "1987-12-27",
        "nepDate": "12.09.2044"
    },
    {
        "engDate": "1987-12-28",
        "nepDate": "13.09.2044"
    },
    {
        "engDate": "1987-12-29",
        "nepDate": "14.09.2044"
    },
    {
        "engDate": "1987-12-30",
        "nepDate": "15.09.2044"
    },
    {
        "engDate": "1987-12-31",
        "nepDate": "16.09.2044"
    },
    {
        "engDate": "1988-01-01",
        "nepDate": "17.09.2044"
    },
    {
        "engDate": "1988-01-02",
        "nepDate": "18.09.2044"
    },
    {
        "engDate": "1988-01-03",
        "nepDate": "19.09.2044"
    },
    {
        "engDate": "1988-01-04",
        "nepDate": "20.09.2044"
    },
    {
        "engDate": "1988-01-05",
        "nepDate": "21.09.2044"
    },
    {
        "engDate": "1988-01-06",
        "nepDate": "22.09.2044"
    },
    {
        "engDate": "1988-01-07",
        "nepDate": "23.09.2044"
    },
    {
        "engDate": "1988-01-08",
        "nepDate": "24.09.2044"
    },
    {
        "engDate": "1988-01-09",
        "nepDate": "25.09.2044"
    },
    {
        "engDate": "1988-01-10",
        "nepDate": "26.09.2044"
    },
    {
        "engDate": "1988-01-11",
        "nepDate": "27.09.2044"
    },
    {
        "engDate": "1988-01-12",
        "nepDate": "28.09.2044"
    },
    {
        "engDate": "1988-01-13",
        "nepDate": "29.09.2044"
    },
    {
        "engDate": "1988-01-14",
        "nepDate": "30.09.2044"
    },
    {
        "engDate": "1988-01-15",
        "nepDate": "01.10.2044"
    },
    {
        "engDate": "1988-01-16",
        "nepDate": "02.10.2044"
    },
    {
        "engDate": "1988-01-17",
        "nepDate": "03.10.2044"
    },
    {
        "engDate": "1988-01-18",
        "nepDate": "04.10.2044"
    },
    {
        "engDate": "1988-01-19",
        "nepDate": "05.10.2044"
    },
    {
        "engDate": "1988-01-20",
        "nepDate": "06.10.2044"
    },
    {
        "engDate": "1988-01-21",
        "nepDate": "07.10.2044"
    },
    {
        "engDate": "1988-01-22",
        "nepDate": "08.10.2044"
    },
    {
        "engDate": "1988-01-23",
        "nepDate": "09.10.2044"
    },
    {
        "engDate": "1988-01-24",
        "nepDate": "10.10.2044"
    },
    {
        "engDate": "1988-01-25",
        "nepDate": "11.10.2044"
    },
    {
        "engDate": "1988-01-26",
        "nepDate": "12.10.2044"
    },
    {
        "engDate": "1988-01-27",
        "nepDate": "13.10.2044"
    },
    {
        "engDate": "1988-01-28",
        "nepDate": "14.10.2044"
    },
    {
        "engDate": "1988-01-29",
        "nepDate": "15.10.2044"
    },
    {
        "engDate": "1988-01-30",
        "nepDate": "16.10.2044"
    },
    {
        "engDate": "1988-01-31",
        "nepDate": "17.10.2044"
    },
    {
        "engDate": "1988-02-01",
        "nepDate": "18.10.2044"
    },
    {
        "engDate": "1988-02-02",
        "nepDate": "19.10.2044"
    },
    {
        "engDate": "1988-02-03",
        "nepDate": "20.10.2044"
    },
    {
        "engDate": "1988-02-04",
        "nepDate": "21.10.2044"
    },
    {
        "engDate": "1988-02-05",
        "nepDate": "22.10.2044"
    },
    {
        "engDate": "1988-02-06",
        "nepDate": "23.10.2044"
    },
    {
        "engDate": "1988-02-07",
        "nepDate": "24.10.2044"
    },
    {
        "engDate": "1988-02-08",
        "nepDate": "25.10.2044"
    },
    {
        "engDate": "1988-02-09",
        "nepDate": "26.10.2044"
    },
    {
        "engDate": "1988-02-10",
        "nepDate": "27.10.2044"
    },
    {
        "engDate": "1988-02-11",
        "nepDate": "28.10.2044"
    },
    {
        "engDate": "1988-02-12",
        "nepDate": "29.10.2044"
    },
    {
        "engDate": "1988-02-13",
        "nepDate": "01.11.2044"
    },
    {
        "engDate": "1988-02-14",
        "nepDate": "02.11.2044"
    },
    {
        "engDate": "1988-02-15",
        "nepDate": "03.11.2044"
    },
    {
        "engDate": "1988-02-16",
        "nepDate": "04.11.2044"
    },
    {
        "engDate": "1988-02-17",
        "nepDate": "05.11.2044"
    },
    {
        "engDate": "1988-02-18",
        "nepDate": "06.11.2044"
    },
    {
        "engDate": "1988-02-19",
        "nepDate": "07.11.2044"
    },
    {
        "engDate": "1988-02-20",
        "nepDate": "08.11.2044"
    },
    {
        "engDate": "1988-02-21",
        "nepDate": "09.11.2044"
    },
    {
        "engDate": "1988-02-22",
        "nepDate": "10.11.2044"
    },
    {
        "engDate": "1988-02-23",
        "nepDate": "11.11.2044"
    },
    {
        "engDate": "1988-02-24",
        "nepDate": "12.11.2044"
    },
    {
        "engDate": "1988-02-25",
        "nepDate": "13.11.2044"
    },
    {
        "engDate": "1988-02-26",
        "nepDate": "14.11.2044"
    },
    {
        "engDate": "1988-02-27",
        "nepDate": "15.11.2044"
    },
    {
        "engDate": "1988-02-28",
        "nepDate": "16.11.2044"
    },
    {
        "engDate": "1988-02-29",
        "nepDate": "17.11.2044"
    },
    {
        "engDate": "1988-03-01",
        "nepDate": "18.11.2044"
    },
    {
        "engDate": "1988-03-02",
        "nepDate": "19.11.2044"
    },
    {
        "engDate": "1988-03-03",
        "nepDate": "20.11.2044"
    },
    {
        "engDate": "1988-03-04",
        "nepDate": "21.11.2044"
    },
    {
        "engDate": "1988-03-05",
        "nepDate": "22.11.2044"
    },
    {
        "engDate": "1988-03-06",
        "nepDate": "23.11.2044"
    },
    {
        "engDate": "1988-03-07",
        "nepDate": "24.11.2044"
    },
    {
        "engDate": "1988-03-08",
        "nepDate": "25.11.2044"
    },
    {
        "engDate": "1988-03-09",
        "nepDate": "26.11.2044"
    },
    {
        "engDate": "1988-03-10",
        "nepDate": "27.11.2044"
    },
    {
        "engDate": "1988-03-11",
        "nepDate": "28.11.2044"
    },
    {
        "engDate": "1988-03-12",
        "nepDate": "29.11.2044"
    },
    {
        "engDate": "1988-03-13",
        "nepDate": "30.11.2044"
    },
    {
        "engDate": "1988-03-14",
        "nepDate": "01.12.2044"
    },
    {
        "engDate": "1988-03-15",
        "nepDate": "02.12.2044"
    },
    {
        "engDate": "1988-03-16",
        "nepDate": "03.12.2044"
    },
    {
        "engDate": "1988-03-17",
        "nepDate": "04.12.2044"
    },
    {
        "engDate": "1988-03-18",
        "nepDate": "05.12.2044"
    },
    {
        "engDate": "1988-03-19",
        "nepDate": "06.12.2044"
    },
    {
        "engDate": "1988-03-20",
        "nepDate": "07.12.2044"
    },
    {
        "engDate": "1988-03-21",
        "nepDate": "08.12.2044"
    },
    {
        "engDate": "1988-03-22",
        "nepDate": "09.12.2044"
    },
    {
        "engDate": "1988-03-23",
        "nepDate": "10.12.2044"
    },
    {
        "engDate": "1988-03-24",
        "nepDate": "11.12.2044"
    },
    {
        "engDate": "1988-03-25",
        "nepDate": "12.12.2044"
    },
    {
        "engDate": "1988-03-26",
        "nepDate": "13.12.2044"
    },
    {
        "engDate": "1988-03-27",
        "nepDate": "14.12.2044"
    },
    {
        "engDate": "1988-03-28",
        "nepDate": "15.12.2044"
    },
    {
        "engDate": "1988-03-29",
        "nepDate": "16.12.2044"
    },
    {
        "engDate": "1988-03-30",
        "nepDate": "17.12.2044"
    },
    {
        "engDate": "1988-03-31",
        "nepDate": "18.12.2044"
    },
    {
        "engDate": "1988-04-01",
        "nepDate": "19.12.2044"
    },
    {
        "engDate": "1988-04-02",
        "nepDate": "20.12.2044"
    },
    {
        "engDate": "1988-04-03",
        "nepDate": "21.12.2044"
    },
    {
        "engDate": "1988-04-04",
        "nepDate": "22.12.2044"
    },
    {
        "engDate": "1988-04-05",
        "nepDate": "23.12.2044"
    },
    {
        "engDate": "1988-04-06",
        "nepDate": "24.12.2044"
    },
    {
        "engDate": "1988-04-07",
        "nepDate": "25.12.2044"
    },
    {
        "engDate": "1988-04-08",
        "nepDate": "26.12.2044"
    },
    {
        "engDate": "1988-04-09",
        "nepDate": "27.12.2044"
    },
    {
        "engDate": "1988-04-10",
        "nepDate": "28.12.2044"
    },
    {
        "engDate": "1988-04-11",
        "nepDate": "29.12.2044"
    },
    {
        "engDate": "1988-04-12",
        "nepDate": "30.12.2044"
    },
    {
        "engDate": "1988-04-13",
        "nepDate": "01.01.2045"
    },
    {
        "engDate": "1988-04-14",
        "nepDate": "02.01.2045"
    },
    {
        "engDate": "1988-04-15",
        "nepDate": "03.01.2045"
    },
    {
        "engDate": "1988-04-16",
        "nepDate": "04.01.2045"
    },
    {
        "engDate": "1988-04-17",
        "nepDate": "05.01.2045"
    },
    {
        "engDate": "1988-04-18",
        "nepDate": "06.01.2045"
    },
    {
        "engDate": "1988-04-19",
        "nepDate": "07.01.2045"
    },
    {
        "engDate": "1988-04-20",
        "nepDate": "08.01.2045"
    },
    {
        "engDate": "1988-04-21",
        "nepDate": "09.01.2045"
    },
    {
        "engDate": "1988-04-22",
        "nepDate": "10.01.2045"
    },
    {
        "engDate": "1988-04-23",
        "nepDate": "11.01.2045"
    },
    {
        "engDate": "1988-04-24",
        "nepDate": "12.01.2045"
    },
    {
        "engDate": "1988-04-25",
        "nepDate": "13.01.2045"
    },
    {
        "engDate": "1988-04-26",
        "nepDate": "14.01.2045"
    },
    {
        "engDate": "1988-04-27",
        "nepDate": "15.01.2045"
    },
    {
        "engDate": "1988-04-28",
        "nepDate": "16.01.2045"
    },
    {
        "engDate": "1988-04-29",
        "nepDate": "17.01.2045"
    },
    {
        "engDate": "1988-04-30",
        "nepDate": "18.01.2045"
    },
    {
        "engDate": "1988-05-01",
        "nepDate": "19.01.2045"
    },
    {
        "engDate": "1988-05-02",
        "nepDate": "20.01.2045"
    },
    {
        "engDate": "1988-05-03",
        "nepDate": "21.01.2045"
    },
    {
        "engDate": "1988-05-04",
        "nepDate": "22.01.2045"
    },
    {
        "engDate": "1988-05-05",
        "nepDate": "23.01.2045"
    },
    {
        "engDate": "1988-05-06",
        "nepDate": "24.01.2045"
    },
    {
        "engDate": "1988-05-07",
        "nepDate": "25.01.2045"
    },
    {
        "engDate": "1988-05-08",
        "nepDate": "26.01.2045"
    },
    {
        "engDate": "1988-05-09",
        "nepDate": "27.01.2045"
    },
    {
        "engDate": "1988-05-10",
        "nepDate": "28.01.2045"
    },
    {
        "engDate": "1988-05-11",
        "nepDate": "29.01.2045"
    },
    {
        "engDate": "1988-05-12",
        "nepDate": "30.01.2045"
    },
    {
        "engDate": "1988-05-13",
        "nepDate": "31.01.2045"
    },
    {
        "engDate": "1988-05-14",
        "nepDate": "01.02.2045"
    },
    {
        "engDate": "1988-05-15",
        "nepDate": "02.02.2045"
    },
    {
        "engDate": "1988-05-16",
        "nepDate": "03.02.2045"
    },
    {
        "engDate": "1988-05-17",
        "nepDate": "04.02.2045"
    },
    {
        "engDate": "1988-05-18",
        "nepDate": "05.02.2045"
    },
    {
        "engDate": "1988-05-19",
        "nepDate": "06.02.2045"
    },
    {
        "engDate": "1988-05-20",
        "nepDate": "07.02.2045"
    },
    {
        "engDate": "1988-05-21",
        "nepDate": "08.02.2045"
    },
    {
        "engDate": "1988-05-22",
        "nepDate": "09.02.2045"
    },
    {
        "engDate": "1988-05-23",
        "nepDate": "10.02.2045"
    },
    {
        "engDate": "1988-05-24",
        "nepDate": "11.02.2045"
    },
    {
        "engDate": "1988-05-25",
        "nepDate": "12.02.2045"
    },
    {
        "engDate": "1988-05-26",
        "nepDate": "13.02.2045"
    },
    {
        "engDate": "1988-05-27",
        "nepDate": "14.02.2045"
    },
    {
        "engDate": "1988-05-28",
        "nepDate": "15.02.2045"
    },
    {
        "engDate": "1988-05-29",
        "nepDate": "16.02.2045"
    },
    {
        "engDate": "1988-05-30",
        "nepDate": "17.02.2045"
    },
    {
        "engDate": "1988-05-31",
        "nepDate": "18.02.2045"
    },
    {
        "engDate": "1988-06-01",
        "nepDate": "19.02.2045"
    },
    {
        "engDate": "1988-06-02",
        "nepDate": "20.02.2045"
    },
    {
        "engDate": "1988-06-03",
        "nepDate": "21.02.2045"
    },
    {
        "engDate": "1988-06-04",
        "nepDate": "22.02.2045"
    },
    {
        "engDate": "1988-06-05",
        "nepDate": "23.02.2045"
    },
    {
        "engDate": "1988-06-06",
        "nepDate": "24.02.2045"
    },
    {
        "engDate": "1988-06-07",
        "nepDate": "25.02.2045"
    },
    {
        "engDate": "1988-06-08",
        "nepDate": "26.02.2045"
    },
    {
        "engDate": "1988-06-09",
        "nepDate": "27.02.2045"
    },
    {
        "engDate": "1988-06-10",
        "nepDate": "28.02.2045"
    },
    {
        "engDate": "1988-06-11",
        "nepDate": "29.02.2045"
    },
    {
        "engDate": "1988-06-12",
        "nepDate": "30.02.2045"
    },
    {
        "engDate": "1988-06-13",
        "nepDate": "31.02.2045"
    },
    {
        "engDate": "1988-06-14",
        "nepDate": "32.02.2045"
    },
    {
        "engDate": "1988-06-15",
        "nepDate": "01.03.2045"
    },
    {
        "engDate": "1988-06-16",
        "nepDate": "02.03.2045"
    },
    {
        "engDate": "1988-06-17",
        "nepDate": "03.03.2045"
    },
    {
        "engDate": "1988-06-18",
        "nepDate": "04.03.2045"
    },
    {
        "engDate": "1988-06-19",
        "nepDate": "05.03.2045"
    },
    {
        "engDate": "1988-06-20",
        "nepDate": "06.03.2045"
    },
    {
        "engDate": "1988-06-21",
        "nepDate": "07.03.2045"
    },
    {
        "engDate": "1988-06-22",
        "nepDate": "08.03.2045"
    },
    {
        "engDate": "1988-06-23",
        "nepDate": "09.03.2045"
    },
    {
        "engDate": "1988-06-24",
        "nepDate": "10.03.2045"
    },
    {
        "engDate": "1988-06-25",
        "nepDate": "11.03.2045"
    },
    {
        "engDate": "1988-06-26",
        "nepDate": "12.03.2045"
    },
    {
        "engDate": "1988-06-27",
        "nepDate": "13.03.2045"
    },
    {
        "engDate": "1988-06-28",
        "nepDate": "14.03.2045"
    },
    {
        "engDate": "1988-06-29",
        "nepDate": "15.03.2045"
    },
    {
        "engDate": "1988-06-30",
        "nepDate": "16.03.2045"
    },
    {
        "engDate": "1988-07-01",
        "nepDate": "17.03.2045"
    },
    {
        "engDate": "1988-07-02",
        "nepDate": "18.03.2045"
    },
    {
        "engDate": "1988-07-03",
        "nepDate": "19.03.2045"
    },
    {
        "engDate": "1988-07-04",
        "nepDate": "20.03.2045"
    },
    {
        "engDate": "1988-07-05",
        "nepDate": "21.03.2045"
    },
    {
        "engDate": "1988-07-06",
        "nepDate": "22.03.2045"
    },
    {
        "engDate": "1988-07-07",
        "nepDate": "23.03.2045"
    },
    {
        "engDate": "1988-07-08",
        "nepDate": "24.03.2045"
    },
    {
        "engDate": "1988-07-09",
        "nepDate": "25.03.2045"
    },
    {
        "engDate": "1988-07-10",
        "nepDate": "26.03.2045"
    },
    {
        "engDate": "1988-07-11",
        "nepDate": "27.03.2045"
    },
    {
        "engDate": "1988-07-12",
        "nepDate": "28.03.2045"
    },
    {
        "engDate": "1988-07-13",
        "nepDate": "29.03.2045"
    },
    {
        "engDate": "1988-07-14",
        "nepDate": "30.03.2045"
    },
    {
        "engDate": "1988-07-15",
        "nepDate": "31.03.2045"
    },
    {
        "engDate": "1988-07-16",
        "nepDate": "01.04.2045"
    },
    {
        "engDate": "1988-07-17",
        "nepDate": "02.04.2045"
    },
    {
        "engDate": "1988-07-18",
        "nepDate": "03.04.2045"
    },
    {
        "engDate": "1988-07-19",
        "nepDate": "04.04.2045"
    },
    {
        "engDate": "1988-07-20",
        "nepDate": "05.04.2045"
    },
    {
        "engDate": "1988-07-21",
        "nepDate": "06.04.2045"
    },
    {
        "engDate": "1988-07-22",
        "nepDate": "07.04.2045"
    },
    {
        "engDate": "1988-07-23",
        "nepDate": "08.04.2045"
    },
    {
        "engDate": "1988-07-24",
        "nepDate": "09.04.2045"
    },
    {
        "engDate": "1988-07-25",
        "nepDate": "10.04.2045"
    },
    {
        "engDate": "1988-07-26",
        "nepDate": "11.04.2045"
    },
    {
        "engDate": "1988-07-27",
        "nepDate": "12.04.2045"
    },
    {
        "engDate": "1988-07-28",
        "nepDate": "13.04.2045"
    },
    {
        "engDate": "1988-07-29",
        "nepDate": "14.04.2045"
    },
    {
        "engDate": "1988-07-30",
        "nepDate": "15.04.2045"
    },
    {
        "engDate": "1988-07-31",
        "nepDate": "16.04.2045"
    },
    {
        "engDate": "1988-08-01",
        "nepDate": "17.04.2045"
    },
    {
        "engDate": "1988-08-02",
        "nepDate": "18.04.2045"
    },
    {
        "engDate": "1988-08-03",
        "nepDate": "19.04.2045"
    },
    {
        "engDate": "1988-08-04",
        "nepDate": "20.04.2045"
    },
    {
        "engDate": "1988-08-05",
        "nepDate": "21.04.2045"
    },
    {
        "engDate": "1988-08-06",
        "nepDate": "22.04.2045"
    },
    {
        "engDate": "1988-08-07",
        "nepDate": "23.04.2045"
    },
    {
        "engDate": "1988-08-08",
        "nepDate": "24.04.2045"
    },
    {
        "engDate": "1988-08-09",
        "nepDate": "25.04.2045"
    },
    {
        "engDate": "1988-08-10",
        "nepDate": "26.04.2045"
    },
    {
        "engDate": "1988-08-11",
        "nepDate": "27.04.2045"
    },
    {
        "engDate": "1988-08-12",
        "nepDate": "28.04.2045"
    },
    {
        "engDate": "1988-08-13",
        "nepDate": "29.04.2045"
    },
    {
        "engDate": "1988-08-14",
        "nepDate": "30.04.2045"
    },
    {
        "engDate": "1988-08-15",
        "nepDate": "31.04.2045"
    },
    {
        "engDate": "1988-08-16",
        "nepDate": "32.04.2045"
    },
    {
        "engDate": "1988-08-17",
        "nepDate": "01.05.2045"
    },
    {
        "engDate": "1988-08-18",
        "nepDate": "02.05.2045"
    },
    {
        "engDate": "1988-08-19",
        "nepDate": "03.05.2045"
    },
    {
        "engDate": "1988-08-20",
        "nepDate": "04.05.2045"
    },
    {
        "engDate": "1988-08-21",
        "nepDate": "05.05.2045"
    },
    {
        "engDate": "1988-08-22",
        "nepDate": "06.05.2045"
    },
    {
        "engDate": "1988-08-23",
        "nepDate": "07.05.2045"
    },
    {
        "engDate": "1988-08-24",
        "nepDate": "08.05.2045"
    },
    {
        "engDate": "1988-08-25",
        "nepDate": "09.05.2045"
    },
    {
        "engDate": "1988-08-26",
        "nepDate": "10.05.2045"
    },
    {
        "engDate": "1988-08-27",
        "nepDate": "11.05.2045"
    },
    {
        "engDate": "1988-08-28",
        "nepDate": "12.05.2045"
    },
    {
        "engDate": "1988-08-29",
        "nepDate": "13.05.2045"
    },
    {
        "engDate": "1988-08-30",
        "nepDate": "14.05.2045"
    },
    {
        "engDate": "1988-08-31",
        "nepDate": "15.05.2045"
    },
    {
        "engDate": "1988-09-01",
        "nepDate": "16.05.2045"
    },
    {
        "engDate": "1988-09-02",
        "nepDate": "17.05.2045"
    },
    {
        "engDate": "1988-09-03",
        "nepDate": "18.05.2045"
    },
    {
        "engDate": "1988-09-04",
        "nepDate": "19.05.2045"
    },
    {
        "engDate": "1988-09-05",
        "nepDate": "20.05.2045"
    },
    {
        "engDate": "1988-09-06",
        "nepDate": "21.05.2045"
    },
    {
        "engDate": "1988-09-07",
        "nepDate": "22.05.2045"
    },
    {
        "engDate": "1988-09-08",
        "nepDate": "23.05.2045"
    },
    {
        "engDate": "1988-09-09",
        "nepDate": "24.05.2045"
    },
    {
        "engDate": "1988-09-10",
        "nepDate": "25.05.2045"
    },
    {
        "engDate": "1988-09-11",
        "nepDate": "26.05.2045"
    },
    {
        "engDate": "1988-09-12",
        "nepDate": "27.05.2045"
    },
    {
        "engDate": "1988-09-13",
        "nepDate": "28.05.2045"
    },
    {
        "engDate": "1988-09-14",
        "nepDate": "29.05.2045"
    },
    {
        "engDate": "1988-09-15",
        "nepDate": "30.05.2045"
    },
    {
        "engDate": "1988-09-16",
        "nepDate": "31.05.2045"
    },
    {
        "engDate": "1988-09-17",
        "nepDate": "01.06.2045"
    },
    {
        "engDate": "1988-09-18",
        "nepDate": "02.06.2045"
    },
    {
        "engDate": "1988-09-19",
        "nepDate": "03.06.2045"
    },
    {
        "engDate": "1988-09-20",
        "nepDate": "04.06.2045"
    },
    {
        "engDate": "1988-09-21",
        "nepDate": "05.06.2045"
    },
    {
        "engDate": "1988-09-22",
        "nepDate": "06.06.2045"
    },
    {
        "engDate": "1988-09-23",
        "nepDate": "07.06.2045"
    },
    {
        "engDate": "1988-09-24",
        "nepDate": "08.06.2045"
    },
    {
        "engDate": "1988-09-25",
        "nepDate": "09.06.2045"
    },
    {
        "engDate": "1988-09-26",
        "nepDate": "10.06.2045"
    },
    {
        "engDate": "1988-09-27",
        "nepDate": "11.06.2045"
    },
    {
        "engDate": "1988-09-28",
        "nepDate": "12.06.2045"
    },
    {
        "engDate": "1988-09-29",
        "nepDate": "13.06.2045"
    },
    {
        "engDate": "1988-09-30",
        "nepDate": "14.06.2045"
    },
    {
        "engDate": "1988-10-01",
        "nepDate": "15.06.2045"
    },
    {
        "engDate": "1988-10-02",
        "nepDate": "16.06.2045"
    },
    {
        "engDate": "1988-10-03",
        "nepDate": "17.06.2045"
    },
    {
        "engDate": "1988-10-04",
        "nepDate": "18.06.2045"
    },
    {
        "engDate": "1988-10-05",
        "nepDate": "19.06.2045"
    },
    {
        "engDate": "1988-10-06",
        "nepDate": "20.06.2045"
    },
    {
        "engDate": "1988-10-07",
        "nepDate": "21.06.2045"
    },
    {
        "engDate": "1988-10-08",
        "nepDate": "22.06.2045"
    },
    {
        "engDate": "1988-10-09",
        "nepDate": "23.06.2045"
    },
    {
        "engDate": "1988-10-10",
        "nepDate": "24.06.2045"
    },
    {
        "engDate": "1988-10-11",
        "nepDate": "25.06.2045"
    },
    {
        "engDate": "1988-10-12",
        "nepDate": "26.06.2045"
    },
    {
        "engDate": "1988-10-13",
        "nepDate": "27.06.2045"
    },
    {
        "engDate": "1988-10-14",
        "nepDate": "28.06.2045"
    },
    {
        "engDate": "1988-10-15",
        "nepDate": "29.06.2045"
    },
    {
        "engDate": "1988-10-16",
        "nepDate": "30.06.2045"
    },
    {
        "engDate": "1988-10-17",
        "nepDate": "01.07.2045"
    },
    {
        "engDate": "1988-10-18",
        "nepDate": "02.07.2045"
    },
    {
        "engDate": "1988-10-19",
        "nepDate": "03.07.2045"
    },
    {
        "engDate": "1988-10-20",
        "nepDate": "04.07.2045"
    },
    {
        "engDate": "1988-10-21",
        "nepDate": "05.07.2045"
    },
    {
        "engDate": "1988-10-22",
        "nepDate": "06.07.2045"
    },
    {
        "engDate": "1988-10-23",
        "nepDate": "07.07.2045"
    },
    {
        "engDate": "1988-10-24",
        "nepDate": "08.07.2045"
    },
    {
        "engDate": "1988-10-25",
        "nepDate": "09.07.2045"
    },
    {
        "engDate": "1988-10-26",
        "nepDate": "10.07.2045"
    },
    {
        "engDate": "1988-10-27",
        "nepDate": "11.07.2045"
    },
    {
        "engDate": "1988-10-28",
        "nepDate": "12.07.2045"
    },
    {
        "engDate": "1988-10-29",
        "nepDate": "13.07.2045"
    },
    {
        "engDate": "1988-10-30",
        "nepDate": "14.07.2045"
    },
    {
        "engDate": "1988-10-31",
        "nepDate": "15.07.2045"
    },
    {
        "engDate": "1988-11-01",
        "nepDate": "16.07.2045"
    },
    {
        "engDate": "1988-11-02",
        "nepDate": "17.07.2045"
    },
    {
        "engDate": "1988-11-03",
        "nepDate": "18.07.2045"
    },
    {
        "engDate": "1988-11-04",
        "nepDate": "19.07.2045"
    },
    {
        "engDate": "1988-11-05",
        "nepDate": "20.07.2045"
    },
    {
        "engDate": "1988-11-06",
        "nepDate": "21.07.2045"
    },
    {
        "engDate": "1988-11-07",
        "nepDate": "22.07.2045"
    },
    {
        "engDate": "1988-11-08",
        "nepDate": "23.07.2045"
    },
    {
        "engDate": "1988-11-09",
        "nepDate": "24.07.2045"
    },
    {
        "engDate": "1988-11-10",
        "nepDate": "25.07.2045"
    },
    {
        "engDate": "1988-11-11",
        "nepDate": "26.07.2045"
    },
    {
        "engDate": "1988-11-12",
        "nepDate": "27.07.2045"
    },
    {
        "engDate": "1988-11-13",
        "nepDate": "28.07.2045"
    },
    {
        "engDate": "1988-11-14",
        "nepDate": "29.07.2045"
    },
    {
        "engDate": "1988-11-15",
        "nepDate": "30.07.2045"
    },
    {
        "engDate": "1988-11-16",
        "nepDate": "01.08.2045"
    },
    {
        "engDate": "1988-11-17",
        "nepDate": "02.08.2045"
    },
    {
        "engDate": "1988-11-18",
        "nepDate": "03.08.2045"
    },
    {
        "engDate": "1988-11-19",
        "nepDate": "04.08.2045"
    },
    {
        "engDate": "1988-11-20",
        "nepDate": "05.08.2045"
    },
    {
        "engDate": "1988-11-21",
        "nepDate": "06.08.2045"
    },
    {
        "engDate": "1988-11-22",
        "nepDate": "07.08.2045"
    },
    {
        "engDate": "1988-11-23",
        "nepDate": "08.08.2045"
    },
    {
        "engDate": "1988-11-24",
        "nepDate": "09.08.2045"
    },
    {
        "engDate": "1988-11-25",
        "nepDate": "10.08.2045"
    },
    {
        "engDate": "1988-11-26",
        "nepDate": "11.08.2045"
    },
    {
        "engDate": "1988-11-27",
        "nepDate": "12.08.2045"
    },
    {
        "engDate": "1988-11-28",
        "nepDate": "13.08.2045"
    },
    {
        "engDate": "1988-11-29",
        "nepDate": "14.08.2045"
    },
    {
        "engDate": "1988-11-30",
        "nepDate": "15.08.2045"
    },
    {
        "engDate": "1988-12-01",
        "nepDate": "16.08.2045"
    },
    {
        "engDate": "1988-12-02",
        "nepDate": "17.08.2045"
    },
    {
        "engDate": "1988-12-03",
        "nepDate": "18.08.2045"
    },
    {
        "engDate": "1988-12-04",
        "nepDate": "19.08.2045"
    },
    {
        "engDate": "1988-12-05",
        "nepDate": "20.08.2045"
    },
    {
        "engDate": "1988-12-06",
        "nepDate": "21.08.2045"
    },
    {
        "engDate": "1988-12-07",
        "nepDate": "22.08.2045"
    },
    {
        "engDate": "1988-12-08",
        "nepDate": "23.08.2045"
    },
    {
        "engDate": "1988-12-09",
        "nepDate": "24.08.2045"
    },
    {
        "engDate": "1988-12-10",
        "nepDate": "25.08.2045"
    },
    {
        "engDate": "1988-12-11",
        "nepDate": "26.08.2045"
    },
    {
        "engDate": "1988-12-12",
        "nepDate": "27.08.2045"
    },
    {
        "engDate": "1988-12-13",
        "nepDate": "28.08.2045"
    },
    {
        "engDate": "1988-12-14",
        "nepDate": "29.08.2045"
    },
    {
        "engDate": "1988-12-15",
        "nepDate": "01.09.2045"
    },
    {
        "engDate": "1988-12-16",
        "nepDate": "02.09.2045"
    },
    {
        "engDate": "1988-12-17",
        "nepDate": "03.09.2045"
    },
    {
        "engDate": "1988-12-18",
        "nepDate": "04.09.2045"
    },
    {
        "engDate": "1988-12-19",
        "nepDate": "05.09.2045"
    },
    {
        "engDate": "1988-12-20",
        "nepDate": "06.09.2045"
    },
    {
        "engDate": "1988-12-21",
        "nepDate": "07.09.2045"
    },
    {
        "engDate": "1988-12-22",
        "nepDate": "08.09.2045"
    },
    {
        "engDate": "1988-12-23",
        "nepDate": "09.09.2045"
    },
    {
        "engDate": "1988-12-24",
        "nepDate": "10.09.2045"
    },
    {
        "engDate": "1988-12-25",
        "nepDate": "11.09.2045"
    },
    {
        "engDate": "1988-12-26",
        "nepDate": "12.09.2045"
    },
    {
        "engDate": "1988-12-27",
        "nepDate": "13.09.2045"
    },
    {
        "engDate": "1988-12-28",
        "nepDate": "14.09.2045"
    },
    {
        "engDate": "1988-12-29",
        "nepDate": "15.09.2045"
    },
    {
        "engDate": "1988-12-30",
        "nepDate": "16.09.2045"
    },
    {
        "engDate": "1988-12-31",
        "nepDate": "17.09.2045"
    },
    {
        "engDate": "1989-01-01",
        "nepDate": "18.09.2045"
    },
    {
        "engDate": "1989-01-02",
        "nepDate": "19.09.2045"
    },
    {
        "engDate": "1989-01-03",
        "nepDate": "20.09.2045"
    },
    {
        "engDate": "1989-01-04",
        "nepDate": "21.09.2045"
    },
    {
        "engDate": "1989-01-05",
        "nepDate": "22.09.2045"
    },
    {
        "engDate": "1989-01-06",
        "nepDate": "23.09.2045"
    },
    {
        "engDate": "1989-01-07",
        "nepDate": "24.09.2045"
    },
    {
        "engDate": "1989-01-08",
        "nepDate": "25.09.2045"
    },
    {
        "engDate": "1989-01-09",
        "nepDate": "26.09.2045"
    },
    {
        "engDate": "1989-01-10",
        "nepDate": "27.09.2045"
    },
    {
        "engDate": "1989-01-11",
        "nepDate": "28.09.2045"
    },
    {
        "engDate": "1989-01-12",
        "nepDate": "29.09.2045"
    },
    {
        "engDate": "1989-01-13",
        "nepDate": "30.09.2045"
    },
    {
        "engDate": "1989-01-14",
        "nepDate": "01.10.2045"
    },
    {
        "engDate": "1989-01-15",
        "nepDate": "02.10.2045"
    },
    {
        "engDate": "1989-01-16",
        "nepDate": "03.10.2045"
    },
    {
        "engDate": "1989-01-17",
        "nepDate": "04.10.2045"
    },
    {
        "engDate": "1989-01-18",
        "nepDate": "05.10.2045"
    },
    {
        "engDate": "1989-01-19",
        "nepDate": "06.10.2045"
    },
    {
        "engDate": "1989-01-20",
        "nepDate": "07.10.2045"
    },
    {
        "engDate": "1989-01-21",
        "nepDate": "08.10.2045"
    },
    {
        "engDate": "1989-01-22",
        "nepDate": "09.10.2045"
    },
    {
        "engDate": "1989-01-23",
        "nepDate": "10.10.2045"
    },
    {
        "engDate": "1989-01-24",
        "nepDate": "11.10.2045"
    },
    {
        "engDate": "1989-01-25",
        "nepDate": "12.10.2045"
    },
    {
        "engDate": "1989-01-26",
        "nepDate": "13.10.2045"
    },
    {
        "engDate": "1989-01-27",
        "nepDate": "14.10.2045"
    },
    {
        "engDate": "1989-01-28",
        "nepDate": "15.10.2045"
    },
    {
        "engDate": "1989-01-29",
        "nepDate": "16.10.2045"
    },
    {
        "engDate": "1989-01-30",
        "nepDate": "17.10.2045"
    },
    {
        "engDate": "1989-01-31",
        "nepDate": "18.10.2045"
    },
    {
        "engDate": "1989-02-01",
        "nepDate": "19.10.2045"
    },
    {
        "engDate": "1989-02-02",
        "nepDate": "20.10.2045"
    },
    {
        "engDate": "1989-02-03",
        "nepDate": "21.10.2045"
    },
    {
        "engDate": "1989-02-04",
        "nepDate": "22.10.2045"
    },
    {
        "engDate": "1989-02-05",
        "nepDate": "23.10.2045"
    },
    {
        "engDate": "1989-02-06",
        "nepDate": "24.10.2045"
    },
    {
        "engDate": "1989-02-07",
        "nepDate": "25.10.2045"
    },
    {
        "engDate": "1989-02-08",
        "nepDate": "26.10.2045"
    },
    {
        "engDate": "1989-02-09",
        "nepDate": "27.10.2045"
    },
    {
        "engDate": "1989-02-10",
        "nepDate": "28.10.2045"
    },
    {
        "engDate": "1989-02-11",
        "nepDate": "29.10.2045"
    },
    {
        "engDate": "1989-02-12",
        "nepDate": "01.11.2045"
    },
    {
        "engDate": "1989-02-13",
        "nepDate": "02.11.2045"
    },
    {
        "engDate": "1989-02-14",
        "nepDate": "03.11.2045"
    },
    {
        "engDate": "1989-02-15",
        "nepDate": "04.11.2045"
    },
    {
        "engDate": "1989-02-16",
        "nepDate": "05.11.2045"
    },
    {
        "engDate": "1989-02-17",
        "nepDate": "06.11.2045"
    },
    {
        "engDate": "1989-02-18",
        "nepDate": "07.11.2045"
    },
    {
        "engDate": "1989-02-19",
        "nepDate": "08.11.2045"
    },
    {
        "engDate": "1989-02-20",
        "nepDate": "09.11.2045"
    },
    {
        "engDate": "1989-02-21",
        "nepDate": "10.11.2045"
    },
    {
        "engDate": "1989-02-22",
        "nepDate": "11.11.2045"
    },
    {
        "engDate": "1989-02-23",
        "nepDate": "12.11.2045"
    },
    {
        "engDate": "1989-02-24",
        "nepDate": "13.11.2045"
    },
    {
        "engDate": "1989-02-25",
        "nepDate": "14.11.2045"
    },
    {
        "engDate": "1989-02-26",
        "nepDate": "15.11.2045"
    },
    {
        "engDate": "1989-02-27",
        "nepDate": "16.11.2045"
    },
    {
        "engDate": "1989-02-28",
        "nepDate": "17.11.2045"
    },
    {
        "engDate": "1989-03-01",
        "nepDate": "18.11.2045"
    },
    {
        "engDate": "1989-03-02",
        "nepDate": "19.11.2045"
    },
    {
        "engDate": "1989-03-03",
        "nepDate": "20.11.2045"
    },
    {
        "engDate": "1989-03-04",
        "nepDate": "21.11.2045"
    },
    {
        "engDate": "1989-03-05",
        "nepDate": "22.11.2045"
    },
    {
        "engDate": "1989-03-06",
        "nepDate": "23.11.2045"
    },
    {
        "engDate": "1989-03-07",
        "nepDate": "24.11.2045"
    },
    {
        "engDate": "1989-03-08",
        "nepDate": "25.11.2045"
    },
    {
        "engDate": "1989-03-09",
        "nepDate": "26.11.2045"
    },
    {
        "engDate": "1989-03-10",
        "nepDate": "27.11.2045"
    },
    {
        "engDate": "1989-03-11",
        "nepDate": "28.11.2045"
    },
    {
        "engDate": "1989-03-12",
        "nepDate": "29.11.2045"
    },
    {
        "engDate": "1989-03-13",
        "nepDate": "30.11.2045"
    },
    {
        "engDate": "1989-03-14",
        "nepDate": "01.12.2045"
    },
    {
        "engDate": "1989-03-15",
        "nepDate": "02.12.2045"
    },
    {
        "engDate": "1989-03-16",
        "nepDate": "03.12.2045"
    },
    {
        "engDate": "1989-03-17",
        "nepDate": "04.12.2045"
    },
    {
        "engDate": "1989-03-18",
        "nepDate": "05.12.2045"
    },
    {
        "engDate": "1989-03-19",
        "nepDate": "06.12.2045"
    },
    {
        "engDate": "1989-03-20",
        "nepDate": "07.12.2045"
    },
    {
        "engDate": "1989-03-21",
        "nepDate": "08.12.2045"
    },
    {
        "engDate": "1989-03-22",
        "nepDate": "09.12.2045"
    },
    {
        "engDate": "1989-03-23",
        "nepDate": "10.12.2045"
    },
    {
        "engDate": "1989-03-24",
        "nepDate": "11.12.2045"
    },
    {
        "engDate": "1989-03-25",
        "nepDate": "12.12.2045"
    },
    {
        "engDate": "1989-03-26",
        "nepDate": "13.12.2045"
    },
    {
        "engDate": "1989-03-27",
        "nepDate": "14.12.2045"
    },
    {
        "engDate": "1989-03-28",
        "nepDate": "15.12.2045"
    },
    {
        "engDate": "1989-03-29",
        "nepDate": "16.12.2045"
    },
    {
        "engDate": "1989-03-30",
        "nepDate": "17.12.2045"
    },
    {
        "engDate": "1989-03-31",
        "nepDate": "18.12.2045"
    },
    {
        "engDate": "1989-04-01",
        "nepDate": "19.12.2045"
    },
    {
        "engDate": "1989-04-02",
        "nepDate": "20.12.2045"
    },
    {
        "engDate": "1989-04-03",
        "nepDate": "21.12.2045"
    },
    {
        "engDate": "1989-04-04",
        "nepDate": "22.12.2045"
    },
    {
        "engDate": "1989-04-05",
        "nepDate": "23.12.2045"
    },
    {
        "engDate": "1989-04-06",
        "nepDate": "24.12.2045"
    },
    {
        "engDate": "1989-04-07",
        "nepDate": "25.12.2045"
    },
    {
        "engDate": "1989-04-08",
        "nepDate": "26.12.2045"
    },
    {
        "engDate": "1989-04-09",
        "nepDate": "27.12.2045"
    },
    {
        "engDate": "1989-04-10",
        "nepDate": "28.12.2045"
    },
    {
        "engDate": "1989-04-11",
        "nepDate": "29.12.2045"
    },
    {
        "engDate": "1989-04-12",
        "nepDate": "30.12.2045"
    },
    {
        "engDate": "1989-04-13",
        "nepDate": "01.01.2046"
    },
    {
        "engDate": "1989-04-14",
        "nepDate": "02.01.2046"
    },
    {
        "engDate": "1989-04-15",
        "nepDate": "03.01.2046"
    },
    {
        "engDate": "1989-04-16",
        "nepDate": "04.01.2046"
    },
    {
        "engDate": "1989-04-17",
        "nepDate": "05.01.2046"
    },
    {
        "engDate": "1989-04-18",
        "nepDate": "06.01.2046"
    },
    {
        "engDate": "1989-04-19",
        "nepDate": "07.01.2046"
    },
    {
        "engDate": "1989-04-20",
        "nepDate": "08.01.2046"
    },
    {
        "engDate": "1989-04-21",
        "nepDate": "09.01.2046"
    },
    {
        "engDate": "1989-04-22",
        "nepDate": "10.01.2046"
    },
    {
        "engDate": "1989-04-23",
        "nepDate": "11.01.2046"
    },
    {
        "engDate": "1989-04-24",
        "nepDate": "12.01.2046"
    },
    {
        "engDate": "1989-04-25",
        "nepDate": "13.01.2046"
    },
    {
        "engDate": "1989-04-26",
        "nepDate": "14.01.2046"
    },
    {
        "engDate": "1989-04-27",
        "nepDate": "15.01.2046"
    },
    {
        "engDate": "1989-04-28",
        "nepDate": "16.01.2046"
    },
    {
        "engDate": "1989-04-29",
        "nepDate": "17.01.2046"
    },
    {
        "engDate": "1989-04-30",
        "nepDate": "18.01.2046"
    },
    {
        "engDate": "1989-05-01",
        "nepDate": "19.01.2046"
    },
    {
        "engDate": "1989-05-02",
        "nepDate": "20.01.2046"
    },
    {
        "engDate": "1989-05-03",
        "nepDate": "21.01.2046"
    },
    {
        "engDate": "1989-05-04",
        "nepDate": "22.01.2046"
    },
    {
        "engDate": "1989-05-05",
        "nepDate": "23.01.2046"
    },
    {
        "engDate": "1989-05-06",
        "nepDate": "24.01.2046"
    },
    {
        "engDate": "1989-05-07",
        "nepDate": "25.01.2046"
    },
    {
        "engDate": "1989-05-08",
        "nepDate": "26.01.2046"
    },
    {
        "engDate": "1989-05-09",
        "nepDate": "27.01.2046"
    },
    {
        "engDate": "1989-05-10",
        "nepDate": "28.01.2046"
    },
    {
        "engDate": "1989-05-11",
        "nepDate": "29.01.2046"
    },
    {
        "engDate": "1989-05-12",
        "nepDate": "30.01.2046"
    },
    {
        "engDate": "1989-05-13",
        "nepDate": "31.01.2046"
    },
    {
        "engDate": "1989-05-14",
        "nepDate": "01.02.2046"
    },
    {
        "engDate": "1989-05-15",
        "nepDate": "02.02.2046"
    },
    {
        "engDate": "1989-05-16",
        "nepDate": "03.02.2046"
    },
    {
        "engDate": "1989-05-17",
        "nepDate": "04.02.2046"
    },
    {
        "engDate": "1989-05-18",
        "nepDate": "05.02.2046"
    },
    {
        "engDate": "1989-05-19",
        "nepDate": "06.02.2046"
    },
    {
        "engDate": "1989-05-20",
        "nepDate": "07.02.2046"
    },
    {
        "engDate": "1989-05-21",
        "nepDate": "08.02.2046"
    },
    {
        "engDate": "1989-05-22",
        "nepDate": "09.02.2046"
    },
    {
        "engDate": "1989-05-23",
        "nepDate": "10.02.2046"
    },
    {
        "engDate": "1989-05-24",
        "nepDate": "11.02.2046"
    },
    {
        "engDate": "1989-05-25",
        "nepDate": "12.02.2046"
    },
    {
        "engDate": "1989-05-26",
        "nepDate": "13.02.2046"
    },
    {
        "engDate": "1989-05-27",
        "nepDate": "14.02.2046"
    },
    {
        "engDate": "1989-05-28",
        "nepDate": "15.02.2046"
    },
    {
        "engDate": "1989-05-29",
        "nepDate": "16.02.2046"
    },
    {
        "engDate": "1989-05-30",
        "nepDate": "17.02.2046"
    },
    {
        "engDate": "1989-05-31",
        "nepDate": "18.02.2046"
    },
    {
        "engDate": "1989-06-01",
        "nepDate": "19.02.2046"
    },
    {
        "engDate": "1989-06-02",
        "nepDate": "20.02.2046"
    },
    {
        "engDate": "1989-06-03",
        "nepDate": "21.02.2046"
    },
    {
        "engDate": "1989-06-04",
        "nepDate": "22.02.2046"
    },
    {
        "engDate": "1989-06-05",
        "nepDate": "23.02.2046"
    },
    {
        "engDate": "1989-06-06",
        "nepDate": "24.02.2046"
    },
    {
        "engDate": "1989-06-07",
        "nepDate": "25.02.2046"
    },
    {
        "engDate": "1989-06-08",
        "nepDate": "26.02.2046"
    },
    {
        "engDate": "1989-06-09",
        "nepDate": "27.02.2046"
    },
    {
        "engDate": "1989-06-10",
        "nepDate": "28.02.2046"
    },
    {
        "engDate": "1989-06-11",
        "nepDate": "29.02.2046"
    },
    {
        "engDate": "1989-06-12",
        "nepDate": "30.02.2046"
    },
    {
        "engDate": "1989-06-13",
        "nepDate": "31.02.2046"
    },
    {
        "engDate": "1989-06-14",
        "nepDate": "32.02.2046"
    },
    {
        "engDate": "1989-06-15",
        "nepDate": "01.03.2046"
    },
    {
        "engDate": "1989-06-16",
        "nepDate": "02.03.2046"
    },
    {
        "engDate": "1989-06-17",
        "nepDate": "03.03.2046"
    },
    {
        "engDate": "1989-06-18",
        "nepDate": "04.03.2046"
    },
    {
        "engDate": "1989-06-19",
        "nepDate": "05.03.2046"
    },
    {
        "engDate": "1989-06-20",
        "nepDate": "06.03.2046"
    },
    {
        "engDate": "1989-06-21",
        "nepDate": "07.03.2046"
    },
    {
        "engDate": "1989-06-22",
        "nepDate": "08.03.2046"
    },
    {
        "engDate": "1989-06-23",
        "nepDate": "09.03.2046"
    },
    {
        "engDate": "1989-06-24",
        "nepDate": "10.03.2046"
    },
    {
        "engDate": "1989-06-25",
        "nepDate": "11.03.2046"
    },
    {
        "engDate": "1989-06-26",
        "nepDate": "12.03.2046"
    },
    {
        "engDate": "1989-06-27",
        "nepDate": "13.03.2046"
    },
    {
        "engDate": "1989-06-28",
        "nepDate": "14.03.2046"
    },
    {
        "engDate": "1989-06-29",
        "nepDate": "15.03.2046"
    },
    {
        "engDate": "1989-06-30",
        "nepDate": "16.03.2046"
    },
    {
        "engDate": "1989-07-01",
        "nepDate": "17.03.2046"
    },
    {
        "engDate": "1989-07-02",
        "nepDate": "18.03.2046"
    },
    {
        "engDate": "1989-07-03",
        "nepDate": "19.03.2046"
    },
    {
        "engDate": "1989-07-04",
        "nepDate": "20.03.2046"
    },
    {
        "engDate": "1989-07-05",
        "nepDate": "21.03.2046"
    },
    {
        "engDate": "1989-07-06",
        "nepDate": "22.03.2046"
    },
    {
        "engDate": "1989-07-07",
        "nepDate": "23.03.2046"
    },
    {
        "engDate": "1989-07-08",
        "nepDate": "24.03.2046"
    },
    {
        "engDate": "1989-07-09",
        "nepDate": "25.03.2046"
    },
    {
        "engDate": "1989-07-10",
        "nepDate": "26.03.2046"
    },
    {
        "engDate": "1989-07-11",
        "nepDate": "27.03.2046"
    },
    {
        "engDate": "1989-07-12",
        "nepDate": "28.03.2046"
    },
    {
        "engDate": "1989-07-13",
        "nepDate": "29.03.2046"
    },
    {
        "engDate": "1989-07-14",
        "nepDate": "30.03.2046"
    },
    {
        "engDate": "1989-07-15",
        "nepDate": "31.03.2046"
    },
    {
        "engDate": "1989-07-16",
        "nepDate": "01.04.2046"
    },
    {
        "engDate": "1989-07-17",
        "nepDate": "02.04.2046"
    },
    {
        "engDate": "1989-07-18",
        "nepDate": "03.04.2046"
    },
    {
        "engDate": "1989-07-19",
        "nepDate": "04.04.2046"
    },
    {
        "engDate": "1989-07-20",
        "nepDate": "05.04.2046"
    },
    {
        "engDate": "1989-07-21",
        "nepDate": "06.04.2046"
    },
    {
        "engDate": "1989-07-22",
        "nepDate": "07.04.2046"
    },
    {
        "engDate": "1989-07-23",
        "nepDate": "08.04.2046"
    },
    {
        "engDate": "1989-07-24",
        "nepDate": "09.04.2046"
    },
    {
        "engDate": "1989-07-25",
        "nepDate": "10.04.2046"
    },
    {
        "engDate": "1989-07-26",
        "nepDate": "11.04.2046"
    },
    {
        "engDate": "1989-07-27",
        "nepDate": "12.04.2046"
    },
    {
        "engDate": "1989-07-28",
        "nepDate": "13.04.2046"
    },
    {
        "engDate": "1989-07-29",
        "nepDate": "14.04.2046"
    },
    {
        "engDate": "1989-07-30",
        "nepDate": "15.04.2046"
    },
    {
        "engDate": "1989-07-31",
        "nepDate": "16.04.2046"
    },
    {
        "engDate": "1989-08-01",
        "nepDate": "17.04.2046"
    },
    {
        "engDate": "1989-08-02",
        "nepDate": "18.04.2046"
    },
    {
        "engDate": "1989-08-03",
        "nepDate": "19.04.2046"
    },
    {
        "engDate": "1989-08-04",
        "nepDate": "20.04.2046"
    },
    {
        "engDate": "1989-08-05",
        "nepDate": "21.04.2046"
    },
    {
        "engDate": "1989-08-06",
        "nepDate": "22.04.2046"
    },
    {
        "engDate": "1989-08-07",
        "nepDate": "23.04.2046"
    },
    {
        "engDate": "1989-08-08",
        "nepDate": "24.04.2046"
    },
    {
        "engDate": "1989-08-09",
        "nepDate": "25.04.2046"
    },
    {
        "engDate": "1989-08-10",
        "nepDate": "26.04.2046"
    },
    {
        "engDate": "1989-08-11",
        "nepDate": "27.04.2046"
    },
    {
        "engDate": "1989-08-12",
        "nepDate": "28.04.2046"
    },
    {
        "engDate": "1989-08-13",
        "nepDate": "29.04.2046"
    },
    {
        "engDate": "1989-08-14",
        "nepDate": "30.04.2046"
    },
    {
        "engDate": "1989-08-15",
        "nepDate": "31.04.2046"
    },
    {
        "engDate": "1989-08-16",
        "nepDate": "32.04.2046"
    },
    {
        "engDate": "1989-08-17",
        "nepDate": "01.05.2046"
    },
    {
        "engDate": "1989-08-18",
        "nepDate": "02.05.2046"
    },
    {
        "engDate": "1989-08-19",
        "nepDate": "03.05.2046"
    },
    {
        "engDate": "1989-08-20",
        "nepDate": "04.05.2046"
    },
    {
        "engDate": "1989-08-21",
        "nepDate": "05.05.2046"
    },
    {
        "engDate": "1989-08-22",
        "nepDate": "06.05.2046"
    },
    {
        "engDate": "1989-08-23",
        "nepDate": "07.05.2046"
    },
    {
        "engDate": "1989-08-24",
        "nepDate": "08.05.2046"
    },
    {
        "engDate": "1989-08-25",
        "nepDate": "09.05.2046"
    },
    {
        "engDate": "1989-08-26",
        "nepDate": "10.05.2046"
    },
    {
        "engDate": "1989-08-27",
        "nepDate": "11.05.2046"
    },
    {
        "engDate": "1989-08-28",
        "nepDate": "12.05.2046"
    },
    {
        "engDate": "1989-08-29",
        "nepDate": "13.05.2046"
    },
    {
        "engDate": "1989-08-30",
        "nepDate": "14.05.2046"
    },
    {
        "engDate": "1989-08-31",
        "nepDate": "15.05.2046"
    },
    {
        "engDate": "1989-09-01",
        "nepDate": "16.05.2046"
    },
    {
        "engDate": "1989-09-02",
        "nepDate": "17.05.2046"
    },
    {
        "engDate": "1989-09-03",
        "nepDate": "18.05.2046"
    },
    {
        "engDate": "1989-09-04",
        "nepDate": "19.05.2046"
    },
    {
        "engDate": "1989-09-05",
        "nepDate": "20.05.2046"
    },
    {
        "engDate": "1989-09-06",
        "nepDate": "21.05.2046"
    },
    {
        "engDate": "1989-09-07",
        "nepDate": "22.05.2046"
    },
    {
        "engDate": "1989-09-08",
        "nepDate": "23.05.2046"
    },
    {
        "engDate": "1989-09-09",
        "nepDate": "24.05.2046"
    },
    {
        "engDate": "1989-09-10",
        "nepDate": "25.05.2046"
    },
    {
        "engDate": "1989-09-11",
        "nepDate": "26.05.2046"
    },
    {
        "engDate": "1989-09-12",
        "nepDate": "27.05.2046"
    },
    {
        "engDate": "1989-09-13",
        "nepDate": "28.05.2046"
    },
    {
        "engDate": "1989-09-14",
        "nepDate": "29.05.2046"
    },
    {
        "engDate": "1989-09-15",
        "nepDate": "30.05.2046"
    },
    {
        "engDate": "1989-09-16",
        "nepDate": "31.05.2046"
    },
    {
        "engDate": "1989-09-17",
        "nepDate": "01.06.2046"
    },
    {
        "engDate": "1989-09-18",
        "nepDate": "02.06.2046"
    },
    {
        "engDate": "1989-09-19",
        "nepDate": "03.06.2046"
    },
    {
        "engDate": "1989-09-20",
        "nepDate": "04.06.2046"
    },
    {
        "engDate": "1989-09-21",
        "nepDate": "05.06.2046"
    },
    {
        "engDate": "1989-09-22",
        "nepDate": "06.06.2046"
    },
    {
        "engDate": "1989-09-23",
        "nepDate": "07.06.2046"
    },
    {
        "engDate": "1989-09-24",
        "nepDate": "08.06.2046"
    },
    {
        "engDate": "1989-09-25",
        "nepDate": "09.06.2046"
    },
    {
        "engDate": "1989-09-26",
        "nepDate": "10.06.2046"
    },
    {
        "engDate": "1989-09-27",
        "nepDate": "11.06.2046"
    },
    {
        "engDate": "1989-09-28",
        "nepDate": "12.06.2046"
    },
    {
        "engDate": "1989-09-29",
        "nepDate": "13.06.2046"
    },
    {
        "engDate": "1989-09-30",
        "nepDate": "14.06.2046"
    },
    {
        "engDate": "1989-10-01",
        "nepDate": "15.06.2046"
    },
    {
        "engDate": "1989-10-02",
        "nepDate": "16.06.2046"
    },
    {
        "engDate": "1989-10-03",
        "nepDate": "17.06.2046"
    },
    {
        "engDate": "1989-10-04",
        "nepDate": "18.06.2046"
    },
    {
        "engDate": "1989-10-05",
        "nepDate": "19.06.2046"
    },
    {
        "engDate": "1989-10-06",
        "nepDate": "20.06.2046"
    },
    {
        "engDate": "1989-10-07",
        "nepDate": "21.06.2046"
    },
    {
        "engDate": "1989-10-08",
        "nepDate": "22.06.2046"
    },
    {
        "engDate": "1989-10-09",
        "nepDate": "23.06.2046"
    },
    {
        "engDate": "1989-10-10",
        "nepDate": "24.06.2046"
    },
    {
        "engDate": "1989-10-11",
        "nepDate": "25.06.2046"
    },
    {
        "engDate": "1989-10-12",
        "nepDate": "26.06.2046"
    },
    {
        "engDate": "1989-10-13",
        "nepDate": "27.06.2046"
    },
    {
        "engDate": "1989-10-14",
        "nepDate": "28.06.2046"
    },
    {
        "engDate": "1989-10-15",
        "nepDate": "29.06.2046"
    },
    {
        "engDate": "1989-10-16",
        "nepDate": "30.06.2046"
    },
    {
        "engDate": "1989-10-17",
        "nepDate": "01.07.2046"
    },
    {
        "engDate": "1989-10-18",
        "nepDate": "02.07.2046"
    },
    {
        "engDate": "1989-10-19",
        "nepDate": "03.07.2046"
    },
    {
        "engDate": "1989-10-20",
        "nepDate": "04.07.2046"
    },
    {
        "engDate": "1989-10-21",
        "nepDate": "05.07.2046"
    },
    {
        "engDate": "1989-10-22",
        "nepDate": "06.07.2046"
    },
    {
        "engDate": "1989-10-23",
        "nepDate": "07.07.2046"
    },
    {
        "engDate": "1989-10-24",
        "nepDate": "08.07.2046"
    },
    {
        "engDate": "1989-10-25",
        "nepDate": "09.07.2046"
    },
    {
        "engDate": "1989-10-26",
        "nepDate": "10.07.2046"
    },
    {
        "engDate": "1989-10-27",
        "nepDate": "11.07.2046"
    },
    {
        "engDate": "1989-10-28",
        "nepDate": "12.07.2046"
    },
    {
        "engDate": "1989-10-29",
        "nepDate": "13.07.2046"
    },
    {
        "engDate": "1989-10-30",
        "nepDate": "14.07.2046"
    },
    {
        "engDate": "1989-10-31",
        "nepDate": "15.07.2046"
    },
    {
        "engDate": "1989-11-01",
        "nepDate": "16.07.2046"
    },
    {
        "engDate": "1989-11-02",
        "nepDate": "17.07.2046"
    },
    {
        "engDate": "1989-11-03",
        "nepDate": "18.07.2046"
    },
    {
        "engDate": "1989-11-04",
        "nepDate": "19.07.2046"
    },
    {
        "engDate": "1989-11-05",
        "nepDate": "20.07.2046"
    },
    {
        "engDate": "1989-11-06",
        "nepDate": "21.07.2046"
    },
    {
        "engDate": "1989-11-07",
        "nepDate": "22.07.2046"
    },
    {
        "engDate": "1989-11-08",
        "nepDate": "23.07.2046"
    },
    {
        "engDate": "1989-11-09",
        "nepDate": "24.07.2046"
    },
    {
        "engDate": "1989-11-10",
        "nepDate": "25.07.2046"
    },
    {
        "engDate": "1989-11-11",
        "nepDate": "26.07.2046"
    },
    {
        "engDate": "1989-11-12",
        "nepDate": "27.07.2046"
    },
    {
        "engDate": "1989-11-13",
        "nepDate": "28.07.2046"
    },
    {
        "engDate": "1989-11-14",
        "nepDate": "29.07.2046"
    },
    {
        "engDate": "1989-11-15",
        "nepDate": "30.07.2046"
    },
    {
        "engDate": "1989-11-16",
        "nepDate": "01.08.2046"
    },
    {
        "engDate": "1989-11-17",
        "nepDate": "02.08.2046"
    },
    {
        "engDate": "1989-11-18",
        "nepDate": "03.08.2046"
    },
    {
        "engDate": "1989-11-19",
        "nepDate": "04.08.2046"
    },
    {
        "engDate": "1989-11-20",
        "nepDate": "05.08.2046"
    },
    {
        "engDate": "1989-11-21",
        "nepDate": "06.08.2046"
    },
    {
        "engDate": "1989-11-22",
        "nepDate": "07.08.2046"
    },
    {
        "engDate": "1989-11-23",
        "nepDate": "08.08.2046"
    },
    {
        "engDate": "1989-11-24",
        "nepDate": "09.08.2046"
    },
    {
        "engDate": "1989-11-25",
        "nepDate": "10.08.2046"
    },
    {
        "engDate": "1989-11-26",
        "nepDate": "11.08.2046"
    },
    {
        "engDate": "1989-11-27",
        "nepDate": "12.08.2046"
    },
    {
        "engDate": "1989-11-28",
        "nepDate": "13.08.2046"
    },
    {
        "engDate": "1989-11-29",
        "nepDate": "14.08.2046"
    },
    {
        "engDate": "1989-11-30",
        "nepDate": "15.08.2046"
    },
    {
        "engDate": "1989-12-01",
        "nepDate": "16.08.2046"
    },
    {
        "engDate": "1989-12-02",
        "nepDate": "17.08.2046"
    },
    {
        "engDate": "1989-12-03",
        "nepDate": "18.08.2046"
    },
    {
        "engDate": "1989-12-04",
        "nepDate": "19.08.2046"
    },
    {
        "engDate": "1989-12-05",
        "nepDate": "20.08.2046"
    },
    {
        "engDate": "1989-12-06",
        "nepDate": "21.08.2046"
    },
    {
        "engDate": "1989-12-07",
        "nepDate": "22.08.2046"
    },
    {
        "engDate": "1989-12-08",
        "nepDate": "23.08.2046"
    },
    {
        "engDate": "1989-12-09",
        "nepDate": "24.08.2046"
    },
    {
        "engDate": "1989-12-10",
        "nepDate": "25.08.2046"
    },
    {
        "engDate": "1989-12-11",
        "nepDate": "26.08.2046"
    },
    {
        "engDate": "1989-12-12",
        "nepDate": "27.08.2046"
    },
    {
        "engDate": "1989-12-13",
        "nepDate": "28.08.2046"
    },
    {
        "engDate": "1989-12-14",
        "nepDate": "29.08.2046"
    },
    {
        "engDate": "1989-12-15",
        "nepDate": "30.08.2046"
    },
    {
        "engDate": "1989-12-16",
        "nepDate": "01.09.2046"
    },
    {
        "engDate": "1989-12-17",
        "nepDate": "02.09.2046"
    },
    {
        "engDate": "1989-12-18",
        "nepDate": "03.09.2046"
    },
    {
        "engDate": "1989-12-19",
        "nepDate": "04.09.2046"
    },
    {
        "engDate": "1989-12-20",
        "nepDate": "05.09.2046"
    },
    {
        "engDate": "1989-12-21",
        "nepDate": "06.09.2046"
    },
    {
        "engDate": "1989-12-22",
        "nepDate": "07.09.2046"
    },
    {
        "engDate": "1989-12-23",
        "nepDate": "08.09.2046"
    },
    {
        "engDate": "1989-12-24",
        "nepDate": "09.09.2046"
    },
    {
        "engDate": "1989-12-25",
        "nepDate": "10.09.2046"
    },
    {
        "engDate": "1989-12-26",
        "nepDate": "11.09.2046"
    },
    {
        "engDate": "1989-12-27",
        "nepDate": "12.09.2046"
    },
    {
        "engDate": "1989-12-28",
        "nepDate": "13.09.2046"
    },
    {
        "engDate": "1989-12-29",
        "nepDate": "14.09.2046"
    },
    {
        "engDate": "1989-12-30",
        "nepDate": "15.09.2046"
    },
    {
        "engDate": "1989-12-31",
        "nepDate": "16.09.2046"
    },
    {
        "engDate": "1990-01-01",
        "nepDate": "17.09.2046"
    },
    {
        "engDate": "1990-01-02",
        "nepDate": "18.09.2046"
    },
    {
        "engDate": "1990-01-03",
        "nepDate": "19.09.2046"
    },
    {
        "engDate": "1990-01-04",
        "nepDate": "20.09.2046"
    },
    {
        "engDate": "1990-01-05",
        "nepDate": "21.09.2046"
    },
    {
        "engDate": "1990-01-06",
        "nepDate": "22.09.2046"
    },
    {
        "engDate": "1990-01-07",
        "nepDate": "23.09.2046"
    },
    {
        "engDate": "1990-01-08",
        "nepDate": "24.09.2046"
    },
    {
        "engDate": "1990-01-09",
        "nepDate": "25.09.2046"
    },
    {
        "engDate": "1990-01-10",
        "nepDate": "26.09.2046"
    },
    {
        "engDate": "1990-01-11",
        "nepDate": "27.09.2046"
    },
    {
        "engDate": "1990-01-12",
        "nepDate": "28.09.2046"
    },
    {
        "engDate": "1990-01-13",
        "nepDate": "29.09.2046"
    },
    {
        "engDate": "1990-01-14",
        "nepDate": "01.10.2046"
    },
    {
        "engDate": "1990-01-15",
        "nepDate": "02.10.2046"
    },
    {
        "engDate": "1990-01-16",
        "nepDate": "03.10.2046"
    },
    {
        "engDate": "1990-01-17",
        "nepDate": "04.10.2046"
    },
    {
        "engDate": "1990-01-18",
        "nepDate": "05.10.2046"
    },
    {
        "engDate": "1990-01-19",
        "nepDate": "06.10.2046"
    },
    {
        "engDate": "1990-01-20",
        "nepDate": "07.10.2046"
    },
    {
        "engDate": "1990-01-21",
        "nepDate": "08.10.2046"
    },
    {
        "engDate": "1990-01-22",
        "nepDate": "09.10.2046"
    },
    {
        "engDate": "1990-01-23",
        "nepDate": "10.10.2046"
    },
    {
        "engDate": "1990-01-24",
        "nepDate": "11.10.2046"
    },
    {
        "engDate": "1990-01-25",
        "nepDate": "12.10.2046"
    },
    {
        "engDate": "1990-01-26",
        "nepDate": "13.10.2046"
    },
    {
        "engDate": "1990-01-27",
        "nepDate": "14.10.2046"
    },
    {
        "engDate": "1990-01-28",
        "nepDate": "15.10.2046"
    },
    {
        "engDate": "1990-01-29",
        "nepDate": "16.10.2046"
    },
    {
        "engDate": "1990-01-30",
        "nepDate": "17.10.2046"
    },
    {
        "engDate": "1990-01-31",
        "nepDate": "18.10.2046"
    },
    {
        "engDate": "1990-02-01",
        "nepDate": "19.10.2046"
    },
    {
        "engDate": "1990-02-02",
        "nepDate": "20.10.2046"
    },
    {
        "engDate": "1990-02-03",
        "nepDate": "21.10.2046"
    },
    {
        "engDate": "1990-02-04",
        "nepDate": "22.10.2046"
    },
    {
        "engDate": "1990-02-05",
        "nepDate": "23.10.2046"
    },
    {
        "engDate": "1990-02-06",
        "nepDate": "24.10.2046"
    },
    {
        "engDate": "1990-02-07",
        "nepDate": "25.10.2046"
    },
    {
        "engDate": "1990-02-08",
        "nepDate": "26.10.2046"
    },
    {
        "engDate": "1990-02-09",
        "nepDate": "27.10.2046"
    },
    {
        "engDate": "1990-02-10",
        "nepDate": "28.10.2046"
    },
    {
        "engDate": "1990-02-11",
        "nepDate": "29.10.2046"
    },
    {
        "engDate": "1990-02-12",
        "nepDate": "01.11.2046"
    },
    {
        "engDate": "1990-02-13",
        "nepDate": "02.11.2046"
    },
    {
        "engDate": "1990-02-14",
        "nepDate": "03.11.2046"
    },
    {
        "engDate": "1990-02-15",
        "nepDate": "04.11.2046"
    },
    {
        "engDate": "1990-02-16",
        "nepDate": "05.11.2046"
    },
    {
        "engDate": "1990-02-17",
        "nepDate": "06.11.2046"
    },
    {
        "engDate": "1990-02-18",
        "nepDate": "07.11.2046"
    },
    {
        "engDate": "1990-02-19",
        "nepDate": "08.11.2046"
    },
    {
        "engDate": "1990-02-20",
        "nepDate": "09.11.2046"
    },
    {
        "engDate": "1990-02-21",
        "nepDate": "10.11.2046"
    },
    {
        "engDate": "1990-02-22",
        "nepDate": "11.11.2046"
    },
    {
        "engDate": "1990-02-23",
        "nepDate": "12.11.2046"
    },
    {
        "engDate": "1990-02-24",
        "nepDate": "13.11.2046"
    },
    {
        "engDate": "1990-02-25",
        "nepDate": "14.11.2046"
    },
    {
        "engDate": "1990-02-26",
        "nepDate": "15.11.2046"
    },
    {
        "engDate": "1990-02-27",
        "nepDate": "16.11.2046"
    },
    {
        "engDate": "1990-02-28",
        "nepDate": "17.11.2046"
    },
    {
        "engDate": "1990-03-01",
        "nepDate": "18.11.2046"
    },
    {
        "engDate": "1990-03-02",
        "nepDate": "19.11.2046"
    },
    {
        "engDate": "1990-03-03",
        "nepDate": "20.11.2046"
    },
    {
        "engDate": "1990-03-04",
        "nepDate": "21.11.2046"
    },
    {
        "engDate": "1990-03-05",
        "nepDate": "22.11.2046"
    },
    {
        "engDate": "1990-03-06",
        "nepDate": "23.11.2046"
    },
    {
        "engDate": "1990-03-07",
        "nepDate": "24.11.2046"
    },
    {
        "engDate": "1990-03-08",
        "nepDate": "25.11.2046"
    },
    {
        "engDate": "1990-03-09",
        "nepDate": "26.11.2046"
    },
    {
        "engDate": "1990-03-10",
        "nepDate": "27.11.2046"
    },
    {
        "engDate": "1990-03-11",
        "nepDate": "28.11.2046"
    },
    {
        "engDate": "1990-03-12",
        "nepDate": "29.11.2046"
    },
    {
        "engDate": "1990-03-13",
        "nepDate": "30.11.2046"
    },
    {
        "engDate": "1990-03-14",
        "nepDate": "01.12.2046"
    },
    {
        "engDate": "1990-03-15",
        "nepDate": "02.12.2046"
    },
    {
        "engDate": "1990-03-16",
        "nepDate": "03.12.2046"
    },
    {
        "engDate": "1990-03-17",
        "nepDate": "04.12.2046"
    },
    {
        "engDate": "1990-03-18",
        "nepDate": "05.12.2046"
    },
    {
        "engDate": "1990-03-19",
        "nepDate": "06.12.2046"
    },
    {
        "engDate": "1990-03-20",
        "nepDate": "07.12.2046"
    },
    {
        "engDate": "1990-03-21",
        "nepDate": "08.12.2046"
    },
    {
        "engDate": "1990-03-22",
        "nepDate": "09.12.2046"
    },
    {
        "engDate": "1990-03-23",
        "nepDate": "10.12.2046"
    },
    {
        "engDate": "1990-03-24",
        "nepDate": "11.12.2046"
    },
    {
        "engDate": "1990-03-25",
        "nepDate": "12.12.2046"
    },
    {
        "engDate": "1990-03-26",
        "nepDate": "13.12.2046"
    },
    {
        "engDate": "1990-03-27",
        "nepDate": "14.12.2046"
    },
    {
        "engDate": "1990-03-28",
        "nepDate": "15.12.2046"
    },
    {
        "engDate": "1990-03-29",
        "nepDate": "16.12.2046"
    },
    {
        "engDate": "1990-03-30",
        "nepDate": "17.12.2046"
    },
    {
        "engDate": "1990-03-31",
        "nepDate": "18.12.2046"
    },
    {
        "engDate": "1990-04-01",
        "nepDate": "19.12.2046"
    },
    {
        "engDate": "1990-04-02",
        "nepDate": "20.12.2046"
    },
    {
        "engDate": "1990-04-03",
        "nepDate": "21.12.2046"
    },
    {
        "engDate": "1990-04-04",
        "nepDate": "22.12.2046"
    },
    {
        "engDate": "1990-04-05",
        "nepDate": "23.12.2046"
    },
    {
        "engDate": "1990-04-06",
        "nepDate": "24.12.2046"
    },
    {
        "engDate": "1990-04-07",
        "nepDate": "25.12.2046"
    },
    {
        "engDate": "1990-04-08",
        "nepDate": "26.12.2046"
    },
    {
        "engDate": "1990-04-09",
        "nepDate": "27.12.2046"
    },
    {
        "engDate": "1990-04-10",
        "nepDate": "28.12.2046"
    },
    {
        "engDate": "1990-04-11",
        "nepDate": "29.12.2046"
    },
    {
        "engDate": "1990-04-12",
        "nepDate": "30.12.2046"
    },
    {
        "engDate": "1990-04-13",
        "nepDate": "31.12.2046"
    },
    {
        "engDate": "1990-04-14",
        "nepDate": "01.01.2047"
    },
    {
        "engDate": "1990-04-15",
        "nepDate": "02.01.2047"
    },
    {
        "engDate": "1990-04-16",
        "nepDate": "03.01.2047"
    },
    {
        "engDate": "1990-04-17",
        "nepDate": "04.01.2047"
    },
    {
        "engDate": "1990-04-18",
        "nepDate": "05.01.2047"
    },
    {
        "engDate": "1990-04-19",
        "nepDate": "06.01.2047"
    },
    {
        "engDate": "1990-04-20",
        "nepDate": "07.01.2047"
    },
    {
        "engDate": "1990-04-21",
        "nepDate": "08.01.2047"
    },
    {
        "engDate": "1990-04-22",
        "nepDate": "09.01.2047"
    },
    {
        "engDate": "1990-04-23",
        "nepDate": "10.01.2047"
    },
    {
        "engDate": "1990-04-24",
        "nepDate": "11.01.2047"
    },
    {
        "engDate": "1990-04-25",
        "nepDate": "12.01.2047"
    },
    {
        "engDate": "1990-04-26",
        "nepDate": "13.01.2047"
    },
    {
        "engDate": "1990-04-27",
        "nepDate": "14.01.2047"
    },
    {
        "engDate": "1990-04-28",
        "nepDate": "15.01.2047"
    },
    {
        "engDate": "1990-04-29",
        "nepDate": "16.01.2047"
    },
    {
        "engDate": "1990-04-30",
        "nepDate": "17.01.2047"
    },
    {
        "engDate": "1990-05-01",
        "nepDate": "18.01.2047"
    },
    {
        "engDate": "1990-05-02",
        "nepDate": "19.01.2047"
    },
    {
        "engDate": "1990-05-03",
        "nepDate": "20.01.2047"
    },
    {
        "engDate": "1990-05-04",
        "nepDate": "21.01.2047"
    },
    {
        "engDate": "1990-05-05",
        "nepDate": "22.01.2047"
    },
    {
        "engDate": "1990-05-06",
        "nepDate": "23.01.2047"
    },
    {
        "engDate": "1990-05-07",
        "nepDate": "24.01.2047"
    },
    {
        "engDate": "1990-05-08",
        "nepDate": "25.01.2047"
    },
    {
        "engDate": "1990-05-09",
        "nepDate": "26.01.2047"
    },
    {
        "engDate": "1990-05-10",
        "nepDate": "27.01.2047"
    },
    {
        "engDate": "1990-05-11",
        "nepDate": "28.01.2047"
    },
    {
        "engDate": "1990-05-12",
        "nepDate": "29.01.2047"
    },
    {
        "engDate": "1990-05-13",
        "nepDate": "30.01.2047"
    },
    {
        "engDate": "1990-05-14",
        "nepDate": "31.01.2047"
    },
    {
        "engDate": "1990-05-15",
        "nepDate": "01.02.2047"
    },
    {
        "engDate": "1990-05-16",
        "nepDate": "02.02.2047"
    },
    {
        "engDate": "1990-05-17",
        "nepDate": "03.02.2047"
    },
    {
        "engDate": "1990-05-18",
        "nepDate": "04.02.2047"
    },
    {
        "engDate": "1990-05-19",
        "nepDate": "05.02.2047"
    },
    {
        "engDate": "1990-05-20",
        "nepDate": "06.02.2047"
    },
    {
        "engDate": "1990-05-21",
        "nepDate": "07.02.2047"
    },
    {
        "engDate": "1990-05-22",
        "nepDate": "08.02.2047"
    },
    {
        "engDate": "1990-05-23",
        "nepDate": "09.02.2047"
    },
    {
        "engDate": "1990-05-24",
        "nepDate": "10.02.2047"
    },
    {
        "engDate": "1990-05-25",
        "nepDate": "11.02.2047"
    },
    {
        "engDate": "1990-05-26",
        "nepDate": "12.02.2047"
    },
    {
        "engDate": "1990-05-27",
        "nepDate": "13.02.2047"
    },
    {
        "engDate": "1990-05-28",
        "nepDate": "14.02.2047"
    },
    {
        "engDate": "1990-05-29",
        "nepDate": "15.02.2047"
    },
    {
        "engDate": "1990-05-30",
        "nepDate": "16.02.2047"
    },
    {
        "engDate": "1990-05-31",
        "nepDate": "17.02.2047"
    },
    {
        "engDate": "1990-06-01",
        "nepDate": "18.02.2047"
    },
    {
        "engDate": "1990-06-02",
        "nepDate": "19.02.2047"
    },
    {
        "engDate": "1990-06-03",
        "nepDate": "20.02.2047"
    },
    {
        "engDate": "1990-06-04",
        "nepDate": "21.02.2047"
    },
    {
        "engDate": "1990-06-05",
        "nepDate": "22.02.2047"
    },
    {
        "engDate": "1990-06-06",
        "nepDate": "23.02.2047"
    },
    {
        "engDate": "1990-06-07",
        "nepDate": "24.02.2047"
    },
    {
        "engDate": "1990-06-08",
        "nepDate": "25.02.2047"
    },
    {
        "engDate": "1990-06-09",
        "nepDate": "26.02.2047"
    },
    {
        "engDate": "1990-06-10",
        "nepDate": "27.02.2047"
    },
    {
        "engDate": "1990-06-11",
        "nepDate": "28.02.2047"
    },
    {
        "engDate": "1990-06-12",
        "nepDate": "29.02.2047"
    },
    {
        "engDate": "1990-06-13",
        "nepDate": "30.02.2047"
    },
    {
        "engDate": "1990-06-14",
        "nepDate": "31.02.2047"
    },
    {
        "engDate": "1990-06-15",
        "nepDate": "01.03.2047"
    },
    {
        "engDate": "1990-06-16",
        "nepDate": "02.03.2047"
    },
    {
        "engDate": "1990-06-17",
        "nepDate": "03.03.2047"
    },
    {
        "engDate": "1990-06-18",
        "nepDate": "04.03.2047"
    },
    {
        "engDate": "1990-06-19",
        "nepDate": "05.03.2047"
    },
    {
        "engDate": "1990-06-20",
        "nepDate": "06.03.2047"
    },
    {
        "engDate": "1990-06-21",
        "nepDate": "07.03.2047"
    },
    {
        "engDate": "1990-06-22",
        "nepDate": "08.03.2047"
    },
    {
        "engDate": "1990-06-23",
        "nepDate": "09.03.2047"
    },
    {
        "engDate": "1990-06-24",
        "nepDate": "10.03.2047"
    },
    {
        "engDate": "1990-06-25",
        "nepDate": "11.03.2047"
    },
    {
        "engDate": "1990-06-26",
        "nepDate": "12.03.2047"
    },
    {
        "engDate": "1990-06-27",
        "nepDate": "13.03.2047"
    },
    {
        "engDate": "1990-06-28",
        "nepDate": "14.03.2047"
    },
    {
        "engDate": "1990-06-29",
        "nepDate": "15.03.2047"
    },
    {
        "engDate": "1990-06-30",
        "nepDate": "16.03.2047"
    },
    {
        "engDate": "1990-07-01",
        "nepDate": "17.03.2047"
    },
    {
        "engDate": "1990-07-02",
        "nepDate": "18.03.2047"
    },
    {
        "engDate": "1990-07-03",
        "nepDate": "19.03.2047"
    },
    {
        "engDate": "1990-07-04",
        "nepDate": "20.03.2047"
    },
    {
        "engDate": "1990-07-05",
        "nepDate": "21.03.2047"
    },
    {
        "engDate": "1990-07-06",
        "nepDate": "22.03.2047"
    },
    {
        "engDate": "1990-07-07",
        "nepDate": "23.03.2047"
    },
    {
        "engDate": "1990-07-08",
        "nepDate": "24.03.2047"
    },
    {
        "engDate": "1990-07-09",
        "nepDate": "25.03.2047"
    },
    {
        "engDate": "1990-07-10",
        "nepDate": "26.03.2047"
    },
    {
        "engDate": "1990-07-11",
        "nepDate": "27.03.2047"
    },
    {
        "engDate": "1990-07-12",
        "nepDate": "28.03.2047"
    },
    {
        "engDate": "1990-07-13",
        "nepDate": "29.03.2047"
    },
    {
        "engDate": "1990-07-14",
        "nepDate": "30.03.2047"
    },
    {
        "engDate": "1990-07-15",
        "nepDate": "31.03.2047"
    },
    {
        "engDate": "1990-07-16",
        "nepDate": "01.04.2047"
    },
    {
        "engDate": "1990-07-17",
        "nepDate": "02.04.2047"
    },
    {
        "engDate": "1990-07-18",
        "nepDate": "03.04.2047"
    },
    {
        "engDate": "1990-07-19",
        "nepDate": "04.04.2047"
    },
    {
        "engDate": "1990-07-20",
        "nepDate": "05.04.2047"
    },
    {
        "engDate": "1990-07-21",
        "nepDate": "06.04.2047"
    },
    {
        "engDate": "1990-07-22",
        "nepDate": "07.04.2047"
    },
    {
        "engDate": "1990-07-23",
        "nepDate": "08.04.2047"
    },
    {
        "engDate": "1990-07-24",
        "nepDate": "09.04.2047"
    },
    {
        "engDate": "1990-07-25",
        "nepDate": "10.04.2047"
    },
    {
        "engDate": "1990-07-26",
        "nepDate": "11.04.2047"
    },
    {
        "engDate": "1990-07-27",
        "nepDate": "12.04.2047"
    },
    {
        "engDate": "1990-07-28",
        "nepDate": "13.04.2047"
    },
    {
        "engDate": "1990-07-29",
        "nepDate": "14.04.2047"
    },
    {
        "engDate": "1990-07-30",
        "nepDate": "15.04.2047"
    },
    {
        "engDate": "1990-07-31",
        "nepDate": "16.04.2047"
    },
    {
        "engDate": "1990-08-01",
        "nepDate": "17.04.2047"
    },
    {
        "engDate": "1990-08-02",
        "nepDate": "18.04.2047"
    },
    {
        "engDate": "1990-08-03",
        "nepDate": "19.04.2047"
    },
    {
        "engDate": "1990-08-04",
        "nepDate": "20.04.2047"
    },
    {
        "engDate": "1990-08-05",
        "nepDate": "21.04.2047"
    },
    {
        "engDate": "1990-08-06",
        "nepDate": "22.04.2047"
    },
    {
        "engDate": "1990-08-07",
        "nepDate": "23.04.2047"
    },
    {
        "engDate": "1990-08-08",
        "nepDate": "24.04.2047"
    },
    {
        "engDate": "1990-08-09",
        "nepDate": "25.04.2047"
    },
    {
        "engDate": "1990-08-10",
        "nepDate": "26.04.2047"
    },
    {
        "engDate": "1990-08-11",
        "nepDate": "27.04.2047"
    },
    {
        "engDate": "1990-08-12",
        "nepDate": "28.04.2047"
    },
    {
        "engDate": "1990-08-13",
        "nepDate": "29.04.2047"
    },
    {
        "engDate": "1990-08-14",
        "nepDate": "30.04.2047"
    },
    {
        "engDate": "1990-08-15",
        "nepDate": "31.04.2047"
    },
    {
        "engDate": "1990-08-16",
        "nepDate": "32.04.2047"
    },
    {
        "engDate": "1990-08-17",
        "nepDate": "01.05.2047"
    },
    {
        "engDate": "1990-08-18",
        "nepDate": "02.05.2047"
    },
    {
        "engDate": "1990-08-19",
        "nepDate": "03.05.2047"
    },
    {
        "engDate": "1990-08-20",
        "nepDate": "04.05.2047"
    },
    {
        "engDate": "1990-08-21",
        "nepDate": "05.05.2047"
    },
    {
        "engDate": "1990-08-22",
        "nepDate": "06.05.2047"
    },
    {
        "engDate": "1990-08-23",
        "nepDate": "07.05.2047"
    },
    {
        "engDate": "1990-08-24",
        "nepDate": "08.05.2047"
    },
    {
        "engDate": "1990-08-25",
        "nepDate": "09.05.2047"
    },
    {
        "engDate": "1990-08-26",
        "nepDate": "10.05.2047"
    },
    {
        "engDate": "1990-08-27",
        "nepDate": "11.05.2047"
    },
    {
        "engDate": "1990-08-28",
        "nepDate": "12.05.2047"
    },
    {
        "engDate": "1990-08-29",
        "nepDate": "13.05.2047"
    },
    {
        "engDate": "1990-08-30",
        "nepDate": "14.05.2047"
    },
    {
        "engDate": "1990-08-31",
        "nepDate": "15.05.2047"
    },
    {
        "engDate": "1990-09-01",
        "nepDate": "16.05.2047"
    },
    {
        "engDate": "1990-09-02",
        "nepDate": "17.05.2047"
    },
    {
        "engDate": "1990-09-03",
        "nepDate": "18.05.2047"
    },
    {
        "engDate": "1990-09-04",
        "nepDate": "19.05.2047"
    },
    {
        "engDate": "1990-09-05",
        "nepDate": "20.05.2047"
    },
    {
        "engDate": "1990-09-06",
        "nepDate": "21.05.2047"
    },
    {
        "engDate": "1990-09-07",
        "nepDate": "22.05.2047"
    },
    {
        "engDate": "1990-09-08",
        "nepDate": "23.05.2047"
    },
    {
        "engDate": "1990-09-09",
        "nepDate": "24.05.2047"
    },
    {
        "engDate": "1990-09-10",
        "nepDate": "25.05.2047"
    },
    {
        "engDate": "1990-09-11",
        "nepDate": "26.05.2047"
    },
    {
        "engDate": "1990-09-12",
        "nepDate": "27.05.2047"
    },
    {
        "engDate": "1990-09-13",
        "nepDate": "28.05.2047"
    },
    {
        "engDate": "1990-09-14",
        "nepDate": "29.05.2047"
    },
    {
        "engDate": "1990-09-15",
        "nepDate": "30.05.2047"
    },
    {
        "engDate": "1990-09-16",
        "nepDate": "31.05.2047"
    },
    {
        "engDate": "1990-09-17",
        "nepDate": "01.06.2047"
    },
    {
        "engDate": "1990-09-18",
        "nepDate": "02.06.2047"
    },
    {
        "engDate": "1990-09-19",
        "nepDate": "03.06.2047"
    },
    {
        "engDate": "1990-09-20",
        "nepDate": "04.06.2047"
    },
    {
        "engDate": "1990-09-21",
        "nepDate": "05.06.2047"
    },
    {
        "engDate": "1990-09-22",
        "nepDate": "06.06.2047"
    },
    {
        "engDate": "1990-09-23",
        "nepDate": "07.06.2047"
    },
    {
        "engDate": "1990-09-24",
        "nepDate": "08.06.2047"
    },
    {
        "engDate": "1990-09-25",
        "nepDate": "09.06.2047"
    },
    {
        "engDate": "1990-09-26",
        "nepDate": "10.06.2047"
    },
    {
        "engDate": "1990-09-27",
        "nepDate": "11.06.2047"
    },
    {
        "engDate": "1990-09-28",
        "nepDate": "12.06.2047"
    },
    {
        "engDate": "1990-09-29",
        "nepDate": "13.06.2047"
    },
    {
        "engDate": "1990-09-30",
        "nepDate": "14.06.2047"
    },
    {
        "engDate": "1990-10-01",
        "nepDate": "15.06.2047"
    },
    {
        "engDate": "1990-10-02",
        "nepDate": "16.06.2047"
    },
    {
        "engDate": "1990-10-03",
        "nepDate": "17.06.2047"
    },
    {
        "engDate": "1990-10-04",
        "nepDate": "18.06.2047"
    },
    {
        "engDate": "1990-10-05",
        "nepDate": "19.06.2047"
    },
    {
        "engDate": "1990-10-06",
        "nepDate": "20.06.2047"
    },
    {
        "engDate": "1990-10-07",
        "nepDate": "21.06.2047"
    },
    {
        "engDate": "1990-10-08",
        "nepDate": "22.06.2047"
    },
    {
        "engDate": "1990-10-09",
        "nepDate": "23.06.2047"
    },
    {
        "engDate": "1990-10-10",
        "nepDate": "24.06.2047"
    },
    {
        "engDate": "1990-10-11",
        "nepDate": "25.06.2047"
    },
    {
        "engDate": "1990-10-12",
        "nepDate": "26.06.2047"
    },
    {
        "engDate": "1990-10-13",
        "nepDate": "27.06.2047"
    },
    {
        "engDate": "1990-10-14",
        "nepDate": "28.06.2047"
    },
    {
        "engDate": "1990-10-15",
        "nepDate": "29.06.2047"
    },
    {
        "engDate": "1990-10-16",
        "nepDate": "30.06.2047"
    },
    {
        "engDate": "1990-10-17",
        "nepDate": "31.06.2047"
    },
    {
        "engDate": "1990-10-18",
        "nepDate": "01.07.2047"
    },
    {
        "engDate": "1990-10-19",
        "nepDate": "02.07.2047"
    },
    {
        "engDate": "1990-10-20",
        "nepDate": "03.07.2047"
    },
    {
        "engDate": "1990-10-21",
        "nepDate": "04.07.2047"
    },
    {
        "engDate": "1990-10-22",
        "nepDate": "05.07.2047"
    },
    {
        "engDate": "1990-10-23",
        "nepDate": "06.07.2047"
    },
    {
        "engDate": "1990-10-24",
        "nepDate": "07.07.2047"
    },
    {
        "engDate": "1990-10-25",
        "nepDate": "08.07.2047"
    },
    {
        "engDate": "1990-10-26",
        "nepDate": "09.07.2047"
    },
    {
        "engDate": "1990-10-27",
        "nepDate": "10.07.2047"
    },
    {
        "engDate": "1990-10-28",
        "nepDate": "11.07.2047"
    },
    {
        "engDate": "1990-10-29",
        "nepDate": "12.07.2047"
    },
    {
        "engDate": "1990-10-30",
        "nepDate": "13.07.2047"
    },
    {
        "engDate": "1990-10-31",
        "nepDate": "14.07.2047"
    },
    {
        "engDate": "1990-11-01",
        "nepDate": "15.07.2047"
    },
    {
        "engDate": "1990-11-02",
        "nepDate": "16.07.2047"
    },
    {
        "engDate": "1990-11-03",
        "nepDate": "17.07.2047"
    },
    {
        "engDate": "1990-11-04",
        "nepDate": "18.07.2047"
    },
    {
        "engDate": "1990-11-05",
        "nepDate": "19.07.2047"
    },
    {
        "engDate": "1990-11-06",
        "nepDate": "20.07.2047"
    },
    {
        "engDate": "1990-11-07",
        "nepDate": "21.07.2047"
    },
    {
        "engDate": "1990-11-08",
        "nepDate": "22.07.2047"
    },
    {
        "engDate": "1990-11-09",
        "nepDate": "23.07.2047"
    },
    {
        "engDate": "1990-11-10",
        "nepDate": "24.07.2047"
    },
    {
        "engDate": "1990-11-11",
        "nepDate": "25.07.2047"
    },
    {
        "engDate": "1990-11-12",
        "nepDate": "26.07.2047"
    },
    {
        "engDate": "1990-11-13",
        "nepDate": "27.07.2047"
    },
    {
        "engDate": "1990-11-14",
        "nepDate": "28.07.2047"
    },
    {
        "engDate": "1990-11-15",
        "nepDate": "29.07.2047"
    },
    {
        "engDate": "1990-11-16",
        "nepDate": "30.07.2047"
    },
    {
        "engDate": "1990-11-17",
        "nepDate": "01.08.2047"
    },
    {
        "engDate": "1990-11-18",
        "nepDate": "02.08.2047"
    },
    {
        "engDate": "1990-11-19",
        "nepDate": "03.08.2047"
    },
    {
        "engDate": "1990-11-20",
        "nepDate": "04.08.2047"
    },
    {
        "engDate": "1990-11-21",
        "nepDate": "05.08.2047"
    },
    {
        "engDate": "1990-11-22",
        "nepDate": "06.08.2047"
    },
    {
        "engDate": "1990-11-23",
        "nepDate": "07.08.2047"
    },
    {
        "engDate": "1990-11-24",
        "nepDate": "08.08.2047"
    },
    {
        "engDate": "1990-11-25",
        "nepDate": "09.08.2047"
    },
    {
        "engDate": "1990-11-26",
        "nepDate": "10.08.2047"
    },
    {
        "engDate": "1990-11-27",
        "nepDate": "11.08.2047"
    },
    {
        "engDate": "1990-11-28",
        "nepDate": "12.08.2047"
    },
    {
        "engDate": "1990-11-29",
        "nepDate": "13.08.2047"
    },
    {
        "engDate": "1990-11-30",
        "nepDate": "14.08.2047"
    },
    {
        "engDate": "1990-12-01",
        "nepDate": "15.08.2047"
    },
    {
        "engDate": "1990-12-02",
        "nepDate": "16.08.2047"
    },
    {
        "engDate": "1990-12-03",
        "nepDate": "17.08.2047"
    },
    {
        "engDate": "1990-12-04",
        "nepDate": "18.08.2047"
    },
    {
        "engDate": "1990-12-05",
        "nepDate": "19.08.2047"
    },
    {
        "engDate": "1990-12-06",
        "nepDate": "20.08.2047"
    },
    {
        "engDate": "1990-12-07",
        "nepDate": "21.08.2047"
    },
    {
        "engDate": "1990-12-08",
        "nepDate": "22.08.2047"
    },
    {
        "engDate": "1990-12-09",
        "nepDate": "23.08.2047"
    },
    {
        "engDate": "1990-12-10",
        "nepDate": "24.08.2047"
    },
    {
        "engDate": "1990-12-11",
        "nepDate": "25.08.2047"
    },
    {
        "engDate": "1990-12-12",
        "nepDate": "26.08.2047"
    },
    {
        "engDate": "1990-12-13",
        "nepDate": "27.08.2047"
    },
    {
        "engDate": "1990-12-14",
        "nepDate": "28.08.2047"
    },
    {
        "engDate": "1990-12-15",
        "nepDate": "29.08.2047"
    },
    {
        "engDate": "1990-12-16",
        "nepDate": "01.09.2047"
    },
    {
        "engDate": "1990-12-17",
        "nepDate": "02.09.2047"
    },
    {
        "engDate": "1990-12-18",
        "nepDate": "03.09.2047"
    },
    {
        "engDate": "1990-12-19",
        "nepDate": "04.09.2047"
    },
    {
        "engDate": "1990-12-20",
        "nepDate": "05.09.2047"
    },
    {
        "engDate": "1990-12-21",
        "nepDate": "06.09.2047"
    },
    {
        "engDate": "1990-12-22",
        "nepDate": "07.09.2047"
    },
    {
        "engDate": "1990-12-23",
        "nepDate": "08.09.2047"
    },
    {
        "engDate": "1990-12-24",
        "nepDate": "09.09.2047"
    },
    {
        "engDate": "1990-12-25",
        "nepDate": "10.09.2047"
    },
    {
        "engDate": "1990-12-26",
        "nepDate": "11.09.2047"
    },
    {
        "engDate": "1990-12-27",
        "nepDate": "12.09.2047"
    },
    {
        "engDate": "1990-12-28",
        "nepDate": "13.09.2047"
    },
    {
        "engDate": "1990-12-29",
        "nepDate": "14.09.2047"
    },
    {
        "engDate": "1990-12-30",
        "nepDate": "15.09.2047"
    },
    {
        "engDate": "1990-12-31",
        "nepDate": "16.09.2047"
    },
    {
        "engDate": "1991-01-01",
        "nepDate": "17.09.2047"
    },
    {
        "engDate": "1991-01-02",
        "nepDate": "18.09.2047"
    },
    {
        "engDate": "1991-01-03",
        "nepDate": "19.09.2047"
    },
    {
        "engDate": "1991-01-04",
        "nepDate": "20.09.2047"
    },
    {
        "engDate": "1991-01-05",
        "nepDate": "21.09.2047"
    },
    {
        "engDate": "1991-01-06",
        "nepDate": "22.09.2047"
    },
    {
        "engDate": "1991-01-07",
        "nepDate": "23.09.2047"
    },
    {
        "engDate": "1991-01-08",
        "nepDate": "24.09.2047"
    },
    {
        "engDate": "1991-01-09",
        "nepDate": "25.09.2047"
    },
    {
        "engDate": "1991-01-10",
        "nepDate": "26.09.2047"
    },
    {
        "engDate": "1991-01-11",
        "nepDate": "27.09.2047"
    },
    {
        "engDate": "1991-01-12",
        "nepDate": "28.09.2047"
    },
    {
        "engDate": "1991-01-13",
        "nepDate": "29.09.2047"
    },
    {
        "engDate": "1991-01-14",
        "nepDate": "30.09.2047"
    },
    {
        "engDate": "1991-01-15",
        "nepDate": "01.10.2047"
    },
    {
        "engDate": "1991-01-16",
        "nepDate": "02.10.2047"
    },
    {
        "engDate": "1991-01-17",
        "nepDate": "03.10.2047"
    },
    {
        "engDate": "1991-01-18",
        "nepDate": "04.10.2047"
    },
    {
        "engDate": "1991-01-19",
        "nepDate": "05.10.2047"
    },
    {
        "engDate": "1991-01-20",
        "nepDate": "06.10.2047"
    },
    {
        "engDate": "1991-01-21",
        "nepDate": "07.10.2047"
    },
    {
        "engDate": "1991-01-22",
        "nepDate": "08.10.2047"
    },
    {
        "engDate": "1991-01-23",
        "nepDate": "09.10.2047"
    },
    {
        "engDate": "1991-01-24",
        "nepDate": "10.10.2047"
    },
    {
        "engDate": "1991-01-25",
        "nepDate": "11.10.2047"
    },
    {
        "engDate": "1991-01-26",
        "nepDate": "12.10.2047"
    },
    {
        "engDate": "1991-01-27",
        "nepDate": "13.10.2047"
    },
    {
        "engDate": "1991-01-28",
        "nepDate": "14.10.2047"
    },
    {
        "engDate": "1991-01-29",
        "nepDate": "15.10.2047"
    },
    {
        "engDate": "1991-01-30",
        "nepDate": "16.10.2047"
    },
    {
        "engDate": "1991-01-31",
        "nepDate": "17.10.2047"
    },
    {
        "engDate": "1991-02-01",
        "nepDate": "18.10.2047"
    },
    {
        "engDate": "1991-02-02",
        "nepDate": "19.10.2047"
    },
    {
        "engDate": "1991-02-03",
        "nepDate": "20.10.2047"
    },
    {
        "engDate": "1991-02-04",
        "nepDate": "21.10.2047"
    },
    {
        "engDate": "1991-02-05",
        "nepDate": "22.10.2047"
    },
    {
        "engDate": "1991-02-06",
        "nepDate": "23.10.2047"
    },
    {
        "engDate": "1991-02-07",
        "nepDate": "24.10.2047"
    },
    {
        "engDate": "1991-02-08",
        "nepDate": "25.10.2047"
    },
    {
        "engDate": "1991-02-09",
        "nepDate": "26.10.2047"
    },
    {
        "engDate": "1991-02-10",
        "nepDate": "27.10.2047"
    },
    {
        "engDate": "1991-02-11",
        "nepDate": "28.10.2047"
    },
    {
        "engDate": "1991-02-12",
        "nepDate": "29.10.2047"
    },
    {
        "engDate": "1991-02-13",
        "nepDate": "01.11.2047"
    },
    {
        "engDate": "1991-02-14",
        "nepDate": "02.11.2047"
    },
    {
        "engDate": "1991-02-15",
        "nepDate": "03.11.2047"
    },
    {
        "engDate": "1991-02-16",
        "nepDate": "04.11.2047"
    },
    {
        "engDate": "1991-02-17",
        "nepDate": "05.11.2047"
    },
    {
        "engDate": "1991-02-18",
        "nepDate": "06.11.2047"
    },
    {
        "engDate": "1991-02-19",
        "nepDate": "07.11.2047"
    },
    {
        "engDate": "1991-02-20",
        "nepDate": "08.11.2047"
    },
    {
        "engDate": "1991-02-21",
        "nepDate": "09.11.2047"
    },
    {
        "engDate": "1991-02-22",
        "nepDate": "10.11.2047"
    },
    {
        "engDate": "1991-02-23",
        "nepDate": "11.11.2047"
    },
    {
        "engDate": "1991-02-24",
        "nepDate": "12.11.2047"
    },
    {
        "engDate": "1991-02-25",
        "nepDate": "13.11.2047"
    },
    {
        "engDate": "1991-02-26",
        "nepDate": "14.11.2047"
    },
    {
        "engDate": "1991-02-27",
        "nepDate": "15.11.2047"
    },
    {
        "engDate": "1991-02-28",
        "nepDate": "16.11.2047"
    },
    {
        "engDate": "1991-03-01",
        "nepDate": "17.11.2047"
    },
    {
        "engDate": "1991-03-02",
        "nepDate": "18.11.2047"
    },
    {
        "engDate": "1991-03-03",
        "nepDate": "19.11.2047"
    },
    {
        "engDate": "1991-03-04",
        "nepDate": "20.11.2047"
    },
    {
        "engDate": "1991-03-05",
        "nepDate": "21.11.2047"
    },
    {
        "engDate": "1991-03-06",
        "nepDate": "22.11.2047"
    },
    {
        "engDate": "1991-03-07",
        "nepDate": "23.11.2047"
    },
    {
        "engDate": "1991-03-08",
        "nepDate": "24.11.2047"
    },
    {
        "engDate": "1991-03-09",
        "nepDate": "25.11.2047"
    },
    {
        "engDate": "1991-03-10",
        "nepDate": "26.11.2047"
    },
    {
        "engDate": "1991-03-11",
        "nepDate": "27.11.2047"
    },
    {
        "engDate": "1991-03-12",
        "nepDate": "28.11.2047"
    },
    {
        "engDate": "1991-03-13",
        "nepDate": "29.11.2047"
    },
    {
        "engDate": "1991-03-14",
        "nepDate": "30.11.2047"
    },
    {
        "engDate": "1991-03-15",
        "nepDate": "01.12.2047"
    },
    {
        "engDate": "1991-03-16",
        "nepDate": "02.12.2047"
    },
    {
        "engDate": "1991-03-17",
        "nepDate": "03.12.2047"
    },
    {
        "engDate": "1991-03-18",
        "nepDate": "04.12.2047"
    },
    {
        "engDate": "1991-03-19",
        "nepDate": "05.12.2047"
    },
    {
        "engDate": "1991-03-20",
        "nepDate": "06.12.2047"
    },
    {
        "engDate": "1991-03-21",
        "nepDate": "07.12.2047"
    },
    {
        "engDate": "1991-03-22",
        "nepDate": "08.12.2047"
    },
    {
        "engDate": "1991-03-23",
        "nepDate": "09.12.2047"
    },
    {
        "engDate": "1991-03-24",
        "nepDate": "10.12.2047"
    },
    {
        "engDate": "1991-03-25",
        "nepDate": "11.12.2047"
    },
    {
        "engDate": "1991-03-26",
        "nepDate": "12.12.2047"
    },
    {
        "engDate": "1991-03-27",
        "nepDate": "13.12.2047"
    },
    {
        "engDate": "1991-03-28",
        "nepDate": "14.12.2047"
    },
    {
        "engDate": "1991-03-29",
        "nepDate": "15.12.2047"
    },
    {
        "engDate": "1991-03-30",
        "nepDate": "16.12.2047"
    },
    {
        "engDate": "1991-03-31",
        "nepDate": "17.12.2047"
    },
    {
        "engDate": "1991-04-01",
        "nepDate": "18.12.2047"
    },
    {
        "engDate": "1991-04-02",
        "nepDate": "19.12.2047"
    },
    {
        "engDate": "1991-04-03",
        "nepDate": "20.12.2047"
    },
    {
        "engDate": "1991-04-04",
        "nepDate": "21.12.2047"
    },
    {
        "engDate": "1991-04-05",
        "nepDate": "22.12.2047"
    },
    {
        "engDate": "1991-04-06",
        "nepDate": "23.12.2047"
    },
    {
        "engDate": "1991-04-07",
        "nepDate": "24.12.2047"
    },
    {
        "engDate": "1991-04-08",
        "nepDate": "25.12.2047"
    },
    {
        "engDate": "1991-04-09",
        "nepDate": "26.12.2047"
    },
    {
        "engDate": "1991-04-10",
        "nepDate": "27.12.2047"
    },
    {
        "engDate": "1991-04-11",
        "nepDate": "28.12.2047"
    },
    {
        "engDate": "1991-04-12",
        "nepDate": "29.12.2047"
    },
    {
        "engDate": "1991-04-13",
        "nepDate": "30.12.2047"
    },
    {
        "engDate": "1991-04-14",
        "nepDate": "01.01.2048"
    },
    {
        "engDate": "1991-04-15",
        "nepDate": "02.01.2048"
    },
    {
        "engDate": "1991-04-16",
        "nepDate": "03.01.2048"
    },
    {
        "engDate": "1991-04-17",
        "nepDate": "04.01.2048"
    },
    {
        "engDate": "1991-04-18",
        "nepDate": "05.01.2048"
    },
    {
        "engDate": "1991-04-19",
        "nepDate": "06.01.2048"
    },
    {
        "engDate": "1991-04-20",
        "nepDate": "07.01.2048"
    },
    {
        "engDate": "1991-04-21",
        "nepDate": "08.01.2048"
    },
    {
        "engDate": "1991-04-22",
        "nepDate": "09.01.2048"
    },
    {
        "engDate": "1991-04-23",
        "nepDate": "10.01.2048"
    },
    {
        "engDate": "1991-04-24",
        "nepDate": "11.01.2048"
    },
    {
        "engDate": "1991-04-25",
        "nepDate": "12.01.2048"
    },
    {
        "engDate": "1991-04-26",
        "nepDate": "13.01.2048"
    },
    {
        "engDate": "1991-04-27",
        "nepDate": "14.01.2048"
    },
    {
        "engDate": "1991-04-28",
        "nepDate": "15.01.2048"
    },
    {
        "engDate": "1991-04-29",
        "nepDate": "16.01.2048"
    },
    {
        "engDate": "1991-04-30",
        "nepDate": "17.01.2048"
    },
    {
        "engDate": "1991-05-01",
        "nepDate": "18.01.2048"
    },
    {
        "engDate": "1991-05-02",
        "nepDate": "19.01.2048"
    },
    {
        "engDate": "1991-05-03",
        "nepDate": "20.01.2048"
    },
    {
        "engDate": "1991-05-04",
        "nepDate": "21.01.2048"
    },
    {
        "engDate": "1991-05-05",
        "nepDate": "22.01.2048"
    },
    {
        "engDate": "1991-05-06",
        "nepDate": "23.01.2048"
    },
    {
        "engDate": "1991-05-07",
        "nepDate": "24.01.2048"
    },
    {
        "engDate": "1991-05-08",
        "nepDate": "25.01.2048"
    },
    {
        "engDate": "1991-05-09",
        "nepDate": "26.01.2048"
    },
    {
        "engDate": "1991-05-10",
        "nepDate": "27.01.2048"
    },
    {
        "engDate": "1991-05-11",
        "nepDate": "28.01.2048"
    },
    {
        "engDate": "1991-05-12",
        "nepDate": "29.01.2048"
    },
    {
        "engDate": "1991-05-13",
        "nepDate": "30.01.2048"
    },
    {
        "engDate": "1991-05-14",
        "nepDate": "31.01.2048"
    },
    {
        "engDate": "1991-05-15",
        "nepDate": "01.02.2048"
    },
    {
        "engDate": "1991-05-16",
        "nepDate": "02.02.2048"
    },
    {
        "engDate": "1991-05-17",
        "nepDate": "03.02.2048"
    },
    {
        "engDate": "1991-05-18",
        "nepDate": "04.02.2048"
    },
    {
        "engDate": "1991-05-19",
        "nepDate": "05.02.2048"
    },
    {
        "engDate": "1991-05-20",
        "nepDate": "06.02.2048"
    },
    {
        "engDate": "1991-05-21",
        "nepDate": "07.02.2048"
    },
    {
        "engDate": "1991-05-22",
        "nepDate": "08.02.2048"
    },
    {
        "engDate": "1991-05-23",
        "nepDate": "09.02.2048"
    },
    {
        "engDate": "1991-05-24",
        "nepDate": "10.02.2048"
    },
    {
        "engDate": "1991-05-25",
        "nepDate": "11.02.2048"
    },
    {
        "engDate": "1991-05-26",
        "nepDate": "12.02.2048"
    },
    {
        "engDate": "1991-05-27",
        "nepDate": "13.02.2048"
    },
    {
        "engDate": "1991-05-28",
        "nepDate": "14.02.2048"
    },
    {
        "engDate": "1991-05-29",
        "nepDate": "15.02.2048"
    },
    {
        "engDate": "1991-05-30",
        "nepDate": "16.02.2048"
    },
    {
        "engDate": "1991-05-31",
        "nepDate": "17.02.2048"
    },
    {
        "engDate": "1991-06-01",
        "nepDate": "18.02.2048"
    },
    {
        "engDate": "1991-06-02",
        "nepDate": "19.02.2048"
    },
    {
        "engDate": "1991-06-03",
        "nepDate": "20.02.2048"
    },
    {
        "engDate": "1991-06-04",
        "nepDate": "21.02.2048"
    },
    {
        "engDate": "1991-06-05",
        "nepDate": "22.02.2048"
    },
    {
        "engDate": "1991-06-06",
        "nepDate": "23.02.2048"
    },
    {
        "engDate": "1991-06-07",
        "nepDate": "24.02.2048"
    },
    {
        "engDate": "1991-06-08",
        "nepDate": "25.02.2048"
    },
    {
        "engDate": "1991-06-09",
        "nepDate": "26.02.2048"
    },
    {
        "engDate": "1991-06-10",
        "nepDate": "27.02.2048"
    },
    {
        "engDate": "1991-06-11",
        "nepDate": "28.02.2048"
    },
    {
        "engDate": "1991-06-12",
        "nepDate": "29.02.2048"
    },
    {
        "engDate": "1991-06-13",
        "nepDate": "30.02.2048"
    },
    {
        "engDate": "1991-06-14",
        "nepDate": "31.02.2048"
    },
    {
        "engDate": "1991-06-15",
        "nepDate": "01.03.2048"
    },
    {
        "engDate": "1991-06-16",
        "nepDate": "02.03.2048"
    },
    {
        "engDate": "1991-06-17",
        "nepDate": "03.03.2048"
    },
    {
        "engDate": "1991-06-18",
        "nepDate": "04.03.2048"
    },
    {
        "engDate": "1991-06-19",
        "nepDate": "05.03.2048"
    },
    {
        "engDate": "1991-06-20",
        "nepDate": "06.03.2048"
    },
    {
        "engDate": "1991-06-21",
        "nepDate": "07.03.2048"
    },
    {
        "engDate": "1991-06-22",
        "nepDate": "08.03.2048"
    },
    {
        "engDate": "1991-06-23",
        "nepDate": "09.03.2048"
    },
    {
        "engDate": "1991-06-24",
        "nepDate": "10.03.2048"
    },
    {
        "engDate": "1991-06-25",
        "nepDate": "11.03.2048"
    },
    {
        "engDate": "1991-06-26",
        "nepDate": "12.03.2048"
    },
    {
        "engDate": "1991-06-27",
        "nepDate": "13.03.2048"
    },
    {
        "engDate": "1991-06-28",
        "nepDate": "14.03.2048"
    },
    {
        "engDate": "1991-06-29",
        "nepDate": "15.03.2048"
    },
    {
        "engDate": "1991-06-30",
        "nepDate": "16.03.2048"
    },
    {
        "engDate": "1991-07-01",
        "nepDate": "17.03.2048"
    },
    {
        "engDate": "1991-07-02",
        "nepDate": "18.03.2048"
    },
    {
        "engDate": "1991-07-03",
        "nepDate": "19.03.2048"
    },
    {
        "engDate": "1991-07-04",
        "nepDate": "20.03.2048"
    },
    {
        "engDate": "1991-07-05",
        "nepDate": "21.03.2048"
    },
    {
        "engDate": "1991-07-06",
        "nepDate": "22.03.2048"
    },
    {
        "engDate": "1991-07-07",
        "nepDate": "23.03.2048"
    },
    {
        "engDate": "1991-07-08",
        "nepDate": "24.03.2048"
    },
    {
        "engDate": "1991-07-09",
        "nepDate": "25.03.2048"
    },
    {
        "engDate": "1991-07-10",
        "nepDate": "26.03.2048"
    },
    {
        "engDate": "1991-07-11",
        "nepDate": "27.03.2048"
    },
    {
        "engDate": "1991-07-12",
        "nepDate": "28.03.2048"
    },
    {
        "engDate": "1991-07-13",
        "nepDate": "29.03.2048"
    },
    {
        "engDate": "1991-07-14",
        "nepDate": "30.03.2048"
    },
    {
        "engDate": "1991-07-15",
        "nepDate": "31.03.2048"
    },
    {
        "engDate": "1991-07-16",
        "nepDate": "32.03.2048"
    },
    {
        "engDate": "1991-07-17",
        "nepDate": "01.04.2048"
    },
    {
        "engDate": "1991-07-18",
        "nepDate": "02.04.2048"
    },
    {
        "engDate": "1991-07-19",
        "nepDate": "03.04.2048"
    },
    {
        "engDate": "1991-07-20",
        "nepDate": "04.04.2048"
    },
    {
        "engDate": "1991-07-21",
        "nepDate": "05.04.2048"
    },
    {
        "engDate": "1991-07-22",
        "nepDate": "06.04.2048"
    },
    {
        "engDate": "1991-07-23",
        "nepDate": "07.04.2048"
    },
    {
        "engDate": "1991-07-24",
        "nepDate": "08.04.2048"
    },
    {
        "engDate": "1991-07-25",
        "nepDate": "09.04.2048"
    },
    {
        "engDate": "1991-07-26",
        "nepDate": "10.04.2048"
    },
    {
        "engDate": "1991-07-27",
        "nepDate": "11.04.2048"
    },
    {
        "engDate": "1991-07-28",
        "nepDate": "12.04.2048"
    },
    {
        "engDate": "1991-07-29",
        "nepDate": "13.04.2048"
    },
    {
        "engDate": "1991-07-30",
        "nepDate": "14.04.2048"
    },
    {
        "engDate": "1991-07-31",
        "nepDate": "15.04.2048"
    },
    {
        "engDate": "1991-08-01",
        "nepDate": "16.04.2048"
    },
    {
        "engDate": "1991-08-02",
        "nepDate": "17.04.2048"
    },
    {
        "engDate": "1991-08-03",
        "nepDate": "18.04.2048"
    },
    {
        "engDate": "1991-08-04",
        "nepDate": "19.04.2048"
    },
    {
        "engDate": "1991-08-05",
        "nepDate": "20.04.2048"
    },
    {
        "engDate": "1991-08-06",
        "nepDate": "21.04.2048"
    },
    {
        "engDate": "1991-08-07",
        "nepDate": "22.04.2048"
    },
    {
        "engDate": "1991-08-08",
        "nepDate": "23.04.2048"
    },
    {
        "engDate": "1991-08-09",
        "nepDate": "24.04.2048"
    },
    {
        "engDate": "1991-08-10",
        "nepDate": "25.04.2048"
    },
    {
        "engDate": "1991-08-11",
        "nepDate": "26.04.2048"
    },
    {
        "engDate": "1991-08-12",
        "nepDate": "27.04.2048"
    },
    {
        "engDate": "1991-08-13",
        "nepDate": "28.04.2048"
    },
    {
        "engDate": "1991-08-14",
        "nepDate": "29.04.2048"
    },
    {
        "engDate": "1991-08-15",
        "nepDate": "30.04.2048"
    },
    {
        "engDate": "1991-08-16",
        "nepDate": "31.04.2048"
    },
    {
        "engDate": "1991-08-17",
        "nepDate": "01.05.2048"
    },
    {
        "engDate": "1991-08-18",
        "nepDate": "02.05.2048"
    },
    {
        "engDate": "1991-08-19",
        "nepDate": "03.05.2048"
    },
    {
        "engDate": "1991-08-20",
        "nepDate": "04.05.2048"
    },
    {
        "engDate": "1991-08-21",
        "nepDate": "05.05.2048"
    },
    {
        "engDate": "1991-08-22",
        "nepDate": "06.05.2048"
    },
    {
        "engDate": "1991-08-23",
        "nepDate": "07.05.2048"
    },
    {
        "engDate": "1991-08-24",
        "nepDate": "08.05.2048"
    },
    {
        "engDate": "1991-08-25",
        "nepDate": "09.05.2048"
    },
    {
        "engDate": "1991-08-26",
        "nepDate": "10.05.2048"
    },
    {
        "engDate": "1991-08-27",
        "nepDate": "11.05.2048"
    },
    {
        "engDate": "1991-08-28",
        "nepDate": "12.05.2048"
    },
    {
        "engDate": "1991-08-29",
        "nepDate": "13.05.2048"
    },
    {
        "engDate": "1991-08-30",
        "nepDate": "14.05.2048"
    },
    {
        "engDate": "1991-08-31",
        "nepDate": "15.05.2048"
    },
    {
        "engDate": "1991-09-01",
        "nepDate": "16.05.2048"
    },
    {
        "engDate": "1991-09-02",
        "nepDate": "17.05.2048"
    },
    {
        "engDate": "1991-09-03",
        "nepDate": "18.05.2048"
    },
    {
        "engDate": "1991-09-04",
        "nepDate": "19.05.2048"
    },
    {
        "engDate": "1991-09-05",
        "nepDate": "20.05.2048"
    },
    {
        "engDate": "1991-09-06",
        "nepDate": "21.05.2048"
    },
    {
        "engDate": "1991-09-07",
        "nepDate": "22.05.2048"
    },
    {
        "engDate": "1991-09-08",
        "nepDate": "23.05.2048"
    },
    {
        "engDate": "1991-09-09",
        "nepDate": "24.05.2048"
    },
    {
        "engDate": "1991-09-10",
        "nepDate": "25.05.2048"
    },
    {
        "engDate": "1991-09-11",
        "nepDate": "26.05.2048"
    },
    {
        "engDate": "1991-09-12",
        "nepDate": "27.05.2048"
    },
    {
        "engDate": "1991-09-13",
        "nepDate": "28.05.2048"
    },
    {
        "engDate": "1991-09-14",
        "nepDate": "29.05.2048"
    },
    {
        "engDate": "1991-09-15",
        "nepDate": "30.05.2048"
    },
    {
        "engDate": "1991-09-16",
        "nepDate": "31.05.2048"
    },
    {
        "engDate": "1991-09-17",
        "nepDate": "01.06.2048"
    },
    {
        "engDate": "1991-09-18",
        "nepDate": "02.06.2048"
    },
    {
        "engDate": "1991-09-19",
        "nepDate": "03.06.2048"
    },
    {
        "engDate": "1991-09-20",
        "nepDate": "04.06.2048"
    },
    {
        "engDate": "1991-09-21",
        "nepDate": "05.06.2048"
    },
    {
        "engDate": "1991-09-22",
        "nepDate": "06.06.2048"
    },
    {
        "engDate": "1991-09-23",
        "nepDate": "07.06.2048"
    },
    {
        "engDate": "1991-09-24",
        "nepDate": "08.06.2048"
    },
    {
        "engDate": "1991-09-25",
        "nepDate": "09.06.2048"
    },
    {
        "engDate": "1991-09-26",
        "nepDate": "10.06.2048"
    },
    {
        "engDate": "1991-09-27",
        "nepDate": "11.06.2048"
    },
    {
        "engDate": "1991-09-28",
        "nepDate": "12.06.2048"
    },
    {
        "engDate": "1991-09-29",
        "nepDate": "13.06.2048"
    },
    {
        "engDate": "1991-09-30",
        "nepDate": "14.06.2048"
    },
    {
        "engDate": "1991-10-01",
        "nepDate": "15.06.2048"
    },
    {
        "engDate": "1991-10-02",
        "nepDate": "16.06.2048"
    },
    {
        "engDate": "1991-10-03",
        "nepDate": "17.06.2048"
    },
    {
        "engDate": "1991-10-04",
        "nepDate": "18.06.2048"
    },
    {
        "engDate": "1991-10-05",
        "nepDate": "19.06.2048"
    },
    {
        "engDate": "1991-10-06",
        "nepDate": "20.06.2048"
    },
    {
        "engDate": "1991-10-07",
        "nepDate": "21.06.2048"
    },
    {
        "engDate": "1991-10-08",
        "nepDate": "22.06.2048"
    },
    {
        "engDate": "1991-10-09",
        "nepDate": "23.06.2048"
    },
    {
        "engDate": "1991-10-10",
        "nepDate": "24.06.2048"
    },
    {
        "engDate": "1991-10-11",
        "nepDate": "25.06.2048"
    },
    {
        "engDate": "1991-10-12",
        "nepDate": "26.06.2048"
    },
    {
        "engDate": "1991-10-13",
        "nepDate": "27.06.2048"
    },
    {
        "engDate": "1991-10-14",
        "nepDate": "28.06.2048"
    },
    {
        "engDate": "1991-10-15",
        "nepDate": "29.06.2048"
    },
    {
        "engDate": "1991-10-16",
        "nepDate": "30.06.2048"
    },
    {
        "engDate": "1991-10-17",
        "nepDate": "31.06.2048"
    },
    {
        "engDate": "1991-10-18",
        "nepDate": "01.07.2048"
    },
    {
        "engDate": "1991-10-19",
        "nepDate": "02.07.2048"
    },
    {
        "engDate": "1991-10-20",
        "nepDate": "03.07.2048"
    },
    {
        "engDate": "1991-10-21",
        "nepDate": "04.07.2048"
    },
    {
        "engDate": "1991-10-22",
        "nepDate": "05.07.2048"
    },
    {
        "engDate": "1991-10-23",
        "nepDate": "06.07.2048"
    },
    {
        "engDate": "1991-10-24",
        "nepDate": "07.07.2048"
    },
    {
        "engDate": "1991-10-25",
        "nepDate": "08.07.2048"
    },
    {
        "engDate": "1991-10-26",
        "nepDate": "09.07.2048"
    },
    {
        "engDate": "1991-10-27",
        "nepDate": "10.07.2048"
    },
    {
        "engDate": "1991-10-28",
        "nepDate": "11.07.2048"
    },
    {
        "engDate": "1991-10-29",
        "nepDate": "12.07.2048"
    },
    {
        "engDate": "1991-10-30",
        "nepDate": "13.07.2048"
    },
    {
        "engDate": "1991-10-31",
        "nepDate": "14.07.2048"
    },
    {
        "engDate": "1991-11-01",
        "nepDate": "15.07.2048"
    },
    {
        "engDate": "1991-11-02",
        "nepDate": "16.07.2048"
    },
    {
        "engDate": "1991-11-03",
        "nepDate": "17.07.2048"
    },
    {
        "engDate": "1991-11-04",
        "nepDate": "18.07.2048"
    },
    {
        "engDate": "1991-11-05",
        "nepDate": "19.07.2048"
    },
    {
        "engDate": "1991-11-06",
        "nepDate": "20.07.2048"
    },
    {
        "engDate": "1991-11-07",
        "nepDate": "21.07.2048"
    },
    {
        "engDate": "1991-11-08",
        "nepDate": "22.07.2048"
    },
    {
        "engDate": "1991-11-09",
        "nepDate": "23.07.2048"
    },
    {
        "engDate": "1991-11-10",
        "nepDate": "24.07.2048"
    },
    {
        "engDate": "1991-11-11",
        "nepDate": "25.07.2048"
    },
    {
        "engDate": "1991-11-12",
        "nepDate": "26.07.2048"
    },
    {
        "engDate": "1991-11-13",
        "nepDate": "27.07.2048"
    },
    {
        "engDate": "1991-11-14",
        "nepDate": "28.07.2048"
    },
    {
        "engDate": "1991-11-15",
        "nepDate": "29.07.2048"
    },
    {
        "engDate": "1991-11-16",
        "nepDate": "30.07.2048"
    },
    {
        "engDate": "1991-11-17",
        "nepDate": "01.08.2048"
    },
    {
        "engDate": "1991-11-18",
        "nepDate": "02.08.2048"
    },
    {
        "engDate": "1991-11-19",
        "nepDate": "03.08.2048"
    },
    {
        "engDate": "1991-11-20",
        "nepDate": "04.08.2048"
    },
    {
        "engDate": "1991-11-21",
        "nepDate": "05.08.2048"
    },
    {
        "engDate": "1991-11-22",
        "nepDate": "06.08.2048"
    },
    {
        "engDate": "1991-11-23",
        "nepDate": "07.08.2048"
    },
    {
        "engDate": "1991-11-24",
        "nepDate": "08.08.2048"
    },
    {
        "engDate": "1991-11-25",
        "nepDate": "09.08.2048"
    },
    {
        "engDate": "1991-11-26",
        "nepDate": "10.08.2048"
    },
    {
        "engDate": "1991-11-27",
        "nepDate": "11.08.2048"
    },
    {
        "engDate": "1991-11-28",
        "nepDate": "12.08.2048"
    },
    {
        "engDate": "1991-11-29",
        "nepDate": "13.08.2048"
    },
    {
        "engDate": "1991-11-30",
        "nepDate": "14.08.2048"
    },
    {
        "engDate": "1991-12-01",
        "nepDate": "15.08.2048"
    },
    {
        "engDate": "1991-12-02",
        "nepDate": "16.08.2048"
    },
    {
        "engDate": "1991-12-03",
        "nepDate": "17.08.2048"
    },
    {
        "engDate": "1991-12-04",
        "nepDate": "18.08.2048"
    },
    {
        "engDate": "1991-12-05",
        "nepDate": "19.08.2048"
    },
    {
        "engDate": "1991-12-06",
        "nepDate": "20.08.2048"
    },
    {
        "engDate": "1991-12-07",
        "nepDate": "21.08.2048"
    },
    {
        "engDate": "1991-12-08",
        "nepDate": "22.08.2048"
    },
    {
        "engDate": "1991-12-09",
        "nepDate": "23.08.2048"
    },
    {
        "engDate": "1991-12-10",
        "nepDate": "24.08.2048"
    },
    {
        "engDate": "1991-12-11",
        "nepDate": "25.08.2048"
    },
    {
        "engDate": "1991-12-12",
        "nepDate": "26.08.2048"
    },
    {
        "engDate": "1991-12-13",
        "nepDate": "27.08.2048"
    },
    {
        "engDate": "1991-12-14",
        "nepDate": "28.08.2048"
    },
    {
        "engDate": "1991-12-15",
        "nepDate": "29.08.2048"
    },
    {
        "engDate": "1991-12-16",
        "nepDate": "01.09.2048"
    },
    {
        "engDate": "1991-12-17",
        "nepDate": "02.09.2048"
    },
    {
        "engDate": "1991-12-18",
        "nepDate": "03.09.2048"
    },
    {
        "engDate": "1991-12-19",
        "nepDate": "04.09.2048"
    },
    {
        "engDate": "1991-12-20",
        "nepDate": "05.09.2048"
    },
    {
        "engDate": "1991-12-21",
        "nepDate": "06.09.2048"
    },
    {
        "engDate": "1991-12-22",
        "nepDate": "07.09.2048"
    },
    {
        "engDate": "1991-12-23",
        "nepDate": "08.09.2048"
    },
    {
        "engDate": "1991-12-24",
        "nepDate": "09.09.2048"
    },
    {
        "engDate": "1991-12-25",
        "nepDate": "10.09.2048"
    },
    {
        "engDate": "1991-12-26",
        "nepDate": "11.09.2048"
    },
    {
        "engDate": "1991-12-27",
        "nepDate": "12.09.2048"
    },
    {
        "engDate": "1991-12-28",
        "nepDate": "13.09.2048"
    },
    {
        "engDate": "1991-12-29",
        "nepDate": "14.09.2048"
    },
    {
        "engDate": "1991-12-30",
        "nepDate": "15.09.2048"
    },
    {
        "engDate": "1991-12-31",
        "nepDate": "16.09.2048"
    },
    {
        "engDate": "1992-01-01",
        "nepDate": "17.09.2048"
    },
    {
        "engDate": "1992-01-02",
        "nepDate": "18.09.2048"
    },
    {
        "engDate": "1992-01-03",
        "nepDate": "19.09.2048"
    },
    {
        "engDate": "1992-01-04",
        "nepDate": "20.09.2048"
    },
    {
        "engDate": "1992-01-05",
        "nepDate": "21.09.2048"
    },
    {
        "engDate": "1992-01-06",
        "nepDate": "22.09.2048"
    },
    {
        "engDate": "1992-01-07",
        "nepDate": "23.09.2048"
    },
    {
        "engDate": "1992-01-08",
        "nepDate": "24.09.2048"
    },
    {
        "engDate": "1992-01-09",
        "nepDate": "25.09.2048"
    },
    {
        "engDate": "1992-01-10",
        "nepDate": "26.09.2048"
    },
    {
        "engDate": "1992-01-11",
        "nepDate": "27.09.2048"
    },
    {
        "engDate": "1992-01-12",
        "nepDate": "28.09.2048"
    },
    {
        "engDate": "1992-01-13",
        "nepDate": "29.09.2048"
    },
    {
        "engDate": "1992-01-14",
        "nepDate": "30.09.2048"
    },
    {
        "engDate": "1992-01-15",
        "nepDate": "01.10.2048"
    },
    {
        "engDate": "1992-01-16",
        "nepDate": "02.10.2048"
    },
    {
        "engDate": "1992-01-17",
        "nepDate": "03.10.2048"
    },
    {
        "engDate": "1992-01-18",
        "nepDate": "04.10.2048"
    },
    {
        "engDate": "1992-01-19",
        "nepDate": "05.10.2048"
    },
    {
        "engDate": "1992-01-20",
        "nepDate": "06.10.2048"
    },
    {
        "engDate": "1992-01-21",
        "nepDate": "07.10.2048"
    },
    {
        "engDate": "1992-01-22",
        "nepDate": "08.10.2048"
    },
    {
        "engDate": "1992-01-23",
        "nepDate": "09.10.2048"
    },
    {
        "engDate": "1992-01-24",
        "nepDate": "10.10.2048"
    },
    {
        "engDate": "1992-01-25",
        "nepDate": "11.10.2048"
    },
    {
        "engDate": "1992-01-26",
        "nepDate": "12.10.2048"
    },
    {
        "engDate": "1992-01-27",
        "nepDate": "13.10.2048"
    },
    {
        "engDate": "1992-01-28",
        "nepDate": "14.10.2048"
    },
    {
        "engDate": "1992-01-29",
        "nepDate": "15.10.2048"
    },
    {
        "engDate": "1992-01-30",
        "nepDate": "16.10.2048"
    },
    {
        "engDate": "1992-01-31",
        "nepDate": "17.10.2048"
    },
    {
        "engDate": "1992-02-01",
        "nepDate": "18.10.2048"
    },
    {
        "engDate": "1992-02-02",
        "nepDate": "19.10.2048"
    },
    {
        "engDate": "1992-02-03",
        "nepDate": "20.10.2048"
    },
    {
        "engDate": "1992-02-04",
        "nepDate": "21.10.2048"
    },
    {
        "engDate": "1992-02-05",
        "nepDate": "22.10.2048"
    },
    {
        "engDate": "1992-02-06",
        "nepDate": "23.10.2048"
    },
    {
        "engDate": "1992-02-07",
        "nepDate": "24.10.2048"
    },
    {
        "engDate": "1992-02-08",
        "nepDate": "25.10.2048"
    },
    {
        "engDate": "1992-02-09",
        "nepDate": "26.10.2048"
    },
    {
        "engDate": "1992-02-10",
        "nepDate": "27.10.2048"
    },
    {
        "engDate": "1992-02-11",
        "nepDate": "28.10.2048"
    },
    {
        "engDate": "1992-02-12",
        "nepDate": "29.10.2048"
    },
    {
        "engDate": "1992-02-13",
        "nepDate": "01.11.2048"
    },
    {
        "engDate": "1992-02-14",
        "nepDate": "02.11.2048"
    },
    {
        "engDate": "1992-02-15",
        "nepDate": "03.11.2048"
    },
    {
        "engDate": "1992-02-16",
        "nepDate": "04.11.2048"
    },
    {
        "engDate": "1992-02-17",
        "nepDate": "05.11.2048"
    },
    {
        "engDate": "1992-02-18",
        "nepDate": "06.11.2048"
    },
    {
        "engDate": "1992-02-19",
        "nepDate": "07.11.2048"
    },
    {
        "engDate": "1992-02-20",
        "nepDate": "08.11.2048"
    },
    {
        "engDate": "1992-02-21",
        "nepDate": "09.11.2048"
    },
    {
        "engDate": "1992-02-22",
        "nepDate": "10.11.2048"
    },
    {
        "engDate": "1992-02-23",
        "nepDate": "11.11.2048"
    },
    {
        "engDate": "1992-02-24",
        "nepDate": "12.11.2048"
    },
    {
        "engDate": "1992-02-25",
        "nepDate": "13.11.2048"
    },
    {
        "engDate": "1992-02-26",
        "nepDate": "14.11.2048"
    },
    {
        "engDate": "1992-02-27",
        "nepDate": "15.11.2048"
    },
    {
        "engDate": "1992-02-28",
        "nepDate": "16.11.2048"
    },
    {
        "engDate": "1992-02-29",
        "nepDate": "17.11.2048"
    },
    {
        "engDate": "1992-03-01",
        "nepDate": "18.11.2048"
    },
    {
        "engDate": "1992-03-02",
        "nepDate": "19.11.2048"
    },
    {
        "engDate": "1992-03-03",
        "nepDate": "20.11.2048"
    },
    {
        "engDate": "1992-03-04",
        "nepDate": "21.11.2048"
    },
    {
        "engDate": "1992-03-05",
        "nepDate": "22.11.2048"
    },
    {
        "engDate": "1992-03-06",
        "nepDate": "23.11.2048"
    },
    {
        "engDate": "1992-03-07",
        "nepDate": "24.11.2048"
    },
    {
        "engDate": "1992-03-08",
        "nepDate": "25.11.2048"
    },
    {
        "engDate": "1992-03-09",
        "nepDate": "26.11.2048"
    },
    {
        "engDate": "1992-03-10",
        "nepDate": "27.11.2048"
    },
    {
        "engDate": "1992-03-11",
        "nepDate": "28.11.2048"
    },
    {
        "engDate": "1992-03-12",
        "nepDate": "29.11.2048"
    },
    {
        "engDate": "1992-03-13",
        "nepDate": "30.11.2048"
    },
    {
        "engDate": "1992-03-14",
        "nepDate": "01.12.2048"
    },
    {
        "engDate": "1992-03-15",
        "nepDate": "02.12.2048"
    },
    {
        "engDate": "1992-03-16",
        "nepDate": "03.12.2048"
    },
    {
        "engDate": "1992-03-17",
        "nepDate": "04.12.2048"
    },
    {
        "engDate": "1992-03-18",
        "nepDate": "05.12.2048"
    },
    {
        "engDate": "1992-03-19",
        "nepDate": "06.12.2048"
    },
    {
        "engDate": "1992-03-20",
        "nepDate": "07.12.2048"
    },
    {
        "engDate": "1992-03-21",
        "nepDate": "08.12.2048"
    },
    {
        "engDate": "1992-03-22",
        "nepDate": "09.12.2048"
    },
    {
        "engDate": "1992-03-23",
        "nepDate": "10.12.2048"
    },
    {
        "engDate": "1992-03-24",
        "nepDate": "11.12.2048"
    },
    {
        "engDate": "1992-03-25",
        "nepDate": "12.12.2048"
    },
    {
        "engDate": "1992-03-26",
        "nepDate": "13.12.2048"
    },
    {
        "engDate": "1992-03-27",
        "nepDate": "14.12.2048"
    },
    {
        "engDate": "1992-03-28",
        "nepDate": "15.12.2048"
    },
    {
        "engDate": "1992-03-29",
        "nepDate": "16.12.2048"
    },
    {
        "engDate": "1992-03-30",
        "nepDate": "17.12.2048"
    },
    {
        "engDate": "1992-03-31",
        "nepDate": "18.12.2048"
    },
    {
        "engDate": "1992-04-01",
        "nepDate": "19.12.2048"
    },
    {
        "engDate": "1992-04-02",
        "nepDate": "20.12.2048"
    },
    {
        "engDate": "1992-04-03",
        "nepDate": "21.12.2048"
    },
    {
        "engDate": "1992-04-04",
        "nepDate": "22.12.2048"
    },
    {
        "engDate": "1992-04-05",
        "nepDate": "23.12.2048"
    },
    {
        "engDate": "1992-04-06",
        "nepDate": "24.12.2048"
    },
    {
        "engDate": "1992-04-07",
        "nepDate": "25.12.2048"
    },
    {
        "engDate": "1992-04-08",
        "nepDate": "26.12.2048"
    },
    {
        "engDate": "1992-04-09",
        "nepDate": "27.12.2048"
    },
    {
        "engDate": "1992-04-10",
        "nepDate": "28.12.2048"
    },
    {
        "engDate": "1992-04-11",
        "nepDate": "29.12.2048"
    },
    {
        "engDate": "1992-04-12",
        "nepDate": "30.12.2048"
    },
    {
        "engDate": "1992-04-13",
        "nepDate": "01.01.2049"
    },
    {
        "engDate": "1992-04-14",
        "nepDate": "02.01.2049"
    },
    {
        "engDate": "1992-04-15",
        "nepDate": "03.01.2049"
    },
    {
        "engDate": "1992-04-16",
        "nepDate": "04.01.2049"
    },
    {
        "engDate": "1992-04-17",
        "nepDate": "05.01.2049"
    },
    {
        "engDate": "1992-04-18",
        "nepDate": "06.01.2049"
    },
    {
        "engDate": "1992-04-19",
        "nepDate": "07.01.2049"
    },
    {
        "engDate": "1992-04-20",
        "nepDate": "08.01.2049"
    },
    {
        "engDate": "1992-04-21",
        "nepDate": "09.01.2049"
    },
    {
        "engDate": "1992-04-22",
        "nepDate": "10.01.2049"
    },
    {
        "engDate": "1992-04-23",
        "nepDate": "11.01.2049"
    },
    {
        "engDate": "1992-04-24",
        "nepDate": "12.01.2049"
    },
    {
        "engDate": "1992-04-25",
        "nepDate": "13.01.2049"
    },
    {
        "engDate": "1992-04-26",
        "nepDate": "14.01.2049"
    },
    {
        "engDate": "1992-04-27",
        "nepDate": "15.01.2049"
    },
    {
        "engDate": "1992-04-28",
        "nepDate": "16.01.2049"
    },
    {
        "engDate": "1992-04-29",
        "nepDate": "17.01.2049"
    },
    {
        "engDate": "1992-04-30",
        "nepDate": "18.01.2049"
    },
    {
        "engDate": "1992-05-01",
        "nepDate": "19.01.2049"
    },
    {
        "engDate": "1992-05-02",
        "nepDate": "20.01.2049"
    },
    {
        "engDate": "1992-05-03",
        "nepDate": "21.01.2049"
    },
    {
        "engDate": "1992-05-04",
        "nepDate": "22.01.2049"
    },
    {
        "engDate": "1992-05-05",
        "nepDate": "23.01.2049"
    },
    {
        "engDate": "1992-05-06",
        "nepDate": "24.01.2049"
    },
    {
        "engDate": "1992-05-07",
        "nepDate": "25.01.2049"
    },
    {
        "engDate": "1992-05-08",
        "nepDate": "26.01.2049"
    },
    {
        "engDate": "1992-05-09",
        "nepDate": "27.01.2049"
    },
    {
        "engDate": "1992-05-10",
        "nepDate": "28.01.2049"
    },
    {
        "engDate": "1992-05-11",
        "nepDate": "29.01.2049"
    },
    {
        "engDate": "1992-05-12",
        "nepDate": "30.01.2049"
    },
    {
        "engDate": "1992-05-13",
        "nepDate": "31.01.2049"
    },
    {
        "engDate": "1992-05-14",
        "nepDate": "01.02.2049"
    },
    {
        "engDate": "1992-05-15",
        "nepDate": "02.02.2049"
    },
    {
        "engDate": "1992-05-16",
        "nepDate": "03.02.2049"
    },
    {
        "engDate": "1992-05-17",
        "nepDate": "04.02.2049"
    },
    {
        "engDate": "1992-05-18",
        "nepDate": "05.02.2049"
    },
    {
        "engDate": "1992-05-19",
        "nepDate": "06.02.2049"
    },
    {
        "engDate": "1992-05-20",
        "nepDate": "07.02.2049"
    },
    {
        "engDate": "1992-05-21",
        "nepDate": "08.02.2049"
    },
    {
        "engDate": "1992-05-22",
        "nepDate": "09.02.2049"
    },
    {
        "engDate": "1992-05-23",
        "nepDate": "10.02.2049"
    },
    {
        "engDate": "1992-05-24",
        "nepDate": "11.02.2049"
    },
    {
        "engDate": "1992-05-25",
        "nepDate": "12.02.2049"
    },
    {
        "engDate": "1992-05-26",
        "nepDate": "13.02.2049"
    },
    {
        "engDate": "1992-05-27",
        "nepDate": "14.02.2049"
    },
    {
        "engDate": "1992-05-28",
        "nepDate": "15.02.2049"
    },
    {
        "engDate": "1992-05-29",
        "nepDate": "16.02.2049"
    },
    {
        "engDate": "1992-05-30",
        "nepDate": "17.02.2049"
    },
    {
        "engDate": "1992-05-31",
        "nepDate": "18.02.2049"
    },
    {
        "engDate": "1992-06-01",
        "nepDate": "19.02.2049"
    },
    {
        "engDate": "1992-06-02",
        "nepDate": "20.02.2049"
    },
    {
        "engDate": "1992-06-03",
        "nepDate": "21.02.2049"
    },
    {
        "engDate": "1992-06-04",
        "nepDate": "22.02.2049"
    },
    {
        "engDate": "1992-06-05",
        "nepDate": "23.02.2049"
    },
    {
        "engDate": "1992-06-06",
        "nepDate": "24.02.2049"
    },
    {
        "engDate": "1992-06-07",
        "nepDate": "25.02.2049"
    },
    {
        "engDate": "1992-06-08",
        "nepDate": "26.02.2049"
    },
    {
        "engDate": "1992-06-09",
        "nepDate": "27.02.2049"
    },
    {
        "engDate": "1992-06-10",
        "nepDate": "28.02.2049"
    },
    {
        "engDate": "1992-06-11",
        "nepDate": "29.02.2049"
    },
    {
        "engDate": "1992-06-12",
        "nepDate": "30.02.2049"
    },
    {
        "engDate": "1992-06-13",
        "nepDate": "31.02.2049"
    },
    {
        "engDate": "1992-06-14",
        "nepDate": "32.02.2049"
    },
    {
        "engDate": "1992-06-15",
        "nepDate": "01.03.2049"
    },
    {
        "engDate": "1992-06-16",
        "nepDate": "02.03.2049"
    },
    {
        "engDate": "1992-06-17",
        "nepDate": "03.03.2049"
    },
    {
        "engDate": "1992-06-18",
        "nepDate": "04.03.2049"
    },
    {
        "engDate": "1992-06-19",
        "nepDate": "05.03.2049"
    },
    {
        "engDate": "1992-06-20",
        "nepDate": "06.03.2049"
    },
    {
        "engDate": "1992-06-21",
        "nepDate": "07.03.2049"
    },
    {
        "engDate": "1992-06-22",
        "nepDate": "08.03.2049"
    },
    {
        "engDate": "1992-06-23",
        "nepDate": "09.03.2049"
    },
    {
        "engDate": "1992-06-24",
        "nepDate": "10.03.2049"
    },
    {
        "engDate": "1992-06-25",
        "nepDate": "11.03.2049"
    },
    {
        "engDate": "1992-06-26",
        "nepDate": "12.03.2049"
    },
    {
        "engDate": "1992-06-27",
        "nepDate": "13.03.2049"
    },
    {
        "engDate": "1992-06-28",
        "nepDate": "14.03.2049"
    },
    {
        "engDate": "1992-06-29",
        "nepDate": "15.03.2049"
    },
    {
        "engDate": "1992-06-30",
        "nepDate": "16.03.2049"
    },
    {
        "engDate": "1992-07-01",
        "nepDate": "17.03.2049"
    },
    {
        "engDate": "1992-07-02",
        "nepDate": "18.03.2049"
    },
    {
        "engDate": "1992-07-03",
        "nepDate": "19.03.2049"
    },
    {
        "engDate": "1992-07-04",
        "nepDate": "20.03.2049"
    },
    {
        "engDate": "1992-07-05",
        "nepDate": "21.03.2049"
    },
    {
        "engDate": "1992-07-06",
        "nepDate": "22.03.2049"
    },
    {
        "engDate": "1992-07-07",
        "nepDate": "23.03.2049"
    },
    {
        "engDate": "1992-07-08",
        "nepDate": "24.03.2049"
    },
    {
        "engDate": "1992-07-09",
        "nepDate": "25.03.2049"
    },
    {
        "engDate": "1992-07-10",
        "nepDate": "26.03.2049"
    },
    {
        "engDate": "1992-07-11",
        "nepDate": "27.03.2049"
    },
    {
        "engDate": "1992-07-12",
        "nepDate": "28.03.2049"
    },
    {
        "engDate": "1992-07-13",
        "nepDate": "29.03.2049"
    },
    {
        "engDate": "1992-07-14",
        "nepDate": "30.03.2049"
    },
    {
        "engDate": "1992-07-15",
        "nepDate": "31.03.2049"
    },
    {
        "engDate": "1992-07-16",
        "nepDate": "01.04.2049"
    },
    {
        "engDate": "1992-07-17",
        "nepDate": "02.04.2049"
    },
    {
        "engDate": "1992-07-18",
        "nepDate": "03.04.2049"
    },
    {
        "engDate": "1992-07-19",
        "nepDate": "04.04.2049"
    },
    {
        "engDate": "1992-07-20",
        "nepDate": "05.04.2049"
    },
    {
        "engDate": "1992-07-21",
        "nepDate": "06.04.2049"
    },
    {
        "engDate": "1992-07-22",
        "nepDate": "07.04.2049"
    },
    {
        "engDate": "1992-07-23",
        "nepDate": "08.04.2049"
    },
    {
        "engDate": "1992-07-24",
        "nepDate": "09.04.2049"
    },
    {
        "engDate": "1992-07-25",
        "nepDate": "10.04.2049"
    },
    {
        "engDate": "1992-07-26",
        "nepDate": "11.04.2049"
    },
    {
        "engDate": "1992-07-27",
        "nepDate": "12.04.2049"
    },
    {
        "engDate": "1992-07-28",
        "nepDate": "13.04.2049"
    },
    {
        "engDate": "1992-07-29",
        "nepDate": "14.04.2049"
    },
    {
        "engDate": "1992-07-30",
        "nepDate": "15.04.2049"
    },
    {
        "engDate": "1992-07-31",
        "nepDate": "16.04.2049"
    },
    {
        "engDate": "1992-08-01",
        "nepDate": "17.04.2049"
    },
    {
        "engDate": "1992-08-02",
        "nepDate": "18.04.2049"
    },
    {
        "engDate": "1992-08-03",
        "nepDate": "19.04.2049"
    },
    {
        "engDate": "1992-08-04",
        "nepDate": "20.04.2049"
    },
    {
        "engDate": "1992-08-05",
        "nepDate": "21.04.2049"
    },
    {
        "engDate": "1992-08-06",
        "nepDate": "22.04.2049"
    },
    {
        "engDate": "1992-08-07",
        "nepDate": "23.04.2049"
    },
    {
        "engDate": "1992-08-08",
        "nepDate": "24.04.2049"
    },
    {
        "engDate": "1992-08-09",
        "nepDate": "25.04.2049"
    },
    {
        "engDate": "1992-08-10",
        "nepDate": "26.04.2049"
    },
    {
        "engDate": "1992-08-11",
        "nepDate": "27.04.2049"
    },
    {
        "engDate": "1992-08-12",
        "nepDate": "28.04.2049"
    },
    {
        "engDate": "1992-08-13",
        "nepDate": "29.04.2049"
    },
    {
        "engDate": "1992-08-14",
        "nepDate": "30.04.2049"
    },
    {
        "engDate": "1992-08-15",
        "nepDate": "31.04.2049"
    },
    {
        "engDate": "1992-08-16",
        "nepDate": "32.04.2049"
    },
    {
        "engDate": "1992-08-17",
        "nepDate": "01.05.2049"
    },
    {
        "engDate": "1992-08-18",
        "nepDate": "02.05.2049"
    },
    {
        "engDate": "1992-08-19",
        "nepDate": "03.05.2049"
    },
    {
        "engDate": "1992-08-20",
        "nepDate": "04.05.2049"
    },
    {
        "engDate": "1992-08-21",
        "nepDate": "05.05.2049"
    },
    {
        "engDate": "1992-08-22",
        "nepDate": "06.05.2049"
    },
    {
        "engDate": "1992-08-23",
        "nepDate": "07.05.2049"
    },
    {
        "engDate": "1992-08-24",
        "nepDate": "08.05.2049"
    },
    {
        "engDate": "1992-08-25",
        "nepDate": "09.05.2049"
    },
    {
        "engDate": "1992-08-26",
        "nepDate": "10.05.2049"
    },
    {
        "engDate": "1992-08-27",
        "nepDate": "11.05.2049"
    },
    {
        "engDate": "1992-08-28",
        "nepDate": "12.05.2049"
    },
    {
        "engDate": "1992-08-29",
        "nepDate": "13.05.2049"
    },
    {
        "engDate": "1992-08-30",
        "nepDate": "14.05.2049"
    },
    {
        "engDate": "1992-08-31",
        "nepDate": "15.05.2049"
    },
    {
        "engDate": "1992-09-01",
        "nepDate": "16.05.2049"
    },
    {
        "engDate": "1992-09-02",
        "nepDate": "17.05.2049"
    },
    {
        "engDate": "1992-09-03",
        "nepDate": "18.05.2049"
    },
    {
        "engDate": "1992-09-04",
        "nepDate": "19.05.2049"
    },
    {
        "engDate": "1992-09-05",
        "nepDate": "20.05.2049"
    },
    {
        "engDate": "1992-09-06",
        "nepDate": "21.05.2049"
    },
    {
        "engDate": "1992-09-07",
        "nepDate": "22.05.2049"
    },
    {
        "engDate": "1992-09-08",
        "nepDate": "23.05.2049"
    },
    {
        "engDate": "1992-09-09",
        "nepDate": "24.05.2049"
    },
    {
        "engDate": "1992-09-10",
        "nepDate": "25.05.2049"
    },
    {
        "engDate": "1992-09-11",
        "nepDate": "26.05.2049"
    },
    {
        "engDate": "1992-09-12",
        "nepDate": "27.05.2049"
    },
    {
        "engDate": "1992-09-13",
        "nepDate": "28.05.2049"
    },
    {
        "engDate": "1992-09-14",
        "nepDate": "29.05.2049"
    },
    {
        "engDate": "1992-09-15",
        "nepDate": "30.05.2049"
    },
    {
        "engDate": "1992-09-16",
        "nepDate": "31.05.2049"
    },
    {
        "engDate": "1992-09-17",
        "nepDate": "01.06.2049"
    },
    {
        "engDate": "1992-09-18",
        "nepDate": "02.06.2049"
    },
    {
        "engDate": "1992-09-19",
        "nepDate": "03.06.2049"
    },
    {
        "engDate": "1992-09-20",
        "nepDate": "04.06.2049"
    },
    {
        "engDate": "1992-09-21",
        "nepDate": "05.06.2049"
    },
    {
        "engDate": "1992-09-22",
        "nepDate": "06.06.2049"
    },
    {
        "engDate": "1992-09-23",
        "nepDate": "07.06.2049"
    },
    {
        "engDate": "1992-09-24",
        "nepDate": "08.06.2049"
    },
    {
        "engDate": "1992-09-25",
        "nepDate": "09.06.2049"
    },
    {
        "engDate": "1992-09-26",
        "nepDate": "10.06.2049"
    },
    {
        "engDate": "1992-09-27",
        "nepDate": "11.06.2049"
    },
    {
        "engDate": "1992-09-28",
        "nepDate": "12.06.2049"
    },
    {
        "engDate": "1992-09-29",
        "nepDate": "13.06.2049"
    },
    {
        "engDate": "1992-09-30",
        "nepDate": "14.06.2049"
    },
    {
        "engDate": "1992-10-01",
        "nepDate": "15.06.2049"
    },
    {
        "engDate": "1992-10-02",
        "nepDate": "16.06.2049"
    },
    {
        "engDate": "1992-10-03",
        "nepDate": "17.06.2049"
    },
    {
        "engDate": "1992-10-04",
        "nepDate": "18.06.2049"
    },
    {
        "engDate": "1992-10-05",
        "nepDate": "19.06.2049"
    },
    {
        "engDate": "1992-10-06",
        "nepDate": "20.06.2049"
    },
    {
        "engDate": "1992-10-07",
        "nepDate": "21.06.2049"
    },
    {
        "engDate": "1992-10-08",
        "nepDate": "22.06.2049"
    },
    {
        "engDate": "1992-10-09",
        "nepDate": "23.06.2049"
    },
    {
        "engDate": "1992-10-10",
        "nepDate": "24.06.2049"
    },
    {
        "engDate": "1992-10-11",
        "nepDate": "25.06.2049"
    },
    {
        "engDate": "1992-10-12",
        "nepDate": "26.06.2049"
    },
    {
        "engDate": "1992-10-13",
        "nepDate": "27.06.2049"
    },
    {
        "engDate": "1992-10-14",
        "nepDate": "28.06.2049"
    },
    {
        "engDate": "1992-10-15",
        "nepDate": "29.06.2049"
    },
    {
        "engDate": "1992-10-16",
        "nepDate": "30.06.2049"
    },
    {
        "engDate": "1992-10-17",
        "nepDate": "01.07.2049"
    },
    {
        "engDate": "1992-10-18",
        "nepDate": "02.07.2049"
    },
    {
        "engDate": "1992-10-19",
        "nepDate": "03.07.2049"
    },
    {
        "engDate": "1992-10-20",
        "nepDate": "04.07.2049"
    },
    {
        "engDate": "1992-10-21",
        "nepDate": "05.07.2049"
    },
    {
        "engDate": "1992-10-22",
        "nepDate": "06.07.2049"
    },
    {
        "engDate": "1992-10-23",
        "nepDate": "07.07.2049"
    },
    {
        "engDate": "1992-10-24",
        "nepDate": "08.07.2049"
    },
    {
        "engDate": "1992-10-25",
        "nepDate": "09.07.2049"
    },
    {
        "engDate": "1992-10-26",
        "nepDate": "10.07.2049"
    },
    {
        "engDate": "1992-10-27",
        "nepDate": "11.07.2049"
    },
    {
        "engDate": "1992-10-28",
        "nepDate": "12.07.2049"
    },
    {
        "engDate": "1992-10-29",
        "nepDate": "13.07.2049"
    },
    {
        "engDate": "1992-10-30",
        "nepDate": "14.07.2049"
    },
    {
        "engDate": "1992-10-31",
        "nepDate": "15.07.2049"
    },
    {
        "engDate": "1992-11-01",
        "nepDate": "16.07.2049"
    },
    {
        "engDate": "1992-11-02",
        "nepDate": "17.07.2049"
    },
    {
        "engDate": "1992-11-03",
        "nepDate": "18.07.2049"
    },
    {
        "engDate": "1992-11-04",
        "nepDate": "19.07.2049"
    },
    {
        "engDate": "1992-11-05",
        "nepDate": "20.07.2049"
    },
    {
        "engDate": "1992-11-06",
        "nepDate": "21.07.2049"
    },
    {
        "engDate": "1992-11-07",
        "nepDate": "22.07.2049"
    },
    {
        "engDate": "1992-11-08",
        "nepDate": "23.07.2049"
    },
    {
        "engDate": "1992-11-09",
        "nepDate": "24.07.2049"
    },
    {
        "engDate": "1992-11-10",
        "nepDate": "25.07.2049"
    },
    {
        "engDate": "1992-11-11",
        "nepDate": "26.07.2049"
    },
    {
        "engDate": "1992-11-12",
        "nepDate": "27.07.2049"
    },
    {
        "engDate": "1992-11-13",
        "nepDate": "28.07.2049"
    },
    {
        "engDate": "1992-11-14",
        "nepDate": "29.07.2049"
    },
    {
        "engDate": "1992-11-15",
        "nepDate": "30.07.2049"
    },
    {
        "engDate": "1992-11-16",
        "nepDate": "01.08.2049"
    },
    {
        "engDate": "1992-11-17",
        "nepDate": "02.08.2049"
    },
    {
        "engDate": "1992-11-18",
        "nepDate": "03.08.2049"
    },
    {
        "engDate": "1992-11-19",
        "nepDate": "04.08.2049"
    },
    {
        "engDate": "1992-11-20",
        "nepDate": "05.08.2049"
    },
    {
        "engDate": "1992-11-21",
        "nepDate": "06.08.2049"
    },
    {
        "engDate": "1992-11-22",
        "nepDate": "07.08.2049"
    },
    {
        "engDate": "1992-11-23",
        "nepDate": "08.08.2049"
    },
    {
        "engDate": "1992-11-24",
        "nepDate": "09.08.2049"
    },
    {
        "engDate": "1992-11-25",
        "nepDate": "10.08.2049"
    },
    {
        "engDate": "1992-11-26",
        "nepDate": "11.08.2049"
    },
    {
        "engDate": "1992-11-27",
        "nepDate": "12.08.2049"
    },
    {
        "engDate": "1992-11-28",
        "nepDate": "13.08.2049"
    },
    {
        "engDate": "1992-11-29",
        "nepDate": "14.08.2049"
    },
    {
        "engDate": "1992-11-30",
        "nepDate": "15.08.2049"
    },
    {
        "engDate": "1992-12-01",
        "nepDate": "16.08.2049"
    },
    {
        "engDate": "1992-12-02",
        "nepDate": "17.08.2049"
    },
    {
        "engDate": "1992-12-03",
        "nepDate": "18.08.2049"
    },
    {
        "engDate": "1992-12-04",
        "nepDate": "19.08.2049"
    },
    {
        "engDate": "1992-12-05",
        "nepDate": "20.08.2049"
    },
    {
        "engDate": "1992-12-06",
        "nepDate": "21.08.2049"
    },
    {
        "engDate": "1992-12-07",
        "nepDate": "22.08.2049"
    },
    {
        "engDate": "1992-12-08",
        "nepDate": "23.08.2049"
    },
    {
        "engDate": "1992-12-09",
        "nepDate": "24.08.2049"
    },
    {
        "engDate": "1992-12-10",
        "nepDate": "25.08.2049"
    },
    {
        "engDate": "1992-12-11",
        "nepDate": "26.08.2049"
    },
    {
        "engDate": "1992-12-12",
        "nepDate": "27.08.2049"
    },
    {
        "engDate": "1992-12-13",
        "nepDate": "28.08.2049"
    },
    {
        "engDate": "1992-12-14",
        "nepDate": "29.08.2049"
    },
    {
        "engDate": "1992-12-15",
        "nepDate": "30.08.2049"
    },
    {
        "engDate": "1992-12-16",
        "nepDate": "01.09.2049"
    },
    {
        "engDate": "1992-12-17",
        "nepDate": "02.09.2049"
    },
    {
        "engDate": "1992-12-18",
        "nepDate": "03.09.2049"
    },
    {
        "engDate": "1992-12-19",
        "nepDate": "04.09.2049"
    },
    {
        "engDate": "1992-12-20",
        "nepDate": "05.09.2049"
    },
    {
        "engDate": "1992-12-21",
        "nepDate": "06.09.2049"
    },
    {
        "engDate": "1992-12-22",
        "nepDate": "07.09.2049"
    },
    {
        "engDate": "1992-12-23",
        "nepDate": "08.09.2049"
    },
    {
        "engDate": "1992-12-24",
        "nepDate": "09.09.2049"
    },
    {
        "engDate": "1992-12-25",
        "nepDate": "10.09.2049"
    },
    {
        "engDate": "1992-12-26",
        "nepDate": "11.09.2049"
    },
    {
        "engDate": "1992-12-27",
        "nepDate": "12.09.2049"
    },
    {
        "engDate": "1992-12-28",
        "nepDate": "13.09.2049"
    },
    {
        "engDate": "1992-12-29",
        "nepDate": "14.09.2049"
    },
    {
        "engDate": "1992-12-30",
        "nepDate": "15.09.2049"
    },
    {
        "engDate": "1992-12-31",
        "nepDate": "16.09.2049"
    },
    {
        "engDate": "1993-01-01",
        "nepDate": "17.09.2049"
    },
    {
        "engDate": "1993-01-02",
        "nepDate": "18.09.2049"
    },
    {
        "engDate": "1993-01-03",
        "nepDate": "19.09.2049"
    },
    {
        "engDate": "1993-01-04",
        "nepDate": "20.09.2049"
    },
    {
        "engDate": "1993-01-05",
        "nepDate": "21.09.2049"
    },
    {
        "engDate": "1993-01-06",
        "nepDate": "22.09.2049"
    },
    {
        "engDate": "1993-01-07",
        "nepDate": "23.09.2049"
    },
    {
        "engDate": "1993-01-08",
        "nepDate": "24.09.2049"
    },
    {
        "engDate": "1993-01-09",
        "nepDate": "25.09.2049"
    },
    {
        "engDate": "1993-01-10",
        "nepDate": "26.09.2049"
    },
    {
        "engDate": "1993-01-11",
        "nepDate": "27.09.2049"
    },
    {
        "engDate": "1993-01-12",
        "nepDate": "28.09.2049"
    },
    {
        "engDate": "1993-01-13",
        "nepDate": "29.09.2049"
    },
    {
        "engDate": "1993-01-14",
        "nepDate": "01.10.2049"
    },
    {
        "engDate": "1993-01-15",
        "nepDate": "02.10.2049"
    },
    {
        "engDate": "1993-01-16",
        "nepDate": "03.10.2049"
    },
    {
        "engDate": "1993-01-17",
        "nepDate": "04.10.2049"
    },
    {
        "engDate": "1993-01-18",
        "nepDate": "05.10.2049"
    },
    {
        "engDate": "1993-01-19",
        "nepDate": "06.10.2049"
    },
    {
        "engDate": "1993-01-20",
        "nepDate": "07.10.2049"
    },
    {
        "engDate": "1993-01-21",
        "nepDate": "08.10.2049"
    },
    {
        "engDate": "1993-01-22",
        "nepDate": "09.10.2049"
    },
    {
        "engDate": "1993-01-23",
        "nepDate": "10.10.2049"
    },
    {
        "engDate": "1993-01-24",
        "nepDate": "11.10.2049"
    },
    {
        "engDate": "1993-01-25",
        "nepDate": "12.10.2049"
    },
    {
        "engDate": "1993-01-26",
        "nepDate": "13.10.2049"
    },
    {
        "engDate": "1993-01-27",
        "nepDate": "14.10.2049"
    },
    {
        "engDate": "1993-01-28",
        "nepDate": "15.10.2049"
    },
    {
        "engDate": "1993-01-29",
        "nepDate": "16.10.2049"
    },
    {
        "engDate": "1993-01-30",
        "nepDate": "17.10.2049"
    },
    {
        "engDate": "1993-01-31",
        "nepDate": "18.10.2049"
    },
    {
        "engDate": "1993-02-01",
        "nepDate": "19.10.2049"
    },
    {
        "engDate": "1993-02-02",
        "nepDate": "20.10.2049"
    },
    {
        "engDate": "1993-02-03",
        "nepDate": "21.10.2049"
    },
    {
        "engDate": "1993-02-04",
        "nepDate": "22.10.2049"
    },
    {
        "engDate": "1993-02-05",
        "nepDate": "23.10.2049"
    },
    {
        "engDate": "1993-02-06",
        "nepDate": "24.10.2049"
    },
    {
        "engDate": "1993-02-07",
        "nepDate": "25.10.2049"
    },
    {
        "engDate": "1993-02-08",
        "nepDate": "26.10.2049"
    },
    {
        "engDate": "1993-02-09",
        "nepDate": "27.10.2049"
    },
    {
        "engDate": "1993-02-10",
        "nepDate": "28.10.2049"
    },
    {
        "engDate": "1993-02-11",
        "nepDate": "29.10.2049"
    },
    {
        "engDate": "1993-02-12",
        "nepDate": "01.11.2049"
    },
    {
        "engDate": "1993-02-13",
        "nepDate": "02.11.2049"
    },
    {
        "engDate": "1993-02-14",
        "nepDate": "03.11.2049"
    },
    {
        "engDate": "1993-02-15",
        "nepDate": "04.11.2049"
    },
    {
        "engDate": "1993-02-16",
        "nepDate": "05.11.2049"
    },
    {
        "engDate": "1993-02-17",
        "nepDate": "06.11.2049"
    },
    {
        "engDate": "1993-02-18",
        "nepDate": "07.11.2049"
    },
    {
        "engDate": "1993-02-19",
        "nepDate": "08.11.2049"
    },
    {
        "engDate": "1993-02-20",
        "nepDate": "09.11.2049"
    },
    {
        "engDate": "1993-02-21",
        "nepDate": "10.11.2049"
    },
    {
        "engDate": "1993-02-22",
        "nepDate": "11.11.2049"
    },
    {
        "engDate": "1993-02-23",
        "nepDate": "12.11.2049"
    },
    {
        "engDate": "1993-02-24",
        "nepDate": "13.11.2049"
    },
    {
        "engDate": "1993-02-25",
        "nepDate": "14.11.2049"
    },
    {
        "engDate": "1993-02-26",
        "nepDate": "15.11.2049"
    },
    {
        "engDate": "1993-02-27",
        "nepDate": "16.11.2049"
    },
    {
        "engDate": "1993-02-28",
        "nepDate": "17.11.2049"
    },
    {
        "engDate": "1993-03-01",
        "nepDate": "18.11.2049"
    },
    {
        "engDate": "1993-03-02",
        "nepDate": "19.11.2049"
    },
    {
        "engDate": "1993-03-03",
        "nepDate": "20.11.2049"
    },
    {
        "engDate": "1993-03-04",
        "nepDate": "21.11.2049"
    },
    {
        "engDate": "1993-03-05",
        "nepDate": "22.11.2049"
    },
    {
        "engDate": "1993-03-06",
        "nepDate": "23.11.2049"
    },
    {
        "engDate": "1993-03-07",
        "nepDate": "24.11.2049"
    },
    {
        "engDate": "1993-03-08",
        "nepDate": "25.11.2049"
    },
    {
        "engDate": "1993-03-09",
        "nepDate": "26.11.2049"
    },
    {
        "engDate": "1993-03-10",
        "nepDate": "27.11.2049"
    },
    {
        "engDate": "1993-03-11",
        "nepDate": "28.11.2049"
    },
    {
        "engDate": "1993-03-12",
        "nepDate": "29.11.2049"
    },
    {
        "engDate": "1993-03-13",
        "nepDate": "30.11.2049"
    },
    {
        "engDate": "1993-03-14",
        "nepDate": "01.12.2049"
    },
    {
        "engDate": "1993-03-15",
        "nepDate": "02.12.2049"
    },
    {
        "engDate": "1993-03-16",
        "nepDate": "03.12.2049"
    },
    {
        "engDate": "1993-03-17",
        "nepDate": "04.12.2049"
    },
    {
        "engDate": "1993-03-18",
        "nepDate": "05.12.2049"
    },
    {
        "engDate": "1993-03-19",
        "nepDate": "06.12.2049"
    },
    {
        "engDate": "1993-03-20",
        "nepDate": "07.12.2049"
    },
    {
        "engDate": "1993-03-21",
        "nepDate": "08.12.2049"
    },
    {
        "engDate": "1993-03-22",
        "nepDate": "09.12.2049"
    },
    {
        "engDate": "1993-03-23",
        "nepDate": "10.12.2049"
    },
    {
        "engDate": "1993-03-24",
        "nepDate": "11.12.2049"
    },
    {
        "engDate": "1993-03-25",
        "nepDate": "12.12.2049"
    },
    {
        "engDate": "1993-03-26",
        "nepDate": "13.12.2049"
    },
    {
        "engDate": "1993-03-27",
        "nepDate": "14.12.2049"
    },
    {
        "engDate": "1993-03-28",
        "nepDate": "15.12.2049"
    },
    {
        "engDate": "1993-03-29",
        "nepDate": "16.12.2049"
    },
    {
        "engDate": "1993-03-30",
        "nepDate": "17.12.2049"
    },
    {
        "engDate": "1993-03-31",
        "nepDate": "18.12.2049"
    },
    {
        "engDate": "1993-04-01",
        "nepDate": "19.12.2049"
    },
    {
        "engDate": "1993-04-02",
        "nepDate": "20.12.2049"
    },
    {
        "engDate": "1993-04-03",
        "nepDate": "21.12.2049"
    },
    {
        "engDate": "1993-04-04",
        "nepDate": "22.12.2049"
    },
    {
        "engDate": "1993-04-05",
        "nepDate": "23.12.2049"
    },
    {
        "engDate": "1993-04-06",
        "nepDate": "24.12.2049"
    },
    {
        "engDate": "1993-04-07",
        "nepDate": "25.12.2049"
    },
    {
        "engDate": "1993-04-08",
        "nepDate": "26.12.2049"
    },
    {
        "engDate": "1993-04-09",
        "nepDate": "27.12.2049"
    },
    {
        "engDate": "1993-04-10",
        "nepDate": "28.12.2049"
    },
    {
        "engDate": "1993-04-11",
        "nepDate": "29.12.2049"
    },
    {
        "engDate": "1993-04-12",
        "nepDate": "30.12.2049"
    },
    {
        "engDate": "1993-04-13",
        "nepDate": "01.01.2050"
    },
    {
        "engDate": "1993-04-14",
        "nepDate": "02.01.2050"
    },
    {
        "engDate": "1993-04-15",
        "nepDate": "03.01.2050"
    },
    {
        "engDate": "1993-04-16",
        "nepDate": "04.01.2050"
    },
    {
        "engDate": "1993-04-17",
        "nepDate": "05.01.2050"
    },
    {
        "engDate": "1993-04-18",
        "nepDate": "06.01.2050"
    },
    {
        "engDate": "1993-04-19",
        "nepDate": "07.01.2050"
    },
    {
        "engDate": "1993-04-20",
        "nepDate": "08.01.2050"
    },
    {
        "engDate": "1993-04-21",
        "nepDate": "09.01.2050"
    },
    {
        "engDate": "1993-04-22",
        "nepDate": "10.01.2050"
    },
    {
        "engDate": "1993-04-23",
        "nepDate": "11.01.2050"
    },
    {
        "engDate": "1993-04-24",
        "nepDate": "12.01.2050"
    },
    {
        "engDate": "1993-04-25",
        "nepDate": "13.01.2050"
    },
    {
        "engDate": "1993-04-26",
        "nepDate": "14.01.2050"
    },
    {
        "engDate": "1993-04-27",
        "nepDate": "15.01.2050"
    },
    {
        "engDate": "1993-04-28",
        "nepDate": "16.01.2050"
    },
    {
        "engDate": "1993-04-29",
        "nepDate": "17.01.2050"
    },
    {
        "engDate": "1993-04-30",
        "nepDate": "18.01.2050"
    },
    {
        "engDate": "1993-05-01",
        "nepDate": "19.01.2050"
    },
    {
        "engDate": "1993-05-02",
        "nepDate": "20.01.2050"
    },
    {
        "engDate": "1993-05-03",
        "nepDate": "21.01.2050"
    },
    {
        "engDate": "1993-05-04",
        "nepDate": "22.01.2050"
    },
    {
        "engDate": "1993-05-05",
        "nepDate": "23.01.2050"
    },
    {
        "engDate": "1993-05-06",
        "nepDate": "24.01.2050"
    },
    {
        "engDate": "1993-05-07",
        "nepDate": "25.01.2050"
    },
    {
        "engDate": "1993-05-08",
        "nepDate": "26.01.2050"
    },
    {
        "engDate": "1993-05-09",
        "nepDate": "27.01.2050"
    },
    {
        "engDate": "1993-05-10",
        "nepDate": "28.01.2050"
    },
    {
        "engDate": "1993-05-11",
        "nepDate": "29.01.2050"
    },
    {
        "engDate": "1993-05-12",
        "nepDate": "30.01.2050"
    },
    {
        "engDate": "1993-05-13",
        "nepDate": "31.01.2050"
    },
    {
        "engDate": "1993-05-14",
        "nepDate": "01.02.2050"
    },
    {
        "engDate": "1993-05-15",
        "nepDate": "02.02.2050"
    },
    {
        "engDate": "1993-05-16",
        "nepDate": "03.02.2050"
    },
    {
        "engDate": "1993-05-17",
        "nepDate": "04.02.2050"
    },
    {
        "engDate": "1993-05-18",
        "nepDate": "05.02.2050"
    },
    {
        "engDate": "1993-05-19",
        "nepDate": "06.02.2050"
    },
    {
        "engDate": "1993-05-20",
        "nepDate": "07.02.2050"
    },
    {
        "engDate": "1993-05-21",
        "nepDate": "08.02.2050"
    },
    {
        "engDate": "1993-05-22",
        "nepDate": "09.02.2050"
    },
    {
        "engDate": "1993-05-23",
        "nepDate": "10.02.2050"
    },
    {
        "engDate": "1993-05-24",
        "nepDate": "11.02.2050"
    },
    {
        "engDate": "1993-05-25",
        "nepDate": "12.02.2050"
    },
    {
        "engDate": "1993-05-26",
        "nepDate": "13.02.2050"
    },
    {
        "engDate": "1993-05-27",
        "nepDate": "14.02.2050"
    },
    {
        "engDate": "1993-05-28",
        "nepDate": "15.02.2050"
    },
    {
        "engDate": "1993-05-29",
        "nepDate": "16.02.2050"
    },
    {
        "engDate": "1993-05-30",
        "nepDate": "17.02.2050"
    },
    {
        "engDate": "1993-05-31",
        "nepDate": "18.02.2050"
    },
    {
        "engDate": "1993-06-01",
        "nepDate": "19.02.2050"
    },
    {
        "engDate": "1993-06-02",
        "nepDate": "20.02.2050"
    },
    {
        "engDate": "1993-06-03",
        "nepDate": "21.02.2050"
    },
    {
        "engDate": "1993-06-04",
        "nepDate": "22.02.2050"
    },
    {
        "engDate": "1993-06-05",
        "nepDate": "23.02.2050"
    },
    {
        "engDate": "1993-06-06",
        "nepDate": "24.02.2050"
    },
    {
        "engDate": "1993-06-07",
        "nepDate": "25.02.2050"
    },
    {
        "engDate": "1993-06-08",
        "nepDate": "26.02.2050"
    },
    {
        "engDate": "1993-06-09",
        "nepDate": "27.02.2050"
    },
    {
        "engDate": "1993-06-10",
        "nepDate": "28.02.2050"
    },
    {
        "engDate": "1993-06-11",
        "nepDate": "29.02.2050"
    },
    {
        "engDate": "1993-06-12",
        "nepDate": "30.02.2050"
    },
    {
        "engDate": "1993-06-13",
        "nepDate": "31.02.2050"
    },
    {
        "engDate": "1993-06-14",
        "nepDate": "32.02.2050"
    },
    {
        "engDate": "1993-06-15",
        "nepDate": "01.03.2050"
    },
    {
        "engDate": "1993-06-16",
        "nepDate": "02.03.2050"
    },
    {
        "engDate": "1993-06-17",
        "nepDate": "03.03.2050"
    },
    {
        "engDate": "1993-06-18",
        "nepDate": "04.03.2050"
    },
    {
        "engDate": "1993-06-19",
        "nepDate": "05.03.2050"
    },
    {
        "engDate": "1993-06-20",
        "nepDate": "06.03.2050"
    },
    {
        "engDate": "1993-06-21",
        "nepDate": "07.03.2050"
    },
    {
        "engDate": "1993-06-22",
        "nepDate": "08.03.2050"
    },
    {
        "engDate": "1993-06-23",
        "nepDate": "09.03.2050"
    },
    {
        "engDate": "1993-06-24",
        "nepDate": "10.03.2050"
    },
    {
        "engDate": "1993-06-25",
        "nepDate": "11.03.2050"
    },
    {
        "engDate": "1993-06-26",
        "nepDate": "12.03.2050"
    },
    {
        "engDate": "1993-06-27",
        "nepDate": "13.03.2050"
    },
    {
        "engDate": "1993-06-28",
        "nepDate": "14.03.2050"
    },
    {
        "engDate": "1993-06-29",
        "nepDate": "15.03.2050"
    },
    {
        "engDate": "1993-06-30",
        "nepDate": "16.03.2050"
    },
    {
        "engDate": "1993-07-01",
        "nepDate": "17.03.2050"
    },
    {
        "engDate": "1993-07-02",
        "nepDate": "18.03.2050"
    },
    {
        "engDate": "1993-07-03",
        "nepDate": "19.03.2050"
    },
    {
        "engDate": "1993-07-04",
        "nepDate": "20.03.2050"
    },
    {
        "engDate": "1993-07-05",
        "nepDate": "21.03.2050"
    },
    {
        "engDate": "1993-07-06",
        "nepDate": "22.03.2050"
    },
    {
        "engDate": "1993-07-07",
        "nepDate": "23.03.2050"
    },
    {
        "engDate": "1993-07-08",
        "nepDate": "24.03.2050"
    },
    {
        "engDate": "1993-07-09",
        "nepDate": "25.03.2050"
    },
    {
        "engDate": "1993-07-10",
        "nepDate": "26.03.2050"
    },
    {
        "engDate": "1993-07-11",
        "nepDate": "27.03.2050"
    },
    {
        "engDate": "1993-07-12",
        "nepDate": "28.03.2050"
    },
    {
        "engDate": "1993-07-13",
        "nepDate": "29.03.2050"
    },
    {
        "engDate": "1993-07-14",
        "nepDate": "30.03.2050"
    },
    {
        "engDate": "1993-07-15",
        "nepDate": "31.03.2050"
    },
    {
        "engDate": "1993-07-16",
        "nepDate": "01.04.2050"
    },
    {
        "engDate": "1993-07-17",
        "nepDate": "02.04.2050"
    },
    {
        "engDate": "1993-07-18",
        "nepDate": "03.04.2050"
    },
    {
        "engDate": "1993-07-19",
        "nepDate": "04.04.2050"
    },
    {
        "engDate": "1993-07-20",
        "nepDate": "05.04.2050"
    },
    {
        "engDate": "1993-07-21",
        "nepDate": "06.04.2050"
    },
    {
        "engDate": "1993-07-22",
        "nepDate": "07.04.2050"
    },
    {
        "engDate": "1993-07-23",
        "nepDate": "08.04.2050"
    },
    {
        "engDate": "1993-07-24",
        "nepDate": "09.04.2050"
    },
    {
        "engDate": "1993-07-25",
        "nepDate": "10.04.2050"
    },
    {
        "engDate": "1993-07-26",
        "nepDate": "11.04.2050"
    },
    {
        "engDate": "1993-07-27",
        "nepDate": "12.04.2050"
    },
    {
        "engDate": "1993-07-28",
        "nepDate": "13.04.2050"
    },
    {
        "engDate": "1993-07-29",
        "nepDate": "14.04.2050"
    },
    {
        "engDate": "1993-07-30",
        "nepDate": "15.04.2050"
    },
    {
        "engDate": "1993-07-31",
        "nepDate": "16.04.2050"
    },
    {
        "engDate": "1993-08-01",
        "nepDate": "17.04.2050"
    },
    {
        "engDate": "1993-08-02",
        "nepDate": "18.04.2050"
    },
    {
        "engDate": "1993-08-03",
        "nepDate": "19.04.2050"
    },
    {
        "engDate": "1993-08-04",
        "nepDate": "20.04.2050"
    },
    {
        "engDate": "1993-08-05",
        "nepDate": "21.04.2050"
    },
    {
        "engDate": "1993-08-06",
        "nepDate": "22.04.2050"
    },
    {
        "engDate": "1993-08-07",
        "nepDate": "23.04.2050"
    },
    {
        "engDate": "1993-08-08",
        "nepDate": "24.04.2050"
    },
    {
        "engDate": "1993-08-09",
        "nepDate": "25.04.2050"
    },
    {
        "engDate": "1993-08-10",
        "nepDate": "26.04.2050"
    },
    {
        "engDate": "1993-08-11",
        "nepDate": "27.04.2050"
    },
    {
        "engDate": "1993-08-12",
        "nepDate": "28.04.2050"
    },
    {
        "engDate": "1993-08-13",
        "nepDate": "29.04.2050"
    },
    {
        "engDate": "1993-08-14",
        "nepDate": "30.04.2050"
    },
    {
        "engDate": "1993-08-15",
        "nepDate": "31.04.2050"
    },
    {
        "engDate": "1993-08-16",
        "nepDate": "32.04.2050"
    },
    {
        "engDate": "1993-08-17",
        "nepDate": "01.05.2050"
    },
    {
        "engDate": "1993-08-18",
        "nepDate": "02.05.2050"
    },
    {
        "engDate": "1993-08-19",
        "nepDate": "03.05.2050"
    },
    {
        "engDate": "1993-08-20",
        "nepDate": "04.05.2050"
    },
    {
        "engDate": "1993-08-21",
        "nepDate": "05.05.2050"
    },
    {
        "engDate": "1993-08-22",
        "nepDate": "06.05.2050"
    },
    {
        "engDate": "1993-08-23",
        "nepDate": "07.05.2050"
    },
    {
        "engDate": "1993-08-24",
        "nepDate": "08.05.2050"
    },
    {
        "engDate": "1993-08-25",
        "nepDate": "09.05.2050"
    },
    {
        "engDate": "1993-08-26",
        "nepDate": "10.05.2050"
    },
    {
        "engDate": "1993-08-27",
        "nepDate": "11.05.2050"
    },
    {
        "engDate": "1993-08-28",
        "nepDate": "12.05.2050"
    },
    {
        "engDate": "1993-08-29",
        "nepDate": "13.05.2050"
    },
    {
        "engDate": "1993-08-30",
        "nepDate": "14.05.2050"
    },
    {
        "engDate": "1993-08-31",
        "nepDate": "15.05.2050"
    },
    {
        "engDate": "1993-09-01",
        "nepDate": "16.05.2050"
    },
    {
        "engDate": "1993-09-02",
        "nepDate": "17.05.2050"
    },
    {
        "engDate": "1993-09-03",
        "nepDate": "18.05.2050"
    },
    {
        "engDate": "1993-09-04",
        "nepDate": "19.05.2050"
    },
    {
        "engDate": "1993-09-05",
        "nepDate": "20.05.2050"
    },
    {
        "engDate": "1993-09-06",
        "nepDate": "21.05.2050"
    },
    {
        "engDate": "1993-09-07",
        "nepDate": "22.05.2050"
    },
    {
        "engDate": "1993-09-08",
        "nepDate": "23.05.2050"
    },
    {
        "engDate": "1993-09-09",
        "nepDate": "24.05.2050"
    },
    {
        "engDate": "1993-09-10",
        "nepDate": "25.05.2050"
    },
    {
        "engDate": "1993-09-11",
        "nepDate": "26.05.2050"
    },
    {
        "engDate": "1993-09-12",
        "nepDate": "27.05.2050"
    },
    {
        "engDate": "1993-09-13",
        "nepDate": "28.05.2050"
    },
    {
        "engDate": "1993-09-14",
        "nepDate": "29.05.2050"
    },
    {
        "engDate": "1993-09-15",
        "nepDate": "30.05.2050"
    },
    {
        "engDate": "1993-09-16",
        "nepDate": "31.05.2050"
    },
    {
        "engDate": "1993-09-17",
        "nepDate": "01.06.2050"
    },
    {
        "engDate": "1993-09-18",
        "nepDate": "02.06.2050"
    },
    {
        "engDate": "1993-09-19",
        "nepDate": "03.06.2050"
    },
    {
        "engDate": "1993-09-20",
        "nepDate": "04.06.2050"
    },
    {
        "engDate": "1993-09-21",
        "nepDate": "05.06.2050"
    },
    {
        "engDate": "1993-09-22",
        "nepDate": "06.06.2050"
    },
    {
        "engDate": "1993-09-23",
        "nepDate": "07.06.2050"
    },
    {
        "engDate": "1993-09-24",
        "nepDate": "08.06.2050"
    },
    {
        "engDate": "1993-09-25",
        "nepDate": "09.06.2050"
    },
    {
        "engDate": "1993-09-26",
        "nepDate": "10.06.2050"
    },
    {
        "engDate": "1993-09-27",
        "nepDate": "11.06.2050"
    },
    {
        "engDate": "1993-09-28",
        "nepDate": "12.06.2050"
    },
    {
        "engDate": "1993-09-29",
        "nepDate": "13.06.2050"
    },
    {
        "engDate": "1993-09-30",
        "nepDate": "14.06.2050"
    },
    {
        "engDate": "1993-10-01",
        "nepDate": "15.06.2050"
    },
    {
        "engDate": "1993-10-02",
        "nepDate": "16.06.2050"
    },
    {
        "engDate": "1993-10-03",
        "nepDate": "17.06.2050"
    },
    {
        "engDate": "1993-10-04",
        "nepDate": "18.06.2050"
    },
    {
        "engDate": "1993-10-05",
        "nepDate": "19.06.2050"
    },
    {
        "engDate": "1993-10-06",
        "nepDate": "20.06.2050"
    },
    {
        "engDate": "1993-10-07",
        "nepDate": "21.06.2050"
    },
    {
        "engDate": "1993-10-08",
        "nepDate": "22.06.2050"
    },
    {
        "engDate": "1993-10-09",
        "nepDate": "23.06.2050"
    },
    {
        "engDate": "1993-10-10",
        "nepDate": "24.06.2050"
    },
    {
        "engDate": "1993-10-11",
        "nepDate": "25.06.2050"
    },
    {
        "engDate": "1993-10-12",
        "nepDate": "26.06.2050"
    },
    {
        "engDate": "1993-10-13",
        "nepDate": "27.06.2050"
    },
    {
        "engDate": "1993-10-14",
        "nepDate": "28.06.2050"
    },
    {
        "engDate": "1993-10-15",
        "nepDate": "29.06.2050"
    },
    {
        "engDate": "1993-10-16",
        "nepDate": "30.06.2050"
    },
    {
        "engDate": "1993-10-17",
        "nepDate": "01.07.2050"
    },
    {
        "engDate": "1993-10-18",
        "nepDate": "02.07.2050"
    },
    {
        "engDate": "1993-10-19",
        "nepDate": "03.07.2050"
    },
    {
        "engDate": "1993-10-20",
        "nepDate": "04.07.2050"
    },
    {
        "engDate": "1993-10-21",
        "nepDate": "05.07.2050"
    },
    {
        "engDate": "1993-10-22",
        "nepDate": "06.07.2050"
    },
    {
        "engDate": "1993-10-23",
        "nepDate": "07.07.2050"
    },
    {
        "engDate": "1993-10-24",
        "nepDate": "08.07.2050"
    },
    {
        "engDate": "1993-10-25",
        "nepDate": "09.07.2050"
    },
    {
        "engDate": "1993-10-26",
        "nepDate": "10.07.2050"
    },
    {
        "engDate": "1993-10-27",
        "nepDate": "11.07.2050"
    },
    {
        "engDate": "1993-10-28",
        "nepDate": "12.07.2050"
    },
    {
        "engDate": "1993-10-29",
        "nepDate": "13.07.2050"
    },
    {
        "engDate": "1993-10-30",
        "nepDate": "14.07.2050"
    },
    {
        "engDate": "1993-10-31",
        "nepDate": "15.07.2050"
    },
    {
        "engDate": "1993-11-01",
        "nepDate": "16.07.2050"
    },
    {
        "engDate": "1993-11-02",
        "nepDate": "17.07.2050"
    },
    {
        "engDate": "1993-11-03",
        "nepDate": "18.07.2050"
    },
    {
        "engDate": "1993-11-04",
        "nepDate": "19.07.2050"
    },
    {
        "engDate": "1993-11-05",
        "nepDate": "20.07.2050"
    },
    {
        "engDate": "1993-11-06",
        "nepDate": "21.07.2050"
    },
    {
        "engDate": "1993-11-07",
        "nepDate": "22.07.2050"
    },
    {
        "engDate": "1993-11-08",
        "nepDate": "23.07.2050"
    },
    {
        "engDate": "1993-11-09",
        "nepDate": "24.07.2050"
    },
    {
        "engDate": "1993-11-10",
        "nepDate": "25.07.2050"
    },
    {
        "engDate": "1993-11-11",
        "nepDate": "26.07.2050"
    },
    {
        "engDate": "1993-11-12",
        "nepDate": "27.07.2050"
    },
    {
        "engDate": "1993-11-13",
        "nepDate": "28.07.2050"
    },
    {
        "engDate": "1993-11-14",
        "nepDate": "29.07.2050"
    },
    {
        "engDate": "1993-11-15",
        "nepDate": "30.07.2050"
    },
    {
        "engDate": "1993-11-16",
        "nepDate": "01.08.2050"
    },
    {
        "engDate": "1993-11-17",
        "nepDate": "02.08.2050"
    },
    {
        "engDate": "1993-11-18",
        "nepDate": "03.08.2050"
    },
    {
        "engDate": "1993-11-19",
        "nepDate": "04.08.2050"
    },
    {
        "engDate": "1993-11-20",
        "nepDate": "05.08.2050"
    },
    {
        "engDate": "1993-11-21",
        "nepDate": "06.08.2050"
    },
    {
        "engDate": "1993-11-22",
        "nepDate": "07.08.2050"
    },
    {
        "engDate": "1993-11-23",
        "nepDate": "08.08.2050"
    },
    {
        "engDate": "1993-11-24",
        "nepDate": "09.08.2050"
    },
    {
        "engDate": "1993-11-25",
        "nepDate": "10.08.2050"
    },
    {
        "engDate": "1993-11-26",
        "nepDate": "11.08.2050"
    },
    {
        "engDate": "1993-11-27",
        "nepDate": "12.08.2050"
    },
    {
        "engDate": "1993-11-28",
        "nepDate": "13.08.2050"
    },
    {
        "engDate": "1993-11-29",
        "nepDate": "14.08.2050"
    },
    {
        "engDate": "1993-11-30",
        "nepDate": "15.08.2050"
    },
    {
        "engDate": "1993-12-01",
        "nepDate": "16.08.2050"
    },
    {
        "engDate": "1993-12-02",
        "nepDate": "17.08.2050"
    },
    {
        "engDate": "1993-12-03",
        "nepDate": "18.08.2050"
    },
    {
        "engDate": "1993-12-04",
        "nepDate": "19.08.2050"
    },
    {
        "engDate": "1993-12-05",
        "nepDate": "20.08.2050"
    },
    {
        "engDate": "1993-12-06",
        "nepDate": "21.08.2050"
    },
    {
        "engDate": "1993-12-07",
        "nepDate": "22.08.2050"
    },
    {
        "engDate": "1993-12-08",
        "nepDate": "23.08.2050"
    },
    {
        "engDate": "1993-12-09",
        "nepDate": "24.08.2050"
    },
    {
        "engDate": "1993-12-10",
        "nepDate": "25.08.2050"
    },
    {
        "engDate": "1993-12-11",
        "nepDate": "26.08.2050"
    },
    {
        "engDate": "1993-12-12",
        "nepDate": "27.08.2050"
    },
    {
        "engDate": "1993-12-13",
        "nepDate": "28.08.2050"
    },
    {
        "engDate": "1993-12-14",
        "nepDate": "29.08.2050"
    },
    {
        "engDate": "1993-12-15",
        "nepDate": "30.08.2050"
    },
    {
        "engDate": "1993-12-16",
        "nepDate": "01.09.2050"
    },
    {
        "engDate": "1993-12-17",
        "nepDate": "02.09.2050"
    },
    {
        "engDate": "1993-12-18",
        "nepDate": "03.09.2050"
    },
    {
        "engDate": "1993-12-19",
        "nepDate": "04.09.2050"
    },
    {
        "engDate": "1993-12-20",
        "nepDate": "05.09.2050"
    },
    {
        "engDate": "1993-12-21",
        "nepDate": "06.09.2050"
    },
    {
        "engDate": "1993-12-22",
        "nepDate": "07.09.2050"
    },
    {
        "engDate": "1993-12-23",
        "nepDate": "08.09.2050"
    },
    {
        "engDate": "1993-12-24",
        "nepDate": "09.09.2050"
    },
    {
        "engDate": "1993-12-25",
        "nepDate": "10.09.2050"
    },
    {
        "engDate": "1993-12-26",
        "nepDate": "11.09.2050"
    },
    {
        "engDate": "1993-12-27",
        "nepDate": "12.09.2050"
    },
    {
        "engDate": "1993-12-28",
        "nepDate": "13.09.2050"
    },
    {
        "engDate": "1993-12-29",
        "nepDate": "14.09.2050"
    },
    {
        "engDate": "1993-12-30",
        "nepDate": "15.09.2050"
    },
    {
        "engDate": "1993-12-31",
        "nepDate": "16.09.2050"
    },
    {
        "engDate": "1994-01-01",
        "nepDate": "17.09.2050"
    },
    {
        "engDate": "1994-01-02",
        "nepDate": "18.09.2050"
    },
    {
        "engDate": "1994-01-03",
        "nepDate": "19.09.2050"
    },
    {
        "engDate": "1994-01-04",
        "nepDate": "20.09.2050"
    },
    {
        "engDate": "1994-01-05",
        "nepDate": "21.09.2050"
    },
    {
        "engDate": "1994-01-06",
        "nepDate": "22.09.2050"
    },
    {
        "engDate": "1994-01-07",
        "nepDate": "23.09.2050"
    },
    {
        "engDate": "1994-01-08",
        "nepDate": "24.09.2050"
    },
    {
        "engDate": "1994-01-09",
        "nepDate": "25.09.2050"
    },
    {
        "engDate": "1994-01-10",
        "nepDate": "26.09.2050"
    },
    {
        "engDate": "1994-01-11",
        "nepDate": "27.09.2050"
    },
    {
        "engDate": "1994-01-12",
        "nepDate": "28.09.2050"
    },
    {
        "engDate": "1994-01-13",
        "nepDate": "29.09.2050"
    },
    {
        "engDate": "1994-01-14",
        "nepDate": "01.10.2050"
    },
    {
        "engDate": "1994-01-15",
        "nepDate": "02.10.2050"
    },
    {
        "engDate": "1994-01-16",
        "nepDate": "03.10.2050"
    },
    {
        "engDate": "1994-01-17",
        "nepDate": "04.10.2050"
    },
    {
        "engDate": "1994-01-18",
        "nepDate": "05.10.2050"
    },
    {
        "engDate": "1994-01-19",
        "nepDate": "06.10.2050"
    },
    {
        "engDate": "1994-01-20",
        "nepDate": "07.10.2050"
    },
    {
        "engDate": "1994-01-21",
        "nepDate": "08.10.2050"
    },
    {
        "engDate": "1994-01-22",
        "nepDate": "09.10.2050"
    },
    {
        "engDate": "1994-01-23",
        "nepDate": "10.10.2050"
    },
    {
        "engDate": "1994-01-24",
        "nepDate": "11.10.2050"
    },
    {
        "engDate": "1994-01-25",
        "nepDate": "12.10.2050"
    },
    {
        "engDate": "1994-01-26",
        "nepDate": "13.10.2050"
    },
    {
        "engDate": "1994-01-27",
        "nepDate": "14.10.2050"
    },
    {
        "engDate": "1994-01-28",
        "nepDate": "15.10.2050"
    },
    {
        "engDate": "1994-01-29",
        "nepDate": "16.10.2050"
    },
    {
        "engDate": "1994-01-30",
        "nepDate": "17.10.2050"
    },
    {
        "engDate": "1994-01-31",
        "nepDate": "18.10.2050"
    },
    {
        "engDate": "1994-02-01",
        "nepDate": "19.10.2050"
    },
    {
        "engDate": "1994-02-02",
        "nepDate": "20.10.2050"
    },
    {
        "engDate": "1994-02-03",
        "nepDate": "21.10.2050"
    },
    {
        "engDate": "1994-02-04",
        "nepDate": "22.10.2050"
    },
    {
        "engDate": "1994-02-05",
        "nepDate": "23.10.2050"
    },
    {
        "engDate": "1994-02-06",
        "nepDate": "24.10.2050"
    },
    {
        "engDate": "1994-02-07",
        "nepDate": "25.10.2050"
    },
    {
        "engDate": "1994-02-08",
        "nepDate": "26.10.2050"
    },
    {
        "engDate": "1994-02-09",
        "nepDate": "27.10.2050"
    },
    {
        "engDate": "1994-02-10",
        "nepDate": "28.10.2050"
    },
    {
        "engDate": "1994-02-11",
        "nepDate": "29.10.2050"
    },
    {
        "engDate": "1994-02-12",
        "nepDate": "30.10.2050"
    },
    {
        "engDate": "1994-02-13",
        "nepDate": "01.11.2050"
    },
    {
        "engDate": "1994-02-14",
        "nepDate": "02.11.2050"
    },
    {
        "engDate": "1994-02-15",
        "nepDate": "03.11.2050"
    },
    {
        "engDate": "1994-02-16",
        "nepDate": "04.11.2050"
    },
    {
        "engDate": "1994-02-17",
        "nepDate": "05.11.2050"
    },
    {
        "engDate": "1994-02-18",
        "nepDate": "06.11.2050"
    },
    {
        "engDate": "1994-02-19",
        "nepDate": "07.11.2050"
    },
    {
        "engDate": "1994-02-20",
        "nepDate": "08.11.2050"
    },
    {
        "engDate": "1994-02-21",
        "nepDate": "09.11.2050"
    },
    {
        "engDate": "1994-02-22",
        "nepDate": "10.11.2050"
    },
    {
        "engDate": "1994-02-23",
        "nepDate": "11.11.2050"
    },
    {
        "engDate": "1994-02-24",
        "nepDate": "12.11.2050"
    },
    {
        "engDate": "1994-02-25",
        "nepDate": "13.11.2050"
    },
    {
        "engDate": "1994-02-26",
        "nepDate": "14.11.2050"
    },
    {
        "engDate": "1994-02-27",
        "nepDate": "15.11.2050"
    },
    {
        "engDate": "1994-02-28",
        "nepDate": "16.11.2050"
    },
    {
        "engDate": "1994-03-01",
        "nepDate": "17.11.2050"
    },
    {
        "engDate": "1994-03-02",
        "nepDate": "18.11.2050"
    },
    {
        "engDate": "1994-03-03",
        "nepDate": "19.11.2050"
    },
    {
        "engDate": "1994-03-04",
        "nepDate": "20.11.2050"
    },
    {
        "engDate": "1994-03-05",
        "nepDate": "21.11.2050"
    },
    {
        "engDate": "1994-03-06",
        "nepDate": "22.11.2050"
    },
    {
        "engDate": "1994-03-07",
        "nepDate": "23.11.2050"
    },
    {
        "engDate": "1994-03-08",
        "nepDate": "24.11.2050"
    },
    {
        "engDate": "1994-03-09",
        "nepDate": "25.11.2050"
    },
    {
        "engDate": "1994-03-10",
        "nepDate": "26.11.2050"
    },
    {
        "engDate": "1994-03-11",
        "nepDate": "27.11.2050"
    },
    {
        "engDate": "1994-03-12",
        "nepDate": "28.11.2050"
    },
    {
        "engDate": "1994-03-13",
        "nepDate": "29.11.2050"
    },
    {
        "engDate": "1994-03-14",
        "nepDate": "01.12.2050"
    },
    {
        "engDate": "1994-03-15",
        "nepDate": "02.12.2050"
    },
    {
        "engDate": "1994-03-16",
        "nepDate": "03.12.2050"
    },
    {
        "engDate": "1994-03-17",
        "nepDate": "04.12.2050"
    },
    {
        "engDate": "1994-03-18",
        "nepDate": "05.12.2050"
    },
    {
        "engDate": "1994-03-19",
        "nepDate": "06.12.2050"
    },
    {
        "engDate": "1994-03-20",
        "nepDate": "07.12.2050"
    },
    {
        "engDate": "1994-03-21",
        "nepDate": "08.12.2050"
    },
    {
        "engDate": "1994-03-22",
        "nepDate": "09.12.2050"
    },
    {
        "engDate": "1994-03-23",
        "nepDate": "10.12.2050"
    },
    {
        "engDate": "1994-03-24",
        "nepDate": "11.12.2050"
    },
    {
        "engDate": "1994-03-25",
        "nepDate": "12.12.2050"
    },
    {
        "engDate": "1994-03-26",
        "nepDate": "13.12.2050"
    },
    {
        "engDate": "1994-03-27",
        "nepDate": "14.12.2050"
    },
    {
        "engDate": "1994-03-28",
        "nepDate": "15.12.2050"
    },
    {
        "engDate": "1994-03-29",
        "nepDate": "16.12.2050"
    },
    {
        "engDate": "1994-03-30",
        "nepDate": "17.12.2050"
    },
    {
        "engDate": "1994-03-31",
        "nepDate": "18.12.2050"
    },
    {
        "engDate": "1994-04-01",
        "nepDate": "19.12.2050"
    },
    {
        "engDate": "1994-04-02",
        "nepDate": "20.12.2050"
    },
    {
        "engDate": "1994-04-03",
        "nepDate": "21.12.2050"
    },
    {
        "engDate": "1994-04-04",
        "nepDate": "22.12.2050"
    },
    {
        "engDate": "1994-04-05",
        "nepDate": "23.12.2050"
    },
    {
        "engDate": "1994-04-06",
        "nepDate": "24.12.2050"
    },
    {
        "engDate": "1994-04-07",
        "nepDate": "25.12.2050"
    },
    {
        "engDate": "1994-04-08",
        "nepDate": "26.12.2050"
    },
    {
        "engDate": "1994-04-09",
        "nepDate": "27.12.2050"
    },
    {
        "engDate": "1994-04-10",
        "nepDate": "28.12.2050"
    },
    {
        "engDate": "1994-04-11",
        "nepDate": "29.12.2050"
    },
    {
        "engDate": "1994-04-12",
        "nepDate": "30.12.2050"
    },
    {
        "engDate": "1994-04-13",
        "nepDate": "31.12.2050"
    },
    {
        "engDate": "1994-04-14",
        "nepDate": "01.01.2051"
    },
    {
        "engDate": "1994-04-15",
        "nepDate": "02.01.2051"
    },
    {
        "engDate": "1994-04-16",
        "nepDate": "03.01.2051"
    },
    {
        "engDate": "1994-04-17",
        "nepDate": "04.01.2051"
    },
    {
        "engDate": "1994-04-18",
        "nepDate": "05.01.2051"
    },
    {
        "engDate": "1994-04-19",
        "nepDate": "06.01.2051"
    },
    {
        "engDate": "1994-04-20",
        "nepDate": "07.01.2051"
    },
    {
        "engDate": "1994-04-21",
        "nepDate": "08.01.2051"
    },
    {
        "engDate": "1994-04-22",
        "nepDate": "09.01.2051"
    },
    {
        "engDate": "1994-04-23",
        "nepDate": "10.01.2051"
    },
    {
        "engDate": "1994-04-24",
        "nepDate": "11.01.2051"
    },
    {
        "engDate": "1994-04-25",
        "nepDate": "12.01.2051"
    },
    {
        "engDate": "1994-04-26",
        "nepDate": "13.01.2051"
    },
    {
        "engDate": "1994-04-27",
        "nepDate": "14.01.2051"
    },
    {
        "engDate": "1994-04-28",
        "nepDate": "15.01.2051"
    },
    {
        "engDate": "1994-04-29",
        "nepDate": "16.01.2051"
    },
    {
        "engDate": "1994-04-30",
        "nepDate": "17.01.2051"
    },
    {
        "engDate": "1994-05-01",
        "nepDate": "18.01.2051"
    },
    {
        "engDate": "1994-05-02",
        "nepDate": "19.01.2051"
    },
    {
        "engDate": "1994-05-03",
        "nepDate": "20.01.2051"
    },
    {
        "engDate": "1994-05-04",
        "nepDate": "21.01.2051"
    },
    {
        "engDate": "1994-05-05",
        "nepDate": "22.01.2051"
    },
    {
        "engDate": "1994-05-06",
        "nepDate": "23.01.2051"
    },
    {
        "engDate": "1994-05-07",
        "nepDate": "24.01.2051"
    },
    {
        "engDate": "1994-05-08",
        "nepDate": "25.01.2051"
    },
    {
        "engDate": "1994-05-09",
        "nepDate": "26.01.2051"
    },
    {
        "engDate": "1994-05-10",
        "nepDate": "27.01.2051"
    },
    {
        "engDate": "1994-05-11",
        "nepDate": "28.01.2051"
    },
    {
        "engDate": "1994-05-12",
        "nepDate": "29.01.2051"
    },
    {
        "engDate": "1994-05-13",
        "nepDate": "30.01.2051"
    },
    {
        "engDate": "1994-05-14",
        "nepDate": "31.01.2051"
    },
    {
        "engDate": "1994-05-15",
        "nepDate": "01.02.2051"
    },
    {
        "engDate": "1994-05-16",
        "nepDate": "02.02.2051"
    },
    {
        "engDate": "1994-05-17",
        "nepDate": "03.02.2051"
    },
    {
        "engDate": "1994-05-18",
        "nepDate": "04.02.2051"
    },
    {
        "engDate": "1994-05-19",
        "nepDate": "05.02.2051"
    },
    {
        "engDate": "1994-05-20",
        "nepDate": "06.02.2051"
    },
    {
        "engDate": "1994-05-21",
        "nepDate": "07.02.2051"
    },
    {
        "engDate": "1994-05-22",
        "nepDate": "08.02.2051"
    },
    {
        "engDate": "1994-05-23",
        "nepDate": "09.02.2051"
    },
    {
        "engDate": "1994-05-24",
        "nepDate": "10.02.2051"
    },
    {
        "engDate": "1994-05-25",
        "nepDate": "11.02.2051"
    },
    {
        "engDate": "1994-05-26",
        "nepDate": "12.02.2051"
    },
    {
        "engDate": "1994-05-27",
        "nepDate": "13.02.2051"
    },
    {
        "engDate": "1994-05-28",
        "nepDate": "14.02.2051"
    },
    {
        "engDate": "1994-05-29",
        "nepDate": "15.02.2051"
    },
    {
        "engDate": "1994-05-30",
        "nepDate": "16.02.2051"
    },
    {
        "engDate": "1994-05-31",
        "nepDate": "17.02.2051"
    },
    {
        "engDate": "1994-06-01",
        "nepDate": "18.02.2051"
    },
    {
        "engDate": "1994-06-02",
        "nepDate": "19.02.2051"
    },
    {
        "engDate": "1994-06-03",
        "nepDate": "20.02.2051"
    },
    {
        "engDate": "1994-06-04",
        "nepDate": "21.02.2051"
    },
    {
        "engDate": "1994-06-05",
        "nepDate": "22.02.2051"
    },
    {
        "engDate": "1994-06-06",
        "nepDate": "23.02.2051"
    },
    {
        "engDate": "1994-06-07",
        "nepDate": "24.02.2051"
    },
    {
        "engDate": "1994-06-08",
        "nepDate": "25.02.2051"
    },
    {
        "engDate": "1994-06-09",
        "nepDate": "26.02.2051"
    },
    {
        "engDate": "1994-06-10",
        "nepDate": "27.02.2051"
    },
    {
        "engDate": "1994-06-11",
        "nepDate": "28.02.2051"
    },
    {
        "engDate": "1994-06-12",
        "nepDate": "29.02.2051"
    },
    {
        "engDate": "1994-06-13",
        "nepDate": "30.02.2051"
    },
    {
        "engDate": "1994-06-14",
        "nepDate": "31.02.2051"
    },
    {
        "engDate": "1994-06-15",
        "nepDate": "01.03.2051"
    },
    {
        "engDate": "1994-06-16",
        "nepDate": "02.03.2051"
    },
    {
        "engDate": "1994-06-17",
        "nepDate": "03.03.2051"
    },
    {
        "engDate": "1994-06-18",
        "nepDate": "04.03.2051"
    },
    {
        "engDate": "1994-06-19",
        "nepDate": "05.03.2051"
    },
    {
        "engDate": "1994-06-20",
        "nepDate": "06.03.2051"
    },
    {
        "engDate": "1994-06-21",
        "nepDate": "07.03.2051"
    },
    {
        "engDate": "1994-06-22",
        "nepDate": "08.03.2051"
    },
    {
        "engDate": "1994-06-23",
        "nepDate": "09.03.2051"
    },
    {
        "engDate": "1994-06-24",
        "nepDate": "10.03.2051"
    },
    {
        "engDate": "1994-06-25",
        "nepDate": "11.03.2051"
    },
    {
        "engDate": "1994-06-26",
        "nepDate": "12.03.2051"
    },
    {
        "engDate": "1994-06-27",
        "nepDate": "13.03.2051"
    },
    {
        "engDate": "1994-06-28",
        "nepDate": "14.03.2051"
    },
    {
        "engDate": "1994-06-29",
        "nepDate": "15.03.2051"
    },
    {
        "engDate": "1994-06-30",
        "nepDate": "16.03.2051"
    },
    {
        "engDate": "1994-07-01",
        "nepDate": "17.03.2051"
    },
    {
        "engDate": "1994-07-02",
        "nepDate": "18.03.2051"
    },
    {
        "engDate": "1994-07-03",
        "nepDate": "19.03.2051"
    },
    {
        "engDate": "1994-07-04",
        "nepDate": "20.03.2051"
    },
    {
        "engDate": "1994-07-05",
        "nepDate": "21.03.2051"
    },
    {
        "engDate": "1994-07-06",
        "nepDate": "22.03.2051"
    },
    {
        "engDate": "1994-07-07",
        "nepDate": "23.03.2051"
    },
    {
        "engDate": "1994-07-08",
        "nepDate": "24.03.2051"
    },
    {
        "engDate": "1994-07-09",
        "nepDate": "25.03.2051"
    },
    {
        "engDate": "1994-07-10",
        "nepDate": "26.03.2051"
    },
    {
        "engDate": "1994-07-11",
        "nepDate": "27.03.2051"
    },
    {
        "engDate": "1994-07-12",
        "nepDate": "28.03.2051"
    },
    {
        "engDate": "1994-07-13",
        "nepDate": "29.03.2051"
    },
    {
        "engDate": "1994-07-14",
        "nepDate": "30.03.2051"
    },
    {
        "engDate": "1994-07-15",
        "nepDate": "31.03.2051"
    },
    {
        "engDate": "1994-07-16",
        "nepDate": "01.04.2051"
    },
    {
        "engDate": "1994-07-17",
        "nepDate": "02.04.2051"
    },
    {
        "engDate": "1994-07-18",
        "nepDate": "03.04.2051"
    },
    {
        "engDate": "1994-07-19",
        "nepDate": "04.04.2051"
    },
    {
        "engDate": "1994-07-20",
        "nepDate": "05.04.2051"
    },
    {
        "engDate": "1994-07-21",
        "nepDate": "06.04.2051"
    },
    {
        "engDate": "1994-07-22",
        "nepDate": "07.04.2051"
    },
    {
        "engDate": "1994-07-23",
        "nepDate": "08.04.2051"
    },
    {
        "engDate": "1994-07-24",
        "nepDate": "09.04.2051"
    },
    {
        "engDate": "1994-07-25",
        "nepDate": "10.04.2051"
    },
    {
        "engDate": "1994-07-26",
        "nepDate": "11.04.2051"
    },
    {
        "engDate": "1994-07-27",
        "nepDate": "12.04.2051"
    },
    {
        "engDate": "1994-07-28",
        "nepDate": "13.04.2051"
    },
    {
        "engDate": "1994-07-29",
        "nepDate": "14.04.2051"
    },
    {
        "engDate": "1994-07-30",
        "nepDate": "15.04.2051"
    },
    {
        "engDate": "1994-07-31",
        "nepDate": "16.04.2051"
    },
    {
        "engDate": "1994-08-01",
        "nepDate": "17.04.2051"
    },
    {
        "engDate": "1994-08-02",
        "nepDate": "18.04.2051"
    },
    {
        "engDate": "1994-08-03",
        "nepDate": "19.04.2051"
    },
    {
        "engDate": "1994-08-04",
        "nepDate": "20.04.2051"
    },
    {
        "engDate": "1994-08-05",
        "nepDate": "21.04.2051"
    },
    {
        "engDate": "1994-08-06",
        "nepDate": "22.04.2051"
    },
    {
        "engDate": "1994-08-07",
        "nepDate": "23.04.2051"
    },
    {
        "engDate": "1994-08-08",
        "nepDate": "24.04.2051"
    },
    {
        "engDate": "1994-08-09",
        "nepDate": "25.04.2051"
    },
    {
        "engDate": "1994-08-10",
        "nepDate": "26.04.2051"
    },
    {
        "engDate": "1994-08-11",
        "nepDate": "27.04.2051"
    },
    {
        "engDate": "1994-08-12",
        "nepDate": "28.04.2051"
    },
    {
        "engDate": "1994-08-13",
        "nepDate": "29.04.2051"
    },
    {
        "engDate": "1994-08-14",
        "nepDate": "30.04.2051"
    },
    {
        "engDate": "1994-08-15",
        "nepDate": "31.04.2051"
    },
    {
        "engDate": "1994-08-16",
        "nepDate": "32.04.2051"
    },
    {
        "engDate": "1994-08-17",
        "nepDate": "01.05.2051"
    },
    {
        "engDate": "1994-08-18",
        "nepDate": "02.05.2051"
    },
    {
        "engDate": "1994-08-19",
        "nepDate": "03.05.2051"
    },
    {
        "engDate": "1994-08-20",
        "nepDate": "04.05.2051"
    },
    {
        "engDate": "1994-08-21",
        "nepDate": "05.05.2051"
    },
    {
        "engDate": "1994-08-22",
        "nepDate": "06.05.2051"
    },
    {
        "engDate": "1994-08-23",
        "nepDate": "07.05.2051"
    },
    {
        "engDate": "1994-08-24",
        "nepDate": "08.05.2051"
    },
    {
        "engDate": "1994-08-25",
        "nepDate": "09.05.2051"
    },
    {
        "engDate": "1994-08-26",
        "nepDate": "10.05.2051"
    },
    {
        "engDate": "1994-08-27",
        "nepDate": "11.05.2051"
    },
    {
        "engDate": "1994-08-28",
        "nepDate": "12.05.2051"
    },
    {
        "engDate": "1994-08-29",
        "nepDate": "13.05.2051"
    },
    {
        "engDate": "1994-08-30",
        "nepDate": "14.05.2051"
    },
    {
        "engDate": "1994-08-31",
        "nepDate": "15.05.2051"
    },
    {
        "engDate": "1994-09-01",
        "nepDate": "16.05.2051"
    },
    {
        "engDate": "1994-09-02",
        "nepDate": "17.05.2051"
    },
    {
        "engDate": "1994-09-03",
        "nepDate": "18.05.2051"
    },
    {
        "engDate": "1994-09-04",
        "nepDate": "19.05.2051"
    },
    {
        "engDate": "1994-09-05",
        "nepDate": "20.05.2051"
    },
    {
        "engDate": "1994-09-06",
        "nepDate": "21.05.2051"
    },
    {
        "engDate": "1994-09-07",
        "nepDate": "22.05.2051"
    },
    {
        "engDate": "1994-09-08",
        "nepDate": "23.05.2051"
    },
    {
        "engDate": "1994-09-09",
        "nepDate": "24.05.2051"
    },
    {
        "engDate": "1994-09-10",
        "nepDate": "25.05.2051"
    },
    {
        "engDate": "1994-09-11",
        "nepDate": "26.05.2051"
    },
    {
        "engDate": "1994-09-12",
        "nepDate": "27.05.2051"
    },
    {
        "engDate": "1994-09-13",
        "nepDate": "28.05.2051"
    },
    {
        "engDate": "1994-09-14",
        "nepDate": "29.05.2051"
    },
    {
        "engDate": "1994-09-15",
        "nepDate": "30.05.2051"
    },
    {
        "engDate": "1994-09-16",
        "nepDate": "31.05.2051"
    },
    {
        "engDate": "1994-09-17",
        "nepDate": "01.06.2051"
    },
    {
        "engDate": "1994-09-18",
        "nepDate": "02.06.2051"
    },
    {
        "engDate": "1994-09-19",
        "nepDate": "03.06.2051"
    },
    {
        "engDate": "1994-09-20",
        "nepDate": "04.06.2051"
    },
    {
        "engDate": "1994-09-21",
        "nepDate": "05.06.2051"
    },
    {
        "engDate": "1994-09-22",
        "nepDate": "06.06.2051"
    },
    {
        "engDate": "1994-09-23",
        "nepDate": "07.06.2051"
    },
    {
        "engDate": "1994-09-24",
        "nepDate": "08.06.2051"
    },
    {
        "engDate": "1994-09-25",
        "nepDate": "09.06.2051"
    },
    {
        "engDate": "1994-09-26",
        "nepDate": "10.06.2051"
    },
    {
        "engDate": "1994-09-27",
        "nepDate": "11.06.2051"
    },
    {
        "engDate": "1994-09-28",
        "nepDate": "12.06.2051"
    },
    {
        "engDate": "1994-09-29",
        "nepDate": "13.06.2051"
    },
    {
        "engDate": "1994-09-30",
        "nepDate": "14.06.2051"
    },
    {
        "engDate": "1994-10-01",
        "nepDate": "15.06.2051"
    },
    {
        "engDate": "1994-10-02",
        "nepDate": "16.06.2051"
    },
    {
        "engDate": "1994-10-03",
        "nepDate": "17.06.2051"
    },
    {
        "engDate": "1994-10-04",
        "nepDate": "18.06.2051"
    },
    {
        "engDate": "1994-10-05",
        "nepDate": "19.06.2051"
    },
    {
        "engDate": "1994-10-06",
        "nepDate": "20.06.2051"
    },
    {
        "engDate": "1994-10-07",
        "nepDate": "21.06.2051"
    },
    {
        "engDate": "1994-10-08",
        "nepDate": "22.06.2051"
    },
    {
        "engDate": "1994-10-09",
        "nepDate": "23.06.2051"
    },
    {
        "engDate": "1994-10-10",
        "nepDate": "24.06.2051"
    },
    {
        "engDate": "1994-10-11",
        "nepDate": "25.06.2051"
    },
    {
        "engDate": "1994-10-12",
        "nepDate": "26.06.2051"
    },
    {
        "engDate": "1994-10-13",
        "nepDate": "27.06.2051"
    },
    {
        "engDate": "1994-10-14",
        "nepDate": "28.06.2051"
    },
    {
        "engDate": "1994-10-15",
        "nepDate": "29.06.2051"
    },
    {
        "engDate": "1994-10-16",
        "nepDate": "30.06.2051"
    },
    {
        "engDate": "1994-10-17",
        "nepDate": "31.06.2051"
    },
    {
        "engDate": "1994-10-18",
        "nepDate": "01.07.2051"
    },
    {
        "engDate": "1994-10-19",
        "nepDate": "02.07.2051"
    },
    {
        "engDate": "1994-10-20",
        "nepDate": "03.07.2051"
    },
    {
        "engDate": "1994-10-21",
        "nepDate": "04.07.2051"
    },
    {
        "engDate": "1994-10-22",
        "nepDate": "05.07.2051"
    },
    {
        "engDate": "1994-10-23",
        "nepDate": "06.07.2051"
    },
    {
        "engDate": "1994-10-24",
        "nepDate": "07.07.2051"
    },
    {
        "engDate": "1994-10-25",
        "nepDate": "08.07.2051"
    },
    {
        "engDate": "1994-10-26",
        "nepDate": "09.07.2051"
    },
    {
        "engDate": "1994-10-27",
        "nepDate": "10.07.2051"
    },
    {
        "engDate": "1994-10-28",
        "nepDate": "11.07.2051"
    },
    {
        "engDate": "1994-10-29",
        "nepDate": "12.07.2051"
    },
    {
        "engDate": "1994-10-30",
        "nepDate": "13.07.2051"
    },
    {
        "engDate": "1994-10-31",
        "nepDate": "14.07.2051"
    },
    {
        "engDate": "1994-11-01",
        "nepDate": "15.07.2051"
    },
    {
        "engDate": "1994-11-02",
        "nepDate": "16.07.2051"
    },
    {
        "engDate": "1994-11-03",
        "nepDate": "17.07.2051"
    },
    {
        "engDate": "1994-11-04",
        "nepDate": "18.07.2051"
    },
    {
        "engDate": "1994-11-05",
        "nepDate": "19.07.2051"
    },
    {
        "engDate": "1994-11-06",
        "nepDate": "20.07.2051"
    },
    {
        "engDate": "1994-11-07",
        "nepDate": "21.07.2051"
    },
    {
        "engDate": "1994-11-08",
        "nepDate": "22.07.2051"
    },
    {
        "engDate": "1994-11-09",
        "nepDate": "23.07.2051"
    },
    {
        "engDate": "1994-11-10",
        "nepDate": "24.07.2051"
    },
    {
        "engDate": "1994-11-11",
        "nepDate": "25.07.2051"
    },
    {
        "engDate": "1994-11-12",
        "nepDate": "26.07.2051"
    },
    {
        "engDate": "1994-11-13",
        "nepDate": "27.07.2051"
    },
    {
        "engDate": "1994-11-14",
        "nepDate": "28.07.2051"
    },
    {
        "engDate": "1994-11-15",
        "nepDate": "29.07.2051"
    },
    {
        "engDate": "1994-11-16",
        "nepDate": "30.07.2051"
    },
    {
        "engDate": "1994-11-17",
        "nepDate": "01.08.2051"
    },
    {
        "engDate": "1994-11-18",
        "nepDate": "02.08.2051"
    },
    {
        "engDate": "1994-11-19",
        "nepDate": "03.08.2051"
    },
    {
        "engDate": "1994-11-20",
        "nepDate": "04.08.2051"
    },
    {
        "engDate": "1994-11-21",
        "nepDate": "05.08.2051"
    },
    {
        "engDate": "1994-11-22",
        "nepDate": "06.08.2051"
    },
    {
        "engDate": "1994-11-23",
        "nepDate": "07.08.2051"
    },
    {
        "engDate": "1994-11-24",
        "nepDate": "08.08.2051"
    },
    {
        "engDate": "1994-11-25",
        "nepDate": "09.08.2051"
    },
    {
        "engDate": "1994-11-26",
        "nepDate": "10.08.2051"
    },
    {
        "engDate": "1994-11-27",
        "nepDate": "11.08.2051"
    },
    {
        "engDate": "1994-11-28",
        "nepDate": "12.08.2051"
    },
    {
        "engDate": "1994-11-29",
        "nepDate": "13.08.2051"
    },
    {
        "engDate": "1994-11-30",
        "nepDate": "14.08.2051"
    },
    {
        "engDate": "1994-12-01",
        "nepDate": "15.08.2051"
    },
    {
        "engDate": "1994-12-02",
        "nepDate": "16.08.2051"
    },
    {
        "engDate": "1994-12-03",
        "nepDate": "17.08.2051"
    },
    {
        "engDate": "1994-12-04",
        "nepDate": "18.08.2051"
    },
    {
        "engDate": "1994-12-05",
        "nepDate": "19.08.2051"
    },
    {
        "engDate": "1994-12-06",
        "nepDate": "20.08.2051"
    },
    {
        "engDate": "1994-12-07",
        "nepDate": "21.08.2051"
    },
    {
        "engDate": "1994-12-08",
        "nepDate": "22.08.2051"
    },
    {
        "engDate": "1994-12-09",
        "nepDate": "23.08.2051"
    },
    {
        "engDate": "1994-12-10",
        "nepDate": "24.08.2051"
    },
    {
        "engDate": "1994-12-11",
        "nepDate": "25.08.2051"
    },
    {
        "engDate": "1994-12-12",
        "nepDate": "26.08.2051"
    },
    {
        "engDate": "1994-12-13",
        "nepDate": "27.08.2051"
    },
    {
        "engDate": "1994-12-14",
        "nepDate": "28.08.2051"
    },
    {
        "engDate": "1994-12-15",
        "nepDate": "29.08.2051"
    },
    {
        "engDate": "1994-12-16",
        "nepDate": "01.09.2051"
    },
    {
        "engDate": "1994-12-17",
        "nepDate": "02.09.2051"
    },
    {
        "engDate": "1994-12-18",
        "nepDate": "03.09.2051"
    },
    {
        "engDate": "1994-12-19",
        "nepDate": "04.09.2051"
    },
    {
        "engDate": "1994-12-20",
        "nepDate": "05.09.2051"
    },
    {
        "engDate": "1994-12-21",
        "nepDate": "06.09.2051"
    },
    {
        "engDate": "1994-12-22",
        "nepDate": "07.09.2051"
    },
    {
        "engDate": "1994-12-23",
        "nepDate": "08.09.2051"
    },
    {
        "engDate": "1994-12-24",
        "nepDate": "09.09.2051"
    },
    {
        "engDate": "1994-12-25",
        "nepDate": "10.09.2051"
    },
    {
        "engDate": "1994-12-26",
        "nepDate": "11.09.2051"
    },
    {
        "engDate": "1994-12-27",
        "nepDate": "12.09.2051"
    },
    {
        "engDate": "1994-12-28",
        "nepDate": "13.09.2051"
    },
    {
        "engDate": "1994-12-29",
        "nepDate": "14.09.2051"
    },
    {
        "engDate": "1994-12-30",
        "nepDate": "15.09.2051"
    },
    {
        "engDate": "1994-12-31",
        "nepDate": "16.09.2051"
    },
    {
        "engDate": "1995-01-01",
        "nepDate": "17.09.2051"
    },
    {
        "engDate": "1995-01-02",
        "nepDate": "18.09.2051"
    },
    {
        "engDate": "1995-01-03",
        "nepDate": "19.09.2051"
    },
    {
        "engDate": "1995-01-04",
        "nepDate": "20.09.2051"
    },
    {
        "engDate": "1995-01-05",
        "nepDate": "21.09.2051"
    },
    {
        "engDate": "1995-01-06",
        "nepDate": "22.09.2051"
    },
    {
        "engDate": "1995-01-07",
        "nepDate": "23.09.2051"
    },
    {
        "engDate": "1995-01-08",
        "nepDate": "24.09.2051"
    },
    {
        "engDate": "1995-01-09",
        "nepDate": "25.09.2051"
    },
    {
        "engDate": "1995-01-10",
        "nepDate": "26.09.2051"
    },
    {
        "engDate": "1995-01-11",
        "nepDate": "27.09.2051"
    },
    {
        "engDate": "1995-01-12",
        "nepDate": "28.09.2051"
    },
    {
        "engDate": "1995-01-13",
        "nepDate": "29.09.2051"
    },
    {
        "engDate": "1995-01-14",
        "nepDate": "30.09.2051"
    },
    {
        "engDate": "1995-01-15",
        "nepDate": "01.10.2051"
    },
    {
        "engDate": "1995-01-16",
        "nepDate": "02.10.2051"
    },
    {
        "engDate": "1995-01-17",
        "nepDate": "03.10.2051"
    },
    {
        "engDate": "1995-01-18",
        "nepDate": "04.10.2051"
    },
    {
        "engDate": "1995-01-19",
        "nepDate": "05.10.2051"
    },
    {
        "engDate": "1995-01-20",
        "nepDate": "06.10.2051"
    },
    {
        "engDate": "1995-01-21",
        "nepDate": "07.10.2051"
    },
    {
        "engDate": "1995-01-22",
        "nepDate": "08.10.2051"
    },
    {
        "engDate": "1995-01-23",
        "nepDate": "09.10.2051"
    },
    {
        "engDate": "1995-01-24",
        "nepDate": "10.10.2051"
    },
    {
        "engDate": "1995-01-25",
        "nepDate": "11.10.2051"
    },
    {
        "engDate": "1995-01-26",
        "nepDate": "12.10.2051"
    },
    {
        "engDate": "1995-01-27",
        "nepDate": "13.10.2051"
    },
    {
        "engDate": "1995-01-28",
        "nepDate": "14.10.2051"
    },
    {
        "engDate": "1995-01-29",
        "nepDate": "15.10.2051"
    },
    {
        "engDate": "1995-01-30",
        "nepDate": "16.10.2051"
    },
    {
        "engDate": "1995-01-31",
        "nepDate": "17.10.2051"
    },
    {
        "engDate": "1995-02-01",
        "nepDate": "18.10.2051"
    },
    {
        "engDate": "1995-02-02",
        "nepDate": "19.10.2051"
    },
    {
        "engDate": "1995-02-03",
        "nepDate": "20.10.2051"
    },
    {
        "engDate": "1995-02-04",
        "nepDate": "21.10.2051"
    },
    {
        "engDate": "1995-02-05",
        "nepDate": "22.10.2051"
    },
    {
        "engDate": "1995-02-06",
        "nepDate": "23.10.2051"
    },
    {
        "engDate": "1995-02-07",
        "nepDate": "24.10.2051"
    },
    {
        "engDate": "1995-02-08",
        "nepDate": "25.10.2051"
    },
    {
        "engDate": "1995-02-09",
        "nepDate": "26.10.2051"
    },
    {
        "engDate": "1995-02-10",
        "nepDate": "27.10.2051"
    },
    {
        "engDate": "1995-02-11",
        "nepDate": "28.10.2051"
    },
    {
        "engDate": "1995-02-12",
        "nepDate": "29.10.2051"
    },
    {
        "engDate": "1995-02-13",
        "nepDate": "01.11.2051"
    },
    {
        "engDate": "1995-02-14",
        "nepDate": "02.11.2051"
    },
    {
        "engDate": "1995-02-15",
        "nepDate": "03.11.2051"
    },
    {
        "engDate": "1995-02-16",
        "nepDate": "04.11.2051"
    },
    {
        "engDate": "1995-02-17",
        "nepDate": "05.11.2051"
    },
    {
        "engDate": "1995-02-18",
        "nepDate": "06.11.2051"
    },
    {
        "engDate": "1995-02-19",
        "nepDate": "07.11.2051"
    },
    {
        "engDate": "1995-02-20",
        "nepDate": "08.11.2051"
    },
    {
        "engDate": "1995-02-21",
        "nepDate": "09.11.2051"
    },
    {
        "engDate": "1995-02-22",
        "nepDate": "10.11.2051"
    },
    {
        "engDate": "1995-02-23",
        "nepDate": "11.11.2051"
    },
    {
        "engDate": "1995-02-24",
        "nepDate": "12.11.2051"
    },
    {
        "engDate": "1995-02-25",
        "nepDate": "13.11.2051"
    },
    {
        "engDate": "1995-02-26",
        "nepDate": "14.11.2051"
    },
    {
        "engDate": "1995-02-27",
        "nepDate": "15.11.2051"
    },
    {
        "engDate": "1995-02-28",
        "nepDate": "16.11.2051"
    },
    {
        "engDate": "1995-03-01",
        "nepDate": "17.11.2051"
    },
    {
        "engDate": "1995-03-02",
        "nepDate": "18.11.2051"
    },
    {
        "engDate": "1995-03-03",
        "nepDate": "19.11.2051"
    },
    {
        "engDate": "1995-03-04",
        "nepDate": "20.11.2051"
    },
    {
        "engDate": "1995-03-05",
        "nepDate": "21.11.2051"
    },
    {
        "engDate": "1995-03-06",
        "nepDate": "22.11.2051"
    },
    {
        "engDate": "1995-03-07",
        "nepDate": "23.11.2051"
    },
    {
        "engDate": "1995-03-08",
        "nepDate": "24.11.2051"
    },
    {
        "engDate": "1995-03-09",
        "nepDate": "25.11.2051"
    },
    {
        "engDate": "1995-03-10",
        "nepDate": "26.11.2051"
    },
    {
        "engDate": "1995-03-11",
        "nepDate": "27.11.2051"
    },
    {
        "engDate": "1995-03-12",
        "nepDate": "28.11.2051"
    },
    {
        "engDate": "1995-03-13",
        "nepDate": "29.11.2051"
    },
    {
        "engDate": "1995-03-14",
        "nepDate": "30.11.2051"
    },
    {
        "engDate": "1995-03-15",
        "nepDate": "01.12.2051"
    },
    {
        "engDate": "1995-03-16",
        "nepDate": "02.12.2051"
    },
    {
        "engDate": "1995-03-17",
        "nepDate": "03.12.2051"
    },
    {
        "engDate": "1995-03-18",
        "nepDate": "04.12.2051"
    },
    {
        "engDate": "1995-03-19",
        "nepDate": "05.12.2051"
    },
    {
        "engDate": "1995-03-20",
        "nepDate": "06.12.2051"
    },
    {
        "engDate": "1995-03-21",
        "nepDate": "07.12.2051"
    },
    {
        "engDate": "1995-03-22",
        "nepDate": "08.12.2051"
    },
    {
        "engDate": "1995-03-23",
        "nepDate": "09.12.2051"
    },
    {
        "engDate": "1995-03-24",
        "nepDate": "10.12.2051"
    },
    {
        "engDate": "1995-03-25",
        "nepDate": "11.12.2051"
    },
    {
        "engDate": "1995-03-26",
        "nepDate": "12.12.2051"
    },
    {
        "engDate": "1995-03-27",
        "nepDate": "13.12.2051"
    },
    {
        "engDate": "1995-03-28",
        "nepDate": "14.12.2051"
    },
    {
        "engDate": "1995-03-29",
        "nepDate": "15.12.2051"
    },
    {
        "engDate": "1995-03-30",
        "nepDate": "16.12.2051"
    },
    {
        "engDate": "1995-03-31",
        "nepDate": "17.12.2051"
    },
    {
        "engDate": "1995-04-01",
        "nepDate": "18.12.2051"
    },
    {
        "engDate": "1995-04-02",
        "nepDate": "19.12.2051"
    },
    {
        "engDate": "1995-04-03",
        "nepDate": "20.12.2051"
    },
    {
        "engDate": "1995-04-04",
        "nepDate": "21.12.2051"
    },
    {
        "engDate": "1995-04-05",
        "nepDate": "22.12.2051"
    },
    {
        "engDate": "1995-04-06",
        "nepDate": "23.12.2051"
    },
    {
        "engDate": "1995-04-07",
        "nepDate": "24.12.2051"
    },
    {
        "engDate": "1995-04-08",
        "nepDate": "25.12.2051"
    },
    {
        "engDate": "1995-04-09",
        "nepDate": "26.12.2051"
    },
    {
        "engDate": "1995-04-10",
        "nepDate": "27.12.2051"
    },
    {
        "engDate": "1995-04-11",
        "nepDate": "28.12.2051"
    },
    {
        "engDate": "1995-04-12",
        "nepDate": "29.12.2051"
    },
    {
        "engDate": "1995-04-13",
        "nepDate": "30.12.2051"
    },
    {
        "engDate": "1995-04-14",
        "nepDate": "01.01.2052"
    },
    {
        "engDate": "1995-04-15",
        "nepDate": "02.01.2052"
    },
    {
        "engDate": "1995-04-16",
        "nepDate": "03.01.2052"
    },
    {
        "engDate": "1995-04-17",
        "nepDate": "04.01.2052"
    },
    {
        "engDate": "1995-04-18",
        "nepDate": "05.01.2052"
    },
    {
        "engDate": "1995-04-19",
        "nepDate": "06.01.2052"
    },
    {
        "engDate": "1995-04-20",
        "nepDate": "07.01.2052"
    },
    {
        "engDate": "1995-04-21",
        "nepDate": "08.01.2052"
    },
    {
        "engDate": "1995-04-22",
        "nepDate": "09.01.2052"
    },
    {
        "engDate": "1995-04-23",
        "nepDate": "10.01.2052"
    },
    {
        "engDate": "1995-04-24",
        "nepDate": "11.01.2052"
    },
    {
        "engDate": "1995-04-25",
        "nepDate": "12.01.2052"
    },
    {
        "engDate": "1995-04-26",
        "nepDate": "13.01.2052"
    },
    {
        "engDate": "1995-04-27",
        "nepDate": "14.01.2052"
    },
    {
        "engDate": "1995-04-28",
        "nepDate": "15.01.2052"
    },
    {
        "engDate": "1995-04-29",
        "nepDate": "16.01.2052"
    },
    {
        "engDate": "1995-04-30",
        "nepDate": "17.01.2052"
    },
    {
        "engDate": "1995-05-01",
        "nepDate": "18.01.2052"
    },
    {
        "engDate": "1995-05-02",
        "nepDate": "19.01.2052"
    },
    {
        "engDate": "1995-05-03",
        "nepDate": "20.01.2052"
    },
    {
        "engDate": "1995-05-04",
        "nepDate": "21.01.2052"
    },
    {
        "engDate": "1995-05-05",
        "nepDate": "22.01.2052"
    },
    {
        "engDate": "1995-05-06",
        "nepDate": "23.01.2052"
    },
    {
        "engDate": "1995-05-07",
        "nepDate": "24.01.2052"
    },
    {
        "engDate": "1995-05-08",
        "nepDate": "25.01.2052"
    },
    {
        "engDate": "1995-05-09",
        "nepDate": "26.01.2052"
    },
    {
        "engDate": "1995-05-10",
        "nepDate": "27.01.2052"
    },
    {
        "engDate": "1995-05-11",
        "nepDate": "28.01.2052"
    },
    {
        "engDate": "1995-05-12",
        "nepDate": "29.01.2052"
    },
    {
        "engDate": "1995-05-13",
        "nepDate": "30.01.2052"
    },
    {
        "engDate": "1995-05-14",
        "nepDate": "31.01.2052"
    },
    {
        "engDate": "1995-05-15",
        "nepDate": "01.02.2052"
    },
    {
        "engDate": "1995-05-16",
        "nepDate": "02.02.2052"
    },
    {
        "engDate": "1995-05-17",
        "nepDate": "03.02.2052"
    },
    {
        "engDate": "1995-05-18",
        "nepDate": "04.02.2052"
    },
    {
        "engDate": "1995-05-19",
        "nepDate": "05.02.2052"
    },
    {
        "engDate": "1995-05-20",
        "nepDate": "06.02.2052"
    },
    {
        "engDate": "1995-05-21",
        "nepDate": "07.02.2052"
    },
    {
        "engDate": "1995-05-22",
        "nepDate": "08.02.2052"
    },
    {
        "engDate": "1995-05-23",
        "nepDate": "09.02.2052"
    },
    {
        "engDate": "1995-05-24",
        "nepDate": "10.02.2052"
    },
    {
        "engDate": "1995-05-25",
        "nepDate": "11.02.2052"
    },
    {
        "engDate": "1995-05-26",
        "nepDate": "12.02.2052"
    },
    {
        "engDate": "1995-05-27",
        "nepDate": "13.02.2052"
    },
    {
        "engDate": "1995-05-28",
        "nepDate": "14.02.2052"
    },
    {
        "engDate": "1995-05-29",
        "nepDate": "15.02.2052"
    },
    {
        "engDate": "1995-05-30",
        "nepDate": "16.02.2052"
    },
    {
        "engDate": "1995-05-31",
        "nepDate": "17.02.2052"
    },
    {
        "engDate": "1995-06-01",
        "nepDate": "18.02.2052"
    },
    {
        "engDate": "1995-06-02",
        "nepDate": "19.02.2052"
    },
    {
        "engDate": "1995-06-03",
        "nepDate": "20.02.2052"
    },
    {
        "engDate": "1995-06-04",
        "nepDate": "21.02.2052"
    },
    {
        "engDate": "1995-06-05",
        "nepDate": "22.02.2052"
    },
    {
        "engDate": "1995-06-06",
        "nepDate": "23.02.2052"
    },
    {
        "engDate": "1995-06-07",
        "nepDate": "24.02.2052"
    },
    {
        "engDate": "1995-06-08",
        "nepDate": "25.02.2052"
    },
    {
        "engDate": "1995-06-09",
        "nepDate": "26.02.2052"
    },
    {
        "engDate": "1995-06-10",
        "nepDate": "27.02.2052"
    },
    {
        "engDate": "1995-06-11",
        "nepDate": "28.02.2052"
    },
    {
        "engDate": "1995-06-12",
        "nepDate": "29.02.2052"
    },
    {
        "engDate": "1995-06-13",
        "nepDate": "30.02.2052"
    },
    {
        "engDate": "1995-06-14",
        "nepDate": "31.02.2052"
    },
    {
        "engDate": "1995-06-15",
        "nepDate": "01.03.2052"
    },
    {
        "engDate": "1995-06-16",
        "nepDate": "02.03.2052"
    },
    {
        "engDate": "1995-06-17",
        "nepDate": "03.03.2052"
    },
    {
        "engDate": "1995-06-18",
        "nepDate": "04.03.2052"
    },
    {
        "engDate": "1995-06-19",
        "nepDate": "05.03.2052"
    },
    {
        "engDate": "1995-06-20",
        "nepDate": "06.03.2052"
    },
    {
        "engDate": "1995-06-21",
        "nepDate": "07.03.2052"
    },
    {
        "engDate": "1995-06-22",
        "nepDate": "08.03.2052"
    },
    {
        "engDate": "1995-06-23",
        "nepDate": "09.03.2052"
    },
    {
        "engDate": "1995-06-24",
        "nepDate": "10.03.2052"
    },
    {
        "engDate": "1995-06-25",
        "nepDate": "11.03.2052"
    },
    {
        "engDate": "1995-06-26",
        "nepDate": "12.03.2052"
    },
    {
        "engDate": "1995-06-27",
        "nepDate": "13.03.2052"
    },
    {
        "engDate": "1995-06-28",
        "nepDate": "14.03.2052"
    },
    {
        "engDate": "1995-06-29",
        "nepDate": "15.03.2052"
    },
    {
        "engDate": "1995-06-30",
        "nepDate": "16.03.2052"
    },
    {
        "engDate": "1995-07-01",
        "nepDate": "17.03.2052"
    },
    {
        "engDate": "1995-07-02",
        "nepDate": "18.03.2052"
    },
    {
        "engDate": "1995-07-03",
        "nepDate": "19.03.2052"
    },
    {
        "engDate": "1995-07-04",
        "nepDate": "20.03.2052"
    },
    {
        "engDate": "1995-07-05",
        "nepDate": "21.03.2052"
    },
    {
        "engDate": "1995-07-06",
        "nepDate": "22.03.2052"
    },
    {
        "engDate": "1995-07-07",
        "nepDate": "23.03.2052"
    },
    {
        "engDate": "1995-07-08",
        "nepDate": "24.03.2052"
    },
    {
        "engDate": "1995-07-09",
        "nepDate": "25.03.2052"
    },
    {
        "engDate": "1995-07-10",
        "nepDate": "26.03.2052"
    },
    {
        "engDate": "1995-07-11",
        "nepDate": "27.03.2052"
    },
    {
        "engDate": "1995-07-12",
        "nepDate": "28.03.2052"
    },
    {
        "engDate": "1995-07-13",
        "nepDate": "29.03.2052"
    },
    {
        "engDate": "1995-07-14",
        "nepDate": "30.03.2052"
    },
    {
        "engDate": "1995-07-15",
        "nepDate": "31.03.2052"
    },
    {
        "engDate": "1995-07-16",
        "nepDate": "32.03.2052"
    },
    {
        "engDate": "1995-07-17",
        "nepDate": "01.04.2052"
    },
    {
        "engDate": "1995-07-18",
        "nepDate": "02.04.2052"
    },
    {
        "engDate": "1995-07-19",
        "nepDate": "03.04.2052"
    },
    {
        "engDate": "1995-07-20",
        "nepDate": "04.04.2052"
    },
    {
        "engDate": "1995-07-21",
        "nepDate": "05.04.2052"
    },
    {
        "engDate": "1995-07-22",
        "nepDate": "06.04.2052"
    },
    {
        "engDate": "1995-07-23",
        "nepDate": "07.04.2052"
    },
    {
        "engDate": "1995-07-24",
        "nepDate": "08.04.2052"
    },
    {
        "engDate": "1995-07-25",
        "nepDate": "09.04.2052"
    },
    {
        "engDate": "1995-07-26",
        "nepDate": "10.04.2052"
    },
    {
        "engDate": "1995-07-27",
        "nepDate": "11.04.2052"
    },
    {
        "engDate": "1995-07-28",
        "nepDate": "12.04.2052"
    },
    {
        "engDate": "1995-07-29",
        "nepDate": "13.04.2052"
    },
    {
        "engDate": "1995-07-30",
        "nepDate": "14.04.2052"
    },
    {
        "engDate": "1995-07-31",
        "nepDate": "15.04.2052"
    },
    {
        "engDate": "1995-08-01",
        "nepDate": "16.04.2052"
    },
    {
        "engDate": "1995-08-02",
        "nepDate": "17.04.2052"
    },
    {
        "engDate": "1995-08-03",
        "nepDate": "18.04.2052"
    },
    {
        "engDate": "1995-08-04",
        "nepDate": "19.04.2052"
    },
    {
        "engDate": "1995-08-05",
        "nepDate": "20.04.2052"
    },
    {
        "engDate": "1995-08-06",
        "nepDate": "21.04.2052"
    },
    {
        "engDate": "1995-08-07",
        "nepDate": "22.04.2052"
    },
    {
        "engDate": "1995-08-08",
        "nepDate": "23.04.2052"
    },
    {
        "engDate": "1995-08-09",
        "nepDate": "24.04.2052"
    },
    {
        "engDate": "1995-08-10",
        "nepDate": "25.04.2052"
    },
    {
        "engDate": "1995-08-11",
        "nepDate": "26.04.2052"
    },
    {
        "engDate": "1995-08-12",
        "nepDate": "27.04.2052"
    },
    {
        "engDate": "1995-08-13",
        "nepDate": "28.04.2052"
    },
    {
        "engDate": "1995-08-14",
        "nepDate": "29.04.2052"
    },
    {
        "engDate": "1995-08-15",
        "nepDate": "30.04.2052"
    },
    {
        "engDate": "1995-08-16",
        "nepDate": "31.04.2052"
    },
    {
        "engDate": "1995-08-17",
        "nepDate": "01.05.2052"
    },
    {
        "engDate": "1995-08-18",
        "nepDate": "02.05.2052"
    },
    {
        "engDate": "1995-08-19",
        "nepDate": "03.05.2052"
    },
    {
        "engDate": "1995-08-20",
        "nepDate": "04.05.2052"
    },
    {
        "engDate": "1995-08-21",
        "nepDate": "05.05.2052"
    },
    {
        "engDate": "1995-08-22",
        "nepDate": "06.05.2052"
    },
    {
        "engDate": "1995-08-23",
        "nepDate": "07.05.2052"
    },
    {
        "engDate": "1995-08-24",
        "nepDate": "08.05.2052"
    },
    {
        "engDate": "1995-08-25",
        "nepDate": "09.05.2052"
    },
    {
        "engDate": "1995-08-26",
        "nepDate": "10.05.2052"
    },
    {
        "engDate": "1995-08-27",
        "nepDate": "11.05.2052"
    },
    {
        "engDate": "1995-08-28",
        "nepDate": "12.05.2052"
    },
    {
        "engDate": "1995-08-29",
        "nepDate": "13.05.2052"
    },
    {
        "engDate": "1995-08-30",
        "nepDate": "14.05.2052"
    },
    {
        "engDate": "1995-08-31",
        "nepDate": "15.05.2052"
    },
    {
        "engDate": "1995-09-01",
        "nepDate": "16.05.2052"
    },
    {
        "engDate": "1995-09-02",
        "nepDate": "17.05.2052"
    },
    {
        "engDate": "1995-09-03",
        "nepDate": "18.05.2052"
    },
    {
        "engDate": "1995-09-04",
        "nepDate": "19.05.2052"
    },
    {
        "engDate": "1995-09-05",
        "nepDate": "20.05.2052"
    },
    {
        "engDate": "1995-09-06",
        "nepDate": "21.05.2052"
    },
    {
        "engDate": "1995-09-07",
        "nepDate": "22.05.2052"
    },
    {
        "engDate": "1995-09-08",
        "nepDate": "23.05.2052"
    },
    {
        "engDate": "1995-09-09",
        "nepDate": "24.05.2052"
    },
    {
        "engDate": "1995-09-10",
        "nepDate": "25.05.2052"
    },
    {
        "engDate": "1995-09-11",
        "nepDate": "26.05.2052"
    },
    {
        "engDate": "1995-09-12",
        "nepDate": "27.05.2052"
    },
    {
        "engDate": "1995-09-13",
        "nepDate": "28.05.2052"
    },
    {
        "engDate": "1995-09-14",
        "nepDate": "29.05.2052"
    },
    {
        "engDate": "1995-09-15",
        "nepDate": "30.05.2052"
    },
    {
        "engDate": "1995-09-16",
        "nepDate": "31.05.2052"
    },
    {
        "engDate": "1995-09-17",
        "nepDate": "01.06.2052"
    },
    {
        "engDate": "1995-09-18",
        "nepDate": "02.06.2052"
    },
    {
        "engDate": "1995-09-19",
        "nepDate": "03.06.2052"
    },
    {
        "engDate": "1995-09-20",
        "nepDate": "04.06.2052"
    },
    {
        "engDate": "1995-09-21",
        "nepDate": "05.06.2052"
    },
    {
        "engDate": "1995-09-22",
        "nepDate": "06.06.2052"
    },
    {
        "engDate": "1995-09-23",
        "nepDate": "07.06.2052"
    },
    {
        "engDate": "1995-09-24",
        "nepDate": "08.06.2052"
    },
    {
        "engDate": "1995-09-25",
        "nepDate": "09.06.2052"
    },
    {
        "engDate": "1995-09-26",
        "nepDate": "10.06.2052"
    },
    {
        "engDate": "1995-09-27",
        "nepDate": "11.06.2052"
    },
    {
        "engDate": "1995-09-28",
        "nepDate": "12.06.2052"
    },
    {
        "engDate": "1995-09-29",
        "nepDate": "13.06.2052"
    },
    {
        "engDate": "1995-09-30",
        "nepDate": "14.06.2052"
    },
    {
        "engDate": "1995-10-01",
        "nepDate": "15.06.2052"
    },
    {
        "engDate": "1995-10-02",
        "nepDate": "16.06.2052"
    },
    {
        "engDate": "1995-10-03",
        "nepDate": "17.06.2052"
    },
    {
        "engDate": "1995-10-04",
        "nepDate": "18.06.2052"
    },
    {
        "engDate": "1995-10-05",
        "nepDate": "19.06.2052"
    },
    {
        "engDate": "1995-10-06",
        "nepDate": "20.06.2052"
    },
    {
        "engDate": "1995-10-07",
        "nepDate": "21.06.2052"
    },
    {
        "engDate": "1995-10-08",
        "nepDate": "22.06.2052"
    },
    {
        "engDate": "1995-10-09",
        "nepDate": "23.06.2052"
    },
    {
        "engDate": "1995-10-10",
        "nepDate": "24.06.2052"
    },
    {
        "engDate": "1995-10-11",
        "nepDate": "25.06.2052"
    },
    {
        "engDate": "1995-10-12",
        "nepDate": "26.06.2052"
    },
    {
        "engDate": "1995-10-13",
        "nepDate": "27.06.2052"
    },
    {
        "engDate": "1995-10-14",
        "nepDate": "28.06.2052"
    },
    {
        "engDate": "1995-10-15",
        "nepDate": "29.06.2052"
    },
    {
        "engDate": "1995-10-16",
        "nepDate": "30.06.2052"
    },
    {
        "engDate": "1995-10-17",
        "nepDate": "31.06.2052"
    },
    {
        "engDate": "1995-10-18",
        "nepDate": "01.07.2052"
    },
    {
        "engDate": "1995-10-19",
        "nepDate": "02.07.2052"
    },
    {
        "engDate": "1995-10-20",
        "nepDate": "03.07.2052"
    },
    {
        "engDate": "1995-10-21",
        "nepDate": "04.07.2052"
    },
    {
        "engDate": "1995-10-22",
        "nepDate": "05.07.2052"
    },
    {
        "engDate": "1995-10-23",
        "nepDate": "06.07.2052"
    },
    {
        "engDate": "1995-10-24",
        "nepDate": "07.07.2052"
    },
    {
        "engDate": "1995-10-25",
        "nepDate": "08.07.2052"
    },
    {
        "engDate": "1995-10-26",
        "nepDate": "09.07.2052"
    },
    {
        "engDate": "1995-10-27",
        "nepDate": "10.07.2052"
    },
    {
        "engDate": "1995-10-28",
        "nepDate": "11.07.2052"
    },
    {
        "engDate": "1995-10-29",
        "nepDate": "12.07.2052"
    },
    {
        "engDate": "1995-10-30",
        "nepDate": "13.07.2052"
    },
    {
        "engDate": "1995-10-31",
        "nepDate": "14.07.2052"
    },
    {
        "engDate": "1995-11-01",
        "nepDate": "15.07.2052"
    },
    {
        "engDate": "1995-11-02",
        "nepDate": "16.07.2052"
    },
    {
        "engDate": "1995-11-03",
        "nepDate": "17.07.2052"
    },
    {
        "engDate": "1995-11-04",
        "nepDate": "18.07.2052"
    },
    {
        "engDate": "1995-11-05",
        "nepDate": "19.07.2052"
    },
    {
        "engDate": "1995-11-06",
        "nepDate": "20.07.2052"
    },
    {
        "engDate": "1995-11-07",
        "nepDate": "21.07.2052"
    },
    {
        "engDate": "1995-11-08",
        "nepDate": "22.07.2052"
    },
    {
        "engDate": "1995-11-09",
        "nepDate": "23.07.2052"
    },
    {
        "engDate": "1995-11-10",
        "nepDate": "24.07.2052"
    },
    {
        "engDate": "1995-11-11",
        "nepDate": "25.07.2052"
    },
    {
        "engDate": "1995-11-12",
        "nepDate": "26.07.2052"
    },
    {
        "engDate": "1995-11-13",
        "nepDate": "27.07.2052"
    },
    {
        "engDate": "1995-11-14",
        "nepDate": "28.07.2052"
    },
    {
        "engDate": "1995-11-15",
        "nepDate": "29.07.2052"
    },
    {
        "engDate": "1995-11-16",
        "nepDate": "30.07.2052"
    },
    {
        "engDate": "1995-11-17",
        "nepDate": "01.08.2052"
    },
    {
        "engDate": "1995-11-18",
        "nepDate": "02.08.2052"
    },
    {
        "engDate": "1995-11-19",
        "nepDate": "03.08.2052"
    },
    {
        "engDate": "1995-11-20",
        "nepDate": "04.08.2052"
    },
    {
        "engDate": "1995-11-21",
        "nepDate": "05.08.2052"
    },
    {
        "engDate": "1995-11-22",
        "nepDate": "06.08.2052"
    },
    {
        "engDate": "1995-11-23",
        "nepDate": "07.08.2052"
    },
    {
        "engDate": "1995-11-24",
        "nepDate": "08.08.2052"
    },
    {
        "engDate": "1995-11-25",
        "nepDate": "09.08.2052"
    },
    {
        "engDate": "1995-11-26",
        "nepDate": "10.08.2052"
    },
    {
        "engDate": "1995-11-27",
        "nepDate": "11.08.2052"
    },
    {
        "engDate": "1995-11-28",
        "nepDate": "12.08.2052"
    },
    {
        "engDate": "1995-11-29",
        "nepDate": "13.08.2052"
    },
    {
        "engDate": "1995-11-30",
        "nepDate": "14.08.2052"
    },
    {
        "engDate": "1995-12-01",
        "nepDate": "15.08.2052"
    },
    {
        "engDate": "1995-12-02",
        "nepDate": "16.08.2052"
    },
    {
        "engDate": "1995-12-03",
        "nepDate": "17.08.2052"
    },
    {
        "engDate": "1995-12-04",
        "nepDate": "18.08.2052"
    },
    {
        "engDate": "1995-12-05",
        "nepDate": "19.08.2052"
    },
    {
        "engDate": "1995-12-06",
        "nepDate": "20.08.2052"
    },
    {
        "engDate": "1995-12-07",
        "nepDate": "21.08.2052"
    },
    {
        "engDate": "1995-12-08",
        "nepDate": "22.08.2052"
    },
    {
        "engDate": "1995-12-09",
        "nepDate": "23.08.2052"
    },
    {
        "engDate": "1995-12-10",
        "nepDate": "24.08.2052"
    },
    {
        "engDate": "1995-12-11",
        "nepDate": "25.08.2052"
    },
    {
        "engDate": "1995-12-12",
        "nepDate": "26.08.2052"
    },
    {
        "engDate": "1995-12-13",
        "nepDate": "27.08.2052"
    },
    {
        "engDate": "1995-12-14",
        "nepDate": "28.08.2052"
    },
    {
        "engDate": "1995-12-15",
        "nepDate": "29.08.2052"
    },
    {
        "engDate": "1995-12-16",
        "nepDate": "01.09.2052"
    },
    {
        "engDate": "1995-12-17",
        "nepDate": "02.09.2052"
    },
    {
        "engDate": "1995-12-18",
        "nepDate": "03.09.2052"
    },
    {
        "engDate": "1995-12-19",
        "nepDate": "04.09.2052"
    },
    {
        "engDate": "1995-12-20",
        "nepDate": "05.09.2052"
    },
    {
        "engDate": "1995-12-21",
        "nepDate": "06.09.2052"
    },
    {
        "engDate": "1995-12-22",
        "nepDate": "07.09.2052"
    },
    {
        "engDate": "1995-12-23",
        "nepDate": "08.09.2052"
    },
    {
        "engDate": "1995-12-24",
        "nepDate": "09.09.2052"
    },
    {
        "engDate": "1995-12-25",
        "nepDate": "10.09.2052"
    },
    {
        "engDate": "1995-12-26",
        "nepDate": "11.09.2052"
    },
    {
        "engDate": "1995-12-27",
        "nepDate": "12.09.2052"
    },
    {
        "engDate": "1995-12-28",
        "nepDate": "13.09.2052"
    },
    {
        "engDate": "1995-12-29",
        "nepDate": "14.09.2052"
    },
    {
        "engDate": "1995-12-30",
        "nepDate": "15.09.2052"
    },
    {
        "engDate": "1995-12-31",
        "nepDate": "16.09.2052"
    },
    {
        "engDate": "1996-01-01",
        "nepDate": "17.09.2052"
    },
    {
        "engDate": "1996-01-02",
        "nepDate": "18.09.2052"
    },
    {
        "engDate": "1996-01-03",
        "nepDate": "19.09.2052"
    },
    {
        "engDate": "1996-01-04",
        "nepDate": "20.09.2052"
    },
    {
        "engDate": "1996-01-05",
        "nepDate": "21.09.2052"
    },
    {
        "engDate": "1996-01-06",
        "nepDate": "22.09.2052"
    },
    {
        "engDate": "1996-01-07",
        "nepDate": "23.09.2052"
    },
    {
        "engDate": "1996-01-08",
        "nepDate": "24.09.2052"
    },
    {
        "engDate": "1996-01-09",
        "nepDate": "25.09.2052"
    },
    {
        "engDate": "1996-01-10",
        "nepDate": "26.09.2052"
    },
    {
        "engDate": "1996-01-11",
        "nepDate": "27.09.2052"
    },
    {
        "engDate": "1996-01-12",
        "nepDate": "28.09.2052"
    },
    {
        "engDate": "1996-01-13",
        "nepDate": "29.09.2052"
    },
    {
        "engDate": "1996-01-14",
        "nepDate": "30.09.2052"
    },
    {
        "engDate": "1996-01-15",
        "nepDate": "01.10.2052"
    },
    {
        "engDate": "1996-01-16",
        "nepDate": "02.10.2052"
    },
    {
        "engDate": "1996-01-17",
        "nepDate": "03.10.2052"
    },
    {
        "engDate": "1996-01-18",
        "nepDate": "04.10.2052"
    },
    {
        "engDate": "1996-01-19",
        "nepDate": "05.10.2052"
    },
    {
        "engDate": "1996-01-20",
        "nepDate": "06.10.2052"
    },
    {
        "engDate": "1996-01-21",
        "nepDate": "07.10.2052"
    },
    {
        "engDate": "1996-01-22",
        "nepDate": "08.10.2052"
    },
    {
        "engDate": "1996-01-23",
        "nepDate": "09.10.2052"
    },
    {
        "engDate": "1996-01-24",
        "nepDate": "10.10.2052"
    },
    {
        "engDate": "1996-01-25",
        "nepDate": "11.10.2052"
    },
    {
        "engDate": "1996-01-26",
        "nepDate": "12.10.2052"
    },
    {
        "engDate": "1996-01-27",
        "nepDate": "13.10.2052"
    },
    {
        "engDate": "1996-01-28",
        "nepDate": "14.10.2052"
    },
    {
        "engDate": "1996-01-29",
        "nepDate": "15.10.2052"
    },
    {
        "engDate": "1996-01-30",
        "nepDate": "16.10.2052"
    },
    {
        "engDate": "1996-01-31",
        "nepDate": "17.10.2052"
    },
    {
        "engDate": "1996-02-01",
        "nepDate": "18.10.2052"
    },
    {
        "engDate": "1996-02-02",
        "nepDate": "19.10.2052"
    },
    {
        "engDate": "1996-02-03",
        "nepDate": "20.10.2052"
    },
    {
        "engDate": "1996-02-04",
        "nepDate": "21.10.2052"
    },
    {
        "engDate": "1996-02-05",
        "nepDate": "22.10.2052"
    },
    {
        "engDate": "1996-02-06",
        "nepDate": "23.10.2052"
    },
    {
        "engDate": "1996-02-07",
        "nepDate": "24.10.2052"
    },
    {
        "engDate": "1996-02-08",
        "nepDate": "25.10.2052"
    },
    {
        "engDate": "1996-02-09",
        "nepDate": "26.10.2052"
    },
    {
        "engDate": "1996-02-10",
        "nepDate": "27.10.2052"
    },
    {
        "engDate": "1996-02-11",
        "nepDate": "28.10.2052"
    },
    {
        "engDate": "1996-02-12",
        "nepDate": "29.10.2052"
    },
    {
        "engDate": "1996-02-13",
        "nepDate": "01.11.2052"
    },
    {
        "engDate": "1996-02-14",
        "nepDate": "02.11.2052"
    },
    {
        "engDate": "1996-02-15",
        "nepDate": "03.11.2052"
    },
    {
        "engDate": "1996-02-16",
        "nepDate": "04.11.2052"
    },
    {
        "engDate": "1996-02-17",
        "nepDate": "05.11.2052"
    },
    {
        "engDate": "1996-02-18",
        "nepDate": "06.11.2052"
    },
    {
        "engDate": "1996-02-19",
        "nepDate": "07.11.2052"
    },
    {
        "engDate": "1996-02-20",
        "nepDate": "08.11.2052"
    },
    {
        "engDate": "1996-02-21",
        "nepDate": "09.11.2052"
    },
    {
        "engDate": "1996-02-22",
        "nepDate": "10.11.2052"
    },
    {
        "engDate": "1996-02-23",
        "nepDate": "11.11.2052"
    },
    {
        "engDate": "1996-02-24",
        "nepDate": "12.11.2052"
    },
    {
        "engDate": "1996-02-25",
        "nepDate": "13.11.2052"
    },
    {
        "engDate": "1996-02-26",
        "nepDate": "14.11.2052"
    },
    {
        "engDate": "1996-02-27",
        "nepDate": "15.11.2052"
    },
    {
        "engDate": "1996-02-28",
        "nepDate": "16.11.2052"
    },
    {
        "engDate": "1996-02-29",
        "nepDate": "17.11.2052"
    },
    {
        "engDate": "1996-03-01",
        "nepDate": "18.11.2052"
    },
    {
        "engDate": "1996-03-02",
        "nepDate": "19.11.2052"
    },
    {
        "engDate": "1996-03-03",
        "nepDate": "20.11.2052"
    },
    {
        "engDate": "1996-03-04",
        "nepDate": "21.11.2052"
    },
    {
        "engDate": "1996-03-05",
        "nepDate": "22.11.2052"
    },
    {
        "engDate": "1996-03-06",
        "nepDate": "23.11.2052"
    },
    {
        "engDate": "1996-03-07",
        "nepDate": "24.11.2052"
    },
    {
        "engDate": "1996-03-08",
        "nepDate": "25.11.2052"
    },
    {
        "engDate": "1996-03-09",
        "nepDate": "26.11.2052"
    },
    {
        "engDate": "1996-03-10",
        "nepDate": "27.11.2052"
    },
    {
        "engDate": "1996-03-11",
        "nepDate": "28.11.2052"
    },
    {
        "engDate": "1996-03-12",
        "nepDate": "29.11.2052"
    },
    {
        "engDate": "1996-03-13",
        "nepDate": "30.11.2052"
    },
    {
        "engDate": "1996-03-14",
        "nepDate": "01.12.2052"
    },
    {
        "engDate": "1996-03-15",
        "nepDate": "02.12.2052"
    },
    {
        "engDate": "1996-03-16",
        "nepDate": "03.12.2052"
    },
    {
        "engDate": "1996-03-17",
        "nepDate": "04.12.2052"
    },
    {
        "engDate": "1996-03-18",
        "nepDate": "05.12.2052"
    },
    {
        "engDate": "1996-03-19",
        "nepDate": "06.12.2052"
    },
    {
        "engDate": "1996-03-20",
        "nepDate": "07.12.2052"
    },
    {
        "engDate": "1996-03-21",
        "nepDate": "08.12.2052"
    },
    {
        "engDate": "1996-03-22",
        "nepDate": "09.12.2052"
    },
    {
        "engDate": "1996-03-23",
        "nepDate": "10.12.2052"
    },
    {
        "engDate": "1996-03-24",
        "nepDate": "11.12.2052"
    },
    {
        "engDate": "1996-03-25",
        "nepDate": "12.12.2052"
    },
    {
        "engDate": "1996-03-26",
        "nepDate": "13.12.2052"
    },
    {
        "engDate": "1996-03-27",
        "nepDate": "14.12.2052"
    },
    {
        "engDate": "1996-03-28",
        "nepDate": "15.12.2052"
    },
    {
        "engDate": "1996-03-29",
        "nepDate": "16.12.2052"
    },
    {
        "engDate": "1996-03-30",
        "nepDate": "17.12.2052"
    },
    {
        "engDate": "1996-03-31",
        "nepDate": "18.12.2052"
    },
    {
        "engDate": "1996-04-01",
        "nepDate": "19.12.2052"
    },
    {
        "engDate": "1996-04-02",
        "nepDate": "20.12.2052"
    },
    {
        "engDate": "1996-04-03",
        "nepDate": "21.12.2052"
    },
    {
        "engDate": "1996-04-04",
        "nepDate": "22.12.2052"
    },
    {
        "engDate": "1996-04-05",
        "nepDate": "23.12.2052"
    },
    {
        "engDate": "1996-04-06",
        "nepDate": "24.12.2052"
    },
    {
        "engDate": "1996-04-07",
        "nepDate": "25.12.2052"
    },
    {
        "engDate": "1996-04-08",
        "nepDate": "26.12.2052"
    },
    {
        "engDate": "1996-04-09",
        "nepDate": "27.12.2052"
    },
    {
        "engDate": "1996-04-10",
        "nepDate": "28.12.2052"
    },
    {
        "engDate": "1996-04-11",
        "nepDate": "29.12.2052"
    },
    {
        "engDate": "1996-04-12",
        "nepDate": "30.12.2052"
    },
    {
        "engDate": "1996-04-13",
        "nepDate": "01.01.2053"
    },
    {
        "engDate": "1996-04-14",
        "nepDate": "02.01.2053"
    },
    {
        "engDate": "1996-04-15",
        "nepDate": "03.01.2053"
    },
    {
        "engDate": "1996-04-16",
        "nepDate": "04.01.2053"
    },
    {
        "engDate": "1996-04-17",
        "nepDate": "05.01.2053"
    },
    {
        "engDate": "1996-04-18",
        "nepDate": "06.01.2053"
    },
    {
        "engDate": "1996-04-19",
        "nepDate": "07.01.2053"
    },
    {
        "engDate": "1996-04-20",
        "nepDate": "08.01.2053"
    },
    {
        "engDate": "1996-04-21",
        "nepDate": "09.01.2053"
    },
    {
        "engDate": "1996-04-22",
        "nepDate": "10.01.2053"
    },
    {
        "engDate": "1996-04-23",
        "nepDate": "11.01.2053"
    },
    {
        "engDate": "1996-04-24",
        "nepDate": "12.01.2053"
    },
    {
        "engDate": "1996-04-25",
        "nepDate": "13.01.2053"
    },
    {
        "engDate": "1996-04-26",
        "nepDate": "14.01.2053"
    },
    {
        "engDate": "1996-04-27",
        "nepDate": "15.01.2053"
    },
    {
        "engDate": "1996-04-28",
        "nepDate": "16.01.2053"
    },
    {
        "engDate": "1996-04-29",
        "nepDate": "17.01.2053"
    },
    {
        "engDate": "1996-04-30",
        "nepDate": "18.01.2053"
    },
    {
        "engDate": "1996-05-01",
        "nepDate": "19.01.2053"
    },
    {
        "engDate": "1996-05-02",
        "nepDate": "20.01.2053"
    },
    {
        "engDate": "1996-05-03",
        "nepDate": "21.01.2053"
    },
    {
        "engDate": "1996-05-04",
        "nepDate": "22.01.2053"
    },
    {
        "engDate": "1996-05-05",
        "nepDate": "23.01.2053"
    },
    {
        "engDate": "1996-05-06",
        "nepDate": "24.01.2053"
    },
    {
        "engDate": "1996-05-07",
        "nepDate": "25.01.2053"
    },
    {
        "engDate": "1996-05-08",
        "nepDate": "26.01.2053"
    },
    {
        "engDate": "1996-05-09",
        "nepDate": "27.01.2053"
    },
    {
        "engDate": "1996-05-10",
        "nepDate": "28.01.2053"
    },
    {
        "engDate": "1996-05-11",
        "nepDate": "29.01.2053"
    },
    {
        "engDate": "1996-05-12",
        "nepDate": "30.01.2053"
    },
    {
        "engDate": "1996-05-13",
        "nepDate": "31.01.2053"
    },
    {
        "engDate": "1996-05-14",
        "nepDate": "01.02.2053"
    },
    {
        "engDate": "1996-05-15",
        "nepDate": "02.02.2053"
    },
    {
        "engDate": "1996-05-16",
        "nepDate": "03.02.2053"
    },
    {
        "engDate": "1996-05-17",
        "nepDate": "04.02.2053"
    },
    {
        "engDate": "1996-05-18",
        "nepDate": "05.02.2053"
    },
    {
        "engDate": "1996-05-19",
        "nepDate": "06.02.2053"
    },
    {
        "engDate": "1996-05-20",
        "nepDate": "07.02.2053"
    },
    {
        "engDate": "1996-05-21",
        "nepDate": "08.02.2053"
    },
    {
        "engDate": "1996-05-22",
        "nepDate": "09.02.2053"
    },
    {
        "engDate": "1996-05-23",
        "nepDate": "10.02.2053"
    },
    {
        "engDate": "1996-05-24",
        "nepDate": "11.02.2053"
    },
    {
        "engDate": "1996-05-25",
        "nepDate": "12.02.2053"
    },
    {
        "engDate": "1996-05-26",
        "nepDate": "13.02.2053"
    },
    {
        "engDate": "1996-05-27",
        "nepDate": "14.02.2053"
    },
    {
        "engDate": "1996-05-28",
        "nepDate": "15.02.2053"
    },
    {
        "engDate": "1996-05-29",
        "nepDate": "16.02.2053"
    },
    {
        "engDate": "1996-05-30",
        "nepDate": "17.02.2053"
    },
    {
        "engDate": "1996-05-31",
        "nepDate": "18.02.2053"
    },
    {
        "engDate": "1996-06-01",
        "nepDate": "19.02.2053"
    },
    {
        "engDate": "1996-06-02",
        "nepDate": "20.02.2053"
    },
    {
        "engDate": "1996-06-03",
        "nepDate": "21.02.2053"
    },
    {
        "engDate": "1996-06-04",
        "nepDate": "22.02.2053"
    },
    {
        "engDate": "1996-06-05",
        "nepDate": "23.02.2053"
    },
    {
        "engDate": "1996-06-06",
        "nepDate": "24.02.2053"
    },
    {
        "engDate": "1996-06-07",
        "nepDate": "25.02.2053"
    },
    {
        "engDate": "1996-06-08",
        "nepDate": "26.02.2053"
    },
    {
        "engDate": "1996-06-09",
        "nepDate": "27.02.2053"
    },
    {
        "engDate": "1996-06-10",
        "nepDate": "28.02.2053"
    },
    {
        "engDate": "1996-06-11",
        "nepDate": "29.02.2053"
    },
    {
        "engDate": "1996-06-12",
        "nepDate": "30.02.2053"
    },
    {
        "engDate": "1996-06-13",
        "nepDate": "31.02.2053"
    },
    {
        "engDate": "1996-06-14",
        "nepDate": "32.02.2053"
    },
    {
        "engDate": "1996-06-15",
        "nepDate": "01.03.2053"
    },
    {
        "engDate": "1996-06-16",
        "nepDate": "02.03.2053"
    },
    {
        "engDate": "1996-06-17",
        "nepDate": "03.03.2053"
    },
    {
        "engDate": "1996-06-18",
        "nepDate": "04.03.2053"
    },
    {
        "engDate": "1996-06-19",
        "nepDate": "05.03.2053"
    },
    {
        "engDate": "1996-06-20",
        "nepDate": "06.03.2053"
    },
    {
        "engDate": "1996-06-21",
        "nepDate": "07.03.2053"
    },
    {
        "engDate": "1996-06-22",
        "nepDate": "08.03.2053"
    },
    {
        "engDate": "1996-06-23",
        "nepDate": "09.03.2053"
    },
    {
        "engDate": "1996-06-24",
        "nepDate": "10.03.2053"
    },
    {
        "engDate": "1996-06-25",
        "nepDate": "11.03.2053"
    },
    {
        "engDate": "1996-06-26",
        "nepDate": "12.03.2053"
    },
    {
        "engDate": "1996-06-27",
        "nepDate": "13.03.2053"
    },
    {
        "engDate": "1996-06-28",
        "nepDate": "14.03.2053"
    },
    {
        "engDate": "1996-06-29",
        "nepDate": "15.03.2053"
    },
    {
        "engDate": "1996-06-30",
        "nepDate": "16.03.2053"
    },
    {
        "engDate": "1996-07-01",
        "nepDate": "17.03.2053"
    },
    {
        "engDate": "1996-07-02",
        "nepDate": "18.03.2053"
    },
    {
        "engDate": "1996-07-03",
        "nepDate": "19.03.2053"
    },
    {
        "engDate": "1996-07-04",
        "nepDate": "20.03.2053"
    },
    {
        "engDate": "1996-07-05",
        "nepDate": "21.03.2053"
    },
    {
        "engDate": "1996-07-06",
        "nepDate": "22.03.2053"
    },
    {
        "engDate": "1996-07-07",
        "nepDate": "23.03.2053"
    },
    {
        "engDate": "1996-07-08",
        "nepDate": "24.03.2053"
    },
    {
        "engDate": "1996-07-09",
        "nepDate": "25.03.2053"
    },
    {
        "engDate": "1996-07-10",
        "nepDate": "26.03.2053"
    },
    {
        "engDate": "1996-07-11",
        "nepDate": "27.03.2053"
    },
    {
        "engDate": "1996-07-12",
        "nepDate": "28.03.2053"
    },
    {
        "engDate": "1996-07-13",
        "nepDate": "29.03.2053"
    },
    {
        "engDate": "1996-07-14",
        "nepDate": "30.03.2053"
    },
    {
        "engDate": "1996-07-15",
        "nepDate": "31.03.2053"
    },
    {
        "engDate": "1996-07-16",
        "nepDate": "01.04.2053"
    },
    {
        "engDate": "1996-07-17",
        "nepDate": "02.04.2053"
    },
    {
        "engDate": "1996-07-18",
        "nepDate": "03.04.2053"
    },
    {
        "engDate": "1996-07-19",
        "nepDate": "04.04.2053"
    },
    {
        "engDate": "1996-07-20",
        "nepDate": "05.04.2053"
    },
    {
        "engDate": "1996-07-21",
        "nepDate": "06.04.2053"
    },
    {
        "engDate": "1996-07-22",
        "nepDate": "07.04.2053"
    },
    {
        "engDate": "1996-07-23",
        "nepDate": "08.04.2053"
    },
    {
        "engDate": "1996-07-24",
        "nepDate": "09.04.2053"
    },
    {
        "engDate": "1996-07-25",
        "nepDate": "10.04.2053"
    },
    {
        "engDate": "1996-07-26",
        "nepDate": "11.04.2053"
    },
    {
        "engDate": "1996-07-27",
        "nepDate": "12.04.2053"
    },
    {
        "engDate": "1996-07-28",
        "nepDate": "13.04.2053"
    },
    {
        "engDate": "1996-07-29",
        "nepDate": "14.04.2053"
    },
    {
        "engDate": "1996-07-30",
        "nepDate": "15.04.2053"
    },
    {
        "engDate": "1996-07-31",
        "nepDate": "16.04.2053"
    },
    {
        "engDate": "1996-08-01",
        "nepDate": "17.04.2053"
    },
    {
        "engDate": "1996-08-02",
        "nepDate": "18.04.2053"
    },
    {
        "engDate": "1996-08-03",
        "nepDate": "19.04.2053"
    },
    {
        "engDate": "1996-08-04",
        "nepDate": "20.04.2053"
    },
    {
        "engDate": "1996-08-05",
        "nepDate": "21.04.2053"
    },
    {
        "engDate": "1996-08-06",
        "nepDate": "22.04.2053"
    },
    {
        "engDate": "1996-08-07",
        "nepDate": "23.04.2053"
    },
    {
        "engDate": "1996-08-08",
        "nepDate": "24.04.2053"
    },
    {
        "engDate": "1996-08-09",
        "nepDate": "25.04.2053"
    },
    {
        "engDate": "1996-08-10",
        "nepDate": "26.04.2053"
    },
    {
        "engDate": "1996-08-11",
        "nepDate": "27.04.2053"
    },
    {
        "engDate": "1996-08-12",
        "nepDate": "28.04.2053"
    },
    {
        "engDate": "1996-08-13",
        "nepDate": "29.04.2053"
    },
    {
        "engDate": "1996-08-14",
        "nepDate": "30.04.2053"
    },
    {
        "engDate": "1996-08-15",
        "nepDate": "31.04.2053"
    },
    {
        "engDate": "1996-08-16",
        "nepDate": "32.04.2053"
    },
    {
        "engDate": "1996-08-17",
        "nepDate": "01.05.2053"
    },
    {
        "engDate": "1996-08-18",
        "nepDate": "02.05.2053"
    },
    {
        "engDate": "1996-08-19",
        "nepDate": "03.05.2053"
    },
    {
        "engDate": "1996-08-20",
        "nepDate": "04.05.2053"
    },
    {
        "engDate": "1996-08-21",
        "nepDate": "05.05.2053"
    },
    {
        "engDate": "1996-08-22",
        "nepDate": "06.05.2053"
    },
    {
        "engDate": "1996-08-23",
        "nepDate": "07.05.2053"
    },
    {
        "engDate": "1996-08-24",
        "nepDate": "08.05.2053"
    },
    {
        "engDate": "1996-08-25",
        "nepDate": "09.05.2053"
    },
    {
        "engDate": "1996-08-26",
        "nepDate": "10.05.2053"
    },
    {
        "engDate": "1996-08-27",
        "nepDate": "11.05.2053"
    },
    {
        "engDate": "1996-08-28",
        "nepDate": "12.05.2053"
    },
    {
        "engDate": "1996-08-29",
        "nepDate": "13.05.2053"
    },
    {
        "engDate": "1996-08-30",
        "nepDate": "14.05.2053"
    },
    {
        "engDate": "1996-08-31",
        "nepDate": "15.05.2053"
    },
    {
        "engDate": "1996-09-01",
        "nepDate": "16.05.2053"
    },
    {
        "engDate": "1996-09-02",
        "nepDate": "17.05.2053"
    },
    {
        "engDate": "1996-09-03",
        "nepDate": "18.05.2053"
    },
    {
        "engDate": "1996-09-04",
        "nepDate": "19.05.2053"
    },
    {
        "engDate": "1996-09-05",
        "nepDate": "20.05.2053"
    },
    {
        "engDate": "1996-09-06",
        "nepDate": "21.05.2053"
    },
    {
        "engDate": "1996-09-07",
        "nepDate": "22.05.2053"
    },
    {
        "engDate": "1996-09-08",
        "nepDate": "23.05.2053"
    },
    {
        "engDate": "1996-09-09",
        "nepDate": "24.05.2053"
    },
    {
        "engDate": "1996-09-10",
        "nepDate": "25.05.2053"
    },
    {
        "engDate": "1996-09-11",
        "nepDate": "26.05.2053"
    },
    {
        "engDate": "1996-09-12",
        "nepDate": "27.05.2053"
    },
    {
        "engDate": "1996-09-13",
        "nepDate": "28.05.2053"
    },
    {
        "engDate": "1996-09-14",
        "nepDate": "29.05.2053"
    },
    {
        "engDate": "1996-09-15",
        "nepDate": "30.05.2053"
    },
    {
        "engDate": "1996-09-16",
        "nepDate": "31.05.2053"
    },
    {
        "engDate": "1996-09-17",
        "nepDate": "01.06.2053"
    },
    {
        "engDate": "1996-09-18",
        "nepDate": "02.06.2053"
    },
    {
        "engDate": "1996-09-19",
        "nepDate": "03.06.2053"
    },
    {
        "engDate": "1996-09-20",
        "nepDate": "04.06.2053"
    },
    {
        "engDate": "1996-09-21",
        "nepDate": "05.06.2053"
    },
    {
        "engDate": "1996-09-22",
        "nepDate": "06.06.2053"
    },
    {
        "engDate": "1996-09-23",
        "nepDate": "07.06.2053"
    },
    {
        "engDate": "1996-09-24",
        "nepDate": "08.06.2053"
    },
    {
        "engDate": "1996-09-25",
        "nepDate": "09.06.2053"
    },
    {
        "engDate": "1996-09-26",
        "nepDate": "10.06.2053"
    },
    {
        "engDate": "1996-09-27",
        "nepDate": "11.06.2053"
    },
    {
        "engDate": "1996-09-28",
        "nepDate": "12.06.2053"
    },
    {
        "engDate": "1996-09-29",
        "nepDate": "13.06.2053"
    },
    {
        "engDate": "1996-09-30",
        "nepDate": "14.06.2053"
    },
    {
        "engDate": "1996-10-01",
        "nepDate": "15.06.2053"
    },
    {
        "engDate": "1996-10-02",
        "nepDate": "16.06.2053"
    },
    {
        "engDate": "1996-10-03",
        "nepDate": "17.06.2053"
    },
    {
        "engDate": "1996-10-04",
        "nepDate": "18.06.2053"
    },
    {
        "engDate": "1996-10-05",
        "nepDate": "19.06.2053"
    },
    {
        "engDate": "1996-10-06",
        "nepDate": "20.06.2053"
    },
    {
        "engDate": "1996-10-07",
        "nepDate": "21.06.2053"
    },
    {
        "engDate": "1996-10-08",
        "nepDate": "22.06.2053"
    },
    {
        "engDate": "1996-10-09",
        "nepDate": "23.06.2053"
    },
    {
        "engDate": "1996-10-10",
        "nepDate": "24.06.2053"
    },
    {
        "engDate": "1996-10-11",
        "nepDate": "25.06.2053"
    },
    {
        "engDate": "1996-10-12",
        "nepDate": "26.06.2053"
    },
    {
        "engDate": "1996-10-13",
        "nepDate": "27.06.2053"
    },
    {
        "engDate": "1996-10-14",
        "nepDate": "28.06.2053"
    },
    {
        "engDate": "1996-10-15",
        "nepDate": "29.06.2053"
    },
    {
        "engDate": "1996-10-16",
        "nepDate": "30.06.2053"
    },
    {
        "engDate": "1996-10-17",
        "nepDate": "01.07.2053"
    },
    {
        "engDate": "1996-10-18",
        "nepDate": "02.07.2053"
    },
    {
        "engDate": "1996-10-19",
        "nepDate": "03.07.2053"
    },
    {
        "engDate": "1996-10-20",
        "nepDate": "04.07.2053"
    },
    {
        "engDate": "1996-10-21",
        "nepDate": "05.07.2053"
    },
    {
        "engDate": "1996-10-22",
        "nepDate": "06.07.2053"
    },
    {
        "engDate": "1996-10-23",
        "nepDate": "07.07.2053"
    },
    {
        "engDate": "1996-10-24",
        "nepDate": "08.07.2053"
    },
    {
        "engDate": "1996-10-25",
        "nepDate": "09.07.2053"
    },
    {
        "engDate": "1996-10-26",
        "nepDate": "10.07.2053"
    },
    {
        "engDate": "1996-10-27",
        "nepDate": "11.07.2053"
    },
    {
        "engDate": "1996-10-28",
        "nepDate": "12.07.2053"
    },
    {
        "engDate": "1996-10-29",
        "nepDate": "13.07.2053"
    },
    {
        "engDate": "1996-10-30",
        "nepDate": "14.07.2053"
    },
    {
        "engDate": "1996-10-31",
        "nepDate": "15.07.2053"
    },
    {
        "engDate": "1996-11-01",
        "nepDate": "16.07.2053"
    },
    {
        "engDate": "1996-11-02",
        "nepDate": "17.07.2053"
    },
    {
        "engDate": "1996-11-03",
        "nepDate": "18.07.2053"
    },
    {
        "engDate": "1996-11-04",
        "nepDate": "19.07.2053"
    },
    {
        "engDate": "1996-11-05",
        "nepDate": "20.07.2053"
    },
    {
        "engDate": "1996-11-06",
        "nepDate": "21.07.2053"
    },
    {
        "engDate": "1996-11-07",
        "nepDate": "22.07.2053"
    },
    {
        "engDate": "1996-11-08",
        "nepDate": "23.07.2053"
    },
    {
        "engDate": "1996-11-09",
        "nepDate": "24.07.2053"
    },
    {
        "engDate": "1996-11-10",
        "nepDate": "25.07.2053"
    },
    {
        "engDate": "1996-11-11",
        "nepDate": "26.07.2053"
    },
    {
        "engDate": "1996-11-12",
        "nepDate": "27.07.2053"
    },
    {
        "engDate": "1996-11-13",
        "nepDate": "28.07.2053"
    },
    {
        "engDate": "1996-11-14",
        "nepDate": "29.07.2053"
    },
    {
        "engDate": "1996-11-15",
        "nepDate": "30.07.2053"
    },
    {
        "engDate": "1996-11-16",
        "nepDate": "01.08.2053"
    },
    {
        "engDate": "1996-11-17",
        "nepDate": "02.08.2053"
    },
    {
        "engDate": "1996-11-18",
        "nepDate": "03.08.2053"
    },
    {
        "engDate": "1996-11-19",
        "nepDate": "04.08.2053"
    },
    {
        "engDate": "1996-11-20",
        "nepDate": "05.08.2053"
    },
    {
        "engDate": "1996-11-21",
        "nepDate": "06.08.2053"
    },
    {
        "engDate": "1996-11-22",
        "nepDate": "07.08.2053"
    },
    {
        "engDate": "1996-11-23",
        "nepDate": "08.08.2053"
    },
    {
        "engDate": "1996-11-24",
        "nepDate": "09.08.2053"
    },
    {
        "engDate": "1996-11-25",
        "nepDate": "10.08.2053"
    },
    {
        "engDate": "1996-11-26",
        "nepDate": "11.08.2053"
    },
    {
        "engDate": "1996-11-27",
        "nepDate": "12.08.2053"
    },
    {
        "engDate": "1996-11-28",
        "nepDate": "13.08.2053"
    },
    {
        "engDate": "1996-11-29",
        "nepDate": "14.08.2053"
    },
    {
        "engDate": "1996-11-30",
        "nepDate": "15.08.2053"
    },
    {
        "engDate": "1996-12-01",
        "nepDate": "16.08.2053"
    },
    {
        "engDate": "1996-12-02",
        "nepDate": "17.08.2053"
    },
    {
        "engDate": "1996-12-03",
        "nepDate": "18.08.2053"
    },
    {
        "engDate": "1996-12-04",
        "nepDate": "19.08.2053"
    },
    {
        "engDate": "1996-12-05",
        "nepDate": "20.08.2053"
    },
    {
        "engDate": "1996-12-06",
        "nepDate": "21.08.2053"
    },
    {
        "engDate": "1996-12-07",
        "nepDate": "22.08.2053"
    },
    {
        "engDate": "1996-12-08",
        "nepDate": "23.08.2053"
    },
    {
        "engDate": "1996-12-09",
        "nepDate": "24.08.2053"
    },
    {
        "engDate": "1996-12-10",
        "nepDate": "25.08.2053"
    },
    {
        "engDate": "1996-12-11",
        "nepDate": "26.08.2053"
    },
    {
        "engDate": "1996-12-12",
        "nepDate": "27.08.2053"
    },
    {
        "engDate": "1996-12-13",
        "nepDate": "28.08.2053"
    },
    {
        "engDate": "1996-12-14",
        "nepDate": "29.08.2053"
    },
    {
        "engDate": "1996-12-15",
        "nepDate": "30.08.2053"
    },
    {
        "engDate": "1996-12-16",
        "nepDate": "01.09.2053"
    },
    {
        "engDate": "1996-12-17",
        "nepDate": "02.09.2053"
    },
    {
        "engDate": "1996-12-18",
        "nepDate": "03.09.2053"
    },
    {
        "engDate": "1996-12-19",
        "nepDate": "04.09.2053"
    },
    {
        "engDate": "1996-12-20",
        "nepDate": "05.09.2053"
    },
    {
        "engDate": "1996-12-21",
        "nepDate": "06.09.2053"
    },
    {
        "engDate": "1996-12-22",
        "nepDate": "07.09.2053"
    },
    {
        "engDate": "1996-12-23",
        "nepDate": "08.09.2053"
    },
    {
        "engDate": "1996-12-24",
        "nepDate": "09.09.2053"
    },
    {
        "engDate": "1996-12-25",
        "nepDate": "10.09.2053"
    },
    {
        "engDate": "1996-12-26",
        "nepDate": "11.09.2053"
    },
    {
        "engDate": "1996-12-27",
        "nepDate": "12.09.2053"
    },
    {
        "engDate": "1996-12-28",
        "nepDate": "13.09.2053"
    },
    {
        "engDate": "1996-12-29",
        "nepDate": "14.09.2053"
    },
    {
        "engDate": "1996-12-30",
        "nepDate": "15.09.2053"
    },
    {
        "engDate": "1996-12-31",
        "nepDate": "16.09.2053"
    },
    {
        "engDate": "1997-01-01",
        "nepDate": "17.09.2053"
    },
    {
        "engDate": "1997-01-02",
        "nepDate": "18.09.2053"
    },
    {
        "engDate": "1997-01-03",
        "nepDate": "19.09.2053"
    },
    {
        "engDate": "1997-01-04",
        "nepDate": "20.09.2053"
    },
    {
        "engDate": "1997-01-05",
        "nepDate": "21.09.2053"
    },
    {
        "engDate": "1997-01-06",
        "nepDate": "22.09.2053"
    },
    {
        "engDate": "1997-01-07",
        "nepDate": "23.09.2053"
    },
    {
        "engDate": "1997-01-08",
        "nepDate": "24.09.2053"
    },
    {
        "engDate": "1997-01-09",
        "nepDate": "25.09.2053"
    },
    {
        "engDate": "1997-01-10",
        "nepDate": "26.09.2053"
    },
    {
        "engDate": "1997-01-11",
        "nepDate": "27.09.2053"
    },
    {
        "engDate": "1997-01-12",
        "nepDate": "28.09.2053"
    },
    {
        "engDate": "1997-01-13",
        "nepDate": "29.09.2053"
    },
    {
        "engDate": "1997-01-14",
        "nepDate": "01.10.2053"
    },
    {
        "engDate": "1997-01-15",
        "nepDate": "02.10.2053"
    },
    {
        "engDate": "1997-01-16",
        "nepDate": "03.10.2053"
    },
    {
        "engDate": "1997-01-17",
        "nepDate": "04.10.2053"
    },
    {
        "engDate": "1997-01-18",
        "nepDate": "05.10.2053"
    },
    {
        "engDate": "1997-01-19",
        "nepDate": "06.10.2053"
    },
    {
        "engDate": "1997-01-20",
        "nepDate": "07.10.2053"
    },
    {
        "engDate": "1997-01-21",
        "nepDate": "08.10.2053"
    },
    {
        "engDate": "1997-01-22",
        "nepDate": "09.10.2053"
    },
    {
        "engDate": "1997-01-23",
        "nepDate": "10.10.2053"
    },
    {
        "engDate": "1997-01-24",
        "nepDate": "11.10.2053"
    },
    {
        "engDate": "1997-01-25",
        "nepDate": "12.10.2053"
    },
    {
        "engDate": "1997-01-26",
        "nepDate": "13.10.2053"
    },
    {
        "engDate": "1997-01-27",
        "nepDate": "14.10.2053"
    },
    {
        "engDate": "1997-01-28",
        "nepDate": "15.10.2053"
    },
    {
        "engDate": "1997-01-29",
        "nepDate": "16.10.2053"
    },
    {
        "engDate": "1997-01-30",
        "nepDate": "17.10.2053"
    },
    {
        "engDate": "1997-01-31",
        "nepDate": "18.10.2053"
    },
    {
        "engDate": "1997-02-01",
        "nepDate": "19.10.2053"
    },
    {
        "engDate": "1997-02-02",
        "nepDate": "20.10.2053"
    },
    {
        "engDate": "1997-02-03",
        "nepDate": "21.10.2053"
    },
    {
        "engDate": "1997-02-04",
        "nepDate": "22.10.2053"
    },
    {
        "engDate": "1997-02-05",
        "nepDate": "23.10.2053"
    },
    {
        "engDate": "1997-02-06",
        "nepDate": "24.10.2053"
    },
    {
        "engDate": "1997-02-07",
        "nepDate": "25.10.2053"
    },
    {
        "engDate": "1997-02-08",
        "nepDate": "26.10.2053"
    },
    {
        "engDate": "1997-02-09",
        "nepDate": "27.10.2053"
    },
    {
        "engDate": "1997-02-10",
        "nepDate": "28.10.2053"
    },
    {
        "engDate": "1997-02-11",
        "nepDate": "29.10.2053"
    },
    {
        "engDate": "1997-02-12",
        "nepDate": "01.11.2053"
    },
    {
        "engDate": "1997-02-13",
        "nepDate": "02.11.2053"
    },
    {
        "engDate": "1997-02-14",
        "nepDate": "03.11.2053"
    },
    {
        "engDate": "1997-02-15",
        "nepDate": "04.11.2053"
    },
    {
        "engDate": "1997-02-16",
        "nepDate": "05.11.2053"
    },
    {
        "engDate": "1997-02-17",
        "nepDate": "06.11.2053"
    },
    {
        "engDate": "1997-02-18",
        "nepDate": "07.11.2053"
    },
    {
        "engDate": "1997-02-19",
        "nepDate": "08.11.2053"
    },
    {
        "engDate": "1997-02-20",
        "nepDate": "09.11.2053"
    },
    {
        "engDate": "1997-02-21",
        "nepDate": "10.11.2053"
    },
    {
        "engDate": "1997-02-22",
        "nepDate": "11.11.2053"
    },
    {
        "engDate": "1997-02-23",
        "nepDate": "12.11.2053"
    },
    {
        "engDate": "1997-02-24",
        "nepDate": "13.11.2053"
    },
    {
        "engDate": "1997-02-25",
        "nepDate": "14.11.2053"
    },
    {
        "engDate": "1997-02-26",
        "nepDate": "15.11.2053"
    },
    {
        "engDate": "1997-02-27",
        "nepDate": "16.11.2053"
    },
    {
        "engDate": "1997-02-28",
        "nepDate": "17.11.2053"
    },
    {
        "engDate": "1997-03-01",
        "nepDate": "18.11.2053"
    },
    {
        "engDate": "1997-03-02",
        "nepDate": "19.11.2053"
    },
    {
        "engDate": "1997-03-03",
        "nepDate": "20.11.2053"
    },
    {
        "engDate": "1997-03-04",
        "nepDate": "21.11.2053"
    },
    {
        "engDate": "1997-03-05",
        "nepDate": "22.11.2053"
    },
    {
        "engDate": "1997-03-06",
        "nepDate": "23.11.2053"
    },
    {
        "engDate": "1997-03-07",
        "nepDate": "24.11.2053"
    },
    {
        "engDate": "1997-03-08",
        "nepDate": "25.11.2053"
    },
    {
        "engDate": "1997-03-09",
        "nepDate": "26.11.2053"
    },
    {
        "engDate": "1997-03-10",
        "nepDate": "27.11.2053"
    },
    {
        "engDate": "1997-03-11",
        "nepDate": "28.11.2053"
    },
    {
        "engDate": "1997-03-12",
        "nepDate": "29.11.2053"
    },
    {
        "engDate": "1997-03-13",
        "nepDate": "30.11.2053"
    },
    {
        "engDate": "1997-03-14",
        "nepDate": "01.12.2053"
    },
    {
        "engDate": "1997-03-15",
        "nepDate": "02.12.2053"
    },
    {
        "engDate": "1997-03-16",
        "nepDate": "03.12.2053"
    },
    {
        "engDate": "1997-03-17",
        "nepDate": "04.12.2053"
    },
    {
        "engDate": "1997-03-18",
        "nepDate": "05.12.2053"
    },
    {
        "engDate": "1997-03-19",
        "nepDate": "06.12.2053"
    },
    {
        "engDate": "1997-03-20",
        "nepDate": "07.12.2053"
    },
    {
        "engDate": "1997-03-21",
        "nepDate": "08.12.2053"
    },
    {
        "engDate": "1997-03-22",
        "nepDate": "09.12.2053"
    },
    {
        "engDate": "1997-03-23",
        "nepDate": "10.12.2053"
    },
    {
        "engDate": "1997-03-24",
        "nepDate": "11.12.2053"
    },
    {
        "engDate": "1997-03-25",
        "nepDate": "12.12.2053"
    },
    {
        "engDate": "1997-03-26",
        "nepDate": "13.12.2053"
    },
    {
        "engDate": "1997-03-27",
        "nepDate": "14.12.2053"
    },
    {
        "engDate": "1997-03-28",
        "nepDate": "15.12.2053"
    },
    {
        "engDate": "1997-03-29",
        "nepDate": "16.12.2053"
    },
    {
        "engDate": "1997-03-30",
        "nepDate": "17.12.2053"
    },
    {
        "engDate": "1997-03-31",
        "nepDate": "18.12.2053"
    },
    {
        "engDate": "1997-04-01",
        "nepDate": "19.12.2053"
    },
    {
        "engDate": "1997-04-02",
        "nepDate": "20.12.2053"
    },
    {
        "engDate": "1997-04-03",
        "nepDate": "21.12.2053"
    },
    {
        "engDate": "1997-04-04",
        "nepDate": "22.12.2053"
    },
    {
        "engDate": "1997-04-05",
        "nepDate": "23.12.2053"
    },
    {
        "engDate": "1997-04-06",
        "nepDate": "24.12.2053"
    },
    {
        "engDate": "1997-04-07",
        "nepDate": "25.12.2053"
    },
    {
        "engDate": "1997-04-08",
        "nepDate": "26.12.2053"
    },
    {
        "engDate": "1997-04-09",
        "nepDate": "27.12.2053"
    },
    {
        "engDate": "1997-04-10",
        "nepDate": "28.12.2053"
    },
    {
        "engDate": "1997-04-11",
        "nepDate": "29.12.2053"
    },
    {
        "engDate": "1997-04-12",
        "nepDate": "30.12.2053"
    },
    {
        "engDate": "1997-04-13",
        "nepDate": "01.01.2054"
    },
    {
        "engDate": "1997-04-14",
        "nepDate": "02.01.2054"
    },
    {
        "engDate": "1997-04-15",
        "nepDate": "03.01.2054"
    },
    {
        "engDate": "1997-04-16",
        "nepDate": "04.01.2054"
    },
    {
        "engDate": "1997-04-17",
        "nepDate": "05.01.2054"
    },
    {
        "engDate": "1997-04-18",
        "nepDate": "06.01.2054"
    },
    {
        "engDate": "1997-04-19",
        "nepDate": "07.01.2054"
    },
    {
        "engDate": "1997-04-20",
        "nepDate": "08.01.2054"
    },
    {
        "engDate": "1997-04-21",
        "nepDate": "09.01.2054"
    },
    {
        "engDate": "1997-04-22",
        "nepDate": "10.01.2054"
    },
    {
        "engDate": "1997-04-23",
        "nepDate": "11.01.2054"
    },
    {
        "engDate": "1997-04-24",
        "nepDate": "12.01.2054"
    },
    {
        "engDate": "1997-04-25",
        "nepDate": "13.01.2054"
    },
    {
        "engDate": "1997-04-26",
        "nepDate": "14.01.2054"
    },
    {
        "engDate": "1997-04-27",
        "nepDate": "15.01.2054"
    },
    {
        "engDate": "1997-04-28",
        "nepDate": "16.01.2054"
    },
    {
        "engDate": "1997-04-29",
        "nepDate": "17.01.2054"
    },
    {
        "engDate": "1997-04-30",
        "nepDate": "18.01.2054"
    },
    {
        "engDate": "1997-05-01",
        "nepDate": "19.01.2054"
    },
    {
        "engDate": "1997-05-02",
        "nepDate": "20.01.2054"
    },
    {
        "engDate": "1997-05-03",
        "nepDate": "21.01.2054"
    },
    {
        "engDate": "1997-05-04",
        "nepDate": "22.01.2054"
    },
    {
        "engDate": "1997-05-05",
        "nepDate": "23.01.2054"
    },
    {
        "engDate": "1997-05-06",
        "nepDate": "24.01.2054"
    },
    {
        "engDate": "1997-05-07",
        "nepDate": "25.01.2054"
    },
    {
        "engDate": "1997-05-08",
        "nepDate": "26.01.2054"
    },
    {
        "engDate": "1997-05-09",
        "nepDate": "27.01.2054"
    },
    {
        "engDate": "1997-05-10",
        "nepDate": "28.01.2054"
    },
    {
        "engDate": "1997-05-11",
        "nepDate": "29.01.2054"
    },
    {
        "engDate": "1997-05-12",
        "nepDate": "30.01.2054"
    },
    {
        "engDate": "1997-05-13",
        "nepDate": "31.01.2054"
    },
    {
        "engDate": "1997-05-14",
        "nepDate": "01.02.2054"
    },
    {
        "engDate": "1997-05-15",
        "nepDate": "02.02.2054"
    },
    {
        "engDate": "1997-05-16",
        "nepDate": "03.02.2054"
    },
    {
        "engDate": "1997-05-17",
        "nepDate": "04.02.2054"
    },
    {
        "engDate": "1997-05-18",
        "nepDate": "05.02.2054"
    },
    {
        "engDate": "1997-05-19",
        "nepDate": "06.02.2054"
    },
    {
        "engDate": "1997-05-20",
        "nepDate": "07.02.2054"
    },
    {
        "engDate": "1997-05-21",
        "nepDate": "08.02.2054"
    },
    {
        "engDate": "1997-05-22",
        "nepDate": "09.02.2054"
    },
    {
        "engDate": "1997-05-23",
        "nepDate": "10.02.2054"
    },
    {
        "engDate": "1997-05-24",
        "nepDate": "11.02.2054"
    },
    {
        "engDate": "1997-05-25",
        "nepDate": "12.02.2054"
    },
    {
        "engDate": "1997-05-26",
        "nepDate": "13.02.2054"
    },
    {
        "engDate": "1997-05-27",
        "nepDate": "14.02.2054"
    },
    {
        "engDate": "1997-05-28",
        "nepDate": "15.02.2054"
    },
    {
        "engDate": "1997-05-29",
        "nepDate": "16.02.2054"
    },
    {
        "engDate": "1997-05-30",
        "nepDate": "17.02.2054"
    },
    {
        "engDate": "1997-05-31",
        "nepDate": "18.02.2054"
    },
    {
        "engDate": "1997-06-01",
        "nepDate": "19.02.2054"
    },
    {
        "engDate": "1997-06-02",
        "nepDate": "20.02.2054"
    },
    {
        "engDate": "1997-06-03",
        "nepDate": "21.02.2054"
    },
    {
        "engDate": "1997-06-04",
        "nepDate": "22.02.2054"
    },
    {
        "engDate": "1997-06-05",
        "nepDate": "23.02.2054"
    },
    {
        "engDate": "1997-06-06",
        "nepDate": "24.02.2054"
    },
    {
        "engDate": "1997-06-07",
        "nepDate": "25.02.2054"
    },
    {
        "engDate": "1997-06-08",
        "nepDate": "26.02.2054"
    },
    {
        "engDate": "1997-06-09",
        "nepDate": "27.02.2054"
    },
    {
        "engDate": "1997-06-10",
        "nepDate": "28.02.2054"
    },
    {
        "engDate": "1997-06-11",
        "nepDate": "29.02.2054"
    },
    {
        "engDate": "1997-06-12",
        "nepDate": "30.02.2054"
    },
    {
        "engDate": "1997-06-13",
        "nepDate": "31.02.2054"
    },
    {
        "engDate": "1997-06-14",
        "nepDate": "32.02.2054"
    },
    {
        "engDate": "1997-06-15",
        "nepDate": "01.03.2054"
    },
    {
        "engDate": "1997-06-16",
        "nepDate": "02.03.2054"
    },
    {
        "engDate": "1997-06-17",
        "nepDate": "03.03.2054"
    },
    {
        "engDate": "1997-06-18",
        "nepDate": "04.03.2054"
    },
    {
        "engDate": "1997-06-19",
        "nepDate": "05.03.2054"
    },
    {
        "engDate": "1997-06-20",
        "nepDate": "06.03.2054"
    },
    {
        "engDate": "1997-06-21",
        "nepDate": "07.03.2054"
    },
    {
        "engDate": "1997-06-22",
        "nepDate": "08.03.2054"
    },
    {
        "engDate": "1997-06-23",
        "nepDate": "09.03.2054"
    },
    {
        "engDate": "1997-06-24",
        "nepDate": "10.03.2054"
    },
    {
        "engDate": "1997-06-25",
        "nepDate": "11.03.2054"
    },
    {
        "engDate": "1997-06-26",
        "nepDate": "12.03.2054"
    },
    {
        "engDate": "1997-06-27",
        "nepDate": "13.03.2054"
    },
    {
        "engDate": "1997-06-28",
        "nepDate": "14.03.2054"
    },
    {
        "engDate": "1997-06-29",
        "nepDate": "15.03.2054"
    },
    {
        "engDate": "1997-06-30",
        "nepDate": "16.03.2054"
    },
    {
        "engDate": "1997-07-01",
        "nepDate": "17.03.2054"
    },
    {
        "engDate": "1997-07-02",
        "nepDate": "18.03.2054"
    },
    {
        "engDate": "1997-07-03",
        "nepDate": "19.03.2054"
    },
    {
        "engDate": "1997-07-04",
        "nepDate": "20.03.2054"
    },
    {
        "engDate": "1997-07-05",
        "nepDate": "21.03.2054"
    },
    {
        "engDate": "1997-07-06",
        "nepDate": "22.03.2054"
    },
    {
        "engDate": "1997-07-07",
        "nepDate": "23.03.2054"
    },
    {
        "engDate": "1997-07-08",
        "nepDate": "24.03.2054"
    },
    {
        "engDate": "1997-07-09",
        "nepDate": "25.03.2054"
    },
    {
        "engDate": "1997-07-10",
        "nepDate": "26.03.2054"
    },
    {
        "engDate": "1997-07-11",
        "nepDate": "27.03.2054"
    },
    {
        "engDate": "1997-07-12",
        "nepDate": "28.03.2054"
    },
    {
        "engDate": "1997-07-13",
        "nepDate": "29.03.2054"
    },
    {
        "engDate": "1997-07-14",
        "nepDate": "30.03.2054"
    },
    {
        "engDate": "1997-07-15",
        "nepDate": "31.03.2054"
    },
    {
        "engDate": "1997-07-16",
        "nepDate": "01.04.2054"
    },
    {
        "engDate": "1997-07-17",
        "nepDate": "02.04.2054"
    },
    {
        "engDate": "1997-07-18",
        "nepDate": "03.04.2054"
    },
    {
        "engDate": "1997-07-19",
        "nepDate": "04.04.2054"
    },
    {
        "engDate": "1997-07-20",
        "nepDate": "05.04.2054"
    },
    {
        "engDate": "1997-07-21",
        "nepDate": "06.04.2054"
    },
    {
        "engDate": "1997-07-22",
        "nepDate": "07.04.2054"
    },
    {
        "engDate": "1997-07-23",
        "nepDate": "08.04.2054"
    },
    {
        "engDate": "1997-07-24",
        "nepDate": "09.04.2054"
    },
    {
        "engDate": "1997-07-25",
        "nepDate": "10.04.2054"
    },
    {
        "engDate": "1997-07-26",
        "nepDate": "11.04.2054"
    },
    {
        "engDate": "1997-07-27",
        "nepDate": "12.04.2054"
    },
    {
        "engDate": "1997-07-28",
        "nepDate": "13.04.2054"
    },
    {
        "engDate": "1997-07-29",
        "nepDate": "14.04.2054"
    },
    {
        "engDate": "1997-07-30",
        "nepDate": "15.04.2054"
    },
    {
        "engDate": "1997-07-31",
        "nepDate": "16.04.2054"
    },
    {
        "engDate": "1997-08-01",
        "nepDate": "17.04.2054"
    },
    {
        "engDate": "1997-08-02",
        "nepDate": "18.04.2054"
    },
    {
        "engDate": "1997-08-03",
        "nepDate": "19.04.2054"
    },
    {
        "engDate": "1997-08-04",
        "nepDate": "20.04.2054"
    },
    {
        "engDate": "1997-08-05",
        "nepDate": "21.04.2054"
    },
    {
        "engDate": "1997-08-06",
        "nepDate": "22.04.2054"
    },
    {
        "engDate": "1997-08-07",
        "nepDate": "23.04.2054"
    },
    {
        "engDate": "1997-08-08",
        "nepDate": "24.04.2054"
    },
    {
        "engDate": "1997-08-09",
        "nepDate": "25.04.2054"
    },
    {
        "engDate": "1997-08-10",
        "nepDate": "26.04.2054"
    },
    {
        "engDate": "1997-08-11",
        "nepDate": "27.04.2054"
    },
    {
        "engDate": "1997-08-12",
        "nepDate": "28.04.2054"
    },
    {
        "engDate": "1997-08-13",
        "nepDate": "29.04.2054"
    },
    {
        "engDate": "1997-08-14",
        "nepDate": "30.04.2054"
    },
    {
        "engDate": "1997-08-15",
        "nepDate": "31.04.2054"
    },
    {
        "engDate": "1997-08-16",
        "nepDate": "32.04.2054"
    },
    {
        "engDate": "1997-08-17",
        "nepDate": "01.05.2054"
    },
    {
        "engDate": "1997-08-18",
        "nepDate": "02.05.2054"
    },
    {
        "engDate": "1997-08-19",
        "nepDate": "03.05.2054"
    },
    {
        "engDate": "1997-08-20",
        "nepDate": "04.05.2054"
    },
    {
        "engDate": "1997-08-21",
        "nepDate": "05.05.2054"
    },
    {
        "engDate": "1997-08-22",
        "nepDate": "06.05.2054"
    },
    {
        "engDate": "1997-08-23",
        "nepDate": "07.05.2054"
    },
    {
        "engDate": "1997-08-24",
        "nepDate": "08.05.2054"
    },
    {
        "engDate": "1997-08-25",
        "nepDate": "09.05.2054"
    },
    {
        "engDate": "1997-08-26",
        "nepDate": "10.05.2054"
    },
    {
        "engDate": "1997-08-27",
        "nepDate": "11.05.2054"
    },
    {
        "engDate": "1997-08-28",
        "nepDate": "12.05.2054"
    },
    {
        "engDate": "1997-08-29",
        "nepDate": "13.05.2054"
    },
    {
        "engDate": "1997-08-30",
        "nepDate": "14.05.2054"
    },
    {
        "engDate": "1997-08-31",
        "nepDate": "15.05.2054"
    },
    {
        "engDate": "1997-09-01",
        "nepDate": "16.05.2054"
    },
    {
        "engDate": "1997-09-02",
        "nepDate": "17.05.2054"
    },
    {
        "engDate": "1997-09-03",
        "nepDate": "18.05.2054"
    },
    {
        "engDate": "1997-09-04",
        "nepDate": "19.05.2054"
    },
    {
        "engDate": "1997-09-05",
        "nepDate": "20.05.2054"
    },
    {
        "engDate": "1997-09-06",
        "nepDate": "21.05.2054"
    },
    {
        "engDate": "1997-09-07",
        "nepDate": "22.05.2054"
    },
    {
        "engDate": "1997-09-08",
        "nepDate": "23.05.2054"
    },
    {
        "engDate": "1997-09-09",
        "nepDate": "24.05.2054"
    },
    {
        "engDate": "1997-09-10",
        "nepDate": "25.05.2054"
    },
    {
        "engDate": "1997-09-11",
        "nepDate": "26.05.2054"
    },
    {
        "engDate": "1997-09-12",
        "nepDate": "27.05.2054"
    },
    {
        "engDate": "1997-09-13",
        "nepDate": "28.05.2054"
    },
    {
        "engDate": "1997-09-14",
        "nepDate": "29.05.2054"
    },
    {
        "engDate": "1997-09-15",
        "nepDate": "30.05.2054"
    },
    {
        "engDate": "1997-09-16",
        "nepDate": "31.05.2054"
    },
    {
        "engDate": "1997-09-17",
        "nepDate": "01.06.2054"
    },
    {
        "engDate": "1997-09-18",
        "nepDate": "02.06.2054"
    },
    {
        "engDate": "1997-09-19",
        "nepDate": "03.06.2054"
    },
    {
        "engDate": "1997-09-20",
        "nepDate": "04.06.2054"
    },
    {
        "engDate": "1997-09-21",
        "nepDate": "05.06.2054"
    },
    {
        "engDate": "1997-09-22",
        "nepDate": "06.06.2054"
    },
    {
        "engDate": "1997-09-23",
        "nepDate": "07.06.2054"
    },
    {
        "engDate": "1997-09-24",
        "nepDate": "08.06.2054"
    },
    {
        "engDate": "1997-09-25",
        "nepDate": "09.06.2054"
    },
    {
        "engDate": "1997-09-26",
        "nepDate": "10.06.2054"
    },
    {
        "engDate": "1997-09-27",
        "nepDate": "11.06.2054"
    },
    {
        "engDate": "1997-09-28",
        "nepDate": "12.06.2054"
    },
    {
        "engDate": "1997-09-29",
        "nepDate": "13.06.2054"
    },
    {
        "engDate": "1997-09-30",
        "nepDate": "14.06.2054"
    },
    {
        "engDate": "1997-10-01",
        "nepDate": "15.06.2054"
    },
    {
        "engDate": "1997-10-02",
        "nepDate": "16.06.2054"
    },
    {
        "engDate": "1997-10-03",
        "nepDate": "17.06.2054"
    },
    {
        "engDate": "1997-10-04",
        "nepDate": "18.06.2054"
    },
    {
        "engDate": "1997-10-05",
        "nepDate": "19.06.2054"
    },
    {
        "engDate": "1997-10-06",
        "nepDate": "20.06.2054"
    },
    {
        "engDate": "1997-10-07",
        "nepDate": "21.06.2054"
    },
    {
        "engDate": "1997-10-08",
        "nepDate": "22.06.2054"
    },
    {
        "engDate": "1997-10-09",
        "nepDate": "23.06.2054"
    },
    {
        "engDate": "1997-10-10",
        "nepDate": "24.06.2054"
    },
    {
        "engDate": "1997-10-11",
        "nepDate": "25.06.2054"
    },
    {
        "engDate": "1997-10-12",
        "nepDate": "26.06.2054"
    },
    {
        "engDate": "1997-10-13",
        "nepDate": "27.06.2054"
    },
    {
        "engDate": "1997-10-14",
        "nepDate": "28.06.2054"
    },
    {
        "engDate": "1997-10-15",
        "nepDate": "29.06.2054"
    },
    {
        "engDate": "1997-10-16",
        "nepDate": "30.06.2054"
    },
    {
        "engDate": "1997-10-17",
        "nepDate": "01.07.2054"
    },
    {
        "engDate": "1997-10-18",
        "nepDate": "02.07.2054"
    },
    {
        "engDate": "1997-10-19",
        "nepDate": "03.07.2054"
    },
    {
        "engDate": "1997-10-20",
        "nepDate": "04.07.2054"
    },
    {
        "engDate": "1997-10-21",
        "nepDate": "05.07.2054"
    },
    {
        "engDate": "1997-10-22",
        "nepDate": "06.07.2054"
    },
    {
        "engDate": "1997-10-23",
        "nepDate": "07.07.2054"
    },
    {
        "engDate": "1997-10-24",
        "nepDate": "08.07.2054"
    },
    {
        "engDate": "1997-10-25",
        "nepDate": "09.07.2054"
    },
    {
        "engDate": "1997-10-26",
        "nepDate": "10.07.2054"
    },
    {
        "engDate": "1997-10-27",
        "nepDate": "11.07.2054"
    },
    {
        "engDate": "1997-10-28",
        "nepDate": "12.07.2054"
    },
    {
        "engDate": "1997-10-29",
        "nepDate": "13.07.2054"
    },
    {
        "engDate": "1997-10-30",
        "nepDate": "14.07.2054"
    },
    {
        "engDate": "1997-10-31",
        "nepDate": "15.07.2054"
    },
    {
        "engDate": "1997-11-01",
        "nepDate": "16.07.2054"
    },
    {
        "engDate": "1997-11-02",
        "nepDate": "17.07.2054"
    },
    {
        "engDate": "1997-11-03",
        "nepDate": "18.07.2054"
    },
    {
        "engDate": "1997-11-04",
        "nepDate": "19.07.2054"
    },
    {
        "engDate": "1997-11-05",
        "nepDate": "20.07.2054"
    },
    {
        "engDate": "1997-11-06",
        "nepDate": "21.07.2054"
    },
    {
        "engDate": "1997-11-07",
        "nepDate": "22.07.2054"
    },
    {
        "engDate": "1997-11-08",
        "nepDate": "23.07.2054"
    },
    {
        "engDate": "1997-11-09",
        "nepDate": "24.07.2054"
    },
    {
        "engDate": "1997-11-10",
        "nepDate": "25.07.2054"
    },
    {
        "engDate": "1997-11-11",
        "nepDate": "26.07.2054"
    },
    {
        "engDate": "1997-11-12",
        "nepDate": "27.07.2054"
    },
    {
        "engDate": "1997-11-13",
        "nepDate": "28.07.2054"
    },
    {
        "engDate": "1997-11-14",
        "nepDate": "29.07.2054"
    },
    {
        "engDate": "1997-11-15",
        "nepDate": "30.07.2054"
    },
    {
        "engDate": "1997-11-16",
        "nepDate": "01.08.2054"
    },
    {
        "engDate": "1997-11-17",
        "nepDate": "02.08.2054"
    },
    {
        "engDate": "1997-11-18",
        "nepDate": "03.08.2054"
    },
    {
        "engDate": "1997-11-19",
        "nepDate": "04.08.2054"
    },
    {
        "engDate": "1997-11-20",
        "nepDate": "05.08.2054"
    },
    {
        "engDate": "1997-11-21",
        "nepDate": "06.08.2054"
    },
    {
        "engDate": "1997-11-22",
        "nepDate": "07.08.2054"
    },
    {
        "engDate": "1997-11-23",
        "nepDate": "08.08.2054"
    },
    {
        "engDate": "1997-11-24",
        "nepDate": "09.08.2054"
    },
    {
        "engDate": "1997-11-25",
        "nepDate": "10.08.2054"
    },
    {
        "engDate": "1997-11-26",
        "nepDate": "11.08.2054"
    },
    {
        "engDate": "1997-11-27",
        "nepDate": "12.08.2054"
    },
    {
        "engDate": "1997-11-28",
        "nepDate": "13.08.2054"
    },
    {
        "engDate": "1997-11-29",
        "nepDate": "14.08.2054"
    },
    {
        "engDate": "1997-11-30",
        "nepDate": "15.08.2054"
    },
    {
        "engDate": "1997-12-01",
        "nepDate": "16.08.2054"
    },
    {
        "engDate": "1997-12-02",
        "nepDate": "17.08.2054"
    },
    {
        "engDate": "1997-12-03",
        "nepDate": "18.08.2054"
    },
    {
        "engDate": "1997-12-04",
        "nepDate": "19.08.2054"
    },
    {
        "engDate": "1997-12-05",
        "nepDate": "20.08.2054"
    },
    {
        "engDate": "1997-12-06",
        "nepDate": "21.08.2054"
    },
    {
        "engDate": "1997-12-07",
        "nepDate": "22.08.2054"
    },
    {
        "engDate": "1997-12-08",
        "nepDate": "23.08.2054"
    },
    {
        "engDate": "1997-12-09",
        "nepDate": "24.08.2054"
    },
    {
        "engDate": "1997-12-10",
        "nepDate": "25.08.2054"
    },
    {
        "engDate": "1997-12-11",
        "nepDate": "26.08.2054"
    },
    {
        "engDate": "1997-12-12",
        "nepDate": "27.08.2054"
    },
    {
        "engDate": "1997-12-13",
        "nepDate": "28.08.2054"
    },
    {
        "engDate": "1997-12-14",
        "nepDate": "29.08.2054"
    },
    {
        "engDate": "1997-12-15",
        "nepDate": "30.08.2054"
    },
    {
        "engDate": "1997-12-16",
        "nepDate": "01.09.2054"
    },
    {
        "engDate": "1997-12-17",
        "nepDate": "02.09.2054"
    },
    {
        "engDate": "1997-12-18",
        "nepDate": "03.09.2054"
    },
    {
        "engDate": "1997-12-19",
        "nepDate": "04.09.2054"
    },
    {
        "engDate": "1997-12-20",
        "nepDate": "05.09.2054"
    },
    {
        "engDate": "1997-12-21",
        "nepDate": "06.09.2054"
    },
    {
        "engDate": "1997-12-22",
        "nepDate": "07.09.2054"
    },
    {
        "engDate": "1997-12-23",
        "nepDate": "08.09.2054"
    },
    {
        "engDate": "1997-12-24",
        "nepDate": "09.09.2054"
    },
    {
        "engDate": "1997-12-25",
        "nepDate": "10.09.2054"
    },
    {
        "engDate": "1997-12-26",
        "nepDate": "11.09.2054"
    },
    {
        "engDate": "1997-12-27",
        "nepDate": "12.09.2054"
    },
    {
        "engDate": "1997-12-28",
        "nepDate": "13.09.2054"
    },
    {
        "engDate": "1997-12-29",
        "nepDate": "14.09.2054"
    },
    {
        "engDate": "1997-12-30",
        "nepDate": "15.09.2054"
    },
    {
        "engDate": "1997-12-31",
        "nepDate": "16.09.2054"
    },
    {
        "engDate": "1998-01-01",
        "nepDate": "17.09.2054"
    },
    {
        "engDate": "1998-01-02",
        "nepDate": "18.09.2054"
    },
    {
        "engDate": "1998-01-03",
        "nepDate": "19.09.2054"
    },
    {
        "engDate": "1998-01-04",
        "nepDate": "20.09.2054"
    },
    {
        "engDate": "1998-01-05",
        "nepDate": "21.09.2054"
    },
    {
        "engDate": "1998-01-06",
        "nepDate": "22.09.2054"
    },
    {
        "engDate": "1998-01-07",
        "nepDate": "23.09.2054"
    },
    {
        "engDate": "1998-01-08",
        "nepDate": "24.09.2054"
    },
    {
        "engDate": "1998-01-09",
        "nepDate": "25.09.2054"
    },
    {
        "engDate": "1998-01-10",
        "nepDate": "26.09.2054"
    },
    {
        "engDate": "1998-01-11",
        "nepDate": "27.09.2054"
    },
    {
        "engDate": "1998-01-12",
        "nepDate": "28.09.2054"
    },
    {
        "engDate": "1998-01-13",
        "nepDate": "29.09.2054"
    },
    {
        "engDate": "1998-01-14",
        "nepDate": "01.10.2054"
    },
    {
        "engDate": "1998-01-15",
        "nepDate": "02.10.2054"
    },
    {
        "engDate": "1998-01-16",
        "nepDate": "03.10.2054"
    },
    {
        "engDate": "1998-01-17",
        "nepDate": "04.10.2054"
    },
    {
        "engDate": "1998-01-18",
        "nepDate": "05.10.2054"
    },
    {
        "engDate": "1998-01-19",
        "nepDate": "06.10.2054"
    },
    {
        "engDate": "1998-01-20",
        "nepDate": "07.10.2054"
    },
    {
        "engDate": "1998-01-21",
        "nepDate": "08.10.2054"
    },
    {
        "engDate": "1998-01-22",
        "nepDate": "09.10.2054"
    },
    {
        "engDate": "1998-01-23",
        "nepDate": "10.10.2054"
    },
    {
        "engDate": "1998-01-24",
        "nepDate": "11.10.2054"
    },
    {
        "engDate": "1998-01-25",
        "nepDate": "12.10.2054"
    },
    {
        "engDate": "1998-01-26",
        "nepDate": "13.10.2054"
    },
    {
        "engDate": "1998-01-27",
        "nepDate": "14.10.2054"
    },
    {
        "engDate": "1998-01-28",
        "nepDate": "15.10.2054"
    },
    {
        "engDate": "1998-01-29",
        "nepDate": "16.10.2054"
    },
    {
        "engDate": "1998-01-30",
        "nepDate": "17.10.2054"
    },
    {
        "engDate": "1998-01-31",
        "nepDate": "18.10.2054"
    },
    {
        "engDate": "1998-02-01",
        "nepDate": "19.10.2054"
    },
    {
        "engDate": "1998-02-02",
        "nepDate": "20.10.2054"
    },
    {
        "engDate": "1998-02-03",
        "nepDate": "21.10.2054"
    },
    {
        "engDate": "1998-02-04",
        "nepDate": "22.10.2054"
    },
    {
        "engDate": "1998-02-05",
        "nepDate": "23.10.2054"
    },
    {
        "engDate": "1998-02-06",
        "nepDate": "24.10.2054"
    },
    {
        "engDate": "1998-02-07",
        "nepDate": "25.10.2054"
    },
    {
        "engDate": "1998-02-08",
        "nepDate": "26.10.2054"
    },
    {
        "engDate": "1998-02-09",
        "nepDate": "27.10.2054"
    },
    {
        "engDate": "1998-02-10",
        "nepDate": "28.10.2054"
    },
    {
        "engDate": "1998-02-11",
        "nepDate": "29.10.2054"
    },
    {
        "engDate": "1998-02-12",
        "nepDate": "30.10.2054"
    },
    {
        "engDate": "1998-02-13",
        "nepDate": "01.11.2054"
    },
    {
        "engDate": "1998-02-14",
        "nepDate": "02.11.2054"
    },
    {
        "engDate": "1998-02-15",
        "nepDate": "03.11.2054"
    },
    {
        "engDate": "1998-02-16",
        "nepDate": "04.11.2054"
    },
    {
        "engDate": "1998-02-17",
        "nepDate": "05.11.2054"
    },
    {
        "engDate": "1998-02-18",
        "nepDate": "06.11.2054"
    },
    {
        "engDate": "1998-02-19",
        "nepDate": "07.11.2054"
    },
    {
        "engDate": "1998-02-20",
        "nepDate": "08.11.2054"
    },
    {
        "engDate": "1998-02-21",
        "nepDate": "09.11.2054"
    },
    {
        "engDate": "1998-02-22",
        "nepDate": "10.11.2054"
    },
    {
        "engDate": "1998-02-23",
        "nepDate": "11.11.2054"
    },
    {
        "engDate": "1998-02-24",
        "nepDate": "12.11.2054"
    },
    {
        "engDate": "1998-02-25",
        "nepDate": "13.11.2054"
    },
    {
        "engDate": "1998-02-26",
        "nepDate": "14.11.2054"
    },
    {
        "engDate": "1998-02-27",
        "nepDate": "15.11.2054"
    },
    {
        "engDate": "1998-02-28",
        "nepDate": "16.11.2054"
    },
    {
        "engDate": "1998-03-01",
        "nepDate": "17.11.2054"
    },
    {
        "engDate": "1998-03-02",
        "nepDate": "18.11.2054"
    },
    {
        "engDate": "1998-03-03",
        "nepDate": "19.11.2054"
    },
    {
        "engDate": "1998-03-04",
        "nepDate": "20.11.2054"
    },
    {
        "engDate": "1998-03-05",
        "nepDate": "21.11.2054"
    },
    {
        "engDate": "1998-03-06",
        "nepDate": "22.11.2054"
    },
    {
        "engDate": "1998-03-07",
        "nepDate": "23.11.2054"
    },
    {
        "engDate": "1998-03-08",
        "nepDate": "24.11.2054"
    },
    {
        "engDate": "1998-03-09",
        "nepDate": "25.11.2054"
    },
    {
        "engDate": "1998-03-10",
        "nepDate": "26.11.2054"
    },
    {
        "engDate": "1998-03-11",
        "nepDate": "27.11.2054"
    },
    {
        "engDate": "1998-03-12",
        "nepDate": "28.11.2054"
    },
    {
        "engDate": "1998-03-13",
        "nepDate": "29.11.2054"
    },
    {
        "engDate": "1998-03-14",
        "nepDate": "01.12.2054"
    },
    {
        "engDate": "1998-03-15",
        "nepDate": "02.12.2054"
    },
    {
        "engDate": "1998-03-16",
        "nepDate": "03.12.2054"
    },
    {
        "engDate": "1998-03-17",
        "nepDate": "04.12.2054"
    },
    {
        "engDate": "1998-03-18",
        "nepDate": "05.12.2054"
    },
    {
        "engDate": "1998-03-19",
        "nepDate": "06.12.2054"
    },
    {
        "engDate": "1998-03-20",
        "nepDate": "07.12.2054"
    },
    {
        "engDate": "1998-03-21",
        "nepDate": "08.12.2054"
    },
    {
        "engDate": "1998-03-22",
        "nepDate": "09.12.2054"
    },
    {
        "engDate": "1998-03-23",
        "nepDate": "10.12.2054"
    },
    {
        "engDate": "1998-03-24",
        "nepDate": "11.12.2054"
    },
    {
        "engDate": "1998-03-25",
        "nepDate": "12.12.2054"
    },
    {
        "engDate": "1998-03-26",
        "nepDate": "13.12.2054"
    },
    {
        "engDate": "1998-03-27",
        "nepDate": "14.12.2054"
    },
    {
        "engDate": "1998-03-28",
        "nepDate": "15.12.2054"
    },
    {
        "engDate": "1998-03-29",
        "nepDate": "16.12.2054"
    },
    {
        "engDate": "1998-03-30",
        "nepDate": "17.12.2054"
    },
    {
        "engDate": "1998-03-31",
        "nepDate": "18.12.2054"
    },
    {
        "engDate": "1998-04-01",
        "nepDate": "19.12.2054"
    },
    {
        "engDate": "1998-04-02",
        "nepDate": "20.12.2054"
    },
    {
        "engDate": "1998-04-03",
        "nepDate": "21.12.2054"
    },
    {
        "engDate": "1998-04-04",
        "nepDate": "22.12.2054"
    },
    {
        "engDate": "1998-04-05",
        "nepDate": "23.12.2054"
    },
    {
        "engDate": "1998-04-06",
        "nepDate": "24.12.2054"
    },
    {
        "engDate": "1998-04-07",
        "nepDate": "25.12.2054"
    },
    {
        "engDate": "1998-04-08",
        "nepDate": "26.12.2054"
    },
    {
        "engDate": "1998-04-09",
        "nepDate": "27.12.2054"
    },
    {
        "engDate": "1998-04-10",
        "nepDate": "28.12.2054"
    },
    {
        "engDate": "1998-04-11",
        "nepDate": "29.12.2054"
    },
    {
        "engDate": "1998-04-12",
        "nepDate": "30.12.2054"
    },
    {
        "engDate": "1998-04-13",
        "nepDate": "31.12.2054"
    },
    {
        "engDate": "1998-04-14",
        "nepDate": "01.01.2055"
    },
    {
        "engDate": "1998-04-15",
        "nepDate": "02.01.2055"
    },
    {
        "engDate": "1998-04-16",
        "nepDate": "03.01.2055"
    },
    {
        "engDate": "1998-04-17",
        "nepDate": "04.01.2055"
    },
    {
        "engDate": "1998-04-18",
        "nepDate": "05.01.2055"
    },
    {
        "engDate": "1998-04-19",
        "nepDate": "06.01.2055"
    },
    {
        "engDate": "1998-04-20",
        "nepDate": "07.01.2055"
    },
    {
        "engDate": "1998-04-21",
        "nepDate": "08.01.2055"
    },
    {
        "engDate": "1998-04-22",
        "nepDate": "09.01.2055"
    },
    {
        "engDate": "1998-04-23",
        "nepDate": "10.01.2055"
    },
    {
        "engDate": "1998-04-24",
        "nepDate": "11.01.2055"
    },
    {
        "engDate": "1998-04-25",
        "nepDate": "12.01.2055"
    },
    {
        "engDate": "1998-04-26",
        "nepDate": "13.01.2055"
    },
    {
        "engDate": "1998-04-27",
        "nepDate": "14.01.2055"
    },
    {
        "engDate": "1998-04-28",
        "nepDate": "15.01.2055"
    },
    {
        "engDate": "1998-04-29",
        "nepDate": "16.01.2055"
    },
    {
        "engDate": "1998-04-30",
        "nepDate": "17.01.2055"
    },
    {
        "engDate": "1998-05-01",
        "nepDate": "18.01.2055"
    },
    {
        "engDate": "1998-05-02",
        "nepDate": "19.01.2055"
    },
    {
        "engDate": "1998-05-03",
        "nepDate": "20.01.2055"
    },
    {
        "engDate": "1998-05-04",
        "nepDate": "21.01.2055"
    },
    {
        "engDate": "1998-05-05",
        "nepDate": "22.01.2055"
    },
    {
        "engDate": "1998-05-06",
        "nepDate": "23.01.2055"
    },
    {
        "engDate": "1998-05-07",
        "nepDate": "24.01.2055"
    },
    {
        "engDate": "1998-05-08",
        "nepDate": "25.01.2055"
    },
    {
        "engDate": "1998-05-09",
        "nepDate": "26.01.2055"
    },
    {
        "engDate": "1998-05-10",
        "nepDate": "27.01.2055"
    },
    {
        "engDate": "1998-05-11",
        "nepDate": "28.01.2055"
    },
    {
        "engDate": "1998-05-12",
        "nepDate": "29.01.2055"
    },
    {
        "engDate": "1998-05-13",
        "nepDate": "30.01.2055"
    },
    {
        "engDate": "1998-05-14",
        "nepDate": "31.01.2055"
    },
    {
        "engDate": "1998-05-15",
        "nepDate": "01.02.2055"
    },
    {
        "engDate": "1998-05-16",
        "nepDate": "02.02.2055"
    },
    {
        "engDate": "1998-05-17",
        "nepDate": "03.02.2055"
    },
    {
        "engDate": "1998-05-18",
        "nepDate": "04.02.2055"
    },
    {
        "engDate": "1998-05-19",
        "nepDate": "05.02.2055"
    },
    {
        "engDate": "1998-05-20",
        "nepDate": "06.02.2055"
    },
    {
        "engDate": "1998-05-21",
        "nepDate": "07.02.2055"
    },
    {
        "engDate": "1998-05-22",
        "nepDate": "08.02.2055"
    },
    {
        "engDate": "1998-05-23",
        "nepDate": "09.02.2055"
    },
    {
        "engDate": "1998-05-24",
        "nepDate": "10.02.2055"
    },
    {
        "engDate": "1998-05-25",
        "nepDate": "11.02.2055"
    },
    {
        "engDate": "1998-05-26",
        "nepDate": "12.02.2055"
    },
    {
        "engDate": "1998-05-27",
        "nepDate": "13.02.2055"
    },
    {
        "engDate": "1998-05-28",
        "nepDate": "14.02.2055"
    },
    {
        "engDate": "1998-05-29",
        "nepDate": "15.02.2055"
    },
    {
        "engDate": "1998-05-30",
        "nepDate": "16.02.2055"
    },
    {
        "engDate": "1998-05-31",
        "nepDate": "17.02.2055"
    },
    {
        "engDate": "1998-06-01",
        "nepDate": "18.02.2055"
    },
    {
        "engDate": "1998-06-02",
        "nepDate": "19.02.2055"
    },
    {
        "engDate": "1998-06-03",
        "nepDate": "20.02.2055"
    },
    {
        "engDate": "1998-06-04",
        "nepDate": "21.02.2055"
    },
    {
        "engDate": "1998-06-05",
        "nepDate": "22.02.2055"
    },
    {
        "engDate": "1998-06-06",
        "nepDate": "23.02.2055"
    },
    {
        "engDate": "1998-06-07",
        "nepDate": "24.02.2055"
    },
    {
        "engDate": "1998-06-08",
        "nepDate": "25.02.2055"
    },
    {
        "engDate": "1998-06-09",
        "nepDate": "26.02.2055"
    },
    {
        "engDate": "1998-06-10",
        "nepDate": "27.02.2055"
    },
    {
        "engDate": "1998-06-11",
        "nepDate": "28.02.2055"
    },
    {
        "engDate": "1998-06-12",
        "nepDate": "29.02.2055"
    },
    {
        "engDate": "1998-06-13",
        "nepDate": "30.02.2055"
    },
    {
        "engDate": "1998-06-14",
        "nepDate": "31.02.2055"
    },
    {
        "engDate": "1998-06-15",
        "nepDate": "01.03.2055"
    },
    {
        "engDate": "1998-06-16",
        "nepDate": "02.03.2055"
    },
    {
        "engDate": "1998-06-17",
        "nepDate": "03.03.2055"
    },
    {
        "engDate": "1998-06-18",
        "nepDate": "04.03.2055"
    },
    {
        "engDate": "1998-06-19",
        "nepDate": "05.03.2055"
    },
    {
        "engDate": "1998-06-20",
        "nepDate": "06.03.2055"
    },
    {
        "engDate": "1998-06-21",
        "nepDate": "07.03.2055"
    },
    {
        "engDate": "1998-06-22",
        "nepDate": "08.03.2055"
    },
    {
        "engDate": "1998-06-23",
        "nepDate": "09.03.2055"
    },
    {
        "engDate": "1998-06-24",
        "nepDate": "10.03.2055"
    },
    {
        "engDate": "1998-06-25",
        "nepDate": "11.03.2055"
    },
    {
        "engDate": "1998-06-26",
        "nepDate": "12.03.2055"
    },
    {
        "engDate": "1998-06-27",
        "nepDate": "13.03.2055"
    },
    {
        "engDate": "1998-06-28",
        "nepDate": "14.03.2055"
    },
    {
        "engDate": "1998-06-29",
        "nepDate": "15.03.2055"
    },
    {
        "engDate": "1998-06-30",
        "nepDate": "16.03.2055"
    },
    {
        "engDate": "1998-07-01",
        "nepDate": "17.03.2055"
    },
    {
        "engDate": "1998-07-02",
        "nepDate": "18.03.2055"
    },
    {
        "engDate": "1998-07-03",
        "nepDate": "19.03.2055"
    },
    {
        "engDate": "1998-07-04",
        "nepDate": "20.03.2055"
    },
    {
        "engDate": "1998-07-05",
        "nepDate": "21.03.2055"
    },
    {
        "engDate": "1998-07-06",
        "nepDate": "22.03.2055"
    },
    {
        "engDate": "1998-07-07",
        "nepDate": "23.03.2055"
    },
    {
        "engDate": "1998-07-08",
        "nepDate": "24.03.2055"
    },
    {
        "engDate": "1998-07-09",
        "nepDate": "25.03.2055"
    },
    {
        "engDate": "1998-07-10",
        "nepDate": "26.03.2055"
    },
    {
        "engDate": "1998-07-11",
        "nepDate": "27.03.2055"
    },
    {
        "engDate": "1998-07-12",
        "nepDate": "28.03.2055"
    },
    {
        "engDate": "1998-07-13",
        "nepDate": "29.03.2055"
    },
    {
        "engDate": "1998-07-14",
        "nepDate": "30.03.2055"
    },
    {
        "engDate": "1998-07-15",
        "nepDate": "31.03.2055"
    },
    {
        "engDate": "1998-07-16",
        "nepDate": "32.03.2055"
    },
    {
        "engDate": "1998-07-17",
        "nepDate": "01.04.2055"
    },
    {
        "engDate": "1998-07-18",
        "nepDate": "02.04.2055"
    },
    {
        "engDate": "1998-07-19",
        "nepDate": "03.04.2055"
    },
    {
        "engDate": "1998-07-20",
        "nepDate": "04.04.2055"
    },
    {
        "engDate": "1998-07-21",
        "nepDate": "05.04.2055"
    },
    {
        "engDate": "1998-07-22",
        "nepDate": "06.04.2055"
    },
    {
        "engDate": "1998-07-23",
        "nepDate": "07.04.2055"
    },
    {
        "engDate": "1998-07-24",
        "nepDate": "08.04.2055"
    },
    {
        "engDate": "1998-07-25",
        "nepDate": "09.04.2055"
    },
    {
        "engDate": "1998-07-26",
        "nepDate": "10.04.2055"
    },
    {
        "engDate": "1998-07-27",
        "nepDate": "11.04.2055"
    },
    {
        "engDate": "1998-07-28",
        "nepDate": "12.04.2055"
    },
    {
        "engDate": "1998-07-29",
        "nepDate": "13.04.2055"
    },
    {
        "engDate": "1998-07-30",
        "nepDate": "14.04.2055"
    },
    {
        "engDate": "1998-07-31",
        "nepDate": "15.04.2055"
    },
    {
        "engDate": "1998-08-01",
        "nepDate": "16.04.2055"
    },
    {
        "engDate": "1998-08-02",
        "nepDate": "17.04.2055"
    },
    {
        "engDate": "1998-08-03",
        "nepDate": "18.04.2055"
    },
    {
        "engDate": "1998-08-04",
        "nepDate": "19.04.2055"
    },
    {
        "engDate": "1998-08-05",
        "nepDate": "20.04.2055"
    },
    {
        "engDate": "1998-08-06",
        "nepDate": "21.04.2055"
    },
    {
        "engDate": "1998-08-07",
        "nepDate": "22.04.2055"
    },
    {
        "engDate": "1998-08-08",
        "nepDate": "23.04.2055"
    },
    {
        "engDate": "1998-08-09",
        "nepDate": "24.04.2055"
    },
    {
        "engDate": "1998-08-10",
        "nepDate": "25.04.2055"
    },
    {
        "engDate": "1998-08-11",
        "nepDate": "26.04.2055"
    },
    {
        "engDate": "1998-08-12",
        "nepDate": "27.04.2055"
    },
    {
        "engDate": "1998-08-13",
        "nepDate": "28.04.2055"
    },
    {
        "engDate": "1998-08-14",
        "nepDate": "29.04.2055"
    },
    {
        "engDate": "1998-08-15",
        "nepDate": "30.04.2055"
    },
    {
        "engDate": "1998-08-16",
        "nepDate": "31.04.2055"
    },
    {
        "engDate": "1998-08-17",
        "nepDate": "01.05.2055"
    },
    {
        "engDate": "1998-08-18",
        "nepDate": "02.05.2055"
    },
    {
        "engDate": "1998-08-19",
        "nepDate": "03.05.2055"
    },
    {
        "engDate": "1998-08-20",
        "nepDate": "04.05.2055"
    },
    {
        "engDate": "1998-08-21",
        "nepDate": "05.05.2055"
    },
    {
        "engDate": "1998-08-22",
        "nepDate": "06.05.2055"
    },
    {
        "engDate": "1998-08-23",
        "nepDate": "07.05.2055"
    },
    {
        "engDate": "1998-08-24",
        "nepDate": "08.05.2055"
    },
    {
        "engDate": "1998-08-25",
        "nepDate": "09.05.2055"
    },
    {
        "engDate": "1998-08-26",
        "nepDate": "10.05.2055"
    },
    {
        "engDate": "1998-08-27",
        "nepDate": "11.05.2055"
    },
    {
        "engDate": "1998-08-28",
        "nepDate": "12.05.2055"
    },
    {
        "engDate": "1998-08-29",
        "nepDate": "13.05.2055"
    },
    {
        "engDate": "1998-08-30",
        "nepDate": "14.05.2055"
    },
    {
        "engDate": "1998-08-31",
        "nepDate": "15.05.2055"
    },
    {
        "engDate": "1998-09-01",
        "nepDate": "16.05.2055"
    },
    {
        "engDate": "1998-09-02",
        "nepDate": "17.05.2055"
    },
    {
        "engDate": "1998-09-03",
        "nepDate": "18.05.2055"
    },
    {
        "engDate": "1998-09-04",
        "nepDate": "19.05.2055"
    },
    {
        "engDate": "1998-09-05",
        "nepDate": "20.05.2055"
    },
    {
        "engDate": "1998-09-06",
        "nepDate": "21.05.2055"
    },
    {
        "engDate": "1998-09-07",
        "nepDate": "22.05.2055"
    },
    {
        "engDate": "1998-09-08",
        "nepDate": "23.05.2055"
    },
    {
        "engDate": "1998-09-09",
        "nepDate": "24.05.2055"
    },
    {
        "engDate": "1998-09-10",
        "nepDate": "25.05.2055"
    },
    {
        "engDate": "1998-09-11",
        "nepDate": "26.05.2055"
    },
    {
        "engDate": "1998-09-12",
        "nepDate": "27.05.2055"
    },
    {
        "engDate": "1998-09-13",
        "nepDate": "28.05.2055"
    },
    {
        "engDate": "1998-09-14",
        "nepDate": "29.05.2055"
    },
    {
        "engDate": "1998-09-15",
        "nepDate": "30.05.2055"
    },
    {
        "engDate": "1998-09-16",
        "nepDate": "31.05.2055"
    },
    {
        "engDate": "1998-09-17",
        "nepDate": "01.06.2055"
    },
    {
        "engDate": "1998-09-18",
        "nepDate": "02.06.2055"
    },
    {
        "engDate": "1998-09-19",
        "nepDate": "03.06.2055"
    },
    {
        "engDate": "1998-09-20",
        "nepDate": "04.06.2055"
    },
    {
        "engDate": "1998-09-21",
        "nepDate": "05.06.2055"
    },
    {
        "engDate": "1998-09-22",
        "nepDate": "06.06.2055"
    },
    {
        "engDate": "1998-09-23",
        "nepDate": "07.06.2055"
    },
    {
        "engDate": "1998-09-24",
        "nepDate": "08.06.2055"
    },
    {
        "engDate": "1998-09-25",
        "nepDate": "09.06.2055"
    },
    {
        "engDate": "1998-09-26",
        "nepDate": "10.06.2055"
    },
    {
        "engDate": "1998-09-27",
        "nepDate": "11.06.2055"
    },
    {
        "engDate": "1998-09-28",
        "nepDate": "12.06.2055"
    },
    {
        "engDate": "1998-09-29",
        "nepDate": "13.06.2055"
    },
    {
        "engDate": "1998-09-30",
        "nepDate": "14.06.2055"
    },
    {
        "engDate": "1998-10-01",
        "nepDate": "15.06.2055"
    },
    {
        "engDate": "1998-10-02",
        "nepDate": "16.06.2055"
    },
    {
        "engDate": "1998-10-03",
        "nepDate": "17.06.2055"
    },
    {
        "engDate": "1998-10-04",
        "nepDate": "18.06.2055"
    },
    {
        "engDate": "1998-10-05",
        "nepDate": "19.06.2055"
    },
    {
        "engDate": "1998-10-06",
        "nepDate": "20.06.2055"
    },
    {
        "engDate": "1998-10-07",
        "nepDate": "21.06.2055"
    },
    {
        "engDate": "1998-10-08",
        "nepDate": "22.06.2055"
    },
    {
        "engDate": "1998-10-09",
        "nepDate": "23.06.2055"
    },
    {
        "engDate": "1998-10-10",
        "nepDate": "24.06.2055"
    },
    {
        "engDate": "1998-10-11",
        "nepDate": "25.06.2055"
    },
    {
        "engDate": "1998-10-12",
        "nepDate": "26.06.2055"
    },
    {
        "engDate": "1998-10-13",
        "nepDate": "27.06.2055"
    },
    {
        "engDate": "1998-10-14",
        "nepDate": "28.06.2055"
    },
    {
        "engDate": "1998-10-15",
        "nepDate": "29.06.2055"
    },
    {
        "engDate": "1998-10-16",
        "nepDate": "30.06.2055"
    },
    {
        "engDate": "1998-10-17",
        "nepDate": "31.06.2055"
    },
    {
        "engDate": "1998-10-18",
        "nepDate": "01.07.2055"
    },
    {
        "engDate": "1998-10-19",
        "nepDate": "02.07.2055"
    },
    {
        "engDate": "1998-10-20",
        "nepDate": "03.07.2055"
    },
    {
        "engDate": "1998-10-21",
        "nepDate": "04.07.2055"
    },
    {
        "engDate": "1998-10-22",
        "nepDate": "05.07.2055"
    },
    {
        "engDate": "1998-10-23",
        "nepDate": "06.07.2055"
    },
    {
        "engDate": "1998-10-24",
        "nepDate": "07.07.2055"
    },
    {
        "engDate": "1998-10-25",
        "nepDate": "08.07.2055"
    },
    {
        "engDate": "1998-10-26",
        "nepDate": "09.07.2055"
    },
    {
        "engDate": "1998-10-27",
        "nepDate": "10.07.2055"
    },
    {
        "engDate": "1998-10-28",
        "nepDate": "11.07.2055"
    },
    {
        "engDate": "1998-10-29",
        "nepDate": "12.07.2055"
    },
    {
        "engDate": "1998-10-30",
        "nepDate": "13.07.2055"
    },
    {
        "engDate": "1998-10-31",
        "nepDate": "14.07.2055"
    },
    {
        "engDate": "1998-11-01",
        "nepDate": "15.07.2055"
    },
    {
        "engDate": "1998-11-02",
        "nepDate": "16.07.2055"
    },
    {
        "engDate": "1998-11-03",
        "nepDate": "17.07.2055"
    },
    {
        "engDate": "1998-11-04",
        "nepDate": "18.07.2055"
    },
    {
        "engDate": "1998-11-05",
        "nepDate": "19.07.2055"
    },
    {
        "engDate": "1998-11-06",
        "nepDate": "20.07.2055"
    },
    {
        "engDate": "1998-11-07",
        "nepDate": "21.07.2055"
    },
    {
        "engDate": "1998-11-08",
        "nepDate": "22.07.2055"
    },
    {
        "engDate": "1998-11-09",
        "nepDate": "23.07.2055"
    },
    {
        "engDate": "1998-11-10",
        "nepDate": "24.07.2055"
    },
    {
        "engDate": "1998-11-11",
        "nepDate": "25.07.2055"
    },
    {
        "engDate": "1998-11-12",
        "nepDate": "26.07.2055"
    },
    {
        "engDate": "1998-11-13",
        "nepDate": "27.07.2055"
    },
    {
        "engDate": "1998-11-14",
        "nepDate": "28.07.2055"
    },
    {
        "engDate": "1998-11-15",
        "nepDate": "29.07.2055"
    },
    {
        "engDate": "1998-11-16",
        "nepDate": "30.07.2055"
    },
    {
        "engDate": "1998-11-17",
        "nepDate": "01.08.2055"
    },
    {
        "engDate": "1998-11-18",
        "nepDate": "02.08.2055"
    },
    {
        "engDate": "1998-11-19",
        "nepDate": "03.08.2055"
    },
    {
        "engDate": "1998-11-20",
        "nepDate": "04.08.2055"
    },
    {
        "engDate": "1998-11-21",
        "nepDate": "05.08.2055"
    },
    {
        "engDate": "1998-11-22",
        "nepDate": "06.08.2055"
    },
    {
        "engDate": "1998-11-23",
        "nepDate": "07.08.2055"
    },
    {
        "engDate": "1998-11-24",
        "nepDate": "08.08.2055"
    },
    {
        "engDate": "1998-11-25",
        "nepDate": "09.08.2055"
    },
    {
        "engDate": "1998-11-26",
        "nepDate": "10.08.2055"
    },
    {
        "engDate": "1998-11-27",
        "nepDate": "11.08.2055"
    },
    {
        "engDate": "1998-11-28",
        "nepDate": "12.08.2055"
    },
    {
        "engDate": "1998-11-29",
        "nepDate": "13.08.2055"
    },
    {
        "engDate": "1998-11-30",
        "nepDate": "14.08.2055"
    },
    {
        "engDate": "1998-12-01",
        "nepDate": "15.08.2055"
    },
    {
        "engDate": "1998-12-02",
        "nepDate": "16.08.2055"
    },
    {
        "engDate": "1998-12-03",
        "nepDate": "17.08.2055"
    },
    {
        "engDate": "1998-12-04",
        "nepDate": "18.08.2055"
    },
    {
        "engDate": "1998-12-05",
        "nepDate": "19.08.2055"
    },
    {
        "engDate": "1998-12-06",
        "nepDate": "20.08.2055"
    },
    {
        "engDate": "1998-12-07",
        "nepDate": "21.08.2055"
    },
    {
        "engDate": "1998-12-08",
        "nepDate": "22.08.2055"
    },
    {
        "engDate": "1998-12-09",
        "nepDate": "23.08.2055"
    },
    {
        "engDate": "1998-12-10",
        "nepDate": "24.08.2055"
    },
    {
        "engDate": "1998-12-11",
        "nepDate": "25.08.2055"
    },
    {
        "engDate": "1998-12-12",
        "nepDate": "26.08.2055"
    },
    {
        "engDate": "1998-12-13",
        "nepDate": "27.08.2055"
    },
    {
        "engDate": "1998-12-14",
        "nepDate": "28.08.2055"
    },
    {
        "engDate": "1998-12-15",
        "nepDate": "29.08.2055"
    },
    {
        "engDate": "1998-12-16",
        "nepDate": "01.09.2055"
    },
    {
        "engDate": "1998-12-17",
        "nepDate": "02.09.2055"
    },
    {
        "engDate": "1998-12-18",
        "nepDate": "03.09.2055"
    },
    {
        "engDate": "1998-12-19",
        "nepDate": "04.09.2055"
    },
    {
        "engDate": "1998-12-20",
        "nepDate": "05.09.2055"
    },
    {
        "engDate": "1998-12-21",
        "nepDate": "06.09.2055"
    },
    {
        "engDate": "1998-12-22",
        "nepDate": "07.09.2055"
    },
    {
        "engDate": "1998-12-23",
        "nepDate": "08.09.2055"
    },
    {
        "engDate": "1998-12-24",
        "nepDate": "09.09.2055"
    },
    {
        "engDate": "1998-12-25",
        "nepDate": "10.09.2055"
    },
    {
        "engDate": "1998-12-26",
        "nepDate": "11.09.2055"
    },
    {
        "engDate": "1998-12-27",
        "nepDate": "12.09.2055"
    },
    {
        "engDate": "1998-12-28",
        "nepDate": "13.09.2055"
    },
    {
        "engDate": "1998-12-29",
        "nepDate": "14.09.2055"
    },
    {
        "engDate": "1998-12-30",
        "nepDate": "15.09.2055"
    },
    {
        "engDate": "1998-12-31",
        "nepDate": "16.09.2055"
    },
    {
        "engDate": "1999-01-01",
        "nepDate": "17.09.2055"
    },
    {
        "engDate": "1999-01-02",
        "nepDate": "18.09.2055"
    },
    {
        "engDate": "1999-01-03",
        "nepDate": "19.09.2055"
    },
    {
        "engDate": "1999-01-04",
        "nepDate": "20.09.2055"
    },
    {
        "engDate": "1999-01-05",
        "nepDate": "21.09.2055"
    },
    {
        "engDate": "1999-01-06",
        "nepDate": "22.09.2055"
    },
    {
        "engDate": "1999-01-07",
        "nepDate": "23.09.2055"
    },
    {
        "engDate": "1999-01-08",
        "nepDate": "24.09.2055"
    },
    {
        "engDate": "1999-01-09",
        "nepDate": "25.09.2055"
    },
    {
        "engDate": "1999-01-10",
        "nepDate": "26.09.2055"
    },
    {
        "engDate": "1999-01-11",
        "nepDate": "27.09.2055"
    },
    {
        "engDate": "1999-01-12",
        "nepDate": "28.09.2055"
    },
    {
        "engDate": "1999-01-13",
        "nepDate": "29.09.2055"
    },
    {
        "engDate": "1999-01-14",
        "nepDate": "30.09.2055"
    },
    {
        "engDate": "1999-01-15",
        "nepDate": "01.10.2055"
    },
    {
        "engDate": "1999-01-16",
        "nepDate": "02.10.2055"
    },
    {
        "engDate": "1999-01-17",
        "nepDate": "03.10.2055"
    },
    {
        "engDate": "1999-01-18",
        "nepDate": "04.10.2055"
    },
    {
        "engDate": "1999-01-19",
        "nepDate": "05.10.2055"
    },
    {
        "engDate": "1999-01-20",
        "nepDate": "06.10.2055"
    },
    {
        "engDate": "1999-01-21",
        "nepDate": "07.10.2055"
    },
    {
        "engDate": "1999-01-22",
        "nepDate": "08.10.2055"
    },
    {
        "engDate": "1999-01-23",
        "nepDate": "09.10.2055"
    },
    {
        "engDate": "1999-01-24",
        "nepDate": "10.10.2055"
    },
    {
        "engDate": "1999-01-25",
        "nepDate": "11.10.2055"
    },
    {
        "engDate": "1999-01-26",
        "nepDate": "12.10.2055"
    },
    {
        "engDate": "1999-01-27",
        "nepDate": "13.10.2055"
    },
    {
        "engDate": "1999-01-28",
        "nepDate": "14.10.2055"
    },
    {
        "engDate": "1999-01-29",
        "nepDate": "15.10.2055"
    },
    {
        "engDate": "1999-01-30",
        "nepDate": "16.10.2055"
    },
    {
        "engDate": "1999-01-31",
        "nepDate": "17.10.2055"
    },
    {
        "engDate": "1999-02-01",
        "nepDate": "18.10.2055"
    },
    {
        "engDate": "1999-02-02",
        "nepDate": "19.10.2055"
    },
    {
        "engDate": "1999-02-03",
        "nepDate": "20.10.2055"
    },
    {
        "engDate": "1999-02-04",
        "nepDate": "21.10.2055"
    },
    {
        "engDate": "1999-02-05",
        "nepDate": "22.10.2055"
    },
    {
        "engDate": "1999-02-06",
        "nepDate": "23.10.2055"
    },
    {
        "engDate": "1999-02-07",
        "nepDate": "24.10.2055"
    },
    {
        "engDate": "1999-02-08",
        "nepDate": "25.10.2055"
    },
    {
        "engDate": "1999-02-09",
        "nepDate": "26.10.2055"
    },
    {
        "engDate": "1999-02-10",
        "nepDate": "27.10.2055"
    },
    {
        "engDate": "1999-02-11",
        "nepDate": "28.10.2055"
    },
    {
        "engDate": "1999-02-12",
        "nepDate": "29.10.2055"
    },
    {
        "engDate": "1999-02-13",
        "nepDate": "01.11.2055"
    },
    {
        "engDate": "1999-02-14",
        "nepDate": "02.11.2055"
    },
    {
        "engDate": "1999-02-15",
        "nepDate": "03.11.2055"
    },
    {
        "engDate": "1999-02-16",
        "nepDate": "04.11.2055"
    },
    {
        "engDate": "1999-02-17",
        "nepDate": "05.11.2055"
    },
    {
        "engDate": "1999-02-18",
        "nepDate": "06.11.2055"
    },
    {
        "engDate": "1999-02-19",
        "nepDate": "07.11.2055"
    },
    {
        "engDate": "1999-02-20",
        "nepDate": "08.11.2055"
    },
    {
        "engDate": "1999-02-21",
        "nepDate": "09.11.2055"
    },
    {
        "engDate": "1999-02-22",
        "nepDate": "10.11.2055"
    },
    {
        "engDate": "1999-02-23",
        "nepDate": "11.11.2055"
    },
    {
        "engDate": "1999-02-24",
        "nepDate": "12.11.2055"
    },
    {
        "engDate": "1999-02-25",
        "nepDate": "13.11.2055"
    },
    {
        "engDate": "1999-02-26",
        "nepDate": "14.11.2055"
    },
    {
        "engDate": "1999-02-27",
        "nepDate": "15.11.2055"
    },
    {
        "engDate": "1999-02-28",
        "nepDate": "16.11.2055"
    },
    {
        "engDate": "1999-03-01",
        "nepDate": "17.11.2055"
    },
    {
        "engDate": "1999-03-02",
        "nepDate": "18.11.2055"
    },
    {
        "engDate": "1999-03-03",
        "nepDate": "19.11.2055"
    },
    {
        "engDate": "1999-03-04",
        "nepDate": "20.11.2055"
    },
    {
        "engDate": "1999-03-05",
        "nepDate": "21.11.2055"
    },
    {
        "engDate": "1999-03-06",
        "nepDate": "22.11.2055"
    },
    {
        "engDate": "1999-03-07",
        "nepDate": "23.11.2055"
    },
    {
        "engDate": "1999-03-08",
        "nepDate": "24.11.2055"
    },
    {
        "engDate": "1999-03-09",
        "nepDate": "25.11.2055"
    },
    {
        "engDate": "1999-03-10",
        "nepDate": "26.11.2055"
    },
    {
        "engDate": "1999-03-11",
        "nepDate": "27.11.2055"
    },
    {
        "engDate": "1999-03-12",
        "nepDate": "28.11.2055"
    },
    {
        "engDate": "1999-03-13",
        "nepDate": "29.11.2055"
    },
    {
        "engDate": "1999-03-14",
        "nepDate": "30.11.2055"
    },
    {
        "engDate": "1999-03-15",
        "nepDate": "01.12.2055"
    },
    {
        "engDate": "1999-03-16",
        "nepDate": "02.12.2055"
    },
    {
        "engDate": "1999-03-17",
        "nepDate": "03.12.2055"
    },
    {
        "engDate": "1999-03-18",
        "nepDate": "04.12.2055"
    },
    {
        "engDate": "1999-03-19",
        "nepDate": "05.12.2055"
    },
    {
        "engDate": "1999-03-20",
        "nepDate": "06.12.2055"
    },
    {
        "engDate": "1999-03-21",
        "nepDate": "07.12.2055"
    },
    {
        "engDate": "1999-03-22",
        "nepDate": "08.12.2055"
    },
    {
        "engDate": "1999-03-23",
        "nepDate": "09.12.2055"
    },
    {
        "engDate": "1999-03-24",
        "nepDate": "10.12.2055"
    },
    {
        "engDate": "1999-03-25",
        "nepDate": "11.12.2055"
    },
    {
        "engDate": "1999-03-26",
        "nepDate": "12.12.2055"
    },
    {
        "engDate": "1999-03-27",
        "nepDate": "13.12.2055"
    },
    {
        "engDate": "1999-03-28",
        "nepDate": "14.12.2055"
    },
    {
        "engDate": "1999-03-29",
        "nepDate": "15.12.2055"
    },
    {
        "engDate": "1999-03-30",
        "nepDate": "16.12.2055"
    },
    {
        "engDate": "1999-03-31",
        "nepDate": "17.12.2055"
    },
    {
        "engDate": "1999-04-01",
        "nepDate": "18.12.2055"
    },
    {
        "engDate": "1999-04-02",
        "nepDate": "19.12.2055"
    },
    {
        "engDate": "1999-04-03",
        "nepDate": "20.12.2055"
    },
    {
        "engDate": "1999-04-04",
        "nepDate": "21.12.2055"
    },
    {
        "engDate": "1999-04-05",
        "nepDate": "22.12.2055"
    },
    {
        "engDate": "1999-04-06",
        "nepDate": "23.12.2055"
    },
    {
        "engDate": "1999-04-07",
        "nepDate": "24.12.2055"
    },
    {
        "engDate": "1999-04-08",
        "nepDate": "25.12.2055"
    },
    {
        "engDate": "1999-04-09",
        "nepDate": "26.12.2055"
    },
    {
        "engDate": "1999-04-10",
        "nepDate": "27.12.2055"
    },
    {
        "engDate": "1999-04-11",
        "nepDate": "28.12.2055"
    },
    {
        "engDate": "1999-04-12",
        "nepDate": "29.12.2055"
    },
    {
        "engDate": "1999-04-13",
        "nepDate": "30.12.2055"
    },
    {
        "engDate": "1999-04-14",
        "nepDate": "01.01.2056"
    },
    {
        "engDate": "1999-04-15",
        "nepDate": "02.01.2056"
    },
    {
        "engDate": "1999-04-16",
        "nepDate": "03.01.2056"
    },
    {
        "engDate": "1999-04-17",
        "nepDate": "04.01.2056"
    },
    {
        "engDate": "1999-04-18",
        "nepDate": "05.01.2056"
    },
    {
        "engDate": "1999-04-19",
        "nepDate": "06.01.2056"
    },
    {
        "engDate": "1999-04-20",
        "nepDate": "07.01.2056"
    },
    {
        "engDate": "1999-04-21",
        "nepDate": "08.01.2056"
    },
    {
        "engDate": "1999-04-22",
        "nepDate": "09.01.2056"
    },
    {
        "engDate": "1999-04-23",
        "nepDate": "10.01.2056"
    },
    {
        "engDate": "1999-04-24",
        "nepDate": "11.01.2056"
    },
    {
        "engDate": "1999-04-25",
        "nepDate": "12.01.2056"
    },
    {
        "engDate": "1999-04-26",
        "nepDate": "13.01.2056"
    },
    {
        "engDate": "1999-04-27",
        "nepDate": "14.01.2056"
    },
    {
        "engDate": "1999-04-28",
        "nepDate": "15.01.2056"
    },
    {
        "engDate": "1999-04-29",
        "nepDate": "16.01.2056"
    },
    {
        "engDate": "1999-04-30",
        "nepDate": "17.01.2056"
    },
    {
        "engDate": "1999-05-01",
        "nepDate": "18.01.2056"
    },
    {
        "engDate": "1999-05-02",
        "nepDate": "19.01.2056"
    },
    {
        "engDate": "1999-05-03",
        "nepDate": "20.01.2056"
    },
    {
        "engDate": "1999-05-04",
        "nepDate": "21.01.2056"
    },
    {
        "engDate": "1999-05-05",
        "nepDate": "22.01.2056"
    },
    {
        "engDate": "1999-05-06",
        "nepDate": "23.01.2056"
    },
    {
        "engDate": "1999-05-07",
        "nepDate": "24.01.2056"
    },
    {
        "engDate": "1999-05-08",
        "nepDate": "25.01.2056"
    },
    {
        "engDate": "1999-05-09",
        "nepDate": "26.01.2056"
    },
    {
        "engDate": "1999-05-10",
        "nepDate": "27.01.2056"
    },
    {
        "engDate": "1999-05-11",
        "nepDate": "28.01.2056"
    },
    {
        "engDate": "1999-05-12",
        "nepDate": "29.01.2056"
    },
    {
        "engDate": "1999-05-13",
        "nepDate": "30.01.2056"
    },
    {
        "engDate": "1999-05-14",
        "nepDate": "31.01.2056"
    },
    {
        "engDate": "1999-05-15",
        "nepDate": "01.02.2056"
    },
    {
        "engDate": "1999-05-16",
        "nepDate": "02.02.2056"
    },
    {
        "engDate": "1999-05-17",
        "nepDate": "03.02.2056"
    },
    {
        "engDate": "1999-05-18",
        "nepDate": "04.02.2056"
    },
    {
        "engDate": "1999-05-19",
        "nepDate": "05.02.2056"
    },
    {
        "engDate": "1999-05-20",
        "nepDate": "06.02.2056"
    },
    {
        "engDate": "1999-05-21",
        "nepDate": "07.02.2056"
    },
    {
        "engDate": "1999-05-22",
        "nepDate": "08.02.2056"
    },
    {
        "engDate": "1999-05-23",
        "nepDate": "09.02.2056"
    },
    {
        "engDate": "1999-05-24",
        "nepDate": "10.02.2056"
    },
    {
        "engDate": "1999-05-25",
        "nepDate": "11.02.2056"
    },
    {
        "engDate": "1999-05-26",
        "nepDate": "12.02.2056"
    },
    {
        "engDate": "1999-05-27",
        "nepDate": "13.02.2056"
    },
    {
        "engDate": "1999-05-28",
        "nepDate": "14.02.2056"
    },
    {
        "engDate": "1999-05-29",
        "nepDate": "15.02.2056"
    },
    {
        "engDate": "1999-05-30",
        "nepDate": "16.02.2056"
    },
    {
        "engDate": "1999-05-31",
        "nepDate": "17.02.2056"
    },
    {
        "engDate": "1999-06-01",
        "nepDate": "18.02.2056"
    },
    {
        "engDate": "1999-06-02",
        "nepDate": "19.02.2056"
    },
    {
        "engDate": "1999-06-03",
        "nepDate": "20.02.2056"
    },
    {
        "engDate": "1999-06-04",
        "nepDate": "21.02.2056"
    },
    {
        "engDate": "1999-06-05",
        "nepDate": "22.02.2056"
    },
    {
        "engDate": "1999-06-06",
        "nepDate": "23.02.2056"
    },
    {
        "engDate": "1999-06-07",
        "nepDate": "24.02.2056"
    },
    {
        "engDate": "1999-06-08",
        "nepDate": "25.02.2056"
    },
    {
        "engDate": "1999-06-09",
        "nepDate": "26.02.2056"
    },
    {
        "engDate": "1999-06-10",
        "nepDate": "27.02.2056"
    },
    {
        "engDate": "1999-06-11",
        "nepDate": "28.02.2056"
    },
    {
        "engDate": "1999-06-12",
        "nepDate": "29.02.2056"
    },
    {
        "engDate": "1999-06-13",
        "nepDate": "30.02.2056"
    },
    {
        "engDate": "1999-06-14",
        "nepDate": "31.02.2056"
    },
    {
        "engDate": "1999-06-15",
        "nepDate": "01.03.2056"
    },
    {
        "engDate": "1999-06-16",
        "nepDate": "02.03.2056"
    },
    {
        "engDate": "1999-06-17",
        "nepDate": "03.03.2056"
    },
    {
        "engDate": "1999-06-18",
        "nepDate": "04.03.2056"
    },
    {
        "engDate": "1999-06-19",
        "nepDate": "05.03.2056"
    },
    {
        "engDate": "1999-06-20",
        "nepDate": "06.03.2056"
    },
    {
        "engDate": "1999-06-21",
        "nepDate": "07.03.2056"
    },
    {
        "engDate": "1999-06-22",
        "nepDate": "08.03.2056"
    },
    {
        "engDate": "1999-06-23",
        "nepDate": "09.03.2056"
    },
    {
        "engDate": "1999-06-24",
        "nepDate": "10.03.2056"
    },
    {
        "engDate": "1999-06-25",
        "nepDate": "11.03.2056"
    },
    {
        "engDate": "1999-06-26",
        "nepDate": "12.03.2056"
    },
    {
        "engDate": "1999-06-27",
        "nepDate": "13.03.2056"
    },
    {
        "engDate": "1999-06-28",
        "nepDate": "14.03.2056"
    },
    {
        "engDate": "1999-06-29",
        "nepDate": "15.03.2056"
    },
    {
        "engDate": "1999-06-30",
        "nepDate": "16.03.2056"
    },
    {
        "engDate": "1999-07-01",
        "nepDate": "17.03.2056"
    },
    {
        "engDate": "1999-07-02",
        "nepDate": "18.03.2056"
    },
    {
        "engDate": "1999-07-03",
        "nepDate": "19.03.2056"
    },
    {
        "engDate": "1999-07-04",
        "nepDate": "20.03.2056"
    },
    {
        "engDate": "1999-07-05",
        "nepDate": "21.03.2056"
    },
    {
        "engDate": "1999-07-06",
        "nepDate": "22.03.2056"
    },
    {
        "engDate": "1999-07-07",
        "nepDate": "23.03.2056"
    },
    {
        "engDate": "1999-07-08",
        "nepDate": "24.03.2056"
    },
    {
        "engDate": "1999-07-09",
        "nepDate": "25.03.2056"
    },
    {
        "engDate": "1999-07-10",
        "nepDate": "26.03.2056"
    },
    {
        "engDate": "1999-07-11",
        "nepDate": "27.03.2056"
    },
    {
        "engDate": "1999-07-12",
        "nepDate": "28.03.2056"
    },
    {
        "engDate": "1999-07-13",
        "nepDate": "29.03.2056"
    },
    {
        "engDate": "1999-07-14",
        "nepDate": "30.03.2056"
    },
    {
        "engDate": "1999-07-15",
        "nepDate": "31.03.2056"
    },
    {
        "engDate": "1999-07-16",
        "nepDate": "32.03.2056"
    },
    {
        "engDate": "1999-07-17",
        "nepDate": "01.04.2056"
    },
    {
        "engDate": "1999-07-18",
        "nepDate": "02.04.2056"
    },
    {
        "engDate": "1999-07-19",
        "nepDate": "03.04.2056"
    },
    {
        "engDate": "1999-07-20",
        "nepDate": "04.04.2056"
    },
    {
        "engDate": "1999-07-21",
        "nepDate": "05.04.2056"
    },
    {
        "engDate": "1999-07-22",
        "nepDate": "06.04.2056"
    },
    {
        "engDate": "1999-07-23",
        "nepDate": "07.04.2056"
    },
    {
        "engDate": "1999-07-24",
        "nepDate": "08.04.2056"
    },
    {
        "engDate": "1999-07-25",
        "nepDate": "09.04.2056"
    },
    {
        "engDate": "1999-07-26",
        "nepDate": "10.04.2056"
    },
    {
        "engDate": "1999-07-27",
        "nepDate": "11.04.2056"
    },
    {
        "engDate": "1999-07-28",
        "nepDate": "12.04.2056"
    },
    {
        "engDate": "1999-07-29",
        "nepDate": "13.04.2056"
    },
    {
        "engDate": "1999-07-30",
        "nepDate": "14.04.2056"
    },
    {
        "engDate": "1999-07-31",
        "nepDate": "15.04.2056"
    },
    {
        "engDate": "1999-08-01",
        "nepDate": "16.04.2056"
    },
    {
        "engDate": "1999-08-02",
        "nepDate": "17.04.2056"
    },
    {
        "engDate": "1999-08-03",
        "nepDate": "18.04.2056"
    },
    {
        "engDate": "1999-08-04",
        "nepDate": "19.04.2056"
    },
    {
        "engDate": "1999-08-05",
        "nepDate": "20.04.2056"
    },
    {
        "engDate": "1999-08-06",
        "nepDate": "21.04.2056"
    },
    {
        "engDate": "1999-08-07",
        "nepDate": "22.04.2056"
    },
    {
        "engDate": "1999-08-08",
        "nepDate": "23.04.2056"
    },
    {
        "engDate": "1999-08-09",
        "nepDate": "24.04.2056"
    },
    {
        "engDate": "1999-08-10",
        "nepDate": "25.04.2056"
    },
    {
        "engDate": "1999-08-11",
        "nepDate": "26.04.2056"
    },
    {
        "engDate": "1999-08-12",
        "nepDate": "27.04.2056"
    },
    {
        "engDate": "1999-08-13",
        "nepDate": "28.04.2056"
    },
    {
        "engDate": "1999-08-14",
        "nepDate": "29.04.2056"
    },
    {
        "engDate": "1999-08-15",
        "nepDate": "30.04.2056"
    },
    {
        "engDate": "1999-08-16",
        "nepDate": "31.04.2056"
    },
    {
        "engDate": "1999-08-17",
        "nepDate": "01.05.2056"
    },
    {
        "engDate": "1999-08-18",
        "nepDate": "02.05.2056"
    },
    {
        "engDate": "1999-08-19",
        "nepDate": "03.05.2056"
    },
    {
        "engDate": "1999-08-20",
        "nepDate": "04.05.2056"
    },
    {
        "engDate": "1999-08-21",
        "nepDate": "05.05.2056"
    },
    {
        "engDate": "1999-08-22",
        "nepDate": "06.05.2056"
    },
    {
        "engDate": "1999-08-23",
        "nepDate": "07.05.2056"
    },
    {
        "engDate": "1999-08-24",
        "nepDate": "08.05.2056"
    },
    {
        "engDate": "1999-08-25",
        "nepDate": "09.05.2056"
    },
    {
        "engDate": "1999-08-26",
        "nepDate": "10.05.2056"
    },
    {
        "engDate": "1999-08-27",
        "nepDate": "11.05.2056"
    },
    {
        "engDate": "1999-08-28",
        "nepDate": "12.05.2056"
    },
    {
        "engDate": "1999-08-29",
        "nepDate": "13.05.2056"
    },
    {
        "engDate": "1999-08-30",
        "nepDate": "14.05.2056"
    },
    {
        "engDate": "1999-08-31",
        "nepDate": "15.05.2056"
    },
    {
        "engDate": "1999-09-01",
        "nepDate": "16.05.2056"
    },
    {
        "engDate": "1999-09-02",
        "nepDate": "17.05.2056"
    },
    {
        "engDate": "1999-09-03",
        "nepDate": "18.05.2056"
    },
    {
        "engDate": "1999-09-04",
        "nepDate": "19.05.2056"
    },
    {
        "engDate": "1999-09-05",
        "nepDate": "20.05.2056"
    },
    {
        "engDate": "1999-09-06",
        "nepDate": "21.05.2056"
    },
    {
        "engDate": "1999-09-07",
        "nepDate": "22.05.2056"
    },
    {
        "engDate": "1999-09-08",
        "nepDate": "23.05.2056"
    },
    {
        "engDate": "1999-09-09",
        "nepDate": "24.05.2056"
    },
    {
        "engDate": "1999-09-10",
        "nepDate": "25.05.2056"
    },
    {
        "engDate": "1999-09-11",
        "nepDate": "26.05.2056"
    },
    {
        "engDate": "1999-09-12",
        "nepDate": "27.05.2056"
    },
    {
        "engDate": "1999-09-13",
        "nepDate": "28.05.2056"
    },
    {
        "engDate": "1999-09-14",
        "nepDate": "29.05.2056"
    },
    {
        "engDate": "1999-09-15",
        "nepDate": "30.05.2056"
    },
    {
        "engDate": "1999-09-16",
        "nepDate": "31.05.2056"
    },
    {
        "engDate": "1999-09-17",
        "nepDate": "32.05.2056"
    },
    {
        "engDate": "1999-09-18",
        "nepDate": "01.06.2056"
    },
    {
        "engDate": "1999-09-19",
        "nepDate": "02.06.2056"
    },
    {
        "engDate": "1999-09-20",
        "nepDate": "03.06.2056"
    },
    {
        "engDate": "1999-09-21",
        "nepDate": "04.06.2056"
    },
    {
        "engDate": "1999-09-22",
        "nepDate": "05.06.2056"
    },
    {
        "engDate": "1999-09-23",
        "nepDate": "06.06.2056"
    },
    {
        "engDate": "1999-09-24",
        "nepDate": "07.06.2056"
    },
    {
        "engDate": "1999-09-25",
        "nepDate": "08.06.2056"
    },
    {
        "engDate": "1999-09-26",
        "nepDate": "09.06.2056"
    },
    {
        "engDate": "1999-09-27",
        "nepDate": "10.06.2056"
    },
    {
        "engDate": "1999-09-28",
        "nepDate": "11.06.2056"
    },
    {
        "engDate": "1999-09-29",
        "nepDate": "12.06.2056"
    },
    {
        "engDate": "1999-09-30",
        "nepDate": "13.06.2056"
    },
    {
        "engDate": "1999-10-01",
        "nepDate": "14.06.2056"
    },
    {
        "engDate": "1999-10-02",
        "nepDate": "15.06.2056"
    },
    {
        "engDate": "1999-10-03",
        "nepDate": "16.06.2056"
    },
    {
        "engDate": "1999-10-04",
        "nepDate": "17.06.2056"
    },
    {
        "engDate": "1999-10-05",
        "nepDate": "18.06.2056"
    },
    {
        "engDate": "1999-10-06",
        "nepDate": "19.06.2056"
    },
    {
        "engDate": "1999-10-07",
        "nepDate": "20.06.2056"
    },
    {
        "engDate": "1999-10-08",
        "nepDate": "21.06.2056"
    },
    {
        "engDate": "1999-10-09",
        "nepDate": "22.06.2056"
    },
    {
        "engDate": "1999-10-10",
        "nepDate": "23.06.2056"
    },
    {
        "engDate": "1999-10-11",
        "nepDate": "24.06.2056"
    },
    {
        "engDate": "1999-10-12",
        "nepDate": "25.06.2056"
    },
    {
        "engDate": "1999-10-13",
        "nepDate": "26.06.2056"
    },
    {
        "engDate": "1999-10-14",
        "nepDate": "27.06.2056"
    },
    {
        "engDate": "1999-10-15",
        "nepDate": "28.06.2056"
    },
    {
        "engDate": "1999-10-16",
        "nepDate": "29.06.2056"
    },
    {
        "engDate": "1999-10-17",
        "nepDate": "30.06.2056"
    },
    {
        "engDate": "1999-10-18",
        "nepDate": "01.07.2056"
    },
    {
        "engDate": "1999-10-19",
        "nepDate": "02.07.2056"
    },
    {
        "engDate": "1999-10-20",
        "nepDate": "03.07.2056"
    },
    {
        "engDate": "1999-10-21",
        "nepDate": "04.07.2056"
    },
    {
        "engDate": "1999-10-22",
        "nepDate": "05.07.2056"
    },
    {
        "engDate": "1999-10-23",
        "nepDate": "06.07.2056"
    },
    {
        "engDate": "1999-10-24",
        "nepDate": "07.07.2056"
    },
    {
        "engDate": "1999-10-25",
        "nepDate": "08.07.2056"
    },
    {
        "engDate": "1999-10-26",
        "nepDate": "09.07.2056"
    },
    {
        "engDate": "1999-10-27",
        "nepDate": "10.07.2056"
    },
    {
        "engDate": "1999-10-28",
        "nepDate": "11.07.2056"
    },
    {
        "engDate": "1999-10-29",
        "nepDate": "12.07.2056"
    },
    {
        "engDate": "1999-10-30",
        "nepDate": "13.07.2056"
    },
    {
        "engDate": "1999-10-31",
        "nepDate": "14.07.2056"
    },
    {
        "engDate": "1999-11-01",
        "nepDate": "15.07.2056"
    },
    {
        "engDate": "1999-11-02",
        "nepDate": "16.07.2056"
    },
    {
        "engDate": "1999-11-03",
        "nepDate": "17.07.2056"
    },
    {
        "engDate": "1999-11-04",
        "nepDate": "18.07.2056"
    },
    {
        "engDate": "1999-11-05",
        "nepDate": "19.07.2056"
    },
    {
        "engDate": "1999-11-06",
        "nepDate": "20.07.2056"
    },
    {
        "engDate": "1999-11-07",
        "nepDate": "21.07.2056"
    },
    {
        "engDate": "1999-11-08",
        "nepDate": "22.07.2056"
    },
    {
        "engDate": "1999-11-09",
        "nepDate": "23.07.2056"
    },
    {
        "engDate": "1999-11-10",
        "nepDate": "24.07.2056"
    },
    {
        "engDate": "1999-11-11",
        "nepDate": "25.07.2056"
    },
    {
        "engDate": "1999-11-12",
        "nepDate": "26.07.2056"
    },
    {
        "engDate": "1999-11-13",
        "nepDate": "27.07.2056"
    },
    {
        "engDate": "1999-11-14",
        "nepDate": "28.07.2056"
    },
    {
        "engDate": "1999-11-15",
        "nepDate": "29.07.2056"
    },
    {
        "engDate": "1999-11-16",
        "nepDate": "30.07.2056"
    },
    {
        "engDate": "1999-11-17",
        "nepDate": "01.08.2056"
    },
    {
        "engDate": "1999-11-18",
        "nepDate": "02.08.2056"
    },
    {
        "engDate": "1999-11-19",
        "nepDate": "03.08.2056"
    },
    {
        "engDate": "1999-11-20",
        "nepDate": "04.08.2056"
    },
    {
        "engDate": "1999-11-21",
        "nepDate": "05.08.2056"
    },
    {
        "engDate": "1999-11-22",
        "nepDate": "06.08.2056"
    },
    {
        "engDate": "1999-11-23",
        "nepDate": "07.08.2056"
    },
    {
        "engDate": "1999-11-24",
        "nepDate": "08.08.2056"
    },
    {
        "engDate": "1999-11-25",
        "nepDate": "09.08.2056"
    },
    {
        "engDate": "1999-11-26",
        "nepDate": "10.08.2056"
    },
    {
        "engDate": "1999-11-27",
        "nepDate": "11.08.2056"
    },
    {
        "engDate": "1999-11-28",
        "nepDate": "12.08.2056"
    },
    {
        "engDate": "1999-11-29",
        "nepDate": "13.08.2056"
    },
    {
        "engDate": "1999-11-30",
        "nepDate": "14.08.2056"
    },
    {
        "engDate": "1999-12-01",
        "nepDate": "15.08.2056"
    },
    {
        "engDate": "1999-12-02",
        "nepDate": "16.08.2056"
    },
    {
        "engDate": "1999-12-03",
        "nepDate": "17.08.2056"
    },
    {
        "engDate": "1999-12-04",
        "nepDate": "18.08.2056"
    },
    {
        "engDate": "1999-12-05",
        "nepDate": "19.08.2056"
    },
    {
        "engDate": "1999-12-06",
        "nepDate": "20.08.2056"
    },
    {
        "engDate": "1999-12-07",
        "nepDate": "21.08.2056"
    },
    {
        "engDate": "1999-12-08",
        "nepDate": "22.08.2056"
    },
    {
        "engDate": "1999-12-09",
        "nepDate": "23.08.2056"
    },
    {
        "engDate": "1999-12-10",
        "nepDate": "24.08.2056"
    },
    {
        "engDate": "1999-12-11",
        "nepDate": "25.08.2056"
    },
    {
        "engDate": "1999-12-12",
        "nepDate": "26.08.2056"
    },
    {
        "engDate": "1999-12-13",
        "nepDate": "27.08.2056"
    },
    {
        "engDate": "1999-12-14",
        "nepDate": "28.08.2056"
    },
    {
        "engDate": "1999-12-15",
        "nepDate": "29.08.2056"
    },
    {
        "engDate": "1999-12-16",
        "nepDate": "01.09.2056"
    },
    {
        "engDate": "1999-12-17",
        "nepDate": "02.09.2056"
    },
    {
        "engDate": "1999-12-18",
        "nepDate": "03.09.2056"
    },
    {
        "engDate": "1999-12-19",
        "nepDate": "04.09.2056"
    },
    {
        "engDate": "1999-12-20",
        "nepDate": "05.09.2056"
    },
    {
        "engDate": "1999-12-21",
        "nepDate": "06.09.2056"
    },
    {
        "engDate": "1999-12-22",
        "nepDate": "07.09.2056"
    },
    {
        "engDate": "1999-12-23",
        "nepDate": "08.09.2056"
    },
    {
        "engDate": "1999-12-24",
        "nepDate": "09.09.2056"
    },
    {
        "engDate": "1999-12-25",
        "nepDate": "10.09.2056"
    },
    {
        "engDate": "1999-12-26",
        "nepDate": "11.09.2056"
    },
    {
        "engDate": "1999-12-27",
        "nepDate": "12.09.2056"
    },
    {
        "engDate": "1999-12-28",
        "nepDate": "13.09.2056"
    },
    {
        "engDate": "1999-12-29",
        "nepDate": "14.09.2056"
    },
    {
        "engDate": "1999-12-30",
        "nepDate": "15.09.2056"
    },
    {
        "engDate": "1999-12-31",
        "nepDate": "16.09.2056"
    },
    {
        "engDate": "2000-01-01",
        "nepDate": "17.09.2056"
    },
    {
        "engDate": "2000-01-02",
        "nepDate": "18.09.2056"
    },
    {
        "engDate": "2000-01-03",
        "nepDate": "19.09.2056"
    },
    {
        "engDate": "2000-01-04",
        "nepDate": "20.09.2056"
    },
    {
        "engDate": "2000-01-05",
        "nepDate": "21.09.2056"
    },
    {
        "engDate": "2000-01-06",
        "nepDate": "22.09.2056"
    },
    {
        "engDate": "2000-01-07",
        "nepDate": "23.09.2056"
    },
    {
        "engDate": "2000-01-08",
        "nepDate": "24.09.2056"
    },
    {
        "engDate": "2000-01-09",
        "nepDate": "25.09.2056"
    },
    {
        "engDate": "2000-01-10",
        "nepDate": "26.09.2056"
    },
    {
        "engDate": "2000-01-11",
        "nepDate": "27.09.2056"
    },
    {
        "engDate": "2000-01-12",
        "nepDate": "28.09.2056"
    },
    {
        "engDate": "2000-01-13",
        "nepDate": "29.09.2056"
    },
    {
        "engDate": "2000-01-14",
        "nepDate": "30.09.2056"
    },
    {
        "engDate": "2000-01-15",
        "nepDate": "01.10.2056"
    },
    {
        "engDate": "2000-01-16",
        "nepDate": "02.10.2056"
    },
    {
        "engDate": "2000-01-17",
        "nepDate": "03.10.2056"
    },
    {
        "engDate": "2000-01-18",
        "nepDate": "04.10.2056"
    },
    {
        "engDate": "2000-01-19",
        "nepDate": "05.10.2056"
    },
    {
        "engDate": "2000-01-20",
        "nepDate": "06.10.2056"
    },
    {
        "engDate": "2000-01-21",
        "nepDate": "07.10.2056"
    },
    {
        "engDate": "2000-01-22",
        "nepDate": "08.10.2056"
    },
    {
        "engDate": "2000-01-23",
        "nepDate": "09.10.2056"
    },
    {
        "engDate": "2000-01-24",
        "nepDate": "10.10.2056"
    },
    {
        "engDate": "2000-01-25",
        "nepDate": "11.10.2056"
    },
    {
        "engDate": "2000-01-26",
        "nepDate": "12.10.2056"
    },
    {
        "engDate": "2000-01-27",
        "nepDate": "13.10.2056"
    },
    {
        "engDate": "2000-01-28",
        "nepDate": "14.10.2056"
    },
    {
        "engDate": "2000-01-29",
        "nepDate": "15.10.2056"
    },
    {
        "engDate": "2000-01-30",
        "nepDate": "16.10.2056"
    },
    {
        "engDate": "2000-01-31",
        "nepDate": "17.10.2056"
    },
    {
        "engDate": "2000-02-01",
        "nepDate": "18.10.2056"
    },
    {
        "engDate": "2000-02-02",
        "nepDate": "19.10.2056"
    },
    {
        "engDate": "2000-02-03",
        "nepDate": "20.10.2056"
    },
    {
        "engDate": "2000-02-04",
        "nepDate": "21.10.2056"
    },
    {
        "engDate": "2000-02-05",
        "nepDate": "22.10.2056"
    },
    {
        "engDate": "2000-02-06",
        "nepDate": "23.10.2056"
    },
    {
        "engDate": "2000-02-07",
        "nepDate": "24.10.2056"
    },
    {
        "engDate": "2000-02-08",
        "nepDate": "25.10.2056"
    },
    {
        "engDate": "2000-02-09",
        "nepDate": "26.10.2056"
    },
    {
        "engDate": "2000-02-10",
        "nepDate": "27.10.2056"
    },
    {
        "engDate": "2000-02-11",
        "nepDate": "28.10.2056"
    },
    {
        "engDate": "2000-02-12",
        "nepDate": "29.10.2056"
    },
    {
        "engDate": "2000-02-13",
        "nepDate": "01.11.2056"
    },
    {
        "engDate": "2000-02-14",
        "nepDate": "02.11.2056"
    },
    {
        "engDate": "2000-02-15",
        "nepDate": "03.11.2056"
    },
    {
        "engDate": "2000-02-16",
        "nepDate": "04.11.2056"
    },
    {
        "engDate": "2000-02-17",
        "nepDate": "05.11.2056"
    },
    {
        "engDate": "2000-02-18",
        "nepDate": "06.11.2056"
    },
    {
        "engDate": "2000-02-19",
        "nepDate": "07.11.2056"
    },
    {
        "engDate": "2000-02-20",
        "nepDate": "08.11.2056"
    },
    {
        "engDate": "2000-02-21",
        "nepDate": "09.11.2056"
    },
    {
        "engDate": "2000-02-22",
        "nepDate": "10.11.2056"
    },
    {
        "engDate": "2000-02-23",
        "nepDate": "11.11.2056"
    },
    {
        "engDate": "2000-02-24",
        "nepDate": "12.11.2056"
    },
    {
        "engDate": "2000-02-25",
        "nepDate": "13.11.2056"
    },
    {
        "engDate": "2000-02-26",
        "nepDate": "14.11.2056"
    },
    {
        "engDate": "2000-02-27",
        "nepDate": "15.11.2056"
    },
    {
        "engDate": "2000-02-28",
        "nepDate": "16.11.2056"
    },
    {
        "engDate": "2000-02-29",
        "nepDate": "17.11.2056"
    },
    {
        "engDate": "2000-03-01",
        "nepDate": "18.11.2056"
    },
    {
        "engDate": "2000-03-02",
        "nepDate": "19.11.2056"
    },
    {
        "engDate": "2000-03-03",
        "nepDate": "20.11.2056"
    },
    {
        "engDate": "2000-03-04",
        "nepDate": "21.11.2056"
    },
    {
        "engDate": "2000-03-05",
        "nepDate": "22.11.2056"
    },
    {
        "engDate": "2000-03-06",
        "nepDate": "23.11.2056"
    },
    {
        "engDate": "2000-03-07",
        "nepDate": "24.11.2056"
    },
    {
        "engDate": "2000-03-08",
        "nepDate": "25.11.2056"
    },
    {
        "engDate": "2000-03-09",
        "nepDate": "26.11.2056"
    },
    {
        "engDate": "2000-03-10",
        "nepDate": "27.11.2056"
    },
    {
        "engDate": "2000-03-11",
        "nepDate": "28.11.2056"
    },
    {
        "engDate": "2000-03-12",
        "nepDate": "29.11.2056"
    },
    {
        "engDate": "2000-03-13",
        "nepDate": "30.11.2056"
    },
    {
        "engDate": "2000-03-14",
        "nepDate": "01.12.2056"
    },
    {
        "engDate": "2000-03-15",
        "nepDate": "02.12.2056"
    },
    {
        "engDate": "2000-03-16",
        "nepDate": "03.12.2056"
    },
    {
        "engDate": "2000-03-17",
        "nepDate": "04.12.2056"
    },
    {
        "engDate": "2000-03-18",
        "nepDate": "05.12.2056"
    },
    {
        "engDate": "2000-03-19",
        "nepDate": "06.12.2056"
    },
    {
        "engDate": "2000-03-20",
        "nepDate": "07.12.2056"
    },
    {
        "engDate": "2000-03-21",
        "nepDate": "08.12.2056"
    },
    {
        "engDate": "2000-03-22",
        "nepDate": "09.12.2056"
    },
    {
        "engDate": "2000-03-23",
        "nepDate": "10.12.2056"
    },
    {
        "engDate": "2000-03-24",
        "nepDate": "11.12.2056"
    },
    {
        "engDate": "2000-03-25",
        "nepDate": "12.12.2056"
    },
    {
        "engDate": "2000-03-26",
        "nepDate": "13.12.2056"
    },
    {
        "engDate": "2000-03-27",
        "nepDate": "14.12.2056"
    },
    {
        "engDate": "2000-03-28",
        "nepDate": "15.12.2056"
    },
    {
        "engDate": "2000-03-29",
        "nepDate": "16.12.2056"
    },
    {
        "engDate": "2000-03-30",
        "nepDate": "17.12.2056"
    },
    {
        "engDate": "2000-03-31",
        "nepDate": "18.12.2056"
    },
    {
        "engDate": "2000-04-01",
        "nepDate": "19.12.2056"
    },
    {
        "engDate": "2000-04-02",
        "nepDate": "20.12.2056"
    },
    {
        "engDate": "2000-04-03",
        "nepDate": "21.12.2056"
    },
    {
        "engDate": "2000-04-04",
        "nepDate": "22.12.2056"
    },
    {
        "engDate": "2000-04-05",
        "nepDate": "23.12.2056"
    },
    {
        "engDate": "2000-04-06",
        "nepDate": "24.12.2056"
    },
    {
        "engDate": "2000-04-07",
        "nepDate": "25.12.2056"
    },
    {
        "engDate": "2000-04-08",
        "nepDate": "26.12.2056"
    },
    {
        "engDate": "2000-04-09",
        "nepDate": "27.12.2056"
    },
    {
        "engDate": "2000-04-10",
        "nepDate": "28.12.2056"
    },
    {
        "engDate": "2000-04-11",
        "nepDate": "29.12.2056"
    },
    {
        "engDate": "2000-04-12",
        "nepDate": "30.12.2056"
    },
    {
        "engDate": "2000-04-13",
        "nepDate": "01.01.2057"
    },
    {
        "engDate": "2000-04-14",
        "nepDate": "02.01.2057"
    },
    {
        "engDate": "2000-04-15",
        "nepDate": "03.01.2057"
    },
    {
        "engDate": "2000-04-16",
        "nepDate": "04.01.2057"
    },
    {
        "engDate": "2000-04-17",
        "nepDate": "05.01.2057"
    },
    {
        "engDate": "2000-04-18",
        "nepDate": "06.01.2057"
    },
    {
        "engDate": "2000-04-19",
        "nepDate": "07.01.2057"
    },
    {
        "engDate": "2000-04-20",
        "nepDate": "08.01.2057"
    },
    {
        "engDate": "2000-04-21",
        "nepDate": "09.01.2057"
    },
    {
        "engDate": "2000-04-22",
        "nepDate": "10.01.2057"
    },
    {
        "engDate": "2000-04-23",
        "nepDate": "11.01.2057"
    },
    {
        "engDate": "2000-04-24",
        "nepDate": "12.01.2057"
    },
    {
        "engDate": "2000-04-25",
        "nepDate": "13.01.2057"
    },
    {
        "engDate": "2000-04-26",
        "nepDate": "14.01.2057"
    },
    {
        "engDate": "2000-04-27",
        "nepDate": "15.01.2057"
    },
    {
        "engDate": "2000-04-28",
        "nepDate": "16.01.2057"
    },
    {
        "engDate": "2000-04-29",
        "nepDate": "17.01.2057"
    },
    {
        "engDate": "2000-04-30",
        "nepDate": "18.01.2057"
    },
    {
        "engDate": "2000-05-01",
        "nepDate": "19.01.2057"
    },
    {
        "engDate": "2000-05-02",
        "nepDate": "20.01.2057"
    },
    {
        "engDate": "2000-05-03",
        "nepDate": "21.01.2057"
    },
    {
        "engDate": "2000-05-04",
        "nepDate": "22.01.2057"
    },
    {
        "engDate": "2000-05-05",
        "nepDate": "23.01.2057"
    },
    {
        "engDate": "2000-05-06",
        "nepDate": "24.01.2057"
    },
    {
        "engDate": "2000-05-07",
        "nepDate": "25.01.2057"
    },
    {
        "engDate": "2000-05-08",
        "nepDate": "26.01.2057"
    },
    {
        "engDate": "2000-05-09",
        "nepDate": "27.01.2057"
    },
    {
        "engDate": "2000-05-10",
        "nepDate": "28.01.2057"
    },
    {
        "engDate": "2000-05-11",
        "nepDate": "29.01.2057"
    },
    {
        "engDate": "2000-05-12",
        "nepDate": "30.01.2057"
    },
    {
        "engDate": "2000-05-13",
        "nepDate": "31.01.2057"
    },
    {
        "engDate": "2000-05-14",
        "nepDate": "01.02.2057"
    },
    {
        "engDate": "2000-05-15",
        "nepDate": "02.02.2057"
    },
    {
        "engDate": "2000-05-16",
        "nepDate": "03.02.2057"
    },
    {
        "engDate": "2000-05-17",
        "nepDate": "04.02.2057"
    },
    {
        "engDate": "2000-05-18",
        "nepDate": "05.02.2057"
    },
    {
        "engDate": "2000-05-19",
        "nepDate": "06.02.2057"
    },
    {
        "engDate": "2000-05-20",
        "nepDate": "07.02.2057"
    },
    {
        "engDate": "2000-05-21",
        "nepDate": "08.02.2057"
    },
    {
        "engDate": "2000-05-22",
        "nepDate": "09.02.2057"
    },
    {
        "engDate": "2000-05-23",
        "nepDate": "10.02.2057"
    },
    {
        "engDate": "2000-05-24",
        "nepDate": "11.02.2057"
    },
    {
        "engDate": "2000-05-25",
        "nepDate": "12.02.2057"
    },
    {
        "engDate": "2000-05-26",
        "nepDate": "13.02.2057"
    },
    {
        "engDate": "2000-05-27",
        "nepDate": "14.02.2057"
    },
    {
        "engDate": "2000-05-28",
        "nepDate": "15.02.2057"
    },
    {
        "engDate": "2000-05-29",
        "nepDate": "16.02.2057"
    },
    {
        "engDate": "2000-05-30",
        "nepDate": "17.02.2057"
    },
    {
        "engDate": "2000-05-31",
        "nepDate": "18.02.2057"
    },
    {
        "engDate": "2000-06-01",
        "nepDate": "19.02.2057"
    },
    {
        "engDate": "2000-06-02",
        "nepDate": "20.02.2057"
    },
    {
        "engDate": "2000-06-03",
        "nepDate": "21.02.2057"
    },
    {
        "engDate": "2000-06-04",
        "nepDate": "22.02.2057"
    },
    {
        "engDate": "2000-06-05",
        "nepDate": "23.02.2057"
    },
    {
        "engDate": "2000-06-06",
        "nepDate": "24.02.2057"
    },
    {
        "engDate": "2000-06-07",
        "nepDate": "25.02.2057"
    },
    {
        "engDate": "2000-06-08",
        "nepDate": "26.02.2057"
    },
    {
        "engDate": "2000-06-09",
        "nepDate": "27.02.2057"
    },
    {
        "engDate": "2000-06-10",
        "nepDate": "28.02.2057"
    },
    {
        "engDate": "2000-06-11",
        "nepDate": "29.02.2057"
    },
    {
        "engDate": "2000-06-12",
        "nepDate": "30.02.2057"
    },
    {
        "engDate": "2000-06-13",
        "nepDate": "31.02.2057"
    },
    {
        "engDate": "2000-06-14",
        "nepDate": "32.02.2057"
    },
    {
        "engDate": "2000-06-15",
        "nepDate": "01.03.2057"
    },
    {
        "engDate": "2000-06-16",
        "nepDate": "02.03.2057"
    },
    {
        "engDate": "2000-06-17",
        "nepDate": "03.03.2057"
    },
    {
        "engDate": "2000-06-18",
        "nepDate": "04.03.2057"
    },
    {
        "engDate": "2000-06-19",
        "nepDate": "05.03.2057"
    },
    {
        "engDate": "2000-06-20",
        "nepDate": "06.03.2057"
    },
    {
        "engDate": "2000-06-21",
        "nepDate": "07.03.2057"
    },
    {
        "engDate": "2000-06-22",
        "nepDate": "08.03.2057"
    },
    {
        "engDate": "2000-06-23",
        "nepDate": "09.03.2057"
    },
    {
        "engDate": "2000-06-24",
        "nepDate": "10.03.2057"
    },
    {
        "engDate": "2000-06-25",
        "nepDate": "11.03.2057"
    },
    {
        "engDate": "2000-06-26",
        "nepDate": "12.03.2057"
    },
    {
        "engDate": "2000-06-27",
        "nepDate": "13.03.2057"
    },
    {
        "engDate": "2000-06-28",
        "nepDate": "14.03.2057"
    },
    {
        "engDate": "2000-06-29",
        "nepDate": "15.03.2057"
    },
    {
        "engDate": "2000-06-30",
        "nepDate": "16.03.2057"
    },
    {
        "engDate": "2000-07-01",
        "nepDate": "17.03.2057"
    },
    {
        "engDate": "2000-07-02",
        "nepDate": "18.03.2057"
    },
    {
        "engDate": "2000-07-03",
        "nepDate": "19.03.2057"
    },
    {
        "engDate": "2000-07-04",
        "nepDate": "20.03.2057"
    },
    {
        "engDate": "2000-07-05",
        "nepDate": "21.03.2057"
    },
    {
        "engDate": "2000-07-06",
        "nepDate": "22.03.2057"
    },
    {
        "engDate": "2000-07-07",
        "nepDate": "23.03.2057"
    },
    {
        "engDate": "2000-07-08",
        "nepDate": "24.03.2057"
    },
    {
        "engDate": "2000-07-09",
        "nepDate": "25.03.2057"
    },
    {
        "engDate": "2000-07-10",
        "nepDate": "26.03.2057"
    },
    {
        "engDate": "2000-07-11",
        "nepDate": "27.03.2057"
    },
    {
        "engDate": "2000-07-12",
        "nepDate": "28.03.2057"
    },
    {
        "engDate": "2000-07-13",
        "nepDate": "29.03.2057"
    },
    {
        "engDate": "2000-07-14",
        "nepDate": "30.03.2057"
    },
    {
        "engDate": "2000-07-15",
        "nepDate": "31.03.2057"
    },
    {
        "engDate": "2000-07-16",
        "nepDate": "01.04.2057"
    },
    {
        "engDate": "2000-07-17",
        "nepDate": "02.04.2057"
    },
    {
        "engDate": "2000-07-18",
        "nepDate": "03.04.2057"
    },
    {
        "engDate": "2000-07-19",
        "nepDate": "04.04.2057"
    },
    {
        "engDate": "2000-07-20",
        "nepDate": "05.04.2057"
    },
    {
        "engDate": "2000-07-21",
        "nepDate": "06.04.2057"
    },
    {
        "engDate": "2000-07-22",
        "nepDate": "07.04.2057"
    },
    {
        "engDate": "2000-07-23",
        "nepDate": "08.04.2057"
    },
    {
        "engDate": "2000-07-24",
        "nepDate": "09.04.2057"
    },
    {
        "engDate": "2000-07-25",
        "nepDate": "10.04.2057"
    },
    {
        "engDate": "2000-07-26",
        "nepDate": "11.04.2057"
    },
    {
        "engDate": "2000-07-27",
        "nepDate": "12.04.2057"
    },
    {
        "engDate": "2000-07-28",
        "nepDate": "13.04.2057"
    },
    {
        "engDate": "2000-07-29",
        "nepDate": "14.04.2057"
    },
    {
        "engDate": "2000-07-30",
        "nepDate": "15.04.2057"
    },
    {
        "engDate": "2000-07-31",
        "nepDate": "16.04.2057"
    },
    {
        "engDate": "2000-08-01",
        "nepDate": "17.04.2057"
    },
    {
        "engDate": "2000-08-02",
        "nepDate": "18.04.2057"
    },
    {
        "engDate": "2000-08-03",
        "nepDate": "19.04.2057"
    },
    {
        "engDate": "2000-08-04",
        "nepDate": "20.04.2057"
    },
    {
        "engDate": "2000-08-05",
        "nepDate": "21.04.2057"
    },
    {
        "engDate": "2000-08-06",
        "nepDate": "22.04.2057"
    },
    {
        "engDate": "2000-08-07",
        "nepDate": "23.04.2057"
    },
    {
        "engDate": "2000-08-08",
        "nepDate": "24.04.2057"
    },
    {
        "engDate": "2000-08-09",
        "nepDate": "25.04.2057"
    },
    {
        "engDate": "2000-08-10",
        "nepDate": "26.04.2057"
    },
    {
        "engDate": "2000-08-11",
        "nepDate": "27.04.2057"
    },
    {
        "engDate": "2000-08-12",
        "nepDate": "28.04.2057"
    },
    {
        "engDate": "2000-08-13",
        "nepDate": "29.04.2057"
    },
    {
        "engDate": "2000-08-14",
        "nepDate": "30.04.2057"
    },
    {
        "engDate": "2000-08-15",
        "nepDate": "31.04.2057"
    },
    {
        "engDate": "2000-08-16",
        "nepDate": "32.04.2057"
    },
    {
        "engDate": "2000-08-17",
        "nepDate": "01.05.2057"
    },
    {
        "engDate": "2000-08-18",
        "nepDate": "02.05.2057"
    },
    {
        "engDate": "2000-08-19",
        "nepDate": "03.05.2057"
    },
    {
        "engDate": "2000-08-20",
        "nepDate": "04.05.2057"
    },
    {
        "engDate": "2000-08-21",
        "nepDate": "05.05.2057"
    },
    {
        "engDate": "2000-08-22",
        "nepDate": "06.05.2057"
    },
    {
        "engDate": "2000-08-23",
        "nepDate": "07.05.2057"
    },
    {
        "engDate": "2000-08-24",
        "nepDate": "08.05.2057"
    },
    {
        "engDate": "2000-08-25",
        "nepDate": "09.05.2057"
    },
    {
        "engDate": "2000-08-26",
        "nepDate": "10.05.2057"
    },
    {
        "engDate": "2000-08-27",
        "nepDate": "11.05.2057"
    },
    {
        "engDate": "2000-08-28",
        "nepDate": "12.05.2057"
    },
    {
        "engDate": "2000-08-29",
        "nepDate": "13.05.2057"
    },
    {
        "engDate": "2000-08-30",
        "nepDate": "14.05.2057"
    },
    {
        "engDate": "2000-08-31",
        "nepDate": "15.05.2057"
    },
    {
        "engDate": "2000-09-01",
        "nepDate": "16.05.2057"
    },
    {
        "engDate": "2000-09-02",
        "nepDate": "17.05.2057"
    },
    {
        "engDate": "2000-09-03",
        "nepDate": "18.05.2057"
    },
    {
        "engDate": "2000-09-04",
        "nepDate": "19.05.2057"
    },
    {
        "engDate": "2000-09-05",
        "nepDate": "20.05.2057"
    },
    {
        "engDate": "2000-09-06",
        "nepDate": "21.05.2057"
    },
    {
        "engDate": "2000-09-07",
        "nepDate": "22.05.2057"
    },
    {
        "engDate": "2000-09-08",
        "nepDate": "23.05.2057"
    },
    {
        "engDate": "2000-09-09",
        "nepDate": "24.05.2057"
    },
    {
        "engDate": "2000-09-10",
        "nepDate": "25.05.2057"
    },
    {
        "engDate": "2000-09-11",
        "nepDate": "26.05.2057"
    },
    {
        "engDate": "2000-09-12",
        "nepDate": "27.05.2057"
    },
    {
        "engDate": "2000-09-13",
        "nepDate": "28.05.2057"
    },
    {
        "engDate": "2000-09-14",
        "nepDate": "29.05.2057"
    },
    {
        "engDate": "2000-09-15",
        "nepDate": "30.05.2057"
    },
    {
        "engDate": "2000-09-16",
        "nepDate": "31.05.2057"
    },
    {
        "engDate": "2000-09-17",
        "nepDate": "01.06.2057"
    },
    {
        "engDate": "2000-09-18",
        "nepDate": "02.06.2057"
    },
    {
        "engDate": "2000-09-19",
        "nepDate": "03.06.2057"
    },
    {
        "engDate": "2000-09-20",
        "nepDate": "04.06.2057"
    },
    {
        "engDate": "2000-09-21",
        "nepDate": "05.06.2057"
    },
    {
        "engDate": "2000-09-22",
        "nepDate": "06.06.2057"
    },
    {
        "engDate": "2000-09-23",
        "nepDate": "07.06.2057"
    },
    {
        "engDate": "2000-09-24",
        "nepDate": "08.06.2057"
    },
    {
        "engDate": "2000-09-25",
        "nepDate": "09.06.2057"
    },
    {
        "engDate": "2000-09-26",
        "nepDate": "10.06.2057"
    },
    {
        "engDate": "2000-09-27",
        "nepDate": "11.06.2057"
    },
    {
        "engDate": "2000-09-28",
        "nepDate": "12.06.2057"
    },
    {
        "engDate": "2000-09-29",
        "nepDate": "13.06.2057"
    },
    {
        "engDate": "2000-09-30",
        "nepDate": "14.06.2057"
    },
    {
        "engDate": "2000-10-01",
        "nepDate": "15.06.2057"
    },
    {
        "engDate": "2000-10-02",
        "nepDate": "16.06.2057"
    },
    {
        "engDate": "2000-10-03",
        "nepDate": "17.06.2057"
    },
    {
        "engDate": "2000-10-04",
        "nepDate": "18.06.2057"
    },
    {
        "engDate": "2000-10-05",
        "nepDate": "19.06.2057"
    },
    {
        "engDate": "2000-10-06",
        "nepDate": "20.06.2057"
    },
    {
        "engDate": "2000-10-07",
        "nepDate": "21.06.2057"
    },
    {
        "engDate": "2000-10-08",
        "nepDate": "22.06.2057"
    },
    {
        "engDate": "2000-10-09",
        "nepDate": "23.06.2057"
    },
    {
        "engDate": "2000-10-10",
        "nepDate": "24.06.2057"
    },
    {
        "engDate": "2000-10-11",
        "nepDate": "25.06.2057"
    },
    {
        "engDate": "2000-10-12",
        "nepDate": "26.06.2057"
    },
    {
        "engDate": "2000-10-13",
        "nepDate": "27.06.2057"
    },
    {
        "engDate": "2000-10-14",
        "nepDate": "28.06.2057"
    },
    {
        "engDate": "2000-10-15",
        "nepDate": "29.06.2057"
    },
    {
        "engDate": "2000-10-16",
        "nepDate": "30.06.2057"
    },
    {
        "engDate": "2000-10-17",
        "nepDate": "01.07.2057"
    },
    {
        "engDate": "2000-10-18",
        "nepDate": "02.07.2057"
    },
    {
        "engDate": "2000-10-19",
        "nepDate": "03.07.2057"
    },
    {
        "engDate": "2000-10-20",
        "nepDate": "04.07.2057"
    },
    {
        "engDate": "2000-10-21",
        "nepDate": "05.07.2057"
    },
    {
        "engDate": "2000-10-22",
        "nepDate": "06.07.2057"
    },
    {
        "engDate": "2000-10-23",
        "nepDate": "07.07.2057"
    },
    {
        "engDate": "2000-10-24",
        "nepDate": "08.07.2057"
    },
    {
        "engDate": "2000-10-25",
        "nepDate": "09.07.2057"
    },
    {
        "engDate": "2000-10-26",
        "nepDate": "10.07.2057"
    },
    {
        "engDate": "2000-10-27",
        "nepDate": "11.07.2057"
    },
    {
        "engDate": "2000-10-28",
        "nepDate": "12.07.2057"
    },
    {
        "engDate": "2000-10-29",
        "nepDate": "13.07.2057"
    },
    {
        "engDate": "2000-10-30",
        "nepDate": "14.07.2057"
    },
    {
        "engDate": "2000-10-31",
        "nepDate": "15.07.2057"
    },
    {
        "engDate": "2000-11-01",
        "nepDate": "16.07.2057"
    },
    {
        "engDate": "2000-11-02",
        "nepDate": "17.07.2057"
    },
    {
        "engDate": "2000-11-03",
        "nepDate": "18.07.2057"
    },
    {
        "engDate": "2000-11-04",
        "nepDate": "19.07.2057"
    },
    {
        "engDate": "2000-11-05",
        "nepDate": "20.07.2057"
    },
    {
        "engDate": "2000-11-06",
        "nepDate": "21.07.2057"
    },
    {
        "engDate": "2000-11-07",
        "nepDate": "22.07.2057"
    },
    {
        "engDate": "2000-11-08",
        "nepDate": "23.07.2057"
    },
    {
        "engDate": "2000-11-09",
        "nepDate": "24.07.2057"
    },
    {
        "engDate": "2000-11-10",
        "nepDate": "25.07.2057"
    },
    {
        "engDate": "2000-11-11",
        "nepDate": "26.07.2057"
    },
    {
        "engDate": "2000-11-12",
        "nepDate": "27.07.2057"
    },
    {
        "engDate": "2000-11-13",
        "nepDate": "28.07.2057"
    },
    {
        "engDate": "2000-11-14",
        "nepDate": "29.07.2057"
    },
    {
        "engDate": "2000-11-15",
        "nepDate": "30.07.2057"
    },
    {
        "engDate": "2000-11-16",
        "nepDate": "01.08.2057"
    },
    {
        "engDate": "2000-11-17",
        "nepDate": "02.08.2057"
    },
    {
        "engDate": "2000-11-18",
        "nepDate": "03.08.2057"
    },
    {
        "engDate": "2000-11-19",
        "nepDate": "04.08.2057"
    },
    {
        "engDate": "2000-11-20",
        "nepDate": "05.08.2057"
    },
    {
        "engDate": "2000-11-21",
        "nepDate": "06.08.2057"
    },
    {
        "engDate": "2000-11-22",
        "nepDate": "07.08.2057"
    },
    {
        "engDate": "2000-11-23",
        "nepDate": "08.08.2057"
    },
    {
        "engDate": "2000-11-24",
        "nepDate": "09.08.2057"
    },
    {
        "engDate": "2000-11-25",
        "nepDate": "10.08.2057"
    },
    {
        "engDate": "2000-11-26",
        "nepDate": "11.08.2057"
    },
    {
        "engDate": "2000-11-27",
        "nepDate": "12.08.2057"
    },
    {
        "engDate": "2000-11-28",
        "nepDate": "13.08.2057"
    },
    {
        "engDate": "2000-11-29",
        "nepDate": "14.08.2057"
    },
    {
        "engDate": "2000-11-30",
        "nepDate": "15.08.2057"
    },
    {
        "engDate": "2000-12-01",
        "nepDate": "16.08.2057"
    },
    {
        "engDate": "2000-12-02",
        "nepDate": "17.08.2057"
    },
    {
        "engDate": "2000-12-03",
        "nepDate": "18.08.2057"
    },
    {
        "engDate": "2000-12-04",
        "nepDate": "19.08.2057"
    },
    {
        "engDate": "2000-12-05",
        "nepDate": "20.08.2057"
    },
    {
        "engDate": "2000-12-06",
        "nepDate": "21.08.2057"
    },
    {
        "engDate": "2000-12-07",
        "nepDate": "22.08.2057"
    },
    {
        "engDate": "2000-12-08",
        "nepDate": "23.08.2057"
    },
    {
        "engDate": "2000-12-09",
        "nepDate": "24.08.2057"
    },
    {
        "engDate": "2000-12-10",
        "nepDate": "25.08.2057"
    },
    {
        "engDate": "2000-12-11",
        "nepDate": "26.08.2057"
    },
    {
        "engDate": "2000-12-12",
        "nepDate": "27.08.2057"
    },
    {
        "engDate": "2000-12-13",
        "nepDate": "28.08.2057"
    },
    {
        "engDate": "2000-12-14",
        "nepDate": "29.08.2057"
    },
    {
        "engDate": "2000-12-15",
        "nepDate": "30.08.2057"
    },
    {
        "engDate": "2000-12-16",
        "nepDate": "01.09.2057"
    },
    {
        "engDate": "2000-12-17",
        "nepDate": "02.09.2057"
    },
    {
        "engDate": "2000-12-18",
        "nepDate": "03.09.2057"
    },
    {
        "engDate": "2000-12-19",
        "nepDate": "04.09.2057"
    },
    {
        "engDate": "2000-12-20",
        "nepDate": "05.09.2057"
    },
    {
        "engDate": "2000-12-21",
        "nepDate": "06.09.2057"
    },
    {
        "engDate": "2000-12-22",
        "nepDate": "07.09.2057"
    },
    {
        "engDate": "2000-12-23",
        "nepDate": "08.09.2057"
    },
    {
        "engDate": "2000-12-24",
        "nepDate": "09.09.2057"
    },
    {
        "engDate": "2000-12-25",
        "nepDate": "10.09.2057"
    },
    {
        "engDate": "2000-12-26",
        "nepDate": "11.09.2057"
    },
    {
        "engDate": "2000-12-27",
        "nepDate": "12.09.2057"
    },
    {
        "engDate": "2000-12-28",
        "nepDate": "13.09.2057"
    },
    {
        "engDate": "2000-12-29",
        "nepDate": "14.09.2057"
    },
    {
        "engDate": "2000-12-30",
        "nepDate": "15.09.2057"
    },
    {
        "engDate": "2000-12-31",
        "nepDate": "16.09.2057"
    },
    {
        "engDate": "2001-01-01",
        "nepDate": "17.09.2057"
    },
    {
        "engDate": "2001-01-02",
        "nepDate": "18.09.2057"
    },
    {
        "engDate": "2001-01-03",
        "nepDate": "19.09.2057"
    },
    {
        "engDate": "2001-01-04",
        "nepDate": "20.09.2057"
    },
    {
        "engDate": "2001-01-05",
        "nepDate": "21.09.2057"
    },
    {
        "engDate": "2001-01-06",
        "nepDate": "22.09.2057"
    },
    {
        "engDate": "2001-01-07",
        "nepDate": "23.09.2057"
    },
    {
        "engDate": "2001-01-08",
        "nepDate": "24.09.2057"
    },
    {
        "engDate": "2001-01-09",
        "nepDate": "25.09.2057"
    },
    {
        "engDate": "2001-01-10",
        "nepDate": "26.09.2057"
    },
    {
        "engDate": "2001-01-11",
        "nepDate": "27.09.2057"
    },
    {
        "engDate": "2001-01-12",
        "nepDate": "28.09.2057"
    },
    {
        "engDate": "2001-01-13",
        "nepDate": "29.09.2057"
    },
    {
        "engDate": "2001-01-14",
        "nepDate": "01.10.2057"
    },
    {
        "engDate": "2001-01-15",
        "nepDate": "02.10.2057"
    },
    {
        "engDate": "2001-01-16",
        "nepDate": "03.10.2057"
    },
    {
        "engDate": "2001-01-17",
        "nepDate": "04.10.2057"
    },
    {
        "engDate": "2001-01-18",
        "nepDate": "05.10.2057"
    },
    {
        "engDate": "2001-01-19",
        "nepDate": "06.10.2057"
    },
    {
        "engDate": "2001-01-20",
        "nepDate": "07.10.2057"
    },
    {
        "engDate": "2001-01-21",
        "nepDate": "08.10.2057"
    },
    {
        "engDate": "2001-01-22",
        "nepDate": "09.10.2057"
    },
    {
        "engDate": "2001-01-23",
        "nepDate": "10.10.2057"
    },
    {
        "engDate": "2001-01-24",
        "nepDate": "11.10.2057"
    },
    {
        "engDate": "2001-01-25",
        "nepDate": "12.10.2057"
    },
    {
        "engDate": "2001-01-26",
        "nepDate": "13.10.2057"
    },
    {
        "engDate": "2001-01-27",
        "nepDate": "14.10.2057"
    },
    {
        "engDate": "2001-01-28",
        "nepDate": "15.10.2057"
    },
    {
        "engDate": "2001-01-29",
        "nepDate": "16.10.2057"
    },
    {
        "engDate": "2001-01-30",
        "nepDate": "17.10.2057"
    },
    {
        "engDate": "2001-01-31",
        "nepDate": "18.10.2057"
    },
    {
        "engDate": "2001-02-01",
        "nepDate": "19.10.2057"
    },
    {
        "engDate": "2001-02-02",
        "nepDate": "20.10.2057"
    },
    {
        "engDate": "2001-02-03",
        "nepDate": "21.10.2057"
    },
    {
        "engDate": "2001-02-04",
        "nepDate": "22.10.2057"
    },
    {
        "engDate": "2001-02-05",
        "nepDate": "23.10.2057"
    },
    {
        "engDate": "2001-02-06",
        "nepDate": "24.10.2057"
    },
    {
        "engDate": "2001-02-07",
        "nepDate": "25.10.2057"
    },
    {
        "engDate": "2001-02-08",
        "nepDate": "26.10.2057"
    },
    {
        "engDate": "2001-02-09",
        "nepDate": "27.10.2057"
    },
    {
        "engDate": "2001-02-10",
        "nepDate": "28.10.2057"
    },
    {
        "engDate": "2001-02-11",
        "nepDate": "29.10.2057"
    },
    {
        "engDate": "2001-02-12",
        "nepDate": "01.11.2057"
    },
    {
        "engDate": "2001-02-13",
        "nepDate": "02.11.2057"
    },
    {
        "engDate": "2001-02-14",
        "nepDate": "03.11.2057"
    },
    {
        "engDate": "2001-02-15",
        "nepDate": "04.11.2057"
    },
    {
        "engDate": "2001-02-16",
        "nepDate": "05.11.2057"
    },
    {
        "engDate": "2001-02-17",
        "nepDate": "06.11.2057"
    },
    {
        "engDate": "2001-02-18",
        "nepDate": "07.11.2057"
    },
    {
        "engDate": "2001-02-19",
        "nepDate": "08.11.2057"
    },
    {
        "engDate": "2001-02-20",
        "nepDate": "09.11.2057"
    },
    {
        "engDate": "2001-02-21",
        "nepDate": "10.11.2057"
    },
    {
        "engDate": "2001-02-22",
        "nepDate": "11.11.2057"
    },
    {
        "engDate": "2001-02-23",
        "nepDate": "12.11.2057"
    },
    {
        "engDate": "2001-02-24",
        "nepDate": "13.11.2057"
    },
    {
        "engDate": "2001-02-25",
        "nepDate": "14.11.2057"
    },
    {
        "engDate": "2001-02-26",
        "nepDate": "15.11.2057"
    },
    {
        "engDate": "2001-02-27",
        "nepDate": "16.11.2057"
    },
    {
        "engDate": "2001-02-28",
        "nepDate": "17.11.2057"
    },
    {
        "engDate": "2001-03-01",
        "nepDate": "18.11.2057"
    },
    {
        "engDate": "2001-03-02",
        "nepDate": "19.11.2057"
    },
    {
        "engDate": "2001-03-03",
        "nepDate": "20.11.2057"
    },
    {
        "engDate": "2001-03-04",
        "nepDate": "21.11.2057"
    },
    {
        "engDate": "2001-03-05",
        "nepDate": "22.11.2057"
    },
    {
        "engDate": "2001-03-06",
        "nepDate": "23.11.2057"
    },
    {
        "engDate": "2001-03-07",
        "nepDate": "24.11.2057"
    },
    {
        "engDate": "2001-03-08",
        "nepDate": "25.11.2057"
    },
    {
        "engDate": "2001-03-09",
        "nepDate": "26.11.2057"
    },
    {
        "engDate": "2001-03-10",
        "nepDate": "27.11.2057"
    },
    {
        "engDate": "2001-03-11",
        "nepDate": "28.11.2057"
    },
    {
        "engDate": "2001-03-12",
        "nepDate": "29.11.2057"
    },
    {
        "engDate": "2001-03-13",
        "nepDate": "30.11.2057"
    },
    {
        "engDate": "2001-03-14",
        "nepDate": "01.12.2057"
    },
    {
        "engDate": "2001-03-15",
        "nepDate": "02.12.2057"
    },
    {
        "engDate": "2001-03-16",
        "nepDate": "03.12.2057"
    },
    {
        "engDate": "2001-03-17",
        "nepDate": "04.12.2057"
    },
    {
        "engDate": "2001-03-18",
        "nepDate": "05.12.2057"
    },
    {
        "engDate": "2001-03-19",
        "nepDate": "06.12.2057"
    },
    {
        "engDate": "2001-03-20",
        "nepDate": "07.12.2057"
    },
    {
        "engDate": "2001-03-21",
        "nepDate": "08.12.2057"
    },
    {
        "engDate": "2001-03-22",
        "nepDate": "09.12.2057"
    },
    {
        "engDate": "2001-03-23",
        "nepDate": "10.12.2057"
    },
    {
        "engDate": "2001-03-24",
        "nepDate": "11.12.2057"
    },
    {
        "engDate": "2001-03-25",
        "nepDate": "12.12.2057"
    },
    {
        "engDate": "2001-03-26",
        "nepDate": "13.12.2057"
    },
    {
        "engDate": "2001-03-27",
        "nepDate": "14.12.2057"
    },
    {
        "engDate": "2001-03-28",
        "nepDate": "15.12.2057"
    },
    {
        "engDate": "2001-03-29",
        "nepDate": "16.12.2057"
    },
    {
        "engDate": "2001-03-30",
        "nepDate": "17.12.2057"
    },
    {
        "engDate": "2001-03-31",
        "nepDate": "18.12.2057"
    },
    {
        "engDate": "2001-04-01",
        "nepDate": "19.12.2057"
    },
    {
        "engDate": "2001-04-02",
        "nepDate": "20.12.2057"
    },
    {
        "engDate": "2001-04-03",
        "nepDate": "21.12.2057"
    },
    {
        "engDate": "2001-04-04",
        "nepDate": "22.12.2057"
    },
    {
        "engDate": "2001-04-05",
        "nepDate": "23.12.2057"
    },
    {
        "engDate": "2001-04-06",
        "nepDate": "24.12.2057"
    },
    {
        "engDate": "2001-04-07",
        "nepDate": "25.12.2057"
    },
    {
        "engDate": "2001-04-08",
        "nepDate": "26.12.2057"
    },
    {
        "engDate": "2001-04-09",
        "nepDate": "27.12.2057"
    },
    {
        "engDate": "2001-04-10",
        "nepDate": "28.12.2057"
    },
    {
        "engDate": "2001-04-11",
        "nepDate": "29.12.2057"
    },
    {
        "engDate": "2001-04-12",
        "nepDate": "30.12.2057"
    },
    {
        "engDate": "2001-04-13",
        "nepDate": "31.12.2057"
    },
    {
        "engDate": "2001-04-14",
        "nepDate": "01.01.2058"
    },
    {
        "engDate": "2001-04-15",
        "nepDate": "02.01.2058"
    },
    {
        "engDate": "2001-04-16",
        "nepDate": "03.01.2058"
    },
    {
        "engDate": "2001-04-17",
        "nepDate": "04.01.2058"
    },
    {
        "engDate": "2001-04-18",
        "nepDate": "05.01.2058"
    },
    {
        "engDate": "2001-04-19",
        "nepDate": "06.01.2058"
    },
    {
        "engDate": "2001-04-20",
        "nepDate": "07.01.2058"
    },
    {
        "engDate": "2001-04-21",
        "nepDate": "08.01.2058"
    },
    {
        "engDate": "2001-04-22",
        "nepDate": "09.01.2058"
    },
    {
        "engDate": "2001-04-23",
        "nepDate": "10.01.2058"
    },
    {
        "engDate": "2001-04-24",
        "nepDate": "11.01.2058"
    },
    {
        "engDate": "2001-04-25",
        "nepDate": "12.01.2058"
    },
    {
        "engDate": "2001-04-26",
        "nepDate": "13.01.2058"
    },
    {
        "engDate": "2001-04-27",
        "nepDate": "14.01.2058"
    },
    {
        "engDate": "2001-04-28",
        "nepDate": "15.01.2058"
    },
    {
        "engDate": "2001-04-29",
        "nepDate": "16.01.2058"
    },
    {
        "engDate": "2001-04-30",
        "nepDate": "17.01.2058"
    },
    {
        "engDate": "2001-05-01",
        "nepDate": "18.01.2058"
    },
    {
        "engDate": "2001-05-02",
        "nepDate": "19.01.2058"
    },
    {
        "engDate": "2001-05-03",
        "nepDate": "20.01.2058"
    },
    {
        "engDate": "2001-05-04",
        "nepDate": "21.01.2058"
    },
    {
        "engDate": "2001-05-05",
        "nepDate": "22.01.2058"
    },
    {
        "engDate": "2001-05-06",
        "nepDate": "23.01.2058"
    },
    {
        "engDate": "2001-05-07",
        "nepDate": "24.01.2058"
    },
    {
        "engDate": "2001-05-08",
        "nepDate": "25.01.2058"
    },
    {
        "engDate": "2001-05-09",
        "nepDate": "26.01.2058"
    },
    {
        "engDate": "2001-05-10",
        "nepDate": "27.01.2058"
    },
    {
        "engDate": "2001-05-11",
        "nepDate": "28.01.2058"
    },
    {
        "engDate": "2001-05-12",
        "nepDate": "29.01.2058"
    },
    {
        "engDate": "2001-05-13",
        "nepDate": "30.01.2058"
    },
    {
        "engDate": "2001-05-14",
        "nepDate": "01.02.2058"
    },
    {
        "engDate": "2001-05-15",
        "nepDate": "02.02.2058"
    },
    {
        "engDate": "2001-05-16",
        "nepDate": "03.02.2058"
    },
    {
        "engDate": "2001-05-17",
        "nepDate": "04.02.2058"
    },
    {
        "engDate": "2001-05-18",
        "nepDate": "05.02.2058"
    },
    {
        "engDate": "2001-05-19",
        "nepDate": "06.02.2058"
    },
    {
        "engDate": "2001-05-20",
        "nepDate": "07.02.2058"
    },
    {
        "engDate": "2001-05-21",
        "nepDate": "08.02.2058"
    },
    {
        "engDate": "2001-05-22",
        "nepDate": "09.02.2058"
    },
    {
        "engDate": "2001-05-23",
        "nepDate": "10.02.2058"
    },
    {
        "engDate": "2001-05-24",
        "nepDate": "11.02.2058"
    },
    {
        "engDate": "2001-05-25",
        "nepDate": "12.02.2058"
    },
    {
        "engDate": "2001-05-26",
        "nepDate": "13.02.2058"
    },
    {
        "engDate": "2001-05-27",
        "nepDate": "14.02.2058"
    },
    {
        "engDate": "2001-05-28",
        "nepDate": "15.02.2058"
    },
    {
        "engDate": "2001-05-29",
        "nepDate": "16.02.2058"
    },
    {
        "engDate": "2001-05-30",
        "nepDate": "17.02.2058"
    },
    {
        "engDate": "2001-05-31",
        "nepDate": "18.02.2058"
    },
    {
        "engDate": "2001-06-01",
        "nepDate": "19.02.2058"
    },
    {
        "engDate": "2001-06-02",
        "nepDate": "20.02.2058"
    },
    {
        "engDate": "2001-06-03",
        "nepDate": "21.02.2058"
    },
    {
        "engDate": "2001-06-04",
        "nepDate": "22.02.2058"
    },
    {
        "engDate": "2001-06-05",
        "nepDate": "23.02.2058"
    },
    {
        "engDate": "2001-06-06",
        "nepDate": "24.02.2058"
    },
    {
        "engDate": "2001-06-07",
        "nepDate": "25.02.2058"
    },
    {
        "engDate": "2001-06-08",
        "nepDate": "26.02.2058"
    },
    {
        "engDate": "2001-06-09",
        "nepDate": "27.02.2058"
    },
    {
        "engDate": "2001-06-10",
        "nepDate": "28.02.2058"
    },
    {
        "engDate": "2001-06-11",
        "nepDate": "29.02.2058"
    },
    {
        "engDate": "2001-06-12",
        "nepDate": "30.02.2058"
    },
    {
        "engDate": "2001-06-13",
        "nepDate": "31.02.2058"
    },
    {
        "engDate": "2001-06-14",
        "nepDate": "32.02.2058"
    },
    {
        "engDate": "2001-06-15",
        "nepDate": "01.03.2058"
    },
    {
        "engDate": "2001-06-16",
        "nepDate": "02.03.2058"
    },
    {
        "engDate": "2001-06-17",
        "nepDate": "03.03.2058"
    },
    {
        "engDate": "2001-06-18",
        "nepDate": "04.03.2058"
    },
    {
        "engDate": "2001-06-19",
        "nepDate": "05.03.2058"
    },
    {
        "engDate": "2001-06-20",
        "nepDate": "06.03.2058"
    },
    {
        "engDate": "2001-06-21",
        "nepDate": "07.03.2058"
    },
    {
        "engDate": "2001-06-22",
        "nepDate": "08.03.2058"
    },
    {
        "engDate": "2001-06-23",
        "nepDate": "09.03.2058"
    },
    {
        "engDate": "2001-06-24",
        "nepDate": "10.03.2058"
    },
    {
        "engDate": "2001-06-25",
        "nepDate": "11.03.2058"
    },
    {
        "engDate": "2001-06-26",
        "nepDate": "12.03.2058"
    },
    {
        "engDate": "2001-06-27",
        "nepDate": "13.03.2058"
    },
    {
        "engDate": "2001-06-28",
        "nepDate": "14.03.2058"
    },
    {
        "engDate": "2001-06-29",
        "nepDate": "15.03.2058"
    },
    {
        "engDate": "2001-06-30",
        "nepDate": "16.03.2058"
    },
    {
        "engDate": "2001-07-01",
        "nepDate": "17.03.2058"
    },
    {
        "engDate": "2001-07-02",
        "nepDate": "18.03.2058"
    },
    {
        "engDate": "2001-07-03",
        "nepDate": "19.03.2058"
    },
    {
        "engDate": "2001-07-04",
        "nepDate": "20.03.2058"
    },
    {
        "engDate": "2001-07-05",
        "nepDate": "21.03.2058"
    },
    {
        "engDate": "2001-07-06",
        "nepDate": "22.03.2058"
    },
    {
        "engDate": "2001-07-07",
        "nepDate": "23.03.2058"
    },
    {
        "engDate": "2001-07-08",
        "nepDate": "24.03.2058"
    },
    {
        "engDate": "2001-07-09",
        "nepDate": "25.03.2058"
    },
    {
        "engDate": "2001-07-10",
        "nepDate": "26.03.2058"
    },
    {
        "engDate": "2001-07-11",
        "nepDate": "27.03.2058"
    },
    {
        "engDate": "2001-07-12",
        "nepDate": "28.03.2058"
    },
    {
        "engDate": "2001-07-13",
        "nepDate": "29.03.2058"
    },
    {
        "engDate": "2001-07-14",
        "nepDate": "30.03.2058"
    },
    {
        "engDate": "2001-07-15",
        "nepDate": "31.03.2058"
    },
    {
        "engDate": "2001-07-16",
        "nepDate": "01.04.2058"
    },
    {
        "engDate": "2001-07-17",
        "nepDate": "02.04.2058"
    },
    {
        "engDate": "2001-07-18",
        "nepDate": "03.04.2058"
    },
    {
        "engDate": "2001-07-19",
        "nepDate": "04.04.2058"
    },
    {
        "engDate": "2001-07-20",
        "nepDate": "05.04.2058"
    },
    {
        "engDate": "2001-07-21",
        "nepDate": "06.04.2058"
    },
    {
        "engDate": "2001-07-22",
        "nepDate": "07.04.2058"
    },
    {
        "engDate": "2001-07-23",
        "nepDate": "08.04.2058"
    },
    {
        "engDate": "2001-07-24",
        "nepDate": "09.04.2058"
    },
    {
        "engDate": "2001-07-25",
        "nepDate": "10.04.2058"
    },
    {
        "engDate": "2001-07-26",
        "nepDate": "11.04.2058"
    },
    {
        "engDate": "2001-07-27",
        "nepDate": "12.04.2058"
    },
    {
        "engDate": "2001-07-28",
        "nepDate": "13.04.2058"
    },
    {
        "engDate": "2001-07-29",
        "nepDate": "14.04.2058"
    },
    {
        "engDate": "2001-07-30",
        "nepDate": "15.04.2058"
    },
    {
        "engDate": "2001-07-31",
        "nepDate": "16.04.2058"
    },
    {
        "engDate": "2001-08-01",
        "nepDate": "17.04.2058"
    },
    {
        "engDate": "2001-08-02",
        "nepDate": "18.04.2058"
    },
    {
        "engDate": "2001-08-03",
        "nepDate": "19.04.2058"
    },
    {
        "engDate": "2001-08-04",
        "nepDate": "20.04.2058"
    },
    {
        "engDate": "2001-08-05",
        "nepDate": "21.04.2058"
    },
    {
        "engDate": "2001-08-06",
        "nepDate": "22.04.2058"
    },
    {
        "engDate": "2001-08-07",
        "nepDate": "23.04.2058"
    },
    {
        "engDate": "2001-08-08",
        "nepDate": "24.04.2058"
    },
    {
        "engDate": "2001-08-09",
        "nepDate": "25.04.2058"
    },
    {
        "engDate": "2001-08-10",
        "nepDate": "26.04.2058"
    },
    {
        "engDate": "2001-08-11",
        "nepDate": "27.04.2058"
    },
    {
        "engDate": "2001-08-12",
        "nepDate": "28.04.2058"
    },
    {
        "engDate": "2001-08-13",
        "nepDate": "29.04.2058"
    },
    {
        "engDate": "2001-08-14",
        "nepDate": "30.04.2058"
    },
    {
        "engDate": "2001-08-15",
        "nepDate": "31.04.2058"
    },
    {
        "engDate": "2001-08-16",
        "nepDate": "32.04.2058"
    },
    {
        "engDate": "2001-08-17",
        "nepDate": "01.05.2058"
    },
    {
        "engDate": "2001-08-18",
        "nepDate": "02.05.2058"
    },
    {
        "engDate": "2001-08-19",
        "nepDate": "03.05.2058"
    },
    {
        "engDate": "2001-08-20",
        "nepDate": "04.05.2058"
    },
    {
        "engDate": "2001-08-21",
        "nepDate": "05.05.2058"
    },
    {
        "engDate": "2001-08-22",
        "nepDate": "06.05.2058"
    },
    {
        "engDate": "2001-08-23",
        "nepDate": "07.05.2058"
    },
    {
        "engDate": "2001-08-24",
        "nepDate": "08.05.2058"
    },
    {
        "engDate": "2001-08-25",
        "nepDate": "09.05.2058"
    },
    {
        "engDate": "2001-08-26",
        "nepDate": "10.05.2058"
    },
    {
        "engDate": "2001-08-27",
        "nepDate": "11.05.2058"
    },
    {
        "engDate": "2001-08-28",
        "nepDate": "12.05.2058"
    },
    {
        "engDate": "2001-08-29",
        "nepDate": "13.05.2058"
    },
    {
        "engDate": "2001-08-30",
        "nepDate": "14.05.2058"
    },
    {
        "engDate": "2001-08-31",
        "nepDate": "15.05.2058"
    },
    {
        "engDate": "2001-09-01",
        "nepDate": "16.05.2058"
    },
    {
        "engDate": "2001-09-02",
        "nepDate": "17.05.2058"
    },
    {
        "engDate": "2001-09-03",
        "nepDate": "18.05.2058"
    },
    {
        "engDate": "2001-09-04",
        "nepDate": "19.05.2058"
    },
    {
        "engDate": "2001-09-05",
        "nepDate": "20.05.2058"
    },
    {
        "engDate": "2001-09-06",
        "nepDate": "21.05.2058"
    },
    {
        "engDate": "2001-09-07",
        "nepDate": "22.05.2058"
    },
    {
        "engDate": "2001-09-08",
        "nepDate": "23.05.2058"
    },
    {
        "engDate": "2001-09-09",
        "nepDate": "24.05.2058"
    },
    {
        "engDate": "2001-09-10",
        "nepDate": "25.05.2058"
    },
    {
        "engDate": "2001-09-11",
        "nepDate": "26.05.2058"
    },
    {
        "engDate": "2001-09-12",
        "nepDate": "27.05.2058"
    },
    {
        "engDate": "2001-09-13",
        "nepDate": "28.05.2058"
    },
    {
        "engDate": "2001-09-14",
        "nepDate": "29.05.2058"
    },
    {
        "engDate": "2001-09-15",
        "nepDate": "30.05.2058"
    },
    {
        "engDate": "2001-09-16",
        "nepDate": "31.05.2058"
    },
    {
        "engDate": "2001-09-17",
        "nepDate": "01.06.2058"
    },
    {
        "engDate": "2001-09-18",
        "nepDate": "02.06.2058"
    },
    {
        "engDate": "2001-09-19",
        "nepDate": "03.06.2058"
    },
    {
        "engDate": "2001-09-20",
        "nepDate": "04.06.2058"
    },
    {
        "engDate": "2001-09-21",
        "nepDate": "05.06.2058"
    },
    {
        "engDate": "2001-09-22",
        "nepDate": "06.06.2058"
    },
    {
        "engDate": "2001-09-23",
        "nepDate": "07.06.2058"
    },
    {
        "engDate": "2001-09-24",
        "nepDate": "08.06.2058"
    },
    {
        "engDate": "2001-09-25",
        "nepDate": "09.06.2058"
    },
    {
        "engDate": "2001-09-26",
        "nepDate": "10.06.2058"
    },
    {
        "engDate": "2001-09-27",
        "nepDate": "11.06.2058"
    },
    {
        "engDate": "2001-09-28",
        "nepDate": "12.06.2058"
    },
    {
        "engDate": "2001-09-29",
        "nepDate": "13.06.2058"
    },
    {
        "engDate": "2001-09-30",
        "nepDate": "14.06.2058"
    },
    {
        "engDate": "2001-10-01",
        "nepDate": "15.06.2058"
    },
    {
        "engDate": "2001-10-02",
        "nepDate": "16.06.2058"
    },
    {
        "engDate": "2001-10-03",
        "nepDate": "17.06.2058"
    },
    {
        "engDate": "2001-10-04",
        "nepDate": "18.06.2058"
    },
    {
        "engDate": "2001-10-05",
        "nepDate": "19.06.2058"
    },
    {
        "engDate": "2001-10-06",
        "nepDate": "20.06.2058"
    },
    {
        "engDate": "2001-10-07",
        "nepDate": "21.06.2058"
    },
    {
        "engDate": "2001-10-08",
        "nepDate": "22.06.2058"
    },
    {
        "engDate": "2001-10-09",
        "nepDate": "23.06.2058"
    },
    {
        "engDate": "2001-10-10",
        "nepDate": "24.06.2058"
    },
    {
        "engDate": "2001-10-11",
        "nepDate": "25.06.2058"
    },
    {
        "engDate": "2001-10-12",
        "nepDate": "26.06.2058"
    },
    {
        "engDate": "2001-10-13",
        "nepDate": "27.06.2058"
    },
    {
        "engDate": "2001-10-14",
        "nepDate": "28.06.2058"
    },
    {
        "engDate": "2001-10-15",
        "nepDate": "29.06.2058"
    },
    {
        "engDate": "2001-10-16",
        "nepDate": "30.06.2058"
    },
    {
        "engDate": "2001-10-17",
        "nepDate": "01.07.2058"
    },
    {
        "engDate": "2001-10-18",
        "nepDate": "02.07.2058"
    },
    {
        "engDate": "2001-10-19",
        "nepDate": "03.07.2058"
    },
    {
        "engDate": "2001-10-20",
        "nepDate": "04.07.2058"
    },
    {
        "engDate": "2001-10-21",
        "nepDate": "05.07.2058"
    },
    {
        "engDate": "2001-10-22",
        "nepDate": "06.07.2058"
    },
    {
        "engDate": "2001-10-23",
        "nepDate": "07.07.2058"
    },
    {
        "engDate": "2001-10-24",
        "nepDate": "08.07.2058"
    },
    {
        "engDate": "2001-10-25",
        "nepDate": "09.07.2058"
    },
    {
        "engDate": "2001-10-26",
        "nepDate": "10.07.2058"
    },
    {
        "engDate": "2001-10-27",
        "nepDate": "11.07.2058"
    },
    {
        "engDate": "2001-10-28",
        "nepDate": "12.07.2058"
    },
    {
        "engDate": "2001-10-29",
        "nepDate": "13.07.2058"
    },
    {
        "engDate": "2001-10-30",
        "nepDate": "14.07.2058"
    },
    {
        "engDate": "2001-10-31",
        "nepDate": "15.07.2058"
    },
    {
        "engDate": "2001-11-01",
        "nepDate": "16.07.2058"
    },
    {
        "engDate": "2001-11-02",
        "nepDate": "17.07.2058"
    },
    {
        "engDate": "2001-11-03",
        "nepDate": "18.07.2058"
    },
    {
        "engDate": "2001-11-04",
        "nepDate": "19.07.2058"
    },
    {
        "engDate": "2001-11-05",
        "nepDate": "20.07.2058"
    },
    {
        "engDate": "2001-11-06",
        "nepDate": "21.07.2058"
    },
    {
        "engDate": "2001-11-07",
        "nepDate": "22.07.2058"
    },
    {
        "engDate": "2001-11-08",
        "nepDate": "23.07.2058"
    },
    {
        "engDate": "2001-11-09",
        "nepDate": "24.07.2058"
    },
    {
        "engDate": "2001-11-10",
        "nepDate": "25.07.2058"
    },
    {
        "engDate": "2001-11-11",
        "nepDate": "26.07.2058"
    },
    {
        "engDate": "2001-11-12",
        "nepDate": "27.07.2058"
    },
    {
        "engDate": "2001-11-13",
        "nepDate": "28.07.2058"
    },
    {
        "engDate": "2001-11-14",
        "nepDate": "29.07.2058"
    },
    {
        "engDate": "2001-11-15",
        "nepDate": "30.07.2058"
    },
    {
        "engDate": "2001-11-16",
        "nepDate": "01.08.2058"
    },
    {
        "engDate": "2001-11-17",
        "nepDate": "02.08.2058"
    },
    {
        "engDate": "2001-11-18",
        "nepDate": "03.08.2058"
    },
    {
        "engDate": "2001-11-19",
        "nepDate": "04.08.2058"
    },
    {
        "engDate": "2001-11-20",
        "nepDate": "05.08.2058"
    },
    {
        "engDate": "2001-11-21",
        "nepDate": "06.08.2058"
    },
    {
        "engDate": "2001-11-22",
        "nepDate": "07.08.2058"
    },
    {
        "engDate": "2001-11-23",
        "nepDate": "08.08.2058"
    },
    {
        "engDate": "2001-11-24",
        "nepDate": "09.08.2058"
    },
    {
        "engDate": "2001-11-25",
        "nepDate": "10.08.2058"
    },
    {
        "engDate": "2001-11-26",
        "nepDate": "11.08.2058"
    },
    {
        "engDate": "2001-11-27",
        "nepDate": "12.08.2058"
    },
    {
        "engDate": "2001-11-28",
        "nepDate": "13.08.2058"
    },
    {
        "engDate": "2001-11-29",
        "nepDate": "14.08.2058"
    },
    {
        "engDate": "2001-11-30",
        "nepDate": "15.08.2058"
    },
    {
        "engDate": "2001-12-01",
        "nepDate": "16.08.2058"
    },
    {
        "engDate": "2001-12-02",
        "nepDate": "17.08.2058"
    },
    {
        "engDate": "2001-12-03",
        "nepDate": "18.08.2058"
    },
    {
        "engDate": "2001-12-04",
        "nepDate": "19.08.2058"
    },
    {
        "engDate": "2001-12-05",
        "nepDate": "20.08.2058"
    },
    {
        "engDate": "2001-12-06",
        "nepDate": "21.08.2058"
    },
    {
        "engDate": "2001-12-07",
        "nepDate": "22.08.2058"
    },
    {
        "engDate": "2001-12-08",
        "nepDate": "23.08.2058"
    },
    {
        "engDate": "2001-12-09",
        "nepDate": "24.08.2058"
    },
    {
        "engDate": "2001-12-10",
        "nepDate": "25.08.2058"
    },
    {
        "engDate": "2001-12-11",
        "nepDate": "26.08.2058"
    },
    {
        "engDate": "2001-12-12",
        "nepDate": "27.08.2058"
    },
    {
        "engDate": "2001-12-13",
        "nepDate": "28.08.2058"
    },
    {
        "engDate": "2001-12-14",
        "nepDate": "29.08.2058"
    },
    {
        "engDate": "2001-12-15",
        "nepDate": "30.08.2058"
    },
    {
        "engDate": "2001-12-16",
        "nepDate": "01.09.2058"
    },
    {
        "engDate": "2001-12-17",
        "nepDate": "02.09.2058"
    },
    {
        "engDate": "2001-12-18",
        "nepDate": "03.09.2058"
    },
    {
        "engDate": "2001-12-19",
        "nepDate": "04.09.2058"
    },
    {
        "engDate": "2001-12-20",
        "nepDate": "05.09.2058"
    },
    {
        "engDate": "2001-12-21",
        "nepDate": "06.09.2058"
    },
    {
        "engDate": "2001-12-22",
        "nepDate": "07.09.2058"
    },
    {
        "engDate": "2001-12-23",
        "nepDate": "08.09.2058"
    },
    {
        "engDate": "2001-12-24",
        "nepDate": "09.09.2058"
    },
    {
        "engDate": "2001-12-25",
        "nepDate": "10.09.2058"
    },
    {
        "engDate": "2001-12-26",
        "nepDate": "11.09.2058"
    },
    {
        "engDate": "2001-12-27",
        "nepDate": "12.09.2058"
    },
    {
        "engDate": "2001-12-28",
        "nepDate": "13.09.2058"
    },
    {
        "engDate": "2001-12-29",
        "nepDate": "14.09.2058"
    },
    {
        "engDate": "2001-12-30",
        "nepDate": "15.09.2058"
    },
    {
        "engDate": "2001-12-31",
        "nepDate": "16.09.2058"
    },
    {
        "engDate": "2002-01-01",
        "nepDate": "17.09.2058"
    },
    {
        "engDate": "2002-01-02",
        "nepDate": "18.09.2058"
    },
    {
        "engDate": "2002-01-03",
        "nepDate": "19.09.2058"
    },
    {
        "engDate": "2002-01-04",
        "nepDate": "20.09.2058"
    },
    {
        "engDate": "2002-01-05",
        "nepDate": "21.09.2058"
    },
    {
        "engDate": "2002-01-06",
        "nepDate": "22.09.2058"
    },
    {
        "engDate": "2002-01-07",
        "nepDate": "23.09.2058"
    },
    {
        "engDate": "2002-01-08",
        "nepDate": "24.09.2058"
    },
    {
        "engDate": "2002-01-09",
        "nepDate": "25.09.2058"
    },
    {
        "engDate": "2002-01-10",
        "nepDate": "26.09.2058"
    },
    {
        "engDate": "2002-01-11",
        "nepDate": "27.09.2058"
    },
    {
        "engDate": "2002-01-12",
        "nepDate": "28.09.2058"
    },
    {
        "engDate": "2002-01-13",
        "nepDate": "29.09.2058"
    },
    {
        "engDate": "2002-01-14",
        "nepDate": "01.10.2058"
    },
    {
        "engDate": "2002-01-15",
        "nepDate": "02.10.2058"
    },
    {
        "engDate": "2002-01-16",
        "nepDate": "03.10.2058"
    },
    {
        "engDate": "2002-01-17",
        "nepDate": "04.10.2058"
    },
    {
        "engDate": "2002-01-18",
        "nepDate": "05.10.2058"
    },
    {
        "engDate": "2002-01-19",
        "nepDate": "06.10.2058"
    },
    {
        "engDate": "2002-01-20",
        "nepDate": "07.10.2058"
    },
    {
        "engDate": "2002-01-21",
        "nepDate": "08.10.2058"
    },
    {
        "engDate": "2002-01-22",
        "nepDate": "09.10.2058"
    },
    {
        "engDate": "2002-01-23",
        "nepDate": "10.10.2058"
    },
    {
        "engDate": "2002-01-24",
        "nepDate": "11.10.2058"
    },
    {
        "engDate": "2002-01-25",
        "nepDate": "12.10.2058"
    },
    {
        "engDate": "2002-01-26",
        "nepDate": "13.10.2058"
    },
    {
        "engDate": "2002-01-27",
        "nepDate": "14.10.2058"
    },
    {
        "engDate": "2002-01-28",
        "nepDate": "15.10.2058"
    },
    {
        "engDate": "2002-01-29",
        "nepDate": "16.10.2058"
    },
    {
        "engDate": "2002-01-30",
        "nepDate": "17.10.2058"
    },
    {
        "engDate": "2002-01-31",
        "nepDate": "18.10.2058"
    },
    {
        "engDate": "2002-02-01",
        "nepDate": "19.10.2058"
    },
    {
        "engDate": "2002-02-02",
        "nepDate": "20.10.2058"
    },
    {
        "engDate": "2002-02-03",
        "nepDate": "21.10.2058"
    },
    {
        "engDate": "2002-02-04",
        "nepDate": "22.10.2058"
    },
    {
        "engDate": "2002-02-05",
        "nepDate": "23.10.2058"
    },
    {
        "engDate": "2002-02-06",
        "nepDate": "24.10.2058"
    },
    {
        "engDate": "2002-02-07",
        "nepDate": "25.10.2058"
    },
    {
        "engDate": "2002-02-08",
        "nepDate": "26.10.2058"
    },
    {
        "engDate": "2002-02-09",
        "nepDate": "27.10.2058"
    },
    {
        "engDate": "2002-02-10",
        "nepDate": "28.10.2058"
    },
    {
        "engDate": "2002-02-11",
        "nepDate": "29.10.2058"
    },
    {
        "engDate": "2002-02-12",
        "nepDate": "30.10.2058"
    },
    {
        "engDate": "2002-02-13",
        "nepDate": "01.11.2058"
    },
    {
        "engDate": "2002-02-14",
        "nepDate": "02.11.2058"
    },
    {
        "engDate": "2002-02-15",
        "nepDate": "03.11.2058"
    },
    {
        "engDate": "2002-02-16",
        "nepDate": "04.11.2058"
    },
    {
        "engDate": "2002-02-17",
        "nepDate": "05.11.2058"
    },
    {
        "engDate": "2002-02-18",
        "nepDate": "06.11.2058"
    },
    {
        "engDate": "2002-02-19",
        "nepDate": "07.11.2058"
    },
    {
        "engDate": "2002-02-20",
        "nepDate": "08.11.2058"
    },
    {
        "engDate": "2002-02-21",
        "nepDate": "09.11.2058"
    },
    {
        "engDate": "2002-02-22",
        "nepDate": "10.11.2058"
    },
    {
        "engDate": "2002-02-23",
        "nepDate": "11.11.2058"
    },
    {
        "engDate": "2002-02-24",
        "nepDate": "12.11.2058"
    },
    {
        "engDate": "2002-02-25",
        "nepDate": "13.11.2058"
    },
    {
        "engDate": "2002-02-26",
        "nepDate": "14.11.2058"
    },
    {
        "engDate": "2002-02-27",
        "nepDate": "15.11.2058"
    },
    {
        "engDate": "2002-02-28",
        "nepDate": "16.11.2058"
    },
    {
        "engDate": "2002-03-01",
        "nepDate": "17.11.2058"
    },
    {
        "engDate": "2002-03-02",
        "nepDate": "18.11.2058"
    },
    {
        "engDate": "2002-03-03",
        "nepDate": "19.11.2058"
    },
    {
        "engDate": "2002-03-04",
        "nepDate": "20.11.2058"
    },
    {
        "engDate": "2002-03-05",
        "nepDate": "21.11.2058"
    },
    {
        "engDate": "2002-03-06",
        "nepDate": "22.11.2058"
    },
    {
        "engDate": "2002-03-07",
        "nepDate": "23.11.2058"
    },
    {
        "engDate": "2002-03-08",
        "nepDate": "24.11.2058"
    },
    {
        "engDate": "2002-03-09",
        "nepDate": "25.11.2058"
    },
    {
        "engDate": "2002-03-10",
        "nepDate": "26.11.2058"
    },
    {
        "engDate": "2002-03-11",
        "nepDate": "27.11.2058"
    },
    {
        "engDate": "2002-03-12",
        "nepDate": "28.11.2058"
    },
    {
        "engDate": "2002-03-13",
        "nepDate": "29.11.2058"
    },
    {
        "engDate": "2002-03-14",
        "nepDate": "01.12.2058"
    },
    {
        "engDate": "2002-03-15",
        "nepDate": "02.12.2058"
    },
    {
        "engDate": "2002-03-16",
        "nepDate": "03.12.2058"
    },
    {
        "engDate": "2002-03-17",
        "nepDate": "04.12.2058"
    },
    {
        "engDate": "2002-03-18",
        "nepDate": "05.12.2058"
    },
    {
        "engDate": "2002-03-19",
        "nepDate": "06.12.2058"
    },
    {
        "engDate": "2002-03-20",
        "nepDate": "07.12.2058"
    },
    {
        "engDate": "2002-03-21",
        "nepDate": "08.12.2058"
    },
    {
        "engDate": "2002-03-22",
        "nepDate": "09.12.2058"
    },
    {
        "engDate": "2002-03-23",
        "nepDate": "10.12.2058"
    },
    {
        "engDate": "2002-03-24",
        "nepDate": "11.12.2058"
    },
    {
        "engDate": "2002-03-25",
        "nepDate": "12.12.2058"
    },
    {
        "engDate": "2002-03-26",
        "nepDate": "13.12.2058"
    },
    {
        "engDate": "2002-03-27",
        "nepDate": "14.12.2058"
    },
    {
        "engDate": "2002-03-28",
        "nepDate": "15.12.2058"
    },
    {
        "engDate": "2002-03-29",
        "nepDate": "16.12.2058"
    },
    {
        "engDate": "2002-03-30",
        "nepDate": "17.12.2058"
    },
    {
        "engDate": "2002-03-31",
        "nepDate": "18.12.2058"
    },
    {
        "engDate": "2002-04-01",
        "nepDate": "19.12.2058"
    },
    {
        "engDate": "2002-04-02",
        "nepDate": "20.12.2058"
    },
    {
        "engDate": "2002-04-03",
        "nepDate": "21.12.2058"
    },
    {
        "engDate": "2002-04-04",
        "nepDate": "22.12.2058"
    },
    {
        "engDate": "2002-04-05",
        "nepDate": "23.12.2058"
    },
    {
        "engDate": "2002-04-06",
        "nepDate": "24.12.2058"
    },
    {
        "engDate": "2002-04-07",
        "nepDate": "25.12.2058"
    },
    {
        "engDate": "2002-04-08",
        "nepDate": "26.12.2058"
    },
    {
        "engDate": "2002-04-09",
        "nepDate": "27.12.2058"
    },
    {
        "engDate": "2002-04-10",
        "nepDate": "28.12.2058"
    },
    {
        "engDate": "2002-04-11",
        "nepDate": "29.12.2058"
    },
    {
        "engDate": "2002-04-12",
        "nepDate": "30.12.2058"
    },
    {
        "engDate": "2002-04-13",
        "nepDate": "31.12.2058"
    },
    {
        "engDate": "2002-04-14",
        "nepDate": "01.01.2059"
    },
    {
        "engDate": "2002-04-15",
        "nepDate": "02.01.2059"
    },
    {
        "engDate": "2002-04-16",
        "nepDate": "03.01.2059"
    },
    {
        "engDate": "2002-04-17",
        "nepDate": "04.01.2059"
    },
    {
        "engDate": "2002-04-18",
        "nepDate": "05.01.2059"
    },
    {
        "engDate": "2002-04-19",
        "nepDate": "06.01.2059"
    },
    {
        "engDate": "2002-04-20",
        "nepDate": "07.01.2059"
    },
    {
        "engDate": "2002-04-21",
        "nepDate": "08.01.2059"
    },
    {
        "engDate": "2002-04-22",
        "nepDate": "09.01.2059"
    },
    {
        "engDate": "2002-04-23",
        "nepDate": "10.01.2059"
    },
    {
        "engDate": "2002-04-24",
        "nepDate": "11.01.2059"
    },
    {
        "engDate": "2002-04-25",
        "nepDate": "12.01.2059"
    },
    {
        "engDate": "2002-04-26",
        "nepDate": "13.01.2059"
    },
    {
        "engDate": "2002-04-27",
        "nepDate": "14.01.2059"
    },
    {
        "engDate": "2002-04-28",
        "nepDate": "15.01.2059"
    },
    {
        "engDate": "2002-04-29",
        "nepDate": "16.01.2059"
    },
    {
        "engDate": "2002-04-30",
        "nepDate": "17.01.2059"
    },
    {
        "engDate": "2002-05-01",
        "nepDate": "18.01.2059"
    },
    {
        "engDate": "2002-05-02",
        "nepDate": "19.01.2059"
    },
    {
        "engDate": "2002-05-03",
        "nepDate": "20.01.2059"
    },
    {
        "engDate": "2002-05-04",
        "nepDate": "21.01.2059"
    },
    {
        "engDate": "2002-05-05",
        "nepDate": "22.01.2059"
    },
    {
        "engDate": "2002-05-06",
        "nepDate": "23.01.2059"
    },
    {
        "engDate": "2002-05-07",
        "nepDate": "24.01.2059"
    },
    {
        "engDate": "2002-05-08",
        "nepDate": "25.01.2059"
    },
    {
        "engDate": "2002-05-09",
        "nepDate": "26.01.2059"
    },
    {
        "engDate": "2002-05-10",
        "nepDate": "27.01.2059"
    },
    {
        "engDate": "2002-05-11",
        "nepDate": "28.01.2059"
    },
    {
        "engDate": "2002-05-12",
        "nepDate": "29.01.2059"
    },
    {
        "engDate": "2002-05-13",
        "nepDate": "30.01.2059"
    },
    {
        "engDate": "2002-05-14",
        "nepDate": "31.01.2059"
    },
    {
        "engDate": "2002-05-15",
        "nepDate": "01.02.2059"
    },
    {
        "engDate": "2002-05-16",
        "nepDate": "02.02.2059"
    },
    {
        "engDate": "2002-05-17",
        "nepDate": "03.02.2059"
    },
    {
        "engDate": "2002-05-18",
        "nepDate": "04.02.2059"
    },
    {
        "engDate": "2002-05-19",
        "nepDate": "05.02.2059"
    },
    {
        "engDate": "2002-05-20",
        "nepDate": "06.02.2059"
    },
    {
        "engDate": "2002-05-21",
        "nepDate": "07.02.2059"
    },
    {
        "engDate": "2002-05-22",
        "nepDate": "08.02.2059"
    },
    {
        "engDate": "2002-05-23",
        "nepDate": "09.02.2059"
    },
    {
        "engDate": "2002-05-24",
        "nepDate": "10.02.2059"
    },
    {
        "engDate": "2002-05-25",
        "nepDate": "11.02.2059"
    },
    {
        "engDate": "2002-05-26",
        "nepDate": "12.02.2059"
    },
    {
        "engDate": "2002-05-27",
        "nepDate": "13.02.2059"
    },
    {
        "engDate": "2002-05-28",
        "nepDate": "14.02.2059"
    },
    {
        "engDate": "2002-05-29",
        "nepDate": "15.02.2059"
    },
    {
        "engDate": "2002-05-30",
        "nepDate": "16.02.2059"
    },
    {
        "engDate": "2002-05-31",
        "nepDate": "17.02.2059"
    },
    {
        "engDate": "2002-06-01",
        "nepDate": "18.02.2059"
    },
    {
        "engDate": "2002-06-02",
        "nepDate": "19.02.2059"
    },
    {
        "engDate": "2002-06-03",
        "nepDate": "20.02.2059"
    },
    {
        "engDate": "2002-06-04",
        "nepDate": "21.02.2059"
    },
    {
        "engDate": "2002-06-05",
        "nepDate": "22.02.2059"
    },
    {
        "engDate": "2002-06-06",
        "nepDate": "23.02.2059"
    },
    {
        "engDate": "2002-06-07",
        "nepDate": "24.02.2059"
    },
    {
        "engDate": "2002-06-08",
        "nepDate": "25.02.2059"
    },
    {
        "engDate": "2002-06-09",
        "nepDate": "26.02.2059"
    },
    {
        "engDate": "2002-06-10",
        "nepDate": "27.02.2059"
    },
    {
        "engDate": "2002-06-11",
        "nepDate": "28.02.2059"
    },
    {
        "engDate": "2002-06-12",
        "nepDate": "29.02.2059"
    },
    {
        "engDate": "2002-06-13",
        "nepDate": "30.02.2059"
    },
    {
        "engDate": "2002-06-14",
        "nepDate": "31.02.2059"
    },
    {
        "engDate": "2002-06-15",
        "nepDate": "01.03.2059"
    },
    {
        "engDate": "2002-06-16",
        "nepDate": "02.03.2059"
    },
    {
        "engDate": "2002-06-17",
        "nepDate": "03.03.2059"
    },
    {
        "engDate": "2002-06-18",
        "nepDate": "04.03.2059"
    },
    {
        "engDate": "2002-06-19",
        "nepDate": "05.03.2059"
    },
    {
        "engDate": "2002-06-20",
        "nepDate": "06.03.2059"
    },
    {
        "engDate": "2002-06-21",
        "nepDate": "07.03.2059"
    },
    {
        "engDate": "2002-06-22",
        "nepDate": "08.03.2059"
    },
    {
        "engDate": "2002-06-23",
        "nepDate": "09.03.2059"
    },
    {
        "engDate": "2002-06-24",
        "nepDate": "10.03.2059"
    },
    {
        "engDate": "2002-06-25",
        "nepDate": "11.03.2059"
    },
    {
        "engDate": "2002-06-26",
        "nepDate": "12.03.2059"
    },
    {
        "engDate": "2002-06-27",
        "nepDate": "13.03.2059"
    },
    {
        "engDate": "2002-06-28",
        "nepDate": "14.03.2059"
    },
    {
        "engDate": "2002-06-29",
        "nepDate": "15.03.2059"
    },
    {
        "engDate": "2002-06-30",
        "nepDate": "16.03.2059"
    },
    {
        "engDate": "2002-07-01",
        "nepDate": "17.03.2059"
    },
    {
        "engDate": "2002-07-02",
        "nepDate": "18.03.2059"
    },
    {
        "engDate": "2002-07-03",
        "nepDate": "19.03.2059"
    },
    {
        "engDate": "2002-07-04",
        "nepDate": "20.03.2059"
    },
    {
        "engDate": "2002-07-05",
        "nepDate": "21.03.2059"
    },
    {
        "engDate": "2002-07-06",
        "nepDate": "22.03.2059"
    },
    {
        "engDate": "2002-07-07",
        "nepDate": "23.03.2059"
    },
    {
        "engDate": "2002-07-08",
        "nepDate": "24.03.2059"
    },
    {
        "engDate": "2002-07-09",
        "nepDate": "25.03.2059"
    },
    {
        "engDate": "2002-07-10",
        "nepDate": "26.03.2059"
    },
    {
        "engDate": "2002-07-11",
        "nepDate": "27.03.2059"
    },
    {
        "engDate": "2002-07-12",
        "nepDate": "28.03.2059"
    },
    {
        "engDate": "2002-07-13",
        "nepDate": "29.03.2059"
    },
    {
        "engDate": "2002-07-14",
        "nepDate": "30.03.2059"
    },
    {
        "engDate": "2002-07-15",
        "nepDate": "31.03.2059"
    },
    {
        "engDate": "2002-07-16",
        "nepDate": "32.03.2059"
    },
    {
        "engDate": "2002-07-17",
        "nepDate": "01.04.2059"
    },
    {
        "engDate": "2002-07-18",
        "nepDate": "02.04.2059"
    },
    {
        "engDate": "2002-07-19",
        "nepDate": "03.04.2059"
    },
    {
        "engDate": "2002-07-20",
        "nepDate": "04.04.2059"
    },
    {
        "engDate": "2002-07-21",
        "nepDate": "05.04.2059"
    },
    {
        "engDate": "2002-07-22",
        "nepDate": "06.04.2059"
    },
    {
        "engDate": "2002-07-23",
        "nepDate": "07.04.2059"
    },
    {
        "engDate": "2002-07-24",
        "nepDate": "08.04.2059"
    },
    {
        "engDate": "2002-07-25",
        "nepDate": "09.04.2059"
    },
    {
        "engDate": "2002-07-26",
        "nepDate": "10.04.2059"
    },
    {
        "engDate": "2002-07-27",
        "nepDate": "11.04.2059"
    },
    {
        "engDate": "2002-07-28",
        "nepDate": "12.04.2059"
    },
    {
        "engDate": "2002-07-29",
        "nepDate": "13.04.2059"
    },
    {
        "engDate": "2002-07-30",
        "nepDate": "14.04.2059"
    },
    {
        "engDate": "2002-07-31",
        "nepDate": "15.04.2059"
    },
    {
        "engDate": "2002-08-01",
        "nepDate": "16.04.2059"
    },
    {
        "engDate": "2002-08-02",
        "nepDate": "17.04.2059"
    },
    {
        "engDate": "2002-08-03",
        "nepDate": "18.04.2059"
    },
    {
        "engDate": "2002-08-04",
        "nepDate": "19.04.2059"
    },
    {
        "engDate": "2002-08-05",
        "nepDate": "20.04.2059"
    },
    {
        "engDate": "2002-08-06",
        "nepDate": "21.04.2059"
    },
    {
        "engDate": "2002-08-07",
        "nepDate": "22.04.2059"
    },
    {
        "engDate": "2002-08-08",
        "nepDate": "23.04.2059"
    },
    {
        "engDate": "2002-08-09",
        "nepDate": "24.04.2059"
    },
    {
        "engDate": "2002-08-10",
        "nepDate": "25.04.2059"
    },
    {
        "engDate": "2002-08-11",
        "nepDate": "26.04.2059"
    },
    {
        "engDate": "2002-08-12",
        "nepDate": "27.04.2059"
    },
    {
        "engDate": "2002-08-13",
        "nepDate": "28.04.2059"
    },
    {
        "engDate": "2002-08-14",
        "nepDate": "29.04.2059"
    },
    {
        "engDate": "2002-08-15",
        "nepDate": "30.04.2059"
    },
    {
        "engDate": "2002-08-16",
        "nepDate": "31.04.2059"
    },
    {
        "engDate": "2002-08-17",
        "nepDate": "01.05.2059"
    },
    {
        "engDate": "2002-08-18",
        "nepDate": "02.05.2059"
    },
    {
        "engDate": "2002-08-19",
        "nepDate": "03.05.2059"
    },
    {
        "engDate": "2002-08-20",
        "nepDate": "04.05.2059"
    },
    {
        "engDate": "2002-08-21",
        "nepDate": "05.05.2059"
    },
    {
        "engDate": "2002-08-22",
        "nepDate": "06.05.2059"
    },
    {
        "engDate": "2002-08-23",
        "nepDate": "07.05.2059"
    },
    {
        "engDate": "2002-08-24",
        "nepDate": "08.05.2059"
    },
    {
        "engDate": "2002-08-25",
        "nepDate": "09.05.2059"
    },
    {
        "engDate": "2002-08-26",
        "nepDate": "10.05.2059"
    },
    {
        "engDate": "2002-08-27",
        "nepDate": "11.05.2059"
    },
    {
        "engDate": "2002-08-28",
        "nepDate": "12.05.2059"
    },
    {
        "engDate": "2002-08-29",
        "nepDate": "13.05.2059"
    },
    {
        "engDate": "2002-08-30",
        "nepDate": "14.05.2059"
    },
    {
        "engDate": "2002-08-31",
        "nepDate": "15.05.2059"
    },
    {
        "engDate": "2002-09-01",
        "nepDate": "16.05.2059"
    },
    {
        "engDate": "2002-09-02",
        "nepDate": "17.05.2059"
    },
    {
        "engDate": "2002-09-03",
        "nepDate": "18.05.2059"
    },
    {
        "engDate": "2002-09-04",
        "nepDate": "19.05.2059"
    },
    {
        "engDate": "2002-09-05",
        "nepDate": "20.05.2059"
    },
    {
        "engDate": "2002-09-06",
        "nepDate": "21.05.2059"
    },
    {
        "engDate": "2002-09-07",
        "nepDate": "22.05.2059"
    },
    {
        "engDate": "2002-09-08",
        "nepDate": "23.05.2059"
    },
    {
        "engDate": "2002-09-09",
        "nepDate": "24.05.2059"
    },
    {
        "engDate": "2002-09-10",
        "nepDate": "25.05.2059"
    },
    {
        "engDate": "2002-09-11",
        "nepDate": "26.05.2059"
    },
    {
        "engDate": "2002-09-12",
        "nepDate": "27.05.2059"
    },
    {
        "engDate": "2002-09-13",
        "nepDate": "28.05.2059"
    },
    {
        "engDate": "2002-09-14",
        "nepDate": "29.05.2059"
    },
    {
        "engDate": "2002-09-15",
        "nepDate": "30.05.2059"
    },
    {
        "engDate": "2002-09-16",
        "nepDate": "31.05.2059"
    },
    {
        "engDate": "2002-09-17",
        "nepDate": "01.06.2059"
    },
    {
        "engDate": "2002-09-18",
        "nepDate": "02.06.2059"
    },
    {
        "engDate": "2002-09-19",
        "nepDate": "03.06.2059"
    },
    {
        "engDate": "2002-09-20",
        "nepDate": "04.06.2059"
    },
    {
        "engDate": "2002-09-21",
        "nepDate": "05.06.2059"
    },
    {
        "engDate": "2002-09-22",
        "nepDate": "06.06.2059"
    },
    {
        "engDate": "2002-09-23",
        "nepDate": "07.06.2059"
    },
    {
        "engDate": "2002-09-24",
        "nepDate": "08.06.2059"
    },
    {
        "engDate": "2002-09-25",
        "nepDate": "09.06.2059"
    },
    {
        "engDate": "2002-09-26",
        "nepDate": "10.06.2059"
    },
    {
        "engDate": "2002-09-27",
        "nepDate": "11.06.2059"
    },
    {
        "engDate": "2002-09-28",
        "nepDate": "12.06.2059"
    },
    {
        "engDate": "2002-09-29",
        "nepDate": "13.06.2059"
    },
    {
        "engDate": "2002-09-30",
        "nepDate": "14.06.2059"
    },
    {
        "engDate": "2002-10-01",
        "nepDate": "15.06.2059"
    },
    {
        "engDate": "2002-10-02",
        "nepDate": "16.06.2059"
    },
    {
        "engDate": "2002-10-03",
        "nepDate": "17.06.2059"
    },
    {
        "engDate": "2002-10-04",
        "nepDate": "18.06.2059"
    },
    {
        "engDate": "2002-10-05",
        "nepDate": "19.06.2059"
    },
    {
        "engDate": "2002-10-06",
        "nepDate": "20.06.2059"
    },
    {
        "engDate": "2002-10-07",
        "nepDate": "21.06.2059"
    },
    {
        "engDate": "2002-10-08",
        "nepDate": "22.06.2059"
    },
    {
        "engDate": "2002-10-09",
        "nepDate": "23.06.2059"
    },
    {
        "engDate": "2002-10-10",
        "nepDate": "24.06.2059"
    },
    {
        "engDate": "2002-10-11",
        "nepDate": "25.06.2059"
    },
    {
        "engDate": "2002-10-12",
        "nepDate": "26.06.2059"
    },
    {
        "engDate": "2002-10-13",
        "nepDate": "27.06.2059"
    },
    {
        "engDate": "2002-10-14",
        "nepDate": "28.06.2059"
    },
    {
        "engDate": "2002-10-15",
        "nepDate": "29.06.2059"
    },
    {
        "engDate": "2002-10-16",
        "nepDate": "30.06.2059"
    },
    {
        "engDate": "2002-10-17",
        "nepDate": "31.06.2059"
    },
    {
        "engDate": "2002-10-18",
        "nepDate": "01.07.2059"
    },
    {
        "engDate": "2002-10-19",
        "nepDate": "02.07.2059"
    },
    {
        "engDate": "2002-10-20",
        "nepDate": "03.07.2059"
    },
    {
        "engDate": "2002-10-21",
        "nepDate": "04.07.2059"
    },
    {
        "engDate": "2002-10-22",
        "nepDate": "05.07.2059"
    },
    {
        "engDate": "2002-10-23",
        "nepDate": "06.07.2059"
    },
    {
        "engDate": "2002-10-24",
        "nepDate": "07.07.2059"
    },
    {
        "engDate": "2002-10-25",
        "nepDate": "08.07.2059"
    },
    {
        "engDate": "2002-10-26",
        "nepDate": "09.07.2059"
    },
    {
        "engDate": "2002-10-27",
        "nepDate": "10.07.2059"
    },
    {
        "engDate": "2002-10-28",
        "nepDate": "11.07.2059"
    },
    {
        "engDate": "2002-10-29",
        "nepDate": "12.07.2059"
    },
    {
        "engDate": "2002-10-30",
        "nepDate": "13.07.2059"
    },
    {
        "engDate": "2002-10-31",
        "nepDate": "14.07.2059"
    },
    {
        "engDate": "2002-11-01",
        "nepDate": "15.07.2059"
    },
    {
        "engDate": "2002-11-02",
        "nepDate": "16.07.2059"
    },
    {
        "engDate": "2002-11-03",
        "nepDate": "17.07.2059"
    },
    {
        "engDate": "2002-11-04",
        "nepDate": "18.07.2059"
    },
    {
        "engDate": "2002-11-05",
        "nepDate": "19.07.2059"
    },
    {
        "engDate": "2002-11-06",
        "nepDate": "20.07.2059"
    },
    {
        "engDate": "2002-11-07",
        "nepDate": "21.07.2059"
    },
    {
        "engDate": "2002-11-08",
        "nepDate": "22.07.2059"
    },
    {
        "engDate": "2002-11-09",
        "nepDate": "23.07.2059"
    },
    {
        "engDate": "2002-11-10",
        "nepDate": "24.07.2059"
    },
    {
        "engDate": "2002-11-11",
        "nepDate": "25.07.2059"
    },
    {
        "engDate": "2002-11-12",
        "nepDate": "26.07.2059"
    },
    {
        "engDate": "2002-11-13",
        "nepDate": "27.07.2059"
    },
    {
        "engDate": "2002-11-14",
        "nepDate": "28.07.2059"
    },
    {
        "engDate": "2002-11-15",
        "nepDate": "29.07.2059"
    },
    {
        "engDate": "2002-11-16",
        "nepDate": "30.07.2059"
    },
    {
        "engDate": "2002-11-17",
        "nepDate": "01.08.2059"
    },
    {
        "engDate": "2002-11-18",
        "nepDate": "02.08.2059"
    },
    {
        "engDate": "2002-11-19",
        "nepDate": "03.08.2059"
    },
    {
        "engDate": "2002-11-20",
        "nepDate": "04.08.2059"
    },
    {
        "engDate": "2002-11-21",
        "nepDate": "05.08.2059"
    },
    {
        "engDate": "2002-11-22",
        "nepDate": "06.08.2059"
    },
    {
        "engDate": "2002-11-23",
        "nepDate": "07.08.2059"
    },
    {
        "engDate": "2002-11-24",
        "nepDate": "08.08.2059"
    },
    {
        "engDate": "2002-11-25",
        "nepDate": "09.08.2059"
    },
    {
        "engDate": "2002-11-26",
        "nepDate": "10.08.2059"
    },
    {
        "engDate": "2002-11-27",
        "nepDate": "11.08.2059"
    },
    {
        "engDate": "2002-11-28",
        "nepDate": "12.08.2059"
    },
    {
        "engDate": "2002-11-29",
        "nepDate": "13.08.2059"
    },
    {
        "engDate": "2002-11-30",
        "nepDate": "14.08.2059"
    },
    {
        "engDate": "2002-12-01",
        "nepDate": "15.08.2059"
    },
    {
        "engDate": "2002-12-02",
        "nepDate": "16.08.2059"
    },
    {
        "engDate": "2002-12-03",
        "nepDate": "17.08.2059"
    },
    {
        "engDate": "2002-12-04",
        "nepDate": "18.08.2059"
    },
    {
        "engDate": "2002-12-05",
        "nepDate": "19.08.2059"
    },
    {
        "engDate": "2002-12-06",
        "nepDate": "20.08.2059"
    },
    {
        "engDate": "2002-12-07",
        "nepDate": "21.08.2059"
    },
    {
        "engDate": "2002-12-08",
        "nepDate": "22.08.2059"
    },
    {
        "engDate": "2002-12-09",
        "nepDate": "23.08.2059"
    },
    {
        "engDate": "2002-12-10",
        "nepDate": "24.08.2059"
    },
    {
        "engDate": "2002-12-11",
        "nepDate": "25.08.2059"
    },
    {
        "engDate": "2002-12-12",
        "nepDate": "26.08.2059"
    },
    {
        "engDate": "2002-12-13",
        "nepDate": "27.08.2059"
    },
    {
        "engDate": "2002-12-14",
        "nepDate": "28.08.2059"
    },
    {
        "engDate": "2002-12-15",
        "nepDate": "29.08.2059"
    },
    {
        "engDate": "2002-12-16",
        "nepDate": "01.09.2059"
    },
    {
        "engDate": "2002-12-17",
        "nepDate": "02.09.2059"
    },
    {
        "engDate": "2002-12-18",
        "nepDate": "03.09.2059"
    },
    {
        "engDate": "2002-12-19",
        "nepDate": "04.09.2059"
    },
    {
        "engDate": "2002-12-20",
        "nepDate": "05.09.2059"
    },
    {
        "engDate": "2002-12-21",
        "nepDate": "06.09.2059"
    },
    {
        "engDate": "2002-12-22",
        "nepDate": "07.09.2059"
    },
    {
        "engDate": "2002-12-23",
        "nepDate": "08.09.2059"
    },
    {
        "engDate": "2002-12-24",
        "nepDate": "09.09.2059"
    },
    {
        "engDate": "2002-12-25",
        "nepDate": "10.09.2059"
    },
    {
        "engDate": "2002-12-26",
        "nepDate": "11.09.2059"
    },
    {
        "engDate": "2002-12-27",
        "nepDate": "12.09.2059"
    },
    {
        "engDate": "2002-12-28",
        "nepDate": "13.09.2059"
    },
    {
        "engDate": "2002-12-29",
        "nepDate": "14.09.2059"
    },
    {
        "engDate": "2002-12-30",
        "nepDate": "15.09.2059"
    },
    {
        "engDate": "2002-12-31",
        "nepDate": "16.09.2059"
    },
    {
        "engDate": "2003-01-01",
        "nepDate": "17.09.2059"
    },
    {
        "engDate": "2003-01-02",
        "nepDate": "18.09.2059"
    },
    {
        "engDate": "2003-01-03",
        "nepDate": "19.09.2059"
    },
    {
        "engDate": "2003-01-04",
        "nepDate": "20.09.2059"
    },
    {
        "engDate": "2003-01-05",
        "nepDate": "21.09.2059"
    },
    {
        "engDate": "2003-01-06",
        "nepDate": "22.09.2059"
    },
    {
        "engDate": "2003-01-07",
        "nepDate": "23.09.2059"
    },
    {
        "engDate": "2003-01-08",
        "nepDate": "24.09.2059"
    },
    {
        "engDate": "2003-01-09",
        "nepDate": "25.09.2059"
    },
    {
        "engDate": "2003-01-10",
        "nepDate": "26.09.2059"
    },
    {
        "engDate": "2003-01-11",
        "nepDate": "27.09.2059"
    },
    {
        "engDate": "2003-01-12",
        "nepDate": "28.09.2059"
    },
    {
        "engDate": "2003-01-13",
        "nepDate": "29.09.2059"
    },
    {
        "engDate": "2003-01-14",
        "nepDate": "30.09.2059"
    },
    {
        "engDate": "2003-01-15",
        "nepDate": "01.10.2059"
    },
    {
        "engDate": "2003-01-16",
        "nepDate": "02.10.2059"
    },
    {
        "engDate": "2003-01-17",
        "nepDate": "03.10.2059"
    },
    {
        "engDate": "2003-01-18",
        "nepDate": "04.10.2059"
    },
    {
        "engDate": "2003-01-19",
        "nepDate": "05.10.2059"
    },
    {
        "engDate": "2003-01-20",
        "nepDate": "06.10.2059"
    },
    {
        "engDate": "2003-01-21",
        "nepDate": "07.10.2059"
    },
    {
        "engDate": "2003-01-22",
        "nepDate": "08.10.2059"
    },
    {
        "engDate": "2003-01-23",
        "nepDate": "09.10.2059"
    },
    {
        "engDate": "2003-01-24",
        "nepDate": "10.10.2059"
    },
    {
        "engDate": "2003-01-25",
        "nepDate": "11.10.2059"
    },
    {
        "engDate": "2003-01-26",
        "nepDate": "12.10.2059"
    },
    {
        "engDate": "2003-01-27",
        "nepDate": "13.10.2059"
    },
    {
        "engDate": "2003-01-28",
        "nepDate": "14.10.2059"
    },
    {
        "engDate": "2003-01-29",
        "nepDate": "15.10.2059"
    },
    {
        "engDate": "2003-01-30",
        "nepDate": "16.10.2059"
    },
    {
        "engDate": "2003-01-31",
        "nepDate": "17.10.2059"
    },
    {
        "engDate": "2003-02-01",
        "nepDate": "18.10.2059"
    },
    {
        "engDate": "2003-02-02",
        "nepDate": "19.10.2059"
    },
    {
        "engDate": "2003-02-03",
        "nepDate": "20.10.2059"
    },
    {
        "engDate": "2003-02-04",
        "nepDate": "21.10.2059"
    },
    {
        "engDate": "2003-02-05",
        "nepDate": "22.10.2059"
    },
    {
        "engDate": "2003-02-06",
        "nepDate": "23.10.2059"
    },
    {
        "engDate": "2003-02-07",
        "nepDate": "24.10.2059"
    },
    {
        "engDate": "2003-02-08",
        "nepDate": "25.10.2059"
    },
    {
        "engDate": "2003-02-09",
        "nepDate": "26.10.2059"
    },
    {
        "engDate": "2003-02-10",
        "nepDate": "27.10.2059"
    },
    {
        "engDate": "2003-02-11",
        "nepDate": "28.10.2059"
    },
    {
        "engDate": "2003-02-12",
        "nepDate": "29.10.2059"
    },
    {
        "engDate": "2003-02-13",
        "nepDate": "01.11.2059"
    },
    {
        "engDate": "2003-02-14",
        "nepDate": "02.11.2059"
    },
    {
        "engDate": "2003-02-15",
        "nepDate": "03.11.2059"
    },
    {
        "engDate": "2003-02-16",
        "nepDate": "04.11.2059"
    },
    {
        "engDate": "2003-02-17",
        "nepDate": "05.11.2059"
    },
    {
        "engDate": "2003-02-18",
        "nepDate": "06.11.2059"
    },
    {
        "engDate": "2003-02-19",
        "nepDate": "07.11.2059"
    },
    {
        "engDate": "2003-02-20",
        "nepDate": "08.11.2059"
    },
    {
        "engDate": "2003-02-21",
        "nepDate": "09.11.2059"
    },
    {
        "engDate": "2003-02-22",
        "nepDate": "10.11.2059"
    },
    {
        "engDate": "2003-02-23",
        "nepDate": "11.11.2059"
    },
    {
        "engDate": "2003-02-24",
        "nepDate": "12.11.2059"
    },
    {
        "engDate": "2003-02-25",
        "nepDate": "13.11.2059"
    },
    {
        "engDate": "2003-02-26",
        "nepDate": "14.11.2059"
    },
    {
        "engDate": "2003-02-27",
        "nepDate": "15.11.2059"
    },
    {
        "engDate": "2003-02-28",
        "nepDate": "16.11.2059"
    },
    {
        "engDate": "2003-03-01",
        "nepDate": "17.11.2059"
    },
    {
        "engDate": "2003-03-02",
        "nepDate": "18.11.2059"
    },
    {
        "engDate": "2003-03-03",
        "nepDate": "19.11.2059"
    },
    {
        "engDate": "2003-03-04",
        "nepDate": "20.11.2059"
    },
    {
        "engDate": "2003-03-05",
        "nepDate": "21.11.2059"
    },
    {
        "engDate": "2003-03-06",
        "nepDate": "22.11.2059"
    },
    {
        "engDate": "2003-03-07",
        "nepDate": "23.11.2059"
    },
    {
        "engDate": "2003-03-08",
        "nepDate": "24.11.2059"
    },
    {
        "engDate": "2003-03-09",
        "nepDate": "25.11.2059"
    },
    {
        "engDate": "2003-03-10",
        "nepDate": "26.11.2059"
    },
    {
        "engDate": "2003-03-11",
        "nepDate": "27.11.2059"
    },
    {
        "engDate": "2003-03-12",
        "nepDate": "28.11.2059"
    },
    {
        "engDate": "2003-03-13",
        "nepDate": "29.11.2059"
    },
    {
        "engDate": "2003-03-14",
        "nepDate": "30.11.2059"
    },
    {
        "engDate": "2003-03-15",
        "nepDate": "01.12.2059"
    },
    {
        "engDate": "2003-03-16",
        "nepDate": "02.12.2059"
    },
    {
        "engDate": "2003-03-17",
        "nepDate": "03.12.2059"
    },
    {
        "engDate": "2003-03-18",
        "nepDate": "04.12.2059"
    },
    {
        "engDate": "2003-03-19",
        "nepDate": "05.12.2059"
    },
    {
        "engDate": "2003-03-20",
        "nepDate": "06.12.2059"
    },
    {
        "engDate": "2003-03-21",
        "nepDate": "07.12.2059"
    },
    {
        "engDate": "2003-03-22",
        "nepDate": "08.12.2059"
    },
    {
        "engDate": "2003-03-23",
        "nepDate": "09.12.2059"
    },
    {
        "engDate": "2003-03-24",
        "nepDate": "10.12.2059"
    },
    {
        "engDate": "2003-03-25",
        "nepDate": "11.12.2059"
    },
    {
        "engDate": "2003-03-26",
        "nepDate": "12.12.2059"
    },
    {
        "engDate": "2003-03-27",
        "nepDate": "13.12.2059"
    },
    {
        "engDate": "2003-03-28",
        "nepDate": "14.12.2059"
    },
    {
        "engDate": "2003-03-29",
        "nepDate": "15.12.2059"
    },
    {
        "engDate": "2003-03-30",
        "nepDate": "16.12.2059"
    },
    {
        "engDate": "2003-03-31",
        "nepDate": "17.12.2059"
    },
    {
        "engDate": "2003-04-01",
        "nepDate": "18.12.2059"
    },
    {
        "engDate": "2003-04-02",
        "nepDate": "19.12.2059"
    },
    {
        "engDate": "2003-04-03",
        "nepDate": "20.12.2059"
    },
    {
        "engDate": "2003-04-04",
        "nepDate": "21.12.2059"
    },
    {
        "engDate": "2003-04-05",
        "nepDate": "22.12.2059"
    },
    {
        "engDate": "2003-04-06",
        "nepDate": "23.12.2059"
    },
    {
        "engDate": "2003-04-07",
        "nepDate": "24.12.2059"
    },
    {
        "engDate": "2003-04-08",
        "nepDate": "25.12.2059"
    },
    {
        "engDate": "2003-04-09",
        "nepDate": "26.12.2059"
    },
    {
        "engDate": "2003-04-10",
        "nepDate": "27.12.2059"
    },
    {
        "engDate": "2003-04-11",
        "nepDate": "28.12.2059"
    },
    {
        "engDate": "2003-04-12",
        "nepDate": "29.12.2059"
    },
    {
        "engDate": "2003-04-13",
        "nepDate": "30.12.2059"
    },
    {
        "engDate": "2003-04-14",
        "nepDate": "01.01.2060"
    },
    {
        "engDate": "2003-04-15",
        "nepDate": "02.01.2060"
    },
    {
        "engDate": "2003-04-16",
        "nepDate": "03.01.2060"
    },
    {
        "engDate": "2003-04-17",
        "nepDate": "04.01.2060"
    },
    {
        "engDate": "2003-04-18",
        "nepDate": "05.01.2060"
    },
    {
        "engDate": "2003-04-19",
        "nepDate": "06.01.2060"
    },
    {
        "engDate": "2003-04-20",
        "nepDate": "07.01.2060"
    },
    {
        "engDate": "2003-04-21",
        "nepDate": "08.01.2060"
    },
    {
        "engDate": "2003-04-22",
        "nepDate": "09.01.2060"
    },
    {
        "engDate": "2003-04-23",
        "nepDate": "10.01.2060"
    },
    {
        "engDate": "2003-04-24",
        "nepDate": "11.01.2060"
    },
    {
        "engDate": "2003-04-25",
        "nepDate": "12.01.2060"
    },
    {
        "engDate": "2003-04-26",
        "nepDate": "13.01.2060"
    },
    {
        "engDate": "2003-04-27",
        "nepDate": "14.01.2060"
    },
    {
        "engDate": "2003-04-28",
        "nepDate": "15.01.2060"
    },
    {
        "engDate": "2003-04-29",
        "nepDate": "16.01.2060"
    },
    {
        "engDate": "2003-04-30",
        "nepDate": "17.01.2060"
    },
    {
        "engDate": "2003-05-01",
        "nepDate": "18.01.2060"
    },
    {
        "engDate": "2003-05-02",
        "nepDate": "19.01.2060"
    },
    {
        "engDate": "2003-05-03",
        "nepDate": "20.01.2060"
    },
    {
        "engDate": "2003-05-04",
        "nepDate": "21.01.2060"
    },
    {
        "engDate": "2003-05-05",
        "nepDate": "22.01.2060"
    },
    {
        "engDate": "2003-05-06",
        "nepDate": "23.01.2060"
    },
    {
        "engDate": "2003-05-07",
        "nepDate": "24.01.2060"
    },
    {
        "engDate": "2003-05-08",
        "nepDate": "25.01.2060"
    },
    {
        "engDate": "2003-05-09",
        "nepDate": "26.01.2060"
    },
    {
        "engDate": "2003-05-10",
        "nepDate": "27.01.2060"
    },
    {
        "engDate": "2003-05-11",
        "nepDate": "28.01.2060"
    },
    {
        "engDate": "2003-05-12",
        "nepDate": "29.01.2060"
    },
    {
        "engDate": "2003-05-13",
        "nepDate": "30.01.2060"
    },
    {
        "engDate": "2003-05-14",
        "nepDate": "31.01.2060"
    },
    {
        "engDate": "2003-05-15",
        "nepDate": "01.02.2060"
    },
    {
        "engDate": "2003-05-16",
        "nepDate": "02.02.2060"
    },
    {
        "engDate": "2003-05-17",
        "nepDate": "03.02.2060"
    },
    {
        "engDate": "2003-05-18",
        "nepDate": "04.02.2060"
    },
    {
        "engDate": "2003-05-19",
        "nepDate": "05.02.2060"
    },
    {
        "engDate": "2003-05-20",
        "nepDate": "06.02.2060"
    },
    {
        "engDate": "2003-05-21",
        "nepDate": "07.02.2060"
    },
    {
        "engDate": "2003-05-22",
        "nepDate": "08.02.2060"
    },
    {
        "engDate": "2003-05-23",
        "nepDate": "09.02.2060"
    },
    {
        "engDate": "2003-05-24",
        "nepDate": "10.02.2060"
    },
    {
        "engDate": "2003-05-25",
        "nepDate": "11.02.2060"
    },
    {
        "engDate": "2003-05-26",
        "nepDate": "12.02.2060"
    },
    {
        "engDate": "2003-05-27",
        "nepDate": "13.02.2060"
    },
    {
        "engDate": "2003-05-28",
        "nepDate": "14.02.2060"
    },
    {
        "engDate": "2003-05-29",
        "nepDate": "15.02.2060"
    },
    {
        "engDate": "2003-05-30",
        "nepDate": "16.02.2060"
    },
    {
        "engDate": "2003-05-31",
        "nepDate": "17.02.2060"
    },
    {
        "engDate": "2003-06-01",
        "nepDate": "18.02.2060"
    },
    {
        "engDate": "2003-06-02",
        "nepDate": "19.02.2060"
    },
    {
        "engDate": "2003-06-03",
        "nepDate": "20.02.2060"
    },
    {
        "engDate": "2003-06-04",
        "nepDate": "21.02.2060"
    },
    {
        "engDate": "2003-06-05",
        "nepDate": "22.02.2060"
    },
    {
        "engDate": "2003-06-06",
        "nepDate": "23.02.2060"
    },
    {
        "engDate": "2003-06-07",
        "nepDate": "24.02.2060"
    },
    {
        "engDate": "2003-06-08",
        "nepDate": "25.02.2060"
    },
    {
        "engDate": "2003-06-09",
        "nepDate": "26.02.2060"
    },
    {
        "engDate": "2003-06-10",
        "nepDate": "27.02.2060"
    },
    {
        "engDate": "2003-06-11",
        "nepDate": "28.02.2060"
    },
    {
        "engDate": "2003-06-12",
        "nepDate": "29.02.2060"
    },
    {
        "engDate": "2003-06-13",
        "nepDate": "30.02.2060"
    },
    {
        "engDate": "2003-06-14",
        "nepDate": "31.02.2060"
    },
    {
        "engDate": "2003-06-15",
        "nepDate": "01.03.2060"
    },
    {
        "engDate": "2003-06-16",
        "nepDate": "02.03.2060"
    },
    {
        "engDate": "2003-06-17",
        "nepDate": "03.03.2060"
    },
    {
        "engDate": "2003-06-18",
        "nepDate": "04.03.2060"
    },
    {
        "engDate": "2003-06-19",
        "nepDate": "05.03.2060"
    },
    {
        "engDate": "2003-06-20",
        "nepDate": "06.03.2060"
    },
    {
        "engDate": "2003-06-21",
        "nepDate": "07.03.2060"
    },
    {
        "engDate": "2003-06-22",
        "nepDate": "08.03.2060"
    },
    {
        "engDate": "2003-06-23",
        "nepDate": "09.03.2060"
    },
    {
        "engDate": "2003-06-24",
        "nepDate": "10.03.2060"
    },
    {
        "engDate": "2003-06-25",
        "nepDate": "11.03.2060"
    },
    {
        "engDate": "2003-06-26",
        "nepDate": "12.03.2060"
    },
    {
        "engDate": "2003-06-27",
        "nepDate": "13.03.2060"
    },
    {
        "engDate": "2003-06-28",
        "nepDate": "14.03.2060"
    },
    {
        "engDate": "2003-06-29",
        "nepDate": "15.03.2060"
    },
    {
        "engDate": "2003-06-30",
        "nepDate": "16.03.2060"
    },
    {
        "engDate": "2003-07-01",
        "nepDate": "17.03.2060"
    },
    {
        "engDate": "2003-07-02",
        "nepDate": "18.03.2060"
    },
    {
        "engDate": "2003-07-03",
        "nepDate": "19.03.2060"
    },
    {
        "engDate": "2003-07-04",
        "nepDate": "20.03.2060"
    },
    {
        "engDate": "2003-07-05",
        "nepDate": "21.03.2060"
    },
    {
        "engDate": "2003-07-06",
        "nepDate": "22.03.2060"
    },
    {
        "engDate": "2003-07-07",
        "nepDate": "23.03.2060"
    },
    {
        "engDate": "2003-07-08",
        "nepDate": "24.03.2060"
    },
    {
        "engDate": "2003-07-09",
        "nepDate": "25.03.2060"
    },
    {
        "engDate": "2003-07-10",
        "nepDate": "26.03.2060"
    },
    {
        "engDate": "2003-07-11",
        "nepDate": "27.03.2060"
    },
    {
        "engDate": "2003-07-12",
        "nepDate": "28.03.2060"
    },
    {
        "engDate": "2003-07-13",
        "nepDate": "29.03.2060"
    },
    {
        "engDate": "2003-07-14",
        "nepDate": "30.03.2060"
    },
    {
        "engDate": "2003-07-15",
        "nepDate": "31.03.2060"
    },
    {
        "engDate": "2003-07-16",
        "nepDate": "32.03.2060"
    },
    {
        "engDate": "2003-07-17",
        "nepDate": "01.04.2060"
    },
    {
        "engDate": "2003-07-18",
        "nepDate": "02.04.2060"
    },
    {
        "engDate": "2003-07-19",
        "nepDate": "03.04.2060"
    },
    {
        "engDate": "2003-07-20",
        "nepDate": "04.04.2060"
    },
    {
        "engDate": "2003-07-21",
        "nepDate": "05.04.2060"
    },
    {
        "engDate": "2003-07-22",
        "nepDate": "06.04.2060"
    },
    {
        "engDate": "2003-07-23",
        "nepDate": "07.04.2060"
    },
    {
        "engDate": "2003-07-24",
        "nepDate": "08.04.2060"
    },
    {
        "engDate": "2003-07-25",
        "nepDate": "09.04.2060"
    },
    {
        "engDate": "2003-07-26",
        "nepDate": "10.04.2060"
    },
    {
        "engDate": "2003-07-27",
        "nepDate": "11.04.2060"
    },
    {
        "engDate": "2003-07-28",
        "nepDate": "12.04.2060"
    },
    {
        "engDate": "2003-07-29",
        "nepDate": "13.04.2060"
    },
    {
        "engDate": "2003-07-30",
        "nepDate": "14.04.2060"
    },
    {
        "engDate": "2003-07-31",
        "nepDate": "15.04.2060"
    },
    {
        "engDate": "2003-08-01",
        "nepDate": "16.04.2060"
    },
    {
        "engDate": "2003-08-02",
        "nepDate": "17.04.2060"
    },
    {
        "engDate": "2003-08-03",
        "nepDate": "18.04.2060"
    },
    {
        "engDate": "2003-08-04",
        "nepDate": "19.04.2060"
    },
    {
        "engDate": "2003-08-05",
        "nepDate": "20.04.2060"
    },
    {
        "engDate": "2003-08-06",
        "nepDate": "21.04.2060"
    },
    {
        "engDate": "2003-08-07",
        "nepDate": "22.04.2060"
    },
    {
        "engDate": "2003-08-08",
        "nepDate": "23.04.2060"
    },
    {
        "engDate": "2003-08-09",
        "nepDate": "24.04.2060"
    },
    {
        "engDate": "2003-08-10",
        "nepDate": "25.04.2060"
    },
    {
        "engDate": "2003-08-11",
        "nepDate": "26.04.2060"
    },
    {
        "engDate": "2003-08-12",
        "nepDate": "27.04.2060"
    },
    {
        "engDate": "2003-08-13",
        "nepDate": "28.04.2060"
    },
    {
        "engDate": "2003-08-14",
        "nepDate": "29.04.2060"
    },
    {
        "engDate": "2003-08-15",
        "nepDate": "30.04.2060"
    },
    {
        "engDate": "2003-08-16",
        "nepDate": "31.04.2060"
    },
    {
        "engDate": "2003-08-17",
        "nepDate": "32.04.2060"
    },
    {
        "engDate": "2003-08-18",
        "nepDate": "01.05.2060"
    },
    {
        "engDate": "2003-08-19",
        "nepDate": "02.05.2060"
    },
    {
        "engDate": "2003-08-20",
        "nepDate": "03.05.2060"
    },
    {
        "engDate": "2003-08-21",
        "nepDate": "04.05.2060"
    },
    {
        "engDate": "2003-08-22",
        "nepDate": "05.05.2060"
    },
    {
        "engDate": "2003-08-23",
        "nepDate": "06.05.2060"
    },
    {
        "engDate": "2003-08-24",
        "nepDate": "07.05.2060"
    },
    {
        "engDate": "2003-08-25",
        "nepDate": "08.05.2060"
    },
    {
        "engDate": "2003-08-26",
        "nepDate": "09.05.2060"
    },
    {
        "engDate": "2003-08-27",
        "nepDate": "10.05.2060"
    },
    {
        "engDate": "2003-08-28",
        "nepDate": "11.05.2060"
    },
    {
        "engDate": "2003-08-29",
        "nepDate": "12.05.2060"
    },
    {
        "engDate": "2003-08-30",
        "nepDate": "13.05.2060"
    },
    {
        "engDate": "2003-08-31",
        "nepDate": "14.05.2060"
    },
    {
        "engDate": "2003-09-01",
        "nepDate": "15.05.2060"
    },
    {
        "engDate": "2003-09-02",
        "nepDate": "16.05.2060"
    },
    {
        "engDate": "2003-09-03",
        "nepDate": "17.05.2060"
    },
    {
        "engDate": "2003-09-04",
        "nepDate": "18.05.2060"
    },
    {
        "engDate": "2003-09-05",
        "nepDate": "19.05.2060"
    },
    {
        "engDate": "2003-09-06",
        "nepDate": "20.05.2060"
    },
    {
        "engDate": "2003-09-07",
        "nepDate": "21.05.2060"
    },
    {
        "engDate": "2003-09-08",
        "nepDate": "22.05.2060"
    },
    {
        "engDate": "2003-09-09",
        "nepDate": "23.05.2060"
    },
    {
        "engDate": "2003-09-10",
        "nepDate": "24.05.2060"
    },
    {
        "engDate": "2003-09-11",
        "nepDate": "25.05.2060"
    },
    {
        "engDate": "2003-09-12",
        "nepDate": "26.05.2060"
    },
    {
        "engDate": "2003-09-13",
        "nepDate": "27.05.2060"
    },
    {
        "engDate": "2003-09-14",
        "nepDate": "28.05.2060"
    },
    {
        "engDate": "2003-09-15",
        "nepDate": "29.05.2060"
    },
    {
        "engDate": "2003-09-16",
        "nepDate": "30.05.2060"
    },
    {
        "engDate": "2003-09-17",
        "nepDate": "31.05.2060"
    },
    {
        "engDate": "2003-09-18",
        "nepDate": "01.06.2060"
    },
    {
        "engDate": "2003-09-19",
        "nepDate": "02.06.2060"
    },
    {
        "engDate": "2003-09-20",
        "nepDate": "03.06.2060"
    },
    {
        "engDate": "2003-09-21",
        "nepDate": "04.06.2060"
    },
    {
        "engDate": "2003-09-22",
        "nepDate": "05.06.2060"
    },
    {
        "engDate": "2003-09-23",
        "nepDate": "06.06.2060"
    },
    {
        "engDate": "2003-09-24",
        "nepDate": "07.06.2060"
    },
    {
        "engDate": "2003-09-25",
        "nepDate": "08.06.2060"
    },
    {
        "engDate": "2003-09-26",
        "nepDate": "09.06.2060"
    },
    {
        "engDate": "2003-09-27",
        "nepDate": "10.06.2060"
    },
    {
        "engDate": "2003-09-28",
        "nepDate": "11.06.2060"
    },
    {
        "engDate": "2003-09-29",
        "nepDate": "12.06.2060"
    },
    {
        "engDate": "2003-09-30",
        "nepDate": "13.06.2060"
    },
    {
        "engDate": "2003-10-01",
        "nepDate": "14.06.2060"
    },
    {
        "engDate": "2003-10-02",
        "nepDate": "15.06.2060"
    },
    {
        "engDate": "2003-10-03",
        "nepDate": "16.06.2060"
    },
    {
        "engDate": "2003-10-04",
        "nepDate": "17.06.2060"
    },
    {
        "engDate": "2003-10-05",
        "nepDate": "18.06.2060"
    },
    {
        "engDate": "2003-10-06",
        "nepDate": "19.06.2060"
    },
    {
        "engDate": "2003-10-07",
        "nepDate": "20.06.2060"
    },
    {
        "engDate": "2003-10-08",
        "nepDate": "21.06.2060"
    },
    {
        "engDate": "2003-10-09",
        "nepDate": "22.06.2060"
    },
    {
        "engDate": "2003-10-10",
        "nepDate": "23.06.2060"
    },
    {
        "engDate": "2003-10-11",
        "nepDate": "24.06.2060"
    },
    {
        "engDate": "2003-10-12",
        "nepDate": "25.06.2060"
    },
    {
        "engDate": "2003-10-13",
        "nepDate": "26.06.2060"
    },
    {
        "engDate": "2003-10-14",
        "nepDate": "27.06.2060"
    },
    {
        "engDate": "2003-10-15",
        "nepDate": "28.06.2060"
    },
    {
        "engDate": "2003-10-16",
        "nepDate": "29.06.2060"
    },
    {
        "engDate": "2003-10-17",
        "nepDate": "30.06.2060"
    },
    {
        "engDate": "2003-10-18",
        "nepDate": "01.07.2060"
    },
    {
        "engDate": "2003-10-19",
        "nepDate": "02.07.2060"
    },
    {
        "engDate": "2003-10-20",
        "nepDate": "03.07.2060"
    },
    {
        "engDate": "2003-10-21",
        "nepDate": "04.07.2060"
    },
    {
        "engDate": "2003-10-22",
        "nepDate": "05.07.2060"
    },
    {
        "engDate": "2003-10-23",
        "nepDate": "06.07.2060"
    },
    {
        "engDate": "2003-10-24",
        "nepDate": "07.07.2060"
    },
    {
        "engDate": "2003-10-25",
        "nepDate": "08.07.2060"
    },
    {
        "engDate": "2003-10-26",
        "nepDate": "09.07.2060"
    },
    {
        "engDate": "2003-10-27",
        "nepDate": "10.07.2060"
    },
    {
        "engDate": "2003-10-28",
        "nepDate": "11.07.2060"
    },
    {
        "engDate": "2003-10-29",
        "nepDate": "12.07.2060"
    },
    {
        "engDate": "2003-10-30",
        "nepDate": "13.07.2060"
    },
    {
        "engDate": "2003-10-31",
        "nepDate": "14.07.2060"
    },
    {
        "engDate": "2003-11-01",
        "nepDate": "15.07.2060"
    },
    {
        "engDate": "2003-11-02",
        "nepDate": "16.07.2060"
    },
    {
        "engDate": "2003-11-03",
        "nepDate": "17.07.2060"
    },
    {
        "engDate": "2003-11-04",
        "nepDate": "18.07.2060"
    },
    {
        "engDate": "2003-11-05",
        "nepDate": "19.07.2060"
    },
    {
        "engDate": "2003-11-06",
        "nepDate": "20.07.2060"
    },
    {
        "engDate": "2003-11-07",
        "nepDate": "21.07.2060"
    },
    {
        "engDate": "2003-11-08",
        "nepDate": "22.07.2060"
    },
    {
        "engDate": "2003-11-09",
        "nepDate": "23.07.2060"
    },
    {
        "engDate": "2003-11-10",
        "nepDate": "24.07.2060"
    },
    {
        "engDate": "2003-11-11",
        "nepDate": "25.07.2060"
    },
    {
        "engDate": "2003-11-12",
        "nepDate": "26.07.2060"
    },
    {
        "engDate": "2003-11-13",
        "nepDate": "27.07.2060"
    },
    {
        "engDate": "2003-11-14",
        "nepDate": "28.07.2060"
    },
    {
        "engDate": "2003-11-15",
        "nepDate": "29.07.2060"
    },
    {
        "engDate": "2003-11-16",
        "nepDate": "30.07.2060"
    },
    {
        "engDate": "2003-11-17",
        "nepDate": "01.08.2060"
    },
    {
        "engDate": "2003-11-18",
        "nepDate": "02.08.2060"
    },
    {
        "engDate": "2003-11-19",
        "nepDate": "03.08.2060"
    },
    {
        "engDate": "2003-11-20",
        "nepDate": "04.08.2060"
    },
    {
        "engDate": "2003-11-21",
        "nepDate": "05.08.2060"
    },
    {
        "engDate": "2003-11-22",
        "nepDate": "06.08.2060"
    },
    {
        "engDate": "2003-11-23",
        "nepDate": "07.08.2060"
    },
    {
        "engDate": "2003-11-24",
        "nepDate": "08.08.2060"
    },
    {
        "engDate": "2003-11-25",
        "nepDate": "09.08.2060"
    },
    {
        "engDate": "2003-11-26",
        "nepDate": "10.08.2060"
    },
    {
        "engDate": "2003-11-27",
        "nepDate": "11.08.2060"
    },
    {
        "engDate": "2003-11-28",
        "nepDate": "12.08.2060"
    },
    {
        "engDate": "2003-11-29",
        "nepDate": "13.08.2060"
    },
    {
        "engDate": "2003-11-30",
        "nepDate": "14.08.2060"
    },
    {
        "engDate": "2003-12-01",
        "nepDate": "15.08.2060"
    },
    {
        "engDate": "2003-12-02",
        "nepDate": "16.08.2060"
    },
    {
        "engDate": "2003-12-03",
        "nepDate": "17.08.2060"
    },
    {
        "engDate": "2003-12-04",
        "nepDate": "18.08.2060"
    },
    {
        "engDate": "2003-12-05",
        "nepDate": "19.08.2060"
    },
    {
        "engDate": "2003-12-06",
        "nepDate": "20.08.2060"
    },
    {
        "engDate": "2003-12-07",
        "nepDate": "21.08.2060"
    },
    {
        "engDate": "2003-12-08",
        "nepDate": "22.08.2060"
    },
    {
        "engDate": "2003-12-09",
        "nepDate": "23.08.2060"
    },
    {
        "engDate": "2003-12-10",
        "nepDate": "24.08.2060"
    },
    {
        "engDate": "2003-12-11",
        "nepDate": "25.08.2060"
    },
    {
        "engDate": "2003-12-12",
        "nepDate": "26.08.2060"
    },
    {
        "engDate": "2003-12-13",
        "nepDate": "27.08.2060"
    },
    {
        "engDate": "2003-12-14",
        "nepDate": "28.08.2060"
    },
    {
        "engDate": "2003-12-15",
        "nepDate": "29.08.2060"
    },
    {
        "engDate": "2003-12-16",
        "nepDate": "01.09.2060"
    },
    {
        "engDate": "2003-12-17",
        "nepDate": "02.09.2060"
    },
    {
        "engDate": "2003-12-18",
        "nepDate": "03.09.2060"
    },
    {
        "engDate": "2003-12-19",
        "nepDate": "04.09.2060"
    },
    {
        "engDate": "2003-12-20",
        "nepDate": "05.09.2060"
    },
    {
        "engDate": "2003-12-21",
        "nepDate": "06.09.2060"
    },
    {
        "engDate": "2003-12-22",
        "nepDate": "07.09.2060"
    },
    {
        "engDate": "2003-12-23",
        "nepDate": "08.09.2060"
    },
    {
        "engDate": "2003-12-24",
        "nepDate": "09.09.2060"
    },
    {
        "engDate": "2003-12-25",
        "nepDate": "10.09.2060"
    },
    {
        "engDate": "2003-12-26",
        "nepDate": "11.09.2060"
    },
    {
        "engDate": "2003-12-27",
        "nepDate": "12.09.2060"
    },
    {
        "engDate": "2003-12-28",
        "nepDate": "13.09.2060"
    },
    {
        "engDate": "2003-12-29",
        "nepDate": "14.09.2060"
    },
    {
        "engDate": "2003-12-30",
        "nepDate": "15.09.2060"
    },
    {
        "engDate": "2003-12-31",
        "nepDate": "16.09.2060"
    },
    {
        "engDate": "2004-01-01",
        "nepDate": "17.09.2060"
    },
    {
        "engDate": "2004-01-02",
        "nepDate": "18.09.2060"
    },
    {
        "engDate": "2004-01-03",
        "nepDate": "19.09.2060"
    },
    {
        "engDate": "2004-01-04",
        "nepDate": "20.09.2060"
    },
    {
        "engDate": "2004-01-05",
        "nepDate": "21.09.2060"
    },
    {
        "engDate": "2004-01-06",
        "nepDate": "22.09.2060"
    },
    {
        "engDate": "2004-01-07",
        "nepDate": "23.09.2060"
    },
    {
        "engDate": "2004-01-08",
        "nepDate": "24.09.2060"
    },
    {
        "engDate": "2004-01-09",
        "nepDate": "25.09.2060"
    },
    {
        "engDate": "2004-01-10",
        "nepDate": "26.09.2060"
    },
    {
        "engDate": "2004-01-11",
        "nepDate": "27.09.2060"
    },
    {
        "engDate": "2004-01-12",
        "nepDate": "28.09.2060"
    },
    {
        "engDate": "2004-01-13",
        "nepDate": "29.09.2060"
    },
    {
        "engDate": "2004-01-14",
        "nepDate": "30.09.2060"
    },
    {
        "engDate": "2004-01-15",
        "nepDate": "01.10.2060"
    },
    {
        "engDate": "2004-01-16",
        "nepDate": "02.10.2060"
    },
    {
        "engDate": "2004-01-17",
        "nepDate": "03.10.2060"
    },
    {
        "engDate": "2004-01-18",
        "nepDate": "04.10.2060"
    },
    {
        "engDate": "2004-01-19",
        "nepDate": "05.10.2060"
    },
    {
        "engDate": "2004-01-20",
        "nepDate": "06.10.2060"
    },
    {
        "engDate": "2004-01-21",
        "nepDate": "07.10.2060"
    },
    {
        "engDate": "2004-01-22",
        "nepDate": "08.10.2060"
    },
    {
        "engDate": "2004-01-23",
        "nepDate": "09.10.2060"
    },
    {
        "engDate": "2004-01-24",
        "nepDate": "10.10.2060"
    },
    {
        "engDate": "2004-01-25",
        "nepDate": "11.10.2060"
    },
    {
        "engDate": "2004-01-26",
        "nepDate": "12.10.2060"
    },
    {
        "engDate": "2004-01-27",
        "nepDate": "13.10.2060"
    },
    {
        "engDate": "2004-01-28",
        "nepDate": "14.10.2060"
    },
    {
        "engDate": "2004-01-29",
        "nepDate": "15.10.2060"
    },
    {
        "engDate": "2004-01-30",
        "nepDate": "16.10.2060"
    },
    {
        "engDate": "2004-01-31",
        "nepDate": "17.10.2060"
    },
    {
        "engDate": "2004-02-01",
        "nepDate": "18.10.2060"
    },
    {
        "engDate": "2004-02-02",
        "nepDate": "19.10.2060"
    },
    {
        "engDate": "2004-02-03",
        "nepDate": "20.10.2060"
    },
    {
        "engDate": "2004-02-04",
        "nepDate": "21.10.2060"
    },
    {
        "engDate": "2004-02-05",
        "nepDate": "22.10.2060"
    },
    {
        "engDate": "2004-02-06",
        "nepDate": "23.10.2060"
    },
    {
        "engDate": "2004-02-07",
        "nepDate": "24.10.2060"
    },
    {
        "engDate": "2004-02-08",
        "nepDate": "25.10.2060"
    },
    {
        "engDate": "2004-02-09",
        "nepDate": "26.10.2060"
    },
    {
        "engDate": "2004-02-10",
        "nepDate": "27.10.2060"
    },
    {
        "engDate": "2004-02-11",
        "nepDate": "28.10.2060"
    },
    {
        "engDate": "2004-02-12",
        "nepDate": "29.10.2060"
    },
    {
        "engDate": "2004-02-13",
        "nepDate": "01.11.2060"
    },
    {
        "engDate": "2004-02-14",
        "nepDate": "02.11.2060"
    },
    {
        "engDate": "2004-02-15",
        "nepDate": "03.11.2060"
    },
    {
        "engDate": "2004-02-16",
        "nepDate": "04.11.2060"
    },
    {
        "engDate": "2004-02-17",
        "nepDate": "05.11.2060"
    },
    {
        "engDate": "2004-02-18",
        "nepDate": "06.11.2060"
    },
    {
        "engDate": "2004-02-19",
        "nepDate": "07.11.2060"
    },
    {
        "engDate": "2004-02-20",
        "nepDate": "08.11.2060"
    },
    {
        "engDate": "2004-02-21",
        "nepDate": "09.11.2060"
    },
    {
        "engDate": "2004-02-22",
        "nepDate": "10.11.2060"
    },
    {
        "engDate": "2004-02-23",
        "nepDate": "11.11.2060"
    },
    {
        "engDate": "2004-02-24",
        "nepDate": "12.11.2060"
    },
    {
        "engDate": "2004-02-25",
        "nepDate": "13.11.2060"
    },
    {
        "engDate": "2004-02-26",
        "nepDate": "14.11.2060"
    },
    {
        "engDate": "2004-02-27",
        "nepDate": "15.11.2060"
    },
    {
        "engDate": "2004-02-28",
        "nepDate": "16.11.2060"
    },
    {
        "engDate": "2004-02-29",
        "nepDate": "17.11.2060"
    },
    {
        "engDate": "2004-03-01",
        "nepDate": "18.11.2060"
    },
    {
        "engDate": "2004-03-02",
        "nepDate": "19.11.2060"
    },
    {
        "engDate": "2004-03-03",
        "nepDate": "20.11.2060"
    },
    {
        "engDate": "2004-03-04",
        "nepDate": "21.11.2060"
    },
    {
        "engDate": "2004-03-05",
        "nepDate": "22.11.2060"
    },
    {
        "engDate": "2004-03-06",
        "nepDate": "23.11.2060"
    },
    {
        "engDate": "2004-03-07",
        "nepDate": "24.11.2060"
    },
    {
        "engDate": "2004-03-08",
        "nepDate": "25.11.2060"
    },
    {
        "engDate": "2004-03-09",
        "nepDate": "26.11.2060"
    },
    {
        "engDate": "2004-03-10",
        "nepDate": "27.11.2060"
    },
    {
        "engDate": "2004-03-11",
        "nepDate": "28.11.2060"
    },
    {
        "engDate": "2004-03-12",
        "nepDate": "29.11.2060"
    },
    {
        "engDate": "2004-03-13",
        "nepDate": "30.11.2060"
    },
    {
        "engDate": "2004-03-14",
        "nepDate": "01.12.2060"
    },
    {
        "engDate": "2004-03-15",
        "nepDate": "02.12.2060"
    },
    {
        "engDate": "2004-03-16",
        "nepDate": "03.12.2060"
    },
    {
        "engDate": "2004-03-17",
        "nepDate": "04.12.2060"
    },
    {
        "engDate": "2004-03-18",
        "nepDate": "05.12.2060"
    },
    {
        "engDate": "2004-03-19",
        "nepDate": "06.12.2060"
    },
    {
        "engDate": "2004-03-20",
        "nepDate": "07.12.2060"
    },
    {
        "engDate": "2004-03-21",
        "nepDate": "08.12.2060"
    },
    {
        "engDate": "2004-03-22",
        "nepDate": "09.12.2060"
    },
    {
        "engDate": "2004-03-23",
        "nepDate": "10.12.2060"
    },
    {
        "engDate": "2004-03-24",
        "nepDate": "11.12.2060"
    },
    {
        "engDate": "2004-03-25",
        "nepDate": "12.12.2060"
    },
    {
        "engDate": "2004-03-26",
        "nepDate": "13.12.2060"
    },
    {
        "engDate": "2004-03-27",
        "nepDate": "14.12.2060"
    },
    {
        "engDate": "2004-03-28",
        "nepDate": "15.12.2060"
    },
    {
        "engDate": "2004-03-29",
        "nepDate": "16.12.2060"
    },
    {
        "engDate": "2004-03-30",
        "nepDate": "17.12.2060"
    },
    {
        "engDate": "2004-03-31",
        "nepDate": "18.12.2060"
    },
    {
        "engDate": "2004-04-01",
        "nepDate": "19.12.2060"
    },
    {
        "engDate": "2004-04-02",
        "nepDate": "20.12.2060"
    },
    {
        "engDate": "2004-04-03",
        "nepDate": "21.12.2060"
    },
    {
        "engDate": "2004-04-04",
        "nepDate": "22.12.2060"
    },
    {
        "engDate": "2004-04-05",
        "nepDate": "23.12.2060"
    },
    {
        "engDate": "2004-04-06",
        "nepDate": "24.12.2060"
    },
    {
        "engDate": "2004-04-07",
        "nepDate": "25.12.2060"
    },
    {
        "engDate": "2004-04-08",
        "nepDate": "26.12.2060"
    },
    {
        "engDate": "2004-04-09",
        "nepDate": "27.12.2060"
    },
    {
        "engDate": "2004-04-10",
        "nepDate": "28.12.2060"
    },
    {
        "engDate": "2004-04-11",
        "nepDate": "29.12.2060"
    },
    {
        "engDate": "2004-04-12",
        "nepDate": "30.12.2060"
    },
    {
        "engDate": "2004-04-13",
        "nepDate": "01.01.2061"
    },
    {
        "engDate": "2004-04-14",
        "nepDate": "02.01.2061"
    },
    {
        "engDate": "2004-04-15",
        "nepDate": "03.01.2061"
    },
    {
        "engDate": "2004-04-16",
        "nepDate": "04.01.2061"
    },
    {
        "engDate": "2004-04-17",
        "nepDate": "05.01.2061"
    },
    {
        "engDate": "2004-04-18",
        "nepDate": "06.01.2061"
    },
    {
        "engDate": "2004-04-19",
        "nepDate": "07.01.2061"
    },
    {
        "engDate": "2004-04-20",
        "nepDate": "08.01.2061"
    },
    {
        "engDate": "2004-04-21",
        "nepDate": "09.01.2061"
    },
    {
        "engDate": "2004-04-22",
        "nepDate": "10.01.2061"
    },
    {
        "engDate": "2004-04-23",
        "nepDate": "11.01.2061"
    },
    {
        "engDate": "2004-04-24",
        "nepDate": "12.01.2061"
    },
    {
        "engDate": "2004-04-25",
        "nepDate": "13.01.2061"
    },
    {
        "engDate": "2004-04-26",
        "nepDate": "14.01.2061"
    },
    {
        "engDate": "2004-04-27",
        "nepDate": "15.01.2061"
    },
    {
        "engDate": "2004-04-28",
        "nepDate": "16.01.2061"
    },
    {
        "engDate": "2004-04-29",
        "nepDate": "17.01.2061"
    },
    {
        "engDate": "2004-04-30",
        "nepDate": "18.01.2061"
    },
    {
        "engDate": "2004-05-01",
        "nepDate": "19.01.2061"
    },
    {
        "engDate": "2004-05-02",
        "nepDate": "20.01.2061"
    },
    {
        "engDate": "2004-05-03",
        "nepDate": "21.01.2061"
    },
    {
        "engDate": "2004-05-04",
        "nepDate": "22.01.2061"
    },
    {
        "engDate": "2004-05-05",
        "nepDate": "23.01.2061"
    },
    {
        "engDate": "2004-05-06",
        "nepDate": "24.01.2061"
    },
    {
        "engDate": "2004-05-07",
        "nepDate": "25.01.2061"
    },
    {
        "engDate": "2004-05-08",
        "nepDate": "26.01.2061"
    },
    {
        "engDate": "2004-05-09",
        "nepDate": "27.01.2061"
    },
    {
        "engDate": "2004-05-10",
        "nepDate": "28.01.2061"
    },
    {
        "engDate": "2004-05-11",
        "nepDate": "29.01.2061"
    },
    {
        "engDate": "2004-05-12",
        "nepDate": "30.01.2061"
    },
    {
        "engDate": "2004-05-13",
        "nepDate": "31.01.2061"
    },
    {
        "engDate": "2004-05-14",
        "nepDate": "01.02.2061"
    },
    {
        "engDate": "2004-05-15",
        "nepDate": "02.02.2061"
    },
    {
        "engDate": "2004-05-16",
        "nepDate": "03.02.2061"
    },
    {
        "engDate": "2004-05-17",
        "nepDate": "04.02.2061"
    },
    {
        "engDate": "2004-05-18",
        "nepDate": "05.02.2061"
    },
    {
        "engDate": "2004-05-19",
        "nepDate": "06.02.2061"
    },
    {
        "engDate": "2004-05-20",
        "nepDate": "07.02.2061"
    },
    {
        "engDate": "2004-05-21",
        "nepDate": "08.02.2061"
    },
    {
        "engDate": "2004-05-22",
        "nepDate": "09.02.2061"
    },
    {
        "engDate": "2004-05-23",
        "nepDate": "10.02.2061"
    },
    {
        "engDate": "2004-05-24",
        "nepDate": "11.02.2061"
    },
    {
        "engDate": "2004-05-25",
        "nepDate": "12.02.2061"
    },
    {
        "engDate": "2004-05-26",
        "nepDate": "13.02.2061"
    },
    {
        "engDate": "2004-05-27",
        "nepDate": "14.02.2061"
    },
    {
        "engDate": "2004-05-28",
        "nepDate": "15.02.2061"
    },
    {
        "engDate": "2004-05-29",
        "nepDate": "16.02.2061"
    },
    {
        "engDate": "2004-05-30",
        "nepDate": "17.02.2061"
    },
    {
        "engDate": "2004-05-31",
        "nepDate": "18.02.2061"
    },
    {
        "engDate": "2004-06-01",
        "nepDate": "19.02.2061"
    },
    {
        "engDate": "2004-06-02",
        "nepDate": "20.02.2061"
    },
    {
        "engDate": "2004-06-03",
        "nepDate": "21.02.2061"
    },
    {
        "engDate": "2004-06-04",
        "nepDate": "22.02.2061"
    },
    {
        "engDate": "2004-06-05",
        "nepDate": "23.02.2061"
    },
    {
        "engDate": "2004-06-06",
        "nepDate": "24.02.2061"
    },
    {
        "engDate": "2004-06-07",
        "nepDate": "25.02.2061"
    },
    {
        "engDate": "2004-06-08",
        "nepDate": "26.02.2061"
    },
    {
        "engDate": "2004-06-09",
        "nepDate": "27.02.2061"
    },
    {
        "engDate": "2004-06-10",
        "nepDate": "28.02.2061"
    },
    {
        "engDate": "2004-06-11",
        "nepDate": "29.02.2061"
    },
    {
        "engDate": "2004-06-12",
        "nepDate": "30.02.2061"
    },
    {
        "engDate": "2004-06-13",
        "nepDate": "31.02.2061"
    },
    {
        "engDate": "2004-06-14",
        "nepDate": "32.02.2061"
    },
    {
        "engDate": "2004-06-15",
        "nepDate": "01.03.2061"
    },
    {
        "engDate": "2004-06-16",
        "nepDate": "02.03.2061"
    },
    {
        "engDate": "2004-06-17",
        "nepDate": "03.03.2061"
    },
    {
        "engDate": "2004-06-18",
        "nepDate": "04.03.2061"
    },
    {
        "engDate": "2004-06-19",
        "nepDate": "05.03.2061"
    },
    {
        "engDate": "2004-06-20",
        "nepDate": "06.03.2061"
    },
    {
        "engDate": "2004-06-21",
        "nepDate": "07.03.2061"
    },
    {
        "engDate": "2004-06-22",
        "nepDate": "08.03.2061"
    },
    {
        "engDate": "2004-06-23",
        "nepDate": "09.03.2061"
    },
    {
        "engDate": "2004-06-24",
        "nepDate": "10.03.2061"
    },
    {
        "engDate": "2004-06-25",
        "nepDate": "11.03.2061"
    },
    {
        "engDate": "2004-06-26",
        "nepDate": "12.03.2061"
    },
    {
        "engDate": "2004-06-27",
        "nepDate": "13.03.2061"
    },
    {
        "engDate": "2004-06-28",
        "nepDate": "14.03.2061"
    },
    {
        "engDate": "2004-06-29",
        "nepDate": "15.03.2061"
    },
    {
        "engDate": "2004-06-30",
        "nepDate": "16.03.2061"
    },
    {
        "engDate": "2004-07-01",
        "nepDate": "17.03.2061"
    },
    {
        "engDate": "2004-07-02",
        "nepDate": "18.03.2061"
    },
    {
        "engDate": "2004-07-03",
        "nepDate": "19.03.2061"
    },
    {
        "engDate": "2004-07-04",
        "nepDate": "20.03.2061"
    },
    {
        "engDate": "2004-07-05",
        "nepDate": "21.03.2061"
    },
    {
        "engDate": "2004-07-06",
        "nepDate": "22.03.2061"
    },
    {
        "engDate": "2004-07-07",
        "nepDate": "23.03.2061"
    },
    {
        "engDate": "2004-07-08",
        "nepDate": "24.03.2061"
    },
    {
        "engDate": "2004-07-09",
        "nepDate": "25.03.2061"
    },
    {
        "engDate": "2004-07-10",
        "nepDate": "26.03.2061"
    },
    {
        "engDate": "2004-07-11",
        "nepDate": "27.03.2061"
    },
    {
        "engDate": "2004-07-12",
        "nepDate": "28.03.2061"
    },
    {
        "engDate": "2004-07-13",
        "nepDate": "29.03.2061"
    },
    {
        "engDate": "2004-07-14",
        "nepDate": "30.03.2061"
    },
    {
        "engDate": "2004-07-15",
        "nepDate": "31.03.2061"
    },
    {
        "engDate": "2004-07-16",
        "nepDate": "01.04.2061"
    },
    {
        "engDate": "2004-07-17",
        "nepDate": "02.04.2061"
    },
    {
        "engDate": "2004-07-18",
        "nepDate": "03.04.2061"
    },
    {
        "engDate": "2004-07-19",
        "nepDate": "04.04.2061"
    },
    {
        "engDate": "2004-07-20",
        "nepDate": "05.04.2061"
    },
    {
        "engDate": "2004-07-21",
        "nepDate": "06.04.2061"
    },
    {
        "engDate": "2004-07-22",
        "nepDate": "07.04.2061"
    },
    {
        "engDate": "2004-07-23",
        "nepDate": "08.04.2061"
    },
    {
        "engDate": "2004-07-24",
        "nepDate": "09.04.2061"
    },
    {
        "engDate": "2004-07-25",
        "nepDate": "10.04.2061"
    },
    {
        "engDate": "2004-07-26",
        "nepDate": "11.04.2061"
    },
    {
        "engDate": "2004-07-27",
        "nepDate": "12.04.2061"
    },
    {
        "engDate": "2004-07-28",
        "nepDate": "13.04.2061"
    },
    {
        "engDate": "2004-07-29",
        "nepDate": "14.04.2061"
    },
    {
        "engDate": "2004-07-30",
        "nepDate": "15.04.2061"
    },
    {
        "engDate": "2004-07-31",
        "nepDate": "16.04.2061"
    },
    {
        "engDate": "2004-08-01",
        "nepDate": "17.04.2061"
    },
    {
        "engDate": "2004-08-02",
        "nepDate": "18.04.2061"
    },
    {
        "engDate": "2004-08-03",
        "nepDate": "19.04.2061"
    },
    {
        "engDate": "2004-08-04",
        "nepDate": "20.04.2061"
    },
    {
        "engDate": "2004-08-05",
        "nepDate": "21.04.2061"
    },
    {
        "engDate": "2004-08-06",
        "nepDate": "22.04.2061"
    },
    {
        "engDate": "2004-08-07",
        "nepDate": "23.04.2061"
    },
    {
        "engDate": "2004-08-08",
        "nepDate": "24.04.2061"
    },
    {
        "engDate": "2004-08-09",
        "nepDate": "25.04.2061"
    },
    {
        "engDate": "2004-08-10",
        "nepDate": "26.04.2061"
    },
    {
        "engDate": "2004-08-11",
        "nepDate": "27.04.2061"
    },
    {
        "engDate": "2004-08-12",
        "nepDate": "28.04.2061"
    },
    {
        "engDate": "2004-08-13",
        "nepDate": "29.04.2061"
    },
    {
        "engDate": "2004-08-14",
        "nepDate": "30.04.2061"
    },
    {
        "engDate": "2004-08-15",
        "nepDate": "31.04.2061"
    },
    {
        "engDate": "2004-08-16",
        "nepDate": "32.04.2061"
    },
    {
        "engDate": "2004-08-17",
        "nepDate": "01.05.2061"
    },
    {
        "engDate": "2004-08-18",
        "nepDate": "02.05.2061"
    },
    {
        "engDate": "2004-08-19",
        "nepDate": "03.05.2061"
    },
    {
        "engDate": "2004-08-20",
        "nepDate": "04.05.2061"
    },
    {
        "engDate": "2004-08-21",
        "nepDate": "05.05.2061"
    },
    {
        "engDate": "2004-08-22",
        "nepDate": "06.05.2061"
    },
    {
        "engDate": "2004-08-23",
        "nepDate": "07.05.2061"
    },
    {
        "engDate": "2004-08-24",
        "nepDate": "08.05.2061"
    },
    {
        "engDate": "2004-08-25",
        "nepDate": "09.05.2061"
    },
    {
        "engDate": "2004-08-26",
        "nepDate": "10.05.2061"
    },
    {
        "engDate": "2004-08-27",
        "nepDate": "11.05.2061"
    },
    {
        "engDate": "2004-08-28",
        "nepDate": "12.05.2061"
    },
    {
        "engDate": "2004-08-29",
        "nepDate": "13.05.2061"
    },
    {
        "engDate": "2004-08-30",
        "nepDate": "14.05.2061"
    },
    {
        "engDate": "2004-08-31",
        "nepDate": "15.05.2061"
    },
    {
        "engDate": "2004-09-01",
        "nepDate": "16.05.2061"
    },
    {
        "engDate": "2004-09-02",
        "nepDate": "17.05.2061"
    },
    {
        "engDate": "2004-09-03",
        "nepDate": "18.05.2061"
    },
    {
        "engDate": "2004-09-04",
        "nepDate": "19.05.2061"
    },
    {
        "engDate": "2004-09-05",
        "nepDate": "20.05.2061"
    },
    {
        "engDate": "2004-09-06",
        "nepDate": "21.05.2061"
    },
    {
        "engDate": "2004-09-07",
        "nepDate": "22.05.2061"
    },
    {
        "engDate": "2004-09-08",
        "nepDate": "23.05.2061"
    },
    {
        "engDate": "2004-09-09",
        "nepDate": "24.05.2061"
    },
    {
        "engDate": "2004-09-10",
        "nepDate": "25.05.2061"
    },
    {
        "engDate": "2004-09-11",
        "nepDate": "26.05.2061"
    },
    {
        "engDate": "2004-09-12",
        "nepDate": "27.05.2061"
    },
    {
        "engDate": "2004-09-13",
        "nepDate": "28.05.2061"
    },
    {
        "engDate": "2004-09-14",
        "nepDate": "29.05.2061"
    },
    {
        "engDate": "2004-09-15",
        "nepDate": "30.05.2061"
    },
    {
        "engDate": "2004-09-16",
        "nepDate": "31.05.2061"
    },
    {
        "engDate": "2004-09-17",
        "nepDate": "01.06.2061"
    },
    {
        "engDate": "2004-09-18",
        "nepDate": "02.06.2061"
    },
    {
        "engDate": "2004-09-19",
        "nepDate": "03.06.2061"
    },
    {
        "engDate": "2004-09-20",
        "nepDate": "04.06.2061"
    },
    {
        "engDate": "2004-09-21",
        "nepDate": "05.06.2061"
    },
    {
        "engDate": "2004-09-22",
        "nepDate": "06.06.2061"
    },
    {
        "engDate": "2004-09-23",
        "nepDate": "07.06.2061"
    },
    {
        "engDate": "2004-09-24",
        "nepDate": "08.06.2061"
    },
    {
        "engDate": "2004-09-25",
        "nepDate": "09.06.2061"
    },
    {
        "engDate": "2004-09-26",
        "nepDate": "10.06.2061"
    },
    {
        "engDate": "2004-09-27",
        "nepDate": "11.06.2061"
    },
    {
        "engDate": "2004-09-28",
        "nepDate": "12.06.2061"
    },
    {
        "engDate": "2004-09-29",
        "nepDate": "13.06.2061"
    },
    {
        "engDate": "2004-09-30",
        "nepDate": "14.06.2061"
    },
    {
        "engDate": "2004-10-01",
        "nepDate": "15.06.2061"
    },
    {
        "engDate": "2004-10-02",
        "nepDate": "16.06.2061"
    },
    {
        "engDate": "2004-10-03",
        "nepDate": "17.06.2061"
    },
    {
        "engDate": "2004-10-04",
        "nepDate": "18.06.2061"
    },
    {
        "engDate": "2004-10-05",
        "nepDate": "19.06.2061"
    },
    {
        "engDate": "2004-10-06",
        "nepDate": "20.06.2061"
    },
    {
        "engDate": "2004-10-07",
        "nepDate": "21.06.2061"
    },
    {
        "engDate": "2004-10-08",
        "nepDate": "22.06.2061"
    },
    {
        "engDate": "2004-10-09",
        "nepDate": "23.06.2061"
    },
    {
        "engDate": "2004-10-10",
        "nepDate": "24.06.2061"
    },
    {
        "engDate": "2004-10-11",
        "nepDate": "25.06.2061"
    },
    {
        "engDate": "2004-10-12",
        "nepDate": "26.06.2061"
    },
    {
        "engDate": "2004-10-13",
        "nepDate": "27.06.2061"
    },
    {
        "engDate": "2004-10-14",
        "nepDate": "28.06.2061"
    },
    {
        "engDate": "2004-10-15",
        "nepDate": "29.06.2061"
    },
    {
        "engDate": "2004-10-16",
        "nepDate": "30.06.2061"
    },
    {
        "engDate": "2004-10-17",
        "nepDate": "01.07.2061"
    },
    {
        "engDate": "2004-10-18",
        "nepDate": "02.07.2061"
    },
    {
        "engDate": "2004-10-19",
        "nepDate": "03.07.2061"
    },
    {
        "engDate": "2004-10-20",
        "nepDate": "04.07.2061"
    },
    {
        "engDate": "2004-10-21",
        "nepDate": "05.07.2061"
    },
    {
        "engDate": "2004-10-22",
        "nepDate": "06.07.2061"
    },
    {
        "engDate": "2004-10-23",
        "nepDate": "07.07.2061"
    },
    {
        "engDate": "2004-10-24",
        "nepDate": "08.07.2061"
    },
    {
        "engDate": "2004-10-25",
        "nepDate": "09.07.2061"
    },
    {
        "engDate": "2004-10-26",
        "nepDate": "10.07.2061"
    },
    {
        "engDate": "2004-10-27",
        "nepDate": "11.07.2061"
    },
    {
        "engDate": "2004-10-28",
        "nepDate": "12.07.2061"
    },
    {
        "engDate": "2004-10-29",
        "nepDate": "13.07.2061"
    },
    {
        "engDate": "2004-10-30",
        "nepDate": "14.07.2061"
    },
    {
        "engDate": "2004-10-31",
        "nepDate": "15.07.2061"
    },
    {
        "engDate": "2004-11-01",
        "nepDate": "16.07.2061"
    },
    {
        "engDate": "2004-11-02",
        "nepDate": "17.07.2061"
    },
    {
        "engDate": "2004-11-03",
        "nepDate": "18.07.2061"
    },
    {
        "engDate": "2004-11-04",
        "nepDate": "19.07.2061"
    },
    {
        "engDate": "2004-11-05",
        "nepDate": "20.07.2061"
    },
    {
        "engDate": "2004-11-06",
        "nepDate": "21.07.2061"
    },
    {
        "engDate": "2004-11-07",
        "nepDate": "22.07.2061"
    },
    {
        "engDate": "2004-11-08",
        "nepDate": "23.07.2061"
    },
    {
        "engDate": "2004-11-09",
        "nepDate": "24.07.2061"
    },
    {
        "engDate": "2004-11-10",
        "nepDate": "25.07.2061"
    },
    {
        "engDate": "2004-11-11",
        "nepDate": "26.07.2061"
    },
    {
        "engDate": "2004-11-12",
        "nepDate": "27.07.2061"
    },
    {
        "engDate": "2004-11-13",
        "nepDate": "28.07.2061"
    },
    {
        "engDate": "2004-11-14",
        "nepDate": "29.07.2061"
    },
    {
        "engDate": "2004-11-15",
        "nepDate": "30.07.2061"
    },
    {
        "engDate": "2004-11-16",
        "nepDate": "01.08.2061"
    },
    {
        "engDate": "2004-11-17",
        "nepDate": "02.08.2061"
    },
    {
        "engDate": "2004-11-18",
        "nepDate": "03.08.2061"
    },
    {
        "engDate": "2004-11-19",
        "nepDate": "04.08.2061"
    },
    {
        "engDate": "2004-11-20",
        "nepDate": "05.08.2061"
    },
    {
        "engDate": "2004-11-21",
        "nepDate": "06.08.2061"
    },
    {
        "engDate": "2004-11-22",
        "nepDate": "07.08.2061"
    },
    {
        "engDate": "2004-11-23",
        "nepDate": "08.08.2061"
    },
    {
        "engDate": "2004-11-24",
        "nepDate": "09.08.2061"
    },
    {
        "engDate": "2004-11-25",
        "nepDate": "10.08.2061"
    },
    {
        "engDate": "2004-11-26",
        "nepDate": "11.08.2061"
    },
    {
        "engDate": "2004-11-27",
        "nepDate": "12.08.2061"
    },
    {
        "engDate": "2004-11-28",
        "nepDate": "13.08.2061"
    },
    {
        "engDate": "2004-11-29",
        "nepDate": "14.08.2061"
    },
    {
        "engDate": "2004-11-30",
        "nepDate": "15.08.2061"
    },
    {
        "engDate": "2004-12-01",
        "nepDate": "16.08.2061"
    },
    {
        "engDate": "2004-12-02",
        "nepDate": "17.08.2061"
    },
    {
        "engDate": "2004-12-03",
        "nepDate": "18.08.2061"
    },
    {
        "engDate": "2004-12-04",
        "nepDate": "19.08.2061"
    },
    {
        "engDate": "2004-12-05",
        "nepDate": "20.08.2061"
    },
    {
        "engDate": "2004-12-06",
        "nepDate": "21.08.2061"
    },
    {
        "engDate": "2004-12-07",
        "nepDate": "22.08.2061"
    },
    {
        "engDate": "2004-12-08",
        "nepDate": "23.08.2061"
    },
    {
        "engDate": "2004-12-09",
        "nepDate": "24.08.2061"
    },
    {
        "engDate": "2004-12-10",
        "nepDate": "25.08.2061"
    },
    {
        "engDate": "2004-12-11",
        "nepDate": "26.08.2061"
    },
    {
        "engDate": "2004-12-12",
        "nepDate": "27.08.2061"
    },
    {
        "engDate": "2004-12-13",
        "nepDate": "28.08.2061"
    },
    {
        "engDate": "2004-12-14",
        "nepDate": "29.08.2061"
    },
    {
        "engDate": "2004-12-15",
        "nepDate": "30.08.2061"
    },
    {
        "engDate": "2004-12-16",
        "nepDate": "01.09.2061"
    },
    {
        "engDate": "2004-12-17",
        "nepDate": "02.09.2061"
    },
    {
        "engDate": "2004-12-18",
        "nepDate": "03.09.2061"
    },
    {
        "engDate": "2004-12-19",
        "nepDate": "04.09.2061"
    },
    {
        "engDate": "2004-12-20",
        "nepDate": "05.09.2061"
    },
    {
        "engDate": "2004-12-21",
        "nepDate": "06.09.2061"
    },
    {
        "engDate": "2004-12-22",
        "nepDate": "07.09.2061"
    },
    {
        "engDate": "2004-12-23",
        "nepDate": "08.09.2061"
    },
    {
        "engDate": "2004-12-24",
        "nepDate": "09.09.2061"
    },
    {
        "engDate": "2004-12-25",
        "nepDate": "10.09.2061"
    },
    {
        "engDate": "2004-12-26",
        "nepDate": "11.09.2061"
    },
    {
        "engDate": "2004-12-27",
        "nepDate": "12.09.2061"
    },
    {
        "engDate": "2004-12-28",
        "nepDate": "13.09.2061"
    },
    {
        "engDate": "2004-12-29",
        "nepDate": "14.09.2061"
    },
    {
        "engDate": "2004-12-30",
        "nepDate": "15.09.2061"
    },
    {
        "engDate": "2004-12-31",
        "nepDate": "16.09.2061"
    },
    {
        "engDate": "2005-01-01",
        "nepDate": "17.09.2061"
    },
    {
        "engDate": "2005-01-02",
        "nepDate": "18.09.2061"
    },
    {
        "engDate": "2005-01-03",
        "nepDate": "19.09.2061"
    },
    {
        "engDate": "2005-01-04",
        "nepDate": "20.09.2061"
    },
    {
        "engDate": "2005-01-05",
        "nepDate": "21.09.2061"
    },
    {
        "engDate": "2005-01-06",
        "nepDate": "22.09.2061"
    },
    {
        "engDate": "2005-01-07",
        "nepDate": "23.09.2061"
    },
    {
        "engDate": "2005-01-08",
        "nepDate": "24.09.2061"
    },
    {
        "engDate": "2005-01-09",
        "nepDate": "25.09.2061"
    },
    {
        "engDate": "2005-01-10",
        "nepDate": "26.09.2061"
    },
    {
        "engDate": "2005-01-11",
        "nepDate": "27.09.2061"
    },
    {
        "engDate": "2005-01-12",
        "nepDate": "28.09.2061"
    },
    {
        "engDate": "2005-01-13",
        "nepDate": "29.09.2061"
    },
    {
        "engDate": "2005-01-14",
        "nepDate": "01.10.2061"
    },
    {
        "engDate": "2005-01-15",
        "nepDate": "02.10.2061"
    },
    {
        "engDate": "2005-01-16",
        "nepDate": "03.10.2061"
    },
    {
        "engDate": "2005-01-17",
        "nepDate": "04.10.2061"
    },
    {
        "engDate": "2005-01-18",
        "nepDate": "05.10.2061"
    },
    {
        "engDate": "2005-01-19",
        "nepDate": "06.10.2061"
    },
    {
        "engDate": "2005-01-20",
        "nepDate": "07.10.2061"
    },
    {
        "engDate": "2005-01-21",
        "nepDate": "08.10.2061"
    },
    {
        "engDate": "2005-01-22",
        "nepDate": "09.10.2061"
    },
    {
        "engDate": "2005-01-23",
        "nepDate": "10.10.2061"
    },
    {
        "engDate": "2005-01-24",
        "nepDate": "11.10.2061"
    },
    {
        "engDate": "2005-01-25",
        "nepDate": "12.10.2061"
    },
    {
        "engDate": "2005-01-26",
        "nepDate": "13.10.2061"
    },
    {
        "engDate": "2005-01-27",
        "nepDate": "14.10.2061"
    },
    {
        "engDate": "2005-01-28",
        "nepDate": "15.10.2061"
    },
    {
        "engDate": "2005-01-29",
        "nepDate": "16.10.2061"
    },
    {
        "engDate": "2005-01-30",
        "nepDate": "17.10.2061"
    },
    {
        "engDate": "2005-01-31",
        "nepDate": "18.10.2061"
    },
    {
        "engDate": "2005-02-01",
        "nepDate": "19.10.2061"
    },
    {
        "engDate": "2005-02-02",
        "nepDate": "20.10.2061"
    },
    {
        "engDate": "2005-02-03",
        "nepDate": "21.10.2061"
    },
    {
        "engDate": "2005-02-04",
        "nepDate": "22.10.2061"
    },
    {
        "engDate": "2005-02-05",
        "nepDate": "23.10.2061"
    },
    {
        "engDate": "2005-02-06",
        "nepDate": "24.10.2061"
    },
    {
        "engDate": "2005-02-07",
        "nepDate": "25.10.2061"
    },
    {
        "engDate": "2005-02-08",
        "nepDate": "26.10.2061"
    },
    {
        "engDate": "2005-02-09",
        "nepDate": "27.10.2061"
    },
    {
        "engDate": "2005-02-10",
        "nepDate": "28.10.2061"
    },
    {
        "engDate": "2005-02-11",
        "nepDate": "29.10.2061"
    },
    {
        "engDate": "2005-02-12",
        "nepDate": "01.11.2061"
    },
    {
        "engDate": "2005-02-13",
        "nepDate": "02.11.2061"
    },
    {
        "engDate": "2005-02-14",
        "nepDate": "03.11.2061"
    },
    {
        "engDate": "2005-02-15",
        "nepDate": "04.11.2061"
    },
    {
        "engDate": "2005-02-16",
        "nepDate": "05.11.2061"
    },
    {
        "engDate": "2005-02-17",
        "nepDate": "06.11.2061"
    },
    {
        "engDate": "2005-02-18",
        "nepDate": "07.11.2061"
    },
    {
        "engDate": "2005-02-19",
        "nepDate": "08.11.2061"
    },
    {
        "engDate": "2005-02-20",
        "nepDate": "09.11.2061"
    },
    {
        "engDate": "2005-02-21",
        "nepDate": "10.11.2061"
    },
    {
        "engDate": "2005-02-22",
        "nepDate": "11.11.2061"
    },
    {
        "engDate": "2005-02-23",
        "nepDate": "12.11.2061"
    },
    {
        "engDate": "2005-02-24",
        "nepDate": "13.11.2061"
    },
    {
        "engDate": "2005-02-25",
        "nepDate": "14.11.2061"
    },
    {
        "engDate": "2005-02-26",
        "nepDate": "15.11.2061"
    },
    {
        "engDate": "2005-02-27",
        "nepDate": "16.11.2061"
    },
    {
        "engDate": "2005-02-28",
        "nepDate": "17.11.2061"
    },
    {
        "engDate": "2005-03-01",
        "nepDate": "18.11.2061"
    },
    {
        "engDate": "2005-03-02",
        "nepDate": "19.11.2061"
    },
    {
        "engDate": "2005-03-03",
        "nepDate": "20.11.2061"
    },
    {
        "engDate": "2005-03-04",
        "nepDate": "21.11.2061"
    },
    {
        "engDate": "2005-03-05",
        "nepDate": "22.11.2061"
    },
    {
        "engDate": "2005-03-06",
        "nepDate": "23.11.2061"
    },
    {
        "engDate": "2005-03-07",
        "nepDate": "24.11.2061"
    },
    {
        "engDate": "2005-03-08",
        "nepDate": "25.11.2061"
    },
    {
        "engDate": "2005-03-09",
        "nepDate": "26.11.2061"
    },
    {
        "engDate": "2005-03-10",
        "nepDate": "27.11.2061"
    },
    {
        "engDate": "2005-03-11",
        "nepDate": "28.11.2061"
    },
    {
        "engDate": "2005-03-12",
        "nepDate": "29.11.2061"
    },
    {
        "engDate": "2005-03-13",
        "nepDate": "30.11.2061"
    },
    {
        "engDate": "2005-03-14",
        "nepDate": "01.12.2061"
    },
    {
        "engDate": "2005-03-15",
        "nepDate": "02.12.2061"
    },
    {
        "engDate": "2005-03-16",
        "nepDate": "03.12.2061"
    },
    {
        "engDate": "2005-03-17",
        "nepDate": "04.12.2061"
    },
    {
        "engDate": "2005-03-18",
        "nepDate": "05.12.2061"
    },
    {
        "engDate": "2005-03-19",
        "nepDate": "06.12.2061"
    },
    {
        "engDate": "2005-03-20",
        "nepDate": "07.12.2061"
    },
    {
        "engDate": "2005-03-21",
        "nepDate": "08.12.2061"
    },
    {
        "engDate": "2005-03-22",
        "nepDate": "09.12.2061"
    },
    {
        "engDate": "2005-03-23",
        "nepDate": "10.12.2061"
    },
    {
        "engDate": "2005-03-24",
        "nepDate": "11.12.2061"
    },
    {
        "engDate": "2005-03-25",
        "nepDate": "12.12.2061"
    },
    {
        "engDate": "2005-03-26",
        "nepDate": "13.12.2061"
    },
    {
        "engDate": "2005-03-27",
        "nepDate": "14.12.2061"
    },
    {
        "engDate": "2005-03-28",
        "nepDate": "15.12.2061"
    },
    {
        "engDate": "2005-03-29",
        "nepDate": "16.12.2061"
    },
    {
        "engDate": "2005-03-30",
        "nepDate": "17.12.2061"
    },
    {
        "engDate": "2005-03-31",
        "nepDate": "18.12.2061"
    },
    {
        "engDate": "2005-04-01",
        "nepDate": "19.12.2061"
    },
    {
        "engDate": "2005-04-02",
        "nepDate": "20.12.2061"
    },
    {
        "engDate": "2005-04-03",
        "nepDate": "21.12.2061"
    },
    {
        "engDate": "2005-04-04",
        "nepDate": "22.12.2061"
    },
    {
        "engDate": "2005-04-05",
        "nepDate": "23.12.2061"
    },
    {
        "engDate": "2005-04-06",
        "nepDate": "24.12.2061"
    },
    {
        "engDate": "2005-04-07",
        "nepDate": "25.12.2061"
    },
    {
        "engDate": "2005-04-08",
        "nepDate": "26.12.2061"
    },
    {
        "engDate": "2005-04-09",
        "nepDate": "27.12.2061"
    },
    {
        "engDate": "2005-04-10",
        "nepDate": "28.12.2061"
    },
    {
        "engDate": "2005-04-11",
        "nepDate": "29.12.2061"
    },
    {
        "engDate": "2005-04-12",
        "nepDate": "30.12.2061"
    },
    {
        "engDate": "2005-04-13",
        "nepDate": "31.12.2061"
    },
    {
        "engDate": "2005-04-14",
        "nepDate": "01.01.2062"
    },
    {
        "engDate": "2005-04-15",
        "nepDate": "02.01.2062"
    },
    {
        "engDate": "2005-04-16",
        "nepDate": "03.01.2062"
    },
    {
        "engDate": "2005-04-17",
        "nepDate": "04.01.2062"
    },
    {
        "engDate": "2005-04-18",
        "nepDate": "05.01.2062"
    },
    {
        "engDate": "2005-04-19",
        "nepDate": "06.01.2062"
    },
    {
        "engDate": "2005-04-20",
        "nepDate": "07.01.2062"
    },
    {
        "engDate": "2005-04-21",
        "nepDate": "08.01.2062"
    },
    {
        "engDate": "2005-04-22",
        "nepDate": "09.01.2062"
    },
    {
        "engDate": "2005-04-23",
        "nepDate": "10.01.2062"
    },
    {
        "engDate": "2005-04-24",
        "nepDate": "11.01.2062"
    },
    {
        "engDate": "2005-04-25",
        "nepDate": "12.01.2062"
    },
    {
        "engDate": "2005-04-26",
        "nepDate": "13.01.2062"
    },
    {
        "engDate": "2005-04-27",
        "nepDate": "14.01.2062"
    },
    {
        "engDate": "2005-04-28",
        "nepDate": "15.01.2062"
    },
    {
        "engDate": "2005-04-29",
        "nepDate": "16.01.2062"
    },
    {
        "engDate": "2005-04-30",
        "nepDate": "17.01.2062"
    },
    {
        "engDate": "2005-05-01",
        "nepDate": "18.01.2062"
    },
    {
        "engDate": "2005-05-02",
        "nepDate": "19.01.2062"
    },
    {
        "engDate": "2005-05-03",
        "nepDate": "20.01.2062"
    },
    {
        "engDate": "2005-05-04",
        "nepDate": "21.01.2062"
    },
    {
        "engDate": "2005-05-05",
        "nepDate": "22.01.2062"
    },
    {
        "engDate": "2005-05-06",
        "nepDate": "23.01.2062"
    },
    {
        "engDate": "2005-05-07",
        "nepDate": "24.01.2062"
    },
    {
        "engDate": "2005-05-08",
        "nepDate": "25.01.2062"
    },
    {
        "engDate": "2005-05-09",
        "nepDate": "26.01.2062"
    },
    {
        "engDate": "2005-05-10",
        "nepDate": "27.01.2062"
    },
    {
        "engDate": "2005-05-11",
        "nepDate": "28.01.2062"
    },
    {
        "engDate": "2005-05-12",
        "nepDate": "29.01.2062"
    },
    {
        "engDate": "2005-05-13",
        "nepDate": "30.01.2062"
    },
    {
        "engDate": "2005-05-14",
        "nepDate": "01.02.2062"
    },
    {
        "engDate": "2005-05-15",
        "nepDate": "02.02.2062"
    },
    {
        "engDate": "2005-05-16",
        "nepDate": "03.02.2062"
    },
    {
        "engDate": "2005-05-17",
        "nepDate": "04.02.2062"
    },
    {
        "engDate": "2005-05-18",
        "nepDate": "05.02.2062"
    },
    {
        "engDate": "2005-05-19",
        "nepDate": "06.02.2062"
    },
    {
        "engDate": "2005-05-20",
        "nepDate": "07.02.2062"
    },
    {
        "engDate": "2005-05-21",
        "nepDate": "08.02.2062"
    },
    {
        "engDate": "2005-05-22",
        "nepDate": "09.02.2062"
    },
    {
        "engDate": "2005-05-23",
        "nepDate": "10.02.2062"
    },
    {
        "engDate": "2005-05-24",
        "nepDate": "11.02.2062"
    },
    {
        "engDate": "2005-05-25",
        "nepDate": "12.02.2062"
    },
    {
        "engDate": "2005-05-26",
        "nepDate": "13.02.2062"
    },
    {
        "engDate": "2005-05-27",
        "nepDate": "14.02.2062"
    },
    {
        "engDate": "2005-05-28",
        "nepDate": "15.02.2062"
    },
    {
        "engDate": "2005-05-29",
        "nepDate": "16.02.2062"
    },
    {
        "engDate": "2005-05-30",
        "nepDate": "17.02.2062"
    },
    {
        "engDate": "2005-05-31",
        "nepDate": "18.02.2062"
    },
    {
        "engDate": "2005-06-01",
        "nepDate": "19.02.2062"
    },
    {
        "engDate": "2005-06-02",
        "nepDate": "20.02.2062"
    },
    {
        "engDate": "2005-06-03",
        "nepDate": "21.02.2062"
    },
    {
        "engDate": "2005-06-04",
        "nepDate": "22.02.2062"
    },
    {
        "engDate": "2005-06-05",
        "nepDate": "23.02.2062"
    },
    {
        "engDate": "2005-06-06",
        "nepDate": "24.02.2062"
    },
    {
        "engDate": "2005-06-07",
        "nepDate": "25.02.2062"
    },
    {
        "engDate": "2005-06-08",
        "nepDate": "26.02.2062"
    },
    {
        "engDate": "2005-06-09",
        "nepDate": "27.02.2062"
    },
    {
        "engDate": "2005-06-10",
        "nepDate": "28.02.2062"
    },
    {
        "engDate": "2005-06-11",
        "nepDate": "29.02.2062"
    },
    {
        "engDate": "2005-06-12",
        "nepDate": "30.02.2062"
    },
    {
        "engDate": "2005-06-13",
        "nepDate": "31.02.2062"
    },
    {
        "engDate": "2005-06-14",
        "nepDate": "32.02.2062"
    },
    {
        "engDate": "2005-06-15",
        "nepDate": "01.03.2062"
    },
    {
        "engDate": "2005-06-16",
        "nepDate": "02.03.2062"
    },
    {
        "engDate": "2005-06-17",
        "nepDate": "03.03.2062"
    },
    {
        "engDate": "2005-06-18",
        "nepDate": "04.03.2062"
    },
    {
        "engDate": "2005-06-19",
        "nepDate": "05.03.2062"
    },
    {
        "engDate": "2005-06-20",
        "nepDate": "06.03.2062"
    },
    {
        "engDate": "2005-06-21",
        "nepDate": "07.03.2062"
    },
    {
        "engDate": "2005-06-22",
        "nepDate": "08.03.2062"
    },
    {
        "engDate": "2005-06-23",
        "nepDate": "09.03.2062"
    },
    {
        "engDate": "2005-06-24",
        "nepDate": "10.03.2062"
    },
    {
        "engDate": "2005-06-25",
        "nepDate": "11.03.2062"
    },
    {
        "engDate": "2005-06-26",
        "nepDate": "12.03.2062"
    },
    {
        "engDate": "2005-06-27",
        "nepDate": "13.03.2062"
    },
    {
        "engDate": "2005-06-28",
        "nepDate": "14.03.2062"
    },
    {
        "engDate": "2005-06-29",
        "nepDate": "15.03.2062"
    },
    {
        "engDate": "2005-06-30",
        "nepDate": "16.03.2062"
    },
    {
        "engDate": "2005-07-01",
        "nepDate": "17.03.2062"
    },
    {
        "engDate": "2005-07-02",
        "nepDate": "18.03.2062"
    },
    {
        "engDate": "2005-07-03",
        "nepDate": "19.03.2062"
    },
    {
        "engDate": "2005-07-04",
        "nepDate": "20.03.2062"
    },
    {
        "engDate": "2005-07-05",
        "nepDate": "21.03.2062"
    },
    {
        "engDate": "2005-07-06",
        "nepDate": "22.03.2062"
    },
    {
        "engDate": "2005-07-07",
        "nepDate": "23.03.2062"
    },
    {
        "engDate": "2005-07-08",
        "nepDate": "24.03.2062"
    },
    {
        "engDate": "2005-07-09",
        "nepDate": "25.03.2062"
    },
    {
        "engDate": "2005-07-10",
        "nepDate": "26.03.2062"
    },
    {
        "engDate": "2005-07-11",
        "nepDate": "27.03.2062"
    },
    {
        "engDate": "2005-07-12",
        "nepDate": "28.03.2062"
    },
    {
        "engDate": "2005-07-13",
        "nepDate": "29.03.2062"
    },
    {
        "engDate": "2005-07-14",
        "nepDate": "30.03.2062"
    },
    {
        "engDate": "2005-07-15",
        "nepDate": "31.03.2062"
    },
    {
        "engDate": "2005-07-16",
        "nepDate": "01.04.2062"
    },
    {
        "engDate": "2005-07-17",
        "nepDate": "02.04.2062"
    },
    {
        "engDate": "2005-07-18",
        "nepDate": "03.04.2062"
    },
    {
        "engDate": "2005-07-19",
        "nepDate": "04.04.2062"
    },
    {
        "engDate": "2005-07-20",
        "nepDate": "05.04.2062"
    },
    {
        "engDate": "2005-07-21",
        "nepDate": "06.04.2062"
    },
    {
        "engDate": "2005-07-22",
        "nepDate": "07.04.2062"
    },
    {
        "engDate": "2005-07-23",
        "nepDate": "08.04.2062"
    },
    {
        "engDate": "2005-07-24",
        "nepDate": "09.04.2062"
    },
    {
        "engDate": "2005-07-25",
        "nepDate": "10.04.2062"
    },
    {
        "engDate": "2005-07-26",
        "nepDate": "11.04.2062"
    },
    {
        "engDate": "2005-07-27",
        "nepDate": "12.04.2062"
    },
    {
        "engDate": "2005-07-28",
        "nepDate": "13.04.2062"
    },
    {
        "engDate": "2005-07-29",
        "nepDate": "14.04.2062"
    },
    {
        "engDate": "2005-07-30",
        "nepDate": "15.04.2062"
    },
    {
        "engDate": "2005-07-31",
        "nepDate": "16.04.2062"
    },
    {
        "engDate": "2005-08-01",
        "nepDate": "17.04.2062"
    },
    {
        "engDate": "2005-08-02",
        "nepDate": "18.04.2062"
    },
    {
        "engDate": "2005-08-03",
        "nepDate": "19.04.2062"
    },
    {
        "engDate": "2005-08-04",
        "nepDate": "20.04.2062"
    },
    {
        "engDate": "2005-08-05",
        "nepDate": "21.04.2062"
    },
    {
        "engDate": "2005-08-06",
        "nepDate": "22.04.2062"
    },
    {
        "engDate": "2005-08-07",
        "nepDate": "23.04.2062"
    },
    {
        "engDate": "2005-08-08",
        "nepDate": "24.04.2062"
    },
    {
        "engDate": "2005-08-09",
        "nepDate": "25.04.2062"
    },
    {
        "engDate": "2005-08-10",
        "nepDate": "26.04.2062"
    },
    {
        "engDate": "2005-08-11",
        "nepDate": "27.04.2062"
    },
    {
        "engDate": "2005-08-12",
        "nepDate": "28.04.2062"
    },
    {
        "engDate": "2005-08-13",
        "nepDate": "29.04.2062"
    },
    {
        "engDate": "2005-08-14",
        "nepDate": "30.04.2062"
    },
    {
        "engDate": "2005-08-15",
        "nepDate": "31.04.2062"
    },
    {
        "engDate": "2005-08-16",
        "nepDate": "32.04.2062"
    },
    {
        "engDate": "2005-08-17",
        "nepDate": "01.05.2062"
    },
    {
        "engDate": "2005-08-18",
        "nepDate": "02.05.2062"
    },
    {
        "engDate": "2005-08-19",
        "nepDate": "03.05.2062"
    },
    {
        "engDate": "2005-08-20",
        "nepDate": "04.05.2062"
    },
    {
        "engDate": "2005-08-21",
        "nepDate": "05.05.2062"
    },
    {
        "engDate": "2005-08-22",
        "nepDate": "06.05.2062"
    },
    {
        "engDate": "2005-08-23",
        "nepDate": "07.05.2062"
    },
    {
        "engDate": "2005-08-24",
        "nepDate": "08.05.2062"
    },
    {
        "engDate": "2005-08-25",
        "nepDate": "09.05.2062"
    },
    {
        "engDate": "2005-08-26",
        "nepDate": "10.05.2062"
    },
    {
        "engDate": "2005-08-27",
        "nepDate": "11.05.2062"
    },
    {
        "engDate": "2005-08-28",
        "nepDate": "12.05.2062"
    },
    {
        "engDate": "2005-08-29",
        "nepDate": "13.05.2062"
    },
    {
        "engDate": "2005-08-30",
        "nepDate": "14.05.2062"
    },
    {
        "engDate": "2005-08-31",
        "nepDate": "15.05.2062"
    },
    {
        "engDate": "2005-09-01",
        "nepDate": "16.05.2062"
    },
    {
        "engDate": "2005-09-02",
        "nepDate": "17.05.2062"
    },
    {
        "engDate": "2005-09-03",
        "nepDate": "18.05.2062"
    },
    {
        "engDate": "2005-09-04",
        "nepDate": "19.05.2062"
    },
    {
        "engDate": "2005-09-05",
        "nepDate": "20.05.2062"
    },
    {
        "engDate": "2005-09-06",
        "nepDate": "21.05.2062"
    },
    {
        "engDate": "2005-09-07",
        "nepDate": "22.05.2062"
    },
    {
        "engDate": "2005-09-08",
        "nepDate": "23.05.2062"
    },
    {
        "engDate": "2005-09-09",
        "nepDate": "24.05.2062"
    },
    {
        "engDate": "2005-09-10",
        "nepDate": "25.05.2062"
    },
    {
        "engDate": "2005-09-11",
        "nepDate": "26.05.2062"
    },
    {
        "engDate": "2005-09-12",
        "nepDate": "27.05.2062"
    },
    {
        "engDate": "2005-09-13",
        "nepDate": "28.05.2062"
    },
    {
        "engDate": "2005-09-14",
        "nepDate": "29.05.2062"
    },
    {
        "engDate": "2005-09-15",
        "nepDate": "30.05.2062"
    },
    {
        "engDate": "2005-09-16",
        "nepDate": "31.05.2062"
    },
    {
        "engDate": "2005-09-17",
        "nepDate": "01.06.2062"
    },
    {
        "engDate": "2005-09-18",
        "nepDate": "02.06.2062"
    },
    {
        "engDate": "2005-09-19",
        "nepDate": "03.06.2062"
    },
    {
        "engDate": "2005-09-20",
        "nepDate": "04.06.2062"
    },
    {
        "engDate": "2005-09-21",
        "nepDate": "05.06.2062"
    },
    {
        "engDate": "2005-09-22",
        "nepDate": "06.06.2062"
    },
    {
        "engDate": "2005-09-23",
        "nepDate": "07.06.2062"
    },
    {
        "engDate": "2005-09-24",
        "nepDate": "08.06.2062"
    },
    {
        "engDate": "2005-09-25",
        "nepDate": "09.06.2062"
    },
    {
        "engDate": "2005-09-26",
        "nepDate": "10.06.2062"
    },
    {
        "engDate": "2005-09-27",
        "nepDate": "11.06.2062"
    },
    {
        "engDate": "2005-09-28",
        "nepDate": "12.06.2062"
    },
    {
        "engDate": "2005-09-29",
        "nepDate": "13.06.2062"
    },
    {
        "engDate": "2005-09-30",
        "nepDate": "14.06.2062"
    },
    {
        "engDate": "2005-10-01",
        "nepDate": "15.06.2062"
    },
    {
        "engDate": "2005-10-02",
        "nepDate": "16.06.2062"
    },
    {
        "engDate": "2005-10-03",
        "nepDate": "17.06.2062"
    },
    {
        "engDate": "2005-10-04",
        "nepDate": "18.06.2062"
    },
    {
        "engDate": "2005-10-05",
        "nepDate": "19.06.2062"
    },
    {
        "engDate": "2005-10-06",
        "nepDate": "20.06.2062"
    },
    {
        "engDate": "2005-10-07",
        "nepDate": "21.06.2062"
    },
    {
        "engDate": "2005-10-08",
        "nepDate": "22.06.2062"
    },
    {
        "engDate": "2005-10-09",
        "nepDate": "23.06.2062"
    },
    {
        "engDate": "2005-10-10",
        "nepDate": "24.06.2062"
    },
    {
        "engDate": "2005-10-11",
        "nepDate": "25.06.2062"
    },
    {
        "engDate": "2005-10-12",
        "nepDate": "26.06.2062"
    },
    {
        "engDate": "2005-10-13",
        "nepDate": "27.06.2062"
    },
    {
        "engDate": "2005-10-14",
        "nepDate": "28.06.2062"
    },
    {
        "engDate": "2005-10-15",
        "nepDate": "29.06.2062"
    },
    {
        "engDate": "2005-10-16",
        "nepDate": "30.06.2062"
    },
    {
        "engDate": "2005-10-17",
        "nepDate": "31.06.2062"
    },
    {
        "engDate": "2005-10-18",
        "nepDate": "01.07.2062"
    },
    {
        "engDate": "2005-10-19",
        "nepDate": "02.07.2062"
    },
    {
        "engDate": "2005-10-20",
        "nepDate": "03.07.2062"
    },
    {
        "engDate": "2005-10-21",
        "nepDate": "04.07.2062"
    },
    {
        "engDate": "2005-10-22",
        "nepDate": "05.07.2062"
    },
    {
        "engDate": "2005-10-23",
        "nepDate": "06.07.2062"
    },
    {
        "engDate": "2005-10-24",
        "nepDate": "07.07.2062"
    },
    {
        "engDate": "2005-10-25",
        "nepDate": "08.07.2062"
    },
    {
        "engDate": "2005-10-26",
        "nepDate": "09.07.2062"
    },
    {
        "engDate": "2005-10-27",
        "nepDate": "10.07.2062"
    },
    {
        "engDate": "2005-10-28",
        "nepDate": "11.07.2062"
    },
    {
        "engDate": "2005-10-29",
        "nepDate": "12.07.2062"
    },
    {
        "engDate": "2005-10-30",
        "nepDate": "13.07.2062"
    },
    {
        "engDate": "2005-10-31",
        "nepDate": "14.07.2062"
    },
    {
        "engDate": "2005-11-01",
        "nepDate": "15.07.2062"
    },
    {
        "engDate": "2005-11-02",
        "nepDate": "16.07.2062"
    },
    {
        "engDate": "2005-11-03",
        "nepDate": "17.07.2062"
    },
    {
        "engDate": "2005-11-04",
        "nepDate": "18.07.2062"
    },
    {
        "engDate": "2005-11-05",
        "nepDate": "19.07.2062"
    },
    {
        "engDate": "2005-11-06",
        "nepDate": "20.07.2062"
    },
    {
        "engDate": "2005-11-07",
        "nepDate": "21.07.2062"
    },
    {
        "engDate": "2005-11-08",
        "nepDate": "22.07.2062"
    },
    {
        "engDate": "2005-11-09",
        "nepDate": "23.07.2062"
    },
    {
        "engDate": "2005-11-10",
        "nepDate": "24.07.2062"
    },
    {
        "engDate": "2005-11-11",
        "nepDate": "25.07.2062"
    },
    {
        "engDate": "2005-11-12",
        "nepDate": "26.07.2062"
    },
    {
        "engDate": "2005-11-13",
        "nepDate": "27.07.2062"
    },
    {
        "engDate": "2005-11-14",
        "nepDate": "28.07.2062"
    },
    {
        "engDate": "2005-11-15",
        "nepDate": "29.07.2062"
    },
    {
        "engDate": "2005-11-16",
        "nepDate": "01.08.2062"
    },
    {
        "engDate": "2005-11-17",
        "nepDate": "02.08.2062"
    },
    {
        "engDate": "2005-11-18",
        "nepDate": "03.08.2062"
    },
    {
        "engDate": "2005-11-19",
        "nepDate": "04.08.2062"
    },
    {
        "engDate": "2005-11-20",
        "nepDate": "05.08.2062"
    },
    {
        "engDate": "2005-11-21",
        "nepDate": "06.08.2062"
    },
    {
        "engDate": "2005-11-22",
        "nepDate": "07.08.2062"
    },
    {
        "engDate": "2005-11-23",
        "nepDate": "08.08.2062"
    },
    {
        "engDate": "2005-11-24",
        "nepDate": "09.08.2062"
    },
    {
        "engDate": "2005-11-25",
        "nepDate": "10.08.2062"
    },
    {
        "engDate": "2005-11-26",
        "nepDate": "11.08.2062"
    },
    {
        "engDate": "2005-11-27",
        "nepDate": "12.08.2062"
    },
    {
        "engDate": "2005-11-28",
        "nepDate": "13.08.2062"
    },
    {
        "engDate": "2005-11-29",
        "nepDate": "14.08.2062"
    },
    {
        "engDate": "2005-11-30",
        "nepDate": "15.08.2062"
    },
    {
        "engDate": "2005-12-01",
        "nepDate": "16.08.2062"
    },
    {
        "engDate": "2005-12-02",
        "nepDate": "17.08.2062"
    },
    {
        "engDate": "2005-12-03",
        "nepDate": "18.08.2062"
    },
    {
        "engDate": "2005-12-04",
        "nepDate": "19.08.2062"
    },
    {
        "engDate": "2005-12-05",
        "nepDate": "20.08.2062"
    },
    {
        "engDate": "2005-12-06",
        "nepDate": "21.08.2062"
    },
    {
        "engDate": "2005-12-07",
        "nepDate": "22.08.2062"
    },
    {
        "engDate": "2005-12-08",
        "nepDate": "23.08.2062"
    },
    {
        "engDate": "2005-12-09",
        "nepDate": "24.08.2062"
    },
    {
        "engDate": "2005-12-10",
        "nepDate": "25.08.2062"
    },
    {
        "engDate": "2005-12-11",
        "nepDate": "26.08.2062"
    },
    {
        "engDate": "2005-12-12",
        "nepDate": "27.08.2062"
    },
    {
        "engDate": "2005-12-13",
        "nepDate": "28.08.2062"
    },
    {
        "engDate": "2005-12-14",
        "nepDate": "29.08.2062"
    },
    {
        "engDate": "2005-12-15",
        "nepDate": "30.08.2062"
    },
    {
        "engDate": "2005-12-16",
        "nepDate": "01.09.2062"
    },
    {
        "engDate": "2005-12-17",
        "nepDate": "02.09.2062"
    },
    {
        "engDate": "2005-12-18",
        "nepDate": "03.09.2062"
    },
    {
        "engDate": "2005-12-19",
        "nepDate": "04.09.2062"
    },
    {
        "engDate": "2005-12-20",
        "nepDate": "05.09.2062"
    },
    {
        "engDate": "2005-12-21",
        "nepDate": "06.09.2062"
    },
    {
        "engDate": "2005-12-22",
        "nepDate": "07.09.2062"
    },
    {
        "engDate": "2005-12-23",
        "nepDate": "08.09.2062"
    },
    {
        "engDate": "2005-12-24",
        "nepDate": "09.09.2062"
    },
    {
        "engDate": "2005-12-25",
        "nepDate": "10.09.2062"
    },
    {
        "engDate": "2005-12-26",
        "nepDate": "11.09.2062"
    },
    {
        "engDate": "2005-12-27",
        "nepDate": "12.09.2062"
    },
    {
        "engDate": "2005-12-28",
        "nepDate": "13.09.2062"
    },
    {
        "engDate": "2005-12-29",
        "nepDate": "14.09.2062"
    },
    {
        "engDate": "2005-12-30",
        "nepDate": "15.09.2062"
    },
    {
        "engDate": "2005-12-31",
        "nepDate": "16.09.2062"
    },
    {
        "engDate": "2006-01-01",
        "nepDate": "17.09.2062"
    },
    {
        "engDate": "2006-01-02",
        "nepDate": "18.09.2062"
    },
    {
        "engDate": "2006-01-03",
        "nepDate": "19.09.2062"
    },
    {
        "engDate": "2006-01-04",
        "nepDate": "20.09.2062"
    },
    {
        "engDate": "2006-01-05",
        "nepDate": "21.09.2062"
    },
    {
        "engDate": "2006-01-06",
        "nepDate": "22.09.2062"
    },
    {
        "engDate": "2006-01-07",
        "nepDate": "23.09.2062"
    },
    {
        "engDate": "2006-01-08",
        "nepDate": "24.09.2062"
    },
    {
        "engDate": "2006-01-09",
        "nepDate": "25.09.2062"
    },
    {
        "engDate": "2006-01-10",
        "nepDate": "26.09.2062"
    },
    {
        "engDate": "2006-01-11",
        "nepDate": "27.09.2062"
    },
    {
        "engDate": "2006-01-12",
        "nepDate": "28.09.2062"
    },
    {
        "engDate": "2006-01-13",
        "nepDate": "29.09.2062"
    },
    {
        "engDate": "2006-01-14",
        "nepDate": "01.10.2062"
    },
    {
        "engDate": "2006-01-15",
        "nepDate": "02.10.2062"
    },
    {
        "engDate": "2006-01-16",
        "nepDate": "03.10.2062"
    },
    {
        "engDate": "2006-01-17",
        "nepDate": "04.10.2062"
    },
    {
        "engDate": "2006-01-18",
        "nepDate": "05.10.2062"
    },
    {
        "engDate": "2006-01-19",
        "nepDate": "06.10.2062"
    },
    {
        "engDate": "2006-01-20",
        "nepDate": "07.10.2062"
    },
    {
        "engDate": "2006-01-21",
        "nepDate": "08.10.2062"
    },
    {
        "engDate": "2006-01-22",
        "nepDate": "09.10.2062"
    },
    {
        "engDate": "2006-01-23",
        "nepDate": "10.10.2062"
    },
    {
        "engDate": "2006-01-24",
        "nepDate": "11.10.2062"
    },
    {
        "engDate": "2006-01-25",
        "nepDate": "12.10.2062"
    },
    {
        "engDate": "2006-01-26",
        "nepDate": "13.10.2062"
    },
    {
        "engDate": "2006-01-27",
        "nepDate": "14.10.2062"
    },
    {
        "engDate": "2006-01-28",
        "nepDate": "15.10.2062"
    },
    {
        "engDate": "2006-01-29",
        "nepDate": "16.10.2062"
    },
    {
        "engDate": "2006-01-30",
        "nepDate": "17.10.2062"
    },
    {
        "engDate": "2006-01-31",
        "nepDate": "18.10.2062"
    },
    {
        "engDate": "2006-02-01",
        "nepDate": "19.10.2062"
    },
    {
        "engDate": "2006-02-02",
        "nepDate": "20.10.2062"
    },
    {
        "engDate": "2006-02-03",
        "nepDate": "21.10.2062"
    },
    {
        "engDate": "2006-02-04",
        "nepDate": "22.10.2062"
    },
    {
        "engDate": "2006-02-05",
        "nepDate": "23.10.2062"
    },
    {
        "engDate": "2006-02-06",
        "nepDate": "24.10.2062"
    },
    {
        "engDate": "2006-02-07",
        "nepDate": "25.10.2062"
    },
    {
        "engDate": "2006-02-08",
        "nepDate": "26.10.2062"
    },
    {
        "engDate": "2006-02-09",
        "nepDate": "27.10.2062"
    },
    {
        "engDate": "2006-02-10",
        "nepDate": "28.10.2062"
    },
    {
        "engDate": "2006-02-11",
        "nepDate": "29.10.2062"
    },
    {
        "engDate": "2006-02-12",
        "nepDate": "30.10.2062"
    },
    {
        "engDate": "2006-02-13",
        "nepDate": "01.11.2062"
    },
    {
        "engDate": "2006-02-14",
        "nepDate": "02.11.2062"
    },
    {
        "engDate": "2006-02-15",
        "nepDate": "03.11.2062"
    },
    {
        "engDate": "2006-02-16",
        "nepDate": "04.11.2062"
    },
    {
        "engDate": "2006-02-17",
        "nepDate": "05.11.2062"
    },
    {
        "engDate": "2006-02-18",
        "nepDate": "06.11.2062"
    },
    {
        "engDate": "2006-02-19",
        "nepDate": "07.11.2062"
    },
    {
        "engDate": "2006-02-20",
        "nepDate": "08.11.2062"
    },
    {
        "engDate": "2006-02-21",
        "nepDate": "09.11.2062"
    },
    {
        "engDate": "2006-02-22",
        "nepDate": "10.11.2062"
    },
    {
        "engDate": "2006-02-23",
        "nepDate": "11.11.2062"
    },
    {
        "engDate": "2006-02-24",
        "nepDate": "12.11.2062"
    },
    {
        "engDate": "2006-02-25",
        "nepDate": "13.11.2062"
    },
    {
        "engDate": "2006-02-26",
        "nepDate": "14.11.2062"
    },
    {
        "engDate": "2006-02-27",
        "nepDate": "15.11.2062"
    },
    {
        "engDate": "2006-02-28",
        "nepDate": "16.11.2062"
    },
    {
        "engDate": "2006-03-01",
        "nepDate": "17.11.2062"
    },
    {
        "engDate": "2006-03-02",
        "nepDate": "18.11.2062"
    },
    {
        "engDate": "2006-03-03",
        "nepDate": "19.11.2062"
    },
    {
        "engDate": "2006-03-04",
        "nepDate": "20.11.2062"
    },
    {
        "engDate": "2006-03-05",
        "nepDate": "21.11.2062"
    },
    {
        "engDate": "2006-03-06",
        "nepDate": "22.11.2062"
    },
    {
        "engDate": "2006-03-07",
        "nepDate": "23.11.2062"
    },
    {
        "engDate": "2006-03-08",
        "nepDate": "24.11.2062"
    },
    {
        "engDate": "2006-03-09",
        "nepDate": "25.11.2062"
    },
    {
        "engDate": "2006-03-10",
        "nepDate": "26.11.2062"
    },
    {
        "engDate": "2006-03-11",
        "nepDate": "27.11.2062"
    },
    {
        "engDate": "2006-03-12",
        "nepDate": "28.11.2062"
    },
    {
        "engDate": "2006-03-13",
        "nepDate": "29.11.2062"
    },
    {
        "engDate": "2006-03-14",
        "nepDate": "01.12.2062"
    },
    {
        "engDate": "2006-03-15",
        "nepDate": "02.12.2062"
    },
    {
        "engDate": "2006-03-16",
        "nepDate": "03.12.2062"
    },
    {
        "engDate": "2006-03-17",
        "nepDate": "04.12.2062"
    },
    {
        "engDate": "2006-03-18",
        "nepDate": "05.12.2062"
    },
    {
        "engDate": "2006-03-19",
        "nepDate": "06.12.2062"
    },
    {
        "engDate": "2006-03-20",
        "nepDate": "07.12.2062"
    },
    {
        "engDate": "2006-03-21",
        "nepDate": "08.12.2062"
    },
    {
        "engDate": "2006-03-22",
        "nepDate": "09.12.2062"
    },
    {
        "engDate": "2006-03-23",
        "nepDate": "10.12.2062"
    },
    {
        "engDate": "2006-03-24",
        "nepDate": "11.12.2062"
    },
    {
        "engDate": "2006-03-25",
        "nepDate": "12.12.2062"
    },
    {
        "engDate": "2006-03-26",
        "nepDate": "13.12.2062"
    },
    {
        "engDate": "2006-03-27",
        "nepDate": "14.12.2062"
    },
    {
        "engDate": "2006-03-28",
        "nepDate": "15.12.2062"
    },
    {
        "engDate": "2006-03-29",
        "nepDate": "16.12.2062"
    },
    {
        "engDate": "2006-03-30",
        "nepDate": "17.12.2062"
    },
    {
        "engDate": "2006-03-31",
        "nepDate": "18.12.2062"
    },
    {
        "engDate": "2006-04-01",
        "nepDate": "19.12.2062"
    },
    {
        "engDate": "2006-04-02",
        "nepDate": "20.12.2062"
    },
    {
        "engDate": "2006-04-03",
        "nepDate": "21.12.2062"
    },
    {
        "engDate": "2006-04-04",
        "nepDate": "22.12.2062"
    },
    {
        "engDate": "2006-04-05",
        "nepDate": "23.12.2062"
    },
    {
        "engDate": "2006-04-06",
        "nepDate": "24.12.2062"
    },
    {
        "engDate": "2006-04-07",
        "nepDate": "25.12.2062"
    },
    {
        "engDate": "2006-04-08",
        "nepDate": "26.12.2062"
    },
    {
        "engDate": "2006-04-09",
        "nepDate": "27.12.2062"
    },
    {
        "engDate": "2006-04-10",
        "nepDate": "28.12.2062"
    },
    {
        "engDate": "2006-04-11",
        "nepDate": "29.12.2062"
    },
    {
        "engDate": "2006-04-12",
        "nepDate": "30.12.2062"
    },
    {
        "engDate": "2006-04-13",
        "nepDate": "31.12.2062"
    },
    {
        "engDate": "2006-04-14",
        "nepDate": "01.01.2063"
    },
    {
        "engDate": "2006-04-15",
        "nepDate": "02.01.2063"
    },
    {
        "engDate": "2006-04-16",
        "nepDate": "03.01.2063"
    },
    {
        "engDate": "2006-04-17",
        "nepDate": "04.01.2063"
    },
    {
        "engDate": "2006-04-18",
        "nepDate": "05.01.2063"
    },
    {
        "engDate": "2006-04-19",
        "nepDate": "06.01.2063"
    },
    {
        "engDate": "2006-04-20",
        "nepDate": "07.01.2063"
    },
    {
        "engDate": "2006-04-21",
        "nepDate": "08.01.2063"
    },
    {
        "engDate": "2006-04-22",
        "nepDate": "09.01.2063"
    },
    {
        "engDate": "2006-04-23",
        "nepDate": "10.01.2063"
    },
    {
        "engDate": "2006-04-24",
        "nepDate": "11.01.2063"
    },
    {
        "engDate": "2006-04-25",
        "nepDate": "12.01.2063"
    },
    {
        "engDate": "2006-04-26",
        "nepDate": "13.01.2063"
    },
    {
        "engDate": "2006-04-27",
        "nepDate": "14.01.2063"
    },
    {
        "engDate": "2006-04-28",
        "nepDate": "15.01.2063"
    },
    {
        "engDate": "2006-04-29",
        "nepDate": "16.01.2063"
    },
    {
        "engDate": "2006-04-30",
        "nepDate": "17.01.2063"
    },
    {
        "engDate": "2006-05-01",
        "nepDate": "18.01.2063"
    },
    {
        "engDate": "2006-05-02",
        "nepDate": "19.01.2063"
    },
    {
        "engDate": "2006-05-03",
        "nepDate": "20.01.2063"
    },
    {
        "engDate": "2006-05-04",
        "nepDate": "21.01.2063"
    },
    {
        "engDate": "2006-05-05",
        "nepDate": "22.01.2063"
    },
    {
        "engDate": "2006-05-06",
        "nepDate": "23.01.2063"
    },
    {
        "engDate": "2006-05-07",
        "nepDate": "24.01.2063"
    },
    {
        "engDate": "2006-05-08",
        "nepDate": "25.01.2063"
    },
    {
        "engDate": "2006-05-09",
        "nepDate": "26.01.2063"
    },
    {
        "engDate": "2006-05-10",
        "nepDate": "27.01.2063"
    },
    {
        "engDate": "2006-05-11",
        "nepDate": "28.01.2063"
    },
    {
        "engDate": "2006-05-12",
        "nepDate": "29.01.2063"
    },
    {
        "engDate": "2006-05-13",
        "nepDate": "30.01.2063"
    },
    {
        "engDate": "2006-05-14",
        "nepDate": "31.01.2063"
    },
    {
        "engDate": "2006-05-15",
        "nepDate": "01.02.2063"
    },
    {
        "engDate": "2006-05-16",
        "nepDate": "02.02.2063"
    },
    {
        "engDate": "2006-05-17",
        "nepDate": "03.02.2063"
    },
    {
        "engDate": "2006-05-18",
        "nepDate": "04.02.2063"
    },
    {
        "engDate": "2006-05-19",
        "nepDate": "05.02.2063"
    },
    {
        "engDate": "2006-05-20",
        "nepDate": "06.02.2063"
    },
    {
        "engDate": "2006-05-21",
        "nepDate": "07.02.2063"
    },
    {
        "engDate": "2006-05-22",
        "nepDate": "08.02.2063"
    },
    {
        "engDate": "2006-05-23",
        "nepDate": "09.02.2063"
    },
    {
        "engDate": "2006-05-24",
        "nepDate": "10.02.2063"
    },
    {
        "engDate": "2006-05-25",
        "nepDate": "11.02.2063"
    },
    {
        "engDate": "2006-05-26",
        "nepDate": "12.02.2063"
    },
    {
        "engDate": "2006-05-27",
        "nepDate": "13.02.2063"
    },
    {
        "engDate": "2006-05-28",
        "nepDate": "14.02.2063"
    },
    {
        "engDate": "2006-05-29",
        "nepDate": "15.02.2063"
    },
    {
        "engDate": "2006-05-30",
        "nepDate": "16.02.2063"
    },
    {
        "engDate": "2006-05-31",
        "nepDate": "17.02.2063"
    },
    {
        "engDate": "2006-06-01",
        "nepDate": "18.02.2063"
    },
    {
        "engDate": "2006-06-02",
        "nepDate": "19.02.2063"
    },
    {
        "engDate": "2006-06-03",
        "nepDate": "20.02.2063"
    },
    {
        "engDate": "2006-06-04",
        "nepDate": "21.02.2063"
    },
    {
        "engDate": "2006-06-05",
        "nepDate": "22.02.2063"
    },
    {
        "engDate": "2006-06-06",
        "nepDate": "23.02.2063"
    },
    {
        "engDate": "2006-06-07",
        "nepDate": "24.02.2063"
    },
    {
        "engDate": "2006-06-08",
        "nepDate": "25.02.2063"
    },
    {
        "engDate": "2006-06-09",
        "nepDate": "26.02.2063"
    },
    {
        "engDate": "2006-06-10",
        "nepDate": "27.02.2063"
    },
    {
        "engDate": "2006-06-11",
        "nepDate": "28.02.2063"
    },
    {
        "engDate": "2006-06-12",
        "nepDate": "29.02.2063"
    },
    {
        "engDate": "2006-06-13",
        "nepDate": "30.02.2063"
    },
    {
        "engDate": "2006-06-14",
        "nepDate": "31.02.2063"
    },
    {
        "engDate": "2006-06-15",
        "nepDate": "01.03.2063"
    },
    {
        "engDate": "2006-06-16",
        "nepDate": "02.03.2063"
    },
    {
        "engDate": "2006-06-17",
        "nepDate": "03.03.2063"
    },
    {
        "engDate": "2006-06-18",
        "nepDate": "04.03.2063"
    },
    {
        "engDate": "2006-06-19",
        "nepDate": "05.03.2063"
    },
    {
        "engDate": "2006-06-20",
        "nepDate": "06.03.2063"
    },
    {
        "engDate": "2006-06-21",
        "nepDate": "07.03.2063"
    },
    {
        "engDate": "2006-06-22",
        "nepDate": "08.03.2063"
    },
    {
        "engDate": "2006-06-23",
        "nepDate": "09.03.2063"
    },
    {
        "engDate": "2006-06-24",
        "nepDate": "10.03.2063"
    },
    {
        "engDate": "2006-06-25",
        "nepDate": "11.03.2063"
    },
    {
        "engDate": "2006-06-26",
        "nepDate": "12.03.2063"
    },
    {
        "engDate": "2006-06-27",
        "nepDate": "13.03.2063"
    },
    {
        "engDate": "2006-06-28",
        "nepDate": "14.03.2063"
    },
    {
        "engDate": "2006-06-29",
        "nepDate": "15.03.2063"
    },
    {
        "engDate": "2006-06-30",
        "nepDate": "16.03.2063"
    },
    {
        "engDate": "2006-07-01",
        "nepDate": "17.03.2063"
    },
    {
        "engDate": "2006-07-02",
        "nepDate": "18.03.2063"
    },
    {
        "engDate": "2006-07-03",
        "nepDate": "19.03.2063"
    },
    {
        "engDate": "2006-07-04",
        "nepDate": "20.03.2063"
    },
    {
        "engDate": "2006-07-05",
        "nepDate": "21.03.2063"
    },
    {
        "engDate": "2006-07-06",
        "nepDate": "22.03.2063"
    },
    {
        "engDate": "2006-07-07",
        "nepDate": "23.03.2063"
    },
    {
        "engDate": "2006-07-08",
        "nepDate": "24.03.2063"
    },
    {
        "engDate": "2006-07-09",
        "nepDate": "25.03.2063"
    },
    {
        "engDate": "2006-07-10",
        "nepDate": "26.03.2063"
    },
    {
        "engDate": "2006-07-11",
        "nepDate": "27.03.2063"
    },
    {
        "engDate": "2006-07-12",
        "nepDate": "28.03.2063"
    },
    {
        "engDate": "2006-07-13",
        "nepDate": "29.03.2063"
    },
    {
        "engDate": "2006-07-14",
        "nepDate": "30.03.2063"
    },
    {
        "engDate": "2006-07-15",
        "nepDate": "31.03.2063"
    },
    {
        "engDate": "2006-07-16",
        "nepDate": "32.03.2063"
    },
    {
        "engDate": "2006-07-17",
        "nepDate": "01.04.2063"
    },
    {
        "engDate": "2006-07-18",
        "nepDate": "02.04.2063"
    },
    {
        "engDate": "2006-07-19",
        "nepDate": "03.04.2063"
    },
    {
        "engDate": "2006-07-20",
        "nepDate": "04.04.2063"
    },
    {
        "engDate": "2006-07-21",
        "nepDate": "05.04.2063"
    },
    {
        "engDate": "2006-07-22",
        "nepDate": "06.04.2063"
    },
    {
        "engDate": "2006-07-23",
        "nepDate": "07.04.2063"
    },
    {
        "engDate": "2006-07-24",
        "nepDate": "08.04.2063"
    },
    {
        "engDate": "2006-07-25",
        "nepDate": "09.04.2063"
    },
    {
        "engDate": "2006-07-26",
        "nepDate": "10.04.2063"
    },
    {
        "engDate": "2006-07-27",
        "nepDate": "11.04.2063"
    },
    {
        "engDate": "2006-07-28",
        "nepDate": "12.04.2063"
    },
    {
        "engDate": "2006-07-29",
        "nepDate": "13.04.2063"
    },
    {
        "engDate": "2006-07-30",
        "nepDate": "14.04.2063"
    },
    {
        "engDate": "2006-07-31",
        "nepDate": "15.04.2063"
    },
    {
        "engDate": "2006-08-01",
        "nepDate": "16.04.2063"
    },
    {
        "engDate": "2006-08-02",
        "nepDate": "17.04.2063"
    },
    {
        "engDate": "2006-08-03",
        "nepDate": "18.04.2063"
    },
    {
        "engDate": "2006-08-04",
        "nepDate": "19.04.2063"
    },
    {
        "engDate": "2006-08-05",
        "nepDate": "20.04.2063"
    },
    {
        "engDate": "2006-08-06",
        "nepDate": "21.04.2063"
    },
    {
        "engDate": "2006-08-07",
        "nepDate": "22.04.2063"
    },
    {
        "engDate": "2006-08-08",
        "nepDate": "23.04.2063"
    },
    {
        "engDate": "2006-08-09",
        "nepDate": "24.04.2063"
    },
    {
        "engDate": "2006-08-10",
        "nepDate": "25.04.2063"
    },
    {
        "engDate": "2006-08-11",
        "nepDate": "26.04.2063"
    },
    {
        "engDate": "2006-08-12",
        "nepDate": "27.04.2063"
    },
    {
        "engDate": "2006-08-13",
        "nepDate": "28.04.2063"
    },
    {
        "engDate": "2006-08-14",
        "nepDate": "29.04.2063"
    },
    {
        "engDate": "2006-08-15",
        "nepDate": "30.04.2063"
    },
    {
        "engDate": "2006-08-16",
        "nepDate": "31.04.2063"
    },
    {
        "engDate": "2006-08-17",
        "nepDate": "01.05.2063"
    },
    {
        "engDate": "2006-08-18",
        "nepDate": "02.05.2063"
    },
    {
        "engDate": "2006-08-19",
        "nepDate": "03.05.2063"
    },
    {
        "engDate": "2006-08-20",
        "nepDate": "04.05.2063"
    },
    {
        "engDate": "2006-08-21",
        "nepDate": "05.05.2063"
    },
    {
        "engDate": "2006-08-22",
        "nepDate": "06.05.2063"
    },
    {
        "engDate": "2006-08-23",
        "nepDate": "07.05.2063"
    },
    {
        "engDate": "2006-08-24",
        "nepDate": "08.05.2063"
    },
    {
        "engDate": "2006-08-25",
        "nepDate": "09.05.2063"
    },
    {
        "engDate": "2006-08-26",
        "nepDate": "10.05.2063"
    },
    {
        "engDate": "2006-08-27",
        "nepDate": "11.05.2063"
    },
    {
        "engDate": "2006-08-28",
        "nepDate": "12.05.2063"
    },
    {
        "engDate": "2006-08-29",
        "nepDate": "13.05.2063"
    },
    {
        "engDate": "2006-08-30",
        "nepDate": "14.05.2063"
    },
    {
        "engDate": "2006-08-31",
        "nepDate": "15.05.2063"
    },
    {
        "engDate": "2006-09-01",
        "nepDate": "16.05.2063"
    },
    {
        "engDate": "2006-09-02",
        "nepDate": "17.05.2063"
    },
    {
        "engDate": "2006-09-03",
        "nepDate": "18.05.2063"
    },
    {
        "engDate": "2006-09-04",
        "nepDate": "19.05.2063"
    },
    {
        "engDate": "2006-09-05",
        "nepDate": "20.05.2063"
    },
    {
        "engDate": "2006-09-06",
        "nepDate": "21.05.2063"
    },
    {
        "engDate": "2006-09-07",
        "nepDate": "22.05.2063"
    },
    {
        "engDate": "2006-09-08",
        "nepDate": "23.05.2063"
    },
    {
        "engDate": "2006-09-09",
        "nepDate": "24.05.2063"
    },
    {
        "engDate": "2006-09-10",
        "nepDate": "25.05.2063"
    },
    {
        "engDate": "2006-09-11",
        "nepDate": "26.05.2063"
    },
    {
        "engDate": "2006-09-12",
        "nepDate": "27.05.2063"
    },
    {
        "engDate": "2006-09-13",
        "nepDate": "28.05.2063"
    },
    {
        "engDate": "2006-09-14",
        "nepDate": "29.05.2063"
    },
    {
        "engDate": "2006-09-15",
        "nepDate": "30.05.2063"
    },
    {
        "engDate": "2006-09-16",
        "nepDate": "31.05.2063"
    },
    {
        "engDate": "2006-09-17",
        "nepDate": "01.06.2063"
    },
    {
        "engDate": "2006-09-18",
        "nepDate": "02.06.2063"
    },
    {
        "engDate": "2006-09-19",
        "nepDate": "03.06.2063"
    },
    {
        "engDate": "2006-09-20",
        "nepDate": "04.06.2063"
    },
    {
        "engDate": "2006-09-21",
        "nepDate": "05.06.2063"
    },
    {
        "engDate": "2006-09-22",
        "nepDate": "06.06.2063"
    },
    {
        "engDate": "2006-09-23",
        "nepDate": "07.06.2063"
    },
    {
        "engDate": "2006-09-24",
        "nepDate": "08.06.2063"
    },
    {
        "engDate": "2006-09-25",
        "nepDate": "09.06.2063"
    },
    {
        "engDate": "2006-09-26",
        "nepDate": "10.06.2063"
    },
    {
        "engDate": "2006-09-27",
        "nepDate": "11.06.2063"
    },
    {
        "engDate": "2006-09-28",
        "nepDate": "12.06.2063"
    },
    {
        "engDate": "2006-09-29",
        "nepDate": "13.06.2063"
    },
    {
        "engDate": "2006-09-30",
        "nepDate": "14.06.2063"
    },
    {
        "engDate": "2006-10-01",
        "nepDate": "15.06.2063"
    },
    {
        "engDate": "2006-10-02",
        "nepDate": "16.06.2063"
    },
    {
        "engDate": "2006-10-03",
        "nepDate": "17.06.2063"
    },
    {
        "engDate": "2006-10-04",
        "nepDate": "18.06.2063"
    },
    {
        "engDate": "2006-10-05",
        "nepDate": "19.06.2063"
    },
    {
        "engDate": "2006-10-06",
        "nepDate": "20.06.2063"
    },
    {
        "engDate": "2006-10-07",
        "nepDate": "21.06.2063"
    },
    {
        "engDate": "2006-10-08",
        "nepDate": "22.06.2063"
    },
    {
        "engDate": "2006-10-09",
        "nepDate": "23.06.2063"
    },
    {
        "engDate": "2006-10-10",
        "nepDate": "24.06.2063"
    },
    {
        "engDate": "2006-10-11",
        "nepDate": "25.06.2063"
    },
    {
        "engDate": "2006-10-12",
        "nepDate": "26.06.2063"
    },
    {
        "engDate": "2006-10-13",
        "nepDate": "27.06.2063"
    },
    {
        "engDate": "2006-10-14",
        "nepDate": "28.06.2063"
    },
    {
        "engDate": "2006-10-15",
        "nepDate": "29.06.2063"
    },
    {
        "engDate": "2006-10-16",
        "nepDate": "30.06.2063"
    },
    {
        "engDate": "2006-10-17",
        "nepDate": "31.06.2063"
    },
    {
        "engDate": "2006-10-18",
        "nepDate": "01.07.2063"
    },
    {
        "engDate": "2006-10-19",
        "nepDate": "02.07.2063"
    },
    {
        "engDate": "2006-10-20",
        "nepDate": "03.07.2063"
    },
    {
        "engDate": "2006-10-21",
        "nepDate": "04.07.2063"
    },
    {
        "engDate": "2006-10-22",
        "nepDate": "05.07.2063"
    },
    {
        "engDate": "2006-10-23",
        "nepDate": "06.07.2063"
    },
    {
        "engDate": "2006-10-24",
        "nepDate": "07.07.2063"
    },
    {
        "engDate": "2006-10-25",
        "nepDate": "08.07.2063"
    },
    {
        "engDate": "2006-10-26",
        "nepDate": "09.07.2063"
    },
    {
        "engDate": "2006-10-27",
        "nepDate": "10.07.2063"
    },
    {
        "engDate": "2006-10-28",
        "nepDate": "11.07.2063"
    },
    {
        "engDate": "2006-10-29",
        "nepDate": "12.07.2063"
    },
    {
        "engDate": "2006-10-30",
        "nepDate": "13.07.2063"
    },
    {
        "engDate": "2006-10-31",
        "nepDate": "14.07.2063"
    },
    {
        "engDate": "2006-11-01",
        "nepDate": "15.07.2063"
    },
    {
        "engDate": "2006-11-02",
        "nepDate": "16.07.2063"
    },
    {
        "engDate": "2006-11-03",
        "nepDate": "17.07.2063"
    },
    {
        "engDate": "2006-11-04",
        "nepDate": "18.07.2063"
    },
    {
        "engDate": "2006-11-05",
        "nepDate": "19.07.2063"
    },
    {
        "engDate": "2006-11-06",
        "nepDate": "20.07.2063"
    },
    {
        "engDate": "2006-11-07",
        "nepDate": "21.07.2063"
    },
    {
        "engDate": "2006-11-08",
        "nepDate": "22.07.2063"
    },
    {
        "engDate": "2006-11-09",
        "nepDate": "23.07.2063"
    },
    {
        "engDate": "2006-11-10",
        "nepDate": "24.07.2063"
    },
    {
        "engDate": "2006-11-11",
        "nepDate": "25.07.2063"
    },
    {
        "engDate": "2006-11-12",
        "nepDate": "26.07.2063"
    },
    {
        "engDate": "2006-11-13",
        "nepDate": "27.07.2063"
    },
    {
        "engDate": "2006-11-14",
        "nepDate": "28.07.2063"
    },
    {
        "engDate": "2006-11-15",
        "nepDate": "29.07.2063"
    },
    {
        "engDate": "2006-11-16",
        "nepDate": "30.07.2063"
    },
    {
        "engDate": "2006-11-17",
        "nepDate": "01.08.2063"
    },
    {
        "engDate": "2006-11-18",
        "nepDate": "02.08.2063"
    },
    {
        "engDate": "2006-11-19",
        "nepDate": "03.08.2063"
    },
    {
        "engDate": "2006-11-20",
        "nepDate": "04.08.2063"
    },
    {
        "engDate": "2006-11-21",
        "nepDate": "05.08.2063"
    },
    {
        "engDate": "2006-11-22",
        "nepDate": "06.08.2063"
    },
    {
        "engDate": "2006-11-23",
        "nepDate": "07.08.2063"
    },
    {
        "engDate": "2006-11-24",
        "nepDate": "08.08.2063"
    },
    {
        "engDate": "2006-11-25",
        "nepDate": "09.08.2063"
    },
    {
        "engDate": "2006-11-26",
        "nepDate": "10.08.2063"
    },
    {
        "engDate": "2006-11-27",
        "nepDate": "11.08.2063"
    },
    {
        "engDate": "2006-11-28",
        "nepDate": "12.08.2063"
    },
    {
        "engDate": "2006-11-29",
        "nepDate": "13.08.2063"
    },
    {
        "engDate": "2006-11-30",
        "nepDate": "14.08.2063"
    },
    {
        "engDate": "2006-12-01",
        "nepDate": "15.08.2063"
    },
    {
        "engDate": "2006-12-02",
        "nepDate": "16.08.2063"
    },
    {
        "engDate": "2006-12-03",
        "nepDate": "17.08.2063"
    },
    {
        "engDate": "2006-12-04",
        "nepDate": "18.08.2063"
    },
    {
        "engDate": "2006-12-05",
        "nepDate": "19.08.2063"
    },
    {
        "engDate": "2006-12-06",
        "nepDate": "20.08.2063"
    },
    {
        "engDate": "2006-12-07",
        "nepDate": "21.08.2063"
    },
    {
        "engDate": "2006-12-08",
        "nepDate": "22.08.2063"
    },
    {
        "engDate": "2006-12-09",
        "nepDate": "23.08.2063"
    },
    {
        "engDate": "2006-12-10",
        "nepDate": "24.08.2063"
    },
    {
        "engDate": "2006-12-11",
        "nepDate": "25.08.2063"
    },
    {
        "engDate": "2006-12-12",
        "nepDate": "26.08.2063"
    },
    {
        "engDate": "2006-12-13",
        "nepDate": "27.08.2063"
    },
    {
        "engDate": "2006-12-14",
        "nepDate": "28.08.2063"
    },
    {
        "engDate": "2006-12-15",
        "nepDate": "29.08.2063"
    },
    {
        "engDate": "2006-12-16",
        "nepDate": "01.09.2063"
    },
    {
        "engDate": "2006-12-17",
        "nepDate": "02.09.2063"
    },
    {
        "engDate": "2006-12-18",
        "nepDate": "03.09.2063"
    },
    {
        "engDate": "2006-12-19",
        "nepDate": "04.09.2063"
    },
    {
        "engDate": "2006-12-20",
        "nepDate": "05.09.2063"
    },
    {
        "engDate": "2006-12-21",
        "nepDate": "06.09.2063"
    },
    {
        "engDate": "2006-12-22",
        "nepDate": "07.09.2063"
    },
    {
        "engDate": "2006-12-23",
        "nepDate": "08.09.2063"
    },
    {
        "engDate": "2006-12-24",
        "nepDate": "09.09.2063"
    },
    {
        "engDate": "2006-12-25",
        "nepDate": "10.09.2063"
    },
    {
        "engDate": "2006-12-26",
        "nepDate": "11.09.2063"
    },
    {
        "engDate": "2006-12-27",
        "nepDate": "12.09.2063"
    },
    {
        "engDate": "2006-12-28",
        "nepDate": "13.09.2063"
    },
    {
        "engDate": "2006-12-29",
        "nepDate": "14.09.2063"
    },
    {
        "engDate": "2006-12-30",
        "nepDate": "15.09.2063"
    },
    {
        "engDate": "2006-12-31",
        "nepDate": "16.09.2063"
    },
    {
        "engDate": "2007-01-01",
        "nepDate": "17.09.2063"
    },
    {
        "engDate": "2007-01-02",
        "nepDate": "18.09.2063"
    },
    {
        "engDate": "2007-01-03",
        "nepDate": "19.09.2063"
    },
    {
        "engDate": "2007-01-04",
        "nepDate": "20.09.2063"
    },
    {
        "engDate": "2007-01-05",
        "nepDate": "21.09.2063"
    },
    {
        "engDate": "2007-01-06",
        "nepDate": "22.09.2063"
    },
    {
        "engDate": "2007-01-07",
        "nepDate": "23.09.2063"
    },
    {
        "engDate": "2007-01-08",
        "nepDate": "24.09.2063"
    },
    {
        "engDate": "2007-01-09",
        "nepDate": "25.09.2063"
    },
    {
        "engDate": "2007-01-10",
        "nepDate": "26.09.2063"
    },
    {
        "engDate": "2007-01-11",
        "nepDate": "27.09.2063"
    },
    {
        "engDate": "2007-01-12",
        "nepDate": "28.09.2063"
    },
    {
        "engDate": "2007-01-13",
        "nepDate": "29.09.2063"
    },
    {
        "engDate": "2007-01-14",
        "nepDate": "30.09.2063"
    },
    {
        "engDate": "2007-01-15",
        "nepDate": "01.10.2063"
    },
    {
        "engDate": "2007-01-16",
        "nepDate": "02.10.2063"
    },
    {
        "engDate": "2007-01-17",
        "nepDate": "03.10.2063"
    },
    {
        "engDate": "2007-01-18",
        "nepDate": "04.10.2063"
    },
    {
        "engDate": "2007-01-19",
        "nepDate": "05.10.2063"
    },
    {
        "engDate": "2007-01-20",
        "nepDate": "06.10.2063"
    },
    {
        "engDate": "2007-01-21",
        "nepDate": "07.10.2063"
    },
    {
        "engDate": "2007-01-22",
        "nepDate": "08.10.2063"
    },
    {
        "engDate": "2007-01-23",
        "nepDate": "09.10.2063"
    },
    {
        "engDate": "2007-01-24",
        "nepDate": "10.10.2063"
    },
    {
        "engDate": "2007-01-25",
        "nepDate": "11.10.2063"
    },
    {
        "engDate": "2007-01-26",
        "nepDate": "12.10.2063"
    },
    {
        "engDate": "2007-01-27",
        "nepDate": "13.10.2063"
    },
    {
        "engDate": "2007-01-28",
        "nepDate": "14.10.2063"
    },
    {
        "engDate": "2007-01-29",
        "nepDate": "15.10.2063"
    },
    {
        "engDate": "2007-01-30",
        "nepDate": "16.10.2063"
    },
    {
        "engDate": "2007-01-31",
        "nepDate": "17.10.2063"
    },
    {
        "engDate": "2007-02-01",
        "nepDate": "18.10.2063"
    },
    {
        "engDate": "2007-02-02",
        "nepDate": "19.10.2063"
    },
    {
        "engDate": "2007-02-03",
        "nepDate": "20.10.2063"
    },
    {
        "engDate": "2007-02-04",
        "nepDate": "21.10.2063"
    },
    {
        "engDate": "2007-02-05",
        "nepDate": "22.10.2063"
    },
    {
        "engDate": "2007-02-06",
        "nepDate": "23.10.2063"
    },
    {
        "engDate": "2007-02-07",
        "nepDate": "24.10.2063"
    },
    {
        "engDate": "2007-02-08",
        "nepDate": "25.10.2063"
    },
    {
        "engDate": "2007-02-09",
        "nepDate": "26.10.2063"
    },
    {
        "engDate": "2007-02-10",
        "nepDate": "27.10.2063"
    },
    {
        "engDate": "2007-02-11",
        "nepDate": "28.10.2063"
    },
    {
        "engDate": "2007-02-12",
        "nepDate": "29.10.2063"
    },
    {
        "engDate": "2007-02-13",
        "nepDate": "01.11.2063"
    },
    {
        "engDate": "2007-02-14",
        "nepDate": "02.11.2063"
    },
    {
        "engDate": "2007-02-15",
        "nepDate": "03.11.2063"
    },
    {
        "engDate": "2007-02-16",
        "nepDate": "04.11.2063"
    },
    {
        "engDate": "2007-02-17",
        "nepDate": "05.11.2063"
    },
    {
        "engDate": "2007-02-18",
        "nepDate": "06.11.2063"
    },
    {
        "engDate": "2007-02-19",
        "nepDate": "07.11.2063"
    },
    {
        "engDate": "2007-02-20",
        "nepDate": "08.11.2063"
    },
    {
        "engDate": "2007-02-21",
        "nepDate": "09.11.2063"
    },
    {
        "engDate": "2007-02-22",
        "nepDate": "10.11.2063"
    },
    {
        "engDate": "2007-02-23",
        "nepDate": "11.11.2063"
    },
    {
        "engDate": "2007-02-24",
        "nepDate": "12.11.2063"
    },
    {
        "engDate": "2007-02-25",
        "nepDate": "13.11.2063"
    },
    {
        "engDate": "2007-02-26",
        "nepDate": "14.11.2063"
    },
    {
        "engDate": "2007-02-27",
        "nepDate": "15.11.2063"
    },
    {
        "engDate": "2007-02-28",
        "nepDate": "16.11.2063"
    },
    {
        "engDate": "2007-03-01",
        "nepDate": "17.11.2063"
    },
    {
        "engDate": "2007-03-02",
        "nepDate": "18.11.2063"
    },
    {
        "engDate": "2007-03-03",
        "nepDate": "19.11.2063"
    },
    {
        "engDate": "2007-03-04",
        "nepDate": "20.11.2063"
    },
    {
        "engDate": "2007-03-05",
        "nepDate": "21.11.2063"
    },
    {
        "engDate": "2007-03-06",
        "nepDate": "22.11.2063"
    },
    {
        "engDate": "2007-03-07",
        "nepDate": "23.11.2063"
    },
    {
        "engDate": "2007-03-08",
        "nepDate": "24.11.2063"
    },
    {
        "engDate": "2007-03-09",
        "nepDate": "25.11.2063"
    },
    {
        "engDate": "2007-03-10",
        "nepDate": "26.11.2063"
    },
    {
        "engDate": "2007-03-11",
        "nepDate": "27.11.2063"
    },
    {
        "engDate": "2007-03-12",
        "nepDate": "28.11.2063"
    },
    {
        "engDate": "2007-03-13",
        "nepDate": "29.11.2063"
    },
    {
        "engDate": "2007-03-14",
        "nepDate": "30.11.2063"
    },
    {
        "engDate": "2007-03-15",
        "nepDate": "01.12.2063"
    },
    {
        "engDate": "2007-03-16",
        "nepDate": "02.12.2063"
    },
    {
        "engDate": "2007-03-17",
        "nepDate": "03.12.2063"
    },
    {
        "engDate": "2007-03-18",
        "nepDate": "04.12.2063"
    },
    {
        "engDate": "2007-03-19",
        "nepDate": "05.12.2063"
    },
    {
        "engDate": "2007-03-20",
        "nepDate": "06.12.2063"
    },
    {
        "engDate": "2007-03-21",
        "nepDate": "07.12.2063"
    },
    {
        "engDate": "2007-03-22",
        "nepDate": "08.12.2063"
    },
    {
        "engDate": "2007-03-23",
        "nepDate": "09.12.2063"
    },
    {
        "engDate": "2007-03-24",
        "nepDate": "10.12.2063"
    },
    {
        "engDate": "2007-03-25",
        "nepDate": "11.12.2063"
    },
    {
        "engDate": "2007-03-26",
        "nepDate": "12.12.2063"
    },
    {
        "engDate": "2007-03-27",
        "nepDate": "13.12.2063"
    },
    {
        "engDate": "2007-03-28",
        "nepDate": "14.12.2063"
    },
    {
        "engDate": "2007-03-29",
        "nepDate": "15.12.2063"
    },
    {
        "engDate": "2007-03-30",
        "nepDate": "16.12.2063"
    },
    {
        "engDate": "2007-03-31",
        "nepDate": "17.12.2063"
    },
    {
        "engDate": "2007-04-01",
        "nepDate": "18.12.2063"
    },
    {
        "engDate": "2007-04-02",
        "nepDate": "19.12.2063"
    },
    {
        "engDate": "2007-04-03",
        "nepDate": "20.12.2063"
    },
    {
        "engDate": "2007-04-04",
        "nepDate": "21.12.2063"
    },
    {
        "engDate": "2007-04-05",
        "nepDate": "22.12.2063"
    },
    {
        "engDate": "2007-04-06",
        "nepDate": "23.12.2063"
    },
    {
        "engDate": "2007-04-07",
        "nepDate": "24.12.2063"
    },
    {
        "engDate": "2007-04-08",
        "nepDate": "25.12.2063"
    },
    {
        "engDate": "2007-04-09",
        "nepDate": "26.12.2063"
    },
    {
        "engDate": "2007-04-10",
        "nepDate": "27.12.2063"
    },
    {
        "engDate": "2007-04-11",
        "nepDate": "28.12.2063"
    },
    {
        "engDate": "2007-04-12",
        "nepDate": "29.12.2063"
    },
    {
        "engDate": "2007-04-13",
        "nepDate": "30.12.2063"
    },
    {
        "engDate": "2007-04-14",
        "nepDate": "01.01.2064"
    },
    {
        "engDate": "2007-04-15",
        "nepDate": "02.01.2064"
    },
    {
        "engDate": "2007-04-16",
        "nepDate": "03.01.2064"
    },
    {
        "engDate": "2007-04-17",
        "nepDate": "04.01.2064"
    },
    {
        "engDate": "2007-04-18",
        "nepDate": "05.01.2064"
    },
    {
        "engDate": "2007-04-19",
        "nepDate": "06.01.2064"
    },
    {
        "engDate": "2007-04-20",
        "nepDate": "07.01.2064"
    },
    {
        "engDate": "2007-04-21",
        "nepDate": "08.01.2064"
    },
    {
        "engDate": "2007-04-22",
        "nepDate": "09.01.2064"
    },
    {
        "engDate": "2007-04-23",
        "nepDate": "10.01.2064"
    },
    {
        "engDate": "2007-04-24",
        "nepDate": "11.01.2064"
    },
    {
        "engDate": "2007-04-25",
        "nepDate": "12.01.2064"
    },
    {
        "engDate": "2007-04-26",
        "nepDate": "13.01.2064"
    },
    {
        "engDate": "2007-04-27",
        "nepDate": "14.01.2064"
    },
    {
        "engDate": "2007-04-28",
        "nepDate": "15.01.2064"
    },
    {
        "engDate": "2007-04-29",
        "nepDate": "16.01.2064"
    },
    {
        "engDate": "2007-04-30",
        "nepDate": "17.01.2064"
    },
    {
        "engDate": "2007-05-01",
        "nepDate": "18.01.2064"
    },
    {
        "engDate": "2007-05-02",
        "nepDate": "19.01.2064"
    },
    {
        "engDate": "2007-05-03",
        "nepDate": "20.01.2064"
    },
    {
        "engDate": "2007-05-04",
        "nepDate": "21.01.2064"
    },
    {
        "engDate": "2007-05-05",
        "nepDate": "22.01.2064"
    },
    {
        "engDate": "2007-05-06",
        "nepDate": "23.01.2064"
    },
    {
        "engDate": "2007-05-07",
        "nepDate": "24.01.2064"
    },
    {
        "engDate": "2007-05-08",
        "nepDate": "25.01.2064"
    },
    {
        "engDate": "2007-05-09",
        "nepDate": "26.01.2064"
    },
    {
        "engDate": "2007-05-10",
        "nepDate": "27.01.2064"
    },
    {
        "engDate": "2007-05-11",
        "nepDate": "28.01.2064"
    },
    {
        "engDate": "2007-05-12",
        "nepDate": "29.01.2064"
    },
    {
        "engDate": "2007-05-13",
        "nepDate": "30.01.2064"
    },
    {
        "engDate": "2007-05-14",
        "nepDate": "31.01.2064"
    },
    {
        "engDate": "2007-05-15",
        "nepDate": "01.02.2064"
    },
    {
        "engDate": "2007-05-16",
        "nepDate": "02.02.2064"
    },
    {
        "engDate": "2007-05-17",
        "nepDate": "03.02.2064"
    },
    {
        "engDate": "2007-05-18",
        "nepDate": "04.02.2064"
    },
    {
        "engDate": "2007-05-19",
        "nepDate": "05.02.2064"
    },
    {
        "engDate": "2007-05-20",
        "nepDate": "06.02.2064"
    },
    {
        "engDate": "2007-05-21",
        "nepDate": "07.02.2064"
    },
    {
        "engDate": "2007-05-22",
        "nepDate": "08.02.2064"
    },
    {
        "engDate": "2007-05-23",
        "nepDate": "09.02.2064"
    },
    {
        "engDate": "2007-05-24",
        "nepDate": "10.02.2064"
    },
    {
        "engDate": "2007-05-25",
        "nepDate": "11.02.2064"
    },
    {
        "engDate": "2007-05-26",
        "nepDate": "12.02.2064"
    },
    {
        "engDate": "2007-05-27",
        "nepDate": "13.02.2064"
    },
    {
        "engDate": "2007-05-28",
        "nepDate": "14.02.2064"
    },
    {
        "engDate": "2007-05-29",
        "nepDate": "15.02.2064"
    },
    {
        "engDate": "2007-05-30",
        "nepDate": "16.02.2064"
    },
    {
        "engDate": "2007-05-31",
        "nepDate": "17.02.2064"
    },
    {
        "engDate": "2007-06-01",
        "nepDate": "18.02.2064"
    },
    {
        "engDate": "2007-06-02",
        "nepDate": "19.02.2064"
    },
    {
        "engDate": "2007-06-03",
        "nepDate": "20.02.2064"
    },
    {
        "engDate": "2007-06-04",
        "nepDate": "21.02.2064"
    },
    {
        "engDate": "2007-06-05",
        "nepDate": "22.02.2064"
    },
    {
        "engDate": "2007-06-06",
        "nepDate": "23.02.2064"
    },
    {
        "engDate": "2007-06-07",
        "nepDate": "24.02.2064"
    },
    {
        "engDate": "2007-06-08",
        "nepDate": "25.02.2064"
    },
    {
        "engDate": "2007-06-09",
        "nepDate": "26.02.2064"
    },
    {
        "engDate": "2007-06-10",
        "nepDate": "27.02.2064"
    },
    {
        "engDate": "2007-06-11",
        "nepDate": "28.02.2064"
    },
    {
        "engDate": "2007-06-12",
        "nepDate": "29.02.2064"
    },
    {
        "engDate": "2007-06-13",
        "nepDate": "30.02.2064"
    },
    {
        "engDate": "2007-06-14",
        "nepDate": "31.02.2064"
    },
    {
        "engDate": "2007-06-15",
        "nepDate": "01.03.2064"
    },
    {
        "engDate": "2007-06-16",
        "nepDate": "02.03.2064"
    },
    {
        "engDate": "2007-06-17",
        "nepDate": "03.03.2064"
    },
    {
        "engDate": "2007-06-18",
        "nepDate": "04.03.2064"
    },
    {
        "engDate": "2007-06-19",
        "nepDate": "05.03.2064"
    },
    {
        "engDate": "2007-06-20",
        "nepDate": "06.03.2064"
    },
    {
        "engDate": "2007-06-21",
        "nepDate": "07.03.2064"
    },
    {
        "engDate": "2007-06-22",
        "nepDate": "08.03.2064"
    },
    {
        "engDate": "2007-06-23",
        "nepDate": "09.03.2064"
    },
    {
        "engDate": "2007-06-24",
        "nepDate": "10.03.2064"
    },
    {
        "engDate": "2007-06-25",
        "nepDate": "11.03.2064"
    },
    {
        "engDate": "2007-06-26",
        "nepDate": "12.03.2064"
    },
    {
        "engDate": "2007-06-27",
        "nepDate": "13.03.2064"
    },
    {
        "engDate": "2007-06-28",
        "nepDate": "14.03.2064"
    },
    {
        "engDate": "2007-06-29",
        "nepDate": "15.03.2064"
    },
    {
        "engDate": "2007-06-30",
        "nepDate": "16.03.2064"
    },
    {
        "engDate": "2007-07-01",
        "nepDate": "17.03.2064"
    },
    {
        "engDate": "2007-07-02",
        "nepDate": "18.03.2064"
    },
    {
        "engDate": "2007-07-03",
        "nepDate": "19.03.2064"
    },
    {
        "engDate": "2007-07-04",
        "nepDate": "20.03.2064"
    },
    {
        "engDate": "2007-07-05",
        "nepDate": "21.03.2064"
    },
    {
        "engDate": "2007-07-06",
        "nepDate": "22.03.2064"
    },
    {
        "engDate": "2007-07-07",
        "nepDate": "23.03.2064"
    },
    {
        "engDate": "2007-07-08",
        "nepDate": "24.03.2064"
    },
    {
        "engDate": "2007-07-09",
        "nepDate": "25.03.2064"
    },
    {
        "engDate": "2007-07-10",
        "nepDate": "26.03.2064"
    },
    {
        "engDate": "2007-07-11",
        "nepDate": "27.03.2064"
    },
    {
        "engDate": "2007-07-12",
        "nepDate": "28.03.2064"
    },
    {
        "engDate": "2007-07-13",
        "nepDate": "29.03.2064"
    },
    {
        "engDate": "2007-07-14",
        "nepDate": "30.03.2064"
    },
    {
        "engDate": "2007-07-15",
        "nepDate": "31.03.2064"
    },
    {
        "engDate": "2007-07-16",
        "nepDate": "32.03.2064"
    },
    {
        "engDate": "2007-07-17",
        "nepDate": "01.04.2064"
    },
    {
        "engDate": "2007-07-18",
        "nepDate": "02.04.2064"
    },
    {
        "engDate": "2007-07-19",
        "nepDate": "03.04.2064"
    },
    {
        "engDate": "2007-07-20",
        "nepDate": "04.04.2064"
    },
    {
        "engDate": "2007-07-21",
        "nepDate": "05.04.2064"
    },
    {
        "engDate": "2007-07-22",
        "nepDate": "06.04.2064"
    },
    {
        "engDate": "2007-07-23",
        "nepDate": "07.04.2064"
    },
    {
        "engDate": "2007-07-24",
        "nepDate": "08.04.2064"
    },
    {
        "engDate": "2007-07-25",
        "nepDate": "09.04.2064"
    },
    {
        "engDate": "2007-07-26",
        "nepDate": "10.04.2064"
    },
    {
        "engDate": "2007-07-27",
        "nepDate": "11.04.2064"
    },
    {
        "engDate": "2007-07-28",
        "nepDate": "12.04.2064"
    },
    {
        "engDate": "2007-07-29",
        "nepDate": "13.04.2064"
    },
    {
        "engDate": "2007-07-30",
        "nepDate": "14.04.2064"
    },
    {
        "engDate": "2007-07-31",
        "nepDate": "15.04.2064"
    },
    {
        "engDate": "2007-08-01",
        "nepDate": "16.04.2064"
    },
    {
        "engDate": "2007-08-02",
        "nepDate": "17.04.2064"
    },
    {
        "engDate": "2007-08-03",
        "nepDate": "18.04.2064"
    },
    {
        "engDate": "2007-08-04",
        "nepDate": "19.04.2064"
    },
    {
        "engDate": "2007-08-05",
        "nepDate": "20.04.2064"
    },
    {
        "engDate": "2007-08-06",
        "nepDate": "21.04.2064"
    },
    {
        "engDate": "2007-08-07",
        "nepDate": "22.04.2064"
    },
    {
        "engDate": "2007-08-08",
        "nepDate": "23.04.2064"
    },
    {
        "engDate": "2007-08-09",
        "nepDate": "24.04.2064"
    },
    {
        "engDate": "2007-08-10",
        "nepDate": "25.04.2064"
    },
    {
        "engDate": "2007-08-11",
        "nepDate": "26.04.2064"
    },
    {
        "engDate": "2007-08-12",
        "nepDate": "27.04.2064"
    },
    {
        "engDate": "2007-08-13",
        "nepDate": "28.04.2064"
    },
    {
        "engDate": "2007-08-14",
        "nepDate": "29.04.2064"
    },
    {
        "engDate": "2007-08-15",
        "nepDate": "30.04.2064"
    },
    {
        "engDate": "2007-08-16",
        "nepDate": "31.04.2064"
    },
    {
        "engDate": "2007-08-17",
        "nepDate": "32.04.2064"
    },
    {
        "engDate": "2007-08-18",
        "nepDate": "01.05.2064"
    },
    {
        "engDate": "2007-08-19",
        "nepDate": "02.05.2064"
    },
    {
        "engDate": "2007-08-20",
        "nepDate": "03.05.2064"
    },
    {
        "engDate": "2007-08-21",
        "nepDate": "04.05.2064"
    },
    {
        "engDate": "2007-08-22",
        "nepDate": "05.05.2064"
    },
    {
        "engDate": "2007-08-23",
        "nepDate": "06.05.2064"
    },
    {
        "engDate": "2007-08-24",
        "nepDate": "07.05.2064"
    },
    {
        "engDate": "2007-08-25",
        "nepDate": "08.05.2064"
    },
    {
        "engDate": "2007-08-26",
        "nepDate": "09.05.2064"
    },
    {
        "engDate": "2007-08-27",
        "nepDate": "10.05.2064"
    },
    {
        "engDate": "2007-08-28",
        "nepDate": "11.05.2064"
    },
    {
        "engDate": "2007-08-29",
        "nepDate": "12.05.2064"
    },
    {
        "engDate": "2007-08-30",
        "nepDate": "13.05.2064"
    },
    {
        "engDate": "2007-08-31",
        "nepDate": "14.05.2064"
    },
    {
        "engDate": "2007-09-01",
        "nepDate": "15.05.2064"
    },
    {
        "engDate": "2007-09-02",
        "nepDate": "16.05.2064"
    },
    {
        "engDate": "2007-09-03",
        "nepDate": "17.05.2064"
    },
    {
        "engDate": "2007-09-04",
        "nepDate": "18.05.2064"
    },
    {
        "engDate": "2007-09-05",
        "nepDate": "19.05.2064"
    },
    {
        "engDate": "2007-09-06",
        "nepDate": "20.05.2064"
    },
    {
        "engDate": "2007-09-07",
        "nepDate": "21.05.2064"
    },
    {
        "engDate": "2007-09-08",
        "nepDate": "22.05.2064"
    },
    {
        "engDate": "2007-09-09",
        "nepDate": "23.05.2064"
    },
    {
        "engDate": "2007-09-10",
        "nepDate": "24.05.2064"
    },
    {
        "engDate": "2007-09-11",
        "nepDate": "25.05.2064"
    },
    {
        "engDate": "2007-09-12",
        "nepDate": "26.05.2064"
    },
    {
        "engDate": "2007-09-13",
        "nepDate": "27.05.2064"
    },
    {
        "engDate": "2007-09-14",
        "nepDate": "28.05.2064"
    },
    {
        "engDate": "2007-09-15",
        "nepDate": "29.05.2064"
    },
    {
        "engDate": "2007-09-16",
        "nepDate": "30.05.2064"
    },
    {
        "engDate": "2007-09-17",
        "nepDate": "31.05.2064"
    },
    {
        "engDate": "2007-09-18",
        "nepDate": "01.06.2064"
    },
    {
        "engDate": "2007-09-19",
        "nepDate": "02.06.2064"
    },
    {
        "engDate": "2007-09-20",
        "nepDate": "03.06.2064"
    },
    {
        "engDate": "2007-09-21",
        "nepDate": "04.06.2064"
    },
    {
        "engDate": "2007-09-22",
        "nepDate": "05.06.2064"
    },
    {
        "engDate": "2007-09-23",
        "nepDate": "06.06.2064"
    },
    {
        "engDate": "2007-09-24",
        "nepDate": "07.06.2064"
    },
    {
        "engDate": "2007-09-25",
        "nepDate": "08.06.2064"
    },
    {
        "engDate": "2007-09-26",
        "nepDate": "09.06.2064"
    },
    {
        "engDate": "2007-09-27",
        "nepDate": "10.06.2064"
    },
    {
        "engDate": "2007-09-28",
        "nepDate": "11.06.2064"
    },
    {
        "engDate": "2007-09-29",
        "nepDate": "12.06.2064"
    },
    {
        "engDate": "2007-09-30",
        "nepDate": "13.06.2064"
    },
    {
        "engDate": "2007-10-01",
        "nepDate": "14.06.2064"
    },
    {
        "engDate": "2007-10-02",
        "nepDate": "15.06.2064"
    },
    {
        "engDate": "2007-10-03",
        "nepDate": "16.06.2064"
    },
    {
        "engDate": "2007-10-04",
        "nepDate": "17.06.2064"
    },
    {
        "engDate": "2007-10-05",
        "nepDate": "18.06.2064"
    },
    {
        "engDate": "2007-10-06",
        "nepDate": "19.06.2064"
    },
    {
        "engDate": "2007-10-07",
        "nepDate": "20.06.2064"
    },
    {
        "engDate": "2007-10-08",
        "nepDate": "21.06.2064"
    },
    {
        "engDate": "2007-10-09",
        "nepDate": "22.06.2064"
    },
    {
        "engDate": "2007-10-10",
        "nepDate": "23.06.2064"
    },
    {
        "engDate": "2007-10-11",
        "nepDate": "24.06.2064"
    },
    {
        "engDate": "2007-10-12",
        "nepDate": "25.06.2064"
    },
    {
        "engDate": "2007-10-13",
        "nepDate": "26.06.2064"
    },
    {
        "engDate": "2007-10-14",
        "nepDate": "27.06.2064"
    },
    {
        "engDate": "2007-10-15",
        "nepDate": "28.06.2064"
    },
    {
        "engDate": "2007-10-16",
        "nepDate": "29.06.2064"
    },
    {
        "engDate": "2007-10-17",
        "nepDate": "30.06.2064"
    },
    {
        "engDate": "2007-10-18",
        "nepDate": "01.07.2064"
    },
    {
        "engDate": "2007-10-19",
        "nepDate": "02.07.2064"
    },
    {
        "engDate": "2007-10-20",
        "nepDate": "03.07.2064"
    },
    {
        "engDate": "2007-10-21",
        "nepDate": "04.07.2064"
    },
    {
        "engDate": "2007-10-22",
        "nepDate": "05.07.2064"
    },
    {
        "engDate": "2007-10-23",
        "nepDate": "06.07.2064"
    },
    {
        "engDate": "2007-10-24",
        "nepDate": "07.07.2064"
    },
    {
        "engDate": "2007-10-25",
        "nepDate": "08.07.2064"
    },
    {
        "engDate": "2007-10-26",
        "nepDate": "09.07.2064"
    },
    {
        "engDate": "2007-10-27",
        "nepDate": "10.07.2064"
    },
    {
        "engDate": "2007-10-28",
        "nepDate": "11.07.2064"
    },
    {
        "engDate": "2007-10-29",
        "nepDate": "12.07.2064"
    },
    {
        "engDate": "2007-10-30",
        "nepDate": "13.07.2064"
    },
    {
        "engDate": "2007-10-31",
        "nepDate": "14.07.2064"
    },
    {
        "engDate": "2007-11-01",
        "nepDate": "15.07.2064"
    },
    {
        "engDate": "2007-11-02",
        "nepDate": "16.07.2064"
    },
    {
        "engDate": "2007-11-03",
        "nepDate": "17.07.2064"
    },
    {
        "engDate": "2007-11-04",
        "nepDate": "18.07.2064"
    },
    {
        "engDate": "2007-11-05",
        "nepDate": "19.07.2064"
    },
    {
        "engDate": "2007-11-06",
        "nepDate": "20.07.2064"
    },
    {
        "engDate": "2007-11-07",
        "nepDate": "21.07.2064"
    },
    {
        "engDate": "2007-11-08",
        "nepDate": "22.07.2064"
    },
    {
        "engDate": "2007-11-09",
        "nepDate": "23.07.2064"
    },
    {
        "engDate": "2007-11-10",
        "nepDate": "24.07.2064"
    },
    {
        "engDate": "2007-11-11",
        "nepDate": "25.07.2064"
    },
    {
        "engDate": "2007-11-12",
        "nepDate": "26.07.2064"
    },
    {
        "engDate": "2007-11-13",
        "nepDate": "27.07.2064"
    },
    {
        "engDate": "2007-11-14",
        "nepDate": "28.07.2064"
    },
    {
        "engDate": "2007-11-15",
        "nepDate": "29.07.2064"
    },
    {
        "engDate": "2007-11-16",
        "nepDate": "30.07.2064"
    },
    {
        "engDate": "2007-11-17",
        "nepDate": "01.08.2064"
    },
    {
        "engDate": "2007-11-18",
        "nepDate": "02.08.2064"
    },
    {
        "engDate": "2007-11-19",
        "nepDate": "03.08.2064"
    },
    {
        "engDate": "2007-11-20",
        "nepDate": "04.08.2064"
    },
    {
        "engDate": "2007-11-21",
        "nepDate": "05.08.2064"
    },
    {
        "engDate": "2007-11-22",
        "nepDate": "06.08.2064"
    },
    {
        "engDate": "2007-11-23",
        "nepDate": "07.08.2064"
    },
    {
        "engDate": "2007-11-24",
        "nepDate": "08.08.2064"
    },
    {
        "engDate": "2007-11-25",
        "nepDate": "09.08.2064"
    },
    {
        "engDate": "2007-11-26",
        "nepDate": "10.08.2064"
    },
    {
        "engDate": "2007-11-27",
        "nepDate": "11.08.2064"
    },
    {
        "engDate": "2007-11-28",
        "nepDate": "12.08.2064"
    },
    {
        "engDate": "2007-11-29",
        "nepDate": "13.08.2064"
    },
    {
        "engDate": "2007-11-30",
        "nepDate": "14.08.2064"
    },
    {
        "engDate": "2007-12-01",
        "nepDate": "15.08.2064"
    },
    {
        "engDate": "2007-12-02",
        "nepDate": "16.08.2064"
    },
    {
        "engDate": "2007-12-03",
        "nepDate": "17.08.2064"
    },
    {
        "engDate": "2007-12-04",
        "nepDate": "18.08.2064"
    },
    {
        "engDate": "2007-12-05",
        "nepDate": "19.08.2064"
    },
    {
        "engDate": "2007-12-06",
        "nepDate": "20.08.2064"
    },
    {
        "engDate": "2007-12-07",
        "nepDate": "21.08.2064"
    },
    {
        "engDate": "2007-12-08",
        "nepDate": "22.08.2064"
    },
    {
        "engDate": "2007-12-09",
        "nepDate": "23.08.2064"
    },
    {
        "engDate": "2007-12-10",
        "nepDate": "24.08.2064"
    },
    {
        "engDate": "2007-12-11",
        "nepDate": "25.08.2064"
    },
    {
        "engDate": "2007-12-12",
        "nepDate": "26.08.2064"
    },
    {
        "engDate": "2007-12-13",
        "nepDate": "27.08.2064"
    },
    {
        "engDate": "2007-12-14",
        "nepDate": "28.08.2064"
    },
    {
        "engDate": "2007-12-15",
        "nepDate": "29.08.2064"
    },
    {
        "engDate": "2007-12-16",
        "nepDate": "01.09.2064"
    },
    {
        "engDate": "2007-12-17",
        "nepDate": "02.09.2064"
    },
    {
        "engDate": "2007-12-18",
        "nepDate": "03.09.2064"
    },
    {
        "engDate": "2007-12-19",
        "nepDate": "04.09.2064"
    },
    {
        "engDate": "2007-12-20",
        "nepDate": "05.09.2064"
    },
    {
        "engDate": "2007-12-21",
        "nepDate": "06.09.2064"
    },
    {
        "engDate": "2007-12-22",
        "nepDate": "07.09.2064"
    },
    {
        "engDate": "2007-12-23",
        "nepDate": "08.09.2064"
    },
    {
        "engDate": "2007-12-24",
        "nepDate": "09.09.2064"
    },
    {
        "engDate": "2007-12-25",
        "nepDate": "10.09.2064"
    },
    {
        "engDate": "2007-12-26",
        "nepDate": "11.09.2064"
    },
    {
        "engDate": "2007-12-27",
        "nepDate": "12.09.2064"
    },
    {
        "engDate": "2007-12-28",
        "nepDate": "13.09.2064"
    },
    {
        "engDate": "2007-12-29",
        "nepDate": "14.09.2064"
    },
    {
        "engDate": "2007-12-30",
        "nepDate": "15.09.2064"
    },
    {
        "engDate": "2007-12-31",
        "nepDate": "16.09.2064"
    },
    {
        "engDate": "2008-01-01",
        "nepDate": "17.09.2064"
    },
    {
        "engDate": "2008-01-02",
        "nepDate": "18.09.2064"
    },
    {
        "engDate": "2008-01-03",
        "nepDate": "19.09.2064"
    },
    {
        "engDate": "2008-01-04",
        "nepDate": "20.09.2064"
    },
    {
        "engDate": "2008-01-05",
        "nepDate": "21.09.2064"
    },
    {
        "engDate": "2008-01-06",
        "nepDate": "22.09.2064"
    },
    {
        "engDate": "2008-01-07",
        "nepDate": "23.09.2064"
    },
    {
        "engDate": "2008-01-08",
        "nepDate": "24.09.2064"
    },
    {
        "engDate": "2008-01-09",
        "nepDate": "25.09.2064"
    },
    {
        "engDate": "2008-01-10",
        "nepDate": "26.09.2064"
    },
    {
        "engDate": "2008-01-11",
        "nepDate": "27.09.2064"
    },
    {
        "engDate": "2008-01-12",
        "nepDate": "28.09.2064"
    },
    {
        "engDate": "2008-01-13",
        "nepDate": "29.09.2064"
    },
    {
        "engDate": "2008-01-14",
        "nepDate": "30.09.2064"
    },
    {
        "engDate": "2008-01-15",
        "nepDate": "01.10.2064"
    },
    {
        "engDate": "2008-01-16",
        "nepDate": "02.10.2064"
    },
    {
        "engDate": "2008-01-17",
        "nepDate": "03.10.2064"
    },
    {
        "engDate": "2008-01-18",
        "nepDate": "04.10.2064"
    },
    {
        "engDate": "2008-01-19",
        "nepDate": "05.10.2064"
    },
    {
        "engDate": "2008-01-20",
        "nepDate": "06.10.2064"
    },
    {
        "engDate": "2008-01-21",
        "nepDate": "07.10.2064"
    },
    {
        "engDate": "2008-01-22",
        "nepDate": "08.10.2064"
    },
    {
        "engDate": "2008-01-23",
        "nepDate": "09.10.2064"
    },
    {
        "engDate": "2008-01-24",
        "nepDate": "10.10.2064"
    },
    {
        "engDate": "2008-01-25",
        "nepDate": "11.10.2064"
    },
    {
        "engDate": "2008-01-26",
        "nepDate": "12.10.2064"
    },
    {
        "engDate": "2008-01-27",
        "nepDate": "13.10.2064"
    },
    {
        "engDate": "2008-01-28",
        "nepDate": "14.10.2064"
    },
    {
        "engDate": "2008-01-29",
        "nepDate": "15.10.2064"
    },
    {
        "engDate": "2008-01-30",
        "nepDate": "16.10.2064"
    },
    {
        "engDate": "2008-01-31",
        "nepDate": "17.10.2064"
    },
    {
        "engDate": "2008-02-01",
        "nepDate": "18.10.2064"
    },
    {
        "engDate": "2008-02-02",
        "nepDate": "19.10.2064"
    },
    {
        "engDate": "2008-02-03",
        "nepDate": "20.10.2064"
    },
    {
        "engDate": "2008-02-04",
        "nepDate": "21.10.2064"
    },
    {
        "engDate": "2008-02-05",
        "nepDate": "22.10.2064"
    },
    {
        "engDate": "2008-02-06",
        "nepDate": "23.10.2064"
    },
    {
        "engDate": "2008-02-07",
        "nepDate": "24.10.2064"
    },
    {
        "engDate": "2008-02-08",
        "nepDate": "25.10.2064"
    },
    {
        "engDate": "2008-02-09",
        "nepDate": "26.10.2064"
    },
    {
        "engDate": "2008-02-10",
        "nepDate": "27.10.2064"
    },
    {
        "engDate": "2008-02-11",
        "nepDate": "28.10.2064"
    },
    {
        "engDate": "2008-02-12",
        "nepDate": "29.10.2064"
    },
    {
        "engDate": "2008-02-13",
        "nepDate": "01.11.2064"
    },
    {
        "engDate": "2008-02-14",
        "nepDate": "02.11.2064"
    },
    {
        "engDate": "2008-02-15",
        "nepDate": "03.11.2064"
    },
    {
        "engDate": "2008-02-16",
        "nepDate": "04.11.2064"
    },
    {
        "engDate": "2008-02-17",
        "nepDate": "05.11.2064"
    },
    {
        "engDate": "2008-02-18",
        "nepDate": "06.11.2064"
    },
    {
        "engDate": "2008-02-19",
        "nepDate": "07.11.2064"
    },
    {
        "engDate": "2008-02-20",
        "nepDate": "08.11.2064"
    },
    {
        "engDate": "2008-02-21",
        "nepDate": "09.11.2064"
    },
    {
        "engDate": "2008-02-22",
        "nepDate": "10.11.2064"
    },
    {
        "engDate": "2008-02-23",
        "nepDate": "11.11.2064"
    },
    {
        "engDate": "2008-02-24",
        "nepDate": "12.11.2064"
    },
    {
        "engDate": "2008-02-25",
        "nepDate": "13.11.2064"
    },
    {
        "engDate": "2008-02-26",
        "nepDate": "14.11.2064"
    },
    {
        "engDate": "2008-02-27",
        "nepDate": "15.11.2064"
    },
    {
        "engDate": "2008-02-28",
        "nepDate": "16.11.2064"
    },
    {
        "engDate": "2008-02-29",
        "nepDate": "17.11.2064"
    },
    {
        "engDate": "2008-03-01",
        "nepDate": "18.11.2064"
    },
    {
        "engDate": "2008-03-02",
        "nepDate": "19.11.2064"
    },
    {
        "engDate": "2008-03-03",
        "nepDate": "20.11.2064"
    },
    {
        "engDate": "2008-03-04",
        "nepDate": "21.11.2064"
    },
    {
        "engDate": "2008-03-05",
        "nepDate": "22.11.2064"
    },
    {
        "engDate": "2008-03-06",
        "nepDate": "23.11.2064"
    },
    {
        "engDate": "2008-03-07",
        "nepDate": "24.11.2064"
    },
    {
        "engDate": "2008-03-08",
        "nepDate": "25.11.2064"
    },
    {
        "engDate": "2008-03-09",
        "nepDate": "26.11.2064"
    },
    {
        "engDate": "2008-03-10",
        "nepDate": "27.11.2064"
    },
    {
        "engDate": "2008-03-11",
        "nepDate": "28.11.2064"
    },
    {
        "engDate": "2008-03-12",
        "nepDate": "29.11.2064"
    },
    {
        "engDate": "2008-03-13",
        "nepDate": "30.11.2064"
    },
    {
        "engDate": "2008-03-14",
        "nepDate": "01.12.2064"
    },
    {
        "engDate": "2008-03-15",
        "nepDate": "02.12.2064"
    },
    {
        "engDate": "2008-03-16",
        "nepDate": "03.12.2064"
    },
    {
        "engDate": "2008-03-17",
        "nepDate": "04.12.2064"
    },
    {
        "engDate": "2008-03-18",
        "nepDate": "05.12.2064"
    },
    {
        "engDate": "2008-03-19",
        "nepDate": "06.12.2064"
    },
    {
        "engDate": "2008-03-20",
        "nepDate": "07.12.2064"
    },
    {
        "engDate": "2008-03-21",
        "nepDate": "08.12.2064"
    },
    {
        "engDate": "2008-03-22",
        "nepDate": "09.12.2064"
    },
    {
        "engDate": "2008-03-23",
        "nepDate": "10.12.2064"
    },
    {
        "engDate": "2008-03-24",
        "nepDate": "11.12.2064"
    },
    {
        "engDate": "2008-03-25",
        "nepDate": "12.12.2064"
    },
    {
        "engDate": "2008-03-26",
        "nepDate": "13.12.2064"
    },
    {
        "engDate": "2008-03-27",
        "nepDate": "14.12.2064"
    },
    {
        "engDate": "2008-03-28",
        "nepDate": "15.12.2064"
    },
    {
        "engDate": "2008-03-29",
        "nepDate": "16.12.2064"
    },
    {
        "engDate": "2008-03-30",
        "nepDate": "17.12.2064"
    },
    {
        "engDate": "2008-03-31",
        "nepDate": "18.12.2064"
    },
    {
        "engDate": "2008-04-01",
        "nepDate": "19.12.2064"
    },
    {
        "engDate": "2008-04-02",
        "nepDate": "20.12.2064"
    },
    {
        "engDate": "2008-04-03",
        "nepDate": "21.12.2064"
    },
    {
        "engDate": "2008-04-04",
        "nepDate": "22.12.2064"
    },
    {
        "engDate": "2008-04-05",
        "nepDate": "23.12.2064"
    },
    {
        "engDate": "2008-04-06",
        "nepDate": "24.12.2064"
    },
    {
        "engDate": "2008-04-07",
        "nepDate": "25.12.2064"
    },
    {
        "engDate": "2008-04-08",
        "nepDate": "26.12.2064"
    },
    {
        "engDate": "2008-04-09",
        "nepDate": "27.12.2064"
    },
    {
        "engDate": "2008-04-10",
        "nepDate": "28.12.2064"
    },
    {
        "engDate": "2008-04-11",
        "nepDate": "29.12.2064"
    },
    {
        "engDate": "2008-04-12",
        "nepDate": "30.12.2064"
    },
    {
        "engDate": "2008-04-13",
        "nepDate": "01.01.2065"
    },
    {
        "engDate": "2008-04-14",
        "nepDate": "02.01.2065"
    },
    {
        "engDate": "2008-04-15",
        "nepDate": "03.01.2065"
    },
    {
        "engDate": "2008-04-16",
        "nepDate": "04.01.2065"
    },
    {
        "engDate": "2008-04-17",
        "nepDate": "05.01.2065"
    },
    {
        "engDate": "2008-04-18",
        "nepDate": "06.01.2065"
    },
    {
        "engDate": "2008-04-19",
        "nepDate": "07.01.2065"
    },
    {
        "engDate": "2008-04-20",
        "nepDate": "08.01.2065"
    },
    {
        "engDate": "2008-04-21",
        "nepDate": "09.01.2065"
    },
    {
        "engDate": "2008-04-22",
        "nepDate": "10.01.2065"
    },
    {
        "engDate": "2008-04-23",
        "nepDate": "11.01.2065"
    },
    {
        "engDate": "2008-04-24",
        "nepDate": "12.01.2065"
    },
    {
        "engDate": "2008-04-25",
        "nepDate": "13.01.2065"
    },
    {
        "engDate": "2008-04-26",
        "nepDate": "14.01.2065"
    },
    {
        "engDate": "2008-04-27",
        "nepDate": "15.01.2065"
    },
    {
        "engDate": "2008-04-28",
        "nepDate": "16.01.2065"
    },
    {
        "engDate": "2008-04-29",
        "nepDate": "17.01.2065"
    },
    {
        "engDate": "2008-04-30",
        "nepDate": "18.01.2065"
    },
    {
        "engDate": "2008-05-01",
        "nepDate": "19.01.2065"
    },
    {
        "engDate": "2008-05-02",
        "nepDate": "20.01.2065"
    },
    {
        "engDate": "2008-05-03",
        "nepDate": "21.01.2065"
    },
    {
        "engDate": "2008-05-04",
        "nepDate": "22.01.2065"
    },
    {
        "engDate": "2008-05-05",
        "nepDate": "23.01.2065"
    },
    {
        "engDate": "2008-05-06",
        "nepDate": "24.01.2065"
    },
    {
        "engDate": "2008-05-07",
        "nepDate": "25.01.2065"
    },
    {
        "engDate": "2008-05-08",
        "nepDate": "26.01.2065"
    },
    {
        "engDate": "2008-05-09",
        "nepDate": "27.01.2065"
    },
    {
        "engDate": "2008-05-10",
        "nepDate": "28.01.2065"
    },
    {
        "engDate": "2008-05-11",
        "nepDate": "29.01.2065"
    },
    {
        "engDate": "2008-05-12",
        "nepDate": "30.01.2065"
    },
    {
        "engDate": "2008-05-13",
        "nepDate": "31.01.2065"
    },
    {
        "engDate": "2008-05-14",
        "nepDate": "01.02.2065"
    },
    {
        "engDate": "2008-05-15",
        "nepDate": "02.02.2065"
    },
    {
        "engDate": "2008-05-16",
        "nepDate": "03.02.2065"
    },
    {
        "engDate": "2008-05-17",
        "nepDate": "04.02.2065"
    },
    {
        "engDate": "2008-05-18",
        "nepDate": "05.02.2065"
    },
    {
        "engDate": "2008-05-19",
        "nepDate": "06.02.2065"
    },
    {
        "engDate": "2008-05-20",
        "nepDate": "07.02.2065"
    },
    {
        "engDate": "2008-05-21",
        "nepDate": "08.02.2065"
    },
    {
        "engDate": "2008-05-22",
        "nepDate": "09.02.2065"
    },
    {
        "engDate": "2008-05-23",
        "nepDate": "10.02.2065"
    },
    {
        "engDate": "2008-05-24",
        "nepDate": "11.02.2065"
    },
    {
        "engDate": "2008-05-25",
        "nepDate": "12.02.2065"
    },
    {
        "engDate": "2008-05-26",
        "nepDate": "13.02.2065"
    },
    {
        "engDate": "2008-05-27",
        "nepDate": "14.02.2065"
    },
    {
        "engDate": "2008-05-28",
        "nepDate": "15.02.2065"
    },
    {
        "engDate": "2008-05-29",
        "nepDate": "16.02.2065"
    },
    {
        "engDate": "2008-05-30",
        "nepDate": "17.02.2065"
    },
    {
        "engDate": "2008-05-31",
        "nepDate": "18.02.2065"
    },
    {
        "engDate": "2008-06-01",
        "nepDate": "19.02.2065"
    },
    {
        "engDate": "2008-06-02",
        "nepDate": "20.02.2065"
    },
    {
        "engDate": "2008-06-03",
        "nepDate": "21.02.2065"
    },
    {
        "engDate": "2008-06-04",
        "nepDate": "22.02.2065"
    },
    {
        "engDate": "2008-06-05",
        "nepDate": "23.02.2065"
    },
    {
        "engDate": "2008-06-06",
        "nepDate": "24.02.2065"
    },
    {
        "engDate": "2008-06-07",
        "nepDate": "25.02.2065"
    },
    {
        "engDate": "2008-06-08",
        "nepDate": "26.02.2065"
    },
    {
        "engDate": "2008-06-09",
        "nepDate": "27.02.2065"
    },
    {
        "engDate": "2008-06-10",
        "nepDate": "28.02.2065"
    },
    {
        "engDate": "2008-06-11",
        "nepDate": "29.02.2065"
    },
    {
        "engDate": "2008-06-12",
        "nepDate": "30.02.2065"
    },
    {
        "engDate": "2008-06-13",
        "nepDate": "31.02.2065"
    },
    {
        "engDate": "2008-06-14",
        "nepDate": "32.02.2065"
    },
    {
        "engDate": "2008-06-15",
        "nepDate": "01.03.2065"
    },
    {
        "engDate": "2008-06-16",
        "nepDate": "02.03.2065"
    },
    {
        "engDate": "2008-06-17",
        "nepDate": "03.03.2065"
    },
    {
        "engDate": "2008-06-18",
        "nepDate": "04.03.2065"
    },
    {
        "engDate": "2008-06-19",
        "nepDate": "05.03.2065"
    },
    {
        "engDate": "2008-06-20",
        "nepDate": "06.03.2065"
    },
    {
        "engDate": "2008-06-21",
        "nepDate": "07.03.2065"
    },
    {
        "engDate": "2008-06-22",
        "nepDate": "08.03.2065"
    },
    {
        "engDate": "2008-06-23",
        "nepDate": "09.03.2065"
    },
    {
        "engDate": "2008-06-24",
        "nepDate": "10.03.2065"
    },
    {
        "engDate": "2008-06-25",
        "nepDate": "11.03.2065"
    },
    {
        "engDate": "2008-06-26",
        "nepDate": "12.03.2065"
    },
    {
        "engDate": "2008-06-27",
        "nepDate": "13.03.2065"
    },
    {
        "engDate": "2008-06-28",
        "nepDate": "14.03.2065"
    },
    {
        "engDate": "2008-06-29",
        "nepDate": "15.03.2065"
    },
    {
        "engDate": "2008-06-30",
        "nepDate": "16.03.2065"
    },
    {
        "engDate": "2008-07-01",
        "nepDate": "17.03.2065"
    },
    {
        "engDate": "2008-07-02",
        "nepDate": "18.03.2065"
    },
    {
        "engDate": "2008-07-03",
        "nepDate": "19.03.2065"
    },
    {
        "engDate": "2008-07-04",
        "nepDate": "20.03.2065"
    },
    {
        "engDate": "2008-07-05",
        "nepDate": "21.03.2065"
    },
    {
        "engDate": "2008-07-06",
        "nepDate": "22.03.2065"
    },
    {
        "engDate": "2008-07-07",
        "nepDate": "23.03.2065"
    },
    {
        "engDate": "2008-07-08",
        "nepDate": "24.03.2065"
    },
    {
        "engDate": "2008-07-09",
        "nepDate": "25.03.2065"
    },
    {
        "engDate": "2008-07-10",
        "nepDate": "26.03.2065"
    },
    {
        "engDate": "2008-07-11",
        "nepDate": "27.03.2065"
    },
    {
        "engDate": "2008-07-12",
        "nepDate": "28.03.2065"
    },
    {
        "engDate": "2008-07-13",
        "nepDate": "29.03.2065"
    },
    {
        "engDate": "2008-07-14",
        "nepDate": "30.03.2065"
    },
    {
        "engDate": "2008-07-15",
        "nepDate": "31.03.2065"
    },
    {
        "engDate": "2008-07-16",
        "nepDate": "01.04.2065"
    },
    {
        "engDate": "2008-07-17",
        "nepDate": "02.04.2065"
    },
    {
        "engDate": "2008-07-18",
        "nepDate": "03.04.2065"
    },
    {
        "engDate": "2008-07-19",
        "nepDate": "04.04.2065"
    },
    {
        "engDate": "2008-07-20",
        "nepDate": "05.04.2065"
    },
    {
        "engDate": "2008-07-21",
        "nepDate": "06.04.2065"
    },
    {
        "engDate": "2008-07-22",
        "nepDate": "07.04.2065"
    },
    {
        "engDate": "2008-07-23",
        "nepDate": "08.04.2065"
    },
    {
        "engDate": "2008-07-24",
        "nepDate": "09.04.2065"
    },
    {
        "engDate": "2008-07-25",
        "nepDate": "10.04.2065"
    },
    {
        "engDate": "2008-07-26",
        "nepDate": "11.04.2065"
    },
    {
        "engDate": "2008-07-27",
        "nepDate": "12.04.2065"
    },
    {
        "engDate": "2008-07-28",
        "nepDate": "13.04.2065"
    },
    {
        "engDate": "2008-07-29",
        "nepDate": "14.04.2065"
    },
    {
        "engDate": "2008-07-30",
        "nepDate": "15.04.2065"
    },
    {
        "engDate": "2008-07-31",
        "nepDate": "16.04.2065"
    },
    {
        "engDate": "2008-08-01",
        "nepDate": "17.04.2065"
    },
    {
        "engDate": "2008-08-02",
        "nepDate": "18.04.2065"
    },
    {
        "engDate": "2008-08-03",
        "nepDate": "19.04.2065"
    },
    {
        "engDate": "2008-08-04",
        "nepDate": "20.04.2065"
    },
    {
        "engDate": "2008-08-05",
        "nepDate": "21.04.2065"
    },
    {
        "engDate": "2008-08-06",
        "nepDate": "22.04.2065"
    },
    {
        "engDate": "2008-08-07",
        "nepDate": "23.04.2065"
    },
    {
        "engDate": "2008-08-08",
        "nepDate": "24.04.2065"
    },
    {
        "engDate": "2008-08-09",
        "nepDate": "25.04.2065"
    },
    {
        "engDate": "2008-08-10",
        "nepDate": "26.04.2065"
    },
    {
        "engDate": "2008-08-11",
        "nepDate": "27.04.2065"
    },
    {
        "engDate": "2008-08-12",
        "nepDate": "28.04.2065"
    },
    {
        "engDate": "2008-08-13",
        "nepDate": "29.04.2065"
    },
    {
        "engDate": "2008-08-14",
        "nepDate": "30.04.2065"
    },
    {
        "engDate": "2008-08-15",
        "nepDate": "31.04.2065"
    },
    {
        "engDate": "2008-08-16",
        "nepDate": "32.04.2065"
    },
    {
        "engDate": "2008-08-17",
        "nepDate": "01.05.2065"
    },
    {
        "engDate": "2008-08-18",
        "nepDate": "02.05.2065"
    },
    {
        "engDate": "2008-08-19",
        "nepDate": "03.05.2065"
    },
    {
        "engDate": "2008-08-20",
        "nepDate": "04.05.2065"
    },
    {
        "engDate": "2008-08-21",
        "nepDate": "05.05.2065"
    },
    {
        "engDate": "2008-08-22",
        "nepDate": "06.05.2065"
    },
    {
        "engDate": "2008-08-23",
        "nepDate": "07.05.2065"
    },
    {
        "engDate": "2008-08-24",
        "nepDate": "08.05.2065"
    },
    {
        "engDate": "2008-08-25",
        "nepDate": "09.05.2065"
    },
    {
        "engDate": "2008-08-26",
        "nepDate": "10.05.2065"
    },
    {
        "engDate": "2008-08-27",
        "nepDate": "11.05.2065"
    },
    {
        "engDate": "2008-08-28",
        "nepDate": "12.05.2065"
    },
    {
        "engDate": "2008-08-29",
        "nepDate": "13.05.2065"
    },
    {
        "engDate": "2008-08-30",
        "nepDate": "14.05.2065"
    },
    {
        "engDate": "2008-08-31",
        "nepDate": "15.05.2065"
    },
    {
        "engDate": "2008-09-01",
        "nepDate": "16.05.2065"
    },
    {
        "engDate": "2008-09-02",
        "nepDate": "17.05.2065"
    },
    {
        "engDate": "2008-09-03",
        "nepDate": "18.05.2065"
    },
    {
        "engDate": "2008-09-04",
        "nepDate": "19.05.2065"
    },
    {
        "engDate": "2008-09-05",
        "nepDate": "20.05.2065"
    },
    {
        "engDate": "2008-09-06",
        "nepDate": "21.05.2065"
    },
    {
        "engDate": "2008-09-07",
        "nepDate": "22.05.2065"
    },
    {
        "engDate": "2008-09-08",
        "nepDate": "23.05.2065"
    },
    {
        "engDate": "2008-09-09",
        "nepDate": "24.05.2065"
    },
    {
        "engDate": "2008-09-10",
        "nepDate": "25.05.2065"
    },
    {
        "engDate": "2008-09-11",
        "nepDate": "26.05.2065"
    },
    {
        "engDate": "2008-09-12",
        "nepDate": "27.05.2065"
    },
    {
        "engDate": "2008-09-13",
        "nepDate": "28.05.2065"
    },
    {
        "engDate": "2008-09-14",
        "nepDate": "29.05.2065"
    },
    {
        "engDate": "2008-09-15",
        "nepDate": "30.05.2065"
    },
    {
        "engDate": "2008-09-16",
        "nepDate": "31.05.2065"
    },
    {
        "engDate": "2008-09-17",
        "nepDate": "01.06.2065"
    },
    {
        "engDate": "2008-09-18",
        "nepDate": "02.06.2065"
    },
    {
        "engDate": "2008-09-19",
        "nepDate": "03.06.2065"
    },
    {
        "engDate": "2008-09-20",
        "nepDate": "04.06.2065"
    },
    {
        "engDate": "2008-09-21",
        "nepDate": "05.06.2065"
    },
    {
        "engDate": "2008-09-22",
        "nepDate": "06.06.2065"
    },
    {
        "engDate": "2008-09-23",
        "nepDate": "07.06.2065"
    },
    {
        "engDate": "2008-09-24",
        "nepDate": "08.06.2065"
    },
    {
        "engDate": "2008-09-25",
        "nepDate": "09.06.2065"
    },
    {
        "engDate": "2008-09-26",
        "nepDate": "10.06.2065"
    },
    {
        "engDate": "2008-09-27",
        "nepDate": "11.06.2065"
    },
    {
        "engDate": "2008-09-28",
        "nepDate": "12.06.2065"
    },
    {
        "engDate": "2008-09-29",
        "nepDate": "13.06.2065"
    },
    {
        "engDate": "2008-09-30",
        "nepDate": "14.06.2065"
    },
    {
        "engDate": "2008-10-01",
        "nepDate": "15.06.2065"
    },
    {
        "engDate": "2008-10-02",
        "nepDate": "16.06.2065"
    },
    {
        "engDate": "2008-10-03",
        "nepDate": "17.06.2065"
    },
    {
        "engDate": "2008-10-04",
        "nepDate": "18.06.2065"
    },
    {
        "engDate": "2008-10-05",
        "nepDate": "19.06.2065"
    },
    {
        "engDate": "2008-10-06",
        "nepDate": "20.06.2065"
    },
    {
        "engDate": "2008-10-07",
        "nepDate": "21.06.2065"
    },
    {
        "engDate": "2008-10-08",
        "nepDate": "22.06.2065"
    },
    {
        "engDate": "2008-10-09",
        "nepDate": "23.06.2065"
    },
    {
        "engDate": "2008-10-10",
        "nepDate": "24.06.2065"
    },
    {
        "engDate": "2008-10-11",
        "nepDate": "25.06.2065"
    },
    {
        "engDate": "2008-10-12",
        "nepDate": "26.06.2065"
    },
    {
        "engDate": "2008-10-13",
        "nepDate": "27.06.2065"
    },
    {
        "engDate": "2008-10-14",
        "nepDate": "28.06.2065"
    },
    {
        "engDate": "2008-10-15",
        "nepDate": "29.06.2065"
    },
    {
        "engDate": "2008-10-16",
        "nepDate": "30.06.2065"
    },
    {
        "engDate": "2008-10-17",
        "nepDate": "01.07.2065"
    },
    {
        "engDate": "2008-10-18",
        "nepDate": "02.07.2065"
    },
    {
        "engDate": "2008-10-19",
        "nepDate": "03.07.2065"
    },
    {
        "engDate": "2008-10-20",
        "nepDate": "04.07.2065"
    },
    {
        "engDate": "2008-10-21",
        "nepDate": "05.07.2065"
    },
    {
        "engDate": "2008-10-22",
        "nepDate": "06.07.2065"
    },
    {
        "engDate": "2008-10-23",
        "nepDate": "07.07.2065"
    },
    {
        "engDate": "2008-10-24",
        "nepDate": "08.07.2065"
    },
    {
        "engDate": "2008-10-25",
        "nepDate": "09.07.2065"
    },
    {
        "engDate": "2008-10-26",
        "nepDate": "10.07.2065"
    },
    {
        "engDate": "2008-10-27",
        "nepDate": "11.07.2065"
    },
    {
        "engDate": "2008-10-28",
        "nepDate": "12.07.2065"
    },
    {
        "engDate": "2008-10-29",
        "nepDate": "13.07.2065"
    },
    {
        "engDate": "2008-10-30",
        "nepDate": "14.07.2065"
    },
    {
        "engDate": "2008-10-31",
        "nepDate": "15.07.2065"
    },
    {
        "engDate": "2008-11-01",
        "nepDate": "16.07.2065"
    },
    {
        "engDate": "2008-11-02",
        "nepDate": "17.07.2065"
    },
    {
        "engDate": "2008-11-03",
        "nepDate": "18.07.2065"
    },
    {
        "engDate": "2008-11-04",
        "nepDate": "19.07.2065"
    },
    {
        "engDate": "2008-11-05",
        "nepDate": "20.07.2065"
    },
    {
        "engDate": "2008-11-06",
        "nepDate": "21.07.2065"
    },
    {
        "engDate": "2008-11-07",
        "nepDate": "22.07.2065"
    },
    {
        "engDate": "2008-11-08",
        "nepDate": "23.07.2065"
    },
    {
        "engDate": "2008-11-09",
        "nepDate": "24.07.2065"
    },
    {
        "engDate": "2008-11-10",
        "nepDate": "25.07.2065"
    },
    {
        "engDate": "2008-11-11",
        "nepDate": "26.07.2065"
    },
    {
        "engDate": "2008-11-12",
        "nepDate": "27.07.2065"
    },
    {
        "engDate": "2008-11-13",
        "nepDate": "28.07.2065"
    },
    {
        "engDate": "2008-11-14",
        "nepDate": "29.07.2065"
    },
    {
        "engDate": "2008-11-15",
        "nepDate": "30.07.2065"
    },
    {
        "engDate": "2008-11-16",
        "nepDate": "01.08.2065"
    },
    {
        "engDate": "2008-11-17",
        "nepDate": "02.08.2065"
    },
    {
        "engDate": "2008-11-18",
        "nepDate": "03.08.2065"
    },
    {
        "engDate": "2008-11-19",
        "nepDate": "04.08.2065"
    },
    {
        "engDate": "2008-11-20",
        "nepDate": "05.08.2065"
    },
    {
        "engDate": "2008-11-21",
        "nepDate": "06.08.2065"
    },
    {
        "engDate": "2008-11-22",
        "nepDate": "07.08.2065"
    },
    {
        "engDate": "2008-11-23",
        "nepDate": "08.08.2065"
    },
    {
        "engDate": "2008-11-24",
        "nepDate": "09.08.2065"
    },
    {
        "engDate": "2008-11-25",
        "nepDate": "10.08.2065"
    },
    {
        "engDate": "2008-11-26",
        "nepDate": "11.08.2065"
    },
    {
        "engDate": "2008-11-27",
        "nepDate": "12.08.2065"
    },
    {
        "engDate": "2008-11-28",
        "nepDate": "13.08.2065"
    },
    {
        "engDate": "2008-11-29",
        "nepDate": "14.08.2065"
    },
    {
        "engDate": "2008-11-30",
        "nepDate": "15.08.2065"
    },
    {
        "engDate": "2008-12-01",
        "nepDate": "16.08.2065"
    },
    {
        "engDate": "2008-12-02",
        "nepDate": "17.08.2065"
    },
    {
        "engDate": "2008-12-03",
        "nepDate": "18.08.2065"
    },
    {
        "engDate": "2008-12-04",
        "nepDate": "19.08.2065"
    },
    {
        "engDate": "2008-12-05",
        "nepDate": "20.08.2065"
    },
    {
        "engDate": "2008-12-06",
        "nepDate": "21.08.2065"
    },
    {
        "engDate": "2008-12-07",
        "nepDate": "22.08.2065"
    },
    {
        "engDate": "2008-12-08",
        "nepDate": "23.08.2065"
    },
    {
        "engDate": "2008-12-09",
        "nepDate": "24.08.2065"
    },
    {
        "engDate": "2008-12-10",
        "nepDate": "25.08.2065"
    },
    {
        "engDate": "2008-12-11",
        "nepDate": "26.08.2065"
    },
    {
        "engDate": "2008-12-12",
        "nepDate": "27.08.2065"
    },
    {
        "engDate": "2008-12-13",
        "nepDate": "28.08.2065"
    },
    {
        "engDate": "2008-12-14",
        "nepDate": "29.08.2065"
    },
    {
        "engDate": "2008-12-15",
        "nepDate": "30.08.2065"
    },
    {
        "engDate": "2008-12-16",
        "nepDate": "01.09.2065"
    },
    {
        "engDate": "2008-12-17",
        "nepDate": "02.09.2065"
    },
    {
        "engDate": "2008-12-18",
        "nepDate": "03.09.2065"
    },
    {
        "engDate": "2008-12-19",
        "nepDate": "04.09.2065"
    },
    {
        "engDate": "2008-12-20",
        "nepDate": "05.09.2065"
    },
    {
        "engDate": "2008-12-21",
        "nepDate": "06.09.2065"
    },
    {
        "engDate": "2008-12-22",
        "nepDate": "07.09.2065"
    },
    {
        "engDate": "2008-12-23",
        "nepDate": "08.09.2065"
    },
    {
        "engDate": "2008-12-24",
        "nepDate": "09.09.2065"
    },
    {
        "engDate": "2008-12-25",
        "nepDate": "10.09.2065"
    },
    {
        "engDate": "2008-12-26",
        "nepDate": "11.09.2065"
    },
    {
        "engDate": "2008-12-27",
        "nepDate": "12.09.2065"
    },
    {
        "engDate": "2008-12-28",
        "nepDate": "13.09.2065"
    },
    {
        "engDate": "2008-12-29",
        "nepDate": "14.09.2065"
    },
    {
        "engDate": "2008-12-30",
        "nepDate": "15.09.2065"
    },
    {
        "engDate": "2008-12-31",
        "nepDate": "16.09.2065"
    },
    {
        "engDate": "2009-01-01",
        "nepDate": "17.09.2065"
    },
    {
        "engDate": "2009-01-02",
        "nepDate": "18.09.2065"
    },
    {
        "engDate": "2009-01-03",
        "nepDate": "19.09.2065"
    },
    {
        "engDate": "2009-01-04",
        "nepDate": "20.09.2065"
    },
    {
        "engDate": "2009-01-05",
        "nepDate": "21.09.2065"
    },
    {
        "engDate": "2009-01-06",
        "nepDate": "22.09.2065"
    },
    {
        "engDate": "2009-01-07",
        "nepDate": "23.09.2065"
    },
    {
        "engDate": "2009-01-08",
        "nepDate": "24.09.2065"
    },
    {
        "engDate": "2009-01-09",
        "nepDate": "25.09.2065"
    },
    {
        "engDate": "2009-01-10",
        "nepDate": "26.09.2065"
    },
    {
        "engDate": "2009-01-11",
        "nepDate": "27.09.2065"
    },
    {
        "engDate": "2009-01-12",
        "nepDate": "28.09.2065"
    },
    {
        "engDate": "2009-01-13",
        "nepDate": "29.09.2065"
    },
    {
        "engDate": "2009-01-14",
        "nepDate": "01.10.2065"
    },
    {
        "engDate": "2009-01-15",
        "nepDate": "02.10.2065"
    },
    {
        "engDate": "2009-01-16",
        "nepDate": "03.10.2065"
    },
    {
        "engDate": "2009-01-17",
        "nepDate": "04.10.2065"
    },
    {
        "engDate": "2009-01-18",
        "nepDate": "05.10.2065"
    },
    {
        "engDate": "2009-01-19",
        "nepDate": "06.10.2065"
    },
    {
        "engDate": "2009-01-20",
        "nepDate": "07.10.2065"
    },
    {
        "engDate": "2009-01-21",
        "nepDate": "08.10.2065"
    },
    {
        "engDate": "2009-01-22",
        "nepDate": "09.10.2065"
    },
    {
        "engDate": "2009-01-23",
        "nepDate": "10.10.2065"
    },
    {
        "engDate": "2009-01-24",
        "nepDate": "11.10.2065"
    },
    {
        "engDate": "2009-01-25",
        "nepDate": "12.10.2065"
    },
    {
        "engDate": "2009-01-26",
        "nepDate": "13.10.2065"
    },
    {
        "engDate": "2009-01-27",
        "nepDate": "14.10.2065"
    },
    {
        "engDate": "2009-01-28",
        "nepDate": "15.10.2065"
    },
    {
        "engDate": "2009-01-29",
        "nepDate": "16.10.2065"
    },
    {
        "engDate": "2009-01-30",
        "nepDate": "17.10.2065"
    },
    {
        "engDate": "2009-01-31",
        "nepDate": "18.10.2065"
    },
    {
        "engDate": "2009-02-01",
        "nepDate": "19.10.2065"
    },
    {
        "engDate": "2009-02-02",
        "nepDate": "20.10.2065"
    },
    {
        "engDate": "2009-02-03",
        "nepDate": "21.10.2065"
    },
    {
        "engDate": "2009-02-04",
        "nepDate": "22.10.2065"
    },
    {
        "engDate": "2009-02-05",
        "nepDate": "23.10.2065"
    },
    {
        "engDate": "2009-02-06",
        "nepDate": "24.10.2065"
    },
    {
        "engDate": "2009-02-07",
        "nepDate": "25.10.2065"
    },
    {
        "engDate": "2009-02-08",
        "nepDate": "26.10.2065"
    },
    {
        "engDate": "2009-02-09",
        "nepDate": "27.10.2065"
    },
    {
        "engDate": "2009-02-10",
        "nepDate": "28.10.2065"
    },
    {
        "engDate": "2009-02-11",
        "nepDate": "29.10.2065"
    },
    {
        "engDate": "2009-02-12",
        "nepDate": "01.11.2065"
    },
    {
        "engDate": "2009-02-13",
        "nepDate": "02.11.2065"
    },
    {
        "engDate": "2009-02-14",
        "nepDate": "03.11.2065"
    },
    {
        "engDate": "2009-02-15",
        "nepDate": "04.11.2065"
    },
    {
        "engDate": "2009-02-16",
        "nepDate": "05.11.2065"
    },
    {
        "engDate": "2009-02-17",
        "nepDate": "06.11.2065"
    },
    {
        "engDate": "2009-02-18",
        "nepDate": "07.11.2065"
    },
    {
        "engDate": "2009-02-19",
        "nepDate": "08.11.2065"
    },
    {
        "engDate": "2009-02-20",
        "nepDate": "09.11.2065"
    },
    {
        "engDate": "2009-02-21",
        "nepDate": "10.11.2065"
    },
    {
        "engDate": "2009-02-22",
        "nepDate": "11.11.2065"
    },
    {
        "engDate": "2009-02-23",
        "nepDate": "12.11.2065"
    },
    {
        "engDate": "2009-02-24",
        "nepDate": "13.11.2065"
    },
    {
        "engDate": "2009-02-25",
        "nepDate": "14.11.2065"
    },
    {
        "engDate": "2009-02-26",
        "nepDate": "15.11.2065"
    },
    {
        "engDate": "2009-02-27",
        "nepDate": "16.11.2065"
    },
    {
        "engDate": "2009-02-28",
        "nepDate": "17.11.2065"
    },
    {
        "engDate": "2009-03-01",
        "nepDate": "18.11.2065"
    },
    {
        "engDate": "2009-03-02",
        "nepDate": "19.11.2065"
    },
    {
        "engDate": "2009-03-03",
        "nepDate": "20.11.2065"
    },
    {
        "engDate": "2009-03-04",
        "nepDate": "21.11.2065"
    },
    {
        "engDate": "2009-03-05",
        "nepDate": "22.11.2065"
    },
    {
        "engDate": "2009-03-06",
        "nepDate": "23.11.2065"
    },
    {
        "engDate": "2009-03-07",
        "nepDate": "24.11.2065"
    },
    {
        "engDate": "2009-03-08",
        "nepDate": "25.11.2065"
    },
    {
        "engDate": "2009-03-09",
        "nepDate": "26.11.2065"
    },
    {
        "engDate": "2009-03-10",
        "nepDate": "27.11.2065"
    },
    {
        "engDate": "2009-03-11",
        "nepDate": "28.11.2065"
    },
    {
        "engDate": "2009-03-12",
        "nepDate": "29.11.2065"
    },
    {
        "engDate": "2009-03-13",
        "nepDate": "30.11.2065"
    },
    {
        "engDate": "2009-03-14",
        "nepDate": "01.12.2065"
    },
    {
        "engDate": "2009-03-15",
        "nepDate": "02.12.2065"
    },
    {
        "engDate": "2009-03-16",
        "nepDate": "03.12.2065"
    },
    {
        "engDate": "2009-03-17",
        "nepDate": "04.12.2065"
    },
    {
        "engDate": "2009-03-18",
        "nepDate": "05.12.2065"
    },
    {
        "engDate": "2009-03-19",
        "nepDate": "06.12.2065"
    },
    {
        "engDate": "2009-03-20",
        "nepDate": "07.12.2065"
    },
    {
        "engDate": "2009-03-21",
        "nepDate": "08.12.2065"
    },
    {
        "engDate": "2009-03-22",
        "nepDate": "09.12.2065"
    },
    {
        "engDate": "2009-03-23",
        "nepDate": "10.12.2065"
    },
    {
        "engDate": "2009-03-24",
        "nepDate": "11.12.2065"
    },
    {
        "engDate": "2009-03-25",
        "nepDate": "12.12.2065"
    },
    {
        "engDate": "2009-03-26",
        "nepDate": "13.12.2065"
    },
    {
        "engDate": "2009-03-27",
        "nepDate": "14.12.2065"
    },
    {
        "engDate": "2009-03-28",
        "nepDate": "15.12.2065"
    },
    {
        "engDate": "2009-03-29",
        "nepDate": "16.12.2065"
    },
    {
        "engDate": "2009-03-30",
        "nepDate": "17.12.2065"
    },
    {
        "engDate": "2009-03-31",
        "nepDate": "18.12.2065"
    },
    {
        "engDate": "2009-04-01",
        "nepDate": "19.12.2065"
    },
    {
        "engDate": "2009-04-02",
        "nepDate": "20.12.2065"
    },
    {
        "engDate": "2009-04-03",
        "nepDate": "21.12.2065"
    },
    {
        "engDate": "2009-04-04",
        "nepDate": "22.12.2065"
    },
    {
        "engDate": "2009-04-05",
        "nepDate": "23.12.2065"
    },
    {
        "engDate": "2009-04-06",
        "nepDate": "24.12.2065"
    },
    {
        "engDate": "2009-04-07",
        "nepDate": "25.12.2065"
    },
    {
        "engDate": "2009-04-08",
        "nepDate": "26.12.2065"
    },
    {
        "engDate": "2009-04-09",
        "nepDate": "27.12.2065"
    },
    {
        "engDate": "2009-04-10",
        "nepDate": "28.12.2065"
    },
    {
        "engDate": "2009-04-11",
        "nepDate": "29.12.2065"
    },
    {
        "engDate": "2009-04-12",
        "nepDate": "30.12.2065"
    },
    {
        "engDate": "2009-04-13",
        "nepDate": "31.12.2065"
    },
    {
        "engDate": "2009-04-14",
        "nepDate": "01.01.2066"
    },
    {
        "engDate": "2009-04-15",
        "nepDate": "02.01.2066"
    },
    {
        "engDate": "2009-04-16",
        "nepDate": "03.01.2066"
    },
    {
        "engDate": "2009-04-17",
        "nepDate": "04.01.2066"
    },
    {
        "engDate": "2009-04-18",
        "nepDate": "05.01.2066"
    },
    {
        "engDate": "2009-04-19",
        "nepDate": "06.01.2066"
    },
    {
        "engDate": "2009-04-20",
        "nepDate": "07.01.2066"
    },
    {
        "engDate": "2009-04-21",
        "nepDate": "08.01.2066"
    },
    {
        "engDate": "2009-04-22",
        "nepDate": "09.01.2066"
    },
    {
        "engDate": "2009-04-23",
        "nepDate": "10.01.2066"
    },
    {
        "engDate": "2009-04-24",
        "nepDate": "11.01.2066"
    },
    {
        "engDate": "2009-04-25",
        "nepDate": "12.01.2066"
    },
    {
        "engDate": "2009-04-26",
        "nepDate": "13.01.2066"
    },
    {
        "engDate": "2009-04-27",
        "nepDate": "14.01.2066"
    },
    {
        "engDate": "2009-04-28",
        "nepDate": "15.01.2066"
    },
    {
        "engDate": "2009-04-29",
        "nepDate": "16.01.2066"
    },
    {
        "engDate": "2009-04-30",
        "nepDate": "17.01.2066"
    },
    {
        "engDate": "2009-05-01",
        "nepDate": "18.01.2066"
    },
    {
        "engDate": "2009-05-02",
        "nepDate": "19.01.2066"
    },
    {
        "engDate": "2009-05-03",
        "nepDate": "20.01.2066"
    },
    {
        "engDate": "2009-05-04",
        "nepDate": "21.01.2066"
    },
    {
        "engDate": "2009-05-05",
        "nepDate": "22.01.2066"
    },
    {
        "engDate": "2009-05-06",
        "nepDate": "23.01.2066"
    },
    {
        "engDate": "2009-05-07",
        "nepDate": "24.01.2066"
    },
    {
        "engDate": "2009-05-08",
        "nepDate": "25.01.2066"
    },
    {
        "engDate": "2009-05-09",
        "nepDate": "26.01.2066"
    },
    {
        "engDate": "2009-05-10",
        "nepDate": "27.01.2066"
    },
    {
        "engDate": "2009-05-11",
        "nepDate": "28.01.2066"
    },
    {
        "engDate": "2009-05-12",
        "nepDate": "29.01.2066"
    },
    {
        "engDate": "2009-05-13",
        "nepDate": "30.01.2066"
    },
    {
        "engDate": "2009-05-14",
        "nepDate": "31.01.2066"
    },
    {
        "engDate": "2009-05-15",
        "nepDate": "01.02.2066"
    },
    {
        "engDate": "2009-05-16",
        "nepDate": "02.02.2066"
    },
    {
        "engDate": "2009-05-17",
        "nepDate": "03.02.2066"
    },
    {
        "engDate": "2009-05-18",
        "nepDate": "04.02.2066"
    },
    {
        "engDate": "2009-05-19",
        "nepDate": "05.02.2066"
    },
    {
        "engDate": "2009-05-20",
        "nepDate": "06.02.2066"
    },
    {
        "engDate": "2009-05-21",
        "nepDate": "07.02.2066"
    },
    {
        "engDate": "2009-05-22",
        "nepDate": "08.02.2066"
    },
    {
        "engDate": "2009-05-23",
        "nepDate": "09.02.2066"
    },
    {
        "engDate": "2009-05-24",
        "nepDate": "10.02.2066"
    },
    {
        "engDate": "2009-05-25",
        "nepDate": "11.02.2066"
    },
    {
        "engDate": "2009-05-26",
        "nepDate": "12.02.2066"
    },
    {
        "engDate": "2009-05-27",
        "nepDate": "13.02.2066"
    },
    {
        "engDate": "2009-05-28",
        "nepDate": "14.02.2066"
    },
    {
        "engDate": "2009-05-29",
        "nepDate": "15.02.2066"
    },
    {
        "engDate": "2009-05-30",
        "nepDate": "16.02.2066"
    },
    {
        "engDate": "2009-05-31",
        "nepDate": "17.02.2066"
    },
    {
        "engDate": "2009-06-01",
        "nepDate": "18.02.2066"
    },
    {
        "engDate": "2009-06-02",
        "nepDate": "19.02.2066"
    },
    {
        "engDate": "2009-06-03",
        "nepDate": "20.02.2066"
    },
    {
        "engDate": "2009-06-04",
        "nepDate": "21.02.2066"
    },
    {
        "engDate": "2009-06-05",
        "nepDate": "22.02.2066"
    },
    {
        "engDate": "2009-06-06",
        "nepDate": "23.02.2066"
    },
    {
        "engDate": "2009-06-07",
        "nepDate": "24.02.2066"
    },
    {
        "engDate": "2009-06-08",
        "nepDate": "25.02.2066"
    },
    {
        "engDate": "2009-06-09",
        "nepDate": "26.02.2066"
    },
    {
        "engDate": "2009-06-10",
        "nepDate": "27.02.2066"
    },
    {
        "engDate": "2009-06-11",
        "nepDate": "28.02.2066"
    },
    {
        "engDate": "2009-06-12",
        "nepDate": "29.02.2066"
    },
    {
        "engDate": "2009-06-13",
        "nepDate": "30.02.2066"
    },
    {
        "engDate": "2009-06-14",
        "nepDate": "31.02.2066"
    },
    {
        "engDate": "2009-06-15",
        "nepDate": "01.03.2066"
    },
    {
        "engDate": "2009-06-16",
        "nepDate": "02.03.2066"
    },
    {
        "engDate": "2009-06-17",
        "nepDate": "03.03.2066"
    },
    {
        "engDate": "2009-06-18",
        "nepDate": "04.03.2066"
    },
    {
        "engDate": "2009-06-19",
        "nepDate": "05.03.2066"
    },
    {
        "engDate": "2009-06-20",
        "nepDate": "06.03.2066"
    },
    {
        "engDate": "2009-06-21",
        "nepDate": "07.03.2066"
    },
    {
        "engDate": "2009-06-22",
        "nepDate": "08.03.2066"
    },
    {
        "engDate": "2009-06-23",
        "nepDate": "09.03.2066"
    },
    {
        "engDate": "2009-06-24",
        "nepDate": "10.03.2066"
    },
    {
        "engDate": "2009-06-25",
        "nepDate": "11.03.2066"
    },
    {
        "engDate": "2009-06-26",
        "nepDate": "12.03.2066"
    },
    {
        "engDate": "2009-06-27",
        "nepDate": "13.03.2066"
    },
    {
        "engDate": "2009-06-28",
        "nepDate": "14.03.2066"
    },
    {
        "engDate": "2009-06-29",
        "nepDate": "15.03.2066"
    },
    {
        "engDate": "2009-06-30",
        "nepDate": "16.03.2066"
    },
    {
        "engDate": "2009-07-01",
        "nepDate": "17.03.2066"
    },
    {
        "engDate": "2009-07-02",
        "nepDate": "18.03.2066"
    },
    {
        "engDate": "2009-07-03",
        "nepDate": "19.03.2066"
    },
    {
        "engDate": "2009-07-04",
        "nepDate": "20.03.2066"
    },
    {
        "engDate": "2009-07-05",
        "nepDate": "21.03.2066"
    },
    {
        "engDate": "2009-07-06",
        "nepDate": "22.03.2066"
    },
    {
        "engDate": "2009-07-07",
        "nepDate": "23.03.2066"
    },
    {
        "engDate": "2009-07-08",
        "nepDate": "24.03.2066"
    },
    {
        "engDate": "2009-07-09",
        "nepDate": "25.03.2066"
    },
    {
        "engDate": "2009-07-10",
        "nepDate": "26.03.2066"
    },
    {
        "engDate": "2009-07-11",
        "nepDate": "27.03.2066"
    },
    {
        "engDate": "2009-07-12",
        "nepDate": "28.03.2066"
    },
    {
        "engDate": "2009-07-13",
        "nepDate": "29.03.2066"
    },
    {
        "engDate": "2009-07-14",
        "nepDate": "30.03.2066"
    },
    {
        "engDate": "2009-07-15",
        "nepDate": "31.03.2066"
    },
    {
        "engDate": "2009-07-16",
        "nepDate": "01.04.2066"
    },
    {
        "engDate": "2009-07-17",
        "nepDate": "02.04.2066"
    },
    {
        "engDate": "2009-07-18",
        "nepDate": "03.04.2066"
    },
    {
        "engDate": "2009-07-19",
        "nepDate": "04.04.2066"
    },
    {
        "engDate": "2009-07-20",
        "nepDate": "05.04.2066"
    },
    {
        "engDate": "2009-07-21",
        "nepDate": "06.04.2066"
    },
    {
        "engDate": "2009-07-22",
        "nepDate": "07.04.2066"
    },
    {
        "engDate": "2009-07-23",
        "nepDate": "08.04.2066"
    },
    {
        "engDate": "2009-07-24",
        "nepDate": "09.04.2066"
    },
    {
        "engDate": "2009-07-25",
        "nepDate": "10.04.2066"
    },
    {
        "engDate": "2009-07-26",
        "nepDate": "11.04.2066"
    },
    {
        "engDate": "2009-07-27",
        "nepDate": "12.04.2066"
    },
    {
        "engDate": "2009-07-28",
        "nepDate": "13.04.2066"
    },
    {
        "engDate": "2009-07-29",
        "nepDate": "14.04.2066"
    },
    {
        "engDate": "2009-07-30",
        "nepDate": "15.04.2066"
    },
    {
        "engDate": "2009-07-31",
        "nepDate": "16.04.2066"
    },
    {
        "engDate": "2009-08-01",
        "nepDate": "17.04.2066"
    },
    {
        "engDate": "2009-08-02",
        "nepDate": "18.04.2066"
    },
    {
        "engDate": "2009-08-03",
        "nepDate": "19.04.2066"
    },
    {
        "engDate": "2009-08-04",
        "nepDate": "20.04.2066"
    },
    {
        "engDate": "2009-08-05",
        "nepDate": "21.04.2066"
    },
    {
        "engDate": "2009-08-06",
        "nepDate": "22.04.2066"
    },
    {
        "engDate": "2009-08-07",
        "nepDate": "23.04.2066"
    },
    {
        "engDate": "2009-08-08",
        "nepDate": "24.04.2066"
    },
    {
        "engDate": "2009-08-09",
        "nepDate": "25.04.2066"
    },
    {
        "engDate": "2009-08-10",
        "nepDate": "26.04.2066"
    },
    {
        "engDate": "2009-08-11",
        "nepDate": "27.04.2066"
    },
    {
        "engDate": "2009-08-12",
        "nepDate": "28.04.2066"
    },
    {
        "engDate": "2009-08-13",
        "nepDate": "29.04.2066"
    },
    {
        "engDate": "2009-08-14",
        "nepDate": "30.04.2066"
    },
    {
        "engDate": "2009-08-15",
        "nepDate": "31.04.2066"
    },
    {
        "engDate": "2009-08-16",
        "nepDate": "32.04.2066"
    },
    {
        "engDate": "2009-08-17",
        "nepDate": "01.05.2066"
    },
    {
        "engDate": "2009-08-18",
        "nepDate": "02.05.2066"
    },
    {
        "engDate": "2009-08-19",
        "nepDate": "03.05.2066"
    },
    {
        "engDate": "2009-08-20",
        "nepDate": "04.05.2066"
    },
    {
        "engDate": "2009-08-21",
        "nepDate": "05.05.2066"
    },
    {
        "engDate": "2009-08-22",
        "nepDate": "06.05.2066"
    },
    {
        "engDate": "2009-08-23",
        "nepDate": "07.05.2066"
    },
    {
        "engDate": "2009-08-24",
        "nepDate": "08.05.2066"
    },
    {
        "engDate": "2009-08-25",
        "nepDate": "09.05.2066"
    },
    {
        "engDate": "2009-08-26",
        "nepDate": "10.05.2066"
    },
    {
        "engDate": "2009-08-27",
        "nepDate": "11.05.2066"
    },
    {
        "engDate": "2009-08-28",
        "nepDate": "12.05.2066"
    },
    {
        "engDate": "2009-08-29",
        "nepDate": "13.05.2066"
    },
    {
        "engDate": "2009-08-30",
        "nepDate": "14.05.2066"
    },
    {
        "engDate": "2009-08-31",
        "nepDate": "15.05.2066"
    },
    {
        "engDate": "2009-09-01",
        "nepDate": "16.05.2066"
    },
    {
        "engDate": "2009-09-02",
        "nepDate": "17.05.2066"
    },
    {
        "engDate": "2009-09-03",
        "nepDate": "18.05.2066"
    },
    {
        "engDate": "2009-09-04",
        "nepDate": "19.05.2066"
    },
    {
        "engDate": "2009-09-05",
        "nepDate": "20.05.2066"
    },
    {
        "engDate": "2009-09-06",
        "nepDate": "21.05.2066"
    },
    {
        "engDate": "2009-09-07",
        "nepDate": "22.05.2066"
    },
    {
        "engDate": "2009-09-08",
        "nepDate": "23.05.2066"
    },
    {
        "engDate": "2009-09-09",
        "nepDate": "24.05.2066"
    },
    {
        "engDate": "2009-09-10",
        "nepDate": "25.05.2066"
    },
    {
        "engDate": "2009-09-11",
        "nepDate": "26.05.2066"
    },
    {
        "engDate": "2009-09-12",
        "nepDate": "27.05.2066"
    },
    {
        "engDate": "2009-09-13",
        "nepDate": "28.05.2066"
    },
    {
        "engDate": "2009-09-14",
        "nepDate": "29.05.2066"
    },
    {
        "engDate": "2009-09-15",
        "nepDate": "30.05.2066"
    },
    {
        "engDate": "2009-09-16",
        "nepDate": "31.05.2066"
    },
    {
        "engDate": "2009-09-17",
        "nepDate": "01.06.2066"
    },
    {
        "engDate": "2009-09-18",
        "nepDate": "02.06.2066"
    },
    {
        "engDate": "2009-09-19",
        "nepDate": "03.06.2066"
    },
    {
        "engDate": "2009-09-20",
        "nepDate": "04.06.2066"
    },
    {
        "engDate": "2009-09-21",
        "nepDate": "05.06.2066"
    },
    {
        "engDate": "2009-09-22",
        "nepDate": "06.06.2066"
    },
    {
        "engDate": "2009-09-23",
        "nepDate": "07.06.2066"
    },
    {
        "engDate": "2009-09-24",
        "nepDate": "08.06.2066"
    },
    {
        "engDate": "2009-09-25",
        "nepDate": "09.06.2066"
    },
    {
        "engDate": "2009-09-26",
        "nepDate": "10.06.2066"
    },
    {
        "engDate": "2009-09-27",
        "nepDate": "11.06.2066"
    },
    {
        "engDate": "2009-09-28",
        "nepDate": "12.06.2066"
    },
    {
        "engDate": "2009-09-29",
        "nepDate": "13.06.2066"
    },
    {
        "engDate": "2009-09-30",
        "nepDate": "14.06.2066"
    },
    {
        "engDate": "2009-10-01",
        "nepDate": "15.06.2066"
    },
    {
        "engDate": "2009-10-02",
        "nepDate": "16.06.2066"
    },
    {
        "engDate": "2009-10-03",
        "nepDate": "17.06.2066"
    },
    {
        "engDate": "2009-10-04",
        "nepDate": "18.06.2066"
    },
    {
        "engDate": "2009-10-05",
        "nepDate": "19.06.2066"
    },
    {
        "engDate": "2009-10-06",
        "nepDate": "20.06.2066"
    },
    {
        "engDate": "2009-10-07",
        "nepDate": "21.06.2066"
    },
    {
        "engDate": "2009-10-08",
        "nepDate": "22.06.2066"
    },
    {
        "engDate": "2009-10-09",
        "nepDate": "23.06.2066"
    },
    {
        "engDate": "2009-10-10",
        "nepDate": "24.06.2066"
    },
    {
        "engDate": "2009-10-11",
        "nepDate": "25.06.2066"
    },
    {
        "engDate": "2009-10-12",
        "nepDate": "26.06.2066"
    },
    {
        "engDate": "2009-10-13",
        "nepDate": "27.06.2066"
    },
    {
        "engDate": "2009-10-14",
        "nepDate": "28.06.2066"
    },
    {
        "engDate": "2009-10-15",
        "nepDate": "29.06.2066"
    },
    {
        "engDate": "2009-10-16",
        "nepDate": "30.06.2066"
    },
    {
        "engDate": "2009-10-17",
        "nepDate": "31.06.2066"
    },
    {
        "engDate": "2009-10-18",
        "nepDate": "01.07.2066"
    },
    {
        "engDate": "2009-10-19",
        "nepDate": "02.07.2066"
    },
    {
        "engDate": "2009-10-20",
        "nepDate": "03.07.2066"
    },
    {
        "engDate": "2009-10-21",
        "nepDate": "04.07.2066"
    },
    {
        "engDate": "2009-10-22",
        "nepDate": "05.07.2066"
    },
    {
        "engDate": "2009-10-23",
        "nepDate": "06.07.2066"
    },
    {
        "engDate": "2009-10-24",
        "nepDate": "07.07.2066"
    },
    {
        "engDate": "2009-10-25",
        "nepDate": "08.07.2066"
    },
    {
        "engDate": "2009-10-26",
        "nepDate": "09.07.2066"
    },
    {
        "engDate": "2009-10-27",
        "nepDate": "10.07.2066"
    },
    {
        "engDate": "2009-10-28",
        "nepDate": "11.07.2066"
    },
    {
        "engDate": "2009-10-29",
        "nepDate": "12.07.2066"
    },
    {
        "engDate": "2009-10-30",
        "nepDate": "13.07.2066"
    },
    {
        "engDate": "2009-10-31",
        "nepDate": "14.07.2066"
    },
    {
        "engDate": "2009-11-01",
        "nepDate": "15.07.2066"
    },
    {
        "engDate": "2009-11-02",
        "nepDate": "16.07.2066"
    },
    {
        "engDate": "2009-11-03",
        "nepDate": "17.07.2066"
    },
    {
        "engDate": "2009-11-04",
        "nepDate": "18.07.2066"
    },
    {
        "engDate": "2009-11-05",
        "nepDate": "19.07.2066"
    },
    {
        "engDate": "2009-11-06",
        "nepDate": "20.07.2066"
    },
    {
        "engDate": "2009-11-07",
        "nepDate": "21.07.2066"
    },
    {
        "engDate": "2009-11-08",
        "nepDate": "22.07.2066"
    },
    {
        "engDate": "2009-11-09",
        "nepDate": "23.07.2066"
    },
    {
        "engDate": "2009-11-10",
        "nepDate": "24.07.2066"
    },
    {
        "engDate": "2009-11-11",
        "nepDate": "25.07.2066"
    },
    {
        "engDate": "2009-11-12",
        "nepDate": "26.07.2066"
    },
    {
        "engDate": "2009-11-13",
        "nepDate": "27.07.2066"
    },
    {
        "engDate": "2009-11-14",
        "nepDate": "28.07.2066"
    },
    {
        "engDate": "2009-11-15",
        "nepDate": "29.07.2066"
    },
    {
        "engDate": "2009-11-16",
        "nepDate": "01.08.2066"
    },
    {
        "engDate": "2009-11-17",
        "nepDate": "02.08.2066"
    },
    {
        "engDate": "2009-11-18",
        "nepDate": "03.08.2066"
    },
    {
        "engDate": "2009-11-19",
        "nepDate": "04.08.2066"
    },
    {
        "engDate": "2009-11-20",
        "nepDate": "05.08.2066"
    },
    {
        "engDate": "2009-11-21",
        "nepDate": "06.08.2066"
    },
    {
        "engDate": "2009-11-22",
        "nepDate": "07.08.2066"
    },
    {
        "engDate": "2009-11-23",
        "nepDate": "08.08.2066"
    },
    {
        "engDate": "2009-11-24",
        "nepDate": "09.08.2066"
    },
    {
        "engDate": "2009-11-25",
        "nepDate": "10.08.2066"
    },
    {
        "engDate": "2009-11-26",
        "nepDate": "11.08.2066"
    },
    {
        "engDate": "2009-11-27",
        "nepDate": "12.08.2066"
    },
    {
        "engDate": "2009-11-28",
        "nepDate": "13.08.2066"
    },
    {
        "engDate": "2009-11-29",
        "nepDate": "14.08.2066"
    },
    {
        "engDate": "2009-11-30",
        "nepDate": "15.08.2066"
    },
    {
        "engDate": "2009-12-01",
        "nepDate": "16.08.2066"
    },
    {
        "engDate": "2009-12-02",
        "nepDate": "17.08.2066"
    },
    {
        "engDate": "2009-12-03",
        "nepDate": "18.08.2066"
    },
    {
        "engDate": "2009-12-04",
        "nepDate": "19.08.2066"
    },
    {
        "engDate": "2009-12-05",
        "nepDate": "20.08.2066"
    },
    {
        "engDate": "2009-12-06",
        "nepDate": "21.08.2066"
    },
    {
        "engDate": "2009-12-07",
        "nepDate": "22.08.2066"
    },
    {
        "engDate": "2009-12-08",
        "nepDate": "23.08.2066"
    },
    {
        "engDate": "2009-12-09",
        "nepDate": "24.08.2066"
    },
    {
        "engDate": "2009-12-10",
        "nepDate": "25.08.2066"
    },
    {
        "engDate": "2009-12-11",
        "nepDate": "26.08.2066"
    },
    {
        "engDate": "2009-12-12",
        "nepDate": "27.08.2066"
    },
    {
        "engDate": "2009-12-13",
        "nepDate": "28.08.2066"
    },
    {
        "engDate": "2009-12-14",
        "nepDate": "29.08.2066"
    },
    {
        "engDate": "2009-12-15",
        "nepDate": "30.08.2066"
    },
    {
        "engDate": "2009-12-16",
        "nepDate": "01.09.2066"
    },
    {
        "engDate": "2009-12-17",
        "nepDate": "02.09.2066"
    },
    {
        "engDate": "2009-12-18",
        "nepDate": "03.09.2066"
    },
    {
        "engDate": "2009-12-19",
        "nepDate": "04.09.2066"
    },
    {
        "engDate": "2009-12-20",
        "nepDate": "05.09.2066"
    },
    {
        "engDate": "2009-12-21",
        "nepDate": "06.09.2066"
    },
    {
        "engDate": "2009-12-22",
        "nepDate": "07.09.2066"
    },
    {
        "engDate": "2009-12-23",
        "nepDate": "08.09.2066"
    },
    {
        "engDate": "2009-12-24",
        "nepDate": "09.09.2066"
    },
    {
        "engDate": "2009-12-25",
        "nepDate": "10.09.2066"
    },
    {
        "engDate": "2009-12-26",
        "nepDate": "11.09.2066"
    },
    {
        "engDate": "2009-12-27",
        "nepDate": "12.09.2066"
    },
    {
        "engDate": "2009-12-28",
        "nepDate": "13.09.2066"
    },
    {
        "engDate": "2009-12-29",
        "nepDate": "14.09.2066"
    },
    {
        "engDate": "2009-12-30",
        "nepDate": "15.09.2066"
    },
    {
        "engDate": "2009-12-31",
        "nepDate": "16.09.2066"
    },
    {
        "engDate": "2010-01-01",
        "nepDate": "17.09.2066"
    },
    {
        "engDate": "2010-01-02",
        "nepDate": "18.09.2066"
    },
    {
        "engDate": "2010-01-03",
        "nepDate": "19.09.2066"
    },
    {
        "engDate": "2010-01-04",
        "nepDate": "20.09.2066"
    },
    {
        "engDate": "2010-01-05",
        "nepDate": "21.09.2066"
    },
    {
        "engDate": "2010-01-06",
        "nepDate": "22.09.2066"
    },
    {
        "engDate": "2010-01-07",
        "nepDate": "23.09.2066"
    },
    {
        "engDate": "2010-01-08",
        "nepDate": "24.09.2066"
    },
    {
        "engDate": "2010-01-09",
        "nepDate": "25.09.2066"
    },
    {
        "engDate": "2010-01-10",
        "nepDate": "26.09.2066"
    },
    {
        "engDate": "2010-01-11",
        "nepDate": "27.09.2066"
    },
    {
        "engDate": "2010-01-12",
        "nepDate": "28.09.2066"
    },
    {
        "engDate": "2010-01-13",
        "nepDate": "29.09.2066"
    },
    {
        "engDate": "2010-01-14",
        "nepDate": "30.09.2066"
    },
    {
        "engDate": "2010-01-15",
        "nepDate": "01.10.2066"
    },
    {
        "engDate": "2010-01-16",
        "nepDate": "02.10.2066"
    },
    {
        "engDate": "2010-01-17",
        "nepDate": "03.10.2066"
    },
    {
        "engDate": "2010-01-18",
        "nepDate": "04.10.2066"
    },
    {
        "engDate": "2010-01-19",
        "nepDate": "05.10.2066"
    },
    {
        "engDate": "2010-01-20",
        "nepDate": "06.10.2066"
    },
    {
        "engDate": "2010-01-21",
        "nepDate": "07.10.2066"
    },
    {
        "engDate": "2010-01-22",
        "nepDate": "08.10.2066"
    },
    {
        "engDate": "2010-01-23",
        "nepDate": "09.10.2066"
    },
    {
        "engDate": "2010-01-24",
        "nepDate": "10.10.2066"
    },
    {
        "engDate": "2010-01-25",
        "nepDate": "11.10.2066"
    },
    {
        "engDate": "2010-01-26",
        "nepDate": "12.10.2066"
    },
    {
        "engDate": "2010-01-27",
        "nepDate": "13.10.2066"
    },
    {
        "engDate": "2010-01-28",
        "nepDate": "14.10.2066"
    },
    {
        "engDate": "2010-01-29",
        "nepDate": "15.10.2066"
    },
    {
        "engDate": "2010-01-30",
        "nepDate": "16.10.2066"
    },
    {
        "engDate": "2010-01-31",
        "nepDate": "17.10.2066"
    },
    {
        "engDate": "2010-02-01",
        "nepDate": "18.10.2066"
    },
    {
        "engDate": "2010-02-02",
        "nepDate": "19.10.2066"
    },
    {
        "engDate": "2010-02-03",
        "nepDate": "20.10.2066"
    },
    {
        "engDate": "2010-02-04",
        "nepDate": "21.10.2066"
    },
    {
        "engDate": "2010-02-05",
        "nepDate": "22.10.2066"
    },
    {
        "engDate": "2010-02-06",
        "nepDate": "23.10.2066"
    },
    {
        "engDate": "2010-02-07",
        "nepDate": "24.10.2066"
    },
    {
        "engDate": "2010-02-08",
        "nepDate": "25.10.2066"
    },
    {
        "engDate": "2010-02-09",
        "nepDate": "26.10.2066"
    },
    {
        "engDate": "2010-02-10",
        "nepDate": "27.10.2066"
    },
    {
        "engDate": "2010-02-11",
        "nepDate": "28.10.2066"
    },
    {
        "engDate": "2010-02-12",
        "nepDate": "29.10.2066"
    },
    {
        "engDate": "2010-02-13",
        "nepDate": "01.11.2066"
    },
    {
        "engDate": "2010-02-14",
        "nepDate": "02.11.2066"
    },
    {
        "engDate": "2010-02-15",
        "nepDate": "03.11.2066"
    },
    {
        "engDate": "2010-02-16",
        "nepDate": "04.11.2066"
    },
    {
        "engDate": "2010-02-17",
        "nepDate": "05.11.2066"
    },
    {
        "engDate": "2010-02-18",
        "nepDate": "06.11.2066"
    },
    {
        "engDate": "2010-02-19",
        "nepDate": "07.11.2066"
    },
    {
        "engDate": "2010-02-20",
        "nepDate": "08.11.2066"
    },
    {
        "engDate": "2010-02-21",
        "nepDate": "09.11.2066"
    },
    {
        "engDate": "2010-02-22",
        "nepDate": "10.11.2066"
    },
    {
        "engDate": "2010-02-23",
        "nepDate": "11.11.2066"
    },
    {
        "engDate": "2010-02-24",
        "nepDate": "12.11.2066"
    },
    {
        "engDate": "2010-02-25",
        "nepDate": "13.11.2066"
    },
    {
        "engDate": "2010-02-26",
        "nepDate": "14.11.2066"
    },
    {
        "engDate": "2010-02-27",
        "nepDate": "15.11.2066"
    },
    {
        "engDate": "2010-02-28",
        "nepDate": "16.11.2066"
    },
    {
        "engDate": "2010-03-01",
        "nepDate": "17.11.2066"
    },
    {
        "engDate": "2010-03-02",
        "nepDate": "18.11.2066"
    },
    {
        "engDate": "2010-03-03",
        "nepDate": "19.11.2066"
    },
    {
        "engDate": "2010-03-04",
        "nepDate": "20.11.2066"
    },
    {
        "engDate": "2010-03-05",
        "nepDate": "21.11.2066"
    },
    {
        "engDate": "2010-03-06",
        "nepDate": "22.11.2066"
    },
    {
        "engDate": "2010-03-07",
        "nepDate": "23.11.2066"
    },
    {
        "engDate": "2010-03-08",
        "nepDate": "24.11.2066"
    },
    {
        "engDate": "2010-03-09",
        "nepDate": "25.11.2066"
    },
    {
        "engDate": "2010-03-10",
        "nepDate": "26.11.2066"
    },
    {
        "engDate": "2010-03-11",
        "nepDate": "27.11.2066"
    },
    {
        "engDate": "2010-03-12",
        "nepDate": "28.11.2066"
    },
    {
        "engDate": "2010-03-13",
        "nepDate": "29.11.2066"
    },
    {
        "engDate": "2010-03-14",
        "nepDate": "01.12.2066"
    },
    {
        "engDate": "2010-03-15",
        "nepDate": "02.12.2066"
    },
    {
        "engDate": "2010-03-16",
        "nepDate": "03.12.2066"
    },
    {
        "engDate": "2010-03-17",
        "nepDate": "04.12.2066"
    },
    {
        "engDate": "2010-03-18",
        "nepDate": "05.12.2066"
    },
    {
        "engDate": "2010-03-19",
        "nepDate": "06.12.2066"
    },
    {
        "engDate": "2010-03-20",
        "nepDate": "07.12.2066"
    },
    {
        "engDate": "2010-03-21",
        "nepDate": "08.12.2066"
    },
    {
        "engDate": "2010-03-22",
        "nepDate": "09.12.2066"
    },
    {
        "engDate": "2010-03-23",
        "nepDate": "10.12.2066"
    },
    {
        "engDate": "2010-03-24",
        "nepDate": "11.12.2066"
    },
    {
        "engDate": "2010-03-25",
        "nepDate": "12.12.2066"
    },
    {
        "engDate": "2010-03-26",
        "nepDate": "13.12.2066"
    },
    {
        "engDate": "2010-03-27",
        "nepDate": "14.12.2066"
    },
    {
        "engDate": "2010-03-28",
        "nepDate": "15.12.2066"
    },
    {
        "engDate": "2010-03-29",
        "nepDate": "16.12.2066"
    },
    {
        "engDate": "2010-03-30",
        "nepDate": "17.12.2066"
    },
    {
        "engDate": "2010-03-31",
        "nepDate": "18.12.2066"
    },
    {
        "engDate": "2010-04-01",
        "nepDate": "19.12.2066"
    },
    {
        "engDate": "2010-04-02",
        "nepDate": "20.12.2066"
    },
    {
        "engDate": "2010-04-03",
        "nepDate": "21.12.2066"
    },
    {
        "engDate": "2010-04-04",
        "nepDate": "22.12.2066"
    },
    {
        "engDate": "2010-04-05",
        "nepDate": "23.12.2066"
    },
    {
        "engDate": "2010-04-06",
        "nepDate": "24.12.2066"
    },
    {
        "engDate": "2010-04-07",
        "nepDate": "25.12.2066"
    },
    {
        "engDate": "2010-04-08",
        "nepDate": "26.12.2066"
    },
    {
        "engDate": "2010-04-09",
        "nepDate": "27.12.2066"
    },
    {
        "engDate": "2010-04-10",
        "nepDate": "28.12.2066"
    },
    {
        "engDate": "2010-04-11",
        "nepDate": "29.12.2066"
    },
    {
        "engDate": "2010-04-12",
        "nepDate": "30.12.2066"
    },
    {
        "engDate": "2010-04-13",
        "nepDate": "31.12.2066"
    },
    {
        "engDate": "2010-04-14",
        "nepDate": "01.01.2067"
    },
    {
        "engDate": "2010-04-15",
        "nepDate": "02.01.2067"
    },
    {
        "engDate": "2010-04-16",
        "nepDate": "03.01.2067"
    },
    {
        "engDate": "2010-04-17",
        "nepDate": "04.01.2067"
    },
    {
        "engDate": "2010-04-18",
        "nepDate": "05.01.2067"
    },
    {
        "engDate": "2010-04-19",
        "nepDate": "06.01.2067"
    },
    {
        "engDate": "2010-04-20",
        "nepDate": "07.01.2067"
    },
    {
        "engDate": "2010-04-21",
        "nepDate": "08.01.2067"
    },
    {
        "engDate": "2010-04-22",
        "nepDate": "09.01.2067"
    },
    {
        "engDate": "2010-04-23",
        "nepDate": "10.01.2067"
    },
    {
        "engDate": "2010-04-24",
        "nepDate": "11.01.2067"
    },
    {
        "engDate": "2010-04-25",
        "nepDate": "12.01.2067"
    },
    {
        "engDate": "2010-04-26",
        "nepDate": "13.01.2067"
    },
    {
        "engDate": "2010-04-27",
        "nepDate": "14.01.2067"
    },
    {
        "engDate": "2010-04-28",
        "nepDate": "15.01.2067"
    },
    {
        "engDate": "2010-04-29",
        "nepDate": "16.01.2067"
    },
    {
        "engDate": "2010-04-30",
        "nepDate": "17.01.2067"
    },
    {
        "engDate": "2010-05-01",
        "nepDate": "18.01.2067"
    },
    {
        "engDate": "2010-05-02",
        "nepDate": "19.01.2067"
    },
    {
        "engDate": "2010-05-03",
        "nepDate": "20.01.2067"
    },
    {
        "engDate": "2010-05-04",
        "nepDate": "21.01.2067"
    },
    {
        "engDate": "2010-05-05",
        "nepDate": "22.01.2067"
    },
    {
        "engDate": "2010-05-06",
        "nepDate": "23.01.2067"
    },
    {
        "engDate": "2010-05-07",
        "nepDate": "24.01.2067"
    },
    {
        "engDate": "2010-05-08",
        "nepDate": "25.01.2067"
    },
    {
        "engDate": "2010-05-09",
        "nepDate": "26.01.2067"
    },
    {
        "engDate": "2010-05-10",
        "nepDate": "27.01.2067"
    },
    {
        "engDate": "2010-05-11",
        "nepDate": "28.01.2067"
    },
    {
        "engDate": "2010-05-12",
        "nepDate": "29.01.2067"
    },
    {
        "engDate": "2010-05-13",
        "nepDate": "30.01.2067"
    },
    {
        "engDate": "2010-05-14",
        "nepDate": "31.01.2067"
    },
    {
        "engDate": "2010-05-15",
        "nepDate": "01.02.2067"
    },
    {
        "engDate": "2010-05-16",
        "nepDate": "02.02.2067"
    },
    {
        "engDate": "2010-05-17",
        "nepDate": "03.02.2067"
    },
    {
        "engDate": "2010-05-18",
        "nepDate": "04.02.2067"
    },
    {
        "engDate": "2010-05-19",
        "nepDate": "05.02.2067"
    },
    {
        "engDate": "2010-05-20",
        "nepDate": "06.02.2067"
    },
    {
        "engDate": "2010-05-21",
        "nepDate": "07.02.2067"
    },
    {
        "engDate": "2010-05-22",
        "nepDate": "08.02.2067"
    },
    {
        "engDate": "2010-05-23",
        "nepDate": "09.02.2067"
    },
    {
        "engDate": "2010-05-24",
        "nepDate": "10.02.2067"
    },
    {
        "engDate": "2010-05-25",
        "nepDate": "11.02.2067"
    },
    {
        "engDate": "2010-05-26",
        "nepDate": "12.02.2067"
    },
    {
        "engDate": "2010-05-27",
        "nepDate": "13.02.2067"
    },
    {
        "engDate": "2010-05-28",
        "nepDate": "14.02.2067"
    },
    {
        "engDate": "2010-05-29",
        "nepDate": "15.02.2067"
    },
    {
        "engDate": "2010-05-30",
        "nepDate": "16.02.2067"
    },
    {
        "engDate": "2010-05-31",
        "nepDate": "17.02.2067"
    },
    {
        "engDate": "2010-06-01",
        "nepDate": "18.02.2067"
    },
    {
        "engDate": "2010-06-02",
        "nepDate": "19.02.2067"
    },
    {
        "engDate": "2010-06-03",
        "nepDate": "20.02.2067"
    },
    {
        "engDate": "2010-06-04",
        "nepDate": "21.02.2067"
    },
    {
        "engDate": "2010-06-05",
        "nepDate": "22.02.2067"
    },
    {
        "engDate": "2010-06-06",
        "nepDate": "23.02.2067"
    },
    {
        "engDate": "2010-06-07",
        "nepDate": "24.02.2067"
    },
    {
        "engDate": "2010-06-08",
        "nepDate": "25.02.2067"
    },
    {
        "engDate": "2010-06-09",
        "nepDate": "26.02.2067"
    },
    {
        "engDate": "2010-06-10",
        "nepDate": "27.02.2067"
    },
    {
        "engDate": "2010-06-11",
        "nepDate": "28.02.2067"
    },
    {
        "engDate": "2010-06-12",
        "nepDate": "29.02.2067"
    },
    {
        "engDate": "2010-06-13",
        "nepDate": "30.02.2067"
    },
    {
        "engDate": "2010-06-14",
        "nepDate": "31.02.2067"
    },
    {
        "engDate": "2010-06-15",
        "nepDate": "01.03.2067"
    },
    {
        "engDate": "2010-06-16",
        "nepDate": "02.03.2067"
    },
    {
        "engDate": "2010-06-17",
        "nepDate": "03.03.2067"
    },
    {
        "engDate": "2010-06-18",
        "nepDate": "04.03.2067"
    },
    {
        "engDate": "2010-06-19",
        "nepDate": "05.03.2067"
    },
    {
        "engDate": "2010-06-20",
        "nepDate": "06.03.2067"
    },
    {
        "engDate": "2010-06-21",
        "nepDate": "07.03.2067"
    },
    {
        "engDate": "2010-06-22",
        "nepDate": "08.03.2067"
    },
    {
        "engDate": "2010-06-23",
        "nepDate": "09.03.2067"
    },
    {
        "engDate": "2010-06-24",
        "nepDate": "10.03.2067"
    },
    {
        "engDate": "2010-06-25",
        "nepDate": "11.03.2067"
    },
    {
        "engDate": "2010-06-26",
        "nepDate": "12.03.2067"
    },
    {
        "engDate": "2010-06-27",
        "nepDate": "13.03.2067"
    },
    {
        "engDate": "2010-06-28",
        "nepDate": "14.03.2067"
    },
    {
        "engDate": "2010-06-29",
        "nepDate": "15.03.2067"
    },
    {
        "engDate": "2010-06-30",
        "nepDate": "16.03.2067"
    },
    {
        "engDate": "2010-07-01",
        "nepDate": "17.03.2067"
    },
    {
        "engDate": "2010-07-02",
        "nepDate": "18.03.2067"
    },
    {
        "engDate": "2010-07-03",
        "nepDate": "19.03.2067"
    },
    {
        "engDate": "2010-07-04",
        "nepDate": "20.03.2067"
    },
    {
        "engDate": "2010-07-05",
        "nepDate": "21.03.2067"
    },
    {
        "engDate": "2010-07-06",
        "nepDate": "22.03.2067"
    },
    {
        "engDate": "2010-07-07",
        "nepDate": "23.03.2067"
    },
    {
        "engDate": "2010-07-08",
        "nepDate": "24.03.2067"
    },
    {
        "engDate": "2010-07-09",
        "nepDate": "25.03.2067"
    },
    {
        "engDate": "2010-07-10",
        "nepDate": "26.03.2067"
    },
    {
        "engDate": "2010-07-11",
        "nepDate": "27.03.2067"
    },
    {
        "engDate": "2010-07-12",
        "nepDate": "28.03.2067"
    },
    {
        "engDate": "2010-07-13",
        "nepDate": "29.03.2067"
    },
    {
        "engDate": "2010-07-14",
        "nepDate": "30.03.2067"
    },
    {
        "engDate": "2010-07-15",
        "nepDate": "31.03.2067"
    },
    {
        "engDate": "2010-07-16",
        "nepDate": "32.03.2067"
    },
    {
        "engDate": "2010-07-17",
        "nepDate": "01.04.2067"
    },
    {
        "engDate": "2010-07-18",
        "nepDate": "02.04.2067"
    },
    {
        "engDate": "2010-07-19",
        "nepDate": "03.04.2067"
    },
    {
        "engDate": "2010-07-20",
        "nepDate": "04.04.2067"
    },
    {
        "engDate": "2010-07-21",
        "nepDate": "05.04.2067"
    },
    {
        "engDate": "2010-07-22",
        "nepDate": "06.04.2067"
    },
    {
        "engDate": "2010-07-23",
        "nepDate": "07.04.2067"
    },
    {
        "engDate": "2010-07-24",
        "nepDate": "08.04.2067"
    },
    {
        "engDate": "2010-07-25",
        "nepDate": "09.04.2067"
    },
    {
        "engDate": "2010-07-26",
        "nepDate": "10.04.2067"
    },
    {
        "engDate": "2010-07-27",
        "nepDate": "11.04.2067"
    },
    {
        "engDate": "2010-07-28",
        "nepDate": "12.04.2067"
    },
    {
        "engDate": "2010-07-29",
        "nepDate": "13.04.2067"
    },
    {
        "engDate": "2010-07-30",
        "nepDate": "14.04.2067"
    },
    {
        "engDate": "2010-07-31",
        "nepDate": "15.04.2067"
    },
    {
        "engDate": "2010-08-01",
        "nepDate": "16.04.2067"
    },
    {
        "engDate": "2010-08-02",
        "nepDate": "17.04.2067"
    },
    {
        "engDate": "2010-08-03",
        "nepDate": "18.04.2067"
    },
    {
        "engDate": "2010-08-04",
        "nepDate": "19.04.2067"
    },
    {
        "engDate": "2010-08-05",
        "nepDate": "20.04.2067"
    },
    {
        "engDate": "2010-08-06",
        "nepDate": "21.04.2067"
    },
    {
        "engDate": "2010-08-07",
        "nepDate": "22.04.2067"
    },
    {
        "engDate": "2010-08-08",
        "nepDate": "23.04.2067"
    },
    {
        "engDate": "2010-08-09",
        "nepDate": "24.04.2067"
    },
    {
        "engDate": "2010-08-10",
        "nepDate": "25.04.2067"
    },
    {
        "engDate": "2010-08-11",
        "nepDate": "26.04.2067"
    },
    {
        "engDate": "2010-08-12",
        "nepDate": "27.04.2067"
    },
    {
        "engDate": "2010-08-13",
        "nepDate": "28.04.2067"
    },
    {
        "engDate": "2010-08-14",
        "nepDate": "29.04.2067"
    },
    {
        "engDate": "2010-08-15",
        "nepDate": "30.04.2067"
    },
    {
        "engDate": "2010-08-16",
        "nepDate": "31.04.2067"
    },
    {
        "engDate": "2010-08-17",
        "nepDate": "01.05.2067"
    },
    {
        "engDate": "2010-08-18",
        "nepDate": "02.05.2067"
    },
    {
        "engDate": "2010-08-19",
        "nepDate": "03.05.2067"
    },
    {
        "engDate": "2010-08-20",
        "nepDate": "04.05.2067"
    },
    {
        "engDate": "2010-08-21",
        "nepDate": "05.05.2067"
    },
    {
        "engDate": "2010-08-22",
        "nepDate": "06.05.2067"
    },
    {
        "engDate": "2010-08-23",
        "nepDate": "07.05.2067"
    },
    {
        "engDate": "2010-08-24",
        "nepDate": "08.05.2067"
    },
    {
        "engDate": "2010-08-25",
        "nepDate": "09.05.2067"
    },
    {
        "engDate": "2010-08-26",
        "nepDate": "10.05.2067"
    },
    {
        "engDate": "2010-08-27",
        "nepDate": "11.05.2067"
    },
    {
        "engDate": "2010-08-28",
        "nepDate": "12.05.2067"
    },
    {
        "engDate": "2010-08-29",
        "nepDate": "13.05.2067"
    },
    {
        "engDate": "2010-08-30",
        "nepDate": "14.05.2067"
    },
    {
        "engDate": "2010-08-31",
        "nepDate": "15.05.2067"
    },
    {
        "engDate": "2010-09-01",
        "nepDate": "16.05.2067"
    },
    {
        "engDate": "2010-09-02",
        "nepDate": "17.05.2067"
    },
    {
        "engDate": "2010-09-03",
        "nepDate": "18.05.2067"
    },
    {
        "engDate": "2010-09-04",
        "nepDate": "19.05.2067"
    },
    {
        "engDate": "2010-09-05",
        "nepDate": "20.05.2067"
    },
    {
        "engDate": "2010-09-06",
        "nepDate": "21.05.2067"
    },
    {
        "engDate": "2010-09-07",
        "nepDate": "22.05.2067"
    },
    {
        "engDate": "2010-09-08",
        "nepDate": "23.05.2067"
    },
    {
        "engDate": "2010-09-09",
        "nepDate": "24.05.2067"
    },
    {
        "engDate": "2010-09-10",
        "nepDate": "25.05.2067"
    },
    {
        "engDate": "2010-09-11",
        "nepDate": "26.05.2067"
    },
    {
        "engDate": "2010-09-12",
        "nepDate": "27.05.2067"
    },
    {
        "engDate": "2010-09-13",
        "nepDate": "28.05.2067"
    },
    {
        "engDate": "2010-09-14",
        "nepDate": "29.05.2067"
    },
    {
        "engDate": "2010-09-15",
        "nepDate": "30.05.2067"
    },
    {
        "engDate": "2010-09-16",
        "nepDate": "31.05.2067"
    },
    {
        "engDate": "2010-09-17",
        "nepDate": "01.06.2067"
    },
    {
        "engDate": "2010-09-18",
        "nepDate": "02.06.2067"
    },
    {
        "engDate": "2010-09-19",
        "nepDate": "03.06.2067"
    },
    {
        "engDate": "2010-09-20",
        "nepDate": "04.06.2067"
    },
    {
        "engDate": "2010-09-21",
        "nepDate": "05.06.2067"
    },
    {
        "engDate": "2010-09-22",
        "nepDate": "06.06.2067"
    },
    {
        "engDate": "2010-09-23",
        "nepDate": "07.06.2067"
    },
    {
        "engDate": "2010-09-24",
        "nepDate": "08.06.2067"
    },
    {
        "engDate": "2010-09-25",
        "nepDate": "09.06.2067"
    },
    {
        "engDate": "2010-09-26",
        "nepDate": "10.06.2067"
    },
    {
        "engDate": "2010-09-27",
        "nepDate": "11.06.2067"
    },
    {
        "engDate": "2010-09-28",
        "nepDate": "12.06.2067"
    },
    {
        "engDate": "2010-09-29",
        "nepDate": "13.06.2067"
    },
    {
        "engDate": "2010-09-30",
        "nepDate": "14.06.2067"
    },
    {
        "engDate": "2010-10-01",
        "nepDate": "15.06.2067"
    },
    {
        "engDate": "2010-10-02",
        "nepDate": "16.06.2067"
    },
    {
        "engDate": "2010-10-03",
        "nepDate": "17.06.2067"
    },
    {
        "engDate": "2010-10-04",
        "nepDate": "18.06.2067"
    },
    {
        "engDate": "2010-10-05",
        "nepDate": "19.06.2067"
    },
    {
        "engDate": "2010-10-06",
        "nepDate": "20.06.2067"
    },
    {
        "engDate": "2010-10-07",
        "nepDate": "21.06.2067"
    },
    {
        "engDate": "2010-10-08",
        "nepDate": "22.06.2067"
    },
    {
        "engDate": "2010-10-09",
        "nepDate": "23.06.2067"
    },
    {
        "engDate": "2010-10-10",
        "nepDate": "24.06.2067"
    },
    {
        "engDate": "2010-10-11",
        "nepDate": "25.06.2067"
    },
    {
        "engDate": "2010-10-12",
        "nepDate": "26.06.2067"
    },
    {
        "engDate": "2010-10-13",
        "nepDate": "27.06.2067"
    },
    {
        "engDate": "2010-10-14",
        "nepDate": "28.06.2067"
    },
    {
        "engDate": "2010-10-15",
        "nepDate": "29.06.2067"
    },
    {
        "engDate": "2010-10-16",
        "nepDate": "30.06.2067"
    },
    {
        "engDate": "2010-10-17",
        "nepDate": "31.06.2067"
    },
    {
        "engDate": "2010-10-18",
        "nepDate": "01.07.2067"
    },
    {
        "engDate": "2010-10-19",
        "nepDate": "02.07.2067"
    },
    {
        "engDate": "2010-10-20",
        "nepDate": "03.07.2067"
    },
    {
        "engDate": "2010-10-21",
        "nepDate": "04.07.2067"
    },
    {
        "engDate": "2010-10-22",
        "nepDate": "05.07.2067"
    },
    {
        "engDate": "2010-10-23",
        "nepDate": "06.07.2067"
    },
    {
        "engDate": "2010-10-24",
        "nepDate": "07.07.2067"
    },
    {
        "engDate": "2010-10-25",
        "nepDate": "08.07.2067"
    },
    {
        "engDate": "2010-10-26",
        "nepDate": "09.07.2067"
    },
    {
        "engDate": "2010-10-27",
        "nepDate": "10.07.2067"
    },
    {
        "engDate": "2010-10-28",
        "nepDate": "11.07.2067"
    },
    {
        "engDate": "2010-10-29",
        "nepDate": "12.07.2067"
    },
    {
        "engDate": "2010-10-30",
        "nepDate": "13.07.2067"
    },
    {
        "engDate": "2010-10-31",
        "nepDate": "14.07.2067"
    },
    {
        "engDate": "2010-11-01",
        "nepDate": "15.07.2067"
    },
    {
        "engDate": "2010-11-02",
        "nepDate": "16.07.2067"
    },
    {
        "engDate": "2010-11-03",
        "nepDate": "17.07.2067"
    },
    {
        "engDate": "2010-11-04",
        "nepDate": "18.07.2067"
    },
    {
        "engDate": "2010-11-05",
        "nepDate": "19.07.2067"
    },
    {
        "engDate": "2010-11-06",
        "nepDate": "20.07.2067"
    },
    {
        "engDate": "2010-11-07",
        "nepDate": "21.07.2067"
    },
    {
        "engDate": "2010-11-08",
        "nepDate": "22.07.2067"
    },
    {
        "engDate": "2010-11-09",
        "nepDate": "23.07.2067"
    },
    {
        "engDate": "2010-11-10",
        "nepDate": "24.07.2067"
    },
    {
        "engDate": "2010-11-11",
        "nepDate": "25.07.2067"
    },
    {
        "engDate": "2010-11-12",
        "nepDate": "26.07.2067"
    },
    {
        "engDate": "2010-11-13",
        "nepDate": "27.07.2067"
    },
    {
        "engDate": "2010-11-14",
        "nepDate": "28.07.2067"
    },
    {
        "engDate": "2010-11-15",
        "nepDate": "29.07.2067"
    },
    {
        "engDate": "2010-11-16",
        "nepDate": "30.07.2067"
    },
    {
        "engDate": "2010-11-17",
        "nepDate": "01.08.2067"
    },
    {
        "engDate": "2010-11-18",
        "nepDate": "02.08.2067"
    },
    {
        "engDate": "2010-11-19",
        "nepDate": "03.08.2067"
    },
    {
        "engDate": "2010-11-20",
        "nepDate": "04.08.2067"
    },
    {
        "engDate": "2010-11-21",
        "nepDate": "05.08.2067"
    },
    {
        "engDate": "2010-11-22",
        "nepDate": "06.08.2067"
    },
    {
        "engDate": "2010-11-23",
        "nepDate": "07.08.2067"
    },
    {
        "engDate": "2010-11-24",
        "nepDate": "08.08.2067"
    },
    {
        "engDate": "2010-11-25",
        "nepDate": "09.08.2067"
    },
    {
        "engDate": "2010-11-26",
        "nepDate": "10.08.2067"
    },
    {
        "engDate": "2010-11-27",
        "nepDate": "11.08.2067"
    },
    {
        "engDate": "2010-11-28",
        "nepDate": "12.08.2067"
    },
    {
        "engDate": "2010-11-29",
        "nepDate": "13.08.2067"
    },
    {
        "engDate": "2010-11-30",
        "nepDate": "14.08.2067"
    },
    {
        "engDate": "2010-12-01",
        "nepDate": "15.08.2067"
    },
    {
        "engDate": "2010-12-02",
        "nepDate": "16.08.2067"
    },
    {
        "engDate": "2010-12-03",
        "nepDate": "17.08.2067"
    },
    {
        "engDate": "2010-12-04",
        "nepDate": "18.08.2067"
    },
    {
        "engDate": "2010-12-05",
        "nepDate": "19.08.2067"
    },
    {
        "engDate": "2010-12-06",
        "nepDate": "20.08.2067"
    },
    {
        "engDate": "2010-12-07",
        "nepDate": "21.08.2067"
    },
    {
        "engDate": "2010-12-08",
        "nepDate": "22.08.2067"
    },
    {
        "engDate": "2010-12-09",
        "nepDate": "23.08.2067"
    },
    {
        "engDate": "2010-12-10",
        "nepDate": "24.08.2067"
    },
    {
        "engDate": "2010-12-11",
        "nepDate": "25.08.2067"
    },
    {
        "engDate": "2010-12-12",
        "nepDate": "26.08.2067"
    },
    {
        "engDate": "2010-12-13",
        "nepDate": "27.08.2067"
    },
    {
        "engDate": "2010-12-14",
        "nepDate": "28.08.2067"
    },
    {
        "engDate": "2010-12-15",
        "nepDate": "29.08.2067"
    },
    {
        "engDate": "2010-12-16",
        "nepDate": "01.09.2067"
    },
    {
        "engDate": "2010-12-17",
        "nepDate": "02.09.2067"
    },
    {
        "engDate": "2010-12-18",
        "nepDate": "03.09.2067"
    },
    {
        "engDate": "2010-12-19",
        "nepDate": "04.09.2067"
    },
    {
        "engDate": "2010-12-20",
        "nepDate": "05.09.2067"
    },
    {
        "engDate": "2010-12-21",
        "nepDate": "06.09.2067"
    },
    {
        "engDate": "2010-12-22",
        "nepDate": "07.09.2067"
    },
    {
        "engDate": "2010-12-23",
        "nepDate": "08.09.2067"
    },
    {
        "engDate": "2010-12-24",
        "nepDate": "09.09.2067"
    },
    {
        "engDate": "2010-12-25",
        "nepDate": "10.09.2067"
    },
    {
        "engDate": "2010-12-26",
        "nepDate": "11.09.2067"
    },
    {
        "engDate": "2010-12-27",
        "nepDate": "12.09.2067"
    },
    {
        "engDate": "2010-12-28",
        "nepDate": "13.09.2067"
    },
    {
        "engDate": "2010-12-29",
        "nepDate": "14.09.2067"
    },
    {
        "engDate": "2010-12-30",
        "nepDate": "15.09.2067"
    },
    {
        "engDate": "2010-12-31",
        "nepDate": "16.09.2067"
    },
    {
        "engDate": "2011-01-01",
        "nepDate": "17.09.2067"
    },
    {
        "engDate": "2011-01-02",
        "nepDate": "18.09.2067"
    },
    {
        "engDate": "2011-01-03",
        "nepDate": "19.09.2067"
    },
    {
        "engDate": "2011-01-04",
        "nepDate": "20.09.2067"
    },
    {
        "engDate": "2011-01-05",
        "nepDate": "21.09.2067"
    },
    {
        "engDate": "2011-01-06",
        "nepDate": "22.09.2067"
    },
    {
        "engDate": "2011-01-07",
        "nepDate": "23.09.2067"
    },
    {
        "engDate": "2011-01-08",
        "nepDate": "24.09.2067"
    },
    {
        "engDate": "2011-01-09",
        "nepDate": "25.09.2067"
    },
    {
        "engDate": "2011-01-10",
        "nepDate": "26.09.2067"
    },
    {
        "engDate": "2011-01-11",
        "nepDate": "27.09.2067"
    },
    {
        "engDate": "2011-01-12",
        "nepDate": "28.09.2067"
    },
    {
        "engDate": "2011-01-13",
        "nepDate": "29.09.2067"
    },
    {
        "engDate": "2011-01-14",
        "nepDate": "30.09.2067"
    },
    {
        "engDate": "2011-01-15",
        "nepDate": "01.10.2067"
    },
    {
        "engDate": "2011-01-16",
        "nepDate": "02.10.2067"
    },
    {
        "engDate": "2011-01-17",
        "nepDate": "03.10.2067"
    },
    {
        "engDate": "2011-01-18",
        "nepDate": "04.10.2067"
    },
    {
        "engDate": "2011-01-19",
        "nepDate": "05.10.2067"
    },
    {
        "engDate": "2011-01-20",
        "nepDate": "06.10.2067"
    },
    {
        "engDate": "2011-01-21",
        "nepDate": "07.10.2067"
    },
    {
        "engDate": "2011-01-22",
        "nepDate": "08.10.2067"
    },
    {
        "engDate": "2011-01-23",
        "nepDate": "09.10.2067"
    },
    {
        "engDate": "2011-01-24",
        "nepDate": "10.10.2067"
    },
    {
        "engDate": "2011-01-25",
        "nepDate": "11.10.2067"
    },
    {
        "engDate": "2011-01-26",
        "nepDate": "12.10.2067"
    },
    {
        "engDate": "2011-01-27",
        "nepDate": "13.10.2067"
    },
    {
        "engDate": "2011-01-28",
        "nepDate": "14.10.2067"
    },
    {
        "engDate": "2011-01-29",
        "nepDate": "15.10.2067"
    },
    {
        "engDate": "2011-01-30",
        "nepDate": "16.10.2067"
    },
    {
        "engDate": "2011-01-31",
        "nepDate": "17.10.2067"
    },
    {
        "engDate": "2011-02-01",
        "nepDate": "18.10.2067"
    },
    {
        "engDate": "2011-02-02",
        "nepDate": "19.10.2067"
    },
    {
        "engDate": "2011-02-03",
        "nepDate": "20.10.2067"
    },
    {
        "engDate": "2011-02-04",
        "nepDate": "21.10.2067"
    },
    {
        "engDate": "2011-02-05",
        "nepDate": "22.10.2067"
    },
    {
        "engDate": "2011-02-06",
        "nepDate": "23.10.2067"
    },
    {
        "engDate": "2011-02-07",
        "nepDate": "24.10.2067"
    },
    {
        "engDate": "2011-02-08",
        "nepDate": "25.10.2067"
    },
    {
        "engDate": "2011-02-09",
        "nepDate": "26.10.2067"
    },
    {
        "engDate": "2011-02-10",
        "nepDate": "27.10.2067"
    },
    {
        "engDate": "2011-02-11",
        "nepDate": "28.10.2067"
    },
    {
        "engDate": "2011-02-12",
        "nepDate": "29.10.2067"
    },
    {
        "engDate": "2011-02-13",
        "nepDate": "01.11.2067"
    },
    {
        "engDate": "2011-02-14",
        "nepDate": "02.11.2067"
    },
    {
        "engDate": "2011-02-15",
        "nepDate": "03.11.2067"
    },
    {
        "engDate": "2011-02-16",
        "nepDate": "04.11.2067"
    },
    {
        "engDate": "2011-02-17",
        "nepDate": "05.11.2067"
    },
    {
        "engDate": "2011-02-18",
        "nepDate": "06.11.2067"
    },
    {
        "engDate": "2011-02-19",
        "nepDate": "07.11.2067"
    },
    {
        "engDate": "2011-02-20",
        "nepDate": "08.11.2067"
    },
    {
        "engDate": "2011-02-21",
        "nepDate": "09.11.2067"
    },
    {
        "engDate": "2011-02-22",
        "nepDate": "10.11.2067"
    },
    {
        "engDate": "2011-02-23",
        "nepDate": "11.11.2067"
    },
    {
        "engDate": "2011-02-24",
        "nepDate": "12.11.2067"
    },
    {
        "engDate": "2011-02-25",
        "nepDate": "13.11.2067"
    },
    {
        "engDate": "2011-02-26",
        "nepDate": "14.11.2067"
    },
    {
        "engDate": "2011-02-27",
        "nepDate": "15.11.2067"
    },
    {
        "engDate": "2011-02-28",
        "nepDate": "16.11.2067"
    },
    {
        "engDate": "2011-03-01",
        "nepDate": "17.11.2067"
    },
    {
        "engDate": "2011-03-02",
        "nepDate": "18.11.2067"
    },
    {
        "engDate": "2011-03-03",
        "nepDate": "19.11.2067"
    },
    {
        "engDate": "2011-03-04",
        "nepDate": "20.11.2067"
    },
    {
        "engDate": "2011-03-05",
        "nepDate": "21.11.2067"
    },
    {
        "engDate": "2011-03-06",
        "nepDate": "22.11.2067"
    },
    {
        "engDate": "2011-03-07",
        "nepDate": "23.11.2067"
    },
    {
        "engDate": "2011-03-08",
        "nepDate": "24.11.2067"
    },
    {
        "engDate": "2011-03-09",
        "nepDate": "25.11.2067"
    },
    {
        "engDate": "2011-03-10",
        "nepDate": "26.11.2067"
    },
    {
        "engDate": "2011-03-11",
        "nepDate": "27.11.2067"
    },
    {
        "engDate": "2011-03-12",
        "nepDate": "28.11.2067"
    },
    {
        "engDate": "2011-03-13",
        "nepDate": "29.11.2067"
    },
    {
        "engDate": "2011-03-14",
        "nepDate": "30.11.2067"
    },
    {
        "engDate": "2011-03-15",
        "nepDate": "01.12.2067"
    },
    {
        "engDate": "2011-03-16",
        "nepDate": "02.12.2067"
    },
    {
        "engDate": "2011-03-17",
        "nepDate": "03.12.2067"
    },
    {
        "engDate": "2011-03-18",
        "nepDate": "04.12.2067"
    },
    {
        "engDate": "2011-03-19",
        "nepDate": "05.12.2067"
    },
    {
        "engDate": "2011-03-20",
        "nepDate": "06.12.2067"
    },
    {
        "engDate": "2011-03-21",
        "nepDate": "07.12.2067"
    },
    {
        "engDate": "2011-03-22",
        "nepDate": "08.12.2067"
    },
    {
        "engDate": "2011-03-23",
        "nepDate": "09.12.2067"
    },
    {
        "engDate": "2011-03-24",
        "nepDate": "10.12.2067"
    },
    {
        "engDate": "2011-03-25",
        "nepDate": "11.12.2067"
    },
    {
        "engDate": "2011-03-26",
        "nepDate": "12.12.2067"
    },
    {
        "engDate": "2011-03-27",
        "nepDate": "13.12.2067"
    },
    {
        "engDate": "2011-03-28",
        "nepDate": "14.12.2067"
    },
    {
        "engDate": "2011-03-29",
        "nepDate": "15.12.2067"
    },
    {
        "engDate": "2011-03-30",
        "nepDate": "16.12.2067"
    },
    {
        "engDate": "2011-03-31",
        "nepDate": "17.12.2067"
    },
    {
        "engDate": "2011-04-01",
        "nepDate": "18.12.2067"
    },
    {
        "engDate": "2011-04-02",
        "nepDate": "19.12.2067"
    },
    {
        "engDate": "2011-04-03",
        "nepDate": "20.12.2067"
    },
    {
        "engDate": "2011-04-04",
        "nepDate": "21.12.2067"
    },
    {
        "engDate": "2011-04-05",
        "nepDate": "22.12.2067"
    },
    {
        "engDate": "2011-04-06",
        "nepDate": "23.12.2067"
    },
    {
        "engDate": "2011-04-07",
        "nepDate": "24.12.2067"
    },
    {
        "engDate": "2011-04-08",
        "nepDate": "25.12.2067"
    },
    {
        "engDate": "2011-04-09",
        "nepDate": "26.12.2067"
    },
    {
        "engDate": "2011-04-10",
        "nepDate": "27.12.2067"
    },
    {
        "engDate": "2011-04-11",
        "nepDate": "28.12.2067"
    },
    {
        "engDate": "2011-04-12",
        "nepDate": "29.12.2067"
    },
    {
        "engDate": "2011-04-13",
        "nepDate": "30.12.2067"
    },
    {
        "engDate": "2011-04-14",
        "nepDate": "01.01.2068"
    },
    {
        "engDate": "2011-04-15",
        "nepDate": "02.01.2068"
    },
    {
        "engDate": "2011-04-16",
        "nepDate": "03.01.2068"
    },
    {
        "engDate": "2011-04-17",
        "nepDate": "04.01.2068"
    },
    {
        "engDate": "2011-04-18",
        "nepDate": "05.01.2068"
    },
    {
        "engDate": "2011-04-19",
        "nepDate": "06.01.2068"
    },
    {
        "engDate": "2011-04-20",
        "nepDate": "07.01.2068"
    },
    {
        "engDate": "2011-04-21",
        "nepDate": "08.01.2068"
    },
    {
        "engDate": "2011-04-22",
        "nepDate": "09.01.2068"
    },
    {
        "engDate": "2011-04-23",
        "nepDate": "10.01.2068"
    },
    {
        "engDate": "2011-04-24",
        "nepDate": "11.01.2068"
    },
    {
        "engDate": "2011-04-25",
        "nepDate": "12.01.2068"
    },
    {
        "engDate": "2011-04-26",
        "nepDate": "13.01.2068"
    },
    {
        "engDate": "2011-04-27",
        "nepDate": "14.01.2068"
    },
    {
        "engDate": "2011-04-28",
        "nepDate": "15.01.2068"
    },
    {
        "engDate": "2011-04-29",
        "nepDate": "16.01.2068"
    },
    {
        "engDate": "2011-04-30",
        "nepDate": "17.01.2068"
    },
    {
        "engDate": "2011-05-01",
        "nepDate": "18.01.2068"
    },
    {
        "engDate": "2011-05-02",
        "nepDate": "19.01.2068"
    },
    {
        "engDate": "2011-05-03",
        "nepDate": "20.01.2068"
    },
    {
        "engDate": "2011-05-04",
        "nepDate": "21.01.2068"
    },
    {
        "engDate": "2011-05-05",
        "nepDate": "22.01.2068"
    },
    {
        "engDate": "2011-05-06",
        "nepDate": "23.01.2068"
    },
    {
        "engDate": "2011-05-07",
        "nepDate": "24.01.2068"
    },
    {
        "engDate": "2011-05-08",
        "nepDate": "25.01.2068"
    },
    {
        "engDate": "2011-05-09",
        "nepDate": "26.01.2068"
    },
    {
        "engDate": "2011-05-10",
        "nepDate": "27.01.2068"
    },
    {
        "engDate": "2011-05-11",
        "nepDate": "28.01.2068"
    },
    {
        "engDate": "2011-05-12",
        "nepDate": "29.01.2068"
    },
    {
        "engDate": "2011-05-13",
        "nepDate": "30.01.2068"
    },
    {
        "engDate": "2011-05-14",
        "nepDate": "31.01.2068"
    },
    {
        "engDate": "2011-05-15",
        "nepDate": "01.02.2068"
    },
    {
        "engDate": "2011-05-16",
        "nepDate": "02.02.2068"
    },
    {
        "engDate": "2011-05-17",
        "nepDate": "03.02.2068"
    },
    {
        "engDate": "2011-05-18",
        "nepDate": "04.02.2068"
    },
    {
        "engDate": "2011-05-19",
        "nepDate": "05.02.2068"
    },
    {
        "engDate": "2011-05-20",
        "nepDate": "06.02.2068"
    },
    {
        "engDate": "2011-05-21",
        "nepDate": "07.02.2068"
    },
    {
        "engDate": "2011-05-22",
        "nepDate": "08.02.2068"
    },
    {
        "engDate": "2011-05-23",
        "nepDate": "09.02.2068"
    },
    {
        "engDate": "2011-05-24",
        "nepDate": "10.02.2068"
    },
    {
        "engDate": "2011-05-25",
        "nepDate": "11.02.2068"
    },
    {
        "engDate": "2011-05-26",
        "nepDate": "12.02.2068"
    },
    {
        "engDate": "2011-05-27",
        "nepDate": "13.02.2068"
    },
    {
        "engDate": "2011-05-28",
        "nepDate": "14.02.2068"
    },
    {
        "engDate": "2011-05-29",
        "nepDate": "15.02.2068"
    },
    {
        "engDate": "2011-05-30",
        "nepDate": "16.02.2068"
    },
    {
        "engDate": "2011-05-31",
        "nepDate": "17.02.2068"
    },
    {
        "engDate": "2011-06-01",
        "nepDate": "18.02.2068"
    },
    {
        "engDate": "2011-06-02",
        "nepDate": "19.02.2068"
    },
    {
        "engDate": "2011-06-03",
        "nepDate": "20.02.2068"
    },
    {
        "engDate": "2011-06-04",
        "nepDate": "21.02.2068"
    },
    {
        "engDate": "2011-06-05",
        "nepDate": "22.02.2068"
    },
    {
        "engDate": "2011-06-06",
        "nepDate": "23.02.2068"
    },
    {
        "engDate": "2011-06-07",
        "nepDate": "24.02.2068"
    },
    {
        "engDate": "2011-06-08",
        "nepDate": "25.02.2068"
    },
    {
        "engDate": "2011-06-09",
        "nepDate": "26.02.2068"
    },
    {
        "engDate": "2011-06-10",
        "nepDate": "27.02.2068"
    },
    {
        "engDate": "2011-06-11",
        "nepDate": "28.02.2068"
    },
    {
        "engDate": "2011-06-12",
        "nepDate": "29.02.2068"
    },
    {
        "engDate": "2011-06-13",
        "nepDate": "30.02.2068"
    },
    {
        "engDate": "2011-06-14",
        "nepDate": "31.02.2068"
    },
    {
        "engDate": "2011-06-15",
        "nepDate": "01.03.2068"
    },
    {
        "engDate": "2011-06-16",
        "nepDate": "02.03.2068"
    },
    {
        "engDate": "2011-06-17",
        "nepDate": "03.03.2068"
    },
    {
        "engDate": "2011-06-18",
        "nepDate": "04.03.2068"
    },
    {
        "engDate": "2011-06-19",
        "nepDate": "05.03.2068"
    },
    {
        "engDate": "2011-06-20",
        "nepDate": "06.03.2068"
    },
    {
        "engDate": "2011-06-21",
        "nepDate": "07.03.2068"
    },
    {
        "engDate": "2011-06-22",
        "nepDate": "08.03.2068"
    },
    {
        "engDate": "2011-06-23",
        "nepDate": "09.03.2068"
    },
    {
        "engDate": "2011-06-24",
        "nepDate": "10.03.2068"
    },
    {
        "engDate": "2011-06-25",
        "nepDate": "11.03.2068"
    },
    {
        "engDate": "2011-06-26",
        "nepDate": "12.03.2068"
    },
    {
        "engDate": "2011-06-27",
        "nepDate": "13.03.2068"
    },
    {
        "engDate": "2011-06-28",
        "nepDate": "14.03.2068"
    },
    {
        "engDate": "2011-06-29",
        "nepDate": "15.03.2068"
    },
    {
        "engDate": "2011-06-30",
        "nepDate": "16.03.2068"
    },
    {
        "engDate": "2011-07-01",
        "nepDate": "17.03.2068"
    },
    {
        "engDate": "2011-07-02",
        "nepDate": "18.03.2068"
    },
    {
        "engDate": "2011-07-03",
        "nepDate": "19.03.2068"
    },
    {
        "engDate": "2011-07-04",
        "nepDate": "20.03.2068"
    },
    {
        "engDate": "2011-07-05",
        "nepDate": "21.03.2068"
    },
    {
        "engDate": "2011-07-06",
        "nepDate": "22.03.2068"
    },
    {
        "engDate": "2011-07-07",
        "nepDate": "23.03.2068"
    },
    {
        "engDate": "2011-07-08",
        "nepDate": "24.03.2068"
    },
    {
        "engDate": "2011-07-09",
        "nepDate": "25.03.2068"
    },
    {
        "engDate": "2011-07-10",
        "nepDate": "26.03.2068"
    },
    {
        "engDate": "2011-07-11",
        "nepDate": "27.03.2068"
    },
    {
        "engDate": "2011-07-12",
        "nepDate": "28.03.2068"
    },
    {
        "engDate": "2011-07-13",
        "nepDate": "29.03.2068"
    },
    {
        "engDate": "2011-07-14",
        "nepDate": "30.03.2068"
    },
    {
        "engDate": "2011-07-15",
        "nepDate": "31.03.2068"
    },
    {
        "engDate": "2011-07-16",
        "nepDate": "32.03.2068"
    },
    {
        "engDate": "2011-07-17",
        "nepDate": "01.04.2068"
    },
    {
        "engDate": "2011-07-18",
        "nepDate": "02.04.2068"
    },
    {
        "engDate": "2011-07-19",
        "nepDate": "03.04.2068"
    },
    {
        "engDate": "2011-07-20",
        "nepDate": "04.04.2068"
    },
    {
        "engDate": "2011-07-21",
        "nepDate": "05.04.2068"
    },
    {
        "engDate": "2011-07-22",
        "nepDate": "06.04.2068"
    },
    {
        "engDate": "2011-07-23",
        "nepDate": "07.04.2068"
    },
    {
        "engDate": "2011-07-24",
        "nepDate": "08.04.2068"
    },
    {
        "engDate": "2011-07-25",
        "nepDate": "09.04.2068"
    },
    {
        "engDate": "2011-07-26",
        "nepDate": "10.04.2068"
    },
    {
        "engDate": "2011-07-27",
        "nepDate": "11.04.2068"
    },
    {
        "engDate": "2011-07-28",
        "nepDate": "12.04.2068"
    },
    {
        "engDate": "2011-07-29",
        "nepDate": "13.04.2068"
    },
    {
        "engDate": "2011-07-30",
        "nepDate": "14.04.2068"
    },
    {
        "engDate": "2011-07-31",
        "nepDate": "15.04.2068"
    },
    {
        "engDate": "2011-08-01",
        "nepDate": "16.04.2068"
    },
    {
        "engDate": "2011-08-02",
        "nepDate": "17.04.2068"
    },
    {
        "engDate": "2011-08-03",
        "nepDate": "18.04.2068"
    },
    {
        "engDate": "2011-08-04",
        "nepDate": "19.04.2068"
    },
    {
        "engDate": "2011-08-05",
        "nepDate": "20.04.2068"
    },
    {
        "engDate": "2011-08-06",
        "nepDate": "21.04.2068"
    },
    {
        "engDate": "2011-08-07",
        "nepDate": "22.04.2068"
    },
    {
        "engDate": "2011-08-08",
        "nepDate": "23.04.2068"
    },
    {
        "engDate": "2011-08-09",
        "nepDate": "24.04.2068"
    },
    {
        "engDate": "2011-08-10",
        "nepDate": "25.04.2068"
    },
    {
        "engDate": "2011-08-11",
        "nepDate": "26.04.2068"
    },
    {
        "engDate": "2011-08-12",
        "nepDate": "27.04.2068"
    },
    {
        "engDate": "2011-08-13",
        "nepDate": "28.04.2068"
    },
    {
        "engDate": "2011-08-14",
        "nepDate": "29.04.2068"
    },
    {
        "engDate": "2011-08-15",
        "nepDate": "30.04.2068"
    },
    {
        "engDate": "2011-08-16",
        "nepDate": "31.04.2068"
    },
    {
        "engDate": "2011-08-17",
        "nepDate": "32.04.2068"
    },
    {
        "engDate": "2011-08-18",
        "nepDate": "01.05.2068"
    },
    {
        "engDate": "2011-08-19",
        "nepDate": "02.05.2068"
    },
    {
        "engDate": "2011-08-20",
        "nepDate": "03.05.2068"
    },
    {
        "engDate": "2011-08-21",
        "nepDate": "04.05.2068"
    },
    {
        "engDate": "2011-08-22",
        "nepDate": "05.05.2068"
    },
    {
        "engDate": "2011-08-23",
        "nepDate": "06.05.2068"
    },
    {
        "engDate": "2011-08-24",
        "nepDate": "07.05.2068"
    },
    {
        "engDate": "2011-08-25",
        "nepDate": "08.05.2068"
    },
    {
        "engDate": "2011-08-26",
        "nepDate": "09.05.2068"
    },
    {
        "engDate": "2011-08-27",
        "nepDate": "10.05.2068"
    },
    {
        "engDate": "2011-08-28",
        "nepDate": "11.05.2068"
    },
    {
        "engDate": "2011-08-29",
        "nepDate": "12.05.2068"
    },
    {
        "engDate": "2011-08-30",
        "nepDate": "13.05.2068"
    },
    {
        "engDate": "2011-08-31",
        "nepDate": "14.05.2068"
    },
    {
        "engDate": "2011-09-01",
        "nepDate": "15.05.2068"
    },
    {
        "engDate": "2011-09-02",
        "nepDate": "16.05.2068"
    },
    {
        "engDate": "2011-09-03",
        "nepDate": "17.05.2068"
    },
    {
        "engDate": "2011-09-04",
        "nepDate": "18.05.2068"
    },
    {
        "engDate": "2011-09-05",
        "nepDate": "19.05.2068"
    },
    {
        "engDate": "2011-09-06",
        "nepDate": "20.05.2068"
    },
    {
        "engDate": "2011-09-07",
        "nepDate": "21.05.2068"
    },
    {
        "engDate": "2011-09-08",
        "nepDate": "22.05.2068"
    },
    {
        "engDate": "2011-09-09",
        "nepDate": "23.05.2068"
    },
    {
        "engDate": "2011-09-10",
        "nepDate": "24.05.2068"
    },
    {
        "engDate": "2011-09-11",
        "nepDate": "25.05.2068"
    },
    {
        "engDate": "2011-09-12",
        "nepDate": "26.05.2068"
    },
    {
        "engDate": "2011-09-13",
        "nepDate": "27.05.2068"
    },
    {
        "engDate": "2011-09-14",
        "nepDate": "28.05.2068"
    },
    {
        "engDate": "2011-09-15",
        "nepDate": "29.05.2068"
    },
    {
        "engDate": "2011-09-16",
        "nepDate": "30.05.2068"
    },
    {
        "engDate": "2011-09-17",
        "nepDate": "31.05.2068"
    },
    {
        "engDate": "2011-09-18",
        "nepDate": "01.06.2068"
    },
    {
        "engDate": "2011-09-19",
        "nepDate": "02.06.2068"
    },
    {
        "engDate": "2011-09-20",
        "nepDate": "03.06.2068"
    },
    {
        "engDate": "2011-09-21",
        "nepDate": "04.06.2068"
    },
    {
        "engDate": "2011-09-22",
        "nepDate": "05.06.2068"
    },
    {
        "engDate": "2011-09-23",
        "nepDate": "06.06.2068"
    },
    {
        "engDate": "2011-09-24",
        "nepDate": "07.06.2068"
    },
    {
        "engDate": "2011-09-25",
        "nepDate": "08.06.2068"
    },
    {
        "engDate": "2011-09-26",
        "nepDate": "09.06.2068"
    },
    {
        "engDate": "2011-09-27",
        "nepDate": "10.06.2068"
    },
    {
        "engDate": "2011-09-28",
        "nepDate": "11.06.2068"
    },
    {
        "engDate": "2011-09-29",
        "nepDate": "12.06.2068"
    },
    {
        "engDate": "2011-09-30",
        "nepDate": "13.06.2068"
    },
    {
        "engDate": "2011-10-01",
        "nepDate": "14.06.2068"
    },
    {
        "engDate": "2011-10-02",
        "nepDate": "15.06.2068"
    },
    {
        "engDate": "2011-10-03",
        "nepDate": "16.06.2068"
    },
    {
        "engDate": "2011-10-04",
        "nepDate": "17.06.2068"
    },
    {
        "engDate": "2011-10-05",
        "nepDate": "18.06.2068"
    },
    {
        "engDate": "2011-10-06",
        "nepDate": "19.06.2068"
    },
    {
        "engDate": "2011-10-07",
        "nepDate": "20.06.2068"
    },
    {
        "engDate": "2011-10-08",
        "nepDate": "21.06.2068"
    },
    {
        "engDate": "2011-10-09",
        "nepDate": "22.06.2068"
    },
    {
        "engDate": "2011-10-10",
        "nepDate": "23.06.2068"
    },
    {
        "engDate": "2011-10-11",
        "nepDate": "24.06.2068"
    },
    {
        "engDate": "2011-10-12",
        "nepDate": "25.06.2068"
    },
    {
        "engDate": "2011-10-13",
        "nepDate": "26.06.2068"
    },
    {
        "engDate": "2011-10-14",
        "nepDate": "27.06.2068"
    },
    {
        "engDate": "2011-10-15",
        "nepDate": "28.06.2068"
    },
    {
        "engDate": "2011-10-16",
        "nepDate": "29.06.2068"
    },
    {
        "engDate": "2011-10-17",
        "nepDate": "30.06.2068"
    },
    {
        "engDate": "2011-10-18",
        "nepDate": "01.07.2068"
    },
    {
        "engDate": "2011-10-19",
        "nepDate": "02.07.2068"
    },
    {
        "engDate": "2011-10-20",
        "nepDate": "03.07.2068"
    },
    {
        "engDate": "2011-10-21",
        "nepDate": "04.07.2068"
    },
    {
        "engDate": "2011-10-22",
        "nepDate": "05.07.2068"
    },
    {
        "engDate": "2011-10-23",
        "nepDate": "06.07.2068"
    },
    {
        "engDate": "2011-10-24",
        "nepDate": "07.07.2068"
    },
    {
        "engDate": "2011-10-25",
        "nepDate": "08.07.2068"
    },
    {
        "engDate": "2011-10-26",
        "nepDate": "09.07.2068"
    },
    {
        "engDate": "2011-10-27",
        "nepDate": "10.07.2068"
    },
    {
        "engDate": "2011-10-28",
        "nepDate": "11.07.2068"
    },
    {
        "engDate": "2011-10-29",
        "nepDate": "12.07.2068"
    },
    {
        "engDate": "2011-10-30",
        "nepDate": "13.07.2068"
    },
    {
        "engDate": "2011-10-31",
        "nepDate": "14.07.2068"
    },
    {
        "engDate": "2011-11-01",
        "nepDate": "15.07.2068"
    },
    {
        "engDate": "2011-11-02",
        "nepDate": "16.07.2068"
    },
    {
        "engDate": "2011-11-03",
        "nepDate": "17.07.2068"
    },
    {
        "engDate": "2011-11-04",
        "nepDate": "18.07.2068"
    },
    {
        "engDate": "2011-11-05",
        "nepDate": "19.07.2068"
    },
    {
        "engDate": "2011-11-06",
        "nepDate": "20.07.2068"
    },
    {
        "engDate": "2011-11-07",
        "nepDate": "21.07.2068"
    },
    {
        "engDate": "2011-11-08",
        "nepDate": "22.07.2068"
    },
    {
        "engDate": "2011-11-09",
        "nepDate": "23.07.2068"
    },
    {
        "engDate": "2011-11-10",
        "nepDate": "24.07.2068"
    },
    {
        "engDate": "2011-11-11",
        "nepDate": "25.07.2068"
    },
    {
        "engDate": "2011-11-12",
        "nepDate": "26.07.2068"
    },
    {
        "engDate": "2011-11-13",
        "nepDate": "27.07.2068"
    },
    {
        "engDate": "2011-11-14",
        "nepDate": "28.07.2068"
    },
    {
        "engDate": "2011-11-15",
        "nepDate": "29.07.2068"
    },
    {
        "engDate": "2011-11-16",
        "nepDate": "30.07.2068"
    },
    {
        "engDate": "2011-11-17",
        "nepDate": "01.08.2068"
    },
    {
        "engDate": "2011-11-18",
        "nepDate": "02.08.2068"
    },
    {
        "engDate": "2011-11-19",
        "nepDate": "03.08.2068"
    },
    {
        "engDate": "2011-11-20",
        "nepDate": "04.08.2068"
    },
    {
        "engDate": "2011-11-21",
        "nepDate": "05.08.2068"
    },
    {
        "engDate": "2011-11-22",
        "nepDate": "06.08.2068"
    },
    {
        "engDate": "2011-11-23",
        "nepDate": "07.08.2068"
    },
    {
        "engDate": "2011-11-24",
        "nepDate": "08.08.2068"
    },
    {
        "engDate": "2011-11-25",
        "nepDate": "09.08.2068"
    },
    {
        "engDate": "2011-11-26",
        "nepDate": "10.08.2068"
    },
    {
        "engDate": "2011-11-27",
        "nepDate": "11.08.2068"
    },
    {
        "engDate": "2011-11-28",
        "nepDate": "12.08.2068"
    },
    {
        "engDate": "2011-11-29",
        "nepDate": "13.08.2068"
    },
    {
        "engDate": "2011-11-30",
        "nepDate": "14.08.2068"
    },
    {
        "engDate": "2011-12-01",
        "nepDate": "15.08.2068"
    },
    {
        "engDate": "2011-12-02",
        "nepDate": "16.08.2068"
    },
    {
        "engDate": "2011-12-03",
        "nepDate": "17.08.2068"
    },
    {
        "engDate": "2011-12-04",
        "nepDate": "18.08.2068"
    },
    {
        "engDate": "2011-12-05",
        "nepDate": "19.08.2068"
    },
    {
        "engDate": "2011-12-06",
        "nepDate": "20.08.2068"
    },
    {
        "engDate": "2011-12-07",
        "nepDate": "21.08.2068"
    },
    {
        "engDate": "2011-12-08",
        "nepDate": "22.08.2068"
    },
    {
        "engDate": "2011-12-09",
        "nepDate": "23.08.2068"
    },
    {
        "engDate": "2011-12-10",
        "nepDate": "24.08.2068"
    },
    {
        "engDate": "2011-12-11",
        "nepDate": "25.08.2068"
    },
    {
        "engDate": "2011-12-12",
        "nepDate": "26.08.2068"
    },
    {
        "engDate": "2011-12-13",
        "nepDate": "27.08.2068"
    },
    {
        "engDate": "2011-12-14",
        "nepDate": "28.08.2068"
    },
    {
        "engDate": "2011-12-15",
        "nepDate": "29.08.2068"
    },
    {
        "engDate": "2011-12-16",
        "nepDate": "01.09.2068"
    },
    {
        "engDate": "2011-12-17",
        "nepDate": "02.09.2068"
    },
    {
        "engDate": "2011-12-18",
        "nepDate": "03.09.2068"
    },
    {
        "engDate": "2011-12-19",
        "nepDate": "04.09.2068"
    },
    {
        "engDate": "2011-12-20",
        "nepDate": "05.09.2068"
    },
    {
        "engDate": "2011-12-21",
        "nepDate": "06.09.2068"
    },
    {
        "engDate": "2011-12-22",
        "nepDate": "07.09.2068"
    },
    {
        "engDate": "2011-12-23",
        "nepDate": "08.09.2068"
    },
    {
        "engDate": "2011-12-24",
        "nepDate": "09.09.2068"
    },
    {
        "engDate": "2011-12-25",
        "nepDate": "10.09.2068"
    },
    {
        "engDate": "2011-12-26",
        "nepDate": "11.09.2068"
    },
    {
        "engDate": "2011-12-27",
        "nepDate": "12.09.2068"
    },
    {
        "engDate": "2011-12-28",
        "nepDate": "13.09.2068"
    },
    {
        "engDate": "2011-12-29",
        "nepDate": "14.09.2068"
    },
    {
        "engDate": "2011-12-30",
        "nepDate": "15.09.2068"
    },
    {
        "engDate": "2011-12-31",
        "nepDate": "16.09.2068"
    },
    {
        "engDate": "2012-01-01",
        "nepDate": "17.09.2068"
    },
    {
        "engDate": "2012-01-02",
        "nepDate": "18.09.2068"
    },
    {
        "engDate": "2012-01-03",
        "nepDate": "19.09.2068"
    },
    {
        "engDate": "2012-01-04",
        "nepDate": "20.09.2068"
    },
    {
        "engDate": "2012-01-05",
        "nepDate": "21.09.2068"
    },
    {
        "engDate": "2012-01-06",
        "nepDate": "22.09.2068"
    },
    {
        "engDate": "2012-01-07",
        "nepDate": "23.09.2068"
    },
    {
        "engDate": "2012-01-08",
        "nepDate": "24.09.2068"
    },
    {
        "engDate": "2012-01-09",
        "nepDate": "25.09.2068"
    },
    {
        "engDate": "2012-01-10",
        "nepDate": "26.09.2068"
    },
    {
        "engDate": "2012-01-11",
        "nepDate": "27.09.2068"
    },
    {
        "engDate": "2012-01-12",
        "nepDate": "28.09.2068"
    },
    {
        "engDate": "2012-01-13",
        "nepDate": "29.09.2068"
    },
    {
        "engDate": "2012-01-14",
        "nepDate": "30.09.2068"
    },
    {
        "engDate": "2012-01-15",
        "nepDate": "01.10.2068"
    },
    {
        "engDate": "2012-01-16",
        "nepDate": "02.10.2068"
    },
    {
        "engDate": "2012-01-17",
        "nepDate": "03.10.2068"
    },
    {
        "engDate": "2012-01-18",
        "nepDate": "04.10.2068"
    },
    {
        "engDate": "2012-01-19",
        "nepDate": "05.10.2068"
    },
    {
        "engDate": "2012-01-20",
        "nepDate": "06.10.2068"
    },
    {
        "engDate": "2012-01-21",
        "nepDate": "07.10.2068"
    },
    {
        "engDate": "2012-01-22",
        "nepDate": "08.10.2068"
    },
    {
        "engDate": "2012-01-23",
        "nepDate": "09.10.2068"
    },
    {
        "engDate": "2012-01-24",
        "nepDate": "10.10.2068"
    },
    {
        "engDate": "2012-01-25",
        "nepDate": "11.10.2068"
    },
    {
        "engDate": "2012-01-26",
        "nepDate": "12.10.2068"
    },
    {
        "engDate": "2012-01-27",
        "nepDate": "13.10.2068"
    },
    {
        "engDate": "2012-01-28",
        "nepDate": "14.10.2068"
    },
    {
        "engDate": "2012-01-29",
        "nepDate": "15.10.2068"
    },
    {
        "engDate": "2012-01-30",
        "nepDate": "16.10.2068"
    },
    {
        "engDate": "2012-01-31",
        "nepDate": "17.10.2068"
    },
    {
        "engDate": "2012-02-01",
        "nepDate": "18.10.2068"
    },
    {
        "engDate": "2012-02-02",
        "nepDate": "19.10.2068"
    },
    {
        "engDate": "2012-02-03",
        "nepDate": "20.10.2068"
    },
    {
        "engDate": "2012-02-04",
        "nepDate": "21.10.2068"
    },
    {
        "engDate": "2012-02-05",
        "nepDate": "22.10.2068"
    },
    {
        "engDate": "2012-02-06",
        "nepDate": "23.10.2068"
    },
    {
        "engDate": "2012-02-07",
        "nepDate": "24.10.2068"
    },
    {
        "engDate": "2012-02-08",
        "nepDate": "25.10.2068"
    },
    {
        "engDate": "2012-02-09",
        "nepDate": "26.10.2068"
    },
    {
        "engDate": "2012-02-10",
        "nepDate": "27.10.2068"
    },
    {
        "engDate": "2012-02-11",
        "nepDate": "28.10.2068"
    },
    {
        "engDate": "2012-02-12",
        "nepDate": "29.10.2068"
    },
    {
        "engDate": "2012-02-13",
        "nepDate": "01.11.2068"
    },
    {
        "engDate": "2012-02-14",
        "nepDate": "02.11.2068"
    },
    {
        "engDate": "2012-02-15",
        "nepDate": "03.11.2068"
    },
    {
        "engDate": "2012-02-16",
        "nepDate": "04.11.2068"
    },
    {
        "engDate": "2012-02-17",
        "nepDate": "05.11.2068"
    },
    {
        "engDate": "2012-02-18",
        "nepDate": "06.11.2068"
    },
    {
        "engDate": "2012-02-19",
        "nepDate": "07.11.2068"
    },
    {
        "engDate": "2012-02-20",
        "nepDate": "08.11.2068"
    },
    {
        "engDate": "2012-02-21",
        "nepDate": "09.11.2068"
    },
    {
        "engDate": "2012-02-22",
        "nepDate": "10.11.2068"
    },
    {
        "engDate": "2012-02-23",
        "nepDate": "11.11.2068"
    },
    {
        "engDate": "2012-02-24",
        "nepDate": "12.11.2068"
    },
    {
        "engDate": "2012-02-25",
        "nepDate": "13.11.2068"
    },
    {
        "engDate": "2012-02-26",
        "nepDate": "14.11.2068"
    },
    {
        "engDate": "2012-02-27",
        "nepDate": "15.11.2068"
    },
    {
        "engDate": "2012-02-28",
        "nepDate": "16.11.2068"
    },
    {
        "engDate": "2012-02-29",
        "nepDate": "17.11.2068"
    },
    {
        "engDate": "2012-03-01",
        "nepDate": "18.11.2068"
    },
    {
        "engDate": "2012-03-02",
        "nepDate": "19.11.2068"
    },
    {
        "engDate": "2012-03-03",
        "nepDate": "20.11.2068"
    },
    {
        "engDate": "2012-03-04",
        "nepDate": "21.11.2068"
    },
    {
        "engDate": "2012-03-05",
        "nepDate": "22.11.2068"
    },
    {
        "engDate": "2012-03-06",
        "nepDate": "23.11.2068"
    },
    {
        "engDate": "2012-03-07",
        "nepDate": "24.11.2068"
    },
    {
        "engDate": "2012-03-08",
        "nepDate": "25.11.2068"
    },
    {
        "engDate": "2012-03-09",
        "nepDate": "26.11.2068"
    },
    {
        "engDate": "2012-03-10",
        "nepDate": "27.11.2068"
    },
    {
        "engDate": "2012-03-11",
        "nepDate": "28.11.2068"
    },
    {
        "engDate": "2012-03-12",
        "nepDate": "29.11.2068"
    },
    {
        "engDate": "2012-03-13",
        "nepDate": "30.11.2068"
    },
    {
        "engDate": "2012-03-14",
        "nepDate": "01.12.2068"
    },
    {
        "engDate": "2012-03-15",
        "nepDate": "02.12.2068"
    },
    {
        "engDate": "2012-03-16",
        "nepDate": "03.12.2068"
    },
    {
        "engDate": "2012-03-17",
        "nepDate": "04.12.2068"
    },
    {
        "engDate": "2012-03-18",
        "nepDate": "05.12.2068"
    },
    {
        "engDate": "2012-03-19",
        "nepDate": "06.12.2068"
    },
    {
        "engDate": "2012-03-20",
        "nepDate": "07.12.2068"
    },
    {
        "engDate": "2012-03-21",
        "nepDate": "08.12.2068"
    },
    {
        "engDate": "2012-03-22",
        "nepDate": "09.12.2068"
    },
    {
        "engDate": "2012-03-23",
        "nepDate": "10.12.2068"
    },
    {
        "engDate": "2012-03-24",
        "nepDate": "11.12.2068"
    },
    {
        "engDate": "2012-03-25",
        "nepDate": "12.12.2068"
    },
    {
        "engDate": "2012-03-26",
        "nepDate": "13.12.2068"
    },
    {
        "engDate": "2012-03-27",
        "nepDate": "14.12.2068"
    },
    {
        "engDate": "2012-03-28",
        "nepDate": "15.12.2068"
    },
    {
        "engDate": "2012-03-29",
        "nepDate": "16.12.2068"
    },
    {
        "engDate": "2012-03-30",
        "nepDate": "17.12.2068"
    },
    {
        "engDate": "2012-03-31",
        "nepDate": "18.12.2068"
    },
    {
        "engDate": "2012-04-01",
        "nepDate": "19.12.2068"
    },
    {
        "engDate": "2012-04-02",
        "nepDate": "20.12.2068"
    },
    {
        "engDate": "2012-04-03",
        "nepDate": "21.12.2068"
    },
    {
        "engDate": "2012-04-04",
        "nepDate": "22.12.2068"
    },
    {
        "engDate": "2012-04-05",
        "nepDate": "23.12.2068"
    },
    {
        "engDate": "2012-04-06",
        "nepDate": "24.12.2068"
    },
    {
        "engDate": "2012-04-07",
        "nepDate": "25.12.2068"
    },
    {
        "engDate": "2012-04-08",
        "nepDate": "26.12.2068"
    },
    {
        "engDate": "2012-04-09",
        "nepDate": "27.12.2068"
    },
    {
        "engDate": "2012-04-10",
        "nepDate": "28.12.2068"
    },
    {
        "engDate": "2012-04-11",
        "nepDate": "29.12.2068"
    },
    {
        "engDate": "2012-04-12",
        "nepDate": "30.12.2068"
    },
    {
        "engDate": "2012-04-13",
        "nepDate": "01.01.2069"
    },
    {
        "engDate": "2012-04-14",
        "nepDate": "02.01.2069"
    },
    {
        "engDate": "2012-04-15",
        "nepDate": "03.01.2069"
    },
    {
        "engDate": "2012-04-16",
        "nepDate": "04.01.2069"
    },
    {
        "engDate": "2012-04-17",
        "nepDate": "05.01.2069"
    },
    {
        "engDate": "2012-04-18",
        "nepDate": "06.01.2069"
    },
    {
        "engDate": "2012-04-19",
        "nepDate": "07.01.2069"
    },
    {
        "engDate": "2012-04-20",
        "nepDate": "08.01.2069"
    },
    {
        "engDate": "2012-04-21",
        "nepDate": "09.01.2069"
    },
    {
        "engDate": "2012-04-22",
        "nepDate": "10.01.2069"
    },
    {
        "engDate": "2012-04-23",
        "nepDate": "11.01.2069"
    },
    {
        "engDate": "2012-04-24",
        "nepDate": "12.01.2069"
    },
    {
        "engDate": "2012-04-25",
        "nepDate": "13.01.2069"
    },
    {
        "engDate": "2012-04-26",
        "nepDate": "14.01.2069"
    },
    {
        "engDate": "2012-04-27",
        "nepDate": "15.01.2069"
    },
    {
        "engDate": "2012-04-28",
        "nepDate": "16.01.2069"
    },
    {
        "engDate": "2012-04-29",
        "nepDate": "17.01.2069"
    },
    {
        "engDate": "2012-04-30",
        "nepDate": "18.01.2069"
    },
    {
        "engDate": "2012-05-01",
        "nepDate": "19.01.2069"
    },
    {
        "engDate": "2012-05-02",
        "nepDate": "20.01.2069"
    },
    {
        "engDate": "2012-05-03",
        "nepDate": "21.01.2069"
    },
    {
        "engDate": "2012-05-04",
        "nepDate": "22.01.2069"
    },
    {
        "engDate": "2012-05-05",
        "nepDate": "23.01.2069"
    },
    {
        "engDate": "2012-05-06",
        "nepDate": "24.01.2069"
    },
    {
        "engDate": "2012-05-07",
        "nepDate": "25.01.2069"
    },
    {
        "engDate": "2012-05-08",
        "nepDate": "26.01.2069"
    },
    {
        "engDate": "2012-05-09",
        "nepDate": "27.01.2069"
    },
    {
        "engDate": "2012-05-10",
        "nepDate": "28.01.2069"
    },
    {
        "engDate": "2012-05-11",
        "nepDate": "29.01.2069"
    },
    {
        "engDate": "2012-05-12",
        "nepDate": "30.01.2069"
    },
    {
        "engDate": "2012-05-13",
        "nepDate": "31.01.2069"
    },
    {
        "engDate": "2012-05-14",
        "nepDate": "01.02.2069"
    },
    {
        "engDate": "2012-05-15",
        "nepDate": "02.02.2069"
    },
    {
        "engDate": "2012-05-16",
        "nepDate": "03.02.2069"
    },
    {
        "engDate": "2012-05-17",
        "nepDate": "04.02.2069"
    },
    {
        "engDate": "2012-05-18",
        "nepDate": "05.02.2069"
    },
    {
        "engDate": "2012-05-19",
        "nepDate": "06.02.2069"
    },
    {
        "engDate": "2012-05-20",
        "nepDate": "07.02.2069"
    },
    {
        "engDate": "2012-05-21",
        "nepDate": "08.02.2069"
    },
    {
        "engDate": "2012-05-22",
        "nepDate": "09.02.2069"
    },
    {
        "engDate": "2012-05-23",
        "nepDate": "10.02.2069"
    },
    {
        "engDate": "2012-05-24",
        "nepDate": "11.02.2069"
    },
    {
        "engDate": "2012-05-25",
        "nepDate": "12.02.2069"
    },
    {
        "engDate": "2012-05-26",
        "nepDate": "13.02.2069"
    },
    {
        "engDate": "2012-05-27",
        "nepDate": "14.02.2069"
    },
    {
        "engDate": "2012-05-28",
        "nepDate": "15.02.2069"
    },
    {
        "engDate": "2012-05-29",
        "nepDate": "16.02.2069"
    },
    {
        "engDate": "2012-05-30",
        "nepDate": "17.02.2069"
    },
    {
        "engDate": "2012-05-31",
        "nepDate": "18.02.2069"
    },
    {
        "engDate": "2012-06-01",
        "nepDate": "19.02.2069"
    },
    {
        "engDate": "2012-06-02",
        "nepDate": "20.02.2069"
    },
    {
        "engDate": "2012-06-03",
        "nepDate": "21.02.2069"
    },
    {
        "engDate": "2012-06-04",
        "nepDate": "22.02.2069"
    },
    {
        "engDate": "2012-06-05",
        "nepDate": "23.02.2069"
    },
    {
        "engDate": "2012-06-06",
        "nepDate": "24.02.2069"
    },
    {
        "engDate": "2012-06-07",
        "nepDate": "25.02.2069"
    },
    {
        "engDate": "2012-06-08",
        "nepDate": "26.02.2069"
    },
    {
        "engDate": "2012-06-09",
        "nepDate": "27.02.2069"
    },
    {
        "engDate": "2012-06-10",
        "nepDate": "28.02.2069"
    },
    {
        "engDate": "2012-06-11",
        "nepDate": "29.02.2069"
    },
    {
        "engDate": "2012-06-12",
        "nepDate": "30.02.2069"
    },
    {
        "engDate": "2012-06-13",
        "nepDate": "31.02.2069"
    },
    {
        "engDate": "2012-06-14",
        "nepDate": "32.02.2069"
    },
    {
        "engDate": "2012-06-15",
        "nepDate": "01.03.2069"
    },
    {
        "engDate": "2012-06-16",
        "nepDate": "02.03.2069"
    },
    {
        "engDate": "2012-06-17",
        "nepDate": "03.03.2069"
    },
    {
        "engDate": "2012-06-18",
        "nepDate": "04.03.2069"
    },
    {
        "engDate": "2012-06-19",
        "nepDate": "05.03.2069"
    },
    {
        "engDate": "2012-06-20",
        "nepDate": "06.03.2069"
    },
    {
        "engDate": "2012-06-21",
        "nepDate": "07.03.2069"
    },
    {
        "engDate": "2012-06-22",
        "nepDate": "08.03.2069"
    },
    {
        "engDate": "2012-06-23",
        "nepDate": "09.03.2069"
    },
    {
        "engDate": "2012-06-24",
        "nepDate": "10.03.2069"
    },
    {
        "engDate": "2012-06-25",
        "nepDate": "11.03.2069"
    },
    {
        "engDate": "2012-06-26",
        "nepDate": "12.03.2069"
    },
    {
        "engDate": "2012-06-27",
        "nepDate": "13.03.2069"
    },
    {
        "engDate": "2012-06-28",
        "nepDate": "14.03.2069"
    },
    {
        "engDate": "2012-06-29",
        "nepDate": "15.03.2069"
    },
    {
        "engDate": "2012-06-30",
        "nepDate": "16.03.2069"
    },
    {
        "engDate": "2012-07-01",
        "nepDate": "17.03.2069"
    },
    {
        "engDate": "2012-07-02",
        "nepDate": "18.03.2069"
    },
    {
        "engDate": "2012-07-03",
        "nepDate": "19.03.2069"
    },
    {
        "engDate": "2012-07-04",
        "nepDate": "20.03.2069"
    },
    {
        "engDate": "2012-07-05",
        "nepDate": "21.03.2069"
    },
    {
        "engDate": "2012-07-06",
        "nepDate": "22.03.2069"
    },
    {
        "engDate": "2012-07-07",
        "nepDate": "23.03.2069"
    },
    {
        "engDate": "2012-07-08",
        "nepDate": "24.03.2069"
    },
    {
        "engDate": "2012-07-09",
        "nepDate": "25.03.2069"
    },
    {
        "engDate": "2012-07-10",
        "nepDate": "26.03.2069"
    },
    {
        "engDate": "2012-07-11",
        "nepDate": "27.03.2069"
    },
    {
        "engDate": "2012-07-12",
        "nepDate": "28.03.2069"
    },
    {
        "engDate": "2012-07-13",
        "nepDate": "29.03.2069"
    },
    {
        "engDate": "2012-07-14",
        "nepDate": "30.03.2069"
    },
    {
        "engDate": "2012-07-15",
        "nepDate": "31.03.2069"
    },
    {
        "engDate": "2012-07-16",
        "nepDate": "01.04.2069"
    },
    {
        "engDate": "2012-07-17",
        "nepDate": "02.04.2069"
    },
    {
        "engDate": "2012-07-18",
        "nepDate": "03.04.2069"
    },
    {
        "engDate": "2012-07-19",
        "nepDate": "04.04.2069"
    },
    {
        "engDate": "2012-07-20",
        "nepDate": "05.04.2069"
    },
    {
        "engDate": "2012-07-21",
        "nepDate": "06.04.2069"
    },
    {
        "engDate": "2012-07-22",
        "nepDate": "07.04.2069"
    },
    {
        "engDate": "2012-07-23",
        "nepDate": "08.04.2069"
    },
    {
        "engDate": "2012-07-24",
        "nepDate": "09.04.2069"
    },
    {
        "engDate": "2012-07-25",
        "nepDate": "10.04.2069"
    },
    {
        "engDate": "2012-07-26",
        "nepDate": "11.04.2069"
    },
    {
        "engDate": "2012-07-27",
        "nepDate": "12.04.2069"
    },
    {
        "engDate": "2012-07-28",
        "nepDate": "13.04.2069"
    },
    {
        "engDate": "2012-07-29",
        "nepDate": "14.04.2069"
    },
    {
        "engDate": "2012-07-30",
        "nepDate": "15.04.2069"
    },
    {
        "engDate": "2012-07-31",
        "nepDate": "16.04.2069"
    },
    {
        "engDate": "2012-08-01",
        "nepDate": "17.04.2069"
    },
    {
        "engDate": "2012-08-02",
        "nepDate": "18.04.2069"
    },
    {
        "engDate": "2012-08-03",
        "nepDate": "19.04.2069"
    },
    {
        "engDate": "2012-08-04",
        "nepDate": "20.04.2069"
    },
    {
        "engDate": "2012-08-05",
        "nepDate": "21.04.2069"
    },
    {
        "engDate": "2012-08-06",
        "nepDate": "22.04.2069"
    },
    {
        "engDate": "2012-08-07",
        "nepDate": "23.04.2069"
    },
    {
        "engDate": "2012-08-08",
        "nepDate": "24.04.2069"
    },
    {
        "engDate": "2012-08-09",
        "nepDate": "25.04.2069"
    },
    {
        "engDate": "2012-08-10",
        "nepDate": "26.04.2069"
    },
    {
        "engDate": "2012-08-11",
        "nepDate": "27.04.2069"
    },
    {
        "engDate": "2012-08-12",
        "nepDate": "28.04.2069"
    },
    {
        "engDate": "2012-08-13",
        "nepDate": "29.04.2069"
    },
    {
        "engDate": "2012-08-14",
        "nepDate": "30.04.2069"
    },
    {
        "engDate": "2012-08-15",
        "nepDate": "31.04.2069"
    },
    {
        "engDate": "2012-08-16",
        "nepDate": "32.04.2069"
    },
    {
        "engDate": "2012-08-17",
        "nepDate": "01.05.2069"
    },
    {
        "engDate": "2012-08-18",
        "nepDate": "02.05.2069"
    },
    {
        "engDate": "2012-08-19",
        "nepDate": "03.05.2069"
    },
    {
        "engDate": "2012-08-20",
        "nepDate": "04.05.2069"
    },
    {
        "engDate": "2012-08-21",
        "nepDate": "05.05.2069"
    },
    {
        "engDate": "2012-08-22",
        "nepDate": "06.05.2069"
    },
    {
        "engDate": "2012-08-23",
        "nepDate": "07.05.2069"
    },
    {
        "engDate": "2012-08-24",
        "nepDate": "08.05.2069"
    },
    {
        "engDate": "2012-08-25",
        "nepDate": "09.05.2069"
    },
    {
        "engDate": "2012-08-26",
        "nepDate": "10.05.2069"
    },
    {
        "engDate": "2012-08-27",
        "nepDate": "11.05.2069"
    },
    {
        "engDate": "2012-08-28",
        "nepDate": "12.05.2069"
    },
    {
        "engDate": "2012-08-29",
        "nepDate": "13.05.2069"
    },
    {
        "engDate": "2012-08-30",
        "nepDate": "14.05.2069"
    },
    {
        "engDate": "2012-08-31",
        "nepDate": "15.05.2069"
    },
    {
        "engDate": "2012-09-01",
        "nepDate": "16.05.2069"
    },
    {
        "engDate": "2012-09-02",
        "nepDate": "17.05.2069"
    },
    {
        "engDate": "2012-09-03",
        "nepDate": "18.05.2069"
    },
    {
        "engDate": "2012-09-04",
        "nepDate": "19.05.2069"
    },
    {
        "engDate": "2012-09-05",
        "nepDate": "20.05.2069"
    },
    {
        "engDate": "2012-09-06",
        "nepDate": "21.05.2069"
    },
    {
        "engDate": "2012-09-07",
        "nepDate": "22.05.2069"
    },
    {
        "engDate": "2012-09-08",
        "nepDate": "23.05.2069"
    },
    {
        "engDate": "2012-09-09",
        "nepDate": "24.05.2069"
    },
    {
        "engDate": "2012-09-10",
        "nepDate": "25.05.2069"
    },
    {
        "engDate": "2012-09-11",
        "nepDate": "26.05.2069"
    },
    {
        "engDate": "2012-09-12",
        "nepDate": "27.05.2069"
    },
    {
        "engDate": "2012-09-13",
        "nepDate": "28.05.2069"
    },
    {
        "engDate": "2012-09-14",
        "nepDate": "29.05.2069"
    },
    {
        "engDate": "2012-09-15",
        "nepDate": "30.05.2069"
    },
    {
        "engDate": "2012-09-16",
        "nepDate": "31.05.2069"
    },
    {
        "engDate": "2012-09-17",
        "nepDate": "01.06.2069"
    },
    {
        "engDate": "2012-09-18",
        "nepDate": "02.06.2069"
    },
    {
        "engDate": "2012-09-19",
        "nepDate": "03.06.2069"
    },
    {
        "engDate": "2012-09-20",
        "nepDate": "04.06.2069"
    },
    {
        "engDate": "2012-09-21",
        "nepDate": "05.06.2069"
    },
    {
        "engDate": "2012-09-22",
        "nepDate": "06.06.2069"
    },
    {
        "engDate": "2012-09-23",
        "nepDate": "07.06.2069"
    },
    {
        "engDate": "2012-09-24",
        "nepDate": "08.06.2069"
    },
    {
        "engDate": "2012-09-25",
        "nepDate": "09.06.2069"
    },
    {
        "engDate": "2012-09-26",
        "nepDate": "10.06.2069"
    },
    {
        "engDate": "2012-09-27",
        "nepDate": "11.06.2069"
    },
    {
        "engDate": "2012-09-28",
        "nepDate": "12.06.2069"
    },
    {
        "engDate": "2012-09-29",
        "nepDate": "13.06.2069"
    },
    {
        "engDate": "2012-09-30",
        "nepDate": "14.06.2069"
    },
    {
        "engDate": "2012-10-01",
        "nepDate": "15.06.2069"
    },
    {
        "engDate": "2012-10-02",
        "nepDate": "16.06.2069"
    },
    {
        "engDate": "2012-10-03",
        "nepDate": "17.06.2069"
    },
    {
        "engDate": "2012-10-04",
        "nepDate": "18.06.2069"
    },
    {
        "engDate": "2012-10-05",
        "nepDate": "19.06.2069"
    },
    {
        "engDate": "2012-10-06",
        "nepDate": "20.06.2069"
    },
    {
        "engDate": "2012-10-07",
        "nepDate": "21.06.2069"
    },
    {
        "engDate": "2012-10-08",
        "nepDate": "22.06.2069"
    },
    {
        "engDate": "2012-10-09",
        "nepDate": "23.06.2069"
    },
    {
        "engDate": "2012-10-10",
        "nepDate": "24.06.2069"
    },
    {
        "engDate": "2012-10-11",
        "nepDate": "25.06.2069"
    },
    {
        "engDate": "2012-10-12",
        "nepDate": "26.06.2069"
    },
    {
        "engDate": "2012-10-13",
        "nepDate": "27.06.2069"
    },
    {
        "engDate": "2012-10-14",
        "nepDate": "28.06.2069"
    },
    {
        "engDate": "2012-10-15",
        "nepDate": "29.06.2069"
    },
    {
        "engDate": "2012-10-16",
        "nepDate": "30.06.2069"
    },
    {
        "engDate": "2012-10-17",
        "nepDate": "01.07.2069"
    },
    {
        "engDate": "2012-10-18",
        "nepDate": "02.07.2069"
    },
    {
        "engDate": "2012-10-19",
        "nepDate": "03.07.2069"
    },
    {
        "engDate": "2012-10-20",
        "nepDate": "04.07.2069"
    },
    {
        "engDate": "2012-10-21",
        "nepDate": "05.07.2069"
    },
    {
        "engDate": "2012-10-22",
        "nepDate": "06.07.2069"
    },
    {
        "engDate": "2012-10-23",
        "nepDate": "07.07.2069"
    },
    {
        "engDate": "2012-10-24",
        "nepDate": "08.07.2069"
    },
    {
        "engDate": "2012-10-25",
        "nepDate": "09.07.2069"
    },
    {
        "engDate": "2012-10-26",
        "nepDate": "10.07.2069"
    },
    {
        "engDate": "2012-10-27",
        "nepDate": "11.07.2069"
    },
    {
        "engDate": "2012-10-28",
        "nepDate": "12.07.2069"
    },
    {
        "engDate": "2012-10-29",
        "nepDate": "13.07.2069"
    },
    {
        "engDate": "2012-10-30",
        "nepDate": "14.07.2069"
    },
    {
        "engDate": "2012-10-31",
        "nepDate": "15.07.2069"
    },
    {
        "engDate": "2012-11-01",
        "nepDate": "16.07.2069"
    },
    {
        "engDate": "2012-11-02",
        "nepDate": "17.07.2069"
    },
    {
        "engDate": "2012-11-03",
        "nepDate": "18.07.2069"
    },
    {
        "engDate": "2012-11-04",
        "nepDate": "19.07.2069"
    },
    {
        "engDate": "2012-11-05",
        "nepDate": "20.07.2069"
    },
    {
        "engDate": "2012-11-06",
        "nepDate": "21.07.2069"
    },
    {
        "engDate": "2012-11-07",
        "nepDate": "22.07.2069"
    },
    {
        "engDate": "2012-11-08",
        "nepDate": "23.07.2069"
    },
    {
        "engDate": "2012-11-09",
        "nepDate": "24.07.2069"
    },
    {
        "engDate": "2012-11-10",
        "nepDate": "25.07.2069"
    },
    {
        "engDate": "2012-11-11",
        "nepDate": "26.07.2069"
    },
    {
        "engDate": "2012-11-12",
        "nepDate": "27.07.2069"
    },
    {
        "engDate": "2012-11-13",
        "nepDate": "28.07.2069"
    },
    {
        "engDate": "2012-11-14",
        "nepDate": "29.07.2069"
    },
    {
        "engDate": "2012-11-15",
        "nepDate": "30.07.2069"
    },
    {
        "engDate": "2012-11-16",
        "nepDate": "01.08.2069"
    },
    {
        "engDate": "2012-11-17",
        "nepDate": "02.08.2069"
    },
    {
        "engDate": "2012-11-18",
        "nepDate": "03.08.2069"
    },
    {
        "engDate": "2012-11-19",
        "nepDate": "04.08.2069"
    },
    {
        "engDate": "2012-11-20",
        "nepDate": "05.08.2069"
    },
    {
        "engDate": "2012-11-21",
        "nepDate": "06.08.2069"
    },
    {
        "engDate": "2012-11-22",
        "nepDate": "07.08.2069"
    },
    {
        "engDate": "2012-11-23",
        "nepDate": "08.08.2069"
    },
    {
        "engDate": "2012-11-24",
        "nepDate": "09.08.2069"
    },
    {
        "engDate": "2012-11-25",
        "nepDate": "10.08.2069"
    },
    {
        "engDate": "2012-11-26",
        "nepDate": "11.08.2069"
    },
    {
        "engDate": "2012-11-27",
        "nepDate": "12.08.2069"
    },
    {
        "engDate": "2012-11-28",
        "nepDate": "13.08.2069"
    },
    {
        "engDate": "2012-11-29",
        "nepDate": "14.08.2069"
    },
    {
        "engDate": "2012-11-30",
        "nepDate": "15.08.2069"
    },
    {
        "engDate": "2012-12-01",
        "nepDate": "16.08.2069"
    },
    {
        "engDate": "2012-12-02",
        "nepDate": "17.08.2069"
    },
    {
        "engDate": "2012-12-03",
        "nepDate": "18.08.2069"
    },
    {
        "engDate": "2012-12-04",
        "nepDate": "19.08.2069"
    },
    {
        "engDate": "2012-12-05",
        "nepDate": "20.08.2069"
    },
    {
        "engDate": "2012-12-06",
        "nepDate": "21.08.2069"
    },
    {
        "engDate": "2012-12-07",
        "nepDate": "22.08.2069"
    },
    {
        "engDate": "2012-12-08",
        "nepDate": "23.08.2069"
    },
    {
        "engDate": "2012-12-09",
        "nepDate": "24.08.2069"
    },
    {
        "engDate": "2012-12-10",
        "nepDate": "25.08.2069"
    },
    {
        "engDate": "2012-12-11",
        "nepDate": "26.08.2069"
    },
    {
        "engDate": "2012-12-12",
        "nepDate": "27.08.2069"
    },
    {
        "engDate": "2012-12-13",
        "nepDate": "28.08.2069"
    },
    {
        "engDate": "2012-12-14",
        "nepDate": "29.08.2069"
    },
    {
        "engDate": "2012-12-15",
        "nepDate": "30.08.2069"
    },
    {
        "engDate": "2012-12-16",
        "nepDate": "01.09.2069"
    },
    {
        "engDate": "2012-12-17",
        "nepDate": "02.09.2069"
    },
    {
        "engDate": "2012-12-18",
        "nepDate": "03.09.2069"
    },
    {
        "engDate": "2012-12-19",
        "nepDate": "04.09.2069"
    },
    {
        "engDate": "2012-12-20",
        "nepDate": "05.09.2069"
    },
    {
        "engDate": "2012-12-21",
        "nepDate": "06.09.2069"
    },
    {
        "engDate": "2012-12-22",
        "nepDate": "07.09.2069"
    },
    {
        "engDate": "2012-12-23",
        "nepDate": "08.09.2069"
    },
    {
        "engDate": "2012-12-24",
        "nepDate": "09.09.2069"
    },
    {
        "engDate": "2012-12-25",
        "nepDate": "10.09.2069"
    },
    {
        "engDate": "2012-12-26",
        "nepDate": "11.09.2069"
    },
    {
        "engDate": "2012-12-27",
        "nepDate": "12.09.2069"
    },
    {
        "engDate": "2012-12-28",
        "nepDate": "13.09.2069"
    },
    {
        "engDate": "2012-12-29",
        "nepDate": "14.09.2069"
    },
    {
        "engDate": "2012-12-30",
        "nepDate": "15.09.2069"
    },
    {
        "engDate": "2012-12-31",
        "nepDate": "16.09.2069"
    },
    {
        "engDate": "2013-01-01",
        "nepDate": "17.09.2069"
    },
    {
        "engDate": "2013-01-02",
        "nepDate": "18.09.2069"
    },
    {
        "engDate": "2013-01-03",
        "nepDate": "19.09.2069"
    },
    {
        "engDate": "2013-01-04",
        "nepDate": "20.09.2069"
    },
    {
        "engDate": "2013-01-05",
        "nepDate": "21.09.2069"
    },
    {
        "engDate": "2013-01-06",
        "nepDate": "22.09.2069"
    },
    {
        "engDate": "2013-01-07",
        "nepDate": "23.09.2069"
    },
    {
        "engDate": "2013-01-08",
        "nepDate": "24.09.2069"
    },
    {
        "engDate": "2013-01-09",
        "nepDate": "25.09.2069"
    },
    {
        "engDate": "2013-01-10",
        "nepDate": "26.09.2069"
    },
    {
        "engDate": "2013-01-11",
        "nepDate": "27.09.2069"
    },
    {
        "engDate": "2013-01-12",
        "nepDate": "28.09.2069"
    },
    {
        "engDate": "2013-01-13",
        "nepDate": "29.09.2069"
    },
    {
        "engDate": "2013-01-14",
        "nepDate": "01.10.2069"
    },
    {
        "engDate": "2013-01-15",
        "nepDate": "02.10.2069"
    },
    {
        "engDate": "2013-01-16",
        "nepDate": "03.10.2069"
    },
    {
        "engDate": "2013-01-17",
        "nepDate": "04.10.2069"
    },
    {
        "engDate": "2013-01-18",
        "nepDate": "05.10.2069"
    },
    {
        "engDate": "2013-01-19",
        "nepDate": "06.10.2069"
    },
    {
        "engDate": "2013-01-20",
        "nepDate": "07.10.2069"
    },
    {
        "engDate": "2013-01-21",
        "nepDate": "08.10.2069"
    },
    {
        "engDate": "2013-01-22",
        "nepDate": "09.10.2069"
    },
    {
        "engDate": "2013-01-23",
        "nepDate": "10.10.2069"
    },
    {
        "engDate": "2013-01-24",
        "nepDate": "11.10.2069"
    },
    {
        "engDate": "2013-01-25",
        "nepDate": "12.10.2069"
    },
    {
        "engDate": "2013-01-26",
        "nepDate": "13.10.2069"
    },
    {
        "engDate": "2013-01-27",
        "nepDate": "14.10.2069"
    },
    {
        "engDate": "2013-01-28",
        "nepDate": "15.10.2069"
    },
    {
        "engDate": "2013-01-29",
        "nepDate": "16.10.2069"
    },
    {
        "engDate": "2013-01-30",
        "nepDate": "17.10.2069"
    },
    {
        "engDate": "2013-01-31",
        "nepDate": "18.10.2069"
    },
    {
        "engDate": "2013-02-01",
        "nepDate": "19.10.2069"
    },
    {
        "engDate": "2013-02-02",
        "nepDate": "20.10.2069"
    },
    {
        "engDate": "2013-02-03",
        "nepDate": "21.10.2069"
    },
    {
        "engDate": "2013-02-04",
        "nepDate": "22.10.2069"
    },
    {
        "engDate": "2013-02-05",
        "nepDate": "23.10.2069"
    },
    {
        "engDate": "2013-02-06",
        "nepDate": "24.10.2069"
    },
    {
        "engDate": "2013-02-07",
        "nepDate": "25.10.2069"
    },
    {
        "engDate": "2013-02-08",
        "nepDate": "26.10.2069"
    },
    {
        "engDate": "2013-02-09",
        "nepDate": "27.10.2069"
    },
    {
        "engDate": "2013-02-10",
        "nepDate": "28.10.2069"
    },
    {
        "engDate": "2013-02-11",
        "nepDate": "29.10.2069"
    },
    {
        "engDate": "2013-02-12",
        "nepDate": "01.11.2069"
    },
    {
        "engDate": "2013-02-13",
        "nepDate": "02.11.2069"
    },
    {
        "engDate": "2013-02-14",
        "nepDate": "03.11.2069"
    },
    {
        "engDate": "2013-02-15",
        "nepDate": "04.11.2069"
    },
    {
        "engDate": "2013-02-16",
        "nepDate": "05.11.2069"
    },
    {
        "engDate": "2013-02-17",
        "nepDate": "06.11.2069"
    },
    {
        "engDate": "2013-02-18",
        "nepDate": "07.11.2069"
    },
    {
        "engDate": "2013-02-19",
        "nepDate": "08.11.2069"
    },
    {
        "engDate": "2013-02-20",
        "nepDate": "09.11.2069"
    },
    {
        "engDate": "2013-02-21",
        "nepDate": "10.11.2069"
    },
    {
        "engDate": "2013-02-22",
        "nepDate": "11.11.2069"
    },
    {
        "engDate": "2013-02-23",
        "nepDate": "12.11.2069"
    },
    {
        "engDate": "2013-02-24",
        "nepDate": "13.11.2069"
    },
    {
        "engDate": "2013-02-25",
        "nepDate": "14.11.2069"
    },
    {
        "engDate": "2013-02-26",
        "nepDate": "15.11.2069"
    },
    {
        "engDate": "2013-02-27",
        "nepDate": "16.11.2069"
    },
    {
        "engDate": "2013-02-28",
        "nepDate": "17.11.2069"
    },
    {
        "engDate": "2013-03-01",
        "nepDate": "18.11.2069"
    },
    {
        "engDate": "2013-03-02",
        "nepDate": "19.11.2069"
    },
    {
        "engDate": "2013-03-03",
        "nepDate": "20.11.2069"
    },
    {
        "engDate": "2013-03-04",
        "nepDate": "21.11.2069"
    },
    {
        "engDate": "2013-03-05",
        "nepDate": "22.11.2069"
    },
    {
        "engDate": "2013-03-06",
        "nepDate": "23.11.2069"
    },
    {
        "engDate": "2013-03-07",
        "nepDate": "24.11.2069"
    },
    {
        "engDate": "2013-03-08",
        "nepDate": "25.11.2069"
    },
    {
        "engDate": "2013-03-09",
        "nepDate": "26.11.2069"
    },
    {
        "engDate": "2013-03-10",
        "nepDate": "27.11.2069"
    },
    {
        "engDate": "2013-03-11",
        "nepDate": "28.11.2069"
    },
    {
        "engDate": "2013-03-12",
        "nepDate": "29.11.2069"
    },
    {
        "engDate": "2013-03-13",
        "nepDate": "30.11.2069"
    },
    {
        "engDate": "2013-03-14",
        "nepDate": "01.12.2069"
    },
    {
        "engDate": "2013-03-15",
        "nepDate": "02.12.2069"
    },
    {
        "engDate": "2013-03-16",
        "nepDate": "03.12.2069"
    },
    {
        "engDate": "2013-03-17",
        "nepDate": "04.12.2069"
    },
    {
        "engDate": "2013-03-18",
        "nepDate": "05.12.2069"
    },
    {
        "engDate": "2013-03-19",
        "nepDate": "06.12.2069"
    },
    {
        "engDate": "2013-03-20",
        "nepDate": "07.12.2069"
    },
    {
        "engDate": "2013-03-21",
        "nepDate": "08.12.2069"
    },
    {
        "engDate": "2013-03-22",
        "nepDate": "09.12.2069"
    },
    {
        "engDate": "2013-03-23",
        "nepDate": "10.12.2069"
    },
    {
        "engDate": "2013-03-24",
        "nepDate": "11.12.2069"
    },
    {
        "engDate": "2013-03-25",
        "nepDate": "12.12.2069"
    },
    {
        "engDate": "2013-03-26",
        "nepDate": "13.12.2069"
    },
    {
        "engDate": "2013-03-27",
        "nepDate": "14.12.2069"
    },
    {
        "engDate": "2013-03-28",
        "nepDate": "15.12.2069"
    },
    {
        "engDate": "2013-03-29",
        "nepDate": "16.12.2069"
    },
    {
        "engDate": "2013-03-30",
        "nepDate": "17.12.2069"
    },
    {
        "engDate": "2013-03-31",
        "nepDate": "18.12.2069"
    },
    {
        "engDate": "2013-04-01",
        "nepDate": "19.12.2069"
    },
    {
        "engDate": "2013-04-02",
        "nepDate": "20.12.2069"
    },
    {
        "engDate": "2013-04-03",
        "nepDate": "21.12.2069"
    },
    {
        "engDate": "2013-04-04",
        "nepDate": "22.12.2069"
    },
    {
        "engDate": "2013-04-05",
        "nepDate": "23.12.2069"
    },
    {
        "engDate": "2013-04-06",
        "nepDate": "24.12.2069"
    },
    {
        "engDate": "2013-04-07",
        "nepDate": "25.12.2069"
    },
    {
        "engDate": "2013-04-08",
        "nepDate": "26.12.2069"
    },
    {
        "engDate": "2013-04-09",
        "nepDate": "27.12.2069"
    },
    {
        "engDate": "2013-04-10",
        "nepDate": "28.12.2069"
    },
    {
        "engDate": "2013-04-11",
        "nepDate": "29.12.2069"
    },
    {
        "engDate": "2013-04-12",
        "nepDate": "30.12.2069"
    },
    {
        "engDate": "2013-04-13",
        "nepDate": "31.12.2069"
    },
    {
        "engDate": "2013-04-14",
        "nepDate": "01.01.2070"
    },
    {
        "engDate": "2013-04-15",
        "nepDate": "02.01.2070"
    },
    {
        "engDate": "2013-04-16",
        "nepDate": "03.01.2070"
    },
    {
        "engDate": "2013-04-17",
        "nepDate": "04.01.2070"
    },
    {
        "engDate": "2013-04-18",
        "nepDate": "05.01.2070"
    },
    {
        "engDate": "2013-04-19",
        "nepDate": "06.01.2070"
    },
    {
        "engDate": "2013-04-20",
        "nepDate": "07.01.2070"
    },
    {
        "engDate": "2013-04-21",
        "nepDate": "08.01.2070"
    },
    {
        "engDate": "2013-04-22",
        "nepDate": "09.01.2070"
    },
    {
        "engDate": "2013-04-23",
        "nepDate": "10.01.2070"
    },
    {
        "engDate": "2013-04-24",
        "nepDate": "11.01.2070"
    },
    {
        "engDate": "2013-04-25",
        "nepDate": "12.01.2070"
    },
    {
        "engDate": "2013-04-26",
        "nepDate": "13.01.2070"
    },
    {
        "engDate": "2013-04-27",
        "nepDate": "14.01.2070"
    },
    {
        "engDate": "2013-04-28",
        "nepDate": "15.01.2070"
    },
    {
        "engDate": "2013-04-29",
        "nepDate": "16.01.2070"
    },
    {
        "engDate": "2013-04-30",
        "nepDate": "17.01.2070"
    },
    {
        "engDate": "2013-05-01",
        "nepDate": "18.01.2070"
    },
    {
        "engDate": "2013-05-02",
        "nepDate": "19.01.2070"
    },
    {
        "engDate": "2013-05-03",
        "nepDate": "20.01.2070"
    },
    {
        "engDate": "2013-05-04",
        "nepDate": "21.01.2070"
    },
    {
        "engDate": "2013-05-05",
        "nepDate": "22.01.2070"
    },
    {
        "engDate": "2013-05-06",
        "nepDate": "23.01.2070"
    },
    {
        "engDate": "2013-05-07",
        "nepDate": "24.01.2070"
    },
    {
        "engDate": "2013-05-08",
        "nepDate": "25.01.2070"
    },
    {
        "engDate": "2013-05-09",
        "nepDate": "26.01.2070"
    },
    {
        "engDate": "2013-05-10",
        "nepDate": "27.01.2070"
    },
    {
        "engDate": "2013-05-11",
        "nepDate": "28.01.2070"
    },
    {
        "engDate": "2013-05-12",
        "nepDate": "29.01.2070"
    },
    {
        "engDate": "2013-05-13",
        "nepDate": "30.01.2070"
    },
    {
        "engDate": "2013-05-14",
        "nepDate": "31.01.2070"
    },
    {
        "engDate": "2013-05-15",
        "nepDate": "01.02.2070"
    },
    {
        "engDate": "2013-05-16",
        "nepDate": "02.02.2070"
    },
    {
        "engDate": "2013-05-17",
        "nepDate": "03.02.2070"
    },
    {
        "engDate": "2013-05-18",
        "nepDate": "04.02.2070"
    },
    {
        "engDate": "2013-05-19",
        "nepDate": "05.02.2070"
    },
    {
        "engDate": "2013-05-20",
        "nepDate": "06.02.2070"
    },
    {
        "engDate": "2013-05-21",
        "nepDate": "07.02.2070"
    },
    {
        "engDate": "2013-05-22",
        "nepDate": "08.02.2070"
    },
    {
        "engDate": "2013-05-23",
        "nepDate": "09.02.2070"
    },
    {
        "engDate": "2013-05-24",
        "nepDate": "10.02.2070"
    },
    {
        "engDate": "2013-05-25",
        "nepDate": "11.02.2070"
    },
    {
        "engDate": "2013-05-26",
        "nepDate": "12.02.2070"
    },
    {
        "engDate": "2013-05-27",
        "nepDate": "13.02.2070"
    },
    {
        "engDate": "2013-05-28",
        "nepDate": "14.02.2070"
    },
    {
        "engDate": "2013-05-29",
        "nepDate": "15.02.2070"
    },
    {
        "engDate": "2013-05-30",
        "nepDate": "16.02.2070"
    },
    {
        "engDate": "2013-05-31",
        "nepDate": "17.02.2070"
    },
    {
        "engDate": "2013-06-01",
        "nepDate": "18.02.2070"
    },
    {
        "engDate": "2013-06-02",
        "nepDate": "19.02.2070"
    },
    {
        "engDate": "2013-06-03",
        "nepDate": "20.02.2070"
    },
    {
        "engDate": "2013-06-04",
        "nepDate": "21.02.2070"
    },
    {
        "engDate": "2013-06-05",
        "nepDate": "22.02.2070"
    },
    {
        "engDate": "2013-06-06",
        "nepDate": "23.02.2070"
    },
    {
        "engDate": "2013-06-07",
        "nepDate": "24.02.2070"
    },
    {
        "engDate": "2013-06-08",
        "nepDate": "25.02.2070"
    },
    {
        "engDate": "2013-06-09",
        "nepDate": "26.02.2070"
    },
    {
        "engDate": "2013-06-10",
        "nepDate": "27.02.2070"
    },
    {
        "engDate": "2013-06-11",
        "nepDate": "28.02.2070"
    },
    {
        "engDate": "2013-06-12",
        "nepDate": "29.02.2070"
    },
    {
        "engDate": "2013-06-13",
        "nepDate": "30.02.2070"
    },
    {
        "engDate": "2013-06-14",
        "nepDate": "31.02.2070"
    },
    {
        "engDate": "2013-06-15",
        "nepDate": "01.03.2070"
    },
    {
        "engDate": "2013-06-16",
        "nepDate": "02.03.2070"
    },
    {
        "engDate": "2013-06-17",
        "nepDate": "03.03.2070"
    },
    {
        "engDate": "2013-06-18",
        "nepDate": "04.03.2070"
    },
    {
        "engDate": "2013-06-19",
        "nepDate": "05.03.2070"
    },
    {
        "engDate": "2013-06-20",
        "nepDate": "06.03.2070"
    },
    {
        "engDate": "2013-06-21",
        "nepDate": "07.03.2070"
    },
    {
        "engDate": "2013-06-22",
        "nepDate": "08.03.2070"
    },
    {
        "engDate": "2013-06-23",
        "nepDate": "09.03.2070"
    },
    {
        "engDate": "2013-06-24",
        "nepDate": "10.03.2070"
    },
    {
        "engDate": "2013-06-25",
        "nepDate": "11.03.2070"
    },
    {
        "engDate": "2013-06-26",
        "nepDate": "12.03.2070"
    },
    {
        "engDate": "2013-06-27",
        "nepDate": "13.03.2070"
    },
    {
        "engDate": "2013-06-28",
        "nepDate": "14.03.2070"
    },
    {
        "engDate": "2013-06-29",
        "nepDate": "15.03.2070"
    },
    {
        "engDate": "2013-06-30",
        "nepDate": "16.03.2070"
    },
    {
        "engDate": "2013-07-01",
        "nepDate": "17.03.2070"
    },
    {
        "engDate": "2013-07-02",
        "nepDate": "18.03.2070"
    },
    {
        "engDate": "2013-07-03",
        "nepDate": "19.03.2070"
    },
    {
        "engDate": "2013-07-04",
        "nepDate": "20.03.2070"
    },
    {
        "engDate": "2013-07-05",
        "nepDate": "21.03.2070"
    },
    {
        "engDate": "2013-07-06",
        "nepDate": "22.03.2070"
    },
    {
        "engDate": "2013-07-07",
        "nepDate": "23.03.2070"
    },
    {
        "engDate": "2013-07-08",
        "nepDate": "24.03.2070"
    },
    {
        "engDate": "2013-07-09",
        "nepDate": "25.03.2070"
    },
    {
        "engDate": "2013-07-10",
        "nepDate": "26.03.2070"
    },
    {
        "engDate": "2013-07-11",
        "nepDate": "27.03.2070"
    },
    {
        "engDate": "2013-07-12",
        "nepDate": "28.03.2070"
    },
    {
        "engDate": "2013-07-13",
        "nepDate": "29.03.2070"
    },
    {
        "engDate": "2013-07-14",
        "nepDate": "30.03.2070"
    },
    {
        "engDate": "2013-07-15",
        "nepDate": "31.03.2070"
    },
    {
        "engDate": "2013-07-16",
        "nepDate": "01.04.2070"
    },
    {
        "engDate": "2013-07-17",
        "nepDate": "02.04.2070"
    },
    {
        "engDate": "2013-07-18",
        "nepDate": "03.04.2070"
    },
    {
        "engDate": "2013-07-19",
        "nepDate": "04.04.2070"
    },
    {
        "engDate": "2013-07-20",
        "nepDate": "05.04.2070"
    },
    {
        "engDate": "2013-07-21",
        "nepDate": "06.04.2070"
    },
    {
        "engDate": "2013-07-22",
        "nepDate": "07.04.2070"
    },
    {
        "engDate": "2013-07-23",
        "nepDate": "08.04.2070"
    },
    {
        "engDate": "2013-07-24",
        "nepDate": "09.04.2070"
    },
    {
        "engDate": "2013-07-25",
        "nepDate": "10.04.2070"
    },
    {
        "engDate": "2013-07-26",
        "nepDate": "11.04.2070"
    },
    {
        "engDate": "2013-07-27",
        "nepDate": "12.04.2070"
    },
    {
        "engDate": "2013-07-28",
        "nepDate": "13.04.2070"
    },
    {
        "engDate": "2013-07-29",
        "nepDate": "14.04.2070"
    },
    {
        "engDate": "2013-07-30",
        "nepDate": "15.04.2070"
    },
    {
        "engDate": "2013-07-31",
        "nepDate": "16.04.2070"
    },
    {
        "engDate": "2013-08-01",
        "nepDate": "17.04.2070"
    },
    {
        "engDate": "2013-08-02",
        "nepDate": "18.04.2070"
    },
    {
        "engDate": "2013-08-03",
        "nepDate": "19.04.2070"
    },
    {
        "engDate": "2013-08-04",
        "nepDate": "20.04.2070"
    },
    {
        "engDate": "2013-08-05",
        "nepDate": "21.04.2070"
    },
    {
        "engDate": "2013-08-06",
        "nepDate": "22.04.2070"
    },
    {
        "engDate": "2013-08-07",
        "nepDate": "23.04.2070"
    },
    {
        "engDate": "2013-08-08",
        "nepDate": "24.04.2070"
    },
    {
        "engDate": "2013-08-09",
        "nepDate": "25.04.2070"
    },
    {
        "engDate": "2013-08-10",
        "nepDate": "26.04.2070"
    },
    {
        "engDate": "2013-08-11",
        "nepDate": "27.04.2070"
    },
    {
        "engDate": "2013-08-12",
        "nepDate": "28.04.2070"
    },
    {
        "engDate": "2013-08-13",
        "nepDate": "29.04.2070"
    },
    {
        "engDate": "2013-08-14",
        "nepDate": "30.04.2070"
    },
    {
        "engDate": "2013-08-15",
        "nepDate": "31.04.2070"
    },
    {
        "engDate": "2013-08-16",
        "nepDate": "32.04.2070"
    },
    {
        "engDate": "2013-08-17",
        "nepDate": "01.05.2070"
    },
    {
        "engDate": "2013-08-18",
        "nepDate": "02.05.2070"
    },
    {
        "engDate": "2013-08-19",
        "nepDate": "03.05.2070"
    },
    {
        "engDate": "2013-08-20",
        "nepDate": "04.05.2070"
    },
    {
        "engDate": "2013-08-21",
        "nepDate": "05.05.2070"
    },
    {
        "engDate": "2013-08-22",
        "nepDate": "06.05.2070"
    },
    {
        "engDate": "2013-08-23",
        "nepDate": "07.05.2070"
    },
    {
        "engDate": "2013-08-24",
        "nepDate": "08.05.2070"
    },
    {
        "engDate": "2013-08-25",
        "nepDate": "09.05.2070"
    },
    {
        "engDate": "2013-08-26",
        "nepDate": "10.05.2070"
    },
    {
        "engDate": "2013-08-27",
        "nepDate": "11.05.2070"
    },
    {
        "engDate": "2013-08-28",
        "nepDate": "12.05.2070"
    },
    {
        "engDate": "2013-08-29",
        "nepDate": "13.05.2070"
    },
    {
        "engDate": "2013-08-30",
        "nepDate": "14.05.2070"
    },
    {
        "engDate": "2013-08-31",
        "nepDate": "15.05.2070"
    },
    {
        "engDate": "2013-09-01",
        "nepDate": "16.05.2070"
    },
    {
        "engDate": "2013-09-02",
        "nepDate": "17.05.2070"
    },
    {
        "engDate": "2013-09-03",
        "nepDate": "18.05.2070"
    },
    {
        "engDate": "2013-09-04",
        "nepDate": "19.05.2070"
    },
    {
        "engDate": "2013-09-05",
        "nepDate": "20.05.2070"
    },
    {
        "engDate": "2013-09-06",
        "nepDate": "21.05.2070"
    },
    {
        "engDate": "2013-09-07",
        "nepDate": "22.05.2070"
    },
    {
        "engDate": "2013-09-08",
        "nepDate": "23.05.2070"
    },
    {
        "engDate": "2013-09-09",
        "nepDate": "24.05.2070"
    },
    {
        "engDate": "2013-09-10",
        "nepDate": "25.05.2070"
    },
    {
        "engDate": "2013-09-11",
        "nepDate": "26.05.2070"
    },
    {
        "engDate": "2013-09-12",
        "nepDate": "27.05.2070"
    },
    {
        "engDate": "2013-09-13",
        "nepDate": "28.05.2070"
    },
    {
        "engDate": "2013-09-14",
        "nepDate": "29.05.2070"
    },
    {
        "engDate": "2013-09-15",
        "nepDate": "30.05.2070"
    },
    {
        "engDate": "2013-09-16",
        "nepDate": "31.05.2070"
    },
    {
        "engDate": "2013-09-17",
        "nepDate": "01.06.2070"
    },
    {
        "engDate": "2013-09-18",
        "nepDate": "02.06.2070"
    },
    {
        "engDate": "2013-09-19",
        "nepDate": "03.06.2070"
    },
    {
        "engDate": "2013-09-20",
        "nepDate": "04.06.2070"
    },
    {
        "engDate": "2013-09-21",
        "nepDate": "05.06.2070"
    },
    {
        "engDate": "2013-09-22",
        "nepDate": "06.06.2070"
    },
    {
        "engDate": "2013-09-23",
        "nepDate": "07.06.2070"
    },
    {
        "engDate": "2013-09-24",
        "nepDate": "08.06.2070"
    },
    {
        "engDate": "2013-09-25",
        "nepDate": "09.06.2070"
    },
    {
        "engDate": "2013-09-26",
        "nepDate": "10.06.2070"
    },
    {
        "engDate": "2013-09-27",
        "nepDate": "11.06.2070"
    },
    {
        "engDate": "2013-09-28",
        "nepDate": "12.06.2070"
    },
    {
        "engDate": "2013-09-29",
        "nepDate": "13.06.2070"
    },
    {
        "engDate": "2013-09-30",
        "nepDate": "14.06.2070"
    },
    {
        "engDate": "2013-10-01",
        "nepDate": "15.06.2070"
    },
    {
        "engDate": "2013-10-02",
        "nepDate": "16.06.2070"
    },
    {
        "engDate": "2013-10-03",
        "nepDate": "17.06.2070"
    },
    {
        "engDate": "2013-10-04",
        "nepDate": "18.06.2070"
    },
    {
        "engDate": "2013-10-05",
        "nepDate": "19.06.2070"
    },
    {
        "engDate": "2013-10-06",
        "nepDate": "20.06.2070"
    },
    {
        "engDate": "2013-10-07",
        "nepDate": "21.06.2070"
    },
    {
        "engDate": "2013-10-08",
        "nepDate": "22.06.2070"
    },
    {
        "engDate": "2013-10-09",
        "nepDate": "23.06.2070"
    },
    {
        "engDate": "2013-10-10",
        "nepDate": "24.06.2070"
    },
    {
        "engDate": "2013-10-11",
        "nepDate": "25.06.2070"
    },
    {
        "engDate": "2013-10-12",
        "nepDate": "26.06.2070"
    },
    {
        "engDate": "2013-10-13",
        "nepDate": "27.06.2070"
    },
    {
        "engDate": "2013-10-14",
        "nepDate": "28.06.2070"
    },
    {
        "engDate": "2013-10-15",
        "nepDate": "29.06.2070"
    },
    {
        "engDate": "2013-10-16",
        "nepDate": "30.06.2070"
    },
    {
        "engDate": "2013-10-17",
        "nepDate": "31.06.2070"
    },
    {
        "engDate": "2013-10-18",
        "nepDate": "01.07.2070"
    },
    {
        "engDate": "2013-10-19",
        "nepDate": "02.07.2070"
    },
    {
        "engDate": "2013-10-20",
        "nepDate": "03.07.2070"
    },
    {
        "engDate": "2013-10-21",
        "nepDate": "04.07.2070"
    },
    {
        "engDate": "2013-10-22",
        "nepDate": "05.07.2070"
    },
    {
        "engDate": "2013-10-23",
        "nepDate": "06.07.2070"
    },
    {
        "engDate": "2013-10-24",
        "nepDate": "07.07.2070"
    },
    {
        "engDate": "2013-10-25",
        "nepDate": "08.07.2070"
    },
    {
        "engDate": "2013-10-26",
        "nepDate": "09.07.2070"
    },
    {
        "engDate": "2013-10-27",
        "nepDate": "10.07.2070"
    },
    {
        "engDate": "2013-10-28",
        "nepDate": "11.07.2070"
    },
    {
        "engDate": "2013-10-29",
        "nepDate": "12.07.2070"
    },
    {
        "engDate": "2013-10-30",
        "nepDate": "13.07.2070"
    },
    {
        "engDate": "2013-10-31",
        "nepDate": "14.07.2070"
    },
    {
        "engDate": "2013-11-01",
        "nepDate": "15.07.2070"
    },
    {
        "engDate": "2013-11-02",
        "nepDate": "16.07.2070"
    },
    {
        "engDate": "2013-11-03",
        "nepDate": "17.07.2070"
    },
    {
        "engDate": "2013-11-04",
        "nepDate": "18.07.2070"
    },
    {
        "engDate": "2013-11-05",
        "nepDate": "19.07.2070"
    },
    {
        "engDate": "2013-11-06",
        "nepDate": "20.07.2070"
    },
    {
        "engDate": "2013-11-07",
        "nepDate": "21.07.2070"
    },
    {
        "engDate": "2013-11-08",
        "nepDate": "22.07.2070"
    },
    {
        "engDate": "2013-11-09",
        "nepDate": "23.07.2070"
    },
    {
        "engDate": "2013-11-10",
        "nepDate": "24.07.2070"
    },
    {
        "engDate": "2013-11-11",
        "nepDate": "25.07.2070"
    },
    {
        "engDate": "2013-11-12",
        "nepDate": "26.07.2070"
    },
    {
        "engDate": "2013-11-13",
        "nepDate": "27.07.2070"
    },
    {
        "engDate": "2013-11-14",
        "nepDate": "28.07.2070"
    },
    {
        "engDate": "2013-11-15",
        "nepDate": "29.07.2070"
    },
    {
        "engDate": "2013-11-16",
        "nepDate": "01.08.2070"
    },
    {
        "engDate": "2013-11-17",
        "nepDate": "02.08.2070"
    },
    {
        "engDate": "2013-11-18",
        "nepDate": "03.08.2070"
    },
    {
        "engDate": "2013-11-19",
        "nepDate": "04.08.2070"
    },
    {
        "engDate": "2013-11-20",
        "nepDate": "05.08.2070"
    },
    {
        "engDate": "2013-11-21",
        "nepDate": "06.08.2070"
    },
    {
        "engDate": "2013-11-22",
        "nepDate": "07.08.2070"
    },
    {
        "engDate": "2013-11-23",
        "nepDate": "08.08.2070"
    },
    {
        "engDate": "2013-11-24",
        "nepDate": "09.08.2070"
    },
    {
        "engDate": "2013-11-25",
        "nepDate": "10.08.2070"
    },
    {
        "engDate": "2013-11-26",
        "nepDate": "11.08.2070"
    },
    {
        "engDate": "2013-11-27",
        "nepDate": "12.08.2070"
    },
    {
        "engDate": "2013-11-28",
        "nepDate": "13.08.2070"
    },
    {
        "engDate": "2013-11-29",
        "nepDate": "14.08.2070"
    },
    {
        "engDate": "2013-11-30",
        "nepDate": "15.08.2070"
    },
    {
        "engDate": "2013-12-01",
        "nepDate": "16.08.2070"
    },
    {
        "engDate": "2013-12-02",
        "nepDate": "17.08.2070"
    },
    {
        "engDate": "2013-12-03",
        "nepDate": "18.08.2070"
    },
    {
        "engDate": "2013-12-04",
        "nepDate": "19.08.2070"
    },
    {
        "engDate": "2013-12-05",
        "nepDate": "20.08.2070"
    },
    {
        "engDate": "2013-12-06",
        "nepDate": "21.08.2070"
    },
    {
        "engDate": "2013-12-07",
        "nepDate": "22.08.2070"
    },
    {
        "engDate": "2013-12-08",
        "nepDate": "23.08.2070"
    },
    {
        "engDate": "2013-12-09",
        "nepDate": "24.08.2070"
    },
    {
        "engDate": "2013-12-10",
        "nepDate": "25.08.2070"
    },
    {
        "engDate": "2013-12-11",
        "nepDate": "26.08.2070"
    },
    {
        "engDate": "2013-12-12",
        "nepDate": "27.08.2070"
    },
    {
        "engDate": "2013-12-13",
        "nepDate": "28.08.2070"
    },
    {
        "engDate": "2013-12-14",
        "nepDate": "29.08.2070"
    },
    {
        "engDate": "2013-12-15",
        "nepDate": "30.08.2070"
    },
    {
        "engDate": "2013-12-16",
        "nepDate": "01.09.2070"
    },
    {
        "engDate": "2013-12-17",
        "nepDate": "02.09.2070"
    },
    {
        "engDate": "2013-12-18",
        "nepDate": "03.09.2070"
    },
    {
        "engDate": "2013-12-19",
        "nepDate": "04.09.2070"
    },
    {
        "engDate": "2013-12-20",
        "nepDate": "05.09.2070"
    },
    {
        "engDate": "2013-12-21",
        "nepDate": "06.09.2070"
    },
    {
        "engDate": "2013-12-22",
        "nepDate": "07.09.2070"
    },
    {
        "engDate": "2013-12-23",
        "nepDate": "08.09.2070"
    },
    {
        "engDate": "2013-12-24",
        "nepDate": "09.09.2070"
    },
    {
        "engDate": "2013-12-25",
        "nepDate": "10.09.2070"
    },
    {
        "engDate": "2013-12-26",
        "nepDate": "11.09.2070"
    },
    {
        "engDate": "2013-12-27",
        "nepDate": "12.09.2070"
    },
    {
        "engDate": "2013-12-28",
        "nepDate": "13.09.2070"
    },
    {
        "engDate": "2013-12-29",
        "nepDate": "14.09.2070"
    },
    {
        "engDate": "2013-12-30",
        "nepDate": "15.09.2070"
    },
    {
        "engDate": "2013-12-31",
        "nepDate": "16.09.2070"
    },
    {
        "engDate": "2014-01-01",
        "nepDate": "17.09.2070"
    },
    {
        "engDate": "2014-01-02",
        "nepDate": "18.09.2070"
    },
    {
        "engDate": "2014-01-03",
        "nepDate": "19.09.2070"
    },
    {
        "engDate": "2014-01-04",
        "nepDate": "20.09.2070"
    },
    {
        "engDate": "2014-01-05",
        "nepDate": "21.09.2070"
    },
    {
        "engDate": "2014-01-06",
        "nepDate": "22.09.2070"
    },
    {
        "engDate": "2014-01-07",
        "nepDate": "23.09.2070"
    },
    {
        "engDate": "2014-01-08",
        "nepDate": "24.09.2070"
    },
    {
        "engDate": "2014-01-09",
        "nepDate": "25.09.2070"
    },
    {
        "engDate": "2014-01-10",
        "nepDate": "26.09.2070"
    },
    {
        "engDate": "2014-01-11",
        "nepDate": "27.09.2070"
    },
    {
        "engDate": "2014-01-12",
        "nepDate": "28.09.2070"
    },
    {
        "engDate": "2014-01-13",
        "nepDate": "29.09.2070"
    },
    {
        "engDate": "2014-01-14",
        "nepDate": "30.09.2070"
    },
    {
        "engDate": "2014-01-15",
        "nepDate": "01.10.2070"
    },
    {
        "engDate": "2014-01-16",
        "nepDate": "02.10.2070"
    },
    {
        "engDate": "2014-01-17",
        "nepDate": "03.10.2070"
    },
    {
        "engDate": "2014-01-18",
        "nepDate": "04.10.2070"
    },
    {
        "engDate": "2014-01-19",
        "nepDate": "05.10.2070"
    },
    {
        "engDate": "2014-01-20",
        "nepDate": "06.10.2070"
    },
    {
        "engDate": "2014-01-21",
        "nepDate": "07.10.2070"
    },
    {
        "engDate": "2014-01-22",
        "nepDate": "08.10.2070"
    },
    {
        "engDate": "2014-01-23",
        "nepDate": "09.10.2070"
    },
    {
        "engDate": "2014-01-24",
        "nepDate": "10.10.2070"
    },
    {
        "engDate": "2014-01-25",
        "nepDate": "11.10.2070"
    },
    {
        "engDate": "2014-01-26",
        "nepDate": "12.10.2070"
    },
    {
        "engDate": "2014-01-27",
        "nepDate": "13.10.2070"
    },
    {
        "engDate": "2014-01-28",
        "nepDate": "14.10.2070"
    },
    {
        "engDate": "2014-01-29",
        "nepDate": "15.10.2070"
    },
    {
        "engDate": "2014-01-30",
        "nepDate": "16.10.2070"
    },
    {
        "engDate": "2014-01-31",
        "nepDate": "17.10.2070"
    },
    {
        "engDate": "2014-02-01",
        "nepDate": "18.10.2070"
    },
    {
        "engDate": "2014-02-02",
        "nepDate": "19.10.2070"
    },
    {
        "engDate": "2014-02-03",
        "nepDate": "20.10.2070"
    },
    {
        "engDate": "2014-02-04",
        "nepDate": "21.10.2070"
    },
    {
        "engDate": "2014-02-05",
        "nepDate": "22.10.2070"
    },
    {
        "engDate": "2014-02-06",
        "nepDate": "23.10.2070"
    },
    {
        "engDate": "2014-02-07",
        "nepDate": "24.10.2070"
    },
    {
        "engDate": "2014-02-08",
        "nepDate": "25.10.2070"
    },
    {
        "engDate": "2014-02-09",
        "nepDate": "26.10.2070"
    },
    {
        "engDate": "2014-02-10",
        "nepDate": "27.10.2070"
    },
    {
        "engDate": "2014-02-11",
        "nepDate": "28.10.2070"
    },
    {
        "engDate": "2014-02-12",
        "nepDate": "29.10.2070"
    },
    {
        "engDate": "2014-02-13",
        "nepDate": "01.11.2070"
    },
    {
        "engDate": "2014-02-14",
        "nepDate": "02.11.2070"
    },
    {
        "engDate": "2014-02-15",
        "nepDate": "03.11.2070"
    },
    {
        "engDate": "2014-02-16",
        "nepDate": "04.11.2070"
    },
    {
        "engDate": "2014-02-17",
        "nepDate": "05.11.2070"
    },
    {
        "engDate": "2014-02-18",
        "nepDate": "06.11.2070"
    },
    {
        "engDate": "2014-02-19",
        "nepDate": "07.11.2070"
    },
    {
        "engDate": "2014-02-20",
        "nepDate": "08.11.2070"
    },
    {
        "engDate": "2014-02-21",
        "nepDate": "09.11.2070"
    },
    {
        "engDate": "2014-02-22",
        "nepDate": "10.11.2070"
    },
    {
        "engDate": "2014-02-23",
        "nepDate": "11.11.2070"
    },
    {
        "engDate": "2014-02-24",
        "nepDate": "12.11.2070"
    },
    {
        "engDate": "2014-02-25",
        "nepDate": "13.11.2070"
    },
    {
        "engDate": "2014-02-26",
        "nepDate": "14.11.2070"
    },
    {
        "engDate": "2014-02-27",
        "nepDate": "15.11.2070"
    },
    {
        "engDate": "2014-02-28",
        "nepDate": "16.11.2070"
    },
    {
        "engDate": "2014-03-01",
        "nepDate": "17.11.2070"
    },
    {
        "engDate": "2014-03-02",
        "nepDate": "18.11.2070"
    },
    {
        "engDate": "2014-03-03",
        "nepDate": "19.11.2070"
    },
    {
        "engDate": "2014-03-04",
        "nepDate": "20.11.2070"
    },
    {
        "engDate": "2014-03-05",
        "nepDate": "21.11.2070"
    },
    {
        "engDate": "2014-03-06",
        "nepDate": "22.11.2070"
    },
    {
        "engDate": "2014-03-07",
        "nepDate": "23.11.2070"
    },
    {
        "engDate": "2014-03-08",
        "nepDate": "24.11.2070"
    },
    {
        "engDate": "2014-03-09",
        "nepDate": "25.11.2070"
    },
    {
        "engDate": "2014-03-10",
        "nepDate": "26.11.2070"
    },
    {
        "engDate": "2014-03-11",
        "nepDate": "27.11.2070"
    },
    {
        "engDate": "2014-03-12",
        "nepDate": "28.11.2070"
    },
    {
        "engDate": "2014-03-13",
        "nepDate": "29.11.2070"
    },
    {
        "engDate": "2014-03-14",
        "nepDate": "30.11.2070"
    },
    {
        "engDate": "2014-03-15",
        "nepDate": "01.12.2070"
    },
    {
        "engDate": "2014-03-16",
        "nepDate": "02.12.2070"
    },
    {
        "engDate": "2014-03-17",
        "nepDate": "03.12.2070"
    },
    {
        "engDate": "2014-03-18",
        "nepDate": "04.12.2070"
    },
    {
        "engDate": "2014-03-19",
        "nepDate": "05.12.2070"
    },
    {
        "engDate": "2014-03-20",
        "nepDate": "06.12.2070"
    },
    {
        "engDate": "2014-03-21",
        "nepDate": "07.12.2070"
    },
    {
        "engDate": "2014-03-22",
        "nepDate": "08.12.2070"
    },
    {
        "engDate": "2014-03-23",
        "nepDate": "09.12.2070"
    },
    {
        "engDate": "2014-03-24",
        "nepDate": "10.12.2070"
    },
    {
        "engDate": "2014-03-25",
        "nepDate": "11.12.2070"
    },
    {
        "engDate": "2014-03-26",
        "nepDate": "12.12.2070"
    },
    {
        "engDate": "2014-03-27",
        "nepDate": "13.12.2070"
    },
    {
        "engDate": "2014-03-28",
        "nepDate": "14.12.2070"
    },
    {
        "engDate": "2014-03-29",
        "nepDate": "15.12.2070"
    },
    {
        "engDate": "2014-03-30",
        "nepDate": "16.12.2070"
    },
    {
        "engDate": "2014-03-31",
        "nepDate": "17.12.2070"
    },
    {
        "engDate": "2014-04-01",
        "nepDate": "18.12.2070"
    },
    {
        "engDate": "2014-04-02",
        "nepDate": "19.12.2070"
    },
    {
        "engDate": "2014-04-03",
        "nepDate": "20.12.2070"
    },
    {
        "engDate": "2014-04-04",
        "nepDate": "21.12.2070"
    },
    {
        "engDate": "2014-04-05",
        "nepDate": "22.12.2070"
    },
    {
        "engDate": "2014-04-06",
        "nepDate": "23.12.2070"
    },
    {
        "engDate": "2014-04-07",
        "nepDate": "24.12.2070"
    },
    {
        "engDate": "2014-04-08",
        "nepDate": "25.12.2070"
    },
    {
        "engDate": "2014-04-09",
        "nepDate": "26.12.2070"
    },
    {
        "engDate": "2014-04-10",
        "nepDate": "27.12.2070"
    },
    {
        "engDate": "2014-04-11",
        "nepDate": "28.12.2070"
    },
    {
        "engDate": "2014-04-12",
        "nepDate": "29.12.2070"
    },
    {
        "engDate": "2014-04-13",
        "nepDate": "30.12.2070"
    },
    {
        "engDate": "2014-04-14",
        "nepDate": "01.01.2071"
    },
    {
        "engDate": "2014-04-15",
        "nepDate": "02.01.2071"
    },
    {
        "engDate": "2014-04-16",
        "nepDate": "03.01.2071"
    },
    {
        "engDate": "2014-04-17",
        "nepDate": "04.01.2071"
    },
    {
        "engDate": "2014-04-18",
        "nepDate": "05.01.2071"
    },
    {
        "engDate": "2014-04-19",
        "nepDate": "06.01.2071"
    },
    {
        "engDate": "2014-04-20",
        "nepDate": "07.01.2071"
    },
    {
        "engDate": "2014-04-21",
        "nepDate": "08.01.2071"
    },
    {
        "engDate": "2014-04-22",
        "nepDate": "09.01.2071"
    },
    {
        "engDate": "2014-04-23",
        "nepDate": "10.01.2071"
    },
    {
        "engDate": "2014-04-24",
        "nepDate": "11.01.2071"
    },
    {
        "engDate": "2014-04-25",
        "nepDate": "12.01.2071"
    },
    {
        "engDate": "2014-04-26",
        "nepDate": "13.01.2071"
    },
    {
        "engDate": "2014-04-27",
        "nepDate": "14.01.2071"
    },
    {
        "engDate": "2014-04-28",
        "nepDate": "15.01.2071"
    },
    {
        "engDate": "2014-04-29",
        "nepDate": "16.01.2071"
    },
    {
        "engDate": "2014-04-30",
        "nepDate": "17.01.2071"
    },
    {
        "engDate": "2014-05-01",
        "nepDate": "18.01.2071"
    },
    {
        "engDate": "2014-05-02",
        "nepDate": "19.01.2071"
    },
    {
        "engDate": "2014-05-03",
        "nepDate": "20.01.2071"
    },
    {
        "engDate": "2014-05-04",
        "nepDate": "21.01.2071"
    },
    {
        "engDate": "2014-05-05",
        "nepDate": "22.01.2071"
    },
    {
        "engDate": "2014-05-06",
        "nepDate": "23.01.2071"
    },
    {
        "engDate": "2014-05-07",
        "nepDate": "24.01.2071"
    },
    {
        "engDate": "2014-05-08",
        "nepDate": "25.01.2071"
    },
    {
        "engDate": "2014-05-09",
        "nepDate": "26.01.2071"
    },
    {
        "engDate": "2014-05-10",
        "nepDate": "27.01.2071"
    },
    {
        "engDate": "2014-05-11",
        "nepDate": "28.01.2071"
    },
    {
        "engDate": "2014-05-12",
        "nepDate": "29.01.2071"
    },
    {
        "engDate": "2014-05-13",
        "nepDate": "30.01.2071"
    },
    {
        "engDate": "2014-05-14",
        "nepDate": "31.01.2071"
    },
    {
        "engDate": "2014-05-15",
        "nepDate": "01.02.2071"
    },
    {
        "engDate": "2014-05-16",
        "nepDate": "02.02.2071"
    },
    {
        "engDate": "2014-05-17",
        "nepDate": "03.02.2071"
    },
    {
        "engDate": "2014-05-18",
        "nepDate": "04.02.2071"
    },
    {
        "engDate": "2014-05-19",
        "nepDate": "05.02.2071"
    },
    {
        "engDate": "2014-05-20",
        "nepDate": "06.02.2071"
    },
    {
        "engDate": "2014-05-21",
        "nepDate": "07.02.2071"
    },
    {
        "engDate": "2014-05-22",
        "nepDate": "08.02.2071"
    },
    {
        "engDate": "2014-05-23",
        "nepDate": "09.02.2071"
    },
    {
        "engDate": "2014-05-24",
        "nepDate": "10.02.2071"
    },
    {
        "engDate": "2014-05-25",
        "nepDate": "11.02.2071"
    },
    {
        "engDate": "2014-05-26",
        "nepDate": "12.02.2071"
    },
    {
        "engDate": "2014-05-27",
        "nepDate": "13.02.2071"
    },
    {
        "engDate": "2014-05-28",
        "nepDate": "14.02.2071"
    },
    {
        "engDate": "2014-05-29",
        "nepDate": "15.02.2071"
    },
    {
        "engDate": "2014-05-30",
        "nepDate": "16.02.2071"
    },
    {
        "engDate": "2014-05-31",
        "nepDate": "17.02.2071"
    },
    {
        "engDate": "2014-06-01",
        "nepDate": "18.02.2071"
    },
    {
        "engDate": "2014-06-02",
        "nepDate": "19.02.2071"
    },
    {
        "engDate": "2014-06-03",
        "nepDate": "20.02.2071"
    },
    {
        "engDate": "2014-06-04",
        "nepDate": "21.02.2071"
    },
    {
        "engDate": "2014-06-05",
        "nepDate": "22.02.2071"
    },
    {
        "engDate": "2014-06-06",
        "nepDate": "23.02.2071"
    },
    {
        "engDate": "2014-06-07",
        "nepDate": "24.02.2071"
    },
    {
        "engDate": "2014-06-08",
        "nepDate": "25.02.2071"
    },
    {
        "engDate": "2014-06-09",
        "nepDate": "26.02.2071"
    },
    {
        "engDate": "2014-06-10",
        "nepDate": "27.02.2071"
    },
    {
        "engDate": "2014-06-11",
        "nepDate": "28.02.2071"
    },
    {
        "engDate": "2014-06-12",
        "nepDate": "29.02.2071"
    },
    {
        "engDate": "2014-06-13",
        "nepDate": "30.02.2071"
    },
    {
        "engDate": "2014-06-14",
        "nepDate": "31.02.2071"
    },
    {
        "engDate": "2014-06-15",
        "nepDate": "01.03.2071"
    },
    {
        "engDate": "2014-06-16",
        "nepDate": "02.03.2071"
    },
    {
        "engDate": "2014-06-17",
        "nepDate": "03.03.2071"
    },
    {
        "engDate": "2014-06-18",
        "nepDate": "04.03.2071"
    },
    {
        "engDate": "2014-06-19",
        "nepDate": "05.03.2071"
    },
    {
        "engDate": "2014-06-20",
        "nepDate": "06.03.2071"
    },
    {
        "engDate": "2014-06-21",
        "nepDate": "07.03.2071"
    },
    {
        "engDate": "2014-06-22",
        "nepDate": "08.03.2071"
    },
    {
        "engDate": "2014-06-23",
        "nepDate": "09.03.2071"
    },
    {
        "engDate": "2014-06-24",
        "nepDate": "10.03.2071"
    },
    {
        "engDate": "2014-06-25",
        "nepDate": "11.03.2071"
    },
    {
        "engDate": "2014-06-26",
        "nepDate": "12.03.2071"
    },
    {
        "engDate": "2014-06-27",
        "nepDate": "13.03.2071"
    },
    {
        "engDate": "2014-06-28",
        "nepDate": "14.03.2071"
    },
    {
        "engDate": "2014-06-29",
        "nepDate": "15.03.2071"
    },
    {
        "engDate": "2014-06-30",
        "nepDate": "16.03.2071"
    },
    {
        "engDate": "2014-07-01",
        "nepDate": "17.03.2071"
    },
    {
        "engDate": "2014-07-02",
        "nepDate": "18.03.2071"
    },
    {
        "engDate": "2014-07-03",
        "nepDate": "19.03.2071"
    },
    {
        "engDate": "2014-07-04",
        "nepDate": "20.03.2071"
    },
    {
        "engDate": "2014-07-05",
        "nepDate": "21.03.2071"
    },
    {
        "engDate": "2014-07-06",
        "nepDate": "22.03.2071"
    },
    {
        "engDate": "2014-07-07",
        "nepDate": "23.03.2071"
    },
    {
        "engDate": "2014-07-08",
        "nepDate": "24.03.2071"
    },
    {
        "engDate": "2014-07-09",
        "nepDate": "25.03.2071"
    },
    {
        "engDate": "2014-07-10",
        "nepDate": "26.03.2071"
    },
    {
        "engDate": "2014-07-11",
        "nepDate": "27.03.2071"
    },
    {
        "engDate": "2014-07-12",
        "nepDate": "28.03.2071"
    },
    {
        "engDate": "2014-07-13",
        "nepDate": "29.03.2071"
    },
    {
        "engDate": "2014-07-14",
        "nepDate": "30.03.2071"
    },
    {
        "engDate": "2014-07-15",
        "nepDate": "31.03.2071"
    },
    {
        "engDate": "2014-07-16",
        "nepDate": "32.03.2071"
    },
    {
        "engDate": "2014-07-17",
        "nepDate": "01.04.2071"
    },
    {
        "engDate": "2014-07-18",
        "nepDate": "02.04.2071"
    },
    {
        "engDate": "2014-07-19",
        "nepDate": "03.04.2071"
    },
    {
        "engDate": "2014-07-20",
        "nepDate": "04.04.2071"
    },
    {
        "engDate": "2014-07-21",
        "nepDate": "05.04.2071"
    },
    {
        "engDate": "2014-07-22",
        "nepDate": "06.04.2071"
    },
    {
        "engDate": "2014-07-23",
        "nepDate": "07.04.2071"
    },
    {
        "engDate": "2014-07-24",
        "nepDate": "08.04.2071"
    },
    {
        "engDate": "2014-07-25",
        "nepDate": "09.04.2071"
    },
    {
        "engDate": "2014-07-26",
        "nepDate": "10.04.2071"
    },
    {
        "engDate": "2014-07-27",
        "nepDate": "11.04.2071"
    },
    {
        "engDate": "2014-07-28",
        "nepDate": "12.04.2071"
    },
    {
        "engDate": "2014-07-29",
        "nepDate": "13.04.2071"
    },
    {
        "engDate": "2014-07-30",
        "nepDate": "14.04.2071"
    },
    {
        "engDate": "2014-07-31",
        "nepDate": "15.04.2071"
    },
    {
        "engDate": "2014-08-01",
        "nepDate": "16.04.2071"
    },
    {
        "engDate": "2014-08-02",
        "nepDate": "17.04.2071"
    },
    {
        "engDate": "2014-08-03",
        "nepDate": "18.04.2071"
    },
    {
        "engDate": "2014-08-04",
        "nepDate": "19.04.2071"
    },
    {
        "engDate": "2014-08-05",
        "nepDate": "20.04.2071"
    },
    {
        "engDate": "2014-08-06",
        "nepDate": "21.04.2071"
    },
    {
        "engDate": "2014-08-07",
        "nepDate": "22.04.2071"
    },
    {
        "engDate": "2014-08-08",
        "nepDate": "23.04.2071"
    },
    {
        "engDate": "2014-08-09",
        "nepDate": "24.04.2071"
    },
    {
        "engDate": "2014-08-10",
        "nepDate": "25.04.2071"
    },
    {
        "engDate": "2014-08-11",
        "nepDate": "26.04.2071"
    },
    {
        "engDate": "2014-08-12",
        "nepDate": "27.04.2071"
    },
    {
        "engDate": "2014-08-13",
        "nepDate": "28.04.2071"
    },
    {
        "engDate": "2014-08-14",
        "nepDate": "29.04.2071"
    },
    {
        "engDate": "2014-08-15",
        "nepDate": "30.04.2071"
    },
    {
        "engDate": "2014-08-16",
        "nepDate": "31.04.2071"
    },
    {
        "engDate": "2014-08-17",
        "nepDate": "01.05.2071"
    },
    {
        "engDate": "2014-08-18",
        "nepDate": "02.05.2071"
    },
    {
        "engDate": "2014-08-19",
        "nepDate": "03.05.2071"
    },
    {
        "engDate": "2014-08-20",
        "nepDate": "04.05.2071"
    },
    {
        "engDate": "2014-08-21",
        "nepDate": "05.05.2071"
    },
    {
        "engDate": "2014-08-22",
        "nepDate": "06.05.2071"
    },
    {
        "engDate": "2014-08-23",
        "nepDate": "07.05.2071"
    },
    {
        "engDate": "2014-08-24",
        "nepDate": "08.05.2071"
    },
    {
        "engDate": "2014-08-25",
        "nepDate": "09.05.2071"
    },
    {
        "engDate": "2014-08-26",
        "nepDate": "10.05.2071"
    },
    {
        "engDate": "2014-08-27",
        "nepDate": "11.05.2071"
    },
    {
        "engDate": "2014-08-28",
        "nepDate": "12.05.2071"
    },
    {
        "engDate": "2014-08-29",
        "nepDate": "13.05.2071"
    },
    {
        "engDate": "2014-08-30",
        "nepDate": "14.05.2071"
    },
    {
        "engDate": "2014-08-31",
        "nepDate": "15.05.2071"
    },
    {
        "engDate": "2014-09-01",
        "nepDate": "16.05.2071"
    },
    {
        "engDate": "2014-09-02",
        "nepDate": "17.05.2071"
    },
    {
        "engDate": "2014-09-03",
        "nepDate": "18.05.2071"
    },
    {
        "engDate": "2014-09-04",
        "nepDate": "19.05.2071"
    },
    {
        "engDate": "2014-09-05",
        "nepDate": "20.05.2071"
    },
    {
        "engDate": "2014-09-06",
        "nepDate": "21.05.2071"
    },
    {
        "engDate": "2014-09-07",
        "nepDate": "22.05.2071"
    },
    {
        "engDate": "2014-09-08",
        "nepDate": "23.05.2071"
    },
    {
        "engDate": "2014-09-09",
        "nepDate": "24.05.2071"
    },
    {
        "engDate": "2014-09-10",
        "nepDate": "25.05.2071"
    },
    {
        "engDate": "2014-09-11",
        "nepDate": "26.05.2071"
    },
    {
        "engDate": "2014-09-12",
        "nepDate": "27.05.2071"
    },
    {
        "engDate": "2014-09-13",
        "nepDate": "28.05.2071"
    },
    {
        "engDate": "2014-09-14",
        "nepDate": "29.05.2071"
    },
    {
        "engDate": "2014-09-15",
        "nepDate": "30.05.2071"
    },
    {
        "engDate": "2014-09-16",
        "nepDate": "31.05.2071"
    },
    {
        "engDate": "2014-09-17",
        "nepDate": "01.06.2071"
    },
    {
        "engDate": "2014-09-18",
        "nepDate": "02.06.2071"
    },
    {
        "engDate": "2014-09-19",
        "nepDate": "03.06.2071"
    },
    {
        "engDate": "2014-09-20",
        "nepDate": "04.06.2071"
    },
    {
        "engDate": "2014-09-21",
        "nepDate": "05.06.2071"
    },
    {
        "engDate": "2014-09-22",
        "nepDate": "06.06.2071"
    },
    {
        "engDate": "2014-09-23",
        "nepDate": "07.06.2071"
    },
    {
        "engDate": "2014-09-24",
        "nepDate": "08.06.2071"
    },
    {
        "engDate": "2014-09-25",
        "nepDate": "09.06.2071"
    },
    {
        "engDate": "2014-09-26",
        "nepDate": "10.06.2071"
    },
    {
        "engDate": "2014-09-27",
        "nepDate": "11.06.2071"
    },
    {
        "engDate": "2014-09-28",
        "nepDate": "12.06.2071"
    },
    {
        "engDate": "2014-09-29",
        "nepDate": "13.06.2071"
    },
    {
        "engDate": "2014-09-30",
        "nepDate": "14.06.2071"
    },
    {
        "engDate": "2014-10-01",
        "nepDate": "15.06.2071"
    },
    {
        "engDate": "2014-10-02",
        "nepDate": "16.06.2071"
    },
    {
        "engDate": "2014-10-03",
        "nepDate": "17.06.2071"
    },
    {
        "engDate": "2014-10-04",
        "nepDate": "18.06.2071"
    },
    {
        "engDate": "2014-10-05",
        "nepDate": "19.06.2071"
    },
    {
        "engDate": "2014-10-06",
        "nepDate": "20.06.2071"
    },
    {
        "engDate": "2014-10-07",
        "nepDate": "21.06.2071"
    },
    {
        "engDate": "2014-10-08",
        "nepDate": "22.06.2071"
    },
    {
        "engDate": "2014-10-09",
        "nepDate": "23.06.2071"
    },
    {
        "engDate": "2014-10-10",
        "nepDate": "24.06.2071"
    },
    {
        "engDate": "2014-10-11",
        "nepDate": "25.06.2071"
    },
    {
        "engDate": "2014-10-12",
        "nepDate": "26.06.2071"
    },
    {
        "engDate": "2014-10-13",
        "nepDate": "27.06.2071"
    },
    {
        "engDate": "2014-10-14",
        "nepDate": "28.06.2071"
    },
    {
        "engDate": "2014-10-15",
        "nepDate": "29.06.2071"
    },
    {
        "engDate": "2014-10-16",
        "nepDate": "30.06.2071"
    },
    {
        "engDate": "2014-10-17",
        "nepDate": "31.06.2071"
    },
    {
        "engDate": "2014-10-18",
        "nepDate": "01.07.2071"
    },
    {
        "engDate": "2014-10-19",
        "nepDate": "02.07.2071"
    },
    {
        "engDate": "2014-10-20",
        "nepDate": "03.07.2071"
    },
    {
        "engDate": "2014-10-21",
        "nepDate": "04.07.2071"
    },
    {
        "engDate": "2014-10-22",
        "nepDate": "05.07.2071"
    },
    {
        "engDate": "2014-10-23",
        "nepDate": "06.07.2071"
    },
    {
        "engDate": "2014-10-24",
        "nepDate": "07.07.2071"
    },
    {
        "engDate": "2014-10-25",
        "nepDate": "08.07.2071"
    },
    {
        "engDate": "2014-10-26",
        "nepDate": "09.07.2071"
    },
    {
        "engDate": "2014-10-27",
        "nepDate": "10.07.2071"
    },
    {
        "engDate": "2014-10-28",
        "nepDate": "11.07.2071"
    },
    {
        "engDate": "2014-10-29",
        "nepDate": "12.07.2071"
    },
    {
        "engDate": "2014-10-30",
        "nepDate": "13.07.2071"
    },
    {
        "engDate": "2014-10-31",
        "nepDate": "14.07.2071"
    },
    {
        "engDate": "2014-11-01",
        "nepDate": "15.07.2071"
    },
    {
        "engDate": "2014-11-02",
        "nepDate": "16.07.2071"
    },
    {
        "engDate": "2014-11-03",
        "nepDate": "17.07.2071"
    },
    {
        "engDate": "2014-11-04",
        "nepDate": "18.07.2071"
    },
    {
        "engDate": "2014-11-05",
        "nepDate": "19.07.2071"
    },
    {
        "engDate": "2014-11-06",
        "nepDate": "20.07.2071"
    },
    {
        "engDate": "2014-11-07",
        "nepDate": "21.07.2071"
    },
    {
        "engDate": "2014-11-08",
        "nepDate": "22.07.2071"
    },
    {
        "engDate": "2014-11-09",
        "nepDate": "23.07.2071"
    },
    {
        "engDate": "2014-11-10",
        "nepDate": "24.07.2071"
    },
    {
        "engDate": "2014-11-11",
        "nepDate": "25.07.2071"
    },
    {
        "engDate": "2014-11-12",
        "nepDate": "26.07.2071"
    },
    {
        "engDate": "2014-11-13",
        "nepDate": "27.07.2071"
    },
    {
        "engDate": "2014-11-14",
        "nepDate": "28.07.2071"
    },
    {
        "engDate": "2014-11-15",
        "nepDate": "29.07.2071"
    },
    {
        "engDate": "2014-11-16",
        "nepDate": "30.07.2071"
    },
    {
        "engDate": "2014-11-17",
        "nepDate": "01.08.2071"
    },
    {
        "engDate": "2014-11-18",
        "nepDate": "02.08.2071"
    },
    {
        "engDate": "2014-11-19",
        "nepDate": "03.08.2071"
    },
    {
        "engDate": "2014-11-20",
        "nepDate": "04.08.2071"
    },
    {
        "engDate": "2014-11-21",
        "nepDate": "05.08.2071"
    },
    {
        "engDate": "2014-11-22",
        "nepDate": "06.08.2071"
    },
    {
        "engDate": "2014-11-23",
        "nepDate": "07.08.2071"
    },
    {
        "engDate": "2014-11-24",
        "nepDate": "08.08.2071"
    },
    {
        "engDate": "2014-11-25",
        "nepDate": "09.08.2071"
    },
    {
        "engDate": "2014-11-26",
        "nepDate": "10.08.2071"
    },
    {
        "engDate": "2014-11-27",
        "nepDate": "11.08.2071"
    },
    {
        "engDate": "2014-11-28",
        "nepDate": "12.08.2071"
    },
    {
        "engDate": "2014-11-29",
        "nepDate": "13.08.2071"
    },
    {
        "engDate": "2014-11-30",
        "nepDate": "14.08.2071"
    },
    {
        "engDate": "2014-12-01",
        "nepDate": "15.08.2071"
    },
    {
        "engDate": "2014-12-02",
        "nepDate": "16.08.2071"
    },
    {
        "engDate": "2014-12-03",
        "nepDate": "17.08.2071"
    },
    {
        "engDate": "2014-12-04",
        "nepDate": "18.08.2071"
    },
    {
        "engDate": "2014-12-05",
        "nepDate": "19.08.2071"
    },
    {
        "engDate": "2014-12-06",
        "nepDate": "20.08.2071"
    },
    {
        "engDate": "2014-12-07",
        "nepDate": "21.08.2071"
    },
    {
        "engDate": "2014-12-08",
        "nepDate": "22.08.2071"
    },
    {
        "engDate": "2014-12-09",
        "nepDate": "23.08.2071"
    },
    {
        "engDate": "2014-12-10",
        "nepDate": "24.08.2071"
    },
    {
        "engDate": "2014-12-11",
        "nepDate": "25.08.2071"
    },
    {
        "engDate": "2014-12-12",
        "nepDate": "26.08.2071"
    },
    {
        "engDate": "2014-12-13",
        "nepDate": "27.08.2071"
    },
    {
        "engDate": "2014-12-14",
        "nepDate": "28.08.2071"
    },
    {
        "engDate": "2014-12-15",
        "nepDate": "29.08.2071"
    },
    {
        "engDate": "2014-12-16",
        "nepDate": "01.09.2071"
    },
    {
        "engDate": "2014-12-17",
        "nepDate": "02.09.2071"
    },
    {
        "engDate": "2014-12-18",
        "nepDate": "03.09.2071"
    },
    {
        "engDate": "2014-12-19",
        "nepDate": "04.09.2071"
    },
    {
        "engDate": "2014-12-20",
        "nepDate": "05.09.2071"
    },
    {
        "engDate": "2014-12-21",
        "nepDate": "06.09.2071"
    },
    {
        "engDate": "2014-12-22",
        "nepDate": "07.09.2071"
    },
    {
        "engDate": "2014-12-23",
        "nepDate": "08.09.2071"
    },
    {
        "engDate": "2014-12-24",
        "nepDate": "09.09.2071"
    },
    {
        "engDate": "2014-12-25",
        "nepDate": "10.09.2071"
    },
    {
        "engDate": "2014-12-26",
        "nepDate": "11.09.2071"
    },
    {
        "engDate": "2014-12-27",
        "nepDate": "12.09.2071"
    },
    {
        "engDate": "2014-12-28",
        "nepDate": "13.09.2071"
    },
    {
        "engDate": "2014-12-29",
        "nepDate": "14.09.2071"
    },
    {
        "engDate": "2014-12-30",
        "nepDate": "15.09.2071"
    },
    {
        "engDate": "2014-12-31",
        "nepDate": "16.09.2071"
    },
    {
        "engDate": "2015-01-01",
        "nepDate": "17.09.2071"
    },
    {
        "engDate": "2015-01-02",
        "nepDate": "18.09.2071"
    },
    {
        "engDate": "2015-01-03",
        "nepDate": "19.09.2071"
    },
    {
        "engDate": "2015-01-04",
        "nepDate": "20.09.2071"
    },
    {
        "engDate": "2015-01-05",
        "nepDate": "21.09.2071"
    },
    {
        "engDate": "2015-01-06",
        "nepDate": "22.09.2071"
    },
    {
        "engDate": "2015-01-07",
        "nepDate": "23.09.2071"
    },
    {
        "engDate": "2015-01-08",
        "nepDate": "24.09.2071"
    },
    {
        "engDate": "2015-01-09",
        "nepDate": "25.09.2071"
    },
    {
        "engDate": "2015-01-10",
        "nepDate": "26.09.2071"
    },
    {
        "engDate": "2015-01-11",
        "nepDate": "27.09.2071"
    },
    {
        "engDate": "2015-01-12",
        "nepDate": "28.09.2071"
    },
    {
        "engDate": "2015-01-13",
        "nepDate": "29.09.2071"
    },
    {
        "engDate": "2015-01-14",
        "nepDate": "30.09.2071"
    },
    {
        "engDate": "2015-01-15",
        "nepDate": "01.10.2071"
    },
    {
        "engDate": "2015-01-16",
        "nepDate": "02.10.2071"
    },
    {
        "engDate": "2015-01-17",
        "nepDate": "03.10.2071"
    },
    {
        "engDate": "2015-01-18",
        "nepDate": "04.10.2071"
    },
    {
        "engDate": "2015-01-19",
        "nepDate": "05.10.2071"
    },
    {
        "engDate": "2015-01-20",
        "nepDate": "06.10.2071"
    },
    {
        "engDate": "2015-01-21",
        "nepDate": "07.10.2071"
    },
    {
        "engDate": "2015-01-22",
        "nepDate": "08.10.2071"
    },
    {
        "engDate": "2015-01-23",
        "nepDate": "09.10.2071"
    },
    {
        "engDate": "2015-01-24",
        "nepDate": "10.10.2071"
    },
    {
        "engDate": "2015-01-25",
        "nepDate": "11.10.2071"
    },
    {
        "engDate": "2015-01-26",
        "nepDate": "12.10.2071"
    },
    {
        "engDate": "2015-01-27",
        "nepDate": "13.10.2071"
    },
    {
        "engDate": "2015-01-28",
        "nepDate": "14.10.2071"
    },
    {
        "engDate": "2015-01-29",
        "nepDate": "15.10.2071"
    },
    {
        "engDate": "2015-01-30",
        "nepDate": "16.10.2071"
    },
    {
        "engDate": "2015-01-31",
        "nepDate": "17.10.2071"
    },
    {
        "engDate": "2015-02-01",
        "nepDate": "18.10.2071"
    },
    {
        "engDate": "2015-02-02",
        "nepDate": "19.10.2071"
    },
    {
        "engDate": "2015-02-03",
        "nepDate": "20.10.2071"
    },
    {
        "engDate": "2015-02-04",
        "nepDate": "21.10.2071"
    },
    {
        "engDate": "2015-02-05",
        "nepDate": "22.10.2071"
    },
    {
        "engDate": "2015-02-06",
        "nepDate": "23.10.2071"
    },
    {
        "engDate": "2015-02-07",
        "nepDate": "24.10.2071"
    },
    {
        "engDate": "2015-02-08",
        "nepDate": "25.10.2071"
    },
    {
        "engDate": "2015-02-09",
        "nepDate": "26.10.2071"
    },
    {
        "engDate": "2015-02-10",
        "nepDate": "27.10.2071"
    },
    {
        "engDate": "2015-02-11",
        "nepDate": "28.10.2071"
    },
    {
        "engDate": "2015-02-12",
        "nepDate": "29.10.2071"
    },
    {
        "engDate": "2015-02-13",
        "nepDate": "01.11.2071"
    },
    {
        "engDate": "2015-02-14",
        "nepDate": "02.11.2071"
    },
    {
        "engDate": "2015-02-15",
        "nepDate": "03.11.2071"
    },
    {
        "engDate": "2015-02-16",
        "nepDate": "04.11.2071"
    },
    {
        "engDate": "2015-02-17",
        "nepDate": "05.11.2071"
    },
    {
        "engDate": "2015-02-18",
        "nepDate": "06.11.2071"
    },
    {
        "engDate": "2015-02-19",
        "nepDate": "07.11.2071"
    },
    {
        "engDate": "2015-02-20",
        "nepDate": "08.11.2071"
    },
    {
        "engDate": "2015-02-21",
        "nepDate": "09.11.2071"
    },
    {
        "engDate": "2015-02-22",
        "nepDate": "10.11.2071"
    },
    {
        "engDate": "2015-02-23",
        "nepDate": "11.11.2071"
    },
    {
        "engDate": "2015-02-24",
        "nepDate": "12.11.2071"
    },
    {
        "engDate": "2015-02-25",
        "nepDate": "13.11.2071"
    },
    {
        "engDate": "2015-02-26",
        "nepDate": "14.11.2071"
    },
    {
        "engDate": "2015-02-27",
        "nepDate": "15.11.2071"
    },
    {
        "engDate": "2015-02-28",
        "nepDate": "16.11.2071"
    },
    {
        "engDate": "2015-03-01",
        "nepDate": "17.11.2071"
    },
    {
        "engDate": "2015-03-02",
        "nepDate": "18.11.2071"
    },
    {
        "engDate": "2015-03-03",
        "nepDate": "19.11.2071"
    },
    {
        "engDate": "2015-03-04",
        "nepDate": "20.11.2071"
    },
    {
        "engDate": "2015-03-05",
        "nepDate": "21.11.2071"
    },
    {
        "engDate": "2015-03-06",
        "nepDate": "22.11.2071"
    },
    {
        "engDate": "2015-03-07",
        "nepDate": "23.11.2071"
    },
    {
        "engDate": "2015-03-08",
        "nepDate": "24.11.2071"
    },
    {
        "engDate": "2015-03-09",
        "nepDate": "25.11.2071"
    },
    {
        "engDate": "2015-03-10",
        "nepDate": "26.11.2071"
    },
    {
        "engDate": "2015-03-11",
        "nepDate": "27.11.2071"
    },
    {
        "engDate": "2015-03-12",
        "nepDate": "28.11.2071"
    },
    {
        "engDate": "2015-03-13",
        "nepDate": "29.11.2071"
    },
    {
        "engDate": "2015-03-14",
        "nepDate": "30.11.2071"
    },
    {
        "engDate": "2015-03-15",
        "nepDate": "01.12.2071"
    },
    {
        "engDate": "2015-03-16",
        "nepDate": "02.12.2071"
    },
    {
        "engDate": "2015-03-17",
        "nepDate": "03.12.2071"
    },
    {
        "engDate": "2015-03-18",
        "nepDate": "04.12.2071"
    },
    {
        "engDate": "2015-03-19",
        "nepDate": "05.12.2071"
    },
    {
        "engDate": "2015-03-20",
        "nepDate": "06.12.2071"
    },
    {
        "engDate": "2015-03-21",
        "nepDate": "07.12.2071"
    },
    {
        "engDate": "2015-03-22",
        "nepDate": "08.12.2071"
    },
    {
        "engDate": "2015-03-23",
        "nepDate": "09.12.2071"
    },
    {
        "engDate": "2015-03-24",
        "nepDate": "10.12.2071"
    },
    {
        "engDate": "2015-03-25",
        "nepDate": "11.12.2071"
    },
    {
        "engDate": "2015-03-26",
        "nepDate": "12.12.2071"
    },
    {
        "engDate": "2015-03-27",
        "nepDate": "13.12.2071"
    },
    {
        "engDate": "2015-03-28",
        "nepDate": "14.12.2071"
    },
    {
        "engDate": "2015-03-29",
        "nepDate": "15.12.2071"
    },
    {
        "engDate": "2015-03-30",
        "nepDate": "16.12.2071"
    },
    {
        "engDate": "2015-03-31",
        "nepDate": "17.12.2071"
    },
    {
        "engDate": "2015-04-01",
        "nepDate": "18.12.2071"
    },
    {
        "engDate": "2015-04-02",
        "nepDate": "19.12.2071"
    },
    {
        "engDate": "2015-04-03",
        "nepDate": "20.12.2071"
    },
    {
        "engDate": "2015-04-04",
        "nepDate": "21.12.2071"
    },
    {
        "engDate": "2015-04-05",
        "nepDate": "22.12.2071"
    },
    {
        "engDate": "2015-04-06",
        "nepDate": "23.12.2071"
    },
    {
        "engDate": "2015-04-07",
        "nepDate": "24.12.2071"
    },
    {
        "engDate": "2015-04-08",
        "nepDate": "25.12.2071"
    },
    {
        "engDate": "2015-04-09",
        "nepDate": "26.12.2071"
    },
    {
        "engDate": "2015-04-10",
        "nepDate": "27.12.2071"
    },
    {
        "engDate": "2015-04-11",
        "nepDate": "28.12.2071"
    },
    {
        "engDate": "2015-04-12",
        "nepDate": "29.12.2071"
    },
    {
        "engDate": "2015-04-13",
        "nepDate": "30.12.2071"
    },
    {
        "engDate": "2015-04-14",
        "nepDate": "01.01.2072"
    },
    {
        "engDate": "2015-04-15",
        "nepDate": "02.01.2072"
    },
    {
        "engDate": "2015-04-16",
        "nepDate": "03.01.2072"
    },
    {
        "engDate": "2015-04-17",
        "nepDate": "04.01.2072"
    },
    {
        "engDate": "2015-04-18",
        "nepDate": "05.01.2072"
    },
    {
        "engDate": "2015-04-19",
        "nepDate": "06.01.2072"
    },
    {
        "engDate": "2015-04-20",
        "nepDate": "07.01.2072"
    },
    {
        "engDate": "2015-04-21",
        "nepDate": "08.01.2072"
    },
    {
        "engDate": "2015-04-22",
        "nepDate": "09.01.2072"
    },
    {
        "engDate": "2015-04-23",
        "nepDate": "10.01.2072"
    },
    {
        "engDate": "2015-04-24",
        "nepDate": "11.01.2072"
    },
    {
        "engDate": "2015-04-25",
        "nepDate": "12.01.2072"
    },
    {
        "engDate": "2015-04-26",
        "nepDate": "13.01.2072"
    },
    {
        "engDate": "2015-04-27",
        "nepDate": "14.01.2072"
    },
    {
        "engDate": "2015-04-28",
        "nepDate": "15.01.2072"
    },
    {
        "engDate": "2015-04-29",
        "nepDate": "16.01.2072"
    },
    {
        "engDate": "2015-04-30",
        "nepDate": "17.01.2072"
    },
    {
        "engDate": "2015-05-01",
        "nepDate": "18.01.2072"
    },
    {
        "engDate": "2015-05-02",
        "nepDate": "19.01.2072"
    },
    {
        "engDate": "2015-05-03",
        "nepDate": "20.01.2072"
    },
    {
        "engDate": "2015-05-04",
        "nepDate": "21.01.2072"
    },
    {
        "engDate": "2015-05-05",
        "nepDate": "22.01.2072"
    },
    {
        "engDate": "2015-05-06",
        "nepDate": "23.01.2072"
    },
    {
        "engDate": "2015-05-07",
        "nepDate": "24.01.2072"
    },
    {
        "engDate": "2015-05-08",
        "nepDate": "25.01.2072"
    },
    {
        "engDate": "2015-05-09",
        "nepDate": "26.01.2072"
    },
    {
        "engDate": "2015-05-10",
        "nepDate": "27.01.2072"
    },
    {
        "engDate": "2015-05-11",
        "nepDate": "28.01.2072"
    },
    {
        "engDate": "2015-05-12",
        "nepDate": "29.01.2072"
    },
    {
        "engDate": "2015-05-13",
        "nepDate": "30.01.2072"
    },
    {
        "engDate": "2015-05-14",
        "nepDate": "31.01.2072"
    },
    {
        "engDate": "2015-05-15",
        "nepDate": "01.02.2072"
    },
    {
        "engDate": "2015-05-16",
        "nepDate": "02.02.2072"
    },
    {
        "engDate": "2015-05-17",
        "nepDate": "03.02.2072"
    },
    {
        "engDate": "2015-05-18",
        "nepDate": "04.02.2072"
    },
    {
        "engDate": "2015-05-19",
        "nepDate": "05.02.2072"
    },
    {
        "engDate": "2015-05-20",
        "nepDate": "06.02.2072"
    },
    {
        "engDate": "2015-05-21",
        "nepDate": "07.02.2072"
    },
    {
        "engDate": "2015-05-22",
        "nepDate": "08.02.2072"
    },
    {
        "engDate": "2015-05-23",
        "nepDate": "09.02.2072"
    },
    {
        "engDate": "2015-05-24",
        "nepDate": "10.02.2072"
    },
    {
        "engDate": "2015-05-25",
        "nepDate": "11.02.2072"
    },
    {
        "engDate": "2015-05-26",
        "nepDate": "12.02.2072"
    },
    {
        "engDate": "2015-05-27",
        "nepDate": "13.02.2072"
    },
    {
        "engDate": "2015-05-28",
        "nepDate": "14.02.2072"
    },
    {
        "engDate": "2015-05-29",
        "nepDate": "15.02.2072"
    },
    {
        "engDate": "2015-05-30",
        "nepDate": "16.02.2072"
    },
    {
        "engDate": "2015-05-31",
        "nepDate": "17.02.2072"
    },
    {
        "engDate": "2015-06-01",
        "nepDate": "18.02.2072"
    },
    {
        "engDate": "2015-06-02",
        "nepDate": "19.02.2072"
    },
    {
        "engDate": "2015-06-03",
        "nepDate": "20.02.2072"
    },
    {
        "engDate": "2015-06-04",
        "nepDate": "21.02.2072"
    },
    {
        "engDate": "2015-06-05",
        "nepDate": "22.02.2072"
    },
    {
        "engDate": "2015-06-06",
        "nepDate": "23.02.2072"
    },
    {
        "engDate": "2015-06-07",
        "nepDate": "24.02.2072"
    },
    {
        "engDate": "2015-06-08",
        "nepDate": "25.02.2072"
    },
    {
        "engDate": "2015-06-09",
        "nepDate": "26.02.2072"
    },
    {
        "engDate": "2015-06-10",
        "nepDate": "27.02.2072"
    },
    {
        "engDate": "2015-06-11",
        "nepDate": "28.02.2072"
    },
    {
        "engDate": "2015-06-12",
        "nepDate": "29.02.2072"
    },
    {
        "engDate": "2015-06-13",
        "nepDate": "30.02.2072"
    },
    {
        "engDate": "2015-06-14",
        "nepDate": "31.02.2072"
    },
    {
        "engDate": "2015-06-15",
        "nepDate": "32.02.2072"
    },
    {
        "engDate": "2015-06-16",
        "nepDate": "01.03.2072"
    },
    {
        "engDate": "2015-06-17",
        "nepDate": "02.03.2072"
    },
    {
        "engDate": "2015-06-18",
        "nepDate": "03.03.2072"
    },
    {
        "engDate": "2015-06-19",
        "nepDate": "04.03.2072"
    },
    {
        "engDate": "2015-06-20",
        "nepDate": "05.03.2072"
    },
    {
        "engDate": "2015-06-21",
        "nepDate": "06.03.2072"
    },
    {
        "engDate": "2015-06-22",
        "nepDate": "07.03.2072"
    },
    {
        "engDate": "2015-06-23",
        "nepDate": "08.03.2072"
    },
    {
        "engDate": "2015-06-24",
        "nepDate": "09.03.2072"
    },
    {
        "engDate": "2015-06-25",
        "nepDate": "10.03.2072"
    },
    {
        "engDate": "2015-06-26",
        "nepDate": "11.03.2072"
    },
    {
        "engDate": "2015-06-27",
        "nepDate": "12.03.2072"
    },
    {
        "engDate": "2015-06-28",
        "nepDate": "13.03.2072"
    },
    {
        "engDate": "2015-06-29",
        "nepDate": "14.03.2072"
    },
    {
        "engDate": "2015-06-30",
        "nepDate": "15.03.2072"
    },
    {
        "engDate": "2015-07-01",
        "nepDate": "16.03.2072"
    },
    {
        "engDate": "2015-07-02",
        "nepDate": "17.03.2072"
    },
    {
        "engDate": "2015-07-03",
        "nepDate": "18.03.2072"
    },
    {
        "engDate": "2015-07-04",
        "nepDate": "19.03.2072"
    },
    {
        "engDate": "2015-07-05",
        "nepDate": "20.03.2072"
    },
    {
        "engDate": "2015-07-06",
        "nepDate": "21.03.2072"
    },
    {
        "engDate": "2015-07-07",
        "nepDate": "22.03.2072"
    },
    {
        "engDate": "2015-07-08",
        "nepDate": "23.03.2072"
    },
    {
        "engDate": "2015-07-09",
        "nepDate": "24.03.2072"
    },
    {
        "engDate": "2015-07-10",
        "nepDate": "25.03.2072"
    },
    {
        "engDate": "2015-07-11",
        "nepDate": "26.03.2072"
    },
    {
        "engDate": "2015-07-12",
        "nepDate": "27.03.2072"
    },
    {
        "engDate": "2015-07-13",
        "nepDate": "28.03.2072"
    },
    {
        "engDate": "2015-07-14",
        "nepDate": "29.03.2072"
    },
    {
        "engDate": "2015-07-15",
        "nepDate": "30.03.2072"
    },
    {
        "engDate": "2015-07-16",
        "nepDate": "31.03.2072"
    },
    {
        "engDate": "2015-07-17",
        "nepDate": "01.04.2072"
    },
    {
        "engDate": "2015-07-18",
        "nepDate": "02.04.2072"
    },
    {
        "engDate": "2015-07-19",
        "nepDate": "03.04.2072"
    },
    {
        "engDate": "2015-07-20",
        "nepDate": "04.04.2072"
    },
    {
        "engDate": "2015-07-21",
        "nepDate": "05.04.2072"
    },
    {
        "engDate": "2015-07-22",
        "nepDate": "06.04.2072"
    },
    {
        "engDate": "2015-07-23",
        "nepDate": "07.04.2072"
    },
    {
        "engDate": "2015-07-24",
        "nepDate": "08.04.2072"
    },
    {
        "engDate": "2015-07-25",
        "nepDate": "09.04.2072"
    },
    {
        "engDate": "2015-07-26",
        "nepDate": "10.04.2072"
    },
    {
        "engDate": "2015-07-27",
        "nepDate": "11.04.2072"
    },
    {
        "engDate": "2015-07-28",
        "nepDate": "12.04.2072"
    },
    {
        "engDate": "2015-07-29",
        "nepDate": "13.04.2072"
    },
    {
        "engDate": "2015-07-30",
        "nepDate": "14.04.2072"
    },
    {
        "engDate": "2015-07-31",
        "nepDate": "15.04.2072"
    },
    {
        "engDate": "2015-08-01",
        "nepDate": "16.04.2072"
    },
    {
        "engDate": "2015-08-02",
        "nepDate": "17.04.2072"
    },
    {
        "engDate": "2015-08-03",
        "nepDate": "18.04.2072"
    },
    {
        "engDate": "2015-08-04",
        "nepDate": "19.04.2072"
    },
    {
        "engDate": "2015-08-05",
        "nepDate": "20.04.2072"
    },
    {
        "engDate": "2015-08-06",
        "nepDate": "21.04.2072"
    },
    {
        "engDate": "2015-08-07",
        "nepDate": "22.04.2072"
    },
    {
        "engDate": "2015-08-08",
        "nepDate": "23.04.2072"
    },
    {
        "engDate": "2015-08-09",
        "nepDate": "24.04.2072"
    },
    {
        "engDate": "2015-08-10",
        "nepDate": "25.04.2072"
    },
    {
        "engDate": "2015-08-11",
        "nepDate": "26.04.2072"
    },
    {
        "engDate": "2015-08-12",
        "nepDate": "27.04.2072"
    },
    {
        "engDate": "2015-08-13",
        "nepDate": "28.04.2072"
    },
    {
        "engDate": "2015-08-14",
        "nepDate": "29.04.2072"
    },
    {
        "engDate": "2015-08-15",
        "nepDate": "30.04.2072"
    },
    {
        "engDate": "2015-08-16",
        "nepDate": "31.04.2072"
    },
    {
        "engDate": "2015-08-17",
        "nepDate": "32.04.2072"
    },
    {
        "engDate": "2015-08-18",
        "nepDate": "01.05.2072"
    },
    {
        "engDate": "2015-08-19",
        "nepDate": "02.05.2072"
    },
    {
        "engDate": "2015-08-20",
        "nepDate": "03.05.2072"
    },
    {
        "engDate": "2015-08-21",
        "nepDate": "04.05.2072"
    },
    {
        "engDate": "2015-08-22",
        "nepDate": "05.05.2072"
    },
    {
        "engDate": "2015-08-23",
        "nepDate": "06.05.2072"
    },
    {
        "engDate": "2015-08-24",
        "nepDate": "07.05.2072"
    },
    {
        "engDate": "2015-08-25",
        "nepDate": "08.05.2072"
    },
    {
        "engDate": "2015-08-26",
        "nepDate": "09.05.2072"
    },
    {
        "engDate": "2015-08-27",
        "nepDate": "10.05.2072"
    },
    {
        "engDate": "2015-08-28",
        "nepDate": "11.05.2072"
    },
    {
        "engDate": "2015-08-29",
        "nepDate": "12.05.2072"
    },
    {
        "engDate": "2015-08-30",
        "nepDate": "13.05.2072"
    },
    {
        "engDate": "2015-08-31",
        "nepDate": "14.05.2072"
    },
    {
        "engDate": "2015-09-01",
        "nepDate": "15.05.2072"
    },
    {
        "engDate": "2015-09-02",
        "nepDate": "16.05.2072"
    },
    {
        "engDate": "2015-09-03",
        "nepDate": "17.05.2072"
    },
    {
        "engDate": "2015-09-04",
        "nepDate": "18.05.2072"
    },
    {
        "engDate": "2015-09-05",
        "nepDate": "19.05.2072"
    },
    {
        "engDate": "2015-09-06",
        "nepDate": "20.05.2072"
    },
    {
        "engDate": "2015-09-07",
        "nepDate": "21.05.2072"
    },
    {
        "engDate": "2015-09-08",
        "nepDate": "22.05.2072"
    },
    {
        "engDate": "2015-09-09",
        "nepDate": "23.05.2072"
    },
    {
        "engDate": "2015-09-10",
        "nepDate": "24.05.2072"
    },
    {
        "engDate": "2015-09-11",
        "nepDate": "25.05.2072"
    },
    {
        "engDate": "2015-09-12",
        "nepDate": "26.05.2072"
    },
    {
        "engDate": "2015-09-13",
        "nepDate": "27.05.2072"
    },
    {
        "engDate": "2015-09-14",
        "nepDate": "28.05.2072"
    },
    {
        "engDate": "2015-09-15",
        "nepDate": "29.05.2072"
    },
    {
        "engDate": "2015-09-16",
        "nepDate": "30.05.2072"
    },
    {
        "engDate": "2015-09-17",
        "nepDate": "31.05.2072"
    },
    {
        "engDate": "2015-09-18",
        "nepDate": "01.06.2072"
    },
    {
        "engDate": "2015-09-19",
        "nepDate": "02.06.2072"
    },
    {
        "engDate": "2015-09-20",
        "nepDate": "03.06.2072"
    },
    {
        "engDate": "2015-09-21",
        "nepDate": "04.06.2072"
    },
    {
        "engDate": "2015-09-22",
        "nepDate": "05.06.2072"
    },
    {
        "engDate": "2015-09-23",
        "nepDate": "06.06.2072"
    },
    {
        "engDate": "2015-09-24",
        "nepDate": "07.06.2072"
    },
    {
        "engDate": "2015-09-25",
        "nepDate": "08.06.2072"
    },
    {
        "engDate": "2015-09-26",
        "nepDate": "09.06.2072"
    },
    {
        "engDate": "2015-09-27",
        "nepDate": "10.06.2072"
    },
    {
        "engDate": "2015-09-28",
        "nepDate": "11.06.2072"
    },
    {
        "engDate": "2015-09-29",
        "nepDate": "12.06.2072"
    },
    {
        "engDate": "2015-09-30",
        "nepDate": "13.06.2072"
    },
    {
        "engDate": "2015-10-01",
        "nepDate": "14.06.2072"
    },
    {
        "engDate": "2015-10-02",
        "nepDate": "15.06.2072"
    },
    {
        "engDate": "2015-10-03",
        "nepDate": "16.06.2072"
    },
    {
        "engDate": "2015-10-04",
        "nepDate": "17.06.2072"
    },
    {
        "engDate": "2015-10-05",
        "nepDate": "18.06.2072"
    },
    {
        "engDate": "2015-10-06",
        "nepDate": "19.06.2072"
    },
    {
        "engDate": "2015-10-07",
        "nepDate": "20.06.2072"
    },
    {
        "engDate": "2015-10-08",
        "nepDate": "21.06.2072"
    },
    {
        "engDate": "2015-10-09",
        "nepDate": "22.06.2072"
    },
    {
        "engDate": "2015-10-10",
        "nepDate": "23.06.2072"
    },
    {
        "engDate": "2015-10-11",
        "nepDate": "24.06.2072"
    },
    {
        "engDate": "2015-10-12",
        "nepDate": "25.06.2072"
    },
    {
        "engDate": "2015-10-13",
        "nepDate": "26.06.2072"
    },
    {
        "engDate": "2015-10-14",
        "nepDate": "27.06.2072"
    },
    {
        "engDate": "2015-10-15",
        "nepDate": "28.06.2072"
    },
    {
        "engDate": "2015-10-16",
        "nepDate": "29.06.2072"
    },
    {
        "engDate": "2015-10-17",
        "nepDate": "30.06.2072"
    },
    {
        "engDate": "2015-10-18",
        "nepDate": "01.07.2072"
    },
    {
        "engDate": "2015-10-19",
        "nepDate": "02.07.2072"
    },
    {
        "engDate": "2015-10-20",
        "nepDate": "03.07.2072"
    },
    {
        "engDate": "2015-10-21",
        "nepDate": "04.07.2072"
    },
    {
        "engDate": "2015-10-22",
        "nepDate": "05.07.2072"
    },
    {
        "engDate": "2015-10-23",
        "nepDate": "06.07.2072"
    },
    {
        "engDate": "2015-10-24",
        "nepDate": "07.07.2072"
    },
    {
        "engDate": "2015-10-25",
        "nepDate": "08.07.2072"
    },
    {
        "engDate": "2015-10-26",
        "nepDate": "09.07.2072"
    },
    {
        "engDate": "2015-10-27",
        "nepDate": "10.07.2072"
    },
    {
        "engDate": "2015-10-28",
        "nepDate": "11.07.2072"
    },
    {
        "engDate": "2015-10-29",
        "nepDate": "12.07.2072"
    },
    {
        "engDate": "2015-10-30",
        "nepDate": "13.07.2072"
    },
    {
        "engDate": "2015-10-31",
        "nepDate": "14.07.2072"
    },
    {
        "engDate": "2015-11-01",
        "nepDate": "15.07.2072"
    },
    {
        "engDate": "2015-11-02",
        "nepDate": "16.07.2072"
    },
    {
        "engDate": "2015-11-03",
        "nepDate": "17.07.2072"
    },
    {
        "engDate": "2015-11-04",
        "nepDate": "18.07.2072"
    },
    {
        "engDate": "2015-11-05",
        "nepDate": "19.07.2072"
    },
    {
        "engDate": "2015-11-06",
        "nepDate": "20.07.2072"
    },
    {
        "engDate": "2015-11-07",
        "nepDate": "21.07.2072"
    },
    {
        "engDate": "2015-11-08",
        "nepDate": "22.07.2072"
    },
    {
        "engDate": "2015-11-09",
        "nepDate": "23.07.2072"
    },
    {
        "engDate": "2015-11-10",
        "nepDate": "24.07.2072"
    },
    {
        "engDate": "2015-11-11",
        "nepDate": "25.07.2072"
    },
    {
        "engDate": "2015-11-12",
        "nepDate": "26.07.2072"
    },
    {
        "engDate": "2015-11-13",
        "nepDate": "27.07.2072"
    },
    {
        "engDate": "2015-11-14",
        "nepDate": "28.07.2072"
    },
    {
        "engDate": "2015-11-15",
        "nepDate": "29.07.2072"
    },
    {
        "engDate": "2015-11-16",
        "nepDate": "30.07.2072"
    },
    {
        "engDate": "2015-11-17",
        "nepDate": "01.08.2072"
    },
    {
        "engDate": "2015-11-18",
        "nepDate": "02.08.2072"
    },
    {
        "engDate": "2015-11-19",
        "nepDate": "03.08.2072"
    },
    {
        "engDate": "2015-11-20",
        "nepDate": "04.08.2072"
    },
    {
        "engDate": "2015-11-21",
        "nepDate": "05.08.2072"
    },
    {
        "engDate": "2015-11-22",
        "nepDate": "06.08.2072"
    },
    {
        "engDate": "2015-11-23",
        "nepDate": "07.08.2072"
    },
    {
        "engDate": "2015-11-24",
        "nepDate": "08.08.2072"
    },
    {
        "engDate": "2015-11-25",
        "nepDate": "09.08.2072"
    },
    {
        "engDate": "2015-11-26",
        "nepDate": "10.08.2072"
    },
    {
        "engDate": "2015-11-27",
        "nepDate": "11.08.2072"
    },
    {
        "engDate": "2015-11-28",
        "nepDate": "12.08.2072"
    },
    {
        "engDate": "2015-11-29",
        "nepDate": "13.08.2072"
    },
    {
        "engDate": "2015-11-30",
        "nepDate": "14.08.2072"
    },
    {
        "engDate": "2015-12-01",
        "nepDate": "15.08.2072"
    },
    {
        "engDate": "2015-12-02",
        "nepDate": "16.08.2072"
    },
    {
        "engDate": "2015-12-03",
        "nepDate": "17.08.2072"
    },
    {
        "engDate": "2015-12-04",
        "nepDate": "18.08.2072"
    },
    {
        "engDate": "2015-12-05",
        "nepDate": "19.08.2072"
    },
    {
        "engDate": "2015-12-06",
        "nepDate": "20.08.2072"
    },
    {
        "engDate": "2015-12-07",
        "nepDate": "21.08.2072"
    },
    {
        "engDate": "2015-12-08",
        "nepDate": "22.08.2072"
    },
    {
        "engDate": "2015-12-09",
        "nepDate": "23.08.2072"
    },
    {
        "engDate": "2015-12-10",
        "nepDate": "24.08.2072"
    },
    {
        "engDate": "2015-12-11",
        "nepDate": "25.08.2072"
    },
    {
        "engDate": "2015-12-12",
        "nepDate": "26.08.2072"
    },
    {
        "engDate": "2015-12-13",
        "nepDate": "27.08.2072"
    },
    {
        "engDate": "2015-12-14",
        "nepDate": "28.08.2072"
    },
    {
        "engDate": "2015-12-15",
        "nepDate": "29.08.2072"
    },
    {
        "engDate": "2015-12-16",
        "nepDate": "01.09.2072"
    },
    {
        "engDate": "2015-12-17",
        "nepDate": "02.09.2072"
    },
    {
        "engDate": "2015-12-18",
        "nepDate": "03.09.2072"
    },
    {
        "engDate": "2015-12-19",
        "nepDate": "04.09.2072"
    },
    {
        "engDate": "2015-12-20",
        "nepDate": "05.09.2072"
    },
    {
        "engDate": "2015-12-21",
        "nepDate": "06.09.2072"
    },
    {
        "engDate": "2015-12-22",
        "nepDate": "07.09.2072"
    },
    {
        "engDate": "2015-12-23",
        "nepDate": "08.09.2072"
    },
    {
        "engDate": "2015-12-24",
        "nepDate": "09.09.2072"
    },
    {
        "engDate": "2015-12-25",
        "nepDate": "10.09.2072"
    },
    {
        "engDate": "2015-12-26",
        "nepDate": "11.09.2072"
    },
    {
        "engDate": "2015-12-27",
        "nepDate": "12.09.2072"
    },
    {
        "engDate": "2015-12-28",
        "nepDate": "13.09.2072"
    },
    {
        "engDate": "2015-12-29",
        "nepDate": "14.09.2072"
    },
    {
        "engDate": "2015-12-30",
        "nepDate": "15.09.2072"
    },
    {
        "engDate": "2015-12-31",
        "nepDate": "16.09.2072"
    },
    {
        "engDate": "2016-01-01",
        "nepDate": "17.09.2072"
    },
    {
        "engDate": "2016-01-02",
        "nepDate": "18.09.2072"
    },
    {
        "engDate": "2016-01-03",
        "nepDate": "19.09.2072"
    },
    {
        "engDate": "2016-01-04",
        "nepDate": "20.09.2072"
    },
    {
        "engDate": "2016-01-05",
        "nepDate": "21.09.2072"
    },
    {
        "engDate": "2016-01-06",
        "nepDate": "22.09.2072"
    },
    {
        "engDate": "2016-01-07",
        "nepDate": "23.09.2072"
    },
    {
        "engDate": "2016-01-08",
        "nepDate": "24.09.2072"
    },
    {
        "engDate": "2016-01-09",
        "nepDate": "25.09.2072"
    },
    {
        "engDate": "2016-01-10",
        "nepDate": "26.09.2072"
    },
    {
        "engDate": "2016-01-11",
        "nepDate": "27.09.2072"
    },
    {
        "engDate": "2016-01-12",
        "nepDate": "28.09.2072"
    },
    {
        "engDate": "2016-01-13",
        "nepDate": "29.09.2072"
    },
    {
        "engDate": "2016-01-14",
        "nepDate": "30.09.2072"
    },
    {
        "engDate": "2016-01-15",
        "nepDate": "01.10.2072"
    },
    {
        "engDate": "2016-01-16",
        "nepDate": "02.10.2072"
    },
    {
        "engDate": "2016-01-17",
        "nepDate": "03.10.2072"
    },
    {
        "engDate": "2016-01-18",
        "nepDate": "04.10.2072"
    },
    {
        "engDate": "2016-01-19",
        "nepDate": "05.10.2072"
    },
    {
        "engDate": "2016-01-20",
        "nepDate": "06.10.2072"
    },
    {
        "engDate": "2016-01-21",
        "nepDate": "07.10.2072"
    },
    {
        "engDate": "2016-01-22",
        "nepDate": "08.10.2072"
    },
    {
        "engDate": "2016-01-23",
        "nepDate": "09.10.2072"
    },
    {
        "engDate": "2016-01-24",
        "nepDate": "10.10.2072"
    },
    {
        "engDate": "2016-01-25",
        "nepDate": "11.10.2072"
    },
    {
        "engDate": "2016-01-26",
        "nepDate": "12.10.2072"
    },
    {
        "engDate": "2016-01-27",
        "nepDate": "13.10.2072"
    },
    {
        "engDate": "2016-01-28",
        "nepDate": "14.10.2072"
    },
    {
        "engDate": "2016-01-29",
        "nepDate": "15.10.2072"
    },
    {
        "engDate": "2016-01-30",
        "nepDate": "16.10.2072"
    },
    {
        "engDate": "2016-01-31",
        "nepDate": "17.10.2072"
    },
    {
        "engDate": "2016-02-01",
        "nepDate": "18.10.2072"
    },
    {
        "engDate": "2016-02-02",
        "nepDate": "19.10.2072"
    },
    {
        "engDate": "2016-02-03",
        "nepDate": "20.10.2072"
    },
    {
        "engDate": "2016-02-04",
        "nepDate": "21.10.2072"
    },
    {
        "engDate": "2016-02-05",
        "nepDate": "22.10.2072"
    },
    {
        "engDate": "2016-02-06",
        "nepDate": "23.10.2072"
    },
    {
        "engDate": "2016-02-07",
        "nepDate": "24.10.2072"
    },
    {
        "engDate": "2016-02-08",
        "nepDate": "25.10.2072"
    },
    {
        "engDate": "2016-02-09",
        "nepDate": "26.10.2072"
    },
    {
        "engDate": "2016-02-10",
        "nepDate": "27.10.2072"
    },
    {
        "engDate": "2016-02-11",
        "nepDate": "28.10.2072"
    },
    {
        "engDate": "2016-02-12",
        "nepDate": "29.10.2072"
    },
    {
        "engDate": "2016-02-13",
        "nepDate": "01.11.2072"
    },
    {
        "engDate": "2016-02-14",
        "nepDate": "02.11.2072"
    },
    {
        "engDate": "2016-02-15",
        "nepDate": "03.11.2072"
    },
    {
        "engDate": "2016-02-16",
        "nepDate": "04.11.2072"
    },
    {
        "engDate": "2016-02-17",
        "nepDate": "05.11.2072"
    },
    {
        "engDate": "2016-02-18",
        "nepDate": "06.11.2072"
    },
    {
        "engDate": "2016-02-19",
        "nepDate": "07.11.2072"
    },
    {
        "engDate": "2016-02-20",
        "nepDate": "08.11.2072"
    },
    {
        "engDate": "2016-02-21",
        "nepDate": "09.11.2072"
    },
    {
        "engDate": "2016-02-22",
        "nepDate": "10.11.2072"
    },
    {
        "engDate": "2016-02-23",
        "nepDate": "11.11.2072"
    },
    {
        "engDate": "2016-02-24",
        "nepDate": "12.11.2072"
    },
    {
        "engDate": "2016-02-25",
        "nepDate": "13.11.2072"
    },
    {
        "engDate": "2016-02-26",
        "nepDate": "14.11.2072"
    },
    {
        "engDate": "2016-02-27",
        "nepDate": "15.11.2072"
    },
    {
        "engDate": "2016-02-28",
        "nepDate": "16.11.2072"
    },
    {
        "engDate": "2016-02-29",
        "nepDate": "17.11.2072"
    },
    {
        "engDate": "2016-03-01",
        "nepDate": "18.11.2072"
    },
    {
        "engDate": "2016-03-02",
        "nepDate": "19.11.2072"
    },
    {
        "engDate": "2016-03-03",
        "nepDate": "20.11.2072"
    },
    {
        "engDate": "2016-03-04",
        "nepDate": "21.11.2072"
    },
    {
        "engDate": "2016-03-05",
        "nepDate": "22.11.2072"
    },
    {
        "engDate": "2016-03-06",
        "nepDate": "23.11.2072"
    },
    {
        "engDate": "2016-03-07",
        "nepDate": "24.11.2072"
    },
    {
        "engDate": "2016-03-08",
        "nepDate": "25.11.2072"
    },
    {
        "engDate": "2016-03-09",
        "nepDate": "26.11.2072"
    },
    {
        "engDate": "2016-03-10",
        "nepDate": "27.11.2072"
    },
    {
        "engDate": "2016-03-11",
        "nepDate": "28.11.2072"
    },
    {
        "engDate": "2016-03-12",
        "nepDate": "29.11.2072"
    },
    {
        "engDate": "2016-03-13",
        "nepDate": "30.11.2072"
    },
    {
        "engDate": "2016-03-14",
        "nepDate": "01.12.2072"
    },
    {
        "engDate": "2016-03-15",
        "nepDate": "02.12.2072"
    },
    {
        "engDate": "2016-03-16",
        "nepDate": "03.12.2072"
    },
    {
        "engDate": "2016-03-17",
        "nepDate": "04.12.2072"
    },
    {
        "engDate": "2016-03-18",
        "nepDate": "05.12.2072"
    },
    {
        "engDate": "2016-03-19",
        "nepDate": "06.12.2072"
    },
    {
        "engDate": "2016-03-20",
        "nepDate": "07.12.2072"
    },
    {
        "engDate": "2016-03-21",
        "nepDate": "08.12.2072"
    },
    {
        "engDate": "2016-03-22",
        "nepDate": "09.12.2072"
    },
    {
        "engDate": "2016-03-23",
        "nepDate": "10.12.2072"
    },
    {
        "engDate": "2016-03-24",
        "nepDate": "11.12.2072"
    },
    {
        "engDate": "2016-03-25",
        "nepDate": "12.12.2072"
    },
    {
        "engDate": "2016-03-26",
        "nepDate": "13.12.2072"
    },
    {
        "engDate": "2016-03-27",
        "nepDate": "14.12.2072"
    },
    {
        "engDate": "2016-03-28",
        "nepDate": "15.12.2072"
    },
    {
        "engDate": "2016-03-29",
        "nepDate": "16.12.2072"
    },
    {
        "engDate": "2016-03-30",
        "nepDate": "17.12.2072"
    },
    {
        "engDate": "2016-03-31",
        "nepDate": "18.12.2072"
    },
    {
        "engDate": "2016-04-01",
        "nepDate": "19.12.2072"
    },
    {
        "engDate": "2016-04-02",
        "nepDate": "20.12.2072"
    },
    {
        "engDate": "2016-04-03",
        "nepDate": "21.12.2072"
    },
    {
        "engDate": "2016-04-04",
        "nepDate": "22.12.2072"
    },
    {
        "engDate": "2016-04-05",
        "nepDate": "23.12.2072"
    },
    {
        "engDate": "2016-04-06",
        "nepDate": "24.12.2072"
    },
    {
        "engDate": "2016-04-07",
        "nepDate": "25.12.2072"
    },
    {
        "engDate": "2016-04-08",
        "nepDate": "26.12.2072"
    },
    {
        "engDate": "2016-04-09",
        "nepDate": "27.12.2072"
    },
    {
        "engDate": "2016-04-10",
        "nepDate": "28.12.2072"
    },
    {
        "engDate": "2016-04-11",
        "nepDate": "29.12.2072"
    },
    {
        "engDate": "2016-04-12",
        "nepDate": "30.12.2072"
    },
    {
        "engDate": "2016-04-13",
        "nepDate": "01.01.2073"
    },
    {
        "engDate": "2016-04-14",
        "nepDate": "02.01.2073"
    },
    {
        "engDate": "2016-04-15",
        "nepDate": "03.01.2073"
    },
    {
        "engDate": "2016-04-16",
        "nepDate": "04.01.2073"
    },
    {
        "engDate": "2016-04-17",
        "nepDate": "05.01.2073"
    },
    {
        "engDate": "2016-04-18",
        "nepDate": "06.01.2073"
    },
    {
        "engDate": "2016-04-19",
        "nepDate": "07.01.2073"
    },
    {
        "engDate": "2016-04-20",
        "nepDate": "08.01.2073"
    },
    {
        "engDate": "2016-04-21",
        "nepDate": "09.01.2073"
    },
    {
        "engDate": "2016-04-22",
        "nepDate": "10.01.2073"
    },
    {
        "engDate": "2016-04-23",
        "nepDate": "11.01.2073"
    },
    {
        "engDate": "2016-04-24",
        "nepDate": "12.01.2073"
    },
    {
        "engDate": "2016-04-25",
        "nepDate": "13.01.2073"
    },
    {
        "engDate": "2016-04-26",
        "nepDate": "14.01.2073"
    },
    {
        "engDate": "2016-04-27",
        "nepDate": "15.01.2073"
    },
    {
        "engDate": "2016-04-28",
        "nepDate": "16.01.2073"
    },
    {
        "engDate": "2016-04-29",
        "nepDate": "17.01.2073"
    },
    {
        "engDate": "2016-04-30",
        "nepDate": "18.01.2073"
    },
    {
        "engDate": "2016-05-01",
        "nepDate": "19.01.2073"
    },
    {
        "engDate": "2016-05-02",
        "nepDate": "20.01.2073"
    },
    {
        "engDate": "2016-05-03",
        "nepDate": "21.01.2073"
    },
    {
        "engDate": "2016-05-04",
        "nepDate": "22.01.2073"
    },
    {
        "engDate": "2016-05-05",
        "nepDate": "23.01.2073"
    },
    {
        "engDate": "2016-05-06",
        "nepDate": "24.01.2073"
    },
    {
        "engDate": "2016-05-07",
        "nepDate": "25.01.2073"
    },
    {
        "engDate": "2016-05-08",
        "nepDate": "26.01.2073"
    },
    {
        "engDate": "2016-05-09",
        "nepDate": "27.01.2073"
    },
    {
        "engDate": "2016-05-10",
        "nepDate": "28.01.2073"
    },
    {
        "engDate": "2016-05-11",
        "nepDate": "29.01.2073"
    },
    {
        "engDate": "2016-05-12",
        "nepDate": "30.01.2073"
    },
    {
        "engDate": "2016-05-13",
        "nepDate": "31.01.2073"
    },
    {
        "engDate": "2016-05-14",
        "nepDate": "01.02.2073"
    },
    {
        "engDate": "2016-05-15",
        "nepDate": "02.02.2073"
    },
    {
        "engDate": "2016-05-16",
        "nepDate": "03.02.2073"
    },
    {
        "engDate": "2016-05-17",
        "nepDate": "04.02.2073"
    },
    {
        "engDate": "2016-05-18",
        "nepDate": "05.02.2073"
    },
    {
        "engDate": "2016-05-19",
        "nepDate": "06.02.2073"
    },
    {
        "engDate": "2016-05-20",
        "nepDate": "07.02.2073"
    },
    {
        "engDate": "2016-05-21",
        "nepDate": "08.02.2073"
    },
    {
        "engDate": "2016-05-22",
        "nepDate": "09.02.2073"
    },
    {
        "engDate": "2016-05-23",
        "nepDate": "10.02.2073"
    },
    {
        "engDate": "2016-05-24",
        "nepDate": "11.02.2073"
    },
    {
        "engDate": "2016-05-25",
        "nepDate": "12.02.2073"
    },
    {
        "engDate": "2016-05-26",
        "nepDate": "13.02.2073"
    },
    {
        "engDate": "2016-05-27",
        "nepDate": "14.02.2073"
    },
    {
        "engDate": "2016-05-28",
        "nepDate": "15.02.2073"
    },
    {
        "engDate": "2016-05-29",
        "nepDate": "16.02.2073"
    },
    {
        "engDate": "2016-05-30",
        "nepDate": "17.02.2073"
    },
    {
        "engDate": "2016-05-31",
        "nepDate": "18.02.2073"
    },
    {
        "engDate": "2016-06-01",
        "nepDate": "19.02.2073"
    },
    {
        "engDate": "2016-06-02",
        "nepDate": "20.02.2073"
    },
    {
        "engDate": "2016-06-03",
        "nepDate": "21.02.2073"
    },
    {
        "engDate": "2016-06-04",
        "nepDate": "22.02.2073"
    },
    {
        "engDate": "2016-06-05",
        "nepDate": "23.02.2073"
    },
    {
        "engDate": "2016-06-06",
        "nepDate": "24.02.2073"
    },
    {
        "engDate": "2016-06-07",
        "nepDate": "25.02.2073"
    },
    {
        "engDate": "2016-06-08",
        "nepDate": "26.02.2073"
    },
    {
        "engDate": "2016-06-09",
        "nepDate": "27.02.2073"
    },
    {
        "engDate": "2016-06-10",
        "nepDate": "28.02.2073"
    },
    {
        "engDate": "2016-06-11",
        "nepDate": "29.02.2073"
    },
    {
        "engDate": "2016-06-12",
        "nepDate": "30.02.2073"
    },
    {
        "engDate": "2016-06-13",
        "nepDate": "31.02.2073"
    },
    {
        "engDate": "2016-06-14",
        "nepDate": "32.02.2073"
    },
    {
        "engDate": "2016-06-15",
        "nepDate": "01.03.2073"
    },
    {
        "engDate": "2016-06-16",
        "nepDate": "02.03.2073"
    },
    {
        "engDate": "2016-06-17",
        "nepDate": "03.03.2073"
    },
    {
        "engDate": "2016-06-18",
        "nepDate": "04.03.2073"
    },
    {
        "engDate": "2016-06-19",
        "nepDate": "05.03.2073"
    },
    {
        "engDate": "2016-06-20",
        "nepDate": "06.03.2073"
    },
    {
        "engDate": "2016-06-21",
        "nepDate": "07.03.2073"
    },
    {
        "engDate": "2016-06-22",
        "nepDate": "08.03.2073"
    },
    {
        "engDate": "2016-06-23",
        "nepDate": "09.03.2073"
    },
    {
        "engDate": "2016-06-24",
        "nepDate": "10.03.2073"
    },
    {
        "engDate": "2016-06-25",
        "nepDate": "11.03.2073"
    },
    {
        "engDate": "2016-06-26",
        "nepDate": "12.03.2073"
    },
    {
        "engDate": "2016-06-27",
        "nepDate": "13.03.2073"
    },
    {
        "engDate": "2016-06-28",
        "nepDate": "14.03.2073"
    },
    {
        "engDate": "2016-06-29",
        "nepDate": "15.03.2073"
    },
    {
        "engDate": "2016-06-30",
        "nepDate": "16.03.2073"
    },
    {
        "engDate": "2016-07-01",
        "nepDate": "17.03.2073"
    },
    {
        "engDate": "2016-07-02",
        "nepDate": "18.03.2073"
    },
    {
        "engDate": "2016-07-03",
        "nepDate": "19.03.2073"
    },
    {
        "engDate": "2016-07-04",
        "nepDate": "20.03.2073"
    },
    {
        "engDate": "2016-07-05",
        "nepDate": "21.03.2073"
    },
    {
        "engDate": "2016-07-06",
        "nepDate": "22.03.2073"
    },
    {
        "engDate": "2016-07-07",
        "nepDate": "23.03.2073"
    },
    {
        "engDate": "2016-07-08",
        "nepDate": "24.03.2073"
    },
    {
        "engDate": "2016-07-09",
        "nepDate": "25.03.2073"
    },
    {
        "engDate": "2016-07-10",
        "nepDate": "26.03.2073"
    },
    {
        "engDate": "2016-07-11",
        "nepDate": "27.03.2073"
    },
    {
        "engDate": "2016-07-12",
        "nepDate": "28.03.2073"
    },
    {
        "engDate": "2016-07-13",
        "nepDate": "29.03.2073"
    },
    {
        "engDate": "2016-07-14",
        "nepDate": "30.03.2073"
    },
    {
        "engDate": "2016-07-15",
        "nepDate": "31.03.2073"
    },
    {
        "engDate": "2016-07-16",
        "nepDate": "01.04.2073"
    },
    {
        "engDate": "2016-07-17",
        "nepDate": "02.04.2073"
    },
    {
        "engDate": "2016-07-18",
        "nepDate": "03.04.2073"
    },
    {
        "engDate": "2016-07-19",
        "nepDate": "04.04.2073"
    },
    {
        "engDate": "2016-07-20",
        "nepDate": "05.04.2073"
    },
    {
        "engDate": "2016-07-21",
        "nepDate": "06.04.2073"
    },
    {
        "engDate": "2016-07-22",
        "nepDate": "07.04.2073"
    },
    {
        "engDate": "2016-07-23",
        "nepDate": "08.04.2073"
    },
    {
        "engDate": "2016-07-24",
        "nepDate": "09.04.2073"
    },
    {
        "engDate": "2016-07-25",
        "nepDate": "10.04.2073"
    },
    {
        "engDate": "2016-07-26",
        "nepDate": "11.04.2073"
    },
    {
        "engDate": "2016-07-27",
        "nepDate": "12.04.2073"
    },
    {
        "engDate": "2016-07-28",
        "nepDate": "13.04.2073"
    },
    {
        "engDate": "2016-07-29",
        "nepDate": "14.04.2073"
    },
    {
        "engDate": "2016-07-30",
        "nepDate": "15.04.2073"
    },
    {
        "engDate": "2016-07-31",
        "nepDate": "16.04.2073"
    },
    {
        "engDate": "2016-08-01",
        "nepDate": "17.04.2073"
    },
    {
        "engDate": "2016-08-02",
        "nepDate": "18.04.2073"
    },
    {
        "engDate": "2016-08-03",
        "nepDate": "19.04.2073"
    },
    {
        "engDate": "2016-08-04",
        "nepDate": "20.04.2073"
    },
    {
        "engDate": "2016-08-05",
        "nepDate": "21.04.2073"
    },
    {
        "engDate": "2016-08-06",
        "nepDate": "22.04.2073"
    },
    {
        "engDate": "2016-08-07",
        "nepDate": "23.04.2073"
    },
    {
        "engDate": "2016-08-08",
        "nepDate": "24.04.2073"
    },
    {
        "engDate": "2016-08-09",
        "nepDate": "25.04.2073"
    },
    {
        "engDate": "2016-08-10",
        "nepDate": "26.04.2073"
    },
    {
        "engDate": "2016-08-11",
        "nepDate": "27.04.2073"
    },
    {
        "engDate": "2016-08-12",
        "nepDate": "28.04.2073"
    },
    {
        "engDate": "2016-08-13",
        "nepDate": "29.04.2073"
    },
    {
        "engDate": "2016-08-14",
        "nepDate": "30.04.2073"
    },
    {
        "engDate": "2016-08-15",
        "nepDate": "31.04.2073"
    },
    {
        "engDate": "2016-08-16",
        "nepDate": "32.04.2073"
    },
    {
        "engDate": "2016-08-17",
        "nepDate": "01.05.2073"
    },
    {
        "engDate": "2016-08-18",
        "nepDate": "02.05.2073"
    },
    {
        "engDate": "2016-08-19",
        "nepDate": "03.05.2073"
    },
    {
        "engDate": "2016-08-20",
        "nepDate": "04.05.2073"
    },
    {
        "engDate": "2016-08-21",
        "nepDate": "05.05.2073"
    },
    {
        "engDate": "2016-08-22",
        "nepDate": "06.05.2073"
    },
    {
        "engDate": "2016-08-23",
        "nepDate": "07.05.2073"
    },
    {
        "engDate": "2016-08-24",
        "nepDate": "08.05.2073"
    },
    {
        "engDate": "2016-08-25",
        "nepDate": "09.05.2073"
    },
    {
        "engDate": "2016-08-26",
        "nepDate": "10.05.2073"
    },
    {
        "engDate": "2016-08-27",
        "nepDate": "11.05.2073"
    },
    {
        "engDate": "2016-08-28",
        "nepDate": "12.05.2073"
    },
    {
        "engDate": "2016-08-29",
        "nepDate": "13.05.2073"
    },
    {
        "engDate": "2016-08-30",
        "nepDate": "14.05.2073"
    },
    {
        "engDate": "2016-08-31",
        "nepDate": "15.05.2073"
    },
    {
        "engDate": "2016-09-01",
        "nepDate": "16.05.2073"
    },
    {
        "engDate": "2016-09-02",
        "nepDate": "17.05.2073"
    },
    {
        "engDate": "2016-09-03",
        "nepDate": "18.05.2073"
    },
    {
        "engDate": "2016-09-04",
        "nepDate": "19.05.2073"
    },
    {
        "engDate": "2016-09-05",
        "nepDate": "20.05.2073"
    },
    {
        "engDate": "2016-09-06",
        "nepDate": "21.05.2073"
    },
    {
        "engDate": "2016-09-07",
        "nepDate": "22.05.2073"
    },
    {
        "engDate": "2016-09-08",
        "nepDate": "23.05.2073"
    },
    {
        "engDate": "2016-09-09",
        "nepDate": "24.05.2073"
    },
    {
        "engDate": "2016-09-10",
        "nepDate": "25.05.2073"
    },
    {
        "engDate": "2016-09-11",
        "nepDate": "26.05.2073"
    },
    {
        "engDate": "2016-09-12",
        "nepDate": "27.05.2073"
    },
    {
        "engDate": "2016-09-13",
        "nepDate": "28.05.2073"
    },
    {
        "engDate": "2016-09-14",
        "nepDate": "29.05.2073"
    },
    {
        "engDate": "2016-09-15",
        "nepDate": "30.05.2073"
    },
    {
        "engDate": "2016-09-16",
        "nepDate": "31.05.2073"
    },
    {
        "engDate": "2016-09-17",
        "nepDate": "01.06.2073"
    },
    {
        "engDate": "2016-09-18",
        "nepDate": "02.06.2073"
    },
    {
        "engDate": "2016-09-19",
        "nepDate": "03.06.2073"
    },
    {
        "engDate": "2016-09-20",
        "nepDate": "04.06.2073"
    },
    {
        "engDate": "2016-09-21",
        "nepDate": "05.06.2073"
    },
    {
        "engDate": "2016-09-22",
        "nepDate": "06.06.2073"
    },
    {
        "engDate": "2016-09-23",
        "nepDate": "07.06.2073"
    },
    {
        "engDate": "2016-09-24",
        "nepDate": "08.06.2073"
    },
    {
        "engDate": "2016-09-25",
        "nepDate": "09.06.2073"
    },
    {
        "engDate": "2016-09-26",
        "nepDate": "10.06.2073"
    },
    {
        "engDate": "2016-09-27",
        "nepDate": "11.06.2073"
    },
    {
        "engDate": "2016-09-28",
        "nepDate": "12.06.2073"
    },
    {
        "engDate": "2016-09-29",
        "nepDate": "13.06.2073"
    },
    {
        "engDate": "2016-09-30",
        "nepDate": "14.06.2073"
    },
    {
        "engDate": "2016-10-01",
        "nepDate": "15.06.2073"
    },
    {
        "engDate": "2016-10-02",
        "nepDate": "16.06.2073"
    },
    {
        "engDate": "2016-10-03",
        "nepDate": "17.06.2073"
    },
    {
        "engDate": "2016-10-04",
        "nepDate": "18.06.2073"
    },
    {
        "engDate": "2016-10-05",
        "nepDate": "19.06.2073"
    },
    {
        "engDate": "2016-10-06",
        "nepDate": "20.06.2073"
    },
    {
        "engDate": "2016-10-07",
        "nepDate": "21.06.2073"
    },
    {
        "engDate": "2016-10-08",
        "nepDate": "22.06.2073"
    },
    {
        "engDate": "2016-10-09",
        "nepDate": "23.06.2073"
    },
    {
        "engDate": "2016-10-10",
        "nepDate": "24.06.2073"
    },
    {
        "engDate": "2016-10-11",
        "nepDate": "25.06.2073"
    },
    {
        "engDate": "2016-10-12",
        "nepDate": "26.06.2073"
    },
    {
        "engDate": "2016-10-13",
        "nepDate": "27.06.2073"
    },
    {
        "engDate": "2016-10-14",
        "nepDate": "28.06.2073"
    },
    {
        "engDate": "2016-10-15",
        "nepDate": "29.06.2073"
    },
    {
        "engDate": "2016-10-16",
        "nepDate": "30.06.2073"
    },
    {
        "engDate": "2016-10-17",
        "nepDate": "01.07.2073"
    },
    {
        "engDate": "2016-10-18",
        "nepDate": "02.07.2073"
    },
    {
        "engDate": "2016-10-19",
        "nepDate": "03.07.2073"
    },
    {
        "engDate": "2016-10-20",
        "nepDate": "04.07.2073"
    },
    {
        "engDate": "2016-10-21",
        "nepDate": "05.07.2073"
    },
    {
        "engDate": "2016-10-22",
        "nepDate": "06.07.2073"
    },
    {
        "engDate": "2016-10-23",
        "nepDate": "07.07.2073"
    },
    {
        "engDate": "2016-10-24",
        "nepDate": "08.07.2073"
    },
    {
        "engDate": "2016-10-25",
        "nepDate": "09.07.2073"
    },
    {
        "engDate": "2016-10-26",
        "nepDate": "10.07.2073"
    },
    {
        "engDate": "2016-10-27",
        "nepDate": "11.07.2073"
    },
    {
        "engDate": "2016-10-28",
        "nepDate": "12.07.2073"
    },
    {
        "engDate": "2016-10-29",
        "nepDate": "13.07.2073"
    },
    {
        "engDate": "2016-10-30",
        "nepDate": "14.07.2073"
    },
    {
        "engDate": "2016-10-31",
        "nepDate": "15.07.2073"
    },
    {
        "engDate": "2016-11-01",
        "nepDate": "16.07.2073"
    },
    {
        "engDate": "2016-11-02",
        "nepDate": "17.07.2073"
    },
    {
        "engDate": "2016-11-03",
        "nepDate": "18.07.2073"
    },
    {
        "engDate": "2016-11-04",
        "nepDate": "19.07.2073"
    },
    {
        "engDate": "2016-11-05",
        "nepDate": "20.07.2073"
    },
    {
        "engDate": "2016-11-06",
        "nepDate": "21.07.2073"
    },
    {
        "engDate": "2016-11-07",
        "nepDate": "22.07.2073"
    },
    {
        "engDate": "2016-11-08",
        "nepDate": "23.07.2073"
    },
    {
        "engDate": "2016-11-09",
        "nepDate": "24.07.2073"
    },
    {
        "engDate": "2016-11-10",
        "nepDate": "25.07.2073"
    },
    {
        "engDate": "2016-11-11",
        "nepDate": "26.07.2073"
    },
    {
        "engDate": "2016-11-12",
        "nepDate": "27.07.2073"
    },
    {
        "engDate": "2016-11-13",
        "nepDate": "28.07.2073"
    },
    {
        "engDate": "2016-11-14",
        "nepDate": "29.07.2073"
    },
    {
        "engDate": "2016-11-15",
        "nepDate": "30.07.2073"
    },
    {
        "engDate": "2016-11-16",
        "nepDate": "01.08.2073"
    },
    {
        "engDate": "2016-11-17",
        "nepDate": "02.08.2073"
    },
    {
        "engDate": "2016-11-18",
        "nepDate": "03.08.2073"
    },
    {
        "engDate": "2016-11-19",
        "nepDate": "04.08.2073"
    },
    {
        "engDate": "2016-11-20",
        "nepDate": "05.08.2073"
    },
    {
        "engDate": "2016-11-21",
        "nepDate": "06.08.2073"
    },
    {
        "engDate": "2016-11-22",
        "nepDate": "07.08.2073"
    },
    {
        "engDate": "2016-11-23",
        "nepDate": "08.08.2073"
    },
    {
        "engDate": "2016-11-24",
        "nepDate": "09.08.2073"
    },
    {
        "engDate": "2016-11-25",
        "nepDate": "10.08.2073"
    },
    {
        "engDate": "2016-11-26",
        "nepDate": "11.08.2073"
    },
    {
        "engDate": "2016-11-27",
        "nepDate": "12.08.2073"
    },
    {
        "engDate": "2016-11-28",
        "nepDate": "13.08.2073"
    },
    {
        "engDate": "2016-11-29",
        "nepDate": "14.08.2073"
    },
    {
        "engDate": "2016-11-30",
        "nepDate": "15.08.2073"
    },
    {
        "engDate": "2016-12-01",
        "nepDate": "16.08.2073"
    },
    {
        "engDate": "2016-12-02",
        "nepDate": "17.08.2073"
    },
    {
        "engDate": "2016-12-03",
        "nepDate": "18.08.2073"
    },
    {
        "engDate": "2016-12-04",
        "nepDate": "19.08.2073"
    },
    {
        "engDate": "2016-12-05",
        "nepDate": "20.08.2073"
    },
    {
        "engDate": "2016-12-06",
        "nepDate": "21.08.2073"
    },
    {
        "engDate": "2016-12-07",
        "nepDate": "22.08.2073"
    },
    {
        "engDate": "2016-12-08",
        "nepDate": "23.08.2073"
    },
    {
        "engDate": "2016-12-09",
        "nepDate": "24.08.2073"
    },
    {
        "engDate": "2016-12-10",
        "nepDate": "25.08.2073"
    },
    {
        "engDate": "2016-12-11",
        "nepDate": "26.08.2073"
    },
    {
        "engDate": "2016-12-12",
        "nepDate": "27.08.2073"
    },
    {
        "engDate": "2016-12-13",
        "nepDate": "28.08.2073"
    },
    {
        "engDate": "2016-12-14",
        "nepDate": "29.08.2073"
    },
    {
        "engDate": "2016-12-15",
        "nepDate": "30.08.2073"
    },
    {
        "engDate": "2016-12-16",
        "nepDate": "01.09.2073"
    },
    {
        "engDate": "2016-12-17",
        "nepDate": "02.09.2073"
    },
    {
        "engDate": "2016-12-18",
        "nepDate": "03.09.2073"
    },
    {
        "engDate": "2016-12-19",
        "nepDate": "04.09.2073"
    },
    {
        "engDate": "2016-12-20",
        "nepDate": "05.09.2073"
    },
    {
        "engDate": "2016-12-21",
        "nepDate": "06.09.2073"
    },
    {
        "engDate": "2016-12-22",
        "nepDate": "07.09.2073"
    },
    {
        "engDate": "2016-12-23",
        "nepDate": "08.09.2073"
    },
    {
        "engDate": "2016-12-24",
        "nepDate": "09.09.2073"
    },
    {
        "engDate": "2016-12-25",
        "nepDate": "10.09.2073"
    },
    {
        "engDate": "2016-12-26",
        "nepDate": "11.09.2073"
    },
    {
        "engDate": "2016-12-27",
        "nepDate": "12.09.2073"
    },
    {
        "engDate": "2016-12-28",
        "nepDate": "13.09.2073"
    },
    {
        "engDate": "2016-12-29",
        "nepDate": "14.09.2073"
    },
    {
        "engDate": "2016-12-30",
        "nepDate": "15.09.2073"
    },
    {
        "engDate": "2016-12-31",
        "nepDate": "16.09.2073"
    },
    {
        "engDate": "2017-01-01",
        "nepDate": "17.09.2073"
    },
    {
        "engDate": "2017-01-02",
        "nepDate": "18.09.2073"
    },
    {
        "engDate": "2017-01-03",
        "nepDate": "19.09.2073"
    },
    {
        "engDate": "2017-01-04",
        "nepDate": "20.09.2073"
    },
    {
        "engDate": "2017-01-05",
        "nepDate": "21.09.2073"
    },
    {
        "engDate": "2017-01-06",
        "nepDate": "22.09.2073"
    },
    {
        "engDate": "2017-01-07",
        "nepDate": "23.09.2073"
    },
    {
        "engDate": "2017-01-08",
        "nepDate": "24.09.2073"
    },
    {
        "engDate": "2017-01-09",
        "nepDate": "25.09.2073"
    },
    {
        "engDate": "2017-01-10",
        "nepDate": "26.09.2073"
    },
    {
        "engDate": "2017-01-11",
        "nepDate": "27.09.2073"
    },
    {
        "engDate": "2017-01-12",
        "nepDate": "28.09.2073"
    },
    {
        "engDate": "2017-01-13",
        "nepDate": "29.09.2073"
    },
    {
        "engDate": "2017-01-14",
        "nepDate": "01.10.2073"
    },
    {
        "engDate": "2017-01-15",
        "nepDate": "02.10.2073"
    },
    {
        "engDate": "2017-01-16",
        "nepDate": "03.10.2073"
    },
    {
        "engDate": "2017-01-17",
        "nepDate": "04.10.2073"
    },
    {
        "engDate": "2017-01-18",
        "nepDate": "05.10.2073"
    },
    {
        "engDate": "2017-01-19",
        "nepDate": "06.10.2073"
    },
    {
        "engDate": "2017-01-20",
        "nepDate": "07.10.2073"
    },
    {
        "engDate": "2017-01-21",
        "nepDate": "08.10.2073"
    },
    {
        "engDate": "2017-01-22",
        "nepDate": "09.10.2073"
    },
    {
        "engDate": "2017-01-23",
        "nepDate": "10.10.2073"
    },
    {
        "engDate": "2017-01-24",
        "nepDate": "11.10.2073"
    },
    {
        "engDate": "2017-01-25",
        "nepDate": "12.10.2073"
    },
    {
        "engDate": "2017-01-26",
        "nepDate": "13.10.2073"
    },
    {
        "engDate": "2017-01-27",
        "nepDate": "14.10.2073"
    },
    {
        "engDate": "2017-01-28",
        "nepDate": "15.10.2073"
    },
    {
        "engDate": "2017-01-29",
        "nepDate": "16.10.2073"
    },
    {
        "engDate": "2017-01-30",
        "nepDate": "17.10.2073"
    },
    {
        "engDate": "2017-01-31",
        "nepDate": "18.10.2073"
    },
    {
        "engDate": "2017-02-01",
        "nepDate": "19.10.2073"
    },
    {
        "engDate": "2017-02-02",
        "nepDate": "20.10.2073"
    },
    {
        "engDate": "2017-02-03",
        "nepDate": "21.10.2073"
    },
    {
        "engDate": "2017-02-04",
        "nepDate": "22.10.2073"
    },
    {
        "engDate": "2017-02-05",
        "nepDate": "23.10.2073"
    },
    {
        "engDate": "2017-02-06",
        "nepDate": "24.10.2073"
    },
    {
        "engDate": "2017-02-07",
        "nepDate": "25.10.2073"
    },
    {
        "engDate": "2017-02-08",
        "nepDate": "26.10.2073"
    },
    {
        "engDate": "2017-02-09",
        "nepDate": "27.10.2073"
    },
    {
        "engDate": "2017-02-10",
        "nepDate": "28.10.2073"
    },
    {
        "engDate": "2017-02-11",
        "nepDate": "29.10.2073"
    },
    {
        "engDate": "2017-02-12",
        "nepDate": "01.11.2073"
    },
    {
        "engDate": "2017-02-13",
        "nepDate": "02.11.2073"
    },
    {
        "engDate": "2017-02-14",
        "nepDate": "03.11.2073"
    },
    {
        "engDate": "2017-02-15",
        "nepDate": "04.11.2073"
    },
    {
        "engDate": "2017-02-16",
        "nepDate": "05.11.2073"
    },
    {
        "engDate": "2017-02-17",
        "nepDate": "06.11.2073"
    },
    {
        "engDate": "2017-02-18",
        "nepDate": "07.11.2073"
    },
    {
        "engDate": "2017-02-19",
        "nepDate": "08.11.2073"
    },
    {
        "engDate": "2017-02-20",
        "nepDate": "09.11.2073"
    },
    {
        "engDate": "2017-02-21",
        "nepDate": "10.11.2073"
    },
    {
        "engDate": "2017-02-22",
        "nepDate": "11.11.2073"
    },
    {
        "engDate": "2017-02-23",
        "nepDate": "12.11.2073"
    },
    {
        "engDate": "2017-02-24",
        "nepDate": "13.11.2073"
    },
    {
        "engDate": "2017-02-25",
        "nepDate": "14.11.2073"
    },
    {
        "engDate": "2017-02-26",
        "nepDate": "15.11.2073"
    },
    {
        "engDate": "2017-02-27",
        "nepDate": "16.11.2073"
    },
    {
        "engDate": "2017-02-28",
        "nepDate": "17.11.2073"
    },
    {
        "engDate": "2017-03-01",
        "nepDate": "18.11.2073"
    },
    {
        "engDate": "2017-03-02",
        "nepDate": "19.11.2073"
    },
    {
        "engDate": "2017-03-03",
        "nepDate": "20.11.2073"
    },
    {
        "engDate": "2017-03-04",
        "nepDate": "21.11.2073"
    },
    {
        "engDate": "2017-03-05",
        "nepDate": "22.11.2073"
    },
    {
        "engDate": "2017-03-06",
        "nepDate": "23.11.2073"
    },
    {
        "engDate": "2017-03-07",
        "nepDate": "24.11.2073"
    },
    {
        "engDate": "2017-03-08",
        "nepDate": "25.11.2073"
    },
    {
        "engDate": "2017-03-09",
        "nepDate": "26.11.2073"
    },
    {
        "engDate": "2017-03-10",
        "nepDate": "27.11.2073"
    },
    {
        "engDate": "2017-03-11",
        "nepDate": "28.11.2073"
    },
    {
        "engDate": "2017-03-12",
        "nepDate": "29.11.2073"
    },
    {
        "engDate": "2017-03-13",
        "nepDate": "30.11.2073"
    },
    {
        "engDate": "2017-03-14",
        "nepDate": "01.12.2073"
    },
    {
        "engDate": "2017-03-15",
        "nepDate": "02.12.2073"
    },
    {
        "engDate": "2017-03-16",
        "nepDate": "03.12.2073"
    },
    {
        "engDate": "2017-03-17",
        "nepDate": "04.12.2073"
    },
    {
        "engDate": "2017-03-18",
        "nepDate": "05.12.2073"
    },
    {
        "engDate": "2017-03-19",
        "nepDate": "06.12.2073"
    },
    {
        "engDate": "2017-03-20",
        "nepDate": "07.12.2073"
    },
    {
        "engDate": "2017-03-21",
        "nepDate": "08.12.2073"
    },
    {
        "engDate": "2017-03-22",
        "nepDate": "09.12.2073"
    },
    {
        "engDate": "2017-03-23",
        "nepDate": "10.12.2073"
    },
    {
        "engDate": "2017-03-24",
        "nepDate": "11.12.2073"
    },
    {
        "engDate": "2017-03-25",
        "nepDate": "12.12.2073"
    },
    {
        "engDate": "2017-03-26",
        "nepDate": "13.12.2073"
    },
    {
        "engDate": "2017-03-27",
        "nepDate": "14.12.2073"
    },
    {
        "engDate": "2017-03-28",
        "nepDate": "15.12.2073"
    },
    {
        "engDate": "2017-03-29",
        "nepDate": "16.12.2073"
    },
    {
        "engDate": "2017-03-30",
        "nepDate": "17.12.2073"
    },
    {
        "engDate": "2017-03-31",
        "nepDate": "18.12.2073"
    },
    {
        "engDate": "2017-04-01",
        "nepDate": "19.12.2073"
    },
    {
        "engDate": "2017-04-02",
        "nepDate": "20.12.2073"
    },
    {
        "engDate": "2017-04-03",
        "nepDate": "21.12.2073"
    },
    {
        "engDate": "2017-04-04",
        "nepDate": "22.12.2073"
    },
    {
        "engDate": "2017-04-05",
        "nepDate": "23.12.2073"
    },
    {
        "engDate": "2017-04-06",
        "nepDate": "24.12.2073"
    },
    {
        "engDate": "2017-04-07",
        "nepDate": "25.12.2073"
    },
    {
        "engDate": "2017-04-08",
        "nepDate": "26.12.2073"
    },
    {
        "engDate": "2017-04-09",
        "nepDate": "27.12.2073"
    },
    {
        "engDate": "2017-04-10",
        "nepDate": "28.12.2073"
    },
    {
        "engDate": "2017-04-11",
        "nepDate": "29.12.2073"
    },
    {
        "engDate": "2017-04-12",
        "nepDate": "30.12.2073"
    },
    {
        "engDate": "2017-04-13",
        "nepDate": "31.12.2073"
    },
    {
        "engDate": "2017-04-14",
        "nepDate": "01.01.2074"
    },
    {
        "engDate": "2017-04-15",
        "nepDate": "02.01.2074"
    },
    {
        "engDate": "2017-04-16",
        "nepDate": "03.01.2074"
    },
    {
        "engDate": "2017-04-17",
        "nepDate": "04.01.2074"
    },
    {
        "engDate": "2017-04-18",
        "nepDate": "05.01.2074"
    },
    {
        "engDate": "2017-04-19",
        "nepDate": "06.01.2074"
    },
    {
        "engDate": "2017-04-20",
        "nepDate": "07.01.2074"
    },
    {
        "engDate": "2017-04-21",
        "nepDate": "08.01.2074"
    },
    {
        "engDate": "2017-04-22",
        "nepDate": "09.01.2074"
    },
    {
        "engDate": "2017-04-23",
        "nepDate": "10.01.2074"
    },
    {
        "engDate": "2017-04-24",
        "nepDate": "11.01.2074"
    },
    {
        "engDate": "2017-04-25",
        "nepDate": "12.01.2074"
    },
    {
        "engDate": "2017-04-26",
        "nepDate": "13.01.2074"
    },
    {
        "engDate": "2017-04-27",
        "nepDate": "14.01.2074"
    },
    {
        "engDate": "2017-04-28",
        "nepDate": "15.01.2074"
    },
    {
        "engDate": "2017-04-29",
        "nepDate": "16.01.2074"
    },
    {
        "engDate": "2017-04-30",
        "nepDate": "17.01.2074"
    },
    {
        "engDate": "2017-05-01",
        "nepDate": "18.01.2074"
    },
    {
        "engDate": "2017-05-02",
        "nepDate": "19.01.2074"
    },
    {
        "engDate": "2017-05-03",
        "nepDate": "20.01.2074"
    },
    {
        "engDate": "2017-05-04",
        "nepDate": "21.01.2074"
    },
    {
        "engDate": "2017-05-05",
        "nepDate": "22.01.2074"
    },
    {
        "engDate": "2017-05-06",
        "nepDate": "23.01.2074"
    },
    {
        "engDate": "2017-05-07",
        "nepDate": "24.01.2074"
    },
    {
        "engDate": "2017-05-08",
        "nepDate": "25.01.2074"
    },
    {
        "engDate": "2017-05-09",
        "nepDate": "26.01.2074"
    },
    {
        "engDate": "2017-05-10",
        "nepDate": "27.01.2074"
    },
    {
        "engDate": "2017-05-11",
        "nepDate": "28.01.2074"
    },
    {
        "engDate": "2017-05-12",
        "nepDate": "29.01.2074"
    },
    {
        "engDate": "2017-05-13",
        "nepDate": "30.01.2074"
    },
    {
        "engDate": "2017-05-14",
        "nepDate": "31.01.2074"
    },
    {
        "engDate": "2017-05-15",
        "nepDate": "01.02.2074"
    },
    {
        "engDate": "2017-05-16",
        "nepDate": "02.02.2074"
    },
    {
        "engDate": "2017-05-17",
        "nepDate": "03.02.2074"
    },
    {
        "engDate": "2017-05-18",
        "nepDate": "04.02.2074"
    },
    {
        "engDate": "2017-05-19",
        "nepDate": "05.02.2074"
    },
    {
        "engDate": "2017-05-20",
        "nepDate": "06.02.2074"
    },
    {
        "engDate": "2017-05-21",
        "nepDate": "07.02.2074"
    },
    {
        "engDate": "2017-05-22",
        "nepDate": "08.02.2074"
    },
    {
        "engDate": "2017-05-23",
        "nepDate": "09.02.2074"
    },
    {
        "engDate": "2017-05-24",
        "nepDate": "10.02.2074"
    },
    {
        "engDate": "2017-05-25",
        "nepDate": "11.02.2074"
    },
    {
        "engDate": "2017-05-26",
        "nepDate": "12.02.2074"
    },
    {
        "engDate": "2017-05-27",
        "nepDate": "13.02.2074"
    },
    {
        "engDate": "2017-05-28",
        "nepDate": "14.02.2074"
    },
    {
        "engDate": "2017-05-29",
        "nepDate": "15.02.2074"
    },
    {
        "engDate": "2017-05-30",
        "nepDate": "16.02.2074"
    },
    {
        "engDate": "2017-05-31",
        "nepDate": "17.02.2074"
    },
    {
        "engDate": "2017-06-01",
        "nepDate": "18.02.2074"
    },
    {
        "engDate": "2017-06-02",
        "nepDate": "19.02.2074"
    },
    {
        "engDate": "2017-06-03",
        "nepDate": "20.02.2074"
    },
    {
        "engDate": "2017-06-04",
        "nepDate": "21.02.2074"
    },
    {
        "engDate": "2017-06-05",
        "nepDate": "22.02.2074"
    },
    {
        "engDate": "2017-06-06",
        "nepDate": "23.02.2074"
    },
    {
        "engDate": "2017-06-07",
        "nepDate": "24.02.2074"
    },
    {
        "engDate": "2017-06-08",
        "nepDate": "25.02.2074"
    },
    {
        "engDate": "2017-06-09",
        "nepDate": "26.02.2074"
    },
    {
        "engDate": "2017-06-10",
        "nepDate": "27.02.2074"
    },
    {
        "engDate": "2017-06-11",
        "nepDate": "28.02.2074"
    },
    {
        "engDate": "2017-06-12",
        "nepDate": "29.02.2074"
    },
    {
        "engDate": "2017-06-13",
        "nepDate": "30.02.2074"
    },
    {
        "engDate": "2017-06-14",
        "nepDate": "31.02.2074"
    },
    {
        "engDate": "2017-06-15",
        "nepDate": "01.03.2074"
    },
    {
        "engDate": "2017-06-16",
        "nepDate": "02.03.2074"
    },
    {
        "engDate": "2017-06-17",
        "nepDate": "03.03.2074"
    },
    {
        "engDate": "2017-06-18",
        "nepDate": "04.03.2074"
    },
    {
        "engDate": "2017-06-19",
        "nepDate": "05.03.2074"
    },
    {
        "engDate": "2017-06-20",
        "nepDate": "06.03.2074"
    },
    {
        "engDate": "2017-06-21",
        "nepDate": "07.03.2074"
    },
    {
        "engDate": "2017-06-22",
        "nepDate": "08.03.2074"
    },
    {
        "engDate": "2017-06-23",
        "nepDate": "09.03.2074"
    },
    {
        "engDate": "2017-06-24",
        "nepDate": "10.03.2074"
    },
    {
        "engDate": "2017-06-25",
        "nepDate": "11.03.2074"
    },
    {
        "engDate": "2017-06-26",
        "nepDate": "12.03.2074"
    },
    {
        "engDate": "2017-06-27",
        "nepDate": "13.03.2074"
    },
    {
        "engDate": "2017-06-28",
        "nepDate": "14.03.2074"
    },
    {
        "engDate": "2017-06-29",
        "nepDate": "15.03.2074"
    },
    {
        "engDate": "2017-06-30",
        "nepDate": "16.03.2074"
    },
    {
        "engDate": "2017-07-01",
        "nepDate": "17.03.2074"
    },
    {
        "engDate": "2017-07-02",
        "nepDate": "18.03.2074"
    },
    {
        "engDate": "2017-07-03",
        "nepDate": "19.03.2074"
    },
    {
        "engDate": "2017-07-04",
        "nepDate": "20.03.2074"
    },
    {
        "engDate": "2017-07-05",
        "nepDate": "21.03.2074"
    },
    {
        "engDate": "2017-07-06",
        "nepDate": "22.03.2074"
    },
    {
        "engDate": "2017-07-07",
        "nepDate": "23.03.2074"
    },
    {
        "engDate": "2017-07-08",
        "nepDate": "24.03.2074"
    },
    {
        "engDate": "2017-07-09",
        "nepDate": "25.03.2074"
    },
    {
        "engDate": "2017-07-10",
        "nepDate": "26.03.2074"
    },
    {
        "engDate": "2017-07-11",
        "nepDate": "27.03.2074"
    },
    {
        "engDate": "2017-07-12",
        "nepDate": "28.03.2074"
    },
    {
        "engDate": "2017-07-13",
        "nepDate": "29.03.2074"
    },
    {
        "engDate": "2017-07-14",
        "nepDate": "30.03.2074"
    },
    {
        "engDate": "2017-07-15",
        "nepDate": "31.03.2074"
    },
    {
        "engDate": "2017-07-16",
        "nepDate": "01.04.2074"
    },
    {
        "engDate": "2017-07-17",
        "nepDate": "02.04.2074"
    },
    {
        "engDate": "2017-07-18",
        "nepDate": "03.04.2074"
    },
    {
        "engDate": "2017-07-19",
        "nepDate": "04.04.2074"
    },
    {
        "engDate": "2017-07-20",
        "nepDate": "05.04.2074"
    },
    {
        "engDate": "2017-07-21",
        "nepDate": "06.04.2074"
    },
    {
        "engDate": "2017-07-22",
        "nepDate": "07.04.2074"
    },
    {
        "engDate": "2017-07-23",
        "nepDate": "08.04.2074"
    },
    {
        "engDate": "2017-07-24",
        "nepDate": "09.04.2074"
    },
    {
        "engDate": "2017-07-25",
        "nepDate": "10.04.2074"
    },
    {
        "engDate": "2017-07-26",
        "nepDate": "11.04.2074"
    },
    {
        "engDate": "2017-07-27",
        "nepDate": "12.04.2074"
    },
    {
        "engDate": "2017-07-28",
        "nepDate": "13.04.2074"
    },
    {
        "engDate": "2017-07-29",
        "nepDate": "14.04.2074"
    },
    {
        "engDate": "2017-07-30",
        "nepDate": "15.04.2074"
    },
    {
        "engDate": "2017-07-31",
        "nepDate": "16.04.2074"
    },
    {
        "engDate": "2017-08-01",
        "nepDate": "17.04.2074"
    },
    {
        "engDate": "2017-08-02",
        "nepDate": "18.04.2074"
    },
    {
        "engDate": "2017-08-03",
        "nepDate": "19.04.2074"
    },
    {
        "engDate": "2017-08-04",
        "nepDate": "20.04.2074"
    },
    {
        "engDate": "2017-08-05",
        "nepDate": "21.04.2074"
    },
    {
        "engDate": "2017-08-06",
        "nepDate": "22.04.2074"
    },
    {
        "engDate": "2017-08-07",
        "nepDate": "23.04.2074"
    },
    {
        "engDate": "2017-08-08",
        "nepDate": "24.04.2074"
    },
    {
        "engDate": "2017-08-09",
        "nepDate": "25.04.2074"
    },
    {
        "engDate": "2017-08-10",
        "nepDate": "26.04.2074"
    },
    {
        "engDate": "2017-08-11",
        "nepDate": "27.04.2074"
    },
    {
        "engDate": "2017-08-12",
        "nepDate": "28.04.2074"
    },
    {
        "engDate": "2017-08-13",
        "nepDate": "29.04.2074"
    },
    {
        "engDate": "2017-08-14",
        "nepDate": "30.04.2074"
    },
    {
        "engDate": "2017-08-15",
        "nepDate": "31.04.2074"
    },
    {
        "engDate": "2017-08-16",
        "nepDate": "32.04.2074"
    },
    {
        "engDate": "2017-08-17",
        "nepDate": "01.05.2074"
    },
    {
        "engDate": "2017-08-18",
        "nepDate": "02.05.2074"
    },
    {
        "engDate": "2017-08-19",
        "nepDate": "03.05.2074"
    },
    {
        "engDate": "2017-08-20",
        "nepDate": "04.05.2074"
    },
    {
        "engDate": "2017-08-21",
        "nepDate": "05.05.2074"
    },
    {
        "engDate": "2017-08-22",
        "nepDate": "06.05.2074"
    },
    {
        "engDate": "2017-08-23",
        "nepDate": "07.05.2074"
    },
    {
        "engDate": "2017-08-24",
        "nepDate": "08.05.2074"
    },
    {
        "engDate": "2017-08-25",
        "nepDate": "09.05.2074"
    },
    {
        "engDate": "2017-08-26",
        "nepDate": "10.05.2074"
    },
    {
        "engDate": "2017-08-27",
        "nepDate": "11.05.2074"
    },
    {
        "engDate": "2017-08-28",
        "nepDate": "12.05.2074"
    },
    {
        "engDate": "2017-08-29",
        "nepDate": "13.05.2074"
    },
    {
        "engDate": "2017-08-30",
        "nepDate": "14.05.2074"
    },
    {
        "engDate": "2017-08-31",
        "nepDate": "15.05.2074"
    },
    {
        "engDate": "2017-09-01",
        "nepDate": "16.05.2074"
    },
    {
        "engDate": "2017-09-02",
        "nepDate": "17.05.2074"
    },
    {
        "engDate": "2017-09-03",
        "nepDate": "18.05.2074"
    },
    {
        "engDate": "2017-09-04",
        "nepDate": "19.05.2074"
    },
    {
        "engDate": "2017-09-05",
        "nepDate": "20.05.2074"
    },
    {
        "engDate": "2017-09-06",
        "nepDate": "21.05.2074"
    },
    {
        "engDate": "2017-09-07",
        "nepDate": "22.05.2074"
    },
    {
        "engDate": "2017-09-08",
        "nepDate": "23.05.2074"
    },
    {
        "engDate": "2017-09-09",
        "nepDate": "24.05.2074"
    },
    {
        "engDate": "2017-09-10",
        "nepDate": "25.05.2074"
    },
    {
        "engDate": "2017-09-11",
        "nepDate": "26.05.2074"
    },
    {
        "engDate": "2017-09-12",
        "nepDate": "27.05.2074"
    },
    {
        "engDate": "2017-09-13",
        "nepDate": "28.05.2074"
    },
    {
        "engDate": "2017-09-14",
        "nepDate": "29.05.2074"
    },
    {
        "engDate": "2017-09-15",
        "nepDate": "30.05.2074"
    },
    {
        "engDate": "2017-09-16",
        "nepDate": "31.05.2074"
    },
    {
        "engDate": "2017-09-17",
        "nepDate": "01.06.2074"
    },
    {
        "engDate": "2017-09-18",
        "nepDate": "02.06.2074"
    },
    {
        "engDate": "2017-09-19",
        "nepDate": "03.06.2074"
    },
    {
        "engDate": "2017-09-20",
        "nepDate": "04.06.2074"
    },
    {
        "engDate": "2017-09-21",
        "nepDate": "05.06.2074"
    },
    {
        "engDate": "2017-09-22",
        "nepDate": "06.06.2074"
    },
    {
        "engDate": "2017-09-23",
        "nepDate": "07.06.2074"
    },
    {
        "engDate": "2017-09-24",
        "nepDate": "08.06.2074"
    },
    {
        "engDate": "2017-09-25",
        "nepDate": "09.06.2074"
    },
    {
        "engDate": "2017-09-26",
        "nepDate": "10.06.2074"
    },
    {
        "engDate": "2017-09-27",
        "nepDate": "11.06.2074"
    },
    {
        "engDate": "2017-09-28",
        "nepDate": "12.06.2074"
    },
    {
        "engDate": "2017-09-29",
        "nepDate": "13.06.2074"
    },
    {
        "engDate": "2017-09-30",
        "nepDate": "14.06.2074"
    },
    {
        "engDate": "2017-10-01",
        "nepDate": "15.06.2074"
    },
    {
        "engDate": "2017-10-02",
        "nepDate": "16.06.2074"
    },
    {
        "engDate": "2017-10-03",
        "nepDate": "17.06.2074"
    },
    {
        "engDate": "2017-10-04",
        "nepDate": "18.06.2074"
    },
    {
        "engDate": "2017-10-05",
        "nepDate": "19.06.2074"
    },
    {
        "engDate": "2017-10-06",
        "nepDate": "20.06.2074"
    },
    {
        "engDate": "2017-10-07",
        "nepDate": "21.06.2074"
    },
    {
        "engDate": "2017-10-08",
        "nepDate": "22.06.2074"
    },
    {
        "engDate": "2017-10-09",
        "nepDate": "23.06.2074"
    },
    {
        "engDate": "2017-10-10",
        "nepDate": "24.06.2074"
    },
    {
        "engDate": "2017-10-11",
        "nepDate": "25.06.2074"
    },
    {
        "engDate": "2017-10-12",
        "nepDate": "26.06.2074"
    },
    {
        "engDate": "2017-10-13",
        "nepDate": "27.06.2074"
    },
    {
        "engDate": "2017-10-14",
        "nepDate": "28.06.2074"
    },
    {
        "engDate": "2017-10-15",
        "nepDate": "29.06.2074"
    },
    {
        "engDate": "2017-10-16",
        "nepDate": "30.06.2074"
    },
    {
        "engDate": "2017-10-17",
        "nepDate": "31.06.2074"
    },
    {
        "engDate": "2017-10-18",
        "nepDate": "01.07.2074"
    },
    {
        "engDate": "2017-10-19",
        "nepDate": "02.07.2074"
    },
    {
        "engDate": "2017-10-20",
        "nepDate": "03.07.2074"
    },
    {
        "engDate": "2017-10-21",
        "nepDate": "04.07.2074"
    },
    {
        "engDate": "2017-10-22",
        "nepDate": "05.07.2074"
    },
    {
        "engDate": "2017-10-23",
        "nepDate": "06.07.2074"
    },
    {
        "engDate": "2017-10-24",
        "nepDate": "07.07.2074"
    },
    {
        "engDate": "2017-10-25",
        "nepDate": "08.07.2074"
    },
    {
        "engDate": "2017-10-26",
        "nepDate": "09.07.2074"
    },
    {
        "engDate": "2017-10-27",
        "nepDate": "10.07.2074"
    },
    {
        "engDate": "2017-10-28",
        "nepDate": "11.07.2074"
    },
    {
        "engDate": "2017-10-29",
        "nepDate": "12.07.2074"
    },
    {
        "engDate": "2017-10-30",
        "nepDate": "13.07.2074"
    },
    {
        "engDate": "2017-10-31",
        "nepDate": "14.07.2074"
    },
    {
        "engDate": "2017-11-01",
        "nepDate": "15.07.2074"
    },
    {
        "engDate": "2017-11-02",
        "nepDate": "16.07.2074"
    },
    {
        "engDate": "2017-11-03",
        "nepDate": "17.07.2074"
    },
    {
        "engDate": "2017-11-04",
        "nepDate": "18.07.2074"
    },
    {
        "engDate": "2017-11-05",
        "nepDate": "19.07.2074"
    },
    {
        "engDate": "2017-11-06",
        "nepDate": "20.07.2074"
    },
    {
        "engDate": "2017-11-07",
        "nepDate": "21.07.2074"
    },
    {
        "engDate": "2017-11-08",
        "nepDate": "22.07.2074"
    },
    {
        "engDate": "2017-11-09",
        "nepDate": "23.07.2074"
    },
    {
        "engDate": "2017-11-10",
        "nepDate": "24.07.2074"
    },
    {
        "engDate": "2017-11-11",
        "nepDate": "25.07.2074"
    },
    {
        "engDate": "2017-11-12",
        "nepDate": "26.07.2074"
    },
    {
        "engDate": "2017-11-13",
        "nepDate": "27.07.2074"
    },
    {
        "engDate": "2017-11-14",
        "nepDate": "28.07.2074"
    },
    {
        "engDate": "2017-11-15",
        "nepDate": "29.07.2074"
    },
    {
        "engDate": "2017-11-16",
        "nepDate": "30.07.2074"
    },
    {
        "engDate": "2017-11-17",
        "nepDate": "01.08.2074"
    },
    {
        "engDate": "2017-11-18",
        "nepDate": "02.08.2074"
    },
    {
        "engDate": "2017-11-19",
        "nepDate": "03.08.2074"
    },
    {
        "engDate": "2017-11-20",
        "nepDate": "04.08.2074"
    },
    {
        "engDate": "2017-11-21",
        "nepDate": "05.08.2074"
    },
    {
        "engDate": "2017-11-22",
        "nepDate": "06.08.2074"
    },
    {
        "engDate": "2017-11-23",
        "nepDate": "07.08.2074"
    },
    {
        "engDate": "2017-11-24",
        "nepDate": "08.08.2074"
    },
    {
        "engDate": "2017-11-25",
        "nepDate": "09.08.2074"
    },
    {
        "engDate": "2017-11-26",
        "nepDate": "10.08.2074"
    },
    {
        "engDate": "2017-11-27",
        "nepDate": "11.08.2074"
    },
    {
        "engDate": "2017-11-28",
        "nepDate": "12.08.2074"
    },
    {
        "engDate": "2017-11-29",
        "nepDate": "13.08.2074"
    },
    {
        "engDate": "2017-11-30",
        "nepDate": "14.08.2074"
    },
    {
        "engDate": "2017-12-01",
        "nepDate": "15.08.2074"
    },
    {
        "engDate": "2017-12-02",
        "nepDate": "16.08.2074"
    },
    {
        "engDate": "2017-12-03",
        "nepDate": "17.08.2074"
    },
    {
        "engDate": "2017-12-04",
        "nepDate": "18.08.2074"
    },
    {
        "engDate": "2017-12-05",
        "nepDate": "19.08.2074"
    },
    {
        "engDate": "2017-12-06",
        "nepDate": "20.08.2074"
    },
    {
        "engDate": "2017-12-07",
        "nepDate": "21.08.2074"
    },
    {
        "engDate": "2017-12-08",
        "nepDate": "22.08.2074"
    },
    {
        "engDate": "2017-12-09",
        "nepDate": "23.08.2074"
    },
    {
        "engDate": "2017-12-10",
        "nepDate": "24.08.2074"
    },
    {
        "engDate": "2017-12-11",
        "nepDate": "25.08.2074"
    },
    {
        "engDate": "2017-12-12",
        "nepDate": "26.08.2074"
    },
    {
        "engDate": "2017-12-13",
        "nepDate": "27.08.2074"
    },
    {
        "engDate": "2017-12-14",
        "nepDate": "28.08.2074"
    },
    {
        "engDate": "2017-12-15",
        "nepDate": "29.08.2074"
    },
    {
        "engDate": "2017-12-16",
        "nepDate": "01.09.2074"
    },
    {
        "engDate": "2017-12-17",
        "nepDate": "02.09.2074"
    },
    {
        "engDate": "2017-12-18",
        "nepDate": "03.09.2074"
    },
    {
        "engDate": "2017-12-19",
        "nepDate": "04.09.2074"
    },
    {
        "engDate": "2017-12-20",
        "nepDate": "05.09.2074"
    },
    {
        "engDate": "2017-12-21",
        "nepDate": "06.09.2074"
    },
    {
        "engDate": "2017-12-22",
        "nepDate": "07.09.2074"
    },
    {
        "engDate": "2017-12-23",
        "nepDate": "08.09.2074"
    },
    {
        "engDate": "2017-12-24",
        "nepDate": "09.09.2074"
    },
    {
        "engDate": "2017-12-25",
        "nepDate": "10.09.2074"
    },
    {
        "engDate": "2017-12-26",
        "nepDate": "11.09.2074"
    },
    {
        "engDate": "2017-12-27",
        "nepDate": "12.09.2074"
    },
    {
        "engDate": "2017-12-28",
        "nepDate": "13.09.2074"
    },
    {
        "engDate": "2017-12-29",
        "nepDate": "14.09.2074"
    },
    {
        "engDate": "2017-12-30",
        "nepDate": "15.09.2074"
    },
    {
        "engDate": "2017-12-31",
        "nepDate": "16.09.2074"
    },
    {
        "engDate": "2018-01-01",
        "nepDate": "17.09.2074"
    },
    {
        "engDate": "2018-01-02",
        "nepDate": "18.09.2074"
    },
    {
        "engDate": "2018-01-03",
        "nepDate": "19.09.2074"
    },
    {
        "engDate": "2018-01-04",
        "nepDate": "20.09.2074"
    },
    {
        "engDate": "2018-01-05",
        "nepDate": "21.09.2074"
    },
    {
        "engDate": "2018-01-06",
        "nepDate": "22.09.2074"
    },
    {
        "engDate": "2018-01-07",
        "nepDate": "23.09.2074"
    },
    {
        "engDate": "2018-01-08",
        "nepDate": "24.09.2074"
    },
    {
        "engDate": "2018-01-09",
        "nepDate": "25.09.2074"
    },
    {
        "engDate": "2018-01-10",
        "nepDate": "26.09.2074"
    },
    {
        "engDate": "2018-01-11",
        "nepDate": "27.09.2074"
    },
    {
        "engDate": "2018-01-12",
        "nepDate": "28.09.2074"
    },
    {
        "engDate": "2018-01-13",
        "nepDate": "29.09.2074"
    },
    {
        "engDate": "2018-01-14",
        "nepDate": "30.09.2074"
    },
    {
        "engDate": "2018-01-15",
        "nepDate": "01.10.2074"
    },
    {
        "engDate": "2018-01-16",
        "nepDate": "02.10.2074"
    },
    {
        "engDate": "2018-01-17",
        "nepDate": "03.10.2074"
    },
    {
        "engDate": "2018-01-18",
        "nepDate": "04.10.2074"
    },
    {
        "engDate": "2018-01-19",
        "nepDate": "05.10.2074"
    },
    {
        "engDate": "2018-01-20",
        "nepDate": "06.10.2074"
    },
    {
        "engDate": "2018-01-21",
        "nepDate": "07.10.2074"
    },
    {
        "engDate": "2018-01-22",
        "nepDate": "08.10.2074"
    },
    {
        "engDate": "2018-01-23",
        "nepDate": "09.10.2074"
    },
    {
        "engDate": "2018-01-24",
        "nepDate": "10.10.2074"
    },
    {
        "engDate": "2018-01-25",
        "nepDate": "11.10.2074"
    },
    {
        "engDate": "2018-01-26",
        "nepDate": "12.10.2074"
    },
    {
        "engDate": "2018-01-27",
        "nepDate": "13.10.2074"
    },
    {
        "engDate": "2018-01-28",
        "nepDate": "14.10.2074"
    },
    {
        "engDate": "2018-01-29",
        "nepDate": "15.10.2074"
    },
    {
        "engDate": "2018-01-30",
        "nepDate": "16.10.2074"
    },
    {
        "engDate": "2018-01-31",
        "nepDate": "17.10.2074"
    },
    {
        "engDate": "2018-02-01",
        "nepDate": "18.10.2074"
    },
    {
        "engDate": "2018-02-02",
        "nepDate": "19.10.2074"
    },
    {
        "engDate": "2018-02-03",
        "nepDate": "20.10.2074"
    },
    {
        "engDate": "2018-02-04",
        "nepDate": "21.10.2074"
    },
    {
        "engDate": "2018-02-05",
        "nepDate": "22.10.2074"
    },
    {
        "engDate": "2018-02-06",
        "nepDate": "23.10.2074"
    },
    {
        "engDate": "2018-02-07",
        "nepDate": "24.10.2074"
    },
    {
        "engDate": "2018-02-08",
        "nepDate": "25.10.2074"
    },
    {
        "engDate": "2018-02-09",
        "nepDate": "26.10.2074"
    },
    {
        "engDate": "2018-02-10",
        "nepDate": "27.10.2074"
    },
    {
        "engDate": "2018-02-11",
        "nepDate": "28.10.2074"
    },
    {
        "engDate": "2018-02-12",
        "nepDate": "29.10.2074"
    },
    {
        "engDate": "2018-02-13",
        "nepDate": "01.11.2074"
    },
    {
        "engDate": "2018-02-14",
        "nepDate": "02.11.2074"
    },
    {
        "engDate": "2018-02-15",
        "nepDate": "03.11.2074"
    },
    {
        "engDate": "2018-02-16",
        "nepDate": "04.11.2074"
    },
    {
        "engDate": "2018-02-17",
        "nepDate": "05.11.2074"
    },
    {
        "engDate": "2018-02-18",
        "nepDate": "06.11.2074"
    },
    {
        "engDate": "2018-02-19",
        "nepDate": "07.11.2074"
    },
    {
        "engDate": "2018-02-20",
        "nepDate": "08.11.2074"
    },
    {
        "engDate": "2018-02-21",
        "nepDate": "09.11.2074"
    },
    {
        "engDate": "2018-02-22",
        "nepDate": "10.11.2074"
    },
    {
        "engDate": "2018-02-23",
        "nepDate": "11.11.2074"
    },
    {
        "engDate": "2018-02-24",
        "nepDate": "12.11.2074"
    },
    {
        "engDate": "2018-02-25",
        "nepDate": "13.11.2074"
    },
    {
        "engDate": "2018-02-26",
        "nepDate": "14.11.2074"
    },
    {
        "engDate": "2018-02-27",
        "nepDate": "15.11.2074"
    },
    {
        "engDate": "2018-02-28",
        "nepDate": "16.11.2074"
    },
    {
        "engDate": "2018-03-01",
        "nepDate": "17.11.2074"
    },
    {
        "engDate": "2018-03-02",
        "nepDate": "18.11.2074"
    },
    {
        "engDate": "2018-03-03",
        "nepDate": "19.11.2074"
    },
    {
        "engDate": "2018-03-04",
        "nepDate": "20.11.2074"
    },
    {
        "engDate": "2018-03-05",
        "nepDate": "21.11.2074"
    },
    {
        "engDate": "2018-03-06",
        "nepDate": "22.11.2074"
    },
    {
        "engDate": "2018-03-07",
        "nepDate": "23.11.2074"
    },
    {
        "engDate": "2018-03-08",
        "nepDate": "24.11.2074"
    },
    {
        "engDate": "2018-03-09",
        "nepDate": "25.11.2074"
    },
    {
        "engDate": "2018-03-10",
        "nepDate": "26.11.2074"
    },
    {
        "engDate": "2018-03-11",
        "nepDate": "27.11.2074"
    },
    {
        "engDate": "2018-03-12",
        "nepDate": "28.11.2074"
    },
    {
        "engDate": "2018-03-13",
        "nepDate": "29.11.2074"
    },
    {
        "engDate": "2018-03-14",
        "nepDate": "30.11.2074"
    },
    {
        "engDate": "2018-03-15",
        "nepDate": "01.12.2074"
    },
    {
        "engDate": "2018-03-16",
        "nepDate": "02.12.2074"
    },
    {
        "engDate": "2018-03-17",
        "nepDate": "03.12.2074"
    },
    {
        "engDate": "2018-03-18",
        "nepDate": "04.12.2074"
    },
    {
        "engDate": "2018-03-19",
        "nepDate": "05.12.2074"
    },
    {
        "engDate": "2018-03-20",
        "nepDate": "06.12.2074"
    },
    {
        "engDate": "2018-03-21",
        "nepDate": "07.12.2074"
    },
    {
        "engDate": "2018-03-22",
        "nepDate": "08.12.2074"
    },
    {
        "engDate": "2018-03-23",
        "nepDate": "09.12.2074"
    },
    {
        "engDate": "2018-03-24",
        "nepDate": "10.12.2074"
    },
    {
        "engDate": "2018-03-25",
        "nepDate": "11.12.2074"
    },
    {
        "engDate": "2018-03-26",
        "nepDate": "12.12.2074"
    },
    {
        "engDate": "2018-03-27",
        "nepDate": "13.12.2074"
    },
    {
        "engDate": "2018-03-28",
        "nepDate": "14.12.2074"
    },
    {
        "engDate": "2018-03-29",
        "nepDate": "15.12.2074"
    },
    {
        "engDate": "2018-03-30",
        "nepDate": "16.12.2074"
    },
    {
        "engDate": "2018-03-31",
        "nepDate": "17.12.2074"
    },
    {
        "engDate": "2018-04-01",
        "nepDate": "18.12.2074"
    },
    {
        "engDate": "2018-04-02",
        "nepDate": "19.12.2074"
    },
    {
        "engDate": "2018-04-03",
        "nepDate": "20.12.2074"
    },
    {
        "engDate": "2018-04-04",
        "nepDate": "21.12.2074"
    },
    {
        "engDate": "2018-04-05",
        "nepDate": "22.12.2074"
    },
    {
        "engDate": "2018-04-06",
        "nepDate": "23.12.2074"
    },
    {
        "engDate": "2018-04-07",
        "nepDate": "24.12.2074"
    },
    {
        "engDate": "2018-04-08",
        "nepDate": "25.12.2074"
    },
    {
        "engDate": "2018-04-09",
        "nepDate": "26.12.2074"
    },
    {
        "engDate": "2018-04-10",
        "nepDate": "27.12.2074"
    },
    {
        "engDate": "2018-04-11",
        "nepDate": "28.12.2074"
    },
    {
        "engDate": "2018-04-12",
        "nepDate": "29.12.2074"
    },
    {
        "engDate": "2018-04-13",
        "nepDate": "30.12.2074"
    },
    {
        "engDate": "2018-04-14",
        "nepDate": "01.01.2075"
    },
    {
        "engDate": "2018-04-15",
        "nepDate": "02.01.2075"
    },
    {
        "engDate": "2018-04-16",
        "nepDate": "03.01.2075"
    },
    {
        "engDate": "2018-04-17",
        "nepDate": "04.01.2075"
    },
    {
        "engDate": "2018-04-18",
        "nepDate": "05.01.2075"
    },
    {
        "engDate": "2018-04-19",
        "nepDate": "06.01.2075"
    },
    {
        "engDate": "2018-04-20",
        "nepDate": "07.01.2075"
    },
    {
        "engDate": "2018-04-21",
        "nepDate": "08.01.2075"
    },
    {
        "engDate": "2018-04-22",
        "nepDate": "09.01.2075"
    },
    {
        "engDate": "2018-04-23",
        "nepDate": "10.01.2075"
    },
    {
        "engDate": "2018-04-24",
        "nepDate": "11.01.2075"
    },
    {
        "engDate": "2018-04-25",
        "nepDate": "12.01.2075"
    },
    {
        "engDate": "2018-04-26",
        "nepDate": "13.01.2075"
    },
    {
        "engDate": "2018-04-27",
        "nepDate": "14.01.2075"
    },
    {
        "engDate": "2018-04-28",
        "nepDate": "15.01.2075"
    },
    {
        "engDate": "2018-04-29",
        "nepDate": "16.01.2075"
    },
    {
        "engDate": "2018-04-30",
        "nepDate": "17.01.2075"
    },
    {
        "engDate": "2018-05-01",
        "nepDate": "18.01.2075"
    },
    {
        "engDate": "2018-05-02",
        "nepDate": "19.01.2075"
    },
    {
        "engDate": "2018-05-03",
        "nepDate": "20.01.2075"
    },
    {
        "engDate": "2018-05-04",
        "nepDate": "21.01.2075"
    },
    {
        "engDate": "2018-05-05",
        "nepDate": "22.01.2075"
    },
    {
        "engDate": "2018-05-06",
        "nepDate": "23.01.2075"
    },
    {
        "engDate": "2018-05-07",
        "nepDate": "24.01.2075"
    },
    {
        "engDate": "2018-05-08",
        "nepDate": "25.01.2075"
    },
    {
        "engDate": "2018-05-09",
        "nepDate": "26.01.2075"
    },
    {
        "engDate": "2018-05-10",
        "nepDate": "27.01.2075"
    },
    {
        "engDate": "2018-05-11",
        "nepDate": "28.01.2075"
    },
    {
        "engDate": "2018-05-12",
        "nepDate": "29.01.2075"
    },
    {
        "engDate": "2018-05-13",
        "nepDate": "30.01.2075"
    },
    {
        "engDate": "2018-05-14",
        "nepDate": "31.01.2075"
    },
    {
        "engDate": "2018-05-15",
        "nepDate": "01.02.2075"
    },
    {
        "engDate": "2018-05-16",
        "nepDate": "02.02.2075"
    },
    {
        "engDate": "2018-05-17",
        "nepDate": "03.02.2075"
    },
    {
        "engDate": "2018-05-18",
        "nepDate": "04.02.2075"
    },
    {
        "engDate": "2018-05-19",
        "nepDate": "05.02.2075"
    },
    {
        "engDate": "2018-05-20",
        "nepDate": "06.02.2075"
    },
    {
        "engDate": "2018-05-21",
        "nepDate": "07.02.2075"
    },
    {
        "engDate": "2018-05-22",
        "nepDate": "08.02.2075"
    },
    {
        "engDate": "2018-05-23",
        "nepDate": "09.02.2075"
    },
    {
        "engDate": "2018-05-24",
        "nepDate": "10.02.2075"
    },
    {
        "engDate": "2018-05-25",
        "nepDate": "11.02.2075"
    },
    {
        "engDate": "2018-05-26",
        "nepDate": "12.02.2075"
    },
    {
        "engDate": "2018-05-27",
        "nepDate": "13.02.2075"
    },
    {
        "engDate": "2018-05-28",
        "nepDate": "14.02.2075"
    },
    {
        "engDate": "2018-05-29",
        "nepDate": "15.02.2075"
    },
    {
        "engDate": "2018-05-30",
        "nepDate": "16.02.2075"
    },
    {
        "engDate": "2018-05-31",
        "nepDate": "17.02.2075"
    },
    {
        "engDate": "2018-06-01",
        "nepDate": "18.02.2075"
    },
    {
        "engDate": "2018-06-02",
        "nepDate": "19.02.2075"
    },
    {
        "engDate": "2018-06-03",
        "nepDate": "20.02.2075"
    },
    {
        "engDate": "2018-06-04",
        "nepDate": "21.02.2075"
    },
    {
        "engDate": "2018-06-05",
        "nepDate": "22.02.2075"
    },
    {
        "engDate": "2018-06-06",
        "nepDate": "23.02.2075"
    },
    {
        "engDate": "2018-06-07",
        "nepDate": "24.02.2075"
    },
    {
        "engDate": "2018-06-08",
        "nepDate": "25.02.2075"
    },
    {
        "engDate": "2018-06-09",
        "nepDate": "26.02.2075"
    },
    {
        "engDate": "2018-06-10",
        "nepDate": "27.02.2075"
    },
    {
        "engDate": "2018-06-11",
        "nepDate": "28.02.2075"
    },
    {
        "engDate": "2018-06-12",
        "nepDate": "29.02.2075"
    },
    {
        "engDate": "2018-06-13",
        "nepDate": "30.02.2075"
    },
    {
        "engDate": "2018-06-14",
        "nepDate": "31.02.2075"
    },
    {
        "engDate": "2018-06-15",
        "nepDate": "01.03.2075"
    },
    {
        "engDate": "2018-06-16",
        "nepDate": "02.03.2075"
    },
    {
        "engDate": "2018-06-17",
        "nepDate": "03.03.2075"
    },
    {
        "engDate": "2018-06-18",
        "nepDate": "04.03.2075"
    },
    {
        "engDate": "2018-06-19",
        "nepDate": "05.03.2075"
    },
    {
        "engDate": "2018-06-20",
        "nepDate": "06.03.2075"
    },
    {
        "engDate": "2018-06-21",
        "nepDate": "07.03.2075"
    },
    {
        "engDate": "2018-06-22",
        "nepDate": "08.03.2075"
    },
    {
        "engDate": "2018-06-23",
        "nepDate": "09.03.2075"
    },
    {
        "engDate": "2018-06-24",
        "nepDate": "10.03.2075"
    },
    {
        "engDate": "2018-06-25",
        "nepDate": "11.03.2075"
    },
    {
        "engDate": "2018-06-26",
        "nepDate": "12.03.2075"
    },
    {
        "engDate": "2018-06-27",
        "nepDate": "13.03.2075"
    },
    {
        "engDate": "2018-06-28",
        "nepDate": "14.03.2075"
    },
    {
        "engDate": "2018-06-29",
        "nepDate": "15.03.2075"
    },
    {
        "engDate": "2018-06-30",
        "nepDate": "16.03.2075"
    },
    {
        "engDate": "2018-07-01",
        "nepDate": "17.03.2075"
    },
    {
        "engDate": "2018-07-02",
        "nepDate": "18.03.2075"
    },
    {
        "engDate": "2018-07-03",
        "nepDate": "19.03.2075"
    },
    {
        "engDate": "2018-07-04",
        "nepDate": "20.03.2075"
    },
    {
        "engDate": "2018-07-05",
        "nepDate": "21.03.2075"
    },
    {
        "engDate": "2018-07-06",
        "nepDate": "22.03.2075"
    },
    {
        "engDate": "2018-07-07",
        "nepDate": "23.03.2075"
    },
    {
        "engDate": "2018-07-08",
        "nepDate": "24.03.2075"
    },
    {
        "engDate": "2018-07-09",
        "nepDate": "25.03.2075"
    },
    {
        "engDate": "2018-07-10",
        "nepDate": "26.03.2075"
    },
    {
        "engDate": "2018-07-11",
        "nepDate": "27.03.2075"
    },
    {
        "engDate": "2018-07-12",
        "nepDate": "28.03.2075"
    },
    {
        "engDate": "2018-07-13",
        "nepDate": "29.03.2075"
    },
    {
        "engDate": "2018-07-14",
        "nepDate": "30.03.2075"
    },
    {
        "engDate": "2018-07-15",
        "nepDate": "31.03.2075"
    },
    {
        "engDate": "2018-07-16",
        "nepDate": "32.03.2075"
    },
    {
        "engDate": "2018-07-17",
        "nepDate": "01.04.2075"
    },
    {
        "engDate": "2018-07-18",
        "nepDate": "02.04.2075"
    },
    {
        "engDate": "2018-07-19",
        "nepDate": "03.04.2075"
    },
    {
        "engDate": "2018-07-20",
        "nepDate": "04.04.2075"
    },
    {
        "engDate": "2018-07-21",
        "nepDate": "05.04.2075"
    },
    {
        "engDate": "2018-07-22",
        "nepDate": "06.04.2075"
    },
    {
        "engDate": "2018-07-23",
        "nepDate": "07.04.2075"
    },
    {
        "engDate": "2018-07-24",
        "nepDate": "08.04.2075"
    },
    {
        "engDate": "2018-07-25",
        "nepDate": "09.04.2075"
    },
    {
        "engDate": "2018-07-26",
        "nepDate": "10.04.2075"
    },
    {
        "engDate": "2018-07-27",
        "nepDate": "11.04.2075"
    },
    {
        "engDate": "2018-07-28",
        "nepDate": "12.04.2075"
    },
    {
        "engDate": "2018-07-29",
        "nepDate": "13.04.2075"
    },
    {
        "engDate": "2018-07-30",
        "nepDate": "14.04.2075"
    },
    {
        "engDate": "2018-07-31",
        "nepDate": "15.04.2075"
    },
    {
        "engDate": "2018-08-01",
        "nepDate": "16.04.2075"
    },
    {
        "engDate": "2018-08-02",
        "nepDate": "17.04.2075"
    },
    {
        "engDate": "2018-08-03",
        "nepDate": "18.04.2075"
    },
    {
        "engDate": "2018-08-04",
        "nepDate": "19.04.2075"
    },
    {
        "engDate": "2018-08-05",
        "nepDate": "20.04.2075"
    },
    {
        "engDate": "2018-08-06",
        "nepDate": "21.04.2075"
    },
    {
        "engDate": "2018-08-07",
        "nepDate": "22.04.2075"
    },
    {
        "engDate": "2018-08-08",
        "nepDate": "23.04.2075"
    },
    {
        "engDate": "2018-08-09",
        "nepDate": "24.04.2075"
    },
    {
        "engDate": "2018-08-10",
        "nepDate": "25.04.2075"
    },
    {
        "engDate": "2018-08-11",
        "nepDate": "26.04.2075"
    },
    {
        "engDate": "2018-08-12",
        "nepDate": "27.04.2075"
    },
    {
        "engDate": "2018-08-13",
        "nepDate": "28.04.2075"
    },
    {
        "engDate": "2018-08-14",
        "nepDate": "29.04.2075"
    },
    {
        "engDate": "2018-08-15",
        "nepDate": "30.04.2075"
    },
    {
        "engDate": "2018-08-16",
        "nepDate": "31.04.2075"
    },
    {
        "engDate": "2018-08-17",
        "nepDate": "01.05.2075"
    },
    {
        "engDate": "2018-08-18",
        "nepDate": "02.05.2075"
    },
    {
        "engDate": "2018-08-19",
        "nepDate": "03.05.2075"
    },
    {
        "engDate": "2018-08-20",
        "nepDate": "04.05.2075"
    },
    {
        "engDate": "2018-08-21",
        "nepDate": "05.05.2075"
    },
    {
        "engDate": "2018-08-22",
        "nepDate": "06.05.2075"
    },
    {
        "engDate": "2018-08-23",
        "nepDate": "07.05.2075"
    },
    {
        "engDate": "2018-08-24",
        "nepDate": "08.05.2075"
    },
    {
        "engDate": "2018-08-25",
        "nepDate": "09.05.2075"
    },
    {
        "engDate": "2018-08-26",
        "nepDate": "10.05.2075"
    },
    {
        "engDate": "2018-08-27",
        "nepDate": "11.05.2075"
    },
    {
        "engDate": "2018-08-28",
        "nepDate": "12.05.2075"
    },
    {
        "engDate": "2018-08-29",
        "nepDate": "13.05.2075"
    },
    {
        "engDate": "2018-08-30",
        "nepDate": "14.05.2075"
    },
    {
        "engDate": "2018-08-31",
        "nepDate": "15.05.2075"
    },
    {
        "engDate": "2018-09-01",
        "nepDate": "16.05.2075"
    },
    {
        "engDate": "2018-09-02",
        "nepDate": "17.05.2075"
    },
    {
        "engDate": "2018-09-03",
        "nepDate": "18.05.2075"
    },
    {
        "engDate": "2018-09-04",
        "nepDate": "19.05.2075"
    },
    {
        "engDate": "2018-09-05",
        "nepDate": "20.05.2075"
    },
    {
        "engDate": "2018-09-06",
        "nepDate": "21.05.2075"
    },
    {
        "engDate": "2018-09-07",
        "nepDate": "22.05.2075"
    },
    {
        "engDate": "2018-09-08",
        "nepDate": "23.05.2075"
    },
    {
        "engDate": "2018-09-09",
        "nepDate": "24.05.2075"
    },
    {
        "engDate": "2018-09-10",
        "nepDate": "25.05.2075"
    },
    {
        "engDate": "2018-09-11",
        "nepDate": "26.05.2075"
    },
    {
        "engDate": "2018-09-12",
        "nepDate": "27.05.2075"
    },
    {
        "engDate": "2018-09-13",
        "nepDate": "28.05.2075"
    },
    {
        "engDate": "2018-09-14",
        "nepDate": "29.05.2075"
    },
    {
        "engDate": "2018-09-15",
        "nepDate": "30.05.2075"
    },
    {
        "engDate": "2018-09-16",
        "nepDate": "31.05.2075"
    },
    {
        "engDate": "2018-09-17",
        "nepDate": "01.06.2075"
    },
    {
        "engDate": "2018-09-18",
        "nepDate": "02.06.2075"
    },
    {
        "engDate": "2018-09-19",
        "nepDate": "03.06.2075"
    },
    {
        "engDate": "2018-09-20",
        "nepDate": "04.06.2075"
    },
    {
        "engDate": "2018-09-21",
        "nepDate": "05.06.2075"
    },
    {
        "engDate": "2018-09-22",
        "nepDate": "06.06.2075"
    },
    {
        "engDate": "2018-09-23",
        "nepDate": "07.06.2075"
    },
    {
        "engDate": "2018-09-24",
        "nepDate": "08.06.2075"
    },
    {
        "engDate": "2018-09-25",
        "nepDate": "09.06.2075"
    },
    {
        "engDate": "2018-09-26",
        "nepDate": "10.06.2075"
    },
    {
        "engDate": "2018-09-27",
        "nepDate": "11.06.2075"
    },
    {
        "engDate": "2018-09-28",
        "nepDate": "12.06.2075"
    },
    {
        "engDate": "2018-09-29",
        "nepDate": "13.06.2075"
    },
    {
        "engDate": "2018-09-30",
        "nepDate": "14.06.2075"
    },
    {
        "engDate": "2018-10-01",
        "nepDate": "15.06.2075"
    },
    {
        "engDate": "2018-10-02",
        "nepDate": "16.06.2075"
    },
    {
        "engDate": "2018-10-03",
        "nepDate": "17.06.2075"
    },
    {
        "engDate": "2018-10-04",
        "nepDate": "18.06.2075"
    },
    {
        "engDate": "2018-10-05",
        "nepDate": "19.06.2075"
    },
    {
        "engDate": "2018-10-06",
        "nepDate": "20.06.2075"
    },
    {
        "engDate": "2018-10-07",
        "nepDate": "21.06.2075"
    },
    {
        "engDate": "2018-10-08",
        "nepDate": "22.06.2075"
    },
    {
        "engDate": "2018-10-09",
        "nepDate": "23.06.2075"
    },
    {
        "engDate": "2018-10-10",
        "nepDate": "24.06.2075"
    },
    {
        "engDate": "2018-10-11",
        "nepDate": "25.06.2075"
    },
    {
        "engDate": "2018-10-12",
        "nepDate": "26.06.2075"
    },
    {
        "engDate": "2018-10-13",
        "nepDate": "27.06.2075"
    },
    {
        "engDate": "2018-10-14",
        "nepDate": "28.06.2075"
    },
    {
        "engDate": "2018-10-15",
        "nepDate": "29.06.2075"
    },
    {
        "engDate": "2018-10-16",
        "nepDate": "30.06.2075"
    },
    {
        "engDate": "2018-10-17",
        "nepDate": "31.06.2075"
    },
    {
        "engDate": "2018-10-18",
        "nepDate": "01.07.2075"
    },
    {
        "engDate": "2018-10-19",
        "nepDate": "02.07.2075"
    },
    {
        "engDate": "2018-10-20",
        "nepDate": "03.07.2075"
    },
    {
        "engDate": "2018-10-21",
        "nepDate": "04.07.2075"
    },
    {
        "engDate": "2018-10-22",
        "nepDate": "05.07.2075"
    },
    {
        "engDate": "2018-10-23",
        "nepDate": "06.07.2075"
    },
    {
        "engDate": "2018-10-24",
        "nepDate": "07.07.2075"
    },
    {
        "engDate": "2018-10-25",
        "nepDate": "08.07.2075"
    },
    {
        "engDate": "2018-10-26",
        "nepDate": "09.07.2075"
    },
    {
        "engDate": "2018-10-27",
        "nepDate": "10.07.2075"
    },
    {
        "engDate": "2018-10-28",
        "nepDate": "11.07.2075"
    },
    {
        "engDate": "2018-10-29",
        "nepDate": "12.07.2075"
    },
    {
        "engDate": "2018-10-30",
        "nepDate": "13.07.2075"
    },
    {
        "engDate": "2018-10-31",
        "nepDate": "14.07.2075"
    },
    {
        "engDate": "2018-11-01",
        "nepDate": "15.07.2075"
    },
    {
        "engDate": "2018-11-02",
        "nepDate": "16.07.2075"
    },
    {
        "engDate": "2018-11-03",
        "nepDate": "17.07.2075"
    },
    {
        "engDate": "2018-11-04",
        "nepDate": "18.07.2075"
    },
    {
        "engDate": "2018-11-05",
        "nepDate": "19.07.2075"
    },
    {
        "engDate": "2018-11-06",
        "nepDate": "20.07.2075"
    },
    {
        "engDate": "2018-11-07",
        "nepDate": "21.07.2075"
    },
    {
        "engDate": "2018-11-08",
        "nepDate": "22.07.2075"
    },
    {
        "engDate": "2018-11-09",
        "nepDate": "23.07.2075"
    },
    {
        "engDate": "2018-11-10",
        "nepDate": "24.07.2075"
    },
    {
        "engDate": "2018-11-11",
        "nepDate": "25.07.2075"
    },
    {
        "engDate": "2018-11-12",
        "nepDate": "26.07.2075"
    },
    {
        "engDate": "2018-11-13",
        "nepDate": "27.07.2075"
    },
    {
        "engDate": "2018-11-14",
        "nepDate": "28.07.2075"
    },
    {
        "engDate": "2018-11-15",
        "nepDate": "29.07.2075"
    },
    {
        "engDate": "2018-11-16",
        "nepDate": "30.07.2075"
    },
    {
        "engDate": "2018-11-17",
        "nepDate": "01.08.2075"
    },
    {
        "engDate": "2018-11-18",
        "nepDate": "02.08.2075"
    },
    {
        "engDate": "2018-11-19",
        "nepDate": "03.08.2075"
    },
    {
        "engDate": "2018-11-20",
        "nepDate": "04.08.2075"
    },
    {
        "engDate": "2018-11-21",
        "nepDate": "05.08.2075"
    },
    {
        "engDate": "2018-11-22",
        "nepDate": "06.08.2075"
    },
    {
        "engDate": "2018-11-23",
        "nepDate": "07.08.2075"
    },
    {
        "engDate": "2018-11-24",
        "nepDate": "08.08.2075"
    },
    {
        "engDate": "2018-11-25",
        "nepDate": "09.08.2075"
    },
    {
        "engDate": "2018-11-26",
        "nepDate": "10.08.2075"
    },
    {
        "engDate": "2018-11-27",
        "nepDate": "11.08.2075"
    },
    {
        "engDate": "2018-11-28",
        "nepDate": "12.08.2075"
    },
    {
        "engDate": "2018-11-29",
        "nepDate": "13.08.2075"
    },
    {
        "engDate": "2018-11-30",
        "nepDate": "14.08.2075"
    },
    {
        "engDate": "2018-12-01",
        "nepDate": "15.08.2075"
    },
    {
        "engDate": "2018-12-02",
        "nepDate": "16.08.2075"
    },
    {
        "engDate": "2018-12-03",
        "nepDate": "17.08.2075"
    },
    {
        "engDate": "2018-12-04",
        "nepDate": "18.08.2075"
    },
    {
        "engDate": "2018-12-05",
        "nepDate": "19.08.2075"
    },
    {
        "engDate": "2018-12-06",
        "nepDate": "20.08.2075"
    },
    {
        "engDate": "2018-12-07",
        "nepDate": "21.08.2075"
    },
    {
        "engDate": "2018-12-08",
        "nepDate": "22.08.2075"
    },
    {
        "engDate": "2018-12-09",
        "nepDate": "23.08.2075"
    },
    {
        "engDate": "2018-12-10",
        "nepDate": "24.08.2075"
    },
    {
        "engDate": "2018-12-11",
        "nepDate": "25.08.2075"
    },
    {
        "engDate": "2018-12-12",
        "nepDate": "26.08.2075"
    },
    {
        "engDate": "2018-12-13",
        "nepDate": "27.08.2075"
    },
    {
        "engDate": "2018-12-14",
        "nepDate": "28.08.2075"
    },
    {
        "engDate": "2018-12-15",
        "nepDate": "29.08.2075"
    },
    {
        "engDate": "2018-12-16",
        "nepDate": "01.09.2075"
    },
    {
        "engDate": "2018-12-17",
        "nepDate": "02.09.2075"
    },
    {
        "engDate": "2018-12-18",
        "nepDate": "03.09.2075"
    },
    {
        "engDate": "2018-12-19",
        "nepDate": "04.09.2075"
    },
    {
        "engDate": "2018-12-20",
        "nepDate": "05.09.2075"
    },
    {
        "engDate": "2018-12-21",
        "nepDate": "06.09.2075"
    },
    {
        "engDate": "2018-12-22",
        "nepDate": "07.09.2075"
    },
    {
        "engDate": "2018-12-23",
        "nepDate": "08.09.2075"
    },
    {
        "engDate": "2018-12-24",
        "nepDate": "09.09.2075"
    },
    {
        "engDate": "2018-12-25",
        "nepDate": "10.09.2075"
    },
    {
        "engDate": "2018-12-26",
        "nepDate": "11.09.2075"
    },
    {
        "engDate": "2018-12-27",
        "nepDate": "12.09.2075"
    },
    {
        "engDate": "2018-12-28",
        "nepDate": "13.09.2075"
    },
    {
        "engDate": "2018-12-29",
        "nepDate": "14.09.2075"
    },
    {
        "engDate": "2018-12-30",
        "nepDate": "15.09.2075"
    },
    {
        "engDate": "2018-12-31",
        "nepDate": "16.09.2075"
    },
    {
        "engDate": "2019-01-01",
        "nepDate": "17.09.2075"
    },
    {
        "engDate": "2019-01-02",
        "nepDate": "18.09.2075"
    },
    {
        "engDate": "2019-01-03",
        "nepDate": "19.09.2075"
    },
    {
        "engDate": "2019-01-04",
        "nepDate": "20.09.2075"
    },
    {
        "engDate": "2019-01-05",
        "nepDate": "21.09.2075"
    },
    {
        "engDate": "2019-01-06",
        "nepDate": "22.09.2075"
    },
    {
        "engDate": "2019-01-07",
        "nepDate": "23.09.2075"
    },
    {
        "engDate": "2019-01-08",
        "nepDate": "24.09.2075"
    },
    {
        "engDate": "2019-01-09",
        "nepDate": "25.09.2075"
    },
    {
        "engDate": "2019-01-10",
        "nepDate": "26.09.2075"
    },
    {
        "engDate": "2019-01-11",
        "nepDate": "27.09.2075"
    },
    {
        "engDate": "2019-01-12",
        "nepDate": "28.09.2075"
    },
    {
        "engDate": "2019-01-13",
        "nepDate": "29.09.2075"
    },
    {
        "engDate": "2019-01-14",
        "nepDate": "30.09.2075"
    },
    {
        "engDate": "2019-01-15",
        "nepDate": "01.10.2075"
    },
    {
        "engDate": "2019-01-16",
        "nepDate": "02.10.2075"
    },
    {
        "engDate": "2019-01-17",
        "nepDate": "03.10.2075"
    },
    {
        "engDate": "2019-01-18",
        "nepDate": "04.10.2075"
    },
    {
        "engDate": "2019-01-19",
        "nepDate": "05.10.2075"
    },
    {
        "engDate": "2019-01-20",
        "nepDate": "06.10.2075"
    },
    {
        "engDate": "2019-01-21",
        "nepDate": "07.10.2075"
    },
    {
        "engDate": "2019-01-22",
        "nepDate": "08.10.2075"
    },
    {
        "engDate": "2019-01-23",
        "nepDate": "09.10.2075"
    },
    {
        "engDate": "2019-01-24",
        "nepDate": "10.10.2075"
    },
    {
        "engDate": "2019-01-25",
        "nepDate": "11.10.2075"
    },
    {
        "engDate": "2019-01-26",
        "nepDate": "12.10.2075"
    },
    {
        "engDate": "2019-01-27",
        "nepDate": "13.10.2075"
    },
    {
        "engDate": "2019-01-28",
        "nepDate": "14.10.2075"
    },
    {
        "engDate": "2019-01-29",
        "nepDate": "15.10.2075"
    },
    {
        "engDate": "2019-01-30",
        "nepDate": "16.10.2075"
    },
    {
        "engDate": "2019-01-31",
        "nepDate": "17.10.2075"
    },
    {
        "engDate": "2019-02-01",
        "nepDate": "18.10.2075"
    },
    {
        "engDate": "2019-02-02",
        "nepDate": "19.10.2075"
    },
    {
        "engDate": "2019-02-03",
        "nepDate": "20.10.2075"
    },
    {
        "engDate": "2019-02-04",
        "nepDate": "21.10.2075"
    },
    {
        "engDate": "2019-02-05",
        "nepDate": "22.10.2075"
    },
    {
        "engDate": "2019-02-06",
        "nepDate": "23.10.2075"
    },
    {
        "engDate": "2019-02-07",
        "nepDate": "24.10.2075"
    },
    {
        "engDate": "2019-02-08",
        "nepDate": "25.10.2075"
    },
    {
        "engDate": "2019-02-09",
        "nepDate": "26.10.2075"
    },
    {
        "engDate": "2019-02-10",
        "nepDate": "27.10.2075"
    },
    {
        "engDate": "2019-02-11",
        "nepDate": "28.10.2075"
    },
    {
        "engDate": "2019-02-12",
        "nepDate": "29.10.2075"
    },
    {
        "engDate": "2019-02-13",
        "nepDate": "01.11.2075"
    },
    {
        "engDate": "2019-02-14",
        "nepDate": "02.11.2075"
    },
    {
        "engDate": "2019-02-15",
        "nepDate": "03.11.2075"
    },
    {
        "engDate": "2019-02-16",
        "nepDate": "04.11.2075"
    },
    {
        "engDate": "2019-02-17",
        "nepDate": "05.11.2075"
    },
    {
        "engDate": "2019-02-18",
        "nepDate": "06.11.2075"
    },
    {
        "engDate": "2019-02-19",
        "nepDate": "07.11.2075"
    },
    {
        "engDate": "2019-02-20",
        "nepDate": "08.11.2075"
    },
    {
        "engDate": "2019-02-21",
        "nepDate": "09.11.2075"
    },
    {
        "engDate": "2019-02-22",
        "nepDate": "10.11.2075"
    },
    {
        "engDate": "2019-02-23",
        "nepDate": "11.11.2075"
    },
    {
        "engDate": "2019-02-24",
        "nepDate": "12.11.2075"
    },
    {
        "engDate": "2019-02-25",
        "nepDate": "13.11.2075"
    },
    {
        "engDate": "2019-02-26",
        "nepDate": "14.11.2075"
    },
    {
        "engDate": "2019-02-27",
        "nepDate": "15.11.2075"
    },
    {
        "engDate": "2019-02-28",
        "nepDate": "16.11.2075"
    },
    {
        "engDate": "2019-03-01",
        "nepDate": "17.11.2075"
    },
    {
        "engDate": "2019-03-02",
        "nepDate": "18.11.2075"
    },
    {
        "engDate": "2019-03-03",
        "nepDate": "19.11.2075"
    },
    {
        "engDate": "2019-03-04",
        "nepDate": "20.11.2075"
    },
    {
        "engDate": "2019-03-05",
        "nepDate": "21.11.2075"
    },
    {
        "engDate": "2019-03-06",
        "nepDate": "22.11.2075"
    },
    {
        "engDate": "2019-03-07",
        "nepDate": "23.11.2075"
    },
    {
        "engDate": "2019-03-08",
        "nepDate": "24.11.2075"
    },
    {
        "engDate": "2019-03-09",
        "nepDate": "25.11.2075"
    },
    {
        "engDate": "2019-03-10",
        "nepDate": "26.11.2075"
    },
    {
        "engDate": "2019-03-11",
        "nepDate": "27.11.2075"
    },
    {
        "engDate": "2019-03-12",
        "nepDate": "28.11.2075"
    },
    {
        "engDate": "2019-03-13",
        "nepDate": "29.11.2075"
    },
    {
        "engDate": "2019-03-14",
        "nepDate": "30.11.2075"
    },
    {
        "engDate": "2019-03-15",
        "nepDate": "01.12.2075"
    },
    {
        "engDate": "2019-03-16",
        "nepDate": "02.12.2075"
    },
    {
        "engDate": "2019-03-17",
        "nepDate": "03.12.2075"
    },
    {
        "engDate": "2019-03-18",
        "nepDate": "04.12.2075"
    },
    {
        "engDate": "2019-03-19",
        "nepDate": "05.12.2075"
    },
    {
        "engDate": "2019-03-20",
        "nepDate": "06.12.2075"
    },
    {
        "engDate": "2019-03-21",
        "nepDate": "07.12.2075"
    },
    {
        "engDate": "2019-03-22",
        "nepDate": "08.12.2075"
    },
    {
        "engDate": "2019-03-23",
        "nepDate": "09.12.2075"
    },
    {
        "engDate": "2019-03-24",
        "nepDate": "10.12.2075"
    },
    {
        "engDate": "2019-03-25",
        "nepDate": "11.12.2075"
    },
    {
        "engDate": "2019-03-26",
        "nepDate": "12.12.2075"
    },
    {
        "engDate": "2019-03-27",
        "nepDate": "13.12.2075"
    },
    {
        "engDate": "2019-03-28",
        "nepDate": "14.12.2075"
    },
    {
        "engDate": "2019-03-29",
        "nepDate": "15.12.2075"
    },
    {
        "engDate": "2019-03-30",
        "nepDate": "16.12.2075"
    },
    {
        "engDate": "2019-03-31",
        "nepDate": "17.12.2075"
    },
    {
        "engDate": "2019-04-01",
        "nepDate": "18.12.2075"
    },
    {
        "engDate": "2019-04-02",
        "nepDate": "19.12.2075"
    },
    {
        "engDate": "2019-04-03",
        "nepDate": "20.12.2075"
    },
    {
        "engDate": "2019-04-04",
        "nepDate": "21.12.2075"
    },
    {
        "engDate": "2019-04-05",
        "nepDate": "22.12.2075"
    },
    {
        "engDate": "2019-04-06",
        "nepDate": "23.12.2075"
    },
    {
        "engDate": "2019-04-07",
        "nepDate": "24.12.2075"
    },
    {
        "engDate": "2019-04-08",
        "nepDate": "25.12.2075"
    },
    {
        "engDate": "2019-04-09",
        "nepDate": "26.12.2075"
    },
    {
        "engDate": "2019-04-10",
        "nepDate": "27.12.2075"
    },
    {
        "engDate": "2019-04-11",
        "nepDate": "28.12.2075"
    },
    {
        "engDate": "2019-04-12",
        "nepDate": "29.12.2075"
    },
    {
        "engDate": "2019-04-13",
        "nepDate": "30.12.2075"
    },
    {
        "engDate": "2019-04-14",
        "nepDate": "01.01.2076"
    },
    {
        "engDate": "2019-04-15",
        "nepDate": "02.01.2076"
    },
    {
        "engDate": "2019-04-16",
        "nepDate": "03.01.2076"
    },
    {
        "engDate": "2019-04-17",
        "nepDate": "04.01.2076"
    },
    {
        "engDate": "2019-04-18",
        "nepDate": "05.01.2076"
    },
    {
        "engDate": "2019-04-19",
        "nepDate": "06.01.2076"
    },
    {
        "engDate": "2019-04-20",
        "nepDate": "07.01.2076"
    },
    {
        "engDate": "2019-04-21",
        "nepDate": "08.01.2076"
    },
    {
        "engDate": "2019-04-22",
        "nepDate": "09.01.2076"
    },
    {
        "engDate": "2019-04-23",
        "nepDate": "10.01.2076"
    },
    {
        "engDate": "2019-04-24",
        "nepDate": "11.01.2076"
    },
    {
        "engDate": "2019-04-25",
        "nepDate": "12.01.2076"
    },
    {
        "engDate": "2019-04-26",
        "nepDate": "13.01.2076"
    },
    {
        "engDate": "2019-04-27",
        "nepDate": "14.01.2076"
    },
    {
        "engDate": "2019-04-28",
        "nepDate": "15.01.2076"
    },
    {
        "engDate": "2019-04-29",
        "nepDate": "16.01.2076"
    },
    {
        "engDate": "2019-04-30",
        "nepDate": "17.01.2076"
    },
    {
        "engDate": "2019-05-01",
        "nepDate": "18.01.2076"
    },
    {
        "engDate": "2019-05-02",
        "nepDate": "19.01.2076"
    },
    {
        "engDate": "2019-05-03",
        "nepDate": "20.01.2076"
    },
    {
        "engDate": "2019-05-04",
        "nepDate": "21.01.2076"
    },
    {
        "engDate": "2019-05-05",
        "nepDate": "22.01.2076"
    },
    {
        "engDate": "2019-05-06",
        "nepDate": "23.01.2076"
    },
    {
        "engDate": "2019-05-07",
        "nepDate": "24.01.2076"
    },
    {
        "engDate": "2019-05-08",
        "nepDate": "25.01.2076"
    },
    {
        "engDate": "2019-05-09",
        "nepDate": "26.01.2076"
    },
    {
        "engDate": "2019-05-10",
        "nepDate": "27.01.2076"
    },
    {
        "engDate": "2019-05-11",
        "nepDate": "28.01.2076"
    },
    {
        "engDate": "2019-05-12",
        "nepDate": "29.01.2076"
    },
    {
        "engDate": "2019-05-13",
        "nepDate": "30.01.2076"
    },
    {
        "engDate": "2019-05-14",
        "nepDate": "31.01.2076"
    },
    {
        "engDate": "2019-05-15",
        "nepDate": "01.02.2076"
    },
    {
        "engDate": "2019-05-16",
        "nepDate": "02.02.2076"
    },
    {
        "engDate": "2019-05-17",
        "nepDate": "03.02.2076"
    },
    {
        "engDate": "2019-05-18",
        "nepDate": "04.02.2076"
    },
    {
        "engDate": "2019-05-19",
        "nepDate": "05.02.2076"
    },
    {
        "engDate": "2019-05-20",
        "nepDate": "06.02.2076"
    },
    {
        "engDate": "2019-05-21",
        "nepDate": "07.02.2076"
    },
    {
        "engDate": "2019-05-22",
        "nepDate": "08.02.2076"
    },
    {
        "engDate": "2019-05-23",
        "nepDate": "09.02.2076"
    },
    {
        "engDate": "2019-05-24",
        "nepDate": "10.02.2076"
    },
    {
        "engDate": "2019-05-25",
        "nepDate": "11.02.2076"
    },
    {
        "engDate": "2019-05-26",
        "nepDate": "12.02.2076"
    },
    {
        "engDate": "2019-05-27",
        "nepDate": "13.02.2076"
    },
    {
        "engDate": "2019-05-28",
        "nepDate": "14.02.2076"
    },
    {
        "engDate": "2019-05-29",
        "nepDate": "15.02.2076"
    },
    {
        "engDate": "2019-05-30",
        "nepDate": "16.02.2076"
    },
    {
        "engDate": "2019-05-31",
        "nepDate": "17.02.2076"
    },
    {
        "engDate": "2019-06-01",
        "nepDate": "18.02.2076"
    },
    {
        "engDate": "2019-06-02",
        "nepDate": "19.02.2076"
    },
    {
        "engDate": "2019-06-03",
        "nepDate": "20.02.2076"
    },
    {
        "engDate": "2019-06-04",
        "nepDate": "21.02.2076"
    },
    {
        "engDate": "2019-06-05",
        "nepDate": "22.02.2076"
    },
    {
        "engDate": "2019-06-06",
        "nepDate": "23.02.2076"
    },
    {
        "engDate": "2019-06-07",
        "nepDate": "24.02.2076"
    },
    {
        "engDate": "2019-06-08",
        "nepDate": "25.02.2076"
    },
    {
        "engDate": "2019-06-09",
        "nepDate": "26.02.2076"
    },
    {
        "engDate": "2019-06-10",
        "nepDate": "27.02.2076"
    },
    {
        "engDate": "2019-06-11",
        "nepDate": "28.02.2076"
    },
    {
        "engDate": "2019-06-12",
        "nepDate": "29.02.2076"
    },
    {
        "engDate": "2019-06-13",
        "nepDate": "30.02.2076"
    },
    {
        "engDate": "2019-06-14",
        "nepDate": "31.02.2076"
    },
    {
        "engDate": "2019-06-15",
        "nepDate": "32.02.2076"
    },
    {
        "engDate": "2019-06-16",
        "nepDate": "01.03.2076"
    },
    {
        "engDate": "2019-06-17",
        "nepDate": "02.03.2076"
    },
    {
        "engDate": "2019-06-18",
        "nepDate": "03.03.2076"
    },
    {
        "engDate": "2019-06-19",
        "nepDate": "04.03.2076"
    },
    {
        "engDate": "2019-06-20",
        "nepDate": "05.03.2076"
    },
    {
        "engDate": "2019-06-21",
        "nepDate": "06.03.2076"
    },
    {
        "engDate": "2019-06-22",
        "nepDate": "07.03.2076"
    },
    {
        "engDate": "2019-06-23",
        "nepDate": "08.03.2076"
    },
    {
        "engDate": "2019-06-24",
        "nepDate": "09.03.2076"
    },
    {
        "engDate": "2019-06-25",
        "nepDate": "10.03.2076"
    },
    {
        "engDate": "2019-06-26",
        "nepDate": "11.03.2076"
    },
    {
        "engDate": "2019-06-27",
        "nepDate": "12.03.2076"
    },
    {
        "engDate": "2019-06-28",
        "nepDate": "13.03.2076"
    },
    {
        "engDate": "2019-06-29",
        "nepDate": "14.03.2076"
    },
    {
        "engDate": "2019-06-30",
        "nepDate": "15.03.2076"
    },
    {
        "engDate": "2019-07-01",
        "nepDate": "16.03.2076"
    },
    {
        "engDate": "2019-07-02",
        "nepDate": "17.03.2076"
    },
    {
        "engDate": "2019-07-03",
        "nepDate": "18.03.2076"
    },
    {
        "engDate": "2019-07-04",
        "nepDate": "19.03.2076"
    },
    {
        "engDate": "2019-07-05",
        "nepDate": "20.03.2076"
    },
    {
        "engDate": "2019-07-06",
        "nepDate": "21.03.2076"
    },
    {
        "engDate": "2019-07-07",
        "nepDate": "22.03.2076"
    },
    {
        "engDate": "2019-07-08",
        "nepDate": "23.03.2076"
    },
    {
        "engDate": "2019-07-09",
        "nepDate": "24.03.2076"
    },
    {
        "engDate": "2019-07-10",
        "nepDate": "25.03.2076"
    },
    {
        "engDate": "2019-07-11",
        "nepDate": "26.03.2076"
    },
    {
        "engDate": "2019-07-12",
        "nepDate": "27.03.2076"
    },
    {
        "engDate": "2019-07-13",
        "nepDate": "28.03.2076"
    },
    {
        "engDate": "2019-07-14",
        "nepDate": "29.03.2076"
    },
    {
        "engDate": "2019-07-15",
        "nepDate": "30.03.2076"
    },
    {
        "engDate": "2019-07-16",
        "nepDate": "31.03.2076"
    },
    {
        "engDate": "2019-07-17",
        "nepDate": "01.04.2076"
    },
    {
        "engDate": "2019-07-18",
        "nepDate": "02.04.2076"
    },
    {
        "engDate": "2019-07-19",
        "nepDate": "03.04.2076"
    },
    {
        "engDate": "2019-07-20",
        "nepDate": "04.04.2076"
    },
    {
        "engDate": "2019-07-21",
        "nepDate": "05.04.2076"
    },
    {
        "engDate": "2019-07-22",
        "nepDate": "06.04.2076"
    },
    {
        "engDate": "2019-07-23",
        "nepDate": "07.04.2076"
    },
    {
        "engDate": "2019-07-24",
        "nepDate": "08.04.2076"
    },
    {
        "engDate": "2019-07-25",
        "nepDate": "09.04.2076"
    },
    {
        "engDate": "2019-07-26",
        "nepDate": "10.04.2076"
    },
    {
        "engDate": "2019-07-27",
        "nepDate": "11.04.2076"
    },
    {
        "engDate": "2019-07-28",
        "nepDate": "12.04.2076"
    },
    {
        "engDate": "2019-07-29",
        "nepDate": "13.04.2076"
    },
    {
        "engDate": "2019-07-30",
        "nepDate": "14.04.2076"
    },
    {
        "engDate": "2019-07-31",
        "nepDate": "15.04.2076"
    },
    {
        "engDate": "2019-08-01",
        "nepDate": "16.04.2076"
    },
    {
        "engDate": "2019-08-02",
        "nepDate": "17.04.2076"
    },
    {
        "engDate": "2019-08-03",
        "nepDate": "18.04.2076"
    },
    {
        "engDate": "2019-08-04",
        "nepDate": "19.04.2076"
    },
    {
        "engDate": "2019-08-05",
        "nepDate": "20.04.2076"
    },
    {
        "engDate": "2019-08-06",
        "nepDate": "21.04.2076"
    },
    {
        "engDate": "2019-08-07",
        "nepDate": "22.04.2076"
    },
    {
        "engDate": "2019-08-08",
        "nepDate": "23.04.2076"
    },
    {
        "engDate": "2019-08-09",
        "nepDate": "24.04.2076"
    },
    {
        "engDate": "2019-08-10",
        "nepDate": "25.04.2076"
    },
    {
        "engDate": "2019-08-11",
        "nepDate": "26.04.2076"
    },
    {
        "engDate": "2019-08-12",
        "nepDate": "27.04.2076"
    },
    {
        "engDate": "2019-08-13",
        "nepDate": "28.04.2076"
    },
    {
        "engDate": "2019-08-14",
        "nepDate": "29.04.2076"
    },
    {
        "engDate": "2019-08-15",
        "nepDate": "30.04.2076"
    },
    {
        "engDate": "2019-08-16",
        "nepDate": "31.04.2076"
    },
    {
        "engDate": "2019-08-17",
        "nepDate": "32.04.2076"
    },
    {
        "engDate": "2019-08-18",
        "nepDate": "01.05.2076"
    },
    {
        "engDate": "2019-08-19",
        "nepDate": "02.05.2076"
    },
    {
        "engDate": "2019-08-20",
        "nepDate": "03.05.2076"
    },
    {
        "engDate": "2019-08-21",
        "nepDate": "04.05.2076"
    },
    {
        "engDate": "2019-08-22",
        "nepDate": "05.05.2076"
    },
    {
        "engDate": "2019-08-23",
        "nepDate": "06.05.2076"
    },
    {
        "engDate": "2019-08-24",
        "nepDate": "07.05.2076"
    },
    {
        "engDate": "2019-08-25",
        "nepDate": "08.05.2076"
    },
    {
        "engDate": "2019-08-26",
        "nepDate": "09.05.2076"
    },
    {
        "engDate": "2019-08-27",
        "nepDate": "10.05.2076"
    },
    {
        "engDate": "2019-08-28",
        "nepDate": "11.05.2076"
    },
    {
        "engDate": "2019-08-29",
        "nepDate": "12.05.2076"
    },
    {
        "engDate": "2019-08-30",
        "nepDate": "13.05.2076"
    },
    {
        "engDate": "2019-08-31",
        "nepDate": "14.05.2076"
    },
    {
        "engDate": "2019-09-01",
        "nepDate": "15.05.2076"
    },
    {
        "engDate": "2019-09-02",
        "nepDate": "16.05.2076"
    },
    {
        "engDate": "2019-09-03",
        "nepDate": "17.05.2076"
    },
    {
        "engDate": "2019-09-04",
        "nepDate": "18.05.2076"
    },
    {
        "engDate": "2019-09-05",
        "nepDate": "19.05.2076"
    },
    {
        "engDate": "2019-09-06",
        "nepDate": "20.05.2076"
    },
    {
        "engDate": "2019-09-07",
        "nepDate": "21.05.2076"
    },
    {
        "engDate": "2019-09-08",
        "nepDate": "22.05.2076"
    },
    {
        "engDate": "2019-09-09",
        "nepDate": "23.05.2076"
    },
    {
        "engDate": "2019-09-10",
        "nepDate": "24.05.2076"
    },
    {
        "engDate": "2019-09-11",
        "nepDate": "25.05.2076"
    },
    {
        "engDate": "2019-09-12",
        "nepDate": "26.05.2076"
    },
    {
        "engDate": "2019-09-13",
        "nepDate": "27.05.2076"
    },
    {
        "engDate": "2019-09-14",
        "nepDate": "28.05.2076"
    },
    {
        "engDate": "2019-09-15",
        "nepDate": "29.05.2076"
    },
    {
        "engDate": "2019-09-16",
        "nepDate": "30.05.2076"
    },
    {
        "engDate": "2019-09-17",
        "nepDate": "31.05.2076"
    },
    {
        "engDate": "2019-09-18",
        "nepDate": "01.06.2076"
    },
    {
        "engDate": "2019-09-19",
        "nepDate": "02.06.2076"
    },
    {
        "engDate": "2019-09-20",
        "nepDate": "03.06.2076"
    },
    {
        "engDate": "2019-09-21",
        "nepDate": "04.06.2076"
    },
    {
        "engDate": "2019-09-22",
        "nepDate": "05.06.2076"
    },
    {
        "engDate": "2019-09-23",
        "nepDate": "06.06.2076"
    },
    {
        "engDate": "2019-09-24",
        "nepDate": "07.06.2076"
    },
    {
        "engDate": "2019-09-25",
        "nepDate": "08.06.2076"
    },
    {
        "engDate": "2019-09-26",
        "nepDate": "09.06.2076"
    },
    {
        "engDate": "2019-09-27",
        "nepDate": "10.06.2076"
    },
    {
        "engDate": "2019-09-28",
        "nepDate": "11.06.2076"
    },
    {
        "engDate": "2019-09-29",
        "nepDate": "12.06.2076"
    },
    {
        "engDate": "2019-09-30",
        "nepDate": "13.06.2076"
    },
    {
        "engDate": "2019-10-01",
        "nepDate": "14.06.2076"
    },
    {
        "engDate": "2019-10-02",
        "nepDate": "15.06.2076"
    },
    {
        "engDate": "2019-10-03",
        "nepDate": "16.06.2076"
    },
    {
        "engDate": "2019-10-04",
        "nepDate": "17.06.2076"
    },
    {
        "engDate": "2019-10-05",
        "nepDate": "18.06.2076"
    },
    {
        "engDate": "2019-10-06",
        "nepDate": "19.06.2076"
    },
    {
        "engDate": "2019-10-07",
        "nepDate": "20.06.2076"
    },
    {
        "engDate": "2019-10-08",
        "nepDate": "21.06.2076"
    },
    {
        "engDate": "2019-10-09",
        "nepDate": "22.06.2076"
    },
    {
        "engDate": "2019-10-10",
        "nepDate": "23.06.2076"
    },
    {
        "engDate": "2019-10-11",
        "nepDate": "24.06.2076"
    },
    {
        "engDate": "2019-10-12",
        "nepDate": "25.06.2076"
    },
    {
        "engDate": "2019-10-13",
        "nepDate": "26.06.2076"
    },
    {
        "engDate": "2019-10-14",
        "nepDate": "27.06.2076"
    },
    {
        "engDate": "2019-10-15",
        "nepDate": "28.06.2076"
    },
    {
        "engDate": "2019-10-16",
        "nepDate": "29.06.2076"
    },
    {
        "engDate": "2019-10-17",
        "nepDate": "30.06.2076"
    },
    {
        "engDate": "2019-10-18",
        "nepDate": "01.07.2076"
    },
    {
        "engDate": "2019-10-19",
        "nepDate": "02.07.2076"
    },
    {
        "engDate": "2019-10-20",
        "nepDate": "03.07.2076"
    },
    {
        "engDate": "2019-10-21",
        "nepDate": "04.07.2076"
    },
    {
        "engDate": "2019-10-22",
        "nepDate": "05.07.2076"
    },
    {
        "engDate": "2019-10-23",
        "nepDate": "06.07.2076"
    },
    {
        "engDate": "2019-10-24",
        "nepDate": "07.07.2076"
    },
    {
        "engDate": "2019-10-25",
        "nepDate": "08.07.2076"
    },
    {
        "engDate": "2019-10-26",
        "nepDate": "09.07.2076"
    },
    {
        "engDate": "2019-10-27",
        "nepDate": "10.07.2076"
    },
    {
        "engDate": "2019-10-28",
        "nepDate": "11.07.2076"
    },
    {
        "engDate": "2019-10-29",
        "nepDate": "12.07.2076"
    },
    {
        "engDate": "2019-10-30",
        "nepDate": "13.07.2076"
    },
    {
        "engDate": "2019-10-31",
        "nepDate": "14.07.2076"
    },
    {
        "engDate": "2019-11-01",
        "nepDate": "15.07.2076"
    },
    {
        "engDate": "2019-11-02",
        "nepDate": "16.07.2076"
    },
    {
        "engDate": "2019-11-03",
        "nepDate": "17.07.2076"
    },
    {
        "engDate": "2019-11-04",
        "nepDate": "18.07.2076"
    },
    {
        "engDate": "2019-11-05",
        "nepDate": "19.07.2076"
    },
    {
        "engDate": "2019-11-06",
        "nepDate": "20.07.2076"
    },
    {
        "engDate": "2019-11-07",
        "nepDate": "21.07.2076"
    },
    {
        "engDate": "2019-11-08",
        "nepDate": "22.07.2076"
    },
    {
        "engDate": "2019-11-09",
        "nepDate": "23.07.2076"
    },
    {
        "engDate": "2019-11-10",
        "nepDate": "24.07.2076"
    },
    {
        "engDate": "2019-11-11",
        "nepDate": "25.07.2076"
    },
    {
        "engDate": "2019-11-12",
        "nepDate": "26.07.2076"
    },
    {
        "engDate": "2019-11-13",
        "nepDate": "27.07.2076"
    },
    {
        "engDate": "2019-11-14",
        "nepDate": "28.07.2076"
    },
    {
        "engDate": "2019-11-15",
        "nepDate": "29.07.2076"
    },
    {
        "engDate": "2019-11-16",
        "nepDate": "30.07.2076"
    },
    {
        "engDate": "2019-11-17",
        "nepDate": "01.08.2076"
    },
    {
        "engDate": "2019-11-18",
        "nepDate": "02.08.2076"
    },
    {
        "engDate": "2019-11-19",
        "nepDate": "03.08.2076"
    },
    {
        "engDate": "2019-11-20",
        "nepDate": "04.08.2076"
    },
    {
        "engDate": "2019-11-21",
        "nepDate": "05.08.2076"
    },
    {
        "engDate": "2019-11-22",
        "nepDate": "06.08.2076"
    },
    {
        "engDate": "2019-11-23",
        "nepDate": "07.08.2076"
    },
    {
        "engDate": "2019-11-24",
        "nepDate": "08.08.2076"
    },
    {
        "engDate": "2019-11-25",
        "nepDate": "09.08.2076"
    },
    {
        "engDate": "2019-11-26",
        "nepDate": "10.08.2076"
    },
    {
        "engDate": "2019-11-27",
        "nepDate": "11.08.2076"
    },
    {
        "engDate": "2019-11-28",
        "nepDate": "12.08.2076"
    },
    {
        "engDate": "2019-11-29",
        "nepDate": "13.08.2076"
    },
    {
        "engDate": "2019-11-30",
        "nepDate": "14.08.2076"
    },
    {
        "engDate": "2019-12-01",
        "nepDate": "15.08.2076"
    },
    {
        "engDate": "2019-12-02",
        "nepDate": "16.08.2076"
    },
    {
        "engDate": "2019-12-03",
        "nepDate": "17.08.2076"
    },
    {
        "engDate": "2019-12-04",
        "nepDate": "18.08.2076"
    },
    {
        "engDate": "2019-12-05",
        "nepDate": "19.08.2076"
    },
    {
        "engDate": "2019-12-06",
        "nepDate": "20.08.2076"
    },
    {
        "engDate": "2019-12-07",
        "nepDate": "21.08.2076"
    },
    {
        "engDate": "2019-12-08",
        "nepDate": "22.08.2076"
    },
    {
        "engDate": "2019-12-09",
        "nepDate": "23.08.2076"
    },
    {
        "engDate": "2019-12-10",
        "nepDate": "24.08.2076"
    },
    {
        "engDate": "2019-12-11",
        "nepDate": "25.08.2076"
    },
    {
        "engDate": "2019-12-12",
        "nepDate": "26.08.2076"
    },
    {
        "engDate": "2019-12-13",
        "nepDate": "27.08.2076"
    },
    {
        "engDate": "2019-12-14",
        "nepDate": "28.08.2076"
    },
    {
        "engDate": "2019-12-15",
        "nepDate": "29.08.2076"
    },
    {
        "engDate": "2019-12-16",
        "nepDate": "30.08.2076"
    },
    {
        "engDate": "2019-12-17",
        "nepDate": "01.09.2076"
    },
    {
        "engDate": "2019-12-18",
        "nepDate": "02.09.2076"
    },
    {
        "engDate": "2019-12-19",
        "nepDate": "03.09.2076"
    },
    {
        "engDate": "2019-12-20",
        "nepDate": "04.09.2076"
    },
    {
        "engDate": "2019-12-21",
        "nepDate": "05.09.2076"
    },
    {
        "engDate": "2019-12-22",
        "nepDate": "06.09.2076"
    },
    {
        "engDate": "2019-12-23",
        "nepDate": "07.09.2076"
    },
    {
        "engDate": "2019-12-24",
        "nepDate": "08.09.2076"
    },
    {
        "engDate": "2019-12-25",
        "nepDate": "09.09.2076"
    },
    {
        "engDate": "2019-12-26",
        "nepDate": "10.09.2076"
    },
    {
        "engDate": "2019-12-27",
        "nepDate": "11.09.2076"
    },
    {
        "engDate": "2019-12-28",
        "nepDate": "12.09.2076"
    },
    {
        "engDate": "2019-12-29",
        "nepDate": "13.09.2076"
    },
    {
        "engDate": "2019-12-30",
        "nepDate": "14.09.2076"
    },
    {
        "engDate": "2019-12-31",
        "nepDate": "15.09.2076"
    },
    {
        "engDate": "2020-01-01",
        "nepDate": "16.09.2076"
    },
    {
        "engDate": "2020-01-02",
        "nepDate": "17.09.2076"
    },
    {
        "engDate": "2020-01-03",
        "nepDate": "18.09.2076"
    },
    {
        "engDate": "2020-01-04",
        "nepDate": "19.09.2076"
    },
    {
        "engDate": "2020-01-05",
        "nepDate": "20.09.2076"
    },
    {
        "engDate": "2020-01-06",
        "nepDate": "21.09.2076"
    },
    {
        "engDate": "2020-01-07",
        "nepDate": "22.09.2076"
    },
    {
        "engDate": "2020-01-08",
        "nepDate": "23.09.2076"
    },
    {
        "engDate": "2020-01-09",
        "nepDate": "24.09.2076"
    },
    {
        "engDate": "2020-01-10",
        "nepDate": "25.09.2076"
    },
    {
        "engDate": "2020-01-11",
        "nepDate": "26.09.2076"
    },
    {
        "engDate": "2020-01-12",
        "nepDate": "27.09.2076"
    },
    {
        "engDate": "2020-01-13",
        "nepDate": "28.09.2076"
    },
    {
        "engDate": "2020-01-14",
        "nepDate": "29.09.2076"
    },
    {
        "engDate": "2020-01-15",
        "nepDate": "01.10.2076"
    },
    {
        "engDate": "2020-01-16",
        "nepDate": "02.10.2076"
    },
    {
        "engDate": "2020-01-17",
        "nepDate": "03.10.2076"
    },
    {
        "engDate": "2020-01-18",
        "nepDate": "04.10.2076"
    },
    {
        "engDate": "2020-01-19",
        "nepDate": "05.10.2076"
    },
    {
        "engDate": "2020-01-20",
        "nepDate": "06.10.2076"
    },
    {
        "engDate": "2020-01-21",
        "nepDate": "07.10.2076"
    },
    {
        "engDate": "2020-01-22",
        "nepDate": "08.10.2076"
    },
    {
        "engDate": "2020-01-23",
        "nepDate": "09.10.2076"
    },
    {
        "engDate": "2020-01-24",
        "nepDate": "10.10.2076"
    },
    {
        "engDate": "2020-01-25",
        "nepDate": "11.10.2076"
    },
    {
        "engDate": "2020-01-26",
        "nepDate": "12.10.2076"
    },
    {
        "engDate": "2020-01-27",
        "nepDate": "13.10.2076"
    },
    {
        "engDate": "2020-01-28",
        "nepDate": "14.10.2076"
    },
    {
        "engDate": "2020-01-29",
        "nepDate": "15.10.2076"
    },
    {
        "engDate": "2020-01-30",
        "nepDate": "16.10.2076"
    },
    {
        "engDate": "2020-01-31",
        "nepDate": "17.10.2076"
    },
    {
        "engDate": "2020-02-01",
        "nepDate": "18.10.2076"
    },
    {
        "engDate": "2020-02-02",
        "nepDate": "19.10.2076"
    },
    {
        "engDate": "2020-02-03",
        "nepDate": "20.10.2076"
    },
    {
        "engDate": "2020-02-04",
        "nepDate": "21.10.2076"
    },
    {
        "engDate": "2020-02-05",
        "nepDate": "22.10.2076"
    },
    {
        "engDate": "2020-02-06",
        "nepDate": "23.10.2076"
    },
    {
        "engDate": "2020-02-07",
        "nepDate": "24.10.2076"
    },
    {
        "engDate": "2020-02-08",
        "nepDate": "25.10.2076"
    },
    {
        "engDate": "2020-02-09",
        "nepDate": "26.10.2076"
    },
    {
        "engDate": "2020-02-10",
        "nepDate": "27.10.2076"
    },
    {
        "engDate": "2020-02-11",
        "nepDate": "28.10.2076"
    },
    {
        "engDate": "2020-02-12",
        "nepDate": "29.10.2076"
    },
    {
        "engDate": "2020-02-13",
        "nepDate": "01.11.2076"
    },
    {
        "engDate": "2020-02-14",
        "nepDate": "02.11.2076"
    },
    {
        "engDate": "2020-02-15",
        "nepDate": "03.11.2076"
    },
    {
        "engDate": "2020-02-16",
        "nepDate": "04.11.2076"
    },
    {
        "engDate": "2020-02-17",
        "nepDate": "05.11.2076"
    },
    {
        "engDate": "2020-02-18",
        "nepDate": "06.11.2076"
    },
    {
        "engDate": "2020-02-19",
        "nepDate": "07.11.2076"
    },
    {
        "engDate": "2020-02-20",
        "nepDate": "08.11.2076"
    },
    {
        "engDate": "2020-02-21",
        "nepDate": "09.11.2076"
    },
    {
        "engDate": "2020-02-22",
        "nepDate": "10.11.2076"
    },
    {
        "engDate": "2020-02-23",
        "nepDate": "11.11.2076"
    },
    {
        "engDate": "2020-02-24",
        "nepDate": "12.11.2076"
    },
    {
        "engDate": "2020-02-25",
        "nepDate": "13.11.2076"
    },
    {
        "engDate": "2020-02-26",
        "nepDate": "14.11.2076"
    },
    {
        "engDate": "2020-02-27",
        "nepDate": "15.11.2076"
    },
    {
        "engDate": "2020-02-28",
        "nepDate": "16.11.2076"
    },
    {
        "engDate": "2020-02-29",
        "nepDate": "17.11.2076"
    },
    {
        "engDate": "2020-03-01",
        "nepDate": "18.11.2076"
    },
    {
        "engDate": "2020-03-02",
        "nepDate": "19.11.2076"
    },
    {
        "engDate": "2020-03-03",
        "nepDate": "20.11.2076"
    },
    {
        "engDate": "2020-03-04",
        "nepDate": "21.11.2076"
    },
    {
        "engDate": "2020-03-05",
        "nepDate": "22.11.2076"
    },
    {
        "engDate": "2020-03-06",
        "nepDate": "23.11.2076"
    },
    {
        "engDate": "2020-03-07",
        "nepDate": "24.11.2076"
    },
    {
        "engDate": "2020-03-08",
        "nepDate": "25.11.2076"
    },
    {
        "engDate": "2020-03-09",
        "nepDate": "26.11.2076"
    },
    {
        "engDate": "2020-03-10",
        "nepDate": "27.11.2076"
    },
    {
        "engDate": "2020-03-11",
        "nepDate": "28.11.2076"
    },
    {
        "engDate": "2020-03-12",
        "nepDate": "29.11.2076"
    },
    {
        "engDate": "2020-03-13",
        "nepDate": "30.11.2076"
    },
    {
        "engDate": "2020-03-14",
        "nepDate": "01.12.2076"
    },
    {
        "engDate": "2020-03-15",
        "nepDate": "02.12.2076"
    },
    {
        "engDate": "2020-03-16",
        "nepDate": "03.12.2076"
    },
    {
        "engDate": "2020-03-17",
        "nepDate": "04.12.2076"
    },
    {
        "engDate": "2020-03-18",
        "nepDate": "05.12.2076"
    },
    {
        "engDate": "2020-03-19",
        "nepDate": "06.12.2076"
    },
    {
        "engDate": "2020-03-20",
        "nepDate": "07.12.2076"
    },
    {
        "engDate": "2020-03-21",
        "nepDate": "08.12.2076"
    },
    {
        "engDate": "2020-03-22",
        "nepDate": "09.12.2076"
    },
    {
        "engDate": "2020-03-23",
        "nepDate": "10.12.2076"
    },
    {
        "engDate": "2020-03-24",
        "nepDate": "11.12.2076"
    },
    {
        "engDate": "2020-03-25",
        "nepDate": "12.12.2076"
    },
    {
        "engDate": "2020-03-26",
        "nepDate": "13.12.2076"
    },
    {
        "engDate": "2020-03-27",
        "nepDate": "14.12.2076"
    },
    {
        "engDate": "2020-03-28",
        "nepDate": "15.12.2076"
    },
    {
        "engDate": "2020-03-29",
        "nepDate": "16.12.2076"
    },
    {
        "engDate": "2020-03-30",
        "nepDate": "17.12.2076"
    },
    {
        "engDate": "2020-03-31",
        "nepDate": "18.12.2076"
    },
    {
        "engDate": "2020-04-01",
        "nepDate": "19.12.2076"
    },
    {
        "engDate": "2020-04-02",
        "nepDate": "20.12.2076"
    },
    {
        "engDate": "2020-04-03",
        "nepDate": "21.12.2076"
    },
    {
        "engDate": "2020-04-04",
        "nepDate": "22.12.2076"
    },
    {
        "engDate": "2020-04-05",
        "nepDate": "23.12.2076"
    },
    {
        "engDate": "2020-04-06",
        "nepDate": "24.12.2076"
    },
    {
        "engDate": "2020-04-07",
        "nepDate": "25.12.2076"
    },
    {
        "engDate": "2020-04-08",
        "nepDate": "26.12.2076"
    },
    {
        "engDate": "2020-04-09",
        "nepDate": "27.12.2076"
    },
    {
        "engDate": "2020-04-10",
        "nepDate": "28.12.2076"
    },
    {
        "engDate": "2020-04-11",
        "nepDate": "29.12.2076"
    },
    {
        "engDate": "2020-04-12",
        "nepDate": "30.12.2076"
    },
    {
        "engDate": "2020-04-13",
        "nepDate": "01.01.2077"
    },
    {
        "engDate": "2020-04-14",
        "nepDate": "02.01.2077"
    },
    {
        "engDate": "2020-04-15",
        "nepDate": "03.01.2077"
    },
    {
        "engDate": "2020-04-16",
        "nepDate": "04.01.2077"
    },
    {
        "engDate": "2020-04-17",
        "nepDate": "05.01.2077"
    },
    {
        "engDate": "2020-04-18",
        "nepDate": "06.01.2077"
    },
    {
        "engDate": "2020-04-19",
        "nepDate": "07.01.2077"
    },
    {
        "engDate": "2020-04-20",
        "nepDate": "08.01.2077"
    },
    {
        "engDate": "2020-04-21",
        "nepDate": "09.01.2077"
    },
    {
        "engDate": "2020-04-22",
        "nepDate": "10.01.2077"
    },
    {
        "engDate": "2020-04-23",
        "nepDate": "11.01.2077"
    },
    {
        "engDate": "2020-04-24",
        "nepDate": "12.01.2077"
    },
    {
        "engDate": "2020-04-25",
        "nepDate": "13.01.2077"
    },
    {
        "engDate": "2020-04-26",
        "nepDate": "14.01.2077"
    },
    {
        "engDate": "2020-04-27",
        "nepDate": "15.01.2077"
    },
    {
        "engDate": "2020-04-28",
        "nepDate": "16.01.2077"
    },
    {
        "engDate": "2020-04-29",
        "nepDate": "17.01.2077"
    },
    {
        "engDate": "2020-04-30",
        "nepDate": "18.01.2077"
    },
    {
        "engDate": "2020-05-01",
        "nepDate": "19.01.2077"
    },
    {
        "engDate": "2020-05-02",
        "nepDate": "20.01.2077"
    },
    {
        "engDate": "2020-05-03",
        "nepDate": "21.01.2077"
    },
    {
        "engDate": "2020-05-04",
        "nepDate": "22.01.2077"
    },
    {
        "engDate": "2020-05-05",
        "nepDate": "23.01.2077"
    },
    {
        "engDate": "2020-05-06",
        "nepDate": "24.01.2077"
    },
    {
        "engDate": "2020-05-07",
        "nepDate": "25.01.2077"
    },
    {
        "engDate": "2020-05-08",
        "nepDate": "26.01.2077"
    },
    {
        "engDate": "2020-05-09",
        "nepDate": "27.01.2077"
    },
    {
        "engDate": "2020-05-10",
        "nepDate": "28.01.2077"
    },
    {
        "engDate": "2020-05-11",
        "nepDate": "29.01.2077"
    },
    {
        "engDate": "2020-05-12",
        "nepDate": "30.01.2077"
    },
    {
        "engDate": "2020-05-13",
        "nepDate": "31.01.2077"
    },
    {
        "engDate": "2020-05-14",
        "nepDate": "01.02.2077"
    },
    {
        "engDate": "2020-05-15",
        "nepDate": "02.02.2077"
    },
    {
        "engDate": "2020-05-16",
        "nepDate": "03.02.2077"
    },
    {
        "engDate": "2020-05-17",
        "nepDate": "04.02.2077"
    },
    {
        "engDate": "2020-05-18",
        "nepDate": "05.02.2077"
    },
    {
        "engDate": "2020-05-19",
        "nepDate": "06.02.2077"
    },
    {
        "engDate": "2020-05-20",
        "nepDate": "07.02.2077"
    },
    {
        "engDate": "2020-05-21",
        "nepDate": "08.02.2077"
    },
    {
        "engDate": "2020-05-22",
        "nepDate": "09.02.2077"
    },
    {
        "engDate": "2020-05-23",
        "nepDate": "10.02.2077"
    },
    {
        "engDate": "2020-05-24",
        "nepDate": "11.02.2077"
    },
    {
        "engDate": "2020-05-25",
        "nepDate": "12.02.2077"
    },
    {
        "engDate": "2020-05-26",
        "nepDate": "13.02.2077"
    },
    {
        "engDate": "2020-05-27",
        "nepDate": "14.02.2077"
    },
    {
        "engDate": "2020-05-28",
        "nepDate": "15.02.2077"
    },
    {
        "engDate": "2020-05-29",
        "nepDate": "16.02.2077"
    },
    {
        "engDate": "2020-05-30",
        "nepDate": "17.02.2077"
    },
    {
        "engDate": "2020-05-31",
        "nepDate": "18.02.2077"
    },
    {
        "engDate": "2020-06-01",
        "nepDate": "19.02.2077"
    },
    {
        "engDate": "2020-06-02",
        "nepDate": "20.02.2077"
    },
    {
        "engDate": "2020-06-03",
        "nepDate": "21.02.2077"
    },
    {
        "engDate": "2020-06-04",
        "nepDate": "22.02.2077"
    },
    {
        "engDate": "2020-06-05",
        "nepDate": "23.02.2077"
    },
    {
        "engDate": "2020-06-06",
        "nepDate": "24.02.2077"
    },
    {
        "engDate": "2020-06-07",
        "nepDate": "25.02.2077"
    },
    {
        "engDate": "2020-06-08",
        "nepDate": "26.02.2077"
    },
    {
        "engDate": "2020-06-09",
        "nepDate": "27.02.2077"
    },
    {
        "engDate": "2020-06-10",
        "nepDate": "28.02.2077"
    },
    {
        "engDate": "2020-06-11",
        "nepDate": "29.02.2077"
    },
    {
        "engDate": "2020-06-12",
        "nepDate": "30.02.2077"
    },
    {
        "engDate": "2020-06-13",
        "nepDate": "31.02.2077"
    },
    {
        "engDate": "2020-06-14",
        "nepDate": "32.02.2077"
    },
    {
        "engDate": "2020-06-15",
        "nepDate": "01.03.2077"
    },
    {
        "engDate": "2020-06-16",
        "nepDate": "02.03.2077"
    },
    {
        "engDate": "2020-06-17",
        "nepDate": "03.03.2077"
    },
    {
        "engDate": "2020-06-18",
        "nepDate": "04.03.2077"
    },
    {
        "engDate": "2020-06-19",
        "nepDate": "05.03.2077"
    },
    {
        "engDate": "2020-06-20",
        "nepDate": "06.03.2077"
    },
    {
        "engDate": "2020-06-21",
        "nepDate": "07.03.2077"
    },
    {
        "engDate": "2020-06-22",
        "nepDate": "08.03.2077"
    },
    {
        "engDate": "2020-06-23",
        "nepDate": "09.03.2077"
    },
    {
        "engDate": "2020-06-24",
        "nepDate": "10.03.2077"
    },
    {
        "engDate": "2020-06-25",
        "nepDate": "11.03.2077"
    },
    {
        "engDate": "2020-06-26",
        "nepDate": "12.03.2077"
    },
    {
        "engDate": "2020-06-27",
        "nepDate": "13.03.2077"
    },
    {
        "engDate": "2020-06-28",
        "nepDate": "14.03.2077"
    },
    {
        "engDate": "2020-06-29",
        "nepDate": "15.03.2077"
    },
    {
        "engDate": "2020-06-30",
        "nepDate": "16.03.2077"
    },
    {
        "engDate": "2020-07-01",
        "nepDate": "17.03.2077"
    },
    {
        "engDate": "2020-07-02",
        "nepDate": "18.03.2077"
    },
    {
        "engDate": "2020-07-03",
        "nepDate": "19.03.2077"
    },
    {
        "engDate": "2020-07-04",
        "nepDate": "20.03.2077"
    },
    {
        "engDate": "2020-07-05",
        "nepDate": "21.03.2077"
    },
    {
        "engDate": "2020-07-06",
        "nepDate": "22.03.2077"
    },
    {
        "engDate": "2020-07-07",
        "nepDate": "23.03.2077"
    },
    {
        "engDate": "2020-07-08",
        "nepDate": "24.03.2077"
    },
    {
        "engDate": "2020-07-09",
        "nepDate": "25.03.2077"
    },
    {
        "engDate": "2020-07-10",
        "nepDate": "26.03.2077"
    },
    {
        "engDate": "2020-07-11",
        "nepDate": "27.03.2077"
    },
    {
        "engDate": "2020-07-12",
        "nepDate": "28.03.2077"
    },
    {
        "engDate": "2020-07-13",
        "nepDate": "29.03.2077"
    },
    {
        "engDate": "2020-07-14",
        "nepDate": "30.03.2077"
    },
    {
        "engDate": "2020-07-15",
        "nepDate": "31.03.2077"
    },
    {
        "engDate": "2020-07-16",
        "nepDate": "01.04.2077"
    },
    {
        "engDate": "2020-07-17",
        "nepDate": "02.04.2077"
    },
    {
        "engDate": "2020-07-18",
        "nepDate": "03.04.2077"
    },
    {
        "engDate": "2020-07-19",
        "nepDate": "04.04.2077"
    },
    {
        "engDate": "2020-07-20",
        "nepDate": "05.04.2077"
    },
    {
        "engDate": "2020-07-21",
        "nepDate": "06.04.2077"
    },
    {
        "engDate": "2020-07-22",
        "nepDate": "07.04.2077"
    },
    {
        "engDate": "2020-07-23",
        "nepDate": "08.04.2077"
    },
    {
        "engDate": "2020-07-24",
        "nepDate": "09.04.2077"
    },
    {
        "engDate": "2020-07-25",
        "nepDate": "10.04.2077"
    },
    {
        "engDate": "2020-07-26",
        "nepDate": "11.04.2077"
    },
    {
        "engDate": "2020-07-27",
        "nepDate": "12.04.2077"
    },
    {
        "engDate": "2020-07-28",
        "nepDate": "13.04.2077"
    },
    {
        "engDate": "2020-07-29",
        "nepDate": "14.04.2077"
    },
    {
        "engDate": "2020-07-30",
        "nepDate": "15.04.2077"
    },
    {
        "engDate": "2020-07-31",
        "nepDate": "16.04.2077"
    },
    {
        "engDate": "2020-08-01",
        "nepDate": "17.04.2077"
    },
    {
        "engDate": "2020-08-02",
        "nepDate": "18.04.2077"
    },
    {
        "engDate": "2020-08-03",
        "nepDate": "19.04.2077"
    },
    {
        "engDate": "2020-08-04",
        "nepDate": "20.04.2077"
    },
    {
        "engDate": "2020-08-05",
        "nepDate": "21.04.2077"
    },
    {
        "engDate": "2020-08-06",
        "nepDate": "22.04.2077"
    },
    {
        "engDate": "2020-08-07",
        "nepDate": "23.04.2077"
    },
    {
        "engDate": "2020-08-08",
        "nepDate": "24.04.2077"
    },
    {
        "engDate": "2020-08-09",
        "nepDate": "25.04.2077"
    },
    {
        "engDate": "2020-08-10",
        "nepDate": "26.04.2077"
    },
    {
        "engDate": "2020-08-11",
        "nepDate": "27.04.2077"
    },
    {
        "engDate": "2020-08-12",
        "nepDate": "28.04.2077"
    },
    {
        "engDate": "2020-08-13",
        "nepDate": "29.04.2077"
    },
    {
        "engDate": "2020-08-14",
        "nepDate": "30.04.2077"
    },
    {
        "engDate": "2020-08-15",
        "nepDate": "31.04.2077"
    },
    {
        "engDate": "2020-08-16",
        "nepDate": "32.04.2077"
    },
    {
        "engDate": "2020-08-17",
        "nepDate": "01.05.2077"
    },
    {
        "engDate": "2020-08-18",
        "nepDate": "02.05.2077"
    },
    {
        "engDate": "2020-08-19",
        "nepDate": "03.05.2077"
    },
    {
        "engDate": "2020-08-20",
        "nepDate": "04.05.2077"
    },
    {
        "engDate": "2020-08-21",
        "nepDate": "05.05.2077"
    },
    {
        "engDate": "2020-08-22",
        "nepDate": "06.05.2077"
    },
    {
        "engDate": "2020-08-23",
        "nepDate": "07.05.2077"
    },
    {
        "engDate": "2020-08-24",
        "nepDate": "08.05.2077"
    },
    {
        "engDate": "2020-08-25",
        "nepDate": "09.05.2077"
    },
    {
        "engDate": "2020-08-26",
        "nepDate": "10.05.2077"
    },
    {
        "engDate": "2020-08-27",
        "nepDate": "11.05.2077"
    },
    {
        "engDate": "2020-08-28",
        "nepDate": "12.05.2077"
    },
    {
        "engDate": "2020-08-29",
        "nepDate": "13.05.2077"
    },
    {
        "engDate": "2020-08-30",
        "nepDate": "14.05.2077"
    },
    {
        "engDate": "2020-08-31",
        "nepDate": "15.05.2077"
    },
    {
        "engDate": "2020-09-01",
        "nepDate": "16.05.2077"
    },
    {
        "engDate": "2020-09-02",
        "nepDate": "17.05.2077"
    },
    {
        "engDate": "2020-09-03",
        "nepDate": "18.05.2077"
    },
    {
        "engDate": "2020-09-04",
        "nepDate": "19.05.2077"
    },
    {
        "engDate": "2020-09-05",
        "nepDate": "20.05.2077"
    },
    {
        "engDate": "2020-09-06",
        "nepDate": "21.05.2077"
    },
    {
        "engDate": "2020-09-07",
        "nepDate": "22.05.2077"
    },
    {
        "engDate": "2020-09-08",
        "nepDate": "23.05.2077"
    },
    {
        "engDate": "2020-09-09",
        "nepDate": "24.05.2077"
    },
    {
        "engDate": "2020-09-10",
        "nepDate": "25.05.2077"
    },
    {
        "engDate": "2020-09-11",
        "nepDate": "26.05.2077"
    },
    {
        "engDate": "2020-09-12",
        "nepDate": "27.05.2077"
    },
    {
        "engDate": "2020-09-13",
        "nepDate": "28.05.2077"
    },
    {
        "engDate": "2020-09-14",
        "nepDate": "29.05.2077"
    },
    {
        "engDate": "2020-09-15",
        "nepDate": "30.05.2077"
    },
    {
        "engDate": "2020-09-16",
        "nepDate": "31.05.2077"
    },
    {
        "engDate": "2020-09-17",
        "nepDate": "01.06.2077"
    },
    {
        "engDate": "2020-09-18",
        "nepDate": "02.06.2077"
    },
    {
        "engDate": "2020-09-19",
        "nepDate": "03.06.2077"
    },
    {
        "engDate": "2020-09-20",
        "nepDate": "04.06.2077"
    },
    {
        "engDate": "2020-09-21",
        "nepDate": "05.06.2077"
    },
    {
        "engDate": "2020-09-22",
        "nepDate": "06.06.2077"
    },
    {
        "engDate": "2020-09-23",
        "nepDate": "07.06.2077"
    },
    {
        "engDate": "2020-09-24",
        "nepDate": "08.06.2077"
    },
    {
        "engDate": "2020-09-25",
        "nepDate": "09.06.2077"
    },
    {
        "engDate": "2020-09-26",
        "nepDate": "10.06.2077"
    },
    {
        "engDate": "2020-09-27",
        "nepDate": "11.06.2077"
    },
    {
        "engDate": "2020-09-28",
        "nepDate": "12.06.2077"
    },
    {
        "engDate": "2020-09-29",
        "nepDate": "13.06.2077"
    },
    {
        "engDate": "2020-09-30",
        "nepDate": "14.06.2077"
    },
    {
        "engDate": "2020-10-01",
        "nepDate": "15.06.2077"
    },
    {
        "engDate": "2020-10-02",
        "nepDate": "16.06.2077"
    },
    {
        "engDate": "2020-10-03",
        "nepDate": "17.06.2077"
    },
    {
        "engDate": "2020-10-04",
        "nepDate": "18.06.2077"
    },
    {
        "engDate": "2020-10-05",
        "nepDate": "19.06.2077"
    },
    {
        "engDate": "2020-10-06",
        "nepDate": "20.06.2077"
    },
    {
        "engDate": "2020-10-07",
        "nepDate": "21.06.2077"
    },
    {
        "engDate": "2020-10-08",
        "nepDate": "22.06.2077"
    },
    {
        "engDate": "2020-10-09",
        "nepDate": "23.06.2077"
    },
    {
        "engDate": "2020-10-10",
        "nepDate": "24.06.2077"
    },
    {
        "engDate": "2020-10-11",
        "nepDate": "25.06.2077"
    },
    {
        "engDate": "2020-10-12",
        "nepDate": "26.06.2077"
    },
    {
        "engDate": "2020-10-13",
        "nepDate": "27.06.2077"
    },
    {
        "engDate": "2020-10-14",
        "nepDate": "28.06.2077"
    },
    {
        "engDate": "2020-10-15",
        "nepDate": "29.06.2077"
    },
    {
        "engDate": "2020-10-16",
        "nepDate": "30.06.2077"
    },
    {
        "engDate": "2020-10-17",
        "nepDate": "01.07.2077"
    },
    {
        "engDate": "2020-10-18",
        "nepDate": "02.07.2077"
    },
    {
        "engDate": "2020-10-19",
        "nepDate": "03.07.2077"
    },
    {
        "engDate": "2020-10-20",
        "nepDate": "04.07.2077"
    },
    {
        "engDate": "2020-10-21",
        "nepDate": "05.07.2077"
    },
    {
        "engDate": "2020-10-22",
        "nepDate": "06.07.2077"
    },
    {
        "engDate": "2020-10-23",
        "nepDate": "07.07.2077"
    },
    {
        "engDate": "2020-10-24",
        "nepDate": "08.07.2077"
    },
    {
        "engDate": "2020-10-25",
        "nepDate": "09.07.2077"
    },
    {
        "engDate": "2020-10-26",
        "nepDate": "10.07.2077"
    },
    {
        "engDate": "2020-10-27",
        "nepDate": "11.07.2077"
    },
    {
        "engDate": "2020-10-28",
        "nepDate": "12.07.2077"
    },
    {
        "engDate": "2020-10-29",
        "nepDate": "13.07.2077"
    },
    {
        "engDate": "2020-10-30",
        "nepDate": "14.07.2077"
    },
    {
        "engDate": "2020-10-31",
        "nepDate": "15.07.2077"
    },
    {
        "engDate": "2020-11-01",
        "nepDate": "16.07.2077"
    },
    {
        "engDate": "2020-11-02",
        "nepDate": "17.07.2077"
    },
    {
        "engDate": "2020-11-03",
        "nepDate": "18.07.2077"
    },
    {
        "engDate": "2020-11-04",
        "nepDate": "19.07.2077"
    },
    {
        "engDate": "2020-11-05",
        "nepDate": "20.07.2077"
    },
    {
        "engDate": "2020-11-06",
        "nepDate": "21.07.2077"
    },
    {
        "engDate": "2020-11-07",
        "nepDate": "22.07.2077"
    },
    {
        "engDate": "2020-11-08",
        "nepDate": "23.07.2077"
    },
    {
        "engDate": "2020-11-09",
        "nepDate": "24.07.2077"
    },
    {
        "engDate": "2020-11-10",
        "nepDate": "25.07.2077"
    },
    {
        "engDate": "2020-11-11",
        "nepDate": "26.07.2077"
    },
    {
        "engDate": "2020-11-12",
        "nepDate": "27.07.2077"
    },
    {
        "engDate": "2020-11-13",
        "nepDate": "28.07.2077"
    },
    {
        "engDate": "2020-11-14",
        "nepDate": "29.07.2077"
    },
    {
        "engDate": "2020-11-15",
        "nepDate": "30.07.2077"
    },
    {
        "engDate": "2020-11-16",
        "nepDate": "01.08.2077"
    },
    {
        "engDate": "2020-11-17",
        "nepDate": "02.08.2077"
    },
    {
        "engDate": "2020-11-18",
        "nepDate": "03.08.2077"
    },
    {
        "engDate": "2020-11-19",
        "nepDate": "04.08.2077"
    },
    {
        "engDate": "2020-11-20",
        "nepDate": "05.08.2077"
    },
    {
        "engDate": "2020-11-21",
        "nepDate": "06.08.2077"
    },
    {
        "engDate": "2020-11-22",
        "nepDate": "07.08.2077"
    },
    {
        "engDate": "2020-11-23",
        "nepDate": "08.08.2077"
    },
    {
        "engDate": "2020-11-24",
        "nepDate": "09.08.2077"
    },
    {
        "engDate": "2020-11-25",
        "nepDate": "10.08.2077"
    },
    {
        "engDate": "2020-11-26",
        "nepDate": "11.08.2077"
    },
    {
        "engDate": "2020-11-27",
        "nepDate": "12.08.2077"
    },
    {
        "engDate": "2020-11-28",
        "nepDate": "13.08.2077"
    },
    {
        "engDate": "2020-11-29",
        "nepDate": "14.08.2077"
    },
    {
        "engDate": "2020-11-30",
        "nepDate": "15.08.2077"
    },
    {
        "engDate": "2020-12-01",
        "nepDate": "16.08.2077"
    },
    {
        "engDate": "2020-12-02",
        "nepDate": "17.08.2077"
    },
    {
        "engDate": "2020-12-03",
        "nepDate": "18.08.2077"
    },
    {
        "engDate": "2020-12-04",
        "nepDate": "19.08.2077"
    },
    {
        "engDate": "2020-12-05",
        "nepDate": "20.08.2077"
    },
    {
        "engDate": "2020-12-06",
        "nepDate": "21.08.2077"
    },
    {
        "engDate": "2020-12-07",
        "nepDate": "22.08.2077"
    },
    {
        "engDate": "2020-12-08",
        "nepDate": "23.08.2077"
    },
    {
        "engDate": "2020-12-09",
        "nepDate": "24.08.2077"
    },
    {
        "engDate": "2020-12-10",
        "nepDate": "25.08.2077"
    },
    {
        "engDate": "2020-12-11",
        "nepDate": "26.08.2077"
    },
    {
        "engDate": "2020-12-12",
        "nepDate": "27.08.2077"
    },
    {
        "engDate": "2020-12-13",
        "nepDate": "28.08.2077"
    },
    {
        "engDate": "2020-12-14",
        "nepDate": "29.08.2077"
    },
    {
        "engDate": "2020-12-15",
        "nepDate": "30.08.2077"
    },
    {
        "engDate": "2020-12-16",
        "nepDate": "01.09.2077"
    },
    {
        "engDate": "2020-12-17",
        "nepDate": "02.09.2077"
    },
    {
        "engDate": "2020-12-18",
        "nepDate": "03.09.2077"
    },
    {
        "engDate": "2020-12-19",
        "nepDate": "04.09.2077"
    },
    {
        "engDate": "2020-12-20",
        "nepDate": "05.09.2077"
    },
    {
        "engDate": "2020-12-21",
        "nepDate": "06.09.2077"
    },
    {
        "engDate": "2020-12-22",
        "nepDate": "07.09.2077"
    },
    {
        "engDate": "2020-12-23",
        "nepDate": "08.09.2077"
    },
    {
        "engDate": "2020-12-24",
        "nepDate": "09.09.2077"
    },
    {
        "engDate": "2020-12-25",
        "nepDate": "10.09.2077"
    },
    {
        "engDate": "2020-12-26",
        "nepDate": "11.09.2077"
    },
    {
        "engDate": "2020-12-27",
        "nepDate": "12.09.2077"
    },
    {
        "engDate": "2020-12-28",
        "nepDate": "13.09.2077"
    },
    {
        "engDate": "2020-12-29",
        "nepDate": "14.09.2077"
    },
    {
        "engDate": "2020-12-30",
        "nepDate": "15.09.2077"
    },
    {
        "engDate": "2020-12-31",
        "nepDate": "16.09.2077"
    },
    {
        "engDate": "2021-01-01",
        "nepDate": "17.09.2077"
    },
    {
        "engDate": "2021-01-02",
        "nepDate": "18.09.2077"
    },
    {
        "engDate": "2021-01-03",
        "nepDate": "19.09.2077"
    },
    {
        "engDate": "2021-01-04",
        "nepDate": "20.09.2077"
    },
    {
        "engDate": "2021-01-05",
        "nepDate": "21.09.2077"
    },
    {
        "engDate": "2021-01-06",
        "nepDate": "22.09.2077"
    },
    {
        "engDate": "2021-01-07",
        "nepDate": "23.09.2077"
    },
    {
        "engDate": "2021-01-08",
        "nepDate": "24.09.2077"
    },
    {
        "engDate": "2021-01-09",
        "nepDate": "25.09.2077"
    },
    {
        "engDate": "2021-01-10",
        "nepDate": "26.09.2077"
    },
    {
        "engDate": "2021-01-11",
        "nepDate": "27.09.2077"
    },
    {
        "engDate": "2021-01-12",
        "nepDate": "28.09.2077"
    },
    {
        "engDate": "2021-01-13",
        "nepDate": "29.09.2077"
    },
    {
        "engDate": "2021-01-14",
        "nepDate": "01.10.2077"
    },
    {
        "engDate": "2021-01-15",
        "nepDate": "02.10.2077"
    },
    {
        "engDate": "2021-01-16",
        "nepDate": "03.10.2077"
    },
    {
        "engDate": "2021-01-17",
        "nepDate": "04.10.2077"
    },
    {
        "engDate": "2021-01-18",
        "nepDate": "05.10.2077"
    },
    {
        "engDate": "2021-01-19",
        "nepDate": "06.10.2077"
    },
    {
        "engDate": "2021-01-20",
        "nepDate": "07.10.2077"
    },
    {
        "engDate": "2021-01-21",
        "nepDate": "08.10.2077"
    },
    {
        "engDate": "2021-01-22",
        "nepDate": "09.10.2077"
    },
    {
        "engDate": "2021-01-23",
        "nepDate": "10.10.2077"
    },
    {
        "engDate": "2021-01-24",
        "nepDate": "11.10.2077"
    },
    {
        "engDate": "2021-01-25",
        "nepDate": "12.10.2077"
    },
    {
        "engDate": "2021-01-26",
        "nepDate": "13.10.2077"
    },
    {
        "engDate": "2021-01-27",
        "nepDate": "14.10.2077"
    },
    {
        "engDate": "2021-01-28",
        "nepDate": "15.10.2077"
    },
    {
        "engDate": "2021-01-29",
        "nepDate": "16.10.2077"
    },
    {
        "engDate": "2021-01-30",
        "nepDate": "17.10.2077"
    },
    {
        "engDate": "2021-01-31",
        "nepDate": "18.10.2077"
    },
    {
        "engDate": "2021-02-01",
        "nepDate": "19.10.2077"
    },
    {
        "engDate": "2021-02-02",
        "nepDate": "20.10.2077"
    },
    {
        "engDate": "2021-02-03",
        "nepDate": "21.10.2077"
    },
    {
        "engDate": "2021-02-04",
        "nepDate": "22.10.2077"
    },
    {
        "engDate": "2021-02-05",
        "nepDate": "23.10.2077"
    },
    {
        "engDate": "2021-02-06",
        "nepDate": "24.10.2077"
    },
    {
        "engDate": "2021-02-07",
        "nepDate": "25.10.2077"
    },
    {
        "engDate": "2021-02-08",
        "nepDate": "26.10.2077"
    },
    {
        "engDate": "2021-02-09",
        "nepDate": "27.10.2077"
    },
    {
        "engDate": "2021-02-10",
        "nepDate": "28.10.2077"
    },
    {
        "engDate": "2021-02-11",
        "nepDate": "29.10.2077"
    },
    {
        "engDate": "2021-02-12",
        "nepDate": "30.10.2077"
    },
    {
        "engDate": "2021-02-13",
        "nepDate": "01.11.2077"
    },
    {
        "engDate": "2021-02-14",
        "nepDate": "02.11.2077"
    },
    {
        "engDate": "2021-02-15",
        "nepDate": "03.11.2077"
    },
    {
        "engDate": "2021-02-16",
        "nepDate": "04.11.2077"
    },
    {
        "engDate": "2021-02-17",
        "nepDate": "05.11.2077"
    },
    {
        "engDate": "2021-02-18",
        "nepDate": "06.11.2077"
    },
    {
        "engDate": "2021-02-19",
        "nepDate": "07.11.2077"
    },
    {
        "engDate": "2021-02-20",
        "nepDate": "08.11.2077"
    },
    {
        "engDate": "2021-02-21",
        "nepDate": "09.11.2077"
    },
    {
        "engDate": "2021-02-22",
        "nepDate": "10.11.2077"
    },
    {
        "engDate": "2021-02-23",
        "nepDate": "11.11.2077"
    },
    {
        "engDate": "2021-02-24",
        "nepDate": "12.11.2077"
    },
    {
        "engDate": "2021-02-25",
        "nepDate": "13.11.2077"
    },
    {
        "engDate": "2021-02-26",
        "nepDate": "14.11.2077"
    },
    {
        "engDate": "2021-02-27",
        "nepDate": "15.11.2077"
    },
    {
        "engDate": "2021-02-28",
        "nepDate": "16.11.2077"
    },
    {
        "engDate": "2021-03-01",
        "nepDate": "17.11.2077"
    },
    {
        "engDate": "2021-03-02",
        "nepDate": "18.11.2077"
    },
    {
        "engDate": "2021-03-03",
        "nepDate": "19.11.2077"
    },
    {
        "engDate": "2021-03-04",
        "nepDate": "20.11.2077"
    },
    {
        "engDate": "2021-03-05",
        "nepDate": "21.11.2077"
    },
    {
        "engDate": "2021-03-06",
        "nepDate": "22.11.2077"
    },
    {
        "engDate": "2021-03-07",
        "nepDate": "23.11.2077"
    },
    {
        "engDate": "2021-03-08",
        "nepDate": "24.11.2077"
    },
    {
        "engDate": "2021-03-09",
        "nepDate": "25.11.2077"
    },
    {
        "engDate": "2021-03-10",
        "nepDate": "26.11.2077"
    },
    {
        "engDate": "2021-03-11",
        "nepDate": "27.11.2077"
    },
    {
        "engDate": "2021-03-12",
        "nepDate": "28.11.2077"
    },
    {
        "engDate": "2021-03-13",
        "nepDate": "29.11.2077"
    },
    {
        "engDate": "2021-03-14",
        "nepDate": "01.12.2077"
    },
    {
        "engDate": "2021-03-15",
        "nepDate": "02.12.2077"
    },
    {
        "engDate": "2021-03-16",
        "nepDate": "03.12.2077"
    },
    {
        "engDate": "2021-03-17",
        "nepDate": "04.12.2077"
    },
    {
        "engDate": "2021-03-18",
        "nepDate": "05.12.2077"
    },
    {
        "engDate": "2021-03-19",
        "nepDate": "06.12.2077"
    },
    {
        "engDate": "2021-03-20",
        "nepDate": "07.12.2077"
    },
    {
        "engDate": "2021-03-21",
        "nepDate": "08.12.2077"
    },
    {
        "engDate": "2021-03-22",
        "nepDate": "09.12.2077"
    },
    {
        "engDate": "2021-03-23",
        "nepDate": "10.12.2077"
    },
    {
        "engDate": "2021-03-24",
        "nepDate": "11.12.2077"
    },
    {
        "engDate": "2021-03-25",
        "nepDate": "12.12.2077"
    },
    {
        "engDate": "2021-03-26",
        "nepDate": "13.12.2077"
    },
    {
        "engDate": "2021-03-27",
        "nepDate": "14.12.2077"
    },
    {
        "engDate": "2021-03-28",
        "nepDate": "15.12.2077"
    },
    {
        "engDate": "2021-03-29",
        "nepDate": "16.12.2077"
    },
    {
        "engDate": "2021-03-30",
        "nepDate": "17.12.2077"
    },
    {
        "engDate": "2021-03-31",
        "nepDate": "18.12.2077"
    },
    {
        "engDate": "2021-04-01",
        "nepDate": "19.12.2077"
    },
    {
        "engDate": "2021-04-02",
        "nepDate": "20.12.2077"
    },
    {
        "engDate": "2021-04-03",
        "nepDate": "21.12.2077"
    },
    {
        "engDate": "2021-04-04",
        "nepDate": "22.12.2077"
    },
    {
        "engDate": "2021-04-05",
        "nepDate": "23.12.2077"
    },
    {
        "engDate": "2021-04-06",
        "nepDate": "24.12.2077"
    },
    {
        "engDate": "2021-04-07",
        "nepDate": "25.12.2077"
    },
    {
        "engDate": "2021-04-08",
        "nepDate": "26.12.2077"
    },
    {
        "engDate": "2021-04-09",
        "nepDate": "27.12.2077"
    },
    {
        "engDate": "2021-04-10",
        "nepDate": "28.12.2077"
    },
    {
        "engDate": "2021-04-11",
        "nepDate": "29.12.2077"
    },
    {
        "engDate": "2021-04-12",
        "nepDate": "30.12.2077"
    },
    {
        "engDate": "2021-04-13",
        "nepDate": "31.12.2077"
    },
    {
        "engDate": "2021-04-14",
        "nepDate": "01.01.2078"
    },
    {
        "engDate": "2021-04-15",
        "nepDate": "02.01.2078"
    },
    {
        "engDate": "2021-04-16",
        "nepDate": "03.01.2078"
    },
    {
        "engDate": "2021-04-17",
        "nepDate": "04.01.2078"
    },
    {
        "engDate": "2021-04-18",
        "nepDate": "05.01.2078"
    },
    {
        "engDate": "2021-04-19",
        "nepDate": "06.01.2078"
    },
    {
        "engDate": "2021-04-20",
        "nepDate": "07.01.2078"
    },
    {
        "engDate": "2021-04-21",
        "nepDate": "08.01.2078"
    },
    {
        "engDate": "2021-04-22",
        "nepDate": "09.01.2078"
    },
    {
        "engDate": "2021-04-23",
        "nepDate": "10.01.2078"
    },
    {
        "engDate": "2021-04-24",
        "nepDate": "11.01.2078"
    },
    {
        "engDate": "2021-04-25",
        "nepDate": "12.01.2078"
    },
    {
        "engDate": "2021-04-26",
        "nepDate": "13.01.2078"
    },
    {
        "engDate": "2021-04-27",
        "nepDate": "14.01.2078"
    },
    {
        "engDate": "2021-04-28",
        "nepDate": "15.01.2078"
    },
    {
        "engDate": "2021-04-29",
        "nepDate": "16.01.2078"
    },
    {
        "engDate": "2021-04-30",
        "nepDate": "17.01.2078"
    },
    {
        "engDate": "2021-05-01",
        "nepDate": "18.01.2078"
    },
    {
        "engDate": "2021-05-02",
        "nepDate": "19.01.2078"
    },
    {
        "engDate": "2021-05-03",
        "nepDate": "20.01.2078"
    },
    {
        "engDate": "2021-05-04",
        "nepDate": "21.01.2078"
    },
    {
        "engDate": "2021-05-05",
        "nepDate": "22.01.2078"
    },
    {
        "engDate": "2021-05-06",
        "nepDate": "23.01.2078"
    },
    {
        "engDate": "2021-05-07",
        "nepDate": "24.01.2078"
    },
    {
        "engDate": "2021-05-08",
        "nepDate": "25.01.2078"
    },
    {
        "engDate": "2021-05-09",
        "nepDate": "26.01.2078"
    },
    {
        "engDate": "2021-05-10",
        "nepDate": "27.01.2078"
    },
    {
        "engDate": "2021-05-11",
        "nepDate": "28.01.2078"
    },
    {
        "engDate": "2021-05-12",
        "nepDate": "29.01.2078"
    },
    {
        "engDate": "2021-05-13",
        "nepDate": "30.01.2078"
    },
    {
        "engDate": "2021-05-14",
        "nepDate": "31.01.2078"
    },
    {
        "engDate": "2021-05-15",
        "nepDate": "01.02.2078"
    },
    {
        "engDate": "2021-05-16",
        "nepDate": "02.02.2078"
    },
    {
        "engDate": "2021-05-17",
        "nepDate": "03.02.2078"
    },
    {
        "engDate": "2021-05-18",
        "nepDate": "04.02.2078"
    },
    {
        "engDate": "2021-05-19",
        "nepDate": "05.02.2078"
    },
    {
        "engDate": "2021-05-20",
        "nepDate": "06.02.2078"
    },
    {
        "engDate": "2021-05-21",
        "nepDate": "07.02.2078"
    },
    {
        "engDate": "2021-05-22",
        "nepDate": "08.02.2078"
    },
    {
        "engDate": "2021-05-23",
        "nepDate": "09.02.2078"
    },
    {
        "engDate": "2021-05-24",
        "nepDate": "10.02.2078"
    },
    {
        "engDate": "2021-05-25",
        "nepDate": "11.02.2078"
    },
    {
        "engDate": "2021-05-26",
        "nepDate": "12.02.2078"
    },
    {
        "engDate": "2021-05-27",
        "nepDate": "13.02.2078"
    },
    {
        "engDate": "2021-05-28",
        "nepDate": "14.02.2078"
    },
    {
        "engDate": "2021-05-29",
        "nepDate": "15.02.2078"
    },
    {
        "engDate": "2021-05-30",
        "nepDate": "16.02.2078"
    },
    {
        "engDate": "2021-05-31",
        "nepDate": "17.02.2078"
    },
    {
        "engDate": "2021-06-01",
        "nepDate": "18.02.2078"
    },
    {
        "engDate": "2021-06-02",
        "nepDate": "19.02.2078"
    },
    {
        "engDate": "2021-06-03",
        "nepDate": "20.02.2078"
    },
    {
        "engDate": "2021-06-04",
        "nepDate": "21.02.2078"
    },
    {
        "engDate": "2021-06-05",
        "nepDate": "22.02.2078"
    },
    {
        "engDate": "2021-06-06",
        "nepDate": "23.02.2078"
    },
    {
        "engDate": "2021-06-07",
        "nepDate": "24.02.2078"
    },
    {
        "engDate": "2021-06-08",
        "nepDate": "25.02.2078"
    },
    {
        "engDate": "2021-06-09",
        "nepDate": "26.02.2078"
    },
    {
        "engDate": "2021-06-10",
        "nepDate": "27.02.2078"
    },
    {
        "engDate": "2021-06-11",
        "nepDate": "28.02.2078"
    },
    {
        "engDate": "2021-06-12",
        "nepDate": "29.02.2078"
    },
    {
        "engDate": "2021-06-13",
        "nepDate": "30.02.2078"
    },
    {
        "engDate": "2021-06-14",
        "nepDate": "31.02.2078"
    },
    {
        "engDate": "2021-06-15",
        "nepDate": "01.03.2078"
    },
    {
        "engDate": "2021-06-16",
        "nepDate": "02.03.2078"
    },
    {
        "engDate": "2021-06-17",
        "nepDate": "03.03.2078"
    },
    {
        "engDate": "2021-06-18",
        "nepDate": "04.03.2078"
    },
    {
        "engDate": "2021-06-19",
        "nepDate": "05.03.2078"
    },
    {
        "engDate": "2021-06-20",
        "nepDate": "06.03.2078"
    },
    {
        "engDate": "2021-06-21",
        "nepDate": "07.03.2078"
    },
    {
        "engDate": "2021-06-22",
        "nepDate": "08.03.2078"
    },
    {
        "engDate": "2021-06-23",
        "nepDate": "09.03.2078"
    },
    {
        "engDate": "2021-06-24",
        "nepDate": "10.03.2078"
    },
    {
        "engDate": "2021-06-25",
        "nepDate": "11.03.2078"
    },
    {
        "engDate": "2021-06-26",
        "nepDate": "12.03.2078"
    },
    {
        "engDate": "2021-06-27",
        "nepDate": "13.03.2078"
    },
    {
        "engDate": "2021-06-28",
        "nepDate": "14.03.2078"
    },
    {
        "engDate": "2021-06-29",
        "nepDate": "15.03.2078"
    },
    {
        "engDate": "2021-06-30",
        "nepDate": "16.03.2078"
    },
    {
        "engDate": "2021-07-01",
        "nepDate": "17.03.2078"
    },
    {
        "engDate": "2021-07-02",
        "nepDate": "18.03.2078"
    },
    {
        "engDate": "2021-07-03",
        "nepDate": "19.03.2078"
    },
    {
        "engDate": "2021-07-04",
        "nepDate": "20.03.2078"
    },
    {
        "engDate": "2021-07-05",
        "nepDate": "21.03.2078"
    },
    {
        "engDate": "2021-07-06",
        "nepDate": "22.03.2078"
    },
    {
        "engDate": "2021-07-07",
        "nepDate": "23.03.2078"
    },
    {
        "engDate": "2021-07-08",
        "nepDate": "24.03.2078"
    },
    {
        "engDate": "2021-07-09",
        "nepDate": "25.03.2078"
    },
    {
        "engDate": "2021-07-10",
        "nepDate": "26.03.2078"
    },
    {
        "engDate": "2021-07-11",
        "nepDate": "27.03.2078"
    },
    {
        "engDate": "2021-07-12",
        "nepDate": "28.03.2078"
    },
    {
        "engDate": "2021-07-13",
        "nepDate": "29.03.2078"
    },
    {
        "engDate": "2021-07-14",
        "nepDate": "30.03.2078"
    },
    {
        "engDate": "2021-07-15",
        "nepDate": "31.03.2078"
    },
    {
        "engDate": "2021-07-16",
        "nepDate": "01.04.2078"
    },
    {
        "engDate": "2021-07-17",
        "nepDate": "02.04.2078"
    },
    {
        "engDate": "2021-07-18",
        "nepDate": "03.04.2078"
    },
    {
        "engDate": "2021-07-19",
        "nepDate": "04.04.2078"
    },
    {
        "engDate": "2021-07-20",
        "nepDate": "05.04.2078"
    },
    {
        "engDate": "2021-07-21",
        "nepDate": "06.04.2078"
    },
    {
        "engDate": "2021-07-22",
        "nepDate": "07.04.2078"
    },
    {
        "engDate": "2021-07-23",
        "nepDate": "08.04.2078"
    },
    {
        "engDate": "2021-07-24",
        "nepDate": "09.04.2078"
    },
    {
        "engDate": "2021-07-25",
        "nepDate": "10.04.2078"
    },
    {
        "engDate": "2021-07-26",
        "nepDate": "11.04.2078"
    },
    {
        "engDate": "2021-07-27",
        "nepDate": "12.04.2078"
    },
    {
        "engDate": "2021-07-28",
        "nepDate": "13.04.2078"
    },
    {
        "engDate": "2021-07-29",
        "nepDate": "14.04.2078"
    },
    {
        "engDate": "2021-07-30",
        "nepDate": "15.04.2078"
    },
    {
        "engDate": "2021-07-31",
        "nepDate": "16.04.2078"
    },
    {
        "engDate": "2021-08-01",
        "nepDate": "17.04.2078"
    },
    {
        "engDate": "2021-08-02",
        "nepDate": "18.04.2078"
    },
    {
        "engDate": "2021-08-03",
        "nepDate": "19.04.2078"
    },
    {
        "engDate": "2021-08-04",
        "nepDate": "20.04.2078"
    },
    {
        "engDate": "2021-08-05",
        "nepDate": "21.04.2078"
    },
    {
        "engDate": "2021-08-06",
        "nepDate": "22.04.2078"
    },
    {
        "engDate": "2021-08-07",
        "nepDate": "23.04.2078"
    },
    {
        "engDate": "2021-08-08",
        "nepDate": "24.04.2078"
    },
    {
        "engDate": "2021-08-09",
        "nepDate": "25.04.2078"
    },
    {
        "engDate": "2021-08-10",
        "nepDate": "26.04.2078"
    },
    {
        "engDate": "2021-08-11",
        "nepDate": "27.04.2078"
    },
    {
        "engDate": "2021-08-12",
        "nepDate": "28.04.2078"
    },
    {
        "engDate": "2021-08-13",
        "nepDate": "29.04.2078"
    },
    {
        "engDate": "2021-08-14",
        "nepDate": "30.04.2078"
    },
    {
        "engDate": "2021-08-15",
        "nepDate": "31.04.2078"
    },
    {
        "engDate": "2021-08-16",
        "nepDate": "32.04.2078"
    },
    {
        "engDate": "2021-08-17",
        "nepDate": "01.05.2078"
    },
    {
        "engDate": "2021-08-18",
        "nepDate": "02.05.2078"
    },
    {
        "engDate": "2021-08-19",
        "nepDate": "03.05.2078"
    },
    {
        "engDate": "2021-08-20",
        "nepDate": "04.05.2078"
    },
    {
        "engDate": "2021-08-21",
        "nepDate": "05.05.2078"
    },
    {
        "engDate": "2021-08-22",
        "nepDate": "06.05.2078"
    },
    {
        "engDate": "2021-08-23",
        "nepDate": "07.05.2078"
    },
    {
        "engDate": "2021-08-24",
        "nepDate": "08.05.2078"
    },
    {
        "engDate": "2021-08-25",
        "nepDate": "09.05.2078"
    },
    {
        "engDate": "2021-08-26",
        "nepDate": "10.05.2078"
    },
    {
        "engDate": "2021-08-27",
        "nepDate": "11.05.2078"
    },
    {
        "engDate": "2021-08-28",
        "nepDate": "12.05.2078"
    },
    {
        "engDate": "2021-08-29",
        "nepDate": "13.05.2078"
    },
    {
        "engDate": "2021-08-30",
        "nepDate": "14.05.2078"
    },
    {
        "engDate": "2021-08-31",
        "nepDate": "15.05.2078"
    },
    {
        "engDate": "2021-09-01",
        "nepDate": "16.05.2078"
    },
    {
        "engDate": "2021-09-02",
        "nepDate": "17.05.2078"
    },
    {
        "engDate": "2021-09-03",
        "nepDate": "18.05.2078"
    },
    {
        "engDate": "2021-09-04",
        "nepDate": "19.05.2078"
    },
    {
        "engDate": "2021-09-05",
        "nepDate": "20.05.2078"
    },
    {
        "engDate": "2021-09-06",
        "nepDate": "21.05.2078"
    },
    {
        "engDate": "2021-09-07",
        "nepDate": "22.05.2078"
    },
    {
        "engDate": "2021-09-08",
        "nepDate": "23.05.2078"
    },
    {
        "engDate": "2021-09-09",
        "nepDate": "24.05.2078"
    },
    {
        "engDate": "2021-09-10",
        "nepDate": "25.05.2078"
    },
    {
        "engDate": "2021-09-11",
        "nepDate": "26.05.2078"
    },
    {
        "engDate": "2021-09-12",
        "nepDate": "27.05.2078"
    },
    {
        "engDate": "2021-09-13",
        "nepDate": "28.05.2078"
    },
    {
        "engDate": "2021-09-14",
        "nepDate": "29.05.2078"
    },
    {
        "engDate": "2021-09-15",
        "nepDate": "30.05.2078"
    },
    {
        "engDate": "2021-09-16",
        "nepDate": "31.05.2078"
    },
    {
        "engDate": "2021-09-17",
        "nepDate": "01.06.2078"
    },
    {
        "engDate": "2021-09-18",
        "nepDate": "02.06.2078"
    },
    {
        "engDate": "2021-09-19",
        "nepDate": "03.06.2078"
    },
    {
        "engDate": "2021-09-20",
        "nepDate": "04.06.2078"
    },
    {
        "engDate": "2021-09-21",
        "nepDate": "05.06.2078"
    },
    {
        "engDate": "2021-09-22",
        "nepDate": "06.06.2078"
    },
    {
        "engDate": "2021-09-23",
        "nepDate": "07.06.2078"
    },
    {
        "engDate": "2021-09-24",
        "nepDate": "08.06.2078"
    },
    {
        "engDate": "2021-09-25",
        "nepDate": "09.06.2078"
    },
    {
        "engDate": "2021-09-26",
        "nepDate": "10.06.2078"
    },
    {
        "engDate": "2021-09-27",
        "nepDate": "11.06.2078"
    },
    {
        "engDate": "2021-09-28",
        "nepDate": "12.06.2078"
    },
    {
        "engDate": "2021-09-29",
        "nepDate": "13.06.2078"
    },
    {
        "engDate": "2021-09-30",
        "nepDate": "14.06.2078"
    },
    {
        "engDate": "2021-10-01",
        "nepDate": "15.06.2078"
    },
    {
        "engDate": "2021-10-02",
        "nepDate": "16.06.2078"
    },
    {
        "engDate": "2021-10-03",
        "nepDate": "17.06.2078"
    },
    {
        "engDate": "2021-10-04",
        "nepDate": "18.06.2078"
    },
    {
        "engDate": "2021-10-05",
        "nepDate": "19.06.2078"
    },
    {
        "engDate": "2021-10-06",
        "nepDate": "20.06.2078"
    },
    {
        "engDate": "2021-10-07",
        "nepDate": "21.06.2078"
    },
    {
        "engDate": "2021-10-08",
        "nepDate": "22.06.2078"
    },
    {
        "engDate": "2021-10-09",
        "nepDate": "23.06.2078"
    },
    {
        "engDate": "2021-10-10",
        "nepDate": "24.06.2078"
    },
    {
        "engDate": "2021-10-11",
        "nepDate": "25.06.2078"
    },
    {
        "engDate": "2021-10-12",
        "nepDate": "26.06.2078"
    },
    {
        "engDate": "2021-10-13",
        "nepDate": "27.06.2078"
    },
    {
        "engDate": "2021-10-14",
        "nepDate": "28.06.2078"
    },
    {
        "engDate": "2021-10-15",
        "nepDate": "29.06.2078"
    },
    {
        "engDate": "2021-10-16",
        "nepDate": "30.06.2078"
    },
    {
        "engDate": "2021-10-17",
        "nepDate": "31.06.2078"
    },
    {
        "engDate": "2021-10-18",
        "nepDate": "01.07.2078"
    },
    {
        "engDate": "2021-10-19",
        "nepDate": "02.07.2078"
    },
    {
        "engDate": "2021-10-20",
        "nepDate": "03.07.2078"
    },
    {
        "engDate": "2021-10-21",
        "nepDate": "04.07.2078"
    },
    {
        "engDate": "2021-10-22",
        "nepDate": "05.07.2078"
    },
    {
        "engDate": "2021-10-23",
        "nepDate": "06.07.2078"
    },
    {
        "engDate": "2021-10-24",
        "nepDate": "07.07.2078"
    },
    {
        "engDate": "2021-10-25",
        "nepDate": "08.07.2078"
    },
    {
        "engDate": "2021-10-26",
        "nepDate": "09.07.2078"
    },
    {
        "engDate": "2021-10-27",
        "nepDate": "10.07.2078"
    },
    {
        "engDate": "2021-10-28",
        "nepDate": "11.07.2078"
    },
    {
        "engDate": "2021-10-29",
        "nepDate": "12.07.2078"
    },
    {
        "engDate": "2021-10-30",
        "nepDate": "13.07.2078"
    },
    {
        "engDate": "2021-10-31",
        "nepDate": "14.07.2078"
    },
    {
        "engDate": "2021-11-01",
        "nepDate": "15.07.2078"
    },
    {
        "engDate": "2021-11-02",
        "nepDate": "16.07.2078"
    },
    {
        "engDate": "2021-11-03",
        "nepDate": "17.07.2078"
    },
    {
        "engDate": "2021-11-04",
        "nepDate": "18.07.2078"
    },
    {
        "engDate": "2021-11-05",
        "nepDate": "19.07.2078"
    },
    {
        "engDate": "2021-11-06",
        "nepDate": "20.07.2078"
    },
    {
        "engDate": "2021-11-07",
        "nepDate": "21.07.2078"
    },
    {
        "engDate": "2021-11-08",
        "nepDate": "22.07.2078"
    },
    {
        "engDate": "2021-11-09",
        "nepDate": "23.07.2078"
    },
    {
        "engDate": "2021-11-10",
        "nepDate": "24.07.2078"
    },
    {
        "engDate": "2021-11-11",
        "nepDate": "25.07.2078"
    },
    {
        "engDate": "2021-11-12",
        "nepDate": "26.07.2078"
    },
    {
        "engDate": "2021-11-13",
        "nepDate": "27.07.2078"
    },
    {
        "engDate": "2021-11-14",
        "nepDate": "28.07.2078"
    },
    {
        "engDate": "2021-11-15",
        "nepDate": "29.07.2078"
    },
    {
        "engDate": "2021-11-16",
        "nepDate": "30.07.2078"
    },
    {
        "engDate": "2021-11-17",
        "nepDate": "01.08.2078"
    },
    {
        "engDate": "2021-11-18",
        "nepDate": "02.08.2078"
    },
    {
        "engDate": "2021-11-19",
        "nepDate": "03.08.2078"
    },
    {
        "engDate": "2021-11-20",
        "nepDate": "04.08.2078"
    },
    {
        "engDate": "2021-11-21",
        "nepDate": "05.08.2078"
    },
    {
        "engDate": "2021-11-22",
        "nepDate": "06.08.2078"
    },
    {
        "engDate": "2021-11-23",
        "nepDate": "07.08.2078"
    },
    {
        "engDate": "2021-11-24",
        "nepDate": "08.08.2078"
    },
    {
        "engDate": "2021-11-25",
        "nepDate": "09.08.2078"
    },
    {
        "engDate": "2021-11-26",
        "nepDate": "10.08.2078"
    },
    {
        "engDate": "2021-11-27",
        "nepDate": "11.08.2078"
    },
    {
        "engDate": "2021-11-28",
        "nepDate": "12.08.2078"
    },
    {
        "engDate": "2021-11-29",
        "nepDate": "13.08.2078"
    },
    {
        "engDate": "2021-11-30",
        "nepDate": "14.08.2078"
    },
    {
        "engDate": "2021-12-01",
        "nepDate": "15.08.2078"
    },
    {
        "engDate": "2021-12-02",
        "nepDate": "16.08.2078"
    },
    {
        "engDate": "2021-12-03",
        "nepDate": "17.08.2078"
    },
    {
        "engDate": "2021-12-04",
        "nepDate": "18.08.2078"
    },
    {
        "engDate": "2021-12-05",
        "nepDate": "19.08.2078"
    },
    {
        "engDate": "2021-12-06",
        "nepDate": "20.08.2078"
    },
    {
        "engDate": "2021-12-07",
        "nepDate": "21.08.2078"
    },
    {
        "engDate": "2021-12-08",
        "nepDate": "22.08.2078"
    },
    {
        "engDate": "2021-12-09",
        "nepDate": "23.08.2078"
    },
    {
        "engDate": "2021-12-10",
        "nepDate": "24.08.2078"
    },
    {
        "engDate": "2021-12-11",
        "nepDate": "25.08.2078"
    },
    {
        "engDate": "2021-12-12",
        "nepDate": "26.08.2078"
    },
    {
        "engDate": "2021-12-13",
        "nepDate": "27.08.2078"
    },
    {
        "engDate": "2021-12-14",
        "nepDate": "28.08.2078"
    },
    {
        "engDate": "2021-12-15",
        "nepDate": "29.08.2078"
    },
    {
        "engDate": "2021-12-16",
        "nepDate": "01.09.2078"
    },
    {
        "engDate": "2021-12-17",
        "nepDate": "02.09.2078"
    },
    {
        "engDate": "2021-12-18",
        "nepDate": "03.09.2078"
    },
    {
        "engDate": "2021-12-19",
        "nepDate": "04.09.2078"
    },
    {
        "engDate": "2021-12-20",
        "nepDate": "05.09.2078"
    },
    {
        "engDate": "2021-12-21",
        "nepDate": "06.09.2078"
    },
    {
        "engDate": "2021-12-22",
        "nepDate": "07.09.2078"
    },
    {
        "engDate": "2021-12-23",
        "nepDate": "08.09.2078"
    },
    {
        "engDate": "2021-12-24",
        "nepDate": "09.09.2078"
    },
    {
        "engDate": "2021-12-25",
        "nepDate": "10.09.2078"
    },
    {
        "engDate": "2021-12-26",
        "nepDate": "11.09.2078"
    },
    {
        "engDate": "2021-12-27",
        "nepDate": "12.09.2078"
    },
    {
        "engDate": "2021-12-28",
        "nepDate": "13.09.2078"
    },
    {
        "engDate": "2021-12-29",
        "nepDate": "14.09.2078"
    },
    {
        "engDate": "2021-12-30",
        "nepDate": "15.09.2078"
    },
    {
        "engDate": "2021-12-31",
        "nepDate": "16.09.2078"
    },
    {
        "engDate": "2022-01-01",
        "nepDate": "17.09.2078"
    },
    {
        "engDate": "2022-01-02",
        "nepDate": "18.09.2078"
    },
    {
        "engDate": "2022-01-03",
        "nepDate": "19.09.2078"
    },
    {
        "engDate": "2022-01-04",
        "nepDate": "20.09.2078"
    },
    {
        "engDate": "2022-01-05",
        "nepDate": "21.09.2078"
    },
    {
        "engDate": "2022-01-06",
        "nepDate": "22.09.2078"
    },
    {
        "engDate": "2022-01-07",
        "nepDate": "23.09.2078"
    },
    {
        "engDate": "2022-01-08",
        "nepDate": "24.09.2078"
    },
    {
        "engDate": "2022-01-09",
        "nepDate": "25.09.2078"
    },
    {
        "engDate": "2022-01-10",
        "nepDate": "26.09.2078"
    },
    {
        "engDate": "2022-01-11",
        "nepDate": "27.09.2078"
    },
    {
        "engDate": "2022-01-12",
        "nepDate": "28.09.2078"
    },
    {
        "engDate": "2022-01-13",
        "nepDate": "29.09.2078"
    },
    {
        "engDate": "2022-01-14",
        "nepDate": "30.09.2078"
    },
    {
        "engDate": "2022-01-15",
        "nepDate": "01.10.2078"
    },
    {
        "engDate": "2022-01-16",
        "nepDate": "02.10.2078"
    },
    {
        "engDate": "2022-01-17",
        "nepDate": "03.10.2078"
    },
    {
        "engDate": "2022-01-18",
        "nepDate": "04.10.2078"
    },
    {
        "engDate": "2022-01-19",
        "nepDate": "05.10.2078"
    },
    {
        "engDate": "2022-01-20",
        "nepDate": "06.10.2078"
    },
    {
        "engDate": "2022-01-21",
        "nepDate": "07.10.2078"
    },
    {
        "engDate": "2022-01-22",
        "nepDate": "08.10.2078"
    },
    {
        "engDate": "2022-01-23",
        "nepDate": "09.10.2078"
    },
    {
        "engDate": "2022-01-24",
        "nepDate": "10.10.2078"
    },
    {
        "engDate": "2022-01-25",
        "nepDate": "11.10.2078"
    },
    {
        "engDate": "2022-01-26",
        "nepDate": "12.10.2078"
    },
    {
        "engDate": "2022-01-27",
        "nepDate": "13.10.2078"
    },
    {
        "engDate": "2022-01-28",
        "nepDate": "14.10.2078"
    },
    {
        "engDate": "2022-01-29",
        "nepDate": "15.10.2078"
    },
    {
        "engDate": "2022-01-30",
        "nepDate": "16.10.2078"
    },
    {
        "engDate": "2022-01-31",
        "nepDate": "17.10.2078"
    },
    {
        "engDate": "2022-02-01",
        "nepDate": "18.10.2078"
    },
    {
        "engDate": "2022-02-02",
        "nepDate": "19.10.2078"
    },
    {
        "engDate": "2022-02-03",
        "nepDate": "20.10.2078"
    },
    {
        "engDate": "2022-02-04",
        "nepDate": "21.10.2078"
    },
    {
        "engDate": "2022-02-05",
        "nepDate": "22.10.2078"
    },
    {
        "engDate": "2022-02-06",
        "nepDate": "23.10.2078"
    },
    {
        "engDate": "2022-02-07",
        "nepDate": "24.10.2078"
    },
    {
        "engDate": "2022-02-08",
        "nepDate": "25.10.2078"
    },
    {
        "engDate": "2022-02-09",
        "nepDate": "26.10.2078"
    },
    {
        "engDate": "2022-02-10",
        "nepDate": "27.10.2078"
    },
    {
        "engDate": "2022-02-11",
        "nepDate": "28.10.2078"
    },
    {
        "engDate": "2022-02-12",
        "nepDate": "29.10.2078"
    },
    {
        "engDate": "2022-02-13",
        "nepDate": "01.11.2078"
    },
    {
        "engDate": "2022-02-14",
        "nepDate": "02.11.2078"
    },
    {
        "engDate": "2022-02-15",
        "nepDate": "03.11.2078"
    },
    {
        "engDate": "2022-02-16",
        "nepDate": "04.11.2078"
    },
    {
        "engDate": "2022-02-17",
        "nepDate": "05.11.2078"
    },
    {
        "engDate": "2022-02-18",
        "nepDate": "06.11.2078"
    },
    {
        "engDate": "2022-02-19",
        "nepDate": "07.11.2078"
    },
    {
        "engDate": "2022-02-20",
        "nepDate": "08.11.2078"
    },
    {
        "engDate": "2022-02-21",
        "nepDate": "09.11.2078"
    },
    {
        "engDate": "2022-02-22",
        "nepDate": "10.11.2078"
    },
    {
        "engDate": "2022-02-23",
        "nepDate": "11.11.2078"
    },
    {
        "engDate": "2022-02-24",
        "nepDate": "12.11.2078"
    },
    {
        "engDate": "2022-02-25",
        "nepDate": "13.11.2078"
    },
    {
        "engDate": "2022-02-26",
        "nepDate": "14.11.2078"
    },
    {
        "engDate": "2022-02-27",
        "nepDate": "15.11.2078"
    },
    {
        "engDate": "2022-02-28",
        "nepDate": "16.11.2078"
    },
    {
        "engDate": "2022-03-01",
        "nepDate": "17.11.2078"
    },
    {
        "engDate": "2022-03-02",
        "nepDate": "18.11.2078"
    },
    {
        "engDate": "2022-03-03",
        "nepDate": "19.11.2078"
    },
    {
        "engDate": "2022-03-04",
        "nepDate": "20.11.2078"
    },
    {
        "engDate": "2022-03-05",
        "nepDate": "21.11.2078"
    },
    {
        "engDate": "2022-03-06",
        "nepDate": "22.11.2078"
    },
    {
        "engDate": "2022-03-07",
        "nepDate": "23.11.2078"
    },
    {
        "engDate": "2022-03-08",
        "nepDate": "24.11.2078"
    },
    {
        "engDate": "2022-03-09",
        "nepDate": "25.11.2078"
    },
    {
        "engDate": "2022-03-10",
        "nepDate": "26.11.2078"
    },
    {
        "engDate": "2022-03-11",
        "nepDate": "27.11.2078"
    },
    {
        "engDate": "2022-03-12",
        "nepDate": "28.11.2078"
    },
    {
        "engDate": "2022-03-13",
        "nepDate": "29.11.2078"
    },
    {
        "engDate": "2022-03-14",
        "nepDate": "30.11.2078"
    },
    {
        "engDate": "2022-03-15",
        "nepDate": "01.12.2078"
    },
    {
        "engDate": "2022-03-16",
        "nepDate": "02.12.2078"
    },
    {
        "engDate": "2022-03-17",
        "nepDate": "03.12.2078"
    },
    {
        "engDate": "2022-03-18",
        "nepDate": "04.12.2078"
    },
    {
        "engDate": "2022-03-19",
        "nepDate": "05.12.2078"
    },
    {
        "engDate": "2022-03-20",
        "nepDate": "06.12.2078"
    },
    {
        "engDate": "2022-03-21",
        "nepDate": "07.12.2078"
    },
    {
        "engDate": "2022-03-22",
        "nepDate": "08.12.2078"
    },
    {
        "engDate": "2022-03-23",
        "nepDate": "09.12.2078"
    },
    {
        "engDate": "2022-03-24",
        "nepDate": "10.12.2078"
    },
    {
        "engDate": "2022-03-25",
        "nepDate": "11.12.2078"
    },
    {
        "engDate": "2022-03-26",
        "nepDate": "12.12.2078"
    },
    {
        "engDate": "2022-03-27",
        "nepDate": "13.12.2078"
    },
    {
        "engDate": "2022-03-28",
        "nepDate": "14.12.2078"
    },
    {
        "engDate": "2022-03-29",
        "nepDate": "15.12.2078"
    },
    {
        "engDate": "2022-03-30",
        "nepDate": "16.12.2078"
    },
    {
        "engDate": "2022-03-31",
        "nepDate": "17.12.2078"
    },
    {
        "engDate": "2022-04-01",
        "nepDate": "18.12.2078"
    },
    {
        "engDate": "2022-04-02",
        "nepDate": "19.12.2078"
    },
    {
        "engDate": "2022-04-03",
        "nepDate": "20.12.2078"
    },
    {
        "engDate": "2022-04-04",
        "nepDate": "21.12.2078"
    },
    {
        "engDate": "2022-04-05",
        "nepDate": "22.12.2078"
    },
    {
        "engDate": "2022-04-06",
        "nepDate": "23.12.2078"
    },
    {
        "engDate": "2022-04-07",
        "nepDate": "24.12.2078"
    },
    {
        "engDate": "2022-04-08",
        "nepDate": "25.12.2078"
    },
    {
        "engDate": "2022-04-09",
        "nepDate": "26.12.2078"
    },
    {
        "engDate": "2022-04-10",
        "nepDate": "27.12.2078"
    },
    {
        "engDate": "2022-04-11",
        "nepDate": "28.12.2078"
    },
    {
        "engDate": "2022-04-12",
        "nepDate": "29.12.2078"
    },
    {
        "engDate": "2022-04-13",
        "nepDate": "30.12.2078"
    },
    {
        "engDate": "2022-04-14",
        "nepDate": "01.01.2079"
    },
    {
        "engDate": "2022-04-15",
        "nepDate": "02.01.2079"
    },
    {
        "engDate": "2022-04-16",
        "nepDate": "03.01.2079"
    },
    {
        "engDate": "2022-04-17",
        "nepDate": "04.01.2079"
    },
    {
        "engDate": "2022-04-18",
        "nepDate": "05.01.2079"
    },
    {
        "engDate": "2022-04-19",
        "nepDate": "06.01.2079"
    },
    {
        "engDate": "2022-04-20",
        "nepDate": "07.01.2079"
    },
    {
        "engDate": "2022-04-21",
        "nepDate": "08.01.2079"
    },
    {
        "engDate": "2022-04-22",
        "nepDate": "09.01.2079"
    },
    {
        "engDate": "2022-04-23",
        "nepDate": "10.01.2079"
    },
    {
        "engDate": "2022-04-24",
        "nepDate": "11.01.2079"
    },
    {
        "engDate": "2022-04-25",
        "nepDate": "12.01.2079"
    },
    {
        "engDate": "2022-04-26",
        "nepDate": "13.01.2079"
    },
    {
        "engDate": "2022-04-27",
        "nepDate": "14.01.2079"
    },
    {
        "engDate": "2022-04-28",
        "nepDate": "15.01.2079"
    },
    {
        "engDate": "2022-04-29",
        "nepDate": "16.01.2079"
    },
    {
        "engDate": "2022-04-30",
        "nepDate": "17.01.2079"
    },
    {
        "engDate": "2022-05-01",
        "nepDate": "18.01.2079"
    },
    {
        "engDate": "2022-05-02",
        "nepDate": "19.01.2079"
    },
    {
        "engDate": "2022-05-03",
        "nepDate": "20.01.2079"
    },
    {
        "engDate": "2022-05-04",
        "nepDate": "21.01.2079"
    },
    {
        "engDate": "2022-05-05",
        "nepDate": "22.01.2079"
    },
    {
        "engDate": "2022-05-06",
        "nepDate": "23.01.2079"
    },
    {
        "engDate": "2022-05-07",
        "nepDate": "24.01.2079"
    },
    {
        "engDate": "2022-05-08",
        "nepDate": "25.01.2079"
    },
    {
        "engDate": "2022-05-09",
        "nepDate": "26.01.2079"
    },
    {
        "engDate": "2022-05-10",
        "nepDate": "27.01.2079"
    },
    {
        "engDate": "2022-05-11",
        "nepDate": "28.01.2079"
    },
    {
        "engDate": "2022-05-12",
        "nepDate": "29.01.2079"
    },
    {
        "engDate": "2022-05-13",
        "nepDate": "30.01.2079"
    },
    {
        "engDate": "2022-05-14",
        "nepDate": "31.01.2079"
    },
    {
        "engDate": "2022-05-15",
        "nepDate": "01.02.2079"
    },
    {
        "engDate": "2022-05-16",
        "nepDate": "02.02.2079"
    },
    {
        "engDate": "2022-05-17",
        "nepDate": "03.02.2079"
    },
    {
        "engDate": "2022-05-18",
        "nepDate": "04.02.2079"
    },
    {
        "engDate": "2022-05-19",
        "nepDate": "05.02.2079"
    },
    {
        "engDate": "2022-05-20",
        "nepDate": "06.02.2079"
    },
    {
        "engDate": "2022-05-21",
        "nepDate": "07.02.2079"
    },
    {
        "engDate": "2022-05-22",
        "nepDate": "08.02.2079"
    },
    {
        "engDate": "2022-05-23",
        "nepDate": "09.02.2079"
    },
    {
        "engDate": "2022-05-24",
        "nepDate": "10.02.2079"
    },
    {
        "engDate": "2022-05-25",
        "nepDate": "11.02.2079"
    },
    {
        "engDate": "2022-05-26",
        "nepDate": "12.02.2079"
    },
    {
        "engDate": "2022-05-27",
        "nepDate": "13.02.2079"
    },
    {
        "engDate": "2022-05-28",
        "nepDate": "14.02.2079"
    },
    {
        "engDate": "2022-05-29",
        "nepDate": "15.02.2079"
    },
    {
        "engDate": "2022-05-30",
        "nepDate": "16.02.2079"
    },
    {
        "engDate": "2022-05-31",
        "nepDate": "17.02.2079"
    },
    {
        "engDate": "2022-06-01",
        "nepDate": "18.02.2079"
    },
    {
        "engDate": "2022-06-02",
        "nepDate": "19.02.2079"
    },
    {
        "engDate": "2022-06-03",
        "nepDate": "20.02.2079"
    },
    {
        "engDate": "2022-06-04",
        "nepDate": "21.02.2079"
    },
    {
        "engDate": "2022-06-05",
        "nepDate": "22.02.2079"
    },
    {
        "engDate": "2022-06-06",
        "nepDate": "23.02.2079"
    },
    {
        "engDate": "2022-06-07",
        "nepDate": "24.02.2079"
    },
    {
        "engDate": "2022-06-08",
        "nepDate": "25.02.2079"
    },
    {
        "engDate": "2022-06-09",
        "nepDate": "26.02.2079"
    },
    {
        "engDate": "2022-06-10",
        "nepDate": "27.02.2079"
    },
    {
        "engDate": "2022-06-11",
        "nepDate": "28.02.2079"
    },
    {
        "engDate": "2022-06-12",
        "nepDate": "29.02.2079"
    },
    {
        "engDate": "2022-06-13",
        "nepDate": "30.02.2079"
    },
    {
        "engDate": "2022-06-14",
        "nepDate": "31.02.2079"
    },
    {
        "engDate": "2022-06-15",
        "nepDate": "01.03.2079"
    },
    {
        "engDate": "2022-06-16",
        "nepDate": "02.03.2079"
    },
    {
        "engDate": "2022-06-17",
        "nepDate": "03.03.2079"
    },
    {
        "engDate": "2022-06-18",
        "nepDate": "04.03.2079"
    },
    {
        "engDate": "2022-06-19",
        "nepDate": "05.03.2079"
    },
    {
        "engDate": "2022-06-20",
        "nepDate": "06.03.2079"
    },
    {
        "engDate": "2022-06-21",
        "nepDate": "07.03.2079"
    },
    {
        "engDate": "2022-06-22",
        "nepDate": "08.03.2079"
    },
    {
        "engDate": "2022-06-23",
        "nepDate": "09.03.2079"
    },
    {
        "engDate": "2022-06-24",
        "nepDate": "10.03.2079"
    },
    {
        "engDate": "2022-06-25",
        "nepDate": "11.03.2079"
    },
    {
        "engDate": "2022-06-26",
        "nepDate": "12.03.2079"
    },
    {
        "engDate": "2022-06-27",
        "nepDate": "13.03.2079"
    },
    {
        "engDate": "2022-06-28",
        "nepDate": "14.03.2079"
    },
    {
        "engDate": "2022-06-29",
        "nepDate": "15.03.2079"
    },
    {
        "engDate": "2022-06-30",
        "nepDate": "16.03.2079"
    },
    {
        "engDate": "2022-07-01",
        "nepDate": "17.03.2079"
    },
    {
        "engDate": "2022-07-02",
        "nepDate": "18.03.2079"
    },
    {
        "engDate": "2022-07-03",
        "nepDate": "19.03.2079"
    },
    {
        "engDate": "2022-07-04",
        "nepDate": "20.03.2079"
    },
    {
        "engDate": "2022-07-05",
        "nepDate": "21.03.2079"
    },
    {
        "engDate": "2022-07-06",
        "nepDate": "22.03.2079"
    },
    {
        "engDate": "2022-07-07",
        "nepDate": "23.03.2079"
    },
    {
        "engDate": "2022-07-08",
        "nepDate": "24.03.2079"
    },
    {
        "engDate": "2022-07-09",
        "nepDate": "25.03.2079"
    },
    {
        "engDate": "2022-07-10",
        "nepDate": "26.03.2079"
    },
    {
        "engDate": "2022-07-11",
        "nepDate": "27.03.2079"
    },
    {
        "engDate": "2022-07-12",
        "nepDate": "28.03.2079"
    },
    {
        "engDate": "2022-07-13",
        "nepDate": "29.03.2079"
    },
    {
        "engDate": "2022-07-14",
        "nepDate": "30.03.2079"
    },
    {
        "engDate": "2022-07-15",
        "nepDate": "31.03.2079"
    },
    {
        "engDate": "2022-07-16",
        "nepDate": "32.03.2079"
    },
    {
        "engDate": "2022-07-17",
        "nepDate": "01.04.2079"
    },
    {
        "engDate": "2022-07-18",
        "nepDate": "02.04.2079"
    },
    {
        "engDate": "2022-07-19",
        "nepDate": "03.04.2079"
    },
    {
        "engDate": "2022-07-20",
        "nepDate": "04.04.2079"
    },
    {
        "engDate": "2022-07-21",
        "nepDate": "05.04.2079"
    },
    {
        "engDate": "2022-07-22",
        "nepDate": "06.04.2079"
    },
    {
        "engDate": "2022-07-23",
        "nepDate": "07.04.2079"
    },
    {
        "engDate": "2022-07-24",
        "nepDate": "08.04.2079"
    },
    {
        "engDate": "2022-07-25",
        "nepDate": "09.04.2079"
    },
    {
        "engDate": "2022-07-26",
        "nepDate": "10.04.2079"
    },
    {
        "engDate": "2022-07-27",
        "nepDate": "11.04.2079"
    },
    {
        "engDate": "2022-07-28",
        "nepDate": "12.04.2079"
    },
    {
        "engDate": "2022-07-29",
        "nepDate": "13.04.2079"
    },
    {
        "engDate": "2022-07-30",
        "nepDate": "14.04.2079"
    },
    {
        "engDate": "2022-07-31",
        "nepDate": "15.04.2079"
    },
    {
        "engDate": "2022-08-01",
        "nepDate": "16.04.2079"
    },
    {
        "engDate": "2022-08-02",
        "nepDate": "17.04.2079"
    },
    {
        "engDate": "2022-08-03",
        "nepDate": "18.04.2079"
    },
    {
        "engDate": "2022-08-04",
        "nepDate": "19.04.2079"
    },
    {
        "engDate": "2022-08-05",
        "nepDate": "20.04.2079"
    },
    {
        "engDate": "2022-08-06",
        "nepDate": "21.04.2079"
    },
    {
        "engDate": "2022-08-07",
        "nepDate": "22.04.2079"
    },
    {
        "engDate": "2022-08-08",
        "nepDate": "23.04.2079"
    },
    {
        "engDate": "2022-08-09",
        "nepDate": "24.04.2079"
    },
    {
        "engDate": "2022-08-10",
        "nepDate": "25.04.2079"
    },
    {
        "engDate": "2022-08-11",
        "nepDate": "26.04.2079"
    },
    {
        "engDate": "2022-08-12",
        "nepDate": "27.04.2079"
    },
    {
        "engDate": "2022-08-13",
        "nepDate": "28.04.2079"
    },
    {
        "engDate": "2022-08-14",
        "nepDate": "29.04.2079"
    },
    {
        "engDate": "2022-08-15",
        "nepDate": "30.04.2079"
    },
    {
        "engDate": "2022-08-16",
        "nepDate": "31.04.2079"
    },
    {
        "engDate": "2022-08-17",
        "nepDate": "01.05.2079"
    },
    {
        "engDate": "2022-08-18",
        "nepDate": "02.05.2079"
    },
    {
        "engDate": "2022-08-19",
        "nepDate": "03.05.2079"
    },
    {
        "engDate": "2022-08-20",
        "nepDate": "04.05.2079"
    },
    {
        "engDate": "2022-08-21",
        "nepDate": "05.05.2079"
    },
    {
        "engDate": "2022-08-22",
        "nepDate": "06.05.2079"
    },
    {
        "engDate": "2022-08-23",
        "nepDate": "07.05.2079"
    },
    {
        "engDate": "2022-08-24",
        "nepDate": "08.05.2079"
    },
    {
        "engDate": "2022-08-25",
        "nepDate": "09.05.2079"
    },
    {
        "engDate": "2022-08-26",
        "nepDate": "10.05.2079"
    },
    {
        "engDate": "2022-08-27",
        "nepDate": "11.05.2079"
    },
    {
        "engDate": "2022-08-28",
        "nepDate": "12.05.2079"
    },
    {
        "engDate": "2022-08-29",
        "nepDate": "13.05.2079"
    },
    {
        "engDate": "2022-08-30",
        "nepDate": "14.05.2079"
    },
    {
        "engDate": "2022-08-31",
        "nepDate": "15.05.2079"
    },
    {
        "engDate": "2022-09-01",
        "nepDate": "16.05.2079"
    },
    {
        "engDate": "2022-09-02",
        "nepDate": "17.05.2079"
    },
    {
        "engDate": "2022-09-03",
        "nepDate": "18.05.2079"
    },
    {
        "engDate": "2022-09-04",
        "nepDate": "19.05.2079"
    },
    {
        "engDate": "2022-09-05",
        "nepDate": "20.05.2079"
    },
    {
        "engDate": "2022-09-06",
        "nepDate": "21.05.2079"
    },
    {
        "engDate": "2022-09-07",
        "nepDate": "22.05.2079"
    },
    {
        "engDate": "2022-09-08",
        "nepDate": "23.05.2079"
    },
    {
        "engDate": "2022-09-09",
        "nepDate": "24.05.2079"
    },
    {
        "engDate": "2022-09-10",
        "nepDate": "25.05.2079"
    },
    {
        "engDate": "2022-09-11",
        "nepDate": "26.05.2079"
    },
    {
        "engDate": "2022-09-12",
        "nepDate": "27.05.2079"
    },
    {
        "engDate": "2022-09-13",
        "nepDate": "28.05.2079"
    },
    {
        "engDate": "2022-09-14",
        "nepDate": "29.05.2079"
    },
    {
        "engDate": "2022-09-15",
        "nepDate": "30.05.2079"
    },
    {
        "engDate": "2022-09-16",
        "nepDate": "31.05.2079"
    },
    {
        "engDate": "2022-09-17",
        "nepDate": "01.06.2079"
    },
    {
        "engDate": "2022-09-18",
        "nepDate": "02.06.2079"
    },
    {
        "engDate": "2022-09-19",
        "nepDate": "03.06.2079"
    },
    {
        "engDate": "2022-09-20",
        "nepDate": "04.06.2079"
    },
    {
        "engDate": "2022-09-21",
        "nepDate": "05.06.2079"
    },
    {
        "engDate": "2022-09-22",
        "nepDate": "06.06.2079"
    },
    {
        "engDate": "2022-09-23",
        "nepDate": "07.06.2079"
    },
    {
        "engDate": "2022-09-24",
        "nepDate": "08.06.2079"
    },
    {
        "engDate": "2022-09-25",
        "nepDate": "09.06.2079"
    },
    {
        "engDate": "2022-09-26",
        "nepDate": "10.06.2079"
    },
    {
        "engDate": "2022-09-27",
        "nepDate": "11.06.2079"
    },
    {
        "engDate": "2022-09-28",
        "nepDate": "12.06.2079"
    },
    {
        "engDate": "2022-09-29",
        "nepDate": "13.06.2079"
    },
    {
        "engDate": "2022-09-30",
        "nepDate": "14.06.2079"
    },
    {
        "engDate": "2022-10-01",
        "nepDate": "15.06.2079"
    },
    {
        "engDate": "2022-10-02",
        "nepDate": "16.06.2079"
    },
    {
        "engDate": "2022-10-03",
        "nepDate": "17.06.2079"
    },
    {
        "engDate": "2022-10-04",
        "nepDate": "18.06.2079"
    },
    {
        "engDate": "2022-10-05",
        "nepDate": "19.06.2079"
    },
    {
        "engDate": "2022-10-06",
        "nepDate": "20.06.2079"
    },
    {
        "engDate": "2022-10-07",
        "nepDate": "21.06.2079"
    },
    {
        "engDate": "2022-10-08",
        "nepDate": "22.06.2079"
    },
    {
        "engDate": "2022-10-09",
        "nepDate": "23.06.2079"
    },
    {
        "engDate": "2022-10-10",
        "nepDate": "24.06.2079"
    },
    {
        "engDate": "2022-10-11",
        "nepDate": "25.06.2079"
    },
    {
        "engDate": "2022-10-12",
        "nepDate": "26.06.2079"
    },
    {
        "engDate": "2022-10-13",
        "nepDate": "27.06.2079"
    },
    {
        "engDate": "2022-10-14",
        "nepDate": "28.06.2079"
    },
    {
        "engDate": "2022-10-15",
        "nepDate": "29.06.2079"
    },
    {
        "engDate": "2022-10-16",
        "nepDate": "30.06.2079"
    },
    {
        "engDate": "2022-10-17",
        "nepDate": "31.06.2079"
    },
    {
        "engDate": "2022-10-18",
        "nepDate": "01.07.2079"
    },
    {
        "engDate": "2022-10-19",
        "nepDate": "02.07.2079"
    },
    {
        "engDate": "2022-10-20",
        "nepDate": "03.07.2079"
    },
    {
        "engDate": "2022-10-21",
        "nepDate": "04.07.2079"
    },
    {
        "engDate": "2022-10-22",
        "nepDate": "05.07.2079"
    },
    {
        "engDate": "2022-10-23",
        "nepDate": "06.07.2079"
    },
    {
        "engDate": "2022-10-24",
        "nepDate": "07.07.2079"
    },
    {
        "engDate": "2022-10-25",
        "nepDate": "08.07.2079"
    },
    {
        "engDate": "2022-10-26",
        "nepDate": "09.07.2079"
    },
    {
        "engDate": "2022-10-27",
        "nepDate": "10.07.2079"
    },
    {
        "engDate": "2022-10-28",
        "nepDate": "11.07.2079"
    },
    {
        "engDate": "2022-10-29",
        "nepDate": "12.07.2079"
    },
    {
        "engDate": "2022-10-30",
        "nepDate": "13.07.2079"
    },
    {
        "engDate": "2022-10-31",
        "nepDate": "14.07.2079"
    },
    {
        "engDate": "2022-11-01",
        "nepDate": "15.07.2079"
    },
    {
        "engDate": "2022-11-02",
        "nepDate": "16.07.2079"
    },
    {
        "engDate": "2022-11-03",
        "nepDate": "17.07.2079"
    },
    {
        "engDate": "2022-11-04",
        "nepDate": "18.07.2079"
    },
    {
        "engDate": "2022-11-05",
        "nepDate": "19.07.2079"
    },
    {
        "engDate": "2022-11-06",
        "nepDate": "20.07.2079"
    },
    {
        "engDate": "2022-11-07",
        "nepDate": "21.07.2079"
    },
    {
        "engDate": "2022-11-08",
        "nepDate": "22.07.2079"
    },
    {
        "engDate": "2022-11-09",
        "nepDate": "23.07.2079"
    },
    {
        "engDate": "2022-11-10",
        "nepDate": "24.07.2079"
    },
    {
        "engDate": "2022-11-11",
        "nepDate": "25.07.2079"
    },
    {
        "engDate": "2022-11-12",
        "nepDate": "26.07.2079"
    },
    {
        "engDate": "2022-11-13",
        "nepDate": "27.07.2079"
    },
    {
        "engDate": "2022-11-14",
        "nepDate": "28.07.2079"
    },
    {
        "engDate": "2022-11-15",
        "nepDate": "29.07.2079"
    },
    {
        "engDate": "2022-11-16",
        "nepDate": "30.07.2079"
    },
    {
        "engDate": "2022-11-17",
        "nepDate": "01.08.2079"
    },
    {
        "engDate": "2022-11-18",
        "nepDate": "02.08.2079"
    },
    {
        "engDate": "2022-11-19",
        "nepDate": "03.08.2079"
    },
    {
        "engDate": "2022-11-20",
        "nepDate": "04.08.2079"
    },
    {
        "engDate": "2022-11-21",
        "nepDate": "05.08.2079"
    },
    {
        "engDate": "2022-11-22",
        "nepDate": "06.08.2079"
    },
    {
        "engDate": "2022-11-23",
        "nepDate": "07.08.2079"
    },
    {
        "engDate": "2022-11-24",
        "nepDate": "08.08.2079"
    },
    {
        "engDate": "2022-11-25",
        "nepDate": "09.08.2079"
    },
    {
        "engDate": "2022-11-26",
        "nepDate": "10.08.2079"
    },
    {
        "engDate": "2022-11-27",
        "nepDate": "11.08.2079"
    },
    {
        "engDate": "2022-11-28",
        "nepDate": "12.08.2079"
    },
    {
        "engDate": "2022-11-29",
        "nepDate": "13.08.2079"
    },
    {
        "engDate": "2022-11-30",
        "nepDate": "14.08.2079"
    },
    {
        "engDate": "2022-12-01",
        "nepDate": "15.08.2079"
    },
    {
        "engDate": "2022-12-02",
        "nepDate": "16.08.2079"
    },
    {
        "engDate": "2022-12-03",
        "nepDate": "17.08.2079"
    },
    {
        "engDate": "2022-12-04",
        "nepDate": "18.08.2079"
    },
    {
        "engDate": "2022-12-05",
        "nepDate": "19.08.2079"
    },
    {
        "engDate": "2022-12-06",
        "nepDate": "20.08.2079"
    },
    {
        "engDate": "2022-12-07",
        "nepDate": "21.08.2079"
    },
    {
        "engDate": "2022-12-08",
        "nepDate": "22.08.2079"
    },
    {
        "engDate": "2022-12-09",
        "nepDate": "23.08.2079"
    },
    {
        "engDate": "2022-12-10",
        "nepDate": "24.08.2079"
    },
    {
        "engDate": "2022-12-11",
        "nepDate": "25.08.2079"
    },
    {
        "engDate": "2022-12-12",
        "nepDate": "26.08.2079"
    },
    {
        "engDate": "2022-12-13",
        "nepDate": "27.08.2079"
    },
    {
        "engDate": "2022-12-14",
        "nepDate": "28.08.2079"
    },
    {
        "engDate": "2022-12-15",
        "nepDate": "29.08.2079"
    },
    {
        "engDate": "2022-12-16",
        "nepDate": "01.09.2079"
    },
    {
        "engDate": "2022-12-17",
        "nepDate": "02.09.2079"
    },
    {
        "engDate": "2022-12-18",
        "nepDate": "03.09.2079"
    },
    {
        "engDate": "2022-12-19",
        "nepDate": "04.09.2079"
    },
    {
        "engDate": "2022-12-20",
        "nepDate": "05.09.2079"
    },
    {
        "engDate": "2022-12-21",
        "nepDate": "06.09.2079"
    },
    {
        "engDate": "2022-12-22",
        "nepDate": "07.09.2079"
    },
    {
        "engDate": "2022-12-23",
        "nepDate": "08.09.2079"
    },
    {
        "engDate": "2022-12-24",
        "nepDate": "09.09.2079"
    },
    {
        "engDate": "2022-12-25",
        "nepDate": "10.09.2079"
    },
    {
        "engDate": "2022-12-26",
        "nepDate": "11.09.2079"
    },
    {
        "engDate": "2022-12-27",
        "nepDate": "12.09.2079"
    },
    {
        "engDate": "2022-12-28",
        "nepDate": "13.09.2079"
    },
    {
        "engDate": "2022-12-29",
        "nepDate": "14.09.2079"
    },
    {
        "engDate": "2022-12-30",
        "nepDate": "15.09.2079"
    },
    {
        "engDate": "2022-12-31",
        "nepDate": "16.09.2079"
    },
    {
        "engDate": "2023-01-01",
        "nepDate": "17.09.2079"
    },
    {
        "engDate": "2023-01-02",
        "nepDate": "18.09.2079"
    },
    {
        "engDate": "2023-01-03",
        "nepDate": "19.09.2079"
    },
    {
        "engDate": "2023-01-04",
        "nepDate": "20.09.2079"
    },
    {
        "engDate": "2023-01-05",
        "nepDate": "21.09.2079"
    },
    {
        "engDate": "2023-01-06",
        "nepDate": "22.09.2079"
    },
    {
        "engDate": "2023-01-07",
        "nepDate": "23.09.2079"
    },
    {
        "engDate": "2023-01-08",
        "nepDate": "24.09.2079"
    },
    {
        "engDate": "2023-01-09",
        "nepDate": "25.09.2079"
    },
    {
        "engDate": "2023-01-10",
        "nepDate": "26.09.2079"
    },
    {
        "engDate": "2023-01-11",
        "nepDate": "27.09.2079"
    },
    {
        "engDate": "2023-01-12",
        "nepDate": "28.09.2079"
    },
    {
        "engDate": "2023-01-13",
        "nepDate": "29.09.2079"
    },
    {
        "engDate": "2023-01-14",
        "nepDate": "30.09.2079"
    },
    {
        "engDate": "2023-01-15",
        "nepDate": "01.10.2079"
    },
    {
        "engDate": "2023-01-16",
        "nepDate": "02.10.2079"
    },
    {
        "engDate": "2023-01-17",
        "nepDate": "03.10.2079"
    },
    {
        "engDate": "2023-01-18",
        "nepDate": "04.10.2079"
    },
    {
        "engDate": "2023-01-19",
        "nepDate": "05.10.2079"
    },
    {
        "engDate": "2023-01-20",
        "nepDate": "06.10.2079"
    },
    {
        "engDate": "2023-01-21",
        "nepDate": "07.10.2079"
    },
    {
        "engDate": "2023-01-22",
        "nepDate": "08.10.2079"
    },
    {
        "engDate": "2023-01-23",
        "nepDate": "09.10.2079"
    },
    {
        "engDate": "2023-01-24",
        "nepDate": "10.10.2079"
    },
    {
        "engDate": "2023-01-25",
        "nepDate": "11.10.2079"
    },
    {
        "engDate": "2023-01-26",
        "nepDate": "12.10.2079"
    },
    {
        "engDate": "2023-01-27",
        "nepDate": "13.10.2079"
    },
    {
        "engDate": "2023-01-28",
        "nepDate": "14.10.2079"
    },
    {
        "engDate": "2023-01-29",
        "nepDate": "15.10.2079"
    },
    {
        "engDate": "2023-01-30",
        "nepDate": "16.10.2079"
    },
    {
        "engDate": "2023-01-31",
        "nepDate": "17.10.2079"
    },
    {
        "engDate": "2023-02-01",
        "nepDate": "18.10.2079"
    },
    {
        "engDate": "2023-02-02",
        "nepDate": "19.10.2079"
    },
    {
        "engDate": "2023-02-03",
        "nepDate": "20.10.2079"
    },
    {
        "engDate": "2023-02-04",
        "nepDate": "21.10.2079"
    },
    {
        "engDate": "2023-02-05",
        "nepDate": "22.10.2079"
    },
    {
        "engDate": "2023-02-06",
        "nepDate": "23.10.2079"
    },
    {
        "engDate": "2023-02-07",
        "nepDate": "24.10.2079"
    },
    {
        "engDate": "2023-02-08",
        "nepDate": "25.10.2079"
    },
    {
        "engDate": "2023-02-09",
        "nepDate": "26.10.2079"
    },
    {
        "engDate": "2023-02-10",
        "nepDate": "27.10.2079"
    },
    {
        "engDate": "2023-02-11",
        "nepDate": "28.10.2079"
    },
    {
        "engDate": "2023-02-12",
        "nepDate": "29.10.2079"
    },
    {
        "engDate": "2023-02-13",
        "nepDate": "01.11.2079"
    },
    {
        "engDate": "2023-02-14",
        "nepDate": "02.11.2079"
    },
    {
        "engDate": "2023-02-15",
        "nepDate": "03.11.2079"
    },
    {
        "engDate": "2023-02-16",
        "nepDate": "04.11.2079"
    },
    {
        "engDate": "2023-02-17",
        "nepDate": "05.11.2079"
    },
    {
        "engDate": "2023-02-18",
        "nepDate": "06.11.2079"
    },
    {
        "engDate": "2023-02-19",
        "nepDate": "07.11.2079"
    },
    {
        "engDate": "2023-02-20",
        "nepDate": "08.11.2079"
    },
    {
        "engDate": "2023-02-21",
        "nepDate": "09.11.2079"
    },
    {
        "engDate": "2023-02-22",
        "nepDate": "10.11.2079"
    },
    {
        "engDate": "2023-02-23",
        "nepDate": "11.11.2079"
    },
    {
        "engDate": "2023-02-24",
        "nepDate": "12.11.2079"
    },
    {
        "engDate": "2023-02-25",
        "nepDate": "13.11.2079"
    },
    {
        "engDate": "2023-02-26",
        "nepDate": "14.11.2079"
    },
    {
        "engDate": "2023-02-27",
        "nepDate": "15.11.2079"
    },
    {
        "engDate": "2023-02-28",
        "nepDate": "16.11.2079"
    },
    {
        "engDate": "2023-03-01",
        "nepDate": "17.11.2079"
    },
    {
        "engDate": "2023-03-02",
        "nepDate": "18.11.2079"
    },
    {
        "engDate": "2023-03-03",
        "nepDate": "19.11.2079"
    },
    {
        "engDate": "2023-03-04",
        "nepDate": "20.11.2079"
    },
    {
        "engDate": "2023-03-05",
        "nepDate": "21.11.2079"
    },
    {
        "engDate": "2023-03-06",
        "nepDate": "22.11.2079"
    },
    {
        "engDate": "2023-03-07",
        "nepDate": "23.11.2079"
    },
    {
        "engDate": "2023-03-08",
        "nepDate": "24.11.2079"
    },
    {
        "engDate": "2023-03-09",
        "nepDate": "25.11.2079"
    },
    {
        "engDate": "2023-03-10",
        "nepDate": "26.11.2079"
    },
    {
        "engDate": "2023-03-11",
        "nepDate": "27.11.2079"
    },
    {
        "engDate": "2023-03-12",
        "nepDate": "28.11.2079"
    },
    {
        "engDate": "2023-03-13",
        "nepDate": "29.11.2079"
    },
    {
        "engDate": "2023-03-14",
        "nepDate": "30.11.2079"
    },
    {
        "engDate": "2023-03-15",
        "nepDate": "01.12.2079"
    },
    {
        "engDate": "2023-03-16",
        "nepDate": "02.12.2079"
    },
    {
        "engDate": "2023-03-17",
        "nepDate": "03.12.2079"
    },
    {
        "engDate": "2023-03-18",
        "nepDate": "04.12.2079"
    },
    {
        "engDate": "2023-03-19",
        "nepDate": "05.12.2079"
    },
    {
        "engDate": "2023-03-20",
        "nepDate": "06.12.2079"
    },
    {
        "engDate": "2023-03-21",
        "nepDate": "07.12.2079"
    },
    {
        "engDate": "2023-03-22",
        "nepDate": "08.12.2079"
    },
    {
        "engDate": "2023-03-23",
        "nepDate": "09.12.2079"
    },
    {
        "engDate": "2023-03-24",
        "nepDate": "10.12.2079"
    },
    {
        "engDate": "2023-03-25",
        "nepDate": "11.12.2079"
    },
    {
        "engDate": "2023-03-26",
        "nepDate": "12.12.2079"
    },
    {
        "engDate": "2023-03-27",
        "nepDate": "13.12.2079"
    },
    {
        "engDate": "2023-03-28",
        "nepDate": "14.12.2079"
    },
    {
        "engDate": "2023-03-29",
        "nepDate": "15.12.2079"
    },
    {
        "engDate": "2023-03-30",
        "nepDate": "16.12.2079"
    },
    {
        "engDate": "2023-03-31",
        "nepDate": "17.12.2079"
    },
    {
        "engDate": "2023-04-01",
        "nepDate": "18.12.2079"
    },
    {
        "engDate": "2023-04-02",
        "nepDate": "19.12.2079"
    },
    {
        "engDate": "2023-04-03",
        "nepDate": "20.12.2079"
    },
    {
        "engDate": "2023-04-04",
        "nepDate": "21.12.2079"
    },
    {
        "engDate": "2023-04-05",
        "nepDate": "22.12.2079"
    },
    {
        "engDate": "2023-04-06",
        "nepDate": "23.12.2079"
    },
    {
        "engDate": "2023-04-07",
        "nepDate": "24.12.2079"
    },
    {
        "engDate": "2023-04-08",
        "nepDate": "25.12.2079"
    },
    {
        "engDate": "2023-04-09",
        "nepDate": "26.12.2079"
    },
    {
        "engDate": "2023-04-10",
        "nepDate": "27.12.2079"
    },
    {
        "engDate": "2023-04-11",
        "nepDate": "28.12.2079"
    },
    {
        "engDate": "2023-04-12",
        "nepDate": "29.12.2079"
    },
    {
        "engDate": "2023-04-13",
        "nepDate": "30.12.2079"
    },
    {
        "engDate": "2023-04-14",
        "nepDate": "01.01.2080"
    },
    {
        "engDate": "2023-04-15",
        "nepDate": "02.01.2080"
    },
    {
        "engDate": "2023-04-16",
        "nepDate": "03.01.2080"
    },
    {
        "engDate": "2023-04-17",
        "nepDate": "04.01.2080"
    },
    {
        "engDate": "2023-04-18",
        "nepDate": "05.01.2080"
    },
    {
        "engDate": "2023-04-19",
        "nepDate": "06.01.2080"
    },
    {
        "engDate": "2023-04-20",
        "nepDate": "07.01.2080"
    },
    {
        "engDate": "2023-04-21",
        "nepDate": "08.01.2080"
    },
    {
        "engDate": "2023-04-22",
        "nepDate": "09.01.2080"
    },
    {
        "engDate": "2023-04-23",
        "nepDate": "10.01.2080"
    },
    {
        "engDate": "2023-04-24",
        "nepDate": "11.01.2080"
    },
    {
        "engDate": "2023-04-25",
        "nepDate": "12.01.2080"
    },
    {
        "engDate": "2023-04-26",
        "nepDate": "13.01.2080"
    },
    {
        "engDate": "2023-04-27",
        "nepDate": "14.01.2080"
    },
    {
        "engDate": "2023-04-28",
        "nepDate": "15.01.2080"
    },
    {
        "engDate": "2023-04-29",
        "nepDate": "16.01.2080"
    },
    {
        "engDate": "2023-04-30",
        "nepDate": "17.01.2080"
    },
    {
        "engDate": "2023-05-01",
        "nepDate": "18.01.2080"
    },
    {
        "engDate": "2023-05-02",
        "nepDate": "19.01.2080"
    },
    {
        "engDate": "2023-05-03",
        "nepDate": "20.01.2080"
    },
    {
        "engDate": "2023-05-04",
        "nepDate": "21.01.2080"
    },
    {
        "engDate": "2023-05-05",
        "nepDate": "22.01.2080"
    },
    {
        "engDate": "2023-05-06",
        "nepDate": "23.01.2080"
    },
    {
        "engDate": "2023-05-07",
        "nepDate": "24.01.2080"
    },
    {
        "engDate": "2023-05-08",
        "nepDate": "25.01.2080"
    },
    {
        "engDate": "2023-05-09",
        "nepDate": "26.01.2080"
    },
    {
        "engDate": "2023-05-10",
        "nepDate": "27.01.2080"
    },
    {
        "engDate": "2023-05-11",
        "nepDate": "28.01.2080"
    },
    {
        "engDate": "2023-05-12",
        "nepDate": "29.01.2080"
    },
    {
        "engDate": "2023-05-13",
        "nepDate": "30.01.2080"
    },
    {
        "engDate": "2023-05-14",
        "nepDate": "31.01.2080"
    },
    {
        "engDate": "2023-05-15",
        "nepDate": "01.02.2080"
    },
    {
        "engDate": "2023-05-16",
        "nepDate": "02.02.2080"
    },
    {
        "engDate": "2023-05-17",
        "nepDate": "03.02.2080"
    },
    {
        "engDate": "2023-05-18",
        "nepDate": "04.02.2080"
    },
    {
        "engDate": "2023-05-19",
        "nepDate": "05.02.2080"
    },
    {
        "engDate": "2023-05-20",
        "nepDate": "06.02.2080"
    },
    {
        "engDate": "2023-05-21",
        "nepDate": "07.02.2080"
    },
    {
        "engDate": "2023-05-22",
        "nepDate": "08.02.2080"
    },
    {
        "engDate": "2023-05-23",
        "nepDate": "09.02.2080"
    },
    {
        "engDate": "2023-05-24",
        "nepDate": "10.02.2080"
    },
    {
        "engDate": "2023-05-25",
        "nepDate": "11.02.2080"
    },
    {
        "engDate": "2023-05-26",
        "nepDate": "12.02.2080"
    },
    {
        "engDate": "2023-05-27",
        "nepDate": "13.02.2080"
    },
    {
        "engDate": "2023-05-28",
        "nepDate": "14.02.2080"
    },
    {
        "engDate": "2023-05-29",
        "nepDate": "15.02.2080"
    },
    {
        "engDate": "2023-05-30",
        "nepDate": "16.02.2080"
    },
    {
        "engDate": "2023-05-31",
        "nepDate": "17.02.2080"
    },
    {
        "engDate": "2023-06-01",
        "nepDate": "18.02.2080"
    },
    {
        "engDate": "2023-06-02",
        "nepDate": "19.02.2080"
    },
    {
        "engDate": "2023-06-03",
        "nepDate": "20.02.2080"
    },
    {
        "engDate": "2023-06-04",
        "nepDate": "21.02.2080"
    },
    {
        "engDate": "2023-06-05",
        "nepDate": "22.02.2080"
    },
    {
        "engDate": "2023-06-06",
        "nepDate": "23.02.2080"
    },
    {
        "engDate": "2023-06-07",
        "nepDate": "24.02.2080"
    },
    {
        "engDate": "2023-06-08",
        "nepDate": "25.02.2080"
    },
    {
        "engDate": "2023-06-09",
        "nepDate": "26.02.2080"
    },
    {
        "engDate": "2023-06-10",
        "nepDate": "27.02.2080"
    },
    {
        "engDate": "2023-06-11",
        "nepDate": "28.02.2080"
    },
    {
        "engDate": "2023-06-12",
        "nepDate": "29.02.2080"
    },
    {
        "engDate": "2023-06-13",
        "nepDate": "30.02.2080"
    },
    {
        "engDate": "2023-06-14",
        "nepDate": "31.02.2080"
    },
    {
        "engDate": "2023-06-15",
        "nepDate": "32.02.2080"
    },
    {
        "engDate": "2023-06-16",
        "nepDate": "01.03.2080"
    },
    {
        "engDate": "2023-06-17",
        "nepDate": "02.03.2080"
    },
    {
        "engDate": "2023-06-18",
        "nepDate": "03.03.2080"
    },
    {
        "engDate": "2023-06-19",
        "nepDate": "04.03.2080"
    },
    {
        "engDate": "2023-06-20",
        "nepDate": "05.03.2080"
    },
    {
        "engDate": "2023-06-21",
        "nepDate": "06.03.2080"
    },
    {
        "engDate": "2023-06-22",
        "nepDate": "07.03.2080"
    },
    {
        "engDate": "2023-06-23",
        "nepDate": "08.03.2080"
    },
    {
        "engDate": "2023-06-24",
        "nepDate": "09.03.2080"
    },
    {
        "engDate": "2023-06-25",
        "nepDate": "10.03.2080"
    },
    {
        "engDate": "2023-06-26",
        "nepDate": "11.03.2080"
    },
    {
        "engDate": "2023-06-27",
        "nepDate": "12.03.2080"
    },
    {
        "engDate": "2023-06-28",
        "nepDate": "13.03.2080"
    },
    {
        "engDate": "2023-06-29",
        "nepDate": "14.03.2080"
    },
    {
        "engDate": "2023-06-30",
        "nepDate": "15.03.2080"
    },
    {
        "engDate": "2023-07-01",
        "nepDate": "16.03.2080"
    },
    {
        "engDate": "2023-07-02",
        "nepDate": "17.03.2080"
    },
    {
        "engDate": "2023-07-03",
        "nepDate": "18.03.2080"
    },
    {
        "engDate": "2023-07-04",
        "nepDate": "19.03.2080"
    },
    {
        "engDate": "2023-07-05",
        "nepDate": "20.03.2080"
    },
    {
        "engDate": "2023-07-06",
        "nepDate": "21.03.2080"
    },
    {
        "engDate": "2023-07-07",
        "nepDate": "22.03.2080"
    },
    {
        "engDate": "2023-07-08",
        "nepDate": "23.03.2080"
    },
    {
        "engDate": "2023-07-09",
        "nepDate": "24.03.2080"
    },
    {
        "engDate": "2023-07-10",
        "nepDate": "25.03.2080"
    },
    {
        "engDate": "2023-07-11",
        "nepDate": "26.03.2080"
    },
    {
        "engDate": "2023-07-12",
        "nepDate": "27.03.2080"
    },
    {
        "engDate": "2023-07-13",
        "nepDate": "28.03.2080"
    },
    {
        "engDate": "2023-07-14",
        "nepDate": "29.03.2080"
    },
    {
        "engDate": "2023-07-15",
        "nepDate": "30.03.2080"
    },
    {
        "engDate": "2023-07-16",
        "nepDate": "31.03.2080"
    },
    {
        "engDate": "2023-07-17",
        "nepDate": "01.04.2080"
    },
    {
        "engDate": "2023-07-18",
        "nepDate": "02.04.2080"
    },
    {
        "engDate": "2023-07-19",
        "nepDate": "03.04.2080"
    },
    {
        "engDate": "2023-07-20",
        "nepDate": "04.04.2080"
    },
    {
        "engDate": "2023-07-21",
        "nepDate": "05.04.2080"
    },
    {
        "engDate": "2023-07-22",
        "nepDate": "06.04.2080"
    },
    {
        "engDate": "2023-07-23",
        "nepDate": "07.04.2080"
    },
    {
        "engDate": "2023-07-24",
        "nepDate": "08.04.2080"
    },
    {
        "engDate": "2023-07-25",
        "nepDate": "09.04.2080"
    },
    {
        "engDate": "2023-07-26",
        "nepDate": "10.04.2080"
    },
    {
        "engDate": "2023-07-27",
        "nepDate": "11.04.2080"
    },
    {
        "engDate": "2023-07-28",
        "nepDate": "12.04.2080"
    },
    {
        "engDate": "2023-07-29",
        "nepDate": "13.04.2080"
    },
    {
        "engDate": "2023-07-30",
        "nepDate": "14.04.2080"
    },
    {
        "engDate": "2023-07-31",
        "nepDate": "15.04.2080"
    },
    {
        "engDate": "2023-08-01",
        "nepDate": "16.04.2080"
    },
    {
        "engDate": "2023-08-02",
        "nepDate": "17.04.2080"
    },
    {
        "engDate": "2023-08-03",
        "nepDate": "18.04.2080"
    },
    {
        "engDate": "2023-08-04",
        "nepDate": "19.04.2080"
    },
    {
        "engDate": "2023-08-05",
        "nepDate": "20.04.2080"
    },
    {
        "engDate": "2023-08-06",
        "nepDate": "21.04.2080"
    },
    {
        "engDate": "2023-08-07",
        "nepDate": "22.04.2080"
    },
    {
        "engDate": "2023-08-08",
        "nepDate": "23.04.2080"
    },
    {
        "engDate": "2023-08-09",
        "nepDate": "24.04.2080"
    },
    {
        "engDate": "2023-08-10",
        "nepDate": "25.04.2080"
    },
    {
        "engDate": "2023-08-11",
        "nepDate": "26.04.2080"
    },
    {
        "engDate": "2023-08-12",
        "nepDate": "27.04.2080"
    },
    {
        "engDate": "2023-08-13",
        "nepDate": "28.04.2080"
    },
    {
        "engDate": "2023-08-14",
        "nepDate": "29.04.2080"
    },
    {
        "engDate": "2023-08-15",
        "nepDate": "30.04.2080"
    },
    {
        "engDate": "2023-08-16",
        "nepDate": "31.04.2080"
    },
    {
        "engDate": "2023-08-17",
        "nepDate": "32.04.2080"
    },
    {
        "engDate": "2023-08-18",
        "nepDate": "01.05.2080"
    },
    {
        "engDate": "2023-08-19",
        "nepDate": "02.05.2080"
    },
    {
        "engDate": "2023-08-20",
        "nepDate": "03.05.2080"
    },
    {
        "engDate": "2023-08-21",
        "nepDate": "04.05.2080"
    },
    {
        "engDate": "2023-08-22",
        "nepDate": "05.05.2080"
    },
    {
        "engDate": "2023-08-23",
        "nepDate": "06.05.2080"
    },
    {
        "engDate": "2023-08-24",
        "nepDate": "07.05.2080"
    },
    {
        "engDate": "2023-08-25",
        "nepDate": "08.05.2080"
    },
    {
        "engDate": "2023-08-26",
        "nepDate": "09.05.2080"
    },
    {
        "engDate": "2023-08-27",
        "nepDate": "10.05.2080"
    },
    {
        "engDate": "2023-08-28",
        "nepDate": "11.05.2080"
    },
    {
        "engDate": "2023-08-29",
        "nepDate": "12.05.2080"
    },
    {
        "engDate": "2023-08-30",
        "nepDate": "13.05.2080"
    },
    {
        "engDate": "2023-08-31",
        "nepDate": "14.05.2080"
    },
    {
        "engDate": "2023-09-01",
        "nepDate": "15.05.2080"
    },
    {
        "engDate": "2023-09-02",
        "nepDate": "16.05.2080"
    },
    {
        "engDate": "2023-09-03",
        "nepDate": "17.05.2080"
    },
    {
        "engDate": "2023-09-04",
        "nepDate": "18.05.2080"
    },
    {
        "engDate": "2023-09-05",
        "nepDate": "19.05.2080"
    },
    {
        "engDate": "2023-09-06",
        "nepDate": "20.05.2080"
    },
    {
        "engDate": "2023-09-07",
        "nepDate": "21.05.2080"
    },
    {
        "engDate": "2023-09-08",
        "nepDate": "22.05.2080"
    },
    {
        "engDate": "2023-09-09",
        "nepDate": "23.05.2080"
    },
    {
        "engDate": "2023-09-10",
        "nepDate": "24.05.2080"
    },
    {
        "engDate": "2023-09-11",
        "nepDate": "25.05.2080"
    },
    {
        "engDate": "2023-09-12",
        "nepDate": "26.05.2080"
    },
    {
        "engDate": "2023-09-13",
        "nepDate": "27.05.2080"
    },
    {
        "engDate": "2023-09-14",
        "nepDate": "28.05.2080"
    },
    {
        "engDate": "2023-09-15",
        "nepDate": "29.05.2080"
    },
    {
        "engDate": "2023-09-16",
        "nepDate": "30.05.2080"
    },
    {
        "engDate": "2023-09-17",
        "nepDate": "31.05.2080"
    },
    {
        "engDate": "2023-09-18",
        "nepDate": "01.06.2080"
    },
    {
        "engDate": "2023-09-19",
        "nepDate": "02.06.2080"
    },
    {
        "engDate": "2023-09-20",
        "nepDate": "03.06.2080"
    },
    {
        "engDate": "2023-09-21",
        "nepDate": "04.06.2080"
    },
    {
        "engDate": "2023-09-22",
        "nepDate": "05.06.2080"
    },
    {
        "engDate": "2023-09-23",
        "nepDate": "06.06.2080"
    },
    {
        "engDate": "2023-09-24",
        "nepDate": "07.06.2080"
    },
    {
        "engDate": "2023-09-25",
        "nepDate": "08.06.2080"
    },
    {
        "engDate": "2023-09-26",
        "nepDate": "09.06.2080"
    },
    {
        "engDate": "2023-09-27",
        "nepDate": "10.06.2080"
    },
    {
        "engDate": "2023-09-28",
        "nepDate": "11.06.2080"
    },
    {
        "engDate": "2023-09-29",
        "nepDate": "12.06.2080"
    },
    {
        "engDate": "2023-09-30",
        "nepDate": "13.06.2080"
    },
    {
        "engDate": "2023-10-01",
        "nepDate": "14.06.2080"
    },
    {
        "engDate": "2023-10-02",
        "nepDate": "15.06.2080"
    },
    {
        "engDate": "2023-10-03",
        "nepDate": "16.06.2080"
    },
    {
        "engDate": "2023-10-04",
        "nepDate": "17.06.2080"
    },
    {
        "engDate": "2023-10-05",
        "nepDate": "18.06.2080"
    },
    {
        "engDate": "2023-10-06",
        "nepDate": "19.06.2080"
    },
    {
        "engDate": "2023-10-07",
        "nepDate": "20.06.2080"
    },
    {
        "engDate": "2023-10-08",
        "nepDate": "21.06.2080"
    },
    {
        "engDate": "2023-10-09",
        "nepDate": "22.06.2080"
    },
    {
        "engDate": "2023-10-10",
        "nepDate": "23.06.2080"
    },
    {
        "engDate": "2023-10-11",
        "nepDate": "24.06.2080"
    },
    {
        "engDate": "2023-10-12",
        "nepDate": "25.06.2080"
    },
    {
        "engDate": "2023-10-13",
        "nepDate": "26.06.2080"
    },
    {
        "engDate": "2023-10-14",
        "nepDate": "27.06.2080"
    },
    {
        "engDate": "2023-10-15",
        "nepDate": "28.06.2080"
    },
    {
        "engDate": "2023-10-16",
        "nepDate": "29.06.2080"
    },
    {
        "engDate": "2023-10-17",
        "nepDate": "30.06.2080"
    },
    {
        "engDate": "2023-10-18",
        "nepDate": "01.07.2080"
    },
    {
        "engDate": "2023-10-19",
        "nepDate": "02.07.2080"
    },
    {
        "engDate": "2023-10-20",
        "nepDate": "03.07.2080"
    },
    {
        "engDate": "2023-10-21",
        "nepDate": "04.07.2080"
    },
    {
        "engDate": "2023-10-22",
        "nepDate": "05.07.2080"
    },
    {
        "engDate": "2023-10-23",
        "nepDate": "06.07.2080"
    },
    {
        "engDate": "2023-10-24",
        "nepDate": "07.07.2080"
    },
    {
        "engDate": "2023-10-25",
        "nepDate": "08.07.2080"
    },
    {
        "engDate": "2023-10-26",
        "nepDate": "09.07.2080"
    },
    {
        "engDate": "2023-10-27",
        "nepDate": "10.07.2080"
    },
    {
        "engDate": "2023-10-28",
        "nepDate": "11.07.2080"
    },
    {
        "engDate": "2023-10-29",
        "nepDate": "12.07.2080"
    },
    {
        "engDate": "2023-10-30",
        "nepDate": "13.07.2080"
    },
    {
        "engDate": "2023-10-31",
        "nepDate": "14.07.2080"
    },
    {
        "engDate": "2023-11-01",
        "nepDate": "15.07.2080"
    },
    {
        "engDate": "2023-11-02",
        "nepDate": "16.07.2080"
    },
    {
        "engDate": "2023-11-03",
        "nepDate": "17.07.2080"
    },
    {
        "engDate": "2023-11-04",
        "nepDate": "18.07.2080"
    },
    {
        "engDate": "2023-11-05",
        "nepDate": "19.07.2080"
    },
    {
        "engDate": "2023-11-06",
        "nepDate": "20.07.2080"
    },
    {
        "engDate": "2023-11-07",
        "nepDate": "21.07.2080"
    },
    {
        "engDate": "2023-11-08",
        "nepDate": "22.07.2080"
    },
    {
        "engDate": "2023-11-09",
        "nepDate": "23.07.2080"
    },
    {
        "engDate": "2023-11-10",
        "nepDate": "24.07.2080"
    },
    {
        "engDate": "2023-11-11",
        "nepDate": "25.07.2080"
    },
    {
        "engDate": "2023-11-12",
        "nepDate": "26.07.2080"
    },
    {
        "engDate": "2023-11-13",
        "nepDate": "27.07.2080"
    },
    {
        "engDate": "2023-11-14",
        "nepDate": "28.07.2080"
    },
    {
        "engDate": "2023-11-15",
        "nepDate": "29.07.2080"
    },
    {
        "engDate": "2023-11-16",
        "nepDate": "30.07.2080"
    },
    {
        "engDate": "2023-11-17",
        "nepDate": "01.08.2080"
    },
    {
        "engDate": "2023-11-18",
        "nepDate": "02.08.2080"
    },
    {
        "engDate": "2023-11-19",
        "nepDate": "03.08.2080"
    },
    {
        "engDate": "2023-11-20",
        "nepDate": "04.08.2080"
    },
    {
        "engDate": "2023-11-21",
        "nepDate": "05.08.2080"
    },
    {
        "engDate": "2023-11-22",
        "nepDate": "06.08.2080"
    },
    {
        "engDate": "2023-11-23",
        "nepDate": "07.08.2080"
    },
    {
        "engDate": "2023-11-24",
        "nepDate": "08.08.2080"
    },
    {
        "engDate": "2023-11-25",
        "nepDate": "09.08.2080"
    },
    {
        "engDate": "2023-11-26",
        "nepDate": "10.08.2080"
    },
    {
        "engDate": "2023-11-27",
        "nepDate": "11.08.2080"
    },
    {
        "engDate": "2023-11-28",
        "nepDate": "12.08.2080"
    },
    {
        "engDate": "2023-11-29",
        "nepDate": "13.08.2080"
    },
    {
        "engDate": "2023-11-30",
        "nepDate": "14.08.2080"
    },
    {
        "engDate": "2023-12-01",
        "nepDate": "15.08.2080"
    },
    {
        "engDate": "2023-12-02",
        "nepDate": "16.08.2080"
    },
    {
        "engDate": "2023-12-03",
        "nepDate": "17.08.2080"
    },
    {
        "engDate": "2023-12-04",
        "nepDate": "18.08.2080"
    },
    {
        "engDate": "2023-12-05",
        "nepDate": "19.08.2080"
    },
    {
        "engDate": "2023-12-06",
        "nepDate": "20.08.2080"
    },
    {
        "engDate": "2023-12-07",
        "nepDate": "21.08.2080"
    },
    {
        "engDate": "2023-12-08",
        "nepDate": "22.08.2080"
    },
    {
        "engDate": "2023-12-09",
        "nepDate": "23.08.2080"
    },
    {
        "engDate": "2023-12-10",
        "nepDate": "24.08.2080"
    },
    {
        "engDate": "2023-12-11",
        "nepDate": "25.08.2080"
    },
    {
        "engDate": "2023-12-12",
        "nepDate": "26.08.2080"
    },
    {
        "engDate": "2023-12-13",
        "nepDate": "27.08.2080"
    },
    {
        "engDate": "2023-12-14",
        "nepDate": "28.08.2080"
    },
    {
        "engDate": "2023-12-15",
        "nepDate": "29.08.2080"
    },
    {
        "engDate": "2023-12-16",
        "nepDate": "30.08.2080"
    },
    {
        "engDate": "2023-12-17",
        "nepDate": "01.09.2080"
    },
    {
        "engDate": "2023-12-18",
        "nepDate": "02.09.2080"
    },
    {
        "engDate": "2023-12-19",
        "nepDate": "03.09.2080"
    },
    {
        "engDate": "2023-12-20",
        "nepDate": "04.09.2080"
    },
    {
        "engDate": "2023-12-21",
        "nepDate": "05.09.2080"
    },
    {
        "engDate": "2023-12-22",
        "nepDate": "06.09.2080"
    },
    {
        "engDate": "2023-12-23",
        "nepDate": "07.09.2080"
    },
    {
        "engDate": "2023-12-24",
        "nepDate": "08.09.2080"
    },
    {
        "engDate": "2023-12-25",
        "nepDate": "09.09.2080"
    },
    {
        "engDate": "2023-12-26",
        "nepDate": "10.09.2080"
    },
    {
        "engDate": "2023-12-27",
        "nepDate": "11.09.2080"
    },
    {
        "engDate": "2023-12-28",
        "nepDate": "12.09.2080"
    },
    {
        "engDate": "2023-12-29",
        "nepDate": "13.09.2080"
    },
    {
        "engDate": "2023-12-30",
        "nepDate": "14.09.2080"
    },
    {
        "engDate": "2023-12-31",
        "nepDate": "15.09.2080"
    },
    {
        "engDate": "2024-01-01",
        "nepDate": "16.09.2080"
    },
    {
        "engDate": "2024-01-02",
        "nepDate": "17.09.2080"
    },
    {
        "engDate": "2024-01-03",
        "nepDate": "18.09.2080"
    },
    {
        "engDate": "2024-01-04",
        "nepDate": "19.09.2080"
    },
    {
        "engDate": "2024-01-05",
        "nepDate": "20.09.2080"
    },
    {
        "engDate": "2024-01-06",
        "nepDate": "21.09.2080"
    },
    {
        "engDate": "2024-01-07",
        "nepDate": "22.09.2080"
    },
    {
        "engDate": "2024-01-08",
        "nepDate": "23.09.2080"
    },
    {
        "engDate": "2024-01-09",
        "nepDate": "24.09.2080"
    },
    {
        "engDate": "2024-01-10",
        "nepDate": "25.09.2080"
    },
    {
        "engDate": "2024-01-11",
        "nepDate": "26.09.2080"
    },
    {
        "engDate": "2024-01-12",
        "nepDate": "27.09.2080"
    },
    {
        "engDate": "2024-01-13",
        "nepDate": "28.09.2080"
    },
    {
        "engDate": "2024-01-14",
        "nepDate": "29.09.2080"
    },
    {
        "engDate": "2024-01-15",
        "nepDate": "01.10.2080"
    },
    {
        "engDate": "2024-01-16",
        "nepDate": "02.10.2080"
    },
    {
        "engDate": "2024-01-17",
        "nepDate": "03.10.2080"
    },
    {
        "engDate": "2024-01-18",
        "nepDate": "04.10.2080"
    },
    {
        "engDate": "2024-01-19",
        "nepDate": "05.10.2080"
    },
    {
        "engDate": "2024-01-20",
        "nepDate": "06.10.2080"
    },
    {
        "engDate": "2024-01-21",
        "nepDate": "07.10.2080"
    },
    {
        "engDate": "2024-01-22",
        "nepDate": "08.10.2080"
    },
    {
        "engDate": "2024-01-23",
        "nepDate": "09.10.2080"
    },
    {
        "engDate": "2024-01-24",
        "nepDate": "10.10.2080"
    },
    {
        "engDate": "2024-01-25",
        "nepDate": "11.10.2080"
    },
    {
        "engDate": "2024-01-26",
        "nepDate": "12.10.2080"
    },
    {
        "engDate": "2024-01-27",
        "nepDate": "13.10.2080"
    },
    {
        "engDate": "2024-01-28",
        "nepDate": "14.10.2080"
    },
    {
        "engDate": "2024-01-29",
        "nepDate": "15.10.2080"
    },
    {
        "engDate": "2024-01-30",
        "nepDate": "16.10.2080"
    },
    {
        "engDate": "2024-01-31",
        "nepDate": "17.10.2080"
    },
    {
        "engDate": "2024-02-01",
        "nepDate": "18.10.2080"
    },
    {
        "engDate": "2024-02-02",
        "nepDate": "19.10.2080"
    },
    {
        "engDate": "2024-02-03",
        "nepDate": "20.10.2080"
    },
    {
        "engDate": "2024-02-04",
        "nepDate": "21.10.2080"
    },
    {
        "engDate": "2024-02-05",
        "nepDate": "22.10.2080"
    },
    {
        "engDate": "2024-02-06",
        "nepDate": "23.10.2080"
    },
    {
        "engDate": "2024-02-07",
        "nepDate": "24.10.2080"
    },
    {
        "engDate": "2024-02-08",
        "nepDate": "25.10.2080"
    },
    {
        "engDate": "2024-02-09",
        "nepDate": "26.10.2080"
    },
    {
        "engDate": "2024-02-10",
        "nepDate": "27.10.2080"
    },
    {
        "engDate": "2024-02-11",
        "nepDate": "28.10.2080"
    },
    {
        "engDate": "2024-02-12",
        "nepDate": "29.10.2080"
    },
    {
        "engDate": "2024-02-13",
        "nepDate": "01.11.2080"
    },
    {
        "engDate": "2024-02-14",
        "nepDate": "02.11.2080"
    },
    {
        "engDate": "2024-02-15",
        "nepDate": "03.11.2080"
    },
    {
        "engDate": "2024-02-16",
        "nepDate": "04.11.2080"
    },
    {
        "engDate": "2024-02-17",
        "nepDate": "05.11.2080"
    },
    {
        "engDate": "2024-02-18",
        "nepDate": "06.11.2080"
    },
    {
        "engDate": "2024-02-19",
        "nepDate": "07.11.2080"
    },
    {
        "engDate": "2024-02-20",
        "nepDate": "08.11.2080"
    },
    {
        "engDate": "2024-02-21",
        "nepDate": "09.11.2080"
    },
    {
        "engDate": "2024-02-22",
        "nepDate": "10.11.2080"
    },
    {
        "engDate": "2024-02-23",
        "nepDate": "11.11.2080"
    },
    {
        "engDate": "2024-02-24",
        "nepDate": "12.11.2080"
    },
    {
        "engDate": "2024-02-25",
        "nepDate": "13.11.2080"
    },
    {
        "engDate": "2024-02-26",
        "nepDate": "14.11.2080"
    },
    {
        "engDate": "2024-02-27",
        "nepDate": "15.11.2080"
    },
    {
        "engDate": "2024-02-28",
        "nepDate": "16.11.2080"
    },
    {
        "engDate": "2024-02-29",
        "nepDate": "17.11.2080"
    },
    {
        "engDate": "2024-03-01",
        "nepDate": "18.11.2080"
    },
    {
        "engDate": "2024-03-02",
        "nepDate": "19.11.2080"
    },
    {
        "engDate": "2024-03-03",
        "nepDate": "20.11.2080"
    },
    {
        "engDate": "2024-03-04",
        "nepDate": "21.11.2080"
    },
    {
        "engDate": "2024-03-05",
        "nepDate": "22.11.2080"
    },
    {
        "engDate": "2024-03-06",
        "nepDate": "23.11.2080"
    },
    {
        "engDate": "2024-03-07",
        "nepDate": "24.11.2080"
    },
    {
        "engDate": "2024-03-08",
        "nepDate": "25.11.2080"
    },
    {
        "engDate": "2024-03-09",
        "nepDate": "26.11.2080"
    },
    {
        "engDate": "2024-03-10",
        "nepDate": "27.11.2080"
    },
    {
        "engDate": "2024-03-11",
        "nepDate": "28.11.2080"
    },
    {
        "engDate": "2024-03-12",
        "nepDate": "29.11.2080"
    },
    {
        "engDate": "2024-03-13",
        "nepDate": "30.11.2080"
    },
    {
        "engDate": "2024-03-14",
        "nepDate": "01.12.2080"
    },
    {
        "engDate": "2024-03-15",
        "nepDate": "02.12.2080"
    },
    {
        "engDate": "2024-03-16",
        "nepDate": "03.12.2080"
    },
    {
        "engDate": "2024-03-17",
        "nepDate": "04.12.2080"
    },
    {
        "engDate": "2024-03-18",
        "nepDate": "05.12.2080"
    },
    {
        "engDate": "2024-03-19",
        "nepDate": "06.12.2080"
    },
    {
        "engDate": "2024-03-20",
        "nepDate": "07.12.2080"
    },
    {
        "engDate": "2024-03-21",
        "nepDate": "08.12.2080"
    },
    {
        "engDate": "2024-03-22",
        "nepDate": "09.12.2080"
    },
    {
        "engDate": "2024-03-23",
        "nepDate": "10.12.2080"
    },
    {
        "engDate": "2024-03-24",
        "nepDate": "11.12.2080"
    },
    {
        "engDate": "2024-03-25",
        "nepDate": "12.12.2080"
    },
    {
        "engDate": "2024-03-26",
        "nepDate": "13.12.2080"
    },
    {
        "engDate": "2024-03-27",
        "nepDate": "14.12.2080"
    },
    {
        "engDate": "2024-03-28",
        "nepDate": "15.12.2080"
    },
    {
        "engDate": "2024-03-29",
        "nepDate": "16.12.2080"
    },
    {
        "engDate": "2024-03-30",
        "nepDate": "17.12.2080"
    },
    {
        "engDate": "2024-03-31",
        "nepDate": "18.12.2080"
    },
    {
        "engDate": "2024-04-01",
        "nepDate": "19.12.2080"
    },
    {
        "engDate": "2024-04-02",
        "nepDate": "20.12.2080"
    },
    {
        "engDate": "2024-04-03",
        "nepDate": "21.12.2080"
    },
    {
        "engDate": "2024-04-04",
        "nepDate": "22.12.2080"
    },
    {
        "engDate": "2024-04-05",
        "nepDate": "23.12.2080"
    },
    {
        "engDate": "2024-04-06",
        "nepDate": "24.12.2080"
    },
    {
        "engDate": "2024-04-07",
        "nepDate": "25.12.2080"
    },
    {
        "engDate": "2024-04-08",
        "nepDate": "26.12.2080"
    },
    {
        "engDate": "2024-04-09",
        "nepDate": "27.12.2080"
    },
    {
        "engDate": "2024-04-10",
        "nepDate": "28.12.2080"
    },
    {
        "engDate": "2024-04-11",
        "nepDate": "29.12.2080"
    },
    {
        "engDate": "2024-04-12",
        "nepDate": "30.12.2080"
    },
    {
        "engDate": "2024-04-13",
        "nepDate": "01.01.2081"
    },
    {
        "engDate": "2024-04-14",
        "nepDate": "02.01.2081"
    },
    {
        "engDate": "2024-04-15",
        "nepDate": "03.01.2081"
    },
    {
        "engDate": "2024-04-16",
        "nepDate": "04.01.2081"
    },
    {
        "engDate": "2024-04-17",
        "nepDate": "05.01.2081"
    },
    {
        "engDate": "2024-04-18",
        "nepDate": "06.01.2081"
    },
    {
        "engDate": "2024-04-19",
        "nepDate": "07.01.2081"
    },
    {
        "engDate": "2024-04-20",
        "nepDate": "08.01.2081"
    },
    {
        "engDate": "2024-04-21",
        "nepDate": "09.01.2081"
    },
    {
        "engDate": "2024-04-22",
        "nepDate": "10.01.2081"
    },
    {
        "engDate": "2024-04-23",
        "nepDate": "11.01.2081"
    },
    {
        "engDate": "2024-04-24",
        "nepDate": "12.01.2081"
    },
    {
        "engDate": "2024-04-25",
        "nepDate": "13.01.2081"
    },
    {
        "engDate": "2024-04-26",
        "nepDate": "14.01.2081"
    },
    {
        "engDate": "2024-04-27",
        "nepDate": "15.01.2081"
    },
    {
        "engDate": "2024-04-28",
        "nepDate": "16.01.2081"
    },
    {
        "engDate": "2024-04-29",
        "nepDate": "17.01.2081"
    },
    {
        "engDate": "2024-04-30",
        "nepDate": "18.01.2081"
    },
    {
        "engDate": "2024-05-01",
        "nepDate": "19.01.2081"
    },
    {
        "engDate": "2024-05-02",
        "nepDate": "20.01.2081"
    },
    {
        "engDate": "2024-05-03",
        "nepDate": "21.01.2081"
    },
    {
        "engDate": "2024-05-04",
        "nepDate": "22.01.2081"
    },
    {
        "engDate": "2024-05-05",
        "nepDate": "23.01.2081"
    },
    {
        "engDate": "2024-05-06",
        "nepDate": "24.01.2081"
    },
    {
        "engDate": "2024-05-07",
        "nepDate": "25.01.2081"
    },
    {
        "engDate": "2024-05-08",
        "nepDate": "26.01.2081"
    },
    {
        "engDate": "2024-05-09",
        "nepDate": "27.01.2081"
    },
    {
        "engDate": "2024-05-10",
        "nepDate": "28.01.2081"
    },
    {
        "engDate": "2024-05-11",
        "nepDate": "29.01.2081"
    },
    {
        "engDate": "2024-05-12",
        "nepDate": "30.01.2081"
    },
    {
        "engDate": "2024-05-13",
        "nepDate": "31.01.2081"
    },
    {
        "engDate": "2024-05-14",
        "nepDate": "01.02.2081"
    },
    {
        "engDate": "2024-05-15",
        "nepDate": "02.02.2081"
    },
    {
        "engDate": "2024-05-16",
        "nepDate": "03.02.2081"
    },
    {
        "engDate": "2024-05-17",
        "nepDate": "04.02.2081"
    },
    {
        "engDate": "2024-05-18",
        "nepDate": "05.02.2081"
    },
    {
        "engDate": "2024-05-19",
        "nepDate": "06.02.2081"
    },
    {
        "engDate": "2024-05-20",
        "nepDate": "07.02.2081"
    },
    {
        "engDate": "2024-05-21",
        "nepDate": "08.02.2081"
    },
    {
        "engDate": "2024-05-22",
        "nepDate": "09.02.2081"
    },
    {
        "engDate": "2024-05-23",
        "nepDate": "10.02.2081"
    },
    {
        "engDate": "2024-05-24",
        "nepDate": "11.02.2081"
    },
    {
        "engDate": "2024-05-25",
        "nepDate": "12.02.2081"
    },
    {
        "engDate": "2024-05-26",
        "nepDate": "13.02.2081"
    },
    {
        "engDate": "2024-05-27",
        "nepDate": "14.02.2081"
    },
    {
        "engDate": "2024-05-28",
        "nepDate": "15.02.2081"
    },
    {
        "engDate": "2024-05-29",
        "nepDate": "16.02.2081"
    },
    {
        "engDate": "2024-05-30",
        "nepDate": "17.02.2081"
    },
    {
        "engDate": "2024-05-31",
        "nepDate": "18.02.2081"
    },
    {
        "engDate": "2024-06-01",
        "nepDate": "19.02.2081"
    },
    {
        "engDate": "2024-06-02",
        "nepDate": "20.02.2081"
    },
    {
        "engDate": "2024-06-03",
        "nepDate": "21.02.2081"
    },
    {
        "engDate": "2024-06-04",
        "nepDate": "22.02.2081"
    },
    {
        "engDate": "2024-06-05",
        "nepDate": "23.02.2081"
    },
    {
        "engDate": "2024-06-06",
        "nepDate": "24.02.2081"
    },
    {
        "engDate": "2024-06-07",
        "nepDate": "25.02.2081"
    },
    {
        "engDate": "2024-06-08",
        "nepDate": "26.02.2081"
    },
    {
        "engDate": "2024-06-09",
        "nepDate": "27.02.2081"
    },
    {
        "engDate": "2024-06-10",
        "nepDate": "28.02.2081"
    },
    {
        "engDate": "2024-06-11",
        "nepDate": "29.02.2081"
    },
    {
        "engDate": "2024-06-12",
        "nepDate": "30.02.2081"
    },
    {
        "engDate": "2024-06-13",
        "nepDate": "31.02.2081"
    },
    {
        "engDate": "2024-06-14",
        "nepDate": "01.03.2081"
    },
    {
        "engDate": "2024-06-15",
        "nepDate": "02.03.2081"
    },
    {
        "engDate": "2024-06-16",
        "nepDate": "03.03.2081"
    },
    {
        "engDate": "2024-06-17",
        "nepDate": "04.03.2081"
    },
    {
        "engDate": "2024-06-18",
        "nepDate": "05.03.2081"
    },
    {
        "engDate": "2024-06-19",
        "nepDate": "06.03.2081"
    },
    {
        "engDate": "2024-06-20",
        "nepDate": "07.03.2081"
    },
    {
        "engDate": "2024-06-21",
        "nepDate": "08.03.2081"
    },
    {
        "engDate": "2024-06-22",
        "nepDate": "09.03.2081"
    },
    {
        "engDate": "2024-06-23",
        "nepDate": "10.03.2081"
    },
    {
        "engDate": "2024-06-24",
        "nepDate": "11.03.2081"
    },
    {
        "engDate": "2024-06-25",
        "nepDate": "12.03.2081"
    },
    {
        "engDate": "2024-06-26",
        "nepDate": "13.03.2081"
    },
    {
        "engDate": "2024-06-27",
        "nepDate": "14.03.2081"
    },
    {
        "engDate": "2024-06-28",
        "nepDate": "15.03.2081"
    },
    {
        "engDate": "2024-06-29",
        "nepDate": "16.03.2081"
    },
    {
        "engDate": "2024-06-30",
        "nepDate": "17.03.2081"
    },
    {
        "engDate": "2024-07-01",
        "nepDate": "18.03.2081"
    },
    {
        "engDate": "2024-07-02",
        "nepDate": "19.03.2081"
    },
    {
        "engDate": "2024-07-03",
        "nepDate": "20.03.2081"
    },
    {
        "engDate": "2024-07-04",
        "nepDate": "21.03.2081"
    },
    {
        "engDate": "2024-07-05",
        "nepDate": "22.03.2081"
    },
    {
        "engDate": "2024-07-06",
        "nepDate": "23.03.2081"
    },
    {
        "engDate": "2024-07-07",
        "nepDate": "24.03.2081"
    },
    {
        "engDate": "2024-07-08",
        "nepDate": "25.03.2081"
    },
    {
        "engDate": "2024-07-09",
        "nepDate": "26.03.2081"
    },
    {
        "engDate": "2024-07-10",
        "nepDate": "27.03.2081"
    },
    {
        "engDate": "2024-07-11",
        "nepDate": "28.03.2081"
    },
    {
        "engDate": "2024-07-12",
        "nepDate": "29.03.2081"
    },
    {
        "engDate": "2024-07-13",
        "nepDate": "30.03.2081"
    },
    {
        "engDate": "2024-07-14",
        "nepDate": "31.03.2081"
    },
    {
        "engDate": "2024-07-15",
        "nepDate": "32.03.2081"
    },
    {
        "engDate": "2024-07-16",
        "nepDate": "01.04.2081"
    },
    {
        "engDate": "2024-07-17",
        "nepDate": "02.04.2081"
    },
    {
        "engDate": "2024-07-18",
        "nepDate": "03.04.2081"
    },
    {
        "engDate": "2024-07-19",
        "nepDate": "04.04.2081"
    },
    {
        "engDate": "2024-07-20",
        "nepDate": "05.04.2081"
    },
    {
        "engDate": "2024-07-21",
        "nepDate": "06.04.2081"
    },
    {
        "engDate": "2024-07-22",
        "nepDate": "07.04.2081"
    },
    {
        "engDate": "2024-07-23",
        "nepDate": "08.04.2081"
    },
    {
        "engDate": "2024-07-24",
        "nepDate": "09.04.2081"
    },
    {
        "engDate": "2024-07-25",
        "nepDate": "10.04.2081"
    },
    {
        "engDate": "2024-07-26",
        "nepDate": "11.04.2081"
    },
    {
        "engDate": "2024-07-27",
        "nepDate": "12.04.2081"
    },
    {
        "engDate": "2024-07-28",
        "nepDate": "13.04.2081"
    },
    {
        "engDate": "2024-07-29",
        "nepDate": "14.04.2081"
    },
    {
        "engDate": "2024-07-30",
        "nepDate": "15.04.2081"
    },
    {
        "engDate": "2024-07-31",
        "nepDate": "16.04.2081"
    },
    {
        "engDate": "2024-08-01",
        "nepDate": "17.04.2081"
    },
    {
        "engDate": "2024-08-02",
        "nepDate": "18.04.2081"
    },
    {
        "engDate": "2024-08-03",
        "nepDate": "19.04.2081"
    },
    {
        "engDate": "2024-08-04",
        "nepDate": "20.04.2081"
    },
    {
        "engDate": "2024-08-05",
        "nepDate": "21.04.2081"
    },
    {
        "engDate": "2024-08-06",
        "nepDate": "22.04.2081"
    },
    {
        "engDate": "2024-08-07",
        "nepDate": "23.04.2081"
    },
    {
        "engDate": "2024-08-08",
        "nepDate": "24.04.2081"
    },
    {
        "engDate": "2024-08-09",
        "nepDate": "25.04.2081"
    },
    {
        "engDate": "2024-08-10",
        "nepDate": "26.04.2081"
    },
    {
        "engDate": "2024-08-11",
        "nepDate": "27.04.2081"
    },
    {
        "engDate": "2024-08-12",
        "nepDate": "28.04.2081"
    },
    {
        "engDate": "2024-08-13",
        "nepDate": "29.04.2081"
    },
    {
        "engDate": "2024-08-14",
        "nepDate": "30.04.2081"
    },
    {
        "engDate": "2024-08-15",
        "nepDate": "31.04.2081"
    },
    {
        "engDate": "2024-08-16",
        "nepDate": "32.04.2081"
    },
    {
        "engDate": "2024-08-17",
        "nepDate": "01.05.2081"
    },
    {
        "engDate": "2024-08-18",
        "nepDate": "02.05.2081"
    },
    {
        "engDate": "2024-08-19",
        "nepDate": "03.05.2081"
    },
    {
        "engDate": "2024-08-20",
        "nepDate": "04.05.2081"
    },
    {
        "engDate": "2024-08-21",
        "nepDate": "05.05.2081"
    },
    {
        "engDate": "2024-08-22",
        "nepDate": "06.05.2081"
    },
    {
        "engDate": "2024-08-23",
        "nepDate": "07.05.2081"
    },
    {
        "engDate": "2024-08-24",
        "nepDate": "08.05.2081"
    },
    {
        "engDate": "2024-08-25",
        "nepDate": "09.05.2081"
    },
    {
        "engDate": "2024-08-26",
        "nepDate": "10.05.2081"
    },
    {
        "engDate": "2024-08-27",
        "nepDate": "11.05.2081"
    },
    {
        "engDate": "2024-08-28",
        "nepDate": "12.05.2081"
    },
    {
        "engDate": "2024-08-29",
        "nepDate": "13.05.2081"
    },
    {
        "engDate": "2024-08-30",
        "nepDate": "14.05.2081"
    },
    {
        "engDate": "2024-08-31",
        "nepDate": "15.05.2081"
    },
    {
        "engDate": "2024-09-01",
        "nepDate": "16.05.2081"
    },
    {
        "engDate": "2024-09-02",
        "nepDate": "17.05.2081"
    },
    {
        "engDate": "2024-09-03",
        "nepDate": "18.05.2081"
    },
    {
        "engDate": "2024-09-04",
        "nepDate": "19.05.2081"
    },
    {
        "engDate": "2024-09-05",
        "nepDate": "20.05.2081"
    },
    {
        "engDate": "2024-09-06",
        "nepDate": "21.05.2081"
    },
    {
        "engDate": "2024-09-07",
        "nepDate": "22.05.2081"
    },
    {
        "engDate": "2024-09-08",
        "nepDate": "23.05.2081"
    },
    {
        "engDate": "2024-09-09",
        "nepDate": "24.05.2081"
    },
    {
        "engDate": "2024-09-10",
        "nepDate": "25.05.2081"
    },
    {
        "engDate": "2024-09-11",
        "nepDate": "26.05.2081"
    },
    {
        "engDate": "2024-09-12",
        "nepDate": "27.05.2081"
    },
    {
        "engDate": "2024-09-13",
        "nepDate": "28.05.2081"
    },
    {
        "engDate": "2024-09-14",
        "nepDate": "29.05.2081"
    },
    {
        "engDate": "2024-09-15",
        "nepDate": "30.05.2081"
    },
    {
        "engDate": "2024-09-16",
        "nepDate": "31.05.2081"
    },
    {
        "engDate": "2024-09-17",
        "nepDate": "01.06.2081"
    },
    {
        "engDate": "2024-09-18",
        "nepDate": "02.06.2081"
    },
    {
        "engDate": "2024-09-19",
        "nepDate": "03.06.2081"
    },
    {
        "engDate": "2024-09-20",
        "nepDate": "04.06.2081"
    },
    {
        "engDate": "2024-09-21",
        "nepDate": "05.06.2081"
    },
    {
        "engDate": "2024-09-22",
        "nepDate": "06.06.2081"
    },
    {
        "engDate": "2024-09-23",
        "nepDate": "07.06.2081"
    },
    {
        "engDate": "2024-09-24",
        "nepDate": "08.06.2081"
    },
    {
        "engDate": "2024-09-25",
        "nepDate": "09.06.2081"
    },
    {
        "engDate": "2024-09-26",
        "nepDate": "10.06.2081"
    },
    {
        "engDate": "2024-09-27",
        "nepDate": "11.06.2081"
    },
    {
        "engDate": "2024-09-28",
        "nepDate": "12.06.2081"
    },
    {
        "engDate": "2024-09-29",
        "nepDate": "13.06.2081"
    },
    {
        "engDate": "2024-09-30",
        "nepDate": "14.06.2081"
    },
    {
        "engDate": "2024-10-01",
        "nepDate": "15.06.2081"
    },
    {
        "engDate": "2024-10-02",
        "nepDate": "16.06.2081"
    },
    {
        "engDate": "2024-10-03",
        "nepDate": "17.06.2081"
    },
    {
        "engDate": "2024-10-04",
        "nepDate": "18.06.2081"
    },
    {
        "engDate": "2024-10-05",
        "nepDate": "19.06.2081"
    },
    {
        "engDate": "2024-10-06",
        "nepDate": "20.06.2081"
    },
    {
        "engDate": "2024-10-07",
        "nepDate": "21.06.2081"
    },
    {
        "engDate": "2024-10-08",
        "nepDate": "22.06.2081"
    },
    {
        "engDate": "2024-10-09",
        "nepDate": "23.06.2081"
    },
    {
        "engDate": "2024-10-10",
        "nepDate": "24.06.2081"
    },
    {
        "engDate": "2024-10-11",
        "nepDate": "25.06.2081"
    },
    {
        "engDate": "2024-10-12",
        "nepDate": "26.06.2081"
    },
    {
        "engDate": "2024-10-13",
        "nepDate": "27.06.2081"
    },
    {
        "engDate": "2024-10-14",
        "nepDate": "28.06.2081"
    },
    {
        "engDate": "2024-10-15",
        "nepDate": "29.06.2081"
    },
    {
        "engDate": "2024-10-16",
        "nepDate": "30.06.2081"
    },
    {
        "engDate": "2024-10-17",
        "nepDate": "01.07.2081"
    },
    {
        "engDate": "2024-10-18",
        "nepDate": "02.07.2081"
    },
    {
        "engDate": "2024-10-19",
        "nepDate": "03.07.2081"
    },
    {
        "engDate": "2024-10-20",
        "nepDate": "04.07.2081"
    },
    {
        "engDate": "2024-10-21",
        "nepDate": "05.07.2081"
    },
    {
        "engDate": "2024-10-22",
        "nepDate": "06.07.2081"
    },
    {
        "engDate": "2024-10-23",
        "nepDate": "07.07.2081"
    },
    {
        "engDate": "2024-10-24",
        "nepDate": "08.07.2081"
    },
    {
        "engDate": "2024-10-25",
        "nepDate": "09.07.2081"
    },
    {
        "engDate": "2024-10-26",
        "nepDate": "10.07.2081"
    },
    {
        "engDate": "2024-10-27",
        "nepDate": "11.07.2081"
    },
    {
        "engDate": "2024-10-28",
        "nepDate": "12.07.2081"
    },
    {
        "engDate": "2024-10-29",
        "nepDate": "13.07.2081"
    },
    {
        "engDate": "2024-10-30",
        "nepDate": "14.07.2081"
    },
    {
        "engDate": "2024-10-31",
        "nepDate": "15.07.2081"
    },
    {
        "engDate": "2024-11-01",
        "nepDate": "16.07.2081"
    },
    {
        "engDate": "2024-11-02",
        "nepDate": "17.07.2081"
    },
    {
        "engDate": "2024-11-03",
        "nepDate": "18.07.2081"
    },
    {
        "engDate": "2024-11-04",
        "nepDate": "19.07.2081"
    },
    {
        "engDate": "2024-11-05",
        "nepDate": "20.07.2081"
    },
    {
        "engDate": "2024-11-06",
        "nepDate": "21.07.2081"
    },
    {
        "engDate": "2024-11-07",
        "nepDate": "22.07.2081"
    },
    {
        "engDate": "2024-11-08",
        "nepDate": "23.07.2081"
    },
    {
        "engDate": "2024-11-09",
        "nepDate": "24.07.2081"
    },
    {
        "engDate": "2024-11-10",
        "nepDate": "25.07.2081"
    },
    {
        "engDate": "2024-11-11",
        "nepDate": "26.07.2081"
    },
    {
        "engDate": "2024-11-12",
        "nepDate": "27.07.2081"
    },
    {
        "engDate": "2024-11-13",
        "nepDate": "28.07.2081"
    },
    {
        "engDate": "2024-11-14",
        "nepDate": "29.07.2081"
    },
    {
        "engDate": "2024-11-15",
        "nepDate": "30.07.2081"
    },
    {
        "engDate": "2024-11-16",
        "nepDate": "01.08.2081"
    },
    {
        "engDate": "2024-11-17",
        "nepDate": "02.08.2081"
    },
    {
        "engDate": "2024-11-18",
        "nepDate": "03.08.2081"
    },
    {
        "engDate": "2024-11-19",
        "nepDate": "04.08.2081"
    },
    {
        "engDate": "2024-11-20",
        "nepDate": "05.08.2081"
    },
    {
        "engDate": "2024-11-21",
        "nepDate": "06.08.2081"
    },
    {
        "engDate": "2024-11-22",
        "nepDate": "07.08.2081"
    },
    {
        "engDate": "2024-11-23",
        "nepDate": "08.08.2081"
    },
    {
        "engDate": "2024-11-24",
        "nepDate": "09.08.2081"
    },
    {
        "engDate": "2024-11-25",
        "nepDate": "10.08.2081"
    },
    {
        "engDate": "2024-11-26",
        "nepDate": "11.08.2081"
    },
    {
        "engDate": "2024-11-27",
        "nepDate": "12.08.2081"
    },
    {
        "engDate": "2024-11-28",
        "nepDate": "13.08.2081"
    },
    {
        "engDate": "2024-11-29",
        "nepDate": "14.08.2081"
    },
    {
        "engDate": "2024-11-30",
        "nepDate": "15.08.2081"
    },
    {
        "engDate": "2024-12-01",
        "nepDate": "16.08.2081"
    },
    {
        "engDate": "2024-12-02",
        "nepDate": "17.08.2081"
    },
    {
        "engDate": "2024-12-03",
        "nepDate": "18.08.2081"
    },
    {
        "engDate": "2024-12-04",
        "nepDate": "19.08.2081"
    },
    {
        "engDate": "2024-12-05",
        "nepDate": "20.08.2081"
    },
    {
        "engDate": "2024-12-06",
        "nepDate": "21.08.2081"
    },
    {
        "engDate": "2024-12-07",
        "nepDate": "22.08.2081"
    },
    {
        "engDate": "2024-12-08",
        "nepDate": "23.08.2081"
    },
    {
        "engDate": "2024-12-09",
        "nepDate": "24.08.2081"
    },
    {
        "engDate": "2024-12-10",
        "nepDate": "25.08.2081"
    },
    {
        "engDate": "2024-12-11",
        "nepDate": "26.08.2081"
    },
    {
        "engDate": "2024-12-12",
        "nepDate": "27.08.2081"
    },
    {
        "engDate": "2024-12-13",
        "nepDate": "28.08.2081"
    },
    {
        "engDate": "2024-12-14",
        "nepDate": "29.08.2081"
    },
    {
        "engDate": "2024-12-15",
        "nepDate": "30.08.2081"
    },
    {
        "engDate": "2024-12-16",
        "nepDate": "01.09.2081"
    },
    {
        "engDate": "2024-12-17",
        "nepDate": "02.09.2081"
    },
    {
        "engDate": "2024-12-18",
        "nepDate": "03.09.2081"
    },
    {
        "engDate": "2024-12-19",
        "nepDate": "04.09.2081"
    },
    {
        "engDate": "2024-12-20",
        "nepDate": "05.09.2081"
    },
    {
        "engDate": "2024-12-21",
        "nepDate": "06.09.2081"
    },
    {
        "engDate": "2024-12-22",
        "nepDate": "07.09.2081"
    },
    {
        "engDate": "2024-12-23",
        "nepDate": "08.09.2081"
    },
    {
        "engDate": "2024-12-24",
        "nepDate": "09.09.2081"
    },
    {
        "engDate": "2024-12-25",
        "nepDate": "10.09.2081"
    },
    {
        "engDate": "2024-12-26",
        "nepDate": "11.09.2081"
    },
    {
        "engDate": "2024-12-27",
        "nepDate": "12.09.2081"
    },
    {
        "engDate": "2024-12-28",
        "nepDate": "13.09.2081"
    },
    {
        "engDate": "2024-12-29",
        "nepDate": "14.09.2081"
    },
    {
        "engDate": "2024-12-30",
        "nepDate": "15.09.2081"
    },
    {
        "engDate": "2024-12-31",
        "nepDate": "16.09.2081"
    },
    {
        "engDate": "2025-01-01",
        "nepDate": "17.09.2081"
    },
    {
        "engDate": "2025-01-02",
        "nepDate": "18.09.2081"
    },
    {
        "engDate": "2025-01-03",
        "nepDate": "19.09.2081"
    },
    {
        "engDate": "2025-01-04",
        "nepDate": "20.09.2081"
    },
    {
        "engDate": "2025-01-05",
        "nepDate": "21.09.2081"
    },
    {
        "engDate": "2025-01-06",
        "nepDate": "22.09.2081"
    },
    {
        "engDate": "2025-01-07",
        "nepDate": "23.09.2081"
    },
    {
        "engDate": "2025-01-08",
        "nepDate": "24.09.2081"
    },
    {
        "engDate": "2025-01-09",
        "nepDate": "25.09.2081"
    },
    {
        "engDate": "2025-01-10",
        "nepDate": "26.09.2081"
    },
    {
        "engDate": "2025-01-11",
        "nepDate": "27.09.2081"
    },
    {
        "engDate": "2025-01-12",
        "nepDate": "28.09.2081"
    },
    {
        "engDate": "2025-01-13",
        "nepDate": "29.09.2081"
    },
    {
        "engDate": "2025-01-14",
        "nepDate": "01.10.2081"
    },
    {
        "engDate": "2025-01-15",
        "nepDate": "02.10.2081"
    },
    {
        "engDate": "2025-01-16",
        "nepDate": "03.10.2081"
    },
    {
        "engDate": "2025-01-17",
        "nepDate": "04.10.2081"
    },
    {
        "engDate": "2025-01-18",
        "nepDate": "05.10.2081"
    },
    {
        "engDate": "2025-01-19",
        "nepDate": "06.10.2081"
    },
    {
        "engDate": "2025-01-20",
        "nepDate": "07.10.2081"
    },
    {
        "engDate": "2025-01-21",
        "nepDate": "08.10.2081"
    },
    {
        "engDate": "2025-01-22",
        "nepDate": "09.10.2081"
    },
    {
        "engDate": "2025-01-23",
        "nepDate": "10.10.2081"
    },
    {
        "engDate": "2025-01-24",
        "nepDate": "11.10.2081"
    },
    {
        "engDate": "2025-01-25",
        "nepDate": "12.10.2081"
    },
    {
        "engDate": "2025-01-26",
        "nepDate": "13.10.2081"
    },
    {
        "engDate": "2025-01-27",
        "nepDate": "14.10.2081"
    },
    {
        "engDate": "2025-01-28",
        "nepDate": "15.10.2081"
    },
    {
        "engDate": "2025-01-29",
        "nepDate": "16.10.2081"
    },
    {
        "engDate": "2025-01-30",
        "nepDate": "17.10.2081"
    },
    {
        "engDate": "2025-01-31",
        "nepDate": "18.10.2081"
    },
    {
        "engDate": "2025-02-01",
        "nepDate": "19.10.2081"
    },
    {
        "engDate": "2025-02-02",
        "nepDate": "20.10.2081"
    },
    {
        "engDate": "2025-02-03",
        "nepDate": "21.10.2081"
    },
    {
        "engDate": "2025-02-04",
        "nepDate": "22.10.2081"
    },
    {
        "engDate": "2025-02-05",
        "nepDate": "23.10.2081"
    },
    {
        "engDate": "2025-02-06",
        "nepDate": "24.10.2081"
    },
    {
        "engDate": "2025-02-07",
        "nepDate": "25.10.2081"
    },
    {
        "engDate": "2025-02-08",
        "nepDate": "26.10.2081"
    },
    {
        "engDate": "2025-02-09",
        "nepDate": "27.10.2081"
    },
    {
        "engDate": "2025-02-10",
        "nepDate": "28.10.2081"
    },
    {
        "engDate": "2025-02-11",
        "nepDate": "29.10.2081"
    },
    {
        "engDate": "2025-02-12",
        "nepDate": "30.10.2081"
    },
    {
        "engDate": "2025-02-13",
        "nepDate": "01.11.2081"
    },
    {
        "engDate": "2025-02-14",
        "nepDate": "02.11.2081"
    },
    {
        "engDate": "2025-02-15",
        "nepDate": "03.11.2081"
    },
    {
        "engDate": "2025-02-16",
        "nepDate": "04.11.2081"
    },
    {
        "engDate": "2025-02-17",
        "nepDate": "05.11.2081"
    },
    {
        "engDate": "2025-02-18",
        "nepDate": "06.11.2081"
    },
    {
        "engDate": "2025-02-19",
        "nepDate": "07.11.2081"
    },
    {
        "engDate": "2025-02-20",
        "nepDate": "08.11.2081"
    },
    {
        "engDate": "2025-02-21",
        "nepDate": "09.11.2081"
    },
    {
        "engDate": "2025-02-22",
        "nepDate": "10.11.2081"
    },
    {
        "engDate": "2025-02-23",
        "nepDate": "11.11.2081"
    },
    {
        "engDate": "2025-02-24",
        "nepDate": "12.11.2081"
    },
    {
        "engDate": "2025-02-25",
        "nepDate": "13.11.2081"
    },
    {
        "engDate": "2025-02-26",
        "nepDate": "14.11.2081"
    },
    {
        "engDate": "2025-02-27",
        "nepDate": "15.11.2081"
    },
    {
        "engDate": "2025-02-28",
        "nepDate": "16.11.2081"
    },
    {
        "engDate": "2025-03-01",
        "nepDate": "17.11.2081"
    },
    {
        "engDate": "2025-03-02",
        "nepDate": "18.11.2081"
    },
    {
        "engDate": "2025-03-03",
        "nepDate": "19.11.2081"
    },
    {
        "engDate": "2025-03-04",
        "nepDate": "20.11.2081"
    },
    {
        "engDate": "2025-03-05",
        "nepDate": "21.11.2081"
    },
    {
        "engDate": "2025-03-06",
        "nepDate": "22.11.2081"
    },
    {
        "engDate": "2025-03-07",
        "nepDate": "23.11.2081"
    },
    {
        "engDate": "2025-03-08",
        "nepDate": "24.11.2081"
    },
    {
        "engDate": "2025-03-09",
        "nepDate": "25.11.2081"
    },
    {
        "engDate": "2025-03-10",
        "nepDate": "26.11.2081"
    },
    {
        "engDate": "2025-03-11",
        "nepDate": "27.11.2081"
    },
    {
        "engDate": "2025-03-12",
        "nepDate": "28.11.2081"
    },
    {
        "engDate": "2025-03-13",
        "nepDate": "29.11.2081"
    },
    {
        "engDate": "2025-03-14",
        "nepDate": "30.11.2081"
    },
    {
        "engDate": "2025-03-15",
        "nepDate": "01.12.2081"
    },
    {
        "engDate": "2025-03-16",
        "nepDate": "02.12.2081"
    },
    {
        "engDate": "2025-03-17",
        "nepDate": "03.12.2081"
    },
    {
        "engDate": "2025-03-18",
        "nepDate": "04.12.2081"
    },
    {
        "engDate": "2025-03-19",
        "nepDate": "05.12.2081"
    },
    {
        "engDate": "2025-03-20",
        "nepDate": "06.12.2081"
    },
    {
        "engDate": "2025-03-21",
        "nepDate": "07.12.2081"
    },
    {
        "engDate": "2025-03-22",
        "nepDate": "08.12.2081"
    },
    {
        "engDate": "2025-03-23",
        "nepDate": "09.12.2081"
    },
    {
        "engDate": "2025-03-24",
        "nepDate": "10.12.2081"
    },
    {
        "engDate": "2025-03-25",
        "nepDate": "11.12.2081"
    },
    {
        "engDate": "2025-03-26",
        "nepDate": "12.12.2081"
    },
    {
        "engDate": "2025-03-27",
        "nepDate": "13.12.2081"
    },
    {
        "engDate": "2025-03-28",
        "nepDate": "14.12.2081"
    },
    {
        "engDate": "2025-03-29",
        "nepDate": "15.12.2081"
    },
    {
        "engDate": "2025-03-30",
        "nepDate": "16.12.2081"
    },
    {
        "engDate": "2025-03-31",
        "nepDate": "17.12.2081"
    },
    {
        "engDate": "2025-04-01",
        "nepDate": "18.12.2081"
    },
    {
        "engDate": "2025-04-02",
        "nepDate": "19.12.2081"
    },
    {
        "engDate": "2025-04-03",
        "nepDate": "20.12.2081"
    },
    {
        "engDate": "2025-04-04",
        "nepDate": "21.12.2081"
    },
    {
        "engDate": "2025-04-05",
        "nepDate": "22.12.2081"
    },
    {
        "engDate": "2025-04-06",
        "nepDate": "23.12.2081"
    },
    {
        "engDate": "2025-04-07",
        "nepDate": "24.12.2081"
    },
    {
        "engDate": "2025-04-08",
        "nepDate": "25.12.2081"
    },
    {
        "engDate": "2025-04-09",
        "nepDate": "26.12.2081"
    },
    {
        "engDate": "2025-04-10",
        "nepDate": "27.12.2081"
    },
    {
        "engDate": "2025-04-11",
        "nepDate": "28.12.2081"
    },
    {
        "engDate": "2025-04-12",
        "nepDate": "29.12.2081"
    },
    {
        "engDate": "2025-04-13",
        "nepDate": "30.12.2081"
    },
    {
        "engDate": "2025-04-14",
        "nepDate": "01.01.2082"
    },
    {
        "engDate": "2025-04-15",
        "nepDate": "02.01.2082"
    },
    {
        "engDate": "2025-04-16",
        "nepDate": "03.01.2082"
    },
    {
        "engDate": "2025-04-17",
        "nepDate": "04.01.2082"
    },
    {
        "engDate": "2025-04-18",
        "nepDate": "05.01.2082"
    },
    {
        "engDate": "2025-04-19",
        "nepDate": "06.01.2082"
    },
    {
        "engDate": "2025-04-20",
        "nepDate": "07.01.2082"
    },
    {
        "engDate": "2025-04-21",
        "nepDate": "08.01.2082"
    },
    {
        "engDate": "2025-04-22",
        "nepDate": "09.01.2082"
    },
    {
        "engDate": "2025-04-23",
        "nepDate": "10.01.2082"
    },
    {
        "engDate": "2025-04-24",
        "nepDate": "11.01.2082"
    },
    {
        "engDate": "2025-04-25",
        "nepDate": "12.01.2082"
    },
    {
        "engDate": "2025-04-26",
        "nepDate": "13.01.2082"
    },
    {
        "engDate": "2025-04-27",
        "nepDate": "14.01.2082"
    },
    {
        "engDate": "2025-04-28",
        "nepDate": "15.01.2082"
    },
    {
        "engDate": "2025-04-29",
        "nepDate": "16.01.2082"
    },
    {
        "engDate": "2025-04-30",
        "nepDate": "17.01.2082"
    },
    {
        "engDate": "2025-05-01",
        "nepDate": "18.01.2082"
    },
    {
        "engDate": "2025-05-02",
        "nepDate": "19.01.2082"
    },
    {
        "engDate": "2025-05-03",
        "nepDate": "20.01.2082"
    },
    {
        "engDate": "2025-05-04",
        "nepDate": "21.01.2082"
    },
    {
        "engDate": "2025-05-05",
        "nepDate": "22.01.2082"
    },
    {
        "engDate": "2025-05-06",
        "nepDate": "23.01.2082"
    },
    {
        "engDate": "2025-05-07",
        "nepDate": "24.01.2082"
    },
    {
        "engDate": "2025-05-08",
        "nepDate": "25.01.2082"
    },
    {
        "engDate": "2025-05-09",
        "nepDate": "26.01.2082"
    },
    {
        "engDate": "2025-05-10",
        "nepDate": "27.01.2082"
    },
    {
        "engDate": "2025-05-11",
        "nepDate": "28.01.2082"
    },
    {
        "engDate": "2025-05-12",
        "nepDate": "29.01.2082"
    },
    {
        "engDate": "2025-05-13",
        "nepDate": "30.01.2082"
    },
    {
        "engDate": "2025-05-14",
        "nepDate": "01.02.2082"
    },
    {
        "engDate": "2025-05-15",
        "nepDate": "02.02.2082"
    },
    {
        "engDate": "2025-05-16",
        "nepDate": "03.02.2082"
    },
    {
        "engDate": "2025-05-17",
        "nepDate": "04.02.2082"
    },
    {
        "engDate": "2025-05-18",
        "nepDate": "05.02.2082"
    },
    {
        "engDate": "2025-05-19",
        "nepDate": "06.02.2082"
    },
    {
        "engDate": "2025-05-20",
        "nepDate": "07.02.2082"
    },
    {
        "engDate": "2025-05-21",
        "nepDate": "08.02.2082"
    },
    {
        "engDate": "2025-05-22",
        "nepDate": "09.02.2082"
    },
    {
        "engDate": "2025-05-23",
        "nepDate": "10.02.2082"
    },
    {
        "engDate": "2025-05-24",
        "nepDate": "11.02.2082"
    },
    {
        "engDate": "2025-05-25",
        "nepDate": "12.02.2082"
    },
    {
        "engDate": "2025-05-26",
        "nepDate": "13.02.2082"
    },
    {
        "engDate": "2025-05-27",
        "nepDate": "14.02.2082"
    },
    {
        "engDate": "2025-05-28",
        "nepDate": "15.02.2082"
    },
    {
        "engDate": "2025-05-29",
        "nepDate": "16.02.2082"
    },
    {
        "engDate": "2025-05-30",
        "nepDate": "17.02.2082"
    },
    {
        "engDate": "2025-05-31",
        "nepDate": "18.02.2082"
    },
    {
        "engDate": "2025-06-01",
        "nepDate": "19.02.2082"
    },
    {
        "engDate": "2025-06-02",
        "nepDate": "20.02.2082"
    },
    {
        "engDate": "2025-06-03",
        "nepDate": "21.02.2082"
    },
    {
        "engDate": "2025-06-04",
        "nepDate": "22.02.2082"
    },
    {
        "engDate": "2025-06-05",
        "nepDate": "23.02.2082"
    },
    {
        "engDate": "2025-06-06",
        "nepDate": "24.02.2082"
    },
    {
        "engDate": "2025-06-07",
        "nepDate": "25.02.2082"
    },
    {
        "engDate": "2025-06-08",
        "nepDate": "26.02.2082"
    },
    {
        "engDate": "2025-06-09",
        "nepDate": "27.02.2082"
    },
    {
        "engDate": "2025-06-10",
        "nepDate": "28.02.2082"
    },
    {
        "engDate": "2025-06-11",
        "nepDate": "29.02.2082"
    },
    {
        "engDate": "2025-06-12",
        "nepDate": "30.02.2082"
    },
    {
        "engDate": "2025-06-13",
        "nepDate": "31.02.2082"
    },
    {
        "engDate": "2025-06-14",
        "nepDate": "32.02.2082"
    },
    {
        "engDate": "2025-06-15",
        "nepDate": "01.03.2082"
    },
    {
        "engDate": "2025-06-16",
        "nepDate": "02.03.2082"
    },
    {
        "engDate": "2025-06-17",
        "nepDate": "03.03.2082"
    },
    {
        "engDate": "2025-06-18",
        "nepDate": "04.03.2082"
    },
    {
        "engDate": "2025-06-19",
        "nepDate": "05.03.2082"
    },
    {
        "engDate": "2025-06-20",
        "nepDate": "06.03.2082"
    },
    {
        "engDate": "2025-06-21",
        "nepDate": "07.03.2082"
    },
    {
        "engDate": "2025-06-22",
        "nepDate": "08.03.2082"
    },
    {
        "engDate": "2025-06-23",
        "nepDate": "09.03.2082"
    },
    {
        "engDate": "2025-06-24",
        "nepDate": "10.03.2082"
    },
    {
        "engDate": "2025-06-25",
        "nepDate": "11.03.2082"
    },
    {
        "engDate": "2025-06-26",
        "nepDate": "12.03.2082"
    },
    {
        "engDate": "2025-06-27",
        "nepDate": "13.03.2082"
    },
    {
        "engDate": "2025-06-28",
        "nepDate": "14.03.2082"
    },
    {
        "engDate": "2025-06-29",
        "nepDate": "15.03.2082"
    },
    {
        "engDate": "2025-06-30",
        "nepDate": "16.03.2082"
    },
    {
        "engDate": "2025-07-01",
        "nepDate": "17.03.2082"
    },
    {
        "engDate": "2025-07-02",
        "nepDate": "18.03.2082"
    },
    {
        "engDate": "2025-07-03",
        "nepDate": "19.03.2082"
    },
    {
        "engDate": "2025-07-04",
        "nepDate": "20.03.2082"
    },
    {
        "engDate": "2025-07-05",
        "nepDate": "21.03.2082"
    },
    {
        "engDate": "2025-07-06",
        "nepDate": "22.03.2082"
    },
    {
        "engDate": "2025-07-07",
        "nepDate": "23.03.2082"
    },
    {
        "engDate": "2025-07-08",
        "nepDate": "24.03.2082"
    },
    {
        "engDate": "2025-07-09",
        "nepDate": "25.03.2082"
    },
    {
        "engDate": "2025-07-10",
        "nepDate": "26.03.2082"
    },
    {
        "engDate": "2025-07-11",
        "nepDate": "27.03.2082"
    },
    {
        "engDate": "2025-07-12",
        "nepDate": "28.03.2082"
    },
    {
        "engDate": "2025-07-13",
        "nepDate": "29.03.2082"
    },
    {
        "engDate": "2025-07-14",
        "nepDate": "30.03.2082"
    },
    {
        "engDate": "2025-07-15",
        "nepDate": "31.03.2082"
    },
    {
        "engDate": "2025-07-16",
        "nepDate": "01.04.2082"
    },
    {
        "engDate": "2025-07-17",
        "nepDate": "02.04.2082"
    },
    {
        "engDate": "2025-07-18",
        "nepDate": "03.04.2082"
    },
    {
        "engDate": "2025-07-19",
        "nepDate": "04.04.2082"
    },
    {
        "engDate": "2025-07-20",
        "nepDate": "05.04.2082"
    },
    {
        "engDate": "2025-07-21",
        "nepDate": "06.04.2082"
    },
    {
        "engDate": "2025-07-22",
        "nepDate": "07.04.2082"
    },
    {
        "engDate": "2025-07-23",
        "nepDate": "08.04.2082"
    },
    {
        "engDate": "2025-07-24",
        "nepDate": "09.04.2082"
    },
    {
        "engDate": "2025-07-25",
        "nepDate": "10.04.2082"
    },
    {
        "engDate": "2025-07-26",
        "nepDate": "11.04.2082"
    },
    {
        "engDate": "2025-07-27",
        "nepDate": "12.04.2082"
    },
    {
        "engDate": "2025-07-28",
        "nepDate": "13.04.2082"
    },
    {
        "engDate": "2025-07-29",
        "nepDate": "14.04.2082"
    },
    {
        "engDate": "2025-07-30",
        "nepDate": "15.04.2082"
    },
    {
        "engDate": "2025-07-31",
        "nepDate": "16.04.2082"
    },
    {
        "engDate": "2025-08-01",
        "nepDate": "17.04.2082"
    },
    {
        "engDate": "2025-08-02",
        "nepDate": "18.04.2082"
    },
    {
        "engDate": "2025-08-03",
        "nepDate": "19.04.2082"
    },
    {
        "engDate": "2025-08-04",
        "nepDate": "20.04.2082"
    },
    {
        "engDate": "2025-08-05",
        "nepDate": "21.04.2082"
    },
    {
        "engDate": "2025-08-06",
        "nepDate": "22.04.2082"
    },
    {
        "engDate": "2025-08-07",
        "nepDate": "23.04.2082"
    },
    {
        "engDate": "2025-08-08",
        "nepDate": "24.04.2082"
    },
    {
        "engDate": "2025-08-09",
        "nepDate": "25.04.2082"
    },
    {
        "engDate": "2025-08-10",
        "nepDate": "26.04.2082"
    },
    {
        "engDate": "2025-08-11",
        "nepDate": "27.04.2082"
    },
    {
        "engDate": "2025-08-12",
        "nepDate": "28.04.2082"
    },
    {
        "engDate": "2025-08-13",
        "nepDate": "29.04.2082"
    },
    {
        "engDate": "2025-08-14",
        "nepDate": "30.04.2082"
    },
    {
        "engDate": "2025-08-15",
        "nepDate": "31.04.2082"
    },
    {
        "engDate": "2025-08-16",
        "nepDate": "32.04.2082"
    },
    {
        "engDate": "2025-08-17",
        "nepDate": "01.05.2082"
    },
    {
        "engDate": "2025-08-18",
        "nepDate": "02.05.2082"
    },
    {
        "engDate": "2025-08-19",
        "nepDate": "03.05.2082"
    },
    {
        "engDate": "2025-08-20",
        "nepDate": "04.05.2082"
    },
    {
        "engDate": "2025-08-21",
        "nepDate": "05.05.2082"
    },
    {
        "engDate": "2025-08-22",
        "nepDate": "06.05.2082"
    },
    {
        "engDate": "2025-08-23",
        "nepDate": "07.05.2082"
    },
    {
        "engDate": "2025-08-24",
        "nepDate": "08.05.2082"
    },
    {
        "engDate": "2025-08-25",
        "nepDate": "09.05.2082"
    },
    {
        "engDate": "2025-08-26",
        "nepDate": "10.05.2082"
    },
    {
        "engDate": "2025-08-27",
        "nepDate": "11.05.2082"
    },
    {
        "engDate": "2025-08-28",
        "nepDate": "12.05.2082"
    },
    {
        "engDate": "2025-08-29",
        "nepDate": "13.05.2082"
    },
    {
        "engDate": "2025-08-30",
        "nepDate": "14.05.2082"
    },
    {
        "engDate": "2025-08-31",
        "nepDate": "15.05.2082"
    },
    {
        "engDate": "2025-09-01",
        "nepDate": "16.05.2082"
    },
    {
        "engDate": "2025-09-02",
        "nepDate": "17.05.2082"
    },
    {
        "engDate": "2025-09-03",
        "nepDate": "18.05.2082"
    },
    {
        "engDate": "2025-09-04",
        "nepDate": "19.05.2082"
    },
    {
        "engDate": "2025-09-05",
        "nepDate": "20.05.2082"
    },
    {
        "engDate": "2025-09-06",
        "nepDate": "21.05.2082"
    },
    {
        "engDate": "2025-09-07",
        "nepDate": "22.05.2082"
    },
    {
        "engDate": "2025-09-08",
        "nepDate": "23.05.2082"
    },
    {
        "engDate": "2025-09-09",
        "nepDate": "24.05.2082"
    },
    {
        "engDate": "2025-09-10",
        "nepDate": "25.05.2082"
    },
    {
        "engDate": "2025-09-11",
        "nepDate": "26.05.2082"
    },
    {
        "engDate": "2025-09-12",
        "nepDate": "27.05.2082"
    },
    {
        "engDate": "2025-09-13",
        "nepDate": "28.05.2082"
    },
    {
        "engDate": "2025-09-14",
        "nepDate": "29.05.2082"
    },
    {
        "engDate": "2025-09-15",
        "nepDate": "30.05.2082"
    },
    {
        "engDate": "2025-09-16",
        "nepDate": "31.05.2082"
    },
    {
        "engDate": "2025-09-17",
        "nepDate": "01.06.2082"
    },
    {
        "engDate": "2025-09-18",
        "nepDate": "02.06.2082"
    },
    {
        "engDate": "2025-09-19",
        "nepDate": "03.06.2082"
    },
    {
        "engDate": "2025-09-20",
        "nepDate": "04.06.2082"
    },
    {
        "engDate": "2025-09-21",
        "nepDate": "05.06.2082"
    },
    {
        "engDate": "2025-09-22",
        "nepDate": "06.06.2082"
    },
    {
        "engDate": "2025-09-23",
        "nepDate": "07.06.2082"
    },
    {
        "engDate": "2025-09-24",
        "nepDate": "08.06.2082"
    },
    {
        "engDate": "2025-09-25",
        "nepDate": "09.06.2082"
    },
    {
        "engDate": "2025-09-26",
        "nepDate": "10.06.2082"
    },
    {
        "engDate": "2025-09-27",
        "nepDate": "11.06.2082"
    },
    {
        "engDate": "2025-09-28",
        "nepDate": "12.06.2082"
    },
    {
        "engDate": "2025-09-29",
        "nepDate": "13.06.2082"
    },
    {
        "engDate": "2025-09-30",
        "nepDate": "14.06.2082"
    },
    {
        "engDate": "2025-10-01",
        "nepDate": "15.06.2082"
    },
    {
        "engDate": "2025-10-02",
        "nepDate": "16.06.2082"
    },
    {
        "engDate": "2025-10-03",
        "nepDate": "17.06.2082"
    },
    {
        "engDate": "2025-10-04",
        "nepDate": "18.06.2082"
    },
    {
        "engDate": "2025-10-05",
        "nepDate": "19.06.2082"
    },
    {
        "engDate": "2025-10-06",
        "nepDate": "20.06.2082"
    },
    {
        "engDate": "2025-10-07",
        "nepDate": "21.06.2082"
    },
    {
        "engDate": "2025-10-08",
        "nepDate": "22.06.2082"
    },
    {
        "engDate": "2025-10-09",
        "nepDate": "23.06.2082"
    },
    {
        "engDate": "2025-10-10",
        "nepDate": "24.06.2082"
    },
    {
        "engDate": "2025-10-11",
        "nepDate": "25.06.2082"
    },
    {
        "engDate": "2025-10-12",
        "nepDate": "26.06.2082"
    },
    {
        "engDate": "2025-10-13",
        "nepDate": "27.06.2082"
    },
    {
        "engDate": "2025-10-14",
        "nepDate": "28.06.2082"
    },
    {
        "engDate": "2025-10-15",
        "nepDate": "29.06.2082"
    },
    {
        "engDate": "2025-10-16",
        "nepDate": "30.06.2082"
    },
    {
        "engDate": "2025-10-17",
        "nepDate": "01.07.2082"
    },
    {
        "engDate": "2025-10-18",
        "nepDate": "02.07.2082"
    },
    {
        "engDate": "2025-10-19",
        "nepDate": "03.07.2082"
    },
    {
        "engDate": "2025-10-20",
        "nepDate": "04.07.2082"
    },
    {
        "engDate": "2025-10-21",
        "nepDate": "05.07.2082"
    },
    {
        "engDate": "2025-10-22",
        "nepDate": "06.07.2082"
    },
    {
        "engDate": "2025-10-23",
        "nepDate": "07.07.2082"
    },
    {
        "engDate": "2025-10-24",
        "nepDate": "08.07.2082"
    },
    {
        "engDate": "2025-10-25",
        "nepDate": "09.07.2082"
    },
    {
        "engDate": "2025-10-26",
        "nepDate": "10.07.2082"
    },
    {
        "engDate": "2025-10-27",
        "nepDate": "11.07.2082"
    },
    {
        "engDate": "2025-10-28",
        "nepDate": "12.07.2082"
    },
    {
        "engDate": "2025-10-29",
        "nepDate": "13.07.2082"
    },
    {
        "engDate": "2025-10-30",
        "nepDate": "14.07.2082"
    },
    {
        "engDate": "2025-10-31",
        "nepDate": "15.07.2082"
    },
    {
        "engDate": "2025-11-01",
        "nepDate": "16.07.2082"
    },
    {
        "engDate": "2025-11-02",
        "nepDate": "17.07.2082"
    },
    {
        "engDate": "2025-11-03",
        "nepDate": "18.07.2082"
    },
    {
        "engDate": "2025-11-04",
        "nepDate": "19.07.2082"
    },
    {
        "engDate": "2025-11-05",
        "nepDate": "20.07.2082"
    },
    {
        "engDate": "2025-11-06",
        "nepDate": "21.07.2082"
    },
    {
        "engDate": "2025-11-07",
        "nepDate": "22.07.2082"
    },
    {
        "engDate": "2025-11-08",
        "nepDate": "23.07.2082"
    },
    {
        "engDate": "2025-11-09",
        "nepDate": "24.07.2082"
    },
    {
        "engDate": "2025-11-10",
        "nepDate": "25.07.2082"
    },
    {
        "engDate": "2025-11-11",
        "nepDate": "26.07.2082"
    },
    {
        "engDate": "2025-11-12",
        "nepDate": "27.07.2082"
    },
    {
        "engDate": "2025-11-13",
        "nepDate": "28.07.2082"
    },
    {
        "engDate": "2025-11-14",
        "nepDate": "29.07.2082"
    },
    {
        "engDate": "2025-11-15",
        "nepDate": "30.07.2082"
    },
    {
        "engDate": "2025-11-16",
        "nepDate": "01.08.2082"
    },
    {
        "engDate": "2025-11-17",
        "nepDate": "02.08.2082"
    },
    {
        "engDate": "2025-11-18",
        "nepDate": "03.08.2082"
    },
    {
        "engDate": "2025-11-19",
        "nepDate": "04.08.2082"
    },
    {
        "engDate": "2025-11-20",
        "nepDate": "05.08.2082"
    },
    {
        "engDate": "2025-11-21",
        "nepDate": "06.08.2082"
    },
    {
        "engDate": "2025-11-22",
        "nepDate": "07.08.2082"
    },
    {
        "engDate": "2025-11-23",
        "nepDate": "08.08.2082"
    },
    {
        "engDate": "2025-11-24",
        "nepDate": "09.08.2082"
    },
    {
        "engDate": "2025-11-25",
        "nepDate": "10.08.2082"
    },
    {
        "engDate": "2025-11-26",
        "nepDate": "11.08.2082"
    },
    {
        "engDate": "2025-11-27",
        "nepDate": "12.08.2082"
    },
    {
        "engDate": "2025-11-28",
        "nepDate": "13.08.2082"
    },
    {
        "engDate": "2025-11-29",
        "nepDate": "14.08.2082"
    },
    {
        "engDate": "2025-11-30",
        "nepDate": "15.08.2082"
    },
    {
        "engDate": "2025-12-01",
        "nepDate": "16.08.2082"
    },
    {
        "engDate": "2025-12-02",
        "nepDate": "17.08.2082"
    },
    {
        "engDate": "2025-12-03",
        "nepDate": "18.08.2082"
    },
    {
        "engDate": "2025-12-04",
        "nepDate": "19.08.2082"
    },
    {
        "engDate": "2025-12-05",
        "nepDate": "20.08.2082"
    },
    {
        "engDate": "2025-12-06",
        "nepDate": "21.08.2082"
    },
    {
        "engDate": "2025-12-07",
        "nepDate": "22.08.2082"
    },
    {
        "engDate": "2025-12-08",
        "nepDate": "23.08.2082"
    },
    {
        "engDate": "2025-12-09",
        "nepDate": "24.08.2082"
    },
    {
        "engDate": "2025-12-10",
        "nepDate": "25.08.2082"
    },
    {
        "engDate": "2025-12-11",
        "nepDate": "26.08.2082"
    },
    {
        "engDate": "2025-12-12",
        "nepDate": "27.08.2082"
    },
    {
        "engDate": "2025-12-13",
        "nepDate": "28.08.2082"
    },
    {
        "engDate": "2025-12-14",
        "nepDate": "29.08.2082"
    },
    {
        "engDate": "2025-12-15",
        "nepDate": "30.08.2082"
    },
    {
        "engDate": "2025-12-16",
        "nepDate": "01.09.2082"
    },
    {
        "engDate": "2025-12-17",
        "nepDate": "02.09.2082"
    },
    {
        "engDate": "2025-12-18",
        "nepDate": "03.09.2082"
    },
    {
        "engDate": "2025-12-19",
        "nepDate": "04.09.2082"
    },
    {
        "engDate": "2025-12-20",
        "nepDate": "05.09.2082"
    },
    {
        "engDate": "2025-12-21",
        "nepDate": "06.09.2082"
    },
    {
        "engDate": "2025-12-22",
        "nepDate": "07.09.2082"
    },
    {
        "engDate": "2025-12-23",
        "nepDate": "08.09.2082"
    },
    {
        "engDate": "2025-12-24",
        "nepDate": "09.09.2082"
    },
    {
        "engDate": "2025-12-25",
        "nepDate": "10.09.2082"
    },
    {
        "engDate": "2025-12-26",
        "nepDate": "11.09.2082"
    },
    {
        "engDate": "2025-12-27",
        "nepDate": "12.09.2082"
    },
    {
        "engDate": "2025-12-28",
        "nepDate": "13.09.2082"
    },
    {
        "engDate": "2025-12-29",
        "nepDate": "14.09.2082"
    },
    {
        "engDate": "2025-12-30",
        "nepDate": "15.09.2082"
    },
    {
        "engDate": "2025-12-31",
        "nepDate": "16.09.2082"
    },
    {
        "engDate": "2026-01-01",
        "nepDate": "17.09.2082"
    },
    {
        "engDate": "2026-01-02",
        "nepDate": "18.09.2082"
    },
    {
        "engDate": "2026-01-03",
        "nepDate": "19.09.2082"
    },
    {
        "engDate": "2026-01-04",
        "nepDate": "20.09.2082"
    },
    {
        "engDate": "2026-01-05",
        "nepDate": "21.09.2082"
    },
    {
        "engDate": "2026-01-06",
        "nepDate": "22.09.2082"
    },
    {
        "engDate": "2026-01-07",
        "nepDate": "23.09.2082"
    },
    {
        "engDate": "2026-01-08",
        "nepDate": "24.09.2082"
    },
    {
        "engDate": "2026-01-09",
        "nepDate": "25.09.2082"
    },
    {
        "engDate": "2026-01-10",
        "nepDate": "26.09.2082"
    },
    {
        "engDate": "2026-01-11",
        "nepDate": "27.09.2082"
    },
    {
        "engDate": "2026-01-12",
        "nepDate": "28.09.2082"
    },
    {
        "engDate": "2026-01-13",
        "nepDate": "29.09.2082"
    },
    {
        "engDate": "2026-01-14",
        "nepDate": "01.10.2082"
    },
    {
        "engDate": "2026-01-15",
        "nepDate": "02.10.2082"
    },
    {
        "engDate": "2026-01-16",
        "nepDate": "03.10.2082"
    },
    {
        "engDate": "2026-01-17",
        "nepDate": "04.10.2082"
    },
    {
        "engDate": "2026-01-18",
        "nepDate": "05.10.2082"
    },
    {
        "engDate": "2026-01-19",
        "nepDate": "06.10.2082"
    },
    {
        "engDate": "2026-01-20",
        "nepDate": "07.10.2082"
    },
    {
        "engDate": "2026-01-21",
        "nepDate": "08.10.2082"
    },
    {
        "engDate": "2026-01-22",
        "nepDate": "09.10.2082"
    },
    {
        "engDate": "2026-01-23",
        "nepDate": "10.10.2082"
    },
    {
        "engDate": "2026-01-24",
        "nepDate": "11.10.2082"
    },
    {
        "engDate": "2026-01-25",
        "nepDate": "12.10.2082"
    },
    {
        "engDate": "2026-01-26",
        "nepDate": "13.10.2082"
    },
    {
        "engDate": "2026-01-27",
        "nepDate": "14.10.2082"
    },
    {
        "engDate": "2026-01-28",
        "nepDate": "15.10.2082"
    },
    {
        "engDate": "2026-01-29",
        "nepDate": "16.10.2082"
    },
    {
        "engDate": "2026-01-30",
        "nepDate": "17.10.2082"
    },
    {
        "engDate": "2026-01-31",
        "nepDate": "18.10.2082"
    },
    {
        "engDate": "2026-02-01",
        "nepDate": "19.10.2082"
    },
    {
        "engDate": "2026-02-02",
        "nepDate": "20.10.2082"
    },
    {
        "engDate": "2026-02-03",
        "nepDate": "21.10.2082"
    },
    {
        "engDate": "2026-02-04",
        "nepDate": "22.10.2082"
    },
    {
        "engDate": "2026-02-05",
        "nepDate": "23.10.2082"
    },
    {
        "engDate": "2026-02-06",
        "nepDate": "24.10.2082"
    },
    {
        "engDate": "2026-02-07",
        "nepDate": "25.10.2082"
    },
    {
        "engDate": "2026-02-08",
        "nepDate": "26.10.2082"
    },
    {
        "engDate": "2026-02-09",
        "nepDate": "27.10.2082"
    },
    {
        "engDate": "2026-02-10",
        "nepDate": "28.10.2082"
    },
    {
        "engDate": "2026-02-11",
        "nepDate": "29.10.2082"
    },
    {
        "engDate": "2026-02-12",
        "nepDate": "30.10.2082"
    },
    {
        "engDate": "2026-02-13",
        "nepDate": "01.11.2082"
    },
    {
        "engDate": "2026-02-14",
        "nepDate": "02.11.2082"
    },
    {
        "engDate": "2026-02-15",
        "nepDate": "03.11.2082"
    },
    {
        "engDate": "2026-02-16",
        "nepDate": "04.11.2082"
    },
    {
        "engDate": "2026-02-17",
        "nepDate": "05.11.2082"
    },
    {
        "engDate": "2026-02-18",
        "nepDate": "06.11.2082"
    },
    {
        "engDate": "2026-02-19",
        "nepDate": "07.11.2082"
    },
    {
        "engDate": "2026-02-20",
        "nepDate": "08.11.2082"
    },
    {
        "engDate": "2026-02-21",
        "nepDate": "09.11.2082"
    },
    {
        "engDate": "2026-02-22",
        "nepDate": "10.11.2082"
    },
    {
        "engDate": "2026-02-23",
        "nepDate": "11.11.2082"
    },
    {
        "engDate": "2026-02-24",
        "nepDate": "12.11.2082"
    },
    {
        "engDate": "2026-02-25",
        "nepDate": "13.11.2082"
    },
    {
        "engDate": "2026-02-26",
        "nepDate": "14.11.2082"
    },
    {
        "engDate": "2026-02-27",
        "nepDate": "15.11.2082"
    },
    {
        "engDate": "2026-02-28",
        "nepDate": "16.11.2082"
    },
    {
        "engDate": "2026-03-01",
        "nepDate": "17.11.2082"
    },
    {
        "engDate": "2026-03-02",
        "nepDate": "18.11.2082"
    },
    {
        "engDate": "2026-03-03",
        "nepDate": "19.11.2082"
    },
    {
        "engDate": "2026-03-04",
        "nepDate": "20.11.2082"
    },
    {
        "engDate": "2026-03-05",
        "nepDate": "21.11.2082"
    },
    {
        "engDate": "2026-03-06",
        "nepDate": "22.11.2082"
    },
    {
        "engDate": "2026-03-07",
        "nepDate": "23.11.2082"
    },
    {
        "engDate": "2026-03-08",
        "nepDate": "24.11.2082"
    },
    {
        "engDate": "2026-03-09",
        "nepDate": "25.11.2082"
    },
    {
        "engDate": "2026-03-10",
        "nepDate": "26.11.2082"
    },
    {
        "engDate": "2026-03-11",
        "nepDate": "27.11.2082"
    },
    {
        "engDate": "2026-03-12",
        "nepDate": "28.11.2082"
    },
    {
        "engDate": "2026-03-13",
        "nepDate": "29.11.2082"
    },
    {
        "engDate": "2026-03-14",
        "nepDate": "30.11.2082"
    },
    {
        "engDate": "2026-03-15",
        "nepDate": "01.12.2082"
    },
    {
        "engDate": "2026-03-16",
        "nepDate": "02.12.2082"
    },
    {
        "engDate": "2026-03-17",
        "nepDate": "03.12.2082"
    },
    {
        "engDate": "2026-03-18",
        "nepDate": "04.12.2082"
    },
    {
        "engDate": "2026-03-19",
        "nepDate": "05.12.2082"
    },
    {
        "engDate": "2026-03-20",
        "nepDate": "06.12.2082"
    },
    {
        "engDate": "2026-03-21",
        "nepDate": "07.12.2082"
    },
    {
        "engDate": "2026-03-22",
        "nepDate": "08.12.2082"
    },
    {
        "engDate": "2026-03-23",
        "nepDate": "09.12.2082"
    },
    {
        "engDate": "2026-03-24",
        "nepDate": "10.12.2082"
    },
    {
        "engDate": "2026-03-25",
        "nepDate": "11.12.2082"
    },
    {
        "engDate": "2026-03-26",
        "nepDate": "12.12.2082"
    },
    {
        "engDate": "2026-03-27",
        "nepDate": "13.12.2082"
    },
    {
        "engDate": "2026-03-28",
        "nepDate": "14.12.2082"
    },
    {
        "engDate": "2026-03-29",
        "nepDate": "15.12.2082"
    },
    {
        "engDate": "2026-03-30",
        "nepDate": "16.12.2082"
    },
    {
        "engDate": "2026-03-31",
        "nepDate": "17.12.2082"
    },
    {
        "engDate": "2026-04-01",
        "nepDate": "18.12.2082"
    },
    {
        "engDate": "2026-04-02",
        "nepDate": "19.12.2082"
    },
    {
        "engDate": "2026-04-03",
        "nepDate": "20.12.2082"
    },
    {
        "engDate": "2026-04-04",
        "nepDate": "21.12.2082"
    },
    {
        "engDate": "2026-04-05",
        "nepDate": "22.12.2082"
    },
    {
        "engDate": "2026-04-06",
        "nepDate": "23.12.2082"
    },
    {
        "engDate": "2026-04-07",
        "nepDate": "24.12.2082"
    },
    {
        "engDate": "2026-04-08",
        "nepDate": "25.12.2082"
    },
    {
        "engDate": "2026-04-09",
        "nepDate": "26.12.2082"
    },
    {
        "engDate": "2026-04-10",
        "nepDate": "27.12.2082"
    },
    {
        "engDate": "2026-04-11",
        "nepDate": "28.12.2082"
    },
    {
        "engDate": "2026-04-12",
        "nepDate": "29.12.2082"
    },
    {
        "engDate": "2026-04-13",
        "nepDate": "30.12.2082"
    },
    {
        "engDate": "2026-04-14",
        "nepDate": "01.01.2083"
    },
    {
        "engDate": "2026-04-15",
        "nepDate": "02.01.2083"
    },
    {
        "engDate": "2026-04-16",
        "nepDate": "03.01.2083"
    },
    {
        "engDate": "2026-04-17",
        "nepDate": "04.01.2083"
    },
    {
        "engDate": "2026-04-18",
        "nepDate": "05.01.2083"
    },
    {
        "engDate": "2026-04-19",
        "nepDate": "06.01.2083"
    },
    {
        "engDate": "2026-04-20",
        "nepDate": "07.01.2083"
    },
    {
        "engDate": "2026-04-21",
        "nepDate": "08.01.2083"
    },
    {
        "engDate": "2026-04-22",
        "nepDate": "09.01.2083"
    },
    {
        "engDate": "2026-04-23",
        "nepDate": "10.01.2083"
    },
    {
        "engDate": "2026-04-24",
        "nepDate": "11.01.2083"
    },
    {
        "engDate": "2026-04-25",
        "nepDate": "12.01.2083"
    },
    {
        "engDate": "2026-04-26",
        "nepDate": "13.01.2083"
    },
    {
        "engDate": "2026-04-27",
        "nepDate": "14.01.2083"
    },
    {
        "engDate": "2026-04-28",
        "nepDate": "15.01.2083"
    },
    {
        "engDate": "2026-04-29",
        "nepDate": "16.01.2083"
    },
    {
        "engDate": "2026-04-30",
        "nepDate": "17.01.2083"
    },
    {
        "engDate": "2026-05-01",
        "nepDate": "18.01.2083"
    },
    {
        "engDate": "2026-05-02",
        "nepDate": "19.01.2083"
    },
    {
        "engDate": "2026-05-03",
        "nepDate": "20.01.2083"
    },
    {
        "engDate": "2026-05-04",
        "nepDate": "21.01.2083"
    },
    {
        "engDate": "2026-05-05",
        "nepDate": "22.01.2083"
    },
    {
        "engDate": "2026-05-06",
        "nepDate": "23.01.2083"
    },
    {
        "engDate": "2026-05-07",
        "nepDate": "24.01.2083"
    },
    {
        "engDate": "2026-05-08",
        "nepDate": "25.01.2083"
    },
    {
        "engDate": "2026-05-09",
        "nepDate": "26.01.2083"
    },
    {
        "engDate": "2026-05-10",
        "nepDate": "27.01.2083"
    },
    {
        "engDate": "2026-05-11",
        "nepDate": "28.01.2083"
    },
    {
        "engDate": "2026-05-12",
        "nepDate": "29.01.2083"
    },
    {
        "engDate": "2026-05-13",
        "nepDate": "30.01.2083"
    },
    {
        "engDate": "2026-05-14",
        "nepDate": "31.01.2083"
    },
    {
        "engDate": "2026-05-15",
        "nepDate": "01.02.2083"
    },
    {
        "engDate": "2026-05-16",
        "nepDate": "02.02.2083"
    },
    {
        "engDate": "2026-05-17",
        "nepDate": "03.02.2083"
    },
    {
        "engDate": "2026-05-18",
        "nepDate": "04.02.2083"
    },
    {
        "engDate": "2026-05-19",
        "nepDate": "05.02.2083"
    },
    {
        "engDate": "2026-05-20",
        "nepDate": "06.02.2083"
    },
    {
        "engDate": "2026-05-21",
        "nepDate": "07.02.2083"
    },
    {
        "engDate": "2026-05-22",
        "nepDate": "08.02.2083"
    },
    {
        "engDate": "2026-05-23",
        "nepDate": "09.02.2083"
    },
    {
        "engDate": "2026-05-24",
        "nepDate": "10.02.2083"
    },
    {
        "engDate": "2026-05-25",
        "nepDate": "11.02.2083"
    },
    {
        "engDate": "2026-05-26",
        "nepDate": "12.02.2083"
    },
    {
        "engDate": "2026-05-27",
        "nepDate": "13.02.2083"
    },
    {
        "engDate": "2026-05-28",
        "nepDate": "14.02.2083"
    },
    {
        "engDate": "2026-05-29",
        "nepDate": "15.02.2083"
    },
    {
        "engDate": "2026-05-30",
        "nepDate": "16.02.2083"
    },
    {
        "engDate": "2026-05-31",
        "nepDate": "17.02.2083"
    },
    {
        "engDate": "2026-06-01",
        "nepDate": "18.02.2083"
    },
    {
        "engDate": "2026-06-02",
        "nepDate": "19.02.2083"
    },
    {
        "engDate": "2026-06-03",
        "nepDate": "20.02.2083"
    },
    {
        "engDate": "2026-06-04",
        "nepDate": "21.02.2083"
    },
    {
        "engDate": "2026-06-05",
        "nepDate": "22.02.2083"
    },
    {
        "engDate": "2026-06-06",
        "nepDate": "23.02.2083"
    },
    {
        "engDate": "2026-06-07",
        "nepDate": "24.02.2083"
    },
    {
        "engDate": "2026-06-08",
        "nepDate": "25.02.2083"
    },
    {
        "engDate": "2026-06-09",
        "nepDate": "26.02.2083"
    },
    {
        "engDate": "2026-06-10",
        "nepDate": "27.02.2083"
    },
    {
        "engDate": "2026-06-11",
        "nepDate": "28.02.2083"
    },
    {
        "engDate": "2026-06-12",
        "nepDate": "29.02.2083"
    },
    {
        "engDate": "2026-06-13",
        "nepDate": "30.02.2083"
    },
    {
        "engDate": "2026-06-14",
        "nepDate": "31.02.2083"
    },
    {
        "engDate": "2026-06-15",
        "nepDate": "01.03.2083"
    },
    {
        "engDate": "2026-06-16",
        "nepDate": "02.03.2083"
    },
    {
        "engDate": "2026-06-17",
        "nepDate": "03.03.2083"
    },
    {
        "engDate": "2026-06-18",
        "nepDate": "04.03.2083"
    },
    {
        "engDate": "2026-06-19",
        "nepDate": "05.03.2083"
    },
    {
        "engDate": "2026-06-20",
        "nepDate": "06.03.2083"
    },
    {
        "engDate": "2026-06-21",
        "nepDate": "07.03.2083"
    },
    {
        "engDate": "2026-06-22",
        "nepDate": "08.03.2083"
    },
    {
        "engDate": "2026-06-23",
        "nepDate": "09.03.2083"
    },
    {
        "engDate": "2026-06-24",
        "nepDate": "10.03.2083"
    },
    {
        "engDate": "2026-06-25",
        "nepDate": "11.03.2083"
    },
    {
        "engDate": "2026-06-26",
        "nepDate": "12.03.2083"
    },
    {
        "engDate": "2026-06-27",
        "nepDate": "13.03.2083"
    },
    {
        "engDate": "2026-06-28",
        "nepDate": "14.03.2083"
    },
    {
        "engDate": "2026-06-29",
        "nepDate": "15.03.2083"
    },
    {
        "engDate": "2026-06-30",
        "nepDate": "16.03.2083"
    },
    {
        "engDate": "2026-07-01",
        "nepDate": "17.03.2083"
    },
    {
        "engDate": "2026-07-02",
        "nepDate": "18.03.2083"
    },
    {
        "engDate": "2026-07-03",
        "nepDate": "19.03.2083"
    },
    {
        "engDate": "2026-07-04",
        "nepDate": "20.03.2083"
    },
    {
        "engDate": "2026-07-05",
        "nepDate": "21.03.2083"
    },
    {
        "engDate": "2026-07-06",
        "nepDate": "22.03.2083"
    },
    {
        "engDate": "2026-07-07",
        "nepDate": "23.03.2083"
    },
    {
        "engDate": "2026-07-08",
        "nepDate": "24.03.2083"
    },
    {
        "engDate": "2026-07-09",
        "nepDate": "25.03.2083"
    },
    {
        "engDate": "2026-07-10",
        "nepDate": "26.03.2083"
    },
    {
        "engDate": "2026-07-11",
        "nepDate": "27.03.2083"
    },
    {
        "engDate": "2026-07-12",
        "nepDate": "28.03.2083"
    },
    {
        "engDate": "2026-07-13",
        "nepDate": "29.03.2083"
    },
    {
        "engDate": "2026-07-14",
        "nepDate": "30.03.2083"
    },
    {
        "engDate": "2026-07-15",
        "nepDate": "31.03.2083"
    },
    {
        "engDate": "2026-07-16",
        "nepDate": "32.03.2083"
    },
    {
        "engDate": "2026-07-17",
        "nepDate": "01.04.2083"
    },
    {
        "engDate": "2026-07-18",
        "nepDate": "02.04.2083"
    },
    {
        "engDate": "2026-07-19",
        "nepDate": "03.04.2083"
    },
    {
        "engDate": "2026-07-20",
        "nepDate": "04.04.2083"
    },
    {
        "engDate": "2026-07-21",
        "nepDate": "05.04.2083"
    },
    {
        "engDate": "2026-07-22",
        "nepDate": "06.04.2083"
    },
    {
        "engDate": "2026-07-23",
        "nepDate": "07.04.2083"
    },
    {
        "engDate": "2026-07-24",
        "nepDate": "08.04.2083"
    },
    {
        "engDate": "2026-07-25",
        "nepDate": "09.04.2083"
    },
    {
        "engDate": "2026-07-26",
        "nepDate": "10.04.2083"
    },
    {
        "engDate": "2026-07-27",
        "nepDate": "11.04.2083"
    },
    {
        "engDate": "2026-07-28",
        "nepDate": "12.04.2083"
    },
    {
        "engDate": "2026-07-29",
        "nepDate": "13.04.2083"
    },
    {
        "engDate": "2026-07-30",
        "nepDate": "14.04.2083"
    },
    {
        "engDate": "2026-07-31",
        "nepDate": "15.04.2083"
    },
    {
        "engDate": "2026-08-01",
        "nepDate": "16.04.2083"
    },
    {
        "engDate": "2026-08-02",
        "nepDate": "17.04.2083"
    },
    {
        "engDate": "2026-08-03",
        "nepDate": "18.04.2083"
    },
    {
        "engDate": "2026-08-04",
        "nepDate": "19.04.2083"
    },
    {
        "engDate": "2026-08-05",
        "nepDate": "20.04.2083"
    },
    {
        "engDate": "2026-08-06",
        "nepDate": "21.04.2083"
    },
    {
        "engDate": "2026-08-07",
        "nepDate": "22.04.2083"
    },
    {
        "engDate": "2026-08-08",
        "nepDate": "23.04.2083"
    },
    {
        "engDate": "2026-08-09",
        "nepDate": "24.04.2083"
    },
    {
        "engDate": "2026-08-10",
        "nepDate": "25.04.2083"
    },
    {
        "engDate": "2026-08-11",
        "nepDate": "26.04.2083"
    },
    {
        "engDate": "2026-08-12",
        "nepDate": "27.04.2083"
    },
    {
        "engDate": "2026-08-13",
        "nepDate": "28.04.2083"
    },
    {
        "engDate": "2026-08-14",
        "nepDate": "29.04.2083"
    },
    {
        "engDate": "2026-08-15",
        "nepDate": "30.04.2083"
    },
    {
        "engDate": "2026-08-16",
        "nepDate": "31.04.2083"
    },
    {
        "engDate": "2026-08-17",
        "nepDate": "01.05.2083"
    },
    {
        "engDate": "2026-08-18",
        "nepDate": "02.05.2083"
    },
    {
        "engDate": "2026-08-19",
        "nepDate": "03.05.2083"
    },
    {
        "engDate": "2026-08-20",
        "nepDate": "04.05.2083"
    },
    {
        "engDate": "2026-08-21",
        "nepDate": "05.05.2083"
    },
    {
        "engDate": "2026-08-22",
        "nepDate": "06.05.2083"
    },
    {
        "engDate": "2026-08-23",
        "nepDate": "07.05.2083"
    },
    {
        "engDate": "2026-08-24",
        "nepDate": "08.05.2083"
    },
    {
        "engDate": "2026-08-25",
        "nepDate": "09.05.2083"
    },
    {
        "engDate": "2026-08-26",
        "nepDate": "10.05.2083"
    },
    {
        "engDate": "2026-08-27",
        "nepDate": "11.05.2083"
    },
    {
        "engDate": "2026-08-28",
        "nepDate": "12.05.2083"
    },
    {
        "engDate": "2026-08-29",
        "nepDate": "13.05.2083"
    },
    {
        "engDate": "2026-08-30",
        "nepDate": "14.05.2083"
    },
    {
        "engDate": "2026-08-31",
        "nepDate": "15.05.2083"
    },
    {
        "engDate": "2026-09-01",
        "nepDate": "16.05.2083"
    },
    {
        "engDate": "2026-09-02",
        "nepDate": "17.05.2083"
    },
    {
        "engDate": "2026-09-03",
        "nepDate": "18.05.2083"
    },
    {
        "engDate": "2026-09-04",
        "nepDate": "19.05.2083"
    },
    {
        "engDate": "2026-09-05",
        "nepDate": "20.05.2083"
    },
    {
        "engDate": "2026-09-06",
        "nepDate": "21.05.2083"
    },
    {
        "engDate": "2026-09-07",
        "nepDate": "22.05.2083"
    },
    {
        "engDate": "2026-09-08",
        "nepDate": "23.05.2083"
    },
    {
        "engDate": "2026-09-09",
        "nepDate": "24.05.2083"
    },
    {
        "engDate": "2026-09-10",
        "nepDate": "25.05.2083"
    },
    {
        "engDate": "2026-09-11",
        "nepDate": "26.05.2083"
    },
    {
        "engDate": "2026-09-12",
        "nepDate": "27.05.2083"
    },
    {
        "engDate": "2026-09-13",
        "nepDate": "28.05.2083"
    },
    {
        "engDate": "2026-09-14",
        "nepDate": "29.05.2083"
    },
    {
        "engDate": "2026-09-15",
        "nepDate": "30.05.2083"
    },
    {
        "engDate": "2026-09-16",
        "nepDate": "31.05.2083"
    },
    {
        "engDate": "2026-09-17",
        "nepDate": "01.06.2083"
    },
    {
        "engDate": "2026-09-18",
        "nepDate": "02.06.2083"
    },
    {
        "engDate": "2026-09-19",
        "nepDate": "03.06.2083"
    },
    {
        "engDate": "2026-09-20",
        "nepDate": "04.06.2083"
    },
    {
        "engDate": "2026-09-21",
        "nepDate": "05.06.2083"
    },
    {
        "engDate": "2026-09-22",
        "nepDate": "06.06.2083"
    },
    {
        "engDate": "2026-09-23",
        "nepDate": "07.06.2083"
    },
    {
        "engDate": "2026-09-24",
        "nepDate": "08.06.2083"
    },
    {
        "engDate": "2026-09-25",
        "nepDate": "09.06.2083"
    },
    {
        "engDate": "2026-09-26",
        "nepDate": "10.06.2083"
    },
    {
        "engDate": "2026-09-27",
        "nepDate": "11.06.2083"
    },
    {
        "engDate": "2026-09-28",
        "nepDate": "12.06.2083"
    },
    {
        "engDate": "2026-09-29",
        "nepDate": "13.06.2083"
    },
    {
        "engDate": "2026-09-30",
        "nepDate": "14.06.2083"
    },
    {
        "engDate": "2026-10-01",
        "nepDate": "15.06.2083"
    },
    {
        "engDate": "2026-10-02",
        "nepDate": "16.06.2083"
    },
    {
        "engDate": "2026-10-03",
        "nepDate": "17.06.2083"
    },
    {
        "engDate": "2026-10-04",
        "nepDate": "18.06.2083"
    },
    {
        "engDate": "2026-10-05",
        "nepDate": "19.06.2083"
    },
    {
        "engDate": "2026-10-06",
        "nepDate": "20.06.2083"
    },
    {
        "engDate": "2026-10-07",
        "nepDate": "21.06.2083"
    },
    {
        "engDate": "2026-10-08",
        "nepDate": "22.06.2083"
    },
    {
        "engDate": "2026-10-09",
        "nepDate": "23.06.2083"
    },
    {
        "engDate": "2026-10-10",
        "nepDate": "24.06.2083"
    },
    {
        "engDate": "2026-10-11",
        "nepDate": "25.06.2083"
    },
    {
        "engDate": "2026-10-12",
        "nepDate": "26.06.2083"
    },
    {
        "engDate": "2026-10-13",
        "nepDate": "27.06.2083"
    },
    {
        "engDate": "2026-10-14",
        "nepDate": "28.06.2083"
    },
    {
        "engDate": "2026-10-15",
        "nepDate": "29.06.2083"
    },
    {
        "engDate": "2026-10-16",
        "nepDate": "30.06.2083"
    },
    {
        "engDate": "2026-10-17",
        "nepDate": "01.07.2083"
    },
    {
        "engDate": "2026-10-18",
        "nepDate": "02.07.2083"
    },
    {
        "engDate": "2026-10-19",
        "nepDate": "03.07.2083"
    },
    {
        "engDate": "2026-10-20",
        "nepDate": "04.07.2083"
    },
    {
        "engDate": "2026-10-21",
        "nepDate": "05.07.2083"
    },
    {
        "engDate": "2026-10-22",
        "nepDate": "06.07.2083"
    },
    {
        "engDate": "2026-10-23",
        "nepDate": "07.07.2083"
    },
    {
        "engDate": "2026-10-24",
        "nepDate": "08.07.2083"
    },
    {
        "engDate": "2026-10-25",
        "nepDate": "09.07.2083"
    },
    {
        "engDate": "2026-10-26",
        "nepDate": "10.07.2083"
    },
    {
        "engDate": "2026-10-27",
        "nepDate": "11.07.2083"
    },
    {
        "engDate": "2026-10-28",
        "nepDate": "12.07.2083"
    },
    {
        "engDate": "2026-10-29",
        "nepDate": "13.07.2083"
    },
    {
        "engDate": "2026-10-30",
        "nepDate": "14.07.2083"
    },
    {
        "engDate": "2026-10-31",
        "nepDate": "15.07.2083"
    },
    {
        "engDate": "2026-11-01",
        "nepDate": "16.07.2083"
    },
    {
        "engDate": "2026-11-02",
        "nepDate": "17.07.2083"
    },
    {
        "engDate": "2026-11-03",
        "nepDate": "18.07.2083"
    },
    {
        "engDate": "2026-11-04",
        "nepDate": "19.07.2083"
    },
    {
        "engDate": "2026-11-05",
        "nepDate": "20.07.2083"
    },
    {
        "engDate": "2026-11-06",
        "nepDate": "21.07.2083"
    },
    {
        "engDate": "2026-11-07",
        "nepDate": "22.07.2083"
    },
    {
        "engDate": "2026-11-08",
        "nepDate": "23.07.2083"
    },
    {
        "engDate": "2026-11-09",
        "nepDate": "24.07.2083"
    },
    {
        "engDate": "2026-11-10",
        "nepDate": "25.07.2083"
    },
    {
        "engDate": "2026-11-11",
        "nepDate": "26.07.2083"
    },
    {
        "engDate": "2026-11-12",
        "nepDate": "27.07.2083"
    },
    {
        "engDate": "2026-11-13",
        "nepDate": "28.07.2083"
    },
    {
        "engDate": "2026-11-14",
        "nepDate": "29.07.2083"
    },
    {
        "engDate": "2026-11-15",
        "nepDate": "30.07.2083"
    },
    {
        "engDate": "2026-11-16",
        "nepDate": "01.08.2083"
    },
    {
        "engDate": "2026-11-17",
        "nepDate": "02.08.2083"
    },
    {
        "engDate": "2026-11-18",
        "nepDate": "03.08.2083"
    },
    {
        "engDate": "2026-11-19",
        "nepDate": "04.08.2083"
    },
    {
        "engDate": "2026-11-20",
        "nepDate": "05.08.2083"
    },
    {
        "engDate": "2026-11-21",
        "nepDate": "06.08.2083"
    },
    {
        "engDate": "2026-11-22",
        "nepDate": "07.08.2083"
    },
    {
        "engDate": "2026-11-23",
        "nepDate": "08.08.2083"
    },
    {
        "engDate": "2026-11-24",
        "nepDate": "09.08.2083"
    },
    {
        "engDate": "2026-11-25",
        "nepDate": "10.08.2083"
    },
    {
        "engDate": "2026-11-26",
        "nepDate": "11.08.2083"
    },
    {
        "engDate": "2026-11-27",
        "nepDate": "12.08.2083"
    },
    {
        "engDate": "2026-11-28",
        "nepDate": "13.08.2083"
    },
    {
        "engDate": "2026-11-29",
        "nepDate": "14.08.2083"
    },
    {
        "engDate": "2026-11-30",
        "nepDate": "15.08.2083"
    },
    {
        "engDate": "2026-12-01",
        "nepDate": "16.08.2083"
    },
    {
        "engDate": "2026-12-02",
        "nepDate": "17.08.2083"
    },
    {
        "engDate": "2026-12-03",
        "nepDate": "18.08.2083"
    },
    {
        "engDate": "2026-12-04",
        "nepDate": "19.08.2083"
    },
    {
        "engDate": "2026-12-05",
        "nepDate": "20.08.2083"
    },
    {
        "engDate": "2026-12-06",
        "nepDate": "21.08.2083"
    },
    {
        "engDate": "2026-12-07",
        "nepDate": "22.08.2083"
    },
    {
        "engDate": "2026-12-08",
        "nepDate": "23.08.2083"
    },
    {
        "engDate": "2026-12-09",
        "nepDate": "24.08.2083"
    },
    {
        "engDate": "2026-12-10",
        "nepDate": "25.08.2083"
    },
    {
        "engDate": "2026-12-11",
        "nepDate": "26.08.2083"
    },
    {
        "engDate": "2026-12-12",
        "nepDate": "27.08.2083"
    },
    {
        "engDate": "2026-12-13",
        "nepDate": "28.08.2083"
    },
    {
        "engDate": "2026-12-14",
        "nepDate": "29.08.2083"
    },
    {
        "engDate": "2026-12-15",
        "nepDate": "30.08.2083"
    },
    {
        "engDate": "2026-12-16",
        "nepDate": "01.09.2083"
    },
    {
        "engDate": "2026-12-17",
        "nepDate": "02.09.2083"
    },
    {
        "engDate": "2026-12-18",
        "nepDate": "03.09.2083"
    },
    {
        "engDate": "2026-12-19",
        "nepDate": "04.09.2083"
    },
    {
        "engDate": "2026-12-20",
        "nepDate": "05.09.2083"
    },
    {
        "engDate": "2026-12-21",
        "nepDate": "06.09.2083"
    },
    {
        "engDate": "2026-12-22",
        "nepDate": "07.09.2083"
    },
    {
        "engDate": "2026-12-23",
        "nepDate": "08.09.2083"
    },
    {
        "engDate": "2026-12-24",
        "nepDate": "09.09.2083"
    },
    {
        "engDate": "2026-12-25",
        "nepDate": "10.09.2083"
    },
    {
        "engDate": "2026-12-26",
        "nepDate": "11.09.2083"
    },
    {
        "engDate": "2026-12-27",
        "nepDate": "12.09.2083"
    },
    {
        "engDate": "2026-12-28",
        "nepDate": "13.09.2083"
    },
    {
        "engDate": "2026-12-29",
        "nepDate": "14.09.2083"
    },
    {
        "engDate": "2026-12-30",
        "nepDate": "15.09.2083"
    },
    {
        "engDate": "2026-12-31",
        "nepDate": "16.09.2083"
    },
    {
        "engDate": "2027-01-01",
        "nepDate": "17.09.2083"
    },
    {
        "engDate": "2027-01-02",
        "nepDate": "18.09.2083"
    },
    {
        "engDate": "2027-01-03",
        "nepDate": "19.09.2083"
    },
    {
        "engDate": "2027-01-04",
        "nepDate": "20.09.2083"
    },
    {
        "engDate": "2027-01-05",
        "nepDate": "21.09.2083"
    },
    {
        "engDate": "2027-01-06",
        "nepDate": "22.09.2083"
    },
    {
        "engDate": "2027-01-07",
        "nepDate": "23.09.2083"
    },
    {
        "engDate": "2027-01-08",
        "nepDate": "24.09.2083"
    },
    {
        "engDate": "2027-01-09",
        "nepDate": "25.09.2083"
    },
    {
        "engDate": "2027-01-10",
        "nepDate": "26.09.2083"
    },
    {
        "engDate": "2027-01-11",
        "nepDate": "27.09.2083"
    },
    {
        "engDate": "2027-01-12",
        "nepDate": "28.09.2083"
    },
    {
        "engDate": "2027-01-13",
        "nepDate": "29.09.2083"
    },
    {
        "engDate": "2027-01-14",
        "nepDate": "01.10.2083"
    },
    {
        "engDate": "2027-01-15",
        "nepDate": "02.10.2083"
    },
    {
        "engDate": "2027-01-16",
        "nepDate": "03.10.2083"
    },
    {
        "engDate": "2027-01-17",
        "nepDate": "04.10.2083"
    },
    {
        "engDate": "2027-01-18",
        "nepDate": "05.10.2083"
    },
    {
        "engDate": "2027-01-19",
        "nepDate": "06.10.2083"
    },
    {
        "engDate": "2027-01-20",
        "nepDate": "07.10.2083"
    },
    {
        "engDate": "2027-01-21",
        "nepDate": "08.10.2083"
    },
    {
        "engDate": "2027-01-22",
        "nepDate": "09.10.2083"
    },
    {
        "engDate": "2027-01-23",
        "nepDate": "10.10.2083"
    },
    {
        "engDate": "2027-01-24",
        "nepDate": "11.10.2083"
    },
    {
        "engDate": "2027-01-25",
        "nepDate": "12.10.2083"
    },
    {
        "engDate": "2027-01-26",
        "nepDate": "13.10.2083"
    },
    {
        "engDate": "2027-01-27",
        "nepDate": "14.10.2083"
    },
    {
        "engDate": "2027-01-28",
        "nepDate": "15.10.2083"
    },
    {
        "engDate": "2027-01-29",
        "nepDate": "16.10.2083"
    },
    {
        "engDate": "2027-01-30",
        "nepDate": "17.10.2083"
    },
    {
        "engDate": "2027-01-31",
        "nepDate": "18.10.2083"
    },
    {
        "engDate": "2027-02-01",
        "nepDate": "19.10.2083"
    },
    {
        "engDate": "2027-02-02",
        "nepDate": "20.10.2083"
    },
    {
        "engDate": "2027-02-03",
        "nepDate": "21.10.2083"
    },
    {
        "engDate": "2027-02-04",
        "nepDate": "22.10.2083"
    },
    {
        "engDate": "2027-02-05",
        "nepDate": "23.10.2083"
    },
    {
        "engDate": "2027-02-06",
        "nepDate": "24.10.2083"
    },
    {
        "engDate": "2027-02-07",
        "nepDate": "25.10.2083"
    },
    {
        "engDate": "2027-02-08",
        "nepDate": "26.10.2083"
    },
    {
        "engDate": "2027-02-09",
        "nepDate": "27.10.2083"
    },
    {
        "engDate": "2027-02-10",
        "nepDate": "28.10.2083"
    },
    {
        "engDate": "2027-02-11",
        "nepDate": "29.10.2083"
    },
    {
        "engDate": "2027-02-12",
        "nepDate": "30.10.2083"
    },
    {
        "engDate": "2027-02-13",
        "nepDate": "01.11.2083"
    },
    {
        "engDate": "2027-02-14",
        "nepDate": "02.11.2083"
    },
    {
        "engDate": "2027-02-15",
        "nepDate": "03.11.2083"
    },
    {
        "engDate": "2027-02-16",
        "nepDate": "04.11.2083"
    },
    {
        "engDate": "2027-02-17",
        "nepDate": "05.11.2083"
    },
    {
        "engDate": "2027-02-18",
        "nepDate": "06.11.2083"
    },
    {
        "engDate": "2027-02-19",
        "nepDate": "07.11.2083"
    },
    {
        "engDate": "2027-02-20",
        "nepDate": "08.11.2083"
    },
    {
        "engDate": "2027-02-21",
        "nepDate": "09.11.2083"
    },
    {
        "engDate": "2027-02-22",
        "nepDate": "10.11.2083"
    },
    {
        "engDate": "2027-02-23",
        "nepDate": "11.11.2083"
    },
    {
        "engDate": "2027-02-24",
        "nepDate": "12.11.2083"
    },
    {
        "engDate": "2027-02-25",
        "nepDate": "13.11.2083"
    },
    {
        "engDate": "2027-02-26",
        "nepDate": "14.11.2083"
    },
    {
        "engDate": "2027-02-27",
        "nepDate": "15.11.2083"
    },
    {
        "engDate": "2027-02-28",
        "nepDate": "16.11.2083"
    },
    {
        "engDate": "2027-03-01",
        "nepDate": "17.11.2083"
    },
    {
        "engDate": "2027-03-02",
        "nepDate": "18.11.2083"
    },
    {
        "engDate": "2027-03-03",
        "nepDate": "19.11.2083"
    },
    {
        "engDate": "2027-03-04",
        "nepDate": "20.11.2083"
    },
    {
        "engDate": "2027-03-05",
        "nepDate": "21.11.2083"
    },
    {
        "engDate": "2027-03-06",
        "nepDate": "22.11.2083"
    },
    {
        "engDate": "2027-03-07",
        "nepDate": "23.11.2083"
    },
    {
        "engDate": "2027-03-08",
        "nepDate": "24.11.2083"
    },
    {
        "engDate": "2027-03-09",
        "nepDate": "25.11.2083"
    },
    {
        "engDate": "2027-03-10",
        "nepDate": "26.11.2083"
    },
    {
        "engDate": "2027-03-11",
        "nepDate": "27.11.2083"
    },
    {
        "engDate": "2027-03-12",
        "nepDate": "28.11.2083"
    },
    {
        "engDate": "2027-03-13",
        "nepDate": "29.11.2083"
    },
    {
        "engDate": "2027-03-14",
        "nepDate": "30.11.2083"
    },
    {
        "engDate": "2027-03-15",
        "nepDate": "01.12.2083"
    },
    {
        "engDate": "2027-03-16",
        "nepDate": "02.12.2083"
    },
    {
        "engDate": "2027-03-17",
        "nepDate": "03.12.2083"
    },
    {
        "engDate": "2027-03-18",
        "nepDate": "04.12.2083"
    },
    {
        "engDate": "2027-03-19",
        "nepDate": "05.12.2083"
    },
    {
        "engDate": "2027-03-20",
        "nepDate": "06.12.2083"
    },
    {
        "engDate": "2027-03-21",
        "nepDate": "07.12.2083"
    },
    {
        "engDate": "2027-03-22",
        "nepDate": "08.12.2083"
    },
    {
        "engDate": "2027-03-23",
        "nepDate": "09.12.2083"
    },
    {
        "engDate": "2027-03-24",
        "nepDate": "10.12.2083"
    },
    {
        "engDate": "2027-03-25",
        "nepDate": "11.12.2083"
    },
    {
        "engDate": "2027-03-26",
        "nepDate": "12.12.2083"
    },
    {
        "engDate": "2027-03-27",
        "nepDate": "13.12.2083"
    },
    {
        "engDate": "2027-03-28",
        "nepDate": "14.12.2083"
    },
    {
        "engDate": "2027-03-29",
        "nepDate": "15.12.2083"
    },
    {
        "engDate": "2027-03-30",
        "nepDate": "16.12.2083"
    },
    {
        "engDate": "2027-03-31",
        "nepDate": "17.12.2083"
    },
    {
        "engDate": "2027-04-01",
        "nepDate": "18.12.2083"
    },
    {
        "engDate": "2027-04-02",
        "nepDate": "19.12.2083"
    },
    {
        "engDate": "2027-04-03",
        "nepDate": "20.12.2083"
    },
    {
        "engDate": "2027-04-04",
        "nepDate": "21.12.2083"
    },
    {
        "engDate": "2027-04-05",
        "nepDate": "22.12.2083"
    },
    {
        "engDate": "2027-04-06",
        "nepDate": "23.12.2083"
    },
    {
        "engDate": "2027-04-07",
        "nepDate": "24.12.2083"
    },
    {
        "engDate": "2027-04-08",
        "nepDate": "25.12.2083"
    },
    {
        "engDate": "2027-04-09",
        "nepDate": "26.12.2083"
    },
    {
        "engDate": "2027-04-10",
        "nepDate": "27.12.2083"
    },
    {
        "engDate": "2027-04-11",
        "nepDate": "28.12.2083"
    },
    {
        "engDate": "2027-04-12",
        "nepDate": "29.12.2083"
    },
    {
        "engDate": "2027-04-13",
        "nepDate": "30.12.2083"
    },
    {
        "engDate": "2027-04-14",
        "nepDate": "01.01.2084"
    },
    {
        "engDate": "2027-04-15",
        "nepDate": "02.01.2084"
    },
    {
        "engDate": "2027-04-16",
        "nepDate": "03.01.2084"
    },
    {
        "engDate": "2027-04-17",
        "nepDate": "04.01.2084"
    },
    {
        "engDate": "2027-04-18",
        "nepDate": "05.01.2084"
    },
    {
        "engDate": "2027-04-19",
        "nepDate": "06.01.2084"
    },
    {
        "engDate": "2027-04-20",
        "nepDate": "07.01.2084"
    },
    {
        "engDate": "2027-04-21",
        "nepDate": "08.01.2084"
    },
    {
        "engDate": "2027-04-22",
        "nepDate": "09.01.2084"
    },
    {
        "engDate": "2027-04-23",
        "nepDate": "10.01.2084"
    },
    {
        "engDate": "2027-04-24",
        "nepDate": "11.01.2084"
    },
    {
        "engDate": "2027-04-25",
        "nepDate": "12.01.2084"
    },
    {
        "engDate": "2027-04-26",
        "nepDate": "13.01.2084"
    },
    {
        "engDate": "2027-04-27",
        "nepDate": "14.01.2084"
    },
    {
        "engDate": "2027-04-28",
        "nepDate": "15.01.2084"
    },
    {
        "engDate": "2027-04-29",
        "nepDate": "16.01.2084"
    },
    {
        "engDate": "2027-04-30",
        "nepDate": "17.01.2084"
    },
    {
        "engDate": "2027-05-01",
        "nepDate": "18.01.2084"
    },
    {
        "engDate": "2027-05-02",
        "nepDate": "19.01.2084"
    },
    {
        "engDate": "2027-05-03",
        "nepDate": "20.01.2084"
    },
    {
        "engDate": "2027-05-04",
        "nepDate": "21.01.2084"
    },
    {
        "engDate": "2027-05-05",
        "nepDate": "22.01.2084"
    },
    {
        "engDate": "2027-05-06",
        "nepDate": "23.01.2084"
    },
    {
        "engDate": "2027-05-07",
        "nepDate": "24.01.2084"
    },
    {
        "engDate": "2027-05-08",
        "nepDate": "25.01.2084"
    },
    {
        "engDate": "2027-05-09",
        "nepDate": "26.01.2084"
    },
    {
        "engDate": "2027-05-10",
        "nepDate": "27.01.2084"
    },
    {
        "engDate": "2027-05-11",
        "nepDate": "28.01.2084"
    },
    {
        "engDate": "2027-05-12",
        "nepDate": "29.01.2084"
    },
    {
        "engDate": "2027-05-13",
        "nepDate": "30.01.2084"
    },
    {
        "engDate": "2027-05-14",
        "nepDate": "31.01.2084"
    },
    {
        "engDate": "2027-05-15",
        "nepDate": "01.02.2084"
    },
    {
        "engDate": "2027-05-16",
        "nepDate": "02.02.2084"
    },
    {
        "engDate": "2027-05-17",
        "nepDate": "03.02.2084"
    },
    {
        "engDate": "2027-05-18",
        "nepDate": "04.02.2084"
    },
    {
        "engDate": "2027-05-19",
        "nepDate": "05.02.2084"
    },
    {
        "engDate": "2027-05-20",
        "nepDate": "06.02.2084"
    },
    {
        "engDate": "2027-05-21",
        "nepDate": "07.02.2084"
    },
    {
        "engDate": "2027-05-22",
        "nepDate": "08.02.2084"
    },
    {
        "engDate": "2027-05-23",
        "nepDate": "09.02.2084"
    },
    {
        "engDate": "2027-05-24",
        "nepDate": "10.02.2084"
    },
    {
        "engDate": "2027-05-25",
        "nepDate": "11.02.2084"
    },
    {
        "engDate": "2027-05-26",
        "nepDate": "12.02.2084"
    },
    {
        "engDate": "2027-05-27",
        "nepDate": "13.02.2084"
    },
    {
        "engDate": "2027-05-28",
        "nepDate": "14.02.2084"
    },
    {
        "engDate": "2027-05-29",
        "nepDate": "15.02.2084"
    },
    {
        "engDate": "2027-05-30",
        "nepDate": "16.02.2084"
    },
    {
        "engDate": "2027-05-31",
        "nepDate": "17.02.2084"
    },
    {
        "engDate": "2027-06-01",
        "nepDate": "18.02.2084"
    },
    {
        "engDate": "2027-06-02",
        "nepDate": "19.02.2084"
    },
    {
        "engDate": "2027-06-03",
        "nepDate": "20.02.2084"
    },
    {
        "engDate": "2027-06-04",
        "nepDate": "21.02.2084"
    },
    {
        "engDate": "2027-06-05",
        "nepDate": "22.02.2084"
    },
    {
        "engDate": "2027-06-06",
        "nepDate": "23.02.2084"
    },
    {
        "engDate": "2027-06-07",
        "nepDate": "24.02.2084"
    },
    {
        "engDate": "2027-06-08",
        "nepDate": "25.02.2084"
    },
    {
        "engDate": "2027-06-09",
        "nepDate": "26.02.2084"
    },
    {
        "engDate": "2027-06-10",
        "nepDate": "27.02.2084"
    },
    {
        "engDate": "2027-06-11",
        "nepDate": "28.02.2084"
    },
    {
        "engDate": "2027-06-12",
        "nepDate": "29.02.2084"
    },
    {
        "engDate": "2027-06-13",
        "nepDate": "30.02.2084"
    },
    {
        "engDate": "2027-06-14",
        "nepDate": "31.02.2084"
    },
    {
        "engDate": "2027-06-15",
        "nepDate": "01.03.2084"
    },
    {
        "engDate": "2027-06-16",
        "nepDate": "02.03.2084"
    },
    {
        "engDate": "2027-06-17",
        "nepDate": "03.03.2084"
    },
    {
        "engDate": "2027-06-18",
        "nepDate": "04.03.2084"
    },
    {
        "engDate": "2027-06-19",
        "nepDate": "05.03.2084"
    },
    {
        "engDate": "2027-06-20",
        "nepDate": "06.03.2084"
    },
    {
        "engDate": "2027-06-21",
        "nepDate": "07.03.2084"
    },
    {
        "engDate": "2027-06-22",
        "nepDate": "08.03.2084"
    },
    {
        "engDate": "2027-06-23",
        "nepDate": "09.03.2084"
    },
    {
        "engDate": "2027-06-24",
        "nepDate": "10.03.2084"
    },
    {
        "engDate": "2027-06-25",
        "nepDate": "11.03.2084"
    },
    {
        "engDate": "2027-06-26",
        "nepDate": "12.03.2084"
    },
    {
        "engDate": "2027-06-27",
        "nepDate": "13.03.2084"
    },
    {
        "engDate": "2027-06-28",
        "nepDate": "14.03.2084"
    },
    {
        "engDate": "2027-06-29",
        "nepDate": "15.03.2084"
    },
    {
        "engDate": "2027-06-30",
        "nepDate": "16.03.2084"
    },
    {
        "engDate": "2027-07-01",
        "nepDate": "17.03.2084"
    },
    {
        "engDate": "2027-07-02",
        "nepDate": "18.03.2084"
    },
    {
        "engDate": "2027-07-03",
        "nepDate": "19.03.2084"
    },
    {
        "engDate": "2027-07-04",
        "nepDate": "20.03.2084"
    },
    {
        "engDate": "2027-07-05",
        "nepDate": "21.03.2084"
    },
    {
        "engDate": "2027-07-06",
        "nepDate": "22.03.2084"
    },
    {
        "engDate": "2027-07-07",
        "nepDate": "23.03.2084"
    },
    {
        "engDate": "2027-07-08",
        "nepDate": "24.03.2084"
    },
    {
        "engDate": "2027-07-09",
        "nepDate": "25.03.2084"
    },
    {
        "engDate": "2027-07-10",
        "nepDate": "26.03.2084"
    },
    {
        "engDate": "2027-07-11",
        "nepDate": "27.03.2084"
    },
    {
        "engDate": "2027-07-12",
        "nepDate": "28.03.2084"
    },
    {
        "engDate": "2027-07-13",
        "nepDate": "29.03.2084"
    },
    {
        "engDate": "2027-07-14",
        "nepDate": "30.03.2084"
    },
    {
        "engDate": "2027-07-15",
        "nepDate": "31.03.2084"
    },
    {
        "engDate": "2027-07-16",
        "nepDate": "32.03.2084"
    },
    {
        "engDate": "2027-07-17",
        "nepDate": "01.04.2084"
    },
    {
        "engDate": "2027-07-18",
        "nepDate": "02.04.2084"
    },
    {
        "engDate": "2027-07-19",
        "nepDate": "03.04.2084"
    },
    {
        "engDate": "2027-07-20",
        "nepDate": "04.04.2084"
    },
    {
        "engDate": "2027-07-21",
        "nepDate": "05.04.2084"
    },
    {
        "engDate": "2027-07-22",
        "nepDate": "06.04.2084"
    },
    {
        "engDate": "2027-07-23",
        "nepDate": "07.04.2084"
    },
    {
        "engDate": "2027-07-24",
        "nepDate": "08.04.2084"
    },
    {
        "engDate": "2027-07-25",
        "nepDate": "09.04.2084"
    },
    {
        "engDate": "2027-07-26",
        "nepDate": "10.04.2084"
    },
    {
        "engDate": "2027-07-27",
        "nepDate": "11.04.2084"
    },
    {
        "engDate": "2027-07-28",
        "nepDate": "12.04.2084"
    },
    {
        "engDate": "2027-07-29",
        "nepDate": "13.04.2084"
    },
    {
        "engDate": "2027-07-30",
        "nepDate": "14.04.2084"
    },
    {
        "engDate": "2027-07-31",
        "nepDate": "15.04.2084"
    },
    {
        "engDate": "2027-08-01",
        "nepDate": "16.04.2084"
    },
    {
        "engDate": "2027-08-02",
        "nepDate": "17.04.2084"
    },
    {
        "engDate": "2027-08-03",
        "nepDate": "18.04.2084"
    },
    {
        "engDate": "2027-08-04",
        "nepDate": "19.04.2084"
    },
    {
        "engDate": "2027-08-05",
        "nepDate": "20.04.2084"
    },
    {
        "engDate": "2027-08-06",
        "nepDate": "21.04.2084"
    },
    {
        "engDate": "2027-08-07",
        "nepDate": "22.04.2084"
    },
    {
        "engDate": "2027-08-08",
        "nepDate": "23.04.2084"
    },
    {
        "engDate": "2027-08-09",
        "nepDate": "24.04.2084"
    },
    {
        "engDate": "2027-08-10",
        "nepDate": "25.04.2084"
    },
    {
        "engDate": "2027-08-11",
        "nepDate": "26.04.2084"
    },
    {
        "engDate": "2027-08-12",
        "nepDate": "27.04.2084"
    },
    {
        "engDate": "2027-08-13",
        "nepDate": "28.04.2084"
    },
    {
        "engDate": "2027-08-14",
        "nepDate": "29.04.2084"
    },
    {
        "engDate": "2027-08-15",
        "nepDate": "30.04.2084"
    },
    {
        "engDate": "2027-08-16",
        "nepDate": "31.04.2084"
    },
    {
        "engDate": "2027-08-17",
        "nepDate": "01.05.2084"
    },
    {
        "engDate": "2027-08-18",
        "nepDate": "02.05.2084"
    },
    {
        "engDate": "2027-08-19",
        "nepDate": "03.05.2084"
    },
    {
        "engDate": "2027-08-20",
        "nepDate": "04.05.2084"
    },
    {
        "engDate": "2027-08-21",
        "nepDate": "05.05.2084"
    },
    {
        "engDate": "2027-08-22",
        "nepDate": "06.05.2084"
    },
    {
        "engDate": "2027-08-23",
        "nepDate": "07.05.2084"
    },
    {
        "engDate": "2027-08-24",
        "nepDate": "08.05.2084"
    },
    {
        "engDate": "2027-08-25",
        "nepDate": "09.05.2084"
    },
    {
        "engDate": "2027-08-26",
        "nepDate": "10.05.2084"
    },
    {
        "engDate": "2027-08-27",
        "nepDate": "11.05.2084"
    },
    {
        "engDate": "2027-08-28",
        "nepDate": "12.05.2084"
    },
    {
        "engDate": "2027-08-29",
        "nepDate": "13.05.2084"
    },
    {
        "engDate": "2027-08-30",
        "nepDate": "14.05.2084"
    },
    {
        "engDate": "2027-08-31",
        "nepDate": "15.05.2084"
    },
    {
        "engDate": "2027-09-01",
        "nepDate": "16.05.2084"
    },
    {
        "engDate": "2027-09-02",
        "nepDate": "17.05.2084"
    },
    {
        "engDate": "2027-09-03",
        "nepDate": "18.05.2084"
    },
    {
        "engDate": "2027-09-04",
        "nepDate": "19.05.2084"
    },
    {
        "engDate": "2027-09-05",
        "nepDate": "20.05.2084"
    },
    {
        "engDate": "2027-09-06",
        "nepDate": "21.05.2084"
    },
    {
        "engDate": "2027-09-07",
        "nepDate": "22.05.2084"
    },
    {
        "engDate": "2027-09-08",
        "nepDate": "23.05.2084"
    },
    {
        "engDate": "2027-09-09",
        "nepDate": "24.05.2084"
    },
    {
        "engDate": "2027-09-10",
        "nepDate": "25.05.2084"
    },
    {
        "engDate": "2027-09-11",
        "nepDate": "26.05.2084"
    },
    {
        "engDate": "2027-09-12",
        "nepDate": "27.05.2084"
    },
    {
        "engDate": "2027-09-13",
        "nepDate": "28.05.2084"
    },
    {
        "engDate": "2027-09-14",
        "nepDate": "29.05.2084"
    },
    {
        "engDate": "2027-09-15",
        "nepDate": "30.05.2084"
    },
    {
        "engDate": "2027-09-16",
        "nepDate": "31.05.2084"
    },
    {
        "engDate": "2027-09-17",
        "nepDate": "01.06.2084"
    },
    {
        "engDate": "2027-09-18",
        "nepDate": "02.06.2084"
    },
    {
        "engDate": "2027-09-19",
        "nepDate": "03.06.2084"
    },
    {
        "engDate": "2027-09-20",
        "nepDate": "04.06.2084"
    },
    {
        "engDate": "2027-09-21",
        "nepDate": "05.06.2084"
    },
    {
        "engDate": "2027-09-22",
        "nepDate": "06.06.2084"
    },
    {
        "engDate": "2027-09-23",
        "nepDate": "07.06.2084"
    },
    {
        "engDate": "2027-09-24",
        "nepDate": "08.06.2084"
    },
    {
        "engDate": "2027-09-25",
        "nepDate": "09.06.2084"
    },
    {
        "engDate": "2027-09-26",
        "nepDate": "10.06.2084"
    },
    {
        "engDate": "2027-09-27",
        "nepDate": "11.06.2084"
    },
    {
        "engDate": "2027-09-28",
        "nepDate": "12.06.2084"
    },
    {
        "engDate": "2027-09-29",
        "nepDate": "13.06.2084"
    },
    {
        "engDate": "2027-09-30",
        "nepDate": "14.06.2084"
    },
    {
        "engDate": "2027-10-01",
        "nepDate": "15.06.2084"
    },
    {
        "engDate": "2027-10-02",
        "nepDate": "16.06.2084"
    },
    {
        "engDate": "2027-10-03",
        "nepDate": "17.06.2084"
    },
    {
        "engDate": "2027-10-04",
        "nepDate": "18.06.2084"
    },
    {
        "engDate": "2027-10-05",
        "nepDate": "19.06.2084"
    },
    {
        "engDate": "2027-10-06",
        "nepDate": "20.06.2084"
    },
    {
        "engDate": "2027-10-07",
        "nepDate": "21.06.2084"
    },
    {
        "engDate": "2027-10-08",
        "nepDate": "22.06.2084"
    },
    {
        "engDate": "2027-10-09",
        "nepDate": "23.06.2084"
    },
    {
        "engDate": "2027-10-10",
        "nepDate": "24.06.2084"
    },
    {
        "engDate": "2027-10-11",
        "nepDate": "25.06.2084"
    },
    {
        "engDate": "2027-10-12",
        "nepDate": "26.06.2084"
    },
    {
        "engDate": "2027-10-13",
        "nepDate": "27.06.2084"
    },
    {
        "engDate": "2027-10-14",
        "nepDate": "28.06.2084"
    },
    {
        "engDate": "2027-10-15",
        "nepDate": "29.06.2084"
    },
    {
        "engDate": "2027-10-16",
        "nepDate": "30.06.2084"
    },
    {
        "engDate": "2027-10-17",
        "nepDate": "01.07.2084"
    },
    {
        "engDate": "2027-10-18",
        "nepDate": "02.07.2084"
    },
    {
        "engDate": "2027-10-19",
        "nepDate": "03.07.2084"
    },
    {
        "engDate": "2027-10-20",
        "nepDate": "04.07.2084"
    },
    {
        "engDate": "2027-10-21",
        "nepDate": "05.07.2084"
    },
    {
        "engDate": "2027-10-22",
        "nepDate": "06.07.2084"
    },
    {
        "engDate": "2027-10-23",
        "nepDate": "07.07.2084"
    },
    {
        "engDate": "2027-10-24",
        "nepDate": "08.07.2084"
    },
    {
        "engDate": "2027-10-25",
        "nepDate": "09.07.2084"
    },
    {
        "engDate": "2027-10-26",
        "nepDate": "10.07.2084"
    },
    {
        "engDate": "2027-10-27",
        "nepDate": "11.07.2084"
    },
    {
        "engDate": "2027-10-28",
        "nepDate": "12.07.2084"
    },
    {
        "engDate": "2027-10-29",
        "nepDate": "13.07.2084"
    },
    {
        "engDate": "2027-10-30",
        "nepDate": "14.07.2084"
    },
    {
        "engDate": "2027-10-31",
        "nepDate": "15.07.2084"
    },
    {
        "engDate": "2027-11-01",
        "nepDate": "16.07.2084"
    },
    {
        "engDate": "2027-11-02",
        "nepDate": "17.07.2084"
    },
    {
        "engDate": "2027-11-03",
        "nepDate": "18.07.2084"
    },
    {
        "engDate": "2027-11-04",
        "nepDate": "19.07.2084"
    },
    {
        "engDate": "2027-11-05",
        "nepDate": "20.07.2084"
    },
    {
        "engDate": "2027-11-06",
        "nepDate": "21.07.2084"
    },
    {
        "engDate": "2027-11-07",
        "nepDate": "22.07.2084"
    },
    {
        "engDate": "2027-11-08",
        "nepDate": "23.07.2084"
    },
    {
        "engDate": "2027-11-09",
        "nepDate": "24.07.2084"
    },
    {
        "engDate": "2027-11-10",
        "nepDate": "25.07.2084"
    },
    {
        "engDate": "2027-11-11",
        "nepDate": "26.07.2084"
    },
    {
        "engDate": "2027-11-12",
        "nepDate": "27.07.2084"
    },
    {
        "engDate": "2027-11-13",
        "nepDate": "28.07.2084"
    },
    {
        "engDate": "2027-11-14",
        "nepDate": "29.07.2084"
    },
    {
        "engDate": "2027-11-15",
        "nepDate": "30.07.2084"
    },
    {
        "engDate": "2027-11-16",
        "nepDate": "01.08.2084"
    },
    {
        "engDate": "2027-11-17",
        "nepDate": "02.08.2084"
    },
    {
        "engDate": "2027-11-18",
        "nepDate": "03.08.2084"
    },
    {
        "engDate": "2027-11-19",
        "nepDate": "04.08.2084"
    },
    {
        "engDate": "2027-11-20",
        "nepDate": "05.08.2084"
    },
    {
        "engDate": "2027-11-21",
        "nepDate": "06.08.2084"
    },
    {
        "engDate": "2027-11-22",
        "nepDate": "07.08.2084"
    },
    {
        "engDate": "2027-11-23",
        "nepDate": "08.08.2084"
    },
    {
        "engDate": "2027-11-24",
        "nepDate": "09.08.2084"
    },
    {
        "engDate": "2027-11-25",
        "nepDate": "10.08.2084"
    },
    {
        "engDate": "2027-11-26",
        "nepDate": "11.08.2084"
    },
    {
        "engDate": "2027-11-27",
        "nepDate": "12.08.2084"
    },
    {
        "engDate": "2027-11-28",
        "nepDate": "13.08.2084"
    },
    {
        "engDate": "2027-11-29",
        "nepDate": "14.08.2084"
    },
    {
        "engDate": "2027-11-30",
        "nepDate": "15.08.2084"
    },
    {
        "engDate": "2027-12-01",
        "nepDate": "16.08.2084"
    },
    {
        "engDate": "2027-12-02",
        "nepDate": "17.08.2084"
    },
    {
        "engDate": "2027-12-03",
        "nepDate": "18.08.2084"
    },
    {
        "engDate": "2027-12-04",
        "nepDate": "19.08.2084"
    },
    {
        "engDate": "2027-12-05",
        "nepDate": "20.08.2084"
    },
    {
        "engDate": "2027-12-06",
        "nepDate": "21.08.2084"
    },
    {
        "engDate": "2027-12-07",
        "nepDate": "22.08.2084"
    },
    {
        "engDate": "2027-12-08",
        "nepDate": "23.08.2084"
    },
    {
        "engDate": "2027-12-09",
        "nepDate": "24.08.2084"
    },
    {
        "engDate": "2027-12-10",
        "nepDate": "25.08.2084"
    },
    {
        "engDate": "2027-12-11",
        "nepDate": "26.08.2084"
    },
    {
        "engDate": "2027-12-12",
        "nepDate": "27.08.2084"
    },
    {
        "engDate": "2027-12-13",
        "nepDate": "28.08.2084"
    },
    {
        "engDate": "2027-12-14",
        "nepDate": "29.08.2084"
    },
    {
        "engDate": "2027-12-15",
        "nepDate": "30.08.2084"
    },
    {
        "engDate": "2027-12-16",
        "nepDate": "01.09.2084"
    },
    {
        "engDate": "2027-12-17",
        "nepDate": "02.09.2084"
    },
    {
        "engDate": "2027-12-18",
        "nepDate": "03.09.2084"
    },
    {
        "engDate": "2027-12-19",
        "nepDate": "04.09.2084"
    },
    {
        "engDate": "2027-12-20",
        "nepDate": "05.09.2084"
    },
    {
        "engDate": "2027-12-21",
        "nepDate": "06.09.2084"
    },
    {
        "engDate": "2027-12-22",
        "nepDate": "07.09.2084"
    },
    {
        "engDate": "2027-12-23",
        "nepDate": "08.09.2084"
    },
    {
        "engDate": "2027-12-24",
        "nepDate": "09.09.2084"
    },
    {
        "engDate": "2027-12-25",
        "nepDate": "10.09.2084"
    },
    {
        "engDate": "2027-12-26",
        "nepDate": "11.09.2084"
    },
    {
        "engDate": "2027-12-27",
        "nepDate": "12.09.2084"
    },
    {
        "engDate": "2027-12-28",
        "nepDate": "13.09.2084"
    },
    {
        "engDate": "2027-12-29",
        "nepDate": "14.09.2084"
    },
    {
        "engDate": "2027-12-30",
        "nepDate": "15.09.2084"
    },
    {
        "engDate": "2027-12-31",
        "nepDate": "16.09.2084"
    },
    {
        "engDate": "2028-01-01",
        "nepDate": "17.09.2084"
    },
    {
        "engDate": "2028-01-02",
        "nepDate": "18.09.2084"
    },
    {
        "engDate": "2028-01-03",
        "nepDate": "19.09.2084"
    },
    {
        "engDate": "2028-01-04",
        "nepDate": "20.09.2084"
    },
    {
        "engDate": "2028-01-05",
        "nepDate": "21.09.2084"
    },
    {
        "engDate": "2028-01-06",
        "nepDate": "22.09.2084"
    },
    {
        "engDate": "2028-01-07",
        "nepDate": "23.09.2084"
    },
    {
        "engDate": "2028-01-08",
        "nepDate": "24.09.2084"
    },
    {
        "engDate": "2028-01-09",
        "nepDate": "25.09.2084"
    },
    {
        "engDate": "2028-01-10",
        "nepDate": "26.09.2084"
    },
    {
        "engDate": "2028-01-11",
        "nepDate": "27.09.2084"
    },
    {
        "engDate": "2028-01-12",
        "nepDate": "28.09.2084"
    },
    {
        "engDate": "2028-01-13",
        "nepDate": "29.09.2084"
    },
    {
        "engDate": "2028-01-14",
        "nepDate": "01.10.2084"
    },
    {
        "engDate": "2028-01-15",
        "nepDate": "02.10.2084"
    },
    {
        "engDate": "2028-01-16",
        "nepDate": "03.10.2084"
    },
    {
        "engDate": "2028-01-17",
        "nepDate": "04.10.2084"
    },
    {
        "engDate": "2028-01-18",
        "nepDate": "05.10.2084"
    },
    {
        "engDate": "2028-01-19",
        "nepDate": "06.10.2084"
    },
    {
        "engDate": "2028-01-20",
        "nepDate": "07.10.2084"
    },
    {
        "engDate": "2028-01-21",
        "nepDate": "08.10.2084"
    },
    {
        "engDate": "2028-01-22",
        "nepDate": "09.10.2084"
    },
    {
        "engDate": "2028-01-23",
        "nepDate": "10.10.2084"
    },
    {
        "engDate": "2028-01-24",
        "nepDate": "11.10.2084"
    },
    {
        "engDate": "2028-01-25",
        "nepDate": "12.10.2084"
    },
    {
        "engDate": "2028-01-26",
        "nepDate": "13.10.2084"
    },
    {
        "engDate": "2028-01-27",
        "nepDate": "14.10.2084"
    },
    {
        "engDate": "2028-01-28",
        "nepDate": "15.10.2084"
    },
    {
        "engDate": "2028-01-29",
        "nepDate": "16.10.2084"
    },
    {
        "engDate": "2028-01-30",
        "nepDate": "17.10.2084"
    },
    {
        "engDate": "2028-01-31",
        "nepDate": "18.10.2084"
    },
    {
        "engDate": "2028-02-01",
        "nepDate": "19.10.2084"
    },
    {
        "engDate": "2028-02-02",
        "nepDate": "20.10.2084"
    },
    {
        "engDate": "2028-02-03",
        "nepDate": "21.10.2084"
    },
    {
        "engDate": "2028-02-04",
        "nepDate": "22.10.2084"
    },
    {
        "engDate": "2028-02-05",
        "nepDate": "23.10.2084"
    },
    {
        "engDate": "2028-02-06",
        "nepDate": "24.10.2084"
    },
    {
        "engDate": "2028-02-07",
        "nepDate": "25.10.2084"
    },
    {
        "engDate": "2028-02-08",
        "nepDate": "26.10.2084"
    },
    {
        "engDate": "2028-02-09",
        "nepDate": "27.10.2084"
    },
    {
        "engDate": "2028-02-10",
        "nepDate": "28.10.2084"
    },
    {
        "engDate": "2028-02-11",
        "nepDate": "29.10.2084"
    },
    {
        "engDate": "2028-02-12",
        "nepDate": "30.10.2084"
    },
    {
        "engDate": "2028-02-13",
        "nepDate": "01.11.2084"
    },
    {
        "engDate": "2028-02-14",
        "nepDate": "02.11.2084"
    },
    {
        "engDate": "2028-02-15",
        "nepDate": "03.11.2084"
    },
    {
        "engDate": "2028-02-16",
        "nepDate": "04.11.2084"
    },
    {
        "engDate": "2028-02-17",
        "nepDate": "05.11.2084"
    },
    {
        "engDate": "2028-02-18",
        "nepDate": "06.11.2084"
    },
    {
        "engDate": "2028-02-19",
        "nepDate": "07.11.2084"
    },
    {
        "engDate": "2028-02-20",
        "nepDate": "08.11.2084"
    },
    {
        "engDate": "2028-02-21",
        "nepDate": "09.11.2084"
    },
    {
        "engDate": "2028-02-22",
        "nepDate": "10.11.2084"
    },
    {
        "engDate": "2028-02-23",
        "nepDate": "11.11.2084"
    },
    {
        "engDate": "2028-02-24",
        "nepDate": "12.11.2084"
    },
    {
        "engDate": "2028-02-25",
        "nepDate": "13.11.2084"
    },
    {
        "engDate": "2028-02-26",
        "nepDate": "14.11.2084"
    },
    {
        "engDate": "2028-02-27",
        "nepDate": "15.11.2084"
    },
    {
        "engDate": "2028-02-28",
        "nepDate": "16.11.2084"
    },
    {
        "engDate": "2028-02-29",
        "nepDate": "17.11.2084"
    },
    {
        "engDate": "2028-03-01",
        "nepDate": "18.11.2084"
    },
    {
        "engDate": "2028-03-02",
        "nepDate": "19.11.2084"
    },
    {
        "engDate": "2028-03-03",
        "nepDate": "20.11.2084"
    },
    {
        "engDate": "2028-03-04",
        "nepDate": "21.11.2084"
    },
    {
        "engDate": "2028-03-05",
        "nepDate": "22.11.2084"
    },
    {
        "engDate": "2028-03-06",
        "nepDate": "23.11.2084"
    },
    {
        "engDate": "2028-03-07",
        "nepDate": "24.11.2084"
    },
    {
        "engDate": "2028-03-08",
        "nepDate": "25.11.2084"
    },
    {
        "engDate": "2028-03-09",
        "nepDate": "26.11.2084"
    },
    {
        "engDate": "2028-03-10",
        "nepDate": "27.11.2084"
    },
    {
        "engDate": "2028-03-11",
        "nepDate": "28.11.2084"
    },
    {
        "engDate": "2028-03-12",
        "nepDate": "29.11.2084"
    },
    {
        "engDate": "2028-03-13",
        "nepDate": "30.11.2084"
    },
    {
        "engDate": "2028-03-14",
        "nepDate": "01.12.2084"
    },
    {
        "engDate": "2028-03-15",
        "nepDate": "02.12.2084"
    },
    {
        "engDate": "2028-03-16",
        "nepDate": "03.12.2084"
    },
    {
        "engDate": "2028-03-17",
        "nepDate": "04.12.2084"
    },
    {
        "engDate": "2028-03-18",
        "nepDate": "05.12.2084"
    },
    {
        "engDate": "2028-03-19",
        "nepDate": "06.12.2084"
    },
    {
        "engDate": "2028-03-20",
        "nepDate": "07.12.2084"
    },
    {
        "engDate": "2028-03-21",
        "nepDate": "08.12.2084"
    },
    {
        "engDate": "2028-03-22",
        "nepDate": "09.12.2084"
    },
    {
        "engDate": "2028-03-23",
        "nepDate": "10.12.2084"
    },
    {
        "engDate": "2028-03-24",
        "nepDate": "11.12.2084"
    },
    {
        "engDate": "2028-03-25",
        "nepDate": "12.12.2084"
    },
    {
        "engDate": "2028-03-26",
        "nepDate": "13.12.2084"
    },
    {
        "engDate": "2028-03-27",
        "nepDate": "14.12.2084"
    },
    {
        "engDate": "2028-03-28",
        "nepDate": "15.12.2084"
    },
    {
        "engDate": "2028-03-29",
        "nepDate": "16.12.2084"
    },
    {
        "engDate": "2028-03-30",
        "nepDate": "17.12.2084"
    },
    {
        "engDate": "2028-03-31",
        "nepDate": "18.12.2084"
    },
    {
        "engDate": "2028-04-01",
        "nepDate": "19.12.2084"
    },
    {
        "engDate": "2028-04-02",
        "nepDate": "20.12.2084"
    },
    {
        "engDate": "2028-04-03",
        "nepDate": "21.12.2084"
    },
    {
        "engDate": "2028-04-04",
        "nepDate": "22.12.2084"
    },
    {
        "engDate": "2028-04-05",
        "nepDate": "23.12.2084"
    },
    {
        "engDate": "2028-04-06",
        "nepDate": "24.12.2084"
    },
    {
        "engDate": "2028-04-07",
        "nepDate": "25.12.2084"
    },
    {
        "engDate": "2028-04-08",
        "nepDate": "26.12.2084"
    },
    {
        "engDate": "2028-04-09",
        "nepDate": "27.12.2084"
    },
    {
        "engDate": "2028-04-10",
        "nepDate": "28.12.2084"
    },
    {
        "engDate": "2028-04-11",
        "nepDate": "29.12.2084"
    },
    {
        "engDate": "2028-04-12",
        "nepDate": "30.12.2084"
    },
    {
        "engDate": "2028-04-13",
        "nepDate": "01.01.2085"
    },
    {
        "engDate": "2028-04-14",
        "nepDate": "02.01.2085"
    },
    {
        "engDate": "2028-04-15",
        "nepDate": "03.01.2085"
    },
    {
        "engDate": "2028-04-16",
        "nepDate": "04.01.2085"
    },
    {
        "engDate": "2028-04-17",
        "nepDate": "05.01.2085"
    },
    {
        "engDate": "2028-04-18",
        "nepDate": "06.01.2085"
    },
    {
        "engDate": "2028-04-19",
        "nepDate": "07.01.2085"
    },
    {
        "engDate": "2028-04-20",
        "nepDate": "08.01.2085"
    },
    {
        "engDate": "2028-04-21",
        "nepDate": "09.01.2085"
    },
    {
        "engDate": "2028-04-22",
        "nepDate": "10.01.2085"
    },
    {
        "engDate": "2028-04-23",
        "nepDate": "11.01.2085"
    },
    {
        "engDate": "2028-04-24",
        "nepDate": "12.01.2085"
    },
    {
        "engDate": "2028-04-25",
        "nepDate": "13.01.2085"
    },
    {
        "engDate": "2028-04-26",
        "nepDate": "14.01.2085"
    },
    {
        "engDate": "2028-04-27",
        "nepDate": "15.01.2085"
    },
    {
        "engDate": "2028-04-28",
        "nepDate": "16.01.2085"
    },
    {
        "engDate": "2028-04-29",
        "nepDate": "17.01.2085"
    },
    {
        "engDate": "2028-04-30",
        "nepDate": "18.01.2085"
    },
    {
        "engDate": "2028-05-01",
        "nepDate": "19.01.2085"
    },
    {
        "engDate": "2028-05-02",
        "nepDate": "20.01.2085"
    },
    {
        "engDate": "2028-05-03",
        "nepDate": "21.01.2085"
    },
    {
        "engDate": "2028-05-04",
        "nepDate": "22.01.2085"
    },
    {
        "engDate": "2028-05-05",
        "nepDate": "23.01.2085"
    },
    {
        "engDate": "2028-05-06",
        "nepDate": "24.01.2085"
    },
    {
        "engDate": "2028-05-07",
        "nepDate": "25.01.2085"
    },
    {
        "engDate": "2028-05-08",
        "nepDate": "26.01.2085"
    },
    {
        "engDate": "2028-05-09",
        "nepDate": "27.01.2085"
    },
    {
        "engDate": "2028-05-10",
        "nepDate": "28.01.2085"
    },
    {
        "engDate": "2028-05-11",
        "nepDate": "29.01.2085"
    },
    {
        "engDate": "2028-05-12",
        "nepDate": "30.01.2085"
    },
    {
        "engDate": "2028-05-13",
        "nepDate": "31.01.2085"
    },
    {
        "engDate": "2028-05-14",
        "nepDate": "01.02.2085"
    },
    {
        "engDate": "2028-05-15",
        "nepDate": "02.02.2085"
    },
    {
        "engDate": "2028-05-16",
        "nepDate": "03.02.2085"
    },
    {
        "engDate": "2028-05-17",
        "nepDate": "04.02.2085"
    },
    {
        "engDate": "2028-05-18",
        "nepDate": "05.02.2085"
    },
    {
        "engDate": "2028-05-19",
        "nepDate": "06.02.2085"
    },
    {
        "engDate": "2028-05-20",
        "nepDate": "07.02.2085"
    },
    {
        "engDate": "2028-05-21",
        "nepDate": "08.02.2085"
    },
    {
        "engDate": "2028-05-22",
        "nepDate": "09.02.2085"
    },
    {
        "engDate": "2028-05-23",
        "nepDate": "10.02.2085"
    },
    {
        "engDate": "2028-05-24",
        "nepDate": "11.02.2085"
    },
    {
        "engDate": "2028-05-25",
        "nepDate": "12.02.2085"
    },
    {
        "engDate": "2028-05-26",
        "nepDate": "13.02.2085"
    },
    {
        "engDate": "2028-05-27",
        "nepDate": "14.02.2085"
    },
    {
        "engDate": "2028-05-28",
        "nepDate": "15.02.2085"
    },
    {
        "engDate": "2028-05-29",
        "nepDate": "16.02.2085"
    },
    {
        "engDate": "2028-05-30",
        "nepDate": "17.02.2085"
    },
    {
        "engDate": "2028-05-31",
        "nepDate": "18.02.2085"
    },
    {
        "engDate": "2028-06-01",
        "nepDate": "19.02.2085"
    },
    {
        "engDate": "2028-06-02",
        "nepDate": "20.02.2085"
    },
    {
        "engDate": "2028-06-03",
        "nepDate": "21.02.2085"
    },
    {
        "engDate": "2028-06-04",
        "nepDate": "22.02.2085"
    },
    {
        "engDate": "2028-06-05",
        "nepDate": "23.02.2085"
    },
    {
        "engDate": "2028-06-06",
        "nepDate": "24.02.2085"
    },
    {
        "engDate": "2028-06-07",
        "nepDate": "25.02.2085"
    },
    {
        "engDate": "2028-06-08",
        "nepDate": "26.02.2085"
    },
    {
        "engDate": "2028-06-09",
        "nepDate": "27.02.2085"
    },
    {
        "engDate": "2028-06-10",
        "nepDate": "28.02.2085"
    },
    {
        "engDate": "2028-06-11",
        "nepDate": "29.02.2085"
    },
    {
        "engDate": "2028-06-12",
        "nepDate": "30.02.2085"
    },
    {
        "engDate": "2028-06-13",
        "nepDate": "31.02.2085"
    },
    {
        "engDate": "2028-06-14",
        "nepDate": "32.02.2085"
    },
    {
        "engDate": "2028-06-15",
        "nepDate": "01.03.2085"
    },
    {
        "engDate": "2028-06-16",
        "nepDate": "02.03.2085"
    },
    {
        "engDate": "2028-06-17",
        "nepDate": "03.03.2085"
    },
    {
        "engDate": "2028-06-18",
        "nepDate": "04.03.2085"
    },
    {
        "engDate": "2028-06-19",
        "nepDate": "05.03.2085"
    },
    {
        "engDate": "2028-06-20",
        "nepDate": "06.03.2085"
    },
    {
        "engDate": "2028-06-21",
        "nepDate": "07.03.2085"
    },
    {
        "engDate": "2028-06-22",
        "nepDate": "08.03.2085"
    },
    {
        "engDate": "2028-06-23",
        "nepDate": "09.03.2085"
    },
    {
        "engDate": "2028-06-24",
        "nepDate": "10.03.2085"
    },
    {
        "engDate": "2028-06-25",
        "nepDate": "11.03.2085"
    },
    {
        "engDate": "2028-06-26",
        "nepDate": "12.03.2085"
    },
    {
        "engDate": "2028-06-27",
        "nepDate": "13.03.2085"
    },
    {
        "engDate": "2028-06-28",
        "nepDate": "14.03.2085"
    },
    {
        "engDate": "2028-06-29",
        "nepDate": "15.03.2085"
    },
    {
        "engDate": "2028-06-30",
        "nepDate": "16.03.2085"
    },
    {
        "engDate": "2028-07-01",
        "nepDate": "17.03.2085"
    },
    {
        "engDate": "2028-07-02",
        "nepDate": "18.03.2085"
    },
    {
        "engDate": "2028-07-03",
        "nepDate": "19.03.2085"
    },
    {
        "engDate": "2028-07-04",
        "nepDate": "20.03.2085"
    },
    {
        "engDate": "2028-07-05",
        "nepDate": "21.03.2085"
    },
    {
        "engDate": "2028-07-06",
        "nepDate": "22.03.2085"
    },
    {
        "engDate": "2028-07-07",
        "nepDate": "23.03.2085"
    },
    {
        "engDate": "2028-07-08",
        "nepDate": "24.03.2085"
    },
    {
        "engDate": "2028-07-09",
        "nepDate": "25.03.2085"
    },
    {
        "engDate": "2028-07-10",
        "nepDate": "26.03.2085"
    },
    {
        "engDate": "2028-07-11",
        "nepDate": "27.03.2085"
    },
    {
        "engDate": "2028-07-12",
        "nepDate": "28.03.2085"
    },
    {
        "engDate": "2028-07-13",
        "nepDate": "29.03.2085"
    },
    {
        "engDate": "2028-07-14",
        "nepDate": "30.03.2085"
    },
    {
        "engDate": "2028-07-15",
        "nepDate": "31.03.2085"
    },
    {
        "engDate": "2028-07-16",
        "nepDate": "01.04.2085"
    },
    {
        "engDate": "2028-07-17",
        "nepDate": "02.04.2085"
    },
    {
        "engDate": "2028-07-18",
        "nepDate": "03.04.2085"
    },
    {
        "engDate": "2028-07-19",
        "nepDate": "04.04.2085"
    },
    {
        "engDate": "2028-07-20",
        "nepDate": "05.04.2085"
    },
    {
        "engDate": "2028-07-21",
        "nepDate": "06.04.2085"
    },
    {
        "engDate": "2028-07-22",
        "nepDate": "07.04.2085"
    },
    {
        "engDate": "2028-07-23",
        "nepDate": "08.04.2085"
    },
    {
        "engDate": "2028-07-24",
        "nepDate": "09.04.2085"
    },
    {
        "engDate": "2028-07-25",
        "nepDate": "10.04.2085"
    },
    {
        "engDate": "2028-07-26",
        "nepDate": "11.04.2085"
    },
    {
        "engDate": "2028-07-27",
        "nepDate": "12.04.2085"
    },
    {
        "engDate": "2028-07-28",
        "nepDate": "13.04.2085"
    },
    {
        "engDate": "2028-07-29",
        "nepDate": "14.04.2085"
    },
    {
        "engDate": "2028-07-30",
        "nepDate": "15.04.2085"
    },
    {
        "engDate": "2028-07-31",
        "nepDate": "16.04.2085"
    },
    {
        "engDate": "2028-08-01",
        "nepDate": "17.04.2085"
    },
    {
        "engDate": "2028-08-02",
        "nepDate": "18.04.2085"
    },
    {
        "engDate": "2028-08-03",
        "nepDate": "19.04.2085"
    },
    {
        "engDate": "2028-08-04",
        "nepDate": "20.04.2085"
    },
    {
        "engDate": "2028-08-05",
        "nepDate": "21.04.2085"
    },
    {
        "engDate": "2028-08-06",
        "nepDate": "22.04.2085"
    },
    {
        "engDate": "2028-08-07",
        "nepDate": "23.04.2085"
    },
    {
        "engDate": "2028-08-08",
        "nepDate": "24.04.2085"
    },
    {
        "engDate": "2028-08-09",
        "nepDate": "25.04.2085"
    },
    {
        "engDate": "2028-08-10",
        "nepDate": "26.04.2085"
    },
    {
        "engDate": "2028-08-11",
        "nepDate": "27.04.2085"
    },
    {
        "engDate": "2028-08-12",
        "nepDate": "28.04.2085"
    },
    {
        "engDate": "2028-08-13",
        "nepDate": "29.04.2085"
    },
    {
        "engDate": "2028-08-14",
        "nepDate": "30.04.2085"
    },
    {
        "engDate": "2028-08-15",
        "nepDate": "31.04.2085"
    },
    {
        "engDate": "2028-08-16",
        "nepDate": "32.04.2085"
    },
    {
        "engDate": "2028-08-17",
        "nepDate": "01.05.2085"
    },
    {
        "engDate": "2028-08-18",
        "nepDate": "02.05.2085"
    },
    {
        "engDate": "2028-08-19",
        "nepDate": "03.05.2085"
    },
    {
        "engDate": "2028-08-20",
        "nepDate": "04.05.2085"
    },
    {
        "engDate": "2028-08-21",
        "nepDate": "05.05.2085"
    },
    {
        "engDate": "2028-08-22",
        "nepDate": "06.05.2085"
    },
    {
        "engDate": "2028-08-23",
        "nepDate": "07.05.2085"
    },
    {
        "engDate": "2028-08-24",
        "nepDate": "08.05.2085"
    },
    {
        "engDate": "2028-08-25",
        "nepDate": "09.05.2085"
    },
    {
        "engDate": "2028-08-26",
        "nepDate": "10.05.2085"
    },
    {
        "engDate": "2028-08-27",
        "nepDate": "11.05.2085"
    },
    {
        "engDate": "2028-08-28",
        "nepDate": "12.05.2085"
    },
    {
        "engDate": "2028-08-29",
        "nepDate": "13.05.2085"
    },
    {
        "engDate": "2028-08-30",
        "nepDate": "14.05.2085"
    },
    {
        "engDate": "2028-08-31",
        "nepDate": "15.05.2085"
    },
    {
        "engDate": "2028-09-01",
        "nepDate": "16.05.2085"
    },
    {
        "engDate": "2028-09-02",
        "nepDate": "17.05.2085"
    },
    {
        "engDate": "2028-09-03",
        "nepDate": "18.05.2085"
    },
    {
        "engDate": "2028-09-04",
        "nepDate": "19.05.2085"
    },
    {
        "engDate": "2028-09-05",
        "nepDate": "20.05.2085"
    },
    {
        "engDate": "2028-09-06",
        "nepDate": "21.05.2085"
    },
    {
        "engDate": "2028-09-07",
        "nepDate": "22.05.2085"
    },
    {
        "engDate": "2028-09-08",
        "nepDate": "23.05.2085"
    },
    {
        "engDate": "2028-09-09",
        "nepDate": "24.05.2085"
    },
    {
        "engDate": "2028-09-10",
        "nepDate": "25.05.2085"
    },
    {
        "engDate": "2028-09-11",
        "nepDate": "26.05.2085"
    },
    {
        "engDate": "2028-09-12",
        "nepDate": "27.05.2085"
    },
    {
        "engDate": "2028-09-13",
        "nepDate": "28.05.2085"
    },
    {
        "engDate": "2028-09-14",
        "nepDate": "29.05.2085"
    },
    {
        "engDate": "2028-09-15",
        "nepDate": "30.05.2085"
    },
    {
        "engDate": "2028-09-16",
        "nepDate": "01.06.2085"
    },
    {
        "engDate": "2028-09-17",
        "nepDate": "02.06.2085"
    },
    {
        "engDate": "2028-09-18",
        "nepDate": "03.06.2085"
    },
    {
        "engDate": "2028-09-19",
        "nepDate": "04.06.2085"
    },
    {
        "engDate": "2028-09-20",
        "nepDate": "05.06.2085"
    },
    {
        "engDate": "2028-09-21",
        "nepDate": "06.06.2085"
    },
    {
        "engDate": "2028-09-22",
        "nepDate": "07.06.2085"
    },
    {
        "engDate": "2028-09-23",
        "nepDate": "08.06.2085"
    },
    {
        "engDate": "2028-09-24",
        "nepDate": "09.06.2085"
    },
    {
        "engDate": "2028-09-25",
        "nepDate": "10.06.2085"
    },
    {
        "engDate": "2028-09-26",
        "nepDate": "11.06.2085"
    },
    {
        "engDate": "2028-09-27",
        "nepDate": "12.06.2085"
    },
    {
        "engDate": "2028-09-28",
        "nepDate": "13.06.2085"
    },
    {
        "engDate": "2028-09-29",
        "nepDate": "14.06.2085"
    },
    {
        "engDate": "2028-09-30",
        "nepDate": "15.06.2085"
    },
    {
        "engDate": "2028-10-01",
        "nepDate": "16.06.2085"
    },
    {
        "engDate": "2028-10-02",
        "nepDate": "17.06.2085"
    },
    {
        "engDate": "2028-10-03",
        "nepDate": "18.06.2085"
    },
    {
        "engDate": "2028-10-04",
        "nepDate": "19.06.2085"
    },
    {
        "engDate": "2028-10-05",
        "nepDate": "20.06.2085"
    },
    {
        "engDate": "2028-10-06",
        "nepDate": "21.06.2085"
    },
    {
        "engDate": "2028-10-07",
        "nepDate": "22.06.2085"
    },
    {
        "engDate": "2028-10-08",
        "nepDate": "23.06.2085"
    },
    {
        "engDate": "2028-10-09",
        "nepDate": "24.06.2085"
    },
    {
        "engDate": "2028-10-10",
        "nepDate": "25.06.2085"
    },
    {
        "engDate": "2028-10-11",
        "nepDate": "26.06.2085"
    },
    {
        "engDate": "2028-10-12",
        "nepDate": "27.06.2085"
    },
    {
        "engDate": "2028-10-13",
        "nepDate": "28.06.2085"
    },
    {
        "engDate": "2028-10-14",
        "nepDate": "29.06.2085"
    },
    {
        "engDate": "2028-10-15",
        "nepDate": "30.06.2085"
    },
    {
        "engDate": "2028-10-16",
        "nepDate": "31.06.2085"
    },
    {
        "engDate": "2028-10-17",
        "nepDate": "01.07.2085"
    },
    {
        "engDate": "2028-10-18",
        "nepDate": "02.07.2085"
    },
    {
        "engDate": "2028-10-19",
        "nepDate": "03.07.2085"
    },
    {
        "engDate": "2028-10-20",
        "nepDate": "04.07.2085"
    },
    {
        "engDate": "2028-10-21",
        "nepDate": "05.07.2085"
    },
    {
        "engDate": "2028-10-22",
        "nepDate": "06.07.2085"
    },
    {
        "engDate": "2028-10-23",
        "nepDate": "07.07.2085"
    },
    {
        "engDate": "2028-10-24",
        "nepDate": "08.07.2085"
    },
    {
        "engDate": "2028-10-25",
        "nepDate": "09.07.2085"
    },
    {
        "engDate": "2028-10-26",
        "nepDate": "10.07.2085"
    },
    {
        "engDate": "2028-10-27",
        "nepDate": "11.07.2085"
    },
    {
        "engDate": "2028-10-28",
        "nepDate": "12.07.2085"
    },
    {
        "engDate": "2028-10-29",
        "nepDate": "13.07.2085"
    },
    {
        "engDate": "2028-10-30",
        "nepDate": "14.07.2085"
    },
    {
        "engDate": "2028-10-31",
        "nepDate": "15.07.2085"
    },
    {
        "engDate": "2028-11-01",
        "nepDate": "16.07.2085"
    },
    {
        "engDate": "2028-11-02",
        "nepDate": "17.07.2085"
    },
    {
        "engDate": "2028-11-03",
        "nepDate": "18.07.2085"
    },
    {
        "engDate": "2028-11-04",
        "nepDate": "19.07.2085"
    },
    {
        "engDate": "2028-11-05",
        "nepDate": "20.07.2085"
    },
    {
        "engDate": "2028-11-06",
        "nepDate": "21.07.2085"
    },
    {
        "engDate": "2028-11-07",
        "nepDate": "22.07.2085"
    },
    {
        "engDate": "2028-11-08",
        "nepDate": "23.07.2085"
    },
    {
        "engDate": "2028-11-09",
        "nepDate": "24.07.2085"
    },
    {
        "engDate": "2028-11-10",
        "nepDate": "25.07.2085"
    },
    {
        "engDate": "2028-11-11",
        "nepDate": "26.07.2085"
    },
    {
        "engDate": "2028-11-12",
        "nepDate": "27.07.2085"
    },
    {
        "engDate": "2028-11-13",
        "nepDate": "28.07.2085"
    },
    {
        "engDate": "2028-11-14",
        "nepDate": "29.07.2085"
    },
    {
        "engDate": "2028-11-15",
        "nepDate": "30.07.2085"
    },
    {
        "engDate": "2028-11-16",
        "nepDate": "01.08.2085"
    },
    {
        "engDate": "2028-11-17",
        "nepDate": "02.08.2085"
    },
    {
        "engDate": "2028-11-18",
        "nepDate": "03.08.2085"
    },
    {
        "engDate": "2028-11-19",
        "nepDate": "04.08.2085"
    },
    {
        "engDate": "2028-11-20",
        "nepDate": "05.08.2085"
    },
    {
        "engDate": "2028-11-21",
        "nepDate": "06.08.2085"
    },
    {
        "engDate": "2028-11-22",
        "nepDate": "07.08.2085"
    },
    {
        "engDate": "2028-11-23",
        "nepDate": "08.08.2085"
    },
    {
        "engDate": "2028-11-24",
        "nepDate": "09.08.2085"
    },
    {
        "engDate": "2028-11-25",
        "nepDate": "10.08.2085"
    },
    {
        "engDate": "2028-11-26",
        "nepDate": "11.08.2085"
    },
    {
        "engDate": "2028-11-27",
        "nepDate": "12.08.2085"
    },
    {
        "engDate": "2028-11-28",
        "nepDate": "13.08.2085"
    },
    {
        "engDate": "2028-11-29",
        "nepDate": "14.08.2085"
    },
    {
        "engDate": "2028-11-30",
        "nepDate": "15.08.2085"
    },
    {
        "engDate": "2028-12-01",
        "nepDate": "16.08.2085"
    },
    {
        "engDate": "2028-12-02",
        "nepDate": "17.08.2085"
    },
    {
        "engDate": "2028-12-03",
        "nepDate": "18.08.2085"
    },
    {
        "engDate": "2028-12-04",
        "nepDate": "19.08.2085"
    },
    {
        "engDate": "2028-12-05",
        "nepDate": "20.08.2085"
    },
    {
        "engDate": "2028-12-06",
        "nepDate": "21.08.2085"
    },
    {
        "engDate": "2028-12-07",
        "nepDate": "22.08.2085"
    },
    {
        "engDate": "2028-12-08",
        "nepDate": "23.08.2085"
    },
    {
        "engDate": "2028-12-09",
        "nepDate": "24.08.2085"
    },
    {
        "engDate": "2028-12-10",
        "nepDate": "25.08.2085"
    },
    {
        "engDate": "2028-12-11",
        "nepDate": "26.08.2085"
    },
    {
        "engDate": "2028-12-12",
        "nepDate": "27.08.2085"
    },
    {
        "engDate": "2028-12-13",
        "nepDate": "28.08.2085"
    },
    {
        "engDate": "2028-12-14",
        "nepDate": "29.08.2085"
    },
    {
        "engDate": "2028-12-15",
        "nepDate": "30.08.2085"
    },
    {
        "engDate": "2028-12-16",
        "nepDate": "01.09.2085"
    },
    {
        "engDate": "2028-12-17",
        "nepDate": "02.09.2085"
    },
    {
        "engDate": "2028-12-18",
        "nepDate": "03.09.2085"
    },
    {
        "engDate": "2028-12-19",
        "nepDate": "04.09.2085"
    },
    {
        "engDate": "2028-12-20",
        "nepDate": "05.09.2085"
    },
    {
        "engDate": "2028-12-21",
        "nepDate": "06.09.2085"
    },
    {
        "engDate": "2028-12-22",
        "nepDate": "07.09.2085"
    },
    {
        "engDate": "2028-12-23",
        "nepDate": "08.09.2085"
    },
    {
        "engDate": "2028-12-24",
        "nepDate": "09.09.2085"
    },
    {
        "engDate": "2028-12-25",
        "nepDate": "10.09.2085"
    },
    {
        "engDate": "2028-12-26",
        "nepDate": "11.09.2085"
    },
    {
        "engDate": "2028-12-27",
        "nepDate": "12.09.2085"
    },
    {
        "engDate": "2028-12-28",
        "nepDate": "13.09.2085"
    },
    {
        "engDate": "2028-12-29",
        "nepDate": "14.09.2085"
    },
    {
        "engDate": "2028-12-30",
        "nepDate": "15.09.2085"
    },
    {
        "engDate": "2028-12-31",
        "nepDate": "16.09.2085"
    },
    {
        "engDate": "2029-01-01",
        "nepDate": "17.09.2085"
    },
    {
        "engDate": "2029-01-02",
        "nepDate": "18.09.2085"
    },
    {
        "engDate": "2029-01-03",
        "nepDate": "19.09.2085"
    },
    {
        "engDate": "2029-01-04",
        "nepDate": "20.09.2085"
    },
    {
        "engDate": "2029-01-05",
        "nepDate": "21.09.2085"
    },
    {
        "engDate": "2029-01-06",
        "nepDate": "22.09.2085"
    },
    {
        "engDate": "2029-01-07",
        "nepDate": "23.09.2085"
    },
    {
        "engDate": "2029-01-08",
        "nepDate": "24.09.2085"
    },
    {
        "engDate": "2029-01-09",
        "nepDate": "25.09.2085"
    },
    {
        "engDate": "2029-01-10",
        "nepDate": "26.09.2085"
    },
    {
        "engDate": "2029-01-11",
        "nepDate": "27.09.2085"
    },
    {
        "engDate": "2029-01-12",
        "nepDate": "28.09.2085"
    },
    {
        "engDate": "2029-01-13",
        "nepDate": "29.09.2085"
    },
    {
        "engDate": "2029-01-14",
        "nepDate": "01.10.2085"
    },
    {
        "engDate": "2029-01-15",
        "nepDate": "02.10.2085"
    },
    {
        "engDate": "2029-01-16",
        "nepDate": "03.10.2085"
    },
    {
        "engDate": "2029-01-17",
        "nepDate": "04.10.2085"
    },
    {
        "engDate": "2029-01-18",
        "nepDate": "05.10.2085"
    },
    {
        "engDate": "2029-01-19",
        "nepDate": "06.10.2085"
    },
    {
        "engDate": "2029-01-20",
        "nepDate": "07.10.2085"
    },
    {
        "engDate": "2029-01-21",
        "nepDate": "08.10.2085"
    },
    {
        "engDate": "2029-01-22",
        "nepDate": "09.10.2085"
    },
    {
        "engDate": "2029-01-23",
        "nepDate": "10.10.2085"
    },
    {
        "engDate": "2029-01-24",
        "nepDate": "11.10.2085"
    },
    {
        "engDate": "2029-01-25",
        "nepDate": "12.10.2085"
    },
    {
        "engDate": "2029-01-26",
        "nepDate": "13.10.2085"
    },
    {
        "engDate": "2029-01-27",
        "nepDate": "14.10.2085"
    },
    {
        "engDate": "2029-01-28",
        "nepDate": "15.10.2085"
    },
    {
        "engDate": "2029-01-29",
        "nepDate": "16.10.2085"
    },
    {
        "engDate": "2029-01-30",
        "nepDate": "17.10.2085"
    },
    {
        "engDate": "2029-01-31",
        "nepDate": "18.10.2085"
    },
    {
        "engDate": "2029-02-01",
        "nepDate": "19.10.2085"
    },
    {
        "engDate": "2029-02-02",
        "nepDate": "20.10.2085"
    },
    {
        "engDate": "2029-02-03",
        "nepDate": "21.10.2085"
    },
    {
        "engDate": "2029-02-04",
        "nepDate": "22.10.2085"
    },
    {
        "engDate": "2029-02-05",
        "nepDate": "23.10.2085"
    },
    {
        "engDate": "2029-02-06",
        "nepDate": "24.10.2085"
    },
    {
        "engDate": "2029-02-07",
        "nepDate": "25.10.2085"
    },
    {
        "engDate": "2029-02-08",
        "nepDate": "26.10.2085"
    },
    {
        "engDate": "2029-02-09",
        "nepDate": "27.10.2085"
    },
    {
        "engDate": "2029-02-10",
        "nepDate": "28.10.2085"
    },
    {
        "engDate": "2029-02-11",
        "nepDate": "29.10.2085"
    },
    {
        "engDate": "2029-02-12",
        "nepDate": "30.10.2085"
    },
    {
        "engDate": "2029-02-13",
        "nepDate": "01.11.2085"
    },
    {
        "engDate": "2029-02-14",
        "nepDate": "02.11.2085"
    },
    {
        "engDate": "2029-02-15",
        "nepDate": "03.11.2085"
    },
    {
        "engDate": "2029-02-16",
        "nepDate": "04.11.2085"
    },
    {
        "engDate": "2029-02-17",
        "nepDate": "05.11.2085"
    },
    {
        "engDate": "2029-02-18",
        "nepDate": "06.11.2085"
    },
    {
        "engDate": "2029-02-19",
        "nepDate": "07.11.2085"
    },
    {
        "engDate": "2029-02-20",
        "nepDate": "08.11.2085"
    },
    {
        "engDate": "2029-02-21",
        "nepDate": "09.11.2085"
    },
    {
        "engDate": "2029-02-22",
        "nepDate": "10.11.2085"
    },
    {
        "engDate": "2029-02-23",
        "nepDate": "11.11.2085"
    },
    {
        "engDate": "2029-02-24",
        "nepDate": "12.11.2085"
    },
    {
        "engDate": "2029-02-25",
        "nepDate": "13.11.2085"
    },
    {
        "engDate": "2029-02-26",
        "nepDate": "14.11.2085"
    },
    {
        "engDate": "2029-02-27",
        "nepDate": "15.11.2085"
    },
    {
        "engDate": "2029-02-28",
        "nepDate": "16.11.2085"
    },
    {
        "engDate": "2029-03-01",
        "nepDate": "17.11.2085"
    },
    {
        "engDate": "2029-03-02",
        "nepDate": "18.11.2085"
    },
    {
        "engDate": "2029-03-03",
        "nepDate": "19.11.2085"
    },
    {
        "engDate": "2029-03-04",
        "nepDate": "20.11.2085"
    },
    {
        "engDate": "2029-03-05",
        "nepDate": "21.11.2085"
    },
    {
        "engDate": "2029-03-06",
        "nepDate": "22.11.2085"
    },
    {
        "engDate": "2029-03-07",
        "nepDate": "23.11.2085"
    },
    {
        "engDate": "2029-03-08",
        "nepDate": "24.11.2085"
    },
    {
        "engDate": "2029-03-09",
        "nepDate": "25.11.2085"
    },
    {
        "engDate": "2029-03-10",
        "nepDate": "26.11.2085"
    },
    {
        "engDate": "2029-03-11",
        "nepDate": "27.11.2085"
    },
    {
        "engDate": "2029-03-12",
        "nepDate": "28.11.2085"
    },
    {
        "engDate": "2029-03-13",
        "nepDate": "29.11.2085"
    },
    {
        "engDate": "2029-03-14",
        "nepDate": "30.11.2085"
    },
    {
        "engDate": "2029-03-15",
        "nepDate": "01.12.2085"
    },
    {
        "engDate": "2029-03-16",
        "nepDate": "02.12.2085"
    },
    {
        "engDate": "2029-03-17",
        "nepDate": "03.12.2085"
    },
    {
        "engDate": "2029-03-18",
        "nepDate": "04.12.2085"
    },
    {
        "engDate": "2029-03-19",
        "nepDate": "05.12.2085"
    },
    {
        "engDate": "2029-03-20",
        "nepDate": "06.12.2085"
    },
    {
        "engDate": "2029-03-21",
        "nepDate": "07.12.2085"
    },
    {
        "engDate": "2029-03-22",
        "nepDate": "08.12.2085"
    },
    {
        "engDate": "2029-03-23",
        "nepDate": "09.12.2085"
    },
    {
        "engDate": "2029-03-24",
        "nepDate": "10.12.2085"
    },
    {
        "engDate": "2029-03-25",
        "nepDate": "11.12.2085"
    },
    {
        "engDate": "2029-03-26",
        "nepDate": "12.12.2085"
    },
    {
        "engDate": "2029-03-27",
        "nepDate": "13.12.2085"
    },
    {
        "engDate": "2029-03-28",
        "nepDate": "14.12.2085"
    },
    {
        "engDate": "2029-03-29",
        "nepDate": "15.12.2085"
    },
    {
        "engDate": "2029-03-30",
        "nepDate": "16.12.2085"
    },
    {
        "engDate": "2029-03-31",
        "nepDate": "17.12.2085"
    },
    {
        "engDate": "2029-04-01",
        "nepDate": "18.12.2085"
    },
    {
        "engDate": "2029-04-02",
        "nepDate": "19.12.2085"
    },
    {
        "engDate": "2029-04-03",
        "nepDate": "20.12.2085"
    },
    {
        "engDate": "2029-04-04",
        "nepDate": "21.12.2085"
    },
    {
        "engDate": "2029-04-05",
        "nepDate": "22.12.2085"
    },
    {
        "engDate": "2029-04-06",
        "nepDate": "23.12.2085"
    },
    {
        "engDate": "2029-04-07",
        "nepDate": "24.12.2085"
    },
    {
        "engDate": "2029-04-08",
        "nepDate": "25.12.2085"
    },
    {
        "engDate": "2029-04-09",
        "nepDate": "26.12.2085"
    },
    {
        "engDate": "2029-04-10",
        "nepDate": "27.12.2085"
    },
    {
        "engDate": "2029-04-11",
        "nepDate": "28.12.2085"
    },
    {
        "engDate": "2029-04-12",
        "nepDate": "29.12.2085"
    },
    {
        "engDate": "2029-04-13",
        "nepDate": "30.12.2085"
    },
    {
        "engDate": "2029-04-14",
        "nepDate": "01.01.2086"
    },
    {
        "engDate": "2029-04-15",
        "nepDate": "02.01.2086"
    },
    {
        "engDate": "2029-04-16",
        "nepDate": "03.01.2086"
    },
    {
        "engDate": "2029-04-17",
        "nepDate": "04.01.2086"
    },
    {
        "engDate": "2029-04-18",
        "nepDate": "05.01.2086"
    },
    {
        "engDate": "2029-04-19",
        "nepDate": "06.01.2086"
    },
    {
        "engDate": "2029-04-20",
        "nepDate": "07.01.2086"
    },
    {
        "engDate": "2029-04-21",
        "nepDate": "08.01.2086"
    },
    {
        "engDate": "2029-04-22",
        "nepDate": "09.01.2086"
    },
    {
        "engDate": "2029-04-23",
        "nepDate": "10.01.2086"
    },
    {
        "engDate": "2029-04-24",
        "nepDate": "11.01.2086"
    },
    {
        "engDate": "2029-04-25",
        "nepDate": "12.01.2086"
    },
    {
        "engDate": "2029-04-26",
        "nepDate": "13.01.2086"
    },
    {
        "engDate": "2029-04-27",
        "nepDate": "14.01.2086"
    },
    {
        "engDate": "2029-04-28",
        "nepDate": "15.01.2086"
    },
    {
        "engDate": "2029-04-29",
        "nepDate": "16.01.2086"
    },
    {
        "engDate": "2029-04-30",
        "nepDate": "17.01.2086"
    },
    {
        "engDate": "2029-05-01",
        "nepDate": "18.01.2086"
    },
    {
        "engDate": "2029-05-02",
        "nepDate": "19.01.2086"
    },
    {
        "engDate": "2029-05-03",
        "nepDate": "20.01.2086"
    },
    {
        "engDate": "2029-05-04",
        "nepDate": "21.01.2086"
    },
    {
        "engDate": "2029-05-05",
        "nepDate": "22.01.2086"
    },
    {
        "engDate": "2029-05-06",
        "nepDate": "23.01.2086"
    },
    {
        "engDate": "2029-05-07",
        "nepDate": "24.01.2086"
    },
    {
        "engDate": "2029-05-08",
        "nepDate": "25.01.2086"
    },
    {
        "engDate": "2029-05-09",
        "nepDate": "26.01.2086"
    },
    {
        "engDate": "2029-05-10",
        "nepDate": "27.01.2086"
    },
    {
        "engDate": "2029-05-11",
        "nepDate": "28.01.2086"
    },
    {
        "engDate": "2029-05-12",
        "nepDate": "29.01.2086"
    },
    {
        "engDate": "2029-05-13",
        "nepDate": "30.01.2086"
    },
    {
        "engDate": "2029-05-14",
        "nepDate": "01.02.2086"
    },
    {
        "engDate": "2029-05-15",
        "nepDate": "02.02.2086"
    },
    {
        "engDate": "2029-05-16",
        "nepDate": "03.02.2086"
    },
    {
        "engDate": "2029-05-17",
        "nepDate": "04.02.2086"
    },
    {
        "engDate": "2029-05-18",
        "nepDate": "05.02.2086"
    },
    {
        "engDate": "2029-05-19",
        "nepDate": "06.02.2086"
    },
    {
        "engDate": "2029-05-20",
        "nepDate": "07.02.2086"
    },
    {
        "engDate": "2029-05-21",
        "nepDate": "08.02.2086"
    },
    {
        "engDate": "2029-05-22",
        "nepDate": "09.02.2086"
    },
    {
        "engDate": "2029-05-23",
        "nepDate": "10.02.2086"
    },
    {
        "engDate": "2029-05-24",
        "nepDate": "11.02.2086"
    },
    {
        "engDate": "2029-05-25",
        "nepDate": "12.02.2086"
    },
    {
        "engDate": "2029-05-26",
        "nepDate": "13.02.2086"
    },
    {
        "engDate": "2029-05-27",
        "nepDate": "14.02.2086"
    },
    {
        "engDate": "2029-05-28",
        "nepDate": "15.02.2086"
    },
    {
        "engDate": "2029-05-29",
        "nepDate": "16.02.2086"
    },
    {
        "engDate": "2029-05-30",
        "nepDate": "17.02.2086"
    },
    {
        "engDate": "2029-05-31",
        "nepDate": "18.02.2086"
    },
    {
        "engDate": "2029-06-01",
        "nepDate": "19.02.2086"
    },
    {
        "engDate": "2029-06-02",
        "nepDate": "20.02.2086"
    },
    {
        "engDate": "2029-06-03",
        "nepDate": "21.02.2086"
    },
    {
        "engDate": "2029-06-04",
        "nepDate": "22.02.2086"
    },
    {
        "engDate": "2029-06-05",
        "nepDate": "23.02.2086"
    },
    {
        "engDate": "2029-06-06",
        "nepDate": "24.02.2086"
    },
    {
        "engDate": "2029-06-07",
        "nepDate": "25.02.2086"
    },
    {
        "engDate": "2029-06-08",
        "nepDate": "26.02.2086"
    },
    {
        "engDate": "2029-06-09",
        "nepDate": "27.02.2086"
    },
    {
        "engDate": "2029-06-10",
        "nepDate": "28.02.2086"
    },
    {
        "engDate": "2029-06-11",
        "nepDate": "29.02.2086"
    },
    {
        "engDate": "2029-06-12",
        "nepDate": "30.02.2086"
    },
    {
        "engDate": "2029-06-13",
        "nepDate": "31.02.2086"
    },
    {
        "engDate": "2029-06-14",
        "nepDate": "32.02.2086"
    },
    {
        "engDate": "2029-06-15",
        "nepDate": "01.03.2086"
    },
    {
        "engDate": "2029-06-16",
        "nepDate": "02.03.2086"
    },
    {
        "engDate": "2029-06-17",
        "nepDate": "03.03.2086"
    },
    {
        "engDate": "2029-06-18",
        "nepDate": "04.03.2086"
    },
    {
        "engDate": "2029-06-19",
        "nepDate": "05.03.2086"
    },
    {
        "engDate": "2029-06-20",
        "nepDate": "06.03.2086"
    },
    {
        "engDate": "2029-06-21",
        "nepDate": "07.03.2086"
    },
    {
        "engDate": "2029-06-22",
        "nepDate": "08.03.2086"
    },
    {
        "engDate": "2029-06-23",
        "nepDate": "09.03.2086"
    },
    {
        "engDate": "2029-06-24",
        "nepDate": "10.03.2086"
    },
    {
        "engDate": "2029-06-25",
        "nepDate": "11.03.2086"
    },
    {
        "engDate": "2029-06-26",
        "nepDate": "12.03.2086"
    },
    {
        "engDate": "2029-06-27",
        "nepDate": "13.03.2086"
    },
    {
        "engDate": "2029-06-28",
        "nepDate": "14.03.2086"
    },
    {
        "engDate": "2029-06-29",
        "nepDate": "15.03.2086"
    },
    {
        "engDate": "2029-06-30",
        "nepDate": "16.03.2086"
    },
    {
        "engDate": "2029-07-01",
        "nepDate": "17.03.2086"
    },
    {
        "engDate": "2029-07-02",
        "nepDate": "18.03.2086"
    },
    {
        "engDate": "2029-07-03",
        "nepDate": "19.03.2086"
    },
    {
        "engDate": "2029-07-04",
        "nepDate": "20.03.2086"
    },
    {
        "engDate": "2029-07-05",
        "nepDate": "21.03.2086"
    },
    {
        "engDate": "2029-07-06",
        "nepDate": "22.03.2086"
    },
    {
        "engDate": "2029-07-07",
        "nepDate": "23.03.2086"
    },
    {
        "engDate": "2029-07-08",
        "nepDate": "24.03.2086"
    },
    {
        "engDate": "2029-07-09",
        "nepDate": "25.03.2086"
    },
    {
        "engDate": "2029-07-10",
        "nepDate": "26.03.2086"
    },
    {
        "engDate": "2029-07-11",
        "nepDate": "27.03.2086"
    },
    {
        "engDate": "2029-07-12",
        "nepDate": "28.03.2086"
    },
    {
        "engDate": "2029-07-13",
        "nepDate": "29.03.2086"
    },
    {
        "engDate": "2029-07-14",
        "nepDate": "30.03.2086"
    },
    {
        "engDate": "2029-07-15",
        "nepDate": "31.03.2086"
    },
    {
        "engDate": "2029-07-16",
        "nepDate": "01.04.2086"
    },
    {
        "engDate": "2029-07-17",
        "nepDate": "02.04.2086"
    },
    {
        "engDate": "2029-07-18",
        "nepDate": "03.04.2086"
    },
    {
        "engDate": "2029-07-19",
        "nepDate": "04.04.2086"
    },
    {
        "engDate": "2029-07-20",
        "nepDate": "05.04.2086"
    },
    {
        "engDate": "2029-07-21",
        "nepDate": "06.04.2086"
    },
    {
        "engDate": "2029-07-22",
        "nepDate": "07.04.2086"
    },
    {
        "engDate": "2029-07-23",
        "nepDate": "08.04.2086"
    },
    {
        "engDate": "2029-07-24",
        "nepDate": "09.04.2086"
    },
    {
        "engDate": "2029-07-25",
        "nepDate": "10.04.2086"
    },
    {
        "engDate": "2029-07-26",
        "nepDate": "11.04.2086"
    },
    {
        "engDate": "2029-07-27",
        "nepDate": "12.04.2086"
    },
    {
        "engDate": "2029-07-28",
        "nepDate": "13.04.2086"
    },
    {
        "engDate": "2029-07-29",
        "nepDate": "14.04.2086"
    },
    {
        "engDate": "2029-07-30",
        "nepDate": "15.04.2086"
    },
    {
        "engDate": "2029-07-31",
        "nepDate": "16.04.2086"
    },
    {
        "engDate": "2029-08-01",
        "nepDate": "17.04.2086"
    },
    {
        "engDate": "2029-08-02",
        "nepDate": "18.04.2086"
    },
    {
        "engDate": "2029-08-03",
        "nepDate": "19.04.2086"
    },
    {
        "engDate": "2029-08-04",
        "nepDate": "20.04.2086"
    },
    {
        "engDate": "2029-08-05",
        "nepDate": "21.04.2086"
    },
    {
        "engDate": "2029-08-06",
        "nepDate": "22.04.2086"
    },
    {
        "engDate": "2029-08-07",
        "nepDate": "23.04.2086"
    },
    {
        "engDate": "2029-08-08",
        "nepDate": "24.04.2086"
    },
    {
        "engDate": "2029-08-09",
        "nepDate": "25.04.2086"
    },
    {
        "engDate": "2029-08-10",
        "nepDate": "26.04.2086"
    },
    {
        "engDate": "2029-08-11",
        "nepDate": "27.04.2086"
    },
    {
        "engDate": "2029-08-12",
        "nepDate": "28.04.2086"
    },
    {
        "engDate": "2029-08-13",
        "nepDate": "29.04.2086"
    },
    {
        "engDate": "2029-08-14",
        "nepDate": "30.04.2086"
    },
    {
        "engDate": "2029-08-15",
        "nepDate": "31.04.2086"
    },
    {
        "engDate": "2029-08-16",
        "nepDate": "32.04.2086"
    },
    {
        "engDate": "2029-08-17",
        "nepDate": "01.05.2086"
    },
    {
        "engDate": "2029-08-18",
        "nepDate": "02.05.2086"
    },
    {
        "engDate": "2029-08-19",
        "nepDate": "03.05.2086"
    },
    {
        "engDate": "2029-08-20",
        "nepDate": "04.05.2086"
    },
    {
        "engDate": "2029-08-21",
        "nepDate": "05.05.2086"
    },
    {
        "engDate": "2029-08-22",
        "nepDate": "06.05.2086"
    },
    {
        "engDate": "2029-08-23",
        "nepDate": "07.05.2086"
    },
    {
        "engDate": "2029-08-24",
        "nepDate": "08.05.2086"
    },
    {
        "engDate": "2029-08-25",
        "nepDate": "09.05.2086"
    },
    {
        "engDate": "2029-08-26",
        "nepDate": "10.05.2086"
    },
    {
        "engDate": "2029-08-27",
        "nepDate": "11.05.2086"
    },
    {
        "engDate": "2029-08-28",
        "nepDate": "12.05.2086"
    },
    {
        "engDate": "2029-08-29",
        "nepDate": "13.05.2086"
    },
    {
        "engDate": "2029-08-30",
        "nepDate": "14.05.2086"
    },
    {
        "engDate": "2029-08-31",
        "nepDate": "15.05.2086"
    },
    {
        "engDate": "2029-09-01",
        "nepDate": "16.05.2086"
    },
    {
        "engDate": "2029-09-02",
        "nepDate": "17.05.2086"
    },
    {
        "engDate": "2029-09-03",
        "nepDate": "18.05.2086"
    },
    {
        "engDate": "2029-09-04",
        "nepDate": "19.05.2086"
    },
    {
        "engDate": "2029-09-05",
        "nepDate": "20.05.2086"
    },
    {
        "engDate": "2029-09-06",
        "nepDate": "21.05.2086"
    },
    {
        "engDate": "2029-09-07",
        "nepDate": "22.05.2086"
    },
    {
        "engDate": "2029-09-08",
        "nepDate": "23.05.2086"
    },
    {
        "engDate": "2029-09-09",
        "nepDate": "24.05.2086"
    },
    {
        "engDate": "2029-09-10",
        "nepDate": "25.05.2086"
    },
    {
        "engDate": "2029-09-11",
        "nepDate": "26.05.2086"
    },
    {
        "engDate": "2029-09-12",
        "nepDate": "27.05.2086"
    },
    {
        "engDate": "2029-09-13",
        "nepDate": "28.05.2086"
    },
    {
        "engDate": "2029-09-14",
        "nepDate": "29.05.2086"
    },
    {
        "engDate": "2029-09-15",
        "nepDate": "30.05.2086"
    },
    {
        "engDate": "2029-09-16",
        "nepDate": "31.05.2086"
    },
    {
        "engDate": "2029-09-17",
        "nepDate": "01.06.2086"
    },
    {
        "engDate": "2029-09-18",
        "nepDate": "02.06.2086"
    },
    {
        "engDate": "2029-09-19",
        "nepDate": "03.06.2086"
    },
    {
        "engDate": "2029-09-20",
        "nepDate": "04.06.2086"
    },
    {
        "engDate": "2029-09-21",
        "nepDate": "05.06.2086"
    },
    {
        "engDate": "2029-09-22",
        "nepDate": "06.06.2086"
    },
    {
        "engDate": "2029-09-23",
        "nepDate": "07.06.2086"
    },
    {
        "engDate": "2029-09-24",
        "nepDate": "08.06.2086"
    },
    {
        "engDate": "2029-09-25",
        "nepDate": "09.06.2086"
    },
    {
        "engDate": "2029-09-26",
        "nepDate": "10.06.2086"
    },
    {
        "engDate": "2029-09-27",
        "nepDate": "11.06.2086"
    },
    {
        "engDate": "2029-09-28",
        "nepDate": "12.06.2086"
    },
    {
        "engDate": "2029-09-29",
        "nepDate": "13.06.2086"
    },
    {
        "engDate": "2029-09-30",
        "nepDate": "14.06.2086"
    },
    {
        "engDate": "2029-10-01",
        "nepDate": "15.06.2086"
    },
    {
        "engDate": "2029-10-02",
        "nepDate": "16.06.2086"
    },
    {
        "engDate": "2029-10-03",
        "nepDate": "17.06.2086"
    },
    {
        "engDate": "2029-10-04",
        "nepDate": "18.06.2086"
    },
    {
        "engDate": "2029-10-05",
        "nepDate": "19.06.2086"
    },
    {
        "engDate": "2029-10-06",
        "nepDate": "20.06.2086"
    },
    {
        "engDate": "2029-10-07",
        "nepDate": "21.06.2086"
    },
    {
        "engDate": "2029-10-08",
        "nepDate": "22.06.2086"
    },
    {
        "engDate": "2029-10-09",
        "nepDate": "23.06.2086"
    },
    {
        "engDate": "2029-10-10",
        "nepDate": "24.06.2086"
    },
    {
        "engDate": "2029-10-11",
        "nepDate": "25.06.2086"
    },
    {
        "engDate": "2029-10-12",
        "nepDate": "26.06.2086"
    },
    {
        "engDate": "2029-10-13",
        "nepDate": "27.06.2086"
    },
    {
        "engDate": "2029-10-14",
        "nepDate": "28.06.2086"
    },
    {
        "engDate": "2029-10-15",
        "nepDate": "29.06.2086"
    },
    {
        "engDate": "2029-10-16",
        "nepDate": "30.06.2086"
    },
    {
        "engDate": "2029-10-17",
        "nepDate": "01.07.2086"
    },
    {
        "engDate": "2029-10-18",
        "nepDate": "02.07.2086"
    },
    {
        "engDate": "2029-10-19",
        "nepDate": "03.07.2086"
    },
    {
        "engDate": "2029-10-20",
        "nepDate": "04.07.2086"
    },
    {
        "engDate": "2029-10-21",
        "nepDate": "05.07.2086"
    },
    {
        "engDate": "2029-10-22",
        "nepDate": "06.07.2086"
    },
    {
        "engDate": "2029-10-23",
        "nepDate": "07.07.2086"
    },
    {
        "engDate": "2029-10-24",
        "nepDate": "08.07.2086"
    },
    {
        "engDate": "2029-10-25",
        "nepDate": "09.07.2086"
    },
    {
        "engDate": "2029-10-26",
        "nepDate": "10.07.2086"
    },
    {
        "engDate": "2029-10-27",
        "nepDate": "11.07.2086"
    },
    {
        "engDate": "2029-10-28",
        "nepDate": "12.07.2086"
    },
    {
        "engDate": "2029-10-29",
        "nepDate": "13.07.2086"
    },
    {
        "engDate": "2029-10-30",
        "nepDate": "14.07.2086"
    },
    {
        "engDate": "2029-10-31",
        "nepDate": "15.07.2086"
    },
    {
        "engDate": "2029-11-01",
        "nepDate": "16.07.2086"
    },
    {
        "engDate": "2029-11-02",
        "nepDate": "17.07.2086"
    },
    {
        "engDate": "2029-11-03",
        "nepDate": "18.07.2086"
    },
    {
        "engDate": "2029-11-04",
        "nepDate": "19.07.2086"
    },
    {
        "engDate": "2029-11-05",
        "nepDate": "20.07.2086"
    },
    {
        "engDate": "2029-11-06",
        "nepDate": "21.07.2086"
    },
    {
        "engDate": "2029-11-07",
        "nepDate": "22.07.2086"
    },
    {
        "engDate": "2029-11-08",
        "nepDate": "23.07.2086"
    },
    {
        "engDate": "2029-11-09",
        "nepDate": "24.07.2086"
    },
    {
        "engDate": "2029-11-10",
        "nepDate": "25.07.2086"
    },
    {
        "engDate": "2029-11-11",
        "nepDate": "26.07.2086"
    },
    {
        "engDate": "2029-11-12",
        "nepDate": "27.07.2086"
    },
    {
        "engDate": "2029-11-13",
        "nepDate": "28.07.2086"
    },
    {
        "engDate": "2029-11-14",
        "nepDate": "29.07.2086"
    },
    {
        "engDate": "2029-11-15",
        "nepDate": "30.07.2086"
    },
    {
        "engDate": "2029-11-16",
        "nepDate": "01.08.2086"
    },
    {
        "engDate": "2029-11-17",
        "nepDate": "02.08.2086"
    },
    {
        "engDate": "2029-11-18",
        "nepDate": "03.08.2086"
    },
    {
        "engDate": "2029-11-19",
        "nepDate": "04.08.2086"
    },
    {
        "engDate": "2029-11-20",
        "nepDate": "05.08.2086"
    },
    {
        "engDate": "2029-11-21",
        "nepDate": "06.08.2086"
    },
    {
        "engDate": "2029-11-22",
        "nepDate": "07.08.2086"
    },
    {
        "engDate": "2029-11-23",
        "nepDate": "08.08.2086"
    },
    {
        "engDate": "2029-11-24",
        "nepDate": "09.08.2086"
    },
    {
        "engDate": "2029-11-25",
        "nepDate": "10.08.2086"
    },
    {
        "engDate": "2029-11-26",
        "nepDate": "11.08.2086"
    },
    {
        "engDate": "2029-11-27",
        "nepDate": "12.08.2086"
    },
    {
        "engDate": "2029-11-28",
        "nepDate": "13.08.2086"
    },
    {
        "engDate": "2029-11-29",
        "nepDate": "14.08.2086"
    },
    {
        "engDate": "2029-11-30",
        "nepDate": "15.08.2086"
    },
    {
        "engDate": "2029-12-01",
        "nepDate": "16.08.2086"
    },
    {
        "engDate": "2029-12-02",
        "nepDate": "17.08.2086"
    },
    {
        "engDate": "2029-12-03",
        "nepDate": "18.08.2086"
    },
    {
        "engDate": "2029-12-04",
        "nepDate": "19.08.2086"
    },
    {
        "engDate": "2029-12-05",
        "nepDate": "20.08.2086"
    },
    {
        "engDate": "2029-12-06",
        "nepDate": "21.08.2086"
    },
    {
        "engDate": "2029-12-07",
        "nepDate": "22.08.2086"
    },
    {
        "engDate": "2029-12-08",
        "nepDate": "23.08.2086"
    },
    {
        "engDate": "2029-12-09",
        "nepDate": "24.08.2086"
    },
    {
        "engDate": "2029-12-10",
        "nepDate": "25.08.2086"
    },
    {
        "engDate": "2029-12-11",
        "nepDate": "26.08.2086"
    },
    {
        "engDate": "2029-12-12",
        "nepDate": "27.08.2086"
    },
    {
        "engDate": "2029-12-13",
        "nepDate": "28.08.2086"
    },
    {
        "engDate": "2029-12-14",
        "nepDate": "29.08.2086"
    },
    {
        "engDate": "2029-12-15",
        "nepDate": "30.08.2086"
    },
    {
        "engDate": "2029-12-16",
        "nepDate": "01.09.2086"
    },
    {
        "engDate": "2029-12-17",
        "nepDate": "02.09.2086"
    },
    {
        "engDate": "2029-12-18",
        "nepDate": "03.09.2086"
    },
    {
        "engDate": "2029-12-19",
        "nepDate": "04.09.2086"
    },
    {
        "engDate": "2029-12-20",
        "nepDate": "05.09.2086"
    },
    {
        "engDate": "2029-12-21",
        "nepDate": "06.09.2086"
    },
    {
        "engDate": "2029-12-22",
        "nepDate": "07.09.2086"
    },
    {
        "engDate": "2029-12-23",
        "nepDate": "08.09.2086"
    },
    {
        "engDate": "2029-12-24",
        "nepDate": "09.09.2086"
    },
    {
        "engDate": "2029-12-25",
        "nepDate": "10.09.2086"
    },
    {
        "engDate": "2029-12-26",
        "nepDate": "11.09.2086"
    },
    {
        "engDate": "2029-12-27",
        "nepDate": "12.09.2086"
    },
    {
        "engDate": "2029-12-28",
        "nepDate": "13.09.2086"
    },
    {
        "engDate": "2029-12-29",
        "nepDate": "14.09.2086"
    },
    {
        "engDate": "2029-12-30",
        "nepDate": "15.09.2086"
    },
    {
        "engDate": "2029-12-31",
        "nepDate": "16.09.2086"
    },
    {
        "engDate": "2030-01-01",
        "nepDate": "17.09.2086"
    },
    {
        "engDate": "2030-01-02",
        "nepDate": "18.09.2086"
    },
    {
        "engDate": "2030-01-03",
        "nepDate": "19.09.2086"
    },
    {
        "engDate": "2030-01-04",
        "nepDate": "20.09.2086"
    },
    {
        "engDate": "2030-01-05",
        "nepDate": "21.09.2086"
    },
    {
        "engDate": "2030-01-06",
        "nepDate": "22.09.2086"
    },
    {
        "engDate": "2030-01-07",
        "nepDate": "23.09.2086"
    },
    {
        "engDate": "2030-01-08",
        "nepDate": "24.09.2086"
    },
    {
        "engDate": "2030-01-09",
        "nepDate": "25.09.2086"
    },
    {
        "engDate": "2030-01-10",
        "nepDate": "26.09.2086"
    },
    {
        "engDate": "2030-01-11",
        "nepDate": "27.09.2086"
    },
    {
        "engDate": "2030-01-12",
        "nepDate": "28.09.2086"
    },
    {
        "engDate": "2030-01-13",
        "nepDate": "29.09.2086"
    },
    {
        "engDate": "2030-01-14",
        "nepDate": "01.10.2086"
    },
    {
        "engDate": "2030-01-15",
        "nepDate": "02.10.2086"
    },
    {
        "engDate": "2030-01-16",
        "nepDate": "03.10.2086"
    },
    {
        "engDate": "2030-01-17",
        "nepDate": "04.10.2086"
    },
    {
        "engDate": "2030-01-18",
        "nepDate": "05.10.2086"
    },
    {
        "engDate": "2030-01-19",
        "nepDate": "06.10.2086"
    },
    {
        "engDate": "2030-01-20",
        "nepDate": "07.10.2086"
    },
    {
        "engDate": "2030-01-21",
        "nepDate": "08.10.2086"
    },
    {
        "engDate": "2030-01-22",
        "nepDate": "09.10.2086"
    },
    {
        "engDate": "2030-01-23",
        "nepDate": "10.10.2086"
    },
    {
        "engDate": "2030-01-24",
        "nepDate": "11.10.2086"
    },
    {
        "engDate": "2030-01-25",
        "nepDate": "12.10.2086"
    },
    {
        "engDate": "2030-01-26",
        "nepDate": "13.10.2086"
    },
    {
        "engDate": "2030-01-27",
        "nepDate": "14.10.2086"
    },
    {
        "engDate": "2030-01-28",
        "nepDate": "15.10.2086"
    },
    {
        "engDate": "2030-01-29",
        "nepDate": "16.10.2086"
    },
    {
        "engDate": "2030-01-30",
        "nepDate": "17.10.2086"
    },
    {
        "engDate": "2030-01-31",
        "nepDate": "18.10.2086"
    },
    {
        "engDate": "2030-02-01",
        "nepDate": "19.10.2086"
    },
    {
        "engDate": "2030-02-02",
        "nepDate": "20.10.2086"
    },
    {
        "engDate": "2030-02-03",
        "nepDate": "21.10.2086"
    },
    {
        "engDate": "2030-02-04",
        "nepDate": "22.10.2086"
    },
    {
        "engDate": "2030-02-05",
        "nepDate": "23.10.2086"
    },
    {
        "engDate": "2030-02-06",
        "nepDate": "24.10.2086"
    },
    {
        "engDate": "2030-02-07",
        "nepDate": "25.10.2086"
    },
    {
        "engDate": "2030-02-08",
        "nepDate": "26.10.2086"
    },
    {
        "engDate": "2030-02-09",
        "nepDate": "27.10.2086"
    },
    {
        "engDate": "2030-02-10",
        "nepDate": "28.10.2086"
    },
    {
        "engDate": "2030-02-11",
        "nepDate": "29.10.2086"
    },
    {
        "engDate": "2030-02-12",
        "nepDate": "30.10.2086"
    },
    {
        "engDate": "2030-02-13",
        "nepDate": "01.11.2086"
    },
    {
        "engDate": "2030-02-14",
        "nepDate": "02.11.2086"
    },
    {
        "engDate": "2030-02-15",
        "nepDate": "03.11.2086"
    },
    {
        "engDate": "2030-02-16",
        "nepDate": "04.11.2086"
    },
    {
        "engDate": "2030-02-17",
        "nepDate": "05.11.2086"
    },
    {
        "engDate": "2030-02-18",
        "nepDate": "06.11.2086"
    },
    {
        "engDate": "2030-02-19",
        "nepDate": "07.11.2086"
    },
    {
        "engDate": "2030-02-20",
        "nepDate": "08.11.2086"
    },
    {
        "engDate": "2030-02-21",
        "nepDate": "09.11.2086"
    },
    {
        "engDate": "2030-02-22",
        "nepDate": "10.11.2086"
    },
    {
        "engDate": "2030-02-23",
        "nepDate": "11.11.2086"
    },
    {
        "engDate": "2030-02-24",
        "nepDate": "12.11.2086"
    },
    {
        "engDate": "2030-02-25",
        "nepDate": "13.11.2086"
    },
    {
        "engDate": "2030-02-26",
        "nepDate": "14.11.2086"
    },
    {
        "engDate": "2030-02-27",
        "nepDate": "15.11.2086"
    },
    {
        "engDate": "2030-02-28",
        "nepDate": "16.11.2086"
    },
    {
        "engDate": "2030-03-01",
        "nepDate": "17.11.2086"
    },
    {
        "engDate": "2030-03-02",
        "nepDate": "18.11.2086"
    },
    {
        "engDate": "2030-03-03",
        "nepDate": "19.11.2086"
    },
    {
        "engDate": "2030-03-04",
        "nepDate": "20.11.2086"
    },
    {
        "engDate": "2030-03-05",
        "nepDate": "21.11.2086"
    },
    {
        "engDate": "2030-03-06",
        "nepDate": "22.11.2086"
    },
    {
        "engDate": "2030-03-07",
        "nepDate": "23.11.2086"
    },
    {
        "engDate": "2030-03-08",
        "nepDate": "24.11.2086"
    },
    {
        "engDate": "2030-03-09",
        "nepDate": "25.11.2086"
    },
    {
        "engDate": "2030-03-10",
        "nepDate": "26.11.2086"
    },
    {
        "engDate": "2030-03-11",
        "nepDate": "27.11.2086"
    },
    {
        "engDate": "2030-03-12",
        "nepDate": "28.11.2086"
    },
    {
        "engDate": "2030-03-13",
        "nepDate": "29.11.2086"
    },
    {
        "engDate": "2030-03-14",
        "nepDate": "30.11.2086"
    },
    {
        "engDate": "2030-03-15",
        "nepDate": "01.12.2086"
    },
    {
        "engDate": "2030-03-16",
        "nepDate": "02.12.2086"
    },
    {
        "engDate": "2030-03-17",
        "nepDate": "03.12.2086"
    },
    {
        "engDate": "2030-03-18",
        "nepDate": "04.12.2086"
    },
    {
        "engDate": "2030-03-19",
        "nepDate": "05.12.2086"
    },
    {
        "engDate": "2030-03-20",
        "nepDate": "06.12.2086"
    },
    {
        "engDate": "2030-03-21",
        "nepDate": "07.12.2086"
    },
    {
        "engDate": "2030-03-22",
        "nepDate": "08.12.2086"
    },
    {
        "engDate": "2030-03-23",
        "nepDate": "09.12.2086"
    },
    {
        "engDate": "2030-03-24",
        "nepDate": "10.12.2086"
    },
    {
        "engDate": "2030-03-25",
        "nepDate": "11.12.2086"
    },
    {
        "engDate": "2030-03-26",
        "nepDate": "12.12.2086"
    },
    {
        "engDate": "2030-03-27",
        "nepDate": "13.12.2086"
    },
    {
        "engDate": "2030-03-28",
        "nepDate": "14.12.2086"
    },
    {
        "engDate": "2030-03-29",
        "nepDate": "15.12.2086"
    },
    {
        "engDate": "2030-03-30",
        "nepDate": "16.12.2086"
    },
    {
        "engDate": "2030-03-31",
        "nepDate": "17.12.2086"
    },
    {
        "engDate": "2030-04-01",
        "nepDate": "18.12.2086"
    },
    {
        "engDate": "2030-04-02",
        "nepDate": "19.12.2086"
    },
    {
        "engDate": "2030-04-03",
        "nepDate": "20.12.2086"
    },
    {
        "engDate": "2030-04-04",
        "nepDate": "21.12.2086"
    },
    {
        "engDate": "2030-04-05",
        "nepDate": "22.12.2086"
    },
    {
        "engDate": "2030-04-06",
        "nepDate": "23.12.2086"
    },
    {
        "engDate": "2030-04-07",
        "nepDate": "24.12.2086"
    },
    {
        "engDate": "2030-04-08",
        "nepDate": "25.12.2086"
    },
    {
        "engDate": "2030-04-09",
        "nepDate": "26.12.2086"
    },
    {
        "engDate": "2030-04-10",
        "nepDate": "27.12.2086"
    },
    {
        "engDate": "2030-04-11",
        "nepDate": "28.12.2086"
    },
    {
        "engDate": "2030-04-12",
        "nepDate": "29.12.2086"
    },
    {
        "engDate": "2030-04-13",
        "nepDate": "30.12.2086"
    },
    {
        "engDate": "2030-04-14",
        "nepDate": "01.01.2087"
    },
    {
        "engDate": "2030-04-15",
        "nepDate": "02.01.2087"
    },
    {
        "engDate": "2030-04-16",
        "nepDate": "03.01.2087"
    },
    {
        "engDate": "2030-04-17",
        "nepDate": "04.01.2087"
    },
    {
        "engDate": "2030-04-18",
        "nepDate": "05.01.2087"
    },
    {
        "engDate": "2030-04-19",
        "nepDate": "06.01.2087"
    },
    {
        "engDate": "2030-04-20",
        "nepDate": "07.01.2087"
    },
    {
        "engDate": "2030-04-21",
        "nepDate": "08.01.2087"
    },
    {
        "engDate": "2030-04-22",
        "nepDate": "09.01.2087"
    },
    {
        "engDate": "2030-04-23",
        "nepDate": "10.01.2087"
    },
    {
        "engDate": "2030-04-24",
        "nepDate": "11.01.2087"
    },
    {
        "engDate": "2030-04-25",
        "nepDate": "12.01.2087"
    },
    {
        "engDate": "2030-04-26",
        "nepDate": "13.01.2087"
    },
    {
        "engDate": "2030-04-27",
        "nepDate": "14.01.2087"
    },
    {
        "engDate": "2030-04-28",
        "nepDate": "15.01.2087"
    },
    {
        "engDate": "2030-04-29",
        "nepDate": "16.01.2087"
    },
    {
        "engDate": "2030-04-30",
        "nepDate": "17.01.2087"
    },
    {
        "engDate": "2030-05-01",
        "nepDate": "18.01.2087"
    },
    {
        "engDate": "2030-05-02",
        "nepDate": "19.01.2087"
    },
    {
        "engDate": "2030-05-03",
        "nepDate": "20.01.2087"
    },
    {
        "engDate": "2030-05-04",
        "nepDate": "21.01.2087"
    },
    {
        "engDate": "2030-05-05",
        "nepDate": "22.01.2087"
    },
    {
        "engDate": "2030-05-06",
        "nepDate": "23.01.2087"
    },
    {
        "engDate": "2030-05-07",
        "nepDate": "24.01.2087"
    },
    {
        "engDate": "2030-05-08",
        "nepDate": "25.01.2087"
    },
    {
        "engDate": "2030-05-09",
        "nepDate": "26.01.2087"
    },
    {
        "engDate": "2030-05-10",
        "nepDate": "27.01.2087"
    },
    {
        "engDate": "2030-05-11",
        "nepDate": "28.01.2087"
    },
    {
        "engDate": "2030-05-12",
        "nepDate": "29.01.2087"
    },
    {
        "engDate": "2030-05-13",
        "nepDate": "30.01.2087"
    },
    {
        "engDate": "2030-05-14",
        "nepDate": "31.01.2087"
    },
    {
        "engDate": "2030-05-15",
        "nepDate": "01.02.2087"
    },
    {
        "engDate": "2030-05-16",
        "nepDate": "02.02.2087"
    },
    {
        "engDate": "2030-05-17",
        "nepDate": "03.02.2087"
    },
    {
        "engDate": "2030-05-18",
        "nepDate": "04.02.2087"
    },
    {
        "engDate": "2030-05-19",
        "nepDate": "05.02.2087"
    },
    {
        "engDate": "2030-05-20",
        "nepDate": "06.02.2087"
    },
    {
        "engDate": "2030-05-21",
        "nepDate": "07.02.2087"
    },
    {
        "engDate": "2030-05-22",
        "nepDate": "08.02.2087"
    },
    {
        "engDate": "2030-05-23",
        "nepDate": "09.02.2087"
    },
    {
        "engDate": "2030-05-24",
        "nepDate": "10.02.2087"
    },
    {
        "engDate": "2030-05-25",
        "nepDate": "11.02.2087"
    },
    {
        "engDate": "2030-05-26",
        "nepDate": "12.02.2087"
    },
    {
        "engDate": "2030-05-27",
        "nepDate": "13.02.2087"
    },
    {
        "engDate": "2030-05-28",
        "nepDate": "14.02.2087"
    },
    {
        "engDate": "2030-05-29",
        "nepDate": "15.02.2087"
    },
    {
        "engDate": "2030-05-30",
        "nepDate": "16.02.2087"
    },
    {
        "engDate": "2030-05-31",
        "nepDate": "17.02.2087"
    },
    {
        "engDate": "2030-06-01",
        "nepDate": "18.02.2087"
    },
    {
        "engDate": "2030-06-02",
        "nepDate": "19.02.2087"
    },
    {
        "engDate": "2030-06-03",
        "nepDate": "20.02.2087"
    },
    {
        "engDate": "2030-06-04",
        "nepDate": "21.02.2087"
    },
    {
        "engDate": "2030-06-05",
        "nepDate": "22.02.2087"
    },
    {
        "engDate": "2030-06-06",
        "nepDate": "23.02.2087"
    },
    {
        "engDate": "2030-06-07",
        "nepDate": "24.02.2087"
    },
    {
        "engDate": "2030-06-08",
        "nepDate": "25.02.2087"
    },
    {
        "engDate": "2030-06-09",
        "nepDate": "26.02.2087"
    },
    {
        "engDate": "2030-06-10",
        "nepDate": "27.02.2087"
    },
    {
        "engDate": "2030-06-11",
        "nepDate": "28.02.2087"
    },
    {
        "engDate": "2030-06-12",
        "nepDate": "29.02.2087"
    },
    {
        "engDate": "2030-06-13",
        "nepDate": "30.02.2087"
    },
    {
        "engDate": "2030-06-14",
        "nepDate": "31.02.2087"
    },
    {
        "engDate": "2030-06-15",
        "nepDate": "01.03.2087"
    },
    {
        "engDate": "2030-06-16",
        "nepDate": "02.03.2087"
    },
    {
        "engDate": "2030-06-17",
        "nepDate": "03.03.2087"
    },
    {
        "engDate": "2030-06-18",
        "nepDate": "04.03.2087"
    },
    {
        "engDate": "2030-06-19",
        "nepDate": "05.03.2087"
    },
    {
        "engDate": "2030-06-20",
        "nepDate": "06.03.2087"
    },
    {
        "engDate": "2030-06-21",
        "nepDate": "07.03.2087"
    },
    {
        "engDate": "2030-06-22",
        "nepDate": "08.03.2087"
    },
    {
        "engDate": "2030-06-23",
        "nepDate": "09.03.2087"
    },
    {
        "engDate": "2030-06-24",
        "nepDate": "10.03.2087"
    },
    {
        "engDate": "2030-06-25",
        "nepDate": "11.03.2087"
    },
    {
        "engDate": "2030-06-26",
        "nepDate": "12.03.2087"
    },
    {
        "engDate": "2030-06-27",
        "nepDate": "13.03.2087"
    },
    {
        "engDate": "2030-06-28",
        "nepDate": "14.03.2087"
    },
    {
        "engDate": "2030-06-29",
        "nepDate": "15.03.2087"
    },
    {
        "engDate": "2030-06-30",
        "nepDate": "16.03.2087"
    },
    {
        "engDate": "2030-07-01",
        "nepDate": "17.03.2087"
    },
    {
        "engDate": "2030-07-02",
        "nepDate": "18.03.2087"
    },
    {
        "engDate": "2030-07-03",
        "nepDate": "19.03.2087"
    },
    {
        "engDate": "2030-07-04",
        "nepDate": "20.03.2087"
    },
    {
        "engDate": "2030-07-05",
        "nepDate": "21.03.2087"
    },
    {
        "engDate": "2030-07-06",
        "nepDate": "22.03.2087"
    },
    {
        "engDate": "2030-07-07",
        "nepDate": "23.03.2087"
    },
    {
        "engDate": "2030-07-08",
        "nepDate": "24.03.2087"
    },
    {
        "engDate": "2030-07-09",
        "nepDate": "25.03.2087"
    },
    {
        "engDate": "2030-07-10",
        "nepDate": "26.03.2087"
    },
    {
        "engDate": "2030-07-11",
        "nepDate": "27.03.2087"
    },
    {
        "engDate": "2030-07-12",
        "nepDate": "28.03.2087"
    },
    {
        "engDate": "2030-07-13",
        "nepDate": "29.03.2087"
    },
    {
        "engDate": "2030-07-14",
        "nepDate": "30.03.2087"
    },
    {
        "engDate": "2030-07-15",
        "nepDate": "31.03.2087"
    },
    {
        "engDate": "2030-07-16",
        "nepDate": "32.03.2087"
    },
    {
        "engDate": "2030-07-17",
        "nepDate": "01.04.2087"
    },
    {
        "engDate": "2030-07-18",
        "nepDate": "02.04.2087"
    },
    {
        "engDate": "2030-07-19",
        "nepDate": "03.04.2087"
    },
    {
        "engDate": "2030-07-20",
        "nepDate": "04.04.2087"
    },
    {
        "engDate": "2030-07-21",
        "nepDate": "05.04.2087"
    },
    {
        "engDate": "2030-07-22",
        "nepDate": "06.04.2087"
    },
    {
        "engDate": "2030-07-23",
        "nepDate": "07.04.2087"
    },
    {
        "engDate": "2030-07-24",
        "nepDate": "08.04.2087"
    },
    {
        "engDate": "2030-07-25",
        "nepDate": "09.04.2087"
    },
    {
        "engDate": "2030-07-26",
        "nepDate": "10.04.2087"
    },
    {
        "engDate": "2030-07-27",
        "nepDate": "11.04.2087"
    },
    {
        "engDate": "2030-07-28",
        "nepDate": "12.04.2087"
    },
    {
        "engDate": "2030-07-29",
        "nepDate": "13.04.2087"
    },
    {
        "engDate": "2030-07-30",
        "nepDate": "14.04.2087"
    },
    {
        "engDate": "2030-07-31",
        "nepDate": "15.04.2087"
    },
    {
        "engDate": "2030-08-01",
        "nepDate": "16.04.2087"
    },
    {
        "engDate": "2030-08-02",
        "nepDate": "17.04.2087"
    },
    {
        "engDate": "2030-08-03",
        "nepDate": "18.04.2087"
    },
    {
        "engDate": "2030-08-04",
        "nepDate": "19.04.2087"
    },
    {
        "engDate": "2030-08-05",
        "nepDate": "20.04.2087"
    },
    {
        "engDate": "2030-08-06",
        "nepDate": "21.04.2087"
    },
    {
        "engDate": "2030-08-07",
        "nepDate": "22.04.2087"
    },
    {
        "engDate": "2030-08-08",
        "nepDate": "23.04.2087"
    },
    {
        "engDate": "2030-08-09",
        "nepDate": "24.04.2087"
    },
    {
        "engDate": "2030-08-10",
        "nepDate": "25.04.2087"
    },
    {
        "engDate": "2030-08-11",
        "nepDate": "26.04.2087"
    },
    {
        "engDate": "2030-08-12",
        "nepDate": "27.04.2087"
    },
    {
        "engDate": "2030-08-13",
        "nepDate": "28.04.2087"
    },
    {
        "engDate": "2030-08-14",
        "nepDate": "29.04.2087"
    },
    {
        "engDate": "2030-08-15",
        "nepDate": "30.04.2087"
    },
    {
        "engDate": "2030-08-16",
        "nepDate": "31.04.2087"
    },
    {
        "engDate": "2030-08-17",
        "nepDate": "01.05.2087"
    },
    {
        "engDate": "2030-08-18",
        "nepDate": "02.05.2087"
    },
    {
        "engDate": "2030-08-19",
        "nepDate": "03.05.2087"
    },
    {
        "engDate": "2030-08-20",
        "nepDate": "04.05.2087"
    },
    {
        "engDate": "2030-08-21",
        "nepDate": "05.05.2087"
    },
    {
        "engDate": "2030-08-22",
        "nepDate": "06.05.2087"
    },
    {
        "engDate": "2030-08-23",
        "nepDate": "07.05.2087"
    },
    {
        "engDate": "2030-08-24",
        "nepDate": "08.05.2087"
    },
    {
        "engDate": "2030-08-25",
        "nepDate": "09.05.2087"
    },
    {
        "engDate": "2030-08-26",
        "nepDate": "10.05.2087"
    },
    {
        "engDate": "2030-08-27",
        "nepDate": "11.05.2087"
    },
    {
        "engDate": "2030-08-28",
        "nepDate": "12.05.2087"
    },
    {
        "engDate": "2030-08-29",
        "nepDate": "13.05.2087"
    },
    {
        "engDate": "2030-08-30",
        "nepDate": "14.05.2087"
    },
    {
        "engDate": "2030-08-31",
        "nepDate": "15.05.2087"
    },
    {
        "engDate": "2030-09-01",
        "nepDate": "16.05.2087"
    },
    {
        "engDate": "2030-09-02",
        "nepDate": "17.05.2087"
    },
    {
        "engDate": "2030-09-03",
        "nepDate": "18.05.2087"
    },
    {
        "engDate": "2030-09-04",
        "nepDate": "19.05.2087"
    },
    {
        "engDate": "2030-09-05",
        "nepDate": "20.05.2087"
    },
    {
        "engDate": "2030-09-06",
        "nepDate": "21.05.2087"
    },
    {
        "engDate": "2030-09-07",
        "nepDate": "22.05.2087"
    },
    {
        "engDate": "2030-09-08",
        "nepDate": "23.05.2087"
    },
    {
        "engDate": "2030-09-09",
        "nepDate": "24.05.2087"
    },
    {
        "engDate": "2030-09-10",
        "nepDate": "25.05.2087"
    },
    {
        "engDate": "2030-09-11",
        "nepDate": "26.05.2087"
    },
    {
        "engDate": "2030-09-12",
        "nepDate": "27.05.2087"
    },
    {
        "engDate": "2030-09-13",
        "nepDate": "28.05.2087"
    },
    {
        "engDate": "2030-09-14",
        "nepDate": "29.05.2087"
    },
    {
        "engDate": "2030-09-15",
        "nepDate": "30.05.2087"
    },
    {
        "engDate": "2030-09-16",
        "nepDate": "31.05.2087"
    },
    {
        "engDate": "2030-09-17",
        "nepDate": "01.06.2087"
    },
    {
        "engDate": "2030-09-18",
        "nepDate": "02.06.2087"
    },
    {
        "engDate": "2030-09-19",
        "nepDate": "03.06.2087"
    },
    {
        "engDate": "2030-09-20",
        "nepDate": "04.06.2087"
    },
    {
        "engDate": "2030-09-21",
        "nepDate": "05.06.2087"
    },
    {
        "engDate": "2030-09-22",
        "nepDate": "06.06.2087"
    },
    {
        "engDate": "2030-09-23",
        "nepDate": "07.06.2087"
    },
    {
        "engDate": "2030-09-24",
        "nepDate": "08.06.2087"
    },
    {
        "engDate": "2030-09-25",
        "nepDate": "09.06.2087"
    },
    {
        "engDate": "2030-09-26",
        "nepDate": "10.06.2087"
    },
    {
        "engDate": "2030-09-27",
        "nepDate": "11.06.2087"
    },
    {
        "engDate": "2030-09-28",
        "nepDate": "12.06.2087"
    },
    {
        "engDate": "2030-09-29",
        "nepDate": "13.06.2087"
    },
    {
        "engDate": "2030-09-30",
        "nepDate": "14.06.2087"
    },
    {
        "engDate": "2030-10-01",
        "nepDate": "15.06.2087"
    },
    {
        "engDate": "2030-10-02",
        "nepDate": "16.06.2087"
    },
    {
        "engDate": "2030-10-03",
        "nepDate": "17.06.2087"
    },
    {
        "engDate": "2030-10-04",
        "nepDate": "18.06.2087"
    },
    {
        "engDate": "2030-10-05",
        "nepDate": "19.06.2087"
    },
    {
        "engDate": "2030-10-06",
        "nepDate": "20.06.2087"
    },
    {
        "engDate": "2030-10-07",
        "nepDate": "21.06.2087"
    },
    {
        "engDate": "2030-10-08",
        "nepDate": "22.06.2087"
    },
    {
        "engDate": "2030-10-09",
        "nepDate": "23.06.2087"
    },
    {
        "engDate": "2030-10-10",
        "nepDate": "24.06.2087"
    },
    {
        "engDate": "2030-10-11",
        "nepDate": "25.06.2087"
    },
    {
        "engDate": "2030-10-12",
        "nepDate": "26.06.2087"
    },
    {
        "engDate": "2030-10-13",
        "nepDate": "27.06.2087"
    },
    {
        "engDate": "2030-10-14",
        "nepDate": "28.06.2087"
    },
    {
        "engDate": "2030-10-15",
        "nepDate": "29.06.2087"
    },
    {
        "engDate": "2030-10-16",
        "nepDate": "30.06.2087"
    },
    {
        "engDate": "2030-10-17",
        "nepDate": "31.06.2087"
    },
    {
        "engDate": "2030-10-18",
        "nepDate": "01.07.2087"
    },
    {
        "engDate": "2030-10-19",
        "nepDate": "02.07.2087"
    },
    {
        "engDate": "2030-10-20",
        "nepDate": "03.07.2087"
    },
    {
        "engDate": "2030-10-21",
        "nepDate": "04.07.2087"
    },
    {
        "engDate": "2030-10-22",
        "nepDate": "05.07.2087"
    },
    {
        "engDate": "2030-10-23",
        "nepDate": "06.07.2087"
    },
    {
        "engDate": "2030-10-24",
        "nepDate": "07.07.2087"
    },
    {
        "engDate": "2030-10-25",
        "nepDate": "08.07.2087"
    },
    {
        "engDate": "2030-10-26",
        "nepDate": "09.07.2087"
    },
    {
        "engDate": "2030-10-27",
        "nepDate": "10.07.2087"
    },
    {
        "engDate": "2030-10-28",
        "nepDate": "11.07.2087"
    },
    {
        "engDate": "2030-10-29",
        "nepDate": "12.07.2087"
    },
    {
        "engDate": "2030-10-30",
        "nepDate": "13.07.2087"
    },
    {
        "engDate": "2030-10-31",
        "nepDate": "14.07.2087"
    },
    {
        "engDate": "2030-11-01",
        "nepDate": "15.07.2087"
    },
    {
        "engDate": "2030-11-02",
        "nepDate": "16.07.2087"
    },
    {
        "engDate": "2030-11-03",
        "nepDate": "17.07.2087"
    },
    {
        "engDate": "2030-11-04",
        "nepDate": "18.07.2087"
    },
    {
        "engDate": "2030-11-05",
        "nepDate": "19.07.2087"
    },
    {
        "engDate": "2030-11-06",
        "nepDate": "20.07.2087"
    },
    {
        "engDate": "2030-11-07",
        "nepDate": "21.07.2087"
    },
    {
        "engDate": "2030-11-08",
        "nepDate": "22.07.2087"
    },
    {
        "engDate": "2030-11-09",
        "nepDate": "23.07.2087"
    },
    {
        "engDate": "2030-11-10",
        "nepDate": "24.07.2087"
    },
    {
        "engDate": "2030-11-11",
        "nepDate": "25.07.2087"
    },
    {
        "engDate": "2030-11-12",
        "nepDate": "26.07.2087"
    },
    {
        "engDate": "2030-11-13",
        "nepDate": "27.07.2087"
    },
    {
        "engDate": "2030-11-14",
        "nepDate": "28.07.2087"
    },
    {
        "engDate": "2030-11-15",
        "nepDate": "29.07.2087"
    },
    {
        "engDate": "2030-11-16",
        "nepDate": "30.07.2087"
    },
    {
        "engDate": "2030-11-17",
        "nepDate": "01.08.2087"
    },
    {
        "engDate": "2030-11-18",
        "nepDate": "02.08.2087"
    },
    {
        "engDate": "2030-11-19",
        "nepDate": "03.08.2087"
    },
    {
        "engDate": "2030-11-20",
        "nepDate": "04.08.2087"
    },
    {
        "engDate": "2030-11-21",
        "nepDate": "05.08.2087"
    },
    {
        "engDate": "2030-11-22",
        "nepDate": "06.08.2087"
    },
    {
        "engDate": "2030-11-23",
        "nepDate": "07.08.2087"
    },
    {
        "engDate": "2030-11-24",
        "nepDate": "08.08.2087"
    },
    {
        "engDate": "2030-11-25",
        "nepDate": "09.08.2087"
    },
    {
        "engDate": "2030-11-26",
        "nepDate": "10.08.2087"
    },
    {
        "engDate": "2030-11-27",
        "nepDate": "11.08.2087"
    },
    {
        "engDate": "2030-11-28",
        "nepDate": "12.08.2087"
    },
    {
        "engDate": "2030-11-29",
        "nepDate": "13.08.2087"
    },
    {
        "engDate": "2030-11-30",
        "nepDate": "14.08.2087"
    },
    {
        "engDate": "2030-12-01",
        "nepDate": "15.08.2087"
    },
    {
        "engDate": "2030-12-02",
        "nepDate": "16.08.2087"
    },
    {
        "engDate": "2030-12-03",
        "nepDate": "17.08.2087"
    },
    {
        "engDate": "2030-12-04",
        "nepDate": "18.08.2087"
    },
    {
        "engDate": "2030-12-05",
        "nepDate": "19.08.2087"
    },
    {
        "engDate": "2030-12-06",
        "nepDate": "20.08.2087"
    },
    {
        "engDate": "2030-12-07",
        "nepDate": "21.08.2087"
    },
    {
        "engDate": "2030-12-08",
        "nepDate": "22.08.2087"
    },
    {
        "engDate": "2030-12-09",
        "nepDate": "23.08.2087"
    },
    {
        "engDate": "2030-12-10",
        "nepDate": "24.08.2087"
    },
    {
        "engDate": "2030-12-11",
        "nepDate": "25.08.2087"
    },
    {
        "engDate": "2030-12-12",
        "nepDate": "26.08.2087"
    },
    {
        "engDate": "2030-12-13",
        "nepDate": "27.08.2087"
    },
    {
        "engDate": "2030-12-14",
        "nepDate": "28.08.2087"
    },
    {
        "engDate": "2030-12-15",
        "nepDate": "29.08.2087"
    },
    {
        "engDate": "2030-12-16",
        "nepDate": "30.08.2087"
    },
    {
        "engDate": "2030-12-17",
        "nepDate": "01.09.2087"
    },
    {
        "engDate": "2030-12-18",
        "nepDate": "02.09.2087"
    },
    {
        "engDate": "2030-12-19",
        "nepDate": "03.09.2087"
    },
    {
        "engDate": "2030-12-20",
        "nepDate": "04.09.2087"
    },
    {
        "engDate": "2030-12-21",
        "nepDate": "05.09.2087"
    },
    {
        "engDate": "2030-12-22",
        "nepDate": "06.09.2087"
    },
    {
        "engDate": "2030-12-23",
        "nepDate": "07.09.2087"
    },
    {
        "engDate": "2030-12-24",
        "nepDate": "08.09.2087"
    },
    {
        "engDate": "2030-12-25",
        "nepDate": "09.09.2087"
    },
    {
        "engDate": "2030-12-26",
        "nepDate": "10.09.2087"
    },
    {
        "engDate": "2030-12-27",
        "nepDate": "11.09.2087"
    },
    {
        "engDate": "2030-12-28",
        "nepDate": "12.09.2087"
    },
    {
        "engDate": "2030-12-29",
        "nepDate": "13.09.2087"
    },
    {
        "engDate": "2030-12-30",
        "nepDate": "14.09.2087"
    },
    {
        "engDate": "2030-12-31",
        "nepDate": "15.09.2087"
    },
    {
        "engDate": "2031-01-01",
        "nepDate": "16.09.2087"
    },
    {
        "engDate": "2031-01-02",
        "nepDate": "17.09.2087"
    },
    {
        "engDate": "2031-01-03",
        "nepDate": "18.09.2087"
    },
    {
        "engDate": "2031-01-04",
        "nepDate": "19.09.2087"
    },
    {
        "engDate": "2031-01-05",
        "nepDate": "20.09.2087"
    },
    {
        "engDate": "2031-01-06",
        "nepDate": "21.09.2087"
    },
    {
        "engDate": "2031-01-07",
        "nepDate": "22.09.2087"
    },
    {
        "engDate": "2031-01-08",
        "nepDate": "23.09.2087"
    },
    {
        "engDate": "2031-01-09",
        "nepDate": "24.09.2087"
    },
    {
        "engDate": "2031-01-10",
        "nepDate": "25.09.2087"
    },
    {
        "engDate": "2031-01-11",
        "nepDate": "26.09.2087"
    },
    {
        "engDate": "2031-01-12",
        "nepDate": "27.09.2087"
    },
    {
        "engDate": "2031-01-13",
        "nepDate": "28.09.2087"
    },
    {
        "engDate": "2031-01-14",
        "nepDate": "29.09.2087"
    },
    {
        "engDate": "2031-01-15",
        "nepDate": "01.10.2087"
    },
    {
        "engDate": "2031-01-16",
        "nepDate": "02.10.2087"
    },
    {
        "engDate": "2031-01-17",
        "nepDate": "03.10.2087"
    },
    {
        "engDate": "2031-01-18",
        "nepDate": "04.10.2087"
    },
    {
        "engDate": "2031-01-19",
        "nepDate": "05.10.2087"
    },
    {
        "engDate": "2031-01-20",
        "nepDate": "06.10.2087"
    },
    {
        "engDate": "2031-01-21",
        "nepDate": "07.10.2087"
    },
    {
        "engDate": "2031-01-22",
        "nepDate": "08.10.2087"
    },
    {
        "engDate": "2031-01-23",
        "nepDate": "09.10.2087"
    },
    {
        "engDate": "2031-01-24",
        "nepDate": "10.10.2087"
    },
    {
        "engDate": "2031-01-25",
        "nepDate": "11.10.2087"
    },
    {
        "engDate": "2031-01-26",
        "nepDate": "12.10.2087"
    },
    {
        "engDate": "2031-01-27",
        "nepDate": "13.10.2087"
    },
    {
        "engDate": "2031-01-28",
        "nepDate": "14.10.2087"
    },
    {
        "engDate": "2031-01-29",
        "nepDate": "15.10.2087"
    },
    {
        "engDate": "2031-01-30",
        "nepDate": "16.10.2087"
    },
    {
        "engDate": "2031-01-31",
        "nepDate": "17.10.2087"
    },
    {
        "engDate": "2031-02-01",
        "nepDate": "18.10.2087"
    },
    {
        "engDate": "2031-02-02",
        "nepDate": "19.10.2087"
    },
    {
        "engDate": "2031-02-03",
        "nepDate": "20.10.2087"
    },
    {
        "engDate": "2031-02-04",
        "nepDate": "21.10.2087"
    },
    {
        "engDate": "2031-02-05",
        "nepDate": "22.10.2087"
    },
    {
        "engDate": "2031-02-06",
        "nepDate": "23.10.2087"
    },
    {
        "engDate": "2031-02-07",
        "nepDate": "24.10.2087"
    },
    {
        "engDate": "2031-02-08",
        "nepDate": "25.10.2087"
    },
    {
        "engDate": "2031-02-09",
        "nepDate": "26.10.2087"
    },
    {
        "engDate": "2031-02-10",
        "nepDate": "27.10.2087"
    },
    {
        "engDate": "2031-02-11",
        "nepDate": "28.10.2087"
    },
    {
        "engDate": "2031-02-12",
        "nepDate": "29.10.2087"
    },
    {
        "engDate": "2031-02-13",
        "nepDate": "30.10.2087"
    },
    {
        "engDate": "2031-02-14",
        "nepDate": "01.11.2087"
    },
    {
        "engDate": "2031-02-15",
        "nepDate": "02.11.2087"
    },
    {
        "engDate": "2031-02-16",
        "nepDate": "03.11.2087"
    },
    {
        "engDate": "2031-02-17",
        "nepDate": "04.11.2087"
    },
    {
        "engDate": "2031-02-18",
        "nepDate": "05.11.2087"
    },
    {
        "engDate": "2031-02-19",
        "nepDate": "06.11.2087"
    },
    {
        "engDate": "2031-02-20",
        "nepDate": "07.11.2087"
    },
    {
        "engDate": "2031-02-21",
        "nepDate": "08.11.2087"
    },
    {
        "engDate": "2031-02-22",
        "nepDate": "09.11.2087"
    },
    {
        "engDate": "2031-02-23",
        "nepDate": "10.11.2087"
    },
    {
        "engDate": "2031-02-24",
        "nepDate": "11.11.2087"
    },
    {
        "engDate": "2031-02-25",
        "nepDate": "12.11.2087"
    },
    {
        "engDate": "2031-02-26",
        "nepDate": "13.11.2087"
    },
    {
        "engDate": "2031-02-27",
        "nepDate": "14.11.2087"
    },
    {
        "engDate": "2031-02-28",
        "nepDate": "15.11.2087"
    },
    {
        "engDate": "2031-03-01",
        "nepDate": "16.11.2087"
    },
    {
        "engDate": "2031-03-02",
        "nepDate": "17.11.2087"
    },
    {
        "engDate": "2031-03-03",
        "nepDate": "18.11.2087"
    },
    {
        "engDate": "2031-03-04",
        "nepDate": "19.11.2087"
    },
    {
        "engDate": "2031-03-05",
        "nepDate": "20.11.2087"
    },
    {
        "engDate": "2031-03-06",
        "nepDate": "21.11.2087"
    },
    {
        "engDate": "2031-03-07",
        "nepDate": "22.11.2087"
    },
    {
        "engDate": "2031-03-08",
        "nepDate": "23.11.2087"
    },
    {
        "engDate": "2031-03-09",
        "nepDate": "24.11.2087"
    },
    {
        "engDate": "2031-03-10",
        "nepDate": "25.11.2087"
    },
    {
        "engDate": "2031-03-11",
        "nepDate": "26.11.2087"
    },
    {
        "engDate": "2031-03-12",
        "nepDate": "27.11.2087"
    },
    {
        "engDate": "2031-03-13",
        "nepDate": "28.11.2087"
    },
    {
        "engDate": "2031-03-14",
        "nepDate": "29.11.2087"
    },
    {
        "engDate": "2031-03-15",
        "nepDate": "30.11.2087"
    },
    {
        "engDate": "2031-03-16",
        "nepDate": "01.12.2087"
    },
    {
        "engDate": "2031-03-17",
        "nepDate": "02.12.2087"
    },
    {
        "engDate": "2031-03-18",
        "nepDate": "03.12.2087"
    },
    {
        "engDate": "2031-03-19",
        "nepDate": "04.12.2087"
    },
    {
        "engDate": "2031-03-20",
        "nepDate": "05.12.2087"
    },
    {
        "engDate": "2031-03-21",
        "nepDate": "06.12.2087"
    },
    {
        "engDate": "2031-03-22",
        "nepDate": "07.12.2087"
    },
    {
        "engDate": "2031-03-23",
        "nepDate": "08.12.2087"
    },
    {
        "engDate": "2031-03-24",
        "nepDate": "09.12.2087"
    },
    {
        "engDate": "2031-03-25",
        "nepDate": "10.12.2087"
    },
    {
        "engDate": "2031-03-26",
        "nepDate": "11.12.2087"
    },
    {
        "engDate": "2031-03-27",
        "nepDate": "12.12.2087"
    },
    {
        "engDate": "2031-03-28",
        "nepDate": "13.12.2087"
    },
    {
        "engDate": "2031-03-29",
        "nepDate": "14.12.2087"
    },
    {
        "engDate": "2031-03-30",
        "nepDate": "15.12.2087"
    },
    {
        "engDate": "2031-03-31",
        "nepDate": "16.12.2087"
    },
    {
        "engDate": "2031-04-01",
        "nepDate": "17.12.2087"
    },
    {
        "engDate": "2031-04-02",
        "nepDate": "18.12.2087"
    },
    {
        "engDate": "2031-04-03",
        "nepDate": "19.12.2087"
    },
    {
        "engDate": "2031-04-04",
        "nepDate": "20.12.2087"
    },
    {
        "engDate": "2031-04-05",
        "nepDate": "21.12.2087"
    },
    {
        "engDate": "2031-04-06",
        "nepDate": "22.12.2087"
    },
    {
        "engDate": "2031-04-07",
        "nepDate": "23.12.2087"
    },
    {
        "engDate": "2031-04-08",
        "nepDate": "24.12.2087"
    },
    {
        "engDate": "2031-04-09",
        "nepDate": "25.12.2087"
    },
    {
        "engDate": "2031-04-10",
        "nepDate": "26.12.2087"
    },
    {
        "engDate": "2031-04-11",
        "nepDate": "27.12.2087"
    },
    {
        "engDate": "2031-04-12",
        "nepDate": "28.12.2087"
    },
    {
        "engDate": "2031-04-13",
        "nepDate": "29.12.2087"
    },
    {
        "engDate": "2031-04-14",
        "nepDate": "30.12.2087"
    },
    {
        "engDate": "2031-04-15",
        "nepDate": "01.01.2088"
    },
    {
        "engDate": "2031-04-16",
        "nepDate": "02.01.2088"
    },
    {
        "engDate": "2031-04-17",
        "nepDate": "03.01.2088"
    },
    {
        "engDate": "2031-04-18",
        "nepDate": "04.01.2088"
    },
    {
        "engDate": "2031-04-19",
        "nepDate": "05.01.2088"
    },
    {
        "engDate": "2031-04-20",
        "nepDate": "06.01.2088"
    },
    {
        "engDate": "2031-04-21",
        "nepDate": "07.01.2088"
    },
    {
        "engDate": "2031-04-22",
        "nepDate": "08.01.2088"
    },
    {
        "engDate": "2031-04-23",
        "nepDate": "09.01.2088"
    },
    {
        "engDate": "2031-04-24",
        "nepDate": "10.01.2088"
    },
    {
        "engDate": "2031-04-25",
        "nepDate": "11.01.2088"
    },
    {
        "engDate": "2031-04-26",
        "nepDate": "12.01.2088"
    },
    {
        "engDate": "2031-04-27",
        "nepDate": "13.01.2088"
    },
    {
        "engDate": "2031-04-28",
        "nepDate": "14.01.2088"
    },
    {
        "engDate": "2031-04-29",
        "nepDate": "15.01.2088"
    },
    {
        "engDate": "2031-04-30",
        "nepDate": "16.01.2088"
    },
    {
        "engDate": "2031-05-01",
        "nepDate": "17.01.2088"
    },
    {
        "engDate": "2031-05-02",
        "nepDate": "18.01.2088"
    },
    {
        "engDate": "2031-05-03",
        "nepDate": "19.01.2088"
    },
    {
        "engDate": "2031-05-04",
        "nepDate": "20.01.2088"
    },
    {
        "engDate": "2031-05-05",
        "nepDate": "21.01.2088"
    },
    {
        "engDate": "2031-05-06",
        "nepDate": "22.01.2088"
    },
    {
        "engDate": "2031-05-07",
        "nepDate": "23.01.2088"
    },
    {
        "engDate": "2031-05-08",
        "nepDate": "24.01.2088"
    },
    {
        "engDate": "2031-05-09",
        "nepDate": "25.01.2088"
    },
    {
        "engDate": "2031-05-10",
        "nepDate": "26.01.2088"
    },
    {
        "engDate": "2031-05-11",
        "nepDate": "27.01.2088"
    },
    {
        "engDate": "2031-05-12",
        "nepDate": "28.01.2088"
    },
    {
        "engDate": "2031-05-13",
        "nepDate": "29.01.2088"
    },
    {
        "engDate": "2031-05-14",
        "nepDate": "30.01.2088"
    },
    {
        "engDate": "2031-05-15",
        "nepDate": "01.02.2088"
    },
    {
        "engDate": "2031-05-16",
        "nepDate": "02.02.2088"
    },
    {
        "engDate": "2031-05-17",
        "nepDate": "03.02.2088"
    },
    {
        "engDate": "2031-05-18",
        "nepDate": "04.02.2088"
    },
    {
        "engDate": "2031-05-19",
        "nepDate": "05.02.2088"
    },
    {
        "engDate": "2031-05-20",
        "nepDate": "06.02.2088"
    },
    {
        "engDate": "2031-05-21",
        "nepDate": "07.02.2088"
    },
    {
        "engDate": "2031-05-22",
        "nepDate": "08.02.2088"
    },
    {
        "engDate": "2031-05-23",
        "nepDate": "09.02.2088"
    },
    {
        "engDate": "2031-05-24",
        "nepDate": "10.02.2088"
    },
    {
        "engDate": "2031-05-25",
        "nepDate": "11.02.2088"
    },
    {
        "engDate": "2031-05-26",
        "nepDate": "12.02.2088"
    },
    {
        "engDate": "2031-05-27",
        "nepDate": "13.02.2088"
    },
    {
        "engDate": "2031-05-28",
        "nepDate": "14.02.2088"
    },
    {
        "engDate": "2031-05-29",
        "nepDate": "15.02.2088"
    },
    {
        "engDate": "2031-05-30",
        "nepDate": "16.02.2088"
    },
    {
        "engDate": "2031-05-31",
        "nepDate": "17.02.2088"
    },
    {
        "engDate": "2031-06-01",
        "nepDate": "18.02.2088"
    },
    {
        "engDate": "2031-06-02",
        "nepDate": "19.02.2088"
    },
    {
        "engDate": "2031-06-03",
        "nepDate": "20.02.2088"
    },
    {
        "engDate": "2031-06-04",
        "nepDate": "21.02.2088"
    },
    {
        "engDate": "2031-06-05",
        "nepDate": "22.02.2088"
    },
    {
        "engDate": "2031-06-06",
        "nepDate": "23.02.2088"
    },
    {
        "engDate": "2031-06-07",
        "nepDate": "24.02.2088"
    },
    {
        "engDate": "2031-06-08",
        "nepDate": "25.02.2088"
    },
    {
        "engDate": "2031-06-09",
        "nepDate": "26.02.2088"
    },
    {
        "engDate": "2031-06-10",
        "nepDate": "27.02.2088"
    },
    {
        "engDate": "2031-06-11",
        "nepDate": "28.02.2088"
    },
    {
        "engDate": "2031-06-12",
        "nepDate": "29.02.2088"
    },
    {
        "engDate": "2031-06-13",
        "nepDate": "30.02.2088"
    },
    {
        "engDate": "2031-06-14",
        "nepDate": "31.02.2088"
    },
    {
        "engDate": "2031-06-15",
        "nepDate": "01.03.2088"
    },
    {
        "engDate": "2031-06-16",
        "nepDate": "02.03.2088"
    },
    {
        "engDate": "2031-06-17",
        "nepDate": "03.03.2088"
    },
    {
        "engDate": "2031-06-18",
        "nepDate": "04.03.2088"
    },
    {
        "engDate": "2031-06-19",
        "nepDate": "05.03.2088"
    },
    {
        "engDate": "2031-06-20",
        "nepDate": "06.03.2088"
    },
    {
        "engDate": "2031-06-21",
        "nepDate": "07.03.2088"
    },
    {
        "engDate": "2031-06-22",
        "nepDate": "08.03.2088"
    },
    {
        "engDate": "2031-06-23",
        "nepDate": "09.03.2088"
    },
    {
        "engDate": "2031-06-24",
        "nepDate": "10.03.2088"
    },
    {
        "engDate": "2031-06-25",
        "nepDate": "11.03.2088"
    },
    {
        "engDate": "2031-06-26",
        "nepDate": "12.03.2088"
    },
    {
        "engDate": "2031-06-27",
        "nepDate": "13.03.2088"
    },
    {
        "engDate": "2031-06-28",
        "nepDate": "14.03.2088"
    },
    {
        "engDate": "2031-06-29",
        "nepDate": "15.03.2088"
    },
    {
        "engDate": "2031-06-30",
        "nepDate": "16.03.2088"
    },
    {
        "engDate": "2031-07-01",
        "nepDate": "17.03.2088"
    },
    {
        "engDate": "2031-07-02",
        "nepDate": "18.03.2088"
    },
    {
        "engDate": "2031-07-03",
        "nepDate": "19.03.2088"
    },
    {
        "engDate": "2031-07-04",
        "nepDate": "20.03.2088"
    },
    {
        "engDate": "2031-07-05",
        "nepDate": "21.03.2088"
    },
    {
        "engDate": "2031-07-06",
        "nepDate": "22.03.2088"
    },
    {
        "engDate": "2031-07-07",
        "nepDate": "23.03.2088"
    },
    {
        "engDate": "2031-07-08",
        "nepDate": "24.03.2088"
    },
    {
        "engDate": "2031-07-09",
        "nepDate": "25.03.2088"
    },
    {
        "engDate": "2031-07-10",
        "nepDate": "26.03.2088"
    },
    {
        "engDate": "2031-07-11",
        "nepDate": "27.03.2088"
    },
    {
        "engDate": "2031-07-12",
        "nepDate": "28.03.2088"
    },
    {
        "engDate": "2031-07-13",
        "nepDate": "29.03.2088"
    },
    {
        "engDate": "2031-07-14",
        "nepDate": "30.03.2088"
    },
    {
        "engDate": "2031-07-15",
        "nepDate": "31.03.2088"
    },
    {
        "engDate": "2031-07-16",
        "nepDate": "32.03.2088"
    },
    {
        "engDate": "2031-07-17",
        "nepDate": "01.04.2088"
    },
    {
        "engDate": "2031-07-18",
        "nepDate": "02.04.2088"
    },
    {
        "engDate": "2031-07-19",
        "nepDate": "03.04.2088"
    },
    {
        "engDate": "2031-07-20",
        "nepDate": "04.04.2088"
    },
    {
        "engDate": "2031-07-21",
        "nepDate": "05.04.2088"
    },
    {
        "engDate": "2031-07-22",
        "nepDate": "06.04.2088"
    },
    {
        "engDate": "2031-07-23",
        "nepDate": "07.04.2088"
    },
    {
        "engDate": "2031-07-24",
        "nepDate": "08.04.2088"
    },
    {
        "engDate": "2031-07-25",
        "nepDate": "09.04.2088"
    },
    {
        "engDate": "2031-07-26",
        "nepDate": "10.04.2088"
    },
    {
        "engDate": "2031-07-27",
        "nepDate": "11.04.2088"
    },
    {
        "engDate": "2031-07-28",
        "nepDate": "12.04.2088"
    },
    {
        "engDate": "2031-07-29",
        "nepDate": "13.04.2088"
    },
    {
        "engDate": "2031-07-30",
        "nepDate": "14.04.2088"
    },
    {
        "engDate": "2031-07-31",
        "nepDate": "15.04.2088"
    },
    {
        "engDate": "2031-08-01",
        "nepDate": "16.04.2088"
    },
    {
        "engDate": "2031-08-02",
        "nepDate": "17.04.2088"
    },
    {
        "engDate": "2031-08-03",
        "nepDate": "18.04.2088"
    },
    {
        "engDate": "2031-08-04",
        "nepDate": "19.04.2088"
    },
    {
        "engDate": "2031-08-05",
        "nepDate": "20.04.2088"
    },
    {
        "engDate": "2031-08-06",
        "nepDate": "21.04.2088"
    },
    {
        "engDate": "2031-08-07",
        "nepDate": "22.04.2088"
    },
    {
        "engDate": "2031-08-08",
        "nepDate": "23.04.2088"
    },
    {
        "engDate": "2031-08-09",
        "nepDate": "24.04.2088"
    },
    {
        "engDate": "2031-08-10",
        "nepDate": "25.04.2088"
    },
    {
        "engDate": "2031-08-11",
        "nepDate": "26.04.2088"
    },
    {
        "engDate": "2031-08-12",
        "nepDate": "27.04.2088"
    },
    {
        "engDate": "2031-08-13",
        "nepDate": "28.04.2088"
    },
    {
        "engDate": "2031-08-14",
        "nepDate": "29.04.2088"
    },
    {
        "engDate": "2031-08-15",
        "nepDate": "30.04.2088"
    },
    {
        "engDate": "2031-08-16",
        "nepDate": "31.04.2088"
    },
    {
        "engDate": "2031-08-17",
        "nepDate": "32.04.2088"
    },
    {
        "engDate": "2031-08-18",
        "nepDate": "01.05.2088"
    },
    {
        "engDate": "2031-08-19",
        "nepDate": "02.05.2088"
    },
    {
        "engDate": "2031-08-20",
        "nepDate": "03.05.2088"
    },
    {
        "engDate": "2031-08-21",
        "nepDate": "04.05.2088"
    },
    {
        "engDate": "2031-08-22",
        "nepDate": "05.05.2088"
    },
    {
        "engDate": "2031-08-23",
        "nepDate": "06.05.2088"
    },
    {
        "engDate": "2031-08-24",
        "nepDate": "07.05.2088"
    },
    {
        "engDate": "2031-08-25",
        "nepDate": "08.05.2088"
    },
    {
        "engDate": "2031-08-26",
        "nepDate": "09.05.2088"
    },
    {
        "engDate": "2031-08-27",
        "nepDate": "10.05.2088"
    },
    {
        "engDate": "2031-08-28",
        "nepDate": "11.05.2088"
    },
    {
        "engDate": "2031-08-29",
        "nepDate": "12.05.2088"
    },
    {
        "engDate": "2031-08-30",
        "nepDate": "13.05.2088"
    },
    {
        "engDate": "2031-08-31",
        "nepDate": "14.05.2088"
    },
    {
        "engDate": "2031-09-01",
        "nepDate": "15.05.2088"
    },
    {
        "engDate": "2031-09-02",
        "nepDate": "16.05.2088"
    },
    {
        "engDate": "2031-09-03",
        "nepDate": "17.05.2088"
    },
    {
        "engDate": "2031-09-04",
        "nepDate": "18.05.2088"
    },
    {
        "engDate": "2031-09-05",
        "nepDate": "19.05.2088"
    },
    {
        "engDate": "2031-09-06",
        "nepDate": "20.05.2088"
    },
    {
        "engDate": "2031-09-07",
        "nepDate": "21.05.2088"
    },
    {
        "engDate": "2031-09-08",
        "nepDate": "22.05.2088"
    },
    {
        "engDate": "2031-09-09",
        "nepDate": "23.05.2088"
    },
    {
        "engDate": "2031-09-10",
        "nepDate": "24.05.2088"
    },
    {
        "engDate": "2031-09-11",
        "nepDate": "25.05.2088"
    },
    {
        "engDate": "2031-09-12",
        "nepDate": "26.05.2088"
    },
    {
        "engDate": "2031-09-13",
        "nepDate": "27.05.2088"
    },
    {
        "engDate": "2031-09-14",
        "nepDate": "28.05.2088"
    },
    {
        "engDate": "2031-09-15",
        "nepDate": "29.05.2088"
    },
    {
        "engDate": "2031-09-16",
        "nepDate": "30.05.2088"
    },
    {
        "engDate": "2031-09-17",
        "nepDate": "01.06.2088"
    },
    {
        "engDate": "2031-09-18",
        "nepDate": "02.06.2088"
    },
    {
        "engDate": "2031-09-19",
        "nepDate": "03.06.2088"
    },
    {
        "engDate": "2031-09-20",
        "nepDate": "04.06.2088"
    },
    {
        "engDate": "2031-09-21",
        "nepDate": "05.06.2088"
    },
    {
        "engDate": "2031-09-22",
        "nepDate": "06.06.2088"
    },
    {
        "engDate": "2031-09-23",
        "nepDate": "07.06.2088"
    },
    {
        "engDate": "2031-09-24",
        "nepDate": "08.06.2088"
    },
    {
        "engDate": "2031-09-25",
        "nepDate": "09.06.2088"
    },
    {
        "engDate": "2031-09-26",
        "nepDate": "10.06.2088"
    },
    {
        "engDate": "2031-09-27",
        "nepDate": "11.06.2088"
    },
    {
        "engDate": "2031-09-28",
        "nepDate": "12.06.2088"
    },
    {
        "engDate": "2031-09-29",
        "nepDate": "13.06.2088"
    },
    {
        "engDate": "2031-09-30",
        "nepDate": "14.06.2088"
    },
    {
        "engDate": "2031-10-01",
        "nepDate": "15.06.2088"
    },
    {
        "engDate": "2031-10-02",
        "nepDate": "16.06.2088"
    },
    {
        "engDate": "2031-10-03",
        "nepDate": "17.06.2088"
    },
    {
        "engDate": "2031-10-04",
        "nepDate": "18.06.2088"
    },
    {
        "engDate": "2031-10-05",
        "nepDate": "19.06.2088"
    },
    {
        "engDate": "2031-10-06",
        "nepDate": "20.06.2088"
    },
    {
        "engDate": "2031-10-07",
        "nepDate": "21.06.2088"
    },
    {
        "engDate": "2031-10-08",
        "nepDate": "22.06.2088"
    },
    {
        "engDate": "2031-10-09",
        "nepDate": "23.06.2088"
    },
    {
        "engDate": "2031-10-10",
        "nepDate": "24.06.2088"
    },
    {
        "engDate": "2031-10-11",
        "nepDate": "25.06.2088"
    },
    {
        "engDate": "2031-10-12",
        "nepDate": "26.06.2088"
    },
    {
        "engDate": "2031-10-13",
        "nepDate": "27.06.2088"
    },
    {
        "engDate": "2031-10-14",
        "nepDate": "28.06.2088"
    },
    {
        "engDate": "2031-10-15",
        "nepDate": "29.06.2088"
    },
    {
        "engDate": "2031-10-16",
        "nepDate": "30.06.2088"
    },
    {
        "engDate": "2031-10-17",
        "nepDate": "31.06.2088"
    },
    {
        "engDate": "2031-10-18",
        "nepDate": "01.07.2088"
    },
    {
        "engDate": "2031-10-19",
        "nepDate": "02.07.2088"
    },
    {
        "engDate": "2031-10-20",
        "nepDate": "03.07.2088"
    },
    {
        "engDate": "2031-10-21",
        "nepDate": "04.07.2088"
    },
    {
        "engDate": "2031-10-22",
        "nepDate": "05.07.2088"
    },
    {
        "engDate": "2031-10-23",
        "nepDate": "06.07.2088"
    },
    {
        "engDate": "2031-10-24",
        "nepDate": "07.07.2088"
    },
    {
        "engDate": "2031-10-25",
        "nepDate": "08.07.2088"
    },
    {
        "engDate": "2031-10-26",
        "nepDate": "09.07.2088"
    },
    {
        "engDate": "2031-10-27",
        "nepDate": "10.07.2088"
    },
    {
        "engDate": "2031-10-28",
        "nepDate": "11.07.2088"
    },
    {
        "engDate": "2031-10-29",
        "nepDate": "12.07.2088"
    },
    {
        "engDate": "2031-10-30",
        "nepDate": "13.07.2088"
    },
    {
        "engDate": "2031-10-31",
        "nepDate": "14.07.2088"
    },
    {
        "engDate": "2031-11-01",
        "nepDate": "15.07.2088"
    },
    {
        "engDate": "2031-11-02",
        "nepDate": "16.07.2088"
    },
    {
        "engDate": "2031-11-03",
        "nepDate": "17.07.2088"
    },
    {
        "engDate": "2031-11-04",
        "nepDate": "18.07.2088"
    },
    {
        "engDate": "2031-11-05",
        "nepDate": "19.07.2088"
    },
    {
        "engDate": "2031-11-06",
        "nepDate": "20.07.2088"
    },
    {
        "engDate": "2031-11-07",
        "nepDate": "21.07.2088"
    },
    {
        "engDate": "2031-11-08",
        "nepDate": "22.07.2088"
    },
    {
        "engDate": "2031-11-09",
        "nepDate": "23.07.2088"
    },
    {
        "engDate": "2031-11-10",
        "nepDate": "24.07.2088"
    },
    {
        "engDate": "2031-11-11",
        "nepDate": "25.07.2088"
    },
    {
        "engDate": "2031-11-12",
        "nepDate": "26.07.2088"
    },
    {
        "engDate": "2031-11-13",
        "nepDate": "27.07.2088"
    },
    {
        "engDate": "2031-11-14",
        "nepDate": "28.07.2088"
    },
    {
        "engDate": "2031-11-15",
        "nepDate": "29.07.2088"
    },
    {
        "engDate": "2031-11-16",
        "nepDate": "30.07.2088"
    },
    {
        "engDate": "2031-11-17",
        "nepDate": "01.08.2088"
    },
    {
        "engDate": "2031-11-18",
        "nepDate": "02.08.2088"
    },
    {
        "engDate": "2031-11-19",
        "nepDate": "03.08.2088"
    },
    {
        "engDate": "2031-11-20",
        "nepDate": "04.08.2088"
    },
    {
        "engDate": "2031-11-21",
        "nepDate": "05.08.2088"
    },
    {
        "engDate": "2031-11-22",
        "nepDate": "06.08.2088"
    },
    {
        "engDate": "2031-11-23",
        "nepDate": "07.08.2088"
    },
    {
        "engDate": "2031-11-24",
        "nepDate": "08.08.2088"
    },
    {
        "engDate": "2031-11-25",
        "nepDate": "09.08.2088"
    },
    {
        "engDate": "2031-11-26",
        "nepDate": "10.08.2088"
    },
    {
        "engDate": "2031-11-27",
        "nepDate": "11.08.2088"
    },
    {
        "engDate": "2031-11-28",
        "nepDate": "12.08.2088"
    },
    {
        "engDate": "2031-11-29",
        "nepDate": "13.08.2088"
    },
    {
        "engDate": "2031-11-30",
        "nepDate": "14.08.2088"
    },
    {
        "engDate": "2031-12-01",
        "nepDate": "15.08.2088"
    },
    {
        "engDate": "2031-12-02",
        "nepDate": "16.08.2088"
    },
    {
        "engDate": "2031-12-03",
        "nepDate": "17.08.2088"
    },
    {
        "engDate": "2031-12-04",
        "nepDate": "18.08.2088"
    },
    {
        "engDate": "2031-12-05",
        "nepDate": "19.08.2088"
    },
    {
        "engDate": "2031-12-06",
        "nepDate": "20.08.2088"
    },
    {
        "engDate": "2031-12-07",
        "nepDate": "21.08.2088"
    },
    {
        "engDate": "2031-12-08",
        "nepDate": "22.08.2088"
    },
    {
        "engDate": "2031-12-09",
        "nepDate": "23.08.2088"
    },
    {
        "engDate": "2031-12-10",
        "nepDate": "24.08.2088"
    },
    {
        "engDate": "2031-12-11",
        "nepDate": "25.08.2088"
    },
    {
        "engDate": "2031-12-12",
        "nepDate": "26.08.2088"
    },
    {
        "engDate": "2031-12-13",
        "nepDate": "27.08.2088"
    },
    {
        "engDate": "2031-12-14",
        "nepDate": "28.08.2088"
    },
    {
        "engDate": "2031-12-15",
        "nepDate": "29.08.2088"
    },
    {
        "engDate": "2031-12-16",
        "nepDate": "30.08.2088"
    },
    {
        "engDate": "2031-12-17",
        "nepDate": "01.09.2088"
    },
    {
        "engDate": "2031-12-18",
        "nepDate": "02.09.2088"
    },
    {
        "engDate": "2031-12-19",
        "nepDate": "03.09.2088"
    },
    {
        "engDate": "2031-12-20",
        "nepDate": "04.09.2088"
    },
    {
        "engDate": "2031-12-21",
        "nepDate": "05.09.2088"
    },
    {
        "engDate": "2031-12-22",
        "nepDate": "06.09.2088"
    },
    {
        "engDate": "2031-12-23",
        "nepDate": "07.09.2088"
    },
    {
        "engDate": "2031-12-24",
        "nepDate": "08.09.2088"
    },
    {
        "engDate": "2031-12-25",
        "nepDate": "09.09.2088"
    },
    {
        "engDate": "2031-12-26",
        "nepDate": "10.09.2088"
    },
    {
        "engDate": "2031-12-27",
        "nepDate": "11.09.2088"
    },
    {
        "engDate": "2031-12-28",
        "nepDate": "12.09.2088"
    },
    {
        "engDate": "2031-12-29",
        "nepDate": "13.09.2088"
    },
    {
        "engDate": "2031-12-30",
        "nepDate": "14.09.2088"
    },
    {
        "engDate": "2031-12-31",
        "nepDate": "15.09.2088"
    },
    {
        "engDate": "2032-01-01",
        "nepDate": "16.09.2088"
    },
    {
        "engDate": "2032-01-02",
        "nepDate": "17.09.2088"
    },
    {
        "engDate": "2032-01-03",
        "nepDate": "18.09.2088"
    },
    {
        "engDate": "2032-01-04",
        "nepDate": "19.09.2088"
    },
    {
        "engDate": "2032-01-05",
        "nepDate": "20.09.2088"
    },
    {
        "engDate": "2032-01-06",
        "nepDate": "21.09.2088"
    },
    {
        "engDate": "2032-01-07",
        "nepDate": "22.09.2088"
    },
    {
        "engDate": "2032-01-08",
        "nepDate": "23.09.2088"
    },
    {
        "engDate": "2032-01-09",
        "nepDate": "24.09.2088"
    },
    {
        "engDate": "2032-01-10",
        "nepDate": "25.09.2088"
    },
    {
        "engDate": "2032-01-11",
        "nepDate": "26.09.2088"
    },
    {
        "engDate": "2032-01-12",
        "nepDate": "27.09.2088"
    },
    {
        "engDate": "2032-01-13",
        "nepDate": "28.09.2088"
    },
    {
        "engDate": "2032-01-14",
        "nepDate": "29.09.2088"
    },
    {
        "engDate": "2032-01-15",
        "nepDate": "01.10.2088"
    },
    {
        "engDate": "2032-01-16",
        "nepDate": "02.10.2088"
    },
    {
        "engDate": "2032-01-17",
        "nepDate": "03.10.2088"
    },
    {
        "engDate": "2032-01-18",
        "nepDate": "04.10.2088"
    },
    {
        "engDate": "2032-01-19",
        "nepDate": "05.10.2088"
    },
    {
        "engDate": "2032-01-20",
        "nepDate": "06.10.2088"
    },
    {
        "engDate": "2032-01-21",
        "nepDate": "07.10.2088"
    },
    {
        "engDate": "2032-01-22",
        "nepDate": "08.10.2088"
    },
    {
        "engDate": "2032-01-23",
        "nepDate": "09.10.2088"
    },
    {
        "engDate": "2032-01-24",
        "nepDate": "10.10.2088"
    },
    {
        "engDate": "2032-01-25",
        "nepDate": "11.10.2088"
    },
    {
        "engDate": "2032-01-26",
        "nepDate": "12.10.2088"
    },
    {
        "engDate": "2032-01-27",
        "nepDate": "13.10.2088"
    },
    {
        "engDate": "2032-01-28",
        "nepDate": "14.10.2088"
    },
    {
        "engDate": "2032-01-29",
        "nepDate": "15.10.2088"
    },
    {
        "engDate": "2032-01-30",
        "nepDate": "16.10.2088"
    },
    {
        "engDate": "2032-01-31",
        "nepDate": "17.10.2088"
    },
    {
        "engDate": "2032-02-01",
        "nepDate": "18.10.2088"
    },
    {
        "engDate": "2032-02-02",
        "nepDate": "19.10.2088"
    },
    {
        "engDate": "2032-02-03",
        "nepDate": "20.10.2088"
    },
    {
        "engDate": "2032-02-04",
        "nepDate": "21.10.2088"
    },
    {
        "engDate": "2032-02-05",
        "nepDate": "22.10.2088"
    },
    {
        "engDate": "2032-02-06",
        "nepDate": "23.10.2088"
    },
    {
        "engDate": "2032-02-07",
        "nepDate": "24.10.2088"
    },
    {
        "engDate": "2032-02-08",
        "nepDate": "25.10.2088"
    },
    {
        "engDate": "2032-02-09",
        "nepDate": "26.10.2088"
    },
    {
        "engDate": "2032-02-10",
        "nepDate": "27.10.2088"
    },
    {
        "engDate": "2032-02-11",
        "nepDate": "28.10.2088"
    },
    {
        "engDate": "2032-02-12",
        "nepDate": "29.10.2088"
    },
    {
        "engDate": "2032-02-13",
        "nepDate": "30.10.2088"
    },
    {
        "engDate": "2032-02-14",
        "nepDate": "01.11.2088"
    },
    {
        "engDate": "2032-02-15",
        "nepDate": "02.11.2088"
    },
    {
        "engDate": "2032-02-16",
        "nepDate": "03.11.2088"
    },
    {
        "engDate": "2032-02-17",
        "nepDate": "04.11.2088"
    },
    {
        "engDate": "2032-02-18",
        "nepDate": "05.11.2088"
    },
    {
        "engDate": "2032-02-19",
        "nepDate": "06.11.2088"
    },
    {
        "engDate": "2032-02-20",
        "nepDate": "07.11.2088"
    },
    {
        "engDate": "2032-02-21",
        "nepDate": "08.11.2088"
    },
    {
        "engDate": "2032-02-22",
        "nepDate": "09.11.2088"
    },
    {
        "engDate": "2032-02-23",
        "nepDate": "10.11.2088"
    },
    {
        "engDate": "2032-02-24",
        "nepDate": "11.11.2088"
    },
    {
        "engDate": "2032-02-25",
        "nepDate": "12.11.2088"
    },
    {
        "engDate": "2032-02-26",
        "nepDate": "13.11.2088"
    },
    {
        "engDate": "2032-02-27",
        "nepDate": "14.11.2088"
    },
    {
        "engDate": "2032-02-28",
        "nepDate": "15.11.2088"
    },
    {
        "engDate": "2032-02-29",
        "nepDate": "16.11.2088"
    },
    {
        "engDate": "2032-03-01",
        "nepDate": "17.11.2088"
    },
    {
        "engDate": "2032-03-02",
        "nepDate": "18.11.2088"
    },
    {
        "engDate": "2032-03-03",
        "nepDate": "19.11.2088"
    },
    {
        "engDate": "2032-03-04",
        "nepDate": "20.11.2088"
    },
    {
        "engDate": "2032-03-05",
        "nepDate": "21.11.2088"
    },
    {
        "engDate": "2032-03-06",
        "nepDate": "22.11.2088"
    },
    {
        "engDate": "2032-03-07",
        "nepDate": "23.11.2088"
    },
    {
        "engDate": "2032-03-08",
        "nepDate": "24.11.2088"
    },
    {
        "engDate": "2032-03-09",
        "nepDate": "25.11.2088"
    },
    {
        "engDate": "2032-03-10",
        "nepDate": "26.11.2088"
    },
    {
        "engDate": "2032-03-11",
        "nepDate": "27.11.2088"
    },
    {
        "engDate": "2032-03-12",
        "nepDate": "28.11.2088"
    },
    {
        "engDate": "2032-03-13",
        "nepDate": "29.11.2088"
    },
    {
        "engDate": "2032-03-14",
        "nepDate": "30.11.2088"
    },
    {
        "engDate": "2032-03-15",
        "nepDate": "01.12.2088"
    },
    {
        "engDate": "2032-03-16",
        "nepDate": "02.12.2088"
    },
    {
        "engDate": "2032-03-17",
        "nepDate": "03.12.2088"
    },
    {
        "engDate": "2032-03-18",
        "nepDate": "04.12.2088"
    },
    {
        "engDate": "2032-03-19",
        "nepDate": "05.12.2088"
    },
    {
        "engDate": "2032-03-20",
        "nepDate": "06.12.2088"
    },
    {
        "engDate": "2032-03-21",
        "nepDate": "07.12.2088"
    },
    {
        "engDate": "2032-03-22",
        "nepDate": "08.12.2088"
    },
    {
        "engDate": "2032-03-23",
        "nepDate": "09.12.2088"
    },
    {
        "engDate": "2032-03-24",
        "nepDate": "10.12.2088"
    },
    {
        "engDate": "2032-03-25",
        "nepDate": "11.12.2088"
    },
    {
        "engDate": "2032-03-26",
        "nepDate": "12.12.2088"
    },
    {
        "engDate": "2032-03-27",
        "nepDate": "13.12.2088"
    },
    {
        "engDate": "2032-03-28",
        "nepDate": "14.12.2088"
    },
    {
        "engDate": "2032-03-29",
        "nepDate": "15.12.2088"
    },
    {
        "engDate": "2032-03-30",
        "nepDate": "16.12.2088"
    },
    {
        "engDate": "2032-03-31",
        "nepDate": "17.12.2088"
    },
    {
        "engDate": "2032-04-01",
        "nepDate": "18.12.2088"
    },
    {
        "engDate": "2032-04-02",
        "nepDate": "19.12.2088"
    },
    {
        "engDate": "2032-04-03",
        "nepDate": "20.12.2088"
    },
    {
        "engDate": "2032-04-04",
        "nepDate": "21.12.2088"
    },
    {
        "engDate": "2032-04-05",
        "nepDate": "22.12.2088"
    },
    {
        "engDate": "2032-04-06",
        "nepDate": "23.12.2088"
    },
    {
        "engDate": "2032-04-07",
        "nepDate": "24.12.2088"
    },
    {
        "engDate": "2032-04-08",
        "nepDate": "25.12.2088"
    },
    {
        "engDate": "2032-04-09",
        "nepDate": "26.12.2088"
    },
    {
        "engDate": "2032-04-10",
        "nepDate": "27.12.2088"
    },
    {
        "engDate": "2032-04-11",
        "nepDate": "28.12.2088"
    },
    {
        "engDate": "2032-04-12",
        "nepDate": "29.12.2088"
    },
    {
        "engDate": "2032-04-13",
        "nepDate": "30.12.2088"
    },
    {
        "engDate": "2032-04-14",
        "nepDate": "01.01.2089"
    },
    {
        "engDate": "2032-04-15",
        "nepDate": "02.01.2089"
    },
    {
        "engDate": "2032-04-16",
        "nepDate": "03.01.2089"
    },
    {
        "engDate": "2032-04-17",
        "nepDate": "04.01.2089"
    },
    {
        "engDate": "2032-04-18",
        "nepDate": "05.01.2089"
    },
    {
        "engDate": "2032-04-19",
        "nepDate": "06.01.2089"
    },
    {
        "engDate": "2032-04-20",
        "nepDate": "07.01.2089"
    },
    {
        "engDate": "2032-04-21",
        "nepDate": "08.01.2089"
    },
    {
        "engDate": "2032-04-22",
        "nepDate": "09.01.2089"
    },
    {
        "engDate": "2032-04-23",
        "nepDate": "10.01.2089"
    },
    {
        "engDate": "2032-04-24",
        "nepDate": "11.01.2089"
    },
    {
        "engDate": "2032-04-25",
        "nepDate": "12.01.2089"
    },
    {
        "engDate": "2032-04-26",
        "nepDate": "13.01.2089"
    },
    {
        "engDate": "2032-04-27",
        "nepDate": "14.01.2089"
    },
    {
        "engDate": "2032-04-28",
        "nepDate": "15.01.2089"
    },
    {
        "engDate": "2032-04-29",
        "nepDate": "16.01.2089"
    },
    {
        "engDate": "2032-04-30",
        "nepDate": "17.01.2089"
    },
    {
        "engDate": "2032-05-01",
        "nepDate": "18.01.2089"
    },
    {
        "engDate": "2032-05-02",
        "nepDate": "19.01.2089"
    },
    {
        "engDate": "2032-05-03",
        "nepDate": "20.01.2089"
    },
    {
        "engDate": "2032-05-04",
        "nepDate": "21.01.2089"
    },
    {
        "engDate": "2032-05-05",
        "nepDate": "22.01.2089"
    },
    {
        "engDate": "2032-05-06",
        "nepDate": "23.01.2089"
    },
    {
        "engDate": "2032-05-07",
        "nepDate": "24.01.2089"
    },
    {
        "engDate": "2032-05-08",
        "nepDate": "25.01.2089"
    },
    {
        "engDate": "2032-05-09",
        "nepDate": "26.01.2089"
    },
    {
        "engDate": "2032-05-10",
        "nepDate": "27.01.2089"
    },
    {
        "engDate": "2032-05-11",
        "nepDate": "28.01.2089"
    },
    {
        "engDate": "2032-05-12",
        "nepDate": "29.01.2089"
    },
    {
        "engDate": "2032-05-13",
        "nepDate": "30.01.2089"
    },
    {
        "engDate": "2032-05-14",
        "nepDate": "01.02.2089"
    },
    {
        "engDate": "2032-05-15",
        "nepDate": "02.02.2089"
    },
    {
        "engDate": "2032-05-16",
        "nepDate": "03.02.2089"
    },
    {
        "engDate": "2032-05-17",
        "nepDate": "04.02.2089"
    },
    {
        "engDate": "2032-05-18",
        "nepDate": "05.02.2089"
    },
    {
        "engDate": "2032-05-19",
        "nepDate": "06.02.2089"
    },
    {
        "engDate": "2032-05-20",
        "nepDate": "07.02.2089"
    },
    {
        "engDate": "2032-05-21",
        "nepDate": "08.02.2089"
    },
    {
        "engDate": "2032-05-22",
        "nepDate": "09.02.2089"
    },
    {
        "engDate": "2032-05-23",
        "nepDate": "10.02.2089"
    },
    {
        "engDate": "2032-05-24",
        "nepDate": "11.02.2089"
    },
    {
        "engDate": "2032-05-25",
        "nepDate": "12.02.2089"
    },
    {
        "engDate": "2032-05-26",
        "nepDate": "13.02.2089"
    },
    {
        "engDate": "2032-05-27",
        "nepDate": "14.02.2089"
    },
    {
        "engDate": "2032-05-28",
        "nepDate": "15.02.2089"
    },
    {
        "engDate": "2032-05-29",
        "nepDate": "16.02.2089"
    },
    {
        "engDate": "2032-05-30",
        "nepDate": "17.02.2089"
    },
    {
        "engDate": "2032-05-31",
        "nepDate": "18.02.2089"
    },
    {
        "engDate": "2032-06-01",
        "nepDate": "19.02.2089"
    },
    {
        "engDate": "2032-06-02",
        "nepDate": "20.02.2089"
    },
    {
        "engDate": "2032-06-03",
        "nepDate": "21.02.2089"
    },
    {
        "engDate": "2032-06-04",
        "nepDate": "22.02.2089"
    },
    {
        "engDate": "2032-06-05",
        "nepDate": "23.02.2089"
    },
    {
        "engDate": "2032-06-06",
        "nepDate": "24.02.2089"
    },
    {
        "engDate": "2032-06-07",
        "nepDate": "25.02.2089"
    },
    {
        "engDate": "2032-06-08",
        "nepDate": "26.02.2089"
    },
    {
        "engDate": "2032-06-09",
        "nepDate": "27.02.2089"
    },
    {
        "engDate": "2032-06-10",
        "nepDate": "28.02.2089"
    },
    {
        "engDate": "2032-06-11",
        "nepDate": "29.02.2089"
    },
    {
        "engDate": "2032-06-12",
        "nepDate": "30.02.2089"
    },
    {
        "engDate": "2032-06-13",
        "nepDate": "31.02.2089"
    },
    {
        "engDate": "2032-06-14",
        "nepDate": "32.02.2089"
    },
    {
        "engDate": "2032-06-15",
        "nepDate": "01.03.2089"
    },
    {
        "engDate": "2032-06-16",
        "nepDate": "02.03.2089"
    },
    {
        "engDate": "2032-06-17",
        "nepDate": "03.03.2089"
    },
    {
        "engDate": "2032-06-18",
        "nepDate": "04.03.2089"
    },
    {
        "engDate": "2032-06-19",
        "nepDate": "05.03.2089"
    },
    {
        "engDate": "2032-06-20",
        "nepDate": "06.03.2089"
    },
    {
        "engDate": "2032-06-21",
        "nepDate": "07.03.2089"
    },
    {
        "engDate": "2032-06-22",
        "nepDate": "08.03.2089"
    },
    {
        "engDate": "2032-06-23",
        "nepDate": "09.03.2089"
    },
    {
        "engDate": "2032-06-24",
        "nepDate": "10.03.2089"
    },
    {
        "engDate": "2032-06-25",
        "nepDate": "11.03.2089"
    },
    {
        "engDate": "2032-06-26",
        "nepDate": "12.03.2089"
    },
    {
        "engDate": "2032-06-27",
        "nepDate": "13.03.2089"
    },
    {
        "engDate": "2032-06-28",
        "nepDate": "14.03.2089"
    },
    {
        "engDate": "2032-06-29",
        "nepDate": "15.03.2089"
    },
    {
        "engDate": "2032-06-30",
        "nepDate": "16.03.2089"
    },
    {
        "engDate": "2032-07-01",
        "nepDate": "17.03.2089"
    },
    {
        "engDate": "2032-07-02",
        "nepDate": "18.03.2089"
    },
    {
        "engDate": "2032-07-03",
        "nepDate": "19.03.2089"
    },
    {
        "engDate": "2032-07-04",
        "nepDate": "20.03.2089"
    },
    {
        "engDate": "2032-07-05",
        "nepDate": "21.03.2089"
    },
    {
        "engDate": "2032-07-06",
        "nepDate": "22.03.2089"
    },
    {
        "engDate": "2032-07-07",
        "nepDate": "23.03.2089"
    },
    {
        "engDate": "2032-07-08",
        "nepDate": "24.03.2089"
    },
    {
        "engDate": "2032-07-09",
        "nepDate": "25.03.2089"
    },
    {
        "engDate": "2032-07-10",
        "nepDate": "26.03.2089"
    },
    {
        "engDate": "2032-07-11",
        "nepDate": "27.03.2089"
    },
    {
        "engDate": "2032-07-12",
        "nepDate": "28.03.2089"
    },
    {
        "engDate": "2032-07-13",
        "nepDate": "29.03.2089"
    },
    {
        "engDate": "2032-07-14",
        "nepDate": "30.03.2089"
    },
    {
        "engDate": "2032-07-15",
        "nepDate": "31.03.2089"
    },
    {
        "engDate": "2032-07-16",
        "nepDate": "01.04.2089"
    },
    {
        "engDate": "2032-07-17",
        "nepDate": "02.04.2089"
    },
    {
        "engDate": "2032-07-18",
        "nepDate": "03.04.2089"
    },
    {
        "engDate": "2032-07-19",
        "nepDate": "04.04.2089"
    },
    {
        "engDate": "2032-07-20",
        "nepDate": "05.04.2089"
    },
    {
        "engDate": "2032-07-21",
        "nepDate": "06.04.2089"
    },
    {
        "engDate": "2032-07-22",
        "nepDate": "07.04.2089"
    },
    {
        "engDate": "2032-07-23",
        "nepDate": "08.04.2089"
    },
    {
        "engDate": "2032-07-24",
        "nepDate": "09.04.2089"
    },
    {
        "engDate": "2032-07-25",
        "nepDate": "10.04.2089"
    },
    {
        "engDate": "2032-07-26",
        "nepDate": "11.04.2089"
    },
    {
        "engDate": "2032-07-27",
        "nepDate": "12.04.2089"
    },
    {
        "engDate": "2032-07-28",
        "nepDate": "13.04.2089"
    },
    {
        "engDate": "2032-07-29",
        "nepDate": "14.04.2089"
    },
    {
        "engDate": "2032-07-30",
        "nepDate": "15.04.2089"
    },
    {
        "engDate": "2032-07-31",
        "nepDate": "16.04.2089"
    },
    {
        "engDate": "2032-08-01",
        "nepDate": "17.04.2089"
    },
    {
        "engDate": "2032-08-02",
        "nepDate": "18.04.2089"
    },
    {
        "engDate": "2032-08-03",
        "nepDate": "19.04.2089"
    },
    {
        "engDate": "2032-08-04",
        "nepDate": "20.04.2089"
    },
    {
        "engDate": "2032-08-05",
        "nepDate": "21.04.2089"
    },
    {
        "engDate": "2032-08-06",
        "nepDate": "22.04.2089"
    },
    {
        "engDate": "2032-08-07",
        "nepDate": "23.04.2089"
    },
    {
        "engDate": "2032-08-08",
        "nepDate": "24.04.2089"
    },
    {
        "engDate": "2032-08-09",
        "nepDate": "25.04.2089"
    },
    {
        "engDate": "2032-08-10",
        "nepDate": "26.04.2089"
    },
    {
        "engDate": "2032-08-11",
        "nepDate": "27.04.2089"
    },
    {
        "engDate": "2032-08-12",
        "nepDate": "28.04.2089"
    },
    {
        "engDate": "2032-08-13",
        "nepDate": "29.04.2089"
    },
    {
        "engDate": "2032-08-14",
        "nepDate": "30.04.2089"
    },
    {
        "engDate": "2032-08-15",
        "nepDate": "31.04.2089"
    },
    {
        "engDate": "2032-08-16",
        "nepDate": "32.04.2089"
    },
    {
        "engDate": "2032-08-17",
        "nepDate": "01.05.2089"
    },
    {
        "engDate": "2032-08-18",
        "nepDate": "02.05.2089"
    },
    {
        "engDate": "2032-08-19",
        "nepDate": "03.05.2089"
    },
    {
        "engDate": "2032-08-20",
        "nepDate": "04.05.2089"
    },
    {
        "engDate": "2032-08-21",
        "nepDate": "05.05.2089"
    },
    {
        "engDate": "2032-08-22",
        "nepDate": "06.05.2089"
    },
    {
        "engDate": "2032-08-23",
        "nepDate": "07.05.2089"
    },
    {
        "engDate": "2032-08-24",
        "nepDate": "08.05.2089"
    },
    {
        "engDate": "2032-08-25",
        "nepDate": "09.05.2089"
    },
    {
        "engDate": "2032-08-26",
        "nepDate": "10.05.2089"
    },
    {
        "engDate": "2032-08-27",
        "nepDate": "11.05.2089"
    },
    {
        "engDate": "2032-08-28",
        "nepDate": "12.05.2089"
    },
    {
        "engDate": "2032-08-29",
        "nepDate": "13.05.2089"
    },
    {
        "engDate": "2032-08-30",
        "nepDate": "14.05.2089"
    },
    {
        "engDate": "2032-08-31",
        "nepDate": "15.05.2089"
    },
    {
        "engDate": "2032-09-01",
        "nepDate": "16.05.2089"
    },
    {
        "engDate": "2032-09-02",
        "nepDate": "17.05.2089"
    },
    {
        "engDate": "2032-09-03",
        "nepDate": "18.05.2089"
    },
    {
        "engDate": "2032-09-04",
        "nepDate": "19.05.2089"
    },
    {
        "engDate": "2032-09-05",
        "nepDate": "20.05.2089"
    },
    {
        "engDate": "2032-09-06",
        "nepDate": "21.05.2089"
    },
    {
        "engDate": "2032-09-07",
        "nepDate": "22.05.2089"
    },
    {
        "engDate": "2032-09-08",
        "nepDate": "23.05.2089"
    },
    {
        "engDate": "2032-09-09",
        "nepDate": "24.05.2089"
    },
    {
        "engDate": "2032-09-10",
        "nepDate": "25.05.2089"
    },
    {
        "engDate": "2032-09-11",
        "nepDate": "26.05.2089"
    },
    {
        "engDate": "2032-09-12",
        "nepDate": "27.05.2089"
    },
    {
        "engDate": "2032-09-13",
        "nepDate": "28.05.2089"
    },
    {
        "engDate": "2032-09-14",
        "nepDate": "29.05.2089"
    },
    {
        "engDate": "2032-09-15",
        "nepDate": "30.05.2089"
    },
    {
        "engDate": "2032-09-16",
        "nepDate": "31.05.2089"
    },
    {
        "engDate": "2032-09-17",
        "nepDate": "01.06.2089"
    },
    {
        "engDate": "2032-09-18",
        "nepDate": "02.06.2089"
    },
    {
        "engDate": "2032-09-19",
        "nepDate": "03.06.2089"
    },
    {
        "engDate": "2032-09-20",
        "nepDate": "04.06.2089"
    },
    {
        "engDate": "2032-09-21",
        "nepDate": "05.06.2089"
    },
    {
        "engDate": "2032-09-22",
        "nepDate": "06.06.2089"
    },
    {
        "engDate": "2032-09-23",
        "nepDate": "07.06.2089"
    },
    {
        "engDate": "2032-09-24",
        "nepDate": "08.06.2089"
    },
    {
        "engDate": "2032-09-25",
        "nepDate": "09.06.2089"
    },
    {
        "engDate": "2032-09-26",
        "nepDate": "10.06.2089"
    },
    {
        "engDate": "2032-09-27",
        "nepDate": "11.06.2089"
    },
    {
        "engDate": "2032-09-28",
        "nepDate": "12.06.2089"
    },
    {
        "engDate": "2032-09-29",
        "nepDate": "13.06.2089"
    },
    {
        "engDate": "2032-09-30",
        "nepDate": "14.06.2089"
    },
    {
        "engDate": "2032-10-01",
        "nepDate": "15.06.2089"
    },
    {
        "engDate": "2032-10-02",
        "nepDate": "16.06.2089"
    },
    {
        "engDate": "2032-10-03",
        "nepDate": "17.06.2089"
    },
    {
        "engDate": "2032-10-04",
        "nepDate": "18.06.2089"
    },
    {
        "engDate": "2032-10-05",
        "nepDate": "19.06.2089"
    },
    {
        "engDate": "2032-10-06",
        "nepDate": "20.06.2089"
    },
    {
        "engDate": "2032-10-07",
        "nepDate": "21.06.2089"
    },
    {
        "engDate": "2032-10-08",
        "nepDate": "22.06.2089"
    },
    {
        "engDate": "2032-10-09",
        "nepDate": "23.06.2089"
    },
    {
        "engDate": "2032-10-10",
        "nepDate": "24.06.2089"
    },
    {
        "engDate": "2032-10-11",
        "nepDate": "25.06.2089"
    },
    {
        "engDate": "2032-10-12",
        "nepDate": "26.06.2089"
    },
    {
        "engDate": "2032-10-13",
        "nepDate": "27.06.2089"
    },
    {
        "engDate": "2032-10-14",
        "nepDate": "28.06.2089"
    },
    {
        "engDate": "2032-10-15",
        "nepDate": "29.06.2089"
    },
    {
        "engDate": "2032-10-16",
        "nepDate": "30.06.2089"
    },
    {
        "engDate": "2032-10-17",
        "nepDate": "01.07.2089"
    },
    {
        "engDate": "2032-10-18",
        "nepDate": "02.07.2089"
    },
    {
        "engDate": "2032-10-19",
        "nepDate": "03.07.2089"
    },
    {
        "engDate": "2032-10-20",
        "nepDate": "04.07.2089"
    },
    {
        "engDate": "2032-10-21",
        "nepDate": "05.07.2089"
    },
    {
        "engDate": "2032-10-22",
        "nepDate": "06.07.2089"
    },
    {
        "engDate": "2032-10-23",
        "nepDate": "07.07.2089"
    },
    {
        "engDate": "2032-10-24",
        "nepDate": "08.07.2089"
    },
    {
        "engDate": "2032-10-25",
        "nepDate": "09.07.2089"
    },
    {
        "engDate": "2032-10-26",
        "nepDate": "10.07.2089"
    },
    {
        "engDate": "2032-10-27",
        "nepDate": "11.07.2089"
    },
    {
        "engDate": "2032-10-28",
        "nepDate": "12.07.2089"
    },
    {
        "engDate": "2032-10-29",
        "nepDate": "13.07.2089"
    },
    {
        "engDate": "2032-10-30",
        "nepDate": "14.07.2089"
    },
    {
        "engDate": "2032-10-31",
        "nepDate": "15.07.2089"
    },
    {
        "engDate": "2032-11-01",
        "nepDate": "16.07.2089"
    },
    {
        "engDate": "2032-11-02",
        "nepDate": "17.07.2089"
    },
    {
        "engDate": "2032-11-03",
        "nepDate": "18.07.2089"
    },
    {
        "engDate": "2032-11-04",
        "nepDate": "19.07.2089"
    },
    {
        "engDate": "2032-11-05",
        "nepDate": "20.07.2089"
    },
    {
        "engDate": "2032-11-06",
        "nepDate": "21.07.2089"
    },
    {
        "engDate": "2032-11-07",
        "nepDate": "22.07.2089"
    },
    {
        "engDate": "2032-11-08",
        "nepDate": "23.07.2089"
    },
    {
        "engDate": "2032-11-09",
        "nepDate": "24.07.2089"
    },
    {
        "engDate": "2032-11-10",
        "nepDate": "25.07.2089"
    },
    {
        "engDate": "2032-11-11",
        "nepDate": "26.07.2089"
    },
    {
        "engDate": "2032-11-12",
        "nepDate": "27.07.2089"
    },
    {
        "engDate": "2032-11-13",
        "nepDate": "28.07.2089"
    },
    {
        "engDate": "2032-11-14",
        "nepDate": "29.07.2089"
    },
    {
        "engDate": "2032-11-15",
        "nepDate": "30.07.2089"
    },
    {
        "engDate": "2032-11-16",
        "nepDate": "01.08.2089"
    },
    {
        "engDate": "2032-11-17",
        "nepDate": "02.08.2089"
    },
    {
        "engDate": "2032-11-18",
        "nepDate": "03.08.2089"
    },
    {
        "engDate": "2032-11-19",
        "nepDate": "04.08.2089"
    },
    {
        "engDate": "2032-11-20",
        "nepDate": "05.08.2089"
    },
    {
        "engDate": "2032-11-21",
        "nepDate": "06.08.2089"
    },
    {
        "engDate": "2032-11-22",
        "nepDate": "07.08.2089"
    },
    {
        "engDate": "2032-11-23",
        "nepDate": "08.08.2089"
    },
    {
        "engDate": "2032-11-24",
        "nepDate": "09.08.2089"
    },
    {
        "engDate": "2032-11-25",
        "nepDate": "10.08.2089"
    },
    {
        "engDate": "2032-11-26",
        "nepDate": "11.08.2089"
    },
    {
        "engDate": "2032-11-27",
        "nepDate": "12.08.2089"
    },
    {
        "engDate": "2032-11-28",
        "nepDate": "13.08.2089"
    },
    {
        "engDate": "2032-11-29",
        "nepDate": "14.08.2089"
    },
    {
        "engDate": "2032-11-30",
        "nepDate": "15.08.2089"
    },
    {
        "engDate": "2032-12-01",
        "nepDate": "16.08.2089"
    },
    {
        "engDate": "2032-12-02",
        "nepDate": "17.08.2089"
    },
    {
        "engDate": "2032-12-03",
        "nepDate": "18.08.2089"
    },
    {
        "engDate": "2032-12-04",
        "nepDate": "19.08.2089"
    },
    {
        "engDate": "2032-12-05",
        "nepDate": "20.08.2089"
    },
    {
        "engDate": "2032-12-06",
        "nepDate": "21.08.2089"
    },
    {
        "engDate": "2032-12-07",
        "nepDate": "22.08.2089"
    },
    {
        "engDate": "2032-12-08",
        "nepDate": "23.08.2089"
    },
    {
        "engDate": "2032-12-09",
        "nepDate": "24.08.2089"
    },
    {
        "engDate": "2032-12-10",
        "nepDate": "25.08.2089"
    },
    {
        "engDate": "2032-12-11",
        "nepDate": "26.08.2089"
    },
    {
        "engDate": "2032-12-12",
        "nepDate": "27.08.2089"
    },
    {
        "engDate": "2032-12-13",
        "nepDate": "28.08.2089"
    },
    {
        "engDate": "2032-12-14",
        "nepDate": "29.08.2089"
    },
    {
        "engDate": "2032-12-15",
        "nepDate": "30.08.2089"
    },
    {
        "engDate": "2032-12-16",
        "nepDate": "01.09.2089"
    },
    {
        "engDate": "2032-12-17",
        "nepDate": "02.09.2089"
    },
    {
        "engDate": "2032-12-18",
        "nepDate": "03.09.2089"
    },
    {
        "engDate": "2032-12-19",
        "nepDate": "04.09.2089"
    },
    {
        "engDate": "2032-12-20",
        "nepDate": "05.09.2089"
    },
    {
        "engDate": "2032-12-21",
        "nepDate": "06.09.2089"
    },
    {
        "engDate": "2032-12-22",
        "nepDate": "07.09.2089"
    },
    {
        "engDate": "2032-12-23",
        "nepDate": "08.09.2089"
    },
    {
        "engDate": "2032-12-24",
        "nepDate": "09.09.2089"
    },
    {
        "engDate": "2032-12-25",
        "nepDate": "10.09.2089"
    },
    {
        "engDate": "2032-12-26",
        "nepDate": "11.09.2089"
    },
    {
        "engDate": "2032-12-27",
        "nepDate": "12.09.2089"
    },
    {
        "engDate": "2032-12-28",
        "nepDate": "13.09.2089"
    },
    {
        "engDate": "2032-12-29",
        "nepDate": "14.09.2089"
    },
    {
        "engDate": "2032-12-30",
        "nepDate": "15.09.2089"
    },
    {
        "engDate": "2032-12-31",
        "nepDate": "16.09.2089"
    },
    {
        "engDate": "2033-01-01",
        "nepDate": "17.09.2089"
    },
    {
        "engDate": "2033-01-02",
        "nepDate": "18.09.2089"
    },
    {
        "engDate": "2033-01-03",
        "nepDate": "19.09.2089"
    },
    {
        "engDate": "2033-01-04",
        "nepDate": "20.09.2089"
    },
    {
        "engDate": "2033-01-05",
        "nepDate": "21.09.2089"
    },
    {
        "engDate": "2033-01-06",
        "nepDate": "22.09.2089"
    },
    {
        "engDate": "2033-01-07",
        "nepDate": "23.09.2089"
    },
    {
        "engDate": "2033-01-08",
        "nepDate": "24.09.2089"
    },
    {
        "engDate": "2033-01-09",
        "nepDate": "25.09.2089"
    },
    {
        "engDate": "2033-01-10",
        "nepDate": "26.09.2089"
    },
    {
        "engDate": "2033-01-11",
        "nepDate": "27.09.2089"
    },
    {
        "engDate": "2033-01-12",
        "nepDate": "28.09.2089"
    },
    {
        "engDate": "2033-01-13",
        "nepDate": "29.09.2089"
    },
    {
        "engDate": "2033-01-14",
        "nepDate": "01.10.2089"
    },
    {
        "engDate": "2033-01-15",
        "nepDate": "02.10.2089"
    },
    {
        "engDate": "2033-01-16",
        "nepDate": "03.10.2089"
    },
    {
        "engDate": "2033-01-17",
        "nepDate": "04.10.2089"
    },
    {
        "engDate": "2033-01-18",
        "nepDate": "05.10.2089"
    },
    {
        "engDate": "2033-01-19",
        "nepDate": "06.10.2089"
    },
    {
        "engDate": "2033-01-20",
        "nepDate": "07.10.2089"
    },
    {
        "engDate": "2033-01-21",
        "nepDate": "08.10.2089"
    },
    {
        "engDate": "2033-01-22",
        "nepDate": "09.10.2089"
    },
    {
        "engDate": "2033-01-23",
        "nepDate": "10.10.2089"
    },
    {
        "engDate": "2033-01-24",
        "nepDate": "11.10.2089"
    },
    {
        "engDate": "2033-01-25",
        "nepDate": "12.10.2089"
    },
    {
        "engDate": "2033-01-26",
        "nepDate": "13.10.2089"
    },
    {
        "engDate": "2033-01-27",
        "nepDate": "14.10.2089"
    },
    {
        "engDate": "2033-01-28",
        "nepDate": "15.10.2089"
    },
    {
        "engDate": "2033-01-29",
        "nepDate": "16.10.2089"
    },
    {
        "engDate": "2033-01-30",
        "nepDate": "17.10.2089"
    },
    {
        "engDate": "2033-01-31",
        "nepDate": "18.10.2089"
    },
    {
        "engDate": "2033-02-01",
        "nepDate": "19.10.2089"
    },
    {
        "engDate": "2033-02-02",
        "nepDate": "20.10.2089"
    },
    {
        "engDate": "2033-02-03",
        "nepDate": "21.10.2089"
    },
    {
        "engDate": "2033-02-04",
        "nepDate": "22.10.2089"
    },
    {
        "engDate": "2033-02-05",
        "nepDate": "23.10.2089"
    },
    {
        "engDate": "2033-02-06",
        "nepDate": "24.10.2089"
    },
    {
        "engDate": "2033-02-07",
        "nepDate": "25.10.2089"
    },
    {
        "engDate": "2033-02-08",
        "nepDate": "26.10.2089"
    },
    {
        "engDate": "2033-02-09",
        "nepDate": "27.10.2089"
    },
    {
        "engDate": "2033-02-10",
        "nepDate": "28.10.2089"
    },
    {
        "engDate": "2033-02-11",
        "nepDate": "29.10.2089"
    },
    {
        "engDate": "2033-02-12",
        "nepDate": "30.10.2089"
    },
    {
        "engDate": "2033-02-13",
        "nepDate": "01.11.2089"
    },
    {
        "engDate": "2033-02-14",
        "nepDate": "02.11.2089"
    },
    {
        "engDate": "2033-02-15",
        "nepDate": "03.11.2089"
    },
    {
        "engDate": "2033-02-16",
        "nepDate": "04.11.2089"
    },
    {
        "engDate": "2033-02-17",
        "nepDate": "05.11.2089"
    },
    {
        "engDate": "2033-02-18",
        "nepDate": "06.11.2089"
    },
    {
        "engDate": "2033-02-19",
        "nepDate": "07.11.2089"
    },
    {
        "engDate": "2033-02-20",
        "nepDate": "08.11.2089"
    },
    {
        "engDate": "2033-02-21",
        "nepDate": "09.11.2089"
    },
    {
        "engDate": "2033-02-22",
        "nepDate": "10.11.2089"
    },
    {
        "engDate": "2033-02-23",
        "nepDate": "11.11.2089"
    },
    {
        "engDate": "2033-02-24",
        "nepDate": "12.11.2089"
    },
    {
        "engDate": "2033-02-25",
        "nepDate": "13.11.2089"
    },
    {
        "engDate": "2033-02-26",
        "nepDate": "14.11.2089"
    },
    {
        "engDate": "2033-02-27",
        "nepDate": "15.11.2089"
    },
    {
        "engDate": "2033-02-28",
        "nepDate": "16.11.2089"
    },
    {
        "engDate": "2033-03-01",
        "nepDate": "17.11.2089"
    },
    {
        "engDate": "2033-03-02",
        "nepDate": "18.11.2089"
    },
    {
        "engDate": "2033-03-03",
        "nepDate": "19.11.2089"
    },
    {
        "engDate": "2033-03-04",
        "nepDate": "20.11.2089"
    },
    {
        "engDate": "2033-03-05",
        "nepDate": "21.11.2089"
    },
    {
        "engDate": "2033-03-06",
        "nepDate": "22.11.2089"
    },
    {
        "engDate": "2033-03-07",
        "nepDate": "23.11.2089"
    },
    {
        "engDate": "2033-03-08",
        "nepDate": "24.11.2089"
    },
    {
        "engDate": "2033-03-09",
        "nepDate": "25.11.2089"
    },
    {
        "engDate": "2033-03-10",
        "nepDate": "26.11.2089"
    },
    {
        "engDate": "2033-03-11",
        "nepDate": "27.11.2089"
    },
    {
        "engDate": "2033-03-12",
        "nepDate": "28.11.2089"
    },
    {
        "engDate": "2033-03-13",
        "nepDate": "29.11.2089"
    },
    {
        "engDate": "2033-03-14",
        "nepDate": "30.11.2089"
    },
    {
        "engDate": "2033-03-15",
        "nepDate": "01.12.2089"
    },
    {
        "engDate": "2033-03-16",
        "nepDate": "02.12.2089"
    },
    {
        "engDate": "2033-03-17",
        "nepDate": "03.12.2089"
    },
    {
        "engDate": "2033-03-18",
        "nepDate": "04.12.2089"
    },
    {
        "engDate": "2033-03-19",
        "nepDate": "05.12.2089"
    },
    {
        "engDate": "2033-03-20",
        "nepDate": "06.12.2089"
    },
    {
        "engDate": "2033-03-21",
        "nepDate": "07.12.2089"
    },
    {
        "engDate": "2033-03-22",
        "nepDate": "08.12.2089"
    },
    {
        "engDate": "2033-03-23",
        "nepDate": "09.12.2089"
    },
    {
        "engDate": "2033-03-24",
        "nepDate": "10.12.2089"
    },
    {
        "engDate": "2033-03-25",
        "nepDate": "11.12.2089"
    },
    {
        "engDate": "2033-03-26",
        "nepDate": "12.12.2089"
    },
    {
        "engDate": "2033-03-27",
        "nepDate": "13.12.2089"
    },
    {
        "engDate": "2033-03-28",
        "nepDate": "14.12.2089"
    },
    {
        "engDate": "2033-03-29",
        "nepDate": "15.12.2089"
    },
    {
        "engDate": "2033-03-30",
        "nepDate": "16.12.2089"
    },
    {
        "engDate": "2033-03-31",
        "nepDate": "17.12.2089"
    },
    {
        "engDate": "2033-04-01",
        "nepDate": "18.12.2089"
    },
    {
        "engDate": "2033-04-02",
        "nepDate": "19.12.2089"
    },
    {
        "engDate": "2033-04-03",
        "nepDate": "20.12.2089"
    },
    {
        "engDate": "2033-04-04",
        "nepDate": "21.12.2089"
    },
    {
        "engDate": "2033-04-05",
        "nepDate": "22.12.2089"
    },
    {
        "engDate": "2033-04-06",
        "nepDate": "23.12.2089"
    },
    {
        "engDate": "2033-04-07",
        "nepDate": "24.12.2089"
    },
    {
        "engDate": "2033-04-08",
        "nepDate": "25.12.2089"
    },
    {
        "engDate": "2033-04-09",
        "nepDate": "26.12.2089"
    },
    {
        "engDate": "2033-04-10",
        "nepDate": "27.12.2089"
    },
    {
        "engDate": "2033-04-11",
        "nepDate": "28.12.2089"
    },
    {
        "engDate": "2033-04-12",
        "nepDate": "29.12.2089"
    },
    {
        "engDate": "2033-04-13",
        "nepDate": "30.12.2089"
    },
    {
        "engDate": "2033-04-14",
        "nepDate": "01.01.2090"
    },
    {
        "engDate": "2033-04-15",
        "nepDate": "02.01.2090"
    },
    {
        "engDate": "2033-04-16",
        "nepDate": "03.01.2090"
    },
    {
        "engDate": "2033-04-17",
        "nepDate": "04.01.2090"
    },
    {
        "engDate": "2033-04-18",
        "nepDate": "05.01.2090"
    },
    {
        "engDate": "2033-04-19",
        "nepDate": "06.01.2090"
    },
    {
        "engDate": "2033-04-20",
        "nepDate": "07.01.2090"
    },
    {
        "engDate": "2033-04-21",
        "nepDate": "08.01.2090"
    },
    {
        "engDate": "2033-04-22",
        "nepDate": "09.01.2090"
    },
    {
        "engDate": "2033-04-23",
        "nepDate": "10.01.2090"
    },
    {
        "engDate": "2033-04-24",
        "nepDate": "11.01.2090"
    },
    {
        "engDate": "2033-04-25",
        "nepDate": "12.01.2090"
    },
    {
        "engDate": "2033-04-26",
        "nepDate": "13.01.2090"
    },
    {
        "engDate": "2033-04-27",
        "nepDate": "14.01.2090"
    },
    {
        "engDate": "2033-04-28",
        "nepDate": "15.01.2090"
    },
    {
        "engDate": "2033-04-29",
        "nepDate": "16.01.2090"
    },
    {
        "engDate": "2033-04-30",
        "nepDate": "17.01.2090"
    },
    {
        "engDate": "2033-05-01",
        "nepDate": "18.01.2090"
    },
    {
        "engDate": "2033-05-02",
        "nepDate": "19.01.2090"
    },
    {
        "engDate": "2033-05-03",
        "nepDate": "20.01.2090"
    },
    {
        "engDate": "2033-05-04",
        "nepDate": "21.01.2090"
    },
    {
        "engDate": "2033-05-05",
        "nepDate": "22.01.2090"
    },
    {
        "engDate": "2033-05-06",
        "nepDate": "23.01.2090"
    },
    {
        "engDate": "2033-05-07",
        "nepDate": "24.01.2090"
    },
    {
        "engDate": "2033-05-08",
        "nepDate": "25.01.2090"
    },
    {
        "engDate": "2033-05-09",
        "nepDate": "26.01.2090"
    },
    {
        "engDate": "2033-05-10",
        "nepDate": "27.01.2090"
    },
    {
        "engDate": "2033-05-11",
        "nepDate": "28.01.2090"
    },
    {
        "engDate": "2033-05-12",
        "nepDate": "29.01.2090"
    },
    {
        "engDate": "2033-05-13",
        "nepDate": "30.01.2090"
    },
    {
        "engDate": "2033-05-14",
        "nepDate": "01.02.2090"
    },
    {
        "engDate": "2033-05-15",
        "nepDate": "02.02.2090"
    },
    {
        "engDate": "2033-05-16",
        "nepDate": "03.02.2090"
    },
    {
        "engDate": "2033-05-17",
        "nepDate": "04.02.2090"
    },
    {
        "engDate": "2033-05-18",
        "nepDate": "05.02.2090"
    },
    {
        "engDate": "2033-05-19",
        "nepDate": "06.02.2090"
    },
    {
        "engDate": "2033-05-20",
        "nepDate": "07.02.2090"
    },
    {
        "engDate": "2033-05-21",
        "nepDate": "08.02.2090"
    },
    {
        "engDate": "2033-05-22",
        "nepDate": "09.02.2090"
    },
    {
        "engDate": "2033-05-23",
        "nepDate": "10.02.2090"
    },
    {
        "engDate": "2033-05-24",
        "nepDate": "11.02.2090"
    },
    {
        "engDate": "2033-05-25",
        "nepDate": "12.02.2090"
    },
    {
        "engDate": "2033-05-26",
        "nepDate": "13.02.2090"
    },
    {
        "engDate": "2033-05-27",
        "nepDate": "14.02.2090"
    },
    {
        "engDate": "2033-05-28",
        "nepDate": "15.02.2090"
    },
    {
        "engDate": "2033-05-29",
        "nepDate": "16.02.2090"
    },
    {
        "engDate": "2033-05-30",
        "nepDate": "17.02.2090"
    },
    {
        "engDate": "2033-05-31",
        "nepDate": "18.02.2090"
    },
    {
        "engDate": "2033-06-01",
        "nepDate": "19.02.2090"
    },
    {
        "engDate": "2033-06-02",
        "nepDate": "20.02.2090"
    },
    {
        "engDate": "2033-06-03",
        "nepDate": "21.02.2090"
    },
    {
        "engDate": "2033-06-04",
        "nepDate": "22.02.2090"
    },
    {
        "engDate": "2033-06-05",
        "nepDate": "23.02.2090"
    },
    {
        "engDate": "2033-06-06",
        "nepDate": "24.02.2090"
    },
    {
        "engDate": "2033-06-07",
        "nepDate": "25.02.2090"
    },
    {
        "engDate": "2033-06-08",
        "nepDate": "26.02.2090"
    },
    {
        "engDate": "2033-06-09",
        "nepDate": "27.02.2090"
    },
    {
        "engDate": "2033-06-10",
        "nepDate": "28.02.2090"
    },
    {
        "engDate": "2033-06-11",
        "nepDate": "29.02.2090"
    },
    {
        "engDate": "2033-06-12",
        "nepDate": "30.02.2090"
    },
    {
        "engDate": "2033-06-13",
        "nepDate": "31.02.2090"
    },
    {
        "engDate": "2033-06-14",
        "nepDate": "32.02.2090"
    },
    {
        "engDate": "2033-06-15",
        "nepDate": "01.03.2090"
    },
    {
        "engDate": "2033-06-16",
        "nepDate": "02.03.2090"
    },
    {
        "engDate": "2033-06-17",
        "nepDate": "03.03.2090"
    },
    {
        "engDate": "2033-06-18",
        "nepDate": "04.03.2090"
    },
    {
        "engDate": "2033-06-19",
        "nepDate": "05.03.2090"
    },
    {
        "engDate": "2033-06-20",
        "nepDate": "06.03.2090"
    },
    {
        "engDate": "2033-06-21",
        "nepDate": "07.03.2090"
    },
    {
        "engDate": "2033-06-22",
        "nepDate": "08.03.2090"
    },
    {
        "engDate": "2033-06-23",
        "nepDate": "09.03.2090"
    },
    {
        "engDate": "2033-06-24",
        "nepDate": "10.03.2090"
    },
    {
        "engDate": "2033-06-25",
        "nepDate": "11.03.2090"
    },
    {
        "engDate": "2033-06-26",
        "nepDate": "12.03.2090"
    },
    {
        "engDate": "2033-06-27",
        "nepDate": "13.03.2090"
    },
    {
        "engDate": "2033-06-28",
        "nepDate": "14.03.2090"
    },
    {
        "engDate": "2033-06-29",
        "nepDate": "15.03.2090"
    },
    {
        "engDate": "2033-06-30",
        "nepDate": "16.03.2090"
    },
    {
        "engDate": "2033-07-01",
        "nepDate": "17.03.2090"
    },
    {
        "engDate": "2033-07-02",
        "nepDate": "18.03.2090"
    },
    {
        "engDate": "2033-07-03",
        "nepDate": "19.03.2090"
    },
    {
        "engDate": "2033-07-04",
        "nepDate": "20.03.2090"
    },
    {
        "engDate": "2033-07-05",
        "nepDate": "21.03.2090"
    },
    {
        "engDate": "2033-07-06",
        "nepDate": "22.03.2090"
    },
    {
        "engDate": "2033-07-07",
        "nepDate": "23.03.2090"
    },
    {
        "engDate": "2033-07-08",
        "nepDate": "24.03.2090"
    },
    {
        "engDate": "2033-07-09",
        "nepDate": "25.03.2090"
    },
    {
        "engDate": "2033-07-10",
        "nepDate": "26.03.2090"
    },
    {
        "engDate": "2033-07-11",
        "nepDate": "27.03.2090"
    },
    {
        "engDate": "2033-07-12",
        "nepDate": "28.03.2090"
    },
    {
        "engDate": "2033-07-13",
        "nepDate": "29.03.2090"
    },
    {
        "engDate": "2033-07-14",
        "nepDate": "30.03.2090"
    },
    {
        "engDate": "2033-07-15",
        "nepDate": "31.03.2090"
    },
    {
        "engDate": "2033-07-16",
        "nepDate": "01.04.2090"
    },
    {
        "engDate": "2033-07-17",
        "nepDate": "02.04.2090"
    },
    {
        "engDate": "2033-07-18",
        "nepDate": "03.04.2090"
    },
    {
        "engDate": "2033-07-19",
        "nepDate": "04.04.2090"
    },
    {
        "engDate": "2033-07-20",
        "nepDate": "05.04.2090"
    },
    {
        "engDate": "2033-07-21",
        "nepDate": "06.04.2090"
    },
    {
        "engDate": "2033-07-22",
        "nepDate": "07.04.2090"
    },
    {
        "engDate": "2033-07-23",
        "nepDate": "08.04.2090"
    },
    {
        "engDate": "2033-07-24",
        "nepDate": "09.04.2090"
    },
    {
        "engDate": "2033-07-25",
        "nepDate": "10.04.2090"
    },
    {
        "engDate": "2033-07-26",
        "nepDate": "11.04.2090"
    },
    {
        "engDate": "2033-07-27",
        "nepDate": "12.04.2090"
    },
    {
        "engDate": "2033-07-28",
        "nepDate": "13.04.2090"
    },
    {
        "engDate": "2033-07-29",
        "nepDate": "14.04.2090"
    },
    {
        "engDate": "2033-07-30",
        "nepDate": "15.04.2090"
    },
    {
        "engDate": "2033-07-31",
        "nepDate": "16.04.2090"
    },
    {
        "engDate": "2033-08-01",
        "nepDate": "17.04.2090"
    },
    {
        "engDate": "2033-08-02",
        "nepDate": "18.04.2090"
    },
    {
        "engDate": "2033-08-03",
        "nepDate": "19.04.2090"
    },
    {
        "engDate": "2033-08-04",
        "nepDate": "20.04.2090"
    },
    {
        "engDate": "2033-08-05",
        "nepDate": "21.04.2090"
    },
    {
        "engDate": "2033-08-06",
        "nepDate": "22.04.2090"
    },
    {
        "engDate": "2033-08-07",
        "nepDate": "23.04.2090"
    },
    {
        "engDate": "2033-08-08",
        "nepDate": "24.04.2090"
    },
    {
        "engDate": "2033-08-09",
        "nepDate": "25.04.2090"
    },
    {
        "engDate": "2033-08-10",
        "nepDate": "26.04.2090"
    },
    {
        "engDate": "2033-08-11",
        "nepDate": "27.04.2090"
    },
    {
        "engDate": "2033-08-12",
        "nepDate": "28.04.2090"
    },
    {
        "engDate": "2033-08-13",
        "nepDate": "29.04.2090"
    },
    {
        "engDate": "2033-08-14",
        "nepDate": "30.04.2090"
    },
    {
        "engDate": "2033-08-15",
        "nepDate": "31.04.2090"
    },
    {
        "engDate": "2033-08-16",
        "nepDate": "32.04.2090"
    },
    {
        "engDate": "2033-08-17",
        "nepDate": "01.05.2090"
    },
    {
        "engDate": "2033-08-18",
        "nepDate": "02.05.2090"
    },
    {
        "engDate": "2033-08-19",
        "nepDate": "03.05.2090"
    },
    {
        "engDate": "2033-08-20",
        "nepDate": "04.05.2090"
    },
    {
        "engDate": "2033-08-21",
        "nepDate": "05.05.2090"
    },
    {
        "engDate": "2033-08-22",
        "nepDate": "06.05.2090"
    },
    {
        "engDate": "2033-08-23",
        "nepDate": "07.05.2090"
    },
    {
        "engDate": "2033-08-24",
        "nepDate": "08.05.2090"
    },
    {
        "engDate": "2033-08-25",
        "nepDate": "09.05.2090"
    },
    {
        "engDate": "2033-08-26",
        "nepDate": "10.05.2090"
    },
    {
        "engDate": "2033-08-27",
        "nepDate": "11.05.2090"
    },
    {
        "engDate": "2033-08-28",
        "nepDate": "12.05.2090"
    },
    {
        "engDate": "2033-08-29",
        "nepDate": "13.05.2090"
    },
    {
        "engDate": "2033-08-30",
        "nepDate": "14.05.2090"
    },
    {
        "engDate": "2033-08-31",
        "nepDate": "15.05.2090"
    },
    {
        "engDate": "2033-09-01",
        "nepDate": "16.05.2090"
    },
    {
        "engDate": "2033-09-02",
        "nepDate": "17.05.2090"
    },
    {
        "engDate": "2033-09-03",
        "nepDate": "18.05.2090"
    },
    {
        "engDate": "2033-09-04",
        "nepDate": "19.05.2090"
    },
    {
        "engDate": "2033-09-05",
        "nepDate": "20.05.2090"
    },
    {
        "engDate": "2033-09-06",
        "nepDate": "21.05.2090"
    },
    {
        "engDate": "2033-09-07",
        "nepDate": "22.05.2090"
    },
    {
        "engDate": "2033-09-08",
        "nepDate": "23.05.2090"
    },
    {
        "engDate": "2033-09-09",
        "nepDate": "24.05.2090"
    },
    {
        "engDate": "2033-09-10",
        "nepDate": "25.05.2090"
    },
    {
        "engDate": "2033-09-11",
        "nepDate": "26.05.2090"
    },
    {
        "engDate": "2033-09-12",
        "nepDate": "27.05.2090"
    },
    {
        "engDate": "2033-09-13",
        "nepDate": "28.05.2090"
    },
    {
        "engDate": "2033-09-14",
        "nepDate": "29.05.2090"
    },
    {
        "engDate": "2033-09-15",
        "nepDate": "30.05.2090"
    },
    {
        "engDate": "2033-09-16",
        "nepDate": "31.05.2090"
    },
    {
        "engDate": "2033-09-17",
        "nepDate": "01.06.2090"
    },
    {
        "engDate": "2033-09-18",
        "nepDate": "02.06.2090"
    },
    {
        "engDate": "2033-09-19",
        "nepDate": "03.06.2090"
    },
    {
        "engDate": "2033-09-20",
        "nepDate": "04.06.2090"
    },
    {
        "engDate": "2033-09-21",
        "nepDate": "05.06.2090"
    },
    {
        "engDate": "2033-09-22",
        "nepDate": "06.06.2090"
    },
    {
        "engDate": "2033-09-23",
        "nepDate": "07.06.2090"
    },
    {
        "engDate": "2033-09-24",
        "nepDate": "08.06.2090"
    },
    {
        "engDate": "2033-09-25",
        "nepDate": "09.06.2090"
    },
    {
        "engDate": "2033-09-26",
        "nepDate": "10.06.2090"
    },
    {
        "engDate": "2033-09-27",
        "nepDate": "11.06.2090"
    },
    {
        "engDate": "2033-09-28",
        "nepDate": "12.06.2090"
    },
    {
        "engDate": "2033-09-29",
        "nepDate": "13.06.2090"
    },
    {
        "engDate": "2033-09-30",
        "nepDate": "14.06.2090"
    },
    {
        "engDate": "2033-10-01",
        "nepDate": "15.06.2090"
    },
    {
        "engDate": "2033-10-02",
        "nepDate": "16.06.2090"
    },
    {
        "engDate": "2033-10-03",
        "nepDate": "17.06.2090"
    },
    {
        "engDate": "2033-10-04",
        "nepDate": "18.06.2090"
    },
    {
        "engDate": "2033-10-05",
        "nepDate": "19.06.2090"
    },
    {
        "engDate": "2033-10-06",
        "nepDate": "20.06.2090"
    },
    {
        "engDate": "2033-10-07",
        "nepDate": "21.06.2090"
    },
    {
        "engDate": "2033-10-08",
        "nepDate": "22.06.2090"
    },
    {
        "engDate": "2033-10-09",
        "nepDate": "23.06.2090"
    },
    {
        "engDate": "2033-10-10",
        "nepDate": "24.06.2090"
    },
    {
        "engDate": "2033-10-11",
        "nepDate": "25.06.2090"
    },
    {
        "engDate": "2033-10-12",
        "nepDate": "26.06.2090"
    },
    {
        "engDate": "2033-10-13",
        "nepDate": "27.06.2090"
    },
    {
        "engDate": "2033-10-14",
        "nepDate": "28.06.2090"
    },
    {
        "engDate": "2033-10-15",
        "nepDate": "29.06.2090"
    },
    {
        "engDate": "2033-10-16",
        "nepDate": "30.06.2090"
    },
    {
        "engDate": "2033-10-17",
        "nepDate": "01.07.2090"
    },
    {
        "engDate": "2033-10-18",
        "nepDate": "02.07.2090"
    },
    {
        "engDate": "2033-10-19",
        "nepDate": "03.07.2090"
    },
    {
        "engDate": "2033-10-20",
        "nepDate": "04.07.2090"
    },
    {
        "engDate": "2033-10-21",
        "nepDate": "05.07.2090"
    },
    {
        "engDate": "2033-10-22",
        "nepDate": "06.07.2090"
    },
    {
        "engDate": "2033-10-23",
        "nepDate": "07.07.2090"
    },
    {
        "engDate": "2033-10-24",
        "nepDate": "08.07.2090"
    },
    {
        "engDate": "2033-10-25",
        "nepDate": "09.07.2090"
    },
    {
        "engDate": "2033-10-26",
        "nepDate": "10.07.2090"
    },
    {
        "engDate": "2033-10-27",
        "nepDate": "11.07.2090"
    },
    {
        "engDate": "2033-10-28",
        "nepDate": "12.07.2090"
    },
    {
        "engDate": "2033-10-29",
        "nepDate": "13.07.2090"
    },
    {
        "engDate": "2033-10-30",
        "nepDate": "14.07.2090"
    },
    {
        "engDate": "2033-10-31",
        "nepDate": "15.07.2090"
    },
    {
        "engDate": "2033-11-01",
        "nepDate": "16.07.2090"
    },
    {
        "engDate": "2033-11-02",
        "nepDate": "17.07.2090"
    },
    {
        "engDate": "2033-11-03",
        "nepDate": "18.07.2090"
    },
    {
        "engDate": "2033-11-04",
        "nepDate": "19.07.2090"
    },
    {
        "engDate": "2033-11-05",
        "nepDate": "20.07.2090"
    },
    {
        "engDate": "2033-11-06",
        "nepDate": "21.07.2090"
    },
    {
        "engDate": "2033-11-07",
        "nepDate": "22.07.2090"
    },
    {
        "engDate": "2033-11-08",
        "nepDate": "23.07.2090"
    },
    {
        "engDate": "2033-11-09",
        "nepDate": "24.07.2090"
    },
    {
        "engDate": "2033-11-10",
        "nepDate": "25.07.2090"
    },
    {
        "engDate": "2033-11-11",
        "nepDate": "26.07.2090"
    },
    {
        "engDate": "2033-11-12",
        "nepDate": "27.07.2090"
    },
    {
        "engDate": "2033-11-13",
        "nepDate": "28.07.2090"
    },
    {
        "engDate": "2033-11-14",
        "nepDate": "29.07.2090"
    },
    {
        "engDate": "2033-11-15",
        "nepDate": "30.07.2090"
    },
    {
        "engDate": "2033-11-16",
        "nepDate": "01.08.2090"
    },
    {
        "engDate": "2033-11-17",
        "nepDate": "02.08.2090"
    },
    {
        "engDate": "2033-11-18",
        "nepDate": "03.08.2090"
    },
    {
        "engDate": "2033-11-19",
        "nepDate": "04.08.2090"
    },
    {
        "engDate": "2033-11-20",
        "nepDate": "05.08.2090"
    },
    {
        "engDate": "2033-11-21",
        "nepDate": "06.08.2090"
    },
    {
        "engDate": "2033-11-22",
        "nepDate": "07.08.2090"
    },
    {
        "engDate": "2033-11-23",
        "nepDate": "08.08.2090"
    },
    {
        "engDate": "2033-11-24",
        "nepDate": "09.08.2090"
    },
    {
        "engDate": "2033-11-25",
        "nepDate": "10.08.2090"
    },
    {
        "engDate": "2033-11-26",
        "nepDate": "11.08.2090"
    },
    {
        "engDate": "2033-11-27",
        "nepDate": "12.08.2090"
    },
    {
        "engDate": "2033-11-28",
        "nepDate": "13.08.2090"
    },
    {
        "engDate": "2033-11-29",
        "nepDate": "14.08.2090"
    },
    {
        "engDate": "2033-11-30",
        "nepDate": "15.08.2090"
    },
    {
        "engDate": "2033-12-01",
        "nepDate": "16.08.2090"
    },
    {
        "engDate": "2033-12-02",
        "nepDate": "17.08.2090"
    },
    {
        "engDate": "2033-12-03",
        "nepDate": "18.08.2090"
    },
    {
        "engDate": "2033-12-04",
        "nepDate": "19.08.2090"
    },
    {
        "engDate": "2033-12-05",
        "nepDate": "20.08.2090"
    },
    {
        "engDate": "2033-12-06",
        "nepDate": "21.08.2090"
    },
    {
        "engDate": "2033-12-07",
        "nepDate": "22.08.2090"
    },
    {
        "engDate": "2033-12-08",
        "nepDate": "23.08.2090"
    },
    {
        "engDate": "2033-12-09",
        "nepDate": "24.08.2090"
    },
    {
        "engDate": "2033-12-10",
        "nepDate": "25.08.2090"
    },
    {
        "engDate": "2033-12-11",
        "nepDate": "26.08.2090"
    },
    {
        "engDate": "2033-12-12",
        "nepDate": "27.08.2090"
    },
    {
        "engDate": "2033-12-13",
        "nepDate": "28.08.2090"
    },
    {
        "engDate": "2033-12-14",
        "nepDate": "29.08.2090"
    },
    {
        "engDate": "2033-12-15",
        "nepDate": "30.08.2090"
    },
    {
        "engDate": "2033-12-16",
        "nepDate": "01.09.2090"
    },
    {
        "engDate": "2033-12-17",
        "nepDate": "02.09.2090"
    },
    {
        "engDate": "2033-12-18",
        "nepDate": "03.09.2090"
    },
    {
        "engDate": "2033-12-19",
        "nepDate": "04.09.2090"
    },
    {
        "engDate": "2033-12-20",
        "nepDate": "05.09.2090"
    },
    {
        "engDate": "2033-12-21",
        "nepDate": "06.09.2090"
    },
    {
        "engDate": "2033-12-22",
        "nepDate": "07.09.2090"
    },
    {
        "engDate": "2033-12-23",
        "nepDate": "08.09.2090"
    },
    {
        "engDate": "2033-12-24",
        "nepDate": "09.09.2090"
    },
    {
        "engDate": "2033-12-25",
        "nepDate": "10.09.2090"
    },
    {
        "engDate": "2033-12-26",
        "nepDate": "11.09.2090"
    },
    {
        "engDate": "2033-12-27",
        "nepDate": "12.09.2090"
    },
    {
        "engDate": "2033-12-28",
        "nepDate": "13.09.2090"
    },
    {
        "engDate": "2033-12-29",
        "nepDate": "14.09.2090"
    },
    {
        "engDate": "2033-12-30",
        "nepDate": "15.09.2090"
    },
    {
        "engDate": "2033-12-31",
        "nepDate": "16.09.2090"
    },
    {
        "engDate": "2034-01-01",
        "nepDate": "17.09.2090"
    },
    {
        "engDate": "2034-01-02",
        "nepDate": "18.09.2090"
    },
    {
        "engDate": "2034-01-03",
        "nepDate": "19.09.2090"
    },
    {
        "engDate": "2034-01-04",
        "nepDate": "20.09.2090"
    },
    {
        "engDate": "2034-01-05",
        "nepDate": "21.09.2090"
    },
    {
        "engDate": "2034-01-06",
        "nepDate": "22.09.2090"
    },
    {
        "engDate": "2034-01-07",
        "nepDate": "23.09.2090"
    },
    {
        "engDate": "2034-01-08",
        "nepDate": "24.09.2090"
    },
    {
        "engDate": "2034-01-09",
        "nepDate": "25.09.2090"
    },
    {
        "engDate": "2034-01-10",
        "nepDate": "26.09.2090"
    },
    {
        "engDate": "2034-01-11",
        "nepDate": "27.09.2090"
    },
    {
        "engDate": "2034-01-12",
        "nepDate": "28.09.2090"
    },
    {
        "engDate": "2034-01-13",
        "nepDate": "29.09.2090"
    },
    {
        "engDate": "2034-01-14",
        "nepDate": "01.10.2090"
    },
    {
        "engDate": "2034-01-15",
        "nepDate": "02.10.2090"
    },
    {
        "engDate": "2034-01-16",
        "nepDate": "03.10.2090"
    },
    {
        "engDate": "2034-01-17",
        "nepDate": "04.10.2090"
    },
    {
        "engDate": "2034-01-18",
        "nepDate": "05.10.2090"
    },
    {
        "engDate": "2034-01-19",
        "nepDate": "06.10.2090"
    },
    {
        "engDate": "2034-01-20",
        "nepDate": "07.10.2090"
    },
    {
        "engDate": "2034-01-21",
        "nepDate": "08.10.2090"
    },
    {
        "engDate": "2034-01-22",
        "nepDate": "09.10.2090"
    },
    {
        "engDate": "2034-01-23",
        "nepDate": "10.10.2090"
    },
    {
        "engDate": "2034-01-24",
        "nepDate": "11.10.2090"
    },
    {
        "engDate": "2034-01-25",
        "nepDate": "12.10.2090"
    },
    {
        "engDate": "2034-01-26",
        "nepDate": "13.10.2090"
    },
    {
        "engDate": "2034-01-27",
        "nepDate": "14.10.2090"
    },
    {
        "engDate": "2034-01-28",
        "nepDate": "15.10.2090"
    },
    {
        "engDate": "2034-01-29",
        "nepDate": "16.10.2090"
    },
    {
        "engDate": "2034-01-30",
        "nepDate": "17.10.2090"
    },
    {
        "engDate": "2034-01-31",
        "nepDate": "18.10.2090"
    },
    {
        "engDate": "2034-02-01",
        "nepDate": "19.10.2090"
    },
    {
        "engDate": "2034-02-02",
        "nepDate": "20.10.2090"
    },
    {
        "engDate": "2034-02-03",
        "nepDate": "21.10.2090"
    },
    {
        "engDate": "2034-02-04",
        "nepDate": "22.10.2090"
    },
    {
        "engDate": "2034-02-05",
        "nepDate": "23.10.2090"
    },
    {
        "engDate": "2034-02-06",
        "nepDate": "24.10.2090"
    },
    {
        "engDate": "2034-02-07",
        "nepDate": "25.10.2090"
    },
    {
        "engDate": "2034-02-08",
        "nepDate": "26.10.2090"
    },
    {
        "engDate": "2034-02-09",
        "nepDate": "27.10.2090"
    },
    {
        "engDate": "2034-02-10",
        "nepDate": "28.10.2090"
    },
    {
        "engDate": "2034-02-11",
        "nepDate": "29.10.2090"
    },
    {
        "engDate": "2034-02-12",
        "nepDate": "30.10.2090"
    },
    {
        "engDate": "2034-02-13",
        "nepDate": "01.11.2090"
    },
    {
        "engDate": "2034-02-14",
        "nepDate": "02.11.2090"
    },
    {
        "engDate": "2034-02-15",
        "nepDate": "03.11.2090"
    },
    {
        "engDate": "2034-02-16",
        "nepDate": "04.11.2090"
    },
    {
        "engDate": "2034-02-17",
        "nepDate": "05.11.2090"
    },
    {
        "engDate": "2034-02-18",
        "nepDate": "06.11.2090"
    },
    {
        "engDate": "2034-02-19",
        "nepDate": "07.11.2090"
    },
    {
        "engDate": "2034-02-20",
        "nepDate": "08.11.2090"
    },
    {
        "engDate": "2034-02-21",
        "nepDate": "09.11.2090"
    },
    {
        "engDate": "2034-02-22",
        "nepDate": "10.11.2090"
    },
    {
        "engDate": "2034-02-23",
        "nepDate": "11.11.2090"
    },
    {
        "engDate": "2034-02-24",
        "nepDate": "12.11.2090"
    },
    {
        "engDate": "2034-02-25",
        "nepDate": "13.11.2090"
    },
    {
        "engDate": "2034-02-26",
        "nepDate": "14.11.2090"
    },
    {
        "engDate": "2034-02-27",
        "nepDate": "15.11.2090"
    },
    {
        "engDate": "2034-02-28",
        "nepDate": "16.11.2090"
    },
    {
        "engDate": "2034-03-01",
        "nepDate": "17.11.2090"
    },
    {
        "engDate": "2034-03-02",
        "nepDate": "18.11.2090"
    },
    {
        "engDate": "2034-03-03",
        "nepDate": "19.11.2090"
    },
    {
        "engDate": "2034-03-04",
        "nepDate": "20.11.2090"
    },
    {
        "engDate": "2034-03-05",
        "nepDate": "21.11.2090"
    },
    {
        "engDate": "2034-03-06",
        "nepDate": "22.11.2090"
    },
    {
        "engDate": "2034-03-07",
        "nepDate": "23.11.2090"
    },
    {
        "engDate": "2034-03-08",
        "nepDate": "24.11.2090"
    },
    {
        "engDate": "2034-03-09",
        "nepDate": "25.11.2090"
    },
    {
        "engDate": "2034-03-10",
        "nepDate": "26.11.2090"
    },
    {
        "engDate": "2034-03-11",
        "nepDate": "27.11.2090"
    },
    {
        "engDate": "2034-03-12",
        "nepDate": "28.11.2090"
    },
    {
        "engDate": "2034-03-13",
        "nepDate": "29.11.2090"
    },
    {
        "engDate": "2034-03-14",
        "nepDate": "30.11.2090"
    },
    {
        "engDate": "2034-03-15",
        "nepDate": "01.12.2090"
    },
    {
        "engDate": "2034-03-16",
        "nepDate": "02.12.2090"
    },
    {
        "engDate": "2034-03-17",
        "nepDate": "03.12.2090"
    },
    {
        "engDate": "2034-03-18",
        "nepDate": "04.12.2090"
    },
    {
        "engDate": "2034-03-19",
        "nepDate": "05.12.2090"
    },
    {
        "engDate": "2034-03-20",
        "nepDate": "06.12.2090"
    },
    {
        "engDate": "2034-03-21",
        "nepDate": "07.12.2090"
    },
    {
        "engDate": "2034-03-22",
        "nepDate": "08.12.2090"
    },
    {
        "engDate": "2034-03-23",
        "nepDate": "09.12.2090"
    },
    {
        "engDate": "2034-03-24",
        "nepDate": "10.12.2090"
    },
    {
        "engDate": "2034-03-25",
        "nepDate": "11.12.2090"
    },
    {
        "engDate": "2034-03-26",
        "nepDate": "12.12.2090"
    },
    {
        "engDate": "2034-03-27",
        "nepDate": "13.12.2090"
    },
    {
        "engDate": "2034-03-28",
        "nepDate": "14.12.2090"
    },
    {
        "engDate": "2034-03-29",
        "nepDate": "15.12.2090"
    },
    {
        "engDate": "2034-03-30",
        "nepDate": "16.12.2090"
    },
    {
        "engDate": "2034-03-31",
        "nepDate": "17.12.2090"
    },
    {
        "engDate": "2034-04-01",
        "nepDate": "18.12.2090"
    },
    {
        "engDate": "2034-04-02",
        "nepDate": "19.12.2090"
    },
    {
        "engDate": "2034-04-03",
        "nepDate": "20.12.2090"
    },
    {
        "engDate": "2034-04-04",
        "nepDate": "21.12.2090"
    },
    {
        "engDate": "2034-04-05",
        "nepDate": "22.12.2090"
    },
    {
        "engDate": "2034-04-06",
        "nepDate": "23.12.2090"
    },
    {
        "engDate": "2034-04-07",
        "nepDate": "24.12.2090"
    },
    {
        "engDate": "2034-04-08",
        "nepDate": "25.12.2090"
    },
    {
        "engDate": "2034-04-09",
        "nepDate": "26.12.2090"
    },
    {
        "engDate": "2034-04-10",
        "nepDate": "27.12.2090"
    },
    {
        "engDate": "2034-04-11",
        "nepDate": "28.12.2090"
    },
    {
        "engDate": "2034-04-12",
        "nepDate": "29.12.2090"
    },
    {
        "engDate": "2034-04-13",
        "nepDate": "30.12.2090"
    },
    {
        "engDate": "2034-04-14",
        "nepDate": "01.01.2091"
    },
    {
        "engDate": "2034-04-15",
        "nepDate": "02.01.2091"
    },
    {
        "engDate": "2034-04-16",
        "nepDate": "03.01.2091"
    },
    {
        "engDate": "2034-04-17",
        "nepDate": "04.01.2091"
    },
    {
        "engDate": "2034-04-18",
        "nepDate": "05.01.2091"
    },
    {
        "engDate": "2034-04-19",
        "nepDate": "06.01.2091"
    },
    {
        "engDate": "2034-04-20",
        "nepDate": "07.01.2091"
    },
    {
        "engDate": "2034-04-21",
        "nepDate": "08.01.2091"
    },
    {
        "engDate": "2034-04-22",
        "nepDate": "09.01.2091"
    },
    {
        "engDate": "2034-04-23",
        "nepDate": "10.01.2091"
    },
    {
        "engDate": "2034-04-24",
        "nepDate": "11.01.2091"
    },
    {
        "engDate": "2034-04-25",
        "nepDate": "12.01.2091"
    },
    {
        "engDate": "2034-04-26",
        "nepDate": "13.01.2091"
    },
    {
        "engDate": "2034-04-27",
        "nepDate": "14.01.2091"
    },
    {
        "engDate": "2034-04-28",
        "nepDate": "15.01.2091"
    },
    {
        "engDate": "2034-04-29",
        "nepDate": "16.01.2091"
    },
    {
        "engDate": "2034-04-30",
        "nepDate": "17.01.2091"
    },
    {
        "engDate": "2034-05-01",
        "nepDate": "18.01.2091"
    },
    {
        "engDate": "2034-05-02",
        "nepDate": "19.01.2091"
    },
    {
        "engDate": "2034-05-03",
        "nepDate": "20.01.2091"
    },
    {
        "engDate": "2034-05-04",
        "nepDate": "21.01.2091"
    },
    {
        "engDate": "2034-05-05",
        "nepDate": "22.01.2091"
    },
    {
        "engDate": "2034-05-06",
        "nepDate": "23.01.2091"
    },
    {
        "engDate": "2034-05-07",
        "nepDate": "24.01.2091"
    },
    {
        "engDate": "2034-05-08",
        "nepDate": "25.01.2091"
    },
    {
        "engDate": "2034-05-09",
        "nepDate": "26.01.2091"
    },
    {
        "engDate": "2034-05-10",
        "nepDate": "27.01.2091"
    },
    {
        "engDate": "2034-05-11",
        "nepDate": "28.01.2091"
    },
    {
        "engDate": "2034-05-12",
        "nepDate": "29.01.2091"
    },
    {
        "engDate": "2034-05-13",
        "nepDate": "30.01.2091"
    },
    {
        "engDate": "2034-05-14",
        "nepDate": "31.01.2091"
    },
    {
        "engDate": "2034-05-15",
        "nepDate": "01.02.2091"
    },
    {
        "engDate": "2034-05-16",
        "nepDate": "02.02.2091"
    },
    {
        "engDate": "2034-05-17",
        "nepDate": "03.02.2091"
    },
    {
        "engDate": "2034-05-18",
        "nepDate": "04.02.2091"
    },
    {
        "engDate": "2034-05-19",
        "nepDate": "05.02.2091"
    },
    {
        "engDate": "2034-05-20",
        "nepDate": "06.02.2091"
    },
    {
        "engDate": "2034-05-21",
        "nepDate": "07.02.2091"
    },
    {
        "engDate": "2034-05-22",
        "nepDate": "08.02.2091"
    },
    {
        "engDate": "2034-05-23",
        "nepDate": "09.02.2091"
    },
    {
        "engDate": "2034-05-24",
        "nepDate": "10.02.2091"
    },
    {
        "engDate": "2034-05-25",
        "nepDate": "11.02.2091"
    },
    {
        "engDate": "2034-05-26",
        "nepDate": "12.02.2091"
    },
    {
        "engDate": "2034-05-27",
        "nepDate": "13.02.2091"
    },
    {
        "engDate": "2034-05-28",
        "nepDate": "14.02.2091"
    },
    {
        "engDate": "2034-05-29",
        "nepDate": "15.02.2091"
    },
    {
        "engDate": "2034-05-30",
        "nepDate": "16.02.2091"
    },
    {
        "engDate": "2034-05-31",
        "nepDate": "17.02.2091"
    },
    {
        "engDate": "2034-06-01",
        "nepDate": "18.02.2091"
    },
    {
        "engDate": "2034-06-02",
        "nepDate": "19.02.2091"
    },
    {
        "engDate": "2034-06-03",
        "nepDate": "20.02.2091"
    },
    {
        "engDate": "2034-06-04",
        "nepDate": "21.02.2091"
    },
    {
        "engDate": "2034-06-05",
        "nepDate": "22.02.2091"
    },
    {
        "engDate": "2034-06-06",
        "nepDate": "23.02.2091"
    },
    {
        "engDate": "2034-06-07",
        "nepDate": "24.02.2091"
    },
    {
        "engDate": "2034-06-08",
        "nepDate": "25.02.2091"
    },
    {
        "engDate": "2034-06-09",
        "nepDate": "26.02.2091"
    },
    {
        "engDate": "2034-06-10",
        "nepDate": "27.02.2091"
    },
    {
        "engDate": "2034-06-11",
        "nepDate": "28.02.2091"
    },
    {
        "engDate": "2034-06-12",
        "nepDate": "29.02.2091"
    },
    {
        "engDate": "2034-06-13",
        "nepDate": "30.02.2091"
    },
    {
        "engDate": "2034-06-14",
        "nepDate": "31.02.2091"
    },
    {
        "engDate": "2034-06-15",
        "nepDate": "01.03.2091"
    },
    {
        "engDate": "2034-06-16",
        "nepDate": "02.03.2091"
    },
    {
        "engDate": "2034-06-17",
        "nepDate": "03.03.2091"
    },
    {
        "engDate": "2034-06-18",
        "nepDate": "04.03.2091"
    },
    {
        "engDate": "2034-06-19",
        "nepDate": "05.03.2091"
    },
    {
        "engDate": "2034-06-20",
        "nepDate": "06.03.2091"
    },
    {
        "engDate": "2034-06-21",
        "nepDate": "07.03.2091"
    },
    {
        "engDate": "2034-06-22",
        "nepDate": "08.03.2091"
    },
    {
        "engDate": "2034-06-23",
        "nepDate": "09.03.2091"
    },
    {
        "engDate": "2034-06-24",
        "nepDate": "10.03.2091"
    },
    {
        "engDate": "2034-06-25",
        "nepDate": "11.03.2091"
    },
    {
        "engDate": "2034-06-26",
        "nepDate": "12.03.2091"
    },
    {
        "engDate": "2034-06-27",
        "nepDate": "13.03.2091"
    },
    {
        "engDate": "2034-06-28",
        "nepDate": "14.03.2091"
    },
    {
        "engDate": "2034-06-29",
        "nepDate": "15.03.2091"
    },
    {
        "engDate": "2034-06-30",
        "nepDate": "16.03.2091"
    },
    {
        "engDate": "2034-07-01",
        "nepDate": "17.03.2091"
    },
    {
        "engDate": "2034-07-02",
        "nepDate": "18.03.2091"
    },
    {
        "engDate": "2034-07-03",
        "nepDate": "19.03.2091"
    },
    {
        "engDate": "2034-07-04",
        "nepDate": "20.03.2091"
    },
    {
        "engDate": "2034-07-05",
        "nepDate": "21.03.2091"
    },
    {
        "engDate": "2034-07-06",
        "nepDate": "22.03.2091"
    },
    {
        "engDate": "2034-07-07",
        "nepDate": "23.03.2091"
    },
    {
        "engDate": "2034-07-08",
        "nepDate": "24.03.2091"
    },
    {
        "engDate": "2034-07-09",
        "nepDate": "25.03.2091"
    },
    {
        "engDate": "2034-07-10",
        "nepDate": "26.03.2091"
    },
    {
        "engDate": "2034-07-11",
        "nepDate": "27.03.2091"
    },
    {
        "engDate": "2034-07-12",
        "nepDate": "28.03.2091"
    },
    {
        "engDate": "2034-07-13",
        "nepDate": "29.03.2091"
    },
    {
        "engDate": "2034-07-14",
        "nepDate": "30.03.2091"
    },
    {
        "engDate": "2034-07-15",
        "nepDate": "31.03.2091"
    },
    {
        "engDate": "2034-07-16",
        "nepDate": "32.03.2091"
    },
    {
        "engDate": "2034-07-17",
        "nepDate": "01.04.2091"
    },
    {
        "engDate": "2034-07-18",
        "nepDate": "02.04.2091"
    },
    {
        "engDate": "2034-07-19",
        "nepDate": "03.04.2091"
    },
    {
        "engDate": "2034-07-20",
        "nepDate": "04.04.2091"
    },
    {
        "engDate": "2034-07-21",
        "nepDate": "05.04.2091"
    },
    {
        "engDate": "2034-07-22",
        "nepDate": "06.04.2091"
    },
    {
        "engDate": "2034-07-23",
        "nepDate": "07.04.2091"
    },
    {
        "engDate": "2034-07-24",
        "nepDate": "08.04.2091"
    },
    {
        "engDate": "2034-07-25",
        "nepDate": "09.04.2091"
    },
    {
        "engDate": "2034-07-26",
        "nepDate": "10.04.2091"
    },
    {
        "engDate": "2034-07-27",
        "nepDate": "11.04.2091"
    },
    {
        "engDate": "2034-07-28",
        "nepDate": "12.04.2091"
    },
    {
        "engDate": "2034-07-29",
        "nepDate": "13.04.2091"
    },
    {
        "engDate": "2034-07-30",
        "nepDate": "14.04.2091"
    },
    {
        "engDate": "2034-07-31",
        "nepDate": "15.04.2091"
    },
    {
        "engDate": "2034-08-01",
        "nepDate": "16.04.2091"
    },
    {
        "engDate": "2034-08-02",
        "nepDate": "17.04.2091"
    },
    {
        "engDate": "2034-08-03",
        "nepDate": "18.04.2091"
    },
    {
        "engDate": "2034-08-04",
        "nepDate": "19.04.2091"
    },
    {
        "engDate": "2034-08-05",
        "nepDate": "20.04.2091"
    },
    {
        "engDate": "2034-08-06",
        "nepDate": "21.04.2091"
    },
    {
        "engDate": "2034-08-07",
        "nepDate": "22.04.2091"
    },
    {
        "engDate": "2034-08-08",
        "nepDate": "23.04.2091"
    },
    {
        "engDate": "2034-08-09",
        "nepDate": "24.04.2091"
    },
    {
        "engDate": "2034-08-10",
        "nepDate": "25.04.2091"
    },
    {
        "engDate": "2034-08-11",
        "nepDate": "26.04.2091"
    },
    {
        "engDate": "2034-08-12",
        "nepDate": "27.04.2091"
    },
    {
        "engDate": "2034-08-13",
        "nepDate": "28.04.2091"
    },
    {
        "engDate": "2034-08-14",
        "nepDate": "29.04.2091"
    },
    {
        "engDate": "2034-08-15",
        "nepDate": "30.04.2091"
    },
    {
        "engDate": "2034-08-16",
        "nepDate": "31.04.2091"
    },
    {
        "engDate": "2034-08-17",
        "nepDate": "01.05.2091"
    },
    {
        "engDate": "2034-08-18",
        "nepDate": "02.05.2091"
    },
    {
        "engDate": "2034-08-19",
        "nepDate": "03.05.2091"
    },
    {
        "engDate": "2034-08-20",
        "nepDate": "04.05.2091"
    },
    {
        "engDate": "2034-08-21",
        "nepDate": "05.05.2091"
    },
    {
        "engDate": "2034-08-22",
        "nepDate": "06.05.2091"
    },
    {
        "engDate": "2034-08-23",
        "nepDate": "07.05.2091"
    },
    {
        "engDate": "2034-08-24",
        "nepDate": "08.05.2091"
    },
    {
        "engDate": "2034-08-25",
        "nepDate": "09.05.2091"
    },
    {
        "engDate": "2034-08-26",
        "nepDate": "10.05.2091"
    },
    {
        "engDate": "2034-08-27",
        "nepDate": "11.05.2091"
    },
    {
        "engDate": "2034-08-28",
        "nepDate": "12.05.2091"
    },
    {
        "engDate": "2034-08-29",
        "nepDate": "13.05.2091"
    },
    {
        "engDate": "2034-08-30",
        "nepDate": "14.05.2091"
    },
    {
        "engDate": "2034-08-31",
        "nepDate": "15.05.2091"
    },
    {
        "engDate": "2034-09-01",
        "nepDate": "16.05.2091"
    },
    {
        "engDate": "2034-09-02",
        "nepDate": "17.05.2091"
    },
    {
        "engDate": "2034-09-03",
        "nepDate": "18.05.2091"
    },
    {
        "engDate": "2034-09-04",
        "nepDate": "19.05.2091"
    },
    {
        "engDate": "2034-09-05",
        "nepDate": "20.05.2091"
    },
    {
        "engDate": "2034-09-06",
        "nepDate": "21.05.2091"
    },
    {
        "engDate": "2034-09-07",
        "nepDate": "22.05.2091"
    },
    {
        "engDate": "2034-09-08",
        "nepDate": "23.05.2091"
    },
    {
        "engDate": "2034-09-09",
        "nepDate": "24.05.2091"
    },
    {
        "engDate": "2034-09-10",
        "nepDate": "25.05.2091"
    },
    {
        "engDate": "2034-09-11",
        "nepDate": "26.05.2091"
    },
    {
        "engDate": "2034-09-12",
        "nepDate": "27.05.2091"
    },
    {
        "engDate": "2034-09-13",
        "nepDate": "28.05.2091"
    },
    {
        "engDate": "2034-09-14",
        "nepDate": "29.05.2091"
    },
    {
        "engDate": "2034-09-15",
        "nepDate": "30.05.2091"
    },
    {
        "engDate": "2034-09-16",
        "nepDate": "31.05.2091"
    },
    {
        "engDate": "2034-09-17",
        "nepDate": "01.06.2091"
    },
    {
        "engDate": "2034-09-18",
        "nepDate": "02.06.2091"
    },
    {
        "engDate": "2034-09-19",
        "nepDate": "03.06.2091"
    },
    {
        "engDate": "2034-09-20",
        "nepDate": "04.06.2091"
    },
    {
        "engDate": "2034-09-21",
        "nepDate": "05.06.2091"
    },
    {
        "engDate": "2034-09-22",
        "nepDate": "06.06.2091"
    },
    {
        "engDate": "2034-09-23",
        "nepDate": "07.06.2091"
    },
    {
        "engDate": "2034-09-24",
        "nepDate": "08.06.2091"
    },
    {
        "engDate": "2034-09-25",
        "nepDate": "09.06.2091"
    },
    {
        "engDate": "2034-09-26",
        "nepDate": "10.06.2091"
    },
    {
        "engDate": "2034-09-27",
        "nepDate": "11.06.2091"
    },
    {
        "engDate": "2034-09-28",
        "nepDate": "12.06.2091"
    },
    {
        "engDate": "2034-09-29",
        "nepDate": "13.06.2091"
    },
    {
        "engDate": "2034-09-30",
        "nepDate": "14.06.2091"
    },
    {
        "engDate": "2034-10-01",
        "nepDate": "15.06.2091"
    },
    {
        "engDate": "2034-10-02",
        "nepDate": "16.06.2091"
    },
    {
        "engDate": "2034-10-03",
        "nepDate": "17.06.2091"
    },
    {
        "engDate": "2034-10-04",
        "nepDate": "18.06.2091"
    },
    {
        "engDate": "2034-10-05",
        "nepDate": "19.06.2091"
    },
    {
        "engDate": "2034-10-06",
        "nepDate": "20.06.2091"
    },
    {
        "engDate": "2034-10-07",
        "nepDate": "21.06.2091"
    },
    {
        "engDate": "2034-10-08",
        "nepDate": "22.06.2091"
    },
    {
        "engDate": "2034-10-09",
        "nepDate": "23.06.2091"
    },
    {
        "engDate": "2034-10-10",
        "nepDate": "24.06.2091"
    },
    {
        "engDate": "2034-10-11",
        "nepDate": "25.06.2091"
    },
    {
        "engDate": "2034-10-12",
        "nepDate": "26.06.2091"
    },
    {
        "engDate": "2034-10-13",
        "nepDate": "27.06.2091"
    },
    {
        "engDate": "2034-10-14",
        "nepDate": "28.06.2091"
    },
    {
        "engDate": "2034-10-15",
        "nepDate": "29.06.2091"
    },
    {
        "engDate": "2034-10-16",
        "nepDate": "30.06.2091"
    },
    {
        "engDate": "2034-10-17",
        "nepDate": "31.06.2091"
    },
    {
        "engDate": "2034-10-18",
        "nepDate": "01.07.2091"
    },
    {
        "engDate": "2034-10-19",
        "nepDate": "02.07.2091"
    },
    {
        "engDate": "2034-10-20",
        "nepDate": "03.07.2091"
    },
    {
        "engDate": "2034-10-21",
        "nepDate": "04.07.2091"
    },
    {
        "engDate": "2034-10-22",
        "nepDate": "05.07.2091"
    },
    {
        "engDate": "2034-10-23",
        "nepDate": "06.07.2091"
    },
    {
        "engDate": "2034-10-24",
        "nepDate": "07.07.2091"
    },
    {
        "engDate": "2034-10-25",
        "nepDate": "08.07.2091"
    },
    {
        "engDate": "2034-10-26",
        "nepDate": "09.07.2091"
    },
    {
        "engDate": "2034-10-27",
        "nepDate": "10.07.2091"
    },
    {
        "engDate": "2034-10-28",
        "nepDate": "11.07.2091"
    },
    {
        "engDate": "2034-10-29",
        "nepDate": "12.07.2091"
    },
    {
        "engDate": "2034-10-30",
        "nepDate": "13.07.2091"
    },
    {
        "engDate": "2034-10-31",
        "nepDate": "14.07.2091"
    },
    {
        "engDate": "2034-11-01",
        "nepDate": "15.07.2091"
    },
    {
        "engDate": "2034-11-02",
        "nepDate": "16.07.2091"
    },
    {
        "engDate": "2034-11-03",
        "nepDate": "17.07.2091"
    },
    {
        "engDate": "2034-11-04",
        "nepDate": "18.07.2091"
    },
    {
        "engDate": "2034-11-05",
        "nepDate": "19.07.2091"
    },
    {
        "engDate": "2034-11-06",
        "nepDate": "20.07.2091"
    },
    {
        "engDate": "2034-11-07",
        "nepDate": "21.07.2091"
    },
    {
        "engDate": "2034-11-08",
        "nepDate": "22.07.2091"
    },
    {
        "engDate": "2034-11-09",
        "nepDate": "23.07.2091"
    },
    {
        "engDate": "2034-11-10",
        "nepDate": "24.07.2091"
    },
    {
        "engDate": "2034-11-11",
        "nepDate": "25.07.2091"
    },
    {
        "engDate": "2034-11-12",
        "nepDate": "26.07.2091"
    },
    {
        "engDate": "2034-11-13",
        "nepDate": "27.07.2091"
    },
    {
        "engDate": "2034-11-14",
        "nepDate": "28.07.2091"
    },
    {
        "engDate": "2034-11-15",
        "nepDate": "29.07.2091"
    },
    {
        "engDate": "2034-11-16",
        "nepDate": "30.07.2091"
    },
    {
        "engDate": "2034-11-17",
        "nepDate": "01.08.2091"
    },
    {
        "engDate": "2034-11-18",
        "nepDate": "02.08.2091"
    },
    {
        "engDate": "2034-11-19",
        "nepDate": "03.08.2091"
    },
    {
        "engDate": "2034-11-20",
        "nepDate": "04.08.2091"
    },
    {
        "engDate": "2034-11-21",
        "nepDate": "05.08.2091"
    },
    {
        "engDate": "2034-11-22",
        "nepDate": "06.08.2091"
    },
    {
        "engDate": "2034-11-23",
        "nepDate": "07.08.2091"
    },
    {
        "engDate": "2034-11-24",
        "nepDate": "08.08.2091"
    },
    {
        "engDate": "2034-11-25",
        "nepDate": "09.08.2091"
    },
    {
        "engDate": "2034-11-26",
        "nepDate": "10.08.2091"
    },
    {
        "engDate": "2034-11-27",
        "nepDate": "11.08.2091"
    },
    {
        "engDate": "2034-11-28",
        "nepDate": "12.08.2091"
    },
    {
        "engDate": "2034-11-29",
        "nepDate": "13.08.2091"
    },
    {
        "engDate": "2034-11-30",
        "nepDate": "14.08.2091"
    },
    {
        "engDate": "2034-12-01",
        "nepDate": "15.08.2091"
    },
    {
        "engDate": "2034-12-02",
        "nepDate": "16.08.2091"
    },
    {
        "engDate": "2034-12-03",
        "nepDate": "17.08.2091"
    },
    {
        "engDate": "2034-12-04",
        "nepDate": "18.08.2091"
    },
    {
        "engDate": "2034-12-05",
        "nepDate": "19.08.2091"
    },
    {
        "engDate": "2034-12-06",
        "nepDate": "20.08.2091"
    },
    {
        "engDate": "2034-12-07",
        "nepDate": "21.08.2091"
    },
    {
        "engDate": "2034-12-08",
        "nepDate": "22.08.2091"
    },
    {
        "engDate": "2034-12-09",
        "nepDate": "23.08.2091"
    },
    {
        "engDate": "2034-12-10",
        "nepDate": "24.08.2091"
    },
    {
        "engDate": "2034-12-11",
        "nepDate": "25.08.2091"
    },
    {
        "engDate": "2034-12-12",
        "nepDate": "26.08.2091"
    },
    {
        "engDate": "2034-12-13",
        "nepDate": "27.08.2091"
    },
    {
        "engDate": "2034-12-14",
        "nepDate": "28.08.2091"
    },
    {
        "engDate": "2034-12-15",
        "nepDate": "29.08.2091"
    },
    {
        "engDate": "2034-12-16",
        "nepDate": "30.08.2091"
    },
    {
        "engDate": "2034-12-17",
        "nepDate": "01.09.2091"
    },
    {
        "engDate": "2034-12-18",
        "nepDate": "02.09.2091"
    },
    {
        "engDate": "2034-12-19",
        "nepDate": "03.09.2091"
    },
    {
        "engDate": "2034-12-20",
        "nepDate": "04.09.2091"
    },
    {
        "engDate": "2034-12-21",
        "nepDate": "05.09.2091"
    },
    {
        "engDate": "2034-12-22",
        "nepDate": "06.09.2091"
    },
    {
        "engDate": "2034-12-23",
        "nepDate": "07.09.2091"
    },
    {
        "engDate": "2034-12-24",
        "nepDate": "08.09.2091"
    },
    {
        "engDate": "2034-12-25",
        "nepDate": "09.09.2091"
    },
    {
        "engDate": "2034-12-26",
        "nepDate": "10.09.2091"
    },
    {
        "engDate": "2034-12-27",
        "nepDate": "11.09.2091"
    },
    {
        "engDate": "2034-12-28",
        "nepDate": "12.09.2091"
    },
    {
        "engDate": "2034-12-29",
        "nepDate": "13.09.2091"
    },
    {
        "engDate": "2034-12-30",
        "nepDate": "14.09.2091"
    },
    {
        "engDate": "2034-12-31",
        "nepDate": "15.09.2091"
    },
    {
        "engDate": "2035-01-01",
        "nepDate": "16.09.2091"
    },
    {
        "engDate": "2035-01-02",
        "nepDate": "17.09.2091"
    },
    {
        "engDate": "2035-01-03",
        "nepDate": "18.09.2091"
    },
    {
        "engDate": "2035-01-04",
        "nepDate": "19.09.2091"
    },
    {
        "engDate": "2035-01-05",
        "nepDate": "20.09.2091"
    },
    {
        "engDate": "2035-01-06",
        "nepDate": "21.09.2091"
    },
    {
        "engDate": "2035-01-07",
        "nepDate": "22.09.2091"
    },
    {
        "engDate": "2035-01-08",
        "nepDate": "23.09.2091"
    },
    {
        "engDate": "2035-01-09",
        "nepDate": "24.09.2091"
    },
    {
        "engDate": "2035-01-10",
        "nepDate": "25.09.2091"
    },
    {
        "engDate": "2035-01-11",
        "nepDate": "26.09.2091"
    },
    {
        "engDate": "2035-01-12",
        "nepDate": "27.09.2091"
    },
    {
        "engDate": "2035-01-13",
        "nepDate": "28.09.2091"
    },
    {
        "engDate": "2035-01-14",
        "nepDate": "29.09.2091"
    },
    {
        "engDate": "2035-01-15",
        "nepDate": "01.10.2091"
    },
    {
        "engDate": "2035-01-16",
        "nepDate": "02.10.2091"
    },
    {
        "engDate": "2035-01-17",
        "nepDate": "03.10.2091"
    },
    {
        "engDate": "2035-01-18",
        "nepDate": "04.10.2091"
    },
    {
        "engDate": "2035-01-19",
        "nepDate": "05.10.2091"
    },
    {
        "engDate": "2035-01-20",
        "nepDate": "06.10.2091"
    },
    {
        "engDate": "2035-01-21",
        "nepDate": "07.10.2091"
    },
    {
        "engDate": "2035-01-22",
        "nepDate": "08.10.2091"
    },
    {
        "engDate": "2035-01-23",
        "nepDate": "09.10.2091"
    },
    {
        "engDate": "2035-01-24",
        "nepDate": "10.10.2091"
    },
    {
        "engDate": "2035-01-25",
        "nepDate": "11.10.2091"
    },
    {
        "engDate": "2035-01-26",
        "nepDate": "12.10.2091"
    },
    {
        "engDate": "2035-01-27",
        "nepDate": "13.10.2091"
    },
    {
        "engDate": "2035-01-28",
        "nepDate": "14.10.2091"
    },
    {
        "engDate": "2035-01-29",
        "nepDate": "15.10.2091"
    },
    {
        "engDate": "2035-01-30",
        "nepDate": "16.10.2091"
    },
    {
        "engDate": "2035-01-31",
        "nepDate": "17.10.2091"
    },
    {
        "engDate": "2035-02-01",
        "nepDate": "18.10.2091"
    },
    {
        "engDate": "2035-02-02",
        "nepDate": "19.10.2091"
    },
    {
        "engDate": "2035-02-03",
        "nepDate": "20.10.2091"
    },
    {
        "engDate": "2035-02-04",
        "nepDate": "21.10.2091"
    },
    {
        "engDate": "2035-02-05",
        "nepDate": "22.10.2091"
    },
    {
        "engDate": "2035-02-06",
        "nepDate": "23.10.2091"
    },
    {
        "engDate": "2035-02-07",
        "nepDate": "24.10.2091"
    },
    {
        "engDate": "2035-02-08",
        "nepDate": "25.10.2091"
    },
    {
        "engDate": "2035-02-09",
        "nepDate": "26.10.2091"
    },
    {
        "engDate": "2035-02-10",
        "nepDate": "27.10.2091"
    },
    {
        "engDate": "2035-02-11",
        "nepDate": "28.10.2091"
    },
    {
        "engDate": "2035-02-12",
        "nepDate": "29.10.2091"
    },
    {
        "engDate": "2035-02-13",
        "nepDate": "30.10.2091"
    },
    {
        "engDate": "2035-02-14",
        "nepDate": "01.11.2091"
    },
    {
        "engDate": "2035-02-15",
        "nepDate": "02.11.2091"
    },
    {
        "engDate": "2035-02-16",
        "nepDate": "03.11.2091"
    },
    {
        "engDate": "2035-02-17",
        "nepDate": "04.11.2091"
    },
    {
        "engDate": "2035-02-18",
        "nepDate": "05.11.2091"
    },
    {
        "engDate": "2035-02-19",
        "nepDate": "06.11.2091"
    },
    {
        "engDate": "2035-02-20",
        "nepDate": "07.11.2091"
    },
    {
        "engDate": "2035-02-21",
        "nepDate": "08.11.2091"
    },
    {
        "engDate": "2035-02-22",
        "nepDate": "09.11.2091"
    },
    {
        "engDate": "2035-02-23",
        "nepDate": "10.11.2091"
    },
    {
        "engDate": "2035-02-24",
        "nepDate": "11.11.2091"
    },
    {
        "engDate": "2035-02-25",
        "nepDate": "12.11.2091"
    },
    {
        "engDate": "2035-02-26",
        "nepDate": "13.11.2091"
    },
    {
        "engDate": "2035-02-27",
        "nepDate": "14.11.2091"
    },
    {
        "engDate": "2035-02-28",
        "nepDate": "15.11.2091"
    },
    {
        "engDate": "2035-03-01",
        "nepDate": "16.11.2091"
    },
    {
        "engDate": "2035-03-02",
        "nepDate": "17.11.2091"
    },
    {
        "engDate": "2035-03-03",
        "nepDate": "18.11.2091"
    },
    {
        "engDate": "2035-03-04",
        "nepDate": "19.11.2091"
    },
    {
        "engDate": "2035-03-05",
        "nepDate": "20.11.2091"
    },
    {
        "engDate": "2035-03-06",
        "nepDate": "21.11.2091"
    },
    {
        "engDate": "2035-03-07",
        "nepDate": "22.11.2091"
    },
    {
        "engDate": "2035-03-08",
        "nepDate": "23.11.2091"
    },
    {
        "engDate": "2035-03-09",
        "nepDate": "24.11.2091"
    },
    {
        "engDate": "2035-03-10",
        "nepDate": "25.11.2091"
    },
    {
        "engDate": "2035-03-11",
        "nepDate": "26.11.2091"
    },
    {
        "engDate": "2035-03-12",
        "nepDate": "27.11.2091"
    },
    {
        "engDate": "2035-03-13",
        "nepDate": "28.11.2091"
    },
    {
        "engDate": "2035-03-14",
        "nepDate": "29.11.2091"
    },
    {
        "engDate": "2035-03-15",
        "nepDate": "30.11.2091"
    },
    {
        "engDate": "2035-03-16",
        "nepDate": "01.12.2091"
    },
    {
        "engDate": "2035-03-17",
        "nepDate": "02.12.2091"
    },
    {
        "engDate": "2035-03-18",
        "nepDate": "03.12.2091"
    },
    {
        "engDate": "2035-03-19",
        "nepDate": "04.12.2091"
    },
    {
        "engDate": "2035-03-20",
        "nepDate": "05.12.2091"
    },
    {
        "engDate": "2035-03-21",
        "nepDate": "06.12.2091"
    },
    {
        "engDate": "2035-03-22",
        "nepDate": "07.12.2091"
    },
    {
        "engDate": "2035-03-23",
        "nepDate": "08.12.2091"
    },
    {
        "engDate": "2035-03-24",
        "nepDate": "09.12.2091"
    },
    {
        "engDate": "2035-03-25",
        "nepDate": "10.12.2091"
    },
    {
        "engDate": "2035-03-26",
        "nepDate": "11.12.2091"
    },
    {
        "engDate": "2035-03-27",
        "nepDate": "12.12.2091"
    },
    {
        "engDate": "2035-03-28",
        "nepDate": "13.12.2091"
    },
    {
        "engDate": "2035-03-29",
        "nepDate": "14.12.2091"
    },
    {
        "engDate": "2035-03-30",
        "nepDate": "15.12.2091"
    },
    {
        "engDate": "2035-03-31",
        "nepDate": "16.12.2091"
    },
    {
        "engDate": "2035-04-01",
        "nepDate": "17.12.2091"
    },
    {
        "engDate": "2035-04-02",
        "nepDate": "18.12.2091"
    },
    {
        "engDate": "2035-04-03",
        "nepDate": "19.12.2091"
    },
    {
        "engDate": "2035-04-04",
        "nepDate": "20.12.2091"
    },
    {
        "engDate": "2035-04-05",
        "nepDate": "21.12.2091"
    },
    {
        "engDate": "2035-04-06",
        "nepDate": "22.12.2091"
    },
    {
        "engDate": "2035-04-07",
        "nepDate": "23.12.2091"
    },
    {
        "engDate": "2035-04-08",
        "nepDate": "24.12.2091"
    },
    {
        "engDate": "2035-04-09",
        "nepDate": "25.12.2091"
    },
    {
        "engDate": "2035-04-10",
        "nepDate": "26.12.2091"
    },
    {
        "engDate": "2035-04-11",
        "nepDate": "27.12.2091"
    },
    {
        "engDate": "2035-04-12",
        "nepDate": "28.12.2091"
    },
    {
        "engDate": "2035-04-13",
        "nepDate": "29.12.2091"
    },
    {
        "engDate": "2035-04-14",
        "nepDate": "30.12.2091"
    },
    {
        "engDate": "2035-04-15",
        "nepDate": "01.01.2092"
    },
    {
        "engDate": "2035-04-16",
        "nepDate": "02.01.2092"
    },
    {
        "engDate": "2035-04-17",
        "nepDate": "03.01.2092"
    },
    {
        "engDate": "2035-04-18",
        "nepDate": "04.01.2092"
    },
    {
        "engDate": "2035-04-19",
        "nepDate": "05.01.2092"
    },
    {
        "engDate": "2035-04-20",
        "nepDate": "06.01.2092"
    },
    {
        "engDate": "2035-04-21",
        "nepDate": "07.01.2092"
    },
    {
        "engDate": "2035-04-22",
        "nepDate": "08.01.2092"
    },
    {
        "engDate": "2035-04-23",
        "nepDate": "09.01.2092"
    },
    {
        "engDate": "2035-04-24",
        "nepDate": "10.01.2092"
    },
    {
        "engDate": "2035-04-25",
        "nepDate": "11.01.2092"
    },
    {
        "engDate": "2035-04-26",
        "nepDate": "12.01.2092"
    },
    {
        "engDate": "2035-04-27",
        "nepDate": "13.01.2092"
    },
    {
        "engDate": "2035-04-28",
        "nepDate": "14.01.2092"
    },
    {
        "engDate": "2035-04-29",
        "nepDate": "15.01.2092"
    },
    {
        "engDate": "2035-04-30",
        "nepDate": "16.01.2092"
    },
    {
        "engDate": "2035-05-01",
        "nepDate": "17.01.2092"
    },
    {
        "engDate": "2035-05-02",
        "nepDate": "18.01.2092"
    },
    {
        "engDate": "2035-05-03",
        "nepDate": "19.01.2092"
    },
    {
        "engDate": "2035-05-04",
        "nepDate": "20.01.2092"
    },
    {
        "engDate": "2035-05-05",
        "nepDate": "21.01.2092"
    },
    {
        "engDate": "2035-05-06",
        "nepDate": "22.01.2092"
    },
    {
        "engDate": "2035-05-07",
        "nepDate": "23.01.2092"
    },
    {
        "engDate": "2035-05-08",
        "nepDate": "24.01.2092"
    },
    {
        "engDate": "2035-05-09",
        "nepDate": "25.01.2092"
    },
    {
        "engDate": "2035-05-10",
        "nepDate": "26.01.2092"
    },
    {
        "engDate": "2035-05-11",
        "nepDate": "27.01.2092"
    },
    {
        "engDate": "2035-05-12",
        "nepDate": "28.01.2092"
    },
    {
        "engDate": "2035-05-13",
        "nepDate": "29.01.2092"
    },
    {
        "engDate": "2035-05-14",
        "nepDate": "30.01.2092"
    },
    {
        "engDate": "2035-05-15",
        "nepDate": "01.02.2092"
    },
    {
        "engDate": "2035-05-16",
        "nepDate": "02.02.2092"
    },
    {
        "engDate": "2035-05-17",
        "nepDate": "03.02.2092"
    },
    {
        "engDate": "2035-05-18",
        "nepDate": "04.02.2092"
    },
    {
        "engDate": "2035-05-19",
        "nepDate": "05.02.2092"
    },
    {
        "engDate": "2035-05-20",
        "nepDate": "06.02.2092"
    },
    {
        "engDate": "2035-05-21",
        "nepDate": "07.02.2092"
    },
    {
        "engDate": "2035-05-22",
        "nepDate": "08.02.2092"
    },
    {
        "engDate": "2035-05-23",
        "nepDate": "09.02.2092"
    },
    {
        "engDate": "2035-05-24",
        "nepDate": "10.02.2092"
    },
    {
        "engDate": "2035-05-25",
        "nepDate": "11.02.2092"
    },
    {
        "engDate": "2035-05-26",
        "nepDate": "12.02.2092"
    },
    {
        "engDate": "2035-05-27",
        "nepDate": "13.02.2092"
    },
    {
        "engDate": "2035-05-28",
        "nepDate": "14.02.2092"
    },
    {
        "engDate": "2035-05-29",
        "nepDate": "15.02.2092"
    },
    {
        "engDate": "2035-05-30",
        "nepDate": "16.02.2092"
    },
    {
        "engDate": "2035-05-31",
        "nepDate": "17.02.2092"
    },
    {
        "engDate": "2035-06-01",
        "nepDate": "18.02.2092"
    },
    {
        "engDate": "2035-06-02",
        "nepDate": "19.02.2092"
    },
    {
        "engDate": "2035-06-03",
        "nepDate": "20.02.2092"
    },
    {
        "engDate": "2035-06-04",
        "nepDate": "21.02.2092"
    },
    {
        "engDate": "2035-06-05",
        "nepDate": "22.02.2092"
    },
    {
        "engDate": "2035-06-06",
        "nepDate": "23.02.2092"
    },
    {
        "engDate": "2035-06-07",
        "nepDate": "24.02.2092"
    },
    {
        "engDate": "2035-06-08",
        "nepDate": "25.02.2092"
    },
    {
        "engDate": "2035-06-09",
        "nepDate": "26.02.2092"
    },
    {
        "engDate": "2035-06-10",
        "nepDate": "27.02.2092"
    },
    {
        "engDate": "2035-06-11",
        "nepDate": "28.02.2092"
    },
    {
        "engDate": "2035-06-12",
        "nepDate": "29.02.2092"
    },
    {
        "engDate": "2035-06-13",
        "nepDate": "30.02.2092"
    },
    {
        "engDate": "2035-06-14",
        "nepDate": "31.02.2092"
    },
    {
        "engDate": "2035-06-15",
        "nepDate": "01.03.2092"
    },
    {
        "engDate": "2035-06-16",
        "nepDate": "02.03.2092"
    },
    {
        "engDate": "2035-06-17",
        "nepDate": "03.03.2092"
    },
    {
        "engDate": "2035-06-18",
        "nepDate": "04.03.2092"
    },
    {
        "engDate": "2035-06-19",
        "nepDate": "05.03.2092"
    },
    {
        "engDate": "2035-06-20",
        "nepDate": "06.03.2092"
    },
    {
        "engDate": "2035-06-21",
        "nepDate": "07.03.2092"
    },
    {
        "engDate": "2035-06-22",
        "nepDate": "08.03.2092"
    },
    {
        "engDate": "2035-06-23",
        "nepDate": "09.03.2092"
    },
    {
        "engDate": "2035-06-24",
        "nepDate": "10.03.2092"
    },
    {
        "engDate": "2035-06-25",
        "nepDate": "11.03.2092"
    },
    {
        "engDate": "2035-06-26",
        "nepDate": "12.03.2092"
    },
    {
        "engDate": "2035-06-27",
        "nepDate": "13.03.2092"
    },
    {
        "engDate": "2035-06-28",
        "nepDate": "14.03.2092"
    },
    {
        "engDate": "2035-06-29",
        "nepDate": "15.03.2092"
    },
    {
        "engDate": "2035-06-30",
        "nepDate": "16.03.2092"
    },
    {
        "engDate": "2035-07-01",
        "nepDate": "17.03.2092"
    },
    {
        "engDate": "2035-07-02",
        "nepDate": "18.03.2092"
    },
    {
        "engDate": "2035-07-03",
        "nepDate": "19.03.2092"
    },
    {
        "engDate": "2035-07-04",
        "nepDate": "20.03.2092"
    },
    {
        "engDate": "2035-07-05",
        "nepDate": "21.03.2092"
    },
    {
        "engDate": "2035-07-06",
        "nepDate": "22.03.2092"
    },
    {
        "engDate": "2035-07-07",
        "nepDate": "23.03.2092"
    },
    {
        "engDate": "2035-07-08",
        "nepDate": "24.03.2092"
    },
    {
        "engDate": "2035-07-09",
        "nepDate": "25.03.2092"
    },
    {
        "engDate": "2035-07-10",
        "nepDate": "26.03.2092"
    },
    {
        "engDate": "2035-07-11",
        "nepDate": "27.03.2092"
    },
    {
        "engDate": "2035-07-12",
        "nepDate": "28.03.2092"
    },
    {
        "engDate": "2035-07-13",
        "nepDate": "29.03.2092"
    },
    {
        "engDate": "2035-07-14",
        "nepDate": "30.03.2092"
    },
    {
        "engDate": "2035-07-15",
        "nepDate": "31.03.2092"
    },
    {
        "engDate": "2035-07-16",
        "nepDate": "32.03.2092"
    },
    {
        "engDate": "2035-07-17",
        "nepDate": "01.04.2092"
    },
    {
        "engDate": "2035-07-18",
        "nepDate": "02.04.2092"
    },
    {
        "engDate": "2035-07-19",
        "nepDate": "03.04.2092"
    },
    {
        "engDate": "2035-07-20",
        "nepDate": "04.04.2092"
    },
    {
        "engDate": "2035-07-21",
        "nepDate": "05.04.2092"
    },
    {
        "engDate": "2035-07-22",
        "nepDate": "06.04.2092"
    },
    {
        "engDate": "2035-07-23",
        "nepDate": "07.04.2092"
    },
    {
        "engDate": "2035-07-24",
        "nepDate": "08.04.2092"
    },
    {
        "engDate": "2035-07-25",
        "nepDate": "09.04.2092"
    },
    {
        "engDate": "2035-07-26",
        "nepDate": "10.04.2092"
    },
    {
        "engDate": "2035-07-27",
        "nepDate": "11.04.2092"
    },
    {
        "engDate": "2035-07-28",
        "nepDate": "12.04.2092"
    },
    {
        "engDate": "2035-07-29",
        "nepDate": "13.04.2092"
    },
    {
        "engDate": "2035-07-30",
        "nepDate": "14.04.2092"
    },
    {
        "engDate": "2035-07-31",
        "nepDate": "15.04.2092"
    },
    {
        "engDate": "2035-08-01",
        "nepDate": "16.04.2092"
    },
    {
        "engDate": "2035-08-02",
        "nepDate": "17.04.2092"
    },
    {
        "engDate": "2035-08-03",
        "nepDate": "18.04.2092"
    },
    {
        "engDate": "2035-08-04",
        "nepDate": "19.04.2092"
    },
    {
        "engDate": "2035-08-05",
        "nepDate": "20.04.2092"
    },
    {
        "engDate": "2035-08-06",
        "nepDate": "21.04.2092"
    },
    {
        "engDate": "2035-08-07",
        "nepDate": "22.04.2092"
    },
    {
        "engDate": "2035-08-08",
        "nepDate": "23.04.2092"
    },
    {
        "engDate": "2035-08-09",
        "nepDate": "24.04.2092"
    },
    {
        "engDate": "2035-08-10",
        "nepDate": "25.04.2092"
    },
    {
        "engDate": "2035-08-11",
        "nepDate": "26.04.2092"
    },
    {
        "engDate": "2035-08-12",
        "nepDate": "27.04.2092"
    },
    {
        "engDate": "2035-08-13",
        "nepDate": "28.04.2092"
    },
    {
        "engDate": "2035-08-14",
        "nepDate": "29.04.2092"
    },
    {
        "engDate": "2035-08-15",
        "nepDate": "30.04.2092"
    },
    {
        "engDate": "2035-08-16",
        "nepDate": "31.04.2092"
    },
    {
        "engDate": "2035-08-17",
        "nepDate": "32.04.2092"
    },
    {
        "engDate": "2035-08-18",
        "nepDate": "01.05.2092"
    },
    {
        "engDate": "2035-08-19",
        "nepDate": "02.05.2092"
    },
    {
        "engDate": "2035-08-20",
        "nepDate": "03.05.2092"
    },
    {
        "engDate": "2035-08-21",
        "nepDate": "04.05.2092"
    },
    {
        "engDate": "2035-08-22",
        "nepDate": "05.05.2092"
    },
    {
        "engDate": "2035-08-23",
        "nepDate": "06.05.2092"
    },
    {
        "engDate": "2035-08-24",
        "nepDate": "07.05.2092"
    },
    {
        "engDate": "2035-08-25",
        "nepDate": "08.05.2092"
    },
    {
        "engDate": "2035-08-26",
        "nepDate": "09.05.2092"
    },
    {
        "engDate": "2035-08-27",
        "nepDate": "10.05.2092"
    },
    {
        "engDate": "2035-08-28",
        "nepDate": "11.05.2092"
    },
    {
        "engDate": "2035-08-29",
        "nepDate": "12.05.2092"
    },
    {
        "engDate": "2035-08-30",
        "nepDate": "13.05.2092"
    },
    {
        "engDate": "2035-08-31",
        "nepDate": "14.05.2092"
    },
    {
        "engDate": "2035-09-01",
        "nepDate": "15.05.2092"
    },
    {
        "engDate": "2035-09-02",
        "nepDate": "16.05.2092"
    },
    {
        "engDate": "2035-09-03",
        "nepDate": "17.05.2092"
    },
    {
        "engDate": "2035-09-04",
        "nepDate": "18.05.2092"
    },
    {
        "engDate": "2035-09-05",
        "nepDate": "19.05.2092"
    },
    {
        "engDate": "2035-09-06",
        "nepDate": "20.05.2092"
    },
    {
        "engDate": "2035-09-07",
        "nepDate": "21.05.2092"
    },
    {
        "engDate": "2035-09-08",
        "nepDate": "22.05.2092"
    },
    {
        "engDate": "2035-09-09",
        "nepDate": "23.05.2092"
    },
    {
        "engDate": "2035-09-10",
        "nepDate": "24.05.2092"
    },
    {
        "engDate": "2035-09-11",
        "nepDate": "25.05.2092"
    },
    {
        "engDate": "2035-09-12",
        "nepDate": "26.05.2092"
    },
    {
        "engDate": "2035-09-13",
        "nepDate": "27.05.2092"
    },
    {
        "engDate": "2035-09-14",
        "nepDate": "28.05.2092"
    },
    {
        "engDate": "2035-09-15",
        "nepDate": "29.05.2092"
    },
    {
        "engDate": "2035-09-16",
        "nepDate": "30.05.2092"
    },
    {
        "engDate": "2035-09-17",
        "nepDate": "31.05.2092"
    },
    {
        "engDate": "2035-09-18",
        "nepDate": "01.06.2092"
    },
    {
        "engDate": "2035-09-19",
        "nepDate": "02.06.2092"
    },
    {
        "engDate": "2035-09-20",
        "nepDate": "03.06.2092"
    },
    {
        "engDate": "2035-09-21",
        "nepDate": "04.06.2092"
    },
    {
        "engDate": "2035-09-22",
        "nepDate": "05.06.2092"
    },
    {
        "engDate": "2035-09-23",
        "nepDate": "06.06.2092"
    },
    {
        "engDate": "2035-09-24",
        "nepDate": "07.06.2092"
    },
    {
        "engDate": "2035-09-25",
        "nepDate": "08.06.2092"
    },
    {
        "engDate": "2035-09-26",
        "nepDate": "09.06.2092"
    },
    {
        "engDate": "2035-09-27",
        "nepDate": "10.06.2092"
    },
    {
        "engDate": "2035-09-28",
        "nepDate": "11.06.2092"
    },
    {
        "engDate": "2035-09-29",
        "nepDate": "12.06.2092"
    },
    {
        "engDate": "2035-09-30",
        "nepDate": "13.06.2092"
    },
    {
        "engDate": "2035-10-01",
        "nepDate": "14.06.2092"
    },
    {
        "engDate": "2035-10-02",
        "nepDate": "15.06.2092"
    },
    {
        "engDate": "2035-10-03",
        "nepDate": "16.06.2092"
    },
    {
        "engDate": "2035-10-04",
        "nepDate": "17.06.2092"
    },
    {
        "engDate": "2035-10-05",
        "nepDate": "18.06.2092"
    },
    {
        "engDate": "2035-10-06",
        "nepDate": "19.06.2092"
    },
    {
        "engDate": "2035-10-07",
        "nepDate": "20.06.2092"
    },
    {
        "engDate": "2035-10-08",
        "nepDate": "21.06.2092"
    },
    {
        "engDate": "2035-10-09",
        "nepDate": "22.06.2092"
    },
    {
        "engDate": "2035-10-10",
        "nepDate": "23.06.2092"
    },
    {
        "engDate": "2035-10-11",
        "nepDate": "24.06.2092"
    },
    {
        "engDate": "2035-10-12",
        "nepDate": "25.06.2092"
    },
    {
        "engDate": "2035-10-13",
        "nepDate": "26.06.2092"
    },
    {
        "engDate": "2035-10-14",
        "nepDate": "27.06.2092"
    },
    {
        "engDate": "2035-10-15",
        "nepDate": "28.06.2092"
    },
    {
        "engDate": "2035-10-16",
        "nepDate": "29.06.2092"
    },
    {
        "engDate": "2035-10-17",
        "nepDate": "30.06.2092"
    },
    {
        "engDate": "2035-10-18",
        "nepDate": "01.07.2092"
    },
    {
        "engDate": "2035-10-19",
        "nepDate": "02.07.2092"
    },
    {
        "engDate": "2035-10-20",
        "nepDate": "03.07.2092"
    },
    {
        "engDate": "2035-10-21",
        "nepDate": "04.07.2092"
    },
    {
        "engDate": "2035-10-22",
        "nepDate": "05.07.2092"
    },
    {
        "engDate": "2035-10-23",
        "nepDate": "06.07.2092"
    },
    {
        "engDate": "2035-10-24",
        "nepDate": "07.07.2092"
    },
    {
        "engDate": "2035-10-25",
        "nepDate": "08.07.2092"
    },
    {
        "engDate": "2035-10-26",
        "nepDate": "09.07.2092"
    },
    {
        "engDate": "2035-10-27",
        "nepDate": "10.07.2092"
    },
    {
        "engDate": "2035-10-28",
        "nepDate": "11.07.2092"
    },
    {
        "engDate": "2035-10-29",
        "nepDate": "12.07.2092"
    },
    {
        "engDate": "2035-10-30",
        "nepDate": "13.07.2092"
    },
    {
        "engDate": "2035-10-31",
        "nepDate": "14.07.2092"
    },
    {
        "engDate": "2035-11-01",
        "nepDate": "15.07.2092"
    },
    {
        "engDate": "2035-11-02",
        "nepDate": "16.07.2092"
    },
    {
        "engDate": "2035-11-03",
        "nepDate": "17.07.2092"
    },
    {
        "engDate": "2035-11-04",
        "nepDate": "18.07.2092"
    },
    {
        "engDate": "2035-11-05",
        "nepDate": "19.07.2092"
    },
    {
        "engDate": "2035-11-06",
        "nepDate": "20.07.2092"
    },
    {
        "engDate": "2035-11-07",
        "nepDate": "21.07.2092"
    },
    {
        "engDate": "2035-11-08",
        "nepDate": "22.07.2092"
    },
    {
        "engDate": "2035-11-09",
        "nepDate": "23.07.2092"
    },
    {
        "engDate": "2035-11-10",
        "nepDate": "24.07.2092"
    },
    {
        "engDate": "2035-11-11",
        "nepDate": "25.07.2092"
    },
    {
        "engDate": "2035-11-12",
        "nepDate": "26.07.2092"
    },
    {
        "engDate": "2035-11-13",
        "nepDate": "27.07.2092"
    },
    {
        "engDate": "2035-11-14",
        "nepDate": "28.07.2092"
    },
    {
        "engDate": "2035-11-15",
        "nepDate": "29.07.2092"
    },
    {
        "engDate": "2035-11-16",
        "nepDate": "30.07.2092"
    },
    {
        "engDate": "2035-11-17",
        "nepDate": "01.08.2092"
    },
    {
        "engDate": "2035-11-18",
        "nepDate": "02.08.2092"
    },
    {
        "engDate": "2035-11-19",
        "nepDate": "03.08.2092"
    },
    {
        "engDate": "2035-11-20",
        "nepDate": "04.08.2092"
    },
    {
        "engDate": "2035-11-21",
        "nepDate": "05.08.2092"
    },
    {
        "engDate": "2035-11-22",
        "nepDate": "06.08.2092"
    },
    {
        "engDate": "2035-11-23",
        "nepDate": "07.08.2092"
    },
    {
        "engDate": "2035-11-24",
        "nepDate": "08.08.2092"
    },
    {
        "engDate": "2035-11-25",
        "nepDate": "09.08.2092"
    },
    {
        "engDate": "2035-11-26",
        "nepDate": "10.08.2092"
    },
    {
        "engDate": "2035-11-27",
        "nepDate": "11.08.2092"
    },
    {
        "engDate": "2035-11-28",
        "nepDate": "12.08.2092"
    },
    {
        "engDate": "2035-11-29",
        "nepDate": "13.08.2092"
    },
    {
        "engDate": "2035-11-30",
        "nepDate": "14.08.2092"
    },
    {
        "engDate": "2035-12-01",
        "nepDate": "15.08.2092"
    },
    {
        "engDate": "2035-12-02",
        "nepDate": "16.08.2092"
    },
    {
        "engDate": "2035-12-03",
        "nepDate": "17.08.2092"
    },
    {
        "engDate": "2035-12-04",
        "nepDate": "18.08.2092"
    },
    {
        "engDate": "2035-12-05",
        "nepDate": "19.08.2092"
    },
    {
        "engDate": "2035-12-06",
        "nepDate": "20.08.2092"
    },
    {
        "engDate": "2035-12-07",
        "nepDate": "21.08.2092"
    },
    {
        "engDate": "2035-12-08",
        "nepDate": "22.08.2092"
    },
    {
        "engDate": "2035-12-09",
        "nepDate": "23.08.2092"
    },
    {
        "engDate": "2035-12-10",
        "nepDate": "24.08.2092"
    },
    {
        "engDate": "2035-12-11",
        "nepDate": "25.08.2092"
    },
    {
        "engDate": "2035-12-12",
        "nepDate": "26.08.2092"
    },
    {
        "engDate": "2035-12-13",
        "nepDate": "27.08.2092"
    },
    {
        "engDate": "2035-12-14",
        "nepDate": "28.08.2092"
    },
    {
        "engDate": "2035-12-15",
        "nepDate": "29.08.2092"
    },
    {
        "engDate": "2035-12-16",
        "nepDate": "30.08.2092"
    },
    {
        "engDate": "2035-12-17",
        "nepDate": "01.09.2092"
    },
    {
        "engDate": "2035-12-18",
        "nepDate": "02.09.2092"
    },
    {
        "engDate": "2035-12-19",
        "nepDate": "03.09.2092"
    },
    {
        "engDate": "2035-12-20",
        "nepDate": "04.09.2092"
    },
    {
        "engDate": "2035-12-21",
        "nepDate": "05.09.2092"
    },
    {
        "engDate": "2035-12-22",
        "nepDate": "06.09.2092"
    },
    {
        "engDate": "2035-12-23",
        "nepDate": "07.09.2092"
    },
    {
        "engDate": "2035-12-24",
        "nepDate": "08.09.2092"
    },
    {
        "engDate": "2035-12-25",
        "nepDate": "09.09.2092"
    },
    {
        "engDate": "2035-12-26",
        "nepDate": "10.09.2092"
    },
    {
        "engDate": "2035-12-27",
        "nepDate": "11.09.2092"
    },
    {
        "engDate": "2035-12-28",
        "nepDate": "12.09.2092"
    },
    {
        "engDate": "2035-12-29",
        "nepDate": "13.09.2092"
    },
    {
        "engDate": "2035-12-30",
        "nepDate": "14.09.2092"
    },
    {
        "engDate": "2035-12-31",
        "nepDate": "15.09.2092"
    },
    {
        "engDate": "2036-01-01",
        "nepDate": "16.09.2092"
    },
    {
        "engDate": "2036-01-02",
        "nepDate": "17.09.2092"
    },
    {
        "engDate": "2036-01-03",
        "nepDate": "18.09.2092"
    },
    {
        "engDate": "2036-01-04",
        "nepDate": "19.09.2092"
    },
    {
        "engDate": "2036-01-05",
        "nepDate": "20.09.2092"
    },
    {
        "engDate": "2036-01-06",
        "nepDate": "21.09.2092"
    },
    {
        "engDate": "2036-01-07",
        "nepDate": "22.09.2092"
    },
    {
        "engDate": "2036-01-08",
        "nepDate": "23.09.2092"
    },
    {
        "engDate": "2036-01-09",
        "nepDate": "24.09.2092"
    },
    {
        "engDate": "2036-01-10",
        "nepDate": "25.09.2092"
    },
    {
        "engDate": "2036-01-11",
        "nepDate": "26.09.2092"
    },
    {
        "engDate": "2036-01-12",
        "nepDate": "27.09.2092"
    },
    {
        "engDate": "2036-01-13",
        "nepDate": "28.09.2092"
    },
    {
        "engDate": "2036-01-14",
        "nepDate": "29.09.2092"
    },
    {
        "engDate": "2036-01-15",
        "nepDate": "01.10.2092"
    },
    {
        "engDate": "2036-01-16",
        "nepDate": "02.10.2092"
    },
    {
        "engDate": "2036-01-17",
        "nepDate": "03.10.2092"
    },
    {
        "engDate": "2036-01-18",
        "nepDate": "04.10.2092"
    },
    {
        "engDate": "2036-01-19",
        "nepDate": "05.10.2092"
    },
    {
        "engDate": "2036-01-20",
        "nepDate": "06.10.2092"
    },
    {
        "engDate": "2036-01-21",
        "nepDate": "07.10.2092"
    },
    {
        "engDate": "2036-01-22",
        "nepDate": "08.10.2092"
    },
    {
        "engDate": "2036-01-23",
        "nepDate": "09.10.2092"
    },
    {
        "engDate": "2036-01-24",
        "nepDate": "10.10.2092"
    },
    {
        "engDate": "2036-01-25",
        "nepDate": "11.10.2092"
    },
    {
        "engDate": "2036-01-26",
        "nepDate": "12.10.2092"
    },
    {
        "engDate": "2036-01-27",
        "nepDate": "13.10.2092"
    },
    {
        "engDate": "2036-01-28",
        "nepDate": "14.10.2092"
    },
    {
        "engDate": "2036-01-29",
        "nepDate": "15.10.2092"
    },
    {
        "engDate": "2036-01-30",
        "nepDate": "16.10.2092"
    },
    {
        "engDate": "2036-01-31",
        "nepDate": "17.10.2092"
    },
    {
        "engDate": "2036-02-01",
        "nepDate": "18.10.2092"
    },
    {
        "engDate": "2036-02-02",
        "nepDate": "19.10.2092"
    },
    {
        "engDate": "2036-02-03",
        "nepDate": "20.10.2092"
    },
    {
        "engDate": "2036-02-04",
        "nepDate": "21.10.2092"
    },
    {
        "engDate": "2036-02-05",
        "nepDate": "22.10.2092"
    },
    {
        "engDate": "2036-02-06",
        "nepDate": "23.10.2092"
    },
    {
        "engDate": "2036-02-07",
        "nepDate": "24.10.2092"
    },
    {
        "engDate": "2036-02-08",
        "nepDate": "25.10.2092"
    },
    {
        "engDate": "2036-02-09",
        "nepDate": "26.10.2092"
    },
    {
        "engDate": "2036-02-10",
        "nepDate": "27.10.2092"
    },
    {
        "engDate": "2036-02-11",
        "nepDate": "28.10.2092"
    },
    {
        "engDate": "2036-02-12",
        "nepDate": "29.10.2092"
    },
    {
        "engDate": "2036-02-13",
        "nepDate": "30.10.2092"
    },
    {
        "engDate": "2036-02-14",
        "nepDate": "01.11.2092"
    },
    {
        "engDate": "2036-02-15",
        "nepDate": "02.11.2092"
    },
    {
        "engDate": "2036-02-16",
        "nepDate": "03.11.2092"
    },
    {
        "engDate": "2036-02-17",
        "nepDate": "04.11.2092"
    },
    {
        "engDate": "2036-02-18",
        "nepDate": "05.11.2092"
    },
    {
        "engDate": "2036-02-19",
        "nepDate": "06.11.2092"
    },
    {
        "engDate": "2036-02-20",
        "nepDate": "07.11.2092"
    },
    {
        "engDate": "2036-02-21",
        "nepDate": "08.11.2092"
    },
    {
        "engDate": "2036-02-22",
        "nepDate": "09.11.2092"
    },
    {
        "engDate": "2036-02-23",
        "nepDate": "10.11.2092"
    },
    {
        "engDate": "2036-02-24",
        "nepDate": "11.11.2092"
    },
    {
        "engDate": "2036-02-25",
        "nepDate": "12.11.2092"
    },
    {
        "engDate": "2036-02-26",
        "nepDate": "13.11.2092"
    },
    {
        "engDate": "2036-02-27",
        "nepDate": "14.11.2092"
    },
    {
        "engDate": "2036-02-28",
        "nepDate": "15.11.2092"
    },
    {
        "engDate": "2036-02-29",
        "nepDate": "16.11.2092"
    },
    {
        "engDate": "2036-03-01",
        "nepDate": "17.11.2092"
    },
    {
        "engDate": "2036-03-02",
        "nepDate": "18.11.2092"
    },
    {
        "engDate": "2036-03-03",
        "nepDate": "19.11.2092"
    },
    {
        "engDate": "2036-03-04",
        "nepDate": "20.11.2092"
    },
    {
        "engDate": "2036-03-05",
        "nepDate": "21.11.2092"
    },
    {
        "engDate": "2036-03-06",
        "nepDate": "22.11.2092"
    },
    {
        "engDate": "2036-03-07",
        "nepDate": "23.11.2092"
    },
    {
        "engDate": "2036-03-08",
        "nepDate": "24.11.2092"
    },
    {
        "engDate": "2036-03-09",
        "nepDate": "25.11.2092"
    },
    {
        "engDate": "2036-03-10",
        "nepDate": "26.11.2092"
    },
    {
        "engDate": "2036-03-11",
        "nepDate": "27.11.2092"
    },
    {
        "engDate": "2036-03-12",
        "nepDate": "28.11.2092"
    },
    {
        "engDate": "2036-03-13",
        "nepDate": "29.11.2092"
    },
    {
        "engDate": "2036-03-14",
        "nepDate": "30.11.2092"
    },
    {
        "engDate": "2036-03-15",
        "nepDate": "01.12.2092"
    },
    {
        "engDate": "2036-03-16",
        "nepDate": "02.12.2092"
    },
    {
        "engDate": "2036-03-17",
        "nepDate": "03.12.2092"
    },
    {
        "engDate": "2036-03-18",
        "nepDate": "04.12.2092"
    },
    {
        "engDate": "2036-03-19",
        "nepDate": "05.12.2092"
    },
    {
        "engDate": "2036-03-20",
        "nepDate": "06.12.2092"
    },
    {
        "engDate": "2036-03-21",
        "nepDate": "07.12.2092"
    },
    {
        "engDate": "2036-03-22",
        "nepDate": "08.12.2092"
    },
    {
        "engDate": "2036-03-23",
        "nepDate": "09.12.2092"
    },
    {
        "engDate": "2036-03-24",
        "nepDate": "10.12.2092"
    },
    {
        "engDate": "2036-03-25",
        "nepDate": "11.12.2092"
    },
    {
        "engDate": "2036-03-26",
        "nepDate": "12.12.2092"
    },
    {
        "engDate": "2036-03-27",
        "nepDate": "13.12.2092"
    },
    {
        "engDate": "2036-03-28",
        "nepDate": "14.12.2092"
    },
    {
        "engDate": "2036-03-29",
        "nepDate": "15.12.2092"
    },
    {
        "engDate": "2036-03-30",
        "nepDate": "16.12.2092"
    },
    {
        "engDate": "2036-03-31",
        "nepDate": "17.12.2092"
    },
    {
        "engDate": "2036-04-01",
        "nepDate": "18.12.2092"
    },
    {
        "engDate": "2036-04-02",
        "nepDate": "19.12.2092"
    },
    {
        "engDate": "2036-04-03",
        "nepDate": "20.12.2092"
    },
    {
        "engDate": "2036-04-04",
        "nepDate": "21.12.2092"
    },
    {
        "engDate": "2036-04-05",
        "nepDate": "22.12.2092"
    },
    {
        "engDate": "2036-04-06",
        "nepDate": "23.12.2092"
    },
    {
        "engDate": "2036-04-07",
        "nepDate": "24.12.2092"
    },
    {
        "engDate": "2036-04-08",
        "nepDate": "25.12.2092"
    },
    {
        "engDate": "2036-04-09",
        "nepDate": "26.12.2092"
    },
    {
        "engDate": "2036-04-10",
        "nepDate": "27.12.2092"
    },
    {
        "engDate": "2036-04-11",
        "nepDate": "28.12.2092"
    },
    {
        "engDate": "2036-04-12",
        "nepDate": "29.12.2092"
    },
    {
        "engDate": "2036-04-13",
        "nepDate": "30.12.2092"
    },
    {
        "engDate": "2036-04-14",
        "nepDate": "01.01.2093"
    },
    {
        "engDate": "2036-04-15",
        "nepDate": "02.01.2093"
    },
    {
        "engDate": "2036-04-16",
        "nepDate": "03.01.2093"
    },
    {
        "engDate": "2036-04-17",
        "nepDate": "04.01.2093"
    },
    {
        "engDate": "2036-04-18",
        "nepDate": "05.01.2093"
    },
    {
        "engDate": "2036-04-19",
        "nepDate": "06.01.2093"
    },
    {
        "engDate": "2036-04-20",
        "nepDate": "07.01.2093"
    },
    {
        "engDate": "2036-04-21",
        "nepDate": "08.01.2093"
    },
    {
        "engDate": "2036-04-22",
        "nepDate": "09.01.2093"
    },
    {
        "engDate": "2036-04-23",
        "nepDate": "10.01.2093"
    },
    {
        "engDate": "2036-04-24",
        "nepDate": "11.01.2093"
    },
    {
        "engDate": "2036-04-25",
        "nepDate": "12.01.2093"
    },
    {
        "engDate": "2036-04-26",
        "nepDate": "13.01.2093"
    },
    {
        "engDate": "2036-04-27",
        "nepDate": "14.01.2093"
    },
    {
        "engDate": "2036-04-28",
        "nepDate": "15.01.2093"
    },
    {
        "engDate": "2036-04-29",
        "nepDate": "16.01.2093"
    },
    {
        "engDate": "2036-04-30",
        "nepDate": "17.01.2093"
    },
    {
        "engDate": "2036-05-01",
        "nepDate": "18.01.2093"
    },
    {
        "engDate": "2036-05-02",
        "nepDate": "19.01.2093"
    },
    {
        "engDate": "2036-05-03",
        "nepDate": "20.01.2093"
    },
    {
        "engDate": "2036-05-04",
        "nepDate": "21.01.2093"
    },
    {
        "engDate": "2036-05-05",
        "nepDate": "22.01.2093"
    },
    {
        "engDate": "2036-05-06",
        "nepDate": "23.01.2093"
    },
    {
        "engDate": "2036-05-07",
        "nepDate": "24.01.2093"
    },
    {
        "engDate": "2036-05-08",
        "nepDate": "25.01.2093"
    },
    {
        "engDate": "2036-05-09",
        "nepDate": "26.01.2093"
    },
    {
        "engDate": "2036-05-10",
        "nepDate": "27.01.2093"
    },
    {
        "engDate": "2036-05-11",
        "nepDate": "28.01.2093"
    },
    {
        "engDate": "2036-05-12",
        "nepDate": "29.01.2093"
    },
    {
        "engDate": "2036-05-13",
        "nepDate": "30.01.2093"
    },
    {
        "engDate": "2036-05-14",
        "nepDate": "01.02.2093"
    },
    {
        "engDate": "2036-05-15",
        "nepDate": "02.02.2093"
    },
    {
        "engDate": "2036-05-16",
        "nepDate": "03.02.2093"
    },
    {
        "engDate": "2036-05-17",
        "nepDate": "04.02.2093"
    },
    {
        "engDate": "2036-05-18",
        "nepDate": "05.02.2093"
    },
    {
        "engDate": "2036-05-19",
        "nepDate": "06.02.2093"
    },
    {
        "engDate": "2036-05-20",
        "nepDate": "07.02.2093"
    },
    {
        "engDate": "2036-05-21",
        "nepDate": "08.02.2093"
    },
    {
        "engDate": "2036-05-22",
        "nepDate": "09.02.2093"
    },
    {
        "engDate": "2036-05-23",
        "nepDate": "10.02.2093"
    },
    {
        "engDate": "2036-05-24",
        "nepDate": "11.02.2093"
    },
    {
        "engDate": "2036-05-25",
        "nepDate": "12.02.2093"
    },
    {
        "engDate": "2036-05-26",
        "nepDate": "13.02.2093"
    },
    {
        "engDate": "2036-05-27",
        "nepDate": "14.02.2093"
    },
    {
        "engDate": "2036-05-28",
        "nepDate": "15.02.2093"
    },
    {
        "engDate": "2036-05-29",
        "nepDate": "16.02.2093"
    },
    {
        "engDate": "2036-05-30",
        "nepDate": "17.02.2093"
    },
    {
        "engDate": "2036-05-31",
        "nepDate": "18.02.2093"
    },
    {
        "engDate": "2036-06-01",
        "nepDate": "19.02.2093"
    },
    {
        "engDate": "2036-06-02",
        "nepDate": "20.02.2093"
    },
    {
        "engDate": "2036-06-03",
        "nepDate": "21.02.2093"
    },
    {
        "engDate": "2036-06-04",
        "nepDate": "22.02.2093"
    },
    {
        "engDate": "2036-06-05",
        "nepDate": "23.02.2093"
    },
    {
        "engDate": "2036-06-06",
        "nepDate": "24.02.2093"
    },
    {
        "engDate": "2036-06-07",
        "nepDate": "25.02.2093"
    },
    {
        "engDate": "2036-06-08",
        "nepDate": "26.02.2093"
    },
    {
        "engDate": "2036-06-09",
        "nepDate": "27.02.2093"
    },
    {
        "engDate": "2036-06-10",
        "nepDate": "28.02.2093"
    },
    {
        "engDate": "2036-06-11",
        "nepDate": "29.02.2093"
    },
    {
        "engDate": "2036-06-12",
        "nepDate": "30.02.2093"
    },
    {
        "engDate": "2036-06-13",
        "nepDate": "31.02.2093"
    },
    {
        "engDate": "2036-06-14",
        "nepDate": "32.02.2093"
    },
    {
        "engDate": "2036-06-15",
        "nepDate": "01.03.2093"
    },
    {
        "engDate": "2036-06-16",
        "nepDate": "02.03.2093"
    },
    {
        "engDate": "2036-06-17",
        "nepDate": "03.03.2093"
    },
    {
        "engDate": "2036-06-18",
        "nepDate": "04.03.2093"
    },
    {
        "engDate": "2036-06-19",
        "nepDate": "05.03.2093"
    },
    {
        "engDate": "2036-06-20",
        "nepDate": "06.03.2093"
    },
    {
        "engDate": "2036-06-21",
        "nepDate": "07.03.2093"
    },
    {
        "engDate": "2036-06-22",
        "nepDate": "08.03.2093"
    },
    {
        "engDate": "2036-06-23",
        "nepDate": "09.03.2093"
    },
    {
        "engDate": "2036-06-24",
        "nepDate": "10.03.2093"
    },
    {
        "engDate": "2036-06-25",
        "nepDate": "11.03.2093"
    },
    {
        "engDate": "2036-06-26",
        "nepDate": "12.03.2093"
    },
    {
        "engDate": "2036-06-27",
        "nepDate": "13.03.2093"
    },
    {
        "engDate": "2036-06-28",
        "nepDate": "14.03.2093"
    },
    {
        "engDate": "2036-06-29",
        "nepDate": "15.03.2093"
    },
    {
        "engDate": "2036-06-30",
        "nepDate": "16.03.2093"
    },
    {
        "engDate": "2036-07-01",
        "nepDate": "17.03.2093"
    },
    {
        "engDate": "2036-07-02",
        "nepDate": "18.03.2093"
    },
    {
        "engDate": "2036-07-03",
        "nepDate": "19.03.2093"
    },
    {
        "engDate": "2036-07-04",
        "nepDate": "20.03.2093"
    },
    {
        "engDate": "2036-07-05",
        "nepDate": "21.03.2093"
    },
    {
        "engDate": "2036-07-06",
        "nepDate": "22.03.2093"
    },
    {
        "engDate": "2036-07-07",
        "nepDate": "23.03.2093"
    },
    {
        "engDate": "2036-07-08",
        "nepDate": "24.03.2093"
    },
    {
        "engDate": "2036-07-09",
        "nepDate": "25.03.2093"
    },
    {
        "engDate": "2036-07-10",
        "nepDate": "26.03.2093"
    },
    {
        "engDate": "2036-07-11",
        "nepDate": "27.03.2093"
    },
    {
        "engDate": "2036-07-12",
        "nepDate": "28.03.2093"
    },
    {
        "engDate": "2036-07-13",
        "nepDate": "29.03.2093"
    },
    {
        "engDate": "2036-07-14",
        "nepDate": "30.03.2093"
    },
    {
        "engDate": "2036-07-15",
        "nepDate": "31.03.2093"
    },
    {
        "engDate": "2036-07-16",
        "nepDate": "01.04.2093"
    },
    {
        "engDate": "2036-07-17",
        "nepDate": "02.04.2093"
    },
    {
        "engDate": "2036-07-18",
        "nepDate": "03.04.2093"
    },
    {
        "engDate": "2036-07-19",
        "nepDate": "04.04.2093"
    },
    {
        "engDate": "2036-07-20",
        "nepDate": "05.04.2093"
    },
    {
        "engDate": "2036-07-21",
        "nepDate": "06.04.2093"
    },
    {
        "engDate": "2036-07-22",
        "nepDate": "07.04.2093"
    },
    {
        "engDate": "2036-07-23",
        "nepDate": "08.04.2093"
    },
    {
        "engDate": "2036-07-24",
        "nepDate": "09.04.2093"
    },
    {
        "engDate": "2036-07-25",
        "nepDate": "10.04.2093"
    },
    {
        "engDate": "2036-07-26",
        "nepDate": "11.04.2093"
    },
    {
        "engDate": "2036-07-27",
        "nepDate": "12.04.2093"
    },
    {
        "engDate": "2036-07-28",
        "nepDate": "13.04.2093"
    },
    {
        "engDate": "2036-07-29",
        "nepDate": "14.04.2093"
    },
    {
        "engDate": "2036-07-30",
        "nepDate": "15.04.2093"
    },
    {
        "engDate": "2036-07-31",
        "nepDate": "16.04.2093"
    },
    {
        "engDate": "2036-08-01",
        "nepDate": "17.04.2093"
    },
    {
        "engDate": "2036-08-02",
        "nepDate": "18.04.2093"
    },
    {
        "engDate": "2036-08-03",
        "nepDate": "19.04.2093"
    },
    {
        "engDate": "2036-08-04",
        "nepDate": "20.04.2093"
    },
    {
        "engDate": "2036-08-05",
        "nepDate": "21.04.2093"
    },
    {
        "engDate": "2036-08-06",
        "nepDate": "22.04.2093"
    },
    {
        "engDate": "2036-08-07",
        "nepDate": "23.04.2093"
    },
    {
        "engDate": "2036-08-08",
        "nepDate": "24.04.2093"
    },
    {
        "engDate": "2036-08-09",
        "nepDate": "25.04.2093"
    },
    {
        "engDate": "2036-08-10",
        "nepDate": "26.04.2093"
    },
    {
        "engDate": "2036-08-11",
        "nepDate": "27.04.2093"
    },
    {
        "engDate": "2036-08-12",
        "nepDate": "28.04.2093"
    },
    {
        "engDate": "2036-08-13",
        "nepDate": "29.04.2093"
    },
    {
        "engDate": "2036-08-14",
        "nepDate": "30.04.2093"
    },
    {
        "engDate": "2036-08-15",
        "nepDate": "31.04.2093"
    },
    {
        "engDate": "2036-08-16",
        "nepDate": "32.04.2093"
    },
    {
        "engDate": "2036-08-17",
        "nepDate": "01.05.2093"
    },
    {
        "engDate": "2036-08-18",
        "nepDate": "02.05.2093"
    },
    {
        "engDate": "2036-08-19",
        "nepDate": "03.05.2093"
    },
    {
        "engDate": "2036-08-20",
        "nepDate": "04.05.2093"
    },
    {
        "engDate": "2036-08-21",
        "nepDate": "05.05.2093"
    },
    {
        "engDate": "2036-08-22",
        "nepDate": "06.05.2093"
    },
    {
        "engDate": "2036-08-23",
        "nepDate": "07.05.2093"
    },
    {
        "engDate": "2036-08-24",
        "nepDate": "08.05.2093"
    },
    {
        "engDate": "2036-08-25",
        "nepDate": "09.05.2093"
    },
    {
        "engDate": "2036-08-26",
        "nepDate": "10.05.2093"
    },
    {
        "engDate": "2036-08-27",
        "nepDate": "11.05.2093"
    },
    {
        "engDate": "2036-08-28",
        "nepDate": "12.05.2093"
    },
    {
        "engDate": "2036-08-29",
        "nepDate": "13.05.2093"
    },
    {
        "engDate": "2036-08-30",
        "nepDate": "14.05.2093"
    },
    {
        "engDate": "2036-08-31",
        "nepDate": "15.05.2093"
    },
    {
        "engDate": "2036-09-01",
        "nepDate": "16.05.2093"
    },
    {
        "engDate": "2036-09-02",
        "nepDate": "17.05.2093"
    },
    {
        "engDate": "2036-09-03",
        "nepDate": "18.05.2093"
    },
    {
        "engDate": "2036-09-04",
        "nepDate": "19.05.2093"
    },
    {
        "engDate": "2036-09-05",
        "nepDate": "20.05.2093"
    },
    {
        "engDate": "2036-09-06",
        "nepDate": "21.05.2093"
    },
    {
        "engDate": "2036-09-07",
        "nepDate": "22.05.2093"
    },
    {
        "engDate": "2036-09-08",
        "nepDate": "23.05.2093"
    },
    {
        "engDate": "2036-09-09",
        "nepDate": "24.05.2093"
    },
    {
        "engDate": "2036-09-10",
        "nepDate": "25.05.2093"
    },
    {
        "engDate": "2036-09-11",
        "nepDate": "26.05.2093"
    },
    {
        "engDate": "2036-09-12",
        "nepDate": "27.05.2093"
    },
    {
        "engDate": "2036-09-13",
        "nepDate": "28.05.2093"
    },
    {
        "engDate": "2036-09-14",
        "nepDate": "29.05.2093"
    },
    {
        "engDate": "2036-09-15",
        "nepDate": "30.05.2093"
    },
    {
        "engDate": "2036-09-16",
        "nepDate": "31.05.2093"
    },
    {
        "engDate": "2036-09-17",
        "nepDate": "01.06.2093"
    },
    {
        "engDate": "2036-09-18",
        "nepDate": "02.06.2093"
    },
    {
        "engDate": "2036-09-19",
        "nepDate": "03.06.2093"
    },
    {
        "engDate": "2036-09-20",
        "nepDate": "04.06.2093"
    },
    {
        "engDate": "2036-09-21",
        "nepDate": "05.06.2093"
    },
    {
        "engDate": "2036-09-22",
        "nepDate": "06.06.2093"
    },
    {
        "engDate": "2036-09-23",
        "nepDate": "07.06.2093"
    },
    {
        "engDate": "2036-09-24",
        "nepDate": "08.06.2093"
    },
    {
        "engDate": "2036-09-25",
        "nepDate": "09.06.2093"
    },
    {
        "engDate": "2036-09-26",
        "nepDate": "10.06.2093"
    },
    {
        "engDate": "2036-09-27",
        "nepDate": "11.06.2093"
    },
    {
        "engDate": "2036-09-28",
        "nepDate": "12.06.2093"
    },
    {
        "engDate": "2036-09-29",
        "nepDate": "13.06.2093"
    },
    {
        "engDate": "2036-09-30",
        "nepDate": "14.06.2093"
    },
    {
        "engDate": "2036-10-01",
        "nepDate": "15.06.2093"
    },
    {
        "engDate": "2036-10-02",
        "nepDate": "16.06.2093"
    },
    {
        "engDate": "2036-10-03",
        "nepDate": "17.06.2093"
    },
    {
        "engDate": "2036-10-04",
        "nepDate": "18.06.2093"
    },
    {
        "engDate": "2036-10-05",
        "nepDate": "19.06.2093"
    },
    {
        "engDate": "2036-10-06",
        "nepDate": "20.06.2093"
    },
    {
        "engDate": "2036-10-07",
        "nepDate": "21.06.2093"
    },
    {
        "engDate": "2036-10-08",
        "nepDate": "22.06.2093"
    },
    {
        "engDate": "2036-10-09",
        "nepDate": "23.06.2093"
    },
    {
        "engDate": "2036-10-10",
        "nepDate": "24.06.2093"
    },
    {
        "engDate": "2036-10-11",
        "nepDate": "25.06.2093"
    },
    {
        "engDate": "2036-10-12",
        "nepDate": "26.06.2093"
    },
    {
        "engDate": "2036-10-13",
        "nepDate": "27.06.2093"
    },
    {
        "engDate": "2036-10-14",
        "nepDate": "28.06.2093"
    },
    {
        "engDate": "2036-10-15",
        "nepDate": "29.06.2093"
    },
    {
        "engDate": "2036-10-16",
        "nepDate": "30.06.2093"
    },
    {
        "engDate": "2036-10-17",
        "nepDate": "01.07.2093"
    },
    {
        "engDate": "2036-10-18",
        "nepDate": "02.07.2093"
    },
    {
        "engDate": "2036-10-19",
        "nepDate": "03.07.2093"
    },
    {
        "engDate": "2036-10-20",
        "nepDate": "04.07.2093"
    },
    {
        "engDate": "2036-10-21",
        "nepDate": "05.07.2093"
    },
    {
        "engDate": "2036-10-22",
        "nepDate": "06.07.2093"
    },
    {
        "engDate": "2036-10-23",
        "nepDate": "07.07.2093"
    },
    {
        "engDate": "2036-10-24",
        "nepDate": "08.07.2093"
    },
    {
        "engDate": "2036-10-25",
        "nepDate": "09.07.2093"
    },
    {
        "engDate": "2036-10-26",
        "nepDate": "10.07.2093"
    },
    {
        "engDate": "2036-10-27",
        "nepDate": "11.07.2093"
    },
    {
        "engDate": "2036-10-28",
        "nepDate": "12.07.2093"
    },
    {
        "engDate": "2036-10-29",
        "nepDate": "13.07.2093"
    },
    {
        "engDate": "2036-10-30",
        "nepDate": "14.07.2093"
    },
    {
        "engDate": "2036-10-31",
        "nepDate": "15.07.2093"
    },
    {
        "engDate": "2036-11-01",
        "nepDate": "16.07.2093"
    },
    {
        "engDate": "2036-11-02",
        "nepDate": "17.07.2093"
    },
    {
        "engDate": "2036-11-03",
        "nepDate": "18.07.2093"
    },
    {
        "engDate": "2036-11-04",
        "nepDate": "19.07.2093"
    },
    {
        "engDate": "2036-11-05",
        "nepDate": "20.07.2093"
    },
    {
        "engDate": "2036-11-06",
        "nepDate": "21.07.2093"
    },
    {
        "engDate": "2036-11-07",
        "nepDate": "22.07.2093"
    },
    {
        "engDate": "2036-11-08",
        "nepDate": "23.07.2093"
    },
    {
        "engDate": "2036-11-09",
        "nepDate": "24.07.2093"
    },
    {
        "engDate": "2036-11-10",
        "nepDate": "25.07.2093"
    },
    {
        "engDate": "2036-11-11",
        "nepDate": "26.07.2093"
    },
    {
        "engDate": "2036-11-12",
        "nepDate": "27.07.2093"
    },
    {
        "engDate": "2036-11-13",
        "nepDate": "28.07.2093"
    },
    {
        "engDate": "2036-11-14",
        "nepDate": "29.07.2093"
    },
    {
        "engDate": "2036-11-15",
        "nepDate": "30.07.2093"
    },
    {
        "engDate": "2036-11-16",
        "nepDate": "01.08.2093"
    },
    {
        "engDate": "2036-11-17",
        "nepDate": "02.08.2093"
    },
    {
        "engDate": "2036-11-18",
        "nepDate": "03.08.2093"
    },
    {
        "engDate": "2036-11-19",
        "nepDate": "04.08.2093"
    },
    {
        "engDate": "2036-11-20",
        "nepDate": "05.08.2093"
    },
    {
        "engDate": "2036-11-21",
        "nepDate": "06.08.2093"
    },
    {
        "engDate": "2036-11-22",
        "nepDate": "07.08.2093"
    },
    {
        "engDate": "2036-11-23",
        "nepDate": "08.08.2093"
    },
    {
        "engDate": "2036-11-24",
        "nepDate": "09.08.2093"
    },
    {
        "engDate": "2036-11-25",
        "nepDate": "10.08.2093"
    },
    {
        "engDate": "2036-11-26",
        "nepDate": "11.08.2093"
    },
    {
        "engDate": "2036-11-27",
        "nepDate": "12.08.2093"
    },
    {
        "engDate": "2036-11-28",
        "nepDate": "13.08.2093"
    },
    {
        "engDate": "2036-11-29",
        "nepDate": "14.08.2093"
    },
    {
        "engDate": "2036-11-30",
        "nepDate": "15.08.2093"
    },
    {
        "engDate": "2036-12-01",
        "nepDate": "16.08.2093"
    },
    {
        "engDate": "2036-12-02",
        "nepDate": "17.08.2093"
    },
    {
        "engDate": "2036-12-03",
        "nepDate": "18.08.2093"
    },
    {
        "engDate": "2036-12-04",
        "nepDate": "19.08.2093"
    },
    {
        "engDate": "2036-12-05",
        "nepDate": "20.08.2093"
    },
    {
        "engDate": "2036-12-06",
        "nepDate": "21.08.2093"
    },
    {
        "engDate": "2036-12-07",
        "nepDate": "22.08.2093"
    },
    {
        "engDate": "2036-12-08",
        "nepDate": "23.08.2093"
    },
    {
        "engDate": "2036-12-09",
        "nepDate": "24.08.2093"
    },
    {
        "engDate": "2036-12-10",
        "nepDate": "25.08.2093"
    },
    {
        "engDate": "2036-12-11",
        "nepDate": "26.08.2093"
    },
    {
        "engDate": "2036-12-12",
        "nepDate": "27.08.2093"
    },
    {
        "engDate": "2036-12-13",
        "nepDate": "28.08.2093"
    },
    {
        "engDate": "2036-12-14",
        "nepDate": "29.08.2093"
    },
    {
        "engDate": "2036-12-15",
        "nepDate": "30.08.2093"
    },
    {
        "engDate": "2036-12-16",
        "nepDate": "01.09.2093"
    },
    {
        "engDate": "2036-12-17",
        "nepDate": "02.09.2093"
    },
    {
        "engDate": "2036-12-18",
        "nepDate": "03.09.2093"
    },
    {
        "engDate": "2036-12-19",
        "nepDate": "04.09.2093"
    },
    {
        "engDate": "2036-12-20",
        "nepDate": "05.09.2093"
    },
    {
        "engDate": "2036-12-21",
        "nepDate": "06.09.2093"
    },
    {
        "engDate": "2036-12-22",
        "nepDate": "07.09.2093"
    },
    {
        "engDate": "2036-12-23",
        "nepDate": "08.09.2093"
    },
    {
        "engDate": "2036-12-24",
        "nepDate": "09.09.2093"
    },
    {
        "engDate": "2036-12-25",
        "nepDate": "10.09.2093"
    },
    {
        "engDate": "2036-12-26",
        "nepDate": "11.09.2093"
    },
    {
        "engDate": "2036-12-27",
        "nepDate": "12.09.2093"
    },
    {
        "engDate": "2036-12-28",
        "nepDate": "13.09.2093"
    },
    {
        "engDate": "2036-12-29",
        "nepDate": "14.09.2093"
    },
    {
        "engDate": "2036-12-30",
        "nepDate": "15.09.2093"
    },
    {
        "engDate": "2036-12-31",
        "nepDate": "16.09.2093"
    },
    {
        "engDate": "2037-01-01",
        "nepDate": "17.09.2093"
    },
    {
        "engDate": "2037-01-02",
        "nepDate": "18.09.2093"
    },
    {
        "engDate": "2037-01-03",
        "nepDate": "19.09.2093"
    },
    {
        "engDate": "2037-01-04",
        "nepDate": "20.09.2093"
    },
    {
        "engDate": "2037-01-05",
        "nepDate": "21.09.2093"
    },
    {
        "engDate": "2037-01-06",
        "nepDate": "22.09.2093"
    },
    {
        "engDate": "2037-01-07",
        "nepDate": "23.09.2093"
    },
    {
        "engDate": "2037-01-08",
        "nepDate": "24.09.2093"
    },
    {
        "engDate": "2037-01-09",
        "nepDate": "25.09.2093"
    },
    {
        "engDate": "2037-01-10",
        "nepDate": "26.09.2093"
    },
    {
        "engDate": "2037-01-11",
        "nepDate": "27.09.2093"
    },
    {
        "engDate": "2037-01-12",
        "nepDate": "28.09.2093"
    },
    {
        "engDate": "2037-01-13",
        "nepDate": "29.09.2093"
    },
    {
        "engDate": "2037-01-14",
        "nepDate": "01.10.2093"
    },
    {
        "engDate": "2037-01-15",
        "nepDate": "02.10.2093"
    },
    {
        "engDate": "2037-01-16",
        "nepDate": "03.10.2093"
    },
    {
        "engDate": "2037-01-17",
        "nepDate": "04.10.2093"
    },
    {
        "engDate": "2037-01-18",
        "nepDate": "05.10.2093"
    },
    {
        "engDate": "2037-01-19",
        "nepDate": "06.10.2093"
    },
    {
        "engDate": "2037-01-20",
        "nepDate": "07.10.2093"
    },
    {
        "engDate": "2037-01-21",
        "nepDate": "08.10.2093"
    },
    {
        "engDate": "2037-01-22",
        "nepDate": "09.10.2093"
    },
    {
        "engDate": "2037-01-23",
        "nepDate": "10.10.2093"
    },
    {
        "engDate": "2037-01-24",
        "nepDate": "11.10.2093"
    },
    {
        "engDate": "2037-01-25",
        "nepDate": "12.10.2093"
    },
    {
        "engDate": "2037-01-26",
        "nepDate": "13.10.2093"
    },
    {
        "engDate": "2037-01-27",
        "nepDate": "14.10.2093"
    },
    {
        "engDate": "2037-01-28",
        "nepDate": "15.10.2093"
    },
    {
        "engDate": "2037-01-29",
        "nepDate": "16.10.2093"
    },
    {
        "engDate": "2037-01-30",
        "nepDate": "17.10.2093"
    },
    {
        "engDate": "2037-01-31",
        "nepDate": "18.10.2093"
    },
    {
        "engDate": "2037-02-01",
        "nepDate": "19.10.2093"
    },
    {
        "engDate": "2037-02-02",
        "nepDate": "20.10.2093"
    },
    {
        "engDate": "2037-02-03",
        "nepDate": "21.10.2093"
    },
    {
        "engDate": "2037-02-04",
        "nepDate": "22.10.2093"
    },
    {
        "engDate": "2037-02-05",
        "nepDate": "23.10.2093"
    },
    {
        "engDate": "2037-02-06",
        "nepDate": "24.10.2093"
    },
    {
        "engDate": "2037-02-07",
        "nepDate": "25.10.2093"
    },
    {
        "engDate": "2037-02-08",
        "nepDate": "26.10.2093"
    },
    {
        "engDate": "2037-02-09",
        "nepDate": "27.10.2093"
    },
    {
        "engDate": "2037-02-10",
        "nepDate": "28.10.2093"
    },
    {
        "engDate": "2037-02-11",
        "nepDate": "29.10.2093"
    },
    {
        "engDate": "2037-02-12",
        "nepDate": "30.10.2093"
    },
    {
        "engDate": "2037-02-13",
        "nepDate": "01.11.2093"
    },
    {
        "engDate": "2037-02-14",
        "nepDate": "02.11.2093"
    },
    {
        "engDate": "2037-02-15",
        "nepDate": "03.11.2093"
    },
    {
        "engDate": "2037-02-16",
        "nepDate": "04.11.2093"
    },
    {
        "engDate": "2037-02-17",
        "nepDate": "05.11.2093"
    },
    {
        "engDate": "2037-02-18",
        "nepDate": "06.11.2093"
    },
    {
        "engDate": "2037-02-19",
        "nepDate": "07.11.2093"
    },
    {
        "engDate": "2037-02-20",
        "nepDate": "08.11.2093"
    },
    {
        "engDate": "2037-02-21",
        "nepDate": "09.11.2093"
    },
    {
        "engDate": "2037-02-22",
        "nepDate": "10.11.2093"
    },
    {
        "engDate": "2037-02-23",
        "nepDate": "11.11.2093"
    },
    {
        "engDate": "2037-02-24",
        "nepDate": "12.11.2093"
    },
    {
        "engDate": "2037-02-25",
        "nepDate": "13.11.2093"
    },
    {
        "engDate": "2037-02-26",
        "nepDate": "14.11.2093"
    },
    {
        "engDate": "2037-02-27",
        "nepDate": "15.11.2093"
    },
    {
        "engDate": "2037-02-28",
        "nepDate": "16.11.2093"
    },
    {
        "engDate": "2037-03-01",
        "nepDate": "17.11.2093"
    },
    {
        "engDate": "2037-03-02",
        "nepDate": "18.11.2093"
    },
    {
        "engDate": "2037-03-03",
        "nepDate": "19.11.2093"
    },
    {
        "engDate": "2037-03-04",
        "nepDate": "20.11.2093"
    },
    {
        "engDate": "2037-03-05",
        "nepDate": "21.11.2093"
    },
    {
        "engDate": "2037-03-06",
        "nepDate": "22.11.2093"
    },
    {
        "engDate": "2037-03-07",
        "nepDate": "23.11.2093"
    },
    {
        "engDate": "2037-03-08",
        "nepDate": "24.11.2093"
    },
    {
        "engDate": "2037-03-09",
        "nepDate": "25.11.2093"
    },
    {
        "engDate": "2037-03-10",
        "nepDate": "26.11.2093"
    },
    {
        "engDate": "2037-03-11",
        "nepDate": "27.11.2093"
    },
    {
        "engDate": "2037-03-12",
        "nepDate": "28.11.2093"
    },
    {
        "engDate": "2037-03-13",
        "nepDate": "29.11.2093"
    },
    {
        "engDate": "2037-03-14",
        "nepDate": "30.11.2093"
    },
    {
        "engDate": "2037-03-15",
        "nepDate": "01.12.2093"
    },
    {
        "engDate": "2037-03-16",
        "nepDate": "02.12.2093"
    },
    {
        "engDate": "2037-03-17",
        "nepDate": "03.12.2093"
    },
    {
        "engDate": "2037-03-18",
        "nepDate": "04.12.2093"
    },
    {
        "engDate": "2037-03-19",
        "nepDate": "05.12.2093"
    },
    {
        "engDate": "2037-03-20",
        "nepDate": "06.12.2093"
    },
    {
        "engDate": "2037-03-21",
        "nepDate": "07.12.2093"
    },
    {
        "engDate": "2037-03-22",
        "nepDate": "08.12.2093"
    },
    {
        "engDate": "2037-03-23",
        "nepDate": "09.12.2093"
    },
    {
        "engDate": "2037-03-24",
        "nepDate": "10.12.2093"
    },
    {
        "engDate": "2037-03-25",
        "nepDate": "11.12.2093"
    },
    {
        "engDate": "2037-03-26",
        "nepDate": "12.12.2093"
    },
    {
        "engDate": "2037-03-27",
        "nepDate": "13.12.2093"
    },
    {
        "engDate": "2037-03-28",
        "nepDate": "14.12.2093"
    },
    {
        "engDate": "2037-03-29",
        "nepDate": "15.12.2093"
    },
    {
        "engDate": "2037-03-30",
        "nepDate": "16.12.2093"
    },
    {
        "engDate": "2037-03-31",
        "nepDate": "17.12.2093"
    },
    {
        "engDate": "2037-04-01",
        "nepDate": "18.12.2093"
    },
    {
        "engDate": "2037-04-02",
        "nepDate": "19.12.2093"
    },
    {
        "engDate": "2037-04-03",
        "nepDate": "20.12.2093"
    },
    {
        "engDate": "2037-04-04",
        "nepDate": "21.12.2093"
    },
    {
        "engDate": "2037-04-05",
        "nepDate": "22.12.2093"
    },
    {
        "engDate": "2037-04-06",
        "nepDate": "23.12.2093"
    },
    {
        "engDate": "2037-04-07",
        "nepDate": "24.12.2093"
    },
    {
        "engDate": "2037-04-08",
        "nepDate": "25.12.2093"
    },
    {
        "engDate": "2037-04-09",
        "nepDate": "26.12.2093"
    },
    {
        "engDate": "2037-04-10",
        "nepDate": "27.12.2093"
    },
    {
        "engDate": "2037-04-11",
        "nepDate": "28.12.2093"
    },
    {
        "engDate": "2037-04-12",
        "nepDate": "29.12.2093"
    },
    {
        "engDate": "2037-04-13",
        "nepDate": "30.12.2093"
    },
    {
        "engDate": "2037-04-14",
        "nepDate": "01.01.2094"
    },
    {
        "engDate": "2037-04-15",
        "nepDate": "02.01.2094"
    },
    {
        "engDate": "2037-04-16",
        "nepDate": "03.01.2094"
    },
    {
        "engDate": "2037-04-17",
        "nepDate": "04.01.2094"
    },
    {
        "engDate": "2037-04-18",
        "nepDate": "05.01.2094"
    },
    {
        "engDate": "2037-04-19",
        "nepDate": "06.01.2094"
    },
    {
        "engDate": "2037-04-20",
        "nepDate": "07.01.2094"
    },
    {
        "engDate": "2037-04-21",
        "nepDate": "08.01.2094"
    },
    {
        "engDate": "2037-04-22",
        "nepDate": "09.01.2094"
    },
    {
        "engDate": "2037-04-23",
        "nepDate": "10.01.2094"
    },
    {
        "engDate": "2037-04-24",
        "nepDate": "11.01.2094"
    },
    {
        "engDate": "2037-04-25",
        "nepDate": "12.01.2094"
    },
    {
        "engDate": "2037-04-26",
        "nepDate": "13.01.2094"
    },
    {
        "engDate": "2037-04-27",
        "nepDate": "14.01.2094"
    },
    {
        "engDate": "2037-04-28",
        "nepDate": "15.01.2094"
    },
    {
        "engDate": "2037-04-29",
        "nepDate": "16.01.2094"
    },
    {
        "engDate": "2037-04-30",
        "nepDate": "17.01.2094"
    },
    {
        "engDate": "2037-05-01",
        "nepDate": "18.01.2094"
    },
    {
        "engDate": "2037-05-02",
        "nepDate": "19.01.2094"
    },
    {
        "engDate": "2037-05-03",
        "nepDate": "20.01.2094"
    },
    {
        "engDate": "2037-05-04",
        "nepDate": "21.01.2094"
    },
    {
        "engDate": "2037-05-05",
        "nepDate": "22.01.2094"
    },
    {
        "engDate": "2037-05-06",
        "nepDate": "23.01.2094"
    },
    {
        "engDate": "2037-05-07",
        "nepDate": "24.01.2094"
    },
    {
        "engDate": "2037-05-08",
        "nepDate": "25.01.2094"
    },
    {
        "engDate": "2037-05-09",
        "nepDate": "26.01.2094"
    },
    {
        "engDate": "2037-05-10",
        "nepDate": "27.01.2094"
    },
    {
        "engDate": "2037-05-11",
        "nepDate": "28.01.2094"
    },
    {
        "engDate": "2037-05-12",
        "nepDate": "29.01.2094"
    },
    {
        "engDate": "2037-05-13",
        "nepDate": "30.01.2094"
    },
    {
        "engDate": "2037-05-14",
        "nepDate": "31.01.2094"
    },
    {
        "engDate": "2037-05-15",
        "nepDate": "01.02.2094"
    },
    {
        "engDate": "2037-05-16",
        "nepDate": "02.02.2094"
    },
    {
        "engDate": "2037-05-17",
        "nepDate": "03.02.2094"
    },
    {
        "engDate": "2037-05-18",
        "nepDate": "04.02.2094"
    },
    {
        "engDate": "2037-05-19",
        "nepDate": "05.02.2094"
    },
    {
        "engDate": "2037-05-20",
        "nepDate": "06.02.2094"
    },
    {
        "engDate": "2037-05-21",
        "nepDate": "07.02.2094"
    },
    {
        "engDate": "2037-05-22",
        "nepDate": "08.02.2094"
    },
    {
        "engDate": "2037-05-23",
        "nepDate": "09.02.2094"
    },
    {
        "engDate": "2037-05-24",
        "nepDate": "10.02.2094"
    },
    {
        "engDate": "2037-05-25",
        "nepDate": "11.02.2094"
    },
    {
        "engDate": "2037-05-26",
        "nepDate": "12.02.2094"
    },
    {
        "engDate": "2037-05-27",
        "nepDate": "13.02.2094"
    },
    {
        "engDate": "2037-05-28",
        "nepDate": "14.02.2094"
    },
    {
        "engDate": "2037-05-29",
        "nepDate": "15.02.2094"
    },
    {
        "engDate": "2037-05-30",
        "nepDate": "16.02.2094"
    },
    {
        "engDate": "2037-05-31",
        "nepDate": "17.02.2094"
    },
    {
        "engDate": "2037-06-01",
        "nepDate": "18.02.2094"
    },
    {
        "engDate": "2037-06-02",
        "nepDate": "19.02.2094"
    },
    {
        "engDate": "2037-06-03",
        "nepDate": "20.02.2094"
    },
    {
        "engDate": "2037-06-04",
        "nepDate": "21.02.2094"
    },
    {
        "engDate": "2037-06-05",
        "nepDate": "22.02.2094"
    },
    {
        "engDate": "2037-06-06",
        "nepDate": "23.02.2094"
    },
    {
        "engDate": "2037-06-07",
        "nepDate": "24.02.2094"
    },
    {
        "engDate": "2037-06-08",
        "nepDate": "25.02.2094"
    },
    {
        "engDate": "2037-06-09",
        "nepDate": "26.02.2094"
    },
    {
        "engDate": "2037-06-10",
        "nepDate": "27.02.2094"
    },
    {
        "engDate": "2037-06-11",
        "nepDate": "28.02.2094"
    },
    {
        "engDate": "2037-06-12",
        "nepDate": "29.02.2094"
    },
    {
        "engDate": "2037-06-13",
        "nepDate": "30.02.2094"
    },
    {
        "engDate": "2037-06-14",
        "nepDate": "31.02.2094"
    },
    {
        "engDate": "2037-06-15",
        "nepDate": "01.03.2094"
    },
    {
        "engDate": "2037-06-16",
        "nepDate": "02.03.2094"
    },
    {
        "engDate": "2037-06-17",
        "nepDate": "03.03.2094"
    },
    {
        "engDate": "2037-06-18",
        "nepDate": "04.03.2094"
    },
    {
        "engDate": "2037-06-19",
        "nepDate": "05.03.2094"
    },
    {
        "engDate": "2037-06-20",
        "nepDate": "06.03.2094"
    },
    {
        "engDate": "2037-06-21",
        "nepDate": "07.03.2094"
    },
    {
        "engDate": "2037-06-22",
        "nepDate": "08.03.2094"
    },
    {
        "engDate": "2037-06-23",
        "nepDate": "09.03.2094"
    },
    {
        "engDate": "2037-06-24",
        "nepDate": "10.03.2094"
    },
    {
        "engDate": "2037-06-25",
        "nepDate": "11.03.2094"
    },
    {
        "engDate": "2037-06-26",
        "nepDate": "12.03.2094"
    },
    {
        "engDate": "2037-06-27",
        "nepDate": "13.03.2094"
    },
    {
        "engDate": "2037-06-28",
        "nepDate": "14.03.2094"
    },
    {
        "engDate": "2037-06-29",
        "nepDate": "15.03.2094"
    },
    {
        "engDate": "2037-06-30",
        "nepDate": "16.03.2094"
    },
    {
        "engDate": "2037-07-01",
        "nepDate": "17.03.2094"
    },
    {
        "engDate": "2037-07-02",
        "nepDate": "18.03.2094"
    },
    {
        "engDate": "2037-07-03",
        "nepDate": "19.03.2094"
    },
    {
        "engDate": "2037-07-04",
        "nepDate": "20.03.2094"
    },
    {
        "engDate": "2037-07-05",
        "nepDate": "21.03.2094"
    },
    {
        "engDate": "2037-07-06",
        "nepDate": "22.03.2094"
    },
    {
        "engDate": "2037-07-07",
        "nepDate": "23.03.2094"
    },
    {
        "engDate": "2037-07-08",
        "nepDate": "24.03.2094"
    },
    {
        "engDate": "2037-07-09",
        "nepDate": "25.03.2094"
    },
    {
        "engDate": "2037-07-10",
        "nepDate": "26.03.2094"
    },
    {
        "engDate": "2037-07-11",
        "nepDate": "27.03.2094"
    },
    {
        "engDate": "2037-07-12",
        "nepDate": "28.03.2094"
    },
    {
        "engDate": "2037-07-13",
        "nepDate": "29.03.2094"
    },
    {
        "engDate": "2037-07-14",
        "nepDate": "30.03.2094"
    },
    {
        "engDate": "2037-07-15",
        "nepDate": "31.03.2094"
    },
    {
        "engDate": "2037-07-16",
        "nepDate": "32.03.2094"
    },
    {
        "engDate": "2037-07-17",
        "nepDate": "01.04.2094"
    },
    {
        "engDate": "2037-07-18",
        "nepDate": "02.04.2094"
    },
    {
        "engDate": "2037-07-19",
        "nepDate": "03.04.2094"
    },
    {
        "engDate": "2037-07-20",
        "nepDate": "04.04.2094"
    },
    {
        "engDate": "2037-07-21",
        "nepDate": "05.04.2094"
    },
    {
        "engDate": "2037-07-22",
        "nepDate": "06.04.2094"
    },
    {
        "engDate": "2037-07-23",
        "nepDate": "07.04.2094"
    },
    {
        "engDate": "2037-07-24",
        "nepDate": "08.04.2094"
    },
    {
        "engDate": "2037-07-25",
        "nepDate": "09.04.2094"
    },
    {
        "engDate": "2037-07-26",
        "nepDate": "10.04.2094"
    },
    {
        "engDate": "2037-07-27",
        "nepDate": "11.04.2094"
    },
    {
        "engDate": "2037-07-28",
        "nepDate": "12.04.2094"
    },
    {
        "engDate": "2037-07-29",
        "nepDate": "13.04.2094"
    },
    {
        "engDate": "2037-07-30",
        "nepDate": "14.04.2094"
    },
    {
        "engDate": "2037-07-31",
        "nepDate": "15.04.2094"
    },
    {
        "engDate": "2037-08-01",
        "nepDate": "16.04.2094"
    },
    {
        "engDate": "2037-08-02",
        "nepDate": "17.04.2094"
    },
    {
        "engDate": "2037-08-03",
        "nepDate": "18.04.2094"
    },
    {
        "engDate": "2037-08-04",
        "nepDate": "19.04.2094"
    },
    {
        "engDate": "2037-08-05",
        "nepDate": "20.04.2094"
    },
    {
        "engDate": "2037-08-06",
        "nepDate": "21.04.2094"
    },
    {
        "engDate": "2037-08-07",
        "nepDate": "22.04.2094"
    },
    {
        "engDate": "2037-08-08",
        "nepDate": "23.04.2094"
    },
    {
        "engDate": "2037-08-09",
        "nepDate": "24.04.2094"
    },
    {
        "engDate": "2037-08-10",
        "nepDate": "25.04.2094"
    },
    {
        "engDate": "2037-08-11",
        "nepDate": "26.04.2094"
    },
    {
        "engDate": "2037-08-12",
        "nepDate": "27.04.2094"
    },
    {
        "engDate": "2037-08-13",
        "nepDate": "28.04.2094"
    },
    {
        "engDate": "2037-08-14",
        "nepDate": "29.04.2094"
    },
    {
        "engDate": "2037-08-15",
        "nepDate": "30.04.2094"
    },
    {
        "engDate": "2037-08-16",
        "nepDate": "31.04.2094"
    },
    {
        "engDate": "2037-08-17",
        "nepDate": "01.05.2094"
    },
    {
        "engDate": "2037-08-18",
        "nepDate": "02.05.2094"
    },
    {
        "engDate": "2037-08-19",
        "nepDate": "03.05.2094"
    },
    {
        "engDate": "2037-08-20",
        "nepDate": "04.05.2094"
    },
    {
        "engDate": "2037-08-21",
        "nepDate": "05.05.2094"
    },
    {
        "engDate": "2037-08-22",
        "nepDate": "06.05.2094"
    },
    {
        "engDate": "2037-08-23",
        "nepDate": "07.05.2094"
    },
    {
        "engDate": "2037-08-24",
        "nepDate": "08.05.2094"
    },
    {
        "engDate": "2037-08-25",
        "nepDate": "09.05.2094"
    },
    {
        "engDate": "2037-08-26",
        "nepDate": "10.05.2094"
    },
    {
        "engDate": "2037-08-27",
        "nepDate": "11.05.2094"
    },
    {
        "engDate": "2037-08-28",
        "nepDate": "12.05.2094"
    },
    {
        "engDate": "2037-08-29",
        "nepDate": "13.05.2094"
    },
    {
        "engDate": "2037-08-30",
        "nepDate": "14.05.2094"
    },
    {
        "engDate": "2037-08-31",
        "nepDate": "15.05.2094"
    },
    {
        "engDate": "2037-09-01",
        "nepDate": "16.05.2094"
    },
    {
        "engDate": "2037-09-02",
        "nepDate": "17.05.2094"
    },
    {
        "engDate": "2037-09-03",
        "nepDate": "18.05.2094"
    },
    {
        "engDate": "2037-09-04",
        "nepDate": "19.05.2094"
    },
    {
        "engDate": "2037-09-05",
        "nepDate": "20.05.2094"
    },
    {
        "engDate": "2037-09-06",
        "nepDate": "21.05.2094"
    },
    {
        "engDate": "2037-09-07",
        "nepDate": "22.05.2094"
    },
    {
        "engDate": "2037-09-08",
        "nepDate": "23.05.2094"
    },
    {
        "engDate": "2037-09-09",
        "nepDate": "24.05.2094"
    },
    {
        "engDate": "2037-09-10",
        "nepDate": "25.05.2094"
    },
    {
        "engDate": "2037-09-11",
        "nepDate": "26.05.2094"
    },
    {
        "engDate": "2037-09-12",
        "nepDate": "27.05.2094"
    },
    {
        "engDate": "2037-09-13",
        "nepDate": "28.05.2094"
    },
    {
        "engDate": "2037-09-14",
        "nepDate": "29.05.2094"
    },
    {
        "engDate": "2037-09-15",
        "nepDate": "30.05.2094"
    },
    {
        "engDate": "2037-09-16",
        "nepDate": "31.05.2094"
    },
    {
        "engDate": "2037-09-17",
        "nepDate": "01.06.2094"
    },
    {
        "engDate": "2037-09-18",
        "nepDate": "02.06.2094"
    },
    {
        "engDate": "2037-09-19",
        "nepDate": "03.06.2094"
    },
    {
        "engDate": "2037-09-20",
        "nepDate": "04.06.2094"
    },
    {
        "engDate": "2037-09-21",
        "nepDate": "05.06.2094"
    },
    {
        "engDate": "2037-09-22",
        "nepDate": "06.06.2094"
    },
    {
        "engDate": "2037-09-23",
        "nepDate": "07.06.2094"
    },
    {
        "engDate": "2037-09-24",
        "nepDate": "08.06.2094"
    },
    {
        "engDate": "2037-09-25",
        "nepDate": "09.06.2094"
    },
    {
        "engDate": "2037-09-26",
        "nepDate": "10.06.2094"
    },
    {
        "engDate": "2037-09-27",
        "nepDate": "11.06.2094"
    },
    {
        "engDate": "2037-09-28",
        "nepDate": "12.06.2094"
    },
    {
        "engDate": "2037-09-29",
        "nepDate": "13.06.2094"
    },
    {
        "engDate": "2037-09-30",
        "nepDate": "14.06.2094"
    },
    {
        "engDate": "2037-10-01",
        "nepDate": "15.06.2094"
    },
    {
        "engDate": "2037-10-02",
        "nepDate": "16.06.2094"
    },
    {
        "engDate": "2037-10-03",
        "nepDate": "17.06.2094"
    },
    {
        "engDate": "2037-10-04",
        "nepDate": "18.06.2094"
    },
    {
        "engDate": "2037-10-05",
        "nepDate": "19.06.2094"
    },
    {
        "engDate": "2037-10-06",
        "nepDate": "20.06.2094"
    },
    {
        "engDate": "2037-10-07",
        "nepDate": "21.06.2094"
    },
    {
        "engDate": "2037-10-08",
        "nepDate": "22.06.2094"
    },
    {
        "engDate": "2037-10-09",
        "nepDate": "23.06.2094"
    },
    {
        "engDate": "2037-10-10",
        "nepDate": "24.06.2094"
    },
    {
        "engDate": "2037-10-11",
        "nepDate": "25.06.2094"
    },
    {
        "engDate": "2037-10-12",
        "nepDate": "26.06.2094"
    },
    {
        "engDate": "2037-10-13",
        "nepDate": "27.06.2094"
    },
    {
        "engDate": "2037-10-14",
        "nepDate": "28.06.2094"
    },
    {
        "engDate": "2037-10-15",
        "nepDate": "29.06.2094"
    },
    {
        "engDate": "2037-10-16",
        "nepDate": "30.06.2094"
    },
    {
        "engDate": "2037-10-17",
        "nepDate": "01.07.2094"
    },
    {
        "engDate": "2037-10-18",
        "nepDate": "02.07.2094"
    },
    {
        "engDate": "2037-10-19",
        "nepDate": "03.07.2094"
    },
    {
        "engDate": "2037-10-20",
        "nepDate": "04.07.2094"
    },
    {
        "engDate": "2037-10-21",
        "nepDate": "05.07.2094"
    },
    {
        "engDate": "2037-10-22",
        "nepDate": "06.07.2094"
    },
    {
        "engDate": "2037-10-23",
        "nepDate": "07.07.2094"
    },
    {
        "engDate": "2037-10-24",
        "nepDate": "08.07.2094"
    },
    {
        "engDate": "2037-10-25",
        "nepDate": "09.07.2094"
    },
    {
        "engDate": "2037-10-26",
        "nepDate": "10.07.2094"
    },
    {
        "engDate": "2037-10-27",
        "nepDate": "11.07.2094"
    },
    {
        "engDate": "2037-10-28",
        "nepDate": "12.07.2094"
    },
    {
        "engDate": "2037-10-29",
        "nepDate": "13.07.2094"
    },
    {
        "engDate": "2037-10-30",
        "nepDate": "14.07.2094"
    },
    {
        "engDate": "2037-10-31",
        "nepDate": "15.07.2094"
    },
    {
        "engDate": "2037-11-01",
        "nepDate": "16.07.2094"
    },
    {
        "engDate": "2037-11-02",
        "nepDate": "17.07.2094"
    },
    {
        "engDate": "2037-11-03",
        "nepDate": "18.07.2094"
    },
    {
        "engDate": "2037-11-04",
        "nepDate": "19.07.2094"
    },
    {
        "engDate": "2037-11-05",
        "nepDate": "20.07.2094"
    },
    {
        "engDate": "2037-11-06",
        "nepDate": "21.07.2094"
    },
    {
        "engDate": "2037-11-07",
        "nepDate": "22.07.2094"
    },
    {
        "engDate": "2037-11-08",
        "nepDate": "23.07.2094"
    },
    {
        "engDate": "2037-11-09",
        "nepDate": "24.07.2094"
    },
    {
        "engDate": "2037-11-10",
        "nepDate": "25.07.2094"
    },
    {
        "engDate": "2037-11-11",
        "nepDate": "26.07.2094"
    },
    {
        "engDate": "2037-11-12",
        "nepDate": "27.07.2094"
    },
    {
        "engDate": "2037-11-13",
        "nepDate": "28.07.2094"
    },
    {
        "engDate": "2037-11-14",
        "nepDate": "29.07.2094"
    },
    {
        "engDate": "2037-11-15",
        "nepDate": "30.07.2094"
    },
    {
        "engDate": "2037-11-16",
        "nepDate": "01.08.2094"
    },
    {
        "engDate": "2037-11-17",
        "nepDate": "02.08.2094"
    },
    {
        "engDate": "2037-11-18",
        "nepDate": "03.08.2094"
    },
    {
        "engDate": "2037-11-19",
        "nepDate": "04.08.2094"
    },
    {
        "engDate": "2037-11-20",
        "nepDate": "05.08.2094"
    },
    {
        "engDate": "2037-11-21",
        "nepDate": "06.08.2094"
    },
    {
        "engDate": "2037-11-22",
        "nepDate": "07.08.2094"
    },
    {
        "engDate": "2037-11-23",
        "nepDate": "08.08.2094"
    },
    {
        "engDate": "2037-11-24",
        "nepDate": "09.08.2094"
    },
    {
        "engDate": "2037-11-25",
        "nepDate": "10.08.2094"
    },
    {
        "engDate": "2037-11-26",
        "nepDate": "11.08.2094"
    },
    {
        "engDate": "2037-11-27",
        "nepDate": "12.08.2094"
    },
    {
        "engDate": "2037-11-28",
        "nepDate": "13.08.2094"
    },
    {
        "engDate": "2037-11-29",
        "nepDate": "14.08.2094"
    },
    {
        "engDate": "2037-11-30",
        "nepDate": "15.08.2094"
    },
    {
        "engDate": "2037-12-01",
        "nepDate": "16.08.2094"
    },
    {
        "engDate": "2037-12-02",
        "nepDate": "17.08.2094"
    },
    {
        "engDate": "2037-12-03",
        "nepDate": "18.08.2094"
    },
    {
        "engDate": "2037-12-04",
        "nepDate": "19.08.2094"
    },
    {
        "engDate": "2037-12-05",
        "nepDate": "20.08.2094"
    },
    {
        "engDate": "2037-12-06",
        "nepDate": "21.08.2094"
    },
    {
        "engDate": "2037-12-07",
        "nepDate": "22.08.2094"
    },
    {
        "engDate": "2037-12-08",
        "nepDate": "23.08.2094"
    },
    {
        "engDate": "2037-12-09",
        "nepDate": "24.08.2094"
    },
    {
        "engDate": "2037-12-10",
        "nepDate": "25.08.2094"
    },
    {
        "engDate": "2037-12-11",
        "nepDate": "26.08.2094"
    },
    {
        "engDate": "2037-12-12",
        "nepDate": "27.08.2094"
    },
    {
        "engDate": "2037-12-13",
        "nepDate": "28.08.2094"
    },
    {
        "engDate": "2037-12-14",
        "nepDate": "29.08.2094"
    },
    {
        "engDate": "2037-12-15",
        "nepDate": "30.08.2094"
    },
    {
        "engDate": "2037-12-16",
        "nepDate": "01.09.2094"
    },
    {
        "engDate": "2037-12-17",
        "nepDate": "02.09.2094"
    },
    {
        "engDate": "2037-12-18",
        "nepDate": "03.09.2094"
    },
    {
        "engDate": "2037-12-19",
        "nepDate": "04.09.2094"
    },
    {
        "engDate": "2037-12-20",
        "nepDate": "05.09.2094"
    },
    {
        "engDate": "2037-12-21",
        "nepDate": "06.09.2094"
    },
    {
        "engDate": "2037-12-22",
        "nepDate": "07.09.2094"
    },
    {
        "engDate": "2037-12-23",
        "nepDate": "08.09.2094"
    },
    {
        "engDate": "2037-12-24",
        "nepDate": "09.09.2094"
    },
    {
        "engDate": "2037-12-25",
        "nepDate": "10.09.2094"
    },
    {
        "engDate": "2037-12-26",
        "nepDate": "11.09.2094"
    },
    {
        "engDate": "2037-12-27",
        "nepDate": "12.09.2094"
    },
    {
        "engDate": "2037-12-28",
        "nepDate": "13.09.2094"
    },
    {
        "engDate": "2037-12-29",
        "nepDate": "14.09.2094"
    },
    {
        "engDate": "2037-12-30",
        "nepDate": "15.09.2094"
    },
    {
        "engDate": "2037-12-31",
        "nepDate": "16.09.2094"
    },
    {
        "engDate": "2038-01-01",
        "nepDate": "17.09.2094"
    },
    {
        "engDate": "2038-01-02",
        "nepDate": "18.09.2094"
    },
    {
        "engDate": "2038-01-03",
        "nepDate": "19.09.2094"
    },
    {
        "engDate": "2038-01-04",
        "nepDate": "20.09.2094"
    },
    {
        "engDate": "2038-01-05",
        "nepDate": "21.09.2094"
    },
    {
        "engDate": "2038-01-06",
        "nepDate": "22.09.2094"
    },
    {
        "engDate": "2038-01-07",
        "nepDate": "23.09.2094"
    },
    {
        "engDate": "2038-01-08",
        "nepDate": "24.09.2094"
    },
    {
        "engDate": "2038-01-09",
        "nepDate": "25.09.2094"
    },
    {
        "engDate": "2038-01-10",
        "nepDate": "26.09.2094"
    },
    {
        "engDate": "2038-01-11",
        "nepDate": "27.09.2094"
    },
    {
        "engDate": "2038-01-12",
        "nepDate": "28.09.2094"
    },
    {
        "engDate": "2038-01-13",
        "nepDate": "29.09.2094"
    },
    {
        "engDate": "2038-01-14",
        "nepDate": "01.10.2094"
    },
    {
        "engDate": "2038-01-15",
        "nepDate": "02.10.2094"
    },
    {
        "engDate": "2038-01-16",
        "nepDate": "03.10.2094"
    },
    {
        "engDate": "2038-01-17",
        "nepDate": "04.10.2094"
    },
    {
        "engDate": "2038-01-18",
        "nepDate": "05.10.2094"
    },
    {
        "engDate": "2038-01-19",
        "nepDate": "06.10.2094"
    },
    {
        "engDate": "2038-01-20",
        "nepDate": "07.10.2094"
    },
    {
        "engDate": "2038-01-21",
        "nepDate": "08.10.2094"
    },
    {
        "engDate": "2038-01-22",
        "nepDate": "09.10.2094"
    },
    {
        "engDate": "2038-01-23",
        "nepDate": "10.10.2094"
    },
    {
        "engDate": "2038-01-24",
        "nepDate": "11.10.2094"
    },
    {
        "engDate": "2038-01-25",
        "nepDate": "12.10.2094"
    },
    {
        "engDate": "2038-01-26",
        "nepDate": "13.10.2094"
    },
    {
        "engDate": "2038-01-27",
        "nepDate": "14.10.2094"
    },
    {
        "engDate": "2038-01-28",
        "nepDate": "15.10.2094"
    },
    {
        "engDate": "2038-01-29",
        "nepDate": "16.10.2094"
    },
    {
        "engDate": "2038-01-30",
        "nepDate": "17.10.2094"
    },
    {
        "engDate": "2038-01-31",
        "nepDate": "18.10.2094"
    },
    {
        "engDate": "2038-02-01",
        "nepDate": "19.10.2094"
    },
    {
        "engDate": "2038-02-02",
        "nepDate": "20.10.2094"
    },
    {
        "engDate": "2038-02-03",
        "nepDate": "21.10.2094"
    },
    {
        "engDate": "2038-02-04",
        "nepDate": "22.10.2094"
    },
    {
        "engDate": "2038-02-05",
        "nepDate": "23.10.2094"
    },
    {
        "engDate": "2038-02-06",
        "nepDate": "24.10.2094"
    },
    {
        "engDate": "2038-02-07",
        "nepDate": "25.10.2094"
    },
    {
        "engDate": "2038-02-08",
        "nepDate": "26.10.2094"
    },
    {
        "engDate": "2038-02-09",
        "nepDate": "27.10.2094"
    },
    {
        "engDate": "2038-02-10",
        "nepDate": "28.10.2094"
    },
    {
        "engDate": "2038-02-11",
        "nepDate": "29.10.2094"
    },
    {
        "engDate": "2038-02-12",
        "nepDate": "30.10.2094"
    },
    {
        "engDate": "2038-02-13",
        "nepDate": "01.11.2094"
    },
    {
        "engDate": "2038-02-14",
        "nepDate": "02.11.2094"
    },
    {
        "engDate": "2038-02-15",
        "nepDate": "03.11.2094"
    },
    {
        "engDate": "2038-02-16",
        "nepDate": "04.11.2094"
    },
    {
        "engDate": "2038-02-17",
        "nepDate": "05.11.2094"
    },
    {
        "engDate": "2038-02-18",
        "nepDate": "06.11.2094"
    },
    {
        "engDate": "2038-02-19",
        "nepDate": "07.11.2094"
    },
    {
        "engDate": "2038-02-20",
        "nepDate": "08.11.2094"
    },
    {
        "engDate": "2038-02-21",
        "nepDate": "09.11.2094"
    },
    {
        "engDate": "2038-02-22",
        "nepDate": "10.11.2094"
    },
    {
        "engDate": "2038-02-23",
        "nepDate": "11.11.2094"
    },
    {
        "engDate": "2038-02-24",
        "nepDate": "12.11.2094"
    },
    {
        "engDate": "2038-02-25",
        "nepDate": "13.11.2094"
    },
    {
        "engDate": "2038-02-26",
        "nepDate": "14.11.2094"
    },
    {
        "engDate": "2038-02-27",
        "nepDate": "15.11.2094"
    },
    {
        "engDate": "2038-02-28",
        "nepDate": "16.11.2094"
    },
    {
        "engDate": "2038-03-01",
        "nepDate": "17.11.2094"
    },
    {
        "engDate": "2038-03-02",
        "nepDate": "18.11.2094"
    },
    {
        "engDate": "2038-03-03",
        "nepDate": "19.11.2094"
    },
    {
        "engDate": "2038-03-04",
        "nepDate": "20.11.2094"
    },
    {
        "engDate": "2038-03-05",
        "nepDate": "21.11.2094"
    },
    {
        "engDate": "2038-03-06",
        "nepDate": "22.11.2094"
    },
    {
        "engDate": "2038-03-07",
        "nepDate": "23.11.2094"
    },
    {
        "engDate": "2038-03-08",
        "nepDate": "24.11.2094"
    },
    {
        "engDate": "2038-03-09",
        "nepDate": "25.11.2094"
    },
    {
        "engDate": "2038-03-10",
        "nepDate": "26.11.2094"
    },
    {
        "engDate": "2038-03-11",
        "nepDate": "27.11.2094"
    },
    {
        "engDate": "2038-03-12",
        "nepDate": "28.11.2094"
    },
    {
        "engDate": "2038-03-13",
        "nepDate": "29.11.2094"
    },
    {
        "engDate": "2038-03-14",
        "nepDate": "30.11.2094"
    },
    {
        "engDate": "2038-03-15",
        "nepDate": "01.12.2094"
    },
    {
        "engDate": "2038-03-16",
        "nepDate": "02.12.2094"
    },
    {
        "engDate": "2038-03-17",
        "nepDate": "03.12.2094"
    },
    {
        "engDate": "2038-03-18",
        "nepDate": "04.12.2094"
    },
    {
        "engDate": "2038-03-19",
        "nepDate": "05.12.2094"
    },
    {
        "engDate": "2038-03-20",
        "nepDate": "06.12.2094"
    },
    {
        "engDate": "2038-03-21",
        "nepDate": "07.12.2094"
    },
    {
        "engDate": "2038-03-22",
        "nepDate": "08.12.2094"
    },
    {
        "engDate": "2038-03-23",
        "nepDate": "09.12.2094"
    },
    {
        "engDate": "2038-03-24",
        "nepDate": "10.12.2094"
    },
    {
        "engDate": "2038-03-25",
        "nepDate": "11.12.2094"
    },
    {
        "engDate": "2038-03-26",
        "nepDate": "12.12.2094"
    },
    {
        "engDate": "2038-03-27",
        "nepDate": "13.12.2094"
    },
    {
        "engDate": "2038-03-28",
        "nepDate": "14.12.2094"
    },
    {
        "engDate": "2038-03-29",
        "nepDate": "15.12.2094"
    },
    {
        "engDate": "2038-03-30",
        "nepDate": "16.12.2094"
    },
    {
        "engDate": "2038-03-31",
        "nepDate": "17.12.2094"
    },
    {
        "engDate": "2038-04-01",
        "nepDate": "18.12.2094"
    },
    {
        "engDate": "2038-04-02",
        "nepDate": "19.12.2094"
    },
    {
        "engDate": "2038-04-03",
        "nepDate": "20.12.2094"
    },
    {
        "engDate": "2038-04-04",
        "nepDate": "21.12.2094"
    },
    {
        "engDate": "2038-04-05",
        "nepDate": "22.12.2094"
    },
    {
        "engDate": "2038-04-06",
        "nepDate": "23.12.2094"
    },
    {
        "engDate": "2038-04-07",
        "nepDate": "24.12.2094"
    },
    {
        "engDate": "2038-04-08",
        "nepDate": "25.12.2094"
    },
    {
        "engDate": "2038-04-09",
        "nepDate": "26.12.2094"
    },
    {
        "engDate": "2038-04-10",
        "nepDate": "27.12.2094"
    },
    {
        "engDate": "2038-04-11",
        "nepDate": "28.12.2094"
    },
    {
        "engDate": "2038-04-12",
        "nepDate": "29.12.2094"
    },
    {
        "engDate": "2038-04-13",
        "nepDate": "30.12.2094"
    },
    {
        "engDate": "2038-04-14",
        "nepDate": "01.01.2095"
    },
    {
        "engDate": "2038-04-15",
        "nepDate": "02.01.2095"
    },
    {
        "engDate": "2038-04-16",
        "nepDate": "03.01.2095"
    },
    {
        "engDate": "2038-04-17",
        "nepDate": "04.01.2095"
    },
    {
        "engDate": "2038-04-18",
        "nepDate": "05.01.2095"
    },
    {
        "engDate": "2038-04-19",
        "nepDate": "06.01.2095"
    },
    {
        "engDate": "2038-04-20",
        "nepDate": "07.01.2095"
    },
    {
        "engDate": "2038-04-21",
        "nepDate": "08.01.2095"
    },
    {
        "engDate": "2038-04-22",
        "nepDate": "09.01.2095"
    },
    {
        "engDate": "2038-04-23",
        "nepDate": "10.01.2095"
    },
    {
        "engDate": "2038-04-24",
        "nepDate": "11.01.2095"
    },
    {
        "engDate": "2038-04-25",
        "nepDate": "12.01.2095"
    },
    {
        "engDate": "2038-04-26",
        "nepDate": "13.01.2095"
    },
    {
        "engDate": "2038-04-27",
        "nepDate": "14.01.2095"
    },
    {
        "engDate": "2038-04-28",
        "nepDate": "15.01.2095"
    },
    {
        "engDate": "2038-04-29",
        "nepDate": "16.01.2095"
    },
    {
        "engDate": "2038-04-30",
        "nepDate": "17.01.2095"
    },
    {
        "engDate": "2038-05-01",
        "nepDate": "18.01.2095"
    },
    {
        "engDate": "2038-05-02",
        "nepDate": "19.01.2095"
    },
    {
        "engDate": "2038-05-03",
        "nepDate": "20.01.2095"
    },
    {
        "engDate": "2038-05-04",
        "nepDate": "21.01.2095"
    },
    {
        "engDate": "2038-05-05",
        "nepDate": "22.01.2095"
    },
    {
        "engDate": "2038-05-06",
        "nepDate": "23.01.2095"
    },
    {
        "engDate": "2038-05-07",
        "nepDate": "24.01.2095"
    },
    {
        "engDate": "2038-05-08",
        "nepDate": "25.01.2095"
    },
    {
        "engDate": "2038-05-09",
        "nepDate": "26.01.2095"
    },
    {
        "engDate": "2038-05-10",
        "nepDate": "27.01.2095"
    },
    {
        "engDate": "2038-05-11",
        "nepDate": "28.01.2095"
    },
    {
        "engDate": "2038-05-12",
        "nepDate": "29.01.2095"
    },
    {
        "engDate": "2038-05-13",
        "nepDate": "30.01.2095"
    },
    {
        "engDate": "2038-05-14",
        "nepDate": "31.01.2095"
    },
    {
        "engDate": "2038-05-15",
        "nepDate": "01.02.2095"
    },
    {
        "engDate": "2038-05-16",
        "nepDate": "02.02.2095"
    },
    {
        "engDate": "2038-05-17",
        "nepDate": "03.02.2095"
    },
    {
        "engDate": "2038-05-18",
        "nepDate": "04.02.2095"
    },
    {
        "engDate": "2038-05-19",
        "nepDate": "05.02.2095"
    },
    {
        "engDate": "2038-05-20",
        "nepDate": "06.02.2095"
    },
    {
        "engDate": "2038-05-21",
        "nepDate": "07.02.2095"
    },
    {
        "engDate": "2038-05-22",
        "nepDate": "08.02.2095"
    },
    {
        "engDate": "2038-05-23",
        "nepDate": "09.02.2095"
    },
    {
        "engDate": "2038-05-24",
        "nepDate": "10.02.2095"
    },
    {
        "engDate": "2038-05-25",
        "nepDate": "11.02.2095"
    },
    {
        "engDate": "2038-05-26",
        "nepDate": "12.02.2095"
    },
    {
        "engDate": "2038-05-27",
        "nepDate": "13.02.2095"
    },
    {
        "engDate": "2038-05-28",
        "nepDate": "14.02.2095"
    },
    {
        "engDate": "2038-05-29",
        "nepDate": "15.02.2095"
    },
    {
        "engDate": "2038-05-30",
        "nepDate": "16.02.2095"
    },
    {
        "engDate": "2038-05-31",
        "nepDate": "17.02.2095"
    },
    {
        "engDate": "2038-06-01",
        "nepDate": "18.02.2095"
    },
    {
        "engDate": "2038-06-02",
        "nepDate": "19.02.2095"
    },
    {
        "engDate": "2038-06-03",
        "nepDate": "20.02.2095"
    },
    {
        "engDate": "2038-06-04",
        "nepDate": "21.02.2095"
    },
    {
        "engDate": "2038-06-05",
        "nepDate": "22.02.2095"
    },
    {
        "engDate": "2038-06-06",
        "nepDate": "23.02.2095"
    },
    {
        "engDate": "2038-06-07",
        "nepDate": "24.02.2095"
    },
    {
        "engDate": "2038-06-08",
        "nepDate": "25.02.2095"
    },
    {
        "engDate": "2038-06-09",
        "nepDate": "26.02.2095"
    },
    {
        "engDate": "2038-06-10",
        "nepDate": "27.02.2095"
    },
    {
        "engDate": "2038-06-11",
        "nepDate": "28.02.2095"
    },
    {
        "engDate": "2038-06-12",
        "nepDate": "29.02.2095"
    },
    {
        "engDate": "2038-06-13",
        "nepDate": "30.02.2095"
    },
    {
        "engDate": "2038-06-14",
        "nepDate": "31.02.2095"
    },
    {
        "engDate": "2038-06-15",
        "nepDate": "01.03.2095"
    },
    {
        "engDate": "2038-06-16",
        "nepDate": "02.03.2095"
    },
    {
        "engDate": "2038-06-17",
        "nepDate": "03.03.2095"
    },
    {
        "engDate": "2038-06-18",
        "nepDate": "04.03.2095"
    },
    {
        "engDate": "2038-06-19",
        "nepDate": "05.03.2095"
    },
    {
        "engDate": "2038-06-20",
        "nepDate": "06.03.2095"
    },
    {
        "engDate": "2038-06-21",
        "nepDate": "07.03.2095"
    },
    {
        "engDate": "2038-06-22",
        "nepDate": "08.03.2095"
    },
    {
        "engDate": "2038-06-23",
        "nepDate": "09.03.2095"
    },
    {
        "engDate": "2038-06-24",
        "nepDate": "10.03.2095"
    },
    {
        "engDate": "2038-06-25",
        "nepDate": "11.03.2095"
    },
    {
        "engDate": "2038-06-26",
        "nepDate": "12.03.2095"
    },
    {
        "engDate": "2038-06-27",
        "nepDate": "13.03.2095"
    },
    {
        "engDate": "2038-06-28",
        "nepDate": "14.03.2095"
    },
    {
        "engDate": "2038-06-29",
        "nepDate": "15.03.2095"
    },
    {
        "engDate": "2038-06-30",
        "nepDate": "16.03.2095"
    },
    {
        "engDate": "2038-07-01",
        "nepDate": "17.03.2095"
    },
    {
        "engDate": "2038-07-02",
        "nepDate": "18.03.2095"
    },
    {
        "engDate": "2038-07-03",
        "nepDate": "19.03.2095"
    },
    {
        "engDate": "2038-07-04",
        "nepDate": "20.03.2095"
    },
    {
        "engDate": "2038-07-05",
        "nepDate": "21.03.2095"
    },
    {
        "engDate": "2038-07-06",
        "nepDate": "22.03.2095"
    },
    {
        "engDate": "2038-07-07",
        "nepDate": "23.03.2095"
    },
    {
        "engDate": "2038-07-08",
        "nepDate": "24.03.2095"
    },
    {
        "engDate": "2038-07-09",
        "nepDate": "25.03.2095"
    },
    {
        "engDate": "2038-07-10",
        "nepDate": "26.03.2095"
    },
    {
        "engDate": "2038-07-11",
        "nepDate": "27.03.2095"
    },
    {
        "engDate": "2038-07-12",
        "nepDate": "28.03.2095"
    },
    {
        "engDate": "2038-07-13",
        "nepDate": "29.03.2095"
    },
    {
        "engDate": "2038-07-14",
        "nepDate": "30.03.2095"
    },
    {
        "engDate": "2038-07-15",
        "nepDate": "31.03.2095"
    },
    {
        "engDate": "2038-07-16",
        "nepDate": "32.03.2095"
    },
    {
        "engDate": "2038-07-17",
        "nepDate": "01.04.2095"
    },
    {
        "engDate": "2038-07-18",
        "nepDate": "02.04.2095"
    },
    {
        "engDate": "2038-07-19",
        "nepDate": "03.04.2095"
    },
    {
        "engDate": "2038-07-20",
        "nepDate": "04.04.2095"
    },
    {
        "engDate": "2038-07-21",
        "nepDate": "05.04.2095"
    },
    {
        "engDate": "2038-07-22",
        "nepDate": "06.04.2095"
    },
    {
        "engDate": "2038-07-23",
        "nepDate": "07.04.2095"
    },
    {
        "engDate": "2038-07-24",
        "nepDate": "08.04.2095"
    },
    {
        "engDate": "2038-07-25",
        "nepDate": "09.04.2095"
    },
    {
        "engDate": "2038-07-26",
        "nepDate": "10.04.2095"
    },
    {
        "engDate": "2038-07-27",
        "nepDate": "11.04.2095"
    },
    {
        "engDate": "2038-07-28",
        "nepDate": "12.04.2095"
    },
    {
        "engDate": "2038-07-29",
        "nepDate": "13.04.2095"
    },
    {
        "engDate": "2038-07-30",
        "nepDate": "14.04.2095"
    },
    {
        "engDate": "2038-07-31",
        "nepDate": "15.04.2095"
    },
    {
        "engDate": "2038-08-01",
        "nepDate": "16.04.2095"
    },
    {
        "engDate": "2038-08-02",
        "nepDate": "17.04.2095"
    },
    {
        "engDate": "2038-08-03",
        "nepDate": "18.04.2095"
    },
    {
        "engDate": "2038-08-04",
        "nepDate": "19.04.2095"
    },
    {
        "engDate": "2038-08-05",
        "nepDate": "20.04.2095"
    },
    {
        "engDate": "2038-08-06",
        "nepDate": "21.04.2095"
    },
    {
        "engDate": "2038-08-07",
        "nepDate": "22.04.2095"
    },
    {
        "engDate": "2038-08-08",
        "nepDate": "23.04.2095"
    },
    {
        "engDate": "2038-08-09",
        "nepDate": "24.04.2095"
    },
    {
        "engDate": "2038-08-10",
        "nepDate": "25.04.2095"
    },
    {
        "engDate": "2038-08-11",
        "nepDate": "26.04.2095"
    },
    {
        "engDate": "2038-08-12",
        "nepDate": "27.04.2095"
    },
    {
        "engDate": "2038-08-13",
        "nepDate": "28.04.2095"
    },
    {
        "engDate": "2038-08-14",
        "nepDate": "29.04.2095"
    },
    {
        "engDate": "2038-08-15",
        "nepDate": "30.04.2095"
    },
    {
        "engDate": "2038-08-16",
        "nepDate": "31.04.2095"
    },
    {
        "engDate": "2038-08-17",
        "nepDate": "01.05.2095"
    },
    {
        "engDate": "2038-08-18",
        "nepDate": "02.05.2095"
    },
    {
        "engDate": "2038-08-19",
        "nepDate": "03.05.2095"
    },
    {
        "engDate": "2038-08-20",
        "nepDate": "04.05.2095"
    },
    {
        "engDate": "2038-08-21",
        "nepDate": "05.05.2095"
    },
    {
        "engDate": "2038-08-22",
        "nepDate": "06.05.2095"
    },
    {
        "engDate": "2038-08-23",
        "nepDate": "07.05.2095"
    },
    {
        "engDate": "2038-08-24",
        "nepDate": "08.05.2095"
    },
    {
        "engDate": "2038-08-25",
        "nepDate": "09.05.2095"
    },
    {
        "engDate": "2038-08-26",
        "nepDate": "10.05.2095"
    },
    {
        "engDate": "2038-08-27",
        "nepDate": "11.05.2095"
    },
    {
        "engDate": "2038-08-28",
        "nepDate": "12.05.2095"
    },
    {
        "engDate": "2038-08-29",
        "nepDate": "13.05.2095"
    },
    {
        "engDate": "2038-08-30",
        "nepDate": "14.05.2095"
    },
    {
        "engDate": "2038-08-31",
        "nepDate": "15.05.2095"
    },
    {
        "engDate": "2038-09-01",
        "nepDate": "16.05.2095"
    },
    {
        "engDate": "2038-09-02",
        "nepDate": "17.05.2095"
    },
    {
        "engDate": "2038-09-03",
        "nepDate": "18.05.2095"
    },
    {
        "engDate": "2038-09-04",
        "nepDate": "19.05.2095"
    },
    {
        "engDate": "2038-09-05",
        "nepDate": "20.05.2095"
    },
    {
        "engDate": "2038-09-06",
        "nepDate": "21.05.2095"
    },
    {
        "engDate": "2038-09-07",
        "nepDate": "22.05.2095"
    },
    {
        "engDate": "2038-09-08",
        "nepDate": "23.05.2095"
    },
    {
        "engDate": "2038-09-09",
        "nepDate": "24.05.2095"
    },
    {
        "engDate": "2038-09-10",
        "nepDate": "25.05.2095"
    },
    {
        "engDate": "2038-09-11",
        "nepDate": "26.05.2095"
    },
    {
        "engDate": "2038-09-12",
        "nepDate": "27.05.2095"
    },
    {
        "engDate": "2038-09-13",
        "nepDate": "28.05.2095"
    },
    {
        "engDate": "2038-09-14",
        "nepDate": "29.05.2095"
    },
    {
        "engDate": "2038-09-15",
        "nepDate": "30.05.2095"
    },
    {
        "engDate": "2038-09-16",
        "nepDate": "31.05.2095"
    },
    {
        "engDate": "2038-09-17",
        "nepDate": "01.06.2095"
    },
    {
        "engDate": "2038-09-18",
        "nepDate": "02.06.2095"
    },
    {
        "engDate": "2038-09-19",
        "nepDate": "03.06.2095"
    },
    {
        "engDate": "2038-09-20",
        "nepDate": "04.06.2095"
    },
    {
        "engDate": "2038-09-21",
        "nepDate": "05.06.2095"
    },
    {
        "engDate": "2038-09-22",
        "nepDate": "06.06.2095"
    },
    {
        "engDate": "2038-09-23",
        "nepDate": "07.06.2095"
    },
    {
        "engDate": "2038-09-24",
        "nepDate": "08.06.2095"
    },
    {
        "engDate": "2038-09-25",
        "nepDate": "09.06.2095"
    },
    {
        "engDate": "2038-09-26",
        "nepDate": "10.06.2095"
    },
    {
        "engDate": "2038-09-27",
        "nepDate": "11.06.2095"
    },
    {
        "engDate": "2038-09-28",
        "nepDate": "12.06.2095"
    },
    {
        "engDate": "2038-09-29",
        "nepDate": "13.06.2095"
    },
    {
        "engDate": "2038-09-30",
        "nepDate": "14.06.2095"
    },
    {
        "engDate": "2038-10-01",
        "nepDate": "15.06.2095"
    },
    {
        "engDate": "2038-10-02",
        "nepDate": "16.06.2095"
    },
    {
        "engDate": "2038-10-03",
        "nepDate": "17.06.2095"
    },
    {
        "engDate": "2038-10-04",
        "nepDate": "18.06.2095"
    },
    {
        "engDate": "2038-10-05",
        "nepDate": "19.06.2095"
    },
    {
        "engDate": "2038-10-06",
        "nepDate": "20.06.2095"
    },
    {
        "engDate": "2038-10-07",
        "nepDate": "21.06.2095"
    },
    {
        "engDate": "2038-10-08",
        "nepDate": "22.06.2095"
    },
    {
        "engDate": "2038-10-09",
        "nepDate": "23.06.2095"
    },
    {
        "engDate": "2038-10-10",
        "nepDate": "24.06.2095"
    },
    {
        "engDate": "2038-10-11",
        "nepDate": "25.06.2095"
    },
    {
        "engDate": "2038-10-12",
        "nepDate": "26.06.2095"
    },
    {
        "engDate": "2038-10-13",
        "nepDate": "27.06.2095"
    },
    {
        "engDate": "2038-10-14",
        "nepDate": "28.06.2095"
    },
    {
        "engDate": "2038-10-15",
        "nepDate": "29.06.2095"
    },
    {
        "engDate": "2038-10-16",
        "nepDate": "30.06.2095"
    },
    {
        "engDate": "2038-10-17",
        "nepDate": "31.06.2095"
    },
    {
        "engDate": "2038-10-18",
        "nepDate": "01.07.2095"
    },
    {
        "engDate": "2038-10-19",
        "nepDate": "02.07.2095"
    },
    {
        "engDate": "2038-10-20",
        "nepDate": "03.07.2095"
    },
    {
        "engDate": "2038-10-21",
        "nepDate": "04.07.2095"
    },
    {
        "engDate": "2038-10-22",
        "nepDate": "05.07.2095"
    },
    {
        "engDate": "2038-10-23",
        "nepDate": "06.07.2095"
    },
    {
        "engDate": "2038-10-24",
        "nepDate": "07.07.2095"
    },
    {
        "engDate": "2038-10-25",
        "nepDate": "08.07.2095"
    },
    {
        "engDate": "2038-10-26",
        "nepDate": "09.07.2095"
    },
    {
        "engDate": "2038-10-27",
        "nepDate": "10.07.2095"
    },
    {
        "engDate": "2038-10-28",
        "nepDate": "11.07.2095"
    },
    {
        "engDate": "2038-10-29",
        "nepDate": "12.07.2095"
    },
    {
        "engDate": "2038-10-30",
        "nepDate": "13.07.2095"
    },
    {
        "engDate": "2038-10-31",
        "nepDate": "14.07.2095"
    },
    {
        "engDate": "2038-11-01",
        "nepDate": "15.07.2095"
    },
    {
        "engDate": "2038-11-02",
        "nepDate": "16.07.2095"
    },
    {
        "engDate": "2038-11-03",
        "nepDate": "17.07.2095"
    },
    {
        "engDate": "2038-11-04",
        "nepDate": "18.07.2095"
    },
    {
        "engDate": "2038-11-05",
        "nepDate": "19.07.2095"
    },
    {
        "engDate": "2038-11-06",
        "nepDate": "20.07.2095"
    },
    {
        "engDate": "2038-11-07",
        "nepDate": "21.07.2095"
    },
    {
        "engDate": "2038-11-08",
        "nepDate": "22.07.2095"
    },
    {
        "engDate": "2038-11-09",
        "nepDate": "23.07.2095"
    },
    {
        "engDate": "2038-11-10",
        "nepDate": "24.07.2095"
    },
    {
        "engDate": "2038-11-11",
        "nepDate": "25.07.2095"
    },
    {
        "engDate": "2038-11-12",
        "nepDate": "26.07.2095"
    },
    {
        "engDate": "2038-11-13",
        "nepDate": "27.07.2095"
    },
    {
        "engDate": "2038-11-14",
        "nepDate": "28.07.2095"
    },
    {
        "engDate": "2038-11-15",
        "nepDate": "29.07.2095"
    },
    {
        "engDate": "2038-11-16",
        "nepDate": "30.07.2095"
    },
    {
        "engDate": "2038-11-17",
        "nepDate": "01.08.2095"
    },
    {
        "engDate": "2038-11-18",
        "nepDate": "02.08.2095"
    },
    {
        "engDate": "2038-11-19",
        "nepDate": "03.08.2095"
    },
    {
        "engDate": "2038-11-20",
        "nepDate": "04.08.2095"
    },
    {
        "engDate": "2038-11-21",
        "nepDate": "05.08.2095"
    },
    {
        "engDate": "2038-11-22",
        "nepDate": "06.08.2095"
    },
    {
        "engDate": "2038-11-23",
        "nepDate": "07.08.2095"
    },
    {
        "engDate": "2038-11-24",
        "nepDate": "08.08.2095"
    },
    {
        "engDate": "2038-11-25",
        "nepDate": "09.08.2095"
    },
    {
        "engDate": "2038-11-26",
        "nepDate": "10.08.2095"
    },
    {
        "engDate": "2038-11-27",
        "nepDate": "11.08.2095"
    },
    {
        "engDate": "2038-11-28",
        "nepDate": "12.08.2095"
    },
    {
        "engDate": "2038-11-29",
        "nepDate": "13.08.2095"
    },
    {
        "engDate": "2038-11-30",
        "nepDate": "14.08.2095"
    },
    {
        "engDate": "2038-12-01",
        "nepDate": "15.08.2095"
    },
    {
        "engDate": "2038-12-02",
        "nepDate": "16.08.2095"
    },
    {
        "engDate": "2038-12-03",
        "nepDate": "17.08.2095"
    },
    {
        "engDate": "2038-12-04",
        "nepDate": "18.08.2095"
    },
    {
        "engDate": "2038-12-05",
        "nepDate": "19.08.2095"
    },
    {
        "engDate": "2038-12-06",
        "nepDate": "20.08.2095"
    },
    {
        "engDate": "2038-12-07",
        "nepDate": "21.08.2095"
    },
    {
        "engDate": "2038-12-08",
        "nepDate": "22.08.2095"
    },
    {
        "engDate": "2038-12-09",
        "nepDate": "23.08.2095"
    },
    {
        "engDate": "2038-12-10",
        "nepDate": "24.08.2095"
    },
    {
        "engDate": "2038-12-11",
        "nepDate": "25.08.2095"
    },
    {
        "engDate": "2038-12-12",
        "nepDate": "26.08.2095"
    },
    {
        "engDate": "2038-12-13",
        "nepDate": "27.08.2095"
    },
    {
        "engDate": "2038-12-14",
        "nepDate": "28.08.2095"
    },
    {
        "engDate": "2038-12-15",
        "nepDate": "29.08.2095"
    },
    {
        "engDate": "2038-12-16",
        "nepDate": "01.09.2095"
    },
    {
        "engDate": "2038-12-17",
        "nepDate": "02.09.2095"
    },
    {
        "engDate": "2038-12-18",
        "nepDate": "03.09.2095"
    },
    {
        "engDate": "2038-12-19",
        "nepDate": "04.09.2095"
    },
    {
        "engDate": "2038-12-20",
        "nepDate": "05.09.2095"
    },
    {
        "engDate": "2038-12-21",
        "nepDate": "06.09.2095"
    },
    {
        "engDate": "2038-12-22",
        "nepDate": "07.09.2095"
    },
    {
        "engDate": "2038-12-23",
        "nepDate": "08.09.2095"
    },
    {
        "engDate": "2038-12-24",
        "nepDate": "09.09.2095"
    },
    {
        "engDate": "2038-12-25",
        "nepDate": "10.09.2095"
    },
    {
        "engDate": "2038-12-26",
        "nepDate": "11.09.2095"
    },
    {
        "engDate": "2038-12-27",
        "nepDate": "12.09.2095"
    },
    {
        "engDate": "2038-12-28",
        "nepDate": "13.09.2095"
    },
    {
        "engDate": "2038-12-29",
        "nepDate": "14.09.2095"
    },
    {
        "engDate": "2038-12-30",
        "nepDate": "15.09.2095"
    },
    {
        "engDate": "2038-12-31",
        "nepDate": "16.09.2095"
    },
    {
        "engDate": "2039-01-01",
        "nepDate": "17.09.2095"
    },
    {
        "engDate": "2039-01-02",
        "nepDate": "18.09.2095"
    },
    {
        "engDate": "2039-01-03",
        "nepDate": "19.09.2095"
    },
    {
        "engDate": "2039-01-04",
        "nepDate": "20.09.2095"
    },
    {
        "engDate": "2039-01-05",
        "nepDate": "21.09.2095"
    },
    {
        "engDate": "2039-01-06",
        "nepDate": "22.09.2095"
    },
    {
        "engDate": "2039-01-07",
        "nepDate": "23.09.2095"
    },
    {
        "engDate": "2039-01-08",
        "nepDate": "24.09.2095"
    },
    {
        "engDate": "2039-01-09",
        "nepDate": "25.09.2095"
    },
    {
        "engDate": "2039-01-10",
        "nepDate": "26.09.2095"
    },
    {
        "engDate": "2039-01-11",
        "nepDate": "27.09.2095"
    },
    {
        "engDate": "2039-01-12",
        "nepDate": "28.09.2095"
    },
    {
        "engDate": "2039-01-13",
        "nepDate": "29.09.2095"
    },
    {
        "engDate": "2039-01-14",
        "nepDate": "30.09.2095"
    },
    {
        "engDate": "2039-01-15",
        "nepDate": "01.10.2095"
    },
    {
        "engDate": "2039-01-16",
        "nepDate": "02.10.2095"
    },
    {
        "engDate": "2039-01-17",
        "nepDate": "03.10.2095"
    },
    {
        "engDate": "2039-01-18",
        "nepDate": "04.10.2095"
    },
    {
        "engDate": "2039-01-19",
        "nepDate": "05.10.2095"
    },
    {
        "engDate": "2039-01-20",
        "nepDate": "06.10.2095"
    },
    {
        "engDate": "2039-01-21",
        "nepDate": "07.10.2095"
    },
    {
        "engDate": "2039-01-22",
        "nepDate": "08.10.2095"
    },
    {
        "engDate": "2039-01-23",
        "nepDate": "09.10.2095"
    },
    {
        "engDate": "2039-01-24",
        "nepDate": "10.10.2095"
    },
    {
        "engDate": "2039-01-25",
        "nepDate": "11.10.2095"
    },
    {
        "engDate": "2039-01-26",
        "nepDate": "12.10.2095"
    },
    {
        "engDate": "2039-01-27",
        "nepDate": "13.10.2095"
    },
    {
        "engDate": "2039-01-28",
        "nepDate": "14.10.2095"
    },
    {
        "engDate": "2039-01-29",
        "nepDate": "15.10.2095"
    },
    {
        "engDate": "2039-01-30",
        "nepDate": "16.10.2095"
    },
    {
        "engDate": "2039-01-31",
        "nepDate": "17.10.2095"
    },
    {
        "engDate": "2039-02-01",
        "nepDate": "18.10.2095"
    },
    {
        "engDate": "2039-02-02",
        "nepDate": "19.10.2095"
    },
    {
        "engDate": "2039-02-03",
        "nepDate": "20.10.2095"
    },
    {
        "engDate": "2039-02-04",
        "nepDate": "21.10.2095"
    },
    {
        "engDate": "2039-02-05",
        "nepDate": "22.10.2095"
    },
    {
        "engDate": "2039-02-06",
        "nepDate": "23.10.2095"
    },
    {
        "engDate": "2039-02-07",
        "nepDate": "24.10.2095"
    },
    {
        "engDate": "2039-02-08",
        "nepDate": "25.10.2095"
    },
    {
        "engDate": "2039-02-09",
        "nepDate": "26.10.2095"
    },
    {
        "engDate": "2039-02-10",
        "nepDate": "27.10.2095"
    },
    {
        "engDate": "2039-02-11",
        "nepDate": "28.10.2095"
    },
    {
        "engDate": "2039-02-12",
        "nepDate": "29.10.2095"
    },
    {
        "engDate": "2039-02-13",
        "nepDate": "30.10.2095"
    },
    {
        "engDate": "2039-02-14",
        "nepDate": "01.11.2095"
    },
    {
        "engDate": "2039-02-15",
        "nepDate": "02.11.2095"
    },
    {
        "engDate": "2039-02-16",
        "nepDate": "03.11.2095"
    },
    {
        "engDate": "2039-02-17",
        "nepDate": "04.11.2095"
    },
    {
        "engDate": "2039-02-18",
        "nepDate": "05.11.2095"
    },
    {
        "engDate": "2039-02-19",
        "nepDate": "06.11.2095"
    },
    {
        "engDate": "2039-02-20",
        "nepDate": "07.11.2095"
    },
    {
        "engDate": "2039-02-21",
        "nepDate": "08.11.2095"
    },
    {
        "engDate": "2039-02-22",
        "nepDate": "09.11.2095"
    },
    {
        "engDate": "2039-02-23",
        "nepDate": "10.11.2095"
    },
    {
        "engDate": "2039-02-24",
        "nepDate": "11.11.2095"
    },
    {
        "engDate": "2039-02-25",
        "nepDate": "12.11.2095"
    },
    {
        "engDate": "2039-02-26",
        "nepDate": "13.11.2095"
    },
    {
        "engDate": "2039-02-27",
        "nepDate": "14.11.2095"
    },
    {
        "engDate": "2039-02-28",
        "nepDate": "15.11.2095"
    },
    {
        "engDate": "2039-03-01",
        "nepDate": "16.11.2095"
    },
    {
        "engDate": "2039-03-02",
        "nepDate": "17.11.2095"
    },
    {
        "engDate": "2039-03-03",
        "nepDate": "18.11.2095"
    },
    {
        "engDate": "2039-03-04",
        "nepDate": "19.11.2095"
    },
    {
        "engDate": "2039-03-05",
        "nepDate": "20.11.2095"
    },
    {
        "engDate": "2039-03-06",
        "nepDate": "21.11.2095"
    },
    {
        "engDate": "2039-03-07",
        "nepDate": "22.11.2095"
    },
    {
        "engDate": "2039-03-08",
        "nepDate": "23.11.2095"
    },
    {
        "engDate": "2039-03-09",
        "nepDate": "24.11.2095"
    },
    {
        "engDate": "2039-03-10",
        "nepDate": "25.11.2095"
    },
    {
        "engDate": "2039-03-11",
        "nepDate": "26.11.2095"
    },
    {
        "engDate": "2039-03-12",
        "nepDate": "27.11.2095"
    },
    {
        "engDate": "2039-03-13",
        "nepDate": "28.11.2095"
    },
    {
        "engDate": "2039-03-14",
        "nepDate": "29.11.2095"
    },
    {
        "engDate": "2039-03-15",
        "nepDate": "30.11.2095"
    },
    {
        "engDate": "2039-03-16",
        "nepDate": "01.12.2095"
    },
    {
        "engDate": "2039-03-17",
        "nepDate": "02.12.2095"
    },
    {
        "engDate": "2039-03-18",
        "nepDate": "03.12.2095"
    },
    {
        "engDate": "2039-03-19",
        "nepDate": "04.12.2095"
    },
    {
        "engDate": "2039-03-20",
        "nepDate": "05.12.2095"
    },
    {
        "engDate": "2039-03-21",
        "nepDate": "06.12.2095"
    },
    {
        "engDate": "2039-03-22",
        "nepDate": "07.12.2095"
    },
    {
        "engDate": "2039-03-23",
        "nepDate": "08.12.2095"
    },
    {
        "engDate": "2039-03-24",
        "nepDate": "09.12.2095"
    },
    {
        "engDate": "2039-03-25",
        "nepDate": "10.12.2095"
    },
    {
        "engDate": "2039-03-26",
        "nepDate": "11.12.2095"
    },
    {
        "engDate": "2039-03-27",
        "nepDate": "12.12.2095"
    },
    {
        "engDate": "2039-03-28",
        "nepDate": "13.12.2095"
    },
    {
        "engDate": "2039-03-29",
        "nepDate": "14.12.2095"
    },
    {
        "engDate": "2039-03-30",
        "nepDate": "15.12.2095"
    },
    {
        "engDate": "2039-03-31",
        "nepDate": "16.12.2095"
    },
    {
        "engDate": "2039-04-01",
        "nepDate": "17.12.2095"
    },
    {
        "engDate": "2039-04-02",
        "nepDate": "18.12.2095"
    },
    {
        "engDate": "2039-04-03",
        "nepDate": "19.12.2095"
    },
    {
        "engDate": "2039-04-04",
        "nepDate": "20.12.2095"
    },
    {
        "engDate": "2039-04-05",
        "nepDate": "21.12.2095"
    },
    {
        "engDate": "2039-04-06",
        "nepDate": "22.12.2095"
    },
    {
        "engDate": "2039-04-07",
        "nepDate": "23.12.2095"
    },
    {
        "engDate": "2039-04-08",
        "nepDate": "24.12.2095"
    },
    {
        "engDate": "2039-04-09",
        "nepDate": "25.12.2095"
    },
    {
        "engDate": "2039-04-10",
        "nepDate": "26.12.2095"
    },
    {
        "engDate": "2039-04-11",
        "nepDate": "27.12.2095"
    },
    {
        "engDate": "2039-04-12",
        "nepDate": "28.12.2095"
    },
    {
        "engDate": "2039-04-13",
        "nepDate": "29.12.2095"
    },
    {
        "engDate": "2039-04-14",
        "nepDate": "30.12.2095"
    },
    {
        "engDate": "2039-04-15",
        "nepDate": "01.01.2096"
    },
    {
        "engDate": "2039-04-16",
        "nepDate": "02.01.2096"
    },
    {
        "engDate": "2039-04-17",
        "nepDate": "03.01.2096"
    },
    {
        "engDate": "2039-04-18",
        "nepDate": "04.01.2096"
    },
    {
        "engDate": "2039-04-19",
        "nepDate": "05.01.2096"
    },
    {
        "engDate": "2039-04-20",
        "nepDate": "06.01.2096"
    },
    {
        "engDate": "2039-04-21",
        "nepDate": "07.01.2096"
    },
    {
        "engDate": "2039-04-22",
        "nepDate": "08.01.2096"
    },
    {
        "engDate": "2039-04-23",
        "nepDate": "09.01.2096"
    },
    {
        "engDate": "2039-04-24",
        "nepDate": "10.01.2096"
    },
    {
        "engDate": "2039-04-25",
        "nepDate": "11.01.2096"
    },
    {
        "engDate": "2039-04-26",
        "nepDate": "12.01.2096"
    },
    {
        "engDate": "2039-04-27",
        "nepDate": "13.01.2096"
    },
    {
        "engDate": "2039-04-28",
        "nepDate": "14.01.2096"
    },
    {
        "engDate": "2039-04-29",
        "nepDate": "15.01.2096"
    },
    {
        "engDate": "2039-04-30",
        "nepDate": "16.01.2096"
    },
    {
        "engDate": "2039-05-01",
        "nepDate": "17.01.2096"
    },
    {
        "engDate": "2039-05-02",
        "nepDate": "18.01.2096"
    },
    {
        "engDate": "2039-05-03",
        "nepDate": "19.01.2096"
    },
    {
        "engDate": "2039-05-04",
        "nepDate": "20.01.2096"
    },
    {
        "engDate": "2039-05-05",
        "nepDate": "21.01.2096"
    },
    {
        "engDate": "2039-05-06",
        "nepDate": "22.01.2096"
    },
    {
        "engDate": "2039-05-07",
        "nepDate": "23.01.2096"
    },
    {
        "engDate": "2039-05-08",
        "nepDate": "24.01.2096"
    },
    {
        "engDate": "2039-05-09",
        "nepDate": "25.01.2096"
    },
    {
        "engDate": "2039-05-10",
        "nepDate": "26.01.2096"
    },
    {
        "engDate": "2039-05-11",
        "nepDate": "27.01.2096"
    },
    {
        "engDate": "2039-05-12",
        "nepDate": "28.01.2096"
    },
    {
        "engDate": "2039-05-13",
        "nepDate": "29.01.2096"
    },
    {
        "engDate": "2039-05-14",
        "nepDate": "30.01.2096"
    },
    {
        "engDate": "2039-05-15",
        "nepDate": "01.02.2096"
    },
    {
        "engDate": "2039-05-16",
        "nepDate": "02.02.2096"
    },
    {
        "engDate": "2039-05-17",
        "nepDate": "03.02.2096"
    },
    {
        "engDate": "2039-05-18",
        "nepDate": "04.02.2096"
    },
    {
        "engDate": "2039-05-19",
        "nepDate": "05.02.2096"
    },
    {
        "engDate": "2039-05-20",
        "nepDate": "06.02.2096"
    },
    {
        "engDate": "2039-05-21",
        "nepDate": "07.02.2096"
    },
    {
        "engDate": "2039-05-22",
        "nepDate": "08.02.2096"
    },
    {
        "engDate": "2039-05-23",
        "nepDate": "09.02.2096"
    },
    {
        "engDate": "2039-05-24",
        "nepDate": "10.02.2096"
    },
    {
        "engDate": "2039-05-25",
        "nepDate": "11.02.2096"
    },
    {
        "engDate": "2039-05-26",
        "nepDate": "12.02.2096"
    },
    {
        "engDate": "2039-05-27",
        "nepDate": "13.02.2096"
    },
    {
        "engDate": "2039-05-28",
        "nepDate": "14.02.2096"
    },
    {
        "engDate": "2039-05-29",
        "nepDate": "15.02.2096"
    },
    {
        "engDate": "2039-05-30",
        "nepDate": "16.02.2096"
    },
    {
        "engDate": "2039-05-31",
        "nepDate": "17.02.2096"
    },
    {
        "engDate": "2039-06-01",
        "nepDate": "18.02.2096"
    },
    {
        "engDate": "2039-06-02",
        "nepDate": "19.02.2096"
    },
    {
        "engDate": "2039-06-03",
        "nepDate": "20.02.2096"
    },
    {
        "engDate": "2039-06-04",
        "nepDate": "21.02.2096"
    },
    {
        "engDate": "2039-06-05",
        "nepDate": "22.02.2096"
    },
    {
        "engDate": "2039-06-06",
        "nepDate": "23.02.2096"
    },
    {
        "engDate": "2039-06-07",
        "nepDate": "24.02.2096"
    },
    {
        "engDate": "2039-06-08",
        "nepDate": "25.02.2096"
    },
    {
        "engDate": "2039-06-09",
        "nepDate": "26.02.2096"
    },
    {
        "engDate": "2039-06-10",
        "nepDate": "27.02.2096"
    },
    {
        "engDate": "2039-06-11",
        "nepDate": "28.02.2096"
    },
    {
        "engDate": "2039-06-12",
        "nepDate": "29.02.2096"
    },
    {
        "engDate": "2039-06-13",
        "nepDate": "30.02.2096"
    },
    {
        "engDate": "2039-06-14",
        "nepDate": "31.02.2096"
    },
    {
        "engDate": "2039-06-15",
        "nepDate": "01.03.2096"
    },
    {
        "engDate": "2039-06-16",
        "nepDate": "02.03.2096"
    },
    {
        "engDate": "2039-06-17",
        "nepDate": "03.03.2096"
    },
    {
        "engDate": "2039-06-18",
        "nepDate": "04.03.2096"
    },
    {
        "engDate": "2039-06-19",
        "nepDate": "05.03.2096"
    },
    {
        "engDate": "2039-06-20",
        "nepDate": "06.03.2096"
    },
    {
        "engDate": "2039-06-21",
        "nepDate": "07.03.2096"
    },
    {
        "engDate": "2039-06-22",
        "nepDate": "08.03.2096"
    },
    {
        "engDate": "2039-06-23",
        "nepDate": "09.03.2096"
    },
    {
        "engDate": "2039-06-24",
        "nepDate": "10.03.2096"
    },
    {
        "engDate": "2039-06-25",
        "nepDate": "11.03.2096"
    },
    {
        "engDate": "2039-06-26",
        "nepDate": "12.03.2096"
    },
    {
        "engDate": "2039-06-27",
        "nepDate": "13.03.2096"
    },
    {
        "engDate": "2039-06-28",
        "nepDate": "14.03.2096"
    },
    {
        "engDate": "2039-06-29",
        "nepDate": "15.03.2096"
    },
    {
        "engDate": "2039-06-30",
        "nepDate": "16.03.2096"
    },
    {
        "engDate": "2039-07-01",
        "nepDate": "17.03.2096"
    },
    {
        "engDate": "2039-07-02",
        "nepDate": "18.03.2096"
    },
    {
        "engDate": "2039-07-03",
        "nepDate": "19.03.2096"
    },
    {
        "engDate": "2039-07-04",
        "nepDate": "20.03.2096"
    },
    {
        "engDate": "2039-07-05",
        "nepDate": "21.03.2096"
    },
    {
        "engDate": "2039-07-06",
        "nepDate": "22.03.2096"
    },
    {
        "engDate": "2039-07-07",
        "nepDate": "23.03.2096"
    },
    {
        "engDate": "2039-07-08",
        "nepDate": "24.03.2096"
    },
    {
        "engDate": "2039-07-09",
        "nepDate": "25.03.2096"
    },
    {
        "engDate": "2039-07-10",
        "nepDate": "26.03.2096"
    },
    {
        "engDate": "2039-07-11",
        "nepDate": "27.03.2096"
    },
    {
        "engDate": "2039-07-12",
        "nepDate": "28.03.2096"
    },
    {
        "engDate": "2039-07-13",
        "nepDate": "29.03.2096"
    },
    {
        "engDate": "2039-07-14",
        "nepDate": "30.03.2096"
    },
    {
        "engDate": "2039-07-15",
        "nepDate": "31.03.2096"
    },
    {
        "engDate": "2039-07-16",
        "nepDate": "32.03.2096"
    },
    {
        "engDate": "2039-07-17",
        "nepDate": "01.04.2096"
    },
    {
        "engDate": "2039-07-18",
        "nepDate": "02.04.2096"
    },
    {
        "engDate": "2039-07-19",
        "nepDate": "03.04.2096"
    },
    {
        "engDate": "2039-07-20",
        "nepDate": "04.04.2096"
    },
    {
        "engDate": "2039-07-21",
        "nepDate": "05.04.2096"
    },
    {
        "engDate": "2039-07-22",
        "nepDate": "06.04.2096"
    },
    {
        "engDate": "2039-07-23",
        "nepDate": "07.04.2096"
    },
    {
        "engDate": "2039-07-24",
        "nepDate": "08.04.2096"
    },
    {
        "engDate": "2039-07-25",
        "nepDate": "09.04.2096"
    },
    {
        "engDate": "2039-07-26",
        "nepDate": "10.04.2096"
    },
    {
        "engDate": "2039-07-27",
        "nepDate": "11.04.2096"
    },
    {
        "engDate": "2039-07-28",
        "nepDate": "12.04.2096"
    },
    {
        "engDate": "2039-07-29",
        "nepDate": "13.04.2096"
    },
    {
        "engDate": "2039-07-30",
        "nepDate": "14.04.2096"
    },
    {
        "engDate": "2039-07-31",
        "nepDate": "15.04.2096"
    },
    {
        "engDate": "2039-08-01",
        "nepDate": "16.04.2096"
    },
    {
        "engDate": "2039-08-02",
        "nepDate": "17.04.2096"
    },
    {
        "engDate": "2039-08-03",
        "nepDate": "18.04.2096"
    },
    {
        "engDate": "2039-08-04",
        "nepDate": "19.04.2096"
    },
    {
        "engDate": "2039-08-05",
        "nepDate": "20.04.2096"
    },
    {
        "engDate": "2039-08-06",
        "nepDate": "21.04.2096"
    },
    {
        "engDate": "2039-08-07",
        "nepDate": "22.04.2096"
    },
    {
        "engDate": "2039-08-08",
        "nepDate": "23.04.2096"
    },
    {
        "engDate": "2039-08-09",
        "nepDate": "24.04.2096"
    },
    {
        "engDate": "2039-08-10",
        "nepDate": "25.04.2096"
    },
    {
        "engDate": "2039-08-11",
        "nepDate": "26.04.2096"
    },
    {
        "engDate": "2039-08-12",
        "nepDate": "27.04.2096"
    },
    {
        "engDate": "2039-08-13",
        "nepDate": "28.04.2096"
    },
    {
        "engDate": "2039-08-14",
        "nepDate": "29.04.2096"
    },
    {
        "engDate": "2039-08-15",
        "nepDate": "30.04.2096"
    },
    {
        "engDate": "2039-08-16",
        "nepDate": "31.04.2096"
    },
    {
        "engDate": "2039-08-17",
        "nepDate": "32.04.2096"
    },
    {
        "engDate": "2039-08-18",
        "nepDate": "01.05.2096"
    },
    {
        "engDate": "2039-08-19",
        "nepDate": "02.05.2096"
    },
    {
        "engDate": "2039-08-20",
        "nepDate": "03.05.2096"
    },
    {
        "engDate": "2039-08-21",
        "nepDate": "04.05.2096"
    },
    {
        "engDate": "2039-08-22",
        "nepDate": "05.05.2096"
    },
    {
        "engDate": "2039-08-23",
        "nepDate": "06.05.2096"
    },
    {
        "engDate": "2039-08-24",
        "nepDate": "07.05.2096"
    },
    {
        "engDate": "2039-08-25",
        "nepDate": "08.05.2096"
    },
    {
        "engDate": "2039-08-26",
        "nepDate": "09.05.2096"
    },
    {
        "engDate": "2039-08-27",
        "nepDate": "10.05.2096"
    },
    {
        "engDate": "2039-08-28",
        "nepDate": "11.05.2096"
    },
    {
        "engDate": "2039-08-29",
        "nepDate": "12.05.2096"
    },
    {
        "engDate": "2039-08-30",
        "nepDate": "13.05.2096"
    },
    {
        "engDate": "2039-08-31",
        "nepDate": "14.05.2096"
    },
    {
        "engDate": "2039-09-01",
        "nepDate": "15.05.2096"
    },
    {
        "engDate": "2039-09-02",
        "nepDate": "16.05.2096"
    },
    {
        "engDate": "2039-09-03",
        "nepDate": "17.05.2096"
    },
    {
        "engDate": "2039-09-04",
        "nepDate": "18.05.2096"
    },
    {
        "engDate": "2039-09-05",
        "nepDate": "19.05.2096"
    },
    {
        "engDate": "2039-09-06",
        "nepDate": "20.05.2096"
    },
    {
        "engDate": "2039-09-07",
        "nepDate": "21.05.2096"
    },
    {
        "engDate": "2039-09-08",
        "nepDate": "22.05.2096"
    },
    {
        "engDate": "2039-09-09",
        "nepDate": "23.05.2096"
    },
    {
        "engDate": "2039-09-10",
        "nepDate": "24.05.2096"
    },
    {
        "engDate": "2039-09-11",
        "nepDate": "25.05.2096"
    },
    {
        "engDate": "2039-09-12",
        "nepDate": "26.05.2096"
    },
    {
        "engDate": "2039-09-13",
        "nepDate": "27.05.2096"
    },
    {
        "engDate": "2039-09-14",
        "nepDate": "28.05.2096"
    },
    {
        "engDate": "2039-09-15",
        "nepDate": "29.05.2096"
    },
    {
        "engDate": "2039-09-16",
        "nepDate": "30.05.2096"
    },
    {
        "engDate": "2039-09-17",
        "nepDate": "31.05.2096"
    },
    {
        "engDate": "2039-09-18",
        "nepDate": "01.06.2096"
    },
    {
        "engDate": "2039-09-19",
        "nepDate": "02.06.2096"
    },
    {
        "engDate": "2039-09-20",
        "nepDate": "03.06.2096"
    },
    {
        "engDate": "2039-09-21",
        "nepDate": "04.06.2096"
    },
    {
        "engDate": "2039-09-22",
        "nepDate": "05.06.2096"
    },
    {
        "engDate": "2039-09-23",
        "nepDate": "06.06.2096"
    },
    {
        "engDate": "2039-09-24",
        "nepDate": "07.06.2096"
    },
    {
        "engDate": "2039-09-25",
        "nepDate": "08.06.2096"
    },
    {
        "engDate": "2039-09-26",
        "nepDate": "09.06.2096"
    },
    {
        "engDate": "2039-09-27",
        "nepDate": "10.06.2096"
    },
    {
        "engDate": "2039-09-28",
        "nepDate": "11.06.2096"
    },
    {
        "engDate": "2039-09-29",
        "nepDate": "12.06.2096"
    },
    {
        "engDate": "2039-09-30",
        "nepDate": "13.06.2096"
    },
    {
        "engDate": "2039-10-01",
        "nepDate": "14.06.2096"
    },
    {
        "engDate": "2039-10-02",
        "nepDate": "15.06.2096"
    },
    {
        "engDate": "2039-10-03",
        "nepDate": "16.06.2096"
    },
    {
        "engDate": "2039-10-04",
        "nepDate": "17.06.2096"
    },
    {
        "engDate": "2039-10-05",
        "nepDate": "18.06.2096"
    },
    {
        "engDate": "2039-10-06",
        "nepDate": "19.06.2096"
    },
    {
        "engDate": "2039-10-07",
        "nepDate": "20.06.2096"
    },
    {
        "engDate": "2039-10-08",
        "nepDate": "21.06.2096"
    },
    {
        "engDate": "2039-10-09",
        "nepDate": "22.06.2096"
    },
    {
        "engDate": "2039-10-10",
        "nepDate": "23.06.2096"
    },
    {
        "engDate": "2039-10-11",
        "nepDate": "24.06.2096"
    },
    {
        "engDate": "2039-10-12",
        "nepDate": "25.06.2096"
    },
    {
        "engDate": "2039-10-13",
        "nepDate": "26.06.2096"
    },
    {
        "engDate": "2039-10-14",
        "nepDate": "27.06.2096"
    },
    {
        "engDate": "2039-10-15",
        "nepDate": "28.06.2096"
    },
    {
        "engDate": "2039-10-16",
        "nepDate": "29.06.2096"
    },
    {
        "engDate": "2039-10-17",
        "nepDate": "30.06.2096"
    },
    {
        "engDate": "2039-10-18",
        "nepDate": "01.07.2096"
    },
    {
        "engDate": "2039-10-19",
        "nepDate": "02.07.2096"
    },
    {
        "engDate": "2039-10-20",
        "nepDate": "03.07.2096"
    },
    {
        "engDate": "2039-10-21",
        "nepDate": "04.07.2096"
    },
    {
        "engDate": "2039-10-22",
        "nepDate": "05.07.2096"
    },
    {
        "engDate": "2039-10-23",
        "nepDate": "06.07.2096"
    },
    {
        "engDate": "2039-10-24",
        "nepDate": "07.07.2096"
    },
    {
        "engDate": "2039-10-25",
        "nepDate": "08.07.2096"
    },
    {
        "engDate": "2039-10-26",
        "nepDate": "09.07.2096"
    },
    {
        "engDate": "2039-10-27",
        "nepDate": "10.07.2096"
    },
    {
        "engDate": "2039-10-28",
        "nepDate": "11.07.2096"
    },
    {
        "engDate": "2039-10-29",
        "nepDate": "12.07.2096"
    },
    {
        "engDate": "2039-10-30",
        "nepDate": "13.07.2096"
    },
    {
        "engDate": "2039-10-31",
        "nepDate": "14.07.2096"
    },
    {
        "engDate": "2039-11-01",
        "nepDate": "15.07.2096"
    },
    {
        "engDate": "2039-11-02",
        "nepDate": "16.07.2096"
    },
    {
        "engDate": "2039-11-03",
        "nepDate": "17.07.2096"
    },
    {
        "engDate": "2039-11-04",
        "nepDate": "18.07.2096"
    },
    {
        "engDate": "2039-11-05",
        "nepDate": "19.07.2096"
    },
    {
        "engDate": "2039-11-06",
        "nepDate": "20.07.2096"
    },
    {
        "engDate": "2039-11-07",
        "nepDate": "21.07.2096"
    },
    {
        "engDate": "2039-11-08",
        "nepDate": "22.07.2096"
    },
    {
        "engDate": "2039-11-09",
        "nepDate": "23.07.2096"
    },
    {
        "engDate": "2039-11-10",
        "nepDate": "24.07.2096"
    },
    {
        "engDate": "2039-11-11",
        "nepDate": "25.07.2096"
    },
    {
        "engDate": "2039-11-12",
        "nepDate": "26.07.2096"
    },
    {
        "engDate": "2039-11-13",
        "nepDate": "27.07.2096"
    },
    {
        "engDate": "2039-11-14",
        "nepDate": "28.07.2096"
    },
    {
        "engDate": "2039-11-15",
        "nepDate": "29.07.2096"
    },
    {
        "engDate": "2039-11-16",
        "nepDate": "30.07.2096"
    },
    {
        "engDate": "2039-11-17",
        "nepDate": "01.08.2096"
    },
    {
        "engDate": "2039-11-18",
        "nepDate": "02.08.2096"
    },
    {
        "engDate": "2039-11-19",
        "nepDate": "03.08.2096"
    },
    {
        "engDate": "2039-11-20",
        "nepDate": "04.08.2096"
    },
    {
        "engDate": "2039-11-21",
        "nepDate": "05.08.2096"
    },
    {
        "engDate": "2039-11-22",
        "nepDate": "06.08.2096"
    },
    {
        "engDate": "2039-11-23",
        "nepDate": "07.08.2096"
    },
    {
        "engDate": "2039-11-24",
        "nepDate": "08.08.2096"
    },
    {
        "engDate": "2039-11-25",
        "nepDate": "09.08.2096"
    },
    {
        "engDate": "2039-11-26",
        "nepDate": "10.08.2096"
    },
    {
        "engDate": "2039-11-27",
        "nepDate": "11.08.2096"
    },
    {
        "engDate": "2039-11-28",
        "nepDate": "12.08.2096"
    },
    {
        "engDate": "2039-11-29",
        "nepDate": "13.08.2096"
    },
    {
        "engDate": "2039-11-30",
        "nepDate": "14.08.2096"
    },
    {
        "engDate": "2039-12-01",
        "nepDate": "15.08.2096"
    },
    {
        "engDate": "2039-12-02",
        "nepDate": "16.08.2096"
    },
    {
        "engDate": "2039-12-03",
        "nepDate": "17.08.2096"
    },
    {
        "engDate": "2039-12-04",
        "nepDate": "18.08.2096"
    },
    {
        "engDate": "2039-12-05",
        "nepDate": "19.08.2096"
    },
    {
        "engDate": "2039-12-06",
        "nepDate": "20.08.2096"
    },
    {
        "engDate": "2039-12-07",
        "nepDate": "21.08.2096"
    },
    {
        "engDate": "2039-12-08",
        "nepDate": "22.08.2096"
    },
    {
        "engDate": "2039-12-09",
        "nepDate": "23.08.2096"
    },
    {
        "engDate": "2039-12-10",
        "nepDate": "24.08.2096"
    },
    {
        "engDate": "2039-12-11",
        "nepDate": "25.08.2096"
    },
    {
        "engDate": "2039-12-12",
        "nepDate": "26.08.2096"
    },
    {
        "engDate": "2039-12-13",
        "nepDate": "27.08.2096"
    },
    {
        "engDate": "2039-12-14",
        "nepDate": "28.08.2096"
    },
    {
        "engDate": "2039-12-15",
        "nepDate": "29.08.2096"
    },
    {
        "engDate": "2039-12-16",
        "nepDate": "01.09.2096"
    },
    {
        "engDate": "2039-12-17",
        "nepDate": "02.09.2096"
    },
    {
        "engDate": "2039-12-18",
        "nepDate": "03.09.2096"
    },
    {
        "engDate": "2039-12-19",
        "nepDate": "04.09.2096"
    },
    {
        "engDate": "2039-12-20",
        "nepDate": "05.09.2096"
    },
    {
        "engDate": "2039-12-21",
        "nepDate": "06.09.2096"
    },
    {
        "engDate": "2039-12-22",
        "nepDate": "07.09.2096"
    },
    {
        "engDate": "2039-12-23",
        "nepDate": "08.09.2096"
    },
    {
        "engDate": "2039-12-24",
        "nepDate": "09.09.2096"
    },
    {
        "engDate": "2039-12-25",
        "nepDate": "10.09.2096"
    },
    {
        "engDate": "2039-12-26",
        "nepDate": "11.09.2096"
    },
    {
        "engDate": "2039-12-27",
        "nepDate": "12.09.2096"
    },
    {
        "engDate": "2039-12-28",
        "nepDate": "13.09.2096"
    },
    {
        "engDate": "2039-12-29",
        "nepDate": "14.09.2096"
    },
    {
        "engDate": "2039-12-30",
        "nepDate": "15.09.2096"
    },
    {
        "engDate": "2039-12-31",
        "nepDate": "16.09.2096"
    },
    {
        "engDate": "2040-01-01",
        "nepDate": "17.09.2096"
    },
    {
        "engDate": "2040-01-02",
        "nepDate": "18.09.2096"
    },
    {
        "engDate": "2040-01-03",
        "nepDate": "19.09.2096"
    },
    {
        "engDate": "2040-01-04",
        "nepDate": "20.09.2096"
    },
    {
        "engDate": "2040-01-05",
        "nepDate": "21.09.2096"
    },
    {
        "engDate": "2040-01-06",
        "nepDate": "22.09.2096"
    },
    {
        "engDate": "2040-01-07",
        "nepDate": "23.09.2096"
    },
    {
        "engDate": "2040-01-08",
        "nepDate": "24.09.2096"
    },
    {
        "engDate": "2040-01-09",
        "nepDate": "25.09.2096"
    },
    {
        "engDate": "2040-01-10",
        "nepDate": "26.09.2096"
    },
    {
        "engDate": "2040-01-11",
        "nepDate": "27.09.2096"
    },
    {
        "engDate": "2040-01-12",
        "nepDate": "28.09.2096"
    },
    {
        "engDate": "2040-01-13",
        "nepDate": "29.09.2096"
    },
    {
        "engDate": "2040-01-14",
        "nepDate": "30.09.2096"
    },
    {
        "engDate": "2040-01-15",
        "nepDate": "01.10.2096"
    },
    {
        "engDate": "2040-01-16",
        "nepDate": "02.10.2096"
    },
    {
        "engDate": "2040-01-17",
        "nepDate": "03.10.2096"
    },
    {
        "engDate": "2040-01-18",
        "nepDate": "04.10.2096"
    },
    {
        "engDate": "2040-01-19",
        "nepDate": "05.10.2096"
    },
    {
        "engDate": "2040-01-20",
        "nepDate": "06.10.2096"
    },
    {
        "engDate": "2040-01-21",
        "nepDate": "07.10.2096"
    },
    {
        "engDate": "2040-01-22",
        "nepDate": "08.10.2096"
    },
    {
        "engDate": "2040-01-23",
        "nepDate": "09.10.2096"
    },
    {
        "engDate": "2040-01-24",
        "nepDate": "10.10.2096"
    },
    {
        "engDate": "2040-01-25",
        "nepDate": "11.10.2096"
    },
    {
        "engDate": "2040-01-26",
        "nepDate": "12.10.2096"
    },
    {
        "engDate": "2040-01-27",
        "nepDate": "13.10.2096"
    },
    {
        "engDate": "2040-01-28",
        "nepDate": "14.10.2096"
    },
    {
        "engDate": "2040-01-29",
        "nepDate": "15.10.2096"
    },
    {
        "engDate": "2040-01-30",
        "nepDate": "16.10.2096"
    },
    {
        "engDate": "2040-01-31",
        "nepDate": "17.10.2096"
    },
    {
        "engDate": "2040-02-01",
        "nepDate": "18.10.2096"
    },
    {
        "engDate": "2040-02-02",
        "nepDate": "19.10.2096"
    },
    {
        "engDate": "2040-02-03",
        "nepDate": "20.10.2096"
    },
    {
        "engDate": "2040-02-04",
        "nepDate": "21.10.2096"
    },
    {
        "engDate": "2040-02-05",
        "nepDate": "22.10.2096"
    },
    {
        "engDate": "2040-02-06",
        "nepDate": "23.10.2096"
    },
    {
        "engDate": "2040-02-07",
        "nepDate": "24.10.2096"
    },
    {
        "engDate": "2040-02-08",
        "nepDate": "25.10.2096"
    },
    {
        "engDate": "2040-02-09",
        "nepDate": "26.10.2096"
    },
    {
        "engDate": "2040-02-10",
        "nepDate": "27.10.2096"
    },
    {
        "engDate": "2040-02-11",
        "nepDate": "28.10.2096"
    },
    {
        "engDate": "2040-02-12",
        "nepDate": "29.10.2096"
    },
    {
        "engDate": "2040-02-13",
        "nepDate": "01.11.2096"
    },
    {
        "engDate": "2040-02-14",
        "nepDate": "02.11.2096"
    },
    {
        "engDate": "2040-02-15",
        "nepDate": "03.11.2096"
    },
    {
        "engDate": "2040-02-16",
        "nepDate": "04.11.2096"
    },
    {
        "engDate": "2040-02-17",
        "nepDate": "05.11.2096"
    },
    {
        "engDate": "2040-02-18",
        "nepDate": "06.11.2096"
    },
    {
        "engDate": "2040-02-19",
        "nepDate": "07.11.2096"
    },
    {
        "engDate": "2040-02-20",
        "nepDate": "08.11.2096"
    },
    {
        "engDate": "2040-02-21",
        "nepDate": "09.11.2096"
    },
    {
        "engDate": "2040-02-22",
        "nepDate": "10.11.2096"
    },
    {
        "engDate": "2040-02-23",
        "nepDate": "11.11.2096"
    },
    {
        "engDate": "2040-02-24",
        "nepDate": "12.11.2096"
    },
    {
        "engDate": "2040-02-25",
        "nepDate": "13.11.2096"
    },
    {
        "engDate": "2040-02-26",
        "nepDate": "14.11.2096"
    },
    {
        "engDate": "2040-02-27",
        "nepDate": "15.11.2096"
    },
    {
        "engDate": "2040-02-28",
        "nepDate": "16.11.2096"
    },
    {
        "engDate": "2040-02-29",
        "nepDate": "17.11.2096"
    },
    {
        "engDate": "2040-03-01",
        "nepDate": "18.11.2096"
    },
    {
        "engDate": "2040-03-02",
        "nepDate": "19.11.2096"
    },
    {
        "engDate": "2040-03-03",
        "nepDate": "20.11.2096"
    },
    {
        "engDate": "2040-03-04",
        "nepDate": "21.11.2096"
    },
    {
        "engDate": "2040-03-05",
        "nepDate": "22.11.2096"
    },
    {
        "engDate": "2040-03-06",
        "nepDate": "23.11.2096"
    },
    {
        "engDate": "2040-03-07",
        "nepDate": "24.11.2096"
    },
    {
        "engDate": "2040-03-08",
        "nepDate": "25.11.2096"
    },
    {
        "engDate": "2040-03-09",
        "nepDate": "26.11.2096"
    },
    {
        "engDate": "2040-03-10",
        "nepDate": "27.11.2096"
    },
    {
        "engDate": "2040-03-11",
        "nepDate": "28.11.2096"
    },
    {
        "engDate": "2040-03-12",
        "nepDate": "29.11.2096"
    },
    {
        "engDate": "2040-03-13",
        "nepDate": "30.11.2096"
    },
    {
        "engDate": "2040-03-14",
        "nepDate": "01.12.2096"
    },
    {
        "engDate": "2040-03-15",
        "nepDate": "02.12.2096"
    },
    {
        "engDate": "2040-03-16",
        "nepDate": "03.12.2096"
    },
    {
        "engDate": "2040-03-17",
        "nepDate": "04.12.2096"
    },
    {
        "engDate": "2040-03-18",
        "nepDate": "05.12.2096"
    },
    {
        "engDate": "2040-03-19",
        "nepDate": "06.12.2096"
    },
    {
        "engDate": "2040-03-20",
        "nepDate": "07.12.2096"
    },
    {
        "engDate": "2040-03-21",
        "nepDate": "08.12.2096"
    },
    {
        "engDate": "2040-03-22",
        "nepDate": "09.12.2096"
    },
    {
        "engDate": "2040-03-23",
        "nepDate": "10.12.2096"
    },
    {
        "engDate": "2040-03-24",
        "nepDate": "11.12.2096"
    },
    {
        "engDate": "2040-03-25",
        "nepDate": "12.12.2096"
    },
    {
        "engDate": "2040-03-26",
        "nepDate": "13.12.2096"
    },
    {
        "engDate": "2040-03-27",
        "nepDate": "14.12.2096"
    },
    {
        "engDate": "2040-03-28",
        "nepDate": "15.12.2096"
    },
    {
        "engDate": "2040-03-29",
        "nepDate": "16.12.2096"
    },
    {
        "engDate": "2040-03-30",
        "nepDate": "17.12.2096"
    },
    {
        "engDate": "2040-03-31",
        "nepDate": "18.12.2096"
    },
    {
        "engDate": "2040-04-01",
        "nepDate": "19.12.2096"
    },
    {
        "engDate": "2040-04-02",
        "nepDate": "20.12.2096"
    },
    {
        "engDate": "2040-04-03",
        "nepDate": "21.12.2096"
    },
    {
        "engDate": "2040-04-04",
        "nepDate": "22.12.2096"
    },
    {
        "engDate": "2040-04-05",
        "nepDate": "23.12.2096"
    },
    {
        "engDate": "2040-04-06",
        "nepDate": "24.12.2096"
    },
    {
        "engDate": "2040-04-07",
        "nepDate": "25.12.2096"
    },
    {
        "engDate": "2040-04-08",
        "nepDate": "26.12.2096"
    },
    {
        "engDate": "2040-04-09",
        "nepDate": "27.12.2096"
    },
    {
        "engDate": "2040-04-10",
        "nepDate": "28.12.2096"
    },
    {
        "engDate": "2040-04-11",
        "nepDate": "29.12.2096"
    },
    {
        "engDate": "2040-04-12",
        "nepDate": "30.12.2096"
    },
    {
        "engDate": "2040-04-13",
        "nepDate": "01.01.2097"
    },
    {
        "engDate": "2040-04-14",
        "nepDate": "02.01.2097"
    },
    {
        "engDate": "2040-04-15",
        "nepDate": "03.01.2097"
    },
    {
        "engDate": "2040-04-16",
        "nepDate": "04.01.2097"
    },
    {
        "engDate": "2040-04-17",
        "nepDate": "05.01.2097"
    },
    {
        "engDate": "2040-04-18",
        "nepDate": "06.01.2097"
    },
    {
        "engDate": "2040-04-19",
        "nepDate": "07.01.2097"
    },
    {
        "engDate": "2040-04-20",
        "nepDate": "08.01.2097"
    },
    {
        "engDate": "2040-04-21",
        "nepDate": "09.01.2097"
    },
    {
        "engDate": "2040-04-22",
        "nepDate": "10.01.2097"
    },
    {
        "engDate": "2040-04-23",
        "nepDate": "11.01.2097"
    },
    {
        "engDate": "2040-04-24",
        "nepDate": "12.01.2097"
    },
    {
        "engDate": "2040-04-25",
        "nepDate": "13.01.2097"
    },
    {
        "engDate": "2040-04-26",
        "nepDate": "14.01.2097"
    },
    {
        "engDate": "2040-04-27",
        "nepDate": "15.01.2097"
    },
    {
        "engDate": "2040-04-28",
        "nepDate": "16.01.2097"
    },
    {
        "engDate": "2040-04-29",
        "nepDate": "17.01.2097"
    },
    {
        "engDate": "2040-04-30",
        "nepDate": "18.01.2097"
    },
    {
        "engDate": "2040-05-01",
        "nepDate": "19.01.2097"
    },
    {
        "engDate": "2040-05-02",
        "nepDate": "20.01.2097"
    },
    {
        "engDate": "2040-05-03",
        "nepDate": "21.01.2097"
    },
    {
        "engDate": "2040-05-04",
        "nepDate": "22.01.2097"
    },
    {
        "engDate": "2040-05-05",
        "nepDate": "23.01.2097"
    },
    {
        "engDate": "2040-05-06",
        "nepDate": "24.01.2097"
    },
    {
        "engDate": "2040-05-07",
        "nepDate": "25.01.2097"
    },
    {
        "engDate": "2040-05-08",
        "nepDate": "26.01.2097"
    },
    {
        "engDate": "2040-05-09",
        "nepDate": "27.01.2097"
    },
    {
        "engDate": "2040-05-10",
        "nepDate": "28.01.2097"
    },
    {
        "engDate": "2040-05-11",
        "nepDate": "29.01.2097"
    },
    {
        "engDate": "2040-05-12",
        "nepDate": "30.01.2097"
    },
    {
        "engDate": "2040-05-13",
        "nepDate": "31.01.2097"
    },
    {
        "engDate": "2040-05-14",
        "nepDate": "01.02.2097"
    },
    {
        "engDate": "2040-05-15",
        "nepDate": "02.02.2097"
    },
    {
        "engDate": "2040-05-16",
        "nepDate": "03.02.2097"
    },
    {
        "engDate": "2040-05-17",
        "nepDate": "04.02.2097"
    },
    {
        "engDate": "2040-05-18",
        "nepDate": "05.02.2097"
    },
    {
        "engDate": "2040-05-19",
        "nepDate": "06.02.2097"
    },
    {
        "engDate": "2040-05-20",
        "nepDate": "07.02.2097"
    },
    {
        "engDate": "2040-05-21",
        "nepDate": "08.02.2097"
    },
    {
        "engDate": "2040-05-22",
        "nepDate": "09.02.2097"
    },
    {
        "engDate": "2040-05-23",
        "nepDate": "10.02.2097"
    },
    {
        "engDate": "2040-05-24",
        "nepDate": "11.02.2097"
    },
    {
        "engDate": "2040-05-25",
        "nepDate": "12.02.2097"
    },
    {
        "engDate": "2040-05-26",
        "nepDate": "13.02.2097"
    },
    {
        "engDate": "2040-05-27",
        "nepDate": "14.02.2097"
    },
    {
        "engDate": "2040-05-28",
        "nepDate": "15.02.2097"
    },
    {
        "engDate": "2040-05-29",
        "nepDate": "16.02.2097"
    },
    {
        "engDate": "2040-05-30",
        "nepDate": "17.02.2097"
    },
    {
        "engDate": "2040-05-31",
        "nepDate": "18.02.2097"
    },
    {
        "engDate": "2040-06-01",
        "nepDate": "19.02.2097"
    },
    {
        "engDate": "2040-06-02",
        "nepDate": "20.02.2097"
    },
    {
        "engDate": "2040-06-03",
        "nepDate": "21.02.2097"
    },
    {
        "engDate": "2040-06-04",
        "nepDate": "22.02.2097"
    },
    {
        "engDate": "2040-06-05",
        "nepDate": "23.02.2097"
    },
    {
        "engDate": "2040-06-06",
        "nepDate": "24.02.2097"
    },
    {
        "engDate": "2040-06-07",
        "nepDate": "25.02.2097"
    },
    {
        "engDate": "2040-06-08",
        "nepDate": "26.02.2097"
    },
    {
        "engDate": "2040-06-09",
        "nepDate": "27.02.2097"
    },
    {
        "engDate": "2040-06-10",
        "nepDate": "28.02.2097"
    },
    {
        "engDate": "2040-06-11",
        "nepDate": "29.02.2097"
    },
    {
        "engDate": "2040-06-12",
        "nepDate": "30.02.2097"
    },
    {
        "engDate": "2040-06-13",
        "nepDate": "31.02.2097"
    },
    {
        "engDate": "2040-06-14",
        "nepDate": "32.02.2097"
    },
    {
        "engDate": "2040-06-15",
        "nepDate": "01.03.2097"
    },
    {
        "engDate": "2040-06-16",
        "nepDate": "02.03.2097"
    },
    {
        "engDate": "2040-06-17",
        "nepDate": "03.03.2097"
    },
    {
        "engDate": "2040-06-18",
        "nepDate": "04.03.2097"
    },
    {
        "engDate": "2040-06-19",
        "nepDate": "05.03.2097"
    },
    {
        "engDate": "2040-06-20",
        "nepDate": "06.03.2097"
    },
    {
        "engDate": "2040-06-21",
        "nepDate": "07.03.2097"
    },
    {
        "engDate": "2040-06-22",
        "nepDate": "08.03.2097"
    },
    {
        "engDate": "2040-06-23",
        "nepDate": "09.03.2097"
    },
    {
        "engDate": "2040-06-24",
        "nepDate": "10.03.2097"
    },
    {
        "engDate": "2040-06-25",
        "nepDate": "11.03.2097"
    },
    {
        "engDate": "2040-06-26",
        "nepDate": "12.03.2097"
    },
    {
        "engDate": "2040-06-27",
        "nepDate": "13.03.2097"
    },
    {
        "engDate": "2040-06-28",
        "nepDate": "14.03.2097"
    },
    {
        "engDate": "2040-06-29",
        "nepDate": "15.03.2097"
    },
    {
        "engDate": "2040-06-30",
        "nepDate": "16.03.2097"
    },
    {
        "engDate": "2040-07-01",
        "nepDate": "17.03.2097"
    },
    {
        "engDate": "2040-07-02",
        "nepDate": "18.03.2097"
    },
    {
        "engDate": "2040-07-03",
        "nepDate": "19.03.2097"
    },
    {
        "engDate": "2040-07-04",
        "nepDate": "20.03.2097"
    },
    {
        "engDate": "2040-07-05",
        "nepDate": "21.03.2097"
    },
    {
        "engDate": "2040-07-06",
        "nepDate": "22.03.2097"
    },
    {
        "engDate": "2040-07-07",
        "nepDate": "23.03.2097"
    },
    {
        "engDate": "2040-07-08",
        "nepDate": "24.03.2097"
    },
    {
        "engDate": "2040-07-09",
        "nepDate": "25.03.2097"
    },
    {
        "engDate": "2040-07-10",
        "nepDate": "26.03.2097"
    },
    {
        "engDate": "2040-07-11",
        "nepDate": "27.03.2097"
    },
    {
        "engDate": "2040-07-12",
        "nepDate": "28.03.2097"
    },
    {
        "engDate": "2040-07-13",
        "nepDate": "29.03.2097"
    },
    {
        "engDate": "2040-07-14",
        "nepDate": "30.03.2097"
    },
    {
        "engDate": "2040-07-15",
        "nepDate": "31.03.2097"
    },
    {
        "engDate": "2040-07-16",
        "nepDate": "01.04.2097"
    },
    {
        "engDate": "2040-07-17",
        "nepDate": "02.04.2097"
    },
    {
        "engDate": "2040-07-18",
        "nepDate": "03.04.2097"
    },
    {
        "engDate": "2040-07-19",
        "nepDate": "04.04.2097"
    },
    {
        "engDate": "2040-07-20",
        "nepDate": "05.04.2097"
    },
    {
        "engDate": "2040-07-21",
        "nepDate": "06.04.2097"
    },
    {
        "engDate": "2040-07-22",
        "nepDate": "07.04.2097"
    },
    {
        "engDate": "2040-07-23",
        "nepDate": "08.04.2097"
    },
    {
        "engDate": "2040-07-24",
        "nepDate": "09.04.2097"
    },
    {
        "engDate": "2040-07-25",
        "nepDate": "10.04.2097"
    },
    {
        "engDate": "2040-07-26",
        "nepDate": "11.04.2097"
    },
    {
        "engDate": "2040-07-27",
        "nepDate": "12.04.2097"
    },
    {
        "engDate": "2040-07-28",
        "nepDate": "13.04.2097"
    },
    {
        "engDate": "2040-07-29",
        "nepDate": "14.04.2097"
    },
    {
        "engDate": "2040-07-30",
        "nepDate": "15.04.2097"
    },
    {
        "engDate": "2040-07-31",
        "nepDate": "16.04.2097"
    },
    {
        "engDate": "2040-08-01",
        "nepDate": "17.04.2097"
    },
    {
        "engDate": "2040-08-02",
        "nepDate": "18.04.2097"
    },
    {
        "engDate": "2040-08-03",
        "nepDate": "19.04.2097"
    },
    {
        "engDate": "2040-08-04",
        "nepDate": "20.04.2097"
    },
    {
        "engDate": "2040-08-05",
        "nepDate": "21.04.2097"
    },
    {
        "engDate": "2040-08-06",
        "nepDate": "22.04.2097"
    },
    {
        "engDate": "2040-08-07",
        "nepDate": "23.04.2097"
    },
    {
        "engDate": "2040-08-08",
        "nepDate": "24.04.2097"
    },
    {
        "engDate": "2040-08-09",
        "nepDate": "25.04.2097"
    },
    {
        "engDate": "2040-08-10",
        "nepDate": "26.04.2097"
    },
    {
        "engDate": "2040-08-11",
        "nepDate": "27.04.2097"
    },
    {
        "engDate": "2040-08-12",
        "nepDate": "28.04.2097"
    },
    {
        "engDate": "2040-08-13",
        "nepDate": "29.04.2097"
    },
    {
        "engDate": "2040-08-14",
        "nepDate": "30.04.2097"
    },
    {
        "engDate": "2040-08-15",
        "nepDate": "31.04.2097"
    },
    {
        "engDate": "2040-08-16",
        "nepDate": "32.04.2097"
    },
    {
        "engDate": "2040-08-17",
        "nepDate": "01.05.2097"
    },
    {
        "engDate": "2040-08-18",
        "nepDate": "02.05.2097"
    },
    {
        "engDate": "2040-08-19",
        "nepDate": "03.05.2097"
    },
    {
        "engDate": "2040-08-20",
        "nepDate": "04.05.2097"
    },
    {
        "engDate": "2040-08-21",
        "nepDate": "05.05.2097"
    },
    {
        "engDate": "2040-08-22",
        "nepDate": "06.05.2097"
    },
    {
        "engDate": "2040-08-23",
        "nepDate": "07.05.2097"
    },
    {
        "engDate": "2040-08-24",
        "nepDate": "08.05.2097"
    },
    {
        "engDate": "2040-08-25",
        "nepDate": "09.05.2097"
    },
    {
        "engDate": "2040-08-26",
        "nepDate": "10.05.2097"
    },
    {
        "engDate": "2040-08-27",
        "nepDate": "11.05.2097"
    },
    {
        "engDate": "2040-08-28",
        "nepDate": "12.05.2097"
    },
    {
        "engDate": "2040-08-29",
        "nepDate": "13.05.2097"
    },
    {
        "engDate": "2040-08-30",
        "nepDate": "14.05.2097"
    },
    {
        "engDate": "2040-08-31",
        "nepDate": "15.05.2097"
    },
    {
        "engDate": "2040-09-01",
        "nepDate": "16.05.2097"
    },
    {
        "engDate": "2040-09-02",
        "nepDate": "17.05.2097"
    },
    {
        "engDate": "2040-09-03",
        "nepDate": "18.05.2097"
    },
    {
        "engDate": "2040-09-04",
        "nepDate": "19.05.2097"
    },
    {
        "engDate": "2040-09-05",
        "nepDate": "20.05.2097"
    },
    {
        "engDate": "2040-09-06",
        "nepDate": "21.05.2097"
    },
    {
        "engDate": "2040-09-07",
        "nepDate": "22.05.2097"
    },
    {
        "engDate": "2040-09-08",
        "nepDate": "23.05.2097"
    },
    {
        "engDate": "2040-09-09",
        "nepDate": "24.05.2097"
    },
    {
        "engDate": "2040-09-10",
        "nepDate": "25.05.2097"
    },
    {
        "engDate": "2040-09-11",
        "nepDate": "26.05.2097"
    },
    {
        "engDate": "2040-09-12",
        "nepDate": "27.05.2097"
    },
    {
        "engDate": "2040-09-13",
        "nepDate": "28.05.2097"
    },
    {
        "engDate": "2040-09-14",
        "nepDate": "29.05.2097"
    },
    {
        "engDate": "2040-09-15",
        "nepDate": "30.05.2097"
    },
    {
        "engDate": "2040-09-16",
        "nepDate": "31.05.2097"
    },
    {
        "engDate": "2040-09-17",
        "nepDate": "01.06.2097"
    },
    {
        "engDate": "2040-09-18",
        "nepDate": "02.06.2097"
    },
    {
        "engDate": "2040-09-19",
        "nepDate": "03.06.2097"
    },
    {
        "engDate": "2040-09-20",
        "nepDate": "04.06.2097"
    },
    {
        "engDate": "2040-09-21",
        "nepDate": "05.06.2097"
    },
    {
        "engDate": "2040-09-22",
        "nepDate": "06.06.2097"
    },
    {
        "engDate": "2040-09-23",
        "nepDate": "07.06.2097"
    },
    {
        "engDate": "2040-09-24",
        "nepDate": "08.06.2097"
    },
    {
        "engDate": "2040-09-25",
        "nepDate": "09.06.2097"
    },
    {
        "engDate": "2040-09-26",
        "nepDate": "10.06.2097"
    },
    {
        "engDate": "2040-09-27",
        "nepDate": "11.06.2097"
    },
    {
        "engDate": "2040-09-28",
        "nepDate": "12.06.2097"
    },
    {
        "engDate": "2040-09-29",
        "nepDate": "13.06.2097"
    },
    {
        "engDate": "2040-09-30",
        "nepDate": "14.06.2097"
    },
    {
        "engDate": "2040-10-01",
        "nepDate": "15.06.2097"
    },
    {
        "engDate": "2040-10-02",
        "nepDate": "16.06.2097"
    },
    {
        "engDate": "2040-10-03",
        "nepDate": "17.06.2097"
    },
    {
        "engDate": "2040-10-04",
        "nepDate": "18.06.2097"
    },
    {
        "engDate": "2040-10-05",
        "nepDate": "19.06.2097"
    },
    {
        "engDate": "2040-10-06",
        "nepDate": "20.06.2097"
    },
    {
        "engDate": "2040-10-07",
        "nepDate": "21.06.2097"
    },
    {
        "engDate": "2040-10-08",
        "nepDate": "22.06.2097"
    },
    {
        "engDate": "2040-10-09",
        "nepDate": "23.06.2097"
    },
    {
        "engDate": "2040-10-10",
        "nepDate": "24.06.2097"
    },
    {
        "engDate": "2040-10-11",
        "nepDate": "25.06.2097"
    },
    {
        "engDate": "2040-10-12",
        "nepDate": "26.06.2097"
    },
    {
        "engDate": "2040-10-13",
        "nepDate": "27.06.2097"
    },
    {
        "engDate": "2040-10-14",
        "nepDate": "28.06.2097"
    },
    {
        "engDate": "2040-10-15",
        "nepDate": "29.06.2097"
    },
    {
        "engDate": "2040-10-16",
        "nepDate": "30.06.2097"
    },
    {
        "engDate": "2040-10-17",
        "nepDate": "01.07.2097"
    },
    {
        "engDate": "2040-10-18",
        "nepDate": "02.07.2097"
    },
    {
        "engDate": "2040-10-19",
        "nepDate": "03.07.2097"
    },
    {
        "engDate": "2040-10-20",
        "nepDate": "04.07.2097"
    },
    {
        "engDate": "2040-10-21",
        "nepDate": "05.07.2097"
    },
    {
        "engDate": "2040-10-22",
        "nepDate": "06.07.2097"
    },
    {
        "engDate": "2040-10-23",
        "nepDate": "07.07.2097"
    },
    {
        "engDate": "2040-10-24",
        "nepDate": "08.07.2097"
    },
    {
        "engDate": "2040-10-25",
        "nepDate": "09.07.2097"
    },
    {
        "engDate": "2040-10-26",
        "nepDate": "10.07.2097"
    },
    {
        "engDate": "2040-10-27",
        "nepDate": "11.07.2097"
    },
    {
        "engDate": "2040-10-28",
        "nepDate": "12.07.2097"
    },
    {
        "engDate": "2040-10-29",
        "nepDate": "13.07.2097"
    },
    {
        "engDate": "2040-10-30",
        "nepDate": "14.07.2097"
    },
    {
        "engDate": "2040-10-31",
        "nepDate": "15.07.2097"
    },
    {
        "engDate": "2040-11-01",
        "nepDate": "16.07.2097"
    },
    {
        "engDate": "2040-11-02",
        "nepDate": "17.07.2097"
    },
    {
        "engDate": "2040-11-03",
        "nepDate": "18.07.2097"
    },
    {
        "engDate": "2040-11-04",
        "nepDate": "19.07.2097"
    },
    {
        "engDate": "2040-11-05",
        "nepDate": "20.07.2097"
    },
    {
        "engDate": "2040-11-06",
        "nepDate": "21.07.2097"
    },
    {
        "engDate": "2040-11-07",
        "nepDate": "22.07.2097"
    },
    {
        "engDate": "2040-11-08",
        "nepDate": "23.07.2097"
    },
    {
        "engDate": "2040-11-09",
        "nepDate": "24.07.2097"
    },
    {
        "engDate": "2040-11-10",
        "nepDate": "25.07.2097"
    },
    {
        "engDate": "2040-11-11",
        "nepDate": "26.07.2097"
    },
    {
        "engDate": "2040-11-12",
        "nepDate": "27.07.2097"
    },
    {
        "engDate": "2040-11-13",
        "nepDate": "28.07.2097"
    },
    {
        "engDate": "2040-11-14",
        "nepDate": "29.07.2097"
    },
    {
        "engDate": "2040-11-15",
        "nepDate": "30.07.2097"
    },
    {
        "engDate": "2040-11-16",
        "nepDate": "01.08.2097"
    },
    {
        "engDate": "2040-11-17",
        "nepDate": "02.08.2097"
    },
    {
        "engDate": "2040-11-18",
        "nepDate": "03.08.2097"
    },
    {
        "engDate": "2040-11-19",
        "nepDate": "04.08.2097"
    },
    {
        "engDate": "2040-11-20",
        "nepDate": "05.08.2097"
    },
    {
        "engDate": "2040-11-21",
        "nepDate": "06.08.2097"
    },
    {
        "engDate": "2040-11-22",
        "nepDate": "07.08.2097"
    },
    {
        "engDate": "2040-11-23",
        "nepDate": "08.08.2097"
    },
    {
        "engDate": "2040-11-24",
        "nepDate": "09.08.2097"
    },
    {
        "engDate": "2040-11-25",
        "nepDate": "10.08.2097"
    },
    {
        "engDate": "2040-11-26",
        "nepDate": "11.08.2097"
    },
    {
        "engDate": "2040-11-27",
        "nepDate": "12.08.2097"
    },
    {
        "engDate": "2040-11-28",
        "nepDate": "13.08.2097"
    },
    {
        "engDate": "2040-11-29",
        "nepDate": "14.08.2097"
    },
    {
        "engDate": "2040-11-30",
        "nepDate": "15.08.2097"
    },
    {
        "engDate": "2040-12-01",
        "nepDate": "16.08.2097"
    },
    {
        "engDate": "2040-12-02",
        "nepDate": "17.08.2097"
    },
    {
        "engDate": "2040-12-03",
        "nepDate": "18.08.2097"
    },
    {
        "engDate": "2040-12-04",
        "nepDate": "19.08.2097"
    },
    {
        "engDate": "2040-12-05",
        "nepDate": "20.08.2097"
    },
    {
        "engDate": "2040-12-06",
        "nepDate": "21.08.2097"
    },
    {
        "engDate": "2040-12-07",
        "nepDate": "22.08.2097"
    },
    {
        "engDate": "2040-12-08",
        "nepDate": "23.08.2097"
    },
    {
        "engDate": "2040-12-09",
        "nepDate": "24.08.2097"
    },
    {
        "engDate": "2040-12-10",
        "nepDate": "25.08.2097"
    },
    {
        "engDate": "2040-12-11",
        "nepDate": "26.08.2097"
    },
    {
        "engDate": "2040-12-12",
        "nepDate": "27.08.2097"
    },
    {
        "engDate": "2040-12-13",
        "nepDate": "28.08.2097"
    },
    {
        "engDate": "2040-12-14",
        "nepDate": "29.08.2097"
    },
    {
        "engDate": "2040-12-15",
        "nepDate": "30.08.2097"
    },
    {
        "engDate": "2040-12-16",
        "nepDate": "01.09.2097"
    },
    {
        "engDate": "2040-12-17",
        "nepDate": "02.09.2097"
    },
    {
        "engDate": "2040-12-18",
        "nepDate": "03.09.2097"
    },
    {
        "engDate": "2040-12-19",
        "nepDate": "04.09.2097"
    },
    {
        "engDate": "2040-12-20",
        "nepDate": "05.09.2097"
    },
    {
        "engDate": "2040-12-21",
        "nepDate": "06.09.2097"
    },
    {
        "engDate": "2040-12-22",
        "nepDate": "07.09.2097"
    },
    {
        "engDate": "2040-12-23",
        "nepDate": "08.09.2097"
    },
    {
        "engDate": "2040-12-24",
        "nepDate": "09.09.2097"
    },
    {
        "engDate": "2040-12-25",
        "nepDate": "10.09.2097"
    },
    {
        "engDate": "2040-12-26",
        "nepDate": "11.09.2097"
    },
    {
        "engDate": "2040-12-27",
        "nepDate": "12.09.2097"
    },
    {
        "engDate": "2040-12-28",
        "nepDate": "13.09.2097"
    },
    {
        "engDate": "2040-12-29",
        "nepDate": "14.09.2097"
    },
    {
        "engDate": "2040-12-30",
        "nepDate": "15.09.2097"
    },
    {
        "engDate": "2040-12-31",
        "nepDate": "16.09.2097"
    },
    {
        "engDate": "2041-01-01",
        "nepDate": "17.09.2097"
    },
    {
        "engDate": "2041-01-02",
        "nepDate": "18.09.2097"
    },
    {
        "engDate": "2041-01-03",
        "nepDate": "19.09.2097"
    },
    {
        "engDate": "2041-01-04",
        "nepDate": "20.09.2097"
    },
    {
        "engDate": "2041-01-05",
        "nepDate": "21.09.2097"
    },
    {
        "engDate": "2041-01-06",
        "nepDate": "22.09.2097"
    },
    {
        "engDate": "2041-01-07",
        "nepDate": "23.09.2097"
    },
    {
        "engDate": "2041-01-08",
        "nepDate": "24.09.2097"
    },
    {
        "engDate": "2041-01-09",
        "nepDate": "25.09.2097"
    },
    {
        "engDate": "2041-01-10",
        "nepDate": "26.09.2097"
    },
    {
        "engDate": "2041-01-11",
        "nepDate": "27.09.2097"
    },
    {
        "engDate": "2041-01-12",
        "nepDate": "28.09.2097"
    },
    {
        "engDate": "2041-01-13",
        "nepDate": "29.09.2097"
    },
    {
        "engDate": "2041-01-14",
        "nepDate": "01.10.2097"
    },
    {
        "engDate": "2041-01-15",
        "nepDate": "02.10.2097"
    },
    {
        "engDate": "2041-01-16",
        "nepDate": "03.10.2097"
    },
    {
        "engDate": "2041-01-17",
        "nepDate": "04.10.2097"
    },
    {
        "engDate": "2041-01-18",
        "nepDate": "05.10.2097"
    },
    {
        "engDate": "2041-01-19",
        "nepDate": "06.10.2097"
    },
    {
        "engDate": "2041-01-20",
        "nepDate": "07.10.2097"
    },
    {
        "engDate": "2041-01-21",
        "nepDate": "08.10.2097"
    },
    {
        "engDate": "2041-01-22",
        "nepDate": "09.10.2097"
    },
    {
        "engDate": "2041-01-23",
        "nepDate": "10.10.2097"
    },
    {
        "engDate": "2041-01-24",
        "nepDate": "11.10.2097"
    },
    {
        "engDate": "2041-01-25",
        "nepDate": "12.10.2097"
    },
    {
        "engDate": "2041-01-26",
        "nepDate": "13.10.2097"
    },
    {
        "engDate": "2041-01-27",
        "nepDate": "14.10.2097"
    },
    {
        "engDate": "2041-01-28",
        "nepDate": "15.10.2097"
    },
    {
        "engDate": "2041-01-29",
        "nepDate": "16.10.2097"
    },
    {
        "engDate": "2041-01-30",
        "nepDate": "17.10.2097"
    },
    {
        "engDate": "2041-01-31",
        "nepDate": "18.10.2097"
    },
    {
        "engDate": "2041-02-01",
        "nepDate": "19.10.2097"
    },
    {
        "engDate": "2041-02-02",
        "nepDate": "20.10.2097"
    },
    {
        "engDate": "2041-02-03",
        "nepDate": "21.10.2097"
    },
    {
        "engDate": "2041-02-04",
        "nepDate": "22.10.2097"
    },
    {
        "engDate": "2041-02-05",
        "nepDate": "23.10.2097"
    },
    {
        "engDate": "2041-02-06",
        "nepDate": "24.10.2097"
    },
    {
        "engDate": "2041-02-07",
        "nepDate": "25.10.2097"
    },
    {
        "engDate": "2041-02-08",
        "nepDate": "26.10.2097"
    },
    {
        "engDate": "2041-02-09",
        "nepDate": "27.10.2097"
    },
    {
        "engDate": "2041-02-10",
        "nepDate": "28.10.2097"
    },
    {
        "engDate": "2041-02-11",
        "nepDate": "29.10.2097"
    },
    {
        "engDate": "2041-02-12",
        "nepDate": "30.10.2097"
    },
    {
        "engDate": "2041-02-13",
        "nepDate": "01.11.2097"
    },
    {
        "engDate": "2041-02-14",
        "nepDate": "02.11.2097"
    },
    {
        "engDate": "2041-02-15",
        "nepDate": "03.11.2097"
    },
    {
        "engDate": "2041-02-16",
        "nepDate": "04.11.2097"
    },
    {
        "engDate": "2041-02-17",
        "nepDate": "05.11.2097"
    },
    {
        "engDate": "2041-02-18",
        "nepDate": "06.11.2097"
    },
    {
        "engDate": "2041-02-19",
        "nepDate": "07.11.2097"
    },
    {
        "engDate": "2041-02-20",
        "nepDate": "08.11.2097"
    },
    {
        "engDate": "2041-02-21",
        "nepDate": "09.11.2097"
    },
    {
        "engDate": "2041-02-22",
        "nepDate": "10.11.2097"
    },
    {
        "engDate": "2041-02-23",
        "nepDate": "11.11.2097"
    },
    {
        "engDate": "2041-02-24",
        "nepDate": "12.11.2097"
    },
    {
        "engDate": "2041-02-25",
        "nepDate": "13.11.2097"
    },
    {
        "engDate": "2041-02-26",
        "nepDate": "14.11.2097"
    },
    {
        "engDate": "2041-02-27",
        "nepDate": "15.11.2097"
    },
    {
        "engDate": "2041-02-28",
        "nepDate": "16.11.2097"
    },
    {
        "engDate": "2041-03-01",
        "nepDate": "17.11.2097"
    },
    {
        "engDate": "2041-03-02",
        "nepDate": "18.11.2097"
    },
    {
        "engDate": "2041-03-03",
        "nepDate": "19.11.2097"
    },
    {
        "engDate": "2041-03-04",
        "nepDate": "20.11.2097"
    },
    {
        "engDate": "2041-03-05",
        "nepDate": "21.11.2097"
    },
    {
        "engDate": "2041-03-06",
        "nepDate": "22.11.2097"
    },
    {
        "engDate": "2041-03-07",
        "nepDate": "23.11.2097"
    },
    {
        "engDate": "2041-03-08",
        "nepDate": "24.11.2097"
    },
    {
        "engDate": "2041-03-09",
        "nepDate": "25.11.2097"
    },
    {
        "engDate": "2041-03-10",
        "nepDate": "26.11.2097"
    },
    {
        "engDate": "2041-03-11",
        "nepDate": "27.11.2097"
    },
    {
        "engDate": "2041-03-12",
        "nepDate": "28.11.2097"
    },
    {
        "engDate": "2041-03-13",
        "nepDate": "29.11.2097"
    },
    {
        "engDate": "2041-03-14",
        "nepDate": "30.11.2097"
    },
    {
        "engDate": "2041-03-15",
        "nepDate": "01.12.2097"
    },
    {
        "engDate": "2041-03-16",
        "nepDate": "02.12.2097"
    },
    {
        "engDate": "2041-03-17",
        "nepDate": "03.12.2097"
    },
    {
        "engDate": "2041-03-18",
        "nepDate": "04.12.2097"
    },
    {
        "engDate": "2041-03-19",
        "nepDate": "05.12.2097"
    },
    {
        "engDate": "2041-03-20",
        "nepDate": "06.12.2097"
    },
    {
        "engDate": "2041-03-21",
        "nepDate": "07.12.2097"
    },
    {
        "engDate": "2041-03-22",
        "nepDate": "08.12.2097"
    },
    {
        "engDate": "2041-03-23",
        "nepDate": "09.12.2097"
    },
    {
        "engDate": "2041-03-24",
        "nepDate": "10.12.2097"
    },
    {
        "engDate": "2041-03-25",
        "nepDate": "11.12.2097"
    },
    {
        "engDate": "2041-03-26",
        "nepDate": "12.12.2097"
    },
    {
        "engDate": "2041-03-27",
        "nepDate": "13.12.2097"
    },
    {
        "engDate": "2041-03-28",
        "nepDate": "14.12.2097"
    },
    {
        "engDate": "2041-03-29",
        "nepDate": "15.12.2097"
    },
    {
        "engDate": "2041-03-30",
        "nepDate": "16.12.2097"
    },
    {
        "engDate": "2041-03-31",
        "nepDate": "17.12.2097"
    },
    {
        "engDate": "2041-04-01",
        "nepDate": "18.12.2097"
    },
    {
        "engDate": "2041-04-02",
        "nepDate": "19.12.2097"
    },
    {
        "engDate": "2041-04-03",
        "nepDate": "20.12.2097"
    },
    {
        "engDate": "2041-04-04",
        "nepDate": "21.12.2097"
    },
    {
        "engDate": "2041-04-05",
        "nepDate": "22.12.2097"
    },
    {
        "engDate": "2041-04-06",
        "nepDate": "23.12.2097"
    },
    {
        "engDate": "2041-04-07",
        "nepDate": "24.12.2097"
    },
    {
        "engDate": "2041-04-08",
        "nepDate": "25.12.2097"
    },
    {
        "engDate": "2041-04-09",
        "nepDate": "26.12.2097"
    },
    {
        "engDate": "2041-04-10",
        "nepDate": "27.12.2097"
    },
    {
        "engDate": "2041-04-11",
        "nepDate": "28.12.2097"
    },
    {
        "engDate": "2041-04-12",
        "nepDate": "29.12.2097"
    },
    {
        "engDate": "2041-04-13",
        "nepDate": "30.12.2097"
    },
    {
        "engDate": "2041-04-14",
        "nepDate": "01.01.2098"
    },
    {
        "engDate": "2041-04-15",
        "nepDate": "02.01.2098"
    },
    {
        "engDate": "2041-04-16",
        "nepDate": "03.01.2098"
    },
    {
        "engDate": "2041-04-17",
        "nepDate": "04.01.2098"
    },
    {
        "engDate": "2041-04-18",
        "nepDate": "05.01.2098"
    },
    {
        "engDate": "2041-04-19",
        "nepDate": "06.01.2098"
    },
    {
        "engDate": "2041-04-20",
        "nepDate": "07.01.2098"
    },
    {
        "engDate": "2041-04-21",
        "nepDate": "08.01.2098"
    },
    {
        "engDate": "2041-04-22",
        "nepDate": "09.01.2098"
    },
    {
        "engDate": "2041-04-23",
        "nepDate": "10.01.2098"
    },
    {
        "engDate": "2041-04-24",
        "nepDate": "11.01.2098"
    },
    {
        "engDate": "2041-04-25",
        "nepDate": "12.01.2098"
    },
    {
        "engDate": "2041-04-26",
        "nepDate": "13.01.2098"
    },
    {
        "engDate": "2041-04-27",
        "nepDate": "14.01.2098"
    },
    {
        "engDate": "2041-04-28",
        "nepDate": "15.01.2098"
    },
    {
        "engDate": "2041-04-29",
        "nepDate": "16.01.2098"
    },
    {
        "engDate": "2041-04-30",
        "nepDate": "17.01.2098"
    },
    {
        "engDate": "2041-05-01",
        "nepDate": "18.01.2098"
    },
    {
        "engDate": "2041-05-02",
        "nepDate": "19.01.2098"
    },
    {
        "engDate": "2041-05-03",
        "nepDate": "20.01.2098"
    },
    {
        "engDate": "2041-05-04",
        "nepDate": "21.01.2098"
    },
    {
        "engDate": "2041-05-05",
        "nepDate": "22.01.2098"
    },
    {
        "engDate": "2041-05-06",
        "nepDate": "23.01.2098"
    },
    {
        "engDate": "2041-05-07",
        "nepDate": "24.01.2098"
    },
    {
        "engDate": "2041-05-08",
        "nepDate": "25.01.2098"
    },
    {
        "engDate": "2041-05-09",
        "nepDate": "26.01.2098"
    },
    {
        "engDate": "2041-05-10",
        "nepDate": "27.01.2098"
    },
    {
        "engDate": "2041-05-11",
        "nepDate": "28.01.2098"
    },
    {
        "engDate": "2041-05-12",
        "nepDate": "29.01.2098"
    },
    {
        "engDate": "2041-05-13",
        "nepDate": "30.01.2098"
    },
    {
        "engDate": "2041-05-14",
        "nepDate": "31.01.2098"
    },
    {
        "engDate": "2041-05-15",
        "nepDate": "01.02.2098"
    },
    {
        "engDate": "2041-05-16",
        "nepDate": "02.02.2098"
    },
    {
        "engDate": "2041-05-17",
        "nepDate": "03.02.2098"
    },
    {
        "engDate": "2041-05-18",
        "nepDate": "04.02.2098"
    },
    {
        "engDate": "2041-05-19",
        "nepDate": "05.02.2098"
    },
    {
        "engDate": "2041-05-20",
        "nepDate": "06.02.2098"
    },
    {
        "engDate": "2041-05-21",
        "nepDate": "07.02.2098"
    },
    {
        "engDate": "2041-05-22",
        "nepDate": "08.02.2098"
    },
    {
        "engDate": "2041-05-23",
        "nepDate": "09.02.2098"
    },
    {
        "engDate": "2041-05-24",
        "nepDate": "10.02.2098"
    },
    {
        "engDate": "2041-05-25",
        "nepDate": "11.02.2098"
    },
    {
        "engDate": "2041-05-26",
        "nepDate": "12.02.2098"
    },
    {
        "engDate": "2041-05-27",
        "nepDate": "13.02.2098"
    },
    {
        "engDate": "2041-05-28",
        "nepDate": "14.02.2098"
    },
    {
        "engDate": "2041-05-29",
        "nepDate": "15.02.2098"
    },
    {
        "engDate": "2041-05-30",
        "nepDate": "16.02.2098"
    },
    {
        "engDate": "2041-05-31",
        "nepDate": "17.02.2098"
    },
    {
        "engDate": "2041-06-01",
        "nepDate": "18.02.2098"
    },
    {
        "engDate": "2041-06-02",
        "nepDate": "19.02.2098"
    },
    {
        "engDate": "2041-06-03",
        "nepDate": "20.02.2098"
    },
    {
        "engDate": "2041-06-04",
        "nepDate": "21.02.2098"
    },
    {
        "engDate": "2041-06-05",
        "nepDate": "22.02.2098"
    },
    {
        "engDate": "2041-06-06",
        "nepDate": "23.02.2098"
    },
    {
        "engDate": "2041-06-07",
        "nepDate": "24.02.2098"
    },
    {
        "engDate": "2041-06-08",
        "nepDate": "25.02.2098"
    },
    {
        "engDate": "2041-06-09",
        "nepDate": "26.02.2098"
    },
    {
        "engDate": "2041-06-10",
        "nepDate": "27.02.2098"
    },
    {
        "engDate": "2041-06-11",
        "nepDate": "28.02.2098"
    },
    {
        "engDate": "2041-06-12",
        "nepDate": "29.02.2098"
    },
    {
        "engDate": "2041-06-13",
        "nepDate": "30.02.2098"
    },
    {
        "engDate": "2041-06-14",
        "nepDate": "31.02.2098"
    },
    {
        "engDate": "2041-06-15",
        "nepDate": "01.03.2098"
    },
    {
        "engDate": "2041-06-16",
        "nepDate": "02.03.2098"
    },
    {
        "engDate": "2041-06-17",
        "nepDate": "03.03.2098"
    },
    {
        "engDate": "2041-06-18",
        "nepDate": "04.03.2098"
    },
    {
        "engDate": "2041-06-19",
        "nepDate": "05.03.2098"
    },
    {
        "engDate": "2041-06-20",
        "nepDate": "06.03.2098"
    },
    {
        "engDate": "2041-06-21",
        "nepDate": "07.03.2098"
    },
    {
        "engDate": "2041-06-22",
        "nepDate": "08.03.2098"
    },
    {
        "engDate": "2041-06-23",
        "nepDate": "09.03.2098"
    },
    {
        "engDate": "2041-06-24",
        "nepDate": "10.03.2098"
    },
    {
        "engDate": "2041-06-25",
        "nepDate": "11.03.2098"
    },
    {
        "engDate": "2041-06-26",
        "nepDate": "12.03.2098"
    },
    {
        "engDate": "2041-06-27",
        "nepDate": "13.03.2098"
    },
    {
        "engDate": "2041-06-28",
        "nepDate": "14.03.2098"
    },
    {
        "engDate": "2041-06-29",
        "nepDate": "15.03.2098"
    },
    {
        "engDate": "2041-06-30",
        "nepDate": "16.03.2098"
    },
    {
        "engDate": "2041-07-01",
        "nepDate": "17.03.2098"
    },
    {
        "engDate": "2041-07-02",
        "nepDate": "18.03.2098"
    },
    {
        "engDate": "2041-07-03",
        "nepDate": "19.03.2098"
    },
    {
        "engDate": "2041-07-04",
        "nepDate": "20.03.2098"
    },
    {
        "engDate": "2041-07-05",
        "nepDate": "21.03.2098"
    },
    {
        "engDate": "2041-07-06",
        "nepDate": "22.03.2098"
    },
    {
        "engDate": "2041-07-07",
        "nepDate": "23.03.2098"
    },
    {
        "engDate": "2041-07-08",
        "nepDate": "24.03.2098"
    },
    {
        "engDate": "2041-07-09",
        "nepDate": "25.03.2098"
    },
    {
        "engDate": "2041-07-10",
        "nepDate": "26.03.2098"
    },
    {
        "engDate": "2041-07-11",
        "nepDate": "27.03.2098"
    },
    {
        "engDate": "2041-07-12",
        "nepDate": "28.03.2098"
    },
    {
        "engDate": "2041-07-13",
        "nepDate": "29.03.2098"
    },
    {
        "engDate": "2041-07-14",
        "nepDate": "30.03.2098"
    },
    {
        "engDate": "2041-07-15",
        "nepDate": "31.03.2098"
    },
    {
        "engDate": "2041-07-16",
        "nepDate": "32.03.2098"
    },
    {
        "engDate": "2041-07-17",
        "nepDate": "01.04.2098"
    },
    {
        "engDate": "2041-07-18",
        "nepDate": "02.04.2098"
    },
    {
        "engDate": "2041-07-19",
        "nepDate": "03.04.2098"
    },
    {
        "engDate": "2041-07-20",
        "nepDate": "04.04.2098"
    },
    {
        "engDate": "2041-07-21",
        "nepDate": "05.04.2098"
    },
    {
        "engDate": "2041-07-22",
        "nepDate": "06.04.2098"
    },
    {
        "engDate": "2041-07-23",
        "nepDate": "07.04.2098"
    },
    {
        "engDate": "2041-07-24",
        "nepDate": "08.04.2098"
    },
    {
        "engDate": "2041-07-25",
        "nepDate": "09.04.2098"
    },
    {
        "engDate": "2041-07-26",
        "nepDate": "10.04.2098"
    },
    {
        "engDate": "2041-07-27",
        "nepDate": "11.04.2098"
    },
    {
        "engDate": "2041-07-28",
        "nepDate": "12.04.2098"
    },
    {
        "engDate": "2041-07-29",
        "nepDate": "13.04.2098"
    },
    {
        "engDate": "2041-07-30",
        "nepDate": "14.04.2098"
    },
    {
        "engDate": "2041-07-31",
        "nepDate": "15.04.2098"
    },
    {
        "engDate": "2041-08-01",
        "nepDate": "16.04.2098"
    },
    {
        "engDate": "2041-08-02",
        "nepDate": "17.04.2098"
    },
    {
        "engDate": "2041-08-03",
        "nepDate": "18.04.2098"
    },
    {
        "engDate": "2041-08-04",
        "nepDate": "19.04.2098"
    },
    {
        "engDate": "2041-08-05",
        "nepDate": "20.04.2098"
    },
    {
        "engDate": "2041-08-06",
        "nepDate": "21.04.2098"
    },
    {
        "engDate": "2041-08-07",
        "nepDate": "22.04.2098"
    },
    {
        "engDate": "2041-08-08",
        "nepDate": "23.04.2098"
    },
    {
        "engDate": "2041-08-09",
        "nepDate": "24.04.2098"
    },
    {
        "engDate": "2041-08-10",
        "nepDate": "25.04.2098"
    },
    {
        "engDate": "2041-08-11",
        "nepDate": "26.04.2098"
    },
    {
        "engDate": "2041-08-12",
        "nepDate": "27.04.2098"
    },
    {
        "engDate": "2041-08-13",
        "nepDate": "28.04.2098"
    },
    {
        "engDate": "2041-08-14",
        "nepDate": "29.04.2098"
    },
    {
        "engDate": "2041-08-15",
        "nepDate": "30.04.2098"
    },
    {
        "engDate": "2041-08-16",
        "nepDate": "31.04.2098"
    },
    {
        "engDate": "2041-08-17",
        "nepDate": "01.05.2098"
    },
    {
        "engDate": "2041-08-18",
        "nepDate": "02.05.2098"
    },
    {
        "engDate": "2041-08-19",
        "nepDate": "03.05.2098"
    },
    {
        "engDate": "2041-08-20",
        "nepDate": "04.05.2098"
    },
    {
        "engDate": "2041-08-21",
        "nepDate": "05.05.2098"
    },
    {
        "engDate": "2041-08-22",
        "nepDate": "06.05.2098"
    },
    {
        "engDate": "2041-08-23",
        "nepDate": "07.05.2098"
    },
    {
        "engDate": "2041-08-24",
        "nepDate": "08.05.2098"
    },
    {
        "engDate": "2041-08-25",
        "nepDate": "09.05.2098"
    },
    {
        "engDate": "2041-08-26",
        "nepDate": "10.05.2098"
    },
    {
        "engDate": "2041-08-27",
        "nepDate": "11.05.2098"
    },
    {
        "engDate": "2041-08-28",
        "nepDate": "12.05.2098"
    },
    {
        "engDate": "2041-08-29",
        "nepDate": "13.05.2098"
    },
    {
        "engDate": "2041-08-30",
        "nepDate": "14.05.2098"
    },
    {
        "engDate": "2041-08-31",
        "nepDate": "15.05.2098"
    },
    {
        "engDate": "2041-09-01",
        "nepDate": "16.05.2098"
    },
    {
        "engDate": "2041-09-02",
        "nepDate": "17.05.2098"
    },
    {
        "engDate": "2041-09-03",
        "nepDate": "18.05.2098"
    },
    {
        "engDate": "2041-09-04",
        "nepDate": "19.05.2098"
    },
    {
        "engDate": "2041-09-05",
        "nepDate": "20.05.2098"
    },
    {
        "engDate": "2041-09-06",
        "nepDate": "21.05.2098"
    },
    {
        "engDate": "2041-09-07",
        "nepDate": "22.05.2098"
    },
    {
        "engDate": "2041-09-08",
        "nepDate": "23.05.2098"
    },
    {
        "engDate": "2041-09-09",
        "nepDate": "24.05.2098"
    },
    {
        "engDate": "2041-09-10",
        "nepDate": "25.05.2098"
    },
    {
        "engDate": "2041-09-11",
        "nepDate": "26.05.2098"
    },
    {
        "engDate": "2041-09-12",
        "nepDate": "27.05.2098"
    },
    {
        "engDate": "2041-09-13",
        "nepDate": "28.05.2098"
    },
    {
        "engDate": "2041-09-14",
        "nepDate": "29.05.2098"
    },
    {
        "engDate": "2041-09-15",
        "nepDate": "30.05.2098"
    },
    {
        "engDate": "2041-09-16",
        "nepDate": "31.05.2098"
    },
    {
        "engDate": "2041-09-17",
        "nepDate": "01.06.2098"
    },
    {
        "engDate": "2041-09-18",
        "nepDate": "02.06.2098"
    },
    {
        "engDate": "2041-09-19",
        "nepDate": "03.06.2098"
    },
    {
        "engDate": "2041-09-20",
        "nepDate": "04.06.2098"
    },
    {
        "engDate": "2041-09-21",
        "nepDate": "05.06.2098"
    },
    {
        "engDate": "2041-09-22",
        "nepDate": "06.06.2098"
    },
    {
        "engDate": "2041-09-23",
        "nepDate": "07.06.2098"
    },
    {
        "engDate": "2041-09-24",
        "nepDate": "08.06.2098"
    },
    {
        "engDate": "2041-09-25",
        "nepDate": "09.06.2098"
    },
    {
        "engDate": "2041-09-26",
        "nepDate": "10.06.2098"
    },
    {
        "engDate": "2041-09-27",
        "nepDate": "11.06.2098"
    },
    {
        "engDate": "2041-09-28",
        "nepDate": "12.06.2098"
    },
    {
        "engDate": "2041-09-29",
        "nepDate": "13.06.2098"
    },
    {
        "engDate": "2041-09-30",
        "nepDate": "14.06.2098"
    },
    {
        "engDate": "2041-10-01",
        "nepDate": "15.06.2098"
    },
    {
        "engDate": "2041-10-02",
        "nepDate": "16.06.2098"
    },
    {
        "engDate": "2041-10-03",
        "nepDate": "17.06.2098"
    },
    {
        "engDate": "2041-10-04",
        "nepDate": "18.06.2098"
    },
    {
        "engDate": "2041-10-05",
        "nepDate": "19.06.2098"
    },
    {
        "engDate": "2041-10-06",
        "nepDate": "20.06.2098"
    },
    {
        "engDate": "2041-10-07",
        "nepDate": "21.06.2098"
    },
    {
        "engDate": "2041-10-08",
        "nepDate": "22.06.2098"
    },
    {
        "engDate": "2041-10-09",
        "nepDate": "23.06.2098"
    },
    {
        "engDate": "2041-10-10",
        "nepDate": "24.06.2098"
    },
    {
        "engDate": "2041-10-11",
        "nepDate": "25.06.2098"
    },
    {
        "engDate": "2041-10-12",
        "nepDate": "26.06.2098"
    },
    {
        "engDate": "2041-10-13",
        "nepDate": "27.06.2098"
    },
    {
        "engDate": "2041-10-14",
        "nepDate": "28.06.2098"
    },
    {
        "engDate": "2041-10-15",
        "nepDate": "29.06.2098"
    },
    {
        "engDate": "2041-10-16",
        "nepDate": "30.06.2098"
    },
    {
        "engDate": "2041-10-17",
        "nepDate": "31.06.2098"
    },
    {
        "engDate": "2041-10-18",
        "nepDate": "01.07.2098"
    },
    {
        "engDate": "2041-10-19",
        "nepDate": "02.07.2098"
    },
    {
        "engDate": "2041-10-20",
        "nepDate": "03.07.2098"
    },
    {
        "engDate": "2041-10-21",
        "nepDate": "04.07.2098"
    },
    {
        "engDate": "2041-10-22",
        "nepDate": "05.07.2098"
    },
    {
        "engDate": "2041-10-23",
        "nepDate": "06.07.2098"
    },
    {
        "engDate": "2041-10-24",
        "nepDate": "07.07.2098"
    },
    {
        "engDate": "2041-10-25",
        "nepDate": "08.07.2098"
    },
    {
        "engDate": "2041-10-26",
        "nepDate": "09.07.2098"
    },
    {
        "engDate": "2041-10-27",
        "nepDate": "10.07.2098"
    },
    {
        "engDate": "2041-10-28",
        "nepDate": "11.07.2098"
    },
    {
        "engDate": "2041-10-29",
        "nepDate": "12.07.2098"
    },
    {
        "engDate": "2041-10-30",
        "nepDate": "13.07.2098"
    },
    {
        "engDate": "2041-10-31",
        "nepDate": "14.07.2098"
    },
    {
        "engDate": "2041-11-01",
        "nepDate": "15.07.2098"
    },
    {
        "engDate": "2041-11-02",
        "nepDate": "16.07.2098"
    },
    {
        "engDate": "2041-11-03",
        "nepDate": "17.07.2098"
    },
    {
        "engDate": "2041-11-04",
        "nepDate": "18.07.2098"
    },
    {
        "engDate": "2041-11-05",
        "nepDate": "19.07.2098"
    },
    {
        "engDate": "2041-11-06",
        "nepDate": "20.07.2098"
    },
    {
        "engDate": "2041-11-07",
        "nepDate": "21.07.2098"
    },
    {
        "engDate": "2041-11-08",
        "nepDate": "22.07.2098"
    },
    {
        "engDate": "2041-11-09",
        "nepDate": "23.07.2098"
    },
    {
        "engDate": "2041-11-10",
        "nepDate": "24.07.2098"
    },
    {
        "engDate": "2041-11-11",
        "nepDate": "25.07.2098"
    },
    {
        "engDate": "2041-11-12",
        "nepDate": "26.07.2098"
    },
    {
        "engDate": "2041-11-13",
        "nepDate": "27.07.2098"
    },
    {
        "engDate": "2041-11-14",
        "nepDate": "28.07.2098"
    },
    {
        "engDate": "2041-11-15",
        "nepDate": "29.07.2098"
    },
    {
        "engDate": "2041-11-16",
        "nepDate": "01.08.2098"
    },
    {
        "engDate": "2041-11-17",
        "nepDate": "02.08.2098"
    },
    {
        "engDate": "2041-11-18",
        "nepDate": "03.08.2098"
    },
    {
        "engDate": "2041-11-19",
        "nepDate": "04.08.2098"
    },
    {
        "engDate": "2041-11-20",
        "nepDate": "05.08.2098"
    },
    {
        "engDate": "2041-11-21",
        "nepDate": "06.08.2098"
    },
    {
        "engDate": "2041-11-22",
        "nepDate": "07.08.2098"
    },
    {
        "engDate": "2041-11-23",
        "nepDate": "08.08.2098"
    },
    {
        "engDate": "2041-11-24",
        "nepDate": "09.08.2098"
    },
    {
        "engDate": "2041-11-25",
        "nepDate": "10.08.2098"
    },
    {
        "engDate": "2041-11-26",
        "nepDate": "11.08.2098"
    },
    {
        "engDate": "2041-11-27",
        "nepDate": "12.08.2098"
    },
    {
        "engDate": "2041-11-28",
        "nepDate": "13.08.2098"
    },
    {
        "engDate": "2041-11-29",
        "nepDate": "14.08.2098"
    },
    {
        "engDate": "2041-11-30",
        "nepDate": "15.08.2098"
    },
    {
        "engDate": "2041-12-01",
        "nepDate": "16.08.2098"
    },
    {
        "engDate": "2041-12-02",
        "nepDate": "17.08.2098"
    },
    {
        "engDate": "2041-12-03",
        "nepDate": "18.08.2098"
    },
    {
        "engDate": "2041-12-04",
        "nepDate": "19.08.2098"
    },
    {
        "engDate": "2041-12-05",
        "nepDate": "20.08.2098"
    },
    {
        "engDate": "2041-12-06",
        "nepDate": "21.08.2098"
    },
    {
        "engDate": "2041-12-07",
        "nepDate": "22.08.2098"
    },
    {
        "engDate": "2041-12-08",
        "nepDate": "23.08.2098"
    },
    {
        "engDate": "2041-12-09",
        "nepDate": "24.08.2098"
    },
    {
        "engDate": "2041-12-10",
        "nepDate": "25.08.2098"
    },
    {
        "engDate": "2041-12-11",
        "nepDate": "26.08.2098"
    },
    {
        "engDate": "2041-12-12",
        "nepDate": "27.08.2098"
    },
    {
        "engDate": "2041-12-13",
        "nepDate": "28.08.2098"
    },
    {
        "engDate": "2041-12-14",
        "nepDate": "29.08.2098"
    },
    {
        "engDate": "2041-12-15",
        "nepDate": "30.08.2098"
    },
    {
        "engDate": "2041-12-16",
        "nepDate": "01.09.2098"
    },
    {
        "engDate": "2041-12-17",
        "nepDate": "02.09.2098"
    },
    {
        "engDate": "2041-12-18",
        "nepDate": "03.09.2098"
    },
    {
        "engDate": "2041-12-19",
        "nepDate": "04.09.2098"
    },
    {
        "engDate": "2041-12-20",
        "nepDate": "05.09.2098"
    },
    {
        "engDate": "2041-12-21",
        "nepDate": "06.09.2098"
    },
    {
        "engDate": "2041-12-22",
        "nepDate": "07.09.2098"
    },
    {
        "engDate": "2041-12-23",
        "nepDate": "08.09.2098"
    },
    {
        "engDate": "2041-12-24",
        "nepDate": "09.09.2098"
    },
    {
        "engDate": "2041-12-25",
        "nepDate": "10.09.2098"
    },
    {
        "engDate": "2041-12-26",
        "nepDate": "11.09.2098"
    },
    {
        "engDate": "2041-12-27",
        "nepDate": "12.09.2098"
    },
    {
        "engDate": "2041-12-28",
        "nepDate": "13.09.2098"
    },
    {
        "engDate": "2041-12-29",
        "nepDate": "14.09.2098"
    },
    {
        "engDate": "2041-12-30",
        "nepDate": "15.09.2098"
    },
    {
        "engDate": "2041-12-31",
        "nepDate": "16.09.2098"
    },
    {
        "engDate": "2042-01-01",
        "nepDate": "17.09.2098"
    },
    {
        "engDate": "2042-01-02",
        "nepDate": "18.09.2098"
    },
    {
        "engDate": "2042-01-03",
        "nepDate": "19.09.2098"
    },
    {
        "engDate": "2042-01-04",
        "nepDate": "20.09.2098"
    },
    {
        "engDate": "2042-01-05",
        "nepDate": "21.09.2098"
    },
    {
        "engDate": "2042-01-06",
        "nepDate": "22.09.2098"
    },
    {
        "engDate": "2042-01-07",
        "nepDate": "23.09.2098"
    },
    {
        "engDate": "2042-01-08",
        "nepDate": "24.09.2098"
    },
    {
        "engDate": "2042-01-09",
        "nepDate": "25.09.2098"
    },
    {
        "engDate": "2042-01-10",
        "nepDate": "26.09.2098"
    },
    {
        "engDate": "2042-01-11",
        "nepDate": "27.09.2098"
    },
    {
        "engDate": "2042-01-12",
        "nepDate": "28.09.2098"
    },
    {
        "engDate": "2042-01-13",
        "nepDate": "29.09.2098"
    },
    {
        "engDate": "2042-01-14",
        "nepDate": "01.10.2098"
    },
    {
        "engDate": "2042-01-15",
        "nepDate": "02.10.2098"
    },
    {
        "engDate": "2042-01-16",
        "nepDate": "03.10.2098"
    },
    {
        "engDate": "2042-01-17",
        "nepDate": "04.10.2098"
    },
    {
        "engDate": "2042-01-18",
        "nepDate": "05.10.2098"
    },
    {
        "engDate": "2042-01-19",
        "nepDate": "06.10.2098"
    },
    {
        "engDate": "2042-01-20",
        "nepDate": "07.10.2098"
    },
    {
        "engDate": "2042-01-21",
        "nepDate": "08.10.2098"
    },
    {
        "engDate": "2042-01-22",
        "nepDate": "09.10.2098"
    },
    {
        "engDate": "2042-01-23",
        "nepDate": "10.10.2098"
    },
    {
        "engDate": "2042-01-24",
        "nepDate": "11.10.2098"
    },
    {
        "engDate": "2042-01-25",
        "nepDate": "12.10.2098"
    },
    {
        "engDate": "2042-01-26",
        "nepDate": "13.10.2098"
    },
    {
        "engDate": "2042-01-27",
        "nepDate": "14.10.2098"
    },
    {
        "engDate": "2042-01-28",
        "nepDate": "15.10.2098"
    },
    {
        "engDate": "2042-01-29",
        "nepDate": "16.10.2098"
    },
    {
        "engDate": "2042-01-30",
        "nepDate": "17.10.2098"
    },
    {
        "engDate": "2042-01-31",
        "nepDate": "18.10.2098"
    },
    {
        "engDate": "2042-02-01",
        "nepDate": "19.10.2098"
    },
    {
        "engDate": "2042-02-02",
        "nepDate": "20.10.2098"
    },
    {
        "engDate": "2042-02-03",
        "nepDate": "21.10.2098"
    },
    {
        "engDate": "2042-02-04",
        "nepDate": "22.10.2098"
    },
    {
        "engDate": "2042-02-05",
        "nepDate": "23.10.2098"
    },
    {
        "engDate": "2042-02-06",
        "nepDate": "24.10.2098"
    },
    {
        "engDate": "2042-02-07",
        "nepDate": "25.10.2098"
    },
    {
        "engDate": "2042-02-08",
        "nepDate": "26.10.2098"
    },
    {
        "engDate": "2042-02-09",
        "nepDate": "27.10.2098"
    },
    {
        "engDate": "2042-02-10",
        "nepDate": "28.10.2098"
    },
    {
        "engDate": "2042-02-11",
        "nepDate": "29.10.2098"
    },
    {
        "engDate": "2042-02-12",
        "nepDate": "30.10.2098"
    },
    {
        "engDate": "2042-02-13",
        "nepDate": "01.11.2098"
    },
    {
        "engDate": "2042-02-14",
        "nepDate": "02.11.2098"
    },
    {
        "engDate": "2042-02-15",
        "nepDate": "03.11.2098"
    },
    {
        "engDate": "2042-02-16",
        "nepDate": "04.11.2098"
    },
    {
        "engDate": "2042-02-17",
        "nepDate": "05.11.2098"
    },
    {
        "engDate": "2042-02-18",
        "nepDate": "06.11.2098"
    },
    {
        "engDate": "2042-02-19",
        "nepDate": "07.11.2098"
    },
    {
        "engDate": "2042-02-20",
        "nepDate": "08.11.2098"
    },
    {
        "engDate": "2042-02-21",
        "nepDate": "09.11.2098"
    },
    {
        "engDate": "2042-02-22",
        "nepDate": "10.11.2098"
    },
    {
        "engDate": "2042-02-23",
        "nepDate": "11.11.2098"
    },
    {
        "engDate": "2042-02-24",
        "nepDate": "12.11.2098"
    },
    {
        "engDate": "2042-02-25",
        "nepDate": "13.11.2098"
    },
    {
        "engDate": "2042-02-26",
        "nepDate": "14.11.2098"
    },
    {
        "engDate": "2042-02-27",
        "nepDate": "15.11.2098"
    },
    {
        "engDate": "2042-02-28",
        "nepDate": "16.11.2098"
    },
    {
        "engDate": "2042-03-01",
        "nepDate": "17.11.2098"
    },
    {
        "engDate": "2042-03-02",
        "nepDate": "18.11.2098"
    },
    {
        "engDate": "2042-03-03",
        "nepDate": "19.11.2098"
    },
    {
        "engDate": "2042-03-04",
        "nepDate": "20.11.2098"
    },
    {
        "engDate": "2042-03-05",
        "nepDate": "21.11.2098"
    },
    {
        "engDate": "2042-03-06",
        "nepDate": "22.11.2098"
    },
    {
        "engDate": "2042-03-07",
        "nepDate": "23.11.2098"
    },
    {
        "engDate": "2042-03-08",
        "nepDate": "24.11.2098"
    },
    {
        "engDate": "2042-03-09",
        "nepDate": "25.11.2098"
    },
    {
        "engDate": "2042-03-10",
        "nepDate": "26.11.2098"
    },
    {
        "engDate": "2042-03-11",
        "nepDate": "27.11.2098"
    },
    {
        "engDate": "2042-03-12",
        "nepDate": "28.11.2098"
    },
    {
        "engDate": "2042-03-13",
        "nepDate": "29.11.2098"
    },
    {
        "engDate": "2042-03-14",
        "nepDate": "01.12.2098"
    },
    {
        "engDate": "2042-03-15",
        "nepDate": "02.12.2098"
    },
    {
        "engDate": "2042-03-16",
        "nepDate": "03.12.2098"
    },
    {
        "engDate": "2042-03-17",
        "nepDate": "04.12.2098"
    },
    {
        "engDate": "2042-03-18",
        "nepDate": "05.12.2098"
    },
    {
        "engDate": "2042-03-19",
        "nepDate": "06.12.2098"
    },
    {
        "engDate": "2042-03-20",
        "nepDate": "07.12.2098"
    },
    {
        "engDate": "2042-03-21",
        "nepDate": "08.12.2098"
    },
    {
        "engDate": "2042-03-22",
        "nepDate": "09.12.2098"
    },
    {
        "engDate": "2042-03-23",
        "nepDate": "10.12.2098"
    },
    {
        "engDate": "2042-03-24",
        "nepDate": "11.12.2098"
    },
    {
        "engDate": "2042-03-25",
        "nepDate": "12.12.2098"
    },
    {
        "engDate": "2042-03-26",
        "nepDate": "13.12.2098"
    },
    {
        "engDate": "2042-03-27",
        "nepDate": "14.12.2098"
    },
    {
        "engDate": "2042-03-28",
        "nepDate": "15.12.2098"
    },
    {
        "engDate": "2042-03-29",
        "nepDate": "16.12.2098"
    },
    {
        "engDate": "2042-03-30",
        "nepDate": "17.12.2098"
    },
    {
        "engDate": "2042-03-31",
        "nepDate": "18.12.2098"
    },
    {
        "engDate": "2042-04-01",
        "nepDate": "19.12.2098"
    },
    {
        "engDate": "2042-04-02",
        "nepDate": "20.12.2098"
    },
    {
        "engDate": "2042-04-03",
        "nepDate": "21.12.2098"
    },
    {
        "engDate": "2042-04-04",
        "nepDate": "22.12.2098"
    },
    {
        "engDate": "2042-04-05",
        "nepDate": "23.12.2098"
    },
    {
        "engDate": "2042-04-06",
        "nepDate": "24.12.2098"
    },
    {
        "engDate": "2042-04-07",
        "nepDate": "25.12.2098"
    },
    {
        "engDate": "2042-04-08",
        "nepDate": "26.12.2098"
    },
    {
        "engDate": "2042-04-09",
        "nepDate": "27.12.2098"
    },
    {
        "engDate": "2042-04-10",
        "nepDate": "28.12.2098"
    },
    {
        "engDate": "2042-04-11",
        "nepDate": "29.12.2098"
    },
    {
        "engDate": "2042-04-12",
        "nepDate": "30.12.2098"
    },
    {
        "engDate": "2042-04-13",
        "nepDate": "31.12.2098"
    },
    {
        "engDate": "2042-04-14",
        "nepDate": "01.01.2099"
    },
    {
        "engDate": "2042-04-15",
        "nepDate": "02.01.2099"
    },
    {
        "engDate": "2042-04-16",
        "nepDate": "03.01.2099"
    },
    {
        "engDate": "2042-04-17",
        "nepDate": "04.01.2099"
    },
    {
        "engDate": "2042-04-18",
        "nepDate": "05.01.2099"
    },
    {
        "engDate": "2042-04-19",
        "nepDate": "06.01.2099"
    },
    {
        "engDate": "2042-04-20",
        "nepDate": "07.01.2099"
    },
    {
        "engDate": "2042-04-21",
        "nepDate": "08.01.2099"
    },
    {
        "engDate": "2042-04-22",
        "nepDate": "09.01.2099"
    },
    {
        "engDate": "2042-04-23",
        "nepDate": "10.01.2099"
    },
    {
        "engDate": "2042-04-24",
        "nepDate": "11.01.2099"
    },
    {
        "engDate": "2042-04-25",
        "nepDate": "12.01.2099"
    },
    {
        "engDate": "2042-04-26",
        "nepDate": "13.01.2099"
    },
    {
        "engDate": "2042-04-27",
        "nepDate": "14.01.2099"
    },
    {
        "engDate": "2042-04-28",
        "nepDate": "15.01.2099"
    },
    {
        "engDate": "2042-04-29",
        "nepDate": "16.01.2099"
    },
    {
        "engDate": "2042-04-30",
        "nepDate": "17.01.2099"
    },
    {
        "engDate": "2042-05-01",
        "nepDate": "18.01.2099"
    },
    {
        "engDate": "2042-05-02",
        "nepDate": "19.01.2099"
    },
    {
        "engDate": "2042-05-03",
        "nepDate": "20.01.2099"
    },
    {
        "engDate": "2042-05-04",
        "nepDate": "21.01.2099"
    },
    {
        "engDate": "2042-05-05",
        "nepDate": "22.01.2099"
    },
    {
        "engDate": "2042-05-06",
        "nepDate": "23.01.2099"
    },
    {
        "engDate": "2042-05-07",
        "nepDate": "24.01.2099"
    },
    {
        "engDate": "2042-05-08",
        "nepDate": "25.01.2099"
    },
    {
        "engDate": "2042-05-09",
        "nepDate": "26.01.2099"
    },
    {
        "engDate": "2042-05-10",
        "nepDate": "27.01.2099"
    },
    {
        "engDate": "2042-05-11",
        "nepDate": "28.01.2099"
    },
    {
        "engDate": "2042-05-12",
        "nepDate": "29.01.2099"
    },
    {
        "engDate": "2042-05-13",
        "nepDate": "30.01.2099"
    },
    {
        "engDate": "2042-05-14",
        "nepDate": "31.01.2099"
    },
    {
        "engDate": "2042-05-15",
        "nepDate": "01.02.2099"
    },
    {
        "engDate": "2042-05-16",
        "nepDate": "02.02.2099"
    },
    {
        "engDate": "2042-05-17",
        "nepDate": "03.02.2099"
    },
    {
        "engDate": "2042-05-18",
        "nepDate": "04.02.2099"
    },
    {
        "engDate": "2042-05-19",
        "nepDate": "05.02.2099"
    },
    {
        "engDate": "2042-05-20",
        "nepDate": "06.02.2099"
    },
    {
        "engDate": "2042-05-21",
        "nepDate": "07.02.2099"
    },
    {
        "engDate": "2042-05-22",
        "nepDate": "08.02.2099"
    },
    {
        "engDate": "2042-05-23",
        "nepDate": "09.02.2099"
    },
    {
        "engDate": "2042-05-24",
        "nepDate": "10.02.2099"
    },
    {
        "engDate": "2042-05-25",
        "nepDate": "11.02.2099"
    },
    {
        "engDate": "2042-05-26",
        "nepDate": "12.02.2099"
    },
    {
        "engDate": "2042-05-27",
        "nepDate": "13.02.2099"
    },
    {
        "engDate": "2042-05-28",
        "nepDate": "14.02.2099"
    },
    {
        "engDate": "2042-05-29",
        "nepDate": "15.02.2099"
    },
    {
        "engDate": "2042-05-30",
        "nepDate": "16.02.2099"
    },
    {
        "engDate": "2042-05-31",
        "nepDate": "17.02.2099"
    },
    {
        "engDate": "2042-06-01",
        "nepDate": "18.02.2099"
    },
    {
        "engDate": "2042-06-02",
        "nepDate": "19.02.2099"
    },
    {
        "engDate": "2042-06-03",
        "nepDate": "20.02.2099"
    },
    {
        "engDate": "2042-06-04",
        "nepDate": "21.02.2099"
    },
    {
        "engDate": "2042-06-05",
        "nepDate": "22.02.2099"
    },
    {
        "engDate": "2042-06-06",
        "nepDate": "23.02.2099"
    },
    {
        "engDate": "2042-06-07",
        "nepDate": "24.02.2099"
    },
    {
        "engDate": "2042-06-08",
        "nepDate": "25.02.2099"
    },
    {
        "engDate": "2042-06-09",
        "nepDate": "26.02.2099"
    },
    {
        "engDate": "2042-06-10",
        "nepDate": "27.02.2099"
    },
    {
        "engDate": "2042-06-11",
        "nepDate": "28.02.2099"
    },
    {
        "engDate": "2042-06-12",
        "nepDate": "29.02.2099"
    },
    {
        "engDate": "2042-06-13",
        "nepDate": "30.02.2099"
    },
    {
        "engDate": "2042-06-14",
        "nepDate": "31.02.2099"
    },
    {
        "engDate": "2042-06-15",
        "nepDate": "01.03.2099"
    },
    {
        "engDate": "2042-06-16",
        "nepDate": "02.03.2099"
    },
    {
        "engDate": "2042-06-17",
        "nepDate": "03.03.2099"
    },
    {
        "engDate": "2042-06-18",
        "nepDate": "04.03.2099"
    },
    {
        "engDate": "2042-06-19",
        "nepDate": "05.03.2099"
    },
    {
        "engDate": "2042-06-20",
        "nepDate": "06.03.2099"
    },
    {
        "engDate": "2042-06-21",
        "nepDate": "07.03.2099"
    },
    {
        "engDate": "2042-06-22",
        "nepDate": "08.03.2099"
    },
    {
        "engDate": "2042-06-23",
        "nepDate": "09.03.2099"
    },
    {
        "engDate": "2042-06-24",
        "nepDate": "10.03.2099"
    },
    {
        "engDate": "2042-06-25",
        "nepDate": "11.03.2099"
    },
    {
        "engDate": "2042-06-26",
        "nepDate": "12.03.2099"
    },
    {
        "engDate": "2042-06-27",
        "nepDate": "13.03.2099"
    },
    {
        "engDate": "2042-06-28",
        "nepDate": "14.03.2099"
    },
    {
        "engDate": "2042-06-29",
        "nepDate": "15.03.2099"
    },
    {
        "engDate": "2042-06-30",
        "nepDate": "16.03.2099"
    },
    {
        "engDate": "2042-07-01",
        "nepDate": "17.03.2099"
    },
    {
        "engDate": "2042-07-02",
        "nepDate": "18.03.2099"
    },
    {
        "engDate": "2042-07-03",
        "nepDate": "19.03.2099"
    },
    {
        "engDate": "2042-07-04",
        "nepDate": "20.03.2099"
    },
    {
        "engDate": "2042-07-05",
        "nepDate": "21.03.2099"
    },
    {
        "engDate": "2042-07-06",
        "nepDate": "22.03.2099"
    },
    {
        "engDate": "2042-07-07",
        "nepDate": "23.03.2099"
    },
    {
        "engDate": "2042-07-08",
        "nepDate": "24.03.2099"
    },
    {
        "engDate": "2042-07-09",
        "nepDate": "25.03.2099"
    },
    {
        "engDate": "2042-07-10",
        "nepDate": "26.03.2099"
    },
    {
        "engDate": "2042-07-11",
        "nepDate": "27.03.2099"
    },
    {
        "engDate": "2042-07-12",
        "nepDate": "28.03.2099"
    },
    {
        "engDate": "2042-07-13",
        "nepDate": "29.03.2099"
    },
    {
        "engDate": "2042-07-14",
        "nepDate": "30.03.2099"
    },
    {
        "engDate": "2042-07-15",
        "nepDate": "31.03.2099"
    },
    {
        "engDate": "2042-07-16",
        "nepDate": "32.03.2099"
    },
    {
        "engDate": "2042-07-17",
        "nepDate": "01.04.2099"
    },
    {
        "engDate": "2042-07-18",
        "nepDate": "02.04.2099"
    },
    {
        "engDate": "2042-07-19",
        "nepDate": "03.04.2099"
    },
    {
        "engDate": "2042-07-20",
        "nepDate": "04.04.2099"
    },
    {
        "engDate": "2042-07-21",
        "nepDate": "05.04.2099"
    },
    {
        "engDate": "2042-07-22",
        "nepDate": "06.04.2099"
    },
    {
        "engDate": "2042-07-23",
        "nepDate": "07.04.2099"
    },
    {
        "engDate": "2042-07-24",
        "nepDate": "08.04.2099"
    },
    {
        "engDate": "2042-07-25",
        "nepDate": "09.04.2099"
    },
    {
        "engDate": "2042-07-26",
        "nepDate": "10.04.2099"
    },
    {
        "engDate": "2042-07-27",
        "nepDate": "11.04.2099"
    },
    {
        "engDate": "2042-07-28",
        "nepDate": "12.04.2099"
    },
    {
        "engDate": "2042-07-29",
        "nepDate": "13.04.2099"
    },
    {
        "engDate": "2042-07-30",
        "nepDate": "14.04.2099"
    },
    {
        "engDate": "2042-07-31",
        "nepDate": "15.04.2099"
    },
    {
        "engDate": "2042-08-01",
        "nepDate": "16.04.2099"
    },
    {
        "engDate": "2042-08-02",
        "nepDate": "17.04.2099"
    },
    {
        "engDate": "2042-08-03",
        "nepDate": "18.04.2099"
    },
    {
        "engDate": "2042-08-04",
        "nepDate": "19.04.2099"
    },
    {
        "engDate": "2042-08-05",
        "nepDate": "20.04.2099"
    },
    {
        "engDate": "2042-08-06",
        "nepDate": "21.04.2099"
    },
    {
        "engDate": "2042-08-07",
        "nepDate": "22.04.2099"
    },
    {
        "engDate": "2042-08-08",
        "nepDate": "23.04.2099"
    },
    {
        "engDate": "2042-08-09",
        "nepDate": "24.04.2099"
    },
    {
        "engDate": "2042-08-10",
        "nepDate": "25.04.2099"
    },
    {
        "engDate": "2042-08-11",
        "nepDate": "26.04.2099"
    },
    {
        "engDate": "2042-08-12",
        "nepDate": "27.04.2099"
    },
    {
        "engDate": "2042-08-13",
        "nepDate": "28.04.2099"
    },
    {
        "engDate": "2042-08-14",
        "nepDate": "29.04.2099"
    },
    {
        "engDate": "2042-08-15",
        "nepDate": "30.04.2099"
    },
    {
        "engDate": "2042-08-16",
        "nepDate": "31.04.2099"
    },
    {
        "engDate": "2042-08-17",
        "nepDate": "01.05.2099"
    },
    {
        "engDate": "2042-08-18",
        "nepDate": "02.05.2099"
    },
    {
        "engDate": "2042-08-19",
        "nepDate": "03.05.2099"
    },
    {
        "engDate": "2042-08-20",
        "nepDate": "04.05.2099"
    },
    {
        "engDate": "2042-08-21",
        "nepDate": "05.05.2099"
    },
    {
        "engDate": "2042-08-22",
        "nepDate": "06.05.2099"
    },
    {
        "engDate": "2042-08-23",
        "nepDate": "07.05.2099"
    },
    {
        "engDate": "2042-08-24",
        "nepDate": "08.05.2099"
    },
    {
        "engDate": "2042-08-25",
        "nepDate": "09.05.2099"
    },
    {
        "engDate": "2042-08-26",
        "nepDate": "10.05.2099"
    },
    {
        "engDate": "2042-08-27",
        "nepDate": "11.05.2099"
    },
    {
        "engDate": "2042-08-28",
        "nepDate": "12.05.2099"
    },
    {
        "engDate": "2042-08-29",
        "nepDate": "13.05.2099"
    },
    {
        "engDate": "2042-08-30",
        "nepDate": "14.05.2099"
    },
    {
        "engDate": "2042-08-31",
        "nepDate": "15.05.2099"
    },
    {
        "engDate": "2042-09-01",
        "nepDate": "16.05.2099"
    },
    {
        "engDate": "2042-09-02",
        "nepDate": "17.05.2099"
    },
    {
        "engDate": "2042-09-03",
        "nepDate": "18.05.2099"
    },
    {
        "engDate": "2042-09-04",
        "nepDate": "19.05.2099"
    },
    {
        "engDate": "2042-09-05",
        "nepDate": "20.05.2099"
    },
    {
        "engDate": "2042-09-06",
        "nepDate": "21.05.2099"
    },
    {
        "engDate": "2042-09-07",
        "nepDate": "22.05.2099"
    },
    {
        "engDate": "2042-09-08",
        "nepDate": "23.05.2099"
    },
    {
        "engDate": "2042-09-09",
        "nepDate": "24.05.2099"
    },
    {
        "engDate": "2042-09-10",
        "nepDate": "25.05.2099"
    },
    {
        "engDate": "2042-09-11",
        "nepDate": "26.05.2099"
    },
    {
        "engDate": "2042-09-12",
        "nepDate": "27.05.2099"
    },
    {
        "engDate": "2042-09-13",
        "nepDate": "28.05.2099"
    },
    {
        "engDate": "2042-09-14",
        "nepDate": "29.05.2099"
    },
    {
        "engDate": "2042-09-15",
        "nepDate": "30.05.2099"
    },
    {
        "engDate": "2042-09-16",
        "nepDate": "31.05.2099"
    },
    {
        "engDate": "2042-09-17",
        "nepDate": "01.06.2099"
    },
    {
        "engDate": "2042-09-18",
        "nepDate": "02.06.2099"
    },
    {
        "engDate": "2042-09-19",
        "nepDate": "03.06.2099"
    },
    {
        "engDate": "2042-09-20",
        "nepDate": "04.06.2099"
    },
    {
        "engDate": "2042-09-21",
        "nepDate": "05.06.2099"
    },
    {
        "engDate": "2042-09-22",
        "nepDate": "06.06.2099"
    },
    {
        "engDate": "2042-09-23",
        "nepDate": "07.06.2099"
    },
    {
        "engDate": "2042-09-24",
        "nepDate": "08.06.2099"
    },
    {
        "engDate": "2042-09-25",
        "nepDate": "09.06.2099"
    },
    {
        "engDate": "2042-09-26",
        "nepDate": "10.06.2099"
    },
    {
        "engDate": "2042-09-27",
        "nepDate": "11.06.2099"
    },
    {
        "engDate": "2042-09-28",
        "nepDate": "12.06.2099"
    },
    {
        "engDate": "2042-09-29",
        "nepDate": "13.06.2099"
    },
    {
        "engDate": "2042-09-30",
        "nepDate": "14.06.2099"
    },
    {
        "engDate": "2042-10-01",
        "nepDate": "15.06.2099"
    },
    {
        "engDate": "2042-10-02",
        "nepDate": "16.06.2099"
    },
    {
        "engDate": "2042-10-03",
        "nepDate": "17.06.2099"
    },
    {
        "engDate": "2042-10-04",
        "nepDate": "18.06.2099"
    },
    {
        "engDate": "2042-10-05",
        "nepDate": "19.06.2099"
    },
    {
        "engDate": "2042-10-06",
        "nepDate": "20.06.2099"
    },
    {
        "engDate": "2042-10-07",
        "nepDate": "21.06.2099"
    },
    {
        "engDate": "2042-10-08",
        "nepDate": "22.06.2099"
    },
    {
        "engDate": "2042-10-09",
        "nepDate": "23.06.2099"
    },
    {
        "engDate": "2042-10-10",
        "nepDate": "24.06.2099"
    },
    {
        "engDate": "2042-10-11",
        "nepDate": "25.06.2099"
    },
    {
        "engDate": "2042-10-12",
        "nepDate": "26.06.2099"
    },
    {
        "engDate": "2042-10-13",
        "nepDate": "27.06.2099"
    },
    {
        "engDate": "2042-10-14",
        "nepDate": "28.06.2099"
    },
    {
        "engDate": "2042-10-15",
        "nepDate": "29.06.2099"
    },
    {
        "engDate": "2042-10-16",
        "nepDate": "30.06.2099"
    },
    {
        "engDate": "2042-10-17",
        "nepDate": "31.06.2099"
    },
    {
        "engDate": "2042-10-18",
        "nepDate": "01.07.2099"
    },
    {
        "engDate": "2042-10-19",
        "nepDate": "02.07.2099"
    },
    {
        "engDate": "2042-10-20",
        "nepDate": "03.07.2099"
    },
    {
        "engDate": "2042-10-21",
        "nepDate": "04.07.2099"
    },
    {
        "engDate": "2042-10-22",
        "nepDate": "05.07.2099"
    },
    {
        "engDate": "2042-10-23",
        "nepDate": "06.07.2099"
    },
    {
        "engDate": "2042-10-24",
        "nepDate": "07.07.2099"
    },
    {
        "engDate": "2042-10-25",
        "nepDate": "08.07.2099"
    },
    {
        "engDate": "2042-10-26",
        "nepDate": "09.07.2099"
    },
    {
        "engDate": "2042-10-27",
        "nepDate": "10.07.2099"
    },
    {
        "engDate": "2042-10-28",
        "nepDate": "11.07.2099"
    },
    {
        "engDate": "2042-10-29",
        "nepDate": "12.07.2099"
    },
    {
        "engDate": "2042-10-30",
        "nepDate": "13.07.2099"
    },
    {
        "engDate": "2042-10-31",
        "nepDate": "14.07.2099"
    },
    {
        "engDate": "2042-11-01",
        "nepDate": "15.07.2099"
    },
    {
        "engDate": "2042-11-02",
        "nepDate": "16.07.2099"
    },
    {
        "engDate": "2042-11-03",
        "nepDate": "17.07.2099"
    },
    {
        "engDate": "2042-11-04",
        "nepDate": "18.07.2099"
    },
    {
        "engDate": "2042-11-05",
        "nepDate": "19.07.2099"
    },
    {
        "engDate": "2042-11-06",
        "nepDate": "20.07.2099"
    },
    {
        "engDate": "2042-11-07",
        "nepDate": "21.07.2099"
    },
    {
        "engDate": "2042-11-08",
        "nepDate": "22.07.2099"
    },
    {
        "engDate": "2042-11-09",
        "nepDate": "23.07.2099"
    },
    {
        "engDate": "2042-11-10",
        "nepDate": "24.07.2099"
    },
    {
        "engDate": "2042-11-11",
        "nepDate": "25.07.2099"
    },
    {
        "engDate": "2042-11-12",
        "nepDate": "26.07.2099"
    },
    {
        "engDate": "2042-11-13",
        "nepDate": "27.07.2099"
    },
    {
        "engDate": "2042-11-14",
        "nepDate": "28.07.2099"
    },
    {
        "engDate": "2042-11-15",
        "nepDate": "29.07.2099"
    },
    {
        "engDate": "2042-11-16",
        "nepDate": "30.07.2099"
    },
    {
        "engDate": "2042-11-17",
        "nepDate": "01.08.2099"
    },
    {
        "engDate": "2042-11-18",
        "nepDate": "02.08.2099"
    },
    {
        "engDate": "2042-11-19",
        "nepDate": "03.08.2099"
    },
    {
        "engDate": "2042-11-20",
        "nepDate": "04.08.2099"
    },
    {
        "engDate": "2042-11-21",
        "nepDate": "05.08.2099"
    },
    {
        "engDate": "2042-11-22",
        "nepDate": "06.08.2099"
    },
    {
        "engDate": "2042-11-23",
        "nepDate": "07.08.2099"
    },
    {
        "engDate": "2042-11-24",
        "nepDate": "08.08.2099"
    },
    {
        "engDate": "2042-11-25",
        "nepDate": "09.08.2099"
    },
    {
        "engDate": "2042-11-26",
        "nepDate": "10.08.2099"
    },
    {
        "engDate": "2042-11-27",
        "nepDate": "11.08.2099"
    },
    {
        "engDate": "2042-11-28",
        "nepDate": "12.08.2099"
    },
    {
        "engDate": "2042-11-29",
        "nepDate": "13.08.2099"
    },
    {
        "engDate": "2042-11-30",
        "nepDate": "14.08.2099"
    },
    {
        "engDate": "2042-12-01",
        "nepDate": "15.08.2099"
    },
    {
        "engDate": "2042-12-02",
        "nepDate": "16.08.2099"
    },
    {
        "engDate": "2042-12-03",
        "nepDate": "17.08.2099"
    },
    {
        "engDate": "2042-12-04",
        "nepDate": "18.08.2099"
    },
    {
        "engDate": "2042-12-05",
        "nepDate": "19.08.2099"
    },
    {
        "engDate": "2042-12-06",
        "nepDate": "20.08.2099"
    },
    {
        "engDate": "2042-12-07",
        "nepDate": "21.08.2099"
    },
    {
        "engDate": "2042-12-08",
        "nepDate": "22.08.2099"
    },
    {
        "engDate": "2042-12-09",
        "nepDate": "23.08.2099"
    },
    {
        "engDate": "2042-12-10",
        "nepDate": "24.08.2099"
    },
    {
        "engDate": "2042-12-11",
        "nepDate": "25.08.2099"
    },
    {
        "engDate": "2042-12-12",
        "nepDate": "26.08.2099"
    },
    {
        "engDate": "2042-12-13",
        "nepDate": "27.08.2099"
    },
    {
        "engDate": "2042-12-14",
        "nepDate": "28.08.2099"
    },
    {
        "engDate": "2042-12-15",
        "nepDate": "29.08.2099"
    },
    {
        "engDate": "2042-12-16",
        "nepDate": "01.09.2099"
    },
    {
        "engDate": "2042-12-17",
        "nepDate": "02.09.2099"
    },
    {
        "engDate": "2042-12-18",
        "nepDate": "03.09.2099"
    },
    {
        "engDate": "2042-12-19",
        "nepDate": "04.09.2099"
    },
    {
        "engDate": "2042-12-20",
        "nepDate": "05.09.2099"
    },
    {
        "engDate": "2042-12-21",
        "nepDate": "06.09.2099"
    },
    {
        "engDate": "2042-12-22",
        "nepDate": "07.09.2099"
    },
    {
        "engDate": "2042-12-23",
        "nepDate": "08.09.2099"
    },
    {
        "engDate": "2042-12-24",
        "nepDate": "09.09.2099"
    },
    {
        "engDate": "2042-12-25",
        "nepDate": "10.09.2099"
    },
    {
        "engDate": "2042-12-26",
        "nepDate": "11.09.2099"
    },
    {
        "engDate": "2042-12-27",
        "nepDate": "12.09.2099"
    },
    {
        "engDate": "2042-12-28",
        "nepDate": "13.09.2099"
    },
    {
        "engDate": "2042-12-29",
        "nepDate": "14.09.2099"
    },
    {
        "engDate": "2042-12-30",
        "nepDate": "15.09.2099"
    },
    {
        "engDate": "2042-12-31",
        "nepDate": "16.09.2099"
    },
    {
        "engDate": "2043-01-01",
        "nepDate": "17.09.2099"
    },
    {
        "engDate": "2043-01-02",
        "nepDate": "18.09.2099"
    },
    {
        "engDate": "2043-01-03",
        "nepDate": "19.09.2099"
    },
    {
        "engDate": "2043-01-04",
        "nepDate": "20.09.2099"
    },
    {
        "engDate": "2043-01-05",
        "nepDate": "21.09.2099"
    },
    {
        "engDate": "2043-01-06",
        "nepDate": "22.09.2099"
    },
    {
        "engDate": "2043-01-07",
        "nepDate": "23.09.2099"
    },
    {
        "engDate": "2043-01-08",
        "nepDate": "24.09.2099"
    },
    {
        "engDate": "2043-01-09",
        "nepDate": "25.09.2099"
    },
    {
        "engDate": "2043-01-10",
        "nepDate": "26.09.2099"
    },
    {
        "engDate": "2043-01-11",
        "nepDate": "27.09.2099"
    },
    {
        "engDate": "2043-01-12",
        "nepDate": "28.09.2099"
    },
    {
        "engDate": "2043-01-13",
        "nepDate": "29.09.2099"
    },
    {
        "engDate": "2043-01-14",
        "nepDate": "01.10.2099"
    },
    {
        "engDate": "2043-01-15",
        "nepDate": "02.10.2099"
    },
    {
        "engDate": "2043-01-16",
        "nepDate": "03.10.2099"
    },
    {
        "engDate": "2043-01-17",
        "nepDate": "04.10.2099"
    },
    {
        "engDate": "2043-01-18",
        "nepDate": "05.10.2099"
    },
    {
        "engDate": "2043-01-19",
        "nepDate": "06.10.2099"
    },
    {
        "engDate": "2043-01-20",
        "nepDate": "07.10.2099"
    },
    {
        "engDate": "2043-01-21",
        "nepDate": "08.10.2099"
    },
    {
        "engDate": "2043-01-22",
        "nepDate": "09.10.2099"
    },
    {
        "engDate": "2043-01-23",
        "nepDate": "10.10.2099"
    },
    {
        "engDate": "2043-01-24",
        "nepDate": "11.10.2099"
    },
    {
        "engDate": "2043-01-25",
        "nepDate": "12.10.2099"
    },
    {
        "engDate": "2043-01-26",
        "nepDate": "13.10.2099"
    },
    {
        "engDate": "2043-01-27",
        "nepDate": "14.10.2099"
    },
    {
        "engDate": "2043-01-28",
        "nepDate": "15.10.2099"
    },
    {
        "engDate": "2043-01-29",
        "nepDate": "16.10.2099"
    },
    {
        "engDate": "2043-01-30",
        "nepDate": "17.10.2099"
    },
    {
        "engDate": "2043-01-31",
        "nepDate": "18.10.2099"
    },
    {
        "engDate": "2043-02-01",
        "nepDate": "19.10.2099"
    },
    {
        "engDate": "2043-02-02",
        "nepDate": "20.10.2099"
    },
    {
        "engDate": "2043-02-03",
        "nepDate": "21.10.2099"
    },
    {
        "engDate": "2043-02-04",
        "nepDate": "22.10.2099"
    },
    {
        "engDate": "2043-02-05",
        "nepDate": "23.10.2099"
    },
    {
        "engDate": "2043-02-06",
        "nepDate": "24.10.2099"
    },
    {
        "engDate": "2043-02-07",
        "nepDate": "25.10.2099"
    },
    {
        "engDate": "2043-02-08",
        "nepDate": "26.10.2099"
    },
    {
        "engDate": "2043-02-09",
        "nepDate": "27.10.2099"
    },
    {
        "engDate": "2043-02-10",
        "nepDate": "28.10.2099"
    },
    {
        "engDate": "2043-02-11",
        "nepDate": "29.10.2099"
    },
    {
        "engDate": "2043-02-12",
        "nepDate": "30.10.2099"
    },
    {
        "engDate": "2043-02-13",
        "nepDate": "01.11.2099"
    },
    {
        "engDate": "2043-02-14",
        "nepDate": "02.11.2099"
    },
    {
        "engDate": "2043-02-15",
        "nepDate": "03.11.2099"
    },
    {
        "engDate": "2043-02-16",
        "nepDate": "04.11.2099"
    },
    {
        "engDate": "2043-02-17",
        "nepDate": "05.11.2099"
    },
    {
        "engDate": "2043-02-18",
        "nepDate": "06.11.2099"
    },
    {
        "engDate": "2043-02-19",
        "nepDate": "07.11.2099"
    },
    {
        "engDate": "2043-02-20",
        "nepDate": "08.11.2099"
    },
    {
        "engDate": "2043-02-21",
        "nepDate": "09.11.2099"
    },
    {
        "engDate": "2043-02-22",
        "nepDate": "10.11.2099"
    },
    {
        "engDate": "2043-02-23",
        "nepDate": "11.11.2099"
    },
    {
        "engDate": "2043-02-24",
        "nepDate": "12.11.2099"
    },
    {
        "engDate": "2043-02-25",
        "nepDate": "13.11.2099"
    },
    {
        "engDate": "2043-02-26",
        "nepDate": "14.11.2099"
    },
    {
        "engDate": "2043-02-27",
        "nepDate": "15.11.2099"
    },
    {
        "engDate": "2043-02-28",
        "nepDate": "16.11.2099"
    },
    {
        "engDate": "2043-03-01",
        "nepDate": "17.11.2099"
    },
    {
        "engDate": "2043-03-02",
        "nepDate": "18.11.2099"
    },
    {
        "engDate": "2043-03-03",
        "nepDate": "19.11.2099"
    },
    {
        "engDate": "2043-03-04",
        "nepDate": "20.11.2099"
    },
    {
        "engDate": "2043-03-05",
        "nepDate": "21.11.2099"
    },
    {
        "engDate": "2043-03-06",
        "nepDate": "22.11.2099"
    },
    {
        "engDate": "2043-03-07",
        "nepDate": "23.11.2099"
    },
    {
        "engDate": "2043-03-08",
        "nepDate": "24.11.2099"
    },
    {
        "engDate": "2043-03-09",
        "nepDate": "25.11.2099"
    },
    {
        "engDate": "2043-03-10",
        "nepDate": "26.11.2099"
    },
    {
        "engDate": "2043-03-11",
        "nepDate": "27.11.2099"
    },
    {
        "engDate": "2043-03-12",
        "nepDate": "28.11.2099"
    },
    {
        "engDate": "2043-03-13",
        "nepDate": "29.11.2099"
    },
    {
        "engDate": "2043-03-14",
        "nepDate": "30.11.2099"
    },
    {
        "engDate": "2043-03-15",
        "nepDate": "01.12.2099"
    },
    {
        "engDate": "2043-03-16",
        "nepDate": "02.12.2099"
    },
    {
        "engDate": "2043-03-17",
        "nepDate": "03.12.2099"
    },
    {
        "engDate": "2043-03-18",
        "nepDate": "04.12.2099"
    },
    {
        "engDate": "2043-03-19",
        "nepDate": "05.12.2099"
    },
    {
        "engDate": "2043-03-20",
        "nepDate": "06.12.2099"
    },
    {
        "engDate": "2043-03-21",
        "nepDate": "07.12.2099"
    },
    {
        "engDate": "2043-03-22",
        "nepDate": "08.12.2099"
    },
    {
        "engDate": "2043-03-23",
        "nepDate": "09.12.2099"
    },
    {
        "engDate": "2043-03-24",
        "nepDate": "10.12.2099"
    },
    {
        "engDate": "2043-03-25",
        "nepDate": "11.12.2099"
    },
    {
        "engDate": "2043-03-26",
        "nepDate": "12.12.2099"
    },
    {
        "engDate": "2043-03-27",
        "nepDate": "13.12.2099"
    },
    {
        "engDate": "2043-03-28",
        "nepDate": "14.12.2099"
    },
    {
        "engDate": "2043-03-29",
        "nepDate": "15.12.2099"
    },
    {
        "engDate": "2043-03-30",
        "nepDate": "16.12.2099"
    },
    {
        "engDate": "2043-03-31",
        "nepDate": "17.12.2099"
    },
    {
        "engDate": "2043-04-01",
        "nepDate": "18.12.2099"
    },
    {
        "engDate": "2043-04-02",
        "nepDate": "19.12.2099"
    },
    {
        "engDate": "2043-04-03",
        "nepDate": "20.12.2099"
    },
    {
        "engDate": "2043-04-04",
        "nepDate": "21.12.2099"
    },
    {
        "engDate": "2043-04-05",
        "nepDate": "22.12.2099"
    },
    {
        "engDate": "2043-04-06",
        "nepDate": "23.12.2099"
    },
    {
        "engDate": "2043-04-07",
        "nepDate": "24.12.2099"
    },
    {
        "engDate": "2043-04-08",
        "nepDate": "25.12.2099"
    },
    {
        "engDate": "2043-04-09",
        "nepDate": "26.12.2099"
    },
    {
        "engDate": "2043-04-10",
        "nepDate": "27.12.2099"
    },
    {
        "engDate": "2043-04-11",
        "nepDate": "28.12.2099"
    },
    {
        "engDate": "2043-04-12",
        "nepDate": "29.12.2099"
    },
    {
        "engDate": "2043-04-13",
        "nepDate": "30.12.2099"
    },
    {
        "engDate": "2043-04-14",
        "nepDate": "01.01.2100"
    },
    {
        "engDate": "2043-04-15",
        "nepDate": "02.01.2100"
    },
    {
        "engDate": "2043-04-16",
        "nepDate": "03.01.2100"
    },
    {
        "engDate": "2043-04-17",
        "nepDate": "04.01.2100"
    },
    {
        "engDate": "2043-04-18",
        "nepDate": "05.01.2100"
    },
    {
        "engDate": "2043-04-19",
        "nepDate": "06.01.2100"
    },
    {
        "engDate": "2043-04-20",
        "nepDate": "07.01.2100"
    },
    {
        "engDate": "2043-04-21",
        "nepDate": "08.01.2100"
    },
    {
        "engDate": "2043-04-22",
        "nepDate": "09.01.2100"
    },
    {
        "engDate": "2043-04-23",
        "nepDate": "10.01.2100"
    },
    {
        "engDate": "2043-04-24",
        "nepDate": "11.01.2100"
    },
    {
        "engDate": "2043-04-25",
        "nepDate": "12.01.2100"
    },
    {
        "engDate": "2043-04-26",
        "nepDate": "13.01.2100"
    },
    {
        "engDate": "2043-04-27",
        "nepDate": "14.01.2100"
    },
    {
        "engDate": "2043-04-28",
        "nepDate": "15.01.2100"
    },
    {
        "engDate": "2043-04-29",
        "nepDate": "16.01.2100"
    },
    {
        "engDate": "2043-04-30",
        "nepDate": "17.01.2100"
    },
    {
        "engDate": "2043-05-01",
        "nepDate": "18.01.2100"
    },
    {
        "engDate": "2043-05-02",
        "nepDate": "19.01.2100"
    },
    {
        "engDate": "2043-05-03",
        "nepDate": "20.01.2100"
    },
    {
        "engDate": "2043-05-04",
        "nepDate": "21.01.2100"
    },
    {
        "engDate": "2043-05-05",
        "nepDate": "22.01.2100"
    },
    {
        "engDate": "2043-05-06",
        "nepDate": "23.01.2100"
    },
    {
        "engDate": "2043-05-07",
        "nepDate": "24.01.2100"
    },
    {
        "engDate": "2043-05-08",
        "nepDate": "25.01.2100"
    },
    {
        "engDate": "2043-05-09",
        "nepDate": "26.01.2100"
    },
    {
        "engDate": "2043-05-10",
        "nepDate": "27.01.2100"
    },
    {
        "engDate": "2043-05-11",
        "nepDate": "28.01.2100"
    },
    {
        "engDate": "2043-05-12",
        "nepDate": "29.01.2100"
    },
    {
        "engDate": "2043-05-13",
        "nepDate": "30.01.2100"
    },
    {
        "engDate": "2043-05-14",
        "nepDate": "31.01.2100"
    },
    {
        "engDate": "2043-05-15",
        "nepDate": "01.02.2100"
    },
    {
        "engDate": "2043-05-16",
        "nepDate": "02.02.2100"
    },
    {
        "engDate": "2043-05-17",
        "nepDate": "03.02.2100"
    },
    {
        "engDate": "2043-05-18",
        "nepDate": "04.02.2100"
    },
    {
        "engDate": "2043-05-19",
        "nepDate": "05.02.2100"
    },
    {
        "engDate": "2043-05-20",
        "nepDate": "06.02.2100"
    },
    {
        "engDate": "2043-05-21",
        "nepDate": "07.02.2100"
    },
    {
        "engDate": "2043-05-22",
        "nepDate": "08.02.2100"
    },
    {
        "engDate": "2043-05-23",
        "nepDate": "09.02.2100"
    },
    {
        "engDate": "2043-05-24",
        "nepDate": "10.02.2100"
    },
    {
        "engDate": "2043-05-25",
        "nepDate": "11.02.2100"
    },
    {
        "engDate": "2043-05-26",
        "nepDate": "12.02.2100"
    },
    {
        "engDate": "2043-05-27",
        "nepDate": "13.02.2100"
    },
    {
        "engDate": "2043-05-28",
        "nepDate": "14.02.2100"
    },
    {
        "engDate": "2043-05-29",
        "nepDate": "15.02.2100"
    },
    {
        "engDate": "2043-05-30",
        "nepDate": "16.02.2100"
    },
    {
        "engDate": "2043-05-31",
        "nepDate": "17.02.2100"
    },
    {
        "engDate": "2043-06-01",
        "nepDate": "18.02.2100"
    },
    {
        "engDate": "2043-06-02",
        "nepDate": "19.02.2100"
    },
    {
        "engDate": "2043-06-03",
        "nepDate": "20.02.2100"
    },
    {
        "engDate": "2043-06-04",
        "nepDate": "21.02.2100"
    },
    {
        "engDate": "2043-06-05",
        "nepDate": "22.02.2100"
    },
    {
        "engDate": "2043-06-06",
        "nepDate": "23.02.2100"
    },
    {
        "engDate": "2043-06-07",
        "nepDate": "24.02.2100"
    },
    {
        "engDate": "2043-06-08",
        "nepDate": "25.02.2100"
    },
    {
        "engDate": "2043-06-09",
        "nepDate": "26.02.2100"
    },
    {
        "engDate": "2043-06-10",
        "nepDate": "27.02.2100"
    },
    {
        "engDate": "2043-06-11",
        "nepDate": "28.02.2100"
    },
    {
        "engDate": "2043-06-12",
        "nepDate": "29.02.2100"
    },
    {
        "engDate": "2043-06-13",
        "nepDate": "30.02.2100"
    },
    {
        "engDate": "2043-06-14",
        "nepDate": "31.02.2100"
    },
    {
        "engDate": "2043-06-15",
        "nepDate": "32.02.2100"
    },
    {
        "engDate": "2043-06-16",
        "nepDate": "01.03.2100"
    },
    {
        "engDate": "2043-06-17",
        "nepDate": "02.03.2100"
    },
    {
        "engDate": "2043-06-18",
        "nepDate": "03.03.2100"
    },
    {
        "engDate": "2043-06-19",
        "nepDate": "04.03.2100"
    },
    {
        "engDate": "2043-06-20",
        "nepDate": "05.03.2100"
    },
    {
        "engDate": "2043-06-21",
        "nepDate": "06.03.2100"
    },
    {
        "engDate": "2043-06-22",
        "nepDate": "07.03.2100"
    },
    {
        "engDate": "2043-06-23",
        "nepDate": "08.03.2100"
    },
    {
        "engDate": "2043-06-24",
        "nepDate": "09.03.2100"
    },
    {
        "engDate": "2043-06-25",
        "nepDate": "10.03.2100"
    },
    {
        "engDate": "2043-06-26",
        "nepDate": "11.03.2100"
    },
    {
        "engDate": "2043-06-27",
        "nepDate": "12.03.2100"
    },
    {
        "engDate": "2043-06-28",
        "nepDate": "13.03.2100"
    },
    {
        "engDate": "2043-06-29",
        "nepDate": "14.03.2100"
    },
    {
        "engDate": "2043-06-30",
        "nepDate": "15.03.2100"
    },
    {
        "engDate": "2043-07-01",
        "nepDate": "16.03.2100"
    },
    {
        "engDate": "2043-07-02",
        "nepDate": "17.03.2100"
    },
    {
        "engDate": "2043-07-03",
        "nepDate": "18.03.2100"
    },
    {
        "engDate": "2043-07-04",
        "nepDate": "19.03.2100"
    },
    {
        "engDate": "2043-07-05",
        "nepDate": "20.03.2100"
    },
    {
        "engDate": "2043-07-06",
        "nepDate": "21.03.2100"
    },
    {
        "engDate": "2043-07-07",
        "nepDate": "22.03.2100"
    },
    {
        "engDate": "2043-07-08",
        "nepDate": "23.03.2100"
    },
    {
        "engDate": "2043-07-09",
        "nepDate": "24.03.2100"
    },
    {
        "engDate": "2043-07-10",
        "nepDate": "25.03.2100"
    },
    {
        "engDate": "2043-07-11",
        "nepDate": "26.03.2100"
    },
    {
        "engDate": "2043-07-12",
        "nepDate": "27.03.2100"
    },
    {
        "engDate": "2043-07-13",
        "nepDate": "28.03.2100"
    },
    {
        "engDate": "2043-07-14",
        "nepDate": "29.03.2100"
    },
    {
        "engDate": "2043-07-15",
        "nepDate": "30.03.2100"
    },
    {
        "engDate": "2043-07-16",
        "nepDate": "31.03.2100"
    },
    {
        "engDate": "2043-07-17",
        "nepDate": "01.04.2100"
    },
    {
        "engDate": "2043-07-18",
        "nepDate": "02.04.2100"
    },
    {
        "engDate": "2043-07-19",
        "nepDate": "03.04.2100"
    },
    {
        "engDate": "2043-07-20",
        "nepDate": "04.04.2100"
    },
    {
        "engDate": "2043-07-21",
        "nepDate": "05.04.2100"
    },
    {
        "engDate": "2043-07-22",
        "nepDate": "06.04.2100"
    },
    {
        "engDate": "2043-07-23",
        "nepDate": "07.04.2100"
    },
    {
        "engDate": "2043-07-24",
        "nepDate": "08.04.2100"
    },
    {
        "engDate": "2043-07-25",
        "nepDate": "09.04.2100"
    },
    {
        "engDate": "2043-07-26",
        "nepDate": "10.04.2100"
    },
    {
        "engDate": "2043-07-27",
        "nepDate": "11.04.2100"
    },
    {
        "engDate": "2043-07-28",
        "nepDate": "12.04.2100"
    },
    {
        "engDate": "2043-07-29",
        "nepDate": "13.04.2100"
    },
    {
        "engDate": "2043-07-30",
        "nepDate": "14.04.2100"
    },
    {
        "engDate": "2043-07-31",
        "nepDate": "15.04.2100"
    },
    {
        "engDate": "2043-08-01",
        "nepDate": "16.04.2100"
    },
    {
        "engDate": "2043-08-02",
        "nepDate": "17.04.2100"
    },
    {
        "engDate": "2043-08-03",
        "nepDate": "18.04.2100"
    },
    {
        "engDate": "2043-08-04",
        "nepDate": "19.04.2100"
    },
    {
        "engDate": "2043-08-05",
        "nepDate": "20.04.2100"
    },
    {
        "engDate": "2043-08-06",
        "nepDate": "21.04.2100"
    },
    {
        "engDate": "2043-08-07",
        "nepDate": "22.04.2100"
    },
    {
        "engDate": "2043-08-08",
        "nepDate": "23.04.2100"
    },
    {
        "engDate": "2043-08-09",
        "nepDate": "24.04.2100"
    },
    {
        "engDate": "2043-08-10",
        "nepDate": "25.04.2100"
    },
    {
        "engDate": "2043-08-11",
        "nepDate": "26.04.2100"
    },
    {
        "engDate": "2043-08-12",
        "nepDate": "27.04.2100"
    },
    {
        "engDate": "2043-08-13",
        "nepDate": "28.04.2100"
    },
    {
        "engDate": "2043-08-14",
        "nepDate": "29.04.2100"
    },
    {
        "engDate": "2043-08-15",
        "nepDate": "30.04.2100"
    },
    {
        "engDate": "2043-08-16",
        "nepDate": "31.04.2100"
    },
    {
        "engDate": "2043-08-17",
        "nepDate": "32.04.2100"
    },
    {
        "engDate": "2043-08-18",
        "nepDate": "01.05.2100"
    },
    {
        "engDate": "2043-08-19",
        "nepDate": "02.05.2100"
    },
    {
        "engDate": "2043-08-20",
        "nepDate": "03.05.2100"
    },
    {
        "engDate": "2043-08-21",
        "nepDate": "04.05.2100"
    },
    {
        "engDate": "2043-08-22",
        "nepDate": "05.05.2100"
    },
    {
        "engDate": "2043-08-23",
        "nepDate": "06.05.2100"
    },
    {
        "engDate": "2043-08-24",
        "nepDate": "07.05.2100"
    },
    {
        "engDate": "2043-08-25",
        "nepDate": "08.05.2100"
    },
    {
        "engDate": "2043-08-26",
        "nepDate": "09.05.2100"
    },
    {
        "engDate": "2043-08-27",
        "nepDate": "10.05.2100"
    },
    {
        "engDate": "2043-08-28",
        "nepDate": "11.05.2100"
    },
    {
        "engDate": "2043-08-29",
        "nepDate": "12.05.2100"
    },
    {
        "engDate": "2043-08-30",
        "nepDate": "13.05.2100"
    },
    {
        "engDate": "2043-08-31",
        "nepDate": "14.05.2100"
    },
    {
        "engDate": "2043-09-01",
        "nepDate": "15.05.2100"
    },
    {
        "engDate": "2043-09-02",
        "nepDate": "16.05.2100"
    },
    {
        "engDate": "2043-09-03",
        "nepDate": "17.05.2100"
    },
    {
        "engDate": "2043-09-04",
        "nepDate": "18.05.2100"
    },
    {
        "engDate": "2043-09-05",
        "nepDate": "19.05.2100"
    },
    {
        "engDate": "2043-09-06",
        "nepDate": "20.05.2100"
    },
    {
        "engDate": "2043-09-07",
        "nepDate": "21.05.2100"
    },
    {
        "engDate": "2043-09-08",
        "nepDate": "22.05.2100"
    },
    {
        "engDate": "2043-09-09",
        "nepDate": "23.05.2100"
    },
    {
        "engDate": "2043-09-10",
        "nepDate": "24.05.2100"
    },
    {
        "engDate": "2043-09-11",
        "nepDate": "25.05.2100"
    },
    {
        "engDate": "2043-09-12",
        "nepDate": "26.05.2100"
    },
    {
        "engDate": "2043-09-13",
        "nepDate": "27.05.2100"
    },
    {
        "engDate": "2043-09-14",
        "nepDate": "28.05.2100"
    },
    {
        "engDate": "2043-09-15",
        "nepDate": "29.05.2100"
    },
    {
        "engDate": "2043-09-16",
        "nepDate": "30.05.2100"
    },
    {
        "engDate": "2043-09-17",
        "nepDate": "01.06.2100"
    },
    {
        "engDate": "2043-09-18",
        "nepDate": "02.06.2100"
    },
    {
        "engDate": "2043-09-19",
        "nepDate": "03.06.2100"
    },
    {
        "engDate": "2043-09-20",
        "nepDate": "04.06.2100"
    },
    {
        "engDate": "2043-09-21",
        "nepDate": "05.06.2100"
    },
    {
        "engDate": "2043-09-22",
        "nepDate": "06.06.2100"
    },
    {
        "engDate": "2043-09-23",
        "nepDate": "07.06.2100"
    },
    {
        "engDate": "2043-09-24",
        "nepDate": "08.06.2100"
    },
    {
        "engDate": "2043-09-25",
        "nepDate": "09.06.2100"
    },
    {
        "engDate": "2043-09-26",
        "nepDate": "10.06.2100"
    },
    {
        "engDate": "2043-09-27",
        "nepDate": "11.06.2100"
    },
    {
        "engDate": "2043-09-28",
        "nepDate": "12.06.2100"
    },
    {
        "engDate": "2043-09-29",
        "nepDate": "13.06.2100"
    },
    {
        "engDate": "2043-09-30",
        "nepDate": "14.06.2100"
    },
    {
        "engDate": "2043-10-01",
        "nepDate": "15.06.2100"
    },
    {
        "engDate": "2043-10-02",
        "nepDate": "16.06.2100"
    },
    {
        "engDate": "2043-10-03",
        "nepDate": "17.06.2100"
    },
    {
        "engDate": "2043-10-04",
        "nepDate": "18.06.2100"
    },
    {
        "engDate": "2043-10-05",
        "nepDate": "19.06.2100"
    },
    {
        "engDate": "2043-10-06",
        "nepDate": "20.06.2100"
    },
    {
        "engDate": "2043-10-07",
        "nepDate": "21.06.2100"
    },
    {
        "engDate": "2043-10-08",
        "nepDate": "22.06.2100"
    },
    {
        "engDate": "2043-10-09",
        "nepDate": "23.06.2100"
    },
    {
        "engDate": "2043-10-10",
        "nepDate": "24.06.2100"
    },
    {
        "engDate": "2043-10-11",
        "nepDate": "25.06.2100"
    },
    {
        "engDate": "2043-10-12",
        "nepDate": "26.06.2100"
    },
    {
        "engDate": "2043-10-13",
        "nepDate": "27.06.2100"
    },
    {
        "engDate": "2043-10-14",
        "nepDate": "28.06.2100"
    },
    {
        "engDate": "2043-10-15",
        "nepDate": "29.06.2100"
    },
    {
        "engDate": "2043-10-16",
        "nepDate": "30.06.2100"
    },
    {
        "engDate": "2043-10-17",
        "nepDate": "31.06.2100"
    },
    {
        "engDate": "2043-10-18",
        "nepDate": "01.07.2100"
    },
    {
        "engDate": "2043-10-19",
        "nepDate": "02.07.2100"
    },
    {
        "engDate": "2043-10-20",
        "nepDate": "03.07.2100"
    },
    {
        "engDate": "2043-10-21",
        "nepDate": "04.07.2100"
    },
    {
        "engDate": "2043-10-22",
        "nepDate": "05.07.2100"
    },
    {
        "engDate": "2043-10-23",
        "nepDate": "06.07.2100"
    },
    {
        "engDate": "2043-10-24",
        "nepDate": "07.07.2100"
    },
    {
        "engDate": "2043-10-25",
        "nepDate": "08.07.2100"
    },
    {
        "engDate": "2043-10-26",
        "nepDate": "09.07.2100"
    },
    {
        "engDate": "2043-10-27",
        "nepDate": "10.07.2100"
    },
    {
        "engDate": "2043-10-28",
        "nepDate": "11.07.2100"
    },
    {
        "engDate": "2043-10-29",
        "nepDate": "12.07.2100"
    },
    {
        "engDate": "2043-10-30",
        "nepDate": "13.07.2100"
    },
    {
        "engDate": "2043-10-31",
        "nepDate": "14.07.2100"
    },
    {
        "engDate": "2043-11-01",
        "nepDate": "15.07.2100"
    },
    {
        "engDate": "2043-11-02",
        "nepDate": "16.07.2100"
    },
    {
        "engDate": "2043-11-03",
        "nepDate": "17.07.2100"
    },
    {
        "engDate": "2043-11-04",
        "nepDate": "18.07.2100"
    },
    {
        "engDate": "2043-11-05",
        "nepDate": "19.07.2100"
    },
    {
        "engDate": "2043-11-06",
        "nepDate": "20.07.2100"
    },
    {
        "engDate": "2043-11-07",
        "nepDate": "21.07.2100"
    },
    {
        "engDate": "2043-11-08",
        "nepDate": "22.07.2100"
    },
    {
        "engDate": "2043-11-09",
        "nepDate": "23.07.2100"
    },
    {
        "engDate": "2043-11-10",
        "nepDate": "24.07.2100"
    },
    {
        "engDate": "2043-11-11",
        "nepDate": "25.07.2100"
    },
    {
        "engDate": "2043-11-12",
        "nepDate": "26.07.2100"
    },
    {
        "engDate": "2043-11-13",
        "nepDate": "27.07.2100"
    },
    {
        "engDate": "2043-11-14",
        "nepDate": "28.07.2100"
    },
    {
        "engDate": "2043-11-15",
        "nepDate": "29.07.2100"
    },
    {
        "engDate": "2043-11-16",
        "nepDate": "30.07.2100"
    },
    {
        "engDate": "2043-11-17",
        "nepDate": "01.08.2100"
    },
    {
        "engDate": "2043-11-18",
        "nepDate": "02.08.2100"
    },
    {
        "engDate": "2043-11-19",
        "nepDate": "03.08.2100"
    },
    {
        "engDate": "2043-11-20",
        "nepDate": "04.08.2100"
    },
    {
        "engDate": "2043-11-21",
        "nepDate": "05.08.2100"
    },
    {
        "engDate": "2043-11-22",
        "nepDate": "06.08.2100"
    },
    {
        "engDate": "2043-11-23",
        "nepDate": "07.08.2100"
    },
    {
        "engDate": "2043-11-24",
        "nepDate": "08.08.2100"
    },
    {
        "engDate": "2043-11-25",
        "nepDate": "09.08.2100"
    },
    {
        "engDate": "2043-11-26",
        "nepDate": "10.08.2100"
    },
    {
        "engDate": "2043-11-27",
        "nepDate": "11.08.2100"
    },
    {
        "engDate": "2043-11-28",
        "nepDate": "12.08.2100"
    },
    {
        "engDate": "2043-11-29",
        "nepDate": "13.08.2100"
    },
    {
        "engDate": "2043-11-30",
        "nepDate": "14.08.2100"
    },
    {
        "engDate": "2043-12-01",
        "nepDate": "15.08.2100"
    },
    {
        "engDate": "2043-12-02",
        "nepDate": "16.08.2100"
    },
    {
        "engDate": "2043-12-03",
        "nepDate": "17.08.2100"
    },
    {
        "engDate": "2043-12-04",
        "nepDate": "18.08.2100"
    },
    {
        "engDate": "2043-12-05",
        "nepDate": "19.08.2100"
    },
    {
        "engDate": "2043-12-06",
        "nepDate": "20.08.2100"
    },
    {
        "engDate": "2043-12-07",
        "nepDate": "21.08.2100"
    },
    {
        "engDate": "2043-12-08",
        "nepDate": "22.08.2100"
    },
    {
        "engDate": "2043-12-09",
        "nepDate": "23.08.2100"
    },
    {
        "engDate": "2043-12-10",
        "nepDate": "24.08.2100"
    },
    {
        "engDate": "2043-12-11",
        "nepDate": "25.08.2100"
    },
    {
        "engDate": "2043-12-12",
        "nepDate": "26.08.2100"
    },
    {
        "engDate": "2043-12-13",
        "nepDate": "27.08.2100"
    },
    {
        "engDate": "2043-12-14",
        "nepDate": "28.08.2100"
    },
    {
        "engDate": "2043-12-15",
        "nepDate": "29.08.2100"
    },
    {
        "engDate": "2043-12-16",
        "nepDate": "01.09.2100"
    },
    {
        "engDate": "2043-12-17",
        "nepDate": "02.09.2100"
    },
    {
        "engDate": "2043-12-18",
        "nepDate": "03.09.2100"
    },
    {
        "engDate": "2043-12-19",
        "nepDate": "04.09.2100"
    },
    {
        "engDate": "2043-12-20",
        "nepDate": "05.09.2100"
    },
    {
        "engDate": "2043-12-21",
        "nepDate": "06.09.2100"
    },
    {
        "engDate": "2043-12-22",
        "nepDate": "07.09.2100"
    },
    {
        "engDate": "2043-12-23",
        "nepDate": "08.09.2100"
    },
    {
        "engDate": "2043-12-24",
        "nepDate": "09.09.2100"
    },
    {
        "engDate": "2043-12-25",
        "nepDate": "10.09.2100"
    },
    {
        "engDate": "2043-12-26",
        "nepDate": "11.09.2100"
    },
    {
        "engDate": "2043-12-27",
        "nepDate": "12.09.2100"
    },
    {
        "engDate": "2043-12-28",
        "nepDate": "13.09.2100"
    },
    {
        "engDate": "2043-12-29",
        "nepDate": "14.09.2100"
    },
    {
        "engDate": "2043-12-30",
        "nepDate": "15.09.2100"
    },
    {
        "engDate": "2043-12-31",
        "nepDate": "16.09.2100"
    },
    {
        "engDate": "2044-01-01",
        "nepDate": "17.09.2100"
    },
    {
        "engDate": "2044-01-02",
        "nepDate": "18.09.2100"
    },
    {
        "engDate": "2044-01-03",
        "nepDate": "19.09.2100"
    },
    {
        "engDate": "2044-01-04",
        "nepDate": "20.09.2100"
    },
    {
        "engDate": "2044-01-05",
        "nepDate": "21.09.2100"
    },
    {
        "engDate": "2044-01-06",
        "nepDate": "22.09.2100"
    },
    {
        "engDate": "2044-01-07",
        "nepDate": "23.09.2100"
    },
    {
        "engDate": "2044-01-08",
        "nepDate": "24.09.2100"
    },
    {
        "engDate": "2044-01-09",
        "nepDate": "25.09.2100"
    },
    {
        "engDate": "2044-01-10",
        "nepDate": "26.09.2100"
    },
    {
        "engDate": "2044-01-11",
        "nepDate": "27.09.2100"
    },
    {
        "engDate": "2044-01-12",
        "nepDate": "28.09.2100"
    },
    {
        "engDate": "2044-01-13",
        "nepDate": "29.09.2100"
    },
    {
        "engDate": "2044-01-14",
        "nepDate": "30.09.2100"
    },
    {
        "engDate": "2044-01-15",
        "nepDate": "01.10.2100"
    },
    {
        "engDate": "2044-01-16",
        "nepDate": "02.10.2100"
    },
    {
        "engDate": "2044-01-17",
        "nepDate": "03.10.2100"
    },
    {
        "engDate": "2044-01-18",
        "nepDate": "04.10.2100"
    },
    {
        "engDate": "2044-01-19",
        "nepDate": "05.10.2100"
    },
    {
        "engDate": "2044-01-20",
        "nepDate": "06.10.2100"
    },
    {
        "engDate": "2044-01-21",
        "nepDate": "07.10.2100"
    },
    {
        "engDate": "2044-01-22",
        "nepDate": "08.10.2100"
    },
    {
        "engDate": "2044-01-23",
        "nepDate": "09.10.2100"
    },
    {
        "engDate": "2044-01-24",
        "nepDate": "10.10.2100"
    },
    {
        "engDate": "2044-01-25",
        "nepDate": "11.10.2100"
    },
    {
        "engDate": "2044-01-26",
        "nepDate": "12.10.2100"
    },
    {
        "engDate": "2044-01-27",
        "nepDate": "13.10.2100"
    },
    {
        "engDate": "2044-01-28",
        "nepDate": "14.10.2100"
    },
    {
        "engDate": "2044-01-29",
        "nepDate": "15.10.2100"
    },
    {
        "engDate": "2044-01-30",
        "nepDate": "16.10.2100"
    },
    {
        "engDate": "2044-01-31",
        "nepDate": "17.10.2100"
    },
    {
        "engDate": "2044-02-01",
        "nepDate": "18.10.2100"
    },
    {
        "engDate": "2044-02-02",
        "nepDate": "19.10.2100"
    },
    {
        "engDate": "2044-02-03",
        "nepDate": "20.10.2100"
    },
    {
        "engDate": "2044-02-04",
        "nepDate": "21.10.2100"
    },
    {
        "engDate": "2044-02-05",
        "nepDate": "22.10.2100"
    },
    {
        "engDate": "2044-02-06",
        "nepDate": "23.10.2100"
    },
    {
        "engDate": "2044-02-07",
        "nepDate": "24.10.2100"
    },
    {
        "engDate": "2044-02-08",
        "nepDate": "25.10.2100"
    },
    {
        "engDate": "2044-02-09",
        "nepDate": "26.10.2100"
    },
    {
        "engDate": "2044-02-10",
        "nepDate": "27.10.2100"
    },
    {
        "engDate": "2044-02-11",
        "nepDate": "28.10.2100"
    },
    {
        "engDate": "2044-02-12",
        "nepDate": "29.10.2100"
    },
    {
        "engDate": "2044-02-13",
        "nepDate": "01.11.2100"
    },
    {
        "engDate": "2044-02-14",
        "nepDate": "02.11.2100"
    },
    {
        "engDate": "2044-02-15",
        "nepDate": "03.11.2100"
    },
    {
        "engDate": "2044-02-16",
        "nepDate": "04.11.2100"
    },
    {
        "engDate": "2044-02-17",
        "nepDate": "05.11.2100"
    },
    {
        "engDate": "2044-02-18",
        "nepDate": "06.11.2100"
    },
    {
        "engDate": "2044-02-19",
        "nepDate": "07.11.2100"
    },
    {
        "engDate": "2044-02-20",
        "nepDate": "08.11.2100"
    },
    {
        "engDate": "2044-02-21",
        "nepDate": "09.11.2100"
    },
    {
        "engDate": "2044-02-22",
        "nepDate": "10.11.2100"
    },
    {
        "engDate": "2044-02-23",
        "nepDate": "11.11.2100"
    },
    {
        "engDate": "2044-02-24",
        "nepDate": "12.11.2100"
    },
    {
        "engDate": "2044-02-25",
        "nepDate": "13.11.2100"
    },
    {
        "engDate": "2044-02-26",
        "nepDate": "14.11.2100"
    },
    {
        "engDate": "2044-02-27",
        "nepDate": "15.11.2100"
    },
    {
        "engDate": "2044-02-28",
        "nepDate": "16.11.2100"
    },
    {
        "engDate": "2044-02-29",
        "nepDate": "17.11.2100"
    },
    {
        "engDate": "2044-03-01",
        "nepDate": "18.11.2100"
    },
    {
        "engDate": "2044-03-02",
        "nepDate": "19.11.2100"
    },
    {
        "engDate": "2044-03-03",
        "nepDate": "20.11.2100"
    },
    {
        "engDate": "2044-03-04",
        "nepDate": "21.11.2100"
    },
    {
        "engDate": "2044-03-05",
        "nepDate": "22.11.2100"
    },
    {
        "engDate": "2044-03-06",
        "nepDate": "23.11.2100"
    },
    {
        "engDate": "2044-03-07",
        "nepDate": "24.11.2100"
    },
    {
        "engDate": "2044-03-08",
        "nepDate": "25.11.2100"
    },
    {
        "engDate": "2044-03-09",
        "nepDate": "26.11.2100"
    },
    {
        "engDate": "2044-03-10",
        "nepDate": "27.11.2100"
    },
    {
        "engDate": "2044-03-11",
        "nepDate": "28.11.2100"
    },
    {
        "engDate": "2044-03-12",
        "nepDate": "29.11.2100"
    },
    {
        "engDate": "2044-03-13",
        "nepDate": "30.11.2100"
    },
    {
        "engDate": "2044-03-14",
        "nepDate": "01.12.2100"
    },
    {
        "engDate": "2044-03-15",
        "nepDate": "02.12.2100"
    },
    {
        "engDate": "2044-03-16",
        "nepDate": "03.12.2100"
    },
    {
        "engDate": "2044-03-17",
        "nepDate": "04.12.2100"
    },
    {
        "engDate": "2044-03-18",
        "nepDate": "05.12.2100"
    },
    {
        "engDate": "2044-03-19",
        "nepDate": "06.12.2100"
    },
    {
        "engDate": "2044-03-20",
        "nepDate": "07.12.2100"
    },
    {
        "engDate": "2044-03-21",
        "nepDate": "08.12.2100"
    },
    {
        "engDate": "2044-03-22",
        "nepDate": "09.12.2100"
    },
    {
        "engDate": "2044-03-23",
        "nepDate": "10.12.2100"
    },
    {
        "engDate": "2044-03-24",
        "nepDate": "11.12.2100"
    },
    {
        "engDate": "2044-03-25",
        "nepDate": "12.12.2100"
    },
    {
        "engDate": "2044-03-26",
        "nepDate": "13.12.2100"
    },
    {
        "engDate": "2044-03-27",
        "nepDate": "14.12.2100"
    },
    {
        "engDate": "2044-03-28",
        "nepDate": "15.12.2100"
    },
    {
        "engDate": "2044-03-29",
        "nepDate": "16.12.2100"
    },
    {
        "engDate": "2044-03-30",
        "nepDate": "17.12.2100"
    },
    {
        "engDate": "2044-03-31",
        "nepDate": "18.12.2100"
    },
    {
        "engDate": "2044-04-01",
        "nepDate": "19.12.2100"
    },
    {
        "engDate": "2044-04-02",
        "nepDate": "20.12.2100"
    },
    {
        "engDate": "2044-04-03",
        "nepDate": "21.12.2100"
    },
    {
        "engDate": "2044-04-04",
        "nepDate": "22.12.2100"
    },
    {
        "engDate": "2044-04-05",
        "nepDate": "23.12.2100"
    },
    {
        "engDate": "2044-04-06",
        "nepDate": "24.12.2100"
    },
    {
        "engDate": "2044-04-07",
        "nepDate": "25.12.2100"
    },
    {
        "engDate": "2044-04-08",
        "nepDate": "26.12.2100"
    },
    {
        "engDate": "2044-04-09",
        "nepDate": "27.12.2100"
    },
    {
        "engDate": "2044-04-10",
        "nepDate": "28.12.2100"
    },
    {
        "engDate": "2044-04-11",
        "nepDate": "29.12.2100"
    },
    {
        "engDate": "2044-04-12",
        "nepDate": "30.12.2100"
    }
]