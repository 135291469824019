import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";


export const StickyWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 99;
   transition: height 0.5s ease;
   background: white;
`



export const NavbarComponents = styled.div`
    width: 100%;
    background: white;
    padding: 8px 4vw;
    filter: drop-shadow(0px 4px 4px #FBEAEA);
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* @media (max-width: 768px){
        padding: 8px 10vw;
    } */

    @media (max-width: 768px){
       /* padding: 4px 6vw; */
       
    justify-content: center;
    }
`

export const LogoWrapper = styled(Link)`
    /* background: green; */
    /* padding: 2px; */
`

export const Logo = styled.img`
    width: 10vw;
    /* margin: 0 4vw; */
    border-radius: 4px;

    @media (max-width: 768px){
        margin: 0;
        width: 50vw;
        /* border-radius: 25px; */
    }
    @media (max-width: 380px){
        width: 25vw;
        /* border-radius: 12px; */
    }`

export const NavItems = styled.div`
    display: flex;

        @media (max-width: 768px){
        display: none ;
    }
`

export const NavItem = styled.p`
    font-size: 1em;
    margin-left: 10px;


`



export const NavLinks = styled(Link)`
    text-decoration: none;
    font-size: 1em;
    margin-left: 2vw;
    /* font-weight: 500; */
    line-height: 1.6;
    text-transform: uppercase;
    color: ${props => props.match ? COLORS.primary : 'black'};
    font-weight: ${props => props.match ? 500 : 300};

    &:hover{
    color: ${COLORS.primary};
    font-weight: 400;
    }

`

// export const TopWrapper = styled.div`
//       max-width: 1400px;
//     width: 98vw;
// 	margin: 25px auto; 
//     padding: 25px 42px;  
//     background: white;
//     filter: drop-shadow(0px 4px 4px #FBEAEA);
//     display: grid;
//     grid-template-columns: 2fr 1fr;
//     border-radius: 8px;

//     @media (max-width: 768px){
//         grid-template-columns: none;
//         grid-template-rows: 2fr 1fr;
//         padding: 15px 30px;  
//     }
//     @media (max-width: 576px){
//        padding: 4px;
//     }
// `

// export const TopLeftWrapper = styled.div`
//     /* background: red; */
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
// `

// export const DateWrapper = styled.div`
//     display: flex;
//      width: 100px;
//      height: 100px;
//      justify-content: center;
//      align-items: center;
//      border-radius: 50px;
//      background: white;
//      font-size: 48px;
//      font-weight: bold;
//      color: red;
//     filter: drop-shadow(0px 4px 4px #FBEAEA);

//     @media (max-width: 768px){
//         font-size: 24px;
//         width: 120px;
//         height: 100px;
//     }

//     @media (max-width: 576px){
//         font-size: 18px;
//         width: 90px;
//         height: 80px;
//     }
// `

// export const DateTimeWrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     justify-content: space-evenly;
//     margin-left: 60px;
//     /* background: yellow; */

//         @media (max-width: 576px){
//         margin-left: 20px;
//     }
// `

// export const SemiBoldFont = styled.h1`
//   font-family: 'Poppins Semibold', sans-serif;

//   display: ${props => props.small ? 'none' : 'block'};
//   font-size: ${props => props.fontsize ? props.fontsize : '36px'};
//   /* font-weight: 600; */

//     @media (max-width: 768px){
//         display: ${props => props.big ? 'none' : 'block'};
//         font-size: ${props => props.fontsize ? `calc(${props.fontsize} - 10px);` : '24px'};
//     }
// `

// export const NormalFont = styled.p`
//     font-size: 18px;

//     @media (max-width: 768px){
//         font-size: ${props => props.fontsize ? `calc(${props.fontsize} - 10px);` : '12px'};
//     }
// `


// export const TopRightWrapper = styled.div`
//     /* background: green; */
//     display: flex;  
//     justify-content: center;
//     align-items: flex-end;
//     flex-direction: column;

//     @media (max-width: 768px){
//         align-items: flex-start;
//         margin-left: 180px;
//     }

//     @media (max-width: 576px){
//         margin-left: 110px;
//     }
    
//     @media (max-width: 380px){
//         /* justify-content: space-around; */
//         margin-left: 100px;
//     }
// `


// export const TimelineWrapper = styled.div`
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;

//     @media (max-width: 768px){
//         justify-content: flex-start;
//     }

//         @media (max-width: 380px){
//         justify-content: space-around;
//     }
    
// `

// export const IconWithTime = styled.div`
//     display: flex;
//     justify-content: space-evenly;
//     align-items: center;
//     /* background: green; */

//        @media (max-width: 576px){
//            display: grid;
//            grid-template-columns: 1fr 1fr;
//            grid-template-rows: 1fr 1fr;
//     }

//            @media (max-width: 380px){
//            grid-template-columns: 1fr;
//            grid-template-rows: none;
//     }
// `

// export const MarginItems = styled.div`
//     display: flex;
//     justify-content: space-evenly;
//     align-items: center;
//     margin-left: 20px;
//     font-size: 18px;

//     @media (max-width: 768px){
//         font-size: 12px;
//         margin-left: 0px;
//         margin-right: 20px;
//     }
// `
