import React from 'react'
import { IconWithText, IconWithTextLink, MobileNavigationWrapper } from './MobileNavigationComponent'
import { FaHome, FaMoon, FaUmbrella, FaTools } from 'react-icons/fa';
import { SiConvertio } from 'react-icons/si';
import { IoIosContact } from 'react-icons/io';
import { useMatch, useResolvedPath } from 'react-router-dom';

const MobileNavigation = () => {

    const routes = [
        {
            name: "Home",
            to: '/',
            icon: <FaHome />
        },
        {
            name: "Converter",
            to: "/converter",
            icon: <SiConvertio />
        },
        {

            name: "Tools",
            to: "/tools",
            icon: <FaTools />
        },

        {
            name: "Contact",
            to: "/contact",
            icon: <IoIosContact />
        }
    ]



    const CustomLink = ({ children, to, ...props }) => {
        let resolved = useResolvedPath(to);
        let match = useMatch({ path: resolved.pathname, end: true });

        return (

            <IconWithTextLink
                match={match}
                to={to}
                {...props}
            >
                {children}
            </IconWithTextLink>

        );
    }

    return (
        <MobileNavigationWrapper>


            {
                routes.map((item) => <CustomLink key={item.name} to={item.to}>
                    {item.icon}
                    {item.name}
                </CustomLink>)
            }


            {/* <CustomLink to='/'>
                <FaHome />
                Home
            </CustomLink>
            <CustomLink>
                <SiConvertio />
                Converter
            </CustomLink>
            <CustomLink>
                <FaTools />
                Tools
            </CustomLink>
            <CustomLink>
                <IoIosContact />
                Contact
            </CustomLink> */}
        </MobileNavigationWrapper>
    )
}

export default MobileNavigation