import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../utils/colors";

export const MainWrapper = styled.div`
    /* background: #F0F0F0; */
    /* max-width: 1400px; */
    flex: 1;
    display: grid;
    grid-template-rows: 3fr 2fr;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: red; */
    width: 98vw;
    /* min-height: calc(100vh - 150px); */
	margin: 0 auto; 
	float: none; 
    /* padding: 26px 0; */

    @media (max-width: 768px){
    grid-template-rows: 2fr 1fr;
    }

    @media (max-width: 576px){
        display: flex;
        justify-content: flex-start;
        grid-template-rows: 1fr 1fr;
        max-height: 600px;
        color: green;
        /* background: red; */
    }
    
`

export const SelectWrapper = styled.div`
    display: grid;
    /* background: green; */
    grid-template-columns: 3fr 1fr 2fr ;
    column-gap: 2vw;
    max-width: 768px;
    width: 98vw;

    @media (max-width: 768px){
        width: 90vw;
        /* background: red; */
    }
    @media (max-width: 576px){
        width: 80vw;
        /* background: red; */
    }
`

export const DateSelect = styled.select`

    /* color: red; */
    appearance: none;
    /* font-size: 1.5vw; */
    /* width: 20%; */
    padding: 0.5vw 2vw 0.3vw 1vw;
    background-image: url("./caretdownred.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 1vw) center;
    background-size: 1vw;
    border: none;
    background-color: #FBEAEA;
    border-radius: 8px;

    align-items: center;
    /* font-family: 'Montserrat Semibold', sans-serif; */
    font-size: 24px;

    
        /* @media (max-width: 768px){
    font-size: 2vw;
    padding-right: 4vw;
    background-size: 1.5vw;
    } */

    
    @media (max-width: 576px){
        font-size: 4vw;
        padding-right: 6vw;
        background-size: 2.5vw;
    }

`

export const ConversionWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    
    @media (max-width: 768px){
        flex-direction: column;
        align-items: center;
        justify-content:center;
        font-size: 16px;
    }

`

export const TopGridWrapper = styled.div`
/* background: green; */
display: flex;
height: 100%;
flex-direction: column;
justify-content: space-evenly;
align-items: flex-end;

    @media (max-width: 576px){
        min-height: 32vh;
        /* background: red; */
    }

`

export const ConvertBtn = styled.button`
    background: ${COLORS.primary};
    color: white;
    font-size: 24px;
    border: none;
    border-radius: 8px;
    padding: 0vw 4vw;
    cursor: pointer;

        @media (max-width: 576px){
        font-size: 4vw;
    border-radius: 4px;
    }
`


export const SemiBoldFont = styled.h1`
  /* font-family: 'Montserrat Semibold', sans-serif; */
  fontweight: 600;
  font-size:24px;
  color: ${COLORS.primary};

    @media (max-width: 768px){
        font-size: 4vw;
    }
`

export const BoldFont = styled.p`
    font-size: 26px;
    font-weight: bold;
    color: ${COLORS.primary};


    @media (max-width: 768px){
        font-size: 4.5vw;
    }
`


export const RadioWrapper = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    
    margin-bottom: 2vw;
`

export const Label = styled.label`
    color: red;
    margin-left: 0.5vw;

    /* font-family: 'Montserrat Semibold', sans-serif; */
    fontweight: 600;
    font-size:24px;
    color: ${COLORS.primary};

    @media (max-width: 768px){
        font-size: 2.5vw;
    }

`