import React from 'react'
import { FooterWrapper, FooterLinks, FooterLink } from './FooterComponents'

const Footer = () => {



    const footerRoutes = [
        {
            name: "Privacy Policy",
            to: '/privacy',
        },
        {
            name: "Terms and Conditions",
            to: "/termsAndConditions"
        },
        {

            name: "Contact Us",
            to: "/cotact"
        },
    ]



    return (
        <FooterWrapper>
            <p>Copyright © 2010-2022 by Saral Patro</p>



            <FooterLinks>


                {
                    footerRoutes.map((item) => <FooterLink key={item.name} to={item.to}>{item.name}</FooterLink>)
                }

            </FooterLinks>
        </FooterWrapper>
    )
}

export default Footer