import React, { useState } from 'react'
import { DateTimeWrapper, DateWrapper, IconWithTime, Logo, LogoWrapper, MarginItems, NavbarComponents, NavItem, NavItems, NavLinks, NormalFont, SemiBoldFont, StickyWrapper, TimelineWrapper, TopLeftWrapper, TopRightWrapper, TopWrapper } from './NavbarComponents'
import { FaSun, FaMoon, FaUmbrella } from 'react-icons/fa';
import { useMatch, useResolvedPath } from 'react-router-dom';


const Navbar = () => {

    // const [logoType, setlogoType] = useState(0);
    // const radius = ['0px', '12px', '25px', '50px']

    // const changeLogo = () => {
    //     setlogoType((logoType + 1) % 4);
    // }

    const routes = [
        {
            name: "Home",
            to: '/',
        },
        {
            name: "Date Converter",
            to: "/converter"
        },
        {

            name: "Tools",
            to: "/tools"
        },

        {
            name: "Contact",
            to: "/contact"
        }
    ]


    const CustomLink = ({ children, to, ...props }) => {
        let resolved = useResolvedPath(to);
        let match = useMatch({ path: resolved.pathname, end: true });

        return (
            <div>
                <NavLinks
                    match={match}
                    to={to}
                    {...props}
                >
                    {children}
                </NavLinks>
            </div>
        );
    }

    return (
        <StickyWrapper>
            <NavbarComponents>
                <LogoWrapper to={'/'}>
                    <Logo src="./logo-rect1.png" alt="Logo" />
                </LogoWrapper>


                <NavItems>

                    {
                        routes.map((item) => <CustomLink key={item.name} to={item.to}>{item.name}</CustomLink>)
                    }

                </NavItems>
            </NavbarComponents>
            {/* <TopWrapper>
                <TopLeftWrapper>
                    <DateWrapper>
                        12
                    </DateWrapper>
                    <DateTimeWrapper>
                        <SemiBoldFont fontSize='36px'>
                            Falgun 2078, Sunday
                        </SemiBoldFont>
                        <SemiBoldFont fontsize='24px' small={true}>
                            13 March, 2022
                        </SemiBoldFont>
                        <NormalFont>Dashami, Falgun Shuklpaksha</NormalFont>
                        <NormalFont>11:54 A.M.</NormalFont>
                    </DateTimeWrapper>
                </TopLeftWrapper>
                <TopRightWrapper>
                    <SemiBoldFont fontsize='24px' big={true}>
                        13 March, 2022
                    </SemiBoldFont>
                    <TimelineWrapper>
                        <IconWithTime>
                            <MarginItems>
                                <FaSun className='icon-margin' />
                                10:30
                            </MarginItems>

                            <MarginItems>
                                <FaUmbrella className='icon-margin' />
                                10:30
                            </MarginItems>

                            <MarginItems>
                                <FaMoon className='icon-margin' />
                                18:30
                            </MarginItems>
                        </IconWithTime>
                    </TimelineWrapper>
                </TopRightWrapper>
            </TopWrapper> */}
        </StickyWrapper>
    )
}

export default Navbar