import Navbar from './components/Navbar';


import { BrowserRouter, Route, Routes } from "react-router-dom";
// import RequireAuth from "./components/Auth/RequireAuth";
import Home from './pages/Home';
import Converter from './pages/Converter';
import MobileNavigation from './components/MobileNavigation';
import { useEffect, useState } from 'react';
import './App.css';


function App() {





  return (
    <BrowserRouter>



      <Routes>
        <Route path="/">


          <Route
            index
            element={
              <Home />
            }
          />

          <Route path="/converter" element={<Converter />} />
          {/* <Route path="/signUp" element={<SignUp />} /> */}

          {/* <Route path="about" element={
            <RequireAuth>
              <About />
            </RequireAuth>
          } /> */}


          <Route
            path="*"
            element={
              <div className='app'>
                <Navbar></Navbar>
                <div style={{
                  background: 'red'
                }}>There's nothing here!</div>
                <MobileNavigation></MobileNavigation>
              </div>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter >






  );
}





export default App;
