import React, { useEffect, useRef } from 'react'
import { FixedBtn, FixedNavRightBtn, FixedNavLeftBtn, MainModal, ModalWrapper } from './ModalComponents';


const Modal = ({ handleClose, show, displayIndicators, onPrevClick, onNextClick, children }) => {


    const ref = useRef();


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                handleClose();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClose]);


    return (
        <ModalWrapper show={show}>
            <MainModal ref={ref}>
                <FixedBtn onClick={handleClose} />
                {children}

                {displayIndicators && <FixedNavLeftBtn onClick={onPrevClick} />}
                {displayIndicators && <FixedNavRightBtn onClick={onNextClick} />}

            </MainModal>
        </ModalWrapper>
    );
};

export default Modal;

