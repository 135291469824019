import React, { useEffect, useState } from 'react'
import MobileNavigation from '../../components/MobileNavigation'
import Navbar from '../../components/Navbar'
import { BoldFont, ConversionWrapper, ConvertBtn, DateSelect, Label, MainWrapper, RadioWrapper, SelectWrapper, SemiBoldFont, TopGridWrapper } from './ConverterComponents'
import { NEPALI_CALENDAR } from '../../utils/nepalicalendar'
import { DATE_CONVERSION } from '../../utils/dateConversion'
import Footer from '../../components/Footer'
import axios from 'axios';
import { GRAPHQL_BSTOAD_DATE_CONVERSION_QUERY, GRAPHQL_ADTOBS_DATE_CONVERSION_QUERY } from '../../utils/constants';
import { LoadingOverlay } from '@mantine/core'


const Converter = () => {

    console.log("9999");
    // console.log(Object.entries(NEPALI_CALENDAR).map(([key, value]) => key));
    console.log("9999")
    // Timezone Nepal
    const moment = require('moment-timezone');

    const [noOfDaysInMonth, setNoOfDaysInMonth] = useState(0);

    const [dateConversionSelected, setDateConversionSelected] = useState('1');

    const bsMonths = [
        "वैशाख", "जेष्ठ", "असार", "श्रावण", "भदौ", "आश्विन", "कार्तिक", "मंसिर", "पौष", "माघ", "फाल्गुण", "चैत्र"]
    const adMonths = [
        "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ]

    const [convertedDate, setConvertedDate] = useState();

    const [isLoading, setIsLoading] = useState(false);

    const changeEngDatetoNep = ({ date }) => {
        // console.log("Changing ddate");
        // console.log(date);
        var index = DATE_CONVERSION.findIndex(p => p.engDate === date);
        // console.log(index);
        // console.log("No index");
        let _nepDate = DATE_CONVERSION[index].nepDate.split('.');
        // console.log(DATE_CONVERSION[index]);
        // console.log(`${_nepDate[2]}-${_nepDate[1]}-${_nepDate[0]}`);
        var nepalidate = moment(`${_nepDate[2].trim()}-${_nepDate[1]}-${_nepDate[0]}`);
        // console.log(nepalidate);
        console.log("===============")
        return nepalidate
    }



    const [selectedNp, setSelectedNp] = useState({
        year: changeEngDatetoNep({
            date: moment.tz(new Date(), 'Asia/Kathmandu').format('YYYY-MM-DD'),
        }).format('YYYY'),
        month: changeEngDatetoNep({
            date: moment.tz(new Date(), 'Asia/Kathmandu').format('YYYY-MM-DD'),
        }).format('M'),
        day: changeEngDatetoNep({
            date: moment.tz(new Date(), 'Asia/Kathmandu').format('YYYY-MM-DD'),
        }).format('D')
    })

    const [selectedAD, setSelectedAD] = useState({
        year: moment.tz(new Date(), 'Asia/Kathmandu').format('YYYY'),
        month: moment.tz(new Date(), 'Asia/Kathmandu').format('M'),
        day: moment.tz(new Date(), 'Asia/Kathmandu').format('D')

    })


    const [currentData, setCurrentData] = useState({
        years: Object.entries(NEPALI_CALENDAR).map(([key, value]) => key),
        months: bsMonths,
        days: parseInt(NEPALI_CALENDAR[selectedNp.year][parseInt(selectedNp.month - 1)])

    })

    // const [selectedYearNp, setSelectedYearNp] = useState(changeEngDatetoNep({
    //     date: moment.tz(new Date(), 'Asia/Kathmandu').format('YYYY-MM-DD'),
    // }).format('YYYY'));

    // const [currentDays, setCurrentDays] = useState(0);


    const [months, setMonths] = useState(dateConversionSelected === '1' ? bsMonths : adMonths)
    // const [selectedMonthNp, setSelectedMonthNp] = useState(changeEngDatetoNep({
    //     date: moment.tz(new Date(), 'Asia/Kathmandu').format('YYYY-MM-DD'),
    // }).format('M'));



    const generateYears = () => {
        let _years = [];
        let _min = 1993;
        let _max = 3000;

        for (let i = _min; i <= _max; i++) {
            _years.push(i);
        }

        return _years;
    }

    useEffect(() => {

        console.log("OOps OOps")
        console.log(dateConversionSelected)
        console.log(currentData);

        let currentDataTemp = { ...currentData };

        if (dateConversionSelected === '1') {

            currentDataTemp =


            {

                years: Object.entries(NEPALI_CALENDAR).map(([key, value]) => key),
                months: [...bsMonths],
                days: parseInt(NEPALI_CALENDAR[selectedNp.year][parseInt(selectedNp.month - 1)])
            }



        } else {
            // setNoOfDaysInMonth(10);

            currentDataTemp =
            {

                years: generateYears(),
                months: [...adMonths],
                days: moment(`${selectedAD.year}-${selectedAD.month}`, "YYYY-MM").daysInMonth()
            }


        }

        console.log(currentDataTemp);
        setCurrentData({ ...currentDataTemp })

    }, [dateConversionSelected])



    useEffect(() => {
        console.log("Current Days are");
        // console.log(selectedNp);
        // console.log(parseInt(selectedMonthNp - 1));
        // console.log(NEPALI_CALENDAR[selectedYearNp][parseInt(selectedMonthNp - 1)]);
        if (dateConversionSelected === '1') {

            setCurrentData(
                {
                    ...currentData,
                    days: parseInt(NEPALI_CALENDAR[selectedNp.year][parseInt(selectedNp.month - 1)])
                }
            )


            setNoOfDaysInMonth(parseInt(NEPALI_CALENDAR[selectedNp.year][parseInt(selectedNp.month - 1)]));
        } else {
            // setNoOfDaysInMonth(10);

            setCurrentData(
                {
                    ...currentData,
                    days: moment(`${selectedAD.year}-${selectedAD.month}`, "YYYY-MM").daysInMonth()
                }
            )
        }



        console.log("Currently");
    }, [selectedNp.month, selectedNp.year, selectedAD.month, selectedAD.year])



    useEffect(() => {
        console.log("Current Days are");
        // console.log(selectedNp);
        // console.log(parseInt(selectedMonthNp - 1));
        // console.log(NEPALI_CALENDAR[selectedYearNp][parseInt(selectedMonthNp - 1)]);
        if (dateConversionSelected === '1') {

            setCurrentData(
                {
                    ...currentData,
                    days: parseInt(NEPALI_CALENDAR[selectedNp.year][parseInt(selectedNp.month - 1)])
                }
            )


            setNoOfDaysInMonth(parseInt(NEPALI_CALENDAR[selectedNp.year][parseInt(selectedNp.month - 1)]));
        } else {
            // setNoOfDaysInMonth(10);

            setCurrentData(
                {
                    ...currentData,
                    days: parseInt(NEPALI_CALENDAR[selectedNp.year][parseInt(selectedNp.month - 1)])
                }
            )
        }



        console.log("Currently");
    }, [selectedNp.month, selectedNp.year])



    const handleConversionChange = (event) => {
        // const { name, value } = event.target;
        console.log(event.target.value);
        let value = event.target.value;

        setDateConversionSelected(value);
        setMonths((value === '1' ? bsMonths : adMonths))

    };

    const handleMonthChange = (event) => {
        if (dateConversionSelected === '1') {
            setSelectedNp({
                ...selectedNp,
                month: event.target.value,
            })
        } else {
            setSelectedAD({
                ...selectedAD,
                month: event.target.value,
            })
        }
    }

    const handleDayChange = (event) => {
        if (dateConversionSelected === '1') {
            setSelectedNp({
                ...selectedNp,
                day: event.target.value,
            })
        } else {
            setSelectedAD({
                ...selectedAD,
                day: event.target.value,
            })
        }
    }

    const handleYearChange = (event) => {
        if (dateConversionSelected === '1') {
            setSelectedNp({
                ...selectedNp,
                year: event.target.value,
            })
        } else {
            setSelectedAD({
                ...selectedAD,
                year: event.target.value,
            })
        }
    }



    //     const CreateDays = () => {
    //         return (
    //             <>{

    //         {/* for(let i = 1; i < parseInt(NEPALI_CALENDAR[selectedYearNp][selectedMonthNp].toString()); i++) => (
    //                 <option>
    //                     {i}
    //                 </option>
    //         ) */}

    //               for(i=0;i<20;i++){
    //     var symKey=naming[i] + "_name";
    //                 console.log(apiData[i][symKey])
    // }

    //         }
    //             </>
    //         )
    //     }


    const convertDates = async () => {

        setIsLoading(true);
        console.log("Inside Convert dates")

        var config = {
            method: 'post',
            url: 'https://api.saralpatro.com/graphql',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                query: GRAPHQL_BSTOAD_DATE_CONVERSION_QUERY({ selectedYear: selectedNp.year, selectedMonth: selectedNp.month, selectedDay: selectedNp.day }),
                // variables: {}
            })
        };



        var config2 = {
            method: 'post',
            url: 'https://api.saralpatro.com/graphql',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                query: GRAPHQL_ADTOBS_DATE_CONVERSION_QUERY({ selectedYear: selectedAD.year, selectedMonth: selectedAD.month, selectedDay: selectedAD.day }),
                // variables: {}
            })
        };


        dateConversionSelected === '1' ? (
            await axios(config)
                .then(function (response) {
                    console.log(response.data.data.dates);

                    setConvertedDate(response.data.data.dates[0]);

                })
        ) : (
            await axios(config2)
                .then(function (response) {
                    console.log(response.data.data.dates);

                    setConvertedDate(response.data.data.dates[0]);

                })
        );

        setIsLoading(false);

    }

    return (
        <div className='App'>
            <Navbar></Navbar>

            <MainWrapper>


                {/* <h1>{currentData.years.length}</h1> */}
                <TopGridWrapper>

                    <ConversionWrapper>

                        <RadioWrapper>
                            <input type="radio" id="bsToAD" name="dateconversion" value="1" onChange={handleConversionChange} checked={dateConversionSelected === '1'} />
                            <Label htmlFor="bsToAD">Convert From BS to AD</Label>
                        </RadioWrapper>
                        <RadioWrapper>
                            <input type="radio" id="adToBS" name="dateconversion" value="2" onChange={handleConversionChange} checked={dateConversionSelected === '2'} />
                            <Label htmlFor="adToBS">Convert From AD to BS</Label>
                        </RadioWrapper>


                    </ConversionWrapper>

                    <SelectWrapper>

                        <DateSelect value={dateConversionSelected === '1' ? selectedNp.month : selectedAD.month} onChange={handleMonthChange}>
                            {
                                currentData.months.map((item, index) => {
                                    return (
                                        <option value={index + 1}>
                                            {item}
                                        </option>

                                    )

                                })
                            }

                        </DateSelect>



                        <DateSelect value={dateConversionSelected === '1' ? selectedNp.day : selectedAD.day} onChange={handleDayChange}>
                            {
                                new Array(currentData.days).fill(4).map((item, index) => (
                                    <option value={index + 1}>{index + 1}</option>
                                )
                                )
                            }

                        </DateSelect>

                        <DateSelect value={dateConversionSelected === '1' ? selectedNp.year : selectedAD.year} onChange={handleYearChange}>
                            {

                                currentData.years.map((key) => {

                                    return (
                                        <option value={key}>
                                            {key}
                                        </option>

                                    )

                                })
                            }

                        </DateSelect>

                    </SelectWrapper>
                    <ConvertBtn onClick={convertDates}>Convert</ConvertBtn>
                </TopGridWrapper>

                <div>
                    <LoadingOverlay visible={isLoading} />
                    {
                        dateConversionSelected === '1' ? (<div>
                            <BoldFont>{convertedDate ? (`${convertedDate?.bsDayNp} ${convertedDate?.bsMonthStrNp} ${convertedDate?.bsYearNp}, ${convertedDate?.weekdayStrNp}`) : "कृपया मिति चयन गर्नुहोस्"}</BoldFont>
                            <SemiBoldFont>{convertedDate ? (`${convertedDate?.adDay} ${convertedDate?.adMonthStrEn} ${convertedDate?.adYear}, ${convertedDate?.weekdayStrEn}`) : "Please select a date"}</SemiBoldFont>
                        </div>) : (<div>
                            <BoldFont>{convertedDate ? (`${convertedDate?.adDay} ${convertedDate?.adMonthStrEn} ${convertedDate?.adYear}, ${convertedDate?.weekdayStrEn}`) : "कृपया मिति चयन गर्नुहोस्"}</BoldFont>
                            <SemiBoldFont>{convertedDate ? (`${convertedDate?.bsDayNp} ${convertedDate?.bsMonthStrNp} ${convertedDate?.bsYearNp}, ${convertedDate?.weekdayStrNp}`) : "कृपया मिति चयन गर्नुहोस्"}</SemiBoldFont>
                        </div>)
                    }
                </div>



            </MainWrapper>



            <Footer />
            <MobileNavigation />
        </div>
    )
}

export default Converter