export const GRAPHQL_API = "https://api.saralpatro.com/graphql";

export const GRAPHQL_QUERY = ({ selectedYear = '2078', selectedMonth }) => `
{
    dates(bsYear: ${selectedYear}) {
   bsDay
    bsMonth
    bsYear
    adDay
    adMonth
    adYear
    bsDayNp
    bsMonthNp
    bsYearNp
    weekday
    weekdayStrEn
    weekdayStrNp
    bsMonthStrEn
    bsMonthStrNp
    adMonthStrEn
    adMonthStrNp
    isHoliday
    tithiCode
    tithiStrNp
    tithiStrEn
    astral {
      moonphase
      moonphaseStr
      sunrise {
        hour
        minute
      }
      sunset {
        hour
        minute
      }
      dawn {
        hour
        minute
      }
      dusk {
        hour
        minute
      }
      noon {
        hour
        minute
      }
    }
    specialDays {
      bratabanda
      bibaha
      rudri
      grihaprabesh
    }
    events {
      strEn
      strNp
      isHoliday
    }
  }
}
`


export const GRAPHQL_BSTOAD_DATE_CONVERSION_QUERY = ({ selectedYear, selectedMonth, selectedDay }) => `
{
    dates(bsYear: ${selectedYear}, bsMonth: ${selectedMonth}, bsDay: ${selectedDay}) {
   bsDay
    bsMonth
    bsYear
    adDay
    adMonth
    adYear
    bsDayNp
    bsMonthNp
    bsYearNp
    weekday
    weekdayStrEn
    weekdayStrNp
    bsMonthStrEn
    bsMonthStrNp
    adMonthStrEn
    adMonthStrNp
    isHoliday
    tithiCode
    tithiStrNp
    tithiStrEn
    astral {
      moonphase
      moonphaseStr
      sunrise {
        hour
        minute
      }
      sunset {
        hour
        minute
      }
      dawn {
        hour
        minute
      }
      dusk {
        hour
        minute
      }
      noon {
        hour
        minute
      }
    }
    specialDays {
      bratabanda
      bibaha
      rudri
      grihaprabesh
    }
    events {
      strEn
      strNp
      isHoliday
    }
  }
}
`


export const GRAPHQL_ADTOBS_DATE_CONVERSION_QUERY = ({ selectedYear, selectedMonth, selectedDay }) => `
{
    dates(adYear: ${selectedYear}, adMonth: ${selectedMonth}, adDay: ${selectedDay}) {
   bsDay
    bsMonth
    bsYear
    adDay
    adMonth
    adYear
    bsDayNp
    bsMonthNp
    bsYearNp
    weekday
    weekdayStrEn
    weekdayStrNp
    bsMonthStrEn
    bsMonthStrNp
    adMonthStrEn
    adMonthStrNp
    isHoliday
    tithiCode
    tithiStrNp
    tithiStrEn
    astral {
      moonphase
      moonphaseStr
      sunrise {
        hour
        minute
      }
      sunset {
        hour
        minute
      }
      dawn {
        hour
        minute
      }
      dusk {
        hour
        minute
      }
      noon {
        hour
        minute
      }
    }
    specialDays {
      bratabanda
      bibaha
      rudri
      grihaprabesh
    }
    events {
      strEn
      strNp
      isHoliday
    }
  }
}
`